// @flow

import React from 'react'
import ReactDOM from 'react-dom'
import styled, { css } from 'styled-components'
import { connect } from 'react-redux'
import type { Dispatch } from 'redux'
import { bindActionCreators } from 'redux'
import { Button, FormFeedback, Progress, ListGroup, ListGroupItem, Input } from 'reactstrap'
import debounce from 'lodash/debounce'
import { selectQuestionnaire, answerQuestion, calculateRisk, setAgentComment } from '../actions'
import {
  getQuestionnaire,
  getTotalQuestionnaire,
  answeredPercent,
  answeredCount,
  getRiskLevelResult,
  getAgentComment,
} from '../selectors'
import { getAge } from 'quick-quote/insured-information/selectors'
import { getToggles } from 'quick-quote/feature-toggles'
import type { Questionnaire, Choice } from 'core/service/investment/questionnaire'
import type { AppState } from 'quick-quote/reducers'
import type { RiskEvaluated } from 'core/service/investment/risklevel'
import MESSAGES from 'core/data-model/constants/messages'
import question7 from 'assets/images/question7_pic2.png'

const MODAL_TITLE = 'แบบประเมินความเสี่ยง'
const PROGRESS_UNIT = 'ข้อ'
const AGENT_NOTE_TITLE = 'สำหรับตัวแทน'
const AGENT_NOTE_SUBTITLE = 'บันทึกความเห็นของตัวแทนประกันชีวิต และข้อสังเกตต่างๆ'
const SUBMIT_TEXT = 'คำนวณความเสี่ยง'
const JUVENILE_INFO =
  'กรณีผู้เอาประกันอายุต่ำกว่า 20 ปี กรุณาให้ผู้ชำระเบี้ยประกันตอบแบบประเมินระดับความเสี่ยงในการลงทุน'

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ParagraphStyled = styled.p`
  text-align: center;
`

const DivCenter = styled.div`
  align-self: center;
`

const ProgressWrapper = styled.div`
  width: 100%;
  top: 0;
  text-align: center;
  align-self: center;
  padding: 15px 0 15px;
  transition-duration: 0.4s;
  transition-property: top;
  font-weight: bold;
  color: #2425aa;

  ${(props) => {
    if (props.isSticky) {
      return css`
        top: 123px;
        left: 0;
        position: fixed;
        padding: 10px 0 0;
        background-color: #fff;
        box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
        border-bottom: 1px solid #e5e5e5;
        z-index: 499;
      `
    }
  }}

  .progress-bar {
    transition: width 0.2s;
  }
`
const Image = styled.img`
  display: block;
  height: auto;
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
`

class RiskQuestionnaire extends React.Component<RiskQuestionnaireProps> {
  static defaultProps = {
    //Header height
    offsetFromTop: 123,
  }

  constructor(props) {
    super(props)
    this.refsBar = null
    this.state = {
      isSticky: false,
      barPosition: 0,
      calculated: false,
    }
  }

  topEdge = () => {
    return this.props.offsetFromTop + window.pageYOffset
  }

  currentBarPosition = () => {
    if (this.refsBar) {
      return ReactDOM.findDOMNode(this.refsBar).offsetTop
    }
  }

  barHeight = () => {
    if (this.refsBar) {
      return ReactDOM.findDOMNode(this.refsBar).offsetHeight
    }
  }

  barPositionChanged = () => {
    return !this.state.isSticky && this.currentBarPosition() !== this.state.barPosition
  }

  updateAgentComment = debounce((comment) => {
    this.props.setAgentComment(comment)
  }, 250)

  onAgentCommentChange = (event) => {
    const comment = event.target.value

    this.updateAgentComment(comment)
  }

  handleScroll = () => {
    if (this.refsBar) {
      if (!this.state.calculated) {
        this.setState({
          calculated: true,
          barPosition: this.currentBarPosition(),
        })
      } else {
        this.setState({
          isSticky: this.topEdge() >= this.state.barPosition,
        })
      }
      if (this.barPositionChanged()) {
        this.setState({
          calculated: false,
        })
      }
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', () => this.handleScroll())
  }

  render() {
    const { isSticky } = this.state
    const {
      insuredAge,
      answeredCount,
      questionnaires,
      answerQuestion,
      riskLevelResult,
      calculateRisk,
      answeredPercent,
      totalQuestionnaire,
      agentComment,
    } = this.props
    const css = answeredPercent === 100 ? 'primary' : 'btn btn-disabled'
    const isJuvenile = insuredAge.unit === 'month' || (insuredAge.unit === 'year' && insuredAge.value < 20)
    const shouldShowJevenileInfo = isJuvenile

    const handleMultipleChoice = (val, oldVal) => {
      if (Array.isArray(oldVal) && oldVal.includes(val)) {
        const newArray = [...oldVal]
        const index = newArray.indexOf(val)
        if (newArray.indexOf(val) > -1) {
          newArray.splice(index, 1)
        }
        return newArray
      }
      return [...oldVal, val]
    }

    return (
      <div className="risk-questionaire">
        <StyledHeader>
          <ParagraphStyled className="promtp-info">{MESSAGES.RPUL_RISK_QUESTIONNAIRE_PROMPT_LINE_01}</ParagraphStyled>
          <ParagraphStyled className="promtp-info">{MESSAGES.RPUL_RISK_QUESTIONNAIRE_PROMPT_LINE_02}</ParagraphStyled>
        </StyledHeader>
        <div className="question-content">
          {shouldShowJevenileInfo ? (
            <div className="info-message-container">
              <div className="form-group">
                <FormFeedback className="info-message attached-icon">
                  <p>{JUVENILE_INFO}</p>
                </FormFeedback>
              </div>
            </div>
          ) : null}
          <div style={{ paddingTop: isSticky ? this.barHeight() : 0 }} />
          <ProgressWrapper isSticky={isSticky} ref={(el) => (this.refsBar = el)}>
            <DivCenter>{MODAL_TITLE}</DivCenter>
            <div>
              {answeredCount} / {totalQuestionnaire} {PROGRESS_UNIT}
            </div>
            <Progress value={answeredPercent} />
          </ProgressWrapper>
          <ul className="quiz">
            {questionnaires.map((item: Questionnairem, index) => (
              <li className="form-group" key={index + 1}>
                <label>{`${index + 1}. ${item.question}`}</label>
                <ListGroup>
                  {index === 6 && getToggles().ENABLE_RPQ_FORM_2022 && (
                    <div>
                      <Image src={question7} alt="question7" />
                      <br />
                    </div>
                  )}
                  {item.choices.map((i: Choice) => {
                    if (item.multipleChoice) {
                      return (
                        <ListGroupItem
                          key={`${index + 1}${i.value}`}
                          active={Array.isArray(item.selectedChoice) && item.selectedChoice.includes(i.value)}
                          onClick={() => answerQuestion(index + 1, handleMultipleChoice(i.value, item.selectedChoice))}
                        >
                          <span
                            dangerouslySetInnerHTML={{ __html: i.choice + (item.hasScore ? ` (${i.value}) ` : '') }}
                          />
                        </ListGroupItem>
                      )
                    }

                    return (
                      <ListGroupItem
                        key={`${index + 1}${i.value}`}
                        active={item.selectedChoice === i.value}
                        onClick={() => answerQuestion(index + 1, i.value)}
                      >
                        <span
                          dangerouslySetInnerHTML={{ __html: i.choice + (item.hasScore ? ` (${i.value}) ` : '') }}
                        />
                      </ListGroupItem>
                    )
                  })}
                </ListGroup>
              </li>
            ))}
            {
              <li className="form-group">
                <div>
                  <strong>{AGENT_NOTE_TITLE}</strong>
                  <p>{AGENT_NOTE_SUBTITLE}</p>
                  <Input defaultValue={agentComment} onChange={this.onAgentCommentChange} />
                </div>
              </li>
            }
          </ul>
          <div className="action">
            <Button
              id="btn-calculate-risk"
              color={css}
              className={css}
              onClick={() => {
                calculateRisk(riskLevelResult)
              }}
            >
              {SUBMIT_TEXT}
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

export type QuestionnaireState = {
  questionnaires: Questionnaire[],
  answeredPercent: number,
}

export type QuestionnaireDispatchers = {
  answerQuestion: (number, number) => void,
  calculateRisk: (RiskEvaluated) => void,
}

type RiskQuestionnaireProps = {
  insuredAge: 1,
  questionnaires: Questionnaire[],
  totalQuestionnaire: number,
  answeredPercent: number,
  answeredCount: number,
  riskLevelResult: RiskEvaluated,
  answerQuestion: (number, number) => void,
  calculateRisk: (RiskEvaluated) => void,
  isModalOpen: boolean,
  toggleModal: () => void,
  agentComment: string,
  setAgentComment: (string) => void,
}

const mapStateToProps = (state: AppState): QuestionnaireState => ({
  insuredAge: getAge(state),
  questionnaires: getQuestionnaire(state),
  totalQuestionnaire: getTotalQuestionnaire(state),
  answeredPercent: answeredPercent(state),
  answeredCount: answeredCount(state),
  riskLevelResult: getRiskLevelResult(state),
  agentComment: getAgentComment(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>): QuestionnaireDispatchers => ({
  selectQuestionnaire: bindActionCreators(selectQuestionnaire, dispatch),
  answerQuestion: bindActionCreators(answerQuestion, dispatch),
  calculateRisk: bindActionCreators(calculateRisk, dispatch),
  setAgentComment: bindActionCreators(setAgentComment, dispatch),
})

const RiskQuestionnaireComponent = connect(mapStateToProps, mapDispatchToProps)(RiskQuestionnaire)

export default RiskQuestionnaireComponent
