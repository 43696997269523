import oldSubmitSection from 'e-submission/domain/data-model/form/submit/submitSection'
import { hasReceiptSigned } from '../selectors'

export const creator = ({ oldSubmitSection }) => {
  return (props = {}, selectedRemoteSelling = false, app) => {
    return Object.assign(
      {},
      {
        id: 'submit',
        prefix: 'submit',
        title: selectedRemoteSelling ? 'ส่งข้อมูล' : 'Submit',
        label: selectedRemoteSelling ? 'ส่งข้อมูล' : 'Submit',
        sections: [oldSubmitSection('submit')],
        exclude: true,
        remoteSection: selectedRemoteSelling,
        disabled: !hasReceiptSigned(app),
      },
      props
    )
  }
}

export const submit = creator({ oldSubmitSection })
