// @flow
import Mustache from 'mustache'
import CONSTANTS from 'core/data-model/constants/messages'
import MESSAGES from 'core/data-model/constants/bi-messages'
import VALUES from 'core/data-model/constants/values'
import { formatNumber } from 'core/service/lib/number-format'
import { buildStacks } from 'core/service/pdf-generation/utils/builder'

import type { DisplayProduct } from 'core/service/display-product'
import type { PolicyValue } from 'core/service/benefit-illustration/policy-value'

type Props = {
  basicPlan: DisplayProduct,
  calculatedCoveragePeriod: number,
  calculatedPaymentPeriod: number,
  sumAssured: number,
  basicPremium: number,
  productGraph: string,
  basicPlanDetailsLabelFn: (DisplayProduct) => string,
  policyValue: PolicyValue,
  selectedModelFactorID: string,
  selectedModelFactorLabel: string,
  sumAssuredContract: number,
}

export const productBenefitsSection = (props: Props) => {
  const { basicPlan, productGraph } = props
  const showGraph = productGraph !== ''
  const summarySection = getSummarySection({ ...props, showGraph })
  const productBenefitsDisclaimer = getProductBenefitsDisclaimer(basicPlan)
  return summarySection.concat(productBenefitsDisclaimer)
}

const getSummarySection = ({
  basicPlan,
  calculatedCoveragePeriod,
  calculatedPaymentPeriod,
  sumAssured,
  basicPremium,
  showGraph,
  productGraph,
  selectedModelFactorID,
  selectedModelFactorLabel,
  sumAssuredContract,
}) => {
  return [
    {
      pageBreak: 'before',
      text: basicPlanDetailsLabelFn(basicPlan),
      style: 'title',
    },
    {
      columns: buildStacks([
        {
          label: MESSAGES.COVERAGE_PERIOD,
          value: `${calculatedCoveragePeriod} ${CONSTANTS.TIME_UNIT_YEAR}`,
          bold: false,
        },
        {
          label: MESSAGES.PAYMENT_PERIOD,
          value:
            selectedModelFactorID === 'model-factor_5'
              ? selectedModelFactorLabel
              : `${calculatedPaymentPeriod} ${CONSTANTS.TIME_UNIT_YEAR}`,
          bold: false,
        },
        {
          label: MESSAGES.BASIC_PLAN_SUM_ASSURED,
          value: `${formatNumber(sumAssuredContract, 0, true)} ${CONSTANTS.BAHT}`,
          bold: false,
        },
        {
          label: MESSAGES.BASIC_PREMIUM,
          value: `${formatNumber(basicPremium, 2)} ${CONSTANTS.BAHT}`,
          bold: false,
        },
      ]),
      style: 'info',
    },
    showGraph ? { image: productGraph, alignment: 'center', width: 425, margin: [0, 20, 0, 30] } : {},
  ]
}

const basicPlanDetailsLabelFn = (basicPlan) => {
  switch (basicPlan.basicPlanCode) {
    case VALUES.MRTA_CODE.MRTA_PLUS:
      return Mustache.render('รายละเอียดของสัญญาหลัก {{& name }} \n({{ planCode }}) ({{ code }})', {
        name: basicPlan.nameThai,
        planCode: MESSAGES.MRTA_PLUS_EN_NAME,
        code: basicPlan.code,
      })
    case VALUES.MRTA_CODE.MRTA_TRUNCATE:
      return Mustache.render(MESSAGES.BASIC_PLAN_DETAILS_MRTA, {
        name: 'กรุงไทยเพื่อคุ้มครองสินเชื่อ แบบความคุ้มครองลดลง \nคุ้มครอง 10 ปี',
        planCode: MESSAGES.MRTA_TRUNCATE_EN_NAME,
        code: basicPlan.code,
      })
    case VALUES.MRTA_CODE.MRTA_FULL:
      return Mustache.render(MESSAGES.BASIC_PLAN_DETAILS_MRTA, {
        name: basicPlan.nameThai,
        planCode: MESSAGES.MRTA_FULL_EN_NAME,
        code: basicPlan.code,
      })
    case VALUES.MRTA_CODE.GLTSP:
      return Mustache.render(MESSAGES.BASIC_PLAN_DETAILS_MRTA, {
        name: basicPlan.nameThai,
        planCode: basicPlan.basicPlanCode,
        code: basicPlan.code,
      })
    default:
      return Mustache.render(MESSAGES.BASIC_PLAN_DETAILS_MRTA, {
        name: basicPlan.nameThai,
        planCode: basicPlan.basicPlanCode,
        code: basicPlan.code,
      })
  }
}

const getProductBenefitsDisclaimer = (basicPlan) => {
  return basicPlan.basicPlanCode === VALUES.GLTSP_CODE.GLTSP
    ? [
        {
          stack: [
            {
              text: [{ text: 'GLTSP : ', bold: true }, MESSAGES.GRAPH_REMARK_GLTSP],
            },
            {
              text: [{ text: 'หมายเหตุ : ', bold: true }, MESSAGES.GRAPH_REMARK_ALL_PDF],
            },
          ],
          style: 'disclaimer',
        },
      ]
    : [
        {
          stack: [
            {
              text: [{ text: 'MRTA : ', bold: true }, MESSAGES.GRAPH_REMARK_MRTA],
            },
            {
              text: [{ text: 'ความคุ้มครอง : ', bold: true }, MESSAGES.GRAPH_REMARK_MRTA_COVERAGE_PDF],
            },
            {
              text: [{ text: 'หมายเหตุ : ', bold: true }, MESSAGES.GRAPH_REMARK_ALL_PDF],
            },
          ],
          style: 'disclaimer',
        },
      ]
}
