// @flow

import { Section } from 'core/service/pdf-generation/products/common/components/general'
import MESSAGES from 'core/data-model/constants/bi-messages'
import {
  paymentChannelsSubSection,
  paymentChannelsSectionILink,
} from '../../common/sections/payment-channels-sub-section'

const header = {
  marginBottom: 5,
  bold: true,
}

export const PaymentChannels = async (productCategory) => {
  const paymentLabel = MESSAGES.PAYMENT_AGENT_OR_BANK
  const paymentChannels = await paymentChannelsSubSection({ paymentLabel, productCategory })
  return Section(MESSAGES.PAY_CHANNELS, [
    {
      text: MESSAGES.PAYMENT_CHANNELS_FOR_PREMIUM,
      style: header,
    },
    ...paymentChannels,
  ])
}

export const PaymentChannelsILink = () => {
  const paymentChannels = paymentChannelsSectionILink()
  return Section(MESSAGES.PAY_CHANNELS, [...paymentChannels])
}
