//@flow
import { compose, lifecycle } from 'recompose'
import { ModalBody, ModalHeader, Button } from 'reactstrap'
import _isNil from 'lodash/fp/isNil'
import { connect } from 'react-redux'
import { getIcon } from 'e-submission/components/Icon'
import { Modal } from 'common-components/index'
import { removeError, setAppValue } from '../../../apps/actions'
import { Dispatch } from 'redux'
import { Link } from 'react-router-dom'
import { getToggles } from 'quick-quote/feature-toggles'

const SubmitButton = ({
  onExit,
  isInvalid = false,
  removeError,
  setSkippedEkyc,
  insuredDopaVerified,
  payerDopaVerified,
  insuredDopaRequestFailed,
  payerDopaRequestFailed,
}) => {
  const isSkippedInsuredEKYC = !insuredDopaVerified && insuredDopaRequestFailed
  const isSkippedPayerKYC = !payerDopaVerified && payerDopaRequestFailed
  return (
    <div className={'normal-text detail-text button-submit'}>
      <Link to="document">
        <Button
          color={isInvalid ? 'disabled' : 'primary'}
          className="primary"
          onClick={() => {
            setSkippedEkyc('insured', isSkippedInsuredEKYC)
            setSkippedEkyc('payer', isSkippedPayerKYC)
            removeError()
            onExit()
          }}
        >
          ตกลง
        </Button>
      </Link>
    </div>
  )
}

export const DopaRequestFailedModal = ({
  isOpen,
  onExit,
  removeError,
  setSkippedEkyc,
  insuredDopaVerified,
  payerDopaVerified,
  insuredDopaRequestFailed,
  payerDopaRequestFailed,
  isInvalid,
}) => {
  const mapText = {
    verifiedFail: {
      title: 'พบปัญหาการยืนยันตัวตนกับระบบ DOPA',
      description1: 'เนื่องจากผลการยืนยันตัวตนกับ DOPA ไม่ผ่าน',
      description2: 'กรุณาขอเอกสารเพิ่มเติมเพื่อดำเนินการยืนยันตัวตนเบื้องต้นในขั้นตอนต่อไป',
    },
    dopaFail: {
      title: 'ขณะนี้ระบบ DOPA ขัดข้อง',
      description1: 'กรุณาขอเอกสารเพิ่มเติมเพื่อดำเนินการยืนยันตัวตนเบื้องต้น',
      description2: 'ระบบจะแจ้งให้ทราบเมื่อขั้นตอน ยืนยันกับ DOPA เสร็จสิ้น',
    },
  }

  if (getToggles().ENABLE_DISABLE_SECOND_DOC) {
    mapText.dopaFail = {
      title: 'ขณะนี้ระบบยืนยันตัวตนกับกรมการปกครองขัดข้อง',
      description1: 'ระบบจะแจ้งผลการยืนยันตัวตนให้ทราบหลังจากที่ระบบ',
      description2: 'กลับมาใช้งาน กรุณากดปุ่มตกลงเพื่อไปยังขั้นตอนถัดไป',
    }
  }

  let text = mapText.dopaFail

  if (getToggles().ENABLE_PAY_LATER) {
    const verifiedFailed =
      (!_isNil(insuredDopaVerified) && !insuredDopaVerified) || (!_isNil(payerDopaVerified) && !payerDopaVerified)
    const dopaFailed = insuredDopaRequestFailed || payerDopaRequestFailed
    if (verifiedFailed && !dopaFailed) text = mapText.verifiedFail
  }

  return (
    <Modal
      className={'identify-ekyc-responding-modal'}
      isOpen={isOpen}
      size="md"
      modalClassName="default-bootstrap remote-selling-modal"
      toggle={() => {
        onExit()
      }}
    >
      <ModalHeader
        className={'header-modal'}
        toggle={() => {
          onExit()
        }}
      ></ModalHeader>
      <ModalBody>
        <div className={`normal-text`}>{getIcon('ekycStatusWarning')}</div>
        <h5 className={'text-header normal-text'}>{text.title}</h5>
        <div className={'normal-text detail-text'}>{text.description1}</div>
        <div className={'normal-text detail-text'}>{text.description2}</div>
        <SubmitButton
          onExit={onExit}
          isInvalid={isInvalid}
          removeError={removeError}
          setSkippedEkyc={setSkippedEkyc}
          insuredDopaVerified={insuredDopaVerified}
          payerDopaVerified={payerDopaVerified}
          insuredDopaRequestFailed={insuredDopaRequestFailed}
          payerDopaRequestFailed={payerDopaRequestFailed}
        />
      </ModalBody>
    </Modal>
  )
}

const mapDispatchToDopaRequestFailedModal = (dispatch: Dispatch<*>) => ({
  removeError: () => dispatch(removeError('EKYC_VERIFY_CITIZENID')),
  setSkippedEkyc: (key, value) => {
    if (!_isNil(value)) dispatch(setAppValue(`ekycInfo.${key}.skipped`, value))
    // if (!_isNil(value)) dispatch(setAppValue(`ekycInfo.${key}.skipped`, value ? true : false))
  },
})

export default compose(
  connect(null, mapDispatchToDopaRequestFailedModal),
  lifecycle({
    componentDidMount() {},
    componentDidUpdate(prevProps) {},
    UNSAFE_componentWillReceiveProps(nextProps) {},
  })
)(DopaRequestFailedModal)
