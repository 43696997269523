//@flow
import { Heading, WordWraps } from 'common-components'

import values from 'core/data-model/constants/values'
import type { RiskRange } from 'core/service/investment/risklevel'
import styled from 'styled-components'
import { getToggles } from 'quick-quote/feature-toggles'

type AcceptanceLevelsProps = {
  acceptableRiskRange: RiskRange,
  className: Object,
}

const displayRiskRange = (riskRange: RiskRange) =>
  riskRange.max === riskRange.min ? riskRange.min : `${riskRange.min}-${riskRange.max}`

const handleTextRiskLevel = () =>
  getToggles().ENABLE_RPQ_FORM_2022
    ? 'แนะนำให้ลงทุนในกองทุนที่มีระดับความเสี่ยง'
    : 'ท่านสามารถลงทุนในกองทุนที่ระดับความเสี่ยง'
const handleTextRiskDescription1 = () =>
  getToggles().ENABLE_RPQ_FORM_2022
    ? 'ท่านสามารถลงทุนในกองทุนที่มีระดับความเสี่ยง'
    : 'หากท่านต้องการลงทุนในระดับความเสี่ยง'
const handleTextRiskDescription2 = () =>
  getToggles().ENABLE_RPQ_FORM_2022
    ? 'ได้ โดยอ้างอิงตัวอย่างคำแนะนำเรื่องการจัดสรรการลงทุน*'
    : 'ไม่ควรลงทุนเกินร้อยละ 20 ของเงินลงทุนทั้งหมด*'
const handleTextDisclaimer = () =>
  getToggles().ENABLE_RPQ_FORM_2022 ? (
    <p>
      * ในกรณีที่ท่านเลือกลงทุนในกองทุนที่มีระดับความเสี่ยง<u>สูงกว่า</u>ระดับความเสี่ยงที่ท่านประเมินได้
      โปรดกรอกข้อมูลในแบบฟอร์มรับทราบความเสี่ยง
    </p>
  ) : (
    <WordWraps>
      * ในกรณีที่ท่านเลือกลงทุนในกองทุนที่มีความเสี่ยงสูงกว่าระดับความเสี่ยงที่ท่านประเมินได้
      จำเป็นต้องกรอกข้อมูลในแบบฟอร์มยอมรับความเสี่ยง
    </WordWraps>
  )

const EvaluatedRiskRange = styled.span`
  font-size: 3rem;
  line-height: 1rem;
`

export const AcceptanceLevels = styled(({ className, acceptableRiskRange }: AcceptanceLevelsProps) => (
  <div className={className}>
    <p>
      {getToggles().ENABLE_RPQ_FORM_2022 ? 'ระดับ' : ''}ความเสี่ยง
      <br />
      {getToggles().ENABLE_RPQ_FORM_2022 ? 'ของกองทุน' : 'ที่ลงทุนได้'}
    </p>
    <EvaluatedRiskRange>{displayRiskRange(acceptableRiskRange)}</EvaluatedRiskRange>
  </div>
))`
  font-size: 0.9rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
`

type AcceptanceLevelsDescProps = {
  acceptableRiskRange: RiskRange,
  overRiskRange: ?RiskRange,
}

const Disclaimer = styled.p`
  font-size: 0.8667rem;
  color: ${values.PRIMARY_COLOR};
`

export const AcceptanceLevelsDesc = ({ acceptableRiskRange, overRiskRange }: AcceptanceLevelsDescProps) => (
  <div>
    <Heading>
      {getToggles().ENABLE_RPQ_FORM_2022
        ? 'ระดับความเสี่ยงของกองทุนที่เหมาะสมในการลงทุน'
        : 'ระดับความเสี่ยงที่รับได้ของท่าน'}
    </Heading>
    {`${handleTextRiskLevel()} ${displayRiskRange(acceptableRiskRange)}`}
    {overRiskRange && (
      <p>
        <WordWraps>
          {`${handleTextRiskDescription1()} ${displayRiskRange(overRiskRange)} ${handleTextRiskDescription2()}`}
        </WordWraps>
      </p>
    )}
    {!overRiskRange && getToggles().ENABLE_RPQ_FORM_2022 ? (
      <p>ท่านสามารถอ้างอิงตัวอย่างคำแนะนำเรื่องการจัดสรรการลงทุน</p>
    ) : null}
    {overRiskRange && <Disclaimer>{handleTextDisclaimer()}</Disclaimer>}
  </div>
)
