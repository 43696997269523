//@flow

import type { PlanCodeAttribute } from '../display-product/types'
import Mustache from 'mustache'

type LoanPlanCodeDependencies = {
  coverageTerm: number,
  insuranceLoanTerm: number,
}

export const generatePlanCode = (
  planCodeSchema: PlanCodeAttribute[],
  { coverageTerm, insuranceLoanTerm }: LoanPlanCodeDependencies
): string => {
  const schema = planCodeSchema.join('')

  return Mustache.render(schema, {
    coverageTerm: formatToTwoDigitString(coverageTerm),
    insuranceLoanTerm: formatToTwoDigitString(insuranceLoanTerm),
  })
}

const formatToTwoDigitString = (number: number): string => number.toString().padStart(2, '0')
