// @flow
import { isNil } from 'core/service/lib/type-check'
import Mustache from 'mustache'

import type { DisplayProduct } from 'core/service/display-product'
import type { SumAssured$, BasicPremium$ } from 'core/data-model/basic-plan'
import type { PolicyValue, BenefitSummary as BenefitSummaryType } from 'core/service/benefit-illustration'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { Age } from 'core/data-model/insured'
import { Redirect } from 'react-router-dom'
import PATH from 'quick-quote/constants/path'
import MESSAGES from 'core/data-model/constants/messages'
import BIMESSAGES from 'core/data-model/constants/bi-messages'
import { renderUnit } from 'core/service/insured/age'
import { formatNumber } from 'core/service/lib/number-format'
import { ProfileSummaryProps } from 'quick-quote/product-investment/benefit-illustration/components'
import { SidebarMenu } from 'quick-quote/components'
import { christianToThaiDate } from 'core/service/lib/date'
import { wrapWordingToTextJustify } from 'core/service/benefit-illustration/utils/wording-wrapper'
import SummaryTable from './summary-table'

type BenefitIllustrationProps = {
  displayProduct: ?DisplayProduct,
  riders: (Rider & RiderState)[],
  genderLabel: string,
  age: Age,
  calculatedCoveragePeriod: number,
  calculatedPaymentPeriod: number,
  sumAssured$: SumAssured$,
  basicPremium$: BasicPremium$,
  totalPremium: number,
  totalRiderPremium: number,
  selectedModelFactorLabel: string,
  selectedModelFactorPeriod: number,
  hasNonLevelRider: boolean,
  coveragePlanStartDate: Date,
  legalDisclaimerEndDate: Date,
  policyValue: PolicyValue,
  benefitSummary: BenefitSummaryType,
  canProceedToBenefitIllustration: boolean,
  isBlockedPdfGeneration: boolean,
  isBlockedEnteringESub: boolean,
}

type LegalDisclaimerProps = {
  startDate: Date,
  endDate: Date,
}

type TaxDeductionProps = {
  taxDeduction: TaxDeduction,
}

const TaxConsentDeductionBenefit = ({ taxDeduction }: TaxDeductionProps) => {
  return (
    <div id="tax-benefit" className="legal group retirement-tax-benefit">
      <h3>{BIMESSAGES.TAX_DEDUCTION_BENEFIT_TITLE}</h3>
      <div className="disclaimer">
        <div>{BIMESSAGES.TAX_DEDUCTION_BENEFIT_SUB_TITLE}</div>
        <div
          dangerouslySetInnerHTML={{
            __html: `${BIMESSAGES.TAX_DEDUCTION_BENEFIT_SUB_DESCRIPTION} <strong>${wrapWordingToTextJustify(
              BIMESSAGES.TAX_DEDUCTION_BENEFIT_SUB_DESCRIPTION_STRONG
            )}</strong>`,
          }}
        />
        <ul className="taxdeduction">
          <li>
            <div>{BIMESSAGES.TAX_CONSENT_DEDUCTION_BENEFIT_SUB_LIST[3].description}</div>
            <div>
              {Mustache.render(BIMESSAGES.TAX_CONSENT_DEDUCTION_BENEFIT_SUB_LIST[3].benefit, {
                value:
                  taxDeduction.taxDeductionLifeForYear === 0
                    ? '-'
                    : formatNumber(taxDeduction.taxDeductionLifeForYear, 2, true),
              })}
            </div>
          </li>
          <li>
            <div>{BIMESSAGES.TAX_CONSENT_DEDUCTION_BENEFIT_SUB_LIST[2].description}</div>
            <div>
              {Mustache.render(BIMESSAGES.TAX_CONSENT_DEDUCTION_BENEFIT_SUB_LIST[2].benefit, {
                value:
                  taxDeduction.taxDeductionHealthForYear === 0
                    ? '-'
                    : formatNumber(taxDeduction.taxDeductionHealthForYear, 2, true),
              })}
            </div>
          </li>
        </ul>
        <p
          dangerouslySetInnerHTML={{
            __html: wrapWordingToTextJustify(BIMESSAGES.TAX_CONSENT_DEDUCTION_BENEFIT_SUB_ADDITIONAL_PA),
          }}
        ></p>
        <br />
        <p>{BIMESSAGES.NOTE}:</p>
        <ul>
          <li>
            <strong>{BIMESSAGES.TAX_CONSENT_DEDUCTION_BENEFIT_SUB_NOTES[1][0]}</strong>{' '}
            {BIMESSAGES.TAX_CONSENT_DEDUCTION_BENEFIT_SUB_NOTES[1][1]}
          </li>
        </ul>
      </div>
    </div>
  )
}

const LegalConditionDisclaimer = ({ startDate, endDate }: LegalDisclaimerProps) => {
  return (
    <div id="legal-disclaimer-pa" className="legal group">
      <h3>{BIMESSAGES.LEGAL_DISCLAIMER}</h3>
      <ol className="nested-counter">
        <li>{BIMESSAGES.PRELIMINARY_OFFER_MAY_CHANGE}</li>
        <li>
          {Mustache.render(BIMESSAGES.FLEXI_HEALTH_OFFER_SALE_PERIOD, {
            startOffer: christianToThaiDate(startDate),
            endOffer: christianToThaiDate(endDate),
          })}
        </li>
      </ol>
    </div>
  )
}

const PAProfileSnapshot = ({
  displayProduct,
  riders,
  genderLabel,
  age,
  calculatedCoveragePeriod,
  calculatedPaymentPeriod,
  sumAssured,
  basicPremium,
  totalPremium,
  selectedModelFactorLabel,
  selectedModelFactorID,
  paBasicSelectedPlan,
  hasNonLevelRider,
  hasShowDisclaimerRiderAsterisk,
  summaryTableHeader,
  isNewAsteriskOfSummaryMainContract,
}: ProfileSummaryProps) => {
  return (
    <div id="profile-summary" className="snapshot group">
      <div id="profile-summary-pa">
        <h2>
          <span>
            {displayProduct.name} ({paBasicSelectedPlan})
          </span>
        </h2>
        <div className="profile">
          <div className="info gender">
            <p className="label">{BIMESSAGES.GENDER}</p>
            <p className="value">{genderLabel}</p>
          </div>
          <div className="info age">
            <p className="label">{BIMESSAGES.AGE}</p>
            <p className="value">
              {age.value} {renderUnit(age.unit)}
            </p>
          </div>
          <div className="info coverage-period">
            <p className="label">{BIMESSAGES.COVERAGE_PERIOD}</p>
            <p className="value">
              {calculatedCoveragePeriod} {MESSAGES.TIME_UNIT_YEAR}
            </p>
          </div>
          <div className="info payment-period">
            <p className="label">{BIMESSAGES.PREMIUM_PAYMENT_PERIOD}</p>
            <p className="value">
              {selectedModelFactorID === 'model-factor_5'
                ? selectedModelFactorLabel
                : calculatedPaymentPeriod + ' ' + MESSAGES.TIME_UNIT_YEAR}
            </p>
          </div>
        </div>
        <div className="coverage">
          <div className="info sum-assured">
            <p className="label">{BIMESSAGES.BASIC_PLAN_SUM_ASSURED}</p>
            <p className="value">
              {formatNumber(sumAssured)} {MESSAGES.BAHT}
            </p>
          </div>
          <div className="info model-factor">
            <p className="label">{MESSAGES.PAYMENT_MODE}</p>
            <p className="value">{selectedModelFactorLabel}</p>
          </div>
        </div>
        <div className="coverage">
          <div className="info basic-premium">
            <p className="label">{BIMESSAGES.PREMIUM}</p>
            <p className="value">
              {formatNumber(totalPremium, 2)} {MESSAGES.BAHT}
            </p>
          </div>
          <div className="info total-premium">
            <p className="label">{BIMESSAGES.TOTAL_PREMIUM_FIRST_PREMIUM}</p>
            <p className="value">
              {formatNumber(totalPremium, 2)} {MESSAGES.BAHT}
            </p>
          </div>
        </div>
      </div>
      <SummaryTable
        displayProduct={displayProduct}
        riders={riders}
        age={age}
        calculatedCoveragePeriod={calculatedCoveragePeriod}
        calculatedPaymentPeriod={calculatedPaymentPeriod}
        basicPremium={basicPremium}
        totalPremium={totalPremium}
        basicSumAssured={sumAssured}
        hasNonLevelRider={hasNonLevelRider}
        hasShowDisclaimerRiderAsterisk={hasShowDisclaimerRiderAsterisk}
        selectedModelFactorID={selectedModelFactorID}
        selectedModelFactorLabel={selectedModelFactorLabel}
        summaryTableHeader={summaryTableHeader}
        isNewAsteriskOfSummaryMainContract={isNewAsteriskOfSummaryMainContract}
      />
    </div>
  )
}

const BenefitIllustration = ({
  displayProduct,
  riders,
  genderLabel,
  age,
  calculatedCoveragePeriod,
  calculatedPaymentPeriod,
  sumAssured$,
  basicPremium$,
  totalPremium,
  totalRiderPremium,
  selectedModelFactorLabel,
  policyValue,
  canProceedToBenefitIllustration,
  paBasicSelectedPlan,
  taxDeduction,
  coveragePlanStartDate,
  legalDisclaimerEndDate,
}: BenefitIllustrationProps) => {
  if (!canProceedToBenefitIllustration) {
    return <Redirect to={PATH.PRODUCT_SELECTION} push />
  } else if (isNil(displayProduct)) return <div id="benefit-illustration-invalid" />
  else {
    return (
      <div id="benefit-illustration">
        <div className="content">
          <PAProfileSnapshot
            displayProduct={displayProduct}
            riders={riders}
            genderLabel={genderLabel}
            age={age}
            calculatedCoveragePeriod={calculatedCoveragePeriod}
            calculatedPaymentPeriod={calculatedPaymentPeriod}
            policyValue={policyValue}
            sumAssured={sumAssured$.value}
            basicPremium={basicPremium$.value}
            totalPremium={totalPremium}
            totalRiderPremium={totalRiderPremium}
            selectedModelFactorLabel={selectedModelFactorLabel}
            paBasicSelectedPlan={paBasicSelectedPlan}
          />
          <TaxConsentDeductionBenefit taxDeduction={taxDeduction} />
          <LegalConditionDisclaimer startDate={coveragePlanStartDate} endDate={legalDisclaimerEndDate} />
        </div>
        <SidebarMenu
          mainMenu={[
            {
              text: BIMESSAGES.SUMMARY_OF_MAIN_AND_ADDITIONAL_CONTRACTS,
              id: 'profile-summary-pa',
              isSub: false,
            },
            {
              text: 'ตารางแสดงผลประโยชน์',
              id: 'summary-table-pa',
              isSub: false,
            },
            {
              text: BIMESSAGES.LEGAL_DISCLAIMER,
              id: 'legal-disclaimer-pa',
              isSub: false,
            },
          ]}
        />
      </div>
    )
  }
}

export default BenefitIllustration
