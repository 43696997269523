/* istanbul ignore file */

import _ from 'lodash'
import { connect } from 'react-redux'
import { FormGroup } from 'reactstrap'
import { Dropdown } from 'common-components'
import RiderPremium from 'quick-quote/product-common/coverage-plan/components/rider/rider-premium'
import { isValidAge } from 'core/service/insured/birthdate'
import { getAge } from 'quick-quote/insured-information/selectors'
import VALUES from 'core/data-model/constants/values'
import { editRiderSumAssured, editRiderSelectedPlan } from 'quick-quote/product-common/coverage-plan/actions'
import { isAllowOptionalIHealtyUltra } from 'identity/selectors'
import RiderMessage from 'quick-quote/product-common/coverage-plan/components/rider/rider-validation-message'

export const getiHealthyUltraPackage = (
  packageRiders,
  previousPlan,
  plan,
  areaOfCover,
  allowOptionalIHealthyUltra,
  planOptional = VALUES.MHP_FULL_COVERAGE
) => {
  const newSelectedPlan = _.find(packageRiders, {
    plan,
  })

  const changedAreaOfCover =
    // $$FlowFixMe
    previousPlan !== plan ? newSelectedPlan.areasOfCover[0] : areaOfCover
  // $$FlowFixMe
  const planCode = newSelectedPlan.codes[changedAreaOfCover][planOptional]
  const sumAssured = _.get(newSelectedPlan, 'defaultSumAssured', 0)
  return {
    planCode,
    plan,
    areaOfCover: changedAreaOfCover,
    sumAssured: sumAssured,
    planOptional,
    allowOptionalIHealthyUltra: allowOptionalIHealthyUltra,
  }
}

export const getMHPPackageRiderAvaliable = (rider, insuredAge) => {
  const result = rider.packageRiders.filter((r) => {
    if (!r.allowAge) return true

    const entryAge = {
      minimum: r.allowAge.minimumValue,
      maximum: r.allowAge.maximumValue,
    }
    return isValidAge(insuredAge.value, insuredAge.unit, entryAge)
  })
  return result
}

const MHPRider = ({
  className,
  rider,
  eligibilityErrors,
  validationErrors,
  additionalInformation,
  planOptions,
  areasOfCover,
  planOptionals,
  allowOptionalIHealthyUltra,
  editHealthPackage,
  ...props
}) => (
  <FormGroup className={`${className} mhp`} {...props}>
    <div className="label">
      <strong>เลือกแผน {rider.name}</strong>
      <Dropdown
        options={planOptions}
        onChange={(value) => {
          editHealthPackage(value, rider.selectedPlan.areaOfCover, rider.selectedPlan.planOptional)
        }}
        value={rider.selectedPlan.plan}
      />
    </div>
    {allowOptionalIHealthyUltra && (
      <div className="label">
        <div className="mhp-second-line">
          <Dropdown
            options={planOptionals}
            onChange={(value) => {
              editHealthPackage(rider.selectedPlan.plan, rider.selectedPlan.areaOfCover, value)
            }}
            value={rider.selectedPlan.planOptional}
            disabled={!rider.isSelected || rider.isReadOnly}
          />
        </div>
      </div>
    )}
    <div className="options">
      <Dropdown
        options={areasOfCover}
        onChange={(value) => {
          editHealthPackage(rider.selectedPlan.plan, value)
        }}
        value={rider.selectedPlan.areaOfCover}
      />
      <div className="link">→</div>
      <RiderPremium
        isSelected={rider.isSelected && rider.isSelectable}
        isSelectable={rider.isSelectable}
        premium={rider.premium}
      />
    </div>

    <div className="label">
      <RiderMessage riderCode={rider.code} type="eligibility" messages={eligibilityErrors} />
      <RiderMessage riderCode={rider.code} type="validation" messages={validationErrors} />
      <RiderMessage riderCode={rider.code} type="additional-information" messages={additionalInformation} />
    </div>
  </FormGroup>
)

const mapStateToProps = (state) => ({
  insuredAge: getAge(state),
  allowOptionalIHealthyUltra: isAllowOptionalIHealtyUltra(state),
})

export default connect(
  mapStateToProps,
  { editRiderSumAssured, editRiderSelectedPlan },
  (stateProps, dispatchProps, ownProps) => {
    const { insuredAge, allowOptionalIHealthyUltra } = stateProps
    const { rider } = ownProps
    const { editRiderSumAssured, editRiderSelectedPlan } = dispatchProps
    const packageRidersAvaliable = getMHPPackageRiderAvaliable(rider, insuredAge)
    const planOptions = packageRidersAvaliable.map(({ plan }) => ({
      text: plan,
      value: plan,
    }))

    const selectedPackage = _.find(rider.packageRiders, {
      plan: rider.selectedPlan.plan,
    })

    const areasOfCover = _.pick(rider.areasOfCover, selectedPackage.areasOfCover)
    const planOptionals = rider.planOptionals
    return {
      ...ownProps,
      editHealthPackage: (plan, areaOfCover, planOptional) => {
        const iHealthyUltraPackage = getiHealthyUltraPackage(
          rider.packageRiders,
          selectedPackage.plan,
          plan,
          areaOfCover,
          allowOptionalIHealthyUltra,
          planOptional
        )
        editRiderSumAssured(rider.code, iHealthyUltraPackage.sumAssured)
        editRiderSelectedPlan(rider.code, iHealthyUltraPackage)
      },
      planOptions,
      areasOfCover,
      planOptionals,
      allowOptionalIHealthyUltra,
    }
  }
)(MHPRider)
