// @flow
import type { DisplayProduct } from 'core/service/display-product'

export const SELECT_PRODUCT_BUMNAN_READY = 'SELECT_PRODUCT_BUMNAN_READY'
export type SelectProductBumnanReady = {
  type: 'SELECT_PRODUCT_BUMNAN_READY',
  payload: String,
}

export const selectProductBumnanReady = (productCode: string): SelectProductBumnanReady => ({
  type: SELECT_PRODUCT_BUMNAN_READY,
  payload: productCode,
})

export const SELECT_BASIC_PLAN = 'SELECT_BASIC_PLAN'
export type SelectBasicPlan = {
  type: 'SELECT_BASIC_PLAN',
  payload: DisplayProduct,
}
export const selectBasicPlan = (product: DisplayProduct): SelectBasicPlan => ({
  type: SELECT_BASIC_PLAN,
  payload: product,
})
