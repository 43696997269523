/* istanbul ignore file */

import { connect } from 'react-redux'
import { getAge } from 'quick-quote/insured-information/selectors'
import FHRiderAdult from './fh-adult'
import FHRiderJuvenile from './fh-juvenile'

import { editRiderSumAssured, editRiderSelectedPlan } from 'quick-quote/product-common/coverage-plan/actions'

const FHRider = ({
  className,
  planOptions,
  planGroupOptions,
  rider,
  areasOfCover,
  areaOfCoverGroupPackages,
  editAdultHealthPackage,
  editJuvenileHealthPackage,
  toggleRiderModule,
  toggleRiderGroupModule,
  insuredAge,
  ...props
}) => {
  const isAdult = insuredAge.unit === 'year' && insuredAge.value > rider.juvenilePolicy.maxAge
  if (isAdult) {
    return <FHRiderAdult {...props} className={className} rider={rider} toggleRiderModule={toggleRiderModule} />
  } else {
    return (
      <FHRiderJuvenile {...props} className={className} rider={rider} toggleRiderGroupModule={toggleRiderGroupModule} />
    )
  }
}

const mapStateToProps = (state) => ({
  insuredAge: getAge(state),
})

export default connect(
  mapStateToProps,
  { editRiderSumAssured, editRiderSelectedPlan },
  (stateProps, dispatchProps, ownProps) => {
    const { insuredAge } = stateProps
    const { rider } = ownProps
    return {
      ...ownProps,
      rider,
      insuredAge,
    }
  }
)(FHRider)
