// @flow
import type { DisplayProduct } from 'core/service/display-product'

// ==== action types ====

export type Action = SelectHealthBasicPlan

export const SELECT_HEALTH_BASIC_PLAN = 'SELECT_HEALTH_BASIC_PLAN'
export type SelectHealthBasicPlan = {
  type: 'SELECT_HEALTH_BASIC_PLAN',
  payload: { planCode: string },
}
export const selectHealthBasicPlan = (planCode: string): SelectHealthBasicPlan => ({
  type: SELECT_HEALTH_BASIC_PLAN,
  payload: { planCode },
})

export const SELECT_BASIC_PLAN = 'SELECT_BASIC_PLAN'
export type SelectBasicPlan = {
  type: 'SELECT_BASIC_PLAN',
  payload: DisplayProduct,
}
export const selectBasicPlan = (product: DisplayProduct): SelectBasicPlan => ({
  type: SELECT_BASIC_PLAN,
  payload: product,
})
