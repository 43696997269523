const Checkbox = ({ id, checked, disabled, onChange, children, className }) => (
  <label className={`custom-control custom-checkbox ${className ? className : ''}`}>
    <input
      className="custom-control-input"
      id={id ? `checkbox${id}` : undefined}
      type="checkbox"
      checked={checked}
      disabled={disabled}
      onChange={onChange}
    />
    <span className="custom-control-indicator" />
    {children}
  </label>
)

export default Checkbox
