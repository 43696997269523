const Width = 'Width'
const Height = 'Height'
const Top = 'Top'
const Left = 'Left'
const scroll = 'scroll'
const client = 'client'
const EventListener = 'EventListener'
const addEventListener = 'add' + EventListener
const length = 'length'
const Math_round = Math.round

function getOthers(target, els) {
  return els.filter((el) => el !== target)
}

function registerSync(el, others) {
  el[addEventListener](
    scroll,
    (el.syn = function() {
      let scrollX = el[scroll + Left]
      let scrollY = el[scroll + Top]

      let xRate = scrollX / (el[scroll + Width] - el[client + Width])
      let yRate = scrollY / (el[scroll + Height] - el[client + Height])

      let updateX = scrollX != el.eX
      let updateY = scrollY != el.eY

      let otherEl,
        i = 0

      el.eX = scrollX
      el.eY = scrollY

      while (i < others[length]) {
        otherEl = others[i++]
        if (otherEl !== el) {
          if (
            updateX &&
            Math_round(
              otherEl[scroll + Left] -
                (scrollX = otherEl.eX = Math_round(xRate * (otherEl[scroll + Width] - otherEl[client + Width])))
            )
          ) {
            otherEl[scroll + Left] = scrollX
          }

          if (
            updateY &&
            Math_round(
              otherEl[scroll + Top] -
                (scrollY = otherEl.eY = Math_round(yRate * (otherEl[scroll + Height] - otherEl[client + Height])))
            )
          ) {
            otherEl[scroll + Top] = scrollY
          }
        }
      }
    }),
    0
  )
}

export default function(els) {
  els.forEach((el) => {
    registerSync(el, getOthers(el, els))
  })
}
