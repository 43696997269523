// @flow
import _ from 'lodash'
import type { Effect } from 'redux-saga/effects'
import { call, put, select, takeLatest, race, take } from 'redux-saga/effects'
import type { DisplayProduct } from 'core/service/display-product'
import type { Age } from 'core/data-model/insured'
import { selectBasicPlan, SELECT_PERFECT_LIFE_PLAN, type SelectPerfectLifePlan } from './actions'
import { SELECTED_PRODUCT } from 'quick-quote/product-selection/actions'
import VALUES from 'core/data-model/constants/values'
import { UPDATE_BIRTHDATE } from 'quick-quote/insured-information/actions'
import { populateAvailableRiders } from 'quick-quote/product-common/coverage-plan/sagas'
import { getDisplayProducts, getSelectedDisplayProductCode } from 'quick-quote/product-selection/selectors'
import { isValidAge } from 'core/service/insured/birthdate'
import { getAge } from 'quick-quote/insured-information/selectors'
import { editSumAssured, editRiderSumAssured } from 'quick-quote/product-common/coverage-plan/actions'

import { editPerfectLifePlan } from 'quick-quote/product-perfect-life/coverage-plan/actions'

export const _switchPerfectLifeProductByAge = (products: DisplayProduct[], age: Age) => {
  const { value, unit } = age
  return _.find(products, (product) => isValidAge(value, unit, _.get(product, 'entryAge')))
}

const _isPerfectLifeGroup = (productCode: string) => _.includes(VALUES.PERFECT_LIFE, productCode)

const _getProductGroupCode = (productCode: string) => {
  if (_isPerfectLifeGroup(productCode)) {
    return VALUES.PERFECT_LIFE
  }
}

export const switchProductByBirthDate = function*(): Generator<*, *, *> {
  const productCode = yield select(getSelectedDisplayProductCode)
  if (!_isPerfectLifeGroup(productCode)) return

  const age = yield select(getAge)
  const products = yield select(getDisplayProducts)
  const productGroupCodes = _getProductGroupCode(productCode)

  const productGroup = _.filter(products, (product) => _.includes(productGroupCodes, _.get(product, 'code')))
  const product = _switchPerfectLifeProductByAge(productGroup, age)
  if (product !== undefined && productCode !== _.get(product, 'code')) {
    yield put(selectBasicPlan(product))
    yield call(populateAvailableRiders)
  }
}

export const selectPerfectLifePlan = function*(action: SelectPerfectLifePlan): Generator<*, *, *> {
  const planCode = action.payload
  const displayProducts = yield select(getDisplayProducts)
  const productCode = yield select(getSelectedDisplayProductCode)
  const product = _.find(displayProducts, { code: productCode })
  product.selectedCampaignPlan = planCode
  yield put(editPerfectLifePlan(product))

  const perfectLifePlans = product.plans
  const selectedPerfectLifePlans = perfectLifePlans.filter((v) => v.planCode === planCode)
  const selectedPerfectLifePlan = _.get(selectedPerfectLifePlans, '0')
  yield put(editSumAssured(_.get(selectedPerfectLifePlan, 'sumAssured')))

  const { success } = yield race({
    success: take('UPDATE_RIDERS_BENEFITS'),
  })

  if (success) {
    for (let rider of _.get(selectedPerfectLifePlan, 'riders', [])) {
      yield put(editRiderSumAssured(rider.code, rider.sumAssured))
    }
  }
}

export function* watchers(): Generator<*, *, Effect[]> {
  yield [
    takeLatest([UPDATE_BIRTHDATE, SELECTED_PRODUCT], switchProductByBirthDate),
    takeLatest([SELECT_PERFECT_LIFE_PLAN], selectPerfectLifePlan),
  ]
}

export const sagas = watchers
