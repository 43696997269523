// @flow
import type { DisplayProduct } from 'core/service/display-product'

export const SELECT_MODEL_FACTOR = 'SELECT_MODEL_FACTOR'
export type SelectModelFactor = {
  type: 'SELECT_MODEL_FACTOR',
  payload: string,
}

export const selectModelFactor = (modelFactorID: string): SelectModelFactor => ({
  type: SELECT_MODEL_FACTOR,
  payload: modelFactorID,
})

export const UPDATE_SELECTED_BASIC_PLAN = 'UPDATE_SELECTED_BASIC_PLAN'
export type UpdateSelectedBasicPlan = {
  type: 'UPDATE_SELECTED_BASIC_PLAN',
  payload: string,
}
export const updateSelectedBasicPlan = (basicPlanCode: string): UpdateSelectedBasicPlan => ({
  type: UPDATE_SELECTED_BASIC_PLAN,
  payload: basicPlanCode,
})

export const SELECT_BASIC_PLAN = 'SELECT_BASIC_PLAN'
export type SelectBasicPlan = {
  type: 'SELECT_BASIC_PLAN',
  payload: DisplayProduct,
}
export const selectBasicPlan = (product: DisplayProduct): SelectBasicPlan => ({
  type: SELECT_BASIC_PLAN,
  payload: product,
})

export const VALIDATE_BASIC_PLAN = 'VALIDATE_BASIC_PLAN'
export type ValidateBasicPlan = { type: 'VALIDATE_BASIC_PLAN' }
export const validateBasicPlan = (): ValidateBasicPlan => ({ type: VALIDATE_BASIC_PLAN })
