// @flow
import type { Rider, RiderState } from 'core/data-model/rider'
import RiderWithBenefitModal from './rider-benefit-modal'
import RiderBenefitSummary from './rider-benefit-summary'
import { AgentProtectionDisclaimer } from './agent-protection-disclaimer'

type WPDRiderBenefitProps = {
  rider: Rider & RiderState,
}

const WPDRiderBenefit = ({ rider }: WPDRiderBenefitProps) => {
  const description = `ผลประโยชน์${rider.description}`
  const title = `${description} (${rider.name})`
  const benefitTable = (
    <div className="details-table">
      <p>
        กรณีผู้ชำระเบี้ยประกันภัยเสียชีวิต บริษัทจะยกเว้นเบี้ยประกันภัยของสัญญาหลักในอนาคต
        จนกว่าจะครบกำหนดระยะเวลาคุ้มครองของสัญญาเพิ่มเติมนี้
      </p>
    </div>
  )
  const benefitRider: Rider & RiderState = { ...rider, description }
  return (
    <RiderWithBenefitModal
      id="wpd-rider-benefit"
      className="rider wpd-rider"
      title={title}
      modalChildren={benefitTable}
    >
      <RiderBenefitSummary rider={benefitRider} />
      {benefitTable}
      <AgentProtectionDisclaimer rider={rider} />
    </RiderWithBenefitModal>
  )
}

export default WPDRiderBenefit
