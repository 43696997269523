import styled from 'styled-components'
import { Flex, Box } from 'rebass'
import { getToggles } from 'quick-quote/feature-toggles'
import VALUES from 'core/data-model/constants/values'

const OldCard = styled(Flex)`
  background: white;
  border: 1px solid ${({ theme }) => theme.variables['$color-mercury']};
  & + & {
    border-top: none;
  }
  ${({ disabled }) => disabled && `pointer-events: none;opacity: 0.6;`}}
`

const NewCard = styled(Flex)`
  background: white;
  border: 1px solid ${({ theme }) => theme.variables['$color-mercury']};
  & + & {
    border-top: none;
  }
  ${({ disabled }) => disabled && `pointer-events: none;opacity: 0.6;`}}
`

const Card = getToggles().ENABLE_QUICKLINK_PAYMENT ? NewCard : OldCard

export const BaseDetailBox = styled(({ label, children, ...props }) => (
  <Box {...props}>
    {label && <label>{label}:</label>} {children}
  </Box>
))`
  margin: 0 10px 0 0;
  font-size: 1rem;
  label {
    margin: 0;
    color: ${({ theme }) => theme.variables['$color-dusty-gray']};
  }
`

export const RiderTags = styled(({ className, riders, lumpSumTopUp = 0 }) => (
  <div className={className}>
    {riders.map(({ name, selectedPlan, code }, index) => (
      <RiderTag key={index + name}>{code === VALUES.MRTAAP ? selectedPlan.planCode : name}</RiderTag>
    ))}
    {lumpSumTopUp > 0 ? <RiderTag>{'LSTU'}</RiderTag> : null}
  </div>
))`
  & > span {
    display: inline-block;
    padding: 0 7px;
    & + span {
      margin-left: 5px;
    }
  }
`

export const RiderTag = styled.span`
  font-size: ${({ theme }) => theme.variables['$font-size-navigation']};
  line-height: 17px;
  border: 1px solid ${({ theme }) => theme.variables['$color-dusty-gray']};
`

export const NameBox = styled(Box)`
  font-size: 1.125rem;
  margin: 0 10px 0 0;
  font-weight: bold;
  line-height: 1;
  flex: 1;
`

export default Card
