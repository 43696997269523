// @flow
import _ from 'lodash'
import { formatNumber } from 'core/service/lib/number-format'
import type { ProductBenefitProps } from 'quick-quote/product-common/benefit-illustration/components/product-benefits/types.js'
import Mustache from 'mustache'
import MESSAGES from 'core/data-model/constants/bi-messages'

const ProductBenefitsSummary = ({
  displayProduct,
  sumAssured,
  basicPremium,
  calculatedPaymentPeriod,
  calculatedCoveragePeriod,
  benefitSummaryHeader,
}: ProductBenefitProps) => {
  const productName = _.isFunction(benefitSummaryHeader)
    ? // $$FlowFixMe
      benefitSummaryHeader({
        displayProduct,
        sumAssured,
        basicPremium,
        calculatedPaymentPeriod,
        calculatedCoveragePeriod,
      })
    : benefitSummaryHeader
  return (
    <div id="product-benefits-summary" className="product">
      <h3 className="name">
        {Mustache.render(MESSAGES.BASIC_PLAN_DETAILS, {
          name: productName,
          code: displayProduct.basicPlanCode,
        })}
      </h3>
      <div className="info-list">
        <div id="benefits-coverage-period" className="info">
          <p className="label">{MESSAGES.COVERAGE_PERIOD}</p>
          <p className="value">{MESSAGES.COVERAGE_PERIOD_BUMNAN_READY_SUFFIX}</p>
        </div>
        <div id="benefits-payment-period" className="info">
          <p className="label">{MESSAGES.PAYMENT_PERIOD}</p>
          <p className="value">{calculatedPaymentPeriod} ปี</p>
        </div>
        <div id="benefits-sum-assured" className="info">
          <p className="label">{MESSAGES.BASIC_PLAN_SUM_ASSURED}</p>
          <p className="value">{formatNumber(sumAssured)} บาท</p>
        </div>
        <div id="benefits-basic-premium" className="info">
          <p className="label">{MESSAGES.BASIC_PREMIUM}</p>
          <p className="value">{formatNumber(basicPremium, 2)} บาท</p>
        </div>
      </div>
    </div>
  )
}

export default ProductBenefitsSummary
