//@flow

import { UnderlineWord } from 'core/service/pdf-generation/products/common/components/general'

const LEFT_MARGIN = 10
const TOP_MARGIN = 10

const header = {
  marginBottom: 5,
  bold: true,
}

export const tpdudrBenefitSection = (riderBenefitData) => {
  const riderBenefit = riderBenefitData.riderBenefitData.benefits
  const riderDiseases = riderBenefit.diseases
  return [
    {
      text: riderBenefit.title,
      style: header,
      marginTop: TOP_MARGIN,
    },
    {
      marginLeft: LEFT_MARGIN,
      stack: [{ text: riderBenefit.description[0] }, { text: riderBenefit.description[1] }],
    },
    {
      marginLeft: LEFT_MARGIN,
      text: `${riderDiseases[0].title} ${riderDiseases[0].rate}`,
    },
    {
      marginLeft: LEFT_MARGIN,
      stack: [{ text: riderBenefit.description_2[0] }, { text: riderBenefit.description_2[1] }],
    },
  ]
}

export const tpdudrNoticeSection = (riderBenefitData) => {
  const riderNotice = riderBenefitData.riderBenefitData.notice
  const notices = riderNotice.generic

  return [
    {
      text: riderNotice.title,
      style: header,
      marginTop: TOP_MARGIN,
    },
    {
      marginLeft: LEFT_MARGIN,
      stack: [
        {
          ul: notices.map((item) => ({
            text: item.text[0],
          })),
        },
      ],
    },
  ]
}

export const tpdudrDisclaimerSection = (riderBenefitData) => {
  const riderDisclaimer = riderBenefitData.riderBenefitData.disclaimer

  return [
    {
      text: UnderlineWord(riderDisclaimer.title, 'บางส่วน'),
      style: header,
      marginTop: TOP_MARGIN,
    },
    {
      text: riderDisclaimer.description[0],
      marginLeft: LEFT_MARGIN,
    },
    {
      ol: [
        {
          text: `${riderDisclaimer.list[0].text}`,
        },
        {
          text: `${riderDisclaimer.list[1].text}`,
        },
        {
          text: `${riderDisclaimer.list[2].text}`,
        },
        {
          text: `${riderDisclaimer.list[3].text}`,
        },
        {
          text: `${riderDisclaimer.list[4].text}`,
        },
        {
          text: `${riderDisclaimer.list[5].text}`,
        },
        {
          text: `${riderDisclaimer.list[6].text}`,
        },
        [
          {
            text: `${riderDisclaimer.list[7].text}`,
          },
          {
            separator: ['(', ')'],
            ol: [
              {
                text: `${riderDisclaimer.list[7].list[0].text}`,
              },
              {
                text: `${riderDisclaimer.list[7].list[1].text}`,
              },
              {
                text: `${riderDisclaimer.list[7].list[2].text}`,
              },
            ],
          },
        ],
        {
          text: `${riderDisclaimer.list[8].text}`,
        },
      ],
    },
  ]
}
