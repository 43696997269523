// @flow
import _ from 'lodash'

import { dashedOutOrWithPrecision, PolicyValueRow, PolicyValueRowICare } from './policy-value-row'
import MESSAGES from 'core/data-model/constants/bi-messages'
import CONSTANTS from 'core/data-model/constants/messages'
import VALUES from 'core/data-model/constants/values'
import type { PolicyValueTableProps } from 'core/service/benefit-illustration/policy-value'
import { _getMessageUnderiShieldPolicyValueTable } from 'core/service/benefit-illustration/policy-value'
import Mustache from 'mustache'
import { getToggles } from 'quick-quote/feature-toggles'

export const IShieldMessageUnderTable = ({ policyValue, basicPlan }: PolicyValueTableProps) => {
  const message = _getMessageUnderiShieldPolicyValueTable({ policyValue, basicPlan })
  if (message) {
    return <div className="ishield-conditional-info">{message}</div>
  } else {
    return null
  }
}

const PolicyValueTable = (props: PolicyValueTableProps) => {
  return (
    <div className="policy group" id="policy-value">
      <h3>{MESSAGES.POLICY_VALUE_TABLE}</h3>

      {renderPolicyValueTableForWholeLife(props)}
    </div>
  )
}

const PolicyTable = ({ className, children }: { className?: string, children?: React.Node }) => (
  <table className={`policy-table${className ? ` ${className}` : ''}`} id="policy-value-table">
    {children}
  </table>
)

// $$FlowFixMe
export const PolicyWithOutCashDropICare = ({
  yearEndPolicyValues,
  lastYearEndPolicy,
  subtotalAnnualBasicPremium,
  subtotalAnnualRiderPremium,
  grandTotalPremium,
  subtotalCashDrop,
  subtotalLowSavingCashDrop,
  subtotalMediumSavingCashDrop,
  subtotalHighSavingCashDrop,
}) => (
  <PolicyTable className="with-cash-drop">
    <thead>
      <tr>
        <td rowSpan={2} width="5%">
          {CONSTANTS.AGE}
        </td>
        <td className="nowrap" rowSpan={2} width="5%">
          {MESSAGES.POLICY_VALUE_TABLE_INSURANCE_YEAR}
        </td>
        <td colSpan={3}>{MESSAGES.PREMIUM_PER_YEAR}</td>
        <td colSpan={3}>{MESSAGES.END_OF_YEAR_POLICY_WITH_BRACKET}</td>
      </tr>
      <tr>
        <td width="10%">{MESSAGES.BASIC_PLAN}</td>
        <td width="10%">{MESSAGES.RIDER}</td>
        <td width="10%">{MESSAGES.TOTAL}</td>
        <td width="10%">{MESSAGES.CUMULATIVE_PREMIUM}</td>
        <td width="10%">{MESSAGES.POLICY_VALUE_TABLE_CASH_SURRENDER_VALUE}</td>
        <td width="10%">{MESSAGES.POLICY_VALUE_TABLE_DEATH_BENEFIT}</td>
      </tr>
    </thead>
    <tbody>
      {yearEndPolicyValues.map((policyValue, index) => (
        <PolicyValueRowICare
          key={`policy-value-row-${policyValue.age.value}`}
          age={policyValue.age}
          yearEndPolicy={policyValue.yearEndPolicy}
          cumulativeBasicPremium={policyValue.cumulativeBasicPremium}
          annualBasicPremium={policyValue.annualBasicPremium}
          annualRiderPremium={policyValue.annualRiderPremium}
          annualTotalPremium={policyValue.annualTotalPremium}
          surrenderCash={policyValue.surrenderCash}
          deathBenefit={policyValue.deathBenefit}
          cumulativeDeathBenefit={policyValue.cumulativeDeathBenefit}
          basicSumAssured={policyValue.basicSumAssured}
        />
      ))}
      <tr className="total" id={`policy-value-row-${lastYearEndPolicy}`} key={`policy-value-row-${lastYearEndPolicy}`}>
        <td />
        <td />
        <td>{dashedOutOrWithPrecision(subtotalAnnualBasicPremium, 2)}</td>
        <td>{dashedOutOrWithPrecision(subtotalAnnualRiderPremium, 2)}</td>
        <td>{dashedOutOrWithPrecision(grandTotalPremium, 2)}</td>
        <td />
        <td />
        <td />
      </tr>
    </tbody>
  </PolicyTable>
)

// $$FlowFixMe
export const PolicyWithoutCashDrop = ({
  yearEndPolicyValues,
  lastYearEndPolicy,
  subtotalAnnualBasicPremium,
  subtotalAnnualRiderPremium,
  grandTotalPremium,
}) => (
  <PolicyTable>
    <thead>
      <tr>
        <td width="5%" rowSpan={2}>
          {CONSTANTS.AGE}
        </td>
        <td className="nowrap" width="5%" rowSpan={2}>
          {MESSAGES.END_OF_THE_YEAR}
        </td>
        <td width="15%" rowSpan={2}>
          {MESSAGES.CUMULATIVE_PREMIUM}
        </td>
        <td width="15%" colSpan={3}>
          {MESSAGES.PREMIUM_PER_YEAR}
        </td>
        <td width="15%" rowSpan={2}>
          {MESSAGES.DEATH_BENEFIT}
        </td>
        <td width="15%" rowSpan={2}>
          {MESSAGES.CASH_SURRENDER_VALUE}**
        </td>
      </tr>
      <tr>
        <td width="15%">{MESSAGES.BASIC_PLAN}</td>
        <td width="15%">{MESSAGES.RIDER}</td>
        <td width="15%">{MESSAGES.TOTAL}</td>
      </tr>
    </thead>
    <tbody>
      {yearEndPolicyValues.map((policyValue) => (
        <PolicyValueRow
          id="policy-value-row"
          key={`policy-value-row-${policyValue.age.value}`}
          age={policyValue.age}
          yearEndPolicy={policyValue.yearEndPolicy}
          cumulativeBasicPremium={policyValue.cumulativeBasicPremium}
          annualBasicPremium={policyValue.annualBasicPremium}
          annualRiderPremium={policyValue.annualRiderPremium}
          annualTotalPremium={policyValue.annualTotalPremium}
          surrenderCash={policyValue.surrenderCash}
          deathBenefit={policyValue.deathBenefit}
          basicSumAssured={policyValue.basicSumAssured}
        />
      ))}
      <tr className="total" id={`policy-value-row-${lastYearEndPolicy}`} key={`policy-value-row-${lastYearEndPolicy}`}>
        <td />
        <td />
        <td />
        <td>{dashedOutOrWithPrecision(subtotalAnnualBasicPremium, 2)}</td>
        <td>{dashedOutOrWithPrecision(subtotalAnnualRiderPremium, 2)}</td>
        <td>{dashedOutOrWithPrecision(grandTotalPremium, 2)}</td>
        <td />
        <td />
      </tr>
    </tbody>
  </PolicyTable>
)

// $$FlowFixMe
export const PolicyWithCashDrop = ({
  yearEndPolicyValues,
  lastYearEndPolicy,
  subtotalAnnualBasicPremium,
  subtotalAnnualRiderPremium,
  grandTotalPremium,
  subtotalCashDrop,
  subtotalLowSavingCashDrop,
  subtotalMediumSavingCashDrop,
  subtotalHighSavingCashDrop,
}) => (
  <PolicyTable className="with-cash-drop">
    <thead>
      <tr>
        <td rowSpan={3} width="5%">
          {CONSTANTS.AGE}
        </td>
        <td className="nowrap" rowSpan={3} width="5%">
          {MESSAGES.END_OF_THE_YEAR}
        </td>
        <td width="10%" rowSpan={3}>
          {MESSAGES.CUMULATIVE_PREMIUM}
        </td>
        <td colSpan={3}>{MESSAGES.PREMIUM_PER_YEAR}</td>

        <td colSpan={4}>{MESSAGES.CASH_DROP_VALUE}</td>

        <td rowSpan={3} width="10%">
          {MESSAGES.DEATH_BENEFIT}
        </td>
        <td rowSpan={3} width="10%">
          {MESSAGES.CASH_SURRENDER_VALUE}**
        </td>
      </tr>
      <tr>
        <td rowSpan={3} width="10%">
          {MESSAGES.BASIC_PLAN}
        </td>
        <td rowSpan={3} width="10%">
          {MESSAGES.RIDER}
        </td>
        <td rowSpan={3} width="10%">
          {MESSAGES.TOTAL}
        </td>
        <td rowSpan={2}>{MESSAGES.INCASE_OF_CASH}</td>
        <td colSpan={3}>{MESSAGES.INCASE_OF_SAVING_WITH_COMPANY}</td>
      </tr>
      <tr>
        <td>
          {MESSAGES.LOW_LEVEL}
          <br />
          {Mustache.render(MESSAGES.PERCENTS_PER_YEAR, { percent: '0.5' })}
        </td>
        <td>
          {MESSAGES.MEDIUM_LEVEL}
          <br />
          {Mustache.render(MESSAGES.PERCENTS_PER_YEAR, { percent: '1.5' })}
        </td>
        <td>
          {MESSAGES.HIGH_LEVEL}
          <br />
          {Mustache.render(MESSAGES.PERCENTS_PER_YEAR, { percent: '2.5' })}
        </td>
      </tr>
    </thead>
    <tbody>
      {yearEndPolicyValues.map((policyValue, index) => (
        <PolicyValueRow
          key={`policy-value-row-${policyValue.age.value}`}
          age={policyValue.age}
          yearEndPolicy={policyValue.yearEndPolicy}
          cumulativeBasicPremium={policyValue.cumulativeBasicPremium}
          annualBasicPremium={policyValue.annualBasicPremium}
          annualRiderPremium={policyValue.annualRiderPremium}
          annualTotalPremium={policyValue.annualTotalPremium}
          surrenderCash={policyValue.surrenderCash}
          deathBenefit={policyValue.deathBenefit}
          basicSumAssured={policyValue.basicSumAssured}
        >
          <td>
            {// $$FlowFixMe
            dashedOutOrWithPrecision(policyValue.cashDrop, 0, true)}
          </td>
          <td>
            {// $$FlowFixMe
            dashedOutOrWithPrecision(policyValue.lowSavingCashDrop, 0, true)}
          </td>
          <td>
            {// $$FlowFixMe
            dashedOutOrWithPrecision(policyValue.mediumSavingCashDrop, 0, true)}
          </td>
          <td>
            {// $$FlowFixMe
            dashedOutOrWithPrecision(policyValue.highSavingCashDrop, 0, true)}
          </td>
        </PolicyValueRow>
      ))}
      <tr className="total" id={`policy-value-row-${lastYearEndPolicy}`} key={`policy-value-row-${lastYearEndPolicy}`}>
        <td />
        <td />
        <td />
        <td>{dashedOutOrWithPrecision(subtotalAnnualBasicPremium, 2)}</td>
        <td>{dashedOutOrWithPrecision(subtotalAnnualRiderPremium, 2)}</td>
        <td>{dashedOutOrWithPrecision(grandTotalPremium, 2)}</td>
        <td>{dashedOutOrWithPrecision(subtotalCashDrop, 0, true)}</td>
        <td>{dashedOutOrWithPrecision(subtotalLowSavingCashDrop, 0, true)}</td>
        <td>{dashedOutOrWithPrecision(subtotalMediumSavingCashDrop, 0, true)}</td>
        <td>{dashedOutOrWithPrecision(subtotalHighSavingCashDrop, 0, true)}</td>
        <td />
        <td />
      </tr>
    </tbody>
  </PolicyTable>
)

export const PolicyValueContent = ({ basicPlan, ...props }) => {
  switch (basicPlan.code) {
    case VALUES['25PL']:
    case VALUES['12PL']:
      return <PolicyWithCashDrop {...props} />
    case VALUES['ICARE']:
      if (getToggles().ENABLE_ICARE_REPRICING === true) {
        return <PolicyWithOutCashDropICare {...props} />
      } else {
        return <PolicyWithoutCashDrop {...props} />
      }
    default:
      return <PolicyWithoutCashDrop {...props} />
  }
}

const renderPolicyValueTableForWholeLife = ({ policyValue, basicPlan }: PolicyValueTableProps) => {
  const lastYearEndPolicy = _.get(_.last(policyValue.yearEndPolicyValues), 'yearEndPolicy', 0) + 1

  // $$FlowFixMe
  const policyValueTableProps = { policyValue, basicPlan }

  return (
    <div>
      <div className="table-wrap">
        <PolicyValueContent lastYearEndPolicy={lastYearEndPolicy} basicPlan={basicPlan} {...policyValue} />
      </div>

      <IShieldMessageUnderTable {...policyValueTableProps} />

      {getToggles().ENABLE_ICARE_REPRICING === true && basicPlan.code === VALUES.ICARE ? (
        <div className="disclaimer">
          <p>{MESSAGES.NOTE}:</p>
          <ul>
            <li>{MESSAGES.POLICY_VALUE_TABLE_DISCLAIMER_ONE}</li>
            <li>{MESSAGES.POLICY_VALUE_TABLE_DISCLAIMER_TWO}</li>
            <li>{MESSAGES.POLICY_VALUE_TABLE_ICARE_DISCLAIMER_THREE}</li>
            <li>{MESSAGES.POLICY_VALUE_TABLE_DISCLAIMER_FOUR}</li>
          </ul>
        </div>
      ) : (
        <div className="disclaimer">
          <p>**{MESSAGES.NOTE}:</p>
          <ul>
            <li>{MESSAGES.POLICY_VALUE_TABLE_NOTE_1}</li>
            <li>{MESSAGES.POLICY_VALUE_TABLE_NOTE_2}</li>
          </ul>
        </div>
      )}
    </div>
  )
}

export default PolicyValueTable
