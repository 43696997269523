//@flow
import type { FundState } from 'core/service/investment'
import {
  allocateLstuIndependently,
  allocateLstuSameAsRpp,
  calculateLstuTotalLstuFundAllocation,
  lstuAcceptForeignInvestment,
  lstuAcceptHigherRiskInvestment,
  resetLstuFundAllocations,
  toggleLstuFundAllocation,
  resetLstuAcceptFundAllocation,
} from 'quick-quote/product-investment/fund-allocation/lstu/actions'
import {
  getLstuAcceptFundAllocation,
  getLstuForeignConsent,
  getLstuFundAllocations,
  getLstuOverRiskConsent,
  getTotalAllocations,
} from 'quick-quote/product-investment/fund-allocation/lstu/selectors'
import { getAvailableFunds, hasValidEntryComponent } from 'quick-quote/product-investment/fund-allocation/selectors'
import {
  getAllowForeignInvestment,
  getAllowHigherRiskInvestment,
  isLowRiskEvaluated,
  getExchangeQuestion,
} from 'quick-quote/product-investment/risk-questionnaire/selectors'
import type { AppState } from 'quick-quote/reducers'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import type { Dispatch } from 'redux'
import { bindActionCreators } from 'redux'
import { getNavTabList } from '../../../components/navigations/tabs'
import { FundAllocation } from './fund-allocation'
import { getLstuFundSummary, getSelectedAllocationOption } from './selectors'

type FundAllocationProps = { funds: FundState[] }

const mapStateToProps = (state: AppState, ownProps: Object): FundAllocationProps => ({
  funds: getAvailableFunds(state),
  allocations: getLstuFundAllocations(state),
  totalAllocation: getTotalAllocations(state),
  allowHigherRiskInvestment: getAllowHigherRiskInvestment(state),
  allowForeignInvestment: getAllowForeignInvestment(state),
  isLowRiskEvaluated: isLowRiskEvaluated(state),
  hasValidEntryComponent: hasValidEntryComponent(state),
  navTabList: getNavTabList({ state, location: ownProps.location }),
  chosenOption: getSelectedAllocationOption(state),
  hasAcceptedFundAllocation: getLstuAcceptFundAllocation(state),
  fundSummary: getLstuFundSummary(state),
  isAcceptedOverRisk: getLstuOverRiskConsent(state),
  isAcceptedForeign: getLstuForeignConsent(state),
  foreignExchangingQuestion: getExchangeQuestion(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  onAllocateSameAsRPP: bindActionCreators(allocateLstuSameAsRpp, dispatch),
  onAllocateIndependently: bindActionCreators(allocateLstuIndependently, dispatch),
  toggleFundAllocation: bindActionCreators(toggleLstuFundAllocation, dispatch),
  calculateTotalFundAllocation: bindActionCreators(calculateLstuTotalLstuFundAllocation, dispatch),
  resetFundAllocations: bindActionCreators(resetLstuFundAllocations, dispatch),
  onAcceptHigherRiskInvestment: bindActionCreators(lstuAcceptHigherRiskInvestment, dispatch),
  onAcceptForeignInvestment: bindActionCreators(lstuAcceptForeignInvestment, dispatch),
  resetLstuAcceptFundAllocation: bindActionCreators(resetLstuAcceptFundAllocation, dispatch),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FundAllocation))
