// @flow
import _ from 'lodash'
import { flow, get, find, isEmpty, negate, map, filter } from 'lodash/fp'
import { createSelector } from 'reselect'

import values from 'core/data-model/constants/values'
import type { AppState } from 'quick-quote/reducers'
import type { Option } from 'common-components/dropdown'
import type { Rider, RiderOptionValue } from 'core/data-model/rider'

export const getiCareSelectedPlanCode = (state: AppState): string =>
  _.get(state, 'coveragePlan.riders["ESCI"].selectedPlan.planCode')

export const getAllBasicPlanOptions = (state: AppState): RiderOptionValue[] =>
  _.get(state, 'coveragePlan.riders["ESCI"].options.0.values')

export const getESCIRider = (state: AppState): Rider => get('coveragePlan.riders["ESCI"]')(state)

export const getDiscountStatus = createSelector(
  getiCareSelectedPlanCode,
  getESCIRider,
  (planCode: string, rider: Rider): boolean => {
    return flow(
      get('options.0.values'),
      find({ planCode: planCode, group: values.RIDER_OPTION_GROUP_DISCOUNT }),
      negate(isEmpty)
    )(rider)
  }
)

export const getSelectedRiderPlan = createSelector(
  getiCareSelectedPlanCode,
  getAllBasicPlanOptions,
  (planCode: string, options: RiderOptionValue[]) => _.find(options, { planCode })
)

export const getBasicPlanOptions = createSelector(
  (appState: AppState) => getDiscountStatus(appState),
  getESCIRider,
  (isDiscount: boolean, rider: Rider): Option[] => {
    return flow(
      get('options.0.values'),
      filter(['group', isDiscount ? values.RIDER_OPTION_GROUP_DISCOUNT : values.RIDER_OPTION_GROUP_NO_DISCOUNT]),
      map((p) => ({
        value: p.planCode,
        text: `แผน ${p.text}`,
      }))
    )(rider)
  }
)

export const getSameValuePlanCode = createSelector(
  getAllBasicPlanOptions,
  getiCareSelectedPlanCode,
  getDiscountStatus,
  getSelectedRiderPlan,
  (
    options: RiderOptionValue[],
    planCode: string,
    isDiscountBeforeToggle: boolean,
    selectedOption: RiderOptionValue
  ): ?string =>
    flow(
      find({
        group: isDiscountBeforeToggle ? values.RIDER_OPTION_GROUP_NO_DISCOUNT : values.RIDER_OPTION_GROUP_DISCOUNT,
        value: selectedOption.value,
      }),
      get('planCode')
    )(options)
)

export const getICareSelectedPlanName = (state: AppState): string =>
  _.get(state, 'coveragePlan.riders["ESCI"].selectedPlan.plan', '')
export const getICareSelectedPlanCode = (state: AppState): string =>
  _.get(state, 'coveragePlan.riders["ESCI"].selectedPlan.planCode', '')
