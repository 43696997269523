// @flow

import type { Fund, FundAllocationToggleItem } from 'core/data-model/investment/types'
import type { InsuredProps } from 'core/service/pdf-generation/products/common/types'
import _ from 'lodash'
import MESSAGES from 'core/data-model/constants/bi-messages'
import { getInsuredFullName } from 'core/service/pdf-generation/utils/insured'
import { ColorfulHeader, WithPadding } from '../../../utils/table-utils'
import VALUE from 'core/data-model/constants/values'
import { Bold } from 'core/service/pdf-generation/products/common/components/general'
import type { TableBody, TableRow } from 'core/service/pdf-generation/utils/table-utils'

const riskLevelEvaluationText = (riskLevelDigestable: boolean): string =>
  riskLevelDigestable ? MESSAGES.YES : MESSAGES.NO

const displayFundProperties = (allocations: FundAllocationToggleItem[], insuredRiskLevel: number) => (
  fund: Fund
): TableRow => {
  const defaultAllocation = {
    fundAllocation: 0,
  }
  const fundAllocation = _.find(allocations, { code: fund.code })
  const insuredAllocation = !!fundAllocation ? fundAllocation : defaultAllocation
  const customerRiskLevel = !!fundAllocation ? `${insuredRiskLevel}` : ''
  const fundRiskLevel = !!fundAllocation ? `${fund.riskLevel}` : ''
  const riskLevelDigestable = !!fundAllocation ? riskLevelEvaluationText(fundAllocation.riskLevelDigestable) : ''
  return [
    `${fund.thaiName} [${fund.code}]`,
    fund.fundType.thaiName,
    { alignment: 'center', text: fund.biFundHouse ? fund.biFundHouse : fund.fundHouse },
    { alignment: 'center', text: `${insuredAllocation.fundAllocation}%` },
    { alignment: 'center', text: `${fundRiskLevel}` },
    { alignment: 'center', text: customerRiskLevel },
    { alignment: 'center', text: riskLevelDigestable },
  ]
}

export const fundRiskTableData = (
  funds: Fund[],
  allocations: FundAllocationToggleItem[],
  insuredRiskLevel: number,
  isShowOnlySelectedFund: Boolean
): TableBody => {
  const displayFundAllocationProperties = displayFundProperties(allocations, insuredRiskLevel)
  const fillColor = VALUE.PURPLE
  const emptyCell = { text: '', fillColor }
  const footerRow = [
    { text: MESSAGES.FUND_RISK_TOTAL, colSpan: 3, fillColor },
    emptyCell,
    emptyCell,
    { text: '100%', alignment: 'center', fillColor },
    emptyCell,
    emptyCell,
    emptyCell,
  ]
  const sortedFunds = _.orderBy(funds, ['fundType.category.categoryOrder', 'riskLevel', 'code'], ['asc', 'desc', 'asc'])
  const selectedFundCodes = allocations.map((item) => item.code)
  const selectedFunds = sortedFunds.filter((item) => selectedFundCodes.includes(item.code))
  const displayedFunds = isShowOnlySelectedFund ? selectedFunds : sortedFunds

  return displayedFunds.map(displayFundAllocationProperties).concat([footerRow])
}

const fundRiskTableComponent = (
  funds: Fund[],
  allocations: FundAllocationToggleItem[],
  insuredRiskLevel: number,
  isShowOnlySelectedFund: Boolean
) => {
  const headers = [
    [
      ColorfulHeader(MESSAGES.FUND_RISK_TABLE_ALLOCATIONS_HEADER, VALUE.EMERALD_GREEN, 4),
      ColorfulHeader(MESSAGES.FUND_RISK_TABLE_RISK_HEADER, VALUE.SAFFRON, 3),
    ],
    [
      ColorfulHeader(MESSAGES.FUND_RISK_TABLE_FUND_NAME, VALUE.EGG_WHITE),
      ColorfulHeader(MESSAGES.FUND_RISK_TABLE_FUND_TYPE, VALUE.EGG_WHITE),
      ColorfulHeader(MESSAGES.FUND_RISK_TABLE_FUND_HOUSE, VALUE.EGG_WHITE),
      ColorfulHeader(MESSAGES.FUND_RISK_TABLE_FUND_ALLOCATION, VALUE.EGG_WHITE),
      ColorfulHeader(MESSAGES.FUND_RISK_TABLE_FUND_RISK, VALUE.EGG_WHITE),
      ColorfulHeader(MESSAGES.FUND_RISK_TABLE_INSURED_RISK, VALUE.EGG_WHITE),
      ColorfulHeader(MESSAGES.FUND_RISK_TABLE_RISK_LIMIT, VALUE.EGG_WHITE),
    ],
  ]
  return {
    table: {
      widths: ['38%', '28%', '6%', '6%', '7%', '7%', '8%'],
      body: headers
        .map((h) => _.flattenDeep(h))
        .concat(fundRiskTableData(funds, allocations, insuredRiskLevel, isShowOnlySelectedFund)),
    },
    layout: {
      vLineWidth: (i, node) => (_.includes([node.table.widths.length, 0, 4], i) ? 1 : 0),
    },
    fontSize: 9,
  }
}

const fundRiskSignTable = (insured: InsuredProps) => {
  const insuredFullName = getInsuredFullName(insured)
  return {
    table: {
      widths: ['15%', '45% '],
      body: [
        [{ text: Bold(MESSAGES.FUND_RISK_SIGNED_BY), alignment: 'center' }, { text: '' }],
        [{ text: '' }, { text: `(${insuredFullName})`, alignment: 'center', border: [false, true, false, false] }],
      ],
    },
    layout: {
      defaultBorder: false,
    },
    alignment: 'center',
    margin: [30, 0],
  }
}

const fundRiskSignComponent = (insured: InsuredProps) => [
  {
    text: Bold(WithPadding(MESSAGES.FUND_RISK_SIGN_MESSAGE)),
  },
  fundRiskSignTable(insured),
]

export const FundRiskTableSection = (
  funds: Fund[],
  allocations: FundAllocationToggleItem[],
  insuredRiskLevel: number,
  insured: InsuredProps,
  isShowOnlySelectedFund: Boolean
): TableBody => {
  return ColorfulHeader(MESSAGES.FUND_RISK_TABLE_HEADER).concat([
    [fundRiskTableComponent(funds, allocations, insuredRiskLevel, isShowOnlySelectedFund)],
    [fundRiskSignComponent(insured)],
  ])
}
