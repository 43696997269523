//@flow
import type { DisplayProduct, DisplayProductQuery } from './types'
import type { BasicPlan, RawBasicPlan } from 'core/data-model/basic-plan'
import { listActiveBasicPlans, getBasicPlan, getViewConfiguration } from 'core/data-model/basic-plan'
import type { Campaign } from 'core/data-model/campaign'
import { listActiveCampaigns, getCampaign } from 'core/data-model/campaign'
import type { Rider } from 'core/data-model/rider'
import type { PromiseFunction } from 'core/data'
import type { ViewConfiguration } from 'core/data-model'
import { convertEntryAge } from 'core/service/insured/birthdate'
import _ from 'lodash'
import moment from 'moment'
import VALUE from 'core/data-model/constants/values'
import { getToggles } from 'quick-quote/feature-toggles'

const getRiderCode = (rider: Rider) => rider.code

export const transformProductToDisplayProduct = (product: RawBasicPlan): DisplayProduct => {
  switch (product.category) {
    case VALUE.LOAN:
      return {
        code: product.productCode,
        name: product.name,
        nameThai: product.nameThai,
        displayName: product.displayName,
        basicPlanCode: product.productCode,
        basicPlanName: product.name,
        category: VALUE.LOAN,
        description: product.description,
        image: product.image,
        type: product.type,
        benefits: product.benefits,
        readOnlyFields: product.readOnlyFields || {},
        riders: product.riders ? product.riders.map((riderCode) => riderCode.replace('rider_', '')) : [],
        coveragePeriod: product.coveragePeriod,
        paymentPeriod: product.paymentPeriod,
        modelFactors: product.modelFactors,
        optionalRiders: [],
        requiredRiders: [],
        licenses: product.licenses,
        planCodeSchema: product.planCodeSchema ? product.planCodeSchema : [],
        aura: product.aura,
        ribbonMessage: product.ribbonMessage || '',
        payerSelection: product.payerSelection || [],
        allowUserType: product.allowUserType || [],
        defaultSumAssured: product.defaultSumAssured || '',
        basicPlanCodeOption: product.basicPlanCodeOption || [],
        brochureLink: product.brochureLink || {},
      }
    case VALUE.INVESTMENT:
      // $FlowFixMe
      return {
        code: product.productCode,
        name: product.name,
        nameThai: product.nameThai,
        saleSheet: product.saleSheet,
        displayName: product.displayName,
        fullNameThai: product.fullNameThai,
        displayNameLanguage: product.displayNameLanguage ? product.displayNameLanguage : 'ENG',
        basicPlanCode: product.productCode,
        basicPlanName: product.name,
        category: product.category,
        description: product.description,
        image: product.image,
        type: product.type,
        benefits: product.benefits,
        readOnlyFields: product.readOnlyFields || {},
        riders: product.riders ? product.riders.map((riderCode) => riderCode.replace('rider_', '')) : [],
        coveragePeriod: product.coveragePeriod,
        paymentPeriod: product.paymentPeriod,
        modelFactors: product.modelFactors,
        optionalRiders: product.optionalRiders || [],
        requiredRiders: product.requiredRiders || [],
        licenses: product.licenses,
        defaults: product.defaults,
        entryAge: {
          minimum: convertEntryAge(product.insuredEntryAgeMinimum || ''),
          maximum: convertEntryAge(product.insuredEntryAgeMaximum || ''),
        },
        entrySumAssured: {
          minimum: product.sumAssuredMinimum,
          maximum: product.sumAssuredMaximum,
        },
        validOccupationFactors: product.validOccupationFactors || [],
        rates: product.rates || {},
        feeStructure: product.feeStructure || {},
        paymentRanges: product.paymentRanges || {},
        aura: product.aura,
        ribbonMessage: product.ribbonMessage || '',
        payerSelection: product.payerSelection || [],
        allowUserType: product.allowUserType || [],
        defaultSumAssured: product.defaultSumAssured || '',
        basicPlanCodeOption: product.basicPlanCodeOption || [],
        brochureLink: product.brochureLink || {},
      }
    default:
      // $FlowFixMe
      return {
        code: product.productCode,
        name: product.name,
        nameThai: product.nameThai,
        displayName: product.displayName,
        fullNameThai: product.fullNameThai,
        displayNameLanguage: product.displayNameLanguage ? product.displayNameLanguage : 'ENG',
        basicPlanCode: product.productCode,
        basicPlanName: product.name,
        category: product.category,
        description: product.description,
        type: product.type,
        benefits: product.benefits,
        readOnlyFields: product.readOnlyFields || {},
        riders: product.riders ? product.riders.map((riderCode) => riderCode.replace('rider_', '')) : [],
        coveragePeriod: product.coveragePeriod,
        paymentPeriod: product.paymentPeriod,
        modelFactors: product.modelFactors,
        optionalRiders: product.optionalRiders || [],
        requiredRiders: product.requiredRiders || [],
        licenses: product.licenses,
        defaults: product.defaults,
        productPlans: product.productPlans,
        entryAge: {
          minimum: convertEntryAge(product.insuredEntryAgeMinimum || ''),
          maximum: convertEntryAge(product.insuredEntryAgeMaximum || ''),
        },
        entrySumAssured: {
          minimum: product.sumAssuredMinimum,
          maximum: product.sumAssuredMaximum,
        },
        aura: product.aura,
        image: product.image,
        validOccupationFactors: product.validOccupationFactors || [],
        expiryDate: product.expiryDate,
        ribbonMessage: product.ribbonMessage || '',
        payerSelection: product.payerSelection || [],
        allowUserType: product.allowUserType || [],
        defaultSumAssured: product.defaultSumAssured || '',
        basicPlanCodeOption: product.basicPlanCodeOption || [],
        brochureLink: product.brochureLink || {},
      }
  }
}

export const transformCampaignToDisplayProduct = (campaign: Campaign): DisplayProduct => {
  const allRiders = campaign.riders.optional.concat(campaign.riders.required).map(getRiderCode)
  // $FlowFixMe

  return {
    code: campaign.code,
    name: campaign.name,
    nameThai: campaign.nameThai,
    fullNameThai: campaign.fullNameThai,
    plans: campaign.basicPlan.plans || [],
    selectedCampaignPlan: campaign.selectedCampaignPlan || '',
    displayName: campaign.displayName,
    basicPlanCode: campaign.basicPlan.productCode,
    basicPlanName: campaign.basicPlan.name,
    displayNameLanguage: campaign.displayNameLanguage,
    category: campaign.category || campaign.basicPlan.category,
    description: campaign.description,
    image: campaign.image,
    type: campaign.type,
    benefits: campaign.benefits,
    readOnlyFields: campaign.readOnlyFields || {},
    riders: allRiders,
    coveragePeriod: campaign.basicPlan.coveragePeriod,
    paymentPeriod: campaign.basicPlan.paymentPeriod,
    modelFactors: campaign.basicPlan.modelFactors,
    optionalRiders: campaign.riders.optional.map(getRiderCode),
    requiredRiders: campaign.riders.required.map(getRiderCode),
    entryAge: {
      minimum: convertEntryAge(campaign.basicPlan.insuredEntryAgeMinimum || ''),
      maximum: convertEntryAge(campaign.basicPlan.insuredEntryAgeMaximum || ''),
    },
    entrySumAssured: {
      minimum: campaign.basicPlan.sumAssuredMinimum,
      maximum: campaign.basicPlan.sumAssuredMaximum,
    },
    validOccupationFactors: campaign.basicPlan.validOccupationFactors || [],
    licenses: campaign.licenses,
    aura: campaign.basicPlan.aura,
    ribbonMessage: campaign.ribbonMessage || '',
    payerSelection: campaign.payerSelection || [],
    allowUserType: campaign.allowUserType || [],
    defaultSumAssured: campaign.defaultSumAssured || '',
    basicPlanCodeOption: campaign.basicPlanCodeOption || [],
    brochureLink: campaign.brochureLink || {},
  }
}

export const transformToDisplayProduct = (product: Campaign | RawBasicPlan): DisplayProduct => {
  if (product.type === 'product') {
    return transformProductToDisplayProduct(product)
  } else {
    return transformCampaignToDisplayProduct(product)
  }
}

export const _sortByCode = (displayProduct: DisplayProduct[], productList: string[]): DisplayProduct[] =>
  _.compact(productList.map((code) => _.find(displayProduct, { code })))

export const isValidProduct = (product: RawBasicPlan) => {
  if (product.expiryDate) {
    const expiryDate = moment(product.expiryDate, VALUE.DATE_FORMAT, VALUE.TIME_ZONE)
    const currentDate = moment(new Date()).tz(VALUE.TIME_ZONE)
    return currentDate.isSameOrBefore(expiryDate)
  }
  return true
}
export const _listDisplayProducts = async (
  listActiveBasicPlans: () => Promise<BasicPlan[]>,
  listActiveCampaigns: () => Promise<Campaign[]>,
  getViewConfiguration: PromiseFunction<ViewConfiguration>
): Promise<DisplayProduct[]> => {
  const displayProduct = await Promise.all([listActiveCampaigns(), listActiveBasicPlans()])
    .then((products) => products.reduce((a, b) => a.concat(b), []))
    .then((products) => products.map(transformToDisplayProduct))
    .then((products) => (getToggles().ENABLE_VALIDATE_PRODUCT_EXPIRED ? products.filter(isValidProduct) : products))
  const productList = await getViewConfiguration().then((conf) => conf.productList)
  return _sortByCode(displayProduct, productList)
}

export const listDisplayProducts = (): Promise<DisplayProduct[]> =>
  _listDisplayProducts(listActiveBasicPlans, listActiveCampaigns, getViewConfiguration)

export const _getBasicPlan = (
  getBasicPlan: (string) => Promise<BasicPlan>,
  getCampaign: (string, string) => Promise<Campaign>,
  query: DisplayProductQuery
): Promise<BasicPlan> =>
  query.type === 'product'
    ? getBasicPlan(query.code, query.distributorGroup, query.agentCode)
    : getCampaign(
        query.code,
        typeof query.selectedCampaignPlan === 'undefined' ? '' : query.selectedCampaignPlan,
        query.distributorGroup,
        query.agentCode
      ).then((campaign) => campaign.basicPlan)

export const getBasicPlanFromPlanCodeOrCampaignCode = (query: DisplayProductQuery) =>
  _getBasicPlan(getBasicPlan, getCampaign, query)

export const isRequiredLicenses = (displayProduct: DisplayProduct) =>
  displayProduct.licenses !== null &&
  displayProduct.licenses !== undefined &&
  displayProduct.licenses.length > 0 &&
  displayProduct.category === 'INVESTMENT'
