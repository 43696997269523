// @flow
import PATH from 'quick-quote/constants/path'
import VALUES from 'core/data-model/constants/values'
import BIMESSAGES from 'core/data-model/constants/bi-messages'
import MESSAGES from 'core/data-model/constants/validation-messages'
import ProfileSnapshot from './profile-snapshot'
import ProfileSnapshotGLTSP from './profile-snapshot-gltsp'
import ProductBenefits from './product-benefits/product-benefits'
import PolicyValueTable from './policy-value/policy-value-table'
import InvestmentBenefit from './investment-benefit/investment-benefit'
import TaxConsentDeductionBenefit from './tax-consent-deduction-benefit'
import { isNil } from 'core/service/lib/type-check'
import { Redirect } from 'react-router-dom'
import { SidebarMenu } from 'quick-quote/components'
import { FormFeedback, FormGroup } from 'reactstrap'
import { LegalConditionDisclaimer } from './mrta-legal-disclaimer'
import { HealthCheckRegulations } from './health-check'

import type { Age } from 'core/data-model/insured'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { BasicPremium$ } from 'core/data-model/basic-plan'
import type { DisplayProduct } from 'core/service/display-product'
import type { PolicyValue, TaxDeduction } from 'core/service/benefit-illustration'

export type BenefitIllustrationProps = {
  displayProduct: ?DisplayProduct,
  riders: (Rider & RiderState)[],
  genderLabel: string,
  age: Age,
  calculatedCoveragePeriod: number,
  calculatedPaymentPeriod: number,
  loanAmount: number,
  loanPeriod: number,
  interestRate: number,
  premiumPerMonthGLTSP: number,
  sumAssured: number,
  paymentMethod: string,
  basicPremium$: BasicPremium$,
  totalPremium: number,
  totalRiderPremium: number,
  coveragePlanStartDate: Date,
  legalDisclaimerEndDate: Date,
  policyValue: PolicyValue,
  canProceedToBenefitIllustration: boolean,
  isBlockedEnteringESub: boolean,
  taxDeduction: TaxDeduction,
  isBancAgent: boolean,
  selectedModelFactorID: string,
  selectedModelFactorLabel: string,
}

const BenefitIllustration = ({
  displayProduct,
  riders,
  genderLabel,
  age,
  calculatedCoveragePeriod,
  calculatedPaymentPeriod,
  loanAmount,
  loanPeriod,
  interestRate,
  premiumPerMonthGLTSP,
  sumAssured,
  paymentMethod,
  basicPremium$,
  totalPremium,
  totalRiderPremium,
  coveragePlanStartDate,
  legalDisclaimerEndDate,
  policyValue,
  canProceedToBenefitIllustration,
  isBlockedEnteringESub,
  taxDeduction,
  selectedModelFactorID,
  selectedModelFactorLabel,
  sumAssuredContract,
  isReviseQQ,
}: BenefitIllustrationProps) => {
  if (!canProceedToBenefitIllustration) {
    return <Redirect to={PATH.PRODUCT_SELECTION} push />
  } else if (isNil(displayProduct)) return <div id="benefit-illustration-invalid" />
  else {
    return (
      <div id="benefit-illustration">
        <div className="content">
          {displayProduct.basicPlanCode !== VALUES.GLTSP_CODE.GLTSP ? (
            <ProfileSnapshot
              displayProduct={displayProduct}
              riders={riders}
              genderLabel={genderLabel}
              age={age}
              calculatedCoveragePeriod={calculatedCoveragePeriod}
              calculatedPaymentPeriod={calculatedPaymentPeriod}
              loanAmount={loanAmount}
              loanPeriod={loanPeriod}
              sumAssured={sumAssured}
              paymentMethod={paymentMethod}
              basicPremium={basicPremium$.value}
              totalPremium={totalPremium}
              totalRiderPremium={totalRiderPremium}
              sumAssuredContract={sumAssuredContract}
              isReviseQQ={isReviseQQ}
            />
          ) : (
            <ProfileSnapshotGLTSP
              displayProduct={displayProduct}
              riders={riders}
              genderLabel={genderLabel}
              age={age}
              calculatedCoveragePeriod={calculatedCoveragePeriod}
              calculatedPaymentPeriod={calculatedPaymentPeriod}
              loanAmount={loanAmount}
              loanPeriod={loanPeriod}
              interestRate={interestRate}
              premiumPerMonthGLTSP={premiumPerMonthGLTSP}
              sumAssured={sumAssured}
              paymentMethod={paymentMethod}
              basicPremium={basicPremium$.value}
              totalPremium={totalPremium}
              totalRiderPremium={totalRiderPremium}
              sumAssuredContract={sumAssuredContract}
            />
          )}

          <ProductBenefits
            displayProduct={displayProduct}
            sumAssured={sumAssured}
            basicPremium={basicPremium$.value}
            calculatedPaymentPeriod={calculatedPaymentPeriod}
            calculatedCoveragePeriod={calculatedCoveragePeriod}
            selectedModelFactorID={selectedModelFactorID}
            selectedModelFactorLabel={selectedModelFactorLabel}
            benefitSummaryHeader={({ displayProduct }) => displayProduct.nameThai}
            sumAssuredContract={sumAssuredContract}
          />

          {displayProduct.basicPlanCode !== VALUES.GLTSP_CODE.GLTSP && (
            <InvestmentBenefit displayProduct={displayProduct} />
          )}

          <PolicyValueTable policyValue={policyValue} basicPlan={displayProduct} />

          <TaxConsentDeductionBenefit taxDeduction={taxDeduction} />

          <LegalConditionDisclaimer startDate={coveragePlanStartDate} endDate={legalDisclaimerEndDate} />

          <HealthCheckRegulations
            age={age.value}
            sumAssuredContract={sumAssuredContract}
            paymentMethod={paymentMethod}
          />

          {isBlockedEnteringESub ? (
            <FormGroup className="has-warning">
              <FormFeedback className={'eligibility-message attached-icon'}>
                <div> {MESSAGES.RULE_ENTER_ESUB_PREREQUISITE} </div>
              </FormFeedback>
            </FormGroup>
          ) : (
            ''
          )}
        </div>
        <SidebarMenu
          mainMenu={[
            {
              text: displayProduct.riders?.length
                ? BIMESSAGES.SUMMARY_OF_MAIN_AND_ADDITIONAL_CONTRACTS
                : BIMESSAGES.SUMMARY_OF_MAIN_CONTRACTS,
              id: 'profile-summary',
              isSub: false,
            },
            {
              text: displayProduct.riders?.length
                ? BIMESSAGES.BASIC_PLAN_AND_RIDER_DETAILS_TITLE
                : BIMESSAGES.BASIC_PLAN_DETAILS_TITLE,
              id: 'product-benefits',
              isSub: false,
            },
            {
              text: BIMESSAGES.HEADER_BENEFIT_TABLE_DEFAULT,
              id: 'investment-benefit',
              isSub: false,
              isShow: displayProduct.basicPlanCode !== VALUES.GLTSP_CODE.GLTSP,
            },
            {
              text: BIMESSAGES.POLICY_VALUE_TABLE,
              id: 'policy-value',
              isSub: false,
            },
            {
              text: BIMESSAGES.LEGAL_DISCLAIMER,
              id: 'legal-disclaimer-market-conduct',
              isSub: false,
            },
          ]}
        />
      </div>
    )
  }
}

export default BenefitIllustration
