// @flow
import type { Age } from 'core/data-model/insured'
import { calculatePeriod } from 'core/service/basic-plan'
import { queryPremiumRateForWPTPD as queryPremiumRate } from 'core/service/rider/query-premium-rate'
import { calculateRiderPremiumForWPTPD as calculateRiderPremium } from 'core/service/rider/premium-calculation'

const getYearsOfCoverage = (
  coveragePeriod: number,
  age: Age,
  basicPaymentPeriod: Period,
  basicPlanCoveragePeriod: number
) => {
  const ageValue = age.unit !== 'year' ? 0 : age.value
  const yearsOfCoverage = Math.min(coveragePeriod - ageValue, calculatePeriod(basicPaymentPeriod, ageValue))
  return yearsOfCoverage > basicPlanCoveragePeriod ? basicPlanCoveragePeriod : yearsOfCoverage
}

export default {
  calculateRiderPremium,
  queryPremiumRate,
  getYearsOfCoverage,
}
