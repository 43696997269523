// @flow
/* istanbul ignore file */
import type { ExpectedReturn } from './sections/benefit-table-example-group'
import type { InsuredProps, PayerProps, StaffProps } from 'core/service/pdf-generation/products/common/types'
import type { Profile, DistributorGroupType } from 'core/data-model/distributor'
import type { CashFlow } from 'core/service/investment/cash-flow/cash-flow'
import { generateCashFlow, isUsedCoiTMORate } from 'core/service/investment/cash-flow/cash-flow'
import type { Gender } from 'core/data-model/insured'
import type { DisplayProduct } from 'core/service'
import type { Fund, FundAllocationToggleItem } from 'core/data-model/investment/types'

import { createPdfDocument } from 'core/service/pdf-generation/document-creation'
import { Footer } from 'core/service/pdf-generation/products/common/components/general'
import {
  BenefitTableExampleGroup,
  CashflowChart,
  Cover,
  FeeDetails,
  FormulaDetails,
  MutualFundPortfolio,
  PaymentChannels,
  PaymentChannelsILink,
  ProcessingTimeline,
  Recommendation,
  RightsInsured,
  RPULConditionsV2,
  RPULConditions2,
  Sign,
  SPULConditions1,
  SPULConditions2,
} from './sections'
import { rpudrContent } from './products/rpudr/content'
import BIMESSAGES from 'core/data-model/constants/bi-messages'
import VALUE from 'core/data-model/constants/values'

type CashFlowProps = {
  displayProduct: DisplayProduct,
  regularPremium: number,
  regularTopUp: number,
  lumpSum: { year: number, value: number }[],
  age: number,
  gender: Gender,
  paymentInterval: number,
  sumAssured: number,
}

export type InvestmentProps = {
  productCategory: 'INVESTMENT',
  productCode: string,
  type: 'FULL_BI',
  policyType: string,
  birthdate: string,
  insured: InsuredProps,
  payer: PayerProps,
  sumAssured: number,
  calculatedCoveragePeriod: number,
  calculatedPaymentPeriod: number,
  regularPremium: number,
  regularTopupPremium: number,
  coverageDeath: number,
  paymentModeLabel: string,
  profile: Profile,
  distributorType: DistributorGroupType,
  accessToken: string,
  dateInThai: string,
  funds: Fund[],
  allocations: FundAllocationToggleItem[],
  insuredRiskLevel: number,
  cashFlowProps: CashFlowProps,
  cashFlowByExpectedReturn: { [ExpectedReturn]: CashFlow[] },
  pieChartFundData: { name: string, value: number }[],
  pieChartFund: string,
  staff: StaffProps,
  action: string,
}

// template
const pageOrientation = 'landscape'
const defaultStyle = {
  font: 'PSLTextPro',
  fontSize: 14,
}

export const _generateCashFlowByExpectedReturn = async (
  displayProduct: DisplayProduct,
  regularPremium: number,
  regularTopUp: number,
  lumpSum: { year: number, value: number }[],
  age: number,
  gender: Gender,
  paymentInterval: number,
  sumAssured: number,
  percentages: number[]
) => {
  const generateCashFlowByExpectedReturn = (expectedReturn) =>
    generateCashFlow(
      displayProduct,
      expectedReturn,
      regularPremium,
      regularTopUp,
      lumpSum,
      age,
      gender,
      paymentInterval,
      sumAssured
    )

  let cashFlowByExpectedReturn = {}
  for (let expectedReturn of percentages) {
    cashFlowByExpectedReturn[`${expectedReturn}`] = await generateCashFlowByExpectedReturn(expectedReturn)
  }

  return cashFlowByExpectedReturn
}

const iWealthyContent = async (props: InvestmentProps) => {
  const paymentChannels = PaymentChannelsILink()
  const portfolio = await MutualFundPortfolio(props)

  const {
    displayProduct,
    regularPremium,
    regularTopUp,
    lumpSum,
    age,
    gender,
    paymentInterval,
    sumAssured,
  } = props.cashFlowProps

  const isUseNewRate = await isUsedCoiTMORate()
  const cashFlowByExpectedReturn = await _generateCashFlowByExpectedReturn(
    displayProduct,
    regularPremium,
    regularTopUp,
    lumpSum,
    age,
    gender,
    paymentInterval,
    sumAssured,
    [-1, 2, 5]
  )
  const propWithCashFlowByExpectedReturn = { ...props, cashFlowByExpectedReturn }
  const rateYear = isUseNewRate ? VALUE.COI_RATE_YEAR_CURRENT : VALUE.COI_RATE_YEAR_BEFORE

  return [
    Cover({
      ...propWithCashFlowByExpectedReturn,
      unitLinkedMessage: BIMESSAGES.IWEALTHY_UNIT_LINKED,
      unitLinkedThMessage: BIMESSAGES.IWEALTHY_UNIT_LINKED_TH,
    }),
    CashflowChart(props.productCode, propWithCashFlowByExpectedReturn),
    portfolio,
    BenefitTableExampleGroup(propWithCashFlowByExpectedReturn),
    FormulaDetails(props.productCode, rateYear),
    RPULConditionsV2(),
    RPULConditions2(),
    ProcessingTimeline(props.productCode),
    FeeDetails(props.productCode, rateYear),
    paymentChannels,
    RightsInsured(props.productCode),
    Recommendation(props.productCode),
    Sign(propWithCashFlowByExpectedReturn),
  ]
}

const iInvestContent = async (props: InvestmentProps) => {
  const paymentChannels = await PaymentChannels()
  const portfolio = await MutualFundPortfolio(props)

  const {
    displayProduct,
    regularPremium,
    regularTopUp,
    lumpSum,
    age,
    gender,
    paymentInterval,
    sumAssured,
  } = props.cashFlowProps

  const cashFlowByExpectedReturn = await _generateCashFlowByExpectedReturn(
    displayProduct,
    regularPremium,
    regularTopUp,
    lumpSum,
    age,
    gender,
    paymentInterval,
    sumAssured,
    [-1, 2, 5]
  )
  const propWithCashFlowByExpectedReturn = { ...props, cashFlowByExpectedReturn }

  return [
    Cover({
      ...props,
      unitLinkedMessage: BIMESSAGES.IINVEST_UNIT_LINKED,
      unitLinkedThMessage: BIMESSAGES.IINVEST_UNIT_LINKED_TH,
    }),
    CashflowChart(props.productCode, propWithCashFlowByExpectedReturn),
    portfolio,
    BenefitTableExampleGroup(propWithCashFlowByExpectedReturn),
    FormulaDetails(props.productCode, ''),
    SPULConditions1(),
    SPULConditions2(),
    ProcessingTimeline(props.productCode),
    FeeDetails(props.productCode, ''),
    paymentChannels,
    RightsInsured(props.productCode),
    Recommendation(props.productCode),
    Sign(props),
  ]
}

async function getContentByProductCode(props) {
  switch (props.productCode) {
    case BIMESSAGES.BI_TYPE_PRODUCT_CODE_IWEALTHY:
      return await iWealthyContent(props)
    case BIMESSAGES.BI_TYPE_PRODUCT_CODE_IINVEST:
      return await iInvestContent(props)
    case BIMESSAGES.BI_TYPE_PRODUCT_CODE_RPUDR:
      return await rpudrContent(props)
    default:
      return null
  }
}

export const createDocDefinition = async (props: InvestmentProps) => {
  const content = await getContentByProductCode(props)
  return {
    content,
    defaultStyle,
    styles: {
      tableHeader: { fillColor: VALUE.HARD_BLUE, color: 'white', alignment: 'center' },
      center: { alignment: 'center' },
    },
    pageOrientation,
    footer: Footer(props.productCode),
  }
}

export const createInvestmentPdf = createPdfDocument(createDocDefinition)
