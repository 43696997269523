// @flow
import _ from 'lodash'
import { d } from 'core/service/lib/decimal'
import riderMessages from 'core/data-model/constants/validation-messages'
import type { LinkedRidersSumAssuredRule } from 'core/data-model/rider/rules'
import type { RiderEligibilities } from 'core/data-model/rider'
import type { ValidationMessage } from 'core/data-model/validation'

export const checkLinkedRidersSumAssuredRule = (
  rule: LinkedRidersSumAssuredRule,
  eligibilities: RiderEligibilities
): ValidationMessage => {
  let errorMessage = ''
  let linkedRider = _.find(eligibilities.selectedRiders, { code: rule.linkedRiderCode })
  if (!_.isEmpty(linkedRider)) {
    let combinedSumAssured = d(eligibilities.rider.sumAssured)
      .plus(_.get(linkedRider, 'sumAssured', 0))
      .toNumber()

    if (
      combinedSumAssured >
      Math.min(rule.maximumCombinedSumAssured, eligibilities.basicSumAssured * rule.basicSumAssuredFactor)
    ) {
      let messageId = `RULE_RIDER_LINKED_${eligibilities.rider.code}_${rule.linkedRiderCode}`
      errorMessage = _.get(riderMessages, messageId) || riderMessages.RULE_MESSAGE_NOT_FOUND
    }
  }
  return {
    type: rule.validationType,
    message: errorMessage,
  }
}
