// @flow
import * as React from 'react'
import type { RiderPlan } from 'core/data-model/rider'
import type { BasicPremium$, ModelFactor, SumAssured$, TotalPremium$ } from 'core/data-model/basic-plan'
import type { Age } from 'core/data-model/insured'
import type { ReadOnlyFields } from 'core/data-model/types'
import type { Option } from 'common-components/dropdown'
import { InsuredInformationBreadcrumb } from 'quick-quote/components'
import RiderGroup from 'quick-quote/product-common/coverage-plan/components/rider/rider-group'

import { ModelFactorSelection } from 'quick-quote/product-common/coverage-plan/components/basic-plan/model-factor-selection'
import { BasicPlan } from 'quick-quote/product-common/coverage-plan/components/basic-plan/basic-plan'
import BasicPlanSelection from './basic-plan-selection'
import { TotalPremiumInput } from 'quick-quote/product-common/coverage-plan/components/basic-plan/total-premium-input'
import PATH from 'quick-quote/constants/path'
import { Redirect } from 'react-router-dom'

export type CoveragePlanProps = CoveragePlanState & CoveragePlanDispatchers

export type CoveragePlanState = {
  modelFactors: ModelFactor[],
  selectedModelFactorID: string,
  basicPremiumState: number,
  haveBasicPremiumState: boolean,
  sumAssured$: SumAssured$,
  basicPremium$: BasicPremium$,
  readOnlyFields: ReadOnlyFields,
  riderCodes: string[],
  selectedBasicPlanCode: string,
  totalPremium$: TotalPremium$,
  hasProductSelected: boolean,
  basicPlanOptions: Option[],
  basicPlanValidationError: string,
  insureAge: Age,
}

export type CoveragePlanDispatchers = {
  selectModelFactor: (string) => void,
  toggleRider: (string, boolean) => void,
  toggleRiderModule: (string, string, boolean) => void,
  toggleRiderGroupModule: (string, string, boolean) => void,
  updateSelectedBasicPlan: (string) => void,
  editRiderSumAssured: (string, number) => void,
  editRiderSelectedPlan: (string, RiderPlan) => void,
  editSumAssured: (?number) => void,
  editBasicPremium: (number) => void,
  editTotalPremium: (number) => void,
  validateBasicPlan: () => void,
}

class CoveragePlan extends React.Component<CoveragePlanProps> {
  constructor(props: CoveragePlanProps) {
    super(props)
  }

  componentDidMount() {
    this.props.validateBasicPlan()
  }

  render() {
    const props = this.props
    if (!props.hasProductSelected) {
      return <Redirect to={PATH.PRODUCT_SELECTION} push />
    }
    return (
      <div>
        <InsuredInformationBreadcrumb />
        <div id="coverage-plan">
          <div className="content">
            <div id="basic-plan">
              <div className="content">
                <ModelFactorSelection
                  modelFactors={props.modelFactors}
                  selectedModelFactorID={props.selectedModelFactorID}
                  selectModelFactor={props.selectModelFactor}
                />
                <BasicPlanSelection
                  basicPlanOptions={props.basicPlanOptions}
                  selectedBasicPlanCode={props.selectedBasicPlanCode}
                  basicPlanValidationError={props.basicPlanValidationError}
                  editSelectedHealthPlan={props.updateSelectedBasicPlan}
                />
                <BasicPlan
                  basicPremiumState={props.basicPremiumState}
                  haveBasicPremiumState={props.haveBasicPremiumState}
                  sumAssured$={props.sumAssured$}
                  basicPremium$={props.basicPremium$}
                  readOnlyFields={props.readOnlyFields}
                  editSumAssured={props.editSumAssured}
                  editBasicPremium={props.editBasicPremium}
                />
              </div>
            </div>
            <div id="total-premium">
              <TotalPremiumInput
                totalPremium$={props.totalPremium$}
                riderCodes={props.riderCodes}
                editTotalPremium={props.editTotalPremium}
              />
            </div>
            <RiderGroup
              riderCodes={props.riderCodes}
              toggleRider={props.toggleRider}
              toggleRiderModule={props.toggleRiderModule}
              toggleRiderGroupModule={props.toggleRiderGroupModule}
              editRiderSumAssured={props.editRiderSumAssured}
              editRiderSelectedPlan={props.editRiderSelectedPlan}
              insureAge={props.insureAge}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default CoveragePlan
