// @flow

import { Section, Bold } from 'core/service/pdf-generation/products/common/components/general'
import VALUE from 'core/data-model/constants/values'

const row1 = [Bold('ระยะเวลาในการคุ้มครอง'), 'กรมธรรม์ครบกำหนดสัญญาเมื่อผู้เอาประกันภัยอายุ 99 ปี']
const row2 = [Bold('อายุรับประกันภัย'), '1 เดือน – 75 ปี']
const row3 = [Bold('เบี้ยประกันภัย'), 'ขั้นต่ำ 100,000 บาท โดยชำระครั้งเดียว']
const row4 = [
  Bold('เบี้ยประกันภัยเพิ่มเติมพิเศษ'),
  [
    'สามารถชำระเบี้ยประกันภัยเพิ่มเติมพิเศษได้ ตั้งแต่ปีกรมธรรม์ที่ 2 เป็นต้นไป ตามที่บริษัทกำหนด โดยกำหนดขั้นต่ำที่ 5,000 บาท',
    'จำนวนเบี้ยประกันภัยเพิ่มเติมพิเศษที่ชำระได้สูงสุดต่อครั้ง คือ 10 เท่าของเบี้ยประกันภัยชำระครั้งเดียว และเบี้ยประกันภัยเพิ่มเติมพิเศษรวมทั้งหมดไม่เกิน 200 ล้านบาทต่อกรมธรรม์',
  ],
]
const row5 = [Bold('จำนวนเงินเอาประกันภัย'), 'เท่ากับเบี้ยประกันภัยครั้งเดียวที่ชำระมา']
const row6 = [
  Bold('ประเภทของกองทุนที่นำเสนอคู่กับแบบประกัน'),
  'บริษัทได้คัดเลือกกองทุนประเภทต่าง ๆ ประกอบไปด้วย กองทุนรวมตลาดเงิน กองทุนรวมพันธบัตรรัฐบาล กองทุนรวมตราสารแห่งหนี้ กองทุนรวมผสม กองทุนรวมตราสารแห่งทุน กองทุนรวมที่ลงทุนในสินทรัพย์ทางเลือก หรือกองทุนรวมอื่น ๆ ตามที่สำนักงานคณะกรรมการกำกับหลักทรัพย์และตลาดหลักทรัพย์กำหนด',
]
const row7 = [Bold({ style: 'tableHeader', colSpan: 2, text: 'ประเภทของผลประโยชน์' }), {}]
const row8 = [
  Bold('ผลประโยชน์ของการมีชีวิตอยู่'),
  [
    {
      ul: [
        {
          text: [
            'หากผู้เอาประกันภัยถือกรมธรรม์เป็นระยะเวลา 5 ปี เป็นต้นไปและไม่มีการถอนเงินใดๆ จากกรมธรรม์ ระหว่างนั้น นับตั้งแต่วันที่กรมธรรม์มีผลบังคับ บริษัทจะให้เงินโบนัสสำหรับการถือกรมธรรม์ทุกสิ้นปีตั้งแต่สิ้นปีกรมธรรม์ที่ 5 เป็นต้นไป เท่ากับอัตราตามตารางต่อไปนี้ของมูลค่าบัญชีกรมธรรม์เฉลี่ย 12 เดือนย้อนหลัง ของ ณ วันทำการสุดท้ายของแต่ละเดือน',
          ],
        },
      ],
    },
    {
      table: {
        widths: [128, 210],
        body: [
          [
            { alignment: 'center', text: 'สิ้นปีกรมธรรม์ที่' },
            { alignment: 'center', text: 'ร้อยละของมูลค่าบัญชีกรมธรรม์เฉลี่ย 12 เดือนย้อนหลัง' },
          ],
          [
            { alignment: 'center', text: '5-10' },
            { alignment: 'center', text: '0.25' },
          ],
          [
            { alignment: 'center', text: '11 ขึ้นไป' },
            { alignment: 'center', text: '0.5' },
          ],
        ],
      },
      layout: {
        hLineColor: VALUE.MEDIUM_BLUE,
        vLineColor: VALUE.MEDIUM_BLUE,
      },
    },
    'ทั้งนี้ ในช่วงตั้งแต่ปีที่ผู้เอาประกันภัยเริ่มได้รับโบนัสสำหรับการถือกรมธรรม์ หากผู้เอาประกันภัยมีการถอนเงินในปีกรมธรรม์ใด บริษัทจะงดให้เงินโบนัสสำหรับการถือกรมธรรม์ในปีกรมธรรม์นั้นๆ',
    {
      ul: [
        {
          text: [
            'ผู้เอาประกันภัยจะได้รับมูลค่าบัญชีกรมธรรม์ ในกรณีที่กรมธรรม์ครบกำหนดสัญญา เมื่อผู้เอาประกันภัยมีอายุครบ 99 ปี',
          ],
        },
      ],
    },
  ],
]
const row9 = [
  Bold('ผลประโยชน์จากการเสียชีวิต'),
  [
    {
      ul: [{ text: ['จำนวนใดที่มากกว่าระหว่าง 1) หรือ 2)'] }],
    },
    '  1) ร้อยละ 110 ของจำนวนเบี้ยประกันภัยชำระครั้งเดียว (Single Premium) หลังหักจำนวนเงินทั้งหมดที่ถอนออกจากกรมธรรม์ (Partial Withdrawal) (ถ้ามี)',
    '  2) ร้อยละ 110 ของมูลค่าบัญชีกรมธรรม์ (Account Value)',
  ],
]

const title = 'สรุปย่อ ข้อกำหนดและเงื่อนไขทั่วไป รวมทั้ง สิทธิและผลประโยชน์ตามกรมธรรม์ประกันภัย'
const content = [
  {
    fontSize: 12,
    table: {
      widths: [180, '*'],
      body: [row1, row2, row3, row4, row5, row6, row7, row8, row9],
    },
    layout: {
      hLineColor: VALUE.MEDIUM_BLUE,
      vLineColor: VALUE.MEDIUM_BLUE,
      fillColor: function(i, node) {
        return i % 2 === 0 ? null : VALUE.ZEBRA_BACKGROUND_BLUE
      },
    },
  },
]

export const SPULConditions1 = () => Section(title, content)
