// @flow
import VALUES from 'core/data-model/constants/values'
import MESSAGES from 'core/data-model/constants/messages'
import BIMESSAGES from 'core/data-model/constants/bi-messages'
import { isNil } from 'core/service/lib/type-check'
import { formatNumber } from 'core/service/lib/number-format'

import type { DisplayProduct } from 'core/service/display-product'
import type { Rider, RiderState } from 'core/data-model/rider'

type SummaryTableProps = {
  displayProduct: ?DisplayProduct,
  riders: (Rider & RiderState)[],
  basicPremium: number,
  basicSumAssured: number,
  paymentMethod: string,
  totalPremium: number,
  totalRiderPremium: number,
  sumAssuredContract: number,
}

const SummaryTable = ({
  displayProduct,
  riders,
  basicPremium,
  basicSumAssured,
  paymentMethod,
  totalPremium,
  totalRiderPremium,
  sumAssuredContract,
}: SummaryTableProps) => {
  if (isNil(displayProduct)) {
    return
  }

  return (
    <div className="summary-table d-none d-md-block">
      <div className="details-table">
        <h3>
          {displayProduct.riders.length
            ? BIMESSAGES.SUMMARY_OF_MAIN_AND_ADDITIONAL_CONTRACTS
            : BIMESSAGES.SUMMARY_OF_MAIN_CONTRACTS}
        </h3>
        <br />
        {/* condition for showcase name CASH_INCLUDE_LOAN */}
        <div>
          <b>
            {paymentMethod === VALUES.CASH_INCLUDE_LOAN ? BIMESSAGES.CASH_INCLUDE_LOAN : BIMESSAGES.CASH_EXCLUDE_LOAN}
          </b>
        </div>
        <br />
        <div className="summary-table-mrta">
          <div className="info-left">{BIMESSAGES.SUM_INSURED_OF_BASIC_PLAN_AT_START_DATE}</div>
          <div className="info-right">
            {formatNumber(sumAssuredContract, 0, true)} {MESSAGES.BAHT}
          </div>
        </div>
        {![VALUES.MRTA_CODE.MRTA_FULL, VALUES.GLTSP_CODE.GLTSP].includes(displayProduct.basicPlanCode) && (
          <div className="summary-table-mrta">
            <div className="info-left">{BIMESSAGES.SUM_RIDER_OF_ACCIDENT_PROTECTION}</div>
            <div className="info-right">
              {![VALUES.MRTA_CODE.MRTA_FULL, VALUES.GLTSP_CODE.GLTSP].includes(displayProduct.basicPlanCode)
                ? formatNumber(riders[0].sumAssured)
                : 0}{' '}
              {MESSAGES.BAHT}
            </div>
          </div>
        )}
        <div className="summary-table-mrta">
          <div className="info-left">{BIMESSAGES.BASIC_PREMIUM}</div>
          <div className="info-right">
            {formatNumber(basicPremium, 2)} {MESSAGES.BAHT}
          </div>
        </div>
        {![VALUES.MRTA_CODE.MRTA_FULL, VALUES.GLTSP_CODE.GLTSP].includes(displayProduct.basicPlanCode) && (
          <div className="summary-table-mrta">
            <div className="info-left">{BIMESSAGES.RIDER_PREMIUM_COVER_ACCIDENT_PROTECTION}</div>
            <div className="info-right">
              {formatNumber(totalRiderPremium, 2)} {MESSAGES.BAHT}
            </div>
          </div>
        )}
        {![VALUES.MRTA_CODE.MRTA_FULL, VALUES.GLTSP_CODE.GLTSP].includes(displayProduct.basicPlanCode) && (
          <div className="summary-table-mrta">
            <div className="info-left">{BIMESSAGES.SUMMARY_STANDARD_PREMIUM}</div>
            <div className="info-right">
              {formatNumber(totalPremium, 2)} {MESSAGES.BAHT}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SummaryTable
