// @flow
import type { CoveragePeriod$ } from 'core/data-model/basic-plan'
import { isZero, enableCoveragePeriod, renderErrorMessage } from 'common-components/view-logic'

type CoveragePeriodErrorMessagesProps = {
  haveBasicPremiumState: boolean,
  coveragePeriod$: CoveragePeriod$,
}

const CoveragePeriodErrorMessages = ({ haveBasicPremiumState, coveragePeriod$ }: CoveragePeriodErrorMessagesProps) => {
  return (
    <div>
      {!enableCoveragePeriod(haveBasicPremiumState, coveragePeriod$) || isZero(coveragePeriod$)
        ? null
        : renderErrorMessage('error-coverage-period-message')(coveragePeriod$)}
    </div>
  )
}

export default CoveragePeriodErrorMessages
