// // @flow

import { combineReducers } from 'redux'
import { getToggles } from 'quick-quote/feature-toggles'
import set from 'lodash/fp/set'
import type { Action } from './actions'
import {
  ACCEPT_FOREIGN_INVESTMENT,
  ACCEPT_HIGHER_RISK_INVESTMENT,
  ANSWER_QUESTION,
  CALCULATE_RISK,
  RESET_ANSWERED_QUESTION,
  RESET_RISK_EVALUATED,
  SELECT_QUESTIONNAIRE,
  SELECT_RISK_LEVEL,
  SET_AGENT_COMMENT,
} from './actions'
import { SELECT_PRODUCT } from 'quick-quote/product-selection/actions'

import type { RiskEvaluated, RiskLevel } from 'core/service/investment/risklevel'
import { getRiskLevel } from 'core/service/investment/risklevel'
import type { Questionnaire } from 'core/service/investment/questionnaire'
import { getQuestionnaire, getQuestionnaireV2 } from 'core/service/investment/questionnaire'

const initialRiskLevelState: RiskLevel = getRiskLevel()

const riskLevel = (state: RiskLevel = initialRiskLevelState, action: Action) => {
  switch (action.type) {
    case SELECT_RISK_LEVEL:
      return action.payload
    default:
      return state
  }
}

const initialRiskState: RiskEvaluated = {
  value: 0,
  label: '',
  isEvaluated: false,
  description: '',
}
const riskEvaluated = (state: RiskEvaluated = initialRiskState, action: Action) => {
  switch (action.type) {
    case SELECT_PRODUCT:
    case RESET_RISK_EVALUATED:
      return initialRiskState
    case CALCULATE_RISK:
      return action.payload
    default:
      return state
  }
}

const initialQuestionnaireState = getQuestionnaire()
const initialQuestionnaireV2State = getQuestionnaireV2()

const questionnaires = (
  state: Questionnaire[] = getToggles().ENABLE_RPQ_FORM_2022 ? initialQuestionnaireV2State : initialQuestionnaireState,
  action: Action
): Questionnaire[] => {
  switch (action.type) {
    case SELECT_PRODUCT:
      return getToggles().ENABLE_RPQ_FORM_2022 ? initialQuestionnaireV2State : initialQuestionnaireState
    case RESET_ANSWERED_QUESTION:
      return state.map((q) => {
        return q.multipleChoice ? { ...q, selectedChoice: [] } : { ...q, selectedChoice: 0 }
      })
    case SELECT_QUESTIONNAIRE:
      return action.payload
    case ANSWER_QUESTION:
      const { index, value } = action.payload
      return set([index - 1, 'selectedChoice'], value)(state)
    default:
      return state
  }
}

export type State = {
  +riskEvaluated: RiskEvaluated,
  +questionnaires: Questionnaire[],
  +riskLevel: RiskLevel,
  +agentComment: string,
  +allowHigherRiskInvestment: boolean,
  +allowForeignInvestment: boolean,
}

const agentComment = (state: string = '', action: Action): string => {
  switch (action.type) {
    case SET_AGENT_COMMENT:
      return action.payload
    case RESET_ANSWERED_QUESTION:
    case RESET_RISK_EVALUATED:
      return ''
    default:
      return state
  }
}

const allowHigherRiskInvestment = (state: boolean = false, action: Action): boolean => {
  switch (action.type) {
    case ACCEPT_HIGHER_RISK_INVESTMENT:
      return !state
    case RESET_ANSWERED_QUESTION:
    case RESET_RISK_EVALUATED:
      return false
    default:
      return state
  }
}

const allowForeignInvestment = (state: boolean = false, action: Action): boolean => {
  switch (action.type) {
    case ACCEPT_FOREIGN_INVESTMENT:
      return !state
    case RESET_ANSWERED_QUESTION:
    case RESET_RISK_EVALUATED:
      return false
    default:
      return state
  }
}

export const reducer = combineReducers({
  riskLevel,
  riskEvaluated,
  questionnaires,
  agentComment,
  allowHigherRiskInvestment,
  allowForeignInvestment,
})
