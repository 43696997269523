import { ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import Dialog from 'e-submission/components/Dialog'

const CustomDialog = ({ toggle, isOpen, onConfirm, noTitle = false, message: { title = '', body = '' }, content }) => (
  <Dialog toggle={toggle} className="my-wealth-plus-dialog" modalClassName="default-bootstrap" isOpen={isOpen} backdrop>
    {!noTitle && (
      <ModalHeader tag="div" toggle={toggle}>
        {title}
      </ModalHeader>
    )}
    {content ? (
      <ModalBody className="modal-body-content">{content}</ModalBody>
    ) : (
      <ModalBody className={noTitle ? 'info' : ''} dangerouslySetInnerHTML={{ __html: body }} />
    )}
    <ModalFooter>
      <Button color="primary" onClick={onConfirm}>
        ยืนยัน
      </Button>
    </ModalFooter>
  </Dialog>
)

export default CustomDialog
