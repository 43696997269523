// @flow

// TODO: #techdebt remove color, it just for component compatible
export const chartLegendPattern = (color: string, text: string, index: number, patterns: string[]) => {
  const legendImg = patterns[index % patterns.length]
  return {
    table: {
      widths: [1, '*'],
      body: [
        [
          {
            table: {
              body: [
                [
                  {
                    image: legendImg,
                    width: 7,
                  },
                ],
              ],
            },
            layout: {
              hLineWidth: () => 0,
              vLineWidth: () => 0,
              paddingLeft: () => 0,
              paddingRight: () => 0,
              paddingTop: () => 0,
              paddingBottom: () => 0,
            },
          },
          { text: text, marginTop: -2, marginLeft: 9, fontSize: 8 },
        ],
      ],
    },
    layout: {
      hLineWidth: () => 0,
      vLineWidth: () => 0,
      paddingLeft: () => 0,
      paddingRight: () => 0,
      paddingTop: () => 0.5,
      paddingBottom: () => 0.5,
    },
    lineHeight: '0.9',
  }
}

export default chartLegendPattern
