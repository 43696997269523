// @flow

import { Section, Bold } from 'core/service/pdf-generation/products/common/components/general'
import VALUE from 'core/data-model/constants/values'

const row1 = [Bold('ระยะเวลาในการคุ้มครอง'), 'กรมธรรม์ครบกำหนดสัญญาเมื่อผู้เอาประกันภัยอายุ 99 ปี']
const row2 = [Bold('อายุรับประกันภัย'), '1 เดือน – 70 ปี']
const row3 = [
  Bold('เบี้ยประกันภัยหลักเพื่อความคุ้มครองรายงวด'),
  'ขั้นต่ำ 18,000 บาทต่อปี หรือ 1,500 บาทต่อเดือน ทั้งนี้ ไม่สามารถเพิ่มจำนวนเบี้ยประกันภัยหลักเพื่อความคุ้มครองรายงวด ภายหลังจากกรมธรรม์ได้รับการอนุมัติ',
]
const row4 = [
  Bold('เบี้ยประกันภัยเพื่อการลงทุนรายงวด'),
  'ขั้นต่ำ 6,000 บาทต่อปี และ สูงสุดไม่เกิน 3 เท่าของเบี้ยประกันภัยหลักเพื่อความคุ้มครองรายงวด โดยสามารถเริ่มชำระเบี้ยประกันภัยเพื่อการลงทุนรายงวดได้ตั้งแต่ปีกรมธรรม์แรกเท่านั้น',
]
const row4ILP2022 = [
  Bold('เบี้ยประกันภัยเพื่อการลงทุนรายงวด'),
  'ขั้นต่ำ - บาท ต่อปี และ สูงสุดไม่เกิน 3 เท่าของเบี้ยประกันภัยหลักเพื่อความคุ้มครองรายงวด โดยสามารถเริ่มชำระเบี้ยประกันภัยเพื่อการลงทุนรายงวดได้ตั้งแต่ปีกรมธรรม์แรกเท่านั้น',
]
const row5 = [
  Bold('เบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษ'),
  'สามารถชำระเบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษได้ ตั้งแต่ปีกรมธรรม์ที่ 2 เป็นต้นไป ตามที่บริษัทกำหนด โดยกำหนดขั้นต่ำที่ 10,000 บาทต่อครั้ง จำนวนเบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษที่ชำระได้สูงสุดต่อปีกรมธรรม์ คือ 5 เท่าของเบี้ยประกันภัยหลักเพื่อความคุ้มครองรายงวด และเบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษรวมทั้งสิ้นไม่เกิน 150 ล้านบาทต่อกรมธรรม์',
]
const row6 = [
  Bold('จำนวนเงินเอาประกันภัย'),
  [
    'จำนวนเงินเอาประกันภัยครั้งแรกจะคำนวณจากจำนวนเท่าของเบี้ยประกันภัยหลักเพื่อความคุ้มครองที่ชำระต่อปี ตามตารางต่อไปนี้',
    {
      table: {
        widths: [128, 110, 95],
        body: [
          [
            { alignment: 'center', text: 'อายุ' },
            { alignment: 'center', text: 'จำนวนเท่าขั้นต่ำ' },
            { alignment: 'center', text: 'จำนวนเท่าขั้นสูง' },
          ],
          [
            { alignment: 'center', text: '0-40' },
            { alignment: 'center', text: '40' },
            { alignment: 'center', text: '60' },
          ],
          [
            { alignment: 'center', text: '41-50' },
            { alignment: 'center', text: '30' },
            { alignment: 'center', text: '50' },
          ],
          [{ alignment: 'center', text: '51-60' }, { colSpan: 2, alignment: 'center', text: '20 เท่า' }, {}],
          [{ alignment: 'center', text: '61-65' }, { colSpan: 2, alignment: 'center', text: '15 เท่า' }, {}],
          [{ alignment: 'center', text: '66 เป็นต้นไป' }, { colSpan: 2, alignment: 'center', text: '5 เท่า' }, {}],
        ],
      },
      layout: {
        hLineColor: VALUE.MEDIUM_BLUE,
        vLineColor: VALUE.MEDIUM_BLUE,
      },
    },
    'ผู้เอาประกันภัยสามารถกำหนดจำนวนเงินเอาประกันภัยได้เอง และปรับเปลี่ยนได้ตามข้อกำหนดระหว่างขั้นสูง-ขั้นต่ำ และเงื่อนไขของกรมธรรม์',
  ],
]
const row6ILP2022 = [
  Bold('จำนวนเงินเอาประกันภัย'),
  'ผู้เอาประกันภัยสามารถกำหนดจำนวนเงินเอาประกันภัยได้เอง และปรับเปลี่ยนได้ตามข้อกำหนดระหว่างขั้นสูง-ขั้นต่ำ และเงื่อนไขของกรมธรรม์',
]

const row7 = [
  Bold('ประเภทของกองทุนที่นำเสนอคู่กับแบบประกัน'),
  'บริษัทได้คัดเลือกกองทุนประเภทต่าง ๆ ประกอบไปด้วย กองทุนรวมตลาดเงิน กองทุนรวมพันธบัตรรัฐบาล กองทุนรวมตราสารแห่งหนี้ กองทุนรวมผสม กองทุนรวมตราสารแห่งทุน กองทุนรวมที่ลงทุนในสินทรัพย์ทางเลือก หรือกองทุนรวมอื่น ๆ ตามที่สำนักงานคณะกรรมการกำกับหลักทรัพย์และตลาดหลักทรัพย์กำหนด',
]
const row8 = [Bold({ style: 'tableHeader', colSpan: 2, text: 'ประเภทของผลประโยชน์' }), {}]
const row9 = [
  Bold('ผลประโยชน์ของการมีชีวิตอยู่ '),
  {
    stack: [
      {
        ul: [
          {
            text: [
              'หากผู้เอาประกันภัยชำระเบี้ยประกันภัยหลักเพื่อความคุ้มครองรายงวดครบทุกงวดตั้งแต่ปีที่ 1 ถึงปีที่ 6 โดยไม่เคยทำการลดจำนวนเบี้ยประกันภัยหลักเพื่อความคุ้มครองรายงวด',
              'และไม่มีการถอนเงินใดๆ จากกรมธรรม์หรือการขาดอายุของกรมธรรม์ในระหว่างนั้น บริษัทจะให้เงินโบนัสสำหรับการถือกรมธรรม์ทุกสิ้นปีตั้งแต่สิ้นปีกรมธรรม์ที่ 6 เป็นต้นไป ',
              'ในอัตราร้อยละ 0.2 ต่อปีของมูลค่ารับซื้อคืนหน่วยลงทุน ณ วันทำการสุดท้ายของแต่ละเดือน เฉลี่ย 12 เดือนย้อนหลัง ทั้งนี้ ในช่วงตั้งแต่ปีที่ผู้เอาประกันภัยเริ่มได้รับโบนัส',
              'สำหรับการถือกรมธรรม์ หากผู้เอาประกันภัยขอลดจำนวนเบี้ยประกันภัยหลักเพื่อความคุ้มครองรายงวดหรือขาดการชำระเบี้ย',
              'ประกันภัยหลักเพื่อความคุ้มครองรายงวดหรือมีการถอนเงินในปีกรมธรรม์ใด บริษัทจะงดให้เงินโบนัส',
              'สำหรับการถือกรมธรรม์',
              'เฉพาะในปีกรมธรรม์นั้นๆ',
            ],
          },
          'ผู้เอาประกันภัยจะได้รับมูลค่ารับซื้อคืนหน่วยลงทุน ในกรณีที่กรมธรรม์ครบกำหนดสัญญา เมื่อผู้เอาประกันภัยมีอายุครบ 99 ปี',
        ],
      },
    ],
  },
]
const row10 = [
  Bold('ผลประโยชน์จากการเสียชีวิต'),
  'ร้อยละ 120 ของจำนวนใดที่มากกว่าระหว่าง 1) จำนวนเงินเอาประกันภัย หรือ 2) มูลค่ารับซื้อคืนหน่วยลงทุน',
]

const title = 'สรุปย่อ ข้อกำหนดและเงื่อนไขทั่วไป รวมทั้ง สิทธิและผลประโยชน์ตามกรมธรรม์ประกันภัย'
const content = [
  {
    fontSize: 12,
    table: {
      widths: [180, '*'],
      body: [row1, row2, row3, row4, row5, row6, row7, row8, row9, row10],
    },
    layout: {
      hLineColor: VALUE.MEDIUM_BLUE,
      vLineColor: VALUE.MEDIUM_BLUE,
      fillColor: function(i, node) {
        return i % 2 === 0 ? null : VALUE.ZEBRA_BACKGROUND_BLUE
      },
    },
  },
]

const contentV2 = [
  {
    fontSize: 12,
    table: {
      widths: [180, '*'],
      body: [row1, row2, row3, row4ILP2022, row5, row6ILP2022, row7, row8, row9, row10],
    },
    layout: {
      hLineColor: VALUE.MEDIUM_BLUE,
      vLineColor: VALUE.MEDIUM_BLUE,
      fillColor: function(i, node) {
        return i % 2 === 0 ? null : VALUE.ZEBRA_BACKGROUND_BLUE
      },
    },
  },
]

export const RPULConditions1 = () => Section(title, content)

export const RPULConditionsV2 = () => Section(title, contentV2)
