// @flow
import PropTypes from 'prop-types'
import { compose, lifecycle, withContext, withState } from 'recompose'
import { connect } from 'react-redux'
import type { Dispatch } from 'redux'
import { bindActionCreators } from 'redux'
import { Route, Router, Switch } from 'react-router-dom'
import { asyncComponent } from 'lib/async'
import { createBrowserHistory } from 'history'
import _ from 'lodash'

import { ROUTE_PATH } from 'quick-quote/constants/path'

import AppUpdating from 'common-components/app-updating'
import { GlobalPopup, MainLayout, NavigationButton, ScrollToTop, SplashScreen } from './components'
import { ThemeProvider } from 'common-components'
import QuickQuoteRoutes from './components/navigations/routes'

import type { Version } from 'quick-quote'
import type { AppState, AppStatus } from 'quick-quote/reducers'
import { reloadApplicationRequest, updateApplicationRequest } from 'quick-quote/actions'
import { clearNotificationMessage, type Notification } from 'quick-quote/notification/actions'
import { analyticsBiEvent, analyticsPageViewEvent } from 'analytics/actions'

import { getApplicationStatus, getVersion, isSelectedRemoteSelling } from 'quick-quote/selectors'
import { isLoggedIn } from 'identity/selectors'
import { clickHomeButton } from './actions'
import AuthenticationError from 'quick-quote/components/authentication-error-keycloak'
import { getInCall } from './remote-selling'

type Props = {
  history: *,
  nextButton: *,
  status: AppStatus,
  isLoggedIn: Function,
  version: Version,
  globalMessage: Notification,
  clearNotificationMessage: () => void,
  reloadApplicationRequest: () => void,
  updateApplicationRequest: () => void,
  dispatchAnalyticsBiEvent: () => void,
  dispatchAnalyticsPageViewEvent: () => void,
  handleHomeButtonClick: () => void,
  selectedRemoteSelling: boolean,
  inCall: boolean,
}

let ESubmission = asyncComponent(() => import(/* webpackChunkName: "e-submission-app" */ 'e-submission/app'))
if (process.env.NODE_ENV !== 'production' && typeof window === 'object') {
  ESubmission = require('e-submission/app').default
}

const MainWithoutRouter = ({
  nextButton,
  isLoggedIn,
  status,
  reloadApplicationRequest,
  updateApplicationRequest,
  version,
  dispatchAnalyticsBiEvent,
  dispatchAnalyticsPageViewEvent,
  globalMessage,
  clearNotificationMessage,
  handleHomeButtonClick,
  selectedRemoteSelling,
  inCall,
  ...props
}: Props) => {
  return (
    <ThemeProvider>
      <MainLayout
        version={version}
        onBackToHome={handleHomeButtonClick}
        selectedRemoteSelling={selectedRemoteSelling}
        inCall={inCall}
      >
        <AppUpdating />
        <GlobalPopup notificationMessage={globalMessage} onExit={clearNotificationMessage} />
        <ScrollToTop>
          <Switch>
            <Route
              path={'/authorize/error'}
              component={(props) => <AuthenticationError onExit={() => {}} notificationMessage={'test'} />}
            />
            <Route path={ROUTE_PATH.ESUBMISSION} component={ESubmission} />
            <Route component={QuickQuoteRoutes} />
          </Switch>
        </ScrollToTop>
        <NavigationButton nextButton={nextButton} />
      </MainLayout>
    </ThemeProvider>
  )
}

const mapStateToProps = (state: AppState) => ({
  isLoggedIn: isLoggedIn(state),
  status: getApplicationStatus(state),
  globalMessage: state.notificationMessage,
  version: getVersion(state),
  selectedRemoteSelling: isSelectedRemoteSelling(state),
  inCall: getInCall(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  reloadApplicationRequest: bindActionCreators(reloadApplicationRequest, dispatch),
  updateApplicationRequest: bindActionCreators(updateApplicationRequest, dispatch),
  dispatchAnalyticsBiEvent: bindActionCreators(analyticsBiEvent, dispatch),
  dispatchAnalyticsPageViewEvent: bindActionCreators(analyticsPageViewEvent, dispatch),
  clearNotificationMessage: bindActionCreators(clearNotificationMessage, dispatch),
  handleHomeButtonClick: bindActionCreators(clickHomeButton, dispatch),
})

const basePath = process.env.BASE_PATH
const MainWithRouter = ({
  history = createBrowserHistory({ basename: basePath }),
  nextButton,
  isLoggedIn,
  status,
  reloadApplicationRequest,
  updateApplicationRequest,
  version,
  dispatchAnalyticsBiEvent,
  globalMessage,
  clearNotificationMessage,
  dispatchAnalyticsPageViewEvent,
  handleHomeButtonClick,
  selectedRemoteSelling,
  inCall,
}: Props) => {
  const matchAuthorizeErrorPathPattern = /\/authorize\/error/g
  const pathName = _.get(history, 'location.pathname')
  if (matchAuthorizeErrorPathPattern.test(pathName))
    return <AuthenticationError onExit={() => {}} notificationMessage={'test'} />
  return (
    <Router history={history}>
      {status === 'ready' ? (
        <MainWithoutRouter
          history={history}
          nextButton={nextButton}
          isLoggedIn={isLoggedIn}
          status={status}
          reloadApplicationRequest={reloadApplicationRequest}
          updateApplicationRequest={updateApplicationRequest}
          version={version}
          dispatchAnalyticsBiEvent={dispatchAnalyticsBiEvent}
          globalMessage={globalMessage}
          clearNotificationMessage={clearNotificationMessage}
          dispatchAnalyticsPageViewEvent={dispatchAnalyticsPageViewEvent}
          handleHomeButtonClick={handleHomeButtonClick}
          selectedRemoteSelling={selectedRemoteSelling}
          inCall={inCall}
        />
      ) : (
        <SplashScreen
          loadingState={status}
          updateApplicationRequest={updateApplicationRequest}
          reloadApplicationRequest={reloadApplicationRequest}
        />
      )}
    </Router>
  )
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export const MainContainer = compose(
  withState('nextButton', 'setNextButton'),
  withContext({ setNextButton: PropTypes.func }, ({ setNextButton }) => ({
    setNextButton,
  })),
  lifecycle({
    componentDidMount() {
      const element = document.getElementById('splash-screen')
      if (element && document.body) {
        document.body.removeChild(element)
      }
    },
  }),
  connector
)(MainWithRouter)
