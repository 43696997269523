// @flow
import type { AppState } from 'quick-quote/reducers'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { RadioButtonGroup } from 'common-components'
import { selectProductTermLife } from 'quick-quote/product-term-life/actions'
import { getSelectableProductUltimateGrowth, getSelectedProductUltimateGrowth } from '../selectors'
import VALUES from 'core/data-model/constants/values'
import { getSelectedDisplayProductCode } from 'quick-quote/product-selection/selectors'
import BIMESSAGES from 'core/data-model/constants/bi-messages'

export const UltimateGrowthProductSelection = ({
  getSelectableProductUltimateGrowth,
  getSelectedDisplayProductCode,
  selectProductTermLife,
}) => {
  const selectableProductUltimateGrowth = getSelectableProductUltimateGrowth

  const selectedProductCode = getSelectedProductUltimateGrowth(
    selectableProductUltimateGrowth,
    getSelectedDisplayProductCode
  )
  const productUltimateGrowth = [
    {
      text: BIMESSAGES.ULTIMATE_GROWTH_QQ_2010,
      value: VALUES.ULTIMATE_GROWTH_CODE.ULTIMATE_GROWTH_2010,
      canSelectable: selectableProductUltimateGrowth.includes(VALUES.ULTIMATE_GROWTH_CODE.ULTIMATE_GROWTH_2010),
    },
    {
      text: BIMESSAGES.ULTIMATE_GROWTH_QQ_2515,
      value: VALUES.ULTIMATE_GROWTH_CODE.ULTIMATE_GROWTH_2515,
      canSelectable: selectableProductUltimateGrowth.includes(VALUES.ULTIMATE_GROWTH_CODE.ULTIMATE_GROWTH_2515),
    },
    {
      text: BIMESSAGES.ULTIMATE_GROWTH_QQ_2525,
      value: VALUES.ULTIMATE_GROWTH_CODE.ULTIMATE_GROWTH_2525,
      canSelectable: selectableProductUltimateGrowth.includes(VALUES.ULTIMATE_GROWTH_CODE.ULTIMATE_GROWTH_2525),
    },
  ]

  return (
    <div className="form-group" data-testid="product-term-life-ultimate-growth">
      <div className="form-group-title">
        <span className="form-group-title-text">{BIMESSAGES.ULTIMATE_GROWTH_QQ_SELECT_PLAN}</span>
      </div>
      <RadioButtonGroup
        keyToGetValue="value"
        className="model-factor"
        onChange={selectProductTermLife}
        value={selectedProductCode}
        options={productUltimateGrowth}
      />
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  getSelectableProductUltimateGrowth: getSelectableProductUltimateGrowth(state),
  getSelectedDisplayProductCode: getSelectedDisplayProductCode(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  selectProductTermLife: bindActionCreators(selectProductTermLife, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(UltimateGrowthProductSelection)
