//@flow
import Mustache from 'mustache'
import MESSAGES from 'core/data-model/constants/bi-messages'
import { formatNumber } from 'core/service/lib/number-format'
import _ from 'lodash'
import type { TaxDeduction } from 'core/service/benefit-illustration'

type FlexiHealthTaxBenefitProps = {
  taxDeduction: TaxDeduction,
}

export const flexiHealthTaxBenefit = ({ taxDeduction }: FlexiHealthTaxBenefitProps) => ({
  pageBreak: 'before',
  stack: [
    { text: MESSAGES.TAX_DEDUCTION_BENEFIT_TITLE, style: 'title' },
    { text: MESSAGES.TAX_DEDUCTION_BENEFIT_SUB_TITLE, bold: true },
    {
      text: `${MESSAGES.TAX_DEDUCTION_BENEFIT_SUB_DESCRIPTION} ${MESSAGES.GIO_TAX_DEDUCTION_BENEFIT_SUB_DESCRIPTION_STRONG}`,
    },
    {
      fontSize: 14,
      table: {
        widths: [300, '*'],
        body: [
          [
            MESSAGES.FLEXI_HEALTH_TAX_BENEFIT_SUB_LIST[0].description,
            {
              text: Mustache.render(MESSAGES.FLEXI_HEALTH_TAX_BENEFIT_SUB_LIST[0].benefit, {
                value: formatNumber(taxDeduction.taxDeductionBasicPremiumForYear, 2, true),
              }),
              alignment: 'right',
            },
          ],
        ],
      },
      marginTop: 5,
      marginBottom: 5,
    },
    {
      stack: [
        { text: `${MESSAGES.TAX_DEDUCTION_BENEFIT_SUB_ADDITIONAL_HTML}`, marginBottom: 10 },
        { text: `${MESSAGES.NOTE}:` },
        {
          text: `${MESSAGES.FLEXI_HEALTH_TAX_BENEFIT_SUB_NOTES[0][0]}${MESSAGES.FLEXI_HEALTH_TAX_BENEFIT_SUB_NOTES[0][1]}`,
          marginBottom: 10,
        },
        { text: `${MESSAGES.FLEXI_HEALTH_TAX_BENEFIT_SUB_NOTES[2][0]}` },
      ],
      marginBottom: 10,
    },
  ],
  style: 'groupRow',
})

type FlexiHealthLegalDisclaimerProps = {
  isPlatinum: boolean,
}

export const flexiHealthLegalDisclaimer = ({ isPlatinum }: FlexiHealthLegalDisclaimerProps) => ({
  pageBreak: 'before',
  stack: [
    { text: MESSAGES.FLEXI_HEALTH_LEGAL_DISCLAIMER_CONDITION_TITLE, style: 'sub-title' },
    { text: MESSAGES.FLEXI_HEALTH_LEGAL_DISCLAIMER_CONDITION_SUB_TITLE, style: 'sub-title' },
    {
      ol: _.compact([
        {
          text: MESSAGES.FLEXI_HEALTH_LEGAL_DISCLAIMER_CONDITION_LIST_FIRST,
        },
        {
          text: MESSAGES.FLEXI_HEALTH_LEGAL_DISCLAIMER_CONDITION_LIST_SECOND_PDF,
        },
        {
          stack: [
            MESSAGES.FLEXI_HEALTH_LEGAL_DISCLAIMER_CONDITION_LIST_THIRD,
            {
              columns: [
                {
                  ul: MESSAGES.FLEXI_HEALTH_LEGAL_DISCLAIMER_CONDITION_LIST_THIRD_DISEASES.slice(0, 4),
                  width: 255,
                },
                {
                  ul: MESSAGES.FLEXI_HEALTH_LEGAL_DISCLAIMER_CONDITION_LIST_THIRD_DISEASES.slice(4, 8),
                  width: '*',
                },
              ],
              columnGap: 15,
              margin: [5, 0, 0, 0],
            },
          ],
        },
        isPlatinum && {
          stack: [
            MESSAGES.FLEXI_HEALTH_LEGAL_DISCLAIMER_CONDITION_LIST_FOURTH_PDF,
            {
              ul: MESSAGES.FLEXI_HEALTH_LEGAL_DISCLAIMER_CONDITION_LIST_FOURTH_DISEASES,
              marginLeft: 5,
            },
          ],
        },
      ]),
      margin: [0, 0, 0, 30],
    },
    { text: MESSAGES.FLEXI_HEALTH_LEGAL_DISCLAIMER_EXEMPTION_TITLE, style: 'sub-title' },
    { text: MESSAGES.FLEXI_HEALTH_LEGAL_DISCLAIMER_EXEMPTION_SUB_TITLE },
    {
      ol: _.compact([
        {
          text: MESSAGES.FLEXI_HEALTH_LEGAL_DISCLAIMER_EXEMPTION_LIST_FIRST,
        },
        {
          text: MESSAGES.FLEXI_HEALTH_LEGAL_DISCLAIMER_EXEMPTION_LIST_SECOND,
        },
        {
          text: MESSAGES.FLEXI_HEALTH_LEGAL_DISCLAIMER_EXEMPTION_LIST_THIRD,
        },
        {
          text: MESSAGES.FLEXI_HEALTH_LEGAL_DISCLAIMER_EXEMPTION_LIST_FORTH,
        },
        {
          text: MESSAGES.FLEXI_HEALTH_LEGAL_DISCLAIMER_EXEMPTION_LIST_FIFTH,
        },
      ]),
    },
    {
      text: MESSAGES.FLEXI_HEALTH_LEGAL_DISCLAIMER_EXEMPTION_LIST_NOTE,
    },
  ],
  style: 'groupRow',
})
type Props = {
  startDate: string,
  endDate: string,
  isBancAgent: boolean,
}

export const flexiHealthlegalConditionDisclaimer = ({ startDate, endDate, isBancAgent }: Props) => ({
  pageBreak: 'before',
  stack: [
    { text: MESSAGES.LEGAL_DISCLAIMER, style: 'title' },
    {
      ol: [
        [
          {
            text: MESSAGES.POLICY_MAY_CHANGE,
          },
          {
            separator: ['1.'],
            ol: [
              MESSAGES.AGE_HEALTH_OR_CAREER,
              Mustache.render(MESSAGES.FLEXI_HEALTH_INCREASE_IN_PREMIUM_ADDITIONAL_CONTRACT, {
                agentName: isBancAgent ? MESSAGES.FLEXI_HEALTH_BANC : MESSAGES.FLEXI_HEALTH_AGENT,
              }),
            ],
          },
        ],
        MESSAGES.PRELIMINARY_OFFER_MAY_CHANGE,
        Mustache.render(MESSAGES.FLEXI_HEALTH_OFFER_SALE_PERIOD, {
          startOffer: startDate,
          endOffer: endDate,
        }),
      ],
    },
  ],
  style: 'groupRow',
})

export const flexiHealthlegalConditionDisclaimerMarketConduct = ({ startDate, endDate, isBancAgent }: Props) => ({
  stack: [
    { text: MESSAGES.LEGAL_DISCLAIMER, style: 'title' },
    {
      ol: [
        Mustache.render(MESSAGES.FLEXI_HEALTH_INCREASE_IN_PREMIUM_ADDITIONAL_CONTRACT, {
          agentName: isBancAgent ? MESSAGES.FLEXI_HEALTH_BANC : MESSAGES.FLEXI_HEALTH_AGENT,
        }),
        MESSAGES.PRELIMINARY_OFFER_MAY_CHANGE,
        Mustache.render(MESSAGES.FLEXI_HEALTH_OFFER_SALE_PERIOD, {
          startOffer: startDate,
          endOffer: endDate,
        }),
      ],
    },
  ],
  style: 'groupRow',
})
