// @flow
export type Table = {
  headerRows: number,
  widths: string[],
  body: *,
}

export type OrderList = {
  ol: string[],
  start: number,
}

type Field = {
  label: string,
  value: string,
  bold: ?boolean,
}

type FieldWithMultipleValues = {
  label: string,
  values: string[],
}

type FieldWithStyle = {
  label: {
    text: string,
    style: ?string,
  },
  value: {
    text: string,
    style: ?string,
  },
}

const buildStackValueTextWithStyle = (field: FieldWithStyle) => {
  return { text: field.value.text, style: field.value.style }
}

const buildStackLabelTextWithStyle = (field: FieldWithStyle) => {
  return { text: field.label.text, style: field.label.style }
}
const buildStackLabel = (value: string) => {
  return { text: value, bold: false }
}

const buildStackValue = (value: string) => {
  return { text: value, bold: true }
}

const buildStackLabelAndValue = (label: string, value: string, bold: boolean = true) => {
  return { text: `${label} ${value}`, bold: bold }
}

export const lineBreak = () => ({ text: '\n' })

export const buildStackWithMultipleValues = (data: FieldWithMultipleValues[]) =>
  data.map((field) => ({
    stack: [buildStackLabel(field.label), ...field.values.map(buildStackValue)],
    width: 'auto',
  }))

export const buildStacksWithStyle = (data: FieldWithStyle[]) =>
  data.map((field) => ({
    stack: [buildStackLabelTextWithStyle(field), buildStackValueTextWithStyle(field)],
    width: 'auto',
  }))

export const buildStacks = (data: Field[]) =>
  data.map((field) => ({
    stack: [buildStackLabel(field.label), buildStackValue(field.value)],
    width: field.width || 'auto',
  }))

export const buildColumn = (data: Field[]) =>
  data.map((field) => ({
    stack: [buildStackLabelAndValue(field.label, field.value, field.bold)],
    width: field.width || 'auto',
  }))

export const buildHeaderTable = (headers: string[]) => {
  return headers.map((header) => ({
    text: header,
    style: 'tableHeader',
  }))
}

export const transformOrderListToString = (orderList: OrderList, index: number): { text: string } => {
  return {
    text: orderList.ol.length === 0 ? '' : orderList.ol[index],
    style: 'indent',
  }
}
