// @flow
import type { Age } from 'core/data-model/insured'
import { FormGroup, FormFeedback } from 'reactstrap'
import { connect } from 'react-redux'
import { compose, withStateHandlers, lifecycle } from 'recompose'
import { renderUnit, isZeroDay } from 'core/service/insured/age'
import messages from 'core/data-model/constants/messages'
import Date from '../../../common-components/date-dropdown'
import { dateFormatterPattern } from 'core/service/lib/date'
import { Dropdown } from 'common-components'
import type { AppState } from 'quick-quote'
import type { DisplayProductQuery } from 'core/service'
import { getBasicPlan } from 'core/service'
import { isReviseQQFlow } from 'quick-quote/selectors'
import { _getEntryAge } from 'core/service/insured/validation'
import { getSelectedDisplayProductQuery } from 'quick-quote/product-selection/selectors'
import { convertAgeToBirthdate, getAgeOptions } from '../utils'

type Props = {
  birthdate: string,
  editBirthdate: (string) => void,
  age: Age,
  errorBirthdateMessage: string,
  isReviseFNAOnQQ: Boolean,
  isPayerSection: Boolean,
  displayProductQuery: DisplayProductQuery,
  entryAgeYear: {
    min: number,
    max: number,
  },
  setEntryAgeYear: (min, max) => void,
}
const Birthdate = ({ birthdate, editBirthdate, age, errorBirthdateMessage, isReviseFNAOnQQ, entryAgeYear }: Props) => {
  const ageOptions = getAgeOptions(entryAgeYear.min, entryAgeYear.max)

  return (
    <FormGroup className={`birthdate-selection ${errorBirthdateMessage !== '' ? 'has-danger' : ''}`}>
      {isReviseFNAOnQQ ? (
        <>
          <div>
            <div className="birthdate-input">
              <label>{messages.AGE_SELECT_LABEL}</label>
              <Dropdown
                testId="ageSelect"
                options={ageOptions}
                onChange={(value) => {
                  const newBirthdate = convertAgeToBirthdate(value)
                  editBirthdate(newBirthdate)
                }}
                value={errorBirthdateMessage !== '' ? '' : age.unit === 'year' ? age.value : 0}
                placeHolder="กรุณาเลือก"
              />
            </div>
            <div className="insured-age-select">
              <p>{renderUnit('year')}</p>
            </div>
          </div>
          <FormFeedback className="validation-message">{errorBirthdateMessage}</FormFeedback>
        </>
      ) : (
        <>
          <div className="birthdate-input">
            <label>{messages.BIRTHDATE_LABEL}</label>
            <Date
              id="birthdate"
              className="form-control"
              type="tel"
              pattern={dateFormatterPattern}
              placeholder={messages.BIRTHDATE_PLACEHOLDER}
              value={birthdate}
              onChange={(e) => {
                editBirthdate(e.target.value)
              }}
            />
            <FormFeedback className="validation-message">{errorBirthdateMessage}</FormFeedback>
          </div>
          <div className="insured-age">
            <label>อายุ</label>
            <p className="age-number">
              {isZeroDay(age) ? '-' : age.value}&nbsp;
              <small>{isZeroDay(age) ? renderUnit('year') : renderUnit(age.unit)}</small>
            </p>
          </div>
        </>
      )}
    </FormGroup>
  )
}

const mapStateToProps = (state: AppState): { isReviseFNAOnQQ: boolean } => ({
  isReviseFNAOnQQ: isReviseQQFlow(state),
  displayProductQuery: getSelectedDisplayProductQuery(state),
})
export default compose(
  connect(mapStateToProps),
  withStateHandlers(
    {
      entryAgeYear: { min: 0, max: 99 },
    },
    {
      setEntryAgeYear: (state) => (min, max) => ({ entryAgeYear: { min, max } }),
    }
  ),

  lifecycle({
    componentDidMount() {
      const { displayProductQuery, isPayerSection, isReviseFNAOnQQ, setEntryAgeYear } = this.props
      const setupAgeYear = async () => {
        const basicPlan = await getBasicPlan(displayProductQuery)
        const entryAge = await _getEntryAge(basicPlan, !!isPayerSection)
        if (entryAge) {
          const minimumAge = entryAge.minimum.unit !== 'year' ? 0 : entryAge.minimum.value
          const maximumAge = entryAge.maximum.value

          setEntryAgeYear(minimumAge, maximumAge)
        }
      }

      if (isReviseFNAOnQQ) {
        setupAgeYear()
      }
    },
  })
)(Birthdate)
