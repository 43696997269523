import _ from 'lodash'
import { reject, flow, mapValues, flatMap, map, uniqBy, isPlainObject, isEmpty, endsWith } from 'lodash/fp'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, branch, lifecycle, withProps, withHandlers, renderNothing } from 'recompose'
import Dialog from 'e-submission/components/Dialog'
import { ModalHeader, ModalBody } from 'reactstrap'

import { getAuraInvalidField } from 'e-submission/apps/selectors'
import { validateApplication } from 'e-submission/apps/actions'

import { getIcon } from 'e-submission/components/Icon'
import Content from 'e-submission/components/Layout/Content'
import EditCard from 'e-submission/components/Card/EditCard'
import { getToggles } from 'quick-quote/feature-toggles'

const Status = styled.div`
  text-align: center;
  padding: 30px 0px;
  div:last-child {
    padding-top: 30px;
  }
`

const Component = styled(({ className, invalid, history, toggle }) => (
  <Dialog className={className} isOpen={!_.isEmpty(invalid)}>
    <ModalHeader toggle={toggle}>
      <div>กรอกข้อมูลไม่ครบ</div>
    </ModalHeader>
    <ModalBody>
      <Status>
        {getIcon('formInvalid')}
        <div>
          ไม่สามารถสร้างเอกสารได้
          <br />
          <b>กรุณากลับไปแก้ไขข้อมูลด้านล่างให้สมบูรณ์</b>
        </div>
      </Status>
      <Content>
        {_.map(invalid, ({ fields, label }, pageId) => (
          <EditCard key={pageId} title={label} onClick={() => history.push(pageId)}>
            <ul>
              {_.map(fields, (label, index) => (
                <li key={index}>
                  {getToggles().ENABLE_SHARED_AGENT_SALE_VALIDATION && pageId === 'agent' && label === 'นายหน้า'
                    ? 'กรุณาตรวจสอบสิทธิการขายของตัวแทนอีกท่าน'
                    : label}
                </li>
              ))}
            </ul>
          </EditCard>
        ))}
      </Content>
    </ModalBody>
  </Dialog>
))`
  .modal-body {
    padding: 0px 0px 32px 0px;
    background: #f5f5f5;
  }
  ul {
    padding-left: 15px;
    margin-bottom: 10px;
    li {
      padding-bottom: 10px;
    }
  }
`

export default compose(
  withRouter,
  connect(
    (state) => ({ invalid: getAuraInvalidField(state) }),
    (dispatch) => ({
      validateApplication: () => dispatch(validateApplication()),
    })
  ),
  branch(({ invalid }) => _.isEmpty(invalid), renderNothing),
  lifecycle({
    componentDidMount() {
      this.props.validateApplication()
    },
  }),
  withProps(({ history, invalid }) => ({
    invalid: mapValues(
      ({ sections, label }) => ({
        label,
        fields: flow(
          map(({ id, fields, label, heading }) => {
            if (endsWith(']', id)) {
              return heading
            }

            if (!isEmpty(label)) {
              return label
            }

            return { id, fields }
          }),
          reject(isEmpty),
          uniqBy((value) => (isPlainObject(value) ? value.id : value)),
          flatMap((value) => (isPlainObject(value) ? map('label', value.fields) : value))
        )(sections),
      }),
      invalid
    ),
  })),
  withHandlers({
    toggle: ({ history, invalid }) => () => history.push(_.keys(invalid)[0]),
  })
)(Component)
