import { benefitSummarySection } from './benefit-summary-section'
import { policyValueTableSection, iSmartPolicyValueTableSection } from './policy-value-table-section'
import { investmentBenefitTableSection } from './invesment-benefit-table-section'
import { basicPlanConditionSection } from './basicplan-condition-section'
import { basicPlanConditionGIOSection } from './basicplan-condition-gio-section'
import { basicPlanConditionISmartSection } from './basicplan-condition-ismart-section'
import { profileSnapshotSection } from './profile-snapshot-section'
import { productBenefitsSection } from './product-benefit-section'

export const section = {
  policyValueTable: policyValueTableSection,
  benefitSummary: benefitSummarySection,
  investmentBenefitTable: investmentBenefitTableSection,
  basicPlanCondition: basicPlanConditionSection,
  basicPlanConditionGIO: basicPlanConditionGIOSection,
  basicPlanConditionISmart: basicPlanConditionISmartSection,
  profileSnapshot: profileSnapshotSection,
  basicPlanBenefit: productBenefitsSection,
  iSmartPolicyValueTable: iSmartPolicyValueTableSection,
}
