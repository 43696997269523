import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import styled from 'styled-components'
import _getOr from 'lodash/fp/getOr'

import Button from '../Button'
import { getCurrentApp } from '../../apps/selectors'
import { verifyEkyc } from '../../apps/actions'
import { FormFeedback } from 'reactstrap'
import { getAppConfig } from '../../../deploy-env/app-config'
import InsuredAndPayerDetailsConfirmation from 'e-submission/containers/Dialog/InsuredAndPayerDetailsConfirmation'

const RefreshButton = styled(Button)`
  margin-top: 0.5rem;
  float: right;
  font-weight: bold;
`

function findParentNodeTarget(domElement) {
  if (!domElement) return null
  if (domElement && domElement.className.match(/FormEngine/)) {
    return domElement
  } else {
    const parentDom = domElement.parentNode
    return findParentNodeTarget(parentDom)
  }
}

class _EkycDocumentFooter extends React.Component {
  constructor(props) {
    super(props)
    this.refRootComponent = null
    this.setRootComponentRef = (element) => {
      this.refRootComponent = element
    }
    this.state = {
      isDisable: false,
    }
  }

  componentDidMount() {
    const formEngineDom = findParentNodeTarget(this.refRootComponent)
    if (formEngineDom) formEngineDom.style.maxWidth = '900px'
  }

  componentWillUnmount() {
    const formEngineDom = findParentNodeTarget(this.refRootComponent)
    if (formEngineDom) formEngineDom.style.maxWidth = '680px'
  }

  render() {
    const { insuredDopaRequestFailed, payerDopaRequestFailed, verify, showRefreshButton } = this.props

    return (
      <div>
        {insuredDopaRequestFailed || payerDopaRequestFailed ? (
          <FormFeedback className="info-message attached-icon">
            <p>ขณะนี้ระบบ DOPA ขัดข้อง ท่านสามารถกดยืนยัน eKYC เพื่อดำเนินการยืนยันตัวตนในขั้นตอนต่อไป</p>
          </FormFeedback>
        ) : null}
        {showRefreshButton ? (
          <div style={{ textAlign: 'right' }}>
            <RefreshButton
              disabled={this.state.isDisable}
              onClick={() => {
                this.setState({ isDisable: true })
                verify('insured')
                verify('payer')
                setTimeout(() => this.setState({ isDisable: false }), getAppConfig().VERIFY_EKYC_INTERVAL_TIME)
              }}
            >
              อัปเดตสถานะ
            </RefreshButton>
          </div>
        ) : null}
        <InsuredAndPayerDetailsConfirmation />
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const app = getCurrentApp(state)
  const insuredDopaRequestFailed = _getOr(
    false,
    'ekycInfo.insured.verificationDetail.dopaResult.dopaRequestFailed',
    app
  )
  const payerDopaRequestFailed = _getOr(false, 'ekycInfo.payer.verificationDetail.dopaResult.dopaRequestFailed', app)
  const showRefreshButton =
    _getOr(false, 'ekycInfo.payer.proprietorId', app) || _getOr(false, 'ekycInfo.insured.proprietorId', app)
  return {
    insuredDopaRequestFailed,
    payerDopaRequestFailed,
    showRefreshButton,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<*>, { ...props }) => ({
  verify: (insuredOrPayer) => dispatch(verifyEkyc(insuredOrPayer)),
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(_EkycDocumentFooter)
