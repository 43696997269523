import moment from 'moment'
import { buddhistToChristianDate, dateToMoment, MINIMUM_BUDDHIST_YEAR_IN_ADVISOR_ZONE } from 'core/service/lib/date'

var DateFormatter = function(datePattern) {
  var owner = this

  owner.date = []
  owner.blocks = []
  owner.datePattern = datePattern
  owner.initBlocks()
}

DateFormatter.prototype = {
  initBlocks: function() {
    var owner = this
    owner.datePattern.forEach(function(value) {
      if (value === 'Y') {
        owner.blocks.push(4)
      } else {
        owner.blocks.push(2)
      }
    })
  },

  getISOFormatDate: function() {
    var owner = this,
      date = owner.date

    return date[2] ? date[2] + '-' + owner.addLeadingZero(date[1]) + '-' + owner.addLeadingZero(date[0]) : ''
  },

  getBlocks: function() {
    return this.blocks
  },

  getDayMonthYear: function(value) {
    let fullYearDone = false
    const result = this.correctValue(value)
    let date = this.getSeparatedDateMonthYear(result)
    if (result.length === 8) {
      fullYearDone = this.getFullYearDone(result)
    }
    if (date.length === 0) {
      return { day: result, month: '', year: '' }
    } else {
      date = this.addLeadingZeroToDate(date, fullYearDone)
      return { day: date[0], month: date[1], year: date[2] }
    }
  },

  correctValue: function(value) {
    var owner = this,
      result = ''

    value = value.replace(/[^\d]/g, '')

    owner.blocks.forEach(function(length, index) {
      if (value.length > 0) {
        var sub = value.slice(0, length),
          sub0 = sub.slice(0, 1),
          rest = value.slice(length)

        switch (owner.datePattern[index]) {
          case 'd':
            if (sub === '00') {
              sub = '01'
            } else if (parseInt(sub0, 10) > 3) {
              sub = '0' + sub0
            } else if (parseInt(sub, 10) > 31) {
              sub = '31'
            }

            break

          case 'm':
            if (sub === '00') {
              sub = '01'
            } else if (parseInt(sub0, 10) > 1) {
              sub = '0' + sub0
            } else if (parseInt(sub, 10) > 12) {
              sub = '12'
            }

            break
        }

        result += sub

        // update remaining string
        value = rest
      }
    })
    return result
  },

  getValidatedDate: function(value) {
    const result = this.correctValue(value)

    return this.getFixedDateString(result)
  },

  getSeparatedDateMonthYear: function(value) {
    var owner = this,
      datePattern = owner.datePattern,
      dayStartIndex = 0,
      monthStartIndex = 0,
      day,
      month,
      year,
      date = []

    // mm-dd || dd-mm
    if (value.length === 4 && datePattern[0].toLowerCase() !== 'y' && datePattern[1].toLowerCase() !== 'y') {
      dayStartIndex = datePattern[0] === 'd' ? 0 : 2
      monthStartIndex = 2 - dayStartIndex
      day = parseInt(value.slice(dayStartIndex, dayStartIndex + 2), 10)
      month = parseInt(value.slice(monthStartIndex, monthStartIndex + 2), 10)

      date = this.getFixedDate(day, month, 0)
    }

    // yyyy-mm-dd || yyyy-dd-mm || mm-dd-yyyy || dd-mm-yyyy || dd-yyyy-mm || mm-yyyy-dd
    if (value.length === 8) {
      const startIndexes = this.getStartIndex()

      day = parseInt(value.slice(startIndexes.day, startIndexes.day + 2), 10)
      month = parseInt(value.slice(startIndexes.month, startIndexes.month + 2), 10)
      year = parseInt(value.slice(startIndexes.year, startIndexes.year + 4), 10)

      date = this.getFixedDate(day, month, year)
    }

    return date
  },

  getStartIndex: function() {
    let owner = this,
      dayIndex = 0,
      monthIndex = 0,
      yearIndex = 0,
      datePattern = owner.datePattern
    datePattern.forEach(function(type, index) {
      switch (type) {
        case 'd':
          dayIndex = index
          break
        case 'm':
          monthIndex = index
          break
        default:
          yearIndex = index
          break
      }
    })

    const yearStartIndex = yearIndex * 2
    const dayStartIndex = dayIndex <= yearIndex ? dayIndex * 2 : dayIndex * 2 + 2
    const monthStartIndex = monthIndex <= yearIndex ? monthIndex * 2 : monthIndex * 2 + 2

    return { day: dayStartIndex, month: monthStartIndex, year: yearStartIndex }
  },

  getFullYearDone: function(value) {
    const startIndexes = this.getStartIndex()

    return value.slice(startIndexes.year, startIndexes.year + 4).length === 4
  },

  addLeadingZeroToDate: function(date, fullYearDone = false) {
    const owner = this,
      datePattern = owner.datePattern
    datePattern.forEach(function(current) {
      switch (current) {
        case 'd':
          date[0] = owner.addLeadingZero(date[0])
          break
        case 'm':
          date[1] = owner.addLeadingZero(date[1])
          break
        default:
          date[2] = fullYearDone ? owner.addLeadingZeroForYear(date[2]) : ''
          break
      }
    })
    return date
  },

  getFixedDateString: function(value) {
    var owner = this,
      datePattern = owner.datePattern,
      date = [],
      fullYearDone = false

    date = this.getSeparatedDateMonthYear(value)

    if (value.length === 8) {
      fullYearDone = this.getFullYearDone(value)
    }

    owner.date = date

    if (date.length === 0) {
      return value
    } else {
      date = owner.addLeadingZeroToDate(date, fullYearDone)
      return datePattern.reduce(function(previous, current) {
        switch (current) {
          case 'd':
            return previous + date[0]
          case 'm':
            return previous + date[1]
          default:
            return previous + (fullYearDone ? date[2] : '')
        }
      }, '')
    }
  },

  getFixedDate: function(day, month, year) {
    day = Math.min(day, 31)
    month = Math.min(month, 12)
    year = parseInt(year || 0, 10)

    let checkYear = year

    const dateMoment = moment()
      .year(year)
      .date(day)
      .month(month - 1)
    if (dateMoment.year() >= MINIMUM_BUDDHIST_YEAR_IN_ADVISOR_ZONE) {
      checkYear = dateToMoment(buddhistToChristianDate(dateMoment.format('DD/MM/YYYY'))).year()
    }

    if ((month < 7 && month % 2 === 0) || (month > 8 && month % 2 === 1)) {
      day = Math.min(day, month === 2 ? (this.isLeapYear(checkYear) ? 29 : 28) : 30)
    }

    return [day, month, year]
  },

  isLeapYear: function(year) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0
  },

  addLeadingZero: function(number) {
    return (number < 10 ? '0' : '') + number
  },

  addLeadingZeroForYear: function(number) {
    return (number < 10 ? '000' : number < 100 ? '00' : number < 1000 ? '0' : '') + number
  },
}

export default DateFormatter
