import Oidc, { UserManager, CordovaPopupNavigator, CordovaIFrameNavigator } from 'oidc-client'

const basePath = '/advisors/quote'

const port = window.location.port ? `:${window.location.port}` : ''
const host = window.cordova
  ? 'http://localhost'
  : `${window.location.protocol}//${window.location.hostname}${port}${basePath}`

Oidc.Log.logger = console
Oidc.Log.level = Oidc.Log.INFO

const getUserManagerConfig = (config = {}) => ({
  authority: config.AUTH_AUTHORITY,
  client_id: 'iCM',
  redirect_uri: `${host}/identity_callback.html`,
  response_type: 'code id_token token',
  scope: 'openid profile',
  post_logout_redirect_uri: `${host}/identity_signout.html`,
  popup_redirect_uri: `${host}/identity_callback.html`,
  popupWindowFeatures: window.cordova
    ? 'location=no,toolbar=yes,zoom=no,closebuttoncaption=Skip,toolbarposition=top'
    : undefined,
  popupWindowTarget: 'iCM',
  automaticSilentRenew: true,
  accessTokenExpiringNotificationTime: 50,
  silent_redirect_uri: `${host}/identity_renew.html`,
  loadUserInfo: true,
  popupNavigator: window.cordova ? new CordovaPopupNavigator() : undefined,
  iframeNavigator: window.cordova ? new CordovaIFrameNavigator() : undefined,
})

export const processSilentRenew = () => {
  const dummyUserManager = new UserManager()
  dummyUserManager.signinSilentCallback()
}

export default (config) => {
  const userManagerConfig = getUserManagerConfig(config)
  return new UserManager(userManagerConfig)
}
