// @flow
import type { AppState } from 'quick-quote/reducers'
import type { ModelFactor } from 'core/data-model/basic-plan'
import { createSelector } from 'reselect'
import { getSelectedDisplayProduct } from './selected-display-product'
import { getDistributorGroups, getUserDistributorGroup } from 'identity/selectors'

export const getModelFactors = (state: AppState): ModelFactor[] => state.modelFactors.payload

export const getModelFactorsForUser = createSelector(
  getModelFactors,
  getSelectedDisplayProduct,
  getUserDistributorGroup,
  getDistributorGroups,
  (modelFactors: ModelFactor[], basicPlan, userDistributorGroup, distributorGroups): ModelFactor[] => {
    const distributorGroupInfoForUser = distributorGroups.find(
      (distributorGroup) => distributorGroup.id === userDistributorGroup
    )
    const isModelFactorForBasicPlan = (modelFactor) => basicPlan.modelFactors.indexOf(modelFactor.id) > -1
    const isModelFactorForUserDistributorGroup = (modelFactor) =>
      !distributorGroupInfoForUser || distributorGroupInfoForUser.modelFactors.includes(modelFactor.id)

    return modelFactors.filter(isModelFactorForBasicPlan).filter(isModelFactorForUserDistributorGroup)
  }
)
