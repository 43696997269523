import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { endsWith, get, omit } from 'lodash/fp'
import { compose, lifecycle, mapProps, withHandlers } from 'recompose'
import { withModalToggle } from 'lib/with-hoc'
import { RadioButtonGroup } from 'common-components'
import { _Dialog } from './_Dialog'
import { getToggles } from 'quick-quote/feature-toggles'
import { setAppValue } from '../../../apps/actions'
import store from 'e-submission/store'

const Component = styled(
  ({
    sections,
    onChange,
    dispatch,
    toggleModal,
    isModalOpen,
    subpage,
    customButtons,
    okButton,
    remoteSelling,
    ...props
  }) => (
    <div>
      <RadioButtonGroup {...props} onChange={onChange} />
      {sections && (
        <_Dialog
          {...props}
          sections={sections}
          toggleModal={toggleModal}
          isModalOpen={isModalOpen}
          subpage={subpage}
          customButtons={customButtons}
          okButton={okButton}
          remoteSelling={remoteSelling}
        />
      )}
    </div>
  )
)`
  .modal-header {
    .modal-title {
      flex-direction: row;
      display: flex;
    }
    .icon,
    .btn-left {
      z-index: 999;
      cursor: pointer;
      height: 40px;
    }
    span {
      margin-left: -28px;
      flex: 1 1 auto;
    }
  }
  .modal-footer {
    justify-content: space-between;
  }
`

export default (hoc) =>
  compose(
    withModalToggle,
    withRouter,
    lifecycle({
      componentDidMount() {
        if (endsWith(this.props.subpage, this.props.history.location.pathname)) {
          this.props.toggleModal()
        }
      },
      UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.isModalOpen && !endsWith(nextProps.subpage, nextProps.history.location.pathname)) {
          this.props.toggleModal()
        }
        if (!nextProps.isModalOpen && endsWith(nextProps.subpage, nextProps.history.location.pathname)) {
          this.props.toggleModal()
        }

        if (
          getToggles().ENABLE_CYBERSOURCE_RETRY &&
          nextProps.isModalOpen &&
          nextProps.cybsRetryUntilCancel &&
          nextProps.subpage === 'payment'
        ) {
          store.dispatch(setAppValue(`cybsRetryUntilCancel`, false))
          this.props.toggleModal()
        }
      },
    }),
    withHandlers({
      toggleModal: ({ toggleModal, history, subpage }) => () => {
        if (endsWith(subpage, history.location.pathname)) {
          history.replace(history.location.pathname.replace(`/${subpage}`, ''))
        } else {
          history.push(`${history.location.pathname}/${subpage}`)
        }
        toggleModal()
      },
    }),
    withHandlers({
      onChange: ({ setValue, options, onChange, toggleModal, dispatch, subpage, title }) => (value, index) => {
        const selectedOption = options[index]
        const openDialog = selectedOption.openDialog || false
        setValue(options[index])
        onChange({ value, index, dispatch })
        if (openDialog) {
          if (getToggles().ENABLE_CYBERSOURCE_RETRY && subpage === 'payment' && title === 'ชำระเบี้ยงวดแรก') {
            dispatch({ type: 'START_CYBS_CHECK_RETRY' })
          }
          toggleModal()
        }
      },
    }),
    mapProps(({ value, ...props }) => {
      return {
        ...omit(['match', 'history', 'location', 'staticContext'])(props),
        value: get('value')(value),
      }
    }),
    hoc
  )(Component)
