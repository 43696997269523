// @flow

import type { User } from 'oidc-client'
import type { DistributorGroup, DistributorGroupType } from 'core/data-model/distributor'
import type { FSE, Office, AdvisorApiUserInfo, User as UserType } from 'core/data-model/identity'
import type { IdToken } from 'core/data-model/identity/types'

export type Action =
  | UserExpired
  | RedirectSuccess
  | SilentRenewError
  | SessionTerminated
  | UserExpiring
  | UserFound
  | LoadingUser
  | UserSignedOut
  | LoadUserError
  | UpdateUserImage
  | UpdateUser
  | SetDistributorGroup
  | UpdateFSE
  | UpdateOffice
  | SetPositionCode

export const USER_EXPIRED = 'USER_EXPIRED'
export type UserExpired = {
  type: 'USER_EXPIRED',
}

export const REDIRECT_SUCCESS = 'REDIRECT_SUCCESS'
export type RedirectSuccess = {
  type: 'REDIRECT_SUCCESS',
  payload: User,
}
export const SILENT_RENEW_ERROR = 'SILENT_RENEW_ERROR'
export type SilentRenewError = {
  type: 'SILENT_RENEW_ERROR',
}
export const SESSION_TERMINATED = 'SESSION_TERMINATED'
export type SessionTerminated = {
  type: 'SESSION_TERMINATED',
}
export const USER_EXPIRING = 'USER_EXPIRING'
export type UserExpiring = {
  type: 'USER_EXPIRING',
}
export const USER_FOUND = 'USER_FOUND'
export type UserFound = {
  type: 'USER_FOUND',
  payload: User,
}
export const USER_FOUND_AUTHZ_CODE_FLOW = 'USER_FOUND_AUTHZ_CODE_FLOW'
export type UserFoundAuthzCodeFlow = {
  type: 'USER_FOUND_AUTHZ_CODE_FLOW',
  payload: IdToken,
}
export const UPDATE_ADDITIONAL_USER_DETAILS = 'UPDATE_ADDITIONAL_USER_DETAILS'
export type UpdateAdditionalUserDetails = {
  type: 'UPDATE_ADDITIONAL_USER_DETAILS',
}
export const LOADING_USER = 'LOADING_USER'
export type LoadingUser = {
  type: 'LOADING_USER',
}
export const USER_SIGNED_OUT = 'USER_SIGNED_OUT'
export type UserSignedOut = {
  type: 'USER_SIGNED_OUT',
}
export const LOAD_USER_ERROR = 'LOAD_USER_ERROR'
export type LoadUserError = {
  type: 'LOAD_USER_ERROR',
  payload: string,
}
export const UPDATE_USER_IMAGE = 'UPDATE_USER_IMAGE'
export type UpdateUserImage = {
  type: 'UPDATE_USER_IMAGE',
  payload: string,
}

export const UPDATE_FSE = 'UPDATE_FSE'
export type UpdateFSE = {
  type: 'UPDATE_FSE',
  payload: ?FSE,
}

export const UPDATE_USER = 'UPDATE_USER'
export type UpdateUser = {
  type: 'UPDATE_USER',
  payload: User,
}

export const SELECT_FSE = 'SELECT_FSE'
export type SelectFSE = {
  type: 'SELECT_FSE',
  payload: AdvisorApiUserInfo,
}

export const UPDATE_BANK_BRANCH = 'UPDATE_BANK_BRANCH'
export type UpdateOffice = {
  type: 'UPDATE_BANK_BRANCH',
  payload: Office,
}

export const SET_POSITION_CODE = 'SET_POSITION_CODE'
export type SetPositionCode = {
  type: 'SET_POSITION_CODE',
  payload: ?string,
}

export const SET_REGISTRATION = 'SET_REGISTRATION'
export type SetRegistration = {
  type: 'SET_REGISTRATION',
  payload: ?(Object[]),
}

export const UPDATE_STAFF_ID = 'UPDATE_STAFF_ID'
export type UpdateStaffId = {
  type: 'UPDATE_STAFF_ID',
  payload: string,
}

export const UPDATE_STAFF_NAME = 'UPDATE_STAFF_NAME'
export type UpdateStaffName = {
  type: 'UPDATE_STAFF_NAME',
  payload: string,
}

export const UPDATE_STAFF_SURNAME = 'UPDATE_STAFF_SURNAME'
export type UpdateStaffSurname = {
  type: 'UPDATE_STAFF_SURNAME',
  payload: string,
}

export const UPDATE_STAFF_POSITION = 'UPDATE_STAFF_POSITION'
export type UpdateStaffPosition = {
  type: 'UPDATE_STAFF_POSITION',
  payload: string,
}

export const UPDATE_CHECK_NATIVE_APPVERSION_USER = 'UPDATE_CHECK_NATIVE_APPVERSION_USER'

export const setPositionCode = (positionCode: ?string): SetPositionCode => ({
  type: SET_POSITION_CODE,
  payload: positionCode,
})

export const setRegistration = (registration: ?(Object[])): SetRegistration => ({
  type: SET_REGISTRATION,
  payload: registration,
})

export const updateUserImage = (userImage: string): UpdateUserImage => ({
  type: UPDATE_USER_IMAGE,
  payload: userImage,
})

export const updateUser = (user: UserType) => ({
  type: UPDATE_USER,
  payload: user,
})

export const updateCheckNativeAppVersionUser = () => ({
  type: UPDATE_CHECK_NATIVE_APPVERSION_USER,
})

export const userExpired = () => ({
  type: USER_EXPIRED,
})

export const redirectSuccess = (user: User) => ({
  type: REDIRECT_SUCCESS,
  payload: user,
})

export const updateAdditionalUserDetails = (): UpdateAdditionalUserDetails => ({
  type: UPDATE_ADDITIONAL_USER_DETAILS,
})

export const userFoundAuthzCodeFlow = (idTokenInfo: IdToken): UserFoundAuthzCodeFlow => ({
  type: USER_FOUND_AUTHZ_CODE_FLOW,
  payload: idTokenInfo,
})

export const userFound = (user: User) => ({
  type: USER_FOUND,
  payload: user,
})

export const silentRenewError = (error: string) => ({
  type: SILENT_RENEW_ERROR,
  payload: error,
})

export const sessionTerminated = () => ({
  type: SESSION_TERMINATED,
})

export const userExpiring = () => ({
  type: USER_EXPIRING,
})

export const loadingUser = () => ({
  type: LOADING_USER,
})

export const userSignedOut = () => ({
  type: USER_SIGNED_OUT,
})

export const loadUserError = () => ({
  type: LOAD_USER_ERROR,
})

export const GET_DISTRIBUTOR_GROUPS_REQUEST = 'GET_DISTRIBUTOR_GROUPS_REQUEST'
export type GetDistributorGroupsRequest = {
  type: 'GET_DISTRIBUTOR_GROUPS_REQUEST',
}

export const GET_DISTRIBUTOR_GROUPS_SUCCESS = 'GET_DISTRIBUTOR_GROUPS_SUCCESS'
export type GetDistributorGroupsSuccess = {
  type: 'GET_DISTRIBUTOR_GROUPS_SUCCESS',
  payload: DistributorGroup[],
}

export const GET_DISTRIBUTOR_GROUPS_FAILED = 'GET_DISTRIBUTOR_GROUPS_FAILED'
export type GetDistributorGroupsFailed = {
  type: 'GET_DISTRIBUTOR_GROUPS_FAILED',
  error: Object,
}

export const SET_DISTRIBUTOR_GROUP = 'SET_DISTRIBUTOR_GROUP'
export type SetDistributorGroup = {
  type: 'SET_DISTRIBUTOR_GROUP',
  payload: DistributorGroupType,
}

export const getDistributorGroupsRequest = (): GetDistributorGroupsRequest => ({
  type: GET_DISTRIBUTOR_GROUPS_REQUEST,
})

export const getDistributorGroupsSuccess = (distributorGroups: DistributorGroup[]): GetDistributorGroupsSuccess => ({
  type: GET_DISTRIBUTOR_GROUPS_SUCCESS,
  payload: distributorGroups,
})

export const getDistributorGroupsFailed = (error: Object): GetDistributorGroupsFailed => ({
  type: GET_DISTRIBUTOR_GROUPS_FAILED,
  error,
})

export const setDistributorGroup = (distributorGroupType: DistributorGroupType): SetDistributorGroup => ({
  type: SET_DISTRIBUTOR_GROUP,
  payload: distributorGroupType,
})

export const updateFSE = (fse: ?FSE): UpdateFSE => ({
  type: UPDATE_FSE,
  payload: fse,
})

export const selectFSE = (fse: AdvisorApiUserInfo): SelectFSE => ({
  type: SELECT_FSE,
  payload: fse,
})

export const updateOffice = (office: Office): UpdateOffice => ({
  type: UPDATE_BANK_BRANCH,
  payload: office,
})

export const updateStaffId = (staffId: string): UpdateStaffId => ({
  type: UPDATE_STAFF_ID,
  payload: staffId,
})

export const updateStaffName = (staffName: string): UpdateStaffName => ({
  type: UPDATE_STAFF_NAME,
  payload: staffName,
})

export const updateStaffSurname = (staffSurname: string): UpdateStaffSurname => ({
  type: UPDATE_STAFF_SURNAME,
  payload: staffSurname,
})

export const updateStaffPosition = (staffPosition: string): UpdateStaffPosition => ({
  type: UPDATE_STAFF_POSITION,
  payload: staffPosition,
})
