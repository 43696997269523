import _ from 'lodash'
import { connect } from 'react-redux'
import { FormGroup } from 'reactstrap'
import { Dropdown, Checkbox } from 'common-components'
import RiderPremium from 'quick-quote/product-common/coverage-plan/components/rider/rider-premium'
import { getAge } from 'quick-quote/insured-information/selectors'
import { isAllowOptionalIHealtyUltra } from 'identity/selectors'
import { editRiderSumAssured, editRiderSelectedPlan } from 'quick-quote/product-common/coverage-plan/actions'
import RiderMessage from 'quick-quote/product-common/coverage-plan/components/rider/rider-validation-message'
import MESSAGES from 'core/data-model/constants/bi-messages'
import { getiHealthyUltraPackage, getMHPPackageRiderAvaliable } from './mhp'

const MHPRider = ({
  className,
  rider,
  riderChecked,
  toggleRider,
  eligibilityErrors,
  validationErrors,
  additionalInformation,
  isRequire,
  planOptions,
  areasOfCover,
  planOptionals,
  allowOptionalIHealthyUltra,
  editHealthPackage,
  ...props
}) => (
  <FormGroup className={`${className}`} {...props}>
    <div className="label">
      <Checkbox
        id={rider.code}
        checked={riderChecked}
        disabled={!rider.isSelectable || Boolean(rider.isReadOnly) || isRequire}
        onChange={() => toggleRider(rider.code, !riderChecked)}
      >
        <span className="custom-control-description">
          {rider.name} ({MESSAGES.MEDICAL_FEE})
        </span>
      </Checkbox>
    </div>
    {rider.isSelectable ? (
      <div className="options mhpwc">
        <Dropdown
          options={planOptions}
          onChange={(value) => {
            editHealthPackage(value, rider.selectedPlan.areaOfCover, rider.selectedPlan.planOptional)
          }}
          value={rider.selectedPlan.plan}
          disabled={!rider.isSelected || rider.isReadOnly}
        />
        {allowOptionalIHealthyUltra && (
          <div className="mhpwc-second-line">
            <Dropdown
              options={planOptionals}
              onChange={(value) => {
                editHealthPackage(rider.selectedPlan.plan, rider.selectedPlan.areaOfCover, value)
              }}
              value={rider.selectedPlan.planOptional}
              disabled={!rider.isSelected || rider.isReadOnly}
            />
          </div>
        )}
        <div className="mhpwc-second-line">
          <div className="mhpwc-second-line-first-item">
            <Dropdown
              options={areasOfCover}
              onChange={(value) => {
                editHealthPackage(rider.selectedPlan.plan, value, rider.selectedPlan.planOptional)
              }}
              value={rider.selectedPlan.areaOfCover}
              disabled={!rider.isSelected || rider.isReadOnly}
            />
          </div>
          <div className="link">→</div>

          <RiderPremium
            isSelected={rider.isSelected && rider.isSelectable}
            isSelectable={rider.isSelectable}
            premium={rider.premium}
          />
        </div>
      </div>
    ) : (
      <div className="options mhpwc">
        <div id={`rider-${rider.code}-option-0`} className="value-pair mhpwc-full-line">
          -
        </div>

        <div className="mhpwc-second-line">
          <div className="mhpwc-second-line-first-item">
            <div id={`rider-${rider.code}-option-1`} className="value-pair">
              -
            </div>
          </div>
          <div className="link">→</div>

          <RiderPremium
            isSelected={rider.isSelected && rider.isSelectable}
            isSelectable={rider.isSelectable}
            premium={rider.premium}
          />
        </div>
      </div>
    )}

    <div className="message">
      <RiderMessage riderCode={rider.code} type="eligibility" messages={eligibilityErrors} />
      <RiderMessage riderCode={rider.code} type="validation" messages={validationErrors} />
      <RiderMessage riderCode={rider.code} type="additional-information" messages={additionalInformation} />
    </div>
  </FormGroup>
)

const mapStateToProps = (state) => ({
  insuredAge: getAge(state),
  allowOptionalIHealthyUltra: isAllowOptionalIHealtyUltra(state),
})

export default connect(
  mapStateToProps,
  { editRiderSumAssured, editRiderSelectedPlan },
  (stateProps, dispatchProps, ownProps) => {
    const { insuredAge, allowOptionalIHealthyUltra } = stateProps
    const { rider } = ownProps
    const { editRiderSumAssured, editRiderSelectedPlan } = dispatchProps
    const packageRidersAvaliable = getMHPPackageRiderAvaliable(rider, insuredAge)
    const planOptions = packageRidersAvaliable.map(({ plan }) => ({
      text: plan,
      value: plan,
    }))

    const selectedPackage = _.find(rider.packageRiders, {
      plan: rider.selectedPlan.plan,
    })

    const areasOfCover = _.pick(rider.areasOfCover, selectedPackage.areasOfCover)
    const planOptionals = rider.planOptionals
    return {
      ...ownProps,
      editHealthPackage: (plan, areaOfCover, planOptional) => {
        const iHealthyUltraPackage = getiHealthyUltraPackage(
          rider.packageRiders,
          selectedPackage.plan,
          plan,
          areaOfCover,
          allowOptionalIHealthyUltra,
          planOptional
        )
        editRiderSumAssured(rider.code, iHealthyUltraPackage.sumAssured)
        editRiderSelectedPlan(rider.code, iHealthyUltraPackage)
      },
      planOptions,
      areasOfCover,
      planOptionals,
      allowOptionalIHealthyUltra,
    }
  }
)(MHPRider)
