//@flow
import type { DisplayProduct } from 'core/service/display-product'
import { formattedHeaderWithStyles, productInfoHeaderStyles } from '../../common/components/product-information-header'

export const packageThaiNameConstants = {
  PLATINUM: 'แพลทินั่ม',
  DIAMOND: 'ไดมอนด์',
  GOLD: 'โกลด์',
  SILVER: 'ซิลเวอร์',
  SMART: 'สมาร์ท',
}

const modifiedStyles = {
  ...productInfoHeaderStyles,
  productName: {
    ...productInfoHeaderStyles.productName,
    lineHeight: 0.8,
  },
}

export const getPdfHeaderParameters = (
  displayProduct: DisplayProduct,
  selectedPlanName: string,
  selectedPlanCode: string
) => {
  const header1 = `${displayProduct.name} ${selectedPlanName}`
  const header2 = `${displayProduct.nameThai} ${
    packageThaiNameConstants[selectedPlanName.toUpperCase()]
  } (${selectedPlanCode})`
  return { header1, header2 }
}

export const render = (
  displayProduct: DisplayProduct,
  selectedPlanName: string,
  selectedPlanCode: string,
  redline: string
) => {
  const { header1, header2 } = getPdfHeaderParameters(displayProduct, selectedPlanName, selectedPlanCode)
  return formattedHeaderWithStyles(header1, header2, redline, modifiedStyles)
}
