//@flow
import values from 'core/data-model/constants/values'
import {
  getAcceptableRiskRange,
  getOverRiskRange,
  getRiskEvaluated,
  getRPQScore,
} from '../../risk-questionnaire/selectors'
import type { RiskEvaluated, RiskRange } from 'core/service/investment/risklevel'
import { getAllRiskColors, getRiskColor } from 'core/service/investment/risklevel'
import { RiskLevelDisplayBlock } from './display-block'
import { RiskScore, RiskScoreDesc } from './risk-score'
import { AcceptanceLevels, AcceptanceLevelsDesc } from './risk-acceptance'
import { connect } from 'react-redux'
import { RiskLevelsTable } from './risk-levels-table'
import { RetakeRPQ } from './retake-rpq'
import { InvestmentAdvice } from './investment-advice'
import styled from 'styled-components'
import { resetAnsweredQuestion, resetRiskEvaluated } from '../../risk-questionnaire/actions'
import Confirmation from '../../../components/confirmation'
import { getToggles } from 'quick-quote/feature-toggles'

type RiskProfileProps = {
  riskEvaluated: RiskEvaluated,
  acceptableRiskRange: RiskRange,
  overRiskRange: ?RiskRange,
  score: number,
  onRetakeRPQConfirmed: Function,
  resetAnsweredQuestion: Function,
  resetRiskEvaluated: Function,
  className: Object,
}

export class _RiskProfile extends React.Component<*, *> {
  constructor(props: RiskProfileProps) {
    super(props)

    this.state = {
      isConfirmationBoxOpen: false,
    }
  }

  onRetakeRPQ = () => {
    this.props.resetAnsweredQuestion()
    this.props.resetRiskEvaluated()
    this.props.onRetakeRPQConfirmed()
  }

  onRetakeTestConfirmed = () => {
    this.onRetakeRPQ()
  }

  toggleConfirmationBox = () => {
    this.setState({
      isConfirmationBoxOpen: !this.state.isConfirmationBoxOpen,
    })
  }

  render() {
    const props = this.props
    return (
      <div className={props.className}>
        <RiskLevelDisplayBlock
          color={values.PRIMARY_COLOR}
          content={
            <RiskScoreDesc riskLevelLabel={props.riskEvaluated.label} riskLevelDesc={props.riskEvaluated.description} />
          }
          contentInCircle={<RiskScore score={props.score} />}
        />
        <RiskLevelDisplayBlock
          color={getRiskColor(props.riskEvaluated.value)}
          content={
            <AcceptanceLevelsDesc acceptableRiskRange={props.acceptableRiskRange} overRiskRange={props.overRiskRange} />
          }
          contentInCircle={<AcceptanceLevels acceptableRiskRange={props.acceptableRiskRange} />}
        />
        <RiskLevelsTable colors={getAllRiskColors()} />
        <RetakeRPQ onRetakeRPQConfirmed={this.toggleConfirmationBox} />
        {getToggles().ENABLE_RPQ_FORM_2022 ? <InvestmentAdvice riskEvaluatedValue={props.riskEvaluated.value} /> : null}
        <Confirmation
          toggle={this.toggleConfirmationBox}
          isOpen={this.state.isConfirmationBoxOpen}
          confirmYes={this.onRetakeTestConfirmed}
          confirmNo={this.toggleConfirmationBox}
          message={{
            title: 'ยืนยันทำแบบประเมินความเสี่ยงใหม่',
            body: 'ท่านต้องการทำแบบประเมินความเสี่ยงใหม่หรือไม่ (ข้อมูลที่ท่านระบุจะถูกลบทิ้ง)',
          }}
        />
      </div>
    )
  }
}

const StyledRiskProfile = styled(_RiskProfile)`
  max-width: 680px;
  margin: 0 auto;
  padding: 20px;
`

const mapStateToProps = (state) => ({
  score: getRPQScore(state),
  riskEvaluated: getRiskEvaluated(state),
  acceptableRiskRange: getAcceptableRiskRange(state),
  overRiskRange: getOverRiskRange(state),
})

const RiskProfile = connect(mapStateToProps, {
  resetAnsweredQuestion,
  resetRiskEvaluated,
})(StyledRiskProfile)

export default RiskProfile
