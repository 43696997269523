import { omit, isEqual } from 'lodash/fp'
import { connect } from 'react-redux'

import { compose, mapProps, withProps } from 'recompose'

import { getCurrentApp } from 'e-submission/apps/selectors'
import validator from 'e-submission/domain/data-model/form/validation'

const options = {
  areStatePropsEqual: isEqual,
}

export default compose(
  withProps(({ id }) => validator(id)),
  withProps(({ setValue, removeValue, allow }) => ({
    setValue: (nextValue) => {
      if (allow(nextValue)) {
        return setValue(nextValue)
      }
    },
  })),
  connect(
    (state, { getValidationMessages, value, required }) =>
      getValidationMessages({
        value,
        required,
        app: getCurrentApp(state),
        state,
      }),
    null,
    null,
    options
  ),
  mapProps(omit(['getValidationMessages']))
)
