export const SET_REMOTE_SELLING_APP_ID = 'SET_REMOTE_SELLING_APP_ID'
export const OPEN_DF2F_EKYC_PAGE = 'OPEN_DF2F_EKYC_PAGE'
export const CREATE_NEW_APP = 'CREATE_NEW_APP'
export const CREATE_POLICY_SUCCEED = 'CREATE_POLICY_SUCCEED'
export const SET_REMOTE_SELLING_DATA = 'SET_REMOTE_SELLING_DATA'
export const RESET_REMOTE_SELLING_STATE = 'RESET_REMOTE_SELLING_STATE'
export const UPDATE_CALL_STATUS = 'UPDATE_CALL_STATUS'
export const RESUME_APPMAN_REMOTE_SELLING = 'RESUME_APPMAN_REMOTE_SELLING'
export const OPEN_DF2F_APPLICATION = 'OPEN_DF2F_APPLICATION'
export const GET_MEDIA_STATUS = 'GET_MEDIA_STATUS'
export const START_VIDEO_RECORDING = 'START_VIDEO_RECORDING'
export const VIDEO_CONSENTED = 'VIDEO_CONSENTED'
export const OPEN_EKYC_CONFIRM_MODAL = 'OPEN_EKYC_CONFIRM_MODAL'
export const CONFIRMED_EKYC = 'CONFIRMED_EKYC'
export const UPLOAD_DOCUMENT_FROM_URL = 'UPLOAD_DOCUMENT_FROM_URL'
export const CANCEL_EVENT = 'CANCEL_EVENT'

export const setRemoteSellingAppId = (payload) => ({
  type: SET_REMOTE_SELLING_APP_ID,
  payload,
})
export const videoConsented = (payload) => ({
  type: VIDEO_CONSENTED,
  payload,
})
export const setRemoteSellingState = (payload) => ({
  type: SET_REMOTE_SELLING_DATA,
  payload,
})

export const openEkycPage = (navProps) => ({
  type: OPEN_DF2F_EKYC_PAGE,
  payload: { navProps },
})

export const startVideoConsent = () => ({
  type: START_VIDEO_RECORDING,
})

export const resetRemoteSellingState = () => ({
  type: RESET_REMOTE_SELLING_STATE,
})

export const updateCallStatus = (payload) => ({
  type: UPDATE_CALL_STATUS,
  payload,
})

export const updateCallStateEsub = (payload) => ({
  type: 'UPDATE_CALL_STATUS_REMOTE',
  payload,
})

export const resumeAppmanRemoteSelling = (payload) => ({
  type: RESUME_APPMAN_REMOTE_SELLING,
  payload,
})

export const openDf2fApplication = (payload = {}) => ({
  type: OPEN_DF2F_APPLICATION,
  payload,
})

export const confirmedEKYC = () => ({
  type: CONFIRMED_EKYC,
})

export const openEkycConfirmModal = () => ({
  type: OPEN_EKYC_CONFIRM_MODAL,
})

export const cancelEvent = (payload) => ({
  type: CANCEL_EVENT,
  payload,
})
