//@flow
import ProceedToFnaButton from 'quick-quote/components/proceed-to-fna-btn'
import { isOnFnaPhase } from 'quick-quote/fna/selectors'
import {
  canProceedToFundAllocation,
  canProceedToRiskQuestionnaire,
  canProceedToRppFundSummary,
} from 'quick-quote/product-investment/journey-control-selectors'
import type { Element } from 'react'
import type { AnalyticsEventType } from 'analytics/components'
import MESSAGES from '../../../../core/data-model/constants/messages'
import { qqPath, ROUTE_PATH } from '../../../constants/path'
import { ShareButtonSection as FlexiHealthShareButtonSection } from '../../../product-flexi-health/benefit-illustration/components'
import { ShareButtonSection as HealthShareButtonSection } from '../../../product-health/benefit-illustration/components'
import ShareQuoteButton from '../../../product-investment/benefit-illustration/share-quote-button'
import { canProceedPerfectLifeBenefitIllustration } from '../../../product-perfect-life/coverage-plan/selectors'
import { canProceedRetirementBenefitIllustration } from '../../../product-retirement/coverage-plan/selectors'
import { canProceedBumnanReadyBenefitIllustration } from '../../../product-bumnan-ready/coverage-plan/selectors'
import { ShareButtonSection } from '../../../product-whole-life/benefit-illustration/components'
import ESubmitButton from '../../../product-whole-life/benefit-illustration/components/e-submit/esub-button'
import { canProceedToBenefitIllustration } from '../../../product-whole-life/coverage-plan/selectors'
import type { AppState } from '../../../reducers'
import { canProceedToCoveragePlan } from '../../../selectors/next-tab-proceeding'

export type NavButton = {
  type: 'link',
  to?: string,
  text?: string,
  disabled?: boolean,
  analyticsEventType?: AnalyticsEventType,
  onClick?: Function,
}

export type NavButtonConfiguration = {
  path: string,
  props: {
    backButton?: NavButton,
    nextButton?:
      | NavButton
      | {
          type: 'component',
          component: Element<*>,
        },
    extraButton?: React$ComponentType<*>,
  },
  excludePath?: string,
}

const bISubmitBtn = (state) => (isOnFnaPhase(state) ? <ESubmitButton /> : <ProceedToFnaButton />)
const productSelectionBackBtn = (state, NEED_AND_GAP_ANALYSIS = '') =>
  isOnFnaPhase(state)
    ? {
        type: 'link',
        to: qqPath(NEED_AND_GAP_ANALYSIS),
      }
    : {
        type: 'link',
        toHome: true,
      }

const wholeLifeNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    PRODUCT_SELECTION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
  } = ROUTE_PATH.WHOLE_LIFE

  return [
    {
      path: qqPath(NEED_AND_GAP_ANALYSIS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(BENEFIT_ILLUSTRATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(PRODUCT_SELECTION),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(INSURED_INFORMATION),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(PRODUCT_SELECTION),
      props: {
        backButton: productSelectionBackBtn(state, NEED_AND_GAP_ANALYSIS),
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(INSURED_INFORMATION),
        },
      },
    },
    {
      path: qqPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: qqPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: qqPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: qqPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: qqPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          component: bISubmitBtn(state),
        },
        extraButton: ShareButtonSection,
      },
    },
  ]
}

const protectionNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    PRODUCT_SELECTION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
  } = ROUTE_PATH.PROTECTION

  return [
    {
      path: qqPath(NEED_AND_GAP_ANALYSIS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(BENEFIT_ILLUSTRATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(PRODUCT_SELECTION),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(INSURED_INFORMATION),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(PRODUCT_SELECTION),
      props: {
        backButton: productSelectionBackBtn(state, NEED_AND_GAP_ANALYSIS),
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(INSURED_INFORMATION),
        },
      },
    },
    {
      path: qqPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: qqPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: qqPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: qqPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: qqPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          component: bISubmitBtn(state),
        },
        extraButton: ShareButtonSection,
      },
    },
  ]
}

const investmentNavigationButtonV2 = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    RISK_QUESTIONNAIRE,
    FUND_ALLOCATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    PRODUCT_SELECTION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
    FUND_SUMMARTY,
  } = ROUTE_PATH.INVESTMENT

  const backButton = {
    backButton: {
      type: 'link',
      to: qqPath(COVERAGE_PLAN),
    },
  }

  const backNextButton = {
    ...backButton,
    nextButton: {
      type: 'link',
      text: MESSAGES.FUND,
      to: qqPath(FUND_ALLOCATION),
      disabled: false,
    },
  }

  const navButton = canProceedToFundAllocation(state) ? backNextButton : backButton
  return [
    {
      path: qqPath(NEED_AND_GAP_ANALYSIS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(BENEFIT_ILLUSTRATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(PRODUCT_SELECTION),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(INSURED_INFORMATION),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(PRODUCT_SELECTION),
      props: {
        backButton: productSelectionBackBtn(state, NEED_AND_GAP_ANALYSIS),
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(INSURED_INFORMATION),
        },
      },
    },
    {
      path: qqPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: qqPath(COVERAGE_PLAN),
          disabled: !canProceedToRiskQuestionnaire(state),
        },
      },
    },
    {
      path: qqPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.RISK_QUESTIONNAIRE,
          to: qqPath(RISK_QUESTIONNAIRE),
          disabled: !canProceedToRiskQuestionnaire(state),
        },
      },
    },
    {
      path: qqPath(RISK_QUESTIONNAIRE),
      props: navButton,
    },
    {
      path: qqPath(FUND_ALLOCATION),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(RISK_QUESTIONNAIRE),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(FUND_SUMMARTY),
          disabled: !canProceedToRppFundSummary(state),
        },
      },
    },
    {
      path: qqPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(FUND_ALLOCATION),
        },
        nextButton: {
          type: 'component',
          component: bISubmitBtn(state),
        },
        extraButton: ShareQuoteButton,
      },
    },
  ]
}

const loanNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    PRODUCT_SELECTION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
  } = ROUTE_PATH.LOAN

  return [
    {
      path: qqPath(NEED_AND_GAP_ANALYSIS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(BENEFIT_ILLUSTRATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(PRODUCT_SELECTION),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(INSURED_INFORMATION),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(PRODUCT_SELECTION),
      props: {
        backButton: productSelectionBackBtn(state, NEED_AND_GAP_ANALYSIS),
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(INSURED_INFORMATION),
        },
      },
    },
    {
      path: qqPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: qqPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: qqPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: qqPath(BENEFIT_ILLUSTRATION),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(COVERAGE_PLAN),
        },
      },
    },
  ]
}

const healthNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    PRODUCT_SELECTION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
  } = ROUTE_PATH.HEALTH

  return [
    {
      path: qqPath(NEED_AND_GAP_ANALYSIS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(BENEFIT_ILLUSTRATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(PRODUCT_SELECTION),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(INSURED_INFORMATION),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(PRODUCT_SELECTION),
      props: {
        backButton: productSelectionBackBtn(state, NEED_AND_GAP_ANALYSIS),
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(INSURED_INFORMATION),
        },
      },
    },
    {
      path: qqPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: qqPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: qqPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: qqPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: qqPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          component: bISubmitBtn(state),
        },
        extraButton: HealthShareButtonSection,
      },
    },
  ]
}

const ihealthyUltraNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    PRODUCT_SELECTION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
  } = ROUTE_PATH.IHEALTHY_ULTRA

  return [
    {
      path: qqPath(NEED_AND_GAP_ANALYSIS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(BENEFIT_ILLUSTRATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(PRODUCT_SELECTION),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(INSURED_INFORMATION),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(PRODUCT_SELECTION),
      props: {
        backButton: productSelectionBackBtn(state, NEED_AND_GAP_ANALYSIS),
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(INSURED_INFORMATION),
        },
      },
    },
    {
      path: qqPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: qqPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: qqPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: qqPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: qqPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          component: bISubmitBtn(state),
        },
        extraButton: HealthShareButtonSection,
      },
    },
  ]
}

const flexiHealthNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    PRODUCT_SELECTION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
  } = ROUTE_PATH.FLEXI_HEALTH

  return [
    {
      path: qqPath(NEED_AND_GAP_ANALYSIS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(BENEFIT_ILLUSTRATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(PRODUCT_SELECTION),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(INSURED_INFORMATION),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(PRODUCT_SELECTION),
      props: {
        backButton: productSelectionBackBtn(state, NEED_AND_GAP_ANALYSIS),
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(INSURED_INFORMATION),
        },
      },
    },
    {
      path: qqPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: qqPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: qqPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: qqPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: qqPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          component: bISubmitBtn(state),
        },
        extraButton: FlexiHealthShareButtonSection,
      },
    },
  ]
}

const savingNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    PRODUCT_SELECTION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
  } = ROUTE_PATH.SAVING

  return [
    {
      path: qqPath(NEED_AND_GAP_ANALYSIS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(BENEFIT_ILLUSTRATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(PRODUCT_SELECTION),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(INSURED_INFORMATION),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(PRODUCT_SELECTION),
      props: {
        backButton: productSelectionBackBtn(state, NEED_AND_GAP_ANALYSIS),
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(INSURED_INFORMATION),
        },
      },
    },
    {
      path: qqPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: qqPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: qqPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: qqPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: qqPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          component: bISubmitBtn(state),
        },
        extraButton: HealthShareButtonSection,
      },
    },
  ]
}

const eSubmissionNavigationButton = (): NavButtonConfiguration[] => {
  return [
    {
      path: qqPath(ROUTE_PATH.REMOTE_SELLING.VIDEO_CONSENT),
      props: {
        backButton: {},
      },
    },
    {
      path: qqPath(ROUTE_PATH.ESUBMISSION_FORM),
      props: {
        backButton: {
          type: 'link',
        },
      },
      excludePath: 'submit',
    },
    {
      path: qqPath(ROUTE_PATH.ESUBMISSION_PAYMENT),
      props: {
        backButton: {
          type: 'link',
        },
      },
    },
  ]
}

const lifeSaveProNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    PRODUCT_SELECTION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
  } = ROUTE_PATH.LIFE_SAVE_PRO

  return [
    {
      path: qqPath(NEED_AND_GAP_ANALYSIS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(BENEFIT_ILLUSTRATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(PRODUCT_SELECTION),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(INSURED_INFORMATION),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(PRODUCT_SELECTION),
      props: {
        backButton: productSelectionBackBtn(state, NEED_AND_GAP_ANALYSIS),
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(INSURED_INFORMATION),
        },
      },
    },
    {
      path: qqPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: qqPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: qqPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: qqPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: qqPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          component: bISubmitBtn(state),
        },
        extraButton: ShareButtonSection,
      },
    },
  ]
}

const retirementNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    PRODUCT_SELECTION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
  } = ROUTE_PATH.RETIREMENT

  return [
    {
      path: qqPath(NEED_AND_GAP_ANALYSIS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(BENEFIT_ILLUSTRATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(PRODUCT_SELECTION),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(INSURED_INFORMATION),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(PRODUCT_SELECTION),
      props: {
        backButton: productSelectionBackBtn(state, NEED_AND_GAP_ANALYSIS),
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(INSURED_INFORMATION),
        },
      },
    },
    {
      path: qqPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: qqPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: qqPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: qqPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedRetirementBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: qqPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          component: bISubmitBtn(state),
        },
        extraButton: ShareButtonSection,
      },
    },
  ]
}

const bumnanReadyNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    PRODUCT_SELECTION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
  } = ROUTE_PATH.BUMNAN_READY

  return [
    {
      path: qqPath(NEED_AND_GAP_ANALYSIS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(BENEFIT_ILLUSTRATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(PRODUCT_SELECTION),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(INSURED_INFORMATION),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(PRODUCT_SELECTION),
      props: {
        backButton: productSelectionBackBtn(state, NEED_AND_GAP_ANALYSIS),
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(INSURED_INFORMATION),
        },
      },
    },
    {
      path: qqPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: qqPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: qqPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: qqPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedBumnanReadyBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: qqPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          component: bISubmitBtn(state),
        },
        extraButton: ShareButtonSection,
      },
    },
  ]
}

const perfectLifeNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    PRODUCT_SELECTION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
  } = ROUTE_PATH.PERFECT_LIFE

  return [
    {
      path: qqPath(NEED_AND_GAP_ANALYSIS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(BENEFIT_ILLUSTRATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(PRODUCT_SELECTION),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(INSURED_INFORMATION),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(PRODUCT_SELECTION),
      props: {
        backButton: productSelectionBackBtn(state, NEED_AND_GAP_ANALYSIS),
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(INSURED_INFORMATION),
        },
      },
    },
    {
      path: qqPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: qqPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: qqPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: qqPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedPerfectLifeBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: qqPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          component: bISubmitBtn(state),
        },
        extraButton: ShareButtonSection,
      },
    },
  ]
}

const healthToppingNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    PRODUCT_SELECTION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
  } = ROUTE_PATH.HEALTH_TOPPING

  return [
    {
      path: qqPath(NEED_AND_GAP_ANALYSIS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(BENEFIT_ILLUSTRATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(PRODUCT_SELECTION),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(INSURED_INFORMATION),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(PRODUCT_SELECTION),
      props: {
        backButton: productSelectionBackBtn(state, NEED_AND_GAP_ANALYSIS),
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(INSURED_INFORMATION),
        },
      },
    },
    {
      path: qqPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: qqPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: qqPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: qqPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: qqPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          component: bISubmitBtn(state),
        },
        extraButton: ShareButtonSection,
      },
    },
  ]
}

const s7ccNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    MATURITY_VALIDATE,
    SELECT_POLICY_OWNER,
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    PRODUCT_SELECTION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
  } = ROUTE_PATH.S7CC

  return [
    {
      path: qqPath(NEED_AND_GAP_ANALYSIS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(BENEFIT_ILLUSTRATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(PRODUCT_SELECTION),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(INSURED_INFORMATION),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(PRODUCT_SELECTION),
      props: {
        backButton: productSelectionBackBtn(state, NEED_AND_GAP_ANALYSIS),
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(INSURED_INFORMATION),
        },
      },
    },
    {
      path: qqPath(MATURITY_VALIDATE),
      props: {
        backButton: {
          type: 'link',
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SELECT_POLICY_OWNER,
          to: qqPath(SELECT_POLICY_OWNER),
          // disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: qqPath(SELECT_POLICY_OWNER),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(MATURITY_VALIDATE),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(INSURED_INFORMATION),
          // disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: qqPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          // to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(PRODUCT_SELECTION),
          to: qqPath(SELECT_POLICY_OWNER),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: qqPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: qqPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: qqPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: qqPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          component: bISubmitBtn(state),
        },
        extraButton: HealthShareButtonSection,
      },
    },
  ]
}

const qqNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const { PRODUCT_SELECTION } = ROUTE_PATH

  return [
    {
      path: qqPath(PRODUCT_SELECTION),
      props: {
        backButton: productSelectionBackBtn(state),
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: '',
          disabled: true,
        },
      },
    },
  ]
}

export default (state: AppState): NavButtonConfiguration[] =>
  wholeLifeNavigationButton(state)
    .concat(investmentNavigationButtonV2(state))
    .concat(loanNavigationButton(state))
    .concat(healthToppingNavigationButton(state))
    .concat(healthNavigationButton(state))
    .concat(ihealthyUltraNavigationButton(state))
    .concat(savingNavigationButton(state))
    .concat(protectionNavigationButton(state))
    .concat(lifeSaveProNavigationButton(state))
    .concat(flexiHealthNavigationButton(state))
    .concat(retirementNavigationButton(state))
    .concat(bumnanReadyNavigationButton(state))
    .concat(perfectLifeNavigationButton(state))
    .concat(s7ccNavigationButton(state))
    .concat(qqNavigationButton(state))
    .concat(eSubmissionNavigationButton())
