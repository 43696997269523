// @flow
import React from 'react'
import PropTypes from 'prop-types'
import RiderWithBenefitModal from './rider-benefit-modal'
import RiderBenefitSummary from './rider-benefit-summary'
import RiderList from './rider-list'
import { WordWraps } from 'common-components'

const renderTextArray = (item) => {
  if (item.text && item.text.length > 1) {
    return item.text.map((text) => {
      return <div className="title">- {`${text.replace(/\r?\n|\r/g, '')}`}</div>
    })
  } else {
    const isTextBold = item.bold || false
    if (isTextBold) {
      return <span className="title-bold">{item.text}</span>
    }
    return <span className="title">- {item.text}</span>
  }
}

const RenderBenefits = (props) => {
  const { content } = props
  return (
    <div className="ciudr-rider-content ciudr-rider-content--benefit">
      <p className="heading">{content.title}</p>
      <p className="description">{content.description.join(' ')}</p>
      <RiderList
        start={1}
        name="diseases"
        items={content.diseases}
        render={(item, index) => (
          <div>
            <span className="title">{item.title}</span>
            <span className="rate">{item.rate}</span>
            {item.moreDetails
              ? item.moreDetails.map((moreItem, index) => (
                  <div className="more-details" key={`more-${index}`}>
                    {renderTextArray(moreItem)}
                    {moreItem.list ? (
                      <RiderList
                        name="benefit-more"
                        items={moreItem.list}
                        render={(moreItem, moreIndex) => moreItem.text}
                      />
                    ) : null}
                  </div>
                ))
              : null}
          </div>
        )}
      />
    </div>
  )
}

const RenderNotice = (props) => {
  const { content } = props
  return (
    <div className="ciudr-rider-content ciudr-rider-content--notice">
      <p className="heading">{content.title}</p>
      <RiderList
        unordered
        name="notice"
        items={content.generic}
        render={(item, index) => (
          <div>
            <span className="description">{item.text.join(' ')}</span>
            {item.list ? (
              <RiderList name="notice-more" items={item.list} render={(moreItem, moreIndex) => moreItem.text} />
            ) : null}
            {item.listFooterText ? <span className="description">{item.listFooterText.join(' ')}</span> : null}
            {item.moreDetails
              ? item.moreDetails.map((moreItem, index) => (
                  <div className="more-details" key={`more-${index}`}>
                    <div className="more-details">
                      <span>{moreItem.text.join(' ')}</span>
                    </div>
                  </div>
                ))
              : null}
          </div>
        )}
      />
    </div>
  )
}

const RenderDisclaimer = (props) => {
  const { content } = props
  return (
    <div className="ciudr-rider-content ciudr-rider-content--disclaimer">
      <p className="heading">{content.title}</p>
      <p className="description">{content.description.join(' ')}</p>
      <RiderList
        start={1}
        name="diseases"
        items={content.list}
        render={(item, index) => <WordWraps>{item.text}</WordWraps>}
      />
    </div>
  )
}

const RenderCriticalIllness = (props) => {
  const { content } = props
  return (
    <div className="ciudr-rider-content ciudr-rider-content--critical-illness">
      <p className="heading">{content.title}</p>
      <p className="description">{content.description.join(' ')}</p>
      <div className="sub-section">
        <p className="heading">{content.earlyStageCriticalIllness}</p>
        <RiderList
          start={1}
          name="diseases"
          items={content.diseases.slice(0, 20)}
          column={true}
          render={(item, index) => <WordWraps>{item}</WordWraps>}
        />
      </div>
      <div className="sub-section">
        <p className="heading">{content.majorStageCriticalIllness}</p>
        <RiderList
          start={1}
          name="diseases"
          items={content.diseases.slice(20, 70)}
          column={true}
          render={(item, index) => <WordWraps>{item}</WordWraps>}
        />
      </div>
    </div>
  )
}

function CIRiderBenefit(props: Object) {
  const { rider, riderBenefitData } = props
  const title = `${rider.description} ${rider.name}`
  const riderName = rider.name.toLowerCase()

  const { benefits, notice, disclaimer, criticalIllness } = riderBenefitData

  const benefitInfo = (
    <div className="ciudr-details-table">
      <RenderBenefits content={benefits} />
    </div>
  )

  return (
    <RiderWithBenefitModal
      id={`${riderName}-rider-benefit`}
      className={`rider ${riderName}-rider`}
      title={title}
      modalChildren={benefitInfo}
    >
      <RiderBenefitSummary rider={rider} />
      {benefitInfo}
      <RenderDisclaimer content={disclaimer} />
      <RenderCriticalIllness content={criticalIllness} />
      <RenderNotice content={notice} />
    </RiderWithBenefitModal>
  )
}

const propTypes = {
  rider: PropTypes.shape({
    name: PropTypes.string,
  }),
  riderBenefitData: PropTypes.shape({}),
}
const defaultProps = {
  rider: {
    name: '',
  },
  riderBenefitData: {},
}

CIRiderBenefit.propTypes = propTypes
CIRiderBenefit.defaultProps = defaultProps

export default CIRiderBenefit
