// @flow
import { compact, get, getOr } from 'lodash/fp'
import axios from 'axios'
import { getAppConfig } from 'deploy-env/app-config'
import { getToggles } from 'quick-quote/feature-toggles'

export const instance = axios.create()

type Application = {
  _id: string,
  _rev: string,
}

type User = {
  access_token: string,
  profile: { sub: string },
}

const dbName = 'application'
const encryptionKey = '1234'
const imageWidth = 1024
const imageHeight = 768

const getUserId = get('profile.sub')
const getToken = (user) => {
  if (user && user.access_token) {
    return user.access_token
  }
  throw 'Please specify user access token'
}
const getOwner = (user) => {
  return 'keycloak_' + user.profile.sub
}

const invoke = (service, params) => {
  return new Promise((resolve, reject) => {
    const args = [
      params,
      (result) => {
        resolve(result)
      },
      (err) => {
        reject(err)
      },
    ]
    service(...compact(args))
  })
}

const authorizedHeaders = (user: User) => {
  if (user && user.access_token) {
    return {
      Authorization: `Bearer ${user.access_token}`,
    }
  }
  throw 'Please specify user access token'
}

const getEndpoint = () => {
  if (getToggles().ENABLE_AUTHORISATION_CODE_FLOW) return getAppConfig().APPLICATION_BUCKET_URL_AUTHZ_CODE_FLOW
  if (getToggles().ENABLE_SGW_PROXY) return getAppConfig().ADMIN_APPLICATION_BUCKET_URL
  return getAppConfig().APPLICATION_BUCKET_URL
}

// const getOwner = (user) => 'keycloak_' + user.profile.sub

export default {
  //start
  subscribeReplicationStatus(resolve, reject) {
    CB.listeningReplicationStatus(resolve, reject)
  },

  callOmise(cardInformation) {
    const params = {
      ...cardInformation,
      publicKey: getAppConfig().OMISE_PUBLIC_KEY,
    }

    return invoke(CB.createOmiseToken, params).then((data) => {
      return data
    })
  },

  openCamera(application, imageName) {
    const params = {
      _id: application._id,
      width: imageWidth,
      height: imageHeight,
      imageName: imageName,
    }
    return invoke(CB.openCamera, params)
  },

  // end
  getAll(user: User) {
    console.debug('getAll')
    const url = getToggles().ENABLE_AUTHORISATION_CODE_FLOW
      ? `${getAppConfig().APPLICATION_BUCKET_URL_AUTHZ_CODE_FLOW}/_all_docs`
      : `${getAppConfig().APPLICATION_BUCKET_URL}/_all_docs`
    const requestConfig = getToggles().ENABLE_AUTHORISATION_CODE_FLOW
      ? {
          params: { include_docs: true },
          withCredentials: true,
          crossDomain: true,
        }
      : {
          params: { include_docs: true },
          headers: authorizedHeaders(user),
        }
    return instance.get(url, requestConfig)
  },

  getSummaries(user: User) {
    console.debug('getSummaries')
    const url = `${getEndpoint()}/_design/applications/_view/summary`
    const requestConfig = getToggles().ENABLE_AUTHORISATION_CODE_FLOW
      ? {
          params: {
            stale: false,
            startkey: `"application_${user.profile.sub}"`,
            endkey: `"application_${user.profile.sub}\uefff"`,
          },
          withCredentials: true,
          crossDomain: true,
        }
      : {
          params: {
            stale: false,
            startkey: `"application_${user.profile.sub}"`,
            endkey: `"application_${user.profile.sub}\uefff"`,
          },
          headers: authorizedHeaders(user),
        }
    return instance.get(url, requestConfig)
  },

  getRev(application: Application, user: User) {
    console.debug('getRev')
    const url = `${getEndpoint()}/_all_docs`
    const requestConfig = getToggles().ENABLE_AUTHORISATION_CODE_FLOW
      ? {
          params: {
            include_docs: false,
            limit: 1,
            startkey: application._id,
          },
          withCredentials: true,
          crossDomain: true,
        }
      : {
          params: {
            include_docs: false,
            limit: 1,
            startkey: application._id,
          },
          headers: authorizedHeaders(user),
        }
    return instance.get(url, requestConfig)
  },

  get(application: Application, user: User) {
    console.debug('get')
    const url = `${getEndpoint()}/${application._id}`
    const requestConfig = getToggles().ENABLE_AUTHORISATION_CODE_FLOW
      ? {
          withCredentials: true,
          crossDomain: true,
        }
      : { headers: authorizedHeaders(user) }
    return instance.get(url, requestConfig)
  },

  create(id: string, user: User) {
    console.debug('create')
    const config = { _id: id, owner: getOwner(user) }
    const requestConfig = getToggles().ENABLE_AUTHORISATION_CODE_FLOW
      ? {
          withCredentials: true,
          crossDomain: true,
        }
      : { headers: authorizedHeaders(user) }
    const url = getToggles().ENABLE_AUTHORISATION_CODE_FLOW
      ? `${getAppConfig().APPLICATION_BUCKET_URL_AUTHZ_CODE_FLOW}/`
      : `${getAppConfig().APPLICATION_BUCKET_URL}/`
    return instance.post(url, config, requestConfig)
  },

  update(application: Application, user: User) {
    console.debug('update')
    const requestConfig = getToggles().ENABLE_AUTHORISATION_CODE_FLOW
      ? {
          withCredentials: true,
          crossDomain: true,
        }
      : { headers: authorizedHeaders(user) }
    const requestBody = { ...application, owner: getOwner(user) }
    const url = `${getEndpoint()}/${application._id}`
    return instance.put(url, requestBody, requestConfig)
  },

  remove(application: Application, user: User) {
    console.debug('remove')
    const requestConfig = getToggles().ENABLE_AUTHORISATION_CODE_FLOW
      ? {
          params: { rev: application._rev },
          withCredentials: true,
          crossDomain: true,
        }
      : {
          params: { rev: application._rev },
          headers: authorizedHeaders(user),
        }
    const url = getToggles().ENABLE_AUTHORISATION_CODE_FLOW
      ? `${getAppConfig().APPLICATION_BUCKET_URL_AUTHZ_CODE_FLOW}/${application._id}`
      : `${getAppConfig().APPLICATION_BUCKET_URL}/${application._id}`
    return instance.delete(url, requestConfig)
  },

  getAttachment(application: Application, imageName: string, user: User) {
    console.debug('getAttachment')
    const url = getToggles().ENABLE_AUTHORISATION_CODE_FLOW
      ? `${getAppConfig().APPLICATION_BUCKET_URL_AUTHZ_CODE_FLOW}/${application._id}/${imageName}`
      : `${getAppConfig().APPLICATION_BUCKET_URL}/${application._id}/${imageName}`
    const requestConfig = getToggles().ENABLE_AUTHORISATION_CODE_FLOW
      ? {
          responseType: 'arraybuffer',
          withCredentials: true,
          crossDomain: true,
        }
      : {
          responseType: 'arraybuffer',
          headers: authorizedHeaders(user),
        }
    return instance.get(url, requestConfig)
  },

  setAttachment(application: Application, imageName: string, image: *, user: User, isInsured: boolean = false) {
    console.debug('setAttachment')
    const requestConfig = getToggles().ENABLE_AUTHORISATION_CODE_FLOW
      ? {
          headers: { 'Content-Type': getOr('image/jpg', 'type', image) },
          withCredentials: true,
          crossDomain: true,
        }
      : {
          headers: {
            ...authorizedHeaders(user),
            'Content-Type': getOr('image/jpg', 'type', image),
          },
        }
    const url = `${getEndpoint()}/${application._id}/${imageName}?rev=${application._rev}`
    return instance.put(url, image, requestConfig, isInsured)
  },
}
