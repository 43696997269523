//@flow
import styled from 'styled-components'

import type { DisplayProduct } from 'core/service/display-product'
import { isRequiredLicenses } from 'core/service/display-product'
import MESSAGES from 'core/data-model/constants/messages'
import _ from 'lodash'
import { getAppConfig } from 'deploy-env/app-config'
import { getToggles } from 'quick-quote/feature-toggles'
import { WordWraps } from 'common-components'

type BasicPlanSummaryProps = {
  displayProduct: DisplayProduct,
  isBrochure: boolean,
}

const ProductImage = styled(({ src, ...props }) => <div {...props} />)`
  background-image: url('${({ src }) => src}');
`

const OrdinaryForceLoginIcon = (displayProduct) => {
  return (
    getAppConfig().ORDINARY_PRODUCT_FORCE_LOGIN_AVALIABLE.includes(displayProduct.code) &&
    getToggles().ENABLE_FORCE_LOGIN_ORDINARY_PRODUCT &&
    displayProduct.category !== 'INVESTMENT'
  )
}

export const BasicPlanSummary = ({ displayProduct, isBrochure = false }: BasicPlanSummaryProps) => {
  const displayName = _.get(displayProduct, 'displayName', '')
  const requireLicenses = isRequiredLicenses(displayProduct)
  const isShowIconCheckLicenses = OrdinaryForceLoginIcon(displayProduct)
  return (
    <div className="basic-plan-summary">
      <ProductImage className="product-image" src={displayProduct.image}>
        {typeof displayProduct.ribbonMessage != 'undefined' && displayProduct.ribbonMessage.length > 0 ? (
          <div className="ribbon-wrapper-red">
            <div className="ribbon-red">{displayProduct.ribbonMessage}</div>
          </div>
        ) : null}
      </ProductImage>
      <div className="title">
        <h4>{displayName}</h4>
      </div>
      <div className="description">
        <div className="description__short">
          <span>{displayProduct.description}</span>
        </div>
        <BasicPlanBenefits id="basic-plan-benefits" benefits={displayProduct.benefits} />
        {(requireLicenses || isShowIconCheckLicenses) && !isBrochure ? (
          <div className="description__notification">
            <span>{MESSAGES.LICENSE_WIFI}</span>
          </div>
        ) : null}
      </div>
    </div>
  )
}

type BasicPlanBenefitsProps = {
  benefits: string[],
}

export const BasicPlanBenefits = ({ benefits }: BasicPlanBenefitsProps) => {
  return (
    <div className="description__highlight">
      <ul>
        {benefits.map((benefit) => (
          <li key={benefit}>
            <WordWraps>{benefit}</WordWraps>
          </li>
        ))}
      </ul>
    </div>
  )
}
