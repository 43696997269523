//@flow
import _ from 'lodash'
import { formatNumber } from 'core/service/lib/number-format'
import MESSAGES from 'core/data-model/constants/bi-messages'
import CONSTANTS from 'core/data-model/constants/messages'
import type { PolicyValue, YearEndPolicyValue } from 'core/service/benefit-illustration'
import type { PolicyValueTableProps } from 'core/service/benefit-illustration/policy-value'

// this is a hack for line wrapping in pdf which is not
// support Thai language yet. Once it is implemented
// this should be removed
export const _breakLine = (text: string) => {
  const premiumPattern = /^(เบี้ยประกันภัย)(สัญญา.+)$/
  const deathBenefitPattern = /^(ความคุ้มครอง)(การเสียชีวิต)$/
  const surrenderCashPattern = /^(มูลค่าเวนคืน)(เงินสด\**)$/
  const savingWithCompany = /^(กรณีรับเป็น)(เงินสด)$/

  const patterns = [premiumPattern, deathBenefitPattern, surrenderCashPattern, savingWithCompany]

  // add space to trick pdfmake to do line wrapping for us
  const breakByFirstMatched = ([p, ...rest]) => (text.match(p) ? text.replace(p, '$1 $2') : breakByFirstMatched(rest))

  return breakByFirstMatched(patterns)
}

const rowHeightConfig = { lineHeight: 0.75, margin: [0, -3, 0, 0] }

let disclaimerPLB = {
  stack: [`${MESSAGES.NOTE}:`, MESSAGES.PLB_POLICY_VALUE_TABLE_DISCLAIMER_ONE.replace('ประสบการณ์', '\nประสบการณ์')],
  style: 'disclaimer',
  pageBreak: '',
}

let disclaimerMHPPLB = {
  stack: [`**${MESSAGES.NOTE}:`, MESSAGES.POLICY_VALUE_TABLE_NOTE_1, MESSAGES.POLICY_VALUE_TABLE_NOTE_2],
  style: 'disclaimer',
  pageBreak: '',
}

const buildPolicyValueTable = (policyValue: PolicyValue, isMHPProduct = false) => {
  const policyValueTables = []
  const rowsPerPage = [36, 36, 36]
  const coverageYear = policyValue.yearEndPolicyValues.length
  const pageAmount = Math.ceil(coverageYear / (rowsPerPage[0] === coverageYear ? rowsPerPage[0] - 1 : rowsPerPage[0]))
  _.times(pageAmount, (currentPage) => {
    policyValueTables.push({
      table: {
        headerRows: 1,
        widths: ['auto', 60, '*', 65, '*', '*', '*'],
        body: [
          ...buildLPLBHeaderTable(isMHPProduct),
          ...policyValue.yearEndPolicyValues
            .slice(
              currentPage * rowsPerPage[currentPage > 0 ? currentPage - 1 : 0],
              rowsPerPage[currentPage] * (currentPage + 1)
            )
            .map((p) => buildPLBPolicyValueRow(p)),
        ],
      },
      layout: 'lightBorder',
      style: 'table',
    })
  })
  _.last(policyValueTables).table.body.push(buildTotalPLBPolicyValueRow(policyValue))
  setLayoutDisclaimer(policyValueTables, rowsPerPage, disclaimerPLB)
  return policyValueTables
}

const setLayoutDisclaimer = (policyValueTables, rowsPerPage, disclaimer) => {
  const headerRow = 4
  const rowDisclaimer = 7
  const amountPage = policyValueTables.length ? policyValueTables.length : 1
  const maxofPage = rowsPerPage[amountPage - 1] + headerRow
  const rowsofPage = policyValueTables[amountPage - 1].table.body.length
  if (rowsofPage >= maxofPage - rowDisclaimer) {
    disclaimer.pageBreak = 'before'
  }
}

const buildLPLBHeaderTable = (isMHPProduct) => {
  const messageSurrenderValue = isMHPProduct
    ? MESSAGES.POLICY_VALUE_TABLE_CASH_SURRENDER_VALUE + '**'
    : MESSAGES.POLICY_VALUE_TABLE_CASH_SURRENDER_VALUE

  return [
    [
      { text: CONSTANTS.AGE, rowSpan: 3, style: 'tableHeader' },
      {
        text: MESSAGES.POLICY_VALUE_TABLE_INSURANCE_YEAR,
        rowSpan: 3,
        style: 'tableHeader',
      },
      { text: MESSAGES.PREMIUM_PER_YEAR, colSpan: 3, style: 'tableHeader' },
      { text: '' },
      { text: '' },
      {
        text: MESSAGES.END_OF_YEAR_POLICY_WITH_BRACKET,
        colSpan: 2,
        style: 'tableHeader',
      },
      { text: '' },
    ],
    [
      '',
      '',
      { text: MESSAGES.BASIC_PLAN, rowSpan: 2, style: 'tableHeader' },
      { text: MESSAGES.RIDER, rowSpan: 2, style: 'tableHeader' },
      { text: MESSAGES.TOTAL, rowSpan: 2, style: 'tableHeader' },
      {
        text: _breakLine(messageSurrenderValue),
        rowSpan: 2,
        style: 'tableHeader',
      },
      {
        text: _breakLine(MESSAGES.POLICY_VALUE_TABLE_DEATH_BENEFIT),
        rowSpan: 2,
        style: 'tableHeader',
      },
    ],
    ['', '', '', '', '', '', ''],
  ]
}

const buildPLBPolicyValueRow = (yearEndPolicy: YearEndPolicyValue) => [
  {
    text: yearEndPolicy.age.value,
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.yearEndPolicy,
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.annualBasicPremium === 0 ? '-' : formatNumber(yearEndPolicy.annualBasicPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.annualRiderPremium === 0 ? '-' : formatNumber(yearEndPolicy.annualRiderPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.annualTotalPremium === 0 ? '-' : formatNumber(yearEndPolicy.annualTotalPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.surrenderCash === 0 ? '-' : formatNumber(yearEndPolicy.surrenderCash),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.deathBenefit === 0 ? '-' : formatNumber(yearEndPolicy.deathBenefit, 2, true),
    alignment: 'right',
    ...rowHeightConfig,
  },
]

const buildTotalPLBPolicyValueRow = (policyValue: PolicyValue) => [
  '',
  '',
  {
    text: policyValue.subtotalAnnualBasicPremium === 0 ? '-' : formatNumber(policyValue.subtotalAnnualBasicPremium, 2),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  {
    text: policyValue.subtotalAnnualRiderPremium === 0 ? '-' : formatNumber(policyValue.subtotalAnnualRiderPremium, 2),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  {
    text: policyValue.grandTotalPremium === 0 ? '-' : formatNumber(policyValue.grandTotalPremium, 2),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  '',
  '',
]

export const policyValueTableSection = ({ policyValue }: PolicyValueTableProps) => [
  { text: MESSAGES.POLICY_VALUE_TABLE, style: 'title', pageBreak: 'before' },
  ...buildPolicyValueTable(policyValue, false),
  disclaimerPLB,
]

export const policyValueTableSectionMHP = ({ policyValue }: PolicyValueTableProps) => [
  { text: MESSAGES.POLICY_VALUE_TABLE, style: 'title', pageBreak: 'before' },
  ...buildPolicyValueTable(policyValue, true),
  disclaimerMHPPLB,
]
