import { requestWrapper } from 'e-submission/domain/data'
import VALUES from 'core/data-model/constants/values'

export const updateDCA = (user, dca = {}) => {
  return requestWrapper.putRequest(`esub/policies/${dca.policyId}/dca`, user, dca).then(({ data }) => data)
}

export const signedDCA = (user, dca = {}) => {
  return requestWrapper
    .patchRequest(`esub/policies/${dca.policyId}/dca`, user, {
      ...dca,
      status: VALUES.DCA_STATUS.SIGNED,
    })
    .then(({ data }) => data)
}

export const cleanDCA = (user, dca = {}) => {
  return requestWrapper.deleteRequest(`esub/policies/${dca.policyId}/dca`, user, dca).then(({ data }) => data)
}
