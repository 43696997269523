// @flow
import _ from 'lodash'
import Mustache from 'mustache'
import riderMessages from 'core/data-model/constants/validation-messages'
import { formatNumber } from 'core/service/lib/number-format'
import type { SumAssuredTiersRule } from 'core/data-model/rider/rules'
import type { RiderEligibilities } from 'core/data-model/rider'
import type { ValidationMessage } from 'core/data-model/validation'

export const checkSumAssuredTiersRule = (
  rule: SumAssuredTiersRule,
  eligibilities: RiderEligibilities
): ValidationMessage => {
  let errorMessage = ''

  const tier = findSumAssuredTier(rule.tiers, eligibilities.basicSumAssured)
  if (tier && eligibilities.rider.sumAssured > tier.maximumRiderSumAssured) {
    errorMessage = Mustache.render(riderMessages.RULE_RIDER_SUM_ASSURED_TIERS, {
      minimumBasicSumAssured: formatNumber(tier.minimumBasicSumAssured),
      maximumBasicSumAssured: formatNumber(tier.maximumBasicSumAssured),
      maximumRiderSumAssured: formatNumber(tier.maximumRiderSumAssured),
    })
  }

  return {
    type: rule.validationType,
    message: errorMessage,
  }
}

const findSumAssuredTier = (tiers, basicSumAssured: number) => {
  return _(tiers)
    .chain()
    .find(
      ({ minimumBasicSumAssured, maximumBasicSumAssured }) =>
        Math.floor(basicSumAssured) >= minimumBasicSumAssured && Math.floor(basicSumAssured) <= maximumBasicSumAssured
    )
    .value()
}
