// @flow

import _ from 'lodash'
import type { AppState } from 'quick-quote/reducers'
import type { HealthToppingPlan } from 'core/data-model/basic-plan/types'
import { createSelector } from 'reselect'

export const getHealthToppingPlans = (state: AppState): HealthToppingPlan[] =>
  _.get(state, 'selectedDisplayProduct.plans', [])

export const getSelectCampaignPlan = (state: AppState): string =>
  _.get(state, 'selectedDisplayProduct.selectedCampaignPlan', '')

export const getHealthToppingOptionsPlans = createSelector(getHealthToppingPlans, (plans) => {
  return plans.map((v) => ({
    text: v.name,
    value: v.planCode,
  }))
})
