/* istanbul ignore file */

import { getAppConfig } from 'deploy-env/app-config'
import { clearAllCordovaCookies } from 'lib/cordova-util'
import { getToggles } from 'quick-quote/feature-toggles'

const FACEBOOK_GRAPH_IMAGE_URL = 'https://graph.facebook.com/me/?fields=picture.type(normal)&access_token='

// Error messages
const ERROR_GETTING_IDP_TOKEN = 'Could not get IDP token'
const LOCAL_REQUEST_FAILED = 'Local request failed'
const ERROR_GETTING_FACEBOOK_IMAGE = 'Could not get Facebook user image profile'

export const getFBToken = (accessToken, token_type) => {
  return new Promise(function(resolve, reject) {
    let xhr = new XMLHttpRequest()
    xhr.onload = function() {
      const statusCode = window.cordova && xhr.status === 0 ? 200 : xhr.status
      const response = JSON.parse(xhr.responseText)
      response.status = statusCode
      resolve(response)
    }
    xhr.onerror = function() {
      reject(new TypeError('Local request failed'))
    }
    xhr.open('GET', `${getAppConfig().AUTH_AUTHORITY}/broker/facebook/token`)
    const authentication = token_type + ' ' + accessToken
    xhr.setRequestHeader('Authorization', authentication)
    xhr.send(null)
  }).then((r) => {
    if (r.status >= 400) {
      throw Error(ERROR_GETTING_IDP_TOKEN)
    } else {
      return r
    }
  })
}

export const logoutFromIDP = () => {
  if (window.cordova) {
    clearAllCordovaCookies()
  } else {
    logoutFromIAM()
  }
}

export const logoutFromIAM = () => {
  const url = getToggles().ENABLE_IAMC_IDP ? getAppConfig().IAMC_LOGOUT_URL : getAppConfig().IAM_LOGOUT_URL
  let windowOpener = window.open(`${url}`, 'logoutIAM', 'width=400,height=400')
  setTimeout(() => {
    if (getToggles().ENABLE_IAMC_IDP) windowOpener.close()
  }, 2000)
}

export const getFBUserImage = (accessToken) => {
  return new Promise(function(resolve, reject) {
    let xhr = new XMLHttpRequest()
    xhr.onload = function() {
      const statusCode = window.cordova && xhr.status === 0 ? 200 : xhr.status
      const response = JSON.parse(xhr.responseText)
      response.status = statusCode
      resolve(response)
    }
    xhr.onerror = function() {
      reject(new TypeError(LOCAL_REQUEST_FAILED))
    }
    xhr.open('GET', `${FACEBOOK_GRAPH_IMAGE_URL}${accessToken}`)
    xhr.send(null)
  }).then((r) => {
    if (r.status >= 400) {
      throw Error(ERROR_GETTING_FACEBOOK_IMAGE)
    } else {
      return r
    }
  })
}
