//@flow
import type { PermissionResult } from 'core/service/distributor/permission'

export type Action = AllowProceedOrdinary | ResetOrdinaryPermission | RejectOrdinaryPermission

type AllowProceedOrdinary = { type: 'ALLOW_PROCEED_ORDINARY' }
export const ALLOW_PROCEED_ORDINARY = 'ALLOW_PROCEED_ORDINARY'
export const allowProceedOrdinary = (): AllowProceedOrdinary => ({ type: 'ALLOW_PROCEED_ORDINARY' })

type ResetOrdinaryPermission = { type: 'RESET_ORDINARY_PERMISSION' }
export const RESET_ORDINARY_PERMISSION = 'RESET_ORDINARY_PERMISSION'
export const resetOrdinaryPermission = (): ResetInvestmentPermission => ({ type: 'RESET_ORDINARY_PERMISSION' })

type RejectOrdinaryPermission = { type: 'REJECT_ORDINARY_PERMISSION', payload: PermissionResult }
export const REJECT_ORDINARY_PERMISSION = 'REJECT_ORDINARY_PERMISSION'
export const rejectOrdinaryPermission = (permissionResult: PermissionResult): RejectOrdinaryPermission => ({
  type: 'REJECT_ORDINARY_PERMISSION',
  payload: permissionResult,
})

type ValidatingSalePermission = { type: 'VALIDATING_SALE_PERMISSION' }
export const VALIDATING_SALE_PERMISSION = 'VALIDATING_SALE_PERMISSION'
export const validatingSalePermission = (): ValidatingSalePermission => ({
  type: 'VALIDATING_SALE_PERMISSION',
})

type SalePermissionValidationCompleted = { type: 'SALE_PERMISSION_VALIDATION_COMPLETED' }
export const SALE_PERMISSION_VALIDATION_COMPLETED = 'SALE_PERMISSION_VALIDATION_COMPLETED'
export const salePermissionValidationCompleted = (): SalePermissionValidationCompleted => ({
  type: 'SALE_PERMISSION_VALIDATION_COMPLETED',
})
