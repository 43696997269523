// @flow
import type { AppState } from 'quick-quote/reducers'

import { compose } from 'recompose'
import { Button } from 'reactstrap'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import MESSAGES from 'core/data-model/constants/messages'
import { setNotificationMessage } from 'quick-quote/notification/actions'
import { enableFnaPhase } from 'quick-quote/fna/actions'
import { getToggles } from 'quick-quote/feature-toggles'
import { getAppConfig } from 'deploy-env/app-config'
import { getUserAgentType } from 'identity/selectors'
import { getSelectedDisplayBasicPlanCode } from 'quick-quote/product-selection/selectors'
import { IsRealDevice } from 'e-submission/utils'
import values from 'core/data-model/constants/values'

type Props = {
  setNotificationMessage: ({ type: string }) => void,
  enableFnaPhase: () => void,
  currentUserRole: string,
  disabled?: boolean,
}

export const _ProceedToFnaButton = (props: Props) => {
  const RealButton = (
    <Button
      className="e-submission"
      color="primary"
      onClick={() =>
        getToggles().ENABLE_FNA_QQ_NAV_V2
          ? props.setNotificationMessage({ type: 'ConfirmToFNA' })
          : props.enableFnaPhase()
      }
      disabled={props.disabled}
    >
      {MESSAGES.PROCEED_TO_FNA}
    </Button>
  )

  /* istanbul ignore next */
  const isBanca = props.currentUserRole === values.AGENT_TYPE_LB
  if (isBanca) {
    // Not allow Banca to use web view to esubmission flow
    if (!IsRealDevice && getToggles().ENABLE_BANCA_BLOCK_ESUB_WEB_VIEW) return null

    // Not allow Banca to use except product in list to allow
    if (
      getToggles().ENABLE_ESUB_BTN_FOR_LB_WITH_PRODUCTLIST &&
      getAppConfig().ESUB_BTN_FOR_LB_WITH_PRODUCTLIST.includes(props.basicPlanCode)
    ) {
      return RealButton
    } else {
      return null
    }
  } else if (
    props.currentUserRole === values.AGENT_TYPE_FSE ||
    props.currentUserRole === values.AGENT_TYPE_BANCA ||
    getToggles().ENABLE_REVISE_FNA
  ) {
    return null
  }

  return RealButton
}

const mapStateToProps = (state: AppState) => ({
  currentUserRole: getUserAgentType(state),
  basicPlanCode: getSelectedDisplayBasicPlanCode(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  setNotificationMessage: bindActionCreators(setNotificationMessage, dispatch),
  enableFnaPhase: bindActionCreators(enableFnaPhase, dispatch),
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(_ProceedToFnaButton)
