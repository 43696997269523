// @flow
import { formatNumber } from 'core/service/lib/number-format'
import type { YearEndPolicyValue } from 'core/service/benefit-illustration'
import { Entry, Track, EntryFixed } from 'quick-quote/product-investment/coverage-plan/components/StickyTable'

// $$FlowFixMe
type YearEndPolicyTwelvePLValue = YearEndPolicyValue & {
  lowInterest: number,
  lowInterestDeposit: number,
  midInterest: number,
  midInterestDeposit: number,
  highInterest: number,
  highInterestDeposit: number,
  totalLowDividend: number,
  totalMidDividend: number,
  totalHighDividend: number,
}

export const dashedOutOrWithPrecision = (value: number, precision: number, isRound: boolean = true) =>
  value === 0 ? '-' : formatNumber(value, precision, isRound)

export const PolicyValueRow = ({
  age,
  yearEndPolicy,
  cumulativeBasicPremium,
  annualBasicPremium,
  annualRiderPremium,
  annualTotalPremium,
  surrenderCash,
  deathBenefit,
  cashDrop,
  sumCashDrop,
  lowInterest,
  lowInterestDeposit,
  midInterest,
  midInterestDeposit,
  highInterest,
  highInterestDeposit,
  totalLowDividend,
  totalMidDividend,
  totalHighDividend,
  totalBenefit,
}: YearEndPolicyTwelvePLValue) => {
  return (
    <Track>
      <EntryFixed>
        <Entry className="policy-value-age">{age.value - 1}</Entry>
        <Entry className="policy-value-year">{yearEndPolicy}</Entry>
      </EntryFixed>
      <Entry className="policy-value-cumulative-basic-premium">
        {dashedOutOrWithPrecision(cumulativeBasicPremium || 0, 2)}
      </Entry>
      <Entry className="policy-value-annual-basic-premium">
        {dashedOutOrWithPrecision(annualBasicPremium || 0, 2)}
      </Entry>
      <Entry className="policy-value-annual-rider-premium">
        {dashedOutOrWithPrecision(annualRiderPremium || 0, 2)}
      </Entry>
      <Entry className="policy-value-annual-total-premium">
        {dashedOutOrWithPrecision(annualTotalPremium || 0, 2)}
      </Entry>
      <Entry className="policy-value-surrender-cash">{dashedOutOrWithPrecision(cashDrop, 0)}</Entry>
      <Entry className="policy-value-death-benefit">{dashedOutOrWithPrecision(sumCashDrop, 0)}</Entry>
      <Entry className="policy-value-pension-year-cash">{dashedOutOrWithPrecision(lowInterest, 0)}</Entry>
      <Entry className="policy-value-pension-balance-cash">{dashedOutOrWithPrecision(lowInterestDeposit, 0)}</Entry>
      <Entry className="policy-value-pension-balance-cash">{dashedOutOrWithPrecision(midInterest, 0)}</Entry>
      <Entry className="policy-value-pension-balance-cash">{dashedOutOrWithPrecision(midInterestDeposit, 0)}</Entry>
      <Entry className="policy-value-pension-balance-cash">{dashedOutOrWithPrecision(highInterest, 0)}</Entry>
      <Entry className="policy-value-pension-balance-cash">{dashedOutOrWithPrecision(highInterestDeposit, 0)}</Entry>
      <Entry className="policy-value-pension-balance-cash">{dashedOutOrWithPrecision(totalLowDividend, 0)}</Entry>
      <Entry className="policy-value-pension-balance-cash">{dashedOutOrWithPrecision(totalMidDividend, 0)}</Entry>
      <Entry className="policy-value-pension-balance-cash">{dashedOutOrWithPrecision(totalHighDividend, 0)}</Entry>
      <Entry className="policy-value-pension-balance-cash">{dashedOutOrWithPrecision(deathBenefit, 0)}</Entry>
      <Entry className="policy-value-pension-balance-cash">{dashedOutOrWithPrecision(totalBenefit, 0)}</Entry>
      <Entry className="policy-value-pension-balance-cash">{dashedOutOrWithPrecision(surrenderCash, 0)}</Entry>
    </Track>
  )
}

export default PolicyValueRow
