import { isCordova } from 'e-submission/utils'
import applicationWebWrapper from './application-web-wrapper'
import applicationCordovaWrapper from './application-cordova-wrapper'

export const applicationWrapper = () => {
  if (isCordova) {
    return applicationCordovaWrapper
  }
  return applicationWebWrapper
}

// export databaseWrapper from './db-wrapper'
export requestWrapper from './request-wrapper'
