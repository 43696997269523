import generateUUID from 'uuid/v1'
import { IsRealDevice, isProdEnv } from '..'

const defaults = {
  onSuccess: async (e, log = true) => {
    if (log) console.log('Response', JSON.stringify(e))
    return e
  },
  onError: async (e) => {
    console.error('Error', JSON.stringify(e))
    throw e
  },
}
export const DF2F = {
  UPLOAD_EVENT: 'sendUploadEvent',
  BANK_CLEARING_EVENT: 'sendBankClearingEvent',
  BANK_CLEARING_PREVIEW: 'sendBankPreviewEvent',
  PAYMENT_EVENT: 'sendPaymentEvent',
  SIGNATURE_EVENT: 'sendSignatureEvent',
  REVIEW_DOCUMENT: 'sendReviewDocumentEvent',
  GET_CALL_STATUS: 'getCallStatus',
  GET_MEDIA_STATUS: 'getMediaStatus',
  END_CALL: 'stopVideoCall',
  CONTROL_MEDIA: 'controlMedia',
  OPEN_DF2F_APP: 'openDF2F',
  TOGGLE_SHARE_SCREEN: 'sharingScreen',
  CREATE_NEW_CASE: 'updateAppId',
  CREATE_NEW_POLICY: 'updatePolicyNumber',
  OPEN_ON_BOARDING_STEP: 'openOnBoardingStep',
  ADD_PROPRIETOR_TO_CASE: 'addProprietor',
  UPDATE_PROPRIETOR_IN_CASE: 'updateProprietor',
  RETIREMENT_EVENT: 'sentRetirementEvent',
  RETIREMENT_PREVIEW: 'sentRetirementPreview',
  PAYMENT_RESULT_EVENT: 'sendPaymentResultEvent',
  CANCEL_EVENT: 'sendCancelEvent',
  GET_FRONT_ID_CARD_URL: 'getFrontIdCardUrl',
}

const mockImages = [
  {
    url: 'https://d27zvxcfjgrhx5.cloudfront.net/remote-selling-mock/id_card.jpg',
    fileType: 'image/jpeg',
    name: '1',
    id: generateUUID(),
  },
  {
    url: 'https://d27zvxcfjgrhx5.cloudfront.net/remote-selling-mock/id_card.jpg',
    fileType: 'image/jpeg',
    name: '2',
    id: generateUUID(),
  },
]
const mockRetirement = {
  formData: {
    lastName: 'hh',
    firstName: 'hh',
    bankName: {
      value: '006',
      text: 'ธนาคารกรุงไทย',
      monthlyCollect: 1,
    },
    accountNumber: '111-1-11111-1',
    title: {
      value: 'mr',
      text: 'นาย',
      gender: 'M',
    },
  },
}
const ekycMock = {
  caseId: '101c88c5-9aa8-4c0f-93ea-46fe6e2644d0',
  insured: {
    feedback: 'ะั',
    backIdCardConfig: { dependenciesRequired: false, isEditable: true, required: true, threshHold: 0.95 },
    backIdCardResult: {
      createdAt: '2022-11-15T08:54:22.304Z',
      edits: { laser: 'JC3-1597877-96' },
      id: '4a18001e-9cd6-4dcc-9762-d4dbe15961f1',
      response: { result: { laser_code: 'JC3-1597877-96' } },
      updatedAt: '2022-11-15T08:54:26.064Z',
      verified: true,
    },
    citizenId: '1100400984897',
    dopaConfig: { attempts: 3, dependenciesRequired: true, required: true },
    dopaResult: {
      dopaRequestFailed: false,
      verified: true,
      id: '1c61f4d0-afdb-4945-9151-350e3230478c',
    },
    email: '',
    expiresAt: '2022-12-15T08:35:34.565Z',
    firstName: 'Gg',
    frontIdCardConfig: { dependenciesRequired: false, isEditable: true, required: true, threshHold: 0.8 },
    frontIdCardResult: {
      createdAt: '2022-11-15T08:53:40.790Z',
      edits: {
        address: '283 ถ.พระรามที่ 3 แขวงบางคอแหลม เขตบางคอแหลม กรุงเทพมหานคร',
        citizenId: '1-1004-00984-89-7',
        dateOfBirth: '1999-04-02',
        expiryDate: '2031-03-31T17:00:00.000Z',
        firstNameEn: 'Jidapa',
        firstNameTh: 'จิดาภา',
        issueDate: '2022-10-05T17:00:00.000Z',
        lastNameEn: 'Obkijkajpon',
        lastNameTh: 'โอบกิจกาจพล',
        titleEn: 'Miss',
        titleTh: 'น.ส.',
      },
      id: '6048397e-8584-4fbe-ae69-adbf66363173',
      response: {
        idCardImageUrl: 'https://d27zvxcfjgrhx5.cloudfront.net/remote-selling-mock/id_card.jpg',
        result: {
          address_no: '283 ถ.พระรามที่ 3',
          address_th: '283 ถ.พระรามที่ 3 แขวงบางคอแหลม เขตบางคอแหลม กรุงเทพมหานคร',
          date_of_birth_en: '2 Apr. 1999',
          date_of_birth_th: '2 เม.ย. 2542',
          date_of_expiry_en: '1 Apr. 2031',
          date_of_expiry_th: '1 เม.ย. 2574',
          date_of_issue_en: '6 Oct. 2022',
          date_of_issue_th: '6 ต.ค. 2565',
          district: 'เขตบางคอแหลม',
          first_name_en: 'Jidapa',
          first_name_th: 'จิดาภา',
          full_name_th: 'น.ส. จิดาภา โอบกิจกาจพล',
          id_number: '1 1004 00984 89 7',
          last_name_th: 'โอบกิจกาจพล',
          middle_last_name_en: 'Obkijkajpon',
          province: 'กรุงเทพมหานคร',
          religion_th: 'พุทธ',
          serial_number: '1031-03-10060826',
          sub_district: 'แขวงบางคอแหลม',
          title_en: 'Miss',
          title_first_name_en: 'Miss Jidapa',
          title_th: 'น.ส.',
        },
      },
      updatedAt: '2022-11-15T08:53:44.915Z',
      verified: false,
    },
    id: '2f1270f8-ce6b-4860-b34f-6e476d7dd40f',
    idFaceRecognitionConfig: {
      attempts: 3,
      dependenciesRequired: false,
      livenessCount: 1,
      required: true,
      threshHold: 0.9,
    },
    idFaceRecognitionResult: {
      livenessResponse: [{ data: { pass: true } }],
      response: {
        data: { result: [{ similarity: 0.9292681 }] },
        faceImageUrl: 'https://d27zvxcfjgrhx5.cloudfront.net/remote-selling-mock/signature.png',
        idFaceRecognitionImageUrl: 'https://d27zvxcfjgrhx5.cloudfront.net/remote-selling-mock/signature.png',
      },
      verified: true,
    },
    lastName: 'Ffg',
    notifyType: 'none',
    pdpaConsented: true,
    phoneNumber: '0619926554',
    ref: 'c4c72550-8fbf-4853-999d-7a72422652d3',
    status: 'completed',
    verifiedAt: '2022-11-15T08:55:13.050Z',
    proprietorId: '3a4a0227-9180-4b5e-a163-edf15b0a0f0c',
  },

  payer: {
    backIdCardConfig: { dependenciesRequired: false, isEditable: true, required: true, threshHold: 0.95 },
    backIdCardResult: {
      createdAt: '2022-11-15T08:57:54.343Z',
      edits: { laser: 'ME1-1230788-02' },
      id: '57f0b458-6543-49c0-9f2e-4b1a30591171',
      response: { result: { laser_code: 'ME1-1230788-02' } },
      updatedAt: '2022-11-15T08:57:57.413Z',
      verified: true,
    },
    citizenId: '2100300026833',
    dopaConfig: { attempts: 3, dependenciesRequired: true, required: true },
    dopaResult: {
      dopaRequestFailed: false,
      verified: true,
      id: 'e5b3a556-5107-406e-9287-280a79f8be7d',
    },
    email: '',
    expiresAt: '2022-12-15T08:47:42.431Z',
    feedback: 'ะั',
    firstName: 'G',
    frontIdCardConfig: { dependenciesRequired: false, isEditable: true, required: true, threshHold: 0.8 },
    frontIdCardResult: {
      createdAt: '2022-11-15T08:57:24.212Z',
      edits: {
        address: '58/1 หมู่ที่ ต.บางน้ำเปรี้ยว อ.บางน้ำเปรี้ยว จ.ฉะเชิงเทรา',
        citizenId: '2-1003-00026-83-3',
        dateOfBirth: '1999-02-19',
        expiryDate: '2027-02-17T17:00:00.000Z',
        firstNameEn: 'Supatet',
        firstNameTh: 'ศุภธัช',
        issueDate: '2018-05-27T17:00:00.000Z',
        lastNameEn: 'Thongklum',
        lastNameTh: 'ทองกล่ำ',
        titleEn: 'Mr.',
        titleTh: 'นาย',
      },
      id: '454ebfec-cb7f-4531-a910-19590ea7f940',
      response: {
        idCardImageUrl: 'https://d27zvxcfjgrhx5.cloudfront.net/remote-selling-mock/id_card.jpg',
        result: {
          address_no: '58/1 หมู่ที่',
          address_th: '58/1 หมู่ที่ ต.บางน้ำเปรี้ยว อ.บางน้ำเปรี้ยว จ.ฉะเชิงเทรา',
          date_of_birth_en: '19 Feb. 1999',
          date_of_birth_th: '19 ก.พ. 2542',
          date_of_expiry_en: '18 Feb. 2027',
          date_of_expiry_th: '18 ก.พ. 2570',
          date_of_issue_en: '28 May 2018',
          date_of_issue_th: '28 พ.ค. 2561',
          district: 'อ.บางน้ำเปรี้ยว',
          first_name_en: 'Supatet',
          first_name_th: 'ศุภธัช',
          full_name_th: 'นาย ศุภธัช ทองกล่ำ',
          id_number: '2 1003 00026 83 3',
          last_name_th: 'ทองกล่ำ',
          middle_last_name_en: 'Thongklum',
          province: 'จ.ฉะเชิงเทรา',
          religion_th: 'อิสลาม',
          serial_number: '2403-02-05280910',
          sub_district: 'ต.บางน้ำเปรี้ยว',
          title_en: 'Mr.',
          title_first_name_en: 'Mr. Supatet',
          title_th: 'นาย',
        },
      },
      updatedAt: '2022-11-15T08:57:28.428Z',
      verified: false,
    },
    id: '2f410e19-148c-463f-96bf-53a42ae61e7e',
    idFaceRecognitionConfig: {
      attempts: 3,
      dependenciesRequired: false,
      livenessCount: 1,
      required: true,
      threshHold: 0.9,
    },
    idFaceRecognitionResult: {
      livenessResponse: [{ data: { pass: true } }],
      response: {
        data: { result: [{ similarity: 0.37891513 }] },
        faceImageUrl: 'https://d27zvxcfjgrhx5.cloudfront.net/remote-selling-mock/signature.png',
        idFaceRecognitionImageUrl: 'https://d27zvxcfjgrhx5.cloudfront.net/remote-selling-mock/signature.png',
      },
      verified: false,
    },
    lastName: 'F',
    notifyType: 'none',
    pdpaConsented: true,
    phoneNumber: '0619926554',
    ref: '8727c22d-6eff-4441-831b-73371e3d735b',
    status: 'completed',
    verifiedAt: '2022-11-15T09:05:27.950Z',
    proprietorId: '3a4a0227-9180-4b5e-a163-edf15b0a0f0c',
  },
}
const dopaFail = {
  dopaRequestFailed: true,
  verified: false,
}
const mockPayment = {
  images: [
    {
      url: 'https://d27zvxcfjgrhx5.cloudfront.net/remote-selling-mock/signature.png',
      fileType: 'image/png',
      name: 'eee',
    },
  ],
  success: true,
  selectedBank: {
    bank: {
      text: 'ธนาคารทหารไทยธนชาต',
      value: 'TTB8',
    },
    uploadDate: '2022-12-28T07:48:44.102Z',
  },
}
const mockBankClearing = {
  formData: {
    passportNumber: '',
    lastName: 'hh',
    firstName: 'hh',
    documentType: {
      value: '001',
      text: 'บัตรประจำตัวประชาชน',
    },
    bankName: {
      value: '006',
      text: 'ธนาคารกรุงไทย',
      monthlyCollect: 1,
    },
    accountNumber: '111-1-11111-1',
    title: {
      value: 'mr',
      text: 'นาย',
      gender: 'M',
    },
    accountType: {
      value: 'ออมทรัพย์',
      text: 'ออมทรัพย์',
    },
    relationship: {
      value: 'SELF',
      text: 'ตัวเอง',
    },
    email: '',
    citizenID: '1111111111119',
    phoneNumber: '0948095982',
  },
}

const mockResponse = async (event, payload, onSuccess, onError) => {
  if (isProdEnv) throw new Error('Cannot mock response in production environment')
  switch (event) {
    case DF2F.UPLOAD_EVENT: {
      return onSuccess(mockImages.map((image) => ({ ...image, id: generateUUID() })))
    }
    case DF2F.BANK_CLEARING_EVENT: {
      return onSuccess(mockBankClearing)
    }
    case DF2F.PAYMENT_EVENT: {
      return onSuccess(mockPayment)
    }
    case DF2F.SIGNATURE_EVENT: {
      return onSuccess({ ...mockImages[1], id: generateUUID() })
    }
    case DF2F.REVIEW_DOCUMENT: {
      return onSuccess({ url: 'https://d27zvxcfjgrhx5.cloudfront.net/remote-selling-mock/signature.png' })
    }
    case DF2F.RETIREMENT_PREVIEW: {
      return onSuccess(mockPayment)
    }
    case DF2F.BANK_CLEARING_PREVIEW: {
      return onSuccess(mockPayment)
    }
    case DF2F.RETIREMENT_EVENT: {
      return onSuccess(mockRetirement)
    }
    case DF2F.OPEN_DF2F_APP: {
      return onSuccess({
        name: 'startVideoCall',
        payload: {
          ...payload,
          // appId: 'application_37e8ec80-d9bd-485b-aa56-326f20670297_29a8b550-859f-11ed-969b-c767180fb63f',
          roomId: 'mockRoomId',
          email: 'appman@jeet.com',
          phoneNumber: '0949637874',
          caseId: 'c946a881-4bf2-43be-9764-464d18b83096',
        },
      })
      // -----------------------
      // return onSuccess({
      //   name: 'resumeCase',
      //   payload: {
      //     appId: 'df2f',
      //   },
      // })
      // ------------------------
      // return onSuccess({
      //   name: 'backToHomePage',
      //   payload: {},
      // })
    }
    case DF2F.GET_FRONT_ID_CARD_URL: {
      return onSuccess({
        insured: {
          idCardImageUrl: 'https://d27zvxcfjgrhx5.cloudfront.net/remote-selling-mock/id_card.jpg',
        },
        payer: {
          idCardImageUrl: 'https://d27zvxcfjgrhx5.cloudfront.net/remote-selling-mock/id_card.jpg',
        },
      })
    }
    case DF2F.GET_MEDIA_STATUS: {
      return onSuccess(
        {
          microphoneEnable: true,
        },
        false
      )
    }
    case DF2F.GET_CALL_STATUS: {
      return onSuccess(
        {
          isAgentStreaming: true,
          isClientStreaming: true,
          latency: 0,
        },
        false
      )
    }
    case DF2F.OPEN_ON_BOARDING_STEP: {
      const insuredId = payload?.insuredId
      const payerId = payload?.payerId
      let response = {
        name: 'nextStep',
        payload: { ...ekycMock, url: 'sd' },
      }
      if (insuredId == '2248212328192') {
        response = {
          ...response,
          payload: {
            ...response.payload,
            insured: {
              ...ekycMock.insured,
              dopaResult: dopaFail,
            },
          },
        }
      }
      if (payerId == '1000000000009') {
        response = {
          ...response,
          payload: {
            ...response.payload,
            payer: {
              ...ekycMock.payer,
              dopaResult: dopaFail,
            },
          },
        }
      }
      return onSuccess(response)
    }
    default: {
      return onSuccess(event)
    }
  }
}
export default {
  sendEvent: async (event = '', payload = {}, onSuccess = defaults.onSuccess, onError = defaults.onError) => {
    const ignoreList = [DF2F.GET_MEDIA_STATUS]
    if (!ignoreList.includes(event)) {
      console.log('sendEvent', event, payload)
    }
    return IsRealDevice || isProdEnv
      ? await new Promise((resolve, reject) => {
          window.DF2F.callEvent(
            event,
            payload,
            (e) => resolve(onSuccess(e, !ignoreList.includes(event))),
            (e) => reject(onError(e))
          )
        })
      : await mockResponse(event, payload, onSuccess, onError)
  },
}
