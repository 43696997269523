// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import type { BasicPremium$, SumAssured$ } from 'core/data-model/basic-plan'
import type { AppState } from 'quick-quote/reducers'

import { FormGroup } from 'reactstrap'
import { enableBasicSumAssured, isInvalid } from 'common-components/view-logic'

import BasicSumAssured from 'quick-quote/product-common/coverage-plan/components/basic-plan/basic-sum-assured'
import BasicPremium from 'quick-quote/product-common/coverage-plan/components/basic-plan/basic-premium'
import BasicPlanErrorMessages from 'quick-quote/product-common/coverage-plan/components/basic-plan/basic-plan-error-messages'

import {
  getReadOnlyFields,
  haveBasicPremiumState,
  getSumAssured$,
  getBasicPremium$,
  getBasicPremiumState,
} from 'quick-quote/product-common/coverage-plan/selectors'

import { editBasicPremiumState, editSumAssured } from 'quick-quote/product-common/coverage-plan/actions'

type BasicPlanProps = {
  basicPremiumState: number,
  haveBasicPremiumState: boolean,
  sumAssured$: SumAssured$,
  basicPremium$: BasicPremium$,
  readOnlyFields: { [string]: boolean },
  editSumAssured: (?number) => void,
  editBasicPremium: (number) => void,
}

export const BasicPlan = ({
  basicPremiumState,
  haveBasicPremiumState,
  sumAssured$,
  basicPremium$,
  readOnlyFields,
  editSumAssured,
  editBasicPremium,
}: BasicPlanProps) => {
  const isInvalidState = isInvalid(haveBasicPremiumState, sumAssured$, basicPremium$)
  return (
    <FormGroup className={`coverage-plan-calculation item basic ${isInvalidState ? 'has-danger' : ''}`}>
      <div className="form-group-title">
        <span className="form-group-title-text">สัญญาหลัก</span>
      </div>
      <div className="sum-assured-input">
        <div className="label">
          <label>จำนวนเงินเอาประกันภัย</label>
          <label>เบี้ยสัญญาหลัก</label>
        </div>
        <div className="options">
          <div className="sum-assured">
            <BasicSumAssured
              isReadOnly={readOnlyFields.basicSumAssured}
              sumAssured={sumAssured$.value}
              enabled={enableBasicSumAssured(haveBasicPremiumState, basicPremium$)}
              editSumAssured={editSumAssured}
            />
          </div>

          <div className="link">{haveBasicPremiumState ? '←' : '→'}</div>

          <div className="premium">
            <BasicPremium
              isReadOnly={readOnlyFields.basicPremium}
              basicPremium={haveBasicPremiumState ? basicPremiumState : basicPremium$.value}
              editBasicPremium={editBasicPremium}
            />
          </div>
        </div>
        <BasicPlanErrorMessages
          haveBasicPremiumState={haveBasicPremiumState}
          sumAssured$={sumAssured$}
          basicPremium$={basicPremium$}
        />
      </div>
    </FormGroup>
  )
}

const mapStateToProps = (state: AppState) => ({
  basicPremiumState: getBasicPremiumState(state),
  haveBasicPremiumState: haveBasicPremiumState(state),
  sumAssured$: getSumAssured$(state),
  basicPremium$: getBasicPremium$(state),
  readOnlyFields: getReadOnlyFields(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  editSumAssured: bindActionCreators(editSumAssured, dispatch),
  editBasicPremium: bindActionCreators(editBasicPremiumState, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(BasicPlan)
