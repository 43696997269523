// @flow
import type { AppState } from 'quick-quote/reducers'
import type { DistributorGroupType } from 'core/data-model/distributor'
import type { FSE, Office, AgentType } from 'core/data-model/identity'

import { compose, withState } from 'recompose'
import { Button } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withModalToggle } from 'lib/with-hoc'
import { getAdvisor } from 'e-submission/domain/service'
import { InformationBranchModal } from 'quick-quote/components'
import { validateAdvisorPermission, validateUserStartedJourney } from 'core/service/distributor/permission'
import { getToggles } from 'quick-quote/feature-toggles'
import { getAppConfig } from 'deploy-env/app-config'
import { IsRealDevice } from 'e-submission/utils'
import values from 'core/data-model/constants/values'
import {
  getIdentityUser,
  getSelectedFSE,
  getSelectedOffice,
  getUserAgentType,
  getUserDistributorGroup,
  isLoggedIn,
} from 'identity/selectors'

import {
  getAllowedAgentTypes,
  getIsAllowRemoteSelling,
  getValidatedEnteringESubRules,
  isBlockedEnteringESub,
  isProcessing,
  isNeedToCompleteFNA,
  isSelectedRemoteSelling,
} from 'quick-quote/selectors'
import { isMRTAGroup, isGLTSPGroup } from 'quick-quote/product-mrta/coverage-plan/selectors'

import { getSelectedBasicCategory, getSelectedDisplayBasicPlanCode } from 'quick-quote/product-selection/selectors'
import { getEntryComponent } from 'quick-quote/entry-component/selectors'
import { isCompletedNeedGap } from 'quick-quote/fna/need-and-gap-analysis/selectors'
import { isCompletedMarketConducts } from 'quick-quote/fna/market-conducts/selectors'

import { setNotificationMessage } from 'quick-quote/notification/actions'
import {
  loginRequest,
  setProcessingApp,
  setProcessingSuccess,
  startStandardSelling,
  unsetProcessing,
} from 'quick-quote/actions'
import { selectFSE, updateOffice } from 'identity/actions'
import { FSEBranchModal } from 'identity/fse-branch/components'

import { get } from 'lodash/fp'
import MESSAGES from 'core/data-model/constants/messages'
import type { State } from '../../../../entry-component/reducer'
import _ from 'lodash'

type Props = {
  setProcessingApp: (*) => void,
  setProcessingSuccess: (*) => void,
  unsetProcessing: (*) => void,
  startStandardSelling: *,
  requestAdvisorStatus: *,
  loginRequest: *,
  user: *,
  isLoggedIn: boolean,
  history: *,
  toggleModal: (?Object) => void,
  isModalOpen: boolean,
  userDistributorGroup: DistributorGroupType,
  selectFSE: (AdvisorApiUserInfo) => void,
  updateOffice: (Office) => void,
  selectedFSE?: FSE,
  selectedOffice?: Office,
  setNotificationMessage: (*) => void,
  isBlockedEnteringESub: boolean,
  productCategory: string,
  isInfoOpen: boolean,
  isProcessing: boolean,
  toggleInfo: (boolean) => void,
  allowedAgentTypes: Array<AgentType>,
  isAllowRemoteSelling: boolean,
  entryComponent: State,
  validatedEnteringESubRules: string[],
  isNeedToCompleteFNA: boolean,
  isCompletedNeedGap: boolean,
  isCompletedMarketConducts: boolean,
  currentUserRole: string,
  selectedRemoteSelling: boolean,
}

export const _ESubmitButton = (props: Props) => {
  const createApplication = () => {
    props.startStandardSelling()
  }

  const agentType = get('profile.agent_type', props.user)
  const agentCode = get('profile.agent_code', props.user)

  const localClick = async () => {
    try {
      if (!props.isLoggedIn) return props.loginRequest()
      if (props.isNeedToCompleteFNA && !(isMRTAGroup(props.basicPlanCode) || isGLTSPGroup(props.basicPlanCode))) {
        props.setNotificationMessage({
          type: 'ErrorIncompleteFNA',
          incompleteNeedGap: !props.isCompletedNeedGap,
          incompleteMarketConducts: !props.isCompletedMarketConducts,
        })
        props.setProcessingSuccess()
        return
      }

      props.setProcessingApp()

      // Login is required before calling this request
      const advisor = await props.requestAdvisorStatus(props.user, agentCode, agentType)

      if (!isAllowAgent(advisor) || !isAllowAdvisor(advisor) || !isUserStartedJourneyFromCorrectApp(advisor)) {
        props.setProcessingSuccess()
        return
      }

      //Not good logic to validated rules here but requirement can't changed
      //Just suggestion : In future we should add logic at selector "isBlockedEnteringESub"
      //and disabled button and make getMessageErrors to show on third page (BI).
      //Now Third page (BI) just fixed message error. It should change to dynamic message by error that we get.
      if (props.validatedEnteringESubRules.length > 0) {
        showErrorEnteringMesasge(props.validatedEnteringESubRules[0])
        props.setProcessingSuccess()
        return
      }

      if (isFSE()) {
        props.setProcessingSuccess()
        return props.toggleModal()
      }

      if (getToggles().ENABLE_REMOTE_SELLING && props.isAllowRemoteSelling) {
        return getToggles().ENABLE_FNA_PHASE2
          ? props.setNotificationMessage({ type: 'RemoteSellingVc' })
          : props.setNotificationMessage({ type: 'RemoteSelling' })
      } else if (!props.isAllowRemoteSelling && getToggles().ENABLE_FNA_PHASE2) {
        return props.setNotificationMessage({ type: 'RemoteSellingVc' })
      } else {
        createApplication()
      }
    } catch (error) {
      console.warn(error)
      get('response.status', error) === 403
        ? props.setNotificationMessage({ type: 'AuthenticationError' })
        : showGenericError(error)

      props.setProcessingSuccess()
      return false
    }
  }

  const allowedDistributorTypes = props.allowedAgentTypes

  const isAllowAgent = (advisor) => {
    const advisorType = _.get(advisor, 'type.code')
    const isCorrectType = allowedDistributorTypes.includes(advisorType)
    if (!isCorrectType) {
      showNotAllowMessage()
      return false
    }
    return true
  }

  const isUserStartedJourneyFromCorrectApp = (advisor) => {
    const advisorType = _.get(advisor, 'type.code')
    if (!validateUserStartedJourney(advisorType, props.entryComponent)) {
      showOnlyAllowFromPaMesasge()
      return false
    }
    return true
  }

  const isAllowAdvisor = (advisor) => {
    if (!validateAdvisorPermission(advisor, allowedDistributorTypes)) {
      showNotAllowMessage()
      return false
    }
    return true
  }

  const showOnlyAllowFromPaMesasge = () => {
    props.setNotificationMessage({
      title: MESSAGES.ONLY_ALLOW_FROM_PA.TITLE,
      message: MESSAGES.ONLY_ALLOW_FROM_PA.DESCRIPTION,
      type: 'ErrorMessage',
    })
  }

  const showNotAllowMessage = () => {
    props.setNotificationMessage({
      title: MESSAGES.NOT_ALLOW_ESUBMISSION.TITLE,
      message: MESSAGES.NOT_ALLOW_ESUBMISSION.DESCRIPTION,
      type: 'ErrorMessage',
    })
  }

  const showErrorEnteringMesasge = (message: string) => {
    props.setNotificationMessage({
      title: MESSAGES.NOT_ALLOW_ESUBMISSION.TITLE,
      message: message,
      type: 'ErrorMessage',
    })
  }

  const showGenericError = (error) => {
    const notificationMessage = getToggles().ENABLE_RED_TRUCK_ERROR_MSG
      ? { type: 'GenericError', message: error.message }
      : { type: 'GenericError' }
    props.setNotificationMessage(notificationMessage)
  }

  const isFSE = () => get('userDistributorGroup', props) === 'BANC'
  const fseBranchModal = isFSE() ? (
    <FSEBranchModal
      isModalOpen={props.isModalOpen}
      toggleModal={() => {
        props.unsetProcessing()
        props.toggleModal()
      }}
      submitAction={() => props.toggleInfo(true)}
      selectFSE={props.selectFSE}
      updateOffice={props.updateOffice}
      selectedOffice={props.selectedOffice}
      selectedFSE={props.selectedFSE}
      user={props.user}
      setNotificationMessage={props.setNotificationMessage}
    />
  ) : null
  const informationBranchModal = (
    <InformationBranchModal
      isModalOpen={props.isInfoOpen}
      toggleModal={() => props.toggleInfo(false)}
      submitAction={() => {
        if (isMRTAGroup(props.basicPlanCode) || isGLTSPGroup(props.basicPlanCode)) {
          props.startStandardSelling()
        } else if (getToggles().ENABLE_FNA_PHASE2) {
          props.setNotificationMessage({ type: 'RemoteSellingVc' })
        } else {
          props.toggleModal()
          props.startStandardSelling()
        }
      }}
    />
  )

  const RealButton = (
    <Button
      className="e-submission"
      color={props.isBlockedEnteringESub ? 'disabled' : 'primary'}
      disabled={props.isBlockedEnteringESub || props.isProcessing}
      onClick={localClick}
    >
      {MESSAGES.ESUBMISSION}
      {fseBranchModal}
      {informationBranchModal}
    </Button>
  )

  /* istanbul ignore next */
  const isBanca = props.currentUserRole === values.AGENT_TYPE_LB
  if (isBanca) {
    // Not allow Banca to use web view to esubmission flow
    if (!IsRealDevice && getToggles().ENABLE_BANCA_BLOCK_ESUB_WEB_VIEW) return null

    // Not allow Banca to use except product in list to allow
    if (
      getToggles().ENABLE_ESUB_BTN_FOR_LB_WITH_PRODUCTLIST &&
      getAppConfig().ESUB_BTN_FOR_LB_WITH_PRODUCTLIST.includes(props.basicPlanCode)
    ) {
      return RealButton
    } else {
      return null
    }
  } else if (props.currentUserRole === values.AGENT_TYPE_FSE || props.currentUserRole === values.AGENT_TYPE_BANCA) {
    return null
  }

  return RealButton
}

const mapStateToProps = (state: AppState) => ({
  isLoggedIn: isLoggedIn(state),
  user: getIdentityUser(state),
  currentUserRole: getUserAgentType(state),
  userDistributorGroup: getUserDistributorGroup(state),
  selectedFSE: getSelectedFSE(state),
  selectedOffice: getSelectedOffice(state),
  isBlockedEnteringESub: isBlockedEnteringESub(state),
  isProcessing: isProcessing(state),
  productCategory: getSelectedBasicCategory(state),
  allowedAgentTypes: getAllowedAgentTypes(state),
  entryComponent: getEntryComponent(state),
  isAllowRemoteSelling: getIsAllowRemoteSelling(state),
  validatedEnteringESubRules: getValidatedEnteringESubRules(state),
  isNeedToCompleteFNA: isNeedToCompleteFNA(state),
  isCompletedNeedGap: isCompletedNeedGap(state),
  isCompletedMarketConducts: isCompletedMarketConducts(state),
  selectedRemoteSelling: isSelectedRemoteSelling(state),
  basicPlanCode: getSelectedDisplayBasicPlanCode(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  setProcessingApp: bindActionCreators(setProcessingApp, dispatch),
  setProcessingSuccess: bindActionCreators(setProcessingSuccess, dispatch),
  unsetProcessing: bindActionCreators(unsetProcessing, dispatch),
  loginRequest: bindActionCreators(loginRequest, dispatch),
  startStandardSelling: bindActionCreators(startStandardSelling, dispatch),
  requestAdvisorStatus: async (user, agentId, agentType) => {
    return await getAdvisor(user, agentId, agentType)
  },
  selectFSE: bindActionCreators(selectFSE, dispatch),
  updateOffice: bindActionCreators(updateOffice, dispatch),
  setNotificationMessage: bindActionCreators(setNotificationMessage, dispatch),
})

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  withModalToggle,
  withState('isInfoOpen', 'toggleInfo', false)
)(_ESubmitButton)
