//@flow

import MESSAGES from 'core/data-model/constants/bi-messages'
import VALUES from 'core/data-model/constants/values'

const TOP_MARGIN = -10

export const basicPlanConditionSection = () => ({
  stack: [
    {
      text: MESSAGES.CONDITION_TITLE,
      style: 'title',
      pageBreak: 'before',
    },
    {
      text: MESSAGES.NO_COVER_TITLE,
      style: 'sub-title',
    },
    {
      ol: [
        {
          text: MESSAGES.MRTA_NOT_COVER_MESSAGE[0],
        },
        {
          text: MESSAGES.MRTA_NOT_COVER_MESSAGE[1],
        },
      ],
      style: 'textRow',
      marginTop: TOP_MARGIN,
    },
    {
      text: MESSAGES.CLAIM_TITLE,
      style: 'sub-title',
    },
    {
      text: MESSAGES.CLAIM_MESSAGE,
      style: 'textRow',
      marginTop: TOP_MARGIN,
    },
    {
      text: MESSAGES.FREELOOK_TITLE,
      style: 'sub-title',
    },
    {
      text: MESSAGES.FREELOOK_MESSAGE,
      style: 'textRow',
      marginTop: TOP_MARGIN,
    },
    {
      text: MESSAGES.DELIVERY_PERIOD_TITLE,
      style: 'sub-title',
    },
    {
      text: MESSAGES.DELIVERY_PERIOD_MESSAGE,
      style: 'textRow',
      marginTop: TOP_MARGIN,
    },
    {
      text: MESSAGES.CHANNEL_TITLE,
      style: 'sub-title',
    },
    {
      text: MESSAGES.CHANNEL_MESSAGE,
      style: 'textRow',
      marginTop: TOP_MARGIN,
    },
  ],
  style: 'groupRow',
})

export const basicPlanGeneralExceptionSection = ({ basicPlan }) => ({
  stack: [
    {
      text: MESSAGES.GENERAL_EXCEPTION_TITLE,
      style: 'title',
      pageBreak: 'before',
    },
    {
      text: MESSAGES.GENERAL_EXCEPTION_CASE_PERMANENT_DISABILITY_SUB_TITLE,
      style: 'sub-title',
    },
    {
      text: MESSAGES.GENERAL_EXCEPTION_CASE_PERMANENT_DISABILITY_MESSAGE[0],
      style: 'textRow',
      marginTop: TOP_MARGIN,
    },
    {
      ol: [
        {
          text: MESSAGES.GENERAL_EXCEPTION_CASE_PERMANENT_DISABILITY_MESSAGE[1],
        },
        {
          text: MESSAGES.GENERAL_EXCEPTION_CASE_PERMANENT_DISABILITY_MESSAGE[2],
        },
        {
          text: MESSAGES.GENERAL_EXCEPTION_CASE_PERMANENT_DISABILITY_MESSAGE[3],
        },
        {
          text: MESSAGES.GENERAL_EXCEPTION_CASE_PERMANENT_DISABILITY_MESSAGE[4],
        },
        {
          text: MESSAGES.GENERAL_EXCEPTION_CASE_PERMANENT_DISABILITY_MESSAGE[5],
        },
      ],
      style: 'textRow',
      marginTop: -5,
    },
    [VALUES.GLTSP_CODE.GLTSP, VALUES.MRTA_CODE.MRTA_FULL].includes(basicPlan.basicPlanCode)
      ? {}
      : {
          text: MESSAGES.GENERAL_EXCEPTION_CASE_RIDER_ACCIDENT_SUB_TITLE,
          style: 'sub-title',
        },
    [VALUES.GLTSP_CODE.GLTSP, VALUES.MRTA_CODE.MRTA_FULL].includes(basicPlan.basicPlanCode)
      ? {}
      : {
          text: MESSAGES.GENERAL_EXCEPTION_CASE_RIDER_ACCIDENT_MESSAGE[0],
          style: 'textRow',
          marginTop: TOP_MARGIN,
        },
    [VALUES.GLTSP_CODE.GLTSP, VALUES.MRTA_CODE.MRTA_FULL].includes(basicPlan.basicPlanCode)
      ? {}
      : {
          ol: [
            {
              text: MESSAGES.GENERAL_EXCEPTION_CASE_RIDER_ACCIDENT_MESSAGE[1],
            },
            {
              text: MESSAGES.GENERAL_EXCEPTION_CASE_RIDER_ACCIDENT_MESSAGE[2],
            },
            {
              text: MESSAGES.GENERAL_EXCEPTION_CASE_RIDER_ACCIDENT_MESSAGE[3],
            },
            {
              text: MESSAGES.GENERAL_EXCEPTION_CASE_RIDER_ACCIDENT_MESSAGE[4],
            },
            {
              text: MESSAGES.GENERAL_EXCEPTION_CASE_RIDER_ACCIDENT_MESSAGE[5],
            },
          ],
          style: 'textRow',
          marginTop: -5,
        },
  ],
  style: 'groupRow',
})
