//@flow
import type { ConfirmEKYCNotification } from '../notification/actions'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { Modal } from '../../common-components'
import { confirmEKYC, cancelEKYC } from 'quick-quote/actions'

type ConfirmEKYCProps = {
  notificationMessage: ConfirmEKYCNotification,
  onExit: Function,
  cancelEKYC: () => void,
  confirmEKYC: () => void,
  history: *,
}

const Warning = styled.p`
  color: red;
  margin-bottom: 0;
`

const CenteredHeader = styled.div`
  .modal-header {
    position: relative;
  }
  button {
    position: absolute;
    right: 15px;
    top: 4px;
  }
`

export const _ConfirmEKYCPopup = ({
  notificationMessage,
  onExit,
  history,
  cancelEKYC,
  confirmEKYC,
  ...props
}: ConfirmEKYCProps) => (
  <Modal
    isOpen={notificationMessage !== 'None'}
    backdrop={true}
    size="lg"
    modalClassName="default-bootstrap remote-selling-modal text-center"
    toggle={() => {
      cancelEKYC()
      onExit()
    }}
  >
    <CenteredHeader>
      <ModalHeader
        toggle={() => {
          cancelEKYC()
          onExit()
        }}
        style={{
          justifyContent: 'center',
        }}
      >
        กรุณายืนยันเพื่อเข้าสู่ขั้นตอนการยืนยันตัวตน
      </ModalHeader>
    </CenteredHeader>
    <ModalBody>
      <p>ท่านได้ตรวจสอบความถูกต้องของข้อมูลแล้วและต้องการไปยังหน้ายืนยันตัวตน ใช่หรือไม่?</p>
      <Warning>หมายเหตุ : เมื่อคลิกยืนยันแล้วท่านจะไม่สามารถแก้ไขข้อมูลได้อีก</Warning>
    </ModalBody>
    <ModalFooter style={{ justifyContent: 'center' }}>
      <div className="bottom" style={{ justifyContent: 'center' }}>
        <Button
          color="outline-primary"
          onClick={() => {
            cancelEKYC()
            onExit()
          }}
        >
          ย้อนกลับ
        </Button>
        <Button
          color="primary"
          onClick={() => {
            confirmEKYC()
            onExit()
          }}
        >
          ยืนยัน
        </Button>
      </div>
    </ModalFooter>
  </Modal>
)

const mapDispatchToConfirmEKYCPopupProps = (dispatch: Dispatch<*>) => ({
  cancelEKYC: bindActionCreators(cancelEKYC, dispatch),
  confirmEKYC: bindActionCreators(confirmEKYC, dispatch),
})

export default withRouter(connect(null, mapDispatchToConfirmEKYCPopupProps)(_ConfirmEKYCPopup))
