import { creditCardSection, netbankSection } from '../../payment/index_old'
import { every, flow, get, last, map, overEvery, some, toFinite, nth } from 'lodash/fp'
import {
  canCreateReceipt,
  getPolicyId,
  getQuickQuotePaymentModelLabel,
  getQuickQuoteProductName,
  getQuickQuoteTotalPremium,
  getReceiptAmount,
  getSelectedModelFactorLabel,
  getSelectedReceiptType,
  hasReceiptPaid,
  hasReceiptSigned,
  isFinishCreditCardPaymentSection,
} from '../selectors'
import { createField, createSection } from '../utils'
import { isSectionValid } from '../../../../apps/selectors'
import { setAppValue, validateApplication } from '../../../../apps/actions'
import { getPaymentSections } from './selectors'
import { getToggles } from 'quick-quote/feature-toggles'

export default (state, app, { history, dispatch } = {}) => {
  const isReceiptValid = (app, state) =>
    overEvery([
      canCreateReceipt,
      flow(
        getPaymentSections,
        map('paymentSteps'),
        map((steps) => createSection([steps[0]])),
        every((section) => isSectionValid(state, [section])(app))
      ),
    ])(app, state)

  let isPaid = flow(
    getPaymentSections,
    map('paymentSteps'),
    map((steps) => last(steps)),
    some(({ state }) => state === 'done')
  )

  if (getToggles().ENABLE_CHANGE_PAY_IN_ATTACHMENT_STEP && getSelectedReceiptType(app) === 'net bank') {
    isPaid = overEvery([
      flow(
        getPaymentSections,
        map('paymentSteps'),
        map((steps) => nth(-2, steps)),
        some(({ state }) => state === 'done')
      ),
      flow(
        getPaymentSections,
        map('paymentSteps'),
        map((steps) => last(steps)),
        some(({ state }) => state === 'done')
      ),
    ])
  }

  let checked = isPaid(app, state)

  const cybsRetryUntilCancel = get('cybsRetryUntilCancel', app)
  const handleSection = (app, state) => {
    const sections = [netbankSection(app, state, true, history), creditCardSection(app, state, true, history)]
    return sections.filter((section) => section)
  }

  return {
    checked,
    icon: isPaid(app, state) ? 'check' : '',
    cybsRetryUntilCancel: cybsRetryUntilCancel,
    disabled: !hasReceiptSigned(app) == true,
    sections: [
      createSection([
        createField(
          'payment',
          [
            {
              id: 'premiumSummaryTable',
              c: 'PremiumSummaryTable',
              p: {
                mergeState: (state, app) => {
                  return {
                    amount: getQuickQuoteTotalPremium(app),
                    policyId: getPolicyId(app),
                    product: getQuickQuoteProductName(app),
                    selectedModelFactorLabel: getSelectedModelFactorLabel(app) || getQuickQuotePaymentModelLabel(app),
                    lastModified: get('updatedAt', app),
                    isPaid: hasReceiptPaid(app),
                    isFinishCreditCardPaymentSection: isFinishCreditCardPaymentSection(app),
                    name: 'bank-slip',
                  }
                },
              },
            },
            {
              id: 'payment',
              c: 'RemotePayment',
              p: {
                mergeState: (state, app, { dispatch } = {}) => {
                  return {
                    disabled: !isPaid(app, state),
                    title: 'กรุณาเลือกช่องทางที่ท่านต้องการชำระเบี้ย',
                    isValid: isReceiptValid(app, state),
                    sections: handleSection(app, state),
                    onSelect: ({ id, paymentType }) => {
                      const receipt = get(id, app)
                      const receiptAmount = getReceiptAmount(receipt)
                      if (toFinite(receiptAmount) === 0 && !isPaid(app, state)) {
                        dispatch(setAppValue(`${id}.type`, paymentType))
                        dispatch(validateApplication())
                      }
                    },
                  }
                },
              },
            },
          ],
          {
            pl: [0, 0, 0],
            pr: [0, 0, 0],
          }
        ),
      ]),
    ],
  }
}
