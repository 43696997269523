// @flow
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'
import { Input } from 'reactstrap'

import { getPercentSumAssured } from 'quick-quote/product-mrta/coverage-plan/selectors'

type PercentSumAssuredProps = {
  isReadOnly: boolean,
  percentSumAssured: number,
  enabled: boolean,
}

const PercentSumAssured = ({ isReadOnly, percentSumAssured, enabled = true }: PercentSumAssuredProps) => {
  if (enabled) {
    return (
      <NumberFormat
        id="percent-sum-assured-input"
        disabled={Boolean(isReadOnly)}
        isAllowed={({ formattedValue }) => formattedValue.length <= 17}
        customInput={Input}
        value={percentSumAssured}
        // thousandSeparator={true}
        allowNegative={false}
        type="tel"
        pattern="[0-9]*"
        onFocus={(e) => e.target.setSelectionRange(0, -1)}
        // onChange={(e, values) => editSumAssured(Number(values.value))}
      />
    )
  } else {
    return <Input id="percent-sum-assured-input" value="-" disabled={true} />
  }
}

const mapStateToProps = (state: AppState) => ({
  percentSumAssured: getPercentSumAssured(state),
})

export default connect(mapStateToProps, {})(PercentSumAssured)
