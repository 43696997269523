// @flow

// TODO: Remove duplication to whole-life
import type { BenefitSummary, PolicyValue, TaxDeduction } from 'core/service/benefit-illustration'
import type { RiderBenefitState } from 'core/service/rider/benefits'

export type Action = UpdateRiderBenefit | AddRiderBenefit | RemoveRiderBenefit | UpdatePolicyValue

export const ADD_RIDER_BENEFIT = 'ADD_RIDER_BENEFIT'
type AddRiderBenefit = {
  type: 'ADD_RIDER_BENEFIT',
  payload: { riderBenefitKey: string, riderBenefitData: RiderBenefitState },
}

export const UPDATE_RIDER_BENEFIT = 'UPDATE_RIDER_BENEFIT'
type UpdateRiderBenefit = {
  type: 'UPDATE_RIDER_BENEFIT',
  payload: { riderBenefitKey: string, riderBenefitData: RiderBenefitState },
}

export const REMOVE_RIDER_BENEFIT = 'REMOVE_RIDER_BENEFIT'
type RemoveRiderBenefit = {
  type: 'REMOVE_RIDER_BENEFIT',
  payload: { riderBenefitKey: string },
}

export const UPDATE_POLICY_VALUE = 'UPDATE_POLICY_VALUE'
type UpdatePolicyValue = {
  type: 'UPDATE_POLICY_VALUE',
  payload: PolicyValue,
}

export const UPDATE_BENEFIT_SUMMARY = 'UPDATE_BENEFIT_SUMMARY'
type UpdateBenefitSummary = {
  type: 'UPDATE_BENEFIT_SUMMARY',
  payload: BenefitSummary,
}

export const UPDATE_TAX_DEDUCTION = 'UPDATE_TAX_DEDUCTION'
type UpdateTaxDeduction = {
  type: 'UPDATE_TAX_DEDUCTION',
  payload: TaxDeduction,
}

export const addRiderBenefit = (riderBenefitKey: string, riderBenefitData: RiderBenefitState): AddRiderBenefit => ({
  type: ADD_RIDER_BENEFIT,
  payload: { riderBenefitKey, riderBenefitData },
})

export const updateRiderBenefit = (
  riderBenefitKey: string,
  riderBenefitData: RiderBenefitState
): UpdateRiderBenefit => ({ type: UPDATE_RIDER_BENEFIT, payload: { riderBenefitKey, riderBenefitData } })

export const removeRiderBenefit = (riderBenefitKey: string): RemoveRiderBenefit => ({
  type: REMOVE_RIDER_BENEFIT,
  payload: { riderBenefitKey },
})

export const updatePolicyValue = (policyValue: PolicyValue): UpdatePolicyValue => ({
  type: UPDATE_POLICY_VALUE,
  payload: policyValue,
})

export const updateBenefitSummary = (benefitSummary: BenefitSummary): UpdateBenefitSummary => ({
  type: UPDATE_BENEFIT_SUMMARY,
  payload: benefitSummary,
})

export const updateTaxDeduction = (taxDeduction: TaxDeduction): UpdateTaxDeduction => ({
  type: UPDATE_TAX_DEDUCTION,
  payload: taxDeduction,
})
