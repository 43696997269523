// @flow

import type { Rider, RiderState } from 'core/data-model/rider'
import type { Benefit } from 'core/data-model/rider/benefits'

import RiderBenefitSummary from './rider-benefit-summary'
import RiderWithBenefitModal from './rider-benefit-modal'
import MESSAGES from 'core/data-model/constants/bi-messages'
import { AgentProtectionDisclaimer } from './agent-protection-disclaimer'
import { AdditionalDisclaimer } from './additional-disclaimer'
import { getRiderPlanCode as getMEXRiderPlanCode } from 'core/service/rider/rider-mex'
import type { Age } from 'core/data-model/insured/types'
import styled from 'styled-components'
import Mustache from 'mustache'
import { WordWraps } from 'common-components'

const BoldStyled = styled.span`
  font-weight: bold;
  margin-right: 10px;
`
const CenterStyled = styled.div`
  text-align: center;
`
const NewlineStyled = styled.span`
  padding-left: 20px;
`

type MEXRiderBenefitProps = {
  rider: Rider & RiderState,
  riderBenefitData: Benefit,
  insureAge: Age,
}

export const findDataOnListByRiderCode = (contents, planCode) => {
  return contents.find((content) => content.planCode === planCode)
    ? contents.find((content) => content.planCode === planCode).value
    : ''
}
const RenderMEXRiderBenefitsHeadTitle = (props) => {
  const { headTitle } = props
  return <h3>{headTitle}</h3>
}

const RenderMEXRiderEndorseTable = (props) => {
  const { endorse, planCode } = props
  const { title, contents, remark, remarkTotalBenefit } = endorse
  return (
    <div className="mex-details-table">
      <h3>{title}</h3>
      <table id="mex-benefit-table">
        <thead>
          <tr>
            <td>{MESSAGES.BENEFIT_TITLE}</td>
            <td>{MESSAGES.BENEFIT_BAHT}</td>
            <td>{MESSAGES.RIDER_BENEFIT_DAY_OR_TIME}</td>
            <td>{MESSAGES.RIDER_TOTAL_BENEFIT_BAHT}</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <BoldStyled>{contents[0].text[0]}</BoldStyled>
              {contents[0].text[1]}
            </td>
            <td>
              <CenterStyled>
                {findDataOnListByRiderCode(contents[0].benefit, planCode)} {contents[0].benefitDesc}
              </CenterStyled>
            </td>
            <td>
              <CenterStyled>
                <WordWraps>{contents[0].totalTimes[0].replace(/\n/g, '')}</WordWraps>
              </CenterStyled>
            </td>
            <td>
              <CenterStyled>
                <WordWraps>
                  {findDataOnListByRiderCode(contents[0].totalBenefit, planCode)} {contents[0].totalBenefitDesc}
                </WordWraps>
              </CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[1].text[0]}</BoldStyled>
              {contents[1].text[1]}
            </td>
            <td>
              <CenterStyled>{contents[1].benefit[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[1].totalTimes[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[1].totalBenefit[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[2].text[0]}</BoldStyled>
              <WordWraps>
                {contents[2].text[1]}
                {findDataOnListByRiderCode(contents[2].text[2], planCode)}
              </WordWraps>
            </td>
            <td>
              <CenterStyled>{contents[2].benefit[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[2].totalTimes[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[2].totalBenefit[0]}</CenterStyled>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="disclaimer-box">
        {planCode.endsWith('J') ? (
          <div>
            {remark[0]}
            <p></p>
          </div>
        ) : (
          ''
        )}

        <div>
          <BoldStyled>{remark[1]}</BoldStyled>
        </div>
        <div>&emsp;{remark[2]}</div>
        <p></p>
        <div>
          <BoldStyled>{remark[3]}</BoldStyled>
        </div>
        <div>&emsp;{remark[4]}</div>
        <p>
          {Mustache.render(remark[5], {
            remarkTotalBenefit: findDataOnListByRiderCode(remarkTotalBenefit, planCode),
          })}
        </p>
      </div>
    </div>
  )
}

const RenderMEXRiderBenefitsTable = (props) => {
  const { benefits, planCode } = props
  const { title, contents, remark } = benefits
  return (
    <div className="mex-details-table">
      <h3>{title}</h3>
      <table id="mex-benefit-table">
        <thead>
          <tr>
            <td>{MESSAGES.BENEFIT_TITLE}</td>
            <td>{MESSAGES.BENEFIT_BAHT}</td>
            <td>{MESSAGES.RIDER_BENEFIT_DAY_OR_TIME}</td>
            <td>{MESSAGES.RIDER_TOTAL_BENEFIT_BAHT}</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={4}>
              <BoldStyled>{contents[0].text[0]}</BoldStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[1].text[0]}</BoldStyled>
              {contents[1].text[1]}
              <div>
                <NewlineStyled>{contents[1].text[2]}</NewlineStyled>
              </div>
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[1].benefit, planCode)}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[1].totalTimes[0].replace(/\n/g, '')}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[1].totalBenefit, planCode)}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <BoldStyled>{contents[2].text[0]}</BoldStyled>
              {contents[2].text[1].replace(/\n/g, '')}
            </td>
            <td></td>
          </tr>
          <tr>
            <td>{contents[3].text[0]}</td>
            <td rowSpan={3}>
              <CenterStyled>{contents[3].benefit[0]}</CenterStyled>
            </td>
            <td rowSpan={3}>
              <CenterStyled>{contents[3].totalTimes[0].replace(/\n/g, '')}</CenterStyled>
            </td>
            <td rowSpan={4}>
              <CenterStyled>
                {findDataOnListByRiderCode(contents[3].totalBenefit, planCode)}{' '}
                {contents[3].totalBenefitDesc.replace(/\n/g, '')}
              </CenterStyled>
            </td>
          </tr>
          <tr>
            <td>{contents[3].text[1]}</td>
          </tr>
          <tr>
            <td>{contents[3].text[2]}</td>
          </tr>
          <tr>
            <td>{contents[3].text[3]}</td>
            <td>
              <CenterStyled>{contents[3].benefit[3].replace(/\n/g, '')}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[3].totalTimes[3].replace(/\n/g, '')}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[4].text[0]}</BoldStyled>
              {contents[4].text[1]}
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[4].benefit, planCode)}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[4].totalTimes[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[4].totalBenefit, planCode)}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <BoldStyled>{contents[5].text[0]}</BoldStyled>
              {contents[5].text[1]}
            </td>
            <td></td>
          </tr>
          <tr>
            <td>{contents[6].text[0]}</td>
            <td>
              <CenterStyled>{contents[6].benefit[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[6].totalTimes[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[6].totalBenefit[0], planCode)}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>{contents[6].text[1].replace(/\n/g, '')}</td>

            <td>
              <CenterStyled>{contents[6].benefit[1][0]}</CenterStyled>
              <CenterStyled>{contents[6].benefit[1][1]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[6].totalTimes[1]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[6].totalBenefit[1]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>{contents[6].text[2].replace(/\n/g, '')}</td>
            <td>
              <CenterStyled>{contents[6].benefit[2]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[6].totalTimes[2]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[6].totalBenefit[2], planCode)}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>{contents[6].text[3]}</td>
            <td>
              <CenterStyled>{contents[6].benefit[3]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[6].totalTimes[3]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[6].totalBenefit[3], planCode)}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>{contents[6].text[4]}</td>
            <td>
              <CenterStyled>{contents[6].benefit[4]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[6].totalTimes[4]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[6].totalBenefit[4]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[7].text[0]}</BoldStyled>
              {contents[7].text[1]}
            </td>
            <td>
              <CenterStyled>{contents[7].benefit[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[7].totalTimes[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[7].totalBenefit[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td colSpan={4}>
              <BoldStyled>{contents[8].text[0]}</BoldStyled>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <BoldStyled>{contents[9].text[0]}</BoldStyled>
              {contents[9].text[1].replace(/\n/g, '')}
            </td>
            <td></td>
          </tr>
          <tr>
            <td>{contents[10].text[0][0].replace(/\n/g, '')}</td>
            <td>
              <CenterStyled>{contents[10].benefit[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[10].totalTimes[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[10].totalBenefit[0], planCode)}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>{contents[10].text[1][0].replace(/\n/g, '')}</td>

            <td>
              <CenterStyled>{contents[10].benefit[1]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[10].totalTimes[1]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[10].totalBenefit[1]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[11].text[0]}</BoldStyled>
              {contents[11].text[1]}
            </td>
            <td>
              <CenterStyled>{contents[11].benefit[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[11].totalTimes[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[11].totalBenefit, planCode)}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[12].text[0]}</BoldStyled>
              {contents[12].text[1].replace(/\n/g, '')}
            </td>
            <td>
              <CenterStyled>{contents[12].benefit[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[12].totalTimes[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[12].totalBenefit[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[13].text[0]}</BoldStyled>
              {contents[13].text[1]}
            </td>
            <td>
              <CenterStyled>{contents[13].benefit[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[13].totalTimes[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[13].totalBenefit[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[14].text[0]}</BoldStyled>
              {contents[14].text[1].replace(/\n/g, '')}
            </td>
            <td>
              <CenterStyled>{contents[14].benefit[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[14].totalTimes[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[14].totalBenefit[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[15].text[0]}</BoldStyled>
              {contents[15].text[1]}
            </td>
            <td>
              <CenterStyled>{contents[15].benefit[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[15].totalTimes[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[15].totalBenefit[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[16].text[0]}</BoldStyled>
              {contents[16].text[1].replace(/\n/g, '')}
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[16].benefit, planCode)}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[16].totalTimes[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>
                {findDataOnListByRiderCode(contents[16].totalBenefit, planCode)}{' '}
                {contents[16].totalBenefitDesc.replace(/\n/g, '')}
              </CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[17].text[0]}</BoldStyled>
              {contents[17].text[1]}
            </td>
            <td>
              <CenterStyled>{contents[17].benefit[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[17].totalTimes[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>
                {findDataOnListByRiderCode(contents[17].totalBenefit, planCode)} {contents[17].totalBenefitDesc}
              </CenterStyled>
            </td>
          </tr>
          <tr>
            <td colSpan={4}>
              <BoldStyled>{contents[18].text[0]}</BoldStyled>
            </td>
          </tr>
          <tr>
            <td>{contents[19].text[0]}</td>
            <td colSpan={3}>
              <CenterStyled>
                {findDataOnListByRiderCode(contents[19].totalBenefit, planCode).replace(/\n/g, '')}
              </CenterStyled>
            </td>
          </tr>
          <tr>
            <td>{contents[20].text[0]}</td>
            <td colSpan={3}>
              <CenterStyled>{contents[20].totalBenefit[0]}</CenterStyled>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="disclaimer-box">{planCode.endsWith('J') ? remark[0] : ''}</div>
    </div>
  )
}

const MEXRiderBenefit = (props: MEXRiderBenefitProps) => {
  const { rider, riderBenefitData, insureAge } = props
  const title = `${rider.description} ${rider.name}`
  const planCode = getMEXRiderPlanCode(rider, insureAge)
  const { benefits, endorse, headTitle, headSubTitle } = riderBenefitData

  const benefitInfo = (
    <div>
      <RenderMEXRiderBenefitsHeadTitle headTitle={headTitle} />
      <RenderMEXRiderBenefitsHeadTitle headTitle={headSubTitle} />
      <RenderMEXRiderBenefitsTable benefits={benefits} planCode={planCode} />
      <br />
      <RenderMEXRiderEndorseTable endorse={endorse} planCode={planCode} />
    </div>
  )
  return (
    <RiderWithBenefitModal
      id="mex-rider-benefit"
      className="rider mexmea-plus-rider-rider"
      title={title}
      modalChildren={benefitInfo}
    >
      <RiderBenefitSummary rider={rider} />
      {benefitInfo}
      <AgentProtectionDisclaimer rider={rider} />
      <AdditionalDisclaimer rider={rider} />
    </RiderWithBenefitModal>
  )
}

export default MEXRiderBenefit
