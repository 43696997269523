// @flow

import { isNil } from 'core/service/lib/type-check'
import type { DisplayProduct } from 'core/service/display-product'
import type { Rider, RiderState } from 'core/data-model/rider'
import SummaryRow from 'quick-quote/product-saving/benefit-illustration/components/profile-summary/summary-row'
import SummaryFooter from 'quick-quote/product-common/benefit-illustration/components/profile-summary/summary-footer'
import MESSAGES from 'core/data-model/constants/messages'
import BIMESSAGES from 'core/data-model/constants/bi-messages'
import values from 'core/data-model/constants/values'
import _ from 'lodash'
import {
  getRiderYearsOfCoverage,
  getRiderYearsOfPayment,
  hasAsteriskFromRiders,
} from 'core/service/rider/benefits/benefit-data'

type SummaryTableProps = {
  displayProduct: ?DisplayProduct,
  riders: (Rider & RiderState)[],
  totalPremium: number,
  calculatedCoveragePeriod: number,
  calculatedPaymentPeriod: number,
  basicPremium: number,
  basicSumAssured: number,
  hasNonLevelRider: boolean,
  summaryTableHeader: Function | string,
}

const SummaryTable = ({
  displayProduct,
  riders,
  calculatedCoveragePeriod,
  calculatedPaymentPeriod,
  basicPremium,
  basicSumAssured,
  totalPremium,
  hasNonLevelRider,
  summaryTableHeader,
}: SummaryTableProps) => {
  if (isNil(displayProduct)) {
    return
  }

  return (
    <div className="summary-table d-none d-md-block">
      <div className="details-table">
        <h3>{BIMESSAGES.SUMMARY_OF_MAIN_AND_ADDITIONAL_CONTRACTS}</h3>
        <table id="summary-table">
          <thead>
            <tr>
              <td>
                {BIMESSAGES.BASIC_PLAN}/{BIMESSAGES.RIDER}
              </td>
              <td>
                {BIMESSAGES.COVERAGE_PERIOD} ({MESSAGES.TIME_UNIT_YEAR})
              </td>
              <td>
                {BIMESSAGES.PAYMENT_PERIOD} ({MESSAGES.TIME_UNIT_YEAR})
              </td>
              <td>
                {BIMESSAGES.SUM_ASSURED} ({MESSAGES.BAHT})
              </td>
              <td>
                {BIMESSAGES.PREMIUM} ({MESSAGES.BAHT})
              </td>
            </tr>
          </thead>
          <tbody>
            {renderBasicPlanSummaryRow(
              displayProduct,
              calculatedCoveragePeriod,
              calculatedPaymentPeriod,
              basicSumAssured,
              basicPremium,
              summaryTableHeader
            )}
            {renderRidersSummaryRows(riders)}
            <SummaryFooter totalPremium={totalPremium} />
          </tbody>
        </table>
        <div className="disclaimer">
          <ul className="list-unstyled">
            {hasAsteriskFromRiders(riders) ? <li>*{BIMESSAGES.DISCLAIMER_SAVING_LEGAL_SUMMARY}</li> : ''}
          </ul>
        </div>
      </div>
    </div>
  )
}

const renderBasicPlanSummaryRow = (
  displayProduct: DisplayProduct,
  calculatedCoveragePeriod: number,
  calculatedPaymentPeriod: number,
  basicSumAssured: number,
  basicPremium: number,
  summaryTableHeader: Function | string
) => {
  const prodCode = BIMESSAGES.BENEFIT_NO_RETURN_VALUE
  const productName = displayProduct.nameThai

  return (
    <SummaryRow
      id={displayProduct.code}
      productCode={prodCode}
      productName={
        // $$FlowFixMe
        productName
      }
      selectedPlan={{ planCode: '' }} // not applicable for basic plan
      coverageYear={calculatedCoveragePeriod}
      paymentPeriod={calculatedPaymentPeriod}
      premium={basicPremium}
      sumAssured={basicSumAssured}
      showSumAssuredDecimalPlaces={false}
    />
  )
}

const renderRiderProductNameSummaryRows = (rider: Rider & RiderState) => {
  if (_.get(rider, 'code') === values.AMG || _.get(rider, 'code') === values.MESG) {
    return `${rider.description}`
  }
  return `${BIMESSAGES.RIDER}${rider.description}`
}

const renderRidersSummaryRows = (riders: (Rider & RiderState)[]) => {
  if (isNil(riders)) return

  return riders.map((rider) => {
    return (
      // $$FlowFixMe
      <SummaryRow
        key={`summary-row-${rider.code}`}
        id={rider.code}
        productCode={rider.package ? rider.selectedPlan.planCode : rider.name}
        productName={renderRiderProductNameSummaryRows(rider)}
        asterisks={rider.premiumStructure === values.PREMIUM_NON_LEVEL ? '***' : ''}
        coverageYear={getRiderYearsOfCoverage(rider)}
        paymentPeriod={getRiderYearsOfPayment(rider, { hasAsterisk: true })}
        selectedPlan={{
          ...rider.selectedPlan,
          planCode: rider.package ? '' : rider.selectedPlan.planCode,
        }}
        sumAssured={rider.showPlanAsSumAssured ? rider.selectedPlan.plan : rider.sumAssured}
        showSumAssuredDecimalPlaces={rider.showSumAssuredDecimalPlaces}
        premium={rider.premium}
      />
    )
  })
}

export default SummaryTable
