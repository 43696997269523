//@flow
import type { AuthenticationErrorNotification } from 'quick-quote/notification/actions'
import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { withProps, compose } from 'recompose'
import { bindActionCreators } from 'redux'
import { Button } from 'reactstrap'

import { getToggles } from 'quick-quote/feature-toggles'
import { logoutRequest } from 'quick-quote/actions'
import { getIcon } from 'e-submission/components/Icon'

import { FullScreenPopup } from './global-popup'

type AuthenticationErrorProps = {
  onExit: Function,
  logoutRequest: Function,
  onClose: Function,
  notificationMessage: AuthenticationErrorNotification,
}
const Heading = styled.h4`
  font-weight: bold;
`
export const _AuthenticationError = ({ notificationMessage, onClose }: AuthenticationErrorProps) => {
  return (
    <FullScreenPopup
      onExit={onClose}
      title="กรุณาออกระบบ และกลับเข้าสู่ระบบใหม่อีกครั้ง"
      isOpen={notificationMessage.type !== 'None'}
    >
      {getIcon('keys')}
      <div className="description">
        {!getToggles().ENABLE_NEW_SESSION_ERROR_MESSAGE && <div data-testid="auth-error-msg">ยืนยันตัวตนผิดพลาด</div>}
        {!getToggles().ENABLE_NEW_SESSION_ERROR_MESSAGE && (
          <div className="highlight">กรุณากดปุ่มออกจากระบบ แล้วเข้าสู่ระบบเพื่อใช้งานอีกครั้ง</div>
        )}

        {getToggles().ENABLE_NEW_SESSION_ERROR_MESSAGE && (
          <Heading data-testid="auth-error-msg">Session Timeout!</Heading>
        )}
        {getToggles().ENABLE_NEW_SESSION_ERROR_MESSAGE && (
          <div className="highlight">กรุณากดปุ่มออกจากระบบ แล้วเข้าสู่ระบบเพื่อใช้งานอีกครั้ง</div>
        )}
      </div>
      <Button color="primary" onClick={onClose} data-testid="auth-error-btn-close">
        {!getToggles().ENABLE_NEW_SESSION_ERROR_MESSAGE && 'ปิด'}
        {getToggles().ENABLE_NEW_SESSION_ERROR_MESSAGE && 'ปิดหน้านี้'}
      </Button>
    </FullScreenPopup>
  )
}

const mapDispatchToProps = (dispatch) => ({
  logoutRequest: bindActionCreators(logoutRequest, dispatch),
})
const enhance = compose(
  connect(null, mapDispatchToProps),
  withProps((props) => ({
    onClose: () => {
      getToggles().ENABLE_AUTO_LOGOUT_ON_AUTH_ERROR_MODAL && props.logoutRequest()
      props.onExit()
    },
  }))
)
export default enhance(_AuthenticationError)
