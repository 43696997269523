// @flow

import { formatNumber } from 'core/service/lib/number-format'
import { getBasicPlanRuleByType } from 'core/data-model/basic-plan/repository'
import { asYears } from 'core/service/insured/age'
import MESSAGES from 'core/data-model/constants/validation-messages'
import Mustache from 'mustache'
import type { LoanAmountRule, LoanTermRule, SumAssuredRule, CoverageTermRule } from 'core/data-model/basic-plan/types'
import type { Age } from 'core/data-model/insured/types'

type Validated = { isValid: false, message: string } | { isValid: true }

export const _validateLoanAmount = (rule: LoanAmountRule, loanAmount: number): Validated => {
  const { minimumValue } = rule
  const isLoanAmountAtLeastEqualMinValue = loanAmount >= minimumValue

  return isLoanAmountAtLeastEqualMinValue
    ? { isValid: true }
    : {
        isValid: false,
        message: Mustache.render(MESSAGES.RULE_LOAN_AMOUNT, { minimumValue: formatNumber(minimumValue) }),
      }
}

export const validateLoanAmount = async (productCode: string, loanAmount: number): Promise<Validated> => {
  const rule = await getBasicPlanRuleByType(productCode, 'LoanAmountRule')
  return _validateLoanAmount(rule, loanAmount)
}

export const _validateLoanTerm = (rule: LoanTermRule, loanTerm: number, insuredAge: Age): Validated => {
  const { minLoanTerm, maxLoanTerm, maxInsuredAgeAtEndOfLoanTerm } = rule
  const isLoanTermLessThanMinValue = loanTerm < minLoanTerm
  const isLoanTermGreaterThanMaxValue = loanTerm > maxLoanTerm

  const insuredAgeAtEndOfLoanTerm = loanTerm + asYears(insuredAge)
  const isInsuredAgeAtEndOfLoanTermGreaterThanMaxValue = insuredAgeAtEndOfLoanTerm > maxInsuredAgeAtEndOfLoanTerm

  if (isLoanTermLessThanMinValue || isLoanTermGreaterThanMaxValue || isInsuredAgeAtEndOfLoanTermGreaterThanMaxValue) {
    return {
      isValid: false,
      message: Mustache.render(MESSAGES.RULE_LOAN_TERM, {
        minLoanTerm: formatNumber(minLoanTerm),
        maxLoanTerm: formatNumber(maxLoanTerm),
        maxInsuredAgeAtEndOfLoanTerm: formatNumber(maxInsuredAgeAtEndOfLoanTerm),
      }),
    }
  }

  return { isValid: true }
}

export const validateLoanTerm = (productCode: string, loanTerm: number, insuredAge: Age): Promise<Validated> =>
  getBasicPlanRuleByType(productCode, 'LoanTermRule').then((rule) => _validateLoanTerm(rule, loanTerm, insuredAge))

export const _validateSumAssured = (rule: SumAssuredRule, sumAssured: number, loanAmount: number): Validated => {
  const { minimumValue } = rule
  const isSumAssuredGreaterThanEqualMinValue = sumAssured >= minimumValue
  const isSumAssuredLessThanEqualToLoanAmount = sumAssured <= loanAmount

  if (isSumAssuredGreaterThanEqualMinValue && isSumAssuredLessThanEqualToLoanAmount) {
    return { isValid: true }
  }

  return {
    isValid: false,
    message: Mustache.render(MESSAGES.RULE_LOAN_SUM_ASSURED, { minimumValue: formatNumber(minimumValue) }),
  }
}

export const validateSumAssured = async (
  productCode: string,
  sumAssured: number,
  loanAmount: number
): Promise<Validated> => {
  const rule = await getBasicPlanRuleByType(productCode, 'SumAssuredRule')
  return _validateSumAssured(rule, sumAssured, loanAmount)
}

export const _validateCoverageTerm = (rule: CoverageTermRule, coverageTerm: number, loanTerm: number): Validated => {
  const { minCoverageTerm, maxCoverageTerm } = rule
  const isCoverageTermLessThanMinValue = Number(coverageTerm) < Number(minCoverageTerm)
  const isCoverageTermGreaterThanMaxValue = Number(coverageTerm) > Number(maxCoverageTerm)
  const isCoverageTermGreaterThanLoanTerm = Number(coverageTerm) > Number(loanTerm)
  if (isCoverageTermLessThanMinValue || isCoverageTermGreaterThanMaxValue || isCoverageTermGreaterThanLoanTerm) {
    return {
      isValid: false,
      message: Mustache.render(MESSAGES.RULE_COVERAGE_PERIOD_INSURED, {
        minCoverageTerm: formatNumber(minCoverageTerm),
      }),
    }
  }

  return { isValid: true }
}

export const validateCoverageTerm = (productCode: string, coverageTerm: number, loanTerm: number): Promise<Validated> =>
  getBasicPlanRuleByType(productCode, 'CoverageTermRule').then((rule) =>
    _validateCoverageTerm(rule, coverageTerm, loanTerm)
  )
