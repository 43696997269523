// @flow

import VALUES from 'core/data-model/constants/values'
import type { RppAcceptFundAllocation, RppForeignConsent, RppOverRiskConsent } from 'core/data-model/investment'
import type { FundAllocationToggleItem, LstuFundAllocationOption, TotalFundAllocation } from 'core/service/investment'
import {
  containForeignFund,
  containOverRiskFund,
  isAcceptForeignInvestment,
  fundsContainErrors,
} from 'core/service/investment/funds'
import _ from 'lodash'
import { hasFirstYearLstu } from 'quick-quote/product-investment/coverage-plan/selectors'
import { getRppFundAllocations } from 'quick-quote/product-investment/fund-allocation/rpp/selectors'
import { isSelectedYesOnForeignExchangeQuestion } from 'quick-quote/product-investment/risk-questionnaire/selectors'
import type { AppState } from 'quick-quote/reducers'
import { createSelector } from 'reselect'

export const getLstuFundAllocations = (state: AppState): FundAllocationToggleItem[] =>
  state.investment.funds.lstu.allocations
export const getTotalAllocations = (state: AppState): TotalFundAllocation => state.investment.funds.lstu.totalAllocation
export const getSelectedAllocationOption = (state: AppState): ?LstuFundAllocationOption =>
  state.investment.funds.lstu.selectedAllocationOption

export const getLstuFundSummary = createSelector(
  getSelectedAllocationOption,
  getLstuFundAllocations,
  getRppFundAllocations,
  (selectedOption, lstuAllocations, rppAllocations): FundAllocationToggleItem[] => {
    return selectedOption === VALUES.LSTU_FUND_ALLOCATION_OPTION.INDEPENDENT ? lstuAllocations : rppAllocations
  }
)

export const getLstuAcceptFundAllocation = (state: AppState): RppAcceptFundAllocation =>
  state.investment.funds.lstu.acceptFundAllocation

export const _hasFinishedFundAllocation = (selectedLstuOption, lstuAllocations, totalLstuAllocations): boolean => {
  switch (selectedLstuOption) {
    case VALUES.LSTU_FUND_ALLOCATION_OPTION.SAME_AS_RPP:
      return true
    case VALUES.LSTU_FUND_ALLOCATION_OPTION.INDEPENDENT:
      return lstuAllocations.length > 0 && totalLstuAllocations.value === 100 && !fundsContainErrors(lstuAllocations)
    default:
      return false
  }
}

export const hasFinishedLstuFundAllocation = createSelector(
  getSelectedAllocationOption,
  getLstuFundAllocations,
  getTotalAllocations,
  _hasFinishedFundAllocation
)

export const getLstuOverRiskConsent = (state: AppState): RppOverRiskConsent =>
  _.get(state, 'investment.funds.lstu.allowHigherRiskInvestment', false)

export const getLstuForeignConsent = (state: AppState): RppForeignConsent =>
  _.get(state, 'investment.funds.lstu.allowForeignInvestment', false)

export const _isLstuForeignConsentNeeded = (
  hasFirstYearLstu,
  isSelectedYesOnForeignExchangeQuestion,
  hasForeignFundAllocation
) => {
  return hasFirstYearLstu && !isSelectedYesOnForeignExchangeQuestion && hasForeignFundAllocation
}

export const hasOverRiskFundSummary = createSelector(getLstuFundSummary, containOverRiskFund)

export const hasForeignFundSummary = createSelector(getLstuFundSummary, containForeignFund)

export const isLstuForeignConsentNeeded = createSelector(
  hasFirstYearLstu,
  isSelectedYesOnForeignExchangeQuestion,
  hasForeignFundSummary,
  _isLstuForeignConsentNeeded
)

export const _isLstuOverRiskConsentNeeded = (hasFirstYearLstu, hasOverRiskFundSummary) =>
  hasFirstYearLstu && hasOverRiskFundSummary

export const isLstuOverRiskConsentNeeded = createSelector(
  hasFirstYearLstu,
  hasOverRiskFundSummary,
  _isLstuOverRiskConsentNeeded
)

export const _hasFinishedNecessaryConsents = (
  hasOverRiskFund,
  overRiskConsent,
  hasForiegnFund,
  acceptForeignInvestment
): boolean => {
  return (
    ((hasOverRiskFund && overRiskConsent) || !hasOverRiskFund) &&
    ((hasForiegnFund && acceptForeignInvestment) || !hasForiegnFund)
  )
}

export const lstuAcceptForeignInvestment = createSelector(
  getLstuForeignConsent,
  isSelectedYesOnForeignExchangeQuestion,
  isAcceptForeignInvestment
)

export const hasFinishedNecessaryConsents = createSelector(
  hasOverRiskFundSummary,
  getLstuOverRiskConsent,
  hasForeignFundSummary,
  lstuAcceptForeignInvestment,
  _hasFinishedNecessaryConsents
)

export const _hasFinishedFundSummary = (allocated100Percent, finishedNecessaryConsents): boolean =>
  allocated100Percent && finishedNecessaryConsents

export const hasFinishedLstuFundSummary = createSelector(
  hasFinishedLstuFundAllocation,
  hasFinishedNecessaryConsents,
  _hasFinishedFundSummary
)

export const _requireLstuFundAllocation = (hasFirstYearLstu): boolean => hasFirstYearLstu

export const requireLstuFundAllocation = createSelector(hasFirstYearLstu, _requireLstuFundAllocation)
