import { compact, flow, map } from 'lodash/fp'
import { appendAfter, concatWith, createPageCreator, prune } from 'e-submission/domain/data-model/form/utils'
import baseForm from 'e-submission/domain/data-model/form'
import { insuredHealth, payerHealth } from 'e-submission/domain/data-model/form/pages/health'
import { signature } from 'e-submission/domain/data-model/form/pages/signature'
import { submit } from 'e-submission/domain/data-model/form/pages/submit'
import { isSelectedRemoteSelling } from '../../form/selectors'
import { videoConsent } from '../../form/pages/videoConsent'
import { remotePayment } from '../../form/pages/remotePayment'
import { remoteSignature } from '../../form/pages/remoteSignature'
import { getToggles } from 'quick-quote/feature-toggles'
import { isJuvenilePurchasing } from '../../form/selectors'

export default (app) => {
  const createPage = createPageCreator(app)
  const selectedRemoteSelling = app && app.quickQuote ? isSelectedRemoteSelling(app) : false
  const isShowJuvenilePurchasingForm = getToggles().ENABLE_JUVENILE_PURCHASING_FORM && isJuvenilePurchasing(app)
  const afterPage = isShowJuvenilePurchasingForm ? 'juvenile-purchasing' : 'beneficiary'
  return flow(
    baseForm,
    map(createPage),
    concatWith(
      selectedRemoteSelling
        ? [
            createPage(signature(app)),
            createPage(videoConsent(app)),
            createPage(remotePayment(app)),
            createPage(remoteSignature(app)),
            createPage(submit(null, selectedRemoteSelling, app)),
          ]
        : [createPage(signature(app)), createPage(submit())]
    ),
    appendAfter(null, afterPage, [createPage(insuredHealth(app)), createPage(payerHealth(app))]),
    compact
  )(app)
}
