//@flow
import _ from 'lodash'
import moment from 'moment'

import type { AdvisorApiUserInfo, AgentType, User, Registration } from 'core/data-model/identity'
import type { DisplayProduct } from 'core/service/display-product'
import { getToggles } from 'quick-quote/feature-toggles'
import ENTRY_COMPONENT from '../../../quick-quote/constants/entry-components'
import values from '../../data-model/constants/values'
import type { State } from 'quick-quote/entry-component/reducer'

export type PermissionResult = {
  isValid: boolean,
  type: PermissionType,
}

export type PermissionType =
  | 'INACTIVE_USER'
  | 'NON_VERIFY_USER'
  | 'INVALID_LICENSES'
  | 'INVALID_RIGHT_LICENSES'
  | 'VALID'
  | 'NOT_VALIDATED'

export const validateSaleInvestmentPermission = (
  displayProduct: DisplayProduct,
  userInfo: AdvisorApiUserInfo,
  user: User
): PermissionResult => {
  if (!isVerifiedUser(user)) return { isValid: false, type: 'NON_VERIFY_USER' }
  if (!isActiveUser(userInfo)) return { isValid: false, type: 'INACTIVE_USER' }
  if (getToggles().VALIDATE_INVESTMENT_LB_PERMISSION) {
    if (_.get(userInfo, 'type.code') === 'LB') return { isValid: false, type: 'INVALID_LICENSES' }
  }

  const validateLicense = hasAllValidLicenses(displayProduct, userInfo)
  if (!validateLicense.isValid) {
    return validateLicense
  }

  return { isValid: true, type: 'VALID' }
}

const isVerifiedUser = (user: User): boolean =>
  user.type === 'Authenticated' && user.profile.agent_code !== undefined && user.profile.agent_code !== null

const isActiveUser = (user: AdvisorApiUserInfo) => user.status === 'active'

export const hasAllValidLicenses = (displayProduct: DisplayProduct, user: AdvisorApiUserInfo) => {
  const requiredNormalLicenses = _.without(displayProduct.licenses, 'UDR RIGHT', 'ILP RIGHT', 'IC REFRESHER')
  const requiredInvestmentLicense = displayProduct.licenses.filter((li) => !requiredNormalLicenses.includes(li))
  if (getToggles().VALIDATE_INVESTMENT_LICENSE_COMPULISORY) {
    const userNormalLicenses = user.party.registrations
      .filter((r) => r.state === 'active')
      .map((registration) => registration.typeCode)

    const validNormalLicense = _.difference(requiredNormalLicenses, userNormalLicenses).length === 0
    if (!validNormalLicense) {
      return { isValid: false, type: 'INVALID_LICENSES' }
    }

    const userInvestmentLicenses = user.party.registrations
      .filter((r) => {
        if (r.typeCode === 'IC REFRESHER') {
          return r.state === 'active' || r.state === 'inapplicable'
        }

        return r.state === 'active'
      })
      .map((registration) => registration.typeCode)

    const validInvestmentLicense = _.difference(requiredInvestmentLicense, userInvestmentLicenses).length === 0
    if (!validInvestmentLicense) {
      return { isValid: false, type: 'INVALID_LICENSES_INVESTMENT', x: userInvestmentLicenses }
    }
  } else {
    const userNormalLicenses = user.party.registrations
      .filter((r) => r.state === 'active')
      .map((registration) => registration.typeCode)

    const validNormalLicense = _.difference(requiredNormalLicenses, userNormalLicenses).length === 0
    if (!validNormalLicense) {
      return { isValid: false, type: 'INVALID_LICENSES' }
    }
  }

  return { isValid: true, type: 'VALID' }
}

const validateFsaUserStartedJourney = (entryComponent) => {
  if (getToggles().ONLY_ALLOW_FSA_TO_START_FROM_PA) {
    return entryComponent.source === ENTRY_COMPONENT.PERFECT_ADVICE
  }
  return true
}

export const validateUserStartedJourney = (agentType: AgentType, entryComponent: State) => {
  if (agentType === values.AGENT_TYPE_FSA) {
    return validateFsaUserStartedJourney(entryComponent)
  }
  return true
}

export const validateAdvisorPermission = (advisor: Object, allowedAgentTypes: AgentType[]) => {
  const registrations = _.get(advisor, 'party.registrations')
  const license = _.isArray(registrations)
    ? registrations.find((registration) => _.get(registration, 'typeCode') === 'BASIC')
    : {}

  const advisorStatus = _.get(advisor, 'status')
  const advisorType = _.get(advisor, 'type.code')
  const advisorLicenseStatus = _.get(license, 'state')

  const isCorrectTypeAdvisor = allowedAgentTypes.includes(advisorType)
  const isCorrectStatus = advisorStatus === 'active'
  const isCorrectLicenseStatus = advisorLicenseStatus === 'active'

  return isCorrectTypeAdvisor && isCorrectStatus && isCorrectLicenseStatus
}

//# Validate Ordinary Product Permission

const checkUserProductLicenses = (displayProduct: DisplayProduct, userInfo): PermissionResult => {
  const userLicenses = filterValidateLicense(userInfo.party.registrations)

  let validLicense = displayProduct.licenses?.every((item) => userLicenses.includes(item))
  if (!validLicense && !!displayProduct.licenses) {
    return { isValid: false, type: 'INVALID_RIGHT_LICENSES' }
  } else {
    return { isValid: true, type: 'VALID' }
  }
}

const validateLicenseDate = (license) => {
  if (!!license.startDate && !!license.endDate) {
    return moment().isBetween(license.startDate, license.endDate)
  } else if (!!license.startDate && !license.endDate) {
    return moment() >= moment(license.startDate)
  } else if (!!license.endDate && !license.startDate) {
    return moment() <= moment(license.endDate)
  } else {
    return true
  }
}

export const filterValidateLicense = (registrations: Registration[]): string[] => {
  return registrations
    .filter((r) => r.state === 'active' && validateLicenseDate(r))
    .map((registration) => registration.typeCode)
}
export const validateUserLicense = (
  displayProduct: DisplayProduct,
  userInfo: AdvisorApiUserInfo,
  user: User
): PermissionResult => {
  if (!isVerifiedUser(user)) return { isValid: false, type: 'NON_VERIFY_USER' }
  if (!isActiveUser(userInfo)) return { isValid: false, type: 'INACTIVE_USER' }

  return checkUserProductLicenses(displayProduct, userInfo)
}
