//@flow

import { validateLastName } from 'core/service/insured'
import type { SelectProduct } from 'quick-quote/product-selection/actions'
import { SELECT_PRODUCT } from 'quick-quote/product-selection/actions'
import { RESET_QUOTE_FORM_AFTER_MATURITY_VALIDATE } from 'quick-quote/product-s7cc/actions'
import { RESET_APPLICATION_STATE, RESET_QUOTE_FORM } from 'quick-quote/actions'
import messages from 'core/data-model/constants/messages'
import VALUES from 'core/data-model/constants/values'

export type State = {
  name: string,
  defaultValue: string,
  errors: string[],
}

const defaultState: State = {
  name: messages.LAST_NAME_DEFAULT,
  defaultValue: messages.LAST_NAME_DEFAULT,
  errors: [],
}

type EditNameAction = {
  type: string,
  payload: string,
}

type Action = EditNameAction | SelectProduct

const redux = (validateName: (string) => string[]) => (namespace: string, initialState?: State) => {
  const finalInitialState = initialState || defaultState

  // actions
  const EDIT_NAME = `${namespace}/EDIT_NAME`
  const CLEAR_NAME = `${namespace}/CLEAR_NAME`
  const editName = (name: string): EditNameAction => ({
    type: EDIT_NAME,
    payload: name,
  })

  // reducer
  const reducer = (state: State = finalInitialState, action: Action): State => {
    switch (action.type) {
      case SELECT_PRODUCT:
        return state
      case CLEAR_NAME:
      case RESET_QUOTE_FORM:
      case RESET_QUOTE_FORM_AFTER_MATURITY_VALIDATE:
      case RESET_APPLICATION_STATE:
        return { ...state, name: '', errors: [] }
      case EDIT_NAME:
        let inputPayload =
          action.payload.length > VALUES.LAST_NAME_MAX_LENGTH
            ? action.payload.substring(0, VALUES.LAST_NAME_MAX_LENGTH)
            : action.payload
        return {
          ...state,
          name: inputPayload,
          errors: validateName(inputPayload),
        }
    }

    return state
  }

  // selectors
  const getName = (state?: State) => (state ? state.name : '')
  const getDefaultValue = (state?: State) => (state ? state.defaultValue : '')
  const getErrors = (state?: State) => (state ? state.errors : '')

  return {
    initialState: finalInitialState,
    actions: { EDIT_NAME },
    actionCreators: { editName },
    reducer,
    selectors: { getName, getDefaultValue, getErrors },
  }
}

export default redux

export const LastNameRedux = redux(validateLastName)('LAST_NAME')
