// @flow
import { FormGroup } from 'reactstrap'
import type { Occupation, NatureOfDuty } from 'core/data-model/insured'
import OccupationSelection from './occupation-selection'
import { Checkbox } from 'common-components'

type Props = {
  selectedOccupation: ?Occupation,
  occupations: Occupation[],
  errors: string[],
  natureOfDuties: NatureOfDuty[],
  selectedNatureOfDutyCode: string,
  selectedOccupationCode: string,
  selectOccupation: (string) => void,
  selectNatureOfDuty: (NatureOfDuty) => void,
  toggleOtherOccupation: () => void,
  isSelectOtherOccupation: boolean,
  defaultOccupationCode: string,
}

const OtherOccupationSelection = ({
  selectedOccupation,
  occupations,
  natureOfDuties,
  errors,
  selectedNatureOfDutyCode,
  selectOccupation,
  selectNatureOfDuty,
  toggleOtherOccupation,
  selectedOccupationCode,
  isSelectOtherOccupation,
  defaultOccupationCode,
}: Props) => {
  return (
    <FormGroup id="other-occupation">
      <Checkbox id={'other-occupation-checkbox'} checked={isSelectOtherOccupation} onChange={toggleOtherOccupation}>
        อาชีพเสริม
      </Checkbox>
      {isSelectOtherOccupation ? (
        <OccupationSelection
          errors={errors}
          natureOfDuties={natureOfDuties}
          occupations={occupations}
          selectedNatureOfDutyCode={selectedNatureOfDutyCode}
          selectedOccupation={selectedOccupation}
          selectedOccupationCode={selectedOccupationCode}
          selectOccupation={selectOccupation}
          selectNatureOfDuty={selectNatureOfDuty}
          defaultOccupationCode={defaultOccupationCode}
        />
      ) : null}
    </FormGroup>
  )
}

export default OtherOccupationSelection
