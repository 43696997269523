// @flow
import _ from 'lodash'

import { checkRule } from './rule-mapper'
import riderMessages from 'core/data-model/constants/validation-messages'
import type { RiderRule, CompositeRule } from 'core/data-model/rider/rules'
import type { RiderEligibilities } from 'core/data-model/rider'
import type { ValidationMessage } from 'core/data-model/validation'

export const checkCompositeRule = (rule: CompositeRule, eligibilities: RiderEligibilities): ValidationMessage => {
  let errorMessage = ''
  let allOrAny = rule.allRulesMustBeViolated ? _.every : _.some
  let violateRule = (rule: RiderRule) => checkRule(rule, eligibilities).message !== ''

  if (
    !_.isEmpty(rule.rules) &&
    (typeof rule.allowUserType == 'undefined' ||
      (typeof rule.allowUserType !== 'undefined' &&
        typeof eligibilities.userAgentType !== 'undefined' &&
        rule.allowUserType.includes(eligibilities.userAgentType))) &&
    allOrAny(rule.rules, violateRule)
  ) {
    let messageId = `RULE_RIDER_${eligibilities.rider.code}_${rule.messageKey}`
    errorMessage = _.get(riderMessages, messageId) || riderMessages.RULE_MESSAGE_NOT_FOUND
  }

  return {
    type: rule.validationType,
    message: errorMessage,
  }
}
