import _ from 'lodash'
import { uniq, mergeWith, isArray, isFunction, mapValues, flow, map, getOr, pick, filter, has } from 'lodash/fp'
import { isEmpty } from './pattern'
import { getToggles } from 'quick-quote/feature-toggles'

const mergeArray = mergeWith((srcValue, destValue) => {
  if (isArray(srcValue)) {
    return uniq(srcValue.concat(destValue))
  }
})

const checkRule = (dependencies = {}) =>
  flow(
    filter(({ rule }) => {
      const { required, value, id } = dependencies
      const isInvalid = !rule(value, dependencies)
      if (getToggles().ENABLE_TAMLO && id === 'document' && has('certifyByAgent.isCertify')(value)) {
        if (required) {
          const isCertify = value.certifyByAgent.isCertify
          return !isCertify
        }
        return false
      }

      if (id && id.includes('document') && getToggles().ENABLE_APPMAN_REMOTE_SELLING && has('checked')(value)) {
        return !value.checked
      }

      if (!required && isEmpty(value)) {
        return false
      }

      if (!getToggles().ENABLE_TAMLO && has('certifyByAgent.isCertify')(value)) {
        return false
      }

      return isInvalid
    }),
    map('message'),
    map((message) => (isFunction(message) ? message(dependencies.value, dependencies) : message))
  )

export const createValidation = (specs) => {
  const allow = getOr((value) => true, 'allow')(specs)
  const getMessages = (dependencies) =>
    flow(
      pick(['errorMessages', 'warningMessages', 'requiredMessages', 'infoMessages']),
      mapValues(checkRule(dependencies))
    )(specs)

  return {
    allow,
    getMessages,
  }
}

export const createValidator = (specs) => (fieldId) => {
  const selectedValidation = _.pickBy(specs, (value, key) => {
    if (_.isNil(fieldId)) {
      return true
    }
    return new RegExp(key).test(fieldId)
  })

  const allow = (value) => _.every(selectedValidation, ({ allow }) => (isEmpty(value) ? true : allow(value)))

  const getValidationMessages = (dependencies) => {
    return _.reduce(
      selectedValidation,
      (messages, { getMessages }) => mergeArray(messages, getMessages({ id: fieldId, ...dependencies })),
      {}
    )
  }
  return {
    allow,
    getValidationMessages,
  }
}
