import styled from 'styled-components'
export default styled.label`
  display: block;
  font-size: 1.125rem;
  font-weight: bold;
  ul {
    padding-left: 20px;
  }

  div {
    font-size: 0.825rem;
    font-weight: normal;
  }
`
