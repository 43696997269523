// @flow
import _ from 'lodash'
import type { Effect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import VALUES from 'core/data-model/constants/values'

import { getProductDefaults } from 'core/data-model/basic-plan/repository'

import { SELECT_PRODUCT } from 'quick-quote/product-selection/actions'
import { populateAvailableRiders } from 'quick-quote/product-common/coverage-plan/sagas'
import { editRiderSelectedPlan, updateSelectedPlan } from 'quick-quote/product-common/coverage-plan/actions'

import { getOptionPlanPARider } from 'quick-quote/product-pa/coverage-plan/selectors'

export function* setProductDefaultsPA(action: SelectProduct): Generator<*, *, *> {
  const displayProduct = action.payload
  if (displayProduct.category !== VALUES.PA) return

  const defaults = yield call(getProductDefaults, displayProduct.basicPlanCode)
  if (defaults) {
    yield put(updateSelectedPlan(defaults.code))
    yield call(populateAvailableRiders)
    const optionPlanPA = yield select(getOptionPlanPARider)
    const planGroupPA = _.find(optionPlanPA, { planCode: defaults.riderCode })
    if (planGroupPA) {
      yield put(
        editRiderSelectedPlan('PARIDER', { planCode: planGroupPA.planCode, packageDetail: planGroupPA.package })
      )
    }
  }
}

export function* watchers(): Generator<*, *, Effect[]> {
  yield [takeLatest(SELECT_PRODUCT, setProductDefaultsPA)]
}

export const sagas = watchers
