import { compact, reduce, endsWith } from 'lodash/fp'
import { createSectionCreator, createField } from '../utils'
import { checker } from '../selectors'

const reasonInputField = (id) =>
  createField(`${id}.detail`, [
    {
      label: 'โปรดระบุรายละเอียด',
      id: `${id}.detail`,
      c: 'TextInput',
      p: {},
    },
  ])

const getQuestions = (app, id) => {
  const { isYes } = checker(app)

  const questions = compact([
    createField(`${id}.isPregnant.checked`, [
      {
        id: `${id}.isPregnant.checked`,
        c: 'RadioButton',
        required: true,
        label: 'ท่านกําลังตั้งครรภ์หรือไม่ หากใช่ โปรดระบุอายุครรภ์',
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
    isYes(`${id}.isPregnant.checked`) &&
      createField(`${id}.pregnancyComplication.checked`, [
        {
          id: `${id}.pregnancyComplication.checked`,
          c: 'RadioButton',
          required: true,
          label:
            'สําหรับสตรีมีครรภ์ ท่านมีภาวะแทรกซ้อนระหว่างการตั้งครรภ์ หรือไม่ เช่น โรคเบาหวาน โรคความดันโลหิตสูง หรืออื่นๆ ถ้ามี กรุณาระบุ',
          p: {
            options: [
              {
                text: 'ใช่',
                value: 'yes',
              },
              {
                text: 'ไม่ใช่',
                value: 'no',
              },
            ],
          },
        },
      ]),
  ])

  return reduce((questions, question) => {
    if (isYes(question.id) && !endsWith('label', question.id)) {
      return questions.concat(question).concat(reasonInputField(question.id))
    }
    return questions.concat(question)
  }, [])(questions)
}

export default createSectionCreator(getQuestions)
