import { useRef, useEffect } from 'react'
import { compose, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import useIsScrollComplete from 'e-submission/utils/hook/useIsScrollComplete'
import { readConsentEdaComplete, resetConsentEdaComplete, setAppValue } from 'e-submission/apps/actions'
import styled from 'styled-components'
import { getCurrentApp } from 'e-submission/apps/selectors'
import { isEDAConsent } from 'e-submission/domain/data-model/form/selectors'
const OverrideDiv = styled.div`
  margin: 1rem auto;
  .form-group {
    display: flex;
    justify-content: center;
  }
`

const ConsentPage = ({ onClickRadioConsent, isEDAConsentChecked, handleReadConsentEdaComplete }) => {
  const containerRef = useRef(null)
  const { isScrollComplete } = useIsScrollComplete({ ref: containerRef, markAsComplete: true })
  useEffect(() => {
    if (isScrollComplete) {
      handleReadConsentEdaComplete()
    }
  }, [isScrollComplete])

  return (
    <>
      <div ref={containerRef} className="consent-page">
        <div id="consent-detail" className="container">
          <label className="sub-heading">ข้าพเจ้ายอมรับว่า</label>
          <label className="text">
            (ก) ธนาคารจะหักเงินเบี้ยประกันรายงวดเต็มจำนวนก็ต่อเมื่อเงินในบัญชีมีเพียงพอในขณะนั้นเท่านั้น
          </label>
          <label className="text">
            (ข) หากไม่สามารถหักเงินตามข้อ (ก) ได้ไม่ว่าสาเหตุใดก็ตาม ให้ถือว่ายังไม่มีการชำระเบี้ยประกันภัย
          </label>
          <label className="text">
            (ค) หากมีความเสียหาย หรือ ความผิดพลาดใด ๆ เกิดขึ้นแก่ธนาคาร หรือบริษัท ฯ เนื่องมาจากการกระทำใด ๆ ของข้าพเจ้า
            ข้าพเจ้ายินยอมรับชดใช้ค่าเสียหายแก่ธนาคารหรือบริษัท ฯ ตามความเสียหายที่เกิดขึ้นจริงทุกประการ
          </label>
          <label className="text">
            (ง) หากจำนวนเงินที่บริษัท ฯ แจ้งแก่ธนาคารไม่ถูกต้อง
            แต่ธนาคารได้ทำการหักเงินจากบัญชีดังกล่าวตามจำนวนเงินที่บริษัท ฯ แจ้ง
            ข้าพเจ้าตกลงที่จะเรียกร้องเงินจำนวนดังกล่าวจากบริษัท ฯ
            โดยตรงและขอสละสิทธิ์ในการเรียกร้องหรือฟ้องร้องให้ธนาคารชดใช้เงินจำนวนนั้น
          </label>
          <label className="text">(จ) ธนาคารไม่มีหน้าที่ตรวจสอบความถูกต้องของจำนวนเงินที่ได้รับแจ้งจากบริษัท ฯ</label>
          <label className="text">
            (ฉ)
            ข้าพเจ้าไม่ประสงค์ให้ธนาคารแจ้งการหักบัญชีแต่อย่างใดเนื่องจากรายการดังกล่าวปรากฏอยู่ในสมุดคู่ฝาก/Statement
            ของธนาคารหรือเอกสารการรับเบี้ยประกันภัยของบริษัท ฯ
          </label>
          <label className="text">
            (ช) ข้าพเจ้ายินยอมให้บริษัท ฯ
            เรียกเก็บเงินด้วยวิธีหักบัญชีเงินฝากธนาคารตามรอบการชำระของกรมธรรม์ของข้าพเจ้าข้างต้น
            และกรณีหักบัญชีไม่ผ่านเนื่องจากจำนวนเงินไม่พอชำระ บริษัท ฯ
            จะทำการส่งข้อมูลให้ธนาคารทำการเรียกเก็บเบี้ยประกันอีกครั้งภายใน 4 วันทำการ
          </label>
          <label className="text">
            (ซ) ข้าพเจ้ายอมรับและยืนยันว่าข้าพเจ้าเป็นเจ้าของบัญชีธนาคารที่ประสงค์ให้หักชำระค่าเบี้ยฯ
            หรือเป็นผู้ชำระแทนในกรณีผู้เยาว์ หรือมีความสัมพันธ์โดยชอบตามทางกฎหมายเป็น บิดา-มารดา สามี-ภรรยา หรือบุตรของ
            ผู้เอาประกัน ทั้งนี้ ข้าพเจ้าจะไม่ปฏิเสธการทำรายการที่เกิดขึ้นในทุกกรณีกับธนาคารหรือบริษัท ฯ
            และหากพบข้อผิดพลาดหรือต้องการขอเงินคืน ข้าพเจ้าจะเป็นผู้ติดต่อไปยัง บริษัท กรุงไทย-แอกซ่า ประกันชีวิต จำกัด
            (มหาชน) โดยตรง
          </label>
          <label className="text">
            (ญ)
            ข้าพเจ้ารับทราบและยอมรับว่าการทำรายการเพื่อสมัครหักชำระค่าเบี้ยประกันด้วยบัญชีธนาคารและ/หรือให้ความยินยอมหลังเวลา
            19:30 น.ของวันทำการ บริษัทฯ จะดำเนินการสมัครหักชำระเบี้ยฯภายในวันทำการถัดไป
          </label>
          <br />
          <label className="text">
            หากบัญชีเงินฝากออมทรัพย์ หรือบัญชีกระแสรายวัน หรือค่าเบี้ยประกันภัย
            ได้เปลี่ยนแปลงไม่ว่าโดยสาเหตุใดก็ตามหนังสือแจ้งความประสงค์ฉบับนี้ ยังคงมีผลบังคับใช้กับบัญชีใหม่ หรือ
            ค่าเบี้ยประกันภัยที่ได้เปลี่ยนแปลงนั้นๆทุกประการ ทั้งนี้ข้าพเจ้าตกลงสละสิทธิ์ยกเลิก /แก้ไข /
            ลบล้างความยินยอมดังกล่าวจนกว่าความคุ้มครองตามสัญญาประกันภัยของข้าพเจ้า หรืออายุความในการฟ้องคดีสิ้นสุดลง
            แล้วแต่ระยะเวลาใดสิ้นสุดภายหลัง ทั้งนี้ หากมีการเปลี่ยนแปลงเลขที่บัญชีธนาคาร
            หรือยกเลิกความยินยอมตามหนังสือฉบับนี้ข้าพเจ้าจะแจ้งเป็นลายลักษณ์อักษรแก่บริษัท ฯ ล่วงหน้าอย่างน้อย 30 วัน
            และให้ถือความยินยอมตามหนังสือฉบับล่าสุดของข้าพเจ้ามีผลบังคับใช้และยกเลิก ความยินยอมเดิมโดยอัตโนมัติ
          </label>
          <br />
          <label className="remark" id="">
            ข้าพเจ้ายินยอมให้บริษัท ฯ จัดเก็บ รวบรวม ใช้
            และเปิดเผยข้อมูลหมายเลขบัญชีเงินฝากของข้าพเจ้าให้แก่ธนาคารเจ้าของบัญชี
            เพื่อวัตถุประสงค์ในการหักชำระค่าเบี้ยประกันภัยของข้าพเจ้าเพื่อชำระให้แก่บริษัท ฯ ภายใต้เงื่อนไขของกรมธรรม์
          </label>
          <br />
        </div>
        <OverrideDiv className="radio-consent-form">
          <div className="form-group">
            <label className="custom-control custom-checkbox" htmlFor="agreeConsentEda-radio">
              <input
                className="custom-control-input"
                type="checkbox"
                name="agreeConsentEda"
                defaultChecked={isEDAConsentChecked}
                id="agreeConsentEda-radio"
                onClick={() => onClickRadioConsent(isEDAConsentChecked ? true : false)}
              />
              <span className="custom-control-indicator" />
              <span>ยอมรับ</span>
            </label>
          </div>
        </OverrideDiv>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  const app = getCurrentApp(state)
  const isEDAConsentChecked = isEDAConsent(app)
  return { isEDAConsentChecked }
}
const mapDispatchToProps = (dispatch) => ({
  handleReadConsentEdaComplete: bindActionCreators(readConsentEdaComplete, dispatch),
  handleResetConsentEdaComplete: bindActionCreators(resetConsentEdaComplete, dispatch),
  onClickRadioConsent: (newValue) => {
    dispatch(setAppValue('isEDAConsent', !newValue))
  },
})

export default (hoc) =>
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    lifecycle({
      componentDidMount() {
        this.props.handleResetConsentEdaComplete()
      },
    }),
    hoc
  )(ConsentPage)
