import { isFinishedPayLaterSelection } from 'e-submission/apps/selectors'
import { compact, get } from 'lodash/fp'
import { getPaidReceipts, isSelectedToPayLater } from '../selectors'
import { signatureSection } from '../signatureSection'
import VALUE from 'core/data-model/constants/values'
import { baseAgentRequiredDocs as defaultRequiredDocs } from './signature'
import { baseAgentRequiredDocs as protectionRequiredDocs } from '../../product-form/product-protection/signature'
import { baseAgentRequiredDocs as investmentRequiredDocs } from '../../product-form/product-investment/signature'
import { baseAgentRequiredDocs as retirementRequiredDocs } from '../../product-form/product-retirement/signature'

export const remoteSignature = (app, props = {}) => {
  const [receipt] = getPaidReceipts(app)
  const id = receipt ? `receipts[${receipt.key}]` : undefined
  const isPayLater = isSelectedToPayLater(app) === true && isFinishedPayLaterSelection(app)
  const isInvestment = get('quickQuote.selectedDisplayProduct.category', app) === VALUE.INVESTMENT
  const isRetirement = get('quickQuote.selectedDisplayProduct.category', app) === VALUE.RETIREMENT
  const isProtection = get('quickQuote.selectedDisplayProduct.category', app) === VALUE.PROTECTION

  let basedRequiredDocs = [...defaultRequiredDocs]

  if (isInvestment) basedRequiredDocs = [...basedRequiredDocs, ...investmentRequiredDocs]
  if (isRetirement) basedRequiredDocs = [...basedRequiredDocs, ...retirementRequiredDocs]
  if (isProtection) basedRequiredDocs = [...basedRequiredDocs, ...protectionRequiredDocs]

  const agentSignatureSection = signatureSection(
    'agent',
    compact([...new Set(basedRequiredDocs).toJSON(), id && 'ECBR']),
    false
  )
  return Object.assign(
    {},
    {
      id: 'remote-signature',
      prefix: 'remote-signature',
      title: 'ตัวแทนลงนาม',
      label: 'ตัวแทนลงนาม',
      sections: compact([agentSignatureSection(id || 'agent', 'ลงนาม ตัวแทน/นายหน้าประกันชีวิต')]),
      remoteSection: true,
      disabled: !id && !isPayLater,
    },
    props
  )
}
