//@flow
import ACTIONS from './action-types'

export type StartFNAFlow = {
  type: ACTIONS.START_FNA_FLOW,
}

export type StartQQFlow = {
  type: ACTIONS.START_QQ_FLOW,
}

export const startFNAFlow = () => {
  return {
    type: ACTIONS.START_FNA_FLOW,
  }
}

export const startQQFlow = () => {
  return {
    type: ACTIONS.START_QQ_FLOW,
  }
}

export type Action = StartFNAFlow | StartQQFlow
