// @flow
import { compact } from 'lodash/fp'
import _ from 'lodash'
import type { AppState } from 'quick-quote/reducers'
import BIMESSAGES from 'core/data-model/constants/bi-messages'

import { LegalConditionDisclaimerMarketConduct } from 'quick-quote/v2/components/disclaimers'
import ProfileSnapshot from 'quick-quote/product-common/benefit-illustration/components/profile-summary/profile-snapshot'
import ProductBenefits from 'quick-quote/product-whole-life/benefit-illustration/components/product-benefits/product-benefits'
import RiderBenefits from 'quick-quote/product-common/benefit-illustration/components/rider-benefits'
import BenefitSummaryTable from 'quick-quote/product-whole-life/benefit-illustration/components/benefit-summary-table'
import { TaxDeductionBenefit } from 'quick-quote/v2/products/customs/sukhapabdekdee/components/benefit-illustration/tax-deduction'
import PolicyValueTable from 'quick-quote/v2/products/customs/sukhapabdekdee/components/benefit-illustration/policy-values/layout-with-cash-drop/policy-value-table'

import { getProfileSnapshot } from 'quick-quote/product-common/benefit-illustration/components/profile-summary/selectors'
import { getProductBenefits } from 'quick-quote/product-whole-life/benefit-illustration/components/product-benefits/selectors'
import { getSelectedRiders } from 'quick-quote/product-common/coverage-plan/selectors'
import { getPolicyValueTableBenefits } from 'quick-quote/v2/components/benefit-illustration/policy-values/layout-with-cash-drop/selectors'
import { getTaxDeduction } from 'quick-quote/product-whole-life/benefit-illustration/selectors'
import { getLegalConditionDisclaimer } from 'quick-quote/v2/components/disclaimers/selectors'

const _summaryHeaderWithCode = ({ displayProduct }: *) => {
  const basicPlanCode = _.get(displayProduct, 'basicPlanCode', '')
  const selectedCampaignPlan = _.get(displayProduct, 'selectedCampaignPlan', '')
  const name = _.get(
    BIMESSAGES,
    `SUKHAPABDEKDEE_PLAN.${selectedCampaignPlan}`,
    _.get(displayProduct, 'displayName', '')
  )
  return `${name} (${basicPlanCode})`
}

const _summaryHeader = ({ displayProduct }): ?string => displayProduct.basicPlanName

export const getSections = (state: AppState) => {
  const riders = getSelectedRiders(state)
  return compact([
    {
      component: ProfileSnapshot,
      props: {
        ...getProfileSnapshot(state),
        profileSummaryHeader: _summaryHeaderWithCode,
        summaryTableHeader: _summaryHeader,
      },
    },
    {
      component: ProductBenefits,
      props: {
        ...getProductBenefits(state),
      },
    },
    riders.length > 0 && {
      component: RiderBenefits,
    },
    {
      component: PolicyValueTable,
      props: {
        ...getPolicyValueTableBenefits(state),
      },
    },
    {
      component: BenefitSummaryTable,
    },
    {
      component: TaxDeductionBenefit,
      props: {
        taxDeduction: getTaxDeduction(state),
      },
    },
    {
      component: LegalConditionDisclaimerMarketConduct,
      props: {
        ...getLegalConditionDisclaimer(state),
      },
    },
  ])
}

export const getMenus = (state: AppState) => [
  {
    text: BIMESSAGES.SUMMARY_OF_MAIN_AND_ADDITIONAL_CONTRACTS,
    id: 'profile-summary',
    isSub: false,
  },
  {
    text: 'รายละเอียดของสัญญาหลัก',
    id: 'product-benefits',
    isSub: false,
  },
  {
    text: BIMESSAGES.POLICY_VALUE_TABLE,
    id: 'policy-value',
    isSub: false,
  },
  {
    text: BIMESSAGES.BENEFIT_SUMMARY,
    id: 'benefit-summary',
    isSub: false,
  },
  {
    text: BIMESSAGES.LEGAL_DISCLAIMER,
    id: 'legal-disclaimer-market-conduct',
    isSub: false,
  },
]
