//@flow

import _ from 'lodash'
import { FormGroup, FormFeedback } from 'reactstrap'
import { isPersonName } from 'core/service/insured/validation'

type Props = {
  name: string,
  defaultValue: string,
  placeholder: string,
  label: string,
  errors: string[],
  testId?: string,
  editName: Function,
}

const Name = ({ name, defaultValue, placeholder, label, errors, testId, editName }: Props) => {
  const className = _.isEmpty(errors) ? '' : 'has-danger'

  return (
    <FormGroup className={className}>
      <div className="name-input">
        <label>{label}</label>
        <input
          data-testid={testId}
          className="form-control"
          type="text"
          placeholder={placeholder}
          value={name}
          onChange={(e) => isPersonName(e.target.value) && editName(e.target.value)}
          onFocus={(e) => {
            if (e.target.value === defaultValue) e.target.value = ''
          }}
          onBlur={(e) => {
            if (e.target.value === '') {
            }
            isPersonName(e.target.value.trim()) && editName(e.target.value.trim())
          }}
        />
        <FormFeedback className={'validation-message attached-icon'}>
          <div>
            {errors.map((message, index) => (
              <p key={index}>{message}</p>
            ))}
          </div>
        </FormFeedback>
      </div>
    </FormGroup>
  )
}

export default Name
