// @flow
import { queryPremiumRateForAP as queryPremiumRate } from 'core/service/rider/query-premium-rate'
import { calculateRiderPremiumForVaryingSumAssured as calculateRiderPremium } from 'core/service/rider/premium-calculation'
import { queryTaxRateForAP as queryTaxRate } from 'core/service/rider/query-tax-rate'

export default {
  calculateRiderPremium,
  queryPremiumRate,
  queryTaxRate,
}
