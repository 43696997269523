//@flow
export const PATH = {
  PORTAL: '/portal',
  PRODUCT_SELECTION: '/product-selection',
  NEED_AND_GAP_ANALYSIS: '/need-and-gap-analysis',
  FNA: '/fna',
  QUICK_QUOTE: '/quick',
  POLICY_LIST: '/e-submission/list',
  INSURED_INFORMATION: '/insured-information',
  COVERAGE_PLAN: '/coverage-plan',
  BENEFIT_ILLUSTRATION: '/benefit-illlustration',
  RISK_QUESTIONNAIRE: '/risk-questionnaire',
  FUND_ALLOCATION: '/fund-allocation',
  LSTU_FUND_ALLOCATION: '/lstu-fund-allocation',
  MARKET_CONDUCTS: '/market-conducts',
  WHOLE_LIFE: '/whole-life',
  PROTECTION: '/protection',
  ESUBMISSION: '/e-submission',
  HEALTH: '/health',
  IHEALTHY_ULTRA: '/ihealthy-ultra',
  LIFE_READY: '/life-ready',
  INVESTMENT: '/investment',
  LOAN: '/loan',
  SAVING: '/saving',
  LIFE_SAVE_PRO: '/life-save-pro',
  FLEXI_HEALTH: '/flexi-health',
  RETIREMENT: '/retirement',
  BUMNAN_READY: '/bumnan-ready',
  PERFECT_LIFE: '/perfect-life',
  HEALTH_TOPPING: '/health-topping',
  FUND_SUMMARTY: '/fund-summary',
  SUKHAPABDEKDEE: '/sukhapabdekdee',
  S7CC: '/s7cc',
  TERM_LIFE: '/term-life',
  MRTA: '/mrta',
  PA: '/personal-accident',
  MATURITY_VALIDATE: '/maturity-validate',
  SELECT_POLICY_OWNER: '/select-policy-owner',
  OPTY: '/opportunities',
  BROCHURE: '/brochure',
  MW_PLUS: '/my-wealth-plus',
}

export const ROUTE_PATH = {
  WHOLE_LIFE: {
    INSURED_INFORMATION: `${PATH.WHOLE_LIFE}${PATH.INSURED_INFORMATION}`,
    COVERAGE_PLAN: `${PATH.WHOLE_LIFE}${PATH.COVERAGE_PLAN}`,
    BENEFIT_ILLUSTRATION: `${PATH.WHOLE_LIFE}${PATH.BENEFIT_ILLUSTRATION}`,
    MARKET_CONDUCTS: `${PATH.WHOLE_LIFE}${PATH.MARKET_CONDUCTS}`,
    NEED_AND_GAP_ANALYSIS: `${PATH.WHOLE_LIFE}${PATH.NEED_AND_GAP_ANALYSIS}`,
    PRODUCT_SELECTION: `${PATH.WHOLE_LIFE}${PATH.PRODUCT_SELECTION}`,
  },
  PROTECTION: {
    INSURED_INFORMATION: `${PATH.PROTECTION}${PATH.INSURED_INFORMATION}`,
    COVERAGE_PLAN: `${PATH.PROTECTION}${PATH.COVERAGE_PLAN}`,
    BENEFIT_ILLUSTRATION: `${PATH.PROTECTION}${PATH.BENEFIT_ILLUSTRATION}`,
    MARKET_CONDUCTS: `${PATH.PROTECTION}${PATH.MARKET_CONDUCTS}`,
    NEED_AND_GAP_ANALYSIS: `${PATH.PROTECTION}${PATH.NEED_AND_GAP_ANALYSIS}`,
    PRODUCT_SELECTION: `${PATH.PROTECTION}${PATH.PRODUCT_SELECTION}`,
  },
  HEALTH: {
    INSURED_INFORMATION: `${PATH.HEALTH}${PATH.INSURED_INFORMATION}`,
    COVERAGE_PLAN: `${PATH.HEALTH}${PATH.COVERAGE_PLAN}`,
    BENEFIT_ILLUSTRATION: `${PATH.HEALTH}${PATH.BENEFIT_ILLUSTRATION}`,
    MARKET_CONDUCTS: `${PATH.HEALTH}${PATH.MARKET_CONDUCTS}`,
    NEED_AND_GAP_ANALYSIS: `${PATH.HEALTH}${PATH.NEED_AND_GAP_ANALYSIS}`,
    PRODUCT_SELECTION: `${PATH.HEALTH}${PATH.PRODUCT_SELECTION}`,
  },
  IHEALTHY_ULTRA: {
    INSURED_INFORMATION: `${PATH.IHEALTHY_ULTRA}${PATH.INSURED_INFORMATION}`,
    COVERAGE_PLAN: `${PATH.IHEALTHY_ULTRA}${PATH.COVERAGE_PLAN}`,
    BENEFIT_ILLUSTRATION: `${PATH.IHEALTHY_ULTRA}${PATH.BENEFIT_ILLUSTRATION}`,
    MARKET_CONDUCTS: `${PATH.IHEALTHY_ULTRA}${PATH.MARKET_CONDUCTS}`,
    NEED_AND_GAP_ANALYSIS: `${PATH.IHEALTHY_ULTRA}${PATH.NEED_AND_GAP_ANALYSIS}`,
    PRODUCT_SELECTION: `${PATH.IHEALTHY_ULTRA}${PATH.PRODUCT_SELECTION}`,
  },
  INVESTMENT: {
    INSURED_INFORMATION: `${PATH.INVESTMENT}${PATH.INSURED_INFORMATION}`,
    RISK_QUESTIONNAIRE: `${PATH.INVESTMENT}${PATH.RISK_QUESTIONNAIRE}`,
    COVERAGE_PLAN: `${PATH.INVESTMENT}${PATH.COVERAGE_PLAN}`,
    FUND_ALLOCATION: `${PATH.INVESTMENT}${PATH.FUND_ALLOCATION}`,
    LSTU_FUND_ALLOCATION: `${PATH.INVESTMENT}${PATH.LSTU_FUND_ALLOCATION}`,
    BENEFIT_ILLUSTRATION: `${PATH.INVESTMENT}${PATH.BENEFIT_ILLUSTRATION}`,
    MARKET_CONDUCTS: `${PATH.INVESTMENT}${PATH.MARKET_CONDUCTS}`,
    NEED_AND_GAP_ANALYSIS: `${PATH.INVESTMENT}${PATH.NEED_AND_GAP_ANALYSIS}`,
    PRODUCT_SELECTION: `${PATH.INVESTMENT}${PATH.PRODUCT_SELECTION}`,
    FUND_SUMMARTY: `${PATH.INVESTMENT}${PATH.FUND_SUMMARTY}`,
  },
  LOAN: {
    INSURED_INFORMATION: `${PATH.LOAN}${PATH.INSURED_INFORMATION}`,
    COVERAGE_PLAN: `${PATH.LOAN}${PATH.COVERAGE_PLAN}`,
    BENEFIT_ILLUSTRATION: `${PATH.LOAN}${PATH.BENEFIT_ILLUSTRATION}`,
    MARKET_CONDUCTS: `${PATH.LOAN}${PATH.MARKET_CONDUCTS}`,
    NEED_AND_GAP_ANALYSIS: `${PATH.LOAN}${PATH.NEED_AND_GAP_ANALYSIS}`,
    PRODUCT_SELECTION: `${PATH.LOAN}${PATH.PRODUCT_SELECTION}`,
  },
  SAVING: {
    INSURED_INFORMATION: `${PATH.SAVING}${PATH.INSURED_INFORMATION}`,
    COVERAGE_PLAN: `${PATH.SAVING}${PATH.COVERAGE_PLAN}`,
    BENEFIT_ILLUSTRATION: `${PATH.SAVING}${PATH.BENEFIT_ILLUSTRATION}`,
    MARKET_CONDUCTS: `${PATH.SAVING}${PATH.MARKET_CONDUCTS}`,
    NEED_AND_GAP_ANALYSIS: `${PATH.SAVING}${PATH.NEED_AND_GAP_ANALYSIS}`,
    PRODUCT_SELECTION: `${PATH.SAVING}${PATH.PRODUCT_SELECTION}`,
  },
  LIFE_SAVE_PRO: {
    INSURED_INFORMATION: `${PATH.LIFE_SAVE_PRO}${PATH.INSURED_INFORMATION}`,
    COVERAGE_PLAN: `${PATH.LIFE_SAVE_PRO}${PATH.COVERAGE_PLAN}`,
    BENEFIT_ILLUSTRATION: `${PATH.LIFE_SAVE_PRO}${PATH.BENEFIT_ILLUSTRATION}`,
    MARKET_CONDUCTS: `${PATH.LIFE_SAVE_PRO}${PATH.MARKET_CONDUCTS}`,
    NEED_AND_GAP_ANALYSIS: `${PATH.LIFE_SAVE_PRO}${PATH.NEED_AND_GAP_ANALYSIS}`,
    PRODUCT_SELECTION: `${PATH.LIFE_SAVE_PRO}${PATH.PRODUCT_SELECTION}`,
  },
  FLEXI_HEALTH: {
    INSURED_INFORMATION: `${PATH.FLEXI_HEALTH}${PATH.INSURED_INFORMATION}`,
    COVERAGE_PLAN: `${PATH.FLEXI_HEALTH}${PATH.COVERAGE_PLAN}`,
    BENEFIT_ILLUSTRATION: `${PATH.FLEXI_HEALTH}${PATH.BENEFIT_ILLUSTRATION}`,
    MARKET_CONDUCTS: `${PATH.FLEXI_HEALTH}${PATH.MARKET_CONDUCTS}`,
    NEED_AND_GAP_ANALYSIS: `${PATH.FLEXI_HEALTH}${PATH.NEED_AND_GAP_ANALYSIS}`,
    PRODUCT_SELECTION: `${PATH.FLEXI_HEALTH}${PATH.PRODUCT_SELECTION}`,
  },
  RETIREMENT: {
    INSURED_INFORMATION: `${PATH.RETIREMENT}${PATH.INSURED_INFORMATION}`,
    COVERAGE_PLAN: `${PATH.RETIREMENT}${PATH.COVERAGE_PLAN}`,
    BENEFIT_ILLUSTRATION: `${PATH.RETIREMENT}${PATH.BENEFIT_ILLUSTRATION}`,
    MARKET_CONDUCTS: `${PATH.RETIREMENT}${PATH.MARKET_CONDUCTS}`,
    NEED_AND_GAP_ANALYSIS: `${PATH.RETIREMENT}${PATH.NEED_AND_GAP_ANALYSIS}`,
    PRODUCT_SELECTION: `${PATH.RETIREMENT}${PATH.PRODUCT_SELECTION}`,
  },
  BUMNAN_READY: {
    INSURED_INFORMATION: `${PATH.BUMNAN_READY}${PATH.INSURED_INFORMATION}`,
    COVERAGE_PLAN: `${PATH.BUMNAN_READY}${PATH.COVERAGE_PLAN}`,
    BENEFIT_ILLUSTRATION: `${PATH.BUMNAN_READY}${PATH.BENEFIT_ILLUSTRATION}`,
    MARKET_CONDUCTS: `${PATH.BUMNAN_READY}${PATH.MARKET_CONDUCTS}`,
    NEED_AND_GAP_ANALYSIS: `${PATH.BUMNAN_READY}${PATH.NEED_AND_GAP_ANALYSIS}`,
    PRODUCT_SELECTION: `${PATH.BUMNAN_READY}${PATH.PRODUCT_SELECTION}`,
  },
  PERFECT_LIFE: {
    INSURED_INFORMATION: `${PATH.PERFECT_LIFE}${PATH.INSURED_INFORMATION}`,
    COVERAGE_PLAN: `${PATH.PERFECT_LIFE}${PATH.COVERAGE_PLAN}`,
    BENEFIT_ILLUSTRATION: `${PATH.PERFECT_LIFE}${PATH.BENEFIT_ILLUSTRATION}`,
    MARKET_CONDUCTS: `${PATH.PERFECT_LIFE}${PATH.MARKET_CONDUCTS}`,
    NEED_AND_GAP_ANALYSIS: `${PATH.PERFECT_LIFE}${PATH.NEED_AND_GAP_ANALYSIS}`,
    PRODUCT_SELECTION: `${PATH.PERFECT_LIFE}${PATH.PRODUCT_SELECTION}`,
  },
  SUKHAPABDEKDEE: {
    INSURED_INFORMATION: `${PATH.SUKHAPABDEKDEE}${PATH.INSURED_INFORMATION}`,
    COVERAGE_PLAN: `${PATH.SUKHAPABDEKDEE}${PATH.COVERAGE_PLAN}`,
    BENEFIT_ILLUSTRATION: `${PATH.SUKHAPABDEKDEE}${PATH.BENEFIT_ILLUSTRATION}`,
    MARKET_CONDUCTS: `${PATH.SUKHAPABDEKDEE}${PATH.MARKET_CONDUCTS}`,
    NEED_AND_GAP_ANALYSIS: `${PATH.SUKHAPABDEKDEE}${PATH.NEED_AND_GAP_ANALYSIS}`,
    PRODUCT_SELECTION: `${PATH.SUKHAPABDEKDEE}${PATH.PRODUCT_SELECTION}`,
  },
  HEALTH_TOPPING: {
    INSURED_INFORMATION: `${PATH.HEALTH_TOPPING}${PATH.INSURED_INFORMATION}`,
    COVERAGE_PLAN: `${PATH.HEALTH_TOPPING}${PATH.COVERAGE_PLAN}`,
    BENEFIT_ILLUSTRATION: `${PATH.HEALTH_TOPPING}${PATH.BENEFIT_ILLUSTRATION}`,
    MARKET_CONDUCTS: `${PATH.HEALTH_TOPPING}${PATH.MARKET_CONDUCTS}`,
    NEED_AND_GAP_ANALYSIS: `${PATH.HEALTH_TOPPING}${PATH.NEED_AND_GAP_ANALYSIS}`,
    PRODUCT_SELECTION: `${PATH.HEALTH_TOPPING}${PATH.PRODUCT_SELECTION}`,
  },
  LIFE_READY: {
    INSURED_INFORMATION: `${PATH.LIFE_READY}${PATH.INSURED_INFORMATION}`,
    COVERAGE_PLAN: `${PATH.LIFE_READY}${PATH.COVERAGE_PLAN}`,
    BENEFIT_ILLUSTRATION: `${PATH.LIFE_READY}${PATH.BENEFIT_ILLUSTRATION}`,
    MARKET_CONDUCTS: `${PATH.LIFE_READY}${PATH.MARKET_CONDUCTS}`,
    NEED_AND_GAP_ANALYSIS: `${PATH.LIFE_READY}${PATH.NEED_AND_GAP_ANALYSIS}`,
    PRODUCT_SELECTION: `${PATH.LIFE_READY}${PATH.PRODUCT_SELECTION}`,
  },
  S7CC: {
    MATURITY_VALIDATE: `${PATH.S7CC}${PATH.MATURITY_VALIDATE}`,
    SELECT_POLICY_OWNER: `${PATH.S7CC}${PATH.SELECT_POLICY_OWNER}`,
    INSURED_INFORMATION: `${PATH.S7CC}${PATH.INSURED_INFORMATION}`,
    COVERAGE_PLAN: `${PATH.S7CC}${PATH.COVERAGE_PLAN}`,
    BENEFIT_ILLUSTRATION: `${PATH.S7CC}${PATH.BENEFIT_ILLUSTRATION}`,
    MARKET_CONDUCTS: `${PATH.S7CC}${PATH.MARKET_CONDUCTS}`,
    NEED_AND_GAP_ANALYSIS: `${PATH.S7CC}${PATH.NEED_AND_GAP_ANALYSIS}`,
    PRODUCT_SELECTION: `${PATH.S7CC}${PATH.PRODUCT_SELECTION}`,
  },
  TERM_LIFE: {
    INSURED_INFORMATION: `${PATH.TERM_LIFE}${PATH.INSURED_INFORMATION}`,
    COVERAGE_PLAN: `${PATH.TERM_LIFE}${PATH.COVERAGE_PLAN}`,
    BENEFIT_ILLUSTRATION: `${PATH.TERM_LIFE}${PATH.BENEFIT_ILLUSTRATION}`,
    MARKET_CONDUCTS: `${PATH.TERM_LIFE}${PATH.MARKET_CONDUCTS}`,
    NEED_AND_GAP_ANALYSIS: `${PATH.TERM_LIFE}${PATH.NEED_AND_GAP_ANALYSIS}`,
    PRODUCT_SELECTION: `${PATH.TERM_LIFE}${PATH.PRODUCT_SELECTION}`,
  },
  MRTA: {
    INSURED_INFORMATION: `${PATH.MRTA}${PATH.INSURED_INFORMATION}`,
    COVERAGE_PLAN: `${PATH.MRTA}${PATH.COVERAGE_PLAN}`,
    BENEFIT_ILLUSTRATION: `${PATH.MRTA}${PATH.BENEFIT_ILLUSTRATION}`,
    MARKET_CONDUCTS: `${PATH.MRTA}${PATH.MARKET_CONDUCTS}`,
    NEED_AND_GAP_ANALYSIS: `${PATH.MRTA}${PATH.NEED_AND_GAP_ANALYSIS}`,
    PRODUCT_SELECTION: `${PATH.MRTA}${PATH.PRODUCT_SELECTION}`,
  },
  PA: {
    INSURED_INFORMATION: `${PATH.PA}${PATH.INSURED_INFORMATION}`,
    COVERAGE_PLAN: `${PATH.PA}${PATH.COVERAGE_PLAN}`,
    BENEFIT_ILLUSTRATION: `${PATH.PA}${PATH.BENEFIT_ILLUSTRATION}`,
    MARKET_CONDUCTS: `${PATH.PA}${PATH.MARKET_CONDUCTS}`,
    NEED_AND_GAP_ANALYSIS: `${PATH.PA}${PATH.NEED_AND_GAP_ANALYSIS}`,
    PRODUCT_SELECTION: `${PATH.PA}${PATH.PRODUCT_SELECTION}`,
  },
  ESUBMISSION: `${PATH.ESUBMISSION}`,
  ESUBMISSION_FORM: `${PATH.ESUBMISSION}/form`,
  ESUBMISSION_PAYMENT: `${PATH.ESUBMISSION}/payment`,
  POLICY_LIST: `${PATH.ESUBMISSION}/list`,
  PORTAL: `${PATH.PORTAL}`,
  FNA_PRODUCT_SELECTION: `${PATH.FNA}${PATH.PRODUCT_SELECTION}`,
  PRODUCT_SELECTION: `${PATH.PRODUCT_SELECTION}`,
  QUICK_QUOTE: `${PATH.QUICK_QUOTE}${PATH.PRODUCT_SELECTION}`,
  NEED_AND_GAP_ANALYSIS: `${PATH.FNA}${PATH.NEED_AND_GAP_ANALYSIS}`,
  MARKET_CONDUCTS: `${PATH.FNA}${PATH.MARKET_CONDUCTS}`,
  REMOTE_SELLING: {
    VIDEO_CONSENT: `${PATH.ESUBMISSION}/form/video-consent`,
    REMOTE_PAYMENT: `${PATH.ESUBMISSION}/form/billing-method`,
    REMOTE_SIGNATURE: `${PATH.ESUBMISSION}/form/remote-signature`,
    SUBMIT: `${PATH.ESUBMISSION}/form/submit`,
  },
  OPTY: `${PATH.OPTY}/list`,
  BROCHURE_LIST: `${PATH.BROCHURE}/list`,
  MW_PLUS: `${PATH.MW_PLUS}/customer-profile-list`,
}

export const fnaPath = (path: string): string => `${PATH.FNA}${path}`
export const qqPath = (path: string): string => `${PATH.QUICK_QUOTE}${path}`

export default ROUTE_PATH
