import { cond, constant, defaultTo, flow, get, isEqual, isMatch, isObject, some, values } from 'lodash/fp'
import { createSelector } from 'reselect'

import { isBuddhistYear, buddhistToChristianDate } from 'core/service/lib/date'
import transform from 'e-submission/domain/data-model/form/transform'

const isGet = (value) => (key) => flow(get(key), isEqual(value))

export const isMatchRecursively = (source) => (object) =>
  isObject(source) &&
  isObject(object) &&
  (isMatch(source)(object) || some((o) => isMatchRecursively(source)(o))(values(object)))
export const isGetYes = isGet('yes')
export const isGetTrue = isGet(true)
export const isGetChecked = (path) => isGetYes(`${path}.checked.value`)
export const when = (condition, fn, elseFn = (_) => _) => (state) => {
  if (condition(state)) {
    return fn(state)
  }
  return elseFn(state)
}

export const fromBuddhistToChristian = (date) =>
  cond([
    [isBuddhistYear, buddhistToChristianDate],
    [constant(true), defaultTo('')],
  ])(date)

const cachedTransformedCurrentApp = createSelector([(state) => state], (state) => transform(state))

export const currentApp = (path) => (state) => {
  return get(path)(cachedTransformedCurrentApp(state))
}
export const currentQQ = (qqPath) => currentApp(`quickQuote.${qqPath}`)
