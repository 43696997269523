// @flow
import { Link } from 'common-components'

import MESSAGES from 'core/data-model/constants/messages'
import { AcceptFundAllocationButton } from 'quick-quote/components/accept-fund-allocation-btn'
import { ROUTE_PATH } from 'quick-quote/constants/path'
import { requireLstuFundAllocation } from 'quick-quote/product-investment/fund-allocation/lstu/selectors'
import { updateRppAcceptFundAllocation } from 'quick-quote/product-investment/fund-allocation/rpp/actions'
import { getRppAcceptFundAllocation } from 'quick-quote/product-investment/fund-allocation/rpp/selectors'
import {
  canProceedToInvestmentBenefitIllustration,
  canProceedToLstuFundAllocation,
} from 'quick-quote/product-investment/journey-control-selectors'
import type { AppState } from 'quick-quote/reducers'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'

import { compose } from 'recompose'
import { bindActionCreators } from 'redux'

type Props = {
  acceptedRppFundAllocation: boolean,
  canProceedToBenefitIllustration: boolean,
  requireLstuFundAllocation: boolean,
  updateAcceptFundAllocation: boolean,
  canProceedToLstuFundAllocation: boolean,
  disabled?: boolean,
}

export const _AcceptFundAllocationButton = (props: Props) => {
  const nextTabDisabled = props.requireLstuFundAllocation
    ? props.canProceedToLstuFundAllocation
    : props.canProceedToBenefitIllustration
  return (
    <div>
      {props.acceptedRppFundAllocation ? (
        <div>
          <Link
            style={{ pointerEvents: nextTabDisabled ? 'auto' : 'none' }}
            to={
              props.requireLstuFundAllocation
                ? ROUTE_PATH.INVESTMENT.LSTU_FUND_ALLOCATION
                : ROUTE_PATH.INVESTMENT.BENEFIT_ILLUSTRATION
            }
          >
            <Button color={nextTabDisabled ? 'primary' : 'disabled'}>
              {props.requireLstuFundAllocation ? MESSAGES.LSTU_FUND : MESSAGES.BENEFIT_ILLUSTRATION}
            </Button>
          </Link>
        </div>
      ) : (
        <div>
          <AcceptFundAllocationButton
            disabled={props.disabled}
            acceptFundAllocation={() => props.updateAcceptFundAllocation(true)}
            message={MESSAGES.SUBMIT}
          />
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  acceptedRppFundAllocation: getRppAcceptFundAllocation(state),
  canProceedToBenefitIllustration: canProceedToInvestmentBenefitIllustration(state),
  canProceedToLstuFundAllocation: canProceedToLstuFundAllocation(state),
  requireLstuFundAllocation: requireLstuFundAllocation(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  updateAcceptFundAllocation: bindActionCreators(updateRppAcceptFundAllocation, dispatch),
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(_AcceptFundAllocationButton)
