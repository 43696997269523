// @flow

import { SVGToBase64 } from 'core/service/lib/tobase64'
import MESSAGES from 'core/data-model/constants/bi-messages'
import VALUES from 'core/data-model/constants/values'
import type { HealthProps } from '../health'
import type { WholeLifeProps } from '../whole-life'
import type { ProtectionProps } from '../protection'
import type { SavingProps } from '../saving'
import type { LifeSaveProProps } from '../life-save-pro'
import type { RetirementProps } from '../retirement'
import type { MRTAProps } from '../mrta'

// FIXME: write test
export const generateProductGraph = async (basicPlanCode: string) => {
  const isWebBrowser = !window.cordova
  try {
    const rawGraphSVG = await import(
      /* webpackChunkName: "b64image" */ `!svg-inline-loader!assets/images/product-benefits/Graph-${basicPlanCode}.svg`
    ).then((m) => m.default)
    return await SVGToBase64(rawGraphSVG, isWebBrowser)
  } catch (e) {
    return ''
  }
}

// FIXME: evaluate if BIProps type can be used (medium/large)
// FIXME: write test
export const createCommonPdf = async (
  props: WholeLifeProps | HealthProps | ProtectionProps | SavingProps | LifeSaveProProps | RetirementProps | MRTAProps,
  createFullBIPdf: Function,
  createMiniBIPdf: Function
) => {
  // $$FlowFixMe
  const getDefault = (m) => m.default

  props.redLine = await import(/* webpackChunkName: "b64image" */ '!url-loader!assets/images/ktaxa-red-line.png').then(
    getDefault
  )
  // $$FlowFixMe
  props.logo = await import(/* webpackChunkName: "b64image" */ '!url-loader!assets/images/logo-ktaxa-th.png').then(
    getDefault
  )

  props.productGraph = VALUES.IHEALTHY_ULTRA_IHEALTHY_CODE.includes(props.basicPlan.code)
    ? await generateProductGraph(props.basicPlan.code)
    : await generateProductGraph(props.basicPlan.basicPlanCode)

  return props.biType === MESSAGES.BI_TYPE_FULL ? await createFullBIPdf(props) : await createMiniBIPdf(props)
}
