// @flow

import type { Version } from 'quick-quote'
import type { ViewConfiguration, FNAConfiguration } from 'core/data-model'
import type { Rider, RiderPremiumRate } from 'core/data-model/rider'
import type { Occupation, OccupationFactor } from 'core/data-model/insured'
import type { ModelFactor } from 'core/data-model/basic-plan'
import type { DisplayProduct } from 'core/service/display-product'
import type { AgentType } from 'core/data-model/identity'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export type LoginRequest = {
  type: 'LOGIN_REQUEST',
}

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export type LogoutRequest = {
  type: 'LOGOUT_REQUEST',
}

export const INITIALISE_APPLICATION_REQUEST = 'INITIALISE_APPLICATION_REQUEST'
export type InitialiseApplicationRequest = {
  type: 'INITIALISE_APPLICATION_REQUEST',
  dbLocalUrl: string,
}

export const RELOAD_APPLICATION_REQUEST = 'RELOAD_APPLICATION_REQUEST'
export type ReloadApplicationRequest = {
  type: 'RELOAD_APPLICATION_REQUEST',
}

export const UPDATE_APPLICATION_REQUEST = 'UPDATE_APPLICATION_REQUEST'
export type UpdateApplicationRequest = {
  type: 'UPDATE_APPLICATION_REQUEST',
}

export const SET_VIEW_CONFIGURATION = 'SET_VIEW_CONFIGURATION'
export type SetViewConfiguration = {
  type: 'SET_VIEW_CONFIGURATION',
  payload: ViewConfiguration,
}

export const SET_FNA_CONFIGURATION = 'SET_FNA_CONFIGURATION'
export type SetFNAConfiguration = {
  type: 'SET_FNA_CONFIGURATION',
  payload: FNAConfiguration,
}

export const SET_VERSION = 'SET_VERSION'
export type SetVersion = {
  type: 'SET_VERSION',
  payload: Version,
}

export const SET_ALLOWED_AGENT_TYPES = 'SET_ALLOWED_AGENT_TYPES'
export type SetAllowedAgentTypes = {
  type: 'SET_ALLOWED_AGENT_TYPES',
  payload: Array<AgentType>,
}

export const INITIALISE_APPLICATION_SUCCESS = 'INITIALISE_APPLICATION_SUCCESS'
export type InitialiseApplicationSuccess = {
  type: 'INITIALISE_APPLICATION_SUCCESS',
}

export const INITIALISE_APPLICATION_FAILED_WITH_NO_INTERNET = 'INITIALISE_APPLICATION_FAILED_WITH_NO_INTERNET'
export type InitialiseApplicationFailedWithNoInternet = {
  type: 'INITIALISE_APPLICATION_FAILED_WITH_NO_INTERNET',
}

export const INITIALISE_APPLICATION_FAILED_WITH_UPDATE_APP = 'INITIALISE_APPLICATION_FAILED_WITH_UPDATE_APP'
export type InitialiseApplicationFailedWithUpdateApp = {
  type: 'INITIALISE_APPLICATION_FAILED_WITH_UPDATE_APP',
}

export const INITIALISE_APPLICATION_FAILED_WITH_UPDATE_DATABASE = 'INITIALISE_APPLICATION_FAILED_WITH_UPDATE_DATABASE'
export type InitialiseApplicationFailedWithUpdateDatabase = {
  type: 'INITIALISE_APPLICATION_FAILED_WITH_UPDATE_DATABASE',
}

export const INITIALISE_APPLICATION_SERVER_IS_DOWN = 'INITIALISE_APPLICATION_SERVER_IS_DOWN'
export type InitialiseApplicationServerIsDown = {
  type: 'INITIALISE_APPLICATION_SERVER_IS_DOWN',
}

export const APP_FAILED_SECURITY_POLICY = 'APP_FAILED_SECURITY_POLICY'
export type AppFailedSecurityPolicy = {
  type: 'APP_FAILED_SECURITY_POLICY',
}

export const GENERATE_PDF_REQUEST = 'GENERATE_PDF_REQUEST'
export type GeneratePDFRequest = {
  type: 'GENERATE_PDF_REQUEST',
}

export const GENERATE_PDF_SUCCESS = 'GENERATE_PDF_SUCCESS'
export type GeneratePDFSuccess = {
  type: 'GENERATE_PDF_SUCCESS',
}

export const GENERATE_PDF_FAILURE = 'GENERATE_PDF_FAILURE'
export type GeneratePDFFailure = {
  type: 'GENERATE_PDF_FAILURE',
}

export const SET_SELECTED_REMOTE_SELLING = 'SET_SELECTED_REMOTE_SELLING'
export type SetSelectedRemoteSelling = {
  type: 'SET_SELECTED_REMOTE_SELLING',
}

export const RESET_SELECTED_REMOTE_SELLING = 'RESET_SELECTED_REMOTE_SELLING'
export type ResetSelectedRemoteSelling = {
  type: 'RESET_SELECTED_REMOTE_SELLING',
}

export const START_REMOTE_SELLING = 'START_REMOTE_SELLING'
export type StartRemoteSelling = {
  type: 'START_REMOTE_SELLING',
}

export const START_STANDARD_SELLING = 'START_STANDARD_SELLING'
export type StartStandardSelling = {
  type: 'START_STANDARD_SELLING',
}

export const CLICK_HOME_BUTTON = 'CLICK_HOME_BUTTON'
export type ClickHomeButton = {
  type: 'CLICK_HOME_BUTTON',
}

export const RESET_IS_SOCIAL = 'RESET_IS_SOCIAL'
export type ResetISSocial = {
  type: 'RESET_IS_SOCIAL',
}

export const loginRequest = (): LoginRequest => ({
  type: LOGIN_REQUEST,
})

export const logoutRequest = (): LogoutRequest => ({
  type: LOGOUT_REQUEST,
})

export const initialiseApplicationRequest = (dbLocalUrl: string): InitialiseApplicationRequest => ({
  type: INITIALISE_APPLICATION_REQUEST,
  dbLocalUrl,
})

export const setViewConfiguration = (viewConfiguration: ViewConfiguration): SetViewConfiguration => ({
  type: SET_VIEW_CONFIGURATION,
  payload: viewConfiguration,
})

export const setFnaConfiguration = (fnaConfiguration: FNAConfiguration): SetFNAConfiguration => ({
  type: SET_FNA_CONFIGURATION,
  payload: fnaConfiguration,
})

export const setAllowedAgentTypes = (allowedAgentTypes: Array<AgentType>): SetAllowedAgentTypes => ({
  type: SET_ALLOWED_AGENT_TYPES,
  payload: allowedAgentTypes,
})

export const setVersion = (version: Version): SetVersion => ({
  type: SET_VERSION,
  payload: version,
})

export const reloadApplicationRequest = (): ReloadApplicationRequest => ({
  type: RELOAD_APPLICATION_REQUEST,
})

export const updateApplicationRequest = (): UpdateApplicationRequest => ({
  type: UPDATE_APPLICATION_REQUEST,
})

export const initialiseApplicationSuccess = (): InitialiseApplicationSuccess => ({
  type: INITIALISE_APPLICATION_SUCCESS,
})

export const initialiseApplicationFailedWithNoInternet = (): InitialiseApplicationFailedWithNoInternet => ({
  type: INITIALISE_APPLICATION_FAILED_WITH_NO_INTERNET,
})

export const initialiseApplicationFailedWithUpdateApp = (): InitialiseApplicationFailedWithUpdateApp => ({
  type: INITIALISE_APPLICATION_FAILED_WITH_UPDATE_APP,
})

export const initialiseApplicationFailedWithUpdateDatabase = (): InitialiseApplicationFailedWithUpdateDatabase => ({
  type: INITIALISE_APPLICATION_FAILED_WITH_UPDATE_DATABASE,
})

export const appFailedSecurityPolicy = (): AppFailedSecurityPolicy => ({
  type: APP_FAILED_SECURITY_POLICY,
})

export const generatePDFRequest = (): GeneratePDFRequest => ({
  type: GENERATE_PDF_REQUEST,
})

export const generatePDFSuccess = (): GeneratePDFSuccess => ({
  type: GENERATE_PDF_SUCCESS,
})

export const generatePDFFailure = (): GeneratePDFFailure => ({
  type: GENERATE_PDF_FAILURE,
})

export const initialiseApplicationServerIsDown = (): InitialiseApplicationServerIsDown => ({
  type: INITIALISE_APPLICATION_SERVER_IS_DOWN,
})

export const setSelectedRemoteSelling = (): SetSelectedRemoteSelling => ({
  type: SET_SELECTED_REMOTE_SELLING,
})

export const resetSelectedRemoteSelling = (): ResetSelectedRemoteSelling => ({
  type: RESET_SELECTED_REMOTE_SELLING,
})

export const startRemoteSelling = (): StartRemoteSelling => ({
  type: START_REMOTE_SELLING,
})

export const startStandardSelling = (): StartStandardSelling => ({
  type: START_STANDARD_SELLING,
})

export const clickHomeButton = (): ClickHomeButton => ({
  type: CLICK_HOME_BUTTON,
})

export const CONNECT_TO_DATABASE_REQUEST = 'CONNECT_TO_DATABASE_REQUEST'
export type ConnectToDatabaseRequest = {
  type: 'CONNECT_TO_DATABASE_REQUEST',
  dbLocalUrl: string,
}

export const CONNECT_TO_DATABASE_SUCCESS = 'CONNECT_TO_DATABASE_SUCCESS'
export type ConnectToDatabaseSuccess = {
  type: 'CONNECT_TO_DATABASE_SUCCESS',
}

export const CONNECT_TO_DATABASE_FAILED = 'CONNECT_TO_DATABASE_FAILED'
export type ConnectToDatabaseFailed = {
  type: 'CONNECT_TO_DATABASE_FAILED',
}

export const connectToDatabaseRequest = (dbLocalUrl: string): ConnectToDatabaseRequest => ({
  type: CONNECT_TO_DATABASE_REQUEST,
  dbLocalUrl,
})

export const connectToDatabaseSuccess = (): ConnectToDatabaseSuccess => ({
  type: CONNECT_TO_DATABASE_SUCCESS,
})

export const connectToDatabaseFailed = (): ConnectToDatabaseFailed => ({
  type: CONNECT_TO_DATABASE_FAILED,
})

export const GET_PRODUCTS_REQUEST = 'PRODUCT_SELECTION/GET_PRODUCTS_REQUEST'
export type GetProductsRequest = {
  type: 'PRODUCT_SELECTION/GET_PRODUCTS_REQUEST',
}

export const GET_PRODUCTS_SUCCESS = 'PRODUCT_SELECTION/GET_PRODUCTS_SUCCESS'
export type GetProductsSuccess = {
  type: 'PRODUCT_SELECTION/GET_PRODUCTS_SUCCESS',
  payload: DisplayProduct[],
}

export const GET_PRODUCTS_FAILED = 'PRODUCT_SELECTION/GET_PRODUCTS_FAILED'
export type GetProductsFailed = {
  type: 'PRODUCT_SELECTION/GET_PRODUCTS_FAILED',
  error: Object,
}

export const getProductsRequest = (): GetProductsRequest => ({
  type: GET_PRODUCTS_REQUEST,
})

export const getProductsSuccess = (products: DisplayProduct[]): GetProductsSuccess => ({
  type: GET_PRODUCTS_SUCCESS,
  payload: products,
})

export const getProductsFailed = (error: Object): GetProductsFailed => ({
  type: GET_PRODUCTS_FAILED,
  error,
})

export const GET_RIDERS_REQUEST = 'COVERAGE_PLAN/GET_RIDERS_REQUEST'
export type GetRidersRequest = {
  type: 'COVERAGE_PLAN/GET_RIDERS_REQUEST',
}

export const GET_RIDERS_SUCCESS = 'COVERAGE_PLAN/GET_RIDERS_SUCCESS'
export type GetRidersSuccess = {
  type: 'COVERAGE_PLAN/GET_RIDERS_SUCCESS',
  payload: Rider[],
}

export const GET_RIDERS_FAILED = 'COVERAGE_PLAN/GET_RIDERS_FAILED'
export type GetRidersFailed = {
  type: 'COVERAGE_PLAN/GET_RIDERS_FAILED',
  error: Object,
}

export const getRidersRequest = (): GetRidersRequest => ({
  type: GET_RIDERS_REQUEST,
})

export const getRidersSuccess = (riders: Rider[]): GetRidersSuccess => ({
  type: GET_RIDERS_SUCCESS,
  payload: riders,
})

export const getRidersFailed = (error: Object): GetRidersFailed => ({
  type: GET_RIDERS_FAILED,
  error,
})

export const GET_OCCUPATIONS_REQUEST = 'GET_OCCUPATIONS_REQUEST'
export type GetOccupationsRequest = {
  type: 'GET_OCCUPATIONS_REQUEST',
}

export const GET_OCCUPATIONS_SUCCESS = 'GET_OCCUPATIONS_SUCCESS'
export type GetOccupationsSuccess = {
  type: 'GET_OCCUPATIONS_SUCCESS',
  payload: Occupation[],
}

export const GET_OCCUPATIONS_FAILED = 'GET_OCCUPATIONS_FAILED'
export type GetOccupationsFailed = {
  type: 'GET_OCCUPATIONS_FAILED',
  error: Object,
}

export const getOccupationsRequest = (): GetOccupationsRequest => ({
  type: GET_OCCUPATIONS_REQUEST,
})

export const getOccupationsSuccess = (occupations: Occupation[]): GetOccupationsSuccess => ({
  type: GET_OCCUPATIONS_SUCCESS,
  payload: occupations,
})

export const getOccupationsFailed = (error: Object): GetOccupationsFailed => ({
  type: GET_OCCUPATIONS_FAILED,
  error,
})

export const GET_OCCUPATION_FACTORS_REQUEST = 'COVERAGE_PLAN/GET_OCCUPATION_FACTORS_REQUEST'
export type GetOccupationFactorsRequest = {
  type: 'COVERAGE_PLAN/GET_OCCUPATION_FACTORS_REQUEST',
}

export const GET_OCCUPATION_FACTORS_SUCCESS = 'COVERAGE_PLAN/GET_OCCUPATION_FACTORS_SUCCESS'
export type GetOccupationFactorsSuccess = {
  type: 'COVERAGE_PLAN/GET_OCCUPATION_FACTORS_SUCCESS',
  payload: OccupationFactor[],
}

export const GET_OCCUPATION_FACTORS_FAILED = 'COVERAGE_PLAN/GET_OCCUPATION_FACTORS_FAILED'
export type GetOccupationFactorsFailed = {
  type: 'COVERAGE_PLAN/GET_OCCUPATION_FACTORS_FAILED',
  error: Object,
}

export const getOccupationFactorsRequest = (): GetOccupationFactorsRequest => ({
  type: GET_OCCUPATION_FACTORS_REQUEST,
})

export const getOccupationFactorsSuccess = (occupationFactors: OccupationFactor[]): GetOccupationFactorsSuccess => ({
  type: GET_OCCUPATION_FACTORS_SUCCESS,
  payload: occupationFactors,
})

export const getOccupationFactorsFailed = (error: Object): GetOccupationFactorsFailed => ({
  type: GET_OCCUPATION_FACTORS_FAILED,
  error,
})

export const GET_MODEL_FACTORS_REQUEST = 'GET_MODEL_FACTORS_REQUEST'
export type GetModelFactorsRequest = {
  type: 'GET_MODEL_FACTORS_REQUEST',
}

export const GET_MODEL_FACTORS_SUCCESS = 'GET_MODEL_FACTORS_SUCCESS'
export type GetModelFactorsSuccess = {
  type: 'GET_MODEL_FACTORS_SUCCESS',
  payload: ModelFactor[],
}

export const GET_MODEL_FACTORS_FAILED = 'GET_MODEL_FACTORS_FAILED'
export type GetModelFactorsFailed = {
  type: 'GET_MODEL_FACTORS_FAILED',
  error: Object,
}

export const getModelFactorsRequest = (): GetModelFactorsRequest => ({
  type: GET_MODEL_FACTORS_REQUEST,
})

export const getModelFactorsSuccess = (modelFactors: ModelFactor[]): GetModelFactorsSuccess => ({
  type: GET_MODEL_FACTORS_SUCCESS,
  payload: modelFactors,
})

export const getModelFactorsFailed = (error: Object): GetModelFactorsFailed => ({
  type: GET_MODEL_FACTORS_FAILED,
  error,
})

export const GET_RIDER_PREMIUM_RATES_REQUEST = 'COVERAGE_PLAN/GET_RIDER_PREMIUM_RATES_REQUEST'
export type GetRiderPremiumRatesRequest = {
  type: 'COVERAGE_PLAN/GET_RIDER_PREMIUM_RATES_REQUEST',
}

export const GET_RIDER_PREMIUM_RATES_SUCCESS = 'COVERAGE_PLAN/GET_RIDER_PREMIUM_RATES_SUCCESS'
export type GetRiderPremiumRatesSuccess = {
  type: 'COVERAGE_PLAN/GET_RIDER_PREMIUM_RATES_SUCCESS',
  payload: RiderPremiumRate[],
}

export const GET_RIDER_PREMIUM_RATES_FAILED = 'COVERAGE_PLAN/GET_RIDER_PREMIUM_RATES_FAILED'
export type GetRiderPremiumRatesFailed = {
  type: 'COVERAGE_PLAN/GET_RIDER_PREMIUM_RATES_FAILED',
  error: Object,
}

export const getRiderPremiumRatesRequest = (): GetRiderPremiumRatesRequest => ({
  type: GET_RIDER_PREMIUM_RATES_REQUEST,
})

export const getRiderPremiumRatesSuccess = (premiumRates: RiderPremiumRate[]): GetRiderPremiumRatesSuccess => ({
  type: GET_RIDER_PREMIUM_RATES_SUCCESS,
  payload: premiumRates,
})

export const getRiderPremiumRatesFailed = (error: Object): GetRiderPremiumRatesFailed => ({
  type: GET_RIDER_PREMIUM_RATES_FAILED,
  error,
})

export const PROCESSING_APP_DATA_REQUEST = 'PROCESSING_APP_DATA_REQUEST'
export const PROCESSING_APP_DATA_SUCCESS = 'PROCESSING_APP_DATA_SUCCESS'
export const PROCESSING_APP_DATA_UNSET = 'PROCESSING_APP_DATA_UNSET'
export const BACK_TO_HOME = 'BACK_TO_HOME'
export const MANAGE_LOGIN_SESSION = 'MANAGE_LOGIN_SESSION'
export const CONFIRM_EKYC = 'CONFIRM_EKYC'
export const CANCEL_EKYC = 'CANCEL_EKYC'
export const CONFIRM_IPRO_FNA = 'CONFIRM_IPRO_FNA'
export const CANCEL_NETWORK_MODAL = 'CANCEL_NETWORK_MODAL'
export const CONFIRM_SUBMIT = 'CONFIRM_SUBMIT'
export const CANCEL_SUBMIT = 'CANCEL_SUBMIT'

export type ProcessingApp = {
  type: 'PROCESSING_APP_DATA_REQUEST',
}
export type ProcessingAppSuccess = {
  type: 'PROCESSING_APP_DATA_SUCCESS',
}
export type ProcessingAppUnset = {
  type: 'PROCESSING_APP_DATA_UNSET',
}
export const setProcessingApp = () => ({
  type: PROCESSING_APP_DATA_REQUEST,
})
export const setProcessingSuccess = () => ({
  type: PROCESSING_APP_DATA_SUCCESS,
})
export const unsetProcessing = () => ({
  type: PROCESSING_APP_DATA_UNSET,
})
export const backToHome = () => ({
  type: BACK_TO_HOME,
})
export const confirmEKYC = () => ({
  type: CONFIRM_EKYC,
})
export const cancelEKYC = () => ({
  type: CANCEL_EKYC,
})

export const confirmIproFNA = () => ({
  type: CONFIRM_IPRO_FNA,
})

export const confirmSubmit = () => ({
  type: CONFIRM_SUBMIT,
})

export const cancelSubmit = () => ({
  type: CANCEL_SUBMIT,
})

export const cancelNetworkModal = () => ({
  type: CANCEL_NETWORK_MODAL,
})

export const goToManageLoginSession = () => ({
  type: MANAGE_LOGIN_SESSION,
})

export const RESET_QUOTE_FORM = 'RESET_QUOTE_FORM'
export type ResetQuoteForm = {
  type: 'RESET_QUOTE_FORM',
}
export const resetQuoteForm = (): ResetQuoteForm => ({
  type: RESET_QUOTE_FORM,
})

export const RESET_DISPLAY_PRODUCT = 'RESET_DISPLAY_PRODUCT'
export type ResetDisplayProduct = {
  type: 'RESET_DISPLAY_PRODUCT',
}
export const resetDisplayProduct = (): ResetDisplayProduct => ({
  type: RESET_DISPLAY_PRODUCT,
})

// These functions are for testing purpose
export const RESET_APPLICATION_STATE = 'RESET_APPLICATION_STATE'
export type ResetApplicationState = {
  type: 'RESET_APPLICATION_STATE',
}
export const _resetApplicationState_ = (): ResetApplicationState => ({
  type: RESET_APPLICATION_STATE,
})

export const UPDATE_SEND_EKYC_LINK_METHOD = 'UPDATE_SEND_EKYC_LINK_METHOD'
export const mediaActions = {
  TOGGLE_MIC: 'TOGGLE_MIC',
  TOGGLE_CAMERA: 'TOGGLE_CAMERA',
  TOGGLE_SCREEN: 'TOGGLE_SCREEN',
  TOGGLE_SHOW_CONTROLS: 'TOGGLE_SHOW_CONTROLS',
  RESET_MEDIA_CONTROL_STATE: 'RESET_MEDIA_CONTROL_STATE',
  HANGUP: 'HANGUP',
  GET_MEDIA_STATUS: 'GET_MEDIA_STATUS',
  SET_MEDIA_CONTROL_STATE: 'SET_MEDIA_CONTROL_STATE',
  OPEN_DF2F_APPLICATION: 'OPEN_DF2F_APPLICATION',
  CHECK_VERSION_APP: 'CHECK_VERSION_APP',
  UPDATE_MEDIA_STATE: 'UPDATE_MEDIA_STATE',
  SYNC_MEDIA_STATE: 'SYNC_MEDIA_STATE',
}
export const OPEN_DF2F_APPLICATION = 'OPEN_DF2F_APPLICATION'
export const CHECK_VERSION_APP = 'CHECK_VERSION_APP'
export const HANGUP = 'HANGUP'
export const GET_MEDIA_STATUS = 'GET_MEDIA_STATUS'
export const SET_MEDIA_CONTROL_STATE = 'SET_MEDIA_CONTROL_STATE'
export const UPDATE_MEDIA_STATE = 'UPDATE_MEDIA_STATE'
export const setMediaControlState = (payload) => ({
  type: mediaActions.SET_MEDIA_CONTROL_STATE,
  payload,
})
export type MediaControlState = {
  micState: boolean,
  cameraState: boolean,
  screenState: boolean,
  showControls: boolean,
}
type mediaControl = mediaActions.TOGGLE_CAMERA | mediaActions.TOGGLE_MIC | mediaActions.TOGGLE_SCREEN
export const updateMediaState = (payload: mediaControl) => ({
  type: mediaActions.UPDATE_MEDIA_STATE,
  payload,
})

export const syncMediaState = () => ({
  type: mediaActions.SYNC_MEDIA_STATE,
})
export const hangup = () => ({
  type: mediaActions.HANGUP,
})
export const getMediaStatus = () => ({
  type: mediaActions.GET_MEDIA_STATUS,
})

export const openDf2fApplication = (payload = {}) => ({
  type: mediaActions.OPEN_DF2F_APPLICATION,
  payload,
})

export const resetMediaControlState = () => ({
  type: mediaActions.RESET_MEDIA_CONTROL_STATE,
})

export const checkVersionAppUpdate = (payload = {}) => ({
  type: mediaActions.CHECK_VERSION_APP,
  payload,
})

export const resetISSocial = (): ResetISSocial => ({
  type: RESET_IS_SOCIAL,
})
