//@flow

import MESSAGES from 'core/data-model/constants/bi-messages'

export const basicPlanConditionISmartSection = () => ({
  stack: [
    {
      text: MESSAGES.CONDITION_TITLE,
      style: 'title',
      pageBreak: 'before',
    },
    {
      text: MESSAGES.COMPLETED_TITLE,
      style: 'sub-title',
    },
    {
      text: MESSAGES.I_SMART_GENERAL_CONDITION_COMPLETED_MESSAGE_ONE,
      style: 'textRow',
    },
    {
      text: MESSAGES.I_SMART_GENERAL_CONDITION_COMPLETED_MESSAGE_TWO,
      style: 'textRow',
    },
    {
      text: MESSAGES.I_SMART_GENERAL_CONDITION_COMPLETED_MESSAGE_THREE,
      style: 'textRow',
    },
    {
      text: MESSAGES.NO_COVER_TITLE,
      style: 'sub-title',
    },
    {
      ol: [
        [
          {
            text: MESSAGES.I_SMART_GENERAL_CONDITION_NO_COVER_MESSAGE_BULLET_ONE_ONE,
            style: 'textRow',
          },
          {
            text: MESSAGES.I_SMART_GENERAL_CONDITION_NO_COVER_MESSAGE_BULLET_ONE_TWO,
            style: 'textRow',
          },
        ],
        [
          {
            text: MESSAGES.I_SMART_GENERAL_CONDITION_NO_COVER_MESSAGE_BULLET_TWO,
            style: 'textRow',
          },
        ],
        [
          {
            text: MESSAGES.I_SMART_GENERAL_CONDITION_NO_COVER_MESSAGE_BULLET_THREE,
            style: 'textRow',
          },
        ],
      ],
      style: 'textRow',
    },
    {
      text: MESSAGES.CLAIM_TITLE,
      style: 'sub-title',
    },
    {
      text: MESSAGES.CLAIM_MESSAGE,
      style: 'textRow',
    },
    {
      text: MESSAGES.FREELOOK_TITLE,
      style: 'sub-title',
    },
    {
      text: MESSAGES.FREELOOK_MESSAGE,
      style: 'textRow',
    },
    {
      text: MESSAGES.DELIVERY_PERIOD_TITLE,
      style: 'sub-title',
    },
    {
      text: MESSAGES.DELIVERY_PERIOD_MESSAGE,
      style: 'textRow',
    },
    {
      text: MESSAGES.CHANNEL_TITLE,
      style: 'sub-title',
    },
    {
      text: MESSAGES.CHANNEL_MESSAGE,
      style: 'textRow',
    },
  ],
  style: 'groupRow',
})
