import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { toClass } from 'recompose'
import _ from 'lodash'

/**
 * Tab, Tab container
 */
const TabPropTypes = {
  children: PropTypes.node,
  active: PropTypes.string,
  onChange: PropTypes.func,
}

const TabDefaultProps = {
  children: null,
  active: null,
  onChange() {},
}

/**
 * Tab Item
 */
const TabItemPropTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  active: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
}
const TabItemDefaultProps = {
  id: '',
  children: '',
  active: false,
  disabled: false,
  className: '',
  onClick() {},
}

/**
 * Tab
 */
class Tab extends React.Component {
  constructor(props) {
    super(props)

    this.refNav = null
    this.refTabList = null
    this.refTabActiveBar = null
    this.refTabItem = {}
    this.setRefNav = (el) => (this.refNav = el)
    this.setRefTabList = (el) => (this.refTabList = el)
    this.setRefTabActiveBar = (el) => (this.refTabActiveBar = el)
  }

  setActive = (id) => {
    const { onChange } = this.props
    onChange(id)
    if (document.body.classList.contains('modal-open')) {
      document.body.classList.remove('modal-open')
    }
  }

  scrollElementToView = (id) => {
    const refNode = this.refs[id]
    if (refNode) {
      const nodeEl = ReactDOM.findDOMNode(refNode)
      const leftPos = Math.max(_.get(nodeEl, 'offsetLeft', 0), 0)
      this.refNav.scrollTo(leftPos, 0)
    }
  }

  scrollTabActiveBar = (id) => {
    const activeBar = this.refTabActiveBar
    const refNode = this.refs[id]
    if (activeBar && refNode) {
      const activeBarEl = ReactDOM.findDOMNode(activeBar)
      const nodeEl = ReactDOM.findDOMNode(refNode)
      const l = Math.max(_.get(nodeEl, 'offsetParent.offsetLeft', 0) + _.get(nodeEl, 'offsetLeft'), 0)
      const r = _.get(activeBarEl, 'offsetParent.clientWidth', 0) - (l + _.get(nodeEl, 'clientWidth', 0))

      activeBar.style.left = `${l}px`
      activeBar.style.right = `${r}px`
    }
  }

  isElementVisible = (el) => {
    if (!el) return false

    const [...vw] = [Math.max(document.documentElement.clientWidth, window.innerWidth)]
    const rect = el.getBoundingClientRect()
    return !(rect.x < 0 || vw - rect.x <= rect.width)
  }

  debounceCalculateListWidth = _.debounce(() => {
    this.calculateListWidth()
    this.scrollTabActiveBar(this.props.active)
  }, 100)

  debounceScrollElementToView = _.debounce(() => {
    this.scrollElementToView(this.props.active)
  }, 1000)

  calculateListWidth() {
    const tabList = this.refTabList
    if (tabList) {
      tabList.style.width = 'auto'
      let listWidth = 0
      const listItems = tabList.children || []
      for (const item of listItems) {
        listWidth += item.clientWidth
      }

      tabList.style.width = `${listWidth}px`
    }
  }

  componentDidMount() {
    this.debounceCalculateListWidth()
    this.debounceScrollElementToView()
    window.addEventListener('resize', this.debounceCalculateListWidth)
    window.addEventListener('resize', this.debounceScrollElementToView)
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(this.props.active, prevProps.active)) {
      this.scrollElementToView(this.props.active)
      this.scrollTabActiveBar(this.props.active)
    }
    if (!_.isEqual(_.size(this.props.children), _.size(prevProps.children))) {
      this.debounceCalculateListWidth()
      this.debounceScrollElementToView()
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.debounceCalculateListWidth)
    window.removeEventListener('resize', this.debounceScrollElementToView)
  }

  render() {
    const { children, className, active } = this.props
    return (
      <div className={['az-tab', className].join(' ')}>
        <div className="az-tab__container">
          <nav ref={this.setRefNav}>
            <span className="az-tab__active-bar" ref={this.setRefTabActiveBar} />
            <ul className="az-tab__list" ref={this.setRefTabList}>
              {React.Children.map(children, (child) => {
                if (child.type.displayName === 'TabItem') {
                  return React.cloneElement(child, {
                    ...child.props,
                    active: active === child.props.id,
                    onClick: !child.props.disabled && active !== child.props.id ? this.setActive : () => {},
                    ref: child.props.id,
                  })
                }
                return child
              })}
            </ul>
          </nav>
        </div>
      </div>
    )
  }
}

const TabItem = (props) => {
  const { children, active, disabled, id, className, onClick } = props
  const tabItemCssClass = ['az-tab__item']
  if (active) tabItemCssClass.push('az-tab__item--active')
  if (disabled) tabItemCssClass.push('az-tab__item--disabled')
  if (className) tabItemCssClass.push(className)

  return (
    <li data-id={id} className={tabItemCssClass.join(' ')} onClick={() => onClick(id)}>
      {children}
    </li>
  )
}

Tab.displayName = 'Tab'
Tab.propTypes = TabPropTypes
Tab.defaultProps = TabDefaultProps

TabItem.displayName = 'TabItem'
TabItem.propTypes = TabItemPropTypes
TabItem.defaultProps = TabItemDefaultProps

Tab.Item = toClass(TabItem)

export default Tab
