// @flow
import type { Rider, RiderState } from 'core/data-model/rider'
import RiderWithBenefitModal from './rider-benefit-modal'
import RiderBenefitSummary from './rider-benefit-summary'
import MESSAGES from 'core/data-model/constants/bi-messages'
import { AgentProtectionDisclaimer } from './agent-protection-disclaimer'

type PBPDDCIRiderBenefitProps = {
  rider: Rider & RiderState,
  sumAssured: number,
}

// const renderImportantExceptionNotes = () => {
//   const exceptionNotes = getPBPDDCIExceptionNotes()
//   const exceptionTitle = exceptionNotes.title
//   const [
//     firstExceptionTitle,
//     firstExceptionDetail,
//     secondExceptionTitle,
//     secondExceptionDetail,
//     secondExceptionDetailExtend,
//     thirdExceptionTitle,
//     thirdExceptionDetail,
//   ] = exceptionNotes.exceptions

//   const renderSubItem = (data: string[]) => {
//     return data.map((dataRow, index) => {
//       return (
//         <div className="list-cell" key={`data_${index}`}>
//           &emsp;&ensp;&ensp;<span>{`(${index + 1})`}</span>
//           <span dangerouslySetInnerHTML={{ __html: dataRow }} />
//         </div>
//       )
//     })
//   }

//   return (
//     <div id="meh-exception-note32">
//       <p></p>
//       <b>{exceptionTitle}</b>
//       <div>{firstExceptionTitle}</div>
//       <ol>{renderListItems(firstExceptionDetail, 0, 1)}</ol>
//       <div>{secondExceptionTitle}</div>
//       <ol style={{ 'margin-bottom': '0' }}>{renderListItems(secondExceptionDetail, 0, 7)}</ol>
//       <div>{renderSubItem(secondExceptionDetailExtend)}</div>
//       <p></p>
//       <div>{thirdExceptionTitle}</div>
//       <ol>{renderListItems(thirdExceptionDetail, 0, 4)}</ol>
//     </div>
//   )
// }

const PBPDDCIRiderBenefit = ({ rider, sumAssured }: PBPDDCIRiderBenefitProps) => {
  const description = `ผลประโยชน์ ${MESSAGES.PB_BEYOND_NAME}`
  const benefitTable = (
    <div className="details-table">
      <p>{MESSAGES.PBPDDCI_RIDER_DETAIL}</p>
      <p>{MESSAGES.PB_RIDER_ADDITIONAL_DETAIL}</p>
      {sumAssured > rider.defaultSumAssured ? <p className="remark"> {MESSAGES.PB_BENEFIT_REMARK} </p> : ''}
    </div>
  )

  const benefitRider: Rider & RiderState = { ...rider, description }
  return (
    <RiderWithBenefitModal
      id="pbpddci-rider-benefit"
      className="rider pbpddci-rider"
      title={description}
      modalChildren={benefitTable}
    >
      <RiderBenefitSummary rider={benefitRider} />
      {benefitTable}
      {/* {renderImportantExceptionNotes()} */}
      <AgentProtectionDisclaimer rider={rider} />
    </RiderWithBenefitModal>
  )
}

export default PBPDDCIRiderBenefit
