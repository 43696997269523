import styled from 'styled-components'
import { QRCodeCanvas } from 'qrcode.react'
import { Flex } from 'rebass'
import { compose, mapProps, withHandlers } from 'recompose'
import ktaxaLogo from '!url-loader!assets/images/logo-ktaxa-th-bordered.png'
import Button from 'common-components/button'
import { hideDialog, sendQrPaymentEvent, showSubmitPaymentDialog } from 'e-submission/apps/actions'
import { connect } from 'react-redux'
import { getToggles } from 'quick-quote/feature-toggles'
import PayerInfo from 'e-submission/components/Form/Payment/PayerInfo'

const Container = styled(({ show, remoteSelling, ...props }) => <Flex {...props} />)`
  transition: all 0.3s ease-in-out;
  overflow: ${({ isQRCodeNetbank }) => (isQRCodeNetbank ? 'visible' : 'hidden')};
  height: ${({ show, remoteSelling, isEnabledECBRSystemization }) =>
    show ? (remoteSelling || isEnabledECBRSystemization ? 'auto' : '240px') : '0'};
`

const QRWrapper = styled(({ show, ...props }) => <Flex {...props} />)`
  opacity: ${({ show }) => (show ? 1 : 0)};
`

const Header = styled.label`
  font-weight: bold;
`

export const Component = (props) => {
  const { value, show, remoteSelling, onClick, amount, isQRCodeNetbank } = props
  const isEnabledECBRSystemization = getToggles().ENABLE_ECBR_SYSTEMIZATION
  console.log('QRPayment props', props)
  return (
    <Container
      column
      show={show}
      remoteSelling={remoteSelling}
      isEnabledECBRSystemization={isEnabledECBRSystemization}
      isQRCodeNetbank={isQRCodeNetbank}
    >
      <QRWrapper id="paymentQrWrapper" show={show} mt={30} justify="center">
        <QRCodeCanvas
          value={value}
          size={200}
          bgColor={'#ffffff'}
          fgColor={'#000000'}
          level={'H'}
          includeMargin={false}
          imageSettings={{
            src: `${ktaxaLogo}`,
            x: undefined,
            y: undefined,
            height: 40,
            width: 80,
            excavate: true,
          }}
        />
      </QRWrapper>

      {isEnabledECBRSystemization ? <PayerInfo /> : null}

      {remoteSelling ? (
        <Flex mt={10}>
          <Flex width={1} justify="center">
            <Button onClick={onClick} disabled={parseFloat(amount) === 0}>
              ส่งคำสั่งชำระเบี้ย
            </Button>
          </Flex>
        </Flex>
      ) : null}
    </Container>
  )
}
Component.displayName = 'QRPayment'

const mapDispatchToProps = (dispatch) => ({
  showLoading: () => {
    dispatch(showSubmitPaymentDialog())
  },
  hideLoading: () => {
    dispatch(hideDialog())
  },
  sendPaymentEvent: (payload) => {
    dispatch(sendQrPaymentEvent(payload))
  },
})

export default compose(
  mapProps((props) => ({
    ...props,
    show: !!props.value,
    value: props.value.toString(),
  })),
  connect(null, mapDispatchToProps),
  withHandlers(({ showLoading, sendPaymentEvent }) => {
    return {
      onClick: ({ payload }) => () => {
        showLoading()
        sendPaymentEvent(payload)
      },
    }
  })
)(Component)
