// @flow
import type { AppState } from 'quick-quote/reducers'

import BenefitIllustration from './components/benefit-illustration'
import { isBancAgent } from 'identity/selectors'
import { connect } from 'react-redux'
import { isBlockedPdfGeneration, isBlockedEnteringESub, isReviseQQFlow } from 'quick-quote/selectors'
import { getSelectedDisplayProduct } from 'quick-quote/product-selection/selectors'
import {
  getPolicyValues,
  getHasNonLevelRider,
  getLegalDisclaimerEndDate,
  getBenefitSummary,
  getTaxDeduction,
} from 'quick-quote/product-whole-life/benefit-illustration/selectors'
import { getGenderLabel, getAge } from 'quick-quote/insured-information/selectors'
import {
  getTotalRiderPremium,
  getcoveragePlanStartDate,
  getSelectedModelFactorID,
  getSelectedModelFactorLabel,
  getSelectedModelFactorPeriod,
  getTotalPremium,
  getBasicPremium$,
  getSelectedRiders,
} from 'quick-quote/product-common/coverage-plan/selectors'
import { canProceedToBenefitIllustration } from 'quick-quote/product-whole-life/coverage-plan/selectors'
import {
  getCalculatedCoveragePeriod,
  getCalculatedPaymentPeriod,
  getHasShowDisclaimerRiderAsterisk,
} from 'quick-quote/product-common/benefit-illustration/selectors'

import {
  getLoanAmount,
  getLoanPeriod,
  getInterestRateValue,
  getSumAssured,
  getPaymentMethod,
  getCoveragePeriod,
  getPremiumPerMonthGLTSP,
  getSumAssuredContract,
} from 'quick-quote/product-mrta/coverage-plan/selectors'

const mapStateToProps = (state: AppState) => ({
  displayProduct: getSelectedDisplayProduct(state),
  riders: getSelectedRiders(state),
  genderLabel: getGenderLabel(state),
  age: getAge(state),
  loanAmount: getLoanAmount(state),
  loanPeriod: getLoanPeriod(state),
  interestRate: getInterestRateValue(state),
  premiumPerMonthGLTSP: getPremiumPerMonthGLTSP(state),
  sumAssured: getSumAssured(state),
  paymentMethod: getPaymentMethod(state),
  coveragePeriod: getCoveragePeriod(state),
  selectedModelFactorID: getSelectedModelFactorID(state),
  selectedModelFactorLabel: getSelectedModelFactorLabel(state),
  selectedModelFactorPeriod: getSelectedModelFactorPeriod(state),
  calculatedCoveragePeriod: getCalculatedCoveragePeriod(state),
  calculatedPaymentPeriod: getCalculatedPaymentPeriod(state),
  hasNonLevelRider: getHasNonLevelRider(state),
  hasShowDisclaimerRiderAsterisk: getHasShowDisclaimerRiderAsterisk(state),
  totalPremium: getTotalPremium(state),
  totalRiderPremium: getTotalRiderPremium(state),
  basicPremium$: getBasicPremium$(state),
  coveragePlanStartDate: getcoveragePlanStartDate(state),
  legalDisclaimerEndDate: getLegalDisclaimerEndDate(state),
  policyValue: getPolicyValues(state),
  benefitSummary: getBenefitSummary(state),
  canProceedToBenefitIllustration: canProceedToBenefitIllustration(state),
  isBlockedPdfGeneration: isBlockedPdfGeneration(state),
  isBlockedEnteringESub: isBlockedEnteringESub(state),
  taxDeduction: getTaxDeduction(state),
  isBancAgent: isBancAgent(state),
  sumAssuredContract: getSumAssuredContract(state),
  isReviseQQ: isReviseQQFlow(state),
})

const mapDispatchToProps = () => ({})

export const BenefitIllustrationContainer = connect(mapStateToProps, mapDispatchToProps)(BenefitIllustration)
