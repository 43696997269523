//@flow
import Mustache from 'mustache'
import MESSAGES from 'core/data-model/constants/bi-messages'
import { buildRiderWithPlansBenefitTable } from 'core/service/pdf-generation/products/common/sections/riders/common'

import { getHICExceptionNotes } from 'core/service/rider/benefits/exception-notes'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { RiderBenefitData } from 'core/data-model/rider/benefits'

type HICBenefitProps = {
  rider: Rider & RiderState,
  riderBenefitData: RiderBenefitData,
}

const buildExceptionNotes = () => {
  const exceptionNotes = getHICExceptionNotes()

  return {
    stack: [
      { text: MESSAGES.EXCEPTION_NOTE, style: 'sub-title' },
      { text: exceptionNotes.title },
      { ol: exceptionNotes.exceptions },
      { text: exceptionNotes.condition },
    ],
  }
}
export const HICBenefitSection = ({ rider, riderBenefitData }: HICBenefitProps) => {
  const disclaimer = Mustache.render(MESSAGES.DISCLAIMER_RIDER_BENEFIT, {
    riderCode: rider.code,
  })

  const exceptionNotes = buildExceptionNotes()
  return [
    {
      table: buildRiderWithPlansBenefitTable({ rider, riderBenefitData }),
      layout: 'lightBorder',
      style: 'table',
    },
    {
      text: disclaimer,
      style: 'disclaimer',
    },
    { ...exceptionNotes, marginTop: 10 },
  ]
}
