// @flow
import type { AppState } from 'quick-quote/reducers'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { RadioButtonGroup } from 'common-components'
import { selectProductCancerX5 } from 'quick-quote/product-life-ready/actions'

import { getSelectedDisplayProductCode } from 'quick-quote/product-selection/selectors'
import BIMESSAGES from 'core/data-model/constants/bi-messages'

export const CancerX5Selection = ({ getSelectedDisplayProductCode, selectProductCancerX5 }) => {
  const productCancerX5 = [
    {
      text: BIMESSAGES.CANCERX5_1_QQ_PLANNAME,
      value: BIMESSAGES.CANCERX5_1_PLANCODE,
    },
    {
      text: BIMESSAGES.CANCERX5_2_QQ_PLANNAME,
      value: BIMESSAGES.CANCERX5_2_PLANCODE,
    },
    {
      text: BIMESSAGES.CANCERX5_3_QQ_PLANNAME,
      value: BIMESSAGES.CANCERX5_3_PLANCODE,
    },
  ]

  return (
    <div className="form-group">
      <div className="form-group-title">
        <span className="form-group-title-text">{BIMESSAGES.QQ_SELECT_PLAN}</span>
      </div>
      <RadioButtonGroup
        keyToGetValue="value"
        className="model-factor"
        onChange={selectProductCancerX5}
        value={getSelectedDisplayProductCode}
        options={productCancerX5}
      />
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  getSelectedDisplayProductCode: getSelectedDisplayProductCode(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  selectProductCancerX5: bindActionCreators(selectProductCancerX5, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CancerX5Selection)
