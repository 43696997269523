//@flow

import { call, put, takeEvery, select } from 'redux-saga/effects'
import { updateHealthCheckList, updateFinancialCheckList } from './actions'
import { SELECTED_PRODUCT } from '../../product-selection/actions'
import type { DisplayProduct } from 'core/service/display-product'
import { getSelectedDisplayProduct } from 'quick-quote/product-selection/selectors'
import constants from 'core/data-model/constants/defaults'

import { getFinancialDocument, getHealthCheckItem } from 'core/service/loan/loan-document-list'

export function* onGetDocumentListRequest(): Generator<*, *, *> {
  const displayProductQuery: DisplayProduct = yield select(getSelectedDisplayProduct)
  if (displayProductQuery.category !== constants.LOAN_PRODUCT_CATEGORY) return
  const financialDocumentList = yield call(getFinancialDocument)
  const healthCheckList = yield call(getHealthCheckItem)

  yield put(updateHealthCheckList(healthCheckList))
  yield put(updateFinancialCheckList(financialDocumentList))
}

export function* sagas(): Generator<*, *, *> {
  yield [takeEvery(SELECTED_PRODUCT, onGetDocumentListRequest)]
}
