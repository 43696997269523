import { Link, withRouter } from 'react-router-dom'
import { fnaPath, qqPath } from 'quick-quote/constants/path'

import { isFNAPath, isQuickQuotePath } from 'quick-quote/components/navigations/utils'

const FlowAwareLink = (props) => {
  const pathname = props.location.pathname
  const to = isFNAPath(pathname) ? fnaPath(props.to) : isQuickQuotePath(pathname) ? qqPath(props.to) : props.to
  return <Link {...props} to={to} />
}

export default withRouter(FlowAwareLink)
