// @flow
import PATH from 'quick-quote/constants/path'
import { Redirect } from 'react-router-dom'

import { InsuredInformationBreadcrumb } from 'quick-quote/components'
import ModelFactorSelection from 'quick-quote/product-common/coverage-plan/components/basic-plan/model-factor-selection'
import BasicPlan from 'quick-quote/product-common/coverage-plan/components/basic-plan/basic-plan'
import RiderGroup from 'quick-quote/product-common/coverage-plan/components/rider/rider-group'
import TotalPremiumInput from 'quick-quote/product-common/coverage-plan/components/basic-plan/total-premium-input'
import BasicPlanSelection from 'quick-quote/product-protection/coverage-plan/components/basic-plan-selection'

import type { RiderPlan } from 'core/data-model/rider'
import type { Option } from 'common-components/dropdown'

export type CoveragePlanProps = CoveragePlanState & CoveragePlanDispatchers

export type CoveragePlanState = {
  riderCodes: string[],
  canProceedToCoveragePlan: boolean,
  basicPlanValidationError: string,
  basicPlanOptions: Option[],
  selectedBasicPlanCode: string,
  isDiscountSelected: boolean,
}

export type CoveragePlanDispatchers = {
  toggleRider: (string, boolean) => void,
  editRiderSumAssured: (string, number) => void,
  editRiderSelectedPlan: (string, RiderPlan) => void,
  toggleDiscount: () => void,
}

const CoveragePlan = ({
  toggleRider,
  riderCodes,
  canProceedToCoveragePlan,
  editRiderSumAssured,
  editRiderSelectedPlan,
  basicPlanOptions,
  selectedBasicPlanCode,
  isDiscountSelected,
  toggleDiscount,
  basicPlanValidationError,
}: CoveragePlanProps) => {
  if (!canProceedToCoveragePlan) {
    return <Redirect to={PATH.PRODUCT_SELECTION} push />
  }

  return (
    <div>
      <InsuredInformationBreadcrumb />
      <div id="coverage-plan">
        <div className="content">
          <div id="basic-plan">
            <div className="content">
              <ModelFactorSelection />
              <BasicPlanSelection
                basicPlanOptions={basicPlanOptions}
                editRiderSelectedPlan={editRiderSelectedPlan}
                selectedBasicPlanCode={selectedBasicPlanCode}
                isDiscountSelected={isDiscountSelected}
                toggleDiscount={toggleDiscount}
                basicPlanValidationError={basicPlanValidationError}
              />
              <BasicPlan />
            </div>
          </div>
          <div id="total-premium">
            <TotalPremiumInput />
          </div>
          <RiderGroup
            riderCodes={riderCodes}
            toggleRider={toggleRider}
            editRiderSumAssured={editRiderSumAssured}
            editRiderSelectedPlan={editRiderSelectedPlan}
          />
        </div>
      </div>
    </div>
  )
}

export default CoveragePlan
