import ACTIONS from './action-types'

const initialAppVersion = {
  fetching: false,
  installing: false,
  error: null,
}

export const appVersion = (state = initialAppVersion, action = '') => {
  switch (action.type) {
    case ACTIONS.RESET_APPLICATION_FETCH_UPDATE:
      return { ...initialAppVersion }
    case ACTIONS.APPLICATION_FETCH_UPDATE:
      return { ...initialAppVersion, fetching: true }
    case ACTIONS.APPLICATION_FETCH_UPDATE_SUCCESS:
      return { ...state, fetching: false, error: undefined }
    case ACTIONS.APPLICATION_FETCH_UPDATE_ERROR:
      return { ...state, fetching: false, error: action.payload.error }
    case ACTIONS.APPLICATION_INSTALL_UPDATE:
      return { ...state, installing: true, error: undefined }
    case ACTIONS.APPLICATION_INSTALL_UPDATE_SUCCESS:
      return { ...state, installing: false, error: undefined }
    case ACTIONS.APPLICATION_INSTALL_UPDATE_ERROR:
      return { ...state, installing: false, error: action.payload.error }
    case ACTIONS.APPLICATION_CHECK_VERSION_INVALIDVERSION:
      return { ...state, error: action.payload.error }
  }
  return state
}
