// @flow
import { connect } from 'react-redux'
import type { Rider, RiderState, RiderPlan } from 'core/data-model/rider'
import type { AppState } from 'quick-quote/reducers'
import _ from 'lodash'
import { getAvailableRidersByCode } from 'quick-quote/product-common/coverage-plan/selectors'
import { getSelectedDisplayProduct } from 'quick-quote/product-selection/selectors'
import { getPayerRelation } from 'quick-quote/insured-information/selectors'
import { isValidPayerRelation } from 'core/service/rider/rider'

import { FormGroup } from 'reactstrap'

import RiderMessage from './rider-validation-message'
import { Checkbox } from 'common-components'

import RiderPlanName from './rider-plan-name'
import RiderSumAssured from './rider-sum-assured'
import RiderPremium from './rider-premium'
import values from 'core/data-model/constants/values'

import { MEHRider } from 'quick-quote/product-health/coverage-plan/components'
import { MHPRider, MHPRiderCheckbox } from 'quick-quote/product-ihealthy-ultra/coverage-plan/components'

import { FHRider } from 'quick-quote/product-flexi-health/coverage-plan/components'

import PARider from 'quick-quote/product-pa/coverage-plan/components/riders/pa-rider-plan-list'

type StateProps = {
  rider: Rider & RiderState,
  displayProduct: DisplayProduct,
  payerRelation: string,
}
type RiderProps = {
  rider: Rider & RiderState,
  displayProduct: DisplayProduct,
  payerRelation: string,
  toggleRider: (string, boolean) => void,
  toggleRiderModule?: (string, string, boolean) => void,
  toggleRiderGroupModule?: (string, string, boolean) => void,
  editRiderSumAssured: (string, number) => void,
  editRiderSelectedPlan: (string, RiderPlan) => void,
}

type OwnProps = {
  code: string,
  toggleRider: (string, boolean) => void,
  toggleRiderModule?: (string, string, boolean) => void,
  toggleRiderGroupModule?: (string, string, boolean) => void,
  editRiderSumAssured: (string, number) => void,
  editRiderSelectedPlan: (string, RiderPlan) => void,
}

export const RiderComponent = ({
  rider,
  displayProduct,
  payerRelation,
  editRiderSumAssured,
  editRiderSelectedPlan,
  toggleRider,
  toggleRiderModule,
  toggleRiderGroupModule,
}: RiderProps) => {
  if (!rider) {
    return null
  }
  if (!isValidPayerRelation(rider, payerRelation)) {
    return null
  }

  const eligibilityErrors = rider.errors.filter((e) => e.type === values.ELIGIBILITY).map((e) => e.message)

  const validationErrors = rider.isSelected
    ? rider.errors.filter((e) => e.type === values.VALIDATION).map((e) => e.message)
    : []

  const additionalInformation = rider.isSelected
    ? rider.errors.filter((e) => e.type === values.ADDITIONAL_INFORMATION).map((e) => e.message)
    : []

  let formGroupState = ''
  let formWarningMsg = ''
  if (!rider.isSelectable) {
    formGroupState = 'warning'
    formWarningMsg = 'has-warning'
  } else if (eligibilityErrors.length > 0 || validationErrors.length > 0) {
    formGroupState = 'danger'
    formWarningMsg = 'has-danger'
  }

  const formGroupProps = {
    id: `rider-${rider.code}`,
    className: `item rider ${!_.isEmpty(formWarningMsg) ? formWarningMsg : ''}`,
    color: formGroupState,
  }

  const riderChecked = rider.isSelected && rider.isSelectable

  if (rider.code === values.MEH) return <MEHRider {...formGroupProps} rider={rider} />
  if (rider.code === values.MHP && displayProduct.category === values.IHEALTHY_ULTRA)
    return (
      <MHPRider
        {...formGroupProps}
        rider={rider}
        eligibilityErrors={eligibilityErrors}
        validationErrors={validationErrors}
        additionalInformation={additionalInformation}
      />
    )

  if (rider.code === values.MHP && displayProduct.category !== values.IHEALTHY_ULTRA) {
    return (
      <MHPRiderCheckbox
        {...formGroupProps}
        rider={rider}
        riderChecked={riderChecked}
        toggleRider={toggleRider}
        eligibilityErrors={eligibilityErrors}
        validationErrors={validationErrors}
        additionalInformation={additionalInformation}
        isRequire={Array.isArray(displayProduct.requiredRiders) && displayProduct.requiredRiders.includes(rider.code)}
      />
    )
  }

  if (displayProduct.category === values.PA) return <PARider {...formGroupProps} rider={rider} />

  // TODO: [FlexiHealth] Add test for rendering FHRider
  if (rider.code === values.FH)
    return (
      <FHRider
        {...formGroupProps}
        rider={rider}
        toggleRiderModule={toggleRiderModule}
        toggleRiderGroupModule={toggleRiderGroupModule}
      />
    )

  return (
    <FormGroup {...formGroupProps}>
      <div className="label">
        <Checkbox
          id={rider.code}
          checked={riderChecked}
          disabled={
            !rider.isSelectable ||
            Boolean(rider.isReadOnly) ||
            (Array.isArray(displayProduct.requiredRiders) && displayProduct.requiredRiders.includes(rider.code))
          }
          onChange={() => toggleRider(rider.code, !riderChecked)}
        >
          <RiderPlanName rider={rider} editRiderSelectedPlan={editRiderSelectedPlan} />
        </Checkbox>
      </div>
      <div className="options">
        {rider.options[0].type === values.OPTION_FIXED_VALUE_HIDE_SUMASSURED ? (
          ''
        ) : (
          <RiderSumAssured
            riderCode={rider.code}
            selectedPlanCode={rider.selectedPlan.planCode}
            isReadOnly={Boolean(rider.isReadOnly)}
            sumAssured={rider.sumAssured}
            riderOptions={rider.options}
            showSumAssuredDecimalPlaces={rider.showSumAssuredDecimalPlaces}
            isSelected={rider.isSelected}
            isSelectable={rider.isSelectable}
            editRiderSumAssured={editRiderSumAssured}
            editRiderSelectedPlan={editRiderSelectedPlan}
            isSumAssureReadonly={rider.isSumAssureReadonly}
          />
        )}
        {rider.options[0].type === values.OPTION_FIXED_VALUE_HIDE_SUMASSURED ? '' : <div className="link">→</div>}
        <RiderPremium
          isSelected={rider.isSelected && rider.isSelectable}
          isSelectable={rider.isSelectable}
          validationErrors={validationErrors.length > 0}
          premium={rider.premium}
        />
      </div>
      <div className="message">
        <RiderMessage riderCode={rider.code} type="eligibility" messages={eligibilityErrors} />
        <RiderMessage riderCode={rider.code} type="validation" messages={validationErrors} />
        <RiderMessage riderCode={rider.code} type="additional-information" messages={additionalInformation} />
      </div>
    </FormGroup>
  )
}

const mapStateToProps = (state: AppState, ownProps: OwnProps): StateProps => ({
  rider: getAvailableRidersByCode(state)[ownProps.code],
  displayProduct: getSelectedDisplayProduct(state),
  payerRelation: getPayerRelation(state),
})

export default connect(mapStateToProps)(RiderComponent)
