// @flow
import _ from 'lodash'
import { d } from 'core/service/lib/decimal'
import { roundDown } from 'core/service/lib/number-format'
import VALUES from 'core/data-model/constants/values'

import { calculateMinimumDeathCoverage } from 'core/service/benefit-illustration/products/retirement'
import { calculateSurrenderCash, queryCashValueRate } from 'core/service/basic-plan/surrender-cash'
import type { CashValueRates, Period } from 'core/data-model/basic-plan'
import type { Age, Gender } from 'core/data-model/insured'
import type { DisplayProduct } from 'core/service/display-product'
import type { YearEndPolicyValue } from '../policy-value'

export const getInitialAge = (age: Age): number => (age.unit === 'year' ? age.value : 0)

export const getCoveragePeriodValue = (age: number, coveragePeriod: Period) => {
  switch (coveragePeriod.type) {
    case VALUES.PERIOD_LIMITED_TO_AGE:
      return coveragePeriod.value
    case VALUES.PERIOD_BY_PACKAGE:
    case VALUES.PERIOD_LIMITED_BY_DURATION:
    default:
      return coveragePeriod.value + age
  }
}

export const getAgeRangeWithinCoveragePeriod = (age: number, coveragePeriod: Period): number[] => {
  const coveragePeriodValue = getCoveragePeriodValue(age, coveragePeriod)
  return _.range(age + 1, coveragePeriodValue + 1)
}

export type CalculateAnnualBasicPremiumProps = {
  basicPremium: number,
  modelFactorPeriod: number,
}
export const calculateAnnualBasicPremium = ({
  basicPremium,
  modelFactorPeriod,
}: CalculateAnnualBasicPremiumProps): number => d(roundDown(d(basicPremium).times(modelFactorPeriod), 2)).toNumber()

export type CalculateGuarenteeDividendProps = {
  sumAssured: number,
  rate: number,
}
export const calculateGuarenteeDividend = ({ sumAssured, rate }: CalculateGuarenteeDividendProps): number =>
  d(sumAssured)
    .times(rate)
    .div(1000)
    .toNumber()

export type CalculateInterestNonGuaranteeDividendDepositProps = {
  previousNonGuranteeDividendDeposit: number,
  guaranteeDividendOfYear: number,
  interestRate: number,
}
export const calculateInterestNonGuaranteeDividendDeposit = ({
  previousNonGuranteeDividendDeposit,
  guaranteeDividendOfYear,
  interestRate,
}: CalculateInterestNonGuaranteeDividendDepositProps): number => {
  return d(previousNonGuranteeDividendDeposit)
    .times(interestRate)
    .plus(guaranteeDividendOfYear)
    .toNumber()
}

export type CalculateInterestGuaranteeDividendProps = {
  guaranteeDividendOfYear: number,
  nonGuaranteeDividendOfYear: number,
}
export const calculateInterestGuaranteeDividend = ({
  guaranteeDividendOfYear,
  nonGuaranteeDividendOfYear,
}: CalculateInterestGuaranteeDividendProps): number => {
  return d(nonGuaranteeDividendOfYear)
    .minus(guaranteeDividendOfYear)
    .toNumber()
}

export type CalculateTotalDividendProps = {
  cumulativeGuaranteeDividend: number,
  interest: number,
}
export const calculateTotalDividend = ({
  cumulativeGuaranteeDividend,
  interest,
}: CalculateTotalDividendProps): number => {
  return d(cumulativeGuaranteeDividend)
    .plus(interest)
    .toNumber()
}

export type CalculateTotalBenefitProps = {
  deathBenefit: number,
  previousNonGuranteeDividend: number,
}
export const calculateTotalBenefit = ({
  deathBenefit,
  previousNonGuranteeDividend,
}: CalculateTotalBenefitProps): CalculateTotalBenefitProps => {
  return d(deathBenefit)
    .plus(previousNonGuranteeDividend)
    .toNumber()
}

type GetSurrenderCashProps = {
  basicPlan: DisplayProduct,
  gender: Gender,
  initialInsuredAge: Age,
  yearEnd: number,
  cashValueRates: CashValueRates[],
  basicSumAssured: number,
}
export const getSurrenderCash = ({
  basicPlan,
  gender,
  initialInsuredAge,
  yearEnd,
  cashValueRates,
  basicSumAssured,
}: GetSurrenderCashProps) => {
  const cashValueRate = queryCashValueRate(basicPlan.basicPlanCode, gender, initialInsuredAge, yearEnd, cashValueRates)
  if (basicPlan.basicPlanCode === VALUES.LIFE_SUPER_SAVE_14_5_CODE)
    return calculateSurrenderCash(cashValueRate, basicSumAssured, 2)
  else return calculateSurrenderCash(cashValueRate, basicSumAssured)
}

export type CalulateDeathBenefitProps = {
  basicPlan: DisplayProduct,
  basicSumAssured: number,
  cumulativeBasicPremium?: number,
  surrenderCash?: number,
}
export const calculateDeathBenefit = ({
  basicPlan,
  basicSumAssured,
  cumulativeBasicPremium = 0,
  surrenderCash = 0,
  yearEnd,
}: CalulateDeathBenefitProps) => {
  const basicPlanCode = _.get(basicPlan, 'basicPlanCode')

  switch (basicPlanCode) {
    case '20P10':
    case '25P15':
    case '20P10S':
    case '25P15S':
      return basicSumAssured + cumulativeBasicPremium
    case 'WL88S':
    case 'WL88J':
    case 'W85P10J':
    case 'W85P10S':
    case 'CIWLM39':
    case 'CIWLM50':
    case 'CIWLM60':
    case 'CIWLS39':
    case 'CIWLS50':
    case 'CIWLS65':
    case 'W99F99H':
    case 'W99F99M':
    case 'W99FU06':
    case 'W99FU12':
    case 'W99FU18':
    case 'W99FH18':
    case 'W99FU99':
    case 'W99FH99':
    case 'H99F06A':
    case 'H99F12A':
    case 'H99F18A':
      const cumulativeBasicPremiumFinal = d(cumulativeBasicPremium).plus(d(cumulativeBasicPremium).times(0.01))
      return Math.max(basicSumAssured, cumulativeBasicPremiumFinal, surrenderCash, cumulativeBasicPremium)
    case 'R01':
    case 'R05':
      const retirementAccumulatedBasicPremium = d(cumulativeBasicPremium).plus(d(cumulativeBasicPremium).times(0.1))
      const doubleBasicSumAssured = d(basicSumAssured).times(VALUES.PERCENT_MINIMUM_DEATH_COVERAGE.surrender)
      return Math.max(doubleBasicSumAssured, retirementAccumulatedBasicPremium, surrenderCash)
    case 'LR05':
      const minDeathCoverage = d(basicSumAssured).times(VALUES.PERCENT_MINIMUM_DEATH_COVERAGE_LR05.surrender)
      return Math.max(minDeathCoverage, cumulativeBasicPremium, surrenderCash)
    case 'R08F':
    case 'R60F':
      const deathCoverage = calculateMinimumDeathCoverage(basicSumAssured, basicPlanCode)
      const minDeathCoverageBumnanReady =
        yearEnd < 5 ? deathCoverage.beforeFiveYearInsurance : deathCoverage.afterFiveYearInsurance
      const bumnanReadyAccumulatedBasicPremium = d(cumulativeBasicPremium).plus(d(cumulativeBasicPremium).times(0.01))
      return Math.max(minDeathCoverageBumnanReady, bumnanReadyAccumulatedBasicPremium, surrenderCash)
    case '25PL_FLEXI': //new logic because cash drop
    case '25PL':
    case 'FWLNP60':
    case 'IGROW':
      return basicSumAssured
    case '12PL':
      return Math.max(basicSumAssured, surrenderCash)
    case 'E20F10':
    case 'E25F15':
    case 'E25F25':
      const cumulativeBasicPremium101 = d(cumulativeBasicPremium).times(1.01)
      const tempBasicSumAssured = yearEnd > 5 ? basicSumAssured * 2 : basicSumAssured
      return Math.max(cumulativeBasicPremium101, tempBasicSumAssured, surrenderCash)
    case 'S14G5T1':
      return Math.max(calculateGioDeathBenefit(basicSumAssured, yearEnd), surrenderCash)
    case 'W80F06': {
      const cumulativeBasicPremium101 = d(cumulativeBasicPremium).times(1.01)
      return Math.max(basicSumAssured * 2, cumulativeBasicPremium101, surrenderCash)
    }
    default:
      return Math.max(basicSumAssured, cumulativeBasicPremium)
  }
}

const calculateGioDeathBenefit = (basicSumAssured, yearEnd) => {
  switch (yearEnd) {
    case 1:
      return d(basicSumAssured).times(1.01)
    case 2:
      return d(basicSumAssured).times(2.02)
    case 3:
      return d(basicSumAssured).times(3.15)
    case 4:
      return d(basicSumAssured).times(4.2)
    default:
      //year 5-14
      return d(basicSumAssured).times(5.25)
  }
}

export type CalculateMaturityProps = {
  basicSumAssured: number,
  maturityRate: number,
}
export const calculateMaturity = ({ basicSumAssured, maturityRate }: CalculateMaturityProps): number => {
  return d(basicSumAssured)
    .times(maturityRate)
    .div(1000)
    .toNumber()
}

export const sumPolicyValuesByColumn = (policyValues: YearEndPolicyValue[], key: string): number => {
  return _.map(policyValues, key)
    .reduce((a, b) => a.add(b), d(0))
    .toNumber()
}
