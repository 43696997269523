// @flow

import type { Dispatch } from 'redux'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { compose, branch, renderNothing, withProps, setDisplayName } from 'recompose'
import {
  InsuredInformationTemplate,
  LBLicenseInfoContainer,
  GenderSelection,
  Birthdate,
  OccupationSelection,
  OtherOccupationSelection,
  PayerInformation,
  Name,
  Title,
} from './components'
import { FirstNameRedux } from './redux/name'
import { LastNameRedux } from './redux/surname'
import { TitleRedux } from './redux/title'
import type { AppState } from 'quick-quote/reducers'
import {
  editBirthdate,
  selectGender,
  selectOccupation,
  selectNatureOfDuty,
  selectOtherOccupation,
  selectOtherNatureOfDuty,
  toggleOtherOccupation,
  selectPayerOtherOccupation,
  selectPayerOtherNatureOfDuty,
  togglePayerOtherOccupation,
  selectPayerGender,
  selectPayerRelation,
  editPayerBirthdate,
  selectPayerNatureOfDuty,
  selectPayerOccupation,
} from './actions'

import {
  getBirthdate,
  getGenderOptions,
  getGender,
  getAge,
  getUniqueOccupations,
  getNatureOfDuties,
  getSelectedOccupationCode,
  getSelectedOccupation,
  getSelectedNatureOfDutyCode,
  getErrorBirthdateMessage,
  getErrorPayerBirthdateMessage,
  getInsuredOccupationErrors,
  getInsuredOtherOccupationErrors,
  getIsSelectedOtherOccupation,
  getSelectedOtherNatureOfDutyCode,
  getIsSelectedPayerOtherOccupation,
  getOtherNatureOfDuties,
  getSelectedOtherOccupation,
  getSelectedOtherOccupationCode,
  getSelectedPayerOccupationCode,
  getSelectedPayerNatureOfDutyCode,
  getPayerOccupationErrors,
  getSelectedPayerOccupation,
  getPayerNatureOfDuties,
  getSelectedPayerOtherOccupationCode,
  getSelectedPayerOtherOccupation,
  getSelectedPayerOtherNatureOfDutyCode,
  getPayerOtherNatureOfDuties,
  getPayerOtherOccupationErrors,
  getDefaultOccupationCode,
  getDefaultPayerOccupationCode,
  getPayerRelationOptions,
  isShowPayerRelationSelection,
} from './selectors'
import messages from 'core/data-model/constants/messages'
import { isReviseQQFlow } from 'quick-quote/selectors'

import { resetInvestmentPermission } from 'quick-quote/product-investment/validate-permission/actions'
import { resetOrdinaryPermission } from 'quick-quote/product-common/validate-permission/actions'

const GenderSelectionContainer = connect(
  (state: AppState) => ({
    genderOptions: getGenderOptions(),
    gender: getGender(state),
  }),
  (dispatch: Dispatch<*>) => ({
    selectGender: bindActionCreators(selectGender, dispatch),
  })
)(GenderSelection)

const BirthdateContainer = connect(
  (state: AppState) => ({
    birthdate: getBirthdate(state),
    errorBirthdateMessage: getErrorBirthdateMessage(state),
    age: getAge(state),
  }),
  (dispatch: Dispatch<*>) => ({
    editBirthdate: bindActionCreators(editBirthdate, dispatch),
  })
)(Birthdate)

const OccupationSelectionContainer = connect(
  (state: AppState) => ({
    occupations: getUniqueOccupations(state),
    natureOfDuties: getNatureOfDuties(state),
    errors: getInsuredOccupationErrors(state),
    selectedOccupation: getSelectedOccupation(state),
    selectedOccupationCode: getSelectedOccupationCode(state),
    selectedNatureOfDutyCode: getSelectedNatureOfDutyCode(state),
    defaultOccupationCode: getDefaultOccupationCode(state),
  }),
  {
    selectOccupation,
    selectNatureOfDuty,
  }
)(OccupationSelection)

const OtherOccupationSelectionContainer = connect(
  (state: AppState) => ({
    occupations: getUniqueOccupations(state),
    natureOfDuties: getOtherNatureOfDuties(state),
    errors: getInsuredOtherOccupationErrors(state),
    selectedOccupation: getSelectedOtherOccupation(state),
    selectedOccupationCode: getSelectedOtherOccupationCode(state),
    selectedNatureOfDutyCode: getSelectedOtherNatureOfDutyCode(state),
    isSelectOtherOccupation: getIsSelectedOtherOccupation(state),
    defaultOccupationCode: getDefaultOccupationCode(state),
  }),
  {
    selectOccupation: selectOtherOccupation,
    selectNatureOfDuty: selectOtherNatureOfDuty,
    toggleOtherOccupation,
  }
)(OtherOccupationSelection)

const PayerOccupationSelectionContainer = connect(
  (state: AppState) => ({
    occupations: getUniqueOccupations(state),
    natureOfDuties: getPayerNatureOfDuties(state),
    errors: getPayerOccupationErrors(state),
    selectedOccupation: getSelectedPayerOccupation(state),
    selectedOccupationCode: getSelectedPayerOccupationCode(state),
    selectedNatureOfDutyCode: getSelectedPayerNatureOfDutyCode(state),
    defaultOccupationCode: getDefaultPayerOccupationCode(state),
  }),
  {
    selectOccupation: selectPayerOccupation,
    selectNatureOfDuty: selectPayerNatureOfDuty,
  }
)(OccupationSelection)

const PayerInformationWithOccupation = () => (
  <PayerInformationContainer>
    <PayerOccupationSelectionContainer />
  </PayerInformationContainer>
)

const PayerInformationWithoutOccupation = () => <PayerInformationContainer></PayerInformationContainer>

const PayerInformationContainer = connect(
  (state: AppState) => ({
    payer: state.insured.payer,
    payerNeeded: state.insured.payerNeeded,
    errorBirthdateMessage: getErrorPayerBirthdateMessage(state),
    genderOptions: getGenderOptions(),
    showPayerRelationSelection: isShowPayerRelationSelection(state),
    payerRelationOptions: getPayerRelationOptions(state),
  }),
  {
    selectGender: selectPayerGender,
    editBirthdate: editPayerBirthdate,
    selectPayerRelation: selectPayerRelation,
  }
)(PayerInformation)

const TitleContainer = compose(
  connect(
    (state: AppState) => ({
      testId: 'title',
      value: TitleRedux.selectors.getTitle(state.insured.title),
      defaultValue: TitleRedux.selectors.getDefaultValue(state.insured.title),
      errors: TitleRedux.selectors.getErrors(state.insured.title),
      options: TitleRedux.selectors.getOptions(state),
      label: messages.TITLE,
      isReviseQQ: isReviseQQFlow(state),
    }),
    {
      onChange: TitleRedux.actionCreators.editTitle,
    }
  ),
  branch((props) => props?.isReviseQQ, renderNothing)
)(Title)

const FirstNameContainer = compose(
  connect(
    (state: AppState) => ({
      testId: 'firstname',
      name: FirstNameRedux.selectors.getName(state.insured.firstName),
      defaultValue: FirstNameRedux.selectors.getDefaultValue(state.insured.firstName),
      errors: FirstNameRedux.selectors.getErrors(state.insured.firstName),
      isReviseQQ: isReviseQQFlow(state),
    }),
    {
      editName: FirstNameRedux.actionCreators.editName,
    }
  ),
  branch((props) => props?.isReviseQQ, renderNothing)
)(Name)

const LastNameContainer = compose(
  connect(
    (state: AppState) => ({
      testId: 'lastname',
      name: LastNameRedux.selectors.getName(state.insured.lastName),
      defaultValue: LastNameRedux.selectors.getDefaultValue(state.insured.lastName),
      errors: LastNameRedux.selectors.getErrors(state.insured.lastName),
      isReviseQQ: isReviseQQFlow(state),
    }),
    {
      editName: LastNameRedux.actionCreators.editName,
    }
  ),
  branch((props) => props?.isReviseQQ, renderNothing)
)(Name)

const NonILPFirstNameContainer = compose(
  setDisplayName('Firstname'),
  withProps({ label: messages.FIRST_NAME + ' ' + messages.FIRST_NAME_WARNING, placeholder: messages.FIRST_NAME })
)(FirstNameContainer)
const NonILPLastNameContainer = compose(
  setDisplayName('Lastname'),
  withProps({ label: messages.LAST_NAME, placeholder: messages.LAST_NAME })
)(LastNameContainer)

export const CommonNonILPInsuredInformationContainer = () => (
  <InsuredInformationTemplate PayerInformation={<PayerInformationWithOccupation />}>
    <LBLicenseInfoContainer />
    <GenderSelectionContainer />
    <BirthdateContainer />
    <TitleContainer />
    <NonILPFirstNameContainer />
    <NonILPLastNameContainer />
    <OccupationSelectionContainer />
    <OtherOccupationSelectionContainer />
  </InsuredInformationTemplate>
)

type OrdinaryContainerProps = {
  resetOrdinaryPermission: Function,
}
export class _OrdinaryInsuredInformationContainer extends React.Component<OrdinaryContainerProps, *> {
  componentDidMount() {
    this.props.resetOrdinaryPermission()
  }

  render() {
    return (
      <InsuredInformationTemplate PayerInformation={<PayerInformationWithOccupation />}>
        <LBLicenseInfoContainer />
        <GenderSelectionContainer />
        <BirthdateContainer />
        <TitleContainer />
        <NonILPFirstNameContainer />
        <NonILPLastNameContainer />
        <OccupationSelectionContainer />
        <OtherOccupationSelectionContainer />
      </InsuredInformationTemplate>
    )
  }
}

export const OrdinaryInsuredInformationContainer = connect(undefined, (dispatch: Dispatch<*>) => ({
  resetOrdinaryPermission: bindActionCreators(resetOrdinaryPermission, dispatch),
}))(_OrdinaryInsuredInformationContainer)

export const WholeLifeInsuredInformationContainer = CommonNonILPInsuredInformationContainer

export const HealthInsuredInformationContainer = CommonNonILPInsuredInformationContainer

export const IHealthyUltraInsuredInformationContainer = CommonNonILPInsuredInformationContainer

export const FlexiHealthInsuredInformationContainer = CommonNonILPInsuredInformationContainer

export const ProtectionInsuredInformationContainer = CommonNonILPInsuredInformationContainer

type ContainerProps = {
  resetInvestmentPermission: Function,
}
export class _InvestmentInsuredInformationContainer extends React.Component<ContainerProps, *> {
  componentDidMount() {
    this.props.resetInvestmentPermission()
  }

  render() {
    return (
      <InsuredInformationTemplate PayerInformation={<PayerInformationWithoutOccupation />}>
        <LBLicenseInfoContainer />
        <GenderSelectionContainer />
        <BirthdateContainer />
        <TitleContainer />
        <NonILPFirstNameContainer />
        <NonILPLastNameContainer />
        <OccupationSelectionContainer />
        <OtherOccupationSelectionContainer />
      </InsuredInformationTemplate>
    )
  }
}

export const InvestmentInsuredInformationContainer = connect(undefined, (dispatch: Dispatch<*>) => ({
  resetInvestmentPermission: bindActionCreators(resetInvestmentPermission, dispatch),
}))(_InvestmentInsuredInformationContainer)

export const LoanInsuredInformationContainer = () => (
  <InsuredInformationTemplate PayerInformation={<PayerInformationWithOccupation />}>
    <LBLicenseInfoContainer />
    <GenderSelectionContainer />
    <BirthdateContainer />
    <TitleContainer />
    <NonILPFirstNameContainer />
    <NonILPLastNameContainer />
    <OccupationSelectionContainer />
  </InsuredInformationTemplate>
)

export const SavingInsuredInformationContainer = () => (
  <InsuredInformationTemplate PayerInformation={<PayerInformationWithOtherOccupation />}>
    <LBLicenseInfoContainer />
    <GenderSelectionContainer />
    <BirthdateContainer />
    <TitleContainer />
    <NonILPFirstNameContainer />
    <NonILPLastNameContainer />
    <OccupationSelectionContainer />
    <OtherOccupationSelectionContainer />
  </InsuredInformationTemplate>
)

export const LifeSaveProInsuredInformationContainer = () => (
  <InsuredInformationTemplate PayerInformation={<PayerInformationWithOtherOccupation />}>
    <LBLicenseInfoContainer />
    <GenderSelectionContainer />
    <BirthdateContainer />
    <TitleContainer />
    <NonILPFirstNameContainer />
    <NonILPLastNameContainer />
    <OccupationSelectionContainer />
    <OtherOccupationSelectionContainer />
  </InsuredInformationTemplate>
)

export const RetirementInsuredInformationContainer = CommonNonILPInsuredInformationContainer

export const PerfectLifeInsuredInformationContainer = CommonNonILPInsuredInformationContainer

export const SukhapabDekDeeInsuredInformationContainer = CommonNonILPInsuredInformationContainer

export const HealthToppingInsuredInformationContainer = CommonNonILPInsuredInformationContainer

export const LifeReadyInsuredInformationContainer = CommonNonILPInsuredInformationContainer

export const MRTAInsuredInformationContainer = CommonNonILPInsuredInformationContainer

const PayerInformationWithOtherOccupation = () => (
  <PayerInformationContainer>
    <PayerOccupationSelectionContainer />
    <PayerOtherOccupationSelectionContainer />
  </PayerInformationContainer>
)

const PayerOtherOccupationSelectionContainer = connect(
  (state: AppState) => ({
    occupations: getUniqueOccupations(state),
    natureOfDuties: getPayerOtherNatureOfDuties(state),
    errors: getPayerOtherOccupationErrors(state),
    selectedOccupation: getSelectedPayerOtherOccupation(state),
    selectedOccupationCode: getSelectedPayerOtherOccupationCode(state),
    selectedNatureOfDutyCode: getSelectedPayerOtherNatureOfDutyCode(state),
    isSelectOtherOccupation: getIsSelectedPayerOtherOccupation(state),
    defaultOccupationCode: getDefaultPayerOccupationCode(state),
  }),
  {
    selectOccupation: selectPayerOtherOccupation,
    selectNatureOfDuty: selectPayerOtherNatureOfDuty,
    toggleOtherOccupation: togglePayerOtherOccupation,
  }
)(OtherOccupationSelection)
