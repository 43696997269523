// @flow
import type { ProductBenefitData } from 'core/data-model/basic-plan'
import type { ProductBenefit } from 'core/data-model/benefit'
import MESSAGES from 'core/data-model/constants/bi-messages'

import { get } from 'lodash/fp'

const getProductBenefitsConfig = (): ProductBenefit => {
  return require('core/service/data-config/product-benefit/benefit.json')
}

export const hasProductCodeInCategory = (
  basicPlanCode: string,
  productCategory: string,
  getConfig: Function = getProductBenefitsConfig
): boolean => {
  return getConfig().productBenefit.some(
    (b) => b.productCategory === productCategory && b.productCodes && b.productCodes.includes(basicPlanCode)
  )
}

export const getBenefitData = (productCategory: string): ProductBenefitData => {
  // $FlowFixMe
  const productBenefit: ProductBenefit = getProductBenefitsConfig().productBenefit.find((data) => {
    return data.productCategory === productCategory
  })
  return get('benefitData')(productBenefit)
}

export const isLoanProduct = (basicPlanCode: string): boolean => {
  return hasProductCodeInCategory(basicPlanCode, MESSAGES.CATEGORY_LOAN)
}

export const isSavingProduct = (basicPlanCode: string): boolean => {
  return hasProductCodeInCategory(basicPlanCode, MESSAGES.CATEGORY_SAVING)
}

export const getLoanBenefitData = (): ProductBenefitData => {
  return getBenefitData(MESSAGES.CATEGORY_LOAN)
}

export const isIProtectProduct = (basicPlanCode: string): boolean => {
  return hasProductCodeInCategory(basicPlanCode, MESSAGES.CATEGORY_WHOLE_LIFE)
}

export const getWholeLife25PLBenefitData = (): ProductBenefitData => {
  return getBenefitData(MESSAGES.CATEGORY_WHOLE_LIFE_25PL)
}

export const isWholeLife25PLProduct = (basicPlanCode: string): boolean => {
  return hasProductCodeInCategory(basicPlanCode, MESSAGES.CATEGORY_WHOLE_LIFE_25PL)
}

export const isWholeLife12PLProduct = (basicPlanCode: string): boolean => {
  return hasProductCodeInCategory(basicPlanCode, MESSAGES.CATEGORY_WHOLE_LIFE_12PL)
}

export const getWholeLife12PLBenefitData = (): ProductBenefitData => {
  return getBenefitData(MESSAGES.CATEGORY_WHOLE_LIFE_12PL)
}

export const isLifeProtectProduct = (basicPlanCode: string): boolean => {
  return hasProductCodeInCategory(basicPlanCode, MESSAGES.CATEGORY_WHOLE_LIFE_LIFEPROTECT)
}

export const isLifeReadyProduct = (basicPlanCode: string): boolean => {
  return hasProductCodeInCategory(basicPlanCode, MESSAGES.CATEGORY_WHOLE_LIFE_LIFEREADY)
}

export const getLifeProtectBenefitData = (): ProductBenefitData => {
  return getBenefitData(MESSAGES.CATEGORY_WHOLE_LIFE_LIFEPROTECT)
}

export const isLifeEnsureProduct = (basicPlanCode: string): boolean => {
  return hasProductCodeInCategory(basicPlanCode, MESSAGES.CATEGORY_WHOLE_LIFE_LIFEENSURE)
}

export const isSukhapabDekDeeProduct = (basicPlanCode: string): boolean => {
  return hasProductCodeInCategory(basicPlanCode, MESSAGES.CATEGORY_SUKHAPABDEKDEE)
}

export const getLifeEnsureBenefitData = (): ProductBenefitData => {
  return getBenefitData(MESSAGES.CATEGORY_WHOLE_LIFE_LIFEENSURE)
}

export const isIRetireProduct = (basicPlanCode: string): boolean => {
  return hasProductCodeInCategory(basicPlanCode, MESSAGES.CATEGORY_RETIREMENT)
}

export const getIRetireBenefitData = (): ProductBenefitData => {
  return getBenefitData(MESSAGES.CATEGORY_RETIREMENT)
}

export const isLifeRetireProduct = (basicPlanCode: string): boolean => {
  return hasProductCodeInCategory(basicPlanCode, MESSAGES.CATEGORY_RETIREMENT_LR05)
}

export const getLifeRetireBenefitData = (): ProductBenefitData => {
  return getBenefitData(MESSAGES.CATEGORY_RETIREMENT_LR05)
}

export const isProtectionProduct = (basicPlanCode: string): boolean => {
  return hasProductCodeInCategory(basicPlanCode, MESSAGES.CATEGORY_PROTECTION)
}

export const getIProtectBenefitData = (): ProductBenefitData => {
  return getBenefitData(MESSAGES.CATEGORY_WHOLE_LIFE)
}

export const isIShieldProduct = (basicPlanCode: string): boolean => {
  return hasProductCodeInCategory(basicPlanCode, MESSAGES.CATEGORY_ISHIELD)
}
export const getIShieldBenefitData = (): ProductBenefitData => {
  return getBenefitData(MESSAGES.CATEGORY_ISHIELD)
}

export const isIHealthyProduct = (basicPlanCode: string): boolean => {
  return hasProductCodeInCategory(basicPlanCode, MESSAGES.CATEGORY_HEALTH)
}

export const isFlexiHealthProduct = (basicPlanCode: string): boolean => {
  return hasProductCodeInCategory(basicPlanCode, MESSAGES.CATEGORY_FLEXI_HEALTH)
}

export const getHealthBenefitData = (): ProductBenefitData => {
  return getBenefitData(MESSAGES.CATEGORY_HEALTH)
}

export const getFlexiHealthBenefitData = (): ProductBenefitData => {
  return getBenefitData(MESSAGES.CATEGORY_FLEXI_HEALTH)
}

export const isLifeLegacyProduct = (basicPlanCode: string): boolean => {
  return hasProductCodeInCategory(basicPlanCode, MESSAGES.CATEGORY_LIFE_LEGACY)
}

export const getLifeLegacyBenefitData = (): ProductBenefitData => {
  return getBenefitData(MESSAGES.CATEGORY_LIFE_LEGACY)
}

export const isLifeSavePro25P15Product = (basicPlanCode: string): boolean => {
  return hasProductCodeInCategory(basicPlanCode, MESSAGES.CATEGORY_LIFE_SAVE_PRO_25P15)
}

export const isLifeSavePro20P10Product = (basicPlanCode: string): boolean => {
  return hasProductCodeInCategory(basicPlanCode, MESSAGES.CATEGORY_LIFE_SAVE_PRO_20P10)
}

export const getLifeSavePro25P15BenefitData = (): ProductBenefitData => {
  return getBenefitData(MESSAGES.CATEGORY_LIFE_SAVE_PRO_25P15)
}

export const getLifeSavePro20P10BenefitData = (): ProductBenefitData => {
  return getBenefitData(MESSAGES.CATEGORY_LIFE_SAVE_PRO_20P10)
}

export const isS7Product = (basicPlanCode: string): boolean => {
  return hasProductCodeInCategory(basicPlanCode, MESSAGES.CATEGORY_S7)
}

export const getS7BenefitData = (): ProductBenefitData => {
  return getBenefitData(MESSAGES.CATEGORY_S7)
}

export const getTermLife_GIO_LifeSuperSave_BenefitData = (): ProductBenefitData => {
  return getBenefitData(MESSAGES.CATEGORY_TERM_LIFE_GIO)
}

export const isTermLife_GIO_Product = (basicPlanCode: string): boolean => {
  return hasProductCodeInCategory(basicPlanCode, MESSAGES.CATEGORY_TERM_LIFE_GIO)
}
