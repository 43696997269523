// @flow

import type { FundData } from '../../components/fund-risk-chart'
import type { TableBody } from 'core/service/pdf-generation/utils/table-utils'
import { ColorfulHeader, centerAlignTable } from 'core/service/pdf-generation/utils/table-utils'
import type {
  FundAllocationCategoryByInvestmentType,
  PortfolioDisplayOptions,
} from 'core/service/pdf-generation/products/investment/sections/mutual-fund-portfolio'

import MESSAGES from 'core/data-model/constants/bi-messages'
import { splitWords } from 'core/service/pdf-generation/utils/line-wrapper'
import { fundGroupColor } from '../../components/fund-allocation-pie-chart'
import { chartLegend } from '../../components/fund-chart-legend'
import { chartLegendPattern } from '../../components/fund-chart-legend-pattern'

const chartGroupTitle = (title: string = '') => ({
  text: title,
  bold: true,
  fontSize: 8,
  alignment: 'center',
  margin: [-0.5, 0],
})

const chartSeparator = () => ({
  text: '',
  margin: [0, 0, 0, 10],
})

const chartImage = (image: string = '') =>
  image.length
    ? {
        image,
        width: 110,
        margin: [12, -8, 0, -6],
      }
    : chartSeparator()

const chartLegendTable = (allocationData: FundData[] = [], portfolioDisplayOptions: PortfolioDisplayOptions) => {
  const lengendType = portfolioDisplayOptions.isUsePatternForChart ? chartLegendPattern : chartLegend
  return centerAlignTable({
    fontSize: 10,
    layout: 'noBorders',
    margin: [0, 0, 0, 5],
    table: {
      body: allocationData.map((fundGroup, index) => [
        lengendType(
          fundGroupColor(index),
          `${fundGroup.name}, ${fundGroup.value}%`,
          index,
          portfolioDisplayOptions.chartPatterns
        ),
      ]),
    },
  })
}

export const FundRiskChartSection = (
  lumpSumTopUp: number = 0,
  allocationCategoryByInvestmentType: FundAllocationCategoryByInvestmentType,
  pieChartFundByInvestmentType: PieChartByTypes,
  portfolioDisplayOptions: PortfolioDisplayOptions
): TableBody => {
  const rppChart = [
    chartGroupTitle(MESSAGES.FUND_RISK_CHART_HEADER_RPP),
    chartImage(pieChartFundByInvestmentType.rpp),
    chartLegendTable(allocationCategoryByInvestmentType.rpp, portfolioDisplayOptions),
  ]
  const lstuChart =
    lumpSumTopUp > 0
      ? [
          chartGroupTitle(MESSAGES.FUND_RISK_CHART_HEADER_LSTU),
          chartImage(pieChartFundByInvestmentType.lstu),
          chartLegendTable(allocationCategoryByInvestmentType.lstu, portfolioDisplayOptions),
        ]
      : []

  const chartContent = [[...rppChart], [chartSeparator()], [...lstuChart]]
  return ColorfulHeader(MESSAGES.FUND_RISK_CHART_HEADER).concat([
    [
      {
        stack: chartContent,
      },
    ],
    [
      {
        text: splitWords(MESSAGES.RPUDR_FUND_RISK_CHART_FOOTER),
        bold: true,
        fontSize: 10,
      },
    ],
  ])
}
