// @flow
import _ from 'lodash'
import { queryPremiumRateForMHP as queryPremiumRate } from 'core/service/rider/query-premium-rate'
import { calculateRiderPremiumForMHP as calculateRiderPremium } from 'core/service/rider/premium-calculation'
import { queryTaxRateForMHP as queryTaxRate } from 'core/service/rider/query-tax-rate'

// get plancode with custom logic
export const getRiderPlanCode = (rider, insureAge) => {
  const planCode = _.get(rider, 'selectedPlan.planCode', '')
  const age = insureAge.unit !== 'year' ? '0' : insureAge.value.toString()
  switch (planCode) {
    case 'MHP1': {
      return age <= 10 ? 'MHP1J' : 'MHP1S'
    }
    case 'MHP2': {
      return age <= 10 ? 'MHP2J' : 'MHP2S'
    }
    default:
      return planCode
  }
}

export const getDisplayOptionalMessage = (rider) => {
  return _.get(rider, 'selectedPlan.allowOptionalIHealthyUltra', false) ? 'allowOption' : 'notAllowOption'
}

export default {
  calculateRiderPremium,
  queryPremiumRate,

  queryTaxRate,
  getRiderPlanCode,
  getDisplayOptionalMessage,
}
