// @flow

import MESSAGES from './messages'

const {
  CALCULATE_PREMIUM,
  INSURED_PERSON,
  BENEFIT_ILLUSTRATION,
  MARKET_CONDUCTS,
  NEED_AND_GAP_ANALYSIS,
  PRODUCT_SELECTION,
  MATURITY_VALIDATE,
  SELECT_POLICY_OWNER,
} = MESSAGES

export default {
  CALCULATE_PREMIUM,
  INSURED_PERSON,
  BENEFIT_ILLUSTRATION,
  MARKET_CONDUCTS,
  NEED_AND_GAP_ANALYSIS,
  PRODUCT_SELECTION,
  RISK: 'ความเสี่ยง',
  PREMIUM: 'เบี้ยฯ',
  FULL_NAME_PREMIUM: 'เบี้ยประกันภัยเพื่อการลงทุนและความคุ้มครอง',
  FUND: 'การลงทุน',
  RPP_FUND: 'การลงทุน RPP',
  LSTU_FUND: 'การลงทุน LSTU',
  MATURITY_VALIDATE,
  SELECT_POLICY_OWNER,
}
