// @flow

import type { Profile, DistributorGroupType } from 'core/data-model/distributor'
import type { InsuredProps, PayerProps, StaffProps } from 'core/service/pdf-generation/products/common/types'
import { Section, Bold } from 'core/service/pdf-generation/products/common/components/general'
import { renderUnit } from 'core/service/insured/age'
import { signBox, form } from 'core/service/pdf-generation/products/common/components/sign-box'
import { getInsuredFullName } from 'core/service/pdf-generation/utils/insured'
import Mustache from 'mustache'
import BIMESSAGES from 'core/data-model/constants/bi-messages'
import MESSAGES from 'core/data-model/constants/messages'
import VALUE from 'core/data-model/constants/values'
import type { FundAllocationToggleItem } from 'core/data-model/investment/types'
import { _isRequiredWealthHubStaff } from 'identity/fse-branch/services'
import { getToggles } from 'quick-quote/feature-toggles'

// const complaintContact = () => [
//   {
//     colSpan: 2,
//     stack: [
//       {
//         text: [Bold('การร้องเรียนสำหรับผู้เอาประกันภัย: '), 'ผู้เอาประกันภัยสามารถร้องเรียนได้ที่หน่วยงานดังต่อไปนี้'],
//       },
//       {
//         ol: [
//           {
//             stack: [
//               'ศูนย์ลูกค้าสัมพันธ์ บริษัท กรุงไทย-แอกซ่า ประกันชีวิต จำกัด (มหาชน) เลขที่ 9 อาคาร จี ทาวเวอร์ แกรนด์ รามา 9 ชั้น 1,22-27 ถนน พระราม 9 แขวงห้วยขวาง เขตห้วยขวาง กทม. 10310',
//               'โทร. 1159 อีเมล: customer-care@krungthai-axa.co.th',
//             ],
//           },
//           'สำนักคณะกรรมการกำกับและส่งเสริมการประกอบธุรกิจประกันภัย สายด่วนประกันภัย โทร. 1186 หรือ www.oic.or.th',
//           'สำนักคณะกรรมการกำกับหลักทรัพย์และตลาดหลักทรัพย์ (สำนักงานคณะกรรมการ ก.ล.ต.) Help Center โทร. 0-2263-6000 หรือ www.sec.or.th',
//         ],
//       },
//     ],
//   },
//   {},
// ]

const complaintContact2022 = () => [
  {
    colSpan: 2,
    stack: [
      {
        text: [Bold('การร้องเรียนสำหรับผู้เอาประกันภัย: '), 'ผู้เอาประกันภัยสามารถร้องเรียนได้ที่หน่วยงานดังต่อไปนี้'],
      },
      {
        ol: [
          {
            stack: [
              'ศูนย์ลูกค้าสัมพันธ์ บริษัท กรุงไทย-แอกซ่า ประกันชีวิต จำกัด (มหาชน) เลขที่ 9 อาคาร จี ทาวเวอร์ แกรนด์ รามา 9 ชั้น 1,22-27 ถนน พระราม 9 แขวงห้วยขวาง เขตห้วยขวาง กทม. 10310',
              'โทร. 1159 อีเมล: customer-care@krungthai-axa.co.th',
            ],
          },
          'สำนักคณะกรรมการกำกับและส่งเสริมการประกอบธุรกิจประกันภัย สายด่วนประกันภัย โทร. 1186 หรือ www.oic.or.th',
          'สำนักคณะกรรมการกำกับหลักทรัพย์และตลาดหลักทรัพย์ (สำนักงานคณะกรรมการ ก.ล.ต.) Help Center โทร. 0-2263-6000 หรือ www.sec.or.th',
        ],
      },
    ],
  },
  {},
]

const insuredSign = (insured: InsuredProps) => {
  const name = getInsuredFullName(insured)
  const { signDate } = insured
  return [
    ['ผู้ขอเอาประกันภัย', ''],
    ['', Mustache.render(BIMESSAGES.SIGNATURE_NAME, { name })],
    ['วันที่', signDate || ''],
  ]
}

const insuredSignBox = (insured: InsuredProps) => signBox(insuredSign(insured))

const disclaimer = (insured: InsuredProps, productCode: string) => [
  {
    colSpan: 2,
    stack: [
      BIMESSAGES.SIGN_DISCLAIMER[productCode],
      {
        alignment: 'right',
        columns: [{}, insuredSignBox(insured)],
      },
    ],
  },
  {},
]

const noBorder = [false, false, false, false]
const borderBottom = [false, false, false, true]
const vAligment = { marginTop: 5, marginBottom: -1 }
const insuredConsentSignBox = ({ firstName, lastName, age, relationship, signDate }: PayerProps = {}) => ({
  fontSize: 12.5,
  stack: [
    'กรณีผู้ขอเอาประกันภัยยังไม่บรรลุนิติภาวะ ให้ผู้ใช้อำนาจปกครองหรือผู้แทนโดยชอบธรรมของผู้เยาว์ เป็นผู้ลงลายมือชื่อ กระทำการแทนหรือให้ความยินยอมแก่ผู้เยาว์ในการเสนอขอเอาประกันภัย และเป็นเจ้าของกรมธรรม์ประกันภัยที่ออกให้ พร้อมทั้งระบุความสัมพันธ์กับผู้เอาประกันภัยตามรายละเอียดข้างล่างนี้',
    {
      table: {
        widths: ['3%', '*'],
        body: [
          [
            { text: MESSAGES.FIRST_NAME, border: noBorder, ...vAligment },
            { text: [firstName || ' ', lastName || ' '].join(' '), border: borderBottom, ...vAligment },
          ],
        ],
      },
    },
    {
      table: {
        widths: ['5%', '13%', '15%', '*'],
        body: [
          [
            { text: MESSAGES.AGE, border: noBorder, ...vAligment },
            { text: (age && [age.value, renderUnit(age.unit)].join(' ')) || ' ', border: borderBottom, ...vAligment },
            { text: BIMESSAGES.RELATIONSHIP, border: noBorder, alignment: 'right', ...vAligment },
            { text: relationship || ' ', border: borderBottom, ...vAligment },
          ],
        ],
      },
    },
    {
      table: {
        widths: ['*'],
        body: [
          [{ text: ' ', border: borderBottom, marginTop: 10 }],
          [{ text: BIMESSAGES.SIGNATURE_OWNER, border: noBorder, ...vAligment }],
        ],
      },
    },
    {
      table: {
        widths: ['5%', '*'],
        body: [
          [
            { text: BIMESSAGES.DATE, border: noBorder, ...vAligment },
            { text: signDate || ' ', border: borderBottom, ...vAligment },
          ],
        ],
      },
    },
  ],
})

export const _getFullName = (profile: Profile) => {
  const fullName = profile.given_name && profile.family_name ? `${profile.given_name} ${profile.family_name}` : ''
  return fullName ? Mustache.render(BIMESSAGES.SIGNATURE_NAME, { name: fullName }) : ' '
}

const getAgentBranch = (profile: Profile, distributorType: DistributorGroupType): string => {
  if (distributorType === 'BANC') return profile.branchName || ''
  else return profile.unitCode || '-'
}
const getAgentId = (profile: Profile, distributorType: DistributorGroupType): string => {
  if (distributorType === 'BANC') return profile.lbCode || ''
  else return profile.agentCode || ''
}
export const distributorDetails = (profile: Profile, distributorType: DistributorGroupType) => {
  const enableAddBasicLicenseBi = getToggles().ENABLE_ADD_BASIC_LICENSE_BI

  if (enableAddBasicLicenseBi) {
    return [
      [BIMESSAGES.DISTRIBUTOR_OR_AGENT_SIGN, ''],
      ['', _getFullName(profile)],
      [BIMESSAGES.BRANCH_UNIT_AGENT, getAgentBranch(profile, distributorType)],
      [
        BIMESSAGES.DISTRIBUTOR_OR_AGENT_SIGNATURE_AGENT_ID,
        `${getAgentId(profile, distributorType)} (เลขที่ใบอนุญาต ${profile.basicLicenseNumber})`,
      ],
      [BIMESSAGES.INVESTOR_ID, profile.singleLicenseNumber || ''],
      [BIMESSAGES.DATE, profile.signDate || ''],
    ]
  }

  return [
    [BIMESSAGES.DISTRIBUTOR_OR_AGENT_SIGN, ''],
    ['', _getFullName(profile)],
    [BIMESSAGES.BRANCH_UNIT_AGENT, getAgentBranch(profile, distributorType)],
    [BIMESSAGES.DISTRIBUTOR_OR_AGENT_SIGNATURE_AGENT_ID, getAgentId(profile, distributorType)],
    [BIMESSAGES.INVESTOR_ID, profile.singleLicenseNumber || ''],
    [BIMESSAGES.DATE, profile.signDate || ''],
  ]
}

const agentBancSignBox = (profile: Profile, distributorType: DistributorGroupType) => {
  const enableAddBasicLicenseBi = getToggles().ENABLE_ADD_BASIC_LICENSE_BI
  if (enableAddBasicLicenseBi) {
    return {
      fontSize: 12.5,
      stack: [
        signBox(distributorDetails(profile, distributorType), {
          4: { marginTop: 13 },
          1: { fontSize: name.length > 27 ? 11 : 12.5 },
          2: { fontSize: (profile.branchName || '').length > 27 ? 11 : 12.5 },
        }),
      ],
    }
  }
  return {
    fontSize: 12.5,
    stack: [signBox(distributorDetails(profile, distributorType))],
  }
}

const wealthHubBox = (staff: StaffProps) => {
  const onlyForWeathHub = {
    text: [
      {
        text: 'สำหรับ Wealth at Hub หรือผู้จัดการสาขา เป็นผู้่ให้คำรับรองเท่านั้น',
        decoration: 'underline',
        bold: true,
      },
    ],
    alignment: 'right',
  }

  const weathHubDesc = {
    text:
      'ผู้ให้คำรับรองปฏิบัติหน้าที่เป็นที่ปรึกษาด้านการลงทุนประจำสำนักงานเขต (Wealth at Hub) หรือผู้จัดการสาขา ผู้ดูแลรับผิดชอบหน่วยงานที่อยู่ในสังกัดสายงานเครือข่ายธุรกิจขนาดเล็กหรือรายย่อยเท่านั้น',
  }

  const alignRight = {
    label: 20,
    field: '*',
  }

  const weathHubForm = {
    stack: [
      form(['ชื่อ', staff.name, 'นามสกุล', staff.surname]),
      form(['รหัสพนักงาน', staff.id, 'ตำแหน่งงาน', staff.position]),
    ],
    lineHeight: 0.7,
    width: '*',
  }

  const sign = {
    stack: [form(['ลงชื่อ', ''], alignRight), form(['วันที่', staff.signDate || ''], alignRight)],
    lineHeight: 0.7,
    width: '25%',
  }

  return [
    {
      stack: [onlyForWeathHub, weathHubDesc, { columns: [weathHubForm, sign], marginTop: -2 }],
      colSpan: 2,
      marginBottom: 2,
    },
    {},
  ]
}

const Contents = (props: SignProps) => {
  const requireFields = {
    category: props.productCategory,
    rpulFundAlloc: props.allocations,
  }

  const isWealthHub = _isRequiredWealthHubStaff(requireFields, props.action, props.profile.agent_type)
  const defaultSignContents = [
    complaintContact2022(),
    disclaimer(props.insured, props.productCode),
    [
      insuredConsentSignBox(props.payer),
      agentBancSignBox({ ...props.profile, datetime: props.datetime }, props.distributorType),
    ],
  ]

  const signContents = {
    fontSize: 12,
    table: {
      widths: [370, '*'],
      body: isWealthHub ? [...defaultSignContents, wealthHubBox(props.staff)] : defaultSignContents,
    },
    layout: {
      hLineColor: VALUE.MEDIUM_BLUE,
      vLineColor: VALUE.MEDIUM_BLUE,
    },
  }

  return [signContents]
}

type SignProps = {
  insured: InsuredProps,
  payer: PayerProps,
  distributorType: DistributorGroupType,
  profile: Profile,
  staff: StaffProps,
  productCategory: 'INVESTMENT',
  productCode: string,
  allocations: FundAllocationToggleItem[],
  action: string,
}

export const Sign = (props: SignProps) => Section('', Contents(props))
