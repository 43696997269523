/* istanbul ignore file */

import styled from 'styled-components'
import { Checkbox } from 'common-components'
import { compose, withHandlers, defaultProps, renameProp } from 'recompose'

export const Component = styled(Checkbox)`
  color: inherit !important;
`

export default (hoc) =>
  compose(
    defaultProps({
      value: false,
    }),
    withHandlers({
      onChange: ({ setValue, value }) => (event) => {
        setValue(!value)
      },
    }),
    renameProp('value', 'checked'),
    hoc
  )(Component)
