// @flow
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'
import { Input } from 'reactstrap'

import { getPercentCoveragePeriod } from 'quick-quote/product-mrta/coverage-plan/selectors'

type PercentCoveragePeriodProps = {
  isReadOnly: boolean,
  percentCoveragePeriod: number,
  enabled: boolean,
}

const PercentCoveragePeriod = ({ isReadOnly, percentCoveragePeriod, enabled = true }: PercentCoveragePeriodProps) => {
  if (enabled) {
    return (
      <NumberFormat
        id="percent-coverage-period-input"
        disabled={Boolean(isReadOnly)}
        isAllowed={({ formattedValue }) => formattedValue.length <= 17}
        customInput={Input}
        value={percentCoveragePeriod}
        // thousandSeparator={true}
        allowNegative={false}
        type="tel"
        pattern="[0-9]*"
        onFocus={(e) => e.target.setSelectionRange(0, -1)}
        // onChange={(e, values) => editSumAssured(Number(values.value))}
      />
    )
  } else {
    return <Input id="percent-coverage-period-input" value="-" disabled={true} />
  }
}

const mapStateToProps = (state: AppState) => ({
  percentCoveragePeriod: getPercentCoveragePeriod(state),
})

export default connect(mapStateToProps, {})(PercentCoveragePeriod)
