// @flow
/* istanbul ignore file */
import _ from 'lodash'
import { flow } from 'lodash/fp'
import VALUES from 'core/data-model/constants/values'
import moment from 'moment-timezone'
import config from 'config'
import { isEmpty } from 'lodash'

export type ConfigMap = {
  ENV_LOCATION: string,
  AUTH_LOGIN_URL: string,
  AUTH_AUTHORITY: string,
  ADVISORZONE_MIDDLEWARE: string,
  IAM_LOGOUT_URL: string,
  IAMC_LOGOUT_URL: string,
  KEYCLOAK_PROFILE_URL: string,
  SYNC_GATEWAY_URL: string,
  CORDOVA_CODE_PUSH_URL: string,
  AURA_INTEGATION_URL: string,
  CYBERSOURCE_PAY_URL: string,
  GA_TRACKING_ID: string,
  MIN_APPVERSION: String,
  toggles: Object,
}

let APP = {
  CONFIG: {},
}

// https://github.com/github/fetch/pull/92#issuecomment-174730593
// patched to remove dependency on Response function which is not present in iOS 9 and below
const fetchFile = (url): Promise<ConfigMap> => {
  return new Promise(function(resolve, reject) {
    var xhr = new XMLHttpRequest()
    xhr.onload = function() {
      const statusCode = window.cordova && xhr.status === 0 ? 200 : xhr.status
      try {
        const response = JSON.parse(xhr.responseText)
        if (statusCode >= 400) {
          reject(new Error(`Could not fetch file from ${url}`))
        } else {
          resolve(response)
        }
      } catch (err) {
        reject(new Error(`Could not fetch file from ${url}`))
      }
    }
    xhr.onerror = function() {
      reject(new TypeError('Local request failed'))
    }
    xhr.open('GET', url)
    xhr.send(null)
  })
}

export const getToggleScheduleRule = (toggle) => {
  if (!toggle.scheduleRule) toggle.defaultValue || false

  const _isOnSchedule = (toggle) => {
    const start = moment(toggle.scheduleRule.startDate, VALUES.DATE_FORMAT, VALUES.TIME_ZONE)
    const end = moment(toggle.scheduleRule.endDate, VALUES.DATE_FORMAT, VALUES.TIME_ZONE)
    const current = moment(new Date(Date.now())).tz(VALUES.TIME_ZONE)
    return current.isSameOrAfter(start) && current.isSameOrBefore(end)
  }

  return _isOnSchedule(toggle) ? toggle.scheduleRule.value : toggle.defaultValue || false
}

const mapToggles = (toggles) => {
  return _.mapValues(toggles, (toggle) => {
    if (_.isObject(toggle)) {
      return flow(getToggleScheduleRule)(toggle)
    }
    return toggle
  })
}

export const setAppConfig = (configMap: ConfigMap | void) => {
  configMap.toggles = mapToggles(configMap.toggles)
  APP.CONFIG = configMap || {}
}

export const loadConfig = (): Promise<*> => {
  const basePath = process.env.BASE_PATH || ''
  const configJsonPath = window.cordova ? 'deploy-config/config.json' : `${basePath}/deploy-config/config.json`
  return fetchFile(configJsonPath)
    .then(setAppConfig)
    .catch((e: Error) => {
      console.warn('Error when loading config from server: ' + e.toString())
    })
    .then(getAppConfig)
}

export const compateLt = (number1, number2) => {
  const arrayN1 = _.split(number1.replace(/[^.0-9 ]/g, ''), '.')
  const arrayN2 = _.split(number2.replace(/[^.0-9 ]/g, ''), '.')
  const maxLength = arrayN1.length > arrayN2.length ? arrayN2.length : arrayN1.length
  for (var i = 0; i < maxLength; i++) {
    const n1 = parseInt(arrayN1[i])
    const n2 = parseInt(arrayN2[i])
    if (n1 < n2) {
      return true
    }
  }
  return false
}

export const checkVersionNativeApp = async (): Promise<*> => {
  let error = null
  let minVersion = '0.0.0'
  let appVersion = '0.0.0'

  try {
    appVersion = await window.getAppVersionByBridging()
  } catch (e) {
    return error
  }
  try {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    minVersion = _.get(userInfo, 'azMWMinVersion', '0.0.0')
    if (appVersion.includes('-')) {
      appVersion = config.appVersion.substring(0, config.appVersion.indexOf('-'))
    }
    if (compateLt(appVersion, minVersion)) {
      error = new Error('Native app version require min app version')
    }
  } catch (e) {
    error = new Error('Error when loading config from server: ' + e.toString())
  }
  return error
}

const getPermissionFromLocalstorage = () => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  if (!isEmpty(userInfo)) {
    return userInfo.isEnableRemoteSelling
  }
  return true
}

const getUserTypeFromLocalstorage = () => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  if (!isEmpty(userInfo)) {
    return userInfo.agent_type
  }
  return true
}

export const getAppConfig = (): Object => {
  APP.CONFIG['ESUB_BUCKET_URL'] = `${APP.CONFIG.SYNC_GATEWAY_URL}/esub`
  APP.CONFIG['APPLICATION_BUCKET_URL'] = `${APP.CONFIG.SYNC_GATEWAY_PROXY_URL}/application`
  APP.CONFIG['ADMIN_APPLICATION_BUCKET_URL'] = `${APP.CONFIG.SYNC_GATEWAY_PROXY_URL}/application`
  APP.CONFIG['APPLICATION_BUCKET_URL_AUTHZ_CODE_FLOW'] = `${APP.CONFIG.ADVISORZONE_MIDDLEWARE}/sync-gateway/application`
  _.set(
    APP.CONFIG,
    'toggles.ENABLE_APPMAN_REMOTE_SELLING',
    _.get(APP.CONFIG, 'toggles.ENABLE_APPMAN_REMOTE_SELLING') && getPermissionFromLocalstorage()
  )
  _.set(
    APP.CONFIG,
    'toggles.ENABLE_SHOW_REMOTESELLING_ONWEB',
    _.get(APP.CONFIG, 'toggles.ENABLE_SHOW_REMOTESELLING_ONWEB') && getPermissionFromLocalstorage()
  )
  _.set(
    APP.CONFIG,
    'toggles.ENABLE_CHECK_TARGET_VERSION',
    _.get(APP.CONFIG, 'toggles.ENABLE_CHECK_TARGET_VERSION') && getUserTypeFromLocalstorage() != 'LB'
  )
  return APP.CONFIG
}
