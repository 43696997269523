//@flow
import { get } from 'lodash'
import { mapProps, compose, lifecycle } from 'recompose'
import { ModalBody, ModalHeader, Button } from 'reactstrap'
import { connect } from 'react-redux'

import { optionsSendLinkMethod } from './constants'
import { getInsured, getCitizenId } from 'e-submission/domain/data-model/form/selectors'
import { getCurrentApp } from '../../apps/selectors'
import { setSendLinkTextInput, setSendLinkMethod } from '../../apps/actions'
import createTextInput from '../../components/Form/Input/TextInput'
import { Modal } from '../../../common-components'
import type { AppState } from 'quick-quote/reducers'
import { validateSendLinkInputPopup } from '../../domain/data-model/form/validation'

type EkycOnDeviceProps = {
  isOpen: Boolean,
  onExit: Function,
  handlerCreateCaseEkyc: Function,
  insuredOrPayer: String,
}

const hocText = mapProps(({ value, onChange }) => {
  return {
    value,
    onChange,
  }
})

const TextInput = createTextInput(hocText)
const SendLinkButton = (props) => {
  const { onExit, isInvalid, handlerCreateCaseEkyc } = props
  return (
    <div style={{ textAlign: 'center' }}>
      <Button
        color={isInvalid ? 'disabled' : 'primary'}
        className="primary"
        onClick={() => {
          handlerCreateCaseEkyc()
          onExit()
        }}
      >
        ยืนยัน
      </Button>
    </div>
  )
}
export const _EkycOnDevice = ({
  insuredOrPayer,
  isOpen,
  openModal,
  onExit,
  options,
  setMethod,
  method,
  textInput,
  setTextInput,
  validateInput,
  citizenId,
  handlerCreateCaseEkyc,
}: EkycOnDeviceProps) => {
  const isInvalid = validateInput && !validateInput.isOk
  return (
    <Modal
      isOpen={isOpen}
      size="md"
      modalClassName="default-bootstrap remote-selling-modal"
      toggle={() => {
        onExit()
      }}
    >
      <ModalHeader
        toggle={() => {
          onExit()
        }}
      >
        ยืนยันตัวตนบนอุปกรณ์
      </ModalHeader>
      <ModalBody>
        ทดสอบ ยืนยันตัวตนบนอุปกรณ์
        {/* <div className={`form-group ${isInvalid ? 'has-danger' : ''}`}>
          <RadioButton options={options} value={method} onChange={(e) => setMethod(e)} />
          {method === 'email' ? (
            <TextInput value={textInput} setValue={setTextInput} />
          ) : (
            <NumberInput
              value={textInput}
              format={'##########'}
              onChange={(e) => setTextInput(e.target.value)}
              className="form-control"
            />
          )}
          {!isInvalid ? null : renderErrorMessage('validate-method-error-message')(validateInput, 'VALIDATION')}
          <div
            style={{ minWidth: '100%' }}
            className="validation-message attached-icon info-message-wrap form-control-feedback"
          >
            <p>ท่านสามารถเลือกช่องทางในการส่งลิงก์ผ่านทางช่องทางที่ท่านต้องการ</p>
          </div>
        </div>
        <SendLinkButton
          onExit={() => onExit()}
          openModal={() => openModal()}
          isInvalid={isInvalid}
          handlerCreateCaseEkyc={() => handlerCreateCaseEkyc(citizenId[insuredOrPayer])}
        /> */}
      </ModalBody>
    </Modal>
  )
}

const mapStateToSendEkycLinkPopup = (state: AppState) => {
  const app = getCurrentApp(state)
  const insuredInfo = getInsured(app)

  const insuredId = getCitizenId('insured')(app)
  const payerId = getCitizenId('payer')(app)

  const insuredEmail = get(insuredInfo, 'email', '')
  const insuredMobileNo = get(insuredInfo, 'mobileNumber', '')
  const textInput = get(app, ['ekycInfo', 'sendLink', 'textInput'], insuredMobileNo)
  const method = get(app, ['ekycInfo', 'sendLink', 'method'], 'sms')
  const validateInput = validateSendLinkInputPopup({ textInput }, method)

  return {
    insuredEmail,
    insuredMobileNo,
    textInput,
    method,
    validateInput,
    options: optionsSendLinkMethod,
    citizenId: { insured: insuredId, payer: payerId },
  }
}

const mapDispatchToSendEkycLinkPopup = (dispatch: Dispatch<*>) => ({
  setTextInput: (e) => dispatch(setSendLinkTextInput('ekycInfo.sendLink.textInput', e)),
  setMethod: (e) => dispatch(setSendLinkMethod('ekycInfo.sendLink.method', e)),
})

export default compose(
  connect(mapStateToSendEkycLinkPopup, mapDispatchToSendEkycLinkPopup),
  lifecycle({
    componentDidMount() {
      const { setTextInput, insuredMobileNo } = this.props
      setTextInput(insuredMobileNo)
    },
    componentDidUpdate(prevProps) {
      const { method, setTextInput, insuredMobileNo, insuredEmail } = this.props
      if (method !== prevProps.method) {
        setTextInput(method === 'sms' ? insuredMobileNo : insuredEmail)
      }
    },
    UNSAFE_componentWillReceiveProps(nextProps) {
      const { isOpen, setMethod } = this.props
      if (!isOpen && isOpen !== nextProps.isOpen) setMethod('sms')
    },
  })
)(_EkycOnDevice)
