import { mapProps } from 'recompose'
import { get } from 'lodash'
import NumberInput from 'react-number-format'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { getCurrentApp } from '../../../apps/selectors'
import { getInsured } from 'e-submission/domain/data-model/form/selectors'
import { setSendLinkTextInput, setSendLinkMethod } from '../../../apps/actions'
import Heading from 'e-submission/components/Heading'
import SubHeading from 'e-submission/components/Heading/SubHeading'
import RadioButton from '../../../../common-components/radio-button-group'
import { optionsSendLinkMethod } from '../constants'
import createTextInput from '../../../components/Form/Input/TextInput'
import { renderErrorMessage } from 'common-components/view-logic'
import { validateSendLinkInputPopup } from '../../../domain/data-model/form/validation'

const hocText = mapProps(({ value, onChange }) => {
  return {
    value,
    onChange,
  }
})

const TextInput = createTextInput(hocText)

const Container = styled.div`
  padding: 1rem 1rem 0 1rem;

  @media (max-width: 390px) {
    padding: 1rem 0 0;
  }
`
export const ResendLinkLinkSection = ({ method, setMethod, textInput, setTextInput, validateInput }) => {
  const isInvalid = validateInput && !validateInput.isOk

  return (
    <Container>
      <Heading>สร้างลิงก์ใหม่</Heading>
      <SubHeading>เลือกช่องทางส่งลิงก์ให้ลูกค้า</SubHeading>
      <div className={`form-group ${isInvalid ? 'has-danger' : ''}`}>
        <RadioButton options={optionsSendLinkMethod} value={method} onChange={(e) => setMethod(e)} />
        {method === 'email' ? (
          <TextInput value={textInput} setValue={setTextInput} />
        ) : (
          <NumberInput
            value={textInput}
            format={'##########'}
            onChange={(e) => setTextInput(e.target.value)}
            className="form-control"
          />
        )}
        {!isInvalid ? null : renderErrorMessage('validate-method-error-message')(validateInput, 'VALIDATION')}
      </div>
    </Container>
  )
}

const mapStateToProps = (state) => {
  const app = getCurrentApp(state)
  const insuredInfo = getInsured(app)

  const insuredMobileNo = get(insuredInfo, 'mobileNumber', '')
  const textInput = get(app, ['ekycInfo', 'sendLink', 'textInput'], insuredMobileNo)
  const method = get(app, ['ekycInfo', 'sendLink', 'method'], 'sms')
  const validateInput = validateSendLinkInputPopup({ textInput }, method)

  return { textInput, method, validateInput }
}
const mapDispatchToProps = (dispatch) => ({
  setTextInput: (e) => dispatch(setSendLinkTextInput('ekycInfo.sendLink.textInput', e)),
  setMethod: (e) => dispatch(setSendLinkMethod('ekycInfo.sendLink.method', e)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ResendLinkLinkSection)
