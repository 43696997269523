// @flow

import ProductBenefits from './product-benefits/product-benefits'
import { SidebarMenu } from 'quick-quote/components'
import HealthCheckRule from './health-check-rule'
import type { DisplayProduct } from 'core/service/display-product'
import { isNil } from 'core/service/lib/type-check'
import MESSAGES from 'core/data-model/constants/bi-messages'

export type BenefitIllustrationProps = {
  displayProduct: ?DisplayProduct,
  coverageTerm: number,
  loanTerm: number,
  sumAssured: number,
  basicPremium: number,
  healthCheckList: string[],
  financialCheckList: string[],
}

const BenefitIllustration = ({
  displayProduct,
  coverageTerm,
  loanTerm,
  sumAssured,
  basicPremium,
  healthCheckList,
  financialCheckList,
}: BenefitIllustrationProps) => {
  if (isNil(displayProduct)) return <div id="benefit-illustration-invalid" />
  else {
    return (
      <div id="benefit-illustration">
        <div className="content">
          <ProductBenefits
            displayProduct={displayProduct}
            sumAssured={sumAssured}
            basicPremium={basicPremium}
            loanTerm={loanTerm}
            coverageTerm={coverageTerm}
          />
          <HealthCheckRule healthCheckList={healthCheckList} financialCheckList={financialCheckList} />
        </div>
        <SidebarMenu
          mainMenu={[
            {
              text: MESSAGES.SUMMARY_OF_MAIN_CONTRACTS,
              id: 'profile-summary',
              isSub: false,
            },
            {
              text: 'รายละเอียดของสัญญาหลัก',
              id: 'product-benefits',
              isSub: false,
            },
            {
              text: MESSAGES.HEALTH_CHECK_RULE,
              id: 'product-benefits-document',
              isSub: false,
            },
            {
              text: MESSAGES.POLICY_VALUE_TABLE,
              id: 'policy-value',
              isSub: false,
            },
            {
              text: MESSAGES.LEGAL_DISCLAIMER,
              id: 'legal-disclaimer',
              isSub: false,
            },
          ]}
        />
      </div>
    )
  }
}

export default BenefitIllustration
