import styled from 'styled-components'
import { Flex, Box } from 'rebass'

const Card = styled(Flex)`
  background: white;
  border: 1px solid ${({ theme }) => theme.variables['$color-mercury']};
  & + & {
    border-top: none;
  }
  ${({ disabled }) => disabled && 'pointer-events: none;'}}
`

export const BaseDetailBox = styled(({ label, children, ...props }) => (
  <Box {...props}>
    {label && <label>{label}:</label>} {children}
  </Box>
))`
  margin: 0 10px 0 0;
  font-size: 1rem;
  label {
    margin: 0;
    color: ${({ theme }) => theme.variables['$color-dusty-gray']};
  }
`

export const RiderTags = styled(({ className, riders }) => (
  <div className={className}>
    {riders.map(({ name }, index) => (
      <RiderTag key={index + name}>{name}</RiderTag>
    ))}
  </div>
))`
  & > span {
    display: inline-block;
    padding: 0 7px;
    & + span {
      margin-left: 5px;
    }
  }
`

export const RiderTag = styled.span`
  font-size: ${({ theme }) => theme.variables['$font-size-navigation']};
  line-height: 17px;
  border: 1px solid ${({ theme }) => theme.variables['$color-dusty-gray']};
`

export const NameBox = styled(Box)`
  font-size: 1.125rem;
  margin: 0 10px 0 0;
  font-weight: bold;
  line-height: 1;
`

export default Card
