//@flow
import { set } from 'lodash/fp'

import type { Action } from './actions'
import ACTIONS from './action-types'

export type SaleFlowType = 'fna' | 'quick-quote'

const initialPortal = {
  saleFlow: '',
}

type PortalState = {
  saleFlow: string,
}

const portal = (state: PortalState = { ...initialPortal }, action: Action) => {
  switch (action.type) {
    case ACTIONS.START_QQ_FLOW:
      return set('saleFlow', 'quick-quote')(state)
    case ACTIONS.START_FNA_FLOW:
      return set('saleFlow', 'fna')(state)
    default: {
      return state
    }
  }
}

export const reducer = portal
export default reducer
