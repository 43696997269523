//@flow
import { compose, lifecycle } from 'recompose'
import { ModalBody, ModalHeader, Button } from 'reactstrap'
import { connect } from 'react-redux'

import Checkbox from 'common-components/checkbox'
import { getIcon } from 'e-submission/components/Icon'
import { Modal } from 'common-components/index'
import type { AppState } from 'quick-quote/reducers'
import { removeError, setAppValue, setResendEkycLink } from '../../../apps/actions'
import { getCurrentApp } from 'e-submission/apps/selectors'
import _getOr from 'lodash/fp/getOr'
import { isOnDeviceEKYC } from 'e-submission/domain/data-model/form/selectors'
import { IsRealDevice, isDisable2ndDoc as shouldDisable2ndDoc } from 'e-submission/utils'

type _IdentifyEkycRespondingModal = {
  isOpen: Boolean,
  onExit: Function,
  identifyEkycRespond: String,
}

const shouldDisabledSubmitButton = (isFailedState, isSkipEkyc) => {
  if (isSkipEkyc) return false
  return isFailedState
}

const resource = {
  success: {
    icon: getIcon('ekycStatusSuccess'),
    titleText: function() {
      return 'สร้างลิงก์สำเร็จ'
    },
    detailText: function() {
      return 'ระบบได้ทำการสร้างลิงก์ \n\t เพื่อส่งขั้นตอนยืนยันตัวตนให้กับ<br class="d-md-none d-block">ผู้เอาประกันหรือผู้ชำระเบี้ยสำเร็จ'
    },
  },
  failed: {
    icon: getIcon('ekycStatusError'),
    titleText: function(isOnDeviceEkyc, isDisable2ndDoc) {
      if (isDisable2ndDoc) {
        return 'ขณะนี้ระบบไม่สามารถดำเนินการยืนยันตัวตนได้'
      }

      return isOnDeviceEkyc && IsRealDevice ? 'สร้างเคสไม่สำเร็จ' : 'สร้างลิงก์ไม่สำเร็จ'
    },
    detailText: function(isOnDeviceEkyc, isDisable2ndDoc) {
      if (isDisable2ndDoc) {
        return 'ท่านสามารถปิดหน้าต่างนี้เพื่อทำการยืนยันตัวตนอีกครั้ง'
      }

      return isOnDeviceEkyc && IsRealDevice
        ? 'ระบบไม่สามารถทำการสร้างเคสนี้ได้ \n\t ท่านสามารถปิดหน้าต่างนี้เพื่อทำการยืนยันตัวตนอีกครั้ง'
        : 'ระบบไม่สามารถทำการสร้างลิงก์ของเคสนี้ได้ \n\t ท่านสามารถปิดหน้าต่างนี้เพื่อทำการส่งลิงก์ในการยืนยันตัวตนอีกครั้ง'
    },
  },
  warning: {
    icon: getIcon('ekycStatusWarning'),
    titleText: function() {
      return 'ระบบขัดข้อง'
    },
    detailText: function() {
      // eslint-disable-next-line quotes
      return `กรุณากดยืนยันเพื่อกลับไปยังหน้าแรกเพื่อทำการส่งลิงก์อีกครั้ง \n\t หรือ ทำการปิดหน้าต่างนี้และเลือกคำสั่งใหม่อีกครั้ง`
    },
  },
}

const SubmitButton = (props) => {
  const { onExit, isInvalid, isSkipEkyc, setSkippedEkyc } = props
  const isDisabled = shouldDisabledSubmitButton(isInvalid, isSkipEkyc)
  return (
    <div className={'normal-text detail-text button-submit'}>
      <Button
        color={isDisabled ? 'disabled' : 'primary'}
        className="primary"
        onClick={() => {
          onExit()
          if (isInvalid) setSkippedEkyc()
        }}
        disabled={isDisabled}
      >
        ยืนยัน
      </Button>
    </div>
  )
}
const AdditionalForFailedCase = (props) => {
  const { toggleSkippingEKYC, isSkipEkyc, isDisable2ndDoc } = props
  return (
    <div className={'additional-failed-container'}>
      <span className={'text-header'}>ยืนยันตัวตนด้วยวิธีการอัปโหลดเอกสารแทน</span>
      <div className={'form-group'}>
        <Checkbox
          checked={isSkipEkyc}
          onChange={() => {
            toggleSkippingEKYC()
          }}
        >
          เลือกเพื่อทำขั้นตอนการอัปโหลดเอกสารแทน โดยแนบบัตรประชาชน{!isDisable2ndDoc && ' และทะเบียนบ้าน'}
        </Checkbox>
      </div>
      <span className={'remark-text'}>
        {isDisable2ndDoc
          ? `กรุณาดำเนินการยืนยันตัวตนผ่าน eKYC Service หลังจากที่กดนำส่งงานเรียบร้อยแล้ว มิเช่นนั้นบริษัทจะไม่สามารถดำเนินการพิจารณาอนุมัติกรมธรรมได้`
          : `*หมายเหตุท่านจะต้องทำการยืนยันตัวตนอิเล็กทรอนิกส์ (eKYC) อีกครั้ง หลังจากส่งใบคำขอเข้าระบบ AdvisorZone`}
      </span>
    </div>
  )
}

class IdentifyEkycRespondingModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSkipEkyc: false,
    }
    this.toggleSkippingEKYC = this.toggleSkippingEKYC.bind(this)
  }

  toggleSkippingEKYC(forceToFalse) {
    this.setState((prevState) => {
      let isSkipEkyc = typeof forceToFalse === 'boolean' ? forceToFalse : !prevState.isSkipEkyc
      return {
        isSkipEkyc,
      }
    })
  }

  render() {
    const {
      identifyEkycRespond,
      insuredOrPayer,
      setSkippedEkyc,
      setResend,
      isOpen,
      onExit,
      removeError,
      isDuplicate,
      isReuseCase,
      isOnDeviceEkyc,
      isDisable2ndDoc,
    } = this.props
    const { isSkipEkyc } = this.state
    const isInvalidCase = identifyEkycRespond === 'failed'
    const resourceWithStateResponse = resource[identifyEkycRespond]
    const onExitWithRollbackSkippingEkycState = () => {
      this.toggleSkippingEKYC(false)
      removeError()
      onExit()
    }

    if (isDuplicate) {
      onExit()
      return null
    }

    return (
      <Modal
        className={'identify-ekyc-responding-modal'}
        isOpen={isOpen}
        size="md"
        modalClassName="default-bootstrap remote-selling-modal"
      >
        <ModalHeader
          className={'header-modal'}
          toggle={() => {
            onExitWithRollbackSkippingEkycState()
          }}
        />
        <ModalBody>
          <div className={`normal-text ${identifyEkycRespond}`}>{resourceWithStateResponse.icon}</div>
          <h5 className={'text-header normal-text'}>
            {resourceWithStateResponse.titleText(isOnDeviceEkyc, isDisable2ndDoc)}
          </h5>
          <div
            dangerouslySetInnerHTML={{ __html: resourceWithStateResponse.detailText(isOnDeviceEkyc, isDisable2ndDoc) }}
            className={'normal-text detail-text'}
          ></div>
          {isInvalidCase && (
            <AdditionalForFailedCase
              toggleSkippingEKYC={this.toggleSkippingEKYC}
              isSkipEkyc={isSkipEkyc}
              isDisable2ndDoc={isDisable2ndDoc}
            />
          )}
          <SubmitButton
            onExit={onExitWithRollbackSkippingEkycState}
            removeError={removeError}
            isInvalid={isInvalidCase}
            isSkipEkyc={isSkipEkyc}
            setSkippedEkyc={() => {
              setSkippedEkyc(insuredOrPayer, true)
            }}
          />
        </ModalBody>
      </Modal>
    )
  }
}

const mapStateToIdentifyEkycRespondingModal = (state: AppState) => {
  const app = getCurrentApp(state)
  const isOnDeviceEkyc = isOnDeviceEKYC(app)
  const isDisable2ndDoc = shouldDisable2ndDoc()

  return {
    payerEkyc: _getOr(false, ['ekycInfo', 'payer'], app),
    insuredEkyc: _getOr(false, ['ekycInfo', 'insured'], app),
    isOnDeviceEkyc,
    isDisable2ndDoc,
  }
}

const mapDispatchToIdentifyEkycRespondingModal = (dispatch: Dispatch<*>) => ({
  removeError: () => {
    dispatch(removeError('EKYC_VERIFY_CITIZENID'))
    dispatch(removeError('EKYC_REUSE_CASE'))
  },
  setSkippedEkyc: (key, value) => {
    dispatch(setAppValue(`ekycInfo.${key}.skipped`, value))
  },
  setResend: (insuredOrpayer) => {
    dispatch(setResendEkycLink(insuredOrpayer))
  },
})

export default compose(
  connect(mapStateToIdentifyEkycRespondingModal, mapDispatchToIdentifyEkycRespondingModal),
  lifecycle({
    componentDidMount() {},
    componentDidUpdate(prevProps) {},
    UNSAFE_componentWillReceiveProps(nextProps) {},
  })
)(IdentifyEkycRespondingModal)
