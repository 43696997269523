import { compose } from 'recompose'
import Header from 'e-submission/components/Heading'
import { getIcon } from 'e-submission/components/Icon'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  padding-left: 20px;

  label {
    padding: 1.6% 0 0 1%;
  }
`

export const HeadingWithIcon = ({ icon, title }) => (
  <Container>
    {getIcon(icon)}
    <Header>{title}</Header>
  </Container>
)

export default (hoc) => compose(hoc)(HeadingWithIcon)
