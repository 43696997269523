// @flow

import { formatNumber } from 'core/service/lib/number-format'
import PATH from 'quick-quote/constants/path'
import { Redirect } from 'react-router-dom'
import { get } from 'lodash/fp'
import { FundAllocationPieChart } from 'core/service/pdf-generation/products/investment/components/fund-allocation-pie-chart'
import type { DisplayProduct } from 'core/service/display-product'
import type { Age } from 'core/data-model/insured'
import { renderBIAge } from 'core/service/insured/age'
import Verification from './verification'
import MESSAGES from 'core/data-model/constants/messages'
import BIMESSAGES from 'core/data-model/constants/bi-messages'
import VALUE from 'core/data-model/constants/values'

export type ProfileSummaryProps = {
  genderLabel: string,
  age: Age,
  calculatedCoveragePeriod?: number,
  coveragePeriod: number,
  coverageDeath: number,
  regularPremium: number,
  regularTopUp: number,
  lumpSumTopUp: number,
  selectedModelFactorLabel: string,
}

export type BenefitIllustrationProps = ProfileSummaryProps & {
  displayProduct: DisplayProduct,
  canProceedToInvestmentBenefitIllustration: boolean,
  canProceedToFundAndCoveragePlan: boolean,
  pieChartFundData: { name: string, value: number }[],
  isBlockedEnteringESub: boolean,
}

const choseProductName = (displayProduct: DisplayProduct): string => {
  return displayProduct ? displayProduct.displayName : ''
}

const ProfileSummary = ({ productCode, ...props }) => {
  switch (productCode) {
    case VALUE.RPUL:
      return <RPULProfileSummary {...props} />
    case VALUE.SPUL:
      return <SPULProfileSummary {...props} />
    default:
      return null
  }
}

const RPULProfileSummary = ({
  genderLabel,
  age,
  coveragePeriod,
  coverageDeath,
  regularPremium,
  regularTopUp,
  selectedModelFactorLabel,
  isReviseQQ,
}: ProfileSummaryProps) => {
  return (
    <div id="profile-summary">
      <div className="coverage">
        <div className="info gender">
          <p className="label">{MESSAGES.GENDER}</p>
          <p className="value">{genderLabel}</p>
        </div>
        <div className="info age">
          <p className="label">{MESSAGES.AGE}</p>
          <p className="value">{renderBIAge(age, isReviseQQ)}</p>
        </div>
        <div className="info period">
          <p className="label">{BIMESSAGES.PAYMENT_PERIOD_BI}</p>
          <p className="value">
            {coveragePeriod} {MESSAGES.TIME_UNIT_YEAR}
          </p>
        </div>
        <div className="info cover">
          <p className="label">{BIMESSAGES.BASIC_PLAN_DEATH_BENEFIT}</p>
          <p className="value">
            {formatNumber(coverageDeath)} {MESSAGES.BAHT}
          </p>
        </div>
      </div>
      <div className="coverage">
        <div className="info regpremium">
          <p className="label">{BIMESSAGES.RPUL_REGULAR_PREMIUM_MINI}</p>
          <p className="value">
            {formatNumber(regularPremium)} {MESSAGES.BAHT}
          </p>
        </div>
        <div className="info topup">
          <p className="label">{BIMESSAGES.REGULAR_TOPUP_BI}</p>
          <p className="value">
            {formatNumber(regularTopUp)} {MESSAGES.BAHT}
          </p>
        </div>
        <div className="info model-factor">
          <p className="label">{MESSAGES.PAYMENT_MODE}</p>
          <p className="value">{selectedModelFactorLabel}</p>
        </div>
      </div>
    </div>
  )
}

const SPULProfileSummary = ({
  genderLabel,
  age,
  coveragePeriod,
  calculatedCoveragePeriod,
  coverageDeath,
  regularPremium,
  selectedModelFactorLabel,
  isReviseQQ,
}: ProfileSummaryProps) => {
  return (
    <div id="profile-summary">
      <div className="coverage">
        <div className="info gender">
          <p className="label">{MESSAGES.GENDER}</p>
          <p className="value">{genderLabel}</p>
        </div>
        <div className="info age">
          <p className="label">{MESSAGES.AGE}</p>
          <p className="value">{renderBIAge(age, isReviseQQ)}</p>
        </div>
        <div className="info period">
          <p className="label">{BIMESSAGES.COVERAGE_PERIOD}</p>
          <p className="value">
            {calculatedCoveragePeriod} {MESSAGES.TIME_UNIT_YEAR}
          </p>
        </div>
        <div className="info cover">
          <p className="label">{BIMESSAGES.BASIC_PLAN_DEATH_BENEFIT}</p>
          <p className="value">
            {formatNumber(coverageDeath)} {MESSAGES.BAHT}
          </p>
        </div>
      </div>
      <div className="coverage">
        <div className="info regpremium">
          <p className="label">{BIMESSAGES.PREMIUM}</p>
          <p className="value">
            {formatNumber(regularPremium)} {MESSAGES.BAHT}
          </p>
        </div>
        <div className="info model-factor">
          <p className="label">{MESSAGES.PAYMENT_MODE}</p>
          <p className="value">{selectedModelFactorLabel}</p>
        </div>
      </div>
    </div>
  )
}

export const BenefitIllustration = ({
  displayProduct,
  genderLabel,
  age,
  coveragePeriod,
  calculatedCoveragePeriod,
  coverageDeath,
  regularPremium,
  regularTopUp,
  lumpSumTopUp,
  selectedModelFactorLabel,
  canProceedToInvestmentBenefitIllustration,
  canProceedToFundAndCoveragePlan,
  allocationCategoryData,
  isBlockedEnteringESub,
  isReviseQQ,
}: BenefitIllustrationProps) => {
  if (!canProceedToFundAndCoveragePlan) {
    return <Redirect to={PATH.PRODUCT_SELECTION} push />
  }
  let productName = choseProductName(displayProduct)

  return (
    <div className="snapshot group">
      <h2 id="product-name">{productName}</h2>
      {canProceedToInvestmentBenefitIllustration ? (
        <ProfileSummary
          productCode={displayProduct.code}
          genderLabel={genderLabel}
          age={age}
          coveragePeriod={coveragePeriod}
          calculatedCoveragePeriod={calculatedCoveragePeriod}
          coverageDeath={coverageDeath}
          regularPremium={regularPremium}
          regularTopUp={regularTopUp}
          lumpSumTopUp={lumpSumTopUp}
          selectedModelFactorLabel={selectedModelFactorLabel}
          isReviseQQ={isReviseQQ}
        />
      ) : (
        <Verification productCode={displayProduct.code} />
      )}
      {get('rpp', allocationCategoryData) && (
        <FundAllocationPieChart data={allocationCategoryData.rpp} imageId="rpp" hide />
      )}
    </div>
  )
}

export const BenefitIllustrationContainer = (props: BenefitIllustrationProps) => {
  return (
    <div id="benefit-illustration">
      <div className="content">
        <BenefitIllustration {...props} />
      </div>
    </div>
  )
}

export default BenefitIllustrationContainer
