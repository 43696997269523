// @flow

import type { Rider, RiderState } from 'core/data-model/rider'
import RiderWithBenefitModal from './rider-benefit-modal'
import RiderBenefitSummary from './rider-benefit-summary'
import { AgentProtectionDisclaimer } from './agent-protection-disclaimer'

type WPRiderBenefitProps = {
  rider: Rider & RiderState,
}

const WPRiderBenefit = ({ rider }: WPRiderBenefitProps) => {
  const description = `ผลประโยชน์${rider.description}`
  const title = `${description} (${rider.name})`
  const benefitTable = (
    <div className="details-table">
      <p>
        กรณีทุพพลภาพสิ้นเชิงก่อนอายุ {rider.coveragePeriod.value} ปี บริบูรณ์
        จะได้รับการยกเว้นเบี้ยประกันภัยของสัญญาหลัก โดยผลประโยชน์จากสัญญาหลักยังเหมือนเดิม
      </p>
    </div>
  )

  const benefitRider: Rider & RiderState = { ...rider, description }
  return (
    <RiderWithBenefitModal id="wp-rider-benefit" className="rider wp-rider" title={title} modalChildren={benefitTable}>
      <RiderBenefitSummary rider={benefitRider} />
      {benefitTable}
      <AgentProtectionDisclaimer rider={rider} />
    </RiderWithBenefitModal>
  )
}

export default WPRiderBenefit
