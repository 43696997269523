//@flow
import type { DisplayProduct } from 'core/service/display-product'
import { formattedHeaderWithStyles, productInfoHeaderStyles } from '../../common/components/product-information-header'
import VALUES from 'core/data-model/constants/values'

export const render = (displayProduct: DisplayProduct, redline: string) => {
  const header1 = displayProduct.name
  const header2 =
    displayProduct.type === VALUES.CAMPAIGN_PRODUCT_TYPE
      ? `${displayProduct.displayName} (${displayProduct.basicPlanCode})`
      : `${displayProduct.nameThai} (${displayProduct.basicPlanCode})`
  return formattedHeaderWithStyles(header1, header2, redline, productInfoHeaderStyles)
}
