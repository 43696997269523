import _ from 'lodash'
import { createSectionCreator, createField } from './utils'
import { getSiblingPersonalTitleList } from 'e-submission/apps/selectors'

const dataSiblingFields = (app, id) => {
  const title = _.get(app, `${id}.title`)
  const isOtherTitle = _.get(title, 'value') === 'other'
  const isShowRemoveButton = !id.includes('[0]')
  return _.compact([
    isShowRemoveButton &&
      createField(
        `${id}.remove`,
        [
          {
            id: `${id}.remove`,
            c: 'Button',
            p: {
              icon: 'remove',
              children: 'ลบ',
              mergeProps: ({ removeValue }) => ({
                onClick: () => removeValue(id),
              }),
              style: {
                fontSize: '0.8725rem',
                padding: '0.125rem 0.5rem',
              },
            },
          },
        ],
        {
          style: {
            textAlign: 'right',
          },
        }
      ),
    createField(`${id}.title`, [
      {
        label: 'คำนำหน้า',
        id: `${id}.title`,
        c: 'Dropdown',
        required: true,
        p: {
          placeHolder: 'กรุณาเลือกคำนำหน้าชื่อ',
          mergeState: (state) => ({
            options: getSiblingPersonalTitleList(state),
          }),
        },
      },
    ]),
    isOtherTitle &&
      createField(`${id}.title.text`, [
        {
          label: 'ระบุคำนำหน้า',
          id: `${id}.title.text`,
          c: 'TextInput',
          required: true,
        },
      ]),
    createField(`${id}.firstName`, [
      {
        label: 'ชื่อ',
        id: `${id}.firstName`,
        required: true,
        c: 'TextInput',
      },
    ]),
    createField(`${id}.lastName`, [
      {
        label: 'นามสกุล',
        id: `${id}.lastName`,
        required: true,
        c: 'TextInput',
      },
    ]),
    createField(`${id}.sex`, [
      {
        label: 'เพศ',
        id: `${id}.sex`,
        c: 'RadioButton',
        required: true,
        p: {
          options: [
            {
              text: 'ชาย',
              value: 'male',
            },
            {
              text: 'หญิง',
              value: 'female',
            },
          ],
        },
      },
    ]),
    createField(`${id}.relations`, [
      {
        label: 'ความสัมพันธ์',
        id: `${id}.relations`,
        c: 'Dropdown',
        required: true,
        p: {
          options: [
            {
              text: 'พี่ชายน้องชาย',
              value: 'BROTHER',
            },
            {
              text: 'พี่สาวน้องสาว',
              value: 'SISTER',
            },
          ],
          placeHolder: 'กรุณาเลือกความสัมพันธ์',
        },
      },
    ]),
    createField(`${id}.policyPlan`, [
      {
        label: 'แผนความคุ้มครองสุขภาพ<br/>(กรุณาระบุชื่อสัญญาสุขภาพที่มี)',
        id: `${id}.policyPlan`,
        required: false,
        c: 'TextInput',
      },
    ]),
    createField(`${id}.siblingPolicyNo`, [
      {
        label: 'เลขที่กรมธรรม์ และสถานะกรมธรรม์',
        id: `${id}.siblingPolicyNo`,
        required: false,
        c: 'TextInput',
      },
    ]),
    createField(`${id}.insuranceName`, [
      {
        label: 'บริษัทประกันชีวิต',
        id: `${id}.insuranceName`,
        required: false,
        c: 'TextInput',
      },
    ]),
  ])
}

const juvenileSibling = (app, id) => {
  const payerRelationshipValue = _.get(app, 'payer.relationshipToInsured.value')
  const requiredFieldFlag = payerRelationshipValue === 'PARENT'

  const hasSibling = _.get(app, `${id}.hasSibling.value`)
  return _.compact([
    createField(`${id}.hasSiblingTitle`, [
      {
        id: `${id}.hasSiblingTitle`,
        c: 'PlainText',
        p: {
          mergeState: (state, app) => ({
            value: 'ผู้เยาว์มีพี่น้องร่วมบิดามารดาเดียวกันหรือไม่',
          }),
        },
      },
    ]),
    createField(`${id}.hasSibling`, [
      {
        id: `${id}.hasSibling`,
        c: 'RadioButton',
        required: requiredFieldFlag,
        invalidLabel: 'ผู้เยาว์มีพี่น้องร่วมบิดามารดาเดียวกันหรือไม่',
        p: {
          options: [
            {
              text: 'ไม่มี',
              value: false,
            },
            {
              text: 'มี',
              value: true,
            },
          ],
        },
      },
    ]),
    hasSibling &&
      createField(`${id}.hasSiblingDetail`, [
        {
          id: `${id}.hasSiblingDetail`,
          c: 'PlainText',
          p: {
            mergeState: (state, app) => ({
              value: `<span class="font-weight-normal">กรณีที่มีพี่น้องร่วมบิดา/มารดาเดียวกัน โปรระบุรายละเอียด<br/>
                    *กรณีที่มีแผนสุขภาพกับบริษัทอื่น สามารถแนบเอกสารหน้าตารางกรมธรรม์<br/>
                    ที่มีผลบังคับ เพื่อใช้ประกอบพิจารณาได้</span>`,
            }),
          },
        },
      ]),
  ])
}

const addNewSiblingFields = (app, id, section) => [
  createField(`${id}.addNew`, [
    {
      id,
      c: 'Button',
      p: {
        icon: 'add',
        children: 'เพิ่มพี่น้องร่วมบิดา/มารดาเดียวกัน',
        block: true,
        mergeProps: ({ setValue }) => ({
          onClick: () => {
            setValue(null)
          },
        }),
      },
    },
  ]),
]

export default (sectionId, sectionLabel) => (app, id) => {
  const appJuvenileSibling = _.get(app, `${id}.juvenilePurchasing.sibling.dataSibling`, [null])
  const hasSibling = _.get(app, `${id}.juvenilePurchasing.sibling.hasSibling.value`)
  return _.compact([
    createSectionCreator(juvenileSibling)(
      `${sectionId}.juvenilePurchasing.sibling`,
      null,
      null,
      null,
      'form-section__juvenile'
    )(app, `${id}.juvenilePurchasing.sibling`),
  ]).concat(
    _.reduce(
      appJuvenileSibling,
      (result, el, i) => {
        if (!hasSibling) {
          return []
        }
        return [
          ...result,
          createSectionCreator(dataSiblingFields)(
            `${sectionId}.juvenilePurchasing.sibling.dataSibling[${i}]`,
            null,
            null,
            null,
            'form-section__juvenile'
          )(app, `${id}.juvenilePurchasing.sibling.dataSibling[${i}]`),
        ]
      },
      []
    ).concat(
      hasSibling && appJuvenileSibling.length < 4
        ? createSectionCreator(addNewSiblingFields)(
            `${sectionId}.juvenilePurchasing.sibling.dataSibling`,
            null,
            null,
            null,
            'form-section__juvenile__btn-add-new'
          )(app, `${id}.juvenilePurchasing.sibling.dataSibling[${appJuvenileSibling.length}]`)
        : []
    )
  )
}
