//@flow
import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import type { FundItemProps, RiskLevelProps } from 'common-components/types/fund-item'

import { withDropdown } from 'lib/with-hoc'
import { getRiskColor } from 'core/service/investment/risklevel'
import values from 'core/data-model/constants/values'

const RiskLevel = ({ value }): RiskLevelProps => {
  return (
    <div className="az-fund-item-summary__risk-lv-summary" style={{ backgroundColor: getRiskColor(value) }}>
      <span className="az-fund-item-summary__risk-lv-summary__value">{value}</span>
    </div>
  )
}

export const FundItem = (props): FundItemProps => {
  const { fundHouse, code, thaiName, riskLevel, fundAllocation } = props

  return (
    <div className="az-fund-item-summary">
      <RiskLevel value={riskLevel} />
      <div className="az-fund-item-summary__fund-content-summary">
        <div className="az-fund-item-summary__fund-content-summary__name">
          <span className={`firm ${_.lowerCase(fundHouse)}`} />
          <span className="code">
            <FundCodeWithInfo code={code} />
          </span>
          <span className="code-and-name">{thaiName}</span>
        </div>
      </div>
      <div className="az-fund-item-summary__fund-summary" style={{ backgroundColor: values.PRIMARY_COLOR }}>
        <span className="az-fund-item-summary__fund-summary__value">{`${fundAllocation}%`}</span>
      </div>
    </div>
  )
}

const FundCodeWithInfo = withDropdown(({ code }) => {
  return (
    <span className="fund-info">
      <span className="code-name">{code}</span>
    </span>
  )
})

const propTypes = {
  thaiName: PropTypes.string,
  englishName: PropTypes.string,
  code: PropTypes.string,
  fundHouse: PropTypes.string,
  factSheet: PropTypes.string,
  riskLevel: PropTypes.number,
  isAllowed: PropTypes.bool,
  isOverRisk: PropTypes.bool,
  isForeign: PropTypes.bool,
  selected: PropTypes.bool,
  allocationPercent: PropTypes.number,
  errors: PropTypes.arrayOf(PropTypes.string),
}
const defaultProps = {
  thaiName: '',
  englishName: '',
  code: '',
  fundHouse: '',
  factSheet: '',
  riskLevel: 1,
  isAllowed: true,
  isOverRisk: false,
  isForeign: false,
  selected: false,
  allocationPercent: 0,
  errors: [],
}

FundItem.propTypes = propTypes
FundItem.defaultProps = defaultProps

export default FundItem
