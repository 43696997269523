// @flow
/* istanbul ignore file */

import type { DisplayProduct } from 'core/service/display-product'
import { render as renderProductInformationHeader } from './components/product-information-header'
import commonTemplate from '../common/template'
import type { SavingProps } from './types'

const productInformationHeader = (
  basicPlan: DisplayProduct,
  selectedPlanName: string,
  selectedPlanCode: string,
  redLine: string,
  sumAssured: number
) => renderProductInformationHeader(basicPlan, selectedPlanName, selectedPlanCode, redLine, sumAssured)

const template = async (props: SavingProps) => {
  const { basicPlan, selectedPlanName, selectedPlanCode, redLine, sumAssured } = props
  const header = productInformationHeader(basicPlan, selectedPlanName, selectedPlanCode, redLine, sumAssured)
  return commonTemplate(props, header)
}

export default template
