// @flow
import type { Age } from 'core/data-model/insured'
import { queryPremiumRateForWP as queryPremiumRate } from 'core/service/rider/query-premium-rate'
import { calculateRiderPremiumForWP as calculateRiderPremium } from 'core/service/rider/premium-calculation'

const getYearsOfCoverage = (
  coveragePeriod: number,
  age: Age,
  basicPaymentPeriod: number,
  basicPlanCoveragePeriod: number
) => {
  const ageValue = age.unit !== 'year' ? 0 : age.value
  const yearsOfCoverage = Math.min(coveragePeriod - ageValue, basicPaymentPeriod)
  return yearsOfCoverage > basicPlanCoveragePeriod ? basicPlanCoveragePeriod : yearsOfCoverage
}

export default {
  calculateRiderPremium,
  queryPremiumRate,
  getYearsOfCoverage,
}
