// @flow

import { reducer as identity } from '../identity'
import { combineReducers } from 'redux'
import { set } from 'lodash/fp'
import type { State as EntryComponentState } from 'quick-quote/entry-component/reducer'
import { reducer as entryComponent } from 'quick-quote/entry-component/reducer'
import { reducer as insured } from 'quick-quote/insured-information'
import { reducer as ui } from 'quick-quote/ui'
import type { State as BenefitIllustrationState } from 'quick-quote/product-whole-life/benefit-illustration/reducer'
import { reducer as benefitIllustration } from 'quick-quote/product-whole-life/benefit-illustration/reducer'
import { reducer as coveragePlan } from 'quick-quote/product-common/coverage-plan'
import { reducer as investment } from 'quick-quote/product-investment'
import { reducer as permissionOrdinary } from 'quick-quote/product-common'
import { reducer as notificationMessage } from 'quick-quote/notification'
import type { State as LoanState } from 'quick-quote/product-loan/redux'
import { reducer as loan } from 'quick-quote/product-loan/redux'
import { reducer as fna } from 'quick-quote/fna'
import { reducer as portal } from 'quick-quote/portal'
import { reducer as remoteSelling } from 'quick-quote/remote-selling'

import type { State as InsuredInformationState } from 'quick-quote/insured-information/reducer'
import type { State as CoveragePlanState } from 'quick-quote/product-common/coverage-plan/reducer'
import type { State as InvestmentState } from 'quick-quote/product-investment/reducer'
import type { State as PermissionOrdinaryState } from 'quick-quote/product-common/reducer'
import type { State as IdentityState } from 'identity/reducer'
import type { Version } from 'quick-quote/version'
import type { ViewConfiguration, FNAConfiguration } from 'core/data-model'
import type { ModelFactor } from 'core/data-model/basic-plan'
import type { Rider } from 'core/data-model/rider'
import type { DisplayProduct } from 'core/service/display-product'
import type { Occupation } from 'core/data-model/insured'
import type { DistributorGroup } from 'core/data-model/distributor'
import type { RemoteSellingState } from './remote-selling/type'

import { appVersion } from 'core/reducers'
import constants from 'core/data-model/constants/defaults'

import type {
  SfReferenceData,
  OptyEnteringData,
  Action as SfReferenceAction,
  OptyEntering as OptyEnteringAction,
} from 'quick-quote/opty/actions'
import { SF_REFERENCE, ENTERING_IPRO } from 'quick-quote/opty/actions'

import type { Action as SelectDisplayProductAction } from 'quick-quote/product-selection/actions'
import {
  SELECT_PRODUCT,
  SELECT_PRODUCT_CATEGORY,
  AVAILABLE_PLAN_CODE,
  AVAILABLE_PRODUCT_CATEGORIES,
} from 'quick-quote/product-selection/actions'
import type { SelectBasicPlan } from 'quick-quote/product-health/coverage-plan/actions'
import { SELECT_BASIC_PLAN } from 'quick-quote/product-health/coverage-plan/actions'

import type { IHEALTHY_ULTRA_SelectBasicPlan } from 'quick-quote/product-ihealthy-ultra/coverage-plan/actions'
import { IHEALTHY_ULTRA_SELECT_BASIC_PLAN } from 'quick-quote/product-ihealthy-ultra/coverage-plan/actions'

import { EDIT_PERFECT_LIFE_PLAN } from 'quick-quote/product-perfect-life/coverage-plan/actions'
import { EDIT_CAMPAIGN_PLAN } from 'quick-quote/product-sukhapabdekdee/actions'
import {
  MRTA_UPDATE_CODE_IN_SELECTED_DISPLAY_PRODUCT,
  MRTA_UPDATE_COVERAGE_PERIOD_IN_SELECTED_DISPLAY_PRODUCT,
} from 'quick-quote/product-mrta/actions'

import type {
  AppFailedSecurityPolicy,
  ConnectToDatabaseFailed,
  ConnectToDatabaseRequest,
  ConnectToDatabaseSuccess,
  GeneratePDFFailure,
  GeneratePDFRequest,
  GeneratePDFSuccess,
  GetModelFactorsFailed,
  GetModelFactorsRequest,
  GetModelFactorsSuccess,
  GetOccupationsFailed,
  GetOccupationsRequest,
  GetOccupationsSuccess,
  GetProductsFailed,
  GetProductsRequest,
  GetProductsSuccess,
  GetRidersFailed,
  GetRidersRequest,
  GetRidersSuccess,
  InitialiseApplicationFailedWithNoInternet,
  InitialiseApplicationFailedWithUpdateApp,
  InitialiseApplicationFailedWithUpdateDatabase,
  InitialiseApplicationRequest,
  InitialiseApplicationServerIsDown,
  InitialiseApplicationSuccess,
  ProcessingApp,
  ProcessingAppSuccess,
  ProcessingAppUnset,
  SetAllowedAgentTypes,
  SetVersion,
  SetViewConfiguration,
  SetFNAConfiguration,
  SetSelectedRemoteSelling,
  StartRemoteSelling,
  StartStandardSelling,
  ResetQuoteForm,
  ResetDisplayProduct,
  ResetSelectedRemoteSelling,
} from './actions'
import {
  APP_FAILED_SECURITY_POLICY,
  CONNECT_TO_DATABASE_FAILED,
  CONNECT_TO_DATABASE_REQUEST,
  CONNECT_TO_DATABASE_SUCCESS,
  GENERATE_PDF_FAILURE,
  GENERATE_PDF_REQUEST,
  GENERATE_PDF_SUCCESS,
  GET_MODEL_FACTORS_FAILED,
  GET_MODEL_FACTORS_REQUEST,
  GET_MODEL_FACTORS_SUCCESS,
  GET_OCCUPATIONS_FAILED,
  GET_OCCUPATIONS_REQUEST,
  GET_OCCUPATIONS_SUCCESS,
  GET_PRODUCTS_FAILED,
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_RIDERS_FAILED,
  GET_RIDERS_REQUEST,
  GET_RIDERS_SUCCESS,
  INITIALISE_APPLICATION_FAILED_WITH_NO_INTERNET,
  INITIALISE_APPLICATION_FAILED_WITH_UPDATE_APP,
  INITIALISE_APPLICATION_FAILED_WITH_UPDATE_DATABASE,
  INITIALISE_APPLICATION_REQUEST,
  INITIALISE_APPLICATION_SERVER_IS_DOWN,
  INITIALISE_APPLICATION_SUCCESS,
  PROCESSING_APP_DATA_REQUEST,
  PROCESSING_APP_DATA_SUCCESS,
  PROCESSING_APP_DATA_UNSET,
  SET_ALLOWED_AGENT_TYPES,
  SET_VERSION,
  SET_VIEW_CONFIGURATION,
  SET_FNA_CONFIGURATION,
  RESET_QUOTE_FORM,
  RESET_DISPLAY_PRODUCT,
} from './actions'

import type { Notification } from 'quick-quote/notification/actions'

import type {
  GetDistributorGroupsFailed,
  GetDistributorGroupsRequest,
  GetDistributorGroupsSuccess,
} from 'identity/actions'
import {
  GET_DISTRIBUTOR_GROUPS_FAILED,
  GET_DISTRIBUTOR_GROUPS_REQUEST,
  GET_DISTRIBUTOR_GROUPS_SUCCESS,
} from 'identity/actions'

import {
  SALE_PERMISSION_VALIDATION_COMPLETED,
  VALIDATING_SALE_PERMISSION,
} from 'quick-quote/product-investment/validate-permission/actions'
import type { AgentType } from 'core/data-model/identity'
import type { FnaState } from './fna/reducer'
import { UPDATE_WEALTH_SOURCE_DATA, INIT_CUSTOMER_PROFILE } from './my-wealth-plus/actions'
import type {
  WealthInfo,
  UpdateWealthSourceData,
  GetMyWealthProfile,
  MyWealthPlusFundLists,
} from './my-wealth-plus/actions'

export type AsyncDataSource<T> = {
  status: 'uninitialised' | 'fetching' | 'ready' | 'error',
  payload: T,
}

export type AppState = {
  products: AsyncDataSource<DisplayProduct[]>,
  riders: AsyncDataSource<Rider[]>,
  occupations: AsyncDataSource<Occupation[]>,
  modelFactors: AsyncDataSource<ModelFactor[]>,
  distributorGroups: AsyncDataSource<DistributorGroup[]>,

  viewConfiguration: ViewConfiguration,
  fnaConfiguration: FNAConfiguration,
  selectedDisplayProduct: DisplayProduct,
  selectedProductCategoryId: string,

  entryComponent: EntryComponentState,
  insured: InsuredInformationState,

  // TODO: group these to wholeLife
  coveragePlan: CoveragePlanState,
  benefitIllustration: BenefitIllustrationState,

  investment: InvestmentState,
  loan: LoanState,

  identity: IdentityState,
  notificationMessage: Notification,

  status: AppStatus,
  processing: boolean,
  version: Version,
  fna: FnaState,
  allowedAgentTypes: Array<AgentType>,
  isRemoteSelling: IsRemoteSelling,
  selectedRemoteSelling: SelectedRemoteSelling,
  permissionOrdinary: PermissionOrdinaryState,

  remoteSelling: RemoteSellingState,

  sfReference: SfReferenceData,
  optyEntering: OptyEnteringData,
  wealthInfo: WealthInfo,
  myWealthProfile: UpdateMyWealthPayload,
}

const initVersionState = {
  appVersion: '',
  fetching: false,
  installing: false,
  error: null,
}
const initAllowedAgentTypes = []
const initViewConfigurationState = {
  productList: [],
}
const initFnaConfigurationState = {
  needCategoryList: [],
  marketConductsQuestionnaires: {
    insuranceQuestion: [],
    riskQuestion: [],
  },
  reasonToSelectProduct: [],
  vulnerableQuestions: [],
}

export const viewConfiguration = (
  state: ViewConfiguration = initViewConfigurationState,
  action: SetViewConfiguration
) => {
  switch (action.type) {
    case SET_VIEW_CONFIGURATION:
      return action.payload
  }
  return state
}

export const fnaConfiguration = (state: FNAConfiguration = initFnaConfigurationState, action: SetFNAConfiguration) => {
  switch (action.type) {
    case SET_FNA_CONFIGURATION:
      return action.payload
  }
  return state
}

export const allowedAgentTypes = (state: Array<AgentType> = initAllowedAgentTypes, action: SetAllowedAgentTypes) => {
  switch (action.type) {
    case SET_ALLOWED_AGENT_TYPES:
      return action.payload
  }
  return state
}
export type VersionType = SetVersion
export const version = (state: Version = initVersionState, action: VersionType) => {
  switch (action.type) {
    case SET_VERSION:
      return action.payload
  }
  return state
}

export type ProductsAction = GetProductsFailed | GetProductsSuccess | GetProductsRequest

const defaultProducts: AsyncDataSource<DisplayProduct[]> = {
  status: 'uninitialised',
  payload: [],
}

export const products = (
  state: AsyncDataSource<DisplayProduct[]> = defaultProducts,
  action: ProductsAction
): AsyncDataSource<DisplayProduct[]> => {
  switch (action.type) {
    case GET_PRODUCTS_REQUEST:
      return { ...state, status: 'fetching' }
    case GET_PRODUCTS_FAILED:
      return { ...state, status: 'error' }
    case GET_PRODUCTS_SUCCESS:
      return { ...state, status: 'ready', payload: action.payload }
    default:
      return state
  }
}

export type RidersAction = GetRidersFailed | GetRidersSuccess | GetRidersRequest

const defaultRiders: AsyncDataSource<Rider[]> = {
  status: 'uninitialised',
  payload: [],
}

export const riders = (
  state: AsyncDataSource<Rider[]> = defaultRiders,
  action: RidersAction
): AsyncDataSource<Rider[]> => {
  switch (action.type) {
    case GET_RIDERS_REQUEST:
      return { ...state, status: 'fetching' }
    case GET_RIDERS_FAILED:
      return { ...state, status: 'error' }
    case GET_RIDERS_SUCCESS:
      return { ...state, status: 'ready', payload: action.payload }
    default:
      return state
  }
}

export type OccupationsAction = GetOccupationsFailed | GetOccupationsRequest | GetOccupationsSuccess

const defaultOccupations: AsyncDataSource<Occupation[]> = {
  status: 'uninitialised',
  payload: [],
}

export const occupations = (
  state: AsyncDataSource<Occupation[]> = defaultOccupations,
  action: OccupationsAction
): AsyncDataSource<Occupation[]> => {
  switch (action.type) {
    case GET_OCCUPATIONS_REQUEST:
      return { ...state, status: 'fetching' }
    case GET_OCCUPATIONS_FAILED:
      return { ...state, status: 'error' }
    case GET_OCCUPATIONS_SUCCESS:
      return { ...state, status: 'ready', payload: action.payload }
    default:
      return state
  }
}

export type ModelFactorsAction = GetModelFactorsFailed | GetModelFactorsRequest | GetModelFactorsSuccess

const defaultModelFactors: AsyncDataSource<ModelFactor[]> = {
  status: 'uninitialised',
  payload: [],
}

export const modelFactors = (
  state: AsyncDataSource<ModelFactor[]> = defaultModelFactors,
  action: ModelFactorsAction
): AsyncDataSource<ModelFactor[]> => {
  switch (action.type) {
    case GET_MODEL_FACTORS_REQUEST:
      return { ...state, status: 'fetching' }
    case GET_MODEL_FACTORS_FAILED:
      return { ...state, status: 'error' }
    case GET_MODEL_FACTORS_SUCCESS:
      return { ...state, status: 'ready', payload: action.payload }
    default:
      return state
  }
}

export type PouchDBState = {
  status: 'replicating' | 'ready' | 'error' | 'uninitialised',
}

export type PouchDBActions = ConnectToDatabaseFailed | ConnectToDatabaseSuccess | ConnectToDatabaseRequest

export const pouchdb = (state: PouchDBState = { status: 'uninitialised' }, action: PouchDBActions): PouchDBState => {
  switch (action.type) {
    case CONNECT_TO_DATABASE_REQUEST:
      return { status: 'replicating' }
    case CONNECT_TO_DATABASE_SUCCESS:
      return { status: 'ready' }
    case CONNECT_TO_DATABASE_FAILED:
      return { status: 'error' }
    default:
      return state
  }
}

export type DistributorGroupsAction =
  | GetDistributorGroupsFailed
  | GetDistributorGroupsRequest
  | GetDistributorGroupsSuccess

const defaultDistributorGroups: AsyncDataSource<DistributorGroup[]> = {
  status: 'uninitialised',
  payload: [],
}

export const distributorGroups = (
  state: AsyncDataSource<DistributorGroup[]> = defaultDistributorGroups,
  action: DistributorGroupsAction
): AsyncDataSource<DistributorGroup[]> => {
  switch (action.type) {
    case GET_DISTRIBUTOR_GROUPS_REQUEST:
      return { ...state, status: 'fetching' }
    case GET_DISTRIBUTOR_GROUPS_FAILED:
      return { ...state, status: 'error' }
    case GET_DISTRIBUTOR_GROUPS_SUCCESS:
      return { ...state, status: 'ready', payload: action.payload }
    default:
      return state
  }
}

export type AppStatus =
  | 'uninitialised'
  | 'initialising'
  | 'ready'
  | 'updateApp'
  | 'updateDatabase'
  | 'noInternet'
  | 'serverIsDown'
  | 'failedSecurityPolicy'
  | 'generatingPDF'
  | 'validatingSalePermission'

export type AppProcessing = boolean
export type AppProcessingActions = ProcessingApp | ProcessingAppSuccess | ProcessingAppUnset
export type RemoteSellingActions = StartRemoteSelling | StartStandardSelling
export type SetSelectedRemoteSellingActions = SetSelectedRemoteSelling | ResetSelectedRemoteSelling
export type IsRemoteSelling = boolean
export type SelectedRemoteSelling = boolean
export type AppStatusActions =
  | InitialiseApplicationRequest
  | InitialiseApplicationSuccess
  | InitialiseApplicationFailedWithNoInternet
  | InitialiseApplicationFailedWithUpdateApp
  | InitialiseApplicationFailedWithUpdateDatabase
  | InitialiseApplicationServerIsDown
  | AppFailedSecurityPolicy
  | GeneratePDFRequest
  | GeneratePDFSuccess
  | GeneratePDFFailure

export const status = (state: AppStatus = 'uninitialised', action: AppStatusActions): AppStatus => {
  switch (action.type) {
    case INITIALISE_APPLICATION_REQUEST:
      return 'initialising'
    case INITIALISE_APPLICATION_FAILED_WITH_NO_INTERNET:
      return 'noInternet'
    case INITIALISE_APPLICATION_FAILED_WITH_UPDATE_APP:
      return 'updateApp'
    case INITIALISE_APPLICATION_FAILED_WITH_UPDATE_DATABASE:
      return 'updateDatabase'
    case INITIALISE_APPLICATION_SERVER_IS_DOWN:
      return 'serverIsDown'
    case APP_FAILED_SECURITY_POLICY:
      return 'failedSecurityPolicy'
    case GENERATE_PDF_REQUEST:
      return 'generatingPDF'
    case GENERATE_PDF_SUCCESS:
    case GENERATE_PDF_FAILURE:
    case SALE_PERMISSION_VALIDATION_COMPLETED:
    case INITIALISE_APPLICATION_SUCCESS:
      return 'ready'
    case VALIDATING_SALE_PERMISSION:
      return 'validatingSalePermission'
    default:
      return state
  }
}

export const isRemoteSelling = (state: IsRemoteSelling = false, action: RemoteSellingActions): IsRemoteSelling => {
  switch (action.type) {
    case 'START_REMOTE_SELLING':
      return true
    case 'START_STANDARD_SELLING':
      return false
    default:
      return state
  }
}

export const selectedRemoteSelling = (
  state: SelectedRemoteSelling = false,
  action: SetSelectedRemoteSellingActions
): SelectedRemoteSelling => {
  switch (action.type) {
    case 'SET_SELECTED_REMOTE_SELLING':
      return true
    case 'RESET_SELECTED_REMOTE_SELLING':
      return false
    default:
      return state
  }
}

export const processing = (state: AppProcessing = false, action: AppProcessingActions): AppProcessing => {
  switch (action.type) {
    case PROCESSING_APP_DATA_REQUEST:
      return true
    case PROCESSING_APP_DATA_UNSET:
    case PROCESSING_APP_DATA_SUCCESS:
      return false
    default:
      return state
  }
}

const initSfReference = {
  startSaleFrom: 'AZ',
  accId: null,
  optyId: null,
}
export const sfReference = (
  state: SfReferenceData = initSfReference,
  action: SfReferenceAction | ResetQuoteForm
): SfReferenceData => {
  switch (action.type) {
    case SF_REFERENCE:
      return action.payload
    case RESET_QUOTE_FORM:
      return initSfReference
    default:
      return state
  }
}

export const optyEntering = (
  state: OptyEnteringData = {},
  action: OptyEnteringAction | ResetQuoteForm
): OptyEnteringData => {
  switch (action.type) {
    case ENTERING_IPRO:
      return action.payload
    case RESET_QUOTE_FORM:
      return {}
    default:
      return state
  }
}

const initWealthInfo = { dataSource: 'DEFAULT', isValidSalePermission: false, data: {} }

export const wealthInfo = (state: WealthInfo = initWealthInfo, action: UpdateWealthSourceData): WealthInfo => {
  switch (action.type) {
    case UPDATE_WEALTH_SOURCE_DATA:
      return action.payload
    default:
      return state
  }
}

export const myWealthProfile = (state: MyWealthPlusFundLists = {}, action: GetMyWealthProfile) => {
  switch (action.type) {
    case INIT_CUSTOMER_PROFILE:
      return action.payload
    default:
      return state
  }
}

export const emptyDisplayProduct: DisplayProduct = {
  code: '',
  basicPlanCode: '',
  type: 'product',
  name: '',
  nameThai: '',
  displayName: '',
  basicPlanName: '',
  description: '',
  benefits: [],
  readOnlyFields: {},
  riders: [],
  image: '',
  category: 'WHOLE_LIFE',
  paymentPeriod: { type: 'by_package', value: -1 },
  coveragePeriod: { type: 'by_package', value: -1 },
  modelFactors: [],
  licenses: [],
  aura: undefined,
}

export const selectedDisplayProduct = (
  state: DisplayProduct = emptyDisplayProduct,
  action:
    | SelectDisplayProductAction
    | SelectBasicPlan
    | IHEALTHY_ULTRA_SelectBasicPlan
    | ResetQuoteForm
    | ResetDisplayProduct
): DisplayProduct => {
  switch (action.type) {
    case SELECT_PRODUCT:
    case SELECT_BASIC_PLAN:
    case IHEALTHY_ULTRA_SELECT_BASIC_PLAN:
    case EDIT_PERFECT_LIFE_PLAN:
    case EDIT_CAMPAIGN_PLAN:
      return action.payload
    case MRTA_UPDATE_CODE_IN_SELECTED_DISPLAY_PRODUCT:
      return {
        ...state,
        code: action.payload,
      }
    case MRTA_UPDATE_COVERAGE_PERIOD_IN_SELECTED_DISPLAY_PRODUCT:
      return {
        ...state,
        coveragePeriod: action.payload,
      }
    case RESET_QUOTE_FORM:
    case RESET_DISPLAY_PRODUCT:
      return emptyDisplayProduct
    default:
      return state
  }
}

const { DEFAULT_PRODUCT_CATEGORY } = constants
const initProductCategoryId = DEFAULT_PRODUCT_CATEGORY

export const selectedProductCategoryId = (state: * = initProductCategoryId, action: *) => {
  switch (action.type) {
    case SELECT_PRODUCT_CATEGORY:
      return action.payload
    case RESET_QUOTE_FORM:
      return initProductCategoryId
    default:
      return state
  }
}

const initProductSelection = {
  availableProductCategories: [],
  availablePlanCode: [],
}
export const productSelection = (state: * = initProductSelection, action: *) => {
  switch (action.type) {
    case AVAILABLE_PRODUCT_CATEGORIES:
      return set('availableProductCategories', action.payload)(state)
    case AVAILABLE_PLAN_CODE:
      return set('availablePlanCode', action.payload)(state)
    case RESET_QUOTE_FORM:
      return initProductSelection
    default:
      return state
  }
}

const _reducer = combineReducers({
  products,
  riders,
  occupations,
  modelFactors,
  viewConfiguration,
  selectedDisplayProduct,
  selectedProductCategoryId,
  productSelection,

  entryComponent,
  insured,
  coveragePlan,
  benefitIllustration,

  investment,
  loan,
  pouchdb,
  status,
  processing,
  version,
  allowedAgentTypes,
  identity,
  notificationMessage,
  distributorGroups,
  isRemoteSelling,
  selectedRemoteSelling,
  permissionOrdinary,
  appVersion,
  portal,
  fna,
  fnaConfiguration,
  ui,
  remoteSelling,
  sfReference,
  optyEntering,
  wealthInfo,
  myWealthProfile,
})

export const reducer = (state: *, action: *) => {
  switch (action.type) {
    case 'LOAD_QUICK_QUOTE_STATE':
      const newState = Object.assign({}, state, action.payload)
      return _reducer(newState, action)
    default:
      return _reducer(state, action)
  }
}
