//@flow
import type {
  FundAllocation,
  FundAllocationToggleItem,
  FundCode,
  TotalFundAllocation,
} from 'core/data-model/investment'

// ==== action types ====
export type Action =
  | UpdateRppFundAllocation
  | UpdateRppTotalFundAllocation
  | ToggleRppFundAllocation
  | CalculateTotalFundAllocation
  | ResetRppFundAllocations

export const CALCULATE_RPP_TOTAL_FUND_ALLOCATION = 'INVESTMENT/CALCULATE_RPP_TOTAL_FUND_ALLOCATION'
export type CalculateTotalFundAllocation = {
  type: 'INVESTMENT/CALCULATE_RPP_TOTAL_FUND_ALLOCATION',
  payload: FundAllocation,
}
export const calculateTotalFundAllocation = (code: FundCode, fundAllocation: number): CalculateTotalFundAllocation => {
  return {
    type: CALCULATE_RPP_TOTAL_FUND_ALLOCATION,
    payload: {
      code,
      fundAllocation,
    },
  }
}
export const UPDATE_RPP_FUND_ALLOCATIONS = 'INVESTMENT/UPDATE_RPP_FUND_ALLOCATIONS'
export type UpdateRppFundAllocation = {
  type: 'INVESTMENT/UPDATE_RPP_FUND_ALLOCATIONS',
  payload: FundAllocationToggleItem[],
}
export const updateRppFundAllocation = (allocations: FundAllocationToggleItem[]): UpdateRppFundAllocation => {
  return {
    type: UPDATE_RPP_FUND_ALLOCATIONS,
    payload: allocations,
  }
}
export const UPDATE_RPP_TOTAL_FUND_ALLOCATION = 'INVESTMENT/UPDATE_RPP_TOTAL_FUND_ALLOCATION'
export type UpdateRppTotalFundAllocation = {
  type: 'INVESTMENT/UPDATE_RPP_TOTAL_FUND_ALLOCATION',
  payload: TotalFundAllocation,
}
export const updateRppTotalFundAllocation = (
  totalFundAllocation: TotalFundAllocation
): UpdateRppTotalFundAllocation => {
  return {
    type: UPDATE_RPP_TOTAL_FUND_ALLOCATION,
    payload: totalFundAllocation,
  }
}
export const TOGGLE_RPP_FUND_ALLOCATION = 'INVESTMENT/TOGGLE_RPP_FUND_ALLOCATION'

export type ToggleFundPayload = {
  code: FundCode,
}
export type ToggleRppFundAllocation = {
  type: 'INVESTMENT/TOGGLE_RPP_FUND_ALLOCATION',
  payload: ToggleFundPayload,
}
export const toggleFundAllocation = (fund: ToggleFundPayload): ToggleRppFundAllocation => {
  return {
    type: TOGGLE_RPP_FUND_ALLOCATION,
    payload: fund,
  }
}

export const RESET_RPP_FUND_ALLOCATION = 'INVESTMENT/RESET_RPP_FUND_ALLOCATION'
export type ResetRppFundAllocations = {
  type: 'INVESTMENT/RESET_RPP_FUND_ALLOCATION',
}
export const resetFundAllocations = (): ResetRppFundAllocations => {
  return {
    type: RESET_RPP_FUND_ALLOCATION,
  }
}

export const UPDATE_RPP_ACCEPT_FUND_ALLOCATION = 'INVESTMENT/UPDATE_RPP_ACCEPT_FUND_ALLOCATION'
export type UpdateRppAcceptFundAllocation = {
  type: 'INVESTMENT/UPDATE_RPP_ACCEPT_FUND_ALLOCATION',
  payload: boolean,
}

export const updateRppAcceptFundAllocation = (acceptFundAllocation: boolean): UpdateRppAcceptFundAllocation => {
  return {
    type: UPDATE_RPP_ACCEPT_FUND_ALLOCATION,
    payload: acceptFundAllocation,
  }
}

export const RESET_RPP_ACCEPT_FUND_ALLOCATION = 'INVESTMENT/RESET_RPP_ACCEPT_FUND_ALLOCATION'

export type ResetRppAcceptFundAllocation = {
  type: 'INVESTMENT/RESET_RPP_ACCEPT_FUND_ALLOCATION',
}

export const resetRppAcceptFundAllocation = (): ResetRppAcceptFundAllocation => {
  return {
    type: RESET_RPP_ACCEPT_FUND_ALLOCATION,
  }
}

export const RPP_ACCEPT_HIGHER_RISK_INVESTMENT = 'RPP_ACCEPT_HIGHER_RISK_INVESTMENT'

export type RPPAcceptHigherRiskInvestment = {
  type: 'RPP_ACCEPT_HIGHER_RISK_INVESTMENT',
}

export const rppAcceptHigherRiskInvestment = (): RPPAcceptHigherRiskInvestment => ({
  type: RPP_ACCEPT_HIGHER_RISK_INVESTMENT,
})

export const RPP_ACCEPT_FOREIGN_INVESTMENT = 'RPP_ACCEPT_FOREIGN_INVESTMENT'

export const ANSWER_QUESTION_TO_ACCEPT_RPP_FOREIGN_INVESTMENT = 'ANSWER_QUESTION_TO_ACCEPT_RPP_FOREIGN_INVESTMENT'

export type RPPAcceptForeignInvestment = {
  type: 'RPP_ACCEPT_FOREIGN_INVESTMENT',
}

export const rppAcceptForeignInvestment = (): RPPAcceptForeignInvestment => ({
  type: RPP_ACCEPT_FOREIGN_INVESTMENT,
})

export type AnswerQuestionToAcceptRppForeignInvestment = {
  type: 'ANSWER_QUESTION_TO_ACCEPT_RPP_FOREIGN_INVESTMENT',
  payload: boolean,
}

export const answerQuestionToAcceptRppForeignInvestment = (
  isAccept: boolean
): AnswerQuestionToAcceptRppForeignInvestment => ({
  type: ANSWER_QUESTION_TO_ACCEPT_RPP_FOREIGN_INVESTMENT,
  payload: isAccept,
})
