// @flow

import { combineReducers } from 'redux'
import type { Action } from './actions'
import {
  SELECT_GENDER,
  UPDATE_BIRTHDATE,
  SELECT_OCCUPATION,
  SELECT_NATURE_OF_DUTY,
  SELECT_OTHER_NATURE_OF_DUTY,
  SELECT_OTHER_OCCUPATION,
  TOGGLE_OTHER_OCCUPATION,
  SELECT_PAYER_OTHER_NATURE_OF_DUTY,
  SELECT_PAYER_OTHER_OCCUPATION,
  TOGGLE_PAYER_OTHER_OCCUPATION,
  SELECT_PAYER_GENDER,
  SELECT_PAYER_RELATION,
  UPDATE_PAYER_BIRTHDATE,
  UPDATE_PAYER_NEEDED,
  SET_OCCUPATION_FACTOR,
  UPDATE_OCCUPATION_ERRORS,
  UPDATE_OTHER_OCCUPATION_ERRORS,
  UPDATE_PAYER_OCCUPATION_ERRORS,
  UPDATE_PAYER_OTHER_OCCUPATION_ERRORS,
  SELECT_PAYER_NATURE_OF_DUTY,
  SELECT_PAYER_OCCUPATION,
  CLEAR_PAYER_INFORMATION,
  CLEAR_PAYER_NEEDED,
} from './actions'
import { RESET_APPLICATION_STATE, RESET_QUOTE_FORM } from 'quick-quote/actions'
import type { State as TitleState } from './redux/title'
import { TitleRedux } from './redux/title'
import type { State as NameState } from './redux/name'
import { FirstNameRedux } from './redux/name'
import type { State as SurnameState } from './redux/surname'
import { LastNameRedux } from './redux/surname'
import type { Gender, Age, NatureOfDuty } from 'core/data-model/insured'
import constants from 'core/data-model/constants/defaults'

import type { MaturityIDCardState, MaturityValidateState } from 'quick-quote/product-s7cc'
import { reducer as maturity } from 'quick-quote/product-s7cc'
import { RESET_QUOTE_FORM_AFTER_MATURITY_VALIDATE } from 'quick-quote/product-s7cc/actions'

const {
  DEFAULT_PAYER_AGE,
  DEFAULT_PAYER_GENDER,
  DEFAULT_INSURED_AGE,
  DEFAULT_INSURED_GENDER,
  DEFAULT_OCCUPATION_CODE,
  DEFAULT_NATURE_OF_DUTY_CODE,
  DEFAULT_NATURE_OF_DUTY_TEXT,
} = constants

const gender = (state: Gender = DEFAULT_INSURED_GENDER, action: Action) => {
  switch (action.type) {
    case RESET_QUOTE_FORM:
    case RESET_QUOTE_FORM_AFTER_MATURITY_VALIDATE:
    case RESET_APPLICATION_STATE:
      return DEFAULT_PAYER_GENDER
    case SELECT_GENDER:
      return action.payload
  }
  return state
}

// birthdate
type BirthDateState = {
  value: string,
  errors: string[],
}

const initialBirthdateState: BirthDateState = {
  value: '',
  errors: [],
}

const birthdate = (state: BirthDateState = initialBirthdateState, action: Action) => {
  switch (action.type) {
    case RESET_QUOTE_FORM:
    case RESET_QUOTE_FORM_AFTER_MATURITY_VALIDATE:
    case RESET_APPLICATION_STATE:
      return initialBirthdateState
    case UPDATE_BIRTHDATE:
      return {
        value: action.birthdate,
        errors: action.errors,
      }
    default:
      return state
  }
}

const age = (state: Age = DEFAULT_INSURED_AGE, action: Action) => {
  switch (action.type) {
    case RESET_QUOTE_FORM:
    case RESET_QUOTE_FORM_AFTER_MATURITY_VALIDATE:
    case RESET_APPLICATION_STATE:
      return DEFAULT_INSURED_AGE
    case UPDATE_BIRTHDATE:
      return action.age
    default:
      return state
  }
}

// occupation

type OccupationState = {
  code: string,
  natureOfDuty: NatureOfDuty,
  errors: string[],
}

type OtherOccupationState = OccupationState & {
  isSelected: boolean,
}

const initialOccupationState: OccupationState = {
  code: DEFAULT_OCCUPATION_CODE,
  natureOfDuty: {
    code: DEFAULT_NATURE_OF_DUTY_CODE,
    text: DEFAULT_NATURE_OF_DUTY_TEXT,
  },
  errors: [],
}

const occupation = (state: OccupationState = initialOccupationState, action: Action) => {
  switch (action.type) {
    case RESET_QUOTE_FORM:
    case RESET_QUOTE_FORM_AFTER_MATURITY_VALIDATE:
    case RESET_APPLICATION_STATE:
      return initialOccupationState
    case SELECT_OCCUPATION:
      return {
        ...state,
        code: action.payload,
      }

    case UPDATE_OCCUPATION_ERRORS:
      return {
        ...state,
        errors: action.payload,
      }

    case SELECT_NATURE_OF_DUTY:
      return {
        ...state,
        natureOfDuty: action.payload,
      }
    ///FIXME why do we need this????
    case SET_OCCUPATION_FACTOR:
      return { ...state, occupationFactor: action.payload }
  }

  return state
}

const initialOtherOccupationState: OtherOccupationState = {
  code: DEFAULT_OCCUPATION_CODE,
  natureOfDuty: {
    code: DEFAULT_NATURE_OF_DUTY_CODE,
    text: DEFAULT_NATURE_OF_DUTY_TEXT,
  },
  errors: [],
  isSelected: false,
}

const otherOccupation = (state: OtherOccupationState = initialOtherOccupationState, action: Action) => {
  switch (action.type) {
    case RESET_QUOTE_FORM:
    case RESET_QUOTE_FORM_AFTER_MATURITY_VALIDATE:
    case RESET_APPLICATION_STATE:
      return initialOtherOccupationState
    case SELECT_OTHER_OCCUPATION:
      return {
        ...state,
        code: action.payload,
      }
    case SELECT_OTHER_NATURE_OF_DUTY:
      return {
        ...state,
        natureOfDuty: action.payload,
      }
    case TOGGLE_OTHER_OCCUPATION:
      return {
        ...state,
        isSelected: !state.isSelected,
      }
    case UPDATE_OTHER_OCCUPATION_ERRORS:
      return {
        ...state,
        errors: action.payload,
      }
  }
  return state
}

export type PayerState = {
  gender: Gender,
  birthdate: {
    value: string,
    errors: string[],
  },
  age: Age,
  occupation: OccupationState,
  otherOccupation: OtherOccupationState,
}

const initialPayerState: PayerState = {
  gender: DEFAULT_PAYER_GENDER,
  birthdate: {
    value: '',
    errors: [],
  },
  age: DEFAULT_PAYER_AGE,
  occupation: initialOccupationState,
  otherOccupation: initialOtherOccupationState,
  relation: constants.PAY_MY_SELF,
}

const payerNeeded = (state: boolean = false, action: Action) => {
  switch (action.type) {
    case RESET_QUOTE_FORM:
    case RESET_QUOTE_FORM_AFTER_MATURITY_VALIDATE:
    case RESET_APPLICATION_STATE:
    case CLEAR_PAYER_NEEDED:
      return false
    case UPDATE_PAYER_NEEDED:
      return action.payload
    default:
      return state
  }
}

const payer = (state: PayerState = initialPayerState, action: Action) => {
  switch (action.type) {
    case RESET_QUOTE_FORM:
    case RESET_QUOTE_FORM_AFTER_MATURITY_VALIDATE:
    case RESET_APPLICATION_STATE:
    case CLEAR_PAYER_INFORMATION:
      return initialPayerState
    case SELECT_PAYER_GENDER:
      return { ...state, gender: action.payload }
    case SELECT_PAYER_RELATION:
      return { ...state, relation: action.payload }
    case UPDATE_PAYER_BIRTHDATE:
      return {
        ...state,
        birthdate: {
          value: action.birthdate,
          errors: action.errors,
        },
        age: action.age,
      }
    case SELECT_PAYER_NATURE_OF_DUTY:
      return {
        ...state,
        occupation: {
          ...state.occupation,
          natureOfDuty: action.payload,
        },
      }
    case SELECT_PAYER_OCCUPATION:
      return {
        ...state,
        occupation: {
          ...state.occupation,
          code: action.payload,
        },
      }
    case UPDATE_PAYER_OCCUPATION_ERRORS:
      return {
        ...state,
        occupation: {
          ...state.occupation,
          errors: action.payload,
        },
      }
    case SELECT_PAYER_OTHER_OCCUPATION:
      return {
        ...state,
        otherOccupation: {
          ...state.otherOccupation,
          code: action.payload,
        },
      }
    case SELECT_PAYER_OTHER_NATURE_OF_DUTY:
      return {
        ...state,
        otherOccupation: {
          ...state.otherOccupation,
          natureOfDuty: action.payload,
        },
      }
    case TOGGLE_PAYER_OTHER_OCCUPATION:
      return {
        ...state,
        otherOccupation: {
          ...state.otherOccupation,
          isSelected: !state.otherOccupation.isSelected,
        },
      }
    case UPDATE_PAYER_OTHER_OCCUPATION_ERRORS:
      return {
        ...state,
        otherOccupation: {
          ...state.otherOccupation,
          errors: action.payload,
        },
      }
  }

  return state
}

type MaturityState = {
  maturityIDCard: MaturityIDCardState,
  maturityValidate: MaturityValidateState,
  policyOwner: string,
}

export type State = {
  title: TitleState,
  firstName: NameState,
  lastName: SurnameState,
  gender: Gender,
  occupation: OccupationState,
  otherOccupation: OtherOccupationState,
  payerNeeded: boolean,
  payer: PayerState,
  birthdate: BirthDateState,
  age: Age,
  maturity: MaturityState,
}

export const reducer = combineReducers({
  title: TitleRedux.reducer,
  firstName: FirstNameRedux.reducer,
  lastName: LastNameRedux.reducer,
  gender,
  occupation,
  otherOccupation,
  payerNeeded,
  payer,
  birthdate,
  age,
  maturity,
})
