// @flow
import d from 'decimal.js'
import { flow, map, sum } from 'lodash/fp'
import { getAppConfig } from 'deploy-env/app-config'
import { getToggles } from 'quick-quote/feature-toggles'

import _ from 'lodash'
import { isAgeMoreThanOrEqual } from 'core/service/insured'
import { formatNumber } from 'core/service/lib/number-format'
import MESSAGES from 'core/data-model/constants/messages'
import BENEFIT from 'core/data-model/constants/bi-rider'
import { generateRiderBenefitDataForESCI } from './benefit-data/esci'
import { generateRiderBenefitDataForFH } from './benefit-data/fh'
import type { Age } from 'core/data-model/insured'
import type { AllRiderBenefitDependencies, RiderBenefitState, RiderBenefitData } from 'core/data-model/rider/benefits'
import type { Rider, RiderState } from 'core/data-model/rider'

const percentageNumber = (percent: number, riderSumAssured: number): number =>
  Math.round((riderSumAssured * percent) / 100)

export const generateRiderBenefitData = (
  { age, riderBenefitData, riderSumAssured, selectedRiders }: AllRiderBenefitDependencies,
  riderCode: string
): RiderBenefitState => {
  switch (riderCode) {
    case 'MEH':
      return generateRiderBenefitDataForMEH({
        selectedRiders: selectedRiders,
        riderBenefitData: riderBenefitData,
      })
    case 'FH':
      return generateRiderBenefitDataForFH({
        selectedRiders: selectedRiders,
        riderBenefitData: riderBenefitData,
      })
    case 'IGROW':
      return generateRiderBenefitDataForIGROW({
        riderBenefitData: riderBenefitData,
        riderSumAssured: riderSumAssured,
      })
    case 'DCI':
      return generateRiderBenefitDataForDCI({
        age: age,
        riderBenefitData: riderBenefitData,
      })
    case 'CPR':
      return generateRiderBenefitDataForCPR({
        age: age,
        riderSumAssured: riderSumAssured,
      })
    case 'AP':
    case 'ECARE':
      return generateRiderBenefitDataForApAndEcare({ selectedRiders })
    case 'ESCI':
      return generateRiderBenefitDataForESCI({
        age,
        riderSumAssured,
      })
    default:
      return {
        riderBenefitData: riderBenefitData,
      }
  }
}

const generateRiderBenefitDataForDCI = ({ age, riderBenefitData }): RiderBenefitState => {
  const MINIMUM_AGE: Age = {
    value: 56,
    unit: 'year',
  }
  const showDisclaimer: boolean = isAgeMoreThanOrEqual(age, MINIMUM_AGE)
  return {
    riderBenefitData,
    showDisclaimer,
  }
}
const generateRiderBenefitDataForCPR = ({ age, riderSumAssured }): RiderBenefitState => {
  const MINIMUM_AGE: Age = {
    value: 56,
    unit: 'year',
  }
  const showDisclaimer: boolean = isAgeMoreThanOrEqual(age, MINIMUM_AGE)

  const riderBenefitData: RiderBenefitData = {
    benefits: [
      {
        description: BENEFIT.RIDER_CPR_DESCRIPTION_01,
        value: formatNumber(percentageNumber(100, riderSumAssured)),
        disclaimer: BENEFIT.RIDER_CPR_DISCLAIMER_01,
      },
      {
        description: BENEFIT.RIDER_CPR_DESCRIPTION_02,
        value: formatNumber(Math.min(percentageNumber(10, riderSumAssured), 50000)),
        disclaimer: BENEFIT.RIDER_CPR_DISCLAIMER_02,
      },
      {
        description: BENEFIT.RIDER_CPR_DESCRIPTION_03,
        value: formatNumber(percentageNumber(15, riderSumAssured)),
        disclaimer: BENEFIT.RIDER_CPR_DISCLAIMER_03,
      },
      {
        description: BENEFIT.RIDER_CPR_DESCRIPTION_04,
        value: formatNumber(Math.min(percentageNumber(15, riderSumAssured), 100000)),
        disclaimer: BENEFIT.RIDER_CPR_DISCLAIMER_04,
      },
      {
        description: BENEFIT.RIDER_CPR_DESCRIPTION_05,
        value: formatNumber(percentageNumber(30, riderSumAssured)),
        disclaimer: BENEFIT.RIDER_CPR_DISCLAIMER_05,
      },
      {
        description: BENEFIT.RIDER_CPR_DESCRIPTION_06,
        value: formatNumber(percentageNumber(15, riderSumAssured)),
        disclaimer: BENEFIT.RIDER_CPR_DISCLAIMER_06,
      },
      {
        description: BENEFIT.RIDER_CPR_DESCRIPTION_07,
        value: formatNumber(percentageNumber(100, riderSumAssured)),
        disclaimer: BENEFIT.RIDER_CPR_DISCLAIMER_07,
      },
    ],
  }
  return {
    riderBenefitData,
    showDisclaimer,
  }
}

const generateRiderBenefitDataForApAndEcare = ({ selectedRiders }) => {
  const ecareSumAssured: number = _.filter(selectedRiders, (rider) => rider.code === 'ECARE')
    .map((rider) => rider.sumAssured)
    .reduce((a, b) => a + b, 0)

  const apSumAssured: number = _.filter(selectedRiders, (rider) => rider.code === 'AP')
    .map((rider) => rider.sumAssured)
    .reduce((a, b) => a + b, 0)

  const apAndEcareSumAssured: number = ecareSumAssured + apSumAssured

  const apAndEcareBenefitsList = [
    {
      description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_01,
      subBenefits: [
        {
          description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_02,
          value: formatNumber(percentageNumber(100, apAndEcareSumAssured)),
        },
        {
          description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_03,
          value: formatNumber(percentageNumber(100, apAndEcareSumAssured)),
        },
        {
          description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_04,
          value: formatNumber(percentageNumber(100, apAndEcareSumAssured)),
        },
        {
          description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_05,
          subBenefits: [
            {
              description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_06,
              value: formatNumber(percentageNumber(75, apAndEcareSumAssured)),
            },
            {
              description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_07,
              value: formatNumber(percentageNumber(15, apAndEcareSumAssured)),
            },
          ],
        },
        {
          description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_08,
          value: formatNumber(percentageNumber(50, apAndEcareSumAssured)),
        },
        {
          description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_09,
          value: formatNumber(percentageNumber(50, apAndEcareSumAssured)),
        },
        {
          description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_10,
          subBenefits: [
            {
              description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_11,
              value: formatNumber(percentageNumber(70, apAndEcareSumAssured)),
            },
            {
              description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_12,
              value: formatNumber(percentageNumber(50, apAndEcareSumAssured)),
            },
          ],
        },
        {
          description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_13,
          subBenefits: [
            {
              description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_14,
              value: formatNumber(percentageNumber(40, apAndEcareSumAssured)),
            },
            {
              description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_15,
              value: formatNumber(percentageNumber(30, apAndEcareSumAssured)),
            },
          ],
        },
        {
          description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_16,
          value: formatNumber(percentageNumber(10, apAndEcareSumAssured)),
        },
        {
          description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_17,
          value: formatNumber(percentageNumber(7.5, apAndEcareSumAssured)),
        },
        {
          description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_18,
          subBenefits: [
            {
              description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_19,
              value: formatNumber(percentageNumber(30, apAndEcareSumAssured)),
            },
            {
              description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_20,
              value: formatNumber(percentageNumber(15, apAndEcareSumAssured)),
            },
            {
              description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_21,
              value: formatNumber(percentageNumber(20, apAndEcareSumAssured)),
            },
            {
              description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_22,
              value: formatNumber(percentageNumber(10, apAndEcareSumAssured)),
            },
          ],
        },
        {
          description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_23,
          subBenefits: [
            {
              description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_24,
              value: formatNumber(percentageNumber(10, apAndEcareSumAssured)),
            },
            {
              description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_25,
              value: formatNumber(percentageNumber(7.5, apAndEcareSumAssured)),
            },
            {
              description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_26,
              value: formatNumber(percentageNumber(5, apAndEcareSumAssured)),
            },
            {
              description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_27,
              value: formatNumber(percentageNumber(7.5, apAndEcareSumAssured)),
            },
            {
              description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_28,
              value: formatNumber(percentageNumber(5, apAndEcareSumAssured)),
            },
            {
              description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_29,
              value: formatNumber(percentageNumber(2, apAndEcareSumAssured)),
            },
          ],
        },
        {
          description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_30,
          subBenefits: [
            {
              description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_31,
              value: formatNumber(percentageNumber(15, apAndEcareSumAssured)),
            },
            {
              description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_32,
              value: formatNumber(percentageNumber(5, apAndEcareSumAssured)),
            },
            {
              description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_33,
              value: formatNumber(percentageNumber(3, apAndEcareSumAssured)),
            },
          ],
        },
      ],
      disclaimer: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_34,
    },
    {
      description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_35,
      subBenefits: [
        {
          description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_36,
          subBenefits: [
            {
              description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_37,
              value: formatNumber(percentageNumber(25, apAndEcareSumAssured)),
            },
            {
              description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_38,
              value: formatNumber(percentageNumber(50, apAndEcareSumAssured)),
            },
            {
              description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_39,
              value: formatNumber(percentageNumber(75, apAndEcareSumAssured)),
            },
            {
              description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_40,
              value: formatNumber(percentageNumber(100, apAndEcareSumAssured)),
            },
          ],
        },
        {
          description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_41,
          subBenefits: [
            {
              description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_42,
              value: formatNumber(percentageNumber(25, apAndEcareSumAssured)),
            },
            {
              description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_43,
              value: formatNumber(percentageNumber(50, apAndEcareSumAssured)),
            },
            {
              description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_44,
              value: formatNumber(percentageNumber(75, apAndEcareSumAssured)),
            },
            {
              description: BENEFIT.RIDER_AP_ECARE_DESCRIPTION_45,
              value: formatNumber(percentageNumber(100, apAndEcareSumAssured)),
            },
          ],
        },
      ],
    },
  ]

  const ecareBenefitsList = [
    {
      description: BENEFIT.RIDER_ECARE_DESCRIPTION_01,
      subBenefits: [
        {
          description: BENEFIT.RIDER_ECARE_DESCRIPTION_02,
          value: `${formatNumber(percentageNumber(1, ecareSumAssured))} ${MESSAGES.BAHT_PER_WEEK}`,
        },
        {
          description: BENEFIT.RIDER_ECARE_DESCRIPTION_03,
          value: `${formatNumber(percentageNumber(0.25, ecareSumAssured))} ${MESSAGES.BAHT_PER_WEEK}`,
        },
      ],
      disclaimer: BENEFIT.RIDER_ECARE_DISCLAIMER_01,
    },
    {
      description: BENEFIT.RIDER_ECARE_DESCRIPTION_04,
      value: `${formatNumber(percentageNumber(1, ecareSumAssured))} ${MESSAGES.BAHT_PER_MONTH}`,
    },
    {
      description: BENEFIT.RIDER_ECARE_DESCRIPTION_05,
      value: `${formatNumber(percentageNumber(1, ecareSumAssured))} ${MESSAGES.BAHT_PER_WEEK}`,
    },
    {
      description: BENEFIT.RIDER_ECARE_DESCRIPTION_06,
      value: `${formatNumber(percentageNumber(5, ecareSumAssured))} ${MESSAGES.BAHT}`,
      disclaimer: BENEFIT.RIDER_ECARE_DISCLAIMER_02,
    },
  ]

  if (ecareSumAssured === 0) {
    return {
      riderBenefitData: {
        benefits: [...apAndEcareBenefitsList],
      },
    }
  }

  return {
    riderBenefitData: {
      benefits: [...apAndEcareBenefitsList, ...ecareBenefitsList],
    },
  }
}

const generateRiderBenefitDataForMEH = ({ selectedRiders, riderBenefitData }): RiderBenefitState => {
  const benefitsList = []
  let areaOfCover = ''
  let areaOfCoverName
  let areaOfCoverList
  let selectedPlan
  let plan

  for (let rider of selectedRiders) {
    if (rider.code === 'MEH') {
      plan = _.get(rider, 'selectedPlan.plan').toUpperCase()
      areaOfCover = _.get(rider, 'selectedPlan.areaOfCover')
      areaOfCoverList = _.get(rider, 'areasOfCover')
      break
    }
  }

  const benefitList = _.get(riderBenefitData, 'benefitList.planType')
  for (let benefit of benefitList) {
    if (benefit.planName === plan) {
      selectedPlan = _.get(benefit, 'values')
      break
    }
  }

  if (!selectedPlan) selectedPlan = []
  areaOfCoverName = _.get(areaOfCoverList, areaOfCover + '.text')
  const worldWide = _.get(riderBenefitData, 'benefitList.coverageArea.worldWide')
  const worldWideList = _.map(worldWide).join('\n')
  const compensation = _.get(riderBenefitData, 'benefitList.coverageArea.compensation')
  const compensationList = _.map(compensation).join('\n')
  const itemList = _.get(riderBenefitData, 'benefitItem.values')
  for (let [index, val] of itemList.entries()) {
    benefitsList.push({ description: val, value: selectedPlan[index] })
  }

  benefitsList.push({ description: _.get(riderBenefitData, 'benefitItem.coverageArea.title'), value: '' })
  benefitsList.push({ description: _.get(riderBenefitData, 'benefitItem.coverageArea.desc'), value: areaOfCoverName })
  benefitsList.push({
    description: _.get(riderBenefitData, 'benefitItem.coverageArea.worldWide'),
    value: worldWideList,
  })
  benefitsList.push({
    description: _.get(riderBenefitData, 'benefitItem.coverageArea.compensation'),
    value: compensationList,
  })

  return {
    riderBenefitData: {
      benefits: [...benefitsList],
    },
  }
}

const generateRiderBenefitDataForIGROW = ({ riderBenefitData, riderSumAssured }): RiderBenefitState => {
  const benefitsList = []
  let itemValue = ''
  let selectedPlan

  const benefitList = _.get(riderBenefitData, 'benefitList.planType')
  for (let benefit of benefitList) {
    if (benefit.planName === riderSumAssured) {
      selectedPlan = _.get(benefit, 'values')
      break
    }
  }

  if (!selectedPlan) selectedPlan = []
  const itemList = _.get(riderBenefitData, 'benefitItem.values')
  for (let [index, val] of itemList.entries()) {
    if (index === 31) {
      itemValue = _.get(riderBenefitData, 'benefitList.payerBenefit')
    } else if (index === 32) {
      const benefits = _.get(riderBenefitData, 'benefitList.payerBenefits')
      itemValue = _.map(benefits).join('\n')
    } else {
      itemValue = selectedPlan[index]
    }

    benefitsList.push({ description: val, value: itemValue })
  }

  return {
    riderBenefitData: {
      benefits: [...benefitsList],
    },
  }
}

//I find the way out to group utils func for rider to group func that can share to PDF and BIComponent
// because I don't want to separate the same logic may be make the issues.
export const getTotalPremiumOfRiderAndModules = (rider: Rider & RiderState): number => {
  const totalModulePremium = flow(
    map((m) => (m.isSelected ? m.premium : 0)),
    sum
  )(_.get(rider, 'selectedPlan.modules', []))
  return d(rider.premium)
    .plus(totalModulePremium)
    .toNumber()
}

const isPaidPeriodTurnToOne = (code: string): boolean => {
  const ignoreRider = _.filter(getAppConfig().IGNORE_RIDER_PERIOD_COVERAGE_AND_PAID_TURN_TO_ONE, (code) => {
    return (getToggles().ENABLE_ICARE_REPRICING && code === 'WPEIP85') || code !== 'WPEIP85'
  })

  return !_.includes(ignoreRider, code)
}
// $$FlowFixMe
export const getRiderYearsOfCoverage = (rider) => {
  return isPaidPeriodTurnToOne(_.get(rider, 'code')) ? 1 : _.get(rider, 'yearsOfCoverage', 1)
}
// $$FlowFixMe
export const getRiderYearsOfPayment = (rider, ops) => {
  const asterisk = _.get(ops, 'hasAsterisk') ? '*' : ''
  return isPaidPeriodTurnToOne(_.get(rider, 'code')) ? `1${asterisk}` : _.get(rider, 'yearsOfPayment', 1)
}

export const hasAsteriskFromRiders = (riders) => {
  return _.some(riders, (rider) => isPaidPeriodTurnToOne(_.get(rider, 'code')))
}
