// @flow
import template from 'core/service/pdf-generation/products/life-save-pro/template'
import { section } from 'core/service/pdf-generation/products/life-save-pro/sections'
import { commonSection } from 'core/service/pdf-generation/products/common/sections'
import { createPdfDocument } from 'core/service/pdf-generation/document-creation'
import { createCommonPdf } from '../common/pdf-document'
import type { LifeSaveProProps } from './index'
import type { DisplayProduct } from 'core/service/display-product'
import Mustache from 'mustache'
import MESSAGES from 'core/data-model/constants/bi-messages'
import { sections as flexiHealthSection } from 'core/service/pdf-generation/products/flexi-health/sections'

const getProfileProps = (props: LifeSaveProProps) => {
  return {
    basicPlanLabel: (displayProduct: DisplayProduct) => displayProduct.nameThai,
  }
}

const getProductBenefitsProps = (props: LifeSaveProProps) => {
  return {
    basicPlanDetailsLabelFn: (displayProduct: DisplayProduct): string =>
      Mustache.render(MESSAGES.BASIC_PLAN_DETAILS, {
        name: displayProduct.nameThai,
        code: displayProduct.code,
      }),
  }
}

export const createMiniBIDocDefinition = async (props: LifeSaveProProps) => {
  const pdfTemplate = await template(props)
  const riderSections = commonSection.riders(props)

  return {
    content: [
      ...commonSection.profile({ ...props, ...getProfileProps(props) }),
      ...commonSection.productBenefits({
        ...props,
        ...getProductBenefitsProps(props),
      }),
      ...riderSections,
      ...section.policyValueTable(props),
      section.investmentBenefitTable(props),
      commonSection.taxDeductionBenefit(props),
      flexiHealthSection.flexiHealthlegalConditionDisclaimerMarketConduct(props),
    ],
    ...pdfTemplate,
  }
}

export const createFullBIDocDefinition = async (props: LifeSaveProProps) => {
  const pdfTemplate = await template({ ...props, biType: props.biType })
  const paymentChannels = await commonSection.paymentChannels(props)
  const riderSections = commonSection.riders(props)

  return {
    content: [
      ...commonSection.profile({ ...props, ...getProfileProps(props) }),
      ...commonSection.productBenefits({
        ...props,
        ...getProductBenefitsProps(props),
      }),
      ...riderSections,
      commonSection.basicPlanCondition,
      ...section.policyValueTable(props),
      section.investmentBenefitTable(props),
      { text: '', pageBreak: 'after' },
      commonSection.taxConsentDeductionBenefit(props),
      flexiHealthSection.flexiHealthlegalConditionDisclaimerMarketConduct(props),
      paymentChannels,
      commonSection.signature(props),
    ],
    ...pdfTemplate,
  }
}

const createFullBIPdf = createPdfDocument(createFullBIDocDefinition)
const createMiniBIPdf = createPdfDocument(createMiniBIDocDefinition)

export const createLifeSaveProPdf = async (props: LifeSaveProProps) => {
  return await createCommonPdf(props, createFullBIPdf, createMiniBIPdf)
}
