// @flow
import { createSelector } from 'reselect'
import { getPolicyValues } from 'quick-quote/product-whole-life/benefit-illustration/selectors'
import { getSelectedDisplayProduct } from 'quick-quote/product-selection/selectors'

import type { PolicyValueTableProps } from 'core/service/benefit-illustration/policy-value'

export const getPolicyValueTableBenefits = createSelector(
  getSelectedDisplayProduct,
  getPolicyValues,
  (displayProduct, policyValue): PolicyValueTableProps => ({
    basicPlan: displayProduct,
    policyValue,
  })
)
