//@flow
/* istanbul ignore file */

import type { DisplayProduct } from 'core/service/display-product'
import { formattedHeaderWithStyles, productInfoHeaderStyles } from '../../common/components/product-information-header'
import MESSAGE from 'core/data-model/constants/bi-messages'
import { formatNumber } from 'core/service/lib/number-format'

const modifiedStyles = {
  ...productInfoHeaderStyles,
  productName: {
    ...productInfoHeaderStyles.productName,
    lineHeight: 0.8,
  },
}

export const getPdfHeaderParameters = (
  displayProduct: DisplayProduct,
  selectedPlanName: string,
  selectedPlanCode: string,
  sumAssured: number
) => {
  const header1 = `${displayProduct.name} ${selectedPlanName}`
  const header2 = `${displayProduct.nameThai} ${MESSAGE.PLAN} ${formatNumber(sumAssured, 0)}`
  return { header1, header2 }
}

export const render = (
  displayProduct: DisplayProduct,
  selectedPlanName: string,
  selectedPlanCode: string,
  redline: string,
  sumAssured: number
) => {
  const { header1, header2 } = getPdfHeaderParameters(displayProduct, selectedPlanName, selectedPlanCode, sumAssured)
  return formattedHeaderWithStyles(header1, header2, redline, modifiedStyles)
}
