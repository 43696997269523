//@flow
import _ from 'lodash'
import type {
  BasicPlan,
  Category,
  DiscountRate,
  Period,
  Rule,
  RuleType,
  ProductPlans,
  AuraBenefitMapping,
  basicPlanCodeOption,
} from 'core/data-model/basic-plan/types'
import type { Insured, Payer } from 'core/data-model/insured/types'
import type { RiderCode, Rider } from 'core/data-model/rider'
import type { ViewConfiguration } from 'core/data-model'
import type { PromiseFunction } from 'core/data'
import type { ReadOnlyFields } from 'core/data-model/types'
import type { PlanCodeAttribute } from 'core/service/display-product/types'

import { isValidConfigurationForTMO } from 'core/service/basic-plan/validation'
import { dataWrapper } from 'core/data'
import { getRider } from 'core/data-model/rider'

const { list, get } = dataWrapper
const DOCUMENT_KEY = 'product'

type DefaultValues = {
  loanAmount: number,
  loanTerm: number,
  sumAssured: number,
  coverageTerm: number,
  insured: Insured,
  payer: Payer,
}

export type RawBasicPlan = {
  package?: boolean,
  category: Category,
  productCode: string,
  name: string,
  nameThai: string,
  fullNameThai: ?string,
  displayName: string,
  description: string,
  benefits: string[],
  image: string,
  sumAssuredMinimum: number,
  sumAssuredMaximum: number,
  discountRates: DiscountRate[],
  readOnlyFields: ReadOnlyFields,
  riders: string[],
  modelFactors: string[],
  selectedRiders: RiderCode[],
  rateUnit: number,
  paymentPeriod: Period,
  coveragePeriod: Period,
  licenses: string[],
  rules?: Rule[],
  planCodeSchema?: PlanCodeAttribute[],
  type: 'product',
  defaults?: DefaultValues,
  requiredRiders?: string[],
  optionalRiders?: string[],
  insuredEntryAgeMinimum?: string,
  insuredEntryAgeMaximum?: string,
  productPlans?: ProductPlans,
  aura?: AuraBenefitMapping,
  validOccupationFactors?: string[],
  expiryDate?: string,
  ribbonMessage?: string,
  payerSelection?: string[],
  allowUserType?: string[],
  agentOverrides?: Object,
  bancOverrides?: Object,
  userOverrides?: Object,
  userOverridesList?: string[],
  userOverridesEffectiveDate?: string,
  userOverridesExpiryDate?: string,
  defaultSumAssured?: number,
  basicPlanCodeOption?: basicPlanCodeOption[],
  brochureLink?: Object,
}

export const _getViewConfiguration = (get: PromiseFunction<ViewConfiguration>) => get('view-configuration')

export const getViewConfiguration = () => _getViewConfiguration(get)

export const _listBasicPlans = (list: PromiseFunction<BasicPlan[]>) => list(DOCUMENT_KEY)

export const listBasicPlans = () => _listBasicPlans(list)

export const _listActiveBasicPlans = (listBasicPlans: PromiseFunction<BasicPlan[]>): Promise<BasicPlan[]> =>
  Promise.resolve(listBasicPlans())

export const listActiveBasicPlans = () => _listActiveBasicPlans(listBasicPlans)

const getRawBasicPlan = async (code: string): Promise<RawBasicPlan> => {
  const basicPlan: RawBasicPlan = await get(`${DOCUMENT_KEY}_${code}`)

  return basicPlan
}

const override = <T>(object: T, overrides: *): T => {
  if (overrides == null || object == null) {
    return object
  } else {
    return { ...object, ...overrides }
  }
}

const _overrideByAgentCode = (basicPlanByType: RawBasicPlan, distributorGroup: string = '', agentCode: string = '') => {
  if (
    isValidConfigurationForTMO(
      basicPlanByType.code,
      basicPlanByType.userOverridesEffectiveDate ? basicPlanByType.userOverridesEffectiveDate : '01-01-2000 00:00:00',
      basicPlanByType.userOverridesExpiryDate ? basicPlanByType.userOverridesExpiryDate : '01-01-2999 00:00:00'
    ) &&
    'userOverridesList' in basicPlanByType &&
    'userOverrides' in basicPlanByType &&
    basicPlanByType.userOverridesList.includes(`${distributorGroup}-${agentCode}`)
  ) {
    return override(basicPlanByType, basicPlanByType.userOverrides)
  } else {
    return basicPlanByType
  }
}

export const _overrideRiderByUserType = (rawRider, distributorGroup = '') => {
  if (distributorGroup === 'BANC' && 'bancOverrides' in rawRider) {
    return override(rawRider, rawRider.bancOverrides)
  }
  if (distributorGroup === 'AGENT' && 'agentOverrides' in rawRider) {
    return override(rawRider, rawRider.agentOverrides)
  }
  return rawRider
}

const _overrideByUserType = (rawBasicPlan: RawBasicPlan, distributorGroup: string = '') => {
  if (distributorGroup === 'BANC' && 'bancOverrides' in rawBasicPlan) {
    return override(rawBasicPlan, rawBasicPlan.bancOverrides)
  }
  if (distributorGroup === 'AGENT' && 'agentOverrides' in rawBasicPlan) {
    return override(rawBasicPlan, rawBasicPlan.agentOverrides)
  }
  return rawBasicPlan
}

export const _getBasicPlan = async (
  getRawBasicPlan: (string) => Promise<RawBasicPlan>,
  getRider: (string) => Promise<Rider>,
  code: string,
  distributorGroup: string = '',
  agentCode: string = ''
): Promise<BasicPlan> => {
  const rawBasicPlan = await getRawBasicPlan(code)
  const basicPlanByType = _overrideByUserType(rawBasicPlan, distributorGroup)
  const basicPlan = _overrideByAgentCode(basicPlanByType, distributorGroup, agentCode)
  basicPlan.rules = basicPlan.rules || []
  basicPlan.riders = basicPlan.riders || []
  basicPlan.readOnlyFields = basicPlan.readOnlyFields || {}
  const riders = await Promise.all(
    basicPlan.riders.map((riderCode) => {
      return new Promise(async (resolve, reject) => {
        const code = riderCode.replace('rider_', '')
        const rawRider = await getRider(code)
        const rider = _overrideRiderByUserType(rawRider, distributorGroup)
        resolve(override(rider, _.get(basicPlan, `ridersOverrides.${code}`, null)))
      })
    })
  )

  let activeRiders: Rider[] = []
  for (var rider of riders) {
    if (rider.effectiveDate === undefined && rider.expiryDate === undefined) {
      activeRiders.push(rider)
    } else if (isValidConfigurationForTMO(rider.code, rider.effectiveDate, rider.expiryDate)) {
      activeRiders.push(rider)
    }
  }
  // $FlowFixMe
  const result: BasicPlan = { ...basicPlan, riders: activeRiders }
  return result
}

// fix flow return type refinement issue.
// see: https://github.com/facebook/flow/issues/3560
type RuleOf<R: Rule> = R

export const getBasicPlan = (code: string, distributorGroup: string, agentCode: string): Promise<BasicPlan> =>
  _getBasicPlan(getRawBasicPlan, getRider, code, distributorGroup, agentCode)

export const _getBasicPlanRuleByType = (getBasicPlan: (string) => Promise<BasicPlan>) => async (
  code: string,
  ruleType: RuleType
): Promise<RuleOf<*>> => {
  const basicPlan: BasicPlan = await getBasicPlan(code)
  const rule: ?Rule = basicPlan.rules.find(({ type }) => type === ruleType)

  if (rule != null && rule.type === ruleType) {
    return Promise.resolve(rule)
  } else {
    return Promise.reject(`Rule ${ruleType} does not exist`)
  }
}

export const getBasicPlanRuleByType = _getBasicPlanRuleByType(getBasicPlan)

export const getProductDefaults = (code: string): Promise<DefaultValues | void> =>
  getRawBasicPlan(code).then(({ defaults }) => defaults)
