import React from 'react'
import styled from 'styled-components'
import Icon from 'e-submission/components/Icon'
import { compose, lifecycle, withState } from 'recompose'
import { connect } from 'react-redux'
import { getMediaStatus, hangup, mediaActions, updateMediaState } from 'quick-quote/actions'
import { setNotificationMessage, clearNotificationMessage } from 'quick-quote/notification/actions'
import {
  getCameraState,
  getInCall,
  getMicState,
  getSharingScreenState,
  getCallLatency,
  isAgentInCall,
  isClientInCall,
} from 'quick-quote/remote-selling'
import { updateCallStatus } from 'quick-quote/remote-selling/actions'
import classNames from 'classnames'
import { IsRealDevice } from 'e-submission/utils'

const MediaControlsContainer = styled.div`
  pointer-events: none;
  position: fixed;
  z-index: 60;
  text-align: center;
  padding: 0px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  width: -webkit-fit-content;
  p {
    width: -webkit-fill-available;
  }
  .network-menu {
    width: -webkit-fill-available;
    justify-items: center;
    border-radius: 3px 15px 15px 3px;
    background: #85858533;
    display: inline-grid;
    justify-content: center;
    margin-bottom: 10px;
  }
  .hide-menu-btn {
    pointer-events: all;
    width: -webkit-fill-available;
    height: 55px;
    justify-items: center;
    font-size: larger;
    border-radius: 3px 15px 15px 3px;
    background: #85858533;
    display: inline-grid;
    justify-content: center;
    margin-bottom: 10px;
    padding: 5px 0px;
    cursor: pointer;
    button {
      padding-right: 0px;
      padding-left: 0px;
      cursor: pointer;
      outline: none;
      border: none;
      background: transparent;
      color: #423c3c;
      position: relative;
      bottom: -5%;
      left: -5%;
    }
  }
  .top-menu {
    width: -webkit-fill-available;
    border-radius: 3px 15px 15px 3px;
    background: #858585;
    margin-bottom: 10px;
    left: 0;
    transition: left 0.5s ease;
    position: relative;
  }
  .hangup {
    border-radius: 0px 0px 15px 3px !important;
    background: #c51634 !important;
  }
  .disconnected {
    color: #c51634 !important;
  }
  .wifiMedium {
    color: #e8900e !important;
  }
  .wifiLow {
    color: #ff9385 !important;
  }
  .wifiHigh {
    color: #36933ade !important;
  }
  .menu-item {
    font-family: 'helvethaica', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    pointer-events: all;
    width: 50px;
    font-size: 10px;
    color: #fff;
    display: block;
    background: none;
    padding: 8px;
    outline: none;
    border: none;
    p {
      margin: 0;
    }
  }
  .blink {
    animation: blink-animation 0.5s steps(5, start) infinite;
    -webkit-animation: blink-animation 0.5s steps(5, start) infinite;
  }
  @keyframes blink-animation {
    to {
      visibility: hidden;
      opacity: 0;
    }
  }
  @-webkit-keyframes blink-animation {
    to {
      visibility: hidden;
      opacity: 0;
    }
  }
  .hide {
    left: -100px !important;
    :after {
      display: none;
    }
  }
  .indicator {
    position: relative;
    left: 20%;
    bottom: 10%;
    background: #cfcccc78;
    width: 10px;
    height: 10px;
    border-radius: 20px;
  }
`

const MediaControls = (props) => {
  const {
    showControls,
    sharingScreen,
    micState,
    cameraState,
    toggleMic,
    toggleCamera,
    toggleShareScreen,
    hangup,
    networkState,
    showMenu,
    setShowMenu,
  } = props

  const getNetworkStatus = (networkState) => {
    switch (networkState) {
      case 'wifiHigh': {
        return (
          <div className={classNames('menu-item', networkState)}>
            <Icon.wifiHigh />
            <p>{'สัญญาณ\nอินเทอร์เน็ต\nคงที่'}</p>
          </div>
        )
      }
      case 'wifiMedium': {
        return (
          <div className={classNames('menu-item', networkState)}>
            <Icon.wifiMedium />
            <p>{'สัญญาณ\nอินเทอร์เน็ต\nไม่คงที่'}</p>
          </div>
        )
      }
      case 'wifiLow': {
        return (
          <div className={classNames('menu-item', networkState)}>
            <Icon.wifiLow />
            <p>{'สัญญาณ\nอินเทอร์เน็ต\nอ่อน'}</p>
          </div>
        )
      }
      default:
        return (
          <div className={classNames('menu-item', networkState)}>
            <Icon.wifiOff />
            <p>{'ไม่พบ\nสัญญาณ\nอินเทอร์เน็ต'}</p>
          </div>
        )
    }
  }
  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }
  if (!showControls) return null
  return (
    <MediaControlsContainer id="media-controls">
      <div className="network-menu">{getNetworkStatus(networkState?.label)}</div>
      <div className="hide-menu-btn" onClick={toggleMenu}>
        <button onClick={toggleMenu}>{showMenu ? <Icon.chevronLeft /> : <Icon.chevronRight />}</button>
        <div className={classNames('indicator', sharingScreen && 'blink')}></div>
      </div>

      <div className={classNames('top-menu', !showMenu && 'hide')}>
        <button className="menu-item" onClick={toggleMic}>
          {micState ? <Icon.microphone id="mute_btn" /> : <Icon.microphoneOff id="un_mute_btn" />}
          <p>ปิดไมค์</p>
        </button>

        <button className="menu-item" onClick={toggleCamera}>
          {cameraState ? <Icon.cameraItem id="turn_off_btn" /> : <Icon.cameraOff id="turn_on_btn" />}
          <p>ปิดกล้อง</p>
        </button>

        <button className="menu-item" onClick={toggleShareScreen}>
          {sharingScreen ? (
            <Icon.screenSharingOff id="share_screen_off_btn" />
          ) : (
            <Icon.screenSharing id="share_screen_btn" />
          )}
          <p>แชร์หน้าจอ</p>
        </button>
        <button className="hangup menu-item" onClick={hangup}>
          <Icon.hangup id="hang_up_btn" />
          <p>วางสาย</p>
        </button>
      </div>
    </MediaControlsContainer>
  )
}
const getNetworkState = (latency, agentInCall) => {
  if (!agentInCall) {
    return { label: 'disconnected', level: 0 }
  }
  switch (true) {
    case latency <= 50 && latency >= 1:
      return { label: 'wifiHigh', level: 3 }
    case latency <= 500 && latency > 50:
      return { label: 'wifiMedium', level: 2 }
    default:
      return { label: 'wifiLow', level: 1 }
  }
}

const mapPropsToState = (state) => {
  const agentInCall = isAgentInCall(state)
  const clientInCall = isClientInCall(state)
  const latency = getCallLatency(state)
  const networkState = getNetworkState(latency, agentInCall)
  return {
    showControls: getInCall(state),
    sharingScreen: getSharingScreenState(state),
    micState: getMicState(state),
    cameraState: getCameraState(state),
    to: null,
    latency,
    agentInCall,
    networkState,
    clientInCall,
    notificationType: state?.notificationMessage?.type,
    notificationMessage: state?.notificationMessage?.message,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    toggleMic: () => dispatch(updateMediaState(mediaActions.TOGGLE_MIC)),
    toggleCamera: () => dispatch(updateMediaState(mediaActions.TOGGLE_CAMERA)),
    toggleShareScreen: () => dispatch(updateMediaState(mediaActions.TOGGLE_SCREEN)),
    hangup: () => dispatch(hangup()),
    updateCallState: () => dispatch(updateCallStatus()),
    getMediaState: () => dispatch(getMediaStatus()),
    showClientConnectedPopup: () =>
      dispatch(
        setNotificationMessage({
          message: 'คู่สนทนาเชื่อมต่อกับระบบสำเร็จ',
          title: undefined,
          type: 'Information',
        })
      ),
    showClientDisconnectPopup: () =>
      dispatch(
        setNotificationMessage({
          message: 'คู่สนทนาขาดการเชื่อมต่อกับระบบกรุณารอสักครู่',
          title: undefined,
          type: 'Information',
        })
      ),
    openNetworkModal: (message, title) =>
      dispatch(
        setNotificationMessage({
          message: message,
          title: title,
          type: 'NetworkModal',
        })
      ),
    closeNetworkModal: () => dispatch(clearNotificationMessage()),
  }
}
let connected = undefined
export default compose(
  withState('notified', 'setNotified', false),
  withState('poppedLevel', 'setPoppedLevel', 5),
  withState('showMenu', 'setShowMenu', true),
  connect(mapPropsToState, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      this.props?.updateCallState()
      // all a timeout to call the function after the component is mounted
      const to = setInterval(() => {
        try {
          this.props.updateCallState()
          if (this.props?.showControls) this.props?.getMediaState()
        } catch (e) {
          console.error('Unable to update call State')
        }
      }, 5000)
      this.setState({ to })
    },
    componentWillUnmount() {
      if (this.state?.to) clearInterval(this.state?.to)
      if (this.state?.timeout) clearInterval(this.state?.timeout)
      if (this.state?.hangupTimeout) clearInterval(this.state?.hangupTimeout)
      connected = false
      this.setState({ to: undefined, timeout: undefined, hangupTimeout: undefined })
    },
    componentDidUpdate(prevProps) {
      const agentDisconnectedMsg =
        'ระบบกำลังค้นหาสัญญาณ...หากยังไม่พบสัญญาณภายใน 60 วินาที ระบบจะดำเนินการวางสายโดยทันที'
      if (!this.props?.showControls) return
      if (!IsRealDevice) return
      if (JSON.stringify(prevProps) === JSON.stringify(this.props)) return
      const networkState = this.props.networkState
      const weekNetworkStatus = ['wifiMedium', 'wifiLow']
      if (
        prevProps.clientInCall &&
        this.props.clientInCall === false &&
        ['None', 'Information'].includes(this.props.notificationType)
      ) {
        this.props.showClientDisconnectPopup()
      }
      if (
        prevProps.clientInCall === false &&
        this.props.clientInCall &&
        ['None', 'Information'].includes(this.props.notificationType)
      ) {
        if (connected) {
          this.props?.closeNetworkModal()
          this.props.showClientConnectedPopup()
        }
      }
      if (this.props.clientInCall) connected = true
      if (this.props.agentInCall && this.state?.hangupTimeout) {
        this.props.getMediaState()
        clearTimeout(this.state?.hangupTimeout)
        this.setState({ hangupTimeout: undefined })
      }
      if (
        prevProps.agentInCall &&
        !this.props.agentInCall &&
        ['None', 'Information', 'NetworkModal'].includes(this.props.notificationType)
      ) {
        this.props.openNetworkModal(agentDisconnectedMsg, 'ตรวจสอบพบสัญญาณอินเทอร์เน็ตไม่เสถียร ')
        if (!this.state?.hangupTimeout) {
          const hangupTimeout = setTimeout(() => {
            this.props.hangup()
          }, 60000)
          this.setState({ hangupTimeout })
        }
      } else {
        if (weekNetworkStatus.includes(networkState.label)) {
          if (!this.state?.timeout) {
            const timeout = setTimeout(() => {
              if (!this.state?.notified && ['None', 'NetworkModal'].includes(this.props.notificationType)) {
                this.props.openNetworkModal(
                  networkState.level === 2
                    ? 'กรุณาตรวจสอบอินเทอร์เน็ตของคุณ\nแนะนำให้เปลี่ยนพื้นที่ให้ไม่อับสัญญาณหรือลองเปลี่ยนสัญญาณอินเทอร์เน็ตที่มีความคงที่'
                    : 'กรุณาตรวจสอบอินเทอร์เน็ตของคุณ\nโปรดหลีกเลี่ยงการใช้อินเทอร์เน็ตสาธารณะหรือการกระจายสัญญาณอินเทอร์เน็ตจากมือถือ\nแนะนำให้ลองเปลี่ยนสัญญาณอินเทอร์เน็ตที่มีความแรงและคงที่',
                  networkState.level === 2 ? 'สัญญาณอินเทอร์เน็ตไม่คงที่' : 'สัญญาณอินเทอร์เน็ตอ่อน'
                )
                this.props.setNotified(true)
                this.props.setPoppedLevel(networkState.level)
              }
            }, 10000)
            this.setState({ timeout })
          }
          if (
            this.props.agentInCall &&
            this.props.notificationType === 'NetworkModal' &&
            this.props.notificationMessage === agentDisconnectedMsg
          )
            this.props?.closeNetworkModal()
        } else {
          if (
            this.props.agentInCall &&
            this.props.notificationType === 'NetworkModal' &&
            this.props.notificationMessage === agentDisconnectedMsg
          )
            this.props?.closeNetworkModal()
          if (this.state?.timeout) {
            this.props.setNotified(false)
            clearInterval(this.state?.timeout)
            this.setState({ timeout: undefined })
          }
        }
      }
    },
  })
)(MediaControls)
