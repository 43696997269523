//@flow
import _ from 'lodash'
import MESSAGES from 'core/data-model/constants/bi-messages'
import VALUES from 'core/data-model/constants/values'
import { isShowHealthCheckList, isShowFinancialCheckList } from 'core/service/pdf-generation/products/mrta/selectors'

type HealthCheckProps = {
  insured: Object,
  sumAssuredContract: number,
  basicPremium: number,
  paymentMethod: string,
}

export const healthAndFinancialRuleSection = ({ insured, sumAssuredContract, paymentMethod }: HealthCheckProps) => {
  const age = _.get(insured, 'age.value', 0)
  const isHealthCheckList = isShowHealthCheckList(age, Number(sumAssuredContract))
  const isFinancialCheckList = isShowFinancialCheckList(Number(sumAssuredContract))
  let contentHealthAndFinancialRule = [
    {
      text:
        paymentMethod === VALUES.CASH_INCLUDE_LOAN
          ? MESSAGES.HEALTH_CHECK_CASH_INCLUDE_LOAN_HEADER
          : MESSAGES.HEALTH_CHECK_CASH_EXCLUDE_LOAN_HEADER,
      style: 'title',
    },
  ]

  if (isHealthCheckList.nonCheck) {
    contentHealthAndFinancialRule.push({ text: MESSAGES.NO_HEALTH_CHECK })
  }

  if (isHealthCheckList.cond1 || isHealthCheckList.cond2) {
    contentHealthAndFinancialRule.push(
      { text: MESSAGES.HEALTH_CHECK_LIST, bold: true },
      { text: MESSAGES.LISTS_HEALTH_CHECK[0] },
      { text: MESSAGES.LISTS_HEALTH_CHECK[1] },
      { text: MESSAGES.LISTS_HEALTH_CHECK[2] },
      { text: MESSAGES.LISTS_HEALTH_CHECK[3] },
      { text: isHealthCheckList.cond2 ? MESSAGES.LISTS_HEALTH_CHECK[4] : ' ' },
      { text: isHealthCheckList.cond2 ? ' ' : '' },
      { text: MESSAGES.NOTE },
      { text: MESSAGES.REMARK_HEALTH_CHECK[0] },
      { text: MESSAGES.REMARK_HEALTH_CHECK[1] }
    )
  }

  if (!isFinancialCheckList.nonCheck) {
    contentHealthAndFinancialRule.push({ text: ' ' }, { text: MESSAGES.FINANCIAL_STATUS_REPORTING_RULES, bold: true })

    if (isFinancialCheckList.cond1) {
      contentHealthAndFinancialRule.push({
        ol: [MESSAGES.FINANCIAL_RULES[0]],
      })
    }

    if (isFinancialCheckList.cond2) {
      contentHealthAndFinancialRule.push({
        ol: [MESSAGES.FINANCIAL_RULES[1]],
      })
    }

    if (isFinancialCheckList.cond3) {
      contentHealthAndFinancialRule.push({
        ol: [
          MESSAGES.FINANCIAL_RULES[2][0],
          MESSAGES.FINANCIAL_RULES[2][1],
          MESSAGES.FINANCIAL_RULES[2][2],
          MESSAGES.FINANCIAL_RULES[2][3],
          MESSAGES.FINANCIAL_RULES[2][4],
        ],
      })
    }
  }

  return {
    stack: contentHealthAndFinancialRule,
    style: 'groupRow',
    unbreakable: true,
  }
}
