// @flow

import { Modal } from 'common-components'
import { ModalHeader, ModalBody } from 'reactstrap'
import { withModalToggle } from 'lib/with-hoc'

// type Props = {
//   id: string,
//   className: string,
//   isModalOpen: boolean,
//   children: *,
//   title: string,
//   modalChildren: *,
//   toggleModal: (?Object) => void
// }

export const RiderWithBenefitModal = (
  // $$FlowFixMe
  { id, className, isModalOpen, children, title, modalChildren, toggleModal }
) => (
  <div id={id} className={className}>
    {children}
    {modalChildren != null && (
      <a className="toggle-modal" onClick={toggleModal}>
        <Modal
          isOpen={isModalOpen}
          toggle={toggleModal}
          backdrop={false}
          modalClassName="ltr-modal scrollable-modal rider-benefit-modal"
        >
          <ModalHeader toggle={toggleModal}>{title}</ModalHeader>
          <ModalBody className={className}>{modalChildren}</ModalBody>
        </Modal>
      </a>
    )}
  </div>
)

export default withModalToggle(RiderWithBenefitModal)
