import { dataWrapper } from 'core/data'
import { get } from 'lodash/fp'
import { appFailedSecurityPolicy, initialiseApplicationRequest } from 'quick-quote/actions'
import { loadConfig } from 'deploy-env/app-config'

import { createBrowserHistory, createHashHistory } from 'history'
import ReactDOM from 'react-dom'
import { AppContainer } from 'react-hot-loader'
import { Provider } from 'react-redux'

import { applyMiddleware, compose, legacy_createStore as createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'

import { MainContainer, reducer as rootReducer, rootSaga } from './quick-quote'

import config from 'config'

import { createUserManager, saga as identitySaga } from './identity'
import { cordovaCodePushLogs, workaroundCordovaKeyboardDidHide } from 'lib/cordova-util'
import './assets/styles/main.scss'

import { instance } from 'e-submission/domain/data/request-wrapper'
import { analytics } from 'analytics/index'
import { isNil, isNotNil } from 'core/service/lib/type-check'
import esubStore from 'e-submission/store'
import { getAppConfig } from './deploy-env/app-config'
import { getToggles } from 'quick-quote/feature-toggles'
import { BrowserRouter } from 'react-router-dom'

/* istanbul ignore next */
let composeEnhancers = compose
if (config.ocEnvironment !== 'production' && typeof window === 'object') {
  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      maxAge: 100,
      serialize: {
        replacer: (key, value) => (key === 'image' ? '' : value),
      },
      trace: true,
      traceLimit: 25,
    })
  }
}

const effectPrinter = {
  SELECT: (e) => e.selector.name,
  CALL: (e) => e.fn.name,
  PUT: (e) => e.action.type,
  FORK: (e) => `${get('args[0].type')(e) || get('args[0]')(e)} ${e.fn.name}`,
  TAKE: (e) => e.pattern,
  CANCEL: (e) => e.name,
}

const printEffect = (effect) => (type) => {
  const printer = effectPrinter[type]
  if (printer) return `${type}: ${printer(effect[type])}`
}
const logEffect = (effect) => {
  if (!effect) return
  else
    return `${Object.keys(effect)
      .filter((k) => k !== '@@redux-saga/IO')
      .map(printEffect(effect))}`
}
/* eslint-disable no-console*/
const sagaMiddleware = createSagaMiddleware({
  sagaMonitor: {
    rootSagaStarted: (o) => console.info(`Effect Started:\tID=${o.effectId}`),
    effectTriggered: (o) => {
      if (getToggles().ENABLE_GOD_MODE && getToggles().RUNTIME_ENABLE_SAGA_MONITOR) {
        console.groupCollapsed(o.effectId, logEffect(o.effect), o)
        if (o.effect) logEffect(o.effect)
      }
    },
    effectResolved: (o) => {
      if (getToggles().ENABLE_GOD_MODE && getToggles().RUNTIME_ENABLE_SAGA_MONITOR) {
        console.info(`effectResolved: ${o.effectId}`)
        console.groupEnd(o.effectId)
      }
    },
    effectRejected: (o) => {
      if (getToggles().ENABLE_GOD_MODE && getToggles().RUNTIME_ENABLE_SAGA_MONITOR) {
        console.info(`effectRejected: ${o.effectId}`)
        console.groupEnd(o.effectId)
      }
    },
    effectCancelled: (o) => {
      if (getToggles().ENABLE_GOD_MODE && getToggles().RUNTIME_ENABLE_SAGA_MONITOR) {
        console.info(`effectCancelled: ${o.effectId}`)
        console.groupEnd(o.effectId)
      }
    },
  },
})
/* eslint-enable no-console*/
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)))

const bootstrapCodePush = (chcpURL) =>
  new Promise((resolve, reject) => {
    const appVersion = config.appVersion
    const options = {
      'config-file': `${chcpURL}/chcp.json`,
      'request-headers': {
        'application-version': `${appVersion}`,
      },
    }

    window.chcp.fetchUpdate((error, data) => {
      cordovaCodePushLogs({ error, data, appVersion, state: 'fetchUpdateStartup' })
      if (error) {
        console.error('Failed to load the update with error code: ' + error.code)
        console.error(error.description)
        reject(error)
      } else {
        console.warn('Update is loaded')
        window.chcp.installUpdate((error) => {
          cordovaCodePushLogs({
            error,
            data,
            appVersion,
            state: 'installUpdate',
          })
          if (error) {
            console.error('Failed to install the update with error code: ' + error.code)
            console.error(error.description)
            reject(error)
          } else {
            console.warn('Update installed!')
            resolve()
          }
        })
      }
    }, options)
  })
const getCodePushServerURL = async () => {
  return getAppConfig().CORDOVA_CODE_PUSH_URL
}

export const bootstrap = () =>
  new Promise((resolve) => {
    if (window.cordova) {
      /* istanbul ignore next */
      document.addEventListener('deviceready', resolve, false)
    } else {
      resolve()
    }
  })
/* istanbul ignore next */
window.isRooted = () => {
  return new Promise((resolve, reject) => {
    window.IRoot.isRootedRedBeer(
      (result) => {
        if (result === true || result === 1) {
          resolve(true)
        } else resolve(false)
      },
      (error) => {
        reject(error)
      }
    )
  })
}
/* istanbul ignore next */
export const securityCheck = () =>
  new Promise((resolve, reject) => {
    if (window.cordova) {
      window
        .isRooted()
        .then((isDeviceRootedOrJailbroken) => {
          if (isDeviceRootedOrJailbroken) {
            store.dispatch(appFailedSecurityPolicy())
            reject()
          } else resolve()
        })
        .catch(() => {
          store.dispatch(appFailedSecurityPolicy())
          reject()
        })
    } else resolve()
  })

const basePath = process.env.BASE_PATH
const history = window.cordova
  ? createHashHistory({ basename: basePath })
  : createBrowserHistory({ basename: basePath })

const render = (Component) =>
  ReactDOM.render(
    <AppContainer>
      <Provider store={store}>
        <BrowserRouter>
          <Component history={history} />
        </BrowserRouter>
      </Provider>
    </AppContainer>,
    document.getElementById('app')
  )

const mockChcp = () => {
  window.chcp = {
    fetchUpdate: (cb) => {
      setTimeout(() => {
        getToggles().ENABLE_MOCK_CORDOVA_CODE_PUSH_FAILED_FETCH
          ? cb({ code: 99, description: 'MOCK ERROR FETCH UPDATE' })
          : cb(undefined, {})
      }, 1500)
    },
    installUpdate: (cb) => {
      setTimeout(() => {
        getToggles().ENABLE_MOCK_CORDOVA_CODE_PUSH_FAILED_INSTALL
          ? cb({ code: 99, description: 'MOCK ERROR INSTALL' })
          : cb(undefined)
      })
    },
  }
}
bootstrap()
  //  .then(securityCheck)
  .then(loadConfig)
  .then((configJSON) => {
    analytics.initialiseGA(getAppConfig().GA_TRACKING_ID)
    if (getToggles().ENABLE_AUTHORISATION_CODE_FLOW) {
      instance.interceptors.request.use((config) => ({
        ...config,
        withCredentials: true,
        crossDomain: true,
      }))
    }
    const userManager = createUserManager(configJSON)
    sagaMiddleware.run(rootSaga, dataWrapper, userManager, esubStore, history)
    sagaMiddleware.run(identitySaga, userManager, store)
    store.dispatch(initialiseApplicationRequest(config.iConnectDb.localURL))
  })
  .then(() => import(/* webpackChunkName: "e-submission" */ 'e-submission'))
  .then(({ initEsubmission }) => initEsubmission(store, history))
  .then(() => {
    render(MainContainer)
  })
  .then(() => {
    const isMockChcp = getToggles().ENABLE_MOCK_CORDOVA_CODE_PUSH_ON_WEB
    if (isMockChcp && !window.cordova) {
      mockChcp()
    }
    if (window.cordova && getToggles().GET_UPDATE_FROM_CORDOVA_CODE_PUSH) {
      getCodePushServerURL()
        .catch((error) => error)
        .then(bootstrapCodePush)
        .catch((error) => error)
    }
    workaroundCordovaKeyboardDidHide()
  })
  .catch((e) => {
    location.reload()
  })
/* istanbul ignore next */
if (module.hot) {
  module.hot.accept('./quick-quote', () => render(MainContainer))
  module.hot.accept('./e-submission', () => render(MainContainer))
  module.hot.accept('./e-submission/app', () => render(MainContainer))
  module.hot.accept('./e-submission/domain/data/request-wrapper', () => render(MainContainer))
  module.hot.accept('./identity', () => render(MainContainer))
  module.hot.accept('./analytics', () => render(MainContainer))
}
/* istanbul ignore next */
const onDefocusActiveElement = (event) => {
  if (isNil(document.activeElement)) return

  const element = event.target
  const elementTagName = element.nodeName
  /* istanbul ignore next */
  const canContinueFocusing =
    elementTagName === 'INPUT' &&
    isNotNil(element.attributes) &&
    isNil(element.attributes.readonly) &&
    isNil(element.attributes.disabled)
  if (canContinueFocusing) return
  /* istanbul ignore next */
  if (isNotNil(document.activeElement.blur)) {
    document.activeElement.blur()
  }
}

document.body.addEventListener('touchstart', onDefocusActiveElement)
