// @flow

import type { AppState } from 'quick-quote/reducers'
import type { Age } from 'core/data-model/insured'
import { createSelector } from 'reselect'

import { getAge } from 'quick-quote/insured-information/selectors'
import { getSumAssured, getPremium, getPaymentMethod } from 'quick-quote/product-loan/coverage-plan/redux'
import type { FinancialCheckList, HealthCheckList } from 'core/data-model/loan'

export const getHealthCheckList = (state: AppState) => state.loan.documentList.healthCheckList[0]
export const getFinancialCheckList = (state: AppState) => state.loan.documentList.financialCheckList[0]

export const getSumAssuredByPaymentMethod = (state: AppState) => {
  return getPaymentMethod(state) === 'CASH' ? getSumAssured(state) : getSumAssured(state) + getPremium(state)
}

export const getHealthCheckDisplay = createSelector(
  getHealthCheckList,
  getSumAssuredByPaymentMethod,
  getAge,
  (healthCheckList: HealthCheckList, sumAssured: number, insureAge: Age): string[] => {
    let healthCheckDispaly = []
    for (var item of healthCheckList.healthCheckTable) {
      if (sumAssured >= item.minSA && sumAssured <= item.maxSA) {
        for (var rule of item.rules) {
          if (insureAge.value >= rule.ageRule.minAge && insureAge.value <= rule.ageRule.maxAge) {
            rule.ageRule.healthCheckCode.forEach(function(code) {
              for (var desc of healthCheckList.healthCheckDesc) {
                if (code === desc.healthCheckCode) {
                  healthCheckDispaly.push(desc.healthCheckDesc)
                  break
                }
              }
            })
            break
          }
        }
        break
      }
    }
    return healthCheckDispaly
  }
)

export const getFinancialDocumentDisplay = createSelector(
  getFinancialCheckList,
  getSumAssuredByPaymentMethod,
  (financialCheckList: FinancialCheckList, sumAssured: number): string[] => {
    let financialDocumentDisplay = []
    for (var item of financialCheckList.financialTable) {
      if (sumAssured >= item.minSA && sumAssured < item.maxSA) {
        item.financialDoc.forEach(function(code) {
          for (var desc of financialCheckList.financialDesc) {
            if (code === desc.financialCode) {
              financialDocumentDisplay.push(desc.financialDesc)
              break
            }
          }
        })
        break
      }
    }
    return financialDocumentDisplay
  }
)
