// @flow
import type { AppState } from 'quick-quote/reducers'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { RadioButtonGroup } from 'common-components'
import { ErrorFeedback } from 'common-components/view-logic'
import { selectProductMRTA } from 'quick-quote/product-mrta/actions'
import { getSelectedProductMRTA, getDescriptionProductMRTA, getSelectableProductMRTA } from '../selectors'
import VALUES from 'core/data-model/constants/values'

export const MRTAProductSelection = ({
  getSelectedProductMRTA,
  getDescriptionProductMRTA,
  selectProductMRTA,
  getSelectableProductMRTA,
}) => {
  const selectableProductMRTA = getSelectableProductMRTA
  const productMRTA = [
    {
      text: 'Full MRTA Plus+',
      value: VALUES.MRTA_CODE.MRTA_PLUS,
      canSelectable: selectableProductMRTA.includes(VALUES.MRTA_CODE.MRTA_PLUS),
    },
    {
      text: 'MRTA Truncate 10 Plus+',
      value: VALUES.MRTA_CODE.MRTA_TRUNCATE,
      canSelectable: selectableProductMRTA.includes(VALUES.MRTA_CODE.MRTA_TRUNCATE),
    },
    {
      text: 'MRTA Full',
      value: VALUES.MRTA_CODE.MRTA_FULL,
      canSelectable: selectableProductMRTA.includes(VALUES.MRTA_CODE.MRTA_FULL),
    },
  ]

  return (
    <div className="form-group">
      <div className="form-group-title">
        <span className="form-group-title-text">แบบประกัน</span>
      </div>
      <RadioButtonGroup
        keyToGetValue="value"
        className="model-factor"
        onChange={selectProductMRTA}
        value={getSelectedProductMRTA}
        options={productMRTA}
        autoClassName={true}
      />
      <ErrorFeedback
        id={'coverage-period-error-msg'}
        errorMessages={[getDescriptionProductMRTA]}
        messageType={'info-message'}
      />
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  getSelectedProductMRTA: getSelectedProductMRTA(state),
  getDescriptionProductMRTA: getDescriptionProductMRTA(state),
  getSelectableProductMRTA: getSelectableProductMRTA(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  selectProductMRTA: bindActionCreators(selectProductMRTA, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(MRTAProductSelection)
