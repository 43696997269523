//@flow
import styled from 'styled-components'
import { compose, lifecycle, withStateHandlers } from 'recompose'
import { Link } from 'react-router-dom'

import { LoginLink } from 'quick-quote/components'
import { getAppConfig } from 'deploy-env/app-config'

export const MainHeader = ({ version, onBackToHome, inCall }: MainHeaderProps) => (
  <div className="header-main">
    <div className="header-brand">
      {inCall ? (
        <div style={{ width: 80 }}></div>
      ) : (
        <Link to="#" className="header-home" id={'main-header_home-button'} onClick={onBackToHome}>
          <div className="brand-logo">
            <span className="brand-logo__main"></span>
            <span className="brand-logo__home"></span>
          </div>
        </Link>
      )}
      <div className="header-title">
        <span>
          Advisor<span className="weak">Zone</span>
        </span>
        <VersionBanner env={getAppConfig().ENV_LOCATION} appVersion={version.appVersion} />
        <EnvBanner />
      </div>
      <div className="global-nav">
        <LoginLink icon={'link-auth-login-icon'} />
      </div>
    </div>
  </div>
)

type MainHeaderProps = {
  onBackToHome: () => void,
  version: {
    appVersion: string,
  },
  selectedRemoteSelling: boolean,
  inCall: boolean,
}

type VersionBannerProps = {
  env: string,
  appVersion: string,
}

const CoverageToggle = styled.span`
  color: #fff;
  display: inline-block;
  position: relative;
  line-height: 1;
  font-size: 0.475rem;
  padding: 1px 4px;
  margin-left: 3px;
  border-radius: 4px;
  border-color: transparent;
  background-color: ${({ warn, failed }) => {
    if (failed > 0) {
      return 'red'
    }
    if (warn > 0) {
      return 'yellowgreen'
    }
    return 'green'
  }};
`

const CoverageContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-radius: 4px;
  padding: 4px;
  width: 240px;
  font-size: 0.675rem;
  position: fixed;
  right: 0;
  bottom: 0;
  transform: translateY(${({ show }) => (show ? '0' : '90')}%);
  transition: transform 0.3s ease-in-out;
  margin-top: 2px;
`

export const VersionBanner = ({ env, appVersion }: VersionBannerProps) => {
  return (
    <div className="version">
      <span className="version-tag">app: {(appVersion && appVersion.split('-')[0]) || '-'}</span>
      <EnvBanner env={env} />
      <BadgesBanner env={env} appVersion={appVersion} />
    </div>
  )
}

/* istanbul ignore next */
export const BadgesBanner = compose(
  withStateHandlers(
    {
      showCoverage: true,
    },
    {
      handleToggleBtnClick: (state) => (e) => ({ ...state, showCoverage: !state.showCoverage }),
    }
  ),
  lifecycle({
    componentDidMount() {
      setTimeout(this.props.handleToggleBtnClick, 3000)
    },
  })
)(({ env = '', coverage, unitTest, appVersion, handleToggleBtnClick, showCoverage }) => {
  if (!['DEV', 'SANDBOX'].includes(env)) return null
  if (!getAppConfig().JENKINS_BADGE_URL) return null

  const BUILD = appVersion.split('.')[2].split('-')[0]
  const baseBadgesUrl =
    BUILD > 0 ? `${getAppConfig().JENKINS_BADGE_URL}&build=${BUILD}` : `${getAppConfig().JENKINS_BADGE_URL}`
  const jenkinsBuildUrl =
    BUILD > 0
      ? `${getAppConfig().JENKINS_URL}/job/TH/job/sit/job/az/job/frontend/job/frontend-build/${BUILD}/`
      : `${getAppConfig().JENKINS_URL}/job/TH/job/sit/job/az/job/frontend/job/frontend-build/`
  const blueOceanBuildUrl =
    BUILD > 0
      ? `${
          getAppConfig().JENKINS_URL
        }/blue/organizations/jenkins/TH%2Fsit%2Faz%2Ffrontend%2Ffrontend-build/detail/frontend-build/${BUILD}/pipeline/`
      : `${getAppConfig().JENKINS_URL}/blue/organizations/jenkins/TH%2Fsit%2Faz%2Ffrontend%2Ffrontend-build/activity`
  const badges = [
    `${baseBadgesUrl}&subject=Build&status=\${displayName}`,
    `${baseBadgesUrl}&subject=Build%20Duration&status=\${duration}`,
    `${baseBadgesUrl}&subject=Build%20Since&status=\${startTime} ago`,
    `${baseBadgesUrl}&config=git-revision`,
    `${baseBadgesUrl}&config=git-message`,
    `${baseBadgesUrl}&config=unit-test`,
    `${baseBadgesUrl}&config=integration-test`,
    `${baseBadgesUrl}&config=lint`,
  ]

  return (
    <div className="coverage">
      <CoverageContent show={showCoverage}>
        <div>
          <CoverageToggle onClick={handleToggleBtnClick} show={showCoverage}>
            {BUILD > 0 ? <span>Build {BUILD} Info</span> : <span>Latest Sandbox Build Info</span>}
          </CoverageToggle>
        </div>
        <a href={jenkinsBuildUrl} target="_blank">
          View on Jenkins
        </a>
        <a href={blueOceanBuildUrl} target="_blank">
          View on Blue Ocean
        </a>
        {badges.map((src) => (
          <div key={src}>
            <img src={src} />
          </div>
        ))}
      </CoverageContent>
    </div>
  )
})

const EnvBanner = ({ env = '' }) => {
  return ['DEV', 'SANDBOX', 'UAT', 'QA'].includes(env) ? <span className="env-tag">{env}</span> : null
}
