// @flow
import { compact } from 'lodash/fp'
import _ from 'lodash'
import type { AppState } from 'quick-quote/reducers'
import BIMESSAGES from 'core/data-model/constants/bi-messages'
import VALUES from 'core/data-model/constants/values'

import LegalDisclaimerMarketConduct from 'quick-quote/product-common/benefit-illustration/components/legal-disclaimer-market-conduct'
import RiderBenefits from 'quick-quote/product-common/benefit-illustration/components/rider-benefits'
import { getProfileSnapshot } from 'quick-quote/product-common/benefit-illustration/components/profile-summary/selectors'
import {
  getcoveragePlanStartDate,
  getPensionType,
  getSumAssured,
  getSelectedRiders,
} from 'quick-quote/product-common/coverage-plan/selectors'

import ProfileSnapshotRetirement from 'quick-quote/product-retirement/benefit-illustration/components/profile-snapshot.js'
import ProductBenefitsRetirement from 'quick-quote/product-retirement/benefit-illustration/components/product-benefits/product-benefits.js'

import ProfileSnapshotBumnanReady from 'quick-quote/product-bumnan-ready/benefit-illustration/components/profile-snapshot.js'
import ProductBenefitsBumnanReady from 'quick-quote/product-bumnan-ready/benefit-illustration/components/product-benefits/product-benefits.js'

import BenefitSummaryTable from 'quick-quote/v2/components/benefit-illustration/benefit-summary-table'
import PolicyValueTable from 'quick-quote/v2/components/benefit-illustration/policy-values/layout-for-retirement/policy-value-table'
import { getPolicyValueTableBenefits } from 'quick-quote/v2/components/benefit-illustration/policy-values/layout-for-retirement/selectors'

import {
  getLegalDisclaimerEndDate,
  getTaxDeduction,
} from 'quick-quote/product-whole-life/benefit-illustration/selectors'
import { getProductBenefits } from 'quick-quote/product-whole-life/benefit-illustration/components/product-benefits/selectors'
import { isBumnanReadyCategory, getSelectedDisplayBasicPlanCode } from 'quick-quote/product-selection/selectors'

import {
  getPensionTypeString,
  getPensionPercent,
  getPensionPercents,
  getPensionPeriod,
  getTotalPensionPeriod,
  calculatePensionAmount,
  calculatePensionAmounts,
  calculateMinimumDeathCoverage,
  calculateTotalPensionAmount,
  getDeathCoveragePercentage,
} from 'core/service/benefit-illustration/products/retirement'

const _summaryHeaderWithCode = ({ displayProduct, riders }: *) => {
  const name = _.get(displayProduct, 'displayName', '')
  const riderPlanCode = _.get(displayProduct, 'basicPlanCode', '')

  const basicPlanCode = _.get(displayProduct, 'basicPlanCode')
  if (basicPlanCode === '25PL') {
    return name
  } else if (
    VALUES.BUMNAN_READY_CODE_GROUP.includes(basicPlanCode) &&
    displayProduct.type !== VALUES.CAMPAIGN_PRODUCT_TYPE
  ) {
    const nameEng = _.get(displayProduct, 'name', '')
    return `${nameEng} (${riderPlanCode})`
  }
  return `${name} (${riderPlanCode})`
}

const _summaryHeader = ({ displayProduct }: *) => {
  if (displayProduct) {
    const basicPlanCode = _.get(displayProduct, 'basicPlanCode', '')
    if (VALUES.BUMNAN_READY_CODE_GROUP.includes(basicPlanCode)) {
      return displayProduct.nameThai
    } else {
      return displayProduct.displayName
    }
  } else {
    return ''
  }
}

export const getSections = (state: AppState) => {
  const productCode = getSelectedDisplayBasicPlanCode(state)
  const riders = getSelectedRiders(state)
  const pensionType = getPensionType(state)
  const sumAssured = getSumAssured(state)

  const profileSnapShot = (productCode) => {
    if (VALUES.BUMNAN_READY_CODE_GROUP.includes(productCode)) {
      return ProfileSnapshotBumnanReady
    } else {
      return ProfileSnapshotRetirement
    }
  }

  const productBenefit = (productCode) => {
    if (VALUES.BUMNAN_READY_CODE_GROUP.includes(productCode)) {
      return ProductBenefitsBumnanReady
    } else {
      return ProductBenefitsRetirement
    }
  }

  return compact([
    {
      component: profileSnapShot(productCode),
      props: {
        ...getProfileSnapshot(state),
        profileSummaryHeader: _summaryHeaderWithCode,
        summaryTableHeader: _summaryHeader,
      },
    },
    {
      component: productBenefit(productCode),
      props: {
        ...getProductBenefits(state),
      },
    },
    riders.length > 0 && {
      component: RiderBenefits,
    },
    {
      component: PolicyValueTable,
      props: {
        ...getPolicyValueTableBenefits(state),
      },
    },
    {
      component: BenefitSummaryTable,
      props: {
        productCode: productCode,
        pensionType: getPensionTypeString(pensionType),
        pensionPercent: getPensionPercent(pensionType, productCode),
        pensionAmount: calculatePensionAmount(sumAssured, pensionType, productCode),
        totalPensionPeriod: getTotalPensionPeriod(pensionType, productCode),
        totalPensionAmount: calculateTotalPensionAmount(sumAssured, pensionType, productCode),
        minimunDeathCoverage: calculateMinimumDeathCoverage(sumAssured, productCode),
        pensionPeriod: getPensionPeriod(pensionType),
        taxDeduction: getTaxDeduction(state),
        deadCoverageSurrender: getDeathCoveragePercentage(productCode, 'surrender'),
        deadCoverageStandard: getDeathCoveragePercentage(productCode, 'standard'),
        pensionPercents: getPensionPercents(pensionType, productCode),
        pensionAmounts: calculatePensionAmounts(sumAssured, pensionType, productCode),
      },
    },
    {
      component: LegalDisclaimerMarketConduct,
      props: {
        startDate: getcoveragePlanStartDate(state),
        endDate: getLegalDisclaimerEndDate(state),
      },
    },
  ])
}

export const getMenus = (state: AppState) => {
  const isBumnanReady = isBumnanReadyCategory(state)
  return compact([
    {
      text: BIMESSAGES.SUMMARY_OF_MAIN_AND_ADDITIONAL_CONTRACTS,
      id: 'profile-summary',
      isSub: false,
    },
    {
      text: 'รายละเอียดของสัญญาหลัก',
      id: 'product-benefits',
      isSub: false,
    },
    {
      text: isBumnanReady ? BIMESSAGES.POLICY_VALUE_TABLE_BUMNAN_READY : BIMESSAGES.POLICY_VALUE_TABLE,
      id: 'policy-value',
      isSub: false,
    },
    {
      text: BIMESSAGES.INVESTMENT_BENEFITS,
      id: 'benefit-summary',
      isSub: false,
    },
    {
      text: BIMESSAGES.LEGAL_DISCLAIMER,
      id: 'legal-disclaimer-market-conduct',
      isSub: false,
    },
  ])
}
