import { createSectionCreator, createField } from './utils'
import { checker } from './selectors'
import { getSelectedPersonalTitleListEN } from 'e-submission/apps/selectors'
import _ from 'lodash'
const CRSField = (app, id) => {
  const { isYes, isNo } = checker(app)
  const isOtherTitle = _.get(app, `${id}.CRS.mainDetail.titleEN.value`) === 'Other'
  return [
    createField(`${id}.CRS.label`, [
      {
        id: `${id}.CRS.label`,
        label:
          'เอกสารรับรองตนเองถูกจัดทำภายใต้พระราชกำหนดการแลกเปลี่ยนข้อมูลเพื่อปฏิบัติตามความตกลงระหว่างประเทศเกี่ยวกับภาษีอากร พ.ศ. 2566 เพื่อเก็บรวบรวมและรายงานข้อมูลของท่านอันเกี่ยวกับถิ่นที่อยู่ทางภาษี ต่อหน่วยงานภาครัฐในประเทศหรือต่างประเทศ',
        c: 'PlainText',
        p: {
          children: '',
        },
      },
    ]),
    createField(`${id}.CRS.checked`, [
      {
        id: `${id}.CRS.checked`,
        c: 'RadioButton',
        required: true,
        label: 'ท่านเป็นผู้มีถิ่นที่อยู่ทางภาษีในประเทศอื่นๆ หรือ จ่ายภาษีให้ประเทศอื่น นอกจากประเทศไทยหรือไม่',
        p: {
          options: [
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
            {
              text: 'ใช่',
              value: 'yes',
            },
          ],
        },
      },
    ]),
    isYes(`${id}.CRS.checked`) &&
      createField(`${id}.CRS.mainDetail.titleEN`, [
        {
          label: 'คำนำหน้า',
          id: `${id}.CRS.mainDetail.titleEN`,
          c: 'Dropdown',
          required: true,
          placeHolder: 'กรุณาเลือก',
          p: {
            mergeState: (state) => ({
              options: getSelectedPersonalTitleListEN(state, 'insured'),
            }),
          },
        },
      ]),
    isYes(`${id}.CRS.checked`) &&
      isOtherTitle &&
      createField(`${id}.CRS.mainDetail.titleEN.text`, [
        {
          label: 'ระบุคำนำหน้า',
          id: `${id}.CRS.mainDetail.titleEN.text`,
          c: 'TextInput',
          required: true,
          p: {},
        },
      ]),
    isYes(`${id}.CRS.checked`) &&
      createField(`${id}.CRS.mainDetail.firstNameEN`, [
        {
          label: 'ชื่อผู้ขอเอาประกันภัย (ระบุเป็นภาษาอังกฤษ)',
          id: `${id}.CRS.mainDetail.firstNameEN`,
          c: 'TextInput',
          required: true,
          p: {},
        },
      ]),
    isYes(`${id}.CRS.checked`) &&
      createField(`${id}.CRS.mainDetail.lastNameEN`, [
        {
          label: 'นามสกุลผู้ขอเอาประกันภัย (ระบุเป็นภาษาอังกฤษ)',
          id: `${id}.CRS.mainDetail.lastNameEN`,
          c: 'TextInput',
          required: true,
          p: {},
        },
      ]),
  ]
}

export default createSectionCreator(CRSField)
