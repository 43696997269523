// @flow
import Mustache from 'mustache'
import MESSAGES from 'core/data-model/constants/bi-messages'

import { christianToThaiDate } from 'core/service/lib/date'

type LegalDisclaimerProps = {
  startDate: Date,
  endDate: Date,
}

const LegalDisclaimerMarketConduct = ({ startDate, endDate }: LegalDisclaimerProps) => {
  return (
    <div id="legal-disclaimer-market-conduct" className="legal group">
      <h3>{MESSAGES.LEGAL_DISCLAIMER}</h3>
      <ol className="nested-counter">
        <li>{MESSAGES.INCREASE_IN_PREMIUM_ADDITIONAL_CONTRACT_DETAIL}</li>
        <li>{MESSAGES.PRELIMINARY_OFFER_MAY_CHANGE}</li>
        <li>
          {Mustache.render(MESSAGES.DATE_DISCLAIMER_FOOTER, {
            startDate: christianToThaiDate(startDate),
            endDate: christianToThaiDate(endDate),
          })}
        </li>
      </ol>
    </div>
  )
}

export default LegalDisclaimerMarketConduct
