// @flow
import _ from 'lodash'
import type { Effect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import type { SelectProductMRTA, EditCoveragePeriod } from './actions'
import {
  SELECT_PRODUCT_MRTA,
  MRTA_EDIT_LOAN_AMOUNT,
  MRTA_EDIT_LOAN_PERIOD,
  MRTA_EDIT_COVERAGE_PERIOD,
  MRTA_EDIT_INTEREST_RATE,
  MRTA_EDIT_PAYMENT_SELECTED_MRTA,
  selectBasicPlan,
  updateLoanAmount,
  updateLoanPeriod,
  editLoanAmount,
  editLoanPeriod,
  editInterestRate,
  updateCoveragePeriod,
  editCoveragePeriod,
  updateMRTASumAssuredInLoan,
  updateCodeInSelectedDisplayProduct,
  updateCoveragePeriodInSelectedDisplayProduct,
  updateValidateCoveragePeriodErros,
  updateValidateInterestRateErros,
} from './actions'

import {
  calculateCoveragePlanFromSumAssured,
  validateCoveragePeriod,
  validateInterestRate,
} from 'core/service/basic-plan'
import { getProductDefaults } from 'core/data-model/basic-plan/repository'
import VALUES from 'core/data-model/constants/values'

import { populateAvailableRiders, updateCoveragePlanState } from 'quick-quote/product-common/coverage-plan/sagas'
import { getUserAgentType } from 'identity/selectors'
import {
  getDisplayProducts,
  getSelectedDisplayProduct,
  getSelectedDisplayProductQuery,
} from 'quick-quote/product-selection/selectors'
import {
  getInsured,
  getAge,
  getPayerAge,
  getPayerGender,
  getAllNatureOfDutyCodes,
  getAllPayerNatureOfDutyCodes,
  getPayerRelation,
} from 'quick-quote/insured-information/selectors'
import {
  getSumAssured,
  getSelectedModelFactorID,
  getAvailableRiders,
} from 'quick-quote/product-common/coverage-plan/selectors'
import {
  getLoanPeriod,
  getCoveragePeriod,
  getLoanAmount,
  getPaymentMethod,
  getInterestRateValue,
  getOptionsLoanPeriod,
  getOptionsCoveragePeriod,
  getLoan,
  getSelectedProductMRTA,
  isGLTSPProduct,
  getMRTATRUNCATELoanPeriod,
} from 'quick-quote/product-mrta/coverage-plan/selectors'
import { SELECT_PRODUCT } from 'quick-quote/product-selection/actions'
import { UPDATE_BIRTHDATE } from 'quick-quote/insured-information/actions'
import {
  editSumAssured,
  editBasicPremiumState,
  editRiderSelectedPlan,
} from 'quick-quote/product-common/coverage-plan/actions'

const filterBasicPlanCodeOptionMRTA = (basicPlanCodeOption, basicPlanCode, loanPeriod, coveragePeriod, age) => {
  switch (basicPlanCode) {
    case VALUES.MRTA_CODE.MRTA_PLUS:
    case VALUES.MRTA_CODE.MRTA_FULL:
    case VALUES.GLTSP_CODE.GLTSP:
      return basicPlanCodeOption.find((option) => option.coveragePeriod.value === Number(coveragePeriod))
    case VALUES.MRTA_CODE.MRTA_TRUNCATE:
      const loanPeriodMRTATRUNCATE = getMRTATRUNCATELoanPeriod(loanPeriod, age)
      return basicPlanCodeOption.find((option) => option.loanPeriod === Number(loanPeriodMRTATRUNCATE))
    default:
      return {}
  }
}

export function* setProductDefaultsMRTA(action: SelectProduct): Generator<*, *, *> {
  const displayProduct = action.payload
  if (displayProduct.category !== VALUES.MRTA) return

  const defaults = yield call(getProductDefaults, displayProduct.basicPlanCode)
  if (defaults) {
    yield put(updateCodeInSelectedDisplayProduct(defaults.code))
    yield put(updateLoanAmount(defaults.loanAmount))
    yield put(updateLoanPeriod(defaults.loanTerm))
    yield put(updateCoveragePeriod(defaults.coverageTerm))
    yield put(updateMRTASumAssuredInLoan(defaults.sumAssured))

    const validCoveragePeriod = validateCoveragePeriod(
      { category: displayProduct.category, loanPeriod: defaults.loanTerm },
      defaults.coverageTerm
    )
    yield call(populateAvailableRiders)
    const riderPlan = getRiderPlanName(displayProduct.basicPlanCode, defaults.coverageTerm)
    if (riderPlan !== '') {
      yield put(editRiderSelectedPlan('MRTAAP', { planCode: riderPlan }))
    }
    yield put(updateValidateCoveragePeriodErros(validCoveragePeriod))

    const isGLTSP = yield select(isGLTSPProduct)
    if (isGLTSP) {
      const paymentMethod = yield select(getPaymentMethod)
      const validInterestRate = validateInterestRate({ category: displayProduct.category, paymentMethod }, 0)
      yield put(updateValidateInterestRateErros(validInterestRate))
    } else {
      yield put(editInterestRate(0))
    }
  }
}

const getRiderPlanName = (basicPlanCode, coveragePeriod) => {
  if (basicPlanCode === VALUES.MRTA_CODE.MRTA_PLUS || basicPlanCode === VALUES.MRTA_CODE.MRTA_TRUNCATE) {
    return 'M' + String(coveragePeriod).padStart(2, '0') + 'AP'
  }
  return ''
}

export const selectProductMRTA = function*(action: SelectProductMRTA): Generator<*, *, *> {
  const displayProducts = yield select(getDisplayProducts)
  const loanAmount = yield select(getLoanAmount)
  const loanPeriod = yield select(getLoanPeriod)
  const sumAssured = yield select(getSumAssured)
  const coveragePeriod = yield select(getCoveragePeriod)
  const productSelected = _.find(displayProducts, { basicPlanCode: action.payload })
  if (!!productSelected) {
    yield put(selectBasicPlan(productSelected))

    const loanPeriodOptions = yield select(getOptionsLoanPeriod)
    const findLoanPeriod = loanPeriodOptions.find((option) => option.value === Number(loanPeriod))
    if (!!findLoanPeriod) {
      yield put(editLoanPeriod(loanPeriod))
    } else {
      yield put(editLoanPeriod(loanPeriodOptions[0].value))
    }

    const coveragePeriodOptions = yield select(getOptionsCoveragePeriod)
    const findCoveragePeriod = coveragePeriodOptions.find((option) => option.value === Number(coveragePeriod))
    var newCoveragePeriod = coveragePeriod
    if (!!findCoveragePeriod) {
      yield put(editCoveragePeriod(coveragePeriod))
      newCoveragePeriod = coveragePeriod
    } else {
      yield put(editCoveragePeriod(coveragePeriodOptions[0].value))
      newCoveragePeriod = coveragePeriodOptions[0].value
    }

    yield call(populateAvailableRiders)
    const riderPlan = getRiderPlanName(action.payload, newCoveragePeriod)
    if (riderPlan !== '') {
      yield put(editRiderSelectedPlan('MRTAAP', { planCode: riderPlan }))
    }
    yield put(editLoanAmount(loanAmount))
    yield put(editSumAssured(sumAssured))
    yield put(editBasicPremiumState(-1))
  }
}

export function* generateCoveragePlanFromLoanAmountChange(action: *): Generator<*, *, *> {
  const displayProductQuery: DisplayProductQuery = yield select(getSelectedDisplayProductQuery)
  const sumAssured = yield select(getSumAssured)
  const natureOfDutyCodes = yield select(getAllNatureOfDutyCodes)
  const payerNatureOfDutyCodes = yield select(getAllPayerNatureOfDutyCodes)
  const insured = yield select(getInsured)
  const modelFactorID = yield select(getSelectedModelFactorID)
  const availableRiders = yield select(getAvailableRiders)
  const payerAge = yield select(getPayerAge)
  const payerGender = yield select(getPayerGender)
  const userAgentType = yield select(getUserAgentType)
  const payerRelation = yield select(getPayerRelation)
  const loanPeriod = yield select(getLoanPeriod)
  const loanAmount = parseInt(action.payload.value)
  const coveragePeriod = yield select(getCoveragePeriod)
  const loan = yield select(getLoan)
  const interestRate = yield select(getInterestRateValue)

  const newValue = yield call(calculateCoveragePlanFromSumAssured, {
    sumAssured,
    displayProductQuery,
    insured,
    natureOfDutyCodes,
    payerNatureOfDutyCodes,
    modelFactorID,
    availableRiders,
    payerAge,
    payerGender,
    userAgentType,
    payerRelation,
    loanPeriod,
    coveragePeriod,
    loanAmount,
    loan,
    interestRate,
  })
  yield put(editBasicPremiumState(-1))
  yield call(updateCoveragePlanState, newValue)
}

// export function* generateCoveragePlanFromPaymentMethodChange(action: *): Generator<*, *, *> {
//   const sumAssured = yield select(getSumAssured)
//   yield put(editSumAssured(sumAssured))
// }

export function* validateCoveragePeriodChange(action: EditCoveragePeriod): Generator<*, *, *> {
  const displayProduct = yield select(getSelectedDisplayProduct)
  const sumAssured = yield select(getSumAssured)
  if (displayProduct.category !== VALUES.MRTA) return

  const loanPeriod = Number(yield select(getLoanPeriod))
  const coveragePeriod = Number(action.payload)
  const validCoveragePeriod = validateCoveragePeriod({ category: displayProduct.category, loanPeriod }, coveragePeriod)
  yield put(updateValidateCoveragePeriodErros(validCoveragePeriod))

  const basicPlanCodeOption = displayProduct.basicPlanCodeOption || []
  const age = yield select(getAge)
  const basicPlanOption = filterBasicPlanCodeOptionMRTA(
    basicPlanCodeOption,
    displayProduct.basicPlanCode,
    loanPeriod,
    coveragePeriod,
    age
  )
  if (basicPlanOption && !_.isEmpty(basicPlanOption)) {
    yield put(updateCodeInSelectedDisplayProduct(basicPlanOption.code))
    yield put(updateCoveragePeriodInSelectedDisplayProduct(basicPlanOption.coveragePeriod))
    const riderPlan = getRiderPlanName(displayProduct.basicPlanCode, basicPlanOption.coveragePeriod.value)
    if (riderPlan !== '') {
      yield put(editRiderSelectedPlan('MRTAAP', { planCode: riderPlan }))
    }
  }
  yield put(editSumAssured(sumAssured))
}

export function* validateLoanPeriodChange(action: EditCoveragePeriod): Generator<*, *, *> {
  const displayProduct = yield select(getSelectedDisplayProduct)
  const sumAssured = yield select(getSumAssured)
  if (displayProduct.category !== VALUES.MRTA) return
  const loanPeriod = Number(action.payload.value)
  const coveragePeriod = Number(yield select(getCoveragePeriod))
  const validCoveragePeriod = validateCoveragePeriod({ category: displayProduct.category, loanPeriod }, coveragePeriod)
  yield put(updateValidateCoveragePeriodErros(validCoveragePeriod))

  if (displayProduct.basicPlanCode === VALUES.MRTA_CODE.MRTA_TRUNCATE) {
    const basicPlanCodeOption = displayProduct.basicPlanCodeOption || []
    const age = yield select(getAge)
    const basicPlanOption = filterBasicPlanCodeOptionMRTA(
      basicPlanCodeOption,
      displayProduct.basicPlanCode,
      loanPeriod,
      coveragePeriod,
      age
    )
    if (basicPlanOption) {
      yield put(updateCodeInSelectedDisplayProduct(basicPlanOption.code))
    }
  }
  yield put(editSumAssured(sumAssured))
}

export function* validateInterestRateErrors(action: EditCoveragePeriod): Generator<*, *, *> {
  const displayProduct = yield select(getSelectedDisplayProduct)
  const sumAssured = yield select(getSumAssured)
  if (displayProduct.category !== VALUES.MRTA) return

  const interestRate = Number(action.payload)
  const paymentMethod = yield select(getPaymentMethod)
  const validInterestRate = validateInterestRate({ category: displayProduct.category, paymentMethod }, interestRate)
  yield put(updateValidateInterestRateErros(validInterestRate))

  yield put(editSumAssured(sumAssured))
}

export const switchPlanMRTAProductByAge = function*(action: *): Generator<*, *, *> {
  const basicPlan = yield select(getSelectedDisplayProduct)
  if (!VALUES.MRTA_CODE_GROUP_ACTIVE.includes(basicPlan.basicPlanCode)) return

  const selectedProductMRTA = yield select(getSelectedProductMRTA)
  if (!!selectedProductMRTA && selectedProductMRTA !== basicPlan.basicPlanCode) {
    yield call(selectProductMRTA, { type: 'SELECT_PRODUCT_MRTA', payload: selectedProductMRTA })
    yield call(populateAvailableRiders)
  } else {
    if (basicPlan.basicPlanCode === VALUES.MRTA_CODE.MRTA_TRUNCATE) {
      const basicPlanCodeOption = basicPlan.basicPlanCodeOption || []
      const age = yield select(getAge)
      const loanPeriod = yield select(getLoanPeriod)
      const coveragePeriod = Number(yield select(getCoveragePeriod))
      const sumAssured = yield select(getSumAssured)

      const basicPlanOption = filterBasicPlanCodeOptionMRTA(
        basicPlanCodeOption,
        basicPlan.basicPlanCode,
        loanPeriod,
        coveragePeriod,
        age
      )
      if (basicPlanOption && !_.isEmpty(basicPlanOption)) {
        yield put(updateCodeInSelectedDisplayProduct(basicPlanOption.code))
      }
      yield put(editSumAssured(sumAssured))
    } else {
      const coveragePeriod = yield select(getCoveragePeriod)
      const coveragePeriodOptions = yield select(getOptionsCoveragePeriod)
      const findCoveragePeriod = coveragePeriodOptions.find((option) => option.value === Number(coveragePeriod))
      if (!findCoveragePeriod) {
        yield put(editCoveragePeriod(coveragePeriodOptions[0].value))
      }
    }
  }
}

export const resetInterestRate = function*(action: *): Generator<*, *, *> {
  const sumAssured = yield select(getSumAssured)
  yield put(editInterestRate(0))
  yield put(editSumAssured(sumAssured))
}
/*
export const switchProductBySumAssured = function*(action: *): Generator<*, *, *> {
  const displayProduct = yield select(getSelectedDisplayProduct)
  if (!isGLTSPGroup(displayProduct.basicPlanCode)) return
  const sumAssured = action.payload.value
  const loanPeriod = yield select(getLoanPeriod)
  const coveragePeriod = yield select(getCoveragePeriod)
  const basicPlanCodeOption = displayProduct.basicPlanCodeOption || []

  const basicPlanOption = filterBasicPlanCodeOptionMRTA(
    basicPlanCodeOption,
    displayProduct.basicPlanCode,
    loanPeriod,
    coveragePeriod,
    sumAssured
  )
  if (basicPlanOption && basicPlanOption.code !== displayProduct.code) {
    yield put(updateCodeInSelectedDisplayProduct(basicPlanOption.code))
  }
}
*/

export function* watchers(): Generator<*, *, Effect[]> {
  yield [
    takeLatest(SELECT_PRODUCT, setProductDefaultsMRTA),
    takeLatest(SELECT_PRODUCT_MRTA, selectProductMRTA),
    takeLatest(MRTA_EDIT_LOAN_AMOUNT, generateCoveragePlanFromLoanAmountChange),
    takeLatest(MRTA_EDIT_COVERAGE_PERIOD, validateCoveragePeriodChange),
    takeLatest(MRTA_EDIT_LOAN_PERIOD, validateLoanPeriodChange),
    takeLatest(MRTA_EDIT_INTEREST_RATE, validateInterestRateErrors),
    takeLatest(MRTA_EDIT_PAYMENT_SELECTED_MRTA, resetInterestRate),
    takeLatest(UPDATE_BIRTHDATE, switchPlanMRTAProductByAge),
    // takeLatest(MRTA_EDIT_PAYMENT_SELECTED_MRTA, generateCoveragePlanFromPaymentMethodChange),
    //takeLatest([UPDATE_SUM_ASSURED$], switchProductBySumAssured),
  ]
}

export const sagas = watchers
