// @flow

import _ from 'lodash'
import { dataWrapper } from 'core/data'
import { isNil } from 'core/service/lib/type-check'
import { getBasicPlan } from 'core/service'
import VALUES from 'core/data-model/constants/values'
import type { OccupationFactor } from 'core/data-model/insured'
import type { RiderState, Rider } from 'core/data-model/rider'
import type { DisplayProductQuery } from 'core/service/display-product'

export const STANDARD_OCCUPATION = 0
export const INVALID_OCCUPATION = -1

export const transformOccupationFactor = (basicFactor: string): number => {
  switch (basicFactor) {
    case 'std':
      return STANDARD_OCCUPATION
    case 'N':
      return INVALID_OCCUPATION
    default: {
      return _.toFinite(basicFactor)
    }
  }
}

export const _isExceptOccupationFactor = (code: string) => {
  const exceptProductCodes = [VALUES.IGROW, VALUES.IRETIRE_1, VALUES.IRETIRE_5]
  return _.includes(exceptProductCodes, code)
}

export const _getOccupationFactor = (
  basicPlanCode: string,
  natureOfDuty: string,
  occupationFactors: OccupationFactor[]
) => {
  const filteredOccupationFactors = occupationFactors.filter(
    ({ natureOfDutyCode }) => natureOfDuty === natureOfDutyCode
  )
  let occupationFactor = filteredOccupationFactors.find((oc) => basicPlanCode === oc.basicPlanCode)
  if (!occupationFactor) {
    occupationFactor = filteredOccupationFactors.find(
      ({ basicPlanCode, riderCode }) => isNil(basicPlanCode) && isNil(riderCode)
    )
  }

  return occupationFactor && !_isExceptOccupationFactor(basicPlanCode)
    ? transformOccupationFactor(occupationFactor.factor)
    : transformOccupationFactor('std')
}

export const getOccupationFactor = async (
  displayProductQuery: DisplayProductQuery,
  natureOfDuty: string
): Promise<number> => {
  const basicPlan = await getBasicPlan(displayProductQuery)
  return dataWrapper
    .getOccupationFactors()
    .then((occupationFactors) => _getOccupationFactor(basicPlan.productCode, natureOfDuty, occupationFactors))
}

const chooseMaxNumber = (factor, otherFactor) => {
  if (factor === INVALID_OCCUPATION || otherFactor === INVALID_OCCUPATION) {
    return INVALID_OCCUPATION
  }
  const _factor = _.isNumber(factor) ? factor : -Infinity

  return Math.max(_factor, otherFactor)
}

export const _getRiskiestOccupation = (
  basicPlanCode: string,
  natureOfDuties: string[],
  occupationFactors: OccupationFactor[]
) => {
  const getOccupationFactorsByNatureOfDuty = (natureOfDuty) =>
    _getOccupationFactor(basicPlanCode, natureOfDuty, occupationFactors)

  return natureOfDuties.map(getOccupationFactorsByNatureOfDuty).reduce(chooseMaxNumber)
}

export const getRiskiestOccupation = async (
  displayProductQuery: DisplayProductQuery,
  natureOfDuties: string[]
): Promise<number> => {
  const basicPlan = await getBasicPlan(displayProductQuery)
  return dataWrapper
    .getOccupationFactors()
    .then((occupationFactors) => _getRiskiestOccupation(basicPlan.productCode, natureOfDuties, occupationFactors))
}

export const _getOccupationFactorByValidOccupations = (
  basicPlanCode: string,
  natureOfDuty: string,
  occupationFactors: OccupationFactor[],
  validOccupationFactors: string[]
) => {
  const occupationFactorForProduct = _getOccupationFactor(basicPlanCode, natureOfDuty, occupationFactors)
  return validOccupationFactors.map(transformOccupationFactor).includes(occupationFactorForProduct)
    ? occupationFactorForProduct
    : transformOccupationFactor('N')
}

export const getOccupationFactorByValidOccupations = async (
  displayProductQuery: DisplayProductQuery,
  natureOfDuty: string
): Promise<number> => {
  const basicPlan = await getBasicPlan(displayProductQuery)
  const validOccupationFactors = basicPlan.validOccupationFactors ? basicPlan.validOccupationFactors : []
  return dataWrapper
    .getOccupationFactors()
    .then((occupationFactors) =>
      _getOccupationFactorByValidOccupations(
        basicPlan.productCode,
        natureOfDuty,
        occupationFactors,
        validOccupationFactors
      )
    )
}

export const getRiskiestOccupationFactorForRiders = async (
  displayProductQuery: DisplayProductQuery,
  natureOfDuties: string[],
  riders: (Rider & RiderState)[]
): Promise<{ [string]: number }> => {
  if (_.isEmpty(natureOfDuties) || _.isEmpty(riders)) {
    return Promise.resolve({})
  }

  const basicPlan = await getBasicPlan(displayProductQuery)
  return dataWrapper.getOccupationFactors().then((occupationFactors) =>
    _getRiskiestOccupationFactorForRiders(
      basicPlan.productCode,
      natureOfDuties,
      riders.map(({ code }) => ({ code })),
      occupationFactors
    )
  )
}

const getRiderOccupationFactor = (basicPlanCode, riderCode, occupationFactorsByNatureOfDuty): number => {
  const isBasicPlanAndRiderCombination = (occupationFactor) =>
    basicPlanCode === occupationFactor.basicPlanCode && riderCode === occupationFactor.riderCode

  const isRiderOnly = (occupationFactor) =>
    isNil(occupationFactor.basicPlanCode) && riderCode === occupationFactor.riderCode

  const occupationFactorFromCombination = occupationFactorsByNatureOfDuty.find(isBasicPlanAndRiderCombination)

  const occupationFactorFromRiderOnly = occupationFactorsByNatureOfDuty.find(isRiderOnly)

  const occupationFactor = occupationFactorFromCombination || occupationFactorFromRiderOnly

  return occupationFactor ? transformOccupationFactor(occupationFactor.factor) : transformOccupationFactor('std')
}

const accumulateRiderOccupationFactor = (basicPlanCode, occupationFactorsByNatureOfDuty) => (acc, rider) => ({
  ...acc,
  [rider.code]: getRiderOccupationFactor(basicPlanCode, rider.code, occupationFactorsByNatureOfDuty),
})

export const _getOccupationFactorForRiders = (
  basicPlanCode: string,
  natureOfDuty: string,
  riders: { code: string }[],
  occupationFactors: OccupationFactor[]
): { [string]: number } => {
  const occupationFactorsByNatureOfDuty = occupationFactors.filter(
    ({ natureOfDutyCode }) => natureOfDuty === natureOfDutyCode
  )

  const emptyRiderOccupationFactors = {}

  return riders.reduce(
    accumulateRiderOccupationFactor(basicPlanCode, occupationFactorsByNatureOfDuty),
    emptyRiderOccupationFactors
  )
}

export const _getRiskiestOccupationFactorForRiders = (
  basicPlanCode: string,
  natureOfDuties: string[],
  riders: { code: string }[],
  occupationFactors: OccupationFactor[]
): { [string]: number } => {
  const occupationFactorForRiders = (natureOfDuties || []).map((natureOfDuty) =>
    _getOccupationFactorForRiders(basicPlanCode, natureOfDuty, riders, occupationFactors)
  )

  const maxRisk = (ridersOccupationFactor, other) => _.mergeWith(ridersOccupationFactor, other, chooseMaxNumber)

  return occupationFactorForRiders.reduce(maxRisk, {})
}
