import _ from 'lodash'
import { isSelectedRemoteSelling } from 'quick-quote/selectors'
import { createSectionCreator, createField } from './utils'
import {
  eKycCheckDataInsured,
  eKycCheckDataPayer,
  isNeedEkyc,
  isProprietorNeedEkyc,
  isDopaResultsResponse,
} from './selectors'
import { IsRealDevice } from 'e-submission/utils'

const ekycFields = (insuredOrPayer) => (app, id) => {
  const validationEKYCInsured = eKycCheckDataInsured(insuredOrPayer, app)
  const validationEKYCPayer = eKycCheckDataPayer(insuredOrPayer, app)
  const isValidationNeeded = isNeedEkyc(app)
  const isDopaValidation = isDopaResultsResponse(insuredOrPayer, app)

  return [
    createField(
      `${insuredOrPayer}.${id}`,
      _.compact(
        isValidationNeeded
          ? [
              isProprietorNeedEkyc(app, insuredOrPayer) && {
                id: `remoteSelling.${insuredOrPayer}.dopaResult`,
                c: 'TextInput',
                p: {
                  hidden: true,
                },
                required: true,
              },
              isProprietorNeedEkyc(app, insuredOrPayer) && {
                id: `remoteSelling.${insuredOrPayer}.idFaceRecognitionResult`,
                c: 'TextInput',
                p: {
                  hidden: true,
                },
                required: true,
              },
            ]
          : [
              {
                id: `${insuredOrPayer}.${id}`,
                c: 'ekycDocument',
                p: {
                  insuredOrPayer,
                },
              },
              {
                id: isDopaValidation ? `validationEKYC.${insuredOrPayer}.isRequired` : ``,
                c: 'NumberInput',
                required:
                  insuredOrPayer === 'payer'
                    ? validationEKYCPayer.isRequiredPayer
                    : validationEKYCInsured.isRequiredInsured,
                p: {
                  hidden: true,
                },
              },
            ]
      )
    ),
  ]
}

const ekycFooter = (insuredOrPayer) => (app, id) => {
  const isAppmanRemoteSelling = isSelectedRemoteSelling(app)
  return [
    createField(
      `${insuredOrPayer}.${id}`,
      _.compact([
        !isAppmanRemoteSelling && {
          id: `${insuredOrPayer}.${id}`,
          c: 'ekycDocumentFooter',
          p: {
            insuredOrPayer,
          },
        },
      ])
    ),
  ]
}

export const ekycOnDevice = (app, id) => {
  const fieldId = 'ekycInfo.isOnDevice'
  const selectedRemoteSelling = _.get(app, `selectedRemoteSelling`)

  const elements = []
  if (!selectedRemoteSelling && IsRealDevice) {
    elements.push({
      id: fieldId,
      c: 'RadioGroup',
      required: true,
      p: {
        options: [
          {
            text: 'ยืนยันตัวตน (eKYC) ผ่านระบบ AdvisorZone',
            value: true,
          },
          {
            text: 'ยืนยันตัวตน (eKYC) ด้วยตนเองผ่าน SMS หรือ email',
            value: false,
          },
        ],
      },
    })
  }

  return [createField(fieldId, elements)]
}

export const insuredEkycSection = createSectionCreator(ekycFields('insured'))
export const payerEkycSection = createSectionCreator(ekycFields('payer'))
export const ekycSectionFooter = createSectionCreator(ekycFooter('payer'))
export const ekycOnDeviceSection = createSectionCreator(ekycOnDevice)
