// @flow
import type { CashFlowRPUDR } from 'core/service/investment/cash-flow/rpudr-cash-flow'
import type { DisplayProduct } from 'core/service'

import _ from 'lodash'
import Mustache from 'mustache'

import MESSAGES from 'core/data-model/constants/bi-messages'
import VALUES from 'core/data-model/constants/values'
import { assembleTable, combineTables } from 'core/service/pdf-generation/utils/table-utils'
import formatLineBreak from 'core/service/pdf-generation/utils/line-break'
import { GroupedTableHeader, tableHeaderWithIndex, SimpleHeader } from '../../components/table-utils'
import { yearsToDisplay, policyPeriodFilter } from '../../components/cash-flow'
import {
  TABLE_LAYOUT,
  TABLE_STYLES,
  HEADER_MARGIN,
  getHeaderMargin,
  roundAndFormat,
  formatReturnValue,
} from './cash-flow-styles'

const colPolicyPeriod = (cashFlows: CashFlowRPUDR[]) => {
  const titles = [
    getHeaderMargin(MESSAGES.YEAR_AT, HEADER_MARGIN.XXL),
    getHeaderMargin(MESSAGES.MONTH, HEADER_MARGIN.XXL),
    getHeaderMargin(MESSAGES.AGE, HEADER_MARGIN.XXL),
  ]

  const header = combineTables(titles.map((title) => SimpleHeader(title, TABLE_STYLES.HEADER_ROW_COUNT, VALUES.CYAN)))

  return {
    widths: Array(titles.length).fill(TABLE_STYLES.COL_XS),
    header,
    body: cashFlows.map(({ policyPeriod }) => {
      const { year, month, age } = policyPeriod
      return [year, month, age]
    }),
  }
}

const colPremium = (cashFlows: CashFlowRPUDR[]) => {
  const subTitles = [
    getHeaderMargin(formatLineBreak(MESSAGES.BASIC_PREMIUM_FOR_COVERAGE), HEADER_MARGIN.L),
    getHeaderMargin(formatLineBreak(MESSAGES.PREMIUM_FOR_SPECIAL_INVESTMENT), HEADER_MARGIN.M),
    getHeaderMargin(formatLineBreak(MESSAGES.CASHFLOW_TABLE_PREMIUM_TOTAL_PREMIUM), HEADER_MARGIN.L),
  ]
  const startIndex = 1
  const header = GroupedTableHeader(MESSAGES.PREMIUM, subTitles, startIndex, '', TABLE_STYLES.HEADER_ROW_COUNT)

  const body = cashFlows.map(({ premium }) => {
    const { totalRegularAndRiderPremium, lumpSum, totalPremium } = premium
    return [roundAndFormat(totalRegularAndRiderPremium), roundAndFormat(lumpSum), roundAndFormat(totalPremium)]
  })

  return {
    widths: Array(subTitles.length).fill(TABLE_STYLES.COL_L),
    header,
    body,
  }
}

const colCharges = (cashFlows: CashFlowRPUDR[], selectableRidersInProduct: String[]) => {
  const groupRiders = []

  // TODO: #ilinkrider Clean up or add
  // Check if rider column should be added
  if (selectableRidersInProduct.includes(VALUES.HSUDR)) {
    groupRiders.push({
      text: formatLineBreak(MESSAGES.CASHFLOW_TABLE_POLICY_CHARGE_TYPE_HS),
      margin: HEADER_MARGIN.S,
    })
  }
  if (selectableRidersInProduct.includes(VALUES.HBUDR)) {
    groupRiders.push({
      text: formatLineBreak(MESSAGES.CASHFLOW_TABLE_POLICY_CHARGE_TYPE_HB),
      margin: HEADER_MARGIN.S,
    })
  }

  const groups = [
    {
      text: MESSAGES.BASIC_PREMIUM_FOR_COVERAGE,
      margin: HEADER_MARGIN.S,
    },
    {
      text: MESSAGES.PREMIUM_FOR_SPECIAL_INVESTMENT,
      margin: HEADER_MARGIN.S,
    },
    {
      text: formatLineBreak(MESSAGES.CASHFLOW_TABLE_POLICY_CHARGE_TYPE_COI),
      margin: HEADER_MARGIN.S,
    },
    {
      text: formatLineBreak(MESSAGES.CASHFLOW_TABLE_POLICY_CHARGE_TYPE_CI),
      margin: HEADER_MARGIN.S,
    },
    {
      text: formatLineBreak(MESSAGES.CASHFLOW_TABLE_POLICY_CHARGE_TYPE_TPD),
      margin: HEADER_MARGIN.S,
    },
    ...groupRiders,
    {
      text: formatLineBreak(MESSAGES.CASHFLOW_TABLE_POLICY_CHARGE_SUM),
      margin: HEADER_MARGIN.M,
    },
    {
      text: '',
    },
  ]

  const fillColor = VALUES.PINK
  const alignment = 'center'
  const commonProps = { alignment, fillColor }
  const blankCol = { text: '', ...commonProps }

  const titleRows = groups.map((groupTitle, index) => {
    return { text: groupTitle.text, marginTop: groupTitle.margin ? groupTitle.margin : 0, ...commonProps }
  })

  const header = [
    [
      { text: MESSAGES.RPUDR_CASHFLOW_TABLE_POLICY_CHARGE_PREMIUM_CHARGE, colSpan: groups.length, ...commonProps },
      ...Array(groups.length - 1).fill(blankCol),
    ],
    [
      {
        text: MESSAGES.PREMIUM_ALLOCATION_FEE,
        colSpan: 2,
        margin: [0, HEADER_MARGIN.S, 0, 0],
        ...commonProps,
      },
      { ...blankCol },
      {
        text: MESSAGES.CASHFLOW_TABLE_POLICY_CHARGE_GROUP,
        colSpan: groups.length - 3,
        margin: [0, HEADER_MARGIN.S, 0, 0],
        ...commonProps,
      },
      ...Array(groups.length - 4).fill(blankCol),
      {
        text: MESSAGES.CASHFLOW_TABLE_POLICY_CHARGE_AMC,
        rowSpan: 2,
        margin: [0, HEADER_MARGIN.L, 0, 0],
        ...commonProps,
      },
    ],
    titleRows,
    [
      { text: '(4)', ...commonProps },
      { text: '(5)', ...commonProps },
      { text: '(6)', ...commonProps },
      { text: '(7)', colSpan: groups.length - 5, ...commonProps },
      ...Array(groups.length - 6).fill(blankCol),
      { text: '(8)', ...commonProps },
      { text: '(9)', ...commonProps },
    ],
  ]

  // Dynamic group column width by calculate with column count (riders)
  const columnWidth = (TABLE_STYLES.COL_L * 9) / groups.length

  return {
    widths: Array(groups.length).fill(columnWidth),
    header,
    body: cashFlows.map(({ policyCharges, coiCor, insuranceManagementFee }) => {
      const { regularPremiumCharge, lumpSumCharge } = policyCharges
      const { coi, corCI, corTPD, corHS, corHB, total } = coiCor
      const { total: feeTotal } = insuranceManagementFee
      const contentRiders = []

      // TODO: #ilinkrider Same here, check if it should be removed
      if (selectableRidersInProduct.includes(VALUES.HSUDR)) {
        contentRiders.push(corHS)
      }
      if (selectableRidersInProduct.includes(VALUES.HBUDR)) {
        contentRiders.push(corHB)
      }

      const premiumColumns = [regularPremiumCharge, lumpSumCharge].map(roundAndFormat)
      const coiColumns = [coi, corCI, corTPD, ...contentRiders, total, feeTotal].map(formatReturnValue)

      return [...premiumColumns, ...coiColumns]
    }),
  }
}

const getInvestmentIndexColumns = (groups: string[], index: number = 0, isFullDetails: boolean = true) => {
  const startIndex = 1
  const summaryIndex = ['10', '11']
  const normalIndex =
    startIndex + index < groups.length ? [`10.${startIndex + index}`, `11.${startIndex + index}`] : summaryIndex
  return !isFullDetails ? summaryIndex : normalIndex
}

const getInvestmentGroupColumns = (expectedReturn: number, isFullDetails: boolean = true) => {
  return !isFullDetails
    ? [
        {
          text: MESSAGES.CASHFLOW_TABLE_INVESTMENT_REDEMPTION_VALUE_MONTH_END_PLUS_BONUS,
        },
      ]
    : [
        { text: MESSAGES.CASHFLOW_TABLE_INVESTMENT_REDEMPTION_VALUE_BEGINNING_MONTH },
        { text: Mustache.render(MESSAGES.RPUDR_CASHFLOW_TABLE_INVESTMENT_FUND_RETURN, { expectedReturn }) },
        { text: MESSAGES.CASHFLOW_TABLE_INVESTMENT_REDEMPTION_VALUE_MONTH_END },
        { text: MESSAGES.RPDUR_CASHFLOW_TABLE_INVESTMENT_BONUS_GROUP, margin: HEADER_MARGIN.S },
        { text: MESSAGES.CASHFLOW_TABLE_INVESTMENT_REDEMPTION_VALUE_MONTH_END_PLUS_BONUS },
      ]
}

const getInvestmentContents = (cashFlows: CashFlowRPUDR[], isFullDetails: boolean = true) => {
  return cashFlows.map(({ investment }) => {
    const {
      redemptionValueAtBeginningMonth,
      investmentReturn,
      redemptionValueAtMonthEnd,
      bonusPremium,
      redemptionValueAtMonthEndPlusBonus,
    } = investment

    const commonContent = [
      formatReturnValue(redemptionValueAtBeginningMonth.rpp),
      formatReturnValue(redemptionValueAtBeginningMonth.lstu),
      formatReturnValue(investmentReturn.rpp),
      formatReturnValue(investmentReturn.lstu),
      formatReturnValue(redemptionValueAtMonthEnd.rpp),
      formatReturnValue(redemptionValueAtMonthEnd.lstu),
      formatReturnValue(bonusPremium.rpp),
      formatReturnValue(bonusPremium.lstu),
    ]
    const summaryContent = [
      formatReturnValue(redemptionValueAtMonthEndPlusBonus.rpp),
      formatReturnValue(redemptionValueAtMonthEndPlusBonus.lstu),
    ]

    return !isFullDetails ? summaryContent : [...commonContent, ...summaryContent]
  })
}

const colInvestment = (expectedReturn: number, cashFlows: CashFlowRPUDR[], isFullDetails: boolean = true) => {
  const groups = getInvestmentGroupColumns(expectedReturn, isFullDetails)
  const colSize = isFullDetails ? TABLE_STYLES.COL_XXL : TABLE_STYLES.COL_L
  const widths = Array(groups.length * 2).fill(colSize)

  const fillColor = VALUES.GREEN
  const alignment = 'center'
  const groupRows = []
  const titleRows = []
  const indexRows = []
  const commonProps = { alignment, fillColor }

  groups.forEach((groupTitle, index) => {
    const colIndex = getInvestmentIndexColumns(groups, index, isFullDetails)
    // Check if column #.4
    const titlePrefix = colIndex[0].split('.')[1] === '4' ? 'มูลค่ารับซื้อคืนหน่วยลงทุนของ' : ''
    groupRows.push(
      {
        text: formatLineBreak(groupTitle.text),
        colSpan: 2,
        marginTop: groupTitle.margin ? groupTitle.margin : 0,
        ...commonProps,
      },
      { text: '', ...commonProps }
    )
    titleRows.push(
      {
        text: formatLineBreak(`${titlePrefix}${MESSAGES.BASIC_PREMIUM_FOR_COVERAGE}`),
        marginTop: titlePrefix ? 0 : HEADER_MARGIN.S,
        ...commonProps,
      },
      {
        text: formatLineBreak(`${titlePrefix}${MESSAGES.PREMIUM_FOR_SPECIAL_INVESTMENT}`),
        ...commonProps,
      }
    )
    indexRows.push({ text: `(${colIndex[0]})`, ...commonProps }, { text: `(${colIndex[1]})`, ...commonProps })
  })
  const header = [
    [
      { text: `${MESSAGES.CASHFLOW_TABLE_INVESTMENT} (10,11)`, colSpan: groups.length * 2, ...commonProps },
      ...Array(groups.length * 2 - 1).fill({ text: '', ...commonProps }),
    ],
    groupRows,
    titleRows,
    indexRows,
  ]

  return {
    widths,
    header,
    body: getInvestmentContents(cashFlows, isFullDetails),
  }
}

const colSurrender = (cashFlows: CashFlowRPUDR[]) => {
  const commonProps = { alignment: 'center', fillColor: VALUES.DARK_CYAN }
  const blankCol = { text: '', ...commonProps }

  const subTitles = [
    getHeaderMargin(MESSAGES.BASIC_PREMIUM_FOR_COVERAGE, HEADER_MARGIN.L),
    getHeaderMargin(MESSAGES.PREMIUM_FOR_SPECIAL_INVESTMENT, HEADER_MARGIN.M),
  ]
  const header = [
    [
      {
        text: MESSAGES.CASHFLOW_TABLE_SURRENDER_CASH_MONTH_END,
        marginTop: HEADER_MARGIN.M,
        colSpan: 2,
        rowSpan: 2,
        ...commonProps,
      },
      { ...blankCol },
    ],
    Array(2).fill(blankCol),
    [
      {
        text: MESSAGES.BASIC_PREMIUM_FOR_COVERAGE,
        marginTop: HEADER_MARGIN.S,
        ...commonProps,
      },
      {
        text: MESSAGES.PREMIUM_FOR_SPECIAL_INVESTMENT,
        ...commonProps,
      },
    ],
    [
      { text: '(12)', ...commonProps },
      { text: '(13)', ...commonProps },
    ],
  ]
  return {
    widths: Array(subTitles.length).fill(TABLE_STYLES.COL_L),
    header: header,
    body: cashFlows.map(({ surrender }) => {
      const { rpp, lstu } = surrender
      return [roundAndFormat(rpp), roundAndFormat(lstu)]
    }),
  }
}

const colDeathBenefit = (cashFlows: CashFlowRPUDR[]) => {
  const { HEADER_ROW_COUNT, COL_L } = TABLE_STYLES
  const header = tableHeaderWithIndex(
    getHeaderMargin(formatLineBreak(MESSAGES.CASHFLOW_TABLE_DEATH_BENEFIT_MONTH_END), HEADER_MARGIN.M),
    14,
    VALUES.DARK_CYAN,
    HEADER_ROW_COUNT
  )
  return {
    widths: [COL_L],
    header,
    body: cashFlows.map(({ deathBenefit }) => {
      return [deathBenefit].map((n) => roundAndFormat(n))
    }),
  }
}

export const findEndOfInvestmentPeriod = (cashFlows: CashFlow[], expectedReturn: number) => {
  const firstNegativeInforce = _.findIndex(cashFlows, (cashFlow) => {
    return cashFlow.inforce.basic === false
  })

  const lastPeriods = [cashFlows[cashFlows.length - 2].policyPeriod, _.last(cashFlows).policyPeriod]
  if (firstNegativeInforce < 1) return lastPeriods

  const beforeFirstLastInvestmentPeriod = cashFlows[firstNegativeInforce - 2].policyPeriod
  const firstLastInvestmentPeriod = cashFlows[firstNegativeInforce - 1].policyPeriod
  const endOfInvestmentPeriod = _.uniqBy([beforeFirstLastInvestmentPeriod, firstLastInvestmentPeriod], 'month')
  return endOfInvestmentPeriod.length ? endOfInvestmentPeriod : lastPeriods
}

export const cashFlowTable = (
  expectedReturn: number,
  cashFlows: CashFlowRPUDR[],
  displayProduct: DisplayProduct,
  tableSectionIndex: number = 0
) => {
  const selectableRidersInProduct = _.get(displayProduct, 'riders', [])
  const productCode = _.get(displayProduct, 'code', '')

  const entryCashFlow = _.find(cashFlows, { policyPeriod: { year: 1, month: 1 } }) || {
    policyPeriod: { year: 1, month: 1, age: 1 },
  }
  const endOfInvestmentPeriod = findEndOfInvestmentPeriod(cashFlows, expectedReturn)
  const chosenYearsToDisplay = yearsToDisplay(entryCashFlow.policyPeriod, endOfInvestmentPeriod, productCode)
  const cashFlowsToDisplay = _.filter(cashFlows, policyPeriodFilter(chosenYearsToDisplay))
  const policyPeriodTable = colPolicyPeriod(cashFlowsToDisplay)
  const premiumTable = colPremium(cashFlowsToDisplay)
  const charges = colCharges(cashFlowsToDisplay, selectableRidersInProduct)
  const investmentTableSummary = colInvestment(expectedReturn, cashFlowsToDisplay, false)
  const investmentTableFull = colInvestment(expectedReturn, cashFlowsToDisplay, true)
  const surrenderCashTable = colSurrender(cashFlowsToDisplay)
  const deathBenefitTable = colDeathBenefit(cashFlowsToDisplay)

  const tableLayouts = [
    [policyPeriodTable, premiumTable, charges, investmentTableSummary, surrenderCashTable, deathBenefitTable],
    [policyPeriodTable, investmentTableFull],
  ]

  const tables = tableLayouts[tableSectionIndex]

  const body = assembleTable(tables)

  const widths = tables.reduce((acc, table) => acc.concat(table.widths), [])

  return {
    ...TABLE_LAYOUT,
    table: { body, widths },
  }
}

export default cashFlowTable
