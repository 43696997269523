import _ from 'lodash'
import React from 'react'
import moment from 'moment'

import Icon from 'e-submission/components/Icon'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { getToggles } from 'quick-quote/feature-toggles'
import { getAppConfig } from '../../deploy-env/app-config'
import { withRouter } from 'react-router-dom'

const Notification = (props) => {
  return (
    <div className="az-portal__notification">
      <div className="az-portal__notification-container">
        <div className="az-portal__notification-container-left">
          <Icon.announcement style={{ width: 60, height: 60 }} />
        </div>
        <div className="az-portal__notification-container-right">
          <div>AdvisorZone ขอแจ้งปิดปรับปรุงระบบชั่วคราว</div>
          <div>
            เนื่องจากมีการปรับปรุงฐานข้อมูลของบริษัท ในวันที่ 9 ต.ค. เวลา 23:00 น. ถึงวันที่ 10 ต.ค. เวลา 02:00 น.
            ส่งผลทำให้ไม่สามารถใช้งานระบบ AdvisorZone ได้
          </div>
          <div>โปรดหลีกเลี่ยงการเข้าใช้งานในช่วงเวลาดังกล่าว ขออภัยในความไม่สะดวก</div>
        </div>
      </div>
    </div>
  )
}
class ModalNotification extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isShow: false,
    }
  }
  componentDidMount() {
    const now = moment().utc()
    const t1 = moment(getAppConfig().ENABLE_PORTAL_NOTIFICATION_STARTDATE_MODAL).utc()
    const t2 = moment(getAppConfig().ENABLE_PORTAL_NOTIFICATION_ENDDATE_MODAL).utc()
    const notiCondition = getToggles().ENABLE_PORTAL_NOTIFICATION && now.isBetween(t1, t2)
    if (notiCondition) {
      this.setState({ isShow: true })
    }
  }
  render() {
    return (
      <Modal wrapClassName="modal-noti" isOpen={this.state.isShow} autoFocus={false}>
        <ModalHeader toggle={() => this.setState({ isShow: false })}>ประกาศจากระบบ AdvisorZone</ModalHeader>
        <ModalBody>
          <div>
            ระบบ AdvisorZone จะปิดให้บริการชั่วคราว วันนี้ เวลา 19.00 น. และจะสามารถกลับใช้งานได้ ในวันที่ 9 สิงหาคม
            เวลา 8.00 น.
          </div>
          <div> - กรุณาหลีกเลี่ยงการเข้าใช้งานในช่วงเวลาดังกล่าว</div>
          <div> - ใบคำขอที่ลูกค้ายังไม่ลงนาม จะไม่ถูกโอนย้ายไปยังที่ใหม่</div>
          <div> - ใบคำขอที่ลูกค้าลงนามแล้ว ต้องนำส่งก่อน 19.00 น. เพื่อป้องกันข้อมูลสูญหาย</div>
        </ModalBody>
      </Modal>
    )
  }
}

export const TitlePortal = withRouter((props) => {
  //Deeplink Handle Section
  localStorage.setItem('deeplinkParam', JSON.stringify({}))
  localStorage.setItem('isDeeplink', false)
  window.deeplinkToUrl = function(url) {
    const path = url.split('/advisors/quote').pop()
    const [subPath, queryString] = path.split('?')
    const param = queryString.split('&')
    const paramObj = {}
    param.map((item) => {
      const [key, value] = item.split('=')
      _.set(paramObj, [`${key}`], value)
    })
    localStorage.setItem('deeplinkParam', JSON.stringify(paramObj))
    localStorage.setItem('isDeeplink', true)
    props.history.push(subPath)
  }

  const now = moment().utc()
  const t1 = moment(getAppConfig().ENABLE_PORTAL_NOTIFICATION_STARTDATE).utc()
  const t2 = moment(getAppConfig().ENABLE_PORTAL_NOTIFICATION_ENDDATE).utc()
  const notiCondition = getToggles().ENABLE_PORTAL_NOTIFICATION && now.isBetween(t1, t2)

  if (notiCondition) {
    return <Notification />
  }

  return (
    <div className="az-portal__title">
      <ModalNotification />
      <h1 className="az-portal__title-text">บริการต่างๆ จากกรุงไทย-แอกซ่า</h1>
    </div>
  )
})
