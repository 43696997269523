//@flow
import type { DisplayProduct } from 'core/service/display-product'
import { formattedHeaderWithStyles, productInfoHeaderStyles } from '../../common/components/product-information-header'
import { translatePlanToThai } from 'core/service/rider/benefits/benefit-data/fh'
import { isProductForReuseFlexiHealth } from 'quick-quote/product-flexi-health/benefit-illustration/selectors'

const modifiedStyles = {
  ...productInfoHeaderStyles,
  productName: {
    ...productInfoHeaderStyles.productName,
    lineHeight: 0.8,
  },
}

const getPdfHeaderParametersByCondition = (
  displayProduct: DisplayProduct,
  selectedPlanName: string,
  selectedPlanCode: string
) => {
  return !isProductForReuseFlexiHealth(displayProduct)
    ? `${displayProduct.nameThai} ${translatePlanToThai(selectedPlanName)} (${selectedPlanCode})`
    : `${displayProduct.nameThai}`
}

export const getPdfHeaderParameters = (
  displayProduct: DisplayProduct,
  selectedPlanName: string,
  selectedPlanCode: string
) => {
  const header1 = `${displayProduct.name} ${selectedPlanName}`
  const header2 = getPdfHeaderParametersByCondition(displayProduct, selectedPlanName, selectedPlanCode)
  return { header1, header2 }
}

export const render = (
  displayProduct: DisplayProduct,
  selectedPlanName: string,
  selectedPlanCode: string,
  redline: string
) => {
  const { header1, header2 } = getPdfHeaderParameters(displayProduct, selectedPlanName, selectedPlanCode)
  return formattedHeaderWithStyles(header1, header2, redline, modifiedStyles)
}
