// @flow

import type { RiderPlan } from 'core/data-model/rider'
import { InsuredInformationBreadcrumb } from 'quick-quote/components'
import RiderGroup from 'quick-quote/product-common/coverage-plan/components/rider/rider-group'
import TotalPremiumInput from 'quick-quote/product-common/coverage-plan/components/basic-plan/total-premium-input'
import PensionType from 'quick-quote/product-bumnan-ready/coverage-plan/components/pension-plan'
import PATH from 'quick-quote/constants/path'
import { Redirect } from 'react-router-dom'
import ModelFactorSelection from 'quick-quote/product-common/coverage-plan/components/basic-plan/model-factor-selection'
import BasicPlan from 'quick-quote/product-common/coverage-plan/components/basic-plan/basic-plan'
import BumnanReadyProductSelection from './bumnan-ready-product-selection'
import _ from 'lodash'

export type CoveragePlanProps = CoveragePlanState & CoveragePlanDispatchers

export type CoveragePlanState = {
  riderCodes: string[],
  canProceedToCoveragePlan: boolean,
  pensionType: string,
  infoMessage: string,
  displayProduct: DisplayProduct,
}

export type CoveragePlanDispatchers = {
  toggleRider: (string, boolean) => void,
  editRiderSumAssured: (string, number) => void,
  editRiderSelectedPlan: (string, RiderPlan) => void,
  editPensionType: (string) => void,
}

const renderSelection = (code: string) => {
  switch (code) {
    case 'KOOMVER-RETIRE':
      return null
    default:
      return <BumnanReadyProductSelection />
  }
}

const CoveragePlan = ({
  toggleRider,
  riderCodes,
  pensionType,
  canProceedToCoveragePlan,
  displayProduct,
  editRiderSumAssured,
  editRiderSelectedPlan,
  editPensionType,
  infoMessage,
}: CoveragePlanProps) => {
  if (!canProceedToCoveragePlan) {
    return <Redirect to={PATH.PRODUCT_SELECTION} push />
  }
  return (
    <div>
      <InsuredInformationBreadcrumb />
      <div id="coverage-plan">
        <div className="content">
          <div id="basic-plan">
            <div className="content">
              <ModelFactorSelection />

              {renderSelection(_.get(displayProduct, 'code'))}

              <BasicPlan />
            </div>
          </div>
          <div id="total-premium">
            <TotalPremiumInput />
          </div>
          <div id="pension-select">
            <PensionType pensionType={pensionType} editPensionType={editPensionType} />
          </div>
          <RiderGroup
            riderCodes={riderCodes}
            toggleRider={toggleRider}
            editRiderSumAssured={editRiderSumAssured}
            editRiderSelectedPlan={editRiderSelectedPlan}
            infoMessage={infoMessage}
          />
        </div>
      </div>
    </div>
  )
}

export default CoveragePlan
