// @flow

import _ from 'lodash'
import { round } from 'core/service/lib/number-format'
import type { SaRates } from 'core/data-model/basic-plan'

export const findSaRate = (productCode: string, SaRates?: SaRates[]) =>
  _.find(SaRates, (rate) => rate.productCode === productCode)

export const querySaRate = (SAyear: number, yearEnd: number, filteredSaRates?: SaRates): number => {
  return Number(_.get(filteredSaRates, ['saRates', 'sayear', SAyear.toString(), yearEnd.toString()], '0'))
}

export const getSumAssuredMRTA = ({ basicSumAssured, SAyear, yearEnd, filterSaRate }) => {
  const saRate = querySaRate(SAyear, yearEnd, filterSaRate)
  return round((saRate * basicSumAssured) / 1000, 0)
}
