//@flow
import type { SetFnaValue } from '../actions'
import { SET_FNA_VALUE } from '../actions'
import type { SelectProduct } from '../../product-selection/actions'
import type { MarketConductQuestionsType } from './reducer'

type MarketConductValue = number | string | number[] | string[]

export const setFnaMarketConductValue = (path: string, value: MarketConductValue): SetFnaValue => ({
  type: SET_FNA_VALUE,
  payload: {
    path: 'marketConduct.' + path,
    value: value,
  },
})

export const setFnaMarketConductReasonOthers = (value: string) => setFnaMarketConductValue('reasonOthers', value)

type QuestionValue = string | number
export const setMarketConductQuestion = (questionKey: string, value: QuestionValue): SetFnaValue =>
  setFnaMarketConductValue(`questions.${questionKey}`, value)

export const setReasonForNeverSplittingPremiumFromExpense = (value: string): SetFnaValue =>
  setFnaMarketConductValue('questions.reasonSplittingPremiumFromExpense', value)

export const setFnaMarketConductThirdPersonFullName = (value: string): SetFnaValue =>
  setFnaMarketConductValue('thirdPerson.fullName', value)

export const setFnaMarketConductThirdPersonRelationship = (value: string): SetFnaValue =>
  setFnaMarketConductValue('thirdPerson.relationship', value)

export const setFnaMarketConductThirdPersonPhoneNumber = (value: string): SetFnaValue =>
  setFnaMarketConductValue('thirdPerson.phoneNumber', value)

export const setFnaMarketConductThirdPersonTitle = (value: string): SetFnaValue =>
  setFnaMarketConductValue('thirdPerson.title.value', value)

export const setFnaMarketConductThirdPersonTitleText = (value: string): SetFnaValue =>
  setFnaMarketConductValue('thirdPerson.title.text', value)

export const setFnaMarketConductThirdPersonFirstName = (value: string): SetFnaValue =>
  setFnaMarketConductValue('thirdPerson.firstName', value)

export const setFnaMarketConductThirdPersonLastName = (value: string): SetFnaValue =>
  setFnaMarketConductValue('thirdPerson.lastName', value)

export const SET_MARKET_CONDUCT_REASON_VALUE = 'SET_MARKET_CONDUCT_REASON_VALUE'
export type SetMarketConductReasonValue = {
  type: 'SET_MARKET_CONDUCT_REASON_VALUE',
  payload: {
    value: string,
  },
}

export const setMarketConductReasonValue = (value: string): SetMarketConductReasonValue => {
  return {
    type: SET_MARKET_CONDUCT_REASON_VALUE,
    payload: {
      value: value,
    },
  }
}

export const SET_CURRENT_MARKET_CONDUCT_FORM = 'SET_CURRENT_MARKET_CONDUCT_FORM'
export type SetCurrentMarketConductForm = {
  type: 'SET_CURRENT_MARKET_CONDUCT_FORM',
  payload: {
    value: MarketConductQuestionsType,
  },
}

export const setCurrentMarketConductForm = (value: MarketConductQuestionsType): SetCurrentMarketConductForm => {
  return {
    type: SET_CURRENT_MARKET_CONDUCT_FORM,
    payload: {
      value,
    },
  }
}

export const CLEAR_MARKET_CONDUCT_FORM = 'CLEAR_MARKET_CONDUCT_FORM'
export type ClearMarketConductForm = {
  type: 'CLEAR_MARKET_CONDUCT_FORM',
}

export const clearMarketConductForm = () => ({
  type: CLEAR_MARKET_CONDUCT_FORM,
})

export const CLEAR_MARKET_CONDUCT_REASON_OTHERS = 'CLEAR_MARKET_CONDUCT_REASON_OTHERS'
export type ClearMarketConductReasonOthers = {
  type: 'CLEAR_MARKET_CONDUCT_REASON_OTHERS',
}
export const clearMarketConductReasonOthers = () => {
  return {
    type: CLEAR_MARKET_CONDUCT_REASON_OTHERS,
  }
}

export const CLEAR_THIRD_PERSON = 'CLEAR_THIRD_PERSON'
export type ClearThirdPerson = {
  type: 'CLEAR_THIRD_PERSON',
}
export const clearThirdPerson = () => {
  return {
    type: CLEAR_THIRD_PERSON,
  }
}

export type Action =
  | SelectProduct
  | SetMarketConductReasonValue
  | ClearMarketConductReasonOthers
  | ClearThirdPerson
  | ClearMarketConductForm
  | SetCurrentMarketConductForm
