//@flow
import styled from 'styled-components'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

export class _WarningModal extends React.Component {
  static defaultProps = {
    message: { title: '', body: '' },
  }

  constructor(props: *) {
    super(props)
  }

  render() {
    const {
      toggle,
      className,
      isOpen,
      message: { title, body },
      confirmBtn,
      linkBtn = {},
    } = this.props
    return (
      <Modal
        toggle={toggle}
        className={className}
        modalClassName="default-bootstrap"
        isOpen={isOpen}
        backdrop={true}
        autoFocus={false}
      >
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody dangerouslySetInnerHTML={{ __html: body }} />
        <ModalFooter>
          <Link to={linkBtn}>
            <Button color="primary" onClick={confirmBtn}>
              ตกลง
            </Button>
          </Link>
        </ModalFooter>
      </Modal>
    )
  }
}

const WarningModal = styled(_WarningModal)`
  .modal-title {
    font-size: 1rem;
    color: #2425aa;
    font-weight: bold;
    text-align: center;
    flex: 1;
  }
  .modal-header {
    padding: 5px 15px 10px 15px;
  }
  .modal-body {
    background: white;
    position: relative;
    flex: 1 1 auto;
    padding: 15px;
  }
  .modal-footer {
    justify-content: center;
  }
`

WarningModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  confirmBtn: PropTypes.func,
  linkBtn: PropTypes.string,
  message: PropTypes.shape({ title: PropTypes.string, body: PropTypes.string }),
}

export default WarningModal
