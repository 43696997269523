//@flow

import { get } from 'lodash/fp'
import styled from 'styled-components'
import VALUES from 'core/data-model/constants/values'

type OptionValue = string | number
export type RadioOption = {
  value: OptionValue,
  text: Object,
}
export type RadioGroupProps = {
  setValue: Function,
  value: ?OptionValue,
  options: RadioOption[],
  disabled: boolean,
}

type RadioProps = {
  option: RadioOption,
  active: boolean,
  onClick: Function,
  disabled: boolean,
}

const CheckBox = styled.div`
  width: 20px;
  height: 20px;
  margin-top: 2px;
  &::before {
    content: '';
    background-color: ${({ disabled }) => (disabled ? '#eceeef' : 'white')};
    width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    display: inline-block;
    border-radius: 20px;
    position: relative;
  }
  &::after {
    content: '';
    background-color: ${({ disabled }) => (disabled ? '#eceeef' : 'white')};
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 20px;
    position: relative;
    top: -31px;
    left: 5px;
  }
  &.active::after {
    background-color: ${VALUES.PRIMARY_COLOR};
  }
`

const RadioWrapper = styled.div`
  display: flex;
  justify-content: start;
`
const Content = styled.div`
  flex: 1;
  margin-left: 10px;
`
const Description = styled.span`
  ${({ show }) =>
    show
      ? `
      display: block;
      color: var(--color-primary-blue);
    `
      : `
      display: none;
      `}
`
export const Radio = ({ option, active, onClick, disabled }: RadioProps) => (
  <RadioWrapper
    className={['radio-item', active ? 'active' : ''].join(' ')}
    onClick={(e) => {
      e.preventDefault()
      onClick(option)
    }}
  >
    <CheckBox className={active ? 'active' : ''} disabled={disabled} />
    <Content>
      {option.text}
      {option.description && <Description show={active}>{option.description}</Description>}
    </Content>
  </RadioWrapper>
)

const RadioGroupWrapper = styled.div`
  flex-direction: column;
  flex-wrap: wrap;
  .radio-item {
    cursor: pointer;
    flex: 0 0 100%;
    align-items: flex-start;
    padding: 8px 10px 8px 10px;
    &:last-child {
      margin-bottom: -1px;
    }
    label {
      margin-bottom: 0;
    }
  }
`

const RadioGroup = ({ setValue, value, options, disabled = false }: RadioGroupProps) => (
  <RadioGroupWrapper>
    {options.map((option, index) => (
      <Radio
        className="radio-item-option"
        key={`${index}-${option.value}`}
        onClick={disabled ? () => {} : setValue}
        option={option}
        active={get('value')(option) === value}
        disabled={disabled}
      />
    ))}
  </RadioGroupWrapper>
)

export default RadioGroup
