import { compose, withHandlers, mapProps } from 'recompose'
import { RadioGroup } from 'common-components'

const Component = ({ options, onClick, value, disabled }) => (
  <RadioGroup options={options} value={value} setValue={onClick} disabled={disabled} />
)
export default (hoc) =>
  compose(
    withHandlers({
      onClick: ({ setValue, customOnClick }) => (option) => {
        if (!!customOnClick) {
          customOnClick(option)
        }

        setValue(option)
      },
    }),
    mapProps(({ value, ...props }) => ({
      ...props,
      value: _.get(value, 'value'),
    })),
    hoc
  )(Component)
