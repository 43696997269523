// @flow

import type { Dispatch } from 'redux'
import { bindActionCreators } from 'redux'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, branch, renderComponent } from 'recompose'

import type { AppState } from 'quick-quote/reducers'

import { MaturityValidate } from './components'
import { editMaturityIDCard } from 'quick-quote/product-s7cc/actions'
import { getCurrentMaturityIDCard, getErrorMaturityValidateMsg } from 'quick-quote/product-s7cc/selectors'
import { getSelectedDisplayProductCode } from 'quick-quote/product-selection/selectors'
import { isLoading } from 'quick-quote/ui/selectors'

const mapStateToProps = (state: AppState) => ({
  currentMaturityIDCard: getCurrentMaturityIDCard(state),
  errors: getErrorMaturityValidateMsg(state),
  isLoading: isLoading(state),
  selectedDisplayProductCode: getSelectedDisplayProductCode(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  editMaturityIDCard: bindActionCreators(editMaturityIDCard, dispatch),
})

export const MaturityValidateContainer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  branch(
    (props) => !props.selectedDisplayProductCode,
    renderComponent(() => <Redirect to="/" push />)
  )
)(MaturityValidate)
