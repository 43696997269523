//@flow
import _ from 'lodash'
import MESSAGES from 'core/data-model/constants/bi-messages'
import VALUES from 'core/data-model/constants/values'
import { formatNumber } from 'core/service/lib/number-format'

import type { DisplayProduct } from 'core/service/display-product'
import type { PolicyValue } from 'core/service/benefit-illustration'
import type { PolicyValueTableProps } from 'core/service/benefit-illustration/policy-value'

export const _breakLine = (text: string) => {
  const premiumPattern = /^(เบี้ยประกันภัย)(สัญญา.+)$/
  const deathBenefitPattern = /^(ความคุ้มครอง)(การเสียชีวิต)$/
  const surrenderCashPattern = /^(มูลค่าเวนคืน)(เงินสด\**)$/
  const savingWithCompany = /^(กรณีรับเป็น)(เงินสด)$/

  const patterns = [premiumPattern, deathBenefitPattern, surrenderCashPattern, savingWithCompany]

  // add space to trick pdfmake to do line wrapping for us
  const breakByFirstMatched = ([p, ...rest]) => (text.match(p) ? text.replace(p, '$1 $2') : breakByFirstMatched(rest))

  return breakByFirstMatched(patterns)
}

const buildHeaderTable = (code) => {
  switch (code) {
    case VALUES.MRTA_CODE.MRTA_FULL:
    case VALUES.MRTA_CODE.MRTA_PLUS:
    case VALUES.MRTA_CODE.MRTA_TRUNCATE:
      return [
        [
          { text: MESSAGES.GIO_END_OF_POLICY, rowSpan: 2, style: 'tableHeader' },
          { text: MESSAGES.TABLE_HEADER_POLICY_COVERAGE, colSpan: 2, style: 'tableHeader' },
          '',
          { text: MESSAGES.TABLE_HEADER_POLICY_TOTAL_SURRENDER, rowSpan: 2, style: 'tableHeader' },
        ],
        [
          '',
          { text: MESSAGES.BASIC_PLAN, style: 'tableHeader' },
          { text: MESSAGES.TABLE_HEADER_POLICY_RIDER_ACCIDENT, style: 'tableHeader' },
          '',
        ],
      ]
    case VALUES.GLTSP_CODE.GLTSP:
      return [
        [
          { text: MESSAGES.GIO_END_OF_POLICY, style: 'tableHeader' },
          { text: MESSAGES.TABLE_HEADER_POLICY_COVERAGE_SUMASSURED, style: 'tableHeader' },
          { text: MESSAGES.TABLE_HEADER_POLICY_SURRENDER, style: 'tableHeader' },
        ],
      ]
    default:
      return []
  }
}

const rowHeightConfig = { lineHeight: 0.75, margin: [0, -3, 0, 0] }

const buildPolicyValueRow = (yearEndPolicy: YearEndPolicyValueMRTA, code: string) => {
  switch (code) {
    case VALUES.MRTA_CODE.MRTA_FULL:
    case VALUES.MRTA_CODE.MRTA_PLUS:
    case VALUES.MRTA_CODE.MRTA_TRUNCATE:
      return [
        {
          text: yearEndPolicy.yearEndPolicy,
          alignment: 'center',
          ...rowHeightConfig,
        },
        {
          text: yearEndPolicy.basicSumAssured === 0 ? '-' : formatNumber(yearEndPolicy.basicSumAssured, 0),
          alignment: 'center',
          ...rowHeightConfig,
        },
        {
          text: yearEndPolicy.riderSumAssured === 0 ? '-' : formatNumber(yearEndPolicy.riderSumAssured, 0),
          alignment: 'center',
          ...rowHeightConfig,
        },
        {
          text: yearEndPolicy.surrenderCash === 0 ? '-' : formatNumber(yearEndPolicy.surrenderCash),
          alignment: 'center',
          ...rowHeightConfig,
        },
      ]
    case VALUES.GLTSP_CODE.GLTSP:
      return [
        {
          text: yearEndPolicy.yearEndPolicy,
          alignment: 'center',
          ...rowHeightConfig,
        },
        {
          text: yearEndPolicy.basicSumAssured === 0 ? '-' : formatNumber(yearEndPolicy.basicSumAssured, 0),
          alignment: 'center',
          ...rowHeightConfig,
        },
        {
          text: yearEndPolicy.surrenderCash === 0 ? '-' : formatNumber(yearEndPolicy.surrenderCash),
          alignment: 'center',
          ...rowHeightConfig,
        },
      ]
    default:
      return []
  }
}

const buildMRTAPolicyValueTable = (policyValue: PolicyValue, basicPlan) => {
  const policyValueTables = []
  const rowsPerPage = [36, 37, 36]
  const pageAmount = Math.ceil(policyValue.yearEndPolicyValues.length / rowsPerPage[0])
  const code = _.get(basicPlan, 'basicPlanCode', '')
  _.times(pageAmount, (currentPage) => {
    policyValueTables.push({
      table: {
        headerRows: 2,
        widths: code === VALUES.GLTSP_CODE.GLTSP ? [60, '*', '*'] : [60, 100, 120, '*'],
        body: [
          ...buildHeaderTable(code),
          ...policyValue.yearEndPolicyValues
            .slice(
              currentPage * rowsPerPage[currentPage > 0 ? currentPage - 1 : 0],
              rowsPerPage[currentPage] * (currentPage + 1)
            )
            .map((p) => buildPolicyValueRow(p, code)),
        ],
      },
      layout: 'lightBorder',
      style: 'table',
      pageBreak: pageAmount !== currentPage + 1 ? 'after' : '',
    })
  })

  return policyValueTables
}

const buildDisclaimer = () => {
  return {
    text: MESSAGES.REMARK_POLICY_TABLE_MRTA,
    style: 'disclaimer',
    margin: [0, 0, -10, 20],
  }
}

const buildPolicyValueTable = (policyValue: PolicyValue, basicPlan: DisplayProduct) => {
  return buildMRTAPolicyValueTable(policyValue, basicPlan)
}

export const policyValueTableSection = ({ policyValue, basicPlan }: PolicyValueTableProps) => [
  { text: MESSAGES.POLICY_VALUE_TABLE, style: 'title', pageBreak: 'before' },
  buildPolicyValueTable(policyValue, basicPlan),
  buildDisclaimer,
]
