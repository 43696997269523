import { compose } from 'recompose'
import styled from 'styled-components'
import { Flex, Box } from 'rebass'
import Card from 'e-submission/components/Card'

const Component = styled(({ title, icon, sections, ...props }) => (
  <Card w={1} column {...props}>
    <Flex align="center">
      <Box flex="auto">
        <strong>{title}</strong>
      </Box>
    </Flex>
  </Card>
))`
  padding: 10px 20px;
  &:hover {
    cursor: not-allowed;
  }
  .arrowRight {
    color: ${({ theme }) => theme.variables['$brand-primary']};
  }
  .icon-left svg {
    max-width: 22px;
    max-height: 30px;
    margin-left: -3px;
    margin-right: 5px;
  }
`

export default (hoc) => compose(hoc)(Component)
