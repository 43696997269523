// @flow

import type { RiskEvaluated, RiskLevel } from 'core/service/investment/risklevel'
import type { Questionnaire } from 'core/service/investment/questionnaire'
import type { SelectProduct } from '../../product-selection/actions'

export const SELECT_RISK_LEVEL = 'SELECT_RISK_LEVEL'

export type Action =
  | SelectRiskLevel
  | SelectQuestionnaire
  | AnswerQuestion
  | CalculateRisk
  | ResetRiskEvaluated
  | ResetAnsweredQuestion
  | SelectProduct

export const RESET_RISK_EVALUATED = 'RESET_RISK_EVALUATED'
export type ResetRiskEvaluated = {
  type: 'RESET_RISK_EVALUATED',
}

export type SelectRiskLevel = {
  type: 'SELECT_RISK_LEVEL',
  payload: RiskLevel,
}

export const selectRiskLevel = (riskLevel: RiskLevel): SelectRiskLevel => ({
  type: SELECT_RISK_LEVEL,
  payload: riskLevel,
})

export const SELECT_QUESTIONNAIRE = 'SELECT_QUESTIONNAIRE'

export type SelectQuestionnaire = {
  type: 'SELECT_QUESTIONNAIRE',
  payload: Questionnaire[],
}

export const selectQuestionnaire = (questionnaires: Questionnaire[]): SelectQuestionnaire => ({
  type: SELECT_QUESTIONNAIRE,
  payload: questionnaires,
})

export const RESET_ANSWERED_QUESTION = 'RESET_ANSWERED_QUESTION'
export type ResetAnsweredQuestion = {
  type: 'RESET_ANSWERED_QUESTION',
}

export const resetAnsweredQuestion = (): ResetAnsweredQuestion => ({
  type: 'RESET_ANSWERED_QUESTION',
})

export const ANSWER_QUESTION = 'ANSWER_QUESTION'

export type AnswerQuestion = {
  type: 'ANSWER_QUESTION',
  payload: { index: number, value: number },
}

export const answerQuestion = (index: number, value: number): AnswerQuestion => ({
  type: ANSWER_QUESTION,
  payload: { index, value },
})

export const CALCULATE_RISK = 'CALCULATE_RISK'

export type CalculateRisk = {
  type: 'CALCULATE_RISK',
  payload: RiskEvaluated,
}

export const calculateRisk = (riskState: RiskEvaluated): CalculateRisk => ({
  type: CALCULATE_RISK,
  payload: riskState,
})

export const resetRiskEvaluated = (): ResetRiskEvaluated => ({
  type: RESET_RISK_EVALUATED,
})

export type AcceptHigherRiskInvestment = {
  type: 'ACCEPT_HIGHER_RISK_INVESTMENT',
}

export const ACCEPT_HIGHER_RISK_INVESTMENT = 'ACCEPT_HIGHER_RISK_INVESTMENT'

export const acceptHigherRiskInvestment = (): AcceptHigherRiskInvestment => ({
  type: ACCEPT_HIGHER_RISK_INVESTMENT,
})

export type AcceptForeignInvestment = {
  type: 'ACCEPT_FOREIGN_INVESTMENT',
}

export const ACCEPT_FOREIGN_INVESTMENT = 'ACCEPT_FOREIGN_INVESTMENT'

export const acceptForeignInvestment = (): AcceptForeignInvestment => ({
  type: ACCEPT_FOREIGN_INVESTMENT,
})

export type SetAgentComment = {
  type: 'SET_AGENT_COMMENT',
  payload: string,
}

export const SET_AGENT_COMMENT = 'SET_AGENT_COMMENT'

export const setAgentComment = (comment: string): SetAgentComment => ({
  type: SET_AGENT_COMMENT,
  payload: comment,
})
