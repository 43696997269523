//@flow
import styled from 'styled-components'
import { Heading } from 'common-components'

type RiskScoreProps = {
  score: number,
}

const Score = styled.span`
  font-size: 3rem;
  line-height: 1rem;
`

const RiskScoreWrapper = styled.div`
  font-size: 0.9rem;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
`

export const RiskScore = ({ score }: RiskScoreProps) => (
  <RiskScoreWrapper>
    <p>คะแนนที่ท่านได้</p> <Score>{score}</Score> คะแนน
  </RiskScoreWrapper>
)

type RiskScoreDescProps = {
  riskLevelLabel: string,
  riskLevelDesc: string,
}

export const RiskScoreDesc = ({ riskLevelLabel, riskLevelDesc }: RiskScoreDescProps) => (
  <div>
    <Heading>ท่านเป็น{riskLevelLabel}</Heading>
    <div>หมายความว่า ท่าน{riskLevelDesc}</div>
  </div>
)
