// @flow

import type { Dispatch } from 'redux'
import { bindActionCreators } from 'redux'
import MobileDetect from 'mobile-detect'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Redirect } from 'react-router-dom'
import { compose, branch, renderComponent, lifecycle } from 'recompose'
import values from 'core/data-model/constants/values'
import { getToggles } from 'quick-quote/feature-toggles'
import { getProceedToInvestmentProduct } from 'quick-quote/product-investment/validate-permission/selectors'
import { getProceedToOrdinaryProduct } from 'quick-quote/product-common/validate-permission/selectors'
import {
  selectProduct,
  selectProductCategory,
  validateInvestmentProduct,
  validateOrdinaryProduct,
  validateNormalProduct,
} from './actions'
import type { AppState } from 'quick-quote/reducers'
import { isFNAPath } from 'quick-quote/components/navigations/utils'
import { BasicPlanSelection } from './components/basic-plan-selection'
import {
  customizeDisplay,
  getDisplayProducts,
  getViewConfiguration,
  getProductCategoryList,
  getProductsInCategory,
  getSelectedDisplayProductCode,
  getSelectedProductCategoryId,
  filterProductsByCategory,
  filterProductCategoryEmpty,
  getAvailableProductCategories,
  getAvailablePlanCode,
} from './selectors'
import { isOnFnaPhase } from '../fna/selectors'
import { getSelectedNeedType } from '../fna/need-and-gap-analysis/selectors'
import { getUserAgentType, isLoggedIn } from 'identity/selectors'
import { getIsUserOnline } from 'quick-quote/selectors'

import { loginRequest } from 'quick-quote/actions'
import { setNotificationMessage } from 'quick-quote/notification/actions'

const mapStateToProps = (state: AppState) => {
  const userAgentType = getUserAgentType(state)
  const isLb = userAgentType === values.AGENT_TYPE_LB
  const mobileDetect = new MobileDetect(window.navigator.userAgent)
  const isDesktop = !mobileDetect.mobile() && !mobileDetect.tablet()
  const displayProducts = getDisplayProducts(state)
  const productsInCategory = getProductsInCategory(getViewConfiguration(state))
  const categoryList = getProductCategoryList(getViewConfiguration(state))
  const productCategories = getToggles().ENABLE_HIDE_EMPTY_TAB_PRODUCT
    ? filterProductCategoryEmpty(displayProducts, categoryList, productsInCategory, userAgentType)
    : categoryList
  const defaultProductCategoryId = getSelectedProductCategoryId(state)
  const selectedProductCategoryId = productCategories.find((category) => category.id === defaultProductCategoryId)
    ? defaultProductCategoryId
    : productCategories[0].id
  const displayCategory = true
  const productList = filterProductsByCategory(
    displayProducts,
    productsInCategory,
    selectedProductCategoryId,
    userAgentType
  )
  const selectedNeedType = getSelectedNeedType(state)
  const availableCategories = getAvailableProductCategories(state)
  const availablePlanCode = getAvailablePlanCode(state)
  const hideHistory = getToggles().ENABLE_FNA_FLOW || (getToggles().DISABLE_HISTORY_FOR_LB && isLb && isDesktop)
  // TODO: [DIGSALTOOL-735] checking if using old config when RPUDR not, remove when RPUDR live.
  // TODO: This is hard coded from old `/core/data/resources/fna-configuration.json` configuration
  const overrideProps = getToggles().ENABLE_FNA_WITH_RPUDR
    ? {}
    : {
        selectedProductCategoryId:
          selectedNeedType === 'SAVING_AND_INVESTMENT' ? 'CATEGORY_LEGACY' : selectedProductCategoryId,
        availableCategories: selectedNeedType === 'SAVING_AND_INVESTMENT' ? ['CATEGORY_LEGACY'] : availableCategories,
        availablePlanCode: selectedNeedType === 'SAVING_AND_INVESTMENT' ? ['SPUL', 'RPUL'] : availablePlanCode,
      }
  const isUserLogin = isLoggedIn(state)
  const isUserOnline = getIsUserOnline(state)

  return {
    displayProducts: customizeDisplay(productList),
    selectedProductCode: getSelectedDisplayProductCode(state),
    displayCategory,
    productCategories,
    selectedProductCategoryId,
    hideHistory,
    allowProceedInvestment: getProceedToInvestmentProduct(state),
    allowProceedOrdinary: getProceedToOrdinaryProduct(state),
    isOnFnaPhase: isOnFnaPhase(state),
    selectedNeedType,
    availableCategories,
    availablePlanCode,
    isUserLogin,
    isUserOnline,
    ...overrideProps,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  selectProduct: bindActionCreators(selectProduct, dispatch),
  selectProductCategory: bindActionCreators(selectProductCategory, dispatch),
  validateInvestmentProduct: bindActionCreators(validateInvestmentProduct, dispatch),
  validateOrdinaryProduct: bindActionCreators(validateOrdinaryProduct, dispatch),
  validateNormalProduct: bindActionCreators(validateNormalProduct, dispatch),
  needLogin: bindActionCreators(loginRequest, dispatch),
  setNotification: bindActionCreators(setNotificationMessage, dispatch),
})

export const BasicPlanSelectionContainer = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    UNSAFE_componentWillMount() {
      if (getToggles().ENABLE_FORCE_LOGIN_FIRST) {
        const { isUserLogin, isUserOnline, needLogin, setNotification } = this.props
        if (!isUserOnline) {
          setNotification({ type: 'Warning', subType: 'NoInternet' })
        } else if (!isUserLogin) {
          needLogin()
        }
      }
    },
  }),
  branch(
    (props) => {
      const { location, selectedNeedType } = props
      const isFNA = isFNAPath(location.pathname)
      return isFNA && !selectedNeedType
    },
    renderComponent(() => <Redirect to="/" push />)
  )
)(BasicPlanSelection)
