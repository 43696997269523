import {
  SET_REMOTE_SELLING_APP_ID,
  SET_REMOTE_SELLING_DATA,
  RESET_REMOTE_SELLING_STATE,
  RESUME_APPMAN_REMOTE_SELLING,
  VIDEO_CONSENTED,
  CONFIRMED_EKYC,
} from './actions'
import { initialRemoteSellingState, mediaActions } from './constants'

const remoteSelling = (state = initialRemoteSellingState, action = { type: '', payload: {} }) => {
  switch (action.type) {
    case mediaActions.TOGGLE_MIC:
      return {
        ...state,
        mediaControl: {
          ...state.mediaControl,
          micState: !state.micState,
        },
      }
    case mediaActions.TOGGLE_CAMERA:
      return {
        ...state,
        mediaControl: {
          ...state.mediaControl,
          cameraState: !state.cameraState,
        },
      }
    case mediaActions.TOGGLE_SCREEN:
      return {
        ...state,
        mediaControl: {
          ...state.mediaControl,
          screenState: !state.screenState,
        },
      }
    case mediaActions.SET_MEDIA_CONTROL_STATE:
      return {
        ...state,
        mediaControl: {
          ...state.mediaControl,
          ...action.payload,
        },
      }
    case mediaActions.RESET_MEDIA_CONTROL_STATE:
      return {
        ...state,
        mediaControl: initialRemoteSellingState.mediaControl,
      }
    case SET_REMOTE_SELLING_APP_ID:
      return {
        ...state,
        appId: action.payload,
      }
    case SET_REMOTE_SELLING_DATA:
      return {
        ...state,
        ...action.payload,
      }
    case RESUME_APPMAN_REMOTE_SELLING: {
      if (action?.payload?.resume) {
        return {
          ...state,
          disableEditing: true,
        }
      }
      return state
    }
    case VIDEO_CONSENTED: {
      return {
        ...state,
        videoConsented: action.payload,
      }
    }
    case CONFIRMED_EKYC: {
      return {
        ...state,
        confirmedEKYC: true,
      }
    }
    case RESET_REMOTE_SELLING_STATE: {
      return initialRemoteSellingState
    }
    default:
      return state
  }
}

export const reducer = remoteSelling
export default reducer
