import { d } from 'core/service/lib/decimal'
import VALUES from 'core/data-model/constants/values'
import { containForeignFund, containOverRiskFund } from 'core/service/investment/funds'
import { isSelectedYesOnForeignExchangeRiskQuestion, requireRADocument } from 'core/service/investment/questionnaire'
import { formatNumber } from 'core/service/lib/number-format'
import { isArray } from 'core/service/lib/type-check'
import {
  DOCUMENT_META,
  DOCUMENT_TYPE,
  RELATIONSHIP_LIST_VALUES,
  bankBeneficiary,
  EDA_CHECK_STATUS_FAIL,
} from 'e-submission/domain/data-model/constants'
import {
  allowToSubmitWithoutPayment,
  getAppStatus,
  getFullNameWithDefault,
  getIsOnOtp,
  getPaidReceipts,
  getPolicyId,
  getProductDisplayFlag,
  getProductsExpiryDate,
  getQuickQuoteAgentAdvisorCode,
  getQuickQuoteAgentFullName,
  getQuickQuoteCoverageSelectedPlan,
  getQuickQuoteInsuredGender,
  getQuickQuoteInsuredMainNatureOfDutyCode,
  getQuickQuoteInsuredMainOccupationCode,
  getQuickQuoteInsuredOtherNatureOfDutyCode,
  getQuickQuoteInsuredOtherOccupationCode,
  getQuickQuoteIsOtherOccupationSelected,
  getQuickQuoteIsPayerOtherOccupationSelected,
  getQuickQuoteModelFactor,
  getQuickQuotePayerGender,
  getQuickQuotePayerMainNatureOfDutyCode,
  getQuickQuotePayerMainOccupationCode,
  getQuickQuotePayerOtherNatureOfDutyCode,
  getQuickQuotePayerOtherOccupationCode,
  getQuickQuoteProductCategory,
  getQuickQuoteProductCode,
  getQuickQuoteProductName,
  getQuickQuoteProductDisplayName,
  getQuickQuoteProductType,
  getQuickQuoteSelectedCampaignPlan,
  getQuickQuoteSelectedRiders,
  getQuickQuoteTotalPremium,
  getQuickQuoteLumpSumTopUp,
  getQuickQuoteHavePayer,
  getSumPremiumPayment as _getSumPremiumPayment,
  isAdditionalDocument,
  isAdditionalDocumentPlaceHolder,
  isCreditCardRelationshipDocument,
  isCreditCardRelationshipDocumentPlaceHolder,
  isAllReceiptCreated,
  isDCASelected,
  isDCASigned,
  isForeigner,
  isJuvenilePurchasing,
  isJuvenileKid,
  isQuickQuoteModelFactorMonthly,
  isRetirementProductCategory,
  isBumnanReadyProductCategory,
  isCashDividend,
  isSelectedToPayNow,
  isShowDCAForm,
  isUndefinedDCA,
  isCreditCardRelationshipTier1,
  isPayerOther,
  isSignedEcbr,
  isFinishCreditCardPaymentSection,
  getCertifyByAgent,
  getQuickQuoteProductBasicPlanCode,
  findS7Product,
  isEkycSkippedAura,
  isSelectedRemoteSelling,
  getInsuredUploadDocStatus,
  getPayerUploadDocStatus,
  isRemoteSelling,
  isCCPAStatusCompleted,
  isCCPAStatusError,
  isPACategory,
} from 'e-submission/domain/data-model/form/selectors'

import validator from 'e-submission/domain/data-model/form/validation'
import getForm from 'e-submission/domain/data-model/product-form'

import { mapProductsConfigToApp, IsRealDevice } from 'e-submission/utils'
import { isEmpty } from 'e-submission/utils/pattern'

import { getIdentityProfile, getSelectedFSE, getSelectedOffice, isBancAgent } from 'identity/selectors'
import _ from 'lodash'
import {
  __,
  concat,
  cond,
  constant,
  defaults,
  defaultsDeep,
  every,
  filter,
  find,
  flatMap,
  flatten,
  flattenDeep,
  flow,
  get,
  getOr,
  groupBy,
  gt,
  has,
  identity,
  includes,
  invokeArgs,
  isEqual,
  isNil,
  isString,
  isUndefined,
  keyBy,
  keys,
  map,
  mapValues,
  merge,
  negate,
  omit,
  omitBy,
  orderBy,
  overEvery,
  overSome,
  pick,
  pickBy,
  reduce,
  reject,
  set,
  size,
  some,
  startsWith,
  stubTrue,
  unset,
  update,
  values,
  compact,
  trim,
} from 'lodash/fp'
import generateUUID from 'uuid/v1'
import { getToggles } from 'quick-quote/feature-toggles'
import { createSelector } from 'reselect'
import { isSelectedToPayLater } from '../domain/data-model/form/selectors'
import { analytics } from 'analytics/analytics'

const concatWith = concat.convert({ rearg: true })
const mapValuesWithKey = mapValues.convert({ cap: false })
const pickByWithKey = pickBy.convert({ cap: false })

export const getQuickQuoteInterface = (state) => state.quickQuoteInterface

export const getOccupations = (state) => state.occupations.payload

export const getSfReference = createSelector(getQuickQuoteInterface, (quickQuote) => quickQuote.sfReference)
export const getStateSaleForm = createSelector(
  getQuickQuoteInterface,
  (quickQuote) => quickQuote.sfReference?.startSaleFrom
)

export const getFirstName = createSelector(getQuickQuoteInterface, (quickQuote) => quickQuote.insured?.firstName?.name)
export const getTitle = createSelector(getQuickQuoteInterface, (quickQuote) => quickQuote.insured?.title?.name)
export const getLastName = createSelector(getQuickQuoteInterface, (quickQuote) => quickQuote.insured?.lastName?.name)

export const getInsuredAge = createSelector(getQuickQuoteInterface, (quickQuote) => quickQuote.insured.age)
export const getProductCategory = createSelector(
  getQuickQuoteInterface,
  (quickQuote) => quickQuote.selectedDisplayProduct.category
)

export const getQQPayerRelation = createSelector(
  getQuickQuoteInterface,
  (quickQuote) => quickQuote.insured.payer.relation
)

export const getErrors = (state) => state.ui.errors
export const getErrorByTypeName = (state, name) => {
  return state.ui.errors[name]
}
export const getFiles = (state) => state.ui.files
export const getPreviewMultipleDoc = (state) => state.previewMultipleDoc
export const getFileWithName = createSelector(
  getFiles,
  (state, name) => name,
  (files, name) => get([name, 'file'], files)
)
export const getFileTypeWithName = createSelector(
  getFiles,
  (state, name) => name,
  (files, name) => get([name, 'fileType'], files)
)
export const getRemoteSellingUploading = (state) => {
  const app = getCurrentApp(state)
  return get('remoteSelling.isUploading', app) === true
}
export const isPDFFileType = (state, name) => getFileTypeWithName(state, name) === 'application/pdf'

export const getReplicationStatus = (state) => state.ui.replicationStatus

export const getDialog = (state) => state.ui.dialog
export const isShowDialog = (state) => !isNil(state.ui.dialog)
export const isHideDialog = (state) => isNil(state.ui.dialog)
export const isLoading = (state) => state.ui.isLoading
export const isEDACheckingStatus = (state) => state.ui.isEDACheckingStatus
export const isEDACheckingStatusError = (state) => state.ui.isEDACheckingStatusFailed === EDA_CHECK_STATUS_FAIL.ERROR
export const isEDACheckingStatusExpired = (state) =>
  state.ui.isEDACheckingStatusFailed === EDA_CHECK_STATUS_FAIL.TOKEN_EXPIRED
export const isConsentEdaComplete = (state) => state.ui.isConsentEdaComplete
export const isEDAViewFormOnly = (state) => state.ui.isEDAViewFormOnly
export const isSubmitting = (state) => state.ui.isSubmitting
export const submittedFlashMessage = (state) => state.ui.submittedFlashMessage
export const getInsuredCommandStatusUploading = (state, name) => {
  const app = getCurrentApp(state)
  const uploadStatus = getInsuredUploadDocStatus(app)
  return get('name', uploadStatus) === name && get('status', uploadStatus) === 'uploading'
}
export const getMotorcycleUsageOption = (state) => {
  const app = getCurrentApp(state)
  return isPACategory(app)
    ? [
        {
          text: 'ขับขี่',
          value: 'yes',
        },
        {
          text: 'ขับขี่แล้วใช้ในอาชีพ',
          value: 'occupation',
        },

        {
          text: 'ไม่ขับขี่',
          value: 'no',
        },
        {
          text: 'อื่นๆ',
          value: 'other',
        },
      ]
    : [
        {
          text: 'ขับขี่',
          value: 'yes',
        },
        {
          text: 'ไม่ขับขี่',
          value: 'no',
        },
      ]
}
export const getPayerCommandStatusUploading = (state, name) => {
  const app = getCurrentApp(state)
  const uploadStatus = getPayerUploadDocStatus(app)
  return get('name', uploadStatus) === name && get('status', uploadStatus) === 'uploading'
}
export const getIsDocumentUploading = (state) => {
  const app = getCurrentApp(state)
  const payer = getPayerUploadDocStatus(app)
  const insured = getInsuredUploadDocStatus(app)
  return get('status', payer) === 'uploading' || get('status', insured) === 'uploading'
}
export const getProductsPayload = (state) => {
  return state.products.payload
}

export const getProductsDisplay = (state) => {
  return state.productDisplays
}

export const appsById = (state) => get('apps.byId', state)
export const currentAppId = (state) => get('apps.currentAppId', state)

export const getCurrentAppId = createSelector(currentAppId, (appId) => appId)

export const getAppWithId = createSelector(
  appsById,
  getCurrentAppId,
  (state, { appId } = {}) => {
    return { appId, state }
  },
  (byId, currentAppId, app) => {
    const productState = app.state
    const appWithId = byId[app.appId] || byId[currentAppId]
    return getToggles().ENABLE_DRAFT_HOUSE_KEEPING && appWithId.insured && appWithId.quickQuote
      ? mapProductsConfigToApp(appWithId, productState)
      : appWithId
  }
)
export const hasCurrentApp = createSelector(
  currentAppId,
  (currentAppId) => !(isNil(currentAppId) || isEmpty(currentAppId))
)
export const getCurrentApp = createSelector([getCurrentAppId, appsById], (appId, byId) => byId[appId])

export const getCurrentAppStatus = createSelector(getCurrentApp, getAppStatus)

export const getCurrentValue = createSelector(
  getCurrentApp,
  (state, fieldId) => fieldId,
  (app, fieldId) => get(fieldId, app)
)

export const getInsuredGender = createSelector(getCurrentApp, getQuickQuoteInsuredGender)
export const getPayerGender = createSelector(getCurrentApp, getQuickQuotePayerGender)
export const havePayer = createSelector(getCurrentApp, flow(getQuickQuoteHavePayer, isEqual(true)))

export const isCertifyByAgent = createSelector(getCurrentApp, getCertifyByAgent)

export const getInsuredMainOccupation = createSelector(getAppWithId, getOccupations, (app, occupations) =>
  find(
    {
      occupationCode: getQuickQuoteInsuredMainOccupationCode(app),
      natureOfDutyCode: getQuickQuoteInsuredMainNatureOfDutyCode(app),
    },
    occupations
  )
)
export const getInsuredOtherOccupation = createSelector(getAppWithId, getOccupations, (app, occupations) =>
  getQuickQuoteIsOtherOccupationSelected(app) === true
    ? find(
        {
          occupationCode: getQuickQuoteInsuredOtherOccupationCode(app),
          natureOfDutyCode: getQuickQuoteInsuredOtherNatureOfDutyCode(app),
        },
        occupations
      )
    : {}
)
export const getPayerMainOccupation = createSelector(getAppWithId, getOccupations, (app, occupations) =>
  find(
    {
      occupationCode: getQuickQuotePayerMainOccupationCode(app),
      natureOfDutyCode: getQuickQuotePayerMainNatureOfDutyCode(app),
    },
    occupations
  )
)
export const getPayerOtherOccupation = createSelector(getAppWithId, getOccupations, (app, occupations) =>
  getQuickQuoteIsPayerOtherOccupationSelected(app) === true
    ? find(
        {
          occupationCode: getQuickQuotePayerOtherOccupationCode(app),
          natureOfDutyCode: getQuickQuotePayerOtherNatureOfDutyCode(app),
        },
        occupations
      )
    : {}
)
export const getDraft = createSelector(
  createSelector(getIdentityProfile, getSelectedFSE, getSelectedOffice, isBancAgent, (profile, fse, office, isBanc) => {
    // Replace fse with LB advisorId to link app to LB
    if (isBanc) {
      fse.advisorId = profile.agent_code
    }

    return omitBy(isNil, {
      ...fse,
      ...office,
      isBanc,
    })
  }),
  getCurrentApp,
  getQuickQuoteInterface,
  (agent, app, quickQuote) => {
    let idCardForSelf = ''
    const isBuyForSelfS7CC = (app, quickQuote) => {
      const policyOwner = _.get(quickQuote, 'insured.maturity.policyOwner')
      const productCode = _.get(quickQuote, 'selectedDisplayProduct.code')
      const isS7CC = findS7Product(productCode)
      return policyOwner === 'SELF' && isS7CC
    }
    let defaultsBeneficiary = {}
    if (_.get(quickQuote, 'selectedDisplayProduct.category') === 'MRTA') {
      defaultsBeneficiary = { beneficiary: [bankBeneficiary] }
    }

    if (isBuyForSelfS7CC(app, quickQuote)) idCardForSelf = _.get(quickQuote, 'insured.maturity.idCard.validIDCard', '')
    return {
      ...app,
      quickQuote: {
        ...omit('fna')(quickQuote),
        agent,
      },
      isRemoteSelling: quickQuote.isRemoteSelling,
      selectedRemoteSelling: quickQuote.selectedRemoteSelling,
      remoteSelling: quickQuote.remoteSelling,
      FNA: omit('fnaPhase')(quickQuote.fna),
      document: {
        certifyByAgent: {
          isCertify: false,
        },
      },
      insured: {
        idNo: {
          citizenId: idCardForSelf,
        },
      },
      ...defaultsBeneficiary,
    }
  }
)

export const getPages = createSelector(getAppWithId, (app) => {
  const category = getQuickQuoteProductCategory(app)
  const productCode = getQuickQuoteProductCode(app)
  return getForm(app, category, productCode)
})

const checkSignature = flow(
  groupBy('name'),
  flatMap(
    reduce(
      ({ signatures }, document) => ({
        ...document,
        signatures: signatures.concat(document.checked),
      }),
      { signatures: [] }
    )
  ),
  map(({ signatures, ...document }) => {
    const isSigned = isDocumentSigned(signatures, document)
    return {
      ...document,
      checked: isSigned,
      isSigned,
    }
  })
)

export const isDocumentSigned = (signatures, document) => {
  /**
   * Some doc are optional to check sign on submit because they might just add that doc after app is signed.
   * So, we will override it to true to let app that signed before new version with new docs can submit normally
   */
  const overrideSignToTrueList = compact([getToggles().ENABLE_CITIZEN_ID_CONSENT_FORM && 'citizen-id-consent'])
  let isSigned
  if (some((docName) => document.name.includes(docName))(overrideSignToTrueList)) {
    isSigned = true
  } else {
    isSigned = every(isEqual(true), signatures)
  }
  return isSigned
}

export const canEditTotalFirstPremiumPayment = (state, key) => flow(getFiles, negate(has(key)))(state)

export const getAllAdditionalDocument = createSelector(
  getFiles,
  flow(
    pickByWithKey((file, name) => isAdditionalDocument(name)),
    mapValuesWithKey((file, name) => ({ ...file, name })),
    values,
    reject(({ name }) => isAdditionalDocumentPlaceHolder(name)),
    orderBy([get('creationdate')], ['asc'])
  )
)

export const getAllCreditCardRelationshipDocument = createSelector(
  getFiles,
  flow(
    pickByWithKey((file, name) => isCreditCardRelationshipDocument(name)),
    mapValuesWithKey((file, name) => ({ ...file, name })),
    values,
    reject(({ name }) => isCreditCardRelationshipDocumentPlaceHolder(name)),
    orderBy([get('creationdate')], ['asc'])
  )
)

const when = (predicate, success) => (value) => (predicate(value) ? success(value) : value)
const traverse = (predicate, map = identity) => {
  let result = []
  return function recusive(obj) {
    obj = map(obj)

    if (predicate(obj)) {
      result.push(obj)
    }
    for (let key in obj) {
      if (obj[key] && typeof obj[key] === 'object') {
        recusive(obj[key])
      }
    }
    return result
  }
}
export const getAllPhoto = createSelector(
  getPages,
  getAppWithId,
  (state) => state,
  (pages, app, state) =>
    flow(
      filter(['id', 'submit']),
      traverse(
        flow(get('c'), isEqual('PhotoCard')),
        flow(
          when(overEvery([has('paymentSteps'), flow(get('selected'), isEqual(false))]), unset('paymentSteps')),
          when(has('p.mergeState'), update('p', invokeArgs('mergeState', [state, app, {}])))
        )
      ),
      flatMap('p.value'),
      map('name'),
      reject(isAdditionalDocument),
      reject(isCreditCardRelationshipDocument)
    )(pages)
)

export const getAllDocument = createSelector(
  getPages,
  getAppWithId,
  (state) => state,
  (pages, app, state) =>
    flow(
      filter(['id', 'signature']),
      flatMap('sections'),
      flatMap('questions'),
      flatMap('elements'),
      filter(['c', 'DocumentCard']),
      map('p'),
      flatMap(({ mergeState }) => {
        const { documents } = mergeState(state, app)
        const isRemoteSelling = isSelectedRemoteSelling(app)
        if (isRemoteSelling) {
          const signedDocs = get('signedDocs', app)
          const ver_doc = {
            description: DOCUMENT_META.VULNERABLE_CUSTOMER.text,
            isSigned: signedDocs?.advisors?.includes(DOCUMENT_META.VULNERABLE_CUSTOMER.name),
            checked: signedDocs?.advisors?.includes(DOCUMENT_META.VULNERABLE_CUSTOMER.name),
            key: 'VULNERABLE_CUSTOMER_agent',
            name: 'vulnerable-customer',
            isRemoteSelling,
          }
          documents.push(ver_doc)
        }
        return documents
      }),
      map(({ checked, key, name, description, isRemoteSelling }) => ({
        checked,
        key,
        name,
        text: description,
        isRemoteSelling,
      })),
      checkSignature
    )(pages)
)

const filterIfRemoteSelling = (documents) => {
  return documents.filter((doc) => !doc?.isRemoteSelling)
}
export const isAllDocumentSigned = createSelector(
  flow(getAllDocument, filterIfRemoteSelling),
  every(flow(get('isSigned'), isEqual(true)))
)

export const isAllPaymentSigned = createSelector(
  getFiles,
  getPages,
  getAppWithId,
  (state) => state,
  (files, pages, app, state) =>
    flow(
      filter(['id', 'submit']),
      flatMap('sections'),
      flatMap('questions'),
      flatMap('elements'),
      filter(flow(get('c'), overSome([isEqual('DocumentCard'), isEqual('PhotoCard'), isEqual('MoreInfoCard')]))),
      map('p'),
      flatMap(({ mergeState, ...props }) => {
        if (mergeState) {
          const { documents, value, checked, paymentOptional } = mergeState(state, app)
          if (!isNil(checked) && !paymentOptional) {
            return checked
          }
          return map('checked', documents || value)
        }
        return true
      }),
      every(isEqual(true))
    )(pages)
)

export const isFinishedPayingFirstPremium = overEvery([isAllPaymentSigned, flow(getCurrentApp, isAllReceiptCreated)])

export const isPayLaterReasonHasBeenFilled = flow(get('submitWithoutPayment.reason.value'), negate(isEmpty))

export const isFinishedPayLaterSelection = overEvery([isPayLaterReasonHasBeenFilled])

export const isFinishedPaymentSection = (state) => {
  const app = getCurrentApp(state)
  if (getToggles().ENABLE_SUBMIT_WITHOUT_PAYMENT && allowToSubmitWithoutPayment(app)) {
    if (isSelectedToPayNow(app)) {
      return isFinishedPayingFirstPremium(state)
    } else if (isSelectedToPayLater(app)) {
      return isFinishedPayLaterSelection(app)
    } else {
      return false
    }
  } else {
    return isFinishedPayingFirstPremium(state)
  }
}

export const isJuvenileSelection = (state) => {
  const app = getCurrentApp(state)
  return isJuvenilePurchasing(app)
}

export const isForeignerSelection = (state) => {
  const app = getCurrentApp(state)
  return isForeigner(app, 'insured')
}

export const getRppAllocations = getOr([], 'quickQuote.funds.rpp.allocations')
export const getLstuAllocations = getOr([], 'quickQuote.funds.lstu.allocations')
export const getRiskQuestionnaire = getOr([], 'quickQuote.investment.investmentRisk.questionnaires')

export const hasLstu = flow(
  get('quickQuote.coveragePlan.selectedBasicPlan.lumpSumTopUp'),
  overEvery(gt(__, 0), negate(isUndefined))
)

export const hasOverRiskFunds = (app) => {
  return containOverRiskFund(getRppAllocations(app)) || containOverRiskFund(getLstuAllocations(app))
}

export const hasForeignFunds = (app) => {
  return containForeignFund(getRppAllocations(app)) || containForeignFund(getLstuAllocations(app))
}

export const isRADocumentNeeded = (state) => {
  return (
    getQuickQuoteProductCategory(getCurrentApp(state)) === VALUES.INVESTMENT &&
    requireRADocument(
      hasOverRiskFunds(getCurrentApp(state)),
      hasForeignFunds(getCurrentApp(state)),
      isSelectedYesOnForeignExchangeRiskQuestion(getRiskQuestionnaire(getCurrentApp(state)))
    )
  )
}

export const isUseDCA = (app) => {
  return isRetirementProductCategory(app) || isBumnanReadyProductCategory(app) || isCashDividend(app)
}

export const isDCAFinished = flow(
  getCurrentApp,
  overSome([
    flow(isUseDCA, isEqual(false)),
    overEvery([isDCASelected, isDCASigned]),
    overEvery([isShowDCAForm, isDCASigned]),
    overEvery([flow(isUndefinedDCA, isEqual(false)), flow(isDCASelected, isEqual(false))]),
  ])
)

export const isCreditCardRelationshipDocumentNotEmpty = (state) => {
  const app = getCurrentApp(state)
  const creditCardRelationshipDocs = getAllCreditCardRelationship(app)
  return isArray(creditCardRelationshipDocs) && creditCardRelationshipDocs.length > 0
}

export const isCheckCreditCardRelationship = (state) => {
  const app = getCurrentApp(state)
  if (getToggles().ENABLE_ECBR_SYSTEMIZATION && isPayerOther(app) && isSignedEcbr(app)) {
    return isCreditCardRelationshipDocumentNotEmpty(state)
  } else if (
    getToggles().ENABLE_CREDIT_CARD_RELATIONSHIP &&
    isCreditCardRelationshipTier1(app) &&
    isFinishCreditCardPaymentSection(app)
  ) {
    return isCreditCardRelationshipDocumentNotEmpty(state)
  } else {
    return true
  }
}

export const canSubmit = overEvery([
  isCheckCreditCardRelationship,
  isAllDocumentSigned,
  isFinishedPaymentSection,
  isDCAFinished,
])

export const canSubmitWithPayment = overEvery([isAllDocumentSigned, isAllPaymentSigned])

export const canSubmitWithoutPayment = overEvery([isAllDocumentSigned])

export const isSectionValid = (state, sections) => (app) =>
  flow(
    flatMap('questions'),
    flatMap('elements'),
    overEvery([
      flow(
        filter(flow(get('c'), overSome([isEqual('DocumentCard'), isEqual('PhotoCard')]))),
        map('p'),
        flatMap(({ mergeState }) => {
          const { documents, value } = mergeState(state, app)
          return documents || value
        }),
        checkSignature,
        overEvery([every(flow(get('checked'), isEqual(true))), every(flow(get('isSigned'), isEqual(true)))])
      ),
      every(({ p, required, id }) => {
        const mergeState = getOr(() => {}, 'mergeState', p)
        const value = getOr(get('value', mergeState(state, app)), id, app)
        const messages = flatten(
          values(
            validator(id).getValidationMessages({
              state,
              app,
              required,
              value,
            })
          )
        )
        return isEmpty(messages)
      }),
    ])
  )(sections)

export const getAllAnswer = createSelector(getPages, getAppWithId, (pages, app) =>
  flow(
    flatMap('sections'),
    flatMap('questions'),
    flatMap('elements'),
    reduce((obj, { id } = {}) => {
      //TODO: check if this default to empty obj cause any problem
      const value = get(id, app)
      if (isNil(value)) {
        return obj
      }
      return set(id, value, obj)
    }, {}),
    defaultsDeep(pick(['insured.isPayer'], app)),
    defaultsDeep(pick(['payer'], app)),
    defaults(app)
  )(pages)
)

export const getAllRequiredField = createSelector(
  getPages,
  flatMap(({ sections, id }) =>
    flow(
      flatMap('questions'),
      flatMap('elements'),
      filter(['required', true]),
      map((element) => ({
        element,
        fieldId: element.id,
        pageId: id,
      })),
      reject(({ fieldId }) => isNil(fieldId))
    )(sections)
  )
)

const flatMapWithKey = flatMap.convert({ cap: false })
export const isInvalidFNA = createSelector(
  getAppWithId,
  (state) => state,
  (app, state) => {
    return getToggles().ENABLE_FNA_FLOW
      ? app.quickQuote.selectedDisplayProduct.category === VALUES.MRTA
        ? false
        : !app.FNA.needGap.type
      : false
  }
)

export const isInvalidVulnerableCustomer = createSelector(getAppWithId, (app) => {
  return getToggles().ENABLE_FNA_PHASE2
    ? app.quickQuote.selectedDisplayProduct.category === VALUES.MRTA
      ? false
      : isEmpty(app.FNA.vulnerableQuestions)
    : false
})

export const getAllInvalidField = createSelector(
  getPages,
  getAppWithId,
  (state) => state,
  (pages, app, state) =>
    flow(
      keyBy('id'),
      omit(['submit', 'signature', 'billing-method']),
      mapValues(({ sections, title }) => ({
        label: title,
        sections: flow(
          flatMap(({ id, questions, heading, label }) => ({
            id,
            label,
            heading: get('heading', find(['id', id], sections)),
            fields: flatMapWithKey(({ elements = [] }, index) =>
              reduce(
                (list, { id, label: fieldLabel, invalidLabel, children, required, p, c }) => {
                  const mergeState = getOr(() => {}, 'mergeState', p)
                  const value = getOr(get('value', mergeState(state, app)), id, app)
                  const messages = flatten(
                    values(
                      validator(id).getValidationMessages({
                        state,
                        app,
                        required,
                        value,
                      })
                    )
                  )
                  const isInvalid = !isEmpty(messages)
                  if (isInvalid) {
                    if (c === 'Checkbox') {
                      return list.concat({
                        id,
                        label:
                          invalidLabel ||
                          fieldLabel ||
                          (isString(children) ? children : '') ||
                          get('[0].title', elements) ||
                          get(`[${index - 1}].elements[0].p.children`, questions),
                        messages,
                      })
                    } else {
                      return list.concat({
                        id,
                        label:
                          invalidLabel ||
                          fieldLabel ||
                          (isString(children) ? children : '') ||
                          get('[0].label', elements) ||
                          get(`[${index - 1}].elements[0].p.children`, questions),
                        messages,
                      })
                    }
                  }
                  return list
                },
                [],
                elements
              )
            )(questions),
          })),
          reject(({ fields }) => isEmpty(fields))
        )(sections),
      })),
      omitBy(({ sections }) => isEmpty(sections))
    )(pages)
)

export const getAuraInvalidField = createSelector(
  getPages,
  getAppWithId,
  (state) => state,
  (pages, app, state) =>
    flow(
      keyBy('id'),
      omit(['aura-health', 'ekyc', 'document', 'submit', 'signature', 'billing-method']),
      mapValues(({ sections, title }) => ({
        label: title,
        sections: flow(
          flatMap(({ id, questions, heading, label }) => ({
            id,
            label,
            heading: get('heading', find(['id', id], sections)),
            fields: flatMapWithKey(({ elements = [] }, index) =>
              reduce(
                (list, { id, label: fieldLabel, invalidLabel, children, required, p }) => {
                  const mergeState = getOr(() => {}, 'mergeState', p)
                  const value = getOr(get('value', mergeState(state, app)), id, app)

                  const messages = flatten(
                    values(
                      validator(id).getValidationMessages({
                        state,
                        app,
                        required,
                        value,
                      })
                    )
                  )
                  const isInvalid = !isEmpty(messages)
                  const skippedEkyc = isEkycSkippedAura(id)

                  if (isInvalid) {
                    if (!skippedEkyc && getToggles().ENABLE_EKYC_F2F) {
                      return list.concat({
                        id,
                        label:
                          invalidLabel ||
                          fieldLabel ||
                          (isString(children) ? children : '') ||
                          get('[0].label', elements) ||
                          get(`[${index - 1}].elements[0].p.children`, questions),
                        messages,
                      })
                    }
                  }

                  return list
                },
                [],
                elements
              )
            )(questions),
          })),
          reject(({ fields }) => isEmpty(fields))
        )(sections),
      })),
      omitBy(({ sections }) => isEmpty(sections))
    )(pages)
)

const getAllFile = createSelector(
  getAllRequiredField,
  getAppWithId,
  (state) => state,
  (fields, app, state) =>
    flow(
      filter(['pageId', 'document']),
      map('element'),
      reduce((obj, { id, p }) => {
        const isUploaded = every(['checked', true])
        const value = flow(invokeArgs('mergeState', [state, app]), get('value'))(p)

        if (isUploaded(value)) {
          return set(id, value, obj)
        }

        return obj
      }, {})
    )(fields)
)

export const getAppProgress = createSelector(getAppWithId, getAllRequiredField, getAllFile, (app, form, files) => {
  const current = flow(
    pickBy(({ fieldId }) => {
      const value = get(fieldId, merge(app, files))
      return !isEmpty(value)
    }),
    values,
    size
  )(form)
  const length = flow(values, size)(form)

  return Math.floor((current / length) * 100)
})

export const getData = (state) => {
  return state.data
}
export const getProvinceList = createSelector(getData, get('province'))
export const getAmphurList = createSelector(
  getData,
  (state, address) => getCurrentValue(state, address),
  (data, address) => flow(get('amphur'), filter(['province_code', get('province.value', address)]))(data)
)
export const getTambolList = createSelector(
  getData,
  (state, address) => getCurrentValue(state, address),
  (data, address) =>
    flow(
      get('tambol'),
      filter(['province_code', get('province.value', address)]),
      filter(['amphur_code', get('amphur.value', address)])
    )(data)
)
export const getPostcodeList = createSelector(
  getData,
  (state, address) => getCurrentValue(state, address),
  (data, address) => {
    const filterTambol = flow(
      get('tambol'),
      filter(['province_code', get('province.value', address)]),
      filter(['amphur_code', get('amphur.value', address)]),
      filter(['value', get('tambol.value', address)])
    )(data)
    const postCodeList = filterTambol[0]?.postal.map((item) => ({ text: String(item), value: item }))
    return postCodeList
  }
)
export const getPersonalNationalityList = createSelector(getData, get('personalNationality'))

export const getDividendList = createSelector(getData, get('dividend'))
export const getCountryList = createSelector(getData, get('country'))
export const getPersonalDocumentTypeList = createSelector(
  getData,
  (state, nationalityCode) => getCurrentValue(state, nationalityCode),
  (data, nationalityCode = 'Thai') =>
    flow(
      get('personalDocumentType'),
      filter((item) => {
        if (nationalityCode !== 'Thai') {
          return get('value', item) === 'PASSPORT'
        } else {
          return get('value', item) !== 'PASSPORT'
        }
      })
    )(data)
)

export const getPersonalDocumentTypeListEkyc = createSelector(
  getData,
  (state, nationalityCode, age, insuredOrPayer) => {
    const reCalAge = getCurrentValue(state, age)
    const remoteSelling = isSelectedRemoteSelling(getCurrentApp(state))
    return {
      remoteSelling,
      insuredOrPayer,
      age: reCalAge && reCalAge.unit === 'year' ? reCalAge.value : 0,
      nationalityCode: getCurrentValue(state, nationalityCode),
    }
  },
  (data, { nationalityCode = 'Thai', age = 0, insuredOrPayer, remoteSelling }) =>
    flow(
      get('personalDocumentType'),
      filter((item) => {
        if (nationalityCode !== 'Thai') {
          return get('value', item) === 'PASSPORT'
        } else {
          if (insuredOrPayer === 'insured') {
            return remoteSelling && age >= 7
              ? get('value', item) === 'CITIZEN_ID'
              : age >= 20
              ? get('value', item) === 'CITIZEN_ID'
              : age < 7
              ? get('value', item) === 'GOV_ID'
              : get('value', item) !== 'PASSPORT'
          } else {
            return get('value', item) === 'CITIZEN_ID'
          }
        }
      })
    )(data)
)

export const getDefaultPersonalTitleList = flow(getData, get('personalTitle.default'))
export const getKidPersonalTitleList = flow(getData, get('personalTitle.kid'))
export const getSiblingPersonalTitleList = flow(getData, get('personalTitle.sibling'))
export const getIsDataEkycConfirmed = createSelector(getCurrentApp, (currentApp) => {
  return getToggles().ENABLE_EKYC_F2F && _.get(currentApp, 'ekycInfo.isDataConfirmed')
})
export const getSelectedPersonalTitleList = createSelector(
  createSelector(
    getCurrentApp,
    getDefaultPersonalTitleList,
    getKidPersonalTitleList,
    (state, insuredOrPayer) => insuredOrPayer,
    (app, personalList, kidList, insuredOrPayer) =>
      insuredOrPayer === 'insured' && isJuvenileKid(app) ? kidList : personalList
  ),
  (state, insuredOrPayer) => (insuredOrPayer === 'insured' ? getInsuredGender(state) : getPayerGender(state)),
  (personalTitleList, selectedGender) =>
    filter(({ gender }) => _.isEmpty(gender) || gender === selectedGender, personalTitleList)
)

export const getDefaultPersonalTitleListEN = flow(getData, get('personalTitleEN.default'))
export const getKidPersonalTitleListEN = flow(getData, get('personalTitleEN.kid'))
export const getSelectedPersonalTitleListEN = createSelector(
  createSelector(
    getCurrentApp,
    getDefaultPersonalTitleListEN,
    getKidPersonalTitleListEN,
    (state, insuredOrPayer) => insuredOrPayer,
    (app, personalList, kidList, insuredOrPayer) =>
      insuredOrPayer === 'insured' && isJuvenileKid(app) ? kidList : personalList
  ),
  (state, insuredOrPayer) => (insuredOrPayer === 'insured' ? getInsuredGender(state) : getPayerGender(state)),
  (personalTitleList, selectedGender) =>
    filter(({ gender }) => _.isEmpty(gender) || gender === selectedGender, personalTitleList)
)

export const getPolicyCurrentApp = createSelector(getCurrentApp, (currentApp) => currentApp.policyNo)

export const getRelationshipList = createSelector(getData, get('relationship'))

const getRelationshipWithFilterList = (relationshipList = [], valueList = []) => {
  return relationshipList
    .filter((r) => valueList.includes(r.value))
    .sort((firstItem, secondItem) => valueList.indexOf(firstItem.value) - valueList.indexOf(secondItem.value))
}

export const getRelationshipListForBeneficiary = createSelector(getRelationshipList, (relationshipList) => {
  return relationshipList.filter((r) => r.value !== 'SELF')
})

export const getRelationshipListForATP = createSelector(getRelationshipList, (relationshipList) =>
  getRelationshipWithFilterList(relationshipList, RELATIONSHIP_LIST_VALUES.ATP)
)

export const getRelationshipListForNewATP = createSelector(getRelationshipList, (relationshipList) => {
  const newRelationshipListWithPayer = relationshipList.concat([{ text: 'ผู้ชำระเบี้ย', value: 'PAYER' }])
  return getRelationshipWithFilterList(newRelationshipListWithPayer, RELATIONSHIP_LIST_VALUES.NEW_ATP)
})

export const getRelationshipListForCCPA = createSelector(getRelationshipList, (relationshipList) =>
  getRelationshipWithFilterList(relationshipList, RELATIONSHIP_LIST_VALUES.CCPA)
)

export const getRelationshipListForPayer = createSelector(
  getRelationshipList,
  filter((o) => includes(get('value')(o))(['COUSIN', 'EMPLOYER', 'GUARDIAN', 'OTHERS', 'PARENT']))
)

export const getRelationshipListForEcbr = createSelector(getRelationshipList, (relationshipList) =>
  getRelationshipWithFilterList(relationshipList, RELATIONSHIP_LIST_VALUES.ECBR)
)

export const getRelationshipSpouse = createSelector(
  getRelationshipList,
  filter((o) => includes(get('value')(o))(['SPOUSE']))
)

const includesIn = includes.convert({ rearg: false })
export const getRelationshipListForFamily = createSelector(
  getRelationshipList,
  filter(flow(get('value'), includesIn(['SPOUSE', 'PARENT', 'CHILD', 'GRANDPARE', 'BROTHER', 'SISTER'])))
)

export const getBankList = flow(getData, get('bank'))
export const getBankListForNetbank = flow(getData, get('netbank'))

export const getATPBankList = flow(
  getBankList,
  reject(({ monthlyCollect }) => isNil(monthlyCollect)),
  map(({ text, code, monthlyCollect }) => ({
    text,
    monthlyCollect,
    value: code,
  }))
)

export const getDCABankList = flow(
  getBankList,
  filter(({ code }) => code === '006' || code === '014' || code === '002' || code === '025' || code === '004'),
  map(({ text, code, monthlyCollect }) => ({
    text,
    monthlyCollect,
    value: code,
  }))
)

export const getMonthsCollectBankList = createSelector(
  getATPBankList,
  filter(({ monthlyCollect }) => monthlyCollect > 1)
)

export const getSelectedBank = createSelector(getCurrentApp, getATPBankList, (app, bankList) => {
  const selectedBank = get('payment.bank', app)
  return find(selectedBank, bankList)
})

export const getCurrentBeneficiary = createSelector(getCurrentApp, getOr([], 'beneficiary'))

export const getAppList = createSelector(
  createSelector(appsById, values),
  flow(map(defaults({ status: 'drafted' })), groupBy('status'))
)

const documentNameList = flow(values, map(values), flattenDeep)(DOCUMENT_TYPE)
export const getAttachmentName = (attachmentName, additionalDocuments) => {
  if (isAdditionalDocument(attachmentName)) {
    return flow(get(attachmentName), get('description'))(additionalDocuments)
  }

  return flow(
    find(({ name }) => startsWith(name, attachmentName)),
    get('text')
  )(documentNameList)
}

export const getAllAttachment = (app) =>
  flow(
    get('_attachments'),
    keys,
    reject(isAdditionalDocument),
    reject(isCreditCardRelationshipDocument),
    reject(isEqual('bi')),
    map((name) => ({ name })),
    concatWith(getOr([], 'documents', app)),
    map(({ name: attachmentName, text }) => {
      if (isNil(text)) {
        return {
          name: attachmentName,
          text: flow(
            find(({ name: documentName }) => startsWith(documentName, attachmentName)),
            get('text')
          )(documentNameList),
        }
      }
      return {
        name: attachmentName,
        text: text,
      }
    })
  )(app)

export const getAllCreditCardRelationship = (app) =>
  flow(get('_attachments'), keys, filter(isCreditCardRelationshipDocument))(app)

export const getAppInformation = createSelector(getAppWithId, get('modelFactors.payload'), (app, modelFactors) => {
  const productCode = getQuickQuoteProductCode(app)
  return {
    createdAt: get('createdAt', app),
    lastModified: get('updatedAt', app),
    policyId: getPolicyId(app),
    status: getOr('drafted', 'status')(app),
    mobileNumber: get('insured.mobileNumber')(app),
    name: getFullNameWithDefault('insured')(app),
    percent: get('progress', app),
    productType: getQuickQuoteProductType(app),
    productName: VALUES.LIST_USE_DISPLAY_NAME_ON_ESUB.includes(productCode)
      ? getQuickQuoteProductDisplayName(app)
      : getQuickQuoteProductName(app),
    productCode: productCode,
    productExpiryDate: getProductsExpiryDate(app),
    isProductDisplay: app && app.product && app.product.length > 0 ? getProductDisplayFlag(app) : false,
    riders: getQuickQuoteSelectedRiders(app),
    totalPremium: getQuickQuoteTotalPremium(app),
    lumpSumTopUp: getQuickQuoteLumpSumTopUp(app),
    modelFactor: find({ id: getQuickQuoteModelFactor(app) })(modelFactors),
    isMonthlyPayment: isQuickQuoteModelFactorMonthly(app),
    isOnOtp: getIsOnOtp(app),
    category: getQuickQuoteProductCategory(app),
    selectedCampaignPlan: getQuickQuoteSelectedCampaignPlan(app),
    coverageSelectedPlan: getQuickQuoteCoverageSelectedPlan(app),
    autoSubmission: getOr(false, 'autoSubmission')(app),
    policy: {
      receipts: getPaidReceipts(app),
      agent: `${getQuickQuoteAgentAdvisorCode(app) || '-'} ${getQuickQuoteAgentFullName(app) || '-'}`,
      documents: getAllAttachment(app),
    },
  }
})

export const prepareRiderInformation = (
  { riders, category, selectedCampaignPlan, coverageSelectedPlan },
  ridersPayload
) => {
  if (category === VALUES.PA) {
    return []
  }
  if (category === VALUES.HEALTH) {
    const mehFromPayload = find(({ code }) => code === VALUES.MEH, ridersPayload)
    const mehRider = find(({ code }) => code === VALUES.MEH, riders)
    const plan = get('selectedPlan.plan', mehRider)
    const areaOfCover = get('selectedPlan.areaOfCover', mehRider)
    const areaOfCoverText = get(`areasOfCover.${areaOfCover}.text`, mehFromPayload)
    const remainRiders = filter(({ code }) => code !== VALUES.MEH, riders)

    return [{ name: plan }, { name: areaOfCoverText }, ...remainRiders]
  }
  if (category === VALUES.FLEXI_HEALTH) {
    const fhFromPayload = find(({ code }) => code === VALUES.FH, ridersPayload)
    const fhRider = find(({ code }) => code === VALUES.FH, riders)
    const fhModules = flow(
      filter((riderModule) => riderModule.isSelected),
      map((riderModule) => {
        return { name: riderModule.code }
      })
    )(get('selectedPlan.modules', fhRider))
    const plan = get('selectedPlan.plan', fhRider)
    const areaOfCover = get('selectedPlan.areaOfCover', fhRider)
    const areaOfCoverText = get(`areasOfCover.${areaOfCover}.text`, fhFromPayload)
    const remainRiders = filter(({ code }) => code !== VALUES.FH, riders)

    return [{ name: plan }, { name: areaOfCoverText }, ...fhModules, ...remainRiders]
  }
  if (category === VALUES.PROTECTION) {
    const esciRider = find(({ code }) => code === VALUES.ESCI, riders)
    const plan = `แผน ${formatNumber(get('sumAssured', esciRider))}`
    return [{ name: plan }]
  }

  if (category === VALUES.PERFECTLIFE) {
    const plan = _.get(VALUES.PERFECT_LIFE_PLAN, selectedCampaignPlan)
    return [{ name: plan }]
  }

  if (category === VALUES.HEALTH_TOPPING) {
    const plan = _.get(VALUES.HEALTH_TOPPING_PLAN, coverageSelectedPlan, 'แผน เอ')
    return [{ name: plan }]
  }

  if (category === VALUES.IHEALTHY_ULTRA) {
    const mhpFromPayload = find(({ code }) => code === VALUES.MHP, ridersPayload)
    const mhpRider = find(({ code }) => code === VALUES.MHP, riders)
    const plan = get('selectedPlan.plan', mhpRider)
    const areaOfCover = get('selectedPlan.areaOfCover', mhpRider)
    const areaOfCoverText = get(`areasOfCover.${areaOfCover}.text`, mhpFromPayload)
    const remainRiders = filter(({ code }) => code !== VALUES.MHP, riders)

    return [{ name: plan }, { name: areaOfCoverText }, ...remainRiders]
  }

  return riders
}

export const prepareSpecificRiderInformation = (appInformation, ridersPayload) => {
  if (
    get('productType', appInformation) === VALUES.CAMPAIGN_PRODUCT_TYPE &&
    get('category', appInformation) !== VALUES.PROTECTION &&
    get('category', appInformation) !== VALUES.PERFECTLIFE &&
    get('category', appInformation) !== VALUES.HEALTH_TOPPING &&
    get('category', appInformation) !== VALUES.IHEALTHY_ULTRA &&
    get('category', appInformation) !== VALUES.LIFE_READY &&
    get('category', appInformation) !== VALUES.BUMNAN_READY
  ) {
    return []
  }
  if (get('category', appInformation) === VALUES.PA) {
    return []
  }
  return prepareRiderInformation(appInformation, ridersPayload)
}

export const getPreparedAppInformation = createSelector(
  getAppInformation,
  get('riders.payload'),
  (appInformation, ridersPayload) => ({
    ...appInformation,
    riders: prepareRiderInformation(appInformation, ridersPayload),
  })
)

export const getQuickQuoteInsuredInformation = createSelector(
  getAppInformation,
  get('riders.payload'),
  (appInformation, ridersPayload) => ({
    ...appInformation,
    riders: prepareSpecificRiderInformation(appInformation, ridersPayload),
  })
)

export const isAddendumDocumentNeeded = createSelector(getCurrentApp, getAllRequiredField, (app, fields) => {
  const getHealthAnswer = (app) =>
    flow(
      filter(flow(get('pageId'), overSome([isEqual('insured-health'), isEqual('owner-health')]))),
      map(({ fieldId }) => ({ fieldId, value: get(fieldId, app) }))
    )(fields)
  const questionListNotToShow = [
    null,
    undefined,
    '',
    'อายุ',
    'สัญชาติ:',
    'ความสัมพันธ์ระหว่างเจ้าของกรมธรรม์กับผู้เอาประกัน',
    'ท่านใช้รถจักรยานยนต์ในการทำงานหรือไม่',
    'อาชีพประจำ/ตำแหน่ง',
    'ลักษณะงานที่ทำ',
    'รายได้ต่อปี (บาท)',
    'สถานที่ทํางาน',
    'ส่วนสูง',
    'น้ำหนัก',
    '(sum(0,{rel:CoverageAmountMedLife},{rel:CoverageAmountMedCI})):0',
    'ภาคแอกซ่า:',
    'สถานที่ทำงาน',
    '((convert{rel:WeightChangeAndUnit}/(convert{rel:WeightAndUnit}+convert{rel:WeightChangeAndUnit})*100)):2',
    '((convert{rel:WeightChangeAndUnit}/(convert{rel:WeightAndUnit}-convert{rel:WeightChangeAndUnit})*100)):2',
    'อาชีพอื่นๆ(ถ้ามี)',
    'ผู้ขอเอาประกันภัยมีสัญชาติ ประเทศที่เกิด ที่เกี่ยวข้องกับประเทศสหรัฐอเมริกาหรือไม่-โปรดระบุ',
    'ผู้ขอเอาประกันภัยเป็น หรือเคยเป็นผู้ถือบัตรประจำตัวผู้มีถิ่นที่อยู่ถาวรอย่างถูกกฎหมาย ในประเทศสหรัฐอเมริกา (Green card)  หรือไม่',
    'ผู้ขอเอาประกันภัยมีสัญชาติ ประเทศที่เกิด ที่เกี่ยวข้องกับประเทศสหรัฐอเมริกาหรือไม่-โปรดระบุ',
    'ผู้ขอเอาประกันภัยมีหน้าที่เสียภาษี ให้แก่กรมสรรพากรสหรัฐอเมริกาหรือไม่',
    'ผู้ขอเอาประกันภัยมีสถานะเป็นผู้มีถิ่นที่อยู่ ในประเทศสหรัฐอเมริกา เพื่อวัตถุประสงค์ในการเก็บภาษีอากรของประเทศสหรัฐอเมริกา ใช่หรือไม่ (เช่น มีถิ่นที่อยู่ในประเทศสหรัฐอเมริกา อย่างน้อย 183 วันในปีปฏิทินที่ผ่านมา)',
    'สำหรับผู้ขอเอาประกันภัยและผู้ชำระเบี้ยประกันภัย (กรณีต้องการแจ้งรายละเอียดเพิ่มเติม)',
    'ผู้ขอเอาประกันภัยมีสัญชาติ ประเทศที่เกิด ที่เกี่ยวข้องกับประเทศสหรัฐอเมริกาหรือไม่',
    'โปรดระบุ',
    'ค่าความดันโลหิตที่วัดได้เท่าไหร่',
    '({rel:Systolic}-{rel:Diastolic}):2',
    getToggles().ENABLE_ADDENDUM_WEIGHTCHANGE ? '' : 'ใน 6เดือนที่ผ่านมา น้ำหนักตัวของท่านเปลี่ยนแปลงหรือไม่',
  ]
  const answerListNotToShow = [
    null,
    undefined,
    '',
    'ไม่ใช่',
    'ไม่เปลี่ยนแปลง',
    'ไม่เป็น',
    'ไม่เคยตั้งครรภ์',
    'NO_ANSWER_CHECKED',
    '-',
    'No Answer',
    'NO_ANSWER',
    '0',
    'ผู้เอาประกันเป็นบุตรบุญธรรม',
  ]

  return overSome([
    flow(get('insured.hasOtherPolicy.value'), isEqual(true)),
    flow(getHealthAnswer, some({ fieldId: 'insured.mental.checked', value: { value: 'no' } })),
    flow(
      getHealthAnswer,
      filter(
        ({ fieldId }) =>
          fieldId !== 'insured.mental.checked' &&
          fieldId !== 'insured.treatmentS7.a.checked' &&
          fieldId !== 'insured.treatmentS7.b.checked' &&
          fieldId !== 'insured.treatmentS7.c.checked' &&
          fieldId !== 'insured.treatmentS7.d.checked' &&
          fieldId !== 'insured.treatmentS7.e.checked' &&
          fieldId !== 'insured.haveDiagnosisS7.checked' &&
          fieldId !== 'insured.relativeHealthCheckS7.checked' &&
          fieldId !== 'insured.otherInsuranceCompany.checked' &&
          fieldId !== 'insured.wasRejected.checked'
      ),
      some({ value: { value: 'yes' } })
    ),
    flow(get('quickQuote.funds.allocations'), size, (v) => gt(v, 12)), // TODO Remove this. [Backward Comp.] funds.allocations will be no longer used
    flow(get('quickQuote.funds.rpp.allocations'), size, (v) => gt(v, 12)),
    flow(get('quickQuote.funds.lstu.allocations'), size, (v) => gt(v, 12)),
    flow(get('insured.aura.auraJson'), (auraQ) => {
      let returnResult = false
      if (!_.isEmpty(auraQ) && auraQ.length > 0) {
        if (!returnResult) {
          auraQ = auraQ.filter((auraQar) => {
            return questionListNotToShow.indexOf(_.get(auraQar, 'question')) < 0
          })
          const result = auraQ.filter((auraQar) => answerListNotToShow.indexOf(_.get(auraQar, 'answer')) < 0)
          returnResult = result.length > 0
        }
      }
      return returnResult
    }),
    flow(get('insured.aura'), (aura) => {
      let returnResult = false
      const auraQ = _.get(aura, 'auraJson')
      const bmiValue = _.get(aura, 'insuredBMI')
      if (!_.isEmpty(bmiValue) && bmiValue.length > 0) {
        returnResult = getToggles().ENABLE_ADDENDUM_BMI_UNDERWEIGHT ? _isBMIUnderweight(auraQ, bmiValue) : false
      }
      return returnResult
    }),
    flow(get('payer.aura.auraJson'), (auraQ) => {
      let returnResult = false
      if (!_.isEmpty(auraQ) && auraQ.length > 0) {
        if (!returnResult) {
          auraQ = auraQ.filter((auraQar) => {
            return questionListNotToShow.indexOf(_.get(auraQar, 'question')) < 0
          })
          const result = auraQ.filter((auraQar) => answerListNotToShow.indexOf(_.get(auraQar, 'answer')) < 0)
          returnResult = result.length > 0
        }
      }
      return returnResult
    }),
    overEvery([
      flow(get('quickQuote.selectedDisplayProduct.code'), includes(VALUES.PRODUCT_GIO)),
      flow(get('beneficiary'), size, (v) => gt(v, 3)),
    ]),
    overEvery([
      flow(get('quickQuote.selectedDisplayProduct.category'), isEqual(VALUES.MRTA)),
      flow(get('beneficiary'), size, (v) => gt(v, 3)),
    ]),
    overEvery([
      flow(get('quickQuote.selectedDisplayProduct.category'), isEqual(VALUES.PA)),
      flow(get('insured.otherInsuranceCompany.checked.value'), isEqual('yes')),
      flow(get('insured.otherInsuranceCompany.detail'), size, (v) => gt(v, 30)),
    ]),
    overEvery([
      flow(get('quickQuote.selectedDisplayProduct.category'), negate(isEqual(VALUES.PA))),
      flow(
        getHealthAnswer,
        filter(({ fieldId }) => fieldId === 'insured.wasRejected.checked'),
        some({ value: { value: 'yes' } })
      ),
    ]),

    // overEvery([
    //   flow(get('quickQuote.selectedDisplayProduct.category'), isEqual(VALUES.MRTA)),
    //   flow(get('insured.currentAddress.numberMoobanArkan'), size, (v) => gt(v, 14)),
    // ]),
  ])(app)
})

const _isBMIUnderweight = (auraQ, bmiValue) => {
  return (
    bmiValue >= VALUES.MIN_OF_BMI_UNDERWEIGHT &&
    bmiValue <= VALUES.MAX_OF_BMI_UNDERWEIGHT &&
    _hasAuraBMIUnderweightSubQuestion(auraQ)
  )
}

const _hasAuraBMIUnderweightSubQuestion = (auraQ) => {
  const weightQuestion = auraQ.filter((data) => {
    return data.question === 'น้ำหนัก'
  })
  return !_.isUndefined(weightQuestion[0].sub_questions) ? weightQuestion[0].sub_questions.length > 0 : false
}

// TODO: To be removed after launched, no longer need to check for backward compatibily
export const isEPolicyConsentDocumentNeeded = createSelector(getCurrentApp, (app) => {
  const isNotInvestmentCategory = getQuickQuoteProductCategory(app) !== VALUES.INVESTMENT
  const isEPolicyInvestmentNeeded = getToggles().DISABLE_EPOLICY_FOR_INVESTMENT ? isNotInvestmentCategory : true
  const productNotRequireRequireEPolicyConsent = VALUES.S7_3PAY.concat(VALUES.S7_SINGLE)
  const isRequireEPolicy = !productNotRequireRequireEPolicyConsent.includes(getQuickQuoteProductBasicPlanCode(app))
  return (
    get('insured.isPolicyDeliveryByEmail.value', app) === true &&
    getToggles().ENABLE_E_POLICY &&
    isEPolicyInvestmentNeeded &&
    isRequireEPolicy
  )
})

// TODO: To be removed after launched, no longer need to check for backward compatibily
export const isPrivacyConsentDocumentNeeded = createSelector(getCurrentApp, (app) => {
  return (
    has('insurancePrivacyConsent.value', app) &&
    has('marketingPrivacyConsent.value', app) &&
    getToggles().ENABLE_PDPA_CONSENT
  )
})

// TODO: To be removed after launched, no longer need to check for backward compatibily
export const isPrivacyPolicyDocumentNeeded = createSelector(getCurrentApp, (app) => {
  return (
    has('insurancePrivacyConsent.value', app) &&
    has('marketingPrivacyConsent.value', app) &&
    getToggles().ENABLE_PDPA_CONSENT
  )
})

export const isFNADocumentNeeded = createSelector(getCurrentApp, (app) => {
  const category = get('quickQuote.selectedDisplayProduct.category', app)
  return category === VALUES.MRTA
    ? false
    : has('FNA.marketConduct', app) && get('FNA.needGap.type', app) && getToggles().ENABLE_FNA_FLOW
})

export const isFATCADocumentNeeded = createSelector(getCurrentApp, (app) => {
  const category = get('quickQuote.selectedDisplayProduct.category', app)
  return category === VALUES.MRTA ? has('FATCA', app) : false
})

export const isCRSDocumentNeeded = createSelector(getCurrentApp, (app) => {
  return getToggles().ENABLE_CRS_SECTION ? has('insured.CRS', app) : false
})

export const isBIDocumentNeeded = createSelector(getCurrentApp, (app) => {
  const category = get('quickQuote.selectedDisplayProduct.category', app)
  return category !== VALUES.PA
})

export const isCitizenIdConsentNeeded = createSelector(getCurrentApp, (app) => {
  // in case of this app already signed before new version that added Citizen ID Consent
  // We want to allow submit for app that already sign
  const isSigned = flow(isEqual('signed'), get('status'))(app)
  if (isSigned) return false
  return getToggles().ENABLE_CITIZEN_ID_CONSENT_FORM
})

export const isInsuredUseCitizenId = (app) => _.get(app, 'insured.idType.value') === 'CITIZEN_ID'
export const isInsuredUsePassport = (app) => _.get(app, 'insured.idType.value') === 'PASSPORT'
export const isPayerUseCitizenId = (app) => _.get(app, 'payer.idType.value') === 'CITIZEN_ID'
export const isPayerUsePassport = (app) => _.get(app, 'payer.idType.value') === 'PASSPORT'

export const isCitizenIdConsentInsuredNeeded = createSelector(getCurrentApp, (app) => {
  const insuredUseCitizenId = isInsuredUseCitizenId(app)
  if ((getToggles().ENABLE_EKYC_F2F || isSelectedRemoteSelling(app)) && !isRemoteSelling(app))
    return insuredUseCitizenId
  // in case of this app already signed before new version that added Citizen ID Consent
  // We want to allow submit for app that already sign
  const isSigned = flow(isEqual('signed'), get('status'))(app)
  if (isSigned) return false
  return getToggles().ENABLE_CITIZEN_ID_CONSENT_FORM && insuredUseCitizenId
})
export const isCitizenIdConsentPayerNeeded = createSelector(getCurrentApp, (app) => {
  const payerUseCitizenId = isPayerUseCitizenId(app)

  if ((getToggles().ENABLE_EKYC_F2F || isSelectedRemoteSelling(app)) && !isRemoteSelling(app)) return payerUseCitizenId
  // in case of this app already signed before new version that added Citizen ID Consent
  // We want to allow submit for app that already sign
  const isSigned = flow(isEqual('signed'), get('status'))(app)
  if (isSigned) return false
  return getToggles().ENABLE_CITIZEN_ID_CONSENT_FORM && payerUseCitizenId
})

export const isPassportConsentInsuredNeeded = createSelector(getCurrentApp, (app) => {
  const insuredUsePassport = isInsuredUsePassport(app)
  if ((getToggles().ENABLE_EKYC_F2F || isSelectedRemoteSelling(app)) && !isRemoteSelling(app)) return insuredUsePassport
  // in case of this app already signed before new version that added Citizen ID Consent
  // We want to allow submit for app that already sign
  const isSigned = flow(isEqual('signed'), get('status'))(app)
  if (isSigned) return false
  return getToggles().ENABLE_CITIZEN_ID_CONSENT_FORM && insuredUsePassport
})
export const isPassportConsentPayerNeeded = createSelector(getCurrentApp, (app) => {
  const payerUsePassport = isPayerUsePassport(app)
  if ((getToggles().ENABLE_EKYC_F2F || isSelectedRemoteSelling(app)) && !isRemoteSelling(app)) return payerUsePassport
  // in case of this app already signed before new version that added Citizen ID Consent
  // We want to allow submit for app that already sign
  const isSigned = flow(isEqual('signed'), get('status'))(app)
  if (isSigned) return false
  return getToggles().ENABLE_CITIZEN_ID_CONSENT_FORM && payerUsePassport
})

export const isVCDocumentNeeded = createSelector(getCurrentApp, (app) => {
  const category = get('quickQuote.selectedDisplayProduct.category', app)
  return category === VALUES.MRTA
    ? false
    : !isEmpty(get('FNA.vulnerableQuestions', app)) && getToggles().ENABLE_FNA_PHASE2
})

export const haveThirdPerson = (state) => {
  return !isEmpty(get('FNA.marketConduct.thirdPerson')(state)) && getToggles().ENABLE_FNA_FLOW
}

export const getAuraImpairment = createSelector(getCurrentApp, get('insured.aura.auraImpairment'))

export const getSumPremiumPayment = flow(getCurrentApp, _getSumPremiumPayment)

export const getDefaultInsuredIncomePerMonth = createSelector(
  getInsuredMainOccupation,
  getOccupations,
  ({ occupationCode, natureOfDutyCode }, occupations) =>
    flow(
      find({
        occupationCode,
        natureOfDutyCode,
      }),
      cond([
        [flow(get('occupationClass'), isEqual('4')), constant('0')],
        [flow(get('occupationClass'), isEqual('6')), constant('0')],
        [stubTrue, constant('')],
      ])
    )(occupations)
)

export const getIsRemoteSelling = createSelector(getCurrentApp, (app) => {
  return getToggles().ENABLE_REMOTE_SELLING
})

export const totalPremiumPlusLumpSumTopUp = (totalPremium, lumpSumTopUp = 0) => {
  return d(totalPremium)
    .plus(lumpSumTopUp)
    .toNumber()
}

export const getAllPhotoIlp = (_requiredAttachments, app) => {
  let requiredAttachments = _requiredAttachments
  if (app) {
    function removeDuplicates(array) {
      return array.reduce((accumulator, currentValue) => {
        if (!accumulator.includes(currentValue)) {
          accumulator.push(currentValue)
        }
        return accumulator
      }, [])
    }
    requiredAttachments = removeDuplicates(_requiredAttachments)

    const index = requiredAttachments.indexOf('bank-book')
    if (getOr(false, 'submitWithoutPayment.selected.value', app)) {
      if (
        index > -1 &&
        (getQuickQuoteProductCategory(app) === 'INVESTMENT' ||
          isCCPAStatusCompleted(app) ||
          isCCPAStatusError(app) ||
          isSelectedToPayLater(app))
      ) {
        requiredAttachments.splice(index, 1)
      }
    } else if (index > -1 && (isCCPAStatusCompleted(app) || isCCPAStatusError(app))) {
      requiredAttachments.splice(index, 1)
    }
  }

  return requiredAttachments
}

export const genaratedTransRefGUID = (role) => {
  const uuid = generateUUID()
  return `v15-${role}-${uuid}`
}

export const getCcpa = (state) => get('CCPA')(state)

export const getGA4ccpaSuccess = () => {
  analytics.sendCustomEvent({
    category: 'CCPA_TRACKING',
    action: 'CCPA_SUBSCRIBTION_SUCCESS',
    label: IsRealDevice ? 'MOBILE' : 'WEB',
  })
}
export const getInsureOrPayerFirstname = (insuredOrPayer, state) => {
  const app = getCurrentApp(state)
  return trim(get(`${insuredOrPayer}.firstName`)(app))
}

export const getInsureOrPayerLastname = (insuredOrPayer, state) => {
  const app = getCurrentApp(state)
  return trim(get(`${insuredOrPayer}.lastName`)(app))
}
