//@flow
import _ from 'lodash'
import { buildHeaderTable } from 'core/service/pdf-generation/utils/builder'
import { isArray, isObject } from 'core/service/lib/type-check'
import { convertBenefitData } from 'core/service/pdf-generation/products/common/sections/riders/transform'
import MESSAGES from 'core/data-model/constants/bi-messages'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { RiderBenefitData } from 'core/data-model/rider/benefits'

type APEcareBenefitProps = {
  rider: Rider & RiderState,
  riderBenefitData: RiderBenefitData,
}

export type DisplayType = 'BenefitTable' | 'BurnsTable' | 'Columns' | 'PlainText'

type ValueBenefit = {
  description: string,
  value: string,
  type: 'VALUE',
  displayType: DisplayType,
  disclaimer: string,
}

export type MultipleBenefit = {
  description: string,
  subBenefits: Benefit[],
  type: 'MULTIPLE',
  displayType: DisplayType,
  disclaimer: string,
}

export type Benefit = ValueBenefit | MultipleBenefit

export const APEcareBenefitSection = ({ riderBenefitData }: APEcareBenefitProps) => {
  if (isObject(riderBenefitData) && isArray(riderBenefitData.benefits)) {
    const benefits = convertBenefitData(riderBenefitData.benefits)
    return benefits.map((benefit) => buildBenefitBlock(benefit))
  } else return []
}

export const buildBenefitBlock = (benefit: Benefit) => {
  const disclaimer = benefit.disclaimer || ''
  switch (benefit.type) {
    case 'VALUE':
      return [
        {
          columns: [
            { text: benefit.description, width: 370 },
            { text: benefit.value, alignment: 'right', width: '*', bold: true },
          ],
        },
        { text: disclaimer, style: 'disclaimer', marginBottom: 0 },
      ]
    case 'MULTIPLE':
      const hasPageBreak = benefit.displayType === 'BenefitTable'
      const pageBreak = hasPageBreak ? { text: '', pageBreak: 'after' } : { text: '' }
      return [
        { text: benefit.description },
        ...buildSubBenefitsTable(benefit),
        { text: disclaimer, style: 'disclaimer' },
        pageBreak,
      ]
    default:
      return []
  }
}

export const buildSubBenefitsTable = (benefit: MultipleBenefit) => {
  switch (benefit.displayType) {
    case 'BenefitTable':
      const FIRST_SIZE_BENEFITS_TABLE = 10
      const firstHalf = _.slice(benefit.subBenefits, 0, FIRST_SIZE_BENEFITS_TABLE)
      const rest = _.slice(benefit.subBenefits, FIRST_SIZE_BENEFITS_TABLE)
      return [
        {
          columns: [tableBuilder.buildSubBenefitsTable(firstHalf), tableBuilder.buildSubBenefitsTable(rest)],
          alignment: 'justify',
          columnGap: 10,
        },
      ]
    case 'BurnsTable':
      return tableBuilder.buildBurnsTable(benefit.subBenefits)
    case 'Columns':
      return tableBuilder.buildBenefitColumns(benefit.subBenefits)
    default:
      return []
  }
}

export const buildSubBenefitContent = (subBenefits: Benefit[], isMultiLevel: boolean = false) => {
  let result = []
  subBenefits.forEach((subBenefit) => {
    if (subBenefit.type === 'VALUE') {
      const value = { text: subBenefit.value, style: 'tableValue' }
      const description = isMultiLevel ? { ul: [subBenefit.description] } : subBenefit.description
      result.push([description, value])
    } else {
      result.push([subBenefit.description, ''])
      const rest = buildSubBenefitContent(subBenefit.subBenefits, true)
      result = result.concat(rest)
    }
  })
  return result
}

export const _buildSubBenefitsTable = (subBenefits: Benefit[]) => {
  return {
    layout: 'lightBorder',
    table: {
      headerRows: 1,
      widths: ['*', 'auto'],
      margin: [100, 100],
      body: [buildHeaderTable(benefitTable.headers), ...buildSubBenefitContent(subBenefits)],
    },
  }
}

export const buildBurnsTableContent = (
  benefits: Benefit[],
  isShowDescription: boolean = true,
  description: string = ''
) => {
  let result = []
  benefits.forEach((benefit) => {
    if (benefit.type === 'MULTIPLE') {
      const heading = benefit.description
      benefit.subBenefits.map((b, index) => {
        if (b.type === 'VALUE') {
          const value = { text: b.value, alignment: 'right', bold: true }
          if (index === 0) result.push([heading, b.description, value])
          else result.push(['', b.description, value])
        }
      })
    }
  })
  return result
}

const buildBurnsTable = (benefits: Benefit[]) => {
  return [
    {
      table: {
        headerRows: 1,
        widths: ['auto', '*', 'auto'],
        margin: [100, 100, 100],
        body: [buildHeaderTable(burnsTable.headers), ...buildBurnsTableContent(benefits)],
      },
    },
  ]
}

export const buildBenefitColumns = (benefits: Benefit[]) => {
  let result = []
  benefits.forEach((benefit) => {
    if (benefit.type === 'VALUE')
      result.push({
        columns: [
          { text: benefit.description, width: 350, marginLeft: 20 },
          { text: benefit.value, width: '*', bold: true, alignment: 'right' },
        ],
      })
  })
  return result
}

export const tableBuilder = {
  buildSubBenefitsTable: _buildSubBenefitsTable,
  buildBurnsTable: buildBurnsTable,
  buildBenefitColumns: buildBenefitColumns,
}

const benefitTable = {
  headers: [MESSAGES.PROTECTION_LIST, MESSAGES.BENEFIT_BAHT],
}

const burnsTable = {
  headers: [MESSAGES.AREA_INVOLVED, MESSAGES.PERCENT_OF_TOTAL_BODY_SURFACE_AREA_BURNT, MESSAGES.BENEFIT_BAHT],
}
