// @flow

import _ from 'lodash'
import { isNil } from 'core/service/lib/type-check'
import type { DisplayProduct } from 'core/service/display-product'
import type { Rider, RiderState } from 'core/data-model/rider'
import MESSAGES from 'core/data-model/constants/messages'
import BIMESSAGES from 'core/data-model/constants/bi-messages'
import { _getDisclaimerRiderPremiumMessage } from 'quick-quote/product-common/benefit-illustration/components/profile-summary/summary-table'
import { formatNumber } from 'core/service/lib/number-format'
import { WordWraps } from 'common-components'

type SummaryTableProps = {
  displayProduct: ?DisplayProduct,
  riders: (Rider & RiderState)[],
  totalPremium: number,
  calculatedCoveragePeriod: number,
  calculatedPaymentPeriod: number,
  basicPremium: number,
  basicSumAssured: number,
  hasNonLevelRider: boolean,
  hasShowDisclaimerRiderAsterisk: boolean,
  selectedModelFactorID: string,
  selectedModelFactorLabel: string,
  summaryTableHeader: Function | string,
  isNewAsteriskOfSummaryMainContract: boolean,
}

type Package = {
  code: string,
  text: string,
  value: number,
}

type RiderPlan = {
  planCode: string,
  packageDetail: Package[],
}

type SummaryRowProps = {
  id: string,
  productCode: string,
  productName: string,
  asterisks?: string,
  coverageYear: number | string,
  paymentPeriod: number | string,
  selectedPlan: RiderPlan,
  sumAssured: number | string,
  showSumAssuredDecimalPlaces: boolean,
  premium: number,
}

const SummaryTable = ({
  displayProduct,
  riders,
  totalPremium,
  isNewAsteriskOfSummaryMainContract,
}: SummaryTableProps) => {
  if (isNil(displayProduct)) {
    return
  }

  return (
    <div className="summary-table d-none d-md-block" id="summary-table-pa">
      <div className="details-table">
        <h3>{BIMESSAGES.SUMMARY_OF_MAIN_CONTRACTS_PA}</h3>
        <table id="summary-table">
          <thead>
            <tr>
              <td colSpan="4">{BIMESSAGES.BASIC_PLAN_RIDER_PA}</td>
              <td>
                {BIMESSAGES.SUM_ASSURED} ({MESSAGES.BAHT})
              </td>
            </tr>
          </thead>
          <tbody>
            {renderRidersSummaryRows(riders, isNewAsteriskOfSummaryMainContract)}
            <SummaryFooter totalPremium={totalPremium} />
          </tbody>
        </table>
      </div>
    </div>
  )
}

type SummaryFooterProps = {
  totalPremium: number,
}

const SummaryFooter = ({ totalPremium }: SummaryFooterProps) => {
  return (
    <tr id="summary-footer-pa" className="border-top">
      <td colSpan="4">{BIMESSAGES.TOTAL_PREMIUM_FIRST_PREMIUM}</td>
      <td>{formatNumber(totalPremium, 2)}</td>
    </tr>
  )
}

const SummaryRow = ({ id, riderText, sumAssured }: SummaryRowProps) => {
  return (
    <tr id={`summary-row-${id}`}>
      <td colSpan="4">
        <WordWraps>{riderText}</WordWraps>
      </td>
      <td>{formatNumber(sumAssured)}</td>
    </tr>
  )
}

const renderRidersSummaryRows = (riders: (Rider & RiderState)[]) => {
  if (isNil(riders)) return

  return riders[0]?.selectedPlan?.packageDetail.map((p: Package) => {
    return <SummaryRow key={`summary-row-${p.code}`} id={p.code} riderText={p.text} sumAssured={p.value} />
  })
}

export default SummaryTable
