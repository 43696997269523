// @flow

import type { FundState } from 'core/service/investment'
import {
  calculateTotalFundAllocation,
  resetFundAllocations,
  rppAcceptForeignInvestment,
  rppAcceptHigherRiskInvestment,
  toggleFundAllocation,
  resetRppAcceptFundAllocation,
} from 'quick-quote/product-investment/fund-allocation/rpp/actions'
import { FundAllocation } from 'quick-quote/product-investment/fund-allocation/rpp/fund-allocation'
import {
  getRppAcceptFundAllocation,
  getRppForeignConsent,
  getRppFundAllocations,
  getRppOverRiskConsent,
  getRppTotalAllocations,
  hasFinishedRppFundSummary,
} from 'quick-quote/product-investment/fund-allocation/rpp/selectors'
import {
  getAllowForeignInvestment,
  getAllowHigherRiskInvestment,
  isLowRiskEvaluated,
  getExchangeQuestion,
} from 'quick-quote/product-investment/risk-questionnaire/selectors'
import type { AppState } from 'quick-quote/reducers'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import type { Dispatch } from 'redux'
import { bindActionCreators } from 'redux'
import { getNavTabList } from '../../../components/navigations/tabs'
import { getAvailableFunds, hasValidEntryComponent } from '../selectors'

type FundAllocationProps = { funds: FundState[] }

const mapStateToProps = (state: AppState, ownProps: Object): FundAllocationProps => ({
  funds: getAvailableFunds(state),
  allocations: getRppFundAllocations(state),
  totalAllocation: getRppTotalAllocations(state),
  allowHigherRiskInvestment: getAllowHigherRiskInvestment(state),
  allowForeignInvestment: getAllowForeignInvestment(state),
  isLowRiskEvaluated: isLowRiskEvaluated(state),
  hasValidEntryComponent: hasValidEntryComponent(state),
  navTabList: getNavTabList({ state, location: ownProps.location }),
  rppAcceptFundAllocation: getRppAcceptFundAllocation(state),
  canFinishRppFundAllocation: hasFinishedRppFundSummary(state),
  isAcceptOverRisk: getRppOverRiskConsent(state),
  isAcceptForeign: getRppForeignConsent(state),
  foreignExchangingQuestion: getExchangeQuestion(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  toggleFundAllocation: bindActionCreators(toggleFundAllocation, dispatch),
  calculateTotalFundAllocation: bindActionCreators(calculateTotalFundAllocation, dispatch),
  resetFundAllocations: bindActionCreators(resetFundAllocations, dispatch),
  rppAcceptHigherRiskInvestment: bindActionCreators(rppAcceptHigherRiskInvestment, dispatch),
  rppAcceptForeignInvestment: bindActionCreators(rppAcceptForeignInvestment, dispatch),
  resetRppAcceptFundAllocation: bindActionCreators(resetRppAcceptFundAllocation, dispatch),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FundAllocation))
