// @flow
import { ErrorFeedback } from 'common-components/view-logic'

type RiderMessageProps = {
  riderCode: string,
  messages: string[],
  type: string,
}

const RiderMessage = ({ riderCode, messages, type }: RiderMessageProps) => {
  if (messages.length === 0) {
    return null
  }
  return (
    <div>
      <ErrorFeedback
        id={`${riderCode}-rider-error-messages`}
        errorMessages={messages}
        messageType={`${type}-message`}
      />
    </div>
  )
}

export default RiderMessage
