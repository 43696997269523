// @flow
import _ from 'lodash'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { RiderBenefitData } from 'core/data-model/rider/benefits'
import { isString, isObject, isArray } from 'core/service/lib/type-check'
import { formatNumber } from 'core/service/lib/number-format'
import ArrowRight from '@axah/web-toolkit/icons/arrow-right.svg'

import RiderWithBenefitModal from './rider-benefit-modal'
import {
  getTotalPremiumOfRiderAndModules,
  getRiderYearsOfCoverage,
  getRiderYearsOfPayment,
} from 'core/service/rider/benefits/benefit-data'
import { getRiderSumAssuredDisplayValue } from 'core/service/benefit-illustration/profile-summary'
import MESSAGES from 'core/data-model/constants/bi-messages'
import BENEFITS from 'core/data-model/constants/bi-rider'
import { AgentProtectionDisclaimer } from './agent-protection-disclaimer'
import { getToggles } from 'quick-quote/feature-toggles'

type ESCIAndWPEIP85RiderBenefitProps = {
  rider: {
    wpeip85Rider: Rider & RiderState,
    esciRider: Rider & RiderState,
  },
  riderBenefitData: RiderBenefitData,
}

const renderESCIAndWPEIP85RiderBenefitsRow = (benefit: Benefit, rowNumber: number) => {
  return (
    <tr key={`esci-wpewl85-rider-benefit-row-${rowNumber}`} id={`esci-wpewl85-rider-benefit-row-${rowNumber}`}>
      <td colSpan={isObject(benefit) && isString(benefit.value) ? '1' : '2'}>
        <div>
          {benefit.description && isString(benefit.description)
            ? benefit.description.split('\n').map((text) => <div key={`${text}-${rowNumber}`}>{text}</div>)
            : ''}
        </div>
      </td>
      {isObject(benefit) && isString(benefit.value) && (
        <td>
          {benefit.value.split('\n').map((text) => (
            <div key={`${text}-${rowNumber}`}>{text}</div>
          ))}
        </td>
      )}
    </tr>
  )
}

const renderESCIAndWPEIP85BenefitTable = (riderSumAssured, riderBenefitData: RiderBenefitData) => {
  return (
    <div className="details-table">
      <table id="esci-wpewl85-benefit-table">
        <thead>
          <tr>
            <td>{MESSAGES.BENEFIT_LIST}</td>
            <td>{`แผน ${formatNumber(riderSumAssured)}`}</td>
          </tr>
        </thead>
        <tbody>
          {riderBenefitData.benefits && isArray(riderBenefitData.benefits)
            ? riderBenefitData.benefits.map((benefit, index) =>
                renderESCIAndWPEIP85RiderBenefitsRow(benefit, index + 1)
              )
            : ''}
        </tbody>
      </table>
    </div>
  )
}

const renderESCIAndWPEIP85BenefitSummary = ({ esciRider, wpeip85Rider, title }) => {
  return (
    <div className="product" key={`${esciRider.name.toLowerCase()}-rider-benefit-summary`}>
      <h6 className="name">
        <span className="text">{title}</span>
        <span className="icon d-md-none">
          <ArrowRight />
        </span>
      </h6>
      <div className="info-list">
        <div className="info d-none d-md-block">
          <p className="label">&nbsp;</p>
          <p id={`${esciRider.name.toLowerCase()}-benefit-rider-name`} className="heading">{`${esciRider.name}`}</p>
          <p
            id={`${wpeip85Rider.name.toLowerCase()}-benefit-rider-name`}
            className="heading"
          >{`${wpeip85Rider.name}`}</p>
        </div>
        <div className="info">
          <p className="label">ระยะเวลาคุ้มครอง</p>
          <p
            id={`${esciRider.name.toLowerCase()}-benefit-coverage-period`}
            className="value"
          >{`${getRiderYearsOfCoverage(esciRider)} ปี`}</p>
          <p
            id={`${wpeip85Rider.name.toLowerCase()}-benefit-coverage-period`}
            className="value d-none d-md-block"
          >{`${getRiderYearsOfCoverage(wpeip85Rider)} ปี`}</p>
        </div>
        <div className="info">
          <p className="label">ระยะเวลาชำระเบี้ย</p>
          <p id={`${esciRider.name.toLowerCase()}-benefit-payment-period`} className="value">{`${getRiderYearsOfPayment(
            esciRider
          )} ปี`}</p>
          <p
            id={`${wpeip85Rider.name.toLowerCase()}-benefit-payment-period`}
            className="value d-none d-md-block"
          >{`${getRiderYearsOfPayment(wpeip85Rider)} ปี`}</p>
        </div>
        <div className="info">
          <p className="label">จำนวนเอาประกัน</p>
          <p id={`${esciRider.name.toLowerCase()}-benefit-sum-assured`} className="value">
            {`${getRiderSumAssuredDisplayValue(esciRider)} บาท`}
          </p>
          <p id={`${wpeip85Rider.name.toLowerCase()}-benefit-sum-assured`} className="value d-none d-md-block">
            {getRiderSumAssuredDisplayValue(wpeip85Rider)}
          </p>
        </div>
        <div className="info">
          <p className="label">เบี้ยประกัน</p>
          <p id={`${esciRider.name.toLowerCase()}-benefit-premium`} className="value">{`${formatNumber(
            getTotalPremiumOfRiderAndModules(esciRider),
            2
          )} บาท`}</p>
          <p
            id={`${wpeip85Rider.name.toLowerCase()}-benefit-premium`}
            className="value d-none d-md-block"
          >{`${formatNumber(getTotalPremiumOfRiderAndModules(wpeip85Rider), 2)} บาท`}</p>
        </div>
      </div>
    </div>
  )
}

export const getESCITitle = () => {
  return getToggles().ENABLE_ICARE_REPRICING
    ? BENEFITS.RIDER_ESCI_2020_RIDER_BENEFIT_TITLE
    : BENEFITS.RIDER_ESCI_RIDER_BENEFIT_TITLE
}

const ESCIAndWPEIP85RiderBenefit = ({
  rider: { wpeip85Rider, esciRider },
  riderBenefitData,
}: ESCIAndWPEIP85RiderBenefitProps) => {
  const title = getESCITitle()
  const riderSumAssured: number = _.get(esciRider, 'sumAssured')
  const benefitTable = riderBenefitData ? renderESCIAndWPEIP85BenefitTable(riderSumAssured, riderBenefitData) : null

  return (
    <RiderWithBenefitModal
      id="esci-wpewl85-rider-benefit"
      className="rider esci-wpewl85-rider"
      title={title}
      modalChildren={benefitTable}
    >
      {renderESCIAndWPEIP85BenefitSummary({ wpeip85Rider, esciRider, title })}
      {benefitTable}
      <AgentProtectionDisclaimer rider={esciRider} />
    </RiderWithBenefitModal>
  )
}

export default ESCIAndWPEIP85RiderBenefit
