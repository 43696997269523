import { benefitSummarySection } from './benefit-summary-section'
import { policyValueTableSection } from './policy-value-table-section'
import { policyFirst12PLValueTableSection, policySecond12PLValueTableSection } from './12pl/policy-value-table-section'
import { taxDeductionBenefit, taxDeductionBenefitSukhapabdekdee } from './tax-deduction-section'
import { benefitSummarySection as benefitSummary12PLSection } from './12pl/benefit-summary-section'
import {
  policyValueTableSection as policyPLBValueTableSection,
  policyValueTableSectionMHP as policyPLBMHPValueTableSection,
} from './plb/policy-value-table-section'
import { basicPlanConditionSection as basicPlanS7ConditionSection } from './S7/basicplan-condition-section'

export const section = {
  policyValueTable: policyValueTableSection,
  benefitSummary: benefitSummarySection,
  benefitSummary12PL: benefitSummary12PLSection,
  taxDeductionBenefit: taxDeductionBenefit,
}

export const sectionSukhapabdekdee = {
  policyValueTable: policyValueTableSection,
  benefitSummary: benefitSummarySection,
  taxDeductionBenefit: taxDeductionBenefitSukhapabdekdee,
}

export const section12PL = {
  policyValueTable: policyFirst12PLValueTableSection,
  secondPolicyValueTable: policySecond12PLValueTableSection,
  benefitSummary: benefitSummarySection,
  taxDeductionBenefit: taxDeductionBenefit,
}

export const sectionPLB = {
  policyValueTable: policyPLBValueTableSection,
}

export const sectionPLBMHP = {
  policyValueTable: policyPLBMHPValueTableSection,
}

export const sectionS7 = {
  basicPlanCondition: basicPlanS7ConditionSection,
}
