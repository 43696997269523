/* istanbul ignore file */

import { connect } from 'react-redux'

import { has } from 'lodash/fp'
import { compose, defaultProps, withHandlers, withProps, withState } from 'recompose'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import MESSAGES from 'core/data-model/constants/messages'

import { getIdentityUser } from 'identity/selectors'
import { getAdvisor, getAdvisors } from 'e-submission/domain/service'
import { getCurrentApp } from 'e-submission/apps/selectors'
import { getQuickQuoteAgentPartyCode } from 'e-submission/domain/data-model/form/selectors'
import { getAppConfig } from 'deploy-env/app-config'

export const Component = AsyncTypeahead
export default (hoc) =>
  compose(
    connect((state) => {
      const app = getCurrentApp(state)
      const user = getIdentityUser(state)
      const partyCode = getQuickQuoteAgentPartyCode(app)
      return { user, partyCode }
    }),
    defaultProps({
      minLength: 3,
      searchText: MESSAGES.SEARCHING,
      promptText: MESSAGES.TYPE_TO_SEARCH,
      emptyLabel: MESSAGES.DATA_NOT_FOUND,
      useCache: false,
      dropup: true,
      inputProps: {
        type: 'tel',
      },
    }),
    withState('options', 'setOptions', []),
    withState('errors', 'setErrors', []),
    withState('isFocus', 'setFocus', false),
    withHandlers({
      onChange: ({ setValue, user }) => async ([recommender]) => {
        try {
          const resetValue = () => setValue(undefined)
          if (!has('party', recommender)) {
            return resetValue()
          }
          const fullRecommender = await getAdvisor(user, recommender.advisorId, recommender.type.code)
          return setValue({
            firstName: fullRecommender.party.firstName,
            lastName: fullRecommender.party.lastName,
            advisorCode: fullRecommender.advisorCode,
            advisorId: fullRecommender.advisorId,
            registrations: fullRecommender.party.registrations,
          })
        } catch (err) {
          return resetValue()
        }
      },
      onSearch: ({ user, partyCode, setOptions }) => async (query) => {
        try {
          const options = await getAdvisors(user, query, partyCode, 'agent')
          setOptions(options)
        } catch (error) {
          console.log(error)
        }
      },
      onFocus: ({ setFocus, validate }) => () => {
        setFocus(true)
        validate && validate(false)
      },
      onBlur: ({ setFocus, validate }) => () => {
        setFocus(false)
        validate && validate(true)
      },
      //   onKeyDown: () => e => {
      //     const charCode = e.which ? e.which : e.keyCode

      //     if (
      //       [46, 8, 9, 27, 13].indexOf(charCode) !== -1 ||
      //       // Allow: Ctrl+A, Command+A
      //       ((charCode === 65 ||
      //         charCode === 67 ||
      //         charCode === 86 ||
      //         charCode === 88) &&
      //         (e.ctrlKey === true || e.metaKey === true)) ||
      //       // Allow: home, end, left, right, down, up
      //       (charCode >= 35 && charCode <= 40)
      //     ) {
      //       // let it happen, don't do anythinggit g
      //       return
      //     }
      //     // Ensure that it is a number and stop the keypress
      //     if (
      //       (e.shiftKey || (charCode < 48 || charCode > 57)) &&
      //       (charCode < 96 || charCode > 105)
      //     ) {
      //       e.preventDefault()
      //     }
      //   }
    }),
    withProps(({ value, isFocus }) => ({
      minLength: getAppConfig().SEARCH_AGENT_DIGIT_LENGTH || 3,
      selected: [value && { ...value, isFocus }],
      labelKey: ({ advisorCode = '', party = {}, firstName = party.firstName, lastName = party.lastName, isFocus }) =>
        isFocus ? advisorCode.split('-')[2] : `${advisorCode.split('-')[2]} - ${firstName} ${lastName}`,
    })),
    hoc
  )(Component)
