// @flow
import _ from 'lodash'
import { formatNumber } from 'core/service/lib/number-format'

import RiderWithBenefitModal from './rider-benefit-modal'
import RiderBenefitSummary from './rider-benefit-summary'

import type { RiderBenefitData } from 'core/data-model/rider/benefits'
import type { Rider, RiderState } from 'core/data-model/rider'
import { AgentProtectionDisclaimer } from './agent-protection-disclaimer'
import { AdditionalDisclaimer } from './additional-disclaimer'

type DCIRiderBenefitProps = {
  rider: Rider & RiderState,
  riderBenefitData: RiderBenefitData,
  showDisclaimer: boolean,
}

const renderDCIBenefitTable = (riderSumAssured, riderBenefitData, showDisclaimer) => {
  const diseases: string[] = _.get(riderBenefitData, ['diseases', 'value'])
  return (
    <div className="details-table">
      <table>
        <tbody>
          <tr>
            <td className="benefit-description">{_.get(riderBenefitData, 'benefitDescription')}</td>
            <td className="rider-sumAssured">{formatNumber(riderSumAssured)} บาท</td>
          </tr>
        </tbody>
      </table>
      <p>{_.get(riderBenefitData, ['diseases', 'title'])}</p>
      <div className="disease">
        <div className="block">
          <ol start="1">
            {_.map(diseases.slice(0, 10), (disease, index) => (
              <li key={index}>{disease}</li>
            ))}
          </ol>
        </div>
        <div className="block">
          <ol start="11">
            {_.map(diseases.slice(10, 20), (disease, index) => (
              <li key={index}>{disease}</li>
            ))}
          </ol>
        </div>
        <div className="block">
          <ol start="21">
            {_.map(diseases.slice(20, diseases.length), (disease, index) => (
              <li key={index}>{disease}</li>
            ))}
          </ol>
        </div>
      </div>
      {showDisclaimer && (
        <div className="disclaimer">
          <ul className="list-unstyled">
            <li>*ผู้เอาประกันที่อายุ 56 ปีขึ้นไป ต้องตรวจสุขภาพโดยแพทย์แต่งตั้งของบริษัททุกราย</li>
          </ul>
        </div>
      )}
    </div>
  )
}

const DCIRiderBenefit = ({ rider, riderBenefitData, showDisclaimer }: DCIRiderBenefitProps) => {
  const title = `${rider.description} ${rider.name}`
  const riderSumAssured: number = _.get(rider, 'sumAssured')
  const benefitTable = riderBenefitData
    ? renderDCIBenefitTable(riderSumAssured, riderBenefitData, showDisclaimer)
    : null

  return (
    <RiderWithBenefitModal
      id="dci-rider-benefit"
      className="rider dci-rider"
      title={title}
      modalChildren={benefitTable}
    >
      <RiderBenefitSummary rider={rider} />
      {benefitTable}
      <AgentProtectionDisclaimer rider={rider} />
      <AdditionalDisclaimer rider={rider} />
    </RiderWithBenefitModal>
  )
}

export default DCIRiderBenefit
