// @flow
import type { DisplayProduct } from 'core/service/display-product'
import { getLoanBenefitData, isLoanProduct } from 'core/service/basic-plan/benefit'
import MESSAGES from 'core/data-model/constants/bi-messages'

type MRTAProductBenefitProps = {
  displayProduct: DisplayProduct,
  sumAssured: number,
}

const MRTAProductBenefits = ({ displayProduct, sumAssured }: MRTAProductBenefitProps) => {
  let benefitData = getLoanBenefitData()
  if (!isLoanProduct(displayProduct.basicPlanCode)) return <span />
  return (
    <div id="product-benefits-content">
      <p>
        <b>{MESSAGES.BASIC_PLAN_BENEFITS}</b>
      </p>

      <div className="info-pair" id="death-benefit">
        <p className="description" id="death-benefit-sum-assured">
          {benefitData.beneficiaryBenefits}
        </p>
      </div>
    </div>
  )
}

export default MRTAProductBenefits
