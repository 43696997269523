//@flow
import styled from 'styled-components'
import values from 'core/data-model/constants/values'
import { Heading } from 'common-components/text'

export type RetakeRPQProps = {
  onRetakeRPQConfirmed: Function,
}

export const RetestLink = styled.span`
  cursor: pointer;
  color: ${values.PRIMARY_COLOR};
  text-decoration: underline;
  line-height: 2.9rem;
`

const RetakeRPQWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    margin-left: 200px;
    width: 370px;
  }
`

export const RetakeRPQ = (props: RetakeRPQProps) => {
  const onRetakeClick = (e) => {
    props.onRetakeRPQConfirmed()
  }

  return (
    <RetakeRPQWrapper>
      <RetestLink onClick={onRetakeClick}>
        <Heading>ทดสอบอีกครั้ง</Heading>
      </RetestLink>
      <span> (ระดับความเสี่ยงของท่านอาจเปลี่ยนแปลง)</span>
    </RetakeRPQWrapper>
  )
}
