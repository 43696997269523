import { omitBy, isNil, get } from 'lodash/fp'
import moment from 'moment'

export const getOCRResult = (payload) => {
  const citizenId = get('citizenId', payload).replace(/[^0-9]/g, '')
  const birthDate = get(undefined, 'dateOfBirth', payload)
  const formatDate = birthDate
    ? moment(birthDate.replace('.', '').replaceAll(' ', '/'))
        .add(543, 'years')
        .format('DD/MM/YYYY')
    : undefined
  return omitBy(isNil, {
    firstName: get('firstNameTh', payload),
    lastName: get('lastNameTh', payload),
    idNo: citizenId
      ? {
          citizenId,
        }
      : undefined,
    birthdate: formatDate,
  })
}
