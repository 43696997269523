import _ from 'lodash'
import { concat, compact, find, findIndex, get, isEmpty, set } from 'lodash/fp'
import moment from 'moment-timezone'
import VALUES from 'core/data-model/constants/values'

export const createField = (id, elements, styleOptions = {}) => ({ id, elements, styleOptions })

export const createPageCreator = (app) => (settings) => {
  if (isEmpty(settings)) {
    return settings
  }
  return {
    id: settings.id,
    label: settings.label,
    title: settings.title,
    sections: _.flatMap(settings.sections, (createSection) => createSection(app, settings.prefix)),
    exclude: settings.exclude,
    lastPage: settings.lastPage,
    isDocumentPage: settings.isDocumentPage,
    size: settings.size,
    remoteSection: settings.remoteSection,
    disabled: settings.disabled,
  }
}

export const createSectionCreator = (getQuestions) => (
  id,
  label,
  styleOptions,
  heading,
  className,
  icon,
  remoteSelling
) => (app, prefix, additionalProps) => ({
  id,
  label,
  heading,
  questions: getQuestions(app, prefix, { id, label, additionalProps }),
  styleOptions: _.defaults(styleOptions, {}),
  className,
  icon,
  remoteSelling,
})

export const createSection = (questions) => ({
  id: '',
  questions,
})

export const concatWith = (right) => (left) => concat(left, right)

export const append = (data, path, id, field, increment) => {
  if (path === null) {
    const arrayToMutate = [...data]
    const foundIndex = findIndex({ id })(arrayToMutate)
    arrayToMutate.splice(foundIndex + increment, 0, ...field)
    return arrayToMutate
  } else {
    const arrayToMutate = [...get(path, data)]
    const foundIndex = findIndex({ id })(arrayToMutate)
    arrayToMutate.splice(foundIndex + increment, 0, ...field)
    return set(path, arrayToMutate, data)
  }
}

export const appendBefore = (path, id, field) => (data) => append(data, path, id, field, 0)

export const appendAfter = (path, id, field) => (data) => append(data, path, id, field, 1)

export const prune = (id) => (data) => {
  const arrayToMutate = [...data]
  const foundIndex = findIndex({ id })(arrayToMutate)
  if (foundIndex >= 0) {
    arrayToMutate.splice(foundIndex, 1)
  }
  return arrayToMutate
}

export const deepInsert = (paths, items, scope = null, positionOffset = 0) => (pages) => {
  !!scope || (scope = pages)
  const [[id, subKey], ...tail] = paths
  if (_.size(tail) === 0) {
    const foundIndex = findIndex({ id })(scope)
    foundIndex >= 0 && scope.splice(foundIndex + positionOffset, 0, ...items)
    return pages
  } else {
    const foundItem = find({ id })(scope)
    const innerScope = _.get(foundItem, subKey)
    if (!foundItem || !innerScope) return pages
    return deepInsert(tail, items, _.get(foundItem, subKey), positionOffset)(pages)
  }
}

export const deepAppend = (paths, items, scope = null, positionOffset = 1) => (pages) => {
  return deepInsert(paths, items, scope, positionOffset)(pages)
}

export const deviceType = () => {
  const ua = navigator.userAgent
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet'
  } else if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)
  ) {
    return 'mobile'
  }
  return 'desktop'
}

export const customDate = (targetDate, format) =>
  moment(targetDate)
    .tz(VALUES.TIME_ZONE)
    .format(format)

export const getRiderLicenses = (riders = []) => {
  // TODO: get all rider license
  const allLicense = riders.map((rider) => rider.licenses).flat()
  const uniqLicenses = _.uniq(allLicense)

  return compact(uniqLicenses)
}
