// @flow
import _ from 'lodash'
import Mustache from 'mustache'

import syncScroll from 'lib/sync-scroll'
import {
  dashedOutOrWithPrecision,
  PolicyAnnuallyValueRow,
  PolicyAnnuallyValueRowLifeRetire5,
  PolicyMonthlyValueRow,
  PolicyMonthlyValueRowLifeRetire5,
} from './policy-value-row'
import MESSAGES from 'core/data-model/constants/bi-messages'
import CONSTANTS from 'core/data-model/constants/messages'
import VALUES from 'core/data-model/constants/values'
import type { PolicyRetirementValueTableProps } from './selectors'
import { getPensionTypeString } from 'core/service/benefit-illustration/products/retirement'
import { wrapWordingToTextJustify } from 'core/service/benefit-illustration/utils/wording-wrapper'
import {
  Entry,
  Headers,
  Heading,
  HeadingGroup,
  HeadingGroupMaster,
  HeadingGroupSub,
  StickyTable,
  Track,
  Tracks,
} from 'quick-quote/product-investment/coverage-plan/components/StickyTable'
import { getToggles } from 'quick-quote/feature-toggles'

export const _isPensionMonthly = (pensionType: string) => pensionType === 'monthly'

const PolicyValueTable = (props: PolicyRetirementValueTableProps) => {
  return (
    <div
      className={
        getToggles().ENABLE_HIDING_RIDER_PREMIUM_AND_TOTAL_PREMIUM
          ? 'policy group retirement-with-no-rider'
          : 'policy group retirement'
      }
      id="policy-value"
    >
      <h3>
        {Mustache.render(
          VALUES.BUMNAN_READY_CODE_GROUP.includes(props.basicPlan.basicPlanCode)
            ? MESSAGES.RETIREMENT_BUMNAN_READY_POLICY_VALUE_TABLE
            : MESSAGES.RETIREMENT_POLICY_VALUE_TABLE,
          {
            pensionType: getPensionTypeString(props.pensionType),
          }
        )}
      </h3>
      {renderPolicyValueTable(props)}
    </div>
  )
}

const PolicyTable = ({ className, children }: { className?: string, children?: React.Node }) => (
  <StickyTable className={`policy-table${className ? `${className}` : ''}`} id="policy-value-table">
    {children}
  </StickyTable>
)

const _annuallyHeader = ({ targetRef }) => (
  <Headers className="retirement" targetRef={targetRef}>
    <Heading>{CONSTANTS.AGE}</Heading>
    <Heading>{MESSAGES.END_OF_THE_YEAR}</Heading>
    <Heading>{MESSAGES.CUMULATIVE_PREMIUM}</Heading>
    <HeadingGroup>
      <HeadingGroupMaster>
        <Heading>{MESSAGES.PREMIUM_PER_YEAR}</Heading>
      </HeadingGroupMaster>
      <HeadingGroupSub>
        <Heading>{MESSAGES.BASIC_PLAN}</Heading>
        <Heading>{MESSAGES.RIDER}*</Heading>
        <Heading>{MESSAGES.TOTAL}</Heading>
      </HeadingGroupSub>
    </HeadingGroup>
    <HeadingGroup>
      <HeadingGroupMaster>
        <Heading>{MESSAGES.BENEFIT_BEFORE_GET_PENSION}</Heading>
      </HeadingGroupMaster>
      <HeadingGroupSub>
        <Heading>
          <span
            dangerouslySetInnerHTML={{
              __html: wrapWordingToTextJustify(MESSAGES.POLICY_SURRENDER_VALUE),
            }}
          ></span>
        </Heading>
        <Heading>{MESSAGES.DEATH_BENEFIT}</Heading>
      </HeadingGroupSub>
    </HeadingGroup>
    <HeadingGroup>
      <HeadingGroupMaster>
        <Heading>{MESSAGES.BENEFIT_DURING_GET_PENSION}</Heading>
      </HeadingGroupMaster>
      <HeadingGroupSub>
        <Heading>{MESSAGES.RETIREMENT_POLICY_VALUE_PENSION}</Heading>
        <Heading>
          <span
            dangerouslySetInnerHTML={{
              __html: wrapWordingToTextJustify(`${MESSAGES.RETIREMENT_POLICY_VALUE_ANNUAL_TOTAL_PENSION}***`, '', {
                replaces: ['15 งวด'],
              }),
            }}
          ></span>
        </Heading>
      </HeadingGroupSub>
    </HeadingGroup>
  </Headers>
)

const _annuallyHeaderLifeRetire5 = ({ targetRef }) => (
  <Headers className="retirement" targetRef={targetRef}>
    <Heading>{CONSTANTS.AGE}</Heading>
    <Heading>{MESSAGES.POLICY_VALUE_TABLE_INSURANCE_YEAR}</Heading>
    {getToggles().ENABLE_HIDING_RIDER_PREMIUM_AND_TOTAL_PREMIUM ? (
      <Heading>{MESSAGES.BASIC_PREMIUM_PER_YEAR}</Heading>
    ) : (
      <HeadingGroup>
        <HeadingGroupMaster>
          <Heading>{MESSAGES.PREMIUM_PER_YEAR}</Heading>
        </HeadingGroupMaster>
        <HeadingGroupSub>
          <Heading>{MESSAGES.BASIC_PLAN}</Heading>
          <Heading>{MESSAGES.RIDER}*</Heading>
          <Heading>{MESSAGES.TOTAL}</Heading>
        </HeadingGroupSub>
      </HeadingGroup>
    )}
    <Heading>{MESSAGES.CUMULATIVE_PREMIUM}</Heading>
    <HeadingGroup>
      <HeadingGroupMaster>
        <Heading>
          {getToggles().ENABLE_HIDING_RIDER_PREMIUM_AND_TOTAL_PREMIUM
            ? MESSAGES.BENEFIT_BEFORE_GET_PENSION
            : MESSAGES.RETIREMENT_POLICY_VALUE_BEFORE_PENSION_WITH_NOTE}
        </Heading>
      </HeadingGroupMaster>
      <HeadingGroupSub>
        <Heading>
          <span
            dangerouslySetInnerHTML={{
              __html: wrapWordingToTextJustify(
                getToggles().ENABLE_HIDING_RIDER_PREMIUM_AND_TOTAL_PREMIUM
                  ? MESSAGES.SURRENDER_VALUE_END_OF_YEAR
                  : MESSAGES.RETIREMENT_SURRENDER_VALUE_END_OF_YEAR_NOTE
              ),
            }}
          ></span>
        </Heading>
        <Heading>{MESSAGES.DEATH_BENEFIT}</Heading>
      </HeadingGroupSub>
    </HeadingGroup>
    <HeadingGroup>
      <HeadingGroupMaster>
        <Heading>
          {getToggles().ENABLE_HIDING_RIDER_PREMIUM_AND_TOTAL_PREMIUM
            ? MESSAGES.RETIREMENT_POLICY_VALUE_ALLOW_PENSION_WITHOUT_NOTE
            : MESSAGES.RETIREMENT_POLICY_VALUE_ALLOW_PENSION_NOTE_2}
        </Heading>
      </HeadingGroupMaster>
      <HeadingGroupSub>
        <Heading>{MESSAGES.RETIREMENT_POLICY_VALUE_PENSION}</Heading>
        <Heading>{MESSAGES.RETIREMENT_POLICY_VALUE_CUMULATIVE_PENSION_AMOUNT}</Heading>
        <Heading>{MESSAGES.DEATH_BENEFIT}</Heading>
      </HeadingGroupSub>
    </HeadingGroup>
  </Headers>
)

const _annuallyBody = ({
  yearEndPolicyValues,
  lastYearEndPolicy,
  subtotalAnnualBasicPremium,
  subtotalAnnualRiderPremium,
  grandTotalPremium,
  pensionType,
  subtotalPensionAmount,
  targetRef,
}) => (
  <Tracks targetRef={targetRef}>
    {yearEndPolicyValues.map((policyValue, key) => (
      <PolicyAnnuallyValueRow
        key={`policy-monthly-value-row-${key}`}
        age={policyValue.age}
        yearEndPolicy={policyValue.yearEndPolicy}
        cumulativeBasicPremium={policyValue.cumulativeBasicPremium}
        annualBasicPremium={policyValue.annualBasicPremium}
        annualRiderPremium={policyValue.annualRiderPremium}
        annualTotalPremium={policyValue.annualTotalPremium}
        surrenderCash={policyValue.surrenderCash}
        deathBenefit={policyValue.deathBenefit}
        basicSumAssured={policyValue.basicSumAssured}
        pensionPerYear={policyValue.pensionPerYear ? policyValue.pensionPerYear : 0}
        pensionTotal={policyValue.pensionTotal ? policyValue.pensionTotal : 0}
        pensionType={pensionType}
        monthEndPolicy={policyValue.monthEndPolicy}
      />
    ))}
    <Track className="total" id={`policy-value-row-${lastYearEndPolicy}`} key={`policy-value-row-${lastYearEndPolicy}`}>
      <Entry />
      <Entry />
      <Entry />
      <Entry>{dashedOutOrWithPrecision(subtotalAnnualBasicPremium, 2)}</Entry>
      <Entry>{dashedOutOrWithPrecision(subtotalAnnualRiderPremium, 2)}</Entry>
      <Entry>{dashedOutOrWithPrecision(grandTotalPremium, 2)}</Entry>
      <Entry />
      <Entry />
      <Entry>{dashedOutOrWithPrecision(subtotalPensionAmount, 2)}</Entry>
      <Entry />
    </Track>
  </Tracks>
)

const _annuallyBodyLifeRetire5 = ({
  yearEndPolicyValues,
  lastYearEndPolicy,
  subtotalAnnualBasicPremium,
  subtotalAnnualRiderPremium,
  grandTotalPremium,
  pensionType,
  subtotalPensionAmount,
  targetRef,
}) => (
  <Tracks targetRef={targetRef}>
    {yearEndPolicyValues.map((policyValue, key) => (
      <PolicyAnnuallyValueRowLifeRetire5
        key={`policy-monthly-value-row-${key}`}
        age={policyValue.age}
        yearEndPolicy={policyValue.yearEndPolicy}
        cumulativeBasicPremium={policyValue.cumulativeBasicPremium}
        annualBasicPremium={policyValue.annualBasicPremium}
        annualRiderPremium={policyValue.annualRiderPremium}
        annualTotalPremium={policyValue.annualTotalPremium}
        surrenderCash={policyValue.surrenderCash}
        deathBenefit={policyValue.deathBenefit}
        basicSumAssured={policyValue.basicSumAssured}
        pensionPerYear={policyValue.pensionPerYear ? policyValue.pensionPerYear : 0}
        pensionTotal={policyValue.pensionTotal ? policyValue.pensionTotal : 0}
        pensionType={pensionType}
        monthEndPolicy={policyValue.monthEndPolicy}
        cumulativePersionAmount={policyValue.cumulativePersionAmount ? policyValue.cumulativePersionAmount : 0}
      />
    ))}
    <Track className="total" id={`policy-value-row-${lastYearEndPolicy}`} key={`policy-value-row-${lastYearEndPolicy}`}>
      <Entry />
      <Entry />
      <Entry>{dashedOutOrWithPrecision(subtotalAnnualBasicPremium, 2)}</Entry>
      {!getToggles().ENABLE_HIDING_RIDER_PREMIUM_AND_TOTAL_PREMIUM && (
        <Entry>{dashedOutOrWithPrecision(subtotalAnnualRiderPremium, 2)}</Entry>
      )}
      {!getToggles().ENABLE_HIDING_RIDER_PREMIUM_AND_TOTAL_PREMIUM && (
        <Entry>{dashedOutOrWithPrecision(grandTotalPremium, 2)}</Entry>
      )}
      <Entry />
      <Entry />
      <Entry />
      <Entry>{dashedOutOrWithPrecision(subtotalPensionAmount, 2)}</Entry>
      <Entry />
      <Entry />
    </Track>
  </Tracks>
)

// $$FlowFixMe
class PolicyRetirementAnnually extends React.Component {
  constructor(props) {
    super(props)
    // $$FlowFixMe
    this.setHeaderRef = this.setHeaderRef.bind(this)
    // $$FlowFixMe
    this.setBodyRef = this.setBodyRef.bind(this)
    // $$FlowFixMe
    this.renderHeader = this.renderHeader.bind(this)
    // $$FlowFixMe
    this.renderBody = this.renderBody.bind(this)
  }

  componentDidMount() {
    // $$FlowFixMe
    syncScroll([this.header, this.body])
  }

  setHeaderRef(element) {
    // $$FlowFixMe
    this.header = element
  }

  setBodyRef(element) {
    // $$FlowFixMe
    this.body = element
  }

  renderHeader() {
    const { basicPlan } = this.props
    return basicPlan.code === VALUES.LIFE_RETIRE_5 || VALUES.BUMNAN_READY_CODE_GROUP.includes(basicPlan.basicPlanCode)
      ? _annuallyHeaderLifeRetire5({ ...this.props, targetRef: this.setHeaderRef })
      : _annuallyHeader({ ...this.props, targetRef: this.setHeaderRef })
  }

  renderBody() {
    const { basicPlan } = this.props

    return basicPlan.code === VALUES.LIFE_RETIRE_5 || VALUES.BUMNAN_READY_CODE_GROUP.includes(basicPlan.basicPlanCode)
      ? _annuallyBodyLifeRetire5({ ...this.props, targetRef: this.setBodyRef })
      : _annuallyBody({ ...this.props, targetRef: this.setBodyRef })
  }

  render() {
    return (
      <PolicyTable className="retirement annually">
        {this.renderHeader()}
        {this.renderBody()}
      </PolicyTable>
    )
  }
}

// $$FlowFixMe
const _monthlyHeader = ({ targetRef }: props) => (
  <Headers className="retirement" targetRef={targetRef}>
    <Heading>{CONSTANTS.AGE}</Heading>
    <Heading>{MESSAGES.END_OF_THE_YEAR}</Heading>
    <Heading>{MESSAGES.RETIREMENT_POLICY_VALUE_MONTHLY}</Heading>
    <Heading>{MESSAGES.CUMULATIVE_PREMIUM}</Heading>
    <HeadingGroup>
      <HeadingGroupMaster>
        <Heading>{MESSAGES.PREMIUM_PER_YEAR}</Heading>
      </HeadingGroupMaster>
      <HeadingGroupSub>
        <Heading>{MESSAGES.BASIC_PLAN}</Heading>
        <Heading>{MESSAGES.RIDER}*</Heading>
        <Heading>{MESSAGES.TOTAL}</Heading>
      </HeadingGroupSub>
    </HeadingGroup>
    <HeadingGroup>
      <HeadingGroupMaster>
        <Heading>{MESSAGES.BENEFIT_BEFORE_GET_PENSION}</Heading>
      </HeadingGroupMaster>
      <HeadingGroupSub>
        <Heading>
          <span
            dangerouslySetInnerHTML={{
              __html: wrapWordingToTextJustify(MESSAGES.POLICY_SURRENDER_VALUE),
            }}
          ></span>
        </Heading>
        <Heading>{MESSAGES.DEATH_BENEFIT}</Heading>
      </HeadingGroupSub>
    </HeadingGroup>
    <HeadingGroup>
      <HeadingGroupMaster>
        <Heading>{MESSAGES.BENEFIT_DURING_GET_PENSION}</Heading>
      </HeadingGroupMaster>
      <HeadingGroupSub>
        <Heading>{MESSAGES.RETIREMENT_POLICY_VALUE_PENSION_MONTHLY}</Heading>
        <Heading>
          <span
            dangerouslySetInnerHTML={{
              __html: wrapWordingToTextJustify(`${MESSAGES.RETIREMENT_POLICY_VALUE_MONTHLY_TOTAL_PENSION}***`, '', {
                replaces: ['180 งวด'],
              }),
            }}
          ></span>
        </Heading>
      </HeadingGroupSub>
    </HeadingGroup>
  </Headers>
)

const _monthlyHeaderLifeRetire5 = ({ targetRef }: props) => (
  <Headers className="retirement" targetRef={targetRef}>
    <Heading>{CONSTANTS.AGE}</Heading>
    <Heading>{MESSAGES.POLICY_VALUE_TABLE_INSURANCE_YEAR}</Heading>
    <Heading>{MESSAGES.RETIREMENT_POLICY_VALUE_MONTHLY}</Heading>
    {getToggles().ENABLE_HIDING_RIDER_PREMIUM_AND_TOTAL_PREMIUM ? (
      <Heading>{MESSAGES.BASIC_PREMIUM_PER_YEAR}</Heading>
    ) : (
      <HeadingGroup>
        <HeadingGroupMaster>
          <Heading>{MESSAGES.PREMIUM_PER_YEAR}</Heading>
        </HeadingGroupMaster>
        <HeadingGroupSub>
          <Heading>{MESSAGES.BASIC_PLAN}</Heading>
          <Heading>{MESSAGES.RIDER}*</Heading>
          <Heading>{MESSAGES.TOTAL}</Heading>
        </HeadingGroupSub>
      </HeadingGroup>
    )}
    <Heading>{MESSAGES.CUMULATIVE_PREMIUM}</Heading>
    <HeadingGroup>
      <HeadingGroupMaster>
        <Heading>
          {getToggles().ENABLE_HIDING_RIDER_PREMIUM_AND_TOTAL_PREMIUM
            ? MESSAGES.BENEFIT_BEFORE_GET_PENSION
            : MESSAGES.RETIREMENT_POLICY_VALUE_BEFORE_PENSION_WITH_NOTE}
        </Heading>
      </HeadingGroupMaster>
      <HeadingGroupSub>
        <Heading>
          <span
            dangerouslySetInnerHTML={{
              __html: wrapWordingToTextJustify(
                getToggles().ENABLE_HIDING_RIDER_PREMIUM_AND_TOTAL_PREMIUM
                  ? MESSAGES.SURRENDER_VALUE_END_OF_YEAR
                  : MESSAGES.RETIREMENT_SURRENDER_VALUE_END_OF_YEAR_NOTE
              ),
            }}
          ></span>
        </Heading>
        <Heading>{MESSAGES.DEATH_BENEFIT}</Heading>
      </HeadingGroupSub>
    </HeadingGroup>
    <HeadingGroup>
      <HeadingGroupMaster>
        <Heading>
          {getToggles().ENABLE_HIDING_RIDER_PREMIUM_AND_TOTAL_PREMIUM
            ? MESSAGES.RETIREMENT_POLICY_VALUE_ALLOW_PENSION_WITHOUT_NOTE
            : MESSAGES.RETIREMENT_POLICY_VALUE_ALLOW_PENSION_NOTE_2}
        </Heading>
      </HeadingGroupMaster>
      <HeadingGroupSub>
        <Heading>{MESSAGES.RETIREMENT_POLICY_VALUE_PENSION_MONTHLY}</Heading>
        <Heading>{MESSAGES.RETIREMENT_POLICY_VALUE_CUMULATIVE_PENSION_AMOUNT}</Heading>
        <Heading>{MESSAGES.DEATH_BENEFIT}</Heading>
      </HeadingGroupSub>
    </HeadingGroup>
  </Headers>
)
// $$FlowFixMe
const _monthlyBody = ({
  yearEndPolicyValues,
  lastYearEndPolicy,
  subtotalAnnualBasicPremium,
  subtotalAnnualRiderPremium,
  grandTotalPremium,
  pensionType,
  subtotalPensionAmount,
  targetRef,
}) => (
  <Tracks targetRef={targetRef}>
    {yearEndPolicyValues.map((policyValue, key) => (
      <PolicyMonthlyValueRow
        key={`policy-monthly-value-row-${key}`}
        age={policyValue.age}
        yearEndPolicy={policyValue.yearEndPolicy}
        cumulativeBasicPremium={policyValue.cumulativeBasicPremium}
        annualBasicPremium={policyValue.annualBasicPremium}
        annualRiderPremium={policyValue.annualRiderPremium}
        annualTotalPremium={policyValue.annualTotalPremium}
        surrenderCash={policyValue.surrenderCash}
        deathBenefit={policyValue.deathBenefit}
        basicSumAssured={policyValue.basicSumAssured}
        pensionPerYear={policyValue.pensionPerYear ? policyValue.pensionPerYear : 0}
        pensionTotal={policyValue.pensionTotal ? policyValue.pensionTotal : 0}
        pensionType={pensionType}
        monthEndPolicy={policyValue.monthEndPolicy}
      />
    ))}
    <Track className="total" id={`policy-value-row-${lastYearEndPolicy}`} key={`policy-value-row-${lastYearEndPolicy}`}>
      <Entry />
      <Entry />
      <Entry />
      <Entry />
      <Entry>{dashedOutOrWithPrecision(subtotalAnnualBasicPremium, 2)}</Entry>
      <Entry>{dashedOutOrWithPrecision(subtotalAnnualRiderPremium, 2)}</Entry>
      <Entry>{dashedOutOrWithPrecision(grandTotalPremium, 2)}</Entry>
      <Entry />
      <Entry />
      <Entry>{dashedOutOrWithPrecision(subtotalPensionAmount, 2)}</Entry>
      <Entry />
    </Track>
  </Tracks>
)

const _monthlyBodyLifeRetire5 = ({
  yearEndPolicyValues,
  lastYearEndPolicy,
  subtotalAnnualBasicPremium,
  subtotalAnnualRiderPremium,
  grandTotalPremium,
  pensionType,
  subtotalPensionAmount,
  targetRef,
}) => (
  <Tracks targetRef={targetRef}>
    {yearEndPolicyValues.map((policyValue, key) => (
      <PolicyMonthlyValueRowLifeRetire5
        key={`policy-monthly-value-row-${key}`}
        age={policyValue.age}
        yearEndPolicy={policyValue.yearEndPolicy}
        cumulativeBasicPremium={policyValue.cumulativeBasicPremium}
        annualBasicPremium={policyValue.annualBasicPremium}
        annualRiderPremium={policyValue.annualRiderPremium}
        annualTotalPremium={policyValue.annualTotalPremium}
        surrenderCash={policyValue.surrenderCash}
        deathBenefit={policyValue.deathBenefit}
        basicSumAssured={policyValue.basicSumAssured}
        pensionPerYear={policyValue.pensionPerYear ? policyValue.pensionPerYear : 0}
        pensionTotal={policyValue.pensionTotal ? policyValue.pensionTotal : 0}
        pensionType={pensionType}
        monthEndPolicy={policyValue.age.value > 60 ? policyValue.monthEndPolicy : ''}
        cumulativePersionAmount={policyValue.cumulativePersionAmount ? policyValue.cumulativePersionAmount : 0}
      />
    ))}
    <Track className="total" id={`policy-value-row-${lastYearEndPolicy}`} key={`policy-value-row-${lastYearEndPolicy}`}>
      <Entry />
      <Entry />
      <Entry />
      <Entry>{dashedOutOrWithPrecision(subtotalAnnualBasicPremium, 2)}</Entry>
      {!getToggles().ENABLE_HIDING_RIDER_PREMIUM_AND_TOTAL_PREMIUM && (
        <Entry>{dashedOutOrWithPrecision(subtotalAnnualRiderPremium, 2)}</Entry>
      )}
      {!getToggles().ENABLE_HIDING_RIDER_PREMIUM_AND_TOTAL_PREMIUM && (
        <Entry>{dashedOutOrWithPrecision(grandTotalPremium, 2)}</Entry>
      )}
      <Entry />
      <Entry />
      <Entry />
      <Entry>{dashedOutOrWithPrecision(subtotalPensionAmount, 2)}</Entry>
      <Entry />
      <Entry />
    </Track>
  </Tracks>
)
// $$FlowFixMe
class PolicyRetirementMonthly extends React.Component {
  constructor(props) {
    super(props)
    // $$FlowFixMe
    this.setHeaderRef = this.setHeaderRef.bind(this)
    // $$FlowFixMe
    this.setBodyRef = this.setBodyRef.bind(this)
    // $$FlowFixMe
    this.renderHeader = this.renderHeader.bind(this)
    // $$FlowFixMe
    this.renderBody = this.renderBody.bind(this)
  }

  componentDidMount() {
    // $$FlowFixMe
    syncScroll([this.header, this.body])
  }

  setHeaderRef(element) {
    // $$FlowFixMe
    this.header = element
  }

  setBodyRef(element) {
    // $$FlowFixMe
    this.body = element
  }

  renderHeader() {
    const { basicPlan } = this.props
    return basicPlan.code === VALUES.LIFE_RETIRE_5 || VALUES.BUMNAN_READY_CODE_GROUP.includes(basicPlan.basicPlanCode)
      ? _monthlyHeaderLifeRetire5({ targetRef: this.setHeaderRef })
      : _monthlyHeader({ targetRef: this.setHeaderRef })
  }

  renderBody() {
    const { basicPlan } = this.props
    return basicPlan.code === VALUES.LIFE_RETIRE_5 || VALUES.BUMNAN_READY_CODE_GROUP.includes(basicPlan.basicPlanCode)
      ? _monthlyBodyLifeRetire5({ ...this.props, targetRef: this.setBodyRef })
      : _monthlyBody({ ...this.props, targetRef: this.setBodyRef })
  }

  render() {
    return (
      <PolicyTable className="retirement monthly">
        {this.renderHeader()}
        {this.renderBody()}
      </PolicyTable>
    )
  }
}

const PolicyValueContent = (props) =>
  _isPensionMonthly(props.pensionType) ? (
    <PolicyRetirementMonthly {...props} />
  ) : (
    <PolicyRetirementAnnually {...props} />
  )

const renderPolicyValueTable = ({ policyValue, basicPlan, pensionType }: PolicyRetirementValueTableProps) => {
  const lastYearEndPolicy = _.get(_.last(policyValue.yearEndPolicyValues), 'yearEndPolicy', 0) + 1
  return (
    <div>
      <div className="sticky-table-wrap">
        <PolicyValueContent
          lastYearEndPolicy={lastYearEndPolicy}
          basicPlan={basicPlan}
          {...policyValue}
          pensionType={pensionType}
        />
      </div>

      <div className="disclaimer">
        <p>{MESSAGES.NOTE}:</p>
        {!getToggles().ENABLE_HIDING_RIDER_PREMIUM_AND_TOTAL_PREMIUM ? (
          <ul>
            <li>{MESSAGES.RETIREMENT_POLICY_VALUE_NOTE_1}</li>
            <li>{MESSAGES.LIFE_RETIREMENT_POLICY_VALUE_NOTE_2}</li>
            <li>{MESSAGES.LIFE_RETIREMENT_POLICY_VALUE_NOTE_3}</li>
          </ul>
        ) : (
          <ul>
            <li>{MESSAGES.RETIREMENT_POLICY_VALUE_NUMBER_NOTE_1}</li>
            <li>{MESSAGES.RETIREMENT_POLICY_VALUE_NUMBER_NOTE_2}</li>
          </ul>
        )}
      </div>
    </div>
  )
}

export default PolicyValueTable
