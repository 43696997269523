// @flow
import type { Effect } from 'redux-saga/effects'
import { put, select, takeEvery } from 'redux-saga/effects'
import _ from 'lodash'
import { UPDATE_BIRTHDATE } from 'quick-quote/insured-information/actions'
import { setFnaVcQuestion } from './actions'
import { getAge } from 'quick-quote/insured-information/selectors'
import { getSelectedVulnerableQuestions } from './selectors'

export const doUpdateVulnerableQuestions = function*(): Generator<*, *, *> {
  const age = yield select(getAge)
  const selectedVulnerableQuestions = yield select(getSelectedVulnerableQuestions)
  if (age.value >= 60) {
    yield put(setFnaVcQuestion(_.union(selectedVulnerableQuestions, ['olderThan60'])))
  } else {
    _.remove(selectedVulnerableQuestions, (n) => n === 'olderThan60')
    yield put(setFnaVcQuestion(selectedVulnerableQuestions))
  }
}

export function* watchers(): Generator<*, *, Effect[]> {
  yield [takeEvery(UPDATE_BIRTHDATE, doUpdateVulnerableQuestions)]
}

export const sagas = watchers
