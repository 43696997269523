// @flow
import { formatNumber } from 'core/service/lib/number-format'
import type { RiderPlan } from 'core/data-model/rider'
import values from 'core/data-model/constants/values'
import BIMESSAGES from 'core/data-model/constants/bi-messages'
import { WordWraps } from 'common-components'

type SummaryRowProps = {
  id: string,
  productCode: string,
  productName: string,
  asterisks?: string,
  coverageYear: number | string,
  paymentPeriod: number | string,
  selectedPlan: RiderPlan,
  sumAssured: number | string,
  showSumAssuredDecimalPlaces: boolean,
  premium: number,
}

const _displayProductName = (productCode, productName, asterisks, selectedPlan) => {
  if (productCode === values.MEK) {
    switch (selectedPlan.planCode) {
      case values.MEK1:
        return `${BIMESSAGES.RIDER}${BIMESSAGES.SUKHAPABDEKDEE_PLAN.MEK1_RIDER_SUMMARY_TITLE_PREFIX} ${asterisks}`
      case values.MEK2:
        return `${BIMESSAGES.RIDER}${BIMESSAGES.SUKHAPABDEKDEE_PLAN.MEK2_RIDER_SUMMARY_TITLE_PREFIX} ${asterisks}`
      default:
        return `${productName} (${productCode})${asterisks}`
    }
  }
  return productCode === values['25PL'] || productCode === values['12PL']
    ? `${productName} ${asterisks}`
    : `${productName} (${productCode})${asterisks}`
}

const SummaryRow = ({
  id,
  productCode,
  productName,
  asterisks = '',
  coverageYear,
  paymentPeriod,
  selectedPlan,
  sumAssured,
  showSumAssuredDecimalPlaces,
  premium,
}: SummaryRowProps) => {
  const displayProductName = _displayProductName(productCode, productName, asterisks, selectedPlan)
  return (
    <tr id={`summary-row-${id}`}>
      <td>
        <WordWraps>{displayProductName}</WordWraps>
      </td>
      <td>{coverageYear}</td>
      <td>{paymentPeriod}</td>
      <td>{sumAssured}</td>
      <td>{formatNumber(premium, 2)}</td>
    </tr>
  )
}

export default SummaryRow
