// @flow
import type { AppState } from 'quick-quote/reducers'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { RadioButtonGroup } from 'common-components'
import { selectProductBumnanReady } from 'quick-quote/product-bumnan-ready/actions'
import { getSelectableProductBumnanReady, getSelectedProductBumnanReady } from '../selectors'
import VALUES from 'core/data-model/constants/values'
import { getSelectedDisplayProductCode } from 'quick-quote/product-selection/selectors'

export const BumnanReadyProductSelection = ({
  getSelectableProductBumnanReady,
  getSelectedDisplayProductCode,
  selectProductBumnanReady,
}) => {
  const selectableProducts = getSelectableProductBumnanReady

  const selectedProductCode = getSelectedProductBumnanReady(selectableProducts, getSelectedDisplayProductCode)
  const productBumnanReady = [
    {
      text: '8 ปี',
      value: VALUES.BUMNAN_READY_CODE.BUMNAN_READY_8,
      canSelectable: selectableProducts.includes(VALUES.BUMNAN_READY_CODE.BUMNAN_READY_8),
    },
    {
      text: 'ครบอายุ 60 ปี',
      value: VALUES.BUMNAN_READY_CODE.BUMNAN_READY_60,
      canSelectable: selectableProducts.includes(VALUES.BUMNAN_READY_CODE.BUMNAN_READY_60),
    },
  ]

  return (
    <div className="form-group">
      <div className="form-group-title">
        <span className="form-group-title-text">ระยะเวลาการชำระเบี้ยประกัน</span>
      </div>
      <RadioButtonGroup
        keyToGetValue="value"
        className="model-factor"
        onChange={selectProductBumnanReady}
        value={selectedProductCode}
        options={productBumnanReady}
      />
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  getSelectableProductBumnanReady: getSelectableProductBumnanReady(state),
  getSelectedDisplayProductCode: getSelectedDisplayProductCode(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  selectProductBumnanReady: bindActionCreators(selectProductBumnanReady, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(BumnanReadyProductSelection)
