// @flow
import type { State } from './reducer'

export const SET_ENTRY_COMPONENT = 'SET_ENTRY_COMPONENT'
export const RESET_ENTRY_COMPONENT = 'RESET_ENTRY_COMPONENT'

export const setEntryInformation = (entryInfo: State) => ({
  type: SET_ENTRY_COMPONENT,
  payload: entryInfo,
})

export const resetEntryInformation = () => ({
  type: RESET_ENTRY_COMPONENT,
})

export type Action = SetEntryComponent | ResetEntryComponent

export type SetEntryComponent = {
  type: 'SET_ENTRY_COMPONENT',
  payload: State,
}

export type ResetEntryComponent = {
  type: 'RESET_ENTRY_COMPONENT',
  payload: State,
}
