// @flow

export const PA_UPDATE_CODE_IN_SELECTED_DISPLAY_PRODUCT = 'PA_UPDATE_CODE_IN_SELECTED_DISPLAY_PRODUCT'
type PAUpdateCodeInSelectedDisplayProduct = {
  type: 'PA_UPDATE_CODE_IN_SELECTED_DISPLAY_PRODUCT',
  payload: string,
}
export const updateCodeInSelectedDisplayProductPA = (code: string): PAUpdateCodeInSelectedDisplayProduct => ({
  type: PA_UPDATE_CODE_IN_SELECTED_DISPLAY_PRODUCT,
  payload: code,
})

export const UPDATE_SUM_ASSURED_AFTER_SELECTED_PLAN_PA = 'UPDATE_SUM_ASSURED_AFTER_SELECTED_PLAN_PA'
export type UpdateSumAssuredAfterSelectedPlanPA = {
  type: 'UPDATE_SUM_ASSURED_AFTER_SELECTED_PLAN_PA',
  payload: number,
}
export const updateSumAssuredAfterSelectedPlanPA = (sumAssured: number): UpdateSumAssuredAfterSelectedPlanPA => ({
  type: UPDATE_SUM_ASSURED_AFTER_SELECTED_PLAN_PA,
  payload: sumAssured,
})
