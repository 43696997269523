// @flow

import type { FlexiHealthProps } from './types'
import { render as renderInsuredInformationHeader } from '../common/components/insured-information-header'
import { render as renderProductInformationHeader } from './components/product-information-header'
import { renderFooter } from '../common/footer'

export const BLUE = '#2425aa'
const template = async (props: FlexiHealthProps) => {
  const {
    basicPlan,
    selectedPlanName,
    selectedPlanCode,
    profile,
    insured,
    version,
    datetime,
    startDate,
    endDate,
    redLine,
    logo,
    biType,
    distributorType,
    isReviseQQ,
  } = props
  const productInformationHeader = renderProductInformationHeader(
    basicPlan,
    selectedPlanName,
    selectedPlanCode,
    redLine
  )
  const { age, title, firstName, lastName, genderLabel } = insured

  return {
    defaultFileName: 'ktaxa',
    defaultStyle: {
      font: 'PSLTextPro',
      fontSize: 14,
    },
    styles: footerStyles,
    pageMargins: [28, 113, 28, 140],
    pageBreakBefore: (currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) => {
      let hasTitleBefore = false
      const lastPreviousNode = previousNodesOnPage.slice(-1)[0]
      if (lastPreviousNode) {
        hasTitleBefore = lastPreviousNode.style === 'title'
      }
      return currentNode.id === 'breakBeforeRow' && currentNode.pageNumbers.length > 1 && !hasTitleBefore
    },
    header: (currentPage, pageCount) => ({
      margin: [28, 28, 28, 28],
      columns: [
        productInformationHeader,
        {
          text: '',
          width: '*',
        },
        renderInsuredInformationHeader({
          title,
          firstName,
          lastName,
          age,
          gender: genderLabel,
          isReviseQQ,
        }),
      ],
    }),
    footer: (currentPage, pageCount) =>
      renderFooter({
        logo,
        profile,
        startDate,
        endDate,
        datetime,
        version,
        currentPage,
        pageCount,
        biType,
        distributorType,
      }),
  }
}

const footerStyles = {
  basicPlanSummaryValue: {
    fontSize: 26,
    bold: true,
    margin: [0, -5, 0, 5],
  },
  disclaimer: {
    fontSize: 12,
  },
  disclaimerBox: {
    fontSize: 12,
    margin: [0, 5, 0, 5],
    alignment: 'center',
  },
  textRow: {
    margin: [0, 0, 0, 5],
  },
  groupRow: {
    margin: [0, 0, 0, 20],
  },
  table: {
    margin: [0, 0, 0, 10],
    width: 'auto',
  },
  tableFlexiHealth: {
    margin: [0, 0, 0, 10],
    width: 'auto',
    fontSize: 9,
  },
  tableHeader: {
    alignment: 'center',
    bold: true,
    noWarp: true,
  },
  title: {
    fontSize: 20,
    margin: [0, -5, 0, 5],
    bold: true,
  },
  'sub-title': {
    fontSize: '16',
    margin: [0, 0, 0, 8],
    bold: true,
  },
  'second-title': {
    fontSize: '18',
    margin: [0, 8, 0, 5],
    bold: true,
  },
  info: {
    columnGap: 50,
    margin: [0, 0, 0, 10],
  },
  indent: {
    marginLeft: 15,
  },
  tableValue: {
    alignment: 'right',
    bold: true,
  },
}
export default template
