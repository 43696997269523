import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button as _Button } from 'reactstrap' //Button
import { Box } from 'rebass'
import _ from 'lodash'

import { getProductsPayload } from 'e-submission/apps/selectors'
import Button from 'e-submission/components/Button'
import Card from 'e-submission/components/Card'
import Content from 'e-submission/components/Layout/Content'
import FormSection from 'e-submission/components/Layout/FormSection'

import axios from 'axios'
import { getMwPlusProfileDetail } from 'e-submission/domain/service/mwPlus'
import { SplashScreen } from 'quick-quote/components'
import { CustomDialog, CustomerProfile, MwpDetailModal, RangeSelection } from 'quick-quote/my-wealth-plus/components'
import { RANGE_OPTIONS } from 'quick-quote/my-wealth-plus/constant'
import {
  getProductDetail,
  mappingProductMwpWithAz,
  getCustomerList,
  getDeeplinkInfo,
} from 'quick-quote/my-wealth-plus/utils'
import { getToggles } from 'quick-quote/feature-toggles'
import { loginRequest } from 'quick-quote/actions'
import { getIsUserOnline } from 'quick-quote/selectors'
import { setNotificationMessage } from 'quick-quote/notification/actions'

import { updateMyWealthPayload } from '../actions'
import useUserAgent from '../hooks/useUserAgent'

const CustomerProfileList = (props) => {
  const { history } = props

  const stateSelector = useSelector((state) => state)
  const dispatch = useDispatch()

  // ----- State zone -----
  const [isOpenNoDataModal, setIsOpenNoDataModal] = useState(false)
  const [isOpenErrorModal, setIsOpenErrorModal] = useState(false)
  const [isOpenDeeplinkErrorModal, setIsOpenDeeplinkErrorModal] = useState(false)
  const [isOpenDetailModal, setIsOpenDetailModal] = useState(false)
  const [isLoadingData, setIsLoadingData] = useState(false)
  const [isDeeplinkSource, setIsDeeplinkSource] = useState(false)
  const [azProducts, setAzProducts] = useState()
  const [selectedRange, setSelectedRange] = useState(RANGE_OPTIONS[0])
  const [mwpCustomerList, setMwpCustomerList] = useState([])
  const [selectedProfile, setSelectedProfile] = useState()

  const userData = useUserAgent()

  // --- Fetch function ---

  const fetchCustomerProfileList = async () => {
    setIsLoadingData(true)

    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    try {
      setIsLoadingData(true)

      const productsFromStore = getProductsPayload(stateSelector)
      setAzProducts(productsFromStore)

      const mwPlusCustomerProfileList = await getCustomerList(userData, selectedRange)

      setIsLoadingData(false)

      if (!mwPlusCustomerProfileList) {
        setIsOpenErrorModal(true)
      } else if (mwPlusCustomerProfileList.length === 0) {
        setIsOpenNoDataModal(true)
      } else {
        setMwpCustomerList(mwPlusCustomerProfileList)
      }

      return () => {
        source.cancel()
      }
    } catch (error) {
      //! TODO: handle error and display modal
      console.error(error)
    }
  }

  const handleSelectCustomerProfile = (customerId) => {
    const payload = {
      customerId,
    }

    getMwPlusProfileDetail(userData, payload).then((result) => {
      if (result.apiStatus === 'FAILED') {
        setIsOpenErrorModal(true)
        return
      }

      setSelectedProfile(result)
      setIsOpenDetailModal(true)
    })
  }

  // ----- Lifecycle -----

  // --- start ComponentWillMount scope ---
  // react hook is deprecated componentWillMount. so, we try this way to solve it.
  const runOnceBeforeRender = async () => {
    const isUserOnline = getIsUserOnline(stateSelector)
    if (!isUserOnline) {
      history.push('../portal')
      dispatch(setNotificationMessage({ type: 'Warning', subType: 'NoInternet' }))
      return
    }

    const agentCode = userData.profile.agent_code
    if (!agentCode) {
      history.push('../portal')
      dispatch(loginRequest())
      return
    } else {
      await fetchCustomerProfileList()

      // check url deeplink
      const { isDeeplink, search, deeplinkCustomerProfileId, deeplinkAgentId } = getDeeplinkInfo(history)
      if (isDeeplink || search) {
        if (
          _.isEmpty(deeplinkCustomerProfileId) ||
          _.isEmpty(deeplinkAgentId) ||
          !(agentCode === deeplinkAgentId) ||
          !getToggles().ENABLE_MWP_MENU
        ) {
          setIsOpenDeeplinkErrorModal(true)
          return
        } else {
          setIsDeeplinkSource(true)
        }
      }
    }
  }
  useState(runOnceBeforeRender)
  // --- end ComponentWillMount scope ---

  useEffect(() => {
    ;(async () => {
      await fetchCustomerProfileList()
    })()
  }, [selectedRange])

  useEffect(() => {
    if (isDeeplinkSource) {
      const { deeplinkCustomerProfileId } = getDeeplinkInfo(history)

      const selectedCustomerProfileFromDeeplink = mwpCustomerList.find((item) => {
        return item.id == deeplinkCustomerProfileId
      })

      if (_.isEmpty(selectedCustomerProfileFromDeeplink)) {
        setIsOpenNoDataModal(true)
        return
      } else {
        handleSelectCustomerProfile(deeplinkCustomerProfileId)
      }

      setIsDeeplinkSource(false)
    }
  }, [isDeeplinkSource])

  // ----- Function -----

  const handleConfirm = (prefillSelected) => {
    setIsOpenDetailModal(false)

    const productCode = selectedProfile.productCode
    const mappedProduct = mappingProductMwpWithAz(productCode)

    if (mappedProduct) {
      const { azCode, categoryId, categoryPath } = mappedProduct
      const detail = getProductDetail(azCode, azProducts) // product detail info

      // -- update quick qoute insured-info state --
      dispatch(
        updateMyWealthPayload({
          productDetail: detail,
          categoryId,
          categoryPath,
          myWealthPlusProfile: selectedProfile,
          premiumPrefill: prefillSelected,
        })
      )
    }
  }

  // ----- Render zone -----
  if (isLoadingData) {
    return (
      <SplashScreen loadingState={'ready'} updateApplicationRequest={() => {}} reloadApplicationRequest={() => {}} />
    )
  }

  return (
    <Content className="mywealth-plus">
      <Box className="app-section">
        <CustomDialog
          isOpen={isOpenErrorModal}
          message={{
            title: 'ระบบไม่สามารถแสดงข้อมูลได้',
            body: 'ท่านสามารถกดปุ่มอัปเดตข้อมูล<br>เพื่อเรียกข้อมูลผู้มุ่งหวังได้ในภายหลัง',
          }}
          onConfirm={() => {
            setIsOpenErrorModal(false)
          }}
        />
        <CustomDialog
          noTitle
          isOpen={isOpenNoDataModal}
          message={{ body: 'ระบบไม่พบข้อมูลผู้มุ่งหวัง' }}
          onConfirm={() => {
            setIsOpenNoDataModal(false)
          }}
        />
        <CustomDialog
          isOpen={isOpenDeeplinkErrorModal}
          message={{
            title: 'ข้อมูลการใช้งานระบบ AdvisorZone ไม่ถูกต้อง',
            body: 'กรุณาตรวจสอบชื่อผู้ใช้งานหรือข้อมูลผู้มุ่งหวังของท่าน ก่อนดำเนินการกรอกข้อมูลใบคำขอ',
          }}
          onConfirm={() => {
            setIsOpenDeeplinkErrorModal(false)
            history.push('../portal')
          }}
        />
        {selectedProfile && (
          <MwpDetailModal
            data={selectedProfile}
            isOpen={isOpenDetailModal}
            onConfirm={handleConfirm}
            toggle={() => {
              setIsOpenDetailModal(false)
            }}
          />
        )}
        <FormSection className="form-section">
          <div className="list-header">
            <strong>ข้อมูลลูกค้า</strong>
            <div className="filter-group">
              <RangeSelection
                value={selectedRange.value}
                onChange={(range) => {
                  setSelectedRange(range)
                }}
              />
              <Button
                icon="refresh"
                color="outline-primary"
                className="refresh-button"
                onClick={async () => {
                  await fetchCustomerProfileList()
                }}
              >
                อัปเดตข้อมูล
              </Button>
            </div>
          </div>
          {mwpCustomerList &&
            mwpCustomerList.map((item, index) => (
              <Card key={index} className="opty-card-item">
                <CustomerProfile
                  name={item.profileName}
                  coverage={item.coverage}
                  product={item.productCode}
                  date={item.createdAt}
                />
                <div className="card-button-section">
                  <_Button
                    color="primary"
                    outline={false}
                    className="card-button"
                    onClick={() => {
                      handleSelectCustomerProfile(item.id)
                    }}
                  >
                    เลือก
                  </_Button>
                </div>
              </Card>
            ))}
        </FormSection>
      </Box>
    </Content>
  )
}

export default CustomerProfileList
