//@flow
import Mustache from 'mustache'
import MESSAGES from 'core/data-model/constants/bi-messages'
import type { TaxDeduction } from 'core/service/benefit-illustration'
import { formatNumber } from 'core/service/lib/number-format'

import { wrapWordingToTextJustify } from 'core/service/benefit-illustration/utils/wording-wrapper'

type TaxDeductionProps = {
  taxDeduction: TaxDeduction,
}

export const TaxDeductionBenefit = ({ taxDeduction }: TaxDeductionProps) => {
  return (
    <div id="tax-benefit" className="legal group retirement-tax-benefit">
      <h3>{MESSAGES.TAX_DEDUCTION_BENEFIT_TITLE}</h3>
      <div className="disclaimer">
        <div>{MESSAGES.TAX_DEDUCTION_BENEFIT_SUB_TITLE}</div>
        <div
          dangerouslySetInnerHTML={{
            __html: `${MESSAGES.TAX_DEDUCTION_BENEFIT_SUB_DESCRIPTION} <strong>${wrapWordingToTextJustify(
              MESSAGES.TAX_DEDUCTION_BENEFIT_SUB_DESCRIPTION_STRONG
            )}</strong>`,
          }}
        />
        <ul className="taxdeduction">
          <li>
            <div>{MESSAGES.SUKHAPABDEKDEE_TAX_DEDUCTION_BENEFIT_SUB_LIST[0].description}</div>
            <div>
              {Mustache.render(MESSAGES.SUKHAPABDEKDEE_TAX_DEDUCTION_BENEFIT_SUB_LIST[0].benefit, {
                value: formatNumber(taxDeduction.taxDeductionBasicPremiumForYear, 2, true),
              })}
            </div>
          </li>
          <li>
            <div>{MESSAGES.SUKHAPABDEKDEE_TAX_DEDUCTION_BENEFIT_SUB_LIST[1].description}</div>
            <div>
              {Mustache.render(MESSAGES.SUKHAPABDEKDEE_TAX_DEDUCTION_BENEFIT_SUB_LIST[1].benefit, {
                value: formatNumber(taxDeduction.taxDeductionRiderPremuimForYear, 2, true),
              })}
            </div>
          </li>
        </ul>
        <p
          dangerouslySetInnerHTML={{
            __html: wrapWordingToTextJustify(MESSAGES.TAX_CONSENT_DEDUCTION_BENEFIT_SUB_ADDITIONAL),
          }}
        ></p>
        <br />
        <p>{MESSAGES.NOTE}:</p>
        <ul>
          <li>
            <strong>{MESSAGES.SUKHAPABDEKDEE_TAX_DEDUCTION_BENEFIT_SUB_NOTES[0][0]}</strong>{' '}
            {MESSAGES.SUKHAPABDEKDEE_TAX_DEDUCTION_BENEFIT_SUB_NOTES[0][1]}
          </li>
          <li>
            <br />
            <strong>{MESSAGES.SUKHAPABDEKDEE_TAX_DEDUCTION_BENEFIT_SUB_NOTES[1][0]}</strong>{' '}
            {MESSAGES.SUKHAPABDEKDEE_TAX_DEDUCTION_BENEFIT_SUB_NOTES[1][1]}
          </li>
        </ul>
      </div>
    </div>
  )
}
