//@flow

import MESSAGES from 'core/data-model/constants/bi-messages'
import Mustache from 'mustache'

type Props = {
  startDate: string,
  endDate: string,
}

export const legalDisclaimerSection = ({ startDate, endDate }: Props) => ({
  stack: [
    { text: MESSAGES.LEGAL_DISCLAIMER, style: 'title' },
    {
      ol: [
        [
          {
            text: MESSAGES.POLICY_MAY_CHANGE,
          },
          {
            separator: ['1.'],
            ol: [MESSAGES.AGE_HEALTH_OR_CAREER, MESSAGES.INCREASE_IN_PREMIUM_ADDITIONAL_CONTRACT],
          },
        ],
        MESSAGES.PRELIMINARY_OFFER_MAY_CHANGE,
        Mustache.render(MESSAGES.OFFER_SALE_PERIOD, {
          startOffer: startDate,
          endOffer: endDate,
        }),
      ],
    },
  ],
  style: 'groupRow',
})

type legalDisclaimerTwoSectionProps = {
  startDate: string,
  endDate: string,
  isBancAgent: boolean,
}

export const legalDisclaimerTwoSection = ({ startDate, endDate, isBancAgent }: legalDisclaimerTwoSectionProps) => ({
  stack: [
    { text: MESSAGES.LEGAL_DISCLAIMER, style: 'title' },
    {
      ol: [
        [
          {
            text: MESSAGES.POLICY_MAY_CHANGE,
          },
          {
            separator: ['1.'],
            ol: [
              MESSAGES.AGE_HEALTH_OR_CAREER,
              Mustache.render(MESSAGES.FLEXI_HEALTH_INCREASE_IN_PREMIUM_ADDITIONAL_CONTRACT, {
                agentName: isBancAgent ? MESSAGES.FLEXI_HEALTH_BANC : MESSAGES.FLEXI_HEALTH_AGENT,
              }),
            ],
          },
        ],
        MESSAGES.PRELIMINARY_OFFER_MAY_CHANGE,
        Mustache.render(MESSAGES.FLEXI_HEALTH_OFFER_SALE_PERIOD, {
          startOffer: startDate,
          endOffer: endDate,
        }),
      ],
    },
  ],
  style: 'groupRow',
})

type LegalDisclaimerSectionProps = {
  startDate: string,
  endDate: string,
}

export const legalDisclaimerThreeSection = ({ startDate, endDate }: LegalDisclaimerSectionProps) => ({
  stack: [
    { text: MESSAGES.LEGAL_DISCLAIMER, style: 'title' },
    {
      ol: [
        [
          {
            text: MESSAGES.POLICY_MAY_CHANGE,
          },
          {
            separator: ['1.'],
            ol: [MESSAGES.AGE_HEALTH_OR_CAREER, MESSAGES.INCREASE_IN_PREMIUM_ADDITIONAL_CONTRACT_DETAIL],
          },
        ],
        MESSAGES.PRELIMINARY_OFFER_MAY_CHANGE,
        Mustache.render(MESSAGES.DATE_DISCLAIMER_FOOTER, {
          startDate: startDate,
          endDate: endDate,
        }),
      ],
    },
  ],
  style: 'groupRow',
})

export const legalDisclaimerMarketConduct = ({ startDate, endDate }: LegalDisclaimerSectionProps) => ({
  stack: [
    { text: MESSAGES.LEGAL_DISCLAIMER, style: 'title' },
    {
      ol: [
        MESSAGES.INCREASE_IN_PREMIUM_ADDITIONAL_CONTRACT_DETAIL,
        MESSAGES.PRELIMINARY_OFFER_MAY_CHANGE,
        Mustache.render(MESSAGES.DATE_DISCLAIMER_FOOTER, {
          startDate: startDate,
          endDate: endDate,
        }),
      ],
    },
  ],
  style: 'groupRow',
})

export const legalDisclaimerMarketConductNoAddtionalContract = ({
  startDate,
  endDate,
}: LegalDisclaimerSectionProps) => ({
  stack: [
    { text: MESSAGES.LEGAL_DISCLAIMER, style: 'title' },
    {
      ol: [
        MESSAGES.PRELIMINARY_OFFER_MAY_CHANGE,
        Mustache.render(MESSAGES.DATE_DISCLAIMER_FOOTER, {
          startDate: startDate,
          endDate: endDate,
        }),
      ],
    },
  ],
  style: 'groupRow',
})
