//@flow
import MESSAGES from 'core/data-model/constants/bi-messages'

const IMAGE_COLUMN_WIDTH = 22
const TEXT_TOP_MARGIN = 4
const TEXT_TOP_MARGIN_SMALL_TABLE = -5
const TABLE_SMALL_BOTTOM_MARGIN = -5
const IMAGE_WIDTH = 20
const BANK_FONT_SIZE = 12
const noBorder = [false, false, false, false]
const BANK_NAME_FIRST_WIDTH = 150
const BANK_CHANNEL_FIRST_WIDTH = 125
const BANK_NAME_SECOND_WIDTH = 125

const addRowStyle = (body) =>
  body.map(([firstImage, firstDescription, secondImage, secondDescription]) => [
    { image: firstImage, width: IMAGE_WIDTH, border: noBorder },
    { text: firstDescription, border: noBorder, marginTop: TEXT_TOP_MARGIN },
    { image: secondImage, width: IMAGE_WIDTH, border: noBorder },
    { text: secondDescription, border: noBorder, marginTop: TEXT_TOP_MARGIN },
  ])
const addRowStyleOrdinary = (body) =>
  body.map(([firstName, firstChannel, secondName, secondChannel]) => [
    { text: firstName, border: noBorder, marginTop: TEXT_TOP_MARGIN_SMALL_TABLE },
    { text: firstChannel, border: noBorder, marginTop: TEXT_TOP_MARGIN_SMALL_TABLE },
    { text: secondName, border: noBorder, marginTop: TEXT_TOP_MARGIN_SMALL_TABLE },
    { text: secondChannel, border: noBorder, marginTop: TEXT_TOP_MARGIN_SMALL_TABLE },
  ])

const importBankImage = async (bankName: string) => {
  // $$FlowFixMe
  return import(
    /* webpackChunkName: "b64image" */ `!url-loader!core/service/pdf-generation/assets/images/${bankName}.png`
  ).then((module) => module.default)
}
export const _prepareBankData = async () => {
  const krungthai = await importBankImage('KrungThai')
  const kasikorn = await importBankImage('Kasikorn')
  const scb = await importBankImage('SCB')
  const ttb = await importBankImage('TTB')
  const krungsri = await importBankImage('Krungsri')
  const bangkok = await importBankImage('Bangkok')
  const baac = await importBankImage('BAAC')
  const blankLogo = await importBankImage('blankLogo')

  return [
    [krungthai, MESSAGES.BANKS.KRUNGTHAI, krungsri, MESSAGES.BANKS.KRUNGSRI],
    [scb, MESSAGES.BANKS.SCB, ttb, MESSAGES.BANKS.TTB],
    [kasikorn, MESSAGES.BANKS.KASIKORN, bangkok, MESSAGES.BANKS.BANGKOK],
    [baac, MESSAGES.BANKS.BAAC, blankLogo, '-'],
  ]
}
export const _prepareBankDataOrdinary = async () => {
  return [
    [
      MESSAGES.BANKS.KRUNGTHAI_NAME,
      MESSAGES.BANKS.KRUNGTHAI_CHANNEL,
      MESSAGES.BANKS.KRUNGSRI_NAME,
      MESSAGES.BANKS.KRUNGSRI_CHANNEL,
    ],
    [
      MESSAGES.BANKS.KASIKORN_NAME,
      MESSAGES.BANKS.KASIKORN_CHANNEL,
      MESSAGES.BANKS.BANGKOK_NAME,
      MESSAGES.BANKS.BANGKOK_CHANNEL,
    ],
    [MESSAGES.BANKS.TTB_NAME, MESSAGES.BANKS.TTB_CHANNEL, MESSAGES.BANKS.BAAC_NAME, MESSAGES.BANKS.BAAC_CHANNEL],
  ]
}
export const bankOptionsOrdianry = async () => {
  const data = await _prepareBankDataOrdinary()
  const body = [data].map(addRowStyleOrdinary).reduce((body) => body)
  const widths = [BANK_NAME_FIRST_WIDTH, BANK_CHANNEL_FIRST_WIDTH, BANK_NAME_SECOND_WIDTH, '*']
  return {
    fontSize: BANK_FONT_SIZE,
    table: {
      widths: ['*'],
      body: [[{ table: { widths, body } }]],
    },
    layout: 'noBorders',
    marginBottom: TABLE_SMALL_BOTTOM_MARGIN,
  }
}

export const _prepareBankATMData = async () => {
  return [
    [
      MESSAGES.BANK_BYATM.KRUNGTHAI,
      MESSAGES.BANK_BYATM.KRUNGTHAI_CHANNEL,
      MESSAGES.BANK_BYATM.KRUNGSRI,
      MESSAGES.BANK_BYATM.KRUNGSRI_CHANNEL,
    ],
    [
      MESSAGES.BANK_BYATM.KASIKORN,
      MESSAGES.BANK_BYATM.KASIKORN_CHANNEL,
      MESSAGES.BANK_BYATM.BANGKOK,
      MESSAGES.BANK_BYATM.BANGKOK_CHANNEL,
    ],
    [
      MESSAGES.BANK_BYATM.TTB,
      MESSAGES.BANK_BYATM.TTB_CHANNEL,
      MESSAGES.BANK_BYATM.SCB,
      MESSAGES.BANK_BYATM.SCB_CHANNEL,
    ],
  ]
}

export const bankOptionsATM = async () => {
  const data = await _prepareBankATMData()
  const body = [data].map(addRowStyleOrdinary).reduce((body) => body)
  const widths = [BANK_NAME_FIRST_WIDTH, BANK_CHANNEL_FIRST_WIDTH, BANK_NAME_SECOND_WIDTH, '*']
  return {
    fontSize: BANK_FONT_SIZE,
    table: {
      widths: ['*'],
      body: [[{ table: { widths, body } }]],
    },
    layout: 'noBorders',
    marginBottom: TABLE_SMALL_BOTTOM_MARGIN,
  }
}

export const bankOptions = async () => {
  const data = await _prepareBankData()
  const body = [data].map(addRowStyle).reduce((body) => body)
  const widths = [IMAGE_COLUMN_WIDTH, '*', IMAGE_COLUMN_WIDTH, '*']

  return {
    fontSize: BANK_FONT_SIZE,
    table: {
      widths: ['*'],
      body: [[{ table: { widths, body } }]],
    },
    marginTop: 5,
  }
}

export const getBankData = () => {
  return {
    byBill: [
      [MESSAGES.BANK_BYBILL.KRUNGTHAI, MESSAGES.BANK_BYBILL.KRUNGTHAI_CHANNEL],
      [MESSAGES.BANK_BYBILL.TTB, MESSAGES.BANK_BYBILL.TTB_CHANNEL],
      [MESSAGES.BANK_BYBILL.KRUNGSRI, MESSAGES.BANK_BYBILL.KRUNGSRI_CHANNEL],
      [MESSAGES.BANK_BYBILL.KASIKORN, MESSAGES.BANK_BYBILL.KASIKORN_CHANNEL],
      [MESSAGES.BANK_BYBILL.BAAC, MESSAGES.BANK_BYBILL.BAAC_CHANNEL],
      [MESSAGES.BANK_BYBILL.BANGKOK, MESSAGES.BANK_BYBILL.BANGKOK_CHANNEL],
    ],
    byATM: [
      [MESSAGES.BANK_BYATM.KRUNGTHAI, MESSAGES.BANK_BYATM.KRUNGTHAI_CHANNEL],
      [MESSAGES.BANK_BYATM.SCB, MESSAGES.BANK_BYATM.SCB_CHANNEL],
      [MESSAGES.BANK_BYATM.KRUNGSRI, MESSAGES.BANK_BYATM.KRUNGSRI_CHANNEL],
      [MESSAGES.BANK_BYATM.TTB, MESSAGES.BANK_BYATM.TTB_CHANNEL],
      [MESSAGES.BANK_BYATM.KASIKORN, MESSAGES.BANK_BYATM.KASIKORN_CHANNEL],
      [MESSAGES.BANK_BYATM.BANGKOK, MESSAGES.BANK_BYATM.BANGKOK_CHANNEL],
    ],
  }
}
