// @flow

import { createSelector } from 'reselect'
import { getSelectedBasicCategory } from 'quick-quote/product-selection/selectors'
import type { Category } from 'core/data-model/basic-plan'
import type { FundAllocationToggleItem } from 'core/service/investment'
import { getRppFundAllocations } from 'quick-quote/product-investment/fund-allocation/rpp/selectors'

export type ValidateFields = {
  category: Category,
  rpulFundAlloc: FundAllocationToggleItem[],
}

export const validateFields = createSelector(
  getSelectedBasicCategory,
  getRppFundAllocations,
  (category: Category, rpulFundAlloc: FundAllocationToggleItem[]): ValidateFields => ({
    category,
    rpulFundAlloc,
  })
)
