import _ from 'lodash'
import { createSectionCreator, createField } from './utils'

const foreignerSpouseDetails = (app, id) => {
  return _.compact([
    createField(`${id}.birthdate`, [
      {
        label: 'วันเกิดของคู่สมรส Birthdate of spouse',
        id: `${id}.birthdate`,
        c: 'DateDropdownInput',
        required: true,
      },
    ]),
    createField(`${id}.annualIncome`, [
      {
        label: 'รายได้ต่อปีของคู่สมรส Annual income of spouse',
        id: `${id}.annualIncome`,
        c: 'NumberInput',
        required: true,
        p: {},
      },
    ]),
    createField(`${id}.occupation`, [
      {
        label: 'อาชีพ/หน้าที่ที่รับผิดชอบของคู่สมรส / Spouse’s Occupation & responsibilities',
        id: `${id}.occupation`,
        c: 'TextInput',
        required: true,
        p: {},
      },
    ]),
    createField(`${id}.howLongHaveYouBeenMarried`, [
      {
        label: 'คุณแต่งงานมานานเท่าไหร่ / How long have you been married?',
        id: `${id}.howLongHaveYouBeenMarried`,
        c: 'TextInput',
        required: true,
        p: {},
      },
    ]),
    createField(`${id}.haveChildren`, [
      {
        label: 'ท่านมีบุตรหรือไม่ / Have children',
        id: `${id}.haveChildren`,
        c: 'RadioButton',
        required: true,
        p: {
          options: [
            {
              text: 'มี/Yes',
              value: true,
            },
            {
              text: 'ไม่มี/No',
              value: false,
            },
          ],
        },
      },
    ]),
  ])
}

const dataChildrenFields = (app, id) => {
  const isShowRemoveButton = !id.includes('[0]')
  return _.compact([
    isShowRemoveButton &&
      createField(
        `${id}.remove`,
        [
          {
            id: `${id}.remove`,
            c: 'Button',
            p: {
              icon: 'remove',
              children: 'ลบ',
              mergeProps: ({ removeValue }) => ({
                onClick: () => removeValue(id),
              }),
              style: {
                fontSize: '0.8725rem',
                padding: '0.125rem 0.5rem',
              },
            },
          },
        ],
        {
          style: {
            position: 'absolute',
            top: '0',
            right: '0',
          },
        }
      ),
    createField(`${id}.childrenAge`, [
      {
        label: 'วันเกิด/Birthdate of children',
        id: `${id}.birthdate`,
        c: 'DateDropdownInput',
      },
    ]),
  ])
}

const addNewChildrenFields = (app, id, section) => [
  createField(`${id}.addNew`, [
    {
      id,
      c: 'Button',
      p: {
        icon: 'add',
        children: 'เพิ่มข้อมูลบุตร Add more children',
        block: true,
        mergeProps: ({ setValue }) => ({
          onClick: () => {
            setValue(null)
          },
        }),
      },
    },
  ]),
]

export default (sectionId, sectionLabel) => (app, id) => {
  const appDataChildren = _.get(app, `${id}.${sectionId}.kid.dataChildren`, [null])
  const haveChildren = _.get(app, `${id}.${sectionId}.haveChildren.value`)
  return _.compact([
    createSectionCreator(foreignerSpouseDetails)(`${sectionId}`, null, null, sectionLabel, 'form-section__foreigner')(
      app,
      `${id}.${sectionId}`
    ),
  ]).concat(
    _.reduce(
      appDataChildren,
      (result, el, i) => {
        if (!haveChildren) {
          return []
        }
        return [
          ...result,
          createSectionCreator(dataChildrenFields)(
            `${id}.${sectionId}.kid.dataChildren[${i}]`,
            null,
            null,
            `บุตรคนที่ ${i + 1}`,
            'form-section__foreigner'
          )(app, `${id}.${sectionId}.kid.dataChildren[${i}]`),
        ]
      },
      []
    ).concat(
      appDataChildren.length < 3 && haveChildren
        ? createSectionCreator(addNewChildrenFields)(
            `${id}.${sectionId}.kid.dataChildren`,
            null,
            null,
            null,
            'form-section__foreigner__btn-add-new'
          )(app, `${id}.${sectionId}.kid.dataChildren[${appDataChildren.length}]`)
        : []
    )
  )
}
