import styled from 'styled-components'
import { Flex } from 'rebass'
import { contentMaxWidth } from 'e-submission/components/mixins'

const Content = styled(Flex).attrs({
  direction: 'column',
})`
  ${contentMaxWidth}
  input[type='file'] {
    position: absolute;
    opacity: 0;
    z-index: -1;
    left: -999px;
  }
`

export default Content
