// @flow
import _ from 'lodash'

import { checkRule } from './rule-mapper'
import values from 'core/data-model/constants/values'
import type { Rider, RiderEligibilities } from 'core/data-model/rider'
import type { RiderRule } from 'core/data-model/rider/rules'
import type { ValidationResult, RuleType } from 'core/data-model/validation'

export const checkRules = (
  rider: Rider,
  eligibilities: RiderEligibilities,
  validationType?: RuleType
): ValidationResult => {
  const checkRuleEligibility = (rule: RiderRule) => checkRule(rule, eligibilities)
  const isApplicable = (rule: RiderRule) => {
    return !validationType || rule.validationType === validationType
  }

  const messages = _.chain(rider.rules)
    .filter(isApplicable)
    .map(checkRuleEligibility)
    .filter((error) => error && error.message)
    .value()

  const isOk = _.every(messages, (error) => {
    return error.type === values.ADDITIONAL_INFORMATION
  })

  return {
    isOk,
    messages,
  }
}
