import { compact, flow } from 'lodash/fp'
import { appendBefore, concatWith, createPageCreator } from 'e-submission/domain/data-model/form/utils'
import investmentBaseForm from 'e-submission/domain/data-model/product-form/product-investment/base'
import { insuredHealth, payerHealth } from 'e-submission/domain/data-model/form/pages/health'
import { submit } from 'e-submission/domain/data-model/form/pages/submit'
import { signature } from '../signature'
import submitApplicationSection from './submitSection'
import { isSelectedRemoteSelling } from 'e-submission/domain/data-model/form/selectors'
import { remotePayment } from '../../../form/pages/remotePayment'
import { videoConsent } from '../../../form/pages/videoConsent'
import { remoteSignature } from 'e-submission/domain/data-model/form/pages/remoteSignature'

export default (app) => {
  const createPage = createPageCreator(app)
  const selectedRemoteSelling = app && app.quickQuote ? isSelectedRemoteSelling(app) : false

  return flow(
    investmentBaseForm(createPage, selectedRemoteSelling),
    appendBefore(null, 'FATCA', [createPage(insuredHealth(app)), createPage(payerHealth(app))]),
    concatWith(
      selectedRemoteSelling
        ? [
            createPage(signature(app)),
            createPage(videoConsent(app)),
            createPage(remotePayment(app)),
            createPage(remoteSignature(app)),
            createPage(
              submit(
                {
                  sections: [submitApplicationSection('submit-application')],
                },
                selectedRemoteSelling,
                app
              )
            ),
          ]
        : [
            createPage(signature(app)),
            createPage(
              submit({
                sections: [submitApplicationSection('submit-application')],
              })
            ),
          ]
    ),
    compact
  )(app)
}
