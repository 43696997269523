// @flow
import type { DisplayProduct } from 'core/service/display-product'
import { formatNumber } from 'core/service/lib/number-format'
import { getIShieldBenefitData, isIShieldProduct } from 'core/service/basic-plan/benefit'
import ComponentWithBenefitModal from 'quick-quote/product-common/benefit-illustration/components/rider-benefits/components/rider-benefit-modal'
import ArrowRight from '@axah/web-toolkit/icons/arrow-right.svg'

import Mustache from 'mustache'
import { underlineHtml } from 'quick-quote/util/html-render-utils'

type IShieldProductBenefitProps = {
  displayProduct: DisplayProduct,
  sumAssured: number,
}
const benefitData = getIShieldBenefitData()

const renderListItems = (data: string[], startIndex: number, count: number) => {
  return data.slice(startIndex, count).map((dataRow, index) => {
    return (
      <li key={`data_${index}`}>
        <span dangerouslySetInnerHTML={{ __html: underlineHtml(dataRow) }} />
      </li>
    )
  })
}

export const MajorStageCriticalIllness = ({ displayProduct, sumAssured }: IShieldProductBenefitProps) => {
  return (
    <div id="major-stage-critical-illness">
      <div className="title">
        <div className="benefit-header">
          <span className="diseases-covered">{`${benefitData.majorStageCriticalIllnessTitle} ${benefitData.majorStageCriticalIllnessDiseasesCovered} `}</span>
          <b className="maximum-benefit">
            {Mustache.render(benefitData.majorStageCriticalIllnessMaximumBenefit, {
              sumAssured: formatNumber(sumAssured),
            })}
          </b>
        </div>

        <span className="icon d-md-none">
          <ArrowRight />
        </span>
      </div>
      <div className="disease">
        <div className="block">
          <ol start="1">{renderListItems(benefitData.diseases, 20, 45)}</ol>
        </div>
        <div className="block">
          <ol start="26">{renderListItems(benefitData.diseases, 45, 70)}</ol>
        </div>
      </div>
      <ul className="benefit-disclaimers">
        <li>
          <span dangerouslySetInnerHTML={{ __html: underlineHtml(benefitData.sumAssuredDisclaimer) }} />
        </li>
      </ul>
    </div>
  )
}

export const EarlyStageCriticalIllness = ({ displayProduct, sumAssured }: IShieldProductBenefitProps) => {
  let earlyStageCriticalIllnessBenefit = sumAssured * benefitData.earlyStageCriticalIllnessBenefitFactor
  return (
    <div id="early-stage-critical-illness">
      <div className="title">
        <div className="benefit-header">
          <span className="diseases-covered">{`${benefitData.earlyStageCriticalIllnessTitle} ${benefitData.earlyStageCriticalIllnessDiseasesCovered} `}</span>
          <b className="compensation-rate">
            {Mustache.render(benefitData.earlyStageCriticalIllnessCompensationRate, {
              earlyStageCriticalIllnessBenefit: formatNumber(earlyStageCriticalIllnessBenefit, 2),
            })}
          </b>
        </div>
        <span className="icon d-md-none">
          <ArrowRight />
        </span>
      </div>
      <div className="disease">
        <div className="block">
          <ol start="1">{renderListItems(benefitData.diseases, 0, 10)}</ol>
        </div>
        <div className="block">
          <ol start="11">{renderListItems(benefitData.diseases, 10, 20)}</ol>
        </div>
      </div>

      <ul className="benefit-disclaimers">{renderListItems(benefitData.benefitDisclaimers, 0, 5)}</ul>
    </div>
  )
}

const IShieldProductBenefits = ({ displayProduct, sumAssured }: IShieldProductBenefitProps) => {
  if (!isIShieldProduct(displayProduct.basicPlanCode)) return null

  const earlyStageComponent = <EarlyStageCriticalIllness displayProduct={displayProduct} sumAssured={sumAssured} />
  const majorStageComponent = <MajorStageCriticalIllness displayProduct={displayProduct} sumAssured={sumAssured} />

  return (
    <div id="product-benefits-content">
      <p id="death-benefits">
        <b className="caption">{benefitData.deathBenefits}</b>
      </p>
      <p className="description">
        <span dangerouslySetInnerHTML={{ __html: underlineHtml(benefitData.deathBenefitsDescription) }} />
      </p>
      <p id="maturity-benefits">
        <b className="caption">{benefitData.maturityBenefits}</b>
      </p>
      <p className="description">
        <span dangerouslySetInnerHTML={{ __html: underlineHtml(benefitData.maturityBenefitsDescription) }} />
      </p>
      <p id="critical-illness-benefits">
        <b className="caption">{benefitData.criticalIllnessBenefits}</b>
      </p>
      <p className="description">{benefitData.criticalIllnessBenefitsDescription}</p>

      <ComponentWithBenefitModal
        id="early-stage-critical-illness-wrapper"
        className="product critical-illness"
        title={benefitData.earlyStageCriticalIllnessTitle}
        modalChildren={earlyStageComponent}
      >
        {earlyStageComponent}
      </ComponentWithBenefitModal>

      <ComponentWithBenefitModal
        id="major-stage-critical-illness-wrapper"
        className="product critical-illness"
        title={benefitData.majorStageCriticalIllnessTitle}
        modalChildren={majorStageComponent}
      >
        {majorStageComponent}
      </ComponentWithBenefitModal>

      <div id="important-facts">
        <p className="title">{benefitData.importantFacts}</p>
        <ul>
          <li>{benefitData.waitingPeriod}</li>
          <li>{benefitData.incorrectInformation}</li>
          <li>
            {benefitData.cliamFormHeader}
            <ol>{renderListItems(benefitData.claimFormList, 0, 3)}</ol>
          </li>
          <li>{benefitData.earlyBenefitPayment}</li>
          <li>{benefitData.policyChangeCondition}</li>
        </ul>
      </div>

      <div id="exemption">
        <p>
          <b>{benefitData.majorExemption}</b>
        </p>
        {benefitData.reasonsForNoCoverage}
        <ol>{renderListItems(benefitData.majorExemptions, 0, 5)}</ol>

        <div className="disclaimer">
          <ul className="list-unstyled">
            <li>{benefitData.disclaimerMajorExemption}</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default IShieldProductBenefits
