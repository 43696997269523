import { createSectionCreator, createField } from '../utils'

const treatmentField = (app, id) => {
  return [
    createField(`${id}.treatmentMRTA.checked`, [
      {
        id: `${id}.treatmentMRTA.checked`,
        c: 'RadioButton',
        required: true,
        label:
          'ข. การบาดเจ็บ เจ็บป่วย การผ่าตัด การปรึกษาแพทย์ การแนะนำจากแพทย์เกี่ยวกับสุขภาพ การรักษาในสถานพยาบาลที่มิได้ระบุไว้ข้างต้น',
        p: {
          options: [
            {
              text: 'ไม่เคย',
              value: 'no',
            },
            {
              text: 'เคย',
              value: 'yes',
            },
          ],
        },
      },
    ]),
  ]
}

export default createSectionCreator(treatmentField)
