//@flow
import _ from 'lodash'
import { compose, lifecycle } from 'recompose'
import { getToggles } from 'quick-quote/feature-toggles'
import { getAge } from 'quick-quote/insured-information/selectors'
import type { AppState } from 'quick-quote/reducers'
import { connect } from 'react-redux'
import { Button, FormFeedback, FormGroup, ModalBody, ModalHeader } from 'reactstrap'
import { bindActionCreators } from 'redux'
import { Modal } from '../../common-components'
import { setProcessingSuccess, startRemoteSelling, startStandardSelling } from '../actions'
import { setFnaSellingMode, setFnaVcQuestion, setFnaBuyWithAdvisor, setFnaIsSocial } from '../fna/actions'
import {
  getNonVenerableQuestions,
  getORDNonVenerableQuestions,
  getILPNonVenerableQuestions,
  getSelectedVulnerableQuestions,
  getSellingMode,
  getBuyWithAdvisor,
  getVenerableQuestions,
  getIsSocial,
} from '../fna/selectors'
import { getIsAllowRemoteSelling, isSelectedRemoteSelling } from 'quick-quote/selectors'
import { isILPProduct } from 'quick-quote/fna/market-conducts/selectors'
import { isOnlyBankDeposit, getQuestionnaire } from 'quick-quote/product-investment/risk-questionnaire/selectors'
import { getIdentityUser, getUserAgentType } from 'identity/selectors'

function RemoteSellingOptionV3(props) {
  const { buyWithAdvisor, setBuyWithAdvisor, isSocial, setIsSocial, userAgentType } = props
  const isChecked = (optValue) => optValue === buyWithAdvisor

  const handleClick = (optValue) => () => {
    return setBuyWithAdvisor(optValue)
  }

  const isCheckedSocial = () => 'Y' === isSocial

  const handleClickSocial = () => () => {
    if (isSocial === 'Y') {
      return setIsSocial('N')
    }
    return setIsSocial('Y')
  }

  return (
    <div className="form-group form-group--radio-with-other">
      <div className="default-bootstrap remote-selling-modal modal-brand-primary">
        ผู้ขอเอาประกันภัยเลือกผลิตภัณฑ์ประกันชีวิตนี้ โดย (กรุณาระบุ)
      </div>
      <br />
      <div className="fieldset">
        <span>
          <label className="custom-control custom-checkbox" key="SelectedWithAgent" htmlFor="SelectedWithAgent">
            <input
              className="custom-control-input"
              type="radio"
              name="buyWithAdvisor"
              checked={isChecked('SelectedWithAgent')}
              id="SelectedWithAgent"
              onChange={handleClick('SelectedWithAgent')}
            />
            <span className="custom-control-indicator" />
            <span className="default-bootstrap remote-selling-modal">
              ข้าพเจ้าเลือกผลิตภัณฑ์ประกันชีวิตนี้ ตามคำแนะนำของตัวแทนประกันชีวิต/นายหน้าประกันชีวิต
            </span>
          </label>
        </span>
        <div className="validation-message attached-icon info-message-wrap form-control-feedback">
          <p>
            ทั้งนี้ ข้าพเจ้ายืนยันว่าผลิตภัณฑ์ประกันชีวิตที่ข้าพเจ้าเลือกมีความเหมาะสมและสอดคล้องกับความต้องการ
            รวมทั้งเป้าหมายทางการเงินและความสามารถในการชำระเบี้ยประกันภัยของข้าพเจ้า{' '}
          </p>
        </div>

        <br />
        <div>
          <span>
            <label className="custom-control custom-checkbox" key="SelectedWithNotAgent" htmlFor="SelectedWithNotAgent">
              <input
                className="custom-control-input"
                type="radio"
                name="buyWithAdvisor"
                checked={isChecked('SelectedWithNotAgent')}
                id="SelectedWithNotAgent"
                onChange={handleClick('SelectedWithNotAgent')}
              />
              <span className="custom-control-indicator" />
              <span className="default-bootstrap remote-selling-modal">
                ข้าพเจ้าไม่ได้เลือกผลิตภัณฑ์ประกันชีวิตนี้ ตามคำแนะนำของตัวแทนประกันชีวิต/นายหน้าประกันชีวิต
              </span>
            </label>
          </span>
          <div className="validation-message attached-icon info-message-wrap form-control-feedback">
            <p>
              ทั้งนี้ ข้าพเจ้ายืนยันว่าผลิตภัณฑ์แบบประกันชีวิตที่เลือกสอดคล้องกับความต้องการของข้าพเจ้า
              แม้ว่าจะไม่ได้ตรงตามผลการวิเคราะห์ความต้องการซื้อประกันชีวิตหรือคำแนะนำเกี่ยวกับผลิตภัณฑ์ของตัวแทนประกันชีวิต/นายหน้าประกันชีวิต
              และข้าพเจ้าเข้าใจถึงความเสี่ยงว่าผลิตภัณฑ์ประกันชีวิตอาจไม่เหมาะสมกับข้าพเจ้า
            </p>
          </div>
        </div>
        {userAgentType !== 'LB' && (
          <div>
            <span>
              <br />
              <label className="custom-control custom-checkbox" key="isSocial" htmlFor="isSocial">
                <input
                  className="custom-control-input"
                  type="radio"
                  name="isSocial"
                  checked={isCheckedSocial()}
                  id="isSocial"
                  onClick={handleClickSocial()}
                />
                <span className="custom-control-indicator" />
                <span className="default-bootstrap remote-selling-modal">ท่านเป็นลูกค้าจากช่องทาง Social Media</span>
              </label>
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

function RemoteSellingOption(props) {
  const { sellingMode, setSellingMode } = props
  const isChecked = (optValue) => optValue === sellingMode

  const handleClick = (optValue) => () => {
    return setSellingMode(optValue)
  }

  return (
    <div className="form-group form-group--radio form-group--radio-with-other">
      <div className="fieldset">
        <span>
          <label className="custom-control custom-checkbox" key="1" htmlFor="standard">
            <input
              className="custom-control-input"
              type="radio"
              name="radio"
              checked={isChecked('standard')}
              id="standard"
              onChange={handleClick('standard')}
            />
            <span className="custom-control-indicator" />
            <span className="default-bootstrap remote-selling-modal modal-brand-primary">
              <b>AZ แบบเข้าพบลูกค้า</b>
            </span>
          </label>
        </span>

        <span className="checkbox-right">
          <label className="custom-control custom-checkbox" key="2" htmlFor="remote">
            <input
              className="custom-control-input"
              type="radio"
              name="radio"
              checked={isChecked('remote')}
              id="remote"
              onChange={handleClick('remote')}
            />
            <span className="custom-control-indicator" />
            <span className="default-bootstrap remote-selling-modal modal-brand-primary">
              <b>
                AZ แบบลูกค้า<u>ไม่สะดวกให้เข้าพบ</u>
              </b>
            </span>
          </label>
        </span>
      </div>
    </div>
  )
}

function VenerableOption(props) {
  const { venerableQuestions, setQuestion, selectedVulnerableQuestions, age, onlyBankDeposit } = props

  const selectedVenerableQuestions =
    selectedVulnerableQuestions !== undefined
      ? selectedVulnerableQuestions.filter((item) => item === 'isVenerableClient')
      : selectedVulnerableQuestions

  const handleClick = (optValue) => () => {
    return age < 60 && setQuestion(_.xor(selectedVenerableQuestions || [], [optValue]))
  }

  const isChecked = (optValue) => _.includes(selectedVenerableQuestions, optValue) && age < 60

  const disabled = age >= 60 || onlyBankDeposit ? true : false

  const radioItem = venerableQuestions.map((option, index) => {
    return (
      <span key={`${index}`}>
        <label className="custom-control custom-checkbox" key={`${index}`} htmlFor={`${option.value}`}>
          <input
            disabled={disabled}
            className="custom-control-input"
            type="checkbox"
            name="radio"
            checked={isChecked(option.value)}
            id={`${option.value}`}
            onChange={handleClick(option.value)}
          />
          <span className="custom-control-indicator" />
          <span className={disabled ? 'text-muted' : ''}>{option.text}</span>
        </label>
      </span>
    )
  })

  return (
    <div className="form-group form-group--radio form-group--radio-with-other">
      <div className="fieldset">{radioItem}</div>
    </div>
  )
}

function NonVenerableOption(props) {
  const { nonVenerableQuestions, setQuestion, selectedVulnerableQuestions, age, onlyBankDeposit } = props
  const selectedNonVenerableQuestions =
    selectedVulnerableQuestions !== undefined
      ? selectedVulnerableQuestions.filter((item) => item !== 'isVenerableClient')
      : selectedVulnerableQuestions

  const handleClick = (optValue) => () => {
    const isNotAllowEdit = optValue === 'noAnyKnowledge' && onlyBankDeposit
    if (optValue !== 'olderThan60' && !isNotAllowEdit)
      setQuestion(_.xor(selectedNonVenerableQuestions || [], [optValue]))
  }
  const isChecked = (optValue) => _.includes(selectedNonVenerableQuestions, optValue)

  const radioItem = nonVenerableQuestions.map((option, index) => {
    const disabled = option.value === 'olderThan60' && age < 60
    const disabledText = option.value === 'olderThan60' || (option.value === 'noAnyKnowledge' && onlyBankDeposit)

    return (
      <span key={`${index}`}>
        <label className="custom-control custom-checkbox" key={`${index}`} htmlFor={`${option.value}`}>
          <input
            disabled={disabled}
            className="custom-control-input"
            type="checkbox"
            name="radio"
            checked={isChecked(option.value)}
            id={`${option.value}`}
            onChange={handleClick(option.value)}
          />
          <span className="custom-control-indicator" />
          <span className={disabledText ? 'text-muted' : ''}>{option.text}</span>
        </label>
      </span>
    )
  })

  return (
    <div className="form-group form-group--radio form-group--radio-with-other">
      <div>{radioItem}</div>
    </div>
  )
}

function StartSellingButton(props) {
  const { onExit, sellingMode, selectedVulnerableQuestions, isAllowRemoteSelling, buyWithAdvisor } = props
  const vc = getToggles().ENABLE_FNA_PHASE2_VC_DIALOG ? !_.isEmpty(selectedVulnerableQuestions) : true
  const buyWithAdvisorValidate = getToggles().ENABLE_FNA_V3 ? !_.isEmpty(buyWithAdvisor) : true
  const css =
    (isAllowRemoteSelling && getToggles().ENABLE_FNA_PHASE2_REMOTE_SELLING_DIALOG
      ? getToggles().ENABLE_APPMAN_REMOTE_SELLING
        ? true
        : !_.isEmpty(sellingMode)
      : true) &&
    vc &&
    buyWithAdvisorValidate
      ? 'primary'
      : 'btn btn-disabled'
  return (
    <div style={{ textAlign: 'center' }}>
      <Button
        color={css}
        className={css}
        onClick={() => {
          onExit()
          sellingMode === 'remote' ? props.startRemoteSelling() : props.startStandardSelling()
        }}
      >
        ยืนยัน
      </Button>
    </div>
  )
}

function DialogModalHeader(props) {
  const { onExit, isAllowRemoteSelling } = props
  return (
    <ModalHeader
      toggle={() => {
        props.setProcessingSuccess()
        onExit()
      }}
    >
      {isAllowRemoteSelling && getToggles().ENABLE_FNA_PHASE2_REMOTE_SELLING_DIALOG
        ? getToggles().ENABLE_FNA_V3
          ? 'คำรับรองของผู้ขอเอาประกันภัย'
          : 'รูปแบบวิธีการขาย'
        : null}
    </ModalHeader>
  )
}

function DialogRemoteSellingBody(props) {
  return (
    <div>
      {getToggles().ENABLE_FNA_V3 && <RemoteSellingOptionV3 {...props} />}
      {!getToggles().ENABLE_APPMAN_REMOTE_SELLING && (
        <div>
          {getToggles().ENABLE_FNA_V3 && (
            <div className="default-bootstrap remote-selling-modal modal-brand-primary">
              <b>รูปแบบวิธีการขาย</b>
            </div>
          )}
          <FormGroup className="has-warning">
            <FormFeedback className={'eligibility-message attached-icon'}>
              <div>
                เนื่องด้วยสถานการณ์การแพร่ระบาดของโรคติดเชื้อไวรัสโคโรนา 2019 (COVID-19) ทางบริษัทฯ
                จึงได้ออกแบบระบบที่รองรับการขายแบบ Digital Remote Selling
                เพื่อช่วยอำนวยความสะดวกแก่ลูกค้าและสนับสนุนการทำงานของท่านเป็นการชั่วคราว
              </div>
            </FormFeedback>
          </FormGroup>
          <p>
            <b>โดยมีเงื่อนไขดังนี้</b>
          </p>
          <ol>
            <li>ใช้เฉพาะกรณีไม่สามารถเข้าพบลูกค้าได้เท่านั้น </li>
            <li>ต้องแนบภาพบัตรประชาชนของผู้เอาประกัน พร้อมระบุข้อความยืนยันตามที่บริษัทฯ กำหนด ผ่าน Advisor Zone</li>
            <li>ต้องแนบภาพเซลฟี่ของผู้เอาประกันตามรูปแบบที่บริษัทฯ กำหนด ผ่าน Advisor Zone</li>
            <li>
              แจ้งลูกค้าว่าทางบริษัทฯ จะติดต่อเพื่อยืนยันการสมัคร ก่อนการพิจารณา (เฉพาะแบบประกันควบการลงทุนเท่านั้น)
            </li>
          </ol>
          อ่านข้อมูลเพิ่มเติมเกี่ยวกับ{' '}
          <a target="_blank" href="https://ktaxa.live/digital-remote-selling">
            Digital Remote Selling
          </a>{' '}
          (แบบลูกค้าไม่สะดวกให้เข้าพบ)
          <br />
          <p className="text-danger">**กรณีตรวจสอบพบว่าดำเนินการเลือกรูปแบบการขายผิด บริษัทฯ ขอสงวนสิทธิในการพิจารณา</p>
          <RemoteSellingOption {...props} />
        </div>
      )}
    </div>
  )
}

function DialogNewVcBody(props) {
  const { isAllowRemoteSelling } = props
  return (
    <div className="form-group">
      {!(isAllowRemoteSelling && getToggles().ENABLE_FNA_PHASE2_REMOTE_SELLING_DIALOG) &&
        getToggles().ENABLE_FNA_V3 && <RemoteSellingOptionV3 {...props} />}
      <div className="default-bootstrap remote-selling-modal modal-brand-primary">
        <b>การพิจารณาลักษณะของผู้ขอเอาประกันภัย</b>
      </div>
      <p />
      <div>ผู้เสนอขายสอบถามข้อมูลหรือพิจารณาลักษณะของผู้ขอเอาประกันภัยจากการพูดคุย โต้ตอบ สื่อสาร</div>
      <div>หากผู้ขอเอาประกันภัยมีลักษณะดังต่อไปนี้ ให้ถือว่าเป็นลูกค้าเปราะบาง</div>
      <p />
      <div className="default-bootstrap remote-selling-modal modal-brand-primary">
        <b>กรุณาทำเครื่องหมาย ☑ หากผู้ขอเอาประกันภัยมีลักษณะดังกล่าว</b>
      </div>
      <div className="validation-message attached-icon info-message-wrap form-control-feedback">
        <p>หน้าที่ของตัวแทน/นายหน้า/ที่ปรึกษาทางการเงิน (ผู้เสนอขาย)</p>
      </div>
      <p />
      <div className="default-bootstrap remote-selling-modal modal-brand-primary">
        ผู้ขอเอาประกันภัยมีลักษณะที่สันนิษฐานได้ว่าไม่มีลักษณะหรือเข้าข่ายเงื่อนไขกลุ่มลูกค้าเปราะบาง
      </div>
      <p />
      <VenerableOption {...props} />

      <div className="default-bootstrap remote-selling-modal modal-brand-primary">
        ผู้ขอเอาประกันภัยมีลักษณะที่สันนิษฐานได้ว่าเป็นลูกค้ากลุ่มเปราะบาง ให้พิจารณาลักษณะหรือเข้าข่ายเงื่อนไข ดังนี้
      </div>
      <p />
      <NonVenerableOption {...props} />
    </div>
  )
}

// class _RemoteSellingVcPopup extends React.Component {}
export const RemoteSellingVcPopup = ({ notificationMessage, onExit, ...props }) => {
  const { isAllowRemoteSelling } = props
  return (
    <Modal
      isOpen={notificationMessage !== 'None'}
      backdrop={true}
      size="lg"
      modalClassName="default-bootstrap remote-selling-modal"
      // toggle={() => {
      //   props.setProcessingSuccess()
      //   onExit()
      // }}
    >
      <DialogModalHeader {...props} onExit={() => onExit()} />
      <ModalBody>
        {isAllowRemoteSelling && getToggles().ENABLE_FNA_PHASE2_REMOTE_SELLING_DIALOG ? (
          <DialogRemoteSellingBody {...props} />
        ) : null}

        {isAllowRemoteSelling &&
        getToggles().ENABLE_FNA_PHASE2_REMOTE_SELLING_DIALOG &&
        getToggles().ENABLE_FNA_PHASE2_VC_DIALOG ? (
          <hr className="hr-dash-grey" />
        ) : null}

        {(isAllowRemoteSelling && getToggles().ENABLE_FNA_PHASE2_VC_DIALOG) || !isAllowRemoteSelling ? (
          <DialogNewVcBody {...props} />
        ) : null}

        <StartSellingButton {...props} onExit={() => onExit()} />
      </ModalBody>
    </Modal>
  )
}

const mapStateToRemoteSellingPopupProps = (state: AppState) => {
  const productILP = isILPProduct(state)
  const bankDeposit = isOnlyBankDeposit(getQuestionnaire(state))

  const venerableQuestions = getVenerableQuestions(state)
  let nonVenerableQuestions = getNonVenerableQuestions(state)
  if (getToggles().ENABLE_VCFORM_ILPORD) {
    if (productILP) {
      nonVenerableQuestions = getILPNonVenerableQuestions(state)
    } else {
      nonVenerableQuestions = getORDNonVenerableQuestions(state)
    }
  }
  const sellingMode = getSellingMode(state)
  const buyWithAdvisor = getBuyWithAdvisor(state)
  const age = getAge(state).value
  const onlyBankDeposit = getToggles().ENABLE_VCFORM_ILPORD && productILP && bankDeposit
  let selectedVulnerableQuestions = getSelectedVulnerableQuestions(state)

  if (age >= 60) {
    selectedVulnerableQuestions = _.union(getSelectedVulnerableQuestions(state), ['olderThan60'])
  }
  if (onlyBankDeposit) {
    selectedVulnerableQuestions = _.union(getSelectedVulnerableQuestions(state), ['noAnyKnowledge'])
  }

  const isAllowRemoteSelling = getIsAllowRemoteSelling(state)
  const remoteSelling = isSelectedRemoteSelling(state)

  const user = getIdentityUser(state)
  const enteringIproData = _.get(state, 'optyEntering')
  const insuredData = _.get(state, 'insured')
  const isSocial = getIsSocial(state)
  const userAgentType = getUserAgentType(state)

  return {
    venerableQuestions,
    nonVenerableQuestions,
    selectedVulnerableQuestions,
    sellingMode,
    buyWithAdvisor,
    age,
    isAllowRemoteSelling,
    remoteSelling,
    onlyBankDeposit,
    user,
    enteringIproData,
    insuredData,
    isSocial,
    userAgentType,
  }
}

const mapDispatchToRemoteSellingPopupProps = (dispatch: Dispatch<*>) => ({
  startStandardSelling: bindActionCreators(startStandardSelling, dispatch),
  startRemoteSelling: bindActionCreators(startRemoteSelling, dispatch),
  setProcessingSuccess: bindActionCreators(setProcessingSuccess, dispatch),
  setQuestion: bindActionCreators(setFnaVcQuestion, dispatch),
  setSellingMode: bindActionCreators(setFnaSellingMode, dispatch),
  setBuyWithAdvisor: bindActionCreators(setFnaBuyWithAdvisor, dispatch),
  setIsSocial: bindActionCreators(setFnaIsSocial, dispatch),
})

export default compose(
  connect(mapStateToRemoteSellingPopupProps, mapDispatchToRemoteSellingPopupProps),
  lifecycle({
    componentDidMount() {
      const { setQuestion, onlyBankDeposit, selectedVulnerableQuestions } = this.props
      if (onlyBankDeposit) {
        setQuestion(_.union(selectedVulnerableQuestions, ['noAnyKnowledge']))
      }
    },
  })
)(RemoteSellingVcPopup)
