// @flow
import _ from 'lodash'
import { formatNumber } from 'core/service/lib/number-format'
import { buildStacks, buildColumn, buildStackWithMultipleValues } from 'core/service/pdf-generation/utils/builder'
import { getRiderSummaryTitle } from 'core/service/pdf-generation/products/common/logic'
import { renderBIAge } from 'core/service/insured/age'
import Mustache from 'mustache'
import defaults from 'core/data-model/constants/defaults'
import BIMESSAGES from 'core/data-model/constants/bi-messages'
import BIRIDER from 'core/data-model/constants/bi-rider'
import MESSAGES from 'core/data-model/constants/messages'
import VALUES from 'core/data-model/constants/values'
import {
  DCIBenefitSection,
  CPRBenefitSection,
  HICBenefitSection,
  MEAPlusBenefitSection,
  MEHBenefitSection,
  MEBBenefitSection,
  APEcareBenefitSection,
  ESCIWPEBenefitSection,
  IGROWBenefitSection,
  FHBenefitSection,
  MEKBenefitSection,
  MEXBenefitSection,
  MHPBenefitSection,
  AdditionalRiderDisclaimerSection,
  CIUDRBenefitSection,
} from 'core/service/pdf-generation/products/common/sections/riders'
import {
  getRiderYearsOfCoverage,
  getRiderYearsOfPayment,
  getTotalPremiumOfRiderAndModules,
} from 'core/service/rider/benefits/benefit-data'

import type { Rider, RiderState } from 'core/data-model/rider'
import type { RiderBenefitTables } from 'core/service/rider/benefits'
import { agentProtectionDisclaimer } from '../logic'
import { translatePlanToThai } from 'core/service/rider/benefits/benefit-data/fh'
import { getToggles } from 'quick-quote/feature-toggles'
import { Section } from 'core/service/pdf-generation/products/common/components/general'
import {
  tpdudrBenefitSection,
  tpdudrNoticeSection,
  tpdudrDisclaimerSection,
} from 'core/service/pdf-generation/products/common/sections/riders/tpdudr-benefit-section'
import {
  hbudrBenefitSection,
  hbudrNoticeSection,
  hbudrDisclaimerSection,
} from 'core/service/pdf-generation/products/common/sections/riders/hbudr-benefit-section'
import {
  hsudrBenefitSection,
  hsudrSecondPageBenefitSection,
  hsudrThirdPageBenefitSection,
  hsudrForthPageBenefitSection,
  hsudrEndorsementBenefitSection,
  hsudrNoticeSection,
  hsudrDisclaimerSection,
  hsudrCriticalListSection,
} from 'core/service/pdf-generation/products/common/sections/riders/hsudr-benefit-section'

type Props = {
  riders: (Rider & RiderState)[],
  riderBenefitData: ?RiderBenefitTables,
}

type Insured = {
  firstName: string,
  lastName: string,
  age: number,
  genderLabel: string,
}

const isApOrEcare = (rider: Rider & RiderState): boolean => {
  return rider.code === VALUES.AP || rider.code === VALUES.ECARE
}

const getWPECodeByToggle = () => {
  return getToggles().ENABLE_ICARE_REPRICING === true ? VALUES.WPEIP85 : VALUES.WPEWL85
}

export const ridersSections = ({ riders, riderBenefitData, insured, sumAssured, isReviseQQ }: Props) => {
  for (let rider of riders) {
    if (_.includes(defaults.PROXY_RIDERS, rider.code)) {
      const riderBenefit = getRiderBenefitData(VALUES.IGROW, riderBenefitData)
      return [buildProxyRiderBenefitSection({ ...rider, code: VALUES.IGROW }, riderBenefit)]
    }
  }

  return _.reduce(
    riders,
    (riders, rider) => {
      if (rider.code === VALUES.WPEWL85 || rider.code === VALUES.WPEIP85) {
        return riders
      }
      if (rider.code === VALUES.ECARE && _.some(riders, ['code', VALUES.AP])) {
        return riders
      }

      return riders.concat(rider)
    },
    []
  ).map((rider, index) => {
    switch (rider.code) {
      case VALUES.ESCI:
        const esciRiderBenefit = getRiderBenefitData(rider.code, riderBenefitData)
        const WPECode = getWPECodeByToggle()
        const WPERider = _.find(riders, { code: WPECode })
        const esciAndWPERider = _.clone(rider)
        esciAndWPERider.premium = rider.premium + _.get(WPERider, 'premium', 0)
        return getToggles().ENABLE_ICARE_REPRICING
          ? buildEsciWpeBenefitSection({ esciRider: rider, wpeRider: WPERider }, esciRiderBenefit)
          : buildRiderBenefitSection(esciAndWPERider, esciRiderBenefit)

      case VALUES.ECARE:
      case VALUES.AP:
        const apEcareRiders = riders.filter((rider) => isApOrEcare(rider))
        const apEcareBenefitData = getRiderBenefitData(apEcareRiders[0].code, riderBenefitData)
        return buildApEcareBenefitSection(apEcareRiders, apEcareBenefitData)
      case VALUES.FH:
        const fhRiderBenefit = getRiderBenefitData(rider.code, riderBenefitData)
        return buildFHRiderBenefitSection(rider, fhRiderBenefit)
      case VALUES.TPDUDR:
        const tpdudrRiderBenefit = getRiderBenefitData(rider.code, riderBenefitData)
        return buildTPDUDRBenefitSection(rider, tpdudrRiderBenefit, insured, isReviseQQ)
      case VALUES.CIUDR:
        const ciudrRiderBenefit = getRiderBenefitData(rider.code, riderBenefitData)
        return buildCIUDRBenefitSection(rider, ciudrRiderBenefit, insured, isReviseQQ)
      case VALUES.HSUDR:
        const hsudrRiderBenefit = getRiderBenefitData(rider.code, riderBenefitData)
        return buildHSUDRBenefitSection(rider, hsudrRiderBenefit, insured)
      case VALUES.HBUDR:
        const hbudrRiderBenefit = getRiderBenefitData(rider.code, riderBenefitData)
        return buildHBUDRBenefitSection(rider, hbudrRiderBenefit, insured)
      case VALUES.MEX:
        const MEXRiderBenefit = getRiderBenefitData(rider.code, riderBenefitData)
        return buildMEXBenefitSection(rider, MEXRiderBenefit, insured)
      case VALUES.MHP:
        const MHPRiderBenefit = getRiderBenefitData(rider.code, riderBenefitData)
        return buildMHPBenefitSection(rider, MHPRiderBenefit, insured)
      default:
        const riderBenefit = getRiderBenefitData(rider.code, riderBenefitData)
        return buildRiderBenefitSection(rider, riderBenefit, index, sumAssured)
    }
  })
}

export const convertToData = (rider: Rider & RiderState) => {
  const precision = rider.showSumAssuredDecimalPlaces ? 2 : 0
  const title = getRiderSummaryTitle(rider)
  return {
    title: `${title}`,
    code: rider.code,
    yearsOfCoverage: rider.yearsOfCoverage,
    yearsOfPayment: rider.yearsOfPayment,
    sumAssured: formatNumber(rider.sumAssured, precision),
    premium: formatNumber(getTotalPremiumOfRiderAndModules(rider), 2),
  }
}

export const buildSumAssuredText = (rider: Rider & RiderState, sumAssured: string) => {
  const planName = _.get(rider, 'selectedPlan.plan')
  const code = _.get(rider, 'code')
  const isDisplayMessage = _.includes(
    _.compact([
      VALUES.WP,
      VALUES.WPD,
      VALUES.WPDD,
      VALUES.WPTPD,
      VALUES.WPTPDCI,
      VALUES.PBPDD,
      VALUES.PBPDDCI,
      VALUES.PBSDD,
      VALUES.PBSDDCI,
      getToggles().ENABLE_ICARE_REPRICING && VALUES.WPEIP85,
    ]),
    code
  )

  if (isDisplayMessage) {
    return BIMESSAGES.SUMMARY_TABLE_WP_SUMASSURED
  } else if (code === VALUES.ESCI) {
    return `${BIMESSAGES.PLAN} ${sumAssured}`
  } else if (code === VALUES.HBUDR || code === VALUES.HSUDR) {
    return `${BIMESSAGES.PLAN} ${sumAssured.replace(/,/g, '')}`
  } else if (code === VALUES.MEH) {
    return `${BIMESSAGES.PLAN} ${planName}`
  } else if (code === VALUES.MHP) {
    let addtional = ''
    switch (_.get(rider, 'selectedPlan.planOptional', '')) {
      case VALUES.MHP_CO_PAYMENT:
        addtional = ' ' + BIMESSAGES.MHP_BI_CO_PAYMENT
        break
      case VALUES.MHP_DEDUCTIBLE:
        addtional = ' ' + BIMESSAGES.MHP_BI_DEDUCTIBLE
        break
    }
    return `${BIMESSAGES.PLAN} ${planName}${addtional}`
  } else if (code === VALUES.FH) {
    return `${BIMESSAGES.PLAN} ${translatePlanToThai(_.get(rider, 'selectedPlan.plan'))}`
  } else {
    return `${sumAssured} ${MESSAGES.BAHT}`
  }
}

export const buildRiderBenefitSummary = (rider: Rider & RiderState, isFirstWPD: Boolean) => {
  const data = convertToData(rider)
  const sumAssuredText = buildSumAssuredText(rider, data.sumAssured)
  // if WPD is the first rider, will go to new page not append to productBenefits
  const pageBreak = isFirstWPD ? { text: '', pageBreak: 'before' } : {}
  return [
    pageBreak,
    {
      text: data.title,
      style: 'sub-title',
    },
    {
      columns: buildStacks([
        {
          label: BIMESSAGES.COVERAGE_PERIOD,
          value: `${getRiderYearsOfCoverage(rider)} ${MESSAGES.TIME_UNIT_YEAR}`,
        },
        {
          label: BIMESSAGES.PAYMENT_PERIOD,
          value: `${getRiderYearsOfPayment(rider)} ${MESSAGES.TIME_UNIT_YEAR}`,
        },
        {
          label: BIMESSAGES.SUM_ASSURED,
          value: sumAssuredText,
        },
        {
          label: BIMESSAGES.PREMIUM,
          value: `${data.premium} ${MESSAGES.BAHT}`,
        },
      ]),
      style: 'info',
    },
  ]
}

export const buildRPUDRRiderBenefitSummary = (rider: Rider & RiderState, insured: Insured, isReviseQQ) => {
  const data = convertToData(rider)
  const sumAssuredText = buildSumAssuredText(rider, data.sumAssured)

  return [
    {
      columns: buildColumn([
        {
          label: `${BIMESSAGES.INSURED_FULLNAME}:`,
          value: `${insured.firstName} ${insured.lastName}`,
          width: '40%',
        },
        {
          label: BIMESSAGES.GENDER,
          value: `${insured.genderLabel}`,
          width: '15%',
        },
        {
          label: BIMESSAGES.AGE,
          value: renderBIAge(insured?.age, isReviseQQ),
          width: '15%',
        },
        {
          label: BIMESSAGES.SUM_ASSURED,
          value: sumAssuredText,
          width: '30%',
        },
      ]),
      style: 'info',
    },
  ]
}

export const buildHBUDRRiderBenefitSummary = (rider: Rider & RiderState, insured: Insured) => {
  const data = convertToData(rider)
  const sumAssuredText = buildSumAssuredText(rider, data.sumAssured)

  return [
    {
      columns: buildColumn([
        {
          label: `${BIMESSAGES.INSURED_FULLNAME}:`,
          value: `${insured.firstName} ${insured.lastName}`,
          width: '40%',
        },
        {
          label: BIMESSAGES.GENDER,
          value: `${insured.genderLabel}`,
          width: '15%',
        },
        {
          label: BIMESSAGES.AGE,
          value: `${insured.age.value} ${MESSAGES.TIME_UNIT_YEAR}`,
          width: '15%',
        },
        {
          label: '',
          value: sumAssuredText,
          width: '30%',
        },
      ]),
      style: 'info',
    },
  ]
}

export const buildProxyRiderBenefitSummary = (rider: Rider & RiderState) => {
  return [
    {
      text: BIMESSAGES.RIDERS_BENEFITS_IGROW,
      style: 'title',
    },
  ]
}

export const buildApEcareExceptionNotes = (riders: (Rider & RiderState)[]) => {
  const riderCode = riders.map((r) => r.code).length === 1 ? riders.map((r) => r.code)[0] : ''
  const exceptionNotes = BIRIDER.EXCEPTION_NOTED_AP_ECARE
  const [sub_header_ap, sub_header_ecare, exceptions] = exceptionNotes.exceptions
  const [title_ap, title_ecare] = exceptionNotes.title

  let title
  let result = {
    stack: [{ text: BIMESSAGES.EXCEPTION_NOTE, style: 'sub-title', margin: [0, 12, 0, 0] }],
  }
  switch (riderCode) {
    case 'AP':
      result = {
        stack: [result.stack, { text: sub_header_ap }],
      }
      title = title_ap
      break

    case 'ECARE':
      result = {
        stack: [result.stack, { text: sub_header_ecare }],
      }
      title = title_ecare
      break

    default:
      result = {
        stack: [result.stack, { text: sub_header_ap + ' และ' }, { text: sub_header_ecare }],
      }
      title = title_ecare
      break
  }

  result = {
    stack: [result.stack, { text: title }, { ol: exceptions }],
  }
  return result
}

export const buildApEcareBenefitSummary = (
  riders: (Rider & RiderState)[],
  _buildRiderBenefitSummary: Function = buildRiderBenefitSummary
) => {
  if (riders.length === 1) {
    return _buildRiderBenefitSummary(riders[0])
  }
  const firstData = convertToData(riders[0])
  const secondData = convertToData(riders[1])
  return [
    {
      text: BIMESSAGES.RIDER_SUMMARY_TITLE_AP_ECARE,
      style: 'sub-title',
    },
    {
      columns: buildStackWithMultipleValues([
        {
          label: ' ',
          values: [`${riders[0].code}`, `${riders[1].code}`],
        },
        {
          label: BIMESSAGES.COVERAGE_PERIOD,
          values: [
            `${getRiderYearsOfCoverage(firstData)} ${MESSAGES.TIME_UNIT_YEAR}`,
            `${getRiderYearsOfCoverage(secondData)} ${MESSAGES.TIME_UNIT_YEAR}`,
          ],
        },
        {
          label: BIMESSAGES.PAYMENT_PERIOD,
          values: [
            `${getRiderYearsOfPayment(firstData)} ${MESSAGES.TIME_UNIT_YEAR}`,
            `${getRiderYearsOfPayment(secondData)} ${MESSAGES.TIME_UNIT_YEAR}`,
          ],
        },
        {
          label: BIMESSAGES.SUM_ASSURED,
          values: [`${firstData.sumAssured} ${MESSAGES.BAHT}`, `${secondData.sumAssured} ${MESSAGES.BAHT}`],
        },
        {
          label: BIMESSAGES.PREMIUM,
          values: [`${firstData.premium} ${MESSAGES.BAHT}`, `${secondData.premium} ${MESSAGES.BAHT}`],
        },
      ]),
      style: 'info',
    },
  ]
}

export const buildEsciWpeBenefitSummary = (
  riders: (Rider & RiderState)[],
  _buildRiderBenefitSummary: Function = buildRiderBenefitSummary
) => {
  if (riders.length === 1) {
    return _buildRiderBenefitSummary(riders[0])
  }
  const firstData = convertToData(riders[0])
  const secondData = convertToData(riders[1])
  return [
    {
      text: BIRIDER.RIDER_ESCI_2020_RIDER_BENEFIT_TITLE,
      style: 'sub-title',
    },
    {
      columns: buildStackWithMultipleValues([
        {
          label: ' ',
          values: [`${riders[0].code}`, `${riders[1].code}`],
        },
        {
          label: BIMESSAGES.COVERAGE_PERIOD,
          values: [
            `${getRiderYearsOfCoverage(firstData)} ${MESSAGES.TIME_UNIT_YEAR}`,
            `${getRiderYearsOfCoverage(secondData)} ${MESSAGES.TIME_UNIT_YEAR}`,
          ],
        },
        {
          label: BIMESSAGES.PAYMENT_PERIOD,
          values: [
            `${getRiderYearsOfPayment(firstData)} ${MESSAGES.TIME_UNIT_YEAR}`,
            `${getRiderYearsOfPayment(secondData)} ${MESSAGES.TIME_UNIT_YEAR}`,
          ],
        },
        {
          label: BIMESSAGES.SUM_ASSURED,
          values: [`${firstData.sumAssured} ${MESSAGES.BAHT}`, BIMESSAGES.SUMMARY_TABLE_WP_SUMASSURED],
        },
        {
          label: BIMESSAGES.PREMIUM,
          values: [`${firstData.premium} ${MESSAGES.BAHT}`, `${secondData.premium} ${MESSAGES.BAHT}`],
        },
      ]),
      style: 'info',
    },
  ]
}

const buildRiderBenefitContent = (rider, riderBenefitData, sumAssured) => {
  let riderBenefitContent = []
  let additionalDisclaimers = []
  let pageBreakAfterDisclaimer = false
  let hasAgentDisclaimer = true
  switch (rider.code) {
    case 'WP':
      riderBenefitContent = [
        Mustache.render(BIMESSAGES.WP_BENEFIT, {
          riderCoveragePeriod: rider.coveragePeriod.value,
        }),
      ]
      break
    case 'WPTPD':
      riderBenefitContent = [
        BIMESSAGES.WPTPD_BENEFIT,
        sumAssured > rider.defaultSumAssured ? [' ', BIMESSAGES.PB_BENEFIT_REMARK] : '',
      ]
      break
    case 'WPTPDCI':
      riderBenefitContent = [
        BIMESSAGES.WPTPDCI_BENEFIT_BI,
        sumAssured > rider.defaultSumAssured ? [' ', BIMESSAGES.PB_BENEFIT_REMARK] : '',
      ]
      break
    case 'WPD':
      riderBenefitContent = [BIMESSAGES.WPD_BENEFIT]
      break
    case 'WPDD':
      riderBenefitContent = [
        {
          stack: [
            BIMESSAGES.WPDD_BENEFIT_DEATH_PERMANENTLY_DISABLED,
            {
              ul: [
                BIMESSAGES.WPDD_BENEFIT_PREMIUM_WAIVER,
                Mustache.render(BIMESSAGES.WPDD_BENEFIT_AGE_CLAUSE, {
                  riderCoveragePeriod: rider.coveragePeriod.value,
                }),
              ],
            },
          ],
        },
      ]
      break
    case 'PBPDD':
      riderBenefitContent = [
        BIMESSAGES.PBPDD_RIDER_DETAIL_PDF,
        sumAssured > rider.defaultSumAssured ? [' ', BIMESSAGES.PB_BENEFIT_REMARK] : '',
      ]
      break
    case 'PBPDDCI':
      riderBenefitContent = [
        BIMESSAGES.PBPDDCI_RIDER_DETAIL_PDF,
        BIMESSAGES.PB_RIDER_ADDITIONAL_DETAIL_PDF,
        sumAssured > rider.defaultSumAssured ? [' ', BIMESSAGES.PB_BENEFIT_REMARK] : '',
      ]
      break
    case 'PBSDD':
      riderBenefitContent = [
        BIMESSAGES.PBSDD_RIDER_DETAIL_PDF,
        sumAssured > rider.defaultSumAssured ? [' ', BIMESSAGES.PB_BENEFIT_REMARK] : '',
      ]
      break
    case 'PBSDDCI':
      riderBenefitContent = [
        BIMESSAGES.PBSDDCI_RIDER_DETAIL_PDF,
        BIMESSAGES.PB_RIDER_ADDITIONAL_DETAIL_PDF,
        sumAssured > rider.defaultSumAssured ? [' ', BIMESSAGES.PB_BENEFIT_REMARK] : '',
      ]
      break
    case 'PLR':
      riderBenefitContent = [
        Mustache.render(BIMESSAGES.PLR_PLS_BENEFIT, {
          riderCode: rider.selectedPlan.planCode,
          riderYearsOfCoverage: rider.yearsOfCoverage,
        }),
      ]
      break
    case 'PLS':
      riderBenefitContent = [
        Mustache.render(BIMESSAGES.PLR_PLS_BENEFIT, {
          riderCode: rider.selectedPlan.planCode,
          riderYearsOfCoverage: rider.yearsOfCoverage,
        }),
      ]
      break
    case 'DCI': {
      riderBenefitContent = DCIBenefitSection({
        rider,
        riderBenefitData: riderBenefitData.riderBenefitData,
        showDisclaimer: riderBenefitData.showDisclaimer,
      })
      additionalDisclaimers.push(AdditionalRiderDisclaimerSection(rider))
      break
    }
    case 'CPR': {
      riderBenefitContent = CPRBenefitSection({
        rider,
        riderBenefitData: riderBenefitData.riderBenefitData,
        showDisclaimer: riderBenefitData.showDisclaimer,
      })
      additionalDisclaimers.push(AdditionalRiderDisclaimerSection(rider))
      break
    }
    case 'WPC':
    case 'WPC25PL':
    case 'WPCWL60':
    case 'WPCWL99': {
      riderBenefitContent = [
        Mustache.render(BIMESSAGES.WPC_BENEFIT, {
          riderCode: rider.selectedPlan.planCode,
          riderYearsOfCoverage: rider.yearsOfCoverage,
        }),
      ]
      break
    }
    case 'HIC': {
      riderBenefitContent = HICBenefitSection({
        rider,
        riderBenefitData: riderBenefitData.riderBenefitData,
      })
      additionalDisclaimers.push(AdditionalRiderDisclaimerSection(rider))
      break
    }
    case 'MEAPlus': {
      riderBenefitContent = MEAPlusBenefitSection({
        rider,
        riderBenefitData: riderBenefitData.riderBenefitData,
      })
      additionalDisclaimers.push(AdditionalRiderDisclaimerSection(rider))
      break
    }
    case 'MEH': {
      riderBenefitContent = MEHBenefitSection({
        rider,
        riderBenefitData: riderBenefitData.riderBenefitData,
      })
      pageBreakAfterDisclaimer = true
      break
    }
    case 'MEK': {
      riderBenefitContent = MEKBenefitSection({
        rider,
        riderBenefitData: riderBenefitData.riderBenefitData,
        sumAssured: sumAssured,
      })
      additionalDisclaimers.push(AdditionalRiderDisclaimerSection(rider))
      break
    }
    case VALUES.IGROW: {
      riderBenefitContent = IGROWBenefitSection({
        rider,
        riderBenefitData: riderBenefitData.riderBenefitData,
      })
      break
    }
    case 'MEB': {
      riderBenefitContent = MEBBenefitSection({
        rider,
        riderBenefitData: riderBenefitData.riderBenefitData,
      })
      additionalDisclaimers.push(AdditionalRiderDisclaimerSection(rider))
      break
    }
    case VALUES.WPEWL85:
    case VALUES.WPEIP85:
      break
    case VALUES.ESCI: {
      riderBenefitContent = ESCIWPEBenefitSection({
        ESCIRider: rider,
        riderBenefitData: riderBenefitData.riderBenefitData,
      })
      pageBreakAfterDisclaimer = false
      break
    }
    case VALUES.AP:
    case VALUES.ECARE: {
      hasAgentDisclaimer = false
      riderBenefitContent = APEcareBenefitSection({
        riderBenefitData: riderBenefitData.riderBenefitData,
      })
      break
    }
    case 'FH': {
      riderBenefitContent = FHBenefitSection({
        rider,
        riderBenefitData: riderBenefitData.riderBenefitData,
      })
      break
    }
    default:
      riderBenefitContent = []
      break
  }
  return pageBreakAfterDisclaimer
    ? [
        ...riderBenefitContent,
        // $FlowFixMe
        hasAgentDisclaimer ? agentProtectionDisclaimer(rider) : [],
        {
          text: '',
          pageBreak: 'before',
          margin: [0, -20, 0, 0],
        },
        ...additionalDisclaimers,
      ]
    : // $FlowFixMe
      [...riderBenefitContent, hasAgentDisclaimer ? agentProtectionDisclaimer(rider) : [], ...additionalDisclaimers]
}
const buildFHRiderBenefitSection = (rider, riderBenefitData) => {
  return {
    stack: [...buildRiderBenefitSummary(rider), ...buildRiderBenefitContent(rider, riderBenefitData)],
    id: 'breakBeforeRow',
    style: 'groupRow',
  }
}

const buildMEXBenefitSection = (rider, riderBenefitData, insured) => ({
  stack: [
    ...buildRiderBenefitSummary(rider, false),
    ...MEXBenefitSection({ rider, riderBenefitData: riderBenefitData.riderBenefitData, insured }),
    agentProtectionDisclaimer(rider),
    AdditionalRiderDisclaimerSection(rider),
    ...buildExceptionNoteContent(rider, riderBenefitData),
  ],
  id: 'breakBeforeRow',
  style: 'groupRow',
})

const buildMHPBenefitSection = (rider, riderBenefitData, insured) => ({
  stack: [
    ...buildRiderBenefitSummary(rider, false),
    ...MHPBenefitSection({ rider, riderBenefitData: riderBenefitData.riderBenefitData, insured }),
    agentProtectionDisclaimer(rider),
    AdditionalRiderDisclaimerSection(rider, BIMESSAGES.MEDICAL_FEE + rider.description + ' (' + rider.code + ') '),
    ...buildExceptionNoteContent(rider, riderBenefitData),
  ],
  id: 'breakBeforeRow',
  style: 'groupRow',
})

const buildRiderBenefitSection = (rider, riderBenefitData, index, sumAssured) => ({
  stack: [
    ...buildRiderBenefitSummary(rider, index === 0 && rider.code === VALUES.WPD),
    ...buildRiderBenefitContent(rider, riderBenefitData, sumAssured),
    ...buildExceptionNoteContent(rider, riderBenefitData),
  ],
  id: 'breakBeforeRow',
  style: 'groupRow',
})

const buildExceptionNoteContent = (rider, RiderBenefitData) => {
  let riderExceptionNoteContent = []
  let exceptionNotes
  let sub_header
  let exceptionNotesInfo
  switch (rider.code) {
    case 'WPDD':
    case 'WP':
      exceptionNotes = rider.code === 'WPDD' ? BIRIDER.EXCEPTION_NOTED_WPDD01 : BIRIDER.EXCEPTION_NOTED_WP

      sub_header = exceptionNotes.exceptions[0]
      exceptionNotesInfo = exceptionNotes.exceptions[1]
      riderExceptionNoteContent = [
        {
          stack: [
            { text: BIMESSAGES.EXCEPTION_NOTE, style: 'sub-title', margin: [0, 12, 0, 0] },
            { text: sub_header },
            { text: exceptionNotes.title },
            { ol: exceptionNotesInfo },
          ],
        },
      ]
      break

    case 'WPD':
      exceptionNotes = BIRIDER.EXCEPTION_NOTED_WPD01
      sub_header = exceptionNotes.exceptions[0]
      riderExceptionNoteContent = [
        {
          stack: [
            { text: BIMESSAGES.EXCEPTION_NOTE, style: 'sub-title', margin: [0, 12, 0, 0] },
            { text: sub_header },
            { text: exceptionNotes.title },
          ],
        },
      ]
      break

    case 'WPTPD':
      exceptionNotes = BIRIDER.EXCEPTION_NOTED_WPTPD
      sub_header = exceptionNotes.exceptions[0]
      exceptionNotesInfo = exceptionNotes.exceptions[1]
      riderExceptionNoteContent = [
        {
          stack: [
            { text: BIMESSAGES.EXCEPTION_NOTE, style: 'sub-title', margin: [0, 12, 0, 0] },
            { text: sub_header },
            { text: exceptionNotes.title },
            { ol: exceptionNotesInfo.slice(0, 7) },
            { ol: exceptionNotesInfo[7], margin: [12, 0, 0, 0] },
          ],
        },
      ]
      break

    case 'WPTPDCI':
      exceptionNotes = BIRIDER.EXCEPTION_NOTED_WPTPDCI
      sub_header = exceptionNotes.exceptions[0]
      exceptionNotesInfo = exceptionNotes.exceptions[1]
      const exceptionNotesInfoCI = exceptionNotes.exceptionsCI
      riderExceptionNoteContent = [
        {
          stack: [
            { text: BIMESSAGES.EXCEPTION_NOTE, style: 'sub-title', margin: [0, 12, 0, 0] },
            { text: sub_header },
            { text: exceptionNotes.title },
            { ol: exceptionNotesInfo.slice(0, 7) },
            { ol: exceptionNotesInfo[7], margin: [12, 0, 0, 0] },
            { text: ' ' },
            { text: exceptionNotes.titleCI },
            { ol: exceptionNotesInfoCI },
          ],
        },
      ]
      break

    case 'PBPDD':
    case 'PBSDD': {
      exceptionNotes = BIRIDER.EXCEPTION_NOTED_PBPDD_PBSDD
      const [
        firstExceptionTitle,
        firstExceptionDetail,
        secondExceptionTitle,
        secondExceptionDetail,
        secondExceptionDetailExtend,
      ] = exceptionNotes.exceptions
      sub_header = exceptionNotes.exceptions[0]
      exceptionNotesInfo = exceptionNotes.exceptions[1]
      riderExceptionNoteContent = [
        {
          stack: [
            { text: BIMESSAGES.EXCEPTION_NOTE, style: 'sub-title', margin: [0, 12, 0, 0] },
            { text: firstExceptionTitle },
            { ol: firstExceptionDetail },
            { text: secondExceptionTitle, margin: [0, 10, 0, 0] },
            { ol: secondExceptionDetail },
            { ol: secondExceptionDetailExtend, margin: [15, 0, 0, 0] },
          ],
        },
      ]
      break
    }

    case 'PBPDDCI':
    case 'PBSDDCI': {
      exceptionNotes = BIRIDER.EXCEPTION_NOTED_PBPDDCI_PBSDDCI
      const [
        firstExceptionTitle,
        firstExceptionDetail,
        secondExceptionTitle,
        secondExceptionDetail,
        secondExceptionDetailExtend,
        thirdExceptionTitle,
        thirdExceptionDetail,
      ] = exceptionNotes.exceptions
      sub_header = exceptionNotes.exceptions[0]
      exceptionNotesInfo = exceptionNotes.exceptions[1]
      riderExceptionNoteContent = [
        {
          stack: [
            { text: BIMESSAGES.EXCEPTION_NOTE, style: 'sub-title', margin: [0, 12, 0, 0] },
            { text: firstExceptionTitle },
            { ol: firstExceptionDetail },
            { text: secondExceptionTitle, margin: [0, 10, 0, 0] },
            { ol: secondExceptionDetail },
            { ol: secondExceptionDetailExtend, margin: [15, 0, 0, 0] },
            { text: thirdExceptionTitle, margin: [0, 60, 0, 0] },
            { ol: thirdExceptionDetail },
          ],
        },
      ]
      break
    }
  }
  return riderExceptionNoteContent
}

const buildEsciWpeBenefitSection = ({ esciRider, wpeRider }, riderBenefitData) => ({
  stack: [
    ...buildEsciWpeBenefitSummary([esciRider, wpeRider]),
    ...buildRiderBenefitContent(esciRider, riderBenefitData),
  ],
  id: 'breakBeforeRow',
  style: 'groupRow',
})

const buildProxyRiderBenefitSection = (rider, riderBenefitData) => ({
  stack: [
    // $FlowFixMe
    ...buildProxyRiderBenefitSummary(rider),
    // $FlowFixMe
    ...buildRiderBenefitContent(rider, riderBenefitData),
  ],
  id: 'breakBeforeRow',
  style: 'groupRow',
})

const buildTPDUDRBenefitSection = (rider, riderBenefitData, insured, isReviseQQ) => {
  const data = convertToData(rider)
  const benefitSummary = buildRPUDRRiderBenefitSummary(rider, insured, isReviseQQ)
  const tpdudrBenefit = tpdudrBenefitSection(riderBenefitData)
  const tpdudrNotice = tpdudrNoticeSection(riderBenefitData)
  const tpdudrDisclaimer = tpdudrDisclaimerSection(riderBenefitData)
  return Section(data.title, [benefitSummary, tpdudrBenefit, tpdudrDisclaimer, tpdudrNotice])
}

const buildCIUDRBenefitSection = (rider, riderBenefitData, insured, isReviseQQ) => {
  const data = convertToData(rider)
  const { title } = data
  const benefitSummary = [buildRPUDRRiderBenefitSummary(rider, insured, isReviseQQ)]
  const benefitContent = CIUDRBenefitSection({ title, benefitSummary, riderBenefitData })
  return Section(title, benefitContent)
}

const buildHBUDRBenefitSection = (rider, riderBenefitData, insured) => {
  const data = convertToData(rider)
  const benefitSummary = buildHBUDRRiderBenefitSummary(rider, insured)
  const hbudrBenefit = hbudrBenefitSection(rider, riderBenefitData)
  const hbudrNotice = hbudrNoticeSection(riderBenefitData, data.title, benefitSummary)
  const hbudrDisclaimer = hbudrDisclaimerSection(riderBenefitData)
  return Section(data.title, [benefitSummary, hbudrBenefit, hbudrDisclaimer, hbudrNotice])
}

const buildHSUDRBenefitSection = (rider, riderBenefitData, insured) => {
  const data = convertToData(rider)
  const benefitSummary = buildHBUDRRiderBenefitSummary(rider, insured)
  const hsudrBenefit = hsudrBenefitSection(rider, riderBenefitData)
  const hsudrSecondBenefit = hsudrSecondPageBenefitSection(rider, riderBenefitData, data.title, benefitSummary)
  const hsudrThirdBenefit = hsudrThirdPageBenefitSection(rider, riderBenefitData, data.title, benefitSummary)
  const hsudrForthBenefit = hsudrForthPageBenefitSection(rider, riderBenefitData, data.title, benefitSummary)
  const hsudrEndorseBenefit = hsudrEndorsementBenefitSection(rider, riderBenefitData, data.title, benefitSummary)
  const hsudrNotice = hsudrNoticeSection(riderBenefitData, benefitSummary, data.title)
  const hsudrDisclaimer = hsudrDisclaimerSection(riderBenefitData, benefitSummary, data.title)
  const hsudrCriticalList = hsudrCriticalListSection(riderBenefitData, benefitSummary, data.title)
  return Section(data.title, [
    benefitSummary,
    hsudrBenefit,
    hsudrSecondBenefit,
    hsudrThirdBenefit,
    hsudrForthBenefit,
    hsudrEndorseBenefit,
    hsudrNotice,
    hsudrDisclaimer,
    hsudrCriticalList,
  ])
}

const buildApEcareBenefitSection = (riders, riderBenefitData) => {
  const isAPEcare = riders.length === 2
  return {
    stack: [
      ...buildApEcareBenefitSummary(riders),
      ...buildRiderBenefitContent(riders[0], riderBenefitData),
      buildApEcareExceptionNotes(riders),
      agentProtectionDisclaimer(riders[0]),
      AdditionalRiderDisclaimerSection(riders[0], isAPEcare ? 'อุบัติเหตุและค่ารักษาพยาบาล (AP & ECARE) ' : null),
    ],
    id: 'breakBeforeRow',
    style: 'groupRow',
  }
}

const getRiderBenefitData = (riderCode, riderBenefitData) => _.get(riderBenefitData, riderCode)
