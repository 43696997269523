// @flow

import CONSTANTS from 'core/data-model/constants/values'

const { HATS_AND_TAILS } = CONSTANTS
const ESTIMATED_THAI_CHARACTER_WIDTH = 11
const ESTIMATED_ENGLISH_CHARACTER_WIDTH = 13

export const calculateFontSizeToFitComponent = (
  text: string,
  componentWidth: number,
  defaultFontSize: number,
  isThaiText: boolean
) => {
  let fontSize = defaultFontSize
  const estimatedNameWidth = isThaiText ? textWidthThaiWord(text) : textWidthEnglishWord(text)
  if (estimatedNameWidth > componentWidth) {
    let proportion = componentWidth / estimatedNameWidth
    fontSize = proportion * defaultFontSize
  }
  return fontSize
}

export const textWidthThaiWord = (text: string) => {
  return textWidth(text, ESTIMATED_THAI_CHARACTER_WIDTH)
}

export const textWidthEnglishWord = (text: string) => {
  return textWidth(text, ESTIMATED_ENGLISH_CHARACTER_WIDTH)
}

const textWidth = (text: string, characterWidth: number) => {
  return text.split('').filter((char) => HATS_AND_TAILS.indexOf(char) < 0).length * characterWidth
}
