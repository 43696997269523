// @flow

import type { AppState } from 'quick-quote/reducers'
import { transformBenefitSummary } from 'core/service/benefit-illustration/benefit-summary'
import { formatNumber } from 'core/service/lib/number-format'
import { connect } from 'react-redux'
import { getBenefitSummary } from 'quick-quote/product-whole-life/benefit-illustration/selectors'
import MESSAGES from 'core/data-model/constants/bi-messages'

type BenefitSummaryTableProps = {
  benefitSummary: BenefitSummary,
}
export const _BenefitSummaryTable = ({ benefitSummary }: BenefitSummaryTableProps) => (
  <div className="group" id="benefit-summary">
    <h3>{MESSAGES.BENEFIT_SUMMARY}</h3>
    <div className="details-table">
      <table>
        <thead>
          <tr>
            <td>{MESSAGES.BENEFIT_LIST}</td>
            <td>{MESSAGES.BENEFIT_BAHT}</td>
          </tr>
        </thead>
        {transformBenefitSummary(benefitSummary).map(([caseLabel, benefitByCauses]) => (
          <BenefitSummaryByCase
            key={caseLabel}
            caseLabel={caseLabel}
            benefitSummaryByCausesOrAmount={benefitByCauses}
          />
        ))}
      </table>
      <div className="disclaimer">
        <p>**{MESSAGES.DISCLAIMER_FORCE_MAJEURE}</p>
      </div>
    </div>
  </div>
)

type BenefitSummaryByCaseProps = {
  caseLabel: string,
  benefitSummaryByCausesOrAmount: *,
}

export const BenefitSummaryByCase = ({ caseLabel, benefitSummaryByCausesOrAmount }: BenefitSummaryByCaseProps) => {
  const amountForCase = typeof benefitSummaryByCausesOrAmount === 'number' ? benefitSummaryByCausesOrAmount : 0

  const benefitSummaryByCauses =
    typeof benefitSummaryByCausesOrAmount !== 'number' ? benefitSummaryByCausesOrAmount : []

  return (
    <tbody>
      <tr>
        <CaseLabel caseLabel={caseLabel} />
        {amountForCase === 0 ? <td /> : <Amount amount={amountForCase} />}
      </tr>
      {benefitSummaryByCauses.map(([cause, amount]) => (
        <BenefitSummary key={cause} cause={cause} amount={amount} />
      ))}
    </tbody>
  )
}

const BenefitSummary = ({ cause, amount }) => (
  <tr>
    <CauseLabel cause={cause} />
    <Amount amount={amount} />
  </tr>
)

const CaseLabel = ({ caseLabel }) => <td>{MESSAGES.caseLabelMapping[caseLabel]}</td>

const CauseLabel = ({ cause }) => (
  <td>
    <ul>
      <li>{MESSAGES.causeLabelMapping[cause]}</li>
    </ul>
  </td>
)

const Amount = ({ amount }) => <td>{formatNumber(amount)}</td>
const mapStateToProps = (state: AppState): BenefitSummaryTableProps => ({
  benefitSummary: getBenefitSummary(state),
})
export default connect(mapStateToProps)(_BenefitSummaryTable)
