import _ from 'lodash'
import { getToggles } from 'quick-quote/feature-toggles'
import { createSectionCreator, createField } from './utils'
import { getAppConfig } from '../../../../deploy-env/app-config'
import {
  getInsuredOtherOccupation,
  getInsuredMainOccupation,
  getPayerMainOccupation,
  getAuraImpairment,
} from 'e-submission/apps/selectors'

const auraHealthDetails = (app, id) => {
  return _.compact([
    createField(`${id}.aura`, [
      {
        id: `${id}.aura`,
        c: 'Aura',
        required: true,
        p: {
          value: 'aura',
          mergeState: (state) => ({
            access_token: state.identity.user.access_token,
            current_appid: state.apps.currentAppId,
            application_bucket: getToggles().ENABLE_AUTHORISATION_CODE_FLOW
              ? getAppConfig().APPLICATION_BUCKET_URL_AUTHZ_CODE_FLOW
              : getAppConfig().APPLICATION_BUCKET_URL,
            occ_insured_main: getInsuredMainOccupation(state),
            occ_insured_other: getInsuredOtherOccupation(state),
            occ_payer_main: getPayerMainOccupation(state),
            auraImpairment: getAuraImpairment(state),
          }),
          placeHolder: 'คำถามสุขภาพ Aura',
        },
      },
    ]),
  ])
}

export default createSectionCreator(auraHealthDetails)
