import { requestWrapper } from 'e-submission/domain/data'
import { getToggles } from 'quick-quote/feature-toggles'

export const getOptyList = (user, agentCode) => {
  const payload = { agentCode }
  return requestWrapper.getRequest(`saleforce-ipro/opportunity-list`, user, payload).then(({ data }) => data)
}

export const getOptyDetail = (user, optyId) => {
  const payload = {
    optyId,
  }
  return requestWrapper.getRequest(`saleforce-ipro/opportunity-detail`, user, payload).then(({ data }) => data)
}

export const createIProData = (user, applicationId, payload) => {
  return requestWrapper.postRequest(`/iPro/application/${applicationId}`, user, payload).then(({ data }) => data)
}

// ** update function dont need response (JUST CALL API 005) **
export const updateIProData = (user, applicationId, stageName, payload) => {
  if (getToggles().ENABLE_OPTY_ERROR) {
    return
  }

  return requestWrapper
    .patchRequest(`saleforce-ipro/application/${applicationId}/stageName/${stageName}`, user, payload)
    .then(({ data }) => data)
}

export const updateIProEnteringData = (user, payload) => {
  return requestWrapper.patchRequest('saleforce-ipro/entering', user, payload).then(({ data }) => {
    return data
  })
}

export const getAppByOptyId = (user, optyId) => {
  const payload = {
    optyId,
  }
  return requestWrapper.getRequest(`saleforce-ipro/get-app-by-optyId`, user, payload).then(({ data }) => data)
}
