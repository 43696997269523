// @flow
import type { RiderOption, RiderPlan } from 'core/data-model/rider'
import { formatNumber } from 'core/service/lib/number-format'
import { Dropdown } from 'common-components'
import { Input } from 'reactstrap'
import _ from 'lodash'
import values from 'core/data-model/constants/values'
import NumberFormat from 'react-number-format'

type RiderSumAssuredProps = {
  riderCode: string,
  selectedPlanCode: string,
  sumAssured: number,
  riderOptions?: RiderOption[],
  showSumAssuredDecimalPlaces: boolean,
  isReadOnly: boolean,
  isSelected: boolean,
  isSelectable: boolean,
  editRiderSumAssured: (string, number) => void,
  editRiderSelectedPlan: (string, RiderPlan) => void,
  isSumAssureReadonly: boolean,
}

const RiderSumAssured = ({
  riderOptions,
  riderCode,
  sumAssured,
  selectedPlanCode,
  showSumAssuredDecimalPlaces,
  isReadOnly,
  isSelected,
  isSelectable,
  editRiderSumAssured,
  editRiderSelectedPlan,
  isSumAssureReadonly,
}: RiderSumAssuredProps) => {
  const renderRiderNumberInput = (readOnly: boolean) => (
    <NumberFormat
      //TODO: change rider-option-id when multiple options rider is implemented
      id={`rider-${riderCode}-option-0`}
      value={sumAssured}
      disabled={!isSelected || readOnly}
      maxLength="15"
      onChange={(e, values) => editRiderSumAssured(riderCode, values.value !== '' ? parseInt(values.value) : 0)}
      customInput={Input}
      thousandSeparator={true}
      allowNegative={false}
      readOnly={readOnly}
      type="tel"
      pattern="[0-9]*"
    />
  )

  const renderGreyOut = () => {
    if (!isSelectable) return <div className="value-pair">-</div>
    if (!isSelected) return <div className="value-pair">0</div>
  }

  const renderSumAssuredInput = () => {
    if (riderOptions && riderOptions[0].type) {
      if (!isSelectable)
        return (
          <div id={`rider-${riderCode}-option-0`} className="value-pair">
            -
          </div>
        )
      const riderOption = riderOptions[0]

      switch (riderOption.type) {
        case values.OPTION_ANY_VALUE: {
          return renderGreyOut() || renderRiderNumberInput(isReadOnly || isSumAssureReadonly)
        }
        case values.OPTION_FIXED_VALUE:
        case values.OPTION_FIXED_VALUE_FROM_LIST:
          return (
            renderGreyOut() || (
              <div id={`rider-${riderCode}-option-0`} className="value-pair">
                {showSumAssuredDecimalPlaces ? formatNumber(sumAssured, 2) : formatNumber(sumAssured)}
              </div>
            )
          )

        case values.OPTION_VALUE_FROM_LIST: {
          const displayOptions = _.map(riderOption.values, (option) => ({
            value: option.planCode,
            text: option.text,
          }))

          const selectedValue = isSelected ? selectedPlanCode : riderOption.values[0].planCode.toString()
          return (
            <Dropdown
              id={`rider-${riderCode}-option-0`}
              options={displayOptions}
              disabled={!isSelected || isReadOnly}
              value={selectedValue}
              onChange={(value) => {
                const selectedPlan = _.find(riderOption.values, { planCode: value })
                if (selectedPlan) {
                  editRiderSumAssured(riderCode, selectedPlan.value)
                  editRiderSelectedPlan(riderCode, { planCode: selectedPlan.planCode })
                }
              }}
            />
          )
        }
      }
    }
  }

  return (
    <div id={`rider-${riderCode}-options`} className="sum-assured">
      {renderSumAssuredInput()}
    </div>
  )
}

export default RiderSumAssured
