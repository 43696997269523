//@flow
import type { Profile, DistributorGroupType } from 'core/data-model/distributor'
import type { DisplayProduct } from 'core/service/display-product'
import type { InsuredProps } from 'core/service/pdf-generation/products/common/types'
import MESSAGES from 'core/data-model/constants/bi-messages'
import VALUES from 'core/data-model/constants/values'
import { signatureSectionAgent } from './signature-section-agent'
import { signatureSectionBanc } from './signature-section-banc'
import { getToggles } from 'quick-quote/feature-toggles'

type Props = {
  insured: InsuredProps,
  profile: Profile,
  basicPlan: DisplayProduct,
  distributorType: DistributorGroupType,
}

const buildSignatureSectionNote = {
  stack: [
    {
      text: MESSAGES.SIGN_NOTES.title,
      style: 'title',
      pageBreak: 'before',
    },
    {
      text: MESSAGES.SIGN_NOTES.remark,
      color: 'red',
    },
    {
      text: MESSAGES.SIGN_NOTES.notes[0],
    },
    {
      ol: MESSAGES.SIGN_NOTES.notes[1],
    },
  ],
}

const buildOrdinarySignatureSectionNote = {
  stack: [
    {
      text: MESSAGES.SIGN_NOTES_ORDINARY.title,
      style: 'title',
      pageBreak: 'before',
    },
    {
      text: MESSAGES.SIGN_NOTES_ORDINARY.remark,
      color: 'red',
    },
    {
      text: MESSAGES.SIGN_NOTES_ORDINARY.notes[0],
    },
    {
      ol: MESSAGES.SIGN_NOTES_ORDINARY.notes[1],
    },
  ],
}
const buildSignatureS7SectionNote = {
  stack: [
    {
      text: MESSAGES.SIGN_NOTES_S7.title,
      style: 'title',
      pageBreak: 'before',
    },
    {
      text: MESSAGES.SIGN_NOTES_S7.remark,
      color: 'red',
    },
    {
      text: MESSAGES.SIGN_NOTES_S7.notes[0],
    },
    {
      ol: MESSAGES.SIGN_NOTES_S7.notes[1],
    },
  ],
}
const buildSignatureMRTASectionNote = {
  stack: [
    {
      text: MESSAGES.SIGN_NOTES_MRTA.title,
      style: 'title',
      pageBreak: 'before',
    },
    {
      text: MESSAGES.SIGN_NOTES_MRTA.sub_title,
      bold: 'true',
      color: 'red',
    },
    {
      text: MESSAGES.SIGN_NOTES_MRTA.remark,
      color: 'red',
    },
    {
      text: MESSAGES.SIGN_NOTES_MRTA.notes[0],
    },
    {
      ol: MESSAGES.SIGN_NOTES_MRTA.notes[1],
    },
  ],
}
const getBuildSignatureSectionNote = (basicPlan: DisplayProduct) => {
  if (getToggles().ENABLE_SIGN_NOTES_ORDINARY) {
    return basicPlan.category === VALUES.MRTA ? buildSignatureMRTASectionNote : buildOrdinarySignatureSectionNote
  } else {
    return VALUES.S7_3PAY.concat(VALUES.S7_SINGLE).includes(basicPlan.basicPlanCode)
      ? buildSignatureS7SectionNote
      : buildSignatureSectionNote
  }
}

export const signatureSection = (props: Props) => {
  const { profile, basicPlan, distributorType, insured } = props
  if (distributorType === 'BANC')
    return signatureSectionBanc(profile, getBuildSignatureSectionNote(basicPlan), insured, basicPlan)
  else return signatureSectionAgent(profile, getBuildSignatureSectionNote(basicPlan), insured, basicPlan)
}
