import { compact, flow, get, has, isEmpty, map, noop, overEvery, size, times } from 'lodash/fp'
import {
  addFileProps,
  sendApplication,
  setDefaultDCA,
  updateConsentEdaTerms,
  updateReceipt,
  validateApplication,
} from 'e-submission/apps/actions'
import {
  canSubmit,
  getAllAdditionalDocument,
  getAllCreditCardRelationshipDocument,
  isCreditCardRelationshipDocumentNotEmpty,
  getFiles,
  getFileWithName,
  getPreviewMultipleDoc,
  isSectionValid,
  isConsentEdaComplete,
  isEDAViewFormOnly,
} from 'e-submission/apps/selectors'
import { createField, createSection, createSectionCreator } from 'e-submission/domain/data-model/form/utils'
import {
  allowToSubmitWithoutPayment,
  hasATP,
  isSelectedToPayNow,
  getDCA,
  isATPSubmitted,
  isATPPaid,
  isFormTypeDA,
  isFormTypeEDA,
  isAcceptConsentDA,
  isEDAConsent,
  isCheckStatusEDA,
  isDCASigned,
  isShowDCAForm,
  isShowDCASelected,
  isDCASelected,
  isRemoteSelling,
  isCreditCardRelationshipTier1,
  isFinishCreditCardPaymentSection,
  isSignedCreditCardPaymentSection,
  isPayerOther,
  isSignedEcbr,
  hasEvidenceUpload,
  getSelectedReceiptType,
  getReceiptDocumentType,
  getReceiptNo,
  isSelectedRemoteSelling,
  isAppEligibleToDoCCPA,
  isCCPAStatusCompleted,
  isCCPAStatusError,
  isQuickQuoteModelFactorMonthly,
} from 'e-submission/domain/data-model/form/selectors'
import { DOCUMENT_TYPE } from 'e-submission/domain/data-model/constants'
import { ATPSection } from 'e-submission/domain/data-model/payment/ATPSection'
import { CCPASection } from 'e-submission/domain/data-model/payment/CCPASection'
import { ConsentSection } from 'e-submission/domain/data-model/payment/consentSection'
import { CheckStatusSection } from 'e-submission/domain/data-model/payment/EDACheckStatusSection'

import { previewDocumentSection } from 'e-submission/domain/data-model/submit'
import { getSubmitWithoutPaymentField } from './submitWithoutPayment'
import paymentModalMergeState from './paymentModalMergeState'
import { isShowFirstPremium, shouldShowOptionsToPayLater } from './selectors'
import { getToggles } from 'quick-quote/feature-toggles'
import store from '../../../../store'
import { hasReceiptPaid, hasReceiptSigned } from '../selectors'
import { DCASection } from './DCASection'
import { cashDocumentSection } from 'e-submission/domain/data-model/payment/index_old'
import { getBasicPlanCode } from 'e-submission/domain/service'
import { documentEcbrNewName } from 'e-submission/utils/documents'

const mapWithKey = map.convert({ cap: false })
let basicPlanCode = []

export const shouldShowSubmitWithoutPaymentSection = (app) => {
  return getToggles().ENABLE_SUBMIT_WITHOUT_PAYMENT && allowToSubmitWithoutPayment(app)
}
export const evidentAttachmentOfPaymentReceiptText = ' (แนบหลักฐานการชำระเงิน)'
export const signedToTemporaryPaymentReceiptText = ' (ลงนามใบรับเงินชั่วคราว)'
export const hasPaidReceiptAndDisappearedEvidence = function(app) {
  return hasReceiptPaid(app) && !hasEvidenceUpload(app)
}
export const noSignedReceiptAndAppearedEvidence = function(app) {
  return !hasReceiptSigned(app) && hasEvidenceUpload(app)
}
export const hasPaidReceiptButNoSignedReceipt = function(app) {
  return hasReceiptPaid(app) && !hasReceiptSigned(app)
}
export const decisionOfFirstPremiumCardTitle = function(app) {
  if (hasPaidReceiptAndDisappearedEvidence(app)) {
    return evidentAttachmentOfPaymentReceiptText
  } else if (noSignedReceiptAndAppearedEvidence(app)) {
    return signedToTemporaryPaymentReceiptText
  } else {
    return ''
  }
}

export const getFirstPremiumCardTitle = (app) => {
  const selectedReceiptType = getSelectedReceiptType(app)
  if (
    getToggles().ENABLE_CHANGE_PAY_IN_ATTACHMENT_STEP &&
    (selectedReceiptType === 'net bank' || selectedReceiptType === 'edc')
  ) {
    return decisionOfFirstPremiumCardTitle(app)
  } else if (getToggles().ENABLE_CHANGE_PAY_IN_ATTACHMENT_STEP && selectedReceiptType === 'cash') {
    if (hasPaidReceiptButNoSignedReceipt(app)) {
      return signedToTemporaryPaymentReceiptText
    } else {
      return ''
    }
  } else {
    if (hasPaidReceiptButNoSignedReceipt(app)) {
      return signedToTemporaryPaymentReceiptText
    } else if (hasReceiptSigned(app)) {
      return evidentAttachmentOfPaymentReceiptText
    } else {
      return ''
    }
  }
}

export const shouldEnableBrowseDocument = (app) =>
  (getToggles().ENABLE_ALLOW_BROWSE_ADDTIONAL_DOC && isRemoteSelling(app)) || isSelectedRemoteSelling(app) || undefined

export const checkedIconAtpSection = function(checkedFlowArray, app) {
  return overEvery(checkedFlowArray)(app)
}

export const mapSectionToArray = function(section, name, app, additionalProps) {
  return [section(name)(app, name, additionalProps)]
}

export function checkPaymentOptional(app) {
  if (!isQuickQuoteModelFactorMonthly(app)) return true

  // if (isATPPaid(app) || isATPSubmitted(app)) return true
  //! Important [2778] the requirement change only submitted ATP, then enable submit button
  if (isATPSubmitted(app)) return true

  if (isCCPAStatusCompleted(app) || isCCPAStatusError(app)) return true

  return false
}

function findCurrentAppData() {
  const storeData = store.getState()
  const currentAppId = get('apps.currentAppId')(storeData)
  return get(`apps.byId.${currentAppId}`)(storeData)
}

const isShowRelationshipDocument = (app) => {
  if (getToggles().ENABLE_CREDIT_CARD_RELATIONSHIP) {
    if (getToggles().ENABLE_ECBR_SYSTEMIZATION) {
      return isSignedEcbr(app) && isPayerOther(app)
    }
    return (
      isCreditCardRelationshipTier1(app) &&
      isFinishCreditCardPaymentSection(app) &&
      isSignedCreditCardPaymentSection(app)
    )
  }
}

function renderingComponent(app, isEnableBrowseFile, isAppmanRemoteSelling, isShowATP, isShowCCPA, id) {
  // const currentApp = findCurrentAppData()

  // The ATP is DA Form
  const newDaTitle = getToggles().ENABLE_EDA ? ' (DA Form)' : ''
  const atpForm = {
    id: 'ATP',
    c: 'MoreInfoCard',
    p: {
      subpage: 'atp',
      title: `สมัครหักค่าเบี้ยประกันอัตโนมัติ ผ่านบัญชีธนาคาร${newDaTitle}`,
      indentTitle: true,
      mergeState: (state, app, section) => {
        let dispatch
        let history
        if (section) {
          dispatch = section.dispatch
          history = section.history
        }

        const isConsentDAChecked = isAcceptConsentDA(app)
        const isEDAConsentChecked = isEDAConsent(app)
        const isToggleEdaOffOrConsentDA = !getToggles().ENABLE_EDA || isConsentDAChecked // toggle off or consentDA already
        const sections = isToggleEdaOffOrConsentDA
          ? mapSectionToArray(ATPSection, 'ATP', app)
          : mapSectionToArray(ConsentSection, 'consent', app)

        const isAppliedATP = isATPPaid(app) || isATPSubmitted(app) || isCheckStatusEDA(app)
        const isAppliedATPonline = getToggles().ENABLE_EDA && isAppliedATP && isFormTypeEDA(app)
        const checkedValidation = checkedIconAtpSection(
          [isATPPaid, isATPSubmitted, isSectionValid(state, sections)],
          app
        )
        const checked = getToggles().ENABLE_EDA ? isFormTypeDA(app) && checkedValidation : checkedValidation
        const readConsentEdaComplete = isConsentEdaComplete(state)
        const isOtherPaymentHasBeenMade = isAppliedATPonline || isCCPAStatusCompleted(app) || isCCPAStatusError(app)

        const isCheckStatusEDAPaid = getToggles().ENABLE_EDA && isATPPaid(app) && isFormTypeEDA(app)

        return {
          sections: sections,
          icon: checked ? 'check' : '',
          //! TODO: For 2566, we need to check if CCPA or eDA has been completed
          disabled: isOtherPaymentHasBeenMade,
          checked,
          paymentOptional: checkPaymentOptional(app),

          className: isToggleEdaOffOrConsentDA ? '' : 'eda-consent',
          isEdaConsent: true,
          customTitle: isToggleEdaOffOrConsentDA
            ? 'สมัครหักค่าเบี้ยประกันอัตโนมัติ ผ่านบัญชีธนาคาร'
            : 'เงื่อนไขหักค่าเบี้ยประกันอัตโนมัติผ่านบัญชีธนาคาร',
          customButtons: isToggleEdaOffOrConsentDA
            ? []
            : [
                {
                  text: 'ถัดไป',
                  visible: true,
                  disabled: !isEDAConsentChecked,
                  onClick: () => {
                    dispatch(updateConsentEdaTerms())
                  },
                },
              ],
          okButton: isCheckStatusEDAPaid ? {} : null,
        }
      },
    },
  }

  const renderAtpAndEda = () => {
    if (isShowATP && getToggles().ENABLE_EDA) {
      return [
        {
          id: 'EDA',
          c: 'MoreInfoCard',
          p: {
            subpage: 'eda',
            title: 'สมัครหักค่าเบี้ยประกันอัตโนมัติ ผ่านบัญชีธนาคารแบบออนไลน์',
            indentTitle: true,
            mergeState: (state, app, section) => {
              let dispatch
              let history
              if (section) {
                dispatch = section.dispatch
                history = section.history
              }

              const isConsentDAChecked = isAcceptConsentDA(app)
              const isEDAConsentChecked = isEDAConsent(app)

              let sections = mapSectionToArray(ATPSection, 'EDA', app)
              if (!isConsentDAChecked) {
                sections = mapSectionToArray(ConsentSection, 'consent', app)
              }
              const isShowCheckStatusPage = isCheckStatusEDA(app) // TODO: checkEDAStatus pending in process
              if (isShowCheckStatusPage) {
                sections = mapSectionToArray(CheckStatusSection, 'checkStatus', app)
              }

              const isAppliedATP = isATPPaid(app) || isATPSubmitted(app)
              const isAppliedATPpaper = getToggles().ENABLE_EDA && isAppliedATP && isFormTypeDA(app)
              const isAppliedATPonline = getToggles().ENABLE_EDA && isAppliedATP && isFormTypeEDA(app)
              const onlyViewEDASummaryInForm = isAppliedATPonline && isEDAViewFormOnly(state)

              if (onlyViewEDASummaryInForm) {
                // show ATP form but disable all input field also document upload
                sections = mapSectionToArray(ATPSection, 'EDA', app, { isViewOnly: true })
              }

              const checkedValidation = checkedIconAtpSection(
                [isATPPaid, isATPSubmitted, isSectionValid(state, sections)],
                app
              )
              const checked = getToggles().ENABLE_EDA ? isFormTypeEDA(app) && checkedValidation : checkedValidation
              const readConsentEdaComplete = isConsentEdaComplete(state)
              const isOtherPaymentHasBeenMade =
                isAppliedATPpaper || isCCPAStatusCompleted(app) || isCCPAStatusError(app)

              return {
                sections: sections,
                className: isConsentDAChecked ? '' : 'eda-consent',
                icon: checked ? 'check' : '',
                disabled: isOtherPaymentHasBeenMade,
                checked,
                paymentOptional: checkPaymentOptional(app),
                isEdaConsent: true,
                customTitle: isConsentDAChecked
                  ? 'สมัครหักค่าเบี้ยประกันอัตโนมัติ ผ่านบัญชีธนาคาร'
                  : 'เงื่อนไขหักค่าเบี้ยประกันอัตโนมัติผ่านบัญชีธนาคาร',
                customButtons: isConsentDAChecked
                  ? []
                  : [
                      {
                        text: 'ถัดไป',
                        visible: true,
                        disabled: !isEDAConsentChecked,
                        onClick: () => {
                          dispatch(updateConsentEdaTerms())
                        },
                      },
                    ],
                isWarningEdaPaymentNeeded: true,
                okButton: isShowCheckStatusPage ? {} : undefined, // for custom check status page show only back button on footer
                isViewOnly: onlyViewEDASummaryInForm,
              }
            },
          },
        },
        atpForm,
      ]
    } else if (isShowATP && !getToggles().ENABLE_EDA) {
      return [atpForm]
    } else {
      return []
    }
  }

  return [
    !isAppmanRemoteSelling &&
      createField(
        `submitWithoutPayment-${shouldShowOptionsToPayLater(app)}`,
        shouldShowSubmitWithoutPaymentSection(app) ? getSubmitWithoutPaymentField(app) : []
      ),
    createField(
      `payment-${getFirstPremiumCardTitle(app)}`,
      compact([
        (!getToggles().ENABLE_SUBMIT_WITHOUT_PAYMENT || isShowFirstPremium(app)) &&
          !isAppmanRemoteSelling && {
            id: 'payment',
            c: 'MoreInfoCard',
            p: {
              subpage: 'payment',
              title: `ชำระเบี้ยงวดแรก${getFirstPremiumCardTitle(app)}`,
              mergeState: paymentModalMergeState,
            },
          },
      ]),
      {
        marginTopLess: true,
        pl: [0, 0, 2],
        pr: [0, 0, 2],
      }
    ),
    createField(
      `payment-optional`,
      compact([
        (isShowATP || isShowCCPA) && {
          id: 'QUICKLINK_MENU_TITLE',
          c: 'TitleCard',
          p: {
            title: 'ชำระเบี้ยงวดถัดไปอัตโนมัติ',
          },
        },
        ...renderAtpAndEda(),
        isShowCCPA && {
          id: 'CCPA',
          c: 'MoreInfoCard',
          p: {
            subpage: 'ccpa',
            title: 'สมัครหักค่าเบี้ยประกันอัตโนมัติ ผ่านบัตรเครดิต',
            indentTitle: true,
            mergeState: (state, app, section) => {
              let dispatch
              let history
              if (section) {
                dispatch = section.dispatch
                history = section.history
              }
              const sections = mapSectionToArray(CCPASection, 'CCPA', { ...app, dispatch }, section)
              const checked = isCCPAStatusCompleted(app) || isCCPAStatusError(app)
              const isDisable =
                isATPPaid(app) || isATPSubmitted(app) || (getToggles().ENABLE_EDA && isCheckStatusEDA(app))
              return {
                sections: sections,
                icon: checked ? 'check' : '',
                //! TODO: For 2566, we need to check if ATP or eDA has been completed
                disabled: isDisable,
                checked,
                paymentOptional: checkPaymentOptional(app),
                okButton: {
                  label: 'ยืนยัน',
                  disabled: !isCCPAStatusCompleted(app),
                  onClick: () => {
                    history.replace(history.location.pathname.replace('/ccpa', ''))
                  },
                },
              }
            },
          },
        },
      ]),
      {
        marginTopLess: true,
        pl: [0, 0, 2],
        pr: [0, 0, 2],
      }
    ),
    createField(
      'DCA',
      compact([
        isShowDCASelected(app) && {
          label: 'ลูกค้าประสงค์จะส่งหนังสือยินยอมขอรับ ผลประโยชน์ตามกรมธรรม์ ผ่านบัญชีธนาคาร (DCA) เมื่อใด',
          id: 'DCA.selected',
          c: 'RadioButtonWithDialog',
          required: true,
          p: {
            options: [
              {
                text: 'มีความประสงค์',
                value: true,
                openDialog: false,
              },
              {
                text: 'มีความประสงค์จะส่งเอกสารภายหลัง',
                value: false,
                openDialog: false,
              },
            ],
            onChange: ({ value, dispatch }) => {},
            subpage: 'DCA',
            title: 'ชำระเบี้ยงวดแรก',
          },
          mergeState: (state, app, { dispatch = noop, history } = {}) => {
            return {
              infoMessage:
                getToggles().ENABLE_DCA_OPTIONS_INFO_MESSAGE && !isDCASelected(app)
                  ? 'กรณีที่ลูกค้าไม่ส่งหนังสือยินยอมขอรับ ผลประโยชน์ตามกรรมธรรม์ ผ่านบัญชีธนาคาร ทางบริษัทขอสงวนสิทธิ การนำส่งในรูปแบบ Cheque แทน'
                  : '',
            }
          },
        },
        isShowDCAForm(app) && {
          id: 'DCA.form',
          c: 'MoreInfoCard',
          p: {
            subpage: 'DCA',
            title: 'ยินยอมขอรับ ผลประโยชน์ตามกรมธรรม์ ผ่านบัญชีธนาคาร (DCA)',
            mergeState: (state, app, { dispatch = noop, history } = {}) => {
              const checked = isDCASigned(app)
              return {
                sections: [DCASection('dca-form')(app, id)],
                icon: checked ? 'check' : '',
                onClick: () => {
                  const dca = getDCA(app)
                  if (_.isUndefined(_.get(dca, 'bank'))) dispatch(setDefaultDCA())
                },
                checked,
              }
            },
            infoMessage: getToggles().ENABLE_HIDE_DCA_MSG
              ? ''
              : 'กรุณาระบุบัญชีผู้เอาประกันภัยของ บมจ.ธนาคารกรุงไทย เท่านั้น',
          },
        },
      ]),
      {
        marginTopLess: true,
        pl: [0, 0, 2],
        pr: [0, 0, 2],
      }
    ),
    isShowRelationshipDocument(app) &&
      createField(
        'credit',
        [
          {
            id: 'insured.creditCardRelationshipDocument',
            c: 'MoreInfoCard',
            p: {
              subpage: 'credit-card-relationship-doc',
              icon: 'illustrate',
              mergeState: (state) => {
                const documentType = get('insured.CREDIT_CARD_RELATIONSHIP_DOCUMENT', DOCUMENT_TYPE)
                const documentTypeName = get('name', documentType)
                const documentTypeText = get('text', documentType)

                const creditCardRelationshipDocumentList = getAllCreditCardRelationshipDocument(state)
                const totalCreditCardRelationshipDoc = size(creditCardRelationshipDocumentList)
                const maxCreditCardRelationshipDoc = 10

                const photoCardField = createField('insured.creditCardRelationshipDocument.documents', [
                  {
                    id: 'insured.creditCardRelationshipDocument.documents',
                    c: 'PhotoCard',
                    required: true,
                    p: {
                      isBrowseFile: isEnableBrowseFile,
                      mergeState: (state, app) => {
                        const creditCardRelationshipDocumentList = getAllCreditCardRelationshipDocument(state)
                        return {
                          value: mapWithKey(
                            (document, index) => ({
                              id: `insured.creditCardRelationshipDocument.documents[${index}]`,
                              name: get('name', document),
                              description: get('description', document),
                              checked: true,
                              editable: true,
                            }),
                            creditCardRelationshipDocumentList
                          ),
                        }
                      },
                    },
                  },
                ])
                const addPhotoCardField = createField('insured.creditCardRelationshipDocument.add', [
                  {
                    id: 'insured.creditCardRelationshipDocument.add',
                    c: 'PhotoCard',
                    required: false,
                    p: {
                      isBrowseFile: isEnableBrowseFile,
                      mergeState: (state, app, { dispatch = noop } = {}) => {
                        const creditCardRelationshipDocumentList = getAllCreditCardRelationshipDocument(state)
                        const totalCreditCardRelationshipDoc = size(creditCardRelationshipDocumentList)
                        const description = flow(getFiles, get([documentTypeName, 'description']))(state)
                        return {
                          description,
                          disabled: isEmpty(description),
                          value:
                            totalCreditCardRelationshipDoc < maxCreditCardRelationshipDoc
                              ? [
                                  {
                                    id: `insured.creditCardRelationshipDocument.documents[${totalCreditCardRelationshipDoc}]`,
                                    name: documentTypeName,
                                    checked: false,
                                    editable: true,
                                    placeholder: 'กรุณากรอกข้อมูล',
                                    onChange: (value) => {
                                      dispatch(
                                        addFileProps(documentTypeName, {
                                          description: value,
                                        })
                                      )
                                    },
                                  },
                                ]
                              : [],
                        }
                      },
                    },
                  },
                ])
                const placeHolderFields = times((index) =>
                  createField(`insured.creditCardRelationshipDocument.placeHolder[${index}]`, [
                    {
                      id: `insured.creditCardRelationshipDocument.placeHolder[${index}]`,
                      c: 'PlaceHolderCard',
                    },
                  ])
                )(maxCreditCardRelationshipDoc - totalCreditCardRelationshipDoc - 1)
                const checked = isCreditCardRelationshipDocumentNotEmpty(state)

                return {
                  title: `${documentTypeText}`,
                  icon: checked ? 'check' : 'illustrate',
                  sections: [
                    createSection(
                      compact([
                        size(creditCardRelationshipDocumentList) > 0 && photoCardField,
                        addPhotoCardField,
                        ...placeHolderFields,
                      ])
                    ),
                  ],
                }
              },
            },
          },
        ],
        {
          marginTopLess: true,
          pl: [0, 0, 2],
          pr: [0, 0, 2],
        }
      ),
    createField(
      'document',
      compact([
        hasReceiptSigned(app) &&
          getSelectedReceiptType(app) === 'cash' &&
          getToggles().ENABLE_CHANGE_PAY_IN_ATTACHMENT_STEP && {
            id: 'bankSlip',
            c: 'MoreInfoPhotoCard',
            p: {
              isBrowseFile: isEnableBrowseFile,
              mergeState: (state, app, { dispatch = noop } = {}) => {
                const id = 'receipts[0]'
                const documentSection = cashDocumentSection(id)(app, id)
                const receipt = get(id, app)
                const receiptNo = getReceiptNo(receipt)
                const documentType = getReceiptDocumentType(receipt)
                const documentTypeName = documentEcbrNewName(documentType.name, receiptNo)
                const isSigned = hasReceiptSigned(app)
                const isUploaded = overEvery([
                  has(['_attachments', documentTypeName]),
                  isSectionValid(state, [documentSection]),
                ])(app)
                return {
                  onOk: () => {
                    if (isUploaded) {
                      dispatch(updateReceipt(id, documentTypeName))
                      return true
                    } else {
                      dispatch(validateApplication())
                      return false
                    }
                  },
                  value: [
                    {
                      icon: isUploaded ? 'check' : '',
                      sections: [documentSection],
                      name: documentTypeName,
                      title: 'ถ่ายรูปใบนําฝากเงินจาก ธนาคาร',
                      description: 'แนบหลักฐานการชำระเงิน',
                      checked: isUploaded,
                      disabled: !isSigned,
                    },
                  ],
                }
              },
            },
          },
        {
          id: 'document',
          c: 'MoreInfoCard',
          p: {
            subpage: 'preview-document',
            title: 'ดูเอกสารที่เซ็นไปแล้ว',
            sections: [previewDocumentSection('preview-document')(app)],
          },
        },
        {
          id: 'insured.additionalDocument',
          c: 'MoreInfoCard',
          p: {
            subpage: 'additional-document',
            icon: 'illustrate',
            mergeState: (state) => {
              const documentType = get('insured.ADDITIONAL_DOCUMENT', DOCUMENT_TYPE)
              const documentTypeName = get('name', documentType)
              const documentTypeText = get('text', documentType)

              const additionalDocumentList = getAllAdditionalDocument(state)
              const totalAdditionalDoc = size(additionalDocumentList)
              const maxAdditionalDoc = 10

              const photoCardField = createField('insured.additionalDocument.documents', [
                {
                  id: 'insured.additionalDocument.documents',
                  c: 'PhotoCard',
                  required: true,
                  p: {
                    isBrowseFile: isEnableBrowseFile,
                    mergeState: (state, app) => {
                      const additionalDocumentList = getAllAdditionalDocument(state)
                      return {
                        value: mapWithKey(
                          (document, index) => ({
                            id: `insured.additionalDocument.documents[${index}]`,
                            name: get('name', document),
                            description: get('description', document),
                            checked: true,
                            editable: true,
                          }),
                          additionalDocumentList
                        ),
                      }
                    },
                  },
                },
              ])
              const addPhotoCardField = createField('insured.additionalDocument.add', [
                {
                  id: 'insured.additionalDocument.add',
                  c: 'PhotoCard',
                  required: false,
                  p: {
                    isBrowseFile: isEnableBrowseFile,
                    mergeState: (state, app, { dispatch = noop } = {}) => {
                      const additionalDocumentList = getAllAdditionalDocument(state)
                      const totalAdditionalDoc = size(additionalDocumentList)
                      const description = flow(getFiles, get([documentTypeName, 'description']))(state)

                      return {
                        description,
                        disabled: isEmpty(description),
                        value:
                          totalAdditionalDoc < maxAdditionalDoc
                            ? [
                                {
                                  id: `insured.additionalDocument.documents[${totalAdditionalDoc}]`,
                                  name: documentTypeName,
                                  checked: false,
                                  editable: true,
                                  placeholder: 'กรุณากรอกข้อมูล',
                                  onChange: (value) => {
                                    dispatch(
                                      addFileProps(documentTypeName, {
                                        description: value,
                                      })
                                    )
                                  },
                                },
                              ]
                            : [],
                      }
                    },
                  },
                },
              ])
              const placeHolderFields = times((index) =>
                createField(`insured.additionalDocument.placeHolder[${index}]`, [
                  {
                    id: `insured.additionalDocument.placeHolder[${index}]`,
                    c: 'PlaceHolderCard',
                  },
                ])
              )(maxAdditionalDoc - totalAdditionalDoc - 1)

              return {
                title: `${documentTypeText} (ถ้ามี)`,
                sections: [
                  createSection(
                    compact([
                      size(additionalDocumentList) > 0 && photoCardField,
                      addPhotoCardField,
                      ...placeHolderFields,
                    ])
                  ),
                ],
              }
            },
          },
        },
        isAppmanRemoteSelling && {
          id: 'insured.additionalDocument',
          c: 'UploadMultipleDocCommand',
          p: {
            accept: '*',
            customText: 'อัปโหลดเอกสารเพิ่มเติมสำหรับผู้เอาประกัน',
            limit: 10,
            mergeState: (state, app) => {
              const documentType = get('insured.ADDITIONAL_DOCUMENT', DOCUMENT_TYPE)
              const documentTypeName = get('name', documentType)
              const additionalDocumentList = getAllAdditionalDocument(state)
              const selectedFile = getPreviewMultipleDoc(app)
              const insuredAdditionalDocList =
                additionalDocumentList && additionalDocumentList.filter((item) => item.isInsured)
              return {
                value: {
                  id: `insured.additionalDocument.documents`,
                  name: documentTypeName,
                  description: 'อัปโหลดเอกสารเพิ่มเติมสำหรับผู้เอาประกัน',
                  checked: insuredAdditionalDocList && insuredAdditionalDocList.length > 0,
                  documentList: insuredAdditionalDocList,
                  url: getFileWithName(state, selectedFile),
                  editable: true,
                  selectedFile,
                },
              }
            },
          },
        },
      ]),
      {
        marginTopLess: true,
        pl: [0, 0, 2],
        pr: [0, 0, 2],
      }
    ),
    createField('insured.submitApplication', [
      {
        id: 'insured.submitApplication',
        c: 'Button',
        p: {
          mergeState: (state, app, { dispatch, history }) => {
            return {
              disabled: !canSubmit(state) || get('remoteSelling.isUploading', app),
              onClick: () => {
                dispatch(sendApplication(history))
              },
            }
          },
          children: 'ส่งใบคำขอ',
          block: true,
          icon: 'wifi',
          style: {
            fontSize: '1.175rem',
            marginTop: '40px',
          },
        },
      },
    ]),
    createField(
      'insured.submitApplicationDiscliamer',
      [
        {
          id: 'insured.submitApplicationDiscliamer',
          c: 'MutedText',
          p: {
            children: 'กรุณาต่อ Internet เพื่อส่งใบคำขอ',
          },
        },
      ],
      {
        style: {
          marginTop: '-1rem',
        },
      }
    ),
  ]
}

function getQuestion(app, id, { dispatch }) {
  if (basicPlanCode.length === 0) {
    getBasicPlanCode().then((data) => {
      if (data instanceof Array) {
        basicPlanCode = data.map((o) => o.planCode)
      }
    })
  }
  const isShowCCPA = getToggles().ENABLE_CCPA_SUBSCRIPTION && isAppEligibleToDoCCPA(app, basicPlanCode)
  const isShowATP =
    hasATP(app) &&
    (getToggles().ENABLE_SUBMIT_WITHOUT_PAYMENT ||
      !allowToSubmitWithoutPayment(app) ||
      (allowToSubmitWithoutPayment(app) && isSelectedToPayNow(app)))
  const isEnableBrowseFile = shouldEnableBrowseDocument(app)
  const isAppmanRemoteSelling = isSelectedRemoteSelling(app)
  return compact(renderingComponent(app, isEnableBrowseFile, isAppmanRemoteSelling, isShowATP, isShowCCPA, id))
}

export default createSectionCreator(getQuestion)
