import { requestWrapper } from 'e-submission/domain/data'
import { base64ToBlob } from 'e-submission/utils'

export const submitCreateEcbrNo = (user, payload) => {
  return requestWrapper.postRequest(`ecbr-systemization/create-ecbr-no`, user, payload).then(({ data }) => data)
}

export const createDownloadEcbrFile = (user, payload) => {
  const ecbrNo = {
    ecbrNo: payload,
  }
  const url = `ecbr-systemization/download-file-ecbr`
  return requestWrapper
    .postRequest(url, user, ecbrNo)
    .then(({ data }) => data)
    .then(({ data, content }) => base64ToBlob(data || content, 'application/pdf'))
}
