import PropTypes from 'prop-types'
import syncScroll from '../../../../lib/sync-scroll'
import BIMESSAGES from '../../../../core/data-model/constants/bi-messages'
import {
  Entry,
  Headers,
  Heading,
  HeadingFixedGroup,
  HeadingGroup,
  HeadingGroupMaster,
  HeadingGroupSub,
  StickyTable,
  Track,
  Tracks,
  EntryFixed,
} from './StickyTable'
import { LumpSumInput, hasLumpSumError } from './expected-return-comparison-table'

class RPUDRTable extends React.Component {
  static propTypes = {
    formatCell: PropTypes.func,
    formatAccountValue: PropTypes.func,
    expectedReturnComparisons: PropTypes.array.isRequired,
    editLumpSum: PropTypes.func.isRequired,
  }

  static defaultProps = {
    formatCell: (...args) => args[0],
    formatAccountValue: (...args) => args[0],
  }

  constructor(props) {
    super(props)

    this.setHeaderRef = this.setHeaderRef.bind(this)
    this.setBodyRef = this.setBodyRef.bind(this)
    this.renderHeader = this.renderHeader.bind(this)
    this.renderBody = this.renderBody.bind(this)
    this.pixelize = this.pixelize.bind(this)
    this.dataColWidth = 140
  }

  componentDidMount() {
    syncScroll([this.header, this.body])
  }

  setHeaderRef(element) {
    this.header = element
  }

  setBodyRef(element) {
    this.body = element
  }

  pixelize(number) {
    return `${number}px`
  }

  renderHeader() {
    return (
      <Headers targetRef={this.setHeaderRef}>
        <HeadingFixedGroup style={{ flexBasis: '65px' }}>
          <Heading className="generic-column" style={{ flexBasis: '30px' }}>
            ปี
          </Heading>
          <Heading className="generic-column" style={{ flexBasis: '35px' }}>
            อายุ
          </Heading>
        </HeadingFixedGroup>
        <Heading style={{ flexBasis: this.pixelize(this.dataColWidth) }}>
          <div>
            <div>เบี้ยฯ สะสม</div>
            <div>(RPP + LSTU)</div>
          </div>
        </Heading>
        <HeadingGroup style={{ flexBasis: this.pixelize(this.dataColWidth * 2) }}>
          <HeadingGroupMaster>
            <Heading className="blue-column">อัตราผลตอบแทน -1%</Heading>
          </HeadingGroupMaster>
          <HeadingGroupSub>
            <Heading className="blue-column" style={{ flexBasis: this.pixelize(this.dataColWidth) }}>
              มูลค่ารับซื้อคืนหน่วยลงทุนรวมโบนัส
            </Heading>
            <Heading className="blue-column" style={{ flexBasis: this.pixelize(this.dataColWidth) }}>
              ความคุ้มครองกรณีเสียชีวิต
            </Heading>
          </HeadingGroupSub>
        </HeadingGroup>
        <HeadingGroup style={{ flexBasis: this.pixelize(this.dataColWidth * 2) }}>
          <HeadingGroupMaster>
            <Heading className="yellow-column">อัตราผลตอบแทน 2%</Heading>
          </HeadingGroupMaster>
          <HeadingGroupSub>
            <Heading className="yellow-column" style={{ flexBasis: this.pixelize(this.dataColWidth) }}>
              มูลค่ารับซื้อคืนหน่วยลงทุนรวมโบนัส
            </Heading>
            <Heading className="yellow-column" style={{ flexBasis: this.pixelize(this.dataColWidth) }}>
              ความคุ้มครองกรณีเสียชีวิต
            </Heading>
          </HeadingGroupSub>
        </HeadingGroup>
        <HeadingGroup style={{ flexBasis: this.pixelize(this.dataColWidth * 2) }}>
          <HeadingGroupMaster>
            <Heading className="red-column">อัตราผลตอบแทน 5%</Heading>
          </HeadingGroupMaster>
          <HeadingGroupSub>
            <Heading className="pink-column" style={{ flexBasis: this.pixelize(this.dataColWidth) }}>
              มูลค่ารับซื้อคืนหน่วยลงทุนรวมโบนัส
            </Heading>
            <Heading className="pink-column" style={{ flexBasis: this.pixelize(this.dataColWidth) }}>
              ความคุ้มครองกรณีเสียชีวิต
            </Heading>
          </HeadingGroupSub>
        </HeadingGroup>
        <HeadingGroup style={{ flexBasis: this.pixelize(this.dataColWidth * 2) }}>
          <HeadingGroupMaster>
            <Heading className="green-column">
              อัตราผลตอบแทน 2% <br />
              อัตราเงินเฟ้อทางการแพทย์ฯ 5%**
            </Heading>
          </HeadingGroupMaster>
          <HeadingGroupSub>
            <Heading className="green-column" style={{ flexBasis: this.pixelize(this.dataColWidth) }}>
              มูลค่ารับซื้อคืนหน่วยลงทุนรวมโบนัส
            </Heading>
            <Heading className="green-column" style={{ flexBasis: this.pixelize(this.dataColWidth) }}>
              ความคุ้มครองกรณีเสียชีวิต
            </Heading>
          </HeadingGroupSub>
        </HeadingGroup>
        <Heading style={{ flexBasis: '135px' }}>
          <div>
            <div>{BIMESSAGES.CASHFLOW_TABLE_PREMIUM_REGULAR_LUMPSUM[0]}</div>
            <div>{BIMESSAGES.CASHFLOW_TABLE_PREMIUM_REGULAR_LUMPSUM[1]} (LSTU)</div>
          </div>
        </Heading>
      </Headers>
    )
  }

  renderBody(expectedReturnComparisons, editLumpSum) {
    return (
      <Tracks targetRef={this.setBodyRef}>
        {expectedReturnComparisons.map((expectedReturnComparison, index) =>
          this.renderRow(index, expectedReturnComparison, editLumpSum)
        )}
      </Tracks>
    )
  }

  renderRow(index, expectedReturnComparison, editLumpSum) {
    const { year, age, accumulatedTotalPremium, benefitByExpectedReturn, lumpSum } = expectedReturnComparison
    const rowClassName = hasLumpSumError(lumpSum) ? 'has-danger' : ''

    return (
      <Track key={index} className={rowClassName} style={{ width: '1180px' }}>
        <EntryFixed style={{ flexBasis: '65px' }}>
          <Entry style={{ flexBasis: '30px' }}>{year}</Entry>
          <Entry style={{ flexBasis: '35px' }}>{age}</Entry>
        </EntryFixed>
        <Entry style={{ flexBasis: this.pixelize(this.dataColWidth) }}>
          {this.props.formatCell(accumulatedTotalPremium)}
        </Entry>
        <Entry className="blue-column" style={{ flexBasis: this.pixelize(this.dataColWidth) }}>
          {this.props.formatAccountValue(benefitByExpectedReturn['-1'].accountValueAfterBonus)}
        </Entry>
        <Entry className="blue-column" style={{ flexBasis: this.pixelize(this.dataColWidth) }}>
          {this.props.formatCell(benefitByExpectedReturn['-1'].deathBenefit)}
        </Entry>
        <Entry className="yellow-column" style={{ flexBasis: this.pixelize(this.dataColWidth) }}>
          {this.props.formatAccountValue(benefitByExpectedReturn['2'].accountValueAfterBonus)}
        </Entry>
        <Entry className="yellow-column" style={{ flexBasis: this.pixelize(this.dataColWidth) }}>
          {this.props.formatCell(benefitByExpectedReturn['2'].deathBenefit)}
        </Entry>
        <Entry className="pink-column" style={{ flexBasis: this.pixelize(this.dataColWidth) }}>
          {this.props.formatAccountValue(benefitByExpectedReturn['5'].accountValueAfterBonus)}
        </Entry>
        <Entry className="pink-column" style={{ flexBasis: this.pixelize(this.dataColWidth) }}>
          {this.props.formatCell(benefitByExpectedReturn['5'].deathBenefit)}
        </Entry>
        <Entry className="green-column" style={{ flexBasis: this.pixelize(this.dataColWidth) }}>
          {this.props.formatAccountValue(benefitByExpectedReturn['3'].accountValueAfterBonus)}
        </Entry>
        <Entry className="green-column" style={{ flexBasis: this.pixelize(this.dataColWidth) }}>
          {this.props.formatCell(benefitByExpectedReturn['3'].deathBenefit)}
        </Entry>
        <Entry className="red-column" style={{ flexBasis: '135px' }}>
          <LumpSumInput
            id={`lumpsum-topup-${index}`}
            year={year}
            lumpSum={lumpSum}
            editLumpSum={editLumpSum}
            forceDisallow={index === 0}
          />
        </Entry>
      </Track>
    )
  }

  render() {
    return (
      <StickyTable className={this.props.className}>
        {this.renderHeader()}
        {this.renderBody(this.props.expectedReturnComparisons, this.props.editLumpSum)}
      </StickyTable>
    )
  }
}

export default RPUDRTable
