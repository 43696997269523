// @flow
import { createSelector } from 'reselect'
import { get, getOr, isNumber } from 'lodash/fp'

import { getFna } from '../selectors'

const getConfiguration = (state) => state.fnaConfiguration
const getSfReference = (state) => state.sfReference

export const getNeeds = createSelector(getConfiguration, getOr([], 'needs'))

export const getQuestions = createSelector(getConfiguration, getOr([], 'needQuestions'))
export const getPlans = createSelector(getConfiguration, getOr([], 'needPlans'))

export const getNeedGap = createSelector(getFna, getOr({}, 'needGap'))
export const getNeedGapQuestion = createSelector(getNeedGap, getOr({}, 'questions'))
export const getNeedGapTargetSavings = createSelector(getNeedGapQuestion, get('targetSavings'))
export const getSelectedNeedType = createSelector(getNeedGap, getOr('', 'type'))
export const getSelectedPlanId = createSelector(getNeedGap, (need) => {
  return getOr('', 'planId')(need) || getOr('', 'type')(need)
})
export const getSelectedPlan = createSelector(getSelectedPlanId, getPlans, get)

export const getMarketConduct = createSelector(getFna, getOr({}, 'marketConduct'))
export const getMarketConductQuestionsType = createSelector(getMarketConduct, getOr({}, 'questionsType'))

export const getPlanByPlanId = (plans, planId) => {
  const selectedPlanId = planId
  return get(selectedPlanId, plans, {})
}

export const getQuestionByPlanId = (questions, plans, planId) => {}
export const needTypeWasSelected = createSelector(getSelectedNeedType, (needType) => !!needType)
export const canProceedToProductSelection = createSelector(needTypeWasSelected, (selectedNeedType) => selectedNeedType)
export const isCompletedNeedGap = createSelector(
  getSelectedNeedType,
  getSelectedPlan,
  getNeedGapTargetSavings,
  (needType, plan, targetSavings) => isNumber(targetSavings)
)

export const getStartSaleFrom = createSelector(getSfReference, getOr([], 'startSaleFrom'))
