//@flow
import type { LoginLimitExceeded } from '../notification/actions'

import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'

import { Modal } from '../../common-components'
import { goToManageLoginSession } from 'quick-quote/actions'
import { logoutFromIDP } from 'identity/service'

type LoginLimitExceededPopupProps = {
  notificationMessage: LoginLimitExceeded,
  onExit: Function,
  goToManageLoginSession: Function,
}

/**
 * To display message if current login user has active login session more that expected sessions.
 * The modal wont't allow user to continue on futher steps but logging out.
 */
// TODO: Implement goToManageLoginSession() (if needed). This will allow user to login from other session/devices.
// TODO: This modal should block futher steps. So, onExit() should be removed?
export const _LoginLimitExceededPopup = ({
  notificationMessage,
  logout,
  onExit,
  goToManageLoginSession,
  ...props
}: LoginLimitExceededPopupProps) => (
  <Modal
    isOpen={notificationMessage !== 'None'}
    backdrop={true}
    size="md"
    modalClassName="default-bootstrap login-session-limit-selling-modal"
  >
    <ModalHeader>การจัดการอุปกรณ์ใช้งาน</ModalHeader>
    <ModalBody>
      <p>
        บัญชีของท่านได้ใช้งานผ่านอุปกรณ์ <strong>ครบจำนวน 3 เครื่องแล้ว</strong>
      </p>
      <p>กรุณาออกจากระบบจากอุปกรณ์ที่ท่านไม่ได้ใช้งาน</p>
    </ModalBody>
    <ModalFooter>
      <div className="bottom">
        <Button
          color="outline-primary"
          onClick={() => {
            onExit()
            logout()
          }}
        >
          ออกจากระบบ
        </Button>
      </div>
    </ModalFooter>
  </Modal>
)

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  goToManageLoginSession: bindActionCreators(goToManageLoginSession, dispatch),
  logout: bindActionCreators(logoutFromIDP, dispatch),
})

export default withRouter(connect(null, mapDispatchToProps)(_LoginLimitExceededPopup))
