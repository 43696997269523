// @flow
import template from './template'
import { createPdfDocument } from 'core/service/pdf-generation/document-creation'
import { createCommonPdf } from '../common/pdf-document'
import { MRTAProps } from './index'

import { getContentFullContent } from './content'

export const createFullBIDocDefinition = async (props: MRTAProps) => {
  const pdfTemplate = await template({ ...props, biType: props.biType })
  return {
    content: await getContentFullContent(props),
    ...pdfTemplate,
  }
}

const createFullBIPdf = createPdfDocument(createFullBIDocDefinition)

export const createMRTAPdf = async (props: MRTAProps) => {
  return await createCommonPdf(props, createFullBIPdf)
}
