import styled from 'styled-components'
import { Flex } from 'rebass'
import Card from './card'
import Button from './button'

const Content = styled(Card)`
  border: 1px solid ${({ theme }) => theme.variables['$color-silver']};
  padding: 8px 16px;

  button {
    font-size: 0.8725rem;
    padding: 0.125rem 0.5rem;
  }

  .card-content {
    border-top: 1px solid ${({ theme }) => theme.variables['$color-mercury']};
    margin-top: 10px;
    padding-top: 10px;
    ul {
      padding-left: 15px;
    }
  }

  ${({ theme }) => theme.media.md`
    padding: 15px 30px;
  `}
`

const Title = styled(Flex)`
  padding: 5px 0;
  label {
    display: flex;
    flex: 1;
    margin: 0;
    align-self: center;
  }
  .btn-link {
    display: flex;
    align-items: center;
    text-decoration: underline;
    border: none;
    &:hover,
    &:active,
    &:focus {
      color: inherit;
      border: none;
      outline: none;
      box-shadow: none;
    }
    svg {
      margin-right: 3px;
      width: 30px;
      height: 30px;
    }
  }
`
const EditCard = ({ title, children, onClick, disabled, ...props }) => (
  <Content {...props} column>
    <Title>
      <label>
        <strong>{title}</strong>
      </label>
      <Button icon="edit" disabled={disabled} onClick={onClick}>
        แก้ไข
      </Button>
    </Title>
    {children ? <div className="card-content">{children}</div> : null}
  </Content>
)

export default EditCard
