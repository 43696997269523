// @flow
import type { Option } from 'common-components/dropdown'
import { Dropdown, Checkbox } from 'common-components'
import { FormFeedback, FormGroup } from 'reactstrap'

import values from 'core/data-model/constants/values'
import type { RiderPlan } from 'core/data-model/rider'

export type BasicPlanSelectionProps = {
  basicPlanOptions: Option[],
  selectedBasicPlanCode: string,
  basicPlanValidationError: string,
  isDiscountSelected: boolean,
  toggleDiscount: () => void,
  editRiderSelectedPlan: (string, RiderPlan) => void,
}

const BasicPlanSelection = ({
  basicPlanOptions,
  selectedBasicPlanCode,
  basicPlanValidationError,
  editRiderSelectedPlan,
  isDiscountSelected,
  toggleDiscount,
}: BasicPlanSelectionProps) => {
  return (
    <FormGroup className={`basic-plan-selection item basic ${basicPlanValidationError !== '' ? 'has-danger' : ''}`}>
      <div className="form-group-title">
        <span className="form-group-title-text">สัญญาหลัก</span>
      </div>
      <div className="options option-labels">
        <label>เลือกแผน</label>
      </div>
      <Dropdown
        className="basic-plan form-control"
        options={basicPlanOptions}
        onChange={(planCode) => {
          editRiderSelectedPlan(values.ESCI, { planCode })
        }}
        value={selectedBasicPlanCode}
      />

      <Checkbox id={'esci-discount-checkbox'} className="mt-3" checked={isDiscountSelected} onChange={toggleDiscount}>
        ใช้สิทธิ์ส่วนลดพิเศษกรมธรรม์สำหรับสามี ภรรยา พ่อ แม่ บุตร ปู่ ย่า ตา ยาย และพี่น้องร่วมบิดามารดาเดียวกัน
        (เมื่อซื้อพร้อมกันตั้งแต่ 2 กรมธรรม์ขึ้นไป)
      </Checkbox>

      <FormFeedback className="validation-message">{basicPlanValidationError}</FormFeedback>
    </FormGroup>
  )
}

export default BasicPlanSelection
