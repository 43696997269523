// @flow

import type { FundState } from 'core/service/investment'
import { flow, get, isEqual } from 'lodash/fp'
import {
  hasForeignFundSummary as hasLstuForeignFunds,
  hasOverRiskFundSummary as hasLstuOverRiskFunds,
} from 'quick-quote/product-investment/fund-allocation/lstu/selectors'
import {
  hasForeignFundAllocation as hasRppForeignFunds,
  hasOverRiskFundAllocation as hasRppOverRiskFunds,
} from 'quick-quote/product-investment/fund-allocation/rpp/selectors'
import type { AppState } from 'quick-quote/reducers'
import { createSelector } from 'reselect'

export const getAvailableFunds = (state: AppState): FundState[] => state.investment.funds.availableFunds
export const hasValidEntryComponent = (state: AppState): boolean =>
  flow(get('entryComponent.source'), isEqual('perfect-advice'))(state) ? true : false

export const _hasOverRiskFunds = (hasRppOverRiskFunds, hasLstuOverRiskFunds) =>
  hasRppOverRiskFunds || hasLstuOverRiskFunds

export const _hasForeignFunds = (hasRppForeignFunds, hasLstuForeignFunds) => hasRppForeignFunds || hasLstuForeignFunds

export const hasOverRiskFunds = createSelector(hasRppOverRiskFunds, hasLstuOverRiskFunds, _hasOverRiskFunds)

export const hasForeignFunds = createSelector(hasRppForeignFunds, hasLstuForeignFunds, _hasForeignFunds)
