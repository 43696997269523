// @flow
import type { AppState } from 'quick-quote/reducers'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { RadioButtonGroup } from 'common-components'
import { selectProductLifeTreasure } from 'quick-quote/product-life-ready/actions'
import { getSelectedDisplayProductCode } from 'quick-quote/product-selection/selectors'
import VALUES from 'core/data-model/constants/values'

export const LifeTreasureProductSelection = ({ getSelectedProductLifeTreasure, selectProductLifeTreasure }) => {
  const productLIFETREASURE = [
    {
      text: '6 ปี',
      value: VALUES.LIFETREASURE_CODE.LIFETREASURE_6,
    },
    {
      text: '12 ปี',
      value: VALUES.LIFETREASURE_CODE.LIFETREASURE_12,
    },
    {
      text: '18 ปี',
      value: VALUES.LIFETREASURE_CODE.LIFETREASURE_18,
    },
  ]

  return (
    <div className="form-group">
      <div className="form-group-title">
        <span className="form-group-title-text">ระยะเวลาการชำระเบี้ยประกัน</span>
      </div>
      <RadioButtonGroup
        keyToGetValue="value"
        className="model-factor"
        onChange={selectProductLifeTreasure}
        value={getSelectedProductLifeTreasure}
        options={productLIFETREASURE}
      />
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  getSelectedProductLifeTreasure: getSelectedDisplayProductCode(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  selectProductLifeTreasure: bindActionCreators(selectProductLifeTreasure, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(LifeTreasureProductSelection)
