import { productBenefitsSection } from './product-benefit-section'
import { ridersSections } from './riders-section'
import { profileSnapshotSection } from './profile-snapshot-section'
import { signatureSection } from './signature-section'
import {
  legalDisclaimerSection,
  legalDisclaimerTwoSection,
  legalDisclaimerThreeSection,
  legalDisclaimerMarketConduct,
  legalDisclaimerMarketConductNoAddtionalContract,
} from './legal-disclaimer-section'
import { paymentChannelsSection } from './payment-channels-section'
import { taxDeductionBenefit } from './tax-deduction-section'
import { taxGioDeductionBenefit } from './tax-gio-deduction-section'
import { taxConsentDeductionBenefit, taxConsentDeductionS7Benefit } from './tax-consent-deduction-section'
import { basicPlanConditionSection } from './basicplan-condition-section'
import { basicPlanOrdinaryConditionSection } from './basicplan-ordinary-condition-section'
import { policyGioValueTableSection } from './policy-gio-value-table-section'
import { policyValueEtiRpuTableSection } from './policy-value-eti-rpu-table-section'

export const commonSection = {
  riders: ridersSections,
  productBenefits: productBenefitsSection,
  profile: profileSnapshotSection,
  legalDisclaimer: legalDisclaimerSection,
  legalDisclaimerTwo: legalDisclaimerTwoSection,
  legalDisclaimerThree: legalDisclaimerThreeSection,
  legalDisclaimerMarketConduct: legalDisclaimerMarketConduct,
  legalDisclaimerMarketConductNoAddtionalContract: legalDisclaimerMarketConductNoAddtionalContract,
  signature: signatureSection,
  paymentChannels: paymentChannelsSection,
  taxDeductionBenefit: taxDeductionBenefit,
  taxGioDeductionBenefit: taxGioDeductionBenefit,
  taxConsentDeductionBenefit: taxConsentDeductionBenefit,
  taxConsentDeductionS7Benefit: taxConsentDeductionS7Benefit,
  basicPlanCondition: basicPlanConditionSection,
  basicPlanOrdinaryConditionSection: basicPlanOrdinaryConditionSection,
  policyGioValueTable: policyGioValueTableSection,
  policyValueEtiRpuTable: policyValueEtiRpuTableSection,
}
