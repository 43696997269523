// @flow

import React from 'react'
import { connect } from 'react-redux'
import type { Dispatch } from 'redux'
import { bindActionCreators } from 'redux'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormFeedback,
  Progress,
  ListGroup,
  ListGroupItem,
  Input,
} from 'reactstrap'
import debounce from 'lodash/debounce'
import { selectQuestionnaire, answerQuestion, calculateRisk, setAgentComment } from '../actions'
import {
  getQuestionnaire,
  getTotalQuestionnaire,
  answeredPercent,
  answeredCount,
  getRiskLevelResult,
  getAgentComment,
} from '../selectors'
import { getAge } from 'quick-quote/insured-information/selectors'
import type { Questionnaire, Choice } from 'core/service/investment/questionnaire'
import type { AppState } from 'quick-quote/reducers'
import type { RiskEvaluated } from 'core/service/investment/risklevel'

const MODAL_TITLE = 'แบบประเมินความเสี่ยง'
const PROGRESS_UNIT = 'ข้อ'
const AGENT_NOTE_TITLE = 'สำหรับตัวแทน'
const AGENT_NOTE_SUBTITLE = 'บันทึกความเห็นของตัวแทนประกันชีวิต และข้อสังเกตต่างๆ'
const SUBMIT_TEXT = 'คำนวณความเสี่ยง'
const JUVENILE_INFO =
  'กรณีผู้เอาประกันอายุต่ำกว่า 20 ปี กรุณาให้ผู้ชำระเบี้ยประกันตอบแบบประเมินระดับความเสี่ยงในการลงทุน'

class QuestionnaireModal extends React.Component<ModalProps> {
  updateAgentComment = debounce((comment) => {
    this.props.setAgentComment(comment)
  }, 250)

  onAgentCommentChange = (event) => {
    const comment = event.target.value

    this.updateAgentComment(comment)
  }

  render() {
    const {
      insuredAge,
      answeredCount,
      questionnaires,
      answerQuestion,
      riskLevelResult,
      calculateRisk,
      answeredPercent,
      totalQuestionnaire,
      isModalOpen,
      toggleModal,
      agentComment,
    } = this.props
    const css = answeredPercent === 100 ? 'primary' : 'btn btn-disabled'
    const isJuvenile = insuredAge.unit === 'month' || (insuredAge.unit === 'year' && insuredAge.value < 20)
    const shouldShowJevenileInfo = isJuvenile

    return (
      <Modal
        isOpen={isModalOpen}
        backdrop={true}
        wrapClassName="modal-noti"
        modalClassName="ltr-modal scrollable-modal risk-quiz-modal"
        autoFocus={false}
      >
        <ModalHeader toggle={toggleModal} tag="div">
          {MODAL_TITLE}
          <div className="progress-wrap">
            {answeredCount} / {totalQuestionnaire} {PROGRESS_UNIT}
            <Progress value={answeredPercent} />
          </div>
        </ModalHeader>

        <ModalBody>
          {shouldShowJevenileInfo ? (
            <div className="info-message-container">
              <div className="form-group">
                <FormFeedback className="info-message attached-icon">
                  <p>{JUVENILE_INFO}</p>
                </FormFeedback>
              </div>
            </div>
          ) : null}
          <ul className="quiz">
            {questionnaires.map((item: Questionnairem, index) => (
              <li className="form-group" key={index + 1}>
                <label>{`${index + 1}. ${item.question}`}</label>
                <ListGroup>
                  {item.choices.map((i: Choice) => (
                    <ListGroupItem
                      key={`${index + 1}${i.value}`}
                      active={item.selectedChoice === i.value}
                      onClick={() => answerQuestion(index + 1, i.value)}
                    >
                      <span dangerouslySetInnerHTML={{ __html: i.choice + (item.hasScore ? ` (${i.value}) ` : '') }} />
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </li>
            ))}
            {
              <li className="form-group">
                <div>
                  <strong>{AGENT_NOTE_TITLE}</strong>
                  <p>{AGENT_NOTE_SUBTITLE}</p>
                  <Input defaultValue={agentComment} onChange={this.onAgentCommentChange} />
                </div>
              </li>
            }
          </ul>
          <div className="action">
            <Button
              id="btn-calculate-risk"
              color={css}
              className={css}
              onClick={() => {
                toggleModal()
                calculateRisk(riskLevelResult)
              }}
            >
              {SUBMIT_TEXT}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}

export type QuestionnaireState = {
  questionnaires: Questionnaire[],
  answeredPercent: number,
}

export type QuestionnaireDispatchers = {
  answerQuestion: (number, number) => void,
  calculateRisk: (RiskEvaluated) => void,
}

type ModalProps = {
  insuredAge: 1,
  questionnaires: Questionnaire[],
  totalQuestionnaire: number,
  answeredPercent: number,
  answeredCount: number,
  riskLevelResult: RiskEvaluated,
  answerQuestion: (number, number) => void,
  calculateRisk: (RiskEvaluated) => void,
  isModalOpen: boolean,
  toggleModal: () => void,
  agentComment: string,
  setAgentComment: (string) => void,
}

const mapStateToProps = (state: AppState): QuestionnaireState => ({
  insuredAge: getAge(state),
  questionnaires: getQuestionnaire(state),
  totalQuestionnaire: getTotalQuestionnaire(state),
  answeredPercent: answeredPercent(state),
  answeredCount: answeredCount(state),
  riskLevelResult: getRiskLevelResult(state),
  agentComment: getAgentComment(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>): QuestionnaireDispatchers => ({
  selectQuestionnaire: bindActionCreators(selectQuestionnaire, dispatch),
  answerQuestion: bindActionCreators(answerQuestion, dispatch),
  calculateRisk: bindActionCreators(calculateRisk, dispatch),
  setAgentComment: bindActionCreators(setAgentComment, dispatch),
})

const QuestionnaireModalComponent = connect(mapStateToProps, mapDispatchToProps)(QuestionnaireModal)

export default QuestionnaireModalComponent
