// @flow
type State = { isModalOpen: boolean }
export const withModalToggle = (Component: *) =>
  class ModalToggle extends React.PureComponent<*, State> {
    state = {
      isModalOpen: false,
    }

    toggleModal = (e: ?Object) => {
      if (e) {
        e.preventDefault()
      }
      this.setState({
        isModalOpen: !this.state.isModalOpen,
      })
    }
    render() {
      return <Component toggleModal={this.toggleModal} isModalOpen={this.state.isModalOpen} {...this.props} />
    }
  }

type DropdownState = {
  dropdownOpen: boolean,
}
export const withDropdown = (Component: *) =>
  class Dropdown extends React.PureComponent<*, DropdownState> {
    state = {
      dropdownOpen: false,
    }

    toggle = (e: ?Object) => {
      if (e) {
        e.preventDefault()
      }
      this.setState({
        dropdownOpen: !this.state.dropdownOpen,
      })
    }
    render() {
      return <Component toggle={this.toggle} dropdownOpen={this.state.dropdownOpen} {...this.props} />
    }
  }
