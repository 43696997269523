const tableLayouts = {
  lightBorder: {
    hLineWidth: () => {
      return 0.75
    },
    vLineWidth: () => {
      return 0.75
    },
  },
  investmentBenefitTableStyle: {
    hLineWidth: function(i, node) {
      const needLineTable = [0, 1, 3, 6]
      if (needLineTable.includes(i) || i === node.table.body.length) {
        return 0.75
      }
      return 0
    },
  },
}

export default tableLayouts
