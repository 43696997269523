import { isNil } from 'lodash/fp'
import { connect } from 'react-redux'
import { defaultProps, compose } from 'recompose'
import { getErrorByTypeName, getCurrentApp } from 'e-submission/apps/selectors'
import { removeError, uploadMultipleDocs } from 'e-submission/apps/actions'
import styled from 'styled-components'
import Button from '../../../common-components/button'
import { cancelEvent } from 'quick-quote/remote-selling'
import classnames from 'classnames'
import { isSelectedRemoteSelling } from 'quick-quote/selectors'
import { get } from 'lodash/fp'

const Container = styled.div`
  display: flex;
  background-color: white;
  font-size: 1rem;
  padding: 1.25rem;

  div {
    font-weight: bold;
    margin: auto;
    flex-grow: 1;
  }

  button {
    margin-bottom: 0;
    height: 1.5rem;
    padding: 0.1rem 1rem;
    font-size: 0.875rem;

    .flex {
      display: flex;
      margin: 0.1rem;

      #spinner {
        width: 1rem;
        height: 1rem;
      }

      label {
        margin-left: 0.35rem;
        margin-bottom: 0;
      }
    }
  }
  .remote-selling-btn-container {
    flex-grow: 0;
    .text-area {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      justify-content: center;
      #spinner {
        display: inline-block;
        margin-right: 10px;
        margin-left: 0;
        flex-grow: 0;
        width: 20px;
        height: 20px;
      }
      label {
        display: inline-block;
        color: #c1c1c1;
        font-weight: 500;
        font-size: 15px;
      }
    }
    .remote-selling-btn.btn-loading,
    .remote-selling-btn.btn-loading:focus {
      border-radius: 5px;
      color: #ff3233;
      border-color: #ff3233;
      background: white;
      box-shadow: none;
      width: 100%;
      font-weight: 500;
      &:after {
        display: none;
      }
    }
  }
`

export const UploadMultipleDocCommand = ({
  disabled,
  name,
  onClose,
  checked,
  isOpen,
  isUploadError,
  onClick,
  loading,
  autoMargin,
  onCancel,
  isRemoteSelling,
  ...props
}) => {
  return (
    <div style={{ margin: autoMargin ? 'auto' : '1rem auto' }}>
      <Container>
        <div>{props.description}</div>
        <div className={classnames(isRemoteSelling && 'remote-selling-btn-container')}>
          {isRemoteSelling && loading && (
            <div className="text-area">
              <div id="spinner" />
              <label>กำลังดำเนินการ</label>
            </div>
          )}

          <Button
            className={classnames(isRemoteSelling && 'remote-selling-btn', isRemoteSelling && loading && 'btn-loading')}
            outline={checked}
            onClick={loading ? onCancel : onClick}
            disabled={disabled}
          >
            {loading ? (
              <div className="flex">
                {!isRemoteSelling && <div id="spinner" />}
                <label>ยกเลิกคำสั่ง</label>
              </div>
            ) : checked ? (
              'ส่งคำสั่งอีกครั้ง'
            ) : (
              'ส่งคำสั่ง'
            )}
          </Button>
        </div>
      </Container>
    </div>
  )
}

export default compose(
  defaultProps({
    isUploadError: false,
  }),
  connect(
    (state, { name }) => {
      const app = getCurrentApp(state)
      const remoteSellingState = get('remoteSelling.callStatus', app)
      return {
        disabled: get('isClientStreaming', remoteSellingState) === false,
        loading: app?.remoteSelling?.isUploading,
        isUploadError: !isNil(getErrorByTypeName(state, name)),
        isRemoteSelling: isSelectedRemoteSelling(app),
      }
    },
    (dispatch, { name, limit }) => ({
      onClick: async () => {
        dispatch(removeError(name))
        dispatch(uploadMultipleDocs(name, false, 'insured', limit))
      },
      onCancel: () => {
        dispatch(cancelEvent({ name, isInsured: true }))
      },
    })
  )
)(UploadMultipleDocCommand)
