//@flow
import _ from 'lodash'
import { buildRiderWithPlansBenefitTable } from 'core/service/pdf-generation/products/common/sections/riders/common'
import MESSAGES from 'core/data-model/constants/bi-messages'
import type { Table } from 'core/service/pdf-generation/utils/builder'
import { transformOrderListToString } from 'core/service/pdf-generation/utils/builder'
import { getMEAPlusExceptionNotes } from 'core/service/rider/benefits/exception-notes'

import type { Rider, RiderState } from 'core/data-model/rider'
import type { RiderBenefitData } from 'core/data-model/rider/benefits'

type MEAPlusBenefitProps = {
  rider: Rider & RiderState,
  riderBenefitData: RiderBenefitData,
}
const buildExceptionNotes = () => {
  const exceptionNotes = getMEAPlusExceptionNotes()
  const [exceptionFirstLine, excptionConditionDesc] = exceptionNotes.exceptions
  return {
    stack: [
      {
        text: MESSAGES.EXCEPTION_NOTE,
        style: 'sub-title',
      },
      {
        text: exceptionFirstLine,
      },
      {
        text: `${excptionConditionDesc[0]}`,
      },
      {
        ol: [...excptionConditionDesc[1]],
      },
    ],
  }
}
export const MEAPlusBenefitSection = ({ rider, riderBenefitData }: MEAPlusBenefitProps) => {
  const benefitTable = buildRiderWithPlansBenefitTable({ rider, riderBenefitData })
  const exceptionNotes = buildExceptionNotes()
  return [
    {
      table: modifiedLastRowTable(benefitTable),
      layout: 'lightBorder',
      style: 'table',
    },
    { ...exceptionNotes, marginTop: 5 },
  ]
}

export const modifiedLastRowTable = (table: Table) => _modifiedLastRowTable(table, transformOrderListToString)

export const _modifiedLastRowTable = (table: Table, transform: Function) => {
  const body = table.body
  const position = body.length
  const lastRow = _.last(body)
  const transformedRow = [transform(lastRow[0], 0), lastRow[1]]

  const newBody = [...body.slice(0, position - 1), transformedRow]
  return { ...table, body: newBody }
}
