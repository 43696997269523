// @flow

type _BIType = 'MINI_BI' | 'FULL_BI'
type _ProductCategoryType = 'WHOLE_LIFE' | 'INVESTMENT' | 'HEALTH'
export type BIProps = {
  biType: string,
  category?: string,
  isIgnoreStaffInfo?: boolean,
}

export const PREPARE_FOR_QUICK_QUOTE = 'PREPARE_FOR_QUICK_QUOTE'
export type PrepareQuickQuote = {
  type: 'PREPARE_FOR_QUICK_QUOTE',
  payload: {
    biType: _BIType,
    productCategory: _ProductCategoryType,
  },
}

export const prepareQuickQuote = (biType: _BIType, productCategory: _ProductCategoryType): PrepareQuickQuote => ({
  type: PREPARE_FOR_QUICK_QUOTE,
  payload: { biType, productCategory },
})

export const SHARE_QUICK_QUOTE = 'SHARE_QUICK_QUOTE'
export type ShareQuickQuote = {
  type: 'SHARE_QUICK_QUOTE',
  payload: BIProps,
}

export const shareQuickQuote = (category: _ProductCategoryType, biType: _BIType): ShareQuickQuote => ({
  type: SHARE_QUICK_QUOTE,
  payload: {
    category,
    biType,
  },
})

export const PREPARE_FOR_RPQ = 'PREPARE_FOR_RPQ'
export type PrepareRPQ = {
  type: 'PREPARE_FOR_RPQ',
  payload: {},
}

export const PREPARE_FOR_RA = 'PREPARE_FOR_RA'
export type PrepareRA = {
  type: 'PREPARE_FOR_RA',
  payload: {},
}

export type PrepareSharePdf = PrepareRPQ | PrepareRA

export const prepareRA = (): PrepareRA => ({
  type: PREPARE_FOR_RA,
  payload: {},
})

export const prepareRPQ = (): PrepareRPQ => ({
  type: PREPARE_FOR_RPQ,
  payload: {},
})

export const SHARE_RPQ = 'SHARE_RPQ'
export type ShareRPQ = {
  type: 'SHARE_RPQ',
}

export const shareRPQ = (): ShareRPQ => ({
  type: SHARE_RPQ,
  payload: {},
})

export const SHARE_RA = 'SHARE_RA'
export type ShareRA = {
  type: 'SHARE_RA',
}
export const shareRA = (): ShareRA => ({
  type: SHARE_RA,
  payload: {},
})
