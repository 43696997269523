// @flow
import { dataWrapper } from 'core/data'
import moment from 'moment'
import { getBasicPlan, _overrideRiderByUserType } from 'core/data-model/basic-plan'
import { getRider } from 'core/data-model/rider'
import type { Rider } from 'core/data-model/rider'
const { list, get } = dataWrapper
import type { PromiseFunction } from 'core/data'
import type { BasicPlan } from 'core/data-model/basic-plan'
import type { Campaign, RawCampaign } from 'core/data-model/campaign/types'
import _ from 'lodash'

const DOCUMENT_KEY = 'campaign'
const DATE_FORMAT = 'DD/MM/YYYY'
const override = <T>(object: T, overrides: *): T => {
  if (overrides == null || object == null) {
    return object
  } else {
    return { ...object, ...overrides }
  }
}

export const _mergeProperties = (
  rawCampaign: RawCampaign,
  selectedCampaignPlan: string,
  distributorGroup: string = '',
  agentCode: string = ''
) => (
  // $FlowFixMe
  [basicPlan, requiredRiders, optionalRiders]
) => {
  let overriddenBasicPlan = override(basicPlan, rawCampaign.basicPlanOverrides)
  if (typeof selectedCampaignPlan !== 'undefined' && selectedCampaignPlan != '') {
    const overriddenCampaignPlan = _.filter(rawCampaign.basicPlanOverrides.plans, { planCode: selectedCampaignPlan })
    if (overriddenCampaignPlan.length > 0 && typeof overriddenCampaignPlan[0].basicPlanOverrides !== 'undefined') {
      overriddenBasicPlan = override(overriddenBasicPlan, overriddenCampaignPlan[0].basicPlanOverrides)
    }
  }
  const overriddenRequiredRiders = requiredRiders.map((rider) =>
    override(_overrideRiderByUserType(rider, distributorGroup), rawCampaign.ridersOverrides[rider.code])
  )
  const overriddenOptionalRiders = optionalRiders.map((rider) =>
    override(_overrideRiderByUserType(rider, distributorGroup), rawCampaign.ridersOverrides[rider.code])
  )
  overriddenBasicPlan.riders = overriddenRequiredRiders.concat(overriddenOptionalRiders)
  return {
    code: rawCampaign.code,
    type: rawCampaign.type,
    name: rawCampaign.name,
    nameThai: rawCampaign.nameThai,
    fullNameThai: rawCampaign.fullNameThai,
    displayName: rawCampaign.displayName,
    category: rawCampaign.category,
    image: rawCampaign.image,
    description: rawCampaign.description,
    benefits: rawCampaign.benefits,
    startDate: rawCampaign.startDate,
    endDate: rawCampaign.endDate,
    basicPlan: overriddenBasicPlan,
    displayNameLanguage: rawCampaign.displayNameLanguage,
    selectedCampaignPlan: rawCampaign.selectedCampaignPlan,
    readOnlyFields: overriddenBasicPlan.readOnlyFields,
    riders: {
      required: overriddenRequiredRiders,
      optional: overriddenOptionalRiders,
    },
    licenses: rawCampaign.licenses,
    ribbonMessage: rawCampaign.ribbonMessage,
    payerSelection: rawCampaign.payerSelection,
    allowUserType: rawCampaign.allowUserType,
    agentOverrides: rawCampaign.agentOverrides,
    bancOverrides: rawCampaign.bancOverrides,
    userOverrides: rawCampaign.userOverrides,
    userOverridesList: rawCampaign.userOverridesList,
    userOverridesEffectiveDate: rawCampaign.userOverridesEffectiveDate,
    userOverridesExpiryDate: rawCampaign.userOverridesExpiryDate,
    defaultSumAssured: rawCampaign.defaultSumAssured,
    basicPlanCodeOption: rawCampaign.basicPlanCodeOption,
    brochureLink: rawCampaign.brochureLink,
  }
}

export const _hydrateCampaign = (
  getBasicPlan: (string, string, string) => Promise<BasicPlan>,
  getRider: (string) => Promise<Rider>,
  rawCampaign: RawCampaign,
  selectedCampaignPlan: string,
  distributorGroup: string = '',
  agentCode: string = ''
): Promise<Campaign> => {
  const getBasicPlanPromise = getBasicPlan(rawCampaign.basicPlanCode, distributorGroup, agentCode)
  const getRequiredRidersPromise = Promise.all(rawCampaign.riders.required.map(getRider))
  const getOptionalRidersPromise = Promise.all(rawCampaign.riders.optional.map(getRider))
  const promises = [getBasicPlanPromise, getRequiredRidersPromise, getOptionalRidersPromise]
  return Promise.all(promises).then(_mergeProperties(rawCampaign, selectedCampaignPlan, distributorGroup, agentCode))
}

export const _getCampaign = (get: PromiseFunction<RawCampaign>, code: string) => get(`${DOCUMENT_KEY}_${code}`)

export const getCampaign = (
  code: string,
  selectedCampaignPlan: string,
  distributorGroup: string = '',
  agentCode: string = ''
) =>
  _getCampaign(get, code).then((rawCampaign) =>
    _hydrateCampaign(getBasicPlan, getRider, rawCampaign, selectedCampaignPlan, distributorGroup, agentCode)
  )

export const _listCampaigns = (list: PromiseFunction<RawCampaign[]>) => list(DOCUMENT_KEY)

export const listCampaigns = () =>
  _listCampaigns(list).then((rawCampaigns) =>
    Promise.all(rawCampaigns.map((rawCampaign) => _hydrateCampaign(getBasicPlan, getRider, rawCampaign)))
  )

export const _listActiveCampaigns = (listCampaigns: PromiseFunction<Campaign[]>) =>
  listCampaigns().then((campaigns) =>
    campaigns.filter((campaign) =>
      moment().isBetween(moment(campaign.startDate, DATE_FORMAT), moment(campaign.endDate, DATE_FORMAT), null, '[]')
    )
  )
export const listActiveCampaigns = () => _listActiveCampaigns(listCampaigns)
