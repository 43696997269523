// @flow
/* istanbul ignore file */

import type { DisplayProduct } from 'core/service/display-product'
import { createPdfDocument } from '../../document-creation'
import { createCommonPdf } from '../common/pdf-document'
import template from './template'
import { commonSection } from 'core/service/pdf-generation/products/common/sections'
import type { SavingProps } from './index'
import { section } from 'core/service/pdf-generation/products/saving/sections'

export const basicPlanLabel = (basicPlan: DisplayProduct) => basicPlan.fullNameThai || basicPlan.nameThai

export const createMiniBIDocDefinition = async (props: SavingProps) => {
  const pdfTemplate = await template(props)
  return {
    content: [
      ...commonSection.profile({ ...props, basicPlanLabel }),
      ...commonSection.productBenefits({ ...props }),
      ...commonSection.riders(props),
      ...section.policyValueTable(props),
      commonSection.legalDisclaimerMarketConduct(props),
    ],
    ...pdfTemplate,
  }
}

export const createFullBIDocDefinition = async (props: SavingProps) => {
  const _props: SavingProps = { ...props, biType: props.biType }
  const pdfTemplate = await template(_props)
  const paymentChannels = await commonSection.paymentChannels(props)

  return {
    content: [
      ...commonSection.profile({ ...props, basicPlanLabel }),
      ...commonSection.productBenefits({ ...props }),
      ...commonSection.riders(props),
      commonSection.basicPlanCondition,
      ...section.policyValueTable(props),
      commonSection.legalDisclaimerMarketConduct(props),
      paymentChannels,
      commonSection.signature(props),
    ],
    ...pdfTemplate,
  }
}

const createFullBIPdf = createPdfDocument(createFullBIDocDefinition)
const createMiniBIPdf = createPdfDocument(createMiniBIDocDefinition)

export const createSavingPdf = async (props: SavingProps) => {
  return await createCommonPdf(props, createFullBIPdf, createMiniBIPdf)
}
