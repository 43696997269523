import styled from 'styled-components'
import { Button } from 'reactstrap'
import { Children } from 'react'
import { getIcon } from 'e-submission/components/Icon'

export const ButtonComponent = styled(Button)`
  font-weight: bold;
  ${(props) => (props.block ? `display: block;` : null)}
  svg,
  span {
    display: inline-block;
    vertical-align: middle;
  }
  svg {
    height: 0.825em;
    width: 0.825em;
    margin-right: 4px;
  }
  &.btn-outline-disabled {
    pointer-events: none;
  }
`

function Component({ disabled, color = 'primary', onClick, ...props }) {
  if (props.block) {
  }
  return (
    <ButtonComponent onClick={disabled ? undefined : onClick} color={disabled ? 'disabled' : color} {...props}>
      {props.icon ? (
        Children.toArray([getIcon(props.icon), <span className="TEE">{props.children}</span>])
      ) : (
        <span>{props.children}</span>
      )}
    </ButtonComponent>
  )
}

export default Component
