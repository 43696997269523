import styled from 'styled-components'
import { marginTopLessMixin } from 'e-submission/components/mixins'

const FormSection = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  position: relative;

  & + & {
    &::before {
      content: '';
      display: block;
      border-top: solid 1px ${({ theme }) => theme.variables['$color-silver']};
      position: absolute;
      top: 1px;
      left: 1rem;
      right: 1rem;
    }
  }

  ${marginTopLessMixin};
`

export default FormSection
