// @flow

import type { DisplayProduct } from 'core/service/display-product'
import type { Rider, RiderState } from 'core/data-model/rider'

import { createSelector } from 'reselect'
import { getSelectedDisplayProduct } from 'quick-quote/product-selection/selectors'
import { getSelectedRiders } from 'quick-quote/product-common/coverage-plan/selectors'
import VALUES from 'core/data-model/constants/values'

export const isProductForReuseFlexiHealth = (displayProduct: DisplayProduct): boolean => {
  return VALUES.PRODUCT_CODE_FOR_REUSE_FLEXI_HEALTH.includes(displayProduct.code)
}

export const getProfileSummaryHeader = createSelector(
  getSelectedDisplayProduct,
  getSelectedRiders,
  (displayProduct: DisplayProduct, riders: (Rider & RiderState)[]): string => {
    const fhRider = riders.find((rider) => rider.code === VALUES.FH)

    const planName = fhRider
      ? // $$FlowFixMe
        isProductForReuseFlexiHealth(displayProduct)
        ? `(${displayProduct.basicPlanCode})`
        : `${fhRider.selectedPlan.plan} (${fhRider.selectedPlan.planCode})`
      : ''
    return `${displayProduct.name} ${planName}`
  }
)
