import { compact } from 'lodash/fp'

import { createSectionCreator, createField } from '../utils'

const getQuestions = (app, id) => {
  return compact([
    createField(
      `${id}.treatmentPA.label`,
      [
        {
          id: `${id}.treatmentPA.label`,
          label: 'คำถามเพื่อข้อมูลเกี่ยวกับสุขภาพ',
          c: 'PlainText',
          p: {
            children: '',
          },
        },
      ],
      {
        style: {
          marginBottom: '0',
        },
      }
    ),
    createField(`${id}.haveDiagnosis.checked`, [
      {
        id: `${id}.haveDiagnosis.checked`,
        c: 'RadioButton',
        required: true,
        label:
          'ก. ท่านเคยเป็น หรือเคยถูกบอกว่าเป็น และเคยได้รับการรักษาหรือเคยปรึกษาแพทย์เกี่ยวกับโรคดังนี้ โรคลมชัก โรคหัวใจ โรคความดันโลหิตสูง โรคเบาหวาน โรคกระดูก และ / หรือกล้ามเนื้อ โรคมะเร็ง โรคเอดส์ หรือตรวจพบเชื้อเอดส์ในเลือด โรคตา โรคหู หรือไม่',
        p: {
          options: [
            {
              text: 'ไม่เคย',
              value: 'no',
            },
            {
              text: 'เคย',
              value: 'yes',
            },
          ],
        },
      },
    ]),
  ])
}

export default createSectionCreator(getQuestions)
