// @flow
import { formatNumber } from 'core/service/lib/number-format'
import type { MainContractBenefit } from './types'
import Mustache from 'mustache'
import MESSAGES from 'core/data-model/constants/bi-messages'
import VALUES from 'core/data-model/constants/values'
import React from 'react'

const calculateMainContractBenefits = (displayProduct, sumAssured) => {
  const percentCalculate = (displayProduct) => {
    const defaultPercent = {
      MINIMUM_DEATH_BENEFIT_PERCENT: 0,
      MATURITY_BENEFIT_PERCENT: 0,
    }
    switch (displayProduct.code) {
      case VALUES.LIFE_SUPER_SAVE_14_5_CODE:
        return VALUES.LIFE_SUPER_SAVE_14_5_MAIN_CONTRACT_BENEFIT
      default:
        return defaultPercent
    }
  }
  const percents = percentCalculate(displayProduct)

  return {
    BASIC_PLAN_DEATH_BENEFIT: (sumAssured * percents.MINIMUM_DEATH_BENEFIT_PERCENT) / 100,
    MATURITY_BENEFIT: (sumAssured * percents.MATURITY_BENEFIT_PERCENT) / 100,
  }
}

const MainContractBenefits = ({ displayProduct, sumAssured }: MainContractBenefit) => {
  const mainContractBenefit = calculateMainContractBenefits(displayProduct, sumAssured)
  return (
    <div id="main-contract-benefit" className="legal group main-contract-benefit">
      <h3>{MESSAGES.BASIC_PLAN_COVERAGE_BENEFITS}</h3>
      <div className="disclaimer">
        <ul className="taxdeduction">
          <li>
            <div>{MESSAGES.MAIN_CONTRACT_BENEFIT_SUMMARY_LIST[0].description}</div>
            <div>
              {Mustache.render(MESSAGES.MAIN_CONTRACT_BENEFIT_SUMMARY_LIST[0].benefit, {
                value: formatNumber(mainContractBenefit.BASIC_PLAN_DEATH_BENEFIT, 2, true),
              })}
            </div>
          </li>
          <li>
            <div>{MESSAGES.MAIN_CONTRACT_BENEFIT_SUMMARY_LIST[1].description}</div>
            <div>
              {Mustache.render(MESSAGES.MAIN_CONTRACT_BENEFIT_SUMMARY_LIST[1].benefit, {
                value: formatNumber(mainContractBenefit.MATURITY_BENEFIT, 2, true),
              })}
            </div>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default MainContractBenefits
