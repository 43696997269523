import _ from 'lodash'
import { createSectionCreator, createField } from './utils'

const otherPolicyField = (app, id) => {
  const isShowRemoveButton = !id.includes('[0]')

  return _.compact([
    createField(`${id}.companyName`, [
      {
        label: 'บริษัท',
        id: `${id}.companyName`,
        c: 'TextInput',
        p: {},
        required: true,
      },
    ]),
    createField(`${id}.policyNumber`, [
      {
        label: 'เลขที่กรมธรรม์',
        id: `${id}.policyNumber`,
        c: 'TextInput',
        p: {},
        required: true,
      },
    ]),
    createField(`${id}.coverageType`, [
      {
        label: 'ประเภทความคุ้มครอง',
        id: `${id}.coverageType`,
        c: 'Dropdown',
        p: {
          placeHolder: 'กรุณาเลือกประเภทความคุ้มครอง',
          options: [
            {
              text: 'ชีวิต',
              value: 'life',
            },
            {
              text: 'โรคร้ายแรง',
              value: 'criticalIllness',
            },
            {
              text: 'อุบัติเหตุ',
              value: 'accident',
            },
          ],
        },
        required: true,
      },
    ]),
    createField(`${id}.sumAssured`, [
      {
        label: 'จํานวนเงินเอาประกันภัย',
        id: `${id}.sumAssured`,
        c: 'NumberInput',
        p: {
          thousandSeparator: true,
        },
        required: true,
      },
    ]),
    createField(`${id}.isCurrentlyEnforced`, [
      {
        label: 'สถานะกรมธรรม์',
        id: `${id}.isCurrentlyEnforced`,
        c: 'Dropdown',
        p: {
          placeHolder: 'กรุณาเลือกสถานะกรมธรรม์',
          options: [
            {
              text: 'ปกติ',
              value: true,
            },
            {
              text: 'ขาดผลบังคับ',
              value: false,
            },
          ],
        },
        required: true,
      },
    ]),
    isShowRemoveButton &&
      createField(
        `${id}.remove`,
        [
          {
            id: `${id}.remove`,
            c: 'Button',
            p: {
              icon: 'remove',
              children: 'ลบ',
              mergeProps: ({ removeValue }) => ({
                onClick: () => removeValue(id),
              }),
              style: {
                fontSize: '0.8725rem',
                padding: '0.125rem 0.5rem',
              },
            },
          },
        ],
        {
          style: {
            position: 'absolute',
            top: '0',
            right: '0',
          },
        }
      ),
  ])
}

const addNewOtherPolicy = (app, id) => [
  createField(`${id}.addNew`, [
    {
      id,
      c: 'Button',
      p: {
        children: 'เพิ่มประวัติการทำประกัน',
        icon: 'add',
        block: true,
        mergeState: () => ({
          mergeProps: ({ setValue }) => ({
            onClick: () => setValue(null),
          }),
        }),
      },
    },
  ]),
]

export default (sectionId, sectionLabel) => (app, id) => {
  const hasOtherPolicy = _.get(app, `${id}.hasOtherPolicy.value`)
  const otherPolicy = _.get(app, `${id}.otherPolicy`, [null])

  return _.compact(
    hasOtherPolicy &&
      _.reduce(
        otherPolicy,
        (result, el, index) => [
          ...result,
          createSectionCreator(otherPolicyField)(`${sectionId}[${index}]`, null, null, `${sectionLabel} ${index + 1}`)(
            app,
            `${id}.otherPolicy[${index}]`
          ),
        ],
        []
      ).concat(
        otherPolicy.length < 10 &&
          createSectionCreator(addNewOtherPolicy)(sectionId)(app, `${id}.otherPolicy[${otherPolicy.length}]`)
      )
  )
}
