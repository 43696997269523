import moment from 'moment'

export const convertAgeToBirthdate = (age) => {
  const date = moment()
  const newBirthDate = age > 0 ? date.subtract(age, 'years') : date.subtract(1, 'months')

  return newBirthDate.format('DD/MM/YYYY')
}

export const getAgeOptions = (min = 0, max = 99) => {
  let ageOptions = []
  for (let i = min; i <= max; i++) {
    let option = {
      text: i.toString(),
      value: i,
    }
    ageOptions.push(option)
  }

  return ageOptions
}
