//@flow
import _ from 'lodash'
import CONSTANTS from 'core/data-model/constants/messages'
import MESSAGES from 'core/data-model/constants/bi-messages'
import { buildHeaderTable } from 'core/service/pdf-generation/utils/builder'
import { isString, isArray, isObject } from 'core/service/lib/type-check'
import { getCPRExceptionNotes } from 'core/service/rider/benefits/exception-notes'

import type { Rider, RiderState } from 'core/data-model/rider'
import type { RiderBenefitData, Benefit } from 'core/data-model/rider/benefits'

const benefitTable = {
  headers: [MESSAGES.BENEFIT_LIST, MESSAGES.BENEFIT_BAHT],
}

type CPRBenefitProps = {
  rider: Rider & RiderState,
  riderBenefitData: RiderBenefitData,
  showDisclaimer: boolean,
}

export const CPRBenefitSection = ({ rider, riderBenefitData, showDisclaimer }: CPRBenefitProps) => {
  const benefits = _.get(riderBenefitData, 'benefits')
  const exceptionNotes = buildExceptionNotes()

  if (benefits && isArray(benefits))
    return [
      {
        table: {
          headerRows: 1,
          widths: ['*', 'auto'],
          body: [buildHeaderTable(benefitTable.headers), ...benefits.map((benefit) => buildCPRBenefitsRow(benefit))],
        },
        layout: 'lightBorder',
        style: 'table',
      },
      buildDisclaimer(showDisclaimer),
      { ...exceptionNotes, pageBreak: 'before' },
    ]
  else return []
}

const buildExceptionNotes = () => {
  const exceptionNotes = getCPRExceptionNotes()

  return {
    stack: [
      { text: MESSAGES.EXCEPTION_NOTE, style: 'sub-title' },
      { text: exceptionNotes.title },
      { ol: exceptionNotes.exceptions },
      { text: exceptionNotes.condition },
    ],
  }
}

const buildDisclaimer = (showDisclaimer) => ({
  stack: [
    showDisclaimer ? MESSAGES.DISCLAIMER_OVER_56_MUST_HAVE_MEDICAL_EXAM : '',
    `*${MESSAGES.DISCLAIMER_UNLIMITED_CLAIM_UNDER_100}`,
    `**${MESSAGES.DISCLAIMER_ORGAN_DEFINITION}`,
    {
      columns: [
        {
          ul: [MESSAGES.DISCLAIMER_ORGAN_OVARY, MESSAGES.DISCLAIMER_ORGAN_VAGINA],
          width: 'auto',
        },
        {
          ul: [MESSAGES.DISCLAIMER_ORGAN_COLON, MESSAGES.DISCLAIMER_ORGAN_STOMACH],
          width: 'auto',
        },
        {
          ul: [MESSAGES.DISCLAIMER_ORGAN_SKIN],
          width: 'auto',
        },
      ],
      columnGap: 10,
    },
    `***${MESSAGES.DISCLAIMER_INFORCE_OR_REINSTATEMENT}`,
  ],
  style: 'disclaimer',
})

const buildCPRBenefitsRow = (benefit: Benefit) => {
  const description = benefit.description && isString(benefit.description) ? benefit.description.split('\n') : ''
  const value = isObject(benefit) && benefit.value ? benefit.value : 0
  const disclaimer = benefit.disclaimer !== '' ? benefit.disclaimer : ''

  return [[description, disclaimer], `${value} ${CONSTANTS.BAHT}`]
}
