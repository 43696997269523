//@flow

import MESSAGES from 'core/data-model/constants/bi-messages'
import type { DisplayProduct } from 'core/service'

const TOP_MARGIN = -10
const HALF_MARGIN = -4

export const basicPlanOrdinaryConditionSection = ({ basicPlan }: DisplayProduct) => ({
  stack: [
    {
      text: MESSAGES.CONDITION_TITLE,
      style: 'title',
      pageBreak: 'before',
    },
    {
      text: MESSAGES.COMPLETED_TITLE,
      style: 'sub-title',
    },
    {
      text: MESSAGES.COMPLETED_MESSAGE_ONE,
      style: 'textRow',
      marginTop: TOP_MARGIN,
    },
    {
      text: MESSAGES.COMPLETED_MESSAGE_TWO,
      style: 'textRow',
    },
    {
      text: MESSAGES.COMPLETED_MESSAGE_THREE,
      style: 'textRow',
    },
    {
      text: MESSAGES.NO_COVER_TITLE,
      style: 'sub-title',
    },
    {
      ol: [
        [
          {
            text:
              basicPlan.name === 'LifeTreasure'
                ? MESSAGES.LIFE_TREASURE_ORDINARY_NO_COVER_MESSAGE_BULLET_ONE_ONE
                : MESSAGES.LIFE_READY_ORDINARY_NO_COVER_MESSAGE_BULLET_ONE_ONE,
            style: 'textRow',
          },
          {
            text:
              basicPlan.name === 'LifeTreasure'
                ? MESSAGES.LIFE_TREASURE_ORDINARY_NO_COVER_MESSAGE_BULLET_ONE_TWO
                : MESSAGES.NO_COVER_MESSAGE_BULLET_ONE_TWO,
            style: 'textRow',
          },
        ],
        [
          {
            text:
              basicPlan.name === 'LifeTreasure'
                ? MESSAGES.LIFE_TREASURE_ORDINARY_NO_COVER_MESSAGE_BULLET_TWO
                : MESSAGES.LIFE_READY_ORDINARY_NO_COVER_MESSAGE_BULLET_TWO,
            style: 'textRow',
          },
        ],
        [
          {
            text: MESSAGES.NO_COVER_MESSAGE_BULLET_THREE,
            style: 'textRow',
          },
        ],
      ],
      style: 'textRow',
      marginTop: TOP_MARGIN,
    },
    {
      text: MESSAGES.CLAIM_TITLE,
      style: 'sub-title',
      marginTop: HALF_MARGIN,
    },
    {
      text: MESSAGES.CLAIM_MESSAGE,
      style: 'textRow',
      marginTop: TOP_MARGIN,
    },
    {
      text: MESSAGES.FREELOOK_TITLE,
      style: 'sub-title',
      marginTop: HALF_MARGIN,
    },
    {
      text: MESSAGES.FREELOOK_MESSAGE,
      style: 'textRow',
      marginTop: TOP_MARGIN,
    },
    {
      text: basicPlan.name === 'LifeTreasure' ? '' : MESSAGES.IMPACT_INSURED_TITLE,
      style: 'sub-title',
      marginTop: HALF_MARGIN,
    },
    {
      text: basicPlan.name === 'LifeTreasure' ? '' : MESSAGES.IMPACT_INSURED_MESSAGE,
      style: 'textRow',
      marginTop: TOP_MARGIN,
    },
    {
      text: MESSAGES.DELIVERY_PERIOD_TITLE,
      style: 'sub-title',
      marginTop: HALF_MARGIN,
    },
    {
      text: MESSAGES.DELIVERY_PERIOD_MESSAGE,
      style: 'textRow',
      marginTop: TOP_MARGIN,
    },
    {
      text: MESSAGES.CHANNEL_TITLE,
      style: 'sub-title',
      marginTop: HALF_MARGIN,
    },
    {
      text: MESSAGES.CHANNEL_MESSAGE,
      style: 'textRow',
      marginTop: TOP_MARGIN,
    },
  ],
  style: 'groupRow',
})
