// @flow
import _ from 'lodash'
import type { DisplayProduct } from 'core/service/display-product'
import type { Rider, RiderState } from 'core/data-model/rider'

import { createSelector } from 'reselect'
import {
  getInsuredFirstName,
  getInsuredLastName,
  getPayerNeeded,
  getBirthdateErrors,
  getTitleErrors,
  getPayerBirthdateErrors,
  getInsuredOccupationErrors,
  getPayerOccupationErrors,
  getPayerOtherOccupationErrors,
  getIsSelectedOtherOccupation,
  getInsuredOtherOccupationErrors,
  getIsSelectedPayerOtherOccupation,
  getIsOccupationsNotEmpty,
} from 'quick-quote/insured-information/selectors'
import { isQQFlow } from 'quick-quote/portal/selectors'
import { isAllowReviseQQMenu } from 'identity/selectors'
import { canProceedToInsuredInformation, getSelectedDisplayProduct } from 'quick-quote/product-selection/selectors'
import { getAvailableRiders } from 'quick-quote/product-common/coverage-plan/selectors'
import VALUES from 'core/data-model/constants/values'
import { getToggles } from 'quick-quote/feature-toggles'

const getErrorsForCampaignProduct = createSelector(
  getBirthdateErrors,
  getPayerBirthdateErrors,
  getSelectedDisplayProduct,
  getAvailableRiders,
  (
    birthdateErrors: string[],
    payerBirthdateErrors: string[],
    selectedDisplayProduct: DisplayProduct,
    riders: (Rider & RiderState)[]
  ): string[] => {
    const requiredRiderCodes = selectedDisplayProduct.requiredRiders || []
    const requiredRidersErrors = _(riders)
      .filter((rider) => requiredRiderCodes.includes(rider.code))
      .flatMap((rider) => {
        return rider.errors
      })
      .filter((error) => error.type === 'ELIGIBILITY')
      .value()

    return [...birthdateErrors, ...payerBirthdateErrors, ...requiredRidersErrors]
  }
)

const getOtherOccupationErrors = createSelector(
  getInsuredOtherOccupationErrors,
  getIsSelectedOtherOccupation,
  (insuredOtherOccupationErrors: string[], isSelectedOtherOccupation: boolean): string[] =>
    isSelectedOtherOccupation ? insuredOtherOccupationErrors : []
)

const getPayerOtherOccupationError = createSelector(
  getIsSelectedPayerOtherOccupation,
  getPayerOtherOccupationErrors,
  getSelectedDisplayProduct,
  (
    isSelectedPayerOtherOccupation: boolean,
    payerOtherOccupationErrors: string[],
    selectedDisplayProduct: DisplayProduct
  ): string[] => {
    const payerOtherOccupationError =
      isSelectedPayerOtherOccupation && selectedDisplayProduct.category === VALUES.SAVING
        ? payerOtherOccupationErrors
        : []
    return payerOtherOccupationError
  }
)

export const canProceedToCoveragePlan = createSelector(
  getInsuredFirstName,
  getInsuredLastName,
  getPayerNeeded,
  getTitleErrors,
  getBirthdateErrors,
  getPayerBirthdateErrors,
  getInsuredOccupationErrors,
  getPayerOccupationErrors,
  getOtherOccupationErrors,
  getPayerOtherOccupationError,
  getErrorsForCampaignProduct,
  canProceedToInsuredInformation,
  getSelectedDisplayProduct,
  getIsOccupationsNotEmpty,
  isQQFlow,
  isAllowReviseQQMenu,
  (
    firstName: string,
    lastName: string,
    isPayerNeeded: boolean,
    titleErrors: string[],
    birthdateErrors: string[],
    payerBirthdateErrors: string[],
    insuredOccupationErrors: string[],
    payerOccupationErrors: string[],
    otherOccupationErrors: string[],
    payerOtherOccupationError: string[],
    errorsForCampaignProduct: string[],
    canProceedToInsuredInformation: boolean,
    selectedDisplayProduct: DisplayProduct,
    isOccupationNotEmpty: boolean,
    isQQFlow: boolean,
    isAllowReviseQQ: boolean
  ): boolean => {
    const errors =
      selectedDisplayProduct.type === VALUES.CAMPAIGN_PRODUCT_TYPE
        ? [
            ...errorsForCampaignProduct,
            ...insuredOccupationErrors,
            ...titleErrors,
            ...otherOccupationErrors,
            ...(isPayerNeeded ? payerBirthdateErrors : []),
            ...(isPayerNeeded ? payerOccupationErrors : []),
            ...(isPayerNeeded ? payerOtherOccupationError : []),
          ]
        : [
            ...birthdateErrors,
            ...insuredOccupationErrors,
            ...titleErrors,
            ...otherOccupationErrors,
            ...(isPayerNeeded ? payerBirthdateErrors : []),
            ...(isPayerNeeded ? payerOccupationErrors : []),
            ...(isPayerNeeded ? payerOtherOccupationError : []),
          ]

    const canProceed =
      errors.length === 0 &&
      canProceedToInsuredInformation &&
      (getToggles().ENABLE_OCCUPATION_BLANK ? isOccupationNotEmpty : true) &&
      (isQQFlow && isAllowReviseQQ ? true : !!firstName) &&
      (isQQFlow && isAllowReviseQQ ? true : !!lastName)
    return canProceed
  }
)
