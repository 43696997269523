//@flow
import { Redirect, Route, Switch } from 'react-router-dom'
import { compose, lifecycle } from 'recompose'
import { ROUTE_PATH } from '../../../constants/path'
import { getToggles } from '../../../feature-toggles'
import { NeedAndGapAnalysisContainer } from '../../../fna/need-and-gap-analysis'
import {
  FlexiHealthInsuredInformationContainer,
  HealthInsuredInformationContainer,
  HealthToppingInsuredInformationContainer,
  InvestmentInsuredInformationContainer,
  LifeSaveProInsuredInformationContainer,
  LoanInsuredInformationContainer,
  PerfectLifeInsuredInformationContainer,
  ProtectionInsuredInformationContainer,
  RetirementInsuredInformationContainer,
  SavingInsuredInformationContainer,
  WholeLifeInsuredInformationContainer,
} from '../../../insured-information'
import {
  BenefitIllustrationContainer as HealthToppingBenefitIllustrationContainer,
  BenefitIllustrationContainer as FlexiHealthBenefitIllustrationContainer,
} from '../../../product-flexi-health/benefit-illustration'
import { CoveragePlanContainer as FlexiHealthCoveragePlanContainer } from '../../../product-flexi-health/coverage-plan'
import { CoveragePlanContainer as HealthToppingCoveragePlanContainer } from '../../../product-health-topping/coverage-plan'
import { BenefitIllustrationContainer as HealthBenefitIllustrationContainer } from '../../../product-health/benefit-illustration'
import { CoveragePlanContainer as HealthCoveragePlanContainer } from '../../../product-health/coverage-plan'
import { InvestmentCoveragePlanContainer } from '../../../product-investment/coverage-plan'
import FundAllocationContainer from '../../../product-investment/fund-allocation/rpp/containers'
import { RiskContainer } from '../../../product-investment/risk-questionnaire'
import { BenefitIllustrationContainer as LifeSaveProBenefitIllustrationContainer } from '../../../product-life-save-pro/benefit-illustration'
import { CoveragePlanContainer as LifeSaveProCoveragePlanContainer } from '../../../product-life-save-pro/coverage-plan'
import { LoanBenefitIllustrationContainer } from '../../../product-loan/benefit-illustration'
import { LoanCoveragePlanContainer } from '../../../product-loan/coverage-plan'
import { CoveragePlanContainer as PerfectLifeCoveragePlanContainer } from '../../../product-perfect-life/coverage-plan'
import { BenefitIllustrationContainer as ProtectionBenefitIllustrationContainer } from '../../../product-protection/benefit-illustration'
import { CoveragePlanContainer as ProtectionCoveragePlanContainer } from '../../../product-protection/coverage-plan'
import { CoveragePlanContainer as RetirementCoveragePlanContainer } from '../../../product-retirement/coverage-plan'
import { BenefitIllustrationContainer as SavingBenefitIllustrationContainer } from '../../../product-saving/benefit-illustration'
import { CoveragePlanContainer as SavingCoveragePlanContainer } from '../../../product-saving/coverage-plan'
import { BasicPlanSelectionContainer } from '../../../product-selection'
import { CoveragePlanContainer as WholeLifeCoveragePlanContainer } from '../../../product-whole-life/coverage-plan'
// import InvestmentBenefitIllustrationContainer from '../../../product-investment/benefit-illustration/containers'
import { BenefitIllustrationContainer as InvestmentBenefitIllustrationContainer } from '../../../v2/products/categories/investment/benefit-illustration'
import { BenefitIllustrationContainer as PerfectLifeBenefitIllustrationContainer } from '../../../v2/products/categories/perfect-life/benefit-illustration'
import { BenefitIllustrationContainer as RetirementBenefitIllustrationContainer } from '../../../v2/products/categories/retirement/benefit-illustration'
import { BenefitIllustrationContainer as WholeLifeBenefitIllustrationContainer } from '../../../v2/products/categories/whole-life/benefit-illustration'

const redirectToLandingPage = ({ history, location }) => {
  if (history.action === 'POP' && location.pathname === ROUTE_PATH.WHOLE_LIFE.BENEFIT_ILLUSTRATION) {
    history.replace(ROUTE_PATH.PRODUCT_SELECTION)
  }
}

const OldQuickQuote = ({ logPageView }) => (
  <Switch>
    <Route path={ROUTE_PATH.PRODUCT_SELECTION} component={logPageView(BasicPlanSelectionContainer)} />
    <Route path={ROUTE_PATH.NEED_AND_GAP_ANALYSIS} component={logPageView(NeedAndGapAnalysisContainer)} />
    {/* === whole life === */}
    <Route
      path={ROUTE_PATH.WHOLE_LIFE.INSURED_INFORMATION}
      component={logPageView(WholeLifeInsuredInformationContainer)}
    />
    <Route path={ROUTE_PATH.WHOLE_LIFE.COVERAGE_PLAN} component={logPageView(WholeLifeCoveragePlanContainer)} />
    <Route path={ROUTE_PATH.WHOLE_LIFE.BENEFIT_ILLUSTRATION} component={WholeLifeBenefitIllustrationContainer} />
    {/* === protection === */}
    <Route
      path={ROUTE_PATH.PROTECTION.INSURED_INFORMATION}
      component={logPageView(ProtectionInsuredInformationContainer)}
    />
    <Route path={ROUTE_PATH.PROTECTION.COVERAGE_PLAN} component={logPageView(ProtectionCoveragePlanContainer)} />
    <Route path={ROUTE_PATH.PROTECTION.BENEFIT_ILLUSTRATION} component={ProtectionBenefitIllustrationContainer} />
    {/* === health === */}
    <Route path={ROUTE_PATH.HEALTH.INSURED_INFORMATION} component={logPageView(HealthInsuredInformationContainer)} />
    <Route path={ROUTE_PATH.HEALTH.COVERAGE_PLAN} component={logPageView(HealthCoveragePlanContainer)} />
    <Route path={ROUTE_PATH.HEALTH.BENEFIT_ILLUSTRATION} component={HealthBenefitIllustrationContainer} />
    {/* === investment === */}
    <Route
      path={ROUTE_PATH.INVESTMENT.INSURED_INFORMATION}
      component={logPageView(InvestmentInsuredInformationContainer)}
    />
    <Route path={ROUTE_PATH.INVESTMENT.RISK_QUESTIONNAIRE} component={logPageView(RiskContainer)} />
    <Route path={ROUTE_PATH.INVESTMENT.COVERAGE_PLAN} component={logPageView(InvestmentCoveragePlanContainer)} />
    <Route path={ROUTE_PATH.INVESTMENT.FUND_ALLOCATION} component={logPageView(FundAllocationContainer)} />
    <Route
      path={ROUTE_PATH.INVESTMENT.BENEFIT_ILLUSTRATION}
      component={logPageView(InvestmentBenefitIllustrationContainer)}
    />
    {/* === loan === */}
    <Route path={ROUTE_PATH.LOAN.INSURED_INFORMATION} component={logPageView(LoanInsuredInformationContainer)} />
    <Route path={ROUTE_PATH.LOAN.COVERAGE_PLAN} component={logPageView(LoanCoveragePlanContainer)} />
    <Route path={ROUTE_PATH.LOAN.BENEFIT_ILLUSTRATION} component={LoanBenefitIllustrationContainer} />
    {/* === saving === */}
    <Route path={ROUTE_PATH.SAVING.INSURED_INFORMATION} component={logPageView(SavingInsuredInformationContainer)} />
    <Route path={ROUTE_PATH.SAVING.COVERAGE_PLAN} component={logPageView(SavingCoveragePlanContainer)} />
    <Route path={ROUTE_PATH.SAVING.BENEFIT_ILLUSTRATION} component={SavingBenefitIllustrationContainer} />
    {/* === life-save-pro === */}
    <Route
      path={ROUTE_PATH.LIFE_SAVE_PRO.INSURED_INFORMATION}
      component={logPageView(LifeSaveProInsuredInformationContainer)}
    />
    <Route path={ROUTE_PATH.LIFE_SAVE_PRO.COVERAGE_PLAN} component={logPageView(LifeSaveProCoveragePlanContainer)} />
    <Route path={ROUTE_PATH.LIFE_SAVE_PRO.BENEFIT_ILLUSTRATION} component={LifeSaveProBenefitIllustrationContainer} />
    {/* === flexi-health === */}
    <Route
      path={ROUTE_PATH.FLEXI_HEALTH.INSURED_INFORMATION}
      component={logPageView(FlexiHealthInsuredInformationContainer)}
    />
    <Route path={ROUTE_PATH.FLEXI_HEALTH.COVERAGE_PLAN} component={logPageView(FlexiHealthCoveragePlanContainer)} />
    <Route path={ROUTE_PATH.FLEXI_HEALTH.BENEFIT_ILLUSTRATION} component={FlexiHealthBenefitIllustrationContainer} />

    {/* === retirement === */}
    <Route
      path={ROUTE_PATH.RETIREMENT.INSURED_INFORMATION}
      component={logPageView(RetirementInsuredInformationContainer)}
    />
    <Route path={ROUTE_PATH.RETIREMENT.COVERAGE_PLAN} component={logPageView(RetirementCoveragePlanContainer)} />
    <Route path={ROUTE_PATH.RETIREMENT.BENEFIT_ILLUSTRATION} component={RetirementBenefitIllustrationContainer} />

    {/* === perfect-life === */}
    <Route
      path={ROUTE_PATH.PERFECT_LIFE.INSURED_INFORMATION}
      component={logPageView(PerfectLifeInsuredInformationContainer)}
    />
    <Route path={ROUTE_PATH.PERFECT_LIFE.COVERAGE_PLAN} component={logPageView(PerfectLifeCoveragePlanContainer)} />
    <Route path={ROUTE_PATH.PERFECT_LIFE.BENEFIT_ILLUSTRATION} component={PerfectLifeBenefitIllustrationContainer} />

    {/* === health-topping === */}
    <Route
      path={ROUTE_PATH.HEALTH_TOPPING.INSURED_INFORMATION}
      component={logPageView(HealthToppingInsuredInformationContainer)}
    />
    <Route path={ROUTE_PATH.HEALTH_TOPPING.COVERAGE_PLAN} component={logPageView(HealthToppingCoveragePlanContainer)} />
    <Route
      path={ROUTE_PATH.HEALTH_TOPPING.BENEFIT_ILLUSTRATION}
      component={HealthToppingBenefitIllustrationContainer}
    />
    {!getToggles().ENABLE_FNA_FLOW && <Redirect from="/" to={ROUTE_PATH.PRODUCT_SELECTION} />}
  </Switch>
)

export default compose(
  lifecycle({
    componentDidMount() {
      redirectToLandingPage(this.props)
    },
    UNSAFE_componentWillReceiveProps(nextProps) {
      redirectToLandingPage(nextProps)
    },
  })
)(OldQuickQuote)
