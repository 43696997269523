// @flow
import { compact } from 'lodash/fp'
import _ from 'lodash'
import BIMESSAGES from 'core/data-model/constants/bi-messages'
import values from 'core/data-model/constants/values'

import { LegalConditionDisclaimerMarketConduct } from 'quick-quote/v2/components/disclaimers'
import ProfileSnapshot from 'quick-quote/product-common/benefit-illustration/components/profile-summary/profile-snapshot'
import ProductBenefits from 'quick-quote/product-whole-life/benefit-illustration/components/product-benefits/product-benefits'
import RiderBenefits from 'quick-quote/product-common/benefit-illustration/components/rider-benefits'
import TaxConsentDeductionBenefit from 'quick-quote/product-common/benefit-illustration/components/tax-consent-deduction-benefit'

import PolicyValueTableLifeReady from 'quick-quote/v2/components/benefit-illustration/policy-values/layout-with-cash-drop/policy-value-table-life-ready'

import { getProfileSnapshot } from 'quick-quote/product-common/benefit-illustration/components/profile-summary/selectors'
import { getProductBenefits } from 'quick-quote/product-whole-life/benefit-illustration/components/product-benefits/selectors'
import { getSelectedRiders } from 'quick-quote/product-common/coverage-plan/selectors'
import { getPolicyValueTableBenefits } from 'quick-quote/v2/components/benefit-illustration/policy-values/layout-with-cash-drop/selectors'
import { getTaxDeduction } from 'quick-quote/product-whole-life/benefit-illustration/selectors'
import { getLegalConditionDisclaimer } from 'quick-quote/v2/components/disclaimers/selectors'
import type { AppState } from 'quick-quote/reducers'

const _summaryHeaderWithCode = ({ displayProduct }: *) => {
  const name = _.get(displayProduct, 'displayName', '')
  const riderPlanCode = _.get(displayProduct, 'basicPlanCode', '')
  return `${name} (${riderPlanCode})`
}
const _summaryHeader = ({ displayProduct }): ?string => {
  return values.LIFEREADY_CAMPAIGN_CODE.includes(displayProduct.code)
    ? BIMESSAGES.LIFE_READY_PACKAGE_NAME
    : values.LIFETREASURE_GROUP_CODE.includes(displayProduct.code)
    ? BIMESSAGES.LIFE_TREASURE_NAME_THAI
    : displayProduct.displayName
}

export const getSections = (state: AppState) => {
  const riders = getSelectedRiders(state)
  return compact([
    {
      component: ProfileSnapshot,
      props: {
        ...getProfileSnapshot(state),
        profileSummaryHeader: _summaryHeaderWithCode,
        summaryTableHeader: _summaryHeader,
      },
    },
    {
      component: ProductBenefits,
      props: {
        ...getProductBenefits(state),
      },
    },
    riders.length > 0 && {
      component: RiderBenefits,
    },
    {
      component: PolicyValueTableLifeReady,
      props: {
        ...getPolicyValueTableBenefits(state),
      },
    },
    {
      component: TaxConsentDeductionBenefit,
      props: {
        taxDeduction: getTaxDeduction(state),
      },
    },
    {
      component: LegalConditionDisclaimerMarketConduct,
      props: {
        ...getLegalConditionDisclaimer(state),
      },
    },
  ])
}

export const getMenus = (state: AppState) => [
  {
    text: BIMESSAGES.SUMMARY_OF_MAIN_AND_ADDITIONAL_CONTRACTS,
    id: 'profile-summary',
    isSub: false,
  },
  {
    text: 'รายละเอียดของสัญญาหลัก',
    id: 'product-benefits',
    isSub: false,
  },
  {
    text: BIMESSAGES.POLICY_VALUE_TABLE,
    id: 'policy-value',
    isSub: false,
  },
  {
    text: BIMESSAGES.LEGAL_DISCLAIMER,
    id: 'legal-disclaimer-market-conduct',
    isSub: false,
  },
]
