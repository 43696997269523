// @flow

import { set, get } from 'lodash/fp'

import { isReviseQQFlow } from 'quick-quote/selectors'
import { getExchangeQuestion, getQuestionnaire, totalScore } from './product-investment/risk-questionnaire/selectors'
import { getRppOverRiskConsent, getRppForeignConsent } from './product-investment/fund-allocation/rpp/selectors'

export const qqToApp = (state, productCategory, advisor) => {
  const getFromState = (k) => get(k)(state)
  let app = {
    advisor,
    policy: {
      productCategory,
      insured: {
        firstName: getFromState('insured.firstName.name'),
        lastName: getFromState('insured.lastName.name'),
        gender: getFromState('insured.gender'),
      },
      payer: state.insured.payer,
      investment: state.investment,
    },
  }

  const isReviseQQ = isReviseQQFlow(state)
  if (isReviseQQ) {
    app = set('policy.insured.firstName', getFromState('insured.firstName.defaultValue'))(app)
    app = set('policy.insured.lastName', getFromState('insured.lastName.defaultValue'))(app)
  }

  app = set(
    'policy.investment.investmentRisk.riskLevel',
    getFromState('investment.investmentRisk.riskEvaluated.value')
  )(app)

  // TODO: Remove duplication on document structure funds
  const rpqScore = totalScore(getQuestionnaire(state))
  const acceptExchangeRate = getExchangeQuestion(state).selectedChoice === 1
  app = set('policy.investment.investmentRisk.rpqScore', rpqScore)(app)
  app = set('policy.investment.investmentRisk.acceptExchangeRate', acceptExchangeRate)(app)
  app = set('policy.coveragePlan.funds', getFromState('investment.funds'))(app)
  // Backward compatibility with old RPQ that contain risk answers
  app = set('policy.investment.investmentRisk.allowHigherRiskInvestment', getRppOverRiskConsent(state))(app)
  app = set('policy.investment.investmentRisk.allowForeignInvestment', getRppForeignConsent(state))(app)
  return app
}
