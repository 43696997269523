//@flow
import _ from 'lodash'
import { formatNumber } from 'core/service/lib/number-format'
import { isString, isObject } from 'core/service/lib/type-check'

import { buildHeaderTable } from 'core/service/pdf-generation/utils/builder'
import { getESCIExceptionNotes, getWPEExceptionNotes } from 'core/service/rider/benefits/exception-notes'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { RiderBenefitData, Benefit } from 'core/data-model/rider/benefits'

import MESSAGES from 'core/data-model/constants/bi-messages'
import { getToggles } from 'quick-quote/feature-toggles'

type EsciWpeBenefitProps = {
  ESCIRider: Rider & RiderState,
  riderBenefitData: RiderBenefitData,
}

const getHeaderColumns = (ESCIRider: Rider & RiderState): string[] => {
  return [MESSAGES.BENEFIT_LIST, `แผน ${formatNumber(ESCIRider.sumAssured)}`]
}

export const buildBenefitsRow = (benefit: Benefit, isDisclaimer: boolean) => {
  const description = benefit.description && isString(benefit.description) ? benefit.description : ''
  const value = isObject(benefit) && benefit.value ? benefit.value : ''

  if (value === '') {
    return [{ text: description, colSpan: 2, bold: !isDisclaimer, italics: isDisclaimer }]
  } else {
    return [description, { text: value, bold: true, alignment: 'right' }]
  }
}

const buildExceptionNotes = () => {
  const exceptionESCINotes = getESCIExceptionNotes()
  const exceptionWPENotes = getWPEExceptionNotes()
  const [
    exceptionESCINotesFirstLine,
    exceptionESCINotesSecondLine,
    exceptionESCINotesConditionDesc,
  ] = exceptionESCINotes.exceptions
  const [
    exceptionWPENotesFirstLine,
    exceptionWPENotesSecondLine,
    exceptionWPENotesConditionDesc,
  ] = exceptionWPENotes.exceptions

  return {
    stack: [
      { text: MESSAGES.EXCEPTION_NOTE, style: 'sub-title' },
      { text: exceptionESCINotesFirstLine },
      { text: exceptionESCINotesSecondLine },
      { ol: [...exceptionESCINotesConditionDesc] },
      { text: exceptionWPENotesFirstLine, margin: [0, 12, 0, 0] },
      { text: exceptionWPENotesSecondLine },
      { ol: [...exceptionWPENotesConditionDesc], margin: [0, 0, 0, 12] },
    ],
  }
}

const buildAdditionalNotes = () => {
  return {
    stack: [
      {
        text: MESSAGES.iCareMessages.ADDITIONAL_NOTE_HEADER,
        style: 'sub-title',
      },
      { text: MESSAGES.iCareMessages.PROTECT_WHEN, bold: true },
      {
        text: [{ text: MESSAGES.iCareMessages.PASS_60_DAYS, bold: true }, MESSAGES.iCareMessages.PASS_60_DAYS_DETAIL],
      },
      {
        text: [{ text: MESSAGES.iCareMessages.PASS_90_DAYS, bold: true }, MESSAGES.iCareMessages.PASS_90_DAYS_DETAIL],
      },
      {
        text: [
          MESSAGES.iCareMessages.CRITICAL_ILLNESS_CLIAM_NOTE,
          MESSAGES.iCareMessages.PASS_ONE_YEAR,
          MESSAGES.iCareMessages.START_FROM,
        ],
        bold: true,
      },
      {
        text: MESSAGES.iCareMessages.FIRST_CRITICAL_ILLNESS,
      },
      { text: getSecondCriticalIllness() },
    ],
  }
}

export const getSecondCriticalIllness = () => {
  return getToggles().ENABLE_ICARE_REPRICING === true
    ? MESSAGES.iCareMessages.SECOND_CRITICAL_ILLNESS_NEW
    : MESSAGES.iCareMessages.SECOND_CRITICAL_ILLNESS
}

export const buildTable = (ESCIRider: Rider & RiderState, riderBenefitData: RiderBenefitData) => {
  const benefits = _.get(riderBenefitData, 'benefits')
  const isDisclaimerRow = (benefitIndex: number) => benefitIndex === 1
  return {
    headerRows: 1,
    widths: ['42%', '58%'],
    body: [
      buildHeaderTable(getHeaderColumns(ESCIRider)),
      ...benefits.map((benefit, index) => buildBenefitsRow(benefit, isDisclaimerRow(index))),
    ],
  }
}

export const ESCIWPEBenefitSection = ({ ESCIRider, riderBenefitData }: EsciWpeBenefitProps) => {
  const exceptionNotes = buildExceptionNotes()
  return [
    {
      table: buildTable(ESCIRider, riderBenefitData),
      layout: 'lightBorder',
      style: 'table',
      pageBreak: 'after',
    },
    { ...buildAdditionalNotes() },
    { ...exceptionNotes, marginTop: 15 },
  ]
}
