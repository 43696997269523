import axios from 'axios'
import { getAppConfig } from 'deploy-env/app-config'
import { getAppVersion } from 'quick-quote/selectors'
import { getToggles } from 'quick-quote/feature-toggles'

export const instance = axios.create()

const returnData = ({ status, data }) => ({ data, statusCode: status })

const appVersionHeaders = () => {
  const appVersion = getAppVersion()
  return {
    'Application-Version': appVersion,
  }
}

const authorizationHeaders = (user) => {
  return {
    Authorization: `Bearer ${user.access_token}`,
  }
}

const getRequestHeaders = (user) => {
  return getToggles().ENABLE_AUTHORISATION_CODE_FLOW
    ? { ...appVersionHeaders() }
    : { ...authorizationHeaders(user), ...appVersionHeaders() }
}
/* istanbul ignore next */
export default {
  postRequest(path, user, data) {
    return instance
      .post(`${getAppConfig().ADVISORZONE_MIDDLEWARE}/${path}`, data, {
        headers: getRequestHeaders(user),
        currentHref: window.location.href,
      })
      .then(returnData)
  },
  getRequest(path, user, params, config = {}) {
    return instance
      .get(`${getAppConfig().ADVISORZONE_MIDDLEWARE}/${path}`, {
        ...config,
        params,
        headers: getRequestHeaders(user),
        currentHref: window.location.href,
      })
      .then(returnData)
  },
  patchRequest(path, user, data, headers) {
    return instance
      .patch(`${getAppConfig().ADVISORZONE_MIDDLEWARE}/${path}`, data, {
        headers: {
          ...getRequestHeaders(user),
          ...headers,
        },
        currentHref: window.location.href,
      })
      .then(returnData)
  },
  putRequest(path, user, data) {
    return instance
      .put(`${getAppConfig().ADVISORZONE_MIDDLEWARE}/${path}`, data, {
        headers: getRequestHeaders(user),
        currentHref: window.location.href,
      })
      .then(returnData)
  },
  deleteRequest(path, user, params) {
    return instance
      .delete(`${getAppConfig().ADVISORZONE_MIDDLEWARE}/${path}`, {
        params,
        headers: getRequestHeaders(user),
        currentHref: window.location.href,
      })
      .then(returnData)
  },
}
