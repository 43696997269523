// @flow
import { IGROWRiderBenefit } from 'quick-quote/product-common/benefit-illustration/components/rider-benefits/rider-benefit-containers'
import MESSAGES from 'core/data-model/constants/bi-messages'

const RidersBenefit = () => {
  return (
    <div id="riders-benefit" className="rider-summary group">
      <h3>{MESSAGES.RIDERS_BENEFITS_IGROW}</h3>
      <IGROWRiderBenefit />
    </div>
  )
}

export default RidersBenefit
