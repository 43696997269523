// @flow
import { createSelector } from 'reselect'
import { isBancAgent } from 'identity/selectors'
import { getLegalDisclaimerEndDate } from 'quick-quote/product-whole-life/benefit-illustration/selectors'
import { getcoveragePlanStartDate } from 'quick-quote/product-common/coverage-plan/selectors'
import type { LegalDisclaimerProps } from './index'

export const getLegalConditionDisclaimer = createSelector(
  getcoveragePlanStartDate,
  getLegalDisclaimerEndDate,
  isBancAgent,
  (getcoveragePlanStartDate, getLegalDisclaimerEndDate, isBancAgent): LegalDisclaimerProps => ({
    startDate: getcoveragePlanStartDate,
    endDate: getLegalDisclaimerEndDate,
    isBancAgent,
  })
)
