import _ from 'lodash'
import { DOCUMENT_TYPE } from 'e-submission/domain/data-model/constants'
import { createSectionCreator, createField } from 'e-submission/domain/data-model/form/utils'

export const documentFields = (banc) => (app, id) => {
  return [
    createField(`${banc}.${id}`, [
      {
        id: `${banc}.${id}`,
        c: 'PhotoCard',
        required: true,
        p: {
          mergeState: (state, app) => {
            const documentTypeText = _.get(DOCUMENT_TYPE, 'banc.SUITABILITY_FORM.text')
            const documentTypeName = _.get(DOCUMENT_TYPE, 'banc.SUITABILITY_FORM.name')

            return {
              value: [
                {
                  id: `${banc}.${id}.${documentTypeName}`,
                  name: documentTypeName,
                  description: documentTypeText,
                  checked: _.has(app, ['_attachments', documentTypeName]),
                },
              ],
            }
          },
        },
      },
    ]),
  ]
}

export const bancDocumentSection = createSectionCreator(documentFields('banc'))
