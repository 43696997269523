// @flow
import _ from 'lodash'
import VALUES from 'core/data-model/constants/values'
import MESSAGES from 'core/data-model/constants/bi-messages'

const InvestmentBenefitTable = ({ displayProduct }) => {
  const basicPlanCode = _.get(displayProduct, 'basicPlanCode', '')
  let header
  switch (basicPlanCode) {
    case VALUES.MRTA_CODE.MRTA_FULL:
      header = MESSAGES.HEADER_BENEFIT_TABLE_MRTA_FULL
      break
    case VALUES.MRTA_CODE.MRTA_PLUS:
      header = MESSAGES.HEADER_BENEFIT_TABLE_MRTA_PLUS
      break
    case VALUES.MRTA_CODE.MRTA_TRUNCATE:
      header = MESSAGES.HEADER_BENEFIT_TABLE_MRTA_PLUS
      break
    default:
      header = MESSAGES.HEADER_BENEFIT_TABLE_DEFAULT
      break
  }
  return (
    <div className="group" id="investment-benefit">
      <h3>{header}</h3>
      <div className="benefit-details-table">
        <table>
          <thead>
            <tr>
              <td style={{ textAlign: 'left' }}>{MESSAGES.TABLE_HEADER_BASIC_PLAN_DETAIL}</td>
              <td>{MESSAGES.BENEFIT_TITLE}</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{MESSAGES.TABLE_DETAIL_BENEFIT_BASIC_PLAN[0][0]}</td>
              <td>{MESSAGES.TABLE_DETAIL_BENEFIT_BASIC_PLAN[0][1]}</td>
            </tr>
            <tr>
              <td>{MESSAGES.TABLE_DETAIL_BENEFIT_BASIC_PLAN[1][0]}</td>
              <td>{MESSAGES.TABLE_DETAIL_BENEFIT_BASIC_PLAN[1][1]}</td>
            </tr>
            <tr>
              <td>
                <ul>{MESSAGES.TABLE_DETAIL_BENEFIT_BASIC_PLAN[2][0]}</ul>
              </td>
              <td>{MESSAGES.TABLE_DETAIL_BENEFIT_BASIC_PLAN[2][1]}</td>
            </tr>
            <tr>
              <td>
                <ul>{MESSAGES.TABLE_DETAIL_BENEFIT_BASIC_PLAN[3][0]}</ul>
              </td>
              <td>{MESSAGES.TABLE_DETAIL_BENEFIT_BASIC_PLAN[3][1]}</td>
            </tr>
            <tr>
              <td>
                <ul>{MESSAGES.TABLE_DETAIL_BENEFIT_BASIC_PLAN[4][0]}</ul>
              </td>
              <td>{MESSAGES.TABLE_DETAIL_BENEFIT_BASIC_PLAN[4][1]}</td>
            </tr>
            <tr>
              <td>
                <ul>{MESSAGES.TABLE_DETAIL_BENEFIT_BASIC_PLAN[5][0]}</ul>
              </td>
              <td>{MESSAGES.TABLE_DETAIL_BENEFIT_BASIC_PLAN[5][1]}</td>
            </tr>
          </tbody>
        </table>

        {/* plan Full will not display */}
        {VALUES.MRTA_CODE.MRTA_FULL !== displayProduct.basicPlanCode && (
          <table style={{ marginTop: '-22px' }}>
            <thead>
              <tr>
                <td colSpan="2" style={{ textAlign: 'left' }}>
                  {MESSAGES.TABLE_HEADER_RIDER_ACCIDENT}
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{MESSAGES.TABLE_DETAIL_RIDER_ACCIDENT[0]}</td>
                <td>{MESSAGES.TABLE_DETAIL_RIDER_ACCIDENT[1]}</td>
              </tr>
              <tr>
                <td colSpan="2" style={{ textAlign: 'left' }}>
                  กรณีเกิดอุบัติเหตุสาธารณภัยรับความคุ้มครองอุบัติเหตุเป็น{' '}
                  <b>
                    <u>2 เท่า</u>
                  </b>{' '}
                  ของความคุ้มครองอุบัติเหตุ
                </td>
              </tr>
            </tbody>
          </table>
        )}

        <div className="disclaimer">
          <text>
            {MESSAGES.NOTE}: {MESSAGES.REMARK_BENEFIT_TABLE_MRTA[0]}
          </text>
          {VALUES.MRTA_CODE.MRTA_FULL !== displayProduct.basicPlanCode && (
            <ul style={{ paddingLeft: '56px' }}>{MESSAGES.REMARK_BENEFIT_TABLE_MRTA[1]}</ul>
          )}
        </div>
      </div>
    </div>
  )
}

export default InvestmentBenefitTable
