// @flow

import _ from 'lodash'
import { d } from 'core/service/lib/decimal'
import { isNotNil } from 'core/service/lib/type-check'
import { roundDown, round } from 'core/service/lib/number-format'
import findUntil from 'core/service/lib/find-until'
import { dataWrapper } from 'core/data'
import { getRiskiestOccupation, STANDARD_OCCUPATION } from 'core/service/insured'
import { getDiscountByOccupationFactor } from './discount'
import VALUES from 'core/data-model/constants/values'
import { getBasicPlan } from 'core/service'
import { getAppConfig } from 'deploy-env/app-config'
import { queryPremiumRate } from 'core/service/rider/rider'
import { isMRTAGroup, isGLTSPGroup } from 'quick-quote/product-mrta/coverage-plan/selectors'
import type {
  DiscountRate,
  PremiumRate,
  DiscountRateWithPremium,
  BasicPremiumParams,
  BasicSumAssuredParams,
} from 'core/data-model/basic-plan'
import type { DisplayProductQuery } from 'core/service/display-product'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { Age, Gender } from 'core/data-model/insured'

const PRECISION = 2

export const validateBasicPremiumParameters = ({
  sumAssured,
  occupationFactor,
  premiumRate,
  discount,
  rateUnit,
  modelFactor,
}: Object): boolean =>
  _.some(
    [sumAssured, occupationFactor, premiumRate, discount, rateUnit, modelFactor],
    (value) => isNotNil(value) && value >= 0
  )

export const validateBasicSumAssuredParameters = ({
  premium,
  occupationFactor,
  premiumRate,
  discount,
  rateUnit,
  modelFactor,
}: Object): boolean =>
  _.some(
    [premium, occupationFactor, premiumRate, discount, rateUnit, modelFactor],
    (value) => isNotNil(value) && value >= 0
  )

export const _lookUpBasicPremiumRate = (
  premiumRates: PremiumRate[],
  basicPlanCode: string,
  gender: Gender,
  age: Age
): number => {
  const basicPlanPremiumRates = premiumRates.find(({ productCode }) => productCode === basicPlanCode)

  if (!basicPlanPremiumRates) {
    return 0
  }
  const ageValue = age.unit !== 'year' ? 0 : age.value
  const premiumRate = basicPlanPremiumRates[gender][ageValue.toString()]

  return premiumRate || 0
}

export const _lookUpBasicPremiumRateMRTA = (
  premiumRates: PremiumRate[],
  basicPlanCode: string,
  gender: Gender,
  age: Age,
  planCode: string
): number => {
  const basicPlanPremiumRates = premiumRates.find(({ productCode }) => productCode === basicPlanCode)

  if (!basicPlanPremiumRates) {
    return 0
  }

  const ageValue = age.unit !== 'year' ? 0 : age.value

  let qryPlanCode = planCode
  if (isGLTSPGroup(basicPlanCode)) {
    qryPlanCode = planCode.substring(0, 5)
  }
  if (basicPlanPremiumRates['premiumRates'][qryPlanCode] === undefined) {
    return 0
  }

  const premiumRate = basicPlanPremiumRates['premiumRates'][qryPlanCode][gender][ageValue.toString()]
  return premiumRate || 0
}

export const lookUpBasicPremiumRate = (basicPlanCode: string, gender: Gender, age: Age): Promise<number> => {
  return dataWrapper.getPremiumRates().then((premiumRates) => {
    return _lookUpBasicPremiumRate(premiumRates, basicPlanCode, gender, age)
  })
}

export const lookUpBasicPremiumRateMRTA = (
  basicPlanCode: string,
  gender: Gender,
  age: Age,
  planCode: string
): Promise<number> => {
  return dataWrapper.getPremiumRates().then((premiumRates) => {
    return _lookUpBasicPremiumRateMRTA(premiumRates, basicPlanCode, gender, age, planCode)
  })
}

export const lookUpRiderPremiumRateMRTA = (
  availableRiders: (Rider & RiderState)[],
  gender: Gender,
  age: Age
): Promise<number> => {
  return dataWrapper.getRiderPremiumRates().then((riderPremiumRatesPayload) => {
    if (availableRiders.length === 0) {
      return 0
    }
    const rider = availableRiders[0]
    if (!rider.isSelected || !rider.isSelectable) {
      return 0
    }
    const riderPremiumRates = _.find(riderPremiumRatesPayload, (r) => r.riderCode === rider.code)
    const queryRiderPremiumRateDependencies = {
      gender: gender,
      selectedBasicPlanCode: 'basic',
      selectedRiderPlanCode: rider.selectedPlan.planCode,
      yearsOfCoverage: rider.yearsOfCoverage,
      age: age,
      basicYearsOfPayment: 0,
      waivePeriod: 0,
    }
    return riderPremiumRates ? queryPremiumRate(rider, riderPremiumRates, queryRiderPremiumRateDependencies) : 0
  })
}

export const _getRiskOccupationForProduct = async (
  displayProductQuery: DisplayProductQuery,
  natureOfDuties: string[]
) => {
  const basicPlan = await getBasicPlan(displayProductQuery)
  return _.includes(VALUES.PLANCODE_EXCEPT_PREMIUM_OCCUPATION_FACTOR, basicPlan.productCode)
    ? STANDARD_OCCUPATION
    : await getRiskiestOccupation(displayProductQuery, natureOfDuties)
}

export const _enhanceCalculateBasicPremiumGLTSP = (
  sumAssured: number,
  premiumRate: number,
  rateUnit: number,
  loan: LoanState,
  riderPremiumRate: number
): number => {
  const sumAssuredDecimal = d(sumAssured)
  const premiumRateDecimal = d(premiumRate)
  const rateUnitDecimal = d(rateUnit)

  if (loan.coveragePlan.coverage.paymentMethod === VALUES.CASH_INCLUDE_LOAN) {
    return round(
      d(((premiumRateDecimal / (1000 - premiumRateDecimal)) * rateUnitDecimal * sumAssuredDecimal) / rateUnitDecimal),
      PRECISION
    )
  } else {
    return round(d((sumAssuredDecimal * d(roundDown(premiumRateDecimal, PRECISION))) / rateUnitDecimal), PRECISION)
  }
}

export const _enhanceCalculateBasicPremiumMRTA = (
  sumAssured: number,
  premiumRate: number,
  rateUnit: number,
  loan: LoanState,
  riderPremiumRate: number
): number => {
  const sumAssuredDecimal = d(sumAssured)
  const premiumRateDecimal = d(premiumRate)
  const rateUnitDecimal = d(rateUnit)

  if (loan.coveragePlan.coverage.paymentMethod === VALUES.CASH_INCLUDE_LOAN) {
    const dividedSA = 1 - premiumRateDecimal / 1000 - riderPremiumRate / 2000
    return round((premiumRateDecimal * round(sumAssuredDecimal / dividedSA, 0)) / rateUnitDecimal, PRECISION)
  } else {
    return round(
      d(roundDown(premiumRateDecimal, 4))
        .times(sumAssuredDecimal)
        .dividedBy(rateUnitDecimal)
        .toNumber(),
      PRECISION
    )
  }
}

export const _enhanceCalculateBasicPremium = (
  sumAssured: number,
  premiumRate: number,
  discount: number,
  rateUnit: number,
  modelFactor: number,
  occupationFactor: number,
  campaignDiscount: number
): number => {
  const sumAssuredDecimal = d(sumAssured)
  const premiumRateDecimal = d(premiumRate)
  const discountDecimal = d(discount)
  const rateUnitDecimal = d(rateUnit)
  const modelFactorDecimal = d(modelFactor)
  const occupationFactorDecimal = d(occupationFactor)
  const campaignDiscountDecimal = d(campaignDiscount)

  let basicPremium
  if (d(premiumRate) <= 0) {
    return 0
  }
  if (campaignDiscount > 0) {
    const baseBasicPremium = d(
      roundDown(
        premiumRateDecimal
          .minus(discountDecimal)
          .plus(occupationFactorDecimal)
          .times(roundDown(sumAssuredDecimal.dividedBy(rateUnitDecimal), 3))
          .toNumber(),
        PRECISION
      )
    )
    const discountPremium = d(
      roundDown(
        baseBasicPremium
          .times(campaignDiscountDecimal)
          .dividedBy(d(100))
          .toNumber(),
        PRECISION
      )
    )
    const baseBasicPremiumDiscount = baseBasicPremium.minus(discountPremium)
    basicPremium = roundDown(baseBasicPremiumDiscount.times(modelFactorDecimal).toNumber(), PRECISION)
  } else {
    basicPremium =
      d(premiumRate) > 0
        ? roundDown(
            d(
              roundDown(
                premiumRateDecimal
                  .minus(discountDecimal)
                  .plus(occupationFactorDecimal)
                  .times(roundDown(sumAssuredDecimal.dividedBy(rateUnitDecimal), 3))
                  .toNumber(),
                PRECISION
              )
            )
              .times(modelFactorDecimal)
              .toNumber(),
            PRECISION
          )
        : 0
  }
  return basicPremium
}

export const _calculateBasicPremium = (
  sumAssured: number,
  premiumRate: number,
  discount: number,
  rateUnit: number,
  modelFactor: number,
  occupationFactor: number
): number => {
  const sumAssuredDecimal = d(sumAssured)
  const premiumRateDecimal = d(premiumRate)
  const discountDecimal = d(discount)
  const rateUnitDecimal = d(rateUnit)
  const modelFactorDecimal = d(modelFactor)
  const occupationFactorDecimal = d(occupationFactor)

  return d(premiumRate) > 0
    ? roundDown(
        d(
          round(
            premiumRateDecimal
              .minus(discountDecimal)
              .plus(occupationFactorDecimal)
              .times(sumAssuredDecimal)
              .dividedBy(rateUnitDecimal)
              .toNumber(),
            PRECISION
          )
        )
          .times(modelFactorDecimal)
          .toNumber(),
        PRECISION
      )
    : 0
}

export const calculateMRTASumAssuredContract = async ({
  sumAssured,
  premiumRate,
  loan,
  riderPremiumRate,
}): Promise<number> => {
  const sumAssuredDecimal = d(sumAssured)
  const premiumRateDecimal = d(premiumRate)

  if (loan.coveragePlan.coverage.paymentMethod === VALUES.CASH_INCLUDE_LOAN) {
    const dividedSA = 1 - premiumRateDecimal / 1000 - riderPremiumRate / 2000
    return round(sumAssuredDecimal / dividedSA, 0)
  } else {
    return sumAssuredDecimal
  }
}

export const calculateGLTSPSumAssuredContract = async ({ sumAssured, basicPremium, loan }): Promise<number> => {
  if (loan.coveragePlan.coverage.paymentMethod === VALUES.CASH_INCLUDE_LOAN) {
    return round(sumAssured + basicPremium)
  } else {
    return sumAssured
  }
}

export const calculateBasicPremium = async ({
  displayProductQuery,
  sumAssured,
  natureOfDutyCodes,
  premiumRate,
  discount,
  rateUnit,
  modelFactor,
  loan,
  riderPremiumRate,
  campaignDiscount,
}: BasicPremiumParams): Promise<number> => {
  const occupationFactor = await _getRiskOccupationForProduct(displayProductQuery, natureOfDutyCodes)
  if (isMRTAGroup(displayProductQuery.code)) {
    return _enhanceCalculateBasicPremiumMRTA(sumAssured, premiumRate, rateUnit, loan, riderPremiumRate)
  }

  if (isGLTSPGroup(displayProductQuery.code)) {
    return _enhanceCalculateBasicPremiumGLTSP(sumAssured, premiumRate, rateUnit, loan, riderPremiumRate)
  }
  return getAppConfig().ENHANCE_BASIC_CALCULATE_PRODUCT.includes(displayProductQuery.code)
    ? _enhanceCalculateBasicPremium(
        sumAssured,
        premiumRate,
        discount,
        rateUnit,
        modelFactor,
        occupationFactor,
        campaignDiscount
      )
    : _calculateBasicPremium(sumAssured, premiumRate, discount, rateUnit, modelFactor, occupationFactor)
}

const hasNoError = (riderPlan: Rider & RiderState): boolean => {
  return _.isEmpty(riderPlan.errors)
}

const hasOnlyAdditionalInformation = (riderPlan: Rider & RiderState): boolean => {
  return riderPlan.errors.every((r) => r.type === VALUES.ADDITIONAL_INFORMATION)
}

export const totalRiderPremium = (availableRiders: (Rider & RiderState)[]) => {
  return availableRiders
    .filter((riderPlan) => riderPlan.isSelected)
    .filter((riderPlan) => hasNoError(riderPlan) || hasOnlyAdditionalInformation(riderPlan))
    .reduce((totalPremium, riderPlan) => {
      const totalPremiumDecimal = d(totalPremium)
      const riderPlanPremiumDecimal = d(riderPlan.premium)
      const modulePremium = _.get(riderPlan, 'selectedPlan.modules', [])
        .filter((m) => m.isSelected === true)
        .reduce(
          (totalPremium, modulePlan) =>
            d(totalPremium)
              .plus(d(modulePlan.premium || 0))
              .toNumber(),
          0
        )

      return totalPremiumDecimal
        .plus(riderPlanPremiumDecimal)
        .plus(modulePremium)
        .toNumber()
    }, 0)
}

export const calculateTotalPremium = (basicPremium: number, riderPremium: number): number =>
  d(basicPremium)
    .plus(d(riderPremium))
    .toNumber()

export const generateDiscountRateWithPremium = (
  premium: number,
  premiumRate: number,
  discount: number,
  rateUnit: number,
  modelFactor: number,
  occupationFactor: number,
  discountRates: DiscountRate[]
): DiscountRateWithPremium[] => {
  if (discountRates.length === 0) return []

  const defaultDiscountRate: DiscountRate = {
    discount: 0,
    maxSumAssured: discountRates[0].minSumAssured,
    minSumAssured: 0,
  }
  return [defaultDiscountRate].concat(discountRates).map((discountRate) => {
    const sumAssured = discountRate.minSumAssured
    const discount = discountRate.discount
    const basicPremium = _calculateBasicPremium(
      sumAssured,
      premiumRate,
      discount,
      rateUnit,
      modelFactor,
      occupationFactor
    )
    return {
      ...discountRate,
      premium: basicPremium,
    }
  })
}

export const generateEnhanceDiscountRateWithPremium = (
  premium: number,
  premiumRate: number,
  discount: number,
  rateUnit: number,
  modelFactor: number,
  occupationFactor: number,
  discountRates: DiscountRate[],
  campaignDiscount: number
): DiscountRateWithPremium[] => {
  if (discountRates.length === 0) return []

  const defaultDiscountRate: DiscountRate = {
    discount: 0,
    maxSumAssured: discountRates[0].minSumAssured,
    minSumAssured: 0,
  }
  return [defaultDiscountRate].concat(discountRates).map((discountRate) => {
    const sumAssured = discountRate.minSumAssured
    const discount = discountRate.discount
    const basicPremium = _enhanceCalculateBasicPremium(
      sumAssured,
      premiumRate,
      discount,
      rateUnit,
      modelFactor,
      occupationFactor,
      campaignDiscount
    )
    return {
      ...discountRate,
      premium: basicPremium,
    }
  })
}

export const getDiscountForPremium = (discountRates: DiscountRateWithPremium[], basicPremium: number): number => {
  if (discountRates.length === 0) return 0
  return _(discountRates)
    .chain()
    .reverse()
    .find((discountRate: DiscountRateWithPremium) => {
      return discountRate.premium <= basicPremium
    })
    .get('discount', 0)
    .value()
}

export const calculatePredictedBasicSumAssured = (
  premium: number,
  premiumRate: number,
  discount: number,
  rateUnit: number,
  modelFactor: number,
  occupationFactor: number
): number => {
  const premiumDecimal = d(premium)
  const premiumRateDecimal = d(premiumRate)
  const discountDecimal = d(discount)
  const rateUnitDecimal = d(rateUnit)
  const modelFactorDecimal = d(modelFactor)
  const occupationFactorDecimal = d(occupationFactor)

  return Math.ceil(
    premiumDecimal
      .mul(rateUnitDecimal)
      .div(modelFactorDecimal.mul(premiumRateDecimal.plus(occupationFactorDecimal).minus(discountDecimal)))
      .toNumber()
  )
}

export const calculateActualBasicSumAssured = (
  sumAssured: number,
  premiumRate: number,
  discount: number,
  rateUnit: number,
  modelFactor: number,
  occupationFactor: number,
  basicPremium: number
) => {
  const premium = _calculateBasicPremium(sumAssured, premiumRate, discount, rateUnit, modelFactor, occupationFactor)
  const initialValue = { sumAssured, premium }
  const stopFunction = ({ premium: currentPremium }, { premium: nextPremium }) => {
    if (currentPremium === 0) return true
    return Math.abs(basicPremium - nextPremium) > Math.abs(basicPremium - currentPremium)
  }
  const findNextPremium = ({ sumAssured }) => ({
    sumAssured: sumAssured + 1,
    premium: _calculateBasicPremium(sumAssured + 1, premiumRate, discount, rateUnit, modelFactor, occupationFactor),
  })
  const resultValue = findUntil(findNextPremium, stopFunction, initialValue)
  return resultValue.sumAssured || 0
}

export const calculateEnhanceActualBasicSumAssured = (
  sumAssured: number,
  premiumRate: number,
  discount: number,
  rateUnit: number,
  modelFactor: number,
  occupationFactor: number,
  basicPremium: number,
  campaignDiscount: number
) => {
  const premium = _enhanceCalculateBasicPremium(
    sumAssured,
    premiumRate,
    discount,
    rateUnit,
    modelFactor,
    occupationFactor,
    campaignDiscount
  )
  const initialValue = { sumAssured, premium }
  const stopFunction = ({ premium: currentPremium }, { premium: nextPremium }) => {
    if (currentPremium === 0) return true
    return Math.abs(basicPremium - nextPremium) > Math.abs(basicPremium - currentPremium)
  }
  const findNextPremium = ({ sumAssured }) => ({
    sumAssured: sumAssured + 1,
    premium: _enhanceCalculateBasicPremium(
      sumAssured + 1,
      premiumRate,
      discount,
      rateUnit,
      modelFactor,
      occupationFactor,
      campaignDiscount
    ),
  })
  const resultValue = findUntil(findNextPremium, stopFunction, initialValue)
  return resultValue.sumAssured || 0
}

export const _calculateBasicSumAssured = (
  premium: number,
  premiumRate: number,
  discount: number,
  rateUnit: number,
  modelFactor: number,
  occupationFactor: number,
  discountRates: DiscountRate[]
) => {
  const discountRatesWithPremium: DiscountRateWithPremium[] = generateDiscountRateWithPremium(
    premium,
    premiumRate,
    discount,
    rateUnit,
    modelFactor,
    occupationFactor,
    discountRates
  )

  const discountFromPremium: number = getDiscountByOccupationFactor({
    occupationFactor,
    discount: getDiscountForPremium(discountRatesWithPremium, premium),
  })

  const predictedBasicSumAssured = calculatePredictedBasicSumAssured(
    premium,
    premiumRate,
    discountFromPremium,
    rateUnit,
    modelFactor,
    occupationFactor
  )

  return calculateActualBasicSumAssured(
    predictedBasicSumAssured,
    premiumRate,
    discountFromPremium,
    rateUnit,
    modelFactor,
    occupationFactor,
    premium
  )
}

export const _enhanceCalculateBasicSumAssured = (
  premium: number,
  premiumRate: number,
  discount: number,
  rateUnit: number,
  modelFactor: number,
  occupationFactor: number,
  discountRates: DiscountRate[],
  campaignDiscount: number
) => {
  const discountRatesWithPremium: DiscountRateWithPremium[] = generateEnhanceDiscountRateWithPremium(
    premium,
    premiumRate,
    discount,
    rateUnit,
    modelFactor,
    occupationFactor,
    discountRates,
    campaignDiscount
  )

  const discountFromPremium: number = getDiscountByOccupationFactor({
    occupationFactor,
    discount: getDiscountForPremium(discountRatesWithPremium, premium),
  })

  const predictedBasicSumAssured = calculatePredictedBasicSumAssured(
    premium,
    premiumRate,
    discountFromPremium,
    rateUnit,
    modelFactor,
    occupationFactor
  )

  return calculateEnhanceActualBasicSumAssured(
    predictedBasicSumAssured,
    premiumRate,
    discountFromPremium,
    rateUnit,
    modelFactor,
    occupationFactor,
    premium,
    campaignDiscount
  )
}

export const calculateBasicSumAssured = async (
  basicSumAssuredParams: BasicSumAssuredParams,
  discountRates: DiscountRate[],
  campaignDiscount: number
) => {
  const occupationFactor = await _getRiskOccupationForProduct(
    basicSumAssuredParams.displayProductQuery,
    basicSumAssuredParams.natureOfDutyCodes
  )
  return getAppConfig().ENHANCE_BASIC_CALCULATE_PRODUCT.includes(
    basicSumAssuredParams.displayProductQuery.basicPlanCode
  )
    ? _enhanceCalculateBasicSumAssured(
        basicSumAssuredParams.premium,
        basicSumAssuredParams.premiumRate,
        basicSumAssuredParams.discount,
        basicSumAssuredParams.rateUnit,
        basicSumAssuredParams.modelFactor,
        occupationFactor,
        discountRates,
        campaignDiscount
      )
    : _calculateBasicSumAssured(
        basicSumAssuredParams.premium,
        basicSumAssuredParams.premiumRate,
        basicSumAssuredParams.discount,
        basicSumAssuredParams.rateUnit,
        basicSumAssuredParams.modelFactor,
        occupationFactor,
        discountRates
      )
}
