//@flow
import _ from 'lodash'
import { getToggles } from 'quick-quote/feature-toggles'
import { PATH } from '../../constants/path'
import type { Tab } from '../navigation-item'
import getFNANavTabConfigs from './fna/tabs'
import getOldQQNavTabConfigs from './old-quick-quote/tabs'
import type { NavTabConfig } from './quick-quote/tabs'
import getQQNavTabConfigs from './quick-quote/tabs'
import getQQv2NavTabConfigs from './quick-quote/v2/tabs'

export const isQuickQuotePath = (path: string): boolean => {
  return _.includes(path, PATH.QUICK_QUOTE)
}

export const isFNAPath = (path: string): boolean => {
  return _.includes(path, PATH.FNA)
}

const getTabsFromMatchingNav = (navConfigList: NavTabConfig[], path): Tab[] => {
  const navConfig = navConfigList.find((qqNav) => _.includes(path, qqNav.path))
  return navConfig ? navConfig.tabs : []
}

export const getNavTabList = ({ location, state }: Object): Tab[] => {
  const path = location.pathname
  if (isQuickQuotePath(path)) {
    return getToggles().ENABLE_FNA_QQ_NAV_V2
      ? getTabsFromMatchingNav(getQQv2NavTabConfigs(state), path)
      : getTabsFromMatchingNav(getQQNavTabConfigs(state), path)
  } else if (isFNAPath(path)) {
    return getTabsFromMatchingNav(getFNANavTabConfigs(state), path)
  } else {
    return getTabsFromMatchingNav(getOldQQNavTabConfigs(state), path)
  }
}
