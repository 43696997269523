// @flow
import type { SumAssured$, BasicPremium$, CoveragePeriod$, InterestRate$ } from 'core/data-model/basic-plan'
import values from 'core/data-model/constants/values'

export const isZero = (validated: SumAssured$ | BasicPremium$ | CoveragePeriod$) => {
  return validated.value === 0
}

export const hasError = (errors: Array<{ type: string, message: string }>) => {
  return errors.filter(({ type }) => type !== values.ADDITIONAL_INFORMATION).length > 0
}

export const enableBasicSumAssured = (haveBasicPremiumState: boolean, basicPremium$: BasicPremium$) => {
  return haveBasicPremiumState ? basicPremium$.isOk || isZero(basicPremium$) : true
}

export const enableBasicPremium = (haveBasicPremiumState: boolean, sumAssured$: SumAssured$) => {
  return haveBasicPremiumState ? true : sumAssured$.isOk || isZero(sumAssured$)
}

export const enableCoveragePeriod = (haveBasicPremiumState: boolean, coveragePeriod$: CoveragePeriod$) => {
  return haveBasicPremiumState ? coveragePeriod$.isOk || isZero(coveragePeriod$) : true
}

export const enableInterestRate = (haveBasicPremiumState: boolean, interestRate$: InterestRate$) => {
  return haveBasicPremiumState ? interestRate$.isOk : true
}

export const isInvalid = (haveBasicPremiumState: boolean, sumAssured$: SumAssured$, basicPremium$: BasicPremium$) => {
  return (
    !enableBasicSumAssured(haveBasicPremiumState, basicPremium$) ||
    !enableBasicPremium(haveBasicPremiumState, sumAssured$) ||
    (!isZero(basicPremium$) && !basicPremium$.isOk) ||
    (!isZero(sumAssured$) && !sumAssured$.isOk)
  )
}
