// @flow

export const delimiter = '#0=0#'

export const spiltByDecorator = (openDecorator: string, closeDecorator: string, text: string): string[] => {
  const tmpDelimiter = '#0,0#'
  const openDecoratorRegX = new RegExp(openDecorator, 'g')
  const closeDecoratorRegX = new RegExp(closeDecorator, 'g')
  const decorator = openDecorator
    .replace('#', '')
    .replace(/{/g, '')
    .replace(/}/g, '')

  const replaced = text
    .replace(openDecoratorRegX, tmpDelimiter + decorator + delimiter)
    .replace(closeDecoratorRegX, tmpDelimiter)
  return replaced.split(tmpDelimiter)
}

export const addDecorator = (source: string[]) => {
  return {
    text: source.map((item) => {
      if (item.search(delimiter) === -1) {
        return item
      }

      const [decoration, text] = item.split(delimiter)
      return { text, decoration }
    }),
  }
}

export const addUnderlineDecorator = (source: string) => {
  return addDecorator(spiltByDecorator('{{#underline}}', '{{/underline}}', source))
}
