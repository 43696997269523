// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Dropdown } from 'common-components'

import { getCoverageTerm, getCoverageTermErrors } from 'quick-quote/product-loan/coverage-plan/redux'
import { getOptionsCoveragePeriod } from 'quick-quote/product-mrta/coverage-plan/selectors'
import { editCoveragePeriod } from 'quick-quote/product-mrta/actions'

type CoveragePeriodProps = {
  optionsCoveragePeriod: Object[],
  coveragePeriod: number,
  coveragePeriodError: string[],
  editCoveragePeriod: (number) => void,
}

const CoveragePeriod = ({
  optionsCoveragePeriod,
  coveragePeriod,
  coveragePeriodError,
  editCoveragePeriod,
}: CoveragePeriodProps) => {
  return (
    // <div>
    <Dropdown
      // className="basic-plan form-control"
      className="coverage-period"
      options={optionsCoveragePeriod}
      onChange={editCoveragePeriod}
      value={coveragePeriod}
    />
    //   {coveragePeriodError.map((message, index) => (
    //     <FormFeedback className={'validation-message attached-icon error-message'} key={`${index}`}>
    //       <p>{message}</p>
    //     </FormFeedback>
    //   ))}
    // </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  optionsCoveragePeriod: getOptionsCoveragePeriod(state),
  coveragePeriod: getCoverageTerm(state),
  coveragePeriodError: getCoverageTermErrors(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  editCoveragePeriod: bindActionCreators(editCoveragePeriod, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CoveragePeriod)
