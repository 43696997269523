import { connect } from 'react-redux'
import { getUserAgentType, isLoggedIn } from 'identity/selectors'
import values from 'core/data-model/constants/values'
import { withProps } from 'recompose'

const enhance = connect((state) => ({
  currentUserRole: getUserAgentType(state),
  isCurrentUserLoggedIn: isLoggedIn(state),
}))

const IfRole = enhance((props) => {
  const { is, isNot, allowGuest, currentUserRole, render, isLoggedIn, isCurrentUserLoggedIn } = props
  if (allowGuest && !isCurrentUserLoggedIn) return render()
  if (isLoggedIn && isCurrentUserLoggedIn && currentUserRole === is) return render()
  if (isLoggedIn && isCurrentUserLoggedIn && currentUserRole !== isNot) return render()
  return null
})
IfRole.displayName = 'IfRole'

export const ROLE_AGENT = values.AGENT_TYPE_AGENT
export const ROLE_FSA = values.AGENT_TYPE_FSA
export const ROLE_LB = values.AGENT_TYPE_LB

export const IfRoleIsLB = withProps({ is: ROLE_LB })(IfRole)
export const IfRoleIsNotLB = withProps({ isNot: ROLE_LB })(IfRole)
export const IfRoleIsAgent = withProps({ is: ROLE_AGENT })(IfRole)
export const IfRoleIsFSA = withProps({ is: ROLE_FSA })(IfRole)

export default IfRole
