import styled from 'styled-components'
import { ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import Dialog from 'e-submission/components/Dialog'

const _CustomDialog = styled(Dialog)`
  &.modal-dialog {
    @media (min-width: 576px) {
      max-width: 625px;
    }
  }
  .modal-title {
    flex: 1 0 auto;
    font-weight: bold;
    text-align: center;
    color: ${({ theme }) => theme.variables['$brand-primary']};
  }
  .modal-body {
    padding: 30px 16px;
    text-align: center;
    background-color: #fff;
  }
  .modal-body-content {
    padding-bottom: 0;
  }
  .info {
    font-weight: bold;
    color: ${({ theme }) => theme.variables['$brand-primary']};
  }
  .modal-footer {
    border-top: none;
    justify-content: center;
  }
  .btn {
    text-transform: unset;
  }
`

const CustomDialog = ({
  toggle,
  isOpen,
  className,
  onConfirm,
  noTitle = false,
  message: { title = '', body = '' },
  content,
}) => (
  <_CustomDialog toggle={toggle} className={className} modalClassName="default-bootstrap" isOpen={isOpen} backdrop>
    {!noTitle && (
      <ModalHeader tag="div" toggle={toggle}>
        {title}
      </ModalHeader>
    )}
    {content ? (
      <ModalBody className="modal-body-content">{content}</ModalBody>
    ) : (
      <ModalBody className={noTitle ? 'info' : ''} dangerouslySetInnerHTML={{ __html: body }} />
    )}
    <ModalFooter>
      <Button color="primary" onClick={onConfirm}>
        ยืนยัน
      </Button>
    </ModalFooter>
  </_CustomDialog>
)

export default CustomDialog
