//@flow
import type { ConfirmToHomeNotification } from '../notification/actions'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { Modal } from '../../common-components'
import { backToHome } from 'quick-quote/actions'

type ConfirmToHomeProps = {
  notificationMessage: ConfirmToHomeNotification,
  onExit: Function,
  backToHome: () => void,
}

const Warning = styled.p`
  color: red;
`

export const _ConfirmToHomePopup = ({ notificationMessage, onExit, backToHome, ...props }: ConfirmToHomeProps) => (
  <Modal
    isOpen={notificationMessage !== 'None'}
    backdrop={true}
    size="lg"
    modalClassName="default-bootstrap remote-selling-modal"
    toggle={() => {
      onExit()
    }}
  >
    <ModalHeader
      toggle={() => {
        onExit()
      }}
    >
      ยืนยันกลับสู่หน้าหลัก
    </ModalHeader>
    <ModalBody>
      <p>ท่านต้องการกลับไปยังหน้าแรกหรือไม่</p>
      <Warning>*หมายเหตุ ข้อมูลที่ท่านระบุมาจะถูกลบทั้งหมด</Warning>
    </ModalBody>
    <ModalFooter>
      <div className="bottom">
        <Button
          color="outline-primary"
          onClick={() => {
            backToHome()
            onExit()
          }}
        >
          ต้องการกลับสู่หน้าแรก
        </Button>
        <Button
          color="outline-primary"
          onClick={() => {
            onExit()
          }}
        >
          ไม่ต้องการ
        </Button>
      </div>
    </ModalFooter>
  </Modal>
)

const mapDispatchToConfirmToHomePopupProps = (dispatch: Dispatch<*>) => ({
  backToHome: bindActionCreators(backToHome, dispatch),
})

export default withRouter(connect(null, mapDispatchToConfirmToHomePopupProps)(_ConfirmToHomePopup))
