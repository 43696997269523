// @flow

import DEFAULTS from 'core/data-model/constants/defaults'
import type {
  ExpectedReturnComparison,
  LumpSumValidationResult,
  RegularPremium,
  RegularTopUp,
  SumAssured,
} from 'core/service/investment'
import { disallowLumpSumInputByYear } from 'core/service/investment'

import { RESET_APPLICATION_STATE } from 'quick-quote/actions'
import { combineReducers } from 'redux'
import type { UpdateBirthdate } from '../../insured-information/actions'
import { UPDATE_BIRTHDATE } from '../../insured-information/actions'
import type { SelectProduct } from '../../product-selection/actions'
import { SELECT_PRODUCT } from '../../product-selection/actions'
import type { Action } from './actions'
import {
  EDIT_REGULAR_PREMIUM,
  EDIT_REGULAR_PREMIUM_RATIO,
  EDIT_REGULAR_TOP_UP,
  EDIT_SUM_ASSURED,
  RESET_EXPECTED_RETURN_COMPARISONS,
  RESET_RPUDR_EXPECTED_RETURN_COMPARISONS,
  SELECT_MODEL_FACTOR,
  UPDATE_EXPECTED_RETURN_COMPARISONS,
  UPDATE_LUMP_SUMS,
  UPDATE_REGULAR_PREMIUM,
  UPDATE_REGULAR_TOP_UP,
  UPDATE_RPUDR_EXPECTED_RETURN_COMPARISONS,
  UPDATE_SUM_ASSURED,
  PREVIOUS_MODEL_FACTOR,
} from './actions'

export type StateProduct = {|
  regularPremium: RegularPremium,
  regularTopUp: RegularTopUp,
  regularPremiumRatio: number,
  sumAssured: SumAssured,
|}

type ExpectedReturnComparisonsState = {
  +value: ExpectedReturnComparison[],
  +errors: string[],
}

export type State = {
  +selectedBasicPlan: StateProduct,
  +selectedModelFactorID: string,
  +previousModelFactorID: string,
  +expectedReturnComparisons: ExpectedReturnComparisonsState,
}

const initialState = { value: 0, errors: [] }
export const sumAssured = (state: SumAssured = initialState, action: Action | SelectProduct) => {
  switch (action.type) {
    case SELECT_PRODUCT:
      return initialState
    case EDIT_SUM_ASSURED:
      return {
        ...state,
        value: action.payload,
      }
    case UPDATE_SUM_ASSURED:
      return action.payload
    default:
      return state
  }
}

export const regularTopUp = (state: RegularTopUp = initialState, action: Action | SelectProduct) => {
  switch (action.type) {
    case SELECT_PRODUCT:
      return initialState
    case EDIT_REGULAR_TOP_UP:
      return {
        ...state,
        value: action.payload,
      }
    case UPDATE_REGULAR_TOP_UP:
      return action.payload
    default:
      return state
  }
}

export const regularPremium = (state: RegularPremium = initialState, action: Action | SelectProduct) => {
  switch (action.type) {
    case SELECT_PRODUCT:
      return initialState
    case EDIT_REGULAR_PREMIUM:
      return {
        ...state,
        value: action.payload,
      }
    case UPDATE_REGULAR_PREMIUM:
      return action.payload
    default:
      return state
  }
}

export const regularPremiumRatio = (state: number = DEFAULTS.INVESTMENT_REGULAR_PREMIUM_RATIO, action: Action) => {
  switch (action.type) {
    case EDIT_REGULAR_PREMIUM_RATIO:
      return action.payload
    default:
      return state
  }
}

const defaultModelFactor = DEFAULTS.SELECTED_MODEL_FACTOR
export const selectedModelFactorID = (state: string = defaultModelFactor, action: Action | SelectProduct) => {
  switch (action.type) {
    case SELECT_PRODUCT: {
      if (action.payload.code === 'SPUL') return DEFAULTS.SELECTED_MODEL_FACTOR_SPUL

      return defaultModelFactor
    }
    case RESET_APPLICATION_STATE:
      return defaultModelFactor
    case SELECT_MODEL_FACTOR:
      return action.payload
    default:
      return state
  }
}

export const previousModelFactorID = (state: string = defaultModelFactor, action: Action | SelectProduct) => {
  switch (action.type) {
    case SELECT_PRODUCT: {
      if (action.payload.code === 'SPUL') return DEFAULTS.SELECTED_MODEL_FACTOR_SPUL
      return defaultModelFactor
    }
    case RESET_APPLICATION_STATE:
      return defaultModelFactor
    case PREVIOUS_MODEL_FACTOR:
      return action.payload
    default:
      return state
  }
}

const updateLumpSumsState = (
  state: ExpectedReturnComparisonsState,
  lumpSums: LumpSumValidationResult
): ExpectedReturnComparisonsState => {
  const value = state.value.map((row) => {
    const newLumpSum = lumpSums.values.find(({ year }) => year === row.year)

    if (newLumpSum != null && newLumpSum.value != null) {
      const lumpSum = { value: newLumpSum.value, isValid: newLumpSum.isValid }
      return { ...row, lumpSum }
    } else {
      return row
    }
  })

  return { value, errors: lumpSums.errors }
}

const initialExpectedReturn = {
  year: 1,
  age: 0,
  accumulatedTotalPremium: 0,
  benefitByExpectedReturn: {
    '-1': { accountValueAfterBonus: 0, deathBenefit: 0 },
    '2': { accountValueAfterBonus: 0, deathBenefit: 0 },
    '5': { accountValueAfterBonus: 0, deathBenefit: 0 },
    '3': { accountValueAfterBonus: 0, deathBenefit: 0 },
  },
  lumpSum: 'DISALLOWED',
}

const initialRPUDRExpectedReturn = {
  year: 1,
  age: 0,
  accumulatedTotalPremium: 0,
  benefitByExpectedReturn: {
    '-1': { accountValueAfterBonus: 0, deathBenefit: 0 },
    '2': { accountValueAfterBonus: 0, deathBenefit: 0 },
    '5': { accountValueAfterBonus: 0, deathBenefit: 0 },
    '3': { accountValueAfterBonus: 0, deathBenefit: 0 },
  },
  lumpSum: {
    value: 0,
    isValid: true,
  },
}

const initialExpectedReturnState = { value: [initialRPUDRExpectedReturn], errors: [] }

const resetExpectedReturnComparisons = (state: ExpectedReturnComparisonsState): ExpectedReturnComparisonsState => {
  const value = state.value
    .map((row) => ({
      ...initialExpectedReturn,
      age: row.age,
      year: row.year,
      lumpSum: row.lumpSum,
    }))
    .map(disallowLumpSumByYear)

  return { ...state, value }
}

const resetRPUDRExpectedReturnComparisons = (state: ExpectedReturnComparisonsState): ExpectedReturnComparisonsState => {
  const value = state.value.map((row) => ({
    ...initialRPUDRExpectedReturn,
    age: row.age,
    year: row.year,
    lumpSum: row.lumpSum,
  }))

  return { ...state, value }
}

const disallowLumpSumByYear = (expectedReturnComparison: ExpectedReturnComparison) => {
  const { year, lumpSum } = expectedReturnComparison
  return {
    ...expectedReturnComparison,
    lumpSum: disallowLumpSumInputByYear(year, lumpSum),
  }
}

const updateExpectedReturnComparisonsState = (
  state: ExpectedReturnComparisonsState,
  expectedReturnComparisons: ExpectedReturnComparisonWithoutLumpSum[]
): ExpectedReturnComparisonsState => {
  const value = expectedReturnComparisons
    .map((row) => {
      const oldExpectedReturnComparison = state.value.find(({ year }) => year === row.year)

      if (oldExpectedReturnComparison != null) {
        return { ...row, lumpSum: oldExpectedReturnComparison.lumpSum }
      } else {
        return { ...row, lumpSum: { value: 0, isValid: true } }
      }
    })
    .map(disallowLumpSumByYear)

  return { ...state, value }
}

const updateUDRExpectedReturnComparisonsState = (
  state: ExpectedReturnComparisonsState,
  expectedReturnComparisons: ExpectedReturnComparisonWithoutLumpSum[]
): ExpectedReturnComparisonsState => {
  const value = expectedReturnComparisons.map((row) => {
    const oldExpectedReturnComparison = state.value.find(({ year }) => year === row.year)

    if (oldExpectedReturnComparison != null) {
      return { ...row, lumpSum: oldExpectedReturnComparison.lumpSum }
    } else {
      return { ...row, lumpSum: { value: 0, isValid: true } }
    }
  })

  return { ...state, value }
}

export const expectedReturnComparisons = (
  state: ExpectedReturnComparisonsState = initialExpectedReturnState,
  action: Action | UpdateBirthdate | SelectProduct
): ExpectedReturnComparisonsState => {
  switch (action.type) {
    case UPDATE_LUMP_SUMS:
      return updateLumpSumsState(state, action.payload)
    case SELECT_PRODUCT:
    case UPDATE_BIRTHDATE:
      return initialExpectedReturnState
    case UPDATE_EXPECTED_RETURN_COMPARISONS:
      return updateExpectedReturnComparisonsState(state, action.payload)
    case UPDATE_RPUDR_EXPECTED_RETURN_COMPARISONS:
      return updateUDRExpectedReturnComparisonsState(state, action.payload)
    case RESET_EXPECTED_RETURN_COMPARISONS:
      return resetExpectedReturnComparisons(state)
    case RESET_RPUDR_EXPECTED_RETURN_COMPARISONS:
      return resetRPUDRExpectedReturnComparisons(state)
    default:
      return state
  }
}

export const selectedBasicPlan = combineReducers({
  regularPremium,
  regularTopUp,
  sumAssured,
  regularPremiumRatio,
})

export const reducer = combineReducers({
  selectedBasicPlan,
  selectedModelFactorID,
  previousModelFactorID,
  expectedReturnComparisons,
})
