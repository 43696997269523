import _ from 'lodash'
import styled from 'styled-components'
import checkCircle from 'react-icons/lib/md/check-circle'
import radioChecked from 'react-icons/lib/md/radio-button-checked'
import radioUnChecked from 'react-icons/lib/md/lens'
import add from 'react-icons/lib/md/add-circle-outline'

import cashPayment from '!svg-react-loader!assets/images/payment/bills_dollar_blue.svg'
import edcPayment from '!svg-react-loader!assets/images/payment/edc-mpos.svg'
import creditCardPayment from '!svg-react-loader!assets/images/payment/credit_card_blue.svg'
import ktaxaLogo from '!svg-react-loader!assets/images/logo-ktaxa-th.svg'
import omiseLogo from '!svg-react-loader!assets/images/omise-logo.svg'

import attach from 'react-icons/lib/md/attach-file'
import arrowDown from 'react-icons/lib/md/keyboard-arrow-down'
import groupSignature from '!svg-react-loader!assets/images/form/group_signature.svg'
import sign from '!svg-react-loader!assets/images/form/sign.svg'
import camera from '!svg-react-loader!assets/images/form/camera.svg'
import documentUploadDesktop from 'assets/images/form/docs-upload-desktop.png'
import documentUploadMobile from 'assets/images/form/docs-upload-mobile.png'
import iconIdCard from '!svg-react-loader!assets/images/form/icon_id-card.svg'
import secondaryDocumentUpload from 'assets/images/form/secondary-doc-upload.png'
import arrowLeft from '@axah/web-toolkit/icons/arrow-left.svg'
import arrowRight from '@axah/web-toolkit/icons/arrow-right.svg'
import cross from '@axah/web-toolkit/icons/cross.svg'

import edit from 'react-icons/lib/md/edit'
import disclaimer from 'assets/images/form/disclaimer.png'
import error from 'assets/images/form/error.png'
import formInvalid from 'assets/images/form/form-invalid.png'
import creditCardError from 'assets/images/form/credit_card_error.png'
import offline from 'assets/images/form/no-internet.png'
import playArrow from 'react-icons/lib/md/play-arrow'
import wifi from 'react-icons/lib/md/wifi'
import clear from 'react-icons/lib/md/clear'

import _illustrate from '!svg-react-loader!assets/images/nav/icon-Illustrate-Idle.svg'
import _delete from 'react-icons/lib/md/delete'
import _check from '!svg-react-loader!assets/images/form/check.svg'
import _keys from '!svg-react-loader!assets/images/form/keys.svg'
import _agreement from '!svg-react-loader!assets/images/form/agreement.svg'
import _padlock from '!svg-react-loader!assets/images/form/padlock.svg'
import _magnify from '!svg-react-loader!assets/images/form/magnifier.svg'

const Image = styled.img`
  display: block;
  height: auto;
  width: 100%;
  margin: 0 auto;
  max-width: 200px;
  ${({ theme }) => theme.media.md`max-width: 300px;`}
`

const ImageSmall = styled.img`
  display: block;
  height: auto;
  width: 100%;
  margin: 0 auto;
  max-width: 150px;
  ${({ theme }) => theme.media.md`max-width: 250px;`}
`

const baseIcon = (Icon) => styled(({ className, ...props }) => <Icon xxxxx className={className} />)`
  width: 100%;
  max-width: 40px;
  max-height: 40px;
`
const illustrate = baseIcon(_illustrate)
const check = baseIcon(_check)
const agreement = baseIcon(_agreement)
const padlock = baseIcon(_padlock)
const keys = styled(({ className, ...props }) => (
  <div className={className}>
    {' '}
    <div className="keys">
      <_keys />
    </div>
  </div>
))`
  color: ${({ theme }) => theme.variables['$color-crimson']};
  width: 100%;
  margin: 0 auto;
  max-width: 200px;
  ${({ theme }) => theme.media.md`max-width: 300px;`}

  .keys {
    width: 100%;
    height: 100%;
    padding: 34px;
    border-radius: 50%;
    background: white;
  }

  svg {
    height: 100%;
    width: 100%;
    path {
      fill: currentColor;
    }
  }
`

const magnify = baseIcon(_magnify).extend`
  path {
    stroke-width: 1.65;
  }
`

const Icon = {
  cashPayment,
  edcPayment,
  creditCardPayment,
  checkCircle,
  radioChecked,
  radioUnChecked,
  add,
  remove: _delete,
  check,
  groupSignature,
  sign,
  magnify,
  camera,
  edit,
  disclaimer: () => <Image src={disclaimer} alt="disclaimer" />,
  error: () => <Image src={error} alt="error" />,
  formInvalid: () => <Image src={formInvalid} alt="formInvalid" />,
  offline: () => <Image src={offline} alt="offline" />,
  creditCardError: () => <Image src={creditCardError} alt="creditCardError" />,
  documentUploadDesktop: () => <Image src={documentUploadDesktop} alt="Document upload desktop" />,
  documentUploadMobile: () => <Image src={documentUploadMobile} alt="Document upload mobile" />,
  secondaryDocumentUpload: () => <ImageSmall src={secondaryDocumentUpload} alt="Secondary document upload" />,
  iconIdCard,
  attach,
  arrowLeft,
  arrowDown,
  arrowRight,
  illustrate,
  playArrow,
  wifi,
  keys,
  agreement,
  padlock,
  clear,
  cross,
  ktaxaLogo,
  omiseLogo,
}

export default Icon
export const getIcon = (iconName) => {
  const GotIcon = _.get(Icon, iconName)
  return GotIcon ? <GotIcon /> : 'Icon is not found.'
}
