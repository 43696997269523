// @flow

import type { DisplayProduct } from 'core/service/display-product'
import type { Rider, RiderState } from 'core/data-model/rider'

import { createSelector } from 'reselect'
import { getSelectedDisplayProduct } from 'quick-quote/product-selection/selectors'
import { getSelectedRiders } from 'quick-quote/product-common/coverage-plan/selectors'
import values from 'core/data-model/constants/values'

export const getProfileSummaryHeader = createSelector(
  getSelectedDisplayProduct,
  getSelectedRiders,
  (displayProduct: DisplayProduct, riders: (Rider & RiderState)[]): string => {
    const mhpRider = riders.find((rider) => rider.code === values.MHP)

    const planName = mhpRider
      ? // $$FlowFixMe
        `${mhpRider.selectedPlan.plan} (${mhpRider.selectedPlan.planCode})`
      : ''
    return `${displayProduct.displayName} ${planName}`
  }
)
