// @flow

import { Link, Redirect } from 'react-router-dom'

import type { DisplayProduct } from 'core/service/display-product'
import type { PermissionResult } from 'core/service/distributor/permission'
import { BasicPlanComponent } from './basic-plan'
import MESSAGES from 'core/data-model/constants/messages'

import { PermissionDenyErrorBox } from './permission-deny-error-box'
import { ProductCategorySelection } from 'quick-quote/components'
import { getToggles } from 'quick-quote/feature-toggles'
import { ROUTE_PATH } from 'quick-quote/constants/path'

type BasicPlanSelectionProps = {
  displayProducts: DisplayProduct[],
  selectedProductCode: string,
  selectProduct: (DisplayProduct) => void,
  selectProductCategory: (categoryId: string) => void,
  selectedProductCategoryId: string,
  validateInvestmentProduct: (DisplayProduct) => void,
  validateOrdinaryProduct: (DisplayProduct) => void,
  validateNormalProduct: (DisplayProduct) => void,
  allowProceedInvestment: PermissionResult,
  allowProceedOrdinary: PermissionResult,
  location: Object,
  isOnFnaPhase: boolean,
  hideHistory?: boolean,
  isSelected?: boolean,
  displayCategory?: boolean,
  productCategories?: Object[],
  availableCategories?: string[],
  availablePlanCode?: string[],
  isUserLogin: boolean,
  isUserOnline: boolean,
}

const isSelected = (displayProduct: DisplayProduct, selectedProductCode: string): boolean => {
  return displayProduct.code === selectedProductCode
}

const isDisabledProduct = (availablePlanCode, displayProduct) =>
  !!(availablePlanCode && availablePlanCode.length && !availablePlanCode.find((d) => d === displayProduct.code))

export const BasicPlanSelection = ({
  displayProducts,
  productCategories,
  selectedProductCode,
  selectProduct,
  displayCategory,
  selectProductCategory,
  selectedProductCategoryId,
  validateInvestmentProduct,
  validateOrdinaryProduct,
  validateNormalProduct,
  allowProceedInvestment,
  allowProceedOrdinary,
  hideHistory,
  availableCategories,
  availablePlanCode,
  isUserLogin,
  isUserOnline,
  location,
  isOnFnaPhase,
}: BasicPlanSelectionProps) => {
  if (getToggles().ENABLE_FORCE_LOGIN_FIRST) {
    if (!isUserOnline || !isUserLogin) {
      return <Redirect to={ROUTE_PATH.PORTAL} push />
    }
  }

  return (
    <div id="product-selection">
      <div className="content">
        {!hideHistory && (
          <Link to="/e-submission/list" className="list-title">
            <span className="text">{MESSAGES.GO_TO_POLICY_LIST}</span>
          </Link>
        )}
        <PermissionDenyErrorBox permissionResult={allowProceedInvestment} />
        <PermissionDenyErrorBox permissionResult={allowProceedOrdinary} />
        {displayCategory ? (
          <ProductCategorySelection
            categoryList={productCategories}
            availableCategories={availableCategories}
            active={selectedProductCategoryId}
            onChange={(categoryId) => selectProductCategory(categoryId)}
          />
        ) : null}
        <div className="az-product-list product-list">
          {displayProducts.map((displayProduct) => (
            <BasicPlanComponent
              key={displayProduct.code}
              location={location}
              isOnFnaPhase={isOnFnaPhase}
              isSelected={isSelected(displayProduct, selectedProductCode)}
              displayProduct={displayProduct}
              selectProduct={selectProduct}
              validateInvestmentProduct={validateInvestmentProduct}
              validateOrdinaryProduct={validateOrdinaryProduct}
              validateNormalProduct={validateNormalProduct}
              disabled={isDisabledProduct(availablePlanCode, displayProduct)}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
