// @flow
import _ from 'lodash'

import { d } from 'core/service/lib/decimal'
import type { Age } from 'core/data-model/insured'
import type { RPURates, PENRates, ETIRates } from 'core/data-model/basic-plan/types'

type RPUValue = {
  RPUSumAssured: number,
  RPUCashpaid: number,
}

type ETIValue = {
  ETIMaturity: number,
  ETICashpaid: number,
  ETIYear: number,
  ETIDay: number,
}

export const calculateRPU = (RPURate: number, sumAssured: number): RPUValue => {
  if (RPURate < 0) return { RPUSumAssured: 0, RPUCashpaid: 0 }
  const RPURateSA = RPURate > 1000 ? 1000 : RPURate
  const RPURateCashpaid = RPURate > 1000 ? RPURate - 1000 : 0

  const multiplyResult = d(sumAssured).dividedBy(1000)
  return {
    RPUSumAssured: d(multiplyResult)
      .times(d(RPURateSA))
      .toNumber(),
    RPUCashpaid: d(multiplyResult)
      .times(d(RPURateCashpaid))
      .toNumber(),
  }
}

export const calculateETI = (ETIRate: number, PENRate: number, sumAssured: number): ETIValue => {
  const PENRateMaturity = PENRate > 1000 ? 1000 : PENRate < 0 ? 0 : PENRate
  const PENRateCashpaid = PENRate > 1000 ? PENRate - 1000 : 0

  const multiplyResult = d(sumAssured).dividedBy(1000)

  return {
    ETIMaturity: d(multiplyResult)
      .times(d(PENRateMaturity))
      .toNumber(),
    ETICashpaid: d(multiplyResult)
      .times(d(PENRateCashpaid))
      .toNumber(),
    ETIYear: Math.floor(ETIRate / 1000),
    ETIDay: ETIRate % 1000,
  }
}

export const findRPURate = (productCode: string, RPURates?: RPURates[]) =>
  _.find(RPURates, (rate) => rate.productCode === productCode)

export const findPENRate = (productCode: string, PENRates?: PENRates[]) =>
  _.find(PENRates, (rate) => rate.productCode === productCode)

export const findETIRate = (productCode: string, ETIRates?: ETIRates[]) =>
  _.find(ETIRates, (rate) => rate.productCode === productCode)

export const queryRPURate = (gender: string, age: Age, policyYear: number, filteredRPURates?: RPURates): number => {
  const queryAge: number = age.unit !== 'year' ? 0 : age.value

  return _.get(filteredRPURates, ['RPURates', gender, queryAge.toString(), policyYear.toString()], 0)
}

export const queryPENRate = (gender: string, age: Age, policyYear: number, filteredPENRates?: PENRates): number => {
  const queryAge: number = age.unit !== 'year' ? 0 : age.value

  return _.get(filteredPENRates, ['PENRates', gender, queryAge.toString(), policyYear.toString()], 0)
}

export const queryETIRate = (gender: string, age: Age, policyYear: number, filteredETIRates?: ETIRates): number => {
  const queryAge: number = age.unit !== 'year' ? 0 : age.value

  return _.get(filteredETIRates, ['ETIRates', gender, queryAge.toString(), policyYear.toString()], 0)
}
