// @flow
import type { Effect } from 'redux-saga/effects'
import { takeLatest, put, select, call } from 'redux-saga/effects'
import { delay } from 'redux-saga'
import { d } from 'core/service/lib/decimal'
import { round } from 'core/service/lib/number-format'
import type { Action, EditLumpSum, UpdateRegularPremium as UpdateRegularPremiumAction } from './actions'
import {
  EDIT_REGULAR_TOP_UP,
  EDIT_REGULAR_PREMIUM,
  SELECT_MODEL_FACTOR,
  EDIT_SUM_ASSURED,
  EDIT_LUMP_SUM,
  UPDATE_LUMP_SUMS,
  UPDATE_REGULAR_PREMIUM,
  UPDATE_REGULAR_TOP_UP,
  UPDATE_SUM_ASSURED,
  updateRegularPremium,
  updateRegularTopUp,
  updateSumAssured,
  updateLumpSums,
  updateExpectedReturnComparisons,
  updateRPUDRExpectedReturnComparisons,
  resetExpectedReturnComparisons,
  resetRPUDRExpectedReturnComparisons,
} from './actions'
import {
  UPDATE_BIRTHDATE,
  SELECT_GENDER,
  SELECT_NATURE_OF_DUTY,
  EDIT_TITLE_NAME,
} from 'quick-quote/insured-information/actions'
import { SELECTED_PRODUCT } from 'quick-quote/product-selection/actions'
import type { Rider, RiderState } from 'core/data-model/rider'
import {
  getSelectedModelFactorID,
  getPreviousModelFactorID,
  getRegularPremium,
  getRegularTopUp,
  getSelectedModelFactor,
  getSumAssured,
  getLumpSum,
  getLumpSumErrors,
} from './selectors'
import type { RegularPremium, RegularTopUp, LumpSumByYear } from 'core/service/investment'
import {
  validateRegularPremium,
  validateRegularTopUp,
  validateSumAssured,
  updateLumpSumByYear,
  validateLumpSumRPUL,
  validateLumpSumSPUL,
  validateLumpSumRPUDR,
  cashFlowsToExpectedReturnComparisons,
  getMaximumSumAssured,
  getRegularPremiumMaxMultiplier,
} from 'core/service/investment'
import type { PremiumAmount } from 'core/data-model/investment'
import type { DisplayProductQuery } from 'core/service'
import {
  getSelectedDisplayProduct,
  getSelectedDisplayProductQuery,
  getSelectedBasicCategory,
  getSelectedDisplayProductCode,
} from 'quick-quote/product-selection/selectors'
import { getAge, getGender, getAllNatureOfDutyCodes, getInsured } from 'quick-quote/insured-information/selectors'
import { asYears } from 'core/service/insured/age'
import { generateCashFlow } from 'core/service/investment/cash-flow/cash-flow'
import _ from 'lodash'
import values from 'core/data-model/constants/values'
import VALUE from 'core/data-model/constants/values'
import type { RuleType } from 'core/data-model/validation/types'
import { getRiskiestOccupation, getRiskiestOccupationFactorForRiders } from 'core/service/insured'
import { getAvailableRiders, getSelectedRiders } from 'quick-quote/product-common/coverage-plan/selectors'
import { TOGGLE_RIDER, EDIT_RIDER_PREMIUM } from 'quick-quote/product-common/coverage-plan/actions'
import { getToggles } from 'quick-quote/feature-toggles'
import { dataWrapper } from 'core/data'
import { getIsAgent } from 'identity/selectors'

const DEFAULT_INFLATION_RATE = 0

export const getModelFactor = async (modelFactorId) => {
  return await dataWrapper.getModelFactorById(modelFactorId)
}

export const rppCalculation = (regularPremium, previousModelFactor, modelFactor) => {
  const result = d(regularPremium)
    .times(previousModelFactor.periods)
    .times(modelFactor.factorILP)
  return round(result.toNumber(), 0)
}

export const rppCalculationFromSA = (sumAssured, premiumMultiplier, previousModelFactor, modelFactor) => {
  const result = d(sumAssured)
    .dividedBy(premiumMultiplier)
    .times(modelFactor.factorILP)
  return round(result.toNumber(), 0)
}

export const rtuCalculation = (regularTopUp, previousModelFactor, modelFactor) => {
  const result = d(regularTopUp)
    .times(previousModelFactor.periods)
    .times(modelFactor.factorILP)
  return round(result.toNumber(), 0)
}

export function* onEditRegularPremium(action: Action): Generator<*, *, *> {
  // const isAgent: Boolean = yield select(getIsAgent)
  const displayProductQuery: DisplayProductQuery = yield select(getSelectedDisplayProductQuery)
  const modelFactorId: string = yield select(getSelectedModelFactorID)
  const previousModelFactorId: string = yield select(getPreviousModelFactorID)

  const modelFactor = yield call(getModelFactor, modelFactorId)

  const previousModelFactor = yield call(getModelFactor, previousModelFactorId)

  const regularPremium: RegularPremium = yield select(getRegularPremium)

  const newRegularPremium: number = yield call(rppCalculation, regularPremium.value, previousModelFactor, modelFactor)

  const actualRpp = action.type === SELECT_MODEL_FACTOR ? newRegularPremium : regularPremium.value

  const insuredAge = yield select(getAge)

  const validatedRegularPremium = yield call(
    validateRegularPremium,
    displayProductQuery,
    modelFactorId,
    actualRpp,
    insuredAge
  )

  yield put(updateRegularPremium(actualRpp, validatedRegularPremium.getAllMessages()))

  if (getToggles().ENABLE_MAXIMUM_SUM_ASSURED_CALCULATION_RPUL && action.type !== SELECT_MODEL_FACTOR) {
    const natureOfDutyCodes = yield select(getAllNatureOfDutyCodes)
    const occupationFactor = yield call(getRiskiestOccupation, displayProductQuery, natureOfDutyCodes)

    const payload = {
      regularPremium: actualRpp,
      insuredAge: insuredAge,
      insuredGender: yield select(getGender),
      occupationFactor: occupationFactor,
    }

    if ([VALUE.RPUL, VALUE.RPUDR].includes(displayProductQuery.code)) {
      if (validatedRegularPremium.getAllMessages() && validatedRegularPremium.getAllMessages().length === 0) {
        const maximumSumAssured = yield call(getMaximumSumAssured, displayProductQuery, modelFactorId, payload)
        yield put(updateSumAssured(maximumSumAssured, []))
        return
      } else {
        yield put(updateSumAssured(0, []))
      }
    }
  }
  if (
    getToggles().ENABLE_MAXIMUM_SUM_ASSURED_CALCULATION_RPUL &&
    getToggles().ENEBLE_IWEALTHY_OPTIMISE &&
    displayProductQuery.code === VALUE.RPUL &&
    action.type === SELECT_MODEL_FACTOR
  ) {
    const natureOfDutyCodes = yield select(getAllNatureOfDutyCodes)
    const occupationFactor = yield call(getRiskiestOccupation, displayProductQuery, natureOfDutyCodes)
    const payload = {
      regularPremium: actualRpp,
      insuredAge: insuredAge,
      insuredGender: yield select(getGender),
      occupationFactor: occupationFactor,
    }
    if (validatedRegularPremium.getAllMessages() && validatedRegularPremium.getAllMessages().length === 0) {
      const maximumSumAssured = yield call(getMaximumSumAssured, displayProductQuery, modelFactorId, payload)
      yield put(updateSumAssured(maximumSumAssured, []))
      return
    } else {
      yield put(updateSumAssured(0, []))
    }
  }
}

export function* onUpdateAfterModelFactorRegularTopUp(action: Action): Generator<*, *, *> {
  const displayProductQuery: DisplayProductQuery = yield select(getSelectedDisplayProductQuery)
  const modelFactorId: string = yield select(getSelectedModelFactorID)
  const previousModelFactorId: string = yield select(getPreviousModelFactorID)

  const modelFactor = yield call(getModelFactor, modelFactorId)

  const previousModelFactor = yield call(getModelFactor, previousModelFactorId)

  const regularTopUp: RegularTopUp = yield select(getRegularTopUp)
  const regularPremium: RegularPremium = yield select(getRegularPremium)

  const newRegularTopUp: number = yield call(rtuCalculation, regularTopUp.value, previousModelFactor, modelFactor)

  const actualRtu = action.type === SELECT_MODEL_FACTOR ? newRegularTopUp : regularTopUp.value

  let premiumAmount: PremiumAmount = {
    regularTopUp: actualRtu,
    regularPremium: regularPremium.value,
  }
  const isAgent = yield select(getIsAgent)
  const validatedRegularTopUp = yield call(
    validateRegularTopUp,
    displayProductQuery,
    modelFactorId,
    premiumAmount,
    isAgent
  )
  yield put(updateRegularTopUp(actualRtu, validatedRegularTopUp.getAllMessages()))
}

export function* onEditRegularTopUp(): Generator<*, *, *> {
  const displayProductQuery: DisplayProductQuery = yield select(getSelectedDisplayProductQuery)
  const modelFactorId: string = yield select(getSelectedModelFactorID)
  const isAgent = yield select(getIsAgent)

  const regularTopUp: RegularTopUp = yield select(getRegularTopUp)
  const regularPremium: RegularPremium = yield select(getRegularPremium)

  let premiumAmount: PremiumAmount = {
    regularTopUp: regularTopUp.value,
    regularPremium: regularPremium.value,
  }
  const validatedRegularTopUp = yield call(
    validateRegularTopUp,
    displayProductQuery,
    modelFactorId,
    premiumAmount,
    isAgent
  )
  yield put(updateRegularTopUp(regularTopUp.value, validatedRegularTopUp.getAllMessages()))
}

export function* onEditSumAssured(action: Action): Generator<*, *, *> {
  if (_.includes([EDIT_REGULAR_PREMIUM, UPDATE_BIRTHDATE, SELECT_MODEL_FACTOR], action.type)) {
    yield call(onEditRegularPremium, action)
  }
  const regularPremium: RegularPremium = yield select(getRegularPremium)
  const sumAssured: RegularTopUp = yield select(getSumAssured)
  if (!_.isEmpty(regularPremium.errors)) {
    yield put(updateSumAssured(sumAssured.value, []))
    return
  }

  const displayProductQuery: DisplayProductQuery = yield select(getSelectedDisplayProductQuery)

  if (displayProductQuery.code === VALUE.RPUL && action.type === SELECT_MODEL_FACTOR) {
    yield call(onUpdateAfterModelFactorRegularTopUp, action)
  }

  if (displayProductQuery.code === VALUE.SPUL) {
    yield put(updateSumAssured(regularPremium.value, []))
    return
  }

  const modelFactorId: string = yield select(getSelectedModelFactorID)
  const natureOfDutyCodes = yield select(getAllNatureOfDutyCodes)
  const occupationFactor = yield getRiskiestOccupation(displayProductQuery, natureOfDutyCodes)

  const payload = {
    sumAssured: sumAssured.value,
    regularPremium: regularPremium.value,
    insuredAge: yield select(getAge),
    insuredGender: yield select(getGender),
    occupationFactor: occupationFactor,
    isAgent: yield select(getIsAgent),
  }
  const validatedSumAssured = yield call(validateSumAssured, displayProductQuery, modelFactorId, payload)
  yield put(updateSumAssured(sumAssured.value, validatedSumAssured.getAllMessages()))
}

export function* onEditLumpSum(action: EditLumpSum): Generator<*, *, *> {
  const lumpSums = yield select(getLumpSum)
  const updatedLumpSums = updateLumpSumByYear(lumpSums, action.payload)
  yield call(validateLumpSums, updatedLumpSums)
}

export function* onUpdateRegularPremium(action: UpdateRegularPremiumAction): Generator<*, *, *> {
  const lumpSums = yield select(getLumpSum)
  yield call(validateLumpSums, lumpSums)
}

export function* validateLumpSums(lumpSums: LumpSumByYear[]): Generator<*, *, *> {
  const regularPremium: RegularPremium = yield select(getRegularPremium)
  const modelFactorId: string = yield select(getSelectedModelFactorID)

  const displayProductQuery: DisplayProductQuery = yield select(getSelectedDisplayProductQuery)

  let validatedLumpSums
  switch (displayProductQuery.code) {
    case VALUE.SPUL:
      validatedLumpSums = yield validateLumpSumSPUL(regularPremium.value, lumpSums, modelFactorId)
      break
    case VALUE.RPUDR:
      validatedLumpSums = yield validateLumpSumRPUDR(regularPremium.value, lumpSums, modelFactorId)
      break
    default:
      validatedLumpSums = yield validateLumpSumRPUL(regularPremium.value, lumpSums, modelFactorId)
      break
  }

  // $$FlowFixMe
  yield put(updateLumpSums(validatedLumpSums))
}

type ValidProps = {
  errors: Array<{ type: RuleType, message: string }>[],
}

const isValid = ({ errors }: ValidProps) =>
  _(errors)
    .filter(({ type }) => type !== values.ADDITIONAL_INFORMATION)
    .isEmpty()

export const filterTPDRiderErrorForUdr = (table) => {
  if (!_.isEmpty(table) && table.TPDUDR) {
    table.TPDUDR.errors =
      table.TPDUDR.errors.length > 1 ? table.TPDUDR.errors.filter((e) => e.type !== 'VALIDATION') : table.TPDUDR.errors
    return table
  } else {
    return table
  }
}

export function* getCashFlowProps(): Generator<*, *, *> {
  const displayProduct = yield select(getSelectedDisplayProduct)
  const age = yield select(getAge)
  const gender = yield select(getGender)
  const modelFactor = yield select(getSelectedModelFactor)
  const regularPremium = yield select(getRegularPremium)
  const regularTopUp = yield select(getRegularTopUp)
  const sumAssured = yield select(getSumAssured)
  const lumpSum = yield select(getLumpSum)
  const hasRequiredInput = regularPremium.value !== 0 && sumAssured.value !== 0
  const readyForCashFlow = hasRequiredInput && _.every([regularTopUp, regularPremium, sumAssured], isValid)

  const displayProductQuery = yield select(getSelectedDisplayProductQuery)
  const natureOfDutyCodes = yield select(getAllNatureOfDutyCodes)

  const inflationRate = DEFAULT_INFLATION_RATE
  const riders: (Rider & RiderState)[] = yield select(getAvailableRiders)

  const riderOccupationFactors = yield getRiskiestOccupationFactorForRiders(
    displayProductQuery,
    natureOfDutyCodes,
    riders
  )

  if (!readyForCashFlow) {
    return { error: 'CASHFLOW_UNREADY' }
  }

  const lumpSumErrors = yield select(getLumpSumErrors)

  if (!_.isEmpty(lumpSumErrors)) {
    return { error: 'LUMP_SUM_INCOMPLETE' }
  }

  return {
    displayProduct,
    regularPremium: regularPremium.value,
    regularTopUp: regularTopUp.value,
    lumpSum,
    age: asYears(age),
    gender,
    paymentInterval: modelFactor.periods,
    sumAssured: sumAssured.value,
    inflationRate,
    riderOccupationFactors,
    riders,
  }
}

export function* getCashFlowByExpectedReturn(): Generator<*, *, *> {
  const props = yield call(getCashFlowProps)

  const cashFlowByExpectedReturn = (expectedReturn) =>
    call(
      generateCashFlow,
      displayProduct,
      expectedReturn,
      regularPremium,
      regularTopUp,
      lumpSum,
      age,
      gender,
      paymentInterval,
      sumAssured,
      inflationRate,
      riderOccupationFactors,
      riders
    )

  const { error } = props

  if (error != null) {
    return { error }
  }

  const {
    displayProduct,
    regularPremium,
    regularTopUp,
    lumpSum,
    age,
    gender,
    paymentInterval,
    sumAssured,
    inflationRate,
    riderOccupationFactors,
    riders,
  } = props

  let result = {}
  // "3 = scenario 4 : Yield 2% Medical inflation rate 5%"
  for (let expectedReturn of [-1, 2, 5, 3]) {
    result[`${expectedReturn}`] = yield cashFlowByExpectedReturn(expectedReturn)
  }

  return result
}

export const hasRiderErrors = (selectedRiders: Rider & RiderState[]): boolean => {
  const errors: Rider & RiderState = selectedRiders.find((r) => r.errors.length > 0)
  return !_.isUndefined(errors)
}

export function* doUpdateExpectedReturnComparisons(): Generator<*, *, *> {
  const category = yield select(getSelectedBasicCategory)

  if (category !== VALUE.INVESTMENT) return

  yield call(delay, 300)

  const cashFlowByExpectedReturn = yield call(getCashFlowByExpectedReturn)
  const productCode = yield select(getSelectedDisplayProductCode)

  if (productCode === values.RPUDR) {
    const selectedRiders: (Rider & RiderState)[] = yield select(getSelectedRiders)
    const hasError: boolean = hasRiderErrors(selectedRiders)
    if (hasError) {
      yield put(resetRPUDRExpectedReturnComparisons())
      return
    }
  }

  if (_.isEmpty(cashFlowByExpectedReturn.error)) {
    const expectedReturnComparisons = cashFlowsToExpectedReturnComparisons(cashFlowByExpectedReturn, productCode)

    if (productCode === values.RPUDR) {
      yield put(updateRPUDRExpectedReturnComparisons(expectedReturnComparisons))
    } else {
      yield put(updateExpectedReturnComparisons(expectedReturnComparisons))
    }
    return
  }

  if (cashFlowByExpectedReturn.error === 'LUMP_SUM_INCOMPLETE') return

  if (productCode === values.RPUDR) {
    yield put(resetRPUDRExpectedReturnComparisons())
  } else {
    yield put(resetExpectedReturnComparisons())
  }
}

export function* revalidateSumAssured(): Generator<*, *, *> {
  const displayProductQuery: DisplayProductQuery = yield select(getSelectedDisplayProductQuery)
  if ([values.RPUL, values.RPUDR].includes(displayProductQuery.code)) {
    const insured: Insured = yield select(getInsured)

    const insuredAge = yield select(getAge)

    const modelFactorID = yield select(getSelectedModelFactorID)

    const regularPremium: RegularPremium = yield select(getRegularPremium)

    const validatedRegularPremium = yield call(
      validateRegularPremium,
      displayProductQuery,
      modelFactorID,
      regularPremium.value,
      insured.age
    )

    yield put(updateRegularPremium(regularPremium.value, validatedRegularPremium.getAllMessages()))

    if (getToggles().ENABLE_MAXIMUM_SUM_ASSURED_CALCULATION_RPUL) {
      const natureOfDutyCodes = yield select(getAllNatureOfDutyCodes)
      const occupationFactor = yield call(getRiskiestOccupation, displayProductQuery, natureOfDutyCodes)

      const payload = {
        regularPremium: regularPremium.value,
        insuredAge: insuredAge,
        insuredGender: insured.gender,
        occupationFactor: occupationFactor,
      }

      if (validatedRegularPremium.getAllMessages() && validatedRegularPremium.getAllMessages().length === 0) {
        const maximumSumAssured = yield call(getMaximumSumAssured, displayProductQuery, modelFactorID, payload)

        const payloadSumAssuredValidation = {
          sumAssured: maximumSumAssured,
          regularPremium: regularPremium.value,
          insuredAge: insuredAge,
          insuredGender: insured.gender,
          occupationFactor: occupationFactor,
          isAgent: yield select(getIsAgent),
        }
        const validatedSumAssured = yield call(
          validateSumAssured,
          displayProductQuery,
          modelFactorID,
          payloadSumAssuredValidation
        )
        yield put(updateSumAssured(maximumSumAssured, validatedSumAssured.getAllMessages()))
      } else {
        yield put(updateSumAssured(0, []))
      }
    }
  }
}

export function* onEditSumAssuredILP(action: Action): Generator<*, *, *> {
  // const isAgent = yield select(getIsAgent)
  const displayProductQuery: DisplayProductQuery = yield select(getSelectedDisplayProductQuery)

  if (
    getToggles().ENEBLE_IWEALTHY_OPTIMISE &&
    _.includes(EDIT_SUM_ASSURED, action.type) &&
    displayProductQuery.code === VALUE.RPUL
  ) {
    const sumAssured = action.payload
    const natureOfDutyCodes = yield select(getAllNatureOfDutyCodes)
    const occupationFactor = yield getRiskiestOccupation(displayProductQuery, natureOfDutyCodes)
    const modelFactorId: string = yield select(getSelectedModelFactorID)
    const modelFactor = yield call(getModelFactor, modelFactorId)
    const previousModelFactorId: string = yield select(getPreviousModelFactorID)
    const previousModelFactor = yield call(getModelFactor, previousModelFactorId)
    const insured: Insured = yield select(getInsured)
    const insuredAge = yield select(getAge)

    const regularPremiumMultiplierPayload = {
      insuredAge: insuredAge,
      insuredGender: insured.gender,
    }
    const regularPremiumMultiplier = yield call(
      getRegularPremiumMaxMultiplier,
      displayProductQuery,
      regularPremiumMultiplierPayload
    )

    const newRegularPremium: number = yield call(
      rppCalculationFromSA,
      sumAssured,
      regularPremiumMultiplier,
      previousModelFactor,
      modelFactor
    )

    const validateSumAssuredPayload = {
      sumAssured,
      regularPremium: newRegularPremium > values.DEFAULT_RPP ? newRegularPremium : values.DEFAULT_RPP,
      insuredAge: yield select(getAge),
      insuredGender: yield select(getGender),
      occupationFactor: occupationFactor,
      isAgent: yield select(getIsAgent),
    }
    const validatedSumAssured = yield call(
      validateSumAssured,
      displayProductQuery,
      modelFactorId,
      validateSumAssuredPayload
    )
    yield put(updateSumAssured(sumAssured, validatedSumAssured.getAllMessages()))

    const validatedRegularPremium = yield call(
      validateRegularPremium,
      displayProductQuery,
      modelFactorId,
      newRegularPremium,
      insuredAge
    )
    yield put(updateRegularPremium(newRegularPremium, validatedRegularPremium.getAllMessages()))
  }
  return
}

export function* watchers(): Generator<*, *, Effect[]> {
  yield [
    takeLatest([EDIT_REGULAR_TOP_UP, EDIT_REGULAR_PREMIUM], onEditRegularTopUp),
    takeLatest(
      [EDIT_SUM_ASSURED, UPDATE_BIRTHDATE, EDIT_REGULAR_PREMIUM, SELECT_MODEL_FACTOR, SELECT_NATURE_OF_DUTY],
      onEditSumAssured
    ),
    takeLatest(EDIT_LUMP_SUM, onEditLumpSum),
    takeLatest(UPDATE_REGULAR_PREMIUM, onUpdateRegularPremium),
    takeLatest(
      [
        SELECTED_PRODUCT,
        UPDATE_BIRTHDATE,
        SELECT_GENDER,
        SELECT_MODEL_FACTOR,
        UPDATE_REGULAR_PREMIUM,
        UPDATE_REGULAR_TOP_UP,
        UPDATE_SUM_ASSURED,
        UPDATE_LUMP_SUMS,
        TOGGLE_RIDER,
        EDIT_RIDER_PREMIUM,
        EDIT_SUM_ASSURED,
      ],
      doUpdateExpectedReturnComparisons
    ),
    takeLatest([UPDATE_BIRTHDATE, SELECT_GENDER, EDIT_TITLE_NAME], revalidateSumAssured),
  ]
}

export const sagas = watchers
