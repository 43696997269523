// @flow

import { InsuredInformationBreadcrumb } from 'quick-quote/components'
import NumberFormat from 'react-number-format'
import { Input, FormGroup } from 'reactstrap'
import { ErrorFeedback } from 'common-components/view-logic'

import VALUES from 'core/data-model/constants/values'
import MESSAGES from 'core/data-model/constants/messages'

import { connect } from 'react-redux'
import _ from 'lodash'
import type { AppState } from 'quick-quote/reducers'

import {
  getLoanTerm,
  getLoanTermErrors,
  getLoanAmount,
  getLoanAmountErrors,
  editLoanTerm,
  editLoanAmount,
  getAvailableCoverageTerms,
  getPaymentMethod,
  getSumAssured,
  getSumAssuredErrors,
  getCoverageTerm,
  getCoverageTermErrors,
  getPremium,
  updateCoverageTerm,
  updateSumAssured,
  updatePaymentMethod,
} from 'quick-quote/product-loan/coverage-plan/redux'

import { RadioButtonGroup } from 'common-components'

type CustomNumberInputProps = {
  id: string,
  value: number,
  onChange: Function,
  disabled?: boolean,
  decimalPrecision?: number,
}

const CustomNumberInput = ({ id, value, onChange, disabled, decimalPrecision }: CustomNumberInputProps) => (
  <NumberFormat
    id={id}
    isAllowed={({ formattedValue }) => formattedValue.length <= 17}
    customInput={Input}
    value={value}
    decimalPrecision={decimalPrecision || 0}
    thousandSeparator={true}
    allowNegative={false}
    type="tel"
    pattern="[0-9]*"
    onFocus={(e) => e.target.setSelectionRange(0, -1)}
    onChange={(e, { value }) => onChange(parseInt(Number(value)))}
    disabled={disabled || false}
  />
)

const InputWithPotentialError = (props) => (
  <div className={`${props.className} ${_.isEmpty(props.errors) ? '' : 'has-danger'}`}>
    <CustomNumberInput {...props} />
  </div>
)

const maskErrorsWhenDependencyInvalid = (errors: string[], dependencyErrors: string[]) =>
  !_.isEmpty(dependencyErrors) ? [] : errors

type LoanProps = {
  loanTerm: number,
  loanTermErrors: string[],
  loanAmount: number,
  loanAmountErrors: string[],
  editLoanTerm: Function,
  editLoanAmount: Function,
}

const Loan = ({ loanAmount, loanAmountErrors, loanTerm, loanTermErrors, editLoanAmount, editLoanTerm }: LoanProps) => (
  <div>
    <div className="title">ข้อมูลสัญญาเงินกู้ธนาคาร</div>
    <FormGroup className={'coverage-plan-calculation item basic'}>
      <div className="loan-input">
        <div className="label">
          <label>จำนวนเงินกู้ตามสัญญา</label>
          <label>ระยะเวลากู้ตามสัญญา (ปี)</label>
        </div>
        <div className="options">
          <InputWithPotentialError
            id="loan-amount-input"
            className="loan-amount"
            value={loanAmount}
            errors={loanTermErrors}
            onChange={editLoanAmount}
          />

          <InputWithPotentialError
            id="loan-term-input"
            className="loan-term"
            value={loanTerm}
            errors={loanTermErrors}
            onChange={editLoanTerm}
          />
        </div>
      </div>
    </FormGroup>

    <FormGroup className="has-danger">
      <ErrorFeedback id="loan-errors" errorMessages={loanAmountErrors.concat(loanTermErrors)} />
    </FormGroup>
  </div>
)

const loanMapStateToProps = (state: AppState) => ({
  loanAmount: getLoanAmount(state),
  loanAmountErrors: getLoanAmountErrors(state),
  loanTerm: getLoanTerm(state),
  loanTermErrors: getLoanTermErrors(state),
})

const loanMapDispatchToProps = {
  editLoanAmount,
  editLoanTerm,
}

export const LoanContainer = connect(loanMapStateToProps, loanMapDispatchToProps)(Loan)

const Coverage = ({
  availableTerms,
  paymentMethod,
  sumAssured,
  loanAmountErrors,
  sumAssuredErrors,
  coverageTerm,
  loanTermErrors,
  coverageTermErrors,
  premium,
  updateSumAssured,
  updateCoverageTerm,
  updatePaymentMethod,
}) => {
  const maskedSumAssuredErrors = maskErrorsWhenDependencyInvalid(sumAssuredErrors, loanAmountErrors)
  const maskedCoverageTermErrors = maskErrorsWhenDependencyInvalid(coverageTermErrors, loanTermErrors)
  const coverageErrors = [...maskedSumAssuredErrors, ...maskedCoverageTermErrors]

  const sumAssuredLabel = <label>จำนวนเงินเอาประกันภัย</label>
  const coverageTermLabel = <label>ระยะเวลาคุ้มครอง (ปี)</label>

  const freeFillAmount = (
    <div className="coverage-input">
      <div className="label">
        {sumAssuredLabel}
        {coverageTermLabel}
      </div>
      <div className="options">
        <InputWithPotentialError
          id="sum-assured-input"
          className="sum-assured"
          value={sumAssured}
          errors={maskedSumAssuredErrors}
          disabled={!_.isEmpty(loanAmountErrors)}
          onChange={updateSumAssured}
        />

        <InputWithPotentialError
          id="coverge-term-input"
          className="coverage-term"
          value={coverageTerm}
          errors={maskedCoverageTermErrors}
          disabled={!_.isEmpty(loanTermErrors)}
          onChange={updateCoverageTerm}
        />
      </div>
    </div>
  )

  const paymentMethods = [
    { value: VALUES.CASH, label: MESSAGES.CASH_PAYMENT },
    { value: VALUES.LOAN, label: MESSAGES.LOAN_PAYMENT },
  ]

  return (
    <div>
      <div className="title">ข้อมูลความคุ้มครองประกันชีวิต</div>
      <div className="payment-methods">
        <div>
          <RadioButtonGroup
            keyToGetValue="id"
            className="payment-methods"
            onChange={updatePaymentMethod}
            value={paymentMethod}
            options={paymentMethods.map(({ value, label }) => ({ id: value, text: `${label}` }))}
          />
        </div>
      </div>

      <FormGroup className={'coverage-plan-calculation item basic'}>{freeFillAmount}</FormGroup>

      <FormGroup className="has-danger">
        <ErrorFeedback id="coverage-errors" errorMessages={coverageErrors} />
      </FormGroup>

      <FormGroup className="item">
        <div>
          <div className="label">
            <label>เบี้ยประกันภัย</label>
          </div>

          <CustomNumberInput
            id="premium-input"
            disabled={true}
            value={premium}
            onChange={() => {}}
            decimalPrecision={2}
          />
        </div>
      </FormGroup>
    </div>
  )
}

// const selectAvailableTerm = (availableTerms) => (
//   <div className="coverage-input">
//     <div>
//       <div className="label">{sumAssuredLabel}</div>
//       {sumAssuredInput}

//       <div className="label">{coverageTermLabel}</div>
//       <RadioButtonGroup
//         keyToGetValue="id"
//         className="coverage-term"
//         onChange={updateCoverageTerm}
//         value={coverageTerm}
//         options={availableTerms.map(term => ({ id: term, text: `${term} ปี`}))}
//       />
//     </div>
//   </div>
// )

const coverageMapStateToProps = (state: AppState) => ({
  availableTerms: getAvailableCoverageTerms(state),
  paymentMethod: getPaymentMethod(state),
  sumAssured: getSumAssured(state),
  sumAssuredErrors: getSumAssuredErrors(state),
  loanAmountErrors: getLoanAmountErrors(state),
  loanTermErrors: getLoanTermErrors(state),
  coverageTermErrors: getCoverageTermErrors(state),
  coverageTerm: getCoverageTerm(state),
  premium: getPremium(state),
})

const coverageMapDispatchToProps = {
  updateSumAssured,
  updateCoverageTerm,
  updatePaymentMethod,
}

export const CoverageContainer = connect(coverageMapStateToProps, coverageMapDispatchToProps)(Coverage)

const CoveragePlan = () => (
  <div>
    <InsuredInformationBreadcrumb />
    <div id="coverage-plan">
      <div className="content">
        <LoanContainer />
        <CoverageContainer />
      </div>
    </div>
  </div>
)

export default CoveragePlan
