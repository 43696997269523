import { get } from 'lodash/fp'

export const getMediaState = (state) => state?.remoteSelling.mediaControl
export const getMicState = (state) => state?.remoteSelling.mediaControl.micState
export const getCameraState = (state) => state?.remoteSelling.mediaControl.cameraState
export const getSharingScreenState = (state) => state?.remoteSelling.mediaControl.screenState
export const getRoomId = (state) => state?.remoteSelling.roomId
export const getRemoteSellingState = (state) => state?.remoteSelling
export const getCurrentAppId = (state) => state?.remoteSelling.appId
export const isOverlayOpen = (state) => state?.remoteSelling.disableEditing
export const getInCall = (state) => state?.remoteSelling.isInCall
export const isClientInCall = (state) => state?.remoteSelling.callStatus?.isClientStreaming
export const isAgentInCall = (state) => state?.remoteSelling.callStatus?.isAgentStreaming
export const getCallLatency = (state) => state?.remoteSelling.callStatus?.latency || 0
export const getRemoteSellingCaseId = (app) => get('remoteSelling.ekyc.caseId', app) || get('remoteSelling.caseId', app)
