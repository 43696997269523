// @flow

import MESSAGES from 'core/data-model/constants/bi-messages'
import type { Rider, RiderState } from 'core/data-model/rider'
import VALUES from 'core/data-model/constants/values'
import { getToggles } from 'quick-quote/feature-toggles'

type Props = {
  rider: Rider & RiderState,
}

export const AgentProtectionDisclaimer = ({ rider }: Props) => {
  if (
    rider.code === VALUES.ESCI &&
    rider.showAgentProtectionDisclaimer &&
    getToggles().ENABLE_ICARE_REPRICING === true
  ) {
    return (
      <div className="disclaimer-box">
        <p>{MESSAGES.DISCLAIMER_ADDITIONAL_AGENT_PROTECTION}</p>
        <p>{MESSAGES.DISCLAIMER_ADDITIONAL_AGENT_ESCI}</p>
      </div>
    )
  } else if (rider.showAgentProtectionDisclaimer) {
    return (
      <div className="disclaimer-box">
        <p>{MESSAGES.DISCLAIMER_AGENT_PROTECTION}</p>
      </div>
    )
  }
  return null
}
