// @flow

import _ from 'lodash'
import type { DisplayProduct } from 'core/service/display-product'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { BasicPremium$, SumAssured$, TotalPremium$ } from 'core/data-model/basic-plan'
import type { AppState } from 'quick-quote/reducers'

import CONSTANTS from 'core/data-model/constants/values'

import { isNil, isNotNil } from 'core/service/lib/type-check'
import { createSelector } from 'reselect'
import {
  getSelectedDisplayProduct,
  getSelectedDisplayProductCode,
  getSelectedDisplayBasicPlanCode,
} from 'quick-quote/product-selection/selectors'
import { getAvailableRidersByCode } from 'quick-quote/product-common/coverage-plan/selectors'
import { canProceedToCoveragePlan } from 'quick-quote/selectors/next-tab-proceeding'
import DEFAULTS from 'core/data-model/constants/defaults'

import {
  getSumAssured$,
  getSelectedRiders,
  getBasicPremium$,
  getTotalPremium$,
  getBasicPlanErrorMessage,
} from 'quick-quote/product-common/coverage-plan/selectors'

export const getValidSelectedRiders = createSelector(getSelectedRiders, (selectedRiders) => {
  return selectedRiders.filter((rider) =>
    isNil(rider.errors.find((error) => error.type !== CONSTANTS.ADDITIONAL_INFORMATION))
  )
})

export const getRiderOccupationFactorForPARider = (state: AppState): string =>
  _.get(state, 'coveragePlan.riders["PARIDER"].riderOccupationFactor', '')

export const getOptionPlanPARider = createSelector(getAvailableRidersByCode, (riders) => {
  if (riders) {
    const PARIDER = riders[CONSTANTS.PARIDER]
    const optionPlanPARider = _.get(PARIDER, 'options[0].values', [])
    return optionPlanPARider
  } else {
    return []
  }
})

export const getAvaliableOptionPlanPARider = createSelector(
  getOptionPlanPARider,
  getRiderOccupationFactorForPARider,
  getSelectedDisplayBasicPlanCode,
  (optionPlanPARider, riderOccupationFactor, basicPlanCode) => {
    if (optionPlanPARider.length > 0) {
      return optionPlanPARider.filter(
        (option) =>
          option.active &&
          option.group === basicPlanCode &&
          option.validOccupationFactors.includes(riderOccupationFactor)
      )
    } else {
      return []
    }
  }
)

export const getPARiderSelectedPlan = (state: AppState): Object =>
  _.get(state, 'coveragePlan.riders[PARIDER].selectedPlan', {})

export const canProceedToBenefitIllustration = createSelector(
  getSumAssured$,
  getBasicPremium$,
  getTotalPremium$,
  getBasicPlanErrorMessage,
  getSelectedRiders,
  canProceedToCoveragePlan,
  getSelectedDisplayProduct,
  getAvaliableOptionPlanPARider,
  getPARiderSelectedPlan,
  (
    sumAssured$: SumAssured$,
    basicPremium: BasicPremium$,
    totalPremium$: TotalPremium$,
    basicPlanErrorMessage: string,
    selectedRiders: (Rider & RiderState)[],
    canProceedToCoveragePlan: boolean,
    selectedDisplayProduct: DisplayProduct,
    avaliableOptionPlanPARider,
    selectedPlanPA
  ): boolean => {
    const selectedRiderInvalidMessages = selectedRiders.filter((rider) =>
      isNotNil(rider.errors.find((error) => error.type === CONSTANTS.VALIDATION))
    )
    const planCodeSelectedPlanPA = _.get(selectedPlanPA, 'planCode', '')
    const planGroupPA = _.find(avaliableOptionPlanPARider, { planCode: planCodeSelectedPlanPA })

    const canProceed =
      canProceedToCoveragePlan &&
      sumAssured$.isOk &&
      basicPremium.isOk &&
      totalPremium$.isOk &&
      _.isEmpty(selectedRiderInvalidMessages) &&
      _.isEmpty(basicPlanErrorMessage) &&
      planGroupPA

    if (selectedDisplayProduct.type === CONSTANTS.CAMPAIGN_PRODUCT_TYPE) {
      const selectedRiderCodes = selectedRiders.map((rider) => rider.code)
      return (
        canProceed &&
        selectedDisplayProduct.requiredRiders != null &&
        selectedDisplayProduct.requiredRiders.every((riderCode) => {
          return selectedRiderCodes.indexOf(riderCode) > -1
        })
      )
    }
    return canProceed
  }
)
