// @flow

import _ from 'lodash'

export default (func: Function, predicate: Function, initialVal: *) => {
  const bounce = (f) => {
    while (_.isFunction(f)) {
      f = f.apply(f.context, f.args)
    }
    return f
  }
  const cursor = (v, nv) => {
    if (predicate(v, nv)) {
      return v
    } else {
      return _.partial(cursor, nv, func(nv))
    }
  }
  return bounce(_.partial(cursor, initialVal, func(initialVal)))
}
