import { noop, isNil } from 'lodash/fp'
import { connect } from 'react-redux'
import { Flex } from 'rebass'
import { defaultProps, withStateHandlers, withHandlers, compose, lifecycle } from 'recompose'
import {
  getFileWithName,
  isPDFFileType,
  getErrorByTypeName,
  getInsuredCommandStatusUploading,
  getPayerCommandStatusUploading,
  getIsDocumentUploading,
  getCurrentApp,
} from 'e-submission/apps/selectors'
import { removeFile, deletePhoto, previewPhoto, esubUploadDocumentFromUrl } from 'e-submission/apps/actions'
import classnames from 'classnames'
import styled from 'styled-components'
import { Button as PrimaryButton } from 'reactstrap'
import { ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Button from '../../../../src/common-components/button'
import Dialog from 'e-submission/components/Dialog'
import Icon from 'e-submission/components/Icon'
import FormEngine from 'e-submission/components/FormEngine'
import waterMaskUrl from 'assets/images/water-mask.png'
import viewerUrl from 'file-loader?name=pdf-viewer/web/viewer.html&emitFile=false!e-submission/pdf-viewer/web/viewer.html'
import { cancelEvent } from 'quick-quote/remote-selling'
import { isSelectedRemoteSelling } from 'quick-quote/selectors'
export const WatermarkModal = styled(Dialog)`
  .modal-content {
    height: 100vh;
  }
  .modal-title {
    display: flex;
    flex: 1 0 100%;
    justify-content: space-between;
    align-items: center;
    button {
      padding: 5px 10px;
    }
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    padding: 0 !important;
    iframe {import { UploadDocCommand } from './UploadDocCommand';

      flex: 1;
    }
  }
  .modal-footer {
    justify-content: space-between;
  }
  .formEngine {
    min-height: 260px;
  }
`
const onLoadIframe = (evt) => {
  const iframe = evt.target
  const iframeDocument = iframe.contentWindow.document
  const toolbar = iframeDocument.querySelector('.toolbar')
  const main = iframeDocument.querySelector('#mainContainer')
  const content = iframeDocument.querySelector('#viewerContainer')
  const customStyle = document.createElement('style')
  customStyle.type = 'text/css'
  customStyle.textContent = `
    .canvasWrapper:before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-image: url(${waterMaskUrl});
      background-size: 200px;
      z-index: 10;
    }
  `
  iframeDocument.head.appendChild(customStyle)
  toolbar.style.visibility = 'hidden'
  content.style.top = 0
}

export const WithWatermark = styled(({ image, ...props }) => <Flex {...props} />)`
  position: relative;
  overflow: hidden;
  background-color: black;
  background-image: url('${({ image }) => image}');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url(${waterMaskUrl});
    background-size: 200px;
    background-attachment: fixed;
  }
`

const Container = styled.div`
  display: flex;
  background-color: white;
  font-size: 1rem;
  padding: 1.25rem;
  margin-left: 0.25rem;

  div {
    font-weight: bold;
    margin: auto;
    flex-grow: 1;
  }

  button {
    margin-bottom: 0;
    height: 1.5rem;
    padding: 0.1rem 1rem;
    font-size: 0.875rem;

    .flex {
      display: flex;
      margin: 0.1rem;

      #spinner {
        width: 1rem;
        height: 1rem;
      }

      label {
        margin-left: 0.35rem;
        margin-bottom: 0;
      }
    }
  }

  .remote-selling-btn-container {
    flex-grow: 0;
    .text-area {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      justify-content: center;
      #spinner {
        display: inline-block;
        margin-right: 10px;
        margin-left: 0;
        flex-grow: 0;
        width: 20px;
        height: 20px;
      }
      label {
        display: inline-block;
        color: #c1c1c1;
        font-weight: 500;
        font-size: 15px;
      }
    }
    .remote-selling-btn.btn-loading,
    .remote-selling-btn.btn-loading:focus {
      border-radius: 5px;
      color: #ff3233;
      border-color: #ff3233;
      background: white;
      box-shadow: none;
      width: 100%;
      font-weight: 500;
      &:after {
        display: none;
      }
    }
  }
`

export const UploadDocCommand = lifecycle({
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.isOpen && nextProps.isUploadError === true) {
      this.props.onClose()
    }
  },
})(
  ({
    disabled,
    isOk,
    sections,
    setRef,
    getRef,
    name,
    url,
    isPDF,
    onOk,
    onClose,
    onDelete,
    onPreview,
    checked,
    readOnly,
    isOpen,
    openModal,
    closeModal,
    CheckedComponent,
    isUploadError,
    onClick,
    onDone,
    isInsured,
    action,
    loading,
    existingId,
    onCancel,
    isRemoteSelling,
    ...props
  }) => {
    return (
      <div>
        <WatermarkModal isOpen={isOpen} toggle={onClose}>
          <ModalHeader tag="div">
            <div className="btn-left" onClick={onClose}>
              <Icon.arrowLeft className="icon" />
            </div>
            <div>{props.description}</div>
            <div />
          </ModalHeader>
          <ModalBody>
            {!isPDF ? (
              <WithWatermark flex="1 1 100%" align="center" image={url} />
            ) : (
              <iframe src={`${viewerUrl}?file=${url}`} onLoad={onLoadIframe} />
            )}
            {sections && (
              <div className="formEngine">
                <FormEngine sections={sections} />
              </div>
            )}
          </ModalBody>
          {readOnly ? null : (
            <ModalFooter>
              <PrimaryButton color="primary" outline onClick={onDelete}>
                ลบ
              </PrimaryButton>
              <PrimaryButton color={!checked ? 'disabled' : 'primary'} onClick={onOk}>
                ตกลง
              </PrimaryButton>
            </ModalFooter>
          )}
        </WatermarkModal>
        <Container>
          <div>{props.description}</div>
          <div className={classnames(isRemoteSelling && 'remote-selling-btn-container')}>
            {isRemoteSelling && loading && (
              <div className="text-area">
                <div id="spinner" />
                <label>กำลังดำเนินการ</label>
              </div>
            )}

            <Button
              className={classnames(
                isRemoteSelling && 'remote-selling-btn',
                isRemoteSelling && loading && 'btn-loading'
              )}
              outline={checked}
              onClick={loading ? onCancel : onClick}
              disabled={disabled}
            >
              {loading ? (
                <div className="flex">
                  {!isRemoteSelling && <div id="spinner" />}
                  <label>ยกเลิกคำสั่ง</label>
                </div>
              ) : checked ? (
                'ส่งคำสั่งอีกครั้ง'
              ) : (
                'ส่งคำสั่ง'
              )}
            </Button>
          </div>
        </Container>

        {checked ? <CheckedComponent {...props} onClick={onPreview} /> : null}
      </div>
    )
  }
)

export default compose(
  defaultProps({
    onOk: noop,
    isUploadError: false,
  }),
  withHandlers(() => {
    let ref
    return {
      setRef: () => (_ref) => {
        ref = _ref
      },
      getRef: () => () => ref,
    }
  }),
  withStateHandlers(
    { isOpen: false },
    {
      openModal: () => () => {
        return { isOpen: true }
      },
      closeModal: () => () => {
        return { isOpen: false }
      },
    }
  ),
  withStateHandlers(
    { loading: false },
    {
      onClick: () => () => {
        return { loading: true }
      },
      onDone: () => () => {
        return { loading: false }
      },
    }
  ),
  connect(
    (state, { name }) => {
      const isDocumentUploading =
        getPayerCommandStatusUploading(state, name) || getInsuredCommandStatusUploading(state, name)
      const app = getCurrentApp(state)
      return {
        disabled: getIsDocumentUploading(state) && !isDocumentUploading,
        loading: isDocumentUploading,
        url: getFileWithName(state, name),
        isPDF: isPDFFileType(state, name),
        isUploadError: !isNil(getErrorByTypeName(state, name)),
        isRemoteSelling: isSelectedRemoteSelling(app),
      }
    },
    (dispatch, { checked, name, openModal, closeModal, onOk, isInsured }) => ({
      onDelete: () => {
        closeModal()
        dispatch(deletePhoto(name))
        dispatch(removeFile(name))
      },
      onPreview: () => {
        if (checked) {
          openModal()
          dispatch(previewPhoto(name))
        }
      },
      onClose: () => {
        closeModal()
        dispatch(removeFile(name))
      },
      onOk: () => {
        const isOk = onOk()
        if (isOk !== false) {
          closeModal()
          dispatch(removeFile(name))
        }
      },
      onCancel: () => {
        dispatch(cancelEvent({ name, isInsured }))
      },
      onClick: async () => {
        dispatch(esubUploadDocumentFromUrl({ name, isInsured, limit: 1 }))
      },
    })
  )
)(UploadDocCommand)
