// @flow
import _ from 'lodash'
import { formatNumber } from 'core/service/lib/number-format'
import type { InvestmentBenefitTable, InvestmentBenefit } from 'core/service/benefit-illustration/investment-benefit'
import type { DisplayProduct } from 'core/service/display-product/types'
import MESSAGES from 'core/data-model/constants/bi-messages'

type DisclaimerProps = {
  startDate: string,
  endDate: string,
  basicPlan: DisplayProduct,
}

export const buildDisclaimer = ({ startDate, endDate, basicPlan }: DisclaimerProps) => [
  {
    stack: [`${MESSAGES.NOTE}: ${MESSAGES.LIFE_SAVE_PRO_POLICY_VALUE_TABLE_NOTE_1}`],
    style: 'disclaimer',
    pageBreak: '',
  },
  {
    stack: [`${MESSAGES.LIFE_SAVE_PRO_POLICY_VALUE_TABLE_NOTE_2}`],
    margin: [45, 0, 0, 0],
    style: 'disclaimer',
  },
  {
    stack: [`${MESSAGES.TERM_LIFE_BENEFITS_VALUE_TABLE_DISCLAIMER_2_LINE_1_BI}`],
    margin: [36, 0, 0, 0],
    style: 'disclaimer',
  },
  {
    stack: [`${MESSAGES.TERM_LIFE_BENEFITS_VALUE_TABLE_DISCLAIMER_2_LINE_2_BI}`],
    margin: [45, 0, 0, 0],
    style: 'disclaimer',
  },
  {
    stack: [`${MESSAGES.TERM_LIFE_BENEFITS_VALUE_TABLE_DISCLAIMER_2_LINE_3_BI}`],
    margin: [45, 0, 0, 0],
    style: 'disclaimer',
  },
  {
    stack: [`${MESSAGES.TERM_LIFE_BENEFITS_VALUE_TABLE_DISCLAIMER_2_LINE_4_BI}`],
    margin: [45, 0, 0, 0],
    style: 'disclaimer',
  },
  {
    stack: [`${MESSAGES.TERM_LIFE_BENEFITS_VALUE_TABLE_DISCLAIMER_2_LINE_5_BI}`],
    margin: [45, 0, 0, 0],
    style: 'disclaimer',
  },
  {
    stack: [`${MESSAGES.TERM_LIFE_BENEFITS_VALUE_TABLE_DISCLAIMER_2_LINE_6_BI}`],
    margin: [45, 0, 0, 0],
    style: 'disclaimer',
  },
  {
    stack: [`${MESSAGES.TERM_LIFE_BENEFITS_VALUE_TABLE_DISCLAIMER_2_LINE_7_BI}`],
    margin: [45, 0, 0, 0],
    style: 'disclaimer',
  },
  {
    stack: [`${MESSAGES.TERM_LIFE_BENEFITS_VALUE_TABLE_DISCLAIMER_2_LINE_8_BI}`],
    margin: [45, 0, 0, 0],
    style: 'disclaimer',
  },
  { text: ' ' },
  { text: ' ' },
]

const buildHeaderInvestmentBenefitTable = () => {
  return [
    [
      { text: MESSAGES.INVESTMENT_BENEFITS_SUMMARY, rowSpan: 3, style: 'tableHeader', margin: [0, 25] },
      { text: MESSAGES.TERM_LIFE_BENEFITS_GUARANTEE, rowSpan: 3, style: 'tableHeader', margin: [0, 25] },
      {
        text: [
          {
            text: `${MESSAGES.EXAMPLE}`,
            fontSize: 15,
            decoration: 'underline',
          },
          `${MESSAGES.TERM_LIFE_BENEFITS_SCENARIO_PDF_2}`,
        ],
        bold: true,
        alignment: 'center',
        colSpan: 3,
      },
      { text: '' },
      { text: '' },
    ],
    [
      { text: '' },
      { text: '' },
      { text: MESSAGES.TERM_LIFE_BENEFITS_LOW, rowSpan: 2, style: 'tableHeader' },
      { text: MESSAGES.TERM_LIFE_BENEFITS_MIDDLE, rowSpan: 2, style: 'tableHeader' },
      { text: MESSAGES.TERM_LIFE_BENEFITS_HIGH, rowSpan: 2, style: 'tableHeader' },
    ],
    [{ text: '' }, { text: '' }, { text: '' }, { text: '' }, { text: '' }],
  ]
}

const rowHeightConfig = { lineHeight: 0.75, margin: [0, 0, 0, 0] }

const buildInvestmentBenefitRowGuarantee = (investmentBenefit: InvestmentBenefit) => [
  {
    text: investmentBenefit.title,
    alignment: 'left',
    ...rowHeightConfig,
  },
  {
    text: formatNumber(investmentBenefit.guaranteeValue, 0, true),
    alignment: 'right',
    ...rowHeightConfig,
  },
  ...investmentBenefit.rates.map((r) => {
    return {
      text: formatNumber(r, 0, true),
      alignment: 'right',
      ...rowHeightConfig,
    }
  }),
]

const buildInvestmentBenefitRowNonGuarantee = (investmentBenefit: InvestmentBenefit) => [
  {
    text: investmentBenefit.title,
    bold: true,
    alignment: 'left',
    ...rowHeightConfig,
  },
  {
    text: formatNumber(investmentBenefit.guaranteeValue, 0, true),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  ...investmentBenefit.rates.map((r) => {
    return {
      text: formatNumber(r, 0, true),
      alignment: 'right',
      bold: true,
      ...rowHeightConfig,
    }
  }),
]

const buildInvestmentSummaryBenefitRow = (investmentBenefit: InvestmentBenefit) => [
  {
    text: investmentBenefit.title,
    alignment: 'left',
    bold: true,
    margin: [13, 0, 0, 0],
  },
  {
    text: formatNumber(investmentBenefit.guaranteeValue, 0, true),
    alignment: 'right',
    bold: true,
  },
  ...investmentBenefit.rates.map((r) => {
    return {
      text: formatNumber(r, 0, true),
      alignment: 'right',
      bold: true,
    }
  }),
]

export const buildTermLifeValueTable = (investmentBenefitTable: InvestmentBenefitTable) => {
  const investmentBenefitTables = []
  const rowsPerPage = [36, 37, 36]
  const guaranteeBenefitsLength = investmentBenefitTable.guaranteeBenefits.length
  const nonGuaranteeBenefitsLength = investmentBenefitTable.nonGuaranteeBenefits.length
  const pageAmount = Math.ceil((nonGuaranteeBenefitsLength + guaranteeBenefitsLength) / rowsPerPage[0])

  _.times(pageAmount, (currentPage) => {
    investmentBenefitTables.push({
      table: {
        headerRows: 1,
        widths: [235, 70, 'auto', 'auto', 'auto'],
        body: [
          ...buildHeaderInvestmentBenefitTable(),
          ...investmentBenefitTable.guaranteeBenefits
            .slice(
              currentPage * rowsPerPage[currentPage > 0 ? currentPage - 1 : 0],
              rowsPerPage[currentPage] * (currentPage + 1)
            )
            .map((r, k) =>
              guaranteeBenefitsLength - 1 === k
                ? buildInvestmentSummaryBenefitRow(r)
                : buildInvestmentBenefitRowGuarantee(r)
            ),
          ...investmentBenefitTable.nonGuaranteeBenefits
            .slice(
              currentPage * rowsPerPage[currentPage > 0 ? currentPage - 1 : 0],
              rowsPerPage[currentPage] * (currentPage + 1)
            )
            .map((r, k) =>
              nonGuaranteeBenefitsLength - 1 === k
                ? buildInvestmentSummaryBenefitRow(r)
                : buildInvestmentBenefitRowNonGuarantee(r)
            ),
        ],
      },
      layout: 'investmentBenefitTableStyle',
      style: 'table',
    })
  })

  return investmentBenefitTables
}

export const buildInvestmentBenefitValueTable = (investmentBenefitTable: InvestmentBenefitTable) =>
  buildTermLifeValueTable(investmentBenefitTable)

export type investmentBenefitTableSectionProps = {
  investmentBenefit: InvestmentBenefitTable,
  startDate: string,
  endDate: string,
  basicPlan: DisplayProduct,
}

export const investmentBenefitTableSection = ({
  investmentBenefit,
  startDate,
  endDate,
  basicPlan,
}: investmentBenefitTableSectionProps) => [
  { text: MESSAGES.INVESTMENT_BENEFITS, style: 'title', pageBreak: 'before' },
  ...buildInvestmentBenefitValueTable(investmentBenefit),
  buildDisclaimer({ startDate, endDate, basicPlan }),
]
