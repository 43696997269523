// @flow

import _ from 'lodash'
import { isNotNil } from 'core/service/lib/type-check'
import { Input } from 'reactstrap'

export type Option = { value: string, text: string }

type Props = {
  onChange: (string, ?number) => void,
  value?: string,
  options: Option[],
  className?: string,
  placeHolder?: string,
  disabled?: boolean,
  testId?: string,
}

const Dropdown = ({ options, value, onChange, className = '', disabled = false, placeHolder, testId }: Props) => {
  const combinedClassName = 'custom-select ' + className
  return (
    <Input
      data-testid={testId}
      type="select"
      className={combinedClassName}
      onClick={(e) => e.preventDefault()}
      onChange={(e) => {
        onChange(e.target.value, e.nativeEvent.target.selectedIndex)
      }}
      value={value}
      disabled={disabled}
    >
      {isNotNil(placeHolder) && (
        <option value="" hidden disabled>
          {placeHolder}
        </option>
      )}
      {_.map(options, (option, index) => (
        <option key={index} value={option.value} data-text={option.text} data-selected={option.value === value}>
          {option.text}
        </option>
      ))}
    </Input>
  )
}

export default Dropdown
