// @flow

import { Section, Bold } from 'core/service/pdf-generation/products/common/components/general'
import VALUE from 'core/data-model/constants/values'

const getPage1 = () => {
  const row1 = [Bold('ระยะเวลาในการคุ้มครอง'), 'ครบอายุ 85 ปี']
  const row2 = [Bold('อายุรับประกันภัย'), '1 เดือน – 70 ปี']
  const row3 = [
    Bold('ผลประโยชน์กรณีเสียชีวิต'),
    [
      'หากผู้เอาประกันภัยเสียชีวิตก่อนวันครบกำหนดสัญญาและในขณะที่กรมธรรม์นี้มีผลบังคับ บริษัทจะจ่ายผลประโยชน์ ดังต่อไปนี้ ให้แก่ผู้รับประโยชน์',
      '1) มูลค่ารับซื้อคืนหน่วยลงทุน ณ วันที่ประเมินราคาซึ่งเป็นวันทำการวันแรกถัดจากวันที่บริษัทได้รับเอกสารหรือหลักฐานที่เกี่ยวข้องกับการมรณกรรมครบถ้วน',
      '2) จำนวนเอาประกันภัย โดยบริษัทจะจ่ายเมื่อได้รับเอกสารหรือหลักฐานที่เกี่ยวข้องกับการมรณกรรมครบถ้วนและอนุมัติการจ่ายสินไหมมรณกรรมแล้ว',
      '3) ร้อยละ 5 ของมูลค่ารับซื้อคืนหน่วยลงทุนของเบี้ยประกันภัยหลักเพื่อความคุ้มครอง ณ วันที่ประเมินราคาซึ่งเป็นวันทำการวันแรกถัดจากวันที่บริษัทได้รับเอกสารหรือหลักฐาน',
      'ที่เกี่ยวข้องกับการมรณกรรมครบถ้วน และอนุมัติการจ่ายสินไหมมรณกรรมแล้ว',
      'ทั้งนี้ บริษัทจะคืนค่าธรรมเนียมกรมธรรม์รายเดือนในส่วนที่ยังมิได้ให้ความคุ้มครองถัดจากวันที่ผู้เอาประกันภัยเสียชีวิตซึ่งบริษัทได้ทำการหักไว้ (ถ้ามี)',
    ],
  ]
  const row4 = [
    Bold('ผลประโยชน์กรณีครบกำหนดสัญญา'),
    [
      'หากผู้เอาประกันภัยมีชีวิตอยู่จนถึงวันครบกำหนดสัญญา บริษัทจะจ่ายมูลค่ารับซื้อคืนหน่วยลงทุนที่ราคารับซื้อคืนหน่วยลงทุน ณ วันที่ประเมินราคาซึ่งเป็นวันทำการวันแรก',
      'ถัดจากวันครบกำหนดสัญญาให้แก่ผู้เอาประกันภัย',
    ],
  ]
  const row5 = [
    Bold('เบี้ยประกันภัยหลักเพื่อความคุ้มครอง'),
    [
      'ผู้เอาประกันภัยสามารถกำหนดเบี้ยประกันภัยหลักเพื่อความคุ้มครองที่ต้องการชำระได้เอง โดยไม่ต่ำกว่าข้อกำหนดเบี้ยประกันภัยขั้นต่ำต่อปี  18,000 บาท หรือ 1,500 บาทต่อเดือน',
      'ทั้งนี้เป็นไปตามหลักเกณฑ์ที่บริษัทกำหนด',
    ],
  ]
  const row6 = [
    Bold('เบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษ'),
    [
      'ผู้เอาประกันภัยสามารถเลือกชำระเบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษได้ไม่น้อยกว่า  10,000  บาทต่อครั้ง ทั้งนี้เป็นไปตามหลักเกณฑ์ที่บริษัทกำหนด',
    ],
  ]
  const row7 = [
    Bold('การนำเบี้ยประกันภัยไปซื้อหน่วยลงทุน'),
    [
      '- เบี้ยประกันภัยหลักเพื่อความคุ้มครองงวดแรก หลังจากการหักค่าธรรมเนียมในการจัดสรรเบี้ยประกันภัยเบี้ยประกันภัยหลักเพื่อความคุ้มครอง และค่าธรรมเนียมกรมธรรม์รายเดือน',
      'แล้วจะถูกนำไปซื้อหน่วยลงทุนตามการจัดสรรการลงทุนที่ผู้เอาประกันภัยกำหนดที่ราคาขายหน่วยลงทุน ณ วันที่ประเมินราคาวันแรกถัดจากวันเริ่มมีผลคุ้มครอง',
      '- เบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษที่ชำระพร้อมเบี้ยประกันภัยหลักเพื่อความคุ้มครองงวดแรก (ถ้ามี) หลังจากการหักค่าธรรมเนียมในการจัดสรรเบี้ยประกันภัยเพื่อการลงทุน',
      'เพิ่มเติมพิเศษแล้ว จะถูกนำไปซื้อหน่วยลงทุนตามการจัดสรรการลงทุนที่ผู้เอาประกันภัยกำหนดที่ราคาขายหน่วยลงทุน ณ วันที่ประเมินราคาวันแรกถัดจากวันเริ่มมีผลคุ้มครอง',
      '- เบี้ยประกันภัยหลักเพื่อความคุ้มครองงวดต่อไป หลังจากการหักค่าธรรมเนียมในการจัดสรรเบี้ยประกันภัยหลักเพื่อความคุ้มครอง และหนี้สินใดๆ ที่ค้างชำระตามสัญญาประกันภัยนี้',
      '(ถ้ามี) จะถูกนำไปซื้อหน่วยลงทุนตามการจัดสรรการลงทุนครั้งล่าสุดที่ราคาขายหน่วยลงทุน ณ วันที่ประเมินราคาวันแรกถัดจากวันที่บริษัทได้รับเบี้ยประกันภัยเพื่อความคุ้มครอง',
      'ครบเต็มจำนวน',
      '- เบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษที่ชำระ หลังจากการหักค่าธรรมเนียมในการจัดสรรเบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษ และหนี้สินใด ๆ ที่ค้างชำระตามสัญญา',
      'ประกันภัยนี้ (ถ้ามี) จะถูกนำไปซื้อหน่วยลงทุนตามการจัดสรรการลงทุนสำหรับเบี้ยประกันภัยหลักเพื่อความคุ้มครองครั้งล่าสุด หรือตามสัดส่วนที่ผู้เอาประกันภัยกำหนดที่ราคาขาย',
      'หน่วยลงทุน ณ วันที่ประเมินราคาวันแรกถัดจากวันที่บริษัทได้รับเบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษครบเต็มจำนวน',
      '- การชำระเบี้ยประกันภัยด้วยวิธีใด ๆ จะถือว่าได้ชำระเบี้ยประกันภัยแล้ว ก็ต่อเมื่อบริษัทสามารถเรียกเก็บเบี้ยประกันภัยเป็นเงินสดได้แล้ว ณ สำนักงานใหญ่',
    ],
  ]
  const row8 = [
    Bold('จำนวนเงินเอาประกันภัย'),
    'ผู้เอาประกันภัยสามารถกำหนดจำนวนเงินเอาประกันภัยและปรับเปลี่ยนได้ ทั้งนี้เป็นไปตามหลักเกณฑ์ที่บริษัทกำหนด',
  ]
  const row9 = [
    Bold('สัญญาเพิ่มเติม'),
    'ผู้เอาประกันภัยสามารถซื้อสัญญาเพิ่มเติมแนบท้ายกรมธรรม์ได้ ทั้งนี้ เป็นไปตามหลักเกณฑ์ที่บริษัทกำหนด',
  ]

  const title = 'สรุปย่อ ข้อกำหนดและเงื่อนไขทั่วไป รวมทั้งสิทธิประโยชน์ตามกรมธรรม์'
  const content = [
    {
      fontSize: 12,
      table: {
        widths: [160, '*'],
        body: [row1, row2, row3, row4, row5, row6, row7, row8, row9],
      },
      layout: {
        hLineColor: VALUE.MEDIUM_BLUE,
        vLineColor: VALUE.MEDIUM_BLUE,
        fillColor: function(i, node) {
          return i % 2 === 0 ? null : VALUE.ZEBRA_BACKGROUND_BLUE
        },
      },
    },
  ]

  return Section(title, content)
}

const getPage1_2 = () => {
  const row10 = [
    Bold('โบนัสสำหรับการชำระเบี้ยประกันภัยหลักเพื่อความคุ้มครอง'),
    [
      '- กรณีที่ผู้เอาประกันภัยชำระเบี้ยประกันหลักเพื่อความคุ้มครองอย่างต่อเนื่องตามกำหนดชำระเบี้ยประกันภัยครบทุกงวดตั้งแต่ปีกรมธรรม์ที่ 1 ถึงปีกรมธรรม์ที่ 4 รวมทั้งไม่เคยทำการลด',
      'จำนวนเบี้ยประกันภัยหลักเพื่อความคุ้มครอง และไม่เคยมีการถอนเงินจากการขายคืนหน่วยลงทุนของเบี้ยประกันภัยหลักเพื่อความคุ้มครองตั้งแต่ปีกรมธรรม์ที่ 1 ถึงปีกรมธรรม์ที่ 4',
      'และกรมธรรม์ไม่เคยขาดอายุตั้งแต่ปีกรมธรรม์ที่ 1 ถึงปีกรมธรรม์ที่ 4 ผู้เอาประกันภัยจะได้รับโบนัสสำหรับการชำระเบี้ยประกันภัยหลักเพื่อความคุ้มครอง โดยบริษัทจะจ่ายโบนัส',
      'สำหรับการชำระเบี้ยประกันภัยหลักเพื่อความคุ้มครองให้ทุก ๆ สิ้นปีกรมธรรม์ เริ่มตั้งแต่สิ้นปีกรมธรรม์ปีที่ 4 เป็นต้นไป ในอัตราร้อยละ 0.2 ต่อปี ของมูลค่ารับซื้อคืนหน่วยลงทุน',
      'ณ วันทำการสุดท้ายของแต่ละเดือนตามปีปฏิทินเฉลี่ย 12 เดือนย้อนหลัง',
      '- ในกรณีที่หลังจากได้รับโบนัสสำหรับการชำระเบี้ยประกันภัยหลักเพื่อความคุ้มครองแล้ว ผู้เอาประกันภัยแจ้งความประสงค์เพื่อขอถอนเงินจากการขายคืนหน่วยลงทุนของ',
      'เบี้ยประกันภัยหลักเพื่อความคุ้มครองจากกรมธรรม์ หรือใช้สิทธิ์การหยุดพักชำระเบี้ยประกันภัยในปีใด ผู้เอาประกันภัยจะไม่มีสิทธิได้รับโบนัสสำหรับการชำระเบี้ยประกันภัยหลัก',
      'เพื่อความคุ้มครองในปีกรมธรรม์นั้น ๆ',
      '- ในกรณีที่หลังจากได้รับโบนัสสำหรับการชำระเบี้ยประกันภัยหลักเพื่อความคุ้มครองแล้ว ผู้เอาประกันภัยแจ้งความประสงค์เพื่อขอลดจำนวนเบี้ยประกันภัยหลักเพื่อความคุ้มครอง สิทธิ',
      'การได้รับโบนัสสำหรับการชำระเบี้ยประกันภัยหลักเพื่อความคุ้มครองนี้จะสิ้นสุดลงและผู้เอาประกันภัยไม่มีสิทธิได้รับโบนัสสำหรับการชำระเบี้ยประกันภัยหลักเพื่อความคุ้มครองนี้อีกต่อไป',
      '- ในกรณีที่กรมธรรม์ขาดอายุหลังจากได้รับโบนัสสำหรับการชำระเบี้ยประกันภัยหลักเพื่อความคุ้มครองแล้ว และผู้เอาประกันภัยได้มีการต่ออายุกรมธรรม์ตามเงื่อนไขที่ระบุในหมวดที่ 2',
      'การประกันภัย ข้อ 16. การต่ออายุกรมธรรม์ (Reinstatement of the Policy) ผู้เอาประกันภัยจะได้รับสิทธิการได้รับโบนัสสำหรับการชำระเบี้ยประกันภัยหลักเพื่อความคุ้มครองต่อไป',
    ],
  ]
  const row11 = [
    Bold('ประเภทของกองทุนที่นำเสนอคู่กับแบบประกัน'),
    [
      'บริษัทได้คัดเลือกกองทุนประเภทต่าง ๆ ประกอบไปด้วย กองทุนรวมตลาดเงิน กองทุนรวมพันธบัตรรัฐบาล กองทุนรวมตราสารแห่งหนี้ กองทุนรวมผสม กองทุนรวมตราสารแห่งทุน',
      'กองทุนรวมที่ลงทุนในสินทรัพย์ทางเลือก หรือกองทุนรวมอื่น ๆ ตามที่สำนักงานคณะกรรมการกำกับหลักทรัพย์และตลาดหลักทรัพย์กำหนด',
    ],
  ]

  const title = 'สรุปย่อ ข้อกำหนดและเงื่อนไขทั่วไป รวมทั้งสิทธิประโยชน์ตามกรมธรรม์ (ต่อ)'
  const content = [
    {
      fontSize: 12,
      table: {
        widths: [160, '*'],
        body: [row10, row11],
      },
      layout: {
        hLineColor: VALUE.MEDIUM_BLUE,
        vLineColor: VALUE.MEDIUM_BLUE,
        fillColor: function(i, node) {
          return i % 2 === 0 ? null : VALUE.ZEBRA_BACKGROUND_BLUE
        },
      },
    },
  ]

  const row1 = [Bold({ style: 'tableHeader', colSpan: 2, text: 'ความยืดหยุ่นของกรมธรรม์', alignment: 'left' }), {}]
  const row2 = [
    Bold('การสับเปลี่ยนกองทุน'),
    [
      '- ผู้เอาประกันภัยอาจแจ้งความประสงค์เพื่อขอสับเปลี่ยนกองทุนบางส่วนหรือทั้งหมดจากกองทุนหนึ่งไปยังกองทุนอื่นได้ โดยขายคืนหน่วยลงทุนของกองทุนต้นทางไปซื้อหน่วยลงทุน',
      'ของกองทุนปลายทาง ทั้งนี้ต้องเป็นไปตามหลักเกณฑ์ที่บริษัทกำหนด โดยอาจมีค่าธรรมเนียมในการสับเปลี่ยนกองทุนตามที่ระบุอยู่ในตารางค่าธรรมเนียมกรมธรรม์',
      '- จำนวนเงินสับเปลี่ยนออกจากกองทุนต้นทางจะต้องไม่ต่ำกว่า 1,000 บาท โดยคำนวณจากราคารับซื้อคืนหน่วยลงทุน ณ วันที่ประเมินราคาวันแรกถัดจากบริษัทอนุมัติการสับเปลี่ยน',
      'กองทุนตามหลักเกณฑ์ของบริษัท',
      '- บริษัทจะไม่คิดค่าธรรมเนียมในการสับเปลี่ยนกองทุน ทั้งนี้บริษัทสงวนสิทธิในการเปลี่ยนแปลงค่าธรรมเนียมในการสับเปลี่ยนกองทุนโดยต้องได้รับความเห็นชอบจากนายทะเบียนก่อน',
      '- บริษัทจะซื้อหน่วยลงทุนของกองทุนปลายทางที่ราคาขายหน่วยลงทุน ณ วันที่ประเมินราคาวันแรกถัดจากวันที่บริษัทได้รับแจ้งราคารับซื้อคืนหน่วยลงทุนของกองทุนต้นทางครบทุกกองทุน',
      '- บริษัทจะแจ้งการทำรายการเพิ่มหรือลดจำนวนหน่วยลงทุนให้แก่ผู้เอาประกันภัยภายในสามสิบวันทำการ (30 วันทำการ) นับแต่วันที่บริษัทได้รับการยืนยันจำนวนหน่วยลงทุน',
      'คงเหลือทั้งหมดของผู้เอาประกันภัย',
    ],
  ]
  const row3 = [
    Bold('การถอนเงินจากกรมธรรม์'),
    [
      'ผู้เอาประกันภัยอาจแจ้งความประสงค์เพื่อขอถอนเงินจากกรมธรรม์ ตามหลักเกณฑ์ที่บริษัทกำหนด',
      '- การถอนเงินจากกรมธรรม์ จะถอนเงินจากการขายคืนหน่วยลงทุนของเบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษ (ถ้ามี) ก่อน หากไม่พอจึงจะทำการถอนเงินจากการขายคืนหน่วยลงทุน',
      'ของเบี้ยประกันภัยหลักเพื่อความคุ้มครอง',
      '- จำนวนเงินขั้นต่ำในการถอนเงินออกจากกรมธรรม์แต่ละครั้งต้องไม่น้อยกว่า 2,000 บาท และมูลค่าคงเหลือหลังการถอนจะต้องเหลือไม่น้อยกว่า 5,000 บาท สำหรับการชำระเบี้ย',
      'ประกันภัยหลักเพื่อความคุ้มครองปีที่ 1-3 และไม่น้อยกว่า 15,000 บาท สำหรับการชำระเบี้ยประกันภัยหลักเพื่อความคุ้มครองปีที่ 4 เป็นต้นไป โดยคำนวณจากราคารับซื้อคืนหน่วยลงทุนล่าสุด',
      '- บริษัทจะคิดค่าธรรมเนียมในการถอนเงินจากกรมธรรม์ตามที่ระบุไว้ในตารางค่าธรรมเนียมกรมธรรม์',
    ],
  ]

  const row4 = [
    Bold('การหยุดพักชำระเบี้ยประกันภัย'),
    [
      '- ผู้เอาประกันภัยสามารถหยุดพักชำระเบี้ยประกันภัยได้ภายหลังชำระเบี้ยประกันภัยหลักเพื่อความคุ้มครองไม่น้อยกว่าจำนวนงวดการชำระเบี้ยประกันภัยดังนี้ งวดรายปี จำนวน 3 งวด',
      'งวดราย 6 เดือน จำนวน 6 งวด งวดราย 3 เดือน จำนวน 12 งวด หรืองวดรายเดือน จำนวน 36 งวด และมีมูลค่ารับซื้อคืนหน่วยลงทุน',
      '- ในกรณีที่มีการเปลี่ยนแปลงงวดการชำระเบี้ยประกันภัยหลักเพื่อความคุ้มครอง การนับจำนวนงวดจะเปลี่ยนแปลงไปตามปีที่ชำระเบี้ยประกันภัย',
    ],
  ]

  const content2 = [
    {
      marginTop: 8,
      fontSize: 12,
      table: {
        widths: [160, '*'],
        body: [row1, row2, row3, row4],
      },
      layout: {
        hLineColor: VALUE.MEDIUM_BLUE,
        vLineColor: VALUE.MEDIUM_BLUE,
        fillColor: function(i, node) {
          return i % 2 === 0 ? null : VALUE.ZEBRA_BACKGROUND_BLUE
        },
      },
    },
  ]

  return Section(title, [content, content2])
}

const getPage2 = () => {
  const row1_1 = [
    Bold({ style: 'tableHeader', colSpan: 2, text: 'ความยืดหยุ่นของกรมธรรม์ (ต่อ)', alignment: 'left' }),
    {},
  ]

  const row1_3 = [
    Bold('การเวนคืนกรมธรรม์'),
    [
      Bold('- การเวนคืนกรมธรรม์'),
      'เมื่อกรมธรรม์มีมูลค่าเวนคืนกรมธรรม์ ผู้เอาประกันภัยย่อมมีสิทธิที่จะบอกเลิกสัญญาประกันภัยนี้ได้โดยแจ้งความประสงค์เพื่อขอเวนคืนกรมธรรม์ และย่อมทำให้สัญญาประกันภัยนี้สิ้นผลบังคับ',
      Bold('- การเวนคืนกรมธรรม์โดยอัตโนมัติ'),
      'หากผู้เอาประกันภัยไม่ชำระเบี้ยประกันภัยหลักเพื่อความคุ้มครองภายในระยะเวลาผ่อนผัน เป็นเวลาสามสิบเอ็ดวัน (31 วัน) นับแต่วันครบกำหนดชำระเบี้ยประกันภัย',
      'และยังชำระเบี้ยประกันภัยหลักเพื่อความคุ้มครองไม่ครบตามงวดการชำระเบี้ยประกันภัย ดังนี้ งวดรายปี จำนวน 3 งวด งวดราย 6 เดือน จำนวน 6 งวด งวดราย 3 เดือน',
      'จำนวน 12 งวด หรืองวดรายเดือน จำนวน 36 งวด กรมธรรม์จะถูกเวนคืนโดยอัตโนมัติ ในกรณีที่มีการเปลี่ยนแปลงงวดการชำระเบี้ยประกันภัยหลักเพื่อความคุ้มครอง',
      'การนับจำนวนงวดจะเปลี่ยนแปลงไปตามปีที่ชำระเบี้ยประกันภัย',
      '- ทั้งนี้ บริษัทฯ จะเรียกเก็บค่าธรรมเนียมการเวนคืนกรมธรรม์ การเวนคืนกรมธรรม์โดยอัตโนมัติ และหนี้สินที่ค้างชำระภายใต้สัญญาประกันภัย (ถ้ามี)',
    ],
  ]

  const row1_4 = [
    Bold('สิทธิการมีผลบังคับอย่างต่อเนื่อง'),
    [
      'สิทธิการมีผลบังคับอย่างต่อเนื่องในส่วนของความคุ้มครองชีวิตตามจำนวนเงินเอาประกันภัย และความคุ้มครองตามสัญญาเพิ่มเติมแบบชำระค่าการประกันภัยโดยการขายคืนหน่วยลงทุนทุกฉบับ (ถ้ามี)',
      '- ภายในระยะเวลา 5 ปี นับจากวันเริ่มมีผลคุ้มครอง ความคุ้มครองชีวิตตามจำนวนเงินเอาประกันภัย และความคุ้มครองตามสัญญาเพิ่มเติมแบบชำระค่าการประกันภัยโดยการ',
      'ขายคืนหน่วยลงทุนทุกฉบับ (ถ้ามี) จะมีผลบังคับอย่างต่อเนื่องถึงแม้มูลค่ารับซื้อคืนหน่วยลงทุนจะมีไม่เพียงพอต่อการชำระค่าธรรมเนียมกรมธรรม์รายเดือน ซึ่งถึงกำหนดชำระ',
      'ภายใต้เงื่อนไขเมื่อผู้เอาประกันภัย',
      '(1) ได้ชำระเบี้ยประกันภัยหลักเพื่อความคุ้มครองครบตามกำหนดชำระเบี้ยประกันภัยทุกงวดภายในระยะเวลาผ่อนผันชำระเบี้ยประกันภัย และ',
      '(2) ไม่เคยมีการถอนเงินจากการขายคืนหน่วยลงทุนของเบี้ยประกันภัยหลักเพื่อความคุ้มครอง และ',
      '(3) ไม่เคยทำการลดจำนวนเบี้ยประกันภัยหลักเพื่อความคุ้มครอง',
      '- หากผู้เอาประกันภัยมิได้ปฏิบัติตามเงื่อนไขข้างต้น สิทธิของการมีผลบังคับอย่างต่อเนื่องของกรมธรรม์นี้จะสิ้นสุดลง และไม่สามารถใช้สิทธิดังกล่าวได้อีก',
      '- ข้อกำหนดและเงื่อนไขอื่น ๆ ของสัญญาเพิ่มเติมแบบชําระค่าการประกันภัยโดยการขายคืนหน่วยลงทุนให้อ้างอิงตามข้อตกลงคุ้มครองของสัญญาเพิ่มเติมนั้น',
    ],
  ]

  const row1 = [
    Bold({ style: 'tableHeader', colSpan: 2, text: 'นิยามในส่วนของการลงทุนของกรมธรรม์', alignment: 'left' }),
    {},
  ]
  const row2 = [Bold('วันทำการ'), 'วันเปิดทำการตามปกติของบริษัท และเป็นวันเปิดทำการซื้อขายของกองทุนด้วย']
  const row3 = [
    Bold('วันที่ประเมินราคา'),
    'วันทำการที่มีการคำนวณมูลค่าทรัพย์สินสุทธิของกองทุน ทั้งนี้ขึ้นอยู่กับเงื่อนไขการดำเนินงานของกองทุนนั้น ๆ',
  ]
  const row4 = [Bold('หน่วยลงทุน'), 'หน่วยลงทุนภายใต้กรมธรรม์ฉบับนี้']
  const row5 = [
    Bold('ราคาขายหน่วยลงทุน'),
    [
      'ราคาที่นำไปใช้คำนวณเพื่อหาจำนวนหน่วยลงทุนที่จะได้รับ (Credited) จากการซื้อหน่วยลงทุน โดยราคาขายหน่วยลงทุนของแต่ละกองทุนจะถูกกำหนดโดยบริษัทจัดการ',
    ],
  ]

  const row6 = [
    Bold('ราคารับซื้อคืนหน่วยลงทุน'),
    [
      'ราคาที่นำไปใช้คำนวณเพื่อหาจำนวนหน่วยลงทุนที่จะถูกหักออก (Debited) เนื่องจากการขายหน่วยลงทุน โดยราคารับซื้อคืนหน่วยลงทุนของแต่ละกองทุนจะถูกกำหนดโดยบริษัทจัดการ',
    ],
  ]

  const row7 = [
    Bold('มูลค่าหน่วยลงทุน'),
    [
      'มูลค่าทรัพย์สินสุทธิของแต่ละกองทุนหารด้วยจำนวนหน่วยลงทุนที่จำหน่ายได้แล้วทั้งหมดของกองทุนนั้น เมื่อสิ้นวันทำการที่คำนวณ ทั้งนี้มูลค่าหน่วยลงทุน และมูลค่าทรัพย์สินสุทธิ',
      'จะเป็นไปตามที่บริษัทจัดการเป็นผู้คำนวณ',
    ],
  ]

  const row8 = [
    Bold('มูลค่าบัญชีกรมธรรม์'),
    'ผลรวมของจำนวนหน่วยลงทุน คูณกับมูลค่าหน่วยลงทุน ณ วันที่ประเมินราคาของแต่ละกองทุนภายใต้กรมธรรม์',
  ]

  const row9 = [
    Bold('มูลค่ารับซื้อคืนหน่วยลงทุน'),
    'ผลรวมของมูลค่ารับซื้อคืนหน่วยลงทุนของเบี้ยประกันภัยหลักเพื่อความคุ้มครองและมูลค่ารับซื้อคืนหน่วยลงทุนของเบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษ',
  ]

  const row10 = [
    Bold('มูลค่าเวนคืนกรมธรรม์'),
    'มูลค่ารับซื้อคืนหน่วยลงทุนหักด้วยค่าธรรมเนียมในการถอนเงินจากกรมธรรม์ (ถ้ามี) ตามที่ระบุไว้ในตารางค่าธรรมเนียมกรมธรรม์',
  ]

  const title = 'สรุปย่อ ข้อกำหนดและเงื่อนไขทั่วไป รวมทั้งสิทธิประโยชน์ตามกรมธรรม์ (ต่อ)'

  const content_1 = [
    {
      fontSize: 12,
      table: {
        widths: [160, '*'],
        body: [row1_1, row1_3, row1_4],
      },
      layout: {
        hLineColor: VALUE.MEDIUM_BLUE,
        vLineColor: VALUE.MEDIUM_BLUE,
        fillColor: function(i, node) {
          return i % 2 === 0 ? null : VALUE.ZEBRA_BACKGROUND_BLUE
        },
      },
    },
  ]

  const content_2 = [
    {
      marginTop: 8,
      fontSize: 12,
      table: {
        widths: [160, '*'],
        body: [row1, row2, row3, row4, row5, row6, row7, row8, row9, row10],
      },
      layout: {
        hLineColor: VALUE.MEDIUM_BLUE,
        vLineColor: VALUE.MEDIUM_BLUE,
        fillColor: function(i, node) {
          return i % 2 === 0 ? null : VALUE.ZEBRA_BACKGROUND_BLUE
        },
      },
    },
  ]

  return Section(title, [content_1, content_2])
}

export const RPUDRConditions = () => {
  return [getPage1(), getPage1_2(), getPage2()]
}
