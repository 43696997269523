import { requestWrapper } from 'e-submission/domain/data'

export const createPolicy = (user, payload) => {
  return requestWrapper.postRequest('esub/policies', user, payload).then(({ data }) => data)
}

export const updatePolicy = (user, policyID, payload) => {
  return requestWrapper.putRequest(`esub/policies/${policyID}`, user, payload).then(({ data }) => data)
}

export const patchPolicy = (user, policyID, payload) => {
  return requestWrapper.patchRequest(`esub/policies/${policyID}`, user, payload).then(({ data }) => data)
}

export const getPolicyStatus = (user, policyID) => {
  return requestWrapper.getRequest(`esub/policies/${policyID}/status`, user).then(({ data }) => data)
}

export const getIsVerifyOTP = (user, policyID) => {
  return requestWrapper.getRequest(`esub/policies/${policyID}/isverifyotp`, user).then(({ data }) => data)
}
