// @flow

import _ from 'lodash'
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'
import { bindActionCreators } from 'redux'
import { compose, lifecycle, setDisplayName } from 'recompose'
import { PieChart, Pie, Cell, LabelList } from 'recharts'

import { svgStringToBase64 } from 'core/service/lib/tobase64'
import { saveImage } from 'quick-quote/ui/actions'
import values from 'core/data-model/constants/values'
import { getPatternByIndex } from './chart-patterns'

type PieData = { name: string, value: number }

export const fundGroupColor = (index) => values.PIE_CHART_FUND_COLORS[index % values.PIE_CHART_FUND_COLORS.length]

const chartDisplayConfig = {
  S: {
    width: 800,
    height: 800,
    fontSize: 45,
  },
  M: {
    width: 800,
    height: 800,
    fontSize: 40,
  },
  L: {
    width: 800,
    height: 800,
    fontSize: 35,
  },
}

export const PieChartSize = {
  S: 'S',
  M: 'M',
  L: 'L',
}

const Wrapper = styled.div`
  ${({ hide }) =>
    hide &&
    css`
      display: none;
    `}
`

const getChartDisplayConfig = (size: string) => {
  return _.get(chartDisplayConfig, size, chartDisplayConfig.L)
}

// To generate pattern as base64 png image for pie-chart
export const generatePatterns = async () => {
  const patterns = []
  for (let index = 0; index < values.PIE_CHART_FUND_COLORS.length; index++) {
    const { string, url } = getPatternByIndex(index)
    const imageSVG = `<svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"><defs>${string}</defs><rect width="30" height="30" fill="${url}"/></svg>`
    const chart = await svgStringToBase64(imageSVG)
    patterns.push(chart)
  }
  return patterns
}

export const Component = ({
  data,
  size,
  imageId,
  hide,
}: {
  data: PieData[],
  size: string,
  imageId?: string,
  hide?: boolean,
}) => {
  const { width, height, fontSize } = getChartDisplayConfig(size)

  const pieDataTransformed = data.map((item, index) => {
    const { name, value } = item
    const { color, string, url } = getPatternByIndex(index)
    return {
      name,
      value,
      label: `${value}%`,
      color: color,
      pattern: string,
      patternURL: url,
    }
  })

  const addPatternScale = (patternDefString) =>
    patternDefString.replace('<pattern ', '<pattern patternTransform="scale(4 4)"')

  return (
    <Wrapper id={`fund-allocation-chart-container--${imageId}`} hide={hide}>
      <PieChart width={width} height={height} xmlns="http://www.w3.org/2000/svg">
        {pieDataTransformed.map((entry, index) => (
          <defs key={`def-${index}`} dangerouslySetInnerHTML={{ __html: `${addPatternScale(entry.pattern)}` }} />
        ))}
        <Pie
          data={pieDataTransformed}
          dataKey="value"
          nameKey="name"
          startAngle={90}
          endAngle={-270}
          isAnimationActive={false}
          fill="#8884d8"
        >
          {pieDataTransformed.map((entry, index) => (
            <Cell key={index} fill={entry.patternURL} />
          ))}
          <LabelList dataKey="label" position="top" fill={'black'} stroke={'none'} fontSize={fontSize} />
        </Pie>
      </PieChart>
    </Wrapper>
  )
}

export const FundAllocationPieChart = compose(
  setDisplayName('FundAllocationPieChart'),
  connect(null, (dispatch) => ({
    saveImage: bindActionCreators(saveImage, dispatch),
  })),
  lifecycle({
    async componentDidMount() {
      const { imageId, saveImage } = this.props
      if (imageId) {
        const chartContainer = document.getElementById(`fund-allocation-chart-container--${imageId}`)
        if (!chartContainer) return ''
        let svgTag = chartContainer.innerHTML.match(/<svg[\s\S]*?svg>/)
        if (!svgTag) return ''
        const resultSVGString = svgTag[0].replace('<svg ', '<svg xmlns="http://www.w3.org/2000/svg" ')
        const chart = await svgStringToBase64(resultSVGString)
        saveImage(imageId, chart)
      }
    },
  })
)(Component)
