// @flow
import _ from 'lodash'
import PolicyValueRow, { dashedOutOrWithPrecision } from './policy-value-row'
import MESSAGES from 'core/data-model/constants/bi-messages'
import CONSTANTS from 'core/data-model/constants/messages'
import { wrapWordingToTextJustify } from 'core/service/benefit-illustration/utils/wording-wrapper'
import type { PolicyValueTableProps } from 'core/service/benefit-illustration/policy-value'
import { _getMessageUnderiShieldPolicyValueTable } from 'core/service/benefit-illustration/policy-value'

export const IShieldMessageUnderTable = ({ policyValue, basicPlan }: PolicyValueTableProps) => {
  const message = _getMessageUnderiShieldPolicyValueTable({
    policyValue,
    basicPlan,
  })
  if (message) {
    return <div className="ishield-conditional-info">{message}</div>
  } else {
    return null
  }
}

const PolicyValueTable = (props: PolicyValueTableProps) => {
  return (
    <div className="policy group" id="policy-value">
      <h3>{MESSAGES.POLICY_VALUE_TABLE}</h3>

      {renderPolicyValueTableForWholeLife(props)}
    </div>
  )
}

const PolicyTable = ({ className, children }: { className?: string, children?: React.Node }) => (
  <table className={`policy-table${className ? ` ${className}` : ''}`} id="policy-value-table">
    {children}
  </table>
)

// $$FlowFixMe
export const PolicyWithOutCashDrop = ({
  yearEndPolicyValues,
  lastYearEndPolicy,
  subtotalAnnualBasicPremium,
  subtotalAnnualRiderPremium,
  grandTotalPremium,
  subtotalCashDrop,
  subtotalLowSavingCashDrop,
  subtotalMediumSavingCashDrop,
  subtotalHighSavingCashDrop,
}) => (
  <PolicyTable className="with-cash-drop">
    <thead>
      <tr>
        <td rowSpan={2} width="5%">
          {CONSTANTS.AGE}
        </td>
        <td className="nowrap" rowSpan={2} width="5%">
          {MESSAGES.POLICY_VALUE_TABLE_INSURANCE_YEAR}
        </td>
        <td colSpan={3}>{MESSAGES.PREMIUM_PER_YEAR}</td>
        <td colSpan={2}>{MESSAGES.END_OF_YEAR_POLICY_WITH_BRACKET}</td>
      </tr>
      <tr>
        <td width="10%">{MESSAGES.BASIC_PLAN}</td>
        <td width="10%">{MESSAGES.RIDER}</td>
        <td width="10%">{MESSAGES.TOTAL}</td>
        <td width="10%">{MESSAGES.POLICY_VALUE_TABLE_CASH_SURRENDER_VALUE}</td>
        <td width="10%">{MESSAGES.POLICY_VALUE_TABLE_DEATH_BENEFIT}</td>
      </tr>
    </thead>
    <tbody>
      {yearEndPolicyValues.map((policyValue, index) => (
        <PolicyValueRow
          key={`policy-value-row-${policyValue.age.value}`}
          age={policyValue.age}
          yearEndPolicy={policyValue.yearEndPolicy}
          cumulativeBasicPremium={policyValue.cumulativeBasicPremium}
          annualBasicPremium={policyValue.annualBasicPremium}
          annualRiderPremium={policyValue.annualRiderPremium}
          annualTotalPremium={policyValue.annualTotalPremium}
          surrenderCash={policyValue.surrenderCash}
          deathBenefit={policyValue.deathBenefit}
          cumulativeDeathBenefit={policyValue.cumulativeDeathBenefit}
          basicSumAssured={policyValue.basicSumAssured}
        />
      ))}
      <tr className="total" id={`policy-value-row-${lastYearEndPolicy}`} key={`policy-value-row-${lastYearEndPolicy}`}>
        <td />
        <td />
        <td>{dashedOutOrWithPrecision(subtotalAnnualBasicPremium, 2)}</td>
        <td>{dashedOutOrWithPrecision(subtotalAnnualRiderPremium, 2)}</td>
        <td>{dashedOutOrWithPrecision(grandTotalPremium, 2)}</td>
        <td />
        <td />
      </tr>
    </tbody>
  </PolicyTable>
)

const PolicyValueContent = ({ basicPlan, ...props }) => {
  return <PolicyWithOutCashDrop {...props} />
}

const renderPolicyValueTableForWholeLife = ({ policyValue, basicPlan }: PolicyValueTableProps) => {
  const lastYearEndPolicy = _.get(_.last(policyValue.yearEndPolicyValues), 'yearEndPolicy', 0) + 1

  // $$FlowFixMe
  const policyValueTableProps = { policyValue, basicPlan }

  return (
    <div>
      <div className="table-wrap">
        <PolicyValueContent lastYearEndPolicy={lastYearEndPolicy} basicPlan={basicPlan} {...policyValue} />
      </div>

      <IShieldMessageUnderTable {...policyValueTableProps} />

      <div className="disclaimer">
        <div>{MESSAGES.NOTE}:</div>
        <div>
          <ul>
            <li
              dangerouslySetInnerHTML={{
                __html: wrapWordingToTextJustify(MESSAGES.PLB_POLICY_VALUE_TABLE_DISCLAIMER_ONE),
              }}
            ></li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default PolicyValueTable
