// @flow
import { formatNumber } from 'core/service/lib/number-format'
import type { RiderPlan } from 'core/data-model/rider'
import MESSAGES from 'core/data-model/constants/bi-messages'

export type SummaryRowProps = {
  id: string,
  productCode: string,
  productName: string,
  asterisks?: string,
  coverageYear: number,
  paymentPeriod: number | string,
  selectedPlan: RiderPlan,
  sumAssured: number,
  showSumAssuredDecimalPlaces: boolean,
  premium: number,
}

const SummaryRow = ({
  id,
  productCode,
  productName,
  asterisks = '',
  coverageYear,
  paymentPeriod,
  selectedPlan,
  sumAssured,
  showSumAssuredDecimalPlaces,
  premium,
}: SummaryRowProps) => {
  const getSumAssuredDisplayValue = (): string => {
    if (selectedPlan.planCode) {
      return MESSAGES.FLEXI_HEALTH_SUMMARY_BENEFIT_MESSAGE
    } else {
      if (showSumAssuredDecimalPlaces) {
        return formatNumber(sumAssured, 2)
      } else {
        return formatNumber(sumAssured)
      }
    }
  }
  const displayProductName = `${productName} (${productCode})${asterisks}`.replace(/\n/g, '<br>')
  return (
    <tr id={`summary-row-${id}`}>
      <td dangerouslySetInnerHTML={{ __html: displayProductName }}></td>
      <td>{coverageYear}</td>
      <td>{paymentPeriod}</td>
      <td>{getSumAssuredDisplayValue()}</td>
      <td>{formatNumber(premium, 2)}</td>
    </tr>
  )
}

export default SummaryRow
