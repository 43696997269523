import { isCordova, isDisable2ndDoc as shouldDisable2ndDoc } from 'e-submission/utils'
import { createSectionCreator, createField } from 'e-submission/domain/data-model/form/utils'
import {
  getInsuredDopaRequestFailed,
  getPayerDopaRequestFailed,
  getSkippedInsuredEkyc,
  getSkippedPayerEkyc,
} from 'e-submission/domain/data-model/form/selectors'

const disclaimerFields = (app, id) => {
  let icon = 'documentUploadDesktop'
  let descriptionText = 'พร้อมแนบรูปที่ชัดเจน'

  if (isCordova) {
    icon = 'documentUploadMobile'
    descriptionText = 'พร้อมถ่ายรูปให้ชัดเจน'
  }

  const isDisable2ndDoc = shouldDisable2ndDoc()
  const isSkippedInsuredEkyc = getSkippedInsuredEkyc(app)
  const isSkippedPayerEkyc = getSkippedPayerEkyc(app)
  const isSkippedEkyc = isSkippedInsuredEkyc || isSkippedPayerEkyc
  const insuredDopaRequestFailed = getInsuredDopaRequestFailed(app)
  const payerDopaRequestFailed = getPayerDopaRequestFailed(app)
  const isSkipDOPA = insuredDopaRequestFailed || payerDopaRequestFailed
  if (isDisable2ndDoc && (isSkipDOPA || isSkippedEkyc)) {
    const textStyle = `style="color: #2425aa; font-weight: bold"`
    icon = 'iconIdCard'
    descriptionText = `<div ${textStyle}>กรุณาดำเนินการยืนยันตัวตนผ่าน eKYC Service</div>
    <div ${textStyle}>หลังจากที่กดนำส่งงานเรียบร้อยแล้ว</div>
    <div style="color: #2425aa; margin-top: 8px">มิเช่นนั้นบริษัทจะไม่สามารถดำเนินการพิจารณาอนุมัติกรมธรรมได้</div>`
  }

  return [
    createField(`${id}.disclaimer`, [
      {
        id: `${id}.disclaimer`,
        c: 'Disclaimer',
        p: {
          icon,
          children: isDisable2ndDoc
            ? descriptionText
            : `
          <div>เซ็นชื่อยืนยันบัตรประชาชนบนกระดาษ</div>
          <div>${descriptionText}</div>
        `,
        },
      },
    ]),
  ]
}

export default createSectionCreator(disclaimerFields)
