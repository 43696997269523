import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { isEmpty } from 'lodash/fp'
import Icon from '../../Icon'
import FormEngine from '../../FormEngine'
import Dialog from '../../Dialog'
import { noop } from 'lodash/fp'
import Button from 'e-submission/components/Button'

export const _Dialog = ({
  subpage = '',
  className,
  isModalOpen,
  toggleModal,
  onEnter = noop,
  title,
  sections,
  okButton,
  customButtons = [],
  remoteSelling,
  isEdaConsent,
  customTitle = '',
}) => (
  <Dialog id={`${subpage}-modal`} className={className} isOpen={isModalOpen} toggle={toggleModal} onEnter={onEnter}>
    <ModalHeader>
      <div className="btn-left" onClick={toggleModal}>
        <Icon.arrowLeft />
      </div>
      <span>{customTitle || title}</span>
    </ModalHeader>
    <ModalBody>
      <FormEngine sections={sections} />
    </ModalBody>
    {remoteSelling ? (
      <ModalFooter>
        <Button onClick={toggleModal} outline>
          กลับ
        </Button>
      </ModalFooter>
    ) : null}
    {isEdaConsent && customButtons.length > 0 && (
      <ModalFooter style={{ justifyContent: 'center' }}>
        {customButtons
          .filter((button) => button.visible)
          .map((button, index) => (
            <Button
              key={index}
              onClick={() => {
                button.onClick({ toggleModal })
              }}
              disabled={button.disabled}
              outline={button.outline ? button.outline : null}
            >
              {button.text}
            </Button>
          ))}
      </ModalFooter>
    )}
    {okButton && (
      <ModalFooter>
        {customButtons.length ? (
          customButtons
            .filter((button) => button.visible)
            .map((button, index) => (
              <Button
                key={index}
                onClick={() => {
                  button.onClick({ toggleModal })
                }}
                disabled={button.disabled}
                outline={button.outline ? button.outline : null}
              >
                {button.text}
              </Button>
            ))
        ) : (
          <Button onClick={toggleModal} outline>
            กลับ
          </Button>
        )}

        {!isEmpty(okButton) && (
          <Button id={okButton.id} onClick={okButton.onClick} disabled={okButton.disabled}>
            {okButton.label}
          </Button>
        )}
      </ModalFooter>
    )}
  </Dialog>
)
