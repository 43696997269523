// @flow

import _ from 'lodash'
import Mustache from 'mustache'
import MESSAGES from 'core/data-model/constants/validation-messages'
import CONSTANTS from 'core/data-model/constants/messages'
import type { DisplayProduct } from 'core/service/display-product'
import { isValidAge } from 'core/service/insured/birthdate'
import type { DisplayProductQuery } from 'core/service'
import {
  getDisplayProducts,
  getSelectedDisplayProduct,
  getSelectedDisplayProductQuery,
} from 'quick-quote/product-selection/selectors'
import { renderUnit } from 'core/service/insured/age'
import type { Age } from 'core/data-model/insured'
import type { Effect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import type { UpdateSelectedBasicPlan } from './actions'
import { selectBasicPlan, UPDATE_SELECTED_BASIC_PLAN, VALIDATE_BASIC_PLAN, validateBasicPlan } from './actions'
import {
  getAge,
  getAllNatureOfDutyCodes,
  getInsured,
  getPayerAge,
  getPayerGender,
  getPayerRelation,
} from 'quick-quote/insured-information/selectors'
import { getSelectedModelFactorID } from './selectors'
import { getAvailableRiders } from 'quick-quote/product-common/coverage-plan/selectors'
import { updateCoveragePlanState } from 'quick-quote/product-common/coverage-plan/sagas'
import { updateBasicPlanErrorMessage } from 'quick-quote/product-common/coverage-plan/actions'

export const onUpdateSelectedBasicPlan = function*(action: UpdateSelectedBasicPlan): Generator<*, *, *> {
  const basicPlanCode = action.payload
  const displayProducts = yield select(getDisplayProducts)
  const product = _.find(displayProducts, { code: basicPlanCode })
  !!product && (yield put(selectBasicPlan(product)))
}

export const generateCoveragePlanFromBasicPlanChange = function*(
  calculateCoveragePlanFn: Function
): Generator<*, *, *> {
  const displayProductQuery: DisplayProductQuery = yield select(getSelectedDisplayProductQuery)
  const natureOfDutyCodes: string[] = yield select(getAllNatureOfDutyCodes)
  const insured = yield select(getInsured)
  const modelFactorID = yield select(getSelectedModelFactorID)
  const availableRiders = yield select(getAvailableRiders)
  const payerAge = yield select(getPayerAge)
  const payerGender = yield select(getPayerGender)
  const payerRelation = yield select(getPayerRelation)
  const newValue = yield call(calculateCoveragePlanFn, {
    displayProductQuery,
    insured,
    natureOfDutyCodes,
    modelFactorID,
    availableRiders,
    payerAge,
    payerGender,
    payerRelation,
  })
  yield call(updateCoveragePlanState, newValue)
  yield put(validateBasicPlan())
}

const getValidationMessage = (displayProduct: DisplayProduct, age: Age): string => {
  const { entryAge } = displayProduct
  if (!entryAge || isValidAge(age.value, age.unit, entryAge)) {
    return ''
  }
  return Mustache.render(MESSAGES.RULE_AGE, {
    owner: CONSTANTS.INSURED_PERSON,
    minimumAge: `${entryAge.minimum.value} ${renderUnit(entryAge.minimum.unit)}`,
    maximumAge: `${entryAge.maximum.value} ${renderUnit(entryAge.maximum.unit)}`,
  })
}

export const onValidateBasicPlan = function*(): Generator<*, *, *> {
  const selectedDisplayProduct: DisplayProduct = yield select(getSelectedDisplayProduct)
  const age: Age = yield select(getAge)
  const message = yield call(getValidationMessage, selectedDisplayProduct, age)
  return yield put(updateBasicPlanErrorMessage(message))
}

export function* sagas(): Generator<*, *, Effect[]> {
  yield [
    takeLatest(UPDATE_SELECTED_BASIC_PLAN, onUpdateSelectedBasicPlan),
    // saga impact current product remove take latest for now becaseu product is off-shelf
    //takeLatest(SELECT_BASIC_PLAN, generateCoveragePlanFromBasicPlanChange, calculateCoveragePlanFromBasicPlanChange),
    takeLatest(VALIDATE_BASIC_PLAN, onValidateBasicPlan),
    //takeLatest(UPDATE_BIRTHDATE, onValidateBasicPlan),
  ]
}
