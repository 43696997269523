// @flow
import { formatNumber } from 'core/service/lib/number-format'
import Mustache from 'mustache'
import { buildHeaderTable } from 'core/service/pdf-generation/utils/builder'
import MESSAGES from 'core/data-model/constants/bi-messages'
import VALUES from 'core/data-model/constants/values'

import {
  getPensionTypeString,
  getPensionPercent,
  getPensionPercents,
  calculatePensionAmount,
  calculatePensionAmounts,
  getTotalPensionPeriod,
  calculateTotalPensionAmount,
  calculateMinimumDeathCoverage,
} from 'core/service/benefit-illustration/products/retirement'
import type { BenefitSummary } from 'core/service/benefit-illustration'

const firstBenefitSummaryTable = (pensionType: string) => {
  return {
    title: Mustache.render(MESSAGES.RETIREMENT_BENEFITS, { pensionType: getPensionTypeString(pensionType) }),
    headers: [
      {
        text: MESSAGES.PERIOD_AGE,
        style: 'tableHeader',
      },
      {
        text: Mustache.render(MESSAGES.PENSION_TYPE, { pensionType: getPensionTypeString(pensionType) }),
        style: 'tableHeader',
      },
      {
        text: MESSAGES.PENSION_AMOUNT,
        style: 'tableHeader',
      },
    ],
  }
}

const buildFirstTotalBenefitSummary = (sumAssured: number, pensionType: string, productCode: string) => [
  {
    text: MESSAGES.TOTAL_BENEFIT_PENSION_AGE_60_88_PDF,
    alignment: 'center',
    bold: true,
  },
  {
    text: Mustache.render(MESSAGES.BUMNAN_READY_MAXIMUM_PENSION_PERIOD, {
      totalPensionPeriod: getTotalPensionPeriod(pensionType, productCode),
    }),
    alignment: 'center',
    margin: [0, 8, 0, 0],
    bold: true,
  },
  {
    text: formatNumber(calculateTotalPensionAmount(sumAssured, pensionType, productCode), 2),
    alignment: 'center',
    margin: [0, 8, 0, 0],
    bold: true,
  },
]

const buildFirstBenefitSummary = (sumAssured: number, pensionType: string, productCode: string) => {
  const pensionPercents = getPensionPercents(pensionType, productCode)
  const pensionAmounts = calculatePensionAmounts(sumAssured, pensionType, productCode)

  const benefitValuesBumnanReadyR60F = [
    [
      {
        text: MESSAGES.BENEFIT_PENSION_AGE_60_70,
        alignment: 'center',
      },
      {
        text: Mustache.render(MESSAGES.PENSION_PERCENT, {
          pensionPercent: pensionPercents !== null ? pensionPercents.R60F_AGE_60_TO_70 : 0,
        }),
        alignment: 'center',
      },
      {
        text: formatNumber(pensionAmounts !== null ? pensionAmounts.R60F_AGE_60_TO_70 : 0, 2),
        alignment: 'center',
      },
    ],
    [
      {
        text: MESSAGES.BENEFIT_PENSION_AGE_71_80,
        alignment: 'center',
      },
      {
        text: Mustache.render(MESSAGES.PENSION_PERCENT, {
          pensionPercent: pensionPercents !== null ? pensionPercents.R60F_AGE_71_TO_80 : 0,
        }),
        alignment: 'center',
      },
      {
        text: formatNumber(pensionAmounts !== null ? pensionAmounts.R60F_AGE_71_TO_80 : 0, 2),
        alignment: 'center',
      },
    ],
    [
      {
        text: MESSAGES.BENEFIT_PENSION_AGE_81_88,
        alignment: 'center',
      },
      {
        text: Mustache.render(MESSAGES.PENSION_PERCENT, {
          pensionPercent: pensionPercents !== null ? pensionPercents.R60F_AGE_81_TO_88 : 0,
        }),
        alignment: 'center',
      },
      {
        text: formatNumber(pensionAmounts !== null ? pensionAmounts.R60F_AGE_81_TO_88 : 0, 2),
        alignment: 'center',
      },
    ],
  ]

  const benefitValuesBumnanReadyR08F = [
    [
      {
        text: MESSAGES.BENEFIT_PENSION_AGE_60_88,
        alignment: 'center',
      },
      {
        text: Mustache.render(MESSAGES.PENSION_PERCENT, {
          pensionPercent: getPensionPercent(pensionType, productCode),
        }),
        alignment: 'center',
      },
      {
        text: formatNumber(calculatePensionAmount(sumAssured, pensionType, productCode), 2),
        alignment: 'center',
      },
    ],
  ]

  const benefitValues =
    productCode === VALUES.BUMNAN_READY_CODE.BUMNAN_READY_8
      ? benefitValuesBumnanReadyR08F
      : benefitValuesBumnanReadyR60F
  benefitValues.push(buildFirstTotalBenefitSummary(sumAssured, pensionType, productCode))
  return benefitValues
}

const buildSecondBenefitSummary = (sumAssured: number, pensionType: string, productCode: string) => {
  const minimumDeathCoverage = calculateMinimumDeathCoverage(sumAssured, productCode)
  return [
    [
      {
        text: MESSAGES.BUMNAN_READY_DEATH_BENEFIT_1,
        alignment: 'left',
      },
      {
        text: '.',
        alignment: 'left',
      },
    ],
    [
      {
        text: MESSAGES.BUMNAN_READY_DEATH_BENEFIT_1_1,
        alignment: 'left',
        border: [true, false, true, false],
      },
      {
        text: Mustache.render(MESSAGES.MINIMUN_DEATH_COVERAGE, {
          minimunDeathCoverage: formatNumber(minimumDeathCoverage.beforeFiveYearInsurance, 2),
        }),
        alignment: 'center',
        border: [true, false, true, false],
      },
    ],
    [
      {
        text: MESSAGES.BUMNAN_READY_DEATH_BENEFIT_1_2,
        alignment: 'left',
        border: [true, false, true, false],
      },
      {
        text: Mustache.render(MESSAGES.MINIMUN_DEATH_COVERAGE, {
          minimunDeathCoverage: formatNumber(minimumDeathCoverage.afterFiveYearInsurance, 2),
        }),
        alignment: 'center',
        border: [true, false, true, false],
      },
    ],
    [
      {
        text: MESSAGES.BUMNAN_READY_DEATH_BENEFIT_1_DISCLAIMER,
        alignment: 'left',
        border: [true, false, true, true],
      },
      {
        text: '',
        alignment: 'center',
        border: [true, false, true, true],
      },
    ],
    [
      {
        text: MESSAGES.BUMNAN_READY_DEATH_BENEFIT_2,
        alignment: 'left',
      },
      {
        text: MESSAGES.BUMNAN_READY_DEATH_COVERAGE_AGE_60_74,
        alignment: 'center',
      },
    ],
    [
      {
        text: MESSAGES.BUMNAN_READY_DEATH_BENEFIT_3,
        alignment: 'left',
      },
      {
        text: MESSAGES.BUMNAN_READY_DEATH_COVERAGE_AGE_AFTER_75_PDF,
        alignment: 'center',
      },
    ],
  ]
}

type Props = {
  benefitSummary: BenefitSummary,
  pensionType: string,
  sumAssured: number,
  productCode: string,
  age: number,
}

export const benefitSummarySection = ({ sumAssured, pensionType, basicPlan }: Props) => ({
  stack: [
    {
      text: firstBenefitSummaryTable(pensionType).title,
      style: 'title',
      pageBreak: 'before',
    },
    {
      text: MESSAGES.PENSION_AGE,
      bold: true,
    },
    {
      table: {
        headerRows: 1,
        widths: ['*', '*', '*'],
        margin: [100, 10, 10],
        body: [
          // $FlowFixMe
          buildHeaderTable(firstBenefitSummaryTable(pensionType).headers),
          ...buildFirstBenefitSummary(sumAssured, pensionType, basicPlan.basicPlanCode),
        ],
      },
      layout: 'lightBorder',
      style: 'table',
    },
    {
      text: MESSAGES.RETIREMENT_DEATH_BENEFIT,
      bold: true,
    },
    {
      table: {
        headerRows: 1,
        widths: ['*', '*'],
        margin: [100, 10],
        body: [...buildSecondBenefitSummary(sumAssured, pensionType, basicPlan.basicPlanCode)],
      },
      layout: 'lightBorder',
      style: 'table',
      pageBreak: 'after',
    },
  ],
  style: 'groupRow',
})
