// @flow
import { compact } from 'lodash/fp'
import _ from 'lodash'
import BIMESSAGES from 'core/data-model/constants/bi-messages'
import MESSAGES from 'core/data-model/constants/bi-messages'

import LegalDisclaimerMarketConduct from 'quick-quote/product-common/benefit-illustration/components/legal-disclaimer-market-conduct'
import ProfileSnapshot from 'quick-quote/product-common/benefit-illustration/components/profile-summary/profile-snapshot'
import ProductBenefits from 'quick-quote/product-whole-life/benefit-illustration/components/product-benefits/product-benefits'
import RiderBenefits from 'quick-quote/product-common/benefit-illustration/components/rider-benefits'
import PolicyValueTable from 'quick-quote/product-whole-life/benefit-illustration/components/policy-value/policy-value-table'
import BenefitSummaryTable from 'quick-quote/product-whole-life/benefit-illustration/components/benefit-summary-table'

import { getProfileSnapshot } from 'quick-quote/product-common/benefit-illustration/components/profile-summary/selectors'
import {
  getLegalDisclaimerEndDate,
  getTaxDeduction,
} from 'quick-quote/product-whole-life/benefit-illustration/selectors'
import { getcoveragePlanStartDate, getSelectedRiders } from 'quick-quote/product-common/coverage-plan/selectors'
import { getProductBenefits } from 'quick-quote/product-whole-life/benefit-illustration/components/product-benefits/selectors'
import { getPolicyValueTableBenefits } from 'quick-quote/product-whole-life/benefit-illustration/components/policy-value/selectors'
import TaxConsentDeductionBenefit from 'quick-quote/product-common/benefit-illustration/components/tax-consent-deduction-benefit'
import { isS7Product } from 'core/service/basic-plan/benefit'
import { getSelectedDisplayProduct } from 'quick-quote/product-selection/selectors'
import values from 'core/data-model/constants/values'
import type { AppState } from 'quick-quote/reducers'

const _summaryHeaderWithCode = ({ displayProduct, riders }: *) => {
  const name = _.get(displayProduct, 'displayName', '')
  const riderPlanCode = _.get(displayProduct, 'basicPlanCode', '')

  if (_.get(displayProduct, 'basicPlanCode') === '25PL') {
    return name
  }

  return `${name} (${riderPlanCode})`
}

const _summaryHeader = ({ displayProduct }) => {
  if (isS7Product(_.get(displayProduct, 'basicPlanCode'))) {
    return MESSAGES.CONTRACTS + _.get(displayProduct, 'nameThai', '')
  }
  if (values.CANCERX5_CAMPAIGN_CODE.includes(_.get(displayProduct, 'code'))) {
    return MESSAGES.LIFE_READY_PACKAGE_NAME
  }
  return displayProduct ? displayProduct.displayName : ''
}

export const getSections = (state: AppState) => {
  const riders = getSelectedRiders(state)
  return compact([
    {
      component: ProfileSnapshot,
      props: {
        ...getProfileSnapshot(state),
        profileSummaryHeader: _summaryHeaderWithCode,
        summaryTableHeader: _summaryHeader,
      },
    },
    {
      component: ProductBenefits,
      props: {
        ...getProductBenefits(state),
      },
    },
    riders.length > 0 && {
      component: RiderBenefits,
    },
    {
      component: PolicyValueTable,
      props: {
        ...getPolicyValueTableBenefits(state),
      },
    },
    {
      component: BenefitSummaryTable,
    },
    {
      component: TaxConsentDeductionBenefit,
      props: {
        taxDeduction: getTaxDeduction(state),
      },
    },
    {
      component: LegalDisclaimerMarketConduct,
      props: {
        startDate: getcoveragePlanStartDate(state),
        endDate: getLegalDisclaimerEndDate(state),
      },
    },
  ])
}

export const getMenus = (state: AppState) => {
  const selectedDisplayProduct = getSelectedDisplayProduct(state)
  return compact([
    {
      text: selectedDisplayProduct?.riders?.length
        ? BIMESSAGES.SUMMARY_OF_MAIN_AND_ADDITIONAL_CONTRACTS
        : BIMESSAGES.SUMMARY_OF_MAIN_CONTRACTS,
      id: 'profile-summary',
      isSub: false,
    },
    {
      text: 'รายละเอียดของสัญญาหลัก',
      id: 'product-benefits',
      isSub: false,
    },
    {
      text: BIMESSAGES.POLICY_VALUE_TABLE,
      id: 'policy-value',
      isSub: false,
    },
    {
      text: BIMESSAGES.BENEFIT_SUMMARY,
      id: 'benefit-summary',
      isSub: false,
    },
    {
      text: BIMESSAGES.LEGAL_DISCLAIMER,
      id: 'legal-disclaimer-market-conduct',
      isSub: false,
    },
  ])
}
