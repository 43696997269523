//@flow
import type { SelectProduct } from 'quick-quote/product-selection/actions'
import { SELECT_PRODUCT } from 'quick-quote/product-selection/actions'
import type { AppState } from 'quick-quote/reducers'
import { RESET_QUOTE_FORM_AFTER_MATURITY_VALIDATE } from 'quick-quote/product-s7cc/actions'
import { RESET_APPLICATION_STATE, RESET_QUOTE_FORM } from 'quick-quote/actions'

import { validateTitle } from 'core/service/insured'
import { getGender, getAge } from 'quick-quote/insured-information/selectors'
import { isKid } from 'core/service/insured/age'
import VALUES from 'core/data-model/constants/values'
import title from 'e-submission/mocks/title.json'

export type State = {
  name: { value: string, text: string },
  defaultValue?: string,
  errors: string[],
}

const defaultState: State = {
  name: '',
  defaultValue: '',
  errors: [],
}

type EditTitleAction = {
  type: string,
  payload: {
    value: string,
    text: string,
  },
}

type Action = EditTitleAction | SelectProduct

const redux = (validateTitle: (string) => string[]) => (namespace: string, initialState?: State) => {
  const finalInitialState = initialState || defaultState

  // actions
  const EDIT_NAME = `${namespace}/EDIT_NAME`
  const editTitle = (payload: { value: string, text: string }): EditTitleAction => ({
    type: EDIT_NAME,
    payload,
  })
  const CLEAR_NAME = `${namespace}/CLEAR_NAME`

  // reducer
  const reducer = (state: State = finalInitialState, action: Action): State => {
    switch (action.type) {
      case SELECT_PRODUCT:
        return state
      case CLEAR_NAME:
      case RESET_QUOTE_FORM:
      case RESET_QUOTE_FORM_AFTER_MATURITY_VALIDATE:
      case RESET_APPLICATION_STATE:
        return { ...state, name: '', errors: [] }
      case EDIT_NAME:
        let inputPayload =
          action.payload.length > VALUES.TITLE_MAX_LENGTH
            ? action.payload.substring(0, VALUES.TITLE_MAX_LENGTH)
            : action.payload
        return {
          ...state,
          name: inputPayload,
          errors: validateTitle(inputPayload),
        }
    }

    return state
  }
  // selectors
  const getTitle = (state?: State) => (state ? state.name : '')
  const getErrors = (state?: State) => (state ? state.errors : '')
  const getOptions = (state: AppState) => {
    if (!state.insured) return
    const gender = getGender(state)
    const age = getAge(state)
    const options = isKid(age) ? title.kid : title.default
    return options.filter((opt) => !opt.gender || opt.gender === gender)
  }
  const getDefaultValue = (state: AppState) => {
    return { value: '', text: '' }
  }

  return {
    initialState: finalInitialState,
    actions: { EDIT_NAME },
    actionCreators: { editTitle },
    reducer,
    selectors: { getTitle, getDefaultValue, getErrors, getOptions },
  }
}

export default redux

export const TitleRedux = redux(validateTitle)('TITLE')
