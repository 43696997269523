import { compact, get } from 'lodash/fp'
import { createField, createSectionCreator } from 'e-submission/domain/data-model/form/utils'
import { ECBR_PAYER_DOCTYPE, ECBR_PAYER_TYPE } from 'e-submission/domain/data-model/constants'
import { getRelationshipListForEcbr } from 'e-submission/apps/selectors'

const isSelectOtherRelationship = (selectedValue = '') => {
  return ['OTHERS'].includes(selectedValue)
}

export const getRelationshipInfoMessage = () => (
  <p>
    ในกรณีที่ผู้เอาประกันเลือกชำระค่าเบี้ยด้วยบุคคลอื่นที่มีความเกี่ยวข้อง จะต้องแนบ
    <strong> เอกสารพิสูจน์ความสัมพันธ์ผู้ชำระเบี้ย</strong> อาทิเช่น
    สามีต้องการชำระเบี้ยให้กับผู้เอาประกันจะต้องแนบเอกสารทะเบียนสมรสหรือเอกสารพิสูจน์ความสัมพันธ์อื่นๆด้วยทุกครั้ง
    หากไม่แนบเอกสารตัวแทนจะไม่สามารถส่งใบคำขอเอาประกันในขั้นตอนต่อไปได้
  </p>
)

export const PayerFormSection = (app) => {
  const id = 'payerInfo'
  const payerType = get(`${id}.payerType`, app)
  const isSelectedOtherPayer = payerType === ECBR_PAYER_TYPE.OTHER
  const isOtherRelationship = isSelectOtherRelationship(get(`${id}.relations.value`, app))

  const idTypeCode = get(`${id}.idType.value`, app)
  const isCiticenIdTypeCode = idTypeCode === 'CITIZEN_ID'
  const isPassportIdTypeCode = idTypeCode === 'PASSPORT'

  return compact([
    createField(`${id}.firstName`, [
      {
        label: 'ชื่อของผู้ชำระเบี้ย',
        id: `${id}.firstName`,
        c: 'TextInput',
        required: isSelectedOtherPayer,
        p: {},
      },
    ]),
    createField(
      `${id}.lastName`,
      [
        {
          label: 'นามสกุลของผู้ชำระเบี้ย',
          id: `${id}.lastName`,
          c: 'TextInput',
          required: isSelectedOtherPayer,
          p: {},
        },
      ],
      {
        mt: 15,
      }
    ),
    createField(
      `${id}.idType`,
      [
        {
          label: 'เอกสารที่ใช้แสดง',
          id: `${id}.idType`,
          c: 'Dropdown',
          required: isSelectedOtherPayer,
          p: {
            mergeState: (state) => {
              return {
                options: ECBR_PAYER_DOCTYPE,
              }
            },
          },
        },
      ],
      {
        mt: 15,
      }
    ),
    isCiticenIdTypeCode &&
      createField(
        `${id}.idNo.citizenId`,
        [
          {
            label: 'เลขประจำตัวประชาชน',
            id: `${id}.idNo.citizenId`,
            c: 'NumberInput',
            required: isSelectedOtherPayer && isCiticenIdTypeCode,
            p: {},
          },
        ],
        {
          mt: 15,
        }
      ),
    isPassportIdTypeCode &&
      createField(
        `${id}.idNo.passport`,
        [
          {
            label: 'หมายเลขหนังสือเดินทาง',
            id: `${id}.idNo.passport`,
            c: 'TextInput',
            required: isSelectedOtherPayer && isPassportIdTypeCode,
            p: {},
          },
        ],
        {
          mt: 15,
        }
      ),
    createField(
      `${id}.relations`,
      [
        {
          label: `ความสัมพันธ์`,
          id: `${id}.relations`,
          c: 'Dropdown',
          required: isSelectedOtherPayer,
          p: {
            placeHolder: 'กรุณาเลือกความสัมพันธ์',
            mergeState: (state) => {
              return {
                options: getRelationshipListForEcbr(state),
              }
            },
          },
        },
      ],
      {
        mt: 15,
      }
    ),
    isOtherRelationship &&
      createField(`${id}.relations.others`, [
        {
          label: '',
          id: `${id}.relations.others`,
          c: 'TextInput',
          placeHolder: 'โปรดระบุความสัมพันธ์',
          required: isSelectedOtherPayer && isOtherRelationship,
          p: {},
        },
      ]),
    createField(`${id}.relationInfoMessage`, [
      {
        id: `${id}.relationInfoMessage`,
        c: 'InfoMessage',
        p: {
          mergeState: () => ({
            value: getRelationshipInfoMessage(),
          }),
        },
      },
    ]),
  ])
}

export const PayerSection = createSectionCreator((app, section) => {
  return PayerFormSection(app, section)
})
