//@flow

import type { FundAllocationToggleItem } from 'core/data-model/investment'
import { map, pick } from 'lodash/fp'
import { hasFirstYearLstu } from 'quick-quote/product-investment/coverage-plan/selectors'
import VALUES from 'core/data-model/constants/values'
import type { FundAllocationState } from 'quick-quote/product-investment/fund-allocation/rpp/reducers'
import type { AppState } from 'quick-quote/reducers'

type FundAllocationInterface = {
  code: string,
  englishName: string,
  thaiName: string,
  fundAllocation: number,
  factSheet: string,
  isOverRisk: boolean,
  isForeign: boolean,
}

type FundInterface = {
  allocations: FundAllocationInterface[],
}

export type Funds = {
  rpp: FundInterface,
  lstu?: FundInterface,
}

const mapFundAllocations = (fundAllocations: FundAllocationToggleItem[]): FundAllocationInterface[] => {
  return map(pick(['code', 'englishName', 'thaiName', 'fundAllocation', 'factSheet', 'isForeign', 'isOverRisk']))(
    fundAllocations
  )
}

const mapFund = (fund: FundAllocationState): FundInterface => ({
  allocations: mapFundAllocations(fund.allocations),
})

const mapLstu = (state: AppState): FundInterface => {
  return hasFirstYearLstu(state)
    ? {
        lstu: {
          ...(state.investment.funds.lstu.selectedAllocationOption === VALUES.LSTU_FUND_ALLOCATION_OPTION.INDEPENDENT
            ? mapFund(state.investment.funds.lstu)
            : {}),
          selectedAllocationOption: state.investment.funds.lstu.selectedAllocationOption,
        },
      }
    : {}
}

export const mapFunds = (state: AppState): Funds => ({
  rpp: mapFund(state.investment.funds.rpp),
  ...mapLstu(state),
})
