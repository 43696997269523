// @flow
import type { RiderExceptionNote } from 'core/data-model/rider/benefits'
import BENEFIT from 'core/data-model/constants/bi-rider'

export const getApEcareExceptionNotes = (riderCodes: string[]): RiderExceptionNote => {
  let noted = { ...BENEFIT.EXCEPTION_NOTED_AP_ECARE }
  noted.condition = noted.condition + (riderCodes.length === 1 ? riderCodes[0] : BENEFIT.RIDER_AP_ECARE_NAME)
  return noted
}

export const getHICExceptionNotes = (): RiderExceptionNote => {
  return BENEFIT.EXCEPTION_NOTED_HIC
}

export const getCPRExceptionNotes = (): RiderExceptionNote => {
  return BENEFIT.EXCEPTION_NOTED_CPR
}

export const getMEAPlusExceptionNotes = (): RiderExceptionNote => {
  return BENEFIT.EXCEPTION_NOTED_MEAPLUS
}

export const getMEXExceptionNotes = (): RiderExceptionNote => {
  return BENEFIT.EXCEPTION_NOTED_MEX
}

export const getMEHExceptionNotes = (): RiderExceptionNote => {
  return BENEFIT.EXCEPTION_NOTED_MEH
}

export const getFHExceptionNotes = (): RiderExceptionNote => {
  return BENEFIT.EXCEPTION_NOTED_FH
}

export const getMEBExceptionNotes = (): RiderExceptionNote => {
  return BENEFIT.EXCEPTION_NOTED_MEB
}

export const getMEBExceptionNotes17 = (): RiderExceptionNote => {
  return BENEFIT.EXCEPTION_NOTED_MEB_17
}

export const getMEKExceptionNotes = (): RiderExceptionNote => {
  return BENEFIT.EXCEPTION_NOTED_MEK
}

export const getMHPExceptionNotes = (): RiderExceptionNote => {
  return BENEFIT.EXCEPTION_NOTED_MHP
}

export const getMEHExceptionNotes32 = (): RiderExceptionNote => {
  return BENEFIT.EXCEPTION_NOTED_MEH_32
}

export const getESCIExceptionNotes = (): RiderExceptionNote => {
  return BENEFIT.EXCEPTION_NOTED_ESCI
}

export const getWPEExceptionNotes = (): RiderExceptionNote => {
  return BENEFIT.EXCEPTION_NOTED_WPE
}

export const getDCIExceptionNotes = (): RiderExceptionNote => {
  return BENEFIT.EXCEPTION_NOTED_DCI
}

export const getPBPDDExceptionNotes = (): RiderExceptionNote => {
  return BENEFIT.EXCEPTION_NOTED_PBPDD_PBSDD
}

export const getPBSDDExceptionNotes = (): RiderExceptionNote => {
  return BENEFIT.EXCEPTION_NOTED_PBPDD_PBSDD
}

export const getPBPDDCIExceptionNotes = (): RiderExceptionNote => {
  return BENEFIT.EXCEPTION_NOTED_PBPDDCI_PBSDDCI
}

export const getPBSDDCIExceptionNotes = (): RiderExceptionNote => {
  return BENEFIT.EXCEPTION_NOTED_PBPDDCI_PBSDDCI
}
