import { useState } from 'react'
import _ from 'lodash'

import { CustomerProfile, CustomDialog } from 'quick-quote/my-wealth-plus/components'
import {
  getInvestmentRiskDetail,
  formatMwpDetail,
  sortFundListByAlowcationAndName,
} from 'quick-quote/my-wealth-plus/utils'
import { PREFILL_OPTIONS, INIT_PREFILL_OPTIONS } from 'quick-quote/my-wealth-plus/constant'

const InvestmentDetail = ({ fundCode, allocation }) => {
  return (
    <div className="fund-item">
      <strong>{fundCode}</strong>
      <span>{allocation}%</span>
    </div>
  )
}

const InvestmentList = ({ rpqScore, fundList }) => {
  const { title, description } = getInvestmentRiskDetail(rpqScore)
  const sortedFundList = sortFundListByAlowcationAndName(fundList)
  const newFundList = sortedFundList.length % 3 == 2 ? sortedFundList.concat({}) : sortedFundList

  return (
    <>
      <div className="risk-detail section">
        <strong>{title}</strong>
        <span>{description}</span>
      </div>
      <div className="fund-list section">
        <strong className="fund-list-title">พอร์ตและสัดส่วนการลงทุน 100%</strong>
        {newFundList.map((item, index) => {
          if (_.isEmpty(item)) {
            return <div className="fund-item" />
          }

          const { fundCode, allocation } = item

          return <InvestmentDetail key={index} fundCode={fundCode} allocation={allocation} />
        })}
      </div>
    </>
  )
}

const SelectPrefillSection = ({ productCode, selectedValue, onSelect }) => {
  const isChecked = (optValue) => _.includes(selectedValue, optValue)
  const handleClick = (optValue) => {
    const newSelectedOptions = _.xor(selectedValue || [], [optValue])

    onSelect(newSelectedOptions)
  }

  return (
    <div className="form-group form-group--radio">
      <div className="select-title">
        <strong>กรุณาเลือกข้อมูลที่ท่านต้องการ</strong>
      </div>
      {PREFILL_OPTIONS.filter((option) => {
        return _.includes(option.products, productCode)
      }).map((option, index) => {
        const { text, value } = option
        return (
          <label
            className="custom-control custom-checkbox"
            key={`mwp-prefill-${index}`}
            htmlFor={`mwp-prefill-${value}`}
            onClick={(e) => {
              handleClick(value)
            }}
          >
            <input
              className="custom-control-input"
              type="checkbox"
              checked={isChecked(value)}
              id={`mwp-prefill-${value}`}
              onClick={(e) => {
                e.stopPropagation()
              }}
            />
            <span className="custom-control-indicator" />
            <span>{text}</span>
          </label>
        )
      })}
      <div className="prefill-remark">
        <strong>หมายเหตุ : </strong>
        <span>ข้อมูลชื่อ-สกุล จะไม่ปรากฎในการจำลองข้อมูลการเงินและการลงทุน และใช้เพื่อการจำลองแผนเท่านั้น</span>
      </div>
    </div>
  )
}

const MwpDetailModal = ({ data, onConfirm, isOpen, toggle }) => {
  const formattedData = formatMwpDetail(data)
  const { profileName, coverage, productCode, fundList, rpq, createdAt } = formattedData
  const [selectedPrefill, setSelectedPrefill] = useState(INIT_PREFILL_OPTIONS)

  return (
    <CustomDialog
      isOpen={isOpen}
      message={{
        title: 'ข้อมูลลูกค้า',
      }}
      content={
        <div className="mwp-modal-detail-content">
          <CustomerProfile name={profileName} coverage={coverage} product={productCode} date={createdAt} />
          <InvestmentList fundList={fundList} rpqScore={rpq.rpqScore} />
          <SelectPrefillSection
            productCode={productCode}
            selectedValue={selectedPrefill}
            onSelect={(newSelected) => setSelectedPrefill(newSelected)}
          />
        </div>
      }
      onConfirm={() => onConfirm(selectedPrefill)}
      toggle={toggle}
    />
  )
}

export default MwpDetailModal
