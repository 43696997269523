//@flow
import React from 'react'
import _ from 'lodash'
import { FormFeedback } from 'reactstrap'

import MESSAGES from 'core/data-model/constants/messages'
import { FundAllocationBar } from 'quick-quote/product-investment/fund-allocation/components/allocation-bar'
import { Link } from 'common-components'
import PATH from 'quick-quote/constants/path'
import { FundList } from 'quick-quote/product-investment/fund-allocation/components/fund-list'

export const FundDisclaimer = () => {
  return (
    <div className="disclaimer-group">
      <div className="disclaimer">{MESSAGES.FUNDS_ALLOCATION['DISCLAIMER_MESSAGE']}</div>
    </div>
  )
}

export class FundCategories extends React.Component<*> {
  render() {
    const {
      funds,
      allocations,
      totalAllocation,
      toggleFundAllocation,
      calculateTotalFundAllocation,
      resetFundAllocations,
      allowHigherRiskInvestment,
      allowForeignInvestment,
      isLowRiskEvaluated,
      hasValidEntryComponent,
      elementIdToSnapAllocationBar,
    } = this.props

    const addAllocationDetails = (fund) => {
      let fundAllocation = _.find(allocations, { code: fund.code })
      return { ...fund, ...fundAllocation }
    }
    const fundsWithAllocation = _.map(funds, addAllocationDetails)

    const fundGroups = _(fundsWithAllocation)
      .groupBy('fundType.category.thaiName')
      .map((funds) => {
        return {
          funds: funds,
          categoryName: funds[0].fundType.category.thaiName,
          categoryOrder: funds[0].fundType.category.categoryOrder,
        }
      })
      .sortBy('categoryOrder')
      .value()

    const selectedFunds = _.filter(fundsWithAllocation, 'selected')

    return (
      <div>
        <div className="fund-allocation-overview form-group">
          {_(allocations)
            .filter('selected')
            .isEmpty() && <span>กรุณาเลือกกองทุน</span>}
          <FundAllocationBar
            selectedFunds={selectedFunds}
            totalAllocation={totalAllocation}
            resetFundAllocations={resetFundAllocations}
            stickedToElementId={elementIdToSnapAllocationBar}
          />
          <div className="allocation-instruction">
            {!_.isEmpty(totalAllocation.errors) && (
              <div>
                <div className="current-allocation">
                  <span>
                    {MESSAGES.FUNDS_ALLOCATION['INVESTMENT_ALLOCATION_MESSAGE']}{' '}
                    <strong>{totalAllocation.value}%</strong>
                  </span>
                </div>
                <div>
                  {_.map(totalAllocation.errors, (error, index) => (
                    <p key={`${index}-allocation-error`}>{error}</p>
                  ))}
                </div>
              </div>
            )}
          </div>
          <FundDisclaimer />
          <FormFeedback className="info-message attached-icon" id="agent-declaration-investment-info-message">
            <p>{MESSAGES.AGENT_DECLARATION_INVESTMENT_INFO_MESSAGE}</p>
          </FormFeedback>
          {!allowHigherRiskInvestment && isLowRiskEvaluated && !hasValidEntryComponent ? (
            <FormFeedback className="info-message attached-icon" id="higher-risk-investment-info-message">
              <p>
                {MESSAGES.HIGHER_RISK_INVESTMENT_INFO_MESSAGE}
                <Link to={PATH.INVESTMENT.RISK_QUESTIONNAIRE}>{MESSAGES.HERE}</Link>
              </p>
            </FormFeedback>
          ) : null}
          {!allowForeignInvestment && !hasValidEntryComponent ? (
            <FormFeedback className="info-message attached-icon" id="foreign-investment-info-message">
              <p>
                {MESSAGES.FOREIGN_INVESTMENT_INFO_MESSAGE}
                <Link to={PATH.INVESTMENT.RISK_QUESTIONNAIRE}>{MESSAGES.HERE}</Link>
              </p>
            </FormFeedback>
          ) : null}
        </div>
        <FundList
          allFunds={fundGroups}
          toggleFund={toggleFundAllocation}
          updateFundPercent={calculateTotalFundAllocation}
        />
      </div>
    )
  }
}
