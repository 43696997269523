// @flow
import type { Age } from 'core/data-model/insured'
import type { Period } from 'core/data-model/basic-plan'
import { calculatePeriod } from 'core/service/basic-plan'
import { queryPremiumRateForPBPDDCI as queryPremiumRate } from 'core/service/rider/query-premium-rate'
import { calculateRiderPremiumForPBSDDCI as calculateRiderPremium } from 'core/service/rider/premium-calculation'

const riderRules = {
  maximumPayerAge: {
    value: 70,
    unit: 'year',
  },
  insuredAgeCoverage: 25,
  payerAgeCoverage: 80,
}

const getYearsOfCoverage = (basicPaymentPeriod: Period, insuredAge: Age, payerAge: Age) => {
  const insuredAgeValue = insuredAge.unit !== 'year' ? 0 : insuredAge.value
  const payerAgeValue = payerAge.unit !== 'year' ? 0 : payerAge.value
  return Math.min(
    calculatePeriod(basicPaymentPeriod, insuredAgeValue),
    riderRules.payerAgeCoverage - payerAgeValue,
    riderRules.insuredAgeCoverage - insuredAgeValue
  )
}

const calculateMaximumPayerAge = (): Age => {
  return riderRules.maximumPayerAge
}

export default {
  calculateRiderPremium,
  queryPremiumRate,
  getYearsOfCoverage,
  calculateMaximumPayerAge,
}
