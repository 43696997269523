// @flow

import type { SelectFSE, UpdateOffice } from 'identity/actions'
import {
  GET_DISTRIBUTOR_GROUPS_SUCCESS,
  SELECT_FSE,
  selectFSE,
  sessionTerminated,
  setDistributorGroup,
  silentRenewError,
  updateFSE,
  updateUserImage,
  USER_FOUND,
  userExpired,
  userExpiring,
  userFound,
  userSignedOut,
} from 'identity/actions'

import * as identityService from 'identity/service'
import { getDistributorGroupForUser } from 'core/service/distributor'

import type { User } from 'oidc-client'
import type { AdvisorApiUserInfo, FSE, Office, User as AppUser } from 'core/data-model/identity'
import { getAdvisor } from 'e-submission/domain/service'
import type { DistributorGroup } from 'core/data-model/distributor'
import { getDistributorGroups, getIdentityProfileImage, getIdentityUser } from 'identity/selectors'
import { getToggles } from 'quick-quote/feature-toggles'
import messages from 'quick-quote/constants/messages'
import { setNotificationMessage } from 'quick-quote/notification/actions'
// import { logoutFromIDP } from 'identity/service'

import _ from 'lodash'
import type { Effect } from 'redux-saga/effects'
import { apply, call, put, select, spawn, take, takeEvery, takeLatest } from 'redux-saga/effects'
import { isNil, isNotNil } from 'core/service/lib/type-check'
import { UPDATE_USER, UPDATE_ADDITIONAL_USER_DETAILS, UPDATE_BANK_BRANCH } from './actions'

export function* onUpdateUser(userInfoResponse: *): Generator<*, *, *> {
  const { payload } = userInfoResponse
  const error = _.get(payload, 'profile.error', undefined)
  if (error === messages.LOGIN_LIMIT_SESSION_EXCEEDED) {
    yield put(setNotificationMessage({ type: 'LoginLimitSessionExceeded' }))
    // yield put(logoutFromIDP())
  }
}

// TODO: test if image fetch works after flow change
export function* onGetUserImage(identityService: *, action: *): Generator<*, *, *> {
  const userImage = yield select(getIdentityProfileImage)
  if (isNotNil(userImage)) {
    return
  }

  if (isNil(action)) {
    return
  }

  const user = action.payload
  if (user.profile.provider === 'facebook') {
    const { access_token: fbAccessToken } = yield call(identityService.getFBToken, user.access_token, user.token_type)
    const fbResult = yield call(identityService.getFBUserImage, fbAccessToken)
    yield put(updateUserImage(fbResult.picture.data.url))
  }
}

export function* subscribeUserManagerEvent(userManager: *, store: *): Generator<*, *, Effect[]> {
  if (getToggles().ENABLE_AUTHORISATION_CODE_FLOW) {
    return
  }
  userManager.events.addUserLoaded((user: User) => {
    store.dispatch(userFound(user))
  })
  userManager.events.addSilentRenewError((error: Error) => {
    store.dispatch(silentRenewError(error.message))
  })
  userManager.events.addAccessTokenExpired(() => {
    store.dispatch(userExpired())
  })
  userManager.events.addAccessTokenExpiring(() => {
    store.dispatch(sessionTerminated())
  })
  userManager.events.addUserUnloaded(() => {
    store.dispatch(userExpiring())
  })
  userManager.events.addUserSignedOut(() => {
    store.dispatch(userSignedOut())
  })

  const user: User = yield apply(userManager, userManager.getUser)
  if (!user || user.expired) {
    yield put(userExpired())
  } else {
    yield put(userFound(user))
  }
}

export function* updateUserDistributorGroup(): Generator<*, *, *> {
  const user: AppUser = yield select(getIdentityUser)
  const distributorGroups: DistributorGroup[] = yield select(getDistributorGroups)
  if (_.isEmpty(distributorGroups)) {
    const { payload } = yield take(GET_DISTRIBUTOR_GROUPS_SUCCESS)
    const userDistributorGroup = yield call(getDistributorGroupForUser, payload, user)
    yield put(setDistributorGroup(userDistributorGroup))
  } else {
    const userDistributorGroup = yield call(getDistributorGroupForUser, distributorGroups, user)
    yield put(setDistributorGroup(userDistributorGroup))
  }
}

export function* onUpdateBankBranch(action: UpdateOffice): Generator<*, *, *> {
  if (!getToggles().ENABLE_PRE_POPULATE_FSE) {
    return
  }

  const selectedOffice: Office = action.payload
  if (_.get(selectedOffice, 'fseCode')) {
    const user: AppUser = yield select(getIdentityUser)
    const fse: AdvisorApiUserInfo = yield call(getAdvisor, user, selectedOffice.fseCode, 'fse')
    yield put(selectFSE(fse))
  } else {
    yield put(updateFSE(undefined))
  }
}

export function* onSelectFSE(action: SelectFSE): Generator<*, *, *> {
  const selectedFSE: AdvisorApiUserInfo = action.payload
  if (
    !_.isUndefined(selectedFSE) &&
    !_.isUndefined(selectedFSE.party) &&
    !_.isUndefined(selectedFSE.party.firstName) &&
    !_.isUndefined(selectedFSE.party.lastName)
  ) {
    const fse: FSE = {
      firstName: selectedFSE.party.firstName,
      lastName: selectedFSE.party.lastName,
      advisorCode: selectedFSE.advisorCode,
      advisorId: selectedFSE.advisorId,
      corporateEmail: _.get(
        _.find(selectedFSE.contactMethods.emailContact, (e) => e.type === 'corporate'),
        'url'
      ),
      personalEmail: _.get(
        _.find(selectedFSE.contactMethods.emailContact, (e) => e.type !== 'corporate'),
        'url'
      ),
    }
    yield put(updateFSE(fse))
  } else {
    yield put(updateFSE(undefined))
  }
}

export default function*(userManager: *, store: *): Generator<*, *, Effect[]> {
  yield [
    spawn(subscribeUserManagerEvent, userManager, store),
    takeLatest(UPDATE_USER, onUpdateUser),
    takeEvery(USER_FOUND, onGetUserImage, identityService),
    takeEvery([USER_FOUND], updateUserDistributorGroup),
    takeEvery([UPDATE_BANK_BRANCH], onUpdateBankBranch),
    takeEvery(UPDATE_ADDITIONAL_USER_DETAILS, updateUserDistributorGroup),
    takeLatest(SELECT_FSE, onSelectFSE),
  ]
}
