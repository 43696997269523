import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { connect } from 'react-redux'
import { withRouter, Redirect } from 'react-router-dom'
import { isQuickQuotePath } from 'quick-quote/components/navigations/utils'
import { getSelectedDisplayProductCode } from 'quick-quote/product-selection/selectors'
import { compose, branch, renderComponent, lifecycle } from 'recompose'
import { loginRequest } from 'quick-quote/actions'
import { setNotificationMessage } from 'quick-quote/notification/actions'
import { getToggles } from 'quick-quote/feature-toggles'
import { setSelectedNeedId, setFnaGapValue } from './actions'
import {
  getNeeds,
  getQuestions,
  getPlans,
  getSelectedPlanId,
  getPlanByPlanId,
  getSelectedNeedType,
  getNeedGapQuestion,
  getNeedGapTargetSavings,
} from './selectors'
import { isOnFnaPhase } from 'quick-quote/fna/selectors'
import { Form } from './components'
import { isLoggedIn } from 'identity/selectors'
import { getIsUserOnline } from 'quick-quote/selectors'

const mapStateToProps = (state) => {
  const needs = getNeeds(state)
  const plans = getPlans(state)
  const needGapQuestions = getNeedGapQuestion(state)
  const questions = _.mapValues(getQuestions(state), (q) => ({ ...q, value: needGapQuestions[q.id] }))
  const selectedNeedId = getSelectedNeedType(state)
  const selectedPlanId = getSelectedPlanId(state)
  const needPlan = getPlanByPlanId(plans, selectedPlanId)
  const targetSavings = getNeedGapTargetSavings(state)
  const onFnaPhase = isOnFnaPhase(state)
  const isUserLogin = isLoggedIn(state)
  const isUserOnline = getIsUserOnline(state)

  return {
    needs,
    questions,
    needPlan,
    selectedNeedId,
    selectedPlanId,
    targetSavings,
    onFnaPhase,
    selectedDisplayProductCode: getSelectedDisplayProductCode(state),
    isUserLogin,
    isUserOnline,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSelectedNeedId: bindActionCreators(setSelectedNeedId, dispatch),
    setQuestion: bindActionCreators(setFnaGapValue, dispatch),
    needLogin: bindActionCreators(loginRequest, dispatch),
    setNotification: bindActionCreators(setNotificationMessage, dispatch),
  }
}

export const NeedAndGapAnalysisContainer = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    UNSAFE_componentWillMount() {
      if (getToggles().ENABLE_FORCE_LOGIN_FIRST) {
        const { isUserLogin, isUserOnline, needLogin, setNotification } = this.props
        if (!isUserOnline) {
          setNotification({ type: 'Warning', subType: 'NoInternet' })
        } else if (!isUserLogin) {
          needLogin()
        }
      }
    },
  }),
  branch(
    (props) => {
      const pathname = props.location.pathname
      const isQQ = isQuickQuotePath(pathname)
      return isQQ && !props.selectedDisplayProductCode
    },
    renderComponent(() => <Redirect to="/" push />)
  )
)(Form)

export default NeedAndGapAnalysisContainer
