// @flow
import type { DisplayProduct } from 'core/service/display-product'
import { getLifeLegacyBenefitData, isLifeLegacyProduct } from 'core/service/basic-plan/benefit'

type LifeLegacyBenefitsProps = {
  displayProduct: DisplayProduct,
}

const LifeLegacyBenefits = ({ displayProduct }: LifeLegacyBenefitsProps) => {
  if (!isLifeLegacyProduct(displayProduct.basicPlanCode)) return null
  const benefitData = getLifeLegacyBenefitData()

  return (
    <div id="product-benefits-content">
      <p id="death-benefits">
        <b className="caption">{benefitData.deathBenefits}</b>
      </p>
      <p id="death-benefits-description" className="description">
        <span>{benefitData.deathBenefitsDescription} </span>
      </p>
      <p id="maturity-benefits">
        <b className="caption">{benefitData.maturityBenefits}</b>
      </p>
      <p id="maturity-benefits-description" className="description">
        <span> {benefitData.maturityBenefitsDescription} </span>
      </p>
    </div>
  )
}

export default LifeLegacyBenefits
