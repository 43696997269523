// @flow
import _ from 'lodash'
import { compose, compact } from 'lodash/fp'
import { round, formatNumber, roundDown } from 'core/service/lib/number-format'
import { d } from 'core/service/lib/decimal'
import { asYears } from 'core/service/insured/age'
import { dataWrapper } from 'core/data'
import { getRiskiestOccupationFactorForRiders } from 'core/service/insured'
import { queryCashValueRate, getSurrenderCashMRTA } from 'core/service/basic-plan/surrender-cash'
import { getSumAssuredMRTA, findSaRate } from 'core/service/basic-plan/sa'
import { isGLTSPGroup } from 'quick-quote/product-mrta/coverage-plan/selectors'
import {
  calculateCashDrop,
  findCashDropRate,
  queryCashDropRate,
  findNonGuaranteeCashDropRate,
} from 'core/service/basic-plan/cash-drop'
import VALUES from 'core/data-model/constants/values'

import {
  findRPURate,
  findPENRate,
  findETIRate,
  queryRPURate,
  queryPENRate,
  queryETIRate,
  calculateRPU,
  calculateETI,
} from 'core/service/basic-plan/RPU-ETI-PEN'
import { calculateTotalRiderPremium } from '../year-end-rider-premium'
import {
  getPresentRate,
  getPensionTotal,
  getPensionTotalBumnanReady,
  calculatePresentValue,
  calculatePensionAmount,
} from '../products/retirement'

import {
  getInitialAge,
  getAgeRangeWithinCoveragePeriod,
  calculateAnnualBasicPremium,
  calculateGuarenteeDividend,
  calculateInterestNonGuaranteeDividendDeposit,
  getSurrenderCash,
  calculateDeathBenefit,
  calculateMaturity,
  sumPolicyValuesByColumn,
} from './utils'
import Mustache from 'mustache'
import MESSAGES from '../../../data-model/constants/bi-messages'
import {
  isLifeProtectProduct,
  isWholeLife12PLProduct,
  isLifeEnsureProduct,
  isLifeLegacyProduct,
  isS7Product,
} from 'core/service/basic-plan/benefit'
import { getToggles } from 'quick-quote/feature-toggles'
import type { RiderBenefitTables } from 'core/service/rider/benefits'
import type Decimal from 'decimal.js'
import type { DisplayProductQuery, DisplayProduct } from 'core/service/display-product'
import { getBasicPlan, transformToDisplayProduct } from 'core/service/display-product'
import type { CashValueRates, Period, CashDropRates, PresentValue, SaRates } from 'core/data-model/basic-plan'
import type { Rider, RiderPremiumRate, RiderState } from 'core/data-model/rider'
import type { Age, Gender } from 'core/data-model/insured'
import type { State as LoanState } from 'quick-quote/product-loan/redux'

export type PolicyValue = {
  yearEndPolicyValues: YearEndPolicyValue[],
  subtotalAnnualBasicPremium: number,
  subtotalAnnualRiderPremium: number,
  grandTotalPremium: number,
  subtotalCashDrop?: number,
  subtotalLowSavingCashDrop?: number,
  subtotalMediumSavingCashDrop?: number,
  subtotalHighSavingCashDrop?: number,
  //12pl
  subtotalGuaranteeDividend?: number,
  subTotalNonGuaranteeDividendDeposit?: number,
  subTotalLowInterest?: number,
  subTotalLowInterestDeposit?: number,
  subTotalMidInterest?: number,
  subTotalMidInterestDeposit?: number,
  subTotalHighInterest?: number,
  subTotalHighInterestDeposit?: number,
  subTotalLowDividend?: number,
  subTotalMidDividend?: number,
  subTotalHighDividend?: number,
}

export type PolicyValueMRTA = {
  yearEndPolicyValues: YearEndPolicyValueMRTA[],
}

export type YearEndPolicyValueMRTA = {
  yearEndPolicy: number,
  basicSumAssured: number,
  riderSumAssured?: number,
  surrenderCash: number,
}

export type PolicyRetirementValue = PolicyValue & {
  subtotalPensionAmount: number,
}

export type YearEndPolicyValue = {
  age: Age,
  yearEndPolicy: number,
  cumulativeBasicPremium: number,
  annualBasicPremium: number,
  annualRiderPremium: number,
  annualTotalPremium: number,
  surrenderCash: number,
  deathBenefit: number,
  cumulativeDeathBenefit?: number,
  deathBenefitDisplay?: string,
  basicSumAssured: number,
  cashDrop?: number,
  cashDropValue?: string,
  sumCashDrop?: number,
  accidentDeathBenefit?: string,
  cashvalue?: number,
  monthEndPolicy?: number,
  pensionPerYear?: number,
  pensionTotal?: number,
  nonGuaranteeDividendDeposit?: number,
  guaranteeDividend?: number,
  nonGuaranteeDividend?: number,
  totalDividend?: number,
  totalBenefit?: number,
  nonGuaranteeCashDrop?: number,
  cumulativeGuaranteeCashDrop?: number,
  maturityAmount?: number,
  lowInterest?: number,
  lowInterestDeposit?: number,
  midInterest?: number,
  midInterestDeposit?: number,
  highInterest?: number,
  highInterestDeposit?: number,
  totalLowDividend?: number,
  totalMidDividend?: number,
  totalHighDividend?: number,
  ETIMaturity?: number,
  ETICashpaid?: number,
  ETIYear?: number,
  ETIDay?: number,
  RPUSumAssured?: number,
  RPUCashpaid?: number,
}

export type PolicyValueParams = {
  basicPlanQuery: DisplayProductQuery,
  age: Age,
  gender: Gender,
  sumAssured: number,
  basicPremium: number,
  modelFactorPeriod: number,
  validSelectedRiders: (Rider & RiderState)[],
  payerAge: Age,
  payerGender: Gender,
  modelFactor: number,
  natureOfDutyCodes: string[],
  riderBenefitTables?: ?RiderBenefitTables,
  pensionType?: string,
  payerNatureOfDutyCodes: string[],
  loan: LoanState,
  fullBasicPremium: number,
  fullAnnualBasicPremium: number,
}

export const _accumulateBasicPremium = (
  age: Age,
  yearEnd: number,
  paymentPeriod: Period,
  basicPremium: Decimal
): number => {
  const lastPaymentYear = (() => {
    switch (paymentPeriod.type) {
      case 'limited_by_duration':
        return paymentPeriod.value

      case 'limited_to_age':
        return paymentPeriod.value - asYears(age)

      case 'by_package':
        throw new Error('Basic plan payment period should never determined by package')

      default:
        throw new Error('This should never be thrown due to exhaustive checking')
    }
  })()

  if (yearEnd <= lastPaymentYear) {
    return basicPremium.times(yearEnd).toNumber()
  }

  return basicPremium.times(lastPaymentYear).toNumber()
}

export const getMaxValue = (policyValues: number[]): number => {
  let maxValue = -1
  for (let cmpValue of policyValues) {
    maxValue = maxValue > cmpValue ? maxValue : cmpValue
  }
  return maxValue
}

// Sorry to make this because keep in time to market.
// they want to reduce age -1 to display on policy value table only LifeSave+
// ** when they want to apply to reduce age to all product. should remove this function and
// focus on calculateTotalRiderPremiumAtYearEnd someone put login to reduce when push to calculated
// and remove + 1 at initialAge you can search in these files.
export const _reduceAgeToRender = (props: YearEndPolicyValue): YearEndPolicyValue => {
  const reduceAgeProps = { ...props }
  _.set(reduceAgeProps, 'age.value', _.get(reduceAgeProps, 'age.value', 0) - 1)
  return reduceAgeProps
}

const decimalPrecision = (basicPlanCode) => {
  if (basicPlanCode === VALUES.ISMART_80_6) {
    return 2
  }
  return 0
}

export const _generatePolicyValuesForLifeSavePro = (
  policyValueParams: PolicyValueParams,
  basicPlan: DisplayProduct,
  cashValueRates: CashValueRates[],
  riderPremiumRatesPayload: RiderPremiumRate[],
  occupationFactorForRider: {
    [string]: number,
  },
  cashDropRates?: CashDropRates[],
  payerOccupationFactorForRider: {
    [string]: number,
  }
): PolicyValue => {
  const {
    age,
    gender,
    sumAssured,
    basicPremium,
    modelFactorPeriod,
    validSelectedRiders,
    payerAge,
    payerGender,
    modelFactor,
    basicPlanQuery,
  } = policyValueParams

  const initialAge = age.unit === 'year' ? age.value : 0
  // const ageRangeWithinCoveragePeriod = _.range(initialAge + 1, basicPlan.coveragePeriod.value + initialAge + 1)
  const ageRangeWithinCoveragePeriod = getAgeRangeWithinCoveragePeriod(initialAge, basicPlan.coveragePeriod)
  const cashDropRate = findCashDropRate(basicPlan.code, cashDropRates)
  const generatePolicyValueRow = getGenerateYearEndPolicyValue({
    basicPlan,
    cashDropRate,
    basicPlanQuery,
  })

  const yearEndPolicyValues = ageRangeWithinCoveragePeriod.reduce((previousValue, yearEndAge) => {
    const yearEnd = yearEndAge - initialAge

    const annualBasicPremium = d(roundDown(d(basicPremium).times(d(modelFactorPeriod).toNumber()), 2))
    const cumulativeBasicPremium = _accumulateBasicPremium(age, yearEnd, basicPlan.paymentPeriod, d(annualBasicPremium))

    return [
      ...previousValue,
      generatePolicyValueRow({
        yearEnd,
        riders: validSelectedRiders,
        basicPlan,
        basicPremium,
        cumulativeBasicPremium,
        gender,
        yearEndAge: {
          value: yearEndAge,
          unit: 'year',
        },
        occupationFactorForRider,
        modelFactor,
        cashValueRates,
        cashDropRates,
        cashDropRate,
        basicSumAssured: sumAssured,
        modelFactorPeriod,
        riderPremiumRatesPayload,
        initialInsuredAge: {
          value: initialAge,
          unit: 'year',
        },
        payerAge,
        payerGender,
        previousYearEndValue: previousValue[previousValue.length - 1],
        payerOccupationFactorForRider,
      }),
    ]
  }, [])

  const sumBy = (policyValues: YearEndPolicyValue[], key: string): number => {
    return _.map(policyValues, key)
      .reduce((a, b) => a.add(b), d(0))
      .toNumber()
  }

  const subtotalAnnualRiderPremium = sumBy(yearEndPolicyValues, 'annualRiderPremium')
  const subtotalAnnualBasicPremium = sumBy(yearEndPolicyValues, 'annualBasicPremium')
  const lastYearEndPolicyValue = _.last(yearEndPolicyValues)
  const subtotalCashDrop = cashDropRate ? sumBy(yearEndPolicyValues, 'cashDrop') : 0

  return {
    yearEndPolicyValues,
    subtotalAnnualRiderPremium,
    subtotalAnnualBasicPremium,
    subtotalCashDrop,
    subtotalLowSavingCashDrop: round(
      _.get(lastYearEndPolicyValue, 'lowSavingCashDrop', 0),
      decimalPrecision(basicPlan.code)
    ),
    subtotalMediumSavingCashDrop: round(_.get(lastYearEndPolicyValue, 'mediumSavingCashDrop', 0)),
    subtotalHighSavingCashDrop: round(_.get(lastYearEndPolicyValue, 'highSavingCashDrop', 0)),
    grandTotalPremium: d(subtotalAnnualRiderPremium)
      .plus(subtotalAnnualBasicPremium)
      .toNumber(),
  }
}

export const _generatePolicyValuesForSaving = (
  policyValueParams: PolicyValueParams,
  basicPlan: DisplayProduct,
  cashValueRates: CashValueRates[],
  riderPremiumRatesPayload: RiderPremiumRate[],
  occupationFactor: {
    [string]: number,
  },
  cashDropRates?: CashDropRates[]
): PolicyValue => {
  const {
    age,
    gender,
    sumAssured,
    basicPremium,
    modelFactorPeriod,
    validSelectedRiders,
    riderBenefitTables,
  } = policyValueParams

  const productCashDropRate = findCashDropRate(basicPlan.code, cashDropRates)
  const benefits = _.get(riderBenefitTables, ['IGROW', 'riderBenefitData', 'benefits'], [])
  const accidentDeathBenefit = _.get(benefits[15], 'value', '').replace(/,/g, '')

  let cashValue
  let cashValueRate
  let cashDropValue
  let cashDropRate
  let deathBenefit = 0
  const yearEndPolicies = []
  let ageValue: number = age.unit !== 'year' ? 0 : age.value
  let isNotCalculated = false
  let isNotDisplay = false
  let riderPremium = d(0)
  let riderExtraPremium = d(0)
  let minPaymentPeriod = 7
  let basicPremiumAmt
  let riderPremiumAmt
  let maxDeathBenefitAmt = 1

  for (let rider of validSelectedRiders) {
    riderPremium = d(riderPremium.plus(d(_.get(rider, 'premium'))))
    if (_.get(rider, 'yearsOfPayment') !== basicPlan.paymentPeriod.value) {
      riderExtraPremium = d(riderExtraPremium.plus(d(_.get(rider, 'premium'))))
      minPaymentPeriod =
        _.get(rider, 'yearsOfPayment') < minPaymentPeriod ? _.get(rider, 'yearsOfPayment') : minPaymentPeriod
    }
  }

  for (let i = 1; i < basicPlan.coveragePeriod.value + 1; i++) {
    ageValue = ageValue + 1
    cashValueRate = queryCashValueRate(basicPlan.code, gender, age, i, cashValueRates)

    cashDropRate = _.get(productCashDropRate, ['cashDropRates', 'F', '0', `${ageValue}`], 0)
    cashDropValue =
      cashDropRate === 0
        ? '-'
        : d(sumAssured)
            .times(d(cashDropRate))
            .dividedBy(100)
    cashValue = d(sumAssured)
      .times(d(cashValueRate))
      .dividedBy(1000)
    if (i < 8) deathBenefit = _.get(benefits[i], 'value', '').replace(/,/g, '')
    isNotDisplay = ageValue > basicPlan.coveragePeriod.value
    basicPremiumAmt = basicPremium * modelFactorPeriod
    riderPremiumAmt =
      i > minPaymentPeriod ? (riderPremium - riderExtraPremium) * modelFactorPeriod : riderPremium * modelFactorPeriod
    isNotCalculated = i > basicPlan.paymentPeriod.value

    if (isNotDisplay) break
    maxDeathBenefitAmt = getMaxValue([
      isNotCalculated ? -1 : basicPremiumAmt * i,
      parseFloat(deathBenefit),
      cashValue.toNumber(),
      maxDeathBenefitAmt,
    ])
    let policyValue: YearEndPolicyValue = {
      age: { unit: 'year', value: isNotDisplay ? -1 : ageValue },
      yearEndPolicy: i,
      cumulativeBasicPremium: isNotCalculated ? -1 : basicPremiumAmt * i,
      annualBasicPremium: isNotCalculated ? -1 : basicPremiumAmt,
      annualRiderPremium: isNotCalculated ? -1 : round(d(riderPremiumAmt).toNumber(), 2),
      annualTotalPremium: isNotCalculated
        ? -1
        : round(
            d(basicPremiumAmt)
              .plus(d(riderPremiumAmt))
              .toNumber(),
            2
          ),
      cashDropValue: isNotDisplay
        ? ''
        : cashDropValue !== '-'
        ? formatNumber(cashDropValue.toNumber(), 2, true)
        : cashDropValue,
      deathBenefitDisplay: isNotDisplay ? '' : formatNumber(maxDeathBenefitAmt, 2, true),
      accidentDeathBenefit: isNotDisplay ? '' : formatNumber(accidentDeathBenefit, 2, true),
      cashvalue: isNotDisplay ? -1 : round(cashValue.toNumber(), 2),
      surrenderCash: 0,
      deathBenefit: 0,
      basicSumAssured: 0,
    }
    yearEndPolicies.push(policyValue)
  }
  return {
    yearEndPolicyValues: yearEndPolicies,
    subtotalAnnualBasicPremium: 0,
    subtotalAnnualRiderPremium: 0,
    grandTotalPremium: 0,
  }
}

export const _generatePolicyValuesForHealthTopping = (
  policyValueParams: PolicyValueParams,
  basicPlan: DisplayProduct,
  cashValueRates: CashValueRates[],
  riderPremiumRatesPayload: RiderPremiumRate[],
  occupationFactorForRider: {
    [string]: number,
  },
  cashDropRates?: CashDropRates[],
  payerOccupationFactorForRider: {
    [string]: number,
  }
): PolicyValue => {
  const {
    age,
    gender,
    sumAssured,
    basicPremium,
    modelFactorPeriod,
    validSelectedRiders,
    payerAge,
    payerGender,
    modelFactor,
    basicPlanQuery,
  } = policyValueParams
  const initialAge = age.unit === 'year' ? age.value : 0
  const ageRangeWithinCoveragePeriod = _.range(initialAge, basicPlan.coveragePeriod.value)
  const cashDropRate = findCashDropRate(basicPlan.code, cashDropRates)
  const generatePolicyValueRow = getGenerateYearEndPolicyValue({
    basicPlan,
    cashDropRate,
    basicPlanQuery,
  })

  const yearEndPolicyValues = ageRangeWithinCoveragePeriod.reduce((previousValue, yearEndAge) => {
    const yearEnd = yearEndAge - initialAge + 1
    const annualBasicPremium = calculateAnnualBasicPremium({
      basicPremium,
      modelFactorPeriod,
    })

    const cumulativeBasicPremium = _accumulateBasicPremium(age, yearEnd, basicPlan.paymentPeriod, d(annualBasicPremium))

    return [
      ...previousValue,
      generatePolicyValueRow({
        yearEnd,
        riders: validSelectedRiders,
        basicPlan,
        basicPremium,
        cumulativeBasicPremium,
        gender,
        yearEndAge: {
          value: yearEndAge,
          unit: 'year',
        },
        occupationFactorForRider,
        modelFactor,
        cashValueRates,
        cashDropRates,
        cashDropRate,
        basicSumAssured: sumAssured,
        modelFactorPeriod,
        riderPremiumRatesPayload,
        initialInsuredAge: {
          value: initialAge,
          unit: 'year',
        },
        payerAge,
        payerGender,
        previousYearEndValue: previousValue[previousValue.length - 1],
        payerOccupationFactorForRider,
      }),
    ]
  }, [])

  const sumBy = (policyValues: YearEndPolicyValue[], key: string): number => {
    return _.map(policyValues, key)
      .reduce((a, b) => a.add(b), d(0))
      .toNumber()
  }

  const subtotalAnnualRiderPremium = sumBy(yearEndPolicyValues, 'annualRiderPremium')
  const subtotalAnnualBasicPremium = sumBy(yearEndPolicyValues, 'annualBasicPremium')
  const lastYearEndPolicyValue = _.last(yearEndPolicyValues)
  const subtotalCashDrop = cashDropRate ? sumBy(yearEndPolicyValues, 'cashDrop') : 0

  return {
    yearEndPolicyValues,
    subtotalAnnualRiderPremium,
    subtotalAnnualBasicPremium,
    subtotalCashDrop,
    subtotalLowSavingCashDrop: round(_.get(lastYearEndPolicyValue, 'lowSavingCashDrop', 0)),
    subtotalMediumSavingCashDrop: round(_.get(lastYearEndPolicyValue, 'mediumSavingCashDrop', 0)),
    subtotalHighSavingCashDrop: round(_.get(lastYearEndPolicyValue, 'highSavingCashDrop', 0)),
    grandTotalPremium: d(subtotalAnnualRiderPremium)
      .plus(subtotalAnnualBasicPremium)
      .toNumber(),
  }
}

const _plancodeForReduceAgeToRender = [
  'WL88J',
  'WL88S',
  'W85P10J',
  'W85P10S',
  'R05',
  'R01',
  '25P15S',
  '25P15',
  '20P10S',
  '20P10',
  'PLB05',
  'PLB10',
  'PLB12',
  'PLB15',
  'ICARE',
  'WLNP60L',
  'WLNP99L',
  'WLNP85',
  'WLCI05',
  'WLCI10',
  'WLCI15',
  'WLCI20',
  'FWLNP85',
  'CIWLM39',
  'CIWLM50',
  'CIWLM60',
  'CIWLS39',
  'CIWLS50',
  'CIWLS65',
  'IHEALTHYULTRA-PWLNP85',
  'IHEALTHYULTRA-PWLNP60',
  'W99F99H',
  'W99F99M',
  'W99FU06',
  'W99FU12',
  'W99FU18',
  'W99FH18',
  'W99FU99',
  'W99FH99',
  'E20F10',
  'E25F15',
  'E25F25',
  'S14G5T1',
  'W80F06',
  'H99F06A',
  'H99F12A',
  'H99F18A',
]

const _campaignPlanCodeForReduceAgeToRender = ['WLNP85']

const _campaignPlanCodeToModify = {
  WLNP85: 'ICARE',
}
const _returnCampaignPlanCode = ['HEALTHTOPPING-WLNP60L', 'IHEALTHYULTRA-PWLNP85', 'IHEALTHYULTRA-PWLNP60']

//TODO: write test
const _modifyCampaignPlanCode = (basicPlanCode, basicPlanQuery) => {
  if (_isCampaignAndMatchPlanCode(basicPlanCode, basicPlanQuery) && getToggles().ENABLE_ICARE_REPRICING === true) {
    return _campaignPlanCodeToModify[basicPlanCode] || basicPlanCode
  } else if (_returnCampaignPlanCode.includes(basicPlanQuery.code) && basicPlanQuery.type === 'campaign') {
    return basicPlanQuery.code
  } else {
    return basicPlanCode
  }
}

const _isCampaignAndMatchPlanCode = (basicPlanCode, basicPlanQuery) =>
  _campaignPlanCodeForReduceAgeToRender.includes(basicPlanCode) && basicPlanQuery.type === 'campaign'

const _isReduceAgeBeforeRender = (basicPlanCode, basicPlanQuery) => {
  const planCode = _modifyCampaignPlanCode(basicPlanCode, basicPlanQuery)
  return _plancodeForReduceAgeToRender.includes(planCode)
}

const generateYearEndPolicyValueGLTSP = ({
  yearEnd,
  age,
  gender,
  basicSumAssured,
  validSelectedRiders,
  basicPlanQuery,
  cashValueRates,
  coverage,
}: GenerateYearEndPolicyValueProps) => {
  const surrenderCash = getSurrenderCashMRTA({
    basicPlanQuery,
    gender,
    age,
    yearEnd,
    cashValueRates,
    basicSumAssured,
    validSelectedRiders,
  })

  const yearBasicSumAssured = round(coverage >= yearEnd ? basicSumAssured : 0, 0)

  const riderSumAssured = 0

  return {
    yearEndPolicy: yearEnd,
    basicSumAssured: yearBasicSumAssured,
    riderSumAssured: riderSumAssured,
    surrenderCash: surrenderCash,
  }
}

const generateYearEndPolicyValueMRTA = ({
  yearEnd,
  age,
  gender,
  basicSumAssured,
  validSelectedRiders,
  basicPlanQuery,
  basicPlan,
  cashValueRates,
  SAyear,
  filterSaRate,
  coverage,
}: GenerateYearEndPolicyValueProps) => {
  const surrenderCash = getSurrenderCashMRTA({
    basicPlanQuery,
    gender,
    age,
    yearEnd,
    cashValueRates,
    basicSumAssured,
    validSelectedRiders,
  })

  const yearBasicSumAssured = getSumAssuredMRTA({
    basicSumAssured,
    SAyear,
    yearEnd,
    filterSaRate,
  })

  const riderSumAssured = round(
    coverage >= yearEnd && yearEnd !== 0 ? validSelectedRiders.reduce((sum, { sumAssured }) => sum + sumAssured, 0) : 0,
    0
  )

  return {
    yearEndPolicy: yearEnd,
    basicSumAssured: yearBasicSumAssured,
    riderSumAssured: riderSumAssured,
    surrenderCash: surrenderCash,
  }
}

export const _generatePolicyValuesForMRTA = (
  policyValueParams: PolicyValueParams,
  basicPlan: DisplayProduct,
  cashValueRates: CashValueRates[],
  saRates: SaRates[]
): PolicyValueMRTA => {
  const { age, gender, validSelectedRiders, basicPlanQuery, loan } = policyValueParams
  const yearEndPeriod = _.get(loan, 'coveragePlan.coverage.term.value', 0) + 1
  const yearEndPeriodRange = _.range(0, yearEndPeriod)

  const basicSumAssured = loan.coveragePlan.loan.sumAssuredContract

  const coverage = loan.coveragePlan.coverage.term.value
  const filterSaRate = findSaRate(basicPlan.code, saRates)
  let SAyear = loan.coveragePlan.coverage.term.value
  if (basicPlanQuery.code === VALUES.MRTA_CODE.MRTA_TRUNCATE) {
    SAyear = loan.coveragePlan.loan.term.value
  }
  let yearEndPolicyValues
  if (isGLTSPGroup(basicPlanQuery.code)) {
    yearEndPolicyValues = yearEndPeriodRange.reduce((previousValue, yearEnd) => {
      return [
        ...previousValue,
        generateYearEndPolicyValueGLTSP({
          yearEnd,
          age,
          gender,
          basicSumAssured,
          validSelectedRiders,
          basicPlanQuery,
          cashValueRates,
          coverage,
        }),
      ]
    }, [])
  } else {
    yearEndPolicyValues = yearEndPeriodRange.reduce((previousValue, yearEnd) => {
      return [
        ...previousValue,
        generateYearEndPolicyValueMRTA({
          yearEnd,
          age,
          gender,
          basicSumAssured,
          validSelectedRiders,
          basicPlanQuery,
          basicPlan,
          cashValueRates,
          SAyear,
          filterSaRate,
          coverage,
        }),
      ]
    }, [])
  }

  return { yearEndPolicyValues }
}

export const _generatePolicyValues = (
  policyValueParams: PolicyValueParams,
  basicPlan: DisplayProduct,
  cashValueRates: CashValueRates[],
  riderPremiumRatesPayload: RiderPremiumRate[],
  occupationFactorForRider: {
    [string]: number,
  },
  cashDropRates?: CashDropRates[],
  payerOccupationFactorForRider: {
    [string]: number,
  },
  RPURates?: RPURates[],
  ETIRates?: ETIRates[],
  PENRates?: PENRates[]
): PolicyValue => {
  const {
    age,
    gender,
    sumAssured,
    basicPremium,
    modelFactorPeriod,
    validSelectedRiders,
    payerAge,
    payerGender,
    modelFactor,
    basicPlanQuery,
  } = policyValueParams
  const initialAge = getInitialAge(age)
  const ageRangeWithinCoveragePeriod = getAgeRangeWithinCoveragePeriod(initialAge, basicPlan.coveragePeriod)

  const cashDropRate = findCashDropRate(basicPlan.code, cashDropRates)
  const RPURate = findRPURate(basicPlan.code, RPURates)
  const ETIRate = findETIRate(basicPlan.code, ETIRates)
  const PENRate = findPENRate(basicPlan.code, PENRates)
  const generatePolicyValueRow = getGenerateYearEndPolicyValue({
    basicPlan,
    cashDropRate,
    basicPlanQuery,
    RPURate,
    ETIRate,
  })

  const yearEndPolicyValues = ageRangeWithinCoveragePeriod.reduce((previousValue, yearEndAge) => {
    const yearEnd = yearEndAge - initialAge

    const annualBasicPremium = calculateAnnualBasicPremium({
      basicPremium,
      modelFactorPeriod,
    })
    const cumulativeBasicPremium = _accumulateBasicPremium(age, yearEnd, basicPlan.paymentPeriod, d(annualBasicPremium))

    return [
      ...previousValue,
      generatePolicyValueRow({
        yearEnd,
        riders: validSelectedRiders,
        basicPlan,
        basicPremium,
        cumulativeBasicPremium,
        gender,
        yearEndAge: {
          value: yearEndAge,
          unit: 'year',
        },
        occupationFactorForRider,
        modelFactor,
        cashValueRates,
        cashDropRates,
        cashDropRate,
        basicSumAssured: sumAssured,
        modelFactorPeriod,
        riderPremiumRatesPayload,
        initialInsuredAge: {
          value: initialAge,
          unit: 'year',
        },
        payerAge,
        payerGender,
        previousYearEndValue: previousValue[previousValue.length - 1],
        payerOccupationFactorForRider,
        RPURate,
        ETIRate,
        PENRate,
      }),
    ]
  }, [])

  return {
    yearEndPolicyValues,
    ...getGenerateTotalFromYearEndPolicyValues({ basicPlan, yearEndPolicyValues, cashDropRate }),
  }
}

type GeneratePensionEndYearProps = GeneratePensionProps & {
  monthEndPolicy: number,
}

export type monthlyDisplayPremiumRiderProps = {
  monthEndPolicy: number,
  modelFactorPeriod: number,
  pensionType: string,
}

export const _isMonthlyDisplayPremiumRider = (props: monthlyDisplayPremiumRiderProps): Boolean => {
  const { monthEndPolicy, modelFactorPeriod, pensionType, age, basicPlanCode, basicCoveragePeriod } = props

  if (VALUES.BUMNAN_READY_CODE_GROUP.includes(basicPlanCode) && age > basicCoveragePeriod.value) {
    return false
  }

  if (pensionType === VALUES.PENSION_ANNUALLY) {
    return true
  }
  switch (modelFactorPeriod) {
    case VALUES.MODEL_FACTOR_PERIOD_MONTHLY:
      return VALUES.RIDER_PREMIUM_DISPLAY_MONTHLY.includes(monthEndPolicy)
    case VALUES.MODEL_FACTOR_PERIOD_QUARTERLY:
      return VALUES.RIDER_PREMIUM_DISPLAY_QUATERLY.includes(monthEndPolicy)
    case VALUES.MODEL_FACTOR_PERIOD_SEMI_ANNUAL:
      return VALUES.RIDER_PREMIUM_DISPLAY_SEMI_ANNUALLY.includes(monthEndPolicy)
    case VALUES.MODEL_FACTOR_PERIOD_ANNUAL:
      return VALUES.RIDER_PREMIUM_DISPLAY_ANNUALLY.includes(monthEndPolicy)
  }
  return false
}

export const _generatePensionEndYear = (props: GeneratePensionEndYearProps): YearEndPolicyValue => {
  const {
    yearEnd,
    yearEndAge,
    basicPlan,
    pensionType,
    presentValues,
    basicSumAssured,
    cumulativeBasicPremium,
    monthEndPolicy,
    previousYearEndValue,
    riderPremiumRatesPayload,
    initialInsuredAge,
    payerAge,
    payerGender,
    gender,
    basicPremium,
    occupationFactorForRider,
    modelFactor,
    modelFactorPeriod,
    riders,
    payerOccupationFactorForRider,
  } = props
  const reductYearAge = yearEndAge.value - 1
  const presentRate = getPresentRate(basicPlan.code, pensionType, presentValues)
  const presentValue = calculatePresentValue({
    sumAssured: basicSumAssured,
    presentRate,
    year: reductYearAge,
    month: monthEndPolicy,
    pensionType,
  })
  const pensionAmount = calculatePensionAmount(basicSumAssured, pensionType, basicPlan.code, reductYearAge)
  const cumulativePersionAmount = d(_.get(previousYearEndValue, 'cumulativePersionAmount', 0)).plus(pensionAmount)

  const pensionAmountBalanceAtBasicPremium = d(cumulativeBasicPremium).minus(cumulativePersionAmount)

  const pensionTotal = VALUES.BUMNAN_READY_CODE_GROUP.includes(basicPlan.code)
    ? getPensionTotalBumnanReady(
        presentValue,
        pensionAmountBalanceAtBasicPremium,
        reductYearAge,
        cumulativeBasicPremium,
        cumulativePersionAmount
      )
    : getPensionTotal(presentValue, pensionAmountBalanceAtBasicPremium)

  const basicPaymentPeriod = basicPlan.paymentPeriod
  const basicCoveragePeriod = basicPlan.coveragePeriod
  let yearEndRiderPremium = 0
  const isSumYearEnd = pensionType === 'annually'

  if (
    _isMonthlyDisplayPremiumRider({
      monthEndPolicy: monthEndPolicy,
      modelFactorPeriod: modelFactorPeriod,
      pensionType: pensionType,
      age: yearEndAge.value,
      basicPlanCode: basicPlan.code,
      basicCoveragePeriod,
    })
  ) {
    yearEndRiderPremium = calculateTotalRiderPremium(
      riderPremiumRatesPayload,
      basicPlan,
      basicPremium,
      initialInsuredAge.value,
      payerAge,
      payerGender,
      gender,
      occupationFactorForRider,
      modelFactor,
      modelFactorPeriod,
      basicPaymentPeriod,
      basicCoveragePeriod,
      yearEndAge.value - 1,
      isSumYearEnd,
      payerOccupationFactorForRider
    )(riders)
  }

  const annualRiderPremium = roundDown(yearEndRiderPremium, 2)

  return {
    age: yearEndAge,
    yearEndPolicy: yearEnd,
    cumulativeBasicPremium,
    annualBasicPremium: 0,
    annualRiderPremium: annualRiderPremium,
    annualTotalPremium: annualRiderPremium,
    surrenderCash: 0,
    deathBenefit: 0,
    basicSumAssured: 0,
    cumulativePersionAmount,
    pensionPerYear: pensionAmount,
    pensionTotal,
  }
}

type GeneratePensionProps = {
  yearEnd: number,
  riders: (Rider & RiderState)[],
  basicPlan: DisplayProduct,
  basicPremium: number,
  cumulativeBasicPremium: number,
  gender: Gender,
  yearEndAge: Age,
  occupationFactorForRider: {
    [string]: number,
  },
  modelFactor: number,
  cashValueRates: CashValueRates[],
  cashDropRate?: CashDropRates,
  basicSumAssured: number,
  modelFactorPeriod: number,
  riderPremiumRatesPayload: Object[],
  initialInsuredAge: Age,
  payerAge: Age,
  payerGender: Gender,
  previousYearEndValue: YearEndPolicyValue,
  pensionType: string,
  presentValues: PresentValue[],
  payerOccupationFactorForRider: {
    [string]: number,
  },
}
export const _generatePension = (props: GeneratePensionProps): YearEndPolicyValue[] => {
  const { pensionType, previousYearEndValue } = props
  if (pensionType === 'monthly') {
    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    let tempPreviousYearEndValue = previousYearEndValue
    return months.map((month) => {
      const policyEndYear = _generatePensionEndYear({
        ...props,
        previousYearEndValue: tempPreviousYearEndValue,
        monthEndPolicy: month,
      })
      tempPreviousYearEndValue = policyEndYear
      return { ...policyEndYear, monthEndPolicy: month }
    })
  }

  return [
    _generatePensionEndYear({
      ...props,
      monthEndPolicy: 1,
    }),
  ]
}

export const _generatePolicyValuesForRetirement = (
  policyValueParams: PolicyValueParams,
  basicPlan: DisplayProduct,
  cashValueRates: CashValueRates[],
  riderPremiumRatesPayload: RiderPremiumRate[],
  occupationFactorForRider: {
    [string]: number,
  },
  cashDropRates?: CashDropRates[],
  presentValues: PresentValue[],
  payerOccupationFactorForRider: {
    [string]: number,
  }
): PolicyRetirementValue => {
  const {
    age,
    gender,
    sumAssured,
    basicPremium,
    fullBasicPremium,
    fullAnnualBasicPremium,
    modelFactorPeriod,
    validSelectedRiders,
    payerAge,
    payerGender,
    modelFactor,
    pensionType = '',
  } = policyValueParams
  const initialAge = age.unit === 'year' ? age.value : 0
  const ageRangeWithinCoveragePeriod = _.range(
    initialAge + 1,
    // +2 because the first year of coverage we don't care when the customer buy,
    // we kindly give them for one more year pension amount
    // if you see in the Excel file when insured age 35 the number of coverage ( last record ) will show 51
    basicPlan.coveragePeriod.value + 2
  )

  const cashDropRate = findCashDropRate(basicPlan.code, cashDropRates)
  const generateCVTable = compose((item) => {
    item.monthEndPolicy = 1
    return [item]
  }, generateYearEndPolicyValue)
  let updateValidSelectedRiders = validSelectedRiders

  const yearEndPolicyValues = ageRangeWithinCoveragePeriod.reduce((previousValue, yearEndAge) => {
    const yearEnd = yearEndAge - initialAge

    let yearBasicPremium = basicPremium
    let annualBasicPremium = calculateAnnualBasicPremium({
      basicPremium: yearBasicPremium,
      modelFactorPeriod,
    })

    if (fullBasicPremium && fullAnnualBasicPremium && basicPremium !== fullBasicPremium && yearEnd !== 1) {
      yearBasicPremium = fullBasicPremium
      annualBasicPremium = calculateAnnualBasicPremium({
        basicPremium: yearBasicPremium,
        modelFactorPeriod,
      })
      // due to after discount price will be same at latest so the data will not set back
      // MRTA IGROW(PBG) is not apply so it will set with defaults
      updateValidSelectedRiders = validSelectedRiders.map((rider) => {
        if (_.get(rider, 'options.0.source') === VALUES.SOURCE_TOTAL_BASIC_ANNUAL_PREMIUM_WITH_LIMITED_RANGE) {
          return { ...rider, sumAssured: fullAnnualBasicPremium }
        }
        return rider
      })
    }

    let cumulativeBasicPremium = _accumulateBasicPremium(age, yearEnd, basicPlan.paymentPeriod, d(annualBasicPremium))
    if (fullBasicPremium && basicPremium !== fullBasicPremium && yearEnd !== 1) {
      let annualFirstYearPremium = calculateAnnualBasicPremium({
        basicPremium: basicPremium,
        modelFactorPeriod,
      })
      cumulativeBasicPremium = cumulativeBasicPremium - annualBasicPremium + annualFirstYearPremium
    }

    const policyValues =
      yearEndAge <= 60
        ? generateCVTable({
            yearEnd,
            riders: updateValidSelectedRiders,
            basicPlan,
            basicPremium: yearBasicPremium,
            cumulativeBasicPremium,
            gender,
            yearEndAge: {
              value: yearEndAge,
              unit: 'year',
            },
            occupationFactorForRider,
            modelFactor,
            cashValueRates,
            cashDropRate,
            basicSumAssured: sumAssured,
            modelFactorPeriod,
            riderPremiumRatesPayload,
            initialInsuredAge: {
              value: initialAge,
              unit: 'year',
            },
            payerAge,
            payerGender,
            previousYearEndValue: previousValue[previousValue.length - 1],
            payerOccupationFactorForRider,
          })
        : _generatePension({
            yearEnd,
            riders: updateValidSelectedRiders,
            basicPlan,
            basicPremium: yearBasicPremium,
            cumulativeBasicPremium,
            gender,
            yearEndAge: {
              value: yearEndAge,
              unit: 'year',
            },
            occupationFactorForRider,
            modelFactor,
            cashValueRates,
            cashDropRate,
            basicSumAssured: sumAssured,
            modelFactorPeriod,
            riderPremiumRatesPayload,
            initialInsuredAge: {
              value: initialAge,
              unit: 'year',
            },
            payerAge,
            payerGender,
            previousYearEndValue: previousValue[previousValue.length - 1],
            pensionType,
            presentValues,
            payerOccupationFactorForRider,
          })
    return [...previousValue, ...policyValues]
  }, [])

  const sumBy = (policyValues: YearEndPolicyValue[], key: string): number => {
    return _.map(policyValues, key)
      .reduce((a, b) => a.add(b || 0), d(0))
      .toNumber()
  }

  const subtotalAnnualRiderPremium = sumBy(yearEndPolicyValues, 'annualRiderPremium')

  const subtotalPensionAmount = sumBy(yearEndPolicyValues, 'pensionPerYear')
  const subtotalAnnualBasicPremium = sumBy(yearEndPolicyValues, 'annualBasicPremium')
  const lastYearEndPolicyValue = _.last(yearEndPolicyValues)
  const subtotalCashDrop = cashDropRate ? sumBy(yearEndPolicyValues, 'cashDrop') : 0

  return {
    yearEndPolicyValues,
    subtotalAnnualRiderPremium,
    subtotalAnnualBasicPremium,
    subtotalCashDrop,
    subtotalPensionAmount,
    subtotalLowSavingCashDrop: round(_.get(lastYearEndPolicyValue, 'lowSavingCashDrop', 0)),
    subtotalMediumSavingCashDrop: round(_.get(lastYearEndPolicyValue, 'mediumSavingCashDrop', 0)),
    subtotalHighSavingCashDrop: round(_.get(lastYearEndPolicyValue, 'highSavingCashDrop', 0)),
    grandTotalPremium: d(subtotalAnnualRiderPremium)
      .plus(subtotalAnnualBasicPremium)
      .toNumber(),
  }
}

export const generatePolicyValues = async (policyValueParams: PolicyValueParams): Promise<PolicyValue> => {
  const basicPlan = await getBasicPlan(policyValueParams.basicPlanQuery)
  const basicPlanCategory = _.get(basicPlan, 'category')
  const code = _.get(policyValueParams, 'basicPlanQuery.code')
  const riderPremiumRatesPayload = await dataWrapper.getRiderPremiumRates()
  const riderOccupationFactors = await getRiskiestOccupationFactorForRiders(
    policyValueParams.basicPlanQuery,
    policyValueParams.natureOfDutyCodes,
    policyValueParams.validSelectedRiders
  )
  const payerOccupationFactorForRider = await getRiskiestOccupationFactorForRiders(
    policyValueParams.basicPlanQuery,
    policyValueParams.payerNatureOfDutyCodes,
    policyValueParams.validSelectedRiders
  )
  const cashValueRates = await dataWrapper.getCashValueRates()
  const cashDropRates = await dataWrapper.getCashDropRates()

  const RPURates = await dataWrapper.getRPURates()
  const ETIRates = await dataWrapper.getETIRates()
  const PENRates = await dataWrapper.getPENRates()
  const SaRates = await dataWrapper.getSaRates()

  // $FlowFixMe
  const displayProduct = transformToDisplayProduct({
    ...basicPlan,
    riders: basicPlan.riders.map((rider) => rider.code),
  })

  switch (basicPlanCategory) {
    case 'SAVING':
      return _generatePolicyValuesForSaving(
        policyValueParams,
        displayProduct,
        cashValueRates,
        riderPremiumRatesPayload,
        riderOccupationFactors,
        cashDropRates
      )
    case 'MRTA':
      return _generatePolicyValuesForMRTA(policyValueParams, displayProduct, cashValueRates, SaRates)
    case 'LIFE_SAVE_PRO':
    case 'TERM_LIFE':
      return _generatePolicyValuesForLifeSavePro(
        policyValueParams,
        displayProduct,
        cashValueRates,
        riderPremiumRatesPayload,
        riderOccupationFactors,
        cashDropRates,
        payerOccupationFactorForRider
      )
    case 'RETIREMENT':
    case 'BUMNAN_READY':
      const presentValues = await dataWrapper.getPresentValues()
      return _generatePolicyValuesForRetirement(
        policyValueParams,
        displayProduct,
        cashValueRates,
        riderPremiumRatesPayload,
        riderOccupationFactors,
        cashDropRates,
        presentValues,
        payerOccupationFactorForRider
      )
    case 'HEALTH':
    case 'HEALTH_TOPPING':
      return VALUES.IHEALTHY_ULTRA_IHEALTHY_CODE.includes(code)
        ? _generatePolicyValues(
            policyValueParams,
            displayProduct,
            cashValueRates,
            riderPremiumRatesPayload,
            riderOccupationFactors,
            cashDropRates,
            payerOccupationFactorForRider
          )
        : _generatePolicyValuesForHealthTopping(
            policyValueParams,
            displayProduct,
            cashValueRates,
            riderPremiumRatesPayload,
            riderOccupationFactors,
            cashDropRates,
            payerOccupationFactorForRider
          )
    default:
      return _generatePolicyValues(
        policyValueParams,
        displayProduct,
        cashValueRates,
        riderPremiumRatesPayload,
        riderOccupationFactors,
        cashDropRates,
        payerOccupationFactorForRider,
        RPURates,
        ETIRates,
        PENRates
      )
  }
}

export const _isWithinBasicPlanCoveragePeriod = (yearEnd: number, coveragePeriod: Period, initialInsuredAge: Age) => {
  switch (coveragePeriod.type) {
    case VALUES.PERIOD_LIMITED_TO_AGE:
      return yearEnd <= coveragePeriod.value - initialInsuredAge.value
    case VALUES.PERIOD_BY_PACKAGE:
    case VALUES.PERIOD_LIMITED_BY_DURATION:
    default:
      return yearEnd <= coveragePeriod.value
  }
}

export const _isRepeatCumulativeBasicPremium = (basicPlan: DisplayProduct) => {
  switch (basicPlan.category) {
    case 'LIFE_SAVE_PRO':
    case 'RETIREMENT':
    case 'HEALTH_TOPPING':
    case 'LIFE_READY':
    case 'BUMNAN_READY':
    case 'TERM_LIFE':
      return true
    case 'WHOLE_LIFE':
      return (
        isLifeLegacyProduct(basicPlan.code) ||
        isLifeProtectProduct(basicPlan.code) ||
        isWholeLife12PLProduct(basicPlan.code) ||
        isLifeEnsureProduct(basicPlan.code) ||
        isS7Product(basicPlan.code)
      )
    default:
      return false
  }
}

export const _isWithinBasicPlanPaymentPeriod = (yearEnd: number, paymentPeriod: Period, initialInsuredAge: Age) => {
  switch (paymentPeriod.type) {
    case VALUES.PERIOD_LIMITED_TO_AGE:
      return yearEnd <= paymentPeriod.value - initialInsuredAge.value
    case VALUES.PERIOD_BY_PACKAGE:
    case VALUES.PERIOD_LIMITED_BY_DURATION:
    default:
      return yearEnd <= paymentPeriod.value
  }
}

type GenerateYearEndPolicyValueProps = {
  yearEnd: number,
  riders: (Rider & RiderState)[],
  basicPlan: DisplayProduct,
  basicPremium: number,
  cumulativeBasicPremium: number,
  gender: Gender,
  yearEndAge: Age,
  occupationFactorForRider: {
    [string]: number,
  },
  modelFactor: number,
  cashValueRates: CashValueRates[],
  cashDropRates?: CashDropRates[],
  cashDropRate?: CashDropRates,
  basicSumAssured: number,
  modelFactorPeriod: number,
  riderPremiumRatesPayload: Object[],
  initialInsuredAge: Age,
  payerAge: Age,
  payerGender: Gender,
  previousYearEndValue: YearEndPolicyValue,
  payerOccupationFactorForRider: {
    [string]: number,
  },
  RPURate?: RPURates,
  ETIRate?: ETIRates,
  PENRate?: PENRates,
}

const generateYearEndPolicyValue = ({
  yearEnd,
  riders,
  basicPlan,
  basicPremium,
  cumulativeBasicPremium,
  gender,
  yearEndAge,
  occupationFactorForRider,
  modelFactor,
  cashValueRates,
  cashDropRate,
  basicSumAssured,
  modelFactorPeriod,
  riderPremiumRatesPayload,
  initialInsuredAge,
  payerAge,
  payerGender,
  previousYearEndValue,
  payerOccupationFactorForRider,
}: GenerateYearEndPolicyValueProps): YearEndPolicyValue => {
  const isWithinBasicPlanPaymentPeriod = _isWithinBasicPlanPaymentPeriod(
    yearEnd,
    basicPlan.paymentPeriod,
    initialInsuredAge
  )
  const isRepeatCumulativeBasicPremium = _isRepeatCumulativeBasicPremium(basicPlan)

  const surrenderCash = getSurrenderCash({
    basicPlan,
    gender,
    initialInsuredAge,
    yearEnd,
    cashValueRates,
    basicSumAssured,
  })

  const basicPaymentPeriod = basicPlan.paymentPeriod
  const basicCoveragePeriod = basicPlan.coveragePeriod
  const isSumYearEnd = true
  const yearEndRiderPremium = calculateTotalRiderPremium(
    riderPremiumRatesPayload,
    basicPlan,
    basicPremium,
    initialInsuredAge.value,
    payerAge,
    payerGender,
    gender,
    occupationFactorForRider,
    modelFactor,
    modelFactorPeriod,
    basicPaymentPeriod,
    basicCoveragePeriod,
    yearEndAge.value - 1,
    isSumYearEnd,
    payerOccupationFactorForRider
  )(riders)

  const annualBasicPremium = d(roundDown(d(basicPremium).times(d(modelFactorPeriod).toNumber()), 2))
  const annualRiderPremium = roundDown(yearEndRiderPremium, 2)
  const annualBasicPremiumInPaymentPeriod = isWithinBasicPlanPaymentPeriod ? annualBasicPremium.toNumber() : 0
  const annualTotalPremium = d(annualBasicPremiumInPaymentPeriod)
    .plus(d(annualRiderPremium))
    .toNumber()

  // death benefit calculation logic is a candidate which should eventually be
  // moved to product configuration. We hard code it here for now to wait until
  // we understand more about its pattern.
  // This formula is just for iProtect series and the campaign on top of it.

  const deathBenefit = calculateDeathBenefit({
    basicPlan,
    basicSumAssured,
    cumulativeBasicPremium,
    surrenderCash,
    yearEnd,
  })

  return {
    age: yearEndAge,
    yearEndPolicy: yearEnd,
    cumulativeBasicPremium:
      isWithinBasicPlanPaymentPeriod || isRepeatCumulativeBasicPremium ? cumulativeBasicPremium : 0,
    annualBasicPremium: annualBasicPremiumInPaymentPeriod,
    annualRiderPremium,
    annualTotalPremium,
    surrenderCash,
    deathBenefit,
    basicSumAssured,
  }
}

export const generateRPU_ETI = ({
  yearEnd,
  gender,
  initialInsuredAge,
  basicSumAssured,
  RPURate,
  ETIRate,
  PENRate,
}: GenerateYearEndPolicyValueProps) => {
  return (result: YearEndPolicyValue): YearEndPolicyValue => {
    const queriedRPURate = queryRPURate(gender, initialInsuredAge, yearEnd, RPURate)
    const queriedPENRate = queryPENRate(gender, initialInsuredAge, yearEnd, PENRate)
    const queriedETIRate = queryETIRate(gender, initialInsuredAge, yearEnd, ETIRate)

    let currentRPU = calculateRPU(queriedRPURate, basicSumAssured)
    let currentETI = calculateETI(queriedETIRate, queriedPENRate, basicSumAssured)

    return {
      ...result,
      ETIMaturity: currentETI.ETIMaturity,
      ETICashpaid: currentETI.ETICashpaid,
      ETIYear: currentETI.ETIYear,
      ETIDay: currentETI.ETIDay,
      RPUSumAssured: currentRPU.RPUSumAssured,
      RPUCashpaid: currentRPU.RPUCashpaid,
    }
  }
}

export const generateCashDrop = ({
  yearEnd,
  riders,
  basicPlan,
  basicPremium,
  cumulativeBasicPremium,
  gender,
  yearEndAge,
  occupationFactorForRider,
  modelFactor,
  cashValueRates,
  cashDropRate,
  basicSumAssured,
  modelFactorPeriod,
  riderPremiumRatesPayload,
  initialInsuredAge,
  payerAge,
  payerGender,
  previousYearEndValue,
}: GenerateYearEndPolicyValueProps) => {
  //prepare the rate
  return (result: YearEndPolicyValue): YearEndPolicyValue => {
    const queriedCashDropRate = queryCashDropRate(gender, initialInsuredAge, yearEnd, cashDropRate)

    const previousCashDrop = _.get(previousYearEndValue, 'cashDrop', 0)
    const previousSumCashDrop = _.get(previousYearEndValue, 'sumCashDrop', 0)
    const previousLowSavingCashDrop = _.get(previousYearEndValue, 'lowSavingCashDrop', 0)
    const previousMediumSavingCashDrop = _.get(previousYearEndValue, 'mediumSavingCashDrop', 0)
    const previousHighSavingCashDrop = _.get(previousYearEndValue, 'highSavingCashDrop', 0)

    let currentCashDrop = calculateCashDrop(queriedCashDropRate, basicSumAssured)
    let sumCashDrop
    let lowSavingCashDrop
    let mediumSavingCashDrop
    let highSavingCashDrop

    const yearEndPolicy =
      basicPlan.coveragePeriod.type === VALUES.PERIOD_LIMITED_BY_DURATION ? yearEnd : yearEndAge.value
    if (basicPlan.category === 'TERM_LIFE' && yearEndPolicy === basicPlan.coveragePeriod.value) {
      const accumulateBasicPremium = d(cumulativeBasicPremium)
        .times(1.01)
        .toNumber()
      const surrenderCash = getSurrenderCash({
        basicPlan,
        gender,
        initialInsuredAge,
        yearEnd,
        cashValueRates,
        basicSumAssured,
      })

      if (VALUES.ULTIMATE_GROWTH_CODE_GROUP.includes(basicPlan.code)) {
        currentCashDrop = Math.max(currentCashDrop, accumulateBasicPremium)
      } else {
        currentCashDrop = Math.max(currentCashDrop, accumulateBasicPremium, surrenderCash)
      }
    }

    const cumulativeDeathBenefit = d(result.deathBenefit)
      .plus(previousSumCashDrop)
      .toNumber()

    let surrenderCash = result.surrenderCash
    if (previousCashDrop) {
      sumCashDrop = d(previousSumCashDrop)
        .plus(currentCashDrop)
        .toNumber()
      lowSavingCashDrop = d(previousLowSavingCashDrop)
        .times(1.005)
        .plus(currentCashDrop)
        .toNumber()
      mediumSavingCashDrop = d(previousMediumSavingCashDrop)
        .times(1.015)
        .plus(currentCashDrop)
        .toNumber()
      highSavingCashDrop = d(previousHighSavingCashDrop)
        .times(1.025)
        .plus(currentCashDrop)
        .toNumber()

      if (
        yearEndAge.value === basicPlan.coveragePeriod.value &&
        !['LIFE_SAVE_PRO', 'TERM_LIFE'].includes(_.get(basicPlan, 'category'))
      ) {
        currentCashDrop = d(currentCashDrop)
          .plus(basicSumAssured)
          .toNumber()
        lowSavingCashDrop = d(lowSavingCashDrop)
          .plus(basicSumAssured)
          .toNumber()
        mediumSavingCashDrop = d(mediumSavingCashDrop)
          .plus(basicSumAssured)
          .toNumber()
        highSavingCashDrop = d(highSavingCashDrop)
          .plus(basicSumAssured)
          .toNumber()
        surrenderCash = basicSumAssured
      }
    } else {
      sumCashDrop = currentCashDrop
      lowSavingCashDrop = currentCashDrop
      mediumSavingCashDrop = currentCashDrop
      highSavingCashDrop = currentCashDrop
    }

    return {
      ...result,
      cashDrop: currentCashDrop,
      sumCashDrop,
      lowSavingCashDrop,
      mediumSavingCashDrop,
      highSavingCashDrop,
      cumulativeDeathBenefit,
      surrenderCash,
    }
  }
}

const _is12PL = (code: string): boolean => code === '12PL'

const getGenerateAdditionalYearEndPolicyValueFor12PL = ({
  basicPlan,
  cashDropRates,
  initialInsuredAge,
  yearEnd,
  gender,
  basicSumAssured,
  previousYearEndValue,
  yearEndAge,
  cashValueRates,
}: GenerateYearEndPolicyValueProps) => {
  const cashDropRateNonGuarantee = findNonGuaranteeCashDropRate(basicPlan.code, cashDropRates)

  const cashDropRateGuarantee = findCashDropRate(basicPlan.code, cashDropRates)

  return (yearEndPolicyValue: YearEndPolicyValue) => {
    const queriedNonGuaranteeCashDropRate = queryCashDropRate(
      gender,
      initialInsuredAge,
      yearEnd,
      cashDropRateNonGuarantee
    )

    const surrenderCash = getSurrenderCash({
      basicPlan,
      gender,
      initialInsuredAge,
      yearEnd,
      cashValueRates,
      basicSumAssured,
    })

    const queriedGuaranteeCashDropRate = queryCashDropRate(gender, initialInsuredAge, yearEnd, cashDropRateGuarantee)

    const nonGuaranteeCashDrop = calculateGuarenteeDividend({
      sumAssured: basicSumAssured,
      rate: queriedNonGuaranteeCashDropRate,
    })
    const guaranteeDividend = calculateGuarenteeDividend({
      sumAssured: basicSumAssured,
      rate: queriedGuaranteeCashDropRate,
    })

    const maturityAmount = calculateMaturity({ basicSumAssured, maturityRate: 1150 })
    const guaranteeDividendFinal =
      yearEndAge.value === basicPlan.coveragePeriod.value ? guaranteeDividend + maturityAmount : guaranteeDividend

    const nonGuaranteeDividendDeposit = calculateInterestNonGuaranteeDividendDeposit({
      previousNonGuranteeDividendDeposit: _.get(previousYearEndValue, 'nonGuaranteeDividendDeposit', 0),
      guaranteeDividendOfYear: guaranteeDividendFinal,
      interestRate: 1.005,
    })

    const cumulativeGuaranteeCashDrop = d(_.get(previousYearEndValue, 'cumulativeGuaranteeCashDrop', 0))
      .plus(guaranteeDividend)
      .toNumber()

    const differenceDividend = d(nonGuaranteeCashDrop)
      .minus(guaranteeDividend)
      .toNumber()
    const lowInterest = d(differenceDividend)
      .times(0)
      .div(100)
      .toNumber()
    const lowInterestDeposit = d(_.get(previousYearEndValue, 'lowInterestDeposit', 0))
      .times(1.005)
      .plus(lowInterest)
      .toNumber()
    const midInterest = d(differenceDividend)
      .times(75)
      .div(100)
      .toNumber()
    const midInterestDeposit = d(_.get(previousYearEndValue, 'midInterestDeposit', 0))
      .times(1.005)
      .plus(midInterest)
      .toNumber()
    const highInterest = d(differenceDividend)
      .times(90)
      .div(100)
      .toNumber()
    const highInterestDeposit = d(_.get(previousYearEndValue, 'highInterestDeposit', 0))
      .times(1.005)
      .plus(highInterest)
      .toNumber()
    const totalLowDividend = d(nonGuaranteeDividendDeposit)
      .plus(lowInterestDeposit)
      .toNumber()
    const totalMidDividend = d(nonGuaranteeDividendDeposit)
      .plus(midInterestDeposit)
      .toNumber()
    const totalHighDividend = d(nonGuaranteeDividendDeposit)
      .plus(highInterestDeposit)
      .toNumber()
    const totalBenefit = d(_.get(previousYearEndValue, 'cumulativeGuaranteeCashDrop', 0))
      .plus(yearEndPolicyValue.deathBenefit)
      .toNumber()

    return {
      ...yearEndPolicyValue,
      surrenderCash,
      cashDrop: guaranteeDividendFinal, //TODO override exists and waiting refactor
      sumCashDrop: nonGuaranteeDividendDeposit, //TODO override exists and waiting refactor
      nonGuaranteeCashDrop,
      guaranteeDividend: guaranteeDividendFinal,
      nonGuaranteeDividendDeposit,
      cumulativeGuaranteeCashDrop,
      maturityAmount,
      lowInterest,
      lowInterestDeposit,
      midInterest,
      midInterestDeposit,
      highInterest,
      highInterestDeposit,
      totalLowDividend,
      totalMidDividend,
      totalHighDividend,
      totalBenefit,
    }
  }
}

const getGenerateYearEndPolicyValue = ({ basicPlan, cashDropRate, basicPlanQuery, RPURate, ETIRate }) => {
  return (props: GenerateYearEndPolicyValueProps) => {
    const mainGenerator = cashDropRate
      ? (props: GenerateYearEndPolicyValueProps) => generateCashDrop(props)(generateYearEndPolicyValue(props))
      : generateYearEndPolicyValue

    const composedGenerator = compact([
      _isReduceAgeBeforeRender(basicPlan.code, basicPlanQuery) && _reduceAgeToRender,
      _is12PL(basicPlan.code) && getGenerateAdditionalYearEndPolicyValueFor12PL(props),
      (RPURate !== undefined || ETIRate !== undefined) && getToggles().ENABLE_RPU_ETI && generateRPU_ETI(props),
      mainGenerator,
    ])

    return compose(composedGenerator)(props)
  }
}

type GenerateTotalFromYearEndPolicyValuesProps = {
  yearEndPolicyValues: YearEndPolicyValue[],
  cashDropRate?: CashDropRates,
}
const generateTotalFromYearEndPolicyValues = ({
  yearEndPolicyValues,
  cashDropRate,
}: GenerateTotalFromYearEndPolicyValuesProps) => {
  const subtotalAnnualRiderPremium = sumPolicyValuesByColumn(yearEndPolicyValues, 'annualRiderPremium')
  const subtotalAnnualBasicPremium = sumPolicyValuesByColumn(yearEndPolicyValues, 'annualBasicPremium')
  const lastYearEndPolicyValue = _.last(yearEndPolicyValues)
  const subtotalCashDrop = cashDropRate ? sumPolicyValuesByColumn(yearEndPolicyValues, 'cashDrop') : 0
  return {
    subtotalAnnualRiderPremium,
    subtotalAnnualBasicPremium,
    subtotalCashDrop,
    subtotalLowSavingCashDrop: round(_.get(lastYearEndPolicyValue, 'lowSavingCashDrop', 0)),
    subtotalMediumSavingCashDrop: round(_.get(lastYearEndPolicyValue, 'mediumSavingCashDrop', 0)),
    subtotalHighSavingCashDrop: round(_.get(lastYearEndPolicyValue, 'highSavingCashDrop', 0)),
    grandTotalPremium: d(subtotalAnnualRiderPremium)
      .plus(subtotalAnnualBasicPremium)
      .toNumber(),
  }
}

const generateTotalFromYearEndPolicyValuesFor12PL = ({
  yearEndPolicyValues,
  cashDropRate,
}: GenerateTotalFromYearEndPolicyValuesProps) => {
  const subtotalAnnualRiderPremium = sumPolicyValuesByColumn(yearEndPolicyValues, 'annualRiderPremium')
  const subtotalAnnualBasicPremium = sumPolicyValuesByColumn(yearEndPolicyValues, 'annualBasicPremium')
  const lastYearEndPolicyValue = _.last(yearEndPolicyValues)
  return {
    subtotalAnnualRiderPremium,
    subtotalAnnualBasicPremium,
    subtotalGuaranteeDividend: sumPolicyValuesByColumn(yearEndPolicyValues, 'guaranteeDividend'),
    subTotalNonGuaranteeDividendDeposit: _.get(lastYearEndPolicyValue, 'nonGuaranteeDividendDeposit'),
    subTotalLowInterest: sumPolicyValuesByColumn(yearEndPolicyValues, 'lowInterest'),
    subTotalLowInterestDeposit: _.get(lastYearEndPolicyValue, 'lowInterestDeposit'),
    subTotalMidInterest: sumPolicyValuesByColumn(yearEndPolicyValues, 'midInterest'),
    subTotalMidInterestDeposit: _.get(lastYearEndPolicyValue, 'midInterestDeposit'),
    subTotalHighInterest: sumPolicyValuesByColumn(yearEndPolicyValues, 'highInterest'),
    subTotalHighInterestDeposit: _.get(lastYearEndPolicyValue, 'highInterestDeposit'),
    subTotalLowDividend: _.get(lastYearEndPolicyValue, 'totalLowDividend'),
    subTotalMidDividend: _.get(lastYearEndPolicyValue, 'totalMidDividend'),
    subTotalHighDividend: _.get(lastYearEndPolicyValue, 'totalHighDividend'),
    grandTotalPremium: d(subtotalAnnualRiderPremium)
      .plus(subtotalAnnualBasicPremium)
      .toNumber(),
  }
}

type GetGenerateTotalFromYearEndPolicyValueProps = {
  basicPlan: DisplayProduct,
  yearEndPolicyValues: YearEndPolicyValue[],
  cashDropRate?: CashDropRates,
}
const getGenerateTotalFromYearEndPolicyValues = ({
  basicPlan,
  yearEndPolicyValues,
  cashDropRate,
}: GetGenerateTotalFromYearEndPolicyValueProps) => {
  const code = basicPlan.code
  switch (code) {
    case '12PL':
      return generateTotalFromYearEndPolicyValuesFor12PL({ yearEndPolicyValues, cashDropRate })
    default:
      return generateTotalFromYearEndPolicyValues({ yearEndPolicyValues, cashDropRate })
  }
}

export type PolicyValueTableProps = {
  policyValue: PolicyValue,
  basicPlan: DisplayProduct,
}

export type PolicyValueRetirementTableProps = PolicyValueTableProps & {
  pensionType: string,
}

export const _getMessageUnderiShieldPolicyValueTable = ({ policyValue, basicPlan }: PolicyValueTableProps): ?string => {
  const firstRow = _findFirstYearEndPolicyValueWhereDeathBenefitExceedsBasicSA(policyValue.yearEndPolicyValues)

  // iShield codes start with 'WLCI'
  if (basicPlan.code.match('^WLCI.+') && firstRow) {
    const { age, yearEndPolicy } = firstRow
    return Mustache.render(MESSAGES.ISHIELD_POLICY_TABLE_INFO, {
      age: age.value,
      yearEndPolicy: yearEndPolicy,
    })
  } else {
    return null
  }
}

export const _findFirstYearEndPolicyValueWhereDeathBenefitExceedsBasicSA = (
  yearEndVal: YearEndPolicyValue[]
): ?YearEndPolicyValue => {
  const filtered = yearEndVal.filter((row) => row.deathBenefit > row.basicSumAssured)
  const sorted = _.sortBy(filtered, (item) => item.yearEndPolicy)
  return sorted[0] // first matching item
}
