// @flow

import { FormFeedback } from 'reactstrap'
import { isZero } from 'common-components/view-logic'
import values from 'core/data-model/constants/values'
import _ from 'lodash'
import type { RuleType } from 'core/data-model/validation'
import type { Checked } from 'core/service/lib/check'

type ErrorFeedbackProps = {
  id: string,
  messageType?: string,
  errorMessages: string[],
}

export const renderInforMessage = (id: string) => (messages: string[]) => {
  return <ErrorFeedback id={id} errorMessages={messages} messageType="info-message" />
}

export const renderErrorMessage = (id: string) => (validated: Checked<number>, type: ?RuleType) => {
  let errorMessages = []
  let messageType = ''

  if (!isZero(validated)) {
    if (type === values.ADDITIONAL_INFORMATION) {
      errorMessages = validated.getErrorMessages(values.ADDITIONAL_INFORMATION)
      messageType = 'info-message'
    } else {
      errorMessages = errorMessages.concat(
        validated.getErrorMessages(values.ELIGIBILITY),
        validated.getErrorMessages(values.VALIDATION)
      )
    }
  }
  return <ErrorFeedback id={id} errorMessages={errorMessages} messageType={messageType} />
}

export const renderErrorMessageAcceptZeroValue = (id: string) => (validated: Checked<number>, type: ?RuleType) => {
  let errorMessages = []
  let messageType = ''

  if (type === values.ADDITIONAL_INFORMATION) {
    errorMessages = validated.getErrorMessages(values.ADDITIONAL_INFORMATION)
    messageType = 'info-message'
  } else {
    errorMessages = errorMessages.concat(
      validated.getErrorMessages(values.ELIGIBILITY),
      validated.getErrorMessages(values.VALIDATION)
    )
  }
  return <ErrorFeedback id={id} errorMessages={errorMessages} messageType={messageType} />
}

export const ErrorFeedback = ({ id, errorMessages, messageType }: ErrorFeedbackProps) => {
  if (_.isEmpty(errorMessages)) {
    return null
  }
  return (
    <div>
      {errorMessages.map((message, index) => (
        <FormFeedback
          className={`validation-message attached-icon ${messageType || 'error-message'}`}
          key={`${id}${index}`}
        >
          <p>{message}</p>
        </FormFeedback>
      ))}
    </div>
  )
}

export const renderErrorMessages = (id: string) => (
  errors: Array<{ type: RuleType, message: string }>,
  type: ?RuleType
) => {
  let errorMessages = []
  let messageType = ''

  if (errors.length > 0) {
    if (type === values.ADDITIONAL_INFORMATION) {
      errorMessages = errors.map(({ message }) => message)
      messageType = 'info-message'
    } else {
      errorMessages = errorMessages.concat(
        errors.filter(({ type }) => type === values.ELIGIBILITY).map(({ message }) => message),
        errors.filter(({ type }) => type === values.VALIDATION).map(({ message }) => message)
      )
    }
  }
  return <ErrorFeedback id={id} errorMessages={errorMessages} messageType={messageType} />
}
