/* istanbul ignore file */
import { compose, lifecycle, withHandlers, withState } from 'recompose'
import { Flex, Box } from 'rebass'
import { Button } from 'reactstrap'
import _ from 'lodash'
import { connect } from 'react-redux'

import { saveDataAuraToMiddelware, setDefaultAppValue } from 'e-submission/apps/actions'
import { getAppConfig } from 'deploy-env/app-config'
import AuraValidation from 'e-submission/containers/AppForm/FormValidation/auraValidation'
import styled from 'styled-components'
import FormSection from 'e-submission/components/Layout/FormSection'
import FormContent from 'e-submission/components/Layout/FormContent'

import { getPredefineData } from 'e-submission/utils/tranformData'
import { genaratedTransRefGUID, getCurrentAppId } from 'e-submission/apps/selectors'

import AddDocument from 'assets/images/nav/AddDocument.svg'
import Stethoscope from 'assets/images/nav/Stethoscope.svg'
import AcceptedDocument from 'assets/images/nav/AcceptedDocument.svg'
import { IsRealDevice } from 'e-submission/utils'

const Aura = styled.div`
  width: 100%;
  text-align: center;
  img,
  svg {
    margin-bottom: 0.5rem;
  }
  iframe {
    width: 100%;
    height: 1024px;
  }
`

export const getFlagRiderHavePayer = (applicationInfo) => {
  const riderObj = _.get(applicationInfo, 'quickQuote.coveragePlan.riders')
  let flagRiderHavePayer = false
  const riderHavePayer = ['WPD01', 'WPDD01']
  const riderCodeHavePayer = ['PBPDD', 'PBPDDCI', 'PBSDD', 'PBSDDCI']
  if (!_.isEmpty(riderObj)) {
    for (var key in riderObj) {
      if (_.get(riderObj, `${key}.isSelectable`, false) && _.get(riderObj, `${key}.isSelected`)) {
        const riderName = _.get(riderObj, `${key}.name`, '')
        const riderCode = _.get(riderObj, `${key}.code`, '')
        flagRiderHavePayer =
          _.indexOf(riderHavePayer, riderName) >= 0 || _.indexOf(riderCodeHavePayer, riderCode) >= 0
            ? true
            : flagRiderHavePayer
      }
    }
  }
  return flagRiderHavePayer
}

const Component = ({ auraImpairment, toSetAuraFrameRef }) => {
  const iFrameURL = encodeURI(getAppConfig().AURA_INTEGATION_URL)

  const displayAura = () => {
    if (!IsRealDevice) {
      return (
        <div>
          <iframe src={iFrameURL} width="100%" frameBorder="0" />
        </div>
      )
    }

    return (
      <div>
        <FormSection>
          <FormContent>
            <Flex>
              <Box w={1}>ระบบการพิจารณารับประกันสุขภาพเบื้องต้น</Box>
            </Flex>
          </FormContent>
        </FormSection>
        <FormSection>
          <FormContent>
            <Flex>
              <Box w={1}>
                <Button
                  color="primary"
                  onClick={(e) => {
                    e.preventDefault()
                    const ref = window.cordova.InAppBrowser.open(
                      iFrameURL,
                      '_blank',
                      'location=no,disallowoverscroll=yes'
                    )
                    toSetAuraFrameRef(ref)
                  }}
                >
                  เข้าสู่ระบบ
                </Button>
              </Box>
            </Flex>
          </FormContent>
        </FormSection>
      </div>
    )
  }

  const displayCompleteMessage = () => {
    let objMessageConfirm = null

    switch (auraImpairment) {
      case 'ACC':
        objMessageConfirm = (
          <FormContent>
            <img src={AcceptedDocument} alt="img-nav" />
            <FormContent>
              ขอแสดงความยินดีที่ท่านได้ทำการตอบแบบสอบถามเสร็จเรียบร้อย บริษัทฯ ขอเรียนให้ท่านทราบว่า
              การสมัครขอทำประกันของท่านในครั้งนี้
              ได้ผ่านการพิจารณารับประกันโดยระบบการพิจารณารับประกันของบริษัทภายใต้ข้อมูลสุขภาพที่ท่านได้แถลงไว้ในครั้งนี้เป็นที่เรียบร้อยแล้ว
              <br />
              <br />
              ขอขอบคุณท่านเป็นอย่างสูงที่ได้ให้ความไว้วางใจในการสมัครทำประกันชีวิตกับ บริษัท กรุงไทย-แอกซ่า ประกันชีวิต
              จำกัด (มหาชน)
              <br />
              <br />
              <br />
              หมายเหตุ : การตอบรับครั้งนี้ เป็นเพียงการตอบรับการสมัครขอทำประกันชีวิตกับบริษัท กรุงไทย-แอกซ่า ประกันชีวิต
              จำกัด (มหาชน) เท่านั้น ความคุ้มครองตามสัญญาประกันภัยจะเริ่มต้นเมื่อบริษัท ฯ
              ได้รับชำระเบี้ยประกันภัยและตกลงรับประกันอย่างเป็นทางการแล้วเท่านั้น
            </FormContent>
          </FormContent>
        )
        break
      case 'RUW':
        objMessageConfirm = (
          <FormContent>
            <img src={Stethoscope} alt="img-nav" />
            <FormContent>
              ขอบคุณที่ท่านได้ให้ความไว้วางใจเลือกสมัครทำประกันชีวิตกับบริษัท กรุงไทย-แอกซ่า ประกันชีวิต จำกัด (มหาชน)
              บริษัท ฯ ได้รับใบคำขอเอาประกันภัยของท่านและนำส่งให้แผนกพิจารณารับประกันเป็นที่เรียบร้อยแล้ว
              <br />
              <br />
              ทั้งนี้ ขอเรียนให้ท่านทราบเพิ่มเติมว่า ท่านอาจได้รับการติดต่อจากบริษัท ฯ
              อีกครั้งเพื่อขอข้อมูลเพิ่มเติมสำหรับประกอบการพิจารณารับประกันภัยของท่านในครั้งนี้
            </FormContent>
          </FormContent>
        )
        break
      case 'EVR':
        objMessageConfirm = (
          <FormContent>
            <img src={AddDocument} alt="img-nav" />
            <FormContent>
              ขอบคุณที่ท่านได้ให้ความไว้วางใจเลือกสมัครทำประกันชีวิตกับบริษัท กรุงไทย-แอกซ่า ประกันชีวิต จำกัด (มหาชน)
              บริษัท ฯ ได้รับใบคำขอเอาประกันภัยของท่านและนำส่งให้แผนกพิจารณารับประกันเป็นที่เรียบร้อยแล้ว
              เพื่อให้ท่านได้รับการพิจารณารับประกันโดยเร็วยิ่งขึ้น บริษัท ฯ
              ใคร่ขอความอนุเคราะห์ให้ท่านนำส่งเอกสารเพิ่มเติมประกอบการพิจารณา
              <br />
              <br />
              ทั้งนี้ ขอเรียนให้ท่านทราบเพิ่มเติมว่า ท่านอาจได้รับการติดต่อจากบริษัท ฯ
              อีกครั้งเพื่อขอข้อมูลเพิ่มเติมสำหรับประกอบการพิจารณารับประกันภัยของท่านในครั้งนี้
            </FormContent>
          </FormContent>
        )
        break
      default:
        objMessageConfirm = (
          <FormContent>
            <img src={AddDocument} alt="img-nav" />
            <FormContent>
              ขอบคุณที่ท่านได้ให้ความไว้วางใจเลือกสมัครทำประกันชีวิตกับบริษัท กรุงไทย-แอกซ่า ประกันชีวิต จำกัด (มหาชน)
              บริษัท ฯ ได้รับใบคำขอเอาประกันภัยของท่านและนำส่งให้แผนกพิจารณารับประกันเป็นที่เรียบร้อยแล้ว
              เพื่อให้ท่านได้รับการพิจารณารับประกันโดยเร็วยิ่งขึ้น บริษัท ฯ
              ใคร่ขอความอนุเคราะห์ให้ท่านนำส่งเอกสารเพิ่มเติมประกอบการพิจารณา
              <br />
              <br />
              ทั้งนี้ ขอเรียนให้ท่านทราบเพิ่มเติมว่า ท่านอาจได้รับการติดต่อจากบริษัท ฯ
              อีกครั้งเพื่อขอข้อมูลเพิ่มเติมสำหรับประกอบการพิจารณารับประกันภัยของท่านในครั้งนี้
            </FormContent>
          </FormContent>
        )
    }

    return (
      <FormSection>
        <FormContent>{objMessageConfirm}</FormContent>
      </FormSection>
    )
  }

  return (
    <Aura className="aura-container">
      <AuraValidation />
      {!auraImpairment ? displayAura() : displayCompleteMessage()}
    </Aura>
  )
}

const mapStateToProps = (state, ownProps) => {
  const currentAppId = getCurrentAppId(state)
  const currentData = _.get(state, `apps.byId.${currentAppId}`, {})

  const insuredPayer = _.get(currentData, 'quickQuote.insured.payer', {})
  const isPayerNeeded = _.get(currentData, 'quickQuote.insured.payerNeeded', '')
  const flagRiderHavePayer = getFlagRiderHavePayer(currentData)
  const hasPayerSession = flagRiderHavePayer && isPayerNeeded && insuredPayer

  const insuredSessionId = _.get(currentData, 'healthSession.sessionId', '')
  const payerSessionId = _.get(currentData, 'healthSession.payerSessionId', '')
  const accessToken = _.get(ownProps, 'access_token')

  return {
    insuredSessionId,
    payerSessionId,
    hasPayerSession,
    predefineData: getPredefineData(currentData, insuredSessionId, payerSessionId, hasPayerSession, accessToken),
  }
}

const mapDispatchToProps = (dispatch) => ({
  saveDataAura: (auraOutput) => {
    dispatch(saveDataAuraToMiddelware(auraOutput))
  },
  setHealthSession: (session) => {
    dispatch(setDefaultAppValue('healthSession', session))
  },
})

export default (hoc) =>
  compose(
    withState('auraFrameRef', 'setAuraFrameRef', undefined),
    withState('isPredefined', 'setIsPredefined', false),
    withHandlers({
      toSetAuraFrameRef: (props) => (value) => {
        props.setAuraFrameRef(value)
      },
      toSetIsPredefined: (props) => (value) => {
        props.setIsPredefined(value)
      },
    }),
    connect(mapStateToProps, mapDispatchToProps),
    lifecycle({
      componentDidMount() {
        const frame = document.getElementsByTagName('iframe')[0] || undefined
        if (!IsRealDevice) {
          frame?.addEventListener('load', () => {
            //  NOTE: section postMessage wait after aura iframe render finish
            setTimeout(() => {
              frame?.contentWindow.postMessage(
                {
                  event_id: 'predefineData',
                  value: this.props.predefineData,
                },
                '*'
              )
            }, 3000)
          })
        }
        window.addEventListener('message', (event) => {
          if (event.data.event_id === 'submitAura') {
            this.props.saveDataAura(event.data?.value)
          }
        })
      },
      UNSAFE_componentWillReceiveProps(nextProps) {
        if (!nextProps.insuredSessionId && !nextProps.auraImpairment) {
          const date = new Date()

          let healthSession = {
            sessionId: genaratedTransRefGUID('insured'),
            updateAt: date,
          }

          if (!nextProps.payerSessionId && nextProps.hasPayerSession) {
            healthSession = {
              ...healthSession,
              payerSessionId: genaratedTransRefGUID('payer'),
              payerUpdateAt: date,
            }
          }

          nextProps.setHealthSession(healthSession)
        }
      },
      componentDidUpdate() {
        if (this.props?.auraFrameRef) {
          const auraPage = this.props?.auraFrameRef
          const data = {
            event_id: 'predefineData',
            value: this.props.predefineData,
          }
          let loop

          auraPage.addEventListener('loadstop', (event) => {
            try {
              // NOTE: Predefined Data
              if (this.props.isPredefined === false) {
                this.props.toSetIsPredefined(true)

                const sendPredefineData = (auraPageRef) => {
                  auraPageRef.executeScript({
                    code: `window.postMessage(${JSON.stringify(data)}, '*');`,
                  })
                }
                setTimeout(() => sendPredefineData(auraPage), 3000)
              }

              // NOTE: Interval for aura result
              const auraInterval = (refPage) => {
                const iabResolveExecuteScript = (code) => {
                  return new Promise((resolve) => {
                    refPage.executeScript({ code: code }, (value) => {
                      resolve(value)
                    })
                  })
                }

                refPage.executeScript({ code: 'document.getElementById("aura-length")?.value' }, async (value) => {
                  if (!_.isEmpty(value) && !_.isNil(value[0])) {
                    const dataLength = JSON.parse(value)
                    let auraData = ''

                    for (let i = 0; i < dataLength; i++) {
                      let value = await iabResolveExecuteScript(`document?.getElementById("aura-result-${i}")?.value`)

                      if (!_.isEmpty(value) && !_.isNil(value[0])) {
                        auraData = `${auraData}${value[0]}`
                      }

                      if (i === dataLength - 1) {
                        const auraResult = JSON.parse(auraData)
                        this.props.saveDataAura(auraResult.value)
                        auraPage.close()
                        clearInterval(loop)
                        loop = 'stop loop'
                      }
                    }
                  }
                })
              }

              if (!loop) {
                loop = setInterval(auraInterval, 5000, auraPage)
              }
            } catch (e) {
              clearInterval(loop)
              loop = 'stop loop'
              console.log('error', e)
            }
          })

          auraPage.addEventListener('exit', () => {
            this.props.toSetIsPredefined(false)
            clearInterval(loop)
            loop = 'stop loop'
          })
        }
      },
    }),
    hoc
  )(Component)
