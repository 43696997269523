import styled from 'styled-components'

const Container = styled.div`
  margin: 0.5rem 1rem;
  @media (max-width: 390px) {
    margin: 0;
  }
`
export const InfoTooltip = ({ children }) => (
  <Container className="info-message attached-icon form-control-feedback">{children}</Container>
)
