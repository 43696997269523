// @flow

import type { DisplayProduct } from 'core/service/display-product'
import { render as renderProductInformationHeader } from './components/product-information-header'
import template from '../common/template'
import type { ProtectionProps } from './types'

const productInformationHeader = (basicPlan: DisplayProduct, selectedPlanCode: string, redLine: string) =>
  renderProductInformationHeader(basicPlan, selectedPlanCode, redLine)

const protectionTemplate = async (props: ProtectionProps) => {
  const { basicPlan, selectedPlanCode, redLine } = props
  const header = productInformationHeader(basicPlan, selectedPlanCode, redLine)
  return template(props, header)
}

export default protectionTemplate
