// @flow
import { createSelector } from 'reselect'
import { get } from 'lodash/fp'
import _ from 'lodash'
import { canProceedToCoveragePlan } from 'quick-quote/selectors/next-tab-proceeding'
import { getUiVisited } from 'quick-quote/ui/selectors'
import {
  getSelectedBasicCategory,
  getSelectedDisplayProduct,
  getIsProductValidated,
  productWasSelected,
} from 'quick-quote/product-selection/selectors'
import { getFnaReasonToSelectProduct } from 'quick-quote/fna/selectors'
import VALUES from 'core/data-model/constants/values'

import { getFna, getFnaConfiguration } from '../selectors'
import { getSelectedDisplayProductCode } from 'quick-quote/product-selection/selectors'

import type { Category } from 'core/data-model/basic-plan'
import type { Reason, Questionnaire } from 'core/data-model/fna/types'
import { getSelectedNeedType, getStartSaleFrom } from '../need-and-gap-analysis/selectors'
import { PATH } from '../../constants/path'
import { getToggles } from 'quick-quote/feature-toggles'

const createReasonGetter = createSelector(getFnaReasonToSelectProduct, (options): Function => (id: string): ?Reason =>
  _.find(options, (option) => option.id === id)
)

export const getMarketConduct = createSelector(getFna, get('marketConduct'))
export const getMarketConductReason = createSelector(getMarketConduct, get('reason'))
export const getMarketConductDefaultReason = createSelector(
  getSelectedNeedType,
  getFnaReasonToSelectProduct,
  createReasonGetter,
  (need, reasons, getReason): ?Reason => getReason(need)
)
export const getMarketConductDefaultReasonValue = createSelector(getMarketConductDefaultReason, (reason): ?string =>
  reason ? reason.value : null
)
export const getMarketConductReasonOther = createSelector(getMarketConduct, get('reasonOthers'))
export const getMarketConductQuestion = createSelector(getMarketConduct, get('questions'))
export const getRelationship = createSelector(getFnaConfiguration, get('relationship'))
export const getTitle = createSelector(getFnaConfiguration, get('title'))

export const getMarketConductInsuranceQuestion = createSelector(
  getFnaConfiguration,
  get('marketConductsQuestionnaires.insuranceQuestion')
)
export const getMarketConductRiskQuestion = createSelector(
  getFnaConfiguration,
  get('marketConductsQuestionnaires.riskQuestion')
)

export const getMarketConductInsuranceQuestionWithPhaseConfig = createSelector(
  getMarketConductInsuranceQuestion,
  (questions) => {
    return questions.filter((item: Questionnaire) =>
      getToggles().ENABLE_FNA_PHASE2_MARKET_CONDUCT ? item._v === 2 : item._v === 1
    )
  }
)

export const getMarketConductInsuranceQuestionWithChoice = createSelector(
  getMarketConductInsuranceQuestionWithPhaseConfig,
  getMarketConductQuestion,
  (questions, choice) => {
    return questions.map((q) => ({ ...q, selectedChoice: get(q.id)(choice) }))
  }
)
export const getMarketConductRiskQuestionWithChoice = createSelector(
  getMarketConductRiskQuestion,
  getMarketConductQuestion,
  (questions, choice) => {
    return questions.map((q) => ({ ...q, selectedChoice: get(q.id)(choice) }))
  }
)

const checkRequiredQuestion = (questionnaires) => (q) => {
  if (!q.required) {
    return true
  } else {
    return _.toPairs(q.required).every(([k, v]) => {
      const targetQuestion = questionnaires.find((tq) => tq.id === k)
      if (targetQuestion.selectedChoice === v) {
        return true
      }
    })
  }
}

export const getTotalInsuranceQuestion = createSelector(
  getFnaConfiguration,
  get('marketConductsQuestionnaires.insuranceQuestion.length')
)
export const getTotalRequiredInsuranceQuestion = createSelector(
  getMarketConductInsuranceQuestionWithChoice,
  (questionnaires) => {
    return questionnaires.filter(checkRequiredQuestion(questionnaires)).length
  }
)
export const getTotalRiskQuestion = createSelector(
  getFnaConfiguration,
  get('marketConductsQuestionnaires.riskQuestion.length')
)

export const getTotalRequiredRiskQuestion = createSelector(getMarketConductRiskQuestion, (questionnaires) => {
  return questionnaires.filter(checkRequiredQuestion(questionnaires)).length
})

export const getTotalILPQuestionnaire = createSelector(
  getTotalInsuranceQuestion,
  getTotalRiskQuestion,
  (riskQuestionLength, insuranceQuestionLength): number => {
    return riskQuestionLength + insuranceQuestionLength
  }
)

export const getTotalRequiredILPQuestionnaire = createSelector(
  getTotalRequiredInsuranceQuestion,
  getTotalRequiredRiskQuestion,
  (riskQuestionLength, insuranceQuestionLength): number => riskQuestionLength + insuranceQuestionLength
)

export const isILPProduct = createSelector(getSelectedBasicCategory, (productCategory): boolean =>
  productCategory === VALUES.INVESTMENT ? true : false
)

export const isSelectedSavingAndInvestmentNeed = createSelector(getSelectedNeedType, (need) => {
  return need === 'SAVING_AND_INVESTMENT'
})

export const isNonILPNeedWithInvestmentProduct = createSelector(
  getSelectedNeedType,
  getSelectedDisplayProduct,
  (need, selectedProduct) => {
    const selectedProductCategory = get('category')(selectedProduct)
    return need !== 'SAVING_AND_INVESTMENT' && selectedProductCategory === 'INVESTMENT'
  }
)

export const isSelectedThirdPartyJoin = createSelector(getMarketConductQuestion, (questions): boolean => {
  const isVenerableClient = get('isVenerableClient')(questions)
  return isVenerableClient === VALUES.FNA_IS_VENERABLE_CLIENT ? true : false
})

export const totalAnswerInsurance = createSelector(
  getMarketConductInsuranceQuestionWithChoice,
  (insuranceQuestion): number => {
    const insuranceChoice = _.filter(insuranceQuestion, (q) => q.selectedChoice != undefined)
    return insuranceChoice.length
  }
)

export const totalAnswerILP = createSelector(
  getMarketConductRiskQuestionWithChoice,
  totalAnswerInsurance,
  (riskQuestion, answerInsuranceQuestion): number => {
    const riskQuestionChoice = _.filter(riskQuestion, (q) => q.selectedChoice != undefined)
    return riskQuestionChoice.length + answerInsuranceQuestion
  }
)

export const getMarketConductQuestionsTypeBySelectedNeed = createSelector(getSelectedNeedType, (need) => {
  switch (need) {
    case 'SAVING_AND_INVESTMENT':
      return VALUES.MARKET_CONDUCT_QUESTIONS_TYPES.ILP
    default:
      return VALUES.MARKET_CONDUCT_QUESTIONS_TYPES.NON_ILP
  }
})

export const getMarketConductQuestionsTypeBySelectedProduct = createSelector(
  getSelectedBasicCategory,
  (productCategory: Category) => {
    switch (productCategory) {
      case VALUES.INVESTMENT:
        return VALUES.MARKET_CONDUCT_QUESTIONS_TYPES.ILP
      default:
        return VALUES.MARKET_CONDUCT_QUESTIONS_TYPES.NON_ILP
    }
  }
)

export const isSelectedNeverSplittingPremiumFromExpense = createSelector(
  getMarketConductQuestion,
  (questions): boolean => {
    const splittingPremiumFromExpense = get('splittingPremiumFromExpense')(questions)
    return splittingPremiumFromExpense === VALUES.FNA_NEVER_SPLITTING_PREMIUM_FROM_EXPENSE ? true : false
  }
)

export const getCurrentMarketConductQuestionsType = createSelector(getMarketConduct, get('questionsType'))
export const getReasonForNeverSplittingPremiumFromExpense = createSelector(
  getMarketConduct,
  get('questions.reasonSplittingPremiumFromExpense')
)
export const getThirdPersonFullName = createSelector(getMarketConduct, get('thirdPerson.fullName'))
export const getThirdPersonRelationship = createSelector(getMarketConduct, get('thirdPerson.relationship'))
export const getThirdPersonPhoneNumber = createSelector(getMarketConduct, get('thirdPerson.phoneNumber'))
export const getThirdPersonTitle = createSelector(getMarketConduct, get('thirdPerson.title.value'))
export const getThirdPersonTitleText = createSelector(getMarketConduct, get('thirdPerson.title.text'))
export const getThirdPersonFirstName = createSelector(getMarketConduct, get('thirdPerson.firstName'))
export const getThirdPersonLastName = createSelector(getMarketConduct, get('thirdPerson.lastName'))

export const shouldClearMarketConductForm = createSelector(
  getCurrentMarketConductQuestionsType,
  getMarketConductQuestionsTypeBySelectedProduct,
  (currentQuestionsType, selectedProductQuestionsType) => currentQuestionsType !== selectedProductQuestionsType
)

export const shouldClearMarketConductFormV2 = createSelector(
  getCurrentMarketConductQuestionsType,
  getMarketConductQuestionsTypeBySelectedNeed,
  (currentQuestionsType, selectedNeedQuestionsType) => currentQuestionsType !== selectedNeedQuestionsType
)

const getReasonOther = createSelector(getFnaReasonToSelectProduct, (options): ?Reason =>
  _.find(options, (option) => option.id === 'OTHER')
)

export const isReasonOthers = createSelector(
  getMarketConductReason,
  getReasonOther,
  (selectedReason, reasonOther): boolean => (reasonOther ? reasonOther.value === selectedReason : false)
)

export const isAnswerAllInsuranceQuestions = createSelector(
  getTotalRequiredInsuranceQuestion,
  totalAnswerInsurance,
  (total, answers) => answers >= total
)

export const isAnswerAllILPQuestions = createSelector(
  getTotalRequiredILPQuestionnaire,
  totalAnswerILP,
  (total, answers) => answers >= total
)

export const isAnswerThirdPerson = createSelector(
  getThirdPersonFullName,
  getThirdPersonRelationship,
  getThirdPersonPhoneNumber,
  getThirdPersonTitle,
  getThirdPersonTitleText,
  getThirdPersonFirstName,
  getThirdPersonLastName,
  (fullName, relationship, phone, title, titleText, firstName, lastName) => {
    if (getToggles().ENABLE_FNA_PHASE2_MARKET_CONDUCT)
      return relationship && phone && (title !== 'other' || (title && titleText)) && firstName && lastName
    else return fullName && relationship && phone
  }
)

export const isCompletedMarketConducts = createSelector(
  isILPProduct,
  isSelectedSavingAndInvestmentNeed,
  isSelectedThirdPartyJoin,
  isReasonOthers,
  isAnswerAllInsuranceQuestions,
  isAnswerAllILPQuestions,
  isAnswerThirdPerson,
  isSelectedNeverSplittingPremiumFromExpense,
  getReasonForNeverSplittingPremiumFromExpense,
  getSelectedDisplayProductCode,
  (
    ilpProduct,
    ilpNeed,
    needThirdParty,
    isNeedOtherReason,
    completedAnswerInsurace,
    completedAnswerILP,
    completedThirdPerson,
    isSelectedNever,
    getReasonForNever,
    selectedDisplayProductCode
  ) => {
    let isIlp = getToggles().ENABLE_FNA_V3 ? ilpNeed : ilpProduct
    if (getToggles().ENABLE_REVISE_FNA) {
      // non-ILP FNA with product investment should check all answer completed same as ILP FNA
      const hasSelectedDisplayProductCode = !_.isEmpty(selectedDisplayProductCode)
      isIlp = ilpNeed || (hasSelectedDisplayProductCode && ilpProduct)
    }
    const isCompletedAnswer = isIlp ? completedAnswerILP && completedAnswerInsurace : completedAnswerInsurace

    const isCompletedThirdParty = needThirdParty ? completedThirdPerson : true
    const isCompletedSplittingPremiumFromExpense =
      getToggles().ENABLE_FNA_PHASE2_MARKET_CONDUCT && isSelectedNever ? getReasonForNever : true
    return isCompletedAnswer && isCompletedThirdParty && isCompletedSplittingPremiumFromExpense
  }
)

export const hasVisitedInsuredInformationPage = createSelector(
  getUiVisited,
  (visitedPath) => !!Object.keys(visitedPath).find((key) => key.includes(PATH.INSURED_INFORMATION))
)

export const getIsSelectAndValidateProduct = createSelector(
  getStartSaleFrom,
  getIsProductValidated,
  productWasSelected,
  (startSaleFrom, isProductValidated, isProductSelected) => {
    return startSaleFrom === 'SF' ? isProductValidated && isProductSelected : isProductSelected
  }
)

export const _canProceedToInsuredInformation = (
  completedMarketConduct,
  ableToCoverage,
  hasVisitedInsuredPageBefore,
  isSelectAndValidateProduct
) => {
  if (getToggles().ENABLE_REVISE_FNA) {
    return !!((completedMarketConduct && isSelectAndValidateProduct) || ableToCoverage || hasVisitedInsuredPageBefore)
  }

  return !!(completedMarketConduct || ableToCoverage || hasVisitedInsuredPageBefore)
}

export const canProceedToInsuredInformation = createSelector(
  isCompletedMarketConducts,
  canProceedToCoveragePlan,
  hasVisitedInsuredInformationPage,
  getIsSelectAndValidateProduct,
  _canProceedToInsuredInformation
)
