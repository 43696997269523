//@flow
import type { PermissionResult } from 'core/service/distributor/permission'
import MESSAGES from 'core/data-model/constants/messages'

import { FormFeedback, FormGroup } from 'reactstrap'

export const PermissionDenyErrorBox = ({ permissionResult }: { permissionResult: PermissionResult }) => {
  switch (permissionResult.type) {
    case 'INVALID_LICENSES':
      return <ErrorMessageBox message={MESSAGES.INVALID_LICENSES_ERROR_MSG} />
    case 'INACTIVE_USER':
      return <ErrorMessageBox message={MESSAGES.INACTIVE_USER_ERROR_MSG} />
    case 'NON_VERIFY_USER':
      return <ErrorMessageBox message={MESSAGES.NON_VERIFY_USER_ERROR_MSG} />
    case 'INVALID_LICENSES_INVESTMENT':
      return <ErrorMessageBox message={MESSAGES.INVALIED_LICENSES_INVESTMENT_ERROR_MSG} />
    case 'INVALID_RIGHT_LICENSES':
      return <ErrorMessageBox message={MESSAGES.INVALID_RIGHT_LICENSES_ERROR_MSG} />
    case 'VALID':
    case 'NOT_VALIDATED':
    default:
      return null
  }
}

const ErrorMessageBox = ({ message }: { message: string }) => (
  <FormGroup color="danger" className="has-danger">
    <FormFeedback className={'validation-message attached-icon error-message'}>
      <p>{message}</p>
    </FormFeedback>
  </FormGroup>
)
