// @flow
import { FormGroup } from 'reactstrap'
import MESSAGES from 'core/data-model/constants/messages'
import { getToggles } from 'quick-quote/feature-toggles'

type Props = {
  pensionType: string,
  editPensionType: (string) => void,
}

export const PensionType = ({ pensionType, editPensionType }: Props) => {
  return (
    <FormGroup className="item" id="pension-type">
      <label>เลือกรับบำนาญแบบ</label>
      <select
        id="pension-type-select"
        onChange={(e) => editPensionType(e.target.value)}
        value={pensionType}
        className="form-control custom-select custom-select--pension"
      >
        <option value="">กรุณาระบุเลือกรับบำนาญแบบ</option>
        <option key="monthly" value="monthly">
          รายเดือน
        </option>
        <option key="annually" value="annually">
          รายปี
        </option>
      </select>
      {getToggles().ENABLE_PENSION_TYPE_INFO_MESSAGE ? (
        <div className="info-message attached-icon form-control-feedback" style={{ paddingLeft: '32px' }}>
          <p>{MESSAGES.PENSION_TYPE_INFO_MESSAGE}</p>
        </div>
      ) : (
        ''
      )}
    </FormGroup>
  )
}

export default PensionType
