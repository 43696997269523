// @flow

import type { FinancialCheckList, HealthCheckList } from 'core/data-model/loan'

export type Action = UpdateHealthCheckList | UpdateFinancialCheckList

export const UPDATE_HEALTH_CHECKLIST = 'UPDATE_HEALTH_CHECKLIST'
export type UpdateHealthCheckList = {
  type: 'UPDATE_HEALTH_CHECKLIST',
  payload: HealthCheckList[],
}
export const updateHealthCheckList = (healthCheckList: HealthCheckList[]): UpdateHealthCheckList => ({
  type: UPDATE_HEALTH_CHECKLIST,
  payload: healthCheckList,
})

export const UPDATE_FINANCIAL_CHECKLIST = 'UPDATE_FINANCIAL_CHECKLIST'
export type UpdateFinancialCheckList = {
  type: 'UPDATE_FINANCIAL_CHECKLIST',
  payload: FinancialCheckList[],
}
export const updateFinancialCheckList = (financialCheckList: FinancialCheckList[]): UpdateFinancialCheckList => ({
  type: UPDATE_FINANCIAL_CHECKLIST,
  payload: financialCheckList,
})
