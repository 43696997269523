//@flow
import _ from 'lodash'
import { formatNumber } from 'core/service/lib/number-format'
import MESSAGES from 'core/data-model/constants/bi-messages'
import CONSTANTS from 'core/data-model/constants/messages'
import type { PolicyValue, YearEndPolicyValue } from 'core/service/benefit-illustration'
import type { PolicyValueTableProps } from 'core/service/benefit-illustration/policy-value'

export const _breakLine = (text: string) => {
  const premiumPattern = /^(เบี้ยประกันภัย)(สัญญา.+)$/
  const deathBenefitPattern = /^(ความคุ้มครอง)(การเสียชีวิต)$/
  const surrenderCashPattern = /^(มูลค่าเวนคืน)(เงินสด\**)$/
  const savingWithCompany = /^(กรณีรับเป็น)(เงินสด)$/

  const patterns = [premiumPattern, deathBenefitPattern, surrenderCashPattern, savingWithCompany]

  // add space to trick pdfmake to do line wrapping for us
  const breakByFirstMatched = ([p, ...rest]) => (text.match(p) ? text.replace(p, '$1 $2') : breakByFirstMatched(rest))

  return breakByFirstMatched(patterns)
}

const disclaimerFirstTable12PL = {
  stack: [`${MESSAGES.NOTE}:`, MESSAGES.TWELVE_PL_POLICY_VALUE_NOTE_1, MESSAGES.TWELVE_PL_POLICY_VALUE_NOTE_3],
  style: 'disclaimer',
  pageBreak: '',
}

const disclaimerSecondTable12PL = {
  stack: [`${MESSAGES.NOTE}:`, MESSAGES.TWELVE_PL_BENEFIT_DISCLAIMER, MESSAGES.TWELVE_PL_POLICY_VALUE_NOTE_3],
  style: 'disclaimer',
  pageBreak: '',
}

const build12PLHeaderTable = () => {
  return [
    [
      { text: CONSTANTS.AGE, rowSpan: 3, style: 'tableHeader' },
      { text: MESSAGES.END_OF_THE_YEAR, rowSpan: 3, style: 'tableHeader' },
      {
        text: _breakLine(MESSAGES.CUMULATIVE_PREMIUM),
        rowSpan: 3,
        style: 'tableHeader',
      },
      { text: MESSAGES.PREMIUM_PER_YEAR, colSpan: 3, style: 'tableHeader' },
      { text: '' },
      { text: '' },
      {
        text: MESSAGES.TWELVE_PL_GUARANTEE_CASHDROP.replace(/ \(/g, '\n('),
        colSpan: 2,
        style: 'tableHeader',
      },
      { text: '' },
      { text: MESSAGES.DEATH_BENEFIT, rowSpan: 3, style: 'tableHeader' },
      {
        text: MESSAGES.TWELVE_PL_DEATH_BENEFIT_COVERAGE,
        rowSpan: 3,
        style: 'tableHeader',
      },
      {
        text: MESSAGES.TWELVE_PL_SURRENDER_CASH.replace(/ \(/g, '\n('),
        rowSpan: 3,
        style: 'tableHeader',
      },
    ],
    [
      '',
      '',
      '',
      { text: MESSAGES.BASIC_PLAN, rowSpan: 2, style: 'tableHeader' },
      { text: MESSAGES.RIDER, rowSpan: 2, style: 'tableHeader' },
      { text: MESSAGES.TOTAL, rowSpan: 2, style: 'tableHeader' },
      {
        text: MESSAGES.INCASE_OF_CASH_BACK,
        rowSpan: 2,
        style: 'tableHeader',
      },
      {
        text: MESSAGES.TWELVE_PL_INCASE_OF_CUMULATIVE,
        rowSpan: 2,
        style: 'tableHeader',
      },
      '',
      '',
      '',
    ],
    ['', '', '', '', '', '', '', '', '', '', ''],
  ]
}

const build12PLFirstTotalPolicyValueRow = (policyValue: PolicyValue) => [
  '',
  '',
  '',
  {
    text: policyValue.subtotalAnnualBasicPremium === 0 ? '-' : formatNumber(policyValue.subtotalAnnualBasicPremium, 2),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  {
    text: policyValue.subtotalAnnualRiderPremium === 0 ? '-' : formatNumber(policyValue.subtotalAnnualRiderPremium, 2),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  {
    text: policyValue.grandTotalPremium === 0 ? '-' : formatNumber(policyValue.grandTotalPremium, 2),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  {
    text:
      // $$FlowFixMe
      policyValue.subtotalGuaranteeDividend === 0
        ? '-'
        : formatNumber(_.get(policyValue, 'subtotalGuaranteeDividend'), 0, true),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  {
    text:
      // $$FlowFixMe
      policyValue.subTotalNonGuaranteeDividendDeposit === 0
        ? '-'
        : formatNumber(_.get(policyValue, 'subTotalNonGuaranteeDividendDeposit'), 0, true),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  '',
  '',
  '',
]

const build12PLSecondTotalPolicyValueRow = (policyValue: PolicyValue) => [
  '',
  '',
  {
    text: policyValue.subTotalLowInterest === 0 ? '-' : formatNumber(policyValue.subTotalLowInterest || 0, 0, true),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  {
    text:
      policyValue.subTotalLowInterestDeposit === 0
        ? '-'
        : formatNumber(policyValue.subTotalLowInterestDeposit || 0, 0, true),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  {
    text: policyValue.subTotalMidInterest === 0 ? '-' : formatNumber(policyValue.subTotalMidInterest || 0, 0, true),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  {
    text:
      policyValue.subTotalMidInterestDeposit === 0
        ? '-'
        : formatNumber(policyValue.subTotalMidInterestDeposit || 0, 0, true),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  {
    text: policyValue.subTotalHighInterest === 0 ? '-' : formatNumber(policyValue.subTotalHighInterest || 0, 0, true),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  {
    text:
      policyValue.subTotalHighInterestDeposit === 0
        ? '-'
        : formatNumber(policyValue.subTotalHighInterestDeposit || 0, 0, true),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  {
    text: policyValue.subTotalLowDividend === 0 ? '-' : formatNumber(policyValue.subTotalLowDividend || 0, 0, true),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  {
    text: policyValue.subTotalMidDividend === 0 ? '-' : formatNumber(policyValue.subTotalMidDividend || 0, 0, true),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  {
    text: policyValue.subTotalHighDividend === 0 ? '-' : formatNumber(policyValue.subTotalHighDividend || 0, 0, true),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
]

const buildSecond12PLHeaderTable = () => {
  return [
    [
      { text: CONSTANTS.AGE, rowSpan: 5, style: 'tableHeader' },
      { text: MESSAGES.END_OF_THE_YEAR, rowSpan: 5, style: 'tableHeader' },
      { text: MESSAGES.TWELVE_PL_DIVIDEND, colSpan: 6, style: 'tableHeader' },
      { text: '' },
      { text: '' },
      { text: '' },
      { text: '' },
      { text: '' },
      {
        text: MESSAGES.TWELVE_PL_GUARANTEE_CASHDROP_WITH_DIVIDEND,
        rowSpan: 4,
        colSpan: 3,
        style: 'tableHeader',
      },
      { text: '' },
      { text: '' },
    ],
    [
      '',
      '',
      {
        text: MESSAGES.EXAMPLE_1,
        rowSpan: 2,
        colSpan: 2,
        style: 'tableHeader',
      },
      '',
      {
        text: MESSAGES.EXAMPLE_2,
        rowSpan: 2,
        colSpan: 2,
        style: 'tableHeader',
      },
      '',
      {
        text: MESSAGES.EXAMPLE_3,
        rowSpan: 2,
        colSpan: 2,
        style: 'tableHeader',
      },
      '',
      '',
      '',
      '',
    ],
    ['', '', '', '', '', '', '', '', '', '', ''],
    [
      '',
      '',
      {
        text: MESSAGES.INCASE_OF_CASH_BACK,
        rowSpan: 2,
        style: 'tableHeader',
      },
      {
        text: MESSAGES.TWELVE_PL_INCASE_OF_CUMULATIVE,
        rowSpan: 2,
        style: 'tableHeader',
      },
      {
        text: MESSAGES.INCASE_OF_CASH_BACK,
        rowSpan: 2,
        style: 'tableHeader',
      },
      {
        text: MESSAGES.TWELVE_PL_INCASE_OF_CUMULATIVE,
        rowSpan: 2,
        style: 'tableHeader',
      },
      {
        text: MESSAGES.INCASE_OF_CASH_BACK,
        rowSpan: 2,
        style: 'tableHeader',
      },
      {
        text: MESSAGES.TWELVE_PL_INCASE_OF_CUMULATIVE,
        rowSpan: 2,
        style: 'tableHeader',
      },

      '',
      '',
      '',
    ],
    [
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      { text: MESSAGES.EXAMPLE_1, style: 'tableHeader' },
      { text: MESSAGES.EXAMPLE_2, style: 'tableHeader' },
      { text: MESSAGES.EXAMPLE_3, style: 'tableHeader' },
    ],
  ]
}

const rowHeightConfig = { lineHeight: 0.75, margin: [0, -3, 0, 0] }

const build12PLPolicyValueRow = (yearEndPolicy: YearEndPolicyValue) => [
  {
    text: yearEndPolicy.age.value - 1,
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.yearEndPolicy,
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.cumulativeBasicPremium === 0 ? '-' : formatNumber(yearEndPolicy.cumulativeBasicPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.annualBasicPremium === 0 ? '-' : formatNumber(yearEndPolicy.annualBasicPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.annualRiderPremium === 0 ? '-' : formatNumber(yearEndPolicy.annualRiderPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.annualTotalPremium === 0 ? '-' : formatNumber(yearEndPolicy.annualTotalPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.cashDrop === 0 ? '-' : formatNumber(yearEndPolicy.cashDrop || 0, 0, true),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.sumCashDrop === 0 ? '-' : formatNumber(yearEndPolicy.sumCashDrop || 0, 0, true),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.deathBenefit === 0 ? '-' : formatNumber(yearEndPolicy.deathBenefit, 0, true),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.totalBenefit === 0 ? '-' : formatNumber(yearEndPolicy.totalBenefit || 0, 0, true),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.surrenderCash === 0 ? '-' : formatNumber(yearEndPolicy.surrenderCash, 0, true),
    alignment: 'right',
    ...rowHeightConfig,
  },
]

const buildSecond12PLPolicyValueRow = (yearEndPolicy: YearEndPolicyValue) => [
  {
    text: yearEndPolicy.age.value - 1,
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.yearEndPolicy,
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.lowInterest === 0 ? '-' : formatNumber(yearEndPolicy.lowInterest || 0, 0, true),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.lowInterestDeposit === 0 ? '-' : formatNumber(yearEndPolicy.lowInterestDeposit || 0, 0, true),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.midInterest === 0 ? '-' : formatNumber(yearEndPolicy.midInterest || 0, 0, true),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.midInterestDeposit === 0 ? '-' : formatNumber(yearEndPolicy.midInterestDeposit || 0, 0, true),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.highInterest === 0 ? '-' : formatNumber(yearEndPolicy.highInterest || 0, 0, true),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.highInterestDeposit === 0 ? '-' : formatNumber(yearEndPolicy.highInterestDeposit || 0, 0, true),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.totalLowDividend === 0 ? '-' : formatNumber(yearEndPolicy.totalLowDividend || 0, 0, true),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.totalMidDividend === 0 ? '-' : formatNumber(yearEndPolicy.totalMidDividend || 0, 0, true),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.totalHighDividend === 0 ? '-' : formatNumber(yearEndPolicy.totalHighDividend || 0, 0, true),
    alignment: 'right',
    ...rowHeightConfig,
  },
]

const getDisclaimer = ({ policyValueTables, rowsPerPage, disclaimer, headerRow, rowDisclaimer }) => {
  const amountPage = policyValueTables.length ? policyValueTables.length : 1
  const maxofPage = rowsPerPage[amountPage - 1] + headerRow
  const rowsofPage = policyValueTables[amountPage - 1].table.body.length

  if (rowsofPage >= maxofPage - rowDisclaimer) {
    disclaimer.pageBreak = 'before'
  }
  return disclaimer
}

const buildFirst12PLPolicyValueTable = (policyValue: PolicyValue) => {
  const policyValueTables = []
  const rowsPerPage = [50, 55, 54]
  const pageAmount = Math.ceil(policyValue.yearEndPolicyValues.length / rowsPerPage[0])
  _.times(pageAmount, (currentPage) => {
    policyValueTables.push({
      table: {
        headerRows: 1,
        widths: [12, 12, 'auto', 40, 40, 40, '*', '*', '*', '*', '*'],
        body: [
          ...build12PLHeaderTable(),
          ...policyValue.yearEndPolicyValues
            .slice(
              currentPage * rowsPerPage[currentPage > 0 ? currentPage - 1 : 0],
              rowsPerPage[currentPage] * (currentPage + 1)
            )
            .map((p) => build12PLPolicyValueRow(p)),
        ],
      },
      layout: 'lightBorder',
      style: 'table25PL',
    })
  })

  _.last(policyValueTables).table.body.push(build12PLFirstTotalPolicyValueRow(policyValue))

  policyValueTables.push(
    getDisclaimer({
      policyValueTables,
      rowsPerPage,
      disclaimer: { ...disclaimerFirstTable12PL },
      headerRow: 7,
      rowDisclaimer: 13,
    })
  )

  return policyValueTables
}

const buildSecond12PLPolicyValueTable = (policyValue: PolicyValue) => {
  const policyValueTables = []
  const rowsPerPage = [54, 55, 54]
  const pageAmount = Math.ceil(policyValue.yearEndPolicyValues.length / rowsPerPage[0])
  _.times(pageAmount, (currentPage) => {
    policyValueTables.push({
      table: {
        headerRows: 1,
        widths: [12, 12, '*', '*', '*', '*', '*', '*', '*', '*', '*'],
        body: [
          ...buildSecond12PLHeaderTable(),
          ...policyValue.yearEndPolicyValues
            .slice(
              currentPage * rowsPerPage[currentPage > 0 ? currentPage - 1 : 0],
              rowsPerPage[currentPage] * (currentPage + 1)
            )
            .map((p) => buildSecond12PLPolicyValueRow(p)),
        ],
      },
      layout: 'lightBorder',
      style: 'table25PL',
    })
  })

  _.last(policyValueTables).table.body.push(build12PLSecondTotalPolicyValueRow(policyValue))

  policyValueTables.push(
    getDisclaimer({
      policyValueTables,
      rowsPerPage,
      disclaimer: { ...disclaimerSecondTable12PL },
      headerRow: 6,
      rowDisclaimer: 10,
    })
  )

  return policyValueTables
}

export const policyFirst12PLValueTableSection = ({ policyValue }: PolicyValueTableProps) => [
  { text: MESSAGES.POLICY_VALUE_TABLE, style: 'title', pageBreak: 'before' },
  ...buildFirst12PLPolicyValueTable(policyValue),
]

export const policySecond12PLValueTableSection = ({ policyValue }: PolicyValueTableProps) => [
  { text: '', style: 'title', pageBreak: 'before', marginTop: -8 },
  ...buildSecond12PLPolicyValueTable(policyValue),
]
