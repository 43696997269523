// @flow

import { formatNumber } from 'core/service/lib/number-format'
import type { YearEndPolicyValueMRTA } from 'core/service/benefit-illustration'

const dashedOutOrWithPrecision = (value: number, precision: number, isRound: boolean = true) =>
  value === 0 ? '-' : formatNumber(value, precision, isRound)

export const PolicyValueRowMRTA = ({
  yearEndPolicy,
  basicSumAssured,
  riderSumAssured,
  surrenderCash,
}: YearEndPolicyValueMRTA) => {
  return (
    <tr id={`policy-value-row-${yearEndPolicy}`}>
      <td className="policy-value-year-end-policy">{yearEndPolicy}</td>
      <td className="policy-value-basic-sum-assured">{dashedOutOrWithPrecision(basicSumAssured, 0)}</td>
      <td className="policy-value-rider-sum-assured">{dashedOutOrWithPrecision(riderSumAssured, 0)}</td>
      <td className="policy-value-surrender-cash">{dashedOutOrWithPrecision(surrenderCash, 0)}</td>
    </tr>
  )
}
export const PolicyValueRowGLTSP = ({ yearEndPolicy, basicSumAssured, surrenderCash }: YearEndPolicyValueMRTA) => {
  return (
    <tr id={`policy-value-row-${yearEndPolicy}`}>
      <td className="policy-value-year-end-policy">{yearEndPolicy}</td>
      <td className="policy-value-basic-sum-assured">{dashedOutOrWithPrecision(basicSumAssured, 0)}</td>
      <td className="policy-value-surrender-cash">{dashedOutOrWithPrecision(surrenderCash, 0)}</td>
    </tr>
  )
}
