// @flow

import type { FactorOfBasicSumAssuredRule } from 'core/data-model/rider/rules'
import type { RiderEligibilities } from 'core/data-model/rider'
import type { ValidationMessage } from 'core/data-model/validation'
import riderMessages from 'core/data-model/constants/validation-messages'

export const checkFactorOfSumAssuredRule = (
  rule: FactorOfBasicSumAssuredRule,
  eligibilities: RiderEligibilities
): ValidationMessage => {
  let errorMessage = ''

  if (
    eligibilities.basicSumAssured > 0 &&
    eligibilities.rider.sumAssured > eligibilities.basicSumAssured * rule.factor
  ) {
    errorMessage = riderMessages.RULE_MESSAGE_NOT_FOUND
  }
  return {
    type: rule.validationType,
    message: errorMessage,
  }
}
