// @flow
import _ from 'lodash'
import type { Effect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import type { DisplayProduct } from 'core/service/display-product'
import type { Age } from 'core/data-model/insured'
import { selectBasicPlan } from './actions'
import { SELECTED_PRODUCT } from 'quick-quote/product-selection/actions'
import VALUES from 'core/data-model/constants/values'
import DEFAULTS from 'core/data-model/constants/defaults'
import { UPDATE_BIRTHDATE } from 'quick-quote/insured-information/actions'
import { populateAvailableRiders } from 'quick-quote/product-common/coverage-plan/sagas'
import { getDisplayProducts, getSelectedDisplayProductCode } from 'quick-quote/product-selection/selectors'
import { isValidAge } from 'core/service/insured/birthdate'
import { getAge } from 'quick-quote/insured-information/selectors'
import { selectModelFactor } from 'quick-quote/product-flexi-health/coverage-plan/actions'

export const _getProductByAge = (products: DisplayProduct[], age: Age, productGroupCodes: string[]) => {
  const { value, unit } = age
  const productGroupFromDefault = _.filter(DEFAULTS.DEFAULT_IHEALTHY_ULTRA_PRODUCT_LIST, (product) =>
    _.includes(productGroupCodes, _.get(product, 'code'))
  )
  const productSelected = _.find(productGroupFromDefault, (product) =>
    isValidAge(value, unit, _.get(product, 'entryAge'))
  )
  return _.find(products, { code: _.get(productSelected, 'code') })
}

const _isiHealthyUltraLifeProtect18Group = (productCode: string) =>
  _.includes(VALUES.IHEALTHY_ULTRA_LIFE_PROTECT_18, productCode)
const _isiHealthyUltraLifeProtect10Group = (productCode: string) =>
  _.includes(VALUES.IHEALTHY_ULTRA_LIFE_PROTECT_10, productCode)
const _isiHealthyUltraLifePlusSaver20P10Group = (productCode: string) =>
  _.includes(VALUES.IHEALTHY_ULTRA_LIFE_SAVE_PRO_20P10, productCode)
const _isiHealthyUltraLifePlusSaver25P15Group = (productCode: string) =>
  _.includes(VALUES.IHEALTHY_ULTRA_LIFE_SAVE_PRO_25P15, productCode)

const _getProductGroupCode = (productCode: string) => {
  if (_isiHealthyUltraLifeProtect18Group(productCode)) {
    return VALUES.IHEALTHY_ULTRA_LIFE_PROTECT_18
  } else if (_isiHealthyUltraLifeProtect10Group(productCode)) {
    return VALUES.IHEALTHY_ULTRA_LIFE_PROTECT_10
  } else if (_isiHealthyUltraLifePlusSaver20P10Group(productCode)) {
    return VALUES.IHEALTHY_ULTRA_LIFE_SAVE_PRO_20P10
  } else if (_isiHealthyUltraLifePlusSaver25P15Group(productCode)) {
    return VALUES.IHEALTHY_ULTRA_LIFE_SAVE_PRO_25P15
  }
}

export const switchIHealthyProductByBirthDate = function*(): Generator<*, *, *> {
  const productCode = yield select(getSelectedDisplayProductCode)
  if (
    !_isiHealthyUltraLifeProtect18Group(productCode) &&
    !_isiHealthyUltraLifeProtect10Group(productCode) &&
    !_isiHealthyUltraLifePlusSaver20P10Group(productCode) &&
    !_isiHealthyUltraLifePlusSaver25P15Group(productCode)
  )
    return

  const age = yield select(getAge)
  const products = yield select(getDisplayProducts)
  const productGroupCodes = _getProductGroupCode(productCode)
  const productGroup = _.filter(products, (product) => _.includes(productGroupCodes, _.get(product, 'code')))
  const product = _getProductByAge(productGroup, age, productGroupCodes)
  const productSeniorGroupLifeSavePro = [
    VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_20_10.SENIOR,
    VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_25_15.SENIOR,
  ]
  if (product !== undefined && productCode !== _.get(product, 'code')) {
    yield put(selectBasicPlan(product))
    if (_.includes(productSeniorGroupLifeSavePro, _.get(product, 'code')))
      yield put(selectModelFactor('model-factor_1'))

    yield call(populateAvailableRiders)
  }
}

export function* watchers(): Generator<*, *, Effect[]> {
  yield [takeLatest([UPDATE_BIRTHDATE, SELECTED_PRODUCT], switchIHealthyProductByBirthDate)]
}

export const sagas = watchers
