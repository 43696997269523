// @flow
import * as React from 'react'
import { Modal, Checkbox } from 'common-components'
import { ModalHeader, ModalBody, ModalFooter, Button, FormGroup } from 'reactstrap'
import { withStateHandlers, compose } from 'recompose'
import MESSAGES from 'core/data-model/constants/messages'

type Props = {
  isModalOpen: boolean,
  toggleModal: (?Object) => void,
  submitAction: (*) => void,
  checked: boolean,
  onChecked: () => void,
}

export const InformationBranchModal = ({ isModalOpen, toggleModal, submitAction, checked, onChecked }: Props) => {
  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop={false}
        autoFocus={false}
        modalClassName="ltr-modal scrollable-modal fse-branch-modal"
      >
        <ModalHeader tag="div">
          <span className="text">{MESSAGES.INFORMATION_BRANCH_HEADER}</span>
        </ModalHeader>
        <ModalBody>
          <div className="content">
            <span>{MESSAGES.INFORMATION_BRANCH_BODY}</span>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="w-100">
            <FormGroup className="agreement-box">
              <Checkbox
                id={'info-branch-checkbox'}
                className="text-primary"
                checked={checked}
                onChange={() => onChecked()}
              >
                {MESSAGES.INFORMATION_BRANCH_CHECKED}
              </Checkbox>
            </FormGroup>
            <div className="bottom">
              <Button outline color="primary" className="done cancel" onClick={toggleModal}>
                {MESSAGES.INFORMATION_BRANCH_CANCEL_BUTTON}
              </Button>
              <Button
                className={!checked ? 'submit bg-secondary' : 'submit'}
                color={!checked ? 'secondary' : 'primary'}
                disabled={!checked}
                onClick={() => submitAction()}
              >
                {MESSAGES.INFORMATION_BRANCH_SUBMIT_BUTTON}
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default compose(
  withStateHandlers(
    ({ checked = false }) => ({
      checked,
    }),
    {
      onChecked: ({ checked }) => () => ({ checked: !checked }),
    }
  )
)(InformationBranchModal)
