// @flow
import type { DisplayProduct } from 'core/service/display-product'
import type { Category, Period } from 'core/data-model/basic-plan'
import type { AppState } from 'quick-quote/reducers'
import _ from 'lodash'
import { createSelector } from 'reselect'
import { isEmpty } from 'core/service/lib/type-check'
import values from 'core/data-model/constants/values'
import defaults from 'core/data-model/constants/defaults'
import { getToggles } from 'quick-quote/feature-toggles'
import { getUserAgentCode, getUserDistributorGroup } from '../../identity/selectors'
import { canProceedToProductSelection } from 'quick-quote/fna/need-and-gap-analysis/selectors'

export const getIsProductValidated = (state: AppState) => _.get(state, 'ui.isValidateProduct')

export const getDisplayProducts = (state: AppState): DisplayProduct[] => state.products.payload

export const getViewConfiguration = (state: AppState) => state.viewConfiguration

export const getDisplayProductsStatus = (state: AppState): string => state.products.status

export const getSelectedDisplayProductCode = (state: AppState): string => state.selectedDisplayProduct.code
export const getSelectedDisplayBasicPlanCode = (state: AppState): string => state.selectedDisplayProduct.basicPlanCode
export const getSelectedDisplayProductCategory = (state: AppState): string => state.selectedDisplayProduct.category

export const isIlinkProduct = (state: AppState): boolean => getSelectedDisplayProductCode(state) === 'RPUDR'

export const getSelectedProductCategoryId = (state: AppState): string => state.selectedProductCategoryId
export const getAvailableProductCategories = (state: AppState): string =>
  _.get(state, 'productSelection.availableProductCategories')
export const getAvailablePlanCode = (state: AppState): string => _.get(state, 'productSelection.availablePlanCode')

export const getSelectedDisplayProductQuery = (state: AppState) => ({
  // code: state.selectedDisplayProduct.code,
  code:
    state.selectedDisplayProduct.category === values.MRTA
      ? state.selectedDisplayProduct.basicPlanCode
      : state.selectedDisplayProduct.code,
  type: state.selectedDisplayProduct.type,
  selectedCampaignPlan: state.selectedDisplayProduct.selectedCampaignPlan,
  distributorGroup: getUserDistributorGroup(state),
  agentCode: getUserAgentCode(state) ? getUserAgentCode(state).padStart(6, '0') : '',
  productPlanCode: state.selectedDisplayProduct.code,
})

export const getSelectedDisplayProductValidOccupationFactors = (state: AppState) =>
  state.selectedDisplayProduct.validOccupationFactors || {}

export const getSelectedDisplayProductNameThai = (state: AppState): string => {
  return state.selectedDisplayProduct.nameThai || ''
}

export const canProceedToInsuredInformation = (state: AppState) => !isEmpty(state.selectedDisplayProduct.code)

export const getSelectedDisplayProduct = (state: AppState) => state.selectedDisplayProduct

export const isPerfectLifeSolution = (state: AppState) =>
  _.includes(values.PERFECT_LIFE, _.get(state, 'selectedDisplayProduct.code'))

export const isInvestmentCategory = (state: AppState) =>
  _.includes(values.INVESTMENT, _.get(state, 'selectedDisplayProduct.category'))

export const isBumnanReadyCategory = (state: AppState) =>
  _.includes(values.BUMNAN_READY, _.get(state, 'selectedDisplayProduct.category'))

export const customizeDisplay = (products: DisplayProduct[]): DisplayProduct[] => {
  const isHealthProduct = (product) => _.get(product, 'category') === values.HEALTH
  const isFlexiHealthProduct = (product) => _.get(product, 'category') === values.FLEXI_HEALTH
  const isHealthDefaultProduct = (product) => _.get(product, 'code') === defaults.DEFAULT_HEALTH_PRODUCT_CODE
  const isFlexiHealthDefaultProduct = (product) => _.get(product, 'code') === defaults.DEFAULT_FLEXI_HEALTH_PRODUCT_CODE
  const isHealthToppingDefaultProduct = (product) =>
    _.get(product, 'code') === defaults.DEFAULT_HEALTH_TOPPING_PRODUCT_CODE
  const isLifeSaveProProduct = (product) => _.get(product, 'category') === values.LIFE_SAVE_PRO
  const isLifeSaveProDefaultProduct = (product) =>
    _.includes(defaults.DEFAULT_LIFE_SAVE_PRO_PRODUCT_CODE, _.get(product, 'code'))
  const isLifeProtect18 = (product) => _.includes(values.LIFE_PROTECT_18, _.get(product, 'code'))
  const isLifeProtect18DefaultProduct = (product) =>
    _.get(product, 'code') === defaults.DEFAULT_LIFE_PROTECT_18_PRODUCT_CODE
  const isLifeProtect10 = (product) => _.includes(values.LIFE_PROTECT_10, _.get(product, 'code'))
  const isLifeProtect10DefaultProduct = (product) =>
    _.get(product, 'code') === defaults.DEFAULT_LIFE_PROTECT_10_PRODUCT_CODE
  const isS73Pay = (product) => _.includes(values.S7_3PAY, _.get(product, 'code'))
  const isS73payDefaultProduct = (product) => _.get(product, 'code') === defaults.DEFAULT_S7_3PAY_PRODUCT_CODE
  const isS7Single = (product) => _.includes(values.S7_SINGLE, _.get(product, 'code'))
  const isS7SingleDefaultProduct = (product) => _.get(product, 'code') === defaults.DEFAULT_S7_SINGLE_PRODUCT_CODE
  const isPerfectLife = (product) => _.includes(values.PERFECT_LIFE, _.get(product, 'code'))
  const isPerfectLifeDefaultProduct = (product) => _.get(product, 'code') === defaults.DEFAULT_PERFECT_LIFE_PRODUCT_CODE
  const isSukhapabDekDeeDefaultProduct = (product) =>
    _.get(product, 'code') === defaults.DEFAULT_SUKHAPABDEKDEE_PRODUCT_CODE
  const isHealthyUltraProduct = (product) => _.get(product, 'category') === values.IHEALTHY_ULTRA
  const isHealthyUltraDefaultProduct = (product) =>
    _.get(product, 'code') === defaults.DEFAULT_IHEALTHY_ULTRA_PRODUCT_CODE

  return _.filter(
    products,
    (product) =>
      (!isHealthProduct(product) &&
        !isFlexiHealthProduct(product) &&
        !isLifeSaveProProduct(product) &&
        !isLifeProtect18(product) &&
        !isLifeProtect10(product) &&
        !isPerfectLife(product) &&
        !isHealthyUltraProduct(product) &&
        !isS73Pay(product) &&
        !isS7Single(product)) ||
      isHealthDefaultProduct(product) ||
      isFlexiHealthDefaultProduct(product) ||
      isLifeSaveProDefaultProduct(product) ||
      isLifeProtect18DefaultProduct(product) ||
      isLifeProtect10DefaultProduct(product) ||
      isPerfectLifeDefaultProduct(product) ||
      isHealthToppingDefaultProduct(product) ||
      isSukhapabDekDeeDefaultProduct(product) ||
      isHealthyUltraDefaultProduct(product) ||
      isS73payDefaultProduct(product) ||
      isS7SingleDefaultProduct(product)
  )
}

export const filterProductsByCategory = (
  products: DisplayProduct[],
  // eslint-disable-next-line flowtype/no-weak-types
  productsInCategory: Object,
  categoryId: string,
  userAgentType: string
) => {
  if (!productsInCategory || !categoryId) return products

  const productCodesInCategory = productsInCategory[categoryId]
  const filterProducts = productCodesInCategory
    ? sortProductsInCategory(
        _.filter(products, (product) => {
          const isProductInCategory = productCodesInCategory.includes(_.get(product, 'code'))
          if (getToggles().ENABLE_SHOW_PRODUCT_LIST_BY_USER_TYPE) {
            const allowUserType = _.get(product, 'allowUserType')
            const isProductCorrectUserType = allowUserType.length > 0 ? allowUserType.includes(userAgentType) : true
            return isProductInCategory && isProductCorrectUserType ? true : false
          } else {
            return isProductInCategory
          }
        }),
        productCodesInCategory
      )
    : products

  return filterProducts
}

export const filterProductCategoryEmpty = (
  products: DisplayProduct[],
  categoryList: Object,
  productsInCategory: Object,
  userAgentType: string
) => {
  const categoryActive = _.filter(categoryList, (category) => {
    const filterProducts = filterProductsByCategory(products, productsInCategory, category.id, userAgentType)
    return filterProducts.length > 0 ? true : false
  })

  return categoryActive
}

const sortProductsInCategory = (products, productCodesInCategory) => {
  const sortedProducts = _.sortBy(products, [(product) => productCodesInCategory.indexOf(_.get(product, 'code'))])
  return sortedProducts
}
// eslint-disable-next-line flowtype/no-weak-types
export const getProductCategoryList = (viewConfiguration: any): any => viewConfiguration.productCategoryList
// eslint-disable-next-line flowtype/no-weak-types
export const getProductsInCategory = (viewConfiguration: any): any => viewConfiguration.productsInCategory

export const getSelectedDisplayProductName = createSelector(
  getSelectedDisplayProduct,
  (product: DisplayProduct): string => {
    return product.displayName
  }
)

export const getSelectedBasicCategory = createSelector(
  getSelectedDisplayProduct,
  (product: DisplayProduct): Category => product.category
)

export const getSelectedDisplayProductCoveragePeriod = createSelector(
  getSelectedDisplayProduct,
  (product: DisplayProduct): Period => product.coveragePeriod
)

export const getSelectedDisplayProductPaymentPeriod = createSelector(
  getSelectedDisplayProduct,
  (product: DisplayProduct): Period => product.paymentPeriod
)

export const productWasSelected = createSelector(
  getSelectedDisplayProductCode,
  (selectedDisplayProductCode) => !!selectedDisplayProductCode
)

export const canProceedToMarketConducts = createSelector(
  canProceedToProductSelection,
  productWasSelected,
  (ableToProceedToProductSelection, productWasSelected) => ableToProceedToProductSelection && productWasSelected
)
