//@flow
import type { Age, Gender, Insured } from 'core/data-model/insured'
import type { PremiumRate } from 'core/data-model/basic-plan'
import type { PaymentMethod } from 'core/data-model/loan/types'

import { dataWrapper } from 'core/data'
import { _lookUpBasicPremiumRate } from '../basic-plan/premium'
import { d } from 'core/service/lib/decimal'
import { roundDown, round } from 'core/service/lib/number-format'
import VALUE from 'core/data-model/constants/values'

export const _lookUpLoanPremiumRate = (
  premiumRates: PremiumRate[],
  productCode: string,
  planCode: string,
  gender: Gender,
  age: Age
): number => {
  return _lookUpBasicPremiumRate(premiumRates, `${productCode}_${planCode}`, gender, age)
}

export const lookUpLoanPremiumRate = (
  productCode: string,
  planCode: string,
  gender: Gender,
  age: Age
): Promise<number> => {
  return dataWrapper.getPremiumRates().then((premiumRates) => {
    return _lookUpLoanPremiumRate(premiumRates, productCode, planCode, gender, age)
  })
}

export const calculatePremiumForCashPaymentMethod = (
  sumAssured: number,
  premiumRate: number,
  discount: number
): number => {
  const sumAssuredDecimal = d(sumAssured)
  const premiumRateDecimal = d(premiumRate)
  const discountDecimal = d(discount)
  const discountFactor = d(1).minus(discountDecimal)
  const totalPremium = roundDown(premiumRateDecimal.times(discountFactor), 2)
  const rawPremium = sumAssuredDecimal.times(d(totalPremium))

  return round(rawPremium.dividedBy(d(1000)), 2)
}

export const calculatePremiumForLoanPaymentMethod = (sumAssured: number, premiumRate: number): number => {
  const premiumRateDecimal = d(premiumRate)
  const sumAssuredDecimal = d(sumAssured)
  const rawPremium = premiumRateDecimal.times(sumAssuredDecimal)
  return roundDown(rawPremium.dividedBy(d(1000)), 2)
}

export const convertToLoanPremiumRate = (
  productCode: string,
  cashPremiumRate: number,
  discount: number = 0
): number => {
  const cashPremiumRateDecimal = d(cashPremiumRate)

  const isIncludedDiscount = productCode !== VALUE.TMRTA

  const calculatedPremiumRate = isIncludedDiscount
    ? d(round(cashPremiumRateDecimal.times(1 - discount), 2))
    : cashPremiumRateDecimal

  const divider = d(1000).minus(calculatedPremiumRate)
  const rawLoanPremiumRate = calculatedPremiumRate.dividedBy(divider).times(1000)

  return round(rawLoanPremiumRate, 4)
}

export const _calculatePremium = (
  sumAssured: number,
  premiumRate: number,
  productCode: string,
  paymentMethod: PaymentMethod,
  props: {
    calculatePremiumForLoanPaymentMethod: Function,
    calculatePremiumForCashPaymentMethod: Function,
    convertToLoanPremiumRate: Function,
  }
): number => {
  const zeroDiscount = 0

  const { calculatePremiumForLoanPaymentMethod, calculatePremiumForCashPaymentMethod, convertToLoanPremiumRate } = props

  if (paymentMethod === VALUE.CASH) return calculatePremiumForCashPaymentMethod(sumAssured, premiumRate, zeroDiscount)

  const loanPremiumRate = convertToLoanPremiumRate(productCode, premiumRate, zeroDiscount)
  return calculatePremiumForLoanPaymentMethod(sumAssured, loanPremiumRate)
}

export const calculatePremium = async (props: {
  sumAssured: number,
  productCode: string,
  paymentMethod: PaymentMethod,
  planCode: string,
  insured: Insured,
}): Promise<number> => {
  const { sumAssured, productCode, paymentMethod, planCode, insured } = props

  const premiumRate = await lookUpLoanPremiumRate(productCode, planCode, insured.gender, insured.age)

  return _calculatePremium(sumAssured, premiumRate, productCode, paymentMethod, {
    calculatePremiumForCashPaymentMethod,
    convertToLoanPremiumRate,
    calculatePremiumForLoanPaymentMethod,
  })
}
