// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import type { BasicPremium$, SumAssured$ } from 'core/data-model/basic-plan'
import type { AppState } from 'quick-quote/reducers'

import { FormGroup } from 'reactstrap'
import { enableBasicSumAssured, isInvalid, ErrorFeedback } from 'common-components/view-logic'
import VALUES from 'core/data-model/constants/values'

import BasicSumAssured from './basic-sum-assured'
import BasicPremium from './basic-premium'
import BasicPlanErrorMessages from './basic-plan-error-messages'
import LoanAmount from './loan-amount'
import LoanPeriod from './loan-period'
import CoveragePeriod from './coverage-period'
import CoveragePeriodErrorMessages from './coverage-period-error-messages'
import PercentSumAssured from './percent-sum-assured'
import PercentCoveragePeriod from './percent-coverage-period'
import PaymentSelectedMRTA from './payment-selected-mrta'
import InterestRate from 'quick-quote/product-mrta/coverage-plan/components/interest-rate'
import InterestRateErrorMessages from 'quick-quote/product-mrta/coverage-plan/components/interest-rate-error-messages'

import {
  getSumAssured$,
  getBasicPremium$,
  getBasicPremiumState,
  getReadOnlyFields,
  haveBasicPremiumState,
} from 'quick-quote/product-common/coverage-plan/selectors'
import {
  getLoanPeriodAll,
  getCoveragePeriodErrors,
  getCoveragePeriodAll,
  getInterestRate,
  getPaymentMethod,
  isGLTSPProduct,
} from 'quick-quote/product-mrta/coverage-plan/selectors'

import { editBasicPremiumState, editSumAssured } from 'quick-quote/product-common/coverage-plan/actions'

import { editInterestRate } from 'quick-quote/product-mrta/actions'

type BasicPlanProps = {
  basicPremiumState: number,
  haveBasicPremiumState: boolean,
  sumAssured$: SumAssured$,
  basicPremium$: BasicPremium$,
  readOnlyFields: { [string]: boolean },
  coveragePeriodError: string[],
  loanPeriod$: Object,
  coveragePeriod$: Object,
  interestRate: number,
  isGLTSPProduct: boolean,
  loanPaymentMethod: string,
  editSumAssured: (number) => void,
  editBasicPremium: (number) => void,
  editInterestRate: (number) => void,
}

export const BasicPlanMRTA = ({
  basicPremiumState,
  haveBasicPremiumState,
  sumAssured$,
  basicPremium$,
  readOnlyFields,
  coveragePeriodError,
  loanPeriod$,
  coveragePeriod$,
  interestRate,
  isGLTSPProduct,
  loanPaymentMethod,
  editSumAssured,
  editBasicPremium,
  editInterestRate,
}: BasicPlanProps) => {
  const isInvalidState = isInvalid(haveBasicPremiumState, sumAssured$, basicPremium$)
  const isShowInterestRate = isGLTSPProduct && loanPaymentMethod === VALUES.CASH_INCLUDE_LOAN
  const isShowInfoCashIncludeLoan = loanPaymentMethod === VALUES.CASH_INCLUDE_LOAN

  return (
    <FormGroup className={`coverage-plan-calculation item basic ${isInvalidState ? 'has-danger' : ''}`}>
      <div className="sum-assured-input">
        <div className="form-group-title">
          <span className="form-group-title-text">ข้อมูลสัญญาเงินกู้ธนาคาร</span>
        </div>
        <div className="options option-labels">
          <label>จำนวนเงินกู้ตามสัญญาเงินกู้ธนาคาร</label>
          <label>ระยะเวลากู้ (ปี)</label>
        </div>
        <div className="options option-inputs">
          <div className="sum-assured">
            <LoanAmount />
          </div>
          <div className="link"> </div>
          <div className="premium">
            <LoanPeriod />
          </div>
        </div>
        <br />

        <div className="form-group-title">
          <span className="form-group-title-text">สัญญาหลัก</span>
        </div>
        <div className="options option-labels">
          <label>จำนวนเงินเอาประกันภัย</label>
          <label>เบี้ยสัญญาหลัก</label>
        </div>
        <div className="options option-inputs">
          <div className="sum-assured">
            <BasicSumAssured
              isReadOnly={readOnlyFields.basicSumAssured}
              sumAssured={sumAssured$.value}
              enabled={enableBasicSumAssured(haveBasicPremiumState, basicPremium$)}
              editSumAssured={editSumAssured}
            />
          </div>
          <div className="link">{haveBasicPremiumState ? '←' : '→'}</div>
          <div className="premium">
            <BasicPremium
              isReadOnly={readOnlyFields.basicPremium}
              basicPremium={haveBasicPremiumState ? basicPremiumState : basicPremium$.value}
              editBasicPremium={editBasicPremium}
            />
          </div>
        </div>
        <BasicPlanErrorMessages
          haveBasicPremiumState={haveBasicPremiumState}
          sumAssured$={sumAssured$}
          basicPremium$={basicPremium$}
        />
        <br />
        <div className="options option-labels">
          <label>ระยะเวลาคุ้มครอง (ปี)</label>
        </div>
        <div className="options option-inputs">
          {/* <div className="sum-assured"> */}
          <CoveragePeriod />
          {/* </div> */}
        </div>
        <CoveragePeriodErrorMessages haveBasicPremiumState={haveBasicPremiumState} coveragePeriod$={coveragePeriod$} />
        <br />
        <div className="options option-labels">
          <label>% จำนวนเงินเอาประกันภัยสัญญาหลัก</label>
          <label>% ระยะเวลาคุ้มครอง</label>
        </div>
        <div className="options option-inputs">
          <div className="sum-assured">
            <PercentSumAssured isReadOnly={true} />
          </div>
          <div className="link"> </div>
          <div className="premium">
            <PercentCoveragePeriod isReadOnly={true} />
          </div>
        </div>
        <br />

        <div className="form-group-title">
          <span className="form-group-title-text">เลือกการชำระเบี้ยประกันภัยแบบ</span>
        </div>
        <div className="options option-inputs">
          <PaymentSelectedMRTA />
        </div>
        {isShowInfoCashIncludeLoan && (
          <ErrorFeedback
            id={'coverage-period-error-msg'}
            errorMessages={[
              'จำนวนเงินเอาประกันภัยหลักจะมีการเปลี่ยนแปลง โดยระบบจะคำนวณจากเบี้ยรวมฯ และทุนสัญญาหลัก กรุณาตรวจสอบรายละเอียดที่หน้าสรุปสัญญาอีกครั้ง',
            ]}
            messageType={'info-message'}
          />
        )}

        {isShowInterestRate && (
          <div>
            <br />
            <div className="form-group-title">
              <span className="form-group-title-text">อัตราดอกเบี้ยกรณีกู้จ่ายค่าเบี้ย (%ต่อปี)</span>
            </div>
            <div className="options option-inputs">
              <InterestRate interestRate={interestRate.value} editInterestRate={editInterestRate} />
            </div>
            <InterestRateErrorMessages haveBasicPremiumState={haveBasicPremiumState} interestRate$={interestRate} />
          </div>
        )}
      </div>
    </FormGroup>
  )
}

const mapStateToProps = (state: AppState) => ({
  basicPremiumState: getBasicPremiumState(state),
  haveBasicPremiumState: haveBasicPremiumState(state),
  sumAssured$: getSumAssured$(state),
  basicPremium$: getBasicPremium$(state),
  readOnlyFields: getReadOnlyFields(state),
  coveragePeriodError: getCoveragePeriodErrors(state),
  loanPeriod$: getLoanPeriodAll(state),
  coveragePeriod$: getCoveragePeriodAll(state),
  interestRate: getInterestRate(state),
  isGLTSPProduct: isGLTSPProduct(state),
  loanPaymentMethod: getPaymentMethod(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  editSumAssured: bindActionCreators(editSumAssured, dispatch),
  editBasicPremium: bindActionCreators(editBasicPremiumState, dispatch),
  editInterestRate: bindActionCreators(editInterestRate, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(BasicPlanMRTA)
