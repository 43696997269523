// @flow

import _ from 'lodash'
import { combineReducers } from 'redux'

import type { RiderBenefitState, RiderBenefitTables } from 'core/service/rider/benefits'
import type { Action } from './actions'
import {
  ADD_RIDER_BENEFIT,
  REMOVE_RIDER_BENEFIT,
  UPDATE_RIDER_BENEFIT,
  UPDATE_POLICY_VALUE,
  UPDATE_BENEFIT_SUMMARY,
  UPDATE_TAX_DEDUCTION,
} from './actions'
import { UPDATE_INVESTMENT_BENEFIT } from 'quick-quote/product-life-save-pro/benefit-illustration/actions'

import { UPDATE_TERM_LIFE_INVESTMENT_BENEFIT } from 'quick-quote/product-term-life/benefit-illustration/actions'
import type { ResetApplicationState } from 'quick-quote/actions'
import { RESET_APPLICATION_STATE } from 'quick-quote/actions'
import type { PolicyValue, BenefitSummary, TaxDeduction } from 'core/service/benefit-illustration'
import type { InvestmentBenefitTable } from 'core/service/benefit-illustration/investment-benefit'

export type State = {
  +riderBenefits: { [string]: RiderBenefitState },
  +policyValue: PolicyValue,
  +benefitSummary: BenefitSummary,
  +investmentBenefit: InvestmentBenefitTable,
  +taxDeduction: TaxDeduction,
}

export const riderBenefits = (state: RiderBenefitTables = {}, action: Action | ResetApplicationState) => {
  switch (action.type) {
    case RESET_APPLICATION_STATE:
      return {}
    case ADD_RIDER_BENEFIT:
    case UPDATE_RIDER_BENEFIT:
      return { ...state, [action.payload.riderBenefitKey]: action.payload.riderBenefitData }
    case REMOVE_RIDER_BENEFIT:
      return _.omit(state, [action.payload.riderBenefitKey])
    default:
      return state
  }
}

const defaultPolicyValue = {
  yearEndPolicyValues: [],
  subtotalAnnualBasicPremium: 0,
  subtotalAnnualRiderPremium: 0,
  grandTotalPremium: 0,
}

export const policyValue = (state: PolicyValue = defaultPolicyValue, action: Action | ResetApplicationState) => {
  switch (action.type) {
    case RESET_APPLICATION_STATE:
      return defaultPolicyValue
    case UPDATE_POLICY_VALUE:
      return action.payload
    default:
      return state
  }
}

export const benefitSummary = (state: BenefitSummary = {}, action: Action) => {
  switch (action.type) {
    case RESET_APPLICATION_STATE:
      return {}
    case UPDATE_BENEFIT_SUMMARY:
      return action.payload
    default:
      return state
  }
}

const defaultInvestmentBenefit = {
  guaranteeBenefits: [],
  nonGuaranteeBenefits: [],
}

export const investmentBenefit = (state: InvestmentBenefitTable = defaultInvestmentBenefit, action: Action) => {
  switch (action.type) {
    case RESET_APPLICATION_STATE:
      return defaultInvestmentBenefit
    case UPDATE_INVESTMENT_BENEFIT:
    case UPDATE_TERM_LIFE_INVESTMENT_BENEFIT:
      return action.payload
    default:
      return state
  }
}

const defaultTaxDeductionBenefit = {
  taxDeductionBasicPremiumForYear: 0,
  taxDeductionRiderPremuimForYear: 0,
  taxDeductionTotalForYear: 0,
  taxDeductionForCoverage: 0,
  taxDeductionRetirementForYear: 0,
  taxDeductionLifeForYear: 0,
  taxDeductionHealthForYear: 0,
}

export const taxDeduction = (state: TaxDeduction = defaultTaxDeductionBenefit, action: Action) => {
  switch (action.type) {
    case RESET_APPLICATION_STATE:
      return defaultTaxDeductionBenefit
    case UPDATE_TAX_DEDUCTION:
      return action.payload
    default:
      return state
  }
}

export const reducer = combineReducers({
  riderBenefits,
  policyValue,
  benefitSummary,
  investmentBenefit,
  taxDeduction,
})
