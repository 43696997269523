import { isShowFirstPremium, shouldShowOptionsToPayLater } from './submit/selectors'
import { createSectionCreator, createField } from './utils'
import { getSubmitWithoutPaymentField } from './submit/submitWithoutPayment'
import { getFirstPremiumCardTitle, shouldShowSubmitWithoutPaymentSection } from './submit/submitSection'
import { getToggles } from 'quick-quote/feature-toggles'
import remotePaymentModalMergeState from './submit/remotePaymentModalMergeState'
import { compact } from 'lodash/fp'
import {
  getPolicyId,
  getQuickQuoteProductName,
  getSelectedModelFactorLabel,
  getQuickQuoteTotalPremium,
  hasReceiptPaid,
  isFinishCreditCardPaymentSection,
  getQuickQuotePaymentModelLabel,
} from 'e-submission/domain/data-model/form/selectors'
import { get } from 'lodash/fp'
import { setSubmitWithPayment } from 'e-submission/apps/actions'

const remotePaymentField = (app, id) => {
  const showFirstPremium = !getToggles().ENABLE_SUBMIT_WITHOUT_PAYMENT || isShowFirstPremium(app)
  const isPaid = hasReceiptPaid(app)
  return [
    createField(
      `premiumSummaryTable-${hasReceiptPaid(app)}`,
      compact([
        {
          id: 'premiumSummaryTable',
          c: 'PremiumSummaryTable',
          p: {
            mergeState: (state, app) => {
              return {
                amount: getQuickQuoteTotalPremium(app),
                policyId: getPolicyId(app),
                product: getQuickQuoteProductName(app),
                selectedModelFactorLabel: getSelectedModelFactorLabel(app) || getQuickQuotePaymentModelLabel(app),
                lastModified: get('updatedAt', app),
                isPaid: hasReceiptPaid(app),
                isFinishCreditCardPaymentSection: isFinishCreditCardPaymentSection(app),
                name: 'bank-slip',
              }
            },
          },
        },
      ])
    ),
    !isPaid &&
      createField(
        `submitWithoutPayment-${shouldShowOptionsToPayLater(app)}`,
        shouldShowSubmitWithoutPaymentSection(app) ? getSubmitWithoutPaymentField(app, true) : []
      ),
    !isPaid &&
      createField(
        `payment-${getFirstPremiumCardTitle(app)}`,
        compact([
          showFirstPremium && {
            id: 'submitWithoutPaymentTitle',
            c: 'SubmitWithoutPaymentTitle',
          },
          showFirstPremium && {
            id: 'payment',
            c: 'RadioButtonWithDialog',
            p: {
              options: [
                {
                  text: 'ชำระเบี้ยงวดแรก',
                  value: false,
                  openDialog: true,
                },
              ],
              onChange: ({ value, dispatch }) => (!value ? dispatch(setSubmitWithPayment()) : () => {}),
              remoteSelling: true,
              subpage: 'payment',
              title: `ชำระเบี้ยงวดแรก${getFirstPremiumCardTitle(app)}`,
              mergeState: remotePaymentModalMergeState,
            },
          },
        ])
      ),
  ]
}

export default createSectionCreator(remotePaymentField)
