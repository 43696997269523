//@flow
/* istanbul ignore file */
// this file is configuration so testing this file seem to be duplicate work of config things
import { AcceptLstuFundAllocationButton } from 'quick-quote/components/accept-fund-allocation-btn'
import AcceptFundAllocationButton from 'quick-quote/components/rpp-accept-fund-allocation-btn'
import { canProceedToInsuredInformation, isCompletedMarketConducts } from 'quick-quote/fna/market-conducts/selectors'
import { canProceedToProductSelection } from 'quick-quote/fna/need-and-gap-analysis/selectors'
import { hasFirstYearLstu } from 'quick-quote/product-investment/coverage-plan/selectors'
import {
  getLstuAcceptFundAllocation,
  hasFinishedLstuFundAllocation,
} from 'quick-quote/product-investment/fund-allocation/lstu/selectors'
import { hasFinishedRppFundAllocation } from 'quick-quote/product-investment/fund-allocation/rpp/selectors'
import {
  canProceedToFundAllocation,
  canProceedToInvestmentBenefitIllustration,
  canProceedToPremiumCalculation,
  canProceedToRiskQuestionnaireV2,
} from 'quick-quote/product-investment/journey-control-selectors'
import { canProceedToNormalTab } from 'quick-quote/product-s7cc/selectors'
import type { Element } from 'react'
import type { AnalyticsEventType } from 'analytics/components'
import MESSAGES from '../../../../core/data-model/constants/messages'
import { fnaPath, ROUTE_PATH } from '../../../constants/path'
import { ShareButtonSection as FlexiHealthShareButtonSection } from '../../../product-flexi-health/benefit-illustration/components'
import { ShareButtonSection as HealthShareButtonSection } from '../../../product-health/benefit-illustration/components'
import ShareQuoteButton from '../../../product-investment/benefit-illustration/share-quote-button'
import { canProceedPerfectLifeBenefitIllustration } from '../../../product-perfect-life/coverage-plan/selectors'
import { canProceedSukhapabDekDeeBenefitIllustration } from '../../../product-sukhapabdekdee/coverage-plan/selectors'
import { canProceedRetirementBenefitIllustration } from '../../../product-retirement/coverage-plan/selectors'
import { canProceedBumnanReadyBenefitIllustration } from '../../../product-bumnan-ready/coverage-plan/selectors'
import { ShareButtonSection } from '../../../product-whole-life/benefit-illustration/components'
import ESubmitButton from '../../../product-whole-life/benefit-illustration/components/e-submit/esub-button'
import { canProceedToBenefitIllustration } from '../../../product-whole-life/coverage-plan/selectors'
import type { AppState } from '../../../reducers'
import { canProceedToCoveragePlan } from '../../../selectors/next-tab-proceeding'
import MaturityValidateButton from 'quick-quote/components/maturity-validate-btn'
import { getToggles } from '../../../feature-toggles'
import ValidateRPQScore from '../../../product-investment/components/risk-profile/validate-rpq-score'

export type NavButton = {
  type: 'link',
  to?: string,
  text?: string,
  disabled?: boolean,
  analyticsEventType?: AnalyticsEventType,
  onClick?: Function,
}

export type NavButtonConfiguration = {
  path: string,
  props: {
    backButton?: NavButton,
    nextButton?:
      | NavButton
      | {
          type: 'component',
          component: Element<*>,
        },
    extraButton?: React$ComponentType<*>,
  },
  excludePath?: string,
}

const wholeLifeNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
    PRODUCT_SELECTION,
  } = ROUTE_PATH.WHOLE_LIFE

  return [
    {
      path: fnaPath(NEED_AND_GAP_ANALYSIS),
      props: {
        backButton: {
          type: 'link',
          toHome: true,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
          disabled: !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
          disabled: getToggles().ENABLE_FNA_V3
            ? !isCompletedMarketConducts(state)
            : !canProceedToProductSelection(state),

          // !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: fnaPath(INSURED_INFORMATION),
          disabled: !canProceedToInsuredInformation(state),
        },
      },
    },
    {
      path: fnaPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: fnaPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: fnaPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: fnaPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: fnaPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
        },
        extraButton: ShareButtonSection,
      },
    },
  ]
}

const lifeReadyNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
    PRODUCT_SELECTION,
  } = ROUTE_PATH.LIFE_READY

  return [
    {
      path: fnaPath(NEED_AND_GAP_ANALYSIS),
      props: {
        backButton: {
          type: 'link',
          toHome: true,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
          disabled: !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
          disabled:
            // !canProceedToProductSelection(state),
            getToggles().ENABLE_FNA_V3 ? !isCompletedMarketConducts(state) : !canProceedToProductSelection(state),
          // isCompletedMarketConducts
          // !isCompletedMarketConducts(state)
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: fnaPath(INSURED_INFORMATION),
          disabled: !canProceedToInsuredInformation(state),
        },
      },
    },
    {
      path: fnaPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: fnaPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: fnaPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: fnaPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: fnaPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
        },
        extraButton: ShareButtonSection,
      },
    },
  ]
}

const mrtaNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
    PRODUCT_SELECTION,
  } = ROUTE_PATH.MRTA

  return [
    {
      path: fnaPath(NEED_AND_GAP_ANALYSIS),
      props: {
        backButton: {
          type: 'link',
          toHome: true,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
          disabled: !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
          disabled: getToggles().ENABLE_FNA_V3
            ? !isCompletedMarketConducts(state)
            : !canProceedToProductSelection(state),

          // !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: fnaPath(INSURED_INFORMATION),
          disabled: !canProceedToInsuredInformation(state),
        },
      },
    },
    {
      path: fnaPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: fnaPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: fnaPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: fnaPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: fnaPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
        },
        extraButton: ShareButtonSection,
      },
    },
  ]
}

const paNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
    PRODUCT_SELECTION,
  } = ROUTE_PATH.PA

  return [
    {
      path: fnaPath(NEED_AND_GAP_ANALYSIS),
      props: {
        backButton: {
          type: 'link',
          toHome: true,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
          disabled: !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
          disabled: !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: fnaPath(INSURED_INFORMATION),
          disabled: !canProceedToInsuredInformation(state),
        },
      },
    },
    {
      path: fnaPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: fnaPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: fnaPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: fnaPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: fnaPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
        },
      },
    },
  ]
}

const protectionNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
    PRODUCT_SELECTION,
  } = ROUTE_PATH.PROTECTION

  return [
    {
      path: fnaPath(NEED_AND_GAP_ANALYSIS),
      props: {
        backButton: {
          type: 'link',
          toHome: true,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
          disabled: !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
          disabled: getToggles().ENABLE_FNA_V3
            ? !isCompletedMarketConducts(state)
            : !canProceedToProductSelection(state),

          // !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: fnaPath(INSURED_INFORMATION),
          disabled: !canProceedToInsuredInformation(state),
        },
      },
    },
    {
      path: fnaPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: fnaPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: fnaPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: fnaPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: fnaPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
        },
        extraButton: ShareButtonSection,
      },
    },
  ]
}

const investmentNavigationButtonV2 = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    RISK_QUESTIONNAIRE,
    FUND_ALLOCATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    NEED_AND_GAP_ANALYSIS,
    PRODUCT_SELECTION,
    MARKET_CONDUCTS,
    LSTU_FUND_ALLOCATION,
  } = ROUTE_PATH.INVESTMENT

  const backNextButton = {
    backButton: {
      type: 'link',
      to: fnaPath(COVERAGE_PLAN),
    },
    nextButton: {
      type: 'component',
      disabled: false,
      component: (
        <ValidateRPQScore
          buttonName={hasFirstYearLstu(state) ? MESSAGES.RPP_FUND : MESSAGES.FUND}
          linkButton={fnaPath(FUND_ALLOCATION)}
          linkModalBtn={fnaPath(PRODUCT_SELECTION)}
        />
      ),
    },
  }

  const backButton = {
    backButton: {
      type: 'link',
      to: fnaPath(COVERAGE_PLAN),
    },
  }

  const navButton = canProceedToFundAllocation(state) ? backNextButton : backButton
  return [
    {
      path: fnaPath(NEED_AND_GAP_ANALYSIS),
      props: {
        backButton: {
          type: 'link',
          toHome: true,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
          disabled: !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
          disabled: getToggles().ENABLE_FNA_V3
            ? !isCompletedMarketConducts(state)
            : !canProceedToProductSelection(state),

          // !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: fnaPath(INSURED_INFORMATION),
          disabled: !canProceedToInsuredInformation(state),
        },
      },
    },
    {
      path: fnaPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: fnaPath(COVERAGE_PLAN),
          disabled: !canProceedToPremiumCalculation(state),
        },
      },
    },
    {
      path: fnaPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.RISK_QUESTIONNAIRE,
          to: fnaPath(RISK_QUESTIONNAIRE),
          disabled: !canProceedToRiskQuestionnaireV2(state),
        },
      },
    },
    {
      path: fnaPath(RISK_QUESTIONNAIRE),
      props: navButton,
    },
    {
      path: fnaPath(FUND_ALLOCATION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(RISK_QUESTIONNAIRE),
        },
        nextButton: {
          type: 'component',
          disabled: !hasFinishedRppFundAllocation(state),
          component: <AcceptFundAllocationButton />,
        },
      },
    },
    {
      path: fnaPath(LSTU_FUND_ALLOCATION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(FUND_ALLOCATION),
        },
        nextButton: !getLstuAcceptFundAllocation(state)
          ? {
              type: 'component',
              disabled: !hasFinishedLstuFundAllocation(state),
              component: <AcceptLstuFundAllocationButton message={MESSAGES.SUBMIT} />,
            }
          : {
              type: 'link',
              text: MESSAGES.BENEFIT_ILLUSTRATION,
              to: fnaPath(BENEFIT_ILLUSTRATION),
              disabled: !canProceedToInvestmentBenefitIllustration(state),
            },
      },
    },
    {
      path: fnaPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: hasFirstYearLstu(state)
          ? {
              type: 'link',
              to: fnaPath(LSTU_FUND_ALLOCATION),
            }
          : {
              type: 'link',
              to: fnaPath(FUND_ALLOCATION),
            },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
        },
        extraButton: ShareQuoteButton,
      },
    },
  ]
}

const loanNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
    PRODUCT_SELECTION,
  } = ROUTE_PATH.LOAN

  return [
    {
      path: fnaPath(NEED_AND_GAP_ANALYSIS),
      props: {
        backButton: {
          type: 'link',
          toHome: true,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
          disabled: !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
          disabled: getToggles().ENABLE_FNA_V3
            ? !isCompletedMarketConducts(state)
            : !canProceedToProductSelection(state),

          // !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: fnaPath(INSURED_INFORMATION),
          disabled: !canProceedToInsuredInformation(state),
        },
      },
    },
    {
      path: fnaPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: fnaPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: fnaPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: fnaPath(BENEFIT_ILLUSTRATION),
          disabled: false,
        },
      },
    },
    {
      path: fnaPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(COVERAGE_PLAN),
        },
      },
    },
  ]
}

const healthNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
    PRODUCT_SELECTION,
  } = ROUTE_PATH.HEALTH

  return [
    {
      path: fnaPath(NEED_AND_GAP_ANALYSIS),
      props: {
        backButton: {
          type: 'link',
          toHome: true,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
          disabled: !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
          disabled: getToggles().ENABLE_FNA_V3
            ? !isCompletedMarketConducts(state)
            : !canProceedToProductSelection(state),

          // !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: fnaPath(INSURED_INFORMATION),
          disabled: !canProceedToInsuredInformation(state),
        },
      },
    },
    {
      path: fnaPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: fnaPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: fnaPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: fnaPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: fnaPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
        },
        extraButton: HealthShareButtonSection,
      },
    },
  ]
}

const ihealthyUltraNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
    PRODUCT_SELECTION,
  } = ROUTE_PATH.IHEALTHY_ULTRA

  return [
    {
      path: fnaPath(NEED_AND_GAP_ANALYSIS),
      props: {
        backButton: {
          type: 'link',
          toHome: true,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
          disabled: !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
          disabled: getToggles().ENABLE_FNA_V3
            ? !isCompletedMarketConducts(state)
            : !canProceedToProductSelection(state),

          // !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: fnaPath(INSURED_INFORMATION),
          disabled: !canProceedToInsuredInformation(state),
        },
      },
    },
    {
      path: fnaPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: fnaPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: fnaPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: fnaPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: fnaPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
        },
        extraButton: HealthShareButtonSection,
      },
    },
  ]
}

const flexiHealthNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
    PRODUCT_SELECTION,
  } = ROUTE_PATH.FLEXI_HEALTH

  return [
    {
      path: fnaPath(NEED_AND_GAP_ANALYSIS),
      props: {
        backButton: {
          type: 'link',
          toHome: true,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
          disabled: !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
          disabled: getToggles().ENABLE_FNA_V3
            ? !isCompletedMarketConducts(state)
            : !canProceedToProductSelection(state),

          // !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: fnaPath(INSURED_INFORMATION),
          disabled: !canProceedToInsuredInformation(state),
        },
      },
    },
    {
      path: fnaPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: fnaPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: fnaPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: fnaPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: fnaPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
        },
        extraButton: FlexiHealthShareButtonSection,
      },
    },
  ]
}

const savingNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
    PRODUCT_SELECTION,
  } = ROUTE_PATH.SAVING

  return [
    {
      path: fnaPath(NEED_AND_GAP_ANALYSIS),
      props: {
        backButton: {
          type: 'link',
          toHome: true,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
          disabled: !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
          disabled: getToggles().ENABLE_FNA_V3
            ? !isCompletedMarketConducts(state)
            : !canProceedToProductSelection(state),

          // !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: fnaPath(INSURED_INFORMATION),
          disabled: !canProceedToInsuredInformation(state),
        },
      },
    },
    {
      path: fnaPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: fnaPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: fnaPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: fnaPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: fnaPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
        },
        extraButton: HealthShareButtonSection,
      },
    },
  ]
}

const eSubmissionNavigationButton = (): NavButtonConfiguration[] => {
  return [
    {
      path: fnaPath(ROUTE_PATH.REMOTE_SELLING.VIDEO_CONSENT),
      props: {
        backButton: undefined,
      },
    },
    {
      path: fnaPath(ROUTE_PATH.ESUBMISSION_FORM),
      props: {
        backButton: {
          type: 'link',
        },
      },
      excludePath: 'submit',
    },
    {
      path: fnaPath(ROUTE_PATH.ESUBMISSION_PAYMENT),
      props: {
        backButton: {
          type: 'link',
        },
      },
    },
  ]
}

const lifeSaveProNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
    PRODUCT_SELECTION,
  } = ROUTE_PATH.LIFE_SAVE_PRO

  return [
    {
      path: fnaPath(NEED_AND_GAP_ANALYSIS),
      props: {
        backButton: {
          type: 'link',
          toHome: true,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
          disabled: !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
          disabled: getToggles().ENABLE_FNA_V3
            ? !isCompletedMarketConducts(state)
            : !canProceedToProductSelection(state),

          // !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: fnaPath(INSURED_INFORMATION),
          disabled: !canProceedToInsuredInformation(state),
        },
      },
    },
    {
      path: fnaPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: fnaPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: fnaPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: fnaPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: fnaPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
        },
        extraButton: ShareButtonSection,
      },
    },
  ]
}

const retirementNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
    PRODUCT_SELECTION,
  } = ROUTE_PATH.RETIREMENT

  return [
    {
      path: fnaPath(NEED_AND_GAP_ANALYSIS),
      props: {
        backButton: {
          type: 'link',
          toHome: true,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
          disabled: !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
          disabled: getToggles().ENABLE_FNA_V3
            ? !isCompletedMarketConducts(state)
            : !canProceedToProductSelection(state),

          // !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: fnaPath(INSURED_INFORMATION),
          disabled: !canProceedToInsuredInformation(state),
        },
      },
    },
    {
      path: fnaPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: fnaPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: fnaPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: fnaPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedRetirementBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: fnaPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
        },
        extraButton: ShareButtonSection,
      },
    },
  ]
}

const bumnanReadyNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
    PRODUCT_SELECTION,
  } = ROUTE_PATH.BUMNAN_READY

  return [
    {
      path: fnaPath(NEED_AND_GAP_ANALYSIS),
      props: {
        backButton: {
          type: 'link',
          toHome: true,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
          disabled: !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
          disabled: getToggles().ENABLE_FNA_V3
            ? !isCompletedMarketConducts(state)
            : !canProceedToProductSelection(state),

          // !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: fnaPath(INSURED_INFORMATION),
          disabled: !canProceedToInsuredInformation(state),
        },
      },
    },
    {
      path: fnaPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: fnaPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: fnaPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: fnaPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedBumnanReadyBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: fnaPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
        },
        extraButton: ShareButtonSection,
      },
    },
  ]
}

const perfectLifeNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
    PRODUCT_SELECTION,
  } = ROUTE_PATH.PERFECT_LIFE

  return [
    {
      path: fnaPath(NEED_AND_GAP_ANALYSIS),
      props: {
        backButton: {
          type: 'link',
          toHome: true,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
          disabled: !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
          disabled: getToggles().ENABLE_FNA_V3
            ? !isCompletedMarketConducts(state)
            : !canProceedToProductSelection(state),

          // !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: fnaPath(INSURED_INFORMATION),
          disabled: !canProceedToInsuredInformation(state),
        },
      },
    },
    {
      path: fnaPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: fnaPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: fnaPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: fnaPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedPerfectLifeBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: fnaPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
        },
        extraButton: ShareButtonSection,
      },
    },
  ]
}

const sukhapabDekDeeNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
    PRODUCT_SELECTION,
  } = ROUTE_PATH.SUKHAPABDEKDEE

  return [
    {
      path: fnaPath(NEED_AND_GAP_ANALYSIS),
      props: {
        backButton: {
          type: 'link',
          toHome: true,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
          disabled: !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
          disabled: getToggles().ENABLE_FNA_V3
            ? !isCompletedMarketConducts(state)
            : !canProceedToProductSelection(state),

          // !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: fnaPath(INSURED_INFORMATION),
          disabled: !canProceedToInsuredInformation(state),
        },
      },
    },
    {
      path: fnaPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: fnaPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: fnaPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: fnaPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedSukhapabDekDeeBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: fnaPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
        },
        extraButton: ShareButtonSection,
      },
    },
  ]
}

const healthToppingNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
    PRODUCT_SELECTION,
  } = ROUTE_PATH.HEALTH_TOPPING

  return [
    {
      path: fnaPath(NEED_AND_GAP_ANALYSIS),
      props: {
        backButton: {
          type: 'link',
          toHome: true,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
          disabled: !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
          disabled: getToggles().ENABLE_FNA_V3
            ? !isCompletedMarketConducts(state)
            : !canProceedToProductSelection(state),

          // !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: fnaPath(INSURED_INFORMATION),
          disabled: !canProceedToInsuredInformation(state),
        },
      },
    },
    {
      path: fnaPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: fnaPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: fnaPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: fnaPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: fnaPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
        },
        extraButton: ShareButtonSection,
      },
    },
  ]
}

const s7ccNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    MATURITY_VALIDATE,
    SELECT_POLICY_OWNER,
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
    PRODUCT_SELECTION,
  } = ROUTE_PATH.S7CC

  return [
    {
      path: fnaPath(NEED_AND_GAP_ANALYSIS),
      props: {
        backButton: {
          type: 'link',
          toHome: true,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
          disabled: !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
          disabled: getToggles().ENABLE_FNA_V3
            ? !isCompletedMarketConducts(state)
            : !canProceedToProductSelection(state),

          // !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: fnaPath(MATURITY_VALIDATE),
          disabled: !canProceedToInsuredInformation(state),
        },
      },
    },
    {
      path: fnaPath(MATURITY_VALIDATE),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
        },
        nextButton: {
          type: 'component',
          component: <MaturityValidateButton />,
        },
      },
    },
    {
      path: fnaPath(SELECT_POLICY_OWNER),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(MATURITY_VALIDATE),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.INSURED_PERSON,
          to: fnaPath(INSURED_INFORMATION),
          disabled: !canProceedToNormalTab(state),
        },
      },
    },
    {
      path: fnaPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(SELECT_POLICY_OWNER),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: fnaPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: fnaPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: fnaPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: fnaPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
        },
        extraButton: HealthShareButtonSection,
      },
    },
  ]
}

const termLifeNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
    PRODUCT_SELECTION,
  } = ROUTE_PATH.TERM_LIFE

  return [
    {
      path: fnaPath(NEED_AND_GAP_ANALYSIS),
      props: {
        backButton: {
          type: 'link',
          toHome: true,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
          disabled: !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
          disabled: getToggles().ENABLE_FNA_V3
            ? !isCompletedMarketConducts(state)
            : !canProceedToProductSelection(state),

          // !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(MARKET_CONDUCTS) : fnaPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: fnaPath(INSURED_INFORMATION),
          disabled: !canProceedToInsuredInformation(state),
        },
      },
    },
    {
      path: fnaPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: getToggles().ENABLE_FNA_V3 ? fnaPath(PRODUCT_SELECTION) : fnaPath(MARKET_CONDUCTS),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: fnaPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: fnaPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: fnaPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: fnaPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: fnaPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
        },
        extraButton: ShareButtonSection,
      },
    },
  ]
}

const fnaNavigationButtonV2 = (state: AppState): NavButtonConfiguration[] => {
  const { NEED_AND_GAP_ANALYSIS, MARKET_CONDUCTS, FNA_PRODUCT_SELECTION } = ROUTE_PATH
  return [
    {
      path: NEED_AND_GAP_ANALYSIS,
      props: {
        backButton: {
          type: 'link',
          toHome: true,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: MARKET_CONDUCTS,
          disabled: !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: MARKET_CONDUCTS,
      props: {
        backButton: {
          type: 'link',
          to: NEED_AND_GAP_ANALYSIS,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: FNA_PRODUCT_SELECTION,
          disabled: !isCompletedMarketConducts(state),
          // !canProceedToInsuredInformation(state),
        },
      },
    },
  ]
}

const fnaNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const { NEED_AND_GAP_ANALYSIS, FNA_PRODUCT_SELECTION } = ROUTE_PATH

  return [
    {
      path: NEED_AND_GAP_ANALYSIS,
      props: {
        backButton: {
          type: 'link',
          toHome: true,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: FNA_PRODUCT_SELECTION,
          disabled: !canProceedToProductSelection(state),
        },
      },
    },
    {
      path: FNA_PRODUCT_SELECTION,
      props: {
        backButton: {
          type: 'link',
          to: NEED_AND_GAP_ANALYSIS,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: '',
          disabled: true,
        },
      },
    },
  ]
}

export default (state: AppState): NavButtonConfiguration[] =>
  wholeLifeNavigationButton(state)
    .concat(lifeReadyNavigationButton(state))
    .concat(investmentNavigationButtonV2(state))
    .concat(loanNavigationButton(state))
    .concat(healthToppingNavigationButton(state))
    .concat(healthNavigationButton(state))
    .concat(ihealthyUltraNavigationButton(state))
    .concat(savingNavigationButton(state))
    .concat(protectionNavigationButton(state))
    .concat(lifeSaveProNavigationButton(state))
    .concat(flexiHealthNavigationButton(state))
    .concat(retirementNavigationButton(state))
    .concat(bumnanReadyNavigationButton(state))
    .concat(perfectLifeNavigationButton(state))
    .concat(sukhapabDekDeeNavigationButton(state))
    .concat(s7ccNavigationButton(state))
    .concat(termLifeNavigationButton(state))
    .concat(mrtaNavigationButton(state))
    .concat(paNavigationButton(state))
    .concat(eSubmissionNavigationButton())
    .concat(getToggles().ENABLE_FNA_V3 ? fnaNavigationButtonV2(state) : fnaNavigationButton(state))
