// @flow

import type { Fund, FundAllocationToggleItem } from 'core/data-model/investment/types'
import type { FundData, PieChartByType } from 'core/service/pdf-generation/products/investment/components'
import {
  FundRiskTableSection as CommonFundRiskTableSection,
  FundRiskChartSection as CommonFundRiskChartSection,
} from 'core/service/pdf-generation/products/investment/components'
import type { InsuredProps } from 'core/service/pdf-generation/products/common/types'

import Mustache from 'mustache'
import { Section } from 'core/service/pdf-generation/products/common/components/general'
import { FundRiskTableSection as RPUDRFundRiskTableSection } from '../products/rpudr/fund-risk-table'
import { FundRiskChartSection as RPUDRFundRiskChartSection } from '../products/rpudr/fund-risk-chart'
import { combineTables } from 'core/service/pdf-generation/utils/table-utils'
import { generatePatterns } from 'core/service/pdf-generation/products/investment/components/fund-allocation-pie-chart'
import MESSAGES from 'core/data-model/constants/bi-messages'
import { getToggles } from 'quick-quote/feature-toggles'

export type FundAllocationCategoryByInvestmentType = {
  rpp: FundData[],
  lstu: FundData[],
}

export type FundAllocationByInvestmentType = {
  rpp: FundAllocationToggleItem,
  lstu: FundAllocationToggleItem,
}

export type PortfolioDisplayOptions = {
  isShowOnlySelectedFund: boolean,
  isUsePatternForChart: boolean,
  chartPatterns?: string[],
}

type MutualFundPortfolioProps = {
  dateInThai: string,
  funds: Fund[],
  allocations: FundAllocationToggleItem[],
  allocationCategoryByInvestmentType: FundAllocationCategoryByInvestmentType,
  insuredRiskLevel: number,
  insured: InsuredProps,
  pieChartFundByInvestmentType: PieChartByType,
  policyType: string,
  productCode?: string,
}

const getChartByProductCode = (
  lumpSumTopUp: number,
  productCode: string,
  allocationCategoryByInvestmentType: FundAllocationCategoryByInvestmentType,
  pieChartFundByInvestmentType: PieChartByType,
  portfolioDisplayOptions: PortfolioDisplayOptions
) => {
  switch (productCode) {
    case MESSAGES.BI_TYPE_PRODUCT_CODE_RPUDR:
      return RPUDRFundRiskChartSection(
        lumpSumTopUp,
        allocationCategoryByInvestmentType,
        pieChartFundByInvestmentType,
        portfolioDisplayOptions
      )
    case MESSAGES.BI_TYPE_PRODUCT_CODE_IWEALTHY:
    case MESSAGES.BI_TYPE_PRODUCT_CODE_IINVEST:
    default:
      return CommonFundRiskChartSection(
        allocationCategoryByInvestmentType.rpp,
        pieChartFundByInvestmentType.rpp,
        portfolioDisplayOptions
      )
  }
}

const getTableRiskTableByProductCode = (
  productCode: string,
  lumpSumTopUp: number,
  funds: Fund[],
  allocationByInvestmentType: FundAllocationByInvestmentType,
  insuredRiskLevel: number,
  insured: InsuredProps,
  fundAllocationChart,
  portfolioDisplayOptions: PortfolioDisplayOptions
) => {
  switch (productCode) {
    case MESSAGES.BI_TYPE_PRODUCT_CODE_RPUDR:
      return combineTables([
        RPUDRFundRiskTableSection(
          lumpSumTopUp,
          funds,
          allocationByInvestmentType,
          insuredRiskLevel,
          insured,
          portfolioDisplayOptions.isShowOnlySelectedFund
        ),
        fundAllocationChart,
      ])
    case MESSAGES.BI_TYPE_PRODUCT_CODE_IWEALTHY:
    case MESSAGES.BI_TYPE_PRODUCT_CODE_IINVEST:
    default:
      return combineTables([
        CommonFundRiskTableSection(
          funds,
          allocationByInvestmentType.rpp,
          insuredRiskLevel,
          insured,
          portfolioDisplayOptions.isShowOnlySelectedFund
        ),
        fundAllocationChart,
      ])
  }
}

/**
 * // TODO: `lumpSumTopUp` should be removed
 * As it a workaround to see if LSTU element should populate of not.
 * while it should consistence with `allocationByInvestmentType.lstu` value
 */
const mutualFundPortfolioTable = async (
  lumpSumTopUp: number,
  funds: Fund[],
  allocationByInvestmentType: FundAllocationByInvestmentType,
  allocationCategoryByInvestmentType: FundAllocationCategoryByInvestmentType,
  insuredRiskLevel: number,
  insured: InsuredProps,
  pieChartFundByInvestmentType: PieChartByType,
  productCode: string,
  portfolioDisplayOptions: PortfolioDisplayOptions
) => {
  const fundAllocationChart = getChartByProductCode(
    lumpSumTopUp,
    productCode,
    allocationCategoryByInvestmentType,
    pieChartFundByInvestmentType,
    portfolioDisplayOptions
  )
  const body = getTableRiskTableByProductCode(
    productCode,
    lumpSumTopUp,
    funds,
    allocationByInvestmentType,
    insuredRiskLevel,
    insured,
    fundAllocationChart,
    portfolioDisplayOptions
  )

  const widths = ['82%', '18%']
  return [
    {
      table: {
        heights: ['auto', 375, 'auto'],
        headerRows: 2,
        body,
        widths,
      },
      layout: {
        hLineWidth: (i, node) => (i === 0 || i === node.table.body.length ? 1 : 0),
      },
      fontSize: 12,
    },
  ]
}

export const MutualFundPortfolio = async ({
  dateInThai,
  lumpSumTopUp,
  funds,
  allocationByInvestmentType,
  allocationCategoryByInvestmentType,
  pieChartFundByInvestmentType,
  insuredRiskLevel,
  insured,
  policyType,
  productCode,
}: MutualFundPortfolioProps) => {
  const isShowOnlySelectedFund = getToggles().BI_SHOW_ONLY_SELECTED_FUND
  const isUsePatternForChart = getToggles().ENABLE_FUND_PIE_CHART_PATTERN
  const chartPatterns = await generatePatterns()
  const portfolioDisplayOptions = {
    isShowOnlySelectedFund,
    isUsePatternForChart,
    chartPatterns,
  }
  const portfolio = await mutualFundPortfolioTable(
    lumpSumTopUp,
    funds,
    allocationByInvestmentType,
    allocationCategoryByInvestmentType,
    insuredRiskLevel,
    insured,
    pieChartFundByInvestmentType,
    productCode,
    portfolioDisplayOptions
  )
  return Section(Mustache.render(MESSAGES.MUTUAL_FUND_PORTFOLIO, { policyType, dateInThai }), portfolio)
}
