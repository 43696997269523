// @flow

import { d } from 'core/service/lib/decimal'

const thousandSeparator = (text: string): string => (text ? String(text).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '')

export const roundDown = (number: number, decimalPlaces: number = 0): number => {
  const exponent = Math.pow(10, decimalPlaces)

  const roundDownNumber = d(number)
    .times(exponent)
    .floor()
    .dividedBy(exponent)

  return roundDownNumber.toNumber()
}

export const roundUp = (number: number, decimalPlaces: number = 0): number => {
  const exponent = Math.pow(10, decimalPlaces)

  const roundUpNumber = d(number)
    .times(exponent)
    .ceil()
    .dividedBy(exponent)

  return roundUpNumber.toNumber()
}

export const round = (number: number, decimalPlaces: number = 0): number => {
  const exponent = Math.pow(10, decimalPlaces)

  const roundDownNumber = d(
    Math.round(
      d(number)
        .times(exponent)
        .toNumber()
    )
  ).dividedBy(exponent)

  return roundDownNumber.toNumber()
}

export const formatNumber = (
  unformattedNumber: number,
  decimalPlaces: number = 0,
  isRound: boolean = false
): string => {
  if (!unformattedNumber) {
    return '0'
  }

  const numberWithDecimals: string = isRound
    ? round(unformattedNumber, decimalPlaces).toFixed(decimalPlaces)
    : roundDown(unformattedNumber, decimalPlaces).toFixed(decimalPlaces)

  const [whole, decimal] = numberWithDecimals.split('.')
  if (!decimalPlaces) {
    return `${thousandSeparator(whole)}`
  } else {
    return `${thousandSeparator(whole)}.${decimal}`
  }
}
