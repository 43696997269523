//@flow
import _ from 'lodash'
import { mrtaSection } from './sections'
import { commonSection } from 'core/service/pdf-generation/products/common/sections'
import { basicPlanDetailsLabelFn } from '../common/sections/product-benefit-section'
import VALUES from 'core/data-model/constants/values'

import { MRTAProps } from './index'

const getDefaultFullContent = async (props: MRTAProps) => {
  const paymentChannels = await commonSection.paymentChannels(props)
  const coveragePeriod = _.get(props, 'calculatedCoveragePeriod', 1)
  return [
    mrtaSection.profile(props),
    mrtaSection.productBenefits({ ...props, basicPlanDetailsLabelFn }),
    mrtaSection.basicPlanCondition(),
    mrtaSection.basicPlanGeneralException(props),
    props.basicPlan.basicPlanCode !== VALUES.GLTSP_CODE.GLTSP ? mrtaSection.benefitSummary(props) : {},
    mrtaSection.policyValueTable(props),
    mrtaSection.taxConsentDeductionBenefit(props),
    coveragePeriod > 10 ? { text: '', pageBreak: 'before' } : {},
    commonSection.legalDisclaimerMarketConductNoAddtionalContract(props),
    mrtaSection.healthAndFinancialRuleSection(props),
    paymentChannels,
    commonSection.signature(props),
  ]
}

const getTemplateCode = (props: MRTAProps): string => {
  return _.get(props, 'basicPlan.code')
}

export const getContentFullContent = async (props: MRTAProps) => {
  const productCode = getTemplateCode(props)
  switch (productCode) {
    default:
      return await getDefaultFullContent(props)
  }
}
