// @flow
import { formatNumber } from 'core/service/lib/number-format'
import MESSAGES from 'core/data-model/constants/bi-messages'

type SummaryFooterProps = {
  totalPremium: number,
}

const SummaryFooter = ({ totalPremium }: SummaryFooterProps) => {
  return (
    <tr id="summary-footer" className="border-top">
      <td colSpan="4">{MESSAGES.TOTAL_PREMIUM_FIRST_PREMIUM}</td>
      <td>{formatNumber(totalPremium, 2, true)}</td>
    </tr>
  )
}

export default SummaryFooter
