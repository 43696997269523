// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Dropdown } from 'common-components'

import { getPaymentMethod } from 'quick-quote/product-loan/coverage-plan/redux'
import { editPaymentSelectedMRTA } from 'quick-quote/product-mrta/actions'

import DEFAULTS from 'core/data-model/constants/defaults'

type PaymentSelectedMRTAProps = {
  paymentMethod: number,
  editPaymentSelectedMRTA: (number) => void,
}

const PaymentSelectedMRTA = ({ paymentMethod, editPaymentSelectedMRTA }: PaymentSelectedMRTAProps) => {
  const optionsPaymentSelectedMRTA = DEFAULTS.DEFAULT_PAYMENT_SELECTED_MRTA

  return (
    <Dropdown
      // className="basic-plan form-control"
      className="payment-selected-mrta"
      options={optionsPaymentSelectedMRTA}
      onChange={editPaymentSelectedMRTA}
      value={paymentMethod}
    />
  )
}

const mapStateToProps = (state: AppState) => ({
  paymentMethod: getPaymentMethod(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  editPaymentSelectedMRTA: bindActionCreators(editPaymentSelectedMRTA, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSelectedMRTA)
