// @flow
import type { DisplayProduct } from 'core/service/display-product'

export const UPDATE_WEALTH_SOURCE_DATA = 'UPDATE_WEALTH_SOURCE_DATA'
export const UPDATE_WEALTH_SOURCE_DATA_COMPLETE = 'UPDATE_WEALTH_SOURCE_DATA_COMPLETE'
export const UPDATE_MY_WEALTH_PLUS_PAYLOAD = 'UPDATE_MY_WEALTH_PLUS_PAYLOAD'
export const MY_WEALTH_PLUS_SALE_PERMISSION_CHECK = 'MY_WEALTH_PLUS_SALE_PERMISSION_CHECK'
export const MY_WEALTH_PLUS_FUND_LISTS = 'MY_WEALTH_PLUS_FUND_LISTS'
export const INIT_CUSTOMER_PROFILE = 'INIT_CUSTOMER_PROFILE'

export type MyWealthPlusProfile = {
  coverage: Decimal,
  rpp: number,
  rtu: number,
  dateOfBirthThai: string,
  age: number,
}

export type UpdateMyWealthPayload = {
  productDetail: DisplayProduct,
  categoryId: string,
  categoryPath: String,
  myWealthPlusProfile: MyWealthPlusProfile,
  premiumPrefill: string[],
}

export type WealthInfo = {
  dataSource: string, // default value is "DEFAULT"
  isValidSalePermission: boolean, // default value is false
  isOpenedPDF: boolean,
  isOpenedLSTUPDF: boolean,
}

export type MyWealthPlusFundLists = {
  fundList: MyWealthPlusFundDetail[],
  fundListLSTU: MyWealthPlusFundLSTU,
  rtu: number,
  premiumPrefill: string[],
}

export type MyWealthPlusFundLSTU = {
  expectedReturn: string,
  fundList: MyWealthPlusFundLists[],
}

export type MyWealthPlusFundDetail = {
  allocation: number,
  fundCode: string,
}

export type UpdateWealthSourceData = {
  type: UPDATE_WEALTH_SOURCE_DATA,
  payload: WealthInfo,
}

export type GetMyWealthProfile = {
  type: INIT_CUSTOMER_PROFILE,
  payload: MyWealthPlusProfile,
}

export const updateWealthSourceData = (info: WealthInfo): UpdateWealthSourceData => ({
  type: UPDATE_WEALTH_SOURCE_DATA,
  payload: info,
})

export const updateWealthSourceDataComplete = () => ({
  type: UPDATE_WEALTH_SOURCE_DATA_COMPLETE,
})

export const updateMyWealthPayload = (mwPlusPayload: UpdateMyWealthPayload) => ({
  type: UPDATE_MY_WEALTH_PLUS_PAYLOAD,
  payload: mwPlusPayload,
})

export const validateMyWealthSalePermission = (product: DisplayProduct) => ({
  type: MY_WEALTH_PLUS_SALE_PERMISSION_CHECK,
  payload: product,
})

export const getMyWealthProfile = (payload: MyWealthPlusFundLists) => ({
  type: INIT_CUSTOMER_PROFILE,
  payload,
})
