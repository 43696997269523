// @flow

import type { Rider, RiderState } from 'core/data-model/rider'
import type { Benefit } from 'core/data-model/rider/benefits'
import { isObject, isNotNil } from 'core/service/lib/type-check'
import _ from 'lodash'
import RiderWithBenefitModal from './rider-benefit-modal'
import MESSAGES from 'core/data-model/constants/bi-messages'
import RiderBenefitSummary from 'quick-quote/product-saving/benefit-illustration/components/rider-benefits/rider-benefit-summary'

type IGROWRiderBenefitProps = {
  rider: Rider & RiderState,
  planCode: number,
  benefits: Benefit[],
}

type IGROWRiderBenefitsRowProps = {
  description: string,
  value: string,
}

const textValueRows = [31, 32]
const headerRows = [0, 8, 13, 24, 25, 26, 30]
const subHeaderRows = [15, 16, 19, 20, 21, 22]

const renderIGROWRiderBenefitsRow = (rowNumber: number, riderBenefitsRowProps: IGROWRiderBenefitsRowProps) => {
  const { description, value } = riderBenefitsRowProps

  const benefitValue = value.split('\n').map((text) => {
    if (_.includes(textValueRows, rowNumber)) {
      return <div key={`v_${text}-${rowNumber}`}>{text}</div>
    }
    return (
      <div key={`v_${text}-${rowNumber}`} className="center-align">
        {text}
      </div>
    )
  })

  const benefitDescription = description.split('\n').map((text) => {
    if (_.includes(headerRows, rowNumber)) {
      return <div key={`${text}-${rowNumber}`}>{text}</div>
    } else if (_.includes(subHeaderRows, rowNumber)) {
      return (
        <div key={`${text}-${rowNumber}`} className="two-tab">
          {text}
        </div>
      )
    }
    return (
      <div key={`${text}-${rowNumber}`} className="one-tab">
        {text}
      </div>
    )
  })

  if (rowNumber === 24 || rowNumber === 25) {
    return (
      <tr key={`igrow-rider-benefit-row-${rowNumber}`} id={`igrow-rider-benefit-row-${rowNumber}`}>
        <td>
          <strong>{benefitDescription}</strong>
        </td>
        <td>{benefitValue}</td>
      </tr>
    )
  }

  if (value === '') {
    return (
      <tr key={`igrow-rider-benefit-row-${rowNumber}`} id={`igrow-rider-benefit-row-${rowNumber}`}>
        <td colSpan={2}>{benefitDescription}</td>
      </tr>
    )
  }

  if (value === '*') {
    return (
      <tr key={`igrow-rider-benefit-row-${rowNumber}`} id={`igrow-rider-benefit-row-${rowNumber}`}>
        <td>{benefitDescription}</td>
        <td>{''}</td>
      </tr>
    )
  }

  return (
    <tr key={`igrow-rider-benefit-row-${rowNumber}`} id={`igrow-rider-benefit-row-${rowNumber}`}>
      <td>{benefitDescription}</td>
      <td>{benefitValue}</td>
    </tr>
  )
}

const renderIGROWRiderBenefitsTable = (benefits: Benefit[], planCode: number) => {
  return (
    <div className="details-table">
      <table id="igrow-benefit-table">
        <thead>
          <tr>
            <td>{MESSAGES.BENEFIT_LIST}</td>
            <td>
              {MESSAGES.BENEFIT_TITLE_IGROW} ({planCode.toLocaleString()})
            </td>
          </tr>
        </thead>
        <tbody>
          {benefits.map((benefit, index) => {
            return renderIGROWRiderBenefitsRow(index, {
              description: isObject(benefit) && isNotNil(benefit.description) ? benefit.description : '',
              value: isObject(benefit) && isNotNil(benefit.value) ? benefit.value : '',
            })
          })}
        </tbody>
      </table>
    </div>
  )
}

const IGROWRiderBenefit = ({ rider, planCode, benefits }: IGROWRiderBenefitProps) => {
  const title = `${MESSAGES.BENEFIT_HEADER_IGROW}`

  const benefitTable = benefits ? renderIGROWRiderBenefitsTable(benefits, planCode) : null
  return (
    <RiderWithBenefitModal
      id="igrow-rider-benefit"
      className="rider igrow-rider"
      title={title}
      modalChildren={benefitTable}
    >
      <RiderBenefitSummary rider={rider} />
      {benefitTable}
    </RiderWithBenefitModal>
  )
}

export default IGROWRiderBenefit
