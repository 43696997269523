// @flow

import { connect } from 'react-redux'
import type { BenefitIllustrationProps } from './components/benefit-illustration'
import BenefitIllustration from './components/benefit-illustration'
import { getSelectedDisplayProduct } from 'quick-quote/product-selection/selectors'
import { getHealthCheckDisplay, getFinancialDocumentDisplay } from './selectors'
import type { AppState } from 'quick-quote/reducers'
import { getLoanTerm, getSumAssured, getCoverageTerm, getPremium } from 'quick-quote/product-loan/coverage-plan/redux'

const mapStateToProps = (state: AppState) =>
  ({
    displayProduct: getSelectedDisplayProduct(state),
    sumAssured: getSumAssured(state),
    coverageTerm: getCoverageTerm(state),
    loanTerm: getLoanTerm(state),
    basicPremium: getPremium(state),
    healthCheckList: getHealthCheckDisplay(state),
    financialCheckList: getFinancialDocumentDisplay(state),
  }: BenefitIllustrationProps)

const mapDispatchToProps = () => ({})

export const LoanBenefitIllustrationContainer = connect(mapStateToProps, mapDispatchToProps)(BenefitIllustration)
