// @flow
import type { RuleType } from 'core/data-model/validation'

import _ from 'lodash'

type Rule<D, A> = (D, A) => boolean
type Message<D, A> = string | ((D, A) => string)

type Spec<D, A> = [string, { rule: Rule<D, A>, type: RuleType, message: Message<D, A> }]

type Specs<D, A> = Spec<D, A>[]

export type Checker<D, A> = (D, A) => Checked<A>

export type Dependencies = { [dependency: string]: * }

export interface Checked<A> {
  +value: A;
  +is: { [string]: boolean };
  +isOk: boolean;

  getErrorMessages(errorType?: RuleType): string[];
  getAllMessages(): Array<{ type: string, message: string }>;
}

export const valid = <A>(value: A): Checked<A> => ({
  value,
  is: {},
  isOk: true,
  getErrorMessages: () => [],
  getAllMessages: () => [],
})

export const invalid = <A>(value: A): Checked<A> => ({
  value,
  is: {},
  isOk: false,
  getErrorMessages: () => [],
  getAllMessages: () => [],
})

export class CheckedImpl<D, A> implements Checked<A> {
  value: A
  dependencies: D
  is: { [string]: boolean }
  isOk: boolean
  specs: Specs<D, A>

  constructor(dependencies: D, specs: Specs<D, A>, value: A) {
    ;(this: *).value = value
    ;(this: *).dependencies = dependencies
    ;(this: *).is = specs
      .map(([key, { rule }]) => ({ [key]: rule(this.dependencies, this.value) }))
      .reduce((acc, curr) => _.merge(acc, curr), {})
    ;(this: *).isOk = specs
      .filter(([, { type }]) => type !== 'ADDITIONAL_INFORMATION')
      .map(([key, { rule }]) => rule(this.dependencies, this.value))
      .reduce((acc, curr) => acc && curr, true)
    ;(this: *).specs = specs
  }

  getAllMessages() {
    const templatizeWhenItIsAFunction = (msg) => {
      return typeof msg === 'string' ? msg : msg(this.dependencies, this.value)
    }

    const invalidErrorMessages = this.specs
      .filter(([, { rule }]) => rule(this.dependencies, this.value) === false)
      .map(([, { type, message }]) => ({
        type,
        message: templatizeWhenItIsAFunction(message),
      }))

    return invalidErrorMessages
  }

  getErrorMessages(selectedType?: RuleType) {
    const validationCondition = (type: string) => selectedType === type

    const allowAllErrorsWhenNoSelectedType = selectedType === undefined

    const invalidErrorMessages = this.specs
      .filter(([, { type }]) => allowAllErrorsWhenNoSelectedType || validationCondition(type))
      .filter(([, { rule }]) => rule(this.dependencies, this.value) === false)
      .map(([, { message }]) => message)

    const templatizeWhenItIsAFunction = (msg) => {
      return typeof msg === 'string' ? msg : msg(this.dependencies, this.value)
    }

    return invalidErrorMessages.map(templatizeWhenItIsAFunction)
  }

  equals(other: CheckedImpl<D, A>) {
    return (
      _.isEqual(this.value, other.value) &&
      _.isEqual(this.dependencies, other.dependencies) &&
      _.isEqual(this.is, other.is) &&
      _.isEqual(this.isOk, other.isOk) &&
      _.isEqual(this.specs, other.specs)
    )
  }
}

export const createChecker = <D, A>(specs: Specs<D, A>): Checker<D, A> => (dependencies: D, value: A) =>
  new CheckedImpl(dependencies, specs, value)
