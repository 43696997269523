import _ from 'lodash'
import moment from 'moment'

import { formatNumber } from 'core/service/lib/number-format'
import { RISK_OPTIONS, MWP_PRODUCTS } from 'quick-quote/my-wealth-plus/constant'
import { getCustomerProfileDetail } from 'e-submission/domain/service/mywealth-plus' // gift implement
import { getMwPlusProfileList } from 'e-submission/domain/service/mwPlus' // p'ex implement

export const formatMwpDetail = (data) => {
  if (!data) return

  const formattedCoverageCurrency = formatNumber(data.coverage)
  const formattedDate = moment(data.updateAt).format('DD/MM/YYYY HH:mm')
  const formattedProductCode = data.productCode.toLowerCase()

  return {
    ...data,
    coverage: formattedCoverageCurrency || '',
    createdAt: formattedDate || '',
    productCode: formattedProductCode || '',
  }
}

export const formatAndSortCustomerProfileDataByDate = (data = []) => {
  const sortedData = data.sort((a, b) => new Date(b.createAt) - new Date(a.createAt))

  const formattedData = sortedData.map(formatMwpDetail)
  return formattedData
}

export const sortFundListByAlowcationAndName = (fundList) => {
  const sortedData = fundList.sort((a, b) => {
    if (b.allocation === a.allocation) {
      return a.fundCode.localeCompare(b.fundCode)
    }
    return b.allocation - a.allocation
  })

  return sortedData
}

export const getInvestmentRiskDetail = (rpqScore = 0) => {
  const matchedOption = _.find(RISK_OPTIONS, ({ minScore, maxScore }) => _.inRange(rpqScore, minScore, maxScore + 1))

  return matchedOption
}

export const mappingProductMwpWithAz = (mwpProductName) =>
  MWP_PRODUCTS.find(({ productCode }) => productCode == (mwpProductName || '').toLowerCase())

export const getProductDetail = (azCode, azProductList) => azProductList.find(({ code }) => code == azCode)

//
export const getCustomerList = async (userData, selectedRange) => {
  const payload = {
    agentCode: userData.profile.agent_code,
    range: selectedRange.value,
  }

  return await getMwPlusProfileList(userData, payload)
    .then((res) => {
      if (res.apiStatus === 'FAILED') {
        return null
      }

      const formatdata = formatAndSortCustomerProfileDataByDate(res.data)

      return formatdata
    })
    .catch((err) => console.error('oh no', err))
}

export const getCustomerDetail = async (user, customerId) => {
  const resp = await getCustomerProfileDetail(user, customerId).catch((e) => undefined)
  const customerDetail = _.get(resp, 'response.data')

  if (!customerDetail) {
    return
  }

  const formatdata = formatMwpDetail(customerDetail)
  return formatdata
}

export const formatDateOfBirth = (date) => {
  return moment(date).format('DD/MM/YYYY')
}

export const getGenderMwPlus = (gender) => {
  return gender === 'male' ? 'M' : 'F'
}

export const getToggleRidersFromMWPlus = (riders) => {
  const arr = Object.keys(riders).map((key) => {
    const code = key.toUpperCase() + 'UDR'
    const { coverage, premium, active } = riders[key]

    return { code, sumAssured: coverage, premium, active }
  })

  return arr
}

export const mapRiderBenefitsFromMWPlus = (rider) => {
  const optionsHBUDR = [
    { value: 500, text: '500', planCode: 'HBUDR1' },
    { value: 1000, text: '1,000', planCode: 'HBUDR2' },
    { value: 2000, text: '2,000', planCode: 'HBUDR3' },
    { value: 3000, text: '3,000', planCode: 'HBUDR4' },
    { value: 4000, text: '4,000', planCode: 'HBUDR5' },
    { value: 5000, text: '5,000', planCode: 'HBUDR6' },
  ]
  const optionsHSUDR = [
    { value: 1500, text: '1,500', planCode: 'HSUDR1' },
    { value: 2500, text: '2,500', planCode: 'HSUDR2' },
    { value: 3500, text: '3,500', planCode: 'HSUDR3' },
    { value: 4500, text: '4,500', planCode: 'HSUDR4' },
    { value: 6500, text: '6,500', planCode: 'HSUDR5' },
  ]

  if (rider.code === 'HSUDR') {
    return optionsHSUDR.find((value) => {
      return value.value === Number(rider.sumAssured)
    })
  } else if (rider.code === 'HBUDR') {
    return optionsHBUDR.find((value) => {
      return value.value === Number(rider.sumAssured)
    })
  }
}

export const mapPaymentPeriodToModelFactor = (paymentPeriod) => {
  switch (paymentPeriod) {
    case 12:
      return 'model-factor_1'
    case 6:
      return 'model-factor_2'
    case 1:
      return 'model-factor_3'
  }
}

const getQueryParam = (string) => {
  const queryString = string.replace('?', '')
  const param = queryString.split('&')
  const paramObj = {}
  param.map((item) => {
    const [key, value] = item.split('=')
    _.set(paramObj, [`${key}`], value)
  })
  return paramObj
}

export const getDeeplinkInfo = (history) => {
  const isDeeplink = localStorage.getItem('isDeeplink') === 'true'
  const search = _.get(history, ['location', 'search'], null)
  const deeplinkParam = isDeeplink
    ? JSON.parse(localStorage.getItem('deeplinkParam'))
    : getQueryParam(_.get(history, ['location', 'search'], ''))
  const deeplinkCustomerProfileId = _.get(deeplinkParam, ['id'], null)
  const deeplinkAgentId = _.get(deeplinkParam, ['ag'], null)

  return {
    isDeeplink,
    search,
    deeplinkCustomerProfileId,
    deeplinkAgentId,
  }
}
