// @flow

import ProductBenefitsSummary from './product-benefits-summary'
import MainContractBenefits from 'quick-quote/product-common/benefit-illustration/components/product-benefits/main-contract-benefit'
import type { ProductBenefitProps } from 'quick-quote/product-common/benefit-illustration/components/product-benefits/types'
import VALUES from 'core/data-model/constants/values'

const ProductBenefits = ({
  displayProduct,
  sumAssured,
  basicPremium,
  calculatedPaymentPeriod,
  calculatedCoveragePeriod,
  policyValue,
  benefitSummaryHeader,
}: ProductBenefitProps) => {
  return (
    <div id="product-benefits" className="benefit group">
      <ProductBenefitsSummary
        displayProduct={displayProduct}
        sumAssured={sumAssured}
        basicPremium={basicPremium}
        calculatedPaymentPeriod={calculatedPaymentPeriod}
        calculatedCoveragePeriod={calculatedCoveragePeriod}
        policyValue={policyValue}
        benefitSummaryHeader={benefitSummaryHeader}
      />
      <br />
      {VALUES.PRODUCT_GIO.includes(displayProduct.code) && (
        <MainContractBenefits displayProduct={displayProduct} sumAssured={sumAssured} />
      )}
    </div>
  )
}

export default ProductBenefits
