import { compact } from 'lodash/fp'

import { createSectionCreator, createField } from '../utils'
import { checker } from '../selectors'

const reasonInputField = (id) =>
  createField(`${id}.detail`, [
    {
      label: 'โปรดระบุรายละเอียด',
      id: `${id}.detail`,
      c: 'TextInput',
      p: {},
    },
  ])

const getQuestions = (app, id) => {
  const { isYes, isNo } = checker(app)
  return compact([
    createField(`${id}.wasRejected.checked`, [
      {
        id: `${id}.wasRejected.checked`,
        c: 'RadioButton',
        required: true,
        label:
          'ท่านเคยถูกปฏิเสธ เลื่อนการรับประกันภัย เพิ่มอัตราเบี้ยประกันภัย เปลี่ยนแปลงเงื่อนไขสำหรับการขอเอาประกันภัย หรือการขอกลับคืนสู่สถานะเดิม หรือการขอต่ออายุของกรมธรรม์ จากบริษัทอื่นหรือไม่',
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
    isYes(`${id}.wasRejected.checked`) && reasonInputField(`${id}.wasRejected`),
    createField(`${id}.treatment.label`, [
      {
        id: `${id}.treatment.label`,
        c: 'PlainText',
        p: {
          children:
            'ท่านเคยได้รับการวินิจฉัย หรือรับการรักษา หรือตั้งข้อสังเกตโดยแพทย์ว่าป่วยเป็นโรคตามรายการท้ายคำถามนี้หรือไม่',
        },
      },
    ]),
    createField(`${id}.treatmentS7.a.checked`, [
      {
        id: `${id}.treatmentS7.a.checked`,
        c: 'RadioButton',
        required: true,
        label:
          'โรคความดันโลหิตสูง โรคหัวใจ โรคเส้นเลือดหัวใจตีบ โรคหลอดเลือด โรคหลอดเลือดในสมอง โรคอัมพฤกษ์/โรคอัมพาต โรคชัก โรคเลือด โรคเบาหวาน',
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
    createField(`${id}.treatmentS7.b.checked`, [
      {
        id: `${id}.treatmentS7.b.checked`,
        c: 'RadioButton',
        required: true,
        label: 'โรคต่อมน้ำเหลืองโต เนื้องอก ก้อนหรือถุงน้ำ โรคมะเร็ง',
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
    createField(`${id}.treatmentS7.c.checked`, [
      {
        id: `${id}.treatmentS7.c.checked`,
        c: 'RadioButton',
        required: true,
        label:
          'โรคแผลในทางเดินอาหาร โรคตับหรือทางเดินน้ำดี โรคต้อหิน โรคปอดหรือปอดอักเสบ โรคไต โรคจิต โรคประสาท โรคพาร์กินสัน โรคสมองเสื่อมชนิดอัลไซเมอร์ ภาวะสมองเสื่อมขั้นรุนแรง',
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
    createField(`${id}.treatmentS7.d.checked`, [
      {
        id: `${id}.treatmentS7.d.checked`,
        c: 'RadioButton',
        required: true,
        label: 'โรคข้ออักเสบ โรคเอดส์หรือภูมิคุ้มกันบกพร่อง พิการทางร่างกาย',
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
    createField(`${id}.treatmentS7.e.checked`, [
      {
        id: `${id}.treatmentS7.e.checked`,
        c: 'RadioButton',
        required: true,
        label: 'โรควัณโรค โรคพิษสุราเรื้อรัง',
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
    createField(`${id}.healthSymptom.label`, [
      {
        id: `${id}.healthSymptom.label`,
        c: 'PlainText',
        p: {
          children: 'ท่านเคยมี หรือกำลังมีอาการ ดังต่อไปนี้หรือไม่',
        },
      },
    ]),
    createField(`${id}.healthSymptom.a.checked`, [
      {
        id: `${id}.healthSymptom.a.checked`,
        c: 'RadioButton',
        required: true,
        label: 'เจ็บหรือแน่นหน้าอก',
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
    isYes(`${id}.healthSymptom.a.checked`) && reasonInputField(`${id}.healthSymptom.a.checked`),
    createField(`${id}.healthSymptom.b.checked`, [
      {
        id: `${id}.healthSymptom.b.checked`,
        c: 'RadioButton',
        required: true,
        label: 'ใจสั่น',
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
    isYes(`${id}.healthSymptom.b.checked`) && reasonInputField(`${id}.healthSymptom.b.checked`),
    createField(`${id}.healthSymptom.c.checked`, [
      {
        id: `${id}.healthSymptom.c.checked`,
        c: 'RadioButton',
        required: true,
        label: 'ไอเป็นเลือด',
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
    isYes(`${id}.healthSymptom.c.checked`) && reasonInputField(`${id}.healthSymptom.c.checked`),
    createField(`${id}.healthSymptom.d.checked`, [
      {
        id: `${id}.healthSymptom.d.checked`,
        c: 'RadioButton',
        required: true,
        label: 'ไอเรื้อรัง',
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
    isYes(`${id}.healthSymptom.d.checked`) && reasonInputField(`${id}.healthSymptom.d.checked`),
    createField(`${id}.healthSymptom.e.checked`, [
      {
        id: `${id}.healthSymptom.e.checked`,
        c: 'RadioButton',
        required: true,
        label: 'อาเจียนหรือถ่ายเป็นเลือด',
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
    isYes(`${id}.healthSymptom.e.checked`) && reasonInputField(`${id}.healthSymptom.e.checked`),
    createField(`${id}.haveDiagnosisS7.checked`, [
      {
        label:
          'ท่านกำลังได้รับการรักษา หรือตั้งใจที่จะปรึกษาแพทย์ หรือได้รับคำแนะนำจากแพทย์ให้ไปรับการรักษา หรือรอผลการทดสอบ หรือรอการสืบสวน สำหรับเงื่อนไขใด ๆ ตามที่ระบุไว้ด้านบนหรือไม่',
        id: `${id}.haveDiagnosisS7.checked`,
        c: 'RadioButton',
        required: true,
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
    createField(`${id}.relativeHealthCheckS7.checked`, [
      {
        label:
          'บิดา มารดา พี่น้องร่วมบิดาและ/หรือมารดา ของท่านเคยได้รับการวินิจฉัยจากแพทย์ว่าเป็นหรือเคยเป็นโรคหัวใจ โรคหลอดเลือด โรคมะเร็ง โรคเบาหวาน โรคไต โรคความดันโลหิตสูง การพยายามฆ่าตัวตายหรืออาการป่วยทางจิต โรคเลือด โรคพาร์กินสัน โรคสมองเสื่อมชนิดอัลไซเมอร์ โรคของเซลล์ประสาทควบคุมการเคลื่อนไหว หรือโรคไวรัสตับอักเสบ ก่อนอายุ 60 ปี หรือไม่',
        id: `${id}.relativeHealthCheckS7.checked`,
        c: 'RadioButton',
        required: true,
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
  ])
}

export default createSectionCreator(getQuestions)
