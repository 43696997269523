//@flow
//TODO [B] create unittest
import _ from 'lodash'
import MESSAGES from 'core/data-model/constants/bi-messages'
import type { Table } from 'core/service/pdf-generation/utils/builder'
import { transformOrderListToString, buildHeaderTable } from 'core/service/pdf-generation/utils/builder'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { RiderBenefitData } from 'core/data-model/rider/benefits'
import { translatePlanToThai } from 'core/service/rider/benefits/benefit-data/fh'

type FHBenefitProps = {
  rider: Rider & RiderState,
  riderBenefitData: RiderBenefitData,
}
type Benefit = {
  description: string,
  value: string,
}

export const FHBenefitSection = ({ rider, riderBenefitData }: FHBenefitProps) => {
  const planName = _.get(rider, 'selectedPlan.plan')
  const headers = [MESSAGES.BENEFIT_LIST, `${MESSAGES.BENEFIT_TITLE} (${translatePlanToThai(planName)})`]
  const benefits = _.get(riderBenefitData, 'benefits')
  const notes = _.get(riderBenefitData, 'notes')
  return [
    {
      table: {
        headerRows: 1,
        widths: ['*', 250],
        body: [
          buildHeaderTable(headers),
          ...benefits.map((benefit) => buildFHBenefitsRow(benefit)),
          [{ colSpan: 2, text: MESSAGES.FLEXI_HEALTH_BENEFIT_AREA_COVERAGE_TITLE }, {}],
          [
            [MESSAGES.FLEXI_HEALTH_BENEFIT_OUT_AREA_COVERAGES[0]],
            { text: MESSAGES.FLEXI_HEALTH_BENEFIT_OUT_AREA_COVERAGES[1], alignment: 'center', margin: [0, 0] },
          ],
          [
            [MESSAGES.FLEXI_HEALTH_BENEFIT_COMPENSATIONS[0]],
            [
              { text: MESSAGES.FLEXI_HEALTH_BENEFIT_COMPENSATIONS[1][0], alignment: 'left', margin: [0, 0] },
              { text: MESSAGES.FLEXI_HEALTH_BENEFIT_COMPENSATIONS[1][1], alignment: 'left', margin: [0, 0] },
            ],
          ],
        ],
      },
      layout: 'lightBorder',
      style: 'table',
    },
    {
      stack: [{ text: MESSAGES.NOTE }, { stack: notes }],
      marginTop: 15,
    },
  ]
}

const buildFHBenefitsRow = (benefit: Benefit) => {
  return _.get(benefit, 'value') === ''
    ? [{ colSpan: 2, text: _.get(benefit, 'description') }, {}]
    : [[benefit.description], { text: _.get(benefit, 'value'), alignment: 'center', margin: [0, 0] }]
}

export const modifiedLastRowTable = (table: Table) => _modifiedLastRowTable(table, transformOrderListToString)

export const _modifiedLastRowTable = (table: Table, transform: Function) => {
  const body = table.body
  const position = body.length
  const lastRow = _.last(body)
  const transformedRow = [transform(lastRow[0], 0), lastRow[1]]

  const newBody = [...body.slice(0, position - 1), transformedRow]
  return { ...table, body: newBody }
}
