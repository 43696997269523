import { createSectionCreator, createField } from 'e-submission/domain/data-model/form/utils'

const certifyByAgentFields = (app, id) => {
  return [
    createField(`${id}.certifyByAgent`, [
      {
        id: `${id}.certifyByAgent.isCertify`,
        title: 'กรุณาทำเครื่องหมายในช่องการยืนยันการระบุและพิสูจน์ทราบตัวตนของลูกค้า',
        c: 'Checkbox',
        p: {
          children:
            'ข้าพเจ้าขอรับรองว่า ข้าพเจ้าได้ดำเนินการระบุและพิสูจน์ทราบตัวตนของผู้เอาประกันภัยเป็นที่เรียบร้อยแล้ว',
        },
      },
    ]),
  ]
}

export const certifyByAgentSection = createSectionCreator(certifyByAgentFields)
