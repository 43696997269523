import { connect } from 'react-redux'
import _ from 'lodash'
import { Flex, Box } from 'rebass'
import { Input } from 'reactstrap'
import styled from 'styled-components'
import Button from '../Button'
import SubHeading from '../Heading/SubHeading'
import { getIcon } from '../Icon'
import { prepareEkycDetailResult } from 'e-submission/utils'

const FlexModified = styled(Flex)`
  flex-wrap: nowrap;

  @media (max-width: 535px) {
    flex-wrap: wrap;
  }
`
const Container = styled.div`
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: relative;

  &::before {
    content: '';
    display: block;
    border-top: solid 1px ${({ theme }) => theme.variables['$color-silver']};
    position: absolute;
    top: 1px;
    left: 1rem;
    right: 1rem;
  }

  @media (max-width: 535px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`
const Footer = styled.div`
  display: flex;
  justify-content: center;
`
const DetailContainer = styled.div`
  width: 100%;
  padding-left: 1.5rem;

  @media (max-width: 535px) {
    padding-left: 0;
  }
`

const SubHeadingModified = styled(SubHeading)`
  color: ${({ theme }) => theme.variables['$brand-primary']};
`
export const DetailBackCitizenId = ({ detail, laserCode }) => (
  <Container>
    <div>{getIcon('citizenIdBack')}</div>
    <DetailContainer>
      <SubHeadingModified>หมายเลขหลังบัตรประชาชน</SubHeadingModified>
      <Flex width={[1, 1 / 2]} pr={[0, 1]}>
        <Input value={laserCode} disabled />
      </Flex>
    </DetailContainer>
  </Container>
)
export const DetailFrontCitizenId = ({ detail, frontIdCardImage }) => (
  <Container>
    <div style={{ paddingBottom: '14px' }}>
      {detail?.frontIdCardResult?.idCardImageUrl ? (
        <img src={frontIdCardImage} width="276" height="161" />
      ) : (
        getIcon('citizenIdFront')
      )}
    </div>
    <DetailContainer>
      <SubHeadingModified>ข้อมูลบัตรประชาชน</SubHeadingModified>
      <FlexModified mt={1}>
        <Box mr={[0, 1]} width={1}>
          คำนำหน้า <Input value={detail.frontIdCardResult.titleTh} disabled />
        </Box>
        <Box width={1}></Box>
      </FlexModified>
      <FlexModified mt={1}>
        <Box mr={[0, 1]} width={1}>
          ชื่อ <Input value={detail.frontIdCardResult.firstNameTh} disabled />
        </Box>
        <Box mt={[1, 0]} width={1}>
          นามสกุล <Input value={detail.frontIdCardResult.lastNameTh} disabled />
        </Box>
      </FlexModified>
      <FlexModified mt={1}>
        <Box mr={[0, 1]} width={1}>
          หมายเลขบัตรประชาชน <Input value={detail.frontIdCardResult.citizenId} disabled />
        </Box>
        <Box mt={[1, 0]} width={1}>
          เกิดวันที่ <Input value={detail.frontIdCardResult.dateOfBirth} disabled />
        </Box>
      </FlexModified>
      <FlexModified column mt={1}>
        ที่อยู่ <Input value={detail.frontIdCardResult.address} disabled />
      </FlexModified>
    </DetailContainer>
  </Container>
)

const getDOPAResultIcon = (verified, dopaRequestFailed) => {
  if (verified === null && dopaRequestFailed === null) {
    return 'citizenIdFront'
  }

  if (verified && !dopaRequestFailed) {
    return 'success'
  }

  if (!verified && !dopaRequestFailed) {
    return 'error'
  }

  if (dopaRequestFailed) {
    return 'warning'
  }
}

export const DetailDopa = ({ detail }) => {
  const dopaMap = {
    icon: {
      success: 'ekycDopaSuccess',
      error: 'ekycDopaError',
      warning: 'ekycDopaWarning',
      citizenIdFront: 'citizenIdFront',
    },
    reason: {
      success: 'ยืนยันตัวตนกับกรมการปกครอง (DOPA) สำเร็จ DOPA Verification successful',
      error:
        'Lorem ipsum dolor sit amet, consetetur sadipscing elite, sed diam nonumy eirmod tempor invidunt ut labore et',
      warning: 'api reason',
    },
  }
  return (
    <Container>
      <div>
        {detail?.dopaResult
          ? getIcon(
              dopaMap.icon[getDOPAResultIcon(detail?.dopaResult?.verified, detail?.dopaResult?.dopaRequestFailed)]
            )
          : getIcon('citizenIdFront')}
      </div>
      <DetailContainer>
        <SubHeadingModified>บัตรประชาชนตรวจสอบเรียบร้อย</SubHeadingModified>
        <div>{detail.dopaResult.message}</div>
      </DetailContainer>
    </Container>
  )
}

const mapStateToProps = (state, props) => {
  const frontIdCardImage = _.get(state, `ui.setFrontIdCardImage.${props.insuredOrPayer}.frontIdCardImage`, null)
  const laserCode = _.get(state, `ui.setBackIdCardLaserCode.${props.insuredOrPayer}.laserCode`, '-')
  return { frontIdCardImage, laserCode }
}

export const _EkycDetail = ({ detail, isShowDetail, setShowEkycDetail, frontIdCardImage, laserCode }) => {
  const preparedDetail = prepareEkycDetailResult(detail)

  if (document.body.classList.contains('modal-open')) {
    document.body.classList.remove('modal-open')
  }

  return (
    <div>
      {isShowDetail ? (
        <div>
          <DetailFrontCitizenId detail={preparedDetail} frontIdCardImage={frontIdCardImage} />
          <DetailBackCitizenId detail={preparedDetail} laserCode={laserCode} />
          <DetailDopa detail={preparedDetail} />
          <Footer>
            <Button
              outline
              icon="arrowUp"
              onClick={() => {
                setShowEkycDetail()
              }}
            >
              แสดงข้อมูล
            </Button>
          </Footer>
        </div>
      ) : null}
    </div>
  )
}

export default connect(mapStateToProps, null)(_EkycDetail)
