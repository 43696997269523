// @flow

import _ from 'lodash'

import type {
  RegularPremium,
  RegularTopUp,
  SumAssured,
  LumpSumValidationResult,
  ExpectedReturnComparisonWithoutLumpSum,
} from 'core/service/investment'

// ==== action types ====

export type Action =
  | EditRegularPremium
  | EditRegularPremiumRatio
  | UpdateRegularPremium
  | UpdateRegularTopUp
  | SelectModelFactor
  | EditRegularTopUp
  | EditSumAssured
  | UpdateSumAssured
  | EditLumpSum
  | UpdateLumpSums
  | UpdateExpectedReturnComparisons
  | UpdateRPUDRExpectedReturnComparisons
  | ResetExpectedReturnComparisons
  | ResetRPUDRExpectedReturnComparisons

export const EDIT_REGULAR_TOP_UP = 'INVESTMENT/EDIT_REGULAR_TOP_UP'
export type EditRegularTopUp = {
  type: 'INVESTMENT/EDIT_REGULAR_TOP_UP',
  payload: number,
}

export const EDIT_REGULAR_PREMIUM = 'INVESTMENT/EDIT_REGULAR_PREMIUM'
export type EditRegularPremium = {
  type: 'INVESTMENT/EDIT_REGULAR_PREMIUM',
  payload: number,
}

export const EDIT_REGULAR_PREMIUM_RATIO = 'INVESTMENT/EDIT_REGULAR_PREMIUM_RATIO'
export type EditRegularPremiumRatio = {
  type: 'INVESTMENT/EDIT_REGULAR_PREMIUM_RATIO',
  payload: number,
}

export const UPDATE_REGULAR_PREMIUM = 'INVESTMENT/UPDATE_REGULAR_PREMIUM'
export type UpdateRegularPremium = {
  type: 'INVESTMENT/UPDATE_REGULAR_PREMIUM',
  payload: RegularPremium,
}

export const UPDATE_SUM_ASSURED = 'INVESTMENT/UPDATE_SUM_ASSURED'
export type UpdateSumAssured = {
  type: 'INVESTMENT/UPDATE_SUM_ASSURED',
  payload: SumAssured,
}

export const UPDATE_REGULAR_TOP_UP = 'INVESTMENT/UPDATE_REGULAR_TOP_UP'
export type UpdateRegularTopUp = {
  type: 'INVESTMENT/UPDATE_REGULAR_TOP_UP',
  payload: RegularTopUp,
}

export const SELECT_MODEL_FACTOR = 'INVESTMENT/SELECT_MODEL_FACTOR'
export type SelectModelFactor = {
  type: 'INVESTMENT/SELECT_MODEL_FACTOR',
  payload: string,
}

export const PREVIOUS_MODEL_FACTOR = 'INVESTMENT/PREVIOUS_MODEL_FACTOR'
export type PreviousModelFactor = {
  type: 'INVESTMENT/PREVIOUS_MODEL_FACTOR',
  payload: string,
}

export const EDIT_SUM_ASSURED = 'INVESTMENT/EDIT_SUM_ASSURED'
export type EditSumAssured = {
  type: 'INVESTMENT/EDIT_SUM_ASSURED',
  payload: number,
}

export const EDIT_LUMP_SUM = 'INVESTMENT/EDIT_LUMP_SUM'
export type EditLumpSum = {
  type: 'INVESTMENT/EDIT_LUMP_SUM',
  payload: { year: number, value: number },
}

export const UPDATE_LUMP_SUMS = 'INVESTMENT/UPDATE_LUMP_SUMS'
export type UpdateLumpSums = {
  type: 'INVESTMENT/UPDATE_LUMP_SUMS',
  payload: LumpSumValidationResult,
}

export const UPDATE_EXPECTED_RETURN_COMPARISONS = 'INVESTMENT/UPDATE_EXPECTED_RETURN_COMPARISONS'
export type UpdateExpectedReturnComparisons = {
  type: 'INVESTMENT/UPDATE_EXPECTED_RETURN_COMPARISONS',
  payload: ExpectedReturnComparisonWithoutLumpSum[],
}

export const UPDATE_RPUDR_EXPECTED_RETURN_COMPARISONS = 'INVESTMENT/UPDATE_RPUDR_EXPECTED_RETURN_COMPARISONS'
export type UpdateRPUDRExpectedReturnComparisons = {
  type: 'INVESTMENT/UPDATE_RPUDR_EXPECTED_RETURN_COMPARISONS',
  payload: ExpectedReturnComparisonWithoutLumpSum[],
}

export const RESET_EXPECTED_RETURN_COMPARISONS = 'INVESTMENT/RESET_EXPECTED_RETURN_COMPARISONS'
export type ResetExpectedReturnComparisons = {
  type: 'INVESTMENT/RESET_EXPECTED_RETURN_COMPARISONS',
}

export const RESET_RPUDR_EXPECTED_RETURN_COMPARISONS = 'INVESTMENT/RESET_RPUDR_EXPECTED_RETURN_COMPARISONS'
export type ResetRPUDRExpectedReturnComparisons = {
  type: 'INVESTMENT/RESET_RPUDR_EXPECTED_RETURN_COMPARISONS',
}

// ==== action creators ====
export const editSumAssured = (sumAssured: number): EditSumAssured => ({
  type: EDIT_SUM_ASSURED,
  payload: _.toFinite(sumAssured),
})

export const editRegularTopUp = (regularTopUp: number): EditRegularTopUp => ({
  type: EDIT_REGULAR_TOP_UP,
  payload: _.toFinite(regularTopUp),
})

export const editRegularPremium = (regularPremium: number): EditRegularPremium => ({
  type: EDIT_REGULAR_PREMIUM,
  payload: _.toFinite(regularPremium),
})

export const editRegularPremiumRatio = (regularPremiumRatio: number): EditRegularPremiumRatio => ({
  type: EDIT_REGULAR_PREMIUM_RATIO,
  payload: _.toFinite(regularPremiumRatio),
})

export const updateSumAssured = (sumAssured: number, errors: Object[]): UpdateSumAssured => ({
  type: UPDATE_SUM_ASSURED,
  payload: { value: sumAssured, errors },
})

export const updateRegularPremium = (regularPremium: number, errors: Object[]): UpdateRegularPremium => ({
  type: UPDATE_REGULAR_PREMIUM,
  payload: { value: regularPremium, errors },
})

export const updateRegularTopUp = (regularTopUp: number, errors: Object[]): UpdateRegularTopUp => ({
  type: UPDATE_REGULAR_TOP_UP,
  payload: { value: regularTopUp, errors },
})

export const selectModelFactor = (modelFactorID: string): SelectModelFactor => ({
  type: SELECT_MODEL_FACTOR,
  payload: modelFactorID,
})

export const previousModelFactor = (modelFactorID: string): PreviousModelFactor => ({
  type: PREVIOUS_MODEL_FACTOR,
  payload: modelFactorID,
})

export const editLumpSum = (lumpSum: { year: number, value: number }): EditLumpSum => ({
  type: EDIT_LUMP_SUM,
  payload: lumpSum,
})

export const updateLumpSums = (lumpSumValidationResult: LumpSumValidationResult): UpdateLumpSums => ({
  type: UPDATE_LUMP_SUMS,
  payload: lumpSumValidationResult,
})

export const updateExpectedReturnComparisons = (
  expectedReturnComparisons: ExpectedReturnComparisonWithoutLumpSum[]
): UpdateExpectedReturnComparisons => ({
  type: UPDATE_EXPECTED_RETURN_COMPARISONS,
  payload: expectedReturnComparisons,
})

export const updateRPUDRExpectedReturnComparisons = (
  expectedReturnComparisons: ExpectedReturnComparisonWithoutLumpSum[]
): UpdateRPUDRExpectedReturnComparisons => ({
  type: UPDATE_RPUDR_EXPECTED_RETURN_COMPARISONS,
  payload: expectedReturnComparisons,
})

export const resetExpectedReturnComparisons = (): ResetExpectedReturnComparisons => ({
  type: RESET_EXPECTED_RETURN_COMPARISONS,
})

export const resetRPUDRExpectedReturnComparisons = (): ResetRPUDRExpectedReturnComparisons => ({
  type: RESET_RPUDR_EXPECTED_RETURN_COMPARISONS,
})
