//@flow
import _ from 'lodash'
import { flow, constant, stubTrue, cond, max } from 'lodash/fp'

import type { PolicyPeriod, PremiumRPUDR } from 'core/data-model/investment/types'
import type Decimal from 'decimal.js'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { ProductConfig, CashFlowDependencies } from './cash-flow'
import { netAllocationPremium, shouldPayPremium, mergeProps } from './cash-flow'
import type {
  InforceRPUDR,
  RidersPremium,
  AccountValueBenefitDeduct,
  RedemptionValueAtBeginningMonth,
  InvestmentReturn,
  RedemptionValueAtMonthEnd,
  BonusPremium,
  Investment,
  PolicyChargesRPUDR,
  NetAllocation,
  CoiAndCor,
  InsuranceManagementFee,
  CashFlowRPUDR,
  CashOfValueProps,
  FindCoiRate,
  CostOfRider,
  FindCorRate,
  CostOfRiderHBUDR,
  CostOfRiderHSUDR,
  CostOfRiderCIUDR,
  CostOfRiderTPDUDR,
  GenerateCoiAndCorDependenciers,
  CalculateBonusPremiumRPP,
  CalculateBonusPremiumLSTU,
  Surrender,
  GenerateDeathBenefit,
} from './rpudr-cash-flow-types'

import { d } from 'core/service/lib/decimal'
import DEFAULTS from 'core/data-model/constants/defaults'
import VALUES from 'core/data-model/constants/values'
import { regularPremiumCharge, lumpSumTopUpCharge } from './premium-charge'
import { round, roundDown } from 'core/service/lib/number-format'
import { adminChargeRPP } from './account-value'
import { rpudrInforce, rpudrRiderInforce } from './inforce'

const { INFORCE } = VALUES
const {
  NO_LAPSE_GUARANTEE,
  NO_LAPSE_GUARANTEE_WITH_MI,
  MINIMUM_ISSUE_AGE_CIUDR,
  MAXIMUM_ISSUE_AGE_CIUDR,
  MINIMUM_ISSUE_AGE_TPDUDR,
  MAXIMUM_ISSUE_AGE_TPDUDR,
  MINIMUM_ISSUE_AGE_HSUDR,
  MAXIMUM_ISSUE_AGE_HSUDR,
  MINIMUM_ISSUE_AGE_HBUDR,
  MAXIMUM_ISSUE_AGE_HBUDR,
  MAXIMUM_COVERAGE_AGE_RPUDR,
  MAXIMUM_COVERAGE_AGE_CIUDR,
  MAXIMUM_COVERAGE_AGE_TPDUDR,
  MAXIMUM_COVERAGE_AGE_HSUDR,
  MAXIMUM_COVERAGE_AGE_HBUDR,
} = INFORCE
const ACCOUNT_VALUE_BD_RATE = 0.05
const REDEMPTION_VALUE_RPP_DEATH_BENEFIT_RATE = 1.05
const riderInforceValueChecks = {
  CIUDR: {
    minAge: MINIMUM_ISSUE_AGE_CIUDR,
    maxAge: MAXIMUM_ISSUE_AGE_CIUDR,
    maxCoverageAge: MAXIMUM_COVERAGE_AGE_CIUDR,
  },
  TPDUDR: {
    minAge: MINIMUM_ISSUE_AGE_TPDUDR,
    maxAge: MAXIMUM_ISSUE_AGE_TPDUDR,
    maxCoverageAge: MAXIMUM_COVERAGE_AGE_TPDUDR,
  },
  HSUDR: {
    minAge: MINIMUM_ISSUE_AGE_HSUDR,
    maxAge: MAXIMUM_ISSUE_AGE_HSUDR,
    maxCoverageAge: MAXIMUM_COVERAGE_AGE_HSUDR,
  },
  HBUDR: {
    minAge: MINIMUM_ISSUE_AGE_HBUDR,
    maxAge: MAXIMUM_ISSUE_AGE_HBUDR,
    maxCoverageAge: MAXIMUM_COVERAGE_AGE_HBUDR,
  },
}

export const checkRiderIsMatch = (riderCode) => {
  return [VALUES.CIUDR, VALUES.TPDUDR, VALUES.HSUDR, VALUES.HBUDR].includes(riderCode)
}

export const getTotalRiderPremium = (ridersPremium): number => {
  return ridersPremium.reduce((acc, rider) => {
    return acc + rider.premium
  }, 0)
}

export const getRiderPremiums = (riders: RidersPremium[], inforce: boolean): RidersPremium[] => {
  return riders.filter((rider) => inforce[rider.code] && inforce.basic)
}

export const premiumForPolicyPeriodUdr = (
  paymentInterval: number,
  { age, year, month }: PolicyPeriod,
  inputRegularPremium: Decimal,
  lumpSumByYears: { year: number, value: Decimal }[],
  riders: RidersPremium[],
  inforce: InforceRPUDR
): PremiumRPUDR => {
  const lumpSumForGivenYear = _.find(lumpSumByYears, { year: year }) || {
    value: d(0),
  }
  const needPayment = shouldPayPremium(paymentInterval, month)
  const regularPremium = needPayment && inforce.basic ? inputRegularPremium : d(0)
  const lumpSum = month === 1 ? lumpSumForGivenYear.value : d(0)
  const ridersPremiums = needPayment ? getRiderPremiums(riders, inforce) : []
  const riderTotalPremium = getTotalRiderPremium(ridersPremiums)
  const totalRegularAndRiderPremium = regularPremium.plus(riderTotalPremium)
  return {
    inforce,
    regularPremium,
    totalRegularAndRiderPremium,
    lumpSum,
    ridersPremiums,
    riderTotalPremium,
    totalPremium: totalRegularAndRiderPremium.plus(lumpSum),
  }
}

const hasRiderWithMI = (riders) => {
  let result = false
  riders.map((rider) => {
    if (rider.isSelectable === true && rider.isSelected === true && rider.code === 'HSUDR') result = true
  })
  return result
}

export const generateInforce = (
  { age, paymentInterval, regularPremium, lumpSum }: CashFlowDependencies,
  config,
  prev: CashFlowRPUDR,
  expectedReturn: number,
  inflationPercentage: number
) => ({ policyPeriod, riders }: CashFlowRPUDR): InforceRPUDR => {
  const { age: attainedAge, year } = policyPeriod
  let prevPolicyPeriod
  let prevYear = 0,
    prevMonth = 0

  if (!_.isUndefined(prev)) {
    prevPolicyPeriod = _.get(prev, 'policyPeriod')
    prevYear = prevPolicyPeriod.year
    prevMonth = prevPolicyPeriod.month
  }

  let noLapseGuarantee
  if (hasRiderWithMI(riders)) {
    if (expectedReturn === 3) noLapseGuarantee = NO_LAPSE_GUARANTEE_WITH_MI
    else if (!_.isUndefined(inflationPercentage)) {
      if (expectedReturn === 2 && inflationPercentage === 5) noLapseGuarantee = NO_LAPSE_GUARANTEE_WITH_MI
      else noLapseGuarantee = NO_LAPSE_GUARANTEE
    } else noLapseGuarantee = NO_LAPSE_GUARANTEE
  } else {
    noLapseGuarantee = NO_LAPSE_GUARANTEE
  }

  const accountValuePlusBonusAtMonthEnd = _.get(prev, 'investment.redemptionValueAtMonthEndPlusBonus.total', 0)
  const checkBasicInforce = rpudrInforce(
    year,
    attainedAge,
    noLapseGuarantee,
    accountValuePlusBonusAtMonthEnd,
    MAXIMUM_COVERAGE_AGE_RPUDR,
    prevYear,
    prevMonth
  )
  const selectableRidersInProduct = config.selectableRidersInProduct.map((riderName) => riderName.split('rider_')[1])
  const riderInforceValues = {}
  riders.forEach((rider) => {
    if (checkRiderIsMatch(rider.code)) {
      const { minAge, maxAge, maxCoverageAge } = riderInforceValueChecks[rider.code]
      const riderInforce = rpudrRiderInforce(age, attainedAge, minAge, maxAge, maxCoverageAge, checkBasicInforce)
      riderInforceValues[rider.code] =
        rider.isSelectable === true && rider.isSelected === true && selectableRidersInProduct.includes(rider.code)
          ? riderInforce
          : false
    }
  })

  return {
    basic: checkBasicInforce,
    ...riderInforceValues,
  }
}

export const generatePremium = ({ paymentInterval, regularPremium, lumpSum }: CashFlowDependencies) => ({
  policyPeriod,
  riders,
  inforce,
}: CashFlowRPUDR): PremiumRPUDR =>
  premiumForPolicyPeriodUdr(paymentInterval, policyPeriod, regularPremium, lumpSum, riders, inforce)

const findRider = (code: string, riders: RidersPremium[]): RidersPremium => {
  const rider = riders.find((v) => v.code === code)
  return rider ? rider : { code: '', premium: d(0), sumAssured: d(0) }
}

export const generateRidersPremium = ({ riders }): (Rider & RiderState)[] => (): RidersPremium[] => {
  return riders.map(({ code, premium, isSelected, isSelectable, sumAssured, yearsOfCoverage, yearsOfPayment }) => ({
    code,
    premium: isSelectable ? (isSelected ? premium : 0) : 0,
    sumAssured,
    isSelectable,
    isSelected,
    yearsOfCoverage,
    yearsOfPayment,
  }))
}

export const generatePolicyCharges = ({ feeStructure }: CashFlowDependencies) => ({
  policyPeriod,
  premium,
}: CashFlowRPUDR): PolicyChargesRPUDR => {
  const lumpSumCharge = d(round(lumpSumTopUpCharge(premium.lumpSum, feeStructure), 2))
  const regularPremiumChargeOnTop = d(
    round(regularPremiumCharge(policyPeriod.year, premium.totalRegularAndRiderPremium, feeStructure), 2)
  )
  const premiumCharge = d(round(lumpSumCharge.plus(regularPremiumChargeOnTop), 2))
  return {
    lumpSumCharge,
    regularPremiumCharge: regularPremiumChargeOnTop,
    total: premiumCharge,
  }
}

export const generateNetAllocationPremium = () => ({ premium, policyCharges }: CashFlowRPUDR): NetAllocation => {
  return {
    rppPremium: netAllocationPremium(premium.totalRegularAndRiderPremium, policyCharges.regularPremiumCharge),
    lstuPremium: premium.lumpSum.minus(policyCharges.lumpSumCharge),
  }
}

const isSetCashOfValueToZero = ({ year, previousAccountValuePlusBonusAtMonthEnd, age }: CashOfValueProps): boolean =>
  (year > NO_LAPSE_GUARANTEE && previousAccountValuePlusBonusAtMonthEnd <= 0) || year > 85 - age //year condition should remove because we pre generate not over 84 years

const findCoiRate = ({ coiRates, gender, age }: FindCoiRate): { age: number, rate: Decimal } => {
  const coiRate = _.find(coiRates[gender], { age })
  if (!coiRate) {
    return { age: 0, rate: 0 }
  }
  return coiRate
}

export const costOfInsurance = (props: CashOfValueProps): number => {
  const { sumAssured, coiRate, accountValueBeforeDeductionRPP, previousDebtAtTheEndOfTheMonth } = props
  const accountValueRPPminusDebt = Math.max(
    0,
    previousDebtAtTheEndOfTheMonth.plus(accountValueBeforeDeductionRPP).toNumber()
  )
  return flow(
    cond([
      [isSetCashOfValueToZero, constant(0)],
      [
        stubTrue,
        constant(
          roundDown(
            d(
              roundDown(
                d(
                  roundDown(
                    d(sumAssured)
                      .plus(adminChargeRPP(d(accountValueRPPminusDebt), ACCOUNT_VALUE_BD_RATE))
                      .div(1000),
                    3
                  )
                ).times(coiRate.rate),
                2
              )
            ).div(12),
            2
          )
        ),
      ],
    ])
  )(props)
}

export const costOfRider = ({ riderSumAssured, coiRate }: CostOfRider): number => {
  return round(
    d(riderSumAssured)
      .times(coiRate.rate)
      .div(1000)
      .div(12),
    2
  )
}

export const findCorRate = ({ corRates, riderCode }: FindCorRate) => {
  return _.find(corRates, { code: riderCode })
}

export const calculateTPDCOR = (sumAssured: number, rate: number) => {
  return roundDown(
    d(sumAssured)
      .times(rate)
      .div(1000)
      .div(12),
    2
  )
}

export const calculateCICOR = (sumAssured: number, rate: number) => {
  return roundDown(
    d(sumAssured)
      .div(1000)
      .times(0.5)
      .times(rate)
      .div(12)
      .toNumber(),
    2
  )
}

export const calculateHBCOR = (rate: number) =>
  roundDown(
    d(rate)
      .div(12)
      .toNumber(),
    2
  )

export const costOfRiderHBUDR = ({
  corRates,
  gender,
  age,
  occupationFactor,
  ridersState,
}: CostOfRiderHBUDR): number => {
  const cor = findCorRate({ corRates, riderCode: VALUES.HBUDR })
  if (!cor) {
    throw new Error('HBCOR Rate missing.')
  }
  const hbudr = _.find(ridersState, { code: VALUES.HBUDR })
  const corRateByPlanCode = _.get(cor.corRates, `${hbudr.selectedPlan.planCode}`, false)
  const corRate = _.get(
    corRateByPlanCode.occupationFactor[`${occupationFactor.toFixed(1)}`],
    `gender.${gender}.age.${age}`,
    false
  )

  if (!corRate) {
    return 0
  }

  return calculateHBCOR(corRate)
}

export const calculateHSCOR = (rate: number): number =>
  roundDown(
    d(rate)
      .div(12)
      .toNumber(),
    2
  )

export const calculateHSCORWithInflationRate = (rate: number, inflationRate: number, year: number): number => {
  const calRate = d(rate).div(12)
  const calInflation = d(1).plus(inflationRate)
  const calYear = d(year).minus(1)
  return roundDown(calInflation.pow(calYear).times(calRate), 2)
}

export const costOfRiderHSUDR = ({
  corRates,
  gender,
  age,
  occupationFactor,
  ridersState,
  medicalInflationRate,
  year,
}: CostOfRiderHSUDR): number => {
  const cor = findCorRate({ corRates, riderCode: VALUES.HSUDR })
  if (!cor) {
    throw new Error('HSCOR Rate missing.')
  }
  const hsudr = _.find(ridersState, { code: VALUES.HSUDR })
  const corRateByPlanCode = _.get(cor.corRates, `${hsudr.selectedPlan.planCode}`, false)
  const corRate = _.get(
    corRateByPlanCode.occupationFactor[`${occupationFactor.toFixed(1)}`],
    `gender.${gender}.age.${age}`,
    false
  )

  if (!corRate) {
    return 0
  }

  return !_.isUndefined(medicalInflationRate)
    ? calculateHSCORWithInflationRate(corRate, medicalInflationRate, year)
    : calculateHSCOR(corRate)
}

export const costOfRiderTPDUDR = ({ corRates, riderSumAssured, gender, policyPeriod }: CostOfRiderTPDUDR) => {
  const cor = findCorRate({ corRates, riderCode: VALUES.TPDUDR })

  if (!cor) {
    throw new Error('TPDUR Rate missing.')
  }

  if (policyPeriod.age >= 80) {
    return d(0)
  }

  const rate = _.get(cor.corRates, `${gender}.${policyPeriod.age}`, false)

  if (!rate) {
    throw new Error('COR TPD rate missing.')
  }

  return d(calculateTPDCOR(riderSumAssured, rate))
}

export const costOfRiderCIUDR = ({ riderSumAssured, corRates, gender, policyPeriod }: CostOfRiderCIUDR): number => {
  const cor = findCorRate({ corRates, riderCode: VALUES.CI })
  if (!cor) {
    throw new Error('CICOR Rate missing.')
  }

  const cormRate = _.get(cor.corRates, `CICORM.${gender}.${policyPeriod.age}`, false)
  const coreRate = _.get(cor.corRates, `CICORE.${gender}.${policyPeriod.age}`, false)
  const corbRate = _.get(cor.corRates, `CICORB.${gender}.${policyPeriod.age}`, false)
  const corrRate = _.get(cor.corRates, `CICORR.${gender}.${policyPeriod.age}`, false)

  if (!cormRate || !coreRate || !corbRate || !corrRate) {
    throw new Error('COR Sub Rate missing.')
  }

  return d(calculateCICOR(riderSumAssured, cormRate))
    .plus(calculateCICOR(riderSumAssured, coreRate))
    .plus(calculateCICOR(riderSumAssured, corbRate))
    .plus(calculateCICOR(riderSumAssured, corrRate))
}

export const generateCoiAndCor = (
  {
    sumAssured,
    coiRates,
    corRates,
    gender,
    age,
    riderOccupationFactors,
    ridersState,
    medicalInflationRate,
  }: GenerateCoiAndCorDependenciers,
  prev: CashFlowRPUDR
) => ({ policyPeriod, accountValueBeforeDeduction, riders, inforce }: CashFlowRPUDR): CoiAndCor => {
  const coiRate = findCoiRate({ coiRates, gender, age: policyPeriod.age })
  const coi = d(
    costOfInsurance({
      age,
      year: policyPeriod.year,
      coiRate,
      sumAssured,
      accountValueBeforeDeductionRPP: accountValueBeforeDeduction.rpp,
      previousRedemptionValueAtMonthEndPlusBonusRPP: d(
        _.get(prev, 'investment.redemptionValueAtMonthEndPlusBonus.rpp', 0)
      ),
      previousDebtAtTheEndOfTheMonth: d(_.get(prev, 'debt.atTheEndOfTheMonth', 0)),
    })
  )

  const corCI = inforce.CIUDR
    ? d(costOfRiderCIUDR({ riderSumAssured: findRider('CIUDR', riders).sumAssured, corRates, gender, policyPeriod }))
    : d(0)
  const corTPD = inforce.TPDUDR
    ? coi.toNumber() <= 0
      ? d(0)
      : d(
          costOfRiderTPDUDR({ riderSumAssured: findRider('TPDUDR', riders).sumAssured, corRates, gender, policyPeriod })
        )
    : d(0)

  const corHS = inforce.HSUDR
    ? d(
        costOfRiderHSUDR({
          corRates,
          gender,
          age: policyPeriod.age,
          occupationFactor: riderOccupationFactors[VALUES.HSUDR],
          medicalInflationRate,
          ridersState,
          year: policyPeriod.year,
        })
      )
    : d(0)
  const corHB = inforce.HBUDR
    ? d(
        costOfRiderHBUDR({
          corRates,
          gender,
          age: policyPeriod.age,
          occupationFactor: riderOccupationFactors[VALUES.HBUDR],
          ridersState,
        })
      )
    : d(0)

  const coiRounding = {
    coi: d(round(coi, 2)),
    corCI: d(round(corCI, 2)),
    corTPD: d(round(corTPD, 2)),
    corHS: d(round(corHS, 2)),
    corHB: d(round(corHB, 2)),
  }

  return {
    ...coiRounding,
    total: coiRounding.coi
      .plus(coiRounding.corCI)
      .plus(coiRounding.corTPD)
      .plus(coiRounding.corHS)
      .plus(coiRounding.corHB),
  }
}

export const generateAccountValueBenefitDeduct = (prev: CashFlowRPUDR) => ({
  premium,
  policyCharges,
  netAllocation,
  policyPeriod,
  inforce,
}: CashFlowRPUDR): AccountValueBenefitDeduct => {
  const previousRedemptionValueAtMonthEndPlusBonusRPP = d(
    _.get(prev, 'investment.redemptionValueAtMonthEndPlusBonus.rpp', 0)
  )
  const previousRedemptionValueAtMonthEndPlusBonusLSTU = d(
    _.get(prev, 'investment.redemptionValueAtMonthEndPlusBonus.lstu', 0)
  )
  const previousDebtAtTheEndOfTheMonth = d(_.get(prev, 'debt.atTheEndOfTheMonth', 0))
  const rpp = inforce.basic
    ? previousRedemptionValueAtMonthEndPlusBonusRPP.plus(netAllocation.rppPremium).toNumber()
    : 0
  const lstu = inforce.basic
    ? previousRedemptionValueAtMonthEndPlusBonusLSTU.plus(netAllocation.lstuPremium).toNumber(0)
    : 0
  const rppDeductDebt = Math.max(0, previousDebtAtTheEndOfTheMonth.plus(rpp).toNumber())
  const lstuDeductRemainingDebt =
    rpp > 0 ? lstu : Math.max(d(lstu).minus(Math.abs(previousDebtAtTheEndOfTheMonth.plus(rpp).toNumber())), 0)

  return {
    rpp: d(rpp),
    lstu: d(lstu),
    total: d(rpp).plus(lstu),
    rppDeductDebt: d(rppDeductDebt),
    lstuDeductRemainingDebt: d(lstuDeductRemainingDebt),
  }
}

export const generateRedemptionValueAtBeginningMonth = ({
  policyPeriod: { age, year },
  accountValueBeforeDeduction,
  insuranceManagementFee,
  coiCor,
}: CashFlowRPUDR): RedemptionValueAtBeginningMonth => {
  const valueOfACRPPminusCOIminusIMF = d(
    accountValueBeforeDeduction.rppDeductDebt.minus(coiCor.total).minus(insuranceManagementFee.total)
  )
  const rpp = valueOfACRPPminusCOIminusIMF.toNumber() >= 0 ? valueOfACRPPminusCOIminusIMF : 0

  const lstu =
    valueOfACRPPminusCOIminusIMF.toNumber() >= 0
      ? accountValueBeforeDeduction.lstuDeductRemainingDebt
      : Math.max(0, accountValueBeforeDeduction.lstuDeductRemainingDebt.plus(valueOfACRPPminusCOIminusIMF).toNumber())

  return {
    valueOfACRPPminusCOIminusIMF: valueOfACRPPminusCOIminusIMF,
    rpp: d(round(d(rpp), 2)),
    lstu: d(round(d(lstu), 2)),
  }
}

export const generateRedemptionValueAtMonthEndPlusBonus = (
  redemptionValueAtMonthEnd: RedemptionValueAtMonthEnd,
  bonusPremium: BonusPremium
) => {
  const rpp = redemptionValueAtMonthEnd.rpp.plus(bonusPremium.rpp).plus(bonusPremium.lstu)
  const lstu = redemptionValueAtMonthEnd.lstu
  const total = rpp.plus(lstu) // accountValuePlusBonusAtMonthEnd
  return {
    rpp,
    lstu,
    total,
  }
}

export const calculateInvestmentReturn = (expectedReturn: Decimal, value: Decimal): number => {
  return value.toNumber() < 0
    ? 0
    : round(
        expectedReturn
          // .div(100)
          // ^ Already a percentage value (expectedReturnPercentage) from /core/service/investment/cash-flow/cash-flow
          .plus(1)
          .pow(d(1).div(12))
          .minus(1)
          .times(value),
        2
      )
}

export const generateInvestmentReturn = (
  expectedReturn: Decimal,
  { rpp, lstu }: RedemptionValueAtBeginningMonth
): InvestmentReturn => {
  const expectReturnRPP = d(round(d(calculateInvestmentReturn(expectedReturn, rpp)), 2))
  const expectReturnLSTU = d(round(d(calculateInvestmentReturn(expectedReturn, lstu)), 2))
  return {
    rpp: expectReturnRPP,
    lstu: expectReturnLSTU,
    total: expectReturnRPP.plus(expectReturnLSTU),
  }
}

export const calculateBonusPremium = (
  key: string,
  items: CashFlowRPUDR[],
  currentRedemptionValueAtMonthEndRPP: Decimal
): number => {
  const redemtionsOnPeriod = items.slice(items.length - 11, items.length).map((v) => {
    return _.get(v, key).toNumber()
  })
  redemtionsOnPeriod.push(currentRedemptionValueAtMonthEndRPP.toNumber())

  return d(
    redemtionsOnPeriod.reduce(
      (acc, curr) =>
        d(acc)
          .plus(curr)
          .toNumber(),
      0
    )
  )
    .div(12)
    .times(0.002)
    .toNumber()
}

const isBonusPremiumBeZero = (
  accountValueBDTotal: number,
  redemptionValueAtBeginningMonthRPP: Decimal,
  redemptionValueAtBeginningMonthLSTU: Decimal,
  year: number,
  month: number
) =>
  year < 4 ||
  redemptionValueAtBeginningMonthRPP.plus(redemptionValueAtBeginningMonthLSTU).toNumber() <= 0 ||
  month !== 12

export const calculateBonusPremiumRPP = ({
  year,
  month,
  acc,
  accountValueBDTotal,
  redemptionValueAtBeginningMonth,
  redemptionValueAtMonthEndRPP,
  inforce,
}: CalculateBonusPremiumRPP): number => {
  if (!inforce.basic) {
    return 0
  }
  return isBonusPremiumBeZero(
    accountValueBDTotal.toNumber(),
    redemptionValueAtBeginningMonth.rpp,
    redemptionValueAtBeginningMonth.lstu,
    year,
    month
  )
    ? 0
    : round(calculateBonusPremium('investment.redemptionValueAtMonthEnd.rpp', acc, redemptionValueAtMonthEndRPP), 2)
}

export const calculateBonusPremiumLSTU = ({
  year,
  month,
  acc,
  accountValueBDTotal,
  redemptionValueAtBeginningMonth,
  redemptionValueAtMonthEndLSTU,
  inforce,
}: CalculateBonusPremiumLSTU): number => {
  if (!inforce.basic) {
    return 0
  }
  return isBonusPremiumBeZero(
    accountValueBDTotal.toNumber(),
    redemptionValueAtBeginningMonth.rpp,
    redemptionValueAtBeginningMonth.lstu,
    year,
    month
  )
    ? 0
    : round(calculateBonusPremium('investment.redemptionValueAtMonthEnd.lstu', acc, redemptionValueAtMonthEndLSTU), 2)
}

export const generateBonusPremium = ({
  curr,
  acc,
  redemptionValueAtBeginningMonth,
  redemptionValueAtMonthEnd,
  inforce,
}: GenerateBonusPremium): BonusPremium => {
  const {
    policyPeriod: { year, month },
    accountValueBeforeDeduction: { total },
  } = curr

  return {
    rpp: d(
      calculateBonusPremiumRPP({
        year,
        month,
        acc,
        accountValueBDTotal: total,
        redemptionValueAtBeginningMonth,
        redemptionValueAtMonthEndRPP: redemptionValueAtMonthEnd.rpp,
        inforce,
      })
    ),
    lstu: d(
      calculateBonusPremiumLSTU({
        year,
        month,
        acc,
        accountValueBDTotal: total,
        redemptionValueAtBeginningMonth,
        redemptionValueAtMonthEndLSTU: redemptionValueAtMonthEnd.lstu,
        inforce,
      })
    ),
  }
}

export const generateInvestment = ({ expectedReturn }: CashFlowDependencies, acc: CashFlowRPUDR[]) => (
  props: CashFlowRPUDR
): Investment => {
  const { inforce } = props
  const redemptionValueAtBeginningMonth = generateRedemptionValueAtBeginningMonth(props)
  const investmentReturn = generateInvestmentReturn(expectedReturn, redemptionValueAtBeginningMonth)
  const redemptionValueAtMonthEnd = {
    rpp:
      redemptionValueAtBeginningMonth.rpp.plus(investmentReturn.rpp).toNumber < 0
        ? d(0)
        : redemptionValueAtBeginningMonth.rpp.plus(investmentReturn.rpp),
    lstu: redemptionValueAtBeginningMonth.lstu.plus(investmentReturn.lstu),
  }
  const bonusPremium = generateBonusPremium({
    curr: props,
    acc,
    redemptionValueAtBeginningMonth,
    redemptionValueAtMonthEnd,
    inforce,
  })
  return {
    redemptionValueAtBeginningMonth, // { rpp, lstu }
    investmentReturn,
    redemptionValueAtMonthEnd,
    bonusPremium,
    redemptionValueAtMonthEndPlusBonus: generateRedemptionValueAtMonthEndPlusBonus(
      redemptionValueAtMonthEnd,
      bonusPremium
    ),
  }
}

export const calculateInsuranceManagementFee = (age: number, accountValue: Decimal): Decimal => {
  return age >= 85 || accountValue < 0
    ? 0
    : roundDown(
        d(0.006)
          .div(12)
          .times(accountValue),
        2
      )
}

export const generateInsuranceManagementFee = () => ({
  policyPeriod,
  accountValueBeforeDeduction,
}: CashFlowRPUDR): InsuranceManagementFee => {
  const rpp = d(calculateInsuranceManagementFee(policyPeriod.age, accountValueBeforeDeduction.rppDeductDebt))
  const lstu = d(calculateInsuranceManagementFee(policyPeriod.age, accountValueBeforeDeduction.lstuDeductRemainingDebt))
  return {
    rpp,
    lstu,
    total: d(rpp).plus(lstu),
  }
}

export const getSurrenderCharge = (year: number): number => {
  const chargeList = {
    '1': 50,
    '2': 30,
    '3': 10,
    '4': 5,
  }
  return chargeList[year] ? chargeList[year] : 0
}

export const calculateSurrenderRPP = (
  surrenderCharge: Decimal,
  redemptionValueAtMonthEndPlusBonusRPP: Decimal
): number => {
  return round(
    d(redemptionValueAtMonthEndPlusBonusRPP).minus(
      redemptionValueAtMonthEndPlusBonusRPP.times(surrenderCharge).div(100)
    ),
    2
  )
}

export const calculateSurrenderLSTU = () => {}

export const generateSurrender = () => ({
  policyPeriod: { year },
  investment: {
    redemptionValueAtMonthEndPlusBonus: { rpp, lstu },
  },
}: CashFlowRPUDR): Surrender => {
  const charge = d(getSurrenderCharge(year))
  return {
    charge,
    rpp: rpp <= 0 ? d(0) : d(calculateSurrenderRPP(charge, rpp)),
    lstu: lstu <= 0 ? d(0) : d(lstu),
  }
}

export const generateDeathBenefit = ({ sumAssured }: GenerateDeathBenefit) => ({
  accountValueBeforeDeduction,
  investment,
  policyPeriod: { year, age },
  inforce,
}: CashFlowRPUDR): Decimal => {
  const rpp = max([
    0,
    d(investment.redemptionValueAtMonthEndPlusBonus.rpp)
      .times(REDEMPTION_VALUE_RPP_DEATH_BENEFIT_RATE)
      .toNumber(),
  ])
  const lstu = max([0, d(investment.redemptionValueAtMonthEndPlusBonus.lstu).toNumber()])
  return age >= 85 || !inforce.basic
    ? d(0)
    : d(
        round(
          d(sumAssured)
            .plus(rpp)
            .plus(lstu),
          2
        )
      )
}

export const generateDebt = (prev: CashFlowRPUDR) => ({
  policyPeriod: { year },
  accountValueBeforeDeduction,
  investment,
}) => {
  const previousDebtAtTheEndOfTheMonth = d(_.get(prev, 'debt.atTheEndOfTheMonth', 0))
  const { rpp: accountValueBDRPP, lstu: accountValueBDLSTU, lstuDeductRemainingDebt } = accountValueBeforeDeduction
  const { redemptionValueAtBeginningMonth, investmentReturn } = investment
  const afterDeducting =
    year > NO_LAPSE_GUARANTEE
      ? d(0)
      : Math.min(
          accountValueBDRPP
            .plus(previousDebtAtTheEndOfTheMonth)
            .plus(accountValueBDLSTU)
            .toNumber(),
          0
        )
  const atTheEndOfTheMonth =
    year > NO_LAPSE_GUARANTEE
      ? d(0)
      : Math.min(
          redemptionValueAtBeginningMonth.valueOfACRPPminusCOIminusIMF
            .plus(lstuDeductRemainingDebt)
            .plus(investmentReturn.rpp)
            .plus(afterDeducting)
            .toNumber(),
          0
        )

  return {
    afterDeducting,
    atTheEndOfTheMonth,
  }
}

export const processRPUDRCashFlow = (
  config: ProductConfig,
  mergeProps: void,
  cashFlowDependencies: CashFlowDependencies,
  expectedReturn: number,
  inflationPercentage: number
) => {
  const age = cashFlowDependencies.age
  const policyPeriodLengthInYears = config.coveragePeriodByAge - age
  const policyYears = _.range(1, policyPeriodLengthInYears + 1)

  const getPolicyPeriodForYear = (year) =>
    _.range(1, DEFAULTS.MONTHS_IN_YEAR + 1).map((month) => ({ policyPeriod: { year, month, age: age + year - 1 } }))

  return _(policyYears)
    .flatMap(getPolicyPeriodForYear)
    .reduce(generateRPUDRCashFlow(cashFlowDependencies, config, expectedReturn, inflationPercentage), [])
}

const generateRPUDRCashFlow = (
  dependencies: CashFlowDependencies,
  config: ProductConfig,
  expectedReturn: number,
  inflationPercentage: number
) => (acc: CashFlowRPUDR[], curr: CashFlowRPUDR) => {
  const {
    sumAssured,
    coiRates,
    corRates,
    gender,
    age,
    riderOccupationFactors,
    riders,
    medicalInflationRate,
  } = dependencies
  const values = flow(
    mergeProps('riders', generateRidersPremium(dependencies)),
    mergeProps('inforce', generateInforce(dependencies, config, _.last(acc), expectedReturn, inflationPercentage)),
    mergeProps('premium', generatePremium(dependencies)),
    mergeProps('policyCharges', generatePolicyCharges(dependencies)),
    mergeProps('netAllocation', generateNetAllocationPremium()),
    mergeProps('accountValueBeforeDeduction', generateAccountValueBenefitDeduct(_.last(acc))),
    mergeProps(
      'coiCor',
      generateCoiAndCor(
        {
          sumAssured,
          coiRates,
          corRates,
          gender,
          age,
          riderOccupationFactors,
          ridersState: riders,
          medicalInflationRate,
        },
        _.last(acc)
      )
    ),
    mergeProps('insuranceManagementFee', generateInsuranceManagementFee()),
    mergeProps('investment', generateInvestment(dependencies, acc)),
    mergeProps('surrender', generateSurrender()),
    mergeProps('deathBenefit', generateDeathBenefit({ sumAssured })),
    mergeProps('debt', generateDebt(_.last(acc)))
  )(curr)
  return [...acc, values]
}
