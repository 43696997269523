// @flow
import VALUES from 'core/data-model/constants/values'
import type { FundAllocationToggleItem } from 'core/service/investment'
import { requireRADocument } from 'core/service/investment/questionnaire'
import {
  getLstuFundAllocations,
  getSelectedAllocationOption,
} from 'quick-quote/product-investment/fund-allocation/lstu/selectors'
import { getRppFundAllocations } from 'quick-quote/product-investment/fund-allocation/rpp/selectors'
import { hasForeignFunds, hasOverRiskFunds } from 'quick-quote/product-investment/fund-allocation/selectors'
import { isSelectedYesOnForeignExchangeQuestion } from 'quick-quote/product-investment/risk-questionnaire/selectors'
import type { AppState } from 'quick-quote/reducers'

import { createSelector } from 'reselect'

const groupFundByCategory = (fundAllocation: FundAllocationToggleItem[]) => {
  return fundAllocation.reduce((grouped, fund: FundAllocationToggleItem) => {
    const categoryName = fund.fundType.thaiName.trim()
    const sameCategoryNameFound = grouped && grouped.find((x) => x.name === categoryName)

    const _addNewCategory = () => {
      return grouped.concat({ name: categoryName, value: fund.fundAllocation })
    }
    const _foldIntoExistingCategory = () => {
      return grouped.map((g) =>
        g.name === categoryName ? Object.assign(g, { value: g.value + fund.fundAllocation }) : g
      )
    }

    return sameCategoryNameFound ? _foldIntoExistingCategory() : _addNewCategory()
  }, [])
}

export const getAllocationByInvestmentType = createSelector(
  getRppFundAllocations,
  getLstuFundAllocations,
  getSelectedAllocationOption,
  (
    rppFundAllocation: FundAllocationToggleItem[] = [],
    lstuFundAllocation: FundAllocationToggleItem[] = [],
    selectedAllocationOption: String = ''
  ) => {
    const allocations = {
      rpp: rppFundAllocation,
      lstu:
        selectedAllocationOption !== VALUES.LSTU_FUND_ALLOCATION_OPTION.SAME_AS_RPP
          ? lstuFundAllocation
          : rppFundAllocation,
    }
    return allocations
  }
)

// TODO: Deplicated
export const getPieChartFundData = createSelector(
  getRppFundAllocations,
  (fundAllocation: FundAllocationToggleItem[]) => {
    return groupFundByCategory(fundAllocation)
  }
)

export const getAllocationCategoryData = createSelector(
  getRppFundAllocations,
  getLstuFundAllocations,
  getSelectedAllocationOption,
  (
    rppFundAllocation: FundAllocationToggleItem[],
    lstuFundAllocation: FundAllocationToggleItem[],
    selectedAllocationOption: String
  ) => {
    const rppAllocationGrouped = sortFundAllocation(groupFundByCategory(rppFundAllocation))
    const allocations = {
      rpp: rppAllocationGrouped,
      lstu:
        selectedAllocationOption !== VALUES.LSTU_FUND_ALLOCATION_OPTION.SAME_AS_RPP
          ? sortFundAllocation(groupFundByCategory(lstuFundAllocation))
          : rppAllocationGrouped,
    }
    return allocations
  }
)

export const getAllocationOptions = createSelector(
  getSelectedAllocationOption,
  (selectedLstuAllocationOption: String = '') => {
    const options = {}
    if (selectedLstuAllocationOption) {
      options.lstu = selectedLstuAllocationOption
    }
    return options
  }
)

export const sortFundAllocation = (allocation: Array = []) => {
  return allocation.sort((a, b) => {
    if (a.value < b.value) return 1
    else if (a.value === b.value) {
      if (a.name > b.name) return 1
      else return -1
    } else return -1
  })
}

export const requireRAForm = createSelector(
  hasOverRiskFunds,
  hasForeignFunds,
  isSelectedYesOnForeignExchangeQuestion,
  requireRADocument
)

export const shouldShowShareRAButton = createSelector(requireRAForm, (isRequireRAForm) => isRequireRAForm)

// TODO: Deplicated
export const getSortedPieChartFundData = (state: AppState) => {
  return getPieChartFundData(state).sort((a, b) => {
    if (a.value < b.value) return 1
    else if (a.value === b.value) {
      if (a.name > b.name) return 1
      else return -1
    } else return -1
  })
}
