import { compact } from 'lodash/fp'

import { createSectionCreator, createField } from '../utils'
import { checker } from '../selectors'

const reasonInputField = (id) =>
  createField(`${id}.detail`, [
    {
      label: 'โปรดระบุรายละเอียด',
      id: `${id}.detail`,
      c: 'TextInput',
      p: {},
    },
  ])

const getQuestions = (app, id) => {
  const { isYes, isNo } = checker(app)

  return compact([
    createField(`${id}.wasRejected.checked`, [
      {
        id: `${id}.wasRejected.checked`,
        c: 'RadioButton',
        required: true,
        label:
          'ท่านเคยถูกปฏิเสธ เลื่อนการรับประกันภัย เพิ่มอัตราเบี้ยประกันภัย เปลี่ยนแปลงเงื่อนไขสำหรับการขอเอาประกันภัย หรือการขอกลับคืนสู่สถานะเดิม หรือการขอต่ออายุของกรมธรรม์ จากบริษัทนี้หรือบริษัทอื่นหรือไม่',
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
    isYes(`${id}.wasRejected.checked`) && reasonInputField(`${id}.wasRejected`),
    createField(`${id}.wasInjured.checked`, [
      {
        label:
          'ในระหว่าง 5 ปี ที่ผ่านมาท่านเคยได้รับการบาดเจ็บ เจ็บป่วย การผ่าตัด การปรึกษาแพทย์ การแนะนำจากแพทย์เกี่ยวกับ สุขภาพ การรักษาในสถานพยาบาล หรือไม่',
        id: `${id}.wasInjured.checked`,
        c: 'RadioButton',
        required: true,
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
    isYes(`${id}.wasInjured.checked`) && reasonInputField(`${id}.wasInjured`),
    createField(`${id}.wasSmoking.checked`, [
      {
        id: `${id}.wasSmoking.checked`,
        c: 'RadioButton',
        required: true,
        label: 'ท่านสูบ หรือเคย สูบบุหรี่ หรือยาสูบชนิดอื่นหรือไม่',
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
    isYes(`${id}.wasSmoking.checked`) &&
      createField(`${id}.wasSmoking.stillSmoking`, [
        {
          id: `${id}.wasSmoking.stillSmoking`,
          c: 'RadioButton',
          required: true,
          label: 'ปัจจุบันท่านยังสูบหรือไม่',
          p: {
            options: [
              {
                text: 'ใช่',
                value: 'yes',
              },
              {
                text: 'ไม่ใช่',
                value: 'no',
              },
            ],
          },
        },
      ]),
    isYes(`${id}.wasSmoking.checked`) &&
      isNo(`${id}.wasSmoking.stillSmoking`) &&
      createField(`${id}.wasSmoking.stopSmoking`, [
        {
          label: 'ท่านหยุดสูบเมื่อไหร่',
          id: `${id}.wasSmoking.stopSmoking`,
          c: 'DropdownDate',
          required: true,
          p: {},
        },
      ]),
    isYes(`${id}.wasSmoking.checked`) &&
      isYes(`${id}.wasSmoking.stillSmoking`) &&
      createField(`${id}.wasSmoking.duration`, [
        {
          label: 'ท่านสูบมานานเท่าใด',
          id: `${id}.wasSmoking.duration`,
          c: 'DropdownDuration',
          required: true,
          p: {
            placeHolder: 'กรุณาเลือก',
          },
        },
      ]),
    isYes(`${id}.wasSmoking.checked`) &&
      isYes(`${id}.wasSmoking.stillSmoking`) &&
      createField(`${id}.wasSmoking.type.text`, [
        {
          label: 'ท่านสูบผลิตภัณฑ์ยาสูบชนิดใด',
          id: `${id}.wasSmoking.type.text`,
          c: 'TextInput',
          required: true,
          p: {},
        },
      ]),
    isYes(`${id}.wasSmoking.checked`) &&
      isYes(`${id}.wasSmoking.stillSmoking`) &&
      createField(`${id}.wasSmoking.amount`, [
        {
          label: 'ท่านสูบวันละกี่มวน',
          id: `${id}.wasSmoking.amount`,
          c: 'NumberInput',
          required: true,
          p: {
            format: '##',
          },
        },
      ]),
    createField(`${id}.wasDrinking.checked`, [
      {
        id: `${id}.wasDrinking.checked`,
        c: 'RadioButton',
        required: true,
        label: 'ท่านดื่ม หรือเคยดื่มเครื่องดื่มที่มีแอลกอฮอล์เป็นประจำหรือไม่',
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
    isYes(`${id}.wasDrinking.checked`) &&
      createField(`${id}.wasDrinking.type.text`, [
        {
          label: 'โปรดระบุชนิดเครื่องดื่ม',
          required: true,
          id: `${id}.wasDrinking.type.text`,
          c: 'TextInput',
          p: {},
        },
      ]),
    isYes(`${id}.wasDrinking.checked`) &&
      createField(`${id}.wasDrinking.amount`, [
        {
          label: 'โปรดระบุปริมาณการดื่มเฉลี่ยต่อสัปดาห์ (ขวด)',
          required: true,
          id: `${id}.wasDrinking.amount`,
          c: 'NumberInput',
          p: {
            format: '##',
          },
        },
      ]),
    createField(`${id}.useDrug.checked`, [
      {
        label:
          'ท่านเสพ หรือเคยเสพยาเสพติด หรือสารเสพติด หรือไม่ ถ้าใช่ กรุณาระบุชนิด ปริมาณการเสพ ความถี่(ครั้ง/สัปดาห์) และ จำนวนปีที่เสพ ถ้าท่านเลิกเสพแล้ว โปรดระบุวันเดือนปีที่เลิกเสพ',
        id: `${id}.useDrug.checked`,
        c: 'RadioButton',
        required: true,
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
    isYes(`${id}.useDrug.checked`) && reasonInputField(`${id}.useDrug`),
  ])
}

export default createSectionCreator(getQuestions)
