//@flow

import { dataWrapper } from 'core/data'
import type { PromiseFunction } from 'core/data'
import type { Fund, FundCode, InvestmentFundLimits, COIRateTable, CORRateTable } from 'core/data-model/investment'
import type { InvestmentProductConfig } from './types'
import type { DisplayProductQuery } from 'core/service'
const INVESTMENT_FUND_DOCUMENT_KEY = 'investment_fund'
const PRODUCT_DOCUMENT_KEY = 'product'
const { list, get } = dataWrapper

export const _listInvestmentFunds = (list: PromiseFunction<Fund[]>) => list(INVESTMENT_FUND_DOCUMENT_KEY)

export const listInvestmentFunds = (): Promise<Fund[]> => _listInvestmentFunds(list)

export const listCoiRates = (): Promise<COIRateTable[]> => _listCoiRates(list)

export const _listCoiRates = (list: PromiseFunction<COIRateTable[]>) => list('coi-rate')

export const listCorRates = (): Promise<CORRateTable[]> => _listCorRates(list)

export const _listCorRates = (list: PromiseFunction<CORRateTable[]>) => list('cor-rate')

export const _getInvestmentFund = (get: PromiseFunction<Fund>, code: FundCode): Promise<Fund> =>
  get(`${INVESTMENT_FUND_DOCUMENT_KEY}_${code}`)

export const getInvestmentFund = (code: FundCode): Promise<Fund> => _getInvestmentFund(get, code)

export const _getInvestmentProductConfiguration = (
  code: string,
  get: PromiseFunction<InvestmentProductConfig>
): Promise<InvestmentProductConfig> => get(`${PRODUCT_DOCUMENT_KEY}_${code}`)

export const getInvestmentProductConfiguration = (query: DisplayProductQuery): Promise<InvestmentProductConfig> =>
  _getInvestmentProductConfiguration(query.code, get)

export const _getInvestmentFundLimits = (
  query: DisplayProductQuery,
  getRpulProductConfiguration: *
): Promise<InvestmentFundLimits> => {
  return getRpulProductConfiguration(query).then((product) => product.fundAllocationLimits)
}

export const getInvestmentFundLimits = (query: DisplayProductQuery): Promise<InvestmentFundLimits> => {
  return _getInvestmentFundLimits(query, getInvestmentProductConfiguration)
}
