// @flow
import type { Effect } from 'redux-saga/effects'
import { put, select, takeLatest } from 'redux-saga/effects'
import { SELECT_SUKHAPABDEKDEE_PLAN, type SelectSukhapabDekDeePlan } from './actions'
import { getSelectedDisplayProduct } from 'quick-quote/product-selection/selectors'
import _ from 'lodash'
import { editCampaignPlan } from 'quick-quote/product-sukhapabdekdee/actions'
import {
  editRiderSelectedPlan,
  updateSelectedPlan,
  editSumAssured,
  editRiderSumAssured,
} from 'quick-quote/product-common/coverage-plan/actions'

export const selectSukhapabDekDeePlan = function*(action: SelectSukhapabDekDeePlan): Generator<*, *, *> {
  const selectedPlanCode = action.payload
  const displayProduct = yield select(getSelectedDisplayProduct)
  const plans = displayProduct.plans
  const selectedPlan = plans.find((v) => v.planCode === selectedPlanCode)
  if (selectedPlan) {
    displayProduct.selectedCampaignPlan = selectedPlanCode
    yield put(editCampaignPlan(displayProduct))
    yield put(editSumAssured(_.get(selectedPlan, 'sumAssured')))
    yield put(updateSelectedPlan(selectedPlanCode))

    for (let rider of selectedPlan.riders) {
      yield put(editRiderSelectedPlan(rider.code, { planCode: rider.planCode }))
      yield put(editRiderSumAssured(rider.code, rider.sumAssured))
    }
  }
}

export function* watchers(): Generator<*, *, Effect[]> {
  yield [takeLatest([SELECT_SUKHAPABDEKDEE_PLAN], selectSukhapabDekDeePlan)]
}

export const sagas = watchers
