// @flow

import { Scrollspy } from 'common-components'
import { scroller } from 'react-scroll'
import { isNil } from 'core/service/lib/type-check'
import MESSAGES from 'core/data-model/constants/bi-messages'

type Submenu = {
  text: string,
  id: string,
  isSub: boolean,
  to?: string,
}

type MenuProps = Submenu & {
  className?: string,
  scrollOffset?: number,
}

const Menu = ({ text, to, isSub, className, scrollOffset }: MenuProps) => (
  <a
    href={to}
    className={`menu ${className ? className : ''}`}
    onClick={(e) => {
      e.preventDefault()
      scroller.scrollTo(to, {
        offset: scrollOffset,
      })
    }}
  >
    {isSub ? <h6 className="name sub">{text}</h6> : <h5 className="name">{text}</h5>}
  </a>
)

export const getAllSubmenu = (parentSelector: string, childSelector: string): Submenu[] => {
  const dom = document.querySelectorAll(parentSelector)
  let results = []

  if (isNil(dom)) return results
  const domLength = dom.length
  for (let i = 0; i < domLength; i++) {
    const element = dom[i]
    const elementChild = element.querySelector(childSelector)
    if (elementChild) {
      results.push({
        text: elementChild.textContent,
        id: element.id,
        isSub: true,
      })
    }
  }
  return results
}

class SidebarMenu extends React.Component<*, *> {
  static defaultProps = {
    mainMenu: [],
    riderMenu: {
      text: MESSAGES.RIDERS_BENEFITS,
      to: 'riders-benefit',
      isSub: false,
    },
    offset: -148,
    margin: 12,
    parentSelector: '.rider',
    childSelector: '.product .name',
  }

  scrollOffset = 0
  firstParentSelector = ''
  lastMenuId = ''

  state = {
    riders: [],
    isInRiderMenu: false,
    isViewAtBottom: false,
  }

  shouldComponentUpdate(nextProps: Object, nextState: Object) {
    return (
      this.state.riders !== nextState.riders ||
      this.state.isInRiderMenu !== nextState.isInRiderMenu ||
      this.state.isViewAtBottom !== nextState.isViewAtBottom
    )
  }

  UNSAFE_componentWillMount() {
    const { offset, margin, parentSelector, mainMenu } = this.props
    this.scrollOffset = offset + margin
    this.firstParentSelector = parentSelector.replace(/./, '')
    if (mainMenu.length > 0) {
      const { id: lastMenuId } = mainMenu[mainMenu.length - 1]
      this.lastMenuId = lastMenuId
    }
  }

  componentDidMount() {
    const { parentSelector, childSelector } = this.props
    const riders = getAllSubmenu(parentSelector, childSelector)
    this.setState(
      {
        riders,
      },
      this.forceUpdate
    )
  }

  updateSection = (element: HTMLElement) => {
    const { firstParentSelector, lastMenuId } = this
    let isInRiderMenu = false
    let isViewAtBottom = false

    if (element) {
      if (element.className.includes(firstParentSelector)) {
        isInRiderMenu = true
      }
      if (element.id === lastMenuId) {
        isViewAtBottom = true
      }
      this.setState({
        isInRiderMenu,
        isViewAtBottom,
      })
    }
  }

  // $FlowFixMe
  renderMenu = (props) => {
    const to = props.id || props.to
    return <Menu {...props} scrollOffset={this.scrollOffset} to={to} key={to} />
  }

  render() {
    const { mainMenu, riderMenu, offset } = this.props
    const { riders, isInRiderMenu, isViewAtBottom } = this.state
    const mainMenuFilter = mainMenu.reduce(function(filtered, m) {
      if (m.isShow !== false) {
        filtered.push(m)
      }
      return filtered
    }, [])

    const MainMenuComponent = mainMenuFilter.map(this.renderMenu)

    const menu =
      riders.length > 0
        ? MainMenuComponent.slice(0, 2)
            .concat([
              this.renderMenu({
                ...riderMenu,
                className: isInRiderMenu && 'parent-active',
              }),
              ...riders.map(this.renderMenu),
            ])
            .concat(MainMenuComponent.slice(2))
        : MainMenuComponent

    return (
      <div className={`sidebar-menu d-none d-md-block ${isViewAtBottom ? 'at-bottom' : ''}`}>
        <Scrollspy
          className="menu-list"
          componentTag="div"
          items={menu.map(({ props = {} }) => props.id)}
          currentClassName="active"
          offset={offset}
          onUpdate={this.updateSection}
        >
          {menu}
        </Scrollspy>
      </div>
    )
  }
}

export default SidebarMenu
