import { createSectionCreator, createField } from './utils'
import { getQuickQuoteAgentFullName, getQuickQuoteAgentAdvisorCode } from './selectors'

const agentFields = (app, id) => {
  return [
    createField(`${id}.info`, [
      {
        label: 'ตัวแทน',
        id: `${id}.info`,
        c: 'PlainText',
        p: {
          mergeState: (state, app) => {
            const fullName = getQuickQuoteAgentFullName(app)
            const advisorCode = getQuickQuoteAgentAdvisorCode(app)
            return {
              value: `${advisorCode || '-'} - ${fullName || '-'}`,
            }
          },
        },
      },
    ]),
  ]
}
export const agentSection = createSectionCreator(agentFields)

const shareCaseFields = (app, id) => {
  return [
    createField(`${id}.shareCase`, [
      {
        id: `${id}.shareCase`,
        c: 'Button',
        p: {
          icon: 'add',
          children: 'เพิ่มนายหน้า เพื่อแชร์เคส',
          block: true,
          mergeProps: ({ setValue, value }) => ({
            onClick: () => setValue(!!!value),
          }),
        },
      },
    ]),
  ]
}
export const agentShareCaseButton = createSectionCreator(shareCaseFields)

const agentRecommenderFields = (app, id) => {
  return [
    createField(`${id}.recommender`, [
      {
        label: 'รหัสนายหน้า',
        id: `${id}.recommender`,
        c: 'AsyncTypeAhead',
        required: true,
        p: {
          placeholder: '000000 - กรุณากรอกรหัสนายหน้า',
        },
        infoMessage: 'ระบุตัวแทนกรณีเคสคู่เท่านั้น',
      },
    ]),
    createField(
      `${id}.shareCase`,
      [
        {
          id: `${id}.shareCase`,
          c: 'Button',
          p: {
            icon: 'remove',
            children: 'ลบ',
            mergeProps: ({ setValue, unsetValue }) => ({
              onClick: () => {
                setValue(false)
                unsetValue(`${id}.recommender`)
              },
            }),
            style: {
              fontSize: '0.8725rem',
              padding: '0.125rem 0.5rem',
            },
          },
        },
      ],
      {
        style: {
          position: 'absolute',
          top: '0',
          right: '0',
        },
      }
    ),
  ]
}
export const agentRecommenderSection = createSectionCreator(agentRecommenderFields)
