// @flow

import type { WholeLifeProps } from 'core/service/pdf-generation/products/whole-life'
import type { InvestmentProps } from 'core/service/pdf-generation/products/investment/pdf-document'
import type { SavingProps } from 'core/service/pdf-generation/products/saving'
import type { LifeSaveProProps } from 'core/service/pdf-generation/products/life-save-pro'
import type { RetirementProps } from 'core/service/pdf-generation/products/retirement'
import type { MRTAProps } from 'core/service/pdf-generation/products/mrta'
import tableLayouts from 'core/service/pdf-generation/products/common/layout'
import { splitWordsInDocDefinition } from 'core/service/pdf-generation/utils/line-wrapper'
import type { HealthProps } from './products/health'
import type { ProtectionProps } from './products/protection'

export type BIType = 'FULL_BI' | 'MINI_BI'
export type BIProps =
  | WholeLifeProps
  | InvestmentProps
  | HealthProps
  | ProtectionProps
  | SavingProps
  | LifeSaveProProps
  | RetirementProps
  | MRTAProps

// FIXME: write snapshot to test this
const initPdfMake = async () => {
  const pdfMake = await import('pdfmake/build/pdfmake.js')
  const vfsfonts = await import('./vfs-fonts.js')

  global.pdfMake.vfs = vfsfonts.default
  global.pdfMake.fonts = {
    Helvethaica: {
      normal: 'dbhelvethaica55-webfont.woff',
      bold: 'dbhelvethaica75-webfont.woff',
    },
    HelvethaicaLight: {
      normal: 'dbhelvethaica35-webfont.woff',
    },
    PSLTextPro: {
      normal: 'PSL001p0.ttf',
      bold: 'PSL002p0.ttf',
      italics: 'PSL003p0.ttf',
    },
  }
  global.pdfMake.tableLayouts = tableLayouts
  return pdfMake
}

export const createPdfDocument = (createDocDefinition: Function) => async (props: BIProps) => {
  const pdfMake = await initPdfMake()
  const docDefinition = await createDocDefinition(props).then(splitWordsInDocDefinition)

  return pdfMake.createPdf(docDefinition, pdfMake.tableLayouts, pdfMake.fonts, pdfMake.vfs)
}
