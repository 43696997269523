//@flow
import _ from 'lodash'
import {
  hasFinishedInsuredInformation,
  hasInputAllInsuredInformationWithoutErrors,
} from 'quick-quote/insured-information/selectors'
import {
  hasFirstYearLstu,
  isFundPageValid,
  isLumpSumValid,
  isPremiumPageValid,
} from 'quick-quote/product-investment/coverage-plan/selectors'
import { hasFinishedLstuFundSummary } from 'quick-quote/product-investment/fund-allocation/lstu/selectors'
import {
  hasFinishedRppFundAllocation,
  hasFinishedRppFundSummary,
} from 'quick-quote/product-investment/fund-allocation/rpp/selectors'
import { hasFinishedRiskEvaluation } from 'quick-quote/product-investment/risk-questionnaire/selectors'
import { canProceedToInsuredInformation } from 'quick-quote/product-selection/selectors'
import { createSelector } from 'reselect'
import { getUserAgentType } from 'identity/selectors'
import { getAcceptableRiskRange } from './risk-questionnaire/selectors'
import { getAppConfig } from 'deploy-env/app-config'
import { getToggles } from 'quick-quote/feature-toggles'

export const canProceedToPremiumCalculation = createSelector(
  hasFinishedInsuredInformation,
  canProceedToInsuredInformation,
  (isFinishedInsuredInfo: boolean, canProceedToInsuredInformation: boolean): boolean => {
    return isFinishedInsuredInfo && canProceedToInsuredInformation
  }
)
// TODO: Remove this function when old RPQ is no longer used
export const canProceedToRiskQuestionnaire = createSelector(
  hasFinishedInsuredInformation,
  (isFinishedInsuredInformation): boolean => isFinishedInsuredInformation
)
export const canProceedToFundAndCoveragePlan = createSelector(
  hasFinishedRiskEvaluation,
  canProceedToRiskQuestionnaire,
  (hasFinishedRiskEvaluation: boolean, canProceedToRiskQuestionnaire: boolean): boolean =>
    hasFinishedRiskEvaluation && canProceedToRiskQuestionnaire
)

// TODO: Rename to canProceedToRiskQuestionnaire when old canProceedToRiskQuestionnaire has been removed
export const canProceedToRiskQuestionnaireV2 = createSelector(
  isPremiumPageValid,
  canProceedToPremiumCalculation,
  (isPremiumPageValid, canProceedToPremiumCalculation): boolean => {
    return isPremiumPageValid && canProceedToPremiumCalculation
  }
)

// TODO: Remove this function when old RPQ is no longer used
export const canProceedToFundAllocation = createSelector(
  hasFinishedRiskEvaluation,
  canProceedToRiskQuestionnaire,
  (hasFinishedRiskEvaluation: boolean, canProceedToRiskQuestionnaire: boolean): boolean =>
    hasFinishedRiskEvaluation && canProceedToRiskQuestionnaire
)

export const canProceedToRppFundAllocation = createSelector(
  hasFinishedRiskEvaluation,
  canProceedToRiskQuestionnaireV2,
  (hasFinishedRiskEvaluation: boolean, canProceedToRiskQuestionnaire: boolean): boolean =>
    hasFinishedRiskEvaluation && canProceedToRiskQuestionnaire
)

// TODO: hasFinishedRppFundAllocation is the only necessary check? need to check purpose of this function again
export const canProceedToRppFundSummary = createSelector(
  hasFinishedRiskEvaluation,
  canProceedToRiskQuestionnaireV2,
  hasFinishedRppFundAllocation,
  (hasFinishedRiskEvaluation: boolean, canProceedToRiskQuestionnaire: boolean, hasFinishedFundAllocation): boolean =>
    hasFinishedRiskEvaluation && canProceedToRiskQuestionnaire && hasFinishedFundAllocation
)

export const canProceedToLstuFundAllocation = createSelector(
  canProceedToRppFundSummary,
  hasFinishedRppFundSummary,
  (canProceedToFundSummary, finishedRppFundSummary) => canProceedToFundSummary && finishedRppFundSummary
)

export const isInvestmentInputsValid = createSelector(
  isPremiumPageValid,
  isFundPageValid,
  isLumpSumValid,
  canProceedToFundAndCoveragePlan,
  (
    isPremiumPageValid: boolean,
    isFundPageValid: boolean,
    isLumpSumValid: boolean,
    canProceedToFundAndCoveragePlan: boolean
  ): boolean => {
    return isPremiumPageValid && isLumpSumValid && isFundPageValid && canProceedToFundAndCoveragePlan
  }
)

export const canProceedToInvestmentBenefitIllustration = createSelector(
  hasFirstYearLstu,
  canProceedToLstuFundAllocation,
  hasFinishedLstuFundSummary,
  canProceedToRppFundSummary,
  hasFinishedRppFundSummary,
  (
    hasFirstYearLstu,
    canProceedToLstuFundAllocation,
    finishedLstuFundSummary,
    canProceedToRppFundSummary,
    finishedRppFundSummary
  ) =>
    hasFirstYearLstu
      ? canProceedToLstuFundAllocation && finishedLstuFundSummary
      : canProceedToRppFundSummary && finishedRppFundSummary
)

export const canProceedToFnaPhase = createSelector(
  hasInputAllInsuredInformationWithoutErrors,
  (isInputAllInsuredInformationWithoutErrors): boolean => isInputAllInsuredInformationWithoutErrors
)

export const blockSelectedFund = createSelector(
  getUserAgentType,
  getAcceptableRiskRange,
  (getUserAgentType, getAcceptableRiskRange) => {
    const allowedType = getAppConfig().ALLOWED_VALIDATE_RPQ_SCORE
    return (
      getToggles().ENABLE_VALIDATE_RPQ_SCORE &&
      getAcceptableRiskRange.max < 3 &&
      _.includes(allowedType, getUserAgentType)
    )
  }
)
