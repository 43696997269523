// @flow
import type { Profile, DistributorGroupType } from 'core/data-model/distributor'
import type { BIType } from 'core/service/pdf-generation'
import type { DisplayProduct } from 'core/service/display-product'
import { textWidthThaiWord } from 'core/service/lib/pdf-utils'

import MESSAGES from 'core/data-model/constants/bi-messages'
import Mustache from 'mustache'
import { gaPhrase } from '../../utils/agent'
import values from 'core/data-model/constants/values'

const NAME_BOX_WIDTH = 300

type FooterProps = {
  logo: string,
  profile: Profile,
  startDate: string,
  endDate: string,
  datetime: string,
  version: string,
  currentPage: number,
  pageCount: number,
  biType: BIType,
  distributorType: DistributorGroupType,
  basicPlan: DisplayProduct,
}
const footerDisclaimer = (biType: BIType) => {
  if (biType === MESSAGES.BI_TYPE_FULL) {
    return ''
  }
  return { text: MESSAGES.DISCLAIMER_FOOTER, bold: true, color: '#e12e4c' }
}

export const renderFooter = ({
  logo,
  profile,
  startDate,
  endDate,
  datetime,
  version,
  currentPage,
  pageCount,
  biType,
  distributorType,
  basicPlan,
}: FooterProps) => {
  const isLifeTreasure = basicPlan?.code && values.LIFETREASURE_GROUP_CODE.includes(basicPlan?.code)
  const fullName = profile.given_name && profile.family_name ? `${profile.given_name} ${profile.family_name}` : ''
  const thaiNameBreakNeeded = textWidthThaiWord(fullName) > NAME_BOX_WIDTH
  const branchName = distributorType === 'AGENT' ? gaPhrase(profile.ga) : profile.branchName
  const unit = distributorType === 'AGENT' ? profile.unitCode : profile.areaName
  return {
    margin: [30, 15, 30, 0],
    fontSize: 11,
    stack: [
      isLifeTreasure
        ? {
            text: MESSAGES.LIFE_TREASURE_FOOTER,
            fontSize: 8,
          }
        : {},
      {
        text: MESSAGES.PLEASE_READ_NOTICE,
        fontSize: 8,
      },
      {
        marginTop: -2,
        canvas: [
          {
            type: 'line',
            x1: 0,
            y1: 5,
            x2: 615 - 2 * 40,
            y2: 5,
            lineWidth: 0.5,
          },
        ],
      },
      {
        alignment: 'justify',
        marginTop: 6,
        columnGap: 5,
        lineHeight: 0.7,
        columns: [
          {
            marginLeft: 15,
            marginTop: 6,
            image: logo,
            width: 50,
          },
          {
            marginLeft: 20,
            width: 250,
            stack: [
              { text: MESSAGES.COMPANY_NAME, bold: true },
              { text: MESSAGES.COMPANY_ADDRESS_1 },
              { text: MESSAGES.COMPANY_ADDRESS_2 },
              { text: MESSAGES.COMPANY_TELEPHONE_NUMBER },
              { text: `${MESSAGES.CALL_CENTER}  ${MESSAGES.COMPANY_WEB_SITE}` },
              { text: MESSAGES.REGISTRATION_NUMBER },
            ],
          },
          {
            width: 60,
            stack: [
              { text: MESSAGES.PRESENT_BY },
              thaiNameBreakNeeded ? { text: ' ' } : null,
              { text: distributorType === 'AGENT' ? MESSAGES.BRANCH_NAME_AGENT : MESSAGES.BRANCH_NAME_BANC },
              { text: distributorType === 'AGENT' ? MESSAGES.BRANCH_UNIT_AGENT : MESSAGES.BRANCH_UNIT_BANC },
              { text: MESSAGES.TELEPHONE_NUMBER },
              { text: MESSAGES.EMAIL },
            ],
          },
          {
            width: NAME_BOX_WIDTH,
            stack: [
              { text: thaiNameBreakNeeded ? profile.given_name : fullName || '-', bold: true },
              thaiNameBreakNeeded ? { text: profile.family_name, bold: true } : null,
              { text: branchName || '-', bold: true },
              { text: unit || '-', bold: true },
              { text: profile.mobile || '-', bold: true },
              { text: profile.email || '-', bold: true },
            ],
          },
        ],
      },
      {
        marginTop: 3,
        fontSize: 10,
        font: 'PSLTextPro',
        absolutePosition: { x: 30, y: 94 },
        stack: [
          Mustache.render(MESSAGES.DATE_INFORMATION_FOOTER, {
            appVersion: version,
            datetime: datetime,
          }),
          Mustache.render(MESSAGES.DATE_DISCLAIMER_FOOTER, {
            startDate,
            endDate,
          }),
          footerDisclaimer(biType),
        ],
      },
      {
        text: `${MESSAGES.PAGE_NUMBER} ${currentPage} / ${pageCount}`,
        absolutePosition: { x: 525, y: 105 },
        bold: true,
      },
    ],
  }
}
