//@flow
import type { AdvisorApiUserInfo, AuthenticatedUser } from 'core/data-model/identity'
import RequestWrapper from 'core/service/advisorzone-middleware/request-wrapper'
import { getAppConfig } from 'deploy-env/app-config'
import axios from 'axios'
import { getToggles } from 'quick-quote/feature-toggles'

const AuthApi = () => {
  const getUserInformation = (user: AuthenticatedUser): ?AdvisorApiUserInfo => {
    if (getToggles().ENABLE_AUTHORISATION_CODE_FLOW) {
      const instance = axios.create({ withCredentials: true, crossDomain: true })
      // $FlowFixMe
      const path = `${getAppConfig().ADVISORZONE_MIDDLEWARE}/advisors/${user.profile.agent_type}-${
        user.profile.agent_code
      }`
      return instance.get(path).then(({ data }) => data)
    } else {
      if (
        user.profile.agent_type === undefined ||
        user.profile.agent_code === undefined ||
        user.profile.agent_type === null ||
        user.profile.agent_code === null
      )
        return undefined

      const path = `advisors/${user.profile.agent_type}-${user.profile.agent_code}`
      return RequestWrapper.getRequest(path, user, {}, {}).then(({ data }) => data)
    }
  }

  return {
    getUserInformation: (user: AuthenticatedUser): ?AdvisorApiUserInfo => getUserInformation(user),
  }
}

export default AuthApi()
