// @flow

import type { DataWrapper } from 'core/data'
import type { Age } from 'core/data-model/insured'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { Effect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import type { BIProps } from 'quick-quote/product-common/benefit-illustration/actions'
import type { InsuredProps } from 'core/service/pdf-generation/products/common/types'
import values from 'core/data-model/constants/values'
import DEFAULT from 'core/data-model/constants/defaults'
import _ from 'lodash'
import { christianToThaiDate, christianToThaiWithoutFormat } from 'core/service/lib/date'
import { getDistributorProfile } from 'core/service/distributor'
import AuthApi from 'core/service/distributor/auth-api'
import { generatePolicyValues, summarizeBenefit, generateTaxDeductionBenefit } from 'core/service/benefit-illustration'
import { generateRiderBenefitData } from 'core/service/rider/benefits'
import { UPDATE_RIDERS_BENEFITS, EDIT_PENSION_TYPE_STATE } from 'quick-quote/product-common/coverage-plan/actions'
import {
  addRiderBenefit,
  removeRiderBenefit,
  updateBenefitSummary,
  updatePolicyValue,
  updateTaxDeduction,
} from 'quick-quote/product-whole-life/benefit-illustration/actions'
import {
  getPolicyValueParams,
  getBenefitSummary,
  getHasNonLevelRider,
  getLegalDisclaimerEndDate,
  getPolicyValues,
  getRiderBenefitTables,
  getTaxDeduction,
} from 'quick-quote/product-whole-life/benefit-illustration/selectors'
import { getDisplayBenefitSummaryTable } from 'quick-quote/v2/products/customs/12pl/components/benefit-summary-table/selectors'
import {
  getIdentityProfile,
  getIdentityUser,
  getSelectedOffice,
  getUserDistributorGroup,
  isNewAsteriskOfSummaryMainContract as isUseNewAsteriskOfSummaryMainContract,
} from 'identity/selectors'
import {
  getAge,
  getBirthdate,
  getGenderLabel,
  getInsuredFirstName,
  getInsuredLastName,
  getGender,
  getAllNatureOfDutyCodes,
} from 'quick-quote/insured-information/selectors'
import { getSelectedDisplayProduct, getSelectedDisplayProductQuery } from 'quick-quote/product-selection/selectors'
import { getVersion, isReviseQQFlow } from 'quick-quote/selectors'
import {
  getAvailableRiders,
  getSelectedRiders,
  getSumAssured,
  getSumAssured$,
  getTotalPremium,
  getBasicPremium,
  getBasicPremium$,
  getTotalRiderPremium,
  getSelectedPlanName,
  getSelectedPlanCode,
  getcoveragePlanStartDate,
  getSelectedModelFactorLabel,
  getSelectedModelFactorPeriod,
  getSelectedBasicCoveragePlan,
  getSelectedModelFactorID,
} from 'quick-quote/product-common/coverage-plan/selectors'
import {
  canProceedToBenefitIllustration,
  getValidSelectedRiders,
} from 'quick-quote/product-whole-life/coverage-plan/selectors'
import {
  getCalculatedCoveragePeriod,
  getCalculatedPaymentPeriod,
} from 'quick-quote/product-common/benefit-illustration/selectors'
import { getRiderTaxRates } from 'core/service/rider'
import { lookUpBasicTaxRate } from 'core/service/basic-plan'
import { EDIT_TITLE_NAME } from 'quick-quote/insured-information/actions'

export function* getBIWholeLifeProps(biProps: BIProps): Generator<*, *, *> {
  const basicPlan = yield select(getSelectedDisplayProduct)
  const productCategory = basicPlan.category
  const riders = yield select(getSelectedRiders)
  const genderLabel = yield select(getGenderLabel)
  const age = yield select(getAge)
  const firstName = yield select(getInsuredFirstName)
  const lastName = yield select(getInsuredLastName)
  const birthdate = yield select(getBirthdate)
  const sumAssured$ = yield select(getSumAssured$)
  const selectedModelFactorLabel = yield select(getSelectedModelFactorLabel)
  const calculatedCoveragePeriod = yield select(getCalculatedCoveragePeriod)
  const calculatedPaymentPeriod = yield select(getCalculatedPaymentPeriod)
  const hasNonLevelRider = yield select(getHasNonLevelRider)
  const totalPremium = yield select(getTotalPremium)
  const basicPremium$ = yield select(getBasicPremium$)
  const totalRiderPremium = yield select(getTotalRiderPremium)
  const benefitSummary = yield select(getBenefitSummary)
  const policyValue = yield select(getPolicyValues)
  const taxDeduction = yield select(getTaxDeduction)
  const riderBenefitData = yield select(getRiderBenefitTables)
  const version = yield select(getVersion)
  let profile = yield select(getIdentityProfile)
  const user = yield select(getIdentityUser)
  const accessToken = user.access_token
  const agentInfo = yield call(AuthApi.getUserInformation, user)
  const bancOffice = yield select(getSelectedOffice)
  profile = getDistributorProfile(profile, agentInfo, bancOffice)
  const distributorType = yield select(getUserDistributorGroup)
  const startDate = yield select(getcoveragePlanStartDate)
  const endDate = yield select(getLegalDisclaimerEndDate)
  const benefitSummaryTable = yield select(getDisplayBenefitSummaryTable)
  const selectedPlanName = yield select(getSelectedPlanName)
  const selectedPlanCode = yield select(getSelectedPlanCode)
  const selectedModelFactorID = yield select(getSelectedModelFactorID)
  const isNewAsteriskOfSummaryMainContract = yield select(isUseNewAsteriskOfSummaryMainContract)
  const getDateTime = () => christianToThaiWithoutFormat().format('Do MMMM YYYY เวลา kk:mm')
  const isReviseQQ = yield select(isReviseQQFlow)
  const datetime = yield call(getDateTime)
  const validateFullName = _.isEmpty(firstName) && _.isEmpty(lastName) ? null : { firstName, lastName }

  const insured: InsuredProps = {
    genderLabel,
    age,
    ...validateFullName,
  }

  return {
    // meta data
    productCategory,
    biType: biProps.biType,

    // data
    basicPlan,
    insured,
    riders,
    birthdate,
    calculatedCoveragePeriod,
    calculatedPaymentPeriod,
    sumAssured: sumAssured$.value,
    basicPremium: basicPremium$.value,
    totalPremium,
    selectedModelFactorLabel,
    hasNonLevelRider,
    benefitSummary,
    benefitSummaryTable,
    policyValue,
    riderBenefitData,
    version: version.appVersion,
    profile,
    accessToken,
    distributorType,
    startDate: christianToThaiDate(startDate),
    endDate: christianToThaiDate(endDate),
    datetime,
    logo: '',
    redLine: '',
    productGraph: '',
    selectedPlanName,
    selectedPlanCode,
    taxDeduction,
    totalRiderPremium,
    selectedModelFactorID,
    isNewAsteriskOfSummaryMainContract,
    isReviseQQ,
  }
}

export const addRiderBenefitFunction = function*(
  dataWrapper: DataWrapper,
  selectedRider: Rider & RiderState,
  selectedRiders: (Rider & RiderState)[],
  age: Age
): Generator<*, *, *> {
  const riderCode = selectedRider.code
  const riderBenefits = yield call(dataWrapper.getRiderBenefit, riderCode)
  const riderSumAssured = selectedRider.sumAssured
  const extendedRiderBenefit = yield call(
    generateRiderBenefitData,
    {
      age: age,
      riderBenefitData: riderBenefits,
      riderSumAssured: riderSumAssured,
      selectedRiders: selectedRiders,
    },
    riderCode
  )
  yield put(addRiderBenefit(riderCode, extendedRiderBenefit))
}
export function* addOrRemoveRiderBenefits(dataWrapper: DataWrapper): Generator<*, *, *> {
  const canProceedToBenefitIllustrationBoolean = yield select(canProceedToBenefitIllustration)
  if (!canProceedToBenefitIllustrationBoolean) {
    return
  }

  try {
    const riders = yield select(getAvailableRiders)
    const selectedRiders = riders.filter(({ isSelected, isSelectable }) => isSelected && isSelectable)
    const nonSelectedRiders = riders.filter(({ isSelected, isSelectable }) => !(isSelected && isSelectable))
    const sumAssured = yield select(getSumAssured)
    var proxyRider: Rider & RiderState
    var isProxyRiderFound = true

    if (selectedRiders.length > 0) {
      const age = yield select(getAge)
      yield selectedRiders.map((rider) => {
        if (_.includes(DEFAULT.PROXY_RIDERS, rider.code)) {
          if (isProxyRiderFound) {
            isProxyRiderFound = false
            proxyRider = {
              ...rider,
              code: values.IGROW,
              sumAssured: sumAssured,
            }
            return call(addRiderBenefitFunction, dataWrapper, proxyRider, selectedRiders, age)
          } else {
            return
          }
        } else {
          return call(addRiderBenefitFunction, dataWrapper, rider, selectedRiders, age)
        }
      })
    }

    yield nonSelectedRiders.map((rider) => {
      return put(removeRiderBenefit(rider.code))
    })
  } catch (error) {}
  yield call(generatePolicyValue)
  yield call(generateBenefitSummary)
  yield call(generateTaxDeduction)
}

export function* generatePolicyValue(): Generator<*, *, *> {
  const canProceedToBenefitIllustrationBoolean = yield select(canProceedToBenefitIllustration)
  if (!canProceedToBenefitIllustrationBoolean) {
    return
  }
  let policyValueParams = yield select(getPolicyValueParams)
  const policyValue = yield call(generatePolicyValues, policyValueParams)
  yield put(updatePolicyValue(policyValue))
}

export function* generateBenefitSummary(): Generator<*, *, *> {
  const basicPlan = yield select(getSelectedBasicCoveragePlan)
  const riders = yield select(getValidSelectedRiders)
  const policyValues = yield select(getPolicyValues)
  const benefitSummary = summarizeBenefit(
    {
      ...basicPlan,
      sumAssured: basicPlan.sumAssured.value,
    },
    riders,
    policyValues
  )
  yield put(updateBenefitSummary(benefitSummary))
}

export function* generateTaxDeduction(): Generator<*, *, *> {
  const modelFactorPeriod = yield select(getSelectedModelFactorPeriod)
  const basicPremium = yield select(getBasicPremium)
  const totalRiderPremium = yield select(getTotalRiderPremium)
  const yearOfCoverage = yield select(getCalculatedCoveragePeriod)
  const availableRiders = yield select(getAvailableRiders)
  const basicPlan = yield select(getSelectedDisplayProduct)
  const gender = yield select(getGender)
  const age = yield select(getAge)
  const displayProductQuery = yield select(getSelectedDisplayProductQuery)
  const natureOfDutyCodes = yield select(getAllNatureOfDutyCodes)

  const riderTaxRates =
    basicPlan.category === values.PA
      ? yield call(getRiderTaxRates, gender, age, availableRiders, natureOfDutyCodes, displayProductQuery)
      : yield call(getRiderTaxRates, gender, age, availableRiders)

  const basicTaxRate = yield call(lookUpBasicTaxRate, basicPlan, gender, age, yearOfCoverage)

  const taxDeduction = generateTaxDeductionBenefit({
    modelFactorPeriod,
    basicPremium,
    totalRiderPremium,
    yearOfCoverage,
    availableRiders,
    basicPlan,
    riderTaxRates,
    basicTaxRate,
  })

  yield put(updateTaxDeduction(taxDeduction))
}

export function* watchers(dataWrapper: DataWrapper): Generator<*, *, Effect[]> {
  yield [
    takeLatest([UPDATE_RIDERS_BENEFITS, EDIT_TITLE_NAME], addOrRemoveRiderBenefits, dataWrapper),
    takeLatest(EDIT_PENSION_TYPE_STATE, generatePolicyValue),
  ]
}

export const sagas = watchers
EDIT_PENSION_TYPE_STATE
