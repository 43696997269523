//@flow
import { RadioGroup } from 'common-components/index'
import { Emphasized } from 'common-components/text'
import { FormFeedback } from 'reactstrap'
import type { LstuFundAllocationOption } from 'core/data-model/investment'
import VALUE from 'core/data-model/constants/values'
import styled from 'styled-components'

type LstuOptionsProps = {
  onAllocateSameAsRPP: Function,
  onAllocateIndependently: Function,
  chosenOption: ?LstuFundAllocationOption,
}

const LstuOptionWrapper = styled.div`
  max-width: 680px;
  margin: 0 auto;
  padding: 0 20px;
`

export const LstuOptions = ({ onAllocateSameAsRPP, onAllocateIndependently, chosenOption }: LstuOptionsProps) => {
  const options = [
    {
      value: VALUE.LSTU_FUND_ALLOCATION_OPTION.SAME_AS_RPP,
      text: (
        <span>
          ท่านต้องการลงทุน<Emphasized>ตามการจัดสรรการลงทุนเบี้ยประกันภัยหลัก (RPP)</Emphasized>
        </span>
      ),
      onClick: onAllocateSameAsRPP,
    },
    {
      value: VALUE.LSTU_FUND_ALLOCATION_OPTION.INDEPENDENT,
      text: (
        <span>
          ท่านต้องการกำหนดการ
          <Emphasized>
            จัดสรรการลงทุนเบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษ (LSTU) เป็นการเฉพาะครั้งนี้เท่านั้น
          </Emphasized>
        </span>
      ),
      onClick: onAllocateIndependently,
    },
  ]
  return (
    <LstuOptionWrapper className={'form-group'}>
      <Emphasized>
        <b>กรณีที่ท่านระบุการจัดสรรการลงทุน สำหรับเบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษ (LSTU)</b>
      </Emphasized>
      <RadioGroup
        options={options}
        value={chosenOption}
        setValue={(option) => {
          option.onClick()
        }}
      />
      {chosenOption !== VALUE.LSTU_FUND_ALLOCATION_OPTION.INDEPENDENT && (
        <FormFeedback className={'info-message attached-icon'}>
          <p>ตัวแทนมีหน้าที่ให้ข้อมูลให้คำอธิบายเกี่ยวกับการจัดสรรการลงทุนให้แก่ลูกค้าเข้าใจครบถ้วน</p>
        </FormFeedback>
      )}
    </LstuOptionWrapper>
  )
}

export default LstuOptions
