// @flow

import Mustache from 'mustache'

export const underlineHtml = (data: string) =>
  Mustache.render(data, { underline: () => (text, render) => `<u>${render(text)}</u>` })

export const renderListItems = (data: string[], startIndex: number, count: number) => {
  return data.slice(startIndex, count).map((dataRow, index) => {
    return (
      <li key={`data_${index}`}>
        <span dangerouslySetInnerHTML={{ __html: underlineHtml(dataRow) }} />
      </li>
    )
  })
}
