import { profileSnapshotSection } from './profile-snapshot-section'
import { productBenefitsSection } from './product-benefit-section'
import { basicPlanConditionSection, basicPlanGeneralExceptionSection } from './basicplan-condition-section'
import { benefitSummarySection } from './benefit-summary-section'
import { policyValueTableSection } from './policy-value-table-section'
import { taxConsentDeductionBenefitSection } from './tax-consent-deduction-section'
import { healthAndFinancialRuleSection } from './health-and-financial-rule-section'

export const mrtaSection = {
  profile: profileSnapshotSection,
  productBenefits: productBenefitsSection,
  basicPlanCondition: basicPlanConditionSection,
  basicPlanGeneralException: basicPlanGeneralExceptionSection,
  benefitSummary: benefitSummarySection,
  policyValueTable: policyValueTableSection,
  taxConsentDeductionBenefit: taxConsentDeductionBenefitSection,
  healthAndFinancialRuleSection: healthAndFinancialRuleSection,
}
