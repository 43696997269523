import _ from 'lodash'
import { createSectionCreator, createField } from './utils'
import { getPersonalNationalityList } from 'e-submission/apps/selectors'

const foreignerPassportDetail = (app, id) => {
  return _.compact([
    createField(`${id}.passport`, [
      {
        label: 'หมายเลขหนังสือเดินทาง / Passport Number',
        id: `${id}.passport`,
        c: 'TextInput',
        p: {
          disabled: true,
        },
      },
    ]),
    createField(`${id}.passportIssue`, [
      {
        label: 'วันที่ออกหนังสือเดินทาง / Issue Date',
        id: `${id}.passportIssue`,
        c: 'DateDropdownInput',
        required: true,
        p: {},
      },
    ]),
    createField(`${id}.passportExpiry`, [
      {
        label: 'วันที่หมดอายุ / Expiration Date',
        id: `${id}.passportExpiry`,
        c: 'DateDropdownInput',
        required: true,
        p: {
          addYear: 20,
        },
      },
    ]),
  ])
}

const foreignerVisaDetail = (app, id) => {
  return _.compact([
    createField(`${id}.visaIssue`, [
      {
        label: 'วันที่ออกวีซ่า / Visa Issue Date',
        id: `${id}.visaIssue`,
        c: 'DateDropdownInput',
        required: true,
        p: {},
      },
    ]),
    createField(`${id}.visaExpiry`, [
      {
        label: 'วันที่หมดอายุ / Expiration Date',
        id: `${id}.visaExpiry`,
        c: 'DateDropdownInput',
        required: true,
        p: {
          addYear: 20,
        },
      },
    ]),
  ])
}

const foreignerWorkpermitDetail = (app, id) => {
  return _.compact([
    createField(`${id}.workpermitIssue`, [
      {
        label: 'วันที่ออกหนังสือรับรองการทํางาน / Work Permit Issue Date ',
        id: `${id}.workpermitIssue`,
        c: 'DateDropdownInput',
        required: false,
        p: {},
      },
    ]),
    createField(`${id}.workpermitExpiry`, [
      {
        label: 'วันที่หมดอายุ / Expiration Date',
        id: `${id}.workpermitExpiry`,
        c: 'DateDropdownInput',
        required: false,
        p: {
          addYear: 20,
        },
      },
    ]),
  ])
}

const foreignerQuestionaireDetails = (app, id) => {
  return _.compact([
    createField(`${id}.address`, [
      {
        label: 'ที่อยู่ที่ต่างประเทศ / Address of domicile',
        id: `${id}.address`,
        c: 'TextInput',
        required: true,
        p: {},
      },
    ]),
    createField(`${id}.sinceWhenComingToThailand`, [
      {
        label: 'เข้ามาในประเทศไทยตั้งแต่เมื่อใด / Arrival date in Thailand',
        id: `${id}.sinceWhenComingToThailand`,
        c: 'DateDropdownInput',
        required: true,
        p: {},
      },
    ]),
    createField(`${id}.onWhatPurpose`, [
      {
        label: 'จุดประสงค์ที่เดินทางเพื่ออะไร / Purpose of stay in Thailand',
        id: `${id}.onWhatPurpose`,
        c: 'TextInput',
        required: true,
        p: {},
      },
    ]),
    createField(`${id}.countryVisitingBeforeComingToThailand`, [
      {
        label: 'ก่อนเดินทางมาประเทศไทยเคยไปยังประเทศใดบ้าง / Country visiting before coming to Thailand',
        id: `${id}.countryVisitingBeforeComingToThailand`,
        c: 'Dropdown',
        required: false,
        p: {
          mergeState: (state) => ({
            options: getPersonalNationalityList(state),
          }),
        },
      },
    ]),
    createField(`${id}.whatPurposeVisitingThoseCountry`, [
      {
        label: 'จุดประสงค์ที่เดินทางเพื่ออะไร / Purpose of visit to previous country',
        id: `${id}.whatPurposeVisitingThoseCountry`,
        c: 'TextInput',
        required: false,
        p: {},
      },
    ]),
    createField(`${id}.forHowLongDoYouExpectToLiveInThailand`, [
      {
        label: 'คาดว่าจะพํานักอยู่ในประเทศไทยนานเท่าใด / For how long do you expect to live in Thailand',
        id: `${id}.forHowLongDoYouExpectToLiveInThailand`,
        c: 'TextInput',
        required: true,
        p: {},
      },
    ]),
    createField(`${id}.highestEducationBackgroundAndQualification`, [
      {
        label: 'ระดับการศึกษาสูงสุด / Highest level of education & qualification',
        id: `${id}.highestEducationBackgroundAndQualification`,
        c: 'TextInput',
        required: true,
        p: {},
      },
    ]),
    createField(`${id}.previousOccupation`, [
      {
        label:
          'อาชีพ/หน้าที่ที่รับผิดชอบในต่างประเทศ ก่อนเข้ามาประเทศไทย / Previous occupation, responsibility before coming to Thailand',
        id: `${id}.previousOccupation`,
        c: 'TextInput',
        required: true,
        p: {},
      },
    ]),
    createField(`${id}.previousAnnualIncome`, [
      {
        label: 'รายได้ต่อปีในต่างประเทศก่อนเข้ามาประเทศไทย / Annual income before coming to Thailand',
        id: `${id}.previousAnnualIncome`,
        c: 'NumberInput',
        required: true,
        p: {},
      },
    ]),
  ])
}

export default (sectionId, sectionLabel) => (app, id) => {
  return _.compact([
    createSectionCreator(foreignerPassportDetail)(
      `${sectionId}`,
      null,
      null,
      'หนังสือเดินทาง Passport',
      'form-section__foreigner'
    )(app, `${id}.${sectionId}`),
    createSectionCreator(foreignerVisaDetail)(`${sectionId}`, null, null, 'วีซ่า Visa', 'form-section__foreigner')(
      app,
      `${id}.${sectionId}`
    ),
    createSectionCreator(foreignerWorkpermitDetail)(
      `${sectionId}`,
      null,
      null,
      'หนังสือรับรองการทํางาน Work Permit',
      'form-section__foreigner'
    )(app, `${id}.${sectionId}`),
    createSectionCreator(foreignerQuestionaireDetails)(`${sectionId}`, null, null, null, 'form-section__foreigner')(
      app,
      `${id}.${sectionId}`
    ),
  ])
}
