// @flow
import NumberFormat from 'react-number-format'
import { Input } from 'reactstrap'

type BasicSumAssuredProps = {
  isReadOnly: boolean,
  sumAssured: number,
  enabled: boolean,
  editSumAssured: (?number) => void,
}

const BasicSumAssured = ({ isReadOnly, sumAssured, enabled, editSumAssured }: BasicSumAssuredProps) => {
  if (enabled) {
    return (
      <NumberFormat
        id="sum-assured-input"
        disabled={Boolean(isReadOnly)}
        isAllowed={({ formattedValue }) => formattedValue.length <= 17}
        customInput={Input}
        value={sumAssured}
        thousandSeparator={true}
        allowNegative={false}
        type="tel"
        pattern="[0-9]*"
        onFocus={(e) => e.target.setSelectionRange(0, -1)}
        onChange={(e, values) => editSumAssured(Number(values.value))}
      />
    )
  } else {
    return <Input id="sum-assured-input" value="-" disabled={true} />
  }
}

//const mapStateToProps = (state: AppState) => ({
//  sumAssured$: getSumAssured$(state),
//  haveBasicPremiumState: haveBasicPremiumState(state),

export default BasicSumAssured
