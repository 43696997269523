import _ from 'lodash'
import styled from 'styled-components'
import { compose, lifecycle } from 'recompose'
import { Flex } from 'rebass'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Alert } from 'reactstrap'
import {
  setAppValue,
  unsetAppValue,
  setShowEcbrSystemizationFailedModal,
  setHideEcbrSystemizationFailedModal,
} from 'e-submission/apps/actions'
import { formatQRPaymentRef2 } from 'e-submission/utils/documents'
import PayerInfoApiFailedModal from 'e-submission/containers/Dialog/PayerInfoApiFailedModal'
import { getCurrentApp, isSubmitting } from 'e-submission/apps/selectors'
import {
  isAllReceiptCreated,
  getFullNameWithDefault,
  getQuickQuoteTotalPremium,
  getAllocateReceiptAmount,
  getRemainReceiptAmount,
  getValidatedTotalPaidPremium,
  getReceipts,
} from 'e-submission/domain/data-model/form/selectors'
import { getToggles } from 'quick-quote/feature-toggles'

import grayDot from 'assets/images/form/gray-dot.png'
import Card from 'e-submission/components/Card'

import Field from 'e-submission/components/FormEngine/FormManager'
import Content from 'e-submission/components/Layout/Content'

import { CheckedCard, NotCheckedCard, Side, Description } from 'e-submission/components/Card/DocumentCard'
import Icon from 'e-submission/components/Icon'
import { NameBox } from 'e-submission/components/Card'
import NumberInput from 'react-number-format'

export const _PaymentInfo = styled(
  ({ isValid, className, fullname, totalPremium, allocatePremium, remainPremium, isAllReceiptCreated }) => {
    const _isValid = remainPremium > 0
    return (
      <div className={className}>
        <Flex justify="space-between" className="user-fullname-container">
          <NameBox flex={['1 0 100%', null, null, '0 1 auto']}>{fullname || '-'}</NameBox>
        </Flex>
        <div className="details-premium-container">
          <Flex justify="space-between">
            <span>งวดแรก</span>
            <NumberInput value={totalPremium} displayType="text" suffix=" บาท" thousandSeparator decimalPrecision={2} />
          </Flex>
          {!isAllReceiptCreated && (
            <Flex justify="space-between">
              <span>จ่ายไปแล้ว</span>
              <NumberInput
                value={allocatePremium}
                displayType="text"
                suffix=" บาท"
                thousandSeparator
                decimalPrecision={2}
              />
            </Flex>
          )}
        </div>
        {!isAllReceiptCreated && (
          <Flex justify="space-between" className={`remain-premium-container ${_isValid ? 'valid' : ''}`}>
            <span>เหลือจ่าย</span>
            <NumberInput
              value={remainPremium}
              displayType="text"
              suffix=" บาท"
              thousandSeparator
              decimalPrecision={2}
            />
          </Flex>
        )}
      </div>
    )
  }
)`
  padding: 1rem 1rem;
  .user-fullname-container {
    padding-bottom: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.variables['$color-silver']};
  }
  .details-premium-container {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.variables['$color-silver']};
  }

  .remain-premium-container {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px double ${({ theme }) => theme.variables['$color-silver']};
    border-width: 4px;
    font-weight: bold;
    transition: color 0.195s;
    &.valid {
      color: ${({ theme }) => theme.variables['$brand-success']};
    }
  }
`

export const PaymentInfo = compose(
  connect((state) => {
    const app = getCurrentApp(state)
    const fullname = getFullNameWithDefault('insured')(app)
    const totalPremium = getQuickQuoteTotalPremium(app)
    const allocatePremium = getAllocateReceiptAmount(app)
    const remainPremium = getRemainReceiptAmount(app)
    const validatedPaidAmount = getValidatedTotalPaidPremium(app)
    const receipts = getReceipts(app)
    const isFirstTimeEnterPaymentAmount = !isSubmitting(state)
    const payerInfoStatus = _.get(app, 'payerInfo.status')
    return {
      fullname,
      totalPremium,
      allocatePremium,
      remainPremium,
      validatedPaidAmount,
      isAllReceiptCreated: isAllReceiptCreated(app),
      isFirstTimeEnterPaymentAmount,
      payerInfoStatus,
    }
  })
)(_PaymentInfo)

const STEP_WIDTH = 40
const PaymentCard = styled((props) => {
  const { id, icon, paid, selected, setValue, className, paymentSteps, disabled, isAllReceiptCreated } = props
  const firstPaymentStep = paymentSteps[0]
  const onSelect = () => !paid && !disabled && props.onSelect(props)
  const eventProps = { onClick: onSelect }

  return (
    <Card
      id={id}
      className={className}
      column
      style={{ background: 'none' }}
      w={1}
      pl={[0, 2, 2]}
      pr={[0, 2, 2]}
      disabled={disabled}
    >
      {(isAllReceiptCreated ? selected : true) && (
        <Flex className="form-group card-header" align="center" {...eventProps}>
          <Field setValue={setValue} elements={firstPaymentStep.elements} />
        </Flex>
      )}
      {isAllReceiptCreated && selected && (
        <div className="content">
          <div className="content-wrapper">
            {paymentSteps.slice(1).map(({ step, state, label, elements = [] }, index) => (
              <Flex className="payment-step" key={index}>
                <Flex className="balloon-container" flex="0 0 auto" justify="center" align="flex-start">
                  <div className={`balloon ${state}`}>
                    {state === 'done' && <Icon.check />}
                    <span className="number">{index + 1}</span>
                  </div>
                </Flex>
                <Flex className={`item-container ${state}`} flex="1 1 100%" align="center">
                  <Flex className="item-content" flex="0 0 100%" justify="flex-end">
                    <Field setValue={setValue} elements={elements} />
                  </Flex>
                </Flex>
              </Flex>
            ))}
          </div>
        </div>
      )}
    </Card>
  )
})`
  margin-bottom: 10px;
  border: none;
  .card-header {
    [id$='.add'] {
      background: ${({ theme }) => theme.variables['$color-wild-sand']};
      text-align: right;
      padding: 20px 0;
      margin-top: 10px;
      margin-left: -22px;
      margin-right: -22px;
      margin-bottom: -22px;
      button,
      button:hover,
      button:active,
      button:focus {
        text-decoration: underline;
        background: transparent;
        border: none;
        box-shadow: none;
        color: ${({ theme }) => theme.variables['$brand-primary']};
        &:after {
          display: none;
        }
      }
    }
    svg {
      margin-right: 10px;
      min-width: 40px;
      min-height: 40px;
      width: 40px;
      height: 40px;
      path {
        fill: black;
      }
    }
    &.form-group {
      background: white;
      padding: 12px 22px;
      border: none;
      > div {
        flex: 1 1 100%;
      }
      .form-group > div {
        > div > div {
          flex: 1 1 auto;
          min-width: 142px;
        }
        input,
        .text {
          max-width: 240px;
          align-self: center;
          text-align: right;
          font-size: ${({ theme }) => theme.variables['$font-size-h6']};
        }
        input[disabled] {
          background: ${({ theme }) => theme.variables['$color-mercury']};
        }
      }
    }
  }
  .content {
    background: white;
    padding-left: 20px;
    padding-right: 20px;
    .content-wrapper {
      padding-top: 30px;
      border-top: 2px solid ${({ theme }) => theme.variables['$color-silver']};
    }
  }
  .form-group {
    padding: 0;
    margin-bottom: 0;
  }
  .payment-step {
    background-image: url(${grayDot});
    background-color: transparent;
    background-repeat: repeat-y;
    background-position: left ${STEP_WIDTH / 2}px top;

    &:last-child {
      background: none;
    }
  }
  .balloon-container {
    width: ${STEP_WIDTH}px;
    .balloon {
      background-color: ${({ theme }) => theme.variables['$color-mercury']};
      border: 1px solid ${({ theme }) => theme.variables['$color-silver']};
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      color: ${({ theme }) => theme.variables['$color-scorpion']};
      &.active {
        color: black;
        background: white;
      }
      &.done {
        .number {
          display: none;
        }
        svg {
          width: 20px;
          height: 20px;
          path {
            fill: white;
            stroke: white;
            stroke-width: 8px;
          }
        }
        background-color: ${({ theme }) => theme.variables['$brand-success']};
      }
    }
  }
  .payment-step:last-child {
    .item-container {
      margin-bottom: 30px;
    }
  }
  .item-container {
    .item-content {
      margin-top: -10px;
      margin-bottom: 40px;
      > div {
        flex: 1 0 100%;
      }
      [id$='.text'] {
        margin-top: 10px;
        margin-left: 12px;
      }
      ${Side} {
        min-height: auto;
        min-width: auto;
        flex: 0 0 80px;
        > div {
          line-height: 50px;
        }
        button {
          height: 50px;
          width: 100%;
        }
      }
      ${NotCheckedCard} {
        color: initial;
        flex-direction: row-reverse;
        align-items: flex-start;
        ${Description} {
          border: 1px solid transparent;
        }
      }
      ${CheckedCard} {
        ${Side}:first-child {
          display: none;
        }
      }
    }
  }
`

const mapStateToProps = (state) => {
  const app = getCurrentApp(state)
  const payerInfoStatus = _.get(app, 'payerInfo.status')
  const isOpenEcbrSystemizationModal = _.get(state, `ui.isEcbrSystemizationModal`)
  const qrRef2 = _.get(app, 'receipts[3].ref2')
  return {
    payerInfoStatus,
    isOpenEcbrSystemizationModal,
    qrRef2,
  }
}

const mapDispatchToProps = (dispatch) => ({
  setValue: (fieldId, newValue) => {
    dispatch(setAppValue(fieldId, newValue))
  },
  unsetValue: (fieldId) => {
    dispatch(unsetAppValue(fieldId))
  },
  setRef2: () => {
    dispatch(setAppValue('receipts[3].ref2', formatQRPaymentRef2()))
  },
  showEcbrSystemizationFailedModal: (isOpenEcbrSystemizationModal) => {
    dispatch(setShowEcbrSystemizationFailedModal(isOpenEcbrSystemizationModal))
  },
  closeEcbrSystemizationFailedModal: (isOpenEcbrSystemizationModal) =>
    dispatch(setHideEcbrSystemizationFailedModal(isOpenEcbrSystemizationModal)),
})

export default (hoc) =>
  compose(
    withRouter,
    hoc,
    connect(mapStateToProps, mapDispatchToProps),
    lifecycle({
      componentDidMount() {
        if (getToggles().ENABLE_ECBR_SYSTEMIZATION && _.isEmpty(this.props.qrRef2)) {
          this.props.setRef2()
        }
      },
    })
  )(
    ({
      title,
      isValid,
      sections,
      disabled,
      isFirstTimeEnterPaymentAmount,
      payerInfoStatus,
      isOpenEcbrSystemizationModal,
      closeEcbrSystemizationFailedModal,
      ...props
    }) => {
      const isSectionSelected =
        sections.filter((item) => {
          return item.selected
        }).length > 0

      return (
        <Content style={{ marginTop: '-1.5rem' }}>
          <PaymentInfo isValid={isValid} />
          <Flex className="ml-3 mb-2 mt-1">
            <b>{title}</b>
          </Flex>
          {sections.map((paymentProps, index) => (
            <PaymentCard key={paymentProps.id + paymentProps.paymentType} {...props} {...paymentProps} />
          ))}
          {!isSectionSelected ? (
            <Flex className="ml-3 mr-3">
              <Alert color={'danger-no-icon'}>
                การชำระเบี้ยประกันตั้งแต่ 30,000 บาทขึ้นไป ควรชำระด้วยบัตรเครดิตหรือบัตรเดบิต
              </Alert>
            </Flex>
          ) : null}
          {getToggles().ENABLE_ECBR_SYSTEMIZATION && (
            <PayerInfoApiFailedModal
              closeModal={closeEcbrSystemizationFailedModal}
              isOpen={isOpenEcbrSystemizationModal}
            />
          )}
        </Content>
      )
    }
  )
