//@flow
import type { InsuredProps } from 'core/service/pdf-generation/products/common/types'

import { BenefitTableExample } from 'core/service/pdf-generation/products/investment/components'
import type { CashFlow } from 'core/service/investment/cash-flow/cash-flow'

export type ExpectedReturn = '-1' | '2' | '5'

type BenefitTableExampleGroupProps = {
  policyType: string,
  sumAssured: number,
  calculatedCoveragePeriod: number,
  calculatedPaymentPeriod: number,
  insured: InsuredProps,
  regularPremium: number,
  regularTopupPremium: number,
  coverageDeath: number,
  paymentModeLabel: string,
  cashFlowByExpectedReturn: { [ExpectedReturn]: CashFlow[] },
}

export const BenefitTableExampleGroup = (props: BenefitTableExampleGroupProps) => {
  const { cashFlowByExpectedReturn } = props
  const EXPECTED_RETURN_RATES: ExpectedReturn[] = Object.keys(cashFlowByExpectedReturn).sort()
  return EXPECTED_RETURN_RATES.map((rate, index) => {
    const cashFlows = props.cashFlowByExpectedReturn[rate]
    return BenefitTableExample({ ...props, cashFlows }, index + 1, parseInt(rate))
  })
}
