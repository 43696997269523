import { compose } from 'recompose'
import styled from 'styled-components'
import { getIcon } from 'e-submission/components/Icon'

const Disclaimer = styled.div`
  text-align: center;
  img,
  svg {
    margin-bottom: 0.5rem;
  }
`

const Component = ({ icon, children }) => (
  <Disclaimer>
    {getIcon(icon)}
    <div dangerouslySetInnerHTML={{ __html: children }} />
  </Disclaimer>
)

export default (hoc) => compose(hoc)(Component)
