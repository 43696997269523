import React from 'react'
import styled from 'styled-components'

const SubmitWithoutPaymentTitle = (props) => {
  return (
    <ComponentSubmitWithoutPaymentTitle>
      <div className="component-container">
        <div className="title">
          <span className="title-text">ลูกค้าประสงค์จะชำระเบี้ยเมื่อใด</span>
        </div>
      </div>
    </ComponentSubmitWithoutPaymentTitle>
  )
}

const ComponentSubmitWithoutPaymentTitle = styled.div`
  display: block;

  .title {
    margin-bottom: -0.5rem;
  }

  .title-text {
    font-size: 1.325rem;
    color: #2425aa;
    font-weight: bold;
  }
`

export default SubmitWithoutPaymentTitle
