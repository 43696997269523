// @flow
import _ from 'lodash'
import VALUES from 'core/data-model/constants/values'
import MESSAGES from 'core/data-model/constants/bi-messages'
import { hasAsteriskFromRiders } from 'core/service/rider/benefits/benefit-data'
import type { DisplayProduct } from 'core/service/display-product'

const _initAsteriskDisclaimer = (basicPlan: ?DisplayProduct, hasShowAsteriskFromRiders: boolean): string[] => {
  if (hasShowAsteriskFromRiders) {
    if (_.get(basicPlan, 'code', '') === VALUES.IGROW) {
      return [`* ${MESSAGES.DISCLAIMER_SAVING_LEGAL_SUMMARY}`]
    } else if (_.get(basicPlan, 'code', '') === 'SUKHAPABDEKDEE-WLNP85') {
      return [`* ${MESSAGES.DISCLAIMER_MEK_RIDER_PREMIUM}`]
    } else {
      return [`* ${MESSAGES.DISCLAIMER_RIDER_PREMIUM}`]
    }
  }
  return []
}

const showDisclaimerRiderAsterisk = (riders) => {
  const result = riders.filter(
    (rider) =>
      rider.premiumStructure === VALUES.PREMIUM_NON_LEVEL &&
      !VALUES.LIST_EXCEPT_ASTERISKS_BENEFIT_SUMMARY_TABLE.includes(rider.code)
  )
  return result.length > 0
}

const chkDisclaimerAsterisksMessageOfMHPandMEX = (riders) => {
  return _.some(riders, (rider) => VALUES.LIST_FIVE_ASTERISKS_BENEFIT_SUMMARY_TABLE.includes(_.get(rider, 'code')))
}

export const disclaimers = (
  basicPlan: ?DisplayProduct,
  riders,
  isNewAsteriskOfSummaryMainContract: boolean
): string[] => {
  const hasShowAsteriskFromRiders = hasAsteriskFromRiders(riders)
  const hasShowDisclaimerRiderAsterisk = showDisclaimerRiderAsterisk(riders)
  const hasDisclaimerAsterisksMessageOfMHPandMEX = chkDisclaimerAsterisksMessageOfMHPandMEX(riders)

  if (!isNewAsteriskOfSummaryMainContract) {
    const initDisclaimerMessage = generateDisclaimers(
      basicPlan,
      hasShowAsteriskFromRiders,
      hasShowDisclaimerRiderAsterisk,
      hasDisclaimerAsterisksMessageOfMHPandMEX
    )
    return initDisclaimerMessage
  } else {
    const initDisclaimerMessage = generateNewDisclaimers(
      basicPlan,
      hasShowAsteriskFromRiders,
      hasShowDisclaimerRiderAsterisk,
      hasDisclaimerAsterisksMessageOfMHPandMEX
    )
    return initDisclaimerMessage
  }
}

const generateDisclaimers = (
  basicPlan: ?DisplayProduct,
  hasShowAsteriskFromRiders: boolean,
  hasShowDisclaimerRiderAsterisk: boolean,
  hasDisclaimerAsterisksMessageOfMHPandMEX: boolean
): string[] => {
  let initAsteriskDisclaimer = _initAsteriskDisclaimer(basicPlan, hasShowAsteriskFromRiders) // *
  initAsteriskDisclaimer.push(`** ${MESSAGES.DISCLAIMER_OCCUPATION_PREMIUM}`) // **

  if (hasShowDisclaimerRiderAsterisk) {
    initAsteriskDisclaimer.push(`*** ${MESSAGES.SUMMARY_TABLE_DISCLAIMER_HAS_NON_LEVEL_RIDER}`) // ***
  }

  initAsteriskDisclaimer.push(`**** ${MESSAGES.DISCLAIMER_SPECIAL_OCCUPATION_PREMIUM}`) // ****

  if (hasDisclaimerAsterisksMessageOfMHPandMEX) {
    initAsteriskDisclaimer = initAsteriskDisclaimer.concat([
      `***** ${MESSAGES.SUMMARY_TABLE_DISCLAIMER_HEALTH_RIDER_TITLE}`,
      `1. ${MESSAGES.SUMMARY_TABLE_DISCLAIMER_HEALTH_RIDER_FIRST}`,
      `2. ${MESSAGES.SUMMARY_TABLE_DISCLAIMER_HEALTH_RIDER_SECOND}`,
      `3. ${MESSAGES.SUMMARY_TABLE_DISCLAIMER_HEALTH_RIDER_THIRD}`,
      `${MESSAGES.SUMMARY_TABLE_DISCLAIMER_HEALTH_RIDER_THIRD_2}`,
    ]) // *****
  }

  return initAsteriskDisclaimer
}

const generateNewDisclaimers = (
  basicPlan: ?DisplayProduct,
  hasShowAsteriskFromRiders: boolean,
  hasShowDisclaimerRiderAsterisk: boolean,
  hasDisclaimerAsterisksMessageOfMHPandMEX: boolean
): string[] => {
  let initAsteriskDisclaimer = _initAsteriskDisclaimer(basicPlan, hasShowAsteriskFromRiders) // *

  if (hasShowDisclaimerRiderAsterisk) {
    initAsteriskDisclaimer.push(`** ${MESSAGES.SUMMARY_TABLE_DISCLAIMER_HAS_NON_LEVEL_RIDER}`) // **
  }

  if (hasDisclaimerAsterisksMessageOfMHPandMEX) {
    initAsteriskDisclaimer = initAsteriskDisclaimer.concat([
      `*** ${MESSAGES.SUMMARY_TABLE_DISCLAIMER_HEALTH_RIDER_TITLE}`,
      `1. ${MESSAGES.SUMMARY_TABLE_DISCLAIMER_HEALTH_RIDER_FIRST}`,
      `2. ${MESSAGES.SUMMARY_TABLE_DISCLAIMER_HEALTH_RIDER_SECOND}`,
      `3. ${MESSAGES.SUMMARY_TABLE_DISCLAIMER_HEALTH_RIDER_THIRD}`,
      `${MESSAGES.SUMMARY_TABLE_DISCLAIMER_HEALTH_RIDER_THIRD_2}`,
    ]) // ***
  }

  return initAsteriskDisclaimer
}
