// @flow

import BasicPlanCalculation from './basic-plan/basic-plan-calculation'
import ExpectedReturnComparisonTable from './expected-return-comparison-table'
import { InsuredInformationBreadcrumb } from 'quick-quote/components'
import PATH from 'quick-quote/constants/path'
import { Redirect } from 'react-router-dom'

type CoveragePlanProps = {
  hasProductSelected: boolean,
}
export const CoveragePlan = ({ hasProductSelected }: CoveragePlanProps) => {
  if (!hasProductSelected) {
    return <Redirect to={PATH.PRODUCT_SELECTION} push />
  }
  return (
    <div>
      <InsuredInformationBreadcrumb />
      <div id="coverage-plan">
        <div className="content">
          <BasicPlanCalculation />
          <div id="total-premium">
            <ExpectedReturnComparisonTable />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CoveragePlan
