// @flow
import moment from 'moment'
import _ from 'lodash'
import { isEmpty } from 'lodash/fp'
import { isInvestmentInputsValid } from 'quick-quote/product-investment/journey-control-selectors'

import type { Version } from 'quick-quote/version'
import type { ViewConfiguration } from 'core/data-model'
import { isQQFlow } from 'quick-quote/portal/selectors'
import { isAllowReviseQQMenu } from 'identity/selectors'
import type { AppState } from '../reducers'
import { createSelector } from 'reselect'
import { getSelectedBasicCategory, getSelectedDisplayProductCode } from 'quick-quote/product-selection/selectors'
import VALUES from 'core/data-model/constants/values'
import {
  getBirthdate,
  getBirthdateErrors,
  getInsuredOccupationErrors,
  getInsuredOtherOccupationErrors,
  getIsSelectedOtherOccupation,
  getIsValidBirthdate,
  getPayerBirthdate,
  getPayerIsValidBirthdate,
  getPayerNeeded,
  getPayerOccupationErrors,
  getSelectedNatureOfDutyCode,
  getSelectedOtherNatureOfDutyCode,
  getSelectedPayerNatureOfDutyCode,
} from 'quick-quote/insured-information/selectors'
import { isCompletedMarketConducts } from 'quick-quote/fna/market-conducts/selectors'
import { isCompletedNeedGap } from 'quick-quote/fna/need-and-gap-analysis/selectors'
import { isOnFnaPhase } from 'quick-quote/fna/selectors'
import type { AgentType } from 'core/data-model/identity'
import type { DistributorGroupType } from 'core/data-model/distributor'
import { getToggles } from 'quick-quote/feature-toggles'

import { getUserAgentType, getUserDistributorGroup } from 'identity/selectors'

export const getApplicationStatus = (state: AppState): string => state.status
export const getVersion = (state: AppState): Version => state.version
export const getAppVersion = (): string => {
  return process.env.APP_VERSION || ''
}
export const getAppName = (): string => {
  return process.env.APP_NAME || ''
}
export const getIsUserOnline = (): boolean => navigator.onLine
export const getViewConfiguration = (state: AppState): ViewConfiguration => state.viewConfiguration

export const getAllowedAgentTypes = (state: AppState): Array<AgentType> => state.allowedAgentTypes
// remote-selling
export const isSelectedRemoteSelling = (state: AppState): boolean => {
  return getToggles().ENABLE_APPMAN_REMOTE_SELLING && state.selectedRemoteSelling
}
export const getIsAllowRemoteSelling = createSelector(
  getSelectedBasicCategory,
  getUserAgentType,
  (productCategory, agentType: AgentType) => {
    return getToggles().ENABLE_INVESTMENT_PRODUCT_REMOTESELLING_WITHOUT_BANCA
      ? agentType !== 'LB'
      : productCategory !== VALUES.INVESTMENT && agentType !== 'LB'
  }
)

const isOccupationInvalid = (natureOfDuty: string, occpationErrors: string[]): boolean =>
  isEmpty(natureOfDuty) || !isEmpty(occpationErrors)
const isOtherOccupationInvalid = (selected: boolean, natureOfDuty: string, occpationErrors: string[]): boolean =>
  selected && isOccupationInvalid(natureOfDuty, occpationErrors)
const isInvalidBirthdate = (birthdate: string, isValidbirthdate: boolean): boolean =>
  isEmpty(birthdate) || !isValidbirthdate
const isSomeOccupationInvalid = (isOccupationInvalid: boolean, isOtherOccupationInvalid: boolean): boolean =>
  isOccupationInvalid || isOtherOccupationInvalid
const isBirthdateOrOccupationInvalid = (isBirthdateInvalid: boolean, hasSomeOccupationInvalid: boolean): boolean =>
  isBirthdateInvalid || hasSomeOccupationInvalid

const isInsuredBirthdateInvalid = createSelector(getBirthdate, getIsValidBirthdate, isInvalidBirthdate)

const isPayerBirthdateInvalid = createSelector(getPayerBirthdate, getPayerIsValidBirthdate, isInvalidBirthdate)

const isInsuredOccupationInvalid = createSelector(
  getSelectedNatureOfDutyCode,
  getInsuredOccupationErrors,
  isOccupationInvalid
)

const isPayerOccupationInvalid = createSelector(
  getSelectedPayerNatureOfDutyCode,
  getPayerOccupationErrors,
  isOccupationInvalid
)

const isInsuredOtherOccupationInvalid = createSelector(
  getIsSelectedOtherOccupation,
  getSelectedOtherNatureOfDutyCode,
  getInsuredOtherOccupationErrors,
  isOtherOccupationInvalid
)

const isPayerOtherOccupationInvalid = (state: AppState): boolean => {
  // TODO: Waiting for requirement
  // const selected = state.insured.payer.otherOccupation.isSelected
  // const natureOfDuty = state.insured.payer.otherOccupation.natureOfDuty.code
  // const occpationErrors = state.insured.payer.otherOccupation.errors
  // return isOtherOccupationInvalid(selected, natureOfDuty, occpationErrors)
  return false
}

const isInsuredSomeOccupationInvalid = createSelector(
  isInsuredOccupationInvalid,
  isInsuredOtherOccupationInvalid,
  isSomeOccupationInvalid
)

const isPayerSomeOccupationInvalid = createSelector(
  isPayerOccupationInvalid,
  isPayerOtherOccupationInvalid,
  isSomeOccupationInvalid
)

const isInsuredBirthdateOrOccupationInvalid = createSelector(
  isInsuredBirthdateInvalid,
  isInsuredSomeOccupationInvalid,
  isBirthdateOrOccupationInvalid
)

const isPayerBirthdateOrOccupationInvalid = createSelector(
  isPayerBirthdateInvalid,
  isPayerSomeOccupationInvalid,
  isBirthdateOrOccupationInvalid
)

export const validationEnteringESubRules = (
  distributorGroup: DistributorGroupType,
  rules: *,
  displayProductCode: string,
  currentDate: moment.Moment
) => {
  const validatedRule = _.get(rules, `${displayProductCode}.allowAgentRule`)
  const shouldValidateRule = validatedRule && validatedRule.agentTypes[0][distributorGroup] ? true : false
  if (shouldValidateRule) {
    const startDate = moment(
      validatedRule.agentTypes[0][distributorGroup].startDate,
      VALUES.DATE_FORMAT,
      VALUES.TIME_ZONE
    )
    const endDate = moment(validatedRule.agentTypes[0][distributorGroup].endDate, VALUES.DATE_FORMAT, VALUES.TIME_ZONE)
    const isValid = currentDate.isSameOrAfter(startDate) && currentDate.isSameOrBefore(endDate)
    return isValid
  } else {
    return false
  }
}

const _validateAgentEnteringEsub = createSelector(
  getUserDistributorGroup,
  getSelectedDisplayProductCode,
  (distributorGroup: DistributorGroupType, displayProductCode: string): string[] => {
    const rules = VALUES.VALIDATED_ENTERING_ESUB_RULES
    const validatedRule = _.get(rules, `${displayProductCode}.allowAgentRule`)
    const currentDate = moment(new Date()).tz(VALUES.TIME_ZONE)
    const _isEnteringEsub = (validatedRule) =>
      validatedRule ? validationEnteringESubRules(distributorGroup, rules, displayProductCode, currentDate) : true
    return _isEnteringEsub(validatedRule) ? [] : [validatedRule.errorMessage]
  }
)

export const getValidatedEnteringESubRules = createSelector(
  _validateAgentEnteringEsub,
  (validatedAgentEnteringEsub: string[]): string[] => {
    return validatedAgentEnteringEsub
  }
)

export const isBlockedPdfGeneration = createSelector(
  getBirthdateErrors,
  getBirthdate,
  getValidatedEnteringESubRules,
  (birthErrors, birthValue, enteringErrors): boolean => {
    return !_.isEmpty(birthErrors) || _.isEmpty(birthValue) || enteringErrors.length > 0
  }
)

export const isBlockedEnteringESubWholeLife = createSelector(
  isInsuredBirthdateOrOccupationInvalid,
  getPayerNeeded,
  isPayerBirthdateOrOccupationInvalid,
  (isInsuredInvalid, isPayerNeed, isPayerInvalid): boolean => isInsuredInvalid || (isPayerNeed && isPayerInvalid)
)

export const isBlockedEnteringESubInvestment = createSelector(
  isInsuredBirthdateOrOccupationInvalid,
  getPayerNeeded,
  isPayerBirthdateInvalid,
  (isInsuredInvalid, isPayerNeed, isPayerInvalid): boolean => isInsuredInvalid || (isPayerNeed && isPayerInvalid)
)

export const _isDisabledESubButton = (
  isBlockedEnteringESubWholeLife: boolean,
  isBlockedEnteringESubInvestment: boolean,
  canProceedToInvestmentBenefitIllustration: boolean,
  productCategory: string
): boolean => {
  if (productCategory === VALUES.INVESTMENT) {
    return isBlockedEnteringESubInvestment || !canProceedToInvestmentBenefitIllustration
  }
  return isBlockedEnteringESubWholeLife
}

export const isBlockedEnteringESub = createSelector(
  isBlockedEnteringESubWholeLife,
  isBlockedEnteringESubInvestment,
  isInvestmentInputsValid,
  getSelectedBasicCategory,
  _isDisabledESubButton
)

export const isProcessing = (state: AppState): boolean => state.processing

export const getAppState = (state: AppState) => state

export const getFnaConfiguration = (state: AppState) => state.fnaConfiguration

export const isCompletedFnaPhase = createSelector(
  isCompletedNeedGap,
  isCompletedMarketConducts,
  (completedNeedGap, completedMarketConducts) => {
    return completedNeedGap && completedMarketConducts
  }
)

export const isNeedToCompleteFNA = createSelector(
  isOnFnaPhase,
  isCompletedFnaPhase,
  (onFnaPhase, completedFnaPhase) => getToggles().ENABLE_FNA_FLOW && !completedFnaPhase
)

export const isQuickQuotePath = () => {
  const currentPath = window.location.pathname
  const quickQuotePath = '/advisors/quote/quick'
  return currentPath.includes(quickQuotePath)
}

export const isReviseQQFlow = createSelector(
  isQQFlow,
  isAllowReviseQQMenu,
  (isQQFlow, isAllowReviseQQ) => isQQFlow && isAllowReviseQQ
)
