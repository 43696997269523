//@flow
import type { PermissionResult } from 'core/service/distributor/permission'
import type { Action } from './actions'
import { REJECT_INVESTMENT_PERMISSION, RESET_INVESTMENT_PERMISSION, ALLOW_PROCEED_INVESTMENT } from './actions'

const initial = { isValid: false, type: 'NOT_VALIDATED' }
export const reducer = (state: PermissionResult = initial, action: Action): PermissionResult => {
  switch (action.type) {
    case REJECT_INVESTMENT_PERMISSION:
      return action.payload
    case RESET_INVESTMENT_PERMISSION:
      return initial
    case ALLOW_PROCEED_INVESTMENT:
      return { isValid: true, type: 'VALID' }
    default:
      return state
  }
}
