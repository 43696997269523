// @flow
import _ from 'lodash'

import { dashedOutOrWithPrecision, PolicyGioValueRow } from './policy-value-row'
import MESSAGES from 'core/data-model/constants/bi-messages'
import CONSTANTS from 'core/data-model/constants/messages'
import type { PolicyValueTableProps } from 'core/service/benefit-illustration/policy-value'

const PolicyValueGioTable = (props: PolicyValueTableProps) => {
  return (
    <div className="policy group" id="policy-value">
      <h3>{MESSAGES.POLICY_VALUE_TABLE}</h3>

      {renderNotePolicyValueTableForGio(props)}
    </div>
  )
}

const PolicyTable = ({ className, children }: { className?: string, children?: React.Node }) => (
  <table className={`policy-table${className ? ` ${className}` : ''}`} id="policy-value-table">
    {children}
  </table>
)

export const PolicyGio = ({ yearEndPolicyValues, lastYearEndPolicy, subtotalAnnualBasicPremium, subtotalCashDrop }) => (
  <PolicyTable className="with-cash-drop">
    <thead>
      <tr>
        <td width="5%">{CONSTANTS.AGE}</td>
        <td className="nowrap" width="10%">
          {MESSAGES.GIO_END_OF_POLICY}
        </td>
        <td className="nowrap" width="14%">
          {MESSAGES.PREMIUM_PER_YEAR}
        </td>
        <td className="nowrap" width="14%">
          {MESSAGES.GIO_CUMULATIVE_PREMIUM_PER_YEAR}
        </td>
        <td width="14%">
          {MESSAGES.GIO_CASHDROP_VALUE_1}
          <br />
          {MESSAGES.GIO_CASHDROP_VALUE_2}
          <br />
          {MESSAGES.GIO_CASHDROP_VALUE_3}
          <br />
          {MESSAGES.END_OF_YEAR_POLICY_WITH_BRACKET}
        </td>
        <td width="13%">
          {MESSAGES.GIO_DEATH_BENEFIT_OF_POLICY_1}
          <br />
          {MESSAGES.GIO_DEATH_BENEFIT_OF_POLICY_2}*<br />
          {MESSAGES.END_OF_YEAR_POLICY_WITH_BRACKET}
        </td>
        <td width="15%">
          {MESSAGES.BASIC_PLAN_DEATH_BENEFIT}
          <br />
          {MESSAGES.END_OF_YEAR_POLICY_WITH_BRACKET}
          <br />
          {MESSAGES.GIO_CUMULATIVE_DEATH_BENEFIT_1}
          <br />
          {MESSAGES.GIO_CUMULATIVE_DEATH_BENEFIT_2}
          <br />
          {MESSAGES.GIO_CUMULATIVE_DEATH_BENEFIT_3}***
        </td>
        <td width="15%">
          {MESSAGES.SURRENDER_CASH_VALUE}
          <br />
          {MESSAGES.GIO_POLICY_SURRENDER_VALUE_2}**
          <br />
          {MESSAGES.END_OF_YEAR_POLICY_WITH_BRACKET}
        </td>
      </tr>
    </thead>
    <tbody>
      {yearEndPolicyValues.map((policyValue) => (
        <PolicyGioValueRow
          key={`policy-value-row-${policyValue.age.value}`}
          age={policyValue.age}
          yearEndPolicy={policyValue.yearEndPolicy}
          annualBasicPremium={policyValue.annualBasicPremium}
          cumulativeBasicPremium={policyValue.cumulativeBasicPremium}
          cashDrop={policyValue.cashDrop}
          deathBenefit={policyValue.deathBenefit}
          cumulativeDeathBenefit={policyValue.cumulativeDeathBenefit}
          surrenderCash={policyValue.surrenderCash}
        />
      ))}
      <tr className="total" id={`policy-value-row-${lastYearEndPolicy}`} key={`policy-value-row-${lastYearEndPolicy}`}>
        <td />
        <td />
        <td>{dashedOutOrWithPrecision(subtotalAnnualBasicPremium, 2)}</td>
        <td />
        <td>{dashedOutOrWithPrecision(subtotalCashDrop, 2)}</td>
        <td />
        <td />
        <td />
      </tr>
    </tbody>
  </PolicyTable>
)

export const PolicyValueContent = ({ basicPlan, ...props }) => {
  return <PolicyGio {...props} />
}

const renderNotePolicyValueTableForGio = ({ policyValue, basicPlan }: PolicyValueTableProps) => {
  const lastYearEndPolicy = _.get(_.last(policyValue.yearEndPolicyValues), 'yearEndPolicy', 0) + 1

  // const policyValueTableProps = { policyValue, basicPlan }

  return (
    <div>
      <div className="table-wrap">
        <PolicyValueContent lastYearEndPolicy={lastYearEndPolicy} basicPlan={basicPlan} {...policyValue} />
      </div>
      <div className="disclaimer">
        <p>{MESSAGES.NOTE}:</p>
        <ul>
          <li>{MESSAGES.GIO_VALUE_TABLE_NOTE_1}</li>
          <li>{MESSAGES.GIO_VALUE_TABLE_NOTE_2}</li>
          <li>{MESSAGES.GIO_VALUE_TABLE_NOTE_3}</li>
          <li>{MESSAGES.GIO_VALUE_TABLE_NOTE_4}</li>
        </ul>
      </div>
    </div>
  )
}

export default PolicyValueGioTable
