import _ from 'lodash'
import { FormGroup } from 'reactstrap'

import { Dropdown } from 'common-components'
import { RANGE_OPTIONS } from 'quick-quote/my-wealth-plus/constant'

const RangeSelection = ({ value, onChange }) => {
  const handleChange = (val) => {
    const selectedRange = _.find(RANGE_OPTIONS, { value: val })
    onChange(selectedRange)
  }

  return (
    <FormGroup>
      <label>เลือกระยะเวลาการแสดงข้อมูล</label>
      <Dropdown options={RANGE_OPTIONS} onChange={handleChange} value={value} />
    </FormGroup>
  )
}

export default RangeSelection
