//@flow
import type { CashFlow } from 'core/service/investment/cash-flow/cash-flow'
import type { ExpectedReturn } from 'core/service/pdf-generation/products/investment/sections/benefit-table-example-group'
import type { InsuredProps } from 'core/service/pdf-generation/products/common/types'
import type Decimal from 'decimal.js'
import { Section, Table, Center } from 'core/service/pdf-generation/products/common/components/general'
import { PolicySummary } from 'core/service/pdf-generation/products/investment/components'

import BIMESSAGES from 'core/data-model/constants/bi-messages'
import VALUE from 'core/data-model/constants/values'
import { round, formatNumber } from 'core/service/lib/number-format'

// $$FlowFixMe
import leftArrow from '!url-loader!core/service/pdf-generation/assets/images/arrow/left.png'
// $$FlowFixMe
import rightArrow from '!url-loader!core/service/pdf-generation/assets/images/arrow/right.png'
import rightArrowEnd from '!url-loader!core/service/pdf-generation/assets/images/arrow/right-a.png'
import rightArrowBody from '!url-loader!core/service/pdf-generation/assets/images/arrow/right-b.png'
import rightArrowHead from '!url-loader!core/service/pdf-generation/assets/images/arrow/right-c.png'
// $$FlowFixMe
import downArrow from '!url-loader!core/service/pdf-generation/assets/images/arrow/down.png'
// $$FlowFixMe
import redLeftArrow from '!url-loader!core/service/pdf-generation/assets/images/arrow/redLeft.png'

type CashflowChartProps = {
  policyType: string,
  sumAssured: number,
  calculatedCoveragePeriod: number,
  calculatedPaymentPeriod: number,
  insured: InsuredProps,
  regularPremium: number,
  regularTopupPremium: number,
  coverageDeath: number,
  paymentModeLabel: string,
  cashFlowByExpectedReturn: { [ExpectedReturn]: CashFlow[] },
}

const CONST = {
  CHART_PREMIUM: 'เบี้ยประกันภัย',
  CHART_REGULAR_PREMIUM: 'เบี้ยประกันภัยหลักเพื่อความคุ้มครองรายงวด',
  CHART_REGULAR_TOPUP: 'เบี้ยประกันภัยเพื่อการลงทุนรายงวด (ถ้ามี)',
  CHART_INVEST_FUND: 'ลงทุนในกองทุน\nผ่านบริษัทจัดการ',
  CHART_HEADER: 'การเคลื่อนไหวของเบี้ยประกันภัยในปีแรก',
  CHART_RPUDR_HEADER:
    'การเคลื่อนไหวของเบี้ยประกันภัยหลักเพื่อความคุ้มครองและเบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษ (ถ้ามี) ในงวดแรก',
}

//============== Helper function

const defaultBoxStyles = {
  fontSize: 16,
  widths: [200],
}

const ColorBorderTable = (color: string) => ({
  hLineColor: color,
  vLineColor: color,
})

const Box = (backgroundColor: string) => (header: string, displayNumber: string, styles: Object) =>
  Table([[HeaderRow(backgroundColor)(header)], [Center(displayNumber)]], {
    ...defaultBoxStyles,
    ...styles,
    lineHeight: 1.4,
  })

const HeaderRow = (backgroundColor: string) => (header: string) => ({
  text: header,
  alignment: 'center',
  style: 'tableHeader',
  marginTop: 5,
  fillColor: backgroundColor,
})

export const LightBlueBox = Box(VALUE.AXA_LOGO_LIGHT_BLUE)
export const RedBox = Box(VALUE.RED)
export const BlueBox = Box(VALUE.HARD_BLUE)
export const GreenBox = Box(VALUE.EMERALD_GREEN)

export const CustomBox = (header: Object, displayNumber: string, styles: Object) =>
  Table([[header], [Center(displayNumber)]], {
    ...defaultBoxStyles,
    ...styles,
    lineHeight: 1.4,
  })

export const SingleLineBox = (header: string, displayNumber: string, styles: Object, headerColor: string) =>
  Table(
    [
      [
        {
          text: header,
          alignment: 'center',
          style: 'tableHeader',
          marginTop: 5,
          fillColor: headerColor,
        },
      ],
      [Center(displayNumber)],
    ],
    {
      ...defaultBoxStyles,
      ...styles,
      heights: [20],
      lineHeight: 1.4,
    }
  )

export const HeaderBox = (header: string, styles: Object) =>
  Table([[{ ...Center(header), margin: [0, 5] }]], {
    ...defaultBoxStyles,
    fontSize: 20,
    heights: [62],
    fillColor: styles.fillColor || VALUE.AXA_LOGO_LIGHT_BLUE,
    color: 'white',
    ...styles,
  })

//============ Main View
type ChartProps = {
  regularPremium: number,
  regularTopupPremium: number,
  cashFlowByExpectedReturn: { [ExpectedReturn]: CashFlow[] },
}

const roundAndFormat = (decimal: Decimal) => {
  const roundedNum = round(decimal.toNumber())
  const formattedNumber = formatNumber(roundedNum)

  return roundedNum === 0 ? '-' : formattedNumber
}

export const getFirstMonthCashFlow = (cashFlows: CashFlow[]): CashFlow => {
  return cashFlows.filter((cashflow) => cashflow.policyPeriod.year === 1 && cashflow.policyPeriod.month === 1).pop()
}

export const Chart = (productCode: string, props: ChartProps) => {
  switch (productCode) {
    case BIMESSAGES.BI_TYPE_PRODUCT_CODE_IINVEST:
      return iInvestChart(props)
    case BIMESSAGES.BI_TYPE_PRODUCT_CODE_IWEALTHY:
      return iWealthyChart(props)
    case BIMESSAGES.BI_TYPE_PRODUCT_CODE_RPUDR:
      return rpudrChart(props)
    default:
      return []
  }
}

const iWealthyChart = (props: ChartProps) => {
  const cashFlow = props.cashFlowByExpectedReturn['-1'][0]
  const { premiumCharge, adminCharge, chargeOfInsurance } = cashFlow.policyCharges
  const { accountValueAfterDeduction } = cashFlow.investment
  const { totalPremium } = cashFlow.premium
  const finalTotalPremium = totalPremium.minus(premiumCharge)
  return [
    { text: CONST.CHART_HEADER, color: VALUE.MEDIUM_BLUE, fontSize: 18, bold: true, marginTop: 12 },
    {
      columns: [
        {
          stack: [
            [BlueBox(CONST.CHART_REGULAR_PREMIUM, `${formatNumber(props.regularPremium)}`, { marginTop: 15 })],
            [BlueBox(CONST.CHART_REGULAR_TOPUP, `${formatNumber(props.regularTopupPremium)}`, { marginTop: 5 })],
            [HeaderBox(CONST.CHART_INVEST_FUND, { marginTop: 42 })],
          ],
          margin: [10, 0, 0, 0],
        },
        {
          stack: [
            { image: rightArrow, width: 45, marginTop: 53, marginLeft: -4 },
            { image: leftArrow, width: 45, marginTop: 110, marginLeft: -4 },
          ],
          width: 40,
        },
        {
          stack: [
            [RedBox('ค่าธรรมเนียมในการจัดสรรเบี้ยประกันภัย', `${roundAndFormat(premiumCharge)}`, { marginTop: 40 })],
            [LightBlueBox('มูลค่าบัญชีกรมธรรม์', `${roundAndFormat(accountValueAfterDeduction)}`, { marginTop: 89 })],
          ],
          margin: [10, 0, 0, 0],
        },
        {
          stack: [
            { image: rightArrow, width: 45, marginTop: 53, marginLeft: -4 },
            { image: redLeftArrow, width: 45, marginTop: 110, marginLeft: -4 },
          ],
          width: 40,
        },
        {
          stack: [
            [BlueBox('เบี้ยประกันภัยส่วนที่นำไปลงทุน', `${roundAndFormat(finalTotalPremium)}`, { marginTop: 40 })],
            { image: downArrow, height: 25, width: 55, margin: [75, 5, 0, 0] },
            { text: 'หักเป็นรายเดือนจากมูลค่าบัญชีกรมธรรม์', margin: [35, 0, 0, 0] },
            Table(
              [
                [
                  {
                    stack: [
                      [RedBox('ค่าธรรมเนียมการบริหารกรมธรรม์', `${roundAndFormat(adminCharge)}`, { marginTop: 5 })],
                      [RedBox('ค่าการประกันภัย*', `${roundAndFormat(chargeOfInsurance)}`, { margin: [0, 5] })],
                    ],
                  },
                ],
              ],
              { marginTop: 5, layout: ColorBorderTable(VALUE.RED) }
            ),
            {
              text: '*ค่าการประกันภัยจะแตกต่างเป็นรายเดือนตามอายุของผู้ขอเอาประกันภัย',
              fontSize: 12,
              width: '*',
              marginTop: 2,
            },
          ],
          margin: [10, 0, 0, 0],
        },
      ],
    },
  ]
}

const iInvestChart = (props: ChartProps) => {
  const cashFlow = props.cashFlowByExpectedReturn['-1'][0]
  const { premiumCharge, adminCharge, chargeOfInsurance } = cashFlow.policyCharges
  const { accountValueAfterDeduction } = cashFlow.investment
  const { totalPremium } = cashFlow.premium
  const finalTotalPremium = totalPremium.minus(premiumCharge)
  return [
    { text: CONST.CHART_HEADER, color: VALUE.MEDIUM_BLUE, fontSize: 18, bold: true, marginTop: 12 },
    {
      columns: [
        {
          stack: [
            [BlueBox(CONST.CHART_PREMIUM, `${formatNumber(props.regularPremium)}`, { marginTop: 40 })],
            [HeaderBox(CONST.CHART_INVEST_FUND, { marginTop: 89, fillColor: VALUE.AXA_LOGO_LIGHT_BLUE })],
          ],
          margin: [10, 0, 0, 0],
        },
        {
          stack: [
            { image: rightArrow, width: 45, marginTop: 53, marginLeft: -4 },
            { image: leftArrow, width: 45, marginTop: 110, marginLeft: -4 },
          ],
          width: 40,
        },
        {
          stack: [
            [RedBox('ค่าธรรมเนียมในการจัดสรรเบี้ยประกันภัย', `${roundAndFormat(premiumCharge)}`, { marginTop: 40 })],
            [LightBlueBox('มูลค่าบัญชีกรมธรรม์', `${roundAndFormat(accountValueAfterDeduction)}`, { marginTop: 89 })],
          ],
          margin: [10, 0, 0, 0],
        },
        {
          stack: [
            { image: rightArrow, width: 45, marginTop: 53, marginLeft: -4 },
            { image: redLeftArrow, width: 45, marginTop: 110, marginLeft: -4 },
          ],
          width: 40,
        },
        {
          stack: [
            [BlueBox('เบี้ยประกันภัยส่วนที่นำไปลงทุน', `${roundAndFormat(finalTotalPremium)}`, { marginTop: 40 })],
            { image: downArrow, height: 25, width: 55, margin: [75, 5, 0, 0] },
            { text: 'หักเป็นรายเดือนจากมูลค่าบัญชีกรมธรรม์', margin: [35, 0, 0, 0] },
            Table(
              [
                [
                  {
                    stack: [
                      [RedBox('ค่าธรรมเนียมการบริหารกรมธรรม์', `${roundAndFormat(adminCharge)}`, { marginTop: 5 })],
                      [RedBox('ค่าการประกันภัย*', `${roundAndFormat(chargeOfInsurance)}`, { margin: [0, 5] })],
                    ],
                  },
                ],
              ],
              { marginTop: 5, layout: ColorBorderTable(VALUE.RED) }
            ),
            {
              text: '*ค่าการประกันภัยจะแตกต่างเป็นรายเดือนตามอายุของผู้ขอเอาประกันภัย',
              fontSize: 12,
              width: '*',
              marginTop: 2,
            },
          ],
          margin: [10, 0, 0, 0],
        },
      ],
    },
  ]
}

const rpudrChart = (props: ChartProps) => {
  const fontSize = 10.5
  const { cashFlowByExpectedReturn } = props
  const firstPolicyPeriodCashFlow = cashFlowByExpectedReturn[Object.keys(cashFlowByExpectedReturn)[0]][0]
  const { premium, policyCharges, coiCor, insuranceManagementFee, investment } = firstPolicyPeriodCashFlow
  const roundAndFormatNumber = (value) => formatNumber(round(value, 2), 2)
  const verticalBoxSpace = { text: '', marginTop: 25 }
  return [
    { text: CONST.CHART_RPUDR_HEADER, fontSize: 14, bold: true, decoration: 'underline', marginTop: 18 },
    Table(
      [
        [
          {
            stack: [
              [
                BlueBox(
                  'เบี้ยประกันภัยหลักเพื่อความคุ้มครอง',
                  `${roundAndFormatNumber(premium.totalRegularAndRiderPremium)}`,
                  {
                    marginTop: 40,
                    widths: [100],
                    fontSize,
                  }
                ),
              ],
              { ...verticalBoxSpace },
              [
                BlueBox(
                  'เบี้ยประกันภัยเพื่อการลงทุน\nเพิ่มเติมพิเศษ (ถ้ามี)',
                  `${roundAndFormatNumber(premium.lumpSum)}`,
                  {
                    widths: [100],
                    fontSize,
                  }
                ),
              ],
            ],
            margin: [0, 0, 0, 0],
          },
          {
            stack: [
              { image: rightArrow, width: 25, marginTop: 60 },
              { image: rightArrow, width: 25, marginTop: 65 },
            ],
            width: 25,
          },
          {
            stack: [
              [
                RedBox(
                  'ค่าธรรมเนียมในการจัดสรร\nเบี้ยประกันภัยหลักเพื่อความคุ้มครอง',
                  `${roundAndFormatNumber(policyCharges.regularPremiumCharge)}`,
                  {
                    marginTop: 40,
                    widths: [120],
                    fontSize,
                  }
                ),
              ],
              { ...verticalBoxSpace },
              [
                RedBox(
                  'ค่าธรรมเนียมในการจัดสรรเบี้ยประกันภัย\nเพื่อการลงทุนเพิ่มเติมพิเศษ',
                  `${roundAndFormatNumber(policyCharges.lumpSumCharge)}`,
                  {
                    widths: [120],
                    fontSize,
                  }
                ),
              ],
            ],
            margin: [0, 0, 0, 0],
          },
          {
            stack: [
              { image: rightArrow, width: 25, marginTop: 60 },
              { image: rightArrowEnd, width: 25, marginTop: 65 },
            ],
            width: 25,
          },
          {
            stack: [
              { text: 'ค่าธรรมเนียมกรมธรรม์รายเดือน*', color: VALUE.RED, marginLeft: 2, marginTop: 5, fontSize: 12 },
              Table(
                [
                  [
                    {
                      stack: [
                        [
                          SingleLineBox(
                            'ค่าการประกันภัย**',
                            `${roundAndFormatNumber(coiCor.total)}`,
                            {
                              widths: [100],
                              fontSize,
                            },
                            VALUE.RED
                          ),
                        ],
                        { text: '', marginTop: 1 },
                        [
                          SingleLineBox(
                            'ค่าธรรมเนียมการบริหารกรมธรรม์',
                            `${roundAndFormatNumber(insuranceManagementFee.total)}`,
                            {
                              widths: [100],
                              fontSize,
                            },
                            VALUE.RED
                          ),
                        ],
                      ],
                    },
                  ],
                ],
                { marginTop: 2, layout: ColorBorderTable(VALUE.RED) }
              ),
              { image: rightArrowBody, height: 25, width: 140, marginTop: 23.17, marginLeft: -20 },
            ],
            margin: [1, 1, 1, 1],
            alignment: 'center',
          },
          {
            stack: [
              { image: rightArrow, width: 25, marginTop: 60 },
              { image: rightArrowHead, width: 25, marginTop: 65 },
            ],
            width: 25,
          },
          {
            stack: [
              [
                BlueBox(
                  'เบี้ยประกันภัยหลักเพื่อ\nความคุ้มครองส่วนที่นำไปลงทุน',
                  `${roundAndFormatNumber(investment.redemptionValueAtBeginningMonth.rpp)}`,
                  {
                    marginTop: 40,
                    widths: [120],
                    fontSize,
                  }
                ),
              ],
              { ...verticalBoxSpace },
              [
                BlueBox(
                  'เบี้ยประกันภัยเพื่อการลงทุน\nเพิ่มเติมพิเศษส่วนที่นำไปลงทุน',
                  `${roundAndFormatNumber(investment.redemptionValueAtBeginningMonth.lstu)}`,
                  {
                    widths: [120],
                    fontSize,
                  }
                ),
              ],
            ],
            margin: [0, 0, 0, 0],
          },
          {
            stack: [{ image: rightArrow, width: 25, marginTop: 106 }],
            width: 25,
          },
          {
            stack: [
              [
                Table([[{ ...Center('ลงทุนในกองทุนผ่านบริษัทจัดการ***'), marginTop: 15 }]], {
                  ...defaultBoxStyles,
                  fontSize: 18,
                  heights: [71],
                  widths: [120],
                  fillColor: VALUE.AXA_LOGO_LIGHT_BLUE,
                  color: 'white',
                  marginTop: 81,
                }),
              ],
            ],
            margin: [0, 0, 0, 1],
          },
        ],
      ],
      {
        layout: 'noBorders',
      }
    ),
    {
      text: '',
      marginTop: 10,
    },
    {
      text: '* ตั้งแต่เดือนที่ 2 เป็นต้นไป ค่าธรรมเนียมกรมธรรม์รายเดือนจะหักจากการขายคืนหน่วยลงทุนอัตโนมัติ',
      fontSize: 12,
      marginTop: 2,
    },
    { text: '** ค่าการประกันภัยขึ้นกับอายุของผู้เอาประกันภัย', fontSize: 12, marginTop: 2 },
    {
      text:
        '*** ผู้เอาประกันภัยสามารถเลือกการจัดสรรการลงทุนของเบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษแตกต่างจากการจัดสรรการลงทุนของเบี้ยประกันภัยหลักเพื่อความคุ้มครองได้ ทั้งนี้เป็นไปตามหลักเกณฑ์ที่บริษัทกำหนด',
      fontSize: 12,
      marginTop: 2,
    },
  ]
}

export const CashflowChart = (productCode: string, props: CashflowChartProps) =>
  Section(BIMESSAGES.CASHFLOW_CHART_HEADER, [PolicySummary(productCode, props, 0.5), Chart(productCode, props)])
