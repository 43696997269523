import _ from 'lodash'

import { createSectionCreator, createField } from './utils'
import { checker, isMatchValue } from './selectors'

import { getCountryList } from 'e-submission/apps/selectors'

const CRSField = (app, id) => {
  const isShowRemoveButton = !id.includes('[0]')
  const { isYes, isNo } = checker(app)
  return _.compact([
    createField(`${id}.tax.country`, [
      {
        label: 'ประเทศถิ่นที่อยู่ทางภาษี',
        id: `${id}.tax.country`,
        c: 'Dropdown',
        required: true,
        p: {
          mergeState: (state) => ({
            options: getCountryList(state).slice(1),
          }),
        },
      },
    ]),
    createField(`${id}.tax.hasTIN.checked`, [
      {
        id: `${id}.tax.hasTIN.checked`,
        c: 'RadioButton',
        required: true,
        label: 'ท่านมีหมายเลขประจำตัวผู้เสียภาษี(TIN) หรือไม่',
        p: {
          options: [
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
            {
              text: 'ใช่',
              value: 'yes',
            },
          ],
          clearValue: `${id}.tax.info`,
        },
      },
    ]),
    isYes(`${id}.tax.hasTIN.checked`) &&
      createField(`${id}.tax.info.TIN`, [
        {
          label: 'ระบุหมายเลขประจำตัวผู้เสียภาษี(TIN)',
          id: `${id}.tax.info.TIN`,
          c: 'TextInput',
          required: true,
          p: {},
        },
      ]),
    isNo(`${id}.tax.hasTIN.checked`) &&
      createField(`${id}.tax.info.NoTIN.reason`, [
        {
          label: '*ในกรณีที่ท่านไม่มีเลขประจำตัวผู้เสียภาษี กรุณาระบุเหตุผลตามข้อ ก. ข. หรือ ค. ดังต่อไปนี้',
          id: `${id}.tax.info.NoTIN.reason`,
          c: 'RadioGroup',
          required: true,
          p: {
            options: [
              {
                text: 'เหตุผล ก. ประเทศที่ข้าพเจ้ามีถิ่นที่อยู่ทางภาษีไม่มีการออกหมายเลขประจำตัวผู้เสียภาษี',
                value: 'a',
                description: 'Reason A. The country where I am subject to Income Tax as a resident does not issue TIN.',
              },
              {
                text:
                  'เหตุผล ข. ข้าพเจ้าไม่สามารถขอรับหมายเลขประจำตัวผู้เสียภาษีหรือเลขอื่นใดที่เทียบเท่ากันได้ (กรุณาอธิบายเพิ่มเติมว่าทำไมท่านจึงไม่ได้รับหมายเลขประจำตัวผู้เสียภาษีที่ออกโดยประเทศนั้น ในตารางด้านล่างหากท่านเลือกเหตุผลนี้)',
                value: 'b',
                description:
                  'Reason B. I am otherwise unable to obtain a TIN or equivalent number (Please explain why you are unable to obtain a TIN in the below table if you have selected this reason)',
              },
              {
                text:
                  'เหตุผล ค. ไม่จำเป็นต้องใช้หมายเลขประจำตัวผู้เสียภาษี เนื่องจากกฎหมายภายในประเทศที่ออกเลขประจำตัวผู้เสียภาษีนั้น ไม่กำหนดให้สถาบันการเงินเก็บ รวบรวมและรายงานเลขประจำตัวผู้เสียภาษี ',
                value: 'c',
                description:
                  'Reason C. No TIN is required because of the tax residence jurisdiction that issued the TIN does not require a Finance Institution to collect and report the TIN',
              },
            ],
          },
        },
      ]),
    isNo(`${id}.tax.hasTIN.checked`) &&
      isMatchValue(`${id}.tax.info.NoTIN.reason`, 'b')(app) &&
      createField(`${id}.tax.info.NoTIN.reason.detail`, [
        {
          label:
            'กรณีที่ท่านเลือกเหตุผลข้อ ข ในตารางข้างต้น โปรดอธิบายในส่วนต่อไปนี้ว่าเหตุใดท่านจึงไม่สามารถขอรับหมายเลขประจำตัวผู้เสียภาษี',
          id: `${id}.tax.info.NoTIN.reason.detail`,
          c: 'TextInput',
          placeholder: 'English language is preferred (แนะนำให้กรอกเป็นภาษาอังกฤษ)',
          required: true,
          p: {},
        },
      ]),
    isShowRemoveButton &&
      createField(
        `${id}.remove`,
        [
          {
            id: `${id}.remove`,
            c: 'Button',
            p: {
              icon: 'remove',
              children: 'ลบ',
              mergeProps: ({ removeValue }) => ({
                onClick: () => removeValue(id),
              }),
              style: {
                fontSize: '0.8725rem',
                padding: '0.125rem 0.5rem',
              },
            },
          },
        ],
        {
          style: {
            position: 'absolute',
            top: '0',
            right: '0',
          },
        }
      ),
  ])
}

const addCRS = (app, id) => [
  createField(`${id}.addNew`, [
    {
      id,
      c: 'Button',
      p: {
        children: 'เพิ่มประเทศถิ่นที่อยู่ทางภาษี',
        icon: 'add',
        block: true,
        mergeState: (state) => ({
          mergeProps: ({ setValue }) => ({
            onClick: () => setValue({ tax: { country: getCountryList(state)[1] } }),
          }),
        }),
      },
    },
  ]),
]

export default (sectionId, sectionLabel) => (app, id) => {
  const CRSDetail = _.get(app, `${id}.CRS.detail`, [null])
  const { isYes, isNo } = checker(app)

  return _.compact(
    isYes(`${id}.CRS.checked`) &&
      _.reduce(
        CRSDetail,
        (result, el, index) => [
          ...result,
          createSectionCreator(CRSField)(
            `${sectionId}[${index}]`,
            null,
            null,
            `ประเทศถิ่นที่อยู่ทางภาษี ลำดับที่ ${index + 1}`
          )(app, `${id}.CRS.detail[${index}]`),
        ],
        []
      ).concat(
        CRSDetail.length < 3 && createSectionCreator(addCRS)(sectionId)(app, `${id}.CRS.detail[${CRSDetail.length}]`)
      )
  )
}
