// @flow
import type { InterestRate$ } from 'core/data-model/basic-plan'
import { enableInterestRate, renderErrorMessageAcceptZeroValue } from 'common-components/view-logic'

type InterestRateErrorMessagesProps = {
  haveBasicPremiumState: boolean,
  interestRate$: InterestRate$,
}

const InterestRateErrorMessages = ({ haveBasicPremiumState, interestRate$ }: InterestRateErrorMessagesProps) => {
  return (
    <div>
      {!enableInterestRate(haveBasicPremiumState, interestRate$)
        ? null
        : renderErrorMessageAcceptZeroValue('error-interest-rate-message')(interestRate$)}
    </div>
  )
}

export default InterestRateErrorMessages
