/**
 * To manipulate pattern of SVG pie-chart in ILP Fund allocation
 */
import { lines, circles, squares, nylon, waves, crosses, hexagons, rhombic } from 'svg-patterns'
import stringify from 'virtual-dom-stringify'

import values from 'core/data-model/constants/values'
import { getToggles } from 'quick-quote/feature-toggles'

const PATTERNS = [lines, circles, squares, nylon, waves, crosses, hexagons, rhombic]
const PATTERN_CONFIGS = [
  (color) => ({
    stroke: color,
    background: null,
  }),
  (color) => ({
    fill: '#222222',
    background: color,
  }),
  (color) => ({
    stroke: '#222222',
    background: color,
  }),
  (color) => ({
    stroke: '#222222',
    background: color,
  }),
  (color) => ({
    stroke: '#222222',
    background: color,
  }),
  (color) => ({
    stroke: '#222222',
    background: color,
  }),
  (color) => ({
    stroke: '#222222',
    background: color,
  }),
  (color) => ({
    stroke: '#222222',
    background: color,
  }),
]

const fundGroupColor = (index) => values.PIE_CHART_FUND_COLORS[index % values.PIE_CHART_FUND_COLORS.length]
const getSVGPattern = (index) => PATTERNS[index % PATTERNS.length]
const getSBGPatternConfig = (index, color) => PATTERN_CONFIGS[index % PATTERNS.length](color)

export const getPatternByIndex = (index) => {
  const isUsePatternForChart = getToggles().ENABLE_FUND_PIE_CHART_PATTERN
  const pattern = getSVGPattern(index)
  const color = fundGroupColor(index)
  const patternConfig = getSBGPatternConfig(index, color)
  const renderPattern = pattern({
    ...patternConfig,
  })

  return {
    color: fundGroupColor(index),
    string: stringify(renderPattern),
    url: isUsePatternForChart ? renderPattern.url() : fundGroupColor(index),
  }
}
