//@flow
import type { RemoteSellingNotification } from '../notification/actions'
import { Modal } from '../../common-components'
import { Button, ModalBody, ModalFooter, ModalHeader, FormFeedback, FormGroup } from 'reactstrap'
import { bindActionCreators } from 'redux'
import { setProcessingSuccess, startStandardSelling, startRemoteSelling } from '../actions'
import { connect } from 'react-redux'

type RemoteSellingProps = {
  startStandardSelling: Function,
  startRemoteSelling: Function,
  setProcessingSuccess: Function,
  notificationMessage: RemoteSellingNotification,
  onExit: Function,
}

export const _RemoteSellingPopup = ({ notificationMessage, onExit, ...props }: RemoteSellingProps) => (
  <Modal
    isOpen={notificationMessage !== 'None'}
    backdrop={true}
    size="lg"
    modalClassName="default-bootstrap remote-selling-modal"
    toggle={() => {
      props.setProcessingSuccess()
      onExit()
    }}
  >
    <ModalHeader
      toggle={() => {
        props.setProcessingSuccess()
        onExit()
      }}
    >
      รูปแบบวิธีการขาย
    </ModalHeader>
    <ModalBody>
      <FormGroup className="has-warning">
        <FormFeedback className={'eligibility-message attached-icon'}>
          <div>
            เนื่องด้วยสถานการณ์การแพร่ระบาดของโรคติดเชื้อไวรัสโคโรนา 2019 (COVID-19) ทางบริษัทฯ
            จึงได้ออกแบบระบบที่รองรับการขายแบบ Digital Remote Selling
            เพื่อช่วยอำนวยความสะดวกแก่ลูกค้าและสนับสนุนการทำงานของท่านเป็นการชั่วคราว
          </div>
        </FormFeedback>
      </FormGroup>
      <p>
        <b>โดยมีเงื่อนไขดังนี้</b>
      </p>
      <ol>
        <li>ใช้เฉพาะกรณีไม่สามารถเข้าพบลูกค้าได้เท่านั้น </li>
        <li>ต้องแนบภาพบัตรประชาชนของผู้เอาประกัน พร้อมระบุข้อความยืนยันตามที่บริษัทฯ กำหนด ผ่าน Advisor Zone</li>
        <li>ต้องแนบภาพเซลฟี่ของผู้เอาประกันตามรูปแบบที่บริษัทฯ กำหนด ผ่าน Advisor Zone</li>
        <li>แจ้งลูกค้าว่าทางบริษัทฯ จะติดต่อเพื่อยืนยันการสมัคร ก่อนการพิจารณา (เฉพาะแบบประกันควบการลงทุนเท่านั้น)</li>
      </ol>
      อ่านข้อมูลเพิ่มเติมเกี่ยวกับ{' '}
      <a target="_blank" href="https://ktaxa.live/digital-remote-selling">
        Digital Remote Selling
      </a>{' '}
      (แบบลูกค้าไม่สะดวกให้เข้าพบ)
      <br />
      <p className="text-danger">**กรณีตรวจสอบพบว่าดำเนินการเลือกรูปแบบการขายผิด บริษัทฯ ขอสงวนสิทธิในการพิจารณา</p>
    </ModalBody>
    <ModalFooter>
      <div className="bottom">
        <Button
          color="outline-primary"
          onClick={() => {
            onExit()
            props.startRemoteSelling()
          }}
        >
          AZ แบบลูกค้าไม่สะดวกให้เข้าพบ
        </Button>
        <Button
          color="outline-primary"
          onClick={() => {
            onExit()
            props.startStandardSelling()
          }}
        >
          AZ แบบเข้าพบลูกค้า
        </Button>
      </div>
    </ModalFooter>
  </Modal>
)

const mapDispatchToRemoteSellingPopupProps = (dispatch: Dispatch<*>) => ({
  startStandardSelling: bindActionCreators(startStandardSelling, dispatch),
  startRemoteSelling: bindActionCreators(startRemoteSelling, dispatch),
  setProcessingSuccess: bindActionCreators(setProcessingSuccess, dispatch),
})

export default connect(null, mapDispatchToRemoteSellingPopupProps)(_RemoteSellingPopup)
