// @flow
import { compact } from 'lodash/fp'
import type { AppState } from 'quick-quote/reducers'
import BIMESSAGES from 'core/data-model/constants/bi-messages'
// Section props/data
import { getSelectedRiders } from 'quick-quote/product-common/coverage-plan/selectors'
import { getCommonInvestmentInfo } from 'quick-quote/v2/products/categories/investment/selectors'

// Section Components
import RiderBenefits from 'quick-quote/product-common/benefit-illustration/components/rider-benefits'
import ProfileSummary from './components/profile-summary'
import ProductBenefits from './components/product-benefits'

export type { DisplayProduct } from 'core/service/display-product'

export const getSections = (state: AppState) => {
  const riders = getSelectedRiders(state)
  return compact([
    {
      component: ProfileSummary,
      props: {
        ...getCommonInvestmentInfo(state),
        riders,
      },
    },
    {
      component: ProductBenefits,
      props: {
        ...getCommonInvestmentInfo(state),
      },
    },
    riders.length > 0 && {
      component: RiderBenefits,
    },
  ])
}

export const getMenus = (state: AppState) => [
  {
    text: BIMESSAGES.SUMMARY_OF_MAIN_AND_ADDITIONAL_CONTRACTS,
    id: 'profile-summary',
    isSub: false,
  },
  {
    text: 'รายละเอียดของสัญญาหลัก',
    id: 'product-benefits',
    isSub: false,
  },
]
