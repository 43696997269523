import { flow, filter, map, get, sortBy } from 'lodash/fp'
const dataPromise = import(/* webpackChunkName: "e-submission-data" */ 'e-submission/mocks')

const moveSpecificValueToFrontOfArray = (value) => (array) => {
  const specificValueFromArray = array.filter((x) => x.value === value)
  const nonSpecificValueFromArray = array.filter((x) => x.value !== value)
  return [...specificValueFromArray, ...nonSpecificValueFromArray]
}
const moveSpecificTextToFrontOfArray = (value) => (array) => {
  const specificValueFromArray = array.filter((x) => x.text === value)
  const nonSpecificValueFromArray = array.filter((x) => x.text !== value)
  return [...specificValueFromArray, ...nonSpecificValueFromArray]
}

export const getPersonalNationality = () => {
  return dataPromise.then(flow(get('nationality'), sortBy('text'), moveSpecificValueToFrontOfArray('Thai')))
}
export const getCountry = () => {
  return dataPromise.then(flow(get('country'), sortBy('text'), moveSpecificValueToFrontOfArray('THAILAND')))
}

export const getDividend = () => {
  return dataPromise.then(({ dividend }) => dividend)
}

export const getPersonalDocumentType = () => {
  return dataPromise.then(({ documentType }) => documentType)
}

export const getPersonalTitle = () => {
  return dataPromise.then(({ title }) => title)
}

export const getPersonalTitleEN = () => {
  return dataPromise.then(({ titleEN }) => titleEN)
}

export const getRelationship = () => {
  return dataPromise.then(({ relationship }) => relationship)
}

export const getProvince = () => {
  return dataPromise.then(
    flow(
      get('province'),
      filter(['nation_code', 'TH']),
      map(({ code, name_th }) => ({
        value: code,
        text: name_th,
      })),
      sortBy('text'),
      moveSpecificTextToFrontOfArray('กรุงเทพมหานคร')
    )
  )
}

export const getAmphur = () => {
  return dataPromise.then(
    flow(
      get('amphur'),
      filter(['nation_code', 'TH']),
      map(({ code, name_th, province_code }) => ({
        province_code,
        value: code,
        text: name_th,
      })),
      sortBy('text')
    )
  )
}

export const getTambol = () => {
  return dataPromise.then(
    flow(
      get('tambol'),
      filter(['nation_code', 'TH']),
      map(({ code, name_th, postal, province_code, amphur_code }) => ({
        province_code,
        amphur_code,
        postal,
        value: code,
        text: name_th,
      })),
      sortBy('text')
    )
  )
}

export const getBank = () => {
  return dataPromise.then(({ bank }) => bank)
}

export const getNetbank = () => {
  return dataPromise.then(({ netbank }) => netbank)
}
