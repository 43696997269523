// @flow

import { getAppConfig } from 'deploy-env/app-config'
import prodConfig from '../deploy-env/prod/config-oc4.json'
import uatConfig from '../deploy-env/uat/config-oc4.json'
import sandboxConfig from '../deploy-env/sandbox/config-oc4.json'

const initialToggle = {}
export const getToggles = (): { [string]: boolean } => getAppConfig().toggles || initialToggle
export const getTogglesAllEnv = () => ({
  prod: prodConfig.toggles,
  uat: uatConfig.toggles,
  sandbox: sandboxConfig.toggles,
})
