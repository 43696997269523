// @flow
import { connect } from 'react-redux'
import type { AppState } from 'quick-quote/reducers'
import BenefitIllustration from '../../../components/benefit-illustration/container'

import { isBlockedEnteringESub } from 'quick-quote/selectors'
import { getSelectedDisplayProductCode } from 'quick-quote/product-selection/selectors'
import { getSections, getRPULSection, getSPULSection } from './selectors'
import { getSections as getRPUDRSection, getMenus as getRPUDRMenus } from '../../customs/rpudr/selectors'

const getSectionByProductCode = (state: AppState) => {
  const productCode = getSelectedDisplayProductCode(state)
  switch (productCode) {
    case 'RPUL':
      return getRPULSection(state)
    case 'SPUL':
      return getSPULSection(state)
    case 'RPUDR':
      return getRPUDRSection(state)
    default:
      return getSections(state)
  }
}

const getMenuByProductCode = (state: AppState) => {
  const productCode = getSelectedDisplayProductCode(state)
  switch (productCode) {
    case 'RPUDR':
      return getRPUDRMenus(state)
    default:
      return []
  }
}

const mapStateToProps = (state: AppState) => ({
  sections: getSectionByProductCode(state),
  menus: getMenuByProductCode(state),
  isBlockedEnteringESub: isBlockedEnteringESub(state),
})

const mapDispatchToProps = () => ({})

export const BenefitIllustrationContainer = connect(mapStateToProps, mapDispatchToProps)(BenefitIllustration)

export default BenefitIllustrationContainer
