// @flow

import { flow, get, find } from 'lodash/fp'
import { takeLatest, select, put } from 'redux-saga/effects'

import { getAllProductSavingPlan, getAllowForProductSavingCoveragePlan } from './selectors'
import { SET_DISTRIBUTOR_GROUP } from 'identity/actions'
import * as SavingAction from 'quick-quote/product-saving/coverage-plan/actions'
import * as CommonSaga from 'quick-quote/product-common/coverage-plan/sagas'
import * as CommonSelector from 'quick-quote/product-common/coverage-plan/selectors'
// import * as InsuredAction from 'quick-quote/insured-information/actions'
// import * as InsuredSaga from 'quick-quote/insured-information/sagas'

export function* generateDefaultProductSelectedPlan(action: *): Generator<*, *, *> {
  const isAllowForProductSavingCoveragePlan = yield select(getAllowForProductSavingCoveragePlan)
  if (!isAllowForProductSavingCoveragePlan) return

  const productPlans = yield select(getAllProductSavingPlan)
  const currentSumAssured = yield select(CommonSelector.getSumAssured)
  const defaultProductPlan =
    flow(find({ plan: currentSumAssured }), get('plan'))(productPlans) || get('0.plan')(productPlans)
  yield put(SavingAction.updateProductPlan(defaultProductPlan.toString()))
}

export function* watchers(action: *): Generator<*, *, *> {
  yield [
    takeLatest(SavingAction.UPDATE_PRODUCT_PLAN, CommonSaga.generateCoveragePlanFromSumAssuredChange),
    takeLatest(SET_DISTRIBUTOR_GROUP, generateDefaultProductSelectedPlan),
  ]
}

export const sagas = watchers
