import { getAppVersion } from 'quick-quote/selectors'
export function ErrorMessageArea(props) {
  return (
    <div>
      <div className="error-msg-label">
        <div className="error-msg-left-label">Message Detail</div>
      </div>
      <div className="text-center">
        <textarea
          id="errorMessageArea"
          className="error-msg"
          readOnly
          value={'Version : ' + getAppVersion() + '\r\nMessage : ' + props.errorMsg}
        />
      </div>
    </div>
  )
}
