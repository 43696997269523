import { legacy_createStore as createStore, applyMiddleware, compose } from 'redux'
import { reducer as rootReducer } from './apps'
import config from 'config'

const initialState = {}

const middlewares = []
const enhancers = [applyMiddleware(...middlewares)]

let composeEnhancers = compose
/* istanbul ignore next */
if (config.ocEnvironment !== 'production' && typeof window === 'object') {
  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      name: 'E-Submission',
    })
  }
}

const store = createStore(rootReducer, initialState, composeEnhancers(...enhancers))

/* istanbul ignore next */
if (module.hot) {
  module.hot.accept('./apps', () => {
    const { reducer: rootReducer } = require('./apps')
    store.replaceReducer(rootReducer)
  })
}

export default store
