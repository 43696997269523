// @flow

import type { Age } from 'core/data-model/insured'
import { queryPremiumRateForWpd as queryPremiumRate } from 'core/service/rider/query-premium-rate'
import { calculateRiderPremiumForWPDD as calculateRiderPremium } from 'core/service/rider/premium-calculation'

const riderRules = {
  maximumPayerAge: {
    value: 55,
    unit: 'year',
  },
  insuredAgeCoverage: 25,
}

const getYearsOfCoverage = (basicPaymentPeriod: number, insuredAge: Age) => {
  const insuredAgeValue = insuredAge.unit !== 'year' ? 0 : insuredAge.value
  return Math.min(basicPaymentPeriod, riderRules.insuredAgeCoverage - insuredAgeValue)
}

const calculateMaximumPayerAge = (yearsOfPayment: number, riderCoveragePeriod: number): Age => {
  const maxPayerAge = Math.min(riderRules.maximumPayerAge.value, riderCoveragePeriod - yearsOfPayment)
  return { value: maxPayerAge, unit: 'year' }
}

export default {
  calculateRiderPremium,
  queryPremiumRate,
  getYearsOfCoverage,
  calculateMaximumPayerAge,
}
