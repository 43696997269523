// @flow

import type { AppState } from 'quick-quote/reducers'
import { hasProductSelected } from 'quick-quote/selectors/selected-display-product'
import type { CoveragePlanDispatchers } from './components'
import { CoveragePlan } from './components'

import type { Dispatch } from 'redux'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  getAvailableRiderCodes,
  getBasicPremium$,
  getBasicPremiumState,
  getSumAssured$,
  getTotalPremium$,
  getReadOnlyFields,
  haveBasicPremiumState,
} from 'quick-quote/product-common/coverage-plan/selectors'
import { getSelectedDisplayProductCode } from 'quick-quote/product-selection/selectors'
import {
  editBasicPremiumState,
  editRiderSelectedPlan,
  editRiderSumAssured,
  editSumAssured,
  editTotalPremium,
  toggleRider,
} from 'quick-quote/product-common/coverage-plan/actions'
import { getModelFactorsForUser } from 'quick-quote/selectors/model-factors'
import { getBasicPlanErrorMessage, getBasicPlanOptions, getSelectedModelFactorID } from './selectors'
import { selectModelFactor, updateSelectedBasicPlan, validateBasicPlan } from './actions'

const mapStateToProps = (state: AppState) => ({
  modelFactors: getModelFactorsForUser(state),
  selectedModelFactorID: getSelectedModelFactorID(state),
  basicPremiumState: getBasicPremiumState(state),
  haveBasicPremiumState: haveBasicPremiumState(state),
  sumAssured$: getSumAssured$(state),
  basicPremium$: getBasicPremium$(state),
  readOnlyFields: getReadOnlyFields(state),
  riderCodes: getAvailableRiderCodes(state),
  selectedBasicPlanCode: getSelectedDisplayProductCode(state),
  totalPremium$: getTotalPremium$(state),
  hasProductSelected: hasProductSelected(state),
  basicPlanOptions: getBasicPlanOptions(state),
  basicPlanValidationError: getBasicPlanErrorMessage(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>): CoveragePlanDispatchers => ({
  selectModelFactor: bindActionCreators(selectModelFactor, dispatch),
  toggleRider: bindActionCreators(toggleRider, dispatch),
  updateSelectedBasicPlan: bindActionCreators(updateSelectedBasicPlan, dispatch),
  editRiderSumAssured: bindActionCreators(editRiderSumAssured, dispatch),
  editRiderSelectedPlan: bindActionCreators(editRiderSelectedPlan, dispatch),
  editSumAssured: bindActionCreators(editSumAssured, dispatch),
  editBasicPremium: bindActionCreators(editBasicPremiumState, dispatch),
  editTotalPremium: bindActionCreators(editTotalPremium, dispatch),
  validateBasicPlan: bindActionCreators(validateBasicPlan, dispatch),
})

export const CoveragePlanContainer = connect(mapStateToProps, mapDispatchToProps)(CoveragePlan)
