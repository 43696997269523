import PropTypes from 'prop-types'
import syncScroll from '../../../../lib/sync-scroll'
import BIMESSAGES from '../../../../core/data-model/constants/bi-messages'
import Mustache from 'mustache'
import {
  Entry,
  Headers,
  Heading,
  HeadingFixedGroup,
  HeadingGroup,
  HeadingGroupMaster,
  HeadingGroupSub,
  StickyTable,
  Track,
  Tracks,
  EntryFixed,
} from './StickyTable'
import _ from 'lodash'
import { LumpSumInput, hasLumpSumError } from './expected-return-comparison-table'

class IInvestTable extends React.Component {
  static propTypes = {
    formatCell: PropTypes.func,
    expectedReturnComparisons: PropTypes.array.isRequired,
    editLumpSum: PropTypes.func.isRequired,
  }

  static defaultProps = {
    formatCell: (...args) => args[0],
  }

  constructor(props) {
    super(props)

    this.setHeaderRef = this.setHeaderRef.bind(this)
    this.setBodyRef = this.setBodyRef.bind(this)
    this.renderHeader = this.renderHeader.bind(this)
    this.renderBody = this.renderBody.bind(this)
    this.getDisplayMonth = this.getDisplayMonth.bind(this)
    this.getYearLabel = this.getYearLabel.bind(this)
  }

  componentDidMount() {
    syncScroll([this.header, this.body])
  }

  setHeaderRef(element) {
    this.header = element
  }

  setBodyRef(element) {
    this.body = element
  }

  renderHeader() {
    return (
      <Headers targetRef={this.setHeaderRef}>
        <HeadingFixedGroup>
          <Heading>{BIMESSAGES.YEAR_AT}</Heading>
          <Heading>{BIMESSAGES.AGE}</Heading>
        </HeadingFixedGroup>
        <HeadingGroup>
          <HeadingGroupMaster>
            <Heading>{BIMESSAGES.PREMIUM}</Heading>
          </HeadingGroupMaster>
          <HeadingGroupSub>
            <Heading>{BIMESSAGES.PREMIUM}</Heading>
            <Heading>{BIMESSAGES.CASHFLOW_TABLE_PREMIUM_LUMPSUM}</Heading>
            <Heading>{BIMESSAGES.CASHFLOW_TABLE_PREMIUM_TOTAL_PREMIUM}</Heading>
          </HeadingGroupSub>
        </HeadingGroup>
        <HeadingGroup>
          <HeadingGroupMaster>
            <Heading>{BIMESSAGES.CASHFLOW_TABLE_POLICY_CHARGE}</Heading>
          </HeadingGroupMaster>
          <HeadingGroupSub>
            <Heading>{BIMESSAGES.PREMIUM_ALLOCATION_FEE} </Heading>
            <Heading>{BIMESSAGES.CASHFLOW_TABLE_POLICY_CHARGE_LUMPSUM_TOPUP}</Heading>
            <Heading>{BIMESSAGES.CASHFLOW_TABLE_POLICY_CHARGE_COI}</Heading>
            <Heading>{BIMESSAGES.CASHFLOW_TABLE_POLICY_CHARGE_AMC}</Heading>
          </HeadingGroupSub>
        </HeadingGroup>
        <HeadingGroup>
          <HeadingGroupMaster>
            <Heading>{BIMESSAGES.CASHFLOW_TABLE_INVESTMENT}</Heading>
          </HeadingGroupMaster>
          <HeadingGroupSub>
            <Heading>{BIMESSAGES.CASHFLOW_TABLE_INVESTMENT_ACCOUNT_VALUE_ACCOUNT_VALUE_AFTER_DEDUCTION}</Heading>
            <Heading>
              {Mustache.render(BIMESSAGES.CASHFLOW_TABLE_INVESTMENT_FUND_RETURN, {
                expectedReturn: 2,
              })}
            </Heading>
            <Heading>{BIMESSAGES.CASHFLOW_TABLE_INVESTMENT_ACCOUNT_VALUE_BEFORE_BONUS}</Heading>
            <Heading>{BIMESSAGES.CASHFLOW_TABLE_INVESTMENT_BONUS}</Heading>
            <Heading>{BIMESSAGES.CASHFLOW_TABLE_INVESTMENT_ACCOUNT_VALUE_AFTER_BONUS}</Heading>
          </HeadingGroupSub>
        </HeadingGroup>
        <Heading>{BIMESSAGES.SURRENDER_CASH_VALUE}</Heading>
        <Heading>{BIMESSAGES.BASIC_PLAN_DEATH_BENEFIT}</Heading>
        <HeadingGroup>
          <HeadingGroupMaster>
            <Heading>{BIMESSAGES.CASHFLOW_TABLE_INVESTMENT_ACCOUNT_VALUE_AFTER_BONUS}</Heading>
          </HeadingGroupMaster>
          <HeadingGroupSub>
            <Heading>
              {Mustache.render(BIMESSAGES.CASHFLOW_TABLE_INVESTMENT_FUND_RETURN, {
                expectedReturn: -1,
              })}
            </Heading>
            <Heading>
              {Mustache.render(BIMESSAGES.CASHFLOW_TABLE_INVESTMENT_FUND_RETURN, {
                expectedReturn: 2,
              })}
            </Heading>
            <Heading>
              {Mustache.render(BIMESSAGES.CASHFLOW_TABLE_INVESTMENT_FUND_RETURN, {
                expectedReturn: 5,
              })}
            </Heading>
          </HeadingGroupSub>
        </HeadingGroup>
        <Heading>{BIMESSAGES.CASHFLOW_TABLE_PREMIUM_LUMPSUM} (LSTU)</Heading>
      </Headers>
    )
  }

  renderBody(expectedReturnComparisons, editLumpSum) {
    return (
      <Tracks targetRef={this.setBodyRef}>
        {expectedReturnComparisons.map((expectedReturnComparison, index) =>
          this.renderRow(index, expectedReturnComparison, editLumpSum)
        )}
      </Tracks>
    )
  }

  getYearLabel(year, index) {
    return year === 1 ? `${year} เดือน ${this.getDisplayMonth(index)}` : year
  }

  getDisplayMonth(index) {
    switch (index) {
      case 1:
        return 2
      case 2:
        return 12
      default:
        return 1
    }
  }

  renderRow(index, expectedReturnComparison, editLumpSum) {
    const { year, age, lumpSum, benefitByExpectedReturn } = expectedReturnComparison
    const yearLabel = this.getYearLabel(expectedReturnComparison.year, index)
    const premium = _.get(benefitByExpectedReturn, '2.premium')
    const policyCharges = _.get(benefitByExpectedReturn, '2.policyCharges')
    const investment = _.get(benefitByExpectedReturn, '2.investment')
    const surrenderCash = _.get(benefitByExpectedReturn, '2.surrenderCash')
    const deathBenefit = _.get(benefitByExpectedReturn, '2.deathBenefit')
    const rowClassName = hasLumpSumError(lumpSum) ? 'has-danger' : ''

    if (premium) {
      return (
        <Track key={index} className={rowClassName}>
          <EntryFixed>
            <Entry>{yearLabel}</Entry>
            <Entry>{age}</Entry>
          </EntryFixed>
          <Entry>{this.props.formatCell(premium.regularPremium)}</Entry>
          <Entry>{this.props.formatCell(lumpSum.value)}</Entry>
          <Entry>{this.props.formatCell(premium.totalPremium)}</Entry>
          <Entry>{this.props.formatCell(policyCharges.premiumCharge)}</Entry>
          <Entry>{this.props.formatCell(policyCharges.lumpsumCharge)}</Entry>
          <Entry>{this.props.formatCell(policyCharges.chargeOfInsurance)}</Entry>
          <Entry>{this.props.formatCell(policyCharges.adminCharge)}</Entry>
          <Entry>{this.props.formatCell(investment.accountValueAfterDeduction)}</Entry>
          <Entry>{this.props.formatCell(investment.fundReturn, '0')}</Entry>
          <Entry>{this.props.formatCell(investment.accountValueBeforeBonus)}</Entry>
          <Entry>{this.props.formatCell(investment.bonus)}</Entry>
          <Entry>{this.props.formatCell(investment.accountValueAfterBonus)}</Entry>
          <Entry>{this.props.formatCell(surrenderCash)}</Entry>
          <Entry>{this.props.formatCell(deathBenefit)}</Entry>
          <Entry>{this.props.formatCell(benefitByExpectedReturn['-1'].accountValueAfterBonus, '0')}</Entry>
          <Entry>{this.props.formatCell(benefitByExpectedReturn['2'].accountValueAfterBonus, '0')}</Entry>
          <Entry>{this.props.formatCell(benefitByExpectedReturn['5'].accountValueAfterBonus, '0')}</Entry>
          <Entry>
            <LumpSumInput year={year} lumpSum={lumpSum} editLumpSum={editLumpSum} />
          </Entry>
        </Track>
      )
    }

    return (
      <Track key={index} className={rowClassName}>
        <EntryFixed>
          <Entry>{yearLabel}</Entry>
          <Entry>{age}</Entry>
        </EntryFixed>
        <Entry>0</Entry>
        <Entry>0</Entry>
        <Entry>0</Entry>
        <Entry>0</Entry>
        <Entry>0</Entry>
        <Entry>0</Entry>
        <Entry>0</Entry>
        <Entry>0</Entry>
        <Entry>0</Entry>
        <Entry>0</Entry>
        <Entry>0</Entry>
        <Entry>0</Entry>
        <Entry>0</Entry>
        <Entry>0</Entry>
        <Entry>0</Entry>
        <Entry>0</Entry>
        <Entry>0</Entry>
        <Entry>
          <LumpSumInput year={year} lumpSum={lumpSum} editLumpSum={editLumpSum} />
        </Entry>
      </Track>
    )
  }

  render() {
    return (
      <StickyTable className={this.props.className}>
        {this.renderHeader()}
        {this.renderBody(this.props.expectedReturnComparisons, this.props.editLumpSum)}
      </StickyTable>
    )
  }
}

export default IInvestTable
