// @flow

import VALUES from 'core/data-model/constants/values'
import type { FundAllocation } from 'core/data-model/investment'
import type {
  FundAllocationToggleItem,
  FundCode,
  LstuFundAllocationOption,
  TotalFundAllocation,
} from 'core/service/investment'

// ==== action types ====

export type Action =
  | UpdateLstuFundAllocation
  | UpdateLstuTotalFundAllocation
  | ToggleLstuFundAllocation
  | CalculateTotalLstuFundAllocation
  | ResetLstuFundAllocations
  | AllocateLstuSameAsRpp
  | AllocateLstuIndependently
  | LSTUAcceptHigherRiskInvestment
  | LSTUAcceptForeignInvestment
  | UpdateLstuAcceptFundAllocation

export const CALCULATE_LSTU_TOTAL_FUND_ALLOCATION = 'INVESTMENT/CALCULATE_LSTU_TOTAL_FUND_ALLOCATION'
export type CalculateTotalLstuFundAllocation = {
  type: 'INVESTMENT/CALCULATE_LSTU_TOTAL_FUND_ALLOCATION',
  payload: FundAllocation,
}

export const calculateLstuTotalLstuFundAllocation = (
  code: FundCode,
  fundAllocation: number
): CalculateTotalLstuFundAllocation => {
  return {
    type: CALCULATE_LSTU_TOTAL_FUND_ALLOCATION,
    payload: {
      code,
      fundAllocation,
    },
  }
}

export const UPDATE_LSTU_FUND_ALLOCATIONS = 'INVESTMENT/UPDATE_LSTU_FUND_ALLOCATIONS'
export type UpdateLstuFundAllocation = {
  type: 'INVESTMENT/UPDATE_LSTU_FUND_ALLOCATIONS',
  payload: FundAllocationToggleItem[],
}

export const updateLstuFundAllocation = (lstuFunds: FundAllocationToggleItem[]): UpdateLstuFundAllocation => {
  return {
    type: UPDATE_LSTU_FUND_ALLOCATIONS,
    payload: lstuFunds,
  }
}

export const UPDATE_LSTU_TOTAL_FUND_ALLOCATION = 'INVESTMENT/UPDATE_LSTU_TOTAL_FUND_ALLOCATION'
export type UpdateLstuTotalFundAllocation = {
  type: 'INVESTMENT/UPDATE_LSTU_TOTAL_FUND_ALLOCATION',
  payload: TotalFundAllocation,
}

export const updateLstuTotalFundAllocation = (
  totalFundAllocation: TotalFundAllocation
): UpdateLstuTotalFundAllocation => {
  return {
    type: UPDATE_LSTU_TOTAL_FUND_ALLOCATION,
    payload: totalFundAllocation,
  }
}

export const TOGGLE_LSTU_FUND_ALLOCATION = 'INVESTMENT/TOGGLE_LSTU_FUND_ALLOCATION'
export type LstuFundCode = {
  code: FundCode,
}
export type ToggleLstuFundAllocation = {
  type: 'INVESTMENT/TOGGLE_LSTU_FUND_ALLOCATION',
  payload: LstuFundCode,
}

export const toggleLstuFundAllocation = (fund: LstuFundCode): ToggleLstuFundAllocation => {
  return {
    type: TOGGLE_LSTU_FUND_ALLOCATION,
    payload: fund,
  }
}

export const ALLOCATE_LSTU_SAME_AS_RPP = 'INVESTMENT/ALLOCATE_LSTU_SAME_AS_RPP'
export type AllocateLstuSameAsRpp = {
  type: 'INVESTMENT/ALLOCATE_LSTU_SAME_AS_RPP',
  payload: LstuFundAllocationOption,
}

export const allocateLstuSameAsRpp = (): AllocateLstuSameAsRpp => {
  return {
    type: ALLOCATE_LSTU_SAME_AS_RPP,
    payload: VALUES.LSTU_FUND_ALLOCATION_OPTION.SAME_AS_RPP,
  }
}

export const ALLOCATE_LSTU_INDEPENDENTLY = 'INVESTMENT/ALLOCATE_LSTU_INDEPENDENTLY'
export type AllocateLstuIndependently = {
  type: 'INVESTMENT/ALLOCATE_LSTU_INDEPENDENTLY',
  payload: LstuFundAllocationOption,
}

export const allocateLstuIndependently = (): AllocateLstuIndependently => {
  return {
    type: ALLOCATE_LSTU_INDEPENDENTLY,
    payload: VALUES.LSTU_FUND_ALLOCATION_OPTION.INDEPENDENT,
  }
}

export const RESET_LSTU_FUND_ALLOCATION = 'INVESTMENT/RESET_LSTU_FUND_ALLOCATION'
export type ResetLstuFundAllocations = {
  type: 'INVESTMENT/RESET_LSTU_FUND_ALLOCATION',
}
export const resetLstuFundAllocations = (): ResetLstuFundAllocations => {
  return {
    type: RESET_LSTU_FUND_ALLOCATION,
  }
}

export const LSTU_ACCEPT_HIGHER_RISK_INVESTMENT = 'LSTU_ACCEPT_HIGHER_RISK_INVESTMENT'

export type LSTUAcceptHigherRiskInvestment = {
  type: 'LSTU_ACCEPT_HIGHER_RISK_INVESTMENT',
}

export const lstuAcceptHigherRiskInvestment = (): LSTUAcceptHigherRiskInvestment => ({
  type: LSTU_ACCEPT_HIGHER_RISK_INVESTMENT,
})

export const ANSWER_QUESTION_TO_ACCEPT_LSTU_FOREIGN_INVESTMENT = 'ANSWER_QUESTION_TO_ACCEPT_LSTU_FOREIGN_INVESTMENT'

export const LSTU_ACCEPT_FOREIGN_INVESTMENT = 'LSTU_ACCEPT_FOREIGN_INVESTMENT'

export type LSTUAcceptForeignInvestment = {
  type: 'LSTU_ACCEPT_FOREIGN_INVESTMENT',
}

export const lstuAcceptForeignInvestment = (): LSTUAcceptForeignInvestment => ({
  type: LSTU_ACCEPT_FOREIGN_INVESTMENT,
})

export type AnswerQuestionToAcceptLstuForeignInvestment = {
  type: 'ANSWER_QUESTION_TO_ACCEPT_LSTU_FOREIGN_INVESTMENT',
  payload: boolean,
}

export const answerQuestionToAcceptLstuForeignInvestment = (
  isAccept: boolean
): AnswerQuestionToAcceptLstuForeignInvestment => ({
  type: ANSWER_QUESTION_TO_ACCEPT_LSTU_FOREIGN_INVESTMENT,
  payload: isAccept,
})

export const UPDATE_LSTU_ACCEPT_FUND_ALLOCATION = 'INVESTMENT/UPDATE_LSTU_ACCEPT_FUND_ALLOCATION'
export type UpdateLstuAcceptFundAllocation = {
  type: 'INVESTMENT/UPDATE_LSTU_ACCEPT_FUND_ALLOCATION',
  payload: boolean,
}

export const RESET_LSTU_FUND_CONSENTS = 'INVESTMENT/RESET_LSTU_FUND_CONSENTS'

export type ResetLstuFundConsents = {
  type: 'INVESTMENT/RESET_LSTU_FUND_CONSENTS',
}

export const resetLstuFundConsents = (): ResetLstuFundConsents => ({
  type: RESET_LSTU_FUND_CONSENTS,
})

export const RESET_LSTU_ACCEPT_FUND_ALLOCATION = 'INVESTMENT/RESET_LSTU_ACCEPT_FUND_ALLOCATION'

export type ResetLstuAcceptFundAllocation = {
  type: 'INVESTMENT/RESET_LSTU_ACCEPT_FUND_ALLOCATION',
}

export const resetLstuAcceptFundAllocation = (): ResetLstuAcceptFundAllocation => {
  return {
    type: RESET_LSTU_ACCEPT_FUND_ALLOCATION,
  }
}

export const updateLstuAcceptFundAllocation = (acceptFundAllocation: boolean): UpdateLstuAcceptFundAllocation => {
  return {
    type: UPDATE_LSTU_ACCEPT_FUND_ALLOCATION,
    payload: acceptFundAllocation,
  }
}

export const acceptFundAllocation = (): UpdateLstuAcceptFundAllocation => {
  return {
    type: UPDATE_LSTU_ACCEPT_FUND_ALLOCATION,
    payload: true,
  }
}
