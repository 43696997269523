import DateFormatter from '../lib/date-formatter'
import PropTypes from 'prop-types'
import { dateFormatterPattern } from 'core/service/lib/date'

export class Date extends React.Component<*, *> {
  static propTypes = {
    className: PropTypes.string,
    pattern: PropTypes.array,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    delimiter: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
  }

  static defaultProps = {
    className: '',
    value: '',
    type: '',
    pattern: dateFormatterPattern,
    placeholder: '',
    delimiter: '/',
    onChange: () => {},
    onFocus: () => {},
    onBlur: () => {},
  }

  componentDidMount() {
    this.setState({
      value: this.props.value,
    })
  }

  constructor(props) {
    super(props)

    this.dateFormatter = new DateFormatter(props.pattern)
    this.blocks = this.dateFormatter.getBlocks()
    this.blocksLength = this.blocks.length
    this.maxLength = this.getMaxLength(this.blocks)
    this.delimiter = props.delimiter
    this.delimiterLength = this.delimiter.length
    this.backspace = false
    this.result = props.value
    this.state = {
      value: '',
    }
  }

  headStr = (str, length) => {
    return str.slice(0, length)
  }

  handleOnChange = (e) => {
    let value = e.target.value
    if (this.backspace && !this.isDelimiter(e.target.value.slice(-this.delimiterLength), this.delimiter)) {
      value = this.headStr(value, value.length - this.delimiterLength)
    }

    value = this.dateFormatter.getValidatedDate(value)
    this.result = this.getFormattedValue(value, this.blocks, this.blocksLength, this.delimiter)

    this.setState({ value: this.result })
    e.target.value = this.result
    this.props.onChange(e)
  }

  handleOnBlur = (e) => {
    this.props.onBlur(e)
  }

  handleOnFocus = (e) => {
    this.props.onFocus(e)
  }

  handleOnKeyDown = (e) => {
    const charCode = e.which || e.keyCode
    this.backspace = !!(charCode === 8 && this.isDelimiter(this.result.slice(-this.delimiterLength), this.delimiter))
  }

  isDelimiter = (letter, delimiter) => {
    return letter === delimiter
  }

  getMaxLength = (blocks) => {
    return blocks.reduce(function(previous, current) {
      return previous + current
    }, 0)
  }

  getFormattedValue = (value, blocks, blocksLength, delimiter) => {
    var result = '',
      currentDelimiter

    // no options, normal input
    if (blocksLength === 0) {
      return value
    }

    blocks.forEach(function(length, index) {
      if (value.length > 0) {
        const sub = value.slice(0, length),
          rest = value.slice(length)

        currentDelimiter = delimiter

        result += sub

        if (sub.length === length && index < blocksLength - 1) {
          result += currentDelimiter
        }

        // update remaining string
        value = rest
      }
    })

    return result
  }

  render() {
    const { type, className, pattern, placeholder } = this.props
    return (
      <input
        type={type}
        value={this.state.value}
        className={className}
        pattern={pattern}
        placeholder={placeholder}
        onChange={this.handleOnChange}
        onKeyDown={this.handleOnKeyDown}
        onBlur={this.handleOnBlur}
        onFocus={this.handleOnFocus}
      />
    )
  }
}

export default Date
