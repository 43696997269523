//@flow
import styled from 'styled-components'
import WarningModal from '../../../components/warning-modal'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import { Link } from 'react-router-dom'
import { blockSelectedFund } from 'quick-quote/product-investment/journey-control-selectors'
import { resetAnsweredQuestion, resetRiskEvaluated } from '../../risk-questionnaire/actions'

type ValidateRPQScoreProps = {
  buttonName: String,
  linkButton: String,
  linkModalBtn: String,
  resetAnsweredQuestion: Function,
  resetRiskEvaluated: Function,
}

export class _ValidateRPQScore extends React.Component<*, *> {
  constructor(props: ValidateRPQScoreProps) {
    super(props)

    this.state = {
      isShowWarningRisk: false,
    }
  }

  onResetRPQ = () => {
    this.props.resetAnsweredQuestion()
    this.props.resetRiskEvaluated()
  }

  toggleWarningRisk = () => {
    this.setState({
      isShowWarningRisk: !this.state.isShowWarningRisk,
    })
  }

  render() {
    return this.props.isShowModal ? (
      <div>
        <Button color="primary" onClick={this.toggleWarningRisk}>
          {this.props.buttonName}
        </Button>

        <WarningModal
          toggle={this.toggleWarningRisk}
          isOpen={this.state.isShowWarningRisk}
          confirmBtn={this.onResetRPQ}
          linkBtn={this.props.linkModalBtn}
          message={{
            title: 'คำเตือน',
            body:
              'ผลการประเมินระดับความเสี่ยงในการลงทุนของท่าน เป็นนักลงทุนประเภทเสี่ยงปานกลางค่อนข้างต่ำ ซึ่งกรมธรรม์ยูนิตลิงค์ซึ่งเป็นผลิตภัณฑ์ที่มีความซับซ้อนและมีความเสี่ยงจากการลงทุน ทางธนาคารพิจารณาว่ากรมธรรม์ยูนิตลิงค์ ไม่เหมาะสมหรือสอดคล้องกับระดับความเสี่ยงที่ท่านยอมรับได้ กรุณาเลือกแบบกรมธรรม์ประกันชีวิตอื่นที่เหมาะสมกับท่าน',
          }}
        />
      </div>
    ) : (
      <div>
        <Link to={this.props.linkButton}>
          <Button color="primary">{this.props.buttonName}</Button>
        </Link>
      </div>
    )
  }
}

const StyledValidateRPQScore = styled(_ValidateRPQScore)`
  max-width: 680px;
  margin: 0 auto;
  padding: 20px;
`

const mapStateToProps = (state) => ({
  isShowModal: blockSelectedFund(state),
})

const ValidateRPQScore = connect(mapStateToProps, {
  resetAnsweredQuestion,
  resetRiskEvaluated,
})(StyledValidateRPQScore)

export default ValidateRPQScore
