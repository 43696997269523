// @flow
import type { InvestmentBenefitTable } from 'core/service/benefit-illustration/investment-benefit'

export const UPDATE_INVESTMENT_BENEFIT = 'UPDATE_INVESTMENT_BENEFIT'
export type UpdateInvestmentBenefit = {
  type: 'UPDATE_INVESTMENT_BENEFIT',
  payload: InvestmentBenefitTable,
}
export const updateInvestmentBenefit = (investmentBenefit: InvestmentBenefitTable): UpdateInvestmentBenefit => ({
  type: UPDATE_INVESTMENT_BENEFIT,
  payload: investmentBenefit,
})
