// @flow

import constants from 'core/data-model/constants/defaults'
import messages from 'core/data-model/constants/messages'
import MESSAGES from 'core/data-model/constants/validation-messages'
import type { Age, Gender, Insured, NatureOfDuty, Occupation, Payer } from 'core/data-model/insured'
import { isPreAdult, isUnderage } from 'core/service/insured/age'
import { isValidAge } from 'core/service/insured/birthdate'
import _ from 'lodash'
import { get } from 'lodash/fp'
import { getToggles } from 'quick-quote/feature-toggles'
import { isInvestmentCategory } from 'quick-quote/product-selection/selectors'
import { isQQFlow } from 'quick-quote/portal/selectors'
import { isAllowReviseQQMenu } from 'identity/selectors'
import type { AppState } from 'quick-quote/reducers'
import { createSelector } from 'reselect'

export const getOccupations = (state: AppState): Occupation[] => state.occupations.payload
export const getOccupationsStatus = (state: AppState): string => state.occupations.status
export const getGenderOptions = () => [
  {
    text: messages.MALE,
    value: constants.MALE,
  },
  {
    text: messages.FEMALE,
    value: constants.FEMALE,
  },
]

export const getPayerRelation = (state: AppState): string => state.insured.payer.relation
export const getPayerRelationOptions = (state: AppState) => {
  const checkCanSelectablePayerRelation = (state, value) => {
    if (!!state.selectedDisplayProduct.payerSelection) {
      const payerSelection = get('selectedDisplayProduct.payerSelection')(state)
      const insureAge = getAge(state)
      const isInsuredValidAge = isValidAge(
        insureAge.value,
        insureAge.unit,
        constants.DEFAULT_PAYER_RELATION_ENTRY_AGE[value]
      )
      return payerSelection.includes(value) && isInsuredValidAge
    } else {
      return false
    }
  }

  return [
    {
      text: messages.PAY_MY_SELF,
      value: constants.PAY_MY_SELF,
      canSelectable: checkCanSelectablePayerRelation(state, constants.PAY_MY_SELF),
    },
    {
      text: messages.PARENT,
      value: constants.PARENT,
      canSelectable: checkCanSelectablePayerRelation(state, constants.PARENT),
    },
    {
      text: messages.SPOUSE,
      value: constants.SPOUSE,
      canSelectable: checkCanSelectablePayerRelation(state, constants.SPOUSE),
    },
  ]
}

export const isShowPayerRelationSelection = (state: AppState): boolean =>
  getToggles().ENABLE_NEW_PAYER_SELECTION &&
  !!state.selectedDisplayProduct.payerSelection &&
  !_.isEmpty(get('selectedDisplayProduct.payerSelection')(state))

export const isNeedPayerComponent = (state: AppState): boolean => {
  if (isShowPayerRelationSelection(state)) return true
  else return state.insured.payerNeeded
}

export const getGender = (state: AppState): Gender => state.insured.gender
export const getBirthdate = (state: AppState): string => get('insured.birthdate.value')(state)
export const getIsValidBirthdate = (state: AppState): boolean => _.isEmpty(get('insured.birthdate.errors')(state))
export const getAge = (state: AppState): Age => state.insured.age
export const getErrorBirthdateMessage = (state: AppState) => state.insured.birthdate.errors.join('\n')
export const getBirthdateErrors = (state: AppState) => state.insured.birthdate.errors
export const getSelectedOccupationCode = (state: AppState): string => state.insured.occupation.code
export const getSelectedNatureOfDutyCode = (state: AppState): string => state.insured.occupation.natureOfDuty.code
export const getInsuredOccupationErrors = (state: AppState) => state.insured.occupation.errors
export const getInsuredOccupationClass = (state: AppState) => state.insured.occupation.natureOfDuty.occupationClass

export const getInsuredFirstName = (state: AppState): string => state.insured.firstName.name
export const getInsuredLastName = (state: AppState): string => state.insured.lastName.name
export const getInsuredCitizenId = (state: AppState): string => state.insured.idNo.citizenId
export const getInsuredPassport = (state: AppState): string => state.insured.foreigner.passport
export const getInsuredIdType = (state: AppState): string => state.insured.idType.value
export const getQuickQuoteInsured = (state: AppState) => {
  return state?.insured
}

export const getPayerNeeded = (state: AppState): boolean => state.insured.payerNeeded
export const getSelectedPayerOccupationCode = (state: AppState): string => state.insured.payer.occupation.code
export const getSelectedPayerNatureOfDutyCode = (state: AppState): string =>
  get('insured.payer.occupation.natureOfDuty.code')(state)
export const getPayerOccupationErrors = (state: AppState) => get('insured.payer.occupation.errors')(state)
export const getPayerBirthdate = (state: AppState): string => state.insured.payer.birthdate.value
export const getPayerIsValidBirthdate = (state: AppState): boolean => _.isEmpty(state.insured.payer.birthdate.errors)
export const getPayerAge = (state: AppState): Age => state.insured.payer.age
export const getPayerGender = (state: AppState): Gender => state.insured.payer.gender
export const getErrorPayerBirthdateMessage = (state: AppState) => state.insured.payer.birthdate.errors.join('\n')
export const getPayerBirthdateErrors = (state: AppState) => state.insured.payer.birthdate.errors

const findOccupation = (
  occupations: Occupation[],
  selectedOccupationCode: string,
  selectedNatureOfDutyCode: string
): ?Occupation => {
  return _.find(occupations, {
    occupationCode: selectedOccupationCode,
    natureOfDutyCode: selectedNatureOfDutyCode,
  })
}

export const getSelectedOccupation = createSelector(
  getOccupations,
  getSelectedOccupationCode,
  getSelectedNatureOfDutyCode,
  findOccupation
)

export const getSelectedPayerOccupation = createSelector(
  getOccupations,
  getSelectedPayerOccupationCode,
  getSelectedPayerNatureOfDutyCode,
  findOccupation
)

export const getSelectedPayerOtherNatureOfDutyCode = (state: AppState): string =>
  _.get(state, 'insured.payer.otherOccupation.natureOfDuty.code')
export const getSelectedPayerOtherOccupationCode = (state: AppState): string =>
  _.get(state, 'insured.payer.otherOccupation.code')
export const getIsSelectedPayerOtherOccupation = (state: AppState): boolean =>
  _.get(state, 'insured.payer.otherOccupation.isSelected', false)
export const getPayerOtherOccupationErrors = (state: AppState) => state.insured.payer.otherOccupation.errors
export const getSelectedPayerOtherOccupation = createSelector(
  getOccupations,
  getSelectedPayerOtherOccupationCode,
  getSelectedPayerOtherNatureOfDutyCode,
  findOccupation
)

export const getIsSelectedOtherOccupation = (state: AppState): boolean => state.insured.otherOccupation.isSelected
export const getInsuredOtherOccupationErrors = (state: AppState) => state.insured.otherOccupation.errors
export const getSelectedOtherOccupationCode = (state: AppState): string => state.insured.otherOccupation.code
export const getSelectedOtherNatureOfDutyCode = (state: AppState): string =>
  state.insured.otherOccupation.natureOfDuty.code
export const getSelectedOtherOccupation = createSelector(
  getOccupations,
  getSelectedOtherOccupationCode,
  getSelectedOtherNatureOfDutyCode,
  findOccupation
)

export const getAllNatureOfDutyCodes = createSelector(
  getSelectedNatureOfDutyCode,
  getSelectedOtherNatureOfDutyCode,
  getIsSelectedOtherOccupation,
  (natureOfDuty, otherNatureOfDuty, isSelectedOtherOccupation): string[] => {
    return [natureOfDuty]
  }
)

export const getAllPayerNatureOfDutyCodes = createSelector(
  getSelectedPayerNatureOfDutyCode,
  getSelectedPayerOtherNatureOfDutyCode,
  getIsSelectedPayerOtherOccupation,
  (payerNatureOfDuty, payerOtherNatureOfDuty, isSelectedPayerOtherOccupation): string[] => {
    const natureOfDuty = isSelectedPayerOtherOccupation
      ? [payerNatureOfDuty, payerOtherNatureOfDuty]
      : [payerNatureOfDuty]
    return natureOfDuty
  }
)

export const getIsInsuredOccupationNotEmpty = createSelector(
  getSelectedOccupation,
  getIsSelectedOtherOccupation,
  getSelectedOtherOccupation,
  (insuredOccupation, isInsuredHasOtherOccupation, insureOtherOccupation): boolean => {
    const isValidInsuredOccupation = !_.isUndefined(insuredOccupation)
    const isValidInsuredOtherOccupation = isInsuredHasOtherOccupation ? !_.isUndefined(insureOtherOccupation) : true

    return isValidInsuredOccupation && isValidInsuredOtherOccupation
  }
)
export const getIsOccupationsNotEmpty = createSelector(
  getIsInsuredOccupationNotEmpty,
  getPayerNeeded,
  getSelectedPayerOccupation,
  getIsSelectedPayerOtherOccupation,
  getSelectedPayerOtherOccupation,
  isInvestmentCategory,
  (
    isInsuredOccupationNotEmpty,
    needPayer,
    payerOccupation,
    isPayerHasOtherOccupation,
    payerOtheOccupation,
    isInvestmentCategory
  ): boolean => {
    const isValidPayerOccupation = needPayer && !isInvestmentCategory ? !_.isUndefined(payerOccupation) : true
    const isValidPayerOtherOccupation =
      needPayer && isPayerHasOtherOccupation && !isInvestmentCategory ? !_.isUndefined(payerOtheOccupation) : true

    return isInsuredOccupationNotEmpty && isValidPayerOccupation && isValidPayerOtherOccupation
  }
)

// $FlowFixMe
export const getInsured = (state: AppState): Insured => ({
  occupation: getSelectedOccupation(state),
  otherOccupation: getSelectedOtherOccupation(state),
  gender: getGender(state),
  age: getAge(state),
})

// $FlowFixMe
export const getPayer = (state: AppState): Payer => ({
  occupation: getSelectedPayerOccupation(state),
  gender: getPayerGender(state),
  age: getPayerAge(state),
  birthdate: getPayerBirthdate(state),
})

export const getGenderLabel = createSelector(getGenderOptions, getGender, (genderOptions, gender): string =>
  _.get(_.find(genderOptions, { value: gender }), 'text')
)

const filterNatureOfDuties = (occupations: Occupation[], selectedOccupationCode: string): NatureOfDuty[] => {
  const natureOfDuties = occupations
    .filter((occupation) => occupation.occupationCode === selectedOccupationCode)
    .sort((a, b) => a.th.sortOrder - b.th.sortOrder)
    .map((occupation) => ({
      code: occupation.natureOfDutyCode,
      text: occupation.th.natureOfDuty,
      occupationClass: occupation.occupationClass,
    }))
  return natureOfDuties
}

const filterUnderage = (age: Age, natureOfDuties: NatureOfDuty[]) => {
  if (isUnderage(age)) {
    if (isPreAdult(age)) {
      return natureOfDuties.filter((occupation) => occupation.code !== constants.DEFAULT_UNDERAGE_NATURE_OF_DUTY_CODE)
    } else {
      return _.filter(
        natureOfDuties,
        (occupation) => !_.includes(constants.IGNORE_NATURE_OF_DUTY_CODE_BETWEEN_AGE_18_19, occupation.code)
      )
    }
  } else {
    return natureOfDuties
  }
}

export const getDefaultOccupationCode = createSelector(getAge, (age: Age): string => {
  return isUnderage(age) ? constants.DEFAULT_SELECT_OCCUPATION_CODE_KIDS : constants.DEFAULT_SELECT_OCCUPATION_CODE
})

export const getDefaultPayerOccupationCode = (state: AppState): string => constants.DEFAULT_SELECT_OCCUPATION_CODE

export const getPayerOtherNatureOfDuties = createSelector(
  getOccupations,
  getSelectedPayerOtherOccupationCode,
  getDefaultPayerOccupationCode,
  (occupations: Occupation[], selectedPayerOtherOccupationCode: string, defaultOccupationCode) => {
    const payerOtherOccupationCode =
      _.isEmpty(selectedPayerOtherOccupationCode) && getToggles().ENABLE_OCCUPATION_BLANK
        ? defaultOccupationCode
        : selectedPayerOtherOccupationCode
    return filterNatureOfDuties(occupations, payerOtherOccupationCode)
  }
)

export const getOtherNatureOfDuties = createSelector(
  getOccupations,
  getSelectedOtherOccupationCode,
  getDefaultOccupationCode,
  (occupations: Occupation[], selectedOtherOccupationCode: string, defaultOccupationCode) => {
    const payerOccupationCode =
      _.isEmpty(selectedOtherOccupationCode) && getToggles().ENABLE_OCCUPATION_BLANK
        ? defaultOccupationCode
        : selectedOtherOccupationCode
    return filterNatureOfDuties(occupations, payerOccupationCode)
  }
)

export const getPayerNatureOfDuties = createSelector(
  getOccupations,
  getSelectedPayerOccupationCode,
  getDefaultPayerOccupationCode,
  (occupations: Occupation[], selectedPayerOccupationCode: string, defaultOccupationCode) => {
    const payerOccupationCode =
      _.isEmpty(selectedPayerOccupationCode) && getToggles().ENABLE_OCCUPATION_BLANK
        ? defaultOccupationCode
        : selectedPayerOccupationCode
    return filterNatureOfDuties(occupations, payerOccupationCode)
  }
)

export const getDefaultPayerNatureOfDuty = createSelector(getPayerNatureOfDuties, (natureOfDuties) => {
  const [defaultNatureOfDuty] = natureOfDuties
  return defaultNatureOfDuty
})

export const getDefaultNatureOfDuries = createSelector(
  getOccupations,
  getDefaultOccupationCode,
  getAge,
  (occupations: Occupation[], occupationCode: string, age: Age): NatureOfDuty[] => {
    const natureOfDuties = filterNatureOfDuties(occupations, occupationCode)
    return filterUnderage(age, natureOfDuties)
  }
)

export const getNatureOfDuties = createSelector(
  getOccupations,
  getSelectedOccupationCode,
  getAge,
  getDefaultOccupationCode,
  (
    occupations: Occupation[],
    selectedOccupationCode: string,
    age: Age,
    defaultOccupationCode: string
  ): NatureOfDuty[] => {
    const occupationCode =
      _.isEmpty(selectedOccupationCode) && getToggles().ENABLE_OCCUPATION_BLANK
        ? defaultOccupationCode
        : selectedOccupationCode
    const filtered = filterNatureOfDuties(occupations, occupationCode)
    return filterUnderage(age, filtered)
  }
)

export const getUniqueOccupations = createSelector(getOccupations, (occupations: Occupation[]): Occupation[] => {
  const uniqueOccupations = _(occupations)
    .sortBy('th.sortOrder')
    .map(({ th, en, occupationCode }) => ({
      th: { occupationName: th.occupationName, sortOrder: th.sortOrder },
      en: { occupationName: en.occupationName, sortOrder: en.sortOrder },
      occupationCode,
    }))
    .uniqBy('th.occupationName')
    .value()

  return uniqueOccupations
})
export const getTitleErrors = (state: AppState) =>
  !getToggles().ENABLE_QQ_INSURED_TITLE ||
  (getToggles().ENABLE_QQ_INSURED_TITLE && isAllowReviseQQMenu(state) && isQQFlow(state))
    ? []
    : _.get(state, 'insured.title.errors', [])
export const getFirstnameErrors = (state: AppState) => state.insured.firstName.errors
export const getLastnameErrors = (state: AppState) => state.insured.lastName.errors
export const isOccupationRisk = (state: AppState): boolean => {
  const haveErrors = state.insured.occupation.errors.length > 0
  return haveErrors
    ? [
        MESSAGES.RULE_INSURED_PRODUCT_OCCUPATION_FACTOR,
        MESSAGES.RULE_INSURED_OCCUPATION_FACTOR,
        MESSAGES.RULE_OCCUPATION_FACTOR,
      ].includes(state.insured.occupation.errors[0])
    : false
}

export const _hasFinishedInsuredInformation = (
  isPayerNeeded: boolean,
  birthdateErrors: string[],
  payerBirthdateErrors: string[],
  titleErrors: string[],
  firstnameErrors: string[],
  lastnameErrors: string[],
  isInsuredOccupationNotEmpty: boolean,
  _isOccupationRisk: boolean
): boolean => {
  const errors = isPayerNeeded
    ? [...birthdateErrors, ...payerBirthdateErrors, ...firstnameErrors, ...lastnameErrors]
    : [...birthdateErrors, ...titleErrors, ...firstnameErrors, ...lastnameErrors]

  return errors.length === 0 && isInsuredOccupationNotEmpty && !_isOccupationRisk
}

export const hasFinishedInsuredInformation = createSelector(
  getPayerNeeded,
  getBirthdateErrors,
  getPayerBirthdateErrors,
  getTitleErrors,
  getFirstnameErrors,
  getLastnameErrors,
  getIsInsuredOccupationNotEmpty,
  isOccupationRisk,
  _hasFinishedInsuredInformation
)

export const hasInputAllInsuredInformationWithoutErrors = createSelector(
  hasFinishedInsuredInformation,
  getBirthdate,
  getIsValidBirthdate,
  getPayerNeeded,
  getPayerBirthdate,
  getPayerIsValidBirthdate,
  (isFinishedInsuredInformation, birthdate, isValidBirthdate, needPayer, payerBirthdaye, isValidPayerBirthdate) => {
    const validInsuredBirthdate = birthdate && isValidBirthdate
    const validPayerBirthdate = needPayer ? payerBirthdaye && isValidPayerBirthdate : true
    return isFinishedInsuredInformation && validInsuredBirthdate && validPayerBirthdate
  }
)
