// @flow
import VALUES from 'core/data-model/constants/values'
import MESSAGES from 'core/data-model/constants/messages'
import BIMESSAGES from 'core/data-model/constants/bi-messages'
import SummaryTable from './summary-table'
import { renderBIAge } from 'core/service/insured/age'
import { formatNumber } from 'core/service/lib/number-format'

import type { Age } from 'core/data-model/insured'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { DisplayProduct } from 'core/service/display-product'

export type ProfileSnapshotProps = {
  displayProduct: ?DisplayProduct,
  riders: (Rider & RiderState)[],
  genderLabel: string,
  age: Age,
  calculatedCoveragePeriod: number,
  loanAmount: number,
  loanPeriod: number,
  interestRate: number,
  premiumPerMonthGLTSP: number,
  sumAssured: number,
  paymentMethod: string,
  basicPremium: number,
  totalPremium: number,
  totalRiderPremium: number,
  sumAssuredContract: number,
}

const ProfileSnapshotGLTSP = ({
  displayProduct,
  riders,
  genderLabel,
  age,
  calculatedCoveragePeriod,
  loanAmount,
  loanPeriod,
  interestRate,
  premiumPerMonthGLTSP,
  sumAssured,
  paymentMethod,
  basicPremium,
  totalPremium,
  totalRiderPremium,
  sumAssuredContract,
  isReviseQQ,
}: ProfileSnapshotProps) => {
  return (
    <div id="profile-summary" className="snapshot group">
      <h2>
        <span>{displayProduct.nameThai}</span>
        <br />
        <span>
          ({displayProduct.basicPlanCode}) ({displayProduct.code})
        </span>
      </h2>
      <div className="profile">
        <div className="info gender">
          <p className="label">{MESSAGES.GENDER}</p>
          <p className="value">{genderLabel}</p>
        </div>
        <div className="info age">
          <p className="label">{MESSAGES.AGE}</p>
          <p className="value">{renderBIAge(age, isReviseQQ)}</p>
        </div>
        <div className="info coverage-period">
          <p className="label">{BIMESSAGES.LOAN_PERIOD_INSURED}</p>
          <p className="value">
            {calculatedCoveragePeriod} {MESSAGES.TIME_UNIT_YEAR}
          </p>
        </div>
        <div className="info payment-period">
          <p className="label">{BIMESSAGES.COVERAGE_PERIOD_INSURED}</p>
          <p className="value">
            {calculatedCoveragePeriod} {MESSAGES.TIME_UNIT_YEAR}
          </p>
        </div>
      </div>
      <div className="coverage">
        <div className="info sum-assured" style={{ maxWidth: '50%' }}>
          <p className="label">{BIMESSAGES.COVERAGE_BASIC_PLAN}</p>
          <p className="value">{BIMESSAGES.COVERAGE_BASIC_PLAN_MESSAGE}</p>
        </div>
        <div className="info sum-assured" style={{ maxWidth: '50%' }}>
          <p className="label">{BIMESSAGES.AMOUNT_INSURED_OF_BASIC_PLAN}</p>
          <p className="value">
            {formatNumber(sumAssuredContract, 0, true)} {MESSAGES.BAHT}
          </p>
        </div>
      </div>

      <div className="coverage" style={{ borderBottom: '0px' }}>
        <div className="info model-factor" style={{ maxWidth: '50%' }}>
          <p className="label">{BIMESSAGES.AMOUNT_LOAN}</p>
          <p className="value">
            {formatNumber(loanAmount)} {MESSAGES.BAHT}
          </p>
        </div>
        <div className="info model-factor" style={{ maxWidth: '50%' }}>
          <p className="label">{BIMESSAGES.COVERAGE_LOAN_PERIOD_BANK}</p>
          <p className="value">
            {loanPeriod} {MESSAGES.TIME_UNIT_YEAR}
          </p>
        </div>
      </div>

      {paymentMethod === VALUES.CASH_INCLUDE_LOAN && (
        <div className="coverage" style={{ margin: 0, borderBottom: '0px' }}>
          <div className="info basic-premium" style={{ maxWidth: '50%' }}>
            <p className="label">{BIMESSAGES.INTEREST_RATE_BORROW_PREMIUM}</p>
            <p className="value">{interestRate} %</p>
          </div>
          <div className="info rider-premium" style={{ maxWidth: '50%' }}>
            <p className="label">{BIMESSAGES.PREMIUM_MONTHLY}</p>
            <p className="value">
              {formatNumber(premiumPerMonthGLTSP, 2)} {MESSAGES.BAHT}
            </p>
          </div>
        </div>
      )}
      <SummaryTable
        displayProduct={displayProduct}
        riders={riders}
        basicPremium={basicPremium}
        basicSumAssured={sumAssured}
        paymentMethod={paymentMethod}
        totalPremium={totalPremium}
        totalRiderPremium={totalRiderPremium}
        sumAssuredContract={sumAssuredContract}
      />
      <br />
      <div className="disclaimer">
        <div>{BIMESSAGES.NOTE}</div>
        <ol className="counter">
          <li> {BIMESSAGES.REMARK_OVERALL_SUMMARY_MRTA[0]}</li>
          <li> {BIMESSAGES.REMARK_OVERALL_SUMMARY_MRTA[1]}</li>
        </ol>
      </div>
    </div>
  )
}

export default ProfileSnapshotGLTSP
