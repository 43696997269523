// @flow
export const SELECT_HEALTH_TOPPING_PLAN = 'SELECT_HEALTH_TOPPING_PLAN'

export type SelectHealthToppingPlan = {
  type: 'SELECT_HEALTH_TOPPING_PLAN',
  payload: string,
}

export const selectHealthToppingPlan = (planCode: string): SelectHealthToppingPlan => ({
  type: SELECT_HEALTH_TOPPING_PLAN,
  payload: planCode,
})
