// @flow
import _ from 'lodash'
import { ButtonGroup, Button } from 'reactstrap'

type Props = {
  onChange: (string, number) => void,
  keyToGetValue: string,
  value: string,
  options?: Object[],
  className: ?string,
  classNamePrefix: string,
  autoClassName: boolean,
}
type State = { selectedId: string }
class RadioButtonGroup extends React.Component<Props, State> {
  state: { selectedId: string }

  static defaultProps: Props = {
    onChange: () => {},
    keyToGetValue: 'value',
    classNamePrefix: 'radio-btn',
    className: undefined,
    value: '',
    autoClassName: false,
  }

  constructor(props: Props) {
    super(props)

    this.state = {
      selectedId: this.props.value,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    if (this.props.value !== nextProps.value || this.props.options !== nextProps.options) {
      this.setState({
        selectedId: nextProps.value,
      })
    }
  }

  selectButton(id: string, index: number) {
    this.props.onChange(id, index)
    this.setState({
      selectedId: id,
    })
  }

  generateClassName = (value: string) => {
    return this.props.autoClassName ? `${this.props.classNamePrefix}-${value.toLowerCase()}` : ''
  }

  setColorBtn = (isActive, option) => {
    if (isActive) return 'primary'
    else {
      if (!_.isUndefined(option.canSelectable)) return option.canSelectable ? 'default' : 'disabled'
      else return 'default'
    }
  }

  render() {
    const { className, options, keyToGetValue } = this.props
    const { selectedId } = this.state

    return (
      <ButtonGroup className={className}>
        {_.map(options, (option: Object, index: number) => {
          const isActive = selectedId === option[keyToGetValue]
          const optionClassName = this.generateClassName(option[keyToGetValue])
          return (
            <Button
              key={index}
              color={this.setColorBtn(isActive, option)}
              className={optionClassName}
              onClick={() => this.selectButton(option[keyToGetValue], index)}
              data-text={option.text}
              data-value={option[keyToGetValue]}
              disabled={_.isUndefined(option.canSelectable) ? false : !option.canSelectable}
            >
              {option.text}
            </Button>
          )
        })}
      </ButtonGroup>
    )
  }
}

export default RadioButtonGroup
