import { renameProp, withProps, compose } from 'recompose'
import UploadMultipleDocCommand from 'e-submission/components/Card/UploadMultipleDocCommand'
import { CheckedCard } from 'e-submission/components/Card/DocumentCard'

const Component = ({ validateFile, action, value, limit, ...props }) => (
  <UploadMultipleDocCommand
    action={action}
    validateFile={validateFile}
    CheckedComponent={CheckedCard}
    {...value}
    {...props}
  />
)

export default (hoc) =>
  compose(
    renameProp('validate', 'validateFile'),
    withProps(({ customText }) => ({
      action: customText,
    })),
    hoc
  )(Component)
