import baseConfig from './base'

// TODO - this doesn't apply for prod, uat since we reuse compiled files in pipeline
const OC_ENVIRONMENT = process.env.OC_ENVIRONMENT || 'sandbox'
const configJson = require('../deploy-env/' + OC_ENVIRONMENT + '/config.json')
const GA_TRACKING_ID = configJson.GA_TRACKING_ID || 'UA-DIST-ENV-ID'
const APP_VERSION = process.env.APP_VERSION || ''
const APP_NAME = process.env.APP_NAME || ''

const config = {
  iConnectDb: {
    localURL: 'iconnect',
  },
  gaTrackingID: GA_TRACKING_ID,
  appVersion: APP_VERSION,
  appName: APP_NAME,
  ocEnvironment: OC_ENVIRONMENT,
}

export default Object.freeze(Object.assign({}, baseConfig, config))
