// @flow
import _ from 'lodash'
import type { DisplayProduct } from 'core/service/display-product'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { Period } from 'core/data-model/basic-plan'
import { getRiderAsterisks } from 'core/service/benefit-illustration/benefit-summary'
import { formatNumber } from 'core/service/lib/number-format'
import Mustache from 'mustache'
import MESSAGES from 'core/data-model/constants/bi-messages'
import values from 'core/data-model/constants/values'
import { getESCITitle } from 'quick-quote/product-common/benefit-illustration/components/rider-benefits/components/esci-wpewl85-rider-benefit'
import { getToggles } from 'quick-quote/feature-toggles'

export const renderRiderNameSummaryTable = (rider: Rider & RiderState) => {
  switch (rider.code) {
    case values.MEH:
      return `${MESSAGES.RIDER} ${rider.description}`
    case values.MHP:
      return `${MESSAGES.RIDER_HEALTH}\n${rider.description}`
    case values.WPC:
    case values.WPC25PL:
    case values.WPCWL60:
    case values.WPCWL99:
    case values.WPEWL85:
    case values.WPEIP85:
      return `${MESSAGES.RIDER}${MESSAGES.BENEFIT_TITLE}${rider.description}`
    case values.AMG:
      return `${rider.description}`
    case values.MESG:
      return `${rider.description}`
    case values.MEK:
      return rider.selectedPlan.planCode === values.MEK1
        ? `${MESSAGES.RIDER}${MESSAGES.SUKHAPABDEKDEE_PLAN.MEK1_RIDER_SUMMARY_TITLE_PREFIX}`
        : `${MESSAGES.RIDER}${MESSAGES.SUKHAPABDEKDEE_PLAN.MEK2_RIDER_SUMMARY_TITLE_PREFIX}`
    case values.MEX:
      return `${MESSAGES.RIDER}${MESSAGES.MEX_DESCRIPTION}`
    case values.PBPDD:
    case values.PBSDD:
      return `${MESSAGES.PB_FIT_NAME}`
    case values.PBPDDCI:
    case values.PBSDDCI:
      return `${MESSAGES.PB_BEYOND_NAME}`
    default:
      return `${MESSAGES.RIDER}${rider.description}`
  }
}

export const getRiderNameDisplayValue = (rider: Rider & RiderState, isNewAsteriskOfSummaryMainContract: boolean) => {
  const productCode = rider.package ? rider.selectedPlan.planCode : rider.name
  const productName = renderRiderNameSummaryTable(rider)
  const asterisks = getRiderAsterisks(rider, isNewAsteriskOfSummaryMainContract)
  return rider.code === values.MEK ? `${productName} ${asterisks}` : `${productName} (${productCode})${asterisks}`
}

export const isNonLevelRider = (rider: Rider & RiderState) => rider.premiumStructure === values.PREMIUM_NON_LEVEL

export const getProtectionDisclaimer = (rider: Rider & RiderState): string => {
  return getToggles().ENABLE_ICARE_REPRICING === true && rider.code === values.ESCI
    ? MESSAGES.DISCLAIMER_ADDITIONAL_AGENT_PROTECTION
    : MESSAGES.DISCLAIMER_AGENT_PROTECTION
}

export const getESCIDisclaimer = (rider: Rider & RiderState) => {
  return rider.code === values.ESCI
    ? {
        stack: [
          {
            text: [MESSAGES.DISCLAIMER_ADDITIONAL_AGENT_ESCI],
          },
        ],
        style: 'disclaimer',
      }
    : {}
}

export const agentProtectionDisclaimer = (rider: Rider & RiderState) => {
  return rider.showAgentProtectionDisclaimer
    ? [
        {
          style: 'disclaimerBox',
          table: {
            widths: ['100%'],
            body: [[getProtectionDisclaimer(rider)]],
          },
        },
        getESCIDisclaimer(rider),
      ]
    : {}
}

export const getRiderSummaryTitle = (rider: Rider & RiderState) => {
  switch (rider.code) {
    case 'WPD':
    case 'WPDD':
    case 'WP':
    case 'WPC':
    case 'WPC25PL':
    case 'WPCWL60':
    case 'WPCWL99':
      return Mustache.render(MESSAGES.RIDER_SUMMARY_TITLE, {
        riderDescription: rider.description,
        riderName: rider.name,
      })
    case 'WPTPD':
    case 'WPTPDCI':
      return Mustache.render(MESSAGES.RIDER_SUMMARY_TITLE, {
        riderDescription: ` ${MESSAGES.RIDER}${rider.description}`,
        riderName: rider.name,
      })
    case 'PBPDD':
    case 'PBSDD':
      return Mustache.render(MESSAGES.RIDER_SUMMARY_TITLE, {
        riderDescription: ` ${MESSAGES.PB_FIT_NAME}`,
        riderName: rider.name,
      })
    case 'PBPDDCI':
    case 'PBSDDCI':
      return Mustache.render(MESSAGES.RIDER_SUMMARY_TITLE, {
        riderDescription: ` ${MESSAGES.PB_BEYOND_NAME}`,
        riderName: rider.name,
      })
    case 'PLR':
      return Mustache.render(MESSAGES.RIDER_SUMMARY_TITLE_WITH_CODE, {
        riderSelectedPlanCode: rider.selectedPlan.planCode,
      })
    case 'PLS':
      return Mustache.render(MESSAGES.RIDER_SUMMARY_TITLE_WITH_CODE, {
        riderSelectedPlanCode: rider.selectedPlan.planCode,
      })
    case 'ESCI':
      return Mustache.render(MESSAGES.RIDER_SUMMARY_TITLE_WITHOUT_CODE, {
        riderDescription: getESCITitle(),
      })
    case 'FH':
      return Mustache.render(MESSAGES.FLEXI_HEALTH_RIDER_SUMMARY_TITLE_DEFAULT, {
        riderDescription: rider.description,
        riderName: rider.name,
      })
    case 'CIUDR':
      return MESSAGES.CI_UDR_RIDER_SUMMARY_TITLE
    case 'TPDUDR':
      return MESSAGES.TPD_UDR_RIDER_SUMMARY_TITLE
    case 'HBUDR':
      return MESSAGES.HB_UDR_RIDER_SUMMARY_TITLE
    case 'HSUDR':
      return MESSAGES.HS_UDR_RIDER_SUMMARY_TITLE
    default:
      return Mustache.render(MESSAGES.RIDER_SUMMARY_TITLE_DEFAULT, {
        riderDescription: rider.description,
        riderName: rider.name,
      })
  }
}

export const getBasicPlanNameDisplayValue = (basicPlan: DisplayProduct) => {
  let planName
  if (basicPlan.code === 'SUKHAPABDEKDEE-WLNP85') {
    planName = basicPlan.basicPlanName
  } else if (values.LIFEREADY_CAMPAIGN_CODE.includes(basicPlan.code)) {
    planName = MESSAGES.LIFE_READY_PACKAGE_NAME
  } else if (values.LIFETREASURE_GROUP_CODE.includes(basicPlan.code)) {
    planName = MESSAGES.LIFE_TREASURE_NAME_THAI
  } else if (values.CANCERX5_CAMPAIGN_CODE.includes(basicPlan.code)) {
    planName = MESSAGES.LIFE_READY_PACKAGE_NAME
  } else if (values.PRODUCT_GIO.includes(basicPlan.code)) {
    planName = values.PREFIX_PRODUCT_NAME_THAI + ' ' + basicPlan.nameThai
  } else {
    planName = basicPlan.name
  }

  if (basicPlan.basicPlanCode === values.IGROW) return MESSAGES.BENEFIT_HEADER_IGROW
  else if (basicPlan.code === 'IHEALTHYULTRA-12PL') return `${planName}`
  else return `${planName} (${basicPlan.basicPlanCode})`
}

export const getSumAssuredDisplayValue = (
  selectedPlan: DisplayProduct,
  sumAssured: number,
  showSumAssuredDecimalPlaces: boolean
): string => {
  // $FlowFixMe
  if (selectedPlan.planCode) {
    return `${MESSAGES.PLAN} ${sumAssured}`
  } else {
    if (showSumAssuredDecimalPlaces) {
      return formatNumber(sumAssured, 2)
    } else {
      return formatNumber(sumAssured)
    }
  }
}

export const isRenderETIandRPU = (props) => {
  const { policyValue } = props
  const RPUCashpaid = _.get(policyValue.yearEndPolicyValues[0], 'RPUCashpaid')
  const ETICashpaid = _.get(policyValue.yearEndPolicyValues[0], 'ETICashpaid')
  return getToggles().ENABLE_RPU_ETI && (RPUCashpaid !== undefined || ETICashpaid !== undefined)
}

export const untilAge = (basicPlanPaymentPeriod: Period) =>
  basicPlanPaymentPeriod.type === 'limited_to_age' ? MESSAGES.UNTIL_AGE : ''

export const calculatePageAmountPolicyValueTable = (policyValue, rowsPerPage) => {
  let totalPage = 0
  let totalRows = policyValue.yearEndPolicyValues.length
  rowsPerPage.map((r) => {
    if (totalRows - r >= 0) {
      totalPage++
      totalRows = totalRows - r
    }
  })
  totalPage++
  return totalPage
}
