// @flow
import { createSelector } from 'reselect'
import type { ProductBenefitProps } from 'quick-quote/product-common/benefit-illustration/components/product-benefits/types'
import { getSelectedDisplayProduct } from 'quick-quote/product-selection/selectors'
import {
  getSumAssured$,
  getBasicPremium$,
  getSelectedModelFactorID,
  getSelectedModelFactorLabel,
} from 'quick-quote/product-common/coverage-plan/selectors'
import {
  getCalculatedCoveragePeriod,
  getCalculatedPaymentPeriod,
} from 'quick-quote/product-common/benefit-illustration/selectors'
import { getPolicyValues } from 'quick-quote/product-whole-life/benefit-illustration/selectors'
import MESSAGE from 'core/data-model/constants/bi-messages'

const _isPerfectLifeSolution = (displayProduct) =>
  displayProduct.code === 'PERFECTLIFE-WL88J' || displayProduct.code === 'PERFECTLIFE-WL88S'

const _getBenefitSummaryHeader = ({ displayProduct }: *): string => {
  return _isPerfectLifeSolution(displayProduct) ? MESSAGE.LIFE_PROTECT_18_NAMETHAI : displayProduct.nameThai
}

export const getProductBenefits = createSelector(
  getSelectedDisplayProduct,
  getSumAssured$,
  getBasicPremium$,
  getCalculatedPaymentPeriod,
  getCalculatedCoveragePeriod,
  getPolicyValues,
  getSelectedModelFactorID,
  getSelectedModelFactorLabel,
  (
    displayProduct,
    sumAssured,
    basicPremium,
    calculatedPaymentPeriod,
    calculatedCoveragePeriod,
    policyValue,
    selectedModelFactorID,
    selectedModelFactorLabel
  ): ProductBenefitProps => ({
    displayProduct,
    sumAssured: sumAssured.value,
    basicPremium: basicPremium.value,
    calculatedPaymentPeriod,
    calculatedCoveragePeriod,
    policyValue,
    benefitSummaryHeader: _getBenefitSummaryHeader,
    selectedModelFactorID,
    selectedModelFactorLabel,
  })
)
