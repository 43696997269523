import { connect } from 'react-redux'
import styled from 'styled-components'
import { FormFeedback } from 'reactstrap'
import { Checkbox } from 'common-components'
import { compose, mapProps, withStateHandlers, lifecycle } from 'recompose'
import { setAppValue } from 'e-submission/apps/actions'
import { CREDIT_CARD_RELATIONSHIP_LIST } from 'e-submission/domain/data-model/constants'
import PayerInfo from 'e-submission/components/Form/Payment/PayerInfo'
import { getToggles } from 'quick-quote/feature-toggles'

const RELATIONSHIP = CREDIT_CARD_RELATIONSHIP_LIST

const Header = styled.label`
  font-weight: bold;
`

const Container = styled.div`
  margin-top: 20px;
`

const Tier1Info = () => (
  <FormFeedback className="info-message attached-icon">
    <p>
      ในกรณีที่ผู้เอาประกันเลือกชำระด้วยบัตรเครดิตของบุคคลที่มีความเกี่ยวข้องกัน จะต้องแนบ{' '}
      <b>เอกสารพิสูจน์ความสัมพันธ์ผู้ชำระเบี้ย</b> อาทิเช่น สามีต้องการชำระเบี้ยให้กับผู้เอาประกัน
      จะต้องแนบเอกสารทะเบียนสมรสหรือเอกสารพิสูจน์ความสัมพันธ์อื่นๆ ด้วยทุกครั้ง
      หากไม่แนบเอกสารตัวแทนจะไม่สามารถส่งใบคำขอเอาประกันในขั้นตอนต่อไปได้
    </p>
  </FormFeedback>
)

const CreditCardConsent = ({ creditCardRelationshipProps, handleChangeCreditCardRelation, isSendLink }) => {
  const isRelationshipSelf = creditCardRelationshipProps === RELATIONSHIP.SELF
  const isRelationshipTier1 = creditCardRelationshipProps === RELATIONSHIP.TIER1
  const isEnabledECBRSystemization = getToggles().ENABLE_ECBR_SYSTEMIZATION
  return isEnabledECBRSystemization ? (
    <PayerInfo isSendLink={isSendLink} />
  ) : (
    <div data-testid="creditcard-consent">
      <Container>
        <Header>โปรดระบุที่มาของบัตรเครดิต</Header>
      </Container>
      <Container>
        {!isSendLink ? (
          <Checkbox checked={isRelationshipSelf} onChange={() => handleChangeCreditCardRelation(RELATIONSHIP.SELF)}>
            ชำระบัตรเครดิตของผู้เอาประกัน
          </Checkbox>
        ) : isSendLink && isRelationshipSelf ? (
          <p>ชำระบัตรเครดิตของผู้เอาประกัน</p>
        ) : null}
      </Container>
      <Container>
        {!isSendLink ? (
          <Checkbox checked={isRelationshipTier1} onChange={() => handleChangeCreditCardRelation(RELATIONSHIP.TIER1)}>
            ชำระด้วยบัตรเครดิตของบุคคลที่มีความเกี่ยวข้องกัน (บิดา มารดา สามี ภรรยา บุตร หรือ อื่นๆ)
          </Checkbox>
        ) : isSendLink && isRelationshipTier1 ? (
          <p>ชำระด้วยบัตรเครดิตของบุคคลที่มีความเกี่ยวข้องกัน (บิดา มารดา สามี ภรรยา บุตร หรือ อื่นๆ)</p>
        ) : null}
      </Container>
      {isRelationshipTier1 && (
        <div>
          <Tier1Info></Tier1Info>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    creditCardRelationshipProps: props.creditCardRelationship || '',
    isSendLink: props.isSendLink || false,
    allState: state,
  }
}

const mapDispatchToProps = (dispatch) => ({
  setAppValue: (fieldId, newValue) => {
    dispatch(setAppValue(fieldId, newValue))
  },
})

export default compose(
  mapProps((props) => ({
    ...props,
  })),
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      this.props.handleConsentValidation(true)
    },
  }),
  withStateHandlers(
    {},
    {
      handleChangeCreditCardRelation: (state, props) => (val) => {
        getToggles().ENABLE_QUICKLINK_PAYMENT
          ? props.handleCheckCreditcardRelationShip(true)
          : props.handleConsentValidation(false)

        props.setAppValue('creditCardRelationship', val)
      },
    }
  )
)(CreditCardConsent)
