// @flow

import _ from 'lodash'
import type { DisplayProductQuery } from 'core/service'
import { getBasicPlan } from 'core/service'
import { queryPremiumRate, isRequirePayerInfo } from 'core/service/rider/rider'
import { getRiskiestOccupationFactorForRiders } from 'core/service/insured'
import { dataWrapper } from 'core/data'
import { isValidConfigurationForTMO } from 'core/service/basic-plan/validation'
import VALUES from 'core/data-model/constants/values'
import { calculatePeriod, calculateWaivePeriod } from 'core/service/basic-plan/period'
import type { Gender, Age } from 'core/data-model/insured'
import type { RiderState, Rider, RiderPremiumRate } from 'core/data-model/rider'

export const _getRiderPremiumRates = (
  age: Age,
  payerAge: Age,
  gender: Gender,
  payerGender: Gender,
  riders: (Rider & RiderState)[],
  basicPlanCode: string,
  occupationFactor: { [string]: number },
  riderPremiumRatesPayload: RiderPremiumRate[],
  basicYearsOfPayment: number
): { [riderCode: string]: number } => {
  const riderPremiumRates = riders.map((rider) => {
    let riderPremiumRates

    const planCode = rider.selectedPlan ? rider.selectedPlan.planCode : ''

    for (let premium of riderPremiumRatesPayload) {
      if ([rider.code, planCode].includes(premium.riderCode)) {
        const effectiveDate = _.get(premium, 'effectiveDate', '')
        const expiryDate = _.get(premium, 'expiryDate', '')

        if (_.isEmpty(effectiveDate) && _.isEmpty(expiryDate)) {
          riderPremiumRates = premium
          break
        } else {
          if (isValidConfigurationForTMO(rider.code, effectiveDate, expiryDate)) {
            riderPremiumRates = premium
            break
          }
        }
      }
    }
    if (!riderPremiumRates) return { code: rider.code, premiumRate: 0 }
    const queryDependencies = {
      selectedBasicPlanCode: basicPlanCode,
      selectedRiderPlanCode: planCode,
      gender: isRequirePayerInfo(rider.code) ? payerGender : gender,
      age: isRequirePayerInfo(rider.code) ? payerAge : age,
      yearsOfCoverage: rider.yearsOfCoverage,
      occupationFactor: occupationFactor[rider.code],
      riderSumAssured: rider.sumAssured,
      basicYearsOfPayment: basicYearsOfPayment,
      waivePeriod: calculateWaivePeriod(rider.code, basicYearsOfPayment, age.unit !== 'year' ? 0 : age.value),
    }

    const premiumRate = queryPremiumRate(rider, riderPremiumRates, queryDependencies)
    return { code: rider.code, premiumRate: premiumRate }
  })

  // TODO: Test this once module requirement is clear
  const riderModulePremiumRate = _(riders)
    .filter('selectedPlan.modules')
    .value()
    .map((rider) => {
      const queryDependencies = {
        selectedBasicPlanCode: basicPlanCode,
        selectedRiderPlanCode: rider.selectedPlan ? rider.selectedPlan.planCode : '',
        gender: isRequirePayerInfo(rider.code) ? payerGender : gender,
        age: isRequirePayerInfo(rider.code) ? payerAge : age,
        yearsOfCoverage: rider.yearsOfCoverage,
        occupationFactor: occupationFactor[rider.code],
        riderSumAssured: rider.sumAssured,
      }

      return rider.selectedPlan.modules.map((riderModule) => {
        const riderPremiumRates = _.find(riderPremiumRatesPayload, { riderCode: riderModule.code })
        if (!riderPremiumRates) return { code: riderModule.code, premiumRate: 0 }

        const premiumRate = queryPremiumRate(rider, riderPremiumRates, queryDependencies)
        return { code: riderModule.code, premiumRate: premiumRate }
      })
    })
    .reduce((flatten, premium) => flatten.concat(premium), [])

  return _(riderPremiumRates)
    .union(riderModulePremiumRate)
    .keyBy('code')
    .mapValues((riderPremiumRate) => riderPremiumRate.premiumRate)
    .value()
}

export const getRiderPremiumRates = async (
  age: Age,
  payerAge: Age,
  gender: Gender,
  payerGender: Gender,
  riders: (Rider & RiderState)[],
  natureOfDuties: string[],
  displayProductQuery: DisplayProductQuery
) => {
  const basicPlan = await getBasicPlan(displayProductQuery)
  const riderPremiumRatesPayload = await dataWrapper.getRiderPremiumRates()
  const occupationFactor = await getRiskiestOccupationFactorForRiders(displayProductQuery, natureOfDuties, riders)
  const basicYearsOfPayment = calculatePeriod(basicPlan.paymentPeriod, age.unit !== 'year' ? 0 : age.value)
  return _getRiderPremiumRates(
    age,
    payerAge,
    gender,
    payerGender,
    riders,
    basicPlan.productCode,
    occupationFactor,
    riderPremiumRatesPayload,
    basicYearsOfPayment
  )
}

export const calculatePremiumOptionPlanPA = async (rider, riderOccupationFactor, planGroupPA, insuredAge) => {
  const riderPremiumRatesPayload = await dataWrapper.getRiderPremiumRates()
  const riderPremiumRates = _.find(riderPremiumRatesPayload, { riderCode: rider.code })

  return planGroupPA.map((riderOption) => {
    if (!riderPremiumRates) return { code: riderOption.planCode, premiumRate: 0 }

    const queryDependencies = {
      selectedBasicPlanCode: '',
      selectedRiderPlanCode: riderOption.planCode,
      gender: '',
      age: insuredAge,
      yearsOfCoverage: 0,
      occupationFactor: riderOccupationFactor,
      riderSumAssured: 0,
    }

    const premiumRate = queryPremiumRate(rider, riderPremiumRates, queryDependencies)
    return { code: riderOption.planCode, premiumRate: premiumRate }
  })
}

export const isUsedTMORate = async () => {
  return _isUsedTMORate(await dataWrapper.getRiderPremiumRates(), [VALUES.DCI])
}

export const _isUsedTMORate = (riderPremiumRatesPayload: RiderPremiumRate[], riders: string[]) => {
  for (let premium of riderPremiumRatesPayload) {
    for (let rider of riders) {
      if (rider === premium.riderCode) {
        let effectiveDate = _.get(premium, 'effectiveDate')
        if (isValidConfigurationForTMO(rider, effectiveDate, _.get(premium, 'expiryDate'))) {
          return rider === VALUES.PLS
            ? effectiveDate === VALUES.PLS_EFFECTIVE_DATE
            : effectiveDate === VALUES.TMO_EFFECTIVE_DATE
        }
        break
      }
    }
  }
  return false
}
