import styled from 'styled-components'
import Button from 'common-components/button'
import Icon from 'e-submission/components/Icon'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { startVideoConsent } from 'quick-quote/remote-selling/actions'
import { getCurrentApp } from 'e-submission/apps/selectors'
import { debounce, getOr } from 'lodash/fp'

const BgWhite = styled.div`
  background-color: white;
  padding: 0.75rem;
  line-height: 1rem;
  font-size: 0.875rem;
  margin-bottom: 1rem;
`

const Container = styled.div`
  text-align: center;
`

export const Heading = styled.div`
  font-weight: bold;
  font-size: 1.333rem;
  margin-bottom: 1rem;

  label {
    display: inline !important;
    padding-left: 0.5rem;
  }
`

const SubHeading = styled.label`
  display: block;
  font-size: 1rem;
  font-weight: bold;
`

const VideoConsent = ({ loading, onClick }) => {
  return (
    <div>
      <Heading>
        <Icon.video />
        <label>บันทึกภาพให้ความยินยอม</label>
      </Heading>
      <BgWhite>
        <SubHeading>บันทึกภาพให้คำยินยอม สำหรับผู้ขอเอาประกันภัย</SubHeading>
      </BgWhite>
      <BgWhite>
        <label>
          เพื่อให้เป็นไปตามหลักเกณฑ์การเสนอขายของสำนักงานคณะกรรมการกำกับและส่งเสริมการประกอบธุรกิจประกันภัย
          ในการเสนอขายกรมธรรม์ประกันชีวิตผ่านระบบ Remote selling
          มีความจำเป็นในการบันทึกการตอบตกลงแจ้งความประสงค์เพื่อยืนยันการสมัครทำประกันภัยของลูกค้า บริษัทฯ
          จึงขออนุญาตบันทึกการยืนยันดังกล่าวในรูปแบบเสียง หรือ ภาพและเสียง เพื่อให้เป็นไปตามหลักเกณฑ์
          และเพื่อประโยชน์ของลูกค้าในการสมัครทำประกันชีวิต
        </label>
      </BgWhite>
      <Container>
        <Button disabled={loading} loading={loading} onClick={onClick}>
          บันทึกภาพ
        </Button>
      </Container>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => ({
  onClick: debounce(750, () => {
    dispatch(startVideoConsent())
  }),
})
export default (hoc) =>
  compose(
    connect((state) => {
      const app = getCurrentApp(state)
      return {
        loading: getOr(false, 'remoteSelling.loadingVideoConsent', app),
      }
    }, mapDispatchToProps),
    hoc
  )(VideoConsent)
