// @flow

import * as React from 'react'
import _ from 'lodash'
import { getAdvisors } from 'e-submission/domain/service'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { FormGroup, FormFeedback } from 'reactstrap'
import MESSAGES from 'core/data-model/constants/messages'

class FSESelection extends React.Component<*, *> {
  constructor(props: *, state: *) {
    super(props)

    this.state = {
      options: [],
      errors: [],
      isFocus: false,
    }
  }

  _onSelect = (selectedItems: Object[]) => {
    const fse = selectedItems[0]
    if (
      !_.isUndefined(fse) &&
      !_.isUndefined(fse.party) &&
      !_.isUndefined(fse.party.firstName) &&
      !_.isUndefined(fse.party.lastName)
    ) {
      this.props.updateSelectedValue(fse)
      this.refs.asyncTypeAhead.getInstance().refs.input._input._input.blur()
    } else {
      this.props.updateSelectedValue(undefined)
    }
  }

  _handleSearch = (query: string) => {
    getAdvisors(this.props.user, query, '', 'fse').then((data) => {
      this._setOptions(data)
    })
  }

  _setOptions = (options: *) => {
    this.setState({ options })
  }

  render() {
    return (
      <FormGroup className={'fse-selection'}>
        <AsyncTypeahead
          ref="asyncTypeAhead"
          inputProps={{
            type: 'tel',
          }}
          {...this.state}
          minLength={3}
          selected={[
            this.props.selectedValue && {
              ...this.props.selectedValue,
              isFocus: this.state.isFocus,
            },
          ]}
          useCache={false}
          labelKey={({
            advisorCode = '',
            party = {},
            firstName = party.firstName,
            lastName = party.lastName,
            isFocus,
          }) => (isFocus ? advisorCode.split('-')[2] : `${advisorCode.split('-')[2]} - ${firstName} ${lastName}`)}
          searchText={MESSAGES.SEARCHING}
          promptText={MESSAGES.TYPE_TO_SEARCH}
          emptyLabel={MESSAGES.DATA_NOT_FOUND}
          onSearch={this._handleSearch}
          placeholder={MESSAGES.FSE_PLACEHOLDER}
          renderMenuItemChildren={this._renderMenuItemChildren}
          onChange={this._onSelect}
          onFocus={() => this.setState({ isFocus: true })}
          onBlur={() => this.setState({ isFocus: false })}
        />
        <FormFeedback className="validation-message">{this.state.errors.join(' ')}</FormFeedback>
      </FormGroup>
    )
  }

  keydownValidate = (evt: *) => {
    var charCode = evt.which ? evt.which : evt.keyCode

    if (
      [46, 8, 9, 27, 13].indexOf(charCode) !== -1 ||
      // Allow: Ctrl+A, Command+A
      ((charCode === 65 || charCode === 67 || charCode === 86 || charCode === 88) &&
        (evt.ctrlKey === true || evt.metaKey === true)) ||
      // Allow: home, end, left, right, down, up
      (charCode >= 35 && charCode <= 40)
    ) {
      // let it happen, don't do anythinggit g
      return
    }
    // Ensure that it is a number and stop the keypress
    if ((evt.shiftKey || charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105)) {
      evt.preventDefault()
    }
  }

  componentDidMount() {
    this.refs.asyncTypeAhead.getInstance().refs.input._input._input.onkeydown = this.keydownValidate
    this.refs.asyncTypeAhead.getInstance().refs.input._input._input.setAttribute('maxLength', '200')
  }

  _renderMenuItemChildren(option: *, props: *, index: number) {
    return (
      <div key={option.advisorId}>
        <span>
          {option.advisorCode.split('-')[2]} - {option.party.firstName} {option.party.lastName}
        </span>
      </div>
    )
  }
}

export default FSESelection
