// @flow
import { formatNumber } from 'core/service/lib/number-format'
import Mustache from 'mustache'
import { buildHeaderTable } from 'core/service/pdf-generation/utils/builder'
import MESSAGES from 'core/data-model/constants/bi-messages'
import VALUES from 'core/data-model/constants/values'

import {
  getPensionTypeString,
  getPensionPercent,
  calculatePensionAmount,
  getTotalPensionPeriod,
  calculateTotalPensionAmount,
  getPensionPeriod,
  calculateMinimumDeathCoverage,
  getDeathCoveragePercentage,
} from 'core/service/benefit-illustration/products/retirement'
import type { BenefitSummary } from 'core/service/benefit-illustration'

const firstBenefitSummaryTable = (pensionType: string) => {
  return {
    title: Mustache.render(MESSAGES.RETIREMENT_BENEFITS, { pensionType: getPensionTypeString(pensionType) }),
    headers: [
      {
        text: MESSAGES.PERIOD_AGE,
        style: 'tableHeader',
      },
      {
        text: Mustache.render(MESSAGES.PENSION_TYPE, { pensionType: getPensionTypeString(pensionType) }),
        style: 'tableHeader',
      },
      {
        text: MESSAGES.PENSION_AMOUNT,
        style: 'tableHeader',
      },
    ],
  }
}

const buildFirstTotalBenefitSummary = (sumAssured: number, pensionType: string, productCode: string) => [
  {
    text: MESSAGES.TOTAL_BENEFIT_PENSION_AGE_60_85_PDF,
    alignment: 'center',
    bold: true,
  },
  {
    text: Mustache.render(MESSAGES.MAXIMUM_PENSION_PERIOD, {
      totalPensionPeriod: getTotalPensionPeriod(pensionType, productCode),
    }),
    alignment: 'center',
    margin: [0, 8, 0, 0],
    bold: true,
  },
  {
    text: formatNumber(calculateTotalPensionAmount(sumAssured, pensionType, productCode), 2),
    alignment: 'right',
    margin: [0, 8, 0, 0],
    bold: true,
  },
]
const buildFirstBenefitSummary = (sumAssured: number, pensionType: string, productCode: string) => {
  const benefitValuesLifeRetire = [
    [
      {
        text: MESSAGES.BENEFIT_PENSION_AGE_60_85,
        alignment: 'center',
      },
      {
        text: Mustache.render(MESSAGES.PENSION_PERCENT, {
          pensionPercent: getPensionPercent(pensionType, productCode),
        }),
        alignment: 'center',
      },
      {
        text: formatNumber(calculatePensionAmount(sumAssured, pensionType, productCode), 2),
        alignment: 'right',
      },
    ],
  ]
  const benefitValuesIRetire = [
    [
      {
        text: MESSAGES.BENEFIT_PENSION_AGE_60_74,
        alignment: 'center',
      },
      {
        text: Mustache.render(MESSAGES.PENSION_PERCENT, {
          pensionPercent: getPensionPercent(pensionType, productCode),
        }),
        alignment: 'center',
      },
      {
        text: formatNumber(calculatePensionAmount(sumAssured, pensionType, productCode), 2),
        alignment: 'center',
      },
    ],
    [
      {
        text: MESSAGES.BENEFIT_PENSION_AGE_75_85,
        alignment: 'center',
      },
      {
        text: Mustache.render(MESSAGES.PENSION_PERCENT, {
          pensionPercent: getPensionPercent(pensionType, productCode),
        }),
        alignment: 'center',
      },
      {
        text: formatNumber(calculatePensionAmount(sumAssured, pensionType, productCode), 2),
        alignment: 'center',
      },
    ],
  ]
  const benefitValues = productCode === VALUES.LIFE_RETIRE_5 ? benefitValuesLifeRetire : benefitValuesIRetire
  benefitValues.push(buildFirstTotalBenefitSummary(sumAssured, pensionType, productCode))
  return benefitValues
}

const buildSecondBenefitSummary = (sumAssured: number, pensionType: string, productCode: string) => {
  const secondBenefitSummaryLifeRetire = [
    [
      {
        text: Mustache.render(MESSAGES.LIFE_RETIRE_DEATH_BENEFIT_1, {
          deadCoverageSurrender: getDeathCoveragePercentage(productCode, 'surrender'),
          deadCoverageStandard: getDeathCoveragePercentage(productCode, 'standard'),
        }),
        alignment: 'left',
      },
      {
        text: Mustache.render(MESSAGES.MINIMUN_DEATH_COVERAGE, {
          minimunDeathCoverage: formatNumber(calculateMinimumDeathCoverage(sumAssured, productCode), 2),
        }),
        alignment: 'center',
        margin: [0, 24, 0, 0],
      },
    ],
    [
      {
        text: MESSAGES.RETIREMENT_DEATH_BENEFIT_2,
        alignment: 'left',
        margin: [0, 24, 0, 0],
      },
      {
        text: Mustache.render(MESSAGES.LIFE_RETIRE_DEATH_COVERAGE_AGE_60_74, {
          pensionPeriod: getPensionPeriod(pensionType),
        }),
        alignment: 'center',
      },
    ],
    [
      {
        text: Mustache.render(MESSAGES.LIFE_RETIRE_DEATH_BENEFIT_3, { pensionPeriod: getPensionPeriod(pensionType) }),
        alignment: 'left',
        margin: [0, 6, 0, 0],
      },
      {
        text: MESSAGES.LIFE_RETIRE_DEATH_COVERAGE_AGE_AFTER_75,
        alignment: 'center',
      },
    ],
  ]
  const secondBenefitSummaryIRetire = [
    [
      {
        text: MESSAGES.RETIREMENT_DEATH_BENEFIT_1,
        alignment: 'left',
      },
      {
        text: Mustache.render(MESSAGES.MINIMUN_DEATH_COVERAGE, {
          minimunDeathCoverage: formatNumber(calculateMinimumDeathCoverage(sumAssured, productCode), 2),
        }),
        alignment: 'center',
        margin: [0, 12, 0, 0],
      },
    ],
    [
      {
        text: MESSAGES.RETIREMENT_DEATH_BENEFIT_2,
        alignment: 'left',
        margin: [0, 24, 0, 0],
      },
      {
        text: Mustache.render(MESSAGES.DEATH_COVERAGE_AGE_60_74, {
          pensionPeriod: getPensionPeriod(pensionType),
        }),
        alignment: 'left',
      },
    ],
    [
      {
        text: Mustache.render(MESSAGES.RETIREMENT_DEATH_BENEFIT_3, { pensionPeriod: getPensionPeriod(pensionType) }),
        alignment: 'left',
        margin: [0, 12, 0, 0],
      },
      {
        text: MESSAGES.DEATH_COVERAGE_AGE_AFTER_75,
        alignment: 'left',
      },
    ],
  ]

  if (productCode === VALUES.LIFE_RETIRE_5) {
    return secondBenefitSummaryLifeRetire
  } else {
    return secondBenefitSummaryIRetire
  }
}

type Props = {
  benefitSummary: BenefitSummary,
  pensionType: string,
  sumAssured: number,
  productCode: string,
}

export const benefitSummarySection = ({ sumAssured, pensionType, productCode }: Props) => ({
  stack: [
    {
      text: firstBenefitSummaryTable(pensionType).title,
      style: 'title',
      pageBreak: 'before',
    },
    {
      text: MESSAGES.PENSION_AGE,
      bold: true,
    },
    {
      table: {
        headerRows: 1,
        widths: ['*', '*', '*'],
        margin: [100, 10, 10],
        body: [
          // $FlowFixMe
          buildHeaderTable(firstBenefitSummaryTable(pensionType).headers),
          ...buildFirstBenefitSummary(sumAssured, pensionType, productCode),
        ],
      },
      layout: 'lightBorder',
      style: 'table',
    },
    {
      text: MESSAGES.RETIREMENT_DEATH_BENEFIT,
      bold: true,
    },
    {
      table: {
        headerRows: 1,
        widths: ['*', '*'],
        margin: [100, 10],
        body: [...buildSecondBenefitSummary(sumAssured, pensionType, productCode)],
      },
      layout: 'lightBorder',
      style: 'table',
    },
  ],
  style: 'groupRow',
})
