// @flow

import type { Rider, RiderState } from 'core/data-model/rider'
import RiderWithBenefitModal from './rider-benefit-modal'
import RiderBenefitSummary from './rider-benefit-summary'
import { AgentProtectionDisclaimer } from './agent-protection-disclaimer'

type PLRRiderBenefitProps = {
  rider: Rider & RiderState,
}

const PLRRiderBenefit = ({ rider }: PLRRiderBenefitProps) => {
  const description = 'ความคุ้มครองชีวิต (เพิ่มจากสัญญาหลัก)'
  const title = `${description} (${rider.name})`
  const benefitTable = (
    <div className="details-table">
      <p>
        คุ้มครองการเสียชีวิตทุกกรณีจาก {rider.selectedPlan.planCode} ภายในระยะเวลา {rider.yearsOfCoverage} ปี
      </p>
    </div>
  )
  const benefitRider: Rider & RiderState = { ...rider, description, name: rider.selectedPlan.planCode }
  return (
    <RiderWithBenefitModal
      id="plr-rider-benefit"
      className="rider plr-rider"
      title={title}
      modalChildren={benefitTable}
    >
      <RiderBenefitSummary rider={benefitRider} />
      {benefitTable}
      <AgentProtectionDisclaimer rider={rider} />
    </RiderWithBenefitModal>
  )
}

export default PLRRiderBenefit
