/* istanbul ignore file */

import { compact, flow, get, isEmpty, map, noop, size, times } from 'lodash/fp'
import { addFileProps, sendApplication } from 'e-submission/apps/actions'
import {
  canSubmitWithoutPayment,
  getAllAdditionalDocument,
  getFiles,
  getFileWithName,
  getPreviewMultipleDoc,
} from 'e-submission/apps/selectors'

import { createField, createSection, createSectionCreator } from 'e-submission/domain/data-model/form/utils'
import { isRemoteSelling, isSelectedRemoteSelling } from 'e-submission/domain/data-model/form/selectors'
import { DOCUMENT_TYPE } from 'e-submission/domain/data-model/constants'
import { previewDocumentSection } from 'e-submission/domain/data-model/submit'

import { getToggles } from 'quick-quote/feature-toggles'

const mapWithKey = map.convert({ cap: false })

// TODO: Clean, refactoring the duplicated field config with submitSection (generic config)
export default createSectionCreator((app, id) => {
  const isAppmanRemoteSelling = isSelectedRemoteSelling(app)
  const isBrowseFile =
    (getToggles().ENABLE_INVESTMENT_PRODUCT_REMOTESELLING_WITHOUT_BANCA && isRemoteSelling(app)) ||
    isAppmanRemoteSelling ||
    undefined
  return [
    createField(
      'document',
      [
        {
          id: 'document',
          c: 'MoreInfoCard',
          p: {
            subpage: 'preview-document',
            title: 'ดูเอกสารที่เซ็นไปแล้ว',
            sections: [previewDocumentSection('preview-document')(app)],
          },
        },
        isAppmanRemoteSelling && {
          id: 'insured.additionalDocument',
          c: 'UploadMultipleDocCommand',
          p: {
            accept: '*',
            customText: 'อัปโหลดเอกสารเพิ่มเติมสำหรับผู้เอาประกัน',
            limit: 10,
            mergeState: (state, app) => {
              const documentType = get('insured.ADDITIONAL_DOCUMENT', DOCUMENT_TYPE)
              const documentTypeName = get('name', documentType)
              const additionalDocumentList = getAllAdditionalDocument(state)
              const selectedFile = getPreviewMultipleDoc(app)
              const insuredAdditionalDocList =
                additionalDocumentList && additionalDocumentList.filter((item) => item.isInsured)
              return {
                value: {
                  id: `insured.additionalDocument.documents`,
                  name: documentTypeName,
                  description: 'อัปโหลดเอกสารเพิ่มเติมสำหรับผู้เอาประกัน',
                  checked: insuredAdditionalDocList && insuredAdditionalDocList.length > 0,
                  documentList: insuredAdditionalDocList,
                  url: getFileWithName(state, selectedFile),
                  editable: true,
                  selectedFile,
                },
              }
            },
          },
        },
        {
          id: 'insured.additionalDocument',
          c: 'MoreInfoCard',
          p: {
            subpage: 'additional-document',
            icon: 'illustrate',
            mergeState: (state) => {
              const documentType = get('insured.ADDITIONAL_DOCUMENT', DOCUMENT_TYPE)
              const documentTypeName = get('name', documentType)
              const documentTypeText = get('text', documentType)

              const additionalDocumentList = getAllAdditionalDocument(state)
              const totalAdditionalDoc = size(additionalDocumentList)
              const maxAdditionalDoc = 10

              const photoCardField = createField('insured.additionalDocument.documents', [
                {
                  id: 'insured.additionalDocument.documents',
                  c: 'PhotoCard',
                  required: true,
                  p: {
                    mergeState: (state, app) => {
                      const additionalDocumentList = getAllAdditionalDocument(state)
                      return {
                        value: mapWithKey(
                          (document, index) => ({
                            id: `insured.additionalDocument.documents[${index}]`,
                            name: get('name', document),
                            description: get('description', document),
                            checked: true,
                            editable: true,
                          }),
                          additionalDocumentList
                        ),
                      }
                    },
                  },
                },
              ])
              const addPhotoCardField = createField('insured.additionalDocument.add', [
                {
                  id: 'insured.additionalDocument.add',
                  c: 'PhotoCard',
                  required: true,
                  p: {
                    isBrowseFile,
                    mergeState: (state, app, { dispatch = noop } = {}) => {
                      const additionalDocumentList = getAllAdditionalDocument(state)
                      const totalAdditionalDoc = size(additionalDocumentList)
                      const description = flow(getFiles, get([documentTypeName, 'description']))(state)

                      return {
                        description,
                        disabled: isEmpty(description),
                        value:
                          totalAdditionalDoc < maxAdditionalDoc
                            ? [
                                {
                                  id: `insured.additionalDocument.documents[${totalAdditionalDoc}]`,
                                  name: documentTypeName,
                                  checked: false,
                                  editable: true,
                                  placeholder: 'กรุณากรอกข้อมูล',
                                  onChange: (value) => {
                                    dispatch(
                                      addFileProps(documentTypeName, {
                                        description: value,
                                      })
                                    )
                                  },
                                },
                              ]
                            : [],
                      }
                    },
                  },
                },
              ])
              const placeHolderFields = times((index) =>
                createField(`insured.additionalDocument.placeHolder[${index}]`, [
                  {
                    id: `insured.additionalDocument.placeHolder[${index}]`,
                    c: 'PlaceHolderCard',
                  },
                ])
              )(maxAdditionalDoc - totalAdditionalDoc - 1)

              return {
                title: `${documentTypeText} (ถ้ามี)`,
                sections: [
                  createSection(
                    compact([
                      size(additionalDocumentList) > 0 && photoCardField,
                      addPhotoCardField,
                      ...placeHolderFields,
                    ])
                  ),
                ],
              }
            },
          },
        },
      ],
      {
        marginTopLess: true,
        pl: [0, 0, 2],
        pr: [0, 0, 2],
      }
    ),
    createField('insured.submitApplication', [
      {
        id: 'insured.submitApplication',
        c: 'Button',
        p: {
          mergeState: (state, app, { dispatch, history }) => {
            let disabled = !canSubmitWithoutPayment(state) || get('remoteSelling.isUploading', app)
            return {
              disabled,
              onClick: () => {
                dispatch(sendApplication(history))
              },
            }
          },
          children: 'ส่งใบคำขอ',
          block: true,
          icon: 'wifi',
          style: {
            fontSize: '1.175rem',
            marginTop: '40px',
          },
        },
      },
    ]),
    createField(
      'insured.submitApplicationDiscliamer',
      [
        {
          id: 'insured.submitApplicationDiscliamer',
          c: 'MutedText',
          p: {
            children: 'กรุณาต่อ Internet เพื่อส่งใบคำขอ',
          },
        },
      ],
      {
        style: {
          marginTop: '-1rem',
        },
      }
    ),
  ]
})
