import PropTypes from 'prop-types'
import moment from 'moment'
import { dateFormatterPattern, isBuddhistYear } from 'core/service/lib/date'
import DateFormatter from '../lib/date-formatter'

const MONTHS = [
  { text: 'มกราคม', value: 1 },
  { text: 'กุมภาพันธ์', value: 2 },
  { text: 'มีนาคม', value: 3 },
  { text: 'เมษายน', value: 4 },
  { text: 'พฤษภาคม', value: 5 },
  { text: 'มิถุนายน', value: 6 },
  { text: 'กรกฎาคม', value: 7 },
  { text: 'สิงหาคม', value: 8 },
  { text: 'กันยายน', value: 9 },
  { text: 'ตุลาคม', value: 10 },
  { text: 'พฤศจิกายน', value: 11 },
  { text: 'ธันวาคม', value: 12 },
]

export class DateDropdown extends React.Component<*, *> {
  static propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.string,
    addYear: PropTypes.number,
    id: PropTypes.string,
  }

  static defaultProps = {
    className: '',
    onChange: () => {},
    onBlur: () => {},
    onFocus: () => {},
    value: '',
    addYear: 0,
  }

  componentDidUpdate(prevProps, prevStates) {
    if (
      prevStates.day !== this.state.day ||
      prevStates.month !== this.state.month ||
      prevStates.year !== this.state.year
    )
      this.handleOnChange(this.state.day, this.state.month, this.state.year)
  }

  constructor(props) {
    super(props)
    this.dateFormatter = new DateFormatter(dateFormatterPattern)
    let date = this.props.value ? this.dateFormatter.getDayMonthYear(this.props.value) : null
    this.state = {
      day: date ? parseInt(date.day) : '',
      month: date ? parseInt(date.month) : '',
      year: date
        ? isBuddhistYear(this.props.value)
          ? parseInt(date.year)
          : this.toBuddhistYear(parseInt(date.year))
        : '',
      availableDays: this.createNumberRange(1, 31),
    }
    this.handleUpdateDay = this.handleUpdateDay.bind(this)
    this.handleUpdateMonth = this.handleUpdateMonth.bind(this)
    this.handleUpdateYear = this.handleUpdateYear.bind(this)
    this.handleOnChange = this.handleOnChange.bind(this)
    this.recorrectDate = this.recorrectDate.bind(this)
    this.years = this.createNumberRange(
      this.toBuddhistYear(
        moment()
          .add(-99, 'years')
          .get('year')
      ),
      this.toBuddhistYear(
        moment()
          .add(this.props.addYear, 'years')
          .get('year')
      )
    ).reverse()
  }

  handleOnChange = (day, month, year) => {
    this.props.onBlur()
    if (day && month && year) {
      if (this.isValidDate(day, month, year)) {
        this.props.onChange({ target: { value: this.getDateString() } })
      } else {
        this.recorrectDate(day, month, year)
      }
    } else {
      this.props.onChange({ target: { value: '' } })
    }
  }

  getDateString = () => {
    const date = this.dateFormatter.addLeadingZeroToDate([this.state.day, this.state.month, this.state.year], true)
    return `${date[0]}/${date[1]}/${date[2]}`
  }

  isValidDate(day, month, year) {
    const [d, m, y] = this.dateFormatter.getFixedDate(day, month, year)
    return d === day && m === month && y === year
  }

  recorrectDate(day, month, year) {
    const [d, m, y] = this.dateFormatter.getFixedDate(day, month, year)
    this.setState({
      day: d,
      month: m,
      year: y,
    })
  }

  generateClassName(className) {
    return className ? `${this.props.className} ${className}` : this.props.className
  }

  createNumberRange(from, to) {
    const result = []
    for (let i = from; i <= to; i++) {
      result.push(i)
    }
    return result
  }

  handleUpdateDay(e) {
    this.setState({
      day: isNaN(e.target.value) ? '' : parseInt(e.target.value),
    })
  }

  handleUpdateMonth(e) {
    this.setState({
      month: isNaN(e.target.value) ? '' : parseInt(e.target.value),
    })
  }

  handleUpdateYear(e) {
    this.setState({
      year: isNaN(e.target.value) ? '' : parseInt(e.target.value),
    })
  }

  toBuddhistYear(year) {
    return year + 543
  }

  render() {
    return (
      <div id={this.props.id} className={'date-dropdown date-dropdown--container'}>
        <select
          onChange={this.handleUpdateDay}
          value={this.state.day}
          className={this.generateClassName('custom-select date-dropdown--day')}
        >
          <option>วัน</option>
          {this.state.availableDays.map((number) => (
            <option key={number} value={number}>
              {number}
            </option>
          ))}
        </select>
        <span className={'date-dropdown--delimiter'}>/</span>
        <select
          onChange={this.handleUpdateMonth}
          value={this.state.month}
          className={this.generateClassName('custom-select date-dropdown--month')}
        >
          <option>เดือน</option>
          {MONTHS.map((month) => (
            <option key={month.value} value={month.value}>
              {month.text}
            </option>
          ))}
        </select>
        <span className={'date-dropdown--delimiter'}>/</span>
        <select
          onChange={this.handleUpdateYear}
          value={this.state.year}
          className={this.generateClassName('custom-select date-dropdown--year')}
        >
          <option>ปี</option>
          {this.years.map((number) => (
            <option key={number} value={number}>
              {number}
            </option>
          ))}
        </select>
      </div>
    )
  }
}

export default DateDropdown
