// @flow
import type { Action } from 'quick-quote/product-selection/actions'
import type { Effect } from 'redux-saga/effects'
import { put, select, takeLatest, call, take } from 'redux-saga/effects'
import { get } from 'lodash/fp'

import { UPDATE_AVAILABLE_RIDERS } from 'quick-quote/product-common/coverage-plan/actions'
import { loginRequest } from 'quick-quote/actions'
import { setNotificationMessage } from 'quick-quote/notification/actions'
import { getIsUserOnline } from 'quick-quote/selectors'
import { isLoggedIn, getIdentityUser } from 'identity/selectors'
import { getMarketConductQuestionsType } from 'quick-quote/fna/need-and-gap-analysis/selectors'

import {
  VALIDATE_INVESTMENT_PRODUCT,
  VALIDATE_ORDINARY_PRODUCT,
  VALIDATE_NORMAL_PRODUCT,
  selectProduct,
  SELECT_PRODUCT,
  selectedProduct,
} from './actions'

import {
  allowProceedInvestment,
  salePermissionValidationCompleted,
  validatingSalePermission,
  rejectInvestmentPermission,
} from 'quick-quote/product-investment/validate-permission/actions'

import { clearPayerInformation, clearPayerNeeded, selectPayerRelation } from 'quick-quote/insured-information/actions'

import {
  allowProceedOrdinary,
  salePermissionValidationCompleted as saleOrdinaryPermissionValidationCompleted,
  validatingSalePermission as validatingOrdinarySalePermission,
  rejectOrdinaryPermission,
} from 'quick-quote/product-common/validate-permission/actions'

import { validateSaleInvestmentPermission, validateUserLicense } from 'core/service/distributor'
import AuthApi from 'core/service/distributor/auth-api'

import { getToggles } from 'quick-quote/feature-toggles'
import { getAppConfig } from 'deploy-env/app-config'
import { setPositionCode, setRegistration } from 'identity/actions'
import type { PermissionType } from 'core/service/distributor/permission'
import { getSelectedDisplayProductCode } from './selectors'
import { getPayerRelation, getAge } from 'quick-quote/insured-information/selectors'
import DEFAULTS from 'core/data-model/constants/defaults'
import { fnaPath, PATH } from 'quick-quote/constants/path'
import { setFnaValue } from 'quick-quote/fna/actions'
import { setAddingInvestmentQuestionForNonILPNeedType } from 'quick-quote/ui/actions'
import { getIsAddingInvestmentQuestionForNonILPNeedType } from 'quick-quote/ui/selectors'

import { updateWealthSourceData } from 'quick-quote/my-wealth-plus/actions'
import { getWealthSource } from 'quick-quote/my-wealth-plus/selectors'
import { selectGender, updateBirthdate } from 'quick-quote/insured-information/actions'
import { updateRegularPremium, updateSumAssured } from 'quick-quote/product-investment/coverage-plan/actions'

const NO_INTERNET = { isValid: false, type: 'NoInternet' }

function* _clearWealthSourceData(): Generator<*, *, *> {
  const wealthSource = yield select(getWealthSource)

  if (wealthSource == 'MY_WEALTH_PLUS') {
    // get wealthSource if = MY_WEALTH_PLUS should clear all default data
    yield put(
      updateBirthdate('', [], {
        value: 30,
        unit: 'year',
      })
    ) // Init birth date value
    yield put(selectGender('M')) // Init gender value
    yield put(updateRegularPremium(0, [])) // Init rpp value
    yield put(updateSumAssured(0, [])) // Init sum assured value
  }

  yield put(
    updateWealthSourceData({
      dataSource: 'DEFAULT',
      isValidSalePermission: false,
      isOpenedPDF: false,
      isOpenedLSTUPDF: false,
    })
  )
}

export function* validatePermission(action: Action): Generator<*, *, *> {
  const isUserOnline = yield select(getIsUserOnline)
  if (!isUserOnline) return NO_INTERNET

  const isUserLoggedIn = yield select(isLoggedIn)
  if (!isUserLoggedIn) {
    return { isValid: false, type: 'LoginRequired' }
  }

  try {
    const selectedDisplayProduct = action.payload
    const user = yield select(getIdentityUser)
    const userInfo = yield call(AuthApi.getUserInformation, user)
    if (!!userInfo) {
      yield put(setPositionCode(userInfo.party.positionCode))
      yield put(setRegistration(userInfo.party.registrations))
    }
    return yield call(validateSaleInvestmentPermission, selectedDisplayProduct, userInfo, user)
  } catch (error) {
    console.error('error', error)
    yield put(setPositionCode(null))
    yield put(setRegistration(null))
    return NO_INTERNET
  }
}

export function* validateOrdinaryPermission(action: Action): Generator<*, *, *> {
  const isUserOnline = yield select(getIsUserOnline)
  if (!isUserOnline) return NO_INTERNET

  const isUserLoggedIn = yield select(isLoggedIn)
  if (!isUserLoggedIn) {
    return { isValid: false, type: 'LoginRequired' }
  }

  try {
    const selectedDisplayProduct = action.payload
    const user = yield select(getIdentityUser)
    const userInfo = yield call(AuthApi.getUserInformation, user)
    if (!!userInfo) {
      yield put(setPositionCode(userInfo.party.positionCode))
      yield put(setRegistration(userInfo.party.registrations))
    }
    return yield call(validateUserLicense, selectedDisplayProduct, userInfo, user)
  } catch (error) {
    yield put(setPositionCode(null))
    yield put(setRegistration(null))
    return NO_INTERNET
  }
}

export function* validateInvestmentSalePermission(action: Action): Generator<*, *, *> {
  const { VALIDATE_IWEALTHY_SALE_PERMISSION } = yield call(getToggles)

  if (VALIDATE_IWEALTHY_SALE_PERMISSION) {
    yield put(validatingSalePermission())
    const result = yield validatePermission(action)
    if (!result.isValid) {
      return yield handleInvalidResult(result)
    }
    yield put(salePermissionValidationCompleted())
  }

  const selectedProductCode = yield select(getSelectedDisplayProductCode)
  if (selectedProductCode !== action.payload.code) {
    const payerRelation = yield select(getPayerRelation)
    const comingSelectedProduct = action.payload
    if (payerRelation === DEFAULTS.SPOUSE && comingSelectedProduct.payerSelection.length === 0) {
      yield put(clearPayerInformation())
      const insuredAge = yield select(getAge)
      if (insuredAge.value > 19) {
        yield put(clearPayerNeeded())
      } else {
        yield put(selectPayerRelation(DEFAULTS.PARENT))
      }
    }

    yield call(_clearWealthSourceData)
    yield put(selectProduct(action.payload))
  }
  yield put(allowProceedInvestment())
}

export function* validateOrdinarySalePermission(action: Action): Generator<*, *, *> {
  const { ENABLE_FORCE_LOGIN_ORDINARY_PRODUCT } = yield call(getToggles)
  const { ORDINARY_PRODUCT_FORCE_LOGIN_AVALIABLE } = yield call(getAppConfig)

  if (ENABLE_FORCE_LOGIN_ORDINARY_PRODUCT && ORDINARY_PRODUCT_FORCE_LOGIN_AVALIABLE.includes(action.payload.code)) {
    yield put(validatingOrdinarySalePermission())
    const result = yield validateOrdinaryPermission(action)
    if (!result.isValid) {
      return yield handleOrdinaryInvalidResult(result)
    }
    yield put(saleOrdinaryPermissionValidationCompleted())
  }

  const selectedProductCode = yield select(getSelectedDisplayProductCode)
  if (selectedProductCode !== action.payload.code) {
    const payerRelation = yield select(getPayerRelation)
    const comingSelectedProduct = action.payload
    if (payerRelation === DEFAULTS.SPOUSE && comingSelectedProduct.payerSelection.length === 0) {
      yield put(clearPayerInformation())
      const insuredAge = yield select(getAge)
      if (insuredAge.value > 19) {
        yield put(clearPayerNeeded())
      } else {
        yield put(selectPayerRelation(DEFAULTS.PARENT))
      }
    }

    yield call(_clearWealthSourceData)
    yield put(selectProduct(action.payload))
  }
  yield put(allowProceedOrdinary())
}

export function* validateNormalSalePermission(action: Action): Generator<*, *, *> {
  const selectedProductCode = yield select(getSelectedDisplayProductCode)
  if (selectedProductCode !== action.payload.code) {
    const payerRelation = yield select(getPayerRelation)
    const comingSelectedProduct = action.payload
    if (payerRelation === DEFAULTS.SPOUSE && comingSelectedProduct.payerSelection.length === 0) {
      yield put(clearPayerInformation())
      const insuredAge = yield select(getAge)
      if (insuredAge.value > 19) {
        yield put(clearPayerNeeded())
      } else {
        yield put(selectPayerRelation(DEFAULTS.PARENT))
      }
    }
  }

  yield call(_clearWealthSourceData)
  yield put(selectProduct(action.payload))
}

type InvalidType = PermissionType | 'LoginRequired' | 'NoInternet'

export function* handleInvalidResult(result: { type: InvalidType, isValid: boolean }): Generator<*, *, *> {
  const { type, isValid } = result
  if (type === 'NoInternet') {
    yield put(setNotificationMessage({ type: 'Warning', subType: 'NoInternet' }))
  } else if (type === 'LoginRequired') {
    yield put(loginRequest())
  } else {
    yield put(rejectInvestmentPermission({ type, isValid }))
  }
  yield put(salePermissionValidationCompleted())
}

export function* handleOrdinaryInvalidResult(result: { type: InvalidType, isValid: boolean }): Generator<*, *, *> {
  const { type, isValid } = result
  if (type === 'NoInternet') {
    yield put(setNotificationMessage({ type: 'Warning', subType: 'NoInternet' }))
  } else if (type === 'LoginRequired') {
    yield put(loginRequest())
  } else {
    yield put(rejectOrdinaryPermission({ type, isValid }))
  }
  yield put(saleOrdinaryPermissionValidationCompleted())
}

export function* onSelectProduct(history: *, action: Action): Generator<*, *, *> {
  const { ENABLE_REVISE_FNA } = yield call(getToggles)
  if (ENABLE_REVISE_FNA) {
    const isAddingInvestmentQuestionForNonILPNeedType = yield select(getIsAddingInvestmentQuestionForNonILPNeedType)
    const fnaMarketConductQuestionsType = yield select(getMarketConductQuestionsType)
    const isFnaNonInvestProductNeeded = fnaMarketConductQuestionsType === 'NON-ILP'

    const selectedProductCategory = get('category')(action.payload)
    const isSelectedProductInvestmentType = selectedProductCategory === 'INVESTMENT'

    if (
      !isAddingInvestmentQuestionForNonILPNeedType &&
      isFnaNonInvestProductNeeded &&
      isSelectedProductInvestmentType
    ) {
      yield [
        put(setAddingInvestmentQuestionForNonILPNeedType(true)),
        put(
          setNotificationMessage({
            type: 'ConfirmModal',
            title: 'แบบสอบถามเพิ่มเติม',
            message: `<p>เนื่องจากท่านเลือกผลิตภัณฑ์ประกันชีวิตควบการลงทุน</p>
          <span>กรุณากดยืนยัน เพื่อตอบแบบสอบถามเพิ่มเติม</span>`,
            customOnClick: () => {
              history.replace(fnaPath(`${PATH.INVESTMENT}${PATH.MARKET_CONDUCTS}`))
            },
          })
        ),
      ]
    } else if (isFnaNonInvestProductNeeded && !isSelectedProductInvestmentType) {
      yield [
        put(
          setFnaValue({
            path: 'marketConduct.questions.useSavingIn7Years',
            value: undefined,
          })
        ),
        put(
          setFnaValue({
            path: 'marketConduct.questions.riskIsAcceptable',
            value: undefined,
          })
        ),
        put(
          setFnaValue({
            path: 'marketConduct.questions.preferPayment',
            value: undefined,
          })
        ),
        yield put(setAddingInvestmentQuestionForNonILPNeedType(false)),
      ]
    }
  }

  yield take(UPDATE_AVAILABLE_RIDERS)
  yield put(selectedProduct(action.payload))
}

export function* sagas(history): Generator<*, *, Effect[]> {
  yield [
    takeLatest(VALIDATE_INVESTMENT_PRODUCT, validateInvestmentSalePermission),
    takeLatest(VALIDATE_ORDINARY_PRODUCT, validateOrdinarySalePermission),
    takeLatest(VALIDATE_NORMAL_PRODUCT, validateNormalSalePermission),
    takeLatest(SELECT_PRODUCT, onSelectProduct, history),
  ]
}
