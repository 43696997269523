//@flow
import { compose, lifecycle } from 'recompose'
import { ModalBody, ModalHeader, Button } from 'reactstrap'
import { connect } from 'react-redux'

import { Modal } from 'common-components/index'
import type { AppState } from 'quick-quote/reducers'
import { setAppValue } from '../../../apps/actions'
import { getCurrentApp } from 'e-submission/apps/selectors'
import { Link } from 'react-router-dom'
import { insuredValidateField } from '../../AppForm/FormValidation/ekycValidation'
import _ from 'lodash'
import { getToggles } from 'quick-quote/feature-toggles'

class InsuredAndPayerDetailsConfirmation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSkipEkyc: false,
    }
  }

  render() {
    const { isDataConfirmed, setEkycDataConfirmed, payer, insured } = this.props

    return (
      <Modal
        className={'insured-and-payer-details-confirmations-modal'}
        isOpen={
          !getToggles().ENABLE_EKYC_F2F_RECREATE_CASE &&
          !isDataConfirmed &&
          !insuredValidateField(payer).length &&
          !insuredValidateField(insured).length
        }
        size="md"
        modalClassName="default-bootstrap remote-selling-modal"
      >
        <ModalHeader className={'header-modal'}>กรุณายืนยันเพื่อเข้าสู่ขั้นตอนการยืนยันตัวตน</ModalHeader>
        <ModalBody>
          <div className={'normal-text detail-text'}>
            ท่านได้ตรวจสอบความถูกต้องของข้อมูลแล้วและต้องการไปยังหน้ายืนยันตัวตน ใช่หรือไม่?
          </div>
          <div className={'normal-text detail-text remark-text'}>
            หมายเหตุ: เมื่อคลิกยืนยันแล้วท่านจะไม่สามารถแก้ไขข้อมูลได้อีก
          </div>

          <div className={'normal-text'}>
            <Link to="insured">
              <Button className="back-button">ย้อนกลับ</Button>
            </Link>
            <Button
              color={'primary'}
              onClick={() => {
                setEkycDataConfirmed()
              }}
              className="primary"
            >
              ยืนยัน
            </Button>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}

const mapStateToIdentifyEkycRespondingModal = (state: AppState) => {
  const app = getCurrentApp(state)
  const isDataConfirmed = _.get(app, `ekycInfo.isDataConfirmed`, false)
  return {
    isDataConfirmed,
    app,
    insured: app.insured,
    payer: app.payer,
  }
}

const mapDispatchToIdentifyEkycRespondingModal = (dispatch: Dispatch<*>) => ({
  setEkycDataConfirmed: (key, value) => {
    dispatch(setAppValue(`ekycInfo.isDataConfirmed`, true))
  },
})

export default compose(
  connect(mapStateToIdentifyEkycRespondingModal, mapDispatchToIdentifyEkycRespondingModal),
  lifecycle({
    componentDidMount() {},
    componentDidUpdate(prevProps) {},
    UNSAFE_componentWillReceiveProps(nextProps) {},
  })
)(InsuredAndPayerDetailsConfirmation)
