//@flow

import moment from 'moment'
import { get, flow, pick, take, join } from 'lodash/fp'
import { fromBuddhistToChristian } from '../../sagas/policy-payload/primitive-selector'

import { requestWrapper } from 'e-submission/domain/data'
import { base64ToBlob } from 'e-submission/utils'
import applicationCordovaWrapper from 'e-submission/domain/data/application-cordova-wrapper'
import { isCordova } from 'e-submission/utils'
import { PAYMENT_TYPE } from 'e-submission/domain/data-model/constants'
import { getAppConfig } from 'deploy-env/app-config'
import { getToggles } from 'quick-quote/feature-toggles'
import { createEdaErrorMockQueryString } from './utils'

export const submitRecurringPayment = (user, payment = {}) => {
  const params = {
    status: 'signed',
  }
  return requestWrapper
    .patchRequest(`esub/policies/${payment.policyId}/recurring-payment`, user, params)
    .then(({ data }) => data)
}

export const updateRecurringPayment = (user, payment = {}) => {
  const initAccountHolder = {
    title: get('title.text', payment),
    firstName: get('firstName', payment),
    lastName: get('lastName', payment),
    idNo: get('idNo.citizenId', payment),
    passport: get('idNo.passport', payment),
    idType: get('idType.value', payment),
    mobileNumber: get('mobileNumber', payment),
    email: get('email', payment),
    relationshipToPayer: get('relations.text', payment),
  }
  const isTitleOther = get('title.value', payment) === 'other'
  const isOtherRelationship = get('relations.text', payment) === 'อื่นๆ'

  const accountHolderPayload = getToggles().ENABLE_EDA
    ? {
        ...initAccountHolder,
        title: isTitleOther ? 'อื่นๆ' : initAccountHolder.title, // [นาย, นาง, น.ส., อื่นๆ]
        titleRemark: isTitleOther ? get('title.text', payment) : '',
        relationshipRemark: isOtherRelationship ? get('otherRelationRemark', payment) : '',
        typeDA: get('typeDA', payment), // ['ONLINE' => DA online/eDA, 'FORM' => DA form]
        daStatus: get('typeDA', payment) === 'FORM' ? '' : 'Pending',
      }
    : initAccountHolder

  const params = {
    accountHolder: accountHolderPayload,
    recurringPayment: {
      bank: flow(get('bank'), pick(['text', 'value']))(payment),
      accountType: flow(get('accountType'), pick(['text', 'value']))(payment),
      branch: flow(get('bankBookNumber'), take(3), join(''))(payment),
      accountNumber: get('bankBookNumber', payment),
    },
  }
  return requestWrapper
    .putRequest(`esub/policies/${payment.policyId}/recurring-payment`, user, params)
    .then(({ data }) => data)
}

export const signPayment = (user, payment = {}) => {
  const params = {
    receiptNo: payment.receiptNo,
    signature: payment.data,
  }

  return requestWrapper.postRequest(`esub/policies/${payment.policyId}/payments`, user, params).then(({ data }) => data)
}

export const updateReceipt = (user, payment = {}) => {
  let params = {}

  if (payment.type === PAYMENT_TYPE.EDC) {
    params = {
      approvalCode: get('approvalCode', payment),
      transactionDate: moment(fromBuddhistToChristian(payment.transactionDate), 'DD/MM/YYYY').format('YYYY-MM-DD'),
    }
  } else {
    params = {
      bankCode: get('bank.value', payment),
      bankSlipDate: moment(fromBuddhistToChristian(payment.bankReceiptDate), 'DD/MM/YYYY').format('YYYY-MM-DD'),
    }
  }

  return requestWrapper
    .patchRequest(`esub/policies/${payment.policyId}/payments/${payment.receiptNo}`, user, params)
    .then(({ data }) => data)
}

export const updateUnsignReceipt = (user, payment = {}) => {
  const isQrCrossBankEnable = getToggles().ENABLE_QR_CROSSBANK
  const isEcbrSystemizationEnable = getToggles().ENABLE_ECBR_SYSTEMIZATION

  let params = {}

  if (payment.type === PAYMENT_TYPE.NETBANK && isQrCrossBankEnable) {
    params = {
      ...params,
      type: 'QR Code',
      bankCode: isQrCrossBankEnable ? 'KTB7' : get('bank.value', payment),
    }
  } else if (payment.type === PAYMENT_TYPE.EDC) {
    params = {
      approvalCode: get('approvalCode', payment),
      transactionDate: moment(fromBuddhistToChristian(payment.transactionDate), 'DD/MM/YYYY').format('YYYY-MM-DD'),
    }
  } else {
    params = {
      bankCode: get('bank.value', payment),
      bankSlipDate: moment(fromBuddhistToChristian(payment.bankReceiptDate), 'DD/MM/YYYY').format('YYYY-MM-DD'),
    }
  }

  if (isEcbrSystemizationEnable) {
    params = {
      ...params,
      receiptNo: payment.receiptNo,
    }
  }
  console.info('Service updateUnsignReceipt params', params)

  return requestWrapper
    .patchRequest(`esub/policies/${payment.policyId}/unsign-payments/${payment.receiptNo}`, user, params)
    .then(({ data }) => data)
}

export const chargeCreditCard = (user, omiseToken, amount, policyId) => {
  const params = {
    amount: amount,
    omiseToken: omiseToken,
    currency: 'thb',
    type: PAYMENT_TYPE.CREDITCARD,
  }
  return requestWrapper
    .postRequest(`esub/policies/${policyId}/payments/credit-card`, user, params)
    .then(({ data }) => data)
}

export const retrieveCreditCard = (user, chargeId, policyId) => {
  return requestWrapper
    .getRequest(`esub/policies/${policyId}/payments/credit-card/${chargeId}`, user)
    .then(({ data }) => data)
}

export const createReceipt = (user, payment = {}) => {
  const params = payment.receipts
  return requestWrapper
    .postRequest(`esub/policies/${payment.policyId}/payment-receipts/bulk`, user, params)
    .then(({ data }) => data)
}

export const checkExistUnsignedPaymentReceipt = (user, payment = {}) => {
  return requestWrapper
    .getRequest(`esub/policies/${payment.policyId}/checking-unsigned-payments`, user)
    .then(({ data }) => data)
}

export const removeReceipt = (user, payment = {}) => {
  const params = payment.receipts

  return requestWrapper
    .deleteRequest(`esub/policies/${payment.policyId}/payment-receipts`, user)
    .then(({ data }) => data)
}

export const initOmise = () => {
  if (isCordova || window.Omise) {
    return Promise.resolve()
  }

  return new Promise((resolve, reject) => {
    const id = 'omisejs'

    const oldElement = document.getElementById(id)
    if (oldElement) {
      oldElement.parentNode.removeChild(oldElement)
    }

    let scriptTag = document.createElement('script')
    scriptTag.id = id
    scriptTag.onload = resolve
    scriptTag.onerror = reject
    scriptTag.src = 'https://cdn.omise.co/omise.js'

    document.head.appendChild(scriptTag)
  }).then(() => {
    window.Omise.setPublicKey(getAppConfig().OMISE_PUBLIC_KEY)
  })
}

export type CreditCard = {
  name: string,
  number: string,
  expiration_month: number,
  expiration_year: number,
  security_code: number,
}

export const getCreditCardPaymentOmiseToken = (cardInformation: CreditCard) => {
  if (isCordova) {
    return applicationCordovaWrapper.callOmise({
      ...cardInformation,
      expirationMonth: cardInformation.expiration_month,
      expirationYear: cardInformation.expiration_year,
      securityCode: cardInformation.security_code,
    })
  } else {
    return new Promise((resolve, reject) => {
      window.Omise.createToken('card', cardInformation, (statusCode, response) => {
        if (statusCode === 200) {
          resolve(response.id)
        } else {
          reject(response)
        }
      })
    })
  }
}

export const previewPayment = (user, payment = {}) => {
  return Promise.resolve()
}

export const getPayment = (user, payment = {}) => {
  let url = `esub/policies/${payment.policyId}/payment-receipts/${payment.receiptNo}`
  if (payment.checked) {
    url = `esub/policies/${payment.policyId}/payments/${payment.receiptNo}/receipt`
  }

  return requestWrapper
    .getRequest(url, user)
    .then(({ data }) => data)
    .then(({ data, content }) => base64ToBlob(data || content, 'application/pdf'))
}

export const getBase64Payment = (user, payment = {}) => {
  let url = `esub/policies/${payment.policyId}/payment-receipts/${payment.receiptNo}`
  if (payment.checked) {
    url = `esub/policies/${payment.policyId}/payments/${payment.receiptNo}/receipt`
  }
  return requestWrapper.getRequest(url, user).then(({ data }) => data)
}

export const listPayment = (user, policyId) => {
  return requestWrapper.getRequest(`esub/policies/${policyId}/payments`, user).then(({ data }) => data)
}

export const listPaymentTransactions = (user, policyId) => {
  return requestWrapper.getRequest(`esub/policies/${policyId}/payment-transactions`, user).then(({ data }) => data)
}

export const listReceipts = (user, policyId) => {
  return requestWrapper.getRequest(`esub/policies/${policyId}/payment-receipts`, user).then(({ data }) => data)
}

export const listCybersourceReceipts = (user, policyId) => {
  return requestWrapper
    .getRequest(`esub/policies/${policyId}/payment-receipts/cybersource`, user)
    .then(({ data }) => data)
}

export const checkPaidPolicyNumber = (user, policyId) => {
  const data = {
    policyId: policyId,
    policyNumber: policyId,
  }
  return requestWrapper
    .postRequest(`esub/policies/${policyId}/payments/checking-paid-policy`, user, data)
    .then(({ data }) => data)
}

export const signCybersourceCreditCard = (user, policyId, amount, productType, insured, isRemoteSelling) => {
  const remoteSellingOverrides = isRemoteSelling && {
    source: 'AR',
    originalSourcePrefix: 'AZ',
  }
  const data = {
    amount: amount,
    policyId: policyId,
    policyNumber: policyId,
    productType: productType,
    type: PAYMENT_TYPE.CREDITCARD,
    transactionType: 'sale',
    source: 'AZ',
    ...insured,
    ...remoteSellingOverrides,
  }
  return requestWrapper
    .postRequest(`esub/policies/${policyId}/payments/credit-card/cybersource/sign`, user, data)
    .then(({ data }) => data)
}

export const updateCybersourceStatus = (user, policyId, referenceNumber, status) => {
  const data = {
    referenceNumber: referenceNumber,
    policyId: policyId,
    status,
  }
  return requestWrapper
    .putRequest(`esub/policies/${policyId}/payments/credit-card/cybersource/${referenceNumber}/status`, user, data)
    .then(({ data }) => data)
}

export const getCybersourceTransactionControl = (user, policyId) => {
  console.log('service : getCybersourceTransactionControl, policyId :' + policyId)
  return requestWrapper
    .getRequest(`esub/policies/${policyId}/payments/credit-card/cybersource/transaction-control`, user)
    .then(({ data }) => data)
}

export const retrieveCybersourceCreditCard = (user, query) => {
  console.log('service : retrieveCybersourceCreditCard, query :' + query)
  return requestWrapper
    .getRequest(
      `esub/policies/${query.policyId}/payments/credit-card/cybersource/${query.referenceNumber}`,
      user,
      query
    )
    .then(({ data }) => data)
}

export const retrieveCybersourceCreditCardPaymentGateway = (user, query) => {
  console.log('service : retrieveCybersourceCreditCardPaymentGateway, query :' + query)

  return requestWrapper
    .getRequest(
      `esub/policies/${query.policyId}/payment-gateway/credit-card/cybersource/${query.referenceNumber}`,
      user,
      query
    )
    .then(({ data }) => data)
}

export const verifyQuickPayStatus = (user, policyId) => {
  console.log('service : verifyQuickPayStatus, policyId :' + policyId)
  return requestWrapper.getRequest(`quickpay/payment/status/${policyId}`, user).then(({ data }) => data)
}

export const createPaymentLink = (user, data) => {
  console.log('service : createPaymentLink, data :' + data)
  return requestWrapper.postRequest(`quickpay/payment`, user, data).then(({ data }) => data)
}

export const createCCPAlink = (user, data) => {
  console.log('service : createCCPAlink, data :' + data)
  return requestWrapper
    .postRequest(`quickpay/ccpa/create-single-subscription-link`, user, data)
    .then(({ data }) => data)
}

export const verifyCCPAStatus = (user, policyId) => {
  console.log('service : verifyCCPAStatus, policyId :' + policyId)
  return requestWrapper.getRequest(`quickpay/ccpa/status/${policyId}`, user).then(({ data }) => data)
}

export const getBasicPlanCode = () => {
  const user = JSON.parse(localStorage.getItem('userInfo')) || {}
  console.log('service : getBasicPlanCode')
  return requestWrapper.getRequest(`quickpay/payment/basic-plan-code`, user).then(({ data }) => data)
}

export const createEdaRequest = (data) => {
  const user = JSON.parse(localStorage.getItem('userInfo')) || {}
  console.log('service : createEdaRequest')

  const errorQueryString = createEdaErrorMockQueryString()

  return requestWrapper
    .postRequest(`eda-integration/register${errorQueryString}`, user, { ...data })
    .then(({ data }) => data)
}

export const getEdaStatusRequest = (data) => {
  const user = JSON.parse(localStorage.getItem('userInfo')) || {}
  console.log('service : getEdaStatusRequest')

  const errorQueryString = createEdaErrorMockQueryString()

  return requestWrapper
    .postRequest(`eda-integration/status${errorQueryString}`, user, { ...data })
    .then(({ data }) => data)
}
