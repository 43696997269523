import moment from 'moment'
import _, { isNil, isObjectLike, size } from 'lodash'
import { buddhistToChristianDate, isBuddhistYear } from 'core/service/lib/date'

const Validate = (pattern) => (text) => pattern.test(text)

export const isLengthLessThanEqual = (maxLength) => Validate(new RegExp(`^.{1,${maxLength}}$`))
export const isLengthLimit = (maxLength) => Validate(new RegExp(`^.{0,${maxLength}}$`))
export const isLengthEqualTo = (fixedLength) => Validate(new RegExp(`^.{${fixedLength}}$`))
export const isEmpty = (value) => (isObjectLike(value) ? size(value) === 0 : isNil(value) || value === '')
export const isNotEmpty = (value) => !isEmpty(value)
export const isPersonName = Validate(/^[a-zA-Z\u0E01-\u0E5B\s\.\-]+$/)
export const isPersonNameEN = Validate(/^[a-zA-Z\s\.\-]+$/)
export const isTextAndNumber = Validate(/^[a-zA-Z0-9\u0E01-\u0E5B]+$/)
export const isTextAndNumberEN = Validate(/^[a-zA-Z0-9]+$/)
export const isInteger = Validate(/^[\d]*$/)
export const isDecimal = (value) => value % 1 === 0
export const isEmail = Validate(
  // /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/
  /^[A-Za-z0-9]+(?:[._-][A-Za-z0-9]+)*@[A-Za-z0-9]+(?:[.-][A-Za-z0-9]+)*\.[a-z]{2,}$/
)

export const isPassport = Validate(/^[a-zA-Z0-9\s]+$/)
export const isIDCardNo = (idCardNo = '') => {
  if (idCardNo.length !== 13) return false
  let sum = 0
  for (let i = 0; i < 12; i++) {
    sum += parseFloat(idCardNo.charAt(i)) * (13 - i)
  }
  return (11 - (sum % 11)) % 10 !== parseFloat(idCardNo.charAt(12)) ? false : true
}
export const isMoreThan = (value, maxValue) => value <= maxValue
export const isFutureDate = (date) => {
  date = isBuddhistYear(date) ? buddhistToChristianDate(date) : date

  const formatDate = 'DD/MM/YYYY'
  const currentDate = moment().format(formatDate)
  const inputDate = moment(date, formatDate, true)
  const today = moment(currentDate, formatDate, true)

  if (!inputDate.isValid()) return false

  const diff = today.diff(inputDate, 'days')

  return diff < 0 ? false : true
}

export const isBetweenEqual = (min, max) => (value) => value >= min && value <= max

export const hasActiveLicense = (code) => (licenses) => {
  const license = _.find(licenses, { typeCode: code })
  return _.get(license, 'state') === 'active'
}

export const hasActiveOrInapplicableLicense = (code) => (licenses) => {
  const license = _.find(licenses, { typeCode: code })
  return _.get(license, 'state') === 'active' || _.get(license, 'state') === 'inapplicable'
}

// export const isText = Validate(/^[a-zA-Z\u0E01-\u0E5B\s]+$/)
// export const isPersonNameEN = Validate(/^[a-zA-Z\s\.\-]+$/)
// export const isPersonNameTH = Validate(/^[\u0E01-\u0E5B\s\.\-]+$/)
// export const isPersonNameContainsTH = text => Validate(/^[a-zA-Z\u0E01-\u0E5B\s\.\-\']+$/)(text) && Validate(/[\u0E01-\u0E5B]{1,}/)(text)
// export const isNumber = Validate(/^[\d]*(\.)?[\d]*$/)
// export const isNumberThreeDigit = Validate(/^[\d]{3}$/)
// export const isCreditCard = Validate(/^[\d]{16}$/)
// export const isPostal = Validate(/^[\d]{5}$/)
// export const isCurrency = Validate(/(?=.)^\$?(([1-9][0-9]{0,2}(,[0-9]{3})*)|[0-9]+)?(\.[0-9]{1,2})?$/)

// export const isTempReceiptNo = (trNo = '') => {
//   if(trNo.length < 8) {
//     return undefined
//   }else if(trNo.length > 8) {
//     trNo = trNo.substring(0,8)
//   }

//   let sum = 0
//   for(let i = 0; i < 7; i++) {
//     sum += parseFloat(trNo.charAt(i))* ((i % 2) + 1)
//   }

//   let checkDigit = sum % 11

//   if (checkDigit >= 10) {
//     checkDigit = 0
//   }

//   return (checkDigit === parseFloat(trNo.charAt(7)))
// }

export const FILE_MAGIC_NUMBERS = {
  PNG: /^89504e47/,
  JPG: /^ffd8ff/,
  TIF: /^49492a0/,
  TIFF: /^4d4d02a/,
}

export const IMAGE_FORMATS = {
  PNG: 'image/png',
  JPEG: 'image/jpeg',
  JPG: 'image/jpg',
  TIFF: 'image/tiff',
  TIF: 'image/tif',
  IN_VALID: 'invalid image format',
}

export const AVAILABLE_IMAGE_FORMATS = [
  IMAGE_FORMATS.PNG,
  IMAGE_FORMATS.JPG,
  IMAGE_FORMATS.JPEG,
  IMAGE_FORMATS.TIFF,
  IMAGE_FORMATS.TIF,
]

export const getImageFormat = (arrayBuffer) => {
  var arr = new Uint8Array(arrayBuffer).subarray(0, 4)
  var header = ''

  for (var i = 0; i < arr.length; i++) {
    header += arr[i].toString(16)
  }

  switch (true) {
    case FILE_MAGIC_NUMBERS.PNG.test(header):
      return IMAGE_FORMATS.PNG
    case FILE_MAGIC_NUMBERS.JPG.test(header):
      return IMAGE_FORMATS.JPG
    case FILE_MAGIC_NUMBERS.TIFF.test(header):
    case FILE_MAGIC_NUMBERS.TIF.test(header):
      return IMAGE_FORMATS.TIFF
    default:
      return IMAGE_FORMATS.IN_VALID
  }
}

export const isBlob = (value) => {
  if (typeof Blob === 'undefined') {
    return false
  }

  return value instanceof Blob || Object.prototype.toString.call(value) === '[object Blob]'
}
