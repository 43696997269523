// @flow
import { connect } from 'react-redux'
import type { AppState } from 'quick-quote'
import MESSAGES from 'core/data-model/constants/messages'
import { isBancAgent } from 'identity/selectors'
import { FormFeedback, FormGroup } from 'reactstrap'

const LBLicenseInfoContainer = ({ isBancAgent }: *) =>
  !isBancAgent ? null : (
    <FormGroup id="lb-license-info">
      <FormFeedback className="info-message attached-icon">
        <p>{MESSAGES.LB_LICENSE_INFO}</p>
      </FormFeedback>
    </FormGroup>
  )

const mapStateToProps = (state: AppState): { isBancAgent: boolean } => ({
  isBancAgent: isBancAgent(state),
})
export default connect(mapStateToProps)(LBLicenseInfoContainer)
