// @flow
import { connect } from 'react-redux'
import type { AppState } from 'quick-quote/reducers'
import BenefitIllustration from '../../../components/benefit-illustration/container'

import { isBlockedEnteringESub } from 'quick-quote/selectors'
import { getSelectedDisplayProductCode } from 'quick-quote/product-selection/selectors'
import { getSections, getMenus } from './selectors'
import { getSections as get12PLSection, getMenus as get12PLMenus } from '../../customs/12pl/selectors'
import { getSections as getPLBSection, getMenus as getPLBMenus } from '../../customs/plb/selectors'
import { getSections as getS7Section, getMenus as getS7Menus } from '../../customs/s7/selectors'
import {
  getSections as getLifeProtect18Section,
  getMenus as getLifeProtect18Menus,
} from '../../customs/life-protect-18/selectors'

import { getSections as getLifeReadySection, getMenus as getLifeReadyMenus } from '../../customs/life-ready/selectors'

import {
  getSections as getLifeEnsure10Section,
  getMenus as getLifeEnsure10Menus,
} from '../../customs/life-ensure-10/selectors'

const getSectionByProductCode = (state: AppState) => {
  const productCode = getSelectedDisplayProductCode(state)
  switch (productCode) {
    case 'WL88J':
    case 'WL88S':
      return getLifeProtect18Section(state)
    case 'W85P10J':
    case 'W85P10S':
      return getLifeEnsure10Section(state)
    case '12PL':
      return get12PLSection(state)
    case 'PLB05':
    case 'PLB10':
    case 'PLB12':
    case 'PLB15':
      return getPLBSection(state)
    case 'CIWLS39':
    case 'CIWLS50':
    case 'CIWLS65':
    case 'CIWLM39':
    case 'CIWLM50':
    case 'CIWLM60':
      return getS7Section(state)
    case 'W99FU06':
    case 'W99FU12':
    case 'W99FU18':
    case 'W99FH18':
    case 'W99FU99':
    case 'W99FH99':
    case 'W99F99H':
    case 'W99F99M':
    case 'KOOMVER-COVER-1':
    case 'KOOMVER-COVER-2':
    case 'KOOMVER-COMPENSATE-1':
    case 'KOOMVER-COMPENSATE-2':
    case 'H99F06A':
    case 'H99F12A':
    case 'H99F18A':
      return getLifeReadySection(state)
    default:
      return getSections(state)
  }
}

const getMenuByProductCode = (state: AppState) => {
  const productCode = getSelectedDisplayProductCode(state)
  switch (productCode) {
    case 'WL88J':
    case 'WL88S':
      return getLifeProtect18Menus(state)
    case 'W85P10J':
    case 'W85P10S':
      return getLifeEnsure10Menus(state)
    case '12PL':
      return get12PLMenus(state)
    case 'PLB05':
    case 'PLB10':
    case 'PLB12':
    case 'PLB15':
      return getPLBMenus(state)
    case 'CIWLS39':
    case 'CIWLS50':
    case 'CIWLS65':
    case 'CIWLM39':
    case 'CIWLM50':
    case 'CIWLM60':
      return getS7Menus(state)
    case 'W99FU06':
    case 'W99FU12':
    case 'W99FU18':
    case 'W99FH18':
    case 'W99FU99':
    case 'W99FH99':
    case 'W99F99H':
    case 'W99F99M':
    case 'KOOMVER-COVER-1':
    case 'KOOMVER-COVER-2':
    case 'KOOMVER-COMPENSATE-1':
    case 'KOOMVER-COMPENSATE-2':
    case 'H99F06A':
    case 'H99F12A':
    case 'H99F18A':
      return getLifeReadyMenus(state)
    default:
      return getMenus(state)
  }
}

const mapStateToProps = (state: AppState) => ({
  sections: getSectionByProductCode(state),
  menus: getMenuByProductCode(state),
  isBlockedEnteringESub: isBlockedEnteringESub(state),
})

const mapDispatchToProps = () => ({})

export const BenefitIllustrationContainer = connect(mapStateToProps, mapDispatchToProps)(BenefitIllustration)
