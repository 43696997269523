// @flow
import type { DisplayProduct } from 'core/service/display-product'
import ProductBenefitsSummary from './product-benefits-summary'
import MRTAProductBenefits from './product-benefits-mrta'

export type ProductBenefitProps = {
  displayProduct: DisplayProduct,
  sumAssured: number,
  basicPremium: number,
  coverageTerm: number,
  loanTerm: number,
}

const ProductBenefits = ({ displayProduct, sumAssured, basicPremium, coverageTerm, loanTerm }: ProductBenefitProps) => {
  return (
    <div id="product-benefits" className="benefit group">
      <ProductBenefitsSummary
        displayProduct={displayProduct}
        sumAssured={sumAssured}
        basicPremium={basicPremium}
        coverageTerm={coverageTerm}
        loanTerm={loanTerm}
      />

      <MRTAProductBenefits displayProduct={displayProduct} sumAssured={sumAssured} />
    </div>
  )
}

export default ProductBenefits
