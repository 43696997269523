// @flow
import Mustache from 'mustache'
import MESSAGES from 'core/data-model/constants/bi-messages'
import { createPdfDocument } from '../../document-creation'
import { createCommonPdf } from '../common/pdf-document'
import { sections } from './sections'
import { commonSection } from 'core/service/pdf-generation/products/common/sections'
import Template from './template'
import { isPlatinum } from 'core/service/benefit-illustration/products/flexi-health'
import { isProductForReuseFlexiHealth } from 'quick-quote/product-flexi-health/benefit-illustration/selectors'
import type { FlexiHealthProps } from './index'
import type { DisplayProduct } from 'core/service/display-product'

export const basicPlanDetailsLabelFn = (basicPlan: DisplayProduct): string =>
  Mustache.render(MESSAGES.BASIC_PLAN_DETAILS, {
    name: basicPlan.fullNameThai || basicPlan.nameThai,
    code: basicPlan.basicPlanCode,
  })

export const basicSummaryPlanDetailsLabelFn = (basicPlan: DisplayProduct): string =>
  Mustache.render(MESSAGES.FLEXI_HEALTH_BASIC_PLAN_DETAILS, {
    basicplan: isProductForReuseFlexiHealth(basicPlan) ? basicPlan.basicPlanName : MESSAGES.BASIC_PLAN,
    name: isProductForReuseFlexiHealth(basicPlan) ? '' : basicPlan.fullNameThai || basicPlan.nameThai,
  })

export const createMiniBIDocDefinition = async (props: FlexiHealthProps) => {
  const pdfTemplate = await Template(props)
  return {
    content: [
      ...sections.profile({ ...props, basicPlanLabel: basicSummaryPlanDetailsLabelFn }),
      ...commonSection.productBenefits({ ...props, basicPlanDetailsLabelFn }),
      ...commonSection.riders(props),
      ...sections.policyValueTable({ ...props, title: MESSAGES.POLICY_VALUE_TABLE }),
      sections.flexiHealthlegalConditionDisclaimerMarketConduct(props),
      sections.flexiHealthTaxBenefit(props),
      sections.flexiHealthLegalDisclaimer({
        isPlatinum: isPlatinum({ riders: props.riders }),
      }),
    ],
    ...pdfTemplate,
  }
}

export const createFullBIDocDefinition = async (props: FlexiHealthProps) => {
  const pdfTemplate = await Template(props)
  const paymentChannels = await commonSection.paymentChannels(props)
  return {
    content: [
      ...sections.profile({ ...props, basicPlanLabel: basicSummaryPlanDetailsLabelFn }),
      ...commonSection.productBenefits({ ...props, basicPlanDetailsLabelFn }),
      ...commonSection.riders(props),
      commonSection.basicPlanCondition,
      ...sections.policyValueTable({ ...props, title: MESSAGES.POLICY_VALUE_TABLE }),
      sections.flexiHealthTaxBenefit(props),
      sections.flexiHealthlegalConditionDisclaimerMarketConduct(props),
      sections.flexiHealthLegalDisclaimer({
        isPlatinum: isPlatinum({ riders: props.riders }),
      }),
      paymentChannels,
      commonSection.signature(props),
    ],
    ...pdfTemplate,
  }
}

const createFullBIPdf = createPdfDocument(createFullBIDocDefinition)
const createMiniBIPdf = createPdfDocument(createMiniBIDocDefinition)

export const createFlexiHealthPdf = async (props: FlexiHealthProps) => {
  return await createCommonPdf(props, createFullBIPdf, createMiniBIPdf)
}
