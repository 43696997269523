// @flow
import _ from 'lodash'
import riderMessages from 'core/data-model/constants/validation-messages'
import type { SumAssuredTiersRule } from 'core/data-model/rider/rules'
import type { RiderEligibilities } from 'core/data-model/rider'
import type { ValidationMessage } from 'core/data-model/validation'

export const overBasicSumassured = (
  rule: SumAssuredTiersRule,
  eligibilities: RiderEligibilities
): ValidationMessage => {
  let errorMessage = ''

  if (eligibilities.basicSumAssured > 0 && eligibilities.basicSumAssured > rule.validSumassured) {
    let messageId = `RULE_RIDER_${eligibilities.rider.code}_OVER_SUM_ASSURED`
    errorMessage = _.get(riderMessages, messageId) || riderMessages.RULE_MESSAGE_NOT_FOUND
  }

  return {
    type: rule.validationType,
    message: errorMessage,
  }
}
