//@flow
import type { AnalyticsEventType } from 'analytics/components'
import { WithAnalytics } from 'analytics/components'
import { NavLink } from 'react-router-dom'

export type Tab = {
  title: string,
  path: string,
  id: string,
  disabled?: boolean,
  hide?: boolean,
  component: null | Function,
  analyticsEventType?: AnalyticsEventType,
}

export const NavigationItem = ({ tab }: { tab: Tab }) => {
  if (tab.hide) return null
  if (!tab.disabled) {
    return (
      <NavLink id={`${tab.id}-link`} activeClassName="active" to={tab.path}>
        <WithAnalytics eventType={tab.analyticsEventType} expectedEventType="BIEvent">
          <span className="title-text">{tab.title}</span>
        </WithAnalytics>
      </NavLink>
    )
  }
  return (
    <a id={`${tab.id}-link`} className="disabled">
      <span className="title-text">{tab.title}</span>
    </a>
  )
}
