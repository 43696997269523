//@flow
import { withRouter } from 'react-router'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { MainHeader, Nav } from 'quick-quote/components'
import ToggleSwitcher from 'e-submission/components/Layout/ToggleSwitcher'
import { getToggles } from '../feature-toggles'
import { isEsubmissionPath, isPortalPath, isProductSelectionPath } from './navigations/utils'
import MediaControls from './media-controls'
import RemoteSellingOverlay from './remote-selling-overlay'

type MainLayoutProps = {
  location: { pathname: string },
  children: React$Element<*>[],
  canProceedToInsuredInformation: boolean,
  canProceedToCoveragePlan: boolean,
  canProceedToBenefitIllustration: boolean,
  productCategory: string,
  canProceedToRiskQuestionnaire: boolean,
  canProceedToFundAndCoveragePlan: boolean,
  canProceedToInvestmentBenefitIllustration: boolean,
  onBackToHome: () => void,
  selectedRemoteSelling: boolean,
  inCall: boolean,
  version: {
    appVersion: string,
    fetching: boolean,
    installing: boolean,
    error: Object,
  },
}

class _MainLayout extends React.Component<MainLayoutProps, *> {
  constructor(props: MainLayoutProps) {
    super(props)
    this.state = {
      pages: null,
    }
  }

  setPages = (pages) => {
    this.setState({
      pages,
    })
  }

  getChildContext() {
    return {
      setPages: this.setPages,
    }
  }

  render() {
    const { location, version, onBackToHome, selectedRemoteSelling, history, inCall } = this.props

    const { pages } = this.state

    let templateClassName = 'product-selected'

    if (isProductSelectionPath(location.pathname)) {
      templateClassName = 'product-selection'
    } else if (isEsubmissionPath(location.pathname)) {
      templateClassName = 'e-submission-page'
    } else if (isPortalPath(location.pathname)) {
      templateClassName = 'portal'
    }

    if (getToggles().ENABLE_FNA_FLOW) {
      templateClassName = `${templateClassName} fna`
    }

    return (
      <div id="main-wrapper">
        <header id="main-header" className={`header ${templateClassName}`}>
          <MainHeader
            version={version}
            onBackToHome={onBackToHome}
            selectedRemoteSelling={selectedRemoteSelling}
            inCall={inCall}
          />
          <Nav location={location} pages={pages} />
        </header>
        <div id="main-container" className={templateClassName}>
          {selectedRemoteSelling && <MediaControls selectedRemoteSelling={selectedRemoteSelling} history={history} />}
          {selectedRemoteSelling && <RemoteSellingOverlay isEsub={isEsubmissionPath(location.pathname)} />}
          {this.props.children}
        </div>
        <ToggleSwitcher />
      </div>
    )
  }
}

_MainLayout.childContextTypes = {
  setPages: PropTypes.func,
}

export const MainLayout = withRouter(_MainLayout)

export const isProductInvestmentPath = (urlPath: string) => {
  return _.includes(urlPath, 'investment')
}
