// @flow

import _ from 'lodash'

import type { EditRiderSelectedPlan } from 'quick-quote/product-common/coverage-plan/actions'
import type { SumAssured$ } from 'core/data-model/basic-plan'

import {
  getRiderSumAssured,
  calculateRiderPremium,
  getSelectedRiderPlan,
  validateRiders,
  isRiderBelongToRpudr,
} from 'core/service/rider/rider'
import { getSelectedDisplayProductQuery } from 'quick-quote/product-selection/selectors'
import { getUserAgentType, getValidUserLicenses, isAllowOptionalIHealtyUltra } from 'identity/selectors'
import {
  getBasicPremium$,
  getSumAssured$,
  getSelectedModelFactorValue,
  getSelectedModelFactorPeriod,
  getSelectedModelFactorID,
  getAvailableRiders,
  hasMHPRiderAvailableRider,
} from 'quick-quote/product-common/coverage-plan/selectors'
import {
  getRiderSelectedPlanCodeMHP,
  getSelectedPlanCodeRiderMultiPlanCodeMHP,
} from 'quick-quote/product-ihealthy-ultra/coverage-plan/selectors'
import {
  getSelectedModelFactorID as getInvestmentSelectedModelFactorID,
  getSelectedModelFactorPeriod as getInvestmentSelectedModelFactorPeriod,
  getSelectedModelFactorILPValue,
  getRegularPremium,
  getSumAssured,
} from 'quick-quote/product-investment/coverage-plan/selectors'
import { getRiskiestOccupationFactorForRiders, getRiskiestOccupation } from 'core/service/insured'
import { getRiderPremiumRates } from 'core/service/rider/premium-rate'
import {
  UPDATE_AVAILABLE_RIDERS,
  EDIT_RIDER_PREMIUM,
  EDIT_RIDER_MODULE_PREMIUM,
  EDIT_BASIC_PREMIUM_STATE,
  EDIT_SUM_ASSURED,
  SELECT_MODEL_FACTOR,
  TOGGLE_RIDER,
  TOGGLE_RIDER_MODULE,
  TOGGLE_RIDER_GROUP_MODULE,
  EDIT_RIDER_SUM_ASSURED,
  EDIT_RIDER_SELECTED_PLAN,
  updateTotalPremium,
  updateTotalRiderPremium,
  updateSumAssured,
  updateBasicPremium,
  updateFullBasicPremium,
  updatFullAnnualBasicPremium,
  updateCampaignDiscountMessage,
  updateCampaignDiscountEndDate,
  editBasicPremiumState,
  editRiderSumAssured,
  editRiderSelectedPlan,
  editRiderPremium,
  editRiderModulePremium,
  updateAvailableRiders,
  updateRidersBenefits,
} from 'quick-quote/product-common/coverage-plan/actions'
import type { DataWrapper } from 'core/data'
import type { Effect } from 'redux-saga/effects'
import { takeLatest, takeEvery, put, call, select } from 'redux-saga/effects'
import type { Rider, RiderCode, RiderState, RiderPlanState } from 'core/data-model/rider'
import type { DisplayProductQuery } from 'core/service'
import { validateTotalPremium } from 'core/service/basic-plan'
import { getBasicPlan } from 'core/service/display-product'
import { editMRTASumAssuredContract, updateCodeInSelectedDisplayProduct } from 'quick-quote/product-mrta/actions'
import { UPDATE_SUM_ASSURED_AFTER_SELECTED_PLAN_PA } from 'quick-quote/product-pa/actions'
import {
  SELECT_MODEL_FACTOR as INVESTMENT_SELECT_MODEL_FACTOR,
  EDIT_SUM_ASSURED as INVESTMENT_EDIT_SUM_ASSURED,
  UPDATE_SUM_ASSURED as INVESTMENT_UPDATE_SUM_ASSURED,
  updateRegularPremium,
  updateSumAssured as investmentUpdateSumAssured,
} from 'quick-quote/product-investment/coverage-plan/actions'
import { GET_MODEL_FACTORS_REQUEST, getModelFactorsSuccess, getModelFactorsFailed } from 'quick-quote/actions'
import { SELECT_PRODUCT } from 'quick-quote/product-selection/actions'
import {
  SELECT_GENDER,
  SELECT_PAYER_GENDER,
  UPDATE_BIRTHDATE,
  UPDATE_PAYER_BIRTHDATE,
  SELECT_NATURE_OF_DUTY,
  SELECT_OTHER_NATURE_OF_DUTY,
  SELECT_PAYER_NATURE_OF_DUTY,
  SELECT_PAYER_OTHER_NATURE_OF_DUTY,
  TOGGLE_OTHER_OCCUPATION,
  TOGGLE_PAYER_OTHER_OCCUPATION,
  EDIT_TITLE_NAME,
  validateInsuredInformation,
  SELECT_PAYER_RELATION,
} from 'quick-quote/insured-information/actions'

import {
  getInsured,
  getAge,
  getPayerAge,
  getPayerGender,
  getAllNatureOfDutyCodes,
  getAllPayerNatureOfDutyCodes,
  getPayerRelation,
} from 'quick-quote/insured-information/selectors'
import { canProceedToCoveragePlan } from 'quick-quote/selectors/next-tab-proceeding'

import {
  calculateCoveragePlanFromSumAssured,
  calculateCoveragePlanFromPremium,
  populateInitialCoveragePlan,
} from 'core/service/basic-plan/basic-plan'
import { totalRiderPremium, calculateTotalPremium } from 'core/service/basic-plan/premium'
import { isInvestment } from 'identity/fse-branch/services'
import { filterTPDRiderErrorForUdr } from 'quick-quote/product-investment/coverage-plan/sagas'
import values from 'core/data-model/constants/values'
import { validateRegularPremium, getMaximumSumAssured, validateSumAssured } from 'core/service/investment'
import { getToggles } from 'quick-quote/feature-toggles'

import {
  getLoanPeriod,
  getCoveragePeriod,
  getLoanAmount,
  getLoan,
  getInterestRateValue,
  isGLTSPGroup,
  getGLTSPPlancode,
} from 'quick-quote/product-mrta/coverage-plan/selectors'

export function* onGetModelFactorsRequest(dataWrapper: DataWrapper): Generator<*, *, *> {
  try {
    const modelFactors = yield call(dataWrapper.getModelFactors)
    yield put(getModelFactorsSuccess(modelFactors))
  } catch (error) {
    yield put(getModelFactorsFailed(error))
  }
}

const dispatchEditRiderSumAssured = function(rider, basicSumAssured, basicPremium, basicPlan, userAgentType) {
  const riderSumAssured = getRiderSumAssured(rider, basicSumAssured.value, basicPremium.value, basicPlan, userAgentType)
  return put(editRiderSumAssured(rider.code, riderSumAssured))
}

export function* updateDefaultRiderSumAssured(action: EditRiderSelectedPlan): Generator<*, *, *> {
  const canProceedToCoveragePlanBoolean = yield select(canProceedToCoveragePlan)
  if (!canProceedToCoveragePlanBoolean) {
    return
  }
  const basicSumAssured = yield select(getSumAssured$)
  const basicPremium = yield select(getBasicPremium$)
  const riderCode = action.payload.code
  const riders = yield select(getAvailableRiders)
  const selectedRiders = riders.filter((rider) => rider.code === riderCode).filter(({ isSelected }) => isSelected)

  if (selectedRiders.length === 0) {
    yield call(revalidateRiders)
  } else {
    const displayProductQuery: DisplayProductQuery = yield select(getSelectedDisplayProductQuery)
    const basicPlan = yield call(getBasicPlan, displayProductQuery)
    const userAgentType = yield call(getUserAgentType)
    yield selectedRiders.map((rider) => {
      return dispatchEditRiderSumAssured(rider, basicSumAssured, basicPremium, basicPlan, userAgentType)
    })
  }
}

export function* updateTotalPremiumState(action: *): Generator<*, *, *> {
  const sumAssured$: SumAssured$ = yield select(getSumAssured$)
  const modelFactorID = yield select(getSelectedModelFactorID)
  const availableRiders = yield select(getAvailableRiders)
  const basicPremium$ = yield select(getBasicPremium$)
  const riderPremium = yield call(totalRiderPremium, availableRiders)
  const totalPremium = yield call(calculateTotalPremium, basicPremium$.value, riderPremium)
  const validatedTotalPremium = validateTotalPremium(
    { basicSumAssured: sumAssured$.value, modelFactorID },
    totalPremium
  )
  yield put(updateTotalPremium(validatedTotalPremium))
  yield put(updateTotalRiderPremium(riderPremium))
  yield put(updateRidersBenefits(availableRiders))
}

const dispatchEditRiderSelectedPlan = function(rider, basicPlan, sumAssured, userAgentType, insuredAge) {
  const selectedPlan = getSelectedRiderPlan(
    rider,
    rider.selectedPlan,
    basicPlan,
    sumAssured,
    userAgentType,
    undefined,
    undefined,
    insuredAge
  )
  return put(editRiderSelectedPlan(rider.code, selectedPlan))
}

export function* updateRiderSelectedPlan(action: *): Generator<*, *, *> {
  const canProceedToCoveragePlanBoolean = yield select(canProceedToCoveragePlan)
  if (!canProceedToCoveragePlanBoolean) {
    return
  }

  const riders: (Rider & RiderPlanState)[] = yield select(getAvailableRiders)
  const riderCode = action.payload.riderCode
  const selectedRider = _(riders)
    .filter({ code: riderCode })
    .filter('isSelected')
    .value()
  if (selectedRider.length === 0) {
    yield call(revalidateRiders)
  } else {
    const displayProductQuery: DisplayProductQuery = yield select(getSelectedDisplayProductQuery)
    const basicPlan = yield call(getBasicPlan, displayProductQuery)

    const sumAssured$: SumAssured$ = isInvestment(basicPlan.category)
      ? yield select(getRegularPremium)
      : yield select(getSumAssured$)

    const userAgentType = yield call(getUserAgentType)
    const insuredAge = yield select(getAge)

    yield selectedRider.map((rider) => {
      return dispatchEditRiderSelectedPlan(rider, basicPlan, sumAssured$.value, userAgentType, insuredAge)
    })
  }
}

const dispatchEditRiderPremium = function(
  riderPremiumRates,
  rider,
  riderOccupationFactors,
  basicPremium,
  modelFactor,
  modelFactorPeriod,
  riderPayerOccupationFactors,
  loan
) {
  const riderPremiumRate = riderPremiumRates[rider.code]
  const riderSumAssured = rider.sumAssured
  const occupationFactor = riderOccupationFactors[rider.code]
  const payerOccupationFactor = riderPayerOccupationFactors[rider.code]
  const allDependencies = {
    riderPremiumRate,
    occupationFactor,
    riderSumAssured,
    basicPremium: basicPremium.value,
    modelFactor,
    modelFactorPeriod,
    payerOccupationFactor,
    loan,
  }

  const riderPremium = calculateRiderPremium(rider, allDependencies)
  return put(editRiderPremium(rider.code, riderPremium))
}

//TODO: must write unit testing on this function
export function* updateAllSelectedRiderPremium(): Generator<*, *, *> {
  const canProceedToCoveragePlanBoolean = yield select(canProceedToCoveragePlan)
  if (!canProceedToCoveragePlanBoolean) {
    return
  }
  const riders: (Rider & RiderState)[] = yield select(getAvailableRiders)
  const displayProductQuery: DisplayProductQuery = yield select(getSelectedDisplayProductQuery)
  const natureOfDutyCodes: string[] = yield select(getAllNatureOfDutyCodes)
  const insured = yield select(getInsured)
  const payerAge = yield select(getPayerAge)
  const payerGender = yield select(getPayerGender)
  const payerNatureOfDutyCodes = yield select(getAllPayerNatureOfDutyCodes)

  const riderPremiumRates: { [RiderCode]: number } = yield call(
    getRiderPremiumRates,
    insured.age,
    payerAge,
    insured.gender,
    payerGender,
    riders,
    natureOfDutyCodes,
    displayProductQuery
  )
  const riderOccupationFactors = yield call(
    getRiskiestOccupationFactorForRiders,
    displayProductQuery,
    natureOfDutyCodes,
    riders
  )

  const riderPayerOccupationFactors = yield call(
    getRiskiestOccupationFactorForRiders,
    displayProductQuery,
    payerNatureOfDutyCodes,
    riders
  )
  const basicPlan = yield call(getBasicPlan, displayProductQuery)
  const loan = yield select(getLoan)

  const modelFactor = isInvestment(basicPlan.category)
    ? yield select(getSelectedModelFactorILPValue)
    : yield select(getSelectedModelFactorValue)

  const basicPremium = yield select(getBasicPremium$)

  const modelFactorPeriod = isInvestment(basicPlan.category)
    ? yield select(getInvestmentSelectedModelFactorPeriod)
    : yield select(getSelectedModelFactorPeriod)

  yield riders
    .filter(({ isSelected }) => isSelected)
    .map((rider) => {
      return dispatchEditRiderPremium(
        riderPremiumRates,
        rider,
        riderOccupationFactors,
        basicPremium,
        modelFactor,
        modelFactorPeriod,
        riderPayerOccupationFactors,
        loan
      )
    })
}

const dispatchEditRiderModulePremium = function(
  riderPremiumRates,
  rider,
  riderModuleCode,
  riderOccupationFactors,
  basicPremium,
  modelFactor,
  modelFactorPeriod,
  riderPayerOccupationFactors
) {
  const riderPremiumRate = riderPremiumRates[riderModuleCode]
  const riderSumAssured = rider.sumAssured
  const occupationFactor = riderOccupationFactors[rider.code]
  const payerOccupationFactor = riderPayerOccupationFactors[rider.code]
  const allDependencies = {
    riderPremiumRate,
    occupationFactor,
    riderSumAssured,
    basicPremium: basicPremium.value,
    modelFactor,
    modelFactorPeriod,
    payerOccupationFactor,
  }

  const riderPremium = calculateRiderPremium(rider, allDependencies)
  return put(editRiderModulePremium(rider.code, riderModuleCode, riderPremium))
}

export function* updateRiderPremium(action: *): Generator<*, *, *> {
  const canProceedToCoveragePlanBoolean = yield select(canProceedToCoveragePlan)
  if (!canProceedToCoveragePlanBoolean) {
    return
  }
  const riderCode = action.payload.code
  const riders: (Rider & RiderState)[] = yield select(getAvailableRiders)
  const displayProductQuery: DisplayProductQuery = yield select(getSelectedDisplayProductQuery)
  const natureOfDutyCodes: string[] = yield select(getAllNatureOfDutyCodes)
  const insured = yield select(getInsured)
  const payerAge = yield select(getPayerAge)
  const payerGender = yield select(getPayerGender)
  const payerNatureOfDutyCodes = yield select(getAllPayerNatureOfDutyCodes)
  const riderPremiumRates: { [RiderCode]: number } = yield call(
    getRiderPremiumRates,
    insured.age,
    payerAge,
    insured.gender,
    payerGender,
    riders,
    natureOfDutyCodes,
    displayProductQuery
  )

  const riderOccupationFactors = yield call(
    getRiskiestOccupationFactorForRiders,
    displayProductQuery,
    natureOfDutyCodes,
    riders
  )
  const riderPayerOccupationFactors = yield call(
    getRiskiestOccupationFactorForRiders,
    displayProductQuery,
    payerNatureOfDutyCodes,
    riders
  )
  const basicPlan = yield call(getBasicPlan, displayProductQuery)
  const basicPremium = yield select(getBasicPremium$)

  const loan = yield select(getLoan)

  const modelFactor = isInvestment(basicPlan.category)
    ? yield select(getSelectedModelFactorILPValue)
    : yield select(getSelectedModelFactorValue)

  const modelFactorPeriod = isInvestment(basicPlan.category)
    ? yield select(getInvestmentSelectedModelFactorPeriod)
    : yield select(getSelectedModelFactorPeriod)

  const selectedRiders = _(riders)
    .filter({ code: riderCode })
    .filter('isSelected')
    .value()

  if (selectedRiders.length === 0) {
    yield call(revalidateRiders)
  }

  yield selectedRiders.map((rider) => {
    return dispatchEditRiderPremium(
      riderPremiumRates,
      rider,
      riderOccupationFactors,
      basicPremium,
      modelFactor,
      modelFactorPeriod,
      riderPayerOccupationFactors,
      loan
    )
  })

  yield _(selectedRiders)
    .filter('selectedPlan.modules')
    .value()
    .map((rider) => {
      return _(rider.selectedPlan.modules)
        .filter('isSelected')
        .value()
        .map((m) => {
          return dispatchEditRiderModulePremium(
            riderPremiumRates,
            rider,
            m.code,
            riderOccupationFactors,
            basicPremium,
            modelFactor,
            modelFactorPeriod,
            riderPayerOccupationFactors
          )
        })
    })
}

export function* populateAvailableRiders(): Generator<*, *, *> {
  const displayProductQuery: DisplayProductQuery = yield select(getSelectedDisplayProductQuery)
  const natureOfDutyCodes = yield select(getAllNatureOfDutyCodes)
  const payerNatureOfDutyCodes = yield select(getAllPayerNatureOfDutyCodes)
  const insured = yield select(getInsured)
  const basicPlan = yield call(getBasicPlan, displayProductQuery)
  const modelFactorID = isInvestment(basicPlan.category)
    ? yield select(getInvestmentSelectedModelFactorID)
    : yield select(getSelectedModelFactorID)

  const payerAge = yield select(getPayerAge)
  const payerGender = yield select(getPayerGender)
  const userAgentType = yield select(getUserAgentType)

  const insuredAge = yield select(getAge)
  const userLicenses = yield select(getValidUserLicenses)
  const payerRelation = yield select(getPayerRelation)
  const loanPeriod = yield select(getLoanPeriod)
  const loanAmount = yield select(getLoanAmount)
  const interestRate = yield select(getInterestRateValue)
  const coveragePeriod = yield select(getCoveragePeriod)
  const loan = yield select(getLoan)
  const allowOptionalIHealthyUltra = yield select(isAllowOptionalIHealtyUltra)
  const newValue = yield call(populateInitialCoveragePlan, {
    displayProductQuery,
    insured,
    natureOfDutyCodes,
    payerNatureOfDutyCodes,
    modelFactorID,
    payerAge,
    payerGender,
    userAgentType,
    userLicenses,
    payerRelation,
    loanPeriod,
    coveragePeriod,
    loanAmount,
    loan,
    interestRate,
    allowOptionalIHealthyUltra,
  })

  if ([values.RPUL, values.RPUDR].includes(displayProductQuery.code)) {
    const validatedRegularPremium = yield call(
      validateRegularPremium,
      displayProductQuery,
      modelFactorID,
      values.DEFAULT_RPP,
      insured.age
    )

    yield put(updateRegularPremium(values.DEFAULT_RPP, validatedRegularPremium.getAllMessages()))

    if (getToggles().ENABLE_MAXIMUM_SUM_ASSURED_CALCULATION_RPUL) {
      const natureOfDutyCodes = yield select(getAllNatureOfDutyCodes)
      const occupationFactor = yield call(getRiskiestOccupation, displayProductQuery, natureOfDutyCodes)

      const payload = {
        regularPremium: values.DEFAULT_RPP,
        insuredAge: insuredAge,
        insuredGender: insured.gender,
        occupationFactor: occupationFactor,
      }

      if (validatedRegularPremium.getAllMessages() && validatedRegularPremium.getAllMessages().length === 0) {
        const maximumSumAssured = yield call(getMaximumSumAssured, displayProductQuery, modelFactorID, payload)

        const payloadSumAssuredValidation = {
          sumAssured: maximumSumAssured,
          regularPremium: values.DEFAULT_RPP,
          insuredAge: insuredAge,
          insuredGender: insured.gender,
          occupationFactor: occupationFactor,
        }
        const validatedSumAssured = yield call(
          validateSumAssured,
          displayProductQuery,
          modelFactorID,
          payloadSumAssuredValidation
        )
        yield put(investmentUpdateSumAssured(maximumSumAssured, validatedSumAssured.getAllMessages()))
      } else {
        yield put(investmentUpdateSumAssured(0, []))
      }
    }
  }
  yield call(updateCoveragePlanState, newValue)
  yield put(validateInsuredInformation())
}

export function* revalidateRiders(): Generator<*, *, *> {
  const canProceedToCoveragePlanBoolean = yield select(canProceedToCoveragePlan)
  const hasMHP = yield select(hasMHPRiderAvailableRider)
  const availableRiders$: (Rider & RiderState)[] = yield select(getAvailableRiders)
  const insured: Insured = yield select(getInsured)
  if (!canProceedToCoveragePlanBoolean) {
    if (!hasMHP) return
    else {
      const selectedPlan = getSelectedPlanCodeRiderMultiPlanCodeMHP(availableRiders$, insured)
      const currRiderSelectedPlanCode = getRiderSelectedPlanCodeMHP(availableRiders$)
      if (selectedPlan.planCode !== currRiderSelectedPlanCode) yield put(editRiderSelectedPlan('MHP', selectedPlan))

      return
    }
  }
  const payerRelation = yield select(getPayerRelation)
  const displayProductQuery: DisplayProductQuery = yield select(getSelectedDisplayProductQuery)
  const natureOfDutyCodes: string[] = yield select(getAllNatureOfDutyCodes)
  const payerNatureOfDutyCodes: string[] = yield select(getAllPayerNatureOfDutyCodes)

  const payerAge: Age = yield select(getPayerAge)

  const basicPlan = yield call(getBasicPlan, displayProductQuery)

  const sumAssured$: SumAssured$ = isInvestment(basicPlan.category)
    ? yield select(getSumAssured)
    : yield select(getSumAssured$)

  if (hasMHP) {
    const selectedPlan = getSelectedPlanCodeRiderMultiPlanCodeMHP(availableRiders$, insured)
    const currRiderSelectedPlanCode = getRiderSelectedPlanCodeMHP(availableRiders$)
    if (selectedPlan.planCode !== currRiderSelectedPlanCode) yield put(editRiderSelectedPlan('MHP', selectedPlan))
  }
  const loan = yield select(getLoan)
  const userAgentType = yield select(getUserAgentType)
  const availableRiders: (Rider & RiderState)[] = yield select(getAvailableRiders)
  // $FlowFixMe
  const updatedAvailableRiders = yield call(
    validateRiders,
    availableRiders,
    insured,
    payerAge,
    sumAssured$.value,
    natureOfDutyCodes,
    payerNatureOfDutyCodes,
    displayProductQuery,
    payerRelation,
    loan,
    userAgentType
  )

  const table = updatedAvailableRiders.reduce((accumulator, current) => {
    accumulator[current.code] = current
    return accumulator
  }, {})

  if (basicPlan.code === values.RPUDR) {
    const shouldUpdateRider = updatedAvailableRiders.some(isRiderBelongToRpudr)
    if (shouldUpdateRider) {
      yield put(updateAvailableRiders(table))
    }
  } else {
    yield put(updateAvailableRiders(table))
  }
}

export function* updateCoveragePlanState(newValue: *): Generator<*, *, *> {
  yield put(updateSumAssured(newValue.validatedSumAssured))
  yield put(updateTotalPremium(newValue.totalPremium))
  yield put(updateTotalRiderPremium(newValue.riderPremium))

  if (newValue.sumAssuredContract) {
    yield put(editMRTASumAssuredContract(Number(newValue.sumAssuredContract)))
    const displayProductQuery: DisplayProductQuery = yield select(getSelectedDisplayProductQuery)
    if (isGLTSPGroup(displayProductQuery.code)) {
      const GLTSPPlancode = getGLTSPPlancode(displayProductQuery.productPlanCode, newValue.sumAssuredContract)
      yield put(updateCodeInSelectedDisplayProduct(GLTSPPlancode))
    }
  }

  if (newValue.campaignDiscountMessage) {
    yield put(updateCampaignDiscountMessage(newValue.campaignDiscountMessage))
  } else {
    yield put(updateCampaignDiscountMessage([]))
  }
  if (newValue.campaignDiscountEndDate) {
    yield put(updateCampaignDiscountEndDate(newValue.campaignDiscountEndDate))
  } else {
    yield put(updateCampaignDiscountEndDate(''))
  }

  if (newValue.fullBasicPremium) {
    yield put(updateFullBasicPremium(newValue.fullBasicPremium))
  }

  if (newValue.fullAnnualBasicPremium) {
    yield put(updatFullAnnualBasicPremium(newValue.fullAnnualBasicPremium))
  }

  const table = newValue.validatedRiders.reduce((accumulator, current) => {
    if (current) {
      accumulator[current.code] = current
    }
    return accumulator
  }, {})

  yield put(updateBasicPremium(newValue.basicPremium))

  const basicPlanCode = _.get(newValue, 'validatedSumAssured.dependencies.basicPlan.code', '')
  if (basicPlanCode === values.RPUDR) {
    const filter = filterTPDRiderErrorForUdr(table)
    yield put(updateAvailableRiders(filter))
  } else {
    yield put(updateAvailableRiders(table))
  }
}

export function* generateCoveragePlanFromSumAssuredChange(action: *): Generator<*, *, *> {
  const displayProductQuery: DisplayProductQuery = yield select(getSelectedDisplayProductQuery)
  const sumAssured: number = parseInt(action.payload)
  const natureOfDutyCodes = yield select(getAllNatureOfDutyCodes)
  const payerNatureOfDutyCodes = yield select(getAllPayerNatureOfDutyCodes)
  const insured = yield select(getInsured)
  const basicPlan = yield call(getBasicPlan, displayProductQuery)
  const modelFactorID = isInvestment(basicPlan.category)
    ? yield select(getInvestmentSelectedModelFactorID)
    : yield select(getSelectedModelFactorID)
  const availableRiders = yield select(getAvailableRiders)
  const payerAge = yield select(getPayerAge)
  const payerGender = yield select(getPayerGender)
  const userAgentType = yield select(getUserAgentType)
  const payerRelation = yield select(getPayerRelation)
  const loanPeriod = yield select(getLoanPeriod)
  const loanAmount = yield select(getLoanAmount)
  const coveragePeriod = yield select(getCoveragePeriod)
  const loan = yield select(getLoan)
  const interestRate = yield select(getInterestRateValue)

  const newValue = yield call(calculateCoveragePlanFromSumAssured, {
    sumAssured,
    displayProductQuery,
    insured,
    natureOfDutyCodes,
    payerNatureOfDutyCodes,
    modelFactorID,
    availableRiders,
    payerAge,
    payerGender,
    userAgentType,
    payerRelation,
    loanPeriod,
    coveragePeriod,
    loanAmount,
    loan,
    interestRate,
  })
  yield put(editBasicPremiumState(-1))
  yield call(updateCoveragePlanState, newValue)
}

export function* onCalculatePremium(action: *): Generator<*, *, *> {
  const displayProductQuery: DisplayProductQuery = yield select(getSelectedDisplayProductQuery)
  const basicPlan = yield call(getBasicPlan, displayProductQuery)
  const sumAssured$: SumAssured$ = isInvestment(basicPlan.category)
    ? yield select(getSumAssured)
    : yield select(getSumAssured$)

  // $FlowFixMe
  yield call(generateCoveragePlanFromSumAssuredChange, { payload: sumAssured$.value, type: action.type })
}

export function* generateCoveragePlanFromPremiumChange(action: *): Generator<*, *, *> {
  const basicPremium: number = action.payload
  if (basicPremium < 0) {
    return
  }
  const displayProductQuery: DisplayProductQuery = yield select(getSelectedDisplayProductQuery)
  const natureOfDutyCodes = yield select(getAllNatureOfDutyCodes)
  const payerNatureOfDutyCodes = yield select(getAllPayerNatureOfDutyCodes)
  const insured = yield select(getInsured)
  const basicPlan = yield call(getBasicPlan, displayProductQuery)
  const modelFactorID = isInvestment(basicPlan.category)
    ? yield select(getInvestmentSelectedModelFactorID)
    : yield select(getSelectedModelFactorID)
  const availableRiders = yield select(getAvailableRiders)
  const payerAge = yield select(getPayerAge)
  const payerGender = yield select(getPayerGender)
  const userAgentType = yield select(getUserAgentType)
  const payerRelation = yield select(getPayerRelation)
  const loanPeriod = yield select(getLoanPeriod)
  const loanAmount = yield select(getLoanAmount)
  const coveragePeriod = yield select(getCoveragePeriod)
  const loan = yield select(getLoan)
  const interestRate = yield select(getInterestRateValue)
  let newValue = yield call(calculateCoveragePlanFromPremium, {
    basicPremium,
    basicPremiumState: basicPremium,
    displayProductQuery,
    insured,
    natureOfDutyCodes,
    payerNatureOfDutyCodes,
    modelFactorID,
    availableRiders,
    payerAge,
    payerGender,
    userAgentType,
    payerRelation,
    loanPeriod,
    coveragePeriod,
    loanAmount,
    loan,
    interestRate,
  })
  yield call(updateCoveragePlanState, newValue)
}

export function* watchers(dataWrapper: DataWrapper): Generator<*, *, Effect[]> {
  const CALCULATE_PREMIUM_ACTION_TRIGGERS = [
    SELECT_GENDER,
    SELECT_NATURE_OF_DUTY,
    SELECT_OTHER_NATURE_OF_DUTY,
    SELECT_PAYER_NATURE_OF_DUTY,
    SELECT_PAYER_OTHER_NATURE_OF_DUTY,
    TOGGLE_PAYER_OTHER_OCCUPATION,
    TOGGLE_OTHER_OCCUPATION,
    UPDATE_BIRTHDATE,
    UPDATE_PAYER_BIRTHDATE,
    SELECT_MODEL_FACTOR,
    INVESTMENT_SELECT_MODEL_FACTOR,
    UPDATE_SUM_ASSURED_AFTER_SELECTED_PLAN_PA,
  ]

  const CALCULATE_RIDER_PREMIUM_ACTION_TRIGGERS = [SELECT_PAYER_GENDER]

  yield [
    takeLatest(GET_MODEL_FACTORS_REQUEST, onGetModelFactorsRequest, dataWrapper),

    takeLatest(EDIT_BASIC_PREMIUM_STATE, generateCoveragePlanFromPremiumChange),
    takeLatest(CALCULATE_PREMIUM_ACTION_TRIGGERS, onCalculatePremium),

    takeEvery([EDIT_RIDER_SELECTED_PLAN], updateDefaultRiderSumAssured),
    takeLatest([TOGGLE_RIDER], updateRiderSelectedPlan),
    takeLatest([TOGGLE_RIDER_MODULE], updateRiderSelectedPlan),
    takeLatest([TOGGLE_RIDER_GROUP_MODULE], updateRiderSelectedPlan),

    takeEvery(CALCULATE_RIDER_PREMIUM_ACTION_TRIGGERS, updateAllSelectedRiderPremium),
    takeEvery([EDIT_RIDER_SUM_ASSURED], updateRiderPremium),

    takeEvery([EDIT_RIDER_PREMIUM, INVESTMENT_UPDATE_SUM_ASSURED, UPDATE_BIRTHDATE, EDIT_TITLE_NAME], revalidateRiders),
    takeLatest(EDIT_RIDER_MODULE_PREMIUM, revalidateRiders),
    takeLatest(SELECT_PAYER_RELATION, revalidateRiders),
    takeLatest(SELECT_PRODUCT, populateAvailableRiders),

    takeLatest([EDIT_SUM_ASSURED, INVESTMENT_EDIT_SUM_ASSURED], generateCoveragePlanFromSumAssuredChange),

    takeLatest([UPDATE_AVAILABLE_RIDERS], updateTotalPremiumState),
  ]
}

export const sagas = watchers
