// @flow
import type { Age } from 'core/data-model/insured'
import BENEFIT from 'core/data-model/constants/bi-rider'

import { formatNumber } from 'core/service/lib/number-format'

import { isAgeLessThanOrEqual } from 'core/service/insured'
import { getToggles } from 'quick-quote/feature-toggles'
import type { RiderBenefitState, RiderBenefitData } from 'core/data-model/rider/benefits'

const percentageNumber = (percent: number, riderSumAssured: number): number =>
  Math.round((riderSumAssured * percent) / 100)

export const generateRiderBenefitDataForESCI = ({
  age,
  riderSumAssured,
}: {
  age: Age,
  riderSumAssured: number,
}): RiderBenefitState => {
  const MAXIMUM_KIDS_AGE: Age = {
    value: 18,
    unit: 'year',
  }
  const isInsuredKid: boolean = isAgeLessThanOrEqual(age, MAXIMUM_KIDS_AGE)

  let benefits = [
    {
      description: BENEFIT.RIDER_ESCI_DESCRIPTION_01,
    },
    {
      description: BENEFIT.RIDER_ESCI_DESCRIPTION_02,
    },
    {
      description: BENEFIT.RIDER_ESCI_DESCRIPTION_03,
    },
    {
      description: BENEFIT.RIDER_ESCI_DESCRIPTION_04,
      value: `${formatNumber(percentageNumber(25, riderSumAssured))} บาท`,
    },
    {
      description: BENEFIT.RIDER_ESCI_DESCRIPTION_05,
      value: `${formatNumber(percentageNumber(50, riderSumAssured))} บาท`,
    },
    {
      description: BENEFIT.RIDER_ESCI_DESCRIPTION_06,
      value: `${formatNumber(percentageNumber(100, riderSumAssured))} บาท`,
    },
    {
      description: BENEFIT.RIDER_ESCI_DESCRIPTION_07,
      value: `${formatNumber(percentageNumber(100, riderSumAssured))} บาท`,
    },
    {
      description: BENEFIT.RIDER_ESCI_DESCRIPTION_08,
      value: `${formatNumber(percentageNumber(500, riderSumAssured))} บาท`,
    },
  ]

  if (isInsuredKid) {
    benefits.push({
      description: BENEFIT.RIDER_ESCI_DESCRIPTION_09,
      value: `ครั้งละ ${formatNumber(
        percentageNumber(20, riderSumAssured)
      )} บาทต่อโรค สูงสุดไม่เกิน 5 โรคหรือ ${formatNumber(percentageNumber(100, riderSumAssured))} บาท`,
    })
  }

  benefits.push({
    description: BENEFIT.RIDER_ESCI_DESCRIPTION_10,
    value: isInsuredKid
      ? `${formatNumber(percentageNumber(600, riderSumAssured))} บาท`
      : `${formatNumber(percentageNumber(500, riderSumAssured))} บาท`,
  })

  if (getToggles().ENABLE_ICARE_REPRICING === true) {
    benefits.push({
      description: BENEFIT.RIDER_ESCI_DESCRIPTION_11,
      value: BENEFIT.RIDER_ESCI_VALUE_12,
    })
  } else {
    benefits.push({
      description: BENEFIT.RIDER_ESCI_DESCRIPTION_11,
      value: BENEFIT.RIDER_ESCI_VALUE_11,
    })
  }

  const riderBenefitData: RiderBenefitData = {
    benefits,
  }

  return {
    riderBenefitData,
  }
}
