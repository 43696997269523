import _ from 'lodash'
import { createField, createSectionCreator } from '../utils'

const dataFields = (app, id, { additionalProps: isDisease }) => {
  const isShowRemoveButton = !id.includes('[0]')

  return _.compact([
    createField(`${id}.disease`, [
      {
        id: `${id}.disease`,
        c: 'TextInput',
        required: true,
        label: `โปรดระบุ${isDisease ? 'โรค' : ''}`,
        p: {},
      },
    ]),
    createField(`${id}.doctorName`, [
      {
        label: 'ชื่อ-นามสกุลแพทย์',
        id: `${id}.doctorName`,
        c: 'TextInput',
        required: true,
        p: {},
      },
    ]),
    createField(`${id}.hospital`, [
      {
        label: 'โรงพยาบาล',
        id: `${id}.hospital`,
        c: 'TextInput',
        required: true,
        p: {},
      },
    ]),
    createField(`${id}.datetime`, [
      {
        label: 'วัน เวลาตรวจรักษา',
        id: `${id}.datetime`,
        c: 'TextInput',
        required: true,
        p: {},
      },
    ]),
    createField(`${id}.result`, [
      {
        label: 'ผลการตรวจรักษา',
        id: `${id}.result`,
        c: 'TextInput',
        required: true,
        p: {},
      },
    ]),
    isShowRemoveButton &&
      createField(
        `${id}.remove`,
        [
          {
            id: `${id}.remove`,
            c: 'Button',
            p: {
              icon: 'remove',
              children: 'ลบ',
              mergeProps: ({ removeValue, dispatch }) => ({
                onClick: () => {
                  removeValue(id)
                },
              }),
              style: {
                fontSize: '0.8725rem',
                padding: '0.125rem 0.5rem',
              },
            },
          },
        ],
        {
          style: {
            position: 'absolute',
            top: '0',
            right: '0',
          },
        }
      ),
  ])
}

const addNewTreatment = (app, id, section) => [
  createField(`${id}.addNew`, [
    {
      id,
      c: 'Button',
      p: {
        icon: 'add',
        children: 'เพิ่มข้อมูล',
        block: true,
        mergeProps: ({ setValue }) => ({
          onClick: () => {
            setValue(null)
          },
        }),
      },
    },
  ]),
]

export default (sectionId, sectionLabel, isDisease) => (app, id) => {
  const appTreatment = _.get(app, sectionId, [null])
  const maxTreatment = 10
  return _.compact(
    _.reduce(
      appTreatment,
      (result, el, i) => {
        return [
          ...result,
          createSectionCreator(dataFields)(
            `${sectionId}[${i}]`,
            null,
            null,
            `${sectionLabel} ${i + 1}`,
            `form-section__treatment__question__${i}`
          )(app, `${sectionId}[${i}]`, isDisease),
        ]
      },
      []
    ).concat(
      appTreatment.length < maxTreatment &&
        createSectionCreator(addNewTreatment)(sectionId, null, null, null, 'form-section__treatment__btn-add-new')(
          app,
          `${sectionId}[${appTreatment.length}]`
        )
    )
  )
}
