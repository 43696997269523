//@flow
import _ from 'lodash'
import { formatNumber } from 'core/service/lib/number-format'
import MESSAGES from 'core/data-model/constants/bi-messages'
import CONSTANTS from 'core/data-model/constants/messages'
import type { PolicyValueTableProps } from 'core/service/benefit-illustration/policy-value'
import { _getMessageUnderiShieldPolicyValueTable } from 'core/service/benefit-illustration/policy-value'
import Mustache from 'mustache'
import type { PolicyValue, YearEndPolicyValue } from 'core/service/benefit-illustration'
import { type DisplayProduct } from 'core/service/display-product'

// this is a hack for line wrapping in pdf which is not
// support Thai language yet. Once it is implemented
// this should be removed
export const _breakLine = (text: string) => {
  const premiumPattern = /^(เบี้ยประกันภัย)(สัญญา.+)$/
  const deathBenefitPattern = /^(ความคุ้มครอง)(การเสียชีวิต)$/
  const surrenderCashPattern = /^(มูลค่าเวนคืน)(เงินสด\**)$/
  const savingWithCompany = /^(กรณีรับเป็น)(เงินสด)$/

  const patterns = [premiumPattern, deathBenefitPattern, surrenderCashPattern, savingWithCompany]

  // add space to trick pdfmake to do line wrapping for us
  const breakByFirstMatched = ([p, ...rest]) => (text.match(p) ? text.replace(p, '$1 $2') : breakByFirstMatched(rest))

  return breakByFirstMatched(patterns)
}

const rowHeightConfig = { lineHeight: 0.75, margin: [0, -3, 0, 0] }

const buildLifeSaveProPolicyValueRow = (yearEndPolicy: YearEndPolicyValue) => [
  {
    text: yearEndPolicy.age.value,
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.yearEndPolicy,
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.cumulativeBasicPremium === 0 ? '-' : formatNumber(yearEndPolicy.cumulativeBasicPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.annualBasicPremium === 0 ? '-' : formatNumber(yearEndPolicy.annualBasicPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.annualRiderPremium === 0 ? '-' : formatNumber(yearEndPolicy.annualRiderPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.annualTotalPremium === 0 ? '-' : formatNumber(yearEndPolicy.annualTotalPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text:
      yearEndPolicy.cashDrop === 0
        ? '-'
        : // $$FlowFixMe
          formatNumber(yearEndPolicy.cashDrop, 0, true),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text:
      yearEndPolicy.lowSavingCashDrop === 0
        ? '-'
        : // $$FlowFixMe
          formatNumber(yearEndPolicy.lowSavingCashDrop, 0, true),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.deathBenefit === 0 ? '-' : formatNumber(yearEndPolicy.deathBenefit, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text:
      yearEndPolicy.cumulativeDeathBenefit === 0
        ? '-'
        : formatNumber(parseFloat(yearEndPolicy.cumulativeDeathBenefit), 2, true),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.surrenderCash === 0 ? '-' : formatNumber(yearEndPolicy.surrenderCash),
    alignment: 'right',
    ...rowHeightConfig,
  },
]

const buildLifeSaveProTotalPolicyValueRow = (policyValue: PolicyValue) => [
  '',
  '',
  '',
  {
    text: policyValue.subtotalAnnualBasicPremium === 0 ? '-' : formatNumber(policyValue.subtotalAnnualBasicPremium, 2),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  {
    text: policyValue.subtotalAnnualRiderPremium === 0 ? '-' : formatNumber(policyValue.subtotalAnnualRiderPremium, 2),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  {
    text: policyValue.grandTotalPremium === 0 ? '-' : formatNumber(policyValue.grandTotalPremium, 2),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  {
    text:
      policyValue.subtotalCashDrop === 0
        ? '-'
        : // $$FlowFixMe
          formatNumber(policyValue.subtotalCashDrop, 0, true),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  {
    text:
      policyValue.subtotalLowSavingCashDrop === 0
        ? '-'
        : // $$FlowFixMe
          formatNumber(policyValue.subtotalLowSavingCashDrop, 0, true),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  '',
  '',
  '',
]

export const lifeSaveProShowMessageUnderPdfTable = ({ policyValue, basicPlan }: PolicyValueTableProps) => {
  const message = _getMessageUnderiShieldPolicyValueTable({
    policyValue,
    basicPlan,
  })
  if (message) {
    return {
      stack: [{ text: message, color: 'red', fontSize: 11, alignment: 'center' }],
    }
  } else return { text: '' }
}

const buildLifeSaveProPolicyValueTable = (policyValue: PolicyValue) => {
  const policyValueTables = []
  const rowsPerPage = [54, 55, 54]
  const pageAmount = Math.ceil(policyValue.yearEndPolicyValues.length / rowsPerPage[0])
  _.times(pageAmount, (currentPage) => {
    policyValueTables.push({
      table: {
        headerRows: 1,
        widths: [12, 12, 'auto', '*', '*', '*', 50, 50, 50, '*', 50],
        body: [
          ...buildLifeSaveProHeaderTable(),
          ...policyValue.yearEndPolicyValues
            .slice(
              currentPage * rowsPerPage[currentPage > 0 ? currentPage - 1 : 0],
              rowsPerPage[currentPage] * (currentPage + 1)
            )
            .map((p) => buildLifeSaveProPolicyValueRow(p)),
        ],
      },
      layout: 'lightBorder',
      style: 'table25PL',
    })
  })

  _.last(policyValueTables).table.body.push(buildLifeSaveProTotalPolicyValueRow(policyValue))
  return policyValueTables
}

const buildLifeSaveProHeaderTable = () => {
  return [
    [
      {
        text: CONSTANTS.AGE,
        rowSpan: 3,
        style: 'tableHeader',
        margin: [0, 36],
      },
      {
        text: MESSAGES.END_OF_THE_YEAR,
        rowSpan: 3,
        style: 'tableHeader',
        margin: [0, 36],
      },
      {
        text: _breakLine(MESSAGES.CUMULATIVE_PREMIUM),
        rowSpan: 3,
        style: 'tableHeader',
        margin: [0, 30],
      },
      {
        text: MESSAGES.PREMIUM_PER_YEAR,
        colSpan: 3,
        style: 'tableHeader',
        margin: [0, 12],
      },
      { text: '' },
      { text: '' },
      {
        text: _breakLine(
          `${MESSAGES.LIFE_SAVE_PRO_CASH_DROP_VALUE_1} \n ${MESSAGES.LIFE_SAVE_PRO_CASH_DROP_VALUE_2} \n ${MESSAGES.END_OF_YEAR_POLICY_WITH_BRACKET}`
        ),
        colSpan: 2,
        style: 'tableHeader',
      },
      { text: '' },
      {
        text: _breakLine(`${MESSAGES.DEATH_BENEFIT} \n ${MESSAGES.END_OF_YEAR_POLICY_WITH_BRACKET}`),
        rowSpan: 3,
        style: 'tableHeader',
        margin: [0, 20],
      },
      {
        text: _breakLine(MESSAGES.LIFE_SAVE_PRO_CUMULATIVE_DEATH_BENEFIT),
        rowSpan: 3,
        style: 'tableHeader',
        margin: [0, 5],
      },
      {
        text: _breakLine(
          `${MESSAGES.LIFE_SAVE_PRO_CASH_SURRENDER_VALUE} \n ${MESSAGES.END_OF_YEAR_POLICY_WITH_BRACKET}`
        ),
        rowSpan: 3,
        style: 'tableHeader',
        margin: [0, 24],
      },
    ],
    [
      '',
      '',
      '',
      {
        text: MESSAGES.BASIC_PLAN,
        style: 'tableHeader',
        rowSpan: 2,
        margin: [0, 15],
      },
      {
        text: MESSAGES.RIDER_2_STAR,
        style: 'tableHeader',
        rowSpan: 2,
        margin: [0, 15],
      },
      {
        text: MESSAGES.TOTAL,
        style: 'tableHeader',
        rowSpan: 2,
        margin: [0, 15],
      },
      {
        text: MESSAGES.INCASE_OF_CASH_BACK,
        style: 'tableHeader',
        rowSpan: 2,
        margin: [0, 15],
      },
      {
        text: `${MESSAGES.LIFE_SAVE_PRO_INCASE_WITH_COMPANY} ${Mustache.render(MESSAGES.PERCENTS_PER_YEAR, {
          percent: '0.5',
        })}`,
        rowSpan: 2,
        style: 'tableHeader',
      },
      '',
      '',
      '',
    ],
    ['', '', '', '', '', '', '', '', '', '', ''],
  ]
}

export const buildPolicyValueTable = (policyValue: PolicyValue, basicPlan: DisplayProduct) => {
  return buildLifeSaveProPolicyValueTable(policyValue)
}

export const policyValueTableSection = ({ policyValue, basicPlan }: PolicyValueTableProps) => [
  {
    text: MESSAGES.POLICY_VALUE_TABLE,
    style: 'title',
    pageBreak: 'before',
    alignment: 'center',
  },
  ...buildPolicyValueTable(policyValue, basicPlan),
  lifeSaveProShowMessageUnderPdfTable({ policyValue, basicPlan }),
]
