// @flow
import NumberFormat from 'react-number-format'
import { Input } from 'reactstrap'

type InterestRateProps = {
  isReadOnly: boolean,
  interestRate: number,
  // enabled: boolean,
  editInterestRate: (?number) => void,
}

const InterestRate = ({ isReadOnly, interestRate, editInterestRate, isInvalidState = false }: InterestRateProps) => {
  return (
    <NumberFormat
      id="interest-rate-input"
      disabled={Boolean(isReadOnly)}
      value={interestRate}
      onChange={(e, values) => editInterestRate(Number(values.value))}
      isAllowed={({ formattedValue }) => formattedValue.length <= 6}
      customInput={Input}
      thousandSeparator={true}
      decimalPrecision={2}
      allowNegative={false}
      onFocus={(e) => e.target.setSelectionRange(0, -1)}
      type="tel"
      pattern="[0-9]*"
    />
  )
}

export default InterestRate
