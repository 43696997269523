// @flow
import _ from 'lodash'
import { d } from 'core/service/lib/decimal'
import riderMessages from 'core/data-model/constants/validation-messages'
import type { LimitIncreasingSumAssuredRule } from 'core/data-model/rider/rules'
import type { RiderEligibilities } from 'core/data-model/rider'
import type { ValidationMessage } from 'core/data-model/validation'

export const constraintStepSumAssuredRule = (
  rule: LimitIncreasingSumAssuredRule,
  eligibilities: RiderEligibilities
): ValidationMessage => {
  let errorMessage = ''

  const riderSumAssured = eligibilities.rider.sumAssured
  const result = d(riderSumAssured)
    .mod(rule.value)
    .toNumber()

  if (result !== 0) {
    let messageId = `RULE_RIDER_${eligibilities.rider.code}_LIMIT_INCREASING`
    errorMessage = _.get(riderMessages, messageId) || riderMessages.RULE_MESSAGE_NOT_FOUND
  }

  return {
    type: rule.validationType,
    message: errorMessage,
  }
}
