//@flow
import type { ProductCampaignApiMaturityStatus } from 'core/data-model/insured'
// import RequestWrapper from 'core/service/advisorzone-middleware/request-wrapper'
import { requestWrapper } from 'e-submission/domain/data'

const MaturityValidateApi = () => {
  const getMaturityStatus = (
    user: AuthenticatedUser,
    maturityIDCard: number,
    productCode: string
  ): ?ProductCampaignApiMaturityStatus => {
    const path = 'product-campaign/customer-maturity'
    const data = {
      productCode: productCode,
      customerIdCard: maturityIDCard,
    }
    return requestWrapper.postRequest(path, user, data).then(({ data }) => data)
  }

  return {
    getMaturityStatus: (
      user: AuthenticatedUser,
      maturityIDCard: number,
      productCode: string
    ): ?ProductCampaignApiMaturityStatus => getMaturityStatus(user, maturityIDCard, productCode),
  }
}

export default MaturityValidateApi()
