//@flow
import _ from 'lodash'
import type { PolicyValue, YearEndPolicyValue } from 'core/service/benefit-illustration'
import { type DisplayProduct } from 'core/service/display-product'
import { formatNumber } from 'core/service/lib/number-format'
import MESSAGES from 'core/data-model/constants/bi-messages'
import CONSTANTS from 'core/data-model/constants/messages'
import type { PolicyValueTableProps } from 'core/service/benefit-illustration/policy-value'

const rowHeightConfig = { lineHeight: 0.75, margin: [0, -3, 0, 0] }

const toCurrencyFormat = (value?: number, precision: number, isRound: boolean = true) =>
  value === undefined || value === -1 ? '' : formatNumber(value, precision, isRound)

const buildPolicyValueRow = (yearEndPolicy: YearEndPolicyValue) => [
  {
    text: toCurrencyFormat(yearEndPolicy.age.value, 0),
    alignment: 'right',
    ...rowHeightConfig,
  },
  { text: yearEndPolicy.yearEndPolicy, alignment: 'right', ...rowHeightConfig },
  {
    text: toCurrencyFormat(yearEndPolicy.cumulativeBasicPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: toCurrencyFormat(yearEndPolicy.annualBasicPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: toCurrencyFormat(yearEndPolicy.annualRiderPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: toCurrencyFormat(yearEndPolicy.annualTotalPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.cashDropValue,
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.deathBenefitDisplay,
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.accidentDeathBenefit,
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: toCurrencyFormat(yearEndPolicy.cashvalue, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
]

const addPageBreak = {
  stack: [
    {
      text: '',
      style: 'title',
      pageBreak: 'before',
    },
  ],
}

const disclaimer = {
  stack: [`**${MESSAGES.NOTE}:`, MESSAGES.BENEFIT_IGROW_DISCLAIMER],
  style: 'disclaimer',
}

const buildPolicyValueContentTable = (policyValue: PolicyValue) => {
  const policyValueTables = []
  const rowsPerPage = [36, 37, 36]
  const pageAmount = Math.ceil(policyValue.yearEndPolicyValues.length / rowsPerPage[0])
  _.times(pageAmount, (currentPage) => {
    policyValueTables.push({
      table: {
        headerRows: 2,
        widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', '*'],
        body: [
          [
            {
              rowSpan: 2,
              text: CONSTANTS.AGE,
              style: 'tableHeader',
            },
            {
              rowSpan: 2,
              text: MESSAGES.END_OF_THE_YEAR,
              style: 'tableHeader',
            },
            {
              rowSpan: 2,
              text: MESSAGES.CUMULATIVE_PREMIUM,
              style: 'tableHeader',
            },
            {
              colSpan: 3,
              text: MESSAGES.PREMIUM_PER_YEAR,
              style: 'tableHeader',
            },
            '',
            '',
            {
              rowSpan: 2,
              text: MESSAGES.BENEFIT_CASH_DROP,
              style: 'tableHeader',
            },
            {
              colSpan: 2,
              text: MESSAGES.DEATH_BENEFIT,
              style: 'tableHeader',
            },
            '',
            {
              rowSpan: 2,
              text: MESSAGES.CASH_SURRENDER_VALUE + '**',
              style: 'tableHeader',
            },
          ],
          [
            '',
            '',
            '',
            {
              text: MESSAGES.BASIC_PLAN,
              style: 'tableHeader',
            },
            {
              text: MESSAGES.RIDER,
              style: 'tableHeader',
            },
            {
              text: MESSAGES.TOTAL,
              style: 'tableHeader',
            },
            '',
            {
              text: MESSAGES.BENEFIT_GENERAL_CASE,
              style: 'tableHeader',
            },
            {
              text: MESSAGES.BENEFIT_DEATH_ACCIDENT,
              style: 'tableHeader',
            },
            '',
          ],
          ...policyValue.yearEndPolicyValues
            .slice(
              currentPage * rowsPerPage[currentPage > 0 ? currentPage - 1 : 0],
              rowsPerPage[currentPage] * (currentPage + 1)
            )
            .map((p) => buildPolicyValueRow(p)),
        ],
      },
      layout: 'lightBorder',
      style: 'table',
    })
  })
  return policyValueTables
}

export const buildPolicyValueTable = (policyValue: PolicyValue, basicPlan: DisplayProduct) => {
  return buildPolicyValueContentTable(policyValue)
}

export const policyValueTableSection = ({ policyValue, basicPlan }: PolicyValueTableProps) => [
  { text: MESSAGES.POLICY_VALUE_TABLE, style: 'title', pageBreak: 'before' },
  ...buildPolicyValueTable(policyValue, basicPlan),
  disclaimer,
  addPageBreak,
]
