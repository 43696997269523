import { compose, withHandlers, mapProps, renameProp } from 'recompose'
import { omit, get } from 'lodash/fp'
import styled from 'styled-components'
import { Flex, Box } from 'rebass'

import createNumberInput from 'e-submission/components/Form/Input/NumberInput'
import createDropdown from './index'

const MONTHS = [
  { text: 'มกราคม', value: '0' },
  { text: 'กุมภาพันธ์', value: '1' },
  { text: 'มีนาคม', value: '2' },
  { text: 'เมษายน', value: '3' },
  { text: 'พฤษภาคม', value: '4' },
  { text: 'มิถุนายน', value: '5' },
  { text: 'กรกฎาคม', value: '6' },
  { text: 'สิงหาคม', value: '7' },
  { text: 'กันยายน', value: '8' },
  { text: 'ตุลาคม', value: '9' },
  { text: 'พฤศจิกายน', value: '10' },
  { text: 'ธันวาคม', value: '11' },
]

const BoxContainer = styled(Box)`
  & + & {
    margin-left: 0.5rem;
  }
`

const hoc = mapProps(omit(['setValue', 'unsetValue', 'removeValue', 'dispatch', 'allow', 'validate']))
const NumberInput = createNumberInput(hoc)
const Dropdown = createDropdown(hoc)

export const Component = ({ validateInput, value, onTextBoxChange, onDropdownChange }) => {
  const month = get('month', value)
  const year = get('year', value)
  return (
    <Flex>
      <BoxContainer flex="1 1 100%">
        <Dropdown placeHolder={'เดือน'} value={month} options={MONTHS} setValue={onDropdownChange(year)} />
      </BoxContainer>
      <BoxContainer flex="1 1 100%">
        <NumberInput
          validate={validateInput}
          placeholder={'ปี (พ.ศ.)'}
          format={'####'}
          value={year}
          setValue={onTextBoxChange(month)}
        />
      </BoxContainer>
    </Flex>
  )
}

export default (hoc) =>
  compose(
    renameProp('validate', 'validateInput'),
    withHandlers({
      onTextBoxChange: ({ setValue }) => (month) => (year) => {
        setValue({
          year,
          month,
        })
      },
      onDropdownChange: ({ setValue }) => (year) => (month) => {
        setValue({
          year,
          month,
        })
      },
    }),
    hoc
  )(Component)
