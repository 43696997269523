// @flow
import { formatNumber } from 'core/service/lib/number-format'

type RiderPremiumProps = {
  isSelected: boolean,
  isSelectable: boolean,
  validationErrors: boolean,
  premium: number,
}

const RiderPremium = ({ isSelected, isSelectable, validationErrors, premium }: RiderPremiumProps) => {
  const premiumContent = () => {
    if (!isSelectable || validationErrors) {
      return <div className="value-pair">-</div>
    } else if (!isSelected) {
      return <div className="value-pair">0.00</div>
    } else {
      return <div className="value-pair">{formatNumber(premium, 2)}</div>
    }
  }

  return <div className="premium right">{premiumContent()}</div>
}

export default RiderPremium
