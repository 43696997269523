// @flow
import _ from 'lodash'
import type { Effect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getDisplayProducts, getSelectedDisplayProductCode } from 'quick-quote/product-selection/selectors'
import { getSelectableProductUltimateGrowth, getSelectedProductUltimateGrowth } from './coverage-plan/selectors'
import type { selectProductTermLife } from './actions'
import { SELECT_PRODUCT_TERM_LIFE, selectBasicPlan } from './actions'
import { UPDATE_BIRTHDATE } from 'quick-quote/insured-information/actions'
import { populateAvailableRiders } from 'quick-quote/product-common/coverage-plan/sagas'
import CONSTANTS from 'core/data-model/constants/values'
import { getSumAssured } from 'quick-quote/product-common/coverage-plan/selectors'
import { editSumAssured, editBasicPremiumState } from 'quick-quote/product-common/coverage-plan/actions'

export const onUpdateSelectedTermLife = function*(action: selectProductTermLife): Generator<*, *, *> {
  const displayProducts = yield select(getDisplayProducts)
  const productSelected = _.find(displayProducts, { code: action.payload })
  if (!!productSelected) {
    const sumAssured = yield select(getSumAssured)
    yield put(selectBasicPlan(productSelected))
    yield put(editSumAssured(sumAssured))
    yield put(editBasicPremiumState(-1))
  }
}

export const switchUltimateGrowthProductByAge = function*(action: *): Generator<*, *, *> {
  const productCode = yield select(getSelectedDisplayProductCode)
  const selectableProducts = yield select(getSelectableProductUltimateGrowth)
  const selectedProductUltimateGrowth = getSelectedProductUltimateGrowth(selectableProducts, productCode)
  if (
    !!selectedProductUltimateGrowth &&
    selectedProductUltimateGrowth !== productCode &&
    CONSTANTS.ULTIMATE_GROWTH_CODE_GROUP.includes(productCode)
  ) {
    const displayProducts = yield select(getDisplayProducts)
    const productSelected = _.find(displayProducts, { code: selectedProductUltimateGrowth })
    yield put(selectBasicPlan(productSelected))
    yield call(populateAvailableRiders)
  }
}

export function* watchers(): Generator<*, *, Effect[]> {
  yield [
    takeLatest([UPDATE_BIRTHDATE], switchUltimateGrowthProductByAge),
    takeLatest([SELECT_PRODUCT_TERM_LIFE], onUpdateSelectedTermLife),
  ]
}

export const sagas = watchers
