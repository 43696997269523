//@flow
import { compose, lifecycle, setDisplayName } from 'recompose'
import type { LstuFundAllocationOption } from 'core/data-model/investment'
import FundSummary from 'quick-quote/product-investment/fund-allocation/components/fund-summary'
import AllocationPart from 'quick-quote/product-investment/fund-allocation/lstu/components/allocation-part'
import type { FundAllocationProps } from 'quick-quote/product-investment/fund-allocation/rpp/fund-allocation'
import React from 'react'
import { InsuredInformationBreadcrumb } from '../../../components'
import MESSAGES from 'core/data-model/constants/messages'

type LstuFundAllocationProps = FundAllocationProps & {
  onAllocateSameAsRPP: Function,
  onAllocateIndependently: Function,
  chosenOption: LstuFundAllocationOption,
}

const FundAllocationComponent = ({
  navTabList,
  history,
  funds,
  allocations,
  totalAllocation,
  toggleFundAllocation,
  calculateTotalFundAllocation,
  resetFundAllocations,
  allowHigherRiskInvestment,
  allowForeignInvestment,
  isLowRiskEvaluated,
  hasValidEntryComponent,
  onAllocateSameAsRPP,
  onAllocateIndependently,
  chosenOption,
  hasAcceptedFundAllocation,
  fundSummary,
  isAcceptedOverRisk,
  isAcceptedForeign,
  onAcceptHigherRiskInvestment,
  onAcceptForeignInvestment,
  foreignExchangingQuestion,
  resetLstuAcceptFundAllocation,
}: LstuFundAllocationProps) => {
  const goToRPQAndRetest = () => {
    const RPQTab = navTabList.find((tab) => tab.id === 'invest')
    if (RPQTab) history.push(RPQTab.path)
  }
  return (
    <div id="lstu-fund-allocation" className="fund-allocation">
      <InsuredInformationBreadcrumb />
      <div className="content">
        {!hasAcceptedFundAllocation ? (
          <AllocationPart
            funds={funds}
            allocations={allocations}
            totalAllocation={totalAllocation}
            toggleFundAllocation={toggleFundAllocation}
            calculateTotalFundAllocation={calculateTotalFundAllocation}
            resetFundAllocations={resetFundAllocations}
            allowHigherRiskInvestment={allowHigherRiskInvestment}
            allowForeignInvestment={allowForeignInvestment}
            isLowRiskEvaluated={isLowRiskEvaluated}
            hasValidEntryComponent={hasValidEntryComponent}
            onAllocateSameAsRPP={onAllocateSameAsRPP}
            onAllocateIndependently={onAllocateIndependently}
            chosenOption={chosenOption}
            onRetakeRPQ={goToRPQAndRetest}
          />
        ) : (
          <FundSummary
            fundSummaryTitile={MESSAGES.LSTU_SUMMARY_FUND_TITLE}
            allocations={fundSummary}
            isAcceptOverRisk={isAcceptedOverRisk}
            isAcceptForeign={isAcceptedForeign}
            rppAcceptHigherRiskInvestment={onAcceptHigherRiskInvestment}
            rppAcceptForeignInvestment={onAcceptForeignInvestment}
            resetRppAcceptFundAllocation={resetLstuAcceptFundAllocation}
            foreignExchangingQuestion={foreignExchangingQuestion}
          />
        )}
      </div>
    </div>
  )
}

export const FundAllocation = compose(
  setDisplayName('FundAllocation'),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (this.props.hasAcceptedFundAllocation !== prevProps.hasAcceptedFundAllocation) {
        window.scrollTo(0, 0)
      }
    },
  })
)(FundAllocationComponent)
