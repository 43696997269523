// @flow
import type { Effect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'redux-saga/effects'

import { getIdentityUser } from 'identity/selectors'
import { getSelectedDisplayProductCode, getSelectedDisplayProductQuery } from 'quick-quote/product-selection/selectors'
import MaturityValidateApi from 'core/service/insured/maturity-validate-api'
import MESSAGES from 'core/data-model/constants/messages'
import DEFAULTS from 'core/data-model/constants/defaults'
import { getToggles } from 'quick-quote/feature-toggles'

import type { MaturityValidate, ValidateSelectPolicyOwner } from './actions'
import {
  MATURITY_VALIDATE,
  EDIT_MATURITY_ID_CARD,
  VALIDATE_SELECT_POLICY_OWNER,
  RESET_QUOTE_FORM_AFTER_MATURITY_VALIDATE,
  setValidMaturityIDCard,
  setErrorValidateMaturityIDCard,
  setErrorValidateMaturityStatus,
  setErrorValidateSelectPolicyOwner,
  resetErrorValidateMaturityIDCard,
  resetErrorValidateSelectPolicyOwner,
  updateMaturityValidate,
  selectPolicyOwner,
  updateSumAssuredAfterMaturityValidate,
} from './actions'

import { setLoadingStatus } from 'quick-quote/ui/actions'
import { getMaturityValidate } from 'quick-quote/product-s7cc/selectors'

import { getBasicPlan } from 'core/service'
import { isIDCardNo } from 'e-submission/utils/pattern'

export const validateMaturityProps = function*(action: MaturityValidate): Generator<*, *, *> {
  try {
    const maturityIDCard = action.payload
    const user = yield select(getIdentityUser)
    const productCode = yield select(getSelectedDisplayProductCode)
    yield put(setValidMaturityIDCard(maturityIDCard))

    yield put(setLoadingStatus(true))
    const maturityValidate = yield call(MaturityValidateApi.getMaturityStatus, user, maturityIDCard, productCode)

    if (maturityValidate) {
      yield put(updateMaturityValidate(maturityValidate))
      if (maturityValidate.maturityStatus === false)
        yield put(setErrorValidateMaturityStatus(MESSAGES.NOT_ALLOW_BUY_S7CC))
    }
  } catch (error) {
    yield put(updateMaturityValidate({ maturityStatus: false, isBuyForOwner: false }))
  }
  yield put(setLoadingStatus(false))
}

export const validateSelectPolicyOwnerProps = function*(action: ValidateSelectPolicyOwner): Generator<*, *, *> {
  const policyOwner = action.payload
  const maturityValidate = yield select(getMaturityValidate)
  yield put(selectPolicyOwner(policyOwner))
  if (policyOwner === DEFAULTS.SELF && maturityValidate.isBuyForOwner === false)
    yield put(setErrorValidateSelectPolicyOwner(MESSAGES.NOT_ALLOW_BUY_FOR_OWNER))
  else yield put(resetErrorValidateSelectPolicyOwner())
}

export const validateFormatIDCard = function*(action: number): Generator<*, *, *> {
  const maturityIDCard = action.payload
  const isIDCardCorrect = isIDCardNo(maturityIDCard.toString())
  if (!isIDCardCorrect && getToggles().ENABLE_CHECK_FORMAT_IDCARD_S7CC)
    yield put(setErrorValidateMaturityIDCard(MESSAGES.PLEASE_INPUT_CORRECT_FORMAT))
  else yield put(resetErrorValidateMaturityIDCard())
}

export const resetDefaultSumAssured = function*(): Generator<*, *, *> {
  const displayProductQuery = yield select(getSelectedDisplayProductQuery)
  const basicPlan = yield call(getBasicPlan, displayProductQuery)
  yield put(updateSumAssuredAfterMaturityValidate(basicPlan.sumAssuredMinimum))
}

export function* watchers(): Generator<*, *, Effect[]> {
  yield [takeLatest([EDIT_MATURITY_ID_CARD], validateFormatIDCard)]
  yield [takeLatest([MATURITY_VALIDATE], validateMaturityProps)]
  yield [takeLatest([VALIDATE_SELECT_POLICY_OWNER], validateSelectPolicyOwnerProps)]
  yield [takeLatest([RESET_QUOTE_FORM_AFTER_MATURITY_VALIDATE], resetDefaultSumAssured)]
}

export const sagas = watchers
