// @flow
import type { DisplayProduct } from 'core/service/display-product'

export const SELECT_PRODUCT_LIFE_READY = 'SELECT_PRODUCT_LIFE_READY'
export const SELECT_PRODUCT_CANCERX5 = 'SELECT_PRODUCT_CANCERX5'
export const SELECT_PRODUCT_KOOMVER_COVER = 'SELECT_PRODUCT_KOOMVER_COVER'
export const SELECT_PRODUCT_KOOMVER_COMPENSATE = 'SELECT_PRODUCT_KOOMVER_COMPENSATE'
export const SELECT_PRODUCT_LIFE_TREASURE = 'SELECT_PRODUCT_LIFE_TREASURE'

export type SelectProductLifeReady = {
  type: 'SELECT_PRODUCT_LIFE_READY',
  payload: String,
}

export type SelectProductLifeTreasure = {
  type: 'SELECT_PRODUCT_LIFE_TREASURE',
  payload: String,
}

export type SelectProductCancerX5 = {
  type: 'SELECT_PRODUCT_CANCERX5',
  payload: String,
}

export type SelectProductKoomverCover = {
  type: 'SELECT_PRODUCT_KOOMVER_COVER',
  payload: String,
}

export type SelectProductKoomverCompensate = {
  type: 'SELECT_PRODUCT_KOOMVER_COMPENSATE',
  payload: String,
}

export const selectProductLifeReady = (productCode: string): SelectProductLifeReady => ({
  type: SELECT_PRODUCT_LIFE_READY,
  payload: productCode,
})

export const selectProductLifeTreasure = (productCode: string): SelectProductLifeTreasure => ({
  type: SELECT_PRODUCT_LIFE_TREASURE,
  payload: productCode,
})

export const selectProductCancerX5 = (productCode: string): SelectProductCancerX5 => ({
  type: SELECT_PRODUCT_CANCERX5,
  payload: productCode,
})

export const selectProductKoomverCover = (productCode: string): SelectProductKoomverCover => ({
  type: SELECT_PRODUCT_KOOMVER_COVER,
  payload: productCode,
})

export const selectProductKoomverCompensate = (productCode: string): SelectProductKoomverCompensate => ({
  type: SELECT_PRODUCT_KOOMVER_COMPENSATE,
  payload: productCode,
})

export const SELECT_BASIC_PLAN = 'SELECT_BASIC_PLAN'
export type SelectBasicPlan = {
  type: 'SELECT_BASIC_PLAN',
  payload: DisplayProduct,
}
export const selectBasicPlan = (product: DisplayProduct): SelectBasicPlan => ({
  type: SELECT_BASIC_PLAN,
  payload: product,
})
