// @flow
import VALUES from 'core/data-model/constants/values'
import type { DisplayProduct } from 'core/service/display-product/types'
import _ from 'lodash'
import Mustache from 'mustache'
import { getDisplayProducts } from 'quick-quote/product-selection/selectors'
import type { AppState } from 'quick-quote/reducers'
import { createSelector } from 'reselect'
import messages from 'core/data-model/constants/messages'
import type { Option } from 'common-components/dropdown'

export const getSelectedModelFactorID = (state: AppState): string => state.coveragePlan.selectedModelFactorID

export const getBasicPlanErrorMessage = (state: AppState): string => state.coveragePlan.selectedBasicPlan.error

const getBasicPlanOptionText = (product: DisplayProduct): string => {
  const template = messages.BASIC_PLAN_SELECTION_LABEL
  if (!product.entryAge) {
    return product.code
  }
  return Mustache.render(template, {
    basicPlanCode: product.code,
    entryAgeMinimum: product.entryAge.minimum.value,
    entryAgeMaximum: product.entryAge.maximum.value,
  })
}

const getHealthDisplayProducts = createSelector(getDisplayProducts, (products: DisplayProduct[]): DisplayProduct[] =>
  products.filter((p) => p.category === VALUES.HEALTH)
)

export const getBasicPlanOptions = createSelector(getHealthDisplayProducts, (products: DisplayProduct[]): Option[] =>
  products.map((p) => ({
    value: p.code,
    text: getBasicPlanOptionText(p),
  }))
)

export const getIhealthySelectedPlanName = (state: AppState): string =>
  _.get(state, 'coveragePlan.riders["MEH"].selectedPlan.plan', '')
export const getIhealthySelectedPlanCode = (state: AppState): string =>
  _.get(state, 'coveragePlan.riders["MEH"].selectedPlan.planCode', '')
