// @flow
import { queryPremiumRateForDCI as queryPremiumRate } from 'core/service/rider/query-premium-rate'
import { calculateRiderPremiumForDCI as calculateRiderPremium } from 'core/service/rider/premium-calculation'
import { queryTaxRateForDCI as queryTaxRate } from 'core/service/rider/query-tax-rate'

export default {
  calculateRiderPremium,
  queryPremiumRate,
  queryTaxRate,
}
