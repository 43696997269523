// @flow

import VALUES from 'core/data-model/constants/values'
import type { Questionnaire } from 'core/service/investment/questionnaire'
import {
  getForeignExchangeRiskQuestion,
  isSelectedYesOnForeignExchangeRiskQuestion,
} from 'core/service/investment/questionnaire'
import type { RiskEvaluated, RiskLevelItem } from 'core/service/investment/risklevel'
import {
  calculateAcceptableRiskRange,
  calculateOverRiskRange,
  calculateRiskLevel,
} from 'core/service/investment/risklevel'
import _ from 'lodash'
import type { AppState } from 'quick-quote/reducers'
import { createSelector } from 'reselect'

export const getAgentComment = (state: AppState) => state.investment.investmentRisk.agentComment
export const getAllowHigherRiskInvestment = (state: AppState) => {
  return state.investment.investmentRisk.allowHigherRiskInvestment
}
export const getAllowForeignInvestment = (state: AppState) => {
  return state.investment.investmentRisk.allowForeignInvestment
}
export const getQuestionnaire = (state: AppState) => state.investment.investmentRisk.questionnaires
export const getTotalQuestionnaire = (state: AppState) => state.investment.investmentRisk.questionnaires.length
export const isEvaluated = (state: AppState) => state.investment.investmentRisk.riskEvaluated.isEvaluated
export const getInvestmentRisk = (state: AppState) => state.investment.investmentRisk
export const getRiskLevel = (state: AppState) => state.investment.investmentRisk.riskLevel.level
export const getRiskEvaluated = (state: AppState) => state.investment.investmentRisk.riskEvaluated

export const hasValidEntryComponent = (state: AppState): boolean => {
  return state.entryComponent.source === 'perfect-advice'
}

export const isLowRiskEvaluated = (state: AppState) => {
  return state.investment.investmentRisk.riskEvaluated.value <= VALUES.HIGH_RISK
}

export const totalAnswered = (questionnaires: Questionnaire[]) => {
  const selectedChoices = _.filter(questionnaires, (q) => q.selectedChoice != 0)
  return selectedChoices.length
}

export const isOnlyBankDeposit = (questionnaires: Questionnaire[]) => {
  const multipleChoiceQuestion = _.find(questionnaires, (q) => q.multipleChoice)
  const sumMultipleChoiceQuestion = multipleChoiceQuestion.selectedChoice.reduce((prev, item) => prev + item, 0)
  return multipleChoiceQuestion.selectedChoice.includes(1) && sumMultipleChoiceQuestion === 1
}

export const _answeredPercent = (questionnaires: Questionnaire[]) =>
  (totalAnswered(questionnaires) / questionnaires.length) * 100
export const answeredPercent = createSelector(getQuestionnaire, _answeredPercent)

export const _answeredCount = (questionnaires: Questionnaire[]) => totalAnswered(questionnaires)
export const answeredCount = createSelector(getQuestionnaire, _answeredCount)
export const _getRiskLevelResult = (questionnaire: Questionnaire[], riskLevel: RiskLevelItem[]): RiskEvaluated =>
  calculateRiskLevel(totalScore(questionnaire), riskLevel)

export const getRiskLevelResult = createSelector(getQuestionnaire, getRiskLevel, _getRiskLevelResult)
export const getAcceptableRiskRange = createSelector(getRiskLevelResult, getRiskLevel, calculateAcceptableRiskRange)
export const getOverRiskRange = createSelector(getRiskLevelResult, getRiskLevel, calculateOverRiskRange)
export const totalScore = (questionnaires: Questionnaire[]) => {
  let score = 0
  _.forEach(questionnaires, (item) => {
    if (item.hasScore) {
      if (Array.isArray(item.selectedChoice) && item.selectedChoice.length !== 0) {
        score += _.max(item.selectedChoice)
      } else {
        score += +item.selectedChoice
      }
    }
  })
  return score
}

export const getRPQScore = createSelector(getQuestionnaire, totalScore)

export const hasFinishedRiskEvaluation = createSelector(
  getRiskEvaluated,
  (riskEvaluated: RiskEvaluated): boolean => riskEvaluated.isEvaluated === true
)

export const hasFinishedFundAllocation = (state: AppState) =>
  _.get(state, 'investment.funds.totalAllocation.value') === 100

export const getExchangeQuestion = createSelector(getQuestionnaire, getForeignExchangeRiskQuestion)

export const isSelectedYesOnForeignExchangeQuestion = createSelector(
  getQuestionnaire,
  isSelectedYesOnForeignExchangeRiskQuestion
)
