// @flow
import type { Age } from 'core/data-model/insured'
import messages from 'core/data-model/constants/messages'

export const ageInYears = (age: Age): number => {
  return age.unit !== 'year' ? 0 : age.value
}
export const formatAgeInYears = (age: Age): string => {
  return `${ageInYears(age)} ${messages.TIME_UNIT_YEAR}`
}

export const formatAge = (age: Age): string => {
  let ageUnitThai = ''

  switch (age.unit) {
    case 'year':
      ageUnitThai = messages.TIME_UNIT_YEAR
      break
    case 'month':
      ageUnitThai = messages.TIME_UNIT_MONTH
      break
    case 'day':
      ageUnitThai = messages.TIME_UNIT_DAY
      break
  }

  return `${age.value} ${ageUnitThai}`
}
