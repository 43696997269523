// @flow
import type { AppState } from 'quick-quote/reducers'
import { createSelector } from 'reselect'
import { getSelectedDisplayProductCode } from '../product-selection/selectors'
import _ from 'lodash'

export const getSelectedDisplayProduct = (state: AppState) => state.selectedDisplayProduct

export const hasProductSelected = createSelector(
  getSelectedDisplayProductCode,
  (productCode: string): boolean => !_.isEmpty(productCode)
)
