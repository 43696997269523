// @flow

import _ from 'lodash'
import { compose, withHandlers, setDisplayName } from 'recompose'
import { FormGroup, FormFeedback } from 'reactstrap'
import { Dropdown } from 'common-components'

type Props = {
  value: { value: string, text: string },
  options: { value: string, text: string }[],
  defaultValue: string,
  placeholder: string,
  label: string,
  errors: string[],
  onChange: Function,
  onDropdownChange: Function,
  onOtherChange: Function,
  testId?: string,
}
const Title = ({
  value,
  options,
  defaultValue,
  placeholder,
  label,
  errors,
  onOtherChange,
  onDropdownChange,
  testId,
}: Props) => {
  const className = _.isEmpty(errors) ? '' : 'has-danger'
  const isOther = value.value === 'other'
  return (
    <div className="title-selection">
      <FormGroup className={className}>
        {label && <label>{label}</label>}
        <Dropdown
          testId={testId}
          options={options}
          onChange={onDropdownChange}
          value={value.value}
          placeHolder="กรุณาเลือก"
        />
      </FormGroup>
      {isOther && (
        <FormGroup className={className}>
          <label>ระบุคำนำหน้า</label>
          <input
            data-testid={testId}
            className="form-control"
            type="text"
            placeholder={placeholder}
            value={value.text}
            onChange={(e) => onOtherChange(e.target.value)}
            onBlur={(e) => {
              if (e.target.value === '') {
                onOtherChange(defaultValue.value)
              }
            }}
          />
          <FormFeedback className="validation-message mb">{errors}</FormFeedback>
        </FormGroup>
      )}
    </div>
  )
}
export const enhance = compose(
  setDisplayName('Title'),
  withHandlers({
    onDropdownChange: (props) => (value) => {
      const opt = _.find(props.options, { value })
      const text = opt && opt.value !== 'other' ? opt.text : ''
      props.onChange({ value, text })
    },
    onOtherChange: (props) => (e) => {
      props.onChange({ value: props.value.value, text: e })
    },
  })
)
export default enhance(Title)
