import {
  allowToSubmitWithoutPayment,
  hasNotChosenToPayNowOrPayLater,
  isSelectedToPayLater,
  isSelectedToPayNow,
} from '../selectors'
import { filter, flow, getOr, last, some } from 'lodash/fp'
import { cashSection, creditCardSection, edcSection, netbankSection } from '../../payment/index_old'

export const isShowFirstPremium = (app) => isSelectedToPayNow(app) || !allowToSubmitWithoutPayment(app)

export const shouldShowOptionsToPayLater = (app) => {
  return allowToSubmitWithoutPayment(app) && (hasNotChosenToPayNowOrPayLater(app) || isSelectedToPayLater(app))
}

export const getPaymentSections = (app, state) => {
  return [cashSection(app, state), edcSection(app, state), creditCardSection(app, state), netbankSection(app, state)]
}

export const getSelectedPaymentSections = flow(getPaymentSections, filter(['selected', true]))

export const isSelectedReceiptPaid = flow(getSelectedPaymentSections, last, getOr(false, 'paid'))

export const isCreditCard = flow(getSelectedPaymentSections, some(['paymentType', 'credit card']))

export const isChangePaymentButtonVisible = (app, state) => {
  return isSelectedReceiptPaid(app, state) && !isCreditCard(app, state)
}

export const isBackButtonVisible = (app, state) => {
  return !isSelectedReceiptPaid(app, state) || (isSelectedReceiptPaid(app, state) && isCreditCard(app, state))
}
