import _ from 'lodash'
import styled from 'styled-components'
import checkCircle from 'react-icons/lib/md/check-circle'
import checkFillCircle from '!svg-react-loader!assets/images/form/check-fill-circle.svg'
import radioChecked from 'react-icons/lib/md/radio-button-checked'
import radioUnChecked from 'react-icons/lib/md/lens'
import close from 'react-icons/lib/md/close'
import add from 'react-icons/lib/md/add-circle-outline'
import info from 'react-icons/lib/io/informatcircled'
import copy from '!svg-react-loader!assets/images/form/duplicate-icon.svg'

import wifiHigh from '!svg-react-loader!assets/images/icons/wifi-high.svg'
import wifiLow from '!svg-react-loader!assets/images/icons/wifi-low.svg'
import wifiMedium from '!svg-react-loader!assets/images/icons/wifi-medium.svg'
import wifiOff from '!svg-react-loader!assets/images/icons/wifi-off.svg'
import cashPayment from '!svg-react-loader!assets/images/payment/bills_dollar_blue.svg'
import edcPayment from '!svg-react-loader!assets/images/payment/edc-mpos.svg'
import creditCardPayment from '!svg-react-loader!assets/images/payment/credit_card_blue.svg'
import netbankPayment from '!svg-react-loader!assets/images/payment/netbank.svg'
import qrCode from '!svg-react-loader!assets/images/payment/qr-code2.svg'
import quickpayPayment from '!svg-react-loader!assets/images/payment/quickpay.svg'
import ktaxaLogo from '!svg-react-loader!assets/images/logo-ktaxa-th.svg'
import omiseLogo from '!svg-react-loader!assets/images/omise-logo.svg'
import citizenIdFront from '!svg-react-loader!assets/images/form/citizenId-front.svg'
import citizenIdFrontSuccess from '!svg-react-loader!assets/images/form/citizenId-front-success.svg'
import citizenIdFrontError from '!svg-react-loader!assets/images/form/citizenId-front-error.svg'
import citizenIdBack from '!svg-react-loader!assets/images/form/citizenId-back.svg'
import citizenIdBackSuccess from '!svg-react-loader!assets/images/form/citizenId-back-success.svg'
import citizenIdBackError from '!svg-react-loader!assets/images/form/citizenId-back-error.svg'
import ekycDopaSuccess from '!svg-react-loader!assets/images/form/ekyc-dopa-success.svg'
import ekycDopaError from '!svg-react-loader!assets/images/form/ekyc-dopa-error.svg'
import ekycDopaWarning from '!svg-react-loader!assets/images/form/ekyc-dopa-warning.svg'
import ekycStatusSuccess from '!svg-react-loader!assets/images/form/ekyc-status-success.svg'
import ekycStatusError from '!svg-react-loader!assets/images/form/ekyc-status-fail.svg'
import ekycStatusWarning from '!svg-react-loader!assets/images/form/ekyc-status-warning.svg'
import ekycStatusPending from '!svg-react-loader!assets/images/form/ekyc-status-pending.svg'

import attach from 'react-icons/lib/md/attach-file'
import groupSignature from '!svg-react-loader!assets/images/form/group_signature.svg'
import sign from '!svg-react-loader!assets/images/form/sign.svg'
import redo from '!svg-react-loader!assets/images/form/redo.svg'
import refresh from '!svg-react-loader!assets/images/form/refresh.svg'
import camera from '!svg-react-loader!assets/images/form/camera.svg'
import netBank from '!svg-react-loader!assets/images/form/net-bank.svg'
import creditCard from '!svg-react-loader!assets/images/form/credit-card.svg'
import circleCheck from '!svg-react-loader!assets/images/form/circle-check.svg'
import documentUploadDesktop from 'assets/images/form/docs-upload-desktop.png'
import documentUploadMobile from 'assets/images/form/docs-upload-mobile.png'
import iconIdCard from '!svg-react-loader!assets/images/form/icon_id-card.svg'
import _cameraEkyc from '!svg-react-loader!assets/images/form/camera-icon.svg'
import secondaryDocumentUpload from 'assets/images/form/secondary-doc-upload.png'
import arrowLeft from '@axah/web-toolkit/icons/arrow-left.svg'
import arrowRight from '@axah/web-toolkit/icons/arrow-right.svg'
import arrowDown from 'react-icons/lib/md/keyboard-arrow-down'
import arrowUp from 'react-icons/lib/md/keyboard-arrow-up'
import _arrowOutlineRight from 'react-icons/lib/io/arrow-right-b'
import _arrowOutlineDown from 'react-icons/lib/io/arrow-down-b'

import cross from '@axah/web-toolkit/icons/cross.svg'
import announcement from '!svg-react-loader!assets/images/form/announcement.svg'

import edit from 'react-icons/lib/md/edit'
import disclaimer from 'assets/images/form/disclaimer.png'
import error from 'assets/images/form/error.png'
import _unsuccess from '!svg-react-loader!assets/images/form/unsuccess.svg'
import _cybersource from '!svg-react-loader!assets/images/form/cybersource.svg'
import _cybersourceRetry from '!svg-react-loader!assets/images/form/cybersourceRetry.svg'
import formInvalid from 'assets/images/form/form-invalid.png'
import creditCardError from 'assets/images/form/credit_card_error.png'
import offline from 'assets/images/form/no-internet.png'
import playArrow from 'react-icons/lib/md/play-arrow'
import wifi from 'react-icons/lib/md/wifi'
import clear from 'react-icons/lib/md/clear'
import chevronRight from 'react-icons/lib/md/chevron-right'
import chevronLeft from 'react-icons/lib/md/chevron-left'

import hangup from '!svg-react-loader!assets/images/icons/hangup.svg'
import microphone from '!svg-react-loader!assets/images/icons/microphone.svg'
import microphoneOff from '!svg-react-loader!assets/images/icons/microphone-red.svg'
import cameraOff from '!svg-react-loader!assets/images/icons/camera-red.svg'
import cameraItem from '!svg-react-loader!assets/images/icons/camera.svg'
import screenSharing from '!svg-react-loader!assets/images/icons/screen-sharing.svg'
import screenSharingOff from '!svg-react-loader!assets/images/icons/screen-sharing-red.svg'

import _illustrate from '!svg-react-loader!assets/images/nav/icon-Illustrate-Idle.svg'
import _delete from 'react-icons/lib/md/delete'
import _check from '!svg-react-loader!assets/images/form/check.svg'
import _video from '!svg-react-loader!assets/images/form/video.svg'
import _keys from '!svg-react-loader!assets/images/form/keys.svg'
import _agreement from '!svg-react-loader!assets/images/form/agreement.svg'
import _padlock from '!svg-react-loader!assets/images/form/padlock.svg'
import _magnify from '!svg-react-loader!assets/images/form/magnifier.svg'

import _alternativeInvestment from '!svg-react-loader!assets/images/risk-profile/alternative-investment.svg'
import _govermentBond from '!svg-react-loader!assets/images/risk-profile/goverment-bond.svg'
import _equityInvestments from '!svg-react-loader!assets/images/risk-profile/equity-investment.svg'
import _savingShorttermBond from '!svg-react-loader!assets/images/risk-profile/saving-shortterm-bond.svg'

const Image = styled.img`
  display: block;
  height: auto;
  width: 100%;
  margin: 0 auto;
  max-width: 200px;
  ${({ theme }) => theme.media.md`max-width: 300px;`}
`

const ImageSmall = styled.img`
  display: block;
  height: auto;
  width: 100%;
  margin: 0 auto;
  max-width: 150px;
  ${({ theme }) => theme.media.md`max-width: 250px;`}
`

const baseIcon = (Icon) => styled(({ className, ...props }) => <Icon className={className} />)`
  width: 100%;
  max-width: 40px;
  max-height: 40px;
`

const cybsIcon = (Icon) => styled(({ className }) => <Icon className={className} />)`
  width: 100%;
  max-width: 80px;
  max-height: 80px;
`

const illustrate = baseIcon(_illustrate)
const check = baseIcon(_check)
const video = baseIcon(_video)
const unsuccess = cybsIcon(_unsuccess)
const cybersource = cybsIcon(_cybersource)
const cybersourceRetry = cybsIcon(_cybersourceRetry)
const agreement = baseIcon(_agreement)
const padlock = baseIcon(_padlock)
const cameraEkyc = baseIcon(_cameraEkyc)
const arrowOutlineRight = baseIcon(_arrowOutlineRight)
const arrowOutlineDown = baseIcon(_arrowOutlineDown)

const alternativeInvestment = cybsIcon(_alternativeInvestment)
const govermentBond = cybsIcon(_govermentBond)
const equityInvestments = cybsIcon(_equityInvestments)
const savingShorttermBond = cybsIcon(_savingShorttermBond)

const keys = styled(({ className, ...props }) => (
  <div className={className}>
    <div className="keys">
      <_keys />
    </div>
  </div>
))`
  color: ${({ theme }) => theme.variables['$color-crimson']};
  width: 100%;
  margin: 0 auto;
  max-width: 200px;
  ${({ theme }) => theme.media.md`max-width: 300px;`}

  .keys {
    width: 100%;
    height: 100%;
    padding: 34px;
    border-radius: 50%;
    background: white;
  }

  svg {
    height: 100%;
    width: 100%;
    path {
      fill: currentColor;
    }
  }
`

const magnify = baseIcon(_magnify).extend`
  path {
    stroke-width: 1.65;
  }
`

const Icon = {
  cashPayment,
  edcPayment,
  creditCardPayment,
  netbankPayment,
  qrCode,
  quickpayPayment,
  checkCircle,
  checkFillCircle,
  radioChecked,
  radioUnChecked,
  copy,
  add,
  remove: _delete,
  check,
  video,
  groupSignature,
  citizenIdFront,
  citizenIdFrontSuccess,
  citizenIdFrontError,
  citizenIdBack,
  citizenIdBackSuccess,
  citizenIdBackError,
  sign,
  info,
  magnify,
  camera,
  netBank,
  creditCard,
  circleCheck,
  edit,
  disclaimer: () => <Image src={disclaimer} alt="disclaimer" />,
  error: () => <Image src={error} alt="error" />,
  unsuccess,
  cybersource,
  cybersourceRetry,
  cameraEkyc,
  formInvalid: () => <Image src={formInvalid} alt="formInvalid" />,
  offline: () => <Image src={offline} alt="offline" />,
  creditCardError: () => <Image src={creditCardError} alt="creditCardError" />,
  documentUploadDesktop: () => <Image src={documentUploadDesktop} alt="Document upload desktop" />,
  documentUploadMobile: () => <Image src={documentUploadMobile} alt="Document upload mobile" />,
  secondaryDocumentUpload: () => <ImageSmall src={secondaryDocumentUpload} alt="Secondary document upload" />,
  iconIdCard,
  attach,
  arrowLeft,
  arrowDown,
  arrowUp,
  arrowRight,
  arrowOutlineRight,
  arrowOutlineDown,
  illustrate,
  playArrow,
  wifi,
  keys,
  agreement,
  padlock,
  clear,
  cross,
  close,
  ktaxaLogo,
  omiseLogo,
  announcement,
  alternativeInvestment,
  equityInvestments,
  govermentBond,
  savingShorttermBond,
  ekycDopaSuccess,
  ekycDopaError,
  ekycDopaWarning,
  ekycStatusSuccess,
  ekycStatusError,
  ekycStatusWarning,
  ekycStatusPending,
  redo,
  refresh,
  cameraItem,
  cameraOff,
  microphoneOff,
  microphone,
  screenSharing,
  screenSharingOff,
  hangup,
  wifiHigh,
  wifiLow,
  wifiMedium,
  wifiOff,
  chevronRight,
  chevronLeft,
}

export default Icon
export const getIcon = (iconName) => {
  const GotIcon = _.get(Icon, iconName)
  return GotIcon ? <GotIcon /> : 'Icon is not found.'
}
