// @flow
import type { DisplayProduct } from 'core/service/display-product'
import type { Age } from 'core/data-model/insured'
import {
  FundAllocationPieChart,
  PieChartSize,
} from 'core/service/pdf-generation/products/investment/components/fund-allocation-pie-chart'
import { renderBIAge } from 'core/service/insured/age'
import MESSAGES from 'core/data-model/constants/messages'
import BIMESSAGES from 'core/data-model/constants/bi-messages'
import { formatNumber } from 'core/service/lib/number-format'
import SummaryTable from './summary-table'

const newLineLabel = (string: string) => {
  return string.split('\n').map((item, i) => {
    return <p className="label">{item}</p>
  })
}

const choseProductName = (displayProduct: DisplayProduct): string => {
  return displayProduct ? displayProduct.displayName : ''
}

export type ProfileSummaryProps = {
  displayProduct: Object,
  genderLabel: string,
  age: Age,
  sumAssured: number,
  calculatedCoveragePeriod?: number,
  calculatedPaymentPeriod?: number,
  coveragePeriod: number,
  coverageDeath: number,
  regularPremium: number,
  regularTopUp: number,
  lumpSumTopUp: number,
  selectedModelFactorLabel: string,
  riders: Object[],
  canProceedToInvestmentBenefitIllustration: boolean,
  canProceedToFundAndCoveragePlan: boolean,
  allocationCategoryData: Object[],
  isBlockedEnteringESub: boolean,
}

const ProfileSummary = ({
  displayProduct,
  genderLabel,
  age,
  sumAssured,
  coveragePeriod,
  calculatedCoveragePeriod,
  calculatedPaymentPeriod,
  coverageDeath,
  regularPremium,
  totalRiderPremium,
  regularTopUp,
  lumpSumTopUp,
  selectedModelFactorLabel,
  canProceedToInvestmentBenefitIllustration,
  canProceedToFundAndCoveragePlan,
  allocationCategoryData,
  isBlockedEnteringESub,
  riders,
  isReviseQQ,
}: ProfileSummaryProps) => {
  const productName = choseProductName(displayProduct)
  const totalPremium = regularPremium + totalRiderPremium
  return (
    <div id="profile-summary" className="snapshot group">
      <h2 id="product-name">{productName}</h2>
      <div className="profile">
        <div className="info gender">
          <p className="label">{MESSAGES.GENDER}</p>
          <p className="value">{genderLabel}</p>
        </div>
        <div className="info age">
          <p className="label">{MESSAGES.AGE}</p>
          <p className="value">{renderBIAge(age, isReviseQQ)}</p>
        </div>
        <div className="info period">
          <p className="label">{BIMESSAGES.PAYMENT_PERIOD_BI}</p>
          <p className="value">
            {coveragePeriod} {MESSAGES.TIME_UNIT_YEAR}
          </p>
        </div>
        <div className="info cover">
          <p className="label">{BIMESSAGES.SUM_ASSURED}</p>
          <p className="value">
            {formatNumber(sumAssured)} {MESSAGES.BAHT}
          </p>
        </div>
      </div>
      <div className="coverage">
        <div className="rpudr-coverage-info regpremium">
          {newLineLabel(BIMESSAGES.PREMIUM_RPP)}
          <p className="value">
            {formatNumber(totalPremium, 2, true)} {MESSAGES.BAHT}
          </p>
        </div>
        <div className="lstu-info topup">
          <p className="label">{BIMESSAGES.PREMIUM_LSTU}</p>
          <p className="value">
            {formatNumber(lumpSumTopUp, 2)} {MESSAGES.BAHT}
          </p>
        </div>
        <div className="rpudr-coverage-info model-factor">
          <p className="label">{MESSAGES.PAYMENT_MODE}</p>
          <p className="value">{selectedModelFactorLabel}</p>
        </div>
      </div>
      <SummaryTable
        displayProduct={displayProduct}
        age={age}
        riders={riders}
        calculatedCoveragePeriod={calculatedCoveragePeriod}
        calculatedPaymentPeriod={calculatedPaymentPeriod}
        basicPremium={regularPremium}
        basicSumAssured={sumAssured}
        totalPremium={totalPremium}
      />
      <FundAllocationPieChart data={allocationCategoryData.rpp} size={PieChartSize.S} imageId="rpp" hide />
      {lumpSumTopUp > 0 ? (
        <FundAllocationPieChart data={allocationCategoryData.lstu} size={PieChartSize.S} imageId="lstu" hide />
      ) : null}
    </div>
  )
}

export default ProfileSummary
