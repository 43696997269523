//@flow
import { getToggles } from 'quick-quote/feature-toggles'
import type { AppState } from '../../reducers'
import fnaNavButtons from './fna/nav-buttons'
import { ROUTE_PATH } from '../../constants/path'

import type { NavButtonConfiguration } from './old-quick-quote/nav-buttons'
import oldQuickQuoteNavButtons from './old-quick-quote/nav-buttons'
import quickQuoteV1NavButtons from './quick-quote/nav-buttons'
import quickQuoteV2NavButtons from './quick-quote/v2/nav-buttons'

const optyNavigationButton = (): NavButtonConfiguration[] => {
  return [
    {
      path: ROUTE_PATH.OPTY,
      props: {
        backButton: {
          type: 'link',
          toHome: true,
        },
      },
    },
  ]
}

const mwpNavigationButton = (): NavButtonConfiguration[] => {
  return [
    {
      path: ROUTE_PATH.MW_PLUS,
      props: {
        backButton: {
          type: 'link',
          toHome: true,
        },
      },
    },
  ]
}

export default (state: AppState): NavButtonConfiguration[] =>
  oldQuickQuoteNavButtons(state)
    .concat(fnaNavButtons(state))
    .concat(getToggles().ENABLE_FNA_QQ_NAV_V2 ? quickQuoteV2NavButtons(state) : quickQuoteV1NavButtons(state))
    .concat(optyNavigationButton())
    .concat(mwpNavigationButton())
