import _ from 'lodash'
import { get, isEqual } from 'lodash/fp'
import moment from 'moment'

const bangkokProvinceObject = { text: 'กรุงเทพมหานคร', value: '010' }

export const addPrefixToAmphur = (amphur, province) => {
  if (_.isEmpty(amphur)) {
    return undefined
  }
  const amphurPrefix = _.isEqual(province, bangkokProvinceObject) ? 'เขต' : 'อ.'
  return `${amphurPrefix}${amphur}`
}
export const addPrefixToTambol = (tambol, province) => {
  if (_.isEmpty(tambol)) {
    return undefined
  }
  const tambolPrefix = _.isEqual(province, bangkokProvinceObject) ? 'แขวง' : 'ต.'
  return `${tambolPrefix}${tambol}`
}

export const creditCardSuffix = (creditCardNumber) => {
  return `xxxx-xxxx-xxxx-${creditCardNumber.substring(12)}`
}

export const quickPayStatus = {
  SUCCESS: (dateTime, creditCardNumber) => ({
    title: 'ผลการตรวจสอบการชำระเงิน',
    body: `<p>ชำระเงินเรียบร้อยเมื่อวันที่ ${dateTime.date} เวลา ${
      dateTime.time
    } น.<br/>ผ่านบัตรเครดิต ลงท้ายด้วย ${creditCardSuffix(creditCardNumber)}</p>`,
  }),
  PENDING: () => ({
    title: 'ผลการตรวจสอบการชำระเงิน',
    body: '<p>ไม่พบข้อมูลการชำระเงิน<br/>กรุณาตรวจสอบสถานะการชำระเบี้ยประกันกับผู้เอาประกันอีกครั้ง</p>',
  }),
  EXPIRED: (dateTime) => ({
    title: 'ผลการตรวจสอบการชำระเงิน',
    body: `<p>ลิงก์สำหรับชำระเบี้ยประกันหมดอายุแล้วเมื่อวันที่ ${dateTime.date} เวลา ${dateTime.time} น.<br/>กรุณากด ยืนยัน เพื่อสร้างลิงก์ชำระเบี้ยอีกครั้ง หรือ เปลี่ยนวิธีการชำระเบี้ย</p>`,
  }),
  ERROR: {
    title: 'ผลการตรวจสอบการชำระเงิน',
    body: '<p>ระบบตรวจสอบการชำระเบี้ยขัดข้อง กรุณากด ยืนยัน เพื่อกลับไปทำการ<br/>ตรวจสอบการชำระเงินอีกครั้ง</p>',
  },
}

export const ccpaStatus = {
  // SUCCESS: (dateTime, creditCardNumber) => ({
  //   title: 'ผลการตรวจสอบการชำระเงิน',
  //   body: `<p>ชำระเงินเรียบร้อยเมื่อวันที่ ${dateTime.date} เวลา ${
  //     dateTime.time
  //   } น.<br/>ผ่านบัตรเครดิต ลงท้ายด้วย ${creditCardSuffix(creditCardNumber)}</p>`,
  // }),
  PENDING: () => ({
    title: 'ผลการสมัครหักค่าเบี้ยประกันอัตโนมัติผ่านบัตรเครดิต',
    body:
      '<p>ไม่พบข้อมูลการสมัคร<br/>กรุณาตรวจสอบสถานะการสมัครหักค่าเบี้ยอัตโนมัติ<br/>ผ่านบัตรเครดิตผู้เอาประกันอีกครั้ง</p>',
  }),
  EXPIRED: (dateTime) => ({
    title: 'ผลการสมัครหักค่าเบี้ยประกันอัตโนมัติผ่านบัตรเครดิต',
    body: `<p>ลิงก์สำหรับการสมัครเพื่อหักค่าเบี้ยประกันอัตโนมัติ หมดอายุแล้วเมื่อวันที่ ${dateTime.date} เวลา ${dateTime.time} น.<br/>กรุณากด ยืนยัน เพื่อสร้างลิงก์สมัครอีกครั้ง หรือ เปลี่ยนวิธีการสมัคร</p>`,
  }),
  ERROR: {
    title: 'ผลการสมัครหักค่าเบี้ยประกันอัตโนมัติผ่านบัตรเครดิต',
    body: '<p>ขออภัย ขณะนี้ระบบไม่สามารถทำการตรวจสอบผลการสมัครได้<br/>กรุณากดยืนยันเพื่อไปยังขั้นตอนถัดไป</p>',
  },
}

export const convertMomentDateToObject = (date) => {
  if (!date) return {}

  const momentDate = moment(date).format('DD/MM/YYYY HH:mm')
  return {
    date: momentDate.split(' ')[0],
    time: momentDate.split(' ')[1],
  }
}

export const getATPDefaultRelation = (app) => {
  const atpInfo = {
    name: get('ATP.firstName', app),
    lastName: get('ATP.lastName', app),
  }
  const insuredInfo = {
    name: get('insured.firstName', app),
    lastName: get('insured.lastName', app),
  }
  const payerInfo = {
    name: get('payer.firstName', app),
    lastName: get('payer.lastName', app),
  }
  const relationshipToInsured = get('payer.relationshipToInsured.value', app)
  const isOwnerRelation = ['SPOUSE', 'PARENT'].includes(relationshipToInsured)

  if (isEqual(atpInfo, insuredInfo)) {
    return 'SELF'
  } else if (isEqual(atpInfo, payerInfo) && isOwnerRelation) {
    return 'PAYER'
  } else {
    return 'OTHERS'
  }
}
