import { assign } from 'lodash/fp'

export default (state = {}, action) => {
  switch (action.type) {
    case 'SET_DATA':
      return assign(action.payload)(state)
    case 'RESET_APP_STATE':
      return state
    default:
      return state
  }
}
