//@flow
import _ from 'lodash'
import { isNil } from 'core/service/lib/type-check'
import { connect } from 'react-redux'
import type { MapStateToProps, MapDispatchToProps, ConnectOptions, MergeProps } from 'react-redux'
import type { Rider, RiderState } from 'core/data-model/rider'
type AP_ECARE_Rider = {
  apRider: Rider & RiderState,
  ecareRider: Rider & RiderState,
}
type Props = {
  rider: (Rider & RiderState) | AP_ECARE_Rider,
  benefits?: Object,
}

const isApEcare = (rider: Object) => !isNil(rider) && (!isNil(rider.apRider) || !isNil(rider.ecareRider))

const isEsciWpeip85 = (rider: Object) => !isNil(rider) && (!isNil(rider.esciRider) || !isNil(rider.wpeip85Rider))

const isSelectedRider = (rider: Object) => {
  if (isApEcare(rider)) {
    return true
  }

  if (isEsciWpeip85(rider)) {
    return true
  }

  if (isNil(rider) || _.isEmpty(rider) || (!isApEcare(rider) && !(rider.isSelected && rider.isSelectable))) {
    return false
  }
  return rider.isSelected
}

export const enchancedRiderComponent = (Component: Class<React$Component<Props, *>>) => {
  return (props: Props) => {
    if (!props || !isSelectedRider(props.rider)) {
      return null
    }

    return <Component {...props} />
  }
}

export const enhancedRiderConnect = (
  mapStateToProps: MapStateToProps<*, *, *>,
  mapDispatchToProps?: MapDispatchToProps<*, *, *>,
  mergeProps?: MergeProps<*, *, *, *>,
  options?: ConnectOptions
) => _.flow(enchancedRiderComponent, connect(mapStateToProps, mapDispatchToProps, mergeProps, options))
