// @flow
import { InsuredInformationBreadcrumb } from 'quick-quote/components'
import { connect } from 'react-redux'
import { canProceedToInsuredInformation } from 'quick-quote/product-selection/selectors'
import type { AppState } from 'quick-quote'
import { Redirect } from 'react-router-dom'
import PATH from 'quick-quote/constants/path'

const InsuredInformationTemplate = ({ children, PayerInformation, canProceedToInsuredInformation }: *) =>
  !canProceedToInsuredInformation ? (
    <Redirect to={PATH.PRODUCT_SELECTION} push />
  ) : (
    <div>
      <InsuredInformationBreadcrumb />
      <div id="information">
        <div className="content">
          <div id="insured-information">{children}</div>
          {PayerInformation}
        </div>
      </div>
    </div>
  )

const mapStateToProps = (state: AppState): { canProceedToInsuredInformation: boolean } => ({
  canProceedToInsuredInformation: canProceedToInsuredInformation(state),
})
export default connect(mapStateToProps)(InsuredInformationTemplate)
