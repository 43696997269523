//@flow
import { withRouter } from 'react-router'

class _ScrollToTop extends React.Component<*> {
  componentDidUpdate(prevProps: Object) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}

export const ScrollToTop = withRouter(_ScrollToTop)
