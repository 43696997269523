// @flow
import React from 'react'
import Mustache from 'mustache'
import MESSAGES from 'core/data-model/constants/bi-messages'
import { formatNumber } from 'core/service/lib/number-format'
import { wrapWordingToTextJustify } from 'core/service/benefit-illustration/utils/wording-wrapper'
import VALUES from 'core/data-model/constants/values'
import type { TaxDeduction } from 'core/service/benefit-illustration'

type BenefitSummaryTableProps = {
  productCode: String,
  pensionType: string,
  pensionPercent: number,
  pensionAmount: number,
  totalPensionPeriod: number,
  totalPensionAmount: number,
  minimunDeathCoverage: number,
  pensionPeriod: number,
  basicPremiumAmoutForTax: number,
  taxDeduction: TaxDeduction,
  deadCoverageSurrender: number,
  deadCoverageStandard: number,
}
const _BenefitSummaryTable = (props: BenefitSummaryTableProps) => {
  switch (props.productCode) {
    case VALUES.LIFE_RETIRE_5:
      return _BenefitSummaryTableLifeRetire(props)
    case VALUES.BUMNAN_READY_CODE.BUMNAN_READY_8:
    case VALUES.BUMNAN_READY_CODE.BUMNAN_READY_60:
      return _BenefitSummaryTableBumnanReady(props)
    default:
      return _BenefitSummaryTableIRetire(props)
  }
}

const _BenefitSummaryTableLifeRetire = ({
  productCode,
  pensionType,
  pensionPercent,
  pensionAmount,
  totalPensionPeriod,
  totalPensionAmount,
  minimunDeathCoverage,
  pensionPeriod,
  taxDeduction,
  deadCoverageSurrender,
  deadCoverageStandard,
}: BenefitSummaryTableProps) => (
  <div className="group" id="benefit-summary">
    <h3>{Mustache.render(MESSAGES.RETIREMENT_BENEFITS, { pensionType })}</h3>

    <table className="retirement-benefit-table">
      <thead>
        <tr className="border-bottom color-wild-sand">
          <th colSpan="3">{MESSAGES.PENSION_AGE}</th>
        </tr>
        <tr className="color-wild-sand">
          <td>{MESSAGES.PERIOD_AGE}</td>
          <td>{Mustache.render(MESSAGES.PENSION_TYPE, { pensionType })}</td>
          <td>{MESSAGES.PENSION_AMOUNT}</td>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>{MESSAGES.BENEFIT_PENSION_AGE_60_85}</td>
          <td className="text-center">{Mustache.render(MESSAGES.PENSION_PERCENT, { pensionPercent })}</td>
          <td className="text-right">{formatNumber(pensionAmount, 2)}</td>
        </tr>
        <tr className="total">
          <td>{MESSAGES.TOTAL_BENEFIT_PENSION_AGE_60_85}</td>
          <td className="text-center">
            {Mustache.render(MESSAGES.MAXIMUM_PENSION_PERIOD, {
              totalPensionPeriod,
            })}
          </td>
          <td className="text-right">{formatNumber(totalPensionAmount, 2)}</td>
        </tr>
      </tbody>
    </table>

    <br />
    <table>
      <thead>
        <tr className="border-bottom color-wild-sand">
          <th colSpan="3">{MESSAGES.RETIREMENT_DEATH_BENEFIT}</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td className="valign-center">
            {Mustache.render(MESSAGES.LIFE_RETIRE_DEATH_BENEFIT_1, {
              deadCoverageSurrender,
              deadCoverageStandard,
            })}
          </td>
          <td className="valign-center text-center">
            {Mustache.render(MESSAGES.MINIMUN_DEATH_COVERAGE, {
              minimunDeathCoverage: formatNumber(minimunDeathCoverage, 2),
            })}
          </td>
        </tr>
        <tr>
          <td className="valign-center">{MESSAGES.RETIREMENT_DEATH_BENEFIT_2}</td>
          <td className="valign-center  text-center">
            {Mustache.render(MESSAGES.LIFE_RETIRE_DEATH_COVERAGE_AGE_60_74, {
              pensionPeriod,
            })}
          </td>
        </tr>
        <tr>
          <td className="valign-center">
            {Mustache.render(MESSAGES.LIFE_RETIRE_DEATH_BENEFIT_3, {
              pensionPeriod,
            })}
          </td>
          <td className="valign-center  text-center">{MESSAGES.LIFE_RETIRE_DEATH_COVERAGE_AGE_AFTER_75}</td>
        </tr>
      </tbody>
    </table>
    <p />

    <div id="tax-benefit" className="legal group retirement-tax-benefit">
      <h3>{MESSAGES.TAX_DEDUCTION_BENEFIT_TITLE}</h3>
      <div className="disclaimer">
        <div>{MESSAGES.TAX_DEDUCTION_BENEFIT_SUB_TITLE}</div>
        <div
          dangerouslySetInnerHTML={{
            __html: `${MESSAGES.TAX_DEDUCTION_BENEFIT_SUB_DESCRIPTION} <strong>${MESSAGES.TAX_DEDUCTION_BENEFIT_SUB_DESCRIPTION_STRONG}</strong>`,
          }}
        ></div>

        <div>
          <ul className="taxdeduction">
            <li>
              <div>{MESSAGES.RETIREMENT_TAX_CONSENT_BENEFIT_SUB_LIST[0].description}</div>
              <div>
                {Mustache.render(MESSAGES.RETIREMENT_TAX_CONSENT_BENEFIT_SUB_LIST[0].benefit, {
                  value:
                    taxDeduction.taxDeductionRetirementForYear === 0
                      ? '-'
                      : formatNumber(taxDeduction.taxDeductionRetirementForYear, 2, true),
                })}
              </div>
            </li>
            <li>
              <div>{MESSAGES.RETIREMENT_TAX_CONSENT_BENEFIT_SUB_LIST[1].description}</div>
              <div>
                {Mustache.render(MESSAGES.RETIREMENT_TAX_CONSENT_BENEFIT_SUB_LIST[1].benefit, {
                  value:
                    taxDeduction.taxDeductionLifeForYear === 0
                      ? '-'
                      : formatNumber(taxDeduction.taxDeductionLifeForYear, 2, true),
                })}
              </div>
            </li>
            <li>
              <div>{MESSAGES.RETIREMENT_TAX_CONSENT_BENEFIT_SUB_LIST[2].description}</div>
              <div>
                {Mustache.render(MESSAGES.RETIREMENT_TAX_CONSENT_BENEFIT_SUB_LIST[2].benefit, {
                  value:
                    taxDeduction.taxDeductionHealthForYear === 0
                      ? '-'
                      : formatNumber(taxDeduction.taxDeductionHealthForYear, 2, true),
                })}
              </div>
            </li>
          </ul>
          <div
            dangerouslySetInnerHTML={{
              __html: `${wrapWordingToTextJustify(MESSAGES.TAX_CONSENT_DEDUCTION_BENEFIT_SUB_ADDITIONAL)}`,
            }}
          ></div>
          <br />
          <p>{MESSAGES.NOTE}:</p>
          <ul>
            <li>
              <div>
                <strong>{MESSAGES.RETIREMENT_TAX_CONSENT_BENEFIT_SUB_NOTES[0][0]}</strong>{' '}
                {MESSAGES.RETIREMENT_TAX_CONSENT_BENEFIT_SUB_NOTES[0][1]}
              </div>
              <br />
              <div>
                <strong>{MESSAGES.RETIREMENT_TAX_CONSENT_BENEFIT_SUB_NOTES[1][0]}</strong>{' '}
                {MESSAGES.RETIREMENT_TAX_CONSENT_BENEFIT_SUB_NOTES[1][1]}
              </div>
              <br />
              <div>
                <strong>{MESSAGES.RETIREMENT_TAX_CONSENT_BENEFIT_SUB_NOTES[2][0]}</strong>{' '}
                {MESSAGES.RETIREMENT_TAX_CONSENT_BENEFIT_SUB_NOTES[2][1]}
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
)

const _BenefitSummaryTableIRetire = ({
  productCode,
  pensionType,
  pensionPercent,
  pensionAmount,
  totalPensionPeriod,
  totalPensionAmount,
  minimunDeathCoverage,
  pensionPeriod,
  taxDeduction,
  deadCoverageSurrender,
  deadCoverageStandard,
}: BenefitSummaryTableProps) => (
  <div className="group" id="benefit-summary">
    <h3>{Mustache.render(MESSAGES.RETIREMENT_BENEFITS, { pensionType })}</h3>

    <table className="retirement-benefit-table">
      <thead>
        <tr>
          <th colSpan="3">{MESSAGES.PENSION_AGE}</th>
        </tr>
        <tr>
          <td>{MESSAGES.PERIOD_AGE}</td>
          <td>{Mustache.render(MESSAGES.PENSION_TYPE, { pensionType })}</td>
          <td>{MESSAGES.PENSION_AMOUNT}</td>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>{MESSAGES.BENEFIT_PENSION_AGE_60_74}</td>
          <td className="text-center">{Mustache.render(MESSAGES.PENSION_PERCENT, { pensionPercent })}</td>
          <td className="text-right">{formatNumber(pensionAmount, 2)}</td>
        </tr>
        <tr>
          <td>{MESSAGES.BENEFIT_PENSION_AGE_75_85}</td>
          <td className="text-center">{Mustache.render(MESSAGES.PENSION_PERCENT, { pensionPercent })}</td>
          <td className="text-right">{formatNumber(pensionAmount, 2)}</td>
        </tr>
        <tr className="total">
          <td>{MESSAGES.TOTAL_BENEFIT_PENSION_AGE_60_85}</td>
          <td className="text-center">
            {Mustache.render(MESSAGES.MAXIMUM_PENSION_PERIOD, {
              totalPensionPeriod,
            })}
          </td>
          <td className="text-right">{formatNumber(totalPensionAmount, 2)}</td>
        </tr>
      </tbody>
    </table>

    <br />
    <table>
      <thead>
        <tr>
          <th colSpan="3">{MESSAGES.RETIREMENT_DEATH_BENEFIT}</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td className="valign-center">
            {Mustache.render(MESSAGES.RETIREMENT_DEATH_BENEFIT_1, {
              deadCoverageSurrender,
              deadCoverageStandard,
            })}
          </td>
          <td className="valign-center text-center">
            {Mustache.render(MESSAGES.MINIMUN_DEATH_COVERAGE, {
              minimunDeathCoverage: formatNumber(minimunDeathCoverage, 2),
            })}
          </td>
        </tr>
        <tr>
          <td className="valign-center">{MESSAGES.RETIREMENT_DEATH_BENEFIT_2}</td>
          <td className="valign-center">
            {Mustache.render(MESSAGES.DEATH_COVERAGE_AGE_60_74, {
              pensionPeriod,
            })}
          </td>
        </tr>
        <tr>
          <td className="valign-center">
            {Mustache.render(MESSAGES.RETIREMENT_DEATH_BENEFIT_3, {
              pensionPeriod,
            })}
          </td>
          <td className="valign-center">{MESSAGES.DEATH_COVERAGE_AGE_AFTER_75}</td>
        </tr>
      </tbody>
    </table>
    <p />

    <div id="tax-benefit" className="legal group retirement-tax-benefit">
      <h3>{MESSAGES.TAX_DEDUCTION_BENEFIT_TITLE}</h3>
      <div className="disclaimer">
        <div>{MESSAGES.TAX_DEDUCTION_BENEFIT_SUB_TITLE}</div>
        <div
          dangerouslySetInnerHTML={{
            __html: `${MESSAGES.TAX_DEDUCTION_BENEFIT_SUB_DESCRIPTION} <strong>${MESSAGES.TAX_DEDUCTION_BENEFIT_SUB_DESCRIPTION_STRONG}</strong>`,
          }}
        ></div>

        <ul className="taxdeduction">
          <li>
            <div>{MESSAGES.RETIREMENT_TAX_BENEFIT_SUB_LIST[0].description}</div>
            <div>
              {Mustache.render(MESSAGES.RETIREMENT_TAX_BENEFIT_SUB_LIST[0].benefit, {
                value: formatNumber(taxDeduction.taxDeductionBasicPremiumForYear, 2, true),
              })}
            </div>
          </li>
          <li>
            <div>{MESSAGES.RETIREMENT_TAX_BENEFIT_SUB_LIST[1].description}</div>
            <div>
              {Mustache.render(MESSAGES.RETIREMENT_TAX_BENEFIT_SUB_LIST[1].benefit, {
                value: formatNumber(taxDeduction.taxDeductionRiderPremuimForYear, 2, true),
              })}
            </div>
          </li>
        </ul>
        <div
          dangerouslySetInnerHTML={{
            __html: `${wrapWordingToTextJustify(MESSAGES.RETIREMENT_TAX_BENEFIT_SUB_ADDITIONAL)}`,
          }}
        ></div>
        <br />
        <p>{MESSAGES.NOTE}:</p>
        <ul>
          <li>
            <strong>{MESSAGES.RETIREMENT_TAX_BENEFIT_SUB_NOTES[0][0]}</strong>{' '}
            {MESSAGES.RETIREMENT_TAX_BENEFIT_SUB_NOTES[0][1]}
            <div>
              <strong>{MESSAGES.RETIREMENT_TAX_BENEFIT_SUB_NOTES[1][0]}</strong>{' '}
              {MESSAGES.RETIREMENT_TAX_BENEFIT_SUB_NOTES[1][1]}
            </div>
          </li>
          <li>
            <br />
            {MESSAGES.RETIREMENT_TAX_BENEFIT_SUB_NOTES[2][0]}
          </li>
        </ul>
      </div>
    </div>
  </div>
)

const _BenefitSummaryTableBumnanReady = ({
  productCode,
  pensionType,
  pensionPercent,
  pensionAmount,
  totalPensionPeriod,
  totalPensionAmount,
  minimunDeathCoverage,
  pensionPeriod,
  taxDeduction,
  deadCoverageSurrender,
  deadCoverageStandard,
  pensionPercents,
  pensionAmounts,
}: BenefitSummaryTableProps) => {
  return (
    <div className="group" id="benefit-summary">
      <h3>{Mustache.render(MESSAGES.RETIREMENT_BENEFITS, { pensionType })}</h3>

      <table className="retirement-benefit-table">
        <thead>
          <tr className="border-bottom color-wild-sand">
            <th colSpan="3">{MESSAGES.PENSION_AGE}</th>
          </tr>
          <tr className="color-wild-sand">
            <td>{MESSAGES.PERIOD_AGE}</td>
            <td>{Mustache.render(MESSAGES.PENSION_TYPE, { pensionType })}</td>
            <td>{MESSAGES.PENSION_AMOUNT}</td>
          </tr>
        </thead>

        <tbody>
          {productCode === VALUES.BUMNAN_READY_CODE.BUMNAN_READY_8 ? (
            <tr>
              <td>{MESSAGES.BENEFIT_PENSION_AGE_60_88}</td>
              <td className="text-center">{Mustache.render(MESSAGES.PENSION_PERCENT, { pensionPercent })}</td>
              <td className="text-right">{formatNumber(pensionAmount, 2)}</td>
            </tr>
          ) : (
            [
              <tr key={MESSAGES.BENEFIT_PENSION_AGE_60_70}>
                <td>{MESSAGES.BENEFIT_PENSION_AGE_60_70}</td>
                <td className="text-center">{pensionPercents.R60F_AGE_60_TO_70 + '% ของจำนวนเงินเอาประกันภัย'}</td>
                <td className="text-right">{formatNumber(pensionAmounts.R60F_AGE_60_TO_70, 2)}</td>
              </tr>,
              <tr key={MESSAGES.BENEFIT_PENSION_AGE_71_80}>
                <td>{MESSAGES.BENEFIT_PENSION_AGE_71_80}</td>
                <td className="text-center">{pensionPercents.R60F_AGE_71_TO_80 + '% ของจำนวนเงินเอาประกันภัย'}</td>
                <td className="text-right">{formatNumber(pensionAmounts.R60F_AGE_71_TO_80, 2)}</td>
              </tr>,
              <tr key={MESSAGES.BENEFIT_PENSION_AGE_81_88}>
                <td>{MESSAGES.BENEFIT_PENSION_AGE_81_88}</td>
                <td className="text-center">{pensionPercents.R60F_AGE_81_TO_88 + '% ของจำนวนเงินเอาประกันภัย'}</td>
                <td className="text-right">{formatNumber(pensionAmounts.R60F_AGE_81_TO_88, 2)}</td>
              </tr>,
            ]
          )}

          <tr className="total">
            <td>{MESSAGES.TOTAL_BENEFIT_PENSION_AGE_60_88}</td>
            <td className="text-center">
              {Mustache.render(MESSAGES.BUMNAN_READY_MAXIMUM_PENSION_PERIOD, {
                totalPensionPeriod,
              })}
            </td>
            <td className="text-right">{formatNumber(totalPensionAmount, 2)}</td>
          </tr>
        </tbody>
      </table>

      <br />
      <table>
        <thead>
          <tr className="border-bottom color-wild-sand">
            <th colSpan="3">{MESSAGES.RETIREMENT_DEATH_BENEFIT}</th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td className="valign-center">{MESSAGES.BUMNAN_READY_DEATH_BENEFIT_1}</td>
            <td className="valign-center text-center"></td>
          </tr>
          <tr>
            <td className="valign-center">{MESSAGES.BUMNAN_READY_DEATH_BENEFIT_1_1}</td>
            <td className="valign-center text-center">
              {Mustache.render(MESSAGES.MINIMUN_DEATH_COVERAGE, {
                minimunDeathCoverage: formatNumber(minimunDeathCoverage.beforeFiveYearInsurance, 2),
              })}
            </td>
          </tr>
          <tr>
            <td className="valign-center">{MESSAGES.BUMNAN_READY_DEATH_BENEFIT_1_2}</td>
            <td className="valign-center text-center">
              {Mustache.render(MESSAGES.MINIMUN_DEATH_COVERAGE, {
                minimunDeathCoverage: formatNumber(minimunDeathCoverage.afterFiveYearInsurance, 2),
              })}
            </td>
          </tr>
          <tr>
            <td className="valign-center">{MESSAGES.BUMNAN_READY_DEATH_BENEFIT_1_DISCLAIMER}</td>
            <td className="valign-center text-center"></td>
          </tr>
          <tr>
            <td className="valign-center">{MESSAGES.BUMNAN_READY_DEATH_BENEFIT_2}</td>
            <td className="valign-center  text-center">
              {Mustache.render(MESSAGES.BUMNAN_READY_DEATH_COVERAGE_AGE_60_74, {
                pensionPeriod,
              })}
            </td>
          </tr>
          <tr>
            <td className="valign-center">
              {Mustache.render(MESSAGES.BUMNAN_READY_DEATH_BENEFIT_3, {
                pensionPeriod,
              })}
            </td>
            <td className="valign-center  text-center">{MESSAGES.BUMNAN_READY_DEATH_COVERAGE_AGE_AFTER_75}</td>
          </tr>
        </tbody>
      </table>
      <p />

      <div id="tax-benefit" className="legal group retirement-tax-benefit">
        <h3>{MESSAGES.TAX_DEDUCTION_BENEFIT_TITLE}</h3>
        <div className="disclaimer">
          <div>{MESSAGES.TAX_DEDUCTION_BENEFIT_SUB_TITLE}</div>
          <div
            dangerouslySetInnerHTML={{
              __html: `${MESSAGES.TAX_DEDUCTION_BENEFIT_SUB_DESCRIPTION} <strong>${MESSAGES.TAX_DEDUCTION_BENEFIT_SUB_DESCRIPTION_STRONG}</strong>`,
            }}
          ></div>

          <div>
            <ul className="taxdeduction">
              <li>
                <div>{MESSAGES.RETIREMENT_TAX_CONSENT_BENEFIT_SUB_LIST[0].description}</div>
                <div>
                  {Mustache.render(MESSAGES.RETIREMENT_TAX_CONSENT_BENEFIT_SUB_LIST[0].benefit, {
                    value:
                      taxDeduction.taxDeductionRetirementForYear === 0
                        ? '-'
                        : formatNumber(taxDeduction.taxDeductionRetirementForYear, 2, true),
                  })}
                </div>
              </li>
              <li>
                <div>{MESSAGES.RETIREMENT_TAX_CONSENT_BENEFIT_SUB_LIST[1].description}</div>
                <div>
                  {Mustache.render(MESSAGES.RETIREMENT_TAX_CONSENT_BENEFIT_SUB_LIST[1].benefit, {
                    value:
                      taxDeduction.taxDeductionLifeForYear === 0
                        ? '-'
                        : formatNumber(taxDeduction.taxDeductionLifeForYear, 2, true),
                  })}
                </div>
              </li>
              <li>
                <div>{MESSAGES.RETIREMENT_TAX_CONSENT_BENEFIT_SUB_LIST[2].description}</div>
                <div>
                  {Mustache.render(MESSAGES.RETIREMENT_TAX_CONSENT_BENEFIT_SUB_LIST[2].benefit, {
                    value:
                      taxDeduction.taxDeductionHealthForYear === 0
                        ? '-'
                        : formatNumber(taxDeduction.taxDeductionHealthForYear, 2, true),
                  })}
                </div>
              </li>
            </ul>
            <div
              dangerouslySetInnerHTML={{
                __html: `${wrapWordingToTextJustify(MESSAGES.TAX_CONSENT_DEDUCTION_BENEFIT_SUB_ADDITIONAL)}`,
              }}
            ></div>
            <br />
            <p>{MESSAGES.NOTE}:</p>
            <ul>
              <li>
                <div>
                  <strong>{MESSAGES.RETIREMENT_TAX_CONSENT_BENEFIT_SUB_NOTES[0][0]}</strong>{' '}
                  {MESSAGES.RETIREMENT_TAX_CONSENT_BENEFIT_SUB_NOTES[0][1]}
                </div>
                <br />
                <div>
                  <strong>{MESSAGES.RETIREMENT_TAX_CONSENT_BENEFIT_SUB_NOTES[1][0]}</strong>{' '}
                  {MESSAGES.RETIREMENT_TAX_CONSENT_BENEFIT_SUB_NOTES[1][1]}
                </div>
                <br />
                <div>
                  <strong>{MESSAGES.RETIREMENT_TAX_CONSENT_BENEFIT_SUB_NOTES[2][0]}</strong>{' '}
                  {MESSAGES.RETIREMENT_TAX_CONSENT_BENEFIT_SUB_NOTES[2][1]}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default _BenefitSummaryTable
