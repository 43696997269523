// @flow
import _ from 'lodash'
import MESSAGES from 'core/data-model/constants/bi-messages'
import VALUES from 'core/data-model/constants/values'
import type { PolicyValueTableProps } from 'core/service/benefit-illustration/policy-value'
import { _getMessageUnderiShieldPolicyValueTable } from 'core/service/benefit-illustration/policy-value'
import {
  PolicyWithoutCashDrop as WLPolicyWithoutCashDrop,
  PolicyWithOutCashDropICare as WLPolicyWithOutCashDropICare,
} from 'quick-quote/product-whole-life/benefit-illustration/components/policy-value/policy-value-table'
import { PolicyWithCashDrop as LSPPolicyWithCashDrop } from 'quick-quote/product-life-save-pro/benefit-illustration/components/policy-value/policy-value-table'
import { PolicyTwelvePL as TWPolicy } from 'quick-quote/v2/components/benefit-illustration/policy-values/layout-for-12pl/policy-value-table.js'

export const IShieldMessageUnderTable = ({ policyValue, basicPlan }: PolicyValueTableProps) => {
  const message = _getMessageUnderiShieldPolicyValueTable({ policyValue, basicPlan })
  if (message) {
    return <div className="ishield-conditional-info">{message}</div>
  } else {
    return null
  }
}

const Is12PLPolicy = (planCode) => {
  return planCode === VALUES.IHEALTHY_ULTRA_CODE['12PL']
}

const PolicyValueTable = (props: PolicyValueTableProps) => {
  return (
    <div className={Is12PLPolicy(props.basicPlan.code) ? 'policy group twelve-pl' : 'policy group'} id="policy-value">
      <h3>{MESSAGES.POLICY_VALUE_TABLE}</h3>
      {renderPolicyValueTableForWholeLife(props)}
    </div>
  )
}

export const PolicyValueContent = ({ basicPlan, ...props }) => {
  switch (basicPlan.code) {
    case VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PROTECT_18.JUNIOR:
    case VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PROTECT_18.SENIOR:
    case VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_ENSURE_10.JUNIOR:
    case VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_ENSURE_10.SENIOR:
      return <WLPolicyWithOutCashDropICare {...props} />
    case VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_25_15.JUNIOR:
    case VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_25_15.SENIOR:
    case VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_20_10.JUNIOR:
    case VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_20_10.SENIOR:
      return <LSPPolicyWithCashDrop {...props} />
    case VALUES.IHEALTHY_ULTRA_CODE['12PL']:
      return <TWPolicy {...props} />
    default:
      return <WLPolicyWithoutCashDrop {...props} />
  }
}

const selectDisclaimer = (basicPlan) => {
  switch (basicPlan.code) {
    case VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PROTECT_18.JUNIOR:
    case VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PROTECT_18.SENIOR:
    case VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_ENSURE_10.JUNIOR:
    case VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_ENSURE_10.SENIOR:
      return (
        <div className="disclaimer">
          <p>{MESSAGES.NOTE}:</p>
          <ul>
            <li>{MESSAGES.POLICY_VALUE_TABLE_DISCLAIMER_ONE}</li>
            <li>{MESSAGES.POLICY_VALUE_TABLE_DISCLAIMER_TWO}</li>
            <li>{MESSAGES.POLICY_VALUE_TABLE_ICARE_DISCLAIMER_THREE}</li>
            <li>{MESSAGES.POLICY_VALUE_TABLE_DISCLAIMER_FOUR}</li>
          </ul>
        </div>
      )
    case VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_25_15.JUNIOR:
    case VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_25_15.SENIOR:
    case VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_20_10.JUNIOR:
    case VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_20_10.SENIOR:
      return (
        <div className="disclaimer">
          <div>{MESSAGES.NOTE}:</div>
          <div>
            <ul>
              <li>{MESSAGES.IHEALTHY_ULTRA_POLICY_VALUE_TABLE_NOTE_1}</li>
              <li>{MESSAGES.IHEALTHY_ULTRA_POLICY_VALUE_TABLE_NOTE_2}</li>
              <li>
                {MESSAGES.IHEALTHY_ULTRA_POLICY_VALUE_TABLE_NOTE_3}
                {MESSAGES.IHEALTHY_ULTRA_POLICY_VALUE_TABLE_NOTE_4}
                {MESSAGES.IHEALTHY_ULTRA_POLICY_VALUE_TABLE_NOTE_5}
              </li>
            </ul>
          </div>
        </div>
      )
    case VALUES.IHEALTHY_ULTRA_CODE['12PL']:
      return (
        <div className="disclaimer">
          <p>{MESSAGES.NOTE}:</p>
          <ul>
            <li>{MESSAGES.TWELVE_PL_POLICY_VALUE_NOTE_1}</li>
            <li>{MESSAGES.TWELVE_PL_BENEFIT_DISCLAIMER}</li>
            <li>{MESSAGES.TWELVE_PL_POLICY_VALUE_NOTE_3}</li>
          </ul>
        </div>
      )
    default:
      return (
        <div className="disclaimer">
          <p>**{MESSAGES.NOTE}:</p>
          <ul>
            <li>{MESSAGES.POLICY_VALUE_TABLE_NOTE_1}</li>
            <li>{MESSAGES.POLICY_VALUE_TABLE_NOTE_2}</li>
          </ul>
        </div>
      )
  }
}

const renderPolicyValueTableForWholeLife = ({ policyValue, basicPlan }: PolicyValueTableProps) => {
  const lastYearEndPolicy = _.get(_.last(policyValue.yearEndPolicyValues), 'yearEndPolicy', 0) + 1

  // $$FlowFixMe
  const policyValueTableProps = { policyValue, basicPlan }

  return (
    <div>
      <div className={Is12PLPolicy(basicPlan.code) ? 'sticky-table-wrap' : 'table-wrap'}>
        <PolicyValueContent lastYearEndPolicy={lastYearEndPolicy} basicPlan={basicPlan} {...policyValue} />
      </div>
      <IShieldMessageUnderTable {...policyValueTableProps} />
      {selectDisclaimer(basicPlan)}
    </div>
  )
}

export default PolicyValueTable
