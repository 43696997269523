import React from 'react'
import PropTypes from 'prop-types'
import MobileDetect from 'mobile-detect'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import _ from 'lodash'
import { getToggles } from 'quick-quote/feature-toggles'
import { ROUTE_PATH } from 'quick-quote/constants/path'
import MESSAGES from 'core/data-model/constants/messages'
import values from 'core/data-model/constants/values'
import { getUserAgentType, isLoggedIn, isAllowIproMenu, isAllowReviseQQMenu, isAllowMwpMenu } from 'identity/selectors'
import { startFNAFlow, startQQFlow } from './actions'
import {
  resetQuoteForm,
  loginRequest,
  setSelectedRemoteSelling,
  resetSelectedRemoteSelling,
  openDf2fApplication,
  checkVersionAppUpdate,
} from 'quick-quote/actions'
import { qqPath } from '../constants/path'
import { TitlePortal } from './title-portal'
import { getAppConfig } from '../../deploy-env/app-config'
import { setNotificationMessage } from 'quick-quote/notification/actions'
import { getIsUserOnline } from 'quick-quote/selectors'
import { IsRealDevice } from 'e-submission/utils'
import {
  applicationCheckVersion,
  applicationCheckVersionInvalidVersion,
  applicationCheckVersionValidVersion,
} from 'core/actions'

export const PortalMenuConfigs = {
  az: {
    id: 'az',
    title: 'Advisor Zone',
    items: [
      {
        id: 'fna',
        title: MESSAGES.GO_TO_NEED_AND_GAP_ANALYSIS,
        path: ROUTE_PATH.NEED_AND_GAP_ANALYSIS,
        disabled: (props) => {
          const isWs = props.userAgentType === values.AGENT_TYPE_CS
          return isWs && !getToggles().ENABLE_CS_USERTYPE
        },
      },
      {
        id: 'calculate-premium',
        title: MESSAGES.GO_TO_QUICK_QUOTE,
        path: qqPath(ROUTE_PATH.PRODUCT_SELECTION),
        hide: (props) => props.allowReviseQQMenu,
        disabled: (props) => {
          const isWs = props.userAgentType === values.AGENT_TYPE_CS
          return isWs && !getToggles().ENABLE_CS_USERTYPE
        },
      },
      {
        id: 'policy-list',
        title: MESSAGES.GO_TO_POLICY_LIST,
        path: ROUTE_PATH.POLICY_LIST,
        disabled: (props) => {
          const isWs = props.userAgentType === values.AGENT_TYPE_CS
          return isWs && !getToggles().ENABLE_CS_USERTYPE ? true : props.disableHistory
        },
      },
      {
        id: 'remote-selling',
        title: MESSAGES.GO_TO_REMOTE_SELLING,
        path: ROUTE_PATH.NEED_AND_GAP_ANALYSIS,
        hide: (props) => {
          return props.hideRemoteSelling
        },
        loginRequired: (props) => {
          return props.loginRequired
        },
      },
    ],
  },
  prospect: {
    id: 'prospect',
    title: 'ข้อมูลผู้มุ่งหวัง (Prospect)',
    items: [
      {
        id: 'opty',
        title: MESSAGES.GO_TO_OPTY,
        path: ROUTE_PATH.OPTY,
        hide: (props) => props.hideOpty,
        loginRequired: (props) => props.loginRequired,
      },
      {
        id: 'mw-plus',
        title: MESSAGES.GO_TO_MW_PLUS,
        path: ROUTE_PATH.MW_PLUS,
        hide: (props) => props.hideMwp,
        loginRequired: (props) => props.loginRequired,
      },
    ],
  },
  additional: {
    id: 'additional',
    title: 'Additional / Other Services',
    items: [
      {
        id: 'calculate-premium',
        title: MESSAGES.GO_TO_REVISE_QUICK_QUOTE,
        path: qqPath(ROUTE_PATH.PRODUCT_SELECTION),
        hide: (props) => !props.allowReviseQQMenu,
      },
      {
        id: 'ecbr',
        title: MESSAGES.GO_TO_ECBR,
        hide: (props) => !getToggles().ENABLE_ECBR_ON_PORTAL || props.hideEcbr,
      },
      {
        id: 'ekyc',
        title: MESSAGES.GO_TO_EKYC,
        hide: (props) => !getToggles().ENABLE_EKYC_PORTAL || props.hideEkyc,
      },
      {
        id: 'brochure',
        title: MESSAGES.GO_TO_BROCHURE,
        path: ROUTE_PATH.BROCHURE_LIST,
        hide: (props) => {
          const isAgentAndCs = _.includes([values.AGENT_TYPE_CS, values.AGENT_TYPE_AGENT], props.userAgentType)
          return !getToggles().ENABLE_BROCHURE || !isAgentAndCs || !props.isUserLogin
        },
      },
    ],
  },
  tools: {
    id: 'tools',
    title: 'Sales tool',
    items: [
      {
        id: 'i-pro',
        isSaleFlow: true,
        externalUrl: `${getAppConfig().PORTAL_IPRO_URL}`,
      },
      {
        id: 'my-wealth',
        isSaleFlow: true,
        externalUrl: `${getAppConfig().PORTAL_MY_WEALTH_URL}`,
        disabled: true,
      },
    ],
  },
  privilege: {
    id: 'privilege',
    title: 'Privilege',
    items: [
      {
        id: 'privilege',
        title: MESSAGES.GO_TO_PRIVILEGE,
        isSaleFlow: true,
        externalUrl: `${getAppConfig().PORTAL_PRIVILEGE_URL}`,
      },
    ],
  },
}

export const RenderMenuGroup = (props) => {
  const { id, title, items, hideEcbr, hideEkyc } = props
  const { history, startFNAFlow, startQQFlow, openDf2fApplication } = props
  const hideTitleAdditional = title === 'Additional / Other Services' && hideEcbr && hideEkyc
  const handleMenuClick = (item) => {
    const { id, path } = item
    if (id === 'remote-selling') {
      openDf2fApplication()
    } else {
      history.push(path)
    }

    if (id === 'fna') {
      startFNAFlow()
    } else if (id === 'quick-quote' || id === 'calculate-premium') {
      startQQFlow()
    }

    if (id === 'ecbr') {
      if (window.cordova) {
        const ecbrWindow = window.cordova.InAppBrowser.open(
          getAppConfig().PORTAL_ECBR_URL + '?location=mobile',
          '_blank',
          'location=no,zoom=no'
        )

        ecbrWindow.addEventListener('loadstart', (event) => {
          if (event.url.indexOf('return-az') > 0) {
            ecbrWindow.close()
          }
        })
      } else {
        window.open(getAppConfig().PORTAL_ECBR_URL + '?location=web')
      }
    }

    if (id === 'ekyc') {
      if (window.cordova) {
        window.cordova.InAppBrowser.open(
          getAppConfig().EKYC_DASHBOARD_URL + '?location=mobile',
          '_blank',
          'location=no,zoom=no'
        )
      } else {
        window.open(getAppConfig().EKYC_DASHBOARD_URL + '?location=web')
      }
    }

    if (id === 'i-pro') {
      window.open(getAppConfig().PORTAL_IPRO_URL)
    }

    if (id === 'privilege') {
      window.open(getAppConfig().PORTAL_PRIVILEGE_URL)
    }
  }

  return items.length ? (
    <div className={`az-portal__group az-portal__group--${id}`}>
      <div className="az-portal__group__title">
        <h2 className="az-portal__group__title-text">{!hideTitleAdditional ? title : ''}</h2>
      </div>
      <div className="az-portal__group__content">
        {items
          .filter((item) => !item.hide || !item.hide(props))
          .map((item) => {
            const disabled = item.disabled && item.disabled(props)
            return (
              <button key={item.id} disabled={disabled} data-id={item.id} onClick={() => handleMenuClick(item)}>
                <span className={id === 'tools' ? item.id : 'button-container'}>
                  <span className={id === 'tools' ? '' : 'title-text'}>{item.title}</span>
                </span>
              </button>
            )
          })}
      </div>
    </div>
  ) : null
}

export const PortalMenu = (props) => {
  const isHideProspect = props.hideOpty && props.hideMwp
  const menulist = _.filter(
    PortalMenuConfigs,
    (menu) => menu.id === 'az' || menu.id === 'additional' || (menu.id === 'prospect' && !isHideProspect)
  )

  return (
    <div className="az-portal">
      <TitlePortal />
      <div className="az-portal__content">
        {menulist.map((menu, index) => (
          <RenderMenuGroup key={index} {...menu} {...props} />
        ))}
      </div>
    </div>
  )
}

PortalMenu.propTypes = {
  startQQFlow: PropTypes.func,
  startFNAFlow: PropTypes.func,
}
PortalMenu.defaultProps = {
  startQQFlow() {},
  startFNAFlow() {},
}

const mapStateToProps = (state) => {
  const userAgentType = getUserAgentType(state)
  const isLb = userAgentType === values.AGENT_TYPE_LB
  const isFSE = userAgentType === values.AGENT_TYPE_FSE
  const isBA = userAgentType === values.AGENT_TYPE_BANCA
  const mobileDetect = new MobileDetect(window.navigator.userAgent)
  const isDesktop = !mobileDetect.mobile() && !mobileDetect.tablet()

  const isWS = userAgentType === values.AGENT_TYPE_CS

  let hideHistory = false
  if (isWS) {
    if (!getToggles().ENABLE_CS_USERTYPE) {
      hideHistory = true
    }
  } else if (isLb) {
    if (getToggles().ENABLE_BANCA_BLOCK_ESUB_WEB_VIEW) {
      hideHistory = !IsRealDevice
    } else if (getToggles().DISABLE_HISTORY_FOR_LB) {
      hideHistory = isDesktop
    }
  } else if (isFSE || isBA) {
    hideHistory = true
  }

  const hideEcbr = !isLoggedIn(state) || !getAppConfig().ALLOWED_ECBR_MENU.includes(userAgentType)

  const hideEkyc = !isLoggedIn(state) || !getAppConfig().ALLOWED_EKYC_MENU.includes(userAgentType)
  let hideRemoteSelling = true
  if (getToggles().ENABLE_APPMAN_REMOTE_SELLING && !hideEkyc) {
    if (getToggles().ENABLE_SHOW_REMOTESELLING_ONWEB) {
      hideRemoteSelling = false
    } else {
      hideRemoteSelling = !IsRealDevice
    }
  }

  const hideOpty = !isAllowIproMenu(state)
  const hideMwp = !isAllowMwpMenu(state)
  const allowReviseQQMenu = isAllowReviseQQMenu(state)
  const disableHistory = hideHistory
  const isUserLogin = isLoggedIn(state)
  const isUserOnline = getIsUserOnline(state)
  const loginRequired = !isLoggedIn(state)

  return {
    hideHistory,
    hideEcbr,
    hideEkyc,
    disableHistory,
    isUserLogin,
    isUserOnline,
    loginRequired,
    hideRemoteSelling,
    userAgentType,
    hideOpty,
    hideMwp,
    allowReviseQQMenu,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    startQQFlow: bindActionCreators(startQQFlow, dispatch),
    startFNAFlow: bindActionCreators(startFNAFlow, dispatch),
    resetQuoteForm: bindActionCreators(resetQuoteForm, dispatch),
    needLogin: bindActionCreators(loginRequest, dispatch),
    setNotification: bindActionCreators(setNotificationMessage, dispatch),
    setSelectedRemoteSelling: bindActionCreators(setSelectedRemoteSelling, dispatch),
    loginRequest: bindActionCreators(loginRequest, dispatch),
    resetSelectedRemoteSelling: bindActionCreators(resetSelectedRemoteSelling, dispatch),
    openDf2fApplication: bindActionCreators(openDf2fApplication, dispatch),
    checkVersionAppUpdate: bindActionCreators(checkVersionAppUpdate, dispatch),
    applicationCheckVersion: bindActionCreators(applicationCheckVersion, dispatch),
    applicationCheckVersionValidVersion: bindActionCreators(applicationCheckVersionValidVersion, dispatch),
    applicationCheckVersionInvalidVersion: bindActionCreators(applicationCheckVersionInvalidVersion, dispatch),
  }
}

export const PortalContainer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {
      this.props.resetQuoteForm()
      this.props.resetSelectedRemoteSelling()
      const userInfo = JSON.parse(localStorage.getItem('userInfo'))
      const azMWMinVersion = _.get(userInfo, 'azMWMinVersion', null)
      if (getToggles().ENABLE_CHECK_TARGET_VERSION && IsRealDevice && azMWMinVersion) {
        this.props.applicationCheckVersion()
        const { checkInvalid } = {
          checkValid: this.props.applicationCheckVersionValidVersion(),
          checkInvalid: this.props.applicationCheckVersionInvalidVersion(),
        }
        if (checkInvalid) {
          return
        }
      }
    },
  })
)(PortalMenu)

export default PortalContainer
