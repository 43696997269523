// @flow

import type { Rider, RiderState } from 'core/data-model/rider'
import type { Benefit } from 'core/data-model/rider/benefits'

import RiderBenefitSummary from './rider-benefit-summary'
import RiderWithBenefitModal from './rider-benefit-modal'
import { formatNumber } from 'core/service/lib/number-format'
import { isObject, isNotNil } from 'core/service/lib/type-check'
import Mustache from 'mustache'
import MESSAGES from 'core/data-model/constants/bi-messages'
import { AgentProtectionDisclaimer } from './agent-protection-disclaimer'
import { AdditionalDisclaimer } from './additional-disclaimer'

type HICRiderBenefitProps = {
  rider: Rider & RiderState,
  benefits: Benefit[],
}

type HICRiderBenefitsRowProps = {
  description: string,
  value: string,
}

const renderHICRiderBenefitsRow = (rowNumber: number, hicRiderBenefitsRowProps: HICRiderBenefitsRowProps) => {
  const benefitDescription = hicRiderBenefitsRowProps.description
    .split('\n')
    .map((text) => <div key={`${text}-${rowNumber}`}>{text}</div>)
  const benefitValue = isNaN(parseInt(hicRiderBenefitsRowProps.value))
    ? hicRiderBenefitsRowProps.value
    : formatNumber(parseInt(hicRiderBenefitsRowProps.value))

  return (
    <tr key={`hic-rider-benefit-row-${rowNumber}`} id={`hic-rider-benefit-row-${rowNumber}`}>
      <td>
        <ol start={`${rowNumber}`}>
          <li> {benefitDescription}</li>
        </ol>
      </td>
      <td>{benefitValue}</td>
    </tr>
  )
}

const renderHICRiderBenefitsTable = (benefits: Benefit[]) => {
  return (
    <div className="details-table">
      <table id="hic-benefit-table">
        <thead>
          <tr>
            <td>{MESSAGES.BENEFIT_LIST}</td>
            <td>{MESSAGES.BENEFIT_BAHT}</td>
          </tr>
        </thead>
        <tbody>
          {benefits.map((benefit, index) => {
            return renderHICRiderBenefitsRow(index + 1, {
              description: isObject(benefit) && isNotNil(benefit.description) ? benefit.description : '',
              value: isObject(benefit) && isNotNil(benefit.value) ? benefit.value : '',
            })
          })}
        </tbody>
      </table>
      <div className="disclaimer">
        <div>{Mustache.render(MESSAGES.DISCLAIMER_RIDER_BENEFIT, 'HIC')}</div>
      </div>
    </div>
  )
}

const HICRiderBenefit = ({ rider, benefits }: HICRiderBenefitProps) => {
  const title = `${rider.description} ${rider.name}`
  const benefitTable = benefits ? renderHICRiderBenefitsTable(benefits) : null
  return (
    <RiderWithBenefitModal
      id="hic-rider-benefit"
      className="rider hic-rider"
      title={title}
      modalChildren={benefitTable}
    >
      <RiderBenefitSummary rider={rider} />
      {benefitTable}
      <AgentProtectionDisclaimer rider={rider} />
      <AdditionalDisclaimer rider={rider} />
    </RiderWithBenefitModal>
  )
}

export default HICRiderBenefit
