import { getUserAgentType } from 'identity/selectors'
import { cond, constant, negate, overEvery } from 'lodash/fp'
import {
  getLstuForeignConsent,
  getLstuOverRiskConsent,
  isLstuForeignConsentNeeded,
  isLstuOverRiskConsentNeeded,
} from 'quick-quote/product-investment/fund-allocation/lstu/selectors'
import {
  getRppForeignConsent,
  getRppOverRiskConsent,
  isRppForeignConsentNeeded,
  isRppOverRiskConsentNeeded,
} from 'quick-quote/product-investment/fund-allocation/rpp/selectors'
import {
  getAgentComment,
  getExchangeQuestion,
  getQuestionnaire,
  getRiskLevelResult,
  totalScore,
} from 'quick-quote/product-investment/risk-questionnaire/selectors'

export const mapInvestmentRisk = (state) => {
  return {
    questionnaires: getQuestionnaire(state),
    riskLevel: getRiskLevelResult(state).value,
    rpqScore: totalScore(getQuestionnaire(state)),
    agentType: getUserAgentType(state),
    agentComment: getAgentComment(state),
    acceptExchangeRate: getExchangeQuestion(state).selectedChoice === 1,
    allowHigherRiskInvestment: allowHigherRiskInvestment(state),
    allowForeignInvestment: allowForeignInvestment(state),
  }
}

export const allowHigherRiskInvestment = (state) => {
  return cond([
    [overEvery([isRppOverRiskConsentNeeded, negate(isLstuOverRiskConsentNeeded)]), getRppOverRiskConsent],
    [overEvery([negate(isRppOverRiskConsentNeeded), isLstuOverRiskConsentNeeded]), getLstuOverRiskConsent],
    [
      overEvery([isRppOverRiskConsentNeeded, isLstuOverRiskConsentNeeded]),
      overEvery([getRppOverRiskConsent, getLstuOverRiskConsent]),
    ],
    [overEvery([negate(isRppOverRiskConsentNeeded), negate(isLstuOverRiskConsentNeeded)]), constant(false)],
  ])(state)
}

export const allowForeignInvestment = (state) => {
  return cond([
    [overEvery([isRppForeignConsentNeeded, negate(isLstuForeignConsentNeeded)]), getRppForeignConsent],
    [overEvery([negate(isRppForeignConsentNeeded), isLstuForeignConsentNeeded]), getLstuForeignConsent],
    [
      overEvery([isRppForeignConsentNeeded, isLstuForeignConsentNeeded]),
      overEvery([getRppForeignConsent, getLstuForeignConsent]),
    ],
    [overEvery([negate(isRppForeignConsentNeeded), negate(isLstuForeignConsentNeeded)]), constant(false)],
  ])(state)
}
