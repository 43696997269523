import { flatten } from 'lodash/fp'
import { createField, createSectionCreator } from 'e-submission/domain/data-model/form/utils'

export const ConsentSection = createSectionCreator((app, id, section) => {
  return flatten([
    createField(`EDA.consent`, [
      {
        id: `EDA.consent`,
        c: 'ConsentPage',
      },
    ]),
  ])
})
