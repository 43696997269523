// @flow

import _ from 'lodash'
import type { DisplayProduct } from 'core/service/display-product'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { BasicPremium$, SumAssured$, TotalPremium$ } from 'core/data-model/basic-plan'
import type { Age } from 'core/data-model/insured'

import CONSTANTS from 'core/data-model/constants/values'

import { isNil, isNotNil } from 'core/service/lib/type-check'
import { isValidAge } from 'core/service/insured/birthdate'
import { createSelector } from 'reselect'
import { roundUp } from 'core/service/lib/number-format'

import { getSelectedDisplayProduct, getDisplayProducts } from 'quick-quote/product-selection/selectors'
import { getAge } from 'quick-quote/insured-information/selectors'
import { canProceedToCoveragePlan } from 'quick-quote/selectors/next-tab-proceeding'
import DEFAULTS from 'core/data-model/constants/defaults'

import {
  getSumAssured$,
  getSelectedRiders,
  getBasicPremium$,
  getTotalPremium$,
  getBasicPlanErrorMessage,
} from 'quick-quote/product-common/coverage-plan/selectors'

// import { getLoanAmount, getLoanTerm, getCoverageTerm } from 'quick-quote/product-loan/coverage-plan/redux'

export const getLoanAmount = (state: AppState) => state.loan.coveragePlan.loan.amount.value
export const getLoanAmountErrors = (state: AppState) => state.loan.coveragePlan.loan.amount.errors
export const getLoanPeriod = (state: AppState): number => state.loan.coveragePlan.loan.term.value
export const getLoanPeriodAll = (state: AppState): Object => state.loan.coveragePlan.loan.term
export const getLoanPeriodErrors = (state: AppState) => state.loan.coveragePlan.loan.term.errors
export const getLoan = (state: AppState) => state.loan
export const getInterestRate = (state: AppState) => state.loan.coveragePlan.loan.interestRate
export const getInterestRateValue = (state: AppState) => state.loan.coveragePlan.loan.interestRate.value
export const getSumAssuredContract = (state: AppState) => state.loan.coveragePlan.loan.sumAssuredContract

export const getAvailableCoveragePeriods = (state: AppState) => state.loan.coveragePlan.coverage.availableTerms
export const getPaymentMethod = (state: AppState): string => state.loan.coveragePlan.coverage.paymentMethod
export const getSumAssured = (state: AppState) => state.loan.coveragePlan.coverage.sumAssured.value
export const getSumAssuredErrors = (state: AppState) => state.loan.coveragePlan.coverage.sumAssured.errors
export const getCoveragePeriod = (state: AppState): number => state.loan.coveragePlan.coverage.term.value
export const getCoveragePeriodAll = (state: AppState): number => state.loan.coveragePlan.coverage.term
export const getCoveragePeriodErrors = (state: AppState) => state.loan.coveragePlan.coverage.term.errors
export const getPremium = (state: AppState) => state.loan.coveragePlan.coverage.premium

export const getPremiumPerMonthGLTSP = createSelector(
  getInterestRateValue,
  getLoanPeriod,
  getBasicPremium$,
  (interestRate, loanPeriod, basicPremium): number => {
    const rate = interestRate / 100 / 12
    const npm = loanPeriod * 12
    const PMT = (rate * basicPremium.value * Math.pow(1 + rate, npm)) / (1 - Math.pow(1 + rate, npm))
    const premiumPerMonth = roundUp(Math.abs(PMT), -2)
    return premiumPerMonth
  }
)

export const canProceedToBenefitIllustration = createSelector(
  getSumAssured$,
  getBasicPremium$,
  getTotalPremium$,
  getBasicPlanErrorMessage,
  getSelectedRiders,
  canProceedToCoveragePlan,
  getSelectedDisplayProduct,
  (
    sumAssured$: SumAssured$,
    basicPremium: BasicPremium$,
    totalPremium$: TotalPremium$,
    basicPlanErrorMessage: string,
    selectedRiders: (Rider & RiderState)[],
    canProceedToCoveragePlan: boolean,
    selectedDisplayProduct: DisplayProduct
  ): boolean => {
    const selectedRiderInvalidMessages = selectedRiders.filter((rider) =>
      isNotNil(rider.errors.find((error) => error.type === CONSTANTS.VALIDATION))
    )

    const canProceed =
      canProceedToCoveragePlan &&
      sumAssured$.isOk &&
      basicPremium.isOk &&
      totalPremium$.isOk &&
      _.isEmpty(selectedRiderInvalidMessages) &&
      _.isEmpty(basicPlanErrorMessage)
    if (selectedDisplayProduct.type === CONSTANTS.CAMPAIGN_PRODUCT_TYPE) {
      const selectedRiderCodes = selectedRiders.map((rider) => rider.code)
      return (
        canProceed &&
        selectedDisplayProduct.requiredRiders != null &&
        selectedDisplayProduct.requiredRiders.every((riderCode) => {
          return selectedRiderCodes.indexOf(riderCode) > -1
        })
      )
    }
    return canProceed
  }
)

export const getValidSelectedRiders = createSelector(getSelectedRiders, (selectedRiders) => {
  return selectedRiders.filter((rider) =>
    isNil(rider.errors.find((error) => error.type !== CONSTANTS.ADDITIONAL_INFORMATION))
  )
})

export const getSelectableProductMRTA = createSelector(getAge, getDisplayProducts, (age: Age, products) => {
  const productGroup = _.filter(products, (product) =>
    _.includes(CONSTANTS.MRTA_CODE_GROUP_ACTIVE, _.get(product, 'basicPlanCode'))
  )
  const validProducts = _.filter(productGroup, (product) => isValidAge(age.value, age.unit, _.get(product, 'entryAge')))
  const validProductCodes = validProducts.map((product) => product.basicPlanCode)

  return validProductCodes
})

export const getSelectedProductMRTA = createSelector(
  getSelectedDisplayProduct,
  getSelectableProductMRTA,
  (basicPlan, productSelectable) => {
    return productSelectable.includes(basicPlan.basicPlanCode)
      ? basicPlan.basicPlanCode
      : DEFAULTS.DEFAULT_MRTA_PLUS_PRODUCT_CODE
  }
)

export const getDescriptionProductMRTA = createSelector(getSelectedDisplayProduct, (basicPlan: DisplayProduct) => {
  switch (basicPlan.basicPlanCode) {
    case 'MRTA_PLUS':
      return DEFAULTS.DEFAULT_MRTA_DESCRIPTION_PRODUCT_PLAN.MRTA_PLUS
    case 'MRTA_FULL':
      return DEFAULTS.DEFAULT_MRTA_DESCRIPTION_PRODUCT_PLAN.MRTA_FULL
    case 'MRTA_TRUNCATE':
      return DEFAULTS.DEFAULT_MRTA_DESCRIPTION_PRODUCT_PLAN.MRTA_TRUNCATE
    default:
      return ''
  }
})

export const getOptionsLoanPeriod = createSelector(getSelectedDisplayProduct, (basicPlan: DisplayProduct) => {
  switch (basicPlan.basicPlanCode) {
    case 'MRTA_SIMPLY':
    case 'MRTA_PLUS':
    case 'MRTA_FULL':
      return DEFAULTS.DEFAULT_LOAN_PERIOD.MRTA
    case 'MRTA_TRUNCATE':
      return DEFAULTS.DEFAULT_LOAN_PERIOD.MRTA.filter((v) => v.value >= 10 && v.value <= 30)
    case 'GLTSP':
      return DEFAULTS.DEFAULT_LOAN_PERIOD.GLTSP
    default:
      return []
  }
})

export const getOptionsCoveragePeriod = createSelector(
  getSelectedDisplayProduct,
  getAge,
  (basicPlan, age: DisplayProduct) => {
    const basicPlanCode = basicPlan.basicPlanCode
    const rangeCoveragePeriod = DEFAULTS.DEFAULT_MRTA_GLTSP_RANGE_COVERAGE_PERIOD[basicPlanCode]
    const maxAgeCoverage = 70 - age.value
    const minCoverage = rangeCoveragePeriod.minimum
    const maxCoverage = [
      DEFAULTS.DEFAULT_MRTA_SIMPLY_PRODUCT_CODE,
      DEFAULTS.DEFAULT_MRTA_PLUS_PRODUCT_CODE,
      DEFAULTS.DEFAULT_MRTA_FULL_PRODUCT_CODE,
      DEFAULTS.DEFAULT_GLTSP_PRODUCT_CODE,
    ].includes(basicPlanCode)
      ? maxAgeCoverage >= rangeCoveragePeriod.maximum
        ? rangeCoveragePeriod.maximum
        : maxAgeCoverage
      : rangeCoveragePeriod.maximum

    const result = DEFAULTS.DEFAULT_LOAN_PERIOD.GLTSP.filter(
      (period) => period.value >= minCoverage && period.value <= maxCoverage
    )
    return result
  }
)

export const getPercentSumAssured = createSelector(getSumAssured$, getLoanAmount, (sumAssrued, loanAmount) => {
  return Math.ceil((sumAssrued.value / loanAmount) * 100)
})

export const getPercentCoveragePeriod = createSelector(
  getCoveragePeriod,
  getLoanPeriod,
  (coveragePeriod, loanPeriod) => {
    return Math.ceil((coveragePeriod / loanPeriod) * 100)
  }
)

export const isMRTAProduct = createSelector(
  getSelectedDisplayProduct,
  (basicPlan: DisplayProduct) =>
    basicPlan.category === CONSTANTS.MRTA && basicPlan.basicPlanCode !== CONSTANTS.GLTSP_CODE.GLTSP
)

export const isMRTAGroup = (basicPlanCode: string) => {
  return Object.values(CONSTANTS.MRTA_CODE).includes(basicPlanCode)
}
export const isGLTSPGroup = (basicPlanCode: string) => {
  return Object.values(CONSTANTS.GLTSP_CODE).includes(basicPlanCode)
}
export const getGLTSPPlancode = (planCode: string, sumAssuredContract: number) => {
  if (sumAssuredContract < 500000) {
    return planCode.substring(0, 5) + 'A'
  } else {
    return planCode.substring(0, 5) + 'B'
  }
}

export const isGLTSPProduct = createSelector(
  getSelectedDisplayProduct,
  (basicPlan: DisplayProduct) =>
    basicPlan.category === CONSTANTS.MRTA && basicPlan.basicPlanCode === CONSTANTS.GLTSP_CODE.GLTSP
)

export const getMRTATRUNCATELoanPeriod = (loanPeriod: number, age: Age) => {
  return Math.min(loanPeriod, CONSTANTS.MRTA_TRUNCATE_MAX_LOAN_INSURE_AGE - age.value)
}
