//@flow
import type { FundAllocation, FundAllocationToggleItem } from 'core/data-model/investment'
import _ from 'lodash'

export const updateFundAllocation = (
  fundAllocations: FundAllocationToggleItem[],
  changedFund: FundAllocation
): FundAllocationToggleItem[] => {
  const fundAllocationsByCode = _.keyBy(fundAllocations, 'code')
  fundAllocationsByCode[changedFund.code] = {
    ...fundAllocationsByCode[changedFund.code],
    fundAllocation: changedFund.fundAllocation,
  }
  return _.values(fundAllocationsByCode)
}
