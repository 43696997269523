import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const propTypes = {
  className: PropTypes.string,
  documentName: PropTypes.string,
}

const defaultProps = {
  className: '',
  documentName: 'Document',
}

export const PDFPassWordProtected = styled(({ className, documentName }) => {
  const message = `'${documentName}' is protected. Please enter a Document Open Password.`
  return (
    <div className={className}>
      <div className="bg"></div>
      <div className="message-box">
        <div className="message-body">
          <p>{message}</p>
          <p className="example-password-input">
            <span>
              Enter Password:{' '}
              <span className="password-input">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </span>
            </span>
          </p>
        </div>
      </div>
      <div className="arrow">
        <div />
      </div>
    </div>
  )
})`
  position: relative;
  max-width: 320px;
  margin: 0 auto;
  padding-bottom: 30px;
  color: #2425aa;
  overflow: hidden;
  font-size: 1rem;

  .bg {
    width: 100%;
    margin: -30px 0;
    position: relative;

    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    &::before {
      position: absolute;
      top: 30px;
      left: 30px;
      bottom: 30px;
      right: 30px;
      content: '';
      display: block;
      background: white;
      border-radius: 50%;
    }
  }

  .arrow {
    position: absolute;
    width: 100%;
    bottom: 0;

    div,
    div::after,
    div::before {
      background: #2425aa;
      width 4px;
      border-radius: 8px;
    }

    div {
      height: 75px;
      margin: 0 auto;
      position: relative;

      &::before,
      &::after {
        content: '';
        display: block;
        height: 15px;
        position: absolute;
        top: -1px;
      }

      &::before {
        transform: rotate(45deg);
        left: calc(50% - 7px);
      }
      &::after {
        transform: rotate(-45deg);
        right: calc(50% - 7px);
      }
    }
  }

  .message-box {
    position: absolute;
    width: 100%;
    height: 100%;
    top: -15px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .message-body {
    flex: 1;
    display: block;
    padding: 20px;
    background: white;
    border: solid 3px #2425aa;
    border-radius: 10px;

    p {
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }

  .password-input {
    display: inline-block;
    border: solid 1px #2425aa;
    padding: 6px 18px 6px 8px;
    margin-left: 0.25rem;
    line-height: 0.8;

    span {
      display: inline-block;
      width: 0.4rem;
      height: 0.4rem;
      background: #2425aa;
      border-radius: 50%;

      & + span {
        margin-left: 0.125rem;
      }
    }
  }
`

PDFPassWordProtected.propTypes = propTypes
PDFPassWordProtected.defaultProps = defaultProps

export default PDFPassWordProtected
