// @flow
import type { Rider, RiderState } from 'core/data-model/rider'
import RiderWithBenefitModal from './rider-benefit-modal'
import RiderBenefitSummary from './rider-benefit-summary'
import MESSAGES from 'core/data-model/constants/bi-messages'
import Mustache from 'mustache'
import { AgentProtectionDisclaimer } from './agent-protection-disclaimer'

type WPDDRiderBenefitProps = {
  rider: Rider & RiderState,
}

const WPDDRiderBenefit = ({ rider }: WPDDRiderBenefitProps) => {
  const description = `ผลประโยชน์${rider.description}`
  const title = `${description} (${rider.name})`
  const benefitTable = (
    <div className="details-table">
      <div>{MESSAGES.WPDD_BENEFIT_DEATH_PERMANENTLY_DISABLED}</div>
      <ul>
        <li>{MESSAGES.WPDD_BENEFIT_PREMIUM_WAIVER}</li>
        <li>
          {Mustache.render(MESSAGES.WPDD_BENEFIT_AGE_CLAUSE, {
            riderCoveragePeriod: rider.coveragePeriod.value,
          })}
        </li>
      </ul>
    </div>
  )
  const benefitRider: Rider & RiderState = { ...rider, description }
  return (
    <RiderWithBenefitModal
      id="wpdd-rider-benefit"
      className="rider wpdd-rider"
      title={title}
      modalChildren={benefitTable}
    >
      <RiderBenefitSummary rider={benefitRider} />
      {benefitTable}
      <AgentProtectionDisclaimer rider={rider} />
    </RiderWithBenefitModal>
  )
}

export default WPDDRiderBenefit
