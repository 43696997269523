//@flow
import type { User } from 'core/data-model/identity'
import type { Action } from 'identity/actions'
import {
  USER_FOUND_AUTHZ_CODE_FLOW,
  USER_FOUND,
  REDIRECT_SUCCESS,
  USER_EXPIRED,
  UPDATE_USER_IMAGE,
  SET_DISTRIBUTOR_GROUP,
  UPDATE_USER,
  UPDATE_FSE,
  UPDATE_BANK_BRANCH,
  SET_POSITION_CODE,
  SET_REGISTRATION,
  UPDATE_STAFF_ID,
  UPDATE_STAFF_NAME,
  UPDATE_STAFF_SURNAME,
  UPDATE_STAFF_POSITION,
  UPDATE_CHECK_NATIVE_APPVERSION_USER,
} from './actions'
import { get, omit } from 'lodash/fp'

export type State = {
  user: User,
}

export const initialState: State = {
  user: {
    type: 'Anonymous',
    distributorGroup: 'AGENT',
  },
}

export default (state: State = initialState, action: Action) => {
  switch (action.type) {
    case UPDATE_USER_IMAGE:
      if (state.user.type === 'Anonymous') return state
      return {
        ...state,
        user: {
          ...state.user,
          type: 'Authenticated',
          profile: {
            ...state.user.profile,
            user_image: action.payload,
          },
        },
      }
    case USER_FOUND_AUTHZ_CODE_FLOW:
      const userInfo = action.payload
      return {
        user: {
          type: 'Authenticated',
          access_token: get('access_token')(userInfo),
          profile: omit('access_token')(userInfo),
        },
      }
    case UPDATE_USER:
      return {
        user: { ...state.user, ...action.payload },
      }
    case UPDATE_CHECK_NATIVE_APPVERSION_USER:
      return {
        user: { ...state.user, headerversioncheck: 'true' },
      }
    case REDIRECT_SUCCESS:
    case USER_FOUND:
      const user = action.payload
      const user_image = state.user.type === 'Authenticated' ? state.user.profile.user_image : undefined
      return {
        user: {
          ...user,
          type: 'Authenticated',
          profile: {
            ...user.profile,
            user_image: user_image,
          },
        },
      }
    case USER_EXPIRED:
      return initialState
    case SET_DISTRIBUTOR_GROUP:
      return {
        ...state,
        user: {
          ...state.user,
          distributorGroup: action.payload,
        },
      }
    case UPDATE_FSE:
      return {
        ...state,
        user: {
          ...state.user,
          fse: action.payload,
        },
      }
    case UPDATE_BANK_BRANCH:
      return {
        ...state,
        user: {
          ...state.user,
          office: action.payload,
        },
      }
    case SET_POSITION_CODE:
      return {
        ...state,
        user: {
          ...state.user,
          positionCode: action.payload,
        },
      }
    case SET_REGISTRATION:
      return {
        ...state,
        user: {
          ...state.user,
          registrations: action.payload,
        },
      }
    case UPDATE_STAFF_ID:
      return {
        ...state,
        user: {
          ...state.user,
          staffId: action.payload,
        },
      }
    case UPDATE_STAFF_NAME:
      return {
        ...state,
        user: {
          ...state.user,
          staffName: action.payload,
        },
      }
    case UPDATE_STAFF_SURNAME:
      return {
        ...state,
        user: {
          ...state.user,
          staffSurname: action.payload,
        },
      }
    case UPDATE_STAFF_POSITION:
      return {
        ...state,
        user: {
          ...state.user,
          staffPosition: action.payload,
        },
      }
    default:
      return state
  }
}
