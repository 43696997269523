// @flow
import type { AppState } from 'quick-quote/reducers'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { RadioButtonGroup } from 'common-components'
import { selectProductKoomverCompensate } from 'quick-quote/product-life-ready/actions'
import { getSelectedDisplayProductCode } from 'quick-quote/product-selection/selectors'
import BIMESSAGES from 'core/data-model/constants/bi-messages'

export const KoomverCompensateProductSelection = ({
  getSelectedDisplayProductCode,
  selectProductKoomverCompensate,
}) => {
  const productKoomverCompensate = [
    {
      text: BIMESSAGES.PLAN_1,
      value: 'KOOMVER-COMPENSATE-1',
    },
    {
      text: BIMESSAGES.PLAN_2,
      value: 'KOOMVER-COMPENSATE-2',
    },
  ]

  return (
    <div className="form-group">
      <div className="form-group-title">
        <span className="form-group-title-text">{BIMESSAGES.QQ_SELECT_PLAN}</span>
      </div>
      <RadioButtonGroup
        keyToGetValue="value"
        className="model-factor"
        onChange={selectProductKoomverCompensate}
        value={getSelectedDisplayProductCode}
        options={productKoomverCompensate}
      />
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  getSelectedDisplayProductCode: getSelectedDisplayProductCode(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  selectProductKoomverCompensate: bindActionCreators(selectProductKoomverCompensate, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(KoomverCompensateProductSelection)
