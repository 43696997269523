// @flow
import type { DisplayProduct } from 'core/service/display-product'
import type { Checked } from 'core/service/lib/check'

export const SELECT_PRODUCT_MRTA = 'SELECT_PRODUCT_MRTA'
export type SelectProductMRTA = {
  type: 'SELECT_PRODUCT_MRTA',
  payload: String,
}

export const selectProductMRTA = (productCode: string): SelectProductMRTA => ({
  type: SELECT_PRODUCT_MRTA,
  payload: productCode,
})

export const SELECT_BASIC_PLAN = 'SELECT_BASIC_PLAN'
export type SelectBasicPlan = {
  type: 'SELECT_BASIC_PLAN',
  payload: DisplayProduct,
}
export const selectBasicPlan = (product: DisplayProduct): SelectBasicPlan => ({
  type: SELECT_BASIC_PLAN,
  payload: product,
})

export const MRTA_UPDATE_LOAN_AMOUNT = 'MRTA_UPDATE_LOAN_AMOUNT'
export type UpdateLoanAmount = {
  type: 'MRTA_UPDATE_LOAN_AMOUNT',
  payload: {
    value: number,
    errors: Object[],
  },
}
export const updateLoanAmount = (loanAmount: ?number): UpdateLoanAmount => ({
  type: MRTA_UPDATE_LOAN_AMOUNT,
  payload: {
    value: loanAmount,
    errors: [],
  },
})

export const MRTA_EDIT_LOAN_AMOUNT = 'MRTA_EDIT_LOAN_AMOUNT'
export type EditLoanAmount = {
  type: 'MRTA_EDIT_LOAN_AMOUNT',
  payload: {
    value: number,
    errors: Object[],
  },
}
export const editLoanAmount = (loanAmount: ?number): EditLoanAmount => ({
  type: MRTA_EDIT_LOAN_AMOUNT,
  payload: {
    value: loanAmount,
    errors: [],
  },
})

export const MRTA_UPDATE_LOAN_PERIOD = 'MRTA_UPDATE_LOAN_PERIOD'
export type UpdateLoanPeriod = {
  type: 'MRTA_UPDATE_LOAN_PERIOD',
  payload: {
    value: number,
    errors: Object[],
  },
}
export const updateLoanPeriod = (loanPeriod: ?number): UpdateLoanPeriod => ({
  type: MRTA_UPDATE_LOAN_PERIOD,
  payload: {
    value: loanPeriod,
    errors: [],
  },
})

export const MRTA_EDIT_LOAN_PERIOD = 'MRAT_EDIT_LOAN_PERIOD'
export type EditLoanPeriod = {
  type: 'MRTA_EDIT_LOAN_PERIOD',
  payload: {
    value: number,
    errors: Object[],
  },
}
export const editLoanPeriod = (loanPeriod: ?number): EditLoanPeriod => ({
  type: MRTA_EDIT_LOAN_PERIOD,
  payload: {
    value: loanPeriod,
    errors: [],
  },
})

export const MRTA_UPDATE_COVERAGE_PERIOD = 'MRTA_UPDATE_COVERAGE_PERIOD'
export type UpdateCoveragePeriod = {
  type: 'MRTA_UPDATE_COVERAGE_PERIOD',
  payload: {
    value: number,
    errors: Object[],
  },
}
export const updateCoveragePeriod = (coveragePeriod: ?number): UpdateCoveragePeriod => ({
  type: MRTA_UPDATE_COVERAGE_PERIOD,
  payload: coveragePeriod,
})

export const MRTA_EDIT_COVERAGE_PERIOD = 'MRTA_EDIT_COVERAGE_PERIOD'
export type EditCoveragePeriod = {
  type: 'MRTA_EDIT_COVERAGE_PERIOD',
  payload: {
    value: number,
    errors: Object[],
  },
}
export const editCoveragePeriod = (coveragePeriod: ?number): EditCoveragePeriod => ({
  type: MRTA_EDIT_COVERAGE_PERIOD,
  payload: coveragePeriod,
})

export const MRTA_EDIT_INTEREST_RATE = 'MRTA_EDIT_INTEREST_RATE'
export type EditInterestRate = {
  type: 'MRTA_EDIT_INTEREST_RATE',
  payload: number,
}
export const editInterestRate = (interestRate: ?number): EditInterestRate => ({
  type: MRTA_EDIT_INTEREST_RATE,
  payload: interestRate,
})

export const MRTA_EDIT_SUMASSURED_CONTRACT = 'MRTA_EDIT_SUMASSURED_CONTRACT'
export type EditMRTASumAssuredContract = {
  type: 'MRTA_EDIT_SUMASSURED_CONTRACT',
  payload: number,
}
export const editMRTASumAssuredContract = (sumAssuredContract: ?number): EditMRTASumAssuredContract => ({
  type: MRTA_EDIT_SUMASSURED_CONTRACT,
  payload: sumAssuredContract,
})

export const MRTA_EDIT_PAYMENT_SELECTED_MRTA = 'MRTA_EDIT_PAYMENT_SELECTED_MRTA'
export type EditPaymentSelectedMRTA = {
  type: 'MRTA_EDIT_PAYMENT_SELECTED_MRTA',
  payload: string,
}
export const editPaymentSelectedMRTA = (paymentSelectedMRTA: ?string): EditPaymentSelectedMRTA => ({
  type: MRTA_EDIT_PAYMENT_SELECTED_MRTA,
  payload: paymentSelectedMRTA,
})

export const MRTA_UPDATE_ERROR_STATUS = 'MRTA_UPDATE_ERROR_STATUS'
export type MRTAUpdateErrorStatus = {
  type: 'MRTA_UPDATE_ERROR_STATUS',
  payload: boolean,
}
export const updateErrorStatus = (value) => ({
  type: MRTA_UPDATE_ERROR_STATUS,
  payload: value,
})

export const MRTA_UPDATE_SUM_ASSURED_IN_LOAN = 'MRTA_UPDATE_SUM_ASSURED_IN_LOAN'
type MRTAUpdateSumAssuredInLoan = {
  type: 'MRTA_UPDATE_SUM_ASSURED_IN_LOAN',
  payload: number,
}
export const updateMRTASumAssuredInLoan = (sumAssured: number): MRTAUpdateSumAssuredInLoan => ({
  type: MRTA_UPDATE_SUM_ASSURED_IN_LOAN,
  payload: sumAssured,
})

export const MRTA_UPDATE_CODE_IN_SELECTED_DISPLAY_PRODUCT = 'MRTA_UPDATE_CODE_IN_SELECTED_DISPLAY_PRODUCT'
type MRTAUpdateCodeInSelectedDisplayProduct = {
  type: 'MRTA_UPDATE_CODE_IN_SELECTED_DISPLAY_PRODUCT',
  payload: string,
}
export const updateCodeInSelectedDisplayProduct = (code: string): MRTAUpdateCodeInSelectedDisplayProduct => ({
  type: MRTA_UPDATE_CODE_IN_SELECTED_DISPLAY_PRODUCT,
  payload: code,
})

export const MRTA_UPDATE_COVERAGE_PERIOD_IN_SELECTED_DISPLAY_PRODUCT =
  'MRTA_UPDATE_COVERAGE_PERIOD_IN_SELECTED_DISPLAY_PRODUCT'
type MRTAUpdateCoveragePeriodInSelectedDisplayProduct = {
  type: 'MRTA_UPDATE_COVERAGE_PERIOD_IN_SELECTED_DISPLAY_PRODUCT',
  payload: {
    type: string,
    value: number,
  },
}
export const updateCoveragePeriodInSelectedDisplayProduct = (
  coveragePeriod: string
): MRTAUpdateCoveragePeriodInSelectedDisplayProduct => ({
  type: MRTA_UPDATE_COVERAGE_PERIOD_IN_SELECTED_DISPLAY_PRODUCT,
  payload: coveragePeriod,
})

export const UPDATE_VALIDATE_COVERAGE_PERIOD_ERRORS = 'UPDATE_VALIDATE_COVERAGE_PERIOD_ERRORS'
type CoveagePeriod$ = Checked<number>
type UpdateValidateCoveragePeriodErros = {
  type: 'UPDATE_VALIDATE_COVERAGE_PERIOD_ERRORS',
  payload: CoveagePeriod$,
}
export const updateValidateCoveragePeriodErros = (
  coveragePeriod: ?CoveagePeriod$
): UpdateValidateCoveragePeriodErros => ({
  type: UPDATE_VALIDATE_COVERAGE_PERIOD_ERRORS,
  payload: coveragePeriod,
})

export const UPDATE_VALIDATE_INTEREST_RATE_ERRORS = 'UPDATE_VALIDATE_INTEREST_RATE_ERRORS'
type InterestRate$ = Checked<number>
type UpdateInterestRateErros = {
  type: 'UPDATE_VALIDATE_INTEREST_RATE_ERRORS',
  payload: InterestRate$,
}
export const updateValidateInterestRateErros = (interestRate: ?InterestRate$): UpdateInterestRateErros => ({
  type: UPDATE_VALIDATE_INTEREST_RATE_ERRORS,
  payload: interestRate,
})
