// @flow
import React from 'react'
import PropTypes from 'prop-types'

import RiderWithBenefitModal from './rider-benefit-modal'
import RiderBenefitSummary from './rider-benefit-summary'
import RiderList from './rider-list'

const RenderBenefits = (props) => {
  const { content } = props
  return (
    <div className="tpd-rider-content tpd-rider-content--benefit">
      <p className="heading">{content.title}</p>
      <p className="description">{content.description.join(' ')}</p>
      <RiderList
        start={1}
        name="tpdudr-diseases"
        items={content.diseases}
        render={(item, index) => (
          <div>
            <span className="title">{item.title}</span>
            <span className="rate">{item.rate}</span>
            {item.moreDetails
              ? item.moreDetails.map((moreItem, index) => (
                  <div className="more-details" key={`more-${index}`}>
                    <span className="title">{moreItem.text}</span>
                    {moreItem.list ? (
                      <RiderList
                        name="benefit-more"
                        items={moreItem.list}
                        render={(moreItem, moreIndex) => moreItem.text}
                      />
                    ) : null}
                  </div>
                ))
              : null}
          </div>
        )}
      />
      <p className="description description-2">{content.description_2.join(' ')}</p>
    </div>
  )
}

const RenderNotice = (props) => {
  const { content } = props
  return (
    <div className="tpd-rider-content tpd-rider-content--notice">
      <p className="heading">{content.title}</p>
      <RiderList
        unordered
        name="notice"
        items={content.generic}
        render={(item, index) => (
          <div>
            <span className="description">{item.text.join(' ')}</span>
            {item.list ? (
              <RiderList name="notice-note" items={item.list} render={(moreItem, moreIndex) => moreItem.text} />
            ) : null}
            {item.listFooterText ? <span className="description">{item.listFooterText.join(' ')}</span> : null}
            {item.moreDetails
              ? item.moreDetails.map((moreItem, index) => (
                  <div className="more-details" key={`more-${index}`}>
                    <div className="more-details">
                      <span>{moreItem.text.join(' ')}</span>
                    </div>
                  </div>
                ))
              : null}
          </div>
        )}
      />
    </div>
  )
}

const RenderDisclaimer = (props) => {
  const { content } = props
  return (
    <div className="tpd-rider-content tpd-rider-content--disclaimer">
      <p className="heading">{content.title}</p>
      <p className="description">{content.description.join(' ')}</p>
      <RiderList
        start={1}
        name="diseases"
        items={content.list}
        render={(item, index) => (
          <div>
            <span className="description">{item.text}</span>
            {item.list ? (
              <RiderList
                name="notice-mote"
                items={item.list}
                nested={false}
                render={(moreItem, moreIndex) => moreItem.text}
              />
            ) : null}
          </div>
        )}
      />
    </div>
  )
}
function TPDRiderBenefit(props: Object) {
  const { rider, riderBenefitData } = props
  const title = `${rider.description} ${rider.name}`
  const riderName = rider.name.toLowerCase()

  const { benefits, notice, disclaimer } = riderBenefitData

  const benefitInfo = (
    <div className="tpdudr-details-table">
      <RenderBenefits content={benefits} />
    </div>
  )

  return (
    <RiderWithBenefitModal
      id={`${riderName}-rider-benefit`}
      className={`rider ${riderName}-rider`}
      title={title}
      modalChildren={benefitInfo}
    >
      <RiderBenefitSummary rider={rider} />
      {benefitInfo}
      <RenderDisclaimer content={disclaimer} />
      <RenderNotice content={notice} />
    </RiderWithBenefitModal>
  )
}

const propTypes = {
  rider: PropTypes.shape({
    name: PropTypes.string,
  }),
  riderBenefitData: PropTypes.shape({}),
}
const defaultProps = {
  rider: {
    name: '',
  },
  riderBenefitData: {},
}

TPDRiderBenefit.propTypes = propTypes
TPDRiderBenefit.defaultProps = defaultProps

export default TPDRiderBenefit
