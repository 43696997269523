/* eslint-disable quotes */
import { compact, get } from 'lodash/fp'
import { createSectionCreator, createField } from './utils'
import { POLICY_DELIVERY_BY_EMAIL } from 'e-submission/domain/data-model/constants'

const policyDeliveryField = (app, id) => {
  const isPolicyDeliveryByEmail = get('insured.isPolicyDeliveryByEmail.value')(app)
  let deliveryByEmailInfo = ''
  if (isPolicyDeliveryByEmail === true) {
    deliveryByEmailInfo = 'กรมธรรม์จะถูกจัดส่งทางอีเมลเท่านั้น ขอบคุณที่ร่วมเป็นส่วนหนึ่งในการลดกระดาษกับเรา'
  } else if (isPolicyDeliveryByEmail === false) {
    deliveryByEmailInfo = 'กรมธรรม์ประกันชีวิตจะถูกจัดส่งไปยังท่านทางไปรษณีย์ตามที่อยู่ที่ท่านได้ระบุไว้'
  }
  return compact([
    createField('insured.isPolicyDeliveryByEmail', [
      {
        id: 'insured.isPolicyDeliveryByEmail',
        label: 'ผู้ขอเอาประกันประสงค์ขอเลือกรับกรมธรรม์ในรูปแบบอิเล็กทรอนิกส์',
        c: 'RadioButton',
        required: true,
        p: {
          options: [POLICY_DELIVERY_BY_EMAIL.HAVE, POLICY_DELIVERY_BY_EMAIL.NOT_HAVE],
        },
        infoMessage: deliveryByEmailInfo,
      },
    ]),
  ])
}

export const PolicyDeliverySection = createSectionCreator(policyDeliveryField)
