// @flow

import { combineReducers } from 'redux'
import type { State as CoveragePlanState } from './coverage-plan/redux'
import { reducer as coveragePlan } from './coverage-plan/redux'
import type { State as DocumentState } from './benefit-illustration/reducer'
import { reducer as documentList } from './benefit-illustration/reducer'

export type State = {
  coveragePlan: CoveragePlanState,
  documentList: DocumentState,
}

export const reducer = combineReducers({
  coveragePlan,
  documentList,
})
