//@flow
import { getToggles } from 'quick-quote/feature-toggles'
import { buildRiderWithPlansBenefitTable } from 'core/service/pdf-generation/products/common/sections/riders/common'
import { getMEBExceptionNotes, getMEBExceptionNotes17 } from 'core/service/rider/benefits/exception-notes'
import MESSAGES from 'core/data-model/constants/bi-messages'

import type { Rider, RiderState } from 'core/data-model/rider'
import type { RiderBenefitData } from 'core/data-model/rider/benefits'

type MEBBenefitProps = {
  rider: Rider & RiderState,
  riderBenefitData: RiderBenefitData,
}

export const MEBBenefitSection = ({ rider, riderBenefitData }: MEBBenefitProps) => {
  const benefitTable = buildRiderWithPlansBenefitTable({
    rider,
    riderBenefitData,
  })

  const disclaimerRow = [MESSAGES.DISCLAIMER_MAX_HOSPITAL_BENEFIT, '']
  const exceptionNotes = getToggles().ENABLE_NEW_MEB_PDF_EXCLUSION_TERM
    ? buildExceptionNotes17()
    : buildExceptionNotes()

  return [
    {
      table: tableWithExtendedRow(benefitTable, disclaimerRow, 1),
      layout: 'lightBorder',
      style: 'table',
    },
    {
      ...exceptionNotes,
      marginTop: 15,
    },
  ]
}

const buildExceptionNotes = () => {
  const exceptionNotes = getMEBExceptionNotes()
  return {
    stack: [{ text: MESSAGES.EXCEPTION_NOTE, style: 'sub-title' }, { ol: exceptionNotes.exceptions }],
  }
}

const buildExceptionNotes17 = () => {
  const exceptionNotes = getMEBExceptionNotes17()
  const [exceptionFirstLine, excptionConditionDesc] = exceptionNotes.exceptions
  return {
    stack: [
      {
        text: MESSAGES.EXCEPTION_NOTE,
        style: 'sub-title',
      },
      {
        text: exceptionFirstLine,
      },
      {
        text: `${excptionConditionDesc[0]}`,
      },
      {
        ol: [...excptionConditionDesc[1]],
      },
    ],
  }
}
const tableWithExtendedRow = (table, newRow, position) => {
  const body = table.body
  const newBody = [...body.slice(0, position), newRow, ...body.slice(position)]
  return { ...table, body: newBody }
}
