import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { Modal } from '../../common-components'
import { cancelSubmit, confirmSubmit } from 'quick-quote/actions'

const CenteredHeader = styled.div`
  .modal-header {
    position: relative;
  }
  button {
    position: absolute;
    right: 15px;
    top: 4px;
  }
`

export const _ConfirmSubmitPopup = ({
  notificationMessage,
  onExit,
  history,
  cancelNetworkModal,
  onConfirm,
  hangup,
  isAgentOffline,
  ...props
}) => (
  <Modal
    isOpen={notificationMessage !== 'None'}
    backdrop={true}
    size="lg"
    modalClassName="default-bootstrap remote-selling-modal text-center"
    toggle={() => {
      onExit()
    }}
  >
    <CenteredHeader>
      <ModalHeader
        toggle={() => {
          onExit()
        }}
        style={{
          justifyContent: 'center',
        }}
      >
        คุณแน่ใจหรือไม่ที่จะส่งใบคำขอ เนื่องจากระบบจะไม่อนุญาตให้แก้ไขได้อีกเมื่อส่งใบคำขอ" <br />
        *หากยังอยู่ในวีดีโอคอล ระบบจะวางสายเมื่อกดส่งใบคำขอ
      </ModalHeader>
    </CenteredHeader>
    <ModalBody>
      <p>{notificationMessage.message}</p>
    </ModalBody>
    <ModalFooter style={{ justifyContent: 'right' }}>
      <div className="bottom" style={{ justifyContent: 'right' }}>
        <Button
          color="outline-primary"
          onClick={() => {
            onConfirm()
            onExit()
          }}
        >
          ส่งใบคำขอ
        </Button>
      </div>
    </ModalFooter>
  </Modal>
)

const mapDispatchToProps = (dispatch) => ({
  onConfirm: bindActionCreators(confirmSubmit, dispatch),
  onCancel: bindActionCreators(cancelSubmit, dispatch),
})

export default withRouter(connect(null, mapDispatchToProps)(_ConfirmSubmitPopup))
