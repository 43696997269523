// @flow

// $$FlowFixMe
import moment from 'moment'
import logo from '!url-loader!assets/images/logo-ktaxa-th.png'
import BIMESSAGE from 'core/data-model/constants/bi-messages'
import { getAppConfig } from 'deploy-env/app-config'

const TITLE_FONT_SIZE = 16

export const pageBreak = () => ({ text: '', pageBreak: 'before' })

export const Header = (title: string = '') => {
  const imageComponent = () => ({ image: logo, width: 40 })
  const titleComponent = () => ({
    text: title,
    bold: true,
    fontSize: 18,
    alignment: 'center',
  })

  const headerComponent = () => ({
    widths: ['auto', '*'],
    marginBottom: 5,
    marginTop: -20,
    columns: [imageComponent(), titleComponent()],
  })

  return { stack: [pageBreak(), headerComponent()] }
}

export const Section = (title: string, sectionContent: *[]) => {
  const header = () => Header(title)
  return [header(), { stack: sectionContent }]
}

export const Columns = (sectionContent: *[]) => ({
  columns: sectionContent,
})

export const Shrinked = (row: Object) => ({
  ...row,
  marginTop: -8.5,
  fontSize: 9,
  lineHeight: 0.75,
})

export const Title = (text: string) => ({
  fontSize: TITLE_FONT_SIZE,
  text: Underline(Bold(text)),
})

export const Bold = (input: Object | string | number): Object => {
  if (typeof input === 'object') return { ...input, bold: true }
  return { text: input, bold: true }
}

export const MarginLeft = (number: number, input: Object) => {
  return { ...input, marginLeft: number }
}

export const MarginTop = (number: number, input: Object) => {
  return { ...input, marginTop: number }
}

export const Underline = (input: Object | string) => {
  if (typeof input === 'object') return { ...input, decoration: 'underline' }
  return { text: input, decoration: 'underline' }
}

export const CustomSymbolList = (list: *[], symbol: string = '-') => ({
  type: 'none',
  ul: list.map((l) => {
    if (typeof l === 'object') return { ...l, text: symbol.concat(' ', l.text) }
    return symbol.concat(' ', l)
  }),
})

export const Footer = (productCode: string) => (currentPageWithCover: number, pageCountWithCover: number) => {
  const currentPage = currentPageWithCover - 1
  const pageCount = pageCountWithCover - 1

  const isCoverPage = currentPage === 0

  const page = {
    text: BIMESSAGE.PAGE_NUMBER + ' ' + currentPage + ' / ' + pageCount,
    bold: true,
  }

  const texts = {
    [BIMESSAGE.BI_TYPE_PRODUCT_CODE_IINVEST]: BIMESSAGE.IINVEST_FOOTER,
    [BIMESSAGE.BI_TYPE_PRODUCT_CODE_IWEALTHY]: BIMESSAGE.IWEALTHY_FOOTER,
    [BIMESSAGE.BI_TYPE_PRODUCT_CODE_RPUDR]: BIMESSAGE.RPUDR_FOOTER,
  }

  const currentDate = moment()
    .locale('th')
    .add(543, 'year')
  const day = currentDate.format('DD')
  const month = currentDate.format('MMMM')
  const year = currentDate.format('YYYY')
  const timestamp = currentDate.format('HH:mm')
  const genAtVersion = `AdvisorZone Version ${
    getAppConfig().version ? getAppConfig().version : ''
  } วันที่ ${day} ${month} ${year} เวลา ${timestamp} น.`
  return [
    {
      text: [genAtVersion],
      alignment: 'right',
      marginRight: 87,
    },
    {
      text: isCoverPage ? [] : [texts[productCode], '\t', page],
      alignment: 'right',
      marginRight: 20,
    },
  ]
}

export const RowSpan = (input: string | Object, number: number) => {
  if (typeof input === 'object') return { ...input, rowSpan: number }
  return { text: input, rowSpan: number }
}

export const ColSpan = (input: string | Object, number: number) => {
  if (typeof input === 'object') return { ...input, colSpan: number }
  return { text: input, colSpan: number }
}

export const Center = (input: string | Object): Object => {
  if (typeof input === 'object') return { ...input, alignment: 'center' }
  return { text: input, alignment: 'center' }
}

export const Table = (content: *[], styles: Object) => ({
  table: {
    ...styles,
    body: content,
  },
  ...styles,
})

export const UnderlineWord = (text: string, wordToUnderline: string) => {
  const regexUnderline = new RegExp(wordToUnderline, 'gi')
  const titleTextParts = `${text}`.replace(regexUnderline, `__${wordToUnderline}__`).split('__')
  return titleTextParts.map((part) => {
    return part === wordToUnderline
      ? {
          text: part,
          decoration: 'underline',
        }
      : { text: part }
  })
}
