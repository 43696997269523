// @flow
import { flow, get, last } from 'lodash/fp'
import MESSAGES from 'core/data-model/constants/bi-messages'
import { getLifeSavePro25P15BenefitData, getLifeSavePro20P10BenefitData } from 'core/service/basic-plan/benefit'

import { dashedOutOrWithPrecision } from 'quick-quote/product-life-save-pro/benefit-illustration/components/policy-value/policy-value-row'
import { untilAge } from 'core/service/pdf-generation/products/common/logic'
import { lineBreak } from 'core/service/pdf-generation/utils/builder'
import { addUnderlineDecorator } from 'core/service/pdf-generation/utils/decorator-utils'
import Mustache from 'mustache'
import type { DisplayProduct } from 'core/service/display-product'
import type { PolicyValue } from 'core/service/benefit-illustration/policy-value'
import type { AdditionalBenefit, AdditionalBenefitDetails } from 'core/data-model/basic-plan'

type BenefitValues = {
  sumSurrenderCash: string,
  lastSurrenderCash: string,
  subtotalCashDrop: string,
  lowSavingCashDrop: string,
  mediumSavingCashDrop: string,
  highSavingCashDrop: string,
  deathBenefit: string,
}

const cashDropBenefitDetailTable = (details: AdditionalBenefitDetails[], benefitValue: BenefitValues) => {
  return {
    table: {
      widths: ['85%', '*'],
      body: details.map((detail) => [
        { text: detail.description },
        { text: Mustache.render(detail.benefit, benefitValue), alignment: 'right' },
      ]),
    },
    layout: 'noBorders',
  }
}

export const renderSumssuredDisclaimer = (sumAssuredDisclaimer: string) => {
  return addUnderlineDecorator(sumAssuredDisclaimer)
}

export const renderClaimForm = (cliamFormHeader: string, claimFormList: string[]) => {
  return {
    ol: [
      {
        ol: claimFormList.map((condition) => ({ text: condition })),
      },
    ],
  }
}

export const buildBenefitValues = (
  sumAssured: number,
  {
    yearEndPolicyValues = [],
    subtotalCashDrop = 0,
    subtotalLowSavingCashDrop = 0,
    subtotalMediumSavingCashDrop = 0,
    subtotalHighSavingCashDrop = 0,
  }: PolicyValue
): BenefitValues => {
  const lastSurrenderCash = flow(last, get('surrenderCash'))(yearEndPolicyValues)

  return {
    sumSurrenderCash: dashedOutOrWithPrecision(subtotalCashDrop - lastSurrenderCash, 0),
    lastSurrenderCash: dashedOutOrWithPrecision(lastSurrenderCash, 0),
    subtotalCashDrop: dashedOutOrWithPrecision(subtotalCashDrop, 0),
    lowSavingCashDrop: dashedOutOrWithPrecision(subtotalLowSavingCashDrop, 0),
    mediumSavingCashDrop: dashedOutOrWithPrecision(subtotalMediumSavingCashDrop, 0),
    highSavingCashDrop: dashedOutOrWithPrecision(subtotalHighSavingCashDrop, 0),
    deathBenefit: dashedOutOrWithPrecision(sumAssured, 0),
  }
}

export const lifeSaveProBenefitDetails = (additional: AdditionalBenefit[], benefitValues: BenefitValues): Object[] => {
  const additinalDetails = additional.reduce(
    (accumulator, current) => [
      ...accumulator,
      {
        style: 'sub-title',
        text: current.title,
      },
      cashDropBenefitDetailTable(current.details, benefitValues),
      lineBreak(),
    ],
    []
  )

  return [{ text: '', pageBreak: 'before' }, ...additinalDetails]
}

export const _getBenefitDataBybasicPlan = (basicPlan: DisplayProduct) =>
  get('basicPlanCode')(basicPlan) === '25P15' || get('basicPlanCode')(basicPlan) === '25P15S'
    ? getLifeSavePro25P15BenefitData()
    : getLifeSavePro20P10BenefitData()

export const buildLifeSaveProBenefitsSection = (
  basicPlan: DisplayProduct,
  sumAssured: number,
  policyValue: PolicyValue
): Object[] => {
  const benefitData = _getBenefitDataBybasicPlan(basicPlan)
  const benefitValues = buildBenefitValues(sumAssured, policyValue)
  const benefitDetails = lifeSaveProBenefitDetails(benefitData.additional, benefitValues)

  return [
    {
      text: MESSAGES.BASIC_PLAN_COVERAGE_BENEFITS,
      style: 'sub-title',
    },
    {
      ol: [
        {
          text: Mustache.render(benefitData.paidAndCoveragePeriod, {
            untilAge: untilAge(basicPlan.paymentPeriod),
            paymentPeriod: basicPlan.paymentPeriod.value,
            coveragePeriod: basicPlan.coveragePeriod.value,
          }),
        },
        {
          text: benefitData.coverageBenefits,
        },
        {
          text: Mustache.render(benefitData.dividendBenefits, {
            coveragePeriod: basicPlan.coveragePeriod.value,
          }),
        },
        {
          text: Mustache.render(benefitData.maturityBenefits, {
            coveragePeriod: basicPlan.coveragePeriod.value,
          }),
        },
      ],
    },
    ...benefitDetails,
  ]
}
