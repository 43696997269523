//@flow
import _ from 'lodash'
import Mustache from 'mustache'
import MESSAGES from 'core/data-model/constants/bi-messages'
import values from 'core/data-model/constants/values'
import type { DisplayProduct } from 'core/service/display-product'

export const getThaiBasicPlanDetailsLabelFn = () => (displayProduct: DisplayProduct): string =>
  Mustache.render(MESSAGES.BASIC_PLAN_DETAILS, {
    name: displayProduct.nameThai,
    code: displayProduct.code,
  })

export const getThaiBasicPlanDetailsLabelFnWithOutCode = (displayProduct: DisplayProduct): string =>
  Mustache.render(MESSAGES.BASIC_PLAN_DETAILS_WITHOUT_CODE, {
    name: displayProduct.basicPlanName,
  })

export const getPLBBasicPlanLabel = () => (displayProduct: DisplayProduct) =>
  _.get(displayProduct, 'name').replace(/ \(PLB.*/, '')

export const getS7BasicPlanLabel = () => (displayProduct: DisplayProduct) =>
  MESSAGES.CONTRACTS + _.get(displayProduct, 'nameThai')

export const getThaiBasicPlanLabel = () => (displayProduct: DisplayProduct) => displayProduct.nameThai

export const getBasicPlanNameLabel = () => (displayProduct: DisplayProduct) => displayProduct.basicPlanName

export const getBasicPlanDetailsLabelFnLifeReady = (displayProduct: DisplayProduct): string =>
  Mustache.render(MESSAGES.BASIC_PLAN_DETAILS, {
    name:
      values.LIFEREADY_CAMPAIGN_CODE.includes(displayProduct.code) ||
      values.CANCERX5_CAMPAIGN_CODE.includes(displayProduct.code)
        ? MESSAGES.LIFE_READY_PACKAGE_NAME_THAI
        : values.LIFETREASURE_GROUP_CODE.includes(displayProduct.code)
        ? MESSAGES.LIFE_TREASURE_NAME_THAI
        : displayProduct.basicPlanName,
    code: displayProduct.basicPlanCode,
  })

export const getBasicPlanDetailsLabelFn = () => (displayProduct: DisplayProduct): string =>
  Mustache.render(MESSAGES.BASIC_PLAN_DETAILS, {
    name: MESSAGES.LIFE_PROTECT_18_NAMETHAI,
    code: displayProduct.basicPlanCode,
  })
