//@flow
import _ from 'lodash'
import Mustache from 'mustache'
import MESSAGES from 'core/data-model/constants/bi-messages'
import type { Rider, RiderState } from 'core/data-model/rider'

type Props = {
  riders: (Rider & RiderState)[],
}

const _getRiderFH = (riders: (Rider & RiderState)[]) => _.find(riders, (rider) => rider.code === 'FH')

const _isPackageInstance = (plan: string) => _.includes(['PLATINUM', 'DIAMOND'], plan.toUpperCase())

const _isPlatinum = (plan: string) => _.includes(['PLATINUM'], plan.toUpperCase())
export const getFlexiHealthTitlePolicyValueTable = ({ riders }: Props) => {
  const riderFH = _getRiderFH(riders)
  const plan = _.get(riderFH, 'selectedPlan.plan', '')
  const title = _isPackageInstance(plan) ? MESSAGES.POLICY_VALUE_TABLE_ONE : MESSAGES.POLICY_VALUE_TABLE_TWO
  return Mustache.render(title, { productName: _.get(riderFH, 'description') })
}

export const isPlatinum = ({ riders }: Props) => {
  const riderFH = _getRiderFH(riders)
  const plan = _.get(riderFH, 'selectedPlan.plan', '')
  return _isPlatinum(plan)
}
