import { benefitSummarySection } from './benefit-summary-section'
import { policyValueTableSection } from './policy-value-table-section'
import { taxDeductionBenefit } from './tax-deduction-section'
import { legalDisclaimerSection } from './legal-disclaimer-section'
import { profileSnapshotSection } from './profile-snapshot-section'
import { productBenefitsSection } from './product-benefit-section'

export const section = {
  policyValueTable: policyValueTableSection,
  benefitSummary: benefitSummarySection,
  taxDeductionBenefit: taxDeductionBenefit,
  legalDisclaimer: legalDisclaimerSection,
  profileSnapshot: profileSnapshotSection,
  productBenefits: productBenefitsSection,
}
