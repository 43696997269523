//@flow

import _ from 'lodash'
import { d } from 'core/service/lib/decimal'
import {
  queryPremiumRate,
  calculateRiderPremium,
  getYearsOfCoverage,
  isRequirePayerInfo,
} from 'core/service/rider/rider'

import values from 'core/data-model/constants/values'
import { calculatePeriod, calculateWaivePeriod } from 'core/service/basic-plan/period'
import type { Rider, RiderState, RiderPremiumRateQueryParams } from 'core/data-model/rider'
import type { Gender, Age } from 'core/data-model/insured'
import type { Period } from 'core/data-model/basic-plan'
import type { DisplayProduct } from 'core/service/display-product'

export const calculateTotalRiderPremium = (
  riderPremiumRatesPayload: Object[],
  basicPlan: DisplayProduct,
  basicPremium: number,
  initialInsuredAge: number,
  payerAge: Age,
  payerGender: Gender,
  gender: Gender,
  occupationFactorForRider: { [string]: number },
  modelFactor: number,
  modelFactorPeriod: number,
  basicPaymentPeriod: Period,
  basicCoveragePeriod: Period,
  yearBeginInsuredAge: number,
  isSumYearEnd: Boolean,
  payerOccupationFactorForRider: { [string]: number }
) => (riders: (Rider & RiderState)[]): number => {
  const yearEnd = yearBeginInsuredAge + 1 - initialInsuredAge
  const insuredAge = { value: initialInsuredAge, unit: 'year' }

  const byWhatNeedsToBePaidOnThisYearEnd = isWithinPaymentPeriod(
    yearEnd,
    insuredAge,
    payerAge,
    basicPaymentPeriod,
    basicCoveragePeriod
  )
  const premiumFactor = isSumYearEnd ? modelFactorPeriod : 1
  const toPremium = (rider) =>
    d(
      calculateRiderPremiumBaseOnPremiumStructure(
        riderPremiumRatesPayload,
        basicPlan,
        basicPremium,
        initialInsuredAge,
        yearBeginInsuredAge,
        payerAge,
        payerGender,
        gender,
        occupationFactorForRider,
        modelFactor,
        modelFactorPeriod,
        payerOccupationFactorForRider
      )(rider)
    )

  return riders
    .filter(byWhatNeedsToBePaidOnThisYearEnd)
    .map(toPremium)
    .reduce((sum, premium) => sum.add(premium), d(0))
    .times(d(premiumFactor))
    .toNumber()
}

const calculateRiderPremiumBaseOnPremiumStructure = (
  riderPremiumRatesPayload: Object[],
  basicPlan: DisplayProduct,
  basicPremium: number,
  initialInsuredAge: number,
  yearBeginInsuredAge: number,
  payerAge: Age,
  payerGender: Gender,
  gender: Gender,
  occupationFactorForRider: { [string]: number },
  modelFactor: number,
  modelFactorPeriod: number,
  payerOccupationFactorForRider: { [string]: number }
) => (rider: Rider & RiderState) => {
  const riderPremiumRates = _.find(riderPremiumRatesPayload, (r) => r.riderCode === rider.code)
  const riderPlan = rider.selectedPlan

  const occupationFactor = occupationFactorForRider[rider.code]
  const payerOccupationFactor = payerOccupationFactorForRider[rider.code]
  const basicYearsOfPayment = calculatePeriod(basicPlan.paymentPeriod, initialInsuredAge)
  const queryRiderPremiumRateDependencies: RiderPremiumRateQueryParams = {
    gender: isRequirePayerInfo(rider.code) ? payerGender : gender,
    selectedBasicPlanCode: basicPlan.basicPlanCode,
    selectedRiderPlanCode: riderPlan.planCode,
    yearsOfCoverage: rider.yearsOfCoverage,
    age: selectAgePayerOrInsured(rider, initialInsuredAge, yearBeginInsuredAge, payerAge),
    occupationFactor,
    basicYearsOfPayment: basicYearsOfPayment,
    waivePeriod: calculateWaivePeriod(rider.code, basicYearsOfPayment, initialInsuredAge),
  }

  const riderPremiumRate = riderPremiumRates
    ? queryPremiumRate(rider, riderPremiumRates, queryRiderPremiumRateDependencies)
    : 0

  const allPremiumCalculationDependencies = {
    riderPremiumRate,
    modelFactor,
    riderSumAssured: rider.sumAssured,
    basicPremium,
    occupationFactor,
    modelFactorPeriod,
    payerOccupationFactor,
  }

  return calculateRiderPremium(rider, allPremiumCalculationDependencies)
}

const getAgeByPremiumStructure = (rider: Rider & RiderState, initialAge: number, yearBeginAge: number): Age => {
  return {
    value: rider.premiumStructure === values.PREMIUM_LEVEL ? initialAge : yearBeginAge,
    unit: 'year',
  }
}

export const selectAgePayerOrInsured = (
  rider: Rider & RiderState,
  initialInsuredAge: number,
  yearBeginInsuredAge: number,
  payerAge: Age
) => {
  if (isRequirePayerInfo(rider.code)) {
    return getAgeByPremiumStructure(rider, payerAge.value, payerAge.value + (yearBeginInsuredAge - initialInsuredAge))
  }

  return getAgeByPremiumStructure(rider, initialInsuredAge, yearBeginInsuredAge)
}

const isWithinPaymentPeriod = (
  yearEnd: number,
  age: Age,
  payerAge: Age,
  basicPaymentPeriod: Period,
  basicCoveragePeriod: Period
) => (rider: Rider & RiderState) => {
  const riderPlan = rider.selectedPlan

  const yearsOfCoverage = getYearsOfCoverage(rider, age, payerAge, basicPaymentPeriod, basicCoveragePeriod, riderPlan)

  return yearEnd <= yearsOfCoverage
}
