import _ from 'lodash'
import { omit, debounce, includes } from 'lodash/fp'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { compose, mapProps, onlyUpdateForKeys, withProps } from 'recompose'

import { getCurrentApp } from 'e-submission/apps/selectors'

export default compose(
  withRouter,
  withProps(({ setValue, component }) => ({
    setValue: includes(component, ['TextInput', 'NumberInput', 'DateDropdownInput', 'DateInput'])
      ? debounce(300, setValue)
      : setValue,
  })),
  connect(
    (state) => state,
    (dispatch) => ({ dispatch }),
    (state, { dispatch }, { mergeState, ...props }) => {
      const app = getCurrentApp(state)
      const value = _.get(app, props.id)

      let componentProps = {}
      if (_.isFunction(mergeState)) {
        componentProps = mergeState(state, app, {
          dispatch,
          ...props,
        })
      }

      return {
        ...props,
        value,
        ...componentProps,
      }
    },
    {
      areStatePropsEqual: (a, b) => a === b,
    }
  ),
  mapProps(omit(['mergeState', 'match', 'location', 'history', 'staticContext', 'component'])),
  onlyUpdateForKeys([
    'id',
    'children',
    'value',
    'disabled',
    'checked',
    'documents',
    'required',
    'options',
    'sections',
    'displayType',
  ])
)
