/* istanbul ignore file */

import _ from 'lodash'
import { connect } from 'react-redux'
import { FormGroup } from 'reactstrap'
import { Dropdown } from 'common-components'
import RiderPremium from 'quick-quote/product-common/coverage-plan/components/rider/rider-premium'
import RiderModuleComponent from 'quick-quote/product-common/coverage-plan/components/rider/rider-module-component'

import { editRiderSumAssured, editRiderSelectedPlan } from 'quick-quote/product-common/coverage-plan/actions'

export const getHealthPackage = (packageRiders, previousPlan, plan, areaOfCover) => {
  const newSelectedPlan = _.find(packageRiders, { plan })

  const changedAreaOfCover = previousPlan !== plan ? newSelectedPlan.areasOfCover[0] : areaOfCover
  const planCode = newSelectedPlan.codes[changedAreaOfCover]
  const description = newSelectedPlan.description

  const availableModules = newSelectedPlan.modules.map((m) => ({
    code: m.code[changedAreaOfCover],
    description: m.description,
    isSelected: !!m.isSelected,
  }))
  return { planCode, plan, description, areaOfCover: changedAreaOfCover, modules: availableModules }
}

const FHRiderAdult = ({
  className,
  planOptions,
  rider,
  areasOfCover,
  editAdultHealthPackage,
  toggleRiderModule,
  ...props
}) => {
  return (
    <FormGroup className={`${className} meh`} {...props}>
      <div className="label">
        <strong>เลือกแผน {rider.name}</strong>
        <div className="options">
          <Dropdown
            options={planOptions}
            disabled={Boolean(rider.isReadOnly)}
            onChange={(value) => {
              editAdultHealthPackage(value, rider.selectedPlan.areaOfCover)
            }}
            value={rider.selectedPlan.plan}
          />
        </div>
      </div>
      <div className="options options--margin-bottom-10">
        <Dropdown
          options={areasOfCover}
          disabled={Boolean(rider.isReadOnly)}
          onChange={(value) => {
            editAdultHealthPackage(rider.selectedPlan.plan, value)
          }}
          value={rider.selectedPlan.areaOfCover}
        />
        <div className="link">→</div>
        <RiderPremium
          isSelected={rider.isSelected && rider.isSelectable}
          isSelectable={rider.isSelectable}
          premium={rider.premium}
        />
      </div>
      <div className="label">
        {rider.selectedPlan.modules.map((moduleRider) => {
          return (
            <RiderModuleComponent
              key={moduleRider.code}
              rider={rider}
              moduleRider={moduleRider}
              toggleRiderModule={toggleRiderModule}
            />
          )
        })}
      </div>
    </FormGroup>
  )
}

const mapStateToProps = (state) => ({})

export default connect(
  mapStateToProps,
  { editRiderSumAssured, editRiderSelectedPlan },
  (stateProps, dispatchProps, ownProps) => {
    const { rider } = ownProps
    const { editRiderSumAssured, editRiderSelectedPlan } = dispatchProps

    const planOptions = rider.packageRiders.map(({ plan, description }) => ({
      text: `${plan}(${description})`,
      value: plan,
    }))

    const selectedPackage = _.find(rider.packageRiders, {
      plan: rider.selectedPlan.plan,
    })

    const areasOfCover = _.pick(rider.areasOfCover, selectedPackage.areasOfCover)

    return {
      ...ownProps,
      rider,
      editAdultHealthPackage: (plan, areaOfCover) => {
        const healthPackage = getHealthPackage(rider.packageRiders, selectedPackage.plan, plan, areaOfCover)
        editRiderSumAssured(rider.code, 0)
        editRiderSelectedPlan(rider.code, healthPackage)
      },
      planOptions,
      areasOfCover,
    }
  }
)(FHRiderAdult)
