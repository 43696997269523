// @flow
import { FormGroup } from 'reactstrap'
import { Checkbox } from 'common-components'
import RiderPremium from './rider-premium'

// TODO: [FlexiHealth] Add test for this new component
export const RiderModuleComponent = ({ rider, moduleRider, toggleRiderModule }: *) => {
  const formGroupProps = {
    id: `rider-module-${moduleRider.code}`,
    className: 'item rider',
  }

  const riderChecked = !!moduleRider.isSelected

  return (
    <FormGroup {...formGroupProps}>
      <div className="options">
        <Checkbox
          id={moduleRider.code}
          checked={riderChecked}
          disabled={false}
          onChange={() => {
            toggleRiderModule(rider.code, moduleRider.code, !riderChecked)
          }}
          className="module"
        >
          <span className="custom-control-description">{moduleRider.description}</span>
        </Checkbox>

        <RiderPremium
          isSelected={moduleRider.isSelected}
          premium={moduleRider.premium}
          isSelectable={true}
          validationErrors={false}
        />
      </div>
    </FormGroup>
  )
}

export default RiderModuleComponent
