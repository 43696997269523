// @flow
import _ from 'lodash'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { RiderBenefitData, Benefit } from 'core/data-model/rider/benefits'
import { isString, isObject, isArray } from 'core/service/lib/type-check'
import { formatNumber } from 'core/service/lib/number-format'

import RiderWithBenefitModal from './rider-benefit-modal'
import RiderBenefitSummary from './rider-benefit-summary'
import MESSAGES from 'core/data-model/constants/bi-messages'
import BENEFITS from 'core/data-model/constants/bi-rider'
import { AgentProtectionDisclaimer } from './agent-protection-disclaimer'
import { getToggles } from 'quick-quote/feature-toggles'

type ESCIAndWPEWL85RiderBenefitProps = {
  rider: Rider & RiderState,
  riderBenefitData: RiderBenefitData,
}

const renderESCIAndWPEWL85RiderBenefitsRow = (benefit: Benefit, rowNumber: number) => {
  return (
    <tr key={`esci-wpewl85-rider-benefit-row-${rowNumber}`} id={`esci-wpewl85-rider-benefit-row-${rowNumber}`}>
      <td colSpan={isObject(benefit) && isString(benefit.value) ? '1' : '2'}>
        <div>
          {benefit.description && isString(benefit.description)
            ? benefit.description.split('\n').map((text) => <div key={`${text}-${rowNumber}`}>{text}</div>)
            : ''}
        </div>
      </td>
      {isObject(benefit) && isString(benefit.value) && <td>{benefit.value}</td>}
    </tr>
  )
}

const renderESCIAndWPEWL85BenefitTable = (riderSumAssured, riderBenefitData: RiderBenefitData) => {
  return (
    <div className="details-table">
      <table id="esci-wpewl85-benefit-table">
        <thead>
          <tr>
            <td>{MESSAGES.BENEFIT_LIST}</td>
            <td>{`แผน ${formatNumber(riderSumAssured)}`}</td>
          </tr>
        </thead>
        <tbody>
          {riderBenefitData.benefits && isArray(riderBenefitData.benefits)
            ? riderBenefitData.benefits.map((benefit, index) =>
                renderESCIAndWPEWL85RiderBenefitsRow(benefit, index + 1)
              )
            : ''}
        </tbody>
      </table>
    </div>
  )
}

export const getESCITitle = () => {
  return getToggles().ENABLE_ICARE_REPRICING
    ? BENEFITS.RIDER_ESCI_2020_RIDER_BENEFIT_TITLE
    : BENEFITS.RIDER_ESCI_RIDER_BENEFIT_TITLE
}

const ESCIAndWPEWL85RiderBenefit = ({ rider, riderBenefitData }: ESCIAndWPEWL85RiderBenefitProps) => {
  const title = getESCITitle()
  const riderSumAssured: number = _.get(rider, 'sumAssured')
  const benefitTable = riderBenefitData ? renderESCIAndWPEWL85BenefitTable(riderSumAssured, riderBenefitData) : null

  return (
    <RiderWithBenefitModal
      id="esci-wpewl85-rider-benefit"
      className="rider esci-wpewl85-rider"
      title={title}
      modalChildren={benefitTable}
    >
      <RiderBenefitSummary rider={rider} title={title} />
      {benefitTable}
      <AgentProtectionDisclaimer rider={rider} />
    </RiderWithBenefitModal>
  )
}

export default ESCIAndWPEWL85RiderBenefit
