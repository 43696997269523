// @flow
import { editBirthdate, selectGender } from 'quick-quote/insured-information/actions'
import {
  updateWealthSourceData,
  updateWealthSourceDataComplete,
  validateMyWealthSalePermission,
  getMyWealthProfile,
} from 'quick-quote/my-wealth-plus/actions'
import {
  editRiderSelectedPlan,
  editRiderSumAssured,
  toggleRider,
} from 'quick-quote/product-common/coverage-plan/actions'
import {
  editLumpSum,
  editRegularPremium,
  editRegularTopUp,
  selectModelFactor,
} from 'quick-quote/product-investment/coverage-plan/actions'
import { answerQuestion } from 'quick-quote/product-investment/risk-questionnaire/actions'
import {
  salePermissionValidationCompleted,
  validatingSalePermission,
} from 'quick-quote/product-investment/validate-permission/actions'
import {
  VALIDATE_INVESTMENT_PRODUCT,
  selectProduct,
  selectProductCategory,
} from 'quick-quote/product-selection/actions'
import { handleInvalidResult, validatePermission } from 'quick-quote/product-selection/sagas'
import { delay } from 'redux-saga'
import { call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects'
import {
  MY_WEALTH_PLUS_SALE_PERMISSION_CHECK,
  UPDATE_MY_WEALTH_PLUS_PAYLOAD,
  UPDATE_WEALTH_SOURCE_DATA_COMPLETE,
  INIT_CUSTOMER_PROFILE,
} from './actions'
import { COVERAGE, I_INVEST, I_LINK, I_WEALTHY, RPQ } from './constant'
import { getSalePermission } from './selectors'
import {
  formatDateOfBirth,
  getGenderMwPlus,
  getToggleRidersFromMWPlus,
  mapPaymentPeriodToModelFactor,
  mapRiderBenefitsFromMWPlus,
} from './utils'

export function* updateMWPlusState(history, action): Generator<*, *, *> {
  const { categoryId, categoryPath, productDetail, myWealthPlusProfile, premiumPrefill } = action.payload
  const {
    rpp,
    rtu,
    dateOfBirthThai,
    productCode,
    gender,
    rider,
    paymentPeriod,
    rpq,
    fundList,
    fundListLSTU,
  } = myWealthPlusProfile
  yield [put(validateMyWealthSalePermission(productDetail)), take(UPDATE_WEALTH_SOURCE_DATA_COMPLETE)]
  yield [put(getMyWealthProfile({ fundList, fundListLSTU, rtu, premiumPrefill })), take(INIT_CUSTOMER_PROFILE)]

  yield put(selectProductCategory(categoryId))
  const isValidSale = yield select(getSalePermission)
  if (!isValidSale) {
    // if not valid permission end process here and push route to qq product-selection tab
    history.replace(`/quick${categoryPath}/product-selection`)
    return
  }

  yield put(selectProduct(productDetail))
  yield call(delay, 300)

  yield put(editBirthdate(formatDateOfBirth(dateOfBirthThai)))
  yield put(selectGender(getGenderMwPlus(gender)))
  yield call(delay, 300)

  // update model factor
  const isIInvest = productCode.toLowerCase() === I_INVEST
  yield put(selectModelFactor(isIInvest ? 'model-factor_5' : mapPaymentPeriodToModelFactor(paymentPeriod)))

  const shouldUpdateRtuAndRpp = premiumPrefill.includes(COVERAGE)
  const isIWealthy = productCode.toLowerCase() === I_WEALTHY
  const isILink = productCode.toLowerCase() === I_LINK

  if (shouldUpdateRtuAndRpp) {
    yield put(editRegularPremium(rpp))

    if (isIWealthy) {
      yield put(editRegularTopUp(rtu))
    } else if (isILink) {
      yield put(editLumpSum({ year: 1, value: rtu }))
      const riderBenefits = getToggleRidersFromMWPlus(rider)

      for (let rider of riderBenefits) {
        switch (rider.code) {
          case 'CIUDR':
          case 'TPDUDR':
            yield put(toggleRider(rider.code, rider.active))
            yield put(editRiderSumAssured(rider.code, rider.sumAssured))
            break
          case 'HSUDR':
          case 'HBUDR':
            yield put(toggleRider(rider.code, rider.active))
            yield put(editRiderSumAssured(rider.code, rider.sumAssured))

            const riderSelectedPlan = mapRiderBenefitsFromMWPlus(rider)
            yield put(
              editRiderSelectedPlan(rider.code, {
                planCode: riderSelectedPlan.planCode,
              })
            )
        }
      }
    } else {
      yield put(editLumpSum({ year: 1, value: rtu }))
    }
  }

  // questionare
  const shouldUpdateQuestions = premiumPrefill.includes(RPQ)
  if (shouldUpdateQuestions) {
    for (let question of rpq.questionResult) {
      yield put(answerQuestion(question.questionNumber, question.answered))
    }
  }

  yield call(delay, 300)
  history.replace(`/quick${categoryPath}/insured-information`)
}

export function* validateInvestmentSalePermission(action: Action): Generator<*, *, *> {
  yield put(validatingSalePermission())
  const validatePermissionPayload = {
    type: VALIDATE_INVESTMENT_PRODUCT,
    payload: action.payload,
  }
  const result = yield call(validatePermission, validatePermissionPayload)

  if (!result.isValid) {
    yield call(handleInvalidResult, result)
  }

  yield put(salePermissionValidationCompleted())

  yield put(
    updateWealthSourceData({
      dataSource: 'MY_WEALTH_PLUS',
      isValidSalePermission: result.isValid,
      isOpenedPDF: true,
      isOpenedLSTUPDF: true,
    })
  )
  yield put(updateWealthSourceDataComplete())
}

export default function* watchers(history): Generator<*, *, Effect[]> {
  yield takeEvery(UPDATE_MY_WEALTH_PLUS_PAYLOAD, updateMWPlusState, history)
  yield takeLatest(MY_WEALTH_PLUS_SALE_PERMISSION_CHECK, validateInvestmentSalePermission)
}

export const sagas = watchers
