// @flow
import { call, put, select, takeLatest } from 'redux-saga/effects'

import type { BIProps } from 'quick-quote/product-common/benefit-illustration/actions'
import type { DataWrapper } from 'core/data'
import type { Rider, RiderState } from 'core/data-model/rider'

import { getDistributorProfile } from 'core/service/distributor'
import AuthApi from 'core/service/distributor/auth-api'
import { christianToThaiWithoutFormat } from 'core/service/lib/date'
import {
  getSelectedModelFactorLabel,
  getRegularPremium,
  getRegularTopUp,
  getSumAssured,
  getCoverageDeathBenefit,
  getLumpSumTopUp,
} from 'quick-quote/product-investment/coverage-plan/selectors'
import {
  getIdentityProfile,
  getIdentityUser,
  getSelectedOffice,
  getUserDistributorGroup,
  getStaffInfo,
} from 'identity/selectors'
import { getAvailableRiders, getSelectedRiders } from 'quick-quote/product-common/coverage-plan/selectors'
import {
  getAge,
  getBirthdate,
  getGenderLabel,
  getInsuredFirstName,
  getInsuredLastName,
} from 'quick-quote/insured-information/selectors'
import {
  getSelectedDisplayProductNameThai,
  getSelectedDisplayProductCode,
  getSelectedDisplayProduct,
} from 'quick-quote/product-selection/selectors'
import { isReviseQQFlow } from 'quick-quote/selectors'
import {
  getCalculatedCoveragePeriod,
  getCalculatedPaymentPeriod,
} from 'quick-quote/product-common/benefit-illustration/selectors'
import { getAvailableFunds } from 'quick-quote/product-investment/fund-allocation/selectors'
import { getRiskEvaluated } from 'quick-quote/product-investment/risk-questionnaire/selectors'
import { getCashFlowProps } from 'quick-quote/product-investment/coverage-plan/sagas'
import { getImageByName } from 'quick-quote/ui/selectors'
import VALUES from 'core/data-model/constants/values'
import { generateRiderBenefitData } from 'core/service/rider/benefits'
import {
  getAllocationCategoryData,
  getAllocationByInvestmentType,
  getAllocationOptions,
} from 'quick-quote/product-investment/benefit-illustration/selectors'
import { UPDATE_RIDERS_BENEFITS } from 'quick-quote/product-common/coverage-plan/actions'
import { addRiderBenefit, removeRiderBenefit } from './actions'
import { getRiderBenefitTables } from 'quick-quote/product-whole-life/benefit-illustration/selectors'
import { getRppFundAllocations } from 'quick-quote/product-investment/fund-allocation/rpp/selectors'

// TODO: Review this file again
export function* getBIInvestmentProps({ isIgnoreStaffInfo = false }: BIProps): Generator<*, *, *> {
  const basicPlan = yield select(getSelectedDisplayProduct)
  const rppChart = yield select(getImageByName('rpp'))
  const lstuChart = yield select(getImageByName('lstu'))
  const pieChartFundByInvestmentType = {
    rpp: rppChart,
    lstu: lstuChart,
  }
  const policyType = yield select(getSelectedDisplayProductNameThai)
  const productCode = yield select(getSelectedDisplayProductCode)
  const riders = yield select(getSelectedRiders)
  const age = yield select(getAge)
  const birthdate = yield select(getBirthdate)
  const genderLabel = yield select(getGenderLabel)
  const sumAssured = yield select(getSumAssured)
  const calculatedCoveragePeriod = yield select(getCalculatedCoveragePeriod)
  const calculatedPaymentPeriod = yield select(getCalculatedPaymentPeriod)
  const firstName = yield select(getInsuredFirstName)
  const lastName = yield select(getInsuredLastName)
  const insured = {
    firstName,
    lastName,
    age,
    genderLabel,
  }
  const regularPremium = yield select(getRegularPremium)
  const regularTopUp = yield select(getRegularTopUp)
  const lumpSumTopUp = yield select(getLumpSumTopUp)
  const coverageDeath = yield select(getCoverageDeathBenefit)
  const riderBenefitData = yield select(getRiderBenefitTables)
  const paymentModeLabel = yield select(getSelectedModelFactorLabel)
  let profile = yield select(getIdentityProfile)
  const user = yield select(getIdentityUser)
  const accessToken = user.access_token
  const distributorType = yield select(getUserDistributorGroup)
  const bancOffice = yield select(getSelectedOffice)
  const agentInfo = yield call(AuthApi.getUserInformation, user)
  profile = getDistributorProfile(profile, agentInfo, bancOffice)
  const funds = yield select(getAvailableFunds)
  const allocations = yield select(getRppFundAllocations)
  const allocationByInvestmentType = yield select(getAllocationByInvestmentType)
  const insuredRiskLevel = yield select(getRiskEvaluated)
  const allocationCategoryByInvestmentType = yield select(getAllocationCategoryData)
  const allocationOptions = yield select(getAllocationOptions)
  const getDateTimeInThai = () => christianToThaiWithoutFormat().format('Do MMMM YYYY')
  const getDateTime = () => christianToThaiWithoutFormat().format('DD/MM/YYYY')
  const isReviseQQ = yield select(isReviseQQFlow)

  const dateInThai = yield call(getDateTimeInThai)
  const datetime = yield call(getDateTime)
  const cashFlowProps = yield call(getCashFlowProps)
  const staff = isIgnoreStaffInfo ? { id: '', name: '', surname: '', position: '' } : yield select(getStaffInfo)

  return {
    productCategory: VALUES.INVESTMENT,
    type: 'FULL_BI',
    basicPlan,
    policyType,
    productCode,
    birthdate,
    sumAssured: sumAssured.value,
    calculatedCoveragePeriod: calculatedCoveragePeriod,
    calculatedPaymentPeriod: calculatedPaymentPeriod,
    insured,
    riders,
    riderBenefitData,
    payer: {},
    regularPremium: regularPremium.value,
    regularTopupPremium: regularTopUp.value,
    lumpSumTopUp,
    coverageDeath,
    paymentModeLabel,
    profile,
    distributorType,
    accessToken,
    dateInThai,
    datetime,
    funds,
    allocations,
    allocationByInvestmentType,
    allocationCategoryByInvestmentType,
    allocationOptions,
    insuredRiskLevel: insuredRiskLevel.value,
    cashFlowProps,
    cashFlowByExpectedReturn: {},
    pieChartFundByInvestmentType,
    staff,
    isReviseQQ,
  }
}

// TODO: Remove duplication to whole-life
export const addRiderBenefitFunction = function*(
  dataWrapper: DataWrapper,
  selectedRider: Rider & RiderState,
  selectedRiders: (Rider & RiderState)[],
  age: Age
): Generator<*, *, *> {
  const riderCode = selectedRider.code
  const riderBenefits = yield call(dataWrapper.getRiderBenefit, riderCode)
  const riderSumAssured = selectedRider.sumAssured
  const extendedRiderBenefit = yield call(
    generateRiderBenefitData,
    {
      age: age,
      riderBenefitData: riderBenefits,
      riderSumAssured: riderSumAssured,
      selectedRiders: selectedRiders,
    },
    riderCode
  )
  yield put(addRiderBenefit(riderCode, extendedRiderBenefit))
}

export function* addOrRemoveRiderBenefits(dataWrapper: DataWrapper): Generator<*, *, *> {
  try {
    const riders = yield select(getAvailableRiders)
    const selectedRiders = riders.filter(({ isSelected, isSelectable }) => isSelected && isSelectable)
    const nonSelectedRiders = riders.filter(({ isSelected, isSelectable }) => !(isSelected && isSelectable))

    if (selectedRiders.length > 0) {
      const age = yield select(getAge)
      yield selectedRiders.map((rider) => {
        return call(addRiderBenefitFunction, dataWrapper, rider, selectedRiders, age)
      })
    }

    yield nonSelectedRiders.map((rider) => {
      return put(removeRiderBenefit(rider.code))
    })
  } catch (error) {}

  yield []
}

export function* watchers(dataWrapper: DataWrapper): Generator<*, *, Effect[]> {
  yield [takeLatest(UPDATE_RIDERS_BENEFITS, addOrRemoveRiderBenefits, dataWrapper)]
}

export const sagas = watchers
