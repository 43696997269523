//@flow
import type { ConfirmMessageNotification } from '../notification/actions'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import styled from 'styled-components'
import { Modal } from '../../common-components'

type ConfirmMessageProps = {
  notificationMessage: ConfirmMessageNotification,
  onExit: Function,
  history: *,
}

const CustomModal = styled(Modal)`
  .modal-title {
    flex: 1 0 auto;
    font-weight: bold;
    text-align: center;
    color: ${({ theme }) => theme.variables['$brand-primary']};
  }
  .modal-body {
    padding: 50px 20px;
    text-align: center;
  }
  .modal-footer {
    justify-content: space-between;
    &.center {
      justify-content: center;
    }
    border-top: none;
  }
`

export const ConfirmMessageModal = ({ notificationMessage, onExit, history, ...props }: ConfirmMessageProps) => (
  <CustomModal isOpen={notificationMessage.type !== 'None'} backdrop={true} modalClassName="default-bootstrap">
    <ModalHeader tag="div">{notificationMessage?.title}</ModalHeader>
    <ModalBody dangerouslySetInnerHTML={{ __html: notificationMessage?.message }} />
    <ModalFooter className="center">
      <Button
        color="primary"
        onClick={() => {
          const customOnClick = notificationMessage?.customOnClick
          if (!!customOnClick) {
            customOnClick()
          }
          onExit()
        }}
      >
        ยืนยัน
      </Button>
    </ModalFooter>
  </CustomModal>
)

export default ConfirmMessageModal
