// @flow
import { queryPremiumRateForMEX as queryPremiumRate } from 'core/service/rider/query-premium-rate'
import { calculateRiderPremiumForMEX as calculateRiderPremium } from 'core/service/rider/premium-calculation'
import { queryTaxRateForMEX as queryTaxRate } from 'core/service/rider/query-tax-rate'
import _ from 'lodash'

// get plancode with custom logic
export const getRiderPlanCode = (rider, insureAge) => {
  const planCode = _.get(rider, 'selectedPlan.planCode', '')
  const age = insureAge.unit !== 'year' ? '0' : insureAge.value.toString()
  switch (planCode) {
    case 'MEX1': {
      return age <= 10 ? 'MEX1J' : 'MEX1S'
    }
    case 'MEX2': {
      return age <= 10 ? 'MEX2J' : 'MEX2S'
    }
    case 'MEX3': {
      return age <= 10 ? 'MEX3J' : 'MEX3S'
    }
    default:
      return planCode
  }
}

export default {
  calculateRiderPremium,
  queryPremiumRate,
  queryTaxRate,
  getRiderPlanCode,
}
