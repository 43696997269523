// @flow

export const UPDATE_PRODUCT_PLAN = 'SAVING/UPDATE_PRODUCT_PLAN'
export type UpdateProductPlan = {
  type: 'SAVING/UPDATE_PRODUCT_PLAN',
  payload: string,
}
export const updateProductPlan = (planCode: string): UpdateProductPlan => ({
  type: UPDATE_PRODUCT_PLAN,
  payload: planCode,
})
