// @flow
import { connect } from 'react-redux'
import type { AppState } from 'quick-quote/reducers'
import BenefitIllustration from '../../../components/benefit-illustration/container'

import { isBlockedEnteringESub } from 'quick-quote/selectors'
import { getMenus, getSections } from './selectors'

const getSectionByProductCode = (state: AppState) => {
  return getSections(state)
}

const getMenuByProductCode = (state: AppState) => {
  return getMenus(state)
}

const mapStateToProps = (state: AppState) => ({
  sections: getSectionByProductCode(state),
  menus: getMenuByProductCode(state),
  isBlockedEnteringESub: isBlockedEnteringESub(state),
})

const mapDispatchToProps = () => ({})

export const BenefitIllustrationContainer = connect(mapStateToProps, mapDispatchToProps)(BenefitIllustration)
