import { Input } from 'reactstrap'
import { compose, defaultProps, withHandlers } from 'recompose'
import { removeZeroWidthSpaces } from '../../../utils'

export const Component = Input

export default (hoc) =>
  compose(
    defaultProps({
      value: '',
    }),
    withHandlers({
      onBlur: ({ validate }) => () => validate && validate(true),
      onFocus: ({ validate }) => () => validate && validate(false),
      onChange: ({ setValue }) => (event) => {
        setValue(removeZeroWidthSpaces(event.target.value))
      },
    }),
    hoc
  )(Component)
