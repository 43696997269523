// @flow

import _ from 'lodash'
import { isNil } from 'core/service/lib/type-check'
import { combineReducers } from 'redux'

import type { Action } from './actions'
import {
  EDIT_SUM_ASSURED,
  UPDATE_TOTAL_PREMIUM,
  TOGGLE_RIDER,
  SELECT_MODEL_FACTOR,
  UPDATE_SUM_ASSURED$,
  EDIT_BASIC_PREMIUM_STATE,
  UPDATE_BASIC_PREMIUM,
  EDIT_RIDER_SUM_ASSURED,
  EDIT_RIDER_SELECTED_PLAN,
  EDIT_RIDER_PREMIUM,
  EDIT_RIDER_MODULE_PREMIUM,
  UPDATE_AVAILABLE_RIDERS,
  UPDATE_TOTAL_RIDER_PREMIUM,
  UPDATE_BASIC_PLAN_ERROR_MESSAGE,
  TOGGLE_RIDER_MODULE,
  TOGGLE_RIDER_GROUP_MODULE,
  EDIT_PENSION_TYPE_STATE,
  UPDATE_SELECTED_PLAN,
  UPDATE_FULL_BASIC_PREMIUM,
  UPDATE_FULL_ANNUAL_BASIC_PREMIUM,
  UPDATE_CAMPAIGN_DISCOUNT_MESSAGE,
  UPDATE_CAMPAIGN_DISCOUNT_ENDDATE,
} from './actions'
import { UPDATE_SUM_ASSURED_AFTER_MATURITY_VALIDATE } from 'quick-quote/product-s7cc/actions'
import { UPDATE_SUM_ASSURED_AFTER_SELECTED_PLAN_PA } from 'quick-quote/product-pa/actions'
import { RESET_APPLICATION_STATE } from 'quick-quote/actions'
import { SELECT_PRODUCT } from 'quick-quote/product-selection/actions'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { SumAssured$ } from 'core/data-model/basic-plan'
import type { TotalPremium$ } from 'core/data-model/basic-plan/types'
import { valid } from 'core/service/lib/check'
import DEFAULTS from 'core/data-model/constants/defaults'

type StateProduct = {|
  sumAssured: SumAssured$,
  premium: number,
  fullBasicPremium: number,
  fullAnnualBasicPremium: number,
  campaignDiscountMessage: string[],
  campaignDiscountEndDate: string,
  error: string,
|}

type StateRider = Rider & RiderState

export type State = {
  totalPremium: TotalPremium$,
  totalRiderPremium: number,
  premiumState: number,
  startDate: Date,
  selectedModelFactorID: string,
  selectedBasicPlan: StateProduct,
  riders: { [string]: StateRider },
  pensionType: string,
  selectedPlan: string,
}

const initialTotalPremium = valid(0)
export const totalPremium = (state: TotalPremium$ = initialTotalPremium, action: Action) => {
  switch (action.type) {
    case RESET_APPLICATION_STATE:
      return initialTotalPremium
    case UPDATE_TOTAL_PREMIUM:
      if (isNil(action.payload)) {
        return initialTotalPremium
      }
      return action.payload
    default:
      return state
  }
}
export const totalRiderPremium = (state: number = 0, action: Action) => {
  switch (action.type) {
    case RESET_APPLICATION_STATE:
      return 0
    case UPDATE_TOTAL_RIDER_PREMIUM:
      return action.payload
    default:
      return state
  }
}

const initialPremiumState = -1
export const premiumState = (state: number = initialPremiumState, action: Action) => {
  switch (action.type) {
    case RESET_APPLICATION_STATE:
    case SELECT_PRODUCT:
    case EDIT_SUM_ASSURED:
      return initialPremiumState
    case EDIT_BASIC_PREMIUM_STATE:
      return action.payload
    default:
      return state
  }
}

const initialPensionType = ''
export const pensionType = (state: string = initialPensionType, action: Action) => {
  switch (action.type) {
    case RESET_APPLICATION_STATE:
    case SELECT_PRODUCT:
      return initialPensionType
    case EDIT_PENSION_TYPE_STATE:
      return action.payload
    default:
      return state
  }
}

export const campaignDiscountMessage = (state: string[] = [], action: Action) => {
  switch (action.type) {
    case RESET_APPLICATION_STATE:
      return 0
    case UPDATE_CAMPAIGN_DISCOUNT_MESSAGE:
      return action.payload
    default:
      return state
  }
}

export const campaignDiscountEndDate = (state: string = '', action: Action) => {
  switch (action.type) {
    case RESET_APPLICATION_STATE:
      return 0
    case UPDATE_CAMPAIGN_DISCOUNT_ENDDATE:
      return action.payload
    default:
      return state
  }
}

export const fullBasicPremium = (state: number = 0, action: Action) => {
  switch (action.type) {
    case RESET_APPLICATION_STATE:
      return 0
    case UPDATE_FULL_BASIC_PREMIUM:
      return action.payload
    default:
      return state
  }
}

export const fullAnnualBasicPremium = (state: number = 0, action: Action) => {
  switch (action.type) {
    case RESET_APPLICATION_STATE:
      return 0
    case UPDATE_FULL_ANNUAL_BASIC_PREMIUM:
      return action.payload
    default:
      return state
  }
}

const initialStartDate = new Date()
export const startDate = (state: Date = initialStartDate) => state

const initialSumAssured = valid(0)
export const sumAssured = (state: SumAssured$ = initialSumAssured, action: Action) => {
  switch (action.type) {
    case RESET_APPLICATION_STATE:
      return initialSumAssured
    case UPDATE_SUM_ASSURED$:
      return action.payload
    // case MRTA_UPDATE_ERROR_STATUS:
    //   return {
    //     ...state,
    //     isOk: action.payload,
    //   }
    case UPDATE_SUM_ASSURED_AFTER_MATURITY_VALIDATE:
    case UPDATE_SUM_ASSURED_AFTER_SELECTED_PLAN_PA:
      return {
        ...state,
        value: action.payload,
      }
    default:
      return state
  }
}

export const premium = (state: number = 0, action: Action) => {
  switch (action.type) {
    case RESET_APPLICATION_STATE:
      return 0
    case UPDATE_BASIC_PREMIUM:
      return action.payload
    default:
      return state
  }
}

const error = (state: string = '', action: Action) => {
  switch (action.type) {
    case UPDATE_BASIC_PLAN_ERROR_MESSAGE:
      return action.payload
    default:
      return state
  }
}

export const selectedBasicPlan = combineReducers({
  sumAssured,
  premium,
  fullBasicPremium,
  fullAnnualBasicPremium,
  campaignDiscountMessage,
  campaignDiscountEndDate,
  error,
})

const defaultModelFactor = DEFAULTS.SELECTED_MODEL_FACTOR
export const selectedModelFactorID = (state: string = defaultModelFactor, action: Action) => {
  switch (action.type) {
    case SELECT_PRODUCT: {
      switch (action.payload.code) {
        case DEFAULTS.DEFAULT_IINVEST_PRODUCT_CODE:
          return DEFAULTS.SELECTED_MODEL_FACTOR_SPUL
        case DEFAULTS.DEFAULT_IRETIRE_1_PRODUCT_CODE:
          return DEFAULTS.SELECTED_MODEL_FACTOR_R01
        case DEFAULTS.DEFAULT_S7_SINGLE_PRODUCT_CODE:
          return DEFAULTS.SELECTED_MODEL_FACTOR_S7_SINGLE
        case DEFAULTS.DEFAULT_MRTA_FULL_PRODUCT_CODE:
        case DEFAULTS.DEFAULT_MRTA_PLUS_PRODUCT_CODE:
        case DEFAULTS.DEFAULT_MRTA_SIMPLY_PRODUCT_CODE:
        case DEFAULTS.DEFAULT_MRTA_TRUNCATE_PRODUCT_CODE:
        case DEFAULTS.DEFAULT_GLTSP_PRODUCT_CODE:
          return DEFAULTS.SELECTED_MODEL_FACTOR_MRTA
        default:
          return defaultModelFactor
      }
    }
    case RESET_APPLICATION_STATE:
      return defaultModelFactor
    case SELECT_MODEL_FACTOR:
      return action.payload
    default:
      return state
  }
}

const defaultSelectedPlan = ''
export const selectedPlan = (state: string = defaultSelectedPlan, action: Action) => {
  switch (action.type) {
    case UPDATE_SELECTED_PLAN:
      return action.payload
    default:
      return state
  }
}

type RidersReducer = { [string]: StateRider }

const defaultRiders = {}
export const riders = (state: RidersReducer = defaultRiders, action: Action) => {
  const table = state

  switch (action.type) {
    case RESET_APPLICATION_STATE:
      return defaultRiders
    case EDIT_RIDER_SUM_ASSURED: {
      const { code, sumAssured } = action.payload
      const updatedTable = { ...table }
      if (table[code]) {
        updatedTable[code] = { ...table[code], sumAssured }
      }
      return updatedTable
    }
    case EDIT_RIDER_SELECTED_PLAN: {
      const { code, selectedPlan } = action.payload
      const updatedTable = { ...table }
      if (table[code]) {
        updatedTable[code] = { ...table[code], selectedPlan }
      }
      return updatedTable
    }
    case EDIT_RIDER_PREMIUM: {
      const { premium, code } = action.payload
      const updatedTable = { ...table }
      if (table[code]) {
        updatedTable[code] = { ...table[code], premium }
      }
      return updatedTable
    }
    case EDIT_RIDER_MODULE_PREMIUM: {
      const { riderCode, riderModuleCode, premium } = action.payload
      const updatedTable = _.cloneDeep(table)

      if (table[riderCode]) {
        const selectedModule = updatedTable[riderCode].selectedPlan.modules
        const moduleIdx = _.findIndex(selectedModule, { code: riderModuleCode })
        updatedTable[riderCode].selectedPlan.modules[moduleIdx] = {
          ...table[riderCode].selectedPlan.modules[moduleIdx],
          premium,
        }
      }

      return updatedTable
    }
    case TOGGLE_RIDER: {
      const code = action.payload.riderCode
      const newState = action.payload.newState
      const updatedTable = { ...table }
      if (table[code]) {
        updatedTable[code] = {
          ...table[code],
          isSelected: newState,
          errors: [],
        }
      }
      return updatedTable
    }
    case TOGGLE_RIDER_MODULE: {
      const riderCode = action.payload.riderCode
      const riderModuleCode = action.payload.riderModuleCode
      const newState = action.payload.newState
      const updatedTable = _.cloneDeep(table)
      if (table[riderCode]) {
        const selectedModule = updatedTable[riderCode].selectedPlan.modules
        const moduleIdx = _.findIndex(selectedModule, { code: riderModuleCode })
        updatedTable[riderCode].selectedPlan.modules[moduleIdx] = {
          ...table[riderCode].selectedPlan.modules[moduleIdx],
          isSelected: newState,
        }
      }
      return updatedTable
    }
    case TOGGLE_RIDER_GROUP_MODULE: {
      const riderCode = action.payload.riderCode
      const riderModuleGroupCode = action.payload.riderModuleGroupCode
      const newState = action.payload.newState
      const updatedTable = _.cloneDeep(table)
      if (table[riderCode] && newState) {
        const selectedPlan = updatedTable[riderCode].selectedPlan.plan
        const areaOfCover = updatedTable[riderCode].selectedPlan.areaOfCover
        const selectedPlanGroupIdx = _.findIndex(updatedTable[riderCode].packageRidersGroup, { plan: selectedPlan })
        const planGroups = updatedTable[riderCode].packageRidersGroup[selectedPlanGroupIdx].planGroups
        let modules = []
        const updatedPlanGroup = planGroups.map((p) => {
          if (p.code === riderModuleGroupCode) {
            p.modules.map((m) => {
              modules.push({
                code: m.code[areaOfCover],
                description: m.description,
                isSelected: true,
              })
            })
            return { ...p, isSelected: newState }
          }
          return { ...p, isSelected: !newState }
        })

        updatedTable[riderCode].packageRidersGroup[selectedPlanGroupIdx].planGroups = updatedPlanGroup
        updatedTable[riderCode].selectedPlan.modules = modules
      }
      return updatedTable
    }
    case UPDATE_AVAILABLE_RIDERS: {
      return action.payload
    }
    default:
      return state
  }
}

export const reducer = combineReducers({
  totalPremium,
  totalRiderPremium,
  premiumState,
  riders,
  selectedBasicPlan,
  selectedModelFactorID,
  startDate,
  pensionType,
  selectedPlan,
})
