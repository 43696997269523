//@flow
import CONSTANTS from 'core/data-model/constants/product'

type Params = {
  coverageTerm: number,
  loanTerm: number,
}
export const getInsuranceLoanTermByProductCode = (productCode: string, { coverageTerm, loanTerm }: Params): number => {
  if (productCode === CONSTANTS.TRUNCATE_MRTA_PRODUCT_CODE) return loanTerm
  return coverageTerm
}
