import {
  hideDialog,
  hideLoading,
  setAppValue,
  setInsuredCommandStatusCompleted,
  setPayerCommandStatusCompleted,
  showErrorDialog,
  showLoading,
} from 'e-submission/apps/actions'
import { getCurrentApp } from 'e-submission/apps/selectors'
import {
  getCitizenId,
  getDocumentTypeName,
  getInsured,
  getInsuredData,
  getPayerIfNeeded,
  getVideoConsentData,
  insurerDoesEkyc,
  isProprietorNeedEkyc,
  payerDoesEkyc,
} from 'e-submission/domain/data-model/form/selectors'
import { IsRealDevice } from 'e-submission/utils'
import df2f, { DF2F } from 'e-submission/utils/bridging/df2f'
import { getOr, get } from 'lodash/fp'
import _ from 'lodash'
import {
  HANGUP,
  OPEN_DF2F_APPLICATION,
  resetMediaControlState,
  setMediaControlState,
  setSelectedRemoteSelling,
  UPDATE_MEDIA_STATE,
  openDf2fApplication as openDf2fApplicationAction,
  CANCEL_EKYC,
  CONFIRM_EKYC,
} from 'quick-quote/actions'
import ROUTE_PATH from 'quick-quote/constants/path'
import { setNotificationMessage } from 'quick-quote/notification/actions'
import { startFNAFlow } from 'quick-quote/portal/actions'
import { handleBackToHome } from 'quick-quote/sagas'
import { isSelectedRemoteSelling } from 'quick-quote/selectors'
import { take, takeLatest, call, put, race, select, all, takeEvery } from 'redux-saga/effects'
import {
  CREATE_POLICY_SUCCEED,
  GET_MEDIA_STATUS,
  openEkycConfirmModal,
  OPEN_DF2F_EKYC_PAGE,
  OPEN_EKYC_CONFIRM_MODAL,
  resumeAppmanRemoteSelling,
  setRemoteSellingState,
  START_VIDEO_RECORDING,
  UPDATE_CALL_STATUS,
  updateCallStateEsub,
} from './actions'
import { mediaActions } from './constants'
import { getMediaState, getRemoteSellingState } from './selectors'
import { getIdentityUser } from 'identity/selectors'
import { setLoadingStatus } from 'quick-quote/ui/actions'
import { getAppConfig } from '../../deploy-env/app-config'

export function* createRemoteSellingPolicy(action) {
  const { policyID } = action
  const isRemoteSelling = yield select(isSelectedRemoteSelling)
  if (isRemoteSelling) {
    try {
      yield call(df2f.sendEvent, DF2F.CREATE_NEW_POLICY, {
        policyNumber: policyID,
      })
    } catch (e) {
      console.error('Tried to create new policy in DF2F, but failed')
    }
  }
}
export function* handleDf2fCallbacks(callback, history, esubStore) {
  switch (callback) {
    case 'hangup': {
      yield call(hangupRemoteSelling, history, esubStore)
      break
    }
    case 'backToHomePage': {
      yield call(handleBackToHome, history)
      break
    }
    default: {
      break
    }
  }
}
export function* openDf2fApplication(history, esubStore, action) {
  const { dispatch } = esubStore
  const user = yield select(getIdentityUser)
  try {
    const { name, payload } = yield call(df2f.sendEvent, DF2F.OPEN_DF2F_APP, {
      env: getAppConfig().OC_ENVIRONMENT || 'uat',
      user: get('profile', user),
      reconnectTimeout: 60,
      ...action.payload,
    })
    if (name) {
      yield put(setLoadingStatus(true))
      yield put(setSelectedRemoteSelling())
      yield call(history.push, ROUTE_PATH.NEED_AND_GAP_ANALYSIS)
      yield call(updateGetRemoteSellingMedia)
      switch (name) {
        case 'startVideoCall': {
          yield call(dispatch, resumeAppmanRemoteSelling(payload))
          yield put(setRemoteSellingState(payload))
          yield put(setRemoteSellingState({ isInCall: true }))
          const { resumed, failed } = yield race({
            resumed: take('REMOTE_SELLING_SUCCESS'),
            failed: take('REMOTE_SELLING_ERROR'),
            notFound: take('REMOTE_SELLING_APP_NOT_FOUND'),
          })
          if (resumed) {
            yield call(updateGetRemoteSellingMedia)
          }
          if (failed) {
            const { payload } = failed
            console.error('Failed to start video call', payload)
            yield put(startFNAFlow())
          }
          break
        }
        case 'resumeCase': {
          yield call(dispatch, resumeAppmanRemoteSelling(payload))
          const { resumed, failed } = yield race({
            resumed: take('REMOTE_SELLING_SUCCESS'),
            failed: take('REMOTE_SELLING_ERROR'),
            notFound: take('REMOTE_SELLING_APP_NOT_FOUND'),
          })
          if (resumed) {
            yield put(setRemoteSellingState({ ...payload, isInCall: false }))
          }
          if (failed) {
            const { payload } = failed
            console.error('Failed to start video call', payload)
            yield put(startFNAFlow())
          }
          break
        }

        default: {
          yield call(handleDf2fCallbacks, name, history, esubStore)
          break
        }
      }
      yield call(df2fGetCallStatus, esubStore)
    }
  } catch (err) {
    console.error('openDf2fApplication', err)
    yield put(setLoadingStatus(false))
    yield call(handleBackToHome, history)
  } finally {
    yield put(setLoadingStatus(false))
  }
}

export function* updateGetRemoteSellingMedia() {
  try {
    const media_status = yield call(df2f.sendEvent, DF2F.GET_MEDIA_STATUS)
    if (media_status) {
      yield put(
        setMediaControlState(
          _({
            micState: media_status?.microphoneEnable,
            cameraState: media_status?.cameraEnable,
            screenState: media_status?.sharingScreen,
          })
            .omit(_.isUndefined)
            .omit(_.isNull)
            .value()
        )
      )
    }
  } catch (err) {
    console.error(err)
  }
}
export function* df2fGetCallStatus(esubStore) {
  const { dispatch, getState } = esubStore
  const esubState = yield call(getState)
  const app = yield call(getCurrentApp, esubState)
  try {
    const { isInCall } = yield select(getRemoteSellingState)
    const call_status = yield call(df2f.sendEvent, DF2F.GET_CALL_STATUS)
    if (call_status) {
      yield put(
        setRemoteSellingState({
          callStatus: call_status,
          latency: call_status?.latency || 0,
        })
      )
      if (app) {
        yield call(dispatch, updateCallStateEsub({ ...call_status, isInCall }))
      }
    }
  } catch (err) {
    console.error(err)
  }
}

export function* hangupRemoteSelling(history, esubStore) {
  try {
    console.info('hangupRemoteSelling remote-selling 216')
    const { getState, dispatch } = esubStore
    const esubState = yield call(getState)
    const app = yield call(getCurrentApp, esubState)
    const insuredInfo = {
      firstNameInsured: get('insured.firstName', app),
      lastNameInsured: get('insured.lastName', app),
    }
    yield call(dispatch, hideDialog())
    yield put(hideDialog())
    yield call(df2f.sendEvent, DF2F.END_CALL, insuredInfo)
    yield put(resetMediaControlState())
    yield call(handleBackToHome, history)
    yield put(
      setNotificationMessage({
        type: 'None',
      })
    )
    yield [call(dispatch, { type: 'RESET_CURRENT_APP' }), call(dispatch, { type: 'RESET_APP_STATE' })]
    if (IsRealDevice) {
      yield put(openDf2fApplicationAction())
    }
  } catch (err) {
    console.error(err)
  }
}
export function* df2fSendMediaUpdates(action) {
  try {
    const mediaState = yield select(getMediaState)
    const { micState, cameraState } = mediaState
    const payload = action.payload
    switch (payload) {
      case mediaActions.TOGGLE_MIC: {
        yield call(df2f.sendEvent, DF2F.CONTROL_MEDIA, {
          microphoneEnable: !micState,
          cameraEnable: cameraState,
        })
        yield put(setMediaControlState({ micState: !micState }))
        break
      }
      case mediaActions.TOGGLE_CAMERA: {
        yield call(df2f.sendEvent, DF2F.CONTROL_MEDIA, {
          microphoneEnable: micState,
          cameraEnable: !cameraState,
        })
        yield put(setMediaControlState({ cameraState: !cameraState }))
        break
      }
      case mediaActions.TOGGLE_SCREEN: {
        yield call(df2f.sendEvent, DF2F.TOGGLE_SHARE_SCREEN)
        break
      }
      default:
        break
    }
    yield call(updateGetRemoteSellingMedia)
  } catch (err) {
    console.error('Error Media Toggle', err)
  }
}

export function* onOpenEkycConfirmModal(esubStore) {
  const { getState, dispatch } = esubStore
  const esubState = yield call(getState)
  const app = yield call(getCurrentApp, esubState)
  const { roomId, isInCall } = yield select(getRemoteSellingState)
  if (isInCall) {
    yield put(
      setNotificationMessage({
        type: 'ConfirmEKYC',
      })
    )
    const { cancel, confirm } = yield race({
      cancel: take(CANCEL_EKYC),
      confirm: take(CONFIRM_EKYC),
    })
    if (confirm) {
      const payer = yield call(getPayerIfNeeded, app)
      const payerNeedsToDoEKYC = yield call(payerDoesEkyc, app)
      const insurerNeedsToDoEkyc = yield call(insurerDoesEkyc, app)
      const insured = yield call(getInsuredData, app)
      if (payer?.title?.gender) delete payer.title.gender
      if (insured?.title?.gender) delete insured.title.gender
      try {
        if (payerNeedsToDoEKYC && !insurerNeedsToDoEkyc) {
          yield call(df2f.sendEvent, DF2F.UPDATE_PROPRIETOR_IN_CASE, {
            roomId,
            proprietorType: 'payer',
            ...payer,
            insured,
          })
        } else if (!payerNeedsToDoEKYC && insurerNeedsToDoEkyc) {
          yield call(df2f.sendEvent, DF2F.UPDATE_PROPRIETOR_IN_CASE, {
            roomId,
            proprietorType: 'insured',
            ...insured,
          })
        } else if (payerNeedsToDoEKYC && insurerNeedsToDoEkyc) {
          yield call(df2f.sendEvent, DF2F.ADD_PROPRIETOR_TO_CASE, { roomId, proprietorType: 'payer', ...payer })
          yield call(df2f.sendEvent, DF2F.ADD_PROPRIETOR_TO_CASE, { roomId, proprietorType: 'insured', ...insured })
        }
        if (payerNeedsToDoEKYC) {
          yield [
            call(dispatch, setAppValue('remoteSelling.payer.completedEkyc', true)),
            take('SAVE_APPLICATION_SUCCESS'),
          ]
        }
        if (insurerNeedsToDoEkyc) {
          yield [
            call(dispatch, setAppValue('remoteSelling.insured.completedEkyc', true)),
            take('SAVE_APPLICATION_SUCCESS'),
          ]
        }
        yield put({ type: 'CONFIRMED_EKYC_MODAL' })
      } catch (err) {
        console.error(err)
        history.goBack()
      }
    }
    if (cancel) {
      yield put({ type: 'CANCELED_EKYC_MODAL' })
    }
  }
}

export function* uploadDocumentFromUrl(services, esubStore, action) {
  const { getState, dispatch } = esubStore
  const { saveAttachmentFromURL, getAttachment } = services
  const { url, name, isInsured, fetch, skipGet, type } = action.payload
  try {
    const user = yield select(getIdentityUser)
    const esubState = yield call(getState)
    const app = yield call(getCurrentApp, esubState)
    const applicationId = get('applicationId', app)
    const document = {
      applicationId,
      url,
      attachmentId: name,
      type,
    }
    yield call(saveAttachmentFromURL, user, document)
    if (!skipGet) {
      const response = yield call(getAttachment, app, name, user)
      yield [
        call(dispatch, {
          type: 'UPLOAD_ATTACHMENT',
          ...{ file: response, name, isInsured },
        }),
        take('UPLOAD_ATTACHMENT_SUCCESS'),
      ]
    }
    if (fetch) {
      yield call(dispatch, { type: 'FETCH_CURRENT_APP' })
      yield take('LOAD_APP')
    }
  } catch (err) {
    console.error(err)
  } finally {
    yield call(dispatch, isInsured ? setInsuredCommandStatusCompleted(name) : setPayerCommandStatusCompleted(name))
  }
}

export function* setEkycData(services, esubStore, payload) {
  const { insuredOrPayer, data } = payload
  const { getState, dispatch } = esubStore
  try {
    yield call(dispatch, showLoading())
    const esubState = yield call(getState)
    const app = yield call(getCurrentApp, esubState)
    const frontIdResults = getOr(undefined, `${insuredOrPayer}.frontIdCardResult.edits`, data)
    const dopaResults = getOr(undefined, `${insuredOrPayer}.dopaResult`, data)
    const faceRecognitionResults = getOr(undefined, `${insuredOrPayer}.idFaceRecognitionResult`, data)
    const caseId = getOr(undefined, 'caseId', data)
    const proprietorId = get(`${insuredOrPayer}.proprietorId`, data)
    yield call(dispatch, showLoading())
    if (proprietorId) {
      yield [
        call(dispatch, setAppValue(`remoteSelling.${insuredOrPayer}.proprietorId`, proprietorId)),
        race({
          success: take('SAVE_APPLICATION_SUCCESS'),
          failed: take('SAVE_APPLICATION_FAIL'),
        }),
      ]
    }
    yield call(dispatch, showLoading())
    if (caseId) {
      yield [
        call(dispatch, setAppValue('remoteSelling.caseId', caseId)),
        race({
          success: take('SAVE_APPLICATION_SUCCESS'),
          failed: take('SAVE_APPLICATION_FAIL'),
        }),
      ]
    }
    if (dopaResults) {
      yield call(dispatch, setAppValue(`remoteSelling.${insuredOrPayer}.dopaResult`, dopaResults))
      yield race({
        success: take('SAVE_APPLICATION_SUCCESS'),
        failed: take('SAVE_APPLICATION_FAIL'),
      })
    }
    yield call(dispatch, showLoading())
    if (faceRecognitionResults) {
      const result = get('response.data.result', faceRecognitionResults)
      yield call(
        dispatch,
        setAppValue(`remoteSelling.${insuredOrPayer}.idFaceRecognitionResult`, {
          faceRecognitionResults: result,
          livenessResponse: get('livenessResponse', faceRecognitionResults),
          feedback: get(`${insuredOrPayer}.feedback`, data) || '',
          verified: get('verified', faceRecognitionResults),
        })
      )
      yield race({
        success: take('SAVE_APPLICATION_SUCCESS'),
        failed: take('SAVE_APPLICATION_FAIL'),
      })
    }
    yield call(dispatch, showLoading())
    if (frontIdResults) {
      yield call(dispatch, setAppValue(`remoteSelling.${insuredOrPayer}.ekycResult`, frontIdResults))
      yield race({
        success: take('SAVE_APPLICATION_SUCCESS'),
        failed: take('SAVE_APPLICATION_FAIL'),
      })

      const idCardImage = yield call(df2f.sendEvent, DF2F.GET_FRONT_ID_CARD_URL)
      const { idCardImageUrl: url } = idCardImage[insuredOrPayer] || {}
      if (url) {
        const document = {
          url,
          name: yield call(getDocumentTypeName, app, insuredOrPayer),
          isInsured: insuredOrPayer === 'insured' ? true : false,
          fetch: true,
          type: 'image/png',
        }
        yield call(uploadDocumentFromUrl, services, esubStore, { payload: document })
      }
    }
    yield call(dispatch, { type: 'SET_EKYC_DATA_SUCCESS', payload: { insuredOrPayer } })
  } catch (e) {
    yield call(dispatch, hideLoading())
    console.error(e)
  }
}
export function* onOpenDf2fEkyc(history, esubStore, services, action) {
  const { navProps } = action.payload
  const { getState, dispatch } = esubStore
  const esubState = yield call(getState)
  const app = yield call(getCurrentApp, esubState)
  const { roomId, isInCall } = yield select(getRemoteSellingState)
  const currentPageIndex = navProps.findIndex((x) => x.id === 'ekyc')

  yield put(openEkycConfirmModal())
  const { cancel, confirm } = yield race({
    confirm: take('CONFIRMED_EKYC_MODAL'),
    cancel: take('CANCELED_EKYC_MODAL'),
  })
  if (!isInCall || confirm) {
    try {
      yield call(dispatch, showLoading())
      const { name, payload } = yield call(df2f.sendEvent, DF2F.OPEN_ON_BOARDING_STEP, {
        insuredId: getCitizenId('insured')(app),
        payerId: getCitizenId('payer')(app),
        roomId,
        step: 'ekyc',
        isJoinedWithVDOCall: isInCall,
      })
      const previousPageIndex = navProps[currentPageIndex - 1] ? currentPageIndex - 1 : 0
      history.push(navProps[previousPageIndex].path)
      const nextPageIndex = navProps[currentPageIndex + 1] ? currentPageIndex + 1 : 0
      if (!name) throw new Error('Invalid response')
      switch (name) {
        case 'previousStep':
        case 'nextStep': {
          yield call(dispatch, showLoading())
          if (isProprietorNeedEkyc(app, 'insured')) {
            yield call(setEkycData, services, esubStore, {
              data: payload,
              insuredOrPayer: 'insured',
            })
          }
          yield call(dispatch, showLoading())
          if (isProprietorNeedEkyc(app, 'payer')) {
            yield call(setEkycData, services, esubStore, { data: payload, insuredOrPayer: 'payer' })
            yield call(dispatch, { type: 'FETCH_CURRENT_APP' })
            yield take('LOAD_APP')
          }
          yield call(dispatch, showLoading())
          yield call(history.push, navProps[nextPageIndex].path)
          break
        }
        default: {
          yield call(handleDf2fCallbacks, name, history, esubStore)
          break
        }
      }
    } catch (err) {
      yield put(
        setNotificationMessage({
          type: 'GenericError',
          message: err?.message || 'ระบบมีปัญหากรุณาลองใหม่อีกครั้งครับ',
        })
      )
    } finally {
      yield call(dispatch, hideLoading())
    }
  }
  if (cancel) {
    yield call(dispatch, hideLoading())
    const previousPageIndex = navProps[currentPageIndex - 1] ? currentPageIndex - 1 : 0
    history.push(navProps[previousPageIndex].path)
  }
}

export function* onStartVideoConsent(history, esubStore) {
  const { roomId, isInCall } = yield select(getRemoteSellingState)
  const { getState, dispatch } = esubStore
  const esubState = yield call(getState)
  const app = yield call(getCurrentApp, esubState)
  const videoConsentPayload = yield call(getVideoConsentData, app)
  yield call(dispatch, setAppValue('remoteSelling.loadingVideoConsent', true))
  try {
    const { name } = yield call(df2f.sendEvent, DF2F.OPEN_ON_BOARDING_STEP, {
      roomId,
      step: 'recordVideoConsent',
      isJoinedWithVDOCall: isInCall,
      videoConsentPayload,
    })
    if (name === 'nextStep') {
      yield put.resolve(showLoading())
      yield [call(dispatch, setAppValue('remoteSelling.videoConsented', true)), take('SAVE_APPLICATION_SUCCESS')]
      yield call(history.push, ROUTE_PATH.REMOTE_SELLING.REMOTE_PAYMENT)
      yield put(hideLoading())
    }
    yield call(handleDf2fCallbacks, name, history, esubStore)
  } catch (e) {
    console.error(e)
    yield call(dispatch, showErrorDialog('failed', 'ไม่สามารถบันทึกภาพได้ในขนาดนี้'))
  } finally {
    yield call(dispatch, setAppValue('remoteSelling.loadingVideoConsent', false))
  }
}

export function* setRemoteSellingData(esubStore) {
  yield take('LOAD_APP')
  const { getState, dispatch } = esubStore
  const esubState = yield call(getState)
  const app = yield call(getCurrentApp, esubState)
  const insured = yield call(getInsured, app)
  const remoteSellingState = yield select(getRemoteSellingState)
  yield [
    call(
      dispatch,
      setAppValue('insured', {
        ...insured,
        email: insured?.email ? insured?.email : getOr('', 'email', remoteSellingState),
        mobileNumber: insured?.mobileNumber
          ? insured?.mobileNumber
          : getOr(insured?.mobileNumber, 'phoneNumber', remoteSellingState),
      })
    ),
    take('SAVE_APPLICATION_SUCCESS'),
  ]
  yield put({
    type: 'SET_REMOTE_SELLING_DATA_SUCCESS',
  })
}
export function* onLoadApp(esubStore) {
  const { getState } = esubStore
  const esubState = yield call(getState)
  const app = yield call(getCurrentApp, esubState)
  const selectedRemoteSelling = getOr(false, 'selectedRemoteSelling', app)
  if (selectedRemoteSelling) {
    yield put(setSelectedRemoteSelling())
  }
}
export function* watchers(history, esubStore, services) {
  yield all([
    takeLatest(OPEN_DF2F_APPLICATION, openDf2fApplication, history, esubStore),
    takeLatest(OPEN_DF2F_APPLICATION, setRemoteSellingData, esubStore),
    takeLatest(HANGUP, hangupRemoteSelling, history, esubStore),
    takeEvery(UPDATE_MEDIA_STATE, df2fSendMediaUpdates),
    takeEvery(UPDATE_CALL_STATUS, df2fGetCallStatus, esubStore),
    takeEvery(GET_MEDIA_STATUS, updateGetRemoteSellingMedia),
    takeLatest(CREATE_POLICY_SUCCEED, createRemoteSellingPolicy),
    takeLatest(START_VIDEO_RECORDING, onStartVideoConsent, history, esubStore),
    takeEvery(OPEN_DF2F_EKYC_PAGE, onOpenDf2fEkyc, history, esubStore, services),
    takeLatest(OPEN_EKYC_CONFIRM_MODAL, onOpenEkycConfirmModal, esubStore),
    takeLatest('LOAD_APP', onLoadApp, esubStore),
  ])
}

export const sagas = watchers
