// @flow
import _ from 'lodash'
import { takeLatest, put, select } from 'redux-saga/effects'

import { TOGGLE_DISCOUNT } from 'quick-quote/product-protection/coverage-plan/actions'
import {
  editRiderSelectedPlan,
  editRiderSumAssured,
  EDIT_RIDER_SELECTED_PLAN,
} from 'quick-quote/product-common/coverage-plan/actions'
import {
  getSameValuePlanCode,
  getSelectedRiderPlan,
  getDiscountStatus,
} from 'quick-quote/product-protection/coverage-plan/selectors'

import values from 'core/data-model/constants/values'
import { getToggles } from 'quick-quote/feature-toggles'

export function* onToggleDiscount(): Generator<*, *, *> {
  const newRiderPlan = yield select(getSameValuePlanCode)
  const isDiscountBeforeToggle = yield select(getDiscountStatus)
  yield put(editRiderSelectedPlan(values.ESCI, { planCode: newRiderPlan }))
  if (getToggles().ENABLE_ICARE_REPRICING === true) {
    yield put(
      editRiderSelectedPlan(values.WPEIP85, { planCode: isDiscountBeforeToggle ? values.WPEIP85 : values.WPDIP85 })
    )
  } else {
    yield put(
      editRiderSelectedPlan(values.WPEWL85, { planCode: isDiscountBeforeToggle ? values.WPEWL85 : values.WPDWL85 })
    )
  }
}

export function* onSelectPlanCode(): Generator<*, *, *> {
  const newRiderPlan = yield select(getSelectedRiderPlan)
  yield put(editRiderSumAssured(values.ESCI, newRiderPlan.value))
  yield put(
    editRiderSelectedPlan(values.ESCI, {
      planCode: newRiderPlan.planCode,
      group: newRiderPlan.group,
    })
  )
}

export function* watchers(): Generator<*, *, *> {
  const selectPlanCode = ({ type, payload }) => {
    return type === EDIT_RIDER_SELECTED_PLAN && payload.code === values.ESCI && _.isNil(payload.selectedPlan.group)
  }
  yield [takeLatest(TOGGLE_DISCOUNT, onToggleDiscount), takeLatest(selectPlanCode, onSelectPlanCode)]
}

export const sagas = watchers
