// @flow

import type { Rider, RiderState } from 'core/data-model/rider'
import type { Benefit } from 'core/data-model/rider/benefits'

import RiderBenefitSummary from './rider-benefit-summary'
import RiderWithBenefitModal from './rider-benefit-modal'
import { formatNumber } from 'core/service/lib/number-format'
import { isObject, isNotNil } from 'core/service/lib/type-check'
import MESSAGES from 'core/data-model/constants/bi-messages'
import { AgentProtectionDisclaimer } from './agent-protection-disclaimer'
import { AdditionalDisclaimer } from './additional-disclaimer'

type MEAPlusRiderBenefitProps = {
  rider: Rider & RiderState,
  benefits: Benefit[],
}

type MEAPlusRiderBenefitsRowProps = {
  description: string,
  benefit: string,
}

const renderMEAPlusRiderBenefitsRow = (
  rowNumber: number,
  meaPlusRiderBenefitsRowProps: MEAPlusRiderBenefitsRowProps
) => {
  const benefitDescription = meaPlusRiderBenefitsRowProps.description
    .split('\n')
    .map((text) => <div key={`${text}-${rowNumber}`}>{text}</div>)
  const benefitValue = isNaN(parseInt(meaPlusRiderBenefitsRowProps.benefit))
    ? meaPlusRiderBenefitsRowProps.benefit
    : formatNumber(parseInt(meaPlusRiderBenefitsRowProps.benefit))

  return (
    <tr key={`mea-plus-rider-benefit-row-${rowNumber}`} id={`mea-plus-rider-benefit-row-${rowNumber}`}>
      <td>
        <ol start={`${rowNumber}`}>
          <li> {benefitDescription}</li>
        </ol>
      </td>
      <td>{benefitValue}</td>
    </tr>
  )
}

const renderMEAPlusRiderBenefitsTable = (benefits: Benefit[]) => {
  return (
    <div className="details-table">
      <table id="mea-plus-benefit-table">
        <thead>
          <tr>
            <td>{MESSAGES.BENEFIT_LIST}</td>
            <td>{MESSAGES.BENEFIT_BAHT}</td>
          </tr>
        </thead>
        <tbody>
          {benefits.map((benefit, index) => {
            return renderMEAPlusRiderBenefitsRow(index + 1, {
              description: isObject(benefit) && isNotNil(benefit.description) ? benefit.description : '',
              benefit: isObject(benefit) && isNotNil(benefit.value) ? benefit.value : '',
            })
          })}
        </tbody>
      </table>
    </div>
  )
}

const MEAPlusRiderBenefit = ({ rider, benefits }: MEAPlusRiderBenefitProps) => {
  const title = `${rider.description} ${rider.name}`
  const benefitTable = benefits ? renderMEAPlusRiderBenefitsTable(benefits) : null
  return (
    <RiderWithBenefitModal
      id="mea-plus-rider-benefit"
      className="rider mea-plus-rider"
      title={title}
      modalChildren={benefitTable}
    >
      <RiderBenefitSummary rider={rider} />
      {benefitTable}
      <AgentProtectionDisclaimer rider={rider} />
      <AdditionalDisclaimer rider={rider} />
    </RiderWithBenefitModal>
  )
}

export default MEAPlusRiderBenefit
