// @flow

import BirthdateSelection from './birthdate'
import GenderSelection from './gender-selection'
import PayerRelationSelection from './payer-relation-selection'
import { Collapse } from 'reactstrap'

import type { Payer, GenderOption } from 'core/data-model/insured'
import messages from 'core/data-model/constants/messages'

type Props = {
  payerNeeded: boolean,
  payer: Payer,
  selectGender: Function,
  editBirthdate: Function,
  errorBirthdateMessage: string,
  genderOptions: GenderOption[],
  children?: *,
}

const PayerInformation = ({
  payerNeeded,
  payer,
  selectGender,
  editBirthdate,
  selectPayerRelation,
  errorBirthdateMessage,
  genderOptions,
  showPayerRelationSelection,
  payerRelationOptions,
  children,
}: Props) => {
  return (
    <div id="payer-information">
      <Collapse
        id="collapse-payer-relation-selection"
        isOpen={showPayerRelationSelection}
        delay={{ show: 225, hide: 225 }}
      >
        <h6>ผู้ชำระเงิน</h6>
        <PayerRelationSelection
          payerRelation={payer.relation}
          selectPayerRelation={selectPayerRelation}
          payerRelationOptions={payerRelationOptions}
        />
      </Collapse>
      <Collapse id="collapse-payer-information" isOpen={payerNeeded} delay={{ show: 225, hide: 225 }}>
        {!showPayerRelationSelection && <h6>ผู้ชำระเงิน</h6>}
        <GenderSelection
          gender={payer.gender}
          selectGender={selectGender}
          title={messages.PAYER_GENDER}
          genderOptions={genderOptions}
        />
        <BirthdateSelection
          title={messages.PAYER_BIRTHDATE}
          birthdate={payer.birthdate.value}
          editBirthdate={editBirthdate}
          age={payer.age}
          errorBirthdateMessage={errorBirthdateMessage}
          isPayerSection={true}
        />
        {children}
      </Collapse>
    </div>
  )
}

export default PayerInformation
