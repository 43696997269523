// @flow
import { FormGroup, FormFeedback } from 'reactstrap'
import NumberFormat from 'react-number-format'

export type MaturityValidateProps = {
  editMaturityIDCard: (string, number) => void,
  currentMaturityIDCard: number,
  isLoading: boolean,
  errors: void,
}

const MaturityValidate = ({ editMaturityIDCard, currentMaturityIDCard, isLoading, errors }: MaturityValidateProps) => {
  return (
    <FormGroup className={`${errors.length > 0 ? 'has-danger' : ''}`}>
      <div className={'personal-information-breadcrumb'}>
        <p>ท่านสามารถตรวจสอบสิทธิ์ ในการซื้อผลิตภัณฑ์ Super 7 CriticalCare</p>
      </div>
      <div id="information">
        <div className="content">
          <div className="options option-labels">
            <label>กรุณาใส่เลขประจำตัวประชาชน</label>
          </div>
          <div className="options option-inputs">
            <div className="form-group">
              <NumberFormat
                id="maturity-idcard-validate"
                disabled={isLoading}
                className="form-control"
                isAllowed={({ formattedValue }) => formattedValue.length <= 13}
                value={currentMaturityIDCard}
                allowNegative={false}
                type="text"
                pattern="[0-9]*"
                onFocus={(e) => e.target.setSelectionRange(0, -1)}
                onChange={(e, values) => editMaturityIDCard(values.value)}
              />
              <FormFeedback className="validation-message">{errors}</FormFeedback>
            </div>
          </div>

          {isLoading === true && (
            <div className="options option-labels">
              <div id="spinner" style={{ float: 'left', marginRight: '10px' }}></div>
              <label className="options option-labels" style={{ fontSize: '20px' }}>
                กำลังตรวจสอบสิทธิ์ในการซื้อผลิตภัณฑ์ Super 7 CriticalCare
              </label>
            </div>
          )}
        </div>
      </div>
    </FormGroup>
  )
}

export default MaturityValidate
