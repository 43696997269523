//@flow
import type { AuthenticationErrorNotification } from 'quick-quote/notification/actions'
import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { withProps, compose } from 'recompose'
import { bindActionCreators } from 'redux'
import { Button } from 'reactstrap'
import { getAppConfig } from 'deploy-env/app-config'

import IconAccess from 'assets/images/nav/icon-iamc-unaccess.svg'
import IconUser from 'assets/images/nav/icon-User-Active.svg'
import { getToggles } from 'quick-quote/feature-toggles'
import { clickHomeButton } from '../actions'

import { FullScreenPopup } from './global-popup'

type AuthenticationErrorProps = {
  onExit: Function,
  logoutRequest: Function,
  handleHomeButtonClick: Function,
  onClose: Function,
  notificationMessage: AuthenticationErrorNotification,
}
const Heading = styled.h4`
  font-weight: bold;
  font-size: 28px;
  line-height: 32px;
  color: var(--color-primary-blue);
`
const Icon = styled.img`
  width: 200px !important;
  height: 200px;
`

const IconRenderUser = styled.img`
  width: 25px !important;
  height: 25px !important;
  display: inline !important;
`

export const _AuthenticationError = ({ notificationMessage, onClose }: AuthenticationErrorProps) => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  const params = Object.fromEntries(urlSearchParams.entries())

  let msg = {
    title: '',
    description: '',
  }
  const renderSwitch = (param) => {
    switch (param) {
      case 'password is expired':
        msg.title = 'บัญชีชื่อผู้ใช้งานของท่าน รหัสผ่านหมดอายุ'
        msg.description =
          'กรุณากด “ลืมรหัสผ่าน” เพื่อขอรับลิงก์ตั้งรหัสผ่านใหม่ หรือสอบถามและบริการช่วยเหลือกับทางแอดมินของทีมฝ่ายขายเพื่อแนะนำวิธี กรุณาติดต่อ 1159 กด 5 ทุกวัน 9.00-20.00 น.'
        return msg

      case 'user is terminated':
        msg.title = 'บัญชีชื่อผู้ใช้งานของท่านไม่สามารถใช้งานได้'
        msg.description =
          'สอบถามและบริการช่วยเหลือกับทางแอดมินของทีมฝ่ายขายเพื่อแนะนำวิธี กรุณาติดต่อ 1159 กด 5 ทุกวัน 9.00-20.00 น.'
        return msg
      case 'user is blocked':
        msg.title = 'บัญชีชื่อผู้ใช้งานของท่านถูกระงับโดยผู้ดูแลระบบ'
        msg.description =
          'สอบถามและบริการช่วยเหลือกับทางแอดมินของทีมฝ่ายขายเพื่อแนะนำวิธี กรุณาติดต่อ 1159 กด 5 ทุกวัน 9.00-20.00 น'
        return msg
      default:
        msg.title = (
          <p>
            • บัญชีผู้ใช้ของท่านหมดอายุ
            <br /> • บัญชีผู้ใช้ของท่านไม่ได้ใช้งานเป็นระยะเวลานาน
            <br /> • รหัสผ่านของท่านหมดอายุ
          </p>
        )
        msg.description = (
          <p>
            กรุณากดปุ่ม ออกจากระบบที่รูปคนมุมขวาบน
            <IconRenderUser src={IconUser} /> หรือ ตรวจสอบกับทางแอดมินของทางทีมฝ่ายขายเพื่อแนะนำวิธี
          </p>
        )
        return msg
    }
  }

  const result = renderSwitch(params.error_description)

  return (
    <FullScreenPopup className="iamc-modal" isOpen={notificationMessage.type !== 'None'}>
      <div className="iamc-modal__wrapper">
        <Icon src={IconAccess} />

        <div className="description">
          {getToggles().ENABLE_NEW_SESSION_ERROR_MESSAGE && (
            <Heading data-testid="auth-error-msg">
              ท่านไม่สามารถใช้งานแอปพลิเคชัน กรุณาตรวจสอบบัญชีผู้ใช้ของท่าน
            </Heading>
          )}

          {result && (
            <div className="form-group has-danger">
              {result.title && (
                <div className="validation-message attached-icon form-control-feedback iam-c">
                  <p>{result?.title}</p>
                </div>
              )}
              {result.description && (
                <div className="info-message attached-icon form-control-feedback iam-c">
                  <p>{result?.description}</p>
                </div>
              )}
            </div>
          )}
        </div>

        <Button color="primary" onClick={onClose} data-testid="auth-error-btn-close">
          {!getToggles().ENABLE_NEW_SESSION_ERROR_MESSAGE && 'ปิด'}
          {getToggles().ENABLE_NEW_SESSION_ERROR_MESSAGE && 'ปิดหน้านี้'}
        </Button>
      </div>
    </FullScreenPopup>
  )
}

const mapDispatchToProps = (dispatch) => ({
  handleHomeButtonClick: bindActionCreators(clickHomeButton, dispatch),
})
const enhance = compose(
  connect(null, mapDispatchToProps),
  withProps((props) => ({
    onClose: () => {
      const iamcLogoutUrl = getAppConfig().IAMC_LOGOUT_URL
      window.open(iamcLogoutUrl, '_self')
    },
  }))
)
export default enhance(_AuthenticationError)
