//@flow
import {
  canProceedToFundAndCoveragePlan,
  canProceedToRiskQuestionnaire,
} from 'quick-quote/product-investment/journey-control-selectors'
import type { Element } from 'react'
import type { AnalyticsEventType } from 'analytics/components'
import MESSAGES from '../../../../core/data-model/constants/messages'
import { ROUTE_PATH } from '../../../constants/path'
import { ShareButtonSection as FlexiHealthShareButtonSection } from '../../../product-flexi-health/benefit-illustration/components'
import { ShareButtonSection as HealthShareButtonSection } from '../../../product-health/benefit-illustration/components'
import ShareQuoteButton from '../../../product-investment/benefit-illustration/share-quote-button'
import { canProceedPerfectLifeBenefitIllustration } from '../../../product-perfect-life/coverage-plan/selectors'
import { canProceedRetirementBenefitIllustration } from '../../../product-retirement/coverage-plan/selectors'
import { ShareButtonSection } from '../../../product-whole-life/benefit-illustration/components'
import ESubmitButton from '../../../product-whole-life/benefit-illustration/components/e-submit/esub-button'
import { canProceedToBenefitIllustration } from '../../../product-whole-life/coverage-plan/selectors'
import type { AppState } from '../../../reducers'
import { canProceedToCoveragePlan } from '../../../selectors/next-tab-proceeding'

export type NavButton = {
  type: 'link',
  to?: string,
  text?: string,
  disabled?: boolean,
  analyticsEventType?: AnalyticsEventType,
  onClick?: Function,
}

export type NavButtonConfiguration = {
  path: string,
  props: {
    backButton?: NavButton,
    nextButton?:
      | NavButton
      | {
          type: 'component',
          component: Element<*>,
        },
    extraButton?: React$ComponentType<*>,
  },
  excludePath?: string,
}

const wholeLifeNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const { INSURED_INFORMATION, COVERAGE_PLAN, BENEFIT_ILLUSTRATION } = ROUTE_PATH.WHOLE_LIFE

  return [
    {
      path: INSURED_INFORMATION,
      props: {
        backButton: {
          type: 'link',
          to: ROUTE_PATH.PRODUCT_SELECTION,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: COVERAGE_PLAN,
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: COVERAGE_PLAN,
      props: {
        backButton: {
          type: 'link',
          to: INSURED_INFORMATION,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: BENEFIT_ILLUSTRATION,
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: BENEFIT_ILLUSTRATION,
      props: {
        backButton: {
          type: 'link',
          to: COVERAGE_PLAN,
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
        },
        extraButton: ShareButtonSection,
      },
    },
  ]
}

const protectionNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const { INSURED_INFORMATION, COVERAGE_PLAN, BENEFIT_ILLUSTRATION } = ROUTE_PATH.PROTECTION

  return [
    {
      path: INSURED_INFORMATION,
      props: {
        backButton: {
          type: 'link',
          to: ROUTE_PATH.PRODUCT_SELECTION,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: COVERAGE_PLAN,
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: COVERAGE_PLAN,
      props: {
        backButton: {
          type: 'link',
          to: INSURED_INFORMATION,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: BENEFIT_ILLUSTRATION,
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: BENEFIT_ILLUSTRATION,
      props: {
        backButton: {
          type: 'link',
          to: COVERAGE_PLAN,
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
        },
        extraButton: ShareButtonSection,
      },
    },
  ]
}

export const investmentNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    RISK_QUESTIONNAIRE,
    FUND_ALLOCATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
  } = ROUTE_PATH.INVESTMENT

  const backNextButton = {
    backButton: {
      type: 'link',
      to: INSURED_INFORMATION,
    },
    nextButton: {
      type: 'link',
      text: MESSAGES.FUND,
      to: FUND_ALLOCATION,
      disabled: false,
    },
  }

  const backButton = {
    backButton: {
      type: 'link',
      to: INSURED_INFORMATION,
    },
  }

  const navButton = canProceedToFundAndCoveragePlan(state) ? backNextButton : backButton
  return [
    {
      path: INSURED_INFORMATION,
      props: {
        backButton: {
          type: 'link',
          to: ROUTE_PATH.PRODUCT_SELECTION,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.RISK_QUESTIONNAIRE,
          to: RISK_QUESTIONNAIRE,
          disabled: !canProceedToRiskQuestionnaire(state),
        },
      },
    },
    {
      path: RISK_QUESTIONNAIRE,
      props: navButton,
    },
    {
      path: FUND_ALLOCATION,
      props: {
        backButton: {
          type: 'link',
          to: RISK_QUESTIONNAIRE,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: COVERAGE_PLAN,
          disabled: !canProceedToFundAndCoveragePlan(state),
        },
      },
    },
    {
      path: COVERAGE_PLAN,
      props: {
        backButton: {
          type: 'link',
          to: FUND_ALLOCATION,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: BENEFIT_ILLUSTRATION,
          disabled: !canProceedToFundAndCoveragePlan(state),
        },
      },
    },
    {
      path: BENEFIT_ILLUSTRATION,
      props: {
        backButton: {
          type: 'link',
          to: COVERAGE_PLAN,
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
        },
        extraButton: ShareQuoteButton,
      },
    },
  ]
}

const loanNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const { INSURED_INFORMATION, COVERAGE_PLAN, BENEFIT_ILLUSTRATION } = ROUTE_PATH.LOAN

  return [
    {
      path: INSURED_INFORMATION,
      props: {
        backButton: {
          type: 'link',
          to: ROUTE_PATH.PRODUCT_SELECTION,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: COVERAGE_PLAN,
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: COVERAGE_PLAN,
      props: {
        backButton: {
          type: 'link',
          to: INSURED_INFORMATION,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: BENEFIT_ILLUSTRATION,
          disabled: false,
        },
      },
    },
    {
      path: BENEFIT_ILLUSTRATION,
      props: {
        backButton: {
          type: 'link',
          to: COVERAGE_PLAN,
        },
      },
    },
  ]
}

const healthNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const { INSURED_INFORMATION, COVERAGE_PLAN, BENEFIT_ILLUSTRATION } = ROUTE_PATH.HEALTH

  return [
    {
      path: INSURED_INFORMATION,
      props: {
        backButton: {
          type: 'link',
          to: ROUTE_PATH.PRODUCT_SELECTION,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: COVERAGE_PLAN,
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: COVERAGE_PLAN,
      props: {
        backButton: {
          type: 'link',
          to: INSURED_INFORMATION,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: BENEFIT_ILLUSTRATION,
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: BENEFIT_ILLUSTRATION,
      props: {
        backButton: {
          type: 'link',
          to: COVERAGE_PLAN,
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
        },
        extraButton: HealthShareButtonSection,
      },
    },
  ]
}

const flexiHealthNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const { INSURED_INFORMATION, COVERAGE_PLAN, BENEFIT_ILLUSTRATION } = ROUTE_PATH.FLEXI_HEALTH

  return [
    {
      path: INSURED_INFORMATION,
      props: {
        backButton: {
          type: 'link',
          to: ROUTE_PATH.PRODUCT_SELECTION,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: COVERAGE_PLAN,
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: COVERAGE_PLAN,
      props: {
        backButton: {
          type: 'link',
          to: INSURED_INFORMATION,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: BENEFIT_ILLUSTRATION,
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: BENEFIT_ILLUSTRATION,
      props: {
        backButton: {
          type: 'link',
          to: COVERAGE_PLAN,
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
        },
        extraButton: FlexiHealthShareButtonSection,
      },
    },
  ]
}

const savingNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const { INSURED_INFORMATION, COVERAGE_PLAN, BENEFIT_ILLUSTRATION } = ROUTE_PATH.SAVING

  return [
    {
      path: INSURED_INFORMATION,
      props: {
        backButton: {
          type: 'link',
          to: ROUTE_PATH.PRODUCT_SELECTION,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: COVERAGE_PLAN,
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: COVERAGE_PLAN,
      props: {
        backButton: {
          type: 'link',
          to: INSURED_INFORMATION,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: BENEFIT_ILLUSTRATION,
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: BENEFIT_ILLUSTRATION,
      props: {
        backButton: {
          type: 'link',
          to: COVERAGE_PLAN,
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
        },
        extraButton: HealthShareButtonSection,
      },
    },
  ]
}

const eSubmissionNavigationButton = (): NavButtonConfiguration[] => {
  return [
    {
      path: ROUTE_PATH.REMOTE_SELLING.VIDEO_CONSENT,
      props: {
        backButton: {},
      },
    },
    {
      path: ROUTE_PATH.ESUBMISSION_FORM,
      props: {
        backButton: {
          type: 'link',
        },
      },
      excludePath: 'submit',
    },
    {
      path: ROUTE_PATH.ESUBMISSION_PAYMENT,
      props: {
        backButton: {
          type: 'link',
        },
      },
    },
  ]
}

const lifeSaveProNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const { INSURED_INFORMATION, COVERAGE_PLAN, BENEFIT_ILLUSTRATION } = ROUTE_PATH.LIFE_SAVE_PRO

  return [
    {
      path: INSURED_INFORMATION,
      props: {
        backButton: {
          type: 'link',
          to: ROUTE_PATH.PRODUCT_SELECTION,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: COVERAGE_PLAN,
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: COVERAGE_PLAN,
      props: {
        backButton: {
          type: 'link',
          to: INSURED_INFORMATION,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: BENEFIT_ILLUSTRATION,
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: BENEFIT_ILLUSTRATION,
      props: {
        backButton: {
          type: 'link',
          to: COVERAGE_PLAN,
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
        },
        extraButton: ShareButtonSection,
      },
    },
  ]
}

const retirementNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const { INSURED_INFORMATION, COVERAGE_PLAN, BENEFIT_ILLUSTRATION } = ROUTE_PATH.RETIREMENT

  return [
    {
      path: INSURED_INFORMATION,
      props: {
        backButton: {
          type: 'link',
          to: ROUTE_PATH.PRODUCT_SELECTION,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: COVERAGE_PLAN,
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: COVERAGE_PLAN,
      props: {
        backButton: {
          type: 'link',
          to: INSURED_INFORMATION,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: BENEFIT_ILLUSTRATION,
          disabled: !canProceedRetirementBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: BENEFIT_ILLUSTRATION,
      props: {
        backButton: {
          type: 'link',
          to: COVERAGE_PLAN,
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
        },
        extraButton: ShareButtonSection,
      },
    },
  ]
}

const perfectLifeNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const { INSURED_INFORMATION, COVERAGE_PLAN, BENEFIT_ILLUSTRATION } = ROUTE_PATH.PERFECT_LIFE

  return [
    {
      path: INSURED_INFORMATION,
      props: {
        backButton: {
          type: 'link',
          to: ROUTE_PATH.PRODUCT_SELECTION,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: COVERAGE_PLAN,
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: COVERAGE_PLAN,
      props: {
        backButton: {
          type: 'link',
          to: INSURED_INFORMATION,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: BENEFIT_ILLUSTRATION,
          disabled: !canProceedPerfectLifeBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: BENEFIT_ILLUSTRATION,
      props: {
        backButton: {
          type: 'link',
          to: COVERAGE_PLAN,
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
        },
        extraButton: ShareButtonSection,
      },
    },
  ]
}

const healthToppingNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const { INSURED_INFORMATION, COVERAGE_PLAN, BENEFIT_ILLUSTRATION } = ROUTE_PATH.HEALTH_TOPPING

  return [
    {
      path: INSURED_INFORMATION,
      props: {
        backButton: {
          type: 'link',
          to: ROUTE_PATH.PRODUCT_SELECTION,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: COVERAGE_PLAN,
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: COVERAGE_PLAN,
      props: {
        backButton: {
          type: 'link',
          to: INSURED_INFORMATION,
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: BENEFIT_ILLUSTRATION,
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: BENEFIT_ILLUSTRATION,
      props: {
        backButton: {
          type: 'link',
          to: COVERAGE_PLAN,
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
        },
        extraButton: ShareButtonSection,
      },
    },
  ]
}

export default (state: AppState): NavButtonConfiguration[] =>
  wholeLifeNavigationButton(state)
    .concat(investmentNavigationButton(state))
    .concat(loanNavigationButton(state))
    .concat(healthNavigationButton(state))
    .concat(savingNavigationButton(state))
    .concat(protectionNavigationButton(state))
    .concat(lifeSaveProNavigationButton(state))
    .concat(flexiHealthNavigationButton(state))
    .concat(retirementNavigationButton(state))
    .concat(perfectLifeNavigationButton(state))
    .concat(healthToppingNavigationButton(state))
    .concat(eSubmissionNavigationButton())
