// @flow

export default {
  RIDER_CPR_DESCRIPTION_01:
    'ผลประโยชน์จากสัญญาเพิ่มเติมคุ้มครองโรคมะเร็ง (CPR) รวมสูงสุดโดยแบ่งจ่ายตามระยะของการตรวจพบ ตามรายละเอียดด้านล่าง',
  RIDER_CPR_DISCLAIMER_01: '(ทั้งนี้การจ่ายผลประโยชน์รวมตลอดช่วงอายุจะต้องไม่เกิน 100% ของจำนวนเงินเอาประกันภัย)',
  RIDER_CPR_DESCRIPTION_02:
    '1. ขั้นที่ 1 โรคมะเร็งระยะไม่ลุกลามขั้นต้น หรือ มะเร็งผิวหนัง เฉพาะบาเซล เซล และสแควร์มัส เซล',
  RIDER_CPR_DISCLAIMER_02:
    'การจ่ายผลประโยชน์ดังกล่าวจะเกิดขึ้นก็ต่อเมื่อวันที่เก็บตัวอย่างชิ้นเนื้อเพื่อนำไปตรวจทางพยาธิวิทยาได้พ้นระยะเวลารอคอย 120 วัน***',
  RIDER_CPR_DESCRIPTION_03:
    '2. ขั้นที่ 2 โรคมะเร็งระยะไม่ลุกลาม หรือโรคมะเร็งรังไข่ระยะแรก*\n- สำหรับมะเร็งระยะไม่ลุกลาม หรือโรคมะเร็งรังไข่ระยะแรก',
  RIDER_CPR_DISCLAIMER_03: '',
  RIDER_CPR_DESCRIPTION_04: '- สำหรับมะเร็งปากมดลูก หรือ CIN III',
  RIDER_CPR_DISCLAIMER_04:
    'การจ่ายผลประโยชน์ดังกล่าวจะเกิดขึ้นก็ต่อเมื่อวันที่เก็บตัวอย่างชิ้นเนื้อเพื่อนำไปตรวจทางพยาธิวิทยาได้พ้นระยะเวลารอคอย 90 วัน***',
  RIDER_CPR_DESCRIPTION_05:
    '3. ขั้นที่ 3 โรคมะเร็งระยะไม่ลุกลามของอวัยวะที่กำหนด ที่มีการรักษาโดยการผ่าตัดแบบ Radical surgery*\n- สำหรับโรคมะเร็งระยะไม่ลุกลามของอวัยวะที่กำหนด ที่มีการรักษาโดยการผ่าตัดแบบ Radical surgery',
  RIDER_CPR_DISCLAIMER_05: '',
  RIDER_CPR_DESCRIPTION_06: '- สำหรับกรณีที่มีการเคลมขั้นที่ 2 และต่อมามีการเคลมในขั้นที่ 3 ที่อวัยวะเดิม**',
  RIDER_CPR_DISCLAIMER_06:
    'การจ่ายผลประโยชน์ดังกล่าวจะเกิดขึ้นก็ต่อเมื่อวันที่เก็บตัวอย่างชิ้นเนื้อเพื่อนำไปตรวจทางพยาธิวิทยาได้พ้นระยะเวลารอคอย 90 วัน***',
  RIDER_CPR_DESCRIPTION_07:
    '4. ขั้นที่ 4 โรคมะเร็งระยะลุกลาม รับสูงสุด (หรือ 100% ของจำนวนเงินเอาประกันภัยหักด้วยผลประโยชน์ที่จ่ายมาแล้วทั้งหมด)',
  RIDER_CPR_DISCLAIMER_07:
    'การจ่ายผลประโยชน์ดังกล่าวจะเกิดขึ้นก็ต่อเมื่อวันที่เก็บตัวอย่างชิ้นเนื้อเพื่อนำไปตรวจทางพยาธิวิทยาได้พ้นระยะเวลารอคอย 60 วัน***',

  RIDER_ECARE_DESCRIPTION_01:
    '3. ค่าชดเชยกรณีทุพพลภาพชั่วคราว (ผลประโยชน์ข้อ 3.1 และ 3.2 รวมกันสูงสุดไม่เกิน 52 สัปดาห์)',
  RIDER_ECARE_DESCRIPTION_02: '3.1 ทุพพลภาพสิ้นเชิงชั่วคราว',
  RIDER_ECARE_DESCRIPTION_03: '3.2 ทุพพลภาพบางส่วนชั่วคราว',
  RIDER_ECARE_DESCRIPTION_04:
    '4. ค่าชดเชยกรณีทุพพลภาพถาวรสิ้นเชิง สูงสุดไม่เกิน 100 เดือน โดยมีเงื่อนไขว่าได้จ่ายค่าชดเชยภายใต้ผลประโยชน์ 3. เป็นเวลา 52 สัปดาห์แล้ว',
  RIDER_ECARE_DESCRIPTION_05: '5. ค่าชดเชยกรณีเข้ารักษาตัวในโรงพยาบาลในฐานะคนไข้ใน (สูงสุดไม่เกิน 20 สัปดาห์)',
  RIDER_ECARE_DESCRIPTION_06: '6. ค่ารักษาพยาบาลเนื่องจากอุบัติเหตุ* ตามที่จ่ายจริง แต่ไม่เกิน',
  RIDER_ECARE_DISCLAIMER_01: '',
  RIDER_ECARE_DISCLAIMER_02: '*ค่ารักษาพยาบาลรวมถึง ค่ายา ค่าโรงพยาบาล ค่าธรรมเนียมแพทย์',

  RIDER_AP_ECARE_NAME: 'AP และ ECARE',
  RIDER_AP_ECARE_DESCRIPTION_01: '1. กรณีเสียชีวิต สูญเสียอวัยวะ และสายตา',
  RIDER_AP_ECARE_DESCRIPTION_02: 'เสียชีวิต',
  RIDER_AP_ECARE_DESCRIPTION_03: 'สูญเสียมือหรือเท้า 1 ข้างหรือมากกว่า',
  RIDER_AP_ECARE_DESCRIPTION_04: 'สูญเสียสายตา 1 ข้างหรือมากกว่า',
  RIDER_AP_ECARE_DESCRIPTION_05: 'สูญเสียการได้ยิน',
  RIDER_AP_ECARE_DESCRIPTION_06: 'ทั้งสองข้าง',
  RIDER_AP_ECARE_DESCRIPTION_07: 'ข้างเดียว',
  RIDER_AP_ECARE_DESCRIPTION_08: 'สูญเสียการพูด',
  RIDER_AP_ECARE_DESCRIPTION_09: 'สูญเสียแก้วตาทั้งสองข้าง',
  RIDER_AP_ECARE_DESCRIPTION_10: 'สูญเสียนิ้วมือทั้ง 4 และนิ้วหัวแม่มือ',
  RIDER_AP_ECARE_DESCRIPTION_11: 'มือขวา',
  RIDER_AP_ECARE_DESCRIPTION_12: 'มือซ้าย',
  RIDER_AP_ECARE_DESCRIPTION_13: 'สูญเสียนิ้วมือทั้ง 4 นิ้ว',
  RIDER_AP_ECARE_DESCRIPTION_14: 'มือขวา',
  RIDER_AP_ECARE_DESCRIPTION_15: 'มือซ้าย',
  RIDER_AP_ECARE_DESCRIPTION_16: 'กระดูกขา หรือสะบ้า แตกหัก',
  RIDER_AP_ECARE_DESCRIPTION_17: 'ขาหดสั้นลงอย่างน้อย 5 เซนติเมตร',
  RIDER_AP_ECARE_DESCRIPTION_18: 'สูญเสียนิ้วหัวแม่มือ',
  RIDER_AP_ECARE_DESCRIPTION_19: 'นิ้วหัวแม่มือขวาทั้งสองข้อ',
  RIDER_AP_ECARE_DESCRIPTION_20: 'นิ้วหัวแม่มือขวาหนึ่งข้อ',
  RIDER_AP_ECARE_DESCRIPTION_21: 'นิ้วหัวแม่มือซ้ายทั้งสองข้อ',
  RIDER_AP_ECARE_DESCRIPTION_22: 'นิ้วหัวแม่มือซ้ายหนึ่งข้อ',
  RIDER_AP_ECARE_DESCRIPTION_23: 'สูญเสียนิ้วมืออื่นๆ',
  RIDER_AP_ECARE_DESCRIPTION_24: 'นิ้วมือขวา นิ้วใดนิ้วหนึ่งทั้งสามข้อ',
  RIDER_AP_ECARE_DESCRIPTION_25: 'นิ้วมือขวา นิ้วใดนิ้วหนึ่งสองข้อ',
  RIDER_AP_ECARE_DESCRIPTION_26: 'นิ้วมือขวา นิ้วใดนิ้วหนึ่งข้อเดียว',
  RIDER_AP_ECARE_DESCRIPTION_27: 'นิ้วมือซ้าย นิ้วใดนิ้วหนึ่งทั้งสามข้อ',
  RIDER_AP_ECARE_DESCRIPTION_28: 'นิ้วมือซ้าย นิ้วใดนิ้วหนึ่งทั้งสองข้อ',
  RIDER_AP_ECARE_DESCRIPTION_29: 'นิ้วมือซ้าย นิ้วใดนิ้วหนึ่งข้อเดียว',
  RIDER_AP_ECARE_DESCRIPTION_30: 'สูญเสียนิ้วเท้า',
  RIDER_AP_ECARE_DESCRIPTION_31: 'นิ้วเท้าหมดทุกนิ้ว 1 เท้า',
  RIDER_AP_ECARE_DESCRIPTION_32: 'นิ้วหัวแม่เท้าทั้งนิ้ว',
  RIDER_AP_ECARE_DESCRIPTION_33: 'นิ้วหัวแม่เท้าหนึ่งข้อ',
  RIDER_AP_ECARE_DESCRIPTION_34: 'กรณีเสียชีวิต ผลประโยชน์เป็น 2 เท่าหากอุบัติเหตุเกิดจากสาธารณภัย',
  RIDER_AP_ECARE_DESCRIPTION_35: '2. กรณีมีบาดแผลจากการไฟไหม้',
  RIDER_AP_ECARE_DESCRIPTION_36: 'ศีรษะ',
  RIDER_AP_ECARE_DESCRIPTION_37: 'มากกว่าหรือเท่ากับ 2% แต่น้อยกว่า 4%',
  RIDER_AP_ECARE_DESCRIPTION_38: 'มากกว่าหรือเท่ากับ 4% แต่น้อยกว่า 6%',
  RIDER_AP_ECARE_DESCRIPTION_39: 'มากกว่าหรือเท่ากับ 6% แต่น้อยกว่า 8%',
  RIDER_AP_ECARE_DESCRIPTION_40: 'เท่ากับหรือมากกว่า 8%',
  RIDER_AP_ECARE_DESCRIPTION_41: 'ร่างกาย',
  RIDER_AP_ECARE_DESCRIPTION_42: 'มากกว่าหรือเท่ากับ 10% แต่น้อยกว่า 12.5%',
  RIDER_AP_ECARE_DESCRIPTION_43: 'มากกว่าหรือเท่ากับ 12.5% แต่น้อยกว่า 15%',
  RIDER_AP_ECARE_DESCRIPTION_44: 'มากกว่าหรือเท่ากับ 15% แต่น้อยกว่า 20%',
  RIDER_AP_ECARE_DESCRIPTION_45: 'เท่ากับหรือมากกว่า 20%',
  RIDER_ESCI_RIDER_BENEFIT_TITLE:
    'สัญญาเพิ่มเติมโรคร้ายแรงคุ้มครองเริ่มต้น และ สัญญาเพิ่มเติมผลประโยชน์ยกเว้นเบี้ยประกันภัยสัญญาหลักเนื่องจากโรคร้ายแรง',
  RIDER_ESCI_2020_RIDER_BENEFIT_TITLE:
    'สัญญาเพิ่มเติมโรคร้ายแรงคุ้มครองเริ่มต้น (ESCI) และ สัญญาเพิ่มเติมผลประโยชน์ยกเว้นเบี้ยประกันภัยสัญญาหลัก เนื่องจากโรคร้ายแรง 2020 (WPE)',
  RIDER_ESCI_DESCRIPTION_01:
    '1. ความคุ้มครองของโรคร้ายแรง 5 กลุ่มโรค ได้แก่ มะเร็ง อวัยวะสำคัญ ระบบประสาท หัวใจ และ โรคร้ายแรงอื่นๆ ตามที่กรมธรรม์กำหนด ซึ่งไม่ได้อยู่ใน 4 กลุ่มแรก',
  RIDER_ESCI_DESCRIPTION_02:
    'โดยการวินิจฉัยว่าเป็นโรคร้ายแรงตามที่กำหนด จะต้องเกิดขึ้นในขณะที่ผู้เอาประกันภัยมีชีวิตอยู่ และกรมธรรม์มีผลบังคับ',
  RIDER_ESCI_DESCRIPTION_03: 'ความคุ้มครองในแต่ละระยะความรุนแรงของแต่ละกลุ่มโรค',
  RIDER_ESCI_DESCRIPTION_04: '1.1 ระยะเริ่มต้น (สูงสุด ต่อโรค)',
  RIDER_ESCI_DESCRIPTION_05: '1.2 ระยะปานกลาง (สูงสุด ต่อโรค)',
  RIDER_ESCI_DESCRIPTION_06: '1.3 ระยะรุนแรง (สูงสุด ต่อโรค)',
  RIDER_ESCI_DESCRIPTION_07: 'รวมผลประโยชน์สูงสุดในแต่ละกลุ่มโรค ไม่เกิน',
  RIDER_ESCI_DESCRIPTION_08: 'รวมผลประโยชน์สูงสุดในการเคลมจาก 5 กลุ่มโรค ไม่เกิน',
  RIDER_ESCI_DESCRIPTION_09:
    'ผลประโยชน์เพิ่มเติม 10 โรคสำหรับผู้เอาประกันภัย อายุ 1 เดือน - 18 ปี (ความคุ้มครองจะสิ้นสุดลง ณ อายุ 18 ปี)',
  RIDER_ESCI_DESCRIPTION_10: 'รวมผลประโยชน์สูงสุดของสัญญาเพิ่มเติม',
  RIDER_ESCI_DESCRIPTION_11: 'ผลประโยชน์พิเศษ',
  RIDER_ESCI_VALUE_11:
    'บริษัทฯ จะจ่ายเบี้ยประกันให้แผนประกัน iCare เพื่อให้กรมธรรม์ยังคงมีผลบังคับต่อไป กรณีตรวจพบโรคร้ายแรงระยะรุนแรงในกลุ่มโรคใดกลุ่มโรคหนึ่ง',
  RIDER_ESCI_VALUE_12:
    'บริษัทฯ จะจ่ายเบี้ยประกันให้แผนประกัน iCare เพื่อให้กรมธรรม์ยังคง \n มีผลบังคับต่อไป กรณีตรวจพบโรคร้ายแรงระยะรุนแรงครั้งแรก \n ในกลุ่มโรคใดกลุ่มโรคหนึ่ง',

  EXCEPTION_NOTED_AP_ECARE: {
    title: [
      'ไม่คุ้มครองการเสียชีวิตและการสูญเสียอวัยวะ อันเกิดจากสาเหตุดังต่อไปนี้',
      'ไม่คุ้มครองการเสียชีวิต การสูญเสียอวัยวะ การทุพพลภาพ และการรักษาพยาบาล อันเกิดจากสาเหตุดังต่อไปนี้',
    ],
    exceptions: [
      'ข้อยกเว้นสัญญาเพิ่มเติมการประกันภัยอุบัติเหตุ คุ้มครองการเสียชีวิต และสูญเสียอวัยวะ (AP) มีทั้งหมด 11 ข้อ',
      'ข้อยกเว้นสัญญาเพิ่มเติมการประกันภัยอุบัติเหตุ คุ้มครองการเสียชีวิต สูญเสียอวัยวะ และค่ารักษาพยาบาล (ECARE) มีทั้งหมด 12 ข้อ เช่น',
      [
        'การการฆ่าตัวตายหรือการทำร้ายร่างกายตนเอง',
        'การกระทำขณะอยู่ภายใต้ฤทธิ์สุรา ยาเสพติด หรือสารเสพติดให้โทษจนไม่สามารถครองสติได้ คำว่า “ขณะอยู่ภายใต้ฤทธิ์สุรา” นั้น ในกรณีที่มีการตรวจเลือดให้ถือเกณฑ์มีระดับแอลกอฮอล์ในเลือดตั้งแต่ 150 มิลลิกรัมเปอร์เซ็นต์ ขึ้นไป',
        'ขณะที่ผู้เอาประกันภัยเข้าร่วมทะเลาะวิวาท หรือมีส่วนยั่วยุให้เกิดการทะเลาะวิวาท',
        'การแท้งลูก',
        'ขณะขึ้นหรือลง หรือเป็นผู้ขับขี่ หรืออยู่ในอากาศยานใด ๆ ไม่ว่าจะขับเคลื่อนด้วยตัวของมันเองหรือไม่ก็ตามที่มิได้จดทะเบียนประกอบการบินพาณิชย์',
      ],
    ],
    condition:
      'ทั้งนี้ หากพบว่าผู้ขอเอาประกันภัยมีอาชีพข้างต้น ให้ระบุในใบคำขอเอาประกันชีวิต ข้อ 21 (หมายเหตุ) หรือในใบคำร้อง UW06 ว่า “ยินยอมไม่คุ้มครองการจลาจล ฆาตกรรม ลอบทำร้าย และสงครามกลางเมือง” ภายใต้สัญญาเพิ่มเติม ',
  },

  EXCEPTION_NOTED_HIC: {
    title:
      'ข้อยกเว้นสัญญาเพิ่มเติมค่าชดเชยรายวันเนื่องจากโรคมะเร็ง (HIC) มีทั้งหมด 16 ข้อ เช่น\nไม่คุ้มครองการรักษาพยาบาล หรือความเสียหายที่เกิดจากการบาดเจ็บหรือเจ็บป่วย อันเนื่องมาจากสาเหตุ ดังต่อไปนี้',
    exceptions: [
      'โรคเรื้อรัง การเจ็บป่วยที่ยังมิได้รักษาให้หายก่อนวันทำสัญญาประกันภัย การตรวจรักษาภาวะที่เป็นมาแต่กำเนิด (Congenital) หรือโรคทางพันธุกรรม',
      'การติดเชื้อไวรัสภูมิคุ้มกันบกพร่อง (HIV Positive) หรือภาวะโรคภูมิคุ้มกันบกพร่อง (AIDS) ของผู้เอาประกันภัย',
      'ผู้เอาประกันภัยปฏิเสธไม่ยอมรับการรักษา แนะนำ หรือ ปฏิบัติตามคำแนะนำของแพทย์',
      'โรคมะเร็งที่มิได้เป็นไปตามคำนิยามของสัญญาเพิ่มเติมนี้',
      'การตรวจรักษาพยาบาลที่ผู้เอาประกันภัย ซึ่งเป็นแพทย์สั่งให้แก่ตัวเอง รวมทั้งการตรวจรักษาพยาบาลจากแพทย์ ผู้ซึ่งเป็นบิดา มารดา คู่สมรส หรือบุตรของผู้เอาประกันภัย',
    ],
    condition: '',
  },
  EXCEPTION_NOTED_CPR: {
    title:
      'ข้อยกเว้นสัญญาเพิ่มเติมคุ้มครองโรคมะเร็ง (CPR) มีทั้งหมด 10 ข้อ เช่น\nไม่คุ้มครองการเจ็บป่วยด้วยโรคมะเร็ง อันเนื่องมาจากสาเหตุ ดังต่อไปนี้',
    exceptions: [
      'โรคเรื้อรัง การเจ็บป่วยที่ยังมิได้รักษาให้หายก่อนวันเริ่มมีผลคุ้มครอง การตรวจรักษาภาวะที่เป็นมาแต่กำเนิด (Congenital) หรือโรคทางพันธุกรรม',
      'การติดเชื้อไวรัสภูมิคุ้มกันบกพร่อง (HIV Positive) หรือภาวะโรคภูมิคุ้มกันบกพร่อง (AIDS) ของผู้เอาประกันภัย',
      'ผู้เอาประกันภัยปฏิเสธไม่ยอมรับการรักษา แนะนำ หรือ ปฏิบัติตามคำแนะนำของแพทย์',
      'โรคมะเร็งที่มิได้เป็นไปตามคำนิยามของสัญญาเพิ่มเติมนี้',
      'การก่อการร้าย',
    ],
    condition: '',
  },
  EXCEPTION_NOTED_MEAPLUS: {
    title: '',
    exceptions: [
      'ข้อยกเว้นสัญญาเพิ่มเติมค่ารักษาพยาบาล (พิเศษ) (MEA+) มีทั้งหมด 17 ข้อ เช่น',
      [
        'สัญญาเพิ่มเติมนี้ไม่คุ้มครองการรักษาพยาบาล หรือความเสียหายที่เกิดจากการบาดเจ็บหรือเจ็บป่วย (รวมทั้งโรคแทรกซ้อน) อาการ หรือภาวะความผิดปกติที่เกิดจาก',
        [
          'โรคเรื้อรัง การเจ็บป่วยหรือการบาดเจ็บที่ยังมิได้รักษาให้หายก่อนวันทำสัญญาประกันภัย การตรวจรักษาภาวะที่เป็นมาแต่กำเนิด (Congenital) หรือปัญหาด้านพัฒนาการ หรือโรคทางพันธุกรรม',
          'การตรวจรักษาหรือการผ่าตัดเพื่อเสริมสวย หรือการแก้ไขปัญหาผิวพรรณ สิว ฝ้า กระ รังแค ผมร่วง หรือการควบคุมน้ำหนักตัว หรือการผ่าตัดอันมีลักษณะเลือกได้ เว้นแต่เป็นการตกแต่งบาดแผลอันเนื่องมาจากอุบัติเหตุที่ได้รับความคุ้มครอง',
          'การตั้งครรภ์ แท้งบุตร ทำแท้ง การคลอดบุตร โรคแทรกซ้อนจากการตั้งครรภ์ การแก้ไขปัญหาการมีบุตรยาก (รวมถึงการสืบวิเคราะห์และการรักษา) การทำหมันหรือการคุมกำเนิด',
          'โรคเอดส์ หรือกามโรค หรือโรคติดต่อทางเพศสัมพันธ์',
          'การตรวจรักษา หรือการป้องกัน การใช้ยา หรือสารต่างๆ เพื่อการชะลอความเสื่อมของวัย หรือการให้ฮอร์โมนทดแทนในวัยใกล้หมดหรือหมดระดู การเสื่อมสมรรถภาพทางเพศในหญิงหรือชาย การรักษาความผิดปกติทางเพศ และการแปลงเพศ',
        ],
      ],
    ],
    condition: '',
  },
  EXCEPTION_NOTED_MEX: {
    title: 'เงื่อนไขที่สำคัญ และข้อยกเว้นสำหรับสัญญาเพิ่มเติมค่ารักษาพยาบาล เอ็กซ์ตร้า (MEX)',
    exceptions: [
      'เงื่อนไขที่สำคัญ กรณีที่บริษัทจะไม่คุ้มครอง เนื่องจาก',
      'การป่วยที่เกิดขึ้นในระยะเวลาที่ไม่คุ้มครอง (Waiting Period) 30 วัน นับแต่วันเริ่มมีผลคุ้มครองตามสัญญาเพิ่มเติมนี้ หรือวันที่บริษัทอนุมัติให้เพิ่มผลประโยชน์ของสัญญาเพิ่มเติมนี้ แล้วแต่กรณีใดจะเกิดขึ้นภายหลังหรือ',
      [
        'การป่วยดังต่อไปนี้ ที่เกิดขึ้นในระยะเวลาที่ไม่คุ้มครอง (Waiting Period) 120 วัน นับแต่วันเริ่มมีผลคุ้มครองตามสัญญาเพิ่มเติมนี้ หรือวันที่บริษัทอนุมัติให้เพิ่มผลประโยชน์ของสัญญาเพิ่มเติมนี้ แล้วแต่กรณีใดจะเกิดขึ้นภายหลัง',
        [
          'เนื้องอก ถุงน้ำ หรือมะเร็งทุกชนิด',
          'ไส้เลื่อนทุกชนิด',
          'การตัดทอนซิล หรืออดีนอยด์',
          'เส้นเลือดขอดที่ขา',
          'ริดสีดวงทวาร',
          'ต้อเนื้อ หรือต้อกระจก',
          'นิ่วทุกชนิด',
          'เยื่อบุโพรงมดลูกเจริญผิดที่',
        ],
      ],
      [
        'สภาพที่เป็นมาก่อนการเอาประกันภัย (Pre-existing Condition)',
        'บริษัทจะไม่จ่ายผลประโยชน์ตามสัญญาเพิ่มเติมนี้ สำหรับโรคเรื้อรัง การบาดเจ็บ หรือการป่วย (รวมถึงภาวะแทรกซ้อน) ที่ยังมิได้รักษาให้หายก่อนวันที่สัญญาเพิ่มเติมนี้เริ่มมีผลคุ้มครองเป็นครั้งแรก เว้นแต่',
        [
          'ผู้เอาประกันภัยได้แถลงให้บริษัททราบ และบริษัทยินยอมรับความเสี่ยงภัย โดยไม่มีเงื่อนไขยกเว้นความคุ้มครองดังกล่าว หรือ',
          'โรคเรื้อรัง การบาดเจ็บ หรือการป่วย (รวมถึงภาวะแทรกซ้อน) นั้น ไม่ปรากฏอาการ ไม่ได้รับการตรวจรักษาหรือวินิจฉัยโดยแพทย์ หรือไม่ได้พบหรือปรึกษาแพทย์ ในระยะ 5 ปี ก่อนวันที่สัญญาเพิ่มเติมเริ่มมีผลคุ้มครองเป็นครั้งแรก และในช่วงเวลา 3 ปี ตั้งแต่วันที่สัญญาเพิ่มเติมนี้เริ่มมีผลคุ้มครองเป็นครั้งแรก',
        ],
      ],
      'ข้อยกเว้นทั่วไป',
      [
        'ข้อยกเว้นสัญญาเพิ่มเติมค่ารักษาพยาบาล เอ็กซ์ตร้า (MEX) มีทั้งหมด 21 ข้อ ตัวอย่างเช่น',
        'การประกันภัยนี้ไม่คุ้มครองค่าใช้จ่ายจากการรักษาพยาบาล หรือความเสียหายที่เกิดจากการบาดเจ็บหรือการป่วย (รวมทั้งภาวะแทรกซ้อน) อาการ หรือภาวะความผิดปกติที่เกิดจาก',
        [
          'ภาวะที่เป็นผลจากความผิดปกติที่เกิดขึ้นแต่กำเนิด หรือระบบการสร้างอวัยวะของร่างกายไม่สมบูรณ์แต่กำเนิด หรือโรคทางพันธุกรรม หรือความผิดปกติในการพัฒนาการของร่างกาย เว้นแต่ สัญญาเพิ่มเติมนี้มีผลคุ้มครองมาไม่น้อยกว่า 1 ปี และปรากฏอาการหลังผู้เอาประกันภัยมีอายุครบ 16 ปีบริบูรณ์',
          'การตรวจรักษาหรือการผ่าตัดเพื่อเสริมสวย หรือการแก้ไขปัญหาผิวพรรณ สิว ฝ้า กระ รังแค ผมร่วงหรือการควบคุมน้ำหนักตัว การผ่าตัดที่สามารถทดแทนด้วยการรักษาแนวทางอื่น เว้นแต่เป็นการตกแต่งบาดแผลอันเนื่องมาจากอุบัติเหตุที่ได้รับความคุ้มครอง',
          'โรคเอดส์ หรือกามโรค หรือโรคติดต่อทางเพศสัมพันธ์',
          'การฆ่าตัวตาย การพยายามฆ่าตัวตาย การทำร้ายร่างกายตนเอง หรือการพยายามทำร้ายร่างกายตนเองไม่ว่าจะเป็นการกระทำโดยตนเอง หรือยินยอมให้ผู้อื่นกระทำไม่ว่าจะอยู่ในระหว่างวิกลจริตหรือไม่ก็ตาม ทั้งนี้รวมถึงอุบัติเหตุจากการที่ผู้เอาประกันภัย กิน ดื่ม หรือฉีดยาหรือสารมีพิษเข้าร่างกาย การใช้ยาเกินกว่าที่แพทย์สั่ง',
          'การบาดเจ็บที่เกิดขึ้นอันเป็นผลมาจากการกระทำของผู้เอาประกันภัย ',
        ],
        [
          'ขณะอยู่ภายใต้ฤทธิ์สารเสพติด หรือยาเสพติดให้โทษจนไม่สามารถครองสติได้ หรือ',
          'ขณะอยู่ภายใต้ฤทธิ์สุราโดยมีระดับแอลกอฮอล์ในร่างกายขณะตรวจเทียบเท่ากับระดับแอลกอฮอล์ในเลือด ตั้งแต่ 150 มิลลิกรัมเปอร์เซ็นต์ขึ้นไป หรือ',
          'ขณะอยู่ภายใต้ฤทธิ์สุราจนไม่สามารถครองสติได้ ในกรณีที่ไม่มีการตรวจวัดหรือในกรณีที่ไม่สามารถตรวจวัด ระดับแอลกอฮอล์ได้',
        ],
      ],
    ],
    condition: '',
  },
  EXCEPTION_NOTED_MHP: {
    title: 'เงื่อนไขที่สำคัญ และข้อยกเว้นสำหรับสัญญาเพิ่มเติมค่ารักษาพยาบาล ไอเฮลท์ตี้ อัลตร้า (MHP)',
    exceptions: [
      'เงื่อนไขที่สำคัญ กรณีที่บริษัทจะไม่คุ้มครอง เนื่องจาก',
      'การป่วยที่เกิดขึ้นในระยะเวลาที่ไม่คุ้มครอง (Waiting Period) 30 วัน นับแต่วันเริ่มมีผลคุ้มครองตามสัญญาเพิ่มเติมนี้ หรือวันที่บริษัทอนุมัติให้เพิ่มผลประโยชน์ของสัญญาเพิ่มเติมนี้ แล้วแต่กรณีใดจะเกิดขึ้นภายหลัง หรือ',
      [
        'การป่วยดังต่อไปนี้ ที่เกิดขึ้นในระยะเวลาที่ไม่คุ้มครอง (Waiting Period) 120 วัน นับแต่วันเริ่มมีผลคุ้มครองตามสัญญาเพิ่มเติมนี้ หรือวันที่บริษัทอนุมัติให้เพิ่มผลประโยชน์ของสัญญาเพิ่มเติมนี้ แล้วแต่กรณีใดจะเกิดขึ้นภายหลัง',
        [
          'เนื้องอก ถุงน้ำ หรือมะเร็งทุกชนิด',
          'ไส้เลื่อนทุกชนิด',
          'การตัดทอนซิล หรืออดีนอยด์',
          'เส้นเลือดขอดที่ขา',
          'ริดสีดวงทวาร',
          'ต้อเนื้อ หรือต้อกระจก',
          'นิ่วทุกชนิด',
          'เยื่อบุโพรงมดลูกเจริญผิดที่',
        ],
      ],
      [
        'สภาพที่เป็นมาก่อนการเอาประกันภัย (Pre-existing Condition)',
        'บริษัทจะไม่จ่ายผลประโยชน์ตามสัญญาเพิ่มเติมนี้ สำหรับโรคเรื้อรัง การบาดเจ็บ หรือการป่วย (รวมถึงภาวะแทรกซ้อน) ที่ยังมิได้รักษาให้หายก่อนวันที่สัญญาเพิ่มเติมนี้เริ่มมีผลคุ้มครองเป็นครั้งแรก เว้นแต่',
        [
          'ผู้เอาประกันภัยได้แถลงให้บริษัททราบ และบริษัทยินยอมรับความเสี่ยงภัย โดยไม่มีเงื่อนไขยกเว้นความคุ้มครองดังกล่าว หรือ',
          'โรคเรื้อรัง การบาดเจ็บ หรือการป่วย (รวมถึงภาวะแทรกซ้อน) นั้น ไม่ปรากฏอาการ ไม่ได้รับการตรวจรักษาหรือวินิจฉัยโดยแพทย์ หรือไม่ได้พบหรือปรึกษาแพทย์ ในระยะ 5 ปี ก่อนวันที่สัญญาเพิ่มเติมเริ่มมีผลคุ้มครองเป็นครั้งแรก และในช่วงเวลา 3 ปี ตั้งแต่วันที่สัญญาเพิ่มเติมนี้เริ่มมีผลคุ้มครองเป็นครั้งแรก',
        ],
      ],
      'ข้อยกเว้นทั่วไป',
      [
        'ข้อยกเว้นสัญญาเพิ่มเติมค่ารักษาพยาบาล ไอเฮลท์ตี้ อัลตร้า (MHP) มีทั้งหมด 21 ข้อ ตัวอย่างเช่น',
        'การประกันภัยนี้ไม่คุ้มครองค่าใช้จ่ายจากการรักษาพยาบาล หรือความเสียหายที่เกิดจากการบาดเจ็บหรือการป่วย (รวมทั้งภาวะแทรกซ้อน) อาการ หรือภาวะความผิดปกติที่เกิดจาก',
        [
          'ภาวะที่เป็นผลจากความผิดปกติที่เกิดขึ้นแต่กำเนิด หรือระบบการสร้างอวัยวะของร่างกายไม่สมบูรณ์แต่กำเนิด หรือโรคทางพันธุกรรม หรือความผิดปกติในการพัฒนาการของร่างกาย เว้นแต่ สัญญาเพิ่มเติมนี้มีผลคุ้มครองมาไม่น้อยกว่า 1 ปี และปรากฏอาการหลังผู้เอาประกันภัยมีอายุครบ 16 ปีบริบูรณ์',
          'การตรวจรักษาหรือการผ่าตัดเพื่อเสริมสวย หรือการแก้ไขปัญหาผิวพรรณ สิว ฝ้า กระ รังแค ผมร่วงหรือการควบคุมน้ำหนักตัว การผ่าตัดที่สามารถทดแทนด้วยการรักษาแนวทางอื่น เว้นแต่เป็นการตกแต่งบาดแผลอันเนื่องมาจากอุบัติเหตุที่ได้รับความคุ้มครอง',
          'โรคเอดส์ หรือกามโรค หรือโรคติดต่อทางเพศสัมพันธ์',
          'การฆ่าตัวตาย การพยายามฆ่าตัวตาย การทำร้ายร่างกายตนเอง หรือการพยายามทำร้ายร่างกายตนเองไม่ว่าจะเป็นการกระทำโดยตนเอง หรือยินยอมให้ผู้อื่นกระทำไม่ว่าจะอยู่ในระหว่างวิกลจริตหรือไม่ก็ตาม ทั้งนี้รวมถึงอุบัติเหตุจากการที่ผู้เอาประกันภัย กิน ดื่ม หรือฉีดยาหรือสารมีพิษเข้าร่างกาย การใช้ยาเกินกว่าที่แพทย์สั่ง',
          'การบาดเจ็บที่เกิดขึ้นอันเป็นผลมาจากการกระทำของผู้เอาประกันภัย',
        ],
        [
          'ขณะอยู่ภายใต้ฤทธิ์สารเสพติด หรือยาเสพติดให้โทษจนไม่สามารถครองสติได้ หรือ',
          'ขณะอยู่ภายใต้ฤทธิ์สุราโดยมีระดับแอลกอฮอล์ในร่างกายขณะตรวจเทียบเท่ากับระดับแอลกอฮอล์ในเลือด ตั้งแต่ 150 มิลลิกรัมเปอร์เซ็นต์ขึ้นไป หรือ',
          'ขณะอยู่ภายใต้ฤทธิ์สุราจนไม่สามารถครองสติได้ ในกรณีที่ไม่มีการตรวจวัดหรือในกรณีที่ไม่สามารถตรวจวัด ระดับแอลกอฮอล์ได้',
        ],
      ],
    ],
  },
  EXCEPTION_NOTED_FH: {
    title: '',
    exceptions: [
      '(*) สัญญาเพิ่มเติมเป็นสัญญาประกันภัยที่มีระยะเวลาเอาประกันภัย 1 ปี อัตราเบี้ยประกันภัยอาจมีการปรับเพิ่มหรือลดได้ และเงื่อนไขความคุ้มครองอาจเปลี่ยนแปลงได้',
      '(**) ทั้งนี้ไม่รวมถึงการบาดเจ็บ, โรคหัวใจ, โรคมะเร็ง, ไตวาย หรือการรักษาในค่าห้องผู้ป่วยวิกฤต',
      '(***) ผลประโยชน์นี้จำกัดใช้ได้ 1 ครั้งในช่วงชีวิตของผู้เอาประกันภัย',
    ],
    condition: '',
  },
  EXCEPTION_NOTED_ESCI: {
    title: '',
    exceptions: [
      'ข้อยกเว้นสัญญาเพิ่มเติม โรคร้ายแรงคุ้มครองเริ่มต้น (ESCI) มีทั้งหมด 9 ข้อ เช่น',
      'ไม่คุ้มครองการเจ็บป่วยด้วยโรคร้ายแรง อันเนื่องมาจากสาเหตุ ดังต่อไปนี้',
      [
        'โรคร้ายแรงที่เกิดขึ้น ก่อนวันเริ่มมีผลบังคับของสัญญาเพิ่มเติมฉบับนี้ หรือก่อนวันต่ออายุสัญญาเพิ่มเติมครั้งนี้ครั้งสุดท้าย หรือก่อนวันที่บริษัทอนุมัติให้เพิ่มจำนวนเงินเอาประกันภัยของสัญญาเพิ่มเติมนี้ แล้วแต่วันใดจะเกิดขึ้นภายหลัง',
        'ผู้เอาประกันภัยปฏิเสธไม่ยอมรับการรักษา แนะนำ หรือ ปฏิบัติตามคำแนะนำของแพทย์',
        'การติดเชื้อไวรัสภูมิคุ้มกันบกพร่อง (HIV Positive) หรือภาวะของโรคภูมิคุ้มกันบกพร่อง (AIDS) ของผู้เอาประกันภัย',
        'การบริจาคอวัยวะร่างกาย โดยผู้เอาประกันภัยเป็นผู้บริจาคอวัยวะของตนเองให้แก่ผู้อื่น',
        'การดื่มสุรา หรือการใช้ยาในทางที่ผิด (Drug abuse) ที่เป็นสาเหตุโดยตรงและโดยอ้อมให้เกิดการเจ็บป่วย หรือการสูญเสียภาวะการทำงานของอวัยวะหรือกล้ามเนื้อ',
      ],
    ],
    condition: '',
  },
  EXCEPTION_NOTED_WPE: {
    title: '',
    exceptions: [
      'ข้อยกเว้นสัญญาเพิ่มเติมผลประโยชน์ยกเว้นเบี้ยประกันภัยสัญญาหลัก เนื่องจากโรคร้ายแรง 2020 (WPE2020) มีทั้งหมด 6 ข้อ เช่น',
      'ไม่คุ้มครองการเจ็บป่วยด้วยโรคร้ายแรง อันเนื่องมาจากสาเหตุ ดังต่อไปนี้',
      [
        'โรคร้ายแรงที่เกิดขึ้น ก่อนวันเริ่มมีผลบังคับของสัญญาเพิ่มเติมฉบับนี้ หรือก่อนวันต่ออายุสัญญาเพิ่มเติมครั้งนี้ครั้งสุดท้าย หรือก่อนวันที่บริษัทอนุมัติให้เพิ่มจำนวนเงินเอาประกันภัยของสัญญาเพิ่มเติมนี้ แล้วแต่วันใดจะเกิดขึ้นภายหลัง',
        'การติดเชื้อไวรัสภูมิคุ้มกันบกพร่อง (HIV Positive) หรือภาวะของโรคภูมิคุ้มกันบกพร่อง (AIDS) ของผู้เอาประกันภัย',
        'การตรวจรักษาพยาบาลที่ผู้เอาประกันภัย ซึ่งเป็นแพทย์สั่งให้แก่ตัวเอง รวมทั้งการตรวจรักษาพยาบาลจากแพทย์ ผู้ซึ่งเป็น บิดา มารดา คู่สมรส หรือบุตรของผู้เอาประกันภัย',
        'ผู้เอาประกันภัยปฏิเสธไม่ยอมรับการรักษา แนะนำ หรือ ปฏิบัติตามคำแนะนำของแพทย์',
        'การฆ่าตัวตาย หรือการทำร้ายร่างกายตนเอง หรือพยายามกระทำเช่นว่านั้น',
      ],
    ],
    condition: '',
  },
  EXCEPTION_NOTED_DCI: {
    title: '',
    exceptions: [
      'ข้อยกเว้นสัญญาเพิ่มเติมเสียชีวิตและโรคร้ายแรง (DCI) มีทั้งหมด 16 ข้อ เช่น',
      'ไม่คุ้มครองการทุพพลภาพ อันเนื่องมาจากสาเหตุ ดังต่อไปนี้',
      [
        'การฆ่าตัวตาย พยายามฆ่าตัวตาย หรือการทำร้ายร่างกายตนเอง',
        'ความบกพร่องทางร่างกาย การเจ็บป่วย หรือบาดเจ็บ ที่ผู้เอาประกันภัยทราบอยู่แล้วในขณะที่ทำสัญญาประกันภัย แต่มิได้แจ้งให้บริษัททราบ',
        [
          'การบาดเจ็บที่เกิดขึ้นจากการกระทำของผู้เอาประกันภัย อันเกิดจากหรือสืบเนื่องจากสาเหตุภายใต้เงื่อนไขข้อหนึ่งข้อใดดังต่อไปนี้',
          [
            '- ขณะอยู่ภายใต้ฤทธิ์สารเสพติด หรือยาเสพติดให้โทษจนไม่สามารถครองสติได้ หรือ',
            '- ขณะอยู่ภายใต้ฤทธิ์สุรา โดยมีระดับแอลกอฮอล์ในร่างกายขณะตรวจ เทียบเท่ากับระดับแอลกอฮอล์ในเลือดตั้งแต่ 150 มิลลิกรัมเปอร์เซ็นต์ขึ้นไป หรือขณะอยู่ภายใต้ฤทธิ์สุราจนไม่สามารถครองสติได้ ในกรณีที่ไม่มีการตรวจวัดหรือในกรณีที่ไม่สามารถตรวจวัดระดับแอลกอฮอล์ได้',
          ],
        ],
        'การบาดเจ็บขณะที่ผู้เอาประกันภัยปฏิบัติหน้าที่เป็นทหาร ตำรวจ หรืออาสาสมัคร และเข้าปฏิบัติการในสงคราม หรือปราบปราม',
        'การบาดเจ็บขณะที่ผู้เอาประกันภัยขับขี่หรือปฏิบัติหน้าที่เป็นพนักงานประจำอากาศยานใดๆ',
      ],
      'ไม่คุ้มครองการเจ็บป่วยด้วยโรคร้ายแรง อันเนื่องมาจากสาเหตุ ดังต่อไปนี้',
      [
        'การฆ่าตัวตาย หรือการทำร้ายร่างกายตนเอง',
        'ความผิดปกติซึ่งแพทย์ยืนยันและมีหลักฐานชัดเจนว่าเกี่ยวข้องกับโรคร้ายแรงหรือโรคร้ายแรงที่เกิดขึ้นก่อนวันเริ่มมีผลคุ้มครองตามสัญญาเพิ่มเติมนี้',
        'การติดเชื้อไวรัสภูมิคุ้มกันบกพร่อง (HIV Positive) หรือภาวะของโรคภูมิคุ้มกันบกพร่อง (AIDS) ของผู้เอาประกันภัย',
        'ผู้เอาประกันภัยปฏิเสธไม่ยอมรับการรักษา แนะนำ หรือ ปฏิบัติตามคำแนะนำของแพทย์',
        'เสพยาเสพติด หรือสุราเกินขนาด',
      ],
    ],
    condition: '',
  },
  EXCEPTION_NOTED_MEB: {
    title: '',
    exceptions: [
      'สัญญาเพิ่มเติมนี้จะไม่สามารถออกให้กับผู้มีภูมิลำเนาหรืออาศัยอยู่ภายนอกราชอาณาจักร',
      'บริษัทฯ สงวนสิทธิ์ในการรับประกันภัยผู้ที่มีสิทธิ์เบิกค่ารักษาพยาบาล หรือได้รับความคุ้มครองภายใต้ประกันสุขภาพอื่นๆอยู่แล้ว',
    ],
    condition: '',
  },

  EXCEPTION_NOTED_MEB_17: {
    title: '',
    exceptions: [
      'ข้อยกเว้นสัญญาเพิ่มเติมค่าชดเชยรายวัน (MEB) มีทั้งหมด 17 ข้อ เช่น',
      [
        'สัญญาเพิ่มเติมนี้ไม่คุ้มครองการรักษาพยาบาล หรือความเสียหายที่เกิดจากการบาดเจ็บหรือเจ็บป่วย (รวมทั้งโรคแทรกซ้อน) อาการ หรือภาวะความผิดปกติที่เกิดจาก',
        [
          'โรคเรื้อรัง การเจ็บป่วยหรือการบาดเจ็บที่ยังมิได้รักษาให้หายก่อนวันทำสัญญาประกันภัย การตรวจรักษาภาวะที่เป็นมาแต่กำเนิด (Congenital) หรือปัญหาด้านพัฒนาการ หรือโรคทางพันธุกรรม',
          'การตรวจรักษาหรือการผ่าตัดเพื่อเสริมสวย หรือการแก้ไขปัญหาผิวพรรณ สิว ฝ้า กระ รังแค ผมร่วง หรือการควบคุมน้ำหนักตัว หรือการผ่าตัดอันมีลักษณะเลือกได้ เว้นแต่เป็นการตกแต่งบาดแผลอันเนื่องมาจากอุบัติเหตุที่ได้รับความคุ้มครอง',
          'การตั้งครรภ์ แท้งบุตร ทำแท้ง การคลอดบุตร โรคแทรกซ้อนจากการตั้งครรภ์ การแก้ไขปัญหาการมีบุตรยาก (รวมถึงการสืบวิเคราะห์และการรักษา) การทำหมันหรือการคุมกำเนิด',
          'โรคเอดส์ หรือกามโรค หรือโรคติดต่อทางเพศสัมพันธ์',
          'การตรวจรักษา หรือการป้องกัน การใช้ยา หรือสารต่างๆ เพื่อการชะลอความเสื่อมของวัย หรือการให้ฮอร์โมนทดแทนในวัยใกล้หมดหรือหมดระดู การเสื่อมสมรรถภาพทางเพศในหญิงหรือชาย การรักษาความผิดปกติทางเพศ และการแปลงเพศ',
        ],
      ],
    ],
    condition: '',
  },

  EXCEPTION_NOTED_MEK: {
    title: 'เงื่อนไขและข้อยกเว้นที่สำคัญสำหรับสัญญาเพิ่มเติมค่ารักษาพยาบาล (พิเศษ) สำหรับเด็ก “สมาร์ท เมดิแคร์ คิดส์”',
    exceptions: [
      'เงื่อนไขทั่วไป',
      'การเจ็บป่วยใดๆที่เกิดขึ้นในระยะเวลา 30 วันนับจากวันที่ทำสัญญาเพิ่มเติมนี้ หรือวันต่ออายุกรณีสัญญาเพิ่มเติมสิ้นผลบังคับครั้งสุดท้าย แล้วแต่กรณีใดจะเกิดขึ้นหลังสุด',
      [
        'สำหรับการเจ็บป่วยดังต่อไปนี้ ที่เกิดขึ้นในระยะเวลา 120 วันนับจากวันทำสัญญาเพิ่มเติมนี้ หรือวันต่ออายุกรณีสัญญาเพิ่มเติมสิ้นผลบังคับครั้งสุดท้าย แล้วแต่กรณีใดจะเกิดขึ้นหลังสุด',
        [
          'เนื้องอก ถุงน้ำ หรือมะเร็งทุกชนิด',
          'ไส้เลื่อนทุกชนิด',
          'การตัดทอนซิล หรืออดีนอยด์',
          'เส้นเลือดขอดที่ขา',
          'ริดสีดวงทวาร',
          'ต้อเนื้อ หรือต้อกระจก',
          'นิ่วทุกชนิด',
          'เยื่อบุโพรงมดลูกเจริญผิดที่',
        ],
      ],
      'ข้อยกเว้น* มีทั้งหมด 26 ข้อ เช่น',
      [
        'สัญญาเพิ่มเติมนี้ไม่คุ้มครองการรักษาพยาบาล หรือความเสียหายที่เกิดจากการบาดเจ็บหรือเจ็บป่วย (รวมทั้งโรคแทรกซ้อน) อาการ หรือภาวะความผิดปกติที่เกิดจาก',
        [
          'โรคเรื้อรัง การเจ็บป่วยหรือการบาดเจ็บที่ยังมิได้รักษาให้หายก่อนวันทำสัญญาประกันภัย การตรวจรักษาภาวะที่เป็นมาแต่กำเนิด (Congenital) หรือปัญหาด้านพัฒนาการ หรือโรคทางพันธุกรรม',
          'การตรวจรักษาหรือการผ่าตัดเพื่อเสริมสวย หรือการแก้ไขปัญหาผิวพรรณ สิว ฝ้า กระ รังแค ผมร่วง หรือการควบคุมน้ำหนักตัว หรือการผ่าตัดอันมีลักษณะเลือกได้ เว้นแต่เป็นการตกแต่งบาดแผลอันเนื่องมาจากอุบัติเหตุที่ได้รับความคุ้มครอง',
          'การตั้งครรภ์ แท้งบุตร ทำแท้ง การคลอดบุตร โรคแทรกซ้อนจากการตั้งครรภ์ การแก้ไขปัญหาการมีบุตรยาก (รวมถึงการสืบวิเคราะห์และการรักษา) การทำหมันหรือการคุมกำเนิด',
          'โรคเอดส์ หรือกามโรค หรือโรคติดต่อทางเพศสัมพันธ์',
          'การตรวจรักษา หรือการป้องกัน การใช้ยา หรือสารต่างๆ เพื่อการชะลอความเสื่อมของวัย หรือการให้ฮอร์โมนทดแทนในวัยใกล้หมดหรือหมดระดู การเสื่อมสมรรถภาพทางเพศในหญิงหรือชาย การรักษาความผิดปกติทางเพศ และการแปลงเพศ',
        ],
      ],
    ],
    condition: 'หมายเหตุ * เฉพาะข้อยกเว้นบางรายการ ท่านสามารถศึกษารายละเอียดข้อยกเว้นทั้งหมดได้ในเล่มกรมธรรม์',
  },

  EXCEPTION_NOTED_MEH: {
    title: '',
    exceptions: [
      'การเจ็บป่วยใดๆที่เกิดขึ้นในระยะเวลา 30 วันนับจากวันที่ทำสัญญาเพิ่มเติมนี้ หรือวันต่ออายุกรณีสัญญาเพิ่มเติมสิ้นผลบังคับครั้งสุดท้าย แล้วแต่กรณีใดจะเกิดขึ้นหลังสุด',
      [
        'สำหรับการเจ็บป่วยดังต่อไปนี้ ที่เกิดขึ้นในระยะเวลา 120 วันนับจากวันทำสัญญาเพิ่มเติมนี้ หรือวันต่ออายุกรณีสัญญาเพิ่มเติมสิ้นผลบังคับครั้งสุดท้าย แล้วแต่กรณีใดจะเกิดขึ้นหลังสุด',
        [
          'เนื้องอก ถุงน้ำ หรือมะเร็งทุกชนิด',
          'ไส้เลื่อนทุกชนิด',
          'การตัดทอนซิล หรืออดีนอยด์',
          'เส้นเลือดขอดที่ขา',
          'ริดสีดวงทวาร',
          'ต้อเนื้อ หรือต้อกระจก',
          'นิ่วทุกชนิด',
          'เยื่อบุโพรงมดลูกเจริญผิดที่',
        ],
      ],
      'โรคเรื้อรัง การเจ็บป่วยหรือการบาดเจ็บที่ยังมิได้รักษาให้หายก่อนวันทำสัญญาประกันภัย การตรวจรักษาภาวะที่เป็นมาแต่กำเนิด หรือปัญหาด้านพัฒนาการ หรือโรคทางพันธุกรรม',
      'โรคเอดส์ หรือ กามโรค หรือโรคติดต่อทางเพศสัมพันธ์',
    ],
    condition: 'หมายเหตุ * เฉพาะข้อยกเว้นบางรายการ โปรดศึกษารายละเอียดข้อยกเว้นทั้งหมดในกรมธรรม์',
  },

  EXCEPTION_NOTED_MEH_32: {
    title: 'เงื่อนไขและข้อยกเว้นที่สำคัญสำหรับสัญญาเพิ่มเติมค่ารักษาพยาบาล',
    exceptions: [
      'เงื่อนไขทั่วไป',
      'การเจ็บป่วยใดๆที่เกิดขึ้นในระยะเวลา 30 วันนับจากวันเริ่มมีผลคุ้มครองของสัญญาเพิ่มเติมนี้ หรือวันต่ออายุกรณีสัญญาเพิ่มเติมสิ้นผลบังคับครั้งสุดท้าย แล้วแต่กรณีใดจะเกิดขึ้นหลังสุด หรือ',
      [
        'สำหรับการเจ็บป่วยดังต่อไปนี้ ที่เกิดขึ้นในระยะเวลา 120 วันนับจากวันเริ่มมีผลคุ้มครองของสัญญาเพิ่มเติมนี้ หรือวันต่ออายุ กรณีสัญญาเพิ่มเติมสิ้นผลบังคับครั้งสุดท้าย แล้วแต่กรณีใดจะเกิดขึ้นหลังสุด',
        [
          'เนื้องอก ถุงน้ำ หรือมะเร็งทุกชนิด',
          'ไส้เลื่อนทุกชนิด',
          'การตัดทอนซิล หรืออดีนอยด์',
          'เส้นเลือดขอดที่ขา',
          'ริดสีดวงทวาร',
          'ต้อเนื้อ หรือต้อกระจก',
          'นิ่วทุกชนิด',
          'เยื่อบุโพรงมดลูกเจริญผิดที่',
        ],
      ],
      'ข้อยกเว้น* มีทั้งหมด 32 ข้อ เช่น',
      [
        'สัญญาเพิ่มเติมนี้ไม่คุ้มครอง ค่าใช้จ่ายการรักษาพยาบาลหรือความเสียหายที่เกิดจากการบาดเจ็บหรือเจ็บป่วย (รวมทั้งโรคแทรกซ้อน) อาการ หรือ ภาวะความผิดปกติที่เกิดจาก',
        [
          'โรคเรื้อรัง การเจ็บป่วยหรือการบาดเจ็บที่ยังมิได้รักษาให้หายก่อนวันทำสัญญาประกันภัย การตรวจรักษาภาวะที่เป็นมาแต่กำเนิด (Congenital) หรือปัญหาด้านพัฒนาการ หรือโรคทางพันธุกรรม',
          'การตรวจรักษาหรือการผ่าตัดเพื่อเสริมสวย หรือการแก้ไขปัญหาผิวพรรณ สิว ฝ้า กระรังแค ผมร่วง หรือการควบคุมน้ำหนักตัว หรือการผ่าตัดอันมีลักษณะเลือกได้ เว้นแต่เป็นการตกแต่งบาดแผลอันเนื่องมาจาก อุบัติเหตุที่ได้รับความคุ้มครอง',
          'โรคเอดส์ หรือกามโรค หรือโรคติดต่อทางเพศสัมพันธ์',
          'การรักษาพยาบาลเชิงป้องกันโรค',
          'การตรวจรักษา หรือการป้องกัน การใช้ยา หรือสารต่าง ๆ เพื่อการชะลอความเสื่อมของวัย หรือการให้ฮอร์โมนทดแทนในวัยใกล้หมด\nหรือหมดระดู การเสื่อมสมรรถสภาพทางเพศในหญิง หรือชาย การรักษาความผิดปกติทางเพศ และการแปลงเพศ',
        ],
      ],
    ],
    condition: 'หมายเหตุ * เฉพาะข้อยกเว้นบางรายการ ท่านสามารถศึกษารายละเอียดข้อยกเว้นทั้งหมดได้ในกรมธรรม์',
  },

  EXCEPTION_NOTED_WP: {
    title: 'ไม่คุ้มครองการทุพพลภาพ อันเนื่องมาจากสาเหตุ ดังต่อไปนี้',
    exceptions: [
      'ข้อยกเว้นสัญญาเพิ่มเติมผลประโยชน์ยกเว้นเบี้ยประกันภัย (WP) มีทั้งหมด 3 ข้อ ได้แก่',
      [
        'การฆ่าตัวตาย การทำร้ายร่างกายตนเอง',
        'การปฏิบัติการทางทหารในขณะที่มีสงคราม ไม่ว่าจะประกาศหรือไม่ก็ตาม หรือขณะที่ปฏิบัติตามคำสั่งให้ปฏิบัติการเยี่ยงสงคราม หรือพิทักษ์ความสงบเรียบร้อยของบ้านเมือง',
        'อุบัติเหตุที่เกิดแก่อากาศยานใด ๆ ซึ่งผู้เอาประกันภัยเป็นผู้ขับขี่ หรืออยู่ในอากาศยานนั้น เว้นแต่ ผู้เอาประกันภัยเป็นผู้โดยสารผู้หนึ่ง หรือเป็นเจ้าหน้าที่ของสายการบินซึ่งอยู่ในอากาศยานที่ได้ประกอบการโดยสายการบินพาณิชย์รับขนส่งโดยสารตามตารางบินโดยสารในเส้นทางที่ได้กำหนดไว้',
      ],
    ],
    condition: '',
  },

  EXCEPTION_NOTED_WPTPD: {
    title: 'ไม่คุ้มครองการทุพพลภาพถาวรสิ้นเชิง ที่เกิดขึ้นอันสืบเนื่องมาจากสาเหตุหรือภาวะแทรกซ้อนดังต่อไปนี้',
    exceptions: [
      'ข้อยกเว้นสัญญาเพิ่มเติมดับบลิวพี ฟิต (WPTPD) มีทั้งหมด 9 ข้อ เช่น',
      [
        'การฆ่าตัวตาย หรือการทำร้ายร่างกายตนเอง หรือพยายามกระทำเช่นว่านั้น',
        'การบาดเจ็บขณะที่ผู้เอาประกันภัยก่ออาชญากรรมที่มีความผิดสถานหนัก หรือขณะถูกจับกุม หรือหลบหนีการจับกุม',
        'การบาดเจ็บขณะที่ผู้เอาประกันภัยกำลังขึ้นหรือกำลังลง หรือโดยสารอยู่ในอากาศยานที่มิได้จดทะเบียนเพื่อบรรทุกผู้โดยสาร และมิได้ประกอบการโดยสายการบินพาณิชย์',
        'การบาดเจ็บขณะที่ผู้เอาประกันภัยขับขี่หรือปฏิบัติหน้าที่เป็นพนักงานประจำอากาศยานใดๆ',
        'การบาดเจ็บขณะที่ผู้เอาประกันภัยปฏิบัติหน้าที่เป็น ทหาร ตำรวจ หรืออาสาสมัคร และเข้าปฏิบัติการในสงคราม หรือปราบปราม',
        'ความบกพร่องทางร่างกาย การป่วย หรือบาดเจ็บ ที่เกี่ยวข้องกับการทุพพลภาพที่แพทย์ยืนยันและมีหลักฐานชัดเจน ซึ่งผู้เอาประกันภัยทราบอยู่แล้วก่อนวันเริ่มมีผลคุ้มครองตามสัญญาเพิ่มเติมนี้ หรือวันที่บริษัทอนุมัติให้เพิ่มจำนวนเงินเอาประกันภัย หรือวันต่ออายุสัญญากรณีสัญญา\nเพิ่มเติมสิ้นผลบังคับ (Reinstatement) ครั้งสุดท้าย แต่มิได้แจ้งให้บริษัททราบ เว้นแต่ ผู้เอาประกันภัย ได้แถลงให้บริษัททราบและบริษัท\nยินยอมรับความเสี่ยงภัย โดยไม่มีเงื่อนไขยกเว้นความคุ้มครองดังกล่าว',
        'การบาดเจ็บที่เกิดขึ้นจากการกระทำของผู้เอาประกันภัย อันเกิดจาก หรือสืบเนื่องจากสาเหตุภายใต้เงื่อนไขข้อหนึ่งข้อใดดังต่อไปนี้',
        [
          'ขณะอยู่ภายใต้ฤทธิ์สารเสพติด หรือยาเสพติดให้โทษจนไม่สามารถครองสติได้ หรือ',
          'ขณะอยู่ภายใต้ฤทธิ์สุรา โดยมีระดับแอลกอฮอล์ในร่างกายขณะตรวจ เทียบเท่ากับระดับแอลกอฮอล์ในเลือดตั้งแต่ 150 มิลลิกรัมเปอร์เซ็นต์\nขึ้นไป หรือ',
          'ขณะอยู่ภายใต้ฤทธิ์สุราจนไม่สามารถครองสติได้ ในกรณีที่ไม่มีการตรวจวัดหรือในกรณีที่ไม่สามารถตรวจวัดระดับแอลกอฮอล์ได้',
        ],
      ],
    ],
    condition: '',
  },

  EXCEPTION_NOTED_WPTPDCI: {
    title: 'ไม่คุ้มครองการทุพพลภาพถาวรสิ้นเชิง ที่เกิดขึ้นอันสืบเนื่องมาจากสาเหตุหรือภาวะแทรกซ้อนดังต่อไปนี้',
    exceptions: [
      'ข้อยกเว้นสัญญาเพิ่มเติมดับบลิวพี บียอนด์ (WPTPDCI) มีทั้งหมด 15 ข้อ เช่น',
      [
        'การฆ่าตัวตาย หรือการทำร้ายร่างกายตนเอง หรือพยายามกระทำเช่นว่านั้น',
        'การบาดเจ็บขณะที่ผู้เอาประกันภัยก่ออาชญากรรมที่มีความผิดสถานหนัก หรือขณะถูกจับกุม หรือหลบหนีการจับกุม',
        'การบาดเจ็บขณะที่ผู้เอาประกันภัยกำลังขึ้นหรือกำลังลง หรือโดยสารอยู่ในอากาศยานที่มิได้จดทะเบียนเพื่อบรรทุกผู้โดยสาร และมิได้ประกอบการโดยสายการบินพาณิชย์',
        'การบาดเจ็บขณะที่ผู้เอาประกันภัยขับขี่หรือปฏิบัติหน้าที่เป็นพนักงานประจำอากาศยานใดๆ',
        'การบาดเจ็บขณะที่ผู้เอาประกันภัยปฏิบัติหน้าที่เป็น ทหาร ตำรวจ หรืออาสาสมัคร และเข้าปฏิบัติการในสงคราม หรือปราบปราม',
        'ความบกพร่องทางร่างกาย การป่วย หรือบาดเจ็บ ที่เกี่ยวข้องกับการทุพพลภาพที่แพทย์ยืนยันและมีหลักฐานชัดเจน ซึ่งผู้เอาประกันภัยทราบอยู่แล้วก่อนวันเริ่มมีผลคุ้มครองตามสัญญาเพิ่มเติมนี้ หรือวันที่บริษัทอนุมัติให้เพิ่มจำนวนเงินเอาประกันภัย หรือวันต่ออายุสัญญากรณีสัญญา\nเพิ่มเติมสิ้นผลบังคับ (Reinstatement) ครั้งสุดท้าย แต่มิได้แจ้งให้บริษัททราบ เว้นแต่ ผู้เอาประกันภัย ได้แถลงให้บริษัททราบและบริษัท\nยินยอมรับความเสี่ยงภัย โดยไม่มีเงื่อนไขยกเว้นความคุ้มครองดังกล่าว',
        'การบาดเจ็บที่เกิดขึ้นจากการกระทำของผู้เอาประกันภัย อันเกิดจาก หรือสืบเนื่องจากสาเหตุภายใต้เงื่อนไขข้อหนึ่งข้อใดดังต่อไปนี้',
        [
          'ขณะอยู่ภายใต้ฤทธิ์สารเสพติด หรือยาเสพติดให้โทษจนไม่สามารถครองสติได้ หรือ',
          'ขณะอยู่ภายใต้ฤทธิ์สุรา โดยมีระดับแอลกอฮอล์ในร่างกายขณะตรวจ เทียบเท่ากับระดับแอลกอฮอล์ในเลือดตั้งแต่ 150 มิลลิกรัมเปอร์เซ็นต์\nขึ้นไป หรือ',
          'ขณะอยู่ภายใต้ฤทธิ์สุราจนไม่สามารถครองสติได้ ในกรณีที่ไม่มีการตรวจวัดหรือในกรณีที่ไม่สามารถตรวจวัดระดับแอลกอฮอล์ได้',
        ],
      ],
    ],

    titleCI:
      'ไม่คุ้มครองการป่วยด้วยโรคร้ายแรงระยะรุนแรง ซึ่งเกิดขึ้นโดยทางตรงหรือทางอ้อม ทั้งหมดหรือแต่บางส่วน อันเนื่องมาจากสาเหตุดังต่อไปนี้',
    exceptionsCI: [
      [
        'ความผิดปกติซึ่งแพทย์ยืนยันและมีหลักฐานชัดเจนว่าเกี่ยวข้องกับโรคร้ายแรงหรือโรคร้ายแรงที่เกิดขึ้นก่อนวันเริ่มมีผลคุ้มครองตามสัญญา\nเพิ่มเติมนี้ หรือก่อนวันที่ให้มีการต่ออายุสัญญาเมื่อสัญญาเพิ่มเติมสิ้นผลบังคับ (Reinstatement) หรือก่อนวันที่บริษัทอนุมัติให้เพิ่มจำนวนเงิน\nเอาประกันภัยของสัญญาเพิ่มเติมนี้ แล้วแต่วันใดจะเกิดขึ้นภายหลัง ทั้งนี้ ในกรณีที่บริษัทอนุมัติให้เพิ่มจำนวนเงินเอาประกันภัย บริษัทจะไม่\nคุ้มครองเฉพาะในส่วนของจำนวนเงินเอาประกันภัยที่เพิ่มขึ้นเท่านั้น',
        'เว้นแต่ ผู้เอาประกันภัย ได้แถลงให้บริษัททราบและบริษัทยินยอมรับความเสี่ยงภัย โดยไม่มีเงื่อนไขยกเว้นความคุ้มครองดังกล่าว',
      ],
      'การฆ่าตัวตาย หรือการทำร้ายร่างกายตนเอง หรือพยายามกระทำเช่นว่านั้น',
      'ผู้เอาประกันภัยปฏิเสธไม่ยอมรับการรักษา แนะนำ หรือ ปฏิบัติตามคำแนะนำของแพทย์',
      'การติดเชื้อไวรัสภูมิคุ้มกันบกพร่อง (HIV Positive) หรือภาวะของโรคภูมิคุ้มกันบกพร่อง (AIDS) ของผู้เอาประกันภัย ไม่ว่าจะทางตรงหรือ\nทางอ้อมก็ตาม',
    ],
    condition: '',
  },

  EXCEPTION_NOTED_WPD01: {
    title:
      'ไม่คุ้มครองการเสียชีวิตของผู้ชำระเบี้ยประกันภัย อันเนื่องมาจากสาเหตุผู้ชำระเบี้ยประกันภัยฆ่าตัวตายภายใน 1 ปี',
    exceptions: ['ข้อยกเว้นสัญญาเพิ่มเติมผลประโยชน์มรณกรรมผู้ชำระเบี้ยประกันภัย (WPD01) มีทั้งหมด 1 ข้อ ได้แก่'],
    condition: '',
  },

  EXCEPTION_NOTED_WPDD01: {
    title: 'ไม่คุ้มครองการเสียชีวิต หรือการทุพพลภาพของผู้ชำระเบี้ยประกันภัย อันเนื่องมาจากสาเหตุ ดังต่อไปนี้',
    exceptions: [
      'ข้อยกเว้นสัญญาเพิ่มเติมผลประโยชน์ผู้ชำระเบี้ยประกันภัย (WPDD01) มีทั้งหมด 4 ข้อ ได้แก่',
      [
        'ผู้ชำระเบี้ยประกันภัยฆ่าตัวตายภายใน 1 ปี',
        'การทำร้ายร่างกายตนเอง',
        'ขณะที่ผู้เอาประกันภัยปฏิบัติหน้าที่เป็นทหาร ตำรวจ หรืออาสาสมัคร และปฏิบัติการในสงครามหรือเยี่ยงสงคราม (ไม่ว่าจะประกาศหรือไม่ก็ตาม) จลาจล เพื่อพิทักษ์ความสงบเรียบร้อยของบ้านเมือง',
        'ขณะขึ้นหรือลง หรือเป็นผู้ขับขี่ หรืออยู่ในอากาศยานใด ๆ ไม่ว่าจะขับเคลื่อนด้วยตัวของมันเองหรือไม่ก็ตามที่มิได้จดทะเบียนประกอบการบินพาณิชย์',
      ],
    ],
    condition: '',
  },

  EXCEPTION_NOTED_PBPDD_PBSDD: {
    title: 'ข้อยกเว้นสำคัญ',
    exceptions: [
      'ข้อยกเว้นสัญญาเพิ่มเติมพีบี ฟิต (PBPDD / PBSDD) มีทั้งหมด 10 ข้อ เช่น',
      [
        'ไม่คุ้มครองการเสียชีวิต อันเนื่องมาจากผู้ชำระเบี้ยประกันภัยฆ่าตัวตายด้วยใจสมัครภายในหนึ่งปี (1 ปี) นับตั้งแต่วันเริ่มมีผลคุ้มครองตามสัญญาเพิ่มเติมนี้ หรือหากมีการต่ออายุสัญญาเมื่อสัญญาเพิ่มเติมสิ้นผลบังคับ (Reinstatement) ให้นับแต่วันเริ่มมีผลคุ้มครองตามการต่ออายุครั้งสุดท้าย',
      ],
      'ไม่คุ้มครองการทุพพลภาพถาวรสิ้นเชิง ที่เกิดขึ้นอันสืบเนื่องมาจากสาเหตุหรือภาวะแทรกซ้อนดังต่อไปนี้',
      [
        'การฆ่าตัวตาย หรือการทำร้ายร่างกายตนเอง หรือพยายามกระทำเช่นว่านั้น',
        'การบาดเจ็บขณะที่ผู้ชำระเบี้ยประกันภัยก่ออาชญากรรมที่มีความผิดสถานหนัก หรือขณะถูกจับกุม หรือหลบหนีการจับกุม',
        'การบาดเจ็บขณะที่ผู้ชำระเบี้ยประกันภัยกำลังขึ้นหรือกำลังลง หรือโดยสารอยู่ในอากาศยานที่มิได้จดทะเบียนเพื่อบรรทุกผู้โดยสาร  และมิได้ประกอบการโดยสายการบินพาณิชย์',
        'การบาดเจ็บขณะที่ผู้ชำระเบี้ยประกันภัยขับขี่หรือปฏิบัติหน้าที่เป็นพนักงานประจำอากาศยานใดๆ',
        'การบาดเจ็บขณะที่ผู้ชำระเบี้ยประกันภัยปฏิบัติหน้าที่เป็น ทหาร ตำรวจ หรืออาสาสมัคร และเข้าปฏิบัติการในสงคราม หรือปราบปราม',
        'ความบกพร่องทางร่างกาย การป่วย หรือบาดเจ็บ ที่เกี่ยวข้องกับการทุพพลภาพที่แพทย์ยืนยันและมีหลักฐานชัดเจน ซึ่งผู้ชำระเบี้ยประกันภัยทราบอยู่แล้วก่อนวันเริ่มมีผลคุ้มครองตามสัญญาเพิ่มเติมนี้ หรือวันที่บริษัทอนุมัติให้เพิ่มจำนวนเงินเอาประกันภัย หรือวันต่ออายุสัญญากรณีสัญญาเพิ่มเติมสิ้นผลบังคับ (Reinstatement) ครั้งสุดท้าย แต่มิได้แจ้งให้บริษัททราบ เว้นแต่ ผู้ชำระเบี้ยประกันภัยได้แถลงให้บริษัททราบ\nและบริษัทยินยอมรับความเสี่ยงภัย โดยไม่มีเงื่อนไขยกเว้นความคุ้มครองดังกล่าว',
        'การบาดเจ็บที่เกิดขึ้นจากการกระทำของผู้ชำระเบี้ยประกันภัย อันเกิดจาก หรือสืบเนื่องจากสาเหตุภายใต้เงื่อนไขข้อหนึ่งข้อใดดังต่อไปนี้',
      ],
      [
        'ขณะอยู่ภายใต้ฤทธิ์สารเสพติด หรือยาเสพติดให้โทษจนไม่สามารถครองสติได้ หรือ',
        'ขณะอยู่ภายใต้ฤทธิ์สุรา โดยมีระดับแอลกอฮอล์ในร่างกายขณะตรวจ เทียบเท่ากับระดับแอลกอฮอล์ในเลือดตั้งแต่ 150 มิลลิกรัมเปอร์เซ็นต์ขึ้นไป หรือ',
        'ขณะอยู่ภายใต้ฤทธิ์สุราจนไม่สามารถครองสติได้ ในกรณีที่ไม่มีการตรวจวัดหรือในกรณีที่ไม่สามารถตรวจวัดระดับแอลกอฮอล์ได้',
      ],
    ],
    condition: '',
  },

  EXCEPTION_NOTED_PBPDDCI_PBSDDCI: {
    title: 'ข้อยกเว้นสำคัญ',
    exceptions: [
      'ข้อยกเว้นสัญญาเพิ่มเติมพีบีี บียอนด์ (PBPDDCI / PBSDDCI) มีทั้งหมด 16 ข้อ เช่น',
      [
        'ไม่คุ้มครองการเสียชีวิต อันเนื่องมาจากผู้ชำระเบี้ยประกันภัยฆ่าตัวตายด้วยใจสมัครภายในหนึ่งปี (1 ปี) นับตั้งแต่วันเริ่มมีผลคุ้มครองตามสัญญาเพิ่มเติมนี้ หรือหากมีการต่ออายุสัญญาเมื่อสัญญาเพิ่มเติมสิ้นผลบังคับ (Reinstatement) ให้นับแต่วันเริ่มมีผลคุ้มครองตามการต่ออายุครั้งสุดท้าย',
      ],
      'ไม่คุ้มครองการทุพพลภาพถาวรสิ้นเชิง ที่เกิดขึ้นอันสืบเนื่องมาจากสาเหตุหรือภาวะแทรกซ้อนดังต่อไปนี้',
      [
        'การฆ่าตัวตาย หรือการทำร้ายร่างกายตนเอง หรือพยายามกระทำเช่นว่านั้น',
        'การบาดเจ็บขณะที่ผู้ชำระเบี้ยประกันภัยก่ออาชญากรรมที่มีความผิดสถานหนัก หรือขณะถูกจับกุม หรือหลบหนีการจับกุม',
        'การบาดเจ็บขณะที่ผู้ชำระเบี้ยประกันภัยกำลังขึ้นหรือกำลังลง หรือโดยสารอยู่ในอากาศยานที่มิได้จดทะเบียนเพื่อบรรทุกผู้โดยสาร  และมิได้ประกอบการโดยสายการบินพาณิชย์',
        'การบาดเจ็บขณะที่ผู้ชำระเบี้ยประกันภัยขับขี่หรือปฏิบัติหน้าที่เป็นพนักงานประจำอากาศยานใดๆ',
        'การบาดเจ็บขณะที่ผู้ชำระเบี้ยประกันภัยปฏิบัติหน้าที่เป็น ทหาร ตำรวจ หรืออาสาสมัคร และเข้าปฏิบัติการในสงคราม หรือปราบปราม',
        'ความบกพร่องทางร่างกาย การป่วย หรือบาดเจ็บ ที่เกี่ยวข้องกับการทุพพลภาพที่แพทย์ยืนยันและมีหลักฐานชัดเจน ซึ่งผู้ชำระเบี้ยประกันภัยทราบอยู่แล้วก่อนวันเริ่มมีผลคุ้มครองตามสัญญาเพิ่มเติมนี้ หรือวันที่บริษัทอนุมัติให้เพิ่มจำนวนเงินเอาประกันภัย หรือวันต่ออายุสัญญากรณีสัญญาเพิ่มเติมสิ้นผลบังคับ (Reinstatement) ครั้งสุดท้าย แต่มิได้แจ้งให้บริษัททราบ เว้นแต่ ผู้ชำระเบี้ยประกันภัยได้แถลงให้บริษัททราบ\nและบริษัทยินยอมรับความเสี่ยงภัย โดยไม่มีเงื่อนไขยกเว้นความคุ้มครองดังกล่าว',
        'การบาดเจ็บที่เกิดขึ้นจากการกระทำของผู้ชำระเบี้ยประกันภัย อันเกิดจาก หรือสืบเนื่องจากสาเหตุภายใต้เงื่อนไขข้อหนึ่งข้อใดดังต่อไปนี้',
      ],
      [
        'ขณะอยู่ภายใต้ฤทธิ์สารเสพติด หรือยาเสพติดให้โทษจนไม่สามารถครองสติได้ หรือ',
        'ขณะอยู่ภายใต้ฤทธิ์สุรา โดยมีระดับแอลกอฮอล์ในร่างกายขณะตรวจ เทียบเท่ากับระดับแอลกอฮอล์ในเลือดตั้งแต่ 150 มิลลิกรัมเปอร์เซ็นต์ขึ้นไป หรือ',
        'ขณะอยู่ภายใต้ฤทธิ์สุราจนไม่สามารถครองสติได้ ในกรณีที่ไม่มีการตรวจวัดหรือในกรณีที่ไม่สามารถตรวจวัดระดับแอลกอฮอล์ได้',
      ],
      'ไม่คุ้มครองการป่วยด้วยโรคร้ายแรงระยะรุนแรง  ซึ่งเกิดขึ้นโดยทางตรงหรือทางอ้อม ทั้งหมดหรือแต่บางส่วน อันเนื่องมาจากสาเหตุดังต่อไปนี้',
      [
        'ความผิดปกติซึ่งแพทย์ยืนยันและมีหลักฐานชัดเจนว่าเกี่ยวข้องกับโรคร้ายแรงหรือโรคร้ายแรงที่เกิดขึ้นก่อนวันเริ่มมีผลคุ้มครองตามสัญญา\nเพิ่มเติมนี้ หรือก่อนวันที่ให้มีการต่ออายุสัญญาเมื่อสัญญาเพิ่มเติมสิ้นผลบังคับ (Reinstatement) หรือก่อนวันที่บริษัทอนุมัติให้เพิ่มจำนวนเงิน\nเอาประกันภัยของสัญญาเพิ่มเติมนี้ แล้วแต่วันใดจะเกิดขึ้นภายหลัง ทั้งนี้ ในกรณีที่บริษัทอนุมัติให้เพิ่มจำนวนเงินเอาประกันภัย บริษัทจะไม่คุ้มครองเฉพาะในส่วนของจำนวนเงินเอาประกันภัยที่เพิ่มขึ้นเท่านั้น\nเว้นแต่ ผู้ชำระเบี้ยประกันภัย ได้แถลงให้บริษัททราบและบริษัทยินยอมรับความเสี่ยงภัย โดยไม่มีเงื่อนไขยกเว้นความคุ้มครองดังกล่าว',
        'การฆ่าตัวตาย หรือการทำร้ายร่างกายตนเอง หรือพยายามกระทำเช่นว่านั้น',
        'ผู้ชำระเบี้ยประกันภัยปฏิเสธไม่ยอมรับการรักษา แนะนำ หรือ ปฏิบัติตามคำแนะนำของแพทย์',
        'การติดเชื้อไวรัสภูมิคุ้มกันบกพร่อง (HIV Positive) หรือภาวะของโรคภูมิคุ้มกันบกพร่อง (AIDS) ของผู้ชำระเบี้ยประกันภัย ไม่ว่าจะทางตรงหรือทางอ้อมก็ตาม',
      ],
    ],
    condition: '',
  },
}
