import { requestWrapper } from 'e-submission/domain/data'

export const getMwPlusProfileList = (user, payload) => {
  return requestWrapper.getRequest(`my-wealth-plus/customer-profile-list`, user, payload).then(({ data }) => data)
}

export const getMwPlusProfileDetail = (user, payload) => {
  return requestWrapper
    .getRequest(`my-wealth-plus/customer/profile/${payload.customerId}`, user)
    .then(({ data }) => data)
}
