// @flow
import wordingList from './wording-list.json'
import _ from 'lodash'

type Options = {
  replaces: string[],
}

export const wrapWordingToTextJustify = (message: string, className?: string, options?: Options): string => {
  const replaces = _.get(options, 'replaces', wordingList)
  const _className = className || 'group-wording'
  const conditions = new RegExp(replaces.join('|'), 'gi')
  return message.replace(conditions, (wording) => `<span class='${_className}'>${wording}</span>`)
}
