// @flow
import { IfRoleIsLB } from 'common-components/if-role'
import BIMESSAGES from 'core/data-model/constants/bi-messages'
import MESSAGES from 'core/data-model/constants/messages'
import { getToggles } from 'quick-quote/feature-toggles'
import {
  prepareQuickQuote,
  prepareRA,
  prepareRPQ,
  shareQuickQuote,
  shareRA,
  shareRPQ,
} from 'quick-quote/product-common/benefit-illustration/actions'
import { shouldShowShareRAButton } from 'quick-quote/product-investment/benefit-illustration/selectors'
import { isInvestmentInputsValid } from 'quick-quote/product-investment/journey-control-selectors'
import ShareButton from 'quick-quote/product-whole-life/benefit-illustration/components/share-button'
import type { AppState } from 'quick-quote/reducers'
import { connect } from 'react-redux'
import styled from 'styled-components'
import values from 'core/data-model/constants/values'
import { isQuickQuotePath } from 'quick-quote/selectors'
import { getUserAgentType } from 'identity/selectors'
// TODO: create test file for this component
const ShareBtnWrapper = styled.span`
  margin-right: 3px;
`

type ShareQuoteButtonProps = {
  shareQuickQuote: (*) => void,
  prepareQuickQuote: (*) => void,
  shareRPQ: (*) => void,
  prepareRPQ: (*) => void,
  shareRA: (*) => void,
  prepareRA: (*) => void,
  canProceedToInvestmentBenefitIllustration: boolean,
  showShareRAButton: boolean,
  currentUserRole: (*) => void,
}
const ShareQuoteButton = ({
  shareQuickQuote,
  prepareQuickQuote,
  shareRPQ,
  prepareRPQ,
  shareRA,
  prepareRA,
  canProceedToInvestmentBenefitIllustration,
  showShareRAButton,
  currentUserRole,
}: ShareQuoteButtonProps) => {
  const PRINT_SALE_PAPER = 'พิมพ์ใบเสนอขาย'
  const SHARE_RPQ = 'ฟอร์ม RPQ'
  const isBanca = currentUserRole === values.AGENT_TYPE_LB
  const isReviseQQ = isQuickQuotePath() && getToggles().ENABLE_REVISE_FNA && !isBanca
  const submitActionQQ = isReviseQQ ? shareQuickQuote : prepareQuickQuote
  const submitActionRA = isReviseQQ ? shareRA : prepareRA
  const submitActionRPQ = isReviseQQ ? shareRPQ : prepareRPQ

  return (
    <div>
      {getToggles().ENABLE_RA_FORM && showShareRAButton && (
        <IfRoleIsLB
          isLoggedIn
          render={() => (
            <ShareBtnWrapper>
              <ShareButton buttonLabel={MESSAGES.DOWNLOAD_RA_FORM} submitAction={submitActionRA} />
            </ShareBtnWrapper>
          )}
        />
      )}
      <IfRoleIsLB
        isLoggedIn
        render={() => (
          <ShareBtnWrapper>
            <ShareButton
              buttonLabel={
                getToggles().ENABLE_CHANGE_WORDING_ON_SHARE_RPQ_BUTTON ? MESSAGES.DOWNLOAD_RPQ_FORM : SHARE_RPQ
              }
              submitAction={submitActionRPQ}
            />
          </ShareBtnWrapper>
        )}
      />

      <ShareButton
        disabled={!canProceedToInvestmentBenefitIllustration}
        biType={BIMESSAGES.BI_TYPE_FULL}
        buttonLabel={
          getToggles().ENABLE_SAME_WORDING_ON_SHARE_BI_BUTTON ? MESSAGES.DOWNLOAD_SALE_PAPER : PRINT_SALE_PAPER
        }
        submitAction={submitActionQQ}
      />
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  canProceedToInvestmentBenefitIllustration: isInvestmentInputsValid(state),
  showShareRAButton: shouldShowShareRAButton(state),
  currentUserRole: getUserAgentType(state),
})

export default connect(mapStateToProps, {
  shareQuickQuote: shareQuickQuote,
  prepareQuickQuote: prepareQuickQuote,
  shareRPQ: shareRPQ,
  prepareRPQ: prepareRPQ,
  shareRA: shareRA,
  prepareRA: prepareRA,
})(ShareQuoteButton)
