// @flow

import _ from 'lodash'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { Benefit } from 'core/data-model/rider/benefits'

import RiderBenefitSummary from './rider-benefit-summary'
import RiderWithBenefitModal from './rider-benefit-modal'
import MESSAGES from 'core/data-model/constants/bi-messages'
import VALUES from 'core/data-model/constants/values'
import { AgentProtectionDisclaimer } from './agent-protection-disclaimer'
import { AdditionalDisclaimer } from './additional-disclaimer'
import { getRiderPlanCode as getMHPRiderPlanCode, getDisplayOptionalMessage } from 'core/service/rider/rider-mhp'
import { getMHPExceptionNotes } from 'core/service/rider/benefits/exception-notes'
import { renderListItems } from 'quick-quote/util/html-render-utils'
import type { Age } from 'core/data-model/insured/types'
import styled from 'styled-components'
import { WordWraps } from 'common-components'

const BoldStyled = styled.span`
  font-weight: bold;
  margin-right: 10px;
`

const CenterStyled = styled.div`
  text-align: center;
`
const UnderlineStyled = styled.div`
  text-decoration: underline;
`
const ItalicStyled = styled.div`
  font-style: italic;
`
type MHPRiderBenefitProps = {
  rider: Rider & RiderState,
  riderBenefitData: Benefit,
  insureAge: Age,
}

export const findDataOnListByRiderCode = (contents, planCode) => {
  return contents.find((content) => content.planCode === planCode)
    ? contents.find((content) => content.planCode === planCode).value
    : '-'
}

export const isJuniorPlan = (planCode) => {
  return VALUES.LIST_OF_MHP_JUNIOR.includes(planCode)
}

export const getMainPlanCode = (planCode) => {
  let mainPlan = planCode
  Object.entries(VALUES.MHP_BENEFIT_GROUP).forEach(([key, value]) => {
    if (value.includes(planCode)) mainPlan = key
  })
  return mainPlan
}

const RenderMHPRiderBenefitsHeadTitle = (props) => {
  const { headTitle } = props
  return <h3>{headTitle}</h3>
}

export const findTerritoryCode = (planCode) => {
  if (VALUES.LIST_OF_MHP_ASIA.includes(planCode)) return 'A'
  if (VALUES.LIST_OF_MHP_WW.includes(planCode)) return 'W'
  return 'T'
}

const RenderMHPRiderBenefitsTerritoryTitle = (props) => {
  const { territory, planCode } = props
  const territoryCode = findTerritoryCode(planCode)
  const area = territory.area.find((item) => item.key === territoryCode).value
  return (
    <h3>
      {territory.title} {area}
    </h3>
  )
}

const BenefitTableHeader = () => {
  return (
    <thead>
      <tr>
        <td style={{ width: '40.75%' }}>{MESSAGES.BENEFIT_TITLE}</td>
        <td style={{ width: '14.14%' }}>{MESSAGES.BENEFIT_BAHT}</td>
        <td style={{ width: '17.87%' }}>{MESSAGES.RIDER_BENEFIT_DAY_OR_TIME}</td>
        <td style={{ width: '27.25%' }}>{MESSAGES.RIDER_TOTAL_BENEFIT_BAHT}</td>
      </tr>
    </thead>
  )
}

export const RenderMHPRiderEndorseTable = (props) => {
  const { endorse, planCode, rider } = props
  const { title, contents } = endorse
  const basePlanCode = getMainPlanCode(planCode)
  const productClassName = _.get(rider, 'selectedPlan.plan').toLowerCase()
  const productOptional = _.get(rider, 'selectedPlan.planOptional', VALUES.MHP_FULL_COVERAGE).toLowerCase()
  const displayOptional = getDisplayOptionalMessage(rider)
  return (
    <div className="mex-details-table">
      <h3>{title}</h3>
      <table id="mex-benefit-table">
        <BenefitTableHeader />
        <tbody>
          <tr>
            <td>
              <BoldStyled>{contents[0].text[0]}</BoldStyled>
              {contents[0].text[1].replace(/\n/g, '')}
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[0].benefit, basePlanCode)}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[0].totalTimes[0]}</CenterStyled>
            </td>
            <td
              rowSpan={productClassName === 'diamond' || productClassName === 'platinum' ? '16' : '7'}
              className="align-top"
            >
              <CenterStyled>
                {findDataOnListByRiderCode(contents[0].totalBenefit, basePlanCode)}{' '}
                {findDataOnListByRiderCode(contents[0].totalBenefit, basePlanCode) !== '-' &&
                  contents[0].totalBenefitDesc.replace(/\n/g, '')}
              </CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[1].text[0]}</BoldStyled>
              {contents[1].text[1]}
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[1].benefit, basePlanCode)}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[1].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[2].text[0]}</BoldStyled>
              {contents[2].text[1]}
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[2].benefit, basePlanCode)}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[2].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[3].text[0]}</BoldStyled>
              {contents[3].text[1]}
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[3].benefit, basePlanCode)}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[3].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[4].text[0]}</BoldStyled>
              {contents[4].text[1]}
            </td>
            <td rowSpan={2}>
              <CenterStyled>{findDataOnListByRiderCode(contents[4].benefit, basePlanCode)}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[4].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[5].text[0]}</BoldStyled>
              {contents[5].text[1]}
            </td>
            <td>
              <CenterStyled>{contents[5].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <UnderlineStyled>
                <BoldStyled>{contents[16].text[displayOptional]}</BoldStyled>
              </UnderlineStyled>
            </td>
          </tr>
          <tr>
            <td>{contents[17].text[displayOptional]}</td>
            <td colSpan={3}>
              <CenterStyled>{findDataOnListByRiderCode(contents[17].totalBenefit, planCode)}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>{contents[18].text[displayOptional]}</td>
            <td colSpan={3}>
              <CenterStyled>{findDataOnListByRiderCode(contents[18].totalBenefit, productOptional)}</CenterStyled>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export const RenderMHPRiderEndorseTableDiamond = (props) => {
  const { endorse, planCode, rider } = props
  const { title, contents } = endorse
  const basePlanCode = getMainPlanCode(planCode)
  const productOptional = _.get(rider, 'selectedPlan.planOptional', VALUES.MHP_FULL_COVERAGE).toLowerCase()
  const displayOptional = getDisplayOptionalMessage(rider)
  return (
    <div className="mex-details-table">
      <h3>{title}</h3>
      <table id="mex-benefit-table">
        <BenefitTableHeader />
        <tbody>
          <tr>
            <td>
              <BoldStyled>{contents[0].text[0]}</BoldStyled>
              {contents[0].text[1].replace(/\n/g, '')}
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[0].benefit, basePlanCode)}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[0].totalTimes[0]}</CenterStyled>
            </td>
            <td rowSpan={'11'} className="align-top">
              <CenterStyled>
                {findDataOnListByRiderCode(contents[0].totalBenefit, basePlanCode)}{' '}
                {findDataOnListByRiderCode(contents[0].totalBenefit, basePlanCode) !== '-' &&
                  contents[0].totalBenefitDesc.replace(/\n/g, '')}
              </CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[1].text[0]}</BoldStyled>
              {contents[1].text[1]}
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[1].benefit, basePlanCode)}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[1].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[2].text[0]}</BoldStyled>
              {contents[2].text[1]}
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[2].benefit, basePlanCode)}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[2].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[3].text[0]}</BoldStyled>
              {contents[3].text[1]}
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[3].benefit, basePlanCode)}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[3].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[4].text[0]}</BoldStyled>
              {contents[4].text[1]}
            </td>
            <td rowSpan={2}>
              <CenterStyled>{findDataOnListByRiderCode(contents[4].benefit, basePlanCode)}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[4].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[5].text[0]}</BoldStyled>
              {contents[5].text[1]}
            </td>
            <td>
              <CenterStyled>{contents[5].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[6].text[0]}</BoldStyled>
              {contents[6].text[1]}
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[6].benefit, basePlanCode)}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[6].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[7].text[0]}</BoldStyled>
              <WordWraps>{contents[7].text[1]}</WordWraps>
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[7].benefit, basePlanCode)}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[7].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[8].text[0]}</BoldStyled>
              <WordWraps>{contents[8].text[1]}</WordWraps>
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[8].benefit, basePlanCode)}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[8].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[9].text[0]}</BoldStyled>
              {contents[9].text[1]}
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[9].benefit, basePlanCode)}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[9].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <UnderlineStyled>
                <BoldStyled>{contents[16].text[displayOptional]}</BoldStyled>
              </UnderlineStyled>
            </td>
          </tr>
          <tr>
            <td>{contents[17].text[displayOptional]}</td>
            <td colSpan={3}>
              <CenterStyled>{findDataOnListByRiderCode(contents[17].totalBenefit, planCode)}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>{contents[18].text[displayOptional]}</td>
            <td colSpan={3}>
              <CenterStyled>{findDataOnListByRiderCode(contents[18].totalBenefit, productOptional)}</CenterStyled>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

const RenderMHPRiderEndorseTablePlatinum = (props) => {
  const { endorse, planCode, rider } = props
  const { title, contents } = endorse
  const basePlanCode = getMainPlanCode(planCode)
  const productClassName = _.get(rider, 'selectedPlan.plan').toLowerCase()
  const productOptional = _.get(rider, 'selectedPlan.planOptional', VALUES.MHP_FULL_COVERAGE).toLowerCase()
  const displayOptional = getDisplayOptionalMessage(rider)
  return (
    <div className="mex-details-table">
      <h3>{title}</h3>
      <table id="mex-benefit-table">
        <BenefitTableHeader />
        <tbody>
          <tr>
            <td>
              <BoldStyled>{contents[0].text[0]}</BoldStyled>
              {contents[0].text[1].replace(/\n/g, '')}
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[0].benefit, basePlanCode)}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[0].totalTimes[0]}</CenterStyled>
            </td>
            <td
              rowSpan={productClassName === 'diamond' || productClassName === 'platinum' ? '16' : '7'}
              className="align-top"
            >
              <CenterStyled>
                {findDataOnListByRiderCode(contents[0].totalBenefit, basePlanCode)}{' '}
                {findDataOnListByRiderCode(contents[0].totalBenefit, basePlanCode) !== '-' &&
                  contents[0].totalBenefitDesc.replace(/\n/g, '')}
              </CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[1].text[0]}</BoldStyled>
              {contents[1].text[1]}
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[1].benefit, basePlanCode)}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[1].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[2].text[0]}</BoldStyled>
              {contents[2].text[1]}
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[2].benefit, basePlanCode)}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[2].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[3].text[0]}</BoldStyled>
              {contents[3].text[1].replace(/\n/g, '')}
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[3].benefit, basePlanCode)}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[3].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[4].text[0]}</BoldStyled>
              {contents[4].text[1]}
            </td>
            {/* rowspan={2} className={(productClassName === 'silver' || productClassName === 'gold') && 'last-row'} */}
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[4].benefit, basePlanCode)}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[4].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[5].text[0]}</BoldStyled>
              {contents[5].text[1]}
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[5].benefit, basePlanCode)}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[5].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[6].text[0]}</BoldStyled>
              {contents[6].text[1]}
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[6].benefit, basePlanCode)}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[6].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[7].text[0]}</BoldStyled>
              <WordWraps>{contents[7].text[1]}</WordWraps>
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[7].benefit, basePlanCode)}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[7].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[8].text[0]}</BoldStyled>
              <WordWraps>{contents[8].text[1]}</WordWraps>
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[8].benefit, basePlanCode)}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[8].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[9].text[0]}</BoldStyled>
              {contents[9].text[1].replace(/\n/g, '')}
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[9].benefit, basePlanCode)}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[9].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[10].text[0]}</BoldStyled>
              <WordWraps>{contents[10].text[1].replace(/\n/g, '')}</WordWraps>
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[10].benefit, basePlanCode)}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[10].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[11].text[0]}</BoldStyled>
              <WordWraps>{contents[11].text[1]}</WordWraps>
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[11].benefit, basePlanCode)}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[11].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[12].text[0]}</BoldStyled>
              {contents[12].text[1]}
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[12].benefit, basePlanCode)}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[12].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[13].text[0]}</BoldStyled>
              {contents[13].text[1]}
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[13].benefit, basePlanCode)}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[13].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[14].text[0]}</BoldStyled>
              {contents[14].text[1].replace(/\n/g, '')}
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[14].benefit, basePlanCode)}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[14].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <UnderlineStyled>
                <BoldStyled>{contents[16].text[displayOptional]}</BoldStyled>
              </UnderlineStyled>
            </td>
          </tr>
          <tr>
            <td>{contents[17].text[displayOptional]}</td>
            <td colSpan={3}>
              <CenterStyled>{findDataOnListByRiderCode(contents[17].totalBenefit, planCode)}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>{contents[18].text[displayOptional]}</td>
            <td colSpan={3}>
              <CenterStyled>
                {findDataOnListByRiderCode(contents[18].totalBenefit, productOptional).replace(/\n/g, '')}
              </CenterStyled>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

const RenderMHPRiderBenefitsTable = (props) => {
  const { benefits, planCode, rider } = props
  const { title, contents } = benefits
  const basePlanCode = getMainPlanCode(planCode)
  const productOptional = _.get(rider, 'selectedPlan.planOptional', VALUES.MHP_FULL_COVERAGE).toLowerCase()
  const displayOptional = _.get(rider, 'selectedPlan.allowOptionalIHealthyUltra', false)
    ? 'allowOption'
    : 'notAllowOption'
  return (
    <div className="mex-details-table">
      <h3>{title}</h3>
      <table id="mex-benefit-table">
        <BenefitTableHeader />
        <tbody>
          <tr>
            <td colSpan={4}>
              <BoldStyled>{contents[0].text[0]}</BoldStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[1].text[0]}</BoldStyled>
              {contents[1].text[1].replace(/\n/g, '')}
              <br />
              &emsp;{contents[1].text[2].replace(/\n/g, '')}
            </td>
            <td>
              <CenterStyled>{findDataOnListByRiderCode(contents[1].benefit, basePlanCode)}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[1].totalTimes[0]}</CenterStyled>
            </td>
            <td rowSpan={26} className="align-top">
              {findDataOnListByRiderCode(contents[1].totalBenefit, basePlanCode)}{' '}
              {contents[1].totalBenefitDesc.replace(/\n/g, '')}
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <BoldStyled>{contents[2].text[0]}</BoldStyled>
              {contents[2].text[1]}
            </td>
          </tr>
          <tr>
            <td>{contents[3].text[0]}</td>
            <td>
              <CenterStyled>{contents[3].benefit[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[3].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>{contents[3].text[1]} </td>
            <td>
              <CenterStyled>{contents[3].benefit[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[3].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>{contents[3].text[2]}</td>
            <td>
              <CenterStyled>{contents[3].benefit[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[3].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>{contents[3].text[3]}</td>
            <td>
              <CenterStyled>{contents[3].benefit[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[3].totalTimes[1].replace(/\n/g, '')}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[4].text[0]}</BoldStyled>
              {contents[4].text[1]}
            </td>
            <td>
              <CenterStyled>
                {findDataOnListByRiderCode(contents[4].benefit, basePlanCode).replace(/\n/g, '')}
              </CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[4].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <BoldStyled>{contents[5].text[0]}</BoldStyled>
              {contents[5].text[1]}
            </td>
          </tr>
          <tr>
            {/* Number #4.1  */}
            <td>{contents[6].text[0]}</td>
            <td>
              <CenterStyled>{contents[6].benefit[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[6].totalBenefit[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>{contents[6].text[1]}</td>
            <td>
              <CenterStyled>{contents[6].benefit[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[6].totalBenefit[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>{contents[6].text[2]}</td>
            <td>
              <CenterStyled>{contents[6].benefit[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[6].totalBenefit[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>{contents[6].text[3]}</td>
            <td>
              <CenterStyled>{contents[6].benefit[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[6].totalBenefit[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>{contents[6].text[4]}</td>
            <td>
              <CenterStyled>{contents[6].benefit[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[6].totalBenefit[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            {/* Number #5.1  */}
            <td>
              <BoldStyled>{contents[7].text[0]}</BoldStyled>
              {contents[7].text[1]}
            </td>
            <td>
              <CenterStyled>{contents[7].benefit[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[7].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <BoldStyled>{contents[8].text[0]}</BoldStyled>
            </td>
          </tr>
          <tr>
            {/* Number #6  */}
            <td colSpan={3}>
              <BoldStyled>{contents[9].text[0]}</BoldStyled>
              {contents[9].text[1].replace(/\n/g, '')}
            </td>
          </tr>
          <tr>
            {/* Number #6.1 */}
            <td>{contents[10].text[0]}</td>
            <td>
              <CenterStyled>{contents[10].benefit[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[10].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>{contents[10].text[1]}</td>
            <td>
              <CenterStyled>{contents[10].benefit[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[10].totalTimes[1]}</CenterStyled>
            </td>
          </tr>
          <tr>
            {/* Number #7*/}
            <td>
              <BoldStyled>{contents[11].text[0]}</BoldStyled>
              {contents[11].text[1]}
            </td>
            <td>
              <CenterStyled>{contents[11].benefit[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[11].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[12].text[0]}</BoldStyled>
              {contents[12].text[1]}
            </td>
            <td>
              <CenterStyled>{contents[12].benefit[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[12].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[13].text[0]}</BoldStyled>
              {contents[13].text[1].replace(/\n/g, '')}
            </td>
            <td>
              <CenterStyled>{contents[13].benefit[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[13].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[14].text[0]}</BoldStyled>
              {contents[14].text[1]}
            </td>
            <td>
              <CenterStyled>{contents[14].benefit[0].replace(/\n/g, '')}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[14].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[15].text[0]}</BoldStyled>
              {contents[15].text[1]}
            </td>
            <td>
              <CenterStyled>{contents[15].benefit[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[15].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[16].text[0]}</BoldStyled>
              {contents[16].text[1]}
            </td>
            <td>
              <CenterStyled>{contents[16].benefit[0]}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[16].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>
              <BoldStyled>{contents[17].text[0]}</BoldStyled>
              {contents[17].text[1]}
            </td>
            <td>
              <CenterStyled>{contents[17].benefit[0].replace(/\n/g, '')}</CenterStyled>
            </td>
            <td>
              <CenterStyled>{contents[17].totalTimes[0]}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <UnderlineStyled>
                <BoldStyled>{contents[18].text[displayOptional]}</BoldStyled>
              </UnderlineStyled>
            </td>
          </tr>
          <tr>
            <td>{contents[19].text[displayOptional]}</td>
            <td colSpan={3}>
              <CenterStyled>{findDataOnListByRiderCode(contents[19].totalBenefit, planCode)}</CenterStyled>
            </td>
          </tr>
          <tr>
            <td>{contents[20].text[displayOptional]}</td>
            <td colSpan={3}>
              <CenterStyled>{findDataOnListByRiderCode(contents[20].totalBenefit, productOptional)}</CenterStyled>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

const RenderMHPRiderDisclaimer = (props) => {
  const { disclaimer, planCode, rider, territory } = props
  const productClassName = _.get(rider, 'selectedPlan.plan').toLowerCase()
  const territoryCode = findTerritoryCode(planCode)
  const productOptional = _.get(rider, 'selectedPlan.planOptional', VALUES.MHP_FULL_COVERAGE).toLowerCase()

  return (
    <div className="disclaimer-box">
      {/* <div>{disclaimer.all_condition}</div> */}
      {isJuniorPlan(planCode) && <div>{disclaimer.junior_condition}</div>}
      {productClassName === 'diamond' || productClassName === 'platinum' ? (
        <div>{disclaimer.diamond_condition}</div>
      ) : (
        ''
      )}
      {productClassName === 'platinum' ? <div>{disclaimer.platinum_condition}</div> : ''}
      {productOptional === 'co_payment' || productOptional === 'deductible' ? (
        <div>{disclaimer.optional_condition.replace(/\n/g, '')}</div>
      ) : (
        ''
      )}
      {productClassName === 'diamond' ||
      productClassName === 'platinum' ||
      productClassName === 'silver' ||
      productClassName === 'gold' ? (
        <div>{disclaimer.smart_condition}</div>
      ) : (
        ''
      )}
      <p></p>
      <div>
        <BoldStyled>{disclaimer.common_condition[0]}</BoldStyled>
      </div>
      <div>&emsp;{disclaimer.common_condition[1].replace(/\n/g, '')}</div>
      <p></p>
      <div>
        <BoldStyled>{disclaimer.common_condition[2]}</BoldStyled>
      </div>
      <div>&emsp;{disclaimer.common_condition[3]}</div>
      <p>{disclaimer.common_condition[4]}</p>
      {territoryCode === 'A' && (
        <p>
          <ItalicStyled>{territory.asia_remark}</ItalicStyled>
        </p>
      )}
    </div>
  )
}

const renderExceptionNotes = (rider) => {
  const exceptionNotes = getMHPExceptionNotes()
  const exceptionTitle = exceptionNotes.title
  const [
    normalConditionSubject,
    firstException,
    secondException,
    thridException,
    extendExceptionTitle,
    extendExceptionDesc,
  ] = exceptionNotes.exceptions

  return (
    <div id="meh-exception-note32">
      <p></p>
      <p>
        <h3>{exceptionTitle}</h3>
      </p>
      <p>
        <h3>{normalConditionSubject} </h3>
      </p>
      <ol id="meh-exception-list">
        <li>{firstException}</li>
        <li>
          {secondException[0]}
          <div className="meh-diseases">
            <div>
              <ul>{renderListItems(secondException[1], 0, 4)}</ul>
            </div>
            <div>
              <ul>{renderListItems(secondException[1], 4, 8)}</ul>
            </div>
          </div>
        </li>
        <li>
          <div>{thridException[0]}</div>
          <div>{thridException[1]}</div>
          <ol className="list-cell">
            <li>
              <span>{'(1)'}</span>
              <span>{thridException[2][0]}</span>
            </li>
            <li>
              <span>{'(2)'}</span>
              <span>{thridException[2][1]}</span>
            </li>
          </ol>
        </li>
      </ol>
      <p>
        <h3>{extendExceptionTitle}</h3>
      </p>
      <div>{extendExceptionDesc[0]}</div>
      <div>{extendExceptionDesc[1]}</div>

      <ol style={{ 'margin-bottom': '0' }}>{renderListItems(extendExceptionDesc[2], 0, 5)}</ol>
      <div>{renderSubItem(extendExceptionDesc[3])}</div>
    </div>
  )
}

const renderSubItem = (data: string[]) => {
  return data.map((dataRow, index) => {
    return (
      <div className="list-cell" key={`data_${index}`}>
        &emsp;&ensp;&ensp;<span>{`(${index + 1})`}</span>
        <span dangerouslySetInnerHTML={{ __html: dataRow }} />
      </div>
    )
  })
}

const MHPRiderBenefit = (props: MHPRiderBenefitProps) => {
  const { rider, riderBenefitData, insureAge } = props
  const title = `${rider.description} ${rider.name}`
  const planCode = getMHPRiderPlanCode(rider, insureAge)
  const { benefits, endorse, headTitle, disclaimer, territory } = riderBenefitData
  const productClassName = _.get(rider, 'selectedPlan.plan').toLowerCase()
  const benefitInfo = (
    <div>
      <RenderMHPRiderBenefitsHeadTitle headTitle={headTitle} />
      <RenderMHPRiderBenefitsTerritoryTitle territory={territory} planCode={planCode} />
      <RenderMHPRiderBenefitsTable benefits={benefits} planCode={planCode} rider={rider} />
      {!(productClassName === 'smart' || productClassName === 'bronze') &&
        (productClassName === 'platinum' ? (
          <RenderMHPRiderEndorseTablePlatinum endorse={endorse} planCode={planCode} rider={rider} />
        ) : productClassName === 'diamond' ? (
          <RenderMHPRiderEndorseTableDiamond endorse={endorse} planCode={planCode} rider={rider} />
        ) : (
          <RenderMHPRiderEndorseTable endorse={endorse} planCode={planCode} rider={rider} />
        ))}
      <RenderMHPRiderDisclaimer disclaimer={disclaimer} planCode={planCode} rider={rider} territory={territory} />
    </div>
  )
  return (
    <RiderWithBenefitModal
      id="mhp-rider-benefit"
      className="rider mexmea-plus-rider-rider"
      title={title}
      modalChildren={benefitInfo}
    >
      <RiderBenefitSummary rider={rider} />
      {benefitInfo}
      {renderExceptionNotes(rider)}
      <AgentProtectionDisclaimer rider={rider} />
      <AdditionalDisclaimer
        rider={rider}
        overrideTitle={`${MESSAGES.MEDICAL_FEE} ${rider.description} (${rider.code})`}
      />
    </RiderWithBenefitModal>
  )
}

export default MHPRiderBenefit
