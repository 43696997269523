import { requestWrapper } from 'e-submission/domain/data'
import { base64ToBlob } from 'e-submission/utils'
import { DOCUMENT_TYPE } from 'e-submission/domain/data-model/constants'

const DEFAULT_DOCUMENT_TYPE = DOCUMENT_TYPE['insured']['ADDITIONAL_DOCUMENT']['name']

export const deleteAdditionalDocument = (user, document = {}, documentType = DEFAULT_DOCUMENT_TYPE) => {
  const params = {
    documentType,
  }
  return requestWrapper
    .deleteRequest(`esub/policies/${document.policyId}/documents/${document.documentId}`, user, params)
    .then(({ data }) => data)
}

export const createAdditionalDocument = (
  user,
  document = {},
  documentType = DEFAULT_DOCUMENT_TYPE,
  isInsured = false
) => {
  const params = {
    documentType,
    description: document.description,
    isInsured,
  }
  return requestWrapper
    .postRequest(`esub/policies/${document.policyId}/documents`, user, params)
    .then(({ data }) => data)
}

export const updateAdditionalDocument = (user, document = {}, documentType = DEFAULT_DOCUMENT_TYPE) => {
  const params = {
    documentType,
    description: document.description,
  }
  return requestWrapper
    .putRequest(`esub/policies/${document.policyId}/documents/${document.documentId}`, user, params)
    .then(({ data }) => data)
}

export const signDocument = (user, document = {}) => {
  const params = {
    signedBy: document.signature.signedBy,
    signature: document.data,
  }
  return requestWrapper
    .postRequest(`esub/policies/${document.policyId}/documents/${document.name}/signature`, user, params)
    .then(({ data }) => data)
}

export const signAllDocument = (user, document = {}, documentCodes) => {
  const params = {
    signedBy: document.signature.signedBy,
    documentCodes: documentCodes,
    signature: document.data,
  }
  return requestWrapper
    .postRequest(`esub/policies/${document.policyId}/documents/signature-all`, user, params)
    .then(({ data }) => data)
}

export const signEmptySignatureDocument = (user, document = {}) => {
  const params = {
    signedBy: document.signature.signedBy,
  }
  return requestWrapper
    .postRequest(`esub/policies/${document.policyId}/documents/${document.name}/signemptysignature`, user, params)
    .then(({ data }) => data)
}

export const getDocument = (user, document = {}) => {
  return requestWrapper
    .getRequest(`esub/policies/${document.policyId}/documents/${document.name}`, user)
    .then(({ data }) => data)
    .then(({ data }) => base64ToBlob(data, 'application/pdf'))
}

export const getBase64Document = (user, document = {}) => {
  return requestWrapper
    .getRequest(`esub/policies/${document.policyId}/documents/${document.name}`, user)
    .then(({ data }) => data)
}

export const listDocument = (user, policyId) => {
  return requestWrapper.getRequest(`esub/policies/${policyId}/documents`, user).then(({ data }) => data)
}
