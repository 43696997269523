// @flow
import template from 'core/service/pdf-generation/products/whole-life/template'
import { createPdfDocument } from 'core/service/pdf-generation/document-creation'
import { createCommonPdf } from '../common/pdf-document'

import { getContentFullContent } from './content'
import type { WholeLifeProps } from './index'

export const createFullBIDocDefinition = async (props: WholeLifeProps) => {
  const pdfTemplate = await template({ ...props, biType: props.biType })
  return {
    content: await getContentFullContent(props),
    ...pdfTemplate,
  }
}

const createFullBIPdf = createPdfDocument(createFullBIDocDefinition)

export const createWholeLifePdf = async (props: WholeLifeProps) => {
  return await createCommonPdf(props, createFullBIPdf)
}
