import styled from 'styled-components'
import { Flex } from 'rebass'

const FormContent = styled.div`
  position: relative;

  & > label {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  & > ${Flex}:last-child {
    margin-bottom: -1rem;
  }
`

export default FormContent
