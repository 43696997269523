// @flow

import _ from 'lodash'
import { enhancedRiderConnect } from 'lib/enchanced-connect'
import { getAvailableRidersByCode, getSumAssured } from 'quick-quote/product-common/coverage-plan/selectors'
import {
  getAPAndEcareRider,
  getAPAndECAREBenefits,
  getRiderWithPlanBenefits,
  getRiderBenefitTables,
} from 'quick-quote/product-whole-life/benefit-illustration/selectors'
import { getAge } from 'quick-quote/insured-information/selectors'

import _APAndECARERiderBenefit from './components/ap-ecare-rider-benefit'
import _CIUDRRiderBenefit from './components/ciudr-rider-benefit'
import _CPRRiderBenefit from './components/cpr-rider-benefit'
import _DCIRiderBenefit from './components/dci-rider-benefit'
import _ESCIAndWPEIP85RiderBenefit from './components/esci-wpeip85-rider-benefit'
import _ESCIAndWPEWL85RiderBenefit from './components/esci-wpewl85-rider-benefit'
import _FHRiderBenefit from './components/fh-rider-benefit'
import _HBUDRRiderBenefit from './components/hbudr-rider-benefit'
import _HICRiderBenefit from './components/hic-rider-benefit'
import _IGROWRiderBenefit from './components/igrow-rider-benefit'
import _HSUDRRiderBenefit from './components/hsudr-rider-benefit'
import _MEAPlusRiderBenefit from './components/mea-plus-rider-benefit'
import _MEBRiderBenefit from './components/meb-rider-benefit'
import _MEHRiderBenefit from './components/meh-rider-benefit'
import _TPDRiderBenefit from './components/tpd-rider-benefit'
import _PLRRiderBenefit from './components/plr-rider-benefit'
import _WPCRiderBenefit from './components/wpc-rider-benefit'
import _WPDDRiderBenefit from './components/wpdd-rider-benefit'
import _WPDRiderBenefit from './components/wpd-rider-benefit'
import _WPRiderBenefit from './components/wp-rider-benefit'
import _MEKRiderBenefit from './components/mek-rider-benefit'
import _MEXRiderBenefit from './components/mex-rider-benefit'
import _MHPRiderBenefit from './components/mhp-rider-benefit'
import _WPTPDRiderBenefit from './components/wptpd-rider-benefit'
import _WPTPDCIRiderBenefit from './components/wptpdci-rider-benefit'
import _PBPDDRiderBenefit from './components/pbpdd-rider-benefit'
import _PBPDDCIRiderBenefit from './components/pbpddci-rider-benefit'
import _PBSDDRiderBenefit from './components/pbsdd-rider-benefit'
import _PBSDDCIRiderBenefit from './components/pbsddci-rider-benefit'

export const CIRiderBenefit = enhancedRiderConnect((state) => {
  const riderBenefitData = _.get(getRiderBenefitTables(state), 'CIUDR')
  return {
    rider: getAvailableRidersByCode(state).CIUDR,
    riderBenefitData: _.get(riderBenefitData, 'riderBenefitData'),
  }
})(_CIUDRRiderBenefit)

export const TPDRiderBenefit = enhancedRiderConnect((state) => {
  const riderBenefitData = _.get(getRiderBenefitTables(state), 'TPDUDR')
  return {
    rider: getAvailableRidersByCode(state).TPDUDR,
    riderBenefitData: _.get(riderBenefitData, 'riderBenefitData'),
  }
})(_TPDRiderBenefit)

export const HSUDRRiderBenefit = enhancedRiderConnect((state) => {
  const riderBenefitData = _.get(getRiderBenefitTables(state), 'HSUDR')
  return {
    rider: getAvailableRidersByCode(state).HSUDR,
    riderBenefitData: _.get(riderBenefitData, 'riderBenefitData'),
  }
})(_HSUDRRiderBenefit)

export const HBUDRRiderBenefit = enhancedRiderConnect((state) => {
  const riderBenefitData = _.get(getRiderBenefitTables(state), 'HBUDR')
  return {
    rider: getAvailableRidersByCode(state).HBUDR,
    riderBenefitData: _.get(riderBenefitData, 'riderBenefitData'),
  }
})(_HBUDRRiderBenefit)

export const PBPDDRiderBenefit = enhancedRiderConnect((state) => ({
  rider: getAvailableRidersByCode(state).PBPDD,
  sumAssured: getSumAssured(state),
}))(_PBPDDRiderBenefit)

export const PBPDDCIRiderBenefit = enhancedRiderConnect((state) => ({
  rider: getAvailableRidersByCode(state).PBPDDCI,
  sumAssured: getSumAssured(state),
}))(_PBPDDCIRiderBenefit)

export const PBSDDRiderBenefit = enhancedRiderConnect((state) => ({
  rider: getAvailableRidersByCode(state).PBSDD,
  sumAssured: getSumAssured(state),
}))(_PBSDDRiderBenefit)

export const PBSDDCIRiderBenefit = enhancedRiderConnect((state) => ({
  rider: getAvailableRidersByCode(state).PBSDDCI,
  sumAssured: getSumAssured(state),
}))(_PBSDDCIRiderBenefit)

export const WPDRiderBenefit = enhancedRiderConnect((state) => ({
  rider: getAvailableRidersByCode(state).WPD,
}))(_WPDRiderBenefit)

export const WPRiderBenefit = enhancedRiderConnect((state) => ({
  rider: getAvailableRidersByCode(state).WP,
}))(_WPRiderBenefit)

export const WPTPDRiderBenefit = enhancedRiderConnect((state) => ({
  rider: getAvailableRidersByCode(state).WPTPD,
  sumAssure: getSumAssured(state),
}))(_WPTPDRiderBenefit)

export const WPTPDCIRiderBenefit = enhancedRiderConnect((state) => ({
  rider: getAvailableRidersByCode(state).WPTPDCI,
  sumAssure: getSumAssured(state),
}))(_WPTPDCIRiderBenefit)

export const WPDDRiderBenefit = enhancedRiderConnect((state) => ({
  rider: getAvailableRidersByCode(state).WPDD,
}))(_WPDDRiderBenefit)

export const PLRRiderBenefit = enhancedRiderConnect((state) => ({
  rider: getAvailableRidersByCode(state).PLR,
}))(_PLRRiderBenefit)

export const PLSRiderBenefit = enhancedRiderConnect((state) => ({
  rider: getAvailableRidersByCode(state).PLS,
}))(_PLRRiderBenefit)

export const MEAPlusRiderBenefit = enhancedRiderConnect((state) => ({
  rider: getAvailableRidersByCode(state).MEAPlus,
  benefits: getRiderWithPlanBenefits('MEAPlus')(state),
}))(_MEAPlusRiderBenefit)

export const MEXRiderBenefit = enhancedRiderConnect((state) => {
  const riderBenefitData = _.get(getRiderBenefitTables(state), 'MEX')
  return {
    rider: getAvailableRidersByCode(state).MEX,
    riderBenefitData: _.get(riderBenefitData, 'riderBenefitData'),
    insureAge: getAge(state),
  }
})(_MEXRiderBenefit)

export const MHPRiderBenefit = enhancedRiderConnect((state) => {
  const riderBenefitData = _.get(getRiderBenefitTables(state), 'MHP')
  return {
    rider: getAvailableRidersByCode(state).MHP,
    riderBenefitData: _.get(riderBenefitData, 'riderBenefitData'),
    insureAge: getAge(state),
  }
})(_MHPRiderBenefit)

export const MEKRiderBenefit = enhancedRiderConnect((state) => {
  const rider = getRiderBenefitTables(state).MEK
  return {
    rider: getAvailableRidersByCode(state).MEK,
    benefits: rider ? rider.riderBenefitData.benefits : undefined,
    planHeader: rider ? rider.riderBenefitData.planheader : undefined,
    sumAssureBenefits: rider ? rider.riderBenefitData.sumassurebenefits : undefined,
    sumAssure: getSumAssured(state),
  }
})(_MEKRiderBenefit)

export const MEBRiderBenefit = enhancedRiderConnect((state) => ({
  rider: getAvailableRidersByCode(state).MEB,
  benefits: getRiderWithPlanBenefits('MEB')(state),
}))(_MEBRiderBenefit)

export const FHRiderBenefit = enhancedRiderConnect((state) => {
  // $$FlowFixMe
  const rider = getRiderBenefitTables(state).FH
  return {
    rider: getAvailableRidersByCode(state).FH,
    benefits: rider ? rider.riderBenefitData.benefits : undefined,
    notes: rider ? rider.riderBenefitData.notes : undefined,
  }
})(_FHRiderBenefit)

export const MEHRiderBenefit = enhancedRiderConnect((state) => {
  // $$FlowFixMe
  const rider = getRiderBenefitTables(state).MEH
  return {
    rider: getAvailableRidersByCode(state).MEH,
    benefits: rider === null || rider === undefined ? undefined : rider.riderBenefitData.benefits,
  }
})(_MEHRiderBenefit)

export const IGROWRiderBenefit = enhancedRiderConnect((state) => {
  // $$FlowFixMe
  const rider = getRiderBenefitTables(state).IGROW
  return {
    rider: getAvailableRidersByCode(state).APG,
    planCode: getSumAssured(state),
    benefits: rider === null || rider === undefined ? undefined : rider.riderBenefitData.benefits,
  }
})(_IGROWRiderBenefit)

export const HICRiderBenefit = enhancedRiderConnect((state) => ({
  rider: getAvailableRidersByCode(state).HIC,
  benefits: getRiderWithPlanBenefits('HIC')(state),
}))(_HICRiderBenefit)

export const APAndECARERiderBenefit = enhancedRiderConnect((state) => ({
  rider: getAPAndEcareRider(state),
  apAndEcareBenefits: getAPAndECAREBenefits(state),
}))(_APAndECARERiderBenefit)

export const DCIRiderBenefit = enhancedRiderConnect((state) => {
  const riderBenefitData = _.get(getRiderBenefitTables(state), 'DCI')
  return {
    rider: getAvailableRidersByCode(state).DCI,
    riderBenefitData: _.get(riderBenefitData, 'riderBenefitData'),
    showDisclaimer: _.get(riderBenefitData, 'showDisclaimer'),
  }
})(_DCIRiderBenefit)

export const CPRRiderBenefit = enhancedRiderConnect((state) => {
  const riderBenefitData = _.get(getRiderBenefitTables(state), 'CPR')
  return {
    rider: getAvailableRidersByCode(state).CPR,
    riderBenefitData: _.get(riderBenefitData, 'riderBenefitData'),
    showDisclaimer: _.get(riderBenefitData, 'showDisclaimer'),
  }
})(_CPRRiderBenefit)

export const WPCRiderBenefit = enhancedRiderConnect((state) => {
  const WPCWL60 = getAvailableRidersByCode(state).WPCWL60
  const WPCWL99 = getAvailableRidersByCode(state).WPCWL99
  const WPC25PL = getAvailableRidersByCode(state).WPC25PL
  return {
    rider: WPCWL60 || WPCWL99 || WPC25PL,
  }
})(_WPCRiderBenefit)

export const ESCIWPEWL85Benefit = enhancedRiderConnect((state) => {
  const riderBenefitDataESCI = _.get(getRiderBenefitTables(state), 'ESCI')

  const riderESCI = getAvailableRidersByCode(state).ESCI
  const riderWPEWL85 = getAvailableRidersByCode(state).WPEWL85
  return {
    rider: {
      ...riderESCI,
      premium: _.get(riderESCI, 'premium') + _.get(riderWPEWL85, 'premium'),
    },
    riderBenefitData: _.get(riderBenefitDataESCI, 'riderBenefitData'),
  }
})(_ESCIAndWPEWL85RiderBenefit)

export const ESCIWPEIP85Benefit = enhancedRiderConnect((state) => {
  const riderBenefitDataESCI = _.get(getRiderBenefitTables(state), 'ESCI')

  const esciRider = getAvailableRidersByCode(state).ESCI
  const wpeip85Rider = getAvailableRidersByCode(state).WPEIP85
  return {
    rider: {
      esciRider,
      wpeip85Rider,
    },
    riderBenefitData: _.get(riderBenefitDataESCI, 'riderBenefitData'),
  }
})(_ESCIAndWPEIP85RiderBenefit)
