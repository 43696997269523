//@flow
import Mustache from 'mustache'
import MESSAGES from 'core/data-model/constants/bi-messages'
import { formatNumber } from 'core/service/lib/number-format'

import type { TaxDeduction } from 'core/service/benefit-illustration'

type TaxDeductionBenefitProps = {
  taxDeduction: TaxDeduction,
}

export const taxConsentDeductionBenefitSection = ({ taxDeduction }: TaxDeductionBenefitProps) => ({
  stack: [
    { text: MESSAGES.TAX_DEDUCTION_BENEFIT_TITLE, style: 'title' },
    { text: MESSAGES.TAX_DEDUCTION_BENEFIT_SUB_TITLE, bold: true },
    {
      text: `${MESSAGES.TAX_DEDUCTION_BENEFIT_SUB_DESCRIPTION} ${MESSAGES.TAX_DEDUCTION_BENEFIT_SUB_DESCRIPTION_STRONG}`,
    },
    {
      fontSize: 12,
      table: {
        widths: [300, '*'],
        body: [
          [
            MESSAGES.MRTA_TAX_CONSENT_DEDUCTION_BENEFIT_SUB_LIST[0].description,
            {
              text: Mustache.render(MESSAGES.MRTA_TAX_CONSENT_DEDUCTION_BENEFIT_SUB_LIST[0].benefit, {
                value:
                  taxDeduction.taxDeductionLifeForYear === 0
                    ? '-'
                    : formatNumber(taxDeduction.taxDeductionLifeForYear, 2, true),
              }),
              alignment: 'right',
            },
          ],
          [
            MESSAGES.MRTA_TAX_CONSENT_DEDUCTION_BENEFIT_SUB_LIST[1].description,
            {
              text: Mustache.render(MESSAGES.MRTA_TAX_CONSENT_DEDUCTION_BENEFIT_SUB_LIST[1].benefit, {
                value:
                  taxDeduction.taxDeductionHealthForYear === 0
                    ? '-'
                    : formatNumber(taxDeduction.taxDeductionHealthForYear, 2, true),
              }),
              alignment: 'right',
            },
          ],
        ],
      },
      layout: 'noBorders',
      margin: [20, 0, 30, 0],
    },
    {
      stack: [
        MESSAGES.TAX_CONSENT_DEDUCTION_BENEFIT_SUB_ADDITIONAL_PDF,
        ' ',
        { text: `${MESSAGES.NOTE}: ` },
        {
          text: `${MESSAGES.TAX_CONSENT_DEDUCTION_BENEFIT_SUB_NOTES_PDF[0][0]} ${MESSAGES.TAX_CONSENT_DEDUCTION_BENEFIT_SUB_NOTES_PDF[0][1]}`,
          margin: [36, -13, 0, 0],
        },
        ' ',
        {
          text: `${MESSAGES.TAX_CONSENT_DEDUCTION_BENEFIT_SUB_NOTES_PDF[1][0]} ${MESSAGES.TAX_CONSENT_DEDUCTION_BENEFIT_SUB_NOTES_PDF[1][1]}`,
          margin: [36, 0, 0, 0],
        },
      ],
      style: 'disclaimer',
    },
  ],
  style: 'groupRow',
  unbreakable: true,
})
