//@flow
import _ from 'lodash'
import { formatNumber } from 'core/service/lib/number-format'
import { buildHeaderTable } from 'core/service/pdf-generation/utils/builder'
import MESSAGES from 'core/data-model/constants/bi-messages'

import type { Rider, RiderState } from 'core/data-model/rider'
import type { RiderBenefitData } from 'core/data-model/rider/benefits'

type ValueRow = {
  planCode: string,
  value: string,
}

type Benefit = {
  description: string,
  values: ValueRow[],
}

type BenefitRow = {
  description: string,
  value: string,
}

type BenefitProps = {
  rider: Rider & RiderState,
  riderBenefitData: RiderBenefitData,
}

const benefitTable = {
  headers: [MESSAGES.BENEFIT_LIST, MESSAGES.BENEFIT_BAHT],
}

export const buildRiderWithPlansBenefitTable = ({ rider, riderBenefitData }: BenefitProps) => {
  const benefits = _.get(riderBenefitData, 'benefits')
  return {
    headerRows: 1,
    widths: ['*', 'auto'],
    body: [buildHeaderTable(benefitTable.headers), ...buildContentBenefitTable(benefits, rider)],
  }
}

const buildContentBenefitTable = (benefits, rider) => {
  return benefits.map((benefit, index) =>
    buildBenefitsRow(getSelectedPlanBenefit(benefit, rider.selectedPlan.planCode), index + 1)
  )
}

const buildBenefitsRow = (benefit: BenefitRow, rowNumber: number) => {
  return [
    {
      ol: [benefit.description].map((desc) => ({ text: desc })),
      start: rowNumber,
    },
    { text: formatBenefitValue(benefit.value), alignment: 'right', bold: true },
  ]
}

export const formatBenefitValue = (value: string): string => {
  return isNaN(parseInt(value)) ? value : formatNumber(parseInt(value))
}

export const getSelectedPlanBenefit = (benefit: Benefit, selectedPlanCode: string): BenefitRow => {
  const selectedPlanBenefit = _.find(benefit.values, ['planCode', selectedPlanCode])

  return {
    description: benefit.description,
    value: selectedPlanBenefit ? selectedPlanBenefit.value : '',
  }
}
