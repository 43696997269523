//@flow
import _ from 'lodash'
import type { PolicyValue, YearEndPolicyValue } from 'core/service/benefit-illustration'
import { type DisplayProduct } from 'core/service/display-product'
import { formatNumber } from 'core/service/lib/number-format'
import MESSAGES from 'core/data-model/constants/bi-messages'
import CONSTANTS from 'core/data-model/constants/messages'
import type { PolicyValueTableProps } from 'core/service/benefit-illustration/policy-value'
import { _getMessageUnderiShieldPolicyValueTable } from 'core/service/benefit-illustration/policy-value'
import { calculatePageAmountPolicyValueTable } from 'core/service/pdf-generation/products/common/logic'

export const _breakLine = (text: string) => {
  const premiumPattern = /^(เบี้ยประกันภัย)(สัญญา.+)$/
  const deathBenefitPattern = /^(ความคุ้มครอง)(การเสียชีวิต)$/
  const surrenderCashPattern = /^(มูลค่าเวนคืน)(เงินสด\**)$/
  const savingWithCompany = /^(กรณีรับเป็น)(เงินสด)$/

  const patterns = [premiumPattern, deathBenefitPattern, surrenderCashPattern, savingWithCompany]

  const breakByFirstMatched = ([p, ...rest]) => (text.match(p) ? text.replace(p, '$1 $2') : breakByFirstMatched(rest))

  return breakByFirstMatched(patterns)
}

const rowHeightConfig = { lineHeight: 0.75, margin: [0, -3, 0, 0] }

const buildGioHeaderTable = () => {
  return [
    [
      {
        text: CONSTANTS.AGE,
        style: 'tableHeader',
        margin: [0, 20],
      },
      {
        text: MESSAGES.GIO_END_OF_POLICY,
        style: 'tableHeader',
        margin: [0, 20],
      },
      {
        text: MESSAGES.PREMIUM_PER_YEAR,
        style: 'tableHeader',
        margin: [0, 20],
      },
      {
        text: MESSAGES.GIO_CUMULATIVE_PREMIUM_PER_YEAR,
        style: 'tableHeader',
        margin: [0, 20],
      },
      {
        text: `${MESSAGES.GIO_CASHDROP_VALUE_1}
          ${MESSAGES.GIO_CASHDROP_VALUE_2}
          ${MESSAGES.GIO_CASHDROP_VALUE_3}
          ${MESSAGES.END_OF_YEAR_POLICY_WITH_BRACKET}`,
        style: 'tableHeader',
        margin: [0, 5],
      },
      {
        text: `${MESSAGES.DEATH_BENEFIT}*
          ${MESSAGES.END_OF_YEAR_POLICY_WITH_BRACKET}`,
        style: 'tableHeader',
        margin: [0, 15],
      },
      {
        text: `${MESSAGES.BASIC_PLAN_DEATH_BENEFIT}
          ${MESSAGES.END_OF_YEAR_POLICY_WITH_BRACKET}
          ${MESSAGES.GIO_CUMULATIVE_DEATH_BENEFIT_1}
          ${MESSAGES.GIO_CUMULATIVE_DEATH_BENEFIT_2}
          ${MESSAGES.GIO_CUMULATIVE_DEATH_BENEFIT_3}***`,
        style: 'tableHeader',
      },
      {
        text: `${MESSAGES.SURRENDER_CASH_VALUE}
          ${MESSAGES.GIO_POLICY_SURRENDER_VALUE_2}**
          ${MESSAGES.END_OF_YEAR_POLICY_WITH_BRACKET}`,
        style: 'tableHeader',
        margin: [0, 10],
      },
    ],
  ]
}

const buildGioPolicyValueRow = (yearEndPolicy: YearEndPolicyValue) => [
  {
    text: yearEndPolicy.age.value,
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.yearEndPolicy,
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.annualBasicPremium === 0 ? '-' : formatNumber(yearEndPolicy.annualBasicPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.cumulativeBasicPremium === 0 ? '-' : formatNumber(yearEndPolicy.cumulativeBasicPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.cashDrop === 0 ? '-' : formatNumber(parseFloat(yearEndPolicy.cashDrop), 2, true),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.deathBenefit === 0 ? '-' : formatNumber(yearEndPolicy.deathBenefit, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text:
      yearEndPolicy.cumulativeDeathBenefit === 0
        ? '-'
        : formatNumber(parseFloat(yearEndPolicy.cumulativeDeathBenefit), 2, true),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.surrenderCash === 0 ? '-' : formatNumber(yearEndPolicy.surrenderCash, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
]

const buildGioTotalPolicyValueRow = (policyValue: PolicyValue) => [
  '',
  '',
  {
    text: policyValue.subtotalAnnualBasicPremium === 0 ? '-' : formatNumber(policyValue.subtotalAnnualBasicPremium, 2),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  '',
  {
    text: policyValue.subtotalCashDrop === 0 ? '-' : formatNumber(parseFloat(policyValue.subtotalCashDrop), 2, true),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  '',
  '',
  '',
]

const disclaimer = [
  {
    stack: [`${MESSAGES.NOTE}: ${MESSAGES.GIO_VALUE_TABLE_NOTE_1}`],
    style: 'disclaimer',
  },
  {
    stack: [`${MESSAGES.GIO_VALUE_TABLE_NOTE_2}`],
    margin: [45, 0, 0, 0],
    style: 'disclaimer',
  },
  {
    stack: [`${MESSAGES.GIO_VALUE_TABLE_NOTE_3}`],
    margin: [36, 0, 0, 0],
    style: 'disclaimer',
  },
  {
    stack: [`${MESSAGES.GIO_VALUE_TABLE_NOTE_4}`],
    margin: [36, 0, 0, 0],
    style: 'disclaimer',
  },
]

export const GioShowMessageUnderPdfTable = ({ policyValue, basicPlan }: PolicyValueTableProps) => {
  const message = _getMessageUnderiShieldPolicyValueTable({
    policyValue,
    basicPlan,
  })
  if (message) {
    return {
      stack: [{ text: message, color: 'red', fontSize: 11, alignment: 'center' }],
    }
  } else return { text: '' }
}

const buildTermLifePolicyValueTable = (policyValue: PolicyValue) => {
  const policyValueTables = []
  let rowsPerPage = [54, 55, 54]
  if (rowsPerPage[0] === policyValue.yearEndPolicyValues.length) {
    rowsPerPage[0] = rowsPerPage[0] - 1
  }
  const pageAmount = calculatePageAmountPolicyValueTable(policyValue, rowsPerPage)
  _.times(pageAmount, (currentPage) => {
    policyValueTables.push({
      table: {
        headerRows: 5,
        widths: [15, 35, 50, 75, 75, 70, 80, 80],
        body: [
          ...buildGioHeaderTable(),
          ...policyValue.yearEndPolicyValues
            .slice(
              currentPage * rowsPerPage[currentPage > 0 ? currentPage - 1 : 0],
              rowsPerPage[currentPage] * (currentPage + 1)
            )
            .map((p) => buildGioPolicyValueRow(p)),
        ],
      },
      layout: 'lightBorder',
      style: 'table25PL',
    })
  })

  _.last(policyValueTables).table.body.push(buildGioTotalPolicyValueRow(policyValue))
  return policyValueTables
}

export const buildPolicyValueTable = (policyValue: PolicyValue, basicPlan: DisplayProduct) => {
  return buildTermLifePolicyValueTable(policyValue)
}

export const policyGioValueTableSection = ({ policyValue, basicPlan }: PolicyValueTableProps) => [
  {
    text: MESSAGES.POLICY_VALUE_TABLE,
    style: 'title',
    pageBreak: 'before',
    alignment: 'center',
  },
  ...buildPolicyValueTable(policyValue, basicPlan),
  GioShowMessageUnderPdfTable({ policyValue, basicPlan }),
  ...disclaimer,
]
