import { assign, has, omit, set, get, isNil, isArrayBuffer } from 'lodash/fp'
import { combineReducers } from 'redux'
import { REPLICATION_STATUS, EDA_CHECK_STATUS_FAIL } from 'e-submission/domain/data-model/constants'
import _ from 'lodash'

export const replicationStatus = (state = REPLICATION_STATUS.IDLE, action = {}) => {
  switch (action.type) {
    case 'SET_REPLICATION_STATUS':
      return action.payload
    default:
      return state
  }
}

export const isSubmitting = (state = false, action) => {
  switch (action.type) {
    case 'VALIDATE_APPLICATION':
    case 'CREATE_POLICY':
      return true
    case 'RESET_VALIDATION':
    case 'SUBMIT_APPLICATION':
    case 'CREATE_NEW_APP':
    case 'CHANGE_CURRENT_APP':
    case 'SET_APP_VALUE':
      const receiptFieldIdPattern = /receipts\[[\d]\].totalFirstPremiumPayment/
      if (receiptFieldIdPattern.test(action.fieldId)) return true
      return false
    case 'UNSET_APP_VALUE':
    case 'ADD_APP_MEMBER':
    case 'REMOVE_APP_MEMBER':
      return false
    default:
      return state
  }
}

export const isLoading = (state = false, action) => {
  switch (action.type) {
    case 'SHOW_LOADING':
      return true
    case 'HIDE_LOADING':
      return false
    default:
      return state
  }
}

export const isConsentEdaComplete = (state = false, action) => {
  switch (action.type) {
    case 'READ_CONSENT_EDA_COMPLETE':
      return true
    case 'RESET_CONSENT_EDA_COMPLETE':
      return false
    default:
      return state
  }
}

export const isEDACheckingStatus = (state = false, action) => {
  switch (action.type) {
    case 'START_EDA_CHECK_STATUS':
      return true
    case 'STOP_EDA_CHECK_STATUS':
      return false
    default:
      return state
  }
}

export const isEDACheckingStatusFailed = (state = null, action) => {
  switch (action.type) {
    case 'EDA_CHECK_STATUS_EXPIRED':
      return EDA_CHECK_STATUS_FAIL.TOKEN_EXPIRED
    case 'EDA_CHECK_STATUS_ERROR':
      return EDA_CHECK_STATUS_FAIL.ERROR
    case 'UNSET_EDA_CHECK_STATUS':
      return null
    default:
      return state
  }
}

export const isEDAViewFormOnly = (state = false, action) => {
  switch (action.type) {
    case 'SET_EDA_VIEW_FORM_ONLY':
      return true
    case 'RESET_EDA_VIEW_FORM_ONLY':
      return false
    default:
      return state
  }
}

export const loadingMessage = (state = '', action) => {
  switch (action.type) {
    case 'SET_LOADING_MESSAGE':
      return action.payload
    case 'CLEAR_LOADING_MESSAGE':
      return ''
    default:
      return state
  }
}

const URL = window.URL || window.webkitURL
export const files = (state = {}, action) => {
  switch (action.type) {
    case 'CREATE_NEW_APP':
    case 'CHANGE_CURRENT_APP':
      return {}
    case 'ADD_FILE': {
      let payload = action.payload
      if (isNil(payload)) {
        return state
      }

      const file = get('file', payload)

      if (isArrayBuffer(file)) {
        const url = URL.createObjectURL(new Blob([file]))
        // https://hectorguo.com/en/file-signature-check/
        const view = new DataView(file)
        const signature = view.getUint32(0, false).toString(16)
        let fileType
        switch (signature) {
          case '25504446':
            fileType = 'application/pdf'
            break
        }
        payload = set('file', url, payload)
        payload = set('fileType', fileType, payload)
      } else if (file instanceof Blob) {
        const url = URL.createObjectURL(file)
        payload = set('file', url, payload)
      }

      return {
        ...state,
        [action.name]: assign(state[action.name], payload),
      }
    }
    case 'REMOVE_FILE': {
      if (has(action.name, state)) {
        const file = get([action.name, 'file'], state)
        if (!isNil(file)) {
          URL.revokeObjectURL(file)
        }

        return omit([action.name], state)
      }

      return state
    }
    default:
      return state
  }
}

export const errors = (state = {}, action) => {
  switch (action.type) {
    case 'ADD_ERROR':
      return set(action.name, action.payload, state)
    case 'REMOVE_ERROR':
      return omit(action.name, state)
    default:
      return state
  }
}

export const dialog = (state = null, action) => {
  switch (action.type) {
    case 'SHOW_DIALOG':
      return action.payload
    case 'HIDE_DIALOG':
      return null
    default:
      return state
  }
}

export const submittedFlashMessage = (state = false, action) => {
  switch (action.type) {
    case 'SHOW_SUBMITTED_FLASH_MESSAGE':
      return true
    case 'HIDE_SUBMITTED_FLASH_MESSAGE':
      return false
    default:
      return state
  }
}

export const setFrontIdCardImage = (state = false, action) => {
  switch (action.type) {
    case 'SET_FRONT_ID_CARD_IMAGE': {
      const obj = {}
      _.set(obj, `${action.payload.insuredOrPayer}.frontIdCardImage`, action.payload.image)
      return {
        ...state,
        ...obj,
      }
    }
    default:
      return state
  }
}

export const setBackIdCardLaserCode = (state = false, action) => {
  switch (action.type) {
    case 'SET_BACK_ID_CARD_LASER_CODE': {
      const laserCode = _.get(action, 'payload.laserCode', '-')
      let maskLaserCode
      if (laserCode && laserCode !== '-') {
        maskLaserCode = `XXX-XXXXX${laserCode.slice(9, 11)}-${laserCode.slice(12, 14)}`
      } else {
        maskLaserCode = '-'
      }
      const obj = {}
      _.set(obj, `${action.payload.insuredOrPayer}.laserCode`, maskLaserCode)
      return {
        ...state,
        ...obj,
      }
    }
    default:
      return state
  }
}

export const setDopaRequestFailedModal = (state = false, action) => {
  switch (action.type) {
    case 'SET_DOPA_REQUEST_FAILED_MODAL': {
      const obj = {}
      _.set(
        obj,
        `${action.payload.insuredOrPayer}.isOpenDopaRequestFailedModal`,
        action.payload.isOpenDopaRequestFailedModal
      )
      return {
        ...state,
        ...obj,
      }
    }
    default:
      return state
  }
}

export const isEcbrSystemizationModal = (state = false, action) => {
  switch (action.type) {
    case 'SHOW_ECBR_SYSTEMIZATION_FAILED_MODAL':
      return true
    case 'HIDE_ECBR_SYSTEMIZATION_FAILED_MODAL':
      return false
    default:
      return state
  }
}

export default combineReducers({
  replicationStatus,
  isLoading,
  isEDACheckingStatus,
  isEDACheckingStatusFailed,
  isEDAViewFormOnly,
  isConsentEdaComplete,
  loadingMessage,
  isSubmitting,
  files,
  errors,
  dialog,
  submittedFlashMessage,
  setFrontIdCardImage,
  setDopaRequestFailedModal,
  setBackIdCardLaserCode,
  isEcbrSystemizationModal,
})
