//@flow
import Mustache from 'mustache'
import MESSAGES from 'core/data-model/constants/bi-messages'
import type { TaxDeduction } from 'core/service/benefit-illustration'
import { formatNumber } from 'core/service/lib/number-format'

type TaxDeductionBenefitProps = {
  taxDeduction: TaxDeduction,
}

export const taxDeductionBenefit = ({ taxDeduction }: TaxDeductionBenefitProps) => ({
  stack: [
    { text: MESSAGES.TAX_DEDUCTION_BENEFIT_TITLE, style: 'title' },
    { text: MESSAGES.TAX_DEDUCTION_BENEFIT_SUB_TITLE, bold: true },
    {
      text: `${MESSAGES.TAX_DEDUCTION_BENEFIT_SUB_DESCRIPTION} ${MESSAGES.TAX_DEDUCTION_BENEFIT_SUB_DESCRIPTION_STRONG}`,
    },
    {
      fontSize: 12,
      table: {
        widths: [300, '*'],
        body: [
          [
            MESSAGES.TAX_DEDUCTION_BENEFIT_SUB_LIST[0].description,
            {
              text: Mustache.render(MESSAGES.TAX_DEDUCTION_BENEFIT_SUB_LIST[0].benefit, {
                value: formatNumber(taxDeduction.taxDeductionTotalForYear, 2, true),
              }),
              alignment: 'right',
            },
          ],
        ],
      },
      layout: 'noBorders',
      margin: [20, 0, 30, 0],
    },
    {
      stack: [
        MESSAGES.TAX_DEDUCTION_BENEFIT_SUB_ADDITIONAL,
        { text: `${MESSAGES.NOTE}: ` },
        {
          text: `${MESSAGES.TAX_DEDUCTION_BENEFIT_SUB_NOTES[0][0]}${MESSAGES.TAX_DEDUCTION_BENEFIT_SUB_NOTES[0][1]}`,
          margin: [36, -13, 0, 0],
        },
        ' ',
        { text: `${MESSAGES.TAX_DEDUCTION_BENEFIT_SUB_NOTES[1][0]}` },
        ' ',
      ],
      style: 'disclaimer',
    },
  ],
  style: 'groupRow',
})
