//@flow
import { FundItem } from 'common-components/fund'
import type { ToggleFundPayload } from 'quick-quote/product-investment/fund-allocation/rpp/actions'
import { Emphasized } from 'common-components/text'
import React from 'react'

const sortFunds = (a, b) => {
  if (b.riskLevel !== a.riskLevel) {
    return b.riskLevel - a.riskLevel
  } else {
    return a.code < b.code ? -1 : a.code > b.code ? 1 : 0
  }
}

export type ViewFunds = {| ...FundState, fundAllocation: number, selected?: boolean, errors?: string[] |}
export type FundCategory = {
  categoryName: string,
  funds: ViewFunds[],
}
type FundListProps = {
  allFunds: FundCategory[],
  toggleFund: (fundCode: ToggleFundPayload) => void,
  updateFundPercent: (code: string, fundAllocation: number) => void,
}
export const FundList = ({ allFunds, toggleFund, updateFundPercent }: FundListProps) => (
  <div className="fund-list">
    <Emphasized>กรุณาเลือกกองทุนโดยประเมินจากความเสี่ยงของท่าน</Emphasized>
    {allFunds.map(({ categoryName, funds }, index) => (
      <div key={`fund-${index}`} className="fund-group">
        <div className="fund-title">{categoryName}</div>
        {funds.sort(sortFunds).map((item) => {
          return (
            <FundItem
              key={`fund-${item.code}`}
              onToggle={toggleFund}
              onChangeAllocationPercent={updateFundPercent}
              {...item}
            />
          )
        })}
      </div>
    ))}
  </div>
)
