// @flow

import _ from 'lodash'
import type { DisplayProduct } from 'core/service/display-product'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { BasicPremium$, SumAssured$, TotalPremium$ } from 'core/data-model/basic-plan'

import CONSTANTS from 'core/data-model/constants/values'

import { isNil, isNotNil } from 'core/service/lib/type-check'
import { createSelector } from 'reselect'
import { getSelectedDisplayProduct } from 'quick-quote/product-selection/selectors'
import { canProceedToCoveragePlan } from 'quick-quote/selectors/next-tab-proceeding'

import {
  getSumAssured$,
  getSelectedRiders,
  getBasicPremium$,
  getTotalPremium$,
  getBasicPlanErrorMessage,
  getPensionType,
} from 'quick-quote/product-common/coverage-plan/selectors'

export const canProceedRetirementBenefitIllustration = createSelector(
  getSumAssured$,
  getBasicPremium$,
  getTotalPremium$,
  getBasicPlanErrorMessage,
  getSelectedRiders,
  canProceedToCoveragePlan,
  getSelectedDisplayProduct,
  getPensionType,
  (
    sumAssured$: SumAssured$,
    basicPremium: BasicPremium$,
    totalPremium$: TotalPremium$,
    basicPlanErrorMessage: string,
    selectedRiders: (Rider & RiderState)[],
    canProceedToCoveragePlan: boolean,
    selectedDisplayProduct: DisplayProduct,
    pensionType: string
  ): boolean => {
    const selectedRiderInvalidMessages = selectedRiders.filter((rider) =>
      isNotNil(rider.errors.find((error) => error.type === CONSTANTS.VALIDATION))
    )

    const canProceed =
      canProceedToCoveragePlan &&
      sumAssured$.isOk &&
      basicPremium.isOk &&
      totalPremium$.isOk &&
      !_.isEmpty(pensionType) &&
      _.isEmpty(selectedRiderInvalidMessages) &&
      _.isEmpty(basicPlanErrorMessage)
    if (selectedDisplayProduct.type === CONSTANTS.CAMPAIGN_PRODUCT_TYPE) {
      const selectedRiderCodes = selectedRiders.map((rider) => rider.code)
      return (
        canProceed &&
        selectedDisplayProduct.requiredRiders != null &&
        selectedDisplayProduct.requiredRiders.every((riderCode) => {
          return selectedRiderCodes.indexOf(riderCode) > -1
        })
      )
    }

    return canProceed
  }
)

export const getValidSelectedRiders = createSelector(getSelectedRiders, (selectedRiders) => {
  return selectedRiders.filter((rider) =>
    isNil(rider.errors.find((error) => error.type !== CONSTANTS.ADDITIONAL_INFORMATION))
  )
})
