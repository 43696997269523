//@flow
import _ from 'lodash'
import { d } from 'core/service/lib/decimal'
import { round } from 'core/service/lib/number-format'
import VALUES from 'core/data-model/constants/values'
import type { PresentValue } from 'core/data-model/basic-plan'

export const getPresentRate = (
  productCode: string,
  pensionType: string,
  presentValues: PresentValue[]
): { [string | number]: number } => {
  const presentValue = presentValues.find((i) => i.productCode === productCode)
  return _.get(presentValue, pensionType, [])
}

type calculatePresentValueProps = {
  sumAssured: number,
  presentRate: { [string | number]: number },
  year: number,
  month: number,
  pensionType: string,
}

export const calculatePresentValue = ({
  sumAssured,
  presentRate,
  year,
  month,
  pensionType,
}: calculatePresentValueProps): number => {
  return d(sumAssured)
    .times(_.get(presentRate, `${year}${pensionType === 'monthly' ? `-${month}` : ''}`, 0))
    .div(1000)
    .toNumber()
}

const findPensionPercentForCalculate = (age = 0, data) => {
  const result = data.find(({ entryAge }) => {
    return age >= entryAge.min && age <= entryAge.max
  })
  return result ? result.percentPension : 0
}

export const calculatePensionAmount = (
  basicSumAssured: number,
  pensionType: string,
  productCode: string,
  age: number = 0
): number => {
  let annually, monthly
  switch (productCode) {
    case 'R05':
      annually = VALUES.PENSION_PERCENT_IRETIRE_R05.annually
      monthly = VALUES.PENSION_PERCENT_IRETIRE_R05.monthly
      break
    case 'LR05':
      annually = VALUES.PENSION_PERCENT_LR05.annually
      monthly = VALUES.PENSION_PERCENT_LR05.monthly
      break
    case 'R08F':
      annually = VALUES.PENSION_PERCENT_R08F.annually
      monthly = VALUES.PENSION_PERCENT_R08F.monthly
      break
    case 'R60F':
      annually = findPensionPercentForCalculate(age, VALUES.PENSION_PERCENT_R60F.annually)
      monthly = findPensionPercentForCalculate(age, VALUES.PENSION_PERCENT_R60F.monthly)
      break
    default:
      annually = VALUES.PENSION_PERCENT_IRETIRE_R01.annually
      monthly = VALUES.PENSION_PERCENT_IRETIRE_R01.monthly
      break
  }

  return pensionType === 'annually'
    ? round(
        d(basicSumAssured)
          .times(annually)
          .div(100),
        2
      )
    : round(
        d(basicSumAssured)
          .times(monthly)
          .div(100),
        2
      )
}

export const calculatePensionAmounts = (basicSumAssured: number, pensionType: string, productCode: string): number => {
  let pensionAmounts = null
  const calPensionAmount = (basicSumAssured, pensionPercent) => {
    return round(
      d(basicSumAssured)
        .times(pensionPercent)
        .div(100),
      2
    )
  }

  if (productCode === VALUES.BUMNAN_READY_CODE.BUMNAN_READY_60) {
    pensionAmounts = {
      R60F_AGE_60_TO_70:
        pensionType === 'monthly'
          ? calPensionAmount(basicSumAssured, VALUES.PENSION_PERCENT_R60F.monthly[0].percentPension)
          : calPensionAmount(basicSumAssured, VALUES.PENSION_PERCENT_R60F.annually[0].percentPension),
      R60F_AGE_71_TO_80:
        pensionType === 'monthly'
          ? calPensionAmount(basicSumAssured, VALUES.PENSION_PERCENT_R60F.monthly[1].percentPension)
          : calPensionAmount(basicSumAssured, VALUES.PENSION_PERCENT_R60F.annually[1].percentPension),
      R60F_AGE_81_TO_88:
        pensionType === 'monthly'
          ? calPensionAmount(basicSumAssured, VALUES.PENSION_PERCENT_R60F.monthly[2].percentPension)
          : calPensionAmount(basicSumAssured, VALUES.PENSION_PERCENT_R60F.annually[2].percentPension),
    }
  }

  return pensionAmounts
}

export const calculateMinimumDeathCoverage = (basicSumAssured: number, productCode: string): number => {
  let deathCoverage
  const calMinimumDeathCoverage = (basicSumAssured, minPercentDeathCoverage) => {
    return d(basicSumAssured)
      .times(minPercentDeathCoverage)
      .toNumber()
  }

  switch (productCode) {
    case 'LR05':
      deathCoverage = calMinimumDeathCoverage(basicSumAssured, VALUES.PERCENT_MINIMUM_DEATH_COVERAGE_LR05.surrender)
      break
    case 'R08F':
    case 'R60F':
      deathCoverage = {
        beforeFiveYearInsurance: calMinimumDeathCoverage(
          basicSumAssured,
          VALUES.PERCENT_MINIMUM_DEATH_COVERAGE_BUMNAN_READY.standard.beforeFiveYearInsurance
        ),
        afterFiveYearInsurance: calMinimumDeathCoverage(
          basicSumAssured,
          VALUES.PERCENT_MINIMUM_DEATH_COVERAGE_BUMNAN_READY.standard.afterFiveYearInsurance
        ),
      }
      break
    default:
      deathCoverage = calMinimumDeathCoverage(basicSumAssured, VALUES.PERCENT_MINIMUM_DEATH_COVERAGE.surrender)
      break
  }
  return deathCoverage
}

export const getDeathCoveragePercentage = (productCode: string, coverageType: string): number => {
  const { surrender, standard } =
    productCode === 'LR05' ? VALUES.PERCENT_MINIMUM_DEATH_COVERAGE_LR05 : VALUES.PERCENT_MINIMUM_DEATH_COVERAGE
  return coverageType === 'surrender'
    ? d(surrender)
        .times(100)
        .toNumber()
    : d(standard)
        .times(100)
        .toNumber()
}

export const calculateTotalPensionAmount = (
  basicSumAssured: number,
  pensionType: string,
  productCode: string,
  age: number = 0
): number => {
  const calTotalPensionAmount = (pensionAmount, pensionPeriod) => {
    return d(pensionAmount)
      .times(pensionPeriod)
      .toNumber()
  }

  if (productCode === VALUES.BUMNAN_READY_CODE.BUMNAN_READY_60) {
    const pensionAmount = calculatePensionAmounts(basicSumAssured, pensionType, productCode)
    const pensionPeriod = VALUES.TOTAL_BUMNAN_READY_PENSION_PERIOD.R60F
    const totalAge60to70 = calTotalPensionAmount(
      pensionAmount.R60F_AGE_60_TO_70,
      _.get(pensionPeriod.AGE_60_TO_70, pensionType, 0)
    )
    const totalAge71to80 = calTotalPensionAmount(
      pensionAmount.R60F_AGE_71_TO_80,
      _.get(pensionPeriod.AGE_71_TO_80, pensionType, 0)
    )
    const totalAge80to88 = calTotalPensionAmount(
      pensionAmount.R60F_AGE_81_TO_88,
      _.get(pensionPeriod.AGE_81_TO_88, pensionType, 0)
    )
    return totalAge60to70 + totalAge71to80 + totalAge80to88
  } else {
    const pensionAmount = calculatePensionAmount(basicSumAssured, pensionType, productCode, age)
    const totalPensionPeriod = getTotalPensionPeriod(pensionType, productCode)
    return calTotalPensionAmount(pensionAmount, totalPensionPeriod)
  }
}

export const isAnnuallyPensionType = (pensionType: string) => {
  return pensionType === VALUES.PENSION_ANNUALLY
}

export const getPensionTypeString = (pensionType: string) => _.get(VALUES.PENSION_TYPE_IRETIRE, pensionType)

export const getPensionPercent = (pensionType: string, productCode: string) => {
  let pensionObj
  switch (productCode) {
    case 'R05':
      pensionObj = VALUES.PENSION_PERCENT_IRETIRE_R05
      break
    case 'LR05':
      pensionObj = VALUES.PENSION_PERCENT_LR05
      break
    case 'R08F':
      pensionObj = VALUES.PENSION_PERCENT_R08F
      break
    default:
      pensionObj = VALUES.PENSION_PERCENT_IRETIRE_R01
      break
  }

  return _.get(pensionObj, pensionType)
}

export const getPensionPercents = (pensionType: string, productCode: string) => {
  let pensionObjs = null
  if (productCode === VALUES.BUMNAN_READY_CODE.BUMNAN_READY_60) {
    pensionObjs = {
      R60F_AGE_60_TO_70:
        pensionType === 'monthly'
          ? VALUES.PENSION_PERCENT_R60F.monthly[0].percentPension
          : VALUES.PENSION_PERCENT_R60F.annually[0].percentPension,
      R60F_AGE_71_TO_80:
        pensionType === 'monthly'
          ? VALUES.PENSION_PERCENT_R60F.monthly[1].percentPension
          : VALUES.PENSION_PERCENT_R60F.annually[1].percentPension,
      R60F_AGE_81_TO_88:
        pensionType === 'monthly'
          ? VALUES.PENSION_PERCENT_R60F.monthly[2].percentPension
          : VALUES.PENSION_PERCENT_R60F.annually[2].percentPension,
    }
  }
  return pensionObjs
}

export const getTotalPensionPeriod = (pensionType: string, productCode: string = '') => {
  if (VALUES.BUMNAN_READY_CODE_GROUP.includes(productCode)) {
    return _.get(VALUES.TOTAL_BUMNAN_READY_PENSION_PERIOD, pensionType, 0)
  } else {
    return _.get(VALUES.TOTAL_PENSION_PERIOD, pensionType, 0)
  }
}

export const getBumnanReadyTotalPensionPeriod = (pensionType: string) =>
  _.get(VALUES.TOTAL_BUMNAN_READY_PENSION_PERIOD, pensionType)

export const getPensionPeriod = (pensionType: string) => _.get(VALUES.PENSION_PERIOD, pensionType)

export const getPensionTotal = (presentValue: number, pensionBalance: number) => {
  return presentValue !== 0 ? Math.max(pensionBalance, presentValue) : pensionBalance > 0 ? pensionBalance : 0
}

export const getPensionTotalBumnanReady = (
  presentValue: number,
  pensionBalance: number,
  insuredAge: number,
  cumulativeBasicPremium: number,
  cumulativePersionAmount: number
) => {
  if (insuredAge >= 60 && insuredAge <= 74)
    return presentValue !== 0 ? Math.max(pensionBalance, presentValue) : pensionBalance > 0 ? pensionBalance : 0
  else {
    if (cumulativeBasicPremium < cumulativePersionAmount) return 0
    else return cumulativeBasicPremium - cumulativePersionAmount
  }
}
