// @flow

import type { Rider, RiderState } from 'core/data-model/rider'
import type { Benefit } from 'core/data-model/rider/benefits'
import { isObject, isNotNil } from 'core/service/lib/type-check'

import RiderBenefitSummary from './rider-benefit-summary'
import RiderWithBenefitModal from './rider-benefit-modal'
import MESSAGES from 'core/data-model/constants/bi-messages'
import { AgentProtectionDisclaimer } from './agent-protection-disclaimer'
import { getMEHExceptionNotes32 } from 'core/service/rider/benefits/exception-notes'
import { renderListItems } from 'quick-quote/util/html-render-utils'

type MEHRiderBenefitProps = {
  rider: Rider & RiderState,
  benefits: Benefit[],
}

type MEHRiderBenefitsRowProps = {
  description: string,
  value: string,
}

const renderMEHRiderBenefitsRow = (rowNumber: number, mehRiderBenefitsRowProps: MEHRiderBenefitsRowProps) => {
  const { description, value } = mehRiderBenefitsRowProps

  const benefitDescription = description.split('\n').map((text) => <div key={`${text}-${rowNumber}`}>{text}</div>)

  const benefitValue = value.split('\n').map((text) => <div key={`v_${text}-${rowNumber}`}>{text}</div>)

  if (value === '') {
    return (
      <tr key={`meh-rider-benefit-row-${rowNumber}`} id={`meh-rider-benefit-row-${rowNumber}`}>
        <td colSpan={2}>{benefitDescription}</td>
      </tr>
    )
  }

  return (
    <tr key={`meh-rider-benefit-row-${rowNumber}`} id={`meh-rider-benefit-row-${rowNumber}`}>
      <td>{benefitDescription}</td>
      <td>{benefitValue}</td>
    </tr>
  )
}

const renderMEHRiderBenefitsTable = (rider: Rider & RiderState, benefits: Benefit[]) => {
  return (
    <div className="details-table">
      <table id="meh-benefit-table">
        <thead>
          <tr>
            <td>{MESSAGES.BENEFIT_LIST}</td>
            <td>
              {MESSAGES.BENEFIT_TITLE} ({rider.selectedPlan.plan})
            </td>
          </tr>
        </thead>
        <tbody>
          {benefits.map((benefit, index) => {
            return renderMEHRiderBenefitsRow(index, {
              description: isObject(benefit) && isNotNil(benefit.description) ? benefit.description : '',
              value: isObject(benefit) && isNotNil(benefit.value) ? benefit.value : '',
            })
          })}
        </tbody>
      </table>
      <div className="disclaimer">
        <div>{MESSAGES.BENEFIT_MEH_DISCLAIMER_LINE_01}</div>
        <div>{MESSAGES.BENEFIT_MEH_DISCLAIMER_LINE_02}</div>
        <div>{MESSAGES.BENEFIT_MEH_DISCLAIMER_LINE_03}</div>
        <div>{MESSAGES.BENEFIT_MEH_DISCLAIMER_LINE_04}</div>
      </div>
    </div>
  )
}

const renderExceptionNotes32 = (rider) => {
  const exceptionNotes = getMEHExceptionNotes32()
  const exceptionTitle = exceptionNotes.title
  const [
    normalCondition,
    firstException,
    secondException,
    extendExceptionTitle,
    extendExceptionDesc,
  ] = exceptionNotes.exceptions

  return (
    <div id="meh-exception-note32">
      <p></p>
      <p>
        <h3>
          {exceptionTitle} {rider.description}
        </h3>
      </p>
      <p>
        <h3>{normalCondition} </h3>
      </p>
      <ol id="meh-exception-list">
        <li>{firstException}</li>
        <li>
          {secondException[0]}
          <div className="meh-diseases">
            <div>
              <ul>{renderListItems(secondException[1], 0, 4)}</ul>
            </div>
            <div>
              <ul>{renderListItems(secondException[1], 4, 8)}</ul>
            </div>
          </div>
        </li>
      </ol>
      <p>
        <h3>{extendExceptionTitle}</h3>
      </p>
      <div>{extendExceptionDesc[0]}</div>
      <ol>{renderListItems(extendExceptionDesc[1], 0, 5)}</ol>
      <div className="disclaimer-box">{exceptionNotes.condition}</div>
    </div>
  )
}

const MEHRiderBenefit = ({ rider, benefits }: MEHRiderBenefitProps) => {
  const title = `${rider.description} ${rider.name}`

  const benefitTable = benefits ? renderMEHRiderBenefitsTable(rider, benefits) : null
  return (
    <RiderWithBenefitModal
      id="meh-rider-benefit"
      className="rider meh-rider"
      title={title}
      modalChildren={benefitTable}
    >
      <RiderBenefitSummary rider={rider} />
      {benefitTable}
      {renderExceptionNotes32(rider)}
      <AgentProtectionDisclaimer rider={rider} />
    </RiderWithBenefitModal>
  )
}

export default MEHRiderBenefit
