// @flow
import type Decimal from 'decimal.js'

import { round, formatNumber } from 'core/service/lib/number-format'

const FONT_SIZE = 6.5 // Regular size: 8
const COL_SCALED_WITH_FONT_SIZE = FONT_SIZE * 2
const COL_XS = 0.8 * COL_SCALED_WITH_FONT_SIZE // Regular size: 10
const COL_S = COL_SCALED_WITH_FONT_SIZE // Regular size: 15
const COL_M = 1.5 * COL_SCALED_WITH_FONT_SIZE // Regular size: 25
const COL_L = 2.95 * COL_SCALED_WITH_FONT_SIZE // Regular size: 40
const COL_XL = 4 * COL_SCALED_WITH_FONT_SIZE
const COL_XXL = 5.2 * COL_SCALED_WITH_FONT_SIZE

const RPUDR_DECIMAL_PLACES = 2
const HEADER_ROW_COUNT = 4

export const TABLE_STYLES = {
  HEADER_ROW_COUNT,
  FONT_SIZE,
  COL_XS,
  COL_S,
  COL_M,
  COL_L,
  COL_XL,
  COL_XXL,
}
export const HEADER_MARGIN = {
  S: 3,
  M: 7,
  L: 10,
  XL: 15,
  XXL: 21,
}

export const TABLE_LAYOUT = {
  marginTop: 5,
  alignment: 'right',
  fontSize: TABLE_STYLES.FONT_SIZE,
  lineHeight: 0.8,
  layout: {
    paddingTop: () => -0.85,
    paddingLeft: () => 1.2,
    paddingRight: () => 1.2,
    hLineWidth: () => 0.65,
    vLineWidth: () => 0.65,
  },
}

export const getHeaderMargin = (text, marginSize) => ({
  text,
  props: {
    margin: [0, marginSize ? marginSize : 0, 0, 0],
  },
})

// TODO: Reduce duplication with rounding in common cash-flow
export const roundAndFormat = (decimal: Decimal) => {
  const roundedNum = round(decimal.toNumber(), RPUDR_DECIMAL_PLACES)
  const formattedNumber = formatNumber(roundedNum, RPUDR_DECIMAL_PLACES)

  return roundedNum <= 0 ? '-' : formattedNumber
}

export const roundAndFormatAccountValue = (decimal: Decimal) => {
  const roundedNum = round(decimal.toNumber(), RPUDR_DECIMAL_PLACES)
  const formattedNumber = formatNumber(roundedNum, RPUDR_DECIMAL_PLACES)

  return roundedNum <= 0 ? '0' : formattedNumber
}

export const formatReturnValue = (decimal: Decimal) => {
  const formattedNumber = formatNumber(decimal.toNumber(), RPUDR_DECIMAL_PLACES)

  return decimal.toNumber() === 0 ? '-' : formattedNumber
}
