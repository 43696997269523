// @flow

import _ from 'lodash'
import type { RiderPremiumRateQueryParams } from 'core/data-model/rider'

const createQuery = (requiredKeys: string[], queryDependencies: RiderPremiumRateQueryParams): string[] => {
  const age = queryDependencies.age.unit !== 'year' ? '0' : queryDependencies.age.value.toString()
  const gender = queryDependencies.gender
  const occupationFactor = Number(queryDependencies.occupationFactor).toFixed(1)
  const selectedRiderPlanCode = queryDependencies.selectedRiderPlanCode

  const queryParams = [
    ['riderPlanCode', selectedRiderPlanCode],
    ['occupationFactor', occupationFactor],
    ['gender', gender],
    ['age', age],
  ].filter(([key]) => requiredKeys.includes(key.toString()))
  return _.flatten(queryParams)
}

export const query = (path: string[]) => (
  riderTaxRatesLookupTable: Object,
  queryDependencies: RiderPremiumRateQueryParams
) => _.get(riderTaxRatesLookupTable, createQuery(path, queryDependencies), 0)

export const queryTaxRateForAP = query(['gender', 'age'])

export const queryTaxRateForMEAPlus = query(['gender'])

export const queryTaxRateForPLS = query(['riderPlanCode'])

export const queryTaxRateForCPR = queryTaxRateForMEAPlus

export const queryTaxRateForMEH = queryTaxRateForMEAPlus

export const queryTaxRateForECARE = queryTaxRateForAP

export const queryTaxRateForDCI = queryTaxRateForAP

export const queryTaxRateForESCI = queryTaxRateForMEAPlus

export const queryTaxRateForMEX = queryTaxRateForMEAPlus

export const queryTaxRateForMHP = queryTaxRateForMEAPlus

export const queryTaxRateForMRTAAP = queryTaxRateForMEAPlus

export const queryTaxRateForPA = query(['riderPlanCode', 'occupationFactor', 'gender', 'age'])
