import _ from 'lodash'
import { reject, flow, mapValues, flatMap, map, uniqBy, isPlainObject, isEmpty, endsWith, get } from 'lodash/fp'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, branch, lifecycle, withProps, withHandlers, renderNothing } from 'recompose'
import Dialog from 'e-submission/components/Dialog'
import { ModalHeader, ModalBody } from 'reactstrap'

import { getAuraInvalidField } from 'e-submission/apps/selectors'
import { validateApplication } from 'e-submission/apps/actions'

import { getIcon } from 'e-submission/components/Icon'
import Content from 'e-submission/components/Layout/Content'
import EditCard from 'e-submission/components/Card/EditCard'
import { getToggles } from 'quick-quote/feature-toggles'

const Status = styled.div`
  text-align: center;
  padding: 30px 0px;
  div:last-child {
    padding-top: 30px;
  }
`

export const insuredValidateField = function(data) {
  if (!data) return []
  const insuredInvalidField = []
  const fieldInsuredValidated = [
    { key: 'title.text', label: 'ระบุคำนำหน้า' },
    { key: 'firstName', label: 'ชื่อของผู้เอาประกันภัย' },
    { key: 'lastName', label: 'นามสกุลของผู้เอาประกันภัย' },
  ]

  for (let idx in fieldInsuredValidated) {
    const key = fieldInsuredValidated[idx].key
    const label = fieldInsuredValidated[idx].label
    if (!get(key)(data)) insuredInvalidField.push(label)
  }
  const citizenIdChecker = { key: 'idNo.citizenId', label: 'เลขประจำตัวประชาชน' }
  const foreignerChecker = { key: 'idNo.passport', label: 'หมายเลขหนังสือเดินทาง' }
  const type = get('idType.value')(data)

  if (type === 'PASSPORT' && !get(foreignerChecker.key)(data)) {
    // insuredInvalidField.push(foreignerChecker.label)
  }

  if ((type === 'CITIZEN_ID' || type === 'GOV_ID') && !get(citizenIdChecker.key)(data)) {
    insuredInvalidField.push(citizenIdChecker.label)
  }

  return insuredInvalidField
}

const createInvalidFieldForEkyc = function(state) {
  const currentAppId = get('apps.currentAppId')(state)
  const applicationData = get(`apps.byId.${currentAppId}`)(state)
  const insured = get('insured')(applicationData)
  const payer = get('payer')(applicationData)

  const insuredInvalidField = insuredValidateField(insured)
  const payerInvalidField = insuredValidateField(payer)

  const invalidEkyc = {}

  if (insuredInvalidField.length) {
    invalidEkyc.insured = {
      fields: insuredInvalidField,
      label: 'ข้อมูลผู้เอาประกันภัย',
    }
  }
  if (payerInvalidField.length) {
    invalidEkyc.owner = {
      fields: payerInvalidField,
      label: 'ข้อมูลผู้ชำระเบี้ย',
    }
  }
  if (insuredInvalidField.length === 0 && payerInvalidField.length === 0) return null

  return invalidEkyc
}

const Component = styled(({ className, invalid, history, toggle, state }) => {
  const invalidForEkycTab = createInvalidFieldForEkyc(state)

  return (
    <Dialog className={className} isOpen={!_.isEmpty(invalidForEkycTab)}>
      <ModalHeader toggle={toggle}>
        <div>กรอกข้อมูลไม่ครบ</div>
      </ModalHeader>
      <ModalBody>
        <Status>
          {getIcon('formInvalid')}
          <div>
            ไม่สามารถสร้างเอกสารได้
            <br />
            <b>กรุณากลับไปแก้ไขข้อมูลด้านล่างให้สมบูรณ์</b>
          </div>
        </Status>
        <Content>
          {_.map(invalidForEkycTab, ({ fields, label }, pageId) => (
            <EditCard key={pageId} title={label} onClick={() => history.push(pageId)}>
              <ul>
                {_.map(fields, (label, index) => (
                  <li key={index}>
                    {getToggles().ENABLE_SHARED_AGENT_SALE_VALIDATION && pageId === 'agent' && label === 'นายหน้า'
                      ? 'กรุณาตรวจสอบสิทธิการขายของตัวแทนอีกท่าน'
                      : label}
                  </li>
                ))}
              </ul>
            </EditCard>
          ))}
        </Content>
      </ModalBody>
    </Dialog>
  )
})`
  .modal-body {
    padding: 0px 0px 32px 0px;
    background: #f5f5f5;
  }
  ul {
    padding-left: 15px;
    margin-bottom: 10px;
    li {
      padding-bottom: 10px;
    }
  }
`

export default compose(
  withRouter,
  connect(
    (state) => ({ invalid: getAuraInvalidField(state), state }),
    (dispatch) => ({
      validateApplication: () => dispatch(validateApplication()),
    })
  ),
  branch(({ invalid }) => _.isEmpty(invalid), renderNothing),
  lifecycle({
    componentDidMount() {
      this.props.validateApplication()
    },
  }),
  withProps(({ history, invalid }) => ({
    invalid: mapValues(
      ({ sections, label }) => ({
        label,
        fields: flow(
          map(({ id, fields, label, heading }) => {
            if (endsWith(']', id)) {
              return heading
            }

            if (!isEmpty(label)) {
              return label
            }

            return { id, fields }
          }),
          reject(isEmpty),
          uniqBy((value) => (isPlainObject(value) ? value.id : value)),
          flatMap((value) => (isPlainObject(value) ? map('label', value.fields) : value))
        )(sections),
      }),
      invalid
    ),
  })),
  withHandlers({
    toggle: ({ history, invalid }) => () => history.push(_.keys(invalid)[0]),
  })
)(Component)
