// @flow

import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import { setNotificationMessage } from 'quick-quote/notification/actions'
import { selectFSE, updateOffice } from 'identity/actions'
import {
  getSelectedOffice,
  getSelectedFSE,
  getIdentityUser,
  isLoggedIn,
  getUserDistributorGroup,
} from 'identity/selectors'
import type { FSE, Office, AdvisorApiUserInfo, User } from 'core/data-model/identity'
import { FSEBranchModal } from 'identity/fse-branch/components'
import { withModalToggle } from 'lib/with-hoc'
import type { AppState } from 'quick-quote/reducers'
import { isBlockedPdfGeneration, isQuickQuotePath } from 'quick-quote/selectors'
import type { DistributorGroupType } from 'core/data-model/distributor'
import type { BIType } from 'core/service/pdf-generation'
import { getToggles } from 'quick-quote/feature-toggles'
import { getSelectedDisplayProduct } from 'quick-quote/product-selection/selectors'

type ShareButtonProps = {
  submitAction: (*) => void,
  toggleModal: (?Object) => void,
  isModalOpen: boolean,
  isLoggedIn: boolean,
  disabled?: boolean,
  isBlockedPdfGeneration: boolean,
  userDistributorGroup: DistributorGroupType,
  selectFSE: (AdvisorApiUserInfo) => void,
  updateOffice: (Office) => void,
  selectedFSE?: FSE,
  selectedOffice?: Office,
  user: User,
  biType: BIType,
  buttonLabel: string,
  setNotificationMessage: ({ message: string }) => void,
}
export const ShareButton = ({
  submitAction,
  toggleModal,
  isModalOpen,
  isLoggedIn,
  disabled,
  isBlockedPdfGeneration,
  userDistributorGroup,
  selectFSE,
  updateOffice,
  selectedFSE,
  selectedOffice,
  user,
  biType,
  buttonLabel,
  setNotificationMessage,
  selectedDisplayProduct,
}: ShareButtonProps) => {
  const onClick = () =>
    isLoggedIn && userDistributorGroup === 'BANC' && biType
      ? toggleModal()
      : isQuickQuotePath() && getToggles().ENABLE_REVISE_FNA
      ? submitAction(selectedDisplayProduct.category, biType)
      : submitAction(biType)
  return (
    <span>
      <Button
        id="share-quote-button"
        type="button"
        color={disabled || isBlockedPdfGeneration ? 'disabled' : 'outline-primary'}
        disabled={disabled || isBlockedPdfGeneration}
        onClick={onClick}
      >
        {buttonLabel}
      </Button>
      <FSEBranchModal
        id="fse-branch-modal"
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        submitAction={submitAction}
        biType={biType}
        selectFSE={selectFSE}
        updateOffice={updateOffice}
        selectedOffice={selectedOffice}
        selectedFSE={selectedFSE}
        user={user}
        setNotificationMessage={setNotificationMessage}
      />
    </span>
  )
}

const mapStateToProps = (state: AppState) => ({
  isLoggedIn: isLoggedIn(state),
  isBlockedPdfGeneration: isBlockedPdfGeneration(state),
  userDistributorGroup: getUserDistributorGroup(state),
  selectedFSE: getSelectedFSE(state),
  selectedOffice: getSelectedOffice(state),
  user: getIdentityUser(state),
  selectedDisplayProduct: getSelectedDisplayProduct(state),
})

export default connect(mapStateToProps, { selectFSE, updateOffice, setNotificationMessage })(
  withModalToggle(ShareButton)
)
