import styled from 'styled-components'
import { Button } from 'reactstrap'
import { getIcon } from './icon'
import { Children } from 'react'

export const ButtonComponent = styled(Button)`
  font-weight: bold;
  ${(props) => (props.block ? 'display: block;' : null)}
  svg,
  span {
    display: inline-block;
    vertical-align: middle;
  }

  svg {
    height: 0.825em;
    width: 0.825em;
    margin-right: 4px;
  }
  &.btn-outline-disabled {
    pointer-events: none;
  }
  &.remote-selling-btn.btn-loading,
  &.remote-selling-btn.btn-loading:focus {
    color: #ff3233;
    border-color: #ff3233;
    background: white;
    box-shadow: none;
    &:after {
      display: none;
    }
    svg {
      color: #ff3233;
    }
    #spinner {
      border-left-color: #ff3233;
    }
  }
`

function Component({ disabled, color = 'primary', onClick, ...props }) {
  return (
    <ButtonComponent onClick={disabled ? undefined : onClick} color={disabled ? 'disabled' : color} {...props}>
      {props.icon ? (
        Children.toArray([getIcon(props.icon), <span>{props.children}</span>])
      ) : (
        <span>{props.children}</span>
      )}
    </ButtonComponent>
  )
}

export default Component
// export default compose(
//   mapProps(({ disabled, color = 'primary', onClick, ...props }) => ({
//     ...props,
//     onClick: disabled ? undefined : onClick,
//     color: disabled ? 'disabled' : color,
//   })),
//   withPropsOnChange(['children', 'icon'], ({ children, icon }) => {
//     if (icon) {
//       return {
//         children: React.Children.toArray([getIcon(icon), <span>{children}</span>]),
//       }
//     }
//   })
// )(Component)
