import styled from 'styled-components'
import { Box } from 'rebass'
import { marginTopLessMixin } from 'e-submission/components/mixins'

const halfGapMixin = ({ halfGap, theme }) =>
  halfGap &&
  theme.media.sm`
    &:nth-child(odd) {
      padding-right: 0.5rem;
    }
    &:nth-child(even) {
      padding-left: 0.5rem;
    }
  `

const FormGroup = styled(({ gapLess, marginTopLess, halfGap, flex = [], color, ...otherProps }) => (
  <Box flex={['1 1 100%', ...flex]} {...otherProps} />
)).attrs({
  className: ({ color }) => {
    const colorClassName = color ? `has-${color}` : ''
    return `form-group ${colorClassName}`
  },
  pl: 2,
  pr: 2,
})`
  ${marginTopLessMixin}
  ${halfGapMixin}
`

export default FormGroup
