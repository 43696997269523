// @flow

import MESSAGES from 'core/data-model/constants/bi-messages'
import { getHealthBenefitData } from 'core/service/basic-plan/benefit'

export const buildFlexiHealthBenefitsSection = () => {
  let benefitData = getHealthBenefitData()
  return [
    {
      text: MESSAGES.BASIC_PLAN_DEATH_BENEFIT,
      style: 'sub-title',
    },
    {
      text: benefitData.deathBenefitsDescription,
      style: 'textRow',
    },
    {
      text: benefitData.maturityBenefits,
      style: 'sub-title',
    },
    {
      text: benefitData.maturityBenefitsDescription,
      style: 'textRow',
    },
  ]
}
