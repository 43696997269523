export const HeadingGroup = (props) => {
  return (
    <div className={`group-heading ${props.className ? props.className : ''}`} style={props.style ? props.style : null}>
      {props.children}
    </div>
  )
}

export const Heading = (props) => {
  return (
    <div className={`heading ${props.className ? props.className : ''}`} style={props.style ? props.style : null}>
      {props.children}
    </div>
  )
}

export const HeadingFixedGroup = (props) => {
  return (
    <div
      className={`heading--fixed ${props.className ? props.className : ''}`}
      style={props.style ? props.style : null}
    >
      {props.children}
    </div>
  )
}

export const Headers = (props) => {
  return (
    <div className={`headers ${props.className ? props.className : ''}`}>
      <div className="scroller" ref={props.targetRef}>
        {props.children}
      </div>
    </div>
  )
}

export const HeadingGroupMaster = (props) => {
  return (
    <div
      className={`master-heading ${props.className ? props.className : ''}`}
      style={props.style ? props.style : null}
    >
      {props.children}
    </div>
  )
}

export const HeadingGroupSub = (props) => {
  return <div className={`sub-heading ${props.className ? props.className : ''}`}>{props.children}</div>
}

export const Tracks = (props) => {
  return (
    <div className={`tracks ${props.className ? props.className : ''}`} ref={props.targetRef}>
      {props.children}
    </div>
  )
}

export const Track = (props) => {
  return (
    <div className={`track ${props.className ? props.className : ''}`} style={props.style ? props.style : null}>
      {props.children}
    </div>
  )
}

export const Entry = (props) => {
  return (
    <div className={`entry ${props.className ? props.className : ''}`} style={props.style ? props.style : null}>
      {props.children}
    </div>
  )
}

export const EntryFixed = (props) => {
  return (
    <div className={`entry--fixed ${props.className ? props.className : ''}`} style={props.style ? props.style : null}>
      {props.children}
    </div>
  )
}

export const StickyTable = (props) => {
  return <div className={`sticky-table wrap ${props.className ? props.className : ''}`}>{props.children}</div>
}
