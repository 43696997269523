// @flow

import { svgStringToBase64 } from 'core/service/lib/tobase64'
import MESSAGES from 'core/data-model/constants/bi-messages'
import type { TableBody } from 'core/service/pdf-generation/utils/table-utils'
import { ColorfulHeader, centerAlignTable } from 'core/service/pdf-generation/utils/table-utils'
import { splitWords } from 'core/service/pdf-generation/utils/line-wrapper'
import { fundGroupColor } from './fund-allocation-pie-chart'
import { chartLegend } from './fund-chart-legend'
import { chartLegendPattern } from './fund-chart-legend-pattern'
import type { PortfolioDisplayOptions } from 'core/service/pdf-generation/products/investment/sections/mutual-fund-portfolio'

const chartHtmlIds = {
  rpp: 'fund-allocation-chart-container',
  lstu: 'fund-allocation-chart-container--lstu',
}

export type FundData = {
  name: string,
  value: number,
}

export type PieChartByType = {
  rpp: string,
  lstu: string,
}

export const getRppChart = () => document.getElementById(chartHtmlIds.rpp)
export const getLstuChart = () => document.getElementById(chartHtmlIds.lstu)

const convertSvgChartToBase64 = async (rawGraphSVG) => {
  let svgTag = rawGraphSVG.match(/<svg[\s\S]*?svg>/)
  if (!svgTag) return ''
  const resultSVGString = svgTag[0].replace('<svg ', '<svg xmlns="http://www.w3.org/2000/svg" ')
  return await svgStringToBase64(resultSVGString)
}

export const fundAllocationChart = async () => {
  const chartContainer = getRppChart()
  if (!chartContainer) return ''
  return convertSvgChartToBase64(chartContainer.innerHTML)
}

export const fundAllocationChartByType = async () => {
  const rppChartContainer = getRppChart()
  const lstuChartContainer = getLstuChart()
  const rppChart = !rppChartContainer ? '' : await convertSvgChartToBase64(rppChartContainer.innerHTML)
  const lstuChart = !lstuChartContainer ? '' : await convertSvgChartToBase64(lstuChartContainer.innerHTML)
  return {
    rpp: rppChart,
    lstu: lstuChart,
  }
}

// FIXME: To be removed as it use to manipulate legend element which no longer used
export const arrayToPairs = (inputArray: (number | *)[]) => {
  const pairs = []
  for (let i = 0; i < inputArray.length; i += 2) {
    pairs.push(inputArray.slice(i, i + 2))
  }
  return pairs.map((row) => (row.length < 2 ? [...row, ''] : row))
}

export const FundRiskChartSection = (
  pieChartFundData: FundData[],
  pieChartFund: string,
  portfolioDisplayOptions: PortfolioDisplayOptions
): TableBody => {
  const lengendType = portfolioDisplayOptions.isUsePatternForChart ? chartLegendPattern : chartLegend
  return ColorfulHeader(MESSAGES.FUND_RISK_CHART_HEADER).concat([
    [
      {
        stack: [
          {
            image: pieChartFund,
            width: 164,
            margin: [-15, 0, 0, 0],
          },
          centerAlignTable({
            table: {
              body: pieChartFundData.map((fundGroup, index) => [
                lengendType(
                  fundGroupColor(index),
                  `${fundGroup.name}, ${fundGroup.value}%`,
                  index,
                  portfolioDisplayOptions.chartPatterns
                ),
              ]),
            },
            fontSize: 10,
            layout: 'noBorders',
          }),
        ],
      },
    ],
    [
      {
        text: splitWords(MESSAGES.FUND_RISK_CHART_FOOTER),
        bold: true,
        fontSize: 10,
      },
    ],
  ])
}
