// @flow

import type { DataWrapper } from 'core/data'
import CORE_MESSAGES from 'core/data-model/constants/messages'
import VALUES from 'core/data-model/constants/values'
import { generatePassword } from 'core/service/pdf-generation/utils/digital-signature'
import { getAdvisor } from 'e-submission/domain/service'
import { getIdentityUser, isLoggedIn } from 'identity/selectors'
import { get } from 'lodash/fp'
import { shareQuickQuote, generatePdfAndSign, generatePdfAndSignWithoutPwd } from 'pdf-generation/pdf-service'
import { generatePDFFailure, generatePDFRequest, generatePDFSuccess, loginRequest } from 'quick-quote/actions'
import MESSAGES from 'quick-quote/constants/messages'
import { getToggles } from 'quick-quote/feature-toggles'
import { getBirthdate } from 'quick-quote/insured-information/selectors'
import { qqToApp } from 'quick-quote/mapping'
import { setNotificationMessage } from 'quick-quote/notification/actions'
import { PREPARE_FOR_RA, SHARE_RA } from 'quick-quote/product-common/benefit-illustration/actions'
import { getBIFlexiHealthProps } from 'quick-quote/product-flexi-health/benefit-illustration/sagas'
import { getBIInvestmentProps } from 'quick-quote/product-investment/benefit-illustration/sagas'
import { getBILifeSaveProProps } from 'quick-quote/product-life-save-pro/benefit-illustration/sagas'
import { getBITermLifeProps } from 'quick-quote/product-term-life/benefit-illustration/sagas'
import { getBIRetirementProps } from 'quick-quote/product-retirement/coverage-plan/sagas'
import { getBISavingProps } from 'quick-quote/product-saving/benefit-illustration/sagas'
import { getSelectedBasicCategory } from 'quick-quote/product-selection/selectors'
import { getBIWholeLifeProps } from 'quick-quote/product-whole-life/benefit-illustration/sagas'
import { getBIIHealthyUltraProps } from 'quick-quote/product-ihealthy-ultra/benefit-illustration/sagas'
import { getBIMRTAProps } from 'quick-quote/product-mrta/benefit-illustration/sagas'
import {
  getAppState,
  getValidatedEnteringESubRules,
  isBlockedPdfGeneration,
  isReviseQQFlow,
} from 'quick-quote/selectors'
import type { Effect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import type { BIProps, PrepareQuickQuote, PrepareRPQ, ShareQuickQuote } from './actions'
import { PREPARE_FOR_QUICK_QUOTE, PREPARE_FOR_RPQ, SHARE_QUICK_QUOTE, SHARE_RPQ } from './actions'

export function* checkPDFReadiness(action: PrepareQuickQuote): Generator<*, *, *> {
  const _isBlockedPdfGeneration = yield select(isBlockedPdfGeneration)
  if (_isBlockedPdfGeneration) {
    return
  }

  const _isLoggedIn = yield select(isLoggedIn)
  if (!_isLoggedIn) {
    return yield put(loginRequest())
  }

  const enteringErrors = yield select(getValidatedEnteringESubRules)

  if (enteringErrors.length > 0) {
    yield put(
      setNotificationMessage({
        title: CORE_MESSAGES.NOT_ALLOW_ESUBMISSION.TITLE,
        message: enteringErrors[0],
        type: 'ErrorMessage',
      })
    )
    return
  }

  const birthDate = yield select(getBirthdate)
  const productCategory = yield select(getSelectedBasicCategory)
  yield put(
    setNotificationMessage({
      title: MESSAGES.INSTRUCTIONS_FOR_BI,
      type: 'PasswordInformation',
      password: generatePassword(birthDate),
      productCategory,
      biType: action.payload.biType,
    })
  )
}

export function* getBIProps(biProps: BIProps): Generator<*, *, *> {
  switch (biProps.category) {
    case VALUES.INVESTMENT:
      return yield call(getBIInvestmentProps, biProps)
    case VALUES.SAVING:
      return yield call(getBISavingProps, biProps)
    case VALUES.LIFE_SAVE_PRO:
      return yield call(getBILifeSaveProProps, biProps)
    case VALUES.TERM_LIFE:
      return yield call(getBITermLifeProps, biProps)
    case VALUES.FLEXI_HEALTH:
      return yield call(getBIFlexiHealthProps, biProps)
    case VALUES.RETIREMENT:
    case VALUES.BUMNAN_READY:
      return yield call(getBIRetirementProps, biProps)
    case VALUES.IHEALTHY_ULTRA:
      return yield call(getBIIHealthyUltraProps, biProps)
    case VALUES.MRTA:
      return yield call(getBIMRTAProps, biProps)
    case VALUES.PA:
    case VALUES.HEALTH:
    case VALUES.WHOLE_LIFE:
    case VALUES.PROTECTION:
    case VALUES.PERFECTLIFE:
    case VALUES.SUKHAPABDEKDEE:
    default:
      return yield call(getBIWholeLifeProps, biProps)
  }
}
const errors = [
  MESSAGES.BOTH_ADBLOCK_POPUP_DETECTED,
  MESSAGES.POPUP_BLOCKER_ENABLED,
  MESSAGES.ADBLOCK_DETECTED,
  MESSAGES.CHECK_PDF_MESSAGE,
]

const getErrorRule = (e) => {
  const adblockAndPopup = {
    accept: () => errors.includes(e.message),
    errorMsg: {
      message: e.message,
      title: undefined,
      type: 'Information',
    },
  }

  const exceptionNetwork = {
    accept: () => e.message !== 'Network error',
    errorMsg: {
      message: getToggles().ENABLE_RED_TRUCK_ERROR_MSG ? e.message : MESSAGES.CAN_NOT_CREATE_DOCUMENT,
      title: undefined,
      type: 'GenericError',
    },
  }
  const rules = [adblockAndPopup, exceptionNetwork]
  return rules.find((rule) => rule.accept())
}

export function* openQuickQuotePDF(action: ShareQuickQuote): Generator<*, *, *> {
  try {
    yield put(generatePDFRequest())
    const biProps = yield call(getBIProps, action.payload)
    yield call(shareQuickQuote, biProps)
    yield put(generatePDFSuccess())
  } catch (e) {
    yield put(generatePDFFailure())
    console.error(e)
    const rule = getErrorRule(e)
    if (!!rule) {
      yield put(setNotificationMessage(rule.errorMsg))
    }
  }
}

export function* prepareRpqData(action: PrepareRPQ): Generator<*, *, *> {
  return yield call(showSharePdfPopup, { title: MESSAGES.INSTRUCTIONS_FOR_RPQ, type: 'RPQPasswordInformation' })
}

export function* prepareRaData(action: PrepareRPQ): Generator<*, *, *> {
  return yield call(showSharePdfPopup, { title: MESSAGES.INSTRUCTIONS_FOR_RA, type: 'RAPasswordInformation' })
}

export function* showSharePdfPopup({ title, type }): Generator<*, *, *> {
  try {
    const _isLoggedIn = yield select(isLoggedIn)
    if (!_isLoggedIn) {
      return yield put(loginRequest())
    }

    const birthDate = yield select(getBirthdate)
    yield put(
      setNotificationMessage({
        title: title,
        type: type,
        password: generatePassword(birthDate),
      })
    )
  } catch (e) {
    console.error('prepareRpqData', e)
  }
}

export function* openRpqPDF(services, action: PrepareRPQ): Generator<*, *, *> {
  yield call(openDocumentPDF, services, 'rpq')
}

export function* openRaPDF(services, action: PrepareRPQ): Generator<*, *, *> {
  yield call(openDocumentPDF, services, 'ra')
}

export function* openDocumentPDF(services, pdfType): Generator<*, *, *> {
  const { openPdf, openNewWindowWithLoading } = yield import(
    /* webpackChunkName: "openPdf" */ '../../../pdf-generation/pdf-opener'
  )
  let newBrowserWindow
  /* istanbul ignore next */
  if (!window.cordova) {
    newBrowserWindow = openNewWindowWithLoading()
  }

  try {
    const qqState = yield select(getAppState)
    const productCategory = yield select(getSelectedBasicCategory)
    const user = yield select(getIdentityUser)

    const birthDate = yield select(getBirthdate)
    const agentType = get('profile.agent_type', user)
    const agentCode = get('profile.agent_code', user)
    const advisor = yield services.getAdvisor(user, agentCode, agentType)

    const accessToken = user.access_token
    const data = qqToApp(qqState, productCategory, advisor)
    console.group('openDocumentPDF')
    console.log('pdfType', pdfType)
    console.log(data)
    console.groupEnd('openDocumentPDF')

    const isReviseQQ = yield select(isReviseQQFlow)

    console.log('isReviseQQ', isReviseQQ)
    let pdfDoc

    if (isReviseQQ) {
      pdfDoc = yield services.generatePdfAndSignWithoutPwd(data, pdfType, accessToken)
    } else {
      pdfDoc = yield services.generatePdfAndSign(data, pdfType, accessToken, birthDate)
    }
    yield openPdf(pdfDoc, newBrowserWindow)
  } catch (e) {
    /* istanbul ignore next */
    if (newBrowserWindow) {
      newBrowserWindow.close()
    }

    if (e.message === 'SIGN ERROR') return

    console.error('PDF Failure: ' + e.message)

    if (getToggles().ENABLE_RED_TRUCK_ERROR_MSG) throw e
    else throw new Error(MESSAGES.GENERATE_PDF_ERROR_MESSAGE)
  }
  let shouldNotiUserToCheckPdf = false
  if (getToggles().ENABLE_HANDLE_PDF_CHROME_ANDROID) {
    yield new Promise((resolve, reject) => {
      setTimeout(() => {
        if (newBrowserWindow && !newBrowserWindow.location.href.startsWith('blob')) {
          newBrowserWindow.close()
          shouldNotiUserToCheckPdf = true
        }
        resolve()
      }, 500)
    })
    if (shouldNotiUserToCheckPdf) {
      yield put(
        setNotificationMessage({
          message: MESSAGES.CHECK_PDF_MESSAGE,
          title: undefined,
          type: 'Information',
        })
      )
    }
  }
  return
}

export function* watchers(dataWrapper: DataWrapper): Generator<*, *, Effect[]> {
  yield [takeLatest(PREPARE_FOR_QUICK_QUOTE, checkPDFReadiness), takeLatest(SHARE_QUICK_QUOTE, openQuickQuotePDF)]
  yield [
    takeLatest(PREPARE_FOR_RPQ, prepareRpqData),
    takeLatest(PREPARE_FOR_RA, prepareRaData),
    takeLatest(SHARE_RPQ, openRpqPDF, { generatePdfAndSign, generatePdfAndSignWithoutPwd, getAdvisor }),
    takeLatest(SHARE_RA, openRaPDF, { generatePdfAndSign, generatePdfAndSignWithoutPwd, getAdvisor }),
  ]
}

export const sagas = watchers
