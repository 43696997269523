import moment from 'moment'
import { PAYMENT_TYPE } from 'e-submission/domain/data-model/constants'
import { isMatchWith, remove, flow, map, join, replace, isInteger, isEmpty, filter } from 'lodash/fp'
import { getToggles } from 'quick-quote/feature-toggles'
import { getAppConfig } from 'deploy-env/app-config'
import VALUE from '../../core/data-model/constants/values'
import { base64StringToBlob } from 'blob-util'
import _ from 'lodash'

export const IsRealDevice = typeof window === 'object' && !isEmpty(window.cordova)
export const isProdEnv = getAppConfig().OC_ENVIRONMENT === 'prod'
export const isCordova = false
export const isDisable2ndDoc = () => {
  return getToggles().ENABLE_EKYC_F2F && getToggles().ENABLE_DISABLE_SECOND_DOC
}

export const when = (condition, fn) => (value) => {
  if (condition(value)) {
    return fn(value)
  }
  return value
}

export const isAppIdPaidPayment = (applications) => {
  const paymentWithPaid = applications.receipts.find((receipt) => receipt.receiptNo && receipt.paid)
  return paymentWithPaid
}

export const isPaidPayment = (appWithID) => {
  const { policy } = appWithID
  const paymentWithPaid = policy.receipts.find((receipt) => receipt.receiptNo && receipt.paid)
  return paymentWithPaid
}

export const checkPaymentType = (appWithID) => {
  const receipt = isPaidPayment(appWithID) || {}
  return receipt.type
}

export const isPolicyExpired = (appWithID) => {
  const currentDate = moment()
  const numberOfDayValidateAppCreated = getAppConfig().NUMBER_OF_DAY_VALIDATE_APP_CREATED
  const validateDayAppCreated = moment(appWithID.createdAt)
    .add(numberOfDayValidateAppCreated, 'days')
    .isBefore(currentDate)
  return validateDayAppCreated
}

export const haveEcbr = (appWithID) => {
  const { policy } = appWithID
  const ecbrIssue = policy.receipts.find((receipt) => receipt.receiptNo && receipt.signed && receipt.paid)
  return ecbrIssue
}

export const productDisableFlag = (appWithID) => {
  const productExpiryDT = appWithID.productExpiryDate ? moment(appWithID.productExpiryDate).format() : null
  const productCode = appWithID.productCode
  const isHiddenProduct = !appWithID.isProductDisplay
  // Use Date.now for date mocking purpose in test
  const dateNow = moment(Date.now()).format()
  const isExpire = appWithID.productExpiryDate ? moment(productExpiryDT).isBefore(dateNow) : false

  let toggleProductFlag = false
  switch (productCode) {
    case '25PL':
      toggleProductFlag = getToggles().SHOW_PRODUCT_25PL
      break
    case VALUE['5WL85']:
      toggleProductFlag = getToggles().SHOW_PRODUCT_5WL85
      break
    case 'RPUDR':
      toggleProductFlag = getToggles().SHOW_PRODUCT_RPUDR
      break
    case VALUE.ICARE:
      toggleProductFlag = getToggles().SHOW_PRODUCT_ICARE
      break
    case VALUE.IGROW:
      toggleProductFlag = getToggles().SHOW_PRODUCT_IGROW
      break
    case '25P15':
      toggleProductFlag = getToggles().SHOW_PRODUCT_25P15
      break
    case '20P10':
      toggleProductFlag = getToggles().SHOW_PRODUCT_20P10
      break
    case 'FWLNP85':
      toggleProductFlag = getToggles().SHOW_PRODUCT_FLEXI_HEALTH
      break
    case '25P15S':
      toggleProductFlag = getToggles().SHOW_PRODUCT_25P15S
      break
    case '20P10S':
      toggleProductFlag = getToggles().SHOW_PRODUCT_20P10S
      break
    case 'W85P10J':
      toggleProductFlag = getToggles().SHOW_PRODUCT_W85P10J
      break
    case 'W85P10S':
      toggleProductFlag = getToggles().SHOW_PRODUCT_W85P10S
      break
    case 'WL88J':
      toggleProductFlag = getToggles().SHOW_PRODUCT_WL88J
      break
    case 'WL88S':
      toggleProductFlag = getToggles().SHOW_PRODUCT_WL88S
      break
    case 'R01':
      toggleProductFlag = getToggles().SHOW_PRODUCT_IRETIRE_R01
      break
    case 'R05':
      toggleProductFlag = getToggles().SHOW_PRODUCT_IRETIRE_R05
      break
    case 'PLB05':
      toggleProductFlag = getToggles().SHOW_PRODUCT_PLB05
      break
    case 'PLB10':
      toggleProductFlag = getToggles().SHOW_PRODUCT_PLB10
      break
    case 'PLB12':
      toggleProductFlag = getToggles().SHOW_PRODUCT_PLB12
      break
    case 'PLB15':
      toggleProductFlag = getToggles().SHOW_PRODUCT_PLB15
      break
    default:
      toggleProductFlag = true
  }
  const isToggleoff = !toggleProductFlag
  return isExpire || isToggleoff || isHiddenProduct
}

export const housekeepingStatusSignedFlow = (appWithID) => {
  const enabledHouseKeeping = getToggles().ENABLE_DRAFT_HOUSE_KEEPING

  let disable = false
  let statusSigned = null

  const policyCheckExpiredFlow = isPolicyExpired(appWithID) || productDisableFlag(appWithID)
  const policyCheckPaymentTypeAndEcbrFlow = isPolicyExpired(appWithID) || productDisableFlag(appWithID)

  if (isPaidPayment(appWithID)) {
    if (checkPaymentType(appWithID) === PAYMENT_TYPE.CREDITCARD) {
      statusSigned = policyCheckPaymentTypeAndEcbrFlow ? 'signed-over' : statusSigned
    } else {
      if (haveEcbr(appWithID)) {
        statusSigned = policyCheckPaymentTypeAndEcbrFlow ? 'signed-over' : statusSigned
      } else {
        disable = policyCheckExpiredFlow
        statusSigned = policyCheckExpiredFlow ? 'signed-disabled' : statusSigned
      }
    }
  } else {
    disable = policyCheckExpiredFlow
    statusSigned = policyCheckExpiredFlow ? 'signed-disabled' : statusSigned
  }
  return {
    disabled: enabledHouseKeeping && disable,
    statusSigned: enabledHouseKeeping ? statusSigned : null,
  }
}

export const housekeepingStatusDraftedFlow = (appWithID) => {
  const enabledHouseKeeping = getToggles().ENABLE_DRAFT_HOUSE_KEEPING

  let disable = false
  let statusSigned = null

  const policyCheckExpiredFlow = isPolicyExpired(appWithID) || productDisableFlag(appWithID)
  disable = policyCheckExpiredFlow
  statusSigned = policyCheckExpiredFlow ? 'drafted-disabled' : statusSigned

  return {
    disabled: enabledHouseKeeping && disable,
    statusSigned: enabledHouseKeeping ? statusSigned : null,
  }
}

export const addSignedDateStatus = (createdAt, productDisable) => {
  const signedOverDate = moment(createdAt)
    .add(30, 'days')
    .format()
  // Use Date.now for date mocking purpose in test
  const dateNow = moment(Date.now()).format()
  const isSignedOverDate = moment(signedOverDate).isBefore(dateNow)
  return productDisable ? 'signed-disabled' : isSignedOverDate ? 'signed-over' : null
}

export const mapProductsConfigToApp = (appWithId, productState) => {
  const productConfig = {
    product: flow(filter((product) => product.code === appWithId.quickQuote.selectedDisplayProduct.code))(
      productState.products.payload
    ),
    isProductDisplay: Array.isArray(productState.productDisplays)
      ? productState.productDisplays.indexOf(appWithId.quickQuote.selectedDisplayProduct.code) > -1
      : false,
  }
  const appWithIdAndProduct = Object.assign(productConfig, appWithId)
  return appWithIdAndProduct
}

export const toPathString = flow(
  map((name) => {
    if (isInteger(name)) {
      return `[${name}]`
    }

    return name
  }),
  join('.'),
  replace(/\.\[/g, '[')
)

export const isShallowEqual = isMatchWith((a, b) => a === b)

export const updateOnChange = (resolver, callback) => {
  let prev = resolver()
  return () => {
    if (!isShallowEqual(prev, resolver())) {
      prev = resolver()
      callback()
    }
  }
}

export const emitter = () => {
  const subscribers = []

  const subscribe = (sub) => {
    subscribers.push(sub)
    return () => remove(subscribers, sub)
  }

  const emit = (item) => {
    const arr = subscribers.slice()
    for (let i = 0, len = arr.length; i < len; i++) {
      arr[i](item)
    }
  }

  return {
    subscribe,
    emit,
  }
}

export const base64ToBlob = (base64, contentType = '', sliceSize = 512) => {
  const byteCharacters = window.atob(base64)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)

    byteArrays.push(byteArray)
  }

  return new Blob(byteArrays, { type: contentType })
}

export const blobToDataUrl = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onerror = (err) => reject(err)

    reader.onload = (evt) => resolve(evt.target.result)

    reader.readAsDataURL(blob)
  })

export const blobToText = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onerror = (err) => reject(err)

    reader.onload = (evt) => resolve(evt.target.result)

    reader.readAsText(blob)
  })

export const removeZeroWidthSpaces = (text) => {
  // There are more invisible characters than this list
  return text.replace(/[\u200B-\u200D\uFEFF]/g, '')
}

/* DIGSALTOOL-1659  */
export const prepareEkycDetailResult = (detail) => {
  const frontIdCardResult = {}
  const backIdCardResult = {}
  const dopaResult = {}

  // frontIdCardResult section
  if (_.get(detail, 'frontIdCardResult.edits', null) === null) {
    frontIdCardResult.titleTh = _.get(detail, 'frontIdCardResult.response.result.title_th', '-')
    frontIdCardResult.titleEn = _.get(detail, 'frontIdCardResult.response.result.title_en', '-')
    frontIdCardResult.firstNameTh = _.get(detail, 'frontIdCardResult.response.result.first_name_th', '-')
    frontIdCardResult.lastNameTh = _.get(detail, 'frontIdCardResult.response.result.last_name_th', '-')
    frontIdCardResult.citizenId = _.get(detail, 'frontIdCardResult.response.result.id_number', '-')
    frontIdCardResult.dateOfBirth = _.get(detail, 'frontIdCardResult.response.result.date_of_birth_th', '-')
    frontIdCardResult.address = _.get(detail, 'frontIdCardResult.response.result.address_th', '-')
  } else {
    frontIdCardResult.titleTh = _.get(detail, 'frontIdCardResult.edits.titleTh', '-')
    frontIdCardResult.titleEn = _.get(detail, 'frontIdCardResult.edits.titleEn', '-')
    frontIdCardResult.firstNameTh = _.get(detail, 'frontIdCardResult.edits.firstNameTh', '-')
    frontIdCardResult.lastNameTh = _.get(detail, 'frontIdCardResult.edits.lastNameTh', '-')
    frontIdCardResult.citizenId = _.get(detail, 'frontIdCardResult.edits.citizenId', '-')
    frontIdCardResult.dateOfBirth = _.get(detail, 'frontIdCardResult.edits.dateOfBirth', '-')
    frontIdCardResult.address = _.get(detail, 'frontIdCardResult.edits.address', '-')
  }
  frontIdCardResult.idCardImageUrl = _.get(detail, 'frontIdCardResult.response.idCardImageUrl', null)

  // backIdCardResult section
  if (_.get(detail, 'backIdCardResult.edits', null) === null) {
    backIdCardResult.laser = _.get(detail, 'backIdCardResult.response.result.laser_code', '-')
  } else {
    backIdCardResult.laser = _.get(detail, 'backIdCardResult.edits.laser', '-')
  }

  // dopaResult section
  dopaResult.verified = _.get(detail, 'dopaResult.verified', null)
  dopaResult.dopaRequestFailed = _.get(detail, 'dopaResult.dopaRequestFailed', null)
  dopaResult.message = _.get(detail, 'dopaResult.response.data.message', '-')

  return {
    frontIdCardResult,
    backIdCardResult,
    dopaResult,
  }
}

export const getVerificationDetail = (detail) => {
  let frontIdCardResult = null
  let backIdCardResult = null
  let dopaResult = null

  // frontIdCardResult section
  if (_.get(detail, 'frontIdCardResult', null)) {
    frontIdCardResult = {
      response: {
        idCardImageKey: _.get(detail, `frontIdCardResult.response.idCardImageKey`, null),
        idCardImageUrl: _.get(detail, `frontIdCardResult.response.idCardImageUrl`, null),
        result: {
          title_th: _.get(detail, `frontIdCardResult.response.result.title_th`, '-'),
          first_name_th: _.get(detail, `frontIdCardResult.response.result.first_name_th`, '-'),
          last_name_th: _.get(detail, `frontIdCardResult.response.result.last_name_th`, '-'),
          id_number: _.get(detail, `frontIdCardResult.response.result.id_number`, '-'),
          date_of_birth_th: _.get(detail, `frontIdCardResult.response.result.date_of_birth_th`, '-'),
          address_th: _.get(detail, `frontIdCardResult.response.result.address_th`, '-'),
        },
      },
      verified: _.get(detail, `frontIdCardResult.verified`, false),
      updatedAt: _.get(detail, `frontIdCardResult.updatedAt`, null),
    }
    if (_.get(detail, 'frontIdCardResult.edits', null)) {
      frontIdCardResult.edits = {
        titleTh: _.get(detail, `frontIdCardResult.edits.titleTh`, '-'),
        firstNameTh: _.get(detail, `frontIdCardResult.edits.firstNameTh`, '-'),
        lastNameTh: _.get(detail, `frontIdCardResult.edits.lastNameTh`, '-'),
        citizenId: _.get(detail, `frontIdCardResult.edits.citizenId`, '-'),
        dateOfBirth: _.get(detail, `frontIdCardResult.edits.dateOfBirth`, '-'),
        address: _.get(detail, `frontIdCardResult.edits.address`, '-'),
      }
    }
  }

  // backIdCardResult section
  if (_.get(detail, 'backIdCardResult', null)) {
    backIdCardResult = {
      response: {
        result: {
          laser_code: '-',
        },
      },
      verified: _.get(detail, `backIdCardResult.verified`, false),
    }
    if (_.get(detail, 'backIdCardResult.edits', null)) {
      backIdCardResult.edits = {
        laser: '-',
      }
    }
  }

  // dopaResult section
  if (_.get(detail, 'dopaResult', null)) {
    dopaResult = {
      response: {
        data: {
          message: _.get(detail, `dopaResult.response.data.message`, '-'),
        },
      },
      verified: _.get(detail, `dopaResult.verified`, false),
      dopaRequestFailed: _.get(detail, `dopaResult.dopaRequestFailed`, null),
    }
  }

  return {
    frontIdCardResult,
    backIdCardResult,
    dopaResult,
  }
}
export const getMetaData = (data) => {
  return typeof data === 'string' ? JSON.parse(data) : data
}
const toDataURL = (url) =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onloadend = () => resolve(reader.result)
          reader.onerror = reject
          reader.readAsDataURL(blob)
        })
    )

export const convertImageUrlToBlob = async (file) => {
  const dataUrl = await toDataURL(file.url)
  return base64StringToBlob(dataUrl.replace(/^.*base64,/, ''), file.fileType)
}
