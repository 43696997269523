// @flow
import _ from 'lodash'

import { d } from 'core/service/lib/decimal'
import type { Age } from 'core/data-model/insured'
import type { CashDropRates } from 'core/data-model/basic-plan/types'

export const calculateCashDrop = (cashDropRate: number, sumAssured: number): number => {
  if (cashDropRate < 0) return 0
  const multiplyResult = d(sumAssured).dividedBy(1000)
  return d(multiplyResult)
    .times(cashDropRate)
    .toNumber()
}

export const findCashDropRate = (productCode: string, cashDropRates?: CashDropRates[]) =>
  _.find(cashDropRates, (rate) => rate.productCode === productCode)

export const findNonGuaranteeCashDropRate = (productCode: string, cashDropRates?: CashDropRates[]) =>
  _.find(cashDropRates, (rate) => rate.productCode === `${productCode}_NON_G`)

export const queryCashDropRate = (
  gender: string,
  age: Age,
  policyYear: number,
  filteredCashDropRates?: CashDropRates
): number => {
  const queryAge: number = age.unit !== 'year' ? 0 : age.value

  return _.get(filteredCashDropRates, ['cashDropRates', gender, queryAge.toString(), policyYear.toString()], 0)
}
