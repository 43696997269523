// @flow
import type { AppState } from 'quick-quote/reducers'

import { compose } from 'recompose'
import { Button } from 'reactstrap'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import MESSAGES from 'core/data-model/constants/messages'
import { getCurrentMaturityIDCard, canEnableBtnProceedToSelectPolicyOwner } from 'quick-quote/product-s7cc/selectors'
import { isLoading } from 'quick-quote/ui/selectors'
import { maturityValidate } from 'quick-quote/product-s7cc/actions'

type Props = {
  currentMaturityIDCard: number,
  maturityValidate: () => void,
}

export const _MaturityValidateButton = (props: Props) => {
  const RealButton = (
    <Button
      className="e-submission"
      color={!props.enableBtnMaturityValidate || props.isServiceProcessing ? 'disabled' : 'primary'}
      onClick={() => props.maturityValidate(props.currentMaturityIDCard)}
      disabled={!props.enableBtnMaturityValidate || props.isServiceProcessing ? true : false}
    >
      {MESSAGES.SUBMIT}
    </Button>
  )
  return RealButton
}

const mapStateToProps = (state: AppState) => ({
  currentMaturityIDCard: getCurrentMaturityIDCard(state),
  enableBtnMaturityValidate: canEnableBtnProceedToSelectPolicyOwner(state),
  isServiceProcessing: isLoading(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  maturityValidate: bindActionCreators(maturityValidate, dispatch),
})

export default compose(connect(mapStateToProps, mapDispatchToProps))(_MaturityValidateButton)
