import styled from 'styled-components'
import { compose } from 'recompose'
import { Flex } from 'rebass'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { Alert } from 'reactstrap'
import VALUE from 'core/data-model/constants/values'
import { setAppValue, unsetAppValue } from 'e-submission/apps/actions'

import grayDot from 'assets/images/form/gray-dot.png'
import Card from 'e-submission/components/Card'

import Field from 'e-submission/components/FormEngine/FormManager'
import Content from 'e-submission/components/Layout/Content'

import { CheckedCard, NotCheckedCard, Side, Description } from 'e-submission/components/Card/DocumentCard'
import Icon from 'e-submission/components/Icon'
import { getCurrentApp } from 'e-submission/apps/selectors'
import { PAYMENT_TYPE } from 'e-submission/domain/data-model/constants'
import { get } from 'lodash/fp'

const STEP_WIDTH = 40
const PaymentCard = styled((props) => {
  const { id, paid, selected, setValue, className, paymentSteps, disabled, isAllReceiptCreated, toggleModal } = props
  const firstPaymentStep = paymentSteps[0]
  const onSelect = () => !paid && !disabled && props.onSelect(props)
  const eventProps = { onClick: onSelect }
  return (
    <Card id={id} className={className} column style={{ background: 'none' }} w={1} pl={[0, 2, 2]} pr={[0, 2, 2]}>
      {(isAllReceiptCreated ? selected : true) && (
        <Flex className="form-group card-header" align="center" {...eventProps}>
          <Field setValue={setValue} elements={firstPaymentStep.elements} />
        </Flex>
      )}
      {isAllReceiptCreated && selected && (
        <div className="content">
          <div className="content-wrapper">
            {paymentSteps.slice(1).map(({ state, elements = [] }, index) => (
              <Flex className="payment-step" key={index}>
                <Flex className="balloon-container" flex="0 0 auto" justify="center" align="flex-start">
                  <div className={`balloon ${state}`}>
                    {state === 'done' && <Icon.check />}
                    <span className="number">{index + 1}</span>
                  </div>
                </Flex>
                <Flex className={`item-container ${state}`} flex="1 1 100%" align="center">
                  <Flex className="item-content" flex="0 0 100%" justify="flex-end">
                    <Field setValue={setValue} elements={elements} />
                  </Flex>
                </Flex>
              </Flex>
            ))}
          </div>
        </div>
      )}
    </Card>
  )
})`
  margin-bottom: 10px;
  border: none;
  .card-header {
    [id$='.add'] {
      background: ${({ theme }) => theme.variables['$color-wild-sand']};
      text-align: right;
      padding: 20px 0;
      margin-top: 10px;
      margin-left: -22px;
      margin-right: -22px;
      margin-bottom: -22px;
      button,
      button:hover,
      button:active,
      button:focus {
        text-decoration: underline;
        background: transparent;
        border: none;
        box-shadow: none;
        color: ${({ theme }) => theme.variables['$brand-primary']};
        &:after {
          display: none;
        }
      }
    }
    svg {
      margin-right: 10px;
      min-width: 40px;
      min-height: 40px;
      width: 40px;
      height: 40px;
      path {
        fill: black;
      }
    }
    &.form-group {
      background: white;
      padding: 12px 22px;
      border: none;
      > div {
        flex: 1 1 100%;
      }
      .form-group > div {
        > div > div {
          flex: 1 1 auto;
          min-width: 142px;
        }
        input,
        .text {
          max-width: 240px;
          align-self: center;
          text-align: right;
          font-size: ${({ theme }) => theme.variables['$font-size-h6']};
        }
        input[disabled] {
          background: ${({ theme }) => theme.variables['$color-mercury']};
        }
      }
    }
  }
  .content {
    background: white;
    padding-left: 20px;
    padding-right: 20px;
    .content-wrapper {
      padding-top: 30px;
      border-top: 2px solid ${({ theme }) => theme.variables['$color-silver']};
    }
  }
  .form-group {
    padding: 0;
    margin-bottom: 0;
  }
  .payment-step {
    background-image: url(${grayDot});
    background-color: transparent;
    background-repeat: repeat-y;
    background-position: left ${STEP_WIDTH / 2}px top;

    &:last-child {
      background: none;
    }
  }
  .balloon-container {
    width: ${STEP_WIDTH}px;
    .balloon {
      background-color: ${({ theme }) => theme.variables['$color-mercury']};
      border: 1px solid ${({ theme }) => theme.variables['$color-silver']};
      border-radius: 50%;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      color: ${({ theme }) => theme.variables['$color-scorpion']};
      &.active {
        color: black;
        background: white;
      }
      &.done {
        .number {
          display: none;
        }
        svg {
          width: 20px;
          height: 20px;
          path {
            fill: white;
            stroke: white;
            stroke-width: 8px;
          }
        }
        background-color: ${({ theme }) => theme.variables['$brand-success']};
      }
    }
  }
  .payment-step:last-child {
    .item-container {
      margin-bottom: 30px;
    }
  }
  .item-container {
    .item-content {
      margin-top: -10px;
      margin-bottom: 40px;
      > div {
        flex: 1 0 100%;
      }
      [id$='.text'] {
        margin-top: 10px;
        margin-left: 12px;
      }
      ${Side} {
        min-height: auto;
        min-width: auto;
        flex: 0 0 80px;
        > div {
          line-height: 50px;
        }
        button {
          height: 50px;
          width: 100%;
        }
      }
      ${NotCheckedCard} {
        color: initial;
        flex-direction: row-reverse;
        align-items: center;
        ${Description} {
          border: 1px solid transparent;
        }
      }
      ${CheckedCard} {
        ${Side}:first-child {
          display: none;
        }
      }
    }
  }
`

const mapDispatchToProps = (dispatch) => ({
  setValue: (fieldId, newValue) => {
    dispatch(setAppValue(fieldId, newValue))
  },
  unsetValue: (fieldId) => {
    dispatch(unsetAppValue(fieldId))
  },
})

const mapStateToProps = (state, props) => {
  const app = getCurrentApp(state)
  const isIInvest =
    get('quickQuote.selectedDisplayProduct.category', app) === VALUE.INVESTMENT &&
    get('quickQuote.selectedDisplayProduct.code', app) === VALUE.SPUL
  return {
    isIInvest,
  }
}

export default (hoc) =>
  compose(
    withRouter,
    hoc,
    connect(mapStateToProps, mapDispatchToProps)
  )(({ title, isValid, sections, disabled, isFirstTimeEnterPaymentAmount, isIInvest, ...props }) => {
    const isSectionSelected =
      sections.filter((item) => {
        return item.selected
      }).length > 0
    const sectionFilterByProductType = isIInvest
      ? sections.filter((item) => item.paymentType !== PAYMENT_TYPE.CREDITCARD)
      : sections
    return (
      <Content style={{ marginTop: '-0.5rem' }}>
        <Flex className="ml-3 mb-2 mt-1" style={{ color: '#2425aa' }}>
          <b>{title}</b>
        </Flex>
        {sectionFilterByProductType.map((paymentProps) => (
          <PaymentCard key={paymentProps.id + paymentProps.paymentType} {...props} {...paymentProps} />
        ))}
        {!isSectionSelected ? (
          <Flex className="ml-3 mr-3">
            <Alert color={'danger-no-icon'}>
              การชำระเบี้ยประกันตั้งแต่ 30,000 บาทขึ้นไป ควรชำระด้วยบัตรเครดิตหรือบัตรเดบิต
            </Alert>
          </Flex>
        ) : null}
      </Content>
    )
  })
