// @flow

import type { FundState } from 'core/data-model/investment'

export const UPDATE_AVAILABLE_FUNDS = 'INVESTMENT/UPDATE_AVAILABLE_FUNDS'
export type UpdateAvailableFunds = {
  type: 'INVESTMENT/UPDATE_AVAILABLE_FUNDS',
  payload: FundState[],
}

export const updateAvailableFunds = (funds: FundState[]): UpdateAvailableFunds => {
  return {
    type: UPDATE_AVAILABLE_FUNDS,
    payload: funds,
  }
}
