//@flow
import _ from 'lodash'
import Mustache from 'mustache'
import { formatNumber } from 'core/service/lib/number-format'
import MESSAGES from 'core/data-model/constants/bi-messages'
import CONSTANTS from 'core/data-model/constants/messages'
import type {
  PolicyValueTableProps,
  PolicyRetirementValue,
  PolicyValueRetirementTableProps,
} from 'core/service/benefit-illustration/policy-value'
import { _getMessageUnderiShieldPolicyValueTable } from 'core/service/benefit-illustration/policy-value'
import { formatFromZeroToDash } from 'core/service/pdf-generation/utils/table-utils'
import { isAnnuallyPensionType, getPensionTypeString } from 'core/service/benefit-illustration/products/retirement'
import { getToggles } from 'quick-quote/feature-toggles'
import { calculatePageAmountPolicyValueTable } from 'core/service/pdf-generation/products/common/logic'
import type { PolicyValue, YearEndPolicyValue } from 'core/service/benefit-illustration'

// this is a hack for line wrapping in pdf which is not
// support Thai language yet. Once it is implemented
// this should be removed
export const _breakLine = (text: string) => {
  const premiumPattern = /^(เบี้ยประกันภัย)(สัญญา.+)$/
  const deathBenefitPattern = /^(ความคุ้มครอง)(การเสียชีวิต)$/
  const surrenderCashPattern = /^(มูลค่าเวนคืน)(เงินสด)$/
  const savingWithCompany = /^(กรณีรับเป็น)(เงินสด)$/

  const patterns = [premiumPattern, deathBenefitPattern, surrenderCashPattern, savingWithCompany]

  // add space to trick pdfmake to do line wrapping for us
  const breakByFirstMatched = ([p, ...rest]) => (text.match(p) ? text.replace(p, '$1 $2') : breakByFirstMatched(rest))

  return breakByFirstMatched(patterns)
}

const rowHeightConfig = { lineHeight: 0.75, margin: [0, -3, 0, 0] }

const getBuildBumnanReadyPolicyValueRow = (yearEndPolicy: YearEndPolicyValue, pensionType: string) => {
  if (isAnnuallyPensionType(pensionType)) {
    return getToggles().ENABLE_HIDING_RIDER_PREMIUM_AND_TOTAL_PREMIUM
      ? buildBumnanReadyAnnuallyPolicyValueRowWithNoRiderAndTotalPremium(yearEndPolicy)
      : buildBumnanReadyAnnuallyPolicyValueRow(yearEndPolicy)
  } else {
    return getToggles().ENABLE_HIDING_RIDER_PREMIUM_AND_TOTAL_PREMIUM
      ? buildBumnanReadyMonthlyPolicyValueRowWithNoRiderAndTotalPremium(yearEndPolicy)
      : buildBumnanReadyMonthlyPolicyValueRow(yearEndPolicy)
  }
}

const buildBumnanReadyMonthlyPolicyValueRow = (yearEndPolicy: YearEndPolicyValue) => [
  {
    text: Number(yearEndPolicy.age.value) - 1,
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.yearEndPolicy,
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.age.value > 60 ? yearEndPolicy.monthEndPolicy : '',
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.annualBasicPremium === 0 ? '-' : formatNumber(yearEndPolicy.annualBasicPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.annualRiderPremium === 0 ? '-' : formatNumber(yearEndPolicy.annualRiderPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.annualTotalPremium === 0 ? '-' : formatNumber(yearEndPolicy.annualTotalPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.cumulativeBasicPremium === 0 ? '-' : formatNumber(yearEndPolicy.cumulativeBasicPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text:
      yearEndPolicy.surrenderCash === 0
        ? '-'
        : // $$FlowFixMe
          formatNumber(yearEndPolicy.surrenderCash, 0, true),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.deathBenefit === 0 ? '-' : formatNumber(yearEndPolicy.deathBenefit, 2, true),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    // $$FlowFixMe
    text: formatFromZeroToDash(formatNumber(yearEndPolicy.pensionPerYear, 2)),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    // $$FlowFixMe
    text: formatFromZeroToDash(formatNumber(yearEndPolicy.cumulativePersionAmount, 2)),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    // $$FlowFixMe
    text: formatFromZeroToDash(formatNumber(yearEndPolicy.pensionTotal, 2, true)),
    alignment: 'right',
    ...rowHeightConfig,
  },
]

const buildBumnanReadyMonthlyPolicyValueRowWithNoRiderAndTotalPremium = (yearEndPolicy: YearEndPolicyValue) => [
  {
    text: Number(yearEndPolicy.age.value) - 1,
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.yearEndPolicy,
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.age.value > 60 ? yearEndPolicy.monthEndPolicy : '',
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.annualBasicPremium === 0 ? '-' : formatNumber(yearEndPolicy.annualBasicPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.cumulativeBasicPremium === 0 ? '-' : formatNumber(yearEndPolicy.cumulativeBasicPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text:
      yearEndPolicy.surrenderCash === 0
        ? '-'
        : // $$FlowFixMe
          formatNumber(yearEndPolicy.surrenderCash, 0, true),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.deathBenefit === 0 ? '-' : formatNumber(yearEndPolicy.deathBenefit, 2, true),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    // $$FlowFixMe
    text: formatFromZeroToDash(formatNumber(yearEndPolicy.pensionPerYear, 2)),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    // $$FlowFixMe
    text: formatFromZeroToDash(formatNumber(yearEndPolicy.cumulativePersionAmount, 2)),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    // $$FlowFixMe
    text: formatFromZeroToDash(formatNumber(yearEndPolicy.pensionTotal, 2, true)),
    alignment: 'right',
    ...rowHeightConfig,
  },
]

const buildBumnanReadyAnnuallyPolicyValueRow = (yearEndPolicy: YearEndPolicyValue) => [
  {
    text: Number(yearEndPolicy.age.value) - 1,
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.yearEndPolicy,
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.annualBasicPremium === 0 ? '-' : formatNumber(yearEndPolicy.annualBasicPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.annualRiderPremium === 0 ? '-' : formatNumber(yearEndPolicy.annualRiderPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.annualTotalPremium === 0 ? '-' : formatNumber(yearEndPolicy.annualTotalPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.cumulativeBasicPremium === 0 ? '-' : formatNumber(yearEndPolicy.cumulativeBasicPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text:
      yearEndPolicy.surrenderCash === 0
        ? '-'
        : // $$FlowFixMe
          formatNumber(yearEndPolicy.surrenderCash, 0, true),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.deathBenefit === 0 ? '-' : formatNumber(yearEndPolicy.deathBenefit, 2, true),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    // $$FlowFixMe
    text: formatFromZeroToDash(formatNumber(yearEndPolicy.pensionPerYear, 2)),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    // $$FlowFixMe
    text: formatFromZeroToDash(formatNumber(yearEndPolicy.cumulativePersionAmount, 2)),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    // $$FlowFixMe
    text: formatFromZeroToDash(formatNumber(yearEndPolicy.pensionTotal, 2, true)),
    alignment: 'right',
    ...rowHeightConfig,
  },
]

const buildBumnanReadyAnnuallyPolicyValueRowWithNoRiderAndTotalPremium = (yearEndPolicy: YearEndPolicyValue) => [
  {
    text: Number(yearEndPolicy.age.value) - 1,
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.yearEndPolicy,
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.annualBasicPremium === 0 ? '-' : formatNumber(yearEndPolicy.annualBasicPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.cumulativeBasicPremium === 0 ? '-' : formatNumber(yearEndPolicy.cumulativeBasicPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text:
      yearEndPolicy.surrenderCash === 0
        ? '-'
        : // $$FlowFixMe
          formatNumber(yearEndPolicy.surrenderCash, 0, true),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.deathBenefit === 0 ? '-' : formatNumber(yearEndPolicy.deathBenefit, 2, true),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    // $$FlowFixMe
    text: formatFromZeroToDash(formatNumber(yearEndPolicy.pensionPerYear, 2)),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    // $$FlowFixMe
    text: formatFromZeroToDash(formatNumber(yearEndPolicy.cumulativePersionAmount, 2)),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    // $$FlowFixMe
    text: formatFromZeroToDash(formatNumber(yearEndPolicy.pensionTotal, 2, true)),
    alignment: 'right',
    ...rowHeightConfig,
  },
]

const getBuildBumnanReadyTotalPolicyValueRow = (policyValue: PolicyRetirementValue, pensionType: string) => {
  if (isAnnuallyPensionType(pensionType)) {
    return getToggles().ENABLE_HIDING_RIDER_PREMIUM_AND_TOTAL_PREMIUM
      ? buildBumnanReadyAnnuallyTotalPolicyValueRowWithNoRiderAndTotalPremium(policyValue)
      : buildBumnanReadyAnnuallyTotalPolicyValueRow(policyValue)
  } else {
    return getToggles().ENABLE_HIDING_RIDER_PREMIUM_AND_TOTAL_PREMIUM
      ? buildBumnanReadyMonthlyTotalPolicyValueRowWithNoRiderAndTotalPremium(policyValue)
      : buildBumnanReadyMonthlyTotalPolicyValueRow(policyValue)
  }
}

const getWidthBumnanReadyHeader = (pensionType: string) => {
  const widthAnnually = [25, 25, 42, 45, 42, 'auto', 50, 45, 52, 45, 45]
  const widthAnnuallyNoRiderAndTotalColumn = [25, 25, 50, 60, 55, 55, 52, 52, 52]
  const widthTableAnnually = getToggles().ENABLE_HIDING_RIDER_PREMIUM_AND_TOTAL_PREMIUM
    ? widthAnnuallyNoRiderAndTotalColumn
    : widthAnnually

  const widthMonthly = [25, 25, 15, 42, 42, 42, 'auto', 50, 40, 48, 40, 40]
  const widthMonthlyNoRiderAndTotalColumn = [25, 25, 15, 50, 60, 55, 55, 52, 52, 52]
  const widthTableMonthly = getToggles().ENABLE_HIDING_RIDER_PREMIUM_AND_TOTAL_PREMIUM
    ? widthMonthlyNoRiderAndTotalColumn
    : widthMonthly

  const isAnnuallyPension = isAnnuallyPensionType(pensionType)
  return isAnnuallyPension ? widthTableAnnually : widthTableMonthly
}

const getBuildBumnanReadyHeader = (pensionType: string) => {
  if (isAnnuallyPensionType(pensionType)) {
    return getToggles().ENABLE_HIDING_RIDER_PREMIUM_AND_TOTAL_PREMIUM
      ? buildBumnanReadyAnnuallyHeaderTableWithNoRiderAndTotalPremium()
      : buildBumnanReadyAnnuallyHeaderTable()
  } else {
    return getToggles().ENABLE_HIDING_RIDER_PREMIUM_AND_TOTAL_PREMIUM
      ? buildBumnanReadyMonthlyHeaderTableWithNoRiderAndTotalPremium()
      : buildBumnanReadyMonthlyHeaderTable()
  }
}

const buildBumnanReadyMonthlyTotalPolicyValueRow = (policyValue: PolicyRetirementValue) => [
  '',
  '',
  '',
  {
    text: policyValue.subtotalAnnualBasicPremium === 0 ? '-' : formatNumber(policyValue.subtotalAnnualBasicPremium, 2),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  {
    text: policyValue.subtotalAnnualRiderPremium === 0 ? '-' : formatNumber(policyValue.subtotalAnnualRiderPremium, 2),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  {
    text: policyValue.grandTotalPremium === 0 ? '-' : formatNumber(policyValue.grandTotalPremium, 2),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  '',
  '',
  '',
  {
    text:
      policyValue.subtotalPensionAmount === 0
        ? '-'
        : // $$FlowFixMe
          formatNumber(policyValue.subtotalPensionAmount, 2, true),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  '',
  '',
]

const buildBumnanReadyMonthlyTotalPolicyValueRowWithNoRiderAndTotalPremium = (policyValue: PolicyRetirementValue) => [
  '',
  '',
  '',
  {
    text: policyValue.subtotalAnnualBasicPremium === 0 ? '-' : formatNumber(policyValue.subtotalAnnualBasicPremium, 2),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  '',
  '',
  '',
  {
    text:
      policyValue.subtotalPensionAmount === 0
        ? '-'
        : // $$FlowFixMe
          formatNumber(policyValue.subtotalPensionAmount, 2, true),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  '',
  '',
]

const buildBumnanReadyAnnuallyTotalPolicyValueRow = (policyValue: PolicyRetirementValue) => [
  '',
  '',
  {
    text: policyValue.subtotalAnnualBasicPremium === 0 ? '-' : formatNumber(policyValue.subtotalAnnualBasicPremium, 2),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  {
    text: policyValue.subtotalAnnualRiderPremium === 0 ? '-' : formatNumber(policyValue.subtotalAnnualRiderPremium, 2),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  {
    text: policyValue.grandTotalPremium === 0 ? '-' : formatNumber(policyValue.grandTotalPremium, 2),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  '',
  '',
  '',
  {
    text:
      policyValue.subtotalPensionAmount === 0
        ? '-'
        : // $$FlowFixMe
          formatNumber(policyValue.subtotalPensionAmount, 2, true),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  '',
  '',
]

const buildBumnanReadyAnnuallyTotalPolicyValueRowWithNoRiderAndTotalPremium = (policyValue: PolicyRetirementValue) => [
  '',
  '',
  {
    text: policyValue.subtotalAnnualBasicPremium === 0 ? '-' : formatNumber(policyValue.subtotalAnnualBasicPremium, 2),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  '',
  '',
  '',
  {
    text:
      policyValue.subtotalPensionAmount === 0
        ? '-'
        : // $$FlowFixMe
          formatNumber(policyValue.subtotalPensionAmount, 2, true),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  '',
  '',
]

let disclaimer = {
  stack: [
    `${MESSAGES.NOTE}:`,
    MESSAGES.RETIREMENT_POLICY_VALUE_NOTE_1,
    MESSAGES.RETIREMENT_POLICY_VALUE_NOTE_2,
    MESSAGES.RETIREMENT_POLICY_VALUE_NOTE_3,
  ],
  style: 'disclaimer',
  pageBreak: '',
}

export const RetirementShowMessageUnderPdfTable = ({ policyValue, basicPlan }: PolicyValueTableProps) => {
  const message = _getMessageUnderiShieldPolicyValueTable({
    policyValue,
    basicPlan,
  })
  if (message) {
    return {
      stack: [{ text: message, color: 'red', fontSize: 11, alignment: 'center' }],
    }
  } else return { text: '' }
}

const buildBumnanReadyPolicyValueTable = (policyValue: PolicyRetirementValue, pensionType: string) => {
  const policyValueTables = []
  let rowsPerPage =
    pensionType === 'monthly'
      ? [46, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48, 48]
      : [48, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50, 50]

  if (rowsPerPage[0] === policyValue.yearEndPolicyValues.length) {
    rowsPerPage[0] = rowsPerPage[0] - 1
  }
  const pageAmount = calculatePageAmountPolicyValueTable(policyValue, rowsPerPage)
  _.times(pageAmount, (currentPage) => {
    policyValueTables.push({
      table: {
        headerRows: 1,
        widths: getWidthBumnanReadyHeader(pensionType),
        body: [
          ...getBuildBumnanReadyHeader(pensionType),
          ...policyValue.yearEndPolicyValues
            .slice(
              currentPage * rowsPerPage[currentPage > 0 ? currentPage - 1 : 0],
              rowsPerPage[currentPage] * (currentPage + 1)
            )
            .map((p) => getBuildBumnanReadyPolicyValueRow(p, pensionType)),
        ],
      },
      layout: 'lightBorder',
      style: 'table25PL',
    })
  })

  _.last(policyValueTables).table.body.push(getBuildBumnanReadyTotalPolicyValueRow(policyValue, pensionType))
  disclaimer = {
    stack: [
      `${MESSAGES.NOTE}:`,
      MESSAGES.RETIREMENT_POLICY_VALUE_NOTE_1,
      MESSAGES.LIFE_RETIREMENT_POLICY_VALUE_NOTE_2,
      MESSAGES.LIFE_RETIREMENT_POLICY_VALUE_NOTE_3,
    ],
    style: 'disclaimer',
    pageBreak: '',
    unbreakable: true,
  }
  setLayoutDisclaimer(policyValueTables, rowsPerPage, disclaimer)
  return policyValueTables
}

export const setLayoutDisclaimer = (
  policyValueTables: Array<Object>,
  rowsPerPage: Array<number>,
  // $$FlowFixMe
  disclaimer
) => {
  const headerRow = 3
  const rowDisclaimer = 6
  const maxRowsDataforTable = 42
  const amountPage = policyValueTables.length ? policyValueTables.length : 1
  const maxofPage = rowsPerPage[amountPage - 1] + headerRow
  const rowsofPage = policyValueTables[amountPage - 1].table.body.length
  const isPageBreakforFirstPage =
    amountPage === 1 && policyValueTables[amountPage - 1].table.body.length > maxRowsDataforTable
  if (rowsofPage >= maxofPage - rowDisclaimer || isPageBreakforFirstPage) {
    disclaimer.pageBreak = 'before'
  } else {
    disclaimer.pageBreak = ''
  }
}

const buildBumnanReadyMonthlyHeaderTable = () => {
  return [
    [
      {
        text: CONSTANTS.AGE,
        rowSpan: 3,
        style: 'tableHeader',
        margin: [0, 26],
      },
      {
        text: MESSAGES.POLICY_VALUE_TABLE_INSURANCE_YEAR,
        rowSpan: 3,
        style: 'tableHeader',
        margin: [0, 26],
      },
      {
        text: MESSAGES.RETIREMENT_POLICY_VALUE_MONTHLY,
        rowSpan: 3,
        style: 'tableHeader',
        margin: [0, 26],
      },
      {
        text: MESSAGES.PREMIUM_PER_YEAR,
        colSpan: 3,
        style: 'tableHeader',
        margin: [0, 10],
      },
      { text: '' },
      { text: '' },
      {
        text: _breakLine(MESSAGES.CUMULATIVE_PREMIUM),
        rowSpan: 3,
        style: 'tableHeader',
        margin: [0, 24],
      },
      {
        text: MESSAGES.RETIREMENT_POLICY_VALUE_BEFORE_PENSION_WITH_NOTE,
        colSpan: 2,
        style: 'tableHeader',
        margin: [0, 10],
      },
      { text: '' },
      {
        text: MESSAGES.RETIREMENT_POLICY_VALUE_ALLOW_PENSION_NOTE_2,
        colSpan: 3,
        style: 'tableHeader',
        margin: [0, 10],
      },
      { text: '' },
      { text: '' },
    ],
    [
      '',
      '',
      '',
      {
        text: MESSAGES.BASIC_PLAN,
        style: 'tableHeader',
        rowSpan: 2,
        margin: [0, 15],
      },
      {
        text: MESSAGES.RIDER_1_STAR,
        style: 'tableHeader',
        rowSpan: 2,
        margin: [0, 15],
      },
      {
        text: MESSAGES.TOTAL,
        style: 'tableHeader',
        rowSpan: 2,
        margin: [0, 15],
      },
      '',
      {
        text: MESSAGES.RETIREMENT_SURRENDER_VALUE_END_OF_YEAR_NOTE_PDF,
        style: 'tableHeader',
        rowSpan: 2,
        margin: [0, 15],
      },
      {
        text: _breakLine(MESSAGES.DEATH_BENEFIT),
        rowSpan: 2,
        margin: [0, 15],
        style: 'tableHeader',
      },
      {
        text: MESSAGES.RETIREMENT_POLICY_VALUE_PENSION_MONTHLY_PDF,
        rowSpan: 2,
        margin: [0, 15],
        style: 'tableHeader',
      },
      {
        text: MESSAGES.RETIREMENT_POLICY_VALUE_CUMULATIVE_PENSION_AMOUNT,
        rowSpan: 2,
        margin: [0, 15],
        style: 'tableHeader',
      },
      {
        text: _breakLine(MESSAGES.DEATH_BENEFIT),
        rowSpan: 2,
        margin: [0, 15],
        style: 'tableHeader',
      },
    ],
    ['', '', '', '', '', '', '', '', '', '', ''],
  ]
}

const buildBumnanReadyMonthlyHeaderTableWithNoRiderAndTotalPremium = () => {
  return [
    [
      {
        text: CONSTANTS.AGE,
        rowSpan: 3,
        style: 'tableHeader',
        margin: [0, 26],
      },
      {
        text: MESSAGES.POLICY_VALUE_TABLE_INSURANCE_YEAR,
        rowSpan: 3,
        style: 'tableHeader',
        margin: [0, 26],
      },
      {
        text: MESSAGES.RETIREMENT_POLICY_VALUE_MONTHLY,
        rowSpan: 3,
        style: 'tableHeader',
        margin: [0, 26],
      },
      {
        text: MESSAGES.BASIC_PREMIUM_PER_YEAR,
        rowSpan: 3,
        style: 'tableHeader',
        margin: [0, 24],
      },
      {
        text: _breakLine(MESSAGES.CUMULATIVE_PREMIUM),
        rowSpan: 3,
        style: 'tableHeader',
        margin: [0, 24],
      },
      {
        text: MESSAGES.BENEFIT_BEFORE_GET_PENSION,
        colSpan: 2,
        style: 'tableHeader',
        margin: [0, 10],
      },
      { text: '' },
      {
        text: MESSAGES.RETIREMENT_POLICY_VALUE_ALLOW_PENSION_WITHOUT_NOTE,
        colSpan: 3,
        style: 'tableHeader',
        margin: [0, 10],
      },
      { text: '' },
      { text: '' },
    ],
    [
      '',
      '',
      '',
      '',
      '',
      {
        text: MESSAGES.RETIREMENT_SURRENDER_VALUE_END_OF_YEAR,
        style: 'tableHeader',
        rowSpan: 2,
        margin: [0, 15],
      },
      {
        text: _breakLine(MESSAGES.DEATH_BENEFIT),
        rowSpan: 2,
        margin: [0, 15],
        style: 'tableHeader',
      },
      {
        text: MESSAGES.RETIREMENT_POLICY_VALUE_PENSION_MONTHLY_PDF,
        rowSpan: 2,
        margin: [0, 15],
        style: 'tableHeader',
      },
      {
        text: MESSAGES.RETIREMENT_POLICY_VALUE_CUMULATIVE_PENSION_AMOUNT,
        rowSpan: 2,
        margin: [0, 15],
        style: 'tableHeader',
      },
      {
        text: _breakLine(MESSAGES.DEATH_BENEFIT),
        rowSpan: 2,
        margin: [0, 15],
        style: 'tableHeader',
      },
    ],
    ['', '', '', '', '', '', '', '', '', ''],
  ]
}

const buildBumnanReadyAnnuallyHeaderTable = () => {
  return [
    [
      {
        text: CONSTANTS.AGE,
        rowSpan: 3,
        style: 'tableHeader',
        margin: [0, 26],
      },
      {
        text: MESSAGES.POLICY_VALUE_TABLE_INSURANCE_YEAR,
        rowSpan: 3,
        style: 'tableHeader',
        margin: [0, 26],
      },
      {
        text: MESSAGES.PREMIUM_PER_YEAR,
        colSpan: 3,
        style: 'tableHeader',
        margin: [0, 10],
      },
      { text: '' },
      { text: '' },
      {
        text: _breakLine(MESSAGES.CUMULATIVE_PREMIUM),
        rowSpan: 3,
        style: 'tableHeader',
        margin: [0, 20],
      },
      {
        text: MESSAGES.RETIREMENT_POLICY_VALUE_BEFORE_PENSION_WITH_NOTE,
        colSpan: 2,
        style: 'tableHeader',
        margin: [0, 10],
      },
      { text: '' },
      {
        text: MESSAGES.RETIREMENT_POLICY_VALUE_ALLOW_PENSION_NOTE_2,
        colSpan: 3,
        style: 'tableHeader',
        margin: [0, 10],
      },
      { text: '' },
      { text: '' },
    ],
    [
      '',
      '',
      {
        text: MESSAGES.BASIC_PLAN,
        style: 'tableHeader',
        rowSpan: 2,
        margin: [0, 12],
      },
      {
        text: MESSAGES.RIDER_1_STAR,
        style: 'tableHeader',
        rowSpan: 2,
        margin: [0, 12],
      },
      {
        text: MESSAGES.TOTAL,
        style: 'tableHeader',
        rowSpan: 2,
        margin: [0, 12],
      },
      '',
      {
        text: MESSAGES.RETIREMENT_SURRENDER_VALUE_END_OF_YEAR_NOTE_PDF,
        style: 'tableHeader',
        rowSpan: 2,
        margin: [0, 10],
      },
      {
        text: _breakLine(MESSAGES.DEATH_BENEFIT),
        rowSpan: 2,
        margin: [0, 10],
        style: 'tableHeader',
      },
      {
        text: MESSAGES.RETIREMENT_POLICY_VALUE_PENSION_PDF,
        rowSpan: 2,
        margin: [0, 10],
        style: 'tableHeader',
      },
      {
        text: MESSAGES.RETIREMENT_POLICY_VALUE_CUMULATIVE_PENSION_AMOUNT,
        rowSpan: 2,
        margin: [0, 10],
        style: 'tableHeader',
      },
      {
        text: _breakLine(MESSAGES.DEATH_BENEFIT),
        rowSpan: 2,
        margin: [0, 10],
        style: 'tableHeader',
      },
    ],
    ['', '', '', '', '', '', '', '', '', ''],
  ]
}

const buildBumnanReadyAnnuallyHeaderTableWithNoRiderAndTotalPremium = () => {
  return [
    [
      {
        text: CONSTANTS.AGE,
        rowSpan: 3,
        style: 'tableHeader',
        margin: [0, 26],
      },
      {
        text: MESSAGES.POLICY_VALUE_TABLE_INSURANCE_YEAR,
        rowSpan: 3,
        style: 'tableHeader',
        margin: [0, 26],
      },
      {
        text: MESSAGES.BASIC_PREMIUM_PER_YEAR,
        rowSpan: 3,
        style: 'tableHeader',
        margin: [0, 20],
      },
      {
        text: _breakLine(MESSAGES.CUMULATIVE_PREMIUM),
        rowSpan: 3,
        style: 'tableHeader',
        margin: [0, 20],
      },
      {
        text: MESSAGES.BENEFIT_BEFORE_GET_PENSION,
        colSpan: 2,
        style: 'tableHeader',
        margin: [0, 10],
      },
      { text: '' },
      {
        text: MESSAGES.RETIREMENT_POLICY_VALUE_ALLOW_PENSION_WITHOUT_NOTE,
        colSpan: 3,
        style: 'tableHeader',
        margin: [0, 10],
      },
      { text: '' },
      { text: '' },
    ],
    [
      '',
      '',
      '',
      '',
      {
        text: MESSAGES.RETIREMENT_SURRENDER_VALUE_END_OF_YEAR,
        style: 'tableHeader',
        rowSpan: 2,
        margin: [0, 10],
      },
      {
        text: _breakLine(MESSAGES.DEATH_BENEFIT),
        rowSpan: 2,
        margin: [0, 10],
        style: 'tableHeader',
      },
      {
        text: MESSAGES.RETIREMENT_POLICY_VALUE_PENSION_PDF,
        rowSpan: 2,
        margin: [0, 10],
        style: 'tableHeader',
      },
      {
        text: MESSAGES.RETIREMENT_POLICY_VALUE_CUMULATIVE_PENSION_AMOUNT,
        rowSpan: 2,
        margin: [0, 10],
        style: 'tableHeader',
      },
      {
        text: _breakLine(MESSAGES.DEATH_BENEFIT),
        rowSpan: 2,
        margin: [0, 10],
        style: 'tableHeader',
      },
    ],
    ['', '', '', '', '', '', '', '', ''],
  ]
}

export const buildPolicyValueTable = (
  policyValue: PolicyValue,
  pensionType: string,
  basicPlan: string
  // $$FlowFixMe
) => buildBumnanReadyPolicyValueTable(policyValue, pensionType)

export const policyValueTableSection = ({ policyValue, basicPlan, pensionType }: PolicyValueRetirementTableProps) => [
  {
    text: Mustache.render(MESSAGES.RETIREMENT_BUMNAN_READY_POLICY_VALUE_TABLE, {
      pensionType: getPensionTypeString(pensionType),
    }),
    style: 'title',
    pageBreak: 'before',
    alignment: 'center',
  },
  ...buildPolicyValueTable(policyValue, pensionType, basicPlan),
  RetirementShowMessageUnderPdfTable({ policyValue, basicPlan }),
  disclaimer,
]
