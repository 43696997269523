import { requestWrapper } from 'e-submission/domain/data'

export const getVerifyCitizenId = (user, citizenId) => {
  return requestWrapper.getRequest(`ekyc/${citizenId}/verify-duplicate`, user).then(({ data }) => data)
}
export const createEkycCase = (user, payload) => {
  return requestWrapper.postRequest('ekyc', user, payload).then(({ data }) => data)
}

export const verifyByProprietorId = (user, proprietorId) => {
  return requestWrapper.getRequest(`ekyc/proprietors/${proprietorId}/verifications`, user).then(({ data }) => data)
}

export const saveAttachmentFromURL = (user, payload) => {
  return requestWrapper.postRequest('ekyc/attachment', user, payload).then(({ data }) => data)
}

export const resendEkycLink = (user, payload) => {
  return requestWrapper.postRequest('ekyc/resend', user, payload).then(({ data }) => data)
}

export const retrieveEkycReport = (user, payload) => {
  return requestWrapper.postRequest(`ekyc/ekyc-pdf-report`, user, payload).then(({ data }) => data)
}
export const generatePdfReport = (user, caseId) => {
  return requestWrapper.getRequest(`ekyc/${caseId}/pdf-report`, user).then(({ data }) => data)
}

export const submitEkycCase = (user, caseId) => {
  return requestWrapper.patchRequest(`ekyc/${caseId}/submit`, user).then(({ data }) => data)
}

export const updatePolicyNumber = (user, payload) => {
  return requestWrapper.patchRequest(`ekyc/policies`, user, payload).then(({ data }) => data)
}

export const updateVerificationRef = (user, proprietorId, payload) => {
  return requestWrapper
    .postRequest(`ekyc/proprietors/${proprietorId}/verifications`, user, payload)
    .then(({ data }) => data)
}
