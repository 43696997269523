// @flow

import type { AppState } from 'quick-quote/reducers'
import { connect } from 'react-redux'
import { getTotalQuestionnaire } from '../selectors'
import { resetAnsweredQuestion } from '../actions'
import { Button } from 'reactstrap'
import MESSAGES from 'core/data-model/constants/messages'
import styled from 'styled-components'

type Props = {
  totalQuestionnaire: number,
  toggleModal: () => void,
  resetAnsweredQuestion: () => void,
}

const RiskQuestionWrapper = styled.div`
  text-align: center;
  margin: 30px 0;
`

export const RiskAssessment = ({ totalQuestionnaire, toggleModal, resetAnsweredQuestion }: Props) => {
  return (
    <RiskQuestionWrapper id="risk-question">
      <p className="promtp-info">{MESSAGES.RPUL_RISK_QUESTIONNAIRE_PROMPT_LINE_01}</p>
      <p className="promtp-info">{MESSAGES.RPUL_RISK_QUESTIONNAIRE_PROMPT_LINE_02}</p>
      <div className="promtp-info">
        <Button
          id="do-risk-assessment"
          color="primary"
          onClick={() => {
            resetAnsweredQuestion()
            toggleModal()
          }}
        >
          ตอบคำถาม {totalQuestionnaire} ข้อ
        </Button>
      </div>
    </RiskQuestionWrapper>
  )
}

const mapStateToProps = (state: AppState) => {
  return {
    totalQuestionnaire: getTotalQuestionnaire(state),
  }
}

const RiskAssessmentContainer = connect(mapStateToProps, {
  resetAnsweredQuestion: resetAnsweredQuestion,
})(RiskAssessment)

export default RiskAssessmentContainer
