// @flow

import type { PolicyOwner } from 'core/data-model/policy-owner'

export const SELECT_POLICY_OWNER = 'SELECT_POLICY_OWNER'
export type SelectPolicyOwner = {
  type: 'SELECT_POLICY_OWNER',
  payload: PolicyOwner,
}
export const selectPolicyOwner = (policyOwner: PolicyOwner): SelectPolicyOwner => ({
  type: SELECT_POLICY_OWNER,
  payload: policyOwner,
})

export const VALIDATE_SELECT_POLICY_OWNER = 'VALIDATE_SELECT_POLICY_OWNER'
export type ValidateSelectPolicyOwner = {
  type: 'VALIDATE_SELECT_POLICY_OWNER',
  payload: string,
}
export const validateSelectPolicyOwner = (policyOwner: PolicyOwner): ValidateSelectPolicyOwner => ({
  type: VALIDATE_SELECT_POLICY_OWNER,
  payload: policyOwner,
})

export const EDIT_MATURITY_ID_CARD = 'EDIT_MATURITY_ID_CARD'
export type EditMaturityIDCard = {
  type: 'EDIT_MATURITY_ID_CARD',
  payload: number,
}
export const editMaturityIDCard = (maturityIDCard: number): EditMaturityIDCard => ({
  type: EDIT_MATURITY_ID_CARD,
  payload: maturityIDCard,
})

export const MATURITY_VALIDATE = 'MATURITY_VALIDATE'
export type MaturityValidate = {
  type: 'MATURITY_VALIDATE',
  payload: number,
}
export const maturityValidate = (currentMaturityIDCard: number): MaturityValidate => ({
  type: MATURITY_VALIDATE,
  payload: currentMaturityIDCard,
})

export const SET_VALID_MATURITY_ID_CARD = 'SET_VALID_MATURITY_ID_CARD'
export type SetValidMaturityIDCard = {
  type: 'SET_VALID_MATURITY_ID_CARD',
  payload: number,
}
export const setValidMaturityIDCard = (validIDCard: number): SetValidMaturityIDCard => ({
  type: SET_VALID_MATURITY_ID_CARD,
  payload: validIDCard,
})

export const SET_ERROR_VALIDATE_MATURITY_ID_CARD = 'SET_ERROR_VALIDATE_MATURITY_ID_CARD'
export type SetErrorValidateMaturityIDCard = {
  type: 'SET_ERROR_VALIDATE_MATURITY_ID_CARD',
  payload: string,
}
export const setErrorValidateMaturityIDCard = (errorMsg: string): SetErrorValidateMaturityIDCard => ({
  type: SET_ERROR_VALIDATE_MATURITY_ID_CARD,
  payload: errorMsg,
})

export const RESET_ERROR_VALIDATE_MATURITY_ID_CARD = 'RESET_ERROR_VALIDATE_MATURITY_ID_CARD'
export type ResetErrorValidateMaturityIDCard = {
  type: 'RESET_ERROR_VALIDATE_MATURITY_ID_CARD',
}
export const resetErrorValidateMaturityIDCard = (): ResetErrorValidateMaturityIDCard => ({
  type: RESET_ERROR_VALIDATE_MATURITY_ID_CARD,
})

export const SET_ERROR_VALIDATE_MATURITY_STATUS = 'SET_ERROR_VALIDATE_MATURITY_STATUS'
export type SetErrorValidateMaturityStatus = {
  type: 'SET_ERROR_VALIDATE_MATURITY_STATUS',
  payload: string,
}
export const setErrorValidateMaturityStatus = (errorMsg: string): SetErrorValidateMaturityStatus => ({
  type: SET_ERROR_VALIDATE_MATURITY_STATUS,
  payload: errorMsg,
})

export const SET_ERROR_VALIDATE_SELECT_POLICY_OWNER = 'SET_ERROR_VALIDATE_SELECT_POLICY_OWNER'
export type SetErrorValidateSelectPolicyOwner = {
  type: 'SET_ERROR_VALIDATE_SELECT_POLICY_OWNER',
  payload: string,
}
export const setErrorValidateSelectPolicyOwner = (errorMsg: string): SetErrorValidateSelectPolicyOwner => ({
  type: SET_ERROR_VALIDATE_SELECT_POLICY_OWNER,
  payload: errorMsg,
})

export const RESET_ERROR_VALIDATE_SELECT_POLICY_OWNER = 'RESET_ERROR_VALIDATE_SELECT_POLICY_OWNER'
export type ResetErrorValidateSelectPolicyOwner = {
  type: 'RESET_ERROR_VALIDATE_SELECT_POLICY_OWNER',
}
export const resetErrorValidateSelectPolicyOwner = (): ResetErrorValidateSelectPolicyOwner => ({
  type: RESET_ERROR_VALIDATE_SELECT_POLICY_OWNER,
})

export const UPDATE_MATURITY_VALIDATE = 'UPDATE_MATURITY_VALIDATE'
export type UpdateMaturityValidate = {
  type: 'UPDATE_MATURITY_VALIDATE',
  payload: MaturityValidateStatus,
}
type MaturityValidateStatus = {
  maturityStatus: boolean,
  isBuyForOwner: boolean,
}
export const updateMaturityValidate = (maturityValidate: MaturityValidateStatus): UpdateMaturityValidate => ({
  type: UPDATE_MATURITY_VALIDATE,
  payload: maturityValidate,
})

export const RESET_QUOTE_FORM_AFTER_MATURITY_VALIDATE = 'RESET_QUOTE_FORM_AFTER_MATURITY_VALIDATE'
export type ResetQuoteFormAfterMaturityValidate = {
  type: 'RESET_QUOTE_FORM_AFTER_MATURITY_VALIDATE',
}
export const resetQuoteFormAfterMaturityValidate = (): ResetQuoteFormAfterMaturityValidate => ({
  type: RESET_QUOTE_FORM_AFTER_MATURITY_VALIDATE,
})

export const UPDATE_SUM_ASSURED_AFTER_MATURITY_VALIDATE = 'UPDATE_SUM_ASSURED_AFTER_MATURITY_VALIDATE'
export type UpdateSumAssuredAfterMaturityValidate = {
  type: 'UPDATE_SUM_ASSURED_AFTER_MATURITY_VALIDATE',
  payload: number,
}
export const updateSumAssuredAfterMaturityValidate = (sumAssured: number): UpdateSumAssuredAfterMaturityValidate => ({
  type: UPDATE_SUM_ASSURED_AFTER_MATURITY_VALIDATE,
  payload: sumAssured,
})
