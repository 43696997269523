// @flow

import { connect } from 'react-redux'

import ShareButton from './share-button'

import MESSAGES from 'core/data-model/constants/messages'
import BIMESSAGES from 'core/data-model/constants/bi-messages'

import { prepareQuickQuote, shareQuickQuote } from 'quick-quote/product-common/benefit-illustration/actions'

import { getToggles } from 'quick-quote/feature-toggles'
import { isQuickQuotePath } from 'quick-quote/selectors'
import type { AppState } from 'quick-quote/reducers'
import { getUserAgentType } from 'identity/selectors'
import values from 'core/data-model/constants/values'

type ShareButtonSectionProps = {
  shareQuickQuote: (*) => void,
  prepareQuickQuote: (*) => void,
  currentUserRole: string,
}

const ShareButtonSection = ({ shareQuickQuote, prepareQuickQuote, currentUserRole }: ShareButtonSectionProps) => {
  const isShareAsFullBI = getToggles().ENABLE_QQ_SHARE_AS_FULL_BI
  const isBanca = currentUserRole === values.AGENT_TYPE_LB
  const submitAction =
    isQuickQuotePath() && getToggles().ENABLE_REVISE_FNA && !isBanca ? shareQuickQuote : prepareQuickQuote

  return (
    <div>
      <ShareButton
        biType={isShareAsFullBI ? BIMESSAGES.BI_TYPE_FULL : BIMESSAGES.BI_TYPE_MINI}
        buttonLabel={
          getToggles().ENABLE_SAME_WORDING_ON_SHARE_BI_BUTTON ? MESSAGES.DOWNLOAD_SALE_PAPER : MESSAGES.SHARE
        }
        submitAction={submitAction}
      />
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  currentUserRole: getUserAgentType(state),
})

export default connect(mapStateToProps, { shareQuickQuote: shareQuickQuote, prepareQuickQuote: prepareQuickQuote })(
  ShareButtonSection
)
