// @flow
import Mustache from 'mustache'
import riderMessages from 'core/data-model/constants/validation-messages'
import { formatNumber } from 'core/service/lib/number-format'
import type { SumAssuredTiersRule } from 'core/data-model/rider/rules'
import type { RiderEligibilities } from 'core/data-model/rider'
import type { ValidationMessage } from 'core/data-model/validation'

export const invalidSumAssuredRule = (
  rule: SumAssuredTiersRule,
  eligibilities: RiderEligibilities
): ValidationMessage => {
  let errorMessage = ''

  if (eligibilities.basicSumAssured > 0 && eligibilities.basicSumAssured <= rule.validSumassured) {
    errorMessage = Mustache.render(riderMessages.RULE_RIDER_SUM_ASSURED, {
      minimumSumassured: formatNumber(rule.validSumassured),
    })
  }

  return {
    type: rule.validationType,
    message: errorMessage,
  }
}
