// @flow

import type { Age } from 'core/data-model/insured'
import type { Period } from 'core/data-model/basic-plan'

import _ from 'lodash'
import { createSelector } from 'reselect'
import { calculatePeriod } from 'core/service/basic-plan'
import { isNotNil } from 'core/service/lib/type-check'
import VALUES from 'core/data-model/constants/values'

import {
  getSelectedDisplayProductCoveragePeriod,
  getSelectedDisplayProductPaymentPeriod,
} from 'quick-quote/product-selection/selectors'

import { getSelectedRiders } from 'quick-quote/product-common/coverage-plan/selectors'

import { getAge } from 'quick-quote/insured-information/selectors'

export const getCalculatedCoveragePeriod = createSelector(
  getSelectedDisplayProductCoveragePeriod,
  getAge,
  (coveragePeriod: Period, age: Age) => {
    const actualAge = age.unit !== 'year' ? 0 : _.get(age, 'value', 0)
    return calculatePeriod(coveragePeriod, actualAge)
  }
)

export const getCalculatedPaymentPeriod = createSelector(
  getSelectedDisplayProductPaymentPeriod,
  getAge,
  (paymentPeriod: Period, age: Age) => {
    const actualAge = age.unit !== 'year' ? 0 : _.get(age, 'value', 0)
    return calculatePeriod(paymentPeriod, actualAge)
  }
)

export const getHasShowDisclaimerRiderAsterisk = createSelector(
  getSelectedRiders,
  (selectedRiders: (Rider & RiderState)[]): boolean => {
    return isNotNil(
      selectedRiders.find(
        (rider) =>
          rider.premiumStructure === VALUES.PREMIUM_NON_LEVEL &&
          !VALUES.LIST_EXCEPT_ASTERISKS_BENEFIT_SUMMARY_TABLE.includes(rider.code)
      )
    )
  }
)
