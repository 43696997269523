//@flow
// $FlowFixMe
import React from 'react'
import styled from 'styled-components'
import type { FundAllocationToggleItem } from 'core/service/investment'
import type { Tab } from '../../../../components/navigation-item'
import { FundList } from './fund-list'
import { SubFundList } from './sub-fund-list'
import Button from 'common-components/button'
import MESSAGES from 'core/data-model/constants/messages'
import VALUES from 'core/data-model/constants/values'

const SummaryContainerWrapper = styled.div`
  max-width: 880px;
  margin: 0 auto 20px auto;
  justify-content: center;
  background-color: #ffffff;
`

const RPPHeader = styled.div`
  width: 100%;
  background-color: ${VALUES.PRIMARY_COLOR};
  display: flex;
`

const TextHeader = styled.div`
  color: #ffffff;
  font-weight: normal;
  font-size: 1rem;
  padding: 10px 0 10px 10px;
`

const FundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px;
`

const OverRiskWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px;
`

const ForeignRiskWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 15px;
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const TextTitle = styled.div`
  font-size: 0.9rem;
  font-weight: normal;
  color: ${VALUES.PRIMARY_COLOR};
  padding-right: 0.5rem;

  @media (max-width: 535px) {
    padding-right: 0.1rem;
  }
`

const SpanTextSize = styled.span`
  font-size: 1rem;
  font-weight: bold;
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 15px;
`

const ButtonStyled = styled(Button)`
  color: ${VALUES.PRIMARY_COLOR} !important;
  background-color: #e1e9f7 !important;
  border-style: dashed !important;
  border-radius: 5px !important;
  &:after {
    background: #e1e9f7 !important;
  }
`

const OverrideCustomControlStyled = styled.label`
  margin-right: 0 !important;
`

type FundSummaryProps = {
  allocations: FundAllocationToggleItem[],
  navTabList: Tab[],
  hasValidEntryComponent: boolean,
  history: Object,
}

export const getOverRiskFund = (fund) => {
  return fund.isOverRisk === true
}

export const getForeignFund = (fund) => {
  return fund.isForeign === true
}

export class FundSummaryBlock extends React.Component<*> {
  render() {
    const {
      allocations,
      resetRppAcceptFundAllocation,
      isAcceptOverRisk,
      isAcceptForeign,
      rppAcceptHigherRiskInvestment,
      rppAcceptForeignInvestment,
      fundSummaryTitile,
      foreignExchangingQuestion,
    } = this.props

    const overRiskFunds = allocations.filter(getOverRiskFund)
    const foreignFunds = allocations.filter(getForeignFund)

    return (
      <div>
        <SummaryContainerWrapper>
          <RPPHeader>
            <TextHeader>{fundSummaryTitile}</TextHeader>
          </RPPHeader>
          <FundWrapper>
            <TitleWrapper>
              <TextTitle>{MESSAGES.FUND_SELECTED}</TextTitle>
              <TextTitle>{MESSAGES.FUNDING_RATIO}</TextTitle>
            </TitleWrapper>
            <FundList allFunds={allocations} />
          </FundWrapper>

          {overRiskFunds.length > 0 ? (
            <div>
              <RPPHeader>
                <TextHeader>{MESSAGES.RPP_OVER_RISK_SUMMARY_TITLE}</TextHeader>
              </RPPHeader>
              <FundWrapper>
                <SubFundList allFunds={overRiskFunds} />
              </FundWrapper>
              <OverRiskWrapper>
                <div className="form-group">
                  <OverrideCustomControlStyled
                    className="custom-control custom-checkbox"
                    htmlFor="fund-summary-overRisk"
                  >
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      name="overRiskFund-radio"
                      checked={isAcceptOverRisk}
                      id="fund-summary-overRisk"
                      onClick={rppAcceptHigherRiskInvestment}
                    />
                    <span className="custom-control-indicator" />
                    <SpanTextSize>{MESSAGES.OVER_RISK_SUMMARY_TITLE}</SpanTextSize>
                  </OverrideCustomControlStyled>
                </div>
              </OverRiskWrapper>
            </div>
          ) : null}

          {foreignFunds.length > 0 && foreignExchangingQuestion.selectedChoice !== 1 ? (
            <div>
              <RPPHeader>
                <TextHeader>{MESSAGES.RPP_FOREIGNER_RISK_SUMMARY_TITLE}</TextHeader>
              </RPPHeader>
              <FundWrapper>
                <SubFundList allFunds={foreignFunds} />
              </FundWrapper>
              <ForeignRiskWrapper>
                <div className="form-group">
                  <OverrideCustomControlStyled
                    className="custom-control custom-checkbox"
                    htmlFor="fund-summary-foreign"
                  >
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      name="foreignFund-radio"
                      checked={isAcceptForeign}
                      id="fund-summary-foreign"
                      onClick={rppAcceptForeignInvestment}
                    />
                    <span className="custom-control-indicator" />
                    <SpanTextSize>{MESSAGES.FOREIGNER_RISK_SUMMARY_TITLE}</SpanTextSize>
                  </OverrideCustomControlStyled>
                </div>
              </ForeignRiskWrapper>
            </div>
          ) : null}

          <FundWrapper>
            <ButtonWrapper>
              <ButtonStyled disabled={false} onClick={() => resetRppAcceptFundAllocation()}>
                {MESSAGES.FUND_CHANGING_BUTTON_TEXT}
              </ButtonStyled>
            </ButtonWrapper>
          </FundWrapper>
        </SummaryContainerWrapper>
      </div>
    )
  }
}

const FundSummary = ({
  allocations,
  isAcceptOverRisk,
  isAcceptForeign,
  rppAcceptHigherRiskInvestment,
  rppAcceptForeignInvestment,
  fundSummaryTitile,
  foreignExchangingQuestion,
  resetRppAcceptFundAllocation,
}: FundSummaryProps) => {
  return (
    <div id="fund-summary">
      <div className="content">
        <FundSummaryBlock
          fundSummaryTitile={fundSummaryTitile}
          allocations={allocations}
          resetRppAcceptFundAllocation={resetRppAcceptFundAllocation}
          isAcceptOverRisk={isAcceptOverRisk}
          isAcceptForeign={isAcceptForeign}
          rppAcceptHigherRiskInvestment={rppAcceptHigherRiskInvestment}
          rppAcceptForeignInvestment={rppAcceptForeignInvestment}
          foreignExchangingQuestion={foreignExchangingQuestion}
        />
      </div>
    </div>
  )
}

export default FundSummary
