import { noop, compact, get, map, has, overEvery } from 'lodash/fp'
import { previewPDF } from 'e-submission/apps/actions'
import { getAllDocument, getAllAttachment, isEDAViewFormOnly } from 'e-submission/apps/selectors'

import { createField, createSectionCreator } from 'e-submission/domain/data-model/form/utils'
import { DOCUMENT_TYPE } from 'e-submission/domain/data-model/constants'
import {
  getDocumentTypeText,
  getDocumentTypeName,
  getPolicyId,
  havePayer,
  isRemoteSelling,
  isSelectedRemoteSelling,
  isATPPaid,
  isFormTypeEDA,
} from 'e-submission/domain/data-model/form/selectors'
import { createDocumentCreator } from 'e-submission/domain/data-model/form/signatureSection'
import { isSuitablilityForm } from 'e-submission/domain/data-model/form/selectors'
import { getToggles } from 'quick-quote/feature-toggles'

export const signatureField = (prefix, person) => (app, id, section) => {
  const mergeState = (state, app, { dispatch } = {}) => {
    const policyId = getPolicyId(app)
    const isUploaded = overEvery([has(['_attachments', section.documentTypeName])])(app)
    section.isUploaded = isUploaded
    const createDocument = createDocumentCreator({
      baseId: id,
      once: true,
      policyId,
      dispatch,
      section,
      state,
      selectedRemoteSelling: isSelectedRemoteSelling(app),
    })

    const path = id.split('.')
    const document = createDocument(path, prefix, person)
    const [, documentName] = path
    let shouldDisable = false

    if (
      getToggles().ENABLE_CHANGE_PAY_IN_ATTACHMENT_STEP &&
      documentName === 'ECBR' &&
      section.documentTypeName !== undefined
    ) {
      if (!isUploaded) {
        shouldDisable = true
      }
    }

    return {
      documents: [
        {
          disabled: shouldDisable,
          ...document,
          icon: null,
          description: document.description,
          selectedRemoteSelling: isSelectedRemoteSelling(app),
        },
      ],
    }
  }

  return createField([prefix, id].join('.'), [
    {
      id: [prefix, id].join('.'),
      c: 'DocumentCard',
      p: {
        mergeState,
      },
    },
  ])
}

export const shouldEnableBrowseBankBook = (app) =>
  (getToggles().ENABLE_ALLOW_BROWSE_DDA_BANK_BOOK && isRemoteSelling(app)) ||
  getToggles().ENABLE_APPMAN_REMOTE_SELLING ||
  undefined

export const documentField = (prefix) => (app, id, section) => {
  // undefined is mandatory in order to let PhotoCard decide its default props.
  //! DA/EDA Relationship doc upload
  const isEnableBrowseFile =
    id === 'insured.BANK_BOOK' || id === 'insured.DCA_BANK_BOOK' || 'insured.DA_RELATIONSHIP'
      ? shouldEnableBrowseBankBook(app)
      : undefined

  // If ATP is submitted, disable delete button
  const isSubmitAtp = get('ATP.submitted', app) === true

  return createField([prefix, id].join('.'), [
    {
      id: [prefix, id].join('.'),
      c: 'PhotoCard',
      required: true,
      p: {
        isBrowseFile: isEnableBrowseFile,
        isDisabledDeleteBtn: isSubmitAtp,
        mergeState: (state, app) => {
          const documentType = get(id, DOCUMENT_TYPE)
          const documentTypeText = documentType.text
          const documentTypeName = documentType.name
          const isChecked = has(['_attachments', documentTypeName], app)

          let eDAorDAInfoMessage = ''
          let readOnly = false

          if (!isATPPaid(app)) {
            // Form page apply for both DA and EDA
            eDAorDAInfoMessage =
              prefix === 'EDA' ? documentType.infoMessageEDA || '' : documentType.infoMessageNewDA || ''
          } else if (prefix !== 'EDA' && !isChecked) {
            // Summary page for DA case only
            // When Document is not uploaded
            eDAorDAInfoMessage = documentType.infoMessageNewDA || ''
          }

          const isEnableEDAFeature = getToggles().ENABLE_EDA
          const documentInfoMessage =
            id === 'insured.BANK_BOOK' && isEnableEDAFeature ? eDAorDAInfoMessage : documentType.infoMessage

          const isAppliedATPonline = isEnableEDAFeature && isATPPaid(app) && isFormTypeEDA(app)
          // const onlyViewEDASummaryInForm = isAppliedATPonline && isEDAViewFormOnly(state)

          // if (onlyViewEDASummaryInForm) {
          //   readOnly = true
          // }

          return {
            value: [
              {
                icon: null,
                name: documentTypeName,
                description: documentTypeText,
                infoMessage: documentInfoMessage,
                isWarning: prefix === 'EDA',
                checked: isChecked,
                // readOnly,
              },
            ],
          }
        },
      },
    },
  ])
}

export const previewDocumentField = (insuredOrPayer) => (app, id) => {
  /**
   * First time submit screen render it will got app from List App screen which is just some fragment of application from CB's app summary view
   * So, If we have to conditionally render some field here we have to make sure that field is already query from app summary view
   */
  const attachments = getAllAttachment(app)
  const selfieDocPrefix = insuredOrPayer === 'insured' ? 'insured' : 'owner'
  const selfieDocName = `${selfieDocPrefix}-selfie`
  const selfieDoc = attachments.find((a) => a.name === selfieDocName)
  const officialDocName = `${selfieDocPrefix}-official-document`
  const officialDoc = attachments.find((a) => a.name === officialDocName)
  const sholdShowCitizenId = !getToggles().ENABLE_CITIZEN_ID_CONSENT_FORM
  const showPayer = havePayer(app)
  const signedAcknowledge = get('signedDocs.acknowledge')(app) || []
  const sholdShowCitizenIdConsent = getToggles().ENABLE_CITIZEN_ID_CONSENT_FORM
  const sholdShowCitizenIdConsentInsured =
    sholdShowCitizenIdConsent &&
    insuredOrPayer === 'insured' &&
    signedAcknowledge.includes('citizen-id-consent-insured')
  const sholdShowCitizenIdConsentPayer =
    sholdShowCitizenIdConsent && insuredOrPayer === 'payer' && signedAcknowledge.includes('citizen-id-consent-payer')
  const sholdShowPassportConsentInsured =
    sholdShowCitizenIdConsent && insuredOrPayer === 'insured' && signedAcknowledge.includes('passport-consent-insured')
  const sholdShowPassportConsentPayer =
    sholdShowCitizenIdConsent && insuredOrPayer === 'payer' && signedAcknowledge.includes('passport-consent-payer')

  const isEnableEDA = getToggles().ENABLE_EDA
  const daRelationshipDoc = attachments.find((a) => a.name === 'da-relationship-doc')
  const shouldShowRelationshipDoc = isEnableEDA && daRelationshipDoc

  return createField(
    `${id}.document.${insuredOrPayer}`,
    compact([
      sholdShowCitizenId && {
        id: `${id}.document.${insuredOrPayer}`,
        c: 'PhotoCard',
        p: {
          mergeState: (state, app) => {
            const documentTypeText = getDocumentTypeText(app, insuredOrPayer)
            const documentTypeName = getDocumentTypeName(app, insuredOrPayer)
            return {
              value: [
                {
                  name: documentTypeName,
                  description: documentTypeText,
                  readOnly: true,
                  checked: true,
                  hasBorder: 'bottom',
                },
              ],
            }
          },
        },
      },
      sholdShowCitizenIdConsentInsured && {
        id: `citizen-id-consent.insured`,
        c: 'DocumentCard',
        p: {
          mergeState: (state, app, { dispatch = noop } = {}) => {
            const policyId = getPolicyId(app)
            return {
              documents: [
                {
                  checked: true,
                  hasBorder: 'bottom',
                  readOnly: true,
                  name: DOCUMENT_TYPE.acknowledge.CITIZEN_ID_CONSENT_INSURED.name,
                  description: DOCUMENT_TYPE.acknowledge.CITIZEN_ID_CONSENT_INSURED.text,
                  onClick: () =>
                    dispatch(
                      previewPDF({
                        name: `citizen-id-consent-insured`,
                        text: DOCUMENT_TYPE.acknowledge.CITIZEN_ID_CONSENT_INSURED.text,
                        readOnly: true,
                        policyId,
                      })
                    ),
                },
              ],
            }
          },
        },
      },
      sholdShowCitizenIdConsentPayer && {
        id: `citizen-id-consent.payer`,
        c: 'DocumentCard',
        p: {
          mergeState: (state, app, { dispatch = noop } = {}) => {
            const policyId = getPolicyId(app)
            return {
              documents: [
                {
                  checked: true,
                  hasBorder: 'bottom',
                  readOnly: true,
                  name: DOCUMENT_TYPE.acknowledge.CITIZEN_ID_CONSENT_PAYER.name,
                  description: DOCUMENT_TYPE.acknowledge.CITIZEN_ID_CONSENT_PAYER.text,
                  onClick: () =>
                    dispatch(
                      previewPDF({
                        name: `citizen-id-consent-payer`,
                        text: DOCUMENT_TYPE.acknowledge.CITIZEN_ID_CONSENT_PAYER.text,
                        readOnly: true,
                        policyId,
                      })
                    ),
                },
              ],
            }
          },
        },
      },
      sholdShowPassportConsentInsured && {
        id: `passport-consent.insured`,
        c: 'DocumentCard',
        p: {
          mergeState: (state, app, { dispatch = noop } = {}) => {
            const policyId = getPolicyId(app)
            return {
              documents: [
                {
                  checked: true,
                  hasBorder: 'bottom',
                  readOnly: true,
                  name: DOCUMENT_TYPE.acknowledge.PASSPORT_CONSENT_INSURED.name,
                  description: DOCUMENT_TYPE.acknowledge.PASSPORT_CONSENT_INSURED.text,
                  onClick: () =>
                    dispatch(
                      previewPDF({
                        name: `passport-consent-insured`,
                        text: DOCUMENT_TYPE.acknowledge.PASSPORT_CONSENT_INSURED.text,
                        readOnly: true,
                        policyId,
                      })
                    ),
                },
              ],
            }
          },
        },
      },
      sholdShowPassportConsentPayer && {
        id: `passport-consent.payer`,
        c: 'DocumentCard',
        p: {
          mergeState: (state, app, { dispatch = noop } = {}) => {
            const policyId = getPolicyId(app)
            return {
              documents: [
                {
                  checked: true,
                  hasBorder: 'bottom',
                  readOnly: true,
                  name: DOCUMENT_TYPE.acknowledge.PASSPORT_CONSENT_PAYER.name,
                  description: DOCUMENT_TYPE.acknowledge.PASSPORT_CONSENT_PAYER.text,
                  onClick: () =>
                    dispatch(
                      previewPDF({
                        name: `passport-consent-payer`,
                        text: DOCUMENT_TYPE.acknowledge.PASSPORT_CONSENT_PAYER.text,
                        readOnly: true,
                        policyId,
                      })
                    ),
                },
              ],
            }
          },
        },
      },
      shouldShowRelationshipDoc && {
        id: 'da-relationship-doc',
        c: 'PhotoCard',
        p: {
          mergeState: (state, app) => {
            return {
              value: [
                {
                  name: daRelationshipDoc.name,
                  description: daRelationshipDoc.text,
                  readOnly: true,
                  checked: true,
                  hasBorder: 'bottom',
                },
              ],
            }
          },
        },
      },
      selfieDoc && {
        id: `${insuredOrPayer}.selfie`,
        c: 'PhotoCard',
        p: {
          mergeState: (state, app) => {
            return {
              value: [
                {
                  name: selfieDoc.name,
                  description: getToggles().ENABLE_TAMLO ? DOCUMENT_TYPE.acknowledge.SELFIE.text : selfieDoc.text,
                  readOnly: true,
                  checked: true,
                  hasBorder: 'bottom',
                },
              ],
            }
          },
        },
      },
      officialDoc && {
        id: `${insuredOrPayer}.official-document`,
        c: 'PhotoCard',
        p: {
          mergeState: (state, app) => {
            return {
              value: [
                {
                  name: officialDoc.name,
                  description: officialDoc.text,
                  readOnly: true,
                  checked: true,
                  hasBorder: 'bottom',
                },
              ],
            }
          },
        },
      },
    ]),
    {
      style: {
        marginBottom: 0,
      },
    }
  )
}

export const previewSuitabilityField = (banc) => (app, id) => {
  return createField(
    `${id}.document.${banc}`,
    [
      {
        id: `${id}.document.${banc}`,
        c: 'PhotoCard',
        p: {
          mergeState: (state, app) => {
            const documentTypeText = _.get(DOCUMENT_TYPE, 'banc.SUITABILITY_FORM.text')
            const documentTypeName = _.get(DOCUMENT_TYPE, 'banc.SUITABILITY_FORM.name')
            return {
              value: [
                {
                  name: documentTypeName,
                  description: documentTypeText,
                  readOnly: true,
                  checked: true,
                  hasBorder: 'bottom',
                },
              ],
            }
          },
        },
      },
    ],
    {
      style: {
        marginBottom: 0,
      },
    }
  )
}

export const getAllDocumentToBeShow = (documents, app) => {
  if (getToggles().ENABLE_CITIZEN_ID_CONSENT_FORM && !getToggles().ENABLE_EKYC_F2F) {
    // citizen-id-consent-insured must be show on top of list. So, we filter it out from here
    documents = documents
      .filter((d) => !d.name.startsWith('citizen-id-consent'))
      .filter((d) => !d.name.startsWith('passport'))
  }
  return documents
}

const previewSignatureField = (app, id, section) => {
  const createDocumentCreator = ({ policyId, dispatch, section, state }) => (document) => {
    return {
      checked: true,
      readOnly: true,
      name: document.name,
      description: document.text,
      onClick: () =>
        dispatch(
          previewPDF({
            ...document,
            readOnly: true,
            policyId,
            sectionId: section.id,
            sectionLabel: section.label,
          })
        ),
    }
  }

  const mergeState = (state, app, { dispatch = noop } = {}) => {
    const policyId = getPolicyId(app)
    const documents = getAllDocument(state)
    const documentsToBeShow = getAllDocumentToBeShow(documents, app)
    const createDocument = createDocumentCreator({
      policyId,
      dispatch,
      section,
      state,
    })

    return {
      documents: map(createDocument, documentsToBeShow),
    }
  }

  return createField(`${id}.signature`, [
    {
      id: `${id}.signature`,
      c: 'DocumentCard',
      p: {
        mergeState,
      },
    },
  ])
}

export const previewDocumentSection = createSectionCreator((app, id, section) => {
  const isShowPayerPage = havePayer(app)
  const isShowSuitabilityForm = isSuitablilityForm(app)

  return compact([
    previewDocumentField('insured')(app, 'preview'),
    isShowPayerPage && previewDocumentField('payer')(app, 'preview'),
    isShowSuitabilityForm && previewSuitabilityField('banc')(app, 'preview'),
    previewSignatureField(app, 'preview', section),
  ])
})
