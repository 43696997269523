// @flow
import { transformBenefitSummary } from 'core/service/benefit-illustration/benefit-summary'
import { buildHeaderTable } from 'core/service/pdf-generation/utils/builder'
import { formatNumber } from 'core/service/lib/number-format'
import MESSAGES from 'core/data-model/constants/bi-messages'
import type { BenefitSummary } from 'core/service/benefit-illustration'

const benefitSummaryTable = {
  title: MESSAGES.BENEFIT_SUMMARY,
  headers: [MESSAGES.BENEFIT_LIST, MESSAGES.BENEFIT_BAHT],
}

const buildBenefitSummary = (benefitSummary) => {
  let results = []
  benefitSummary.forEach((benefit) => {
    if (typeof benefit[1] === 'number') {
      results.push([MESSAGES.caseLabelMapping[benefit[0]], { text: formatNumber(benefit[1]), style: 'tableValue' }])
    } else {
      results.push([
        [
          MESSAGES.caseLabelMapping[benefit[0]],
          {
            ul: benefit[1].map((b) => MESSAGES.causeLabelMapping[b[0]]),
          },
        ],
        [' ', benefit[1].map((b) => ({ text: formatNumber(b[1]), style: 'tableValue' }))],
      ])
    }
  })
  return results
}

type Props = {
  benefitSummary: BenefitSummary,
}

export const benefitSummarySection = ({ benefitSummary }: Props) => ({
  stack: [
    {
      text: benefitSummaryTable.title,
      style: 'title',
      pageBreak: 'before',
    },
    {
      table: {
        headerRows: 1,
        widths: ['*', '*'],
        margin: [100, 10],
        body: [
          buildHeaderTable(benefitSummaryTable.headers),
          ...buildBenefitSummary(transformBenefitSummary(benefitSummary)),
        ],
      },
      layout: 'lightBorder',
      style: 'table',
    },
    {
      text: `**${MESSAGES.DISCLAIMER_FORCE_MAJEURE}`,
      style: 'disclaimer',
    },
  ],
  style: 'groupRow',
})
