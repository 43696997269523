// @flow
import { find, includes, flow, isPlainObject } from 'lodash/fp'
import { addDaysToDate } from 'core/service/lib/date'
import VALUES from 'core/data-model/constants/values'
import type { RiderState, Rider } from 'core/data-model/rider'

export const calculateLegalDisclaimerEndDate = (startDate: Date): Date => {
  return addDaysToDate(startDate, VALUES.LEGAL_DISCLAIMER_DURATION)
}

export const isCancerRiders = flow(
  find((rider: Rider & RiderState) => {
    const _cancerRiders = ['CPR', 'HIC']
    return includes(rider.code)(_cancerRiders)
  }),
  isPlainObject
)
