// @flow

import { combineReducers } from 'redux'
import type { Effect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import type { AppState } from 'quick-quote/reducers'
import type { PaymentMethod } from 'core/data-model/loan'
import {
  validateCoverageTerm,
  validateLoanAmount,
  validateLoanTerm,
  validateSumAssured,
} from 'core/service/basic-plan/rules/rules-validator'
import type { SelectProduct } from 'quick-quote/product-selection/actions'
import { SELECT_PRODUCT } from 'quick-quote/product-selection/actions'
import type { UpdateBirthdate } from 'quick-quote/insured-information/actions'
import { SELECT_GENDER, UPDATE_BIRTHDATE } from 'quick-quote/insured-information/actions'
import { EDIT_SUM_ASSURED } from 'quick-quote/product-common/coverage-plan/actions'
import { RESET_APPLICATION_STATE } from 'quick-quote/actions'

import { calculatePremium, generatePlanCode, getInsuranceLoanTermByProductCode } from 'core/service/loan'
import { valid } from 'core/service/lib/check'
import VALUES from 'core/data-model/constants/values'

import { getSelectedDisplayProduct, getSelectedDisplayProductCode } from 'quick-quote/product-selection/selectors'

import { getProductDefaults } from 'core/data-model/basic-plan/repository'
import { getAge, getInsured } from 'quick-quote/insured-information/selectors'

import {
  MRTA_UPDATE_LOAN_AMOUNT,
  MRTA_EDIT_LOAN_AMOUNT,
  MRTA_UPDATE_LOAN_PERIOD,
  MRTA_EDIT_LOAN_PERIOD,
  MRTA_UPDATE_COVERAGE_PERIOD,
  MRTA_EDIT_COVERAGE_PERIOD,
  MRTA_EDIT_PAYMENT_SELECTED_MRTA,
  MRTA_UPDATE_SUM_ASSURED_IN_LOAN,
  UPDATE_VALIDATE_COVERAGE_PERIOD_ERRORS,
  MRTA_EDIT_INTEREST_RATE,
  UPDATE_VALIDATE_INTEREST_RATE_ERRORS,
  MRTA_EDIT_SUMASSURED_CONTRACT,
} from 'quick-quote/product-mrta/actions'
// action

export const EDIT_LOAN_TERM = 'LOAN/EDIT_LOAN_TERM'
type EditLoanTerm = {
  type: 'LOAN/EDIT_LOAN_TERM',
  payload: number,
}

export const editLoanTerm = (loanTerm: number): EditLoanTerm => ({
  type: EDIT_LOAN_TERM,
  payload: loanTerm,
})

export const UPDATE_LOAN_TERM = 'LOAN/UPDATE_LOAN_TERM'
type UpdateLoanTerm = {
  type: 'LOAN/UPDATE_LOAN_TERM',
  payload: WithError<number>,
}

export const updateLoanTerm = (loanTermWithErrors: WithError<number>): UpdateLoanTerm => ({
  type: UPDATE_LOAN_TERM,
  payload: loanTermWithErrors,
})

export const EDIT_LOAN_AMOUNT = 'LOAN/EDIT_LOAN_AMOUNT'
type EditLoanAmount = {
  type: 'LOAN/EDIT_LOAN_AMOUNT',
  payload: number,
}

export const editLoanAmount = (loanAmount: number): EditLoanAmount => ({
  type: EDIT_LOAN_AMOUNT,
  payload: loanAmount,
})

export const UPDATE_LOAN_AMOUNT = 'LOAN/UPDATE_LOAN_AMOUNT'
type UpdateLoanAmount = {
  type: 'LOAN/UPDATE_LOAN_AMOUNT',
  payload: WithError<number>,
}

export const updateLoanAmount = (loanAmountWithErrors: WithError<number>): UpdateLoanAmount => ({
  type: UPDATE_LOAN_AMOUNT,
  payload: loanAmountWithErrors,
})

export const UPDATE_AVAILABLE_COVERAGE_TERM = 'LOAN/UPDATE_AVAILABLE_COVERAGE_TERM'
type UpdateAvailableCoverageTerms = {
  type: 'LOAN/UPDATE_AVAILABLE_COVERAGE_TERM',
  payload: number[],
}

export const updateAvailableCoverageTerms = (availableCoverageTerms: number[]): UpdateAvailableCoverageTerms => ({
  type: UPDATE_AVAILABLE_COVERAGE_TERM,
  payload: availableCoverageTerms,
})

export const UPDATE_SUM_ASSURED = 'LOAN/UPDATE_SUM_ASSURED'
type UpdateSumAssured = {
  type: 'LOAN/UPDATE_SUM_ASSURED',
  payload: number,
}

export const updateSumAssured = (sumAssured: number): UpdateSumAssured => ({
  type: UPDATE_SUM_ASSURED,
  payload: sumAssured,
})

export const UPDATE_SUM_ASSURED_ERRORS = 'LOAN/UPDATE_SUM_ASSURED_ERRORS'
type UpdateSumAssuredErrors = {
  type: 'LOAN/UPDATE_SUM_ASSURED_ERRORS',
  payload: string[],
}

export const updateSumAssuredErrors = (errors: string[]): UpdateSumAssuredErrors => ({
  type: UPDATE_SUM_ASSURED_ERRORS,
  payload: errors,
})

export const UPDATE_COVERAGE_TERM = 'LOAN/UPDATE_COVERAGE_TERM'
type UpdateCoverageTerm = {
  type: 'LOAN/UPDATE_COVERAGE_TERM',
  payload: number,
}

export const updateCoverageTerm = (coverageTerm: number): UpdateCoverageTerm => ({
  type: UPDATE_COVERAGE_TERM,
  payload: coverageTerm,
})

export const UPDATE_PAYMENT_METHOD = 'LOAN/UPDATE_PAYMENT_METHOD'
type UpdatePaymentMethod = {
  type: 'LOAN/UPDATE_PAYMENT_METHOD',
  payload: PaymentMethod,
}

export const updatePaymentMethod = (paymentMethod: PaymentMethod): UpdatePaymentMethod => ({
  type: UPDATE_PAYMENT_METHOD,
  payload: paymentMethod,
})

export const UPDATE_COVERAGE_TERM_ERRORS = 'LOAN/UPDATE_COVERAGE_TERM_ERRORS'
type UpdateCoverageTermErrors = {
  type: 'LOAN/UPDATE_COVERAGE_TERM_ERRORS',
  payload: string[],
}

export const updateCoverageTermErrors = (errors: string[]): UpdateCoverageTermErrors => ({
  type: UPDATE_COVERAGE_TERM_ERRORS,
  payload: errors,
})

export const UPDATE_PREMIUM = 'LOAN/UPDATE_PREMIUM'
type UpdatePremium = {
  type: 'LOAN/UPDATE_PREMIUM',
  payload: number,
}

export const updatePremium = (premium: number): UpdatePremium => ({
  type: UPDATE_PREMIUM,
  payload: premium,
})

export const UPDATE_INSURANCE_LOAN_TERM = 'LOAN/UPDATE_INSURANCE_LOAN_TERM'
type UpdateInsuranceLoanTerm = {
  type: 'LOAN/UPDATE_INSURANCE_LOAN_TERM',
  payload: number,
}

export const updateInsuranceLoanTerm = (insuranceLoanTerm: number): UpdateInsuranceLoanTerm => ({
  type: UPDATE_INSURANCE_LOAN_TERM,
  payload: insuranceLoanTerm,
})

// reducer

type WithError<A> = {
  value: A,
  errors: string[],
}

type LoanState = {
  amount: WithError<number>,
  term: WithError<number>,
  interestRate: number,
  sumAssuredContract: number,
}

type CoverageState = {
  sumAssured: WithError<number>,
  term: WithError<number>,
  availableTerms?: number[],
  premium: number,
  insuranceLoanTerm: number,
  paymentMethod: PaymentMethod,
}

export type State = {
  loan: LoanState,
  coverage: CoverageState,
}

const defaultNumberWithError: WithError<number> = { value: 0, errors: [] }

const initialLoan: LoanState = {
  amount: defaultNumberWithError,
  term: defaultNumberWithError,
  interestRate: valid(0),
  sumAssuredContract: 0,
}

const validDefault = valid(0)
const initialCoverage: CoverageState = {
  sumAssured: defaultNumberWithError,
  term: { ...validDefault, errors: [] },
  premium: 0,
  insuranceLoanTerm: 0,
  paymentMethod: VALUES.CASH_INCLUDE_LOAN,
}

export const loan = (state: LoanState = initialLoan, action: *) => {
  switch (action.type) {
    case RESET_APPLICATION_STATE:
      return initialLoan
    case UPDATE_LOAN_TERM:
    case MRTA_UPDATE_LOAN_PERIOD:
    case MRTA_EDIT_LOAN_PERIOD:
      return { ...state, term: action.payload }
    case UPDATE_LOAN_AMOUNT:
    case MRTA_UPDATE_LOAN_AMOUNT:
    case MRTA_EDIT_LOAN_AMOUNT:
      return { ...state, amount: action.payload }
    case MRTA_EDIT_INTEREST_RATE:
      return { ...state, interestRate: { ...state.interestRate, value: action.payload } }
    case MRTA_EDIT_SUMASSURED_CONTRACT:
      return { ...state, sumAssuredContract: action.payload }
    case UPDATE_VALIDATE_INTEREST_RATE_ERRORS:
      return { ...state, interestRate: action.payload }
    default:
      return state
  }
}

export const coverage = (state: CoverageState = initialCoverage, action: *) => {
  switch (action.type) {
    case RESET_APPLICATION_STATE:
      return initialCoverage
    case UPDATE_AVAILABLE_COVERAGE_TERM:
      return { ...state, availableTerms: action.payload }
    case UPDATE_SUM_ASSURED:
    case MRTA_UPDATE_SUM_ASSURED_IN_LOAN:
    case EDIT_SUM_ASSURED:
      return { ...state, sumAssured: { ...state.sumAssured, value: action.payload } }
    case UPDATE_SUM_ASSURED_ERRORS:
      return { ...state, sumAssured: { ...state.sumAssured, errors: action.payload } }
    case UPDATE_COVERAGE_TERM:
    case MRTA_UPDATE_COVERAGE_PERIOD:
    case MRTA_EDIT_COVERAGE_PERIOD:
      return { ...state, term: { ...state.term, value: action.payload } }
    case UPDATE_PAYMENT_METHOD:
    case MRTA_EDIT_PAYMENT_SELECTED_MRTA:
      return { ...state, paymentMethod: action.payload }
    case UPDATE_COVERAGE_TERM_ERRORS:
      return { ...state, term: { ...state.term, errors: action.payload } }
    case UPDATE_PREMIUM:
      return { ...state, premium: action.payload }
    case UPDATE_INSURANCE_LOAN_TERM:
      return { ...state, insuranceLoanTerm: action.payload }
    case UPDATE_VALIDATE_COVERAGE_PERIOD_ERRORS:
      return { ...state, term: action.payload }
    default:
      return state
  }
}

export const reducer = combineReducers({ loan, coverage })

// selector

export const getLoanAmount = (state: AppState) => state.loan.coveragePlan.loan.amount.value
export const getLoanAmountErrors = (state: AppState) => state.loan.coveragePlan.loan.amount.errors
export const getLoanTerm = (state: AppState): number => state.loan.coveragePlan.loan.term.value
export const getLoanTermErrors = (state: AppState) => state.loan.coveragePlan.loan.term.errors
export const getLoan = (state: AppState) => state.loan

export const getAvailableCoverageTerms = (state: AppState) => state.loan.coveragePlan.coverage.availableTerms
export const getPaymentMethod = (state: AppState): string => state.loan.coveragePlan.coverage.paymentMethod
export const getSumAssured = (state: AppState) => state.loan.coveragePlan.coverage.sumAssured.value
export const getSumAssuredErrors = (state: AppState) => state.loan.coveragePlan.coverage.sumAssured.errors
export const getCoverageTerm = (state: AppState): number => state.loan.coveragePlan.coverage.term.value
export const getCoverageTermErrors = (state: AppState) => state.loan.coveragePlan.coverage.term.errors
export const getPremium = (state: AppState) => state.loan.coveragePlan.coverage.premium

export const getInsuranceLoanTerm = (state: AppState): number => state.loan.coveragePlan.coverage.insuranceLoanTerm

// saga

export function* validateAndUpdateLoanAmount(action: EditLoanAmount): Generator<*, *, *> {
  const value = action.payload
  const code = yield select(getSelectedDisplayProductCode)
  const validated = yield call(validateLoanAmount, code, value)

  const errors = validated.isValid ? [] : [validated.message]

  yield put(updateLoanAmount({ value, errors }))
}

export function* validateAndUpdateLoanTerm(action: EditLoanTerm | UpdateBirthdate): Generator<*, *, *> {
  const selectedProduct = yield select(getSelectedDisplayProduct)
  if (selectedProduct.category !== VALUES.LOAN) {
    return
  }

  let value

  if (action.type === EDIT_LOAN_TERM) {
    value = action.payload
  } else {
    value = yield select(getLoanTerm)
  }
  const insuredAge = yield select(getAge)
  const code = yield select(getSelectedDisplayProductCode)

  const validated = yield call(validateLoanTerm, code, value, insuredAge)
  const errors = validated.isValid ? [] : [validated.message]

  yield put(updateLoanTerm({ value, errors }))
}

export function* doValidateSumAssured(action: UpdateSumAssured | UpdateLoanAmount): Generator<*, *, *> {
  const displayProduct = yield select(getSelectedDisplayProduct)
  if (displayProduct.category !== VALUES.LOAN) return

  const sumAssured = yield select(getSumAssured)
  const loanAmount = yield select(getLoanAmount)
  const validated = yield call(validateSumAssured, displayProduct.basicPlanCode, sumAssured, loanAmount)
  const errors = validated.isValid ? [] : [validated.message]

  yield put(updateSumAssuredErrors(errors))
}

export function* doValidateCoverageTerm(action: UpdateLoanTerm | UpdateCoverageTerm): Generator<*, *, *> {
  const displayProduct = yield select(getSelectedDisplayProduct)
  if (displayProduct.category !== VALUES.LOAN) return

  const loanTerm = yield select(getLoanTerm)
  const coverageTerm = yield select(getCoverageTerm)
  const validated = yield call(validateCoverageTerm, displayProduct.basicPlanCode, coverageTerm, loanTerm)
  const errors = validated.isValid ? [] : [validated.message]
  yield put(updateCoverageTermErrors(errors))
}

export function* onUpdateInsuranceLoanTerm(): Generator<*, *, *> {
  const code = yield select(getSelectedDisplayProductCode)
  const coverageTerm = yield select(getCoverageTerm)
  const loanTerm = yield select(getLoanTerm)
  const insuranceLoanTerm = yield call(getInsuranceLoanTermByProductCode, code, { coverageTerm, loanTerm })

  yield put(updateInsuranceLoanTerm(insuranceLoanTerm))
}

export function* setProductDefaults(action: SelectProduct): Generator<*, *, *> {
  const displayProduct = action.payload
  if (displayProduct.category !== VALUES.LOAN) return

  const defaults = yield call(getProductDefaults, displayProduct.basicPlanCode)
  if (defaults) {
    yield put(updateLoanAmount({ value: defaults.loanAmount, errors: [] }))
    yield put(updateLoanTerm({ value: defaults.loanTerm, errors: [] }))
    yield put(updateSumAssured(defaults.sumAssured))
    yield put(updateCoverageTerm(defaults.coverageTerm))
    yield put(updateAvailableCoverageTerms(defaults.availableTerms))
  }
}

export function* onCalculatePremium(): Generator<*, *, *> {
  const selectedProduct = yield select(getSelectedDisplayProduct)
  if (selectedProduct.category !== VALUES.LOAN) {
    return
  }
  const sumAssured = yield select(getSumAssured)
  const productCode = yield select(getSelectedDisplayProductCode)
  const coverageTerm = yield select(getCoverageTerm)
  const insuranceLoanTerm = yield select(getInsuranceLoanTerm)
  const paymentMethod = yield select(getPaymentMethod)
  const insured = yield select(getInsured)
  const planCode = yield call(generatePlanCode, selectedProduct.planCodeSchema, { coverageTerm, insuranceLoanTerm })
  const premiumParams = {
    sumAssured: sumAssured,
    productCode: productCode,
    paymentMethod: paymentMethod,
    planCode: planCode,
    insured: insured,
  }
  const premium = yield call(calculatePremium, premiumParams)

  yield put(updatePremium(premium))
}

function* watchers(): Generator<*, *, Effect[]> {
  const CALCULATE_PREMIUM_ACTION_TRIGGERS = [
    SELECT_GENDER,
    UPDATE_BIRTHDATE,
    UPDATE_INSURANCE_LOAN_TERM,
    UPDATE_SUM_ASSURED,
    UPDATE_LOAN_AMOUNT,
    UPDATE_PAYMENT_METHOD,
  ]

  yield [
    takeLatest(EDIT_LOAN_AMOUNT, validateAndUpdateLoanAmount),
    takeLatest([EDIT_LOAN_TERM, UPDATE_BIRTHDATE], validateAndUpdateLoanTerm),
    takeLatest([UPDATE_SUM_ASSURED, UPDATE_LOAN_AMOUNT], doValidateSumAssured),
    takeLatest([UPDATE_COVERAGE_TERM, UPDATE_LOAN_TERM], doValidateCoverageTerm),
    takeLatest([UPDATE_LOAN_TERM, UPDATE_COVERAGE_TERM], onUpdateInsuranceLoanTerm),
    takeLatest(SELECT_PRODUCT, setProductDefaults),
    takeLatest(CALCULATE_PREMIUM_ACTION_TRIGGERS, onCalculatePremium),
  ]
}

export const sagas = watchers
