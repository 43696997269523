//@flow
type FnaValue = {
  path: string,
  value: number | string | number[] | string[],
}
export const SET_FNA_VALUE = 'SET_FNA_VALUE'
export type SetFnaValue = {
  type: 'SET_FNA_VALUE',
  payload: FnaValue,
}

export const setFnaValue = (fnaValue: FnaValue): SetFnaValue => ({
  type: SET_FNA_VALUE,
  payload: fnaValue,
})

export const ENABLE_FNA_PHASE = 'ENABLE_FNA_PHASE'
export type EnableFnaPhase = {
  type: 'ENABLE_FNA_PHASE',
}

export const enableFnaPhase = (): EnableFnaPhase => ({
  type: ENABLE_FNA_PHASE,
})

export const DISABLE_FNA_PHASE = 'DISABLE_FNA_PHASE'
export type DisableFnaPhase = {
  type: 'DISABLE_FNA_PHASE',
}

export const disableFnaPhase = (): DisableFnaPhase => ({
  type: DISABLE_FNA_PHASE,
})

export const SET_FNA_VC_QUESTION = 'SET_FNA_VC_QUESTION'
export type SetFnaVcQuestion = {
  type: 'SET_FNA_VC_QUESTION',
  payload: {
    value: string,
  },
}
export const setFnaVcQuestion = (value: string) => {
  return {
    type: SET_FNA_VC_QUESTION,
    payload: {
      value,
    },
  }
}

export const SET_FNA_SELLING_MODE = 'SET_FNA_SELLING_MODE'
export type SetFnaSellingMode = {
  type: 'SET_FNA_SELLING_MODE',
  payload: {
    value: string,
  },
}
export const setFnaSellingMode = (value: string) => {
  return {
    type: SET_FNA_SELLING_MODE,
    payload: {
      value,
    },
  }
}

export const SET_FNA_BUYWITHADVISOR = 'SET_FNA_BUYWITHADVISOR'
export type SetFnaBuyWithAdvisor = {
  type: 'SET_FNA_BUYWITHADVISOR',
  payload: {
    value: string,
  },
}
export const setFnaBuyWithAdvisor = (value: string) => {
  return {
    type: SET_FNA_BUYWITHADVISOR,
    payload: {
      value,
    },
  }
}

export const SET_FNA_ISSOCIAL = 'SET_FNA_ISSOCIAL'
export type SetFnaIsSocial = {
  type: 'SET_FNA_ISSOCIAL',
  payload: {
    value: string,
  },
}
export const setFnaIsSocial = (value: string) => {
  return {
    type: SET_FNA_ISSOCIAL,
    payload: {
      value,
    },
  }
}

export type Action = SetFnaValue | EnableFnaPhase | DisableFnaPhase | SetFnaVcQuestion | SetFnaSellingMode
