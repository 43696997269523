// @flow

import _ from 'lodash'
import { isNil } from 'core/service/lib/type-check'
import type { DisplayProduct } from 'core/service/display-product'
import type { Rider, RiderState } from 'core/data-model/rider'
import SummaryRow from './summary-row'
import SummaryFooter from '../../../product-common/benefit-illustration/components/profile-summary/summary-footer'
import MESSAGES from 'core/data-model/constants/messages'
import BIMESSAGES from 'core/data-model/constants/bi-messages'
import values from 'core/data-model/constants/values'
import {
  getRiderYearsOfCoverage,
  getRiderYearsOfPayment,
  hasAsteriskFromRiders,
} from 'core/service/rider/benefits/benefit-data'
import { getRiderAsterisks } from 'core/service/benefit-illustration/benefit-summary'
import { getSumAssuredDisplayValue } from 'core/service/pdf-generation/products/common/logic'
import { getRiderSumAssuredDisplayValue } from 'core/service/benefit-illustration/profile-summary'
import {
  getDisclaimerFiveAsterisksMessage,
  _getDisclaimerRiderPremiumMessage,
  newAsteriskOfSummaryMainContractDiv,
} from '../../../product-common/benefit-illustration/components/profile-summary/summary-table'

import { getToggles } from 'quick-quote/feature-toggles'

type SummaryTableProps = {
  displayProduct: ?DisplayProduct,
  riders: (Rider & RiderState)[],
  totalPremium: number,
  calculatedCoveragePeriod: number,
  calculatedPaymentPeriod: number,
  basicPremium: number,
  basicSumAssured: number,
  hasNonLevelRider: boolean,
  hasShowDisclaimerRiderAsterisk: boolean,
  summaryTableHeader: Function | string,
  isNewAsteriskOfSummaryMainContract: boolean,
}

const SummaryTable = ({
  displayProduct,
  riders,
  calculatedCoveragePeriod,
  calculatedPaymentPeriod,
  basicPremium,
  basicSumAssured,
  totalPremium,
  hasNonLevelRider,
  hasShowDisclaimerRiderAsterisk,
  summaryTableHeader,
  isNewAsteriskOfSummaryMainContract,
}: SummaryTableProps) => {
  if (isNil(displayProduct)) {
    return
  }

  return (
    <div className="summary-table d-none d-md-block">
      <div className="details-table">
        <h3>{BIMESSAGES.SUMMARY_OF_MAIN_AND_ADDITIONAL_CONTRACTS}</h3>
        <table id="summary-table">
          <thead>
            <tr>
              <td>
                {BIMESSAGES.BASIC_PLAN}/{BIMESSAGES.RIDER}
              </td>
              <td>
                {BIMESSAGES.COVERAGE_PERIOD} ({MESSAGES.TIME_UNIT_YEAR})
              </td>
              <td>
                {BIMESSAGES.PAYMENT_PERIOD} ({MESSAGES.TIME_UNIT_YEAR})
              </td>
              <td>
                {BIMESSAGES.SUM_ASSURED} ({MESSAGES.BAHT}){isNewAsteriskOfSummaryMainContract ? '' : '****'}
              </td>
              <td>
                {BIMESSAGES.PREMIUM}
                {isNewAsteriskOfSummaryMainContract ? '' : '**'} ({MESSAGES.BAHT})
              </td>
            </tr>
          </thead>
          <tbody>
            {renderBasicPlanSummaryRow(
              displayProduct,
              calculatedCoveragePeriod,
              calculatedPaymentPeriod,
              basicSumAssured,
              basicPremium,
              summaryTableHeader
            )}
            {renderRidersSummaryRows(riders, isNewAsteriskOfSummaryMainContract)}
            <SummaryFooter totalPremium={totalPremium} />
          </tbody>
        </table>
        {isNewAsteriskOfSummaryMainContract ? (
          newAsteriskOfSummaryMainContractDiv(riders, hasShowDisclaimerRiderAsterisk, hasNonLevelRider)
        ) : (
          <div className="disclaimer">
            <ul className="list-unstyled">
              {hasAsteriskFromRiders(riders) ? <li>* {_getDisclaimerRiderPremiumMessage(riders)}</li> : ''}
              <li>** {BIMESSAGES.DISCLAIMER_OCCUPATION_PREMIUM}</li>
              {getToggles().ENABLE_EXCEPT_DISCLAIMER_RIDER_BENEFIT_TABLE
                ? hasShowDisclaimerRiderAsterisk && (
                    <li>*** {BIMESSAGES.SUMMARY_TABLE_DISCLAIMER_HAS_NON_LEVEL_RIDER}</li>
                  )
                : hasNonLevelRider && <li>*** {BIMESSAGES.SUMMARY_TABLE_DISCLAIMER_HAS_NON_LEVEL_RIDER}</li>}
              <li>**** {BIMESSAGES.DISCLAIMER_SPECIAL_OCCUPATION_PREMIUM}</li>
              {getDisclaimerFiveAsterisksMessage(riders)}
              <br />
              <div className="disclaimerSummaryBox">
                <li>{BIMESSAGES.FLEXI_SUMMARY_TABLE_DISCLAIMER_3}</li>
                <li>{BIMESSAGES.FLEXI_SUMMARY_TABLE_DISCLAIMER_4}</li>
              </div>
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}

const renderBasicPlanSummaryRow = (
  displayProduct: DisplayProduct,
  calculatedCoveragePeriod: number,
  calculatedPaymentPeriod: number,
  basicSumAssured: number,
  basicPremium: number,
  summaryTableHeader: Function | string
) => {
  const productName = _.isFunction(summaryTableHeader)
    ? // $$FlowFixMe
      summaryTableHeader({
        displayProduct,
        calculatedCoveragePeriod,
        calculatedPaymentPeriod,
        basicSumAssured,
        basicPremium,
      })
    : summaryTableHeader

  const sumAssured = getSumAssuredDisplayValue(displayProduct, basicSumAssured, false)

  return (
    <SummaryRow
      id={displayProduct.code}
      productCode={displayProduct.basicPlanCode}
      productName={
        // $$FlowFixMe
        productName
      }
      selectedPlan={{ planCode: '' }} // not applicable for basic plan
      coverageYear={calculatedCoveragePeriod}
      paymentPeriod={calculatedPaymentPeriod}
      premium={basicPremium}
      sumAssured={sumAssured}
      showSumAssuredDecimalPlaces={false}
    />
  )
}

const renderRiderProductNameSummaryRows = (rider: Rider & RiderState) => {
  switch (rider.code) {
    case values.MEH:
      return `${BIMESSAGES.RIDER} ${rider.description}`
    case values.WPC:
    case values.WPC25PL:
    case values.WPCWL60:
    case values.WPCWL99:
    case values.WPEWL85:
    case values.WPEIP85:
      return `${BIMESSAGES.RIDER}${BIMESSAGES.BENEFIT_TITLE}${rider.description}`
    case values.PBPDD:
    case values.PBSDD:
      return `${BIMESSAGES.PB_FIT_NAME}`
    case values.PBPDDCI:
    case values.PBSDDCI:
      return `${BIMESSAGES.PB_BEYOND_NAME}`
    default:
      return `${BIMESSAGES.RIDER}${rider.description}`
  }
}

const renderRidersSummaryRows = (riders: (Rider & RiderState)[], isNewAsteriskOfSummaryMainContract) => {
  if (isNil(riders)) return

  return riders.map((rider) => {
    return (
      // $$FlowFixMe
      <SummaryRow
        key={`summary-row-${rider.code}`}
        id={rider.code}
        productCode={rider.package ? rider.selectedPlan.planCode : rider.name}
        productName={renderRiderProductNameSummaryRows(rider)}
        asterisks={getRiderAsterisks(rider, isNewAsteriskOfSummaryMainContract)}
        selectedPlan={{
          ...rider.selectedPlan,
          planCode: rider.package ? '' : rider.selectedPlan.planCode,
        }}
        coverageYear={getRiderYearsOfCoverage(rider)}
        paymentPeriod={getRiderYearsOfPayment(rider, { hasAsterisk: true })}
        premium={rider.premium}
        sumAssured={getRiderSumAssuredDisplayValue(rider)}
        showSumAssuredDecimalPlaces={rider.showSumAssuredDecimalPlaces}
      />
    )
  })
}
export default SummaryTable
