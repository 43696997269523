// @flow
import _ from 'lodash'
import { formatNumber } from 'core/service/lib/number-format'

import { totalRiderPremium } from 'core/service/basic-plan/premium'
import { buildStacksWithStyle, buildHeaderTable } from 'core/service/pdf-generation/utils/builder'
import { getSumAssuredDisplayValue, getRiderNameDisplayValue } from 'core/service/pdf-generation/products/common/logic'
import { summaryTable } from 'core/service/pdf-generation/products/common/sections/profile-snapshot-section'
import { disclaimers } from 'core/service/pdf-generation/products/common/components/disclaimers'
import {
  getRiderYearsOfCoverage,
  getRiderYearsOfPayment,
  hasAsteriskFromRiders,
} from 'core/service/rider/benefits/benefit-data'
import { getRiderSumAssuredDisplayValue } from 'core/service/benefit-illustration/profile-summary'

import BIMESSAGES from 'core/data-model/constants/bi-messages'
import MESSAGES from 'core/data-model/constants/messages'

import type { Rider, RiderState } from 'core/data-model/rider'
import type { Age } from 'core/data-model/insured'
import type { DisplayProduct } from 'core/service/display-product'
import type { BenefitSummary } from 'core/service/benefit-illustration'

const buildBasicPlanRowInTable = (
  basicPlan: DisplayProduct,
  calculatedCoveragePeriod,
  calculatedPaymentPeriod,
  basicSumAssured,
  basicPremium,
  basicPlanLabel
) => [
  {
    text: `${basicPlan.nameThai} (${basicPlan.basicPlanCode})`,
  },
  { text: BIMESSAGES.COVERAGE_PERIOD_BUMNAN_READY_SUFFIX, alignment: 'center' },
  { text: calculatedPaymentPeriod, alignment: 'center' },
  {
    text: getSumAssuredDisplayValue(basicPlan, basicSumAssured, false),
    alignment: 'center',
  },
  { text: formatNumber(basicPremium, 2), bold: true, alignment: 'right' },
]

const buildTotalPremiumRow = (totalPremium: number) => {
  return [
    { colSpan: 4, rowSpan: 1, text: BIMESSAGES.TOTAL_PREMIUM_FIRST_PREMIUM },
    '',
    '',
    '',
    {
      colSpan: 1,
      rowSpan: 1,
      text: formatNumber(totalPremium, 2),
      bold: true,
      alignment: 'right',
    },
  ]
}

// const buildCoveragePeriodSuffix = (basicPlan: DisplayProduct, calculatedCoveragePeriod) => {
//   return isIRetireProduct(basicPlan.basicPlanCode) || isLifeRetireProduct(basicPlan.basicPlanCode)
//     ? BIMESSAGES.COVERAGE_PERIOD_RETIREMENT_SUFFIX
//     : calculatedCoveragePeriod
// }

const buildRidersRowInTable = (riders, isNewAsteriskOfSummaryMainContract) => {
  return _.map(riders, (rider) => [
    { text: getRiderNameDisplayValue(rider, isNewAsteriskOfSummaryMainContract) },
    { text: getRiderYearsOfCoverage(rider), alignment: 'center' },
    { text: getRiderYearsOfPayment(rider, { hasAsterisk: true }), alignment: 'center' },
    { text: getRiderSumAssuredDisplayValue(rider), alignment: 'center' },
    { text: formatNumber(rider.premium, 2), bold: true, alignment: 'right' },
  ])
}

const buildSummaryBasicPlan = (sumAssured, selectedModelFactorLabel) => [
  {
    label: { text: BIMESSAGES.BASIC_PLAN_SUM_ASSURED, style: '' },
    value: {
      text: `${formatNumber(sumAssured)} ${MESSAGES.BAHT}`,
      style: 'basicPlanSummaryValue',
    },
  },
  {
    label: { text: MESSAGES.PAYMENT_MODE, style: '' },
    value: { text: selectedModelFactorLabel, style: 'basicPlanSummaryValue' },
  },
]

const buildSummaryPremium = (basicPremium, riderPremium, totalPremium, riders) => [
  {
    label: { text: BIMESSAGES.BASIC_PREMIUM, style: '' },
    value: {
      text: `${formatNumber(basicPremium, 2)} ${MESSAGES.BAHT}`,
      style: 'basicPlanSummaryValue',
    },
  },
  {
    label: { text: `${BIMESSAGES.TOTAL_RIDER_PREMIUM}${hasAsteriskFromRiders(riders) ? '*' : ''}`, style: '' },
    value: {
      text: `${formatNumber(riderPremium, 2)} ${MESSAGES.BAHT}`,
      style: 'basicPlanSummaryValue',
    },
  },
  {
    label: { text: BIMESSAGES.TOTAL_PREMIUM_FIRST_PREMIUM, style: '' },
    value: {
      text: `${formatNumber(totalPremium, 2)} ${MESSAGES.BAHT}`,
      style: 'basicPlanSummaryValue',
    },
  },
]

const buildDisclaimers = (basicPlan: ?DisplayProduct, riders, isNewAsteriskOfSummaryMainContract: boolean) => {
  return {
    stack: disclaimers(basicPlan, riders, isNewAsteriskOfSummaryMainContract).map((disclaimer) => ({
      text: disclaimer,
    })),
    style: 'disclaimer',
  }
}

const buildSummaryTableDisclaimer = () => {
  return {
    stack: [' ', BIMESSAGES.FLEXI_SUMMARY_TABLE_DISCLAIMER_3, BIMESSAGES.FLEXI_SUMMARY_TABLE_DISCLAIMER_4],
    style: 'disclaimerBox',
  }
}

type Props = {
  basicPlan: DisplayProduct,
  riders: (Rider & RiderState)[],
  genderLabel: string,
  age: Age,
  calculatedCoveragePeriod: number,
  calculatedPaymentPeriod: number,
  sumAssured: number,
  basicPremium: number,
  totalPremium: number,
  selectedModelFactorLabel: string,
  hasNonLevelRider: boolean,
  benefitSummary: BenefitSummary,
  basicPlanLabel: ?Function,
  isNewAsteriskOfSummaryMainContract: boolean,
  campaignDiscountMessage: string[],
}

export const profileSnapshotSection = ({
  basicPlan,
  genderLabel,
  age,
  calculatedCoveragePeriod,
  calculatedPaymentPeriod,
  sumAssured,
  selectedModelFactorLabel,
  basicPremium,
  riders,
  hasNonLevelRider,
  totalPremium,
  basicPlanLabel,
  isNewAsteriskOfSummaryMainContract,
  campaignDiscountMessage,
  ...props
}: Props) => [
  {
    table: {
      widths: ['33%', '60%'],
      body: [buildStacksWithStyle(buildSummaryBasicPlan(sumAssured, selectedModelFactorLabel))],
    },
    layout: 'noBorders',
  },
  {
    table: {
      widths: ['33%', '34%', '33%'],
      body: [buildStacksWithStyle(buildSummaryPremium(basicPremium, totalRiderPremium(riders), totalPremium, riders))],
    },
    layout: 'noBorders',
    marginBottom: 20,
  },
  {
    text: campaignDiscountMessage && campaignDiscountMessage.length > 0 ? campaignDiscountMessage[1] : '',
    color: 'red',
    alignment: 'center',
    marginBottom: 10,
  },
  {
    text: summaryTable(basicPlan, isNewAsteriskOfSummaryMainContract).title,
    style: 'title',
  },
  {
    table: {
      headerRows: 1,
      widths: [150, 'auto', 'auto', 'auto', 'auto'],
      margin: [100, 10],
      body: [
        buildHeaderTable(summaryTable(basicPlan, isNewAsteriskOfSummaryMainContract).headers),
        buildBasicPlanRowInTable(
          basicPlan,
          calculatedCoveragePeriod,
          calculatedPaymentPeriod,
          sumAssured,
          basicPremium,
          basicPlanLabel
        ),
        ...buildRidersRowInTable(riders, isNewAsteriskOfSummaryMainContract),
        buildTotalPremiumRow(totalPremium),
      ],
    },
    layout: 'lightBorder',
    style: 'table',
  },
  buildDisclaimers(basicPlan, riders, isNewAsteriskOfSummaryMainContract),
  buildSummaryTableDisclaimer(),
]
