import { css } from 'styled-components'

export const marginTopLessMixin = ({ marginTopLess }) =>
  marginTopLess &&
  css`
    & + & {
      margin-top: -1rem;
    }
  `

export const backgroundAsBorderMixin = () => css`
  background: linear-gradient(
      ${({ theme }) => theme.variables['$color-silver']},
      ${({ theme }) => theme.variables['$color-silver']}
    )
    ${({ hasBorder = 'top' }) => hasBorder} no-repeat;
`

const maxWidth = {
  sm: 680,
  xl: 1200,
}
export const contentMaxWidth = ({ size = 'sm' }) => css`
  max-width: ${maxWidth[size]}px;
  flex: 1 1 ${maxWidth[size]}px;
  margin: 0 auto;
`
