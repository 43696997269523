const OptyDetail = (props) => {
  const { name, source, product, mobile, date } = props

  return (
    <div>
      <div>
        <strong>ชื่อ-นามสกุล</strong>
        <span> : {name}</span>
      </div>
      <div>
        <strong>ที่มาของผู้มุ่งหวัง</strong>
        <span> : {source}</span>
      </div>
      <div>
        <strong>ผลิตภัณฑ์ที่สนใจ</strong>
        <span> : {product}</span>
      </div>
      <div>
        <strong>เบอร์โทรศัพท์</strong>
        <span> : {mobile}</span>
      </div>
      <div>
        <strong>วันที่สร้าง</strong>
        <span> : {date}</span>
      </div>
    </div>
  )
}

export default OptyDetail
