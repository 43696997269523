import _ from 'lodash'
import { createSectionCreator, createField } from './utils'
import VALUE from 'core/data-model/constants/values'
import { getProvinceList, getAmphurList, getTambolList, getPostcodeList } from 'e-submission/apps/selectors'
import { getToggles } from 'quick-quote/feature-toggles'

const ADDRESS_TYPES = {
  current: 'currentAddress',
  register: 'registeredAddress',
  office: 'officeAddress',
  payer: 'payerAddress',
  spouse: 'foreigner.spouse.address',
  spouseOffice: 'foreigner.spouse.officeAddress',
}

export const addressFields = (addressType) => (app, id) => {
  const fieldId = `${id}.${addressType}`
  // const isCurrentAddress = addressType === 'currentAddress'
  const dataPostCode = _.get(app, `${fieldId}.dataPostCode.value`, [])
  const tambol = _.get(app, `${fieldId}.tambol.value`, '')
  const isShowTextInputPostCodeCondition = dataPostCode.length <= 1
  return [
    createField(`${fieldId}.numberMoobanArkan`, [
      {
        label: addressType !== ADDRESS_TYPES.office ? 'บ้านเลขที่, หมู่บ้าน/อาคาร' : 'สถานที่ทำงาน/อาคารสำนักงาน',
        id: `${fieldId}.numberMoobanArkan`,
        c: 'TextInput',
        required: true,
        p: {},
      },
    ]),
    createField(
      `${fieldId}-${tambol}`,
      [
        {
          label: 'จังหวัด',
          id: `${fieldId}.province`,
          c: 'Dropdown',
          required: true,
          p: {
            mergeState: (state) => ({
              options: getProvinceList(state, fieldId),
            }),
            placeHolder: 'กรุณาเลือกจังหวัด',
          },
        },
        {
          label: 'เขต/อำเภอ',
          id: `${fieldId}.amphur`,
          c: 'Dropdown',
          required: true,
          p: {
            mergeState: (state) => ({
              options: getAmphurList(state, fieldId),
            }),
            placeHolder: 'กรุณาเลือกเขต/อำเภอ',
          },
        },
        {
          label: 'แขวง/ตำบล',
          id: `${fieldId}.tambol`,
          c: 'Dropdown',
          required: true,
          p: {
            mergeState: (state) => ({
              options: getTambolList(state, fieldId),
            }),
            placeHolder: 'กรุณาเลือกแขวง/ตำบล',
          },
        },
        isShowTextInputPostCodeCondition
          ? {
              label: 'รหัสไปรษณีย์',
              id: `${fieldId}.postCode.value`,
              c: 'TextInput',
              required: true,
              disabled: !tambol,
              p: {},
            }
          : {
              label: 'รหัสไปรษณีย์',
              id: `${fieldId}.postCode`,
              c: 'Dropdown',
              required: true,
              p: {
                mergeState: (state) => ({
                  options: getPostcodeList(state, fieldId),
                }),
                placeHolder: 'กรุณาเลือกรหัสไปรษณีย์',
              },
            },
      ],
      {
        flex: ['0 0 50%'],
        halfGap: true,
      }
    ),
  ]
}

export const twoLinesAddressField = (addressType) => (app, id) => {
  const fieldId = `${id}.${addressType}`
  // const isCurrentAddress = addressType === 'currentAddress'

  return [
    createField(`${fieldId}.numberMoobanArkan`, [
      {
        label: 'บ้านเลขที่, หมู่บ้าน/อาคาร',
        id: `${fieldId}.numberMoobanArkan`,
        c: 'TextInput',
        required: true,
        p: {},
      },
    ]),
    createField(`${fieldId}.numberMoobanArkan2`, [
      {
        label: 'ซอย, ถนน',
        id: `${fieldId}.numberMoobanArkan2`,
        c: 'TextInput',
        required: false,
        p: {},
      },
    ]),
    createField(
      `${fieldId}.province`,
      [
        {
          label: 'จังหวัด',
          id: `${fieldId}.province`,
          c: 'Dropdown',
          required: true,
          p: {
            mergeState: (state) => ({
              options: getProvinceList(state, fieldId),
            }),
            placeHolder: 'กรุณาเลือกจังหวัด',
          },
        },
        {
          label: 'เขต/อำเภอ',
          id: `${fieldId}.amphur`,
          c: 'Dropdown',
          required: true,
          p: {
            mergeState: (state) => ({
              options: getAmphurList(state, fieldId),
            }),
            placeHolder: 'กรุณาเลือกเขต/อำเภอ',
          },
        },
        {
          label: 'แขวง/ตำบล',
          id: `${fieldId}.tambol`,
          c: 'Dropdown',
          required: true,
          p: {
            mergeState: (state) => ({
              options: getTambolList(state, fieldId),
            }),
            placeHolder: 'กรุณาเลือกแขวง/ตำบล',
          },
        },
        {
          label: 'รหัสไปรษณีย์',
          id: `${fieldId}.postCode`,
          c: 'Dropdown',
          required: true,
          p: {
            mergeState: (state) => ({
              options: getPostcodeList(state, fieldId),
            }),
            placeHolder: 'กรุณาเลือกรหัสไปรษณีย์',
          },
        },
      ],
      {
        flex: ['0 0 50%'],
        halfGap: true,
      }
    ),
  ]
}

const currentAddressFields = (app, id, test) => {
  return getToggles().ENABLE_2_LINES_ADDRESS
    ? twoLinesAddressField('currentAddress')(app, id)
    : addressFields('currentAddress')(app, id)
}

const registeredAddressFields = (app, id) => {
  const notUseCurrentAddress = _.get(app, `${id}.registeredAddress.notUseCurrentAddress`)
  return [
    createField(`${id}.registeredAddress.notUseCurrentAddress`, [
      {
        id: `${id}.registeredAddress.notUseCurrentAddress`,
        c: 'Checkbox',
        p: {
          children: 'ที่อยู่ปัจจุบัน ไม่เหมือนกับที่อยู่ตามทะเบียนบ้าน',
        },
      },
    ]),
    ...(notUseCurrentAddress
      ? getToggles().ENABLE_2_LINES_ADDRESS
        ? twoLinesAddressField(ADDRESS_TYPES.register)(app, id)
        : addressFields(ADDRESS_TYPES.register)(app, id)
      : []),
  ]
}

const officeAddressFields = (app, id) => {
  const isSameAsCurrentAddress = _.get(app, `${id}.officeAddress.isSameAsCurrentAddress`)
  const isInvestment = _.get(app, 'quickQuote.selectedDisplayProduct.category') === VALUE.INVESTMENT

  return _.compact([
    getToggles().ENABLE_OFFICE_NAME_ILP_PRODUCT &&
      isInvestment &&
      createField(`${id}.officeName`, [
        {
          label: 'ชื่อบริษัท/ชื่อสถานที่ทำงาน',
          id: `${id}.officeName`,
          c: 'TextInput',
          infoMessage: 'กรุณาระบุชื่อบริษัท/ชื่อสถานที่ทำงาน (ถ้ามี)',
        },
      ]),
    createField(`${id}.officeAddress.haveAddress`, [
      {
        id: `${id}.officeAddress.isSameAsCurrentAddress`,
        c: 'Checkbox',
        p: {
          children: 'สถานที่ทำงานเหมือนกับที่อยู่ปัจจุบัน',
        },
      },
    ]),
    ...(!isSameAsCurrentAddress
      ? getToggles().ENABLE_2_LINES_ADDRESS
        ? twoLinesAddressField(ADDRESS_TYPES.office)(app, id)
        : addressFields(ADDRESS_TYPES.office)(app, id)
      : []),
  ])
}

const payerAddressFields = (app, id) => {
  const isUseCurrentInsuredAddress = _.get(app, `${id}.payerAddress.useCurrentInsuredAddress`)
  return [
    createField('payer.payerAddress.useCurrentInsuredAddress', [
      {
        id: 'payer.payerAddress.useCurrentInsuredAddress',
        c: 'Checkbox',
        p: {
          children: 'ที่อยู่เหมือนกับที่อยู่ปัจจุบันของผู้เอาประกันภัย',
        },
      },
    ]),
    ...(isUseCurrentInsuredAddress
      ? []
      : getToggles().ENABLE_2_LINES_ADDRESS
      ? twoLinesAddressField(ADDRESS_TYPES.payer)(app, id)
      : addressFields(ADDRESS_TYPES.payer)(app, id)),
  ]
}

export const payerAddressSection = createSectionCreator(payerAddressFields)

const spouseAddressFields = (app, id) => {
  const isUseCurrentInsuredAddress = _.get(app, `${id}.foreigner.spouse.address.useCurrentInsuredAddress`)
  return [
    createField('insured.foreigner.spouse.address.useCurrentInsuredAddress', [
      {
        id: 'insured.foreigner.spouse.address.useCurrentInsuredAddress',
        c: 'Checkbox',
        p: {
          children: 'ที่อยู่เหมือนกับที่อยู่ปัจจุบันของผู้เอาประกันภัย',
        },
      },
    ]),
    ...(isUseCurrentInsuredAddress ? [] : addressFields(ADDRESS_TYPES.spouse)(app, id)),
  ]
}

const spouseOfficeAddressFields = (app, id) => {
  const isSameAsCurrentAddress = _.get(app, `${id}.foreigner.spouse.officeAddress.isSameAsCurrentAddress`)
  return [
    createField(`${id}.foreigner.spouse.officeAddress.haveAddress`, [
      {
        id: `${id}.foreigner.spouse.officeAddress.isSameAsCurrentAddress`,
        c: 'Checkbox',
        p: {
          children: 'สถานที่ทำงานเหมือนกับที่อยู่ปัจจุบัน',
        },
      },
    ]),
    ...(!isSameAsCurrentAddress
      ? getToggles().ENABLE_2_LINES_ADDRESS
        ? twoLinesAddressField(ADDRESS_TYPES.spouseOffice)(app, id)
        : addressFields(ADDRESS_TYPES.spouseOffice)(app, id)
      : []),
  ]
}

export const spouseAddressSection = createSectionCreator(spouseAddressFields)

export const spouseOfficeAddressSection = createSectionCreator(spouseOfficeAddressFields)

export const currentAddressSection = createSectionCreator(currentAddressFields)
export const registeredAddressSection = createSectionCreator(registeredAddressFields)
export const officeAddressSection = createSectionCreator(officeAddressFields)
