// @flow

import template from 'core/service/pdf-generation/products/retirement/template'
import { section } from 'core/service/pdf-generation/products/retirement/sections'
import { commonSection } from 'core/service/pdf-generation/products/common/sections'
import { createPdfDocument } from 'core/service/pdf-generation/document-creation'
import { createCommonPdf } from '../common/pdf-document'
import { basicPlanDetailsLabelFn } from '../common/sections/product-benefit-section'
import type { RetirementProps } from './index'
import type { DisplayProduct } from 'core/service/display-product'

const getProfileProps = (props: RetirementProps) => {
  if (props.basicPlan.code === '25PL') {
    return {
      basicPlanLabel: (displayProduct: DisplayProduct) => displayProduct.nameThai,
    }
  }
  return {}
}

const getProductBenefitsProps = (props: RetirementProps) => {
  return {
    basicPlanDetailsLabelFn,
  }
}

export const createMiniBIDocDefinition = async (props: RetirementProps) => {
  const pdfTemplate = await template(props)
  return {
    content: [
      ...commonSection.profile({ ...props, ...getProfileProps(props) }),
      ...commonSection.productBenefits({ ...props, ...getProductBenefitsProps(props) }),
      ...commonSection.riders(props),
      ...section.policyValueTable(props),
      section.benefitSummary(props),
      section.taxDeductionBenefit(props),
      commonSection.legalDisclaimerMarketConduct(props),
    ],
    ...pdfTemplate,
  }
}

export const createFullBIDocDefinition = async (props: RetirementProps) => {
  const pdfTemplate = await template({ ...props, biType: props.biType })
  const paymentChannels = await commonSection.paymentChannels(props)

  return {
    content: [
      section.profileSnapshot(props),
      //...commonSection.profile({ ...props, ...getProfileProps(props) }),
      section.productBenefits({ ...props, ...getProductBenefitsProps(props) }),
      ...commonSection.riders(props),
      commonSection.basicPlanCondition,
      section.policyValueTable(props),
      section.benefitSummary(props),
      section.taxDeductionBenefit(props),
      commonSection.legalDisclaimerMarketConduct(props),
      paymentChannels,
      commonSection.signature(props),
    ],
    ...pdfTemplate,
  }
}

const createFullBIPdf = createPdfDocument(createFullBIDocDefinition)
const createMiniBIPdf = createPdfDocument(createMiniBIDocDefinition)

export const createRetirementPdf = async (props: RetirementProps) => {
  return await createCommonPdf(props, createFullBIPdf, createMiniBIPdf)
}
