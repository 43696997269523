// @flow

import _ from 'lodash'
import { dateToMoment, buddhistToChristianDate, dateNowMoment } from 'core/service/lib/date'
import CONSTANTS from 'core/data-model/constants/values'
import type Moment from 'moment'
import type { Age, EntryAge } from 'core/data-model/insured'

const { TOTAL_DAYS_PER_YEAR, TOTAL_DAYS_PER_MONTH } = CONSTANTS

const [DAY, MONTH, YEAR] = ['day', 'month', 'year']

export const isValidBirthdate = (birthdateString: string, todayString: string): boolean => {
  let birthdateAC
  let birthdateBD
  try {
    birthdateAC = dateToMoment(birthdateString)
    birthdateBD = dateToMoment(buddhistToChristianDate(birthdateString))
  } catch (err) {
    return false
  }
  const today: Moment = dateToMoment(todayString)

  return today.isValid() && (birthdateAC.isValid() || birthdateBD.isValid())
}

export const calculateAge = (birthdateString: string, now: string): Age => {
  let birthday = dateToMoment(birthdateString)
  const today = dateToMoment(now)

  if (!birthday.isValid() || birthday.isAfter(today)) {
    birthday = dateToMoment(buddhistToChristianDate(birthdateString))
  }

  const value = today.diff(birthday, 'years')
  const unit = YEAR

  const _year = today.diff(birthday, 'years')
  const _day = today.diff(birthday, 'days') + 1
  const _month = today.diff(birthday, 'months')

  if (Number.isFinite(value)) {
    if (_year > 0) {
      return { value: _year, unit: YEAR }
    } else if (_day < TOTAL_DAYS_PER_MONTH) {
      return { value: _day, unit: DAY }
    } else if (_month > 0) {
      return { value: _month, unit: MONTH }
    } else if (_day >= TOTAL_DAYS_PER_MONTH) {
      return { value: 1, unit: MONTH }
    }
  }

  return { value: value, unit: unit }
}

export const getAgeFromBirthDate = (birthdate: string) => {
  const currentDate = dateNowMoment()
  let age
  if (!_.isEmpty(birthdate)) {
    const { value: ageValue, unit } = calculateAge(birthdate, currentDate)
    if (unit === 'year') {
      age = `${ageValue}`
    } else {
      age = '0'
    }
  }
  return age
}

export const convertEntryAge = (entryAge: string) => {
  const [age, unit] = _.words(entryAge)

  let actualUnit

  switch (_.lowerCase(unit)) {
    case 'y':
      actualUnit = YEAR
      break
    case 'm':
      actualUnit = MONTH
      break
    default:
      actualUnit = DAY
      break
  }

  return { value: +age, unit: actualUnit }
}

export const getParseAgeToDate = (value: number, unit: string) => {
  switch (unit) {
    case YEAR:
      return value * TOTAL_DAYS_PER_YEAR
    case MONTH:
      return value * TOTAL_DAYS_PER_MONTH
    default:
      return value
  }
}

export const isValidAge = (value: number, unit: string, entryAge: EntryAge) => {
  const { minimum, maximum } = entryAge

  const parseDateFromAge = getParseAgeToDate(value, unit)
  const parseDateFromEntryDateMinimum = getParseAgeToDate(minimum.value, minimum.unit)
  const parseDateFromEntryDateMaximum = getParseAgeToDate(maximum.value, maximum.unit)

  return !(parseDateFromAge < parseDateFromEntryDateMinimum || parseDateFromAge > parseDateFromEntryDateMaximum)
}
