// @flow

import _ from 'lodash'
import ProductBenefitsSummary from 'quick-quote/product-common/benefit-illustration/components/product-benefits/product-benefits-summary'
import WholeLife25PLBenefits from 'quick-quote/product-whole-life/benefit-illustration/components/product-benefits/product-benefits-whole-life-25pl'
import type { ProductBenefitProps } from 'quick-quote/product-common/benefit-illustration/components/product-benefits/types'
import IProtectProductBenefits from './product-benefits-iprotect'
import IShieldProductBenefits from './product-benefits-ishield'
import LifeLegacyBenefits from './product-benefits-lifeLegacy'
import LifeProtect18ProductBenefits from './product-benefits-lifeprotect18'
import LifeEnsureProductBenefits from './product-benefits-lifeEnsure'
import RetirementProductBenefits from 'quick-quote/v2/products/categories/retirement/product-benefits-iretire'
import S7ProductBenefits from 'quick-quote/v2/products/categories/whole-life/product-benefits-s7'

const ProductBenefits = ({
  displayProduct,
  sumAssured,
  basicPremium,
  calculatedPaymentPeriod,
  calculatedCoveragePeriod,
  policyValue,
  benefitSummaryHeader,
  selectedModelFactorID,
  selectedModelFactorLabel,
}: ProductBenefitProps) => {
  return (
    <div id="product-benefits" className="benefit group">
      <ProductBenefitsSummary
        displayProduct={displayProduct}
        sumAssured={sumAssured}
        basicPremium={basicPremium}
        calculatedPaymentPeriod={calculatedPaymentPeriod}
        calculatedCoveragePeriod={calculatedCoveragePeriod}
        policyValue={policyValue}
        benefitSummaryHeader={benefitSummaryHeader}
        selectedModelFactorID={selectedModelFactorID}
        selectedModelFactorLabel={selectedModelFactorLabel}
      />

      {_.get(displayProduct, 'basicPlanCode') === '25PL' ? (
        <div className="d-none d-md-block">
          <WholeLife25PLBenefits displayProduct={displayProduct} policyValue={policyValue} sumAssured={sumAssured} />
        </div>
      ) : (
        ''
      )}

      <RetirementProductBenefits displayProduct={displayProduct} sumAssured={sumAssured} />

      <LifeProtect18ProductBenefits displayProduct={displayProduct} sumAssured={sumAssured} />

      <LifeEnsureProductBenefits displayProduct={displayProduct} />

      <IProtectProductBenefits displayProduct={displayProduct} sumAssured={sumAssured} />

      <IShieldProductBenefits displayProduct={displayProduct} sumAssured={sumAssured} />

      <LifeLegacyBenefits displayProduct={displayProduct} />

      <S7ProductBenefits displayProduct={displayProduct} sumAssured={sumAssured} />
    </div>
  )
}

export default ProductBenefits
