//@flow
import CONSTANTS from 'core/data-model/constants/messages'
import MESSAGES from 'core/data-model/constants/bi-messages'
import { formatNumber } from 'core/service/lib/number-format'
import _ from 'lodash'

import type { RiderBenefitData } from 'core/data-model/rider/benefits'
import type { Rider, RiderState } from 'core/data-model/rider'
import { getDCIExceptionNotes } from 'core/service/rider/benefits/exception-notes'

type DCIBenefitProps = {
  rider: Rider & RiderState,
  riderBenefitData: RiderBenefitData,
  showDisclaimer: boolean,
}

export const DCIBenefitSection = ({ rider, riderBenefitData, showDisclaimer }: DCIBenefitProps) => {
  const diseases: string[] = _.get(riderBenefitData, ['diseases', 'value'])
  const exceptionNotes = buildExceptionNotes()
  return [
    `${_.get(riderBenefitData, ['benefitDescription'])} ${formatNumber(rider.sumAssured)} ${CONSTANTS.BAHT}`,
    _.get(riderBenefitData, ['diseases', 'title']),
    {
      columns: [
        {
          ol: diseases.slice(0, 10),
          width: 167,
        },
        {
          start: 11,
          ol: diseases.slice(10, 20),
          width: 130,
        },
        {
          start: 21,
          ol: diseases.slice(20, diseases.length),
          width: '*',
        },
      ],
      style: 'disclaimer',
      columnGap: 15,
      margin: [5, 0, 0, 0],
    },
    buildDisclaimer(showDisclaimer),
    { ...exceptionNotes, marginTop: 15 },
  ]
}

const buildDisclaimer = (showDisclaimer) =>
  showDisclaimer ? `*${MESSAGES.DISCLAIMER_OVER_56_MUST_HAVE_MEDICAL_EXAM}` : ''

const buildExceptionNotes = () => {
  const exceptionNotes = getDCIExceptionNotes()
  const [
    exceptionDCINotesFirstLine,
    exceptionDCINotesSecondLine,
    exceptionDCINotesThirdDesc,
    exceptionDCINotesFourthLine,
    exceptionDCINotesFifthDesc,
  ] = exceptionNotes.exceptions
  return {
    stack: [
      { text: MESSAGES.EXCEPTION_NOTE, style: 'sub-title' },
      { text: exceptionDCINotesFirstLine },
      { text: exceptionDCINotesSecondLine },
      { ol: [...exceptionDCINotesThirdDesc] },
      { text: exceptionDCINotesFourthLine, margin: [0, 12, 0, 0] },
      { ol: [...exceptionDCINotesFifthDesc] },
    ],
  }
}
