// @flow
import type { AppState } from 'quick-quote/reducers'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { RadioButtonGroup } from 'common-components'
import { selectProductLifeReady } from 'quick-quote/product-life-ready/actions'
import { getSelectedProductLifeReady } from '../selectors'
import VALUES from 'core/data-model/constants/values'

export const LifeReadyProductSelection = ({ getSelectedProductLifeReady, selectProductLifeReady }) => {
  const productLifeReady = [
    {
      text: '6 ปี',
      value: VALUES.LIFEREADY_CODE.LIFEREADY6,
    },
    {
      text: '12 ปี',
      value: VALUES.LIFEREADY_CODE.LIFEREADY12,
    },
    {
      text: '18 ปี',
      value: VALUES.LIFEREADY_CODE.LIFEREADY18_UNDER,
    },
    {
      text: 'ถึงอายุ 99 ปี',
      value: VALUES.LIFEREADY_CODE.LIFEREADY99_UNDER,
    },
  ]

  return (
    <div className="form-group">
      <div className="form-group-title">
        <span className="form-group-title-text">ระยะเวลาการชำระเบี้ยประกัน</span>
      </div>
      <RadioButtonGroup
        keyToGetValue="value"
        className="model-factor"
        onChange={selectProductLifeReady}
        value={getSelectedProductLifeReady}
        options={productLifeReady}
      />
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  getSelectedProductLifeReady: getSelectedProductLifeReady(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  selectProductLifeReady: bindActionCreators(selectProductLifeReady, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(LifeReadyProductSelection)
