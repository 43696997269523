import { checker } from '../selectors'

import { createSectionCreator, createField } from '../utils'
import { reduce, endsWith } from 'lodash/fp'

const reasonInputField = (id) =>
  createField(`${id}.detail`, [
    {
      label: 'โปรดระบุรายละเอียด',
      id: `${id}.detail`,
      c: 'TextInput',
      p: {},
    },
  ])

const getQuestions = (app, id) => {
  const { isYes } = checker(app)

  const questions = [
    createField(`${id}.healthDiagnosis.label`, [
      {
        id: `${id}.healthDiagnosis.label`,
        c: 'PlainText',
        p: {
          children:
            'ท่านเคยได้รับการวินิจฉัย หรือรับการรักษา หรือตั้งข้อสังเกตจากแพทย์ว่าป่วยเป็นโรค ตามรายการต่อไปนี้หรือไม่',
        },
      },
    ]),
    createField(`${id}.healthDiagnosis.a.checked`, [
      {
        id: `${id}.healthDiagnosis.a.checked`,
        c: 'RadioButton',
        required: true,
        label:
          'โรคต่อมทอนซิลอักเสบเรื้อรัง, โรคกรดไหลย้อน, โรคนิ่ว, โรคถุงน้ําดีอักเสบ, โรคไส้เลื่อน, โรคริดสีดวงทวาร, ฝีคันฑสูตร, โรคภูมิแพ้ , โรคไซนัสอักเสบ, โรคออทิสติก, โรคหลอดลมอักเสบเรื้อรัง, โรคติดเชื้อในหูชั้นกลาง, โรคปวดศีรษะไมเกรน, โรคสมาธิสั้น, เยื่อบุมดลูกเจริญผิดที่',
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
    createField(`${id}.healthDiagnosis.b.checked`, [
      {
        id: `${id}.healthDiagnosis.b.checked`,
        c: 'RadioButton',
        required: true,
        label:
          'โรคกระดูกสันหลังเคลื่อน, โรคหมอนรองกระดูกเคลื่อนหรือ ทับเส้นประสาท, โรคข้อเสื่อม, โรคเส้นเอ็นอักเสบเรื้อรัง, โรคเส้นประสาทอักเสบหรือถูกเบียดทับ',
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
  ]

  return reduce((questions, question) => {
    if (isYes(question.id) && !endsWith('label', question.id)) {
      return questions.concat(question).concat(reasonInputField(question.id))
    }
    return questions.concat(question)
  }, [])(questions)
}

export default createSectionCreator(getQuestions)
