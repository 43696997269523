// @flow

import { formatNumber } from 'core/service/lib/number-format'
import type { YearEndPolicyValue } from 'core/service/benefit-illustration'

export const dashedOutOrWithPrecision = (value: number, precision: number, isRound: boolean = true) =>
  value === 0 ? '-' : formatNumber(value, precision, isRound)

export const PolicyValueRow = ({
  age,
  yearEndPolicy,
  cumulativeBasicPremium,
  annualBasicPremium,
  surrenderCash,
  annualRiderPremium,
  annualTotalPremium,
  deathBenefit,
  children,
}: YearEndPolicyValue & { children?: React.Node }) => {
  return (
    <tr id={`policy-value-row-${age.value}`}>
      <td className="policy-value-age">{age.value}</td>
      <td className="policy-value-year">{yearEndPolicy}</td>
      <td className="policy-value-cumulative-basic-premium">{dashedOutOrWithPrecision(cumulativeBasicPremium, 2)}</td>
      <td className="policy-value-annual-basic-premium">{dashedOutOrWithPrecision(annualBasicPremium, 2)}</td>
      <td className="policy-value-annual-rider-premium">{dashedOutOrWithPrecision(annualRiderPremium, 2)}</td>
      <td className="policy-value-annual-total-premium">{dashedOutOrWithPrecision(annualTotalPremium, 2)}</td>
      {children}
      <td className="policy-value-death-benefit">{dashedOutOrWithPrecision(deathBenefit, 2)}</td>
      <td className="policy-value-surrender-cash">{dashedOutOrWithPrecision(surrenderCash, 0)}</td>
    </tr>
  )
}

export const PolicyValueRowICare = ({
  age,
  yearEndPolicy,
  cumulativeBasicPremium,
  annualBasicPremium,
  surrenderCash,
  annualRiderPremium,
  annualTotalPremium,
  cumulativeDeathBenefit,
  deathBenefit,
  children,
}: YearEndPolicyValue & { children?: React.Node } & {
  cumulativeDeathBenefit?: number,
}) => {
  return (
    <tr id={`policy-value-row-${age.value}`}>
      <td className="policy-value-age">{age.value}</td>
      <td className="policy-value-year">{yearEndPolicy}</td>
      <td className="policy-value-annual-basic-premium">{dashedOutOrWithPrecision(annualBasicPremium, 2)}</td>
      <td className="policy-value-annual-rider-premium">{dashedOutOrWithPrecision(annualRiderPremium, 2)}</td>
      <td className="policy-value-annual-total-premium">{dashedOutOrWithPrecision(annualTotalPremium, 2)}</td>
      {children}
      <td className="policy-value-cumulative-basic-premium">{dashedOutOrWithPrecision(cumulativeBasicPremium, 2)}</td>
      <td className="policy-value-surrender-cash">{dashedOutOrWithPrecision(surrenderCash, 0)}</td>
      <td className="policy-value-death-benefit">{dashedOutOrWithPrecision(deathBenefit, 2)}</td>
    </tr>
  )
}
