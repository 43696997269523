//@flow
import { isOnFnaPhase } from 'quick-quote/fna/selectors'
import {
  canProceedToFundAllocation,
  canProceedToFundAndCoveragePlan,
  canProceedToRiskQuestionnaire,
} from 'quick-quote/product-investment/journey-control-selectors'
import { canProceedPerfectLifeBenefitIllustration } from 'quick-quote/product-perfect-life/coverage-plan/selectors'
import { canProceedRetirementBenefitIllustration } from 'quick-quote/product-retirement/coverage-plan/selectors'
import { canProceedBumnanReadyBenefitIllustration } from 'quick-quote/product-bumnan-ready/coverage-plan/selectors'
import { canProceedToInsuredInformation } from 'quick-quote/product-selection/selectors'
import { canProceedToBenefitIllustration } from 'quick-quote/product-whole-life/coverage-plan/selectors'
import { canProceedToCoveragePlan } from 'quick-quote/selectors/next-tab-proceeding'
import { canProceedToSelectPolicyOwner, canProceedToNormalTab } from 'quick-quote/product-s7cc/selectors'
import TABS from '../../../../core/data-model/constants/tab-messages'
import { PATH, qqPath, ROUTE_PATH } from '../../../constants/path'
import type { AppState } from '../../../reducers'
import type { Tab } from '../../navigation-item'

export type NavTabConfig = {
  path: string,
  tabs: Tab[],
}

export const wholeLife = (state: AppState): NavTabConfig => {
  return {
    path: qqPath(PATH.WHOLE_LIFE),
    tabs: [
      {
        title: TABS.NEED_AND_GAP_ANALYSIS,
        path: qqPath(ROUTE_PATH.WHOLE_LIFE.NEED_AND_GAP_ANALYSIS),
        id: 'need',
        disabled: false,
        hide: !isOnFnaPhase(state),
        component: null,
      },
      {
        title: TABS.PRODUCT_SELECTION,
        path: qqPath(ROUTE_PATH.WHOLE_LIFE.PRODUCT_SELECTION),
        id: 'product-selection',
        disabled: false,
        component: null,
      },
      {
        title: TABS.MARKET_CONDUCTS,
        path: qqPath(ROUTE_PATH.WHOLE_LIFE.MARKET_CONDUCTS),
        id: 'market-conduct',
        hide: !isOnFnaPhase(state),
        disabled: false,
        component: null,
      },
      {
        title: TABS.INSURED_PERSON,
        path: qqPath(ROUTE_PATH.WHOLE_LIFE.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: qqPath(ROUTE_PATH.WHOLE_LIFE.COVERAGE_PLAN),
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: qqPath(ROUTE_PATH.WHOLE_LIFE.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: !canProceedToBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}

export const lifeReady = (state: AppState): NavTabConfig => {
  return {
    path: qqPath(PATH.LIFE_READY),
    tabs: [
      {
        title: TABS.NEED_AND_GAP_ANALYSIS,
        path: qqPath(ROUTE_PATH.LIFE_READY.NEED_AND_GAP_ANALYSIS),
        id: 'need',
        disabled: false,
        hide: !isOnFnaPhase(state),
        component: null,
      },
      {
        title: TABS.PRODUCT_SELECTION,
        path: qqPath(ROUTE_PATH.LIFE_READY.PRODUCT_SELECTION),
        id: 'product-selection',
        disabled: false,
        component: null,
      },
      {
        title: TABS.MARKET_CONDUCTS,
        path: qqPath(ROUTE_PATH.LIFE_READY.MARKET_CONDUCTS),
        id: 'market-conduct',
        hide: !isOnFnaPhase(state),
        disabled: false,
        component: null,
      },
      {
        title: TABS.INSURED_PERSON,
        path: qqPath(ROUTE_PATH.LIFE_READY.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: qqPath(ROUTE_PATH.LIFE_READY.COVERAGE_PLAN),
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: qqPath(ROUTE_PATH.LIFE_READY.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: !canProceedToBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}

export const mrta = (state: AppState): NavTabConfig => {
  return {
    path: qqPath(PATH.MRTA),
    tabs: [
      {
        title: TABS.NEED_AND_GAP_ANALYSIS,
        path: qqPath(ROUTE_PATH.MRTA.NEED_AND_GAP_ANALYSIS),
        id: 'need',
        disabled: false,
        hide: !isOnFnaPhase(state),
        component: null,
      },
      {
        title: TABS.PRODUCT_SELECTION,
        path: qqPath(ROUTE_PATH.MRTA.PRODUCT_SELECTION),
        id: 'product-selection',
        disabled: false,
        component: null,
      },
      {
        title: TABS.MARKET_CONDUCTS,
        path: qqPath(ROUTE_PATH.MRTA.MARKET_CONDUCTS),
        id: 'market-conduct',
        hide: !isOnFnaPhase(state),
        disabled: false,
        component: null,
      },
      {
        title: TABS.INSURED_PERSON,
        path: qqPath(ROUTE_PATH.MRTA.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: qqPath(ROUTE_PATH.MRTA.COVERAGE_PLAN),
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: qqPath(ROUTE_PATH.MRTA.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: !canProceedToBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}

export const protection = (state: AppState): NavTabConfig => {
  return {
    path: qqPath(PATH.PROTECTION),
    tabs: [
      {
        title: TABS.NEED_AND_GAP_ANALYSIS,
        path: qqPath(ROUTE_PATH.PROTECTION.NEED_AND_GAP_ANALYSIS),
        id: 'need',
        disabled: false,
        hide: !isOnFnaPhase(state),
        component: null,
      },
      {
        title: TABS.PRODUCT_SELECTION,
        path: qqPath(ROUTE_PATH.PROTECTION.PRODUCT_SELECTION),
        id: 'product-selection',
        disabled: false,
        component: null,
      },
      {
        title: TABS.MARKET_CONDUCTS,
        path: qqPath(ROUTE_PATH.PROTECTION.MARKET_CONDUCTS),
        id: 'market-conduct',
        hide: !isOnFnaPhase(state),
        disabled: false,
        component: null,
      },
      {
        title: TABS.INSURED_PERSON,
        path: qqPath(ROUTE_PATH.PROTECTION.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: qqPath(ROUTE_PATH.PROTECTION.COVERAGE_PLAN),
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: qqPath(ROUTE_PATH.PROTECTION.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: !canProceedToBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}

export const flexiHealth = (state: AppState): NavTabConfig => {
  return {
    path: qqPath(PATH.FLEXI_HEALTH),
    tabs: [
      {
        title: TABS.NEED_AND_GAP_ANALYSIS,
        path: qqPath(ROUTE_PATH.FLEXI_HEALTH.NEED_AND_GAP_ANALYSIS),
        id: 'need',
        disabled: false,
        hide: !isOnFnaPhase(state),
        component: null,
      },
      {
        title: TABS.PRODUCT_SELECTION,
        path: qqPath(ROUTE_PATH.FLEXI_HEALTH.PRODUCT_SELECTION),
        id: 'product-selection',
        disabled: false,
        component: null,
      },
      {
        title: TABS.MARKET_CONDUCTS,
        path: qqPath(ROUTE_PATH.FLEXI_HEALTH.MARKET_CONDUCTS),
        id: 'market-conduct',
        hide: !isOnFnaPhase(state),
        disabled: false,
        component: null,
      },
      {
        title: TABS.INSURED_PERSON,
        path: qqPath(ROUTE_PATH.FLEXI_HEALTH.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: qqPath(ROUTE_PATH.FLEXI_HEALTH.COVERAGE_PLAN),
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: qqPath(ROUTE_PATH.FLEXI_HEALTH.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: !canProceedToBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}
export const healthTopping = (state: AppState): NavTabConfig => {
  return {
    path: qqPath(PATH.HEALTH_TOPPING),
    tabs: [
      {
        title: TABS.NEED_AND_GAP_ANALYSIS,
        path: qqPath(ROUTE_PATH.HEALTH_TOPPING.NEED_AND_GAP_ANALYSIS),
        id: 'need',
        disabled: false,
        hide: !isOnFnaPhase(state),
        component: null,
      },
      {
        title: TABS.PRODUCT_SELECTION,
        path: qqPath(ROUTE_PATH.HEALTH_TOPPING.PRODUCT_SELECTION),
        id: 'product-selection',
        disabled: false,
        component: null,
      },
      {
        title: TABS.MARKET_CONDUCTS,
        path: qqPath(ROUTE_PATH.HEALTH_TOPPING.MARKET_CONDUCTS),
        id: 'market-conduct',
        hide: !isOnFnaPhase(state),
        disabled: false,
        component: null,
      },
      {
        title: TABS.INSURED_PERSON,
        path: qqPath(ROUTE_PATH.HEALTH_TOPPING.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: qqPath(ROUTE_PATH.HEALTH_TOPPING.COVERAGE_PLAN),
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: qqPath(ROUTE_PATH.HEALTH_TOPPING.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: !canProceedToBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}
export const health = (state: AppState): NavTabConfig => {
  return {
    path: qqPath(PATH.HEALTH),
    tabs: [
      {
        title: TABS.NEED_AND_GAP_ANALYSIS,
        path: qqPath(ROUTE_PATH.HEALTH.NEED_AND_GAP_ANALYSIS),
        id: 'need',
        disabled: false,
        hide: !isOnFnaPhase(state),
        component: null,
      },
      {
        title: TABS.PRODUCT_SELECTION,
        path: qqPath(ROUTE_PATH.HEALTH.PRODUCT_SELECTION),
        id: 'product-selection',
        disabled: false,
        component: null,
      },
      {
        title: TABS.MARKET_CONDUCTS,
        path: qqPath(ROUTE_PATH.HEALTH.MARKET_CONDUCTS),
        id: 'market-conduct',
        hide: !isOnFnaPhase(state),
        disabled: false,
        component: null,
      },
      {
        title: TABS.INSURED_PERSON,
        path: qqPath(ROUTE_PATH.HEALTH.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: qqPath(ROUTE_PATH.HEALTH.COVERAGE_PLAN),
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: qqPath(ROUTE_PATH.HEALTH.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: !canProceedToBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}

export const ihealthyUltra = (state: AppState): NavTabConfig => {
  return {
    path: qqPath(PATH.IHEALTHY_ULTRA),
    tabs: [
      {
        title: TABS.NEED_AND_GAP_ANALYSIS,
        path: qqPath(ROUTE_PATH.IHEALTHY_ULTRA.NEED_AND_GAP_ANALYSIS),
        id: 'need',
        disabled: false,
        hide: !isOnFnaPhase(state),
        component: null,
      },
      {
        title: TABS.PRODUCT_SELECTION,
        path: qqPath(ROUTE_PATH.IHEALTHY_ULTRA.PRODUCT_SELECTION),
        id: 'product-selection',
        disabled: false,
        component: null,
      },
      {
        title: TABS.MARKET_CONDUCTS,
        path: qqPath(ROUTE_PATH.IHEALTHY_ULTRA.MARKET_CONDUCTS),
        id: 'market-conduct',
        hide: !isOnFnaPhase(state),
        disabled: false,
        component: null,
      },
      {
        title: TABS.INSURED_PERSON,
        path: qqPath(ROUTE_PATH.IHEALTHY_ULTRA.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: qqPath(ROUTE_PATH.IHEALTHY_ULTRA.COVERAGE_PLAN),
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: qqPath(ROUTE_PATH.IHEALTHY_ULTRA.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: !canProceedToBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}

export const investmentV2 = (state: AppState): NavTabConfig => {
  return {
    path: qqPath(PATH.INVESTMENT),
    tabs: [
      {
        title: TABS.NEED_AND_GAP_ANALYSIS,
        path: qqPath(ROUTE_PATH.INVESTMENT.NEED_AND_GAP_ANALYSIS),
        id: 'need',
        disabled: false,
        hide: !isOnFnaPhase(state),
        component: null,
      },
      {
        title: TABS.PRODUCT_SELECTION,
        path: qqPath(ROUTE_PATH.INVESTMENT.PRODUCT_SELECTION),
        id: 'product-selection',
        disabled: false,
        component: null,
      },
      {
        title: TABS.MARKET_CONDUCTS,
        path: qqPath(ROUTE_PATH.INVESTMENT.MARKET_CONDUCTS),
        id: 'market-conduct',
        hide: !isOnFnaPhase(state),
        disabled: false,
        component: null,
      },
      {
        title: TABS.INSURED_PERSON,
        path: qqPath(ROUTE_PATH.INVESTMENT.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.PREMIUM,
        path: qqPath(ROUTE_PATH.INVESTMENT.COVERAGE_PLAN),
        id: 'premium',
        disabled: !canProceedToRiskQuestionnaire(state),
        component: null,
      },
      {
        title: TABS.RISK,
        path: qqPath(ROUTE_PATH.INVESTMENT.RISK_QUESTIONNAIRE),
        id: 'invest',
        disabled: !canProceedToRiskQuestionnaire(state),
        component: null,
      },
      {
        title: TABS.FUND,
        path: qqPath(ROUTE_PATH.INVESTMENT.FUND_ALLOCATION),
        id: 'fund',
        disabled: !canProceedToFundAllocation(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: qqPath(ROUTE_PATH.INVESTMENT.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: !canProceedToFundAllocation(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}

export const investment = (state: AppState): NavTabConfig => {
  return {
    path: qqPath(PATH.INVESTMENT),
    tabs: [
      {
        title: TABS.NEED_AND_GAP_ANALYSIS,
        path: qqPath(ROUTE_PATH.INVESTMENT.NEED_AND_GAP_ANALYSIS),
        id: 'need',
        disabled: false,
        hide: !isOnFnaPhase(state),
        component: null,
      },
      {
        title: TABS.PRODUCT_SELECTION,
        path: qqPath(ROUTE_PATH.INVESTMENT.PRODUCT_SELECTION),
        id: 'product-selection',
        disabled: false,
        component: null,
      },
      {
        title: TABS.MARKET_CONDUCTS,
        path: qqPath(ROUTE_PATH.INVESTMENT.MARKET_CONDUCTS),
        id: 'market-conduct',
        hide: !isOnFnaPhase(state),
        disabled: false,
        component: null,
      },
      {
        title: TABS.INSURED_PERSON,
        path: qqPath(ROUTE_PATH.INVESTMENT.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.RISK,
        path: qqPath(ROUTE_PATH.INVESTMENT.RISK_QUESTIONNAIRE),
        id: 'invest',
        disabled: !canProceedToRiskQuestionnaire(state),
        component: null,
      },
      {
        title: TABS.FUND,
        path: qqPath(ROUTE_PATH.INVESTMENT.FUND_ALLOCATION),
        id: 'fund',
        disabled: !canProceedToFundAndCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.PREMIUM,
        path: qqPath(ROUTE_PATH.INVESTMENT.COVERAGE_PLAN),
        id: 'premium',
        disabled: !canProceedToFundAndCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: qqPath(ROUTE_PATH.INVESTMENT.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: !canProceedToFundAndCoveragePlan(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}

export const loan = (state: AppState): NavTabConfig => {
  return {
    path: qqPath(PATH.LOAN),
    tabs: [
      {
        title: TABS.NEED_AND_GAP_ANALYSIS,
        path: qqPath(ROUTE_PATH.LOAN.NEED_AND_GAP_ANALYSIS),
        id: 'need',
        disabled: false,
        hide: !isOnFnaPhase(state),
        component: null,
      },
      {
        title: TABS.PRODUCT_SELECTION,
        path: qqPath(ROUTE_PATH.LOAN.PRODUCT_SELECTION),
        id: 'product-selection',
        disabled: false,
        component: null,
      },
      {
        title: TABS.MARKET_CONDUCTS,
        path: qqPath(ROUTE_PATH.LOAN.MARKET_CONDUCTS),
        id: 'market-conduct',
        hide: !isOnFnaPhase(state),
        disabled: false,
        component: null,
      },
      {
        title: TABS.INSURED_PERSON,
        path: qqPath(ROUTE_PATH.LOAN.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: qqPath(ROUTE_PATH.LOAN.COVERAGE_PLAN),
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: qqPath(ROUTE_PATH.LOAN.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: false,
        component: null,
      },
    ],
  }
}
export const saving = (state: AppState): NavTabConfig => {
  return {
    path: qqPath(PATH.SAVING),
    tabs: [
      {
        title: TABS.NEED_AND_GAP_ANALYSIS,
        path: qqPath(ROUTE_PATH.SAVING.NEED_AND_GAP_ANALYSIS),
        id: 'need',
        disabled: false,
        hide: !isOnFnaPhase(state),
        component: null,
      },
      {
        title: TABS.PRODUCT_SELECTION,
        path: qqPath(ROUTE_PATH.SAVING.PRODUCT_SELECTION),
        id: 'product-selection',
        disabled: false,
        component: null,
      },
      {
        title: TABS.MARKET_CONDUCTS,
        path: qqPath(ROUTE_PATH.SAVING.MARKET_CONDUCTS),
        id: 'market-conduct',
        hide: !isOnFnaPhase(state),
        disabled: false,
        component: null,
      },
      {
        title: TABS.INSURED_PERSON,
        path: qqPath(ROUTE_PATH.SAVING.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: qqPath(ROUTE_PATH.SAVING.COVERAGE_PLAN),
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: qqPath(ROUTE_PATH.SAVING.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: !canProceedToBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}
export const lifeSavePro = (state: AppState): NavTabConfig => {
  return {
    path: qqPath(PATH.LIFE_SAVE_PRO),
    tabs: [
      {
        title: TABS.NEED_AND_GAP_ANALYSIS,
        path: qqPath(ROUTE_PATH.LIFE_SAVE_PRO.NEED_AND_GAP_ANALYSIS),
        id: 'need',
        disabled: false,
        hide: !isOnFnaPhase(state),
        component: null,
      },
      {
        title: TABS.PRODUCT_SELECTION,
        path: qqPath(ROUTE_PATH.LIFE_SAVE_PRO.PRODUCT_SELECTION),
        id: 'product-selection',
        disabled: false,
        component: null,
      },
      {
        title: TABS.MARKET_CONDUCTS,
        path: qqPath(ROUTE_PATH.LIFE_SAVE_PRO.MARKET_CONDUCTS),
        id: 'market-conduct',
        hide: !isOnFnaPhase(state),
        disabled: false,
        component: null,
      },
      {
        title: TABS.INSURED_PERSON,
        path: qqPath(ROUTE_PATH.LIFE_SAVE_PRO.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: qqPath(ROUTE_PATH.LIFE_SAVE_PRO.COVERAGE_PLAN),
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: qqPath(ROUTE_PATH.LIFE_SAVE_PRO.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: !canProceedToBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}

export const retirement = (state: AppState): NavTabConfig => {
  return {
    path: qqPath(PATH.RETIREMENT),
    tabs: [
      {
        title: TABS.NEED_AND_GAP_ANALYSIS,
        path: qqPath(ROUTE_PATH.RETIREMENT.NEED_AND_GAP_ANALYSIS),
        id: 'need',
        disabled: false,
        hide: !isOnFnaPhase(state),
        component: null,
      },
      {
        title: TABS.PRODUCT_SELECTION,
        path: qqPath(ROUTE_PATH.RETIREMENT.PRODUCT_SELECTION),
        id: 'product-selection',
        disabled: false,
        component: null,
      },
      {
        title: TABS.MARKET_CONDUCTS,
        path: qqPath(ROUTE_PATH.RETIREMENT.MARKET_CONDUCTS),
        id: 'market-conduct',
        hide: !isOnFnaPhase(state),
        disabled: false,
        component: null,
      },
      {
        title: TABS.INSURED_PERSON,
        path: qqPath(ROUTE_PATH.RETIREMENT.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: qqPath(ROUTE_PATH.RETIREMENT.COVERAGE_PLAN),
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: qqPath(ROUTE_PATH.RETIREMENT.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: !canProceedRetirementBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}

export const bumnanReady = (state: AppState): NavTabConfig => {
  return {
    path: qqPath(PATH.BUMNAN_READY),
    tabs: [
      {
        title: TABS.NEED_AND_GAP_ANALYSIS,
        path: qqPath(ROUTE_PATH.BUMNAN_READY.NEED_AND_GAP_ANALYSIS),
        id: 'need',
        disabled: false,
        hide: !isOnFnaPhase(state),
        component: null,
      },
      {
        title: TABS.PRODUCT_SELECTION,
        path: qqPath(ROUTE_PATH.BUMNAN_READY.PRODUCT_SELECTION),
        id: 'product-selection',
        disabled: false,
        component: null,
      },
      {
        title: TABS.MARKET_CONDUCTS,
        path: qqPath(ROUTE_PATH.BUMNAN_READY.MARKET_CONDUCTS),
        id: 'market-conduct',
        hide: !isOnFnaPhase(state),
        disabled: false,
        component: null,
      },
      {
        title: TABS.INSURED_PERSON,
        path: qqPath(ROUTE_PATH.BUMNAN_READY.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: qqPath(ROUTE_PATH.BUMNAN_READY.COVERAGE_PLAN),
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: qqPath(ROUTE_PATH.BUMNAN_READY.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: !canProceedBumnanReadyBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}

export const perfectLife = (state: AppState): NavTabConfig => {
  return {
    path: qqPath(PATH.PERFECT_LIFE),
    tabs: [
      {
        title: TABS.NEED_AND_GAP_ANALYSIS,
        path: qqPath(ROUTE_PATH.PERFECT_LIFE.NEED_AND_GAP_ANALYSIS),
        id: 'need',
        disabled: false,
        hide: !isOnFnaPhase(state),
        component: null,
      },
      {
        title: TABS.PRODUCT_SELECTION,
        path: qqPath(ROUTE_PATH.PERFECT_LIFE.PRODUCT_SELECTION),
        id: 'product-selection',
        disabled: false,
        component: null,
      },
      {
        title: TABS.MARKET_CONDUCTS,
        path: qqPath(ROUTE_PATH.PERFECT_LIFE.MARKET_CONDUCTS),
        id: 'market-conduct',
        hide: !isOnFnaPhase(state),
        disabled: false,
        component: null,
      },
      {
        title: TABS.INSURED_PERSON,
        path: qqPath(ROUTE_PATH.PERFECT_LIFE.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: qqPath(ROUTE_PATH.PERFECT_LIFE.COVERAGE_PLAN),
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: qqPath(ROUTE_PATH.PERFECT_LIFE.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: !canProceedPerfectLifeBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}

export const s7cc = (state: AppState): NavTabConfig => {
  return {
    path: qqPath(PATH.S7CC),
    tabs: [
      {
        title: TABS.NEED_AND_GAP_ANALYSIS,
        path: qqPath(ROUTE_PATH.S7CC.NEED_AND_GAP_ANALYSIS),
        id: 'need',
        disabled: false,
        hide: !isOnFnaPhase(state),
        component: null,
      },
      {
        title: TABS.PRODUCT_SELECTION,
        path: qqPath(ROUTE_PATH.S7CC.PRODUCT_SELECTION),
        id: 'product-selection',
        disabled: false,
        component: null,
      },
      {
        title: TABS.MARKET_CONDUCTS,
        path: qqPath(ROUTE_PATH.S7CC.MARKET_CONDUCTS),
        id: 'market-conduct',
        hide: !isOnFnaPhase(state),
        disabled: false,
        component: null,
      },
      {
        title: TABS.MATURITY_VALIDATE,
        path: qqPath(ROUTE_PATH.S7CC.MATURITY_VALIDATE),
        // id: 'insured-information',
        id: 'maturity-validate',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.SELECT_POLICY_OWNER,
        path: qqPath(ROUTE_PATH.S7CC.SELECT_POLICY_OWNER),
        // id: 'insured-information',
        id: 'select-policy-owner',
        disabled: !canProceedToSelectPolicyOwner(state),
        component: null,
      },
      {
        title: TABS.INSURED_PERSON,
        path: qqPath(ROUTE_PATH.S7CC.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToNormalTab(state) || !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: qqPath(ROUTE_PATH.S7CC.COVERAGE_PLAN),
        id: 'calculate-premium',
        disabled: !canProceedToNormalTab(state) || !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: qqPath(ROUTE_PATH.S7CC.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: !canProceedToNormalTab(state) || !canProceedToBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}

export const qqProductSelection = (state: AppState): NavTabConfig => {
  return {
    path: qqPath(PATH.PRODUCT_SELECTION),
    tabs: [
      {
        title: TABS.PRODUCT_SELECTION,
        path: qqPath(ROUTE_PATH.PRODUCT_SELECTION),
        id: 'product-selection',
        disabled: false,
        component: null,
      },
      {
        title: TABS.INSURED_PERSON,
        path: '',
        id: 'insured-information',
        disabled: true,
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: '',
        id: 'calculate-premium',
        disabled: true,
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: '',
        id: 'benefit-illustration',
        disabled: true,
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}

export default (state: AppState): NavTabConfig[] => [
  wholeLife(state),
  lifeReady(state),
  investmentV2(state),
  loan(state),
  healthTopping(state),
  health(state),
  ihealthyUltra(state),
  saving(state),
  protection(state),
  lifeSavePro(state),
  flexiHealth(state),
  retirement(state),
  bumnanReady(state),
  perfectLife(state),
  s7cc(state),
  mrta(state),
  qqProductSelection(state),
]
