// @flow
import VALUES from 'core/data-model/constants/values'
import MESSAGES from 'core/data-model/constants/messages'
import BIMESSAGES from 'core/data-model/constants/bi-messages'
import SummaryTable from './summary-table'
import { renderBIAge } from 'core/service/insured/age'
import { formatNumber } from 'core/service/lib/number-format'

import type { Age } from 'core/data-model/insured'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { DisplayProduct } from 'core/service/display-product'
import { getMRTATRUNCATELoanPeriod } from 'quick-quote/product-mrta/coverage-plan/selectors'

export type ProfileSnapshotProps = {
  displayProduct: ?DisplayProduct,
  riders: (Rider & RiderState)[],
  genderLabel: string,
  age: Age,
  calculatedCoveragePeriod: number,
  loanAmount: number,
  loanPeriod: number,
  sumAssured: number,
  paymentMethod: string,
  basicPremium: number,
  totalPremium: number,
  totalRiderPremium: number,
  sumAssuredContract: number,
}

const ProfileSnapshot = ({
  displayProduct,
  riders,
  genderLabel,
  age,
  calculatedCoveragePeriod,
  loanAmount,
  loanPeriod,
  sumAssured,
  paymentMethod,
  basicPremium,
  totalPremium,
  totalRiderPremium,
  sumAssuredContract,
  isReviseQQ,
}: ProfileSnapshotProps) => {
  let planName
  switch (displayProduct.basicPlanCode) {
    case VALUES.MRTA_CODE.MRTA_FULL:
      planName = BIMESSAGES.MRTA_FULL_EN_NAME
      break
    case VALUES.MRTA_CODE.MRTA_PLUS:
      planName = BIMESSAGES.MRTA_PLUS_EN_NAME
      break
    case VALUES.MRTA_CODE.MRTA_TRUNCATE:
      planName = BIMESSAGES.MRTA_TRUNCATE_EN_NAME
      break
  }
  return (
    <div id="profile-summary" className="snapshot group">
      <h2>
        <span>{displayProduct.nameThai}</span>
        {displayProduct.basicPlanCode !== VALUES.MRTA_CODE.MRTA_TRUNCATE ? <br /> : ' '}
        <span>
          ({planName}) ({displayProduct.code})
        </span>
      </h2>
      <div className="profile">
        <div className="info gender">
          <p className="label">{MESSAGES.GENDER}</p>
          <p className="value">{genderLabel}</p>
        </div>
        <div className="info age">
          <p className="label">{MESSAGES.AGE}</p>
          <p className="value">{renderBIAge(age, isReviseQQ)}</p>
        </div>
        <div className="info coverage-period">
          <p className="label">{BIMESSAGES.LOAN_PERIOD_INSURED}</p>
          <p className="value">
            {displayProduct.basicPlanCode !== VALUES.MRTA_CODE.MRTA_TRUNCATE
              ? calculatedCoveragePeriod
              : getMRTATRUNCATELoanPeriod(loanPeriod, age)}{' '}
            {MESSAGES.TIME_UNIT_YEAR}
          </p>
        </div>
        <div className="info payment-period">
          <p className="label">{BIMESSAGES.COVERAGE_PERIOD_INSURED}</p>
          <p className="value">
            {calculatedCoveragePeriod} {MESSAGES.TIME_UNIT_YEAR}
          </p>
        </div>
      </div>

      <div className="coverage" style={{ borderBottom: '0px' }}>
        <div className="info sum-assured" style={{ maxWidth: '50%' }}>
          <p className="label">{BIMESSAGES.COVERAGE_BASIC_PLAN}</p>
          <p className="value">{BIMESSAGES.COVERAGE_BASIC_PLAN_MESSAGE}</p>
        </div>
        {/* for MRTA Full don't show this sections */}
        {displayProduct.basicPlanCode !== VALUES.MRTA_CODE.MRTA_FULL && (
          <div className="info model-factor" style={{ maxWidth: '50%' }}>
            <p className="label">{BIMESSAGES.COVERAGE_RIDER}</p>
            <p className="value">{BIMESSAGES.COVERAGE_RIDER_MESSAGE}</p>
          </div>
        )}
      </div>

      <div className="coverage">
        <div className="info sum-assured" style={{ maxWidth: '50%' }}>
          <p className="label">{BIMESSAGES.AMOUNT_INSURED_OF_BASIC_PLAN}</p>
          <p className="value">
            {formatNumber(sumAssuredContract)} {MESSAGES.BAHT}
          </p>
        </div>
        {/* for MRTA Full don't show this text */}
        {displayProduct.basicPlanCode !== VALUES.MRTA_CODE.MRTA_FULL && (
          <div className="info basic-premium" style={{ maxWidth: '50%' }}>
            <p className="label">{BIMESSAGES.AMOUNT_RIDER_ACCIDENT}</p>
            <p className="value">
              {displayProduct.basicPlanCode !== VALUES.MRTA_CODE.MRTA_FULL ? formatNumber(riders[0].sumAssured) : 0}{' '}
              {MESSAGES.BAHT}
            </p>
          </div>
        )}
      </div>

      <div className="coverage">
        <div className="info model-factor" style={{ maxWidth: '50%' }}>
          <p className="label">{BIMESSAGES.AMOUNT_LOAN}</p>
          <p className="value">
            {/* Loan amount */}
            {formatNumber(loanAmount)} {MESSAGES.BAHT}
          </p>
        </div>
        <div className="info rider-premium" style={{ maxWidth: '50%' }}>
          <p className="label">{BIMESSAGES.COVERAGE_LOAN_PERIOD_BANK}</p>
          <p className="value">
            {loanPeriod} {MESSAGES.TIME_UNIT_YEAR}
          </p>
        </div>
      </div>

      <SummaryTable
        displayProduct={displayProduct}
        riders={riders}
        basicPremium={basicPremium}
        basicSumAssured={sumAssured}
        paymentMethod={paymentMethod}
        totalPremium={totalPremium}
        totalRiderPremium={totalRiderPremium}
        sumAssuredContract={sumAssuredContract}
      />
      <br />
      <div className="disclaimer">
        <div>{BIMESSAGES.NOTE}</div>
        <ol className="counter">
          <li> {BIMESSAGES.REMARK_OVERALL_SUMMARY_MRTA[0]}</li>
          <li> {BIMESSAGES.REMARK_OVERALL_SUMMARY_MRTA[1]}</li>
        </ol>
      </div>
    </div>
  )
}

export default ProfileSnapshot
