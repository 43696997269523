// @flow
import type { AppState } from 'quick-quote/reducers'
import { FormGroup, Input } from 'reactstrap'
import { connect } from 'react-redux'
import NumberFormat from 'react-number-format'
import type { Option } from 'common-components/dropdown'
import Dropdown from 'common-components/dropdown'

import MESSAGES from 'core/data-model/constants/messages'
import {
  getRelationship,
  getThirdPersonFullName,
  getThirdPersonPhoneNumber,
  getThirdPersonRelationship,
  getTitle,
  getThirdPersonTitle,
  getThirdPersonTitleText,
  getThirdPersonFirstName,
  getThirdPersonLastName,
} from 'quick-quote/fna/market-conducts/selectors'
import {
  setFnaMarketConductThirdPersonFullName,
  setFnaMarketConductThirdPersonPhoneNumber,
  setFnaMarketConductThirdPersonRelationship,
  setFnaMarketConductThirdPersonTitle,
  setFnaMarketConductThirdPersonTitleText,
  setFnaMarketConductThirdPersonFirstName,
  setFnaMarketConductThirdPersonLastName,
} from 'quick-quote/fna/market-conducts/actions'
import { getToggles } from '../../../feature-toggles'

type Props = {
  thirdPersonName: string,
  relationship: string,
  options: Option[],
  phoneNumber: string,
  editName: (string) => void,
  selectRelationship: (string) => void,
  editPhoneNumber: (string) => void,
  title: string,
  titleOptions: Option[],
  selectTitle: (string) => void,
  titleText: string,
  editTitleText: (string) => void,
  thirdPersonFirstName: string,
  editFirstName: (string) => void,
  thirdPersonLastName: string,
  editLastName: (string) => void,
}

const ThirdPersonPhase1Component = ({
  thirdPersonName,
  editName,
  relationship,
  options,
  selectRelationship,
  phoneNumber,
  editPhoneNumber,
}) => {
  return (
    <FormGroup className="third-person">
      <div className="name-input">
        <label>{MESSAGES.THIRD_PARTY_NAME}</label>
        <input
          id="fullName"
          className="form-control"
          type="text"
          value={thirdPersonName}
          maxLength="100"
          onChange={(e) => editName(e.target.value)}
        />
      </div>
      <div className="relationship">
        <label>{MESSAGES.THIRD_PARTY_RELATIONSHIP}</label>
        <Dropdown id="relationship" value={relationship} options={options} onChange={(e) => selectRelationship(e)} />
      </div>
      <div className="phoneNumber">
        <label>{MESSAGES.THIRD_PARTY_TELEPHONE}</label>
        <NumberFormat
          id="phoneNumber"
          customInput={Input}
          value={phoneNumber}
          format={'### ### ####'}
          onChange={(e) => editPhoneNumber(e.target.value)}
        />
      </div>
    </FormGroup>
  )
}

const TitleTextComponent = ({ titleText, editTitleText }) => {
  return (
    <div className="form-group">
      <div className="name-input">
        <label>{MESSAGES.THIRD_PARTY_TITLE_TEXT}</label>
        <input
          id="titleText"
          className="form-control"
          type="text"
          value={titleText}
          maxLength="100"
          onChange={(e) => editTitleText(e.target.value)}
        />
      </div>
    </div>
  )
}

const ThirdPersonPhase2Component = ({
  title,
  titleOptions,
  selectTitle,
  titleText,
  editTitleText,
  thirdPersonFirstName,
  editFirstName,
  thirdPersonLastName,
  editLastName,
  relationship,
  options,
  selectRelationship,
  phoneNumber,
  editPhoneNumber,
}) => {
  return (
    <FormGroup className="third-person-split">
      <div className="form-group">
        <div className="name-input">
          <label>{MESSAGES.THIRD_PARTY_TITLE}</label>
          <Dropdown
            id="title"
            value={title}
            options={titleOptions}
            onChange={(e) => {
              const selectedText = titleOptions.filter((item) => item.value === e)[0].text
              selectTitle(e)
              editTitleText(e === 'other' ? '' : selectedText)
            }}
          />
        </div>
      </div>

      {title === 'other' ? <TitleTextComponent editTitleText={editTitleText} titleText={titleText} /> : null}

      <div className="form-group">
        <div className="name-input">
          <label>{MESSAGES.THIRD_PARTY_FIRST_NAME}</label>
          <input
            id="firstName"
            className="form-control"
            type="text"
            value={thirdPersonFirstName}
            maxLength="100"
            onChange={(e) => editFirstName(e.target.value)}
          />
        </div>
      </div>

      <div className="form-group">
        <div className="name-input">
          <label>{MESSAGES.THIRD_PARTY_LAST_NAME}</label>
          <input
            id="lastName"
            className="form-control"
            type="text"
            value={thirdPersonLastName}
            maxLength="100"
            onChange={(e) => editLastName(e.target.value)}
          />
        </div>
      </div>

      <div className="form-group">
        <div className="relationship">
          <label>{MESSAGES.THIRD_PARTY_RELATIONSHIP}</label>
          <Dropdown id="relationship" value={relationship} options={options} onChange={(e) => selectRelationship(e)} />
        </div>
      </div>

      <div className="form-group">
        <div className="phoneNumber">
          <label>{MESSAGES.THIRD_PARTY_TELEPHONE}</label>
          <NumberFormat
            id="phoneNumber"
            customInput={Input}
            value={phoneNumber}
            format={'### ### ####'}
            onChange={(e) => editPhoneNumber(e.target.value)}
          />
        </div>
      </div>
    </FormGroup>
  )
}

export const ThirdPerson = ({
  thirdPersonName,
  relationship,
  options,
  phoneNumber,
  editName,
  selectRelationship,
  editPhoneNumber,
  title,
  titleOptions,
  selectTitle,
  titleText,
  editTitleText,
  thirdPersonFirstName,
  editFirstName,
  thirdPersonLastName,
  editLastName,
}: Props) => {
  return getToggles().ENABLE_FNA_PHASE2_MARKET_CONDUCT ? (
    <ThirdPersonPhase2Component
      title={title}
      titleOptions={titleOptions}
      selectTitle={selectTitle}
      titleText={titleText}
      editTitleText={editTitleText}
      thirdPersonFirstName={thirdPersonFirstName}
      editFirstName={editFirstName}
      thirdPersonLastName={thirdPersonLastName}
      editLastName={editLastName}
      relationship={relationship}
      options={options}
      selectRelationship={selectRelationship}
      phoneNumber={phoneNumber}
      editPhoneNumber={editPhoneNumber}
    />
  ) : (
    <ThirdPersonPhase1Component
      thirdPersonName={thirdPersonName}
      editName={editName}
      relationship={relationship}
      options={options}
      selectRelationship={selectRelationship}
      phoneNumber={phoneNumber}
      editPhoneNumber={editPhoneNumber}
    />
  )
}

const ThirdPersonContainer = connect(
  (state: AppState) => ({
    thirdPersonName: getThirdPersonFullName(state),
    options: getRelationship(state),
    relationship: getThirdPersonRelationship(state),
    phoneNumber: getThirdPersonPhoneNumber(state),
    titleOptions: getTitle(state),
    title: getThirdPersonTitle(state),
    titleText: getThirdPersonTitleText(state),
    thirdPersonFirstName: getThirdPersonFirstName(state),
    thirdPersonLastName: getThirdPersonLastName(state),
  }),
  {
    editName: setFnaMarketConductThirdPersonFullName,
    selectRelationship: setFnaMarketConductThirdPersonRelationship,
    editPhoneNumber: setFnaMarketConductThirdPersonPhoneNumber,
    selectTitle: setFnaMarketConductThirdPersonTitle,
    editTitleText: setFnaMarketConductThirdPersonTitleText,
    editFirstName: setFnaMarketConductThirdPersonFirstName,
    editLastName: setFnaMarketConductThirdPersonLastName,
  }
)(ThirdPerson)

export default ThirdPersonContainer
