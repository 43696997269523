import { reduce, endsWith } from 'lodash/fp'

import { createSectionCreator, createField } from '../utils'
import { checker } from '../selectors'

const reasonInputField = (id) =>
  createField(`${id}.detail`, [
    {
      label: 'โปรดระบุรายละเอียด',
      id: `${id}.detail`,
      c: 'TextInput',
      p: {},
    },
  ])

const getQuestions = (app, id) => {
  const { isYes } = checker(app)

  const questions = [
    createField(`${id}.treatment.label`, [
      {
        id: `${id}.treatment.label`,
        c: 'PlainText',
        p: {
          children: 'ท่านเคยได้รับการวินิจฉัย หรือรับการรักษาโรคตามรายการดังต่อไปนี้ หรือไม่',
        },
      },
    ]),
    createField(`${id}.treatment.a.checked`, [
      {
        id: `${id}.treatment.a.checked`,
        c: 'RadioButton',
        required: true,
        label: 'โรคเส้นเลือดหัวใจตีบ, โรคหลอดเลือด, โรคอัมพฤกษ์ / โรคอัมพาต, โรคเบาหวาน, โรคหัวใจ, โรคเลือด, ม้ามโต',
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
    createField(`${id}.treatment.b.checked`, [
      {
        id: `${id}.treatment.b.checked`,
        c: 'RadioButton',
        required: true,
        label: 'โรคมะเร็ง, โรคต่อมน้ำเหลืองโต, เนื้องอก ก้อนหรือถุงน้ำ',
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
    createField(`${id}.treatment.c.checked`, [
      {
        id: `${id}.treatment.c.checked`,
        c: 'RadioButton',
        required: true,
        label:
          'โรคตับอ่อนอักเสบ, โรคไต, ภาวะหยุดหายใจขณะหลับ, โรคปอดหรือปอดอักเสบ, โรคหอบหืด, โรคสายตาพิการ จอประสาทตา,  โรคชัก,  พิการทางร่างกาย, โรคซึมเศร้า, โรคจิต, โรคประสาท, ดาวน์ซินโดรม, โรคตับหรือทางเดินน้ำดี, โรคดีซ่าน, โรคไทรอยด์, โรคแผลในทางเดินอาหาร, โรคเอสแอลอี',
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
    createField(`${id}.treatment.d.checked`, [
      {
        id: `${id}.treatment.d.checked`,
        c: 'RadioButton',
        required: true,
        label: 'โรคเอดส์ หรือภูมิคุ้มกันบอกพร่อง, โรควัณโรค',
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
    createField(`${id}.fiveYearsHealthCheck.checked`, [
      {
        label:
          'ในระหว่าง 5 ปีที่ผ่านมา ท่านเคยได้รับการตรวจสุขภาพ การตรวจชิ้นเนื้อหรือการตรวจเพื่อวินิจฉัยโรคเช่น การเอกซเรย์ การตรวจคลื่นหัวใจ การตรวจเลือด หรือการตรวจด้วยเครื่องมือพิเศษอื่นๆหรือไม่',
        id: `${id}.fiveYearsHealthCheck.checked`,
        c: 'RadioButton',
        required: true,
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
    createField(`${id}.relativeHealthCheck.checked`, [
      {
        label:
          'บิดา มารดา หรือพี่น้องร่วมบิดา และ/หรือ มารดาของท่านเคยได้รับการวินิจฉัยจากแพทย์ว่าเป็นหรือเคยเป็นโรคหัวใจ โรคหลอดเลือด โรคมะเร็ง โรคเบาหวาน โรคไต โรคความดันโลหิตสูง การพยายามฆ่าตัวตายหรือ อาการป่วยทางจิต โรคเลือด หรือไวรัสตับอักเสบ หรือไม่',
        id: `${id}.relativeHealthCheck.checked`,
        c: 'RadioButton',
        required: true,
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
    createField(`${id}.healthSymptom.label`, [
      {
        id: `${id}.healthSymptom.label`,
        c: 'PlainText',
        p: {
          children: 'ท่านเคยมีหรือกำลังมีอาการดังต่อไปนี้หรือไม่',
        },
      },
    ]),
    createField(`${id}.healthSymptom.a.checked`, [
      {
        id: `${id}.healthSymptom.a.checked`,
        c: 'RadioButton',
        required: true,
        label:
          'เจ็บหรือแน่นหน้าอก, ใจสั่น, กล้ามเนื้ออ่อนแรง, เหนื่อยง่ายผิดปกติ, การเคลื่อนไหวของร่างกายผิดปกติ, ร่างกายสูญเสียประสาทรับความรู้สึก, จ้ำเลือด',
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
    createField(`${id}.healthSymptom.b.checked`, [
      {
        id: `${id}.healthSymptom.b.checked`,
        c: 'RadioButton',
        required: true,
        label: 'คลำพบก้อนเนื้อ, ปวดศีรษะรุนแรงเรื้อรัง',
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
    createField(`${id}.healthSymptom.c.checked`, [
      {
        id: `${id}.healthSymptom.c.checked`,
        c: 'RadioButton',
        required: true,
        label:
          'การมองเห็นผิดปกติ, เคยพยายามทำร้ายตัวเอง, มีพัฒนาการช้า, ปวดท้องเรื้อรัง, อาเจียนหรือถ่ายเป็นเลือด, ท้องมาน, ท้องเสียเรื้อรัง, มีเลือดปนในปัสสาวะ',
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
    createField(`${id}.healthSymptom.d.checked`, [
      {
        id: `${id}.healthSymptom.d.checked`,
        c: 'RadioButton',
        required: true,
        label: 'ปวดข้อเรื้อรัง',
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
    createField(`${id}.healthSymptom.e.checked`, [
      {
        id: `${id}.healthSymptom.e.checked`,
        c: 'RadioButton',
        required: true,
        label: 'ไอเรื้อรัง, ไอเป็นเลือด',
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
    createField(`${id}.sixMonthSymptom.label`, [
      {
        id: `${id}.sixMonthSymptom.label`,
        c: 'PlainText',
        p: {
          children: 'ในระหว่าง 6 เดือนที่ผ่านมา ท่านมีอาการดังต่อไปนี้หรือไม่',
        },
      },
    ]),
    createField(`${id}.sixMonthSymptom.fatigue.checked`, [
      {
        id: `${id}.sixMonthSymptom.fatigue.checked`,
        c: 'RadioButton',
        required: true,
        label: 'อาการอ่อนเพลีย',
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
    createField(`${id}.sixMonthSymptom.weightLoss.checked`, [
      {
        id: `${id}.sixMonthSymptom.weightLoss.checked`,
        c: 'RadioButton',
        required: true,
        label: 'น้ำหนักลด',
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
    createField(`${id}.sixMonthSymptom.chronicDiarrhea.checked`, [
      {
        id: `${id}.sixMonthSymptom.chronicDiarrhea.checked`,
        c: 'RadioButton',
        required: true,
        label: 'ท้องเดินเรื้อรัง',
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
    createField(`${id}.sixMonthSymptom.chronicFever.checked`, [
      {
        id: `${id}.sixMonthSymptom.chronicFever.checked`,
        c: 'RadioButton',
        required: true,
        label: 'ไข้เรื้อรัง',
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
    createField(`${id}.sixMonthSymptom.chronicSkinDisease.checked`, [
      {
        id: `${id}.sixMonthSymptom.chronicSkinDisease.checked`,
        c: 'RadioButton',
        required: true,
        label: 'โรคผิวหนังเรื้อรัง',
        p: {
          options: [
            {
              text: 'ใช่',
              value: 'yes',
            },
            {
              text: 'ไม่ใช่',
              value: 'no',
            },
          ],
        },
      },
    ]),
  ]

  return reduce((questions, question) => {
    if (isYes(question.id) && !endsWith('label', question.id)) {
      return questions.concat(question).concat(reasonInputField(question.id))
    }
    return questions.concat(question)
  }, [])(questions)
}

export default createSectionCreator(getQuestions)
