import { createField, createSectionCreator } from './utils'
import { getQuickQuoteAgentAdvisorCode, getQuickQuoteAgentFirstName, getQuickQuoteAgentLastName } from './selectors'
import MESSAGES from 'core/data-model/constants/messages'

const bancInfoFields = (app, id) => {
  return [
    createField(`${id}.name`, [
      {
        label: 'เจ้าหน้าที่บริการทางการเงิน',
        id: `${id}.name`,
        c: 'PlainText',
        p: {
          mergeState: (state, app) => {
            const advisorCode = getQuickQuoteAgentAdvisorCode(app)
            const firstName = getQuickQuoteAgentFirstName(app)
            const lastName = getQuickQuoteAgentLastName(app)

            return {
              value: `${advisorCode || '-'} - ${firstName || '-'} ${lastName || '-'}`,
            }
          },
        },
      },
    ]),
  ]
}

export const bancInfoSection = createSectionCreator(bancInfoFields)

const shareCaseFields = (app, id) => {
  return [
    createField(`${id}.shareCase`, [
      {
        id: `${id}.shareCase`,
        c: 'Button',
        p: {
          icon: 'add',
          children: 'เพิ่มผู้แนะนำบริการ เพื่อแชร์เคส',
          block: true,
          mergeProps: ({ setValue, value }) => ({
            onClick: () => setValue(!!!value),
          }),
        },
      },
    ]),
  ]
}
export const bancShareCaseButton = createSectionCreator(shareCaseFields)

const bancRecommenderFields = (app, id) => {
  return [
    createField(`${id}.recommender.advisorCode`, [
      {
        label: 'รหัสพนักงาน',
        id: `${id}.recommender.advisorCode`,
        c: 'NumberInput',
        required: true,
        p: {
          placeholder: MESSAGES.RECOMMENDER_PLACEHOLDER,
        },
      },
    ]),
    createField(`${id}.recommender.firstName`, [
      {
        label: 'ชื่อ',
        id: `${id}.recommender.firstName`,
        c: 'TextInput',
        required: true,
      },
    ]),
    createField(`${id}.recommender.lastName`, [
      {
        label: 'นามสกุล',
        id: `${id}.recommender.lastName`,
        c: 'TextInput',
        required: true,
      },
    ]),
    createField(
      `${id}.shareCase`,
      [
        {
          id: `${id}.shareCase`,
          c: 'Button',
          p: {
            icon: 'remove',
            children: 'ลบ',
            mergeProps: ({ setValue, unsetValue }) => ({
              onClick: () => {
                setValue(false)
                unsetValue(`${id}.recommender`)
              },
            }),
            style: {
              fontSize: '0.8725rem',
              padding: '0.125rem 0.5rem',
            },
          },
        },
      ],
      {
        style: {
          position: 'absolute',
          top: '0',
          right: '0',
        },
      }
    ),
  ]
}
export const bancRecommenderSection = createSectionCreator(bancRecommenderFields)
