import { get, pick } from 'lodash/fp'
import { getToggles } from 'quick-quote/feature-toggles'
import { PAYMENT_TYPE } from 'e-submission/domain/data-model/constants'
import moment from 'moment'
import { fromBuddhistToChristian } from 'e-submission/sagas/policy-payload/primitive-selector.js'

export const isAtLeastOneEdaErrorToggleEnabled = (edaToggles) => {
  return Object.values(edaToggles).filter((toggle) => toggle).length <= 1
}

export const areAllEdaTogglesDisabled = (edaToggles) => {
  return Object.values(edaToggles).filter((toggle) => toggle).length === 0
}

export const mappingErrorEda = () => {
  if (getToggles().ENABLE_EDA_ERROR_TECHNICAL) {
    return 'technical'
  } else if (getToggles().ENABLE_EDA_ERROR_INVALID) {
    return 'invalid'
  } else if (getToggles().ENABLE_EDA_ERROR_TOKEN_EXPIRED) {
    return 'token_expired'
  } else if (getToggles().ENABLE_EDA_ERROR_REJECTED) {
    return 'rejected'
  } else if (getToggles().ENABLE_EDA_PREVIOUS_SUCCESS) {
    return 'previous_success'
  } else if (getToggles().ENABLE_EDA_DUPLICATED_DATA) {
    return 'duplicated_data'
  } else if (getToggles().ENABLE_EDA_SUCCESS) {
    return 'success'
  }

  return ''
}

export const getOnlyEdaToggles = (toggles) => {
  return pick(
    [
      'ENABLE_EDA_ERROR_TECHNICAL',
      'ENABLE_EDA_ERROR_INVALID',
      'ENABLE_EDA_ERROR_TOKEN_EXPIRED',
      'ENABLE_EDA_ERROR_REJECTED',
      'ENABLE_EDA_PREVIOUS_SUCCESS',
      'ENABLE_EDA_SUCCESS',
      'ENABLE_EDA_DUPLICATED_DATA',
    ],
    toggles
  )
}

export const createEdaErrorMockQueryString = () => {
  const edaToggles = getOnlyEdaToggles(getToggles())

  // no such eda error toggles are configured, eg. PROD
  if (Object.keys(edaToggles).length === 0) {
    return ''
  }

  return !areAllEdaTogglesDisabled(edaToggles) ? `?error_mock=${mappingErrorEda()}` : ''
}

export const checkUpdateUnsignedReceipt = (payment) => {
  let params = {}
  const isQrCrossBankEnable = getToggles().ENABLE_QR_CROSSBANK

  if (payment.type === PAYMENT_TYPE.NETBANK && isQrCrossBankEnable) {
    params = {
      ...params,
      type: 'QR Code',
      bankCode: isQrCrossBankEnable ? 'KTB7' : get('bank.value', payment),
    }
  } else if (payment.type === PAYMENT_TYPE.EDC) {
    params = {
      approvalCode: get('approvalCode', payment),
      transactionDate: moment(fromBuddhistToChristian(payment.transactionDate), 'DD/MM/YYYY').format('YYYY-MM-DD'),
    }
  } else {
    params = {
      bankCode: get('bank.value', payment),
      bankSlipDate: moment(fromBuddhistToChristian(payment.bankReceiptDate), 'DD/MM/YYYY').format('YYYY-MM-DD'),
    }
  }
  return params
}
