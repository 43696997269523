import _ from 'lodash'
import { createSectionCreator, createField } from './utils'

const heightAndtWeightDetails = (app, id) => {
  const isWeightChangedRecently = _.get(app, `${id}.isWeightChangedRecently`)

  return _.compact([
    createField(`${id}.height`, [
      {
        label: 'ส่วนสูง (เซนติเมตร)',
        id: `${id}.height`,
        c: 'NumberInput',
        required: true,
        p: {},
      },
    ]),
    createField(`${id}.weight`, [
      {
        label: 'น้ำหนัก (กิโลกรัม)',
        id: `${id}.weight`,
        c: 'NumberInput',
        required: true,
        p: {},
      },
    ]),
    createField(`${id}.isWeightChangedRecently`, [
      {
        id: `${id}.isWeightChangedRecently`,
        c: 'Checkbox',
        p: {
          children: 'น้ำหนักที่ระบุ ได้มีการเปลี่ยนแปลงภายใน 6 เดือนที่ผ่านมา',
        },
      },
    ]),
    ...(isWeightChangedRecently ? weightChangedRecentlyFields(id) : []),
  ])
}

const weightChangedRecentlyFields = (id) => [
  createField(`${id}.weightChangedRecently.how`, [
    {
      id: `${id}.weightChangedRecently.how`,
      c: 'RadioButton',
      required: true,
      p: {
        options: [
          {
            text: 'เพิ่มขึ้น',
            value: 'increase',
          },
          {
            text: 'ลดลง',
            value: 'decrease',
          },
        ],
      },
    },
  ]),
  createField(`${id}.weightChangedRecently.value`, [
    {
      label: 'น้ำหนัก (กิโลกรัม)',
      id: `${id}.weightChangedRecently.value`,
      c: 'NumberInput',
      required: true,
      p: {
        format: null,
      },
    },
  ]),
  createField(`${id}.weightChangedRecently.reason`, [
    {
      label: 'สาเหตุ',
      id: `${id}.weightChangedRecently.reason`,
      c: 'TextInput',
      required: true,
      p: {},
    },
  ]),
]

export default createSectionCreator(heightAndtWeightDetails)
