// @flow
import VALUES from 'core/data-model/constants/values'
import MESSAGES from 'core/data-model/constants/bi-messages'
import ProductBenefitsSummary from './product-benefits-summary'

import type { ProductBenefitProps } from 'quick-quote/product-common/benefit-illustration/components/product-benefits/types'

const ProductBenefits = ({
  displayProduct,
  sumAssured,
  basicPremium,
  calculatedPaymentPeriod,
  calculatedCoveragePeriod,
  selectedModelFactorID,
  selectedModelFactorLabel,
  benefitSummaryHeader,
  sumAssuredContract,
}: ProductBenefitProps) => {
  return (
    <div id="product-benefits" className="benefit group">
      <ProductBenefitsSummary
        displayProduct={displayProduct}
        sumAssured={sumAssured}
        basicPremium={basicPremium}
        calculatedPaymentPeriod={calculatedPaymentPeriod}
        calculatedCoveragePeriod={calculatedCoveragePeriod}
        benefitSummaryHeader={benefitSummaryHeader}
        selectedModelFactorID={selectedModelFactorID}
        selectedModelFactorLabel={selectedModelFactorLabel}
        sumAssuredContract={sumAssuredContract}
      />
      <br />
      {displayProduct.basicPlanCode !== VALUES.GLTSP_CODE.GLTSP ? (
        <div className="remark-message">
          <p>
            <b>MRTA : </b>
            {MESSAGES.GRAPH_REMARK_MRTA}
          </p>
          <p>
            <b>ความคุ้มครอง : </b>
            {MESSAGES.GRAPH_REMARK_MRTA_COVERAGE}
          </p>
          <p>
            <b>{MESSAGES.NOTE} : </b>
            {MESSAGES.GRAPH_REMARK_ALL}
          </p>
        </div>
      ) : (
        <div className="remark-message">
          <p>
            <b>GLTSP : </b>
            {MESSAGES.GRAPH_REMARK_GLTSP}
          </p>
          <p>
            <b>{MESSAGES.NOTE} : </b>
            {MESSAGES.GRAPH_REMARK_ALL}
          </p>
        </div>
      )}
    </div>
  )
}

export default ProductBenefits
