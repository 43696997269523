//@flow
import VALUES from 'core/data-model/constants/values'
import BIMESSAGES from 'core/data-model/constants/bi-messages'
import type { DisplayProduct } from 'core/service/display-product'
import { formattedHeaderWithStyles, productInfoHeaderStyles } from '../../common/components/product-information-header'

export const render = (displayProduct: DisplayProduct, redline: string) => {
  let header1
  const header2 = `${displayProduct.nameThai} (${displayProduct.code})`

  switch (displayProduct.basicPlanCode) {
    case VALUES.MRTA_CODE.MRTA_FULL:
      header1 = BIMESSAGES.MRTA_FULL_EN_NAME
      break
    case VALUES.MRTA_CODE.MRTA_PLUS:
      header1 = BIMESSAGES.MRTA_PLUS_EN_NAME
      break
    case VALUES.MRTA_CODE.MRTA_TRUNCATE:
      header1 = BIMESSAGES.MRTA_TRUNCATE_EN_NAME
      break
    default:
      header1 = displayProduct.name
      break
  }

  return formattedHeaderWithStyles(header1, header2, redline, productInfoHeaderStyles)
}
