import React from 'react'
import PropTypes from 'prop-types'

import { WordWraps } from 'common-components'

function NeedButton(props) {
  const { id, title, active, onClick } = props
  const cssClass = ['az-need-and-gap__need-button']

  if (active) cssClass.push('az-need-and-gap__need-button--active')

  return (
    <div
      data-id={id}
      className={cssClass.join(' ')}
      onClick={() => {
        onClick(id)
        setTimeout(() => document.getElementById('nav-bottom').scrollIntoView({ behavior: 'smooth' }), 150)
      }}
    >
      <span className="title-icon" />
      <span className="title-text">
        <WordWraps>{title}</WordWraps>
      </span>
    </div>
  )
}

const propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
}
const defaultProps = {
  id: '',
  title: '',
  active: false,
  onClick() {},
}

NeedButton.propTypes = propTypes
NeedButton.defaultProps = defaultProps

export default NeedButton
