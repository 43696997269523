import { flatten, overSome, overEvery, negate, noop, compact, get, getOr } from 'lodash/fp'
import {
  unsetAppValue,
  submitRecurringPayment,
  updateRecurringPayment,
  setEDAViewFormOnly,
  resetEDAViewFormOnly,
  setAppValue,
} from 'e-submission/apps/actions'
import { createField, createSection, createSectionCreator } from 'e-submission/domain/data-model/form/utils'
import { identityField } from 'e-submission/domain/data-model/form/personalSection'

import {
  getATPBankList,
  getMonthsCollectBankList,
  isSectionValid,
  getDefaultPersonalTitleList,
  getRelationshipListForATP,
  getRelationshipListForNewATP,
  isEDAViewFormOnly,
} from 'e-submission/apps/selectors'
import { getPolicyId, isATPSubmitted, isATPPaid, isFormTypeEDA } from 'e-submission/domain/data-model/form/selectors'
import { signatureField, documentField } from 'e-submission/domain/data-model/submit'
import { getToggles } from 'quick-quote/feature-toggles'
import { DOCUMENT_TYPE } from 'e-submission/domain/data-model/constants'

const isSelectOtherRelationship = (selectedValue = '') => {
  return ['OTHERS'].includes(selectedValue)
}

const getFullNameListOfBeneficiary = (app = {}) => {
  const beneficiaryList = getOr([], 'beneficiary', app)
  const fullNameList = beneficiaryList.map((beneficiary) =>
    [get('firstName', beneficiary), get('lastName', beneficiary)].join(' ')
  )
  return fullNameList
}

export const ATPFormSection = (app, id, psection) => {
  const section = { id: 'ATP' }
  const isEnableEDAFeature = getToggles().ENABLE_EDA
  const isOtherTitle = get(`${section.id}.title.value`, app) === 'other'
  const insuredFirstName = get('insured.firstName', app)
  const isMonthly = get('quickQuote.coveragePlan.selectedModelFactorID', app) === 'model-factor_3'

  const isOtherRelationship = isSelectOtherRelationship(get(`${section.id}.relations.value`, app))
  const hasDifferentLastNameToInsured = get('insured.lastName', app) !== get(`${section.id}.lastName`, app)
  const isNotOneOfBeneficiary = !getFullNameListOfBeneficiary(app).includes(
    [get(`${section.id}.firstName`, app), get(`${section.id}.lastName`, app)].join(' ')
  )
  const hasNameCondition = hasDifferentLastNameToInsured ? isNotOneOfBeneficiary : hasDifferentLastNameToInsured
  const shouldShowRelationshipMsg = isOtherRelationship || (hasNameCondition && !isEnableEDAFeature)

  const relationsInfoMessage = shouldShowRelationshipMsg
    ? `กรุณาแนบเอกสารพิสูจน์ความสัมพันธ์ เพื่อใช้ในการประกอบการพิจารณา${
        hasNameCondition && !isOtherRelationship
          ? ` เนื่องจากนามสกุลของผู้ชำระเงินไม่ตรงกับผู้เอาประกัน และไม่ใช่ผู้รับประโยชน์`
          : ''
      }`
    : ''

  const ENABLE_PREFILL_IDTYPE_DROPDOWN_DA_FORM = getToggles().ENABLE_PREFILL_IDTYPE_DROPDOWN_DA_FORM
  const identityFields = identityField(app, section.id, ENABLE_PREFILL_IDTYPE_DROPDOWN_DA_FORM)
  const idTypeCode = get(`${section.id}.idType.value`, app)
  const isCiticenIdTypeCode = idTypeCode === 'CITIZEN_ID'
  const isPassportIdTypeCode = idTypeCode === 'PASSPORT'
  const isEDA = isEnableEDAFeature && id === 'EDA'

  const isAppliedATP = isATPPaid(app) || isATPSubmitted(app)
  const isAppliedATPonline = isEnableEDAFeature && isAppliedATP && isFormTypeEDA(app)

  return compact([
    createField(`${section.id}.title`, [
      {
        label: 'คำนำหน้า',
        id: `${section.id}.title`,
        c: 'Dropdown',
        required: true,
        p: {
          placeHolder: 'กรุณาเลือกคำนำหน้า',
          mergeState: (state) => {
            const onlyViewEDASummaryInForm = isAppliedATPonline && isEDAViewFormOnly(state)

            return {
              options: getDefaultPersonalTitleList(state),
              disabled: onlyViewEDASummaryInForm,
            }
          },
        },
      },
    ]),
    isOtherTitle &&
      createField(`${section.id}.title.text`, [
        {
          label: 'ระบุคำนำหน้า',
          id: `${section.id}.title.text`,
          c: 'TextInput',
          required: true,
          p: {
            mergeState: (state) => {
              const onlyViewEDASummaryInForm = isAppliedATPonline && isEDAViewFormOnly(state)

              return {
                disabled: onlyViewEDASummaryInForm,
              }
            },
          },
        },
      ]),

    createField(`${section.id}.firstName`, [
      {
        label: 'ชื่อเจ้าของบัญชีเงินฝาก',
        id: `${section.id}.firstName`,
        c: 'TextInput',
        required: true,
        p: {
          mergeState: (state) => {
            const onlyViewEDASummaryInForm = isAppliedATPonline && isEDAViewFormOnly(state)

            return {
              disabled: onlyViewEDASummaryInForm,
            }
          },
        },
      },
    ]),
    createField(`${section.id}.lastName`, [
      {
        label: 'นามสกุลเจ้าของบัญชีเงินฝาก',
        id: `${section.id}.lastName`,
        c: 'TextInput',
        required: true,
        p: {
          mergeState: (state) => {
            const onlyViewEDASummaryInForm = isAppliedATPonline && isEDAViewFormOnly(state)

            return {
              disabled: onlyViewEDASummaryInForm,
            }
          },
        },
      },
    ]),
    createField(`${section.id}.bank`, [
      {
        label: 'ตัดเบี้ยอัตโนมัติจากบัญชีธนาคาร',
        id: `${section.id}.bank`,
        c: 'Dropdown',
        required: true,
        p: {
          placeHolder: 'กรุณาเลือกธนาคาร',
          mergeState: (state, app) => {
            const monthsCollectBankList = getMonthsCollectBankList(state)

            let bankOptions = getATPBankList(state)
            if (isEDA) {
              bankOptions = bankOptions.filter((item) => {
                const valueBankListForEDA = ['006'] // '006' = 'ธนาคารกรุงไทย'

                return valueBankListForEDA.includes(item.value)
              })
            }

            const onlyViewEDASummaryInForm = isAppliedATPonline && isEDAViewFormOnly(state)

            return {
              disabled: isEDA || onlyViewEDASummaryInForm,
              options: bankOptions,
              infoMessage: isEDA
                ? '' // for EDA don't show info message box
                : `สำหรับ${monthsCollectBankList
                    .reduce((result, { text }) => [...result, text], [])
                    .join(' ')} กรุณาเก็บงวดแรกสองงวด`,
            }
          },
        },
      },
    ]),
    getToggles().ENABLE_ACCOUNT_TYPE_ATP &&
      createField(`${section.id}.accountType`, [
        {
          label: 'ประเภทบัญชีเงินฝาก',
          id: `${section.id}.accountType`,
          c: 'Dropdown',
          required: true,
          p: {
            placeHolder: 'กรุณาเลือกประเภทบัญชี',
            mergeState: (state, app) => {
              const onlyViewEDASummaryInForm = isAppliedATPonline && isEDAViewFormOnly(state)

              return {
                disabled: onlyViewEDASummaryInForm,
                options: [
                  {
                    text: 'ออมทรัพย์',
                    value: 'ออมทรัพย์',
                  },
                  {
                    text: 'กระแสรายวัน',
                    value: 'กระแสรายวัน',
                  },
                ],
              }
            },
          },
        },
      ]),
    createField(`${section.id}.bankBookNumber`, [
      {
        label: 'เลขที่บัญชี',
        id: `${section.id}.bankBookNumber`,
        c: 'NumberInput',
        required: true,
        p: {
          format: '###-#-#####-#',
          mergeState: (state) => {
            const onlyViewEDASummaryInForm = isAppliedATPonline && isEDAViewFormOnly(state)

            return {
              disabled: onlyViewEDASummaryInForm,
            }
          },
        },
      },
    ]),

    ENABLE_PREFILL_IDTYPE_DROPDOWN_DA_FORM ? identityFields[0] : null,
    ENABLE_PREFILL_IDTYPE_DROPDOWN_DA_FORM ? null : identityFields[1],

    ENABLE_PREFILL_IDTYPE_DROPDOWN_DA_FORM &&
      isCiticenIdTypeCode &&
      createField(`${section.id}.idNo.citizenId`, [
        {
          label: 'เลขประจำตัวประชาชน',
          id: `${section.id}.idNo.citizenId`,
          c: 'NumberInput',
          required: isCiticenIdTypeCode,
          p: {
            mergeState: (state) => {
              const onlyViewEDASummaryInForm = isAppliedATPonline && isEDAViewFormOnly(state)

              return {
                disabled: onlyViewEDASummaryInForm,
              }
            },
          },
        },
      ]),

    ENABLE_PREFILL_IDTYPE_DROPDOWN_DA_FORM &&
      isPassportIdTypeCode &&
      createField(`${section.id}.idNo.passport`, [
        {
          label: 'หมายเลขหนังสือเดินทาง',
          id: `${section.id}.idNo.passport`,
          c: 'TextInput',
          required: isPassportIdTypeCode,
          p: {
            mergeState: (state) => {
              const onlyViewEDASummaryInForm = isAppliedATPonline && isEDAViewFormOnly(state)

              return {
                disabled: onlyViewEDASummaryInForm,
              }
            },
          },
        },
      ]),

    isEnableEDAFeature
      ? createField(`${section.id}.relations-${isOtherRelationship.toString()}-${hasNameCondition.toString()}`, [
          {
            label: `ความสัมพันธ์กับคุณ ${insuredFirstName}`,
            id: `${section.id}.relations`,
            c: 'RadioGroup',
            required: true,
            p: {
              mergeState: (state, app, { dispatch = noop } = {}) => {
                const onlyViewEDASummaryInForm = isAppliedATPonline && isEDAViewFormOnly(state)

                return {
                  disabled: onlyViewEDASummaryInForm,
                  options: getRelationshipListForNewATP(state),
                  customOnClick: (option) => {
                    const isOtherSelected = option?.value === 'OTHERS'
                    if (!isOtherSelected) {
                      dispatch(unsetAppValue('ATP.otherRelationRemark'))
                    }

                    dispatch(setAppValue('ATP.isManualRelationship', true))
                  },
                }
              },
            },
          },
        ])
      : createField(`${section.id}.relations-${isOtherRelationship.toString()}-${hasNameCondition.toString()}`, [
          {
            label: `ความสัมพันธ์กับคุณ ${insuredFirstName}`,
            id: `${section.id}.relations`,
            c: 'Dropdown',
            required: true,
            p: {
              mergeState: (state) => {
                const onlyViewEDASummaryInForm = isAppliedATPonline && isEDAViewFormOnly(state)

                return {
                  disabled: onlyViewEDASummaryInForm,
                  options: getRelationshipListForATP(state),
                  infoMessage: relationsInfoMessage,
                }
              },
              placeHolder: 'กรุณาเลือกความสัมพันธ์',
            },
          },
        ]),
    ,
    isEnableEDAFeature &&
      isOtherRelationship &&
      createField(`${section.id}.relation-remark.text`, [
        {
          label: '',
          id: `${section.id}.otherRelationRemark`,
          c: 'TextInput',
          required: true,
          p: {
            mergeState: (state) => {
              const onlyViewEDASummaryInForm = isAppliedATPonline && isEDAViewFormOnly(state)

              return {
                disabled: onlyViewEDASummaryInForm,
                infoMessage: relationsInfoMessage,
              }
            },
            placeHolder: 'กรุณาระบุความสัมพันธ์',
          },
        },
      ]),
    isEnableEDAFeature &&
      !!relationsInfoMessage &&
      documentField(`${id}_form`)(app, 'insured.DA_RELATIONSHIP', section),

    createField(`${section.id}.mobileNumber`, [
      {
        label: 'เบอร์โทรศัพท์',
        id: `${section.id}.mobileNumber`,
        c: 'NumberInput',
        required: true,
        p: {
          mergeState: (state) => {
            const onlyViewEDASummaryInForm = isAppliedATPonline && isEDAViewFormOnly(state)

            return {
              disabled: onlyViewEDASummaryInForm,
            }
          },
        },
      },
    ]),
    createField(`${section.id}.email`, [
      {
        label: `Email${isMonthly ? ' (ถ้ามี)' : ''}`,
        id: `${section.id}.email`,
        c: 'TextInput',
        required: !isMonthly,
        p: {
          mergeState: (state) => {
            const onlyViewEDASummaryInForm = isAppliedATPonline && isEDAViewFormOnly(state)

            return {
              disabled: onlyViewEDASummaryInForm,
            }
          },
        },
      },
    ]),
  ])
}

export const ATPSignatureSection = (app, id, section) => {
  const policyId = getPolicyId(app)
  const isATPRequireSignature = getToggles().ENABLE_DA_FORM_SIGNING
  const isEnableEDA = getToggles().ENABLE_EDA
  const isOtherRelationship = isSelectOtherRelationship(get('ATP.relations.value', app))
  const isEDA = isEnableEDA && id === 'EDA'

  const atpFields = [
    createField(
      'ATP.edit',
      [
        {
          id: 'ATP.edit',
          c: 'EditCard',
          p: {
            title: 'ข้อมูลที่กรอกไปถูกนำมาสร้างหนังสือยินยอม',
            children: isATPRequireSignature ? 'การแก้ไขข้อมูลจะต้องลงนามใหม่' : '',
            viewOnly: isEDA,
            mergeState: (state, app) => {
              const isSubmitAtp = get('ATP.submitted', app) === true
              // [2781] either toggle is enabled or ATP is submitted, then we disable the edit button
              return {
                disabled: isATPRequireSignature || isSubmitAtp ? isSubmitAtp : false,
              }
            },
            mergeProps: ({ dispatch }) => ({
              onClick: () => {
                if (isEDA) {
                  dispatch(setEDAViewFormOnly())
                } else {
                  dispatch(unsetAppValue('ATP.paid'))
                  dispatch(unsetAppValue('ATP.submitted'))
                  dispatch(updateRecurringPayment(true)) // isEdit = true
                }
              },
            }),
          },
        },
      ],
      {
        pl: 0,
        pr: 0,
      }
    ),
  ]

  if (!isATPRequireSignature) {
    atpFields.unshift(
      createField('ATP.preview', [
        {
          id: 'ATP.preview',
          c: 'UnsignedDocument',
          p: {
            description: DOCUMENT_TYPE.insured.ATP.text,
            name: isEDA ? 'eda' : DOCUMENT_TYPE.insured.ATP.name,
            policyId,
          },
        },
      ])
    )
  } else {
    atpFields.push(
      createField('insured.ATPLabel', [
        {
          id: 'insured.ATPLabel',
          c: 'SubHeading',
          p: {
            children: 'ลงนาม ผู้เอาประกันภัย',
          },
        },
      ]),
      signatureField('insured')(app, 'insured.ATP', {
        label: 'ลงนาม ผู้เอาประกันภัย',
      }),
      createField('payer.ATPLabel', [
        {
          id: 'insured.ATPLabel',
          c: 'SubHeading',
          p: {
            children: 'ลงนาม เจ้าของบัญชี',
          },
        },
      ]),
      signatureField('payer', 'ATP')(app, 'payer.ATP', {
        label: 'ลงนาม เจ้าของบัญชี',
      }),
      createField('agent.ATPLabel', [
        {
          id: 'ATP.insuredLabel',
          c: 'SubHeading',
          p: {
            children: 'ลงนาม ตัวแทน/นายหน้าประกันชีวิต',
          },
        },
      ]),
      signatureField('agent')(app, 'agent.ATP', {
        label: 'ลงนาม ตัวแทน/นายหน้าประกันชีวิต',
      })
    )
  }
  if (isEnableEDA && isOtherRelationship) {
    atpFields.push(documentField(id)(app, 'insured.DA_RELATIONSHIP', section))
  }

  return compact(atpFields)
}

export const ATPSection = createSectionCreator((app, id, section) => {
  const ATPFormFields = ATPFormSection(app, id, section)
  const ATPSignatureFields = ATPSignatureSection(app, id, section)
  const isATPRequireSignature = getToggles().ENABLE_DA_FORM_SIGNING
  // const isAllSigned = overEvery([
  //   isSectionValid(state, [createSection(ATPSignatureFields)]),
  //   isPaid,
  //   isSubmitted
  // ])
  const isEnableEDAFeature = getToggles().ENABLE_EDA
  const isAppliedATP = isATPPaid(app) || isATPSubmitted(app)
  const isAppliedATPonline = isEnableEDAFeature && isAppliedATP && isFormTypeEDA(app)
  const isViewFormOnly = get('additionalProps.isViewOnly', section)
  const isOtherRelationship = isSelectOtherRelationship(get(`ATP.relations.value`, app))
  const isSubmitAtp = get('ATP.submitted', app) === true

  return flatten(
    compact([
      documentField(id)(app, 'insured.BANK_BOOK', section),
      isATPPaid(app) && !(isAppliedATPonline && isViewFormOnly)
        ? [
            ...ATPSignatureFields,
            createField('ATP.submit', [
              {
                id: 'ATP.submit',
                c: 'Button',
                p: {
                  block: true,
                  children: 'ตกลง',
                  infoMessage: 'หากดำเนินการกดปุ่มตกลงจะไม่สามารถกลับมาแก้ไขข้อมูลได้',
                  mergeState: (state, app, { dispatch = noop, history, ...props } = {}) => {
                    const defaultDisableLogic = overSome([
                      negate(
                        isSectionValid(state, [createSection(documentField(id)(app, 'insured.BANK_BOOK', section))])
                      ),
                      isEnableEDAFeature && isOtherRelationship
                        ? negate(
                            isSectionValid(state, [
                              createSection(documentField(`${id}_form`)(app, 'insured.DA_RELATIONSHIP', section)),
                            ])
                          )
                        : () => false,
                      isATPRequireSignature
                        ? negate(isSectionValid(state, [createSection(ATPSignatureFields)]))
                        : () => false,
                      isATPRequireSignature ? isATPSubmitted : () => false,
                    ])(app)

                    return {
                      disabled: isSubmitAtp ? true : defaultDisableLogic,
                      onClick: () => {
                        dispatch(submitRecurringPayment())
                      },
                    }
                  },
                },
              },
            ]),
          ]
        : [
            ...ATPFormFields,
            createField('ATP.sign', [
              {
                id: 'ATP.sign',
                c: 'Button',
                p: {
                  block: true,
                  children: isATPRequireSignature ? 'ลงนาม' : 'เสร็จสิ้น',
                  mergeState: (state, app, { dispatch = noop } = {}) => {
                    const onlyViewEDASummaryInForm = isAppliedATPonline && isEDAViewFormOnly(state)
                    const canSign = onlyViewEDASummaryInForm
                      ? overEvery([
                          isSectionValid(state, [createSection(ATPFormSection(app, id, section))]),
                          isSectionValid(state, [createSection(documentField(id)(app, 'insured.BANK_BOOK', section))]),
                        ])
                      : overEvery([
                          isSectionValid(state, [createSection(ATPFormSection(app, id, section))]),
                          isSectionValid(state, [createSection(documentField(id)(app, 'insured.BANK_BOOK', section))]),
                          negate(isATPPaid),
                        ])

                    return {
                      disabled: negate(canSign)(app),
                      onClick: () => {
                        if (onlyViewEDASummaryInForm) {
                          dispatch(resetEDAViewFormOnly())
                        } else {
                          dispatch(updateRecurringPayment())
                        }
                      },
                    }
                  },
                },
              },
            ]),
          ],
    ])
  )
})
