//@flow
import type { CordovaUpdateFailedNotification } from '../notification/actions'

import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import { applicationFetchUpdate, resetApplicationFetchUpdate } from 'core/actions'
import { Modal } from '../../common-components'
import { setProcessingSuccess } from '../actions'
import { setNotificationMessage } from 'quick-quote/notification/actions'
import { getToggles } from 'quick-quote/feature-toggles'

type CordovaUpdateFailedModalProps = {
  applicationFetchUpdate: Function,
  resetApplicationFetchUpdate: Function,
  setProcessingSuccess: Function,
  notificationMessage: CordovaUpdateFailedNotification,
  onExit: Function,
}

const WarningMsg = styled.p`
  color: red;
`

export const _CordovaUpdateFailedModal = ({
  notificationMessage,
  onExit,
  setNotificationMessage,
  history,
  ...props
}: CordovaUpdateFailedModalProps) => (
  <Modal
    isOpen={notificationMessage !== 'None'}
    backdrop={true}
    size="lg"
    modalClassName="default-bootstrap remote-selling-modal"
    toggle={() => {
      if (getToggles().ENABLE_CHECK_NATIVE_APPVERSION && history.location.pathname !== '/e-submission/list') {
        props.setProcessingSuccess()
        props.resetApplicationFetchUpdate()
        onExit()
      }
    }}
  >
    {getToggles().ENABLE_CHECK_NATIVE_APPVERSION && history.location.pathname === '/e-submission/list' ? (
      <ModalHeader>อัพเดทเวอชั่นล่าสุด</ModalHeader>
    ) : (
      <ModalHeader
        toggle={() => {
          props.setProcessingSuccess()
          props.resetApplicationFetchUpdate()
          onExit()
        }}
      >
        อัพเดทเวอชั่นล่าสุด
      </ModalHeader>
    )}
    <ModalBody>
      <p>
        เพื่อประโยชน์และประสิทธิภาพสูงสุดของการใช้งาน กรุณาดำเนินการอัปเดตเวอร์ชั่นล่าสุดของแอปพลิเคชัน
        <ul>
          <li>เพื่อสามารถไปสู่ขั้นตอนดำเนินการใบคำขอ</li>
          <li>เพื่อสามารถดำเนินการแชร์ข้อเสนอ</li>
        </ul>
      </p>
      <WarningMsg>* หมายเหตุ ท่านจำเป็นต้องใช้อินเตอร์เน็ตในการติดตั้งเวอชั่นล่าสุดของแอปพลิเคชัน</WarningMsg>
    </ModalBody>
    <ModalFooter>
      <div className="bottom">
        <Button
          color="outline-primary"
          onClick={(e) => {
            props.setProcessingSuccess()
            props.resetApplicationFetchUpdate()
            onExit()
            getToggles().ENABLE_CHECK_NATIVE_APPVERSION &&
              history.location.pathname === '/e-submission/list' &&
              history.push('/')
            e.stopPropagation()
          }}
        >
          กลับสู่หน้าก่อนหน้า
        </Button>
        <Button
          color="primary"
          onClick={(e) => {
            props.setProcessingSuccess()
            props.applicationFetchUpdate()
            onExit()
            e.stopPropagation()
          }}
        >
          ติดตั้งทันที
        </Button>
      </div>
    </ModalFooter>
  </Modal>
)

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  applicationFetchUpdate: bindActionCreators(applicationFetchUpdate, dispatch),
  resetApplicationFetchUpdate: bindActionCreators(resetApplicationFetchUpdate, dispatch),
  setProcessingSuccess: bindActionCreators(setProcessingSuccess, dispatch),
  setNotificationMessage: bindActionCreators(setNotificationMessage, dispatch),
})

export default withRouter(connect(null, mapDispatchToProps)(_CordovaUpdateFailedModal))
