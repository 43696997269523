// @flow
import { connect } from 'react-redux'
import { FormGroup } from 'reactstrap'
import type { AppState } from 'quick-quote/reducers'
import type { Dispatch } from 'redux'
import {
  isFundPageValid,
  isPremiumPageValid,
  isLumpSumValid,
} from 'quick-quote/product-investment/coverage-plan/selectors'
import { renderErrorMessages } from 'common-components/view-logic'
import TAB_MESSAGE from 'core/data-model/constants/tab-messages'
import type { RuleType } from 'core/data-model/validation/types'
import VALUES from 'core/data-model/constants/values'

type Props = {
  productCode?: string,
  _isFundPageValid: boolean,
  _isPremiumPageValid: boolean,
  _isLumpSumValid: boolean,
}

type InvalidSectionProps = {
  errors: Array<{ type: RuleType, message: string }>,
  sectionName: string,
  sectionTitle: string,
}

const InvalidSection = ({ errors, sectionName, sectionTitle }: InvalidSectionProps) => (
  <FormGroup id={`${sectionName}-verification`} className="has-danger">
    <div className="title-error-verification">
      <span className={`icon ${sectionName}-icon`}></span>
      <label className="label-error">{sectionTitle}</label>
    </div>
    {renderErrorMessages(`${sectionName}-invalid-page-error-message`)(errors, 'VALIDATION')}
  </FormGroup>
)

export const Verification = ({ productCode, _isFundPageValid, _isPremiumPageValid, _isLumpSumValid }: Props) => {
  const FIX_FUND_ALLOCATION = 'กรุณาเลือกกองทุน หรือจัดสัดส่วนการลงทุนให้ครบ 100%'
  const FIX_PREMIUM = 'กรุณาจัดสัดส่วนเบี้ยประกันภัยฯ และ/หรือ เลือกความคุ้มครองชีวิตให้ถูกต้อง'
  const FIX_LUMP_SUM_ERROR =
    productCode === VALUES.SPUL
      ? 'กรุณาแก้ไขเบี้ยประกันภัยเพิ่มเติมพิเศษให้เป็นไปตามเงื่อนไข'
      : 'กรุณาแก้ไขเบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษให้เป็นไปตามเงื่อนไข'
  const SUMMARY_ERROR_1 = 'ไม่สามารถพิมพ์ใบเสนอขาย'
  const SUMMARY_ERROR_2 = 'กรุณาแก้ไขข้อมูลด้านล่างให้ถูกต้อง'
  const fundPageError = [{ type: 'VALIDATION', message: FIX_FUND_ALLOCATION }]
  const premiumPageError = { type: 'VALIDATION', message: FIX_PREMIUM }
  const lumpSumError = { type: 'VALIDATION', message: FIX_LUMP_SUM_ERROR }
  let coveragePageError = []
  if (!_isPremiumPageValid) {
    coveragePageError.push(premiumPageError)
  }
  if (!_isLumpSumValid) {
    coveragePageError.push(lumpSumError)
  }
  return (
    <div className="snapshot group">
      <FormGroup>
        <span className="form-invalid-icon"></span>
        <label className="summary-error-msg">
          <strong>{SUMMARY_ERROR_1}</strong>
        </label>
        <label className="summary-error-msg">
          <strong>{SUMMARY_ERROR_2}</strong>
        </label>
      </FormGroup>
      <FormGroup>
        {!_isFundPageValid && (
          <InvalidSection sectionName="fund" sectionTitle={TAB_MESSAGE.FUND} errors={fundPageError} />
        )}
        {(!_isLumpSumValid || !_isPremiumPageValid) && (
          <InvalidSection
            sectionName="premium"
            sectionTitle={TAB_MESSAGE.FULL_NAME_PREMIUM}
            errors={coveragePageError}
          />
        )}
      </FormGroup>
    </div>
  )
}

const mapStateToProps = (state: AppState, { productCode }: { productCode: string }) => ({
  _isFundPageValid: isFundPageValid(state),
  _isPremiumPageValid: isPremiumPageValid(state),
  _isLumpSumValid: isLumpSumValid(state),
  productCode,
})

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Verification)
