// @flow
import type { BasicPremium$, SumAssured$ } from 'core/data-model/basic-plan'
import {
  isZero,
  enableBasicPremium,
  enableBasicSumAssured,
  renderErrorMessage,
  renderInforMessage,
} from 'common-components/view-logic'

import values from 'core/data-model/constants/values'

type BasicPlanErrorMessagesProps = {
  haveBasicPremiumState: boolean,
  sumAssured$: SumAssured$,
  basicPremium$: BasicPremium$,
  basicPremiumInfo: string[],
}

const BasicPlanErrorMessages = ({
  haveBasicPremiumState,
  sumAssured$,
  basicPremium$,
  basicPremiumInfo,
}: BasicPlanErrorMessagesProps) => {
  return (
    <div>
      {haveBasicPremiumState
        ? renderErrorMessage('basic-premium-info-message')(basicPremium$, values.ADDITIONAL_INFORMATION)
        : null}

      {!enableBasicSumAssured(haveBasicPremiumState, basicPremium$) || isZero(sumAssured$)
        ? null
        : renderErrorMessage('error-sum-assured-message')(sumAssured$)}

      {!enableBasicPremium(haveBasicPremiumState, sumAssured$) || isZero(basicPremium$)
        ? null
        : renderErrorMessage('basic-premium-error-message')(basicPremium$)}

      {basicPremiumInfo && basicPremiumInfo.length > 0
        ? renderInforMessage('basic-premium-discount-message')(basicPremiumInfo)
        : null}
    </div>
  )
}

export default BasicPlanErrorMessages
