//@flow
import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import type { FundItemProps, RiskLevelProps } from 'common-components/types/fund-item'

import { withDropdown } from 'lib/with-hoc'
import { getRiskColor } from 'core/service/investment/risklevel'

const SubRiskLevel = ({ value }): RiskLevelProps => {
  return (
    <div className="az-fund-item-summary__risk-lv-summary-sub" style={{ backgroundColor: getRiskColor(value) }}>
      <span className="az-fund-item-summary__risk-lv-summary-sub__value">{value}</span>
    </div>
  )
}

export const SubFundItem = (props): FundItemProps => {
  const { fundHouse, code, thaiName, riskLevel } = props

  return (
    <div className="az-fund-item-summary">
      <SubRiskLevel value={riskLevel} />
      <div className="az-fund-item-summary__fund-content-summary-sub">
        <div className="az-fund-item-summary__fund-content-summary-sub__name">
          <span className={`firm ${_.lowerCase(fundHouse)}`} />
          <span className="code-and-name">
            <FundCodeAndNameWithInfo code={code} name={thaiName} />
          </span>
        </div>
      </div>
    </div>
  )
}

const FundCodeAndNameWithInfo = withDropdown(({ code, name }) => {
  return (
    <span className="fund-info">
      <span className="code">
        {code} {name}
      </span>
    </span>
  )
})

const propTypes = {
  thaiName: PropTypes.string,
  englishName: PropTypes.string,
  code: PropTypes.string,
  fundHouse: PropTypes.string,
  factSheet: PropTypes.string,
  riskLevel: PropTypes.number,
  isAllowed: PropTypes.bool,
  isOverRisk: PropTypes.bool,
  isForeign: PropTypes.bool,
  selected: PropTypes.bool,
  allocationPercent: PropTypes.number,
  errors: PropTypes.arrayOf(PropTypes.string),
}
const defaultProps = {
  thaiName: '',
  englishName: '',
  code: '',
  fundHouse: '',
  factSheet: '',
  riskLevel: 1,
  isAllowed: true,
  isOverRisk: false,
  isForeign: false,
  selected: false,
  allocationPercent: 0,
  errors: [],
}

SubFundItem.propTypes = propTypes
SubFundItem.defaultProps = defaultProps

export default SubFundItem
