// @flow
import type { Gender, Age, NatureOfDuty, Relation } from 'core/data-model/insured'
import type { Reason } from 'core/data-model/fna'

// ==== action types ====

export type Action =
  | SelectGender
  | UpdateBirthdate
  | UpdatePayerBirthdate
  | SelectOccupation
  | SelectNatureOfDuty
  | UpdatePayerNeeded
  | SelectPayerGender
  | SetOccupationFactor
  | UpdateOccupationErrors
  | SelectPayerOccupation
  | SelectPayerNatureOfDuty
  | UpdatePayerOccupationErrors

export const VALIDATE_INSURED_INFORMATION = 'VALIDATE_INSURED_INFORMATION'
export type ValidateInsuredInformation = {
  type: 'VALIDATE_INSURED_INFORMATION',
}

export const SELECT_GENDER = 'SELECT_GENDER'
export type SelectGender = {
  type: 'SELECT_GENDER',
  payload: Gender,
}

export const SELECT_PAYER_GENDER = 'SELECT_PAYER_GENDER'
type SelectPayerGender = {
  type: 'SELECT_PAYER_GENDER',
  payload: Gender,
}

export const SELECT_PAYER_RELATION = 'SELECT_PAYER_RELATION'
type SelectPayerRelation = {
  type: 'SELECT_PAYER_RELATION',
  payload: Relation,
}

export const SET_OCCUPATION_FACTOR = 'SET_OCCUPATION_FACTOR'
export type SetOccupationFactor = {
  type: 'SET_OCCUPATION_FACTOR',
  payload: string,
}

export const EDIT_BIRTHDATE = 'EDIT_BIRTHDATE'
export type EditBirthdate = {
  type: 'EDIT_BIRTHDATE',
  birthdate: string,
}

export const UPDATE_BIRTHDATE = 'UPDATE_BIRTHDATE'
export type UpdateBirthdate = {
  type: 'UPDATE_BIRTHDATE',
  birthdate: string,
  errors: string[],
  age: Age,
}

export const UPDATE_PAYER_BIRTHDATE = 'UPDATE_PAYER_BIRTHDATE'
export type UpdatePayerBirthdate = {
  type: 'UPDATE_PAYER_BIRTHDATE',
  birthdate: string,
  errors: string[],
  age: Age,
}

export const EDIT_PAYER_BIRTHDATE = 'EDIT_PAYER_BIRTHDATE'
export type EditPayerBirthdate = {
  type: 'EDIT_PAYER_BIRTHDATE',
  birthdate: string,
}

export const SELECT_OCCUPATION = 'SELECT_OCCUPATION'
export type SelectOccupation = {
  type: 'SELECT_OCCUPATION',
  payload: string,
}

export const SELECT_NATURE_OF_DUTY = 'SELECT_NATURE_OF_DUTY'
export type SelectNatureOfDuty = {
  type: 'SELECT_NATURE_OF_DUTY',
  payload: NatureOfDuty,
}

export const SELECT_PAYER_OCCUPATION = 'SELECT_PAYER_OCCUPATION'
export type SelectPayerOccupation = {
  type: 'SELECT_PAYER_OCCUPATION',
  payload: string,
}

export const SELECT_PAYER_NATURE_OF_DUTY = 'SELECT_PAYER_NATURE_OF_DUTY'
export type SelectPayerNatureOfDuty = {
  type: 'SELECT_PAYER_NATURE_OF_DUTY',
  payload: NatureOfDuty,
}

export const SELECT_OTHER_OCCUPATION = 'SELECT_OTHER_OCCUPATION'
export type SelectOtherOccupation = {
  type: 'SELECT_OTHER_OCCUPATION',
  payload: string,
}

export const SELECT_PAYER_OTHER_OCCUPATION = 'SELECT_PAYER_OTHER_OCCUPATION'
export type SelectPayerOtherOccupation = {
  type: 'SELECT_PAYER_OTHER_OCCUPATION',
  payload: string,
}

export const SELECT_OTHER_NATURE_OF_DUTY = 'SELECT_OTHER_NATURE_OF_DUTY'
export type SelectOtherNatureOfDuty = {
  type: 'SELECT_OTHER_NATURE_OF_DUTY',
  payload: NatureOfDuty,
}

export const SELECT_PAYER_OTHER_NATURE_OF_DUTY = 'SELECT_PAYER_OTHER_NATURE_OF_DUTY'
export type SelectPayerOtherNatureOfDuty = {
  type: 'SELECT_PAYER_OTHER_NATURE_OF_DUTY',
  payload: NatureOfDuty,
}

export const TOGGLE_OTHER_OCCUPATION = 'TOGGLE_OTHER_OCCUPATION'

export const TOGGLE_PAYER_OTHER_OCCUPATION = 'TOGGLE_PAYER_OTHER_OCCUPATION'

export const UPDATE_OTHER_OCCUPATION_ERRORS = 'UPDATE_OTHER_OCCUPATION_ERRORS'
type UpdateOtherOccupationErrors = {
  type: 'UPDATE_OTHER_OCCUPATION_ERRORS',
  payload: string[],
}

export const UPDATE_PAYER_OTHER_OCCUPATION_ERRORS = 'UPDATE_PAYER_OTHER_OCCUPATION_ERRORS'
type UpdatePayerOtherOccupationErrors = {
  type: 'UPDATE_PAYER_OTHER_OCCUPATION_ERRORS',
  payload: string[],
}

export const UPDATE_PAYER_NEEDED = 'UPDATE_PAYER_NEEDED'
type UpdatePayerNeeded = {
  type: 'UPDATE_PAYER_NEEDED',
  payload: boolean,
}

export const UPDATE_OCCUPATION_ERRORS = 'UPDATE_OCCUPATION_ERRORS'
type UpdateOccupationErrors = {
  type: 'UPDATE_OCCUPATION_ERRORS',
  payload: string[],
}

export const UPDATE_PAYER_OCCUPATION_ERRORS = 'UPDATE_PAYER_OCCUPATION_ERRORS'
type UpdatePayerOccupationErrors = {
  type: 'UPDATE_PAYER_OCCUPATION_ERRORS',
  payload: string[],
}

export const SELECT_REASON = 'SELECT_REASON'
export type SelectReason = {
  type: 'SELECT_REASON',
  payload: Reason,
}

export const EDIT_TITLE_NAME = 'TITLE/EDIT_NAME'

// ==== action creators ====
export const validateInsuredInformation = (): ValidateInsuredInformation => ({
  type: VALIDATE_INSURED_INFORMATION,
})

export const selectGender = (gender: Gender): SelectGender => ({
  type: SELECT_GENDER,
  payload: gender,
})

export const selectReason = (reason: Reason): SelectReason => ({
  type: SELECT_REASON,
  payload: reason,
})

export const selectPayerGender = (gender: Gender): SelectPayerGender => ({
  type: SELECT_PAYER_GENDER,
  payload: gender,
})

export const selectPayerRelation = (relation: Relation): SelectPayerRelation => ({
  type: SELECT_PAYER_RELATION,
  payload: relation,
})

export const CLEAR_PAYER_INFORMATION = 'CLEAR_PAYER_INFORMATION'
export const clearPayerInformation = () => ({
  type: CLEAR_PAYER_INFORMATION,
})

export const CLEAR_PAYER_NEEDED = 'CLEAR_PAYER_NEEDED'
export const clearPayerNeeded = () => ({
  type: CLEAR_PAYER_NEEDED,
})

export const editBirthdate = (birthdate: string): EditBirthdate => ({
  type: EDIT_BIRTHDATE,
  birthdate: birthdate,
})

export const updateBirthdate = (birthdate: string, errors: string[], age: Age): UpdateBirthdate => ({
  type: UPDATE_BIRTHDATE,
  birthdate,
  errors,
  age,
})

export const editPayerBirthdate = (birthdate: string): EditPayerBirthdate => {
  return {
    type: EDIT_PAYER_BIRTHDATE,
    birthdate: birthdate,
  }
}

export const updatePayerBirthdate = (birthdate: string, errors: string[], age: Age): UpdatePayerBirthdate => ({
  type: UPDATE_PAYER_BIRTHDATE,
  birthdate,
  errors,
  age,
})

export const selectOccupation = (selectedOccupation: string): SelectOccupation => ({
  type: SELECT_OCCUPATION,
  payload: selectedOccupation,
})

export const selectNatureOfDuty = (natureOfDuty: NatureOfDuty): SelectNatureOfDuty => ({
  type: SELECT_NATURE_OF_DUTY,
  payload: natureOfDuty,
})

export const selectPayerOccupation = (selectedOccupation: string): SelectPayerOccupation => ({
  type: SELECT_PAYER_OCCUPATION,
  payload: selectedOccupation,
})

export const selectPayerNatureOfDuty = (natureOfDuty: NatureOfDuty): SelectPayerNatureOfDuty => ({
  type: SELECT_PAYER_NATURE_OF_DUTY,
  payload: natureOfDuty,
})

export const selectOtherOccupation = (selectedOtherOccupation: string): SelectOtherOccupation => ({
  type: SELECT_OTHER_OCCUPATION,
  payload: selectedOtherOccupation,
})

export const selectPayerOtherOccupation = (selectedPayerOtherOccupation: string): SelectPayerOtherOccupation => ({
  type: SELECT_PAYER_OTHER_OCCUPATION,
  payload: selectedPayerOtherOccupation,
})

export const selectOtherNatureOfDuty = (otherNatureOfDuty: NatureOfDuty): SelectOtherNatureOfDuty => ({
  type: SELECT_OTHER_NATURE_OF_DUTY,
  payload: otherNatureOfDuty,
})

export const selectPayerOtherNatureOfDuty = (payerOtherNatureOfDuty: NatureOfDuty): SelectPayerOtherNatureOfDuty => ({
  type: SELECT_PAYER_OTHER_NATURE_OF_DUTY,
  payload: payerOtherNatureOfDuty,
})

export const toggleOtherOccupation = () => ({
  type: TOGGLE_OTHER_OCCUPATION,
})

export const togglePayerOtherOccupation = () => ({
  type: TOGGLE_PAYER_OTHER_OCCUPATION,
})

export const updateOtherOccupationErrors = (errors: string[]): UpdateOtherOccupationErrors => ({
  type: UPDATE_OTHER_OCCUPATION_ERRORS,
  payload: errors,
})

export const updatePayerOtherOccupationErrors = (errors: string[]): UpdatePayerOtherOccupationErrors => ({
  type: UPDATE_PAYER_OTHER_OCCUPATION_ERRORS,
  payload: errors,
})

export const updatePayerNeeded = (payerNeeded: boolean): UpdatePayerNeeded => ({
  type: UPDATE_PAYER_NEEDED,
  payload: payerNeeded,
})

export const setOccupationFactor = (occupationFactor: string): SetOccupationFactor => ({
  type: SET_OCCUPATION_FACTOR,
  payload: occupationFactor,
})

export const updateOccupationErrors = (errors: string[]): UpdateOccupationErrors => ({
  type: UPDATE_OCCUPATION_ERRORS,
  payload: errors,
})

export const updatePayerOccupationErrors = (errors: string[]): UpdatePayerOccupationErrors => ({
  type: UPDATE_PAYER_OCCUPATION_ERRORS,
  payload: errors,
})
