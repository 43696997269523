// @flow

import { Section } from 'core/service/pdf-generation/products/common/components/general'
import BIMESSAGES from 'core/data-model/constants/bi-messages'

const BIMESSAGE = {
  RECOMMENDATION_HEADER: 'ข้อรับรู้ที่สำคัญ',
  WARNING_HEADER: 'คำเตือน',
}

const iWealthyRecommendation = () => [
  {
    ul: [
      'การนำส่งเบี้ยประกันภัยเป็นหน้าที่ของผู้เอาประกันภัย กรณีที่ตัวแทนประกันชีวิต หรือ นายหน้าประกันชีวิต เป็นผู้รับเบี้ยประกันภัยและนำไปชำระแทนผู้เอาประกันภัย เป็นการให้บริการเท่านั้น',
      'ผู้ขอเอาประกันภัยควรพิจารณาถึงความสามารถในการชำระเบี้ยประกันภัย เนื่องจากกรมธรรม์นี้เหมาะสำหรับการออมเงินระยะยาว มีค่าธรรมเนียมในการถอนเงินจากกรมธรรม์ ซึ่งอาจมีผลให้ มูลค่าเวนคืนกรมธรรม์ และมูลค่าจากการถอนเงินจากกรมธรรม์ มีมูลค่าน้อยกว่ามูลค่ารับซื้อคืนหน่วยลงทุน ผู้เอาประกันภัยควรศึกษารายละเอียดจากตารางค่าธรรมเนียมในการถอนเงินจากกรมธรรม์',
      'ผู้ขอเอาประกันภัยควรศึกษา อ่าน และทำความเข้าใจในเอกสารประกอบการเสนอขาย ก่อนการตัดสินใจทำประกันภัย',
      'กองทุนรวมเป็นนิติบุคคลแยกต่างหากจากบริษัทประกันชีวิตและบริษัทจัดการ ดังนั้นบริษัทประกันชีวิตและบริษัทจัดการจึงไม่มีภาระผูกพันในการชดเชยผลขาดทุนของกองทุนรวมควบรวมกรมธรรม์ และจะไม่สามารถนำทรัพย์สินของกองทุนรวมไปใช้ประโยชน์เพื่อการชำระหนี้ของตนเอง',
      'การลงทุนมีความเสี่ยง ผู้เอาประกันภัยอาจได้รับเงินคืนมากกว่าหรือน้อยกว่ามูลค่าเบี้ยประกันภัยที่ถูกจัดสรรเข้ากองทุนรวม โดยหากผลการดำเนินงานของกองทุนรวมมีกำไร ผู้เอาประกันภัยก็จะได้ ผลตอบแทนสูง แต่หากผลการดำเนินงานของกองทุนรวมขาดทุน ผู้เอาประกันภัยจะได้ผลตอบแทนต่ำหรือบางกรณีอาจขาดทุน กล่าวคือ ไม่ได้รับเงินส่วนลงทุนคืนเต็มจำนวน ทั้งนี้ กรมธรรม์นี้ไม่มีการรับประกันเงินส่วนลงทุน',
      'มูลค่าบัญชีของกรมธรรม์ที่แสดงอยู่ในใบนำเสนอการขายนี้ เกิดจากการใช้สมมติฐาน เพื่อที่จะแสดงให้เห็นถึงผลกระทบของมูลค่าบัญชีกรมธรรม์ ที่เกิดจากการจ่ายค่าธรรมเนียมต่าง ๆ รวมถึงผลตอบแทน การลงทุน โดยสมมติฐานเหล่านี้ จะไม่มีส่วนเกี่ยวข้อง กับเงื่อนไขต่าง ๆ ที่ถูกระบุไว้ในสัญญากรมธรรม์',
      'การลงทุนในหน่วยลงทุนมิใช่การฝากเงิน และมีความเสี่ยงของการลงทุน ผู้เอาประกันภัยควรเลือกกองทุนรวมภายใต้กรมธรรม์ เมื่อเห็นว่าการลงทุนในกองทุนรวมเหมาะสมกับวัตถุประสงค์การลงทุนของตน และผู้เอาประกันภัยยอมรับความเสี่ยงที่อาจเกิดขึ้นจากการลงทุนดังกล่าวได้',
      'เพื่อผลประโยชน์สูงสุดตามเงื่อนไขกรมธรรม์ ผู้เอาประกันภัยควรถือกรมธรรม์จนครบกำหนดสัญญาประกันภัย ซี่งมูลค่าบัญชีกรมธรรม์ ณ วันครบกำหนดสัญญา อาจมีมูลค่าต่ำกว่าเบี้ยประกันภัย ที่ชำระมาแล้วทั้งหมด ทั้งนี้ขึ้นอยู่กับผลตอบแทนจากการลงทุน หรือผู้เอาประกันภัยอยู่ในกลุ่มผู้สูงอายุ ซึ่งมีค่าการประกันภัยค่อนข้างสูง',
      'ผู้เอาประกันภัยควรอ่านหนังสือชี้ชวนของกองทุน อย่างละเอียดก่อนที่จะตัดสินใจเลือกที่จะลงทุนในกองทุนรวม และควรเก็บหนังสือชี้ชวนของกองทุนเพื่ออ้างอิงในอนาคตต่อไป และหากมีข้อสงสัยประการใด โปรดติดต่อตัวแทน หรือ พนักงานนายหน้า ที่ได้รับอนุญาตจาก กรุงไทย-แอกซ่า ก่อนที่จะตัดสินใจเลือก ซื้อ/ขาย กองทุนภายใต้กรมธรรม์นี้',
      'ผู้เอาประกันภัยควรแน่ใจว่าบุคคลที่ทำการติดต่อ เพื่อแนะนำการลงทุน / แนะนำการประกันชีวิต เป็นบุคคลที่ได้รับอนุญาตจากสำนักงานคณะกรรมการกำกับและส่งเสริมการประกอบธุรกิจประกันภัย (OIC) และ สำนักงานคณะกรรมการกำกับหลักทรัพย์และตลาดหลักทรัพย์ (SEC) โดยผู้เอาประกันภัยควรที่จะได้รับการแสดงบัตรรับรองจากบุคคลที่ได้รับการติดต่อ เพื่อแนะนำการลงทุน / แนะนำการประกันชีวิต',
      'การลงทุนมีความเสี่ยง ผู้เอาประกันภัยอาจได้รับผลกำไรจากเบี้ยประกันภัย ที่ถูกนำไปลงทุนในกองทุนภายใต้กรมธรรม์ หากกองทุนเหล่านี้มีผลประกอบการที่ดี แต่ในทางกลับกันหาก กองทุนภายใต้กรมธรรม์ เหล่านี้ มีผลประกอบการที่ไม่น่าพึงพอใจ ผู้เอาประกันภัยอาจประสบภาวะขาดทุนจากเบี้ยประกันภัย กล่าวคือ ไม่ได้รับเงินส่วนลงทุนคืนเต็มจำนวน ทั้งนี้ กรมธรรม์นี้ไม่มีการรับประกันเงินส่วนลงทุน',
      'ผลการดำเนินงานของกองทุนรวมควบกรมธรรม์ไม่ได้ขึ้นอยู่กับสถานะทางการเงินหรือผลการดำเนินงานของบริษัทประกันชีวิตและบริษัทจัดการ',
    ],
  },
]

const iInvestRecommendation = () => [
  {
    ul: [
      'การนำส่งเบี้ยประกันภัยเป็นหน้าที่ของผู้เอาประกันภัย กรณีที่ตัวแทนประกันชีวิต หรือ นายหน้าประกันชีวิต เป็นผู้รับเบี้ยประกันภัยและนำไปชำระแทนผู้เอาประกันภัย เป็นการให้บริการเท่านั้น',
      'มูลค่าบัญชีของกรมธรรม์ที่แสดงอยู่ใน คู่มือการเสนอขายนี้ เกิดจากการใช้สมมติฐาน เพื่อที่จะแสดงให้เห็นถึงผลกระทบของมูลค่าบัญชีกรมธรรม์ ที่เกิดจากการจ่ายค่าธรรมเนียมต่าง ๆ รวมถึงผลตอบแทนการลงทุน โดยสมมติฐานเหล่านี้จะไม่มีส่วนเกี่ยวข้อง กับเงื่อนไขต่าง ๆ ที่ถูกระบุไว้ในสัญญากรมธรรม์ ',
      'การลงทุนในหน่วยลงทุนมิใช่การฝากเงิน และมีความเสี่ยงของการลงทุน ผู้เอาประกันภัยควรเลือกกองทุนรวมภายใต้กรมธรรม์ เมื่อเห็นว่าการลงทุนในกองทุนรวมเหมาะสมกับวัตถุประสงค์การลงทุนของตนและผู้เอาประกันภัยยอมรับความเสี่ยงที่อาจเกิดขึ้นจากการลงทุนดังกล่าวได้',
      'เพื่อผลประโยชน์สูงสุดตามเงื่อนไขกรมธรรม์ ผู้เอาประกันภัยควรถือกรมธรรม์จนครบกำหนดสัญญาประกันภัย ซี่งมูลค่าบัญชีกรมธรรม์ ณ วันครบกำหนดสัญญา อาจมีมูลค่าต่ำกว่าเบี้ยประกันภัยที่ชำระมาแล้วทั้งหมด ทั้งนี้ ขึ้นอยู่กับผลตอบแทนจากการลงทุน หรือผู้เอาประกันภัยอยู่ในกลุ่มผู้สูงอายุ ซึ่งมีค่าการประกันภัยค่อนข้างสูง',
      'ผู้เอาประกันภัยควรอ่านหนังสือชี้ชวนของกองทุน อย่างละเอียดก่อนที่จะตัดสินใจเลือกที่จะลงทุนในกองทุนรวม และควรเก็บหนังสือชี้ชวนของกองทุนเพื่ออ้างอิงในอนาคตต่อไป และหากมีข้อสงสัยประการใด โปรดติดต่อตัวแทน หรือ พนักงานนายหน้า ที่ได้รับอนุญาตจาก กรุงไทย-แอกซ่า ก่อนที่จะตัดสินใจเลือก ซื้อ/ขาย กองทุนภายใต้กรมธรรม์นี้',
      'ผู้เอาประกันภัยควรแน่ใจว่าบุคคลที่ทำการติดต่อ เพื่อแนะนำการลงทุน / แนะนำการประกันชีวิต เป็นบุคคลที่ได้รับอนุญาตจากสำนักงานคณะกรรมการกำกับและส่งเสริมการประกอบธุรกิจประกันภัย (OIC) และ สำนักงานคณะกรรมการกำกับหลักทรัพย์และตลาดหลักทรัพย์ (SEC) โดยผู้เอาประกันภัยควรที่จะได้รับการแสดงบัตรรับรองจากบุคคลที่ได้รับการติดต่อ เพื่อแนะนำการลงทุน / แนะนำการประกันชีวิต',
      'การลงทุนมีความเสี่ยง ผู้เอาประกันภัยอาจได้รับผลกำไรจากเบี้ยประกันภัย ที่ถูกนำไปลงทุนในกองทุนภายใต้กรมธรรม์ หากกองทุนเหล่านี้มีผลประกอบการที่ดี แต่ในทางกลับกันหากกองทุนภายใต้กรมธรรม์เหล่านี้มีผลประกอบการที่ไม่น่าพึงพอใจ ผู้เอาประกันภัยอาจประสบภาวะขาดทุนจากเบี้ยประกันภัย',
    ],
  },
]

const rpudrRecommendation = () => [
  {
    ol: [
      [
        'ผลิตภัณฑ์ประกันชีวิตนี้มีการลงทุนที่ให้ผลตอบแทนขึ้นอยู่กับผลการดำเนินงานของกองทุนรวมที่ผู้เอาประกันภัยได้เลือกลงทุนไว้ การลงทุนในกองทุนรวมมีความเสี่ยง',
        'โดยผู้เอาประกันภัยเป็นผู้เลือกลงทุนในกองทุนเพื่อวัตถุประสงค์ในการลงทุนของตนเอง และเป็นผู้รับความเสี่ยงจากการลงทุนเอง ทั้งนี้กรมธรรม์นี้ไม่มีการรับประกัน',
        'เงินส่วนที่ได้ลงทุนไป ความผันผวนของสภาวการณ์ทางการตลาดหรือผลประกอบการของกองทุนรวมอาจทำให้ผู้เอาประกันภัยได้รับผลตอบแทนมากกว่าหรือน้อยกว่า',
        'มูลค่าเบี้ยประกันภัยที่ถูกจัดสรรเข้ากองทุนที่ผู้เอาประกันภัยเป็นผู้เลือก ซึ่งผู้เอาประกันภัยอาจจำเป็นต้องชำระเบี้ยประกันภัยเพิ่มเติมเพื่อให้กรมธรรม์มีผลบังคับ',
        'อย่างต่อเนื่อง',
      ],
      [
        'ผู้เอาประกันภัยควรพิจารณาถึงความสามารถในการชำระเบี้ยประกันภัย เนื่องจากสัญญาประกันภัยนี้แบบประกันภัยระยะยาว ผู้เอาประกันภัยควรศึกษาผลประโยชน์',
        'เงื่อนไข ค่าธรรมเนียม และ/หรือ ค่าใช้จ่ายตามที่ระบุไว้ในสัญญาประกันภัยนี้ รวมถึงผู้เอาประกันภัยสามารถศึกษาข้อมูลเกี่ยวกับค่าธรรมเนียม และหรือ',
        'ค่าใช้จ่ายอื่น ๆ ที่เกี่ยวข้องกับการลงทุนในกองทุนรวมที่ไม่ได้ระบุไว้ในสัญญาประกันภัยได้จากหนังสือชี้ชวนของกองทุนรวมที่ผู้เอาประกันภัยได้เลือกไว้',
      ],
      ['ผู้เอาประกันภัยควรศึกษารายละเอียดเกี่ยวกับความเสี่ยงจากการลงทุนของกองทุนรวมในหนังสือชี้ชวนของกองทุนด้วย'],
      [
        'กองทุนรวมเป็นนิติบุคคลแยกต่างหากจากบริษัทและบริษัทจัดการ ดังนั้น บริษัทและบริษัทจัดการจึงไม่มีภาระผูกผันในการชดเชยผลขาดทุนของกองทุนรวม',
        'และจะไม่สามารถนำทรัพย์สินของกองทุนรวมไปใช้ประโยชน์เพื่อวัตถุประสงค์ของตนเองได้',
      ],
      ['ผลการดำเนินงานของกองทุนรวมไม่ได้ขึ้นอยู่กับสถานะทางการเงิน หรือผลการดำเนินงานของบริษัทและบริษัทจัดการ'],
      [
        'การนำส่งเบี้ยประกันภัยเป็นหน้าที่ของผู้เอาประกันภัย ซึ่งบริษัทกรุงไทย-แอกซ่ามีการบริการรับชำระเบี้ยประกันภัยผ่านช่องทางต่าง ๆ เช่น การชำระผ่าน',
        'การหักบัญชีธนาคารหรือบัญชีบัตรเครดิตโดยอัตโนมัติ เพื่อให้มั่นใจว่าสามารถชำระเบี้ยประกันภัยได้ทันตามกำหนดเวลา',
      ],
      [
        'ผู้เอาประกันภัยควรแน่ใจว่าบุคคลที่ทำการติดต่อเพื่อแนะนำการลงทุน / แนะนำการประกันชีวิต เป็นบุคคลที่ได้รับอนุญาตจากสำนักงานคณะกรรมการกำกับและ',
        'ส่งเสริมการประกอบธุรกิจประกันภัย (OIC) และ สำนักงานคณะกรรมการกำกับหลักทรัพย์และตลาดหลักทรัพย์ (SEC) โดยผู้เอาประกันภัยควรที่จะได้รับ',
        'การแสดงบัตรรับรองจากบุคคลที่ได้รับการติดต่อเพื่อแนะนำการลงทุน / แนะนำการประกันชีวิต',
      ],
    ],
  },
]

export const Recommendation = (productCode: string) => {
  switch (productCode) {
    case BIMESSAGES.BI_TYPE_PRODUCT_CODE_IINVEST:
      return Section(BIMESSAGE.RECOMMENDATION_HEADER, iInvestRecommendation())
    case BIMESSAGES.BI_TYPE_PRODUCT_CODE_IWEALTHY:
      return Section(BIMESSAGE.RECOMMENDATION_HEADER, iWealthyRecommendation())
    case BIMESSAGES.BI_TYPE_PRODUCT_CODE_RPUDR:
      return Section(BIMESSAGE.WARNING_HEADER, rpudrRecommendation())
    default:
      return []
  }
}
