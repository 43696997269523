import { flow, get, map } from 'lodash/fp'

const mapWithKey = map.convert({ cap: false })

export const getAllReceiptKey = flow(
  get('receipts'),
  mapWithKey((value, index) => `receipts[${index}]`)
)

export const SIGNATURE_TYPE = {
  FAMILY_DISCOUNT: {
    signedBy: {
      acknowledge: () => 'insured',
    },
  },
  APPLICATION: {
    signedBy: {
      insured: () => 'insured',
      payer: () => 'payer',
      agent: (app) => 'advisor',
    },
  },
  FATCA: {
    signedBy: {
      insured: () => 'insured',
    },
  },
  CRS: {
    signedBy: {
      insured: () => 'insured',
      payer: () => 'payer',
    },
  },
  BI: {
    signedBy: {
      insured: () => 'insured',
      agent: () => 'advisor',
      payer: () => 'payer',
      wealthHub: () => 'wealthHub',
    },
  },
  ADDENDUM: {
    signedBy: {
      insured: () => 'insured',
      payer: () => 'payer',
      agent: () => 'advisor',
    },
  },
  ATP: {
    signedBy: {
      insured: () => 'insured',
      payer: () => 'accountHolder',
      agent: () => 'advisor',
    },
  },
  DCA: {
    signedBy: {
      insured: () => 'insured',
    },
  },
  ECBR: {
    signedBy: {
      agent: getAllReceiptKey,
    },
  },
  E_POLICY_CONSENT: {
    signedBy: {
      insured: () => 'insured',
      payer: () => 'payer',
      agent: () => 'advisor',
    },
  },
  PENSION_RECEIVE: {
    signedBy: {
      insured: () => 'insured',
    },
  },
  JUVENILE_PURCHASE: {
    signedBy: {
      insured: () => 'insured',
      payer: () => 'payer',
      agent: () => 'advisor',
    },
  },
  FOREIGNER: {
    signedBy: {
      insured: () => 'insured',
      agent: () => 'advisor',
    },
  },
  // only for investment

  KYC: {
    signedBy: {
      insured: () => 'insured',
      payer: () => 'payer',
    },
  },
  RPQ: {
    signedBy: {
      insured: () => 'insured',
      payer: () => 'payer',
      agent: () => 'advisor',
    },
  },
  RA: {
    signedBy: {
      insured: () => 'insured',
      payer: () => 'payer',
    },
  },
  PRIVACY_CONSENT: {
    signedBy: {
      insured: () => 'insured',
      payer: () => 'payer',
    },
  },
  CITIZEN_ID_CONSENT_INSURED: {
    signedBy: {
      acknowledge: () => 'insured',
      insured: () => 'insured',
    },
  },
  CITIZEN_ID_CONSENT_PAYER: {
    signedBy: {
      acknowledge: () => 'insured',
      payer: () => 'payer',
    },
  },
  PASSPORT_CONSENT_INSURED: {
    signedBy: {
      acknowledge: () => 'insured',
      insured: () => 'insured',
    },
  },
  PASSPORT_CONSENT_PAYER: {
    signedBy: {
      acknowledge: () => 'insured',
      payer: () => 'payer',
    },
  },
  PRIVACY_POLICY: {
    signedBy: {
      acknowledge: () => 'insured',
    },
  },
  FNA: {
    signedBy: {
      insured: () => 'insured',
      payer: () => 'payer',
      agent: () => 'advisor',
      thirdPerson: () => 'thirdPerson',
    },
  },
  VULNERABLE_CUSTOMER: {
    signedBy: {
      agent: () => 'advisor',
    },
  },
}

export const DOCUMENT_META = {
  CITIZEN_ID_CONSENT_INSURED: {
    name: 'citizen-id-consent-insured',
    text: 'บัตรประจำตัวประชาชน',
    notShowInSignatureSection: true,
  },
  CITIZEN_ID_CONSENT_PAYER: {
    name: 'citizen-id-consent-payer',
    text: 'บัตรประจำตัวประชาชน',
    notShowInSignatureSection: true,
  },
  PASSPORT_CONSENT_INSURED: {
    name: 'passport-consent-insured',
    text: 'หนังสือเดินทาง',
    notShowInSignatureSection: true,
  },
  PASSPORT_CONSENT_PAYER: {
    name: 'passport-consent-payer',
    text: 'หนังสือเดินทาง',
    notShowInSignatureSection: true,
  },
  FAMILY_DISCOUNT: {
    name: 'family-discount',
    text: 'เอกสารแนบกรณีรับส่วนลดเบี้ยประกันภัยสำหรับครอบครัว',
    shortText: 'ส่วนลดสำหรับครอบครัว',
  },
  APPLICATION: {
    name: 'application',
    text: 'ใบคำขอเอาประกันภัย ประเภทสามัญชนิดทั่วไป',
    shortText: 'ใบคำขอฯ',
  },
  BI: {
    name: 'bi',
    text: 'เอกสารประกอบการเสนอขาย',
    shortText: 'เอกสารเสนอขายฯ',
  },
  ADDENDUM: {
    name: 'addendum',
    text: 'ถ้อยคำแถลงเพิ่มเติม แบบท้ายใบคำขอเอาประกันภัย',
    shortText: 'ถ้อยคำแถลงเพิ่มเติม',
  },
  E_POLICY_CONSENT: {
    name: 'e-policy-consent',
    text: 'ใบแจ้งความประสงค์ขอรับกรมธรรม์อิเล็กทรอนิกส์',
    shortText: 'กรมธรรม์อิเล็กทรอนิกส์',
  },
  KYC: {
    name: 'kyc',
    text: 'แบบแสดงข้อมูลผู้ถือหน่วยลงทุน (KYC)',
    shortText: 'ข้อมูลผู้ถือหน่วยลงทุน',
  },
  RPQ: {
    name: 'rpq',
    text: 'แบบประเมินความเสี่ยงในการลงทุน (RPQ)',
    shortText: 'แบบประเมินความเสี่ยง',
  },
  RA: {
    name: 'ra',
    text: 'แบบฟอร์มรับทราบความเสี่ยง (RA)',
    shortText: 'แบบฟอร์มรับทราบความเสี่ยง',
  },
  PENSION_RECEIVE: {
    name: 'pension-receive',
    text: 'แบบฟอร์มหนังสือแจ้งความประสงค์ในการขอเลือกรับเงินบำนาญ สำหรับผลิตภัณฑ์ประกันแบบบำนาญ',
    shortText: 'แบบฟอร์มการขอเลือกรับบำนาญ',
  },
  PRIVACY_CONSENT: {
    name: 'privacy-consent',
    text: 'หนังสือให้ความยินยอมในการเก็บ ใช้ และเปิดเผยข้อมูล',
    shortText: 'หนังสือให้ความยินยอมในการเก็บ ใช้ และเปิดเผยข้อมูล',
  },
  PRIVACY_POLICY: {
    name: 'privacy-policy',
    text: 'นโยบายความเป็นส่วนตัว',
    shortText: 'นโยบายความเป็นส่วนตัว',
  },
  FNA: {
    name: 'fna',
    text: 'แบบสอบถามเพื่อวิเคราะห์ความต้องการซื้อผลิตภัณฑ์ประกันชีวิต',
    shortText: 'แบบสอบถามเพื่อวิเคราะห์ความต้องการซื้อผลิตภัณฑ์ประกันชีวิต',
  },
  VULNERABLE_CUSTOMER: {
    name: 'vulnerable-customer',
    text: 'แบบสอบถามความเข้าใจ กลุ่มคำถามเปราะบาง',
    shortText: 'แบบสอบถามความเข้าใจ กลุ่มคำถามเปราะบาง',
  },
  SELFIE: {
    name: 'selfie',
    text: 'รูปเซลฟี่พร้อมถือบัตรประชาชน/หนังสือเดินทาง (ในกรณีชาวต่างชาติ)',
    shortText: 'รูปเซลฟี่พร้อมถือบัตรประชาชน/หนังสือเดินทาง (ในกรณีชาวต่างชาติ)',
  },
  FATCA: {
    name: 'fatca',
    text: 'หนังสือยินยอมการให้ข้อมูลตามกฎหมาย FATCA',
    shortText: 'หนังสือยินยอมการให้ข้อมูลตามกฎหมาย FATCA',
  },
  CRS: {
    name: 'crs',
    text: 'เอกสารรับรองตนเองเพื่อปฏิบัติตามมาตรฐานการแลกเปลี่ยนข้อมูลทางการเงินอัตโนมัติ',
    shortText: 'เอกสารรับรองตนเอง ถิ่นที่อยู่ทางภาษี(CRS)',
  },
  DA_RELATIONSHIP: {
    name: 'da-relationship-doc',
    text: 'เอกสารพิสูจน์ความสัมพันธ์',
    shortText: 'เอกสารพิสูจน์ความสัมพันธ์',
  },
}

export const DOCUMENT_TYPE = {
  acknowledge: {
    FAMILY_DISCOUNT: { ...DOCUMENT_META.FAMILY_DISCOUNT },
    PRIVACY_POLICY: { ...DOCUMENT_META.PRIVACY_POLICY },
    CITIZEN_ID_CONSENT_INSURED: { ...DOCUMENT_META.CITIZEN_ID_CONSENT_INSURED },
    CITIZEN_ID_CONSENT_PAYER: { ...DOCUMENT_META.CITIZEN_ID_CONSENT_PAYER },
    PASSPORT_CONSENT_INSURED: { ...DOCUMENT_META.PASSPORT_CONSENT_INSURED },
    PASSPORT_CONSENT_PAYER: { ...DOCUMENT_META.PASSPORT_CONSENT_PAYER },
    SELFIE: { ...DOCUMENT_META.SELFIE },
  },
  agent: {
    ATP: {
      name: 'atp',
      text: 'หนังสือยินยอมหักค่าเบี้ยประกันอัตโนมัติ',
    },
    APPLICATION: { ...DOCUMENT_META.APPLICATION },
    BI: { ...DOCUMENT_META.BI },
    ADDENDUM: { ...DOCUMENT_META.ADDENDUM },
    E_POLICY_CONSENT: { ...DOCUMENT_META.E_POLICY_CONSENT },
    ECBR: {
      name: 'ecbr',
      text: 'ตัวแทน/นายหน้าประกันชีวิตออกใบรับเงินชั่วคราว',
    },
    KYC: { ...DOCUMENT_META.KYC },
    RPQ: { ...DOCUMENT_META.RPQ },
    FNA: { ...DOCUMENT_META.FNA },
    JUVENILE_PURCHASE: {
      name: 'family-purchasing',
      text: 'แบบสอบถามเพื่อการซื้อประกันสุขภาพผู้เยาว์',
    },
    FOREIGNER: {
      name: 'foreigner',
      text: 'แบบสอบถามชาวต่างชาติ',
    },
    VULNERABLE_CUSTOMER: { ...DOCUMENT_META.VULNERABLE_CUSTOMER },
  },
  insured: {
    ATP: {
      name: 'atp',
      text: 'หนังสือยินยอมหักค่าเบี้ยประกันอัตโนมัติ',
    },
    CCPA: {
      name: 'ccpa',
      text: 'หนังสือการสมัครหักค่าเบี้ยประกันอัตโนมัติผ่านบัตรเครดิต',
    },
    APPLICATION: { ...DOCUMENT_META.APPLICATION },
    CITIZEN_ID_CONSENT_INSURED: { ...DOCUMENT_META.CITIZEN_ID_CONSENT_INSURED },
    PASSPORT_CONSENT_INSURED: { ...DOCUMENT_META.PASSPORT_CONSENT_INSURED },
    BI: { ...DOCUMENT_META.BI },
    ADDENDUM: { ...DOCUMENT_META.ADDENDUM },
    E_POLICY_CONSENT: { ...DOCUMENT_META.E_POLICY_CONSENT },
    ADDITIONAL_DOCUMENT: {
      name: 'additional-doc',
      text: 'เอกสารอื่นๆ เพิ่มเติม',
    },
    CREDIT_CARD_RELATIONSHIP_DOCUMENT: {
      name: 'credit-card-relationship-doc',
      text: 'แนบเอกสารพิสูจน์ความสัมพันธ์ผู้ชำระเบี้ย',
    },
    KYC: { ...DOCUMENT_META.KYC },
    RPQ: { ...DOCUMENT_META.RPQ },
    RA: { ...DOCUMENT_META.RA },
    PENSION_RECEIVE: { ...DOCUMENT_META.PENSION_RECEIVE },
    JUVENILE_PURCHASE: {
      name: 'family-purchasing',
      text: 'แบบสอบถามเพื่อการซื้อประกันสุขภาพผู้เยาว์',
    },
    FOREIGNER: {
      name: 'foreigner',
      text: 'แบบสอบถามชาวต่างชาติ',
    },
    PRIVACY_CONSENT: { ...DOCUMENT_META.PRIVACY_CONSENT },
    FNA: { ...DOCUMENT_META.FNA },

    // CapturePhoto
    EDC: {
      name: 'edc',
      text: 'ใบยืนยันสลิป EDC',
    },
    BANK_BOOK: {
      name: 'bank-book',
      text: 'หน้าสมุดเงินฝาก',
      infoMessage: 'กรุณานำส่งเอกสารยินยอมการหักผ่านบัญชีพร้อมหน้าบัญชีธนาคารฉบับจริงมายังบริษัทด้วยทุกครั้ง',
      infoMessageNewDA: `<b>กรุณานำส่งหนังสือยินยอมหักค่าเบี้ยประกันผ่านบัญชีธนาคารฉบับจริงพร้อมลงลายมือชื่อเซ็นสดเข้ามาที่บริษัทด้วยทุกครั้ง</b> หลังจากทางบริษัทฯ ได้รับเอกสารฉบับจริงจะดำเนินการส่งไปที่ธนาคารเพื่อตรวจสอบลายเซ็น <b>ระยะเวลาในการตรวจสอบลายเซ็นอย่างน้อย 14 วันทำการ</b> นับจากวันที่ธนาคารได้รับเอกสาร จึงจะสามารถดำนินการหักค่าเบี้ยประกันอัตโนมัติในงวดถัดไปได้`,
      infoMessageEDA:
        'กรุณาตรวจสอบว่าท่านมีแอปพลิเคชั่นของทางธนาคารที่ต้องการตัดบัญชีธนาคารอัตโนมัติออนไลน์หรือไม่ หากยังไม่มีกรุณาติดตั้งพร้อมลงทะเบียนให้เรียบร้อยก่อนทำรายการ',
    },
    DCA_BANK_BOOK: {
      name: 'dca-bank-book',
      text: 'หน้าสมุดเงินฝาก',
    },
    DCA: {
      name: 'dca',
      text: 'หนังสือยินยอมขอรับผลประโยชน์ตามกรมธรรม์ผ่านบัญชีธนาคาร',
    },
    BANK_RECEIPT: {
      name: 'bank-slip',
      text: 'ใบนำฝากเงินจากธนาคาร',
    },
    CITIZEN_ID: {
      name: 'insured-id-card',
      text: 'บัตรประจำตัวประชาชนพร้อมลายเซ็น',
    },
    CITIZEN_ID_CONSENT: {
      name: 'citizen-id-consent',
      text: 'รับรองบัตรประชาชน',
    },
    SELFIE: {
      name: 'insured-selfie',
      text: 'รูปภาพเซลฟี่',
    },
    SELFIE_NEW: {
      name: 'insured-selfie',
      text: 'รูปเซลฟี่พร้อมถือบัตรประชาชน/หนังสือเดินทาง (ในกรณีชาวต่างชาติ)',
    },
    OFFICIAL_DOC: {
      name: 'insured-official-document',
      text: 'เอกสารทางราชการ เช่น ใบขับขี่ ทะเบียนบ้าน บัตรประจำตัวข้าราชการ หรือ หนังสือเดินทาง',
    },
    GOV_ID: {
      name: 'insured-house-registration',
      text: 'ทะเบียนบ้านพร้อมลายเซ็น',
    },
    PASSPORT: {
      name: 'insured-passport',
      text: 'หนังสือเดินทางพร้อมลายเซ็น',
    },
    FATCA: {
      // name: 'insured-fatca',
      // text: 'หนังสือยินยอมการให้ข้อมูลตามกฎหมาย FATCA',
      ...DOCUMENT_META.FATCA,
    },
    CRS: {
      ...DOCUMENT_META.CRS,
    },
    // RELATIONSHIP: {
    //   name: 'insured-relationship',
    //   text: 'เอกสารพิสูจน์ความสัมพันธ์',
    // },
    DA_RELATIONSHIP: { ...DOCUMENT_META.DA_RELATIONSHIP },
  },
  payer: {
    ATP: {
      name: 'atp',
      text: 'หนังสือยินยอมหักค่าเบี้ยประกันอัตโนมัติ',
    },
    BI: { ...DOCUMENT_META.BI },
    E_POLICY_CONSENT: { ...DOCUMENT_META.E_POLICY_CONSENT },
    APPLICATION: { ...DOCUMENT_META.APPLICATION },
    CITIZEN_ID_CONSENT_PAYER: { ...DOCUMENT_META.CITIZEN_ID_CONSENT_PAYER },
    PASSPORT_CONSENT_PAYER: { ...DOCUMENT_META.PASSPORT_CONSENT_PAYER },
    ADDENDUM: { ...DOCUMENT_META.ADDENDUM },
    KYC: { ...DOCUMENT_META.KYC },
    RPQ: { ...DOCUMENT_META.RPQ },
    RA: { ...DOCUMENT_META.RA },
    CITIZEN_ID: {
      name: 'owner-id-card',
      text: 'บัตรประจำตัวประชาชนพร้อมลายเซ็น',
    },
    CITIZEN_ID_CONSENT: {
      name: 'citizen-id-consent',
      text: 'รับรองบัตรประชาชน',
    },
    SELFIE: {
      name: 'owner-selfie',
      text: 'รูปภาพเซลฟี่',
    },
    SELFIE_NEW: {
      name: 'owner-selfie',
      text: 'รูปเซลฟี่พร้อมถือบัตรประชาชน/หนังสือเดินทาง (ในกรณีชาวต่างชาติ)',
    },
    OFFICIAL_DOC: {
      name: 'owner-official-document',
      text: 'เอกสารทางราชการ เช่น ใบขับขี่ ทะเบียนบ้าน บัตรประจำตัวข้าราชการ หรือ หนังสือเดินทาง',
    },
    FNA: { ...DOCUMENT_META.FNA },

    GOV_ID: {
      name: 'owner-house-registration',
      text: 'ทะเบียนบ้านพร้อมลายเซ็น',
    },
    PASSPORT: {
      name: 'owner-passport',
      text: 'หนังสือเดินทางพร้อมลายเซ็น',
    },
    PRIVACY_CONSENT: { ...DOCUMENT_META.PRIVACY_CONSENT },
    JUVENILE_PURCHASE: {
      name: 'family-purchasing',
      text: 'แบบสอบถามเพื่อการซื้อประกันสุขภาพผู้เยาว์',
    },
    CRS: {
      ...DOCUMENT_META.CRS,
    },
  },
  banc: {
    SUITABILITY_FORM: {
      name: 'suitability-form',
      text: 'แบบสอบถามความต้องการซื้อผลิตภัณฑ์ประกันชีวิตผ่านธนาคาร',
    },
  },
  wealthHub: {
    BI: {
      name: 'bi',
      text: 'เอกสารประกอบการเสนอขาย',
    },
  },
  thirdPerson: {
    FNA: { ...DOCUMENT_META.FNA },
  },
}

export const APP_STATUS = {
  DRAFTED: 'drafted',
  SIGNED: 'signed',
  SUBMITTED: 'submitted',
  SUBMITTING: 'submitting',
  FAILED: 'failed',
}

export const REPLICATION_STATUS = {
  ACTIVE: 'active',
  OFFLINE: 'offline',
  IDLE: 'idle',
}

export const EDA_CHECK_STATUS_FAIL = {
  TOKEN_EXPIRED: 'expired',
  ERROR: 'error',
}

export const PAYMENT_TYPE = {
  CASH: 'cash',
  EDC: 'edc',
  CREDITCARD: 'credit card',
  QUICKPAY: 'quickpay',
  NETBANK: 'net bank',
}

export const FUND_ALLOCATION = {
  LSTU: {
    ALLOCATION_OPTIONS: {
      SAME_AS_RPP: 'same_as_rpp',
      NOT_SAME_AS_RPP: 'not_same_as_rpp',
    },
  },
}

export const UPLOAD_DOC_STATUS = {
  UPLOADING: 'uploading',
  COMPLETED: 'completed',
}

/**
 * The filterred relationship value to be displayed as options in relationship dropdown
 * See more: e-submission/mocks/relationship.json
 */
export const RELATIONSHIP_LIST_VALUES = {
  NEW_ATP: ['SELF', 'PAYER', 'OTHERS'],
  ATP: ['SELF', 'PARENT', 'SPOUSE', 'CHILD', 'OTHERS'],
  CCPA: ['SELF', 'PARENT', 'SPOUSE'],
  FAMILY: ['SPOUSE', 'PARENT', 'CHILD', 'GRANDPARE', 'BROTHER', 'SISTER'],
  PAYER: ['COUSIN', 'EMPLOYER', 'GUARDIAN', 'OTHERS', 'PARENT'],
  ECBR: ['SPOUSE', 'CHILD', 'OTHERS'],
}

export const CREDIT_CARD_RELATIONSHIP_LIST = {
  SELF: 'Self',
  TIER1: 'Tier1',
}

export const CREDIT_CARD_PAYMENT_METHOD = {
  ON_AZ: 'cybersource',
  QUICKLINK: 'quickpaylink',
}

export const ECBR_PAYER_TYPE = {
  INSURED: 'insured',
  PAYER: 'payer',
  OTHER: 'other',
}

export const ECBR_PAYER_DOCTYPE = [
  {
    value: 'CITIZEN_ID',
    text: 'บัตรประจำตัวประชาชน',
  },
  {
    value: 'PASSPORT',
    text: 'หนังสือเดินทาง',
  },
]

export const POLICY_DELIVERY_BY_EMAIL = {
  HAVE: {
    text: 'มีความประสงค์',
    value: true,
  },
  NOT_HAVE: {
    text: 'ไม่มีความประสงค์',
    value: false,
  },
}

export const titleEkyc = {
  boy: {
    key: '001',
    code: '001',
    sex: 'M',
    translations: {
      en: {
        label: 'Mr. (juvenile)',
      },
      th: {
        label: 'ด.ช.',
      },
      id: {
        label: 'Tuan',
      },
    },
  },
  girl: {
    key: '002',
    code: '002',
    sex: 'F',
    translations: {
      en: {
        label: 'Ms. (juvenile)',
      },
      th: {
        label: 'ด.ญ.',
      },
      id: {
        label: 'Nona',
      },
    },
  },
  miss: {
    key: '003',
    code: '003',
    sex: 'F',
    translations: {
      en: {
        label: 'Ms.',
      },
      th: {
        label: 'น.ส.',
      },
      id: {
        label: 'Nona',
      },
    },
  },
  mrs: {
    key: '004',
    code: '004',
    sex: 'F',
    translations: {
      en: {
        label: 'Mrs.',
      },
      th: {
        label: 'นาง',
      },
      id: {
        label: 'Nyonya',
      },
    },
  },
  mr: {
    key: '005',
    code: '005',
    sex: 'M',
    translations: {
      en: {
        label: 'Mr.',
      },
      th: {
        label: 'นาย',
      },
      id: {
        label: 'Tuan',
      },
    },
  },
  other: {
    key: '006',
    code: '006',
    sex: '',
    translations: {
      en: {
        label: 'Mr./Ms./Mrs.',
      },
      th: {
        label: 'คุณ',
      },
      id: {
        label: 'Tuan/Nona/Nyonya',
      },
    },
  },
}

export const bankBeneficiary = {
  allocation: '100',
  beneficiaryAddress: {
    amphur: {
      province_code: '',
      text: '',
      value: '',
    },
    numberMoobanArkan: '',
    postCode: '',
    province: {
      text: '',
      value: '',
    },
    tambol: {
      amphur_code: '',
      postal: [],
      province_code: '',
      text: '',
      value: '',
    },
  },
  birthdate: '',
  firstName: 'ธนาคารกรุงไทย',
  lastName: 'จำกัด (มหาชน)',
  relations: {
    text: 'อื่นๆ',
    value: 'OTHERS',
  },
  sex: {
    text: '',
    value: '',
  },
  title: {
    gender: '',
    text: '',
    value: '',
  },
}
