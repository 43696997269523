// @flow

import { connect } from 'react-redux'
import type { Dispatch } from 'redux'
import { CoveragePlan } from './components'
import type { AppState } from 'quick-quote/reducers'
import { hasProductSelected } from 'quick-quote/selectors/selected-display-product'

const mapStateToProps = (state: AppState) => ({
  hasProductSelected: hasProductSelected(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({})

export const CoveragePlanContainer = connect(mapStateToProps, mapDispatchToProps)(CoveragePlan)
