import moment from 'moment'
import {
  isEmpty,
  trim,
  every,
  first,
  flow,
  get,
  isEqual,
  isInteger,
  isMatch,
  isNil,
  last,
  map,
  negate,
  overEvery,
  overSome,
  pick,
  props,
  set,
  size,
  some,
  take,
  toFinite,
  toNumber,
  values,
  includes,
  concat,
  compact,
} from 'lodash/fp'
import VALUES from 'core/data-model/constants/values'
import { createChecker } from 'core/service/lib/check'

import { isCordova } from 'e-submission/utils'
import { createValidation, createValidator } from 'e-submission/utils/validator'
import {
  isInteger as isDigit,
  hasActiveLicense,
  hasActiveOrInapplicableLicense,
  isBetweenEqual,
  isDecimal,
  isEmail,
  isFutureDate,
  isIDCardNo,
  isLengthEqualTo,
  isLengthLessThanEqual,
  isMoreThan,
  isNotEmpty,
  isPersonName,
  isPersonNameEN,
  isTextAndNumberEN,
  isPassport,
} from 'e-submission/utils/pattern'
import {
  isEqualBeneficiaryAllocation,
  isExceedBeneficiaryAllocation,
  isInsuredAndPayerCitizenIdEqual,
  isInsuredAndPayerPassportEqual,
  isJuvenile,
  isMinFirstTotalPayment,
  getQuickQuoteProductCategory,
  isAdditionalDocument,
  getQuickQuoteTotalPremium,
  isMaxFirstTotalPayment,
  isQuickQuoteModelFactorMonthly,
  getMaximumPaidPremium,
  getMinimumPaidPremium,
  isValidateTaxId,
  isInsuredIdCardZeroByte,
  isPayerIdCardZeroByte,
  isBancSuitablityFormZeroByte,
  getCertifyByAgent,
  getQuickQuoteSelectedProductLicenses,
  getSelectedRiders,
  getQuickQuoteProductCode,
} from './selectors'
import { getRiderLicenses } from './utils'
import { PAYMENT_TYPE } from 'e-submission/domain/data-model/constants'
import { formatNumber } from 'core/service/lib/number-format'
import { getToggles } from 'quick-quote/feature-toggles'

const isUploaded = every(['checked', true])

const getUploadError = (value, { state }) => {
  const pickIn = (obj) => (keys) => pick(keys, obj)
  return flow(map('name'), pickIn(state.ui.errors), values, first)(value)
}

const hasUploadError = flow(getUploadError, negate(isNil))

const getReceiptKey = (fieldId) => {
  const key = fieldId.match(/^receipts\[[0-9]+\]/)
  return key ? key[0] : 'receipts[0]'
}

export const validateSendLinkInputPopup: Checker<{ textInput: string }, string> = createChecker([
  [
    'emptyValue',
    {
      type: 'VALIDATION',
      rule: ({ textInput }) => isNotEmpty(textInput),
      message: 'กรุณากรอกข้อมูล',
    },
  ],
  [
    'invalidInput',
    {
      type: 'VALIDATION',
      rule: ({ textInput }, method) => {
        if (textInput) return method === 'email' ? isEmail(textInput) : isLengthEqualTo(10)(textInput)
        return true
      },
      message: ({}, method) =>
        method === 'email' ? 'รูปแบบอีเมลไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง' : 'กรุณากรอกข้อมูลให้ถูกต้อง',
    },
  ],
])

export default createValidator({
  '.': createValidation({
    requiredMessages: [
      {
        rule: isNotEmpty,
        message: (value, { id, app }) => {
          if (getToggles().ENABLE_EKYC_F2F && id === 'validationEKYC.payer.isRequired') {
            return 'กรุณายืนยันตัวตนของผู้ชำระเบี้ย'
          } else if (getToggles().ENABLE_EKYC_F2F && id === 'validationEKYC.insured.isRequired') {
            return 'กรุณายืนยันตัวตนของผู้เอาประกันภัย'
          } else {
            return 'กรุณากรอกข้อมูล'
          }
        },
      },
    ],
  }),
  '(id-card|document|selfie|official-document|additionalDocument.add|Document|bankReceipt)$': createValidation({
    errorMessages: [
      {
        rule: negate(hasUploadError),
        message: (value, appState) => {
          const fileErrorType = getUploadError(value, appState)

          if (fileErrorType === 'INVALID_IMAGE_FILE') {
            return 'กรุณาแนบไฟล์รูปที่รองรับ เช่น .jpg หรือ .png หากท่านใช้ iPhone หรือ iPad ในการถ่ายรูป กรุณาตั้งค่าที่ การตั้งค่า (Setting) > กล้อง (Camera) > รูปแบบ (Format) และเลือก เข้ากันได้มากที่สุด (Most Compatible) และนำมาถ่ายรูปใหม่อีกครั้ง'
          }

          if (fileErrorType === 'FILE_SIZE_OVER_LIMIT') {
            return isCordova ? 'กรุณาลดขนาดรูปและลองถ่ายใหม่' : 'ขนาดรูปใหญ่เกินไป'
          } else {
            return isCordova ? 'กรุณาถ่ายรูปใหม่' : 'กรุณาแนบรูปใหม่'
          }
        },
      },
    ],
    requiredMessages: [
      {
        rule: overSome([
          isUploaded,
          hasUploadError,
          (value) => {
            const name = (value[0] && value[0].name) || ''
            return isAdditionalDocument(name)
          },
        ]),
        message: isCordova ? 'กรุณาถ่ายรูป' : 'กรุณาแนบรูป',
      },
    ],
  }),
  'payer.document$': createValidation({
    errorMessages: [
      {
        rule: (value, { app }) => !isPayerIdCardZeroByte(app),
        message: (value, appState) => {
          return 'เอกสาร ผู้ชำระเบี้ย สูญหาย กรุณากดปุ่ม "ดู" แล้วทำการลบ เพื่ออับโหลดเอกสารอีกครั้ง'
        },
      },
    ],
    requiredMessages: [
      {
        rule: (value, { app }) => !isPayerIdCardZeroByte(app),
        message: 'เอกสาร ผู้ชำระเบี้ย สูญหาย กรุณากดปุ่ม "ดู" แล้วทำการลบ เพื่ออับโหลดเอกสารอีกครั้ง',
      },
    ],
  }),
  'insured.document$': createValidation({
    errorMessages: [
      {
        rule: (value, { app }) => !isInsuredIdCardZeroByte(app),
        message: (value, appState) => {
          return 'เอกสาร ผู้เอาประกันภัย สูญหาย กรุณากดปุ่ม "ดู" แล้วทำการลบ เพื่ออับโหลดเอกสารอีกครั้ง'
        },
      },
    ],
    requiredMessages: [
      {
        rule: (value, { app }) => !isInsuredIdCardZeroByte(app),
        message: 'เอกสาร ผู้เอาประกันภัย สูญหาย กรุณากดปุ่ม "ดู" แล้วทำการลบ เพื่ออับโหลดเอกสารอีกครั้ง',
      },
    ],
  }),
  'certifyByAgent.isCertify': createValidation({
    errorMessages: [
      {
        rule: (value, { app }) => (getToggles().ENABLE_TAMLO ? getCertifyByAgent(app) : false),
        message: (value, appState) => {
          return 'กรุณาทำเครื่องหมายในช่องการยืนยันการระบุและพิสูจน์ทราบตัวตนของลูกค้า'
        },
      },
    ],
    requiredMessages: [
      {
        rule: (value, { app }) => (getToggles().ENABLE_TAMLO ? getCertifyByAgent(app) : false),
        message: 'กรุณาทำเครื่องหมายในช่องการยืนยันการระบุและพิสูจน์ทราบตัวตนของลูกค้า',
      },
    ],
  }),
  'banc.document$': createValidation({
    errorMessages: [
      {
        rule: (value, { app }) => !isBancSuitablityFormZeroByte(app),
        message: (value, appState) => {
          return 'เอกสาร แบบสอบถามความต้องการซื้อผลิตภัณฑ์ประกันชีวิตผ่านธนาคาร สูญหาย กรุณากดปุ่ม "ดู" แล้วทำการลบ เพื่ออับโหลดเอกสารอีกครั้ง'
        },
      },
    ],
    requiredMessages: [
      {
        rule: (value, { app }) => !isBancSuitablityFormZeroByte(app),
        message:
          'เอกสาร แบบสอบถามความต้องการซื้อผลิตภัณฑ์ประกันชีวิตผ่านธนาคาร สูญหาย กรุณากดปุ่ม "ดู" แล้วทำการลบ เพื่ออับโหลดเอกสารอีกครั้ง',
      },
    ],
  }),
  duration$: createValidation({
    requiredMessages: [
      {
        rule: flow(props(['value', 'unit']), every(isNotEmpty)),
        message: 'กรุณากรอกข้อมูล',
      },
    ],
  }),
  stopSmoking$: createValidation({
    requiredMessages: [
      {
        rule: flow(props(['month', 'year']), overEvery([every(isNotEmpty), flow(last, size, isEqual(4))])),
        message: 'กรุณากรอกข้อมูล',
      },
    ],
  }),
  email$: createValidation({
    errorMessages: [
      {
        rule: isEmail,
        message: 'รูปแบบอีเมลไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง',
      },
    ],
    allow: overEvery([isLengthLessThanEqual(50)]),
  }),
  recommender$: createValidation({
    errorMessages: [
      {
        rule: (value, { app }) => {
          const category = getQuickQuoteProductCategory(app)
          const shareCaseToggleOn = getToggles().ENABLE_SHARED_AGENT_SALE_VALIDATION

          if (shareCaseToggleOn) {
            const productLicenses = getQuickQuoteSelectedProductLicenses(app)
            const selectedRiders = getSelectedRiders(app)
            const selectedRiderLicense = getRiderLicenses(selectedRiders)

            const allRequiredLicences = compact(concat(productLicenses, selectedRiderLicense))
            const allRequiredLicencesCheckList = allRequiredLicences.map((licenseCode) => {
              if (licenseCode === 'IC REFRESHER') {
                return hasActiveOrInapplicableLicense(licenseCode)
              } else {
                return hasActiveLicense(licenseCode)
              }
            })

            return flow(get('registrations'), overEvery(allRequiredLicencesCheckList))(value)
          } else {
            if (category === VALUES.INVESTMENT) {
              return flow(
                get('registrations'),
                overEvery([hasActiveLicense('BASIC'), hasActiveLicense('SINGLE LIC'), hasActiveLicense('OIC REG')])
              )(value)
            } else {
              return true
            }
          }
        },
        message: (value, { app }) => {
          const shareCaseToggleOn = getToggles().ENABLE_SHARED_AGENT_SALE_VALIDATION
          if (shareCaseToggleOn) {
            const category = getQuickQuoteProductCategory(app)
            const productCode = getQuickQuoteProductCode(app)
            const selectedRiders = getSelectedRiders(app) || []
            const licenseRegistrations = get('registrations')(value)

            if (category === VALUES.INVESTMENT) {
              const investmentLicenseErrorMsg = 'กรุณาระบุตัวแทนที่มีใบอนุญาตในการเสนอขายผลิตภัณฑ์เพื่อการลงทุน'
              if (!hasActiveLicense('SINGLE LIC')(licenseRegistrations)) {
                return investmentLicenseErrorMsg
              } else if (!hasActiveLicense('OIC REG')(licenseRegistrations)) {
                return investmentLicenseErrorMsg
              } else if (!hasActiveLicense('ILP RIGHT')(licenseRegistrations)) {
                return investmentLicenseErrorMsg
              } else if (productCode === 'RPUDR' && !hasActiveLicense('UDR RIGHT')(licenseRegistrations)) {
                return 'กรุณาระบุตัวแทนที่มีใบอนุญาตในการเสนอขายผลิตภัณฑ์ยูนิตลิงค์'
              } else if (!hasActiveOrInapplicableLicense('IC REFRESHER')(licenseRegistrations)) {
                return investmentLicenseErrorMsg
              }
            }

            const haveRiders = !isEmpty(selectedRiders)
            const s7ccProductCodeList = ['CIWLS39', 'CIWLS50', 'CIWLS65', 'CIWLM39', 'CIWLM50', 'CIWLM60']
            if (haveRiders) {
              const selectedRiderLicense = getRiderLicenses(selectedRiders) || []
              if (
                includes('IHEALTHY RIGHT', selectedRiderLicense) &&
                !hasActiveLicense('IHEALTHY RIGHT')(licenseRegistrations)
              ) {
                return 'กรุณาระบุตัวแทนที่ได้รับสิทธิให้เสนอขายผลิตภัณฑ์ ไอเฮลท์ตี้ อัลตร้า'
              } else if (
                includes('MEX RIGHT', selectedRiderLicense) &&
                !hasActiveLicense('MEX RIGHT')(licenseRegistrations)
              ) {
                return 'กรุณาระบุตัวแทนที่ได้รับสิทธิให้เสนอขายผลิตภัณฑ์ ค่ารักษาพยาบาล เอ็กซ์ตร้า'
              } else if (
                productCode === 'RPUDR' &&
                includes('UDR HEALTH', selectedRiderLicense) &&
                !hasActiveLicense('UDR HEALTH')(licenseRegistrations)
              ) {
                return 'กรุณาระบุตัวแทนที่ได้รับสิทธิให้เสนอขายผลิตภัณฑ์ สัญญาเพิ่มเติมเฮลท์ลิงค์, แคชลิงค์'
              }
            } else if (
              includes(productCode, s7ccProductCodeList) &&
              !hasActiveLicense('[CI] S7CC')(licenseRegistrations)
            ) {
              // check selected product is in s7cc group but license is inactived
              // ps. s7cc dont have riders
              return 'กรุณาระบุตัวแทนที่ได้รับสิทธิให้เสนอขายผลิตภัณฑ์ ซุปเปอร์ เซเว่น คริติคอลแคร์'
            }
          }

          return `กรุณาเลือกตัวแทนที่มีใบอนุญาตให้บริการขายผลิตภัณฑ์เพื่อการลงทุน`
        },
      },
    ],
  }),
  OccupationIncomePerMonth$: createValidation({
    allow: overEvery([isLengthLessThanEqual(8)]),
  }),
  'title.otherText$': createValidation({
    allow: overEvery([isLengthLessThanEqual(10)]),
  }),
  'title.text$': createValidation({
    allow: overEvery([isLengthLessThanEqual(50)]),
  }),
  'titleEN.text$': createValidation({
    allow: overEvery([isLengthLessThanEqual(9), isPersonNameEN]),
  }),
  firstName$: createValidation({
    allow: overEvery([isLengthLessThanEqual(20), isPersonName]),
  }),
  firstNameEN$: createValidation({
    allow: overEvery([isLengthLessThanEqual(20), isPersonNameEN]),
  }),
  lastName$: createValidation({
    allow: overEvery([isLengthLessThanEqual(30), isPersonName]),
  }),
  lastNameEN$: createValidation({
    allow: overEvery([isLengthLessThanEqual(50), isPersonNameEN]),
  }),
  'tax.info.TIN$': createValidation({
    allow: overEvery([isLengthLessThanEqual(25), isTextAndNumberEN]),
  }),
  'tax.info.NoTIN.reason.detail$': createValidation({
    allow: overEvery([isLengthLessThanEqual(130)]),
  }),
  placeOfBirthCity: createValidation({
    allow: overEvery([isLengthLessThanEqual(50)]),
  }),
  mobileNumber$: createValidation({
    allow: isLengthLessThanEqual(10),
    errorMessages: [
      {
        rule: flow(size, isEqual(10)),
        message: 'กรุณากรอกข้อมูลให้ถูกต้อง',
      },
    ],
  }),
  'motorcycleUsage.otherText$': createValidation({
    allow: overEvery([isLengthLessThanEqual(70)]),
  }),
  sms$: createValidation({
    allow: isLengthLessThanEqual(10),
    errorMessages: [
      {
        rule: flow(size, isEqual(10)),
        message: 'กรุณากรอกข้อมูลให้ถูกต้อง',
      },
    ],
  }),
  telephoneNumber$: createValidation({
    allow: isLengthLessThanEqual(15),
    errorMessages: [
      {
        rule: flow(size, isBetweenEqual(9, 15)),
        message: 'กรุณากรอกข้อมูลให้ถูกต้อง',
      },
    ],
  }),
  'idNo.taxId$': createValidation({
    errorMessages: [
      {
        rule: (value, { app }) => isValidateTaxId(app),
        message: 'กรุณากรอกข้อมูลให้ถูกต้อง',
      },
    ],
    allow: overEvery([isLengthLessThanEqual(13)]),
  }),
  'idNo.citizenId$': createValidation({
    errorMessages: [
      {
        rule: isIDCardNo,
        message: 'กรุณากรอกข้อมูลให้ถูกต้อง',
      },
      {
        rule: (value, { app }) => !isInsuredAndPayerCitizenIdEqual(app),
        message: 'เลขประจำตัวประชาชน ของผู้เอาประกันภัยและผู้ชำระเบี้ยซ้ำกัน',
      },
    ],
    allow: overEvery([isLengthLessThanEqual(13)]),
  }),
  'idNo.govId$': createValidation({
    allow: overEvery([isLengthLessThanEqual(20)]),
  }),
  'idNo.passport$': createValidation({
    errorMessages: [
      {
        rule: (value, { app }) => !isInsuredAndPayerPassportEqual(app),
        message: 'หมายเลขหนังสือเดินทาง ของผู้เอาประกันภัยและผู้ชำระเบี้ยซ้ำกัน',
      },
    ],
    allow: overEvery([isLengthLessThanEqual(13), isPassport]),
  }),
  'numberMoobanArkan[0-9]?$': createValidation({
    allow: overEvery([isLengthLessThanEqual(30)]),
  }),
  'postCode.value': createValidation({
    allow: overEvery([isLengthLessThanEqual(5)]),
  }),
  officeName$: createValidation({
    allow: overEvery([isLengthLessThanEqual(60)]),
  }),
  companyName$: createValidation({
    allow: overEvery([isLengthLessThanEqual(30)]),
  }),
  policyNumber$: createValidation({
    allow: overEvery([isLengthLessThanEqual(15)]),
  }),
  sumAssured$: createValidation({
    allow: overEvery([isLengthLessThanEqual(15)]),
  }),
  height$: createValidation({
    allow: overEvery([isLengthLessThanEqual(3)]),
    errorMessages: [
      {
        rule: (value) => isMoreThan(value, 300),
        message: 'ส่วนสูงไม่ควรเกิน 300 เซนติเมตร',
      },
    ],
  }),
  'relationshipToInsured.otherText$': createValidation({
    allow: overEvery([isLengthLessThanEqual(20)]),
  }),
  'relations.others$': createValidation({
    allow: overEvery([isLengthLessThanEqual(50)]),
  }),
  otherRelationRemark$: createValidation({
    allow: overEvery([isLengthLessThanEqual(50)]),
  }),
  allocation$: createValidation({
    allow: overEvery([isLengthLessThanEqual(3)]),
    errorMessages: [
      {
        rule: (value, { app }) =>
          every(isEqual(true))([isExceedBeneficiaryAllocation(app), flow(toFinite, negate(isEqual(0)))(value)]),
        message: 'กรุณากรอกร้อยละผลประโยชน์ใหม่',
      },
      {
        rule: (value, { app }) => isEqualBeneficiaryAllocation(app),
        message: 'กรุณากรอกร้อยละผลประโยชน์ให้ครบร้อยละ 100',
      },
    ],
  }),
  weight$: createValidation({
    allow: overEvery([isLengthLessThanEqual(3)]),
    errorMessages: [
      {
        rule: (value) => isMoreThan(value, 300),
        message: 'น้ำหนักไม่ควรเกิน 300 กิโลกรัม',
      },
    ],
  }),
  'weightChangedRecently.value$': createValidation({
    allow: overEvery([isLengthLessThanEqual(2)]),
  }),
  'weightChangedRecently.reason$': createValidation({
    allow: overEvery([isLengthLessThanEqual(100)]),
  }),
  'weightChangedRecently.reason30Chars$': createValidation({
    allow: overEvery([isLengthLessThanEqual(30)]),
  }),
  totalFirstPremiumPayment$: createValidation({
    errorMessages: [
      {
        rule: (value, { id, app }) => {
          const key = getReceiptKey(id)
          const receiptType = get(`${key}.type`, app)

          if (receiptType === PAYMENT_TYPE.CASH) {
            return isDecimal(value)
          }

          return true
        },
        message: 'ยอดรวมต้องไม่มีจุดทศนิยม',
      },
      {
        rule: (value, { id, app }) => {
          if (value === 0) {
            return true
          }
          const key = getReceiptKey(id)
          const receiptType = get(`${key}.type`, app)

          if (
            receiptType === PAYMENT_TYPE.CASH ||
            receiptType === PAYMENT_TYPE.EDC ||
            receiptType === PAYMENT_TYPE.CREDITCARD
          ) {
            return isMinFirstTotalPayment(app, value)
          }
          return true
        },
        message: (value, { app }) => {
          const totalPremium = getQuickQuoteTotalPremium(app)
          const roundDownTotalPremium = getMinimumPaidPremium(totalPremium)

          return `ยอดชำระรวมต้องไม่ต่ำกว่า ${formatNumber(roundDownTotalPremium)} บาท`
        },
      },
      {
        rule: (value, { app }) => isMaxFirstTotalPayment(app, value),
        message: (value, { app }) => {
          const totalPremium = getQuickQuoteTotalPremium(app)
          const isMonthlyPayment = isQuickQuoteModelFactorMonthly(app)

          const maximumTotalPayment = getMaximumPaidPremium(totalPremium, isMonthlyPayment)
          return `ยอดชำระรวมต้องไม่เกิน ${formatNumber(maximumTotalPayment)} บาท`
        },
      },
    ],
  }),
  '^(?!expiry).*(date|Date)$': createValidation({
    errorMessages: [
      {
        rule: isFutureDate,
        message: 'กรุณากรอกข้อมูลให้ถูกต้อง',
      },
    ],
  }),
  bankBookNumber$: createValidation({
    errorMessages: [
      {
        rule: flow(size, isEqual(10)),
        message: 'กรุณากรอกข้อมูลให้ถูกต้อง',
      },
    ],
  }),
  accountNumber$: createValidation({
    errorMessages: [
      {
        rule: flow(size, isEqual(10)),
        message: 'กรุณากรอกข้อมูลให้ถูกต้อง',
      },
    ],
  }),
  'recommender.advisorCode$': createValidation({
    allow: overEvery([isLengthLessThanEqual(6)]),
  }),
  '(insured|payer).typeOfBusiness': createValidation({
    allow: overEvery([isLengthLessThanEqual(50)]),
  }),
  'otherInsuranceCompany.detail$': createValidation({
    allow: overEvery([isLengthLessThanEqual(250)]),
  }),
  'wasRejected.detail$': createValidation({
    allow: overEvery([isLengthLessThanEqual(100)]),
  }),
  'detail[[0-9]+].disease$': createValidation({
    allow: overEvery([isLengthLessThanEqual(250)]),
  }),
  '^beneficiary[[0-9]+].(firstName|lastName)': createValidation({
    errorMessages: [
      {
        rule: (value, { id, app }) => {
          let beneficiary = get('beneficiary', app)
          if (isJuvenile(app)) {
            const payer = get('payer', app)
            beneficiary = set([0, 'firstName'], get('firstName', payer), beneficiary)
            beneficiary = set([0, 'lastName'], get('lastName', payer), beneficiary)
          }

          const index = toNumber(id.match(/[0-9]/))
          if (isInteger(index)) {
            const isSameName = isMatch({
              firstName: get([index, 'firstName'], beneficiary),
              lastName: get([index, 'lastName'], beneficiary),
            })
            return negate(flow(take(index), some(isSameName)))(beneficiary)
          }
          return true
        },
        message: 'ผู้รับประโยชน์ซ้ำกัน',
      },
    ],
  }),
  approvalCode$: createValidation({
    allow: isLengthLessThanEqual(6),
    requiredMessages: [
      {
        rule: isLengthEqualTo(6),
        message: 'กรุณากรอกข้อมูลให้ถูกต้อง',
      },
    ],
  }),
  creditCardNumber$: createValidation({
    errorMessages: [
      {
        rule: isLengthEqualTo(16),
        message: 'หมายเลขบัตรไม่ถูกต้อง',
      },
    ],
  }),
  nameOnCard$: createValidation({
    errorMessages: [
      {
        rule: flow(trim, overEvery([negate(isEmpty), (value) => /^[^0-9\/]*$/gi.test(value)])),
        message: 'กรุณากรอกชื่อ-สกุลด้วย ก-ฮ / a-z เท่านั้น',
      },
    ],
  }),
  expiryDate$: createValidation({
    errorMessages: [
      {
        rule: overEvery([flow((date) => moment().isSameOrBefore(moment(date, 'MM/YY'), 'month')), isLengthEqualTo(5)]),
        message: 'บัตรของคุณหมดอายุ',
      },
    ],
  }),
  securityCode$: createValidation({
    allow: overEvery([isDigit, isLengthLessThanEqual(3)]),
    errorMessages: [
      {
        rule: isLengthEqualTo(3),
        message: 'หมายเลข CVV ไม่ถูกต้อง',
      },
    ],
  }),
  insurancePrivacyConsent$: createValidation({
    errorMessages: [
      {
        rule: (value) => get('value', value) === true,
        message: 'กรุณาให้ความยินยอมก่อนทำการสมัครเพื่อขอเอาประกันภัย',
      },
    ],
  }),
})
