import styled from 'styled-components'
import { Flex } from 'rebass'
import { FormFeedback } from 'reactstrap'
import { connect } from 'react-redux'
import { get, getOr } from 'lodash/fp'
import { getCurrentApp, getGA4ccpaSuccess } from 'e-submission/apps/selectors'
import { creditCardSuffix } from 'e-submission/sagas/utils'
import { compose } from 'recompose'

import Card from 'common-components/card'
import Button from 'e-submission/components/Button'
import { getIcon } from 'e-submission/components/Icon'
import { verifyCCPA, resendLinkCCPA, changeCCPAMethod } from 'e-submission/apps/actions'
import { isCCPAStatusExpiredOrPending, isCCPAStatusCompleted } from 'e-submission/domain/data-model/form/selectors'

export const Header = styled.div`
  font-size: 1.125rem;
  margin: 0 0 10px 0;
  font-weight: bold;
  line-height: 1;
  flex: 1;
`

const sendMethodText = {
  sms: ' SMS',
  email: 'อีเมล',
}

const getContentFormFeedback = (isExpiredOrPending, expiredDateTime) => {
  if (!isExpiredOrPending) {
    return `ลิงก์สมัครหักค่าเบี้ยประกันของท่านจะหมดอายุภายในวันที่ ${expiredDateTime?.date} เวลา ${expiredDateTime?.time} น.หรือภายใน 60 นาทีหลังจากได้รับข้อความ กรุณาตรวจสอบการชำระเบี้ยผู้เอาประกัน`
  }
  return `ลิงก์สมัครหักค่าเบี้ยประกันของท่านจะหมดอายุภายใน 60 นาทีหลังจากได้รับข้อความ กรุณาตรวจสอบการชำระเบี้ยผู้เอาประกัน`
}

const CcpaStatusCard = styled(
  ({
    className,
    children,
    phone,
    email,
    onVerifyCCPA,
    onResendCCPALink,
    onChangeCCPAMethod,
    isExpiredOrPending,
    isCCPACompleted,
    expiredDateTime,
    successfullDateTime,
    maskedCardNo,
    sendMethod,
    getGA4ccpaSuccess,
    ...props
  }) => {
    return (
      <div>
        <Header>สร้างลิงก์เพื่อสมัครหักค่าเบี้ยประกันอัตโนมัติผ่านบัตรเครดิต</Header>
        <Card className={className} w={1} column {...props}>
          <div className="title">ช่องทางที่ท่านระบุในการส่งลิงก์สมัครหักค่าเบี้ยประกันอัตโนมัติผ่านบัตรเครดิต</div>
          <div className="info-message">
            <div className="icon">{getIcon('checkFillCircle')}</div>
            ท่านเลือกสมัครผ่านช่องทาง{sendMethodText[sendMethod]} : {sendMethod === 'sms' ? phone : email}
          </div>
          {isCCPACompleted && (
            <div>
              <div className="title">ผลการสมัครหักค่าเบี้ยประกันอัตโนมัติผ่านบัตรเครดิต</div>
              <div className="info-message">
                <div>
                  สมัครเรียบร้อยเมื่อวันที่ {successfullDateTime?.date} เวลา {successfullDateTime?.time} น.
                  <br />
                  ด้วยบัตรเครดิต ลงท้ายด้วย {creditCardSuffix(maskedCardNo)}
                </div>
              </div>
            </div>
          )}
          <Flex column width={1} align="center" className="btn-container" id="ccpa-status">
            {!isCCPACompleted && (
              <Button className="btn-col" onClick={onVerifyCCPA}>
                ตรวจสอบสถานะ
              </Button>
            )}
            {isExpiredOrPending && (
              <Button className="btn-col" outline onClick={onResendCCPALink}>
                สร้างลิงก์อีกครั้ง
              </Button>
            )}
            {isExpiredOrPending && (
              <Button className="btn-col" outline onClick={onChangeCCPAMethod}>
                เปลี่ยนวิธีการสมัคร
              </Button>
            )}
          </Flex>
          {!isCCPACompleted && (
            <FormFeedback className="info-message attached-icon">
              <p>{getContentFormFeedback(isExpiredOrPending, expiredDateTime)}</p>
            </FormFeedback>
          )}
        </Card>
      </div>
    )
  }
)`
  padding: 22px 22px;
  border: none;
  .title {
    font-weight: bold;
  }
  .info-message {
    display: flex;
  }
  .icon {
    margin: 0 5px 0 0;
  }
  .btn-container {
    margin: 10px auto;
  }
  .btn-col {
    width: 100%;
    max-width: 207px;
  }
`

export default (hoc) =>
  compose(
    connect(
      (state) => {
        const app = getCurrentApp(state)
        const ccpaDetail = getOr(
          { status: null, isSendLink: false, expiredDateTime: null, successfullDateTime: null },
          'CCPA',
          app
        )
        const isExpiredOrPending = isCCPAStatusExpiredOrPending(app)
        const isCCPACompleted = isCCPAStatusCompleted(app)
        const expiredDateTime = get('expiredDateTime', ccpaDetail)
        const successfullDateTime = get('successfullDateTime', ccpaDetail)
        const maskedCardNo = getOr('', 'maskedCardNo', ccpaDetail)
        const sendMethod = get('sendLink.method.value', ccpaDetail)
        const phone = get('sendLink.sms', ccpaDetail)
        const email = get('sendLink.email', ccpaDetail)

        return {
          isExpiredOrPending,
          isCCPACompleted,
          expiredDateTime,
          successfullDateTime,
          maskedCardNo,
          sendMethod,
          phone,
          email,
          getGA4ccpaSuccess,
        }
      },
      (dispatch) => ({
        onVerifyCCPA: () => {
          dispatch(verifyCCPA())
        },
        onResendCCPALink: () => {
          dispatch(resendLinkCCPA())
        },
        onChangeCCPAMethod: () => {
          dispatch(changeCCPAMethod())
        },
      })
    )
  )(CcpaStatusCard)
