// @flow

// $$FlowFixMe
import logo from '!url-loader!assets/images/logo-ktaxa-th.png'
import { Columns, Bold } from 'core/service/pdf-generation/products/common/components/general'
import VALUE from 'core/data-model/constants/values'
import BIMESSAGES from 'core/data-model/constants/bi-messages'
import { gaPhrase } from '../../../utils/agent'
import type { Profile, DistributorGroupType } from 'core/data-model/distributor'
import type { InsuredProps } from 'core/service/pdf-generation/products/common/types'

const LogoImage = () => ({
  image: logo,
  width: 90,
})

// const CompanyDetail = {
//   stack: [
//     { text: 'บริษัท กรุงไทย-แอกซ่า ประกันชีวิต จำกัด (มหาชน)', bold: true, fontSize: 16 },
//     '9 อาคาร จี ทาวเวอร์ แกรนด์ รามา 9 ชั้น 1, 20-27 ถนนพระราม 9 แขวงห้วยขวาง เขตห้วยขวาง กรุงเทพมหานคร 10310',
//     'โทร 0 2044 4000 โทรสาร 0 2044 4032 ทะเบียนเลขที่ 0107555000376 www.krungthai-axa.co.th',
//     'ศูนย์ลูกค้าสัมพันธ์ โทร.1159',
//   ],
//   marginLeft: 20,
// }

const CompanyDetail2022 = {
  stack: [
    { text: 'บริษัท กรุงไทย-แอกซ่า ประกันชีวิต จำกัด (มหาชน)', bold: true, fontSize: 16 },
    '9 อาคาร จี ทาวเวอร์ แกรนด์ รามา 9 ชั้น 1, 22-27 ถนนพระราม 9 แขวงห้วยขวาง เขตห้วยขวาง กรุงเทพมหานคร 10310',
    'โทร 0 2044 4000 โทรสาร 0 2044 4032 ทะเบียนเลขที่ 0107555000376 www.krungthai-axa.co.th',
    'ศูนย์ลูกค้าสัมพันธ์ โทร.1159',
  ],
  marginLeft: 20,
}

export const insuredInfo = ({ title, firstName, lastName }: InsuredProps = {}) => {
  const insuredFullNameTitle = [
    title || BIMESSAGES.DEFAULT_TITLE,
    firstName || BIMESSAGES.DEFAULT_FIRSTNAME,
    lastName || BIMESSAGES.DEFAULT_LASTNAME,
  ].join(' ')
  return {
    alignment: 'center',
    marginTop: 50,
    marginBottom: 50,
    stack: [
      { text: BIMESSAGES.SPECIALLY_FOR, color: VALUE.MEDIUM_BLUE, fontSize: 22 },
      Bold({ text: insuredFullNameTitle, fontSize: 24 }),
    ],
  }
}

const getAgentCode = (profile: Profile, distributorType: DistributorGroupType): string => {
  if (distributorType === 'AGENT') return profile.agentCode || '-'
  else return profile.lbCode || '-'
}

const getBranchCode = (profile: Profile, distributorType: DistributorGroupType): string => {
  if (distributorType === 'AGENT') return gaPhrase(profile.ga)
  else return profile.branchName || '-'
}

export const _distributorInfo = (profile: Profile, distributorType: DistributorGroupType) => [
  [BIMESSAGES.PRESENT_BY, profile.given_name + ' ' + profile.family_name],
  [BIMESSAGES.PRESENTER_CODE, getAgentCode(profile, distributorType)],
  [BIMESSAGES.BRANCH_GA, getBranchCode(profile, distributorType)],
  [BIMESSAGES.TELEPHONE_NUMBER_CONTACT, profile.mobile || '-'],
]

export const distributorInfo = (profile: Profile, distributorType: DistributorGroupType) => ({
  marginLeft: 24,
  fontSize: 18,
  stack: _distributorInfo(profile, distributorType).map(([label, value]) =>
    Columns([
      { width: 85, text: label },
      { ...Bold({ text: value }), width: '*' },
    ])
  ),
})

type CoverProps = {
  insured: InsuredProps,
  distributorType: DistributorGroupType,
  profile: Profile,
  unitLinkedMessage: string,
  unitLinkedThMessage: string,
}

export const Cover = ({ insured, profile, distributorType, unitLinkedMessage, unitLinkedThMessage }: CoverProps) => ({
  stack: [
    { columns: [LogoImage(), CompanyDetail2022] },
    {
      fontSize: 36,
      marginTop: 24,
      marginBottom: 24,
      text: BIMESSAGES.INSURANCE_COUPLED_INVESTMENT_TITLE,
    },
    Bold({ text: unitLinkedMessage, color: '#004994', fontSize: 54 }),
    Bold({ text: unitLinkedThMessage, color: '#e4003a', fontSize: 36 }),
    insuredInfo(insured),
    distributorInfo(profile, distributorType),
  ],
})

export const RPUDRCover = ({
  insured,
  profile,
  distributorType,
  unitLinkedMessage,
  unitLinkedThMessage,
}: CoverProps) => ({
  stack: [
    { columns: [LogoImage(), CompanyDetail2022] },
    {
      fontSize: 36,
      marginTop: 24,
      marginBottom: 24,
      text: BIMESSAGES.INSURANCE_COUPLED_INVESTMENT_RPUDR_TITLE,
    },
    Bold({ text: unitLinkedMessage, color: '#004994', fontSize: 54 }),
    Bold({ text: unitLinkedThMessage, color: '#e4003a', fontSize: 36 }),
    insuredInfo(insured),
    distributorInfo(profile, distributorType),
  ],
})
