import { get, has, set, unset } from 'lodash/fp'
import { ListGroup } from 'common-components'
import { compose, withHandlers } from 'recompose'
import { Component as Checkbox } from './index'

const Component = ({ onChange, value, options }) => (
  <ListGroup
    value={value}
    options={options}
    onChange={onChange}
    isActive={(option) => {
      const key = get('value')(option)
      return has(key)(value)
    }}
    render={(props) => <Checkbox defaultChecked={false} {...props} />}
  />
)

export default (hoc) =>
  compose(
    withHandlers({
      onChange: ({ value, setValue }) => (option) => {
        const key = get('value')(option)
        const hasKey = has(key)(value)
        const newOptions = hasKey ? unset(key)(value) : set(key, option)(value)
        setValue(newOptions)
      },
    }),
    hoc
  )(Component)
