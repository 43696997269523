// @flow
import { queryPremiumRateForESCI as queryPremiumRate } from 'core/service/rider/query-premium-rate'
import { calculateRiderPremiumForESCI as calculateRiderPremium } from 'core/service/rider/premium-calculation'
import { queryTaxRateForESCI as queryTaxRate } from 'core/service/rider/query-tax-rate'

export default {
  calculateRiderPremium,
  queryPremiumRate,
  queryTaxRate,
}
