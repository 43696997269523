//@flow
import type { SetFnaValue } from '../actions'
import { SET_FNA_VALUE } from '../actions'

export const SET_FNA_NEED_CATEGORY = 'SET_FNA_NEED_CATEGORY'
export type SetSelectedNeedId = {
  type: 'SET_FNA_NEED_CATEGORY',
  payload: {
    id: string,
    planId: string,
  },
}
export const setSelectedNeedId = (id: string, planId: string) => {
  return {
    type: SET_FNA_NEED_CATEGORY,
    payload: {
      id,
      planId,
    },
  }
}

export const CLEAR_FNA_NEED_GAP_QUESTIONS = 'CLEAR_FNA_NEED_GAP_QUESTIONS'
export type ClearFnaNeedGapQuestions = {
  type: 'CLEAR_FNA_NEED_GAP_QUESTIONS',
}
export const clearFnaNeedGapQuestions = () => {
  return {
    type: CLEAR_FNA_NEED_GAP_QUESTIONS,
  }
}

export const SET_FNA_GAP_VALUE = 'SET_FNA_GAP_VALUE'
export type SetFnaGapValue = {
  type: 'SET_FNA_GAP_VALUE',
  payload: {
    questionKey: string,
    value: string | number,
  },
}
export const setFnaGapValue = (questionKey: string, value: string | number) => {
  return {
    type: SET_FNA_GAP_VALUE,
    payload: {
      questionKey,
      value,
    },
  }
}

export const SET_FNA_GAP_TARGET_SAVINGS = 'SET_FNA_GAP_TARGET_SAVINGS'
export type SetFnaGapTargetSavings = {
  type: 'SET_FNA_GAP_TARGET_SAVINGS',
  payload: string | number,
}
export const setFnaGapTargetSavings = (value: number) => {
  return {
    type: SET_FNA_GAP_TARGET_SAVINGS,
    payload: value,
  }
}

export const CLEAR_FNA_GAP_TARGET_SAVINGS = 'CLEAR_FNA_GAP_TARGET_SAVINGS'
export type ClearFnaGapTargetSavings = {
  type: 'CLEAR_FNA_GAP_TARGET_SAVINGS',
}
export const clearFnaGapTargetSavings = () => {
  return {
    type: CLEAR_FNA_GAP_TARGET_SAVINGS,
  }
}

export const CLEAR_FNA_GAP_INVESTMENT_GOAL_OTHER = 'CLEAR_FNA_GAP_INVESTMENT_GOAL_OTHER'
export type ClearFnaGapInvestmentGoalOther = {
  type: 'CLEAR_FNA_GAP_INVESTMENT_GOAL_OTHER',
}
export const clearFnaGapInvestmentGoalOther = () => {
  return {
    type: CLEAR_FNA_GAP_INVESTMENT_GOAL_OTHER,
  }
}

export const CLEAR_REASON_FOR_CHOOSING_OTHER = 'CLEAR_REASON_FOR_CHOOSING_OTHER'
export type ClearReasonForChoosingOther = {
  type: 'CLEAR_REASON_FOR_CHOOSING_OTHER',
}
export const clearReasonForChoosingOther = () => {
  return {
    type: CLEAR_REASON_FOR_CHOOSING_OTHER,
  }
}

export const CLEAR_OTHER_COVERAGE_OTHER = 'CLEAR_OTHER_COVERAGE_OTHER'
export type ClearOtherCoverageOther = {
  type: 'CLEAR_OTHER_COVERAGE_OTHER',
}
export const clearOtherCoverageOther = () => {
  return {
    type: CLEAR_OTHER_COVERAGE_OTHER,
  }
}

type NeedAndGapValue = number | string | number[] | string[]

export const setNeedGapValue = (path: string, value: NeedAndGapValue): SetFnaValue => ({
  type: SET_FNA_VALUE,
  payload: {
    path: 'needGap.' + path,
    value: value,
  },
})

type QuestionValue = string | number
export const setQuestion = (questionKey: string, value: QuestionValue): SetFnaValue =>
  setNeedGapValue(`questions.${questionKey}`, value)

export const RESET_FNA_NEED = 'RESET_FNA_NEED'
export type ResetFNA = {
  type: 'RESET_FNA_NEED',
}

export const resetFNA = () => ({
  type: RESET_FNA_NEED,
})

export type Action =
  | SetSelectedNeedId
  | ClearFnaGapTargetSavings
  | ClearFnaNeedGapQuestions
  | ClearFnaGapInvestmentGoalOther
  | ClearReasonForChoosingOther
  | ClearOtherCoverageOther
  | SetFnaValue
  | SetFnaGapValue
  | SetFnaGapTargetSavings
  | ResetFNA
