import { flatten } from 'lodash/fp'
import { createField, createSectionCreator } from 'e-submission/domain/data-model/form/utils'

export const CheckStatusSection = createSectionCreator((app, id, section) => {
  return flatten([
    createField(`EDA.checkStatus`, [
      {
        id: `EDA.checkStatus`,
        c: 'EDACheckStatusPage',
      },
    ]),
  ])
})
