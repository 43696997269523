import { compact, get, has } from 'lodash/fp'
import VALUES from 'core/data-model/constants/values'
import productWholeLife from './product-whole-life'
import productLifeSavePro from './product-life-save-pro'
import product25PL from './product-whole-life/25PL'
import productRPUL from './product-investment/RPUL'
import productSPUL from './product-investment/SPUL'
import productHealth from './product-health'
import productProtection from './product-protection'
import productRetirement from './product-retirement'
import productTermLife from './product-term-life'
import productPA from './product-pa'

const formLoader = {
  [VALUES.WHOLE_LIFE]: productWholeLife,
  [VALUES.INVESTMENT]: productRPUL,
  [VALUES.HEALTH]: productHealth,
  [VALUES.SPUL]: productSPUL,
  [VALUES.PROTECTION]: productProtection,
  [VALUES['25PL']]: product25PL,
  [VALUES['12PL']]: product25PL,
  [VALUES.LIFE_SAVE_PRO]: productLifeSavePro,
  [VALUES.RETIREMENT]: productRetirement,
  [VALUES.BUMNAN_READY]: productRetirement,
  [VALUES.TERM_LIFE]: productTermLife,
  [VALUES.PA]: productPA,
}

export default (app, category, productCode) => {
  let form
  if (has(productCode, formLoader)) {
    form = get(productCode, formLoader)(app)
  } else if (has(category, formLoader)) {
    form = get(category, formLoader)(app)
  } else {
    form = get(VALUES.WHOLE_LIFE, formLoader)(app)
  }

  return compact(form)
}
