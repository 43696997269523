// @flow
import VALUES from 'core/data-model/constants/values'

export const calculateCoverageDeathBenefit = (productCode: string, sumAssured: number) => {
  switch (productCode) {
    case VALUES.RPUL:
      return (sumAssured * VALUES.RPUL_DEATH_BENEFIT_PERCENT) / 100
    case VALUES.SPUL:
      return (sumAssured * VALUES.SPUL_DEATH_BENEFIT_PERCENT) / 100
    default:
      return 0
  }
}
