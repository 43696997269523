// @flow
import _ from 'lodash'
import { formatNumber } from 'core/service/lib/number-format'
import type { ProductBenefitProps } from 'quick-quote/product-common/benefit-illustration/components/product-benefits/types'
import type { DisplayProduct } from 'core/service/display-product'
import Mustache from 'mustache'
import MESSAGES from 'core/data-model/constants/messages'
import BIMESSAGES from 'core/data-model/constants/bi-messages'
import values from 'core/data-model/constants/values'
import GraphE20F10 from 'assets/images/product-benefits/Graph-E20F10.svg'
import GraphE25F15 from 'assets/images/product-benefits/Graph-E25F15.svg'
import GraphE25F25 from 'assets/images/product-benefits/Graph-E25F25.svg'
import GraphS14G5T1 from 'assets/images/product-benefits/Graph-S14G5T1.svg'
import Graph_MRTA_FULL from 'assets/images/product-benefits/Graph-MRTA_FULL.svg'
import Graph_MRTA_PLUS from 'assets/images/product-benefits/Graph-MRTA_PLUS.svg'
import Graph_MRTA_TRUNCATE from 'assets/images/product-benefits/Graph-MRTA_TRUNCATE.svg'
import GraphW80F06 from 'assets/images/product-benefits/Graph-W80F06.svg'
import React from 'react'

const _getProductName = (productName, displayProduct) => {
  const selectedCampaignPlan = displayProduct.selectedCampaignPlan
  let displayProductName
  if (selectedCampaignPlan === values.MEK1) {
    displayProductName = BIMESSAGES.SUKHAPABDEKDEE_PLAN.MEK1
  } else if (selectedCampaignPlan === values.MEK2) {
    displayProductName = BIMESSAGES.SUKHAPABDEKDEE_PLAN.MEK2
  } else if (values.LIFEREADY_CAMPAIGN_CODE.includes(displayProduct.code)) {
    displayProductName = BIMESSAGES.LIFE_READY_PACKAGE_NAME_THAI
  } else if (values.CANCERX5_CAMPAIGN_CODE.includes(displayProduct.code)) {
    displayProductName = BIMESSAGES.LIFE_READY_PACKAGE_NAME_THAI
  } else {
    displayProductName = productName
  }
  return Mustache.render(BIMESSAGES.BASIC_PLAN_DETAILS, {
    name: displayProductName,
    code: displayProduct.basicPlanCode,
  })
}

const renderGraph = (basicPlanCode: string) => {
  const graph = (() => {
    switch (basicPlanCode) {
      case 'E20F10':
        return <GraphE20F10 />
      case 'E25F15':
        return <GraphE25F15 />
      case 'E25F25':
        return <GraphE25F25 />
      case 'S14G5T1':
        return <GraphS14G5T1 />
      case 'MRTA_FULL':
        return <Graph_MRTA_FULL />
      case 'MRTA_PLUS':
        return <Graph_MRTA_PLUS />
      case 'MRTA_TRUNCATE':
        return <Graph_MRTA_TRUNCATE />
      case 'W80F06':
        return <GraphW80F06 />
      default:
        return null
    }
  })()
  if (graph) {
    const className = `graph-${basicPlanCode}`
    return <div className={className}>{graph}</div>
  } else {
    return ''
  }
}

const renderCoveragePeriod = (displayProduct: DisplayProduct, calculatedCoveragePeriod: number) => {
  if (displayProduct?.basicPlanCode === 'W80F06') {
    return BIMESSAGES.COVERAGE_PERIOD_ISMART_SUFFIX
  }
  return `${calculatedCoveragePeriod} ${MESSAGES.TIME_UNIT_YEAR}`
}

const ProductBenefitsSummary = ({
  displayProduct,
  sumAssured,
  basicPremium,
  calculatedPaymentPeriod,
  calculatedCoveragePeriod,
  benefitSummaryHeader,
  selectedModelFactorID,
  selectedModelFactorLabel,
  showGraph = true,
}: ProductBenefitProps) => {
  const productName = _.isFunction(benefitSummaryHeader)
    ? // $$FlowFixMe
      benefitSummaryHeader({
        displayProduct,
        sumAssured,
        basicPremium,
        calculatedPaymentPeriod,
        calculatedCoveragePeriod,
      })
    : benefitSummaryHeader
  return (
    <div id="product-benefits-summary" className="product">
      <h3 className="name">{_getProductName(productName, displayProduct)}</h3>
      <div className="info-list">
        <div id="benefits-coverage-period" className="info">
          <p className="label">{BIMESSAGES.COVERAGE_PERIOD}</p>
          <p className="value">{renderCoveragePeriod(displayProduct, calculatedCoveragePeriod)}</p>
        </div>
        <div id="benefits-payment-period" className="info">
          <p className="label">{BIMESSAGES.PAYMENT_PERIOD}</p>
          <p className="value">
            {selectedModelFactorID === 'model-factor_5' ? selectedModelFactorLabel : calculatedPaymentPeriod + ' ปี'}
          </p>
        </div>
        <div id="benefits-sum-assured" className="info">
          <p className="label">{BIMESSAGES.BASIC_PLAN_SUM_ASSURED}</p>
          <p className="value">{formatNumber(sumAssured)} บาท</p>
        </div>
        <div id="benefits-basic-premium" className="info">
          <p className="label">{BIMESSAGES.BASIC_PREMIUM}</p>
          <p className="value">{formatNumber(basicPremium, 2)} บาท</p>
        </div>
      </div>
      {showGraph && displayProduct ? renderGraph(displayProduct.basicPlanCode) : ''}
    </div>
  )
}

export default ProductBenefitsSummary
