export const mediaActions = {
  TOGGLE_MIC: 'TOGGLE_MIC',
  TOGGLE_CAMERA: 'TOGGLE_CAMERA',
  TOGGLE_SCREEN: 'TOGGLE_SCREEN',
  TOGGLE_SHOW_CONTROLS: 'TOGGLE_SHOW_CONTROLS',
  RESET_MEDIA_CONTROL_STATE: 'RESET_MEDIA_CONTROL_STATE',
  HANGUP: 'HANGUP',
  GET_MEDIA_STATUS: 'GET_MEDIA_STATUS',
  SET_MEDIA_CONTROL_STATE: 'SET_MEDIA_CONTROL_STATE',
  OPEN_DF2F_APPLICATION: 'OPEN_DF2F_APPLICATION',
  UPDATE_MEDIA_STATE: 'UPDATE_MEDIA_STATE',
}

export const initialMediaControlState = {
  micState: false,
  cameraState: false,
  screenState: false,
  showControls: false,
}

export const initialRemoteSellingState = {
  roomId: '',
  isInCall: false,
  email: '',
  phoneNumber: '',
  callStatus: {
    isAgentStreaming: false,
    isClientStreaming: false,
    latency: 0,
  },
  appId: '',
  disableEditing: false,
  mediaControl: initialMediaControlState,
}
