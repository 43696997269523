//@flow
import VALUES from 'core/data-model/constants/values'
import type { DisplayProductQuery } from 'core/service'
import type { FundAllocationToggleItem } from 'core/service/investment'
import { calculateTotalAllocation } from 'core/service/investment'
import { resetLstuFundConsents } from 'quick-quote/product-investment/fund-allocation/lstu/actions'
import { getSelectedAllocationOption } from 'quick-quote/product-investment/fund-allocation/lstu/selectors'
import type { CalculateTotalFundAllocation } from 'quick-quote/product-investment/fund-allocation/rpp/actions'
import {
  updateRppFundAllocation,
  updateRppTotalFundAllocation,
} from 'quick-quote/product-investment/fund-allocation/rpp/actions'
import { getRppFundAllocations } from 'quick-quote/product-investment/fund-allocation/rpp/selectors'
import { updateFundAllocation } from 'quick-quote/product-investment/fund-allocation/utils'
import { getRiskEvaluated } from 'quick-quote/product-investment/risk-questionnaire/selectors'
import { getSelectedDisplayProductQuery } from 'quick-quote/product-selection/selectors'
import { call, put, select } from 'redux-saga/effects'

export function* onCalculateRppTotalAllocationRequest(action: CalculateTotalFundAllocation): Generator<*, *, *> {
  const fundAllocations = yield select(getRppFundAllocations)
  yield call(processAndUpdateRppFundAllocation, updateFundAllocation(fundAllocations, action.payload))
}

export function* processAndUpdateRppFundAllocation(fundAllocations: FundAllocationToggleItem[]): Generator<*, *, *> {
  const displayProductQuery: DisplayProductQuery = yield select(getSelectedDisplayProductQuery)
  const insuredRiskLevel = yield select(getRiskEvaluated)
  const allocationResult = yield call(
    calculateTotalAllocation,
    displayProductQuery,
    insuredRiskLevel.value,
    fundAllocations
  )
  yield put(updateRppTotalFundAllocation(allocationResult.totalAllocation))
  yield put(updateRppFundAllocation(allocationResult.fundAllocations))
}

export function* onEditRppFundAllocation(): Generator<*, *, *> {
  const selectedLstuOption = yield select(getSelectedAllocationOption)
  if (selectedLstuOption === VALUES.LSTU_FUND_ALLOCATION_OPTION.SAME_AS_RPP) {
    yield put(resetLstuFundConsents())
  }
}
