import React from 'react'
import PropTypes from 'prop-types'

import NeedList from './need-list'
import NeedQuestions from './need-questions'
import { getToggles } from 'quick-quote/feature-toggles'
import { Redirect } from 'react-router-dom'
import { ROUTE_PATH } from 'quick-quote/constants/path'

function Form(props) {
  const { selectedNeedId, isUserOnline, isUserLogin } = props
  if (getToggles().ENABLE_FORCE_LOGIN_FIRST) {
    if (!isUserOnline || !isUserLogin) {
      return <Redirect to={ROUTE_PATH.PORTAL} push />
    }
  }

  return (
    <div id="need-gap-form" className="az-need-and-gap">
      <div className="az-need-and-gap__container">
        <NeedList {...props} />
        {selectedNeedId ? <NeedQuestions key={selectedNeedId} {...props} /> : null}
      </div>
    </div>
  )
}

const propTypes = {
  needs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  questions: PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.string,
  }),
  needPlan: PropTypes.shape({}),
  selectedNeedId: PropTypes.string,
  selectedPlanId: PropTypes.string,
  setSelectedNeedId: PropTypes.func,
  setQuestion: PropTypes.func,
  targetSavings: PropTypes.number,
}
const defaultProps = {
  needs: [],
  questions: {},
  needPlan: {},
  selectedNeedId: '',
  selectedPlanId: '',
  setSelectedNeedId() {},
  setQuestion() {},
}

Form.propTypes = propTypes
Form.defaultProps = defaultProps

export default Form
