// @flow
import _ from 'lodash'

import VALUES from 'core/data-model/constants/values'
import BenefitSummaryTable from 'quick-quote/product-whole-life/benefit-illustration/components/benefit-summary-table'

import InvestmentBenefit from 'quick-quote/product-life-save-pro/benefit-illustration/components/investment-benefit/investment-benefit'
import { Benefit12PL } from 'quick-quote/product-ihealthy-ultra/benefit-illustration/components/benefitSummary'
import type { BenefitSummaryTableProps } from 'quick-quote/v2/products/customs/12pl/components/benefit-summary-table/container'

export type BenefitSummaryContentProps = {
  displayProduct: DisplayProduct,
  coveragePlanStartDate: Date,
  legalDisclaimerEndDate: Date,
  benefitSummaryProps12PL: BenefitSummaryTableProps,
}

const BenefitSummaryContent = ({
  displayProduct,
  coveragePlanStartDate,
  legalDisclaimerEndDate,
  benefitSummaryProps12PL,
}: BenefitSummaryContentProps) => {
  const seletedCode = _.get(displayProduct, 'code')
  const {
    totalGuaranteeDividend,
    lastGuaranteeDividend,
    totalGuaranteeDividendBeforeLastYear,
    nonGuaranteeDividendBeforeDueDate,
    totalNonGuaranteeDividend,
  } = benefitSummaryProps12PL
  switch (seletedCode) {
    case VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_25_15.JUNIOR:
    case VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_25_15.SENIOR:
    case VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_20_10.JUNIOR:
    case VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_20_10.SENIOR:
      return (
        <InvestmentBenefit
          displayProduct={displayProduct}
          coveragePlanStartDate={coveragePlanStartDate}
          legalDisclaimerEndDate={legalDisclaimerEndDate}
        />
      )
    case VALUES.IHEALTHY_ULTRA_CODE['12PL']:
      return (
        <Benefit12PL
          lastGuaranteeDividend={lastGuaranteeDividend}
          totalGuaranteeDividend={totalGuaranteeDividend}
          totalGuaranteeDividendBeforeLastYear={totalGuaranteeDividendBeforeLastYear}
          nonGuaranteeDividendBeforeDueDate={nonGuaranteeDividendBeforeDueDate}
          totalNonGuaranteeDividend={totalNonGuaranteeDividend}
        />
      )
    case VALUES.IHEALTHY_ULTRA_CODE.IHEALTHY_60:
    case VALUES.IHEALTHY_ULTRA_CODE.IHEALTHY_85:
      return <div></div>
    default:
      return <BenefitSummaryTable />
  }
}

const BenefitSummaryContents = ({
  displayProduct,
  coveragePlanStartDate,
  legalDisclaimerEndDate,
  benefitSummaryProps12PL,
}: BenefitSummaryContentProps) => {
  return (
    <BenefitSummaryContent
      displayProduct={displayProduct}
      coveragePlanStartDate={coveragePlanStartDate}
      legalDisclaimerEndDate={legalDisclaimerEndDate}
      benefitSummaryProps12PL={benefitSummaryProps12PL}
    />
  )
}

export default BenefitSummaryContents
