// @flow

import _ from 'lodash'
import { getOccupationFactor, getOccupationFactorByValidOccupations } from 'core/service/insured'
import { renderUnit } from 'core/service/insured/age'
import { calculateAge, convertEntryAge, isValidAge, isValidBirthdate } from 'core/service/insured/birthdate'
import { isPartiallyValidDate } from 'core/service/lib/date'
import { createChecker } from 'core/service/lib/check'
import type { DisplayProductQuery } from 'core/service'
import { getBasicPlan } from 'core/service'
import Mustache from 'mustache'
import CONSTANTS from 'core/data-model/constants/messages'
import MESSAGES from 'core/data-model/constants/validation-messages'
import VALUES from 'core/data-model/constants/values'
import DEFAULTS from 'core/data-model/constants/defaults'
import type { Age, EntryAge } from 'core/data-model/insured'
import type { BasicPlan } from 'core/data-model/basic-plan/types'

export const isPartiallyFilled = (birthdate: string): boolean => {
  return birthdate.length < 10
}

export const _validateBirthdate = (
  birthdate: string,
  entryAge: EntryAge,
  previousAge: Age,
  currentDate: string,
  isPayerBirthdate: boolean = false
) => {
  const error: Array<string> = []

  const isValidBirthdateInput: boolean = isPartiallyFilled(birthdate)
    ? isPartiallyValidDate(birthdate)
    : isValidBirthdate(birthdate, currentDate)

  const isReadyToCalculateAge: boolean = !isPartiallyFilled(birthdate) && isValidBirthdateInput

  if (isReadyToCalculateAge) {
    let age: Age = calculateAge(birthdate, currentDate)
    if (!isValidAge(age.value, age.unit, entryAge)) {
      const owner: string = isPayerBirthdate ? CONSTANTS.PAYER : CONSTANTS.INSURED_PERSON
      error.push(
        Mustache.render(MESSAGES.RULE_AGE, {
          owner,
          minimumAge: `${entryAge.minimum.value} ${renderUnit(entryAge.minimum.unit)}`,
          maximumAge: `${entryAge.maximum.value} ${renderUnit(entryAge.maximum.unit)}`,
        })
      )
      age = { value: 0, unit: 'day' }
    }
    return { birthdate, error, age }
  } else {
    if (!isValidBirthdateInput || (previousAge.value === 0 && previousAge.unit === 'day')) {
      error.push(MESSAGES.RULE_BIRTHDATE)
    }
    return { birthdate, error, age: previousAge }
  }
}

export const _getEntryAge = (basicPlan: BasicPlan, isPayerBirthdate: boolean) => {
  const config = basicPlan.product || basicPlan
  if (isPayerBirthdate) {
    const payerEntryAgeMinimum = _.get(config, 'payerEntryAgeMinimum', -1)
    if (payerEntryAgeMinimum !== -1) {
      return {
        minimum: convertEntryAge(payerEntryAgeMinimum),
        maximum: convertEntryAge(_.get(config, 'payerEntryAgeMaximum', DEFAULTS.DEFAULT_INSURED_ENTRY_AGE_MAXIMUM)),
      }
    } else {
      return DEFAULTS.DEFAULT_PAYER_ENTRY_AGE
    }
  }

  const insuredEntryAgeMinimum = _.get(config, 'insuredEntryAgeMinimum', DEFAULTS.DEFAULT_INSURED_ENTRY_AGE_MINIMUM)
  const insuredEntryAgeMaximum = _.get(config, 'insuredEntryAgeMaximum', DEFAULTS.DEFAULT_INSURED_ENTRY_AGE_MAXIMUM)
  return {
    minimum: convertEntryAge(insuredEntryAgeMinimum),
    maximum: convertEntryAge(insuredEntryAgeMaximum),
  }
}

export const validateBirthdate = async (
  birthdate: string,
  displayProductQuery: DisplayProductQuery,
  previousAge: Age,
  currentDate: string,
  isPayerBirthdate: boolean = false
) => {
  const basicPlan = await getBasicPlan(displayProductQuery)
  const entryAge = _getEntryAge(basicPlan, isPayerBirthdate)
  return _validateBirthdate(birthdate, entryAge, previousAge, currentDate, isPayerBirthdate)
}

export const _validateOccupationFactor = (basicPlanOccupationFactor: number) =>
  basicPlanOccupationFactor === -1 ? [MESSAGES.RULE_INSURED_OCCUPATION_FACTOR] : []

export const _validateProductOccupationFactor = (basicPlanOccupationFactor: number) =>
  basicPlanOccupationFactor === -1 ? [MESSAGES.RULE_INSURED_PRODUCT_OCCUPATION_FACTOR] : []

export const validateOccupationFactor = async (displayProductQuery: DisplayProductQuery, natureOfDutyCode: string) =>
  getOccupationFactor(displayProductQuery, natureOfDutyCode).then(_validateOccupationFactor)

//TODO this function can be grouped with validateOccupationFactor
export const validateValidOccupationFactor = async (
  displayProductQuery: DisplayProductQuery,
  natureOfDutyCode: string
) => getOccupationFactorByValidOccupations(displayProductQuery, natureOfDutyCode).then(_validateProductOccupationFactor)

// ==== name validation ====

const charRange = (startChar: string, endChar: string) =>
  _.range(startChar.charCodeAt(0), endChar.charCodeAt(0) + 1).map((c) => String.fromCharCode(c))

const thaiChars = _.concat(charRange('ก', 'ฮ'), charRange('ฯ', 'ฺ'), charRange('เ', 'ํ'))

const englishChars = _.concat(charRange('a', 'z'), charRange('A', 'Z'))

export const _allowedChars = _.concat(thaiChars, englishChars, [' ', '.', '-'])

const validateName = (minLength: number, maxLength: number) => (name: string) => {
  const checker = createChecker([
    [
      `lengthLessThanOrEqual${maxLength}`,
      {
        type: 'VALIDATION',
        rule: ({}, name) => name.length >= minLength && name.length <= maxLength,
        message: MESSAGES.RULE_NAME_EXCEED_LENGTH,
      },
    ],
    [
      'onlyThaiOrEnglishAndNotIncludeBothDotAndDash',
      {
        type: 'VALIDATION',
        rule: ({}, name) => isPersonName(name),
        message: MESSAGES.RULE_NAME_INVALID_CHARACTER,
      },
    ],
  ])

  return checker({}, name).getErrorMessages('VALIDATION')
}

const _validateTitle = (minLength: number, maxLength: number) => (value: { value: string, text: string }) => {
  const checker = createChecker([
    [
      'notIncludeNumber',
      {
        type: 'VALIDATION',
        rule: ({}, value) => value.value !== '' && isTitle(value.text),
        message: MESSAGES.RULE_TITLE_EMPTY_OTHER,
      },
    ],
  ])

  return checker({}, value).getErrorMessages('VALIDATION')
}

const validatePattern = (pattern) => (text: string) => pattern.test(text)
export const isPersonName = validatePattern(/^[a-zA-Z\u0E01-\u0E5B\s\.\-]*$/)
export const isTitle = validatePattern(/[^0-9]/)
export const validateTitle = _validateTitle(VALUES.TITLE_MIN_LENGTH, VALUES.TITLE_MAX_LENGTH)
export const validateFirstName = validateName(VALUES.FIRST_NAME_MIN_LENGTH, VALUES.FIRST_NAME_MAX_LENGTH)
export const validateLastName = validateName(VALUES.LAST_NAME_MIN_LENGTH, VALUES.LAST_NAME_MAX_LENGTH)
