//
import { put, takeLatest, call } from 'redux-saga/effects'
import config from 'config'

import { getAppConfig } from 'deploy-env/app-config'
import { cordovaCodePushLogs } from 'lib/cordova-util'
import {
  applicationFetchUpdateSuccess,
  applicationFetchUpdateError,
  applicationInstallUpdate,
  applicationInstallUpdateSuccess,
  applicationInstallUpdateError,
  applicationFetchUpdate,
  applicationCheckVersionValidVersion,
  applicationCheckVersionInvalidVersion,
} from 'core/actions'
import { getToggles } from 'quick-quote/feature-toggles'
import { INITIALISE_APPLICATION_SUCCESS } from 'quick-quote/actions'
import { setNotificationMessage } from 'quick-quote/notification/actions'

import { APPLICATION_FETCH_UPDATE, APPLICATION_CHECK_VERSION } from './action-types'
import { checkVersionNativeApp } from '../deploy-env/app-config'
import { updateCheckNativeAppVersionUser } from 'identity/actions'
import { IsRealDevice } from 'e-submission/utils'

/* eslint-disable no-console */
export async function fetchChcpUpdate() {
  const chcpURL = getAppConfig().CORDOVA_CODE_PUSH_URL
  const appVersion = config.appVersion
  const installData = await new Promise((resolve, reject) => {
    const options = {
      'config-file': `${chcpURL}/chcp.json`,
      'request-headers': {
        'application-version': `${appVersion}`,
      },
    }
    window.chcp.fetchUpdate((error, data) => {
      cordovaCodePushLogs({ error, data, appVersion, state: 'fetchUpdateManual' })
      // New version avaliable
      if (!error) {
        resolve(data)
        return
      }
      // Nothing new to load from server
      console.info('no chcp update', error.code === 2 || error.code === -17)
      if (error.code === 2 || error.code === -17) {
        resolve(undefined)
        return
      }
      // There are error, rejected them use catch to show both known and unknown error
      console.error('Failed to load the update with error code: ' + error.code)
      console.error(error.description)
      reject(error)
    }, options)
  })
  return installData
}
/* eslint-enable no-console */

export function* startFetchApplicationUpdate() {
  if (!window.chcp) {
    return
  }
  const appVersion = config.appVersion
  // Below code is a bit confuse.
  //TODO: Need to refacetor CHCP saga
  let installData
  try {
    installData = yield fetchChcpUpdate()
    if (!installData) {
      // No need to install new version. So, we act like fetch and install is success
      yield put(applicationFetchUpdateSuccess())
      yield put(applicationInstallUpdateSuccess())
      return
    }
  } catch (err) {
    yield put(applicationFetchUpdateError(err.description || err.message || err))
    yield put(
      setNotificationMessage({
        type: 'CordovaUpdateFailed',
        message: err.description || err.message || err,
      })
    )
    return
  }
  try {
    yield put(applicationFetchUpdateSuccess())
    yield put(applicationInstallUpdate())
    yield new Promise((resolve, reject) => {
      window.chcp.installUpdate((error) => {
        cordovaCodePushLogs({
          error,
          installData,
          appVersion,
          state: 'installUpdateManual',
        })
        if (error) {
          console.error('Failed to install the update with error code: ' + error.code)
          console.error(error.description)
          reject(error)
        } else {
          console.warn('Update installed!')
          resolve()
        }
      })
    })
  } catch (err) {
    yield put(applicationInstallUpdateError(err.description || err.message || err))
    yield put(
      setNotificationMessage({
        type: 'CordovaUpdateFailed',
        message: err.description || err.message || err,
      })
    )
    return
  }
  yield put(applicationInstallUpdateSuccess())
}

export function* startCheckversion() {
  if (getToggles().ENABLE_CHECK_TARGET_VERSION && IsRealDevice) {
    try {
      const err = yield call(checkVersionNativeApp)

      if (!err) {
        yield put(applicationCheckVersionValidVersion())
        yield put(updateCheckNativeAppVersionUser())
      } else {
        yield put(applicationCheckVersionInvalidVersion(err))
        yield put(
          setNotificationMessage({
            type: 'CheckVersionNative',
          })
        )
      }
    } catch (error) {
      yield put(applicationCheckVersionInvalidVersion(error))
      yield put(
        setNotificationMessage({
          type: 'CheckVersionNative',
        })
      )
    }
  }
}

export function* onAppInitSuccess() {
  if (getToggles().ENABLE_CORDOVA_STARTUP_UPDATE_PROGRESS && window.chcp) {
    yield put(applicationFetchUpdate())
  }
}

export default function*() {
  yield [takeLatest(APPLICATION_FETCH_UPDATE, startFetchApplicationUpdate)]
  yield [takeLatest(INITIALISE_APPLICATION_SUCCESS, onAppInitSuccess)]
  yield [takeLatest(APPLICATION_CHECK_VERSION, startCheckversion)]
}
