import _ from 'lodash'

import { createSectionCreator, createField } from '../utils'
import { checker } from '../selectors'

const treatmentField = (app, id) => {
  const isShowRemoveButton = !id.includes('[0]')

  return _.compact([
    createField(`${id}.symptom`, [
      {
        label: 'อาการ',
        id: `${id}.symptom`,
        c: 'TextInput',
        required: true,
        p: {},
      },
    ]),
    createField(`${id}.when`, [
      {
        label: 'วัน/เดือน/ปี ที่รับการรักษา',
        id: `${id}.when`,
        c: 'TextInput',
        required: true,
        p: {},
      },
    ]),
    createField(`${id}.where`, [
      {
        label: 'สถานพยาบาล',
        id: `${id}.where`,
        c: 'TextInput',
        required: true,
        p: {},
      },
    ]),
    createField(`${id}.result`, [
      {
        label: 'ผลการรักษา',
        id: `${id}.result`,
        c: 'TextInput',
        required: true,
        p: {},
      },
    ]),
    isShowRemoveButton &&
      createField(
        `${id}.remove`,
        [
          {
            id: `${id}.remove`,
            c: 'Button',
            p: {
              icon: 'remove',
              children: 'ลบ',
              mergeProps: ({ removeValue }) => ({
                onClick: () => removeValue(id),
              }),
              style: {
                fontSize: '0.8725rem',
                padding: '0.125rem 0.5rem',
              },
            },
          },
        ],
        {
          style: {
            position: 'absolute',
            top: '0',
            right: '0',
          },
        }
      ),
  ])
}

const addTreatment = (app, id) => [
  createField(`${id}.addNew`, [
    {
      id,
      c: 'Button',
      p: {
        children: 'เพิ่มข้อมูล',
        icon: 'add',
        block: true,
        mergeState: () => ({
          mergeProps: ({ setValue }) => ({
            onClick: () => setValue(null),
          }),
        }),
      },
    },
  ]),
]

export default (sectionId, sectionLabel) => (app, id) => {
  const treatmentDetail = _.get(app, `${id}.treatmentMRTA.detail`, [null])
  const { isYes, isNo } = checker(app)

  return _.compact(
    isYes(`${id}.treatmentMRTA.checked`) &&
      _.reduce(
        treatmentDetail,
        (result, el, index) => [
          ...result,
          createSectionCreator(treatmentField)(
            `${sectionId}[${index}]`,
            null,
            null,
            `รายละเอียดการรักษาที่ ${index + 1}`
          )(app, `${id}.treatmentMRTA.detail[${index}]`),
        ],
        []
      ).concat(
        treatmentDetail.length < 10 &&
          createSectionCreator(addTreatment)(sectionId)(app, `${id}.treatmentMRTA.detail[${treatmentDetail.length}]`)
      )
  )
}
