// @flow
import VALUES from 'core/data-model/constants/values'
import MESSAGES from 'core/data-model/constants/bi-messages'

import type { DisplayProduct } from 'core/service/display-product'

type Props = {
  basicPlan: DisplayProduct,
}

export const benefitSummarySection = ({ basicPlan }: Props) => ({
  stack: [
    {
      text: _buildHeader(basicPlan),
      style: 'title',
      pageBreak: 'before',
    },
    {
      table: {
        headerRows: 1,
        widths: ['*', '*'],
        margin: [100, 10],
        body: [..._buildBenefitSummary(basicPlan)],
      },
      layout: 'lightBorder',
      style: 'table',
    },
    { stack: _buildDisclaimer(basicPlan), style: 'disclaimer' },
  ],
  style: 'groupRow',
})

const _buildHeader = (basicPlan) => {
  switch (basicPlan.basicPlanCode) {
    case VALUES.MRTA_CODE.MRTA_FULL:
      return MESSAGES.HEADER_BENEFIT_TABLE_MRTA_FULL
    case VALUES.MRTA_CODE.MRTA_PLUS:
      return MESSAGES.HEADER_BENEFIT_TABLE_MRTA_PLUS
    case VALUES.MRTA_CODE.MRTA_TRUNCATE:
      return MESSAGES.HEADER_BENEFIT_TABLE_MRTA_PLUS
    default:
      return MESSAGES.HEADER_BENEFIT_TABLE_DEFAULT
  }
}

const _buildBenefitSummary = (basicPlan) => {
  let results = [
    [
      { text: MESSAGES.TABLE_HEADER_BASIC_PLAN_DETAIL, alignment: 'left', style: 'tableHeader' },
      { text: MESSAGES.BENEFIT_TITLE, style: 'tableHeader' },
    ],
    _buildRowInTable(MESSAGES.TABLE_DETAIL_BENEFIT_BASIC_PLAN[0][0], MESSAGES.TABLE_DETAIL_BENEFIT_BASIC_PLAN[0][1]),
    _buildRowInTable(MESSAGES.TABLE_DETAIL_BENEFIT_BASIC_PLAN[1][0], MESSAGES.TABLE_DETAIL_BENEFIT_BASIC_PLAN[1][1]),
    _buildRowInTable(MESSAGES.TABLE_DETAIL_BENEFIT_BASIC_PLAN[2][0], MESSAGES.TABLE_DETAIL_BENEFIT_BASIC_PLAN[2][1]),
    _buildRowInTable(MESSAGES.TABLE_DETAIL_BENEFIT_BASIC_PLAN[3][0], MESSAGES.TABLE_DETAIL_BENEFIT_BASIC_PLAN[3][1]),
    _buildRowInTable(MESSAGES.TABLE_DETAIL_BENEFIT_BASIC_PLAN[4][0], MESSAGES.TABLE_DETAIL_BENEFIT_BASIC_PLAN[4][1]),
    _buildRowInTable(MESSAGES.TABLE_DETAIL_BENEFIT_BASIC_PLAN[5][0], MESSAGES.TABLE_DETAIL_BENEFIT_BASIC_PLAN[5][1]),
  ]

  if (basicPlan.basicPlanCode !== VALUES.MRTA_CODE.MRTA_FULL) {
    results.push(
      [{ text: MESSAGES.TABLE_HEADER_RIDER_ACCIDENT, colSpan: 2, bold: true }],
      [
        { text: MESSAGES.TABLE_DETAIL_RIDER_ACCIDENT[0] },
        { text: `\n\n\n\n${MESSAGES.TABLE_DETAIL_RIDER_ACCIDENT[1]}`, alignment: 'center' },
      ],
      [{ text: MESSAGES.TABLE_DETAIL_RIDER_ACCIDENT[2], colSpan: 2 }]
    )
  }

  return results
}

const _buildDisclaimer = (basicPlan) => {
  return [
    {
      text: [`${MESSAGES.NOTE}: `, MESSAGES.REMARK_BENEFIT_TABLE_MRTA[0]],
    },
    {
      text: basicPlan.basicPlanCode !== VALUES.MRTA_CODE.MRTA_FULL ? MESSAGES.REMARK_BENEFIT_TABLE_MRTA[1] : '',
      margin: [36, 0, 0, 0],
    },
  ]
}

const _buildRowInTable = (messages, value) => {
  return [{ text: messages }, { text: value, alignment: 'center' }]
}
