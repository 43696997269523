export const RANGE_OPTIONS = [
  {
    text: '3 เดือน',
    value: '3M',
  },
  {
    text: '6 เดือน',
    value: '6M',
  },
  {
    text: '12 เดือน',
    value: '1Y',
  },
  {
    text: 'ทั้งหมด',
    value: 'All',
  },
]

export const MWP_PRODUCTS = [
  {
    productCode: 'iwealthy',
    azCode: 'RPUL',
    displayedText: 'iWealthy',
    categoryId: 'CATEGORY_EDUCATION',
    categoryPath: '/investment',
  },
  {
    productCode: 'iinvest',
    azCode: 'SPUL',
    displayedText: 'iInvest',
    categoryId: 'CATEGORY_EDUCATION',
    categoryPath: '/investment',
  },
  {
    productCode: 'ilink',
    azCode: 'RPUDR',
    displayedText: 'iLink',
    categoryId: 'CATEGORY_PROTECTION',
    categoryPath: '/investment',
  },
]

export const RISK_OPTIONS = [
  {
    minScore: 0,
    maxScore: 14,
    title: 'ระดับความเสี่ยง 1',
    description: 'ท่านสามารถลงทุนในกองทุนที่มีระดับความเสี่ยง 2-8 ได้ โดยอ้างอิงตัวอย่างคำแนะนำเรื่องการจัดสรรการลงทุน',
  },
  {
    minScore: 15,
    maxScore: 21,
    title: 'ระดับความเสี่ยง 1-4',
    description: 'ท่านสามารถลงทุนในกองทุนที่มีระดับความเสี่ยง 5-8 ได้ โดยอ้างอิงตัวอย่างคำแนะนำเรื่องการจัดสรรการลงทุน',
  },
  {
    minScore: 22,
    maxScore: 29,
    title: 'ระดับความเสี่ยง 1-5',
    description: 'ท่านสามารถลงทุนในกองทุนที่มีระดับความเสี่ยง 6-8 ได้ โดยอ้างอิงตัวอย่างคำแนะนำเรื่องการจัดสรรการลงทุน',
  },
  {
    minScore: 30,
    maxScore: 36,
    title: 'ระดับความเสี่ยง 1-7',
    description: 'ท่านสามารถลงทุนในกองทุนที่มีระดับความเสี่ยง 8 ได้ โดยอ้างอิงตัวอย่างคำแนะนำเรื่องการจัดสรรการลงทุน',
  },
  {
    minScore: 37,
    maxScore: 1000,
    title: 'ระดับความเสี่ยง 1-8',
    description: 'ท่านสามารถอ้างอิงตัวอย่างคำแนะนำเรื่องการจัดสรรการลงทุน',
  },
]

export const I_LINK = 'ilink'
export const I_INVEST = 'iinvest'
export const I_WEALTHY = 'iwealthy'

export const COVERAGE = 'COVERAGE'
export const RPQ = 'RPQ'
export const FUND_ALLOCATION = 'FUND_ALLOCATION'
export const INIT_PREFILL_OPTIONS = [COVERAGE, RPQ, FUND_ALLOCATION]

export const PREFILL_OPTIONS = [
  {
    text: '1. ข้อมูลเบี้ยเพื่อความคุ้มครอง RPP และ เบี้ยเพื่อการลงทุนเพิ่มเติมพิเศษ LSTU',
    value: COVERAGE,
    products: [I_LINK],
  },

  {
    text: '1. ข้อมูลเบี้ยประกันภัย',
    value: COVERAGE,
    products: [I_INVEST],
  },
  {
    text: '1. ข้อมูลเบี้ยเพื่อความคุ้มครอง RPP และเบี้ยเพื่อการลงทุนรายงวด RTU',
    value: COVERAGE,
    products: [I_WEALTHY],
  },
  {
    text: '2. ข้อมูลการทำแบบประเมินความเสี่ยง (RPQ)',
    value: RPQ,
    products: [I_LINK, I_INVEST, I_WEALTHY],
  },
  {
    text: '3. ข้อมูลที่ท่านทำการจัดสรรกองทุนไว้',
    value: FUND_ALLOCATION,
    products: [I_LINK, I_INVEST, I_WEALTHY],
  },
]
