// @flow

import { isIRetireProduct, isLifeRetireProduct } from 'core/service/basic-plan/benefit'
import MESSAGES from 'core/data-model/constants/bi-messages'
import CONSTANTS from 'core/data-model/constants/messages'
import { formatNumber } from 'core/service/lib/number-format'
import { buildStacks } from 'core/service/pdf-generation/utils/builder'

import { buildRetirementBenefitsSection } from 'core/service/pdf-generation/products/whole-life/sections/product-benefit-section'

import Mustache from 'mustache'
import type { DisplayProduct } from 'core/service/display-product'
import type { PolicyValue } from 'core/service/benefit-illustration/policy-value'

export const getProductSpecificBenefits = (basicPlan: DisplayProduct, sumAssured: number, policyValue: PolicyValue) => {
  if (isIRetireProduct(basicPlan.basicPlanCode) || isLifeRetireProduct(basicPlan.basicPlanCode)) {
    return buildRetirementBenefitsSection(basicPlan, sumAssured)
  } else {
    return []
  }
}

type Props = {
  basicPlan: DisplayProduct,
  calculatedCoveragePeriod: number,
  calculatedPaymentPeriod: number,
  sumAssured: number,
  basicPremium: number,
  productGraph: string,
  basicPlanDetailsLabelFn: (DisplayProduct) => string,
  policyValue: PolicyValue,
}

export const productBenefitsSection = (props: Props) => {
  const { basicPlan, sumAssured, productGraph, policyValue } = props
  const showGraph = productGraph !== ''
  const summarySection = getSummarySection({ ...props, showGraph })
  const productSpecificBeneftits = getProductSpecificBenefits(basicPlan, sumAssured, policyValue)
  return summarySection.concat(productSpecificBeneftits)
}

type SummarySectionProps = Props & {
  showGraph: boolean,
}

export const getSummarySection = ({
  basicPlanDetailsLabelFn,
  basicPlan,
  calculatedCoveragePeriod,
  calculatedPaymentPeriod,
  sumAssured,
  basicPremium,
  showGraph,
  productGraph,
}: SummarySectionProps) => {
  return [
    {
      pageBreak: 'before',
      text: basicPlanDetailsLabelFn(basicPlan),
      style: 'title',
    },
    {
      columns: buildStacks([
        {
          label: MESSAGES.COVERAGE_PERIOD,
          value: `${MESSAGES.COVERAGE_PERIOD_RETIREMENT_SUFFIX}`,
        },
        {
          label: MESSAGES.PAYMENT_PERIOD,
          value: `${calculatedPaymentPeriod} ${CONSTANTS.TIME_UNIT_YEAR}`,
        },
        {
          label: MESSAGES.BASIC_PLAN_SUM_ASSURED,
          value: `${formatNumber(sumAssured)} ${CONSTANTS.BAHT}`,
        },
        {
          label: MESSAGES.BASIC_PREMIUM,
          value: `${formatNumber(basicPremium, 2)} ${CONSTANTS.BAHT}`,
        },
      ]),
      style: 'info',
    },
    showGraph ? { image: productGraph, alignment: 'center', width: 425, margin: [0, 20, 0, 30] } : {},
  ]
}

export const basicPlanDetailsLabelFn = (basicPlan: DisplayProduct): string =>
  Mustache.render(MESSAGES.BASIC_PLAN_DETAILS, {
    name: basicPlan.basicPlanName,
    code: basicPlan.basicPlanCode,
  })
