// @flow
import { queryPremiumRateForMRTAAP as queryPremiumRate } from 'core/service/rider/query-premium-rate'
import { calculateRiderPremiumForMRTAAP as calculateRiderPremium } from 'core/service/rider/premium-calculation'
import { queryTaxRateForMRTAAP as queryTaxRate } from 'core/service/rider/query-tax-rate'

const getYearsOfCoverage = (selectedPlan: RiderPlan) => {
  return selectedPlan.planCode ? Number(selectedPlan.planCode.substring(1, 3)) : 0
}

export default {
  calculateRiderPremium,
  queryPremiumRate,
  queryTaxRate,
  getYearsOfCoverage,
}
