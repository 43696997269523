// @flow

import type { Rider, RiderState } from 'core/data-model/rider'
import RiderWithBenefitModal from './rider-benefit-modal'
import RiderBenefitSummary from './rider-benefit-summary'
import { AgentProtectionDisclaimer } from './agent-protection-disclaimer'
import MESSAGES from 'core/data-model/constants/bi-messages'

type WPTPDRiderBenefitProps = {
  rider: Rider & RiderState,
  sumAssure: number,
}

const WPTPDRiderBenefit = ({ rider, sumAssure }: WPTPDRiderBenefitProps) => {
  const description = `${MESSAGES.BENEFIT_TITLE} ${MESSAGES.RIDER}${rider.description}`
  const title = `${description} (${rider.name})`
  const benefitTable = (
    <div className="details-table">
      <p>{MESSAGES.WPTPD_BENEFIT}</p>
      {sumAssure > rider.defaultSumAssured ? <p className="remark"> {MESSAGES.PB_BENEFIT_REMARK} </p> : ''}
    </div>
  )
  const benefitRider: Rider & RiderState = { ...rider, description }
  return (
    <RiderWithBenefitModal id="wp-rider-benefit" className="rider wp-rider" title={title} modalChildren={benefitTable}>
      <RiderBenefitSummary rider={benefitRider} />
      {benefitTable}
      <AgentProtectionDisclaimer rider={rider} />
    </RiderWithBenefitModal>
  )
}

export default WPTPDRiderBenefit
