// @flow

import _ from 'lodash'
import PATH from 'quick-quote/constants/path'

import RiderGroup from 'quick-quote/product-common/coverage-plan/components/rider/rider-group'
import TotalPremiumInput from 'quick-quote/product-common/coverage-plan/components/basic-plan/total-premium-input'
import { Redirect } from 'react-router-dom'
import { InsuredInformationBreadcrumb } from 'quick-quote/components'

import BasicPlan from 'quick-quote/product-common/coverage-plan/components/basic-plan/basic-plan'
import CancerX5Selection from './cancerX5-selection'
import ModelFactorSelection from 'quick-quote/product-common/coverage-plan/components/basic-plan/model-factor-selection'
import LifeReadyProductSelection from './life-ready-product-selection'
import KoomverCoverProductSelection from './koomver-cover-selection'
import KoomverCompensateProductSelection from './koomver-compensate-selection'
import LifeTreasureProductSelection from './life-treasure-product-selection'

import type { RiderPlan } from 'core/data-model/rider'
import type { DisplayProduct } from 'core/service/display-product'

export type CoveragePlanProps = CoveragePlanState & CoveragePlanDispatchers

export type CoveragePlanState = {
  riderCodes: string[],
  canProceedToCoveragePlan: boolean,
  displayProduct: DisplayProduct,
}

export type CoveragePlanDispatchers = {
  toggleRider: (string, boolean) => void,
  editRiderSumAssured: (string, number) => void,
  editRiderSelectedPlan: (string, RiderPlan) => void,
}

const CoveragePlan = ({
  toggleRider,
  riderCodes,
  canProceedToCoveragePlan,
  displayProduct,
  editRiderSumAssured,
  editRiderSelectedPlan,
}: CoveragePlanProps) => {
  if (!canProceedToCoveragePlan) {
    return <Redirect to={PATH.PRODUCT_SELECTION} push />
  }
  let displayProductSelections = function(displayProduct) {
    let displayProductCode = _.get(displayProduct, 'code')
    switch (displayProductCode) {
      case 'CANCERX5-1':
      case 'CANCERX5-2':
      case 'CANCERX5-3':
        return <CancerX5Selection />
      case 'KOOMVER-COVER-1':
      case 'KOOMVER-COVER-2':
        return <KoomverCoverProductSelection />
      case 'KOOMVER-COMPENSATE-1':
      case 'KOOMVER-COMPENSATE-2':
        return <KoomverCompensateProductSelection />
      case 'H99F06A':
      case 'H99F12A':
      case 'H99F18A':
        return <LifeTreasureProductSelection />
      default:
        return <LifeReadyProductSelection />
    }
  }

  return (
    <div>
      <InsuredInformationBreadcrumb />
      <div id="coverage-plan">
        <div className="content">
          <div id="basic-plan">
            <div className="content">
              <ModelFactorSelection />
              {displayProductSelections(displayProduct)}
              <BasicPlan />
            </div>
          </div>
          <div id="total-premium">
            <TotalPremiumInput />
          </div>
          <RiderGroup
            riderCodes={riderCodes}
            toggleRider={toggleRider}
            editRiderSumAssured={editRiderSumAssured}
            editRiderSelectedPlan={editRiderSelectedPlan}
          />
        </div>
      </div>
    </div>
  )
}

export default CoveragePlan
