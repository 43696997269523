import { requestWrapper } from 'e-submission/domain/data'

export const getAdvisor = (user, id, type) => {
  return requestWrapper.getRequest(`advisors/${type}-${id}`, user).then(({ data }) => data)
}

export const getAdvisors = (user, codeStartWith, unit, type) => {
  return requestWrapper
    .getRequest('advisors', user, { code_start_with: codeStartWith, unit, type })
    .then(({ data }) => data)
}

export const getBranches = (user, codeStartWith) => {
  return requestWrapper
    .getRequest('advisors/branches', user, { code_start_with: codeStartWith })
    .then(({ data }) => data)
}
