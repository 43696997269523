const onOpenBrochure = (_url) => {
  try {
    if (window.cordova) {
      var ref = window.cordova.InAppBrowser.open(_url, '_system')
      ref.addEventListener('loaderror', function(event) {
        alert('ไม่สามารถเปิดได้ (' + event.message + ')')
      })
    } else {
      window.open(_url)
    }
  } catch (e) {}
}

export { onOpenBrochure }
