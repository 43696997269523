//@flow
import type { DistributorGroup } from 'core/data-model/distributor'
import { listDistributorGroups } from 'core/data-model/distributor'
import type { User } from 'core/data-model/identity'

export const getDistributorGroups = (): Promise<DistributorGroup[]> => listDistributorGroups()

export const getDistributorGroupForUser = (distributorGroups: DistributorGroup[], user: User): string => {
  if (user.type === 'Authenticated') {
    const agentType = user.profile.agent_type ? user.profile.agent_type : 'AGENT'
    const distributorGroupForUser = distributorGroups.find((distributorGroup) =>
      distributorGroup.agentTypes.includes(agentType)
    )
    return distributorGroupForUser ? distributorGroupForUser.id : 'AGENT'
  } else {
    return 'AGENT'
  }
}
