//@flow

import type { ResetQuoteForm } from '../actions'

export const VISIT_PATH = 'VISIT_PATH'
export type VisitPath = {
  type: 'VISIT_PATH',
  payload: string,
}
export const visitPath = (path: string): VisitPath => ({
  type: VISIT_PATH,
  payload: path,
})

export const SAVE_IMAGE = 'SAVE_IMAGE'
export type SaveImage = {
  type: 'SAVE_IMAGE',
  payload: {
    name: string,
    image: string,
  },
}
export const saveImage = (name: string, image: string): SaveImage => ({
  type: SAVE_IMAGE,
  payload: { name, image },
})

export const SET_LOADING_STATUS = 'SET_LOADING_STATUS'
export type SetLoadingStatus = {
  type: 'SET_LOADING_STATUS',
  payload: boolean,
}

export const setLoadingStatus = (status: boolean): SetLoadingStatus => ({
  type: SET_LOADING_STATUS,
  payload: status,
})

export const SET_ADDING_INVEST_QUESTION_NONILP_NEED_STATUS = 'SET_ADDING_INVEST_QUESTION_NONILP_NEED_STATUS'
export type SetAddingInvestmentQuestionForNonILPNeedType = {
  type: 'SET_ADDING_INVEST_QUESTION_NONILP_NEED_STATUS',
  payload: boolean,
}
export const setAddingInvestmentQuestionForNonILPNeedType = (
  status: boolean
): SetAddingInvestmentQuestionForNonILPNeedType => ({
  type: SET_ADDING_INVEST_QUESTION_NONILP_NEED_STATUS,
  payload: status,
})

export type Action =
  | VisitPath
  | ResetQuoteForm
  | SaveImage
  | SetLoadingStatus
  | SetAddingInvestmentQuestionForNonILPNeedType
