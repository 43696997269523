// @flow
import _ from 'lodash'
import type { Effect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import VALUES from 'core/data-model/constants/values'

import { populateAvailableRiders } from 'quick-quote/product-common/coverage-plan/sagas'
import { getDisplayProducts, getSelectedDisplayProductCode } from 'quick-quote/product-selection/selectors'
import { getSumAssured } from 'quick-quote/product-common/coverage-plan/selectors'
import {
  editSumAssured,
  editBasicPremiumState,
  UPDATE_SUM_ASSURED$,
} from 'quick-quote/product-common/coverage-plan/actions'

import type {
  SelectProductLifeReady,
  SelectProductCancerX5,
  SelectProductKoomverCover,
  SelectProductKoomverCompensate,
  SelectProductLifeTreasure,
} from './actions'
import {
  SELECT_PRODUCT_LIFE_READY,
  selectBasicPlan,
  SELECT_PRODUCT_CANCERX5,
  SELECT_PRODUCT_KOOMVER_COVER,
  SELECT_PRODUCT_KOOMVER_COMPENSATE,
  SELECT_PRODUCT_LIFE_TREASURE,
} from './actions'

const _isLifeReadyMultiPlanGroup = (productCode: string) =>
  _.includes(VALUES.LIFE_READY_LIST_PRODUCT_MULTI_PLANCODE, productCode)
const _isLifeReady18Group = (productCode: string) => _.includes(VALUES.LIFE_READY_18_GROUP, productCode)
const _isLifeReady99Group = (productCode: string) => _.includes(VALUES.LIFE_READY_99_GROUP, productCode)

const _getLifeReadyProductGroupCode = (productCode: string) => {
  if (_isLifeReady18Group(productCode)) {
    return VALUES.LIFE_READY_18_GROUP
  } else if (_isLifeReady99Group(productCode)) {
    return VALUES.LIFE_READY_99_GROUP
  }
}

const _isValidSumAssured = (entrySumAssured, sumAssured) => {
  const { minimum, maximum } = entrySumAssured
  return !(sumAssured < minimum || sumAssured > maximum)
}

export const switchProductBySumAssured = function*(action: *): Generator<*, *, *> {
  const productCode = yield select(getSelectedDisplayProductCode)
  if (!_isLifeReadyMultiPlanGroup(productCode)) return

  const sumAssured = action.payload.value
  const products = yield select(getDisplayProducts)
  const productGroupCodes = _getLifeReadyProductGroupCode(productCode)
  const productGroup = _.filter(products, (product) => _.includes(productGroupCodes, _.get(product, 'code')))
  const product = _.find(productGroup, (product) => _isValidSumAssured(_.get(product, 'entrySumAssured'), sumAssured))
  if (product !== undefined && productCode !== _.get(product, 'code')) {
    yield put(selectBasicPlan(product))
    yield put(editSumAssured(sumAssured))
    yield call(populateAvailableRiders)
  }
}

export const onUpdateSelectedLifeReady = function*(action: SelectProductLifeReady): Generator<*, *, *> {
  const displayProducts = yield select(getDisplayProducts)
  const sumAssured = yield select(getSumAssured)
  const productSelected = _.find(displayProducts, { code: action.payload })
  if (!!productSelected) {
    yield put(selectBasicPlan(productSelected))
    yield put(editSumAssured(sumAssured))
    yield put(editBasicPremiumState(-1))
  }
}

export const onUpdateSelectedLifeTreasure = function*(action: SelectProductLifeTreasure): Generator<*, *, *> {
  const displayProducts = yield select(getDisplayProducts)
  const sumAssured = yield select(getSumAssured)
  const productSelected = _.find(displayProducts, { code: action.payload })
  if (!!productSelected) {
    yield put(selectBasicPlan(productSelected))
    yield put(editSumAssured(sumAssured))
    yield put(editBasicPremiumState(-1))
  }
}

export const onUpdateSelectedCancerX5 = function*(action: SelectProductCancerX5): Generator<*, *, *> {
  const displayProducts = yield select(getDisplayProducts)
  const productSelected = _.find(displayProducts, { code: action.payload })
  if (!!productSelected) {
    yield put(selectBasicPlan(productSelected))
    yield put(editSumAssured(productSelected.entrySumAssured.minimum))
    yield call(populateAvailableRiders)
  }
}

export const onUpdateSelectedKoomverCover = function*(action: SelectProductKoomverCover): Generator<*, *, *> {
  const displayProducts = yield select(getDisplayProducts)
  const productSelected = _.find(displayProducts, { code: action.payload })
  if (!!productSelected) {
    yield put(selectBasicPlan(productSelected))
    yield put(editSumAssured(productSelected.entrySumAssured.minimum))
    yield call(populateAvailableRiders)
  }
}

export const onUpdateSelectKoomverCompensate = function*(action: SelectProductKoomverCompensate): Generator<*, *, *> {
  const displayProducts = yield select(getDisplayProducts)
  const productSelected = _.find(displayProducts, { code: action.payload })
  if (!!productSelected) {
    yield put(selectBasicPlan(productSelected))
    yield put(editSumAssured(productSelected.entrySumAssured.minimum))
    yield call(populateAvailableRiders)
  }
}

export function* watchers(): Generator<*, *, Effect[]> {
  yield [
    takeLatest([SELECT_PRODUCT_LIFE_READY], onUpdateSelectedLifeReady),
    takeLatest([SELECT_PRODUCT_CANCERX5], onUpdateSelectedCancerX5),
    takeLatest([SELECT_PRODUCT_KOOMVER_COVER], onUpdateSelectedKoomverCover),
    takeLatest([SELECT_PRODUCT_KOOMVER_COMPENSATE], onUpdateSelectKoomverCompensate),
    takeLatest([SELECT_PRODUCT_LIFE_TREASURE], onUpdateSelectedLifeTreasure),
    takeLatest([UPDATE_SUM_ASSURED$], switchProductBySumAssured),
  ]
}

export const sagas = watchers
