// @flow
import { compact } from 'lodash/fp'
import _ from 'lodash'
import BIMESSAGES from 'core/data-model/constants/bi-messages'
import LegalDisclaimerMarketConduct from 'quick-quote/product-common/benefit-illustration/components/legal-disclaimer-market-conduct'
import ProfileSnapshot from 'quick-quote/product-common/benefit-illustration/components/profile-summary/profile-snapshot'
import ProductBenefits from 'quick-quote/product-whole-life/benefit-illustration/components/product-benefits/product-benefits'
import RiderBenefits from 'quick-quote/product-common/benefit-illustration/components/rider-benefits'
import BenefitSummaryTable from 'quick-quote/product-whole-life/benefit-illustration/components/benefit-summary-table'
import PolicyValueTable from './components/layout-with-cash-drop/policy-value-table'
import TaxConsentDeductionBenefit from 'quick-quote/product-common/benefit-illustration/components/tax-consent-deduction-benefit'

import { getProfileSnapshot } from 'quick-quote/product-common/benefit-illustration/components/profile-summary/selectors'
import {
  getLegalDisclaimerEndDate,
  getTaxDeduction,
} from 'quick-quote/product-whole-life/benefit-illustration/selectors'
import { getcoveragePlanStartDate, getSelectedRiders } from 'quick-quote/product-common/coverage-plan/selectors'
import { getProductBenefits } from 'quick-quote/product-whole-life/benefit-illustration/components/product-benefits/selectors'
import { getPolicyValueTableBenefits } from 'quick-quote/v2/components/benefit-illustration/policy-values/layout-with-cash-drop/selectors'
import type { AppState } from 'quick-quote/reducers'

export type { DisplayProduct } from 'core/service/display-product'

const _summaryHeader = ({ displayProduct }) => _.get(displayProduct, 'name', '').replace(/ \(PLB.*/, '')

export const getSections = (state: AppState) => {
  const riders = getSelectedRiders(state)
  return compact([
    {
      component: ProfileSnapshot,
      props: {
        ...getProfileSnapshot(state),
        summaryTableHeader: _summaryHeader,
      },
    },
    {
      component: ProductBenefits,
      props: {
        ...getProductBenefits(state),
      },
    },
    riders.length > 0 && {
      component: RiderBenefits,
    },
    {
      component: PolicyValueTable,
      props: {
        ...getPolicyValueTableBenefits(state),
      },
    },
    {
      component: BenefitSummaryTable,
    },
    {
      component: TaxConsentDeductionBenefit,
      props: {
        taxDeduction: getTaxDeduction(state),
      },
    },
    {
      component: LegalDisclaimerMarketConduct,
      props: {
        startDate: getcoveragePlanStartDate(state),
        endDate: getLegalDisclaimerEndDate(state),
      },
    },
  ])
}

export const getMenus = (state: AppState) => [
  {
    text: BIMESSAGES.SUMMARY_OF_MAIN_AND_ADDITIONAL_CONTRACTS,
    id: 'profile-summary',
    isSub: false,
  },
  {
    text: 'รายละเอียดของสัญญาหลัก',
    id: 'product-benefits',
    isSub: false,
  },
  {
    text: BIMESSAGES.POLICY_VALUE_TABLE,
    id: 'policy-value',
    isSub: false,
  },
  {
    text: BIMESSAGES.BENEFIT_SUMMARY,
    id: 'benefit-summary',
    isSub: false,
  },
  {
    text: BIMESSAGES.LEGAL_DISCLAIMER,
    id: 'legal-disclaimer-market-conduct',
    isSub: false,
  },
]
