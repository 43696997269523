// @flow

import _ from 'lodash'
import Mustache from 'mustache'
import { isNil } from 'core/service/lib/type-check'
import type { DisplayProduct } from 'core/service/display-product'
import type { Rider, RiderState, RiderModule } from 'core/data-model/rider'
import type { SummaryRowProps } from './summary-row'
import SummaryRow from './summary-row'
import SummaryFooter from './summary-footer'
import MESSAGES from 'core/data-model/constants/messages'
import BIMESSAGES from 'core/data-model/constants/bi-messages'
import values from 'core/data-model/constants/values'
import { isFlexiHealthModuleGroupOne, translatePlanToThai } from 'core/service/rider/benefits/benefit-data/fh'
import {
  getRiderYearsOfCoverage,
  getRiderYearsOfPayment,
  hasAsteriskFromRiders,
} from 'core/service/rider/benefits/benefit-data'

type SummaryTableProps = {
  displayProduct: ?DisplayProduct,
  riders: (Rider & RiderState)[],
  totalPremium: number,
  calculatedCoveragePeriod: number,
  calculatedPaymentPeriod: number,
  basicPremium: number,
  basicSumAssured: number,
  hasNonLevelRider: boolean,
  summaryTableHeader: Function | string,
}

const SummaryTable = ({
  displayProduct,
  riders,
  calculatedCoveragePeriod,
  calculatedPaymentPeriod,
  basicPremium,
  basicSumAssured,
  totalPremium,
  hasNonLevelRider,
  summaryTableHeader,
}: SummaryTableProps) => {
  if (isNil(displayProduct)) {
    return
  }

  return (
    <div className="summary-table d-none d-md-block">
      <div className="details-table">
        <h3>{BIMESSAGES.SUMMARY_OF_MAIN_AND_ADDITIONAL_CONTRACTS}</h3>
        <table id="summary-table">
          <thead>
            <tr>
              <td>
                {BIMESSAGES.BASIC_PLAN}/{BIMESSAGES.RIDER}
              </td>
              <td>
                {BIMESSAGES.COVERAGE_PERIOD} ({MESSAGES.TIME_UNIT_YEAR})
              </td>
              <td>
                {BIMESSAGES.PAYMENT_PERIOD} ({MESSAGES.TIME_UNIT_YEAR})
              </td>
              <td>
                {BIMESSAGES.SUM_ASSURED} ({MESSAGES.BAHT})
              </td>
              <td>
                {BIMESSAGES.PREMIUM} ({MESSAGES.BAHT})
              </td>
            </tr>
          </thead>
          <tbody>
            {renderBasicPlanSummaryRow(
              displayProduct,
              calculatedCoveragePeriod,
              calculatedPaymentPeriod,
              basicSumAssured,
              basicPremium,
              summaryTableHeader
            )}
            {renderRiderSummaryRows(riders)}
            <SummaryFooter totalPremium={totalPremium} />
          </tbody>
        </table>
        <div className="disclaimer">
          <ul className="list-unstyled">
            {hasAsteriskFromRiders(riders) ? <li>* {BIMESSAGES.DISCLAIMER_RIDER_PREMIUM}</li> : ''}
            <li>{BIMESSAGES.FLEXI_SUMMARY_TABLE_DISCLAIMER_2}</li>
            <br />
            <div className="disclaimerSummaryBox">
              <li>{BIMESSAGES.FLEXI_SUMMARY_TABLE_DISCLAIMER_3}</li>
              <li>{BIMESSAGES.FLEXI_SUMMARY_TABLE_DISCLAIMER_4}</li>
            </div>
          </ul>
        </div>
      </div>
    </div>
  )
}

const renderBasicPlanSummaryRow = (
  displayProduct: DisplayProduct,
  calculatedCoveragePeriod: number,
  calculatedPaymentPeriod: number,
  basicSumAssured: number,
  basicPremium: number,
  summaryTableHeader: Function | string
) => {
  const productName = _.isFunction(summaryTableHeader)
    ? // $$FlowFixMe
      summaryTableHeader({
        displayProduct,
        calculatedCoveragePeriod,
        calculatedPaymentPeriod,
        basicSumAssured,
        basicPremium,
      })
    : summaryTableHeader

  return (
    <SummaryRow
      id={displayProduct.code}
      productCode={displayProduct.basicPlanCode}
      productName={
        // $$FlowFixMe
        productName
      }
      selectedPlan={{ planCode: '' }} // not applicable for basic plan
      coverageYear={calculatedCoveragePeriod}
      paymentPeriod={calculatedPaymentPeriod}
      premium={basicPremium}
      sumAssured={basicSumAssured}
      showSumAssuredDecimalPlaces={false}
    />
  )
}

const renderRiderProductNameSummaryRows = (rider: Rider & RiderState, plan) =>
  Mustache.render(BIMESSAGES.FLEXI_HEALTH_SUMMARY_PREFIX_FH, {
    plan: translatePlanToThai(plan),
    description: _.get(rider, 'selectedPlan.description'),
  })

const renderModuleProductNameSummaryRows = (riderModule: RiderModule, plan) => {
  return isFlexiHealthModuleGroupOne(riderModule.code)
    ? Mustache.render(BIMESSAGES.FLEXI_HEALTH_SUMMARY_PREFIX_MODULE_ONE, {
        plan: translatePlanToThai(plan),
        description: _.get(riderModule, 'description'),
      })
    : Mustache.render(BIMESSAGES.FLEXI_HEALTH_SUMMARY_PREFIX_MODULE_TWO, {
        plan: translatePlanToThai(plan),
        description: _.get(riderModule, 'description'),
      })
}

const tranformRiderToSummaryRowProps = (rider: Rider & RiderState): SummaryRowProps => {
  return {
    id: rider.code,
    productCode: rider.selectedPlan.planCode,
    productName: renderRiderProductNameSummaryRows(rider, rider.selectedPlan.plan),
    asterisks: rider.premiumStructure === values.PREMIUM_NON_LEVEL ? '**' : '',
    coverageYear: getRiderYearsOfCoverage(rider),
    paymentPeriod: getRiderYearsOfPayment(rider, { hasAsterisk: true }),
    selectedPlan: {
      ...rider.selectedPlan,
      planCode: rider.package ? '' : _.get(rider, 'selectedPlan.planCode'),
    },
    sumAssured: rider.showPlanAsSumAssured ? rider.selectedPlan.plan : rider.sumAssured,
    showSumAssuredDecimalPlaces: rider.showSumAssuredDecimalPlaces,
    premium: rider.premium,
  }
}

const tranformModuleToSummaryRowProps = (rider: Rider & RiderState, riderModule: RiderModule): SummaryRowProps => {
  return {
    id: rider.code,
    productCode: riderModule.code,
    productName: renderModuleProductNameSummaryRows(riderModule, rider.selectedPlan.plan),
    asterisks: rider.premiumStructure === values.PREMIUM_NON_LEVEL ? '**' : '',
    coverageYear: getRiderYearsOfCoverage(rider),
    paymentPeriod: getRiderYearsOfPayment(rider, { hasAsterisk: true }),
    selectedPlan: {
      ...rider.selectedPlan,
      planCode: rider.package ? '' : _.get(rider, 'selectedPlan.planCode'),
    },
    sumAssured: rider.showPlanAsSumAssured ? rider.selectedPlan.plan : rider.sumAssured,
    showSumAssuredDecimalPlaces: rider.showSumAssuredDecimalPlaces,
    premium: riderModule.premium,
  }
}

export const getFlattenRidersAndModules = (riders: (Rider & RiderState)[]): SummaryRowProps[] => {
  const flattenRidersAndModules = []
  riders.forEach((rider) => {
    flattenRidersAndModules.push(tranformRiderToSummaryRowProps(rider))
    if (_.get(rider, 'selectedPlan.modules.length') > 0) {
      rider.selectedPlan.modules.forEach((riderModule) => {
        if (riderModule.isSelected) {
          flattenRidersAndModules.push(tranformModuleToSummaryRowProps(rider, riderModule))
        }
      })
    }
  })

  return flattenRidersAndModules
}

const renderRiderSummaryRows = (riders: (Rider & RiderState)[]) => {
  if (isNil(riders)) return

  // $$FlowFixMe
  const flattenRidersAndModules = getFlattenRidersAndModules(riders)

  return flattenRidersAndModules.map((r, n) => {
    return (
      // $$FlowFixMe
      <SummaryRow
        key={`summary-row-${r.id}-${n}`}
        id={r.id}
        productCode={r.productCode}
        productName={r.productName}
        asterisks={r.asterisks}
        coverageYear={r.coverageYear}
        paymentPeriod={r.paymentPeriod}
        selectedPlan={r.selectedPlan}
        sumAssured={r.sumAssured}
        showSumAssuredDecimalPlaces={r.showSumAssuredDecimalPlaces}
        premium={r.premium}
      />
    )
  })
}

export default SummaryTable
