import { connect } from 'react-redux'
import { compose } from 'recompose'
import { FormFeedback, Button } from 'reactstrap'
import styled, { css } from 'styled-components'
import { Flex } from 'rebass'

import { backgroundAsBorderMixin } from '../mixins'
import Card from 'e-submission/components/Card'
import Icon from 'e-submission/components/Icon'
import { previewPDF } from 'e-submission/apps/actions'
import { Side, Description } from './DocumentCard'

/**
 * UnsignedDocument
 *
 * View the unsigned document that doesn't require the signature
 */
const UnsignedDocument = styled(
  ({ hasBorder, hasBackground, description, name, policyId, infoMessage, disabled, onPreview, ...props }) => {
    const Content = () => {
      return (
        <Flex column {...props}>
          <Flex flex={1}>
            {!disabled && (
              <Side>
                <Icon.check className="icon-check" />
              </Side>
            )}
            <Description description={description} />
            <Side align="flex-end">
              <Button disabled={disabled} color={disabled ? 'disabled' : 'primary'} outline onClick={onPreview}>
                ดู
              </Button>
            </Side>
          </Flex>
          {infoMessage && (
            <FormFeedback className="info-message attached-icon">
              <p>{infoMessage}</p>
            </FormFeedback>
          )}
        </Flex>
      )
    }
    return hasBackground ? (
      <Card column style={{ border: 'none' }} w={1} pl={[0, 2, 2]} pr={[0, 2, 2]} {...props}>
        <Content />
      </Card>
    ) : (
      <Content />
    )
  }
)`
  & + & {
    padding-top: 10px;
    ${backgroundAsBorderMixin};
    background-size: 100% 1px;
    background-position-y: 5px;
  }
  ${({ hasBorder }) =>
    hasBorder &&
    css`
      padding-${hasBorder}: 10px;
      ${backgroundAsBorderMixin};
      background-size: 100% 1px;
      background-position-x: 0;
    `} svg {
    width: 48px;
    fill: ${({ theme }) => theme.variables['$brand-submitted']};
  }
  button {
    background: white;
    margin-bottom: 0;
    border-color: ${({ theme, disabled }) =>
      disabled ? theme.variables['$color-silver'] : theme.variables['$color-primary-blue']};
  }
  .icon-check {
    align-self: center;
  }
`

export default compose(
  connect(
    (state) => ({}),
    (dispatch, { name, policyId, description }) => ({
      onPreview: () => {
        dispatch(
          previewPDF({
            name,
            policyId,
            readOnly: true,
            text: description,
          })
        )
      },
    })
  )
)(UnsignedDocument)
