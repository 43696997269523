import { compose, withHandlers, mapProps, renameProp } from 'recompose'
import { omit, get } from 'lodash/fp'
import styled from 'styled-components'
import { Flex, Box } from 'rebass'

import createNumberInput from 'e-submission/components/Form/Input/NumberInput'
import createDropdown from './index'

const UNITS = [
  {
    text: 'วัน',
    value: 'day',
  },
  {
    text: 'สัปดาห์',
    value: 'week',
  },
  {
    text: 'เดือน',
    value: 'month',
  },
  {
    text: 'ปี',
    value: 'year',
  },
]

const BoxContainer = styled(Box)`
  & + & {
    margin-left: 0.5rem;
  }
`

const hoc = mapProps(omit(['setValue', 'unsetValue', 'removeValue', 'dispatch', 'allow', 'validate']))
const NumberInput = createNumberInput(hoc)
const Dropdown = createDropdown(hoc)

export const Component = ({ validateInput, placeHolder, value, onTextBoxChange, onDropdownChange }) => {
  const unit = get('unit', value)
  const number = get('value', value)
  return (
    <Flex>
      <BoxContainer flex="1 1 100%">
        <NumberInput validate={validateInput} format={'###'} value={number} setValue={onTextBoxChange(unit)} />
      </BoxContainer>
      <BoxContainer flex="1 1 100%">
        <Dropdown placeHolder={placeHolder} value={unit} options={UNITS} setValue={onDropdownChange(number)} />
      </BoxContainer>
    </Flex>
  )
}

export default (hoc) =>
  compose(
    renameProp('validate', 'validateInput'),
    withHandlers({
      onTextBoxChange: ({ setValue }) => (unit) => (value) => {
        setValue({
          value,
          unit,
        })
      },
      onDropdownChange: ({ setValue }) => (value) => (unit) => {
        setValue({
          value,
          unit,
        })
      },
    }),
    hoc
  )(Component)
