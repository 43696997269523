// @flow
import _ from 'lodash'

import PolicyValueRow, { dashedOutOrWithPrecision } from './policy-value-row'
import MESSAGES from 'core/data-model/constants/bi-messages'
import CONSTANTS from 'core/data-model/constants/messages'
import type { PolicyValueTableProps } from 'core/service/benefit-illustration/policy-value'
import { _getMessageUnderiShieldPolicyValueTable } from 'core/service/benefit-illustration/policy-value'
import Mustache from 'mustache'

export const IShieldMessageUnderTable = ({ policyValue, basicPlan }: PolicyValueTableProps) => {
  const message = _getMessageUnderiShieldPolicyValueTable({
    policyValue,
    basicPlan,
  })
  if (message) {
    return <div className="ishield-conditional-info">{message}</div>
  } else {
    return null
  }
}

const PolicyValueTable = (props: PolicyValueTableProps) => {
  return (
    <div className="policy group" id="policy-value">
      <h3>{MESSAGES.POLICY_VALUE_TABLE}</h3>

      {renderPolicyValueTableForWholeLife(props)}
    </div>
  )
}

const PolicyTable = ({ className, children }: { className?: string, children?: React.Node }) => (
  <table className={`policy-table${className ? ` ${className}` : ''}`} id="policy-value-table">
    {children}
  </table>
)

// $$FlowFixMe
export const PolicyWithCashDrop = ({
  yearEndPolicyValues,
  lastYearEndPolicy,
  subtotalAnnualBasicPremium,
  subtotalAnnualRiderPremium,
  grandTotalPremium,
  subtotalCashDrop,
  subtotalLowSavingCashDrop,
  subtotalMediumSavingCashDrop,
  subtotalHighSavingCashDrop,
}) => (
  <PolicyTable className="with-cash-drop">
    <thead>
      <tr>
        <td rowSpan={3} width="5%">
          {CONSTANTS.AGE}
        </td>
        <td className="nowrap" rowSpan={3} width="5%">
          {MESSAGES.END_OF_THE_YEAR}
        </td>
        <td width="5%" rowSpan={3}>
          {MESSAGES.CUMULATIVE_PREMIUM}
        </td>
        <td colSpan={3}>{MESSAGES.PREMIUM_PER_YEAR}</td>
        <td colSpan={2}>
          {MESSAGES.LIFE_SAVE_PRO_CASH_DROP_VALUE_1}
          <br />
          {MESSAGES.LIFE_SAVE_PRO_CASH_DROP_VALUE_2}
          <br />
          {MESSAGES.END_OF_YEAR_POLICY_WITH_BRACKET}
        </td>
        <td rowSpan={2} width="11%">
          {MESSAGES.DEATH_BENEFIT}
          <br />
          {MESSAGES.END_OF_YEAR_POLICY_WITH_BRACKET}
        </td>
        <td rowSpan={2} width="">
          {MESSAGES.LIFE_SAVE_PRO_CUMULATIVE_DEATH_BENEFIT}
        </td>
        <td rowSpan={2} width="11%">
          {MESSAGES.LIFE_SAVE_PRO_CASH_SURRENDER_VALUE}
          <br />
          {MESSAGES.END_OF_YEAR_POLICY_WITH_BRACKET}
        </td>
      </tr>
      <tr>
        <td width="10%">{MESSAGES.BASIC_PLAN}</td>
        <td width="10%">{MESSAGES.RIDER_2_STAR}</td>
        <td width="10%">{MESSAGES.TOTAL}</td>
        <td width="11%">{MESSAGES.INCASE_OF_CASH_BACK}</td>
        <td width="11%">
          {MESSAGES.LIFE_SAVE_PRO_INCASE_WITH_COMPANY}
          <br />
          {Mustache.render(MESSAGES.PERCENTS_PER_YEAR, { percent: '0.5' })}
        </td>
        <td></td>
      </tr>
    </thead>
    <tbody>
      {yearEndPolicyValues.map((policyValue, index) => (
        <PolicyValueRow
          key={`policy-value-row-${policyValue.age.value}`}
          age={policyValue.age}
          yearEndPolicy={policyValue.yearEndPolicy}
          cumulativeBasicPremium={policyValue.cumulativeBasicPremium}
          annualBasicPremium={policyValue.annualBasicPremium}
          annualRiderPremium={policyValue.annualRiderPremium}
          annualTotalPremium={policyValue.annualTotalPremium}
          surrenderCash={policyValue.surrenderCash}
          deathBenefit={policyValue.deathBenefit}
          cumulativeDeathBenefit={policyValue.cumulativeDeathBenefit}
          basicSumAssured={policyValue.basicSumAssured}
        >
          <td>
            {// $$FlowFixMe
            dashedOutOrWithPrecision(policyValue.cashDrop, 0, true)}
          </td>
          <td>
            {// $$FlowFixMe
            dashedOutOrWithPrecision(policyValue.lowSavingCashDrop, 0, true)}
          </td>
        </PolicyValueRow>
      ))}
      <tr className="total" id={`policy-value-row-${lastYearEndPolicy}`} key={`policy-value-row-${lastYearEndPolicy}`}>
        <td />
        <td />
        <td />
        <td>{dashedOutOrWithPrecision(subtotalAnnualBasicPremium, 2)}</td>
        <td>{dashedOutOrWithPrecision(subtotalAnnualRiderPremium, 2)}</td>
        <td>{dashedOutOrWithPrecision(grandTotalPremium, 2)}</td>
        <td>{dashedOutOrWithPrecision(subtotalCashDrop, 0, true)}</td>
        <td>{dashedOutOrWithPrecision(subtotalLowSavingCashDrop, 0, true)}</td>
        <td />
        <td />
        <td />
      </tr>
    </tbody>
  </PolicyTable>
)

const PolicyValueContent = ({ basicPlan, ...props }) => {
  return <PolicyWithCashDrop {...props} />
}

const renderPolicyValueTableForWholeLife = ({ policyValue, basicPlan }: PolicyValueTableProps) => {
  const lastYearEndPolicy = _.get(_.last(policyValue.yearEndPolicyValues), 'yearEndPolicy', 0) + 1

  // $$FlowFixMe
  const policyValueTableProps = { policyValue, basicPlan }

  return (
    <div>
      <div className="table-wrap">
        <PolicyValueContent lastYearEndPolicy={lastYearEndPolicy} basicPlan={basicPlan} {...policyValue} />
      </div>

      <IShieldMessageUnderTable {...policyValueTableProps} />

      {/* <div className="disclaimer">
        <div>{MESSAGES.NOTE}:</div>
        <div>
          <ul>
            <li>{MESSAGES.LIFE_SAVE_PRO_POLICY_VALUE_TABLE_NOTE_1}</li>
            <li>{MESSAGES.LIFE_SAVE_PRO_POLICY_VALUE_TABLE_NOTE_2}</li>
            <li>
              {MESSAGES.LIFE_SAVE_PRO_POLICY_VALUE_TABLE_NOTE_3}
              {MESSAGES.LIFE_SAVE_PRO_POLICY_VALUE_TABLE_NOTE_4}
              {MESSAGES.LIFE_SAVE_PRO_POLICY_VALUE_TABLE_NOTE_5}
            </li>
          </ul>
        </div>
      </div> */}
    </div>
  )
}

export default PolicyValueTable
