import { compact, flow, map } from 'lodash/fp'

import { appendBefore, appendAfter, concatWith, createPageCreator } from 'e-submission/domain/data-model/form/utils'

import baseForm from 'e-submission/domain/data-model/form'
import { insuredHealth, payerHealth } from 'e-submission/domain/data-model/form/pages/health'
import { submit } from 'e-submission/domain/data-model/form/pages/submit'
import familyDiscountSection from 'e-submission/domain/data-model/form/familyDiscountSection'
import signature from './signature'
import { isQuickQuoteFamilyDiscount, isSelectedRemoteSelling } from 'e-submission/domain/data-model/form/selectors'
import { videoConsent } from '../../form/pages/videoConsent'
import { remotePayment } from '../../form/pages/remotePayment'
import { remoteSignature } from '../../form/pages/remoteSignature'

export default (app) => {
  const createPage = createPageCreator(app)
  const selectedRemoteSelling = app && app.quickQuote ? isSelectedRemoteSelling(app) : false

  return flow(
    baseForm,
    map(createPage),
    concatWith(
      selectedRemoteSelling
        ? [
            createPage(signature(app)),
            createPage(videoConsent(app)),
            createPage(remotePayment(app)),
            createPage(remoteSignature(app)),
            createPage(submit(null, selectedRemoteSelling, app)),
          ]
        : [createPage(signature(app)), createPage(submit())]
    ),
    appendBefore(null, 'FATCA', [createPage(insuredHealth(app)), createPage(payerHealth(app))]),
    appendAfter(
      null,
      'beneficiary',
      compact([
        isQuickQuoteFamilyDiscount(app) &&
          createPage({
            id: 'family-discount',
            prefix: 'family',
            title: 'ส่วนลดครอบครัว',
            label: 'ส่วนลดครอบครัว',
            sections: [familyDiscountSection('family', 'สมาชิกครอบครัว')],
          }),
      ])
    ),
    compact
  )(app)
}
