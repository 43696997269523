import styled from 'styled-components'
import { Flex, Box } from 'rebass'
import Card from './index'
import Icon, { getIcon } from 'e-submission/components/Icon'

const EdaCardTitle = () => {
  return (
    <>
      <strong className="eda-title">(รองรับการใช้งาน KTB Next เท่านั้น)</strong>
    </>
  )
}

const MoreInfoCard = styled(
  ({ className, children, title, icon, indentTitle, disabled, isWarningEdaPaymentNeeded, ...props }) => {
    return (
      <Card className={className} w={1} column disabled={disabled} {...props}>
        <Flex align="center">
          <Flex className="icon-left">{icon && getIcon(icon)}</Flex>
          {indentTitle && <Flex className="indent" />}
          <Box flex="auto">
            <strong className="title">{title}</strong>
            {isWarningEdaPaymentNeeded ? (
              <>
                <br />
                <EdaCardTitle />
              </>
            ) : null}
          </Box>
          {!disabled && <Icon.arrowRight className="arrowRight" />}
        </Flex>
        {children}
      </Card>
    )
  }
)`
  padding: 10px 20px;
  &:hover {
    cursor: pointer;
  }
  .arrowRight {
    color: ${({ theme }) => theme.variables['$brand-primary']};
  }
  .icon-left svg {
    max-width: 22px;
    max-height: 30px;
    margin-left: -3px;
    margin-right: 5px;
  }
  .title {
    color: ${({ theme, disabled }) => disabled && theme.variables['$color-dot-grey']};
  }
  .indent {
    padding-left: ${({ icon }) => (icon ? 0 : `25px`)};
  }

  .eda-title {
    color: ${({ theme, disabled }) => (disabled ? theme.variables['$color-dot-grey'] : '#ffbf00')};
  }
`

export default MoreInfoCard
