//@flow
import { isArray, isObject, isString } from 'core/service/lib/type-check'
import type {
  Benefit,
  DisplayType,
} from 'core/service/pdf-generation/products/common/sections/riders/ap-ecare-benefit-section'
import type { Benefit as DomainBenefit } from 'core/data-model/rider/benefits'

export const convertBenefitData = (benefits: DomainBenefit[]): Benefit[] => {
  return benefits.map((benefit, index) => {
    if (isObject(benefit) && isString(benefit.value)) {
      const disclaimer = benefit.disclaimer ? benefit.disclaimer : ''
      return {
        description: benefit.description,
        value: benefit.value,
        type: 'VALUE',
        disclaimer: disclaimer,
        displayType: 'PlainText',
      }
    } else if (isObject(benefit) && isArray(benefit.subBenefits)) {
      const disclaimer = benefit.disclaimer ? benefit.disclaimer : ''
      return {
        description: benefit.description,
        subBenefits: convertBenefitData(benefit.subBenefits),
        type: 'MULTIPLE',
        disclaimer: disclaimer,
        displayType: indexMappingStyle(index),
      }
    }
    return {
      description: '',
      value: '',
      type: 'VALUE',
      disclaimer: '',
      displayType: 'PlainText',
    }
  })
}

export const indexMappingStyle = (index: number): DisplayType => {
  switch (index) {
    case 0:
      return 'BenefitTable'
    case 1:
      return 'BurnsTable'
    case 2:
      return 'Columns'
    default:
      return 'PlainText'
  }
}
