import { haveThirdPerson } from 'e-submission/apps/selectors'
import { signature as commonSignature } from 'e-submission/domain/data-model/form/pages/signature'
import {
  hasWealthHubSignPage,
  havePayer,
  isSelectedRemoteSelling,
  isRemoteSelling,
  isNeedDocCRS,
} from 'e-submission/domain/data-model/form/selectors'
import {
  isInsuredUseCitizenId,
  isInsuredUsePassport,
  isPayerUseCitizenId,
  isPayerUsePassport,
} from 'e-submission/apps/selectors'
import signatureDisclaimerSection from 'e-submission/domain/data-model/form/signatureDisclaimerSection'
import { signatureSection } from 'e-submission/domain/data-model/form/signatureSection'
import { compact } from 'lodash/fp'
import { getToggles } from 'quick-quote/feature-toggles'

export const baseAgentRequiredDocs = [
  'BI',
  'APPLICATION',
  'E_POLICY_CONSENT',
  'RPQ',
  'ADDENDUM',
  'FOREIGNER',
  'FNA',
  'VULNERABLE_CUSTOMER',
]

export const signature = (app) => {
  const isShowPayerPage = havePayer(app)
  const isWealthHubLB = hasWealthHubSignPage(app)
  const isHaveThirdPerson = haveThirdPerson(app)

  const overrideDescription = { APPLICATION: 'ใบคำขอเอาประกันชีวิต' }

  const insuredRequiredDocs = [
    'BI',
    'APPLICATION',
    'E_POLICY_CONSENT',
    'PRIVACY_CONSENT',
    'KYC',
    'RPQ',
    'RA',
    'ADDENDUM',
    'FOREIGNER',
    'FNA',
    getToggles().ENABLE_CRS_SECTION && isNeedDocCRS(app, 'insured') && 'CRS',
    (getToggles().ENABLE_EKYC_F2F || isSelectedRemoteSelling(app)) && 'PASSPORT_CONSENT_INSURED',
    (getToggles().ENABLE_EKYC_F2F || isSelectedRemoteSelling(app)) && 'CITIZEN_ID_CONSENT_INSURED',
  ]
  const payerRequiredDocs = [
    'BI',
    'APPLICATION',
    'E_POLICY_CONSENT',
    'PRIVACY_CONSENT',
    'KYC',
    'RPQ',
    'RA',
    'ADDENDUM',
    'FNA',
    getToggles().ENABLE_CRS_SECTION && isNeedDocCRS(app, 'payer') && 'CRS',
    (getToggles().ENABLE_EKYC_F2F || isSelectedRemoteSelling(app)) && 'PASSPORT_CONSENT_PAYER',
    (getToggles().ENABLE_EKYC_F2F || isSelectedRemoteSelling(app)) && 'CITIZEN_ID_CONSENT_PAYER',
  ]
  let acknowledgeRequiredDocs = [
    'PRIVACY_POLICY',
    getToggles().ENABLE_CITIZEN_ID_CONSENT_FORM && isInsuredUseCitizenId(app)
      ? 'CITIZEN_ID_CONSENT_INSURED'
      : undefined,
    getToggles().ENABLE_CITIZEN_ID_CONSENT_FORM && isInsuredUsePassport(app) ? 'PASSPORT_CONSENT_INSURED' : undefined,
    getToggles().ENABLE_CITIZEN_ID_CONSENT_FORM && isPayerUseCitizenId(app) ? 'CITIZEN_ID_CONSENT_PAYER' : undefined,
    getToggles().ENABLE_CITIZEN_ID_CONSENT_FORM && isPayerUsePassport(app) ? 'PASSPORT_CONSENT_PAYER' : undefined,
  ]

  if ((getToggles().ENABLE_EKYC_F2F || isSelectedRemoteSelling(app)) && !isRemoteSelling(app)) {
    acknowledgeRequiredDocs = acknowledgeRequiredDocs.filter((docs) => docs === 'PRIVACY_POLICY')
  }

  const insuredSignatureSection = signatureSection('insured', insuredRequiredDocs, overrideDescription)

  const agentSignatureSection = signatureSection('agent', baseAgentRequiredDocs, overrideDescription)

  const payerSignatureSection = signatureSection('payer', payerRequiredDocs, overrideDescription)

  const acknowledgeSignatureSection = signatureSection('acknowledge', compact(acknowledgeRequiredDocs))

  const wealthHubSignatureSection = signatureSection('wealthHub', ['BI'], overrideDescription)

  const thirdPersonSignatureSection = signatureSection('thirdPerson', ['FNA'])

  return commonSignature(app, {
    sections: compact([
      insuredSignatureSection('insured', 'ลงนาม ผู้เอาประกันภัย'),
      isShowPayerPage && payerSignatureSection('payer', 'ลงนาม ผู้ชำระเบี้ย'),
      !isSelectedRemoteSelling(app) && agentSignatureSection('agent', 'ลงนาม ตัวแทน/นายหน้าประกันชีวิต'),
      isWealthHubLB && wealthHubSignatureSection('wealthHub', 'ลงนามผู้ให้คํารับรอง Wealth Hub'),
      isHaveThirdPerson && thirdPersonSignatureSection('thirdPerson', 'ลงนาม บุคคลที่สาม'),
      getToggles().ENABLE_PDPA_CONSENT && acknowledgeSignatureSection('acknowledge', 'ตรวจทานเอกสาร'),
      signatureDisclaimerSection('disclaimer'),
    ]),
  })
}
