// @flow
import _ from 'lodash'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { RiderBenefitData, Benefit } from 'core/data-model/rider/benefits'
import { isString, isObject, isArray } from 'core/service/lib/type-check'

import RiderWithBenefitModal from './rider-benefit-modal'
import RiderBenefitSummary from './rider-benefit-summary'
import MESSAGES from 'core/data-model/constants/bi-messages'
import { AgentProtectionDisclaimer } from './agent-protection-disclaimer'
import { AdditionalDisclaimer } from './additional-disclaimer'

type CPRRiderBenefitProps = {
  rider: Rider & RiderState,
  riderBenefitData: RiderBenefitData,
  showDisclaimer: boolean,
}

const renderCPRRiderBenefitsRow = (benefit: Benefit, rowNumber: number) => {
  const disclaimer =
    typeof benefit !== 'string' && benefit.disclaimer !== '' ? (
      <div className="disclaimer"> {benefit.disclaimer} </div>
    ) : null
  return (
    <tr key={`cpr-rider-benefit-row-${rowNumber}`} id={`cpr-rider-benefit-row-${rowNumber}`}>
      <td>
        <div>
          {benefit.description && isString(benefit.description)
            ? benefit.description.split('\n').map((text) => <div key={`${text}-${rowNumber}`}>{text}</div>)
            : ''}
        </div>
        {disclaimer}
      </td>
      <td>{isObject(benefit) ? benefit.value : 0} บาท</td>
    </tr>
  )
}

const renderCPRBenefitTable = (riderSumAssured, riderBenefitData: RiderBenefitData, showDisclaimer) => {
  return (
    <div className="details-table">
      <table id="cpr-benefit-table">
        <thead>
          <tr>
            <td>{MESSAGES.BENEFIT_LIST}</td>
            <td>{MESSAGES.BENEFIT_BAHT}</td>
          </tr>
        </thead>
        <tbody>
          {riderBenefitData.benefits && isArray(riderBenefitData.benefits)
            ? riderBenefitData.benefits.map((benefit, index) => renderCPRRiderBenefitsRow(benefit, index + 1))
            : ''}
        </tbody>
      </table>
      <div className="disclaimer">
        {showDisclaimer && <div>{MESSAGES.DISCLAIMER_OVER_56_MUST_HAVE_MEDICAL_EXAM}</div>}
        <div>*{MESSAGES.DISCLAIMER_UNLIMITED_CLAIM_UNDER_100}</div>
        <div>**{MESSAGES.DISCLAIMER_ORGAN_DEFINITION}</div>
        <ul>
          <li>{MESSAGES.DISCLAIMER_ORGAN_OVARY}</li>
          <li>{MESSAGES.DISCLAIMER_ORGAN_VAGINA}</li>
          <li>{MESSAGES.DISCLAIMER_ORGAN_COLON}</li>
          <li>{MESSAGES.DISCLAIMER_ORGAN_STOMACH}</li>
          <li>{MESSAGES.DISCLAIMER_ORGAN_SKIN}</li>
        </ul>
        <div>***{MESSAGES.DISCLAIMER_INFORCE_OR_REINSTATEMENT}</div>
      </div>
    </div>
  )
}

const CPRRiderBenefit = ({ rider, riderBenefitData, showDisclaimer }: CPRRiderBenefitProps) => {
  const title = `${rider.description} ${rider.name}`
  const riderSumAssured: number = _.get(rider, 'sumAssured')
  const benefitTable = riderBenefitData
    ? renderCPRBenefitTable(riderSumAssured, riderBenefitData, showDisclaimer)
    : null

  return (
    <RiderWithBenefitModal
      id="cpr-rider-benefit"
      className="rider cpr-rider"
      title={title}
      modalChildren={benefitTable}
    >
      <RiderBenefitSummary rider={rider} />
      {benefitTable}
      <AgentProtectionDisclaimer rider={rider} />
      <AdditionalDisclaimer rider={rider} />
    </RiderWithBenefitModal>
  )
}

export default CPRRiderBenefit
