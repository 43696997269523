// @flow

import {
  isWholeLife25PLProduct,
  isWholeLife12PLProduct,
  isIHealthyProduct,
  isIProtectProduct,
  isIShieldProduct,
  isLifeLegacyProduct,
  isSavingProduct,
  isLifeSavePro25P15Product,
  isLifeSavePro20P10Product,
  isFlexiHealthProduct,
  isLifeProtectProduct,
  isLifeEnsureProduct,
  isIRetireProduct,
  isLifeRetireProduct,
  isS7Product,
  isTermLife_GIO_Product,
} from 'core/service/basic-plan/benefit'
import type { DisplayProduct } from 'core/service/display-product'
import type { PolicyValue } from 'core/service/benefit-illustration/policy-value'
import MESSAGES from 'core/data-model/constants/bi-messages'
import CONSTANTS from 'core/data-model/constants/messages'
import { formatNumber } from 'core/service/lib/number-format'
import { buildStacks } from 'core/service/pdf-generation/utils/builder'
import { buildIhealthyBenefitsSection } from 'core/service/pdf-generation/products/health/sections/product-benefit-section'
import { buildFlexiHealthBenefitsSection } from 'core/service/pdf-generation/products/flexi-health/sections/product-benefit-section'

import {
  buildWholeLife25PLBenefitsSection,
  buildWholeLife12PLBenefitsSection,
  buildIProtectBenefitsSection,
  buildIShieldBenefitsSection,
  buildLifeLegacyProduct,
  buildLifeProtectBenefitsSection,
  buildLifeEnsureBenefitsSection,
  buildRetirementBenefitsSection,
  buildS7BenefitsSection,
} from 'core/service/pdf-generation/products/whole-life/sections/product-benefit-section'

import { buildLifeSaveProBenefitsSection } from 'core/service/pdf-generation/products/life-save-pro/sections/product-benefit-section'
import { buildProductGIOBenefitsSection } from 'core/service/pdf-generation/products/term-life/sections/product-benefit-section'

import Mustache from 'mustache'

export const getProductSpecificBenefits = (basicPlan: DisplayProduct, sumAssured: number, policyValue: PolicyValue) => {
  if (isIProtectProduct(basicPlan.basicPlanCode)) {
    return buildIProtectBenefitsSection(basicPlan, sumAssured)
  } else if (isIShieldProduct(basicPlan.basicPlanCode)) {
    return buildIShieldBenefitsSection(basicPlan, sumAssured)
  } else if (isIHealthyProduct(basicPlan.basicPlanCode)) {
    return buildIhealthyBenefitsSection()
  } else if (isFlexiHealthProduct(basicPlan.basicPlanCode)) {
    return buildFlexiHealthBenefitsSection()
  } else if (isLifeLegacyProduct(basicPlan.basicPlanCode)) {
    return buildLifeLegacyProduct()
  } else if (isWholeLife25PLProduct(basicPlan.basicPlanCode)) {
    return buildWholeLife25PLBenefitsSection(basicPlan, sumAssured, policyValue)
  } else if (isWholeLife12PLProduct(basicPlan.basicPlanCode)) {
    return buildWholeLife12PLBenefitsSection(basicPlan, sumAssured, policyValue)
  } else if (isLifeSavePro25P15Product(basicPlan.basicPlanCode) || isLifeSavePro20P10Product(basicPlan.basicPlanCode)) {
    return buildLifeSaveProBenefitsSection(basicPlan, sumAssured, policyValue)
  } else if (isLifeProtectProduct(basicPlan.basicPlanCode)) {
    return buildLifeProtectBenefitsSection(basicPlan, sumAssured)
  } else if (isLifeEnsureProduct(basicPlan.basicPlanCode)) {
    return buildLifeEnsureBenefitsSection(basicPlan)
  } else if (isSavingProduct(basicPlan.basicPlanCode)) {
    return []
  } else if (isIRetireProduct(basicPlan.basicPlanCode) || isLifeRetireProduct(basicPlan.basicPlanCode)) {
    return buildRetirementBenefitsSection(basicPlan, sumAssured)
  } else if (isS7Product(basicPlan.basicPlanCode)) {
    return buildS7BenefitsSection(basicPlan, sumAssured)
  } else if (isTermLife_GIO_Product(basicPlan.basicPlanCode)) {
    return buildProductGIOBenefitsSection(basicPlan, sumAssured)
  } else {
    return []
  }
}

type Props = {
  basicPlan: DisplayProduct,
  calculatedCoveragePeriod: number,
  calculatedPaymentPeriod: number,
  sumAssured: number,
  basicPremium: number,
  productGraph: string,
  basicPlanDetailsLabelFn: (DisplayProduct) => string,
  policyValue: PolicyValue,
  selectedModelFactorID: string,
  selectedModelFactorLabel: string,
}

export const productBenefitsSection = (props: Props) => {
  const { basicPlan, sumAssured, productGraph, policyValue } = props
  const showGraph = productGraph !== ''
  const summarySection = getSummarySection({ ...props, showGraph })
  const productSpecificBeneftits = getProductSpecificBenefits(basicPlan, sumAssured, policyValue)
  return summarySection.concat(productSpecificBeneftits)
}

type SummarySectionProps = Props & {
  showGraph: boolean,
}

export const getSummarySection = ({
  basicPlanDetailsLabelFn,
  basicPlan,
  calculatedCoveragePeriod,
  calculatedPaymentPeriod,
  sumAssured,
  basicPremium,
  showGraph,
  productGraph,
  selectedModelFactorID,
  selectedModelFactorLabel,
}: SummarySectionProps) => {
  if (isSavingProduct(basicPlan.basicPlanCode)) {
    return [showGraph ? { image: productGraph, alignment: 'center', width: 425, margin: [0, 20, 0, 30] } : {}]
  } else {
    return [
      {
        pageBreak: 'before',
        text: basicPlanDetailsLabelFn(basicPlan),
        style: 'title',
      },
      {
        columns: buildStacks([
          {
            label: MESSAGES.COVERAGE_PERIOD,
            value: `${calculatedCoveragePeriod} ${CONSTANTS.TIME_UNIT_YEAR}`,
          },
          {
            label: MESSAGES.PAYMENT_PERIOD,
            value:
              selectedModelFactorID === 'model-factor_5'
                ? selectedModelFactorLabel
                : `${calculatedPaymentPeriod} ${CONSTANTS.TIME_UNIT_YEAR}`,
          },
          {
            label: MESSAGES.BASIC_PLAN_SUM_ASSURED,
            value: `${formatNumber(sumAssured)} ${CONSTANTS.BAHT}`,
          },
          {
            label: MESSAGES.BASIC_PREMIUM,
            value: `${formatNumber(basicPremium, 2)} ${CONSTANTS.BAHT}`,
          },
        ]),
        style: 'info',
      },
      showGraph ? { image: productGraph, alignment: 'center', width: 425, margin: [0, 20, 0, 30] } : {},
    ]
  }
}

export const basicPlanDetailsLabelFn = (basicPlan: DisplayProduct): string =>
  Mustache.render(MESSAGES.BASIC_PLAN_DETAILS, {
    name: basicPlan.basicPlanName,
    code: basicPlan.basicPlanCode,
  })
