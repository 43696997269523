// @flow

// ==== action types ====
export type Action = SfReference
export const SF_REFERENCE = 'OPTY/SF_REFERENCE'
export const ENTERING_IPRO = 'OPTY/ENTERING_IPRO'
export const UPDATE_OPTY_E_APP_CREATE = 'UPDATE_OPTY_E_APP_CREATE'
export const UPDATE_OPTY_E_APP = 'UPDATE_OPTY_E_APP'
export const UPDATE_OPTY_KYC = 'UPDATE_OPTY_KYC'
export const UPDATE_OPTY_PAYMENT = 'UPDATE_OPTY_PAYMENT'

export const UPDATE_OPTY_QUOTE_FNA = 'UPDATE_OPTY_QUOTE_FNA'
export const UPDATE_OPTY_SUBMIT_APPLICATION = 'UPDATE_OPTY_SUBMIT_APPLICATION'

export type SfReferenceData = {
  startSaleFrom: string,
  accId?: string,
  optyId?: string,
}

export type SfReference = {
  type: 'OPTY/SF_REFERENCE',
  payload: SfReferenceData,
}

export type OptyEnteringData = {
  agentCode?: string,
  accId?: string,
  optyId?: string,
  name?: string,
}

export type OptyEntering = {
  type: 'OPTY/ENTERING_IPRO',
  payload: OptyEnteringData,
}

// ==== action creators ====

export const sfReference = (sfRefData: SfReferenceData): SfReference => ({
  type: SF_REFERENCE,
  payload: sfRefData,
})

export const optyEntering = (optyEnteringData: OptyEnteringData): OptyEntering => ({
  type: ENTERING_IPRO,
  payload: optyEnteringData,
})

export const updateOptyStageEAppCreate = () => ({
  type: UPDATE_OPTY_E_APP_CREATE,
})

export const updateOptyStageEApp = () => ({
  type: UPDATE_OPTY_E_APP,
})

export const updateOptyStageKyc = () => ({
  type: UPDATE_OPTY_KYC,
})

export const updateOptyStagePayment = () => ({
  type: UPDATE_OPTY_PAYMENT,
})
