// @flow

import _ from 'lodash'
import { d } from 'core/service/lib/decimal'
import VALUES from 'core/data-model/constants/values'
import { getToggles } from 'quick-quote/feature-toggles'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { PolicyValue } from 'core/service/benefit-illustration'

type SumOfBenefitAmount = number
type ProductCode = string

type Percentage = number
type PlanSumAssuredPercentage = {
  [ProductCode]: Percentage,
}

export type Case = 'DEATH' | 'DISABILITY' | 'CRITICAL_ILLNESS' | 'BENEFIT_OF_MATURITY'
export type Cause = 'ILLNESS' | 'RIOT' | 'ACCIDENT' | 'DISASTER' | 'MATURITY'

type PlanSumAssured = {
  code: ProductCode,
  sumAssured: number,
}

export type BenefitSummary = {
  [Case]: { [Cause]: SumOfBenefitAmount },
}

const _isShowBenefitMaturity = (code: ProductCode) => !['PLB05', 'PLB10', 'PLB12', 'PLB15'].includes(code)

export const summarizeBenefit = (
  basicSumAssured: PlanSumAssured,
  riderSumAssured: PlanSumAssured[],
  policyValue: PolicyValue
): BenefitSummary => {
  const summarizeBenefitAmount: (PlanSumAssuredPercentage) => SumOfBenefitAmount = summarizeProductBenefitAmount(
    basicSumAssured,
    riderSumAssured
  )

  const BASIC_PRODUCT_CODE = basicSumAssured.code

  return {
    DEATH: {
      ILLNESS: summarizeBenefitAmount({
        [BASIC_PRODUCT_CODE]: 100,
        DCI: 100,
        PLS: 100,
      }),
      ACCIDENT: summarizeBenefitAmount({
        [BASIC_PRODUCT_CODE]: 100,
        AP: 100,
        ECARE: 100,
        DCI: 100,
        PLS: 100,
      }),
      DISASTER: summarizeBenefitAmount({
        [BASIC_PRODUCT_CODE]: 100,
        AP: 200,
        ECARE: 200,
        DCI: 100,
        PLS: 100,
      }),
      RIOT: summarizeBenefitAmount({
        [BASIC_PRODUCT_CODE]: 100,
        AP: 100,
        ECARE: 100,
        DCI: 100,
        PLS: 100,
      }),
    },
    CRITICAL_ILLNESS: {
      ILLNESS: summarizeBenefitAmount({
        DCI: 100,
      }),
    },
    DISABILITY: {
      ILLNESS: summarizeBenefitAmount({
        DCI: 100,
      }),
      ACCIDENT: summarizeBenefitAmount({
        ECARE: 100,
        DCI: 100,
      }),
    },
    BENEFIT_OF_MATURITY: {
      MATURITY:
        !getToggles().ENABLE_DISABLE_SHOWING_BENEFIT_MATURITY && _isShowBenefitMaturity(basicSumAssured.code)
          ? getMaturityBenefit(policyValue)
          : 0,
    },
  }
}

export const transformBenefitSummary = (benefitSummary: BenefitSummary) => {
  const toNestedPairs = (causeMapping, caseLabel) => [caseLabel, _.toPairs(causeMapping)]

  const hasNonZeroAmount = ([, amount]) => amount !== 0
  const hasBenefit = ([, benefitByCauses]) => _.some(benefitByCauses, hasNonZeroAmount)
  const collapseSingleCause = ([caseLabel, benefitsByCause]) => [
    caseLabel,
    benefitsByCause.length === 1 ? benefitsByCause[0][1] : benefitsByCause.filter(hasNonZeroAmount),
  ]

  return _.map(benefitSummary, toNestedPairs)
    .filter(hasBenefit)
    .map(collapseSingleCause)
}

const calculatePercentage = (value: number, percentage: Percentage) =>
  d(value)
    .times(percentage)
    .div(100)

const getMaturityBenefit = (policyValue: PolicyValue): number => {
  const endPolicyValue = _.last(policyValue.yearEndPolicyValues)
  return _.get(endPolicyValue, 'deathBenefit', 0)
}

const summarizeProductBenefitAmount = (basicSumAssured: PlanSumAssured, riderSumAssured: PlanSumAssured[]) => (
  planSumAssuredPercentage: PlanSumAssuredPercentage
) =>
  riderSumAssured
    .concat([basicSumAssured])
    .map((product) => calculatePercentage(product.sumAssured, planSumAssuredPercentage[product.code] || 0))
    .reduce((sum, sumAssured) => sum.plus(sumAssured), d(0))
    .toNumber()

export const getRiderAsterisks = (rider: Rider & RiderState, isNewAsteriskOfSummaryMainContract) => {
  if (VALUES.LIST_FIVE_ASTERISKS_BENEFIT_SUMMARY_TABLE.includes(rider.code)) {
    return isNewAsteriskOfSummaryMainContract ? '***' : '*****'
  } else if (
    rider.premiumStructure === VALUES.PREMIUM_NON_LEVEL &&
    !VALUES.LIST_EXCEPT_ASTERISKS_BENEFIT_SUMMARY_TABLE.includes(rider.code)
  ) {
    return isNewAsteriskOfSummaryMainContract ? '**' : '***'
  } else {
    return ''
  }
}
