// @flow
import type { ModelFactor } from 'core/data-model/basic-plan'
import type { AppState } from 'quick-quote/reducers'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { RadioButtonGroup } from 'common-components'
import { getSelectedModelFactorID, getModelFactorsForUser } from 'quick-quote/product-common/coverage-plan/selectors'
import { selectModelFactor } from 'quick-quote/product-common/coverage-plan/actions'

type ModelFactorSelectionProps = {
  modelFactors: ModelFactor[],
  selectedModelFactorID: string,
  selectModelFactor?: (string) => void,
}

export const ModelFactorSelection = ({
  modelFactors,
  selectedModelFactorID,
  selectModelFactor,
}: ModelFactorSelectionProps) => {
  return (
    <div className="form-group" data-testid="basic-plan-model-factor-selection">
      <div className="form-group-title">
        <span className="form-group-title-text">งวดการชำระเบี้ยประกันภัย</span>
      </div>
      <RadioButtonGroup
        keyToGetValue="id"
        className="model-factor"
        onChange={selectModelFactor}
        value={selectedModelFactorID}
        options={modelFactors}
      />
    </div>
  )
}

const mapStateToProps = (state: AppState) => ({
  modelFactors: getModelFactorsForUser(state),
  selectedModelFactorID: getSelectedModelFactorID(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  selectModelFactor: bindActionCreators(selectModelFactor, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ModelFactorSelection)
