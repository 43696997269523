// @flow
import type { FundCode, TotalFundAllocation } from 'core/data-model/investment'
import _ from 'lodash'
import { validateInvestmentFundAllocation, validateInvestmentFundTotalAllocation } from './validation'
import type { InvestmentFundAllocation } from './rules'
import MESSAGES from 'core/data-model/constants/validation-messages'
import type { DisplayProductQuery } from 'core/service'

type FundAllocationResult = {
  fundAllocation: number,
  selected?: boolean,
  riskLevelDigestable: boolean,
  code: FundCode,
  errors?: string[],
}

type Allocation = {
  fundAllocations: FundAllocationResult[],
  totalAllocation: TotalFundAllocation,
}

const riskLevelDigestable = (insuredRiskLevel: number) => (fund: InvestmentFundAllocation): boolean =>
  fund.riskLevel <= insuredRiskLevel

export const _calculateAllocation = async (
  fundAllocations: InvestmentFundAllocation[],
  insuredRiskLevel: number,
  validateFundAllocation: *,
  validateFundTotalAllocation: *
): Promise<Allocation> => {
  const selectedFunds = _.filter(fundAllocations, 'selected')
  let totalFundAllocation = _.sumBy(selectedFunds, 'fundAllocation')

  let [fundAllocations$, totalAllocation$] = await Promise.all([
    Promise.all(_.map(selectedFunds, validateFundAllocation)),
    validateFundTotalAllocation(totalFundAllocation),
  ])
  const riskLevelDigestableForInsured = riskLevelDigestable(insuredRiskLevel)
  let resultAllocations = _.map(fundAllocations$, (fund$) => ({
    ...fund$.value,
    errors: fund$.getErrorMessages(),
    riskLevelDigestable: riskLevelDigestableForInsured(fund$.value),
  }))
  if (!totalAllocation$.isOk) {
    _.each(resultAllocations, (fundAllocation) => {
      fundAllocation.errors && fundAllocation.errors.push(MESSAGES.RULE_IWEALTHY_MAX_FUND_LIMIT)
    })
  }
  return {
    fundAllocations: resultAllocations,
    totalAllocation: { value: totalAllocation$.value, errors: totalAllocation$.getErrorMessages() },
  }
}
export const calculateTotalAllocation = async (
  displayProductQuery: DisplayProductQuery,
  insuredRiskLevel: number,
  fundAllocations: InvestmentFundAllocation[]
): Promise<Allocation> => {
  const validateFundAllocationForProduct = validateInvestmentFundAllocation(displayProductQuery)
  const validateTotalAllocationForProduct = validateInvestmentFundTotalAllocation(displayProductQuery)
  return _calculateAllocation(
    fundAllocations,
    insuredRiskLevel,
    validateFundAllocationForProduct,
    validateTotalAllocationForProduct
  )
}
