// @flow

import { combineReducers } from 'redux'
import type { FinancialCheckList, HealthCheckList } from 'core/data-model/loan'
import type { Action } from './actions'
import { UPDATE_HEALTH_CHECKLIST, UPDATE_FINANCIAL_CHECKLIST } from './actions'

export const healthCheckList = (state: HealthCheckList[] = [], action: Action) => {
  switch (action.type) {
    case UPDATE_HEALTH_CHECKLIST:
      return action.payload
    default:
      return state
  }
}

export const financialCheckList = (state: FinancialCheckList[] = [], action: Action) => {
  switch (action.type) {
    case UPDATE_FINANCIAL_CHECKLIST:
      return action.payload
    default:
      return state
  }
}

export type State = {
  +healthCheckList: HealthCheckList[],
  +financialCheckList: FinancialCheckList[],
}

export const reducer = combineReducers({
  healthCheckList,
  financialCheckList,
})
