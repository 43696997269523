import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import { getCurrentApp } from 'e-submission/apps/selectors'
import { Checkbox } from 'common-components'
import FormEngine from 'e-submission/components/FormEngine'
import styled from 'styled-components'
import { FormFeedback } from 'reactstrap'
import { ECBR_PAYER_TYPE } from 'e-submission/domain/data-model/constants'
import { setAppValue, unsetAppValue, setHideEcbrSystemizationFailedModal } from 'e-submission/apps/actions'
import { PayerSection, getRelationshipInfoMessage } from './PayerSection'
import PayerInfoApiFailedModal from 'e-submission/containers/Dialog/PayerInfoApiFailedModal'
import { getToggles } from 'quick-quote/feature-toggles'

const Header = styled.label`
  font-weight: bold;
`
const Container = styled.div`
  margin-top: 20px;
`
const FieldGroup = styled.div`
  input,
  .text {
    text-align: left !important;
    max-width: 100% !important;
    font-size: 1rem !important;
  }
  margin-top: -20px !important;
  ${({ theme }) => theme.media.sm`
  margin: 0 -50px;
  `};
`
const Content = styled.div`
  margin-top: 0.75rem;
  margin-bottom: 1rem;
`

const payerTypeText = {
  insured: 'ผู้เอาประกัน',
  payer: 'ผู้ชำระเบี้ย',
  other: 'บุคคลอื่น',
}

const PayerInfo = (props) => {
  const {
    handleInputChange,
    payerInfo,
    app,
    isPayer,
    isSendLink,
    isOpenEcbrSystemizationModal,
    closeEcbrSystemizationFailedModal,
  } = props

  return (
    <>
      <div data-testid="ecbr-section" style={{ marginTop: '20px' }}>
        <Container>
          <Header>กรุณาเลือกผู้ชำระเบี้ยประกัน (เจ้าของบัญชีที่ใช้ในการชำระเบี้ย)</Header>
        </Container>
        {isSendLink ? (
          <>
            <Content>{payerTypeText[payerInfo?.payerType]}</Content>
            {payerInfo?.payerType === ECBR_PAYER_TYPE.OTHER && (
              <FormFeedback className="info-message attached-icon">{getRelationshipInfoMessage()}</FormFeedback>
            )}
          </>
        ) : (
          <>
            <Container>
              <Checkbox
                id={ECBR_PAYER_TYPE.INSURED}
                checked={payerInfo?.payerType === ECBR_PAYER_TYPE.INSURED}
                onChange={() => handleInputChange('payerType', ECBR_PAYER_TYPE.INSURED)}
              >
                ผู้เอาประกัน
              </Checkbox>
              <Checkbox
                id={ECBR_PAYER_TYPE.PAYER}
                disabled={isPayer}
                checked={payerInfo?.payerType === ECBR_PAYER_TYPE.PAYER}
                onChange={() => handleInputChange('payerType', ECBR_PAYER_TYPE.PAYER)}
              >
                ผู้ชำระเบี้ย
              </Checkbox>
              <Checkbox
                id={ECBR_PAYER_TYPE.OTHER}
                checked={payerInfo?.payerType === ECBR_PAYER_TYPE.OTHER}
                onChange={() => handleInputChange('payerType', ECBR_PAYER_TYPE.OTHER)}
              >
                บุคคลอื่น (โปรดระบุ ชื่อนามสกุล หมายเลขบัตรประชาชน และความสัมพันธ์)
              </Checkbox>
            </Container>
            {payerInfo?.payerType === ECBR_PAYER_TYPE.OTHER && (
              <FieldGroup>
                <FormEngine sections={[PayerSection('payerInfo')(app)]} />
              </FieldGroup>
            )}
          </>
        )}
      </div>
      {getToggles().ENABLE_ECBR_SYSTEMIZATION && (
        <PayerInfoApiFailedModal closeModal={closeEcbrSystemizationFailedModal} isOpen={isOpenEcbrSystemizationModal} />
      )}
    </>
  )
}

const mapStateToProps = (state) => {
  const app = getCurrentApp(state)
  const payerInfo = _.get(app, 'payerInfo')
  const isPayer = _.get(app, 'insured.isPayer')
  const isOpenEcbrSystemizationModal = _.get(state, `ui.isEcbrSystemizationModal`)

  return {
    payerInfo,
    app,
    isPayer,
    isOpenEcbrSystemizationModal,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleInputChange: (key, value) => {
      if (value === ECBR_PAYER_TYPE.INSURED || value === ECBR_PAYER_TYPE.PAYER) {
        dispatch(unsetAppValue(`payerInfo.firstName`))
        dispatch(unsetAppValue(`payerInfo.lastName`))
        dispatch(unsetAppValue(`payerInfo.idType`))
        dispatch(unsetAppValue(`payerInfo.idNo`))
        dispatch(unsetAppValue(`payerInfo.relations`))
      }
      dispatch(setAppValue(`payerInfo.${key}`, value))
    },
    closeEcbrSystemizationFailedModal: () => dispatch(setHideEcbrSystemizationFailedModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PayerInfo)
