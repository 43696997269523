// @flow

import { canProceedToRiskQuestionnaire } from 'quick-quote/product-investment/journey-control-selectors'
import type { AppState } from 'quick-quote/reducers'
import { connect } from 'react-redux'
import type { Dispatch } from 'redux'
import { bindActionCreators } from 'redux'

import { acceptForeignInvestment, acceptHigherRiskInvestment } from './actions'
import type { QuestionnaireProps } from './components'
import RiskQuestionnaire from './components'
import {
  getAllowForeignInvestment,
  getAllowHigherRiskInvestment,
  hasValidEntryComponent,
  isEvaluated,
} from './selectors'

const mapStateToProps = (state: AppState): QuestionnaireProps => {
  return {
    isEvaluated: isEvaluated(state),
    hasValidEntryComponent: hasValidEntryComponent(state),
    canProceedToRiskQuestionnaire: canProceedToRiskQuestionnaire(state),
    allowHigherRiskInvestment: getAllowHigherRiskInvestment(state),
    allowForeignInvestment: getAllowForeignInvestment(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  acceptHigherRiskInvestment: bindActionCreators(acceptHigherRiskInvestment, dispatch),
  acceptForeignInvestment: bindActionCreators(acceptForeignInvestment, dispatch),
})

const RiskContainer = connect(mapStateToProps, mapDispatchToProps)(RiskQuestionnaire)
export default RiskContainer
