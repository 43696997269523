// @flow
import _ from 'lodash'
import { getToggles } from 'quick-quote/feature-toggles'
import DEFAULTS from 'core/data-model/constants/defaults'
import VALUES from 'core/data-model/constants/values'
import BIMESSAGES from 'core/data-model/constants/bi-messages'
import { formatNumber } from 'core/service/lib/number-format'
import type { Rider, RiderState } from 'core/data-model/rider'

export const getRiderSumAssuredDisplayValue = (rider: Rider & RiderState): string => {
  const isDisplayMessage = _.includes(
    _.compact([
      VALUES.WP,
      VALUES.WPD,
      VALUES.WPDD,
      VALUES.WPTPD,
      VALUES.WPTPDCI,
      VALUES.MEK,
      VALUES.PBPDD,
      VALUES.PBPDDCI,
      VALUES.PBSDD,
      VALUES.PBSDDCI,
      getToggles().ENABLE_ICARE_REPRICING && VALUES.WPEIP85,
    ]),
    rider.code
  )

  if (isDisplayMessage) {
    return rider.code === VALUES.MEK ? BIMESSAGES.SUMMARY_TABLE_MEK_SUMASSURED : BIMESSAGES.SUMMARY_TABLE_WP_SUMASSURED
  } else if (_.includes(DEFAULTS.PROXY_RIDERS, rider.code)) {
    return formatNumber(rider.sumAssured)
  } else if (rider.package ? '' : rider.selectedPlan.planCode) {
    let sumAssured = rider.showPlanAsSumAssured ? rider.selectedPlan.plan : rider.sumAssured

    if (rider.code === VALUES.MHP && rider.selectedPlan.planOptional) {
      switch (rider.selectedPlan.planOptional) {
        case VALUES.MHP_CO_PAYMENT:
          sumAssured = sumAssured + ' ' + BIMESSAGES.MHP_BI_CO_PAYMENT
          break
        case VALUES.MHP_DEDUCTIBLE:
          sumAssured = sumAssured + ' ' + BIMESSAGES.MHP_BI_DEDUCTIBLE
          break
      }
    }

    return `${BIMESSAGES.PLAN} ${
      // $$FlowFixMe
      sumAssured
    }`
  } else {
    if (rider.showSumAssuredDecimalPlaces) {
      return formatNumber(rider.sumAssured, 2)
    } else {
      return formatNumber(rider.sumAssured)
    }
  }
}
