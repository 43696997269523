// @flow

import type { AnalyticsPageViewEvent } from 'analytics/actions'
import type { ToggleRider } from 'quick-quote/product-common/coverage-plan/actions'

import { takeEvery, select, takeLatest } from 'redux-saga/effects'
import { analytics } from 'analytics/index'
import { LOGIN_REQUEST, LOGOUT_REQUEST } from 'quick-quote/actions'
import { USER_FOUND_AUTHZ_CODE_FLOW } from 'identity/actions'
import { TOGGLE_RIDER } from 'quick-quote/product-common/coverage-plan/actions'
import { PREPARE_FOR_QUICK_QUOTE } from 'quick-quote/product-common/benefit-illustration/actions'
import { ANALYTICS_BI_EVENT, ANALYTICS_PAGE_VIEW_EVENT } from 'analytics/actions'
import { getSelectedDisplayProductName } from 'quick-quote/product-selection/selectors'
import {
  getBirthdate,
  getGender,
  getAge,
  getSelectedOccupationCode,
  getSelectedNatureOfDutyCode,
} from 'quick-quote/insured-information/selectors'

import {
  getSumAssured$,
  getTotalPremium,
  getAvailableRidersByCode,
  getSelectedModelFactorLabel,
} from 'quick-quote/product-common/coverage-plan/selectors'
import { getToggles } from 'quick-quote/feature-toggles'

export const uuid = () => {
  let bufferArray = new Uint32Array(1)
  let randomNumber = () => {
    return window.crypto.getRandomValues(bufferArray)[0]
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r = randomNumber() % 16 | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}
export function* sendBenefitIllustrationEvent(): Generator<*, *, *> {
  const selectedBasicPlanName = yield select(getSelectedDisplayProductName)
  const insurerBirthDate = yield select(getBirthdate)
  const insurerAge = yield select(getAge)
  const insurerGender = yield select(getGender)
  const insurerOccupationCode = yield select(getSelectedOccupationCode)
  const insurerNatureOfDutyCode = yield select(getSelectedNatureOfDutyCode)
  const riders = yield select(getAvailableRidersByCode)
  const riderPremiumFor = (code) => {
    return riders && riders[code] && riders[code].isSelected ? riders[code].premium : 0
  }

  const basicSumAssured = yield select(getSumAssured$)
  const riderPLR = riders['PLR']
  const riderPLRplan = riderPLR && riderPLR.isSelected && riderPLR.selectedPlan ? riderPLR.selectedPlan.planCode : ''
  const totalPremium = yield select(getTotalPremium)
  const modelFactor = yield select(getSelectedModelFactorLabel)

  const ageDenominator = {
    year: 1,
    month: 12,
    day: 365,
  }

  const insurerAgeYear = insurerAge.value / ageDenominator[insurerAge.unit]

  const metricRiderPremium = {
    metric3: riderPremiumFor('AP'),
    metric4: riderPremiumFor('CPR'),
    metric5: riderPremiumFor('DCI'),
    metric6: riderPremiumFor('ECARE'),
    metric7: riderPremiumFor('HIC'),
    metric8: riderPremiumFor('MEAPlus'),
    metric9: riderPremiumFor('MEB'),
    metric10: riderPremiumFor('PLR'),
    metric11: riderPremiumFor('WP'),
    metric12: riderPremiumFor('WPD'),
    metric13: riderPremiumFor('WPDD'),
  }

  analytics.sendCustomEvent({
    category: 'Benefit Illustration',
    action: 'Summary',
    label: '',
    dimension1: selectedBasicPlanName,
    dimension2: insurerGender,
    dimension3: insurerAgeYear.toString(),
    dimension4: insurerOccupationCode,
    dimension5: insurerNatureOfDutyCode,
    dimension6: riderPLRplan,
    dimension7: uuid(),
    dimension8: modelFactor,
    dimension9: insurerBirthDate,
    metric1: insurerAgeYear,
    metric2: basicSumAssured.value,
    metric14: totalPremium,
    ...metricRiderPremium,
  })
}

export function* sendDeselectRiderEvent(action: ToggleRider): Generator<*, *, *> {
  const { payload } = action

  const riders = yield select(getAvailableRidersByCode)
  if (riders[payload] && !riders[payload].isSelected) {
    analytics.sendCustomEvent({
      category: 'Coverage Plan',
      action: 'Deselect Rider',
      label: payload,
    })
  }
}

export function* sendLoginEvent(): Generator<*, *, *> {
  analytics.sendCustomEvent({
    category: 'Identity',
    action: 'Login',
    label: '',
  })
}

export function* sendLoginSuccessEvent(): Generator<*, *, *> {
  analytics.sendCustomEvent({
    category: 'Identity',
    action: 'LogInSuccess',
    label: '',
  })
}

export function* sendLogoutEvent(): Generator<*, *, *> {
  analytics.sendCustomEvent({
    category: 'Identity',
    action: 'Logout',
    label: '',
  })
}

export function* sendShareQuickQuoteEvent(): Generator<*, *, *> {
  analytics.sendCustomEvent({
    category: 'Share',
    action: 'PrepareQuickQuote',
    label: '',
  })
}

export function* sendPageViewEvent(action: AnalyticsPageViewEvent): Generator<*, *, *> {
  const { payload } = action
  if (getToggles().ENABLE_REMOVE_POLICY_NO_GA4) analytics.logPageView(payload)
}

export function* watchers(): Generator<*, *, *> {
  yield [
    takeEvery([ANALYTICS_BI_EVENT], sendBenefitIllustrationEvent),
    takeEvery([LOGIN_REQUEST], sendLoginEvent),
    takeEvery([LOGOUT_REQUEST], sendLogoutEvent),
    takeEvery([USER_FOUND_AUTHZ_CODE_FLOW], sendLoginSuccessEvent),
    takeLatest([TOGGLE_RIDER], sendDeselectRiderEvent),
    takeEvery([PREPARE_FOR_QUICK_QUOTE], sendShareQuickQuoteEvent),
    takeLatest([ANALYTICS_PAGE_VIEW_EVENT], sendPageViewEvent),
  ]
}

export const sagas = watchers
