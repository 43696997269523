// @flow

import type { Rider, RiderState } from 'core/data-model/rider'
import type { Benefit } from 'core/data-model/rider/benefits'
import { isObject, isNotNil } from 'core/service/lib/type-check'

import RiderBenefitSummary from './rider-benefit-summary'
import RiderWithBenefitModal from './rider-benefit-modal'
import MESSAGES from 'core/data-model/constants/bi-messages'
import { translatePlanToThai } from 'core/service/rider/benefits/benefit-data/fh'

type FHRiderBenefitProps = {
  rider: Rider & RiderState,
  benefits: Benefit[],
  notes: String[],
}

type FHRiderBenefitsRowProps = {
  description: string,
  value: string,
}

const renderFHRiderBenefitsRow = (rowNumber: number, fhRiderBenefitsRowProps: FHRiderBenefitsRowProps) => {
  const { description, value } = fhRiderBenefitsRowProps

  const benefitDescription = <div>{description}</div>

  const benefitValue = value.split('\n').map((text) => <div key={`v_${text}-${rowNumber}`}>{text}</div>)

  if (value === '') {
    return (
      <tr key={`fh-rider-benefit-row-${rowNumber}`} id={`fh-rider-benefit-row-${rowNumber}`}>
        <td colSpan={2}>{benefitDescription}</td>
      </tr>
    )
  }

  return (
    <tr key={`fh-rider-benefit-row-${rowNumber}`} id={`fh-rider-benefit-row-${rowNumber}`}>
      <td>{benefitDescription}</td>
      <td>{benefitValue}</td>
    </tr>
  )
}

const renderFHRiderBenefitsTable = (rider: Rider & RiderState, benefits: Benefit[], notes: String[]) => {
  return (
    <div className="details-table">
      <table id="fh-benefit-table">
        <thead>
          <tr>
            <td>{MESSAGES.BENEFIT_LIST}</td>
            <td>
              {MESSAGES.BENEFIT_TITLE} ({translatePlanToThai(rider.selectedPlan.plan)})
            </td>
          </tr>
        </thead>
        <tbody>
          {benefits.map((benefit, index) => {
            return renderFHRiderBenefitsRow(index, {
              description: isObject(benefit) && isNotNil(benefit.description) ? benefit.description : '',
              value: isObject(benefit) && isNotNil(benefit.value) ? benefit.value : '',
            })
          })}
          <tr>
            <td colSpan="2">
              <div>{MESSAGES.FLEXI_HEALTH_BENEFIT_AREA_COVERAGE_TITLE}</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>{MESSAGES.FLEXI_HEALTH_BENEFIT_OUT_AREA_COVERAGES[0]}</div>
            </td>
            <td>
              <div>{MESSAGES.FLEXI_HEALTH_BENEFIT_OUT_AREA_COVERAGES[1]}</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>{MESSAGES.FLEXI_HEALTH_BENEFIT_COMPENSATIONS[0]}</div>
            </td>
            <td style={{ textAlign: 'left' }}>
              <div>{MESSAGES.FLEXI_HEALTH_BENEFIT_COMPENSATIONS[1][0]}</div>
              <div>{MESSAGES.FLEXI_HEALTH_BENEFIT_COMPENSATIONS[1][1]}</div>
            </td>
          </tr>
        </tbody>
      </table>
      <div className="disclaimer">
        <div>{MESSAGES.NOTE}</div>
        {notes.map((note, index) => {
          return <div key={`fh-rider-note-row-${index}`}>{note}</div>
        })}
      </div>
    </div>
  )
}

const FHRiderBenefit = ({ rider, benefits, notes }: FHRiderBenefitProps) => {
  const title = `${rider.description} ${rider.name}`

  const benefitTable = benefits ? renderFHRiderBenefitsTable(rider, benefits, notes) : null

  return (
    <RiderWithBenefitModal id="fh-rider-benefit" className="rider fh-rider" title={title} modalChildren={benefitTable}>
      <RiderBenefitSummary rider={rider} />
      {benefitTable}
    </RiderWithBenefitModal>
  )
}

export default FHRiderBenefit
