import { get, omit } from 'lodash/fp'
import { lifecycle, compose, withState, mapProps, withHandlers, withProps } from 'recompose'

export default compose(
  withState('localValue', 'updateValue', get('value')),
  withState('isDirty', 'setDirty', false),
  withState('isShowFullLabel', 'showFullLabel', false),
  withHandlers(({ id, setDirty }) => {
    let onFocus = () => {
      setDirty(false)
    }

    return {
      subscribeFocus: () => () => {
        const elem = document.querySelector(`[id='${id}'] input`)
        if (elem) {
          elem.addEventListener('focus', onFocus)
        }
      },
      unsubscribeFocus: () => () => {
        const elem = document.querySelector(`[id='${id}'] input`)
        if (elem) {
          setDirty(false)
          elem.removeEventListener('focus', onFocus)
        }
      },
    }
  }),
  lifecycle({
    UNSAFE_componentWillReceiveProps(nextProps) {
      if (this.props.localValue !== nextProps.value && !nextProps.isDirty) {
        this.props.updateValue(nextProps.value)
      }
    },
    componentDidMount() {
      this.props.subscribeFocus()
    },
    componentWillUnmount() {
      this.props.unsubscribeFocus()
    },
  }),
  withProps(({ value, localValue, id }) => {
    const elem = document.querySelector(`[id='${id}'] input:focus`)
    if (elem) {
      return { value: localValue }
    }
    return { value }
  }),
  withHandlers({
    setValue: ({ id, setValue, updateValue, setDirty }) => (value) => {
      setDirty(true)
      updateValue(value)
      setValue(id, value)
    },
  }),
  withHandlers({
    toggleFullLabel: ({ showFullLabel, isShowFullLabel }) => () => {
      showFullLabel(!isShowFullLabel)
    },
  }),
  mapProps(
    omit(['updateValue', 'localValue', 'subscribeFocus', 'unsubscribeFocus', 'isDirty', 'setDirty', 'showFullLabel'])
  )
)
