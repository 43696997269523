//@flow

import { calculateFontSizeToFitComponent } from 'core/service/lib/pdf-utils'

export const COMPONENT_WIDTH = 190
export const DEFAULT_NAME_FONT_SIZE = 50
export const MAX_WORD_WIDTH_HEADER2 = 35
export const BLUE = '#2425aa'
export const BROWN = '#e12e4c'

export const productInfoHeaderStyles = {
  productName: {
    font: 'Helvethaica',
    fontSize: DEFAULT_NAME_FONT_SIZE,
    lineHeight: 0.7,
    width: COMPONENT_WIDTH,
    characterSpacing: 0,
    color: BLUE,
    bold: true,
  },
  thaiLongNameStyle: {
    font: 'Helvethaica',
    fontSize: 20,
    color: BROWN,
    bold: true,
  },
  thaiNameStyle: {
    font: 'Helvethaica',
    fontSize: 24,
    color: BROWN,
    bold: true,
  },
}

export const formattedHeaderWithStyles = (header1: string, header2: string, redline: string, styles: Object) => {
  const nameStyle = (productTitle: string) => {
    return {
      ...styles.productName,
      fontSize: calculateFontSizeToFitComponent(productTitle, COMPONENT_WIDTH, DEFAULT_NAME_FONT_SIZE, false),
    }
  }

  const header2Style = header2.length > MAX_WORD_WIDTH_HEADER2 ? styles.thaiLongNameStyle : styles.thaiNameStyle
  const header2Margin = header2.length > MAX_WORD_WIDTH_HEADER2 ? [0, 5, 0, 0] : [0, 0, 0, 0]

  return {
    width: 'auto',
    columns: [
      {
        stack: [
          {
            text: header1,
            margin: [0, -10, 0, 0],
            style: nameStyle(header1),
          },
          {
            text: header2,
            margin: header2Margin,
            style: header2Style,
          },
        ],
      },
      {
        image: redline,
        width: 56,
      },
    ],
  }
}
