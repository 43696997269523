import { compact } from 'lodash/fp'
import videoConsentSection from '../videoConsentSection'

export const videoConsent = (app, props = {}) => {
  return Object.assign(
    {},
    {
      id: 'video-consent',
      prefix: 'video-consent',
      title: 'บันทึกภาพ',
      label: 'บันทึกภาพ',
      sections: compact([videoConsentSection()]),
      remoteSection: true,
    },
    props
  )
}
