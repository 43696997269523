/* istanbul ignore file */

import _ from 'lodash'
import { connect } from 'react-redux'
import { FormGroup } from 'reactstrap'
import { Dropdown } from 'common-components'
import RiderPremium from 'quick-quote/product-common/coverage-plan/components/rider/rider-premium'

import { editRiderSumAssured, editRiderSelectedPlan } from 'quick-quote/product-common/coverage-plan/actions'

export const getHealthPackage = (packageRiders, previousPlan, plan, areaOfCover) => {
  const newSelectedPlan = _.find(packageRiders, {
    plan,
  })

  const changedAreaOfCover =
    // $$FlowFixMe
    previousPlan !== plan ? newSelectedPlan.areasOfCover[0] : areaOfCover
  // $$FlowFixMe
  const planCode = newSelectedPlan.codes[changedAreaOfCover]

  return { planCode, plan, areaOfCover: changedAreaOfCover }
}

const MEHRider = ({ className, rider, planOptions, areasOfCover, editHealthPackage, ...props }) => (
  <FormGroup className={`${className} meh`} {...props}>
    <div className="label">
      <strong>เลือกแผน {rider.name}</strong>
      <Dropdown
        options={planOptions}
        onChange={(value) => {
          editHealthPackage(value, rider.selectedPlan.areaOfCover)
        }}
        value={rider.selectedPlan.plan}
      />
    </div>
    <div className="options">
      <Dropdown
        options={areasOfCover}
        onChange={(value) => {
          editHealthPackage(rider.selectedPlan.plan, value)
        }}
        value={rider.selectedPlan.areaOfCover}
      />
      <div className="link">→</div>
      <RiderPremium
        isSelected={rider.isSelected && rider.isSelectable}
        isSelectable={rider.isSelectable}
        premium={rider.premium}
      />
    </div>
  </FormGroup>
)

export default connect(
  () => ({}),
  { editRiderSumAssured, editRiderSelectedPlan },
  (stateProps, dispatchProps, ownProps) => {
    const { rider } = ownProps
    const { editRiderSumAssured, editRiderSelectedPlan } = dispatchProps

    const planOptions = rider.packageRiders.map(({ plan }) => ({
      text: plan,
      value: plan,
    }))

    const selectedPackage = _.find(rider.packageRiders, {
      plan: rider.selectedPlan.plan,
    })

    const areasOfCover = _.pick(rider.areasOfCover, selectedPackage.areasOfCover)

    return {
      ...ownProps,
      editHealthPackage: (plan, areaOfCover) => {
        const healthPackage = getHealthPackage(rider.packageRiders, selectedPackage.plan, plan, areaOfCover)
        editRiderSumAssured(rider.code, 0)
        editRiderSelectedPlan(rider.code, healthPackage)
      },
      planOptions,
      areasOfCover,
    }
  }
)(MEHRider)
