// @flow
import type { Effect } from 'redux-saga/effects'
import { put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { isNumber, cond, identity, stubTrue, constant, isArray, includes } from 'lodash/fp'
import type { SetSelectedNeedId } from './actions'
import {
  SET_FNA_NEED_CATEGORY,
  SET_FNA_GAP_VALUE,
  clearFnaNeedGapQuestions,
  setFnaGapTargetSavings,
  clearFnaGapInvestmentGoalOther,
  clearReasonForChoosingOther,
  clearOtherCoverageOther,
  clearFnaGapTargetSavings,
} from './actions'
import {
  availableProductCategories,
  availablePlanCode,
  selectProductCategory,
} from 'quick-quote/product-selection/actions'
import { setMarketConductReasonValue } from 'quick-quote/fna/market-conducts/actions'
import { getMarketConductDefaultReasonValue } from 'quick-quote/fna/market-conducts/selectors'
import {
  getSelectedDisplayProductCode,
  getSelectedDisplayProductCategory,
} from 'quick-quote/product-selection/selectors'
import { getSelectedPlanId, getSelectedNeedType, getNeedGapQuestion, getNeeds, getStartSaleFrom } from './selectors'
import { setNotificationMessage } from 'quick-quote/notification/actions'
import { CONFIRM_IPRO_FNA, resetDisplayProduct } from 'quick-quote/actions'
import { getToggles } from 'quick-quote/feature-toggles'
import { setAddingInvestmentQuestionForNonILPNeedType } from 'quick-quote/ui/actions'

export function* onNeedCategoryChange(action: SetSelectedNeedId): Generator<*, *, *> {
  yield put(clearFnaNeedGapQuestions())
  const needs = yield select(getNeeds)
  const need = needs.find((n) => action.payload.planId === n.planId && action.payload.id === (n.needId || n.id))
  if (!need) return
  // clear allow product
  yield put(availableProductCategories([]))
  yield put(availablePlanCode([]))

  const isReviseFNA = getToggles().ENABLE_REVISE_FNA
  const startSaleForm = yield select(getStartSaleFrom)
  const isSaleFromIpro = startSaleForm === 'SF'
  const selectedDisplayProductCode = yield select(getSelectedDisplayProductCode)
  const isSelectedDisplayProductCodeAvailable =
    !!need.availablePlanCode && includes(selectedDisplayProductCode, need.availablePlanCode)
  // Show popup when Ipro product not match with selected need config (only 'SAVING_AND_INVESTMENT')
  if (
    isReviseFNA &&
    isSaleFromIpro &&
    selectedDisplayProductCode && // iPro have selected product
    !isSelectedDisplayProductCodeAvailable &&
    need.needId === 'SAVING_AND_INVESTMENT'
  ) {
    yield put(
      setNotificationMessage({
        type: 'ConfirmIproFNA',
      })
    )
  }

  // Set default market conduct reason
  const defaultReason = yield select(getMarketConductDefaultReasonValue)
  yield put(setMarketConductReasonValue(defaultReason))
  // Respect user selected product
  // but if user not have selected product before change need
  // or start sale from iPro with invest product
  // should suggest category and limit product
  if (
    !selectedDisplayProductCode ||
    (isReviseFNA &&
    isSaleFromIpro &&
    selectedDisplayProductCode && // iPro have selected product
      isSelectedDisplayProductCodeAvailable &&
      need.needId === 'SAVING_AND_INVESTMENT')
  ) {
    // suggest product category that suit user needs

    if (selectedDisplayProductCode === 'RPUDR') {
      // only product iLink
      yield put(selectProductCategory('CATEGORY_RETIREMENT'))
    } else {
      yield put(selectProductCategory(need.productCategory))
    }
    // limit product to what that needs allow
    yield put(availableProductCategories(need.availableProductCategories || []))
    yield put(availablePlanCode(need.availablePlanCode || []))
  }

  const selectedDisplayProductCategory = yield select(getSelectedDisplayProductCategory)

  if (
    isReviseFNA &&
    isSaleFromIpro &&
    need.needId !== 'SAVING_AND_INVESTMENT' &&
    selectedDisplayProductCategory === 'INVESTMENT'
  ) {
    yield put(setAddingInvestmentQuestionForNonILPNeedType(true))
  }
}
const formatComputed = cond([
  [isNumber, identity],
  [stubTrue, constant(0)],
])

export const PROTECTION_AND_HEALTH_STRATEGY = {
  required: ['monthlyExpenses', 'coveragePeriod', 'currentSavings'],
  compute: ({ monthlyExpenses, coveragePeriod, currentSavings }) =>
    monthlyExpenses * 12 * coveragePeriod - currentSavings,
}
export const RETRIEMENT_STRATEGY = {
  required: ['retiredAge', 'retiredMonthlyExpenses', 'retiredCoveragePeriod', 'currentSavings'],
  compute: ({ retiredMonthlyExpenses, retiredCoveragePeriod, currentSavings }) =>
    retiredMonthlyExpenses * 12 * retiredCoveragePeriod - currentSavings,
}
export const EDUCATION_STRATEGY = {
  required: ['educationLevel', 'numberOfChild', 'educationExpenses', 'currentSavings', 'educationExpenses'],
  compute: ({ educationExpenses, currentSavings }) => educationExpenses - currentSavings,
}
export const SAVING_AND_INVESTMENT_STRATEGY = {
  validate: [
    ({ investmentGoal, investmentGoalOther }) => {
      return investmentGoal === 'OTHER' ? !!investmentGoalOther : true
    },
  ],
  required: ['investmentGoal', 'requiredSavings', 'currentSavings', 'targetSavingPeriod'],
  compute: ({ requiredSavings, currentSavings }) => requiredSavings - currentSavings,
}
export const SAVING_AND_INVESTMENT_2_STRATEGY = {
  validate: [
    ({ investmentGoal, investmentGoalOther }) => {
      if (!investmentGoal || investmentGoal.length === 0) return false
      return investmentGoal.indexOf('OTHER') > -1 ? !!investmentGoalOther : true
    },
  ],
  required: ['investmentGoal', 'requiredSavings', 'currentSavings', 'targetSavingPeriod'],
  compute: ({ requiredSavings, currentSavings }) => requiredSavings - currentSavings,
}
export const PROTECTION_AND_LEGACY_2_STRATEGY = {
  validate: [
    ({ otherCoverage, otherCoverageOther }) => {
      if (!otherCoverage || otherCoverage.length === 0) return true
      return otherCoverage.indexOf('OTHER') > -1 ? !!otherCoverageOther : true
    },
  ],
  required: ['monthlyExpenses', 'coveragePeriod', 'currentSavings'],
  compute: ({ monthlyExpenses, coveragePeriod, currentSavings }) =>
    monthlyExpenses * 12 * coveragePeriod - currentSavings,
}
export const getTargetSavingCalculationStrategy = (type: string) =>
  ({
    PROTECTION_AND_HEALTH: PROTECTION_AND_HEALTH_STRATEGY,
    RETIREMENT: RETRIEMENT_STRATEGY,
    EDUCATION: EDUCATION_STRATEGY,
    SAVING_AND_INVESTMENT: SAVING_AND_INVESTMENT_STRATEGY,
    LEGACY: PROTECTION_AND_HEALTH_STRATEGY,
    PROTECTION_AND_LEGACY_2: PROTECTION_AND_LEGACY_2_STRATEGY,
    HEALTH: {
      required: ['hospitalName', 'OPDPerYear', 'IPDPerYear', 'roomServicePerDay'],
      compute: () => 0,
    },
    EDUCATION_2: EDUCATION_STRATEGY,
    RETIREMENT_2: RETRIEMENT_STRATEGY,
    LEGACY_2: PROTECTION_AND_LEGACY_2_STRATEGY,
    SAVING_AND_INVESTMENT_2: SAVING_AND_INVESTMENT_2_STRATEGY,
    CRITICAL_ILLNESS: PROTECTION_AND_LEGACY_2_STRATEGY,
    ACCIDENT: PROTECTION_AND_LEGACY_2_STRATEGY,
    DEBT: PROTECTION_AND_LEGACY_2_STRATEGY,
    TAX: PROTECTION_AND_LEGACY_2_STRATEGY,
  }[type])

export function* calculateTargetSavings(): Generator<*, *, *> {
  const currentPlanId = yield select(getSelectedPlanId)
  const questions = yield select(getNeedGapQuestion)
  const calculate = getTargetSavingCalculationStrategy(currentPlanId)
  const someRequiredFieldMissing = calculate.required.some((k) => questions[k] === undefined || questions[k] === '')
  const allValidationPassed = calculate.validate ? calculate.validate.every((v) => v(questions)) : true
  const shouldClearOtherValue = (key) =>
    isArray(questions[key]) ? questions[key].indexOf('OTHER') === -1 : questions[key] !== 'OTHER'

  if (shouldClearOtherValue('investmentGoal')) yield put(clearFnaGapInvestmentGoalOther())
  if (shouldClearOtherValue('reasonForChoosing')) yield put(clearReasonForChoosingOther())
  if (shouldClearOtherValue('otherCoverage')) yield put(clearOtherCoverageOther())

  if (someRequiredFieldMissing || !allValidationPassed) {
    yield put(clearFnaGapTargetSavings())
    return
  }

  const targetSavings = formatComputed(calculate.compute(questions))
  yield put(setFnaGapTargetSavings(Math.max(targetSavings, 0)))
}

export function* onConfirmProceedIproByFNA(): Generator<*, *, *> {
  // reset selected display product from ipro that not matching with selected FNA need and gap
  // using need and gap data to lead customer on next step process
  yield put(resetDisplayProduct())

  const fnaSelectedNeedPlanId = yield select(getSelectedPlanId)
  const fnaSelectedNeedType = yield select(getSelectedNeedType)
  const needs = yield select(getNeeds)
  // specific fnaSelectedNeedPlanId = 'SAVING_AND_INVESTMENT_2' and fnaSelectedNeedType = 'SAVING_AND_INVESTMENT'
  const need = needs.find((n) => fnaSelectedNeedPlanId === n.planId && fnaSelectedNeedType === (n.needId || n.id))
  if (!need) return

  // suggest product category that suit user needs
  yield put(selectProductCategory(need.productCategory))
  // limit product to what that needs allow
  yield put(availableProductCategories(need.availableProductCategories || []))
  yield put(availablePlanCode(need.availablePlanCode || []))
}

export function* watchers(): Generator<*, *, Effect[]> {
  yield [takeEvery(SET_FNA_NEED_CATEGORY, onNeedCategoryChange)]
  yield [takeEvery(SET_FNA_GAP_VALUE, calculateTargetSavings)]
  yield [takeLatest(CONFIRM_IPRO_FNA, onConfirmProceedIproByFNA)]
}

export const sagas = watchers
