import { compact, noop, flatten, overSome, negate } from 'lodash/fp'
import { createField, createSection, createSectionCreator } from 'e-submission/domain/data-model/form/utils'

import VALUES from 'core/data-model/constants/values'

import {
  getSelectedPersonalTitleList,
  getMonthsCollectBankList,
  getDCABankList,
  isSectionValid,
} from 'e-submission/apps/selectors'

import { isDCASigned, isDCAInformation } from 'e-submission/domain/data-model/form/selectors'
import { setAppValue, updateDCA, signedDCA } from 'e-submission/apps/actions'
import { signatureField, documentField } from 'e-submission/domain/data-model/submit'

import MESSAGES from 'core/data-model/constants/messages'
import { getToggles } from 'quick-quote/feature-toggles'

const PREFIX_FORM = 'DCA'

export const DCAFormSection = (app) => {
  return compact([
    createField(`${PREFIX_FORM}.title`, [
      {
        label: 'คำนำหน้า',
        id: `${PREFIX_FORM}.title`,
        c: 'Dropdown',
        disabled: true,
        required: true,
        p: {
          mergeState: (state) => ({
            options: getSelectedPersonalTitleList(state, 'insured'),
          }),
        },
      },
    ]),
    createField(`${PREFIX_FORM}.firstName`, [
      {
        label: 'ชื่อเจ้าของบัญชี',
        id: `${PREFIX_FORM}.firstName`,
        c: 'TextInput',
        disabled: true,
        required: true,
        p: {},
      },
    ]),
    createField(`${PREFIX_FORM}.lastName`, [
      {
        label: 'นามสกุลเจ้าของบัญชี',
        id: `${PREFIX_FORM}.lastName`,
        c: 'TextInput',
        disabled: true,
        required: true,
        p: {},
      },
    ]),
    !getToggles().ENABLE_DCA_NONE_SELECT_BANK &&
      createField(`${PREFIX_FORM}.bank`, [
        {
          label: 'ยินยอมขอรับผลประโยชน์ผ่านบัญชีธนาคาร',
          id: `${PREFIX_FORM}.bank`,
          c: 'Dropdown',
          required: true,
          p: {
            placeHolder: 'กรุณาเลือกธนาคาร',
            mergeState: (state, app) => {
              const monthsCollectBankList = getMonthsCollectBankList(state)
              return {
                options: getDCABankList(state),
                disabled: false,
              }
            },
          },
        },
      ]),
    createField(`${PREFIX_FORM}.accountNumber`, [
      {
        label: getToggles().ENABLE_DCA_NONE_SELECT_BANK
          ? `${MESSAGES.DCA_CONSENT_RECEIVE_BENEFITS} ${MESSAGES.ACCOUNT_NUMBER}`
          : `${MESSAGES.ACCOUNT_NUMBER}`,
        id: `${PREFIX_FORM}.accountNumber`,
        c: 'NumberInput',
        required: true,
        p: {
          format: '###-#-#####-#',
        },
      },
    ]),
  ])
}

export const DCASignatureSection = (app) =>
  compact([
    createField(
      'DCA.edit',
      [
        {
          id: 'DCA.edit',
          c: 'EditCard',
          p: {
            title: 'ข้อมูลที่กรอกไปถูกนำมาสร้างหนังสือยินยอม',
            children: 'การแก้ไขข้อมูลจะต้องลงนามใหม่',
            mergeState: (state, app) => ({
              disabled: isDCASigned(app),
            }),
            mergeProps: ({ dispatch }) => ({
              onClick: () => {
                dispatch(setAppValue('DCA.status', VALUES.DCA_STATUS.INFORMATION))
              },
            }),
          },
        },
      ],
      {
        pl: 0,
        pr: 0,
      }
    ),
    createField('insured.DCALabel', [
      {
        id: 'insured.DCALabel',
        c: 'SubHeading',
        p: {
          children: 'ลงนาม ผู้เอาประกันภัย',
        },
      },
    ]),
    signatureField('insured')(app, 'insured.DCA', {
      label: 'ลงนาม ผู้เอาประกันภัย',
    }),
  ])

export const DCASection = createSectionCreator((app, id, section) => {
  const DCAForm = [
    ...DCAFormSection(app),
    createField('DCA.sign', [
      {
        id: 'DCA.sign',
        c: 'Button',
        p: {
          block: true,
          children: 'ลงนาม',
          mergeState: (state, app, { dispatch = noop, history, ...props } = {}) => ({
            disabled: overSome([
              negate(isSectionValid(state, [createSection(documentField(id)(app, 'insured.DCA_BANK_BOOK', section))])),
              negate(isSectionValid(state, [createSection(DCAFormSection(app))])),
            ])(app),
            onClick: () => {
              dispatch(updateDCA())
            },
          }),
        },
      },
    ]),
  ]
  const DCASignature = [
    ...DCASignatureSection(app),
    createField('DCA.submit', [
      {
        id: 'DCA.submit',
        c: 'Button',
        p: {
          block: true,
          children: 'ตกลง',
          mergeState: (state, app, { dispatch = noop, history, ...props } = {}) => ({
            disabled: overSome([
              negate(isSectionValid(state, [createSection(documentField(id)(app, 'insured.DCA_BANK_BOOK', section))])),
              negate(isSectionValid(state, [createSection(DCASignatureSection(app))])),
              isDCASigned,
            ])(app),
            onClick: () => {
              dispatch(signedDCA())
            },
          }),
        },
      },
    ]),
  ]

  const isInformation = isDCAInformation(app)
  return flatten([documentField(id)(app, 'insured.DCA_BANK_BOOK', section), isInformation ? DCAForm : DCASignature])
})
