import { flow, map, findIndex } from 'lodash/fp'
import { appendAfter, appendBefore } from 'e-submission/domain/data-model/form/utils'

import baseForm from 'e-submission/domain/data-model/form'

import {
  incomePerYearField,
  incomeSourceSection,
  investReasonSection,
  jobIndustrySection,
  occupationSection,
} from './KYC'
import { submit } from 'e-submission/domain/data-model/form/pages/submit'

export default (createPage, selectedRemoteSelling = false) => (app) => {
  return flow(
    baseForm,
    map(createPage),
    appendBefore(null, 'beneficiary', [
      createPage({
        id: 'KYC',
        prefix: 'KYC',
        title: 'ข้อมูลผู้ถือหน่วยลงทุน (KYC)',
        label: 'KYC',
        sections: [
          occupationSection('KYC-occupation', 'ข้อมูลเกี่ยวกับอาชีพ'),
          jobIndustrySection('KYC-jobIndustry', 'ประเภทธุรกิจ'),
          incomeSourceSection('KYC-incomeSource', 'แหล่งที่มาของรายได้'),
          investReasonSection('KYC-investReason', 'วัตถุประสงค์ในการลงทุน'),
        ],
        size: 'xl',
      }),
    ]), //TODO waiting for solution
    !selectedRemoteSelling ? appendAfter(null, 'otp', [createPage(submit())]) : (app) => app,
    (data) => {
      const insuredIndex = findIndex((d) => d.id === 'insured')(data)
      return { data, insuredIndex }
    },
    (obj) =>
      appendAfter(`${obj.insuredIndex}.sections.0.questions`, 'insured.incomePerYear', [
        incomePerYearField(app, 'KYC'),
      ])(obj.data)
  )(app)
}
