// @flow
import type { AppState } from 'quick-quote/reducers'
import _ from 'lodash'

export const getWealthSource = (state: AppState): string => _.get(state, 'wealthInfo.dataSource')
export const getSalePermission = (state: AppState): string => _.get(state, 'wealthInfo.isValidSalePermission')
export const getFundList = (state: AppState): string => _.get(state, 'myWealthProfile.fundList')
export const getFundListLSTU = (state: AppState): string => _.get(state, 'myWealthProfile.fundListLSTU.fundList')
export const getIsOpenedPDF = (state: AppState): boolean => state.wealthInfo.isOpenedPDF
export const getIsOpenedLSTUPDF = (state: AppState): boolean => state.wealthInfo.isOpenedLSTUPDF
export const getPremiumPrefill = (state: AppState): string => _.get(state, 'myWealthProfile.premiumPrefill')
