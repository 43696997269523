//@flow
import { Header, UnderlineWord } from 'core/service/pdf-generation/products/common/components/general'

const LEFT_MARGIN = 10
const header = {
  marginBottom: 5,
  bold: true,
}

const mapNumberMarker = (list, start: number) => list.map((v, k) => `${start + k}. ${v}`)

export const CIUDRBenefitSection = ({ riderBenefitData, title, benefitSummary }) => {
  const { benefits, notice, disclaimer, criticalIllness } = riderBenefitData.riderBenefitData
  const { diseases } = benefits

  return [
    benefitSummary,
    {
      text: benefits.title,
      style: header,
      marginTop: 15,
    },
    {
      marginLeft: LEFT_MARGIN,
      stack: [
        { text: benefits.description },
        {
          table: {
            widths: ['75%', '25%'],
            body: [
              [`1 ${diseases[0].title}`, { text: diseases[0].rate, alignment: 'right' }],
              [
                [`2 ${diseases[1].title}`, `- ${diseases[1].moreDetails[0].text[0]}`],
                { text: diseases[1].rate, alignment: 'right' },
              ],
              [
                [
                  `3 ${diseases[2].title}`,
                  `- ${diseases[2].moreDetails[0].text[0]}`,
                  {
                    separator: ['3.'],
                    marginLeft: LEFT_MARGIN,
                    ol: [
                      diseases[2].moreDetails[0].list[0].text,
                      diseases[2].moreDetails[0].list[1].text,
                      diseases[2].moreDetails[0].list[2].text,
                    ],
                  },
                  `- ${diseases[2].moreDetails[1].text[0]}`,
                  `- ${diseases[2].moreDetails[1].text[1]}`,
                  `- ${diseases[2].moreDetails[1].text[2]}`,
                ],
                { text: diseases[2].rate, alignment: 'right' },
              ],
            ],
          },
          layout: 'noBorders',
        },
      ],
    },
    Header(`${title} (ต่อ)`),
    benefitSummary,
    {
      marginTop: 15,
      marginLeft: LEFT_MARGIN,
      stack: [
        {
          table: {
            widths: ['75%', '25%'],
            body: [
              [
                [
                  `4 ${diseases[3].title}`,
                  `- ${diseases[3].moreDetails[0].text[0]}`,
                  {
                    separator: ['4.'],
                    marginLeft: LEFT_MARGIN,
                    ol: [
                      diseases[3].moreDetails[0].list[0].text,
                      diseases[3].moreDetails[0].list[1].text,
                      diseases[3].moreDetails[0].list[2].text,
                    ],
                  },
                  `- ${diseases[3].moreDetails[1].text[0]} `,
                  {
                    text: `${diseases[3].moreDetails[2].text[0]}`,
                    bold: true,
                  },
                ],
                { text: diseases[3].rate, alignment: 'right' },
              ],
            ],
          },
          layout: 'noBorders',
        },
        {
          text: UnderlineWord(disclaimer.title, 'บางส่วน'),
          style: header,
          marginTop: 10,
        },
        disclaimer.description[0],
        {
          ol: [
            disclaimer.list[0].text,
            disclaimer.list[1].text,
            disclaimer.list[2].text,
            disclaimer.list[3].text,
            disclaimer.list[4].text,
            disclaimer.list[5].text,
          ],
        },
      ],
    },
    //new page disclaimer
    Header(`${title} (ต่อ)`),
    benefitSummary,
    {
      text: criticalIllness.title,
      style: header,
      marginTop: 10,
    },
    criticalIllness.description[0],
    {
      text: criticalIllness.earlyStageCriticalIllness,
      style: header,
      marginTop: 10,
    },
    {
      columns: [
        mapNumberMarker(criticalIllness.diseases.slice(0, 7), 1),
        mapNumberMarker(criticalIllness.diseases.slice(7, 13), 8),
        mapNumberMarker(criticalIllness.diseases.slice(13, 20), 14),
      ],
    },
    {
      text: criticalIllness.majorStageCriticalIllness,
      style: header,
      marginTop: 10,
    },
    {
      columns: [
        mapNumberMarker(criticalIllness.diseases.slice(20, 37), 1),
        mapNumberMarker(criticalIllness.diseases.slice(37, 54), 18),
        mapNumberMarker(criticalIllness.diseases.slice(54, 70), 35),
      ],
    },
    Header(`${title} (ต่อ)`),
    benefitSummary,
    {
      text: notice.title,
      style: header,
      marginTop: 15,
    },
    {
      marginLeft: LEFT_MARGIN,
      stack: [
        {
          ul: [
            notice.generic[0].text[0],
            notice.generic[1].text[0],
            notice.generic[2].text[0],
            notice.generic[3].text[0],
          ],
        },
      ],
    },
  ]
}
