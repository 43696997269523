// @flow
import axios from 'axios'
import _ from 'lodash'
import { getAppConfig } from '../deploy-env/app-config'
import { getToggles } from 'quick-quote/feature-toggles'
import { isCordova } from 'e-submission/utils'

export const instance = axios.create()

export const isMobileOnline = () => {
  return !(window.cordova && window.navigator.connection.type === 'none')
}

export const clearAllCordovaCookies = (callback: Function) => {
  if (window.cordova) {
    if (window.cookieMaster.clearCookies) {
      window.cookieMaster.clearCookies(() => {
        callback()
      })
    } else if (window.cookieMaster.clear) {
      window.cookieMaster.clear(() => {
        callback()
      })
    }
  }
}

export const cordovaCodePushLogs = async (params: Object) => {
  if (!getToggles().ENABLE_CORDOVA_LOGS) return false

  const headers = {
    'Content-Type': 'application/json; charset=utf-8',
    'cache-control': 'no-cache',
  }
  const data = JSON.stringify(params)
  return instance({
    method: 'POST',
    url: `${getAppConfig().ADVISORZONE_MIDDLEWARE}/logs/cordova`,
    data,
    headers,
  })
}

const _isModalOpen = () => {
  const classList = _.get(window, 'document.body.classList', [])
  return _.includes(classList, 'modal-open')
}

export const workaroundCordovaKeyboardDidHide = () => {
  if (isCordova) {
    window.addEventListener('keyboardDidHide', () => {
      const bodyOffsetHeight = _.get(document, 'body.offsetHeight')
      const windowHeight = window.innerHeight
      if (bodyOffsetHeight === windowHeight || bodyOffsetHeight === 0 || _isModalOpen()) {
        window.scrollTo(0, 0)
      }
    })
  }
}
