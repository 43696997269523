export const PRODUCT_IPRO_WITH_AZ = [
  {
    ipro: 'life-ready',
    azCode: 'W99FU18',
    categoryId: 'CATEGORY_PROTECTION',
    categoryPath: '/life-ready',
  },
  {
    ipro: 'shechillsafesure',
    azCode: 'W99FU18',
    categoryId: 'CATEGORY_PROTECTION',
    categoryPath: '/life-ready',
  },
  {
    ipro: 'iwealthy',
    azCode: 'RPUL',
    categoryId: 'CATEGORY_EDUCATION',
    categoryPath: '/investment',
  },
  {
    ipro: 'iinvest',
    azCode: 'SPUL',
    categoryId: 'CATEGORY_EDUCATION',
    categoryPath: '/investment',
  },
  {
    ipro: 'ilink',
    azCode: 'RPUDR',
    categoryId: 'CATEGORY_PROTECTION',
    categoryPath: '/investment',
  },
  {
    ipro: 'ishield',
    azCode: 'WLCI05',
    categoryId: 'CATEGORY_PROTECTION',
    categoryPath: '/whole-life',
  },
  {
    ipro: 'icare',
    azCode: 'ICARE',
    categoryId: 'CATEGORY_PROTECTION',
    categoryPath: '/protection',
  },
  {
    ipro: '12pl',
    azCode: '12PL',
    categoryId: 'CATEGORY_PROTECTION',
    categoryPath: '/whole-life',
  },
  {
    ipro: 'protection-life-5',
    azCode: 'PLB05',
    categoryId: 'CATEGORY_PROTECTION',
    categoryPath: '/whole-life',
  },
  {
    ipro: 'protection-life-10',
    azCode: 'PLB10',
    categoryId: 'CATEGORY_PROTECTION',
    categoryPath: '/whole-life',
  },
  {
    ipro: 'protection-life-12',
    azCode: 'PLB12',
    categoryId: 'CATEGORY_PROTECTION',
    categoryPath: '/whole-life',
  },
  {
    ipro: 'protection-life-15',
    azCode: 'PLB15',
    categoryId: 'CATEGORY_PROTECTION',
    categoryPath: '/whole-life',
  },
  {
    ipro: 'super-7-criticalcare-1pay',
    azCode: 'CIWLS39',
    categoryId: 'CATEGORY_PROTECTION',
    categoryPath: '/whole-life',
  },
  {
    ipro: 'super-7-criticalcare-3pay',
    azCode: 'CIWLM39',
    categoryId: 'CATEGORY_PROTECTION',
    categoryPath: '/whole-life',
  },
  {
    ipro: 'health-ultra-package',
    azCode: 'W99F99H',
    categoryId: 'CATEGORY_HEALTH',
    categoryPath: '/whole-life',
  },
  {
    ipro: 'health-extra-package',
    azCode: 'W99F99M',
    categoryId: 'CATEGORY_HEALTH',
    categoryPath: '/whole-life',
  },
  {
    ipro: 'life-super-save-14-5',
    azCode: 'S14G5T1',
    categoryId: 'CATEGORY_EDUCATION',
    categoryPath: '/term-life',
  },
  {
    ipro: 'ultimate-growth',
    azCode: 'E20F10',
    categoryId: 'CATEGORY_EDUCATION',
    categoryPath: '/term-life',
  },
  {
    ipro: 'bumnan-ready',
    azCode: 'R08F',
    categoryId: 'CATEGORY_RETIREMENT',
    categoryPath: '/bumnan-ready',
  },
  {
    ipro: 'shechillfeelgood',
    azCode: 'R08F',
    categoryId: 'CATEGORY_RETIREMENT',
    categoryPath: '/bumnan-ready',
  },
  {
    ipro: 'ismart80-6',
    azCode: 'W80F06',
    categoryId: 'CATEGORY_PROTECTION',
    categoryPath: '/term-life',
  },
  {
    ipro: 'life-treasure',
    azCode: 'H99F18A',
    categoryId: 'CATEGORY_PROTECTION',
    categoryPath: '/life-ready',
  },
  {
    ipro: 'pa-classic',
    azCode: 'PA_CLASSIC',
    categoryId: 'CATEGORY_PROTECTION',
    categoryPath: '/personal-accident',
  },
  {
    ipro: 'pa-platinum',
    azCode: 'PA_PLATINUM',
    categoryId: 'CATEGORY_PROTECTION',
    categoryPath: '/personal-accident',
  },
  {
    ipro: 'pa-max',
    azCode: 'PA_MAX',
    categoryId: 'CATEGORY_PROTECTION',
    categoryPath: '/personal-accident',
  },
]
