// @flow
import type { DisplayProduct } from 'core/service/display-product'

export const SELECT_SUKHAPABDEKDEE_PLAN = 'SELECT_SUKHAPABDEKDEE_PLAN'

export type SelectSukhapabDekDeePlan = {
  type: 'SELECT_SUKHAPABDEKDEE_PLAN',
  payload: string,
}

export const selectSukhapabDekDeePlan = (planCode: string): SelectSukhapabDekDeePlan => ({
  type: SELECT_SUKHAPABDEKDEE_PLAN,
  payload: planCode,
})

export const EDIT_CAMPAIGN_PLAN = 'EDIT_CAMPAIGN_PLAN'
export type EditCampaignPlan = {
  type: 'EDIT_CAMPAIGN_PLAN',
  // payload: string
  payload: DisplayProduct,
}

export const editCampaignPlan = (displayProducts: DisplayProduct): EditCampaignPlan => ({
  type: EDIT_CAMPAIGN_PLAN,
  payload: displayProducts,
})
