//@flow
import type { DisplayProduct } from 'core/service/display-product'
import type { AppState } from 'quick-quote/reducers'
import type { PermissionResult } from 'core/service/distributor/permission'
import type { Dispatch } from 'redux'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'

import { ROUTE_PATH, PATH } from 'quick-quote/constants/path'
import { BasicPlanSummary } from './basic-plan-summary'

import { isQuickQuotePath, isFNAPath } from 'quick-quote/components/navigations/tabs'
import { resetOrdinaryPermission } from 'quick-quote/product-common/validate-permission/actions'
import VALUE from 'core/data-model/constants/values'
import { getToggles } from '../../feature-toggles'

type OrdinaryBasicPlanProps = ownProps & {
  proceedToOrdinaryProduct: PermissionResult,
  resetOrdinaryPermission: Function,
  location: Object,
  isSelected?: boolean,
  isOnFnaPhase?: boolean,
}

type ownProps = {
  displayProduct: DisplayProduct,
  isSelected?: boolean,
  cssClass: string,
  validateProduct: (DisplayProduct) => void,
}

export const PLANCODE_ROUTE_QQ = {
  [VALUE.CIWLM39]: ROUTE_PATH.S7CC.MATURITY_VALIDATE,
  [VALUE.CIWLM50]: ROUTE_PATH.S7CC.MATURITY_VALIDATE,
  [VALUE.CIWLM60]: ROUTE_PATH.S7CC.MATURITY_VALIDATE,
  [VALUE.CIWLS39]: ROUTE_PATH.S7CC.MATURITY_VALIDATE,
  [VALUE.CIWLS50]: ROUTE_PATH.S7CC.MATURITY_VALIDATE,
  [VALUE.CIWLS65]: ROUTE_PATH.S7CC.MATURITY_VALIDATE,
  [VALUE.IHEALTHY_ULTRA_CODE.LIFE_LEGACY_99]: ROUTE_PATH.IHEALTHY_ULTRA.INSURED_INFORMATION,
  [VALUE.IHEALTHY_ULTRA_CODE.LIFE_LEGACY_60]: ROUTE_PATH.IHEALTHY_ULTRA.INSURED_INFORMATION,
  [VALUE.IHEALTHY_ULTRA_CODE.IPROTECT_85]: ROUTE_PATH.IHEALTHY_ULTRA.INSURED_INFORMATION,
  [VALUE.IHEALTHY_ULTRA_CODE['12PL']]: ROUTE_PATH.IHEALTHY_ULTRA.INSURED_INFORMATION,
  [VALUE.IHEALTHY_ULTRA_CODE.IHEALTHY_85]: ROUTE_PATH.IHEALTHY_ULTRA.INSURED_INFORMATION,
  [VALUE.IHEALTHY_ULTRA_CODE.IHEALTHY_60]: ROUTE_PATH.IHEALTHY_ULTRA.INSURED_INFORMATION,
  [VALUE.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PROTECT_18.JUNIOR]: ROUTE_PATH.IHEALTHY_ULTRA.INSURED_INFORMATION,
  [VALUE.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PROTECT_18.SENIOR]: ROUTE_PATH.IHEALTHY_ULTRA.INSURED_INFORMATION,
  [VALUE.IHEALTHY_ULTRA_MULTI_CODE.LIFE_ENSURE_10.JUNIOR]: ROUTE_PATH.IHEALTHY_ULTRA.INSURED_INFORMATION,
  [VALUE.IHEALTHY_ULTRA_MULTI_CODE.LIFE_ENSURE_10.SENIOR]: ROUTE_PATH.IHEALTHY_ULTRA.INSURED_INFORMATION,
  [VALUE.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_20_10.JUNIOR]: ROUTE_PATH.IHEALTHY_ULTRA.INSURED_INFORMATION,
  [VALUE.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_20_10.SENIOR]: ROUTE_PATH.IHEALTHY_ULTRA.INSURED_INFORMATION,
  [VALUE.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_25_15.JUNIOR]: ROUTE_PATH.IHEALTHY_ULTRA.INSURED_INFORMATION,
  [VALUE.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_25_15.SENIOR]: ROUTE_PATH.IHEALTHY_ULTRA.INSURED_INFORMATION,
  [VALUE.WLNP85]: ROUTE_PATH.WHOLE_LIFE.INSURED_INFORMATION,
  [VALUE.WLNP60L]: ROUTE_PATH.WHOLE_LIFE.INSURED_INFORMATION,
  [VALUE.WLNP99L]: ROUTE_PATH.WHOLE_LIFE.INSURED_INFORMATION,
  [VALUE.LIFE_SAVE_PRO_20P10_CODE.JUNIOR]: ROUTE_PATH.LIFE_SAVE_PRO.INSURED_INFORMATION,
  [VALUE.LIFE_SAVE_PRO_20P10_CODE.SENIOR]: ROUTE_PATH.LIFE_SAVE_PRO.INSURED_INFORMATION,
  [VALUE.LIFE_SAVE_PRO_25P15_CODE.JUNIOR]: ROUTE_PATH.LIFE_SAVE_PRO.INSURED_INFORMATION,
  [VALUE.LIFE_SAVE_PRO_25P15_CODE.SENIOR]: ROUTE_PATH.LIFE_SAVE_PRO.INSURED_INFORMATION,
  [VALUE.CODE_12PL]: ROUTE_PATH.WHOLE_LIFE.INSURED_INFORMATION,
  [VALUE.WL88J]: ROUTE_PATH.WHOLE_LIFE.INSURED_INFORMATION,
  [VALUE.WL88S]: ROUTE_PATH.WHOLE_LIFE.INSURED_INFORMATION,
  [VALUE.W85P10J]: ROUTE_PATH.WHOLE_LIFE.INSURED_INFORMATION,
  [VALUE.W85P10S]: ROUTE_PATH.WHOLE_LIFE.INSURED_INFORMATION,
  [VALUE.LIFEREADY_CODE.LIFEREADY12]: ROUTE_PATH.LIFE_READY.INSURED_INFORMATION,
  [VALUE.LIFEREADY_CODE.LIFEREADY18_HIGHER]: ROUTE_PATH.LIFE_READY.INSURED_INFORMATION,
  [VALUE.LIFEREADY_CODE.LIFEREADY18_UNDER]: ROUTE_PATH.LIFE_READY.INSURED_INFORMATION,
  [VALUE.LIFEREADY_CODE.LIFEREADY6]: ROUTE_PATH.LIFE_READY.INSURED_INFORMATION,
  [VALUE.LIFEREADY_CODE.LIFEREADY99_HIGHER]: ROUTE_PATH.LIFE_READY.INSURED_INFORMATION,
  [VALUE.LIFEREADY_CODE.LIFEREADY99_UNDER]: ROUTE_PATH.LIFE_READY.INSURED_INFORMATION,
  [VALUE.LIFEREADY_CAMPAIGN_CODE_W99F99H]: ROUTE_PATH.WHOLE_LIFE.INSURED_INFORMATION,
  [VALUE.LIFEREADY_CAMPAIGN_CODE_W99F99M]: ROUTE_PATH.WHOLE_LIFE.INSURED_INFORMATION,
  [VALUE.BUMNAN_READY_CODE.BUMNAN_READY_8]: ROUTE_PATH.BUMNAN_READY.INSURED_INFORMATION,
  [VALUE.BUMNAN_READY_CODE.BUMNAN_READY_60]: ROUTE_PATH.BUMNAN_READY.INSURED_INFORMATION,
  [VALUE.ULTIMATE_GROWTH_CODE.ULTIMATE_GROWTH_2010]: ROUTE_PATH.TERM_LIFE.INSURED_INFORMATION,
  [VALUE.ULTIMATE_GROWTH_CODE.ULTIMATE_GROWTH_2515]: ROUTE_PATH.TERM_LIFE.INSURED_INFORMATION,
  [VALUE.ULTIMATE_GROWTH_CODE.ULTIMATE_GROWTH_2525]: ROUTE_PATH.TERM_LIFE.INSURED_INFORMATION,
  [VALUE.ISMART_80_6]: ROUTE_PATH.TERM_LIFE.INSURED_INFORMATION,
  [VALUE.MRTA]: ROUTE_PATH.MRTA.INSURED_INFORMATION,
  [VALUE.LIFETREASURE_CODE.LIFETREASURE_6]: ROUTE_PATH.LIFE_READY.INSURED_INFORMATION,
  [VALUE.LIFETREASURE_CODE.LIFETREASURE_12]: ROUTE_PATH.LIFE_READY.INSURED_INFORMATION,
  [VALUE.LIFETREASURE_CODE.LIFETREASURE_18]: ROUTE_PATH.LIFE_READY.INSURED_INFORMATION,
}

export const PLANCODE_ROUTE_QQ_FNA_PHASE = {
  [VALUE.CIWLM39]: ROUTE_PATH.S7CC.MARKET_CONDUCTS,
  [VALUE.CIWLM50]: ROUTE_PATH.S7CC.MARKET_CONDUCTS,
  [VALUE.CIWLM60]: ROUTE_PATH.S7CC.MARKET_CONDUCTS,
  [VALUE.CIWLS39]: ROUTE_PATH.S7CC.MARKET_CONDUCTS,
  [VALUE.CIWLS50]: ROUTE_PATH.S7CC.MARKET_CONDUCTS,
  [VALUE.CIWLS65]: ROUTE_PATH.S7CC.MARKET_CONDUCTS,
  [VALUE.IHEALTHY_ULTRA_CODE.LIFE_LEGACY_99]: ROUTE_PATH.IHEALTHY_ULTRA.MARKET_CONDUCTS,
  [VALUE.IHEALTHY_ULTRA_CODE.LIFE_LEGACY_60]: ROUTE_PATH.IHEALTHY_ULTRA.MARKET_CONDUCTS,
  [VALUE.IHEALTHY_ULTRA_CODE.IPROTECT_85]: ROUTE_PATH.IHEALTHY_ULTRA.MARKET_CONDUCTS,
  [VALUE.IHEALTHY_ULTRA_CODE['12PL']]: ROUTE_PATH.IHEALTHY_ULTRA.MARKET_CONDUCTS,
  [VALUE.IHEALTHY_ULTRA_CODE.IHEALTHY_85]: ROUTE_PATH.IHEALTHY_ULTRA.MARKET_CONDUCTS,
  [VALUE.IHEALTHY_ULTRA_CODE.IHEALTHY_60]: ROUTE_PATH.IHEALTHY_ULTRA.MARKET_CONDUCTS,
  [VALUE.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PROTECT_18.JUNIOR]: ROUTE_PATH.IHEALTHY_ULTRA.MARKET_CONDUCTS,
  [VALUE.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PROTECT_18.SENIOR]: ROUTE_PATH.IHEALTHY_ULTRA.MARKET_CONDUCTS,
  [VALUE.IHEALTHY_ULTRA_MULTI_CODE.LIFE_ENSURE_10.JUNIOR]: ROUTE_PATH.IHEALTHY_ULTRA.MARKET_CONDUCTS,
  [VALUE.IHEALTHY_ULTRA_MULTI_CODE.LIFE_ENSURE_10.SENIOR]: ROUTE_PATH.IHEALTHY_ULTRA.MARKET_CONDUCTS,
  [VALUE.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_20_10.JUNIOR]: ROUTE_PATH.IHEALTHY_ULTRA.MARKET_CONDUCTS,
  [VALUE.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_20_10.SENIOR]: ROUTE_PATH.IHEALTHY_ULTRA.MARKET_CONDUCTS,
  [VALUE.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_25_15.JUNIOR]: ROUTE_PATH.IHEALTHY_ULTRA.MARKET_CONDUCTS,
  [VALUE.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_25_15.SENIOR]: ROUTE_PATH.IHEALTHY_ULTRA.MARKET_CONDUCTS,
  [VALUE.WLNP85]: ROUTE_PATH.WHOLE_LIFE.MARKET_CONDUCTS,
  [VALUE.WLNP60L]: ROUTE_PATH.WHOLE_LIFE.MARKET_CONDUCTS,
  [VALUE.WLNP99L]: ROUTE_PATH.WHOLE_LIFE.MARKET_CONDUCTS,
  [VALUE.LIFE_SAVE_PRO_20P10_CODE.JUNIOR]: ROUTE_PATH.LIFE_SAVE_PRO.MARKET_CONDUCTS,
  [VALUE.LIFE_SAVE_PRO_20P10_CODE.SENIOR]: ROUTE_PATH.LIFE_SAVE_PRO.MARKET_CONDUCTS,
  [VALUE.LIFE_SAVE_PRO_25P15_CODE.JUNIOR]: ROUTE_PATH.LIFE_SAVE_PRO.MARKET_CONDUCTS,
  [VALUE.LIFE_SAVE_PRO_25P15_CODE.SENIOR]: ROUTE_PATH.LIFE_SAVE_PRO.MARKET_CONDUCTS,
  [VALUE.CODE_12PL]: ROUTE_PATH.WHOLE_LIFE.MARKET_CONDUCTS,
  [VALUE.WL88J]: ROUTE_PATH.WHOLE_LIFE.MARKET_CONDUCTS,
  [VALUE.WL88S]: ROUTE_PATH.WHOLE_LIFE.MARKET_CONDUCTS,
  [VALUE.W85P10J]: ROUTE_PATH.WHOLE_LIFE.MARKET_CONDUCTS,
  [VALUE.W85P10S]: ROUTE_PATH.WHOLE_LIFE.MARKET_CONDUCTS,
  [VALUE.W85P10S]: ROUTE_PATH.WHOLE_LIFE.MARKET_CONDUCTS,
  [VALUE.LIFEREADY_CODE.LIFEREADY12]: ROUTE_PATH.LIFE_READY.MARKET_CONDUCTS,
  [VALUE.LIFEREADY_CODE.LIFEREADY18_HIGHER]: ROUTE_PATH.LIFE_READY.MARKET_CONDUCTS,
  [VALUE.LIFEREADY_CODE.LIFEREADY18_UNDER]: ROUTE_PATH.LIFE_READY.MARKET_CONDUCTS,
  [VALUE.LIFEREADY_CODE.LIFEREADY6]: ROUTE_PATH.LIFE_READY.MARKET_CONDUCTS,
  [VALUE.LIFEREADY_CODE.LIFEREADY99_HIGHER]: ROUTE_PATH.LIFE_READY.MARKET_CONDUCTS,
  [VALUE.LIFEREADY_CODE.LIFEREADY99_UNDER]: ROUTE_PATH.LIFE_READY.MARKET_CONDUCTS,
  [VALUE.LIFEREADY_CAMPAIGN_CODE_W99F99H]: ROUTE_PATH.WHOLE_LIFE.MARKET_CONDUCTS,
  [VALUE.LIFEREADY_CAMPAIGN_CODE_W99F99M]: ROUTE_PATH.WHOLE_LIFE.MARKET_CONDUCTS,
  [VALUE.BUMNAN_READY_CODE.BUMNAN_READY_8]: ROUTE_PATH.BUMNAN_READY.MARKET_CONDUCTS,
  [VALUE.BUMNAN_READY_CODE.BUMNAN_READY_60]: ROUTE_PATH.BUMNAN_READY.MARKET_CONDUCTS,
  [VALUE.ULTIMATE_GROWTH_CODE.ULTIMATE_GROWTH_2010]: ROUTE_PATH.TERM_LIFE.MARKET_CONDUCTS,
  [VALUE.ULTIMATE_GROWTH_CODE.ULTIMATE_GROWTH_2515]: ROUTE_PATH.TERM_LIFE.MARKET_CONDUCTS,
  [VALUE.ULTIMATE_GROWTH_CODE.ULTIMATE_GROWTH_2525]: ROUTE_PATH.TERM_LIFE.MARKET_CONDUCTS,
  [VALUE.ISMART_80_6]: ROUTE_PATH.TERM_LIFE.MARKET_CONDUCTS,
  [VALUE.MRTA]: ROUTE_PATH.MRTA.MARKET_CONDUCTS,
  [VALUE.LIFETREASURE_CODE.LIFETREASURE_6]: ROUTE_PATH.LIFE_READY.MARKET_CONDUCTS,
  [VALUE.LIFETREASURE_CODE.LIFETREASURE_12]: ROUTE_PATH.LIFE_READY.MARKET_CONDUCTS,
  [VALUE.LIFETREASURE_CODE.LIFETREASURE_18]: ROUTE_PATH.LIFE_READY.MARKET_CONDUCTS,
}

export const PLANCODE_ROUTE_FNA = {
  [VALUE.CIWLM39]: ROUTE_PATH.S7CC.MARKET_CONDUCTS,
  [VALUE.CIWLM50]: ROUTE_PATH.S7CC.MARKET_CONDUCTS,
  [VALUE.CIWLM60]: ROUTE_PATH.S7CC.MARKET_CONDUCTS,
  [VALUE.CIWLS39]: ROUTE_PATH.S7CC.MARKET_CONDUCTS,
  [VALUE.CIWLS50]: ROUTE_PATH.S7CC.MARKET_CONDUCTS,
  [VALUE.CIWLS65]: ROUTE_PATH.S7CC.MARKET_CONDUCTS,
  [VALUE.IHEALTHY_ULTRA_CODE.LIFE_LEGACY_99]: ROUTE_PATH.IHEALTHY_ULTRA.MARKET_CONDUCTS,
  [VALUE.IHEALTHY_ULTRA_CODE.LIFE_LEGACY_60]: ROUTE_PATH.IHEALTHY_ULTRA.MARKET_CONDUCTS,
  [VALUE.IHEALTHY_ULTRA_CODE.IPROTECT_85]: ROUTE_PATH.IHEALTHY_ULTRA.MARKET_CONDUCTS,
  [VALUE.IHEALTHY_ULTRA_CODE['12PL']]: ROUTE_PATH.IHEALTHY_ULTRA.MARKET_CONDUCTS,
  [VALUE.IHEALTHY_ULTRA_CODE.IHEALTHY_85]: ROUTE_PATH.IHEALTHY_ULTRA.MARKET_CONDUCTS,
  [VALUE.IHEALTHY_ULTRA_CODE.IHEALTHY_60]: ROUTE_PATH.IHEALTHY_ULTRA.MARKET_CONDUCTS,
  [VALUE.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PROTECT_18.JUNIOR]: ROUTE_PATH.IHEALTHY_ULTRA.MARKET_CONDUCTS,
  [VALUE.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PROTECT_18.SENIOR]: ROUTE_PATH.IHEALTHY_ULTRA.MARKET_CONDUCTS,
  [VALUE.IHEALTHY_ULTRA_MULTI_CODE.LIFE_ENSURE_10.JUNIOR]: ROUTE_PATH.IHEALTHY_ULTRA.MARKET_CONDUCTS,
  [VALUE.IHEALTHY_ULTRA_MULTI_CODE.LIFE_ENSURE_10.SENIOR]: ROUTE_PATH.IHEALTHY_ULTRA.MARKET_CONDUCTS,
  [VALUE.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_20_10.JUNIOR]: ROUTE_PATH.IHEALTHY_ULTRA.MARKET_CONDUCTS,
  [VALUE.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_20_10.SENIOR]: ROUTE_PATH.IHEALTHY_ULTRA.MARKET_CONDUCTS,
  [VALUE.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_25_15.JUNIOR]: ROUTE_PATH.IHEALTHY_ULTRA.MARKET_CONDUCTS,
  [VALUE.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_25_15.SENIOR]: ROUTE_PATH.IHEALTHY_ULTRA.MARKET_CONDUCTS,
  [VALUE.WLNP85]: ROUTE_PATH.WHOLE_LIFE.MARKET_CONDUCTS,
  [VALUE.WLNP60L]: ROUTE_PATH.WHOLE_LIFE.MARKET_CONDUCTS,
  [VALUE.WLNP99L]: ROUTE_PATH.WHOLE_LIFE.MARKET_CONDUCTS,
  [VALUE.LIFE_SAVE_PRO_20P10_CODE.JUNIOR]: ROUTE_PATH.LIFE_SAVE_PRO.MARKET_CONDUCTS,
  [VALUE.LIFE_SAVE_PRO_20P10_CODE.SENIOR]: ROUTE_PATH.LIFE_SAVE_PRO.MARKET_CONDUCTS,
  [VALUE.LIFE_SAVE_PRO_25P15_CODE.JUNIOR]: ROUTE_PATH.LIFE_SAVE_PRO.MARKET_CONDUCTS,
  [VALUE.LIFE_SAVE_PRO_25P15_CODE.SENIOR]: ROUTE_PATH.LIFE_SAVE_PRO.MARKET_CONDUCTS,
  [VALUE.CODE_12PL]: ROUTE_PATH.WHOLE_LIFE.MARKET_CONDUCTS,
  [VALUE.WL88J]: ROUTE_PATH.WHOLE_LIFE.MARKET_CONDUCTS,
  [VALUE.WL88S]: ROUTE_PATH.WHOLE_LIFE.MARKET_CONDUCTS,
  [VALUE.W85P10J]: ROUTE_PATH.WHOLE_LIFE.MARKET_CONDUCTS,
  [VALUE.W85P10S]: ROUTE_PATH.WHOLE_LIFE.MARKET_CONDUCTS,
  [VALUE.LIFEREADY_CODE.LIFEREADY12]: ROUTE_PATH.LIFE_READY.MARKET_CONDUCTS,
  [VALUE.LIFEREADY_CODE.LIFEREADY18_HIGHER]: ROUTE_PATH.LIFE_READY.MARKET_CONDUCTS,
  [VALUE.LIFEREADY_CODE.LIFEREADY18_UNDER]: ROUTE_PATH.LIFE_READY.MARKET_CONDUCTS,
  [VALUE.LIFEREADY_CODE.LIFEREADY6]: ROUTE_PATH.LIFE_READY.MARKET_CONDUCTS,
  [VALUE.LIFEREADY_CODE.LIFEREADY99_HIGHER]: ROUTE_PATH.LIFE_READY.MARKET_CONDUCTS,
  [VALUE.LIFEREADY_CODE.LIFEREADY99_UNDER]: ROUTE_PATH.LIFE_READY.MARKET_CONDUCTS,
  [VALUE.LIFEREADY_CAMPAIGN_CODE_W99F99H]: ROUTE_PATH.WHOLE_LIFE.MARKET_CONDUCTS,
  [VALUE.LIFEREADY_CAMPAIGN_CODE_W99F99M]: ROUTE_PATH.WHOLE_LIFE.MARKET_CONDUCTS,
  [VALUE.BUMNAN_READY_CODE.BUMNAN_READY_8]: ROUTE_PATH.BUMNAN_READY.MARKET_CONDUCTS,
  [VALUE.BUMNAN_READY_CODE.BUMNAN_READY_60]: ROUTE_PATH.BUMNAN_READY.MARKET_CONDUCTS,
  [VALUE.ULTIMATE_GROWTH_CODE.ULTIMATE_GROWTH_2010]: ROUTE_PATH.TERM_LIFE.MARKET_CONDUCTS,
  [VALUE.ULTIMATE_GROWTH_CODE.ULTIMATE_GROWTH_2515]: ROUTE_PATH.TERM_LIFE.MARKET_CONDUCTS,
  [VALUE.ULTIMATE_GROWTH_CODE.ULTIMATE_GROWTH_2525]: ROUTE_PATH.TERM_LIFE.MARKET_CONDUCTS,
  [VALUE.ISMART_80_6]: ROUTE_PATH.TERM_LIFE.MARKET_CONDUCTS,
  [VALUE.MRTA]: ROUTE_PATH.MRTA.MARKET_CONDUCTS,
  [VALUE.LIFETREASURE_CODE.LIFETREASURE_6]: ROUTE_PATH.LIFE_READY.MARKET_CONDUCTS,
  [VALUE.LIFETREASURE_CODE.LIFETREASURE_12]: ROUTE_PATH.LIFE_READY.MARKET_CONDUCTS,
  [VALUE.LIFETREASURE_CODE.LIFETREASURE_18]: ROUTE_PATH.LIFE_READY.MARKET_CONDUCTS,
}

export const PLANCODE_NEW_ROUTE_FNA = {
  [VALUE.CIWLM39]: ROUTE_PATH.S7CC.MATURITY_VALIDATE,
  [VALUE.CIWLM50]: ROUTE_PATH.S7CC.MATURITY_VALIDATE,
  [VALUE.CIWLM60]: ROUTE_PATH.S7CC.MATURITY_VALIDATE,
  [VALUE.CIWLS39]: ROUTE_PATH.S7CC.MATURITY_VALIDATE,
  [VALUE.CIWLS50]: ROUTE_PATH.S7CC.MATURITY_VALIDATE,
  [VALUE.CIWLS65]: ROUTE_PATH.S7CC.MATURITY_VALIDATE,
  [VALUE.IHEALTHY_ULTRA_CODE.LIFE_LEGACY_99]: ROUTE_PATH.IHEALTHY_ULTRA.INSURED_INFORMATION,
  [VALUE.IHEALTHY_ULTRA_CODE.LIFE_LEGACY_60]: ROUTE_PATH.IHEALTHY_ULTRA.INSURED_INFORMATION,
  [VALUE.IHEALTHY_ULTRA_CODE.IPROTECT_85]: ROUTE_PATH.IHEALTHY_ULTRA.INSURED_INFORMATION,
  [VALUE.IHEALTHY_ULTRA_CODE['12PL']]: ROUTE_PATH.IHEALTHY_ULTRA.INSURED_INFORMATION,
  [VALUE.IHEALTHY_ULTRA_CODE.IHEALTHY_85]: ROUTE_PATH.IHEALTHY_ULTRA.INSURED_INFORMATION,
  [VALUE.IHEALTHY_ULTRA_CODE.IHEALTHY_60]: ROUTE_PATH.IHEALTHY_ULTRA.INSURED_INFORMATION,
  [VALUE.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PROTECT_18.JUNIOR]: ROUTE_PATH.IHEALTHY_ULTRA.INSURED_INFORMATION,
  [VALUE.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PROTECT_18.SENIOR]: ROUTE_PATH.IHEALTHY_ULTRA.INSURED_INFORMATION,
  [VALUE.IHEALTHY_ULTRA_MULTI_CODE.LIFE_ENSURE_10.JUNIOR]: ROUTE_PATH.IHEALTHY_ULTRA.INSURED_INFORMATION,
  [VALUE.IHEALTHY_ULTRA_MULTI_CODE.LIFE_ENSURE_10.SENIOR]: ROUTE_PATH.IHEALTHY_ULTRA.INSURED_INFORMATION,
  [VALUE.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_20_10.JUNIOR]: ROUTE_PATH.IHEALTHY_ULTRA.INSURED_INFORMATION,
  [VALUE.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_20_10.SENIOR]: ROUTE_PATH.IHEALTHY_ULTRA.INSURED_INFORMATION,
  [VALUE.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_25_15.JUNIOR]: ROUTE_PATH.IHEALTHY_ULTRA.INSURED_INFORMATION,
  [VALUE.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_25_15.SENIOR]: ROUTE_PATH.IHEALTHY_ULTRA.INSURED_INFORMATION,
  [VALUE.WLNP85]: ROUTE_PATH.WHOLE_LIFE.INSURED_INFORMATION,
  [VALUE.WLNP60L]: ROUTE_PATH.WHOLE_LIFE.INSURED_INFORMATION,
  [VALUE.WLNP99L]: ROUTE_PATH.WHOLE_LIFE.INSURED_INFORMATION,
  [VALUE.LIFE_SAVE_PRO_20P10_CODE.JUNIOR]: ROUTE_PATH.LIFE_SAVE_PRO.INSURED_INFORMATION,
  [VALUE.LIFE_SAVE_PRO_20P10_CODE.SENIOR]: ROUTE_PATH.LIFE_SAVE_PRO.INSURED_INFORMATION,
  [VALUE.LIFE_SAVE_PRO_25P15_CODE.JUNIOR]: ROUTE_PATH.LIFE_SAVE_PRO.INSURED_INFORMATION,
  [VALUE.LIFE_SAVE_PRO_25P15_CODE.SENIOR]: ROUTE_PATH.LIFE_SAVE_PRO.INSURED_INFORMATION,
  [VALUE.CODE_12PL]: ROUTE_PATH.WHOLE_LIFE.INSURED_INFORMATION,
  [VALUE.WL88J]: ROUTE_PATH.WHOLE_LIFE.INSURED_INFORMATION,
  [VALUE.WL88S]: ROUTE_PATH.WHOLE_LIFE.INSURED_INFORMATION,
  [VALUE.W85P10J]: ROUTE_PATH.WHOLE_LIFE.INSURED_INFORMATION,
  [VALUE.W85P10S]: ROUTE_PATH.WHOLE_LIFE.INSURED_INFORMATION,
  [VALUE.LIFEREADY_CODE.LIFEREADY12]: ROUTE_PATH.LIFE_READY.INSURED_INFORMATION,
  [VALUE.LIFEREADY_CODE.LIFEREADY18_HIGHER]: ROUTE_PATH.LIFE_READY.INSURED_INFORMATION,
  [VALUE.LIFEREADY_CODE.LIFEREADY18_UNDER]: ROUTE_PATH.LIFE_READY.INSURED_INFORMATION,
  [VALUE.LIFEREADY_CODE.LIFEREADY6]: ROUTE_PATH.LIFE_READY.INSURED_INFORMATION,
  [VALUE.LIFEREADY_CODE.LIFEREADY99_HIGHER]: ROUTE_PATH.LIFE_READY.INSURED_INFORMATION,
  [VALUE.LIFEREADY_CODE.LIFEREADY99_UNDER]: ROUTE_PATH.LIFE_READY.INSURED_INFORMATION,
  [VALUE.LIFEREADY_CAMPAIGN_CODE_W99F99H]: ROUTE_PATH.WHOLE_LIFE.INSURED_INFORMATION,
  [VALUE.LIFEREADY_CAMPAIGN_CODE_W99F99M]: ROUTE_PATH.WHOLE_LIFE.INSURED_INFORMATION,
  [VALUE.BUMNAN_READY_CODE.BUMNAN_READY_8]: ROUTE_PATH.BUMNAN_READY.INSURED_INFORMATION,
  [VALUE.BUMNAN_READY_CODE.BUMNAN_READY_60]: ROUTE_PATH.BUMNAN_READY.INSURED_INFORMATION,
  [VALUE.ULTIMATE_GROWTH_CODE.ULTIMATE_GROWTH_2010]: ROUTE_PATH.TERM_LIFE.INSURED_INFORMATION,
  [VALUE.ULTIMATE_GROWTH_CODE.ULTIMATE_GROWTH_2515]: ROUTE_PATH.TERM_LIFE.INSURED_INFORMATION,
  [VALUE.ULTIMATE_GROWTH_CODE.ULTIMATE_GROWTH_2525]: ROUTE_PATH.TERM_LIFE.INSURED_INFORMATION,
  [VALUE.ISMART_80_6]: ROUTE_PATH.TERM_LIFE.INSURED_INFORMATION,
  [VALUE.MRTA]: ROUTE_PATH.MRTA.INSURED_INFORMATION,
  [VALUE.LIFETREASURE_CODE.LIFETREASURE_6]: ROUTE_PATH.LIFE_READY.INSURED_INFORMATION,
  [VALUE.LIFETREASURE_CODE.LIFETREASURE_12]: ROUTE_PATH.LIFE_READY.INSURED_INFORMATION,
  [VALUE.LIFETREASURE_CODE.LIFETREASURE_18]: ROUTE_PATH.LIFE_READY.INSURED_INFORMATION,
}

const getRedirectLink = (plancode, category, location, isOnFnaPhase) => {
  const code = category === VALUE.MRTA ? VALUE.MRTA : plancode
  if (!PLANCODE_ROUTE_QQ[code] && !PLANCODE_ROUTE_FNA[code]) return

  const path = location && location.pathname
  if (isQuickQuotePath(path) && !isOnFnaPhase) {
    return PATH.QUICK_QUOTE + PLANCODE_ROUTE_QQ[code]
  } else if (isQuickQuotePath(path) && isOnFnaPhase) {
    return PATH.QUICK_QUOTE + PLANCODE_ROUTE_QQ_FNA_PHASE[code]
  } else if (isFNAPath(path)) {
    return getToggles().ENABLE_FNA_V3 ? PATH.FNA + PLANCODE_NEW_ROUTE_FNA[code] : PATH.FNA + PLANCODE_ROUTE_FNA[code]
  } else {
    return PLANCODE_ROUTE_QQ[code]
  }
}
export class _OrdinaryBasicPlan extends React.Component<OrdinaryBasicPlanProps, *> {
  componentWillUnmount() {
    // $FlowFixMe
    if (['PUSH', 'REPLACE'].includes(this.props.history.action)) {
      this.props.resetOrdinaryPermission()
    }
  }

  render() {
    const {
      displayProduct,
      isSelected,
      cssClass,
      validateProduct,
      proceedToOrdinaryProduct,
      location,
      isOnFnaPhase,
      disabled,
    } = this.props
    if (proceedToOrdinaryProduct.isValid && isSelected)
      return <Redirect to={getRedirectLink(displayProduct.code, displayProduct.category, location, isOnFnaPhase)} />
    return (
      <div
        key={displayProduct.code}
        className={['product', cssClass, isSelected ? 'selected' : '', disabled ? 'disabled' : ''].join(' ')}
        data-product-name={displayProduct.displayName}
        data-product-code={displayProduct.code}
        onClick={() => !disabled && validateProduct(displayProduct)}
      >
        <div className="product-card-container">
          <BasicPlanSummary displayProduct={displayProduct} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: AppState, props: ownProps) => ({
  displayProduct: props.displayProduct,
  isSelected: props.isSelected,
  cssClass: props.cssClass,
  validateProduct: props.validateProduct,
  proceedToOrdinaryProduct: state.permissionOrdinary.proceedToOrdinaryProduct,
})

export const OrdinaryBasicPlan = withRouter(
  connect(mapStateToProps, (dispatch: Dispatch<*>) => ({
    resetOrdinaryPermission: bindActionCreators(resetOrdinaryPermission, dispatch),
  }))(_OrdinaryBasicPlan)
)
