// @flow
import type { InvalidOccupationFactorRule } from 'core/data-model/rider/rules'
import type { RiderEligibilities } from 'core/data-model/rider'
import type { ValidationMessage } from 'core/data-model/validation'
import riderMessages from 'core/data-model/constants/validation-messages'

export const checkInvalidOccupationFactorRule = (
  rule: InvalidOccupationFactorRule,
  eligibilities: RiderEligibilities
): ValidationMessage => {
  let errorMessage = ''

  if (eligibilities.occupationFactor === rule.value) {
    errorMessage = riderMessages.RULE_RIDER_OCCUPATION_FACTOR
  }
  return {
    type: rule.validationType,
    message: errorMessage,
  }
}
