//@flow

export default {
  GENERATING_PDF: 'กำลังสร้างไฟล​์',
  LOADING: 'Loading..',
  GENERATE_PDF_ERROR_MESSAGE: 'เกิดข้อผิดพลาดในการสร้างไฟล์ กรุณาลองใหม่',
  CHECK_PDF_MESSAGE: 'ดาวน์โหลด PDF เสร็จสิ้น กรุณาตรวจสอบ',
  POPUP_CLOSE_BUTTON: 'ปิด',
  POPUP_BLOCKER_ENABLED: 'กรุณาอนุญาตป๊อปอัปเพื่อแชร์ไฟล์',
  CAN_NOT_CREATE_DOCUMENT: 'ไม่สามารถสร้างเอกสารได้',
  ADBLOCK_DETECTED: 'กรุณาปิด AdBlock และกดรีเฟรชเพื่อแชร์ไฟล์',
  BOTH_ADBLOCK_POPUP_DETECTED: 'กรุณาอนุญาตป๊อปอัพและปิด Adblock เพื่อแชร์ไฟล์',
  VALIDATING_SALE_PERMISSION: 'กำลังตรวจสอบสิทธิการขาย\n',
  INSTRUCTIONS_FOR_BI: 'การเปิดไฟล์และแชร์สรุปสัญญา',
  INSTRUCTIONS_FOR_RPQ: 'การเปิดไฟล์และแชร์ RPQ',
  INSTRUCTIONS_FOR_RA: 'การเปิดไฟล์และแชร์ RA',
  ENTER_DAY_MONTH_YEAR: 'ใส่วัน / เดือน / ปีเกิด',
  TO_OPEN_FILE: 'ของผู้เอาประกันเพื่อเปิดไฟล์',
  COPY: 'copy-number',
  OPEN_PDF: 'แชร์สรุปสัญญา',
  DASH: '-',
  OK: 'ตกลง',
  FNA_VALIDATE_TITLE: 'กรอกข้อมูลไม่ครบ',
  FNA_REQUIRED_NEED_TITLE: 'ความต้องการของท่าน',
  FNA_REQUIRED_NEED_MESSAGE: 'กรุณาระบุความต้องการของท่าน และวางแผนทางการเงินของท่าน',
  FNA_REQUIRED_MARKET_CONDUCTS_TITLE: 'แบบสอบถาม',
  FNA_REQUIRED_MARKET_CONDUCTS_MESSAGE: 'กรุณาตอบแบบสอบถามเพื่อวิเคราะห์ความต้องการซื้อผลิตภัณฑ์ประกันชีวิต',
  LOGIN_LIMIT_SESSION_EXCEEDED: 'LOGIN_LIMIT_SESSION_EXCEEDED',
}
