// @flow
import _ from 'lodash'

import { d } from 'core/service/lib/decimal'
import { round } from 'core/service/lib/number-format'
import { isGLTSPGroup } from 'quick-quote/product-mrta/coverage-plan/selectors'
import type { Age } from 'core/data-model/insured'
import type { CashValueRates } from 'core/data-model/basic-plan/types'

export const calculateSurrenderCash = (cashValueRate: number, sumAssured: number, decimal: number = 0): number => {
  if (cashValueRate < 0 || sumAssured < 0) return 0
  const multiplyResult = d(cashValueRate).times(d(sumAssured))
  const surrenderCash = d(multiplyResult).dividedBy(d(1000))
  return round(d(surrenderCash).toNumber(), decimal)
}

export const calculateSurrenderCashWithoutRound = (cashValueRate: number, sumAssured: number): number => {
  if (cashValueRate < 0 || sumAssured < 0) return 0
  const multiplyResult = d(cashValueRate).times(d(sumAssured))
  const surrenderCash = d(multiplyResult).dividedBy(d(1000))
  return d(surrenderCash).toNumber()
}

export const queryCashValueRate = (
  productCode: string,
  gender: string,
  age: Age,
  policyYear: number,
  cashValueRates: CashValueRates[]
): number => {
  const queryAge: number = age.unit !== 'year' ? 0 : age.value

  const filteredCashValueRates = _.find(cashValueRates, (rate) => rate.productCode === productCode)
  return _.get(filteredCashValueRates, ['cashValueRates', gender, queryAge.toString(), policyYear.toString()], 0)
}

export const queryMRTACashValueRate = (
  productCode: string,
  productPlanCode: string,
  gender: string,
  age: Age,
  policyYear: number,
  cashValueRates: CashValueRates[]
): number => {
  const queryAge: number = age.unit !== 'year' ? 0 : age.value
  const filteredCashValueRates = _.find(cashValueRates, (rate) => rate.productCode === productCode)
  let qryPlanCode = productPlanCode
  if (isGLTSPGroup(productCode)) {
    qryPlanCode = productPlanCode.substring(0, 5)
  }

  return _.get(
    filteredCashValueRates,
    ['cashValueRates', qryPlanCode, gender, queryAge.toString(), policyYear.toString()],
    0
  )
}

export const getSurrenderCashMRTA = ({
  basicPlanQuery,
  gender,
  age,
  yearEnd,
  cashValueRates,
  basicSumAssured,
  validSelectedRiders,
}) => {
  const basicCashValueRate = queryMRTACashValueRate(
    basicPlanQuery.code,
    basicPlanQuery.productPlanCode,
    gender,
    age,
    yearEnd,
    cashValueRates
  )
  const basicCV = calculateSurrenderCashWithoutRound(basicCashValueRate, basicSumAssured)
  const riderCV = validSelectedRiders.reduce(function(sumRiderCV, rider) {
    const RiderCVRate = queryMRTACashValueRate(
      rider.code,
      rider.selectedPlan.planCode,
      gender,
      age,
      yearEnd,
      cashValueRates
    )
    return sumRiderCV + calculateSurrenderCashWithoutRound(RiderCVRate, rider.sumAssured)
  }, 0)
  return round(basicCV + riderCV, 0)
}
