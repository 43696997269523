//@flow
export type PageViewCapturerProps = {
  pageViewHandlers: Function[],
}

export default ({ pageViewHandlers }: PageViewCapturerProps) => (WrappedComponent: Object): Function => {
  return class extends React.Component<*> {
    componentDidMount() {
      pageViewHandlers.forEach((handler) => handler(this.props.location.pathname))
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      if (this.props.location.pathname !== nextProps.location.pathname) {
        pageViewHandlers.forEach((handler) => handler(this.props.location.pathname))
      }
    }

    render() {
      return <WrappedComponent {...this.props} />
    }
  }
}
