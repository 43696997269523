//@flow
import styled from 'styled-components'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import PropTypes from 'prop-types'

export class _Confirmation extends React.Component {
  static defaultProps = {
    message: { title: '', body: '' },
  }

  constructor(props: *) {
    super(props)
  }

  render() {
    const {
      toggle,
      className,
      isOpen,
      message: { title, body },
      confirmNo,
      confirmYes,
    } = this.props
    return (
      <Modal
        toggle={toggle}
        className={className}
        modalClassName="default-bootstrap"
        isOpen={isOpen}
        backdrop={true}
        autoFocus={false}
      >
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody dangerouslySetInnerHTML={{ __html: body }} />
        <ModalFooter>
          <Button color="primary" outline onClick={confirmNo}>
            ยกเลิก
          </Button>
          <Button color="primary" onClick={confirmYes}>
            ยืนยัน
          </Button>
        </ModalFooter>
      </Modal>
    )
  }
}

const Confirmation = styled(_Confirmation)`
  .modal-title {
    font-size: 1rem;
    color: #2425aa;
    font-weight: bold;
  }
  .modal-header {
    padding: 5px 15px 10px 15px;
  }
  .modal-body {
    background: white;
    position: relative;
    flex: 1 1 auto;
    padding: 15px;
  }
  .modal-footer {
    justify-content: flex-end;
  }
`

Confirmation.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool,
  confirmYes: PropTypes.func,
  confirmNo: PropTypes.func,
  message: PropTypes.shape({ title: PropTypes.string, body: PropTypes.string }),
}

export default Confirmation
