// @flow

import type { WPDAgeRule } from 'core/data-model/rider/rules'
import type { RiderEligibilities } from 'core/data-model/rider'
import type { ValidationMessage } from 'core/data-model/validation'

import { formatAge } from 'core/service/lib/age-format'
import { d } from 'core/service/lib/decimal'
import { getToggles } from 'quick-quote/feature-toggles'

import riderMessages from 'core/data-model/constants/validation-messages'
import Mustache from 'mustache'

export const checkWPDAgeRule = (rule: WPDAgeRule, eligibilities: RiderEligibilities): ValidationMessage => {
  let errorMessage = ''

  const limitAge = d(65).minus(Math.min(d(25).minus(eligibilities.insured.age.value), eligibilities.yearsOfPayment))

  if (getToggles().ENABLE_WPD_AGE_RULE) {
    if (eligibilities.payerAge.value > limitAge) {
      errorMessage = Mustache.render(riderMessages.RULE_RIDER_PAYER_AGE, {
        insuredAge: formatAge(eligibilities.insured.age),
        maximumPayerAge: formatAge(eligibilities.maxPayerAge),
      })
    }
  }

  if (eligibilities.basicPaymentPeriod.value < 10) {
    errorMessage = Mustache.render(riderMessages.RULE_YEAR_OF_PAYMENT_WPD, {
      riderName: eligibilities.rider.name,
      yearsOfPayment: eligibilities.basicPaymentPeriod.value,
    })
  }
  return {
    type: rule.validationType,
    message: errorMessage,
  }
}
