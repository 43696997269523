import { compose, defaultProps, withStateHandlers } from 'recompose'
import { connect } from 'react-redux'
import { noop } from 'lodash/fp'
import styled from 'styled-components'
import NumberInput from 'react-number-format'
import { getCurrentApp, getFileWithName, isPDFFileType, isSubmitting } from 'e-submission/apps/selectors'
import {
  getAllocateReceiptAmount,
  getPaidReceipts,
  getQuickQuoteTotalPremium,
  getRemainReceiptAmount,
  getValidatedTotalPaidPremium,
  isAllReceiptCreated,
} from 'e-submission/domain/data-model/form/selectors'
import { Flex } from 'rebass'
import Card from 'e-submission/components/Card'
import Content from 'e-submission/components/Layout/Content'
import Icon from 'e-submission/components/Icon'
import { Button as PrimaryButton } from 'reactstrap'
import { ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Dialog from 'e-submission/components/Dialog'
import waterMaskUrl from 'assets/images/water-mask.png'
import viewerUrl from 'file-loader?name=pdf-viewer/web/viewer.html&emitFile=false!e-submission/pdf-viewer/web/viewer.html'
import { previewPhoto, removeFile } from 'e-submission/apps/actions'

export const WatermarkModal = styled(Dialog)`
  .modal-content {
    height: 100vh;
  }
  .modal-title {
    display: flex;
    flex: 1 0 100%;
    justify-content: space-between;
    align-items: center;
    button {
      padding: 5px 10px;
    }
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    padding: 0 !important;
    iframe {import { PremiumSummaryTable } from './PremiumSummaryTable';
      flex: 1;
    }
  }
  .modal-footer {
    justify-content: space-between;
  }
`
const onLoadIframe = (evt) => {
  const iframe = evt.target
  const iframeDocument = iframe.contentWindow.document
  const toolbar = iframeDocument.querySelector('.toolbar')
  const main = iframeDocument.querySelector('#mainContainer')
  const content = iframeDocument.querySelector('#viewerContainer')
  const customStyle = document.createElement('style')
  customStyle.type = 'text/css'
  customStyle.textContent = `
    .canvasWrapper:before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-image: url(${waterMaskUrl});
      background-size: 200px;
      z-index: 10;
    }
  `
  iframeDocument.head.appendChild(customStyle)
  toolbar.style.visibility = 'hidden'
  content.style.top = 0
}

export const WithWatermark = styled(({ image, ...props }) => <Flex {...props} />)`
  position: relative;
  overflow: hidden;
  background-color: black;
  background-image: url('${({ image }) => image}');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url(${waterMaskUrl});
    background-size: 200px;
    background-attachment: fixed;
  }
`

export const _PaymentInfo = styled(
  ({ isValid, className, totalPremium, allocatePremium, remainPremium, isAllReceiptCreated }) => (
    <div className={className}>
      <div className="title">ชำระเบี้ยงวดแรก</div>
      <div className="details-premium-container">
        <Flex justify="space-between">
          <span>งวดแรก</span>
          <NumberInput value={totalPremium} displayType="text" suffix=" บาท" thousandSeparator decimalPrecision={2} />
        </Flex>
        {!isAllReceiptCreated && (
          <Flex justify="space-between">
            <span>จ่ายไปแล้ว</span>
            <NumberInput
              value={allocatePremium}
              displayType="text"
              suffix=" บาท"
              thousandSeparator
              decimalPrecision={2}
            />
          </Flex>
        )}
      </div>
      {!isAllReceiptCreated && (
        <Flex justify="space-between" className={`remain-premium-container ${isValid ? 'valid' : ''}`}>
          <span>เหลือจ่าย</span>
          <NumberInput value={remainPremium} displayType="text" suffix=" บาท" thousandSeparator decimalPrecision={2} />
        </Flex>
      )}
    </div>
  )
)`
  padding: 1rem 1rem;
  background-color: #fff;

  .title {
    font-size: 1rem;
    font-weight: bold;
    color: #2425aa;
    padding-bottom: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.variables['$color-silver']};
  }
  .details-premium-container {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid ${({ theme }) => theme.variables['$color-silver']};
  }

  .remain-premium-container {
    color: #1cc54e;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px double ${({ theme }) => theme.variables['$color-silver']};
    border-width: 4px;
    font-weight: bold;
    transition: color 0.195s;
    &.valid {
      color: ${({ theme }) => theme.variables['$brand-success']};
    }
  }
`

export const PaymentInfo = compose(
  connect((state) => {
    const app = getCurrentApp(state)
    const totalPremium = getQuickQuoteTotalPremium(app)
    const allocatePremium = getAllocateReceiptAmount(app)
    const remainPremium = getRemainReceiptAmount(app)
    const validatedPaidAmount = getValidatedTotalPaidPremium(app)
    const isFirstTimeEnterPaymentAmount = !isSubmitting(state)

    return {
      totalPremium,
      allocatePremium,
      remainPremium,
      validatedPaidAmount,
      isAllReceiptCreated: isAllReceiptCreated(app),
      isFirstTimeEnterPaymentAmount,
    }
  })
)(_PaymentInfo)

const Table = ({ amount, policyId, product, selectedModelFactorLabel, lastModified }) => {
  return (
    <TableContainer>
      <TitleHeader>
        <div className="subject">ข้อมูลชำระเบี้ยประกันภัย</div>
        <div className="info">
          {lastModified
            ? `ณ วันที่ ${new Date(lastModified).toLocaleDateString('th', {
                day: 'numeric',
                month: 'short',
                year: 'numeric',
              })} / ${new Date(lastModified)
                .toLocaleTimeString([], {
                  hour: '2-digit',
                  minute: '2-digit',
                })
                .replace(':', '.')}`
            : '-'}
        </div>
      </TitleHeader>
      <table id="premium-summary-table">
        <thead>
          <tr>
            <td>หมายเลขกรมธรรม์</td>
            <td>ผลิตภัณฑ์</td>
            <td>งวดการชำระ</td>
            <td>จำนวนที่ชำระ</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{policyId}</td>
            <td>{product}</td>
            <td>แบบ{selectedModelFactorLabel}</td>
            <td>
              <NumberInput value={amount} displayType="text" suffix=" บาท" thousandSeparator decimalPrecision={2} />
            </td>
          </tr>
        </tbody>
      </table>
    </TableContainer>
  )
}

const PaymentStatus = styled.div`
  padding: 1rem;
  background-color: #fff;
  color: #2425aa;
  font-size: 0.85rem;
`

const Detail = styled.div`
  flex-grow: 1;
  padding-left: 5px;
`

const Component = ({
  amount,
  maskCreditCardNumber,
  policyId,
  product,
  selectedModelFactorLabel,
  lastModified,
  showInfo,
  isPaid,
  isFinishCreditCardPaymentSection,
  onPreview,
  onClose,
  onOk,
  isOpen,
  isPDF,
  url,
}) => {
  return showInfo ? (
    <Content>
      <Flex className="ml-3 mb-2 mt-1">
        <ComponentTitle>
          <div className="component-container">
            <div className="title">
              <span className="title-text">ชำระเบี้ยประกัน</span>
              <div className="desc">โปรดตรวจสอบความถูกต้องของเบี้ยที่ท่านต้องชำระ</div>
            </div>
          </div>
        </ComponentTitle>
      </Flex>
      <Card column style={{ background: 'none', border: 'none' }} w={1} pl={[0, 2, 2]} pr={[0, 2, 2]}>
        <Table
          amount={amount}
          policyId={policyId}
          product={product}
          selectedModelFactorLabel={selectedModelFactorLabel}
          lastModified={lastModified}
        />
      </Card>
      <Card column style={{ background: 'none', border: 'none' }} w={1} pl={[0, 2, 2]} pr={[0, 2, 2]}>
        <PaymentInfo />
      </Card>
    </Content>
  ) : (
    <Content>
      <WatermarkModal isOpen={isOpen} toggle={onClose}>
        <ModalHeader tag="div">
          <div className="btn-left" onClick={onClose}>
            <Icon.arrowLeft className="icon" />
          </div>
          <div>หลักฐานการชำระเงิน</div>
          <div />
        </ModalHeader>
        <ModalBody>
          {!isPDF ? (
            <WithWatermark flex="1 1 100%" align="center" image={url} />
          ) : (
            <iframe src={`${viewerUrl}?file=${url}`} onLoad={onLoadIframe} />
          )}
        </ModalBody>
        <ModalFooter style={{ justifyContent: 'flex-end' }}>
          <PrimaryButton color="primary" onClick={onOk}>
            ตกลง
          </PrimaryButton>
        </ModalFooter>
      </WatermarkModal>
      <ComponentTitle>
        <div className="component-container">
          <div className="title">
            <span className="title-text">ชำระเบี้ยประกัน</span>
            <div className="desc">โปรดตรวจสอบความถูกต้องของเบี้ยที่ท่านต้องชำระ</div>
          </div>
        </div>
      </ComponentTitle>
      <Table
        amount={amount}
        policyId={policyId}
        product={product}
        selectedModelFactorLabel={selectedModelFactorLabel}
        lastModified={lastModified}
      />
      {isPaid ? (
        <PaymentStatus>
          {isFinishCreditCardPaymentSection ? (
            <Flex justify="space-between" key="credit-card-status">
              <Flex align="center">
                <Icon.creditCard />
                <Detail>{maskCreditCardNumber}</Detail>
              </Flex>
              <Flex align="center">
                <Icon.circleCheck />
                <Detail>ชำระด้วยช่องทาง ชำระด้วยบัตรเครดิต</Detail>
              </Flex>
            </Flex>
          ) : (
            <Flex justify="space-between" key="net-bank-status">
              <Flex align="center">
                <Icon.netBank />
                <Detail style={{ cursor: 'pointer' }} onClick={onPreview}>
                  หลักฐานการชำระเงิน
                </Detail>
              </Flex>
              <Flex align="center">
                <Icon.circleCheck />
                <Detail>ชำระด้วยช่องทาง อินเทอร์เน็ต แบงก์กิ้ง</Detail>
              </Flex>
            </Flex>
          )}
        </PaymentStatus>
      ) : null}
    </Content>
  )
}

const ComponentTitle = styled.div`
  display: block;

  .title {
    margin-bottom: 0.5rem;
  }

  .title-text {
    font-size: 1.325rem;
    color: #2425aa;
    font-weight: bold;
  }

  .desc {
    font-size: 1rem;
    color: #2425aa;
  }
`

const TableContainer = styled.div`
  background-color: #fff;

  table {
    width: 100%;
    text-align: center;
    font-size: 0.875rem;
  }

  table,
  tr,
  td {
    border: none;
  }

  tbody {
    background-color: #e5e5e5;
  }
`

const TitleHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 0.5rem 0.5rem 0.5rem;
  margin: 0.25rem 0.5rem;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;

  .subject {
    font-size: 1rem;
    font-weight: bold;
    color: #2425aa;
    text-align: left;
  }

  .info {
    font-size: 0.75rem;
    font-weight: 400;
    color: #cccccc;
    text-align: right;
  }
`

export default (hoc) =>
  compose(
    defaultProps({
      onOk: noop,
    }),
    withStateHandlers(
      { isOpen: false },
      {
        openModal: () => () => {
          return { isOpen: true }
        },
        closeModal: () => () => {
          return { isOpen: false }
        },
      }
    ),
    connect(
      (state, { name }) => {
        const app = getCurrentApp(state)
        return {
          url: getFileWithName(state, name),
          isPDF: isPDFFileType(state, name),
          maskCreditCardNumber: getPaidReceipts(app)[0]?.maskCreditCardNumber,
        }
      },
      (dispatch, { name, openModal, closeModal, onOk }) => ({
        onPreview: () => {
          openModal()
          dispatch(previewPhoto(name))
        },
        onClose: () => {
          closeModal()
          dispatch(removeFile(name))
        },
        onOk: () => {
          const isOk = onOk()
          if (isOk !== false) {
            closeModal()
            dispatch(removeFile(name))
          }
        },
      })
    )
  )(Component)
