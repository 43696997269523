import React, { useState } from 'react'
import { ModalConfirm } from '../components'
import { useSelector, useDispatch } from 'react-redux'
import {
  customizeDisplay,
  filterProductCategoryEmpty,
  filterProductsByCategory,
  getAvailableProductCategories,
  getDisplayProducts,
  getProductCategoryList,
  getProductsInCategory,
  getSelectedProductCategoryId,
  getViewConfiguration,
} from 'quick-quote/product-selection/selectors'
import { BasicPlanSummary } from 'quick-quote/product-selection/components/basic-plan-summary'
import { ProductCategorySelection } from 'quick-quote/components'
import { getToggles } from 'quick-quote/feature-toggles'
import { getUserAgentType } from 'identity/selectors'
import { selectProductCategory } from 'quick-quote/product-selection/actions'
import { clickHomeButton } from 'quick-quote/actions'
import { Button } from 'reactstrap'
import { analytics } from 'analytics/analytics'
import { makeNameGABrochureByName } from '../utils'
import { onOpenBrochure } from '../components/actions'

const BrochureProductList = (props) => {
  const [isVisiableModal, setIsVisiableModal] = useState(false)
  const [productSelect, setProductSelect] = useState(null)
  const state = useSelector((state) => state)
  let displayProducts = getDisplayProducts(state)
  const userAgentType = getUserAgentType(state)
  const productsInCategory = getProductsInCategory(getViewConfiguration(state))
  const categoryList = getProductCategoryList(getViewConfiguration(state))
  const productCategories = getToggles().ENABLE_HIDE_EMPTY_TAB_PRODUCT
    ? filterProductCategoryEmpty(displayProducts, categoryList, productsInCategory, userAgentType)
    : categoryList
  const availableCategories = getAvailableProductCategories(state)
  const defaultProductCategoryId = getSelectedProductCategoryId(state)
  const selectedProductCategoryId = productCategories.find((category) => category.id === defaultProductCategoryId)
    ? defaultProductCategoryId
    : productCategories[0].id
  const dispatch = useDispatch()
  const productList = filterProductsByCategory(
    displayProducts,
    productsInCategory,
    selectedProductCategoryId,
    userAgentType
  )
  displayProducts = customizeDisplay(productList)

  return (
    <div id="main-container" className="product-selection fna">
      <div id="product-selection">
        <div className="content">
          <ProductCategorySelection
            categoryList={productCategories}
            availableCategories={availableCategories}
            active={selectedProductCategoryId}
            onChange={(categoryId) => dispatch(selectProductCategory(categoryId))}
          />
          <div className="az-product-list product-list">
            {displayProducts.map((displayProduct) => (
              <div
                key={displayProduct.code}
                className={'product '}
                data-product-name={displayProduct.displayName}
                data-product-code={displayProduct.code}
                onClick={async () => {
                  const dataGA4 = {
                    category: 'AZ_BROCHURE',
                    action: makeNameGABrochureByName(displayProduct.displayName),
                    label: '',
                  }
                  analytics.sendCustomEvent(dataGA4)

                  let _getKeys = Object.keys(displayProduct.brochureLink)
                  if (_getKeys.length == 1) {
                    onOpenBrochure(displayProduct.brochureLink[_getKeys])
                  } else {
                    await setProductSelect(displayProduct)
                    await setIsVisiableModal(true)
                  }
                }}
              >
                <div className="product-card-container">
                  <BasicPlanSummary displayProduct={displayProduct} isBrochure={true} />
                </div>
              </div>
            ))}
          </div>
        </div>
        <ModalConfirm
          isOpen={isVisiableModal}
          application={productSelect}
          onExit={() => {
            setIsVisiableModal(false)
          }}
        />
      </div>
      <div id="nav-bottom" className="nav-bottom">
        <div className="nav-group">
          <div className="left">
            <Button
              onClick={() => dispatch(clickHomeButton())}
              type="button"
              color="primary"
              outline={true}
              disabled={false}
            >
              กลับ
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BrochureProductList
