import { renameProp, withProps, compose } from 'recompose'
import UploadDocCommand from 'e-submission/components/Card/UploadDocCommand'
import { CheckedCard } from 'e-submission/components/Card/DocumentCard'

const Component = ({ validateFile, action, value, ...props }) => (
  <UploadDocCommand action={action} validateFile={validateFile} CheckedComponent={CheckedCard} {...value} {...props} />
)

export default (hoc) =>
  compose(
    renameProp('validate', 'validateFile'),
    withProps(({ customText }) => ({
      action: customText,
    })),
    hoc
  )(Component)
