//@flow
import type { AdvisorApiUserInfo } from 'core/data-model/identity'
import type { Profile } from 'core/data-model/distributor'

type UserProfile = {
  given_name: string,
  family_name: string,
  email: string,
  agent_type: string,
}

type BancOffice = {
  branchName: string,
  areaName: string,
}

export const getDistributorProfile = (
  profile: UserProfile,
  userInfo: ?AdvisorApiUserInfo,
  bancOffice: ?BancOffice
): Profile => {
  const { given_name, family_name, email, agent_type } = profile
  const distributorInfo = !!userInfo ? getDistributorInfo(userInfo) : {}
  const office = !!bancOffice ? distributorOffice(bancOffice) : {}

  return {
    given_name,
    family_name,
    email,
    agent_type,
    ...distributorInfo,
    ...office,
  }
}

const distributorOffice = (bancOffice) => {
  const { branchName, areaName } = bancOffice
  return { branchName, areaName }
}

const mobileNumberFromContacts = (contactMethods) => {
  if (!contactMethods || !contactMethods.phoneContacts) return
  const mobilePhone = contactMethods.phoneContacts.find((p) => p.type === 'mobile')
  if (mobilePhone) return mobilePhone.localNumber
}

const activeSingleLicense = (party) => {
  if (!party || !party.registrations) return
  const singleLicense = party.registrations.find((r) => r.typeCode === 'SINGLE LIC' && r.state === 'active')
  if (singleLicense) return singleLicense.id
}
const getBasicLicenseNo = (party) => {
  if (!party || !party.registrations) return
  const singleLicense = party.registrations.find((r) => r.typeName === 'BASIC')
  if (singleLicense) return singleLicense.id
}

const getDistributorInfo = (userInfo) => {
  const { advisorCode, advisorId, party, contactMethods, office } = userInfo
  const mobile = mobileNumberFromContacts(contactMethods)
  const singleLicenseNumber = activeSingleLicense(party)
  const basicLicenseNumber = getBasicLicenseNo(party)
  const unitCode = !!advisorCode && advisorCode.includes('-') ? advisorCode.split('-')[0] : '-'

  let distributorProfile = {
    agentCode: advisorCode,
    lbCode: advisorId,
    positionCode: party.positionCode ? party.positionCode : '',
    mobile,
    singleLicenseNumber,
    basicLicenseNumber,
    unitCode,
  }

  if (office && office.ga) {
    distributorProfile = { ...distributorProfile, ga: office.ga }
  }
  return distributorProfile
}
