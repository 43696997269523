// @flow
import PropTypes from 'prop-types'
import React from 'react'

const ListItem = (props: Object) => {
  const { item, index, render } = props
  return <li>{render(item, index)}</li>
}

const OrderedList = (props: Object) => {
  const { nested, name, items, render, column } = props
  return (
    <ol data-col={column ? 'true' : 'false'} data-nested={nested ? 'true' : 'false'} className={`${name}`}>
      {items.map((item, index) => (
        <ListItem key={`${name}-${index}`} name={name} item={item} index={index} render={render} />
      ))}
    </ol>
  )
}

const UnOrderedList = (props: Object) => {
  const { start, nested, name, items, render, column } = props
  return (
    <ul
      start={start}
      data-col={column ? 'true' : 'false'}
      data-item-count={items.length}
      data-nested={nested ? 'true' : 'false'}
    >
      {items.map((item, index) => (
        <ListItem key={`${name}-${index}`} name={name} item={item} index={index} render={render} />
      ))}
    </ul>
  )
}

const RiderList = (props: Object) => {
  const { name, items, unordered } = props
  return items.length ? (
    <div className={`list-container list-container--${name}`}>
      {unordered ? UnOrderedList({ ...props }) : OrderedList({ ...props })}
    </div>
  ) : null
}

const propTypes = {
  /**
   * List start number
   */
  start: PropTypes.number,
  /**
   * Should the list render as nested item
   */
  nested: PropTypes.bool,
  /**
   * List name
   */
  name: PropTypes.string.isRequired,
  /**
   * Array of item in list
   */
  items: PropTypes.array.isRequired,
  /**
   * Should the list has [data-col]
   */
  column: PropTypes.bool,
  /**
   * Should the list represents an unordered list of items
   */
  unordered: PropTypes.bool,
  /**
   * Render function
   */
  render: PropTypes.func.isRequired,
}
const defaultProps = {
  start: 1,
  nested: true,
  name: '',
  items: [],
  column: false,
  unordered: false,
  render(item, index) {
    return item
  },
}

RiderList.propTypes = propTypes
RiderList.defaultProps = defaultProps

export default RiderList
