//@flow
import type { ConfirmToFNANotification } from '../notification/actions'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Modal } from '../../common-components'
import { enableFnaPhase } from 'quick-quote/fna/actions'
import IconHome from 'assets/images/nav/icon-home.svg'

type ConfirmToHomeProps = {
  notificationMessage: ConfirmToFNANotification,
  onExit: Function,
  enableFnaPhase: () => void,
}

const Warning = styled.p`
  color: red;
`
const Icon = styled.img`
  width: 28px;
  height: 28px;
`

export const _ConfirmToFNA = ({ notificationMessage, onExit, enableFnaPhase, ...props }: ConfirmToHomeProps) => (
  <Modal
    isOpen={notificationMessage !== 'None'}
    backdrop={true}
    size="lg"
    modalClassName="default-bootstrap remote-selling-modal"
    toggle={() => {
      onExit()
    }}
  >
    <ModalHeader
      toggle={() => {
        onExit()
      }}
    >
      ดำเนินการ FNA
    </ModalHeader>
    <ModalBody>
      <p>ท่านต้องการดำเนินการ FNA เพื่อดำเนินการคำขอสมัครประกันชีวิต</p>
      <Warning>
        *หมายเหตุ ระหว่างดำเนินการ FNA ท่านจะไม่สามารถแก้ไข ผลิตภัณท์ เบี้ยประกันชีวิตได้ <br />
        ดังนั้นกรุณาตรวจสอบก่อน ให้แน่ใจว่าผลิตภัณท์และข้อมูลที่ระบุ ถูกต้องครบถ้วนตรงตามความต้องการของท่านก่อนกดยืนยัน{' '}
        <br />
        หากท่านเข้าสู่ระบบ FNA และต้องการเปลี่ยนผลิตภัณท์ ท่านสามารถคลิก <Icon src={IconHome} /> เพื่อกลับสู่หน้าหลัก
      </Warning>
    </ModalBody>
    <ModalFooter>
      <div className="bottom">
        <Button
          color="primary"
          onClick={() => {
            enableFnaPhase()
            onExit()
          }}
        >
          ยืนยัน
        </Button>
      </div>
    </ModalFooter>
  </Modal>
)

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  enableFnaPhase: bindActionCreators(enableFnaPhase, dispatch),
})

export default connect(null, mapDispatchToProps)(_ConfirmToFNA)
