import { checker } from '../selectors'

import { createSectionCreator, createField } from '../utils'
import { reduce, endsWith } from 'lodash/fp'

const reasonInputField = (id) =>
  createField(`${id}.detail`, [
    {
      id: `${id}.detail`,
      c: 'TextInput',
      label: 'โปรดระบุรายละเอียด',
      required: true,
      infoMessage: 'การให้ถ้อยแถลงในส่วนนี้ อาจเพิ่มระยะเวลาในการพิจารณาใบคำขอ',
      invalidLabel: 'โปรดระบุรายละเอียดถ้อยแถลงเพิ่มเติม',
    },
  ])

const getQuestions = (app, id) => {
  const { isYes } = checker(app)

  const questions = [
    createField(`${id}.additionalDeclaration.label`, [
      {
        id: `${id}.additionalDeclaration.label`,
        c: 'PlainText',
        p: {
          children: 'สำหรับผู้ขอเอาประกันภัยและผู้ชำระเบี้ย (กรณีต้องการแจ้งรายละเอียดเพิ่มเติม)',
        },
      },
    ]),
    createField(`${id}.additionalDeclaration.checked`, [
      {
        id: `${id}.additionalDeclaration.checked`,
        c: 'Dropdown',
        p: {
          placeHolder: 'กรุณาเลือก',
          options: [
            {
              text: 'ไม่มีถ้อยแถลงเพิ่มเติม',
              value: 'no',
            },
            {
              text: 'มีถ้อยแถลงเกี่ยวกับสุขภาพเพิ่มเติม',
              value: 'yes',
            },
          ],
        },
        required: true,
      },
    ]),
  ]

  return reduce((questions, question) => {
    if (isYes(question.id) && !endsWith('label', question.id)) {
      return questions.concat(question).concat(reasonInputField(question.id))
    }
    return questions.concat(question)
  }, [])(questions)
}

export default createSectionCreator(getQuestions)
