import { omit, get, isString } from 'lodash/fp'
import { toPathString } from 'e-submission/utils'
import { APP_STATUS, UPLOAD_DOC_STATUS } from 'e-submission/domain/data-model/constants'

import generateUUID from 'uuid/v1'

export const setReplicationStatus = (status) => ({
  type: 'SET_REPLICATION_STATUS',
  payload: status,
})

export const setDefaultDCA = () => ({
  type: 'SET_DEFAULT_DCA',
})

export const setAllocationBeneficiaryMRTA = () => ({
  type: 'SET_ALLOCATION_BENEFICIARY_MRTA',
})

export const updateDCA = () => ({
  type: 'UPDATE_DCA',
})

export const signedDCA = () => ({
  type: 'SIGNED_DCA',
})

export const cleanDCA = () => ({
  type: 'CLEAN_DCA',
})

export const submitRecurringPayment = () => ({
  type: 'SUBMIT_RECURRING_PAYMENT',
})

export const startRemoteBankClearing = () => ({
  type: 'START_REMOTE_BANK_CLEARING',
})

export const updateRecurringPayment = (isEdit = false) => ({
  type: 'UPDATE_RECURRING_PAYMENT',
  isEdit,
})

export const setSubmitWithPayment = () => {
  return unsetAppValue('submitWithoutPayment.reason')
}

export const updateReceipt = (id, name) => ({
  type: 'UPDATE_RECEIPT',
  id,
  name,
})

export const updateUnsignReceipt = (id, name) => ({
  type: 'UPDATE_UNSIGN_RECEIPT',
  id,
  name,
})

export const createReceipt = (payload) => ({
  type: 'CREATE_RECEIPT',
  payload,
})

export const uploadRemoteSellingReceipt = (payload) => ({
  type: 'UPLOAD_REMOTE_SELLING_RECEIPT',
  payload,
})

export const sendQrPaymentEvent = (payload) => ({
  type: 'SEND_QR_PAYMENT_EVENT',
  payload,
})

export const esubUploadDocumentFromUrl = (payload) => ({
  type: 'ESUB_UPLOAD_DOCUMENT_FROM_URL',
  payload,
})

export const removePaymentReceipt = (payload) => ({
  type: 'REMOVE_PAYMENT_RECEIPT',
  payload,
})

export const submitCreditCard = (payload) => ({
  type: 'SUBMIT_CREDIT_CARD',
  payload,
})

export const fetchSignature = () => ({
  type: 'FETCH_SIGNATURE',
})

export const previewPDF = (payload) => ({
  type: 'PREVIEW_PDF',
  payload,
})

export const previewMultiplePDF = (payload) => ({
  type: 'PREVIEW_MULTIPLE_PDF',
  payload,
})

export const signDocument = (payload) => ({
  type: 'SIGN_DOCUMENT',
  payload,
})

export const capturePhoto = (name, isShowPreview = false) => ({
  type: 'CAPTURE_PHOTO',
  name,
  isShowPreview,
})

export const browsePhoto = (name, images, isShowPreview = false, remoteSellingActor, format) => ({
  type: 'BROWSE_PHOTO',
  payload: images,
  name,
  isShowPreview,
  remoteSellingActor,
  format,
})

export const uploadMultipleDocs = (name, isShowPreview = false, actor, limit) => ({
  type: 'UPLOAD_MULTIPLE_DOCUMENTS',
  name,
  isShowPreview,
  actor,
  limit,
})

export const clonePhoto = (from, destination) => ({
  type: 'CLONE_PHOTO',
  from,
  destination,
})

export const previewPhoto = (name) => ({
  type: 'PREVIEW_PHOTO',
  name,
})

export const deletePhoto = (name) => ({
  type: 'DELETE_PHOTO',
  name,
})

export const addFile = (name, file, fileProps = {}) => ({
  type: 'ADD_FILE',
  payload: { file, ...fileProps },
  name,
})
export const addFileProps = (name, fileProps = {}) => ({
  type: 'ADD_FILE',
  payload: fileProps,
  name,
})

export const removeFile = (name) => ({
  type: 'REMOVE_FILE',
  name,
})

export const addError = (name, error) => ({
  type: 'ADD_ERROR',
  payload: error,
  name,
})

export const removeError = (name) => ({
  type: 'REMOVE_ERROR',
  name,
})

export const saveEcbrPhoto = (from, destination) => ({
  type: 'SAVE_ECBR_PHOTO',
  from,
  destination,
})

export const createNewApp = (user) => {
  const userId = get('profile.sub', user)
  const uuid = generateUUID()
  const appId = `application_${userId}_${uuid}`
  return {
    type: 'CREATE_NEW_APP',
    appId,
  }
}

export const loadQuickQuote = (payload) => ({
  type: 'LOAD_QUICK_QUOTE',
  payload,
})

export const loadApplication = (app) => {
  const _id = get('_id', app) || get('id', app)
  return {
    type: 'LOAD_APP',
    appId: _id,
    payload: omit(['id', 'rev', '_rev'], app),
  }
}

export const submitApplication = () => ({
  type: 'SUBMIT_APPLICATION',
})

export const sendApplication = (payload) => ({
  type: 'SEND_APPLICATION',
  payload,
})

export const mergeApplication = (appId, payload) => {
  return {
    type: 'MERGE_APP',
    appId,
    payload,
  }
}

export const changeCurrentApp = (appId) => {
  return {
    type: 'CHANGE_CURRENT_APP',
    appId,
  }
}

export const fetchApplication = (isWaitCompletedSync = false) => ({
  type: 'FETCH_ALL_APP',
  payload: isWaitCompletedSync,
})

export const setAppValue = (fieldId, value, isExcludeNull) => {
  let newValue = value

  if (isString(value)) {
    const trimmedStringValue = value && value.trim()
    newValue = trimmedStringValue
  }
  return {
    type: 'SET_APP_VALUE',
    fieldId: isString(fieldId) ? fieldId : toPathString(fieldId),
    value: newValue,
    isExcludeNull,
  }
}
// The diffrent between SET_APP_VALUE VS SAVE_APP_DATA
// SET_APP_VALUE is per field. while SAVE_APP_DATA is type to save current state
export const saveAppData = () => ({
  type: 'SAVE_APP_DATA',
})
export const setAppStatusDrafted = () => {
  return setAppValue('status', APP_STATUS.DRAFTED)
}
export const setAppStatusSigned = () => {
  return setAppValue('status', APP_STATUS.SIGNED)
}
export const setAppStatusSubmitted = () => {
  return setAppValue('status', APP_STATUS.SUBMITTED)
}
export const setAppStatusSubmitting = () => {
  return setAppValue('status', APP_STATUS.SUBMITTING)
}
export const setAppStatusFailed = () => {
  return setAppValue('status', APP_STATUS.FAILED)
}

export const setInsuredCommandStatusUploading = (name) => {
  return setAppValue('insuredUploadDocStatus', { status: UPLOAD_DOC_STATUS.UPLOADING, name })
}
export const setPayerCommandStatusUploading = (name) => {
  return setAppValue('payerUploadDocStatus', { status: UPLOAD_DOC_STATUS.UPLOADING, name })
}
export const setInsuredCommandStatusCompleted = (name) => {
  return setAppValue('insuredUploadDocStatus', { status: UPLOAD_DOC_STATUS.COMPLETED, name })
}
export const setPayerCommandStatusCompleted = (name) => {
  return setAppValue('payerUploadDocStatus', { status: UPLOAD_DOC_STATUS.COMPLETED, name })
}
export const setUploadingStatus = (insuredOrPayer, value) => {
  return setAppValue(`${insuredOrPayer}UploadDocStatus`, {
    status: value ? UPLOAD_DOC_STATUS.COMPLETED : UPLOAD_DOC_STATUS.UPLOADING,
  })
}

export const setPreviewMultipleDoc = (name) => {
  return setAppValue('previewMultipleDoc', name)
}

export const setDefaultAppValue = (fieldId, value) => {
  return {
    type: 'SET_DEFAULT_APP_VALUE',
    fieldId: isString(fieldId) ? fieldId : toPathString(fieldId),
    value,
  }
}

export const unsetAppValue = (fieldId) => {
  return {
    type: 'UNSET_APP_VALUE',
    fieldId: isString(fieldId) ? fieldId : toPathString(fieldId),
  }
}

export const addAppMember = (fieldId) => {
  return {
    type: 'ADD_APP_MEMBER',
    fieldId,
  }
}

export const removeAppMember = (fieldId) => {
  return {
    type: 'REMOVE_APP_MEMBER',
    fieldId,
  }
}

export const setData = (obj) => {
  return {
    type: 'SET_DATA',
    payload: obj,
  }
}

export const showDialog = (dialog) => ({
  type: 'SHOW_DIALOG',
  payload: isString(dialog) ? { name: dialog } : dialog,
})
export const loadDocument = (document) => {
  return {
    type: 'LOAD_DOCUMENT',
    payload: document,
  }
}

export const sendMultipleDocument = (documents, documentData) => {
  return {
    type: 'SEND_MULTIPLE_DOCUMENT',
    payload: {
      documents,
      documentData,
    },
  }
}

export const setLoadingMessage = (payload) => ({
  type: 'SET_LOADING_MESSAGE',
  payload,
})

export const clearLoadingMessage = () => ({
  type: 'CLEAR_LOADING_MESSAGE',
})

export const showPreviewPDFDialog = (document) => {
  return showDialog({
    name: 'PreviewPDF',
    document,
  })
}
export const showPreviewMultiplePDFDialog = (document) => {
  return showDialog({
    name: 'PreviewMultiplePDF',
    document,
  })
}
export const showSignatureDialog = (document) => {
  return showDialog({
    name: 'Signature',
    document,
  })
}
export const showErrorDialog = (type, caption) => {
  return showDialog({
    name: 'Error',
    type,
    caption,
  })
}
export const showSubmitPaymentDialog = (props) => {
  return showDialog({
    name: 'SubmitPayment',
    ...props,
  })
}
export const showSyncingDialog = (props = {}) => {
  return showDialog({
    name: 'Syncing',
    ...props,
  })
}

export const hideDialog = () => ({
  type: 'HIDE_DIALOG',
})

export const showLoading = () => ({
  type: 'SHOW_LOADING',
})

export const hideLoading = () => ({
  type: 'HIDE_LOADING',
})

export const validateApplication = () => ({
  type: 'VALIDATE_APPLICATION',
})

export const resetValidation = () => ({
  type: 'RESET_VALIDATION',
})

export const showSignPad = (payload) => ({
  type: 'SHOW_SIGNPAD',
  payload,
})

export const sendPreviewDocument = (payload) => ({
  type: 'SEND_PREVIEW_DOCUMENT',
  payload,
})
export const showSubmittedFlashMessage = () => ({
  type: 'SHOW_SUBMITTED_FLASH_MESSAGE',
})

export const hideSubmittedFlashMessage = () => ({
  type: 'HIDE_SUBMITTED_FLASH_MESSAGE',
})

export const confirmYes = () => ({ type: 'CONFIRM_YES' })
export const confirmNo = () => ({ type: 'CONFIRM_NO' })
export const setOnOtp = () => setAppValue('isOnOtp', true)
export const setOffOtp = () => setAppValue('isOnOtp', false)
export const setSendLinkTextInput = (field, payload) => setAppValue(field, payload)
export const setSendLinkMethod = (field, payload) => setAppValue(field, payload)
export const setResendEkycLink = (insuredOrPayer) => setAppValue(`ekycInfo.${insuredOrPayer}.resendLink`, true)
export const startSubmitPage = () => ({ type: 'START_SUBMIT_PAGE' })
export const signedAgentDocuments = () => ({ type: 'SIGNED_AGENT_DOCUMENTS' })

export const setFlagCheckStatus = () => ({ type: 'SET_EDA_CHECK_STATUS' })

export const verifyPolicy = (history) => ({
  type: 'VERIFY_POLICY',
  payload: history,
})

export const comfirmSubmittedPolicy = (history) => ({
  type: 'CONFIRM_SUBMITTED_POLICY',
  payload: history,
})
export const submittedPolicy = (history) => ({
  type: 'SUBMITTED_POLICY',
  payload: history,
})

export const startRemoteSubmitPage = (history) => ({
  type: 'START_REMOTE_SUBMIT_PAGE',
  payload: history,
})

export const errorOtpAttachment = (payload) => ({
  type: 'ERROR_OTP_ATTACHMENTS',
  payload,
})

export const subscribeReplicationStatus = () => ({
  type: 'SUBSCRIBE_REPLICATION_STATUS',
})

export const unsubscribeReplicationStatus = () => ({
  type: 'UNSUBSCRIBE_REPLICATION_STATUS',
})

export const createCaseEkyc = (insuredOrPayer, citizenId, onDupModal) => ({
  type: 'CREATE_CASE_EKYC',
  payload: { insuredOrPayer, citizenId, onDupModal },
})

export const createCaseEkycFromDevice = (insuredOrPayer, citizenId, openRespondModalOnFailedCase, onDupModal) => {
  return {
    type: 'CREATE_CASE_EKYC_DEVICE',
    payload: { insuredOrPayer, citizenId, openRespondModalOnFailedCase, onDupModal },
  }
}

export const reuseCaseEkyc = (insuredOrPayer, proprietorId) => ({
  type: 'REUSE_CASE_EKYC',
  payload: { insuredOrPayer, proprietorId },
})

export const verifyEkyc = (insuredOrPayer) => ({
  type: 'VERIFY_EKYC',
  payload: { insuredOrPayer },
})

export const getAttachment = (imageName, insuredOrPayer) => ({
  type: 'GET_ATTACHMENT',
  payload: { imageName, insuredOrPayer },
})

export const setFrontIdCardImage = (image, insuredOrPayer) => ({
  type: 'SET_FRONT_ID_CARD_IMAGE',
  payload: { image, insuredOrPayer },
})

export const setBackIdCardLaserCode = (laserCode, insuredOrPayer) => ({
  type: 'SET_BACK_ID_CARD_LASER_CODE',
  payload: { laserCode, insuredOrPayer },
})

export const setDopaRequestFailedModal = (isOpenDopaRequestFailedModal, insuredOrPayer) => ({
  type: 'SET_DOPA_REQUEST_FAILED_MODAL',
  payload: { isOpenDopaRequestFailedModal, insuredOrPayer },
})

export const sendLinkQuickpay = () => ({
  type: 'SEND_LINK_QUICK_PAY',
})

export const verifyPayment = () => ({
  type: 'VERIFY_PAYMENT',
})

export const verifyCCPA = () => ({
  type: 'VERIFY_CCPA',
})
export const checkPaymentStatus = (toggleQuickpayModal) => ({
  type: 'CHECK_PAYMENT_STATUS',
  payload: { toggleQuickpayModal },
})

export const changePaymentMethod = () => ({
  type: 'CHANGE_PAYMENT_METHOD',
})

export const changeCCPAMethod = () => ({
  type: 'CHANGE_CCPA_METHOD',
})

export const remoteSellingError = (payload) => ({
  type: 'REMOTE_SELLING_ERROR',
  payload,
})

export const remoteSellingSuccess = () => ({
  type: 'REMOTE_SELLING_SUCCESS',
})

export const saveDataAuraToMiddelware = (payload) => ({
  type: 'SAVE_DATA_AURA_TO_MIDDLEWARE',
  payload,
})

export const sendLinkCCPA = () => ({
  type: 'SEND_LINK_CCPA',
})

export const resendLinkCCPA = () => ({
  type: 'RESEND_LINK_CCPA',
})

export const updateConsentEdaTerms = () => ({
  type: 'UPDATE_CONSENT_EDA_TERMS',
})

export const readConsentEdaComplete = () => ({
  type: 'READ_CONSENT_EDA_COMPLETE',
})

export const resetConsentEdaComplete = () => ({
  type: 'RESET_CONSENT_EDA_COMPLETE',
})

export const startEDACheckingStatus = () => ({
  type: 'START_EDA_CHECK_STATUS',
})

export const stopEDACheckingStatus = () => ({
  type: 'STOP_EDA_CHECK_STATUS',
})

export const unsetEDACheckStatus = () => ({
  type: 'UNSET_EDA_CHECK_STATUS',
})

export const setEDACheckStatusError = () => ({
  type: 'EDA_CHECK_STATUS_ERROR',
})

export const setEDACheckStatusExpired = () => ({
  type: 'EDA_CHECK_STATUS_EXPIRED',
})

export const setEDACheckStatusSuccess = () => ({
  type: 'EDA_CHECK_STATUS_SUCCESS',
})

export const setEDACheckStatusRejected = () => ({
  type: 'EDA_CHECK_STATUS_REJECTED',
})

export const setEDAViewFormOnly = () => ({
  type: 'SET_EDA_VIEW_FORM_ONLY',
})

export const resetEDAViewFormOnly = () => ({
  type: 'RESET_EDA_VIEW_FORM_ONLY',
})

export const setShowEcbrSystemizationFailedModal = () => ({
  type: 'SHOW_ECBR_SYSTEMIZATION_FAILED_MODAL',
  payload: { isOpenModal: true },
})

export const setHideEcbrSystemizationFailedModal = () => ({
  type: 'HIDE_ECBR_SYSTEMIZATION_FAILED_MODAL',
  payload: { isOpenModal: false },
})

export const createEcbrNo = (id, paymentType) => ({
  type: 'CREATE_ECBR_NO',
  id,
  paymentType,
})

export const downloadEcbrFile = (payload) => ({
  type: 'SAVE_DOWNLOAD_ECBR_FILE',
  payload,
})

export const createNewEda = (payload) => ({
  type: 'CREATE_NEW_EDA',
  payload,
})

export const getEdaStatus = () => ({
  type: 'GET_EDA_STATUS',
})

export const removeEDACheckStatus = () => ({
  type: 'REMOVE_EDA_CHECK_STATUS',
})
