//@flow
import { set, unset, compose } from 'lodash/fp'
import type { Action } from './actions'
import {
  CLEAR_MARKET_CONDUCT_FORM,
  CLEAR_MARKET_CONDUCT_REASON_OTHERS,
  CLEAR_THIRD_PERSON,
  SET_CURRENT_MARKET_CONDUCT_FORM,
  SET_MARKET_CONDUCT_REASON_VALUE,
} from './actions'
import VALUES from '../../../core/data-model/constants/values'
import { RESET_QUOTE_FORM } from '../../actions'

export const initialMarketConduct = {
  questionsType: null,
  questions: {},
  reason: '',
  reasonOthers: '',
}
export type MarketConductQuestionsType = $Values<typeof VALUES.MARKET_CONDUCT_QUESTIONS_TYPES>
export type MarketConductState = {
  questionsType: ?'ILP' | 'NON-ILP',
  questions: { [key: string]: string | number },
  reason: string,
  reasonOthers: string,
}
const reducer = (state: MarketConductState = initialMarketConduct, action: Action) => {
  switch (action.type) {
    case SET_MARKET_CONDUCT_REASON_VALUE:
      return set('reason', action.payload.value)(state)
    case CLEAR_MARKET_CONDUCT_REASON_OTHERS:
      return set('reasonOthers', '')(state)
    case CLEAR_THIRD_PERSON:
      return unset('thirdPerson')(state)
    case SET_CURRENT_MARKET_CONDUCT_FORM:
      return set('questionsType', action.payload.value)(state)
    case CLEAR_MARKET_CONDUCT_FORM:
      return compose(set('questionsType', state.questionsType), set('reason', state.reason))(initialMarketConduct)
    case RESET_QUOTE_FORM:
      return initialMarketConduct
    default: {
      return state
    }
  }
}

export default reducer
