// @flow
import type { DisplayProduct } from 'core/service/display-product'
// ==== action types ====
export type Action = SelectProduct | ValidateInvestmentProduct

export const SELECT_PRODUCT = 'PRODUCT_SELECTION/SELECT_PRODUCT'
export const SELECTED_PRODUCT = 'PRODUCT_SELECTION/SELECTED_PRODUCT'
export const SELECT_PRODUCT_CATEGORY = 'PRODUCT_SELECTION/SELECT_PRODUCT_CATEGORY'
export const AVAILABLE_PRODUCT_CATEGORIES = 'PRODUCT_SELECTION/AVAILABLE_PRODUCT_CATEGORIES'
export const AVAILABLE_PLAN_CODE = 'PRODUCT_SELECTION/AVAILABLE_PLAN_CODE'

export type SelectProduct = {
  type: 'PRODUCT_SELECTION/SELECT_PRODUCT',
  payload: DisplayProduct,
}

export type SelectedProduct = {
  type: 'PRODUCT_SELECTION/SELECTED_PRODUCT',
  payload: DisplayProduct,
}

export const VALIDATE_INVESTMENT_PRODUCT = 'PRODUCT_SELECTION/VALIDATE_INVESTMENT_PRODUCT'
export type ValidateInvestmentProduct = {
  type: 'PRODUCT_SELECTION/VALIDATE_INVESTMENT_PRODUCT',
  payload: DisplayProduct,
}

// ==== action creators ====

export const selectProduct = (product: DisplayProduct): SelectProduct => ({
  type: SELECT_PRODUCT,
  payload: product,
})

export const selectedProduct = (product: DisplayProduct): SelectedProduct => ({
  type: SELECTED_PRODUCT,
  payload: product,
})

export const selectProductCategory = (categoryId: string) => ({
  type: SELECT_PRODUCT_CATEGORY,
  payload: categoryId,
})
export const availableProductCategories = (categoryIds: string[]) => ({
  type: AVAILABLE_PRODUCT_CATEGORIES,
  payload: categoryIds,
})
export const availablePlanCode = (planCodes: string[]) => ({
  type: AVAILABLE_PLAN_CODE,
  payload: planCodes,
})

export const validateInvestmentProduct = (product: DisplayProduct): ValidateInvestmentProduct => ({
  type: VALIDATE_INVESTMENT_PRODUCT,
  payload: product,
})

export const VALIDATE_ORDINARY_PRODUCT = 'PRODUCT_SELECTION/VALIDATE_ORDINARY_PRODUCT'
export type ValidateOrdinaryProduct = {
  type: 'PRODUCT_SELECTION/VALIDATE_ORDINARY_PRODUCT',
  payload: DisplayProduct,
}
export const validateOrdinaryProduct = (product: DisplayProduct): ValidateInvestmentProduct => ({
  type: VALIDATE_ORDINARY_PRODUCT,
  payload: product,
})

export const VALIDATE_NORMAL_PRODUCT = 'PRODUCT_SELECTION/VALIDATE_NORMAL_PRODUCT'
export type ValidateNormalProduct = {
  type: 'PRODUCT_SELECTION/VALIDATE_NORMAL_PRODUCT',
  payload: DisplayProduct,
}
export const validateNormalProduct = (product: DisplayProduct): ValidateNormalProduct => ({
  type: VALIDATE_NORMAL_PRODUCT,
  payload: product,
})
