// @flow
import type { AppState } from 'quick-quote/reducers'
import type { BenefitIllustrationProps } from './components/benefit-illustration'
import BenefitIllustration from './components/benefit-illustration'

import { connect } from 'react-redux'
import { isBlockedPdfGeneration, isBlockedEnteringESub, isReviseQQFlow } from 'quick-quote/selectors'
import { getSelectedDisplayProduct } from 'quick-quote/product-selection/selectors'
import {
  getPolicyValues,
  getHasNonLevelRider,
  getLegalDisclaimerEndDate,
  getBenefitSummary,
} from 'quick-quote/product-whole-life/benefit-illustration/selectors'
import { getGenderLabel, getAge } from 'quick-quote/insured-information/selectors'
import {
  getTotalRiderPremium,
  getcoveragePlanStartDate,
  getSelectedModelFactorLabel,
  getSelectedModelFactorPeriod,
  getSumAssured$,
  getTotalPremium,
  getBasicPremium$,
  getSelectedRiders,
} from 'quick-quote/product-common/coverage-plan/selectors'
import { canProceedToBenefitIllustration } from 'quick-quote/product-whole-life/coverage-plan/selectors'
import {
  getCalculatedCoveragePeriod,
  getCalculatedPaymentPeriod,
} from 'quick-quote/product-common/benefit-illustration/selectors'

const mapStateToProps = (state: AppState) =>
  ({
    displayProduct: getSelectedDisplayProduct(state),
    riders: getSelectedRiders(state),
    genderLabel: getGenderLabel(state),
    age: getAge(state),
    sumAssured$: getSumAssured$(state),
    selectedModelFactorLabel: getSelectedModelFactorLabel(state),
    selectedModelFactorPeriod: getSelectedModelFactorPeriod(state),
    calculatedCoveragePeriod: getCalculatedCoveragePeriod(state),
    calculatedPaymentPeriod: getCalculatedPaymentPeriod(state),
    hasNonLevelRider: getHasNonLevelRider(state),
    totalPremium: getTotalPremium(state),
    totalRiderPremium: getTotalRiderPremium(state),
    basicPremium$: getBasicPremium$(state),
    coveragePlanStartDate: getcoveragePlanStartDate(state),
    legalDisclaimerEndDate: getLegalDisclaimerEndDate(state),
    policyValue: getPolicyValues(state),
    benefitSummary: getBenefitSummary(state),
    canProceedToBenefitIllustration: canProceedToBenefitIllustration(state),
    isBlockedPdfGeneration: isBlockedPdfGeneration(state),
    isBlockedEnteringESub: isBlockedEnteringESub(state),
    isReviseQQ: isReviseQQFlow(state),
  }: BenefitIllustrationProps)

const mapDispatchToProps = () => ({})

export const BenefitIllustrationContainer = connect(mapStateToProps, mapDispatchToProps)(BenefitIllustration)
