// @flow
import { Component } from 'react'
// $$FlowFixMe
import styled from 'styled-components'
import _ from 'lodash'
import syncScroll from 'lib/sync-scroll'
import PolicyValueRow, { dashedOutOrWithPrecision } from './policy-value-row'
import MESSAGES from 'core/data-model/constants/bi-messages'
import CONSTANTS from 'core/data-model/constants/messages'
import type { PolicyValueTableProps } from 'core/service/benefit-illustration/policy-value'
import { wrapWordingToTextJustify } from 'core/service/benefit-illustration/utils/wording-wrapper'
import {
  Entry,
  Headers,
  Heading,
  HeadingGroup,
  HeadingGroupMaster,
  HeadingGroupSub,
  StickyTable,
  Track,
  Tracks,
  HeadingFixedGroup,
  EntryFixed,
} from 'quick-quote/product-investment/coverage-plan/components/StickyTable'

const HeadingStyled = styled(Heading)`
  height: 85px !important;
`

const HeadingSubStyled = styled(Heading)`
  height: 20px !important;
`

const PolicyValueTable = (props: PolicyValueTableProps) => {
  return (
    <div className="policy group twelve-pl" id="policy-value">
      <h3>{MESSAGES.POLICY_VALUE_TABLE}</h3>
      {renderPolicyValueTable(props)}
    </div>
  )
}

const PolicyTable = ({ className, children }: { className?: string, children?: React.Node }) => {
  return (
    <StickyTable className={`policy-table${className ? `${className}` : ''}`} id="policy-value-table">
      {children}
    </StickyTable>
  )
}

// $$FlowFixMe
const _12PLHeader = ({ targetRef }) => (
  <Headers className="twelve" targetRef={targetRef}>
    <HeadingFixedGroup>
      <Heading>{CONSTANTS.AGE}</Heading>
      <Heading>{MESSAGES.END_OF_THE_YEAR}</Heading>
    </HeadingFixedGroup>
    <Heading>{MESSAGES.CUMULATIVE_PREMIUM}</Heading>

    <HeadingGroup>
      <HeadingGroupMaster>
        <HeadingStyled>{MESSAGES.PREMIUM_PER_YEAR}</HeadingStyled>
      </HeadingGroupMaster>
      <HeadingGroupSub>
        <Heading>{MESSAGES.BASIC_PLAN}</Heading>
        <Heading>{MESSAGES.RIDER}</Heading>
        <Heading>{MESSAGES.TOTAL}</Heading>
      </HeadingGroupSub>
    </HeadingGroup>

    <HeadingGroup>
      <HeadingGroupMaster>
        <HeadingStyled>
          <span
            dangerouslySetInnerHTML={{
              __html: wrapWordingToTextJustify(MESSAGES.TWELVE_PL_GUARANTEE_CASHDROP),
            }}
          ></span>
        </HeadingStyled>
      </HeadingGroupMaster>
      <HeadingGroupSub>
        <Heading>{MESSAGES.INCASE_OF_CASH_BACK}</Heading>
        <Heading>{MESSAGES.TWELVE_PL_INCASE_OF_CUMULATIVE}</Heading>
      </HeadingGroupSub>
    </HeadingGroup>

    <HeadingGroup>
      <HeadingGroupMaster>
        <HeadingStyled>{MESSAGES.TWELVE_PL_DIVIDEND}</HeadingStyled>
      </HeadingGroupMaster>
      <HeadingGroupSub>
        <HeadingGroup>
          <HeadingGroupSub>
            <HeadingSubStyled>{MESSAGES.EXAMPLE_1}</HeadingSubStyled>
            <HeadingSubStyled>{MESSAGES.EXAMPLE_2}</HeadingSubStyled>
            <HeadingSubStyled>{MESSAGES.EXAMPLE_3}</HeadingSubStyled>
          </HeadingGroupSub>
          <HeadingGroupSub>
            <Heading>{MESSAGES.INCASE_OF_CASH_BACK}</Heading>
            <Heading>{MESSAGES.TWELVE_PL_INCASE_OF_CUMULATIVE}</Heading>
            <Heading>{MESSAGES.INCASE_OF_CASH_BACK}</Heading>
            <Heading>{MESSAGES.TWELVE_PL_INCASE_OF_CUMULATIVE}</Heading>
            <Heading>{MESSAGES.INCASE_OF_CASH_BACK}</Heading>
            <Heading>{MESSAGES.TWELVE_PL_INCASE_OF_CUMULATIVE}</Heading>
          </HeadingGroupSub>
        </HeadingGroup>
      </HeadingGroupSub>
    </HeadingGroup>

    <HeadingGroup>
      <HeadingGroupMaster>
        <HeadingStyled>
          <span
            dangerouslySetInnerHTML={{
              __html: wrapWordingToTextJustify(MESSAGES.TWELVE_PL_GUARANTEE_CASHDROP_WITH_DIVIDEND),
            }}
          ></span>
        </HeadingStyled>
      </HeadingGroupMaster>
      <HeadingGroupSub>
        <Heading>{MESSAGES.EXAMPLE_1}</Heading>
        <Heading>{MESSAGES.EXAMPLE_2}</Heading>
        <Heading>{MESSAGES.EXAMPLE_3}</Heading>
      </HeadingGroupSub>
    </HeadingGroup>
    <HeadingGroup>
      <HeadingGroupSub>
        <Heading>{MESSAGES.DEATH_BENEFIT}</Heading>
        <Heading>{MESSAGES.TWELVE_PL_DEATH_BENEFIT_COVERAGE}</Heading>
        <Heading>
          <span
            dangerouslySetInnerHTML={{
              __html: wrapWordingToTextJustify(MESSAGES.TWELVE_PL_SURRENDER_CASH),
            }}
          ></span>
        </Heading>
      </HeadingGroupSub>
    </HeadingGroup>
  </Headers>
)

// $$FlowFixMe
const _12PLBody = ({
  yearEndPolicyValues,
  lastYearEndPolicy,
  subtotalAnnualBasicPremium,
  subtotalAnnualRiderPremium,
  grandTotalPremium,
  subtotalGuaranteeDividend,
  subTotalNonGuaranteeDividendDeposit,
  subTotalLowInterest,
  subTotalLowInterestDeposit,
  subTotalMidInterest,
  subTotalMidInterestDeposit,
  subTotalHighInterest,
  subTotalHighInterestDeposit,
  subTotalLowDividend,
  subTotalMidDividend,
  subTotalHighDividend,
  targetRef,
}) => (
  <Tracks targetRef={targetRef}>
    {yearEndPolicyValues.map((policyValue, key) => (
      <PolicyValueRow
        key={`policy-twelve-pl-value-row-${key}`}
        age={policyValue.age}
        yearEndPolicy={policyValue.yearEndPolicy}
        cumulativeBasicPremium={policyValue.cumulativeBasicPremium}
        annualBasicPremium={policyValue.annualBasicPremium}
        annualRiderPremium={policyValue.annualRiderPremium}
        annualTotalPremium={policyValue.annualTotalPremium}
        surrenderCash={policyValue.surrenderCash}
        deathBenefit={policyValue.deathBenefit}
        cashDrop={policyValue.cashDrop}
        sumCashDrop={policyValue.sumCashDrop}
        lowInterest={policyValue.lowInterest}
        lowInterestDeposit={policyValue.lowInterestDeposit}
        midInterest={policyValue.midInterest}
        midInterestDeposit={policyValue.midInterestDeposit}
        highInterest={policyValue.highInterest}
        highInterestDeposit={policyValue.highInterestDeposit}
        totalLowDividend={policyValue.totalLowDividend}
        totalMidDividend={policyValue.totalMidDividend}
        totalHighDividend={policyValue.totalHighDividend}
        totalBenefit={policyValue.totalBenefit}
      />
    ))}
    <Track className="total" id={`policy-value-row-${lastYearEndPolicy}`} key={`policy-value-row-${lastYearEndPolicy}`}>
      <EntryFixed>
        <Entry />
        <Entry />
      </EntryFixed>
      <Entry />
      <Entry>{dashedOutOrWithPrecision(subtotalAnnualBasicPremium || 0, 2)}</Entry>
      <Entry>{dashedOutOrWithPrecision(subtotalAnnualRiderPremium || 0, 2)}</Entry>
      <Entry>{dashedOutOrWithPrecision(grandTotalPremium || 0, 2)}</Entry>
      <Entry>{dashedOutOrWithPrecision(subtotalGuaranteeDividend || 0, 0)}</Entry>
      <Entry>{dashedOutOrWithPrecision(subTotalNonGuaranteeDividendDeposit || 0, 0)}</Entry>
      <Entry>{dashedOutOrWithPrecision(subTotalLowInterest || 0, 0)}</Entry>
      <Entry>{dashedOutOrWithPrecision(subTotalLowInterestDeposit || 0, 0)}</Entry>
      <Entry>{dashedOutOrWithPrecision(subTotalMidInterest || 0, 0)}</Entry>
      <Entry>{dashedOutOrWithPrecision(subTotalMidInterestDeposit || 0, 0)}</Entry>
      <Entry>{dashedOutOrWithPrecision(subTotalHighInterest || 0, 0)}</Entry>
      <Entry>{dashedOutOrWithPrecision(subTotalHighInterestDeposit || 0, 0)}</Entry>
      <Entry>{dashedOutOrWithPrecision(subTotalLowDividend || 0, 0)}</Entry>
      <Entry>{dashedOutOrWithPrecision(subTotalMidDividend || 0, 0)}</Entry>
      <Entry>{dashedOutOrWithPrecision(subTotalHighDividend || 0, 0)}</Entry>
      <Entry />
      <Entry />
      <Entry />
    </Track>
  </Tracks>
)

// $$FlowFixMe
export class PolicyTwelvePL extends Component {
  constructor(props) {
    super(props)
    // $$FlowFixMe
    this.setHeaderRef = this.setHeaderRef.bind(this)
    // $$FlowFixMe
    this.setBodyRef = this.setBodyRef.bind(this)
    // $$FlowFixMe
    this.renderHeader = this.renderHeader.bind(this)
    // $$FlowFixMe
    this.renderBody = this.renderBody.bind(this)
  }

  componentDidMount() {
    // $$FlowFixMe
    syncScroll([this.header, this.body])
  }

  setHeaderRef(element) {
    // $$FlowFixMe
    this.header = element
  }

  setBodyRef(element) {
    // $$FlowFixMe
    this.body = element
  }

  renderHeader() {
    return _12PLHeader({ targetRef: this.setHeaderRef })
  }

  renderBody() {
    return _12PLBody({ ...this.props, targetRef: this.setBodyRef })
  }

  render() {
    return (
      <PolicyTable className="twelve-pl twelve">
        {this.renderHeader()}
        {this.renderBody()}
      </PolicyTable>
    )
  }
}

const PolicyValueContent = (props) => <PolicyTwelvePL {...props} />

const renderPolicyValueTable = ({ policyValue, basicPlan }: PolicyValueTableProps) => {
  const lastYearEndPolicy = _.get(_.last(policyValue.yearEndPolicyValues), 'yearEndPolicy', 0) + 1

  return (
    <div>
      <div className="sticky-table-wrap">
        <PolicyValueContent lastYearEndPolicy={lastYearEndPolicy} basicPlan={basicPlan} {...policyValue} />
      </div>

      <div className="disclaimer">
        <p>{MESSAGES.NOTE}:</p>
        <ul>
          <li>{MESSAGES.TWELVE_PL_POLICY_VALUE_NOTE_1}</li>
          <li>{MESSAGES.TWELVE_PL_BENEFIT_DISCLAIMER}</li>
          <li>{MESSAGES.TWELVE_PL_POLICY_VALUE_NOTE_3}</li>
        </ul>
      </div>
    </div>
  )
}

export default PolicyValueTable
