import React, { useState, useEffect } from 'react'
import { Button, ModalBody, ModalHeader, Row } from 'reactstrap'
import { Modal } from '../../../common-components'
import { onOpenBrochure } from './actions'
import _ from 'lodash'

const ModalConfirm = (props) => {
  const [hedaerModal, setHeaderModal] = useState('')
  const [messageModal, setMessageModal] = useState('')

  useEffect(() => {
    try {
      if (props.isOpen) {
        if (Object.keys(props.application.brochureLink).length > 0) {
          setHeaderModal('กรุณาเลือกโบร์ชัวร์นำเสนอผลิตภัณฑ์')
          setMessageModal('ท่านสามารถเลือกภาษาสำหรับโบร์ชัวร์เพื่อนำเสนอผลิตภัณฑ์ให้แก่ลูกค้าได้')
        } else {
          setHeaderModal('ไม่พบโบรชัวร์ที่ท่านต้องการดาวน์โหลด')
          setMessageModal('ขออภัย ไม่พบโบรชัวร์ที่ท่านต้องการดาวน์โหลด')
        }
      }
    } catch (error) {
      console.error('Error : ', error)
      setHeaderModal('ไม่พบโบรชัวร์ที่ท่านต้องการดาวน์โหลด')
      setMessageModal('ขออภัย ไม่พบโบรชัวร์ที่ท่านต้องการดาวน์โหลด')
    }
  }, [props.isOpen])

  const RenderBTNPDF = (_doc) => {
    try {
      let _viewBTN = []
      if (_.includes(Object.keys(_doc.brochureLink), 'th')) {
        _viewBTN.push(
          <div key={'docBrochureTH'} className="colLanguage">
            <Button
              color="primary"
              className="BTN_TH"
              onClick={() => {
                onOpenBrochure(_doc.brochureLink.th)
                props.onExit()
              }}
            >
              สำหรับภาษาไทย
            </Button>
          </div>
        )
      }
      if (_.includes(Object.keys(_doc.brochureLink), 'en')) {
        _viewBTN.push(
          <div key={'docBrochureEN'} className="colLanguage">
            <Button
              outline
              color="primary"
              className="BTN_EN"
              onClick={() => {
                onOpenBrochure(_doc.brochureLink.en)
                props.onExit()
              }}
            >
              สำหรับภาษาอังกฤษ
            </Button>
          </div>
        )
      }
      return _viewBTN
    } catch (error) {
      return <></>
    }
  }

  return (
    <Modal
      isOpen={props.isOpen}
      backdrop={true}
      size="lg"
      modalClassName="default-bootstrap brochurelanguage-modal"
      toggle={props.onExit}
    >
      <ModalHeader tag="div" toggle={props.onExit}>
        <div>{hedaerModal}</div>
      </ModalHeader>
      <ModalBody className="modal-body-content">
        <p>{messageModal}</p>
        <Row md="2" sm="2" xs="1">
          {!_.isNull(props.application) &&
          _.includes(Object.keys(props.application), 'brochureLink') &&
          Object.keys(props.application.brochureLink).length > 0 &&
          !_.isNull(props.application.brochureLink) ? (
            RenderBTNPDF(props.application)
          ) : (
            <div className="colLanguage">
              <Button
                color="primary"
                className="BTN_CANCEL"
                onClick={() => {
                  props.onExit()
                }}
              >
                ยืนยัน
              </Button>
            </div>
          )}
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default ModalConfirm
