//@flow
import styled from 'styled-components'
import { Progress } from 'reactstrap'
import { Heading } from 'common-components/text'
import { Flex } from 'rebass'
import { getIcon } from 'e-submission/components/Icon'

export type InvestmentAdviceProps = {
  riskEvaluatedValue: number,
}

const InvestmentAdviceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;
  padding: 3% 3%;
  background-color: white;
  text-align: center;

  @media (min-width: 768px) {
    justify-content: space-around;
    align-items: flex-start;
    text-align: left;
  }
`
const InvestmentTitle = styled.div`
  margin-bottom: 3%;
`
const InvestmentTable = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    width: 100%;
  }
`
const InvestItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`
const Pic = styled.div`
  color: ${(prop) => prop.color};
  display: flex;
  align-items: center;
  width: 100%;

  @media (min-width: 768px) {
    max-width: 270px;
  }
`
const PercentBar = styled.div`
  width: 320px;
  display: flex;

  @media (min-width: 768px) {
    width: 100%;
  }
  .progress {
    flex: 1;
  }
  .progress-bar {
    background-color: ${(prop) => prop.color};
    height: auto;
  }
`

const TextRemark = styled.span`
  font-size: 0.8667rem;
  margin-top: 3%;
`

const InvestmentDescription = styled.div`
  padding: 0 6%;
  text-align: left;
  @media (min-width: 768px) {
    padding: 0 10%;
    width: 200px;
  }
`
const FlexModified = styled(Flex)`
  max-width: 50px;
  @media (min-width: 768px) {
    max-width: 53px;
  }
`
const PercentText = styled.div`
  color: ${(prop) => prop.color};
  background-color: ${(prop) => prop.bgColor};
  min-width: 16%;
  padding: 0 1%;
  @media (min-width: 768px) {
    min-width: 15.3%;
  }
`
const mapInvestMent = (riskLavel) => {
  const mapLevel = {
    1: ['75', 30, 5, 0],
    4: [25, 65, 10, 5],
    5: [10, 60, 20, 10],
    7: [10, 40, 40, 15],
    8: [5, 15, '60', 25],
  }
  const investValue = [
    {
      id: 1,
      icon: 'savingShorttermBond',
      description: 'เงินฝากและตราสารหนี้ระยะสั้น',
      color: '#4976BA',
      bgColor: '#d2e2fa',
    },
    {
      id: 2,
      icon: 'govermentBond',
      description: 'ตราสารหนี้ภาครัฐและตราสารหนี้ภาคเอกชน',
      color: '#8A7333',
      bgColor: '#f5e3b0',
    },
    {
      id: 3,
      icon: 'equityInvestments',
      description: 'ตราสารทุน',
      color: '#5B7B73',
      bgColor: '#d5f5ed',
    },
    {
      id: 4,
      icon: 'alternativeInvestment',
      description: 'การลงทุนทางเลือก*',
      color: '#147180',
      bgColor: '#d7f5fa',
    },
  ]

  return (
    <InvestmentTable>
      {investValue.map((p, index) => (
        <InvestItem key={`InvestItem-${index}`}>
          <Pic color={p.color}>
            <FlexModified>{getIcon(p.icon)}</FlexModified>
            <InvestmentDescription>{p.description}</InvestmentDescription>
          </Pic>
          <PercentBar color={p.color}>
            <PercentText color={p.color} bgColor={p.bgColor}>
              {typeof mapLevel[riskLavel][index] === 'string' ? '≥' : '≤'} {mapLevel[riskLavel][index]}%
            </PercentText>
            <Progress value={mapLevel[riskLavel][index]}></Progress>
          </PercentBar>
        </InvestItem>
      ))}
    </InvestmentTable>
  )
}
export const InvestmentAdvice = (props: InvestmentAdviceProps) => {
  return (
    <InvestmentAdviceWrapper>
      <InvestmentTitle>
        <Heading>ตัวอย่างคำแนะนำเรื่องการจัดสรรการลงทุนของท่าน</Heading>
      </InvestmentTitle>
      {mapInvestMent(props.riskEvaluatedValue)}
      <TextRemark>*รวมถึงสินค้าโภคภัณฑ์ สัญญาซื้อขายล่วงหน้า</TextRemark>
    </InvestmentAdviceWrapper>
  )
}
