// @flow

import _ from 'lodash'
import thaiDict from './thai-wordlist.json'

// EOW = End of Word
type Leaf = { EOW: {} }

type Node = { [string]: Node | Leaf }
type PrefixTree = Node | Leaf

const insertWord = (tree: PrefixTree, word: string): PrefixTree => {
  let chars = word.split('')
  let currentNode = tree

  while (!_.isEmpty(chars)) {
    const [char, ...remainings] = chars

    if (!_.has(currentNode, char)) {
      currentNode[char] = {}
    }

    // prepare for next iteration
    chars = remainings
    currentNode = currentNode[char]
  }

  // mark as leaf when no more char left
  currentNode['EOW'] = {}

  return tree
}

export const _buildPrefixTree = (words: string[]): PrefixTree => {
  const tree = {}
  words.forEach((word) => insertWord(tree, word))

  return tree
}

type Matched = string
type Rest = string

export const _extractFirstComponent = (tree: PrefixTree, text: string): [Matched, Rest] => {
  let chars = text.split('')
  let currentNode = tree
  let matched = ''
  let isMatchingThaiWord = _.has(currentNode, chars[0])
  let possibleResult: [Matched, Rest] = [text, '']

  while (!_.isEmpty(chars)) {
    const [char, ...remainings] = chars
    const isPossibleToBeThaiWord = _.has(currentNode, char)
    const isPossibleWordEnd = _.has(currentNode[char], 'EOW')

    if (isMatchingThaiWord) {
      if (isPossibleToBeThaiWord) {
        matched = matched + char
        currentNode = currentNode[char]

        if (isPossibleWordEnd) {
          possibleResult = [matched, remainings.join('')]
        }
      } else {
        return possibleResult
      }
    }

    if (!isMatchingThaiWord) {
      if (!isPossibleToBeThaiWord) {
        matched = matched + char
      } else {
        break
      }
    }

    chars = remainings
  }

  const rest = chars.join('')

  if (text === rest) {
    throw new Error('${text} can never get extracted')
  }

  return [matched, rest]
}

export const _splitWords = (dict: string[]) => {
  const tree = _buildPrefixTree(dict)

  return (text: string): string[] => {
    let localText = text
    let splited = []

    while (localText !== '') {
      const [word, rest] = _extractFirstComponent(tree, localText)
      splited.push(word)

      // prepare for next iteration
      localText = rest
    }

    return splited
  }
}

export const splitWords: (string) => string[] = _splitWords(thaiDict)

type ArrayContainerKey = 'ol' | 'ul' | 'columns' | 'stack'
type ArrayContainer = {
  [ArrayContainerKey]: Content[],
  table: Table,
  ...Object,
}

type Table = { body: Content[][], ...Object }
type Text = string | { text: Text | Text[], ...Object }

type Content = ArrayContainer | Text
type DocDefinition = { content: Content[], ...Object }

export const _splitWordsInDocDefinition = (splitWords: (string) => string[]) => (
  docDefinition: DocDefinition
): DocDefinition => {
  const splitWordsInContent = (node: Content) => {
    if (typeof node === 'string') {
      return splitWordsForText(node)
    }

    if (node.text != null) {
      return splitWordsForText(node)
    }

    if (Array.isArray(node)) {
      return node.map(splitWordsInContent)
    }

    return splitWordsInArrayContainer(node)
  }

  const splitWordsInArrayContainer = (node: ArrayContainer): ArrayContainer => {
    if (node.table) {
      if (node.table.body) {
        return {
          ...node,
          table: {
            ...node.table,
            body: node.table.body.map((b) => b.map(splitWordsInContent)),
          },
        }
      }
    }

    const containerTypes: ArrayContainerKey[] = ['ol', 'ul', 'columns', 'stack']

    const combineResult = (result, type) =>
      Array.isArray(node[type]) ? { ...node, [type]: node[type].map(splitWordsInContent) } : result

    return containerTypes.reduce(combineResult, node)
  }

  const splitWordsForText = (node: Text): Text => {
    if (typeof node === 'string') {
      return { text: splitWords(node) }
    }

    if (typeof node === 'number') {
      return node
    }

    if (typeof node === 'object') {
      if (node.hasOwnProperty('text')) {
        if (Array.isArray(node.text)) {
          return { ...node, text: node.text.map(splitWordsForText) }
        }
        if (node.hasOwnProperty('decoration')) {
          return node
        }
        if (typeof node.text === 'string') {
          return { ...node, text: splitWords(node.text) }
        }

        if (typeof node.text === 'number') {
          return node
        }

        if (typeof node.text === 'object') {
          return { ...node, text: splitWordsForText(node.text) }
        }
      }
    }

    ;(node: empty)
    throw new Error('This should never throw from splitWordsForText due to exhaustive checking')
  }

  const content = docDefinition.content.map(splitWordsInContent)
  return { ...docDefinition, content }
}

export const splitWordsInDocDefinition: (DocDefinition) => DocDefinition = _splitWordsInDocDefinition(splitWords)
