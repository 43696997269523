//@flow
import { FundItem } from './fund-item'

export const sortFunds = (a, b) => {
  if (b.riskLevel !== a.riskLevel) {
    return b.riskLevel - a.riskLevel
  } else {
    return a.code < b.code ? -1 : a.code > b.code ? 1 : 0
  }
}

export type ViewFunds = {| ...FundState, fundAllocation: number, selected?: boolean, errors?: string[] |}
export type FundCategory = {
  categoryName: string,
  funds: ViewFunds[],
}
export type FundListProps = {
  allFunds: FundCategory[],
}
export const FundList = ({ allFunds }: FundListProps) => (
  <div className="fund-summary">
    <div className="fund-group">
      {allFunds.sort(sortFunds).map((item, index) => (
        <FundItem key={`fund-${index}`} {...item} />
      ))}
    </div>
  </div>
)
