// @flow

import _ from 'lodash'
import type { AppState } from 'quick-quote/reducers'
import type { Benefit, RiderBenefitData } from 'core/data-model/rider/benefits'
import type { Rider, RiderState } from 'core/data-model/rider'
import type {
  APAndECARERiderBenefitObject,
  APAndECARERiderBenefits,
  RiderBenefitTables,
} from 'core/service/rider/benefits'
import type { PolicyValueParams, BenefitSummary, PolicyValue, TaxDeduction } from 'core/service/benefit-illustration'
import { calculateLegalDisclaimerEndDate } from 'core/service/benefit-illustration'

import values from 'core/data-model/constants/values'
import { isNotNil, isString, isArray } from 'core/service/lib/type-check'
import { createSelector } from 'reselect'
import { formatNumber } from 'core/service/lib/number-format'
import { getSelectedDisplayProductQuery, getSelectedDisplayProductCode } from 'quick-quote/product-selection/selectors'
import {
  getSumAssured$,
  getSelectedModelFactorPeriod,
  getSelectedModelFactorValue,
  getcoveragePlanStartDate,
  getPensionType,
  getBasicPremium$,
  getAvailableRidersByCode,
  getSelectedRiders,
  getAvailableRiders,
  getTotalRiderPremium,
  getFullBasicPremium,
  getFullAnnualBasicPremium,
} from 'quick-quote/product-common/coverage-plan/selectors'

import { getValidSelectedRiders } from 'quick-quote/product-whole-life/coverage-plan/selectors'

import {
  getAge,
  getGender,
  getPayerAge,
  getPayerGender,
  getAllNatureOfDutyCodes,
  getAllPayerNatureOfDutyCodes,
} from 'quick-quote/insured-information/selectors'
import { getLoan } from 'quick-quote/product-mrta/coverage-plan/selectors'

export const getPolicyValues = (state: AppState): PolicyValue => state.benefitIllustration.policyValue
export const getRiderBenefitTables = (state: AppState): ?RiderBenefitTables => state.benefitIllustration.riderBenefits
export const getBenefitSummary = (state: AppState): BenefitSummary => state.benefitIllustration.benefitSummary
export const getTaxDeduction = (state: AppState): TaxDeduction => state.benefitIllustration.taxDeduction

export const getRiderPremiumForTaxDeduction = createSelector(
  getSelectedDisplayProductCode,
  getAvailableRiders,
  getTotalRiderPremium,
  (productCode: string, availableRiders: (Rider & RiderState)[], totalRiderPremium: number): number => {
    switch (productCode) {
      case 'WL88J':
      case 'WL88S':
        const riderPLS = availableRiders.find(
          (r) => _.includes(['PLS10', 'PLS12', 'PLS15'], _.get(r, 'selectedPlan.planCode')) && r.isSelected
        )

        return _.get(riderPLS, 'premium', 0) > 100000 ? 100000 : _.get(riderPLS, 'premium', 0)
      default:
        return totalRiderPremium
    }
  }
)

export const getLegalDisclaimerEndDate = createSelector(getcoveragePlanStartDate, (startDate): Date => {
  return calculateLegalDisclaimerEndDate(startDate)
})

export const getAPAndEcareRider = (state: AppState) => {
  const apRider = getAvailableRidersByCode(state).AP
  const ecareRider = getAvailableRidersByCode(state).ECARE
  return _.omitBy({ apRider, ecareRider }, (rider) => rider && !(rider.isSelected && rider.isSelectable))
}

// TODO: Move data to database
export const getAPAndECARERiderBenefitDescription = (): APAndECARERiderBenefitObject => {
  return {
    apAndEcare: {
      accidents: {
        description: '1. กรณีเสียชีวิต สูญเสียอวัยวะ และสายตา',
        lossOfLife: 'เสียชีวิต',
        lossOf1orMoreLimbs: 'สูญเสียมือหรือเท้า 1 ข้างหรือมากกว่า',
        lossOfSightOf1orBothEyes: 'สูญเสียสายตา 1 ข้างหรือมากกว่า',
        lossOfHearing: {
          description: 'สูญเสียการได้ยิน',
          bothEars: '- ทั้งสองข้าง',
          oneEars: '- ข้างเดียว',
        },
        lossOfSpeech: 'สูญเสียการพูด',
        lossOfLensOfBothEyes: 'สูญเสียแก้วตาทั้งสองข้าง',
        lossOfAllJointsOf4fingersAndThumbOf: {
          description: 'สูญเสียนิ้วมือทั้ง 4 และนิ้วหัวแม่มือ',
          rightHand: '- มือขวา',
          leftHand: '- มือซ้าย',
        },
        lossOfAllJointsOf4fingersOf: {
          description: 'สูญเสียนิ้วมือทั้ง 4 นิ้ว',
          rightHand: '- มือขวา',
          leftHand: '- มือซ้าย',
        },
        fracturedLegOrPatella: 'กระดูกขา หรือสะบ้า แตกหัก',
        shorteningOfLegByLeast5cms: 'ขาหดสั้นลงอย่างน้อย 5 เซนติเมตร',
        lossOfThumb: {
          description: 'สูญเสียนิ้วหัวแม่มือ',
          twoJointsOfRightThumb: '- นิ้วหัวแม่มือขวาทั้งสองข้อ',
          oneJointsOfRightThumb: '- นิ้วหัวแม่มือขวาหนึ่งข้อ',
          twoJointsOfLeftThumb: '- นิ้วหัวแม่มือซ้ายทั้งสองข้อ',
          oneJointsOfLeftThumb: '- นิ้วหัวแม่มือซ้ายหนึ่งข้อ',
        },
        lossOfOtherFinger: {
          description: 'สูญเสียนิ้วมืออื่นๆ',
          threeJointsOf1fingerOfRightHand: '- นิ้วมือขวา นิ้วใดนิ้วหนึ่งทั้งสามข้อ',
          twoJointsOf1fingerOfRightHand: '- นิ้วมือขวา นิ้วใดนิ้วหนึ่งสองข้อ',
          oneJointsOf1fingerOfRightHand: '- นิ้วมือขวา นิ้วใดนิ้วหนึ่งข้อเดียว',
          threeJointsOf1fingerOfLeftHand: '- นิ้วมือซ้าย นิ้วใดนิ้วหนึ่งทั้งสามข้อ',
          twoJointsOf1fingerOfLeftHand: '- นิ้วมือซ้าย นิ้วใดนิ้วหนึ่งทั้งสองข้อ',
          oneJointsOf1fingerOfLeftHand: '- นิ้วมือซ้าย นิ้วใดนิ้วหนึ่งข้อเดียว',
        },
        lossOfToe: {
          description: 'สูญเสียนิ้วเท้า',
          allJointsOfAllToesOf1foot: '- นิ้วเท้าหมดทุกนิ้ว 1 เท้า',
          allJointsOfGreatToe: '- นิ้วหัวแม่เท้าทั้งนิ้ว',
          oneJointsOfGreatToe: '- นิ้วหัวแม่เท้าหนึ่งข้อ',
        },
        disclaimer: 'กรณีเสียชีวิต ผลประโยชน์เป็น 2 เท่าหากอุบัติเหตุเกิดจากสาธารณภัย',
      },
      burns: {
        description: '2. กรณีมีบาดแผลจากการไฟไหม้',
        head: {
          description: 'ศีรษะ',
          equalToOrGreaterThan2PercentButLessThan4Percent: 'มากกว่าหรือเท่ากับ 2% แต่น้อยกว่า 4%',
          equalToOrGreaterThan4PercentButLessThan6Percent: 'มากกว่าหรือเท่ากับ 4% แต่น้อยกว่า 6%',
          equalToOrGreaterThan6PercentButLessThan8Percent: 'มากกว่าหรือเท่ากับ 6% แต่น้อยกว่า 8%',
          equalToOrGreaterThan8Percent: 'เท่ากับหรือมากกว่า 8%',
        },
        body: {
          description: 'ร่างกาย',
          equalToOrGreaterThan10PercentButLessThan12Point5Percent: 'มากกว่าหรือเท่ากับ 10% แต่น้อยกว่า 12.5%',
          equalToOrGreaterThan12Point5PercentButLessThan15Percent: 'มากกว่าหรือเท่ากับ 12.5% แต่น้อยกว่า 15%',
          equalToOrGreaterThan15PercentButLessThan20Percent: 'มากกว่าหรือเท่ากับ 15% แต่น้อยกว่า 20%',
          equalToOrGreaterThan20Percent: 'เท่ากับหรือมากกว่า 20%',
        },
      },
    },
    ecare: {
      temporaryDisability: {
        description: '3. ค่าชดเชยกรณีทุพพลภาพชั่วคราว (ผลประโยชน์ข้อ 3.1 และ 3.2 รวมกันสูงสุดไม่เกิน 52 สัปดาห์)',
        totalAndTemporaryDisability: '3.1 ทุพพลภาพสิ้นเชิงชั่วคราว',
        partialAndTemporaryDisability: '3.2 ทุพพลภาพบางส่วนชั่วคราว',
        disclaimer: '',
      },
      permanentDisability:
        '4. ค่าชดเชยกรณีทุพพลภาพถาวรสิ้นเชิง สูงสุดไม่เกิน 100 เดือน โดยมีเงื่อนไขว่าได้จ่ายค่าชดเชยภายใต้ผลประโยชน์ 3. เป็นเวลา 52 สัปดาห์แล้ว',
      hospitalConfinement: '5. ค่าชดเชยกรณีเข้ารักษาตัวในโรงพยาบาลในฐานะคนไข้ใน (สูงสุดไม่เกิน 20 สัปดาห์)',
      accidentalReimbursed: '6. ค่ารักษาพยาบาลเนื่องจากอุบัติเหตุ* ตามที่จ่ายจริง แต่ไม่เกิน',
      disclaimer: '*ค่ารักษาพยาบาลรวมถึง ค่ายา ค่าโรงพยาบาล ค่าธรรมเนียมแพทย์',
    },
  }
}
// TODO: Move data to database
export const getAPAndECARERiderBenefitValue = (): APAndECARERiderBenefitObject => {
  return {
    apAndEcare: {
      accidents: {
        lossOfLife: '100%',
        lossOf1orMoreLimbs: '100%',
        lossOfSightOf1orBothEyes: '100%',
        lossOfHearing: {
          bothEars: '75%',
          oneEars: '15%',
        },
        lossOfSpeech: '50%',
        lossOfLensOfBothEyes: '50%',
        lossOfAllJointsOf4fingersAndThumbOf: {
          rightHand: '70%',
          leftHand: '50%',
        },
        lossOfAllJointsOf4fingersOf: {
          rightHand: '40%',
          leftHand: '30%',
        },
        fracturedLegOrPatella: '10%',
        shorteningOfLegByLeast5cms: '7.5%',
        lossOfThumb: {
          twoJointsOfRightThumb: '30%',
          oneJointsOfRightThumb: '15%',
          twoJointsOfLeftThumb: '20%',
          oneJointsOfLeftThumb: '10%',
        },
        lossOfOtherFinger: {
          threeJointsOf1fingerOfRightHand: '10%',
          twoJointsOf1fingerOfRightHand: '7.5%',
          oneJointsOf1fingerOfRightHand: '5%',
          threeJointsOf1fingerOfLeftHand: '7.5%',
          twoJointsOf1fingerOfLeftHand: '5%',
          oneJointsOf1fingerOfLeftHand: '2%',
        },
        lossOfToe: {
          allJointsOfAllToesOf1foot: '15%',
          allJointsOfGreatToe: '5%',
          oneJointsOfGreatToe: '3%',
        },
      },
      burns: {
        head: {
          equalToOrGreaterThan2PercentButLessThan4Percent: '25%',
          equalToOrGreaterThan4PercentButLessThan6Percent: '50%',
          equalToOrGreaterThan6PercentButLessThan8Percent: '75%',
          equalToOrGreaterThan8Percent: '100%',
        },
        body: {
          equalToOrGreaterThan10PercentButLessThan12Point5Percent: '25%',
          equalToOrGreaterThan12Point5PercentButLessThan15Percent: '50%',
          equalToOrGreaterThan15PercentButLessThan20Percent: '75%',
          equalToOrGreaterThan20Percent: '100%',
        },
      },
    },
    ecare: {
      temporaryDisability: {
        totalAndTemporaryDisability: '1%',
        partialAndTemporaryDisability: '0.25%',
      },
      permanentDisability: '1%',
      hospitalConfinement: '1%',
      accidentalReimbursed: '5%',
    },
  }
}

export const getRiderWithPlanBenefits = (riderCode: string) =>
  createSelector(getRiderBenefitTables, getAvailableRidersByCode, (riderBenefitTables, ridersTable): ?(Benefit[]) => {
    const rider = ridersTable[riderCode]
    if (isNotNil(rider) && isNotNil(rider.selectedPlan.planCode)) {
      const benefitData = _.get(riderBenefitTables, [riderCode, 'riderBenefitData'])
      if (!benefitData) {
        return undefined
      }
      return benefitData.benefits.map((benefit) => {
        if (rider) return getSelectedPlanBenefit(benefit, rider.selectedPlan.planCode)
      })
    }
  })

const getSelectedPlanBenefit = (benefit: RiderBenefitData, selectedPlanCode: string): Benefit => {
  let selectedPlanBenefit =
    benefit.values && isArray(benefit.values) ? _.find(benefit.values, ['planCode', selectedPlanCode]) : null
  return {
    description: isString(benefit.description) ? benefit.description : '',
    value:
      selectedPlanBenefit && selectedPlanBenefit.value && isString(selectedPlanBenefit.value)
        ? selectedPlanBenefit.value
        : '',
  }
}

export const getAPAndECAREBenefits = createSelector(
  getSelectedRiders,
  getAPAndECARERiderBenefitDescription,
  getAPAndECARERiderBenefitValue,
  (
    selectedRiders: (Rider & RiderState)[],
    apAndEcareRiderBenefitDescription,
    apAndEcareRiderBenefitValue
  ): ?APAndECARERiderBenefits => {
    const ecareSumAssured: number = _.filter(selectedRiders, (rider) => rider.code === 'ECARE')
      .map((rider) => rider.sumAssured)
      .reduce((a, b) => a + b, 0)

    const apSumAssured: number = _.filter(selectedRiders, (rider) => rider.code === 'AP')
      .map((rider) => rider.sumAssured)
      .reduce((a, b) => a + b, 0)

    const apAndEcareSumAssured: number = ecareSumAssured + apSumAssured

    const percentageNumber = (percent: number, sumAssured: number): string =>
      formatNumber(Math.round((sumAssured * percent) / 100))
    const changePercentToNumber = (percent: string): number => Number(percent.replace('%', ''))

    const calculateRiderSumAssuredFromPercentObject = (
      riderBenefitPercentObject: Object,
      riderSumAssured: number
    ): Object => {
      for (let key in riderBenefitPercentObject) {
        switch (typeof riderBenefitPercentObject[key]) {
          case 'object':
            riderBenefitPercentObject[key] = calculateRiderSumAssuredFromPercentObject(
              riderBenefitPercentObject[key],
              riderSumAssured
            )
            break
          case 'string':
            riderBenefitPercentObject[key] = percentageNumber(
              changePercentToNumber(riderBenefitPercentObject[key]),
              riderSumAssured
            )
            break
        }
      }
      return riderBenefitPercentObject
    }

    if (apSumAssured === 0 && ecareSumAssured === 0) {
      return
    }

    if (apSumAssured !== 0 && ecareSumAssured === 0) {
      return {
        description: {
          apAndEcare: apAndEcareRiderBenefitDescription.apAndEcare,
        },
        value: {
          apAndEcare: calculateRiderSumAssuredFromPercentObject(
            apAndEcareRiderBenefitValue.apAndEcare,
            apAndEcareSumAssured
          ),
        },
      }
    }

    return {
      description: apAndEcareRiderBenefitDescription,
      value: {
        apAndEcare: calculateRiderSumAssuredFromPercentObject(
          apAndEcareRiderBenefitValue.apAndEcare,
          apAndEcareSumAssured
        ),
        ecare: apAndEcareRiderBenefitValue.ecare
          ? calculateRiderSumAssuredFromPercentObject(apAndEcareRiderBenefitValue.ecare, ecareSumAssured)
          : undefined,
      },
    }
  }
)

export const getHasNonLevelRider = createSelector(
  getSelectedRiders,
  (selectedRiders: (Rider & RiderState)[]): boolean => {
    return isNotNil(selectedRiders.find((rider) => rider.premiumStructure === values.PREMIUM_NON_LEVEL))
  }
)

export const getPolicyValueParams = createSelector(
  getSelectedDisplayProductQuery,
  getAge,
  getPayerAge,
  getGender,
  getPayerGender,
  getSumAssured$,
  getBasicPremium$,
  getSelectedModelFactorPeriod,
  getValidSelectedRiders,
  getSelectedModelFactorValue,
  getAllNatureOfDutyCodes,
  getRiderBenefitTables,
  getPensionType,
  getAllPayerNatureOfDutyCodes,
  getLoan,
  getFullBasicPremium,
  getFullAnnualBasicPremium,
  (
    basicPlanQuery,
    insuredAge,
    payerAge,
    gender,
    payerGender,
    sumAssured$,
    basicPremium$,
    modelFactorPeriod,
    validSelectedRiders,
    modelFactor,
    natureOfDutyCodes,
    riderBenefitTables,
    pensionType,
    payerNatureOfDutyCodes,
    loan,
    fullBasicPremium,
    fullAnnualBasicPremium
  ): PolicyValueParams => ({
    basicPlanQuery,
    age: insuredAge,
    payerAge,
    gender,
    payerGender,
    sumAssured: sumAssured$.value,
    basicPremium: basicPremium$.value,
    modelFactor,
    modelFactorPeriod,
    validSelectedRiders,
    natureOfDutyCodes,
    riderBenefitTables,
    pensionType,
    payerNatureOfDutyCodes,
    loan,
    fullBasicPremium,
    fullAnnualBasicPremium,
  })
)
