// @flow
import type { Dispatch } from 'redux'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormGroup } from 'reactstrap'

import PremiumInput from './premium-input'
import type { ModelFactor } from 'core/data-model/basic-plan'
import type { RegularPremium } from 'core/service/investment'

import BasicPlanErrorMessages from './basic-plan-error-messages'
import {
  editRegularPremium,
  editRegularTopUp,
  editSumAssured,
  selectModelFactor,
} from 'quick-quote/product-investment/coverage-plan/actions'
import {
  getAvailableModelFactors,
  getRegularPremium,
  getRegularTopUp,
  getSelectedModelFactorID,
  getSumAssured,
} from 'quick-quote/product-investment/coverage-plan/selectors'
import { getSelectedDisplayProductCode } from 'quick-quote/product-selection/selectors'
import type { AppState } from 'quick-quote/reducers'
import { RadioButtonGroup } from 'common-components'
import MESSAGES from 'core/data-model/constants/messages'

type SpulBasicPlanCalculationProps = {
  selectedProductCode: string,
  modelFactors: ModelFactor[],
  regularPremium: RegularPremium,
  selectedModelFactorID: string,
  editRegularPremium: (number) => void,
  selectModelFactor?: (string) => void,
}

export const SpulBasicPlanCalculation = ({
  modelFactors,
  regularPremium,
  selectedModelFactorID,
  editRegularPremium,
  selectModelFactor,
}: SpulBasicPlanCalculationProps) => {
  return (
    <div id="basic-plan">
      <div className="content">
        <div className="title-bottom-padding">{MESSAGES.PREMIUM_PROPORTION}</div>

        <FormGroup>
          <label> {MESSAGES.PAYMENT_MODE} </label>
          <RadioButtonGroup
            keyToGetValue="id"
            className="model-factor"
            onChange={selectModelFactor}
            value={selectedModelFactorID}
            options={modelFactors}
          />
        </FormGroup>

        <FormGroup className="coverage-plan-calculation item basic">
          <div>
            <div className="sum-assured-input">
              <div className="label">
                <label>{MESSAGES.SPUL_PREMIUM_LABEL}</label>
              </div>

              <PremiumInput
                id="coverage-regular-premium"
                premium={regularPremium}
                editPremiumAmount={editRegularPremium}
              />
            </div>
            <FormGroup className="has-danger">
              <BasicPlanErrorMessages premiumValue={regularPremium} />
            </FormGroup>
          </div>
        </FormGroup>
      </div>
    </div>
  )
}

const mapStateToProps = (state: AppState): * => ({
  selectedProductCode: getSelectedDisplayProductCode(state),
  modelFactors: getAvailableModelFactors(state),
  sumAssured: getSumAssured(state),
  regularPremium: getRegularPremium(state),
  regularTopUp: getRegularTopUp(state),
  selectedModelFactorID: getSelectedModelFactorID(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  selectModelFactor: bindActionCreators(selectModelFactor, dispatch),
  editSumAssured: bindActionCreators(editSumAssured, dispatch),
  editRegularPremium: bindActionCreators(editRegularPremium, dispatch),
  editRegularTopUp: bindActionCreators(editRegularTopUp, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(SpulBasicPlanCalculation)
