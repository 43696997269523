import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import words from './non-breaking-words.json'

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.node)]),
}

const defaultProps = {
  children: '',
}

const WordWraps = styled(({ className, children }) => {
  const childrenText = React.Children.map(children, (child) => child).join('')
  const conditions = new RegExp(words.join('|'), 'gi')
  const processedWords = childrenText
    ? childrenText.replace(conditions, (word) => `<span class="nbw">${word}</span>`)
    : ''

  return <span className={className} dangerouslySetInnerHTML={{ __html: processedWords }} />
})`
  .nbw {
    white-space: nowrap;
  }
`

WordWraps.propTypes = propTypes
WordWraps.defaultProps = defaultProps

export default WordWraps
