//@flow
const noBorder = [false, false, false, false]
const bottomBorder = [false, false, false, true]
const HORIZONTAL_PADDING = 5
const VERTICAL_PADDING = 15
const LABEL_COLUMN_WIDTH = 80
const LABEL_BOTTOM_MARGIN = -3

export const blankCell = { text: ' ', border: noBorder }

const addRowStyle = (body, configs) => {
  if (configs) {
    return body.map(([first, second], i) => {
      return [
        { text: first, border: noBorder, marginBottom: LABEL_BOTTOM_MARGIN },
        { text: second, border: bottomBorder, alignment: 'center', ...configs[i] },
      ]
    })
  }
  return body.map(([first, second]) => [
    { text: first, border: noBorder, marginBottom: LABEL_BOTTOM_MARGIN },
    { text: second, border: bottomBorder, alignment: 'center' },
  ])
}

const addVerticalPadding = ([head, ...rest]) => {
  if (!head) return []
  const newHead = head.map((cell) => ({ ...cell, marginTop: VERTICAL_PADDING }))
  const newLast = head.map(() => ({ ...blankCell }))
  return [newHead].concat(rest).concat([newLast])
}

const addSideColumns = (body) => body.map((row) => [blankCell, ...row, blankCell])

export const signBox = (data: [string | string[], string][], configs) => {
  const widths = [HORIZONTAL_PADDING, LABEL_COLUMN_WIDTH, '*', HORIZONTAL_PADDING]

  const body = [data]
    .map((d) => addRowStyle(d, configs))
    .map(addVerticalPadding)
    .map(addSideColumns)
    .reduce((body) => body)

  return { table: { widths, body } }
}

export const label = (text: string) => ({
  text,
  border: noBorder,
  alignment: 'right',
})

export const field = (text: string) => ({
  text,
  border: bottomBorder,
  alignment: 'center',
})

const isLabel = (index) => index % 2 === 0

const toForm = (text, index) => (isLabel(index) ? label(text) : field(text))

type FormWidth = { label: string | number, field: string | number }

export const form = (formData: string[], width: FormWidth = { label: 'auto', field: '*' }) => ({
  table: {
    body: [formData.map(toForm)],
    widths: formData.map((_, index) => (isLabel(index) ? width.label : width.field)),
  },
  layout: {
    hLineWidth: () => 0.1,
  },
})
