// @flow
import MESSAGES from 'core/data-model/constants/bi-messages'

type HealthCheckRuleProps = {
  healthCheckList: string[],
  financialCheckList: string[],
}

const HealthCheckRule = ({ healthCheckList, financialCheckList }: HealthCheckRuleProps) => {
  let healthCheckItems = healthCheckList.map(function(desc) {
    return <li key={desc}>{desc}</li>
  })
  let financialDocuments = financialCheckList.map(function(desc) {
    return <li key={desc}>{desc}</li>
  })
  let healthCheckListTitle = healthCheckList.length > 0 ? MESSAGES.HEALTH_CHECK_LIST : MESSAGES.NO_HEALTH_CHECK
  let financialCheckListTitle = financialCheckList.length > 0 ? MESSAGES.FINANCIAL_STATUS_REPORTING_RULES : ''
  return (
    <div id="product-benefits-document" className="benefit group">
      <div id="product-document-summary" className="basicPlan">
        <div className="basicplan">
          <h3 className="name">{MESSAGES.HEALTH_CHECK_RULE}</h3>
        </div>
        <p>
          <b>{healthCheckListTitle}</b>
        </p>
        <ol id="benefit-list">{healthCheckItems} </ol>
        <p>
          <b>{financialCheckListTitle}</b>
        </p>
        <ol id="benefit-list">{financialDocuments} </ol>
      </div>
    </div>
  )
}
export default HealthCheckRule
