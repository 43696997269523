// @flow
import type { Effect } from 'redux-saga/effects'
import { put, select, takeEvery } from 'redux-saga/effects'
import { SET_FNA_VALUE, SetFnaValue } from '../actions'
import { getToggles } from '../../feature-toggles'
import { setNotificationMessage } from 'quick-quote/notification/actions'

import {
  clearMarketConductForm,
  clearMarketConductReasonOthers,
  clearThirdPerson,
  SET_MARKET_CONDUCT_REASON_VALUE,
  setCurrentMarketConductForm,
  setFnaMarketConductValue,
  setFnaMarketConductThirdPersonFullName,
  setFnaMarketConductThirdPersonPhoneNumber,
  setFnaMarketConductThirdPersonRelationship,
  setFnaMarketConductThirdPersonTitle,
  setFnaMarketConductThirdPersonTitleText,
  setFnaMarketConductThirdPersonFirstName,
  setFnaMarketConductThirdPersonLastName,
} from './actions'
import { SELECTED_PRODUCT } from '../../product-selection/actions'
import { SET_FNA_NEED_CATEGORY } from '../need-and-gap-analysis/actions'

import {
  getMarketConductQuestionsTypeBySelectedProduct,
  getMarketConductQuestionsTypeBySelectedNeed,
  shouldClearMarketConductForm,
  shouldClearMarketConductFormV2,
} from './selectors'

export function* onMarketConductReasonChange(): Generator<*, *, *> {
  yield put(clearMarketConductReasonOthers())
}

export function* handleIsVenerableClientChange(action: SetFnaValue): Generator<*, *, *> {
  const isVenerable = action.payload.value === 'YES'
  if (isVenerable) {
    yield put(setFnaMarketConductThirdPersonPhoneNumber(''))
    yield put(setFnaMarketConductThirdPersonFullName(''))
    yield put(setFnaMarketConductThirdPersonRelationship('FATHER'))
    yield put(setFnaMarketConductThirdPersonTitle('mr'))
    yield put(setFnaMarketConductThirdPersonTitleText('นาย'))
    yield put(setFnaMarketConductThirdPersonFirstName(''))
    yield put(setFnaMarketConductThirdPersonLastName(''))
  } else {
    yield put(clearThirdPerson())
  }
}

export function* handleOwnedAnyPolicyChange(action: SetFnaValue): Generator<*, *, *> {
  const isOwnedPolicy = action.payload.value === 'NO'
  if (isOwnedPolicy) {
    yield put(setFnaMarketConductValue('questions.ownedPolicyStatus', undefined))
    yield put(setFnaMarketConductValue('questions.splittingPremiumFromExpense', undefined))
  }
}

export function* handleIsSplittingPremiumFromExpenseChange(action: SetFnaValue): Generator<*, *, *> {
  const isNever = action.payload.value === 'Never'
  if (!isNever) {
    yield put(setFnaMarketConductValue('questions.reasonSplittingPremiumFromExpense', undefined))
  }
}

export function* handleRiskIsAcceptable(action: SetFnaValue): Generator<*, *, *> {
  const isUnacceptable = action.payload.value === 'NO'
  if (getToggles().ENABLE_REVISE_FNA && isUnacceptable) {
    yield put(
      setNotificationMessage({
        type: 'ConfirmModal',
        title: 'คำเตือน',
        message:
          'เนื่องจากท่านไม่สามารถยอมรับความเสี่ยงได้ หากผลตอบแทนของผลิตภัณฑ์ประกันชีวิตขึ้นอยู่กับผลตอบแทนการลงทุนที่ท่านเลือก กรุณา “ยืนยัน” เพื่อดำเนินการต่อไป',
      })
    )
  }
}

export function* onSelectProduct(): Generator<*, *, *> {
  if (!getToggles().ENABLE_FNA_V3) {
    if (yield select(shouldClearMarketConductForm)) {
      yield put(clearMarketConductForm())
    }
    yield put(setCurrentMarketConductForm(yield select(getMarketConductQuestionsTypeBySelectedProduct)))
  }
}

export function* onSelectNeed(): Generator<*, *, *> {
  if (getToggles().ENABLE_FNA_V3) {
    if (yield select(shouldClearMarketConductFormV2)) {
      yield put(clearMarketConductForm())
    }
    yield put(setCurrentMarketConductForm(yield select(getMarketConductQuestionsTypeBySelectedNeed)))
  }
}

export function* watchers(): Generator<*, *, Effect[]> {
  yield [
    takeEvery(SET_MARKET_CONDUCT_REASON_VALUE, onMarketConductReasonChange),
    takeEvery(SELECTED_PRODUCT, onSelectProduct),
    takeEvery(SET_FNA_NEED_CATEGORY, onSelectNeed),
  ]
  yield [
    takeEvery(
      (action) => action.type === SET_FNA_VALUE && action.payload.path === 'marketConduct.questions.isVenerableClient',
      handleIsVenerableClientChange
    ),
    takeEvery(
      (action) => action.type === SET_FNA_VALUE && action.payload.path === 'marketConduct.questions.ownedAnyPolicy',
      handleOwnedAnyPolicyChange
    ),
    takeEvery(
      (action) =>
        action.type === SET_FNA_VALUE && action.payload.path === 'marketConduct.questions.splittingPremiumFromExpense',
      handleIsSplittingPremiumFromExpenseChange
    ),
    takeEvery(
      (action) => action.type === SET_FNA_VALUE && action.payload.path === 'marketConduct.questions.riskIsAcceptable',
      handleRiskIsAcceptable
    ),
  ]
}

export const sagas = watchers
