import { includes } from 'lodash/fp'
import styled from 'styled-components'
import { lifecycle } from 'recompose'
import { Modal } from 'common-components'

const Dialog = styled(Modal).attrs({
  modalClassName: 'ltr-modal scrollable-modal',
  backdrop: false,
})`
  .modal-body {
    padding: 0;
    background: ${({ theme }) => theme.variables['$color-wild-sand']};
  }
  .modal-footer {
    justify-content: center;
  }
`

export default lifecycle({
  componentDidUpdate(prevProps) {
    if (this.props.isOpen) {
      if (!includes('modal-open', document.body.className)) {
        document.body.classList.add('modal-open')
      }
    }
  },
})(Dialog)
