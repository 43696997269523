// @flow
import _ from 'lodash'
import type { AppState } from 'quick-quote/reducers'
import type { InvestmentBenefitTable } from 'core/service/benefit-illustration/investment-benefit'
import { formatNumber } from 'core/service/lib/number-format'
import { connect } from 'react-redux'
import { getInvestmentBenefit } from './selectors'
import { getLegalDisclaimerEndDate } from 'quick-quote/product-whole-life/benefit-illustration/selectors'
import { getcoveragePlanStartDate } from 'quick-quote/product-common/coverage-plan/selectors'
import { getSelectedDisplayProductNameThai } from 'quick-quote/product-selection/selectors'
import MESSAGES from 'core/data-model/constants/bi-messages'

type InvestmentBenefitTableProps = {
  investmentBenefit: InvestmentBenefitTable,
  productName: string,
  legalDisclaimerEndDate: Date,
  coveragePlanStartDate: Date,
}

export const _InvestmentBenefitTable = ({
  investmentBenefit,
  productName,
  legalDisclaimerEndDate,
  coveragePlanStartDate,
}: InvestmentBenefitTableProps) => (
  <div className="group" id="investment-benefit">
    <h3>{MESSAGES.INVESTMENT_BENEFITS}</h3>
    <div className="dividend-table">
      <table>
        <thead>
          <tr>
            <td rowSpan={2}>{MESSAGES.INVESTMENT_BENEFITS_SUMMARY}</td>
            <td rowSpan={2}>{MESSAGES.INVESTMENT_BENEFITS_GUARANTEE}</td>
            <td colSpan={3}>
              <div>
                <text style={{ color: '#2425aa', fontSize: '24px', textDecorationLine: 'underline' }}>
                  {MESSAGES.EXAMPLE}
                </text>{' '}
                {MESSAGES.TERM_LIFE_BENEFITS_SCENARIO_2}
              </div>
              <div>{MESSAGES.TERM_LIFE_BENEFITS_SCENARIO_3}</div>
              <div>
                {MESSAGES.TERM_LIFE_BENEFITS_SCENARIO_4}
                <text style={{ color: '#2425aa', fontSize: '24px' }}>**</text>
              </div>
            </td>
          </tr>
          <tr>
            <td width="15%">{MESSAGES.TERM_LIFE_BENEFITS_LOW}</td>
            <td width="15%">{MESSAGES.TERM_LIFE_BENEFITS_MIDDLE}</td>
            <td width="15%">{MESSAGES.TERM_LIFE_BENEFITS_HIGH}</td>
          </tr>
        </thead>
        <tbody>
          {investmentBenefit.guaranteeBenefits.map((v, k) => (
            <InvestmentBenifitComponent datas={v} key={`guarantee_benefit_${k}`} />
          ))}
        </tbody>
        <tbody>
          {investmentBenefit.nonGuaranteeBenefits.map((v, k) => (
            <InvestmentBenifitComponent datas={v} key={`no_guarantee_benefit_${k}`} />
          ))}
        </tbody>
      </table>
    </div>
    <div className="disclaimer" style={{ color: '#2425aa' }}>
      <text style={{ color: '#000000' }}>{MESSAGES.NOTE}: </text>
      {MESSAGES.LIFE_SAVE_PRO_POLICY_VALUE_TABLE_NOTE_1}
      <p>{MESSAGES.LIFE_SAVE_PRO_POLICY_VALUE_TABLE_NOTE_2}</p>
      <p>{MESSAGES.TERM_LIFE_BENEFITS_VALUE_TABLE_NOTE_1}</p>
      <p>{MESSAGES.TERM_LIFE_BENEFITS_VALUE_TABLE_NOTE_2}</p>
      <br />
    </div>
  </div>
)

const InvestmentBenifitComponent = ({ datas }) => {
  if (datas.title === MESSAGES.INVESTMENT_BENEFITS_NONGUARANTEE_MATURITY) {
    return (
      <tr>
        <td>
          3&#41;{' '}
          <text style={{ color: '#2425aa', fontSize: '24px' }}>
            {MESSAGES.TERM_LIFE_BENEFITS_NONGUARANTEE_MATURITY_1}
          </text>
          {MESSAGES.TERM_LIFE_BENEFITS_NONGUARANTEE_MATURITY_2}
        </td>
        <Amount amount={datas.guaranteeValue} />
        {datas.rates.map((v, k) => (_.isNumber(v) ? <Amount amount={v} key={k} /> : <td>{v}</td>))}
      </tr>
    )
  } else if (datas.title === MESSAGES.INVESTMENT_BENEFITS_NONGUARANTEE_TOTAL) {
    return (
      <tr>
        <td>
          {MESSAGES.TERM_LIFE_BENEFITS_NONGUARANTEE_TOTAL_1}{' '}
          <text style={{ color: '#2425aa', fontSize: '24px' }}>{MESSAGES.TERM_LIFE_BENEFITS_NONGUARANTEE_TOTAL_2}</text>
        </td>
        <Amount amount={datas.guaranteeValue} />
        {datas.rates.map((v, k) => (_.isNumber(v) ? <Amount amount={v} key={k} /> : <td>{v}</td>))}
      </tr>
    )
  } else {
    return (
      <tr>
        <td>{datas.title}</td>
        <Amount amount={datas.guaranteeValue} />
        {datas.rates.map((v, k) => (_.isNumber(v) ? <Amount amount={v} key={k} /> : <td>{v}</td>))}
      </tr>
    )
  }
}

type AmountProps = {
  amount: number,
}

const Amount = ({ amount }: AmountProps) => <td>{formatNumber(amount, 0, true)}</td>

const mapStateToProps = (state: AppState): InvestmentBenefitTableProps => ({
  investmentBenefit: getInvestmentBenefit(state),
  legalDisclaimerEndDate: getLegalDisclaimerEndDate(state),
  coveragePlanStartDate: getcoveragePlanStartDate(state),
  productName: getSelectedDisplayProductNameThai(state),
})
export default connect(mapStateToProps)(_InvestmentBenefitTable)
