// @flow

import _ from 'lodash'
import Mustache from 'mustache'
import MESSAGES from 'core/data-model/constants/bi-messages'
import VALUES from 'core/data-model/constants/values'
import { createPdfDocument } from '../../document-creation'
import { createCommonPdf } from '../common/pdf-document'

import { commonSection } from 'core/service/pdf-generation/products/common/sections'

import { section as sectionLifeSavePro } from 'core/service/pdf-generation/products/life-save-pro/sections'
import {
  section as sectionWholeLife,
  section12PL,
  sectionPLBMHP as sectionPLB,
} from 'core/service/pdf-generation/products/whole-life/sections'

import template from './template'
import type { DisplayProduct } from 'core/service/display-product'
import type { HealthProps } from './index'

// export const basicPlanLabel = (basicPlan: DisplayProduct) => basicPlan.fullNameThai || basicPlan.nameThai
export const basicPlanLabel = (basicPlan: DisplayProduct) => basicPlan.nameThai
export const iHealthyBasicPlanDetailsLabelFn = (basicPlan: DisplayProduct): string =>
  Mustache.render(MESSAGES.BASIC_PLAN_DETAILS, {
    // $FlowFixMe: `fullNameThai` is not in `DisplayProduct` but was added as optional to `RawBasicPlan` and mapped in the fn `transformToDisplayProduct`
    name: basicPlan.fullNameThai,
    code: basicPlan.basicPlanCode,
  })

const getSectionPolicyValueTable = (code) => {
  switch (code) {
    case VALUES.IHEALTHY_ULTRA_CODE.IHEALTHY_85:
    case VALUES.IHEALTHY_ULTRA_CODE.IHEALTHY_60:
      return sectionPLB
    case VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_25_15.JUNIOR:
    case VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_25_15.SENIOR:
    case VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_20_10.JUNIOR:
    case VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_20_10.SENIOR:
      return sectionLifeSavePro
    case VALUES.IHEALTHY_ULTRA_CODE['12PL']:
      return section12PL
    default:
      return sectionWholeLife
  }
}

const getSectionBenefitTable = (code) => {
  switch (code) {
    case VALUES.IHEALTHY_ULTRA_CODE.IHEALTHY_85:
    case VALUES.IHEALTHY_ULTRA_CODE.IHEALTHY_60:
      return sectionPLB
    case VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_25_15.JUNIOR:
    case VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_25_15.SENIOR:
    case VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_20_10.JUNIOR:
    case VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_20_10.SENIOR:
      return sectionLifeSavePro
    default:
      return sectionWholeLife
  }
}

const paddingTemplate = () => {
  return { text: '', margin: [0, 0, 0, 15] }
}

const benefitSummaryTableContents = (section, props) => {
  const code = _.get(props.basicPlan, 'code')
  if (VALUES.IHEALTHY_ULTRA_GROUP_BENEFIT_SUMMARY_TABLE.includes(code)) {
    return code === 'IHEALTHYULTRA-12PL' ? section.benefitSummary12PL(props) : section.investmentBenefitTable(props)
  } else if (VALUES.IHEALTHY_ULTRA_GROUP_COVERAGE_SUMMARY_TABLE.includes(code)) {
    return section.benefitSummary(props)
  } else {
    return ''
  }
}

export const createMiniBIDocDefinition = async (props: HealthProps) => {
  const code = _.get(props.basicPlan, 'code')
  const pdfTemplate = await template(props)
  const sectionPolicyValue = getSectionPolicyValueTable(code)
  const sectionBenefitTable = getSectionBenefitTable(code)
  return {
    content: [
      ...commonSection.profile({ ...props, basicPlanLabel }),
      ...commonSection.productBenefits({ ...props, basicPlanDetailsLabelFn: iHealthyBasicPlanDetailsLabelFn }),
      ...commonSection.riders(props),
      ...sectionPolicyValue.policyValueTable(props),
      code === VALUES.IHEALTHY_ULTRA_CODE['12PL'] ? sectionPolicyValue.secondPolicyValueTable(props) : '',
      paddingTemplate(),
      benefitSummaryTableContents(sectionBenefitTable, props),
      commonSection.legalDisclaimerMarketConduct(props),
    ],
    ...pdfTemplate,
  }
}

export const createFullBIDocDefinition = async (props: HealthProps) => {
  const code = _.get(props.basicPlan, 'code')
  const pdfTemplate = await template(props)
  const paymentChannels = await commonSection.paymentChannels(props)
  const sectionPolicyValue = getSectionPolicyValueTable(code)
  const sectionBenefitTable = getSectionBenefitTable(code)
  return {
    content: [
      ...commonSection.profile({ ...props, basicPlanLabel }),
      ...commonSection.productBenefits({ ...props, basicPlanDetailsLabelFn: iHealthyBasicPlanDetailsLabelFn }),
      ...commonSection.riders(props),
      commonSection.basicPlanCondition,
      ...sectionPolicyValue.policyValueTable(props),
      code === VALUES.IHEALTHY_ULTRA_CODE['12PL'] ? sectionPolicyValue.secondPolicyValueTable(props) : '',
      paddingTemplate(),
      benefitSummaryTableContents(sectionBenefitTable, props),
      commonSection.taxConsentDeductionBenefit(props),
      VALUES.IHEALTHY_ULTRA_GROUP_NEW_PAGE_FOR_LEGAL_PDF.includes(code)
        ? [{ text: '', pageBreak: 'before' }, commonSection.legalDisclaimerMarketConduct(props)]
        : commonSection.legalDisclaimerMarketConduct(props),
      paymentChannels,
      commonSection.signature(props),
    ],
    ...pdfTemplate,
  }
}

const createFullBIPdf = createPdfDocument(createFullBIDocDefinition)
const createMiniBIPdf = createPdfDocument(createMiniBIDocDefinition)

export const createiHealthyUltraPdf = async (props: HealthProps) => {
  return await createCommonPdf(props, createFullBIPdf, createMiniBIPdf)
}
