// @flow

import { getFlexiHealthBenefitData } from 'core/service/basic-plan/benefit'

import { underlineHtml } from 'quick-quote/util/html-render-utils'

const benefitData = getFlexiHealthBenefitData()

const ProductBenefitsContent = () => (
  <div id="product-benefits-content">
    <p id="death-benefits">
      <b className="caption">{benefitData.deathBenefits}</b>
    </p>
    <p className="description">
      <span dangerouslySetInnerHTML={{ __html: underlineHtml(benefitData.deathBenefitsDescription) }} />
    </p>
    <p id="maturity-benefits">
      <b className="caption">{benefitData.maturityBenefits}</b>
    </p>
    <p className="description">
      <span dangerouslySetInnerHTML={{ __html: underlineHtml(benefitData.maturityBenefitsDescription) }} />
    </p>
  </div>
)

export default ProductBenefitsContent
