import { createSectionCreator, createField } from '../utils'

const wasRejectedField = (app, id) => {
  return [
    createField(`${id}.wasRejectedMRTA.checked`, [
      {
        id: `${id}.wasRejectedMRTA.checked`,
        c: 'RadioButton',
        required: true,
        label:
          'ท่านเคยถูกปฏิเสธ เลื่อนการรับประกันภัย เพิ่มอัตราเบี้ยประกันภัย เปลี่ยนแปลงเงื่อนไข สำหรับการขอเอาประกันภัย หรือการขอกลับคืนสู่สถานะเดิม หรือการขอต่ออายุของกรมธรรม์ จากบริษัทนี้หรือบริษัทอื่นหรือไม่',
        p: {
          options: [
            {
              text: 'ไม่เคย',
              value: 'no',
            },
            {
              text: 'เคย',
              value: 'yes',
            },
          ],
        },
      },
    ]),
  ]
}

export default createSectionCreator(wasRejectedField)
