//@flow
import { buildColumn } from 'core/service/pdf-generation/utils/builder'
import { Header } from 'core/service/pdf-generation/products/common/components/general'

const LEFT_MARGIN = 10
const TOP_MARGIN = 10

const header = {
  marginBottom: 5,
  bold: true,
}

export const hbudrBenefitSection = (rider, riderBenefitData) => {
  const riderBenefit = riderBenefitData.riderBenefitData.benefits
  return [
    {
      text: riderBenefit.title,
      style: header,
      marginTop: TOP_MARGIN,
    },
    {
      marginLeft: LEFT_MARGIN,
      stack: riderBenefit.description.map((text) => ({ text: text })),
    },
    {
      marginLeft: LEFT_MARGIN * 2,
      columns: buildColumn([
        {
          label: '',
          value: `${riderBenefit.contents[0].text}`,
          width: '60%',
          bold: false,
        },
        {
          label: '',
          value: `${
            riderBenefit.contents[0].values.find((value) => value.planCode === rider.selectedPlan.planCode).value
          }`,
          width: '40%',
          bold: false,
        },
      ]),
    },
    {
      marginLeft: LEFT_MARGIN * 2,
      columns: buildColumn([
        {
          label: '',
          value: `${riderBenefit.contents[1].text}`,
          width: '60%',
          bold: false,
        },
        {
          label: '',
          value: `${
            riderBenefit.contents[1].values.find((value) => value.planCode === rider.selectedPlan.planCode).value
          }`,
          width: '40%',
          bold: false,
        },
      ]),
    },
    {
      marginLeft: LEFT_MARGIN * 2,
      columns: buildColumn([
        {
          label: '',
          value: `${riderBenefit.contents[2].text}`,
          width: '60%',
          bold: false,
        },
        {
          label: '',
          value: `${
            riderBenefit.contents[2].values.find((value) => value.planCode === rider.selectedPlan.planCode).value
          }`,
          width: '40%',
          bold: false,
        },
      ]),
    },
    {
      marginLeft: LEFT_MARGIN,
      stack: riderBenefit.remark.map((text) => ({ text: text })),
    },
  ]
}

export const hbudrNoticeSection = (riderBenefitData, title, benefitSummary) => {
  const riderNotice = riderBenefitData.riderBenefitData.notice
  const columns = [
    riderNotice.generic[1].list.slice(0, 4),
    riderNotice.generic[1].list.slice(4, riderNotice.generic[1].list.length),
  ]
  return [
    Header(`${title} (ต่อ)`),
    benefitSummary,
    {
      text: riderNotice.title,
      style: header,
      marginTop: TOP_MARGIN,
    },
    {
      marginLeft: LEFT_MARGIN,
      stack: [{ ul: [{ text: `${riderNotice.generic[0].text}` }] }],
    },
    {
      marginLeft: LEFT_MARGIN,
      stack: [{ ul: [{ text: `${riderNotice.generic[1].text}` }] }],
    },
    {
      marginLeft: LEFT_MARGIN * 2,
      columns: buildColumn(
        columns[0].map((item) => ({
          label: '',
          value: `- ${item.text}`,
          width: '25%',
          bold: false,
        }))
      ),
    },
    {
      marginLeft: LEFT_MARGIN * 2,
      columns: buildColumn(
        columns[1].map((item) => ({
          label: '',
          value: `- ${item.text}`,
          width: '25%',
          bold: false,
        }))
      ),
    },
    {
      marginLeft: LEFT_MARGIN,
      stack: [{ ul: [{ text: `${riderNotice.generic[2].text}` }] }],
    },
    {
      marginLeft: LEFT_MARGIN * 2,
      ol: riderNotice.generic[2].detail.map((text) => ({ text: text })),
    },
    {
      marginLeft: LEFT_MARGIN,
      stack: [{ ul: [{ text: `${riderNotice.generic[3].text}` }] }],
    },
  ]
}

export const hbudrDisclaimerSection = (riderBenefitData) => {
  const riderDisclaimer = riderBenefitData.riderBenefitData.disclaimer

  return [
    {
      text: riderDisclaimer.title,
      style: header,
      marginTop: TOP_MARGIN,
    },
    {
      text: riderDisclaimer.description[0],
      marginLeft: LEFT_MARGIN,
    },
    {
      ol: riderDisclaimer.list.map((text) => ({ text: text })),
    },
  ]
}
