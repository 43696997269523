//@flow
import { compose, withStateHandlers, withHandlers } from 'recompose'
import { ModalBody, Button } from 'reactstrap'
import { connect } from 'react-redux'
import SubHeading from 'e-submission/components/Heading/SubHeading'
import ExpandableDuplicateField from './ExpandableDuplicateField'
import ResendLinkSection from './ResendLinkSection'
import { InfoTooltip } from './InfoTooltip'
import { SpanTextSize } from 'common-components/text'

import { getCurrentApp } from '../../../apps/selectors'
import { getCitizenId } from 'e-submission/domain/data-model/form/selectors'
import { createCaseEkyc, createCaseEkycFromDevice, removeError, reuseCaseEkyc } from '../../../apps/actions'
import { getIcon } from 'e-submission/components/Icon'
import { Modal } from 'common-components/index'
import styled from 'styled-components'
import { deviceType } from '../../../domain/data-model/form/utils'

type _DuplicateEkycCaseModal = {
  isOpen: Boolean,
  onExit: Function,
  cases: any,
}

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Footer = styled.div`
  display: flex;
  padding-top: 1rem;
  justify-content: center;
`

const MarginRigthBtn = styled(Button)`
  margin-right: 1rem;
`

const SubmitButton = (props) => {
  const { onExit, handlerConfirm } = props

  return (
    <Footer>
      <MarginRigthBtn outline color="primary" onClick={() => onExit()}>
        ยกเลิก
      </MarginRigthBtn>
      <Button color="primary" onClick={() => handlerConfirm()}>
        ยืนยัน
      </Button>
    </Footer>
  )
}

export const DuplicateEkycCaseModal = ({
  isOpen,
  duplicateCase,
  seletedOption,
  setOption,
  handleOnExistDuplicateModal,
  handlerConfirm,
  isEnableEkycOnDevice,
}: _DuplicateEkycCaseModal) => {
  return (
    <Modal
      className={'duplicate-ekyc-modal'}
      isOpen={isOpen}
      size="md"
      modalClassName="default-bootstrap remote-selling-modal"
      toggle={() => {
        handleOnExistDuplicateModal()
      }}
    >
      <ModalBody className="form-group">
        <TitleSection>
          {getIcon('copy')}
          <SubHeading>แจ้งเตือนเคส eKYC</SubHeading>
          เราตรวจเจอเคสที่ซ้ำกันที่จำเป็นต้องชี้แจง
        </TitleSection>
        <ExpandableDuplicateField cases={duplicateCase} seletedOption={seletedOption} setOption={setOption} />
        <InfoTooltip>
          {deviceType() === 'mobile' || !isEnableEkycOnDevice ? null : (
            <div>
              <SpanTextSize>สร้างใหม่:</SpanTextSize> สร้างเป็นเคสใหม่และส่งลิงก์เพื่อยืนยันตัวตนใหม่
              <br />
              <SpanTextSize>ใช้ซ้ำ:</SpanTextSize> สร้างแถวใหม่ขึ้นมาในแดชบอร์ดแต่ใช้ผลลัพธ์ยืนยันตัวตนจากเคสก่อน
            </div>
          )}
          <SpanTextSize>*โปรดตรวจสอบวันหมดอายุของเอกสารทุกครั้ง</SpanTextSize>
        </InfoTooltip>
        {seletedOption === 'new-case' && !isEnableEkycOnDevice && <ResendLinkSection />}
        <SubmitButton onExit={handleOnExistDuplicateModal} handlerConfirm={handlerConfirm} />
      </ModalBody>
    </Modal>
  )
}

const mapStateToProps = (state, props) => {
  const app = getCurrentApp(state)

  const insuredId = getCitizenId('insured')(app)
  const payerId = getCitizenId('payer')(app)
  return {
    citizenId: { insured: insuredId, payer: payerId },
  }
}
const mapDispatchToProps = (dispatch, { insuredOrPayer, onExit }) => ({
  createCase: (citizenId) => dispatch(createCaseEkyc(insuredOrPayer, citizenId, true)),
  createCaseFromDevice: (citizenId, openRespondModalOnFailedCase) => {
    dispatch(createCaseEkycFromDevice(insuredOrPayer, citizenId, openRespondModalOnFailedCase, true))
  },
  reuseCaseEkyc: (proprietorId) => dispatch(reuseCaseEkyc(insuredOrPayer, proprietorId)),
  handleOnExistDuplicateModal: () => {
    onExit()
    dispatch(removeError('EKYC_DUPLICATED_CITIZENID'))
  },
})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStateHandlers(
    { seletedOption: 'new-case' },
    {
      setOption: (state) => (optionValue) => ({ seletedOption: optionValue }),
    }
  ),
  withHandlers({
    handlerConfirm: ({
      insuredOrPayer,
      duplicateCase,
      citizenId,
      seletedOption,
      handleOnExistDuplicateModal,
      createCase,
      reuseCaseEkyc,
      openIdentifyRespondingModal,
      closeIdentifyRespondingModal,
      isEnableEkycOnDevice,
      createCaseFromDevice,
    }) => () => {
      handleOnExistDuplicateModal()
      if (seletedOption === 'new-case') {
        if (isEnableEkycOnDevice) {
          function openRespondModalOnFailedCase() {
            openIdentifyRespondingModal()
          }
          createCaseFromDevice(citizenId[insuredOrPayer], openRespondModalOnFailedCase)
          return
        } else {
          createCase(citizenId[insuredOrPayer])
        }
      }
      if (seletedOption === 'reuse') {
        const reuseProprietorId = duplicateCase[0].id
        reuseCaseEkyc(reuseProprietorId)
      }
      openIdentifyRespondingModal()
    },
  })
)(DuplicateEkycCaseModal)
