// @flow
import type { Fund } from 'core/data-model/investment/types'
import type { InsuredProps } from 'core/service/pdf-generation/products/common/types'
import type { TableBody, TableRow } from 'core/service/pdf-generation/utils/table-utils'
import type { FundAllocationCategoryByInvestmentType } from 'core/service/pdf-generation/products/investment/sections/mutual-fund-portfolio'

import _ from 'lodash'

import MESSAGES from 'core/data-model/constants/bi-messages'
import { getInsuredFullName } from 'core/service/pdf-generation/utils/insured'
import { ColorfulHeader, WithPadding } from '../../../../utils/table-utils'
import { renderHeader } from '../../components/table-utils'
import VALUE from 'core/data-model/constants/values'
import { Bold } from 'core/service/pdf-generation/products/common/components/general'

const riskLevelEvaluationText = (riskLevelDigestable: boolean): string =>
  riskLevelDigestable ? MESSAGES.YES : MESSAGES.NO

export const displayFundProperties = (
  fund: Fund[],
  hasLstu: Boolean,
  allocationByInvestmentType: FundAllocationCategoryByInvestmentType,
  selectedFundItem: Fund,
  insuredRiskLevel: number
): TableRow => {
  const { rpp, lstu } = allocationByInvestmentType
  const rppFund = _.find(rpp, { code: selectedFundItem.code })
  const lstuFund = _.find(lstu, { code: selectedFundItem.code })

  const isSelected = rppFund || lstuFund
  const fundRiskLevel = isSelected ? `${selectedFundItem.riskLevel}` : ''
  const customerRiskLevel = isSelected ? `${insuredRiskLevel}` : ''
  const riskLevelDigestable = isSelected ? riskLevelEvaluationText(fundRiskLevel <= customerRiskLevel) : ''

  const lstuCol = hasLstu ? [{ alignment: 'center', text: lstuFund ? `${lstuFund.fundAllocation}%` : '0%' }] : []

  return [
    `${selectedFundItem.thaiName} [${selectedFundItem.code}]`,
    selectedFundItem.fundType.thaiName,
    {
      alignment: 'center',
      text: selectedFundItem.biFundHouse ? selectedFundItem.biFundHouse : selectedFundItem.fundHouse,
    },
    { alignment: 'center', text: rppFund ? `${rppFund.fundAllocation}%` : '0%' },
    ...lstuCol,
    { alignment: 'center', text: fundRiskLevel },
    { alignment: 'center', text: customerRiskLevel },
    { alignment: 'center', text: riskLevelDigestable },
  ]
}

export const fundRiskTableData = (
  funds: Fund[],
  hasLstu: Boolean,
  allocationByInvestmentType: FundAllocationCategoryByInvestmentType,
  insuredRiskLevel: number,
  isShowOnlySelectedFund: Boolean
): TableBody => {
  const { rpp, lstu } = allocationByInvestmentType
  const sortedFunds = _.orderBy(funds, ['fundType.category.categoryOrder', 'riskLevel', 'code'], ['asc', 'desc', 'asc'])
  const selectedRppFundCodes = rpp.map((item) => item.code)
  const selectedLstuFundCodes = hasLstu ? lstu.map((item) => item.code) : []
  const selectedFundCodes = [...selectedRppFundCodes, ...selectedLstuFundCodes]
  const selectedFunds = sortedFunds.filter((item) => selectedFundCodes.includes(item.code))
  const displayedFunds = isShowOnlySelectedFund ? selectedFunds : sortedFunds

  return displayedFunds.map((item) =>
    displayFundProperties(funds, hasLstu, allocationByInvestmentType, item, insuredRiskLevel)
  )
}

export const tableHeaderRows = (hasLstu: Boolean = false) => {
  const allocationColSpan = hasLstu ? 2 : 1
  const headerAllocationGroupCol = hasLstu ? [{}] : []
  const headerLstuCol = hasLstu
    ? [renderHeader(MESSAGES.FUND_RISK_TABLE_FUND_ALLOCATION_LSTU, { fillColor: VALUE.CYAN })]
    : []

  return [
    [
      renderHeader(MESSAGES.FUND_RISK_TABLE_ALLOCATIONS_HEADER, { fillColor: VALUE.EMERALD_GREEN, colSpan: 3 }),
      {},
      {},
      renderHeader(MESSAGES.FUND_RISK_TABLE_FUND_ALLOCATION, { fillColor: VALUE.CYAN, colSpan: allocationColSpan }),
      ...headerAllocationGroupCol,
      renderHeader(MESSAGES.FUND_RISK_TABLE_FUND_RISK, { fillColor: VALUE.EGG_WHITE, rowSpan: 2 }),
      renderHeader(MESSAGES.FUND_RISK_TABLE_INSURED_RISK, { fillColor: VALUE.EGG_WHITE, rowSpan: 2 }),
      renderHeader(MESSAGES.FUND_RISK_TABLE_RISK_LIMIT, { fillColor: VALUE.EGG_WHITE, rowSpan: 2 }),
    ],
    [
      renderHeader(MESSAGES.FUND_RISK_TABLE_FUND_NAME, { fillColor: VALUE.EMERALD_GREEN }),
      renderHeader(MESSAGES.FUND_RISK_TABLE_FUND_TYPE, { fillColor: VALUE.EMERALD_GREEN }),
      renderHeader(MESSAGES.FUND_RISK_TABLE_FUND_HOUSE, { fillColor: VALUE.EMERALD_GREEN }),
      renderHeader(MESSAGES.BASIC_PREMIUM_FOR_COVERAGE, { fillColor: VALUE.CYAN }),
      ...headerLstuCol,
      {},
      {},
      {},
    ],
  ]
}

const tableFooterRows = (hasLstu: Boolean = false) => {
  const fillColor = VALUE.PURPLE
  const emptyCell = { text: '', fillColor }
  const footerLstuCol = hasLstu ? [{ text: '100%', alignment: 'center', fillColor }] : []

  return [
    [
      { text: MESSAGES.FUND_RISK_TOTAL, colSpan: 3, fillColor },
      emptyCell,
      emptyCell,
      { text: '100%', alignment: 'center', fillColor },
      ...footerLstuCol,
      emptyCell,
      emptyCell,
      emptyCell,
    ],
  ]
}

const tableWidths = (hasLstu: Boolean = false) => {
  return hasLstu
    ? ['*', '25%', '5%', '7%', '7%', '6.5%', '6.5%', '7%']
    : ['*', '25%', '5%', '12%', '6.5%', '6.5%', '7%']
}

const vLineValues = (hasLstu: Boolean = false) => {
  return hasLstu ? [0, 3, 5] : [0, 3, 4]
}

const fundRiskTableComponent = (
  lumpSumTopUp: number,
  funds: Fund[],
  allocationByInvestmentType: FundAllocationCategoryByInvestmentType,
  insuredRiskLevel: number,
  isShowOnlySelectedFund: Boolean
) => {
  const hasLstu = lumpSumTopUp > 0
  const headerRows = tableHeaderRows(hasLstu)
  const footerRows = tableFooterRows(hasLstu)
  const vLineWidths = vLineValues(hasLstu)
  return {
    stack: [
      {
        table: {
          widths: tableWidths(hasLstu),
          body: headerRows
            .map((h) => _.flattenDeep(h))
            .concat(
              fundRiskTableData(funds, hasLstu, allocationByInvestmentType, insuredRiskLevel, isShowOnlySelectedFund)
            )
            .concat(footerRows),
        },
        layout: {
          vLineWidth: (i, node) => (vLineWidths.concat(node.table.widths.length).includes(i) ? 1 : 0),
          paddingLeft: () => 2,
          paddingRight: () => 2,
        },
        fontSize: 8.5,
      },
      hasLstu ? fundNoteComponent() : {},
    ],
  }
}

export const fundRiskSignTable = (insured: InsuredProps) => {
  const insuredFullName = getInsuredFullName(insured)
  return {
    table: {
      widths: ['15%', '45%'],
      body: [
        [{ text: Bold(MESSAGES.FUND_RISK_SIGNED_BY), alignment: 'center' }, { text: '' }],
        [{ text: '' }, { text: `(${insuredFullName})`, alignment: 'center', border: [false, true, false, false] }],
      ],
    },
    layout: {
      defaultBorder: false,
    },
    alignment: 'center',
    margin: [30, 0],
  }
}

const fundRiskSignComponent = (insured: InsuredProps) => [
  {
    text: Bold(WithPadding(MESSAGES.FUND_RISK_SIGN_MESSAGE)),
  },
  fundRiskSignTable(insured),
]

const fundNoteComponent = () => [
  {
    text:
      '* สัดส่วนการลงทุนของเบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษที่ชำระพร้อมเบี้ยประกันภัยหลักเพื่อความคุ้มครองงวดแรกเท่านั้น',
    fontSize: 8,
    margin: [0, 1, 0, 0],
  },
]

export const FundRiskTableSection = (
  lumpSumTopUp: number = 0,
  funds: Fund[],
  allocationByInvestmentType: FundAllocationCategoryByInvestmentType,
  insuredRiskLevel: number,
  insured: InsuredProps,
  isShowOnlySelectedFund: Boolean
): TableBody => {
  return ColorfulHeader(MESSAGES.FUND_RISK_TABLE_HEADER).concat([
    [fundRiskTableComponent(lumpSumTopUp, funds, allocationByInvestmentType, insuredRiskLevel, isShowOnlySelectedFund)],
    [fundRiskSignComponent(insured)],
  ])
}
