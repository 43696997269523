import { createField } from 'e-submission/domain/data-model/form/utils'
import { getDividendList } from 'e-submission/apps/selectors'

export default (app, id) => {
  return [
    createField('insured.dividend', [
      {
        label: 'วิธีขอรับเงินปันผล / เงินคืนตามสัญญา',
        id: 'insured.dividend',
        c: 'Dropdown',
        required: true,
        p: {
          mergeState: (state) => ({
            options: getDividendList(state),
          }),
        },
      },
    ]),
  ]
}
