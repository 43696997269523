import { compact } from 'lodash/fp'

import { createSectionCreator, createField } from '../utils'

const getQuestions = (app, id) => {
  return compact([
    createField(`${id}.fiveYearsHealthCheck.checked`, [
      {
        id: `${id}.fiveYearsHealthCheck.checked`,
        c: 'RadioButton',
        required: true,
        label: 'ข. ภายในระยะเวลา 5 ปีที่ผ่านมา ท่านเคยป่วยจนต้องเข้ารับการรักษาในโรงพยาบาลหรือไม่',
        p: {
          options: [
            {
              text: 'ไม่เคย',
              value: 'no',
            },
            {
              text: 'เคย',
              value: 'yes',
            },
          ],
        },
      },
    ]),
  ])
}

export default createSectionCreator(getQuestions)
