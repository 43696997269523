// @flow
import type { Rider, RiderState } from 'core/data-model/rider'

import RiderWithBenefitModal from './rider-benefit-modal'
import RiderBenefitSummary from './rider-benefit-summary'

type WPCRiderBenefitProps = {
  rider: Rider & RiderState,
}

const WPCRiderBenefit = ({ rider, riderBenefitDatas }: WPCRiderBenefitProps) => {
  const description = `ผลประโยชน์${rider.description}`
  const title = `${description} (${rider.name})`
  const benefitTable = (
    <div className="details-table">
      <p>
        การจ่ายผลประโยชน์ดังกล่าวจะเกิดขึ้นก็ต่อเมื่อวันที่เก็บตัวอย่างชิ้นเนื้อเพื่อนำไปตรวจทางพยาธิวิทยาได้พ้นระยะเวลารอคอย
        60 วัน
      </p>
    </div>
  )

  const benefitRider: Rider & RiderState = { ...rider, description }
  return (
    <RiderWithBenefitModal
      id="wpc-rider-benefit"
      className="rider wpc-rider"
      title={title}
      modalChildren={benefitTable}
    >
      <RiderBenefitSummary rider={benefitRider} />
      {benefitTable}
    </RiderWithBenefitModal>
  )
}

export default WPCRiderBenefit
