//@flow
import type { User } from 'core/data-model/identity'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'
import { isLoggedIn, getIdentityUser, getIdentityProfile } from 'identity/selectors'
import { loginRequest, logoutRequest } from 'quick-quote/actions'

import LoginDropdown from './login-dropdown'

type LoginLinkProps = {
  isLoggedIn: boolean,
  loginRequest: Function,
  icon: string,
  user: User,
  profile: Profile,
  logoutRequest: () => void,
}

export const _LoginLink = ({ isLoggedIn, loginRequest, icon, user, logoutRequest }: LoginLinkProps) => {
  if (isLoggedIn && user.type === 'Authenticated') {
    return <LoginDropdown icon={icon} user={user} logoutRequest={logoutRequest} />
  } else {
    return (
      <a onClick={(e) => loginRequest() && e.preventDefault()}>
        <span id={icon} className="global-nav-link"></span>
      </a>
    )
  }
}

const mapStateToProps = (state) => {
  const profile = getIdentityProfile(state)

  return {
    isLoggedIn: isLoggedIn(state),
    user: getIdentityUser(state),
    profile,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  loginRequest: bindActionCreators(loginRequest, dispatch),
  logoutRequest: bindActionCreators(logoutRequest, dispatch),
})

// export const LoginLink = connect(mapStateToProps, mapDispatchToProps)(_LoginLink)
export const LoginLink = compose(
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidMount() {},
    componentDidUpdate() {},
  })
)(_LoginLink)

export default LoginLink
