// @flow
import type { ValidatedValue } from 'core/service/investment'
import NumberFormat from 'react-number-format'
import { Input } from 'reactstrap'

type RegularPremiumProps = {
  id: string,
  premium: ValidatedValue,
  editPremiumAmount: (number) => void,
}

const PremiumInput = ({ id, editPremiumAmount, premium, disabled = false }: RegularPremiumProps) => {
  const isInvalidPremium = premium.errors.filter((error) => error.type === 'VALIDATION').length > 0
  return (
    <div className={isInvalidPremium ? 'has-danger' : ''}>
      <NumberFormat
        id={id}
        value={premium.value}
        onChange={(e, values) => editPremiumAmount(Number(values.value))}
        isAllowed={({ formattedValue }) => formattedValue.length <= 17}
        customInput={Input}
        thousandSeparator={true}
        decimalPrecision={0}
        allowNegative={false}
        disabled={disabled}
        onFocus={(e) => e.target.setSelectionRange(0, -1)}
        type="tel"
        pattern="[0-9]*"
      />
    </div>
  )
}

export default PremiumInput
