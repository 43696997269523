// @flow
import type { Questionnaire } from 'core/data-model/fna/types'
import MESSAGES from 'core/data-model/constants/messages'
import Reason from './reason'
import Questionnaires from './questionnaire'
import ThirdPerson from './third-person'
import { getToggles } from '../../../feature-toggles'

type Props = {
  insuranceQuestion: Questionnaire[],
  riskQuestion: Questionnaire[],
  productILP: boolean,
  isSelectedSavingAndInvestmentNeed: boolean,
  isSelectedThirdPartyJoin: boolean,
  setMarketConductQuestion: Function,
  isSelectedNeverSplittingPremiumFromExpense: boolean,
  setReasonForNeverSplittingPremiumFromExpense: Function,
  reasonForNeverSplittingPremiumFromExpense: string,
  isNonILPNeedWithInvestmentProduct: Boolean,
}

const MarketConducts = ({
  insuranceQuestion,
  riskQuestion,
  productILP,
  isSelectedSavingAndInvestmentNeed,
  isSelectedThirdPartyJoin,
  setMarketConductQuestion,
  isSelectedNeverSplittingPremiumFromExpense,
  setReasonForNeverSplittingPremiumFromExpense,
  reasonForNeverSplittingPremiumFromExpense,
  isNonILPNeedWithInvestmentProduct,
}: Props) => {
  const shouldShowRiskSection = () => {
    if (getToggles().ENABLE_FNA_V3) {
      if (getToggles().ENABLE_REVISE_FNA && isNonILPNeedWithInvestmentProduct) {
        return true
      }
      return isSelectedSavingAndInvestmentNeed
    } else {
      return productILP
    }
  }

  return (
    <div id="market-conducts" key="market-conducts">
      <p className="title">{MESSAGES.MARKET_CONDUCTS_TITLE}</p>
      <div className="quiz">
        {shouldShowRiskSection() ? (
          <div id="questionnaireILP">
            <div id="insurance" key="insurance">
              <Questionnaires
                questionnaires={insuranceQuestion.concat(riskQuestion)}
                setMarketConductQuestion={setMarketConductQuestion}
                isSelectedNeverSplittingPremiumFromExpense={isSelectedNeverSplittingPremiumFromExpense}
                setReasonForNeverSplittingPremiumFromExpense={setReasonForNeverSplittingPremiumFromExpense}
                reasonForNeverSplittingPremiumFromExpense={reasonForNeverSplittingPremiumFromExpense}
                isSelectedThirdPartyJoin={isSelectedThirdPartyJoin}
              />
            </div>
          </div>
        ) : (
          <div id="questionnaireNonILP" key="questionnaireNonILP">
            <Questionnaires
              questionnaires={insuranceQuestion}
              setMarketConductQuestion={setMarketConductQuestion}
              isSelectedNeverSplittingPremiumFromExpense={isSelectedNeverSplittingPremiumFromExpense}
              setReasonForNeverSplittingPremiumFromExpense={setReasonForNeverSplittingPremiumFromExpense}
              reasonForNeverSplittingPremiumFromExpense={reasonForNeverSplittingPremiumFromExpense}
            />
            {isSelectedThirdPartyJoin ? <ThirdPerson /> : null}
          </div>
        )}
        {getToggles().ENABLE_FNA_PHASE2_MARKET_CONDUCT ? null : <Reason />}
      </div>
    </div>
  )
}

export default MarketConducts
