//@flow
import _ from 'lodash'
import { buildHeaderTable } from 'core/service/pdf-generation/utils/builder'
import MESSAGES from 'core/data-model/constants/bi-messages'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { RiderBenefitData } from 'core/data-model/rider/benefits'

type IGROWBenefitProps = {
  rider: Rider & RiderState,
  riderBenefitData: RiderBenefitData,
}

type Benefit = {
  description: string,
  value: string,
}

export const buildSubBenefitContent = (subBenefits: Benefit[]) => {
  let result = []
  subBenefits.forEach((subBenefit) => {
    const value = { text: subBenefit.value, style: 'tableValue' }
    const description = subBenefit.description
    result.push([description, value])
  })
  return result
}

export const IGROWBenefitSection = ({ rider, riderBenefitData }: IGROWBenefitProps) => {
  const benefits = _.get(riderBenefitData, 'benefits')
  const planName = _.get(benefits[1], 'value')
  const headers = [MESSAGES.BENEFIT_LIST, `${MESSAGES.BENEFIT_TITLE_IGROW} (${planName})`]

  return [
    {
      table: {
        headerRows: 1,
        widths: ['65%', 'auto'],
        body: [buildHeaderTable(headers), ...benefits.map((benefit, index) => buildBenefitsRow(benefit, index))],
      },
      layout: 'lightBorder',
      style: 'table',
    },
  ]
}

const buildBenefitsRow = (benefit: Benefit, index: number) => {
  if (benefit.value === '') {
    return [{ colSpan: 2, text: benefit.description }, {}]
  } else if (benefit.value === '*') {
    return [{ margin: [15, 0, 0, 0], text: benefit.description }, '']
  }

  const subHeaderRows = [15, 16, 19, 20, 21, 22]
  const headerRows = [0, 8, 13, 24, 25, 26, 30]
  const textValueRows = [31, 32]

  if (_.includes(headerRows, index)) {
    return [[benefit.description], { alignment: 'center', text: benefit.value }]
  } else if (_.includes(subHeaderRows, index)) {
    return [
      { margin: [30, 0, 0, 0], text: benefit.description },
      { alignment: 'center', text: benefit.value },
    ]
  } else if (_.includes(textValueRows, index)) {
    return [{ margin: [15, 0, 0, 0], text: benefit.description }, { text: benefit.value }]
  }

  return [
    { margin: [15, 0, 0, 0], text: benefit.description },
    { alignment: 'center', text: benefit.value },
  ]
}
