import React from 'react'
import styled from 'styled-components'
import { compose, lifecycle, withStateHandlers } from 'recompose'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Box } from 'rebass'
import _ from 'lodash'
import { Button as _Button } from 'reactstrap' //Button

import Button from 'e-submission/components/Button'
import Content from 'e-submission/components/Layout/Content'
import FormSection from 'e-submission/components/Layout/FormSection'
import Card from 'e-submission/components/Card'
import { contentMaxWidth } from 'e-submission/components/mixins'
import CustomDialog from './dialog'
import { SplashScreen } from 'quick-quote/components'

import { getAppList, getProductsPayload } from 'e-submission/apps/selectors'

import { FirstNameRedux } from 'quick-quote/insured-information/redux/name'
import { LastNameRedux } from 'quick-quote/insured-information/redux/surname'
import { selectProduct, selectProductCategory } from 'quick-quote/product-selection/actions'
import { sfReference, optyEntering } from 'quick-quote/opty/actions'
import { startFNAFlow } from 'quick-quote/portal/actions'

import { getAppByOptyId, getOptyDetail, updateIProEnteringData } from 'e-submission/domain/service/opty'
import {
  getProductDetail,
  mappingProductIproWithAz,
  filterOptyStage,
  formatAndSortOptyByDate,
  handleGetOptyList,
  getQueryParam,
} from 'quick-quote/opty/utils'

import OptyDetail from 'quick-quote/opty/components/optyDetail'
import OptyDetailModal from 'quick-quote/opty/components/optyDetailModal'
import { getToggles } from 'quick-quote/feature-toggles'
import { loginRequest } from 'quick-quote/actions'
import { ROUTE_PATH, PATH } from 'quick-quote/constants/path'
import { getIsUserOnline } from 'quick-quote/selectors'
import { setNotificationMessage } from 'quick-quote/notification/actions'
/* istanbul ignore next */
const AppContent = Content.extend`
  ${({ theme }) => theme.media.xl`
    ${contentMaxWidth({ size: 'xl' })}
    .appWrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      ${AppSection} {
        flex: 0 0 32%;
        &:last-child {
          margin-right: 0;
        }
      }
      ${AppSection}:first-child {
        flex: 1 1 100%;
        > div {
          width: 32%;
        }
      }
    }
  `};
`

const AppSection = styled(Box)`
  padding-bottom: 40px;
`

const _Card = styled(Card)`
  &.opty-card-item {
    margin-top: 8px;
    display: block;
    padding: 24px 20px;
  }
`

const StyledFormSection = styled(FormSection)`
  &.form-section {
    min-width: 85vw;
    @media only screen and (min-width: 680px) {
      min-width: 650px;
    }
  }
`

const ListHeader = styled.div`
  &.list-header {
    display: flex;
    align-items: end;
    justify-content: space-between;
  }

  strong {
    font-size: 1.25rem;
    color: ${({ theme }) => theme.variables['$brand-primary']};
  }
`

const RefreshButton = styled(Button)`
  &.refresh-button {
    margin: 0;
    border-radius: 6px;
    background-color: #fff !important;
    color: ${({ theme }) => theme.variables['$brand-primary']} !important;
  }

  span {
    padding-left: 12px;
    font-weight: normal;
  }
`

const ButtonSection = styled.div`
  &.card-button-section {
    display: flex;
    justify-content: end;
    padding-top: 12px;
  }

  &.card-button {
    margin: 0;
  }
`

const mapStateToProps = (state, props) => {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  const user = {
    access_token: _.get(userInfo, ['access_token'], null),
    profile: {
      agent_code: _.get(userInfo, ['agent_code'], null),
    },
  }

  return {
    ...getAppList(state),
    user,
    products: getProductsPayload(state),
    isUserOnline: getIsUserOnline(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatchSfReference: bindActionCreators(sfReference, dispatch),
  dispatchOptyEntering: bindActionCreators(optyEntering, dispatch),
  dispatchSelectProduct: bindActionCreators(selectProduct, dispatch),
  dispatchSelectProductCategory: bindActionCreators(selectProductCategory, dispatch),
  dispatchFirstName: bindActionCreators(FirstNameRedux.actionCreators.editName, dispatch),
  dispatchLastName: bindActionCreators(LastNameRedux.actionCreators.editName, dispatch),
  loginRequest: bindActionCreators(loginRequest, dispatch),
  setNotification: bindActionCreators(setNotificationMessage, dispatch),
  startFNAFlow: bindActionCreators(startFNAFlow, dispatch),
})

export default compose(
  withStateHandlers(
    {
      isOpenNoDataModal: false,
      isOpenDiffAgentModal: false,
      isOpenErrorModal: false,
      isOpenDetailModal: false,
      selectedOpty: undefined,
      selectedOption: 'f2f',
      optyListData: [],
      isLoadingOptyList: false,
      // isLoadingOptyDetail: false,
    },
    {
      openNoDataModal: (state) => () => ({ isOpenNoDataModal: true }),
      closeNoDataModal: (state) => () => ({ isOpenNoDataModal: false }),
      openDiffAgentModal: (state) => () => ({ isOpenDiffAgentModal: true }),
      closeDiffAgentModal: (state) => () => ({ isOpenDiffAgentModal: false }),
      openErrorModal: (state) => () => ({ isOpenErrorModal: true }),
      closeErrorModal: (state) => () => ({ isOpenErrorModal: false }),
      openDetailModal: (state) => () => ({ isOpenDetailModal: true }),
      closeDetailModal: (state) => () => ({ isOpenDetailModal: false }),
      setSelectedOpty: (state) => (data) => ({ selectedOpty: data }),
      setSelectedOption: (state) => (option) => ({ selectedOption: option }),
      setOptyListData: (state) => (data) => ({ optyListData: data }),
      setIsLoadingOptyList: (state) => (isLoading) => ({ isLoadingOptyList: isLoading }),
      // setIsLoadingOptyDetail: (state) => (isLoading) => ({ isLoadingOptyDetail: isLoading }),
    }
  ),
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    async UNSAFE_componentWillMount() {
      const {
        setOptyListData,
        openErrorModal,
        openNoDataModal,
        setIsLoadingOptyList,
        setSelectedOpty,
        openDetailModal,
        history,
        loginRequest,
        openDiffAgentModal,
        user,
        isUserOnline,
        setNotification,
        startFNAFlow,
      } = this.props

      if (!isUserOnline) {
        history.push('../portal')
        setNotification({ type: 'Warning', subType: 'NoInternet' })
        return
      }

      const agent_code = _.get(JSON.parse(localStorage.getItem('userInfo')), ['agent_code'], null)
      if (!agent_code) {
        history.push('../portal')
        loginRequest()
      } else {
        setIsLoadingOptyList(true)
        const optyList = await handleGetOptyList(user)
        setIsLoadingOptyList(false)

        if (getToggles().ENABLE_REVISE_FNA) {
          startFNAFlow()
        }

        const filterredOptyList = filterOptyStage(optyList)
        const isDeeplink = localStorage.getItem('isDeeplink') === 'true'
        const search = _.get(history, ['location', 'search'], null)
        const deeplinkParam = isDeeplink
          ? JSON.parse(localStorage.getItem('deeplinkParam'))
          : getQueryParam(_.get(history, ['location', 'search'], ''))
        const deeplinkOptyId = _.get(deeplinkParam, ['opty'], null)
        const deeplinkAgentId = _.get(deeplinkParam, ['ag'], null)
        if (isDeeplink || search) {
          if (
            _.isEmpty(deeplinkOptyId) ||
            _.isEmpty(deeplinkAgentId) ||
            !(agent_code === deeplinkAgentId) ||
            !getToggles().ENABLE_OPTY_MENU
          ) {
            openDiffAgentModal()
            return
          } else {
            const { response } = await getAppByOptyId(user, deeplinkOptyId)

            if (response) {
              history.push({
                pathname: ROUTE_PATH.POLICY_LIST,
                search: `?ag=${deeplinkAgentId}&opty=${deeplinkOptyId}`,
              })
            }

            if (!_.isEmpty(deeplinkParam)) {
              const selectedOptyId = filterredOptyList.find((item) => {
                return item.Id === deeplinkOptyId
              })
              if (!_.isEmpty(selectedOptyId)) {
                const optyId = _.get(selectedOptyId, ['Id'])
                const respOptyDetail = await getOptyDetail(user, optyId).catch((e) => undefined)

                if (respOptyDetail === undefined) {
                  openErrorModal()
                } else {
                  const respOptyDetailRecords = _.get(respOptyDetail, 'response.records', [])
                  if (respOptyDetailRecords.length === 0) {
                    openNoDataModal()
                  } else {
                    const formattedOptyDetail = formatAndSortOptyByDate(respOptyDetailRecords)

                    setSelectedOpty(formattedOptyDetail[0])
                    openDetailModal()
                  }
                }
              } else {
                openNoDataModal()
              }
            }
          }
        }
        if (optyList === undefined) {
          openErrorModal()
        } else if (filterredOptyList.length === 0) {
          openNoDataModal()
        } else {
          setOptyListData(filterredOptyList)
        }
      }
    },
    componentWillUnmount() {
      const { setSelectedOpty, openNoDataModal, openErrorModal } = this.props
      setSelectedOpty([])
      openNoDataModal()
      openErrorModal()
    },
  })
)((props) => {
  const {
    isOpenDetailModal,
    isOpenErrorModal,
    isOpenNoDataModal,
    isOpenDiffAgentModal,
    openDetailModal,
    closeDetailModal,
    openNoDataModal,
    closeNoDataModal,
    openErrorModal,
    closeErrorModal,
    selectedOpty,
    setSelectedOpty,
    selectedOption,
    setSelectedOption,
    products,
    history,
    dispatchFirstName,
    dispatchLastName,
    dispatchSelectProduct,
    dispatchSelectProductCategory,
    dispatchSfReference,
    dispatchOptyEntering,
    user,
    optyListData,
    setOptyListData,
    isLoadingOptyList,
    setIsLoadingOptyList,
  } = props

  const setF2fDefaultNameInput = (name) => {
    const [firstName, lastName] = name.split(/ (.*)/, 2)
    // Ex. => name = "fName mName lName", result will be ["fNmae", "mName lName"]

    dispatchFirstName(firstName)
    dispatchLastName(lastName)
  }

  const setDefaultProductAndGetCategoryPath = (iproProductName) => {
    const mappedProduct = mappingProductIproWithAz(iproProductName)

    if (mappedProduct) {
      const { azCode, categoryId, categoryPath } = mappedProduct
      const detail = getProductDetail(azCode, products)

      dispatchSelectProduct(detail)
      dispatchSelectProductCategory(categoryId)

      return categoryPath
    }

    // need to default category at protection tab
    dispatchSelectProductCategory('CATEGORY_PROTECTION')
    return ''
  }

  const setSfReferenceFromSaleforce = (id, accId) => {
    const sfRefData = {
      startSaleFrom: 'SF',
      accId: accId,
      optyId: id,
    }
    dispatchSfReference(sfRefData)
  }

  return isLoadingOptyList ? (
    <SplashScreen loadingState={'ready'} updateApplicationRequest={() => {}} reloadApplicationRequest={() => {}} />
  ) : (
    <AppContent>
      <AppSection>
        {/* <OptyLoading isOpen={isLoadingOptyDetail} /> */}
        <CustomDialog
          isOpen={isOpenErrorModal}
          message={{
            title: 'ระบบไม่สามารถแสดงข้อมูลได้',
            body: 'ท่านสามารถกดปุ่มอัปเดตข้อมูล<br>เพื่อเรียกข้อมูลผู้มุ่งหวังได้ในภายหลัง',
          }}
          onConfirm={() => {
            closeErrorModal()
          }}
        />
        <CustomDialog
          noTitle
          isOpen={isOpenNoDataModal}
          message={{ body: 'ระบบไม่พบข้อมูลผู้มุ่งหวัง' }}
          onConfirm={() => {
            closeNoDataModal()
          }}
        />
        <CustomDialog
          isOpen={isOpenDiffAgentModal}
          message={{
            title: 'ข้อมูลการใช้งานระบบ AdvisorZone ไม่ถูกต้อง',
            body: 'กรุณาตรวจสอบชื่อผู้ใช้งานหรือข้อมูลผู้มุ่งหวังของท่าน ก่อนดำเนินการกรอกข้อมูลใบคำขอ',
          }}
          onConfirm={() => {
            history.push('../portal')
          }}
        />
        {!!selectedOpty && (
          <OptyDetailModal
            setSelectedOption={setSelectedOption}
            selectedOption={selectedOption}
            selectedOpty={selectedOpty}
            isOpen={isOpenDetailModal}
            onConfirm={async () => {
              const agentCode = _.get(user, 'profile.agent_code')
              const payload = {
                agentCode,
                accId: selectedOpty.AccountId,
                optyId: selectedOpty.Id,
                name: selectedOpty.Name,
              }
              updateIProEnteringData(user, payload).catch((e) => undefined)

              setSfReferenceFromSaleforce(selectedOpty.Id, selectedOpty.AccountId)
              dispatchOptyEntering(payload)

              if (selectedOption === 'f2f') {
                setF2fDefaultNameInput(selectedOpty.Name)
                const categoryPath = setDefaultProductAndGetCategoryPath(selectedOpty.Product_interest__c)

                if (getToggles().ENABLE_REVISE_FNA) {
                  history.replace(`/fna${categoryPath}${PATH.NEED_AND_GAP_ANALYSIS}`)
                } else {
                  history.replace(`/quick${categoryPath}/product-selection`)
                }
              }

              closeDetailModal()
            }}
            toggle={() => {
              closeDetailModal()
            }}
          />
        )}
        <StyledFormSection className="form-section">
          <ListHeader className="list-header">
            <strong>รายชื่อผู้มุ่งหวัง</strong>
            <RefreshButton
              icon="refresh"
              color="outline-primary"
              className="refresh-button"
              onClick={async () => {
                setIsLoadingOptyList(true)
                const optyList = await handleGetOptyList(user)
                setIsLoadingOptyList(false)
                const filterredOptyList = filterOptyStage(optyList)

                if (optyList === undefined) {
                  openErrorModal()
                } else if (filterredOptyList.length === 0) {
                  openNoDataModal()
                  setOptyListData([])
                } else {
                  setOptyListData(filterredOptyList)
                }
              }}
            >
              อัปเดตข้อมูล
            </RefreshButton>
          </ListHeader>
          {optyListData &&
            optyListData.map((item, index) => (
              <_Card key={index} className="opty-card-item">
                <OptyDetail
                  name={item.Name}
                  source={item.LeadSource}
                  product={item.Product_interest__c}
                  mobile={item.Mobile_of_Lead__c}
                  date={item.CreatedDate}
                />
                <ButtonSection className="card-button-section">
                  <_Button
                    color="primary"
                    outline={false}
                    className="card-button"
                    onClick={async () => {
                      const optyId = item.Id
                      // setIsLoadingOptyDetail(true)
                      const respOptyDetail = await getOptyDetail(user, optyId).catch((e) => undefined)
                      // setIsLoadingOptyDetail(false)
                      if (respOptyDetail === undefined) {
                        openErrorModal()
                      } else {
                        const respOptyDetailRecords = _.get(respOptyDetail, 'response.records', [])
                        if (respOptyDetailRecords.length === 0) {
                          openNoDataModal()
                        } else {
                          const formattedOptyDetail = formatAndSortOptyByDate(respOptyDetailRecords)

                          setSelectedOpty(formattedOptyDetail[0])
                          openDetailModal()
                        }
                      }
                    }}
                  >
                    เลือก
                  </_Button>
                </ButtonSection>
              </_Card>
            ))}
        </StyledFormSection>
      </AppSection>
    </AppContent>
  )
})
