import { compact } from 'lodash/fp'
import { createSectionCreator, createField } from 'e-submission/domain/data-model/form/utils'

// Remember to rename ID to same as PDF mapping stuff

const otherDetailFields = (id) => {
  return createField(
    `${id}.otherDetail`,
    [
      {
        id: `${id}.otherDetail`,
        label: 'อื่นๆ โปรดระบุ',
        c: 'TextInput',
      },
    ],
    {
      flex: [null, '0 1 50%'],
      order: 2,
      pr: [2, null, 0],
    }
  )
}

const occupationFields = (app, id, section) => {
  const fileldId = `${id}.occupation`
  return compact([
    createField(fileldId, [
      {
        id: fileldId,
        c: 'MultipleCheckbox',
        required: true,
        p: {
          options: [
            {
              text: 'พนักงานบริษัท',
              value: 'employee',
            },
            {
              text: 'นักการเมือง',
              value: 'politician',
            },
            {
              text: 'ค้าขาย',
              value: 'merchant',
            },
            {
              text: 'แพทย์หรือพยาบาล',
              value: 'healthcareProfessional',
            },
            {
              text: 'ข้าราชการ',
              value: 'bureaucrat',
            },
            {
              text: 'เจ้าของกิจการ',
              value: 'businessOwner',
            },
            {
              text: 'วิศวกรหรือสถาปนิก',
              value: 'engineerOrArchitect',
            },
            {
              text: 'พนักงานรัฐวิสาหกิจ',
              value: 'socialEnterpriseEmployee',
            },
            {
              text: 'วิชาชีพอิสระ',
              value: 'freelance',
            },
            {
              text: 'เกษียณอายุ',
              value: 'retiree',
            },
            {
              text: 'ไม่ได้ทำงานหรือไม่ได้ประกอบอาชีพ',
              value: 'unemployed',
            },
          ],
        },
      },
    ]),
    otherDetailFields(fileldId),
  ])
}

export const occupationSection = createSectionCreator(occupationFields)

const jobIndustryFields = (app, id, section) => {
  const fieldId = `${id}.jobIndustry`
  return compact([
    createField(fieldId, [
      {
        id: fieldId,
        c: 'MultipleCheckbox',
        required: true,
        p: {
          options: [
            {
              text: 'สํานักงานกฎหมายหรือบัญชี',
              value: 'lawOrAccount',
            },
            {
              text: 'ธุรกิจอาหารและเครื่องดื่ม',
              value: 'foodAndDrinks',
            },
            {
              text: 'ธุรกิจท่องเที่ยวและบริการ',
              value: 'travelAndHospitallity',
            },
            {
              text: 'ผลิตอาวุธยุทโธปกรณ์',
              value: 'defence',
            },
            {
              text: 'ธุรกิจประกัน',
              value: 'insurance',
            },
            {
              text: 'อสังหาริมทรัพย์',
              value: 'realEstate',
            },
            {
              text: 'สื่อสารและโทรคมนาคม',
              value: 'telecommunication',
            },
            {
              text: 'เครื่องประดับ อัญมนี วัตถุโบราณ',
              value: 'jewelleryAndAntiques',
            },
            {
              text: 'สถาบันการเงินหรือธนาคาร',
              value: 'financeOrBank',
            },
            {
              text: 'โฆษณา ศิลปะ บันเทิง',
              value: 'advertisementArtEntertainment',
            },
            {
              text: 'ธุรกิจคาสิโนหรือการพนัน',
              value: 'casinoOrGambling',
            },
          ],
        },
      },
    ]),
    otherDetailFields(fieldId),
  ])
}
export const jobIndustrySection = createSectionCreator(jobIndustryFields)

const incomeSourceFields = (app, id, section) => {
  const fieldId = `${id}.incomeSource`
  return compact([
    createField(fieldId, [
      {
        id: fieldId,
        c: 'MultipleCheckbox',
        required: true,
        p: {
          options: [
            {
              text: 'เงินเดือน, การดำเนินงานของกิจการ',
              value: 'salaryAndBusiness',
            },
            {
              text: 'ดอกเบี้ย, เงินปันผล',
              value: 'interestAndDividend',
            },
            {
              text: 'ค่าเช่า',
              value: 'rental',
            },
            {
              text: 'ค่าคอมมิชชั่น',
              value: 'commission',
            },
            {
              text: 'มรดก',
              value: 'inheritance',
            },
          ],
        },
      },
    ]),
    otherDetailFields(fieldId),
  ])
}
export const incomeSourceSection = createSectionCreator(incomeSourceFields)

const investReasonFields = (app, id, section) => {
  const fieldId = `${id}.investReason`
  return compact([
    createField(fieldId, [
      {
        id: fieldId,
        c: 'MultipleCheckbox',
        required: true,
        p: {
          options: [
            {
              text: 'เพื่อใช้จ่ายหลังเกษียณอายุ',
              value: 'retirement',
            },
            {
              text: 'เพื่อความมั่นคงของบุคคลในครอบครัว',
              value: 'security',
            },
            {
              text: 'เพื่อใช้เป็นทุนการศึกษาของตนเองหรือบุคคลในครอบครัว',
              value: 'education',
            },
            {
              text: 'เพื่อซื้อสินทรัพย์อื่น เช่น บ้าน รถ ที่ดิน',
              value: 'asset',
            },
          ],
        },
      },
    ]),
    otherDetailFields(fieldId),
  ])
}
export const investReasonSection = createSectionCreator(investReasonFields)

export const incomePerYearField = (app, id, section) => {
  const fieldId = `${id}.incomePerYear`
  return createField(fieldId, [
    {
      id: fieldId,
      label: 'รายได้ต่อปีรวมโบนัส',
      c: 'Radio',
      required: true,
      p: {
        options: [
          {
            text: 'น้อยกว่า 1 ล้านบาท',
            value: 'lessThan1Million',
          },
          {
            text: '1-5 ล้านบาท',
            value: '1To5Million',
          },
          {
            text: '5-10 ล้านบาท',
            value: '5To10Million',
          },
          {
            text: '10 ล้านบาท ขึ้นไป',
            value: 'moreThan10Million',
          },
        ],
      },
    },
  ])
}
