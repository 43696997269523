import { compose } from 'recompose'
import { CheckedOrNotCard, PlaceHolderCard as _PlaceHolderCard } from 'e-submission/components/Card/DocumentCard'

const Component = ({ icon, action, documents, hidden }) => {
  if (hidden) return null
  return (
    <div>
      {documents.map((props, index) => (
        <CheckedOrNotCard id={props.id} key={`DocumentCard${index}`} {...props} />
      ))}
    </div>
  )
}

export default (hoc) => compose(hoc)(Component)

export const PlaceHolderCard = (hoc) => hoc(_PlaceHolderCard)
