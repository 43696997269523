// @flow
import _ from 'lodash'
import { d } from 'core/service/lib/decimal'
import type { Checker, Checked } from 'core/service/lib/check'
import { createChecker } from 'core/service/lib/check'
import type { BasicPlan, ModelFactor } from 'core/data-model/basic-plan'
import { dataWrapper } from 'core/data'
import type { DisplayProductQuery } from 'core/service'
import { getBasicPlan } from 'core/service'
import type { PaymentRange, MinMaxRange, SumAssuredPremium } from './rules'
import {
  validateMinTopUpValueRule,
  validateMinPremiumValueRule,
  validateMaxRegularTopUpRule,
  validateSumAssuredRule,
} from './rules'
import type { PremiumAmount } from 'core/data-model/investment'
import MESSAGES from 'core/data-model/constants/validation-messages'
import CONSTANT_MESSAGES from 'core/data-model/constants/messages'
import Mustache from 'mustache'
import { formatNumber, roundDown } from 'core/service/lib/number-format'
import type { Age } from 'core/data-model/insured'
import { ageInYears } from '../../lib/age-format'
import VALUE from 'core/data-model/constants/values'
import { STANDARD_OCCUPATION } from 'core/service/insured'

const regularPremiumChecker: Checker<?PaymentRange, number> = createChecker([
  [
    'greaterThanMinimum',
    {
      type: 'VALIDATION',
      rule: validateMinPremiumValueRule,
      message: (paymentRange) => {
        const msg = _.get(paymentRange, ['messages'])
        return typeof msg === 'string' ? msg : _.get(msg, 'min', '')
      },
    },
  ],
])

const validateMinPremium = (paymentRange: ?PaymentRange, premiumAmount: PremiumAmount) =>
  validateMinTopUpValueRule(paymentRange, premiumAmount.regularTopUp)

const topUpChecker: Checker<?PaymentRange, PremiumAmount> = createChecker([
  [
    'greaterThanMinimum',
    {
      type: 'VALIDATION',
      rule: validateMinPremium,
      message: (paymentRange: ?PaymentRange): string => {
        let paymentFrequency: string = _.get(paymentRange, 'paymentFrequency', '')
        let paymentFrequencyInThai = _.get(CONSTANT_MESSAGES, _.snakeCase(paymentFrequency).toUpperCase())
        return Mustache.render(MESSAGES.RULE_IWEALTHY_MIN_TOPUP_PREMIUM, {
          paymentFrequency: paymentFrequencyInThai,
          minRegularTopUp: formatNumber(_.get(paymentRange, 'min', 0)),
        })
      },
    },
  ],
  [
    'lessThanMaximum',
    {
      type: 'VALIDATION',
      rule: validateMaxRegularTopUpRule,
      message: MESSAGES.RULE_IWEALTHY_MAX_TOPUP_PREMIUM,
    },
  ],
])

export const validateRegularPremium = async (
  displayProductQuery: DisplayProductQuery,
  modelFactorId: string,
  regularPremium: number,
  insuredAge: Object
): Promise<Checked<number>> => {
  let paymentFrequency = await getPaymentFrequency(dataWrapper.getModelFactorById, modelFactorId)
  let paymentRange: ?PaymentRange = await getRegularPaymentRange(
    getBasicPlan,
    displayProductQuery,
    paymentFrequency,
    'regularPremium',
    insuredAge.value
  )
  return _validateRegularPremium(paymentRange, regularPremium)
}

export const _validateRegularPremium = async (
  paymentRange: ?PaymentRange,
  regularPremium: number
): Promise<Checked<number>> => {
  return regularPremiumChecker(paymentRange, regularPremium)
}

export const validateRegularTopUp = async (
  displayProductQuery: DisplayProductQuery,
  modelFactorId: string,
  premiumAmount: PremiumAmount
): Promise<Checked<PremiumAmount>> => {
  let paymentFrequency = await getPaymentFrequency(dataWrapper.getModelFactorById, modelFactorId)
  let paymentRange = await getRegularPaymentRange(getBasicPlan, displayProductQuery, paymentFrequency, 'regularTopUp')
  let paymentRangeWithFrequency = { ...paymentRange, paymentFrequency }
  return _validateRegularTopUp(paymentRangeWithFrequency, premiumAmount)
}

export const _validateRegularTopUp = async (
  paymentRange: ?PaymentRange,
  premiumAmount: PremiumAmount
): Promise<Checked<PremiumAmount>> => {
  return topUpChecker(paymentRange, premiumAmount)
}

export const getRegularPaymentRange = async (
  getBasicPlan: (DisplayProductQuery) => Promise<BasicPlan>,
  displayProductQuery: DisplayProductQuery,
  paymentFrequency: string,
  premiumType: string,
  insuredAge?: number
): Promise<?PaymentRange> => {
  const basicPlan: BasicPlan = await getBasicPlan(displayProductQuery)
  const paymentRange: * = _.get(basicPlan, ['paymentRanges', premiumType, _.camelCase(paymentFrequency)], { min: 0 })
  return _.isArray(paymentRange)
    ? _.find(
        paymentRange,
        (paymentrange) =>
          insuredAge >= _.get(paymentrange, ['age', 'min'], 0) && insuredAge <= _.get(paymentrange, ['age', 'max'], 0)
      )
    : paymentRange
}

export const getPaymentFrequency = async (
  getModelFactorById: (string) => Promise<ModelFactor>,
  modelFactorId: string
): Promise<string> => {
  const modelFactor: ModelFactor = await getModelFactorById(modelFactorId)
  return modelFactor.paymentFrequency
}

const sumAssuredMessage = (sumAssuredrange, sumAssuredPremium) => {
  let messageKey =
    sumAssuredrange.min === sumAssuredrange.max
      ? 'RULE_IWEALTHY_EXPECTED_SUM_ASSURED'
      : 'RULE_IWEALTHY_SUM_ASSURED_LIMIT'

  let annualRegularPremium = sumAssuredPremium.annualRegularPremium
  return Mustache.render(MESSAGES[messageKey], {
    minSumAssured: formatNumber(annualRegularPremium * sumAssuredrange.min),
    maxSumAssured: formatNumber(annualRegularPremium * sumAssuredrange.max),
  })
}

const sumAssuredChecker: Checker<MinMaxRange, SumAssuredPremium> = createChecker([
  [
    'withinRangeValidation',
    {
      type: 'VALIDATION',
      rule: validateSumAssuredRule,
      message: sumAssuredMessage,
    },
  ],
  [
    'withinRangeInformation',
    {
      type: 'ADDITIONAL_INFORMATION',
      rule: _.negate(validateSumAssuredRule),
      message: sumAssuredMessage,
    },
  ],
])

type SumAssuredPayload = {
  sumAssured: number,
  regularPremium: number,
  insuredAge: Age,
  insuredGender: string,
  occupationFactor: number,
}

export const validateSumAssured = async (
  displayProductQuery: DisplayProductQuery,
  modelFactorId: string,
  { sumAssured, regularPremium, insuredAge, insuredGender, occupationFactor }: SumAssuredPayload
): Promise<Checked<SumAssuredPremium>> => {
  return _validateSumAssured(
    getBasicPlan,
    dataWrapper.getModelFactorById,
    displayProductQuery,
    modelFactorId,
    sumAssured,
    regularPremium,
    insuredAge,
    insuredGender,
    occupationFactor
  )
}

export const _getForSumassuredWithOccupationFactor = (
  occupationFactor: number = 0,
  productCode: string,
  { min, max }: RegularPremiumMultiplier
) => {
  if (occupationFactor === STANDARD_OCCUPATION || productCode !== VALUE.RPUDR) {
    return { min, max }
  }
  return _calculateSumassuredWithOccupationFactor(occupationFactor, { min, max })
}

export const _calculateSumassuredWithOccupationFactor = (
  occupationFactor: number,
  { min, max }: RegularPremiumMultiplier
) => {
  const tempLoading = d(occupationFactor)
  const convertedMin = d(min)
  const convertedMax = d(max)
  const minResult = roundDown(convertedMin / (1 + convertedMin * (tempLoading / 1000) * 1.15), 0)
  const maxResult = roundDown(convertedMax / (1 + convertedMax * (tempLoading / 1000) * 1.15), 0)
  return {
    min: minResult,
    max: maxResult,
  }
}

export const _validateSumAssured = async (
  getBasicPlan: (DisplayProductQuery) => Promise<BasicPlan>,
  getModelFactorById: (string) => Promise<ModelFactor>,
  displayProductQuery: DisplayProductQuery,
  modelFactorId: string,
  sumAssured: number,
  regularPremium: number,
  insuredAge: Age,
  insuredGender: string,
  occupationFactor: number
): Promise<Checked<SumAssuredPremium>> => {
  const basicPlan: BasicPlan = await getBasicPlan(displayProductQuery)
  let modelFactor: ModelFactor = await getModelFactorById(modelFactorId)
  const productCode = basicPlan.productCode
  let sumAssuredLimits = _.get(basicPlan, ['paymentRanges', 'assured'])
  let insuredAgeInYears = ageInYears(insuredAge)

  const insuredSumAssuredLimit = _getInsuredSumAssuredLimit(sumAssuredLimits, insuredAgeInYears)
  const regularPremiumMultiplier = _getRegularPremiumMultiplier(
    insuredSumAssuredLimit,
    basicPlan.productCode,
    insuredGender
  )

  const regularPremiumMultiplierAfterCalculated = _getForSumassuredWithOccupationFactor(
    occupationFactor,
    basicPlan.productCode,
    {
      min: regularPremiumMultiplier.min,
      max: regularPremiumMultiplier.max,
    }
  )

  let annualRegularPremium = regularPremium * modelFactor.periods

  return sumAssuredChecker(regularPremiumMultiplierAfterCalculated, { annualRegularPremium, sumAssured, productCode })
}

type AgeLimit = {
  min: string,
  max: string,
}

export type SumAssuredLimit = {
  age: AgeLimit,
  regularPremiumMultiplier: RegularPremiumMultiplier,
}

type RegularPremiumMultiplier = {
  M?: Object,
  F?: Object,
  max?: string,
  min?: string,
}

export type InsuredSumAssuredLimit = {
  regularPremiumMultiplier: RegularPremiumMultiplier,
}

export const _getRegularPremiumMultiplier = (
  insuredSumAssuredLimit: InsuredSumAssuredLimit,
  code: string,
  gender: string
) => {
  if (code === VALUE.RPUDR) {
    return _getRegularPremiumMultiplierRPUDR(insuredSumAssuredLimit, gender)
  } else {
    return _getRegularPremiumMultiplierRPUL(insuredSumAssuredLimit)
  }
}

export const _getRegularPremiumMultiplierRPUDR = (insuredSumAssuredLimit: InsuredSumAssuredLimit, gender: string) => {
  return !!insuredSumAssuredLimit
    ? _.get(insuredSumAssuredLimit, ['regularPremiumMultiplier', gender], { min: 0, max: 0 })
    : { min: 0, max: 0 }
}

export const _getRegularPremiumMultiplierRPUL = (insuredSumAssuredLimit: InsuredSumAssuredLimit) => {
  return !!insuredSumAssuredLimit
    ? _.get(insuredSumAssuredLimit, ['regularPremiumMultiplier'], { min: 0, max: 0 })
    : { min: 0, max: 0 }
}

export const _getInsuredSumAssuredLimit = (sumAssuredLimits: SumAssuredLimit[], insuredAgeInYears: number) => {
  return _.find(sumAssuredLimits, ({ age }) => insuredAgeInYears >= age.min && insuredAgeInYears <= age.max)
}

export const getMaximumSumAssured = async (
  displayProductQuery: DisplayProductQuery,
  modelFactorId: string,
  { regularPremium, insuredAge, insuredGender, occupationFactor }: SumAssuredPayload
): Promise<Checked<SumAssuredPremium>> => {
  return _getMaximumSumAssured(
    getBasicPlan,
    dataWrapper.getModelFactorById,
    displayProductQuery,
    modelFactorId,
    regularPremium,
    insuredAge,
    insuredGender,
    occupationFactor
  )
}

export const _getMaximumSumAssured = async (
  getBasicPlan: (DisplayProductQuery) => Promise<BasicPlan>,
  getModelFactorById: (string) => Promise<ModelFactor>,
  displayProductQuery: DisplayProductQuery,
  modelFactorId: string,
  regularPremium: number,
  insuredAge: Age,
  insuredGender: string,
  occupationFactor: number
): number => {
  const basicPlan: BasicPlan = await getBasicPlan(displayProductQuery)
  const modelFactor: ModelFactor = await getModelFactorById(modelFactorId)
  const sumAssuredLimits = _.get(basicPlan, ['paymentRanges', 'assured'])
  const insuredAgeInYears = ageInYears(insuredAge)

  const insuredSumAssuredLimit = _getInsuredSumAssuredLimit(sumAssuredLimits, insuredAgeInYears)
  const regularPremiumMultiplier = _getRegularPremiumMultiplier(
    insuredSumAssuredLimit,
    basicPlan.productCode,
    insuredGender
  )

  const regularPremiumMultiplierAfterCalculated = _getForSumassuredWithOccupationFactor(
    occupationFactor,
    basicPlan.productCode,
    {
      min: regularPremiumMultiplier.min,
      max: regularPremiumMultiplier.max,
    }
  )

  const annualRegularPremium = d(regularPremium).times(d(modelFactor.periods))
  const maxSumAssured = d(annualRegularPremium).times(d(regularPremiumMultiplierAfterCalculated.max))

  return maxSumAssured.toNumber()
}

export const getRegularPremiumMaxMultiplier = async (
  displayProductQuery: DisplayProductQuery,
  { insuredAge, insuredGender }: SumAssuredPayload
): Promise<Checked<SumAssuredPremium>> => {
  return _getRegularPremiumMaxMultiplier(getBasicPlan, displayProductQuery, insuredAge, insuredGender)
}

export const _getRegularPremiumMaxMultiplier = async (
  getBasicPlan: (DisplayProductQuery) => Promise<BasicPlan>,
  displayProductQuery: DisplayProductQuery,
  insuredAge: Age,
  insuredGender: string
): number => {
  const basicPlan: BasicPlan = await getBasicPlan(displayProductQuery)
  const sumAssuredLimits = _.get(basicPlan, ['paymentRanges', 'assured'])
  const insuredAgeInYears = ageInYears(insuredAge)
  const insuredSumAssuredLimit = _getInsuredSumAssuredLimit(sumAssuredLimits, insuredAgeInYears)

  const regularPremiumMultiplier = _getRegularPremiumMultiplier(
    insuredSumAssuredLimit,
    basicPlan.productCode,
    insuredGender
  )

  return regularPremiumMultiplier.max
}
