// @flow
import { Button } from 'reactstrap'
import MESSAGES from 'quick-quote/constants/messages'
import type { AppStatus } from 'quick-quote/reducers'

type SplashScreenProps = {
  loadingState: AppStatus,
  reloadApplicationRequest: () => void,
  updateApplicationRequest: () => void,
}

export const SplashScreen = ({
  loadingState,
  reloadApplicationRequest,
  updateApplicationRequest,
}: SplashScreenProps) => (
  <div id="main-wrapper" data-reactroot="">
    {[
      'noInternet',
      'updateApp',
      'updateDatabase',
      'serverIsDown',
      'failedSecurityPolicy',
      'loginConnectivityError',
    ].includes(loadingState) ? (
      <LoadingFailed retry={reloadApplicationRequest} update={updateApplicationRequest} loadingState={loadingState} />
    ) : (
      <Loading message={getLoadingMessageByLoadingState(loadingState)} />
    )}
  </div>
)

type LoadingProps = {
  message: string,
}
export const Loading = ({ message }: LoadingProps) => (
  <div id="loader">
    <h6 className="status">{message}</h6>
    <div id="spinner" />
  </div>
)

type LoadingFailedProps = {
  retry: () => void,
  update: () => void,
  loadingState: AppStatus,
}

const newLineStringWithPTag = (string: string) => {
  return string.split('\n').map((item, i) => {
    return <p key={`string${i}`}>{item}</p>
  })
}

const getInfoMessageByLoadingState = (loadingState: AppStatus) => {
  switch (loadingState) {
    case 'updateApp':
    case 'updateDatabase':
      return 'มีเวอร์ชันใหม่'
    case 'noInternet':
      return 'ไม่สามารถเชื่อมต่อ\nระบบ กรุงไทย-แอกซ่า ได้\nกรุณาตรวจสอบการเชื่อมต่ออินเตอร์เน็ต \nรอสักครู่แล้วลองใหม่อีกครั้ง'
    case 'failedSecurityPolicy':
      return 'ไม่สามารถให้บริการท่านได้\nเนื่องจากเครื่องท่านไม่อยู่ในเงื่อนไขความปลอดภัย'
    case 'serverIsDown':
    default:
      return 'เกิดข้อผิดพลาด\nไม่สามารถให้บริการได้ในขณะนี้'
  }
}

const getLoadingMessageByLoadingState = (loadingState: AppStatus) => {
  switch (loadingState) {
    case 'generatingPDF':
      return MESSAGES.GENERATING_PDF
    case 'validatingSalePermission':
      return MESSAGES.VALIDATING_SALE_PERMISSION
    default:
      return ''
  }
}

const getButtonMessageByLoadingState = (loadingState: AppStatus) => {
  switch (loadingState) {
    case 'updateApp':
    case 'updateDatabase':
      return 'อัพเดทที่นี่'
    case 'noInternet':
    case 'serverIsDown':
    default:
      return 'ลองใหม่อีกครั้ง'
  }
}

export const LoadingFailed = ({ retry, update, loadingState }: LoadingFailedProps) => {
  if (loadingState === 'failedSecurityPolicy') {
    return (
      <div id="loader">
        <h6 className="status">{newLineStringWithPTag(getInfoMessageByLoadingState(loadingState))}</h6>
      </div>
    )
  } else {
    return (
      <div id="loader">
        <h6 className="status">{newLineStringWithPTag(getInfoMessageByLoadingState(loadingState))}</h6>
        <Button color="primary" onClick={loadingState === 'updateApp' ? update : retry} type="button">
          {getButtonMessageByLoadingState(loadingState)}
        </Button>
      </div>
    )
  }
}
