import { compose } from 'recompose'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { isAppUpdating, isFetchingAppUpdate, isInstallAppUpdate } from 'core/selectors'

export const AppUpdating = styled((props) => {
  const { className, showLoading, fetching, installing } = props
  if (!(showLoading || fetching || installing)) return null
  return (
    <div className={['az-app-updating', className].join(' ')}>
      <div className="az-app-updating__container">
        <div className="az-app-updating__status">
          {showLoading && <div id="spinner" />}
          {fetching && <h5>กำลังตรวจสอบอัพเดท</h5>}
          {installing && <h5>กำลังติดตั้ง</h5>}
        </div>
      </div>
    </div>
  )
})`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 1060;

  .az-app-updating__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 15px;
    transition: background ease-in-out 0.8s;
    background: rgba(255, 255, 255, 0.75);
  }

  .az-app-updating__status {
    display: flex;
    max-width: 300px;
    p {
      margin-bottom: 0.5rem;
    }
    .btn-retry {
      margin-left: 5px;
    }
  }

  #spinner {
    display: inline-block;
    margin-right: 0.5rem;
    flex: 1;
    width: 1rem;
    height: 1rem;
  }
`

export default compose(
  connect(
    (state) => ({
      showLoading: isAppUpdating(state),
      fetching: isFetchingAppUpdate(state),
      installing: isInstallAppUpdate(state),
    }),
    (dispatch) => ({})
  )
)(AppUpdating)
