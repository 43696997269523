//@flow
import { acceptFundAllocation } from 'quick-quote/product-investment/fund-allocation/lstu/actions'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import { bindActionCreators } from 'redux'

type AcceptFundAllocationBtnProps = {
  disabled: boolean,
  acceptFundAllocation: Function,
  message: string,
}

export const AcceptFundAllocationButton = (props: AcceptFundAllocationBtnProps) => {
  const localClick = () => {
    if (!props.disabled) {
      props.acceptFundAllocation()
    }
  }
  return (
    <Button color={props.disabled ? 'disabled' : 'primary'} onClick={localClick}>
      {props.message}
    </Button>
  )
}

export const AcceptLstuFundAllocationButton = connect(null, (dispatch) => ({
  acceptFundAllocation: bindActionCreators(acceptFundAllocation, dispatch),
}))(AcceptFundAllocationButton)
