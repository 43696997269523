import { compact, map, get } from 'lodash/fp'
import { getAppConfig } from '../../../deploy-env/app-config'

const dbName = 'application'
const encryptionKey = '1234'
const imageWidth = 1024
const imageHeight = 768

const getUserId = get('profile.sub')
const getToken = (user) => {
  if (user && user.access_token) {
    return user.access_token
  }
  throw 'Please specify user access token'
}
const getOwner = (user) => {
  return 'keycloak_' + user.profile.sub
}

const invoke = (service, params) => {
  return new Promise((resolve, reject) => {
    const args = [
      params,
      (result) => {
        resolve(result)
      },
      (err) => {
        reject(err)
      },
    ]
    service(...compact(args))
  })
}

export default {
  subscribeReplicationStatus(resolve, reject) {
    CB.listeningReplicationStatus(resolve, reject)
  },

  callOmise(cardInformation) {
    const params = {
      ...cardInformation,
      publicKey: getAppConfig().OMISE_PUBLIC_KEY,
    }

    return invoke(CB.createOmiseToken, params).then((data) => {
      return data
    })
  },

  openCamera(application, imageName) {
    const params = {
      _id: application._id,
      width: imageWidth,
      height: imageHeight,
      imageName: imageName,
    }
    return invoke(CB.openCamera, params)
  },

  deleteAttachment(application, imageName) {
    const params = {
      _id: application._id,
      imageName: imageName,
    }
    return invoke(CB.deleteAttachment, params)
  },

  setAttachment(application, fileName, dataUrl, user, inInsured = false) {
    const contentType = dataUrl.substring('data:'.length, dataUrl.indexOf(';base64'))
    const data = dataUrl.substring(dataUrl.indexOf(',') + 1)

    const params = {
      _id: application._id,
      contentType: contentType,
      data: data,
      fileName: fileName,
      inInsured,
    }
    return invoke(CB.setAttachmentFile, params).then((data) => ({ data }))
  },

  getAttachment(application, imageName) {
    const params = {
      _id: application._id,
      imageName: imageName,
      isBinary: true,
    }
    return invoke(CB.getAttachment, params).then((data) => ({ data }))
  },

  getSummaries() {
    return invoke(CB.getModelList)
      .then((rows) => ({
        rows: map((value) => ({ value }), rows),
      }))
      .then((data) => ({ data }))
  },

  getAll() {
    return invoke(CB.getModelList)
      .then((rows) => ({
        rows: map((doc) => ({ doc }), rows),
      }))
      .then((data) => ({ data }))
  },

  getRev(application) {
    return Promise.resolve({
      rows: [{ value: { rev: undefined } }],
    }).then((data) => ({ data }))
  },
}
