// @flow
import type { Option } from 'common-components/dropdown'
import type { AppState } from 'quick-quote/reducers'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Dropdown } from 'common-components'
import { FormGroup } from 'reactstrap'
import { getSelectedPlan } from 'quick-quote/product-common/coverage-plan/selectors'
import { getHealthToppingOptionsPlans } from 'quick-quote/product-health-topping/coverage-plan/selectors'

import { selectHealthToppingPlan } from '../../actions'

export type BasicPlanSelectionProps = {
  healthToppingOptionsPlans: Option[],
  selectedPlan: string,
  selectHealthToppingPlan: (planCode: string) => void,
}

const BasicPlanSelection = ({
  healthToppingOptionsPlans,
  selectedPlan,
  selectHealthToppingPlan,
}: BasicPlanSelectionProps) => {
  return (
    <FormGroup className={'basic-plan-selection item basic'}>
      <div className="form-group-title">
        <span className="form-group-title-text">สัญญาหลัก</span>
      </div>
      <div className="options option-labels">
        <label>เลือกแผน</label>
      </div>
      <Dropdown
        className="basic-plan form-control"
        options={healthToppingOptionsPlans}
        onChange={(planCode) => {
          selectHealthToppingPlan(planCode)
        }}
        value={selectedPlan}
      />
    </FormGroup>
  )
}

const mapStateToProps = (state: AppState) => ({
  healthToppingOptionsPlans: getHealthToppingOptionsPlans(state),
  selectedPlan: getSelectedPlan(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  selectHealthToppingPlan: bindActionCreators(selectHealthToppingPlan, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(BasicPlanSelection)
