import _ from 'lodash'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { compose, branch, renderComponent, lifecycle } from 'recompose'
import { FormGroup } from 'reactstrap'
import { Checkbox, WordWraps } from 'common-components'
import styled from 'styled-components'

import { formatNumber } from 'core/service/lib/number-format'
import { calculatePremiumOptionPlanPA } from 'core/service/rider/premium-rate'

import { getSelectedDisplayProduct } from 'quick-quote/product-selection/selectors'
import { getAge } from 'quick-quote/insured-information/selectors'
import { editRiderSelectedPlan, updateSelectedPlan } from 'quick-quote/product-common/coverage-plan/actions'
import {
  getRiderOccupationFactorForPARider,
  getOptionPlanPARider,
  getAvaliableOptionPlanPARider,
  getPARiderSelectedPlan,
} from 'quick-quote/product-pa/coverage-plan/selectors'
import { updateSumAssuredAfterSelectedPlanPA } from 'quick-quote/product-pa/actions'

const packageDetailGroupPAComponent = (packagePA) => {
  return packagePA.map((p) => (
    <div>
      <tr key={`packageDetail-row-${p.code}`} id={`packageDetail-row-${p.code}`}>
        <td key={`packageDetail-label-${p.code}`} id={`packageDetail-label-${p.code}`} width="500px">
          <WordWraps>{p.text}</WordWraps>
        </td>
        <td className="pa-value" key={`packageDetail-value-${p.code}`} id={`packageDetail-value-${p.code}`}>
          {formatNumber(p.value)}
        </td>
        <td className="pa-value">&nbsp;บาท</td>
      </tr>
    </div>
  ))
}

const PARider = ({
  className,
  rider,
  displayProduct,
  riderOccupationFactor,
  optionPlanPARider,
  avaliableOptionPlanPARider,
  insuredAge,
  selectedPAPackage,
  ...props
}) => {
  const riderOptionAvaliablePlanPA = Promise.resolve(
    calculatePremiumOptionPlanPA(rider, riderOccupationFactor, avaliableOptionPlanPARider, insuredAge)
  )
  riderOptionAvaliablePlanPA.then(function(result) {
    const premiumOptionPlanPA = result.map((r) => {
      return {
        planCode: r.code,
        premium: r.premiumRate,
      }
    })
    _.merge(avaliableOptionPlanPARider, premiumOptionPlanPA)
  })

  const PAPremium = styled.span`
    font-weight: bold;
  `

  return avaliableOptionPlanPARider.map((planPA) => (
    <FormGroup className={`${className}`} {...props}>
      <div className="pa-group">
        <div className="pa-label">
          <Checkbox
            id={planPA.planCode}
            checked={planPA.planCode === rider.selectedPlan.planCode}
            onChange={() => selectedPAPackage(planPA.planCode, planPA.package)}
          >
            <span className="custom-control-description">{planPA.name}</span>
          </Checkbox>
        </div>
        <div className="options">
          <div className="pa-premium-value right">
            <p>
              เบี้ยประกันของคุณ <PAPremium>{formatNumber(planPA.premium, 2)}</PAPremium> บาท/ ปี
            </p>
          </div>
        </div>
      </div>

      {packageDetailGroupPAComponent(planPA.package)}
    </FormGroup>
  ))
}

const mapStateToProps = (state) => ({
  displayProduct: getSelectedDisplayProduct(state),
  riderOccupationFactor: getRiderOccupationFactorForPARider(state),
  optionPlanPARider: getOptionPlanPARider(state),
  avaliableOptionPlanPARider: getAvaliableOptionPlanPARider(state),
  insuredAge: getAge(state),
  selectedPlanPA: getPARiderSelectedPlan(state),
})

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    { editRiderSelectedPlan, updateSelectedPlan, updateSumAssuredAfterSelectedPlanPA },
    (stateProps, dispatchProps, ownProps) => {
      const {
        displayProduct,
        riderOccupationFactor,
        optionPlanPARider,
        avaliableOptionPlanPARider,
        insuredAge,
        selectedPlanPA,
      } = stateProps
      const { rider } = ownProps
      const { editRiderSelectedPlan, updateSelectedPlan, updateSumAssuredAfterSelectedPlanPA } = dispatchProps
      return {
        ...ownProps,
        displayProduct,
        riderOccupationFactor,
        optionPlanPARider,
        avaliableOptionPlanPARider,
        insuredAge,
        selectedPlanPA,
        selectedPAPackage: (riderPackageCode, riderPackageDetail) => {
          if (displayProduct.basicPlanCodeOption) {
            const basicOption = _.find(displayProduct.basicPlanCodeOption, { riderCode: riderPackageCode })
            if (basicOption) {
              updateSelectedPlan(basicOption.code)
              updateSumAssuredAfterSelectedPlanPA(basicOption.sumAssuredMinimum)
            } else {
              updateSelectedPlan('')
            }
          }

          const payloadSelectedPlanPA = {
            planCode: riderPackageCode,
            packageDetail: riderPackageDetail,
          }
          editRiderSelectedPlan(rider.code, payloadSelectedPlanPA)
        },
      }
    }
  ),
  lifecycle({
    UNSAFE_componentWillMount() {
      const { avaliableOptionPlanPARider, selectedPAPackage, selectedPlanPA } = this.props
      const planCodeSelectedPlanPA = _.get(selectedPlanPA, 'planCode', '')
      const planGroupPA = _.find(avaliableOptionPlanPARider, { planCode: planCodeSelectedPlanPA })
      if (planGroupPA) {
        selectedPAPackage(planGroupPA.planCode, planGroupPA.package)
      } else {
        selectedPAPackage(avaliableOptionPlanPARider[0].planCode, avaliableOptionPlanPARider[0].package)
      }
    },
  })
)(PARider)
