//@flow
import { Section, Columns, Bold, Shrinked } from 'core/service/pdf-generation/products/common/components/general'
import { PolicySummary } from 'core/service/pdf-generation/products/investment/components'

import BIMESSAGES from 'core/data-model/constants/bi-messages'
import Mustache from 'mustache'
import VALUE from 'core/data-model/constants/values'

import {
  iInvestCashFlowTable,
  iWealthyCashFlowTable,
} from 'core/service/pdf-generation/products/investment/components/cash-flow'
import {
  cashFlowTable as rpudrCashFlowTable,
  findEndOfInvestmentPeriod as rpudrFindEndOfInvestmentPeriod,
} from '../products/rpudr/cash-flow'
import type { CashFlow } from 'core/service/investment/cash-flow/cash-flow'
import type { InsuredProps } from 'core/service/pdf-generation/products/common/types'

type BenefitTableExampleProps = {
  policyType: string,
  sumAssured: number,
  calculatedCoveragePeriod: number,
  calculatedPaymentPeriod: number,
  insured: InsuredProps,
  regularPremium: number,
  regularTopupPremium: number,
  coverageDeath: number,
  paymentModeLabel: string,
  cashFlows: CashFlow[],
}

const cashFlowTable = (props, expectedReturn, tableSection = 1) => {
  switch (props.productCode) {
    case BIMESSAGES.BI_TYPE_PRODUCT_CODE_IINVEST:
      return [iInvestCashFlowTable(expectedReturn, props.cashFlows, props.productCode), iInvestDisclaimer()]
    case BIMESSAGES.BI_TYPE_PRODUCT_CODE_IWEALTHY:
      return [iWealthyCashFlowTable(expectedReturn, props.cashFlows, props.productCode), iWealthyDisclaimer()]
    case BIMESSAGES.BI_TYPE_PRODUCT_CODE_RPUDR:
      return [
        rpudrCashFlowTable(expectedReturn, props.cashFlows, props.displayProduct, tableSection),
        rpudrDisclaimer(props),
      ]
    default:
      return []
  }
}

export const BenefitTableExample = (props: BenefitTableExampleProps, caseNumber: number, expectedReturn: number) => {
  return Section(Mustache.render(BIMESSAGES.EXAMPLE_BENEFIT_TABLE_HEADER, { number: caseNumber }), [
    Shrinked(PolicySummary(props.productCode, props, 0.3)),
    ExampleExplanation(caseNumber, expectedReturn, props.productCode),

    ...cashFlowTable(props, expectedReturn),
  ])
}

/**
 * The benefit table that separate details to 2 pages
 */
export const BenefitTableExampleExtendPage = (
  props: BenefitTableExampleProps,
  caseNumber: number,
  expectedReturnPercentage: number,
  inflationPercentage: number
) => {
  const sections = []
  for (let sectionIndex = 0; sectionIndex < 2; sectionIndex++) {
    sections.push(
      Section(Mustache.render(BIMESSAGES.EXAMPLE_BENEFIT_TABLE_HEADER, { number: caseNumber }), [
        // $FlowFixMe
        Shrinked(PolicySummary(props.productCode, props, 0.5)),
        ExampleExplanationWithInflationRate(
          caseNumber,
          expectedReturnPercentage,
          inflationPercentage,
          props.productCode
        ),

        ...cashFlowTable(props, expectedReturnPercentage, sectionIndex),
      ])
    )
  }
  return sections
}

const exampleBenefitExplanation = (productCode) => {
  switch (productCode) {
    case BIMESSAGES.BI_TYPE_PRODUCT_CODE_IINVEST:
      return BIMESSAGES.IINVEST_EXAMPLE_BENEFIT_EXPLANATION_NOTE
    case BIMESSAGES.BI_TYPE_PRODUCT_CODE_IWEALTHY:
      return BIMESSAGES.IWEALTHY_EXAMPLE_BENEFIT_EXPLANATION_NOTE
    case BIMESSAGES.BI_TYPE_PRODUCT_CODE_RPUDR:
      return BIMESSAGES.ILINK_EXAMPLE_BENEFIT_EXPLANATION_NOTE
    default:
      return ''
  }
}

export const ExampleExplanation = (caseNumber: number, percentNumber: number, productCode: string) => {
  const header = Mustache.render(BIMESSAGES.EXAMPLE_BENEFIT_EXPLANATION_HEADER, { caseNumber, percentNumber })
  const benefitExplanation = exampleBenefitExplanation(productCode)
  return {
    stack: [
      { text: header, color: VALUE.MEDIUM_BLUE, fontSize: 13, bold: true },
      { text: BIMESSAGES.EXAMPLE_BENEFIT_EXPLANATION, fontSize: 9, lineHeight: 0.78, marginTop: -4 },
      Columns([
        { ...Bold(BIMESSAGES.EXAMPLE_BENEFIT_EXPLANATION_NOTE_HEADER), width: 45, fontSize: 9 },
        { text: benefitExplanation, width: '*', fontSize: 9 },
      ]),
    ],
    marginTop: 2,
  }
}

export const ExampleExplanationWithInflationRate = (
  caseNumber: number,
  expectedReturnPercentage: number,
  inflationPercentage: number,
  productCode: string
) => {
  const header = Mustache.render(BIMESSAGES.EXAMPLE_BENEFIT_EXPLANATION_HEADER_WITH_INFLATION, {
    caseNumber,
    expectedReturnPercentage: expectedReturnPercentage,
    inflationPercentage: inflationPercentage,
  })
  const benefitExplanation = exampleBenefitExplanation(productCode)
  const fontStyles = { fontSize: 9, lineHeight: 0.9 }
  return {
    stack: [
      { text: header, color: VALUE.MEDIUM_BLUE, fontSize: 13, bold: true },
      { text: BIMESSAGES.EXAMPLE_BENEFIT_EXPLANATION, ...fontStyles, marginBottom: 4 },
      Columns([
        { ...Bold(BIMESSAGES.EXAMPLE_BENEFIT_EXPLANATION_NOTE_HEADER), width: 45, ...fontStyles },
        { text: benefitExplanation, width: '*', ...fontStyles },
      ]),
    ],
    marginTop: 4,
  }
}

export const iWealthyDisclaimer = () => ({
  marginTop: 3,
  stack: [
    Columns([
      { text: BIMESSAGES.EXAMPLE_BENEFIT_NOTE, width: 32, fontSize: 8.5, marginLeft: 1 },
      { text: BIMESSAGES.IWEALTHY_EXAMPLE_BENEFIT_EXPLANATION_DISCLAIMER, width: 'auto', fontSize: 8.5 },
    ]),
  ],
})

export const iInvestDisclaimer = () => ({
  marginTop: 3,
  stack: [
    Columns([
      { text: BIMESSAGES.EXAMPLE_BENEFIT_NOTE, width: 32, fontSize: 8.5, marginLeft: 1 },
      { text: BIMESSAGES.IINVEST_EXAMPLE_BENEFIT_EXPLANATION_DISCLAIMER, width: 'auto', fontSize: 8.5 },
    ]),
  ],
})

export const rpudrDisclaimer = (props: BenefitTableExampleProps) => {
  const { cashFlows } = props

  const lastPolicyPeriod = rpudrFindEndOfInvestmentPeriod(cashFlows)[1]
  const { year, month, age } = lastPolicyPeriod

  const cashflowDisclaimer = Mustache.render(BIMESSAGES.RPUDR_EXAMPLE_BENEFIT_EXPLANATION_DISCLAIMER, {
    year,
    month,
    age,
  })

  return {
    marginTop: 3,
    stack: [
      Columns([
        { text: '', width: 30 },
        { text: cashflowDisclaimer, width: 'auto', fontSize: 8.5, lineHeight: 0.9 },
      ]),
    ],
  }
}
