// @flow
import _ from 'lodash'
import PolicyValueRow, { dashedOutOrWithPrecision } from './policy-value-row'
import MESSAGES from 'core/data-model/constants/bi-messages'
import CONSTANTS from 'core/data-model/constants/messages'
import type { PolicyValueTableProps, PolicyValue } from 'core/service/benefit-illustration/policy-value'
import type { DisplayProduct } from 'core/service/display-product'

type FlexiHealthPolicyValueTableProps = {
  policyValue: PolicyValue,
  basicPlan: DisplayProduct,
  title: string,
}

const PolicyValueTable = (props: FlexiHealthPolicyValueTableProps) => {
  return (
    <div className="policy group" id="policy-value">
      <h3>{props.title}</h3>
      {renderPolicyValueTableForWholeLife(props)}
    </div>
  )
}

const PolicyTable = ({ className, children }: { className?: string, children?: React.Node }) => (
  <table className={`policy-table${className ? ` ${className}` : ''}`} id="policy-value-table">
    {children}
  </table>
)

// $$FlowFixMe
export const PolicyWithoutCashDrop = ({
  yearEndPolicyValues,
  lastYearEndPolicy,
  subtotalAnnualBasicPremium,
  subtotalAnnualRiderPremium,
  grandTotalPremium,
}) => (
  <PolicyTable>
    <thead>
      <tr>
        <td width="5%" rowSpan={3}>
          {CONSTANTS.AGE}
        </td>
        <td className="nowrap" width="5%" rowSpan={3}>
          {MESSAGES.END_OF_THE_YEAR_AT}
        </td>
        <td width="15%" colSpan={1}>
          {MESSAGES.PREMIUM_PER_YEAR}
        </td>
        <td width="15%" colSpan={3}>
          {MESSAGES.END_OF_YEAR_POLICY}
        </td>
      </tr>
      <tr>
        <td width="15%">{MESSAGES.BASIC_PLAN}</td>
        <td width="19%">{MESSAGES.CUMULATIVE_PREMIUM}</td>
        <td width="19%">{MESSAGES.POLICY_VALUE_TABLE_CASH_SURRENDER_VALUE}</td>
        <td width="19%">{MESSAGES.POLICY_VALUE_TABLE_DEATH_BENEFIT}</td>
      </tr>
    </thead>
    <tbody>
      {yearEndPolicyValues.map((policyValue) => (
        <PolicyValueRow
          key={`policy-value-row-${policyValue.age.value}`}
          age={policyValue.age}
          yearEndPolicy={policyValue.yearEndPolicy}
          cumulativeBasicPremium={policyValue.cumulativeBasicPremium}
          annualBasicPremium={policyValue.annualBasicPremium}
          annualRiderPremium={policyValue.annualRiderPremium}
          annualTotalPremium={policyValue.annualTotalPremium}
          surrenderCash={policyValue.surrenderCash}
          deathBenefit={policyValue.deathBenefit}
          basicSumAssured={policyValue.basicSumAssured}
        />
      ))}
      <tr className="total" id={`policy-value-row-${lastYearEndPolicy}`} key={`policy-value-row-${lastYearEndPolicy}`}>
        <td colSpan={2} className={'td-total'}>
          {MESSAGES.TOTAL}
        </td>
        <td>{dashedOutOrWithPrecision(subtotalAnnualBasicPremium, 2)}</td>
        <td>{dashedOutOrWithPrecision(grandTotalPremium, 2)}</td>
        <td />
        <td />
      </tr>
    </tbody>
  </PolicyTable>
)

const PolicyValueContent = ({ basicPlan, ...props }) => <PolicyWithoutCashDrop {...props} />

const renderPolicyValueTableForWholeLife = ({ policyValue, basicPlan }: PolicyValueTableProps) => {
  const lastYearEndPolicy = _.get(_.last(policyValue.yearEndPolicyValues), 'yearEndPolicy', 0) + 1
  return (
    <div>
      <div className="table-wrap">
        <PolicyValueContent lastYearEndPolicy={lastYearEndPolicy} basicPlan={basicPlan} {...policyValue} />
      </div>

      <div className="disclaimer">
        <p>{MESSAGES.NOTE}:</p>
        <ul>
          <li>{MESSAGES.FLEXI_HEALTH_POLICY_VALUE_TABLE_NOTE_1}</li>
          <li>{MESSAGES.FLEXI_HEALTH_POLICY_VALUE_TABLE_NOTE_2}</li>
        </ul>
      </div>
    </div>
  )
}

export default PolicyValueTable
