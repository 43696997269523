//@flow
// $FlowFixMe
import noInternetImage from '!base64-image-loader!assets/images/form/no-internet.png'
import ErrorImage from 'assets/images/form/error.png'
import { Modal } from 'common-components'
import ThemeProvider from 'e-submission/components/ThemeProvider'
import { withModalToggle } from 'lib/with-hoc'
import MESSAGES from 'quick-quote/constants/messages'
import type {
  ErrorMessageNotification,
  GenericErrorNotification,
  InformationNotification,
  Notification,
  PasswordInformationNotification,
  RAPasswordInformationNotification,
  RPQPasswordInformationNotification,
  WarningNotification,
} from 'quick-quote/notification/actions'

import { shareRA } from 'quick-quote/product-common/benefit-illustration/actions'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { connect } from 'react-redux'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { bindActionCreators } from 'redux'
import { shareQuickQuote, shareRPQ } from '../product-common/benefit-illustration/actions'
import ConfirmToFNA from './confirm-to-fna-modal'
import ConfirmToHome from './confirm-to-home-modal'
import CordovaUpdateFailedModal from './cordova-update-failed-modal'
import ErrorIncompleteFNA from './error-incomplete-fna-modal'
import PDFPassWordProtected from './pdf-password-protected'
import RemoteSellingPopup from './remote-selling-modal'
import RemoteSellingVcPopup from './remote-selling-vc-modal'
import CheckVersionNativePopup from './checkversion-modal'
import LoginLimitSessionExceededPopup from './login-session-limit-exceeded-modal'
import AuthenticationError from './authentication-error-modal'
import { ErrorMessageArea } from 'e-submission/containers/Dialog/ErrorMessageArea'
import { getToggles } from 'quick-quote/feature-toggles'
import ConfirmEkycModal from './confirm-ekyc-modal'
import ConfirmModal from './confirm-message-modal'
import ConfirmIproFNAModal from './confirm-ipro-fna-modal'
import NetworkModal from './network-modal'
import ConfirmSubmitModal from './confirm-submit-modal'

type GlobalPopupProps = {
  onExit: Function,
  notificationMessage: Notification,
}

export const GlobalPopupPrivate = ({ onExit, notificationMessage }: GlobalPopupProps) => {
  switch (notificationMessage.type) {
    case 'Warning':
      if (notificationMessage.subType === 'NoInternet')
        return <NoInternetPopup onExit={onExit} notificationMessage={notificationMessage} />
      else return null
    case 'Information':
      return <SmallPopup onExit={onExit} notificationMessage={notificationMessage} />
    case 'PasswordInformation':
      return <PdfPasswordPopup onExit={onExit} notificationMessage={notificationMessage} />
    case 'RPQPasswordInformation':
      return <RPQPasswordPopup onExit={onExit} notificationMessage={notificationMessage} />
    case 'RAPasswordInformation':
      return <RAPasswordPopup onExit={onExit} notificationMessage={notificationMessage} />
    case 'GenericError':
      return <GenericError onExit={onExit} notificationMessage={notificationMessage} />
    case 'ErrorMessage':
      return <ErrorMessage onExit={onExit} notificationMessage={notificationMessage} />
    case 'ErrorIncompleteFNA':
      return <ErrorIncompleteFNA onExit={onExit} notificationMessage={notificationMessage} />
    case 'ConfirmToHome':
      return <ConfirmToHome onExit={onExit} notificationMessage={notificationMessage} />
    case 'ConfirmToFNA':
      return <ConfirmToFNA onExit={onExit} notificationMessage={notificationMessage} />
    case 'RemoteSelling':
      return (
        <RemoteSellingPopup
          data-testid="remote-selling-popup"
          onExit={onExit}
          notificationMessage={notificationMessage}
        />
      )
    case 'RemoteSellingVc':
      return <RemoteSellingVcPopup onExit={onExit} notificationMessage={notificationMessage} />
    case 'LoginLimitSessionExceeded':
      return (
        <LoginLimitSessionExceededPopup
          data-testid="login-limit-session-exceeded-popup"
          onExit={onExit}
          notificationMessage={notificationMessage}
        />
      )
    case 'CheckVersionNative':
      return <CheckVersionNativePopup onExit={onExit} notificationMessage={notificationMessage} />
    case 'CordovaUpdateFailed':
      return (
        <CordovaUpdateFailedModal
          data-testid="cordova-update-failed-modal"
          onExit={onExit}
          notificationMessage={notificationMessage}
        />
      )
    case 'ConfirmEKYC':
      return <ConfirmEkycModal onExit={onExit} notificationMessage={notificationMessage} />
    case 'ConfirmModal':
      return <ConfirmModal onExit={onExit} notificationMessage={notificationMessage} />
    case 'ConfirmIproFNA':
      return <ConfirmIproFNAModal onExit={onExit} notificationMessage={notificationMessage} />
    case 'NetworkModal':
      return <NetworkModal onExit={onExit} notificationMessage={notificationMessage} />
    case 'ConfirmSubmit':
      return <ConfirmSubmitModal onExit={onExit} notificationMessage={notificationMessage} />
    case 'AuthenticationError':
      return (
        <ThemeProvider>
          <AuthenticationError
            data-testid="authentication-error"
            onExit={onExit}
            notificationMessage={notificationMessage}
          />
        </ThemeProvider>
      )
    case 'None':
    default:
      return null
  }
}

export const GlobalPopup = withModalToggle(GlobalPopupPrivate)

type ErrorMessageProps = {
  notificationMessage: ErrorMessageNotification,
  onExit: Function,
}
const ErrorMessage = ({ onExit, notificationMessage }: ErrorMessageProps) => (
  <Modal
    isOpen={notificationMessage.type !== 'None'}
    modalClassName="full-screen-modal error-message-modal"
    toggle={onExit}
  >
    <ModalHeader toggle={onExit}>{notificationMessage.title}</ModalHeader>
    <ModalBody>
      <div className="description">{notificationMessage.message}</div>
      <Button color="primary" onClick={onExit}>
        {MESSAGES.OK}
      </Button>
    </ModalBody>
  </Modal>
)

type GenericErrorProps = {
  onExit: Function,
  notificationMessage: GenericErrorNotification,
}
const GenericError = ({ notificationMessage, onExit }: GenericErrorProps) => (
  <FullScreenPopup onExit={onExit} title="กรุณารอสักครู่แล้วลองอีกครั้ง" isOpen={notificationMessage.type !== 'None'}>
    <img src={ErrorImage} alt="error-image" />
    <div className="description">
      <div>
        {getToggles().ENABLE_RED_TRUCK_ERROR_MSG ? null : notificationMessage.message || 'ไม่สามารถสร้างเอกสารได้'}
      </div>
      <div className="highlight">กรุณารอสักครู่แล้วลองใหม่อีกครั้ง</div>
    </div>
    <Button color="primary" onClick={onExit}>
      ลองอีกครั้ง
    </Button>
    {getToggles().ENABLE_RED_TRUCK_ERROR_MSG ? <ErrorMessageArea errorMsg={notificationMessage.message} /> : null}
  </FullScreenPopup>
)

export type FullScreenPopupProps = {
  onExit: Function,
  title: string,
  isOpen: boolean,
  children: React$Element<*>[],
  className?: String,
}
export const FullScreenPopup = ({ onExit, title, isOpen, children, className }: FullScreenPopupProps) => (
  <Modal isOpen={isOpen} backdrop={true} modalClassName={`full-screen-modal ${className}`} toggle={onExit}>
    <ModalHeader toggle={onExit}>{title}</ModalHeader>
    <ModalBody>{children}</ModalBody>
  </Modal>
)

type NoInterPopupProps = {
  onExit: Function,
  notificationMessage: WarningNotification,
}

const NoInternetPopup = ({ onExit, notificationMessage }: NoInterPopupProps) => (
  <FullScreenPopup onExit={onExit} title="กรุณาต่อ Internet" isOpen={notificationMessage.type !== 'None'}>
    <img src={noInternetImage} alt="no-internet" />
    <div className="description">
      <div>ไม่สามารถเชื่อมต่อระบบ กรุงไทย-แอกซ่า ได้</div>
      <div className="highlight">กรุณาตรวจสอบการเชื่อมต่ออินเตอร์เน็ต </div>
    </div>
    <Button color="primary" onClick={onExit}>
      ลองอีกครั้ง
    </Button>
  </FullScreenPopup>
)

type PdfPasswordPopupProps = {
  onExit: Function,
  notificationMessage:
    | PasswordInformationNotification
    | RPQPasswordInformationNotification
    | RAPasswordInformationNotification,
  openPdf: Function,
  documentName: String,
}
export const _PdfPasswordPopup = ({ onExit, notificationMessage, openPdf, documentName }: PdfPasswordPopupProps) => {
  const openPdfAndExit = () => {
    openPdf(notificationMessage.productCategory, notificationMessage.biType)
    onExit()
  }

  return (
    <FullScreenPopup
      data-testid="full-screen-popup"
      onExit={onExit}
      title={notificationMessage.title}
      isOpen={notificationMessage.type !== 'None'}
    >
      <PDFPassWordProtected documentName={documentName} />
      <div className="description">
        <div>
          <CopyToClipboard text={notificationMessage.password}>
            <span className="password">{notificationMessage.password}</span>
          </CopyToClipboard>
        </div>
        <CopyToClipboard text={notificationMessage.password}>
          <a href="#" onClick={(e) => e.preventDefault()} className="copy-password">
            {MESSAGES.COPY}
          </a>
        </CopyToClipboard>
        <div>{MESSAGES.ENTER_DAY_MONTH_YEAR}</div>
        <div>{MESSAGES.TO_OPEN_FILE}</div>
      </div>
      <Button id="continue" color="primary" onClick={openPdfAndExit}>
        {MESSAGES.OPEN_PDF}
      </Button>
    </FullScreenPopup>
  )
}

const mapShareQuickQuoteToProps = (dispatch: Dispatch<*>) => ({
  openPdf: bindActionCreators(shareQuickQuote, dispatch),
  documentName: 'QuickQuite.pdf',
})

const PdfPasswordPopup = connect(null, mapShareQuickQuoteToProps)(_PdfPasswordPopup)

const mapShareRPQToProps = (dispatch: Dispatch<*>) => ({
  openPdf: bindActionCreators(shareRPQ, dispatch),
  documentName: 'RPQ.pdf',
})

const RPQPasswordPopup = connect(null, mapShareRPQToProps)(_PdfPasswordPopup)

const mapShareRAToProps = (dispatch: Dispatch<*>) => ({
  openPdf: bindActionCreators(shareRA, dispatch),
  documentName: 'RA.pdf',
})

const RAPasswordPopup = connect(null, mapShareRAToProps)(_PdfPasswordPopup)

type SmallPopupProps = {
  onExit: Function,
  notificationMessage: InformationNotification,
}

const SmallPopup = ({ onExit, notificationMessage }: SmallPopupProps) => (
  <Modal isOpen={notificationMessage !== 'None'} backdrop={true} modalClassName="default-bootstrap" toggle={onExit}>
    {notificationMessage.title ? <ModalHeader>{notificationMessage.title}</ModalHeader> : null}
    <ModalBody>{notificationMessage.message}</ModalBody>
    <ModalFooter>
      <Button color="primary" onClick={onExit}>
        {MESSAGES.POPUP_CLOSE_BUTTON}
      </Button>
    </ModalFooter>
  </Modal>
)
