//@flow
import Mustache from 'mustache'
import MESSAGES from 'core/data-model/constants/bi-messages'
import { getToggles } from 'quick-quote/feature-toggles'
import { signBox, blankCell } from 'core/service/pdf-generation/products/common/components/sign-box'

import type { Profile } from 'core/data-model/distributor'
import type { DisplayProduct } from 'core/service/display-product'
import type { InsuredProps } from 'core/service/pdf-generation/products/common/types'

export const customer = ({ firstName, lastName, mobile, signDate }: InsuredProps = {}) => {
  const name = [firstName || MESSAGES.DEFAULT_FIRSTNAME, lastName || MESSAGES.DEFAULT_LASTNAME].join(' ')
  return [
    [MESSAGES.CUSTOMER_SIGN, ''],
    ['', Mustache.render(MESSAGES.SIGNATURE_NAME, { name })],
    [MESSAGES.TELEPHONE_NUMBER, mobile || ''],
    [MESSAGES.DATE, signDate || ''],
  ]
}
const distributor = (profile: Profile) => {
  const enableAddBasicLicenseBi = getToggles().ENABLE_ADD_BASIC_LICENSE_BI
  const fullName = profile.given_name && profile.family_name ? `${profile.given_name} ${profile.family_name}` : ''
  const name = fullName ? Mustache.render(MESSAGES.SIGNATURE_NAME, { name: fullName }) : ''

  if (enableAddBasicLicenseBi) {
    return [
      [MESSAGES.DISTRIBUTOR_SIGN, ''],
      ['', name],
      [MESSAGES.BRANCH_UNIT_AGENT, profile.unitCode || '-'],
      [MESSAGES.DISTRIBUTOR_SIGNATURE_AGENT_ID, profile.agentCode || ''],
      [MESSAGES.LICENSE_NO, profile.basicLicenseNumber || ''],
      [MESSAGES.TELEPHONE_NUMBER, profile.mobile || ''],
    ]
  }

  return [
    [MESSAGES.DISTRIBUTOR_SIGN, ''],
    ['', name],
    [MESSAGES.BRANCH_UNIT_AGENT, profile.unitCode || '-'],
    [MESSAGES.DISTRIBUTOR_SIGNATURE_AGENT_ID, profile.agentCode || ''],
    [MESSAGES.TELEPHONE_NUMBER, profile.mobile || ''],
  ]
}

const signSection = (profile: Profile, insured: InsuredProps, basicPlan: DisplayProduct) => {
  const enableAddBasicLicenseBi = getToggles().ENABLE_ADD_BASIC_LICENSE_BI
  const fullName = profile.given_name && profile.family_name ? `${profile.given_name} ${profile.family_name}` : ''
  const name = fullName ? Mustache.render(MESSAGES.SIGNATURE_NAME, { name: fullName }) : ''

  if (enableAddBasicLicenseBi) {
    return {
      table: {
        widths: ['*', 10, '*'],
        body: [
          [
            signBox(customer(insured)),
            blankCell,
            signBox(distributor(profile), {
              1: { fontSize: name.length > 27 ? 11 : 12.5 },
              2: { fontSize: (profile.branchName || '').length > 27 ? 11 : 12.5 },
            }),
          ],
        ],
      },
    }
  }

  return {
    table: {
      widths: ['*', 10, '*'],
      body: [[signBox(customer(insured)), blankCell, signBox(distributor(profile))]],
    },
  }
}

export const signatureSectionAgent = (
  profile: Profile,
  notes: Object,
  insured: InsuredProps,
  basicPlan: DisplayProduct
) => ({
  stack: [
    notes,
    {
      ...signSection(profile, insured, basicPlan),
      absolutePosition: { x: 30, y: 510 },
    },
  ],
})
