// @flow
import { FormGroup } from 'reactstrap'
import type { Gender, GenderOption } from 'core/data-model/insured'
import { RadioButtonGroup } from 'common-components'

type Props = {
  genderOptions: GenderOption[],
  gender: Gender,
  selectGender: (string) => void,
}

const GenderSelection = ({ genderOptions, gender, selectGender }: Props) => (
  <FormGroup id="insured-gender-selection" className="gender-selection">
    <label>เพศตามกำเนิด</label>
    <RadioButtonGroup
      value={gender}
      options={genderOptions}
      onChange={selectGender}
      classNamePrefix="gender"
      autoClassName={true}
    />
  </FormGroup>
)

export default GenderSelection
