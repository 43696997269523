import styled from 'styled-components'
import moment from 'moment-timezone'
import { compose, withStateHandlers } from 'recompose'
import { deviceType, customDate } from '../../../domain/data-model/form/utils'

import { Table } from 'reactstrap'
import VALUES from 'core/data-model/constants/values'

import { getIcon } from 'e-submission/components/Icon'
import { connect } from 'react-redux'

const DupTable = styled(Table)`
  text-align: center;
  width: 100%;

  .actionColumn {
    border-radius: 6px;
    width: 25%;
    box-shadow: 0 1px 2px 3px rgb(0 0 0 / 11%);
  }

  .column {
    display: flex;
    justify-content: space-around;
  }

  .firstColumn {
    text-align: right;
    width: 5%;
  }

  &.ekyc-mobile-layout {
    tbody th:first-child {
      width: 5.5rem;
      min-width: 5.5rem;
      max-width: 5.5rem;
      word-break: break-all;
      text-align: right;
      padding-right: 0.5rem;
      position: sticky;
      left: 0;
      z-index: 99;
      background-color: white;
    }

    td {
      text-align: left;
    }

    .slim {
      font-weight: normal;
      font-size: smaller;
    }
  }
`

const VerifiedColumn = styled.span`
  color: #1dc54e;
  font-weight: bold;
`

const Container = styled.div`
  &.mobile {
    padding-top: 1rem;
    max-height: 24rem;
  }

  &.web {
    padding: 1rem;
    overflow-x: scroll;
    max-height: 10rem;
  }
`

const ChoiceItem = styled.div`
   {
    padding: 1rem 0 0;
    display: flex;
  }
`
const ChoiceContainer = styled.div`
  padding: 1rem 0.5rem;

  &::before {
    content: '';
    display: block;
    border-top: solid 1px ${({ theme }) => theme.variables['$color-silver']};
    top: 1px;
    left: 1rem;
    right: 1rem;
  }

  .title {
    font-weight: bold;
  }

  .detail {
    padding-left: 0.5rem;
  }

  [type='radio'] {
    width: 20px;
    height: 20px;
    top: 5px;
    position: relative;
  }
`

const ScrollContainer = styled.div`
  overflow-x: scroll;

  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
`

const ExpandableIconDiv = styled.div`
  width: 16px;
  height: auto;
  cursor: pointer;
`

export const FirstCase = ({ cases, isShowHistories, toggle, handleClick, isChedked }) => {
  return (
    <tr>
      <td className="firstColumn">
        <ExpandableIconDiv onClick={() => toggle()}>
          {isShowHistories ? getIcon('arrowOutlineDown') : getIcon('arrowOutlineRight')}
        </ExpandableIconDiv>
      </td>
      <td>{cases.case.caseType.key}</td>
      <td>-</td>
      <td>{cases.firstName}</td>
      <td>{cases.lastName}</td>
      <td>{cases.citizenId}</td>
      <td>
        {moment(cases.expiresAt)
          .tz(VALUES.TIME_ZONE)
          .add(543, 'years')
          .format('DD MMM YYYY')}
      </td>
      <td className="bold">{cases.verificationCache.dopaConfig.required ? 'ต้องการ' : 'ไม่ต้องการ'}</td>
      <td>
        <div className="column">
          <div>
            <input
              type="radio"
              name="method-handle-dup-cases"
              id="new-case"
              checked={isChedked('new-case')}
              onChange={handleClick('new-case')}
            />
          </div>
          <div>
            <input
              type="radio"
              name="method-handle-dup-cases"
              id="reuse"
              checked={isChedked('reuse')}
              onChange={handleClick('reuse')}
            />
          </div>
        </div>
      </td>
    </tr>
  )
}
export const CaseItems = ({ cases }) => {
  return (
    <tr>
      <td className="firstColumn" />
      <td>{cases.case.caseType.key}</td>
      <td>-</td>
      <td>{cases.firstName}</td>
      <td>{cases.lastName}</td>
      <td>{cases.citizenId}</td>
      <td>
        {moment(cases.expiresAt)
          .tz(VALUES.TIME_ZONE)
          .add(543, 'years')
          .format('DD MMM YYYY')}
      </td>
      <td className="bold">{cases.verificationCache.dopaConfig.required ? 'ต้องการ' : 'ไม่ต้องการ'}</td>
      <td>
        {cases.verificationCache.verifiedAt ? (
          <VerifiedColumn>
            Verified ({customDate(cases.verificationCache.verifiedAt, 'DD/MM/YYYY hh:mm')})
          </VerifiedColumn>
        ) : (
          '-'
        )}
      </td>
    </tr>
  )
}

const MobileDupTable = ({ cases }) => (
  <ScrollContainer>
    <DupTable className="ekyc-mobile-layout">
      <tbody>
        <tr>
          <th scope="row">ประเภทเคส</th>
          {cases.map((c, index) => (
            <td key={index}>{c.case.caseType.key}</td>
          ))}
        </tr>
        <tr>
          <th scope="row">เลขกรมธรรม์</th>
          {cases.map((c, index) => (
            <td key={index}>-</td>
          ))}
        </tr>
        <tr>
          <th scope="row">ชื่อจริง</th>
          {cases.map((c, index) => (
            <td key={index}>{c.firstName}</td>
          ))}
        </tr>
        <tr>
          <th scope="row">นามสกุล</th>
          {cases.map((c, index) => (
            <td key={index}>{c.lastName}</td>
          ))}
        </tr>
        <tr>
          <th scope="row">เลขเอกสาร</th>
          {cases.map((c, index) => (
            <td key={index}>{c.citizenId}</td>
          ))}
        </tr>
        <tr>
          <th scope="row">วันหมดอายุเอกสาร</th>
          {cases.map((c, index) => (
            <td key={index}>
              {moment(c.expiresAt)
                .tz(VALUES.TIME_ZONE)
                .add(543, 'years')
                .format('DD MMM YYYY')}
            </td>
          ))}
        </tr>
        <tr>
          <th scope="row">DOPA</th>
          {cases.map((c, index) => (
            <td key={index} className="bold">
              {c.verificationCache.dopaConfig.required ? 'ต้องการ' : 'ไม่ต้องการ'}
            </td>
          ))}
        </tr>
        <tr>
          <th scope="row" />
          {cases.map((c, index) => (
            <td key={index}>
              {c.verificationCache.verifiedAt ? (
                <VerifiedColumn>
                  <div>Verified</div>
                  <div className="slim">{customDate(c.verificationCache.verifiedAt, 'DD/MM/YYYY hh:mm')}</div>
                </VerifiedColumn>
              ) : (
                '-'
              )}
            </td>
          ))}
        </tr>
      </tbody>
    </DupTable>
  </ScrollContainer>
)

const Choice = ({ isChedked, handleClick }) => (
  <ChoiceContainer>
    <ChoiceItem>
      <input
        type="radio"
        name="method-handle-dup-cases"
        id="new-case"
        checked={isChedked('new-case')}
        onChange={handleClick('new-case')}
      />
      <div className="detail">
        <span className="title"> สร้างใหม่ </span> - สร้างเป็นเคสใหม่และส่งลิงก์เพื่อยืนยันตัวตนใหม่{' '}
      </div>
    </ChoiceItem>
    <ChoiceItem>
      <input
        type="radio"
        name="method-handle-dup-cases"
        id="reuse"
        checked={isChedked('reuse')}
        onChange={handleClick('reuse')}
      />
      <div className="detail">
        <span className="title"> ใช้ซ้ำ </span> - สร้างแถวใหม่ขึ้นมาในแดชบอร์ดแต่ใช้ผลลัพธ์ยืนยันตัวตนจากเคสก่อน{' '}
      </div>
    </ChoiceItem>
  </ChoiceContainer>
)

const ExpandableDuplicateField = ({ cases, setShowHistories, isShowHistories, seletedOption, setOption }) => {
  const firstCase = cases[0]
  const anotherCases = cases.filter((c, i) => i > 0)
  const isChedked = (optValue) => optValue === seletedOption
  const handleClick = (optValue) => () => {
    return setOption(optValue)
  }
  return cases.length > 0 ? (
    <div>
      {deviceType() === 'mobile' ? (
        <Container className="mobile">
          <MobileDupTable cases={cases} />
          <Choice isChedked={isChedked} handleClick={handleClick} />
        </Container>
      ) : (
        <Container className="web">
          <DupTable>
            <thead>
              <tr>
                <td className="firstColumn" />
                <td>ประเภทเคส</td>
                <td>เลขกรมธรรม์</td>
                <td>ชื่อจริง</td>
                <td>นามสกุล</td>
                <td>เลขเอกสาร</td>
                <td>วันหมดอายุเอกสาร</td>
                <td>DOPA</td>
                <td className="actionColumn">
                  <div className="column">
                    <div>สร้างใหม่</div>
                    <div>ใช้ซ้ำ</div>
                  </div>
                </td>
              </tr>
            </thead>
            <tbody>
              <FirstCase
                cases={firstCase}
                isShowHistories={isShowHistories}
                toggle={setShowHistories}
                isChedked={isChedked}
                handleClick={handleClick}
              />
              {isShowHistories &&
                anotherCases.map((c, index) => <CaseItems key={`cases-item-${index}`} index={index} cases={c} />)}
            </tbody>
          </DupTable>
        </Container>
      )}
    </div>
  ) : null
}

const mapStateToProps = (state, props) => ({})
const mapDispatchToProps = (dispatch, { setShowHistories }) => ({})

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStateHandlers(
    { isShowHistories: false },
    {
      setShowHistories: (state) => () => ({ isShowHistories: !state.isShowHistories }),
    }
  )
)(ExpandableDuplicateField)
