import { connect } from 'react-redux'
import { Modal } from 'common-components'
import { ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap'
import { bindActionCreators } from 'redux'
import { setProcessingSuccess } from '../actions'
import { getURLStore, getDevice } from '../util/getDevice'

class CheckversionNativePopup extends React.Component {
  render() {
    const { notificationMessage } = this.props
    return (
      <Modal
        isOpen={notificationMessage !== 'None'}
        backdrop={true}
        size="xl"
        modalClassName="default-bootstrap check-version-modal"
      >
        <ModalHeader>อัพเดตเวอร์ชั่นล่าสุด</ModalHeader>
        {getDevice() === 'other' ? (
          <div>
            <ModalBody>โปรด Refresh หน้าเว็ปไซต์ AdvisorZone</ModalBody>
          </div>
        ) : (
          <div>
            <ModalBody>
              กรุณาไปยัง{getDevice() === 'ios' ? 'แอปสโตร์' : 'เพลสโตร์'}
              เพื่ออัพเดตแอปพลิเคชันเป็นเวอร์ชั่นล่าสุด
            </ModalBody>
            <ModalFooter>
              <div className="bottom">
                <Button color="primary" onClick={() => window.open(getURLStore(), '_system')} target="_top">
                  ตกลง
                </Button>
              </div>
            </ModalFooter>
          </div>
        )}
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => ({
  setProcessingSuccess: bindActionCreators(setProcessingSuccess, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(CheckversionNativePopup)
