//@flow
import type { State as InvestmentCoveragePlanState } from './coverage-plan/reducer'
import { reducer as investmentCoveragePlan } from './coverage-plan/reducer'
import type { State as InvestmentRiskState } from './risk-questionnaire/reducer'
import { reducer as investmentRisk } from './risk-questionnaire/reducer'
import type { State as FundState } from './fund-allocation/reducer'
import { reducer as funds } from './fund-allocation/reducer'
import type { PermissionResult } from 'core/service/distributor/permission'
import { combineReducers } from 'redux'
import { reducer as proceedToInvestmentProduct } from './validate-permission/reducer'

export type State = {
  coveragePlan: InvestmentCoveragePlanState,
  investmentRisk: InvestmentRiskState,
  funds: FundState,
  proceedToInvestmentProduct: PermissionResult,
}

export const reducer = combineReducers({
  coveragePlan: investmentCoveragePlan,
  investmentRisk: investmentRisk,
  funds: funds,
  proceedToInvestmentProduct,
})
