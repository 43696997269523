// @flow
import {
  APAndECARERiderBenefit,
  CIRiderBenefit,
  CPRRiderBenefit,
  DCIRiderBenefit,
  ESCIWPEIP85Benefit,
  ESCIWPEWL85Benefit,
  FHRiderBenefit,
  HBUDRRiderBenefit,
  HICRiderBenefit,
  HSUDRRiderBenefit,
  MEAPlusRiderBenefit,
  MEKRiderBenefit,
  MEBRiderBenefit,
  MEHRiderBenefit,
  PLRRiderBenefit,
  PLSRiderBenefit,
  TPDRiderBenefit,
  WPCRiderBenefit,
  WPDDRiderBenefit,
  WPDRiderBenefit,
  WPRiderBenefit,
  WPTPDRiderBenefit,
  WPTPDCIRiderBenefit,
  MEXRiderBenefit,
  MHPRiderBenefit,
  PBPDDRiderBenefit,
  PBPDDCIRiderBenefit,
  PBSDDRiderBenefit,
  PBSDDCIRiderBenefit,
} from './rider-benefit-containers'
import MESSAGES from 'core/data-model/constants/bi-messages'
import { getToggles } from 'quick-quote/feature-toggles'

const RidersBenefit = () => {
  return (
    <div id="riders-benefit" className="rider-summary group">
      <h3>{MESSAGES.RIDERS_BENEFITS}</h3>
      <WPRiderBenefit />
      <WPTPDRiderBenefit />
      <WPTPDCIRiderBenefit />
      <PBPDDRiderBenefit />
      <PBPDDCIRiderBenefit />
      <PBSDDRiderBenefit />
      <PBSDDCIRiderBenefit />
      <WPDRiderBenefit />
      <WPDDRiderBenefit />
      <MHPRiderBenefit />
      <MEXRiderBenefit />
      <MEAPlusRiderBenefit />
      <MEHRiderBenefit />
      <FHRiderBenefit />
      <MEBRiderBenefit />
      <MEKRiderBenefit />
      <APAndECARERiderBenefit />
      <DCIRiderBenefit />
      <PLRRiderBenefit />
      <PLSRiderBenefit />
      <CPRRiderBenefit />
      <HICRiderBenefit />
      <WPCRiderBenefit />
      <CIRiderBenefit />
      <TPDRiderBenefit />
      <HSUDRRiderBenefit />
      <HBUDRRiderBenefit />
      {getToggles().ENABLE_ICARE_REPRICING === true ? <ESCIWPEIP85Benefit /> : <ESCIWPEWL85Benefit />}
    </div>
  )
}

export default RidersBenefit
