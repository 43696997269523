//@flow

import { dataWrapper } from 'core/data'
import type { PromiseFunction } from 'core/data'
import type { FinancialCheckList, HealthCheckList } from 'core/data-model/loan'

const LOAN_FINANCIAL_DOCUMENT_KEY = 'loan_financial'
const LOAN_HEALTH_DOCUMENT_KEY = 'loan_health'
const { list } = dataWrapper

export const _listFinancialTable = (list: PromiseFunction<FinancialCheckList[]>) => list(LOAN_FINANCIAL_DOCUMENT_KEY)

export const listFinancialTable = (): Promise<FinancialCheckList[]> => _listFinancialTable(list)

export const _listHealthCheckTable = (list: PromiseFunction<HealthCheckList[]>) => list(LOAN_HEALTH_DOCUMENT_KEY)

export const listHealthCheckTable = (): Promise<HealthCheckList[]> => _listHealthCheckTable(list)
