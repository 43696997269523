/* istanbul ignore file */

import _ from 'lodash'
import d from 'decimal.js'
import { connect } from 'react-redux'
import { FormGroup } from 'reactstrap'
import { Dropdown, Checkbox } from 'common-components'
import RiderPremium from 'quick-quote/product-common/coverage-plan/components/rider/rider-premium'

import { editRiderSumAssured, editRiderSelectedPlan } from 'quick-quote/product-common/coverage-plan/actions'

export const getHealthPackageGroup = (packageRidersGroup, previousPlan, plan, areaOfCover) => {
  const newSelectedPlan = _.find(packageRidersGroup, { plan })
  const changedAreaOfCover = previousPlan !== plan ? newSelectedPlan.areasOfCover[0] : areaOfCover
  let planCode = newSelectedPlan.planGroups[0].codes[changedAreaOfCover]
  let description = newSelectedPlan.planGroups[0].description
  let availableModules = []
  const filterSelectedPlan = newSelectedPlan.planGroups.filter((plan) => plan.isSelected)
  if (filterSelectedPlan.length > 0) {
    planCode = filterSelectedPlan[0].codes[changedAreaOfCover]
    description = filterSelectedPlan[0].description
    availableModules = _.get(filterSelectedPlan[0], 'modules', []).map((m) => {
      return { code: m.code[changedAreaOfCover], description: m.description, isSelected: true }
    })
  }

  return {
    planCode,
    plan,
    description,
    areaOfCover: changedAreaOfCover,
    modules: availableModules,
  }
}

export const accumulateRiderPremium = (rider) => {
  const modulePremium = _.get(rider, 'selectedPlan.modules', []).reduce((acc, m) => d(acc).plus(m.premium || 0), 0)
  return d(rider.premium)
    .plus(modulePremium)
    .toNumber()
}

const FHRiderJuvenile = ({
  className,
  planGroupOptions,
  rider,
  areaOfCoverGroupPackages,
  editJuvenileHealthPackage,
  toggleRiderGroupModule,
  ...props
}) => {
  return (
    <FormGroup className={`${className} meh`} {...props}>
      <div className="label">
        <strong>เลือกแผน {rider.name}</strong>
        <div className="options">
          <Dropdown
            options={planGroupOptions}
            onChange={(value) => {
              editJuvenileHealthPackage(value, rider.selectedPlan.areaOfCover)
            }}
            value={rider.selectedPlan.plan}
          />
          <div className="link">→</div>
          <Dropdown
            options={areaOfCoverGroupPackages}
            onChange={(value) => {
              editJuvenileHealthPackage(rider.selectedPlan.plan, value)
            }}
            value={rider.selectedPlan.areaOfCover}
          />
        </div>
        {rider.packageRidersGroup
          .find((r) => r.plan === rider.selectedPlan.plan)
          .planGroups.map((plan) => {
            const riderGroupChecked = !!plan.isSelected

            return (
              <div className="options" key={plan.code}>
                <Checkbox
                  id={plan.code}
                  checked={riderGroupChecked}
                  disabled={false}
                  onChange={() => {
                    toggleRiderGroupModule(rider.code, plan.code, !riderGroupChecked)
                  }}
                >
                  <span className="custom-control-description">{plan.planName}</span>
                </Checkbox>
              </div>
            )
          })}
      </div>
      <div className="line-break" />
      <div className="options">
        <label>เบี้ยประกันสำหรับสัญญาเพิ่มเติม</label>
        <RiderPremium isSelected={true} isSelectable={true} premium={accumulateRiderPremium(rider)} />
      </div>
    </FormGroup>
  )
}

const mapStateToProps = (state) => ({})

export default connect(
  mapStateToProps,
  { editRiderSumAssured, editRiderSelectedPlan },
  (stateProps, dispatchProps, ownProps) => {
    const { rider } = ownProps
    const { editRiderSumAssured, editRiderSelectedPlan } = dispatchProps

    const planGroupOptions = rider.packageRidersGroup.map(({ plan }) => ({
      text: plan,
      value: plan,
    }))

    const selectedPackageGroup = _.find(rider.packageRidersGroup, {
      plan: rider.selectedPlan.plan,
    })

    const areaOfCoverGroupPackages = _.pick(rider.areasOfCover, selectedPackageGroup.areasOfCover)

    return {
      ...ownProps,
      rider,
      editJuvenileHealthPackage: (plan, areaOfCover) => {
        const healthPackage = getHealthPackageGroup(
          rider.packageRidersGroup,
          selectedPackageGroup.plan,
          plan,
          areaOfCover
        )
        editRiderSumAssured(rider.code, 0)
        editRiderSelectedPlan(rider.code, healthPackage)
      },
      planGroupOptions,
      areaOfCoverGroupPackages,
    }
  }
)(FHRiderJuvenile)
