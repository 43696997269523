// @flow
import _ from 'lodash'
import VALUES from 'core/data-model/constants/values'
import DEFAULTS from 'core/data-model/constants/defaults'
import type { DisplayProduct } from 'core/service/display-product/types'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { Insured } from 'core/data-model/insured'
import Mustache from 'mustache'
import { getDisplayProducts } from 'quick-quote/product-selection/selectors'
import type { AppState } from 'quick-quote/reducers'
import { createSelector } from 'reselect'
import messages from 'core/data-model/constants/messages'
import type { Option } from 'common-components/dropdown'

export const getSelectedModelFactorID = (state: AppState): string => state.coveragePlan.selectedModelFactorID

export const getBasicPlanErrorMessage = (state: AppState): string => state.coveragePlan.selectedBasicPlan.error

export const getInsuredOccupationErrorMessage = (state: AppState): string => _.get(state, 'insured.occupation.errors')

export const getIHealthyUltraBasicPlanErrorMessage = createSelector(
  getBasicPlanErrorMessage,
  getInsuredOccupationErrorMessage,
  (basicPlanError: string, InsuredOccError: string[]): string[] => {
    const errorMsg: string[] = []
    if (basicPlanError) errorMsg.push(basicPlanError)
    errorMsg.push(...InsuredOccError)
    return errorMsg
  }
)

const getBasicPlanOptionText = (product: DisplayProduct): string => {
  const template = messages.BASIC_PLAN_SELECTION_LABEL
  if (!product.entryAge) {
    return product.code
  }
  return Mustache.render(template, {
    basicPlanCode: product.productName,
    entryAgeMinimum: product.entryAgeDisplay.min,
    entryAgeMaximum: product.entryAgeDisplay.max,
  })
}

export const getRiderSelectedPlanCodeMHP = (availableRiders: (Rider & RiderState)[]): string => {
  const riderMHP = _.find(availableRiders, (rider) => rider.code === 'MHP')
  return riderMHP ? riderMHP.selectedPlan.planCode : ''
}

export const getSelectedPlanCodeRiderMultiPlanCodeMHP = (
  availableRiders: (Rider & RiderState)[],
  insured: Insured
): string => {
  const age = _.get(insured, 'age.value', 0)
  let selectedPlan = {}

  const riderMHP = _.find(availableRiders, (rider) => rider.code === 'MHP')
  if (riderMHP) {
    const riderCode = riderMHP.selectedPlan.planCode
    let riderSelectedCode = ''
    let riderPlan = riderMHP.selectedPlan.plan
    let areaOfCoverSelected = riderMHP.selectedPlan.areaOfCover
    let allowOptionalIHealthyUltra = riderMHP.selectedPlan.allowOptionalIHealthyUltra
      ? riderMHP.selectedPlan.allowOptionalIHealthyUltra
      : false
    const planOptionalSelected = riderMHP.selectedPlan.planOptional
      ? riderMHP.selectedPlan.planOptional
      : VALUES.DEFAULT_MHP_OPTIONAL
    if (VALUES.LIST_OF_MHP_MULTI_PLANCODE.includes(riderCode)) {
      if (age > VALUES.MHP_AGE_OF_SMART_SILVER_JUVENILE_PLAN.MAX.value) riderSelectedCode = riderCode.replace(/.$/, 'S')
      else riderSelectedCode = riderCode.replace(/.$/, 'J')
    } else {
      if (age <= VALUES.MHP_AGE_OF_SMART_SILVER_JUVENILE_PLAN.MAX.value) {
        switch (planOptionalSelected) {
          case VALUES.MHP_CO_PAYMENT:
            riderSelectedCode = DEFAULTS.DEFAULT_MHP_PLANCODE.SMART.JUNIOR_COPAY
            break
          case VALUES.MHP_DEDUCTIBLE:
            riderSelectedCode = DEFAULTS.DEFAULT_MHP_PLANCODE.SMART.JUNIOR_DEDUCT
            break
          default:
            riderSelectedCode = DEFAULTS.DEFAULT_MHP_PLANCODE.SMART.JUNIOR
            break
        }
        riderPlan = DEFAULTS.DEFAULT_MHP_PLANNAME.SMART
        areaOfCoverSelected = DEFAULTS.DEFAULT_MHP_AREA_COVER.THAILAND
      } else riderSelectedCode = riderCode
    }

    selectedPlan = {
      planCode: riderSelectedCode,
      plan: riderPlan,
      areaOfCover: areaOfCoverSelected,
      planOptional: planOptionalSelected,
      allowOptionalIHealthyUltra: allowOptionalIHealthyUltra,
    }
  }
  return selectedPlan
}

export const getSelectedDisplayProductCodeIHealthyUltra = (state: AppState): string => {
  const selectedCode = state.selectedDisplayProduct.code
  switch (selectedCode) {
    case VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PROTECT_18.SENIOR:
      return VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PROTECT_18.JUNIOR
    case VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_ENSURE_10.SENIOR:
      return VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_ENSURE_10.JUNIOR
    case VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_25_15.SENIOR:
      return VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_25_15.JUNIOR
    case VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_20_10.SENIOR:
      return VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_20_10.JUNIOR
    default:
      return selectedCode
  }
}

const getiHealthyUltraProductsList = createSelector(
  getDisplayProducts,
  (products: DisplayProduct[]): DisplayProduct[] => {
    return DEFAULTS.DEFAULT_IHEALTHY_ULTRA_PRODUCT_LIST.filter((p) => p.isShow === true)
  }
)

export const getBasicPlanOptions = createSelector(
  getiHealthyUltraProductsList,
  (products: DisplayProduct[]): Option[] =>
    products.map((p) => ({
      value: p.code,
      text: getBasicPlanOptionText(p),
    }))
)

export const getIhealthyUltraSelectedPlanName = (state: AppState): string =>
  _.get(state, 'coveragePlan.riders["MHP"].selectedPlan.plan', '')
export const getIhealthyUltraSelectedPlanCode = (state: AppState): string =>
  _.get(state, 'coveragePlan.riders["MHP"].selectedPlan.planCode', '')
