//@flow
import type { PromiseFunction } from 'core/data'
import type { DistributorGroup } from 'core/data-model/distributor'
import { dataWrapper } from 'core/data'

const { list } = dataWrapper
const DOCUMENT_KEY = 'distributor-group'

export const _listDistributorGroups = (list: PromiseFunction<DistributorGroup[]>) => list(DOCUMENT_KEY)

export const listDistributorGroups = () => _listDistributorGroups(list)
