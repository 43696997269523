// @flow
import {
  getBasicPlanNameDisplayValue,
  getSumAssuredDisplayValue,
} from 'core/service/pdf-generation/products/common/logic'
import { getFlattenRidersAndModules } from 'quick-quote/product-flexi-health/benefit-illustration/components/profile-summary/summary-table'
import _ from 'lodash'
import { formatNumber } from 'core/service/lib/number-format'
import { buildStacksWithStyle, buildHeaderTable } from 'core/service/pdf-generation/utils/builder'
import BIMESSAGES from 'core/data-model/constants/bi-messages'
import MESSAGES from 'core/data-model/constants/messages'
import { totalRiderPremium } from 'core/service/basic-plan/premium'
import { hasAsteriskFromRiders } from 'core/service/rider/benefits/benefit-data'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { Age } from 'core/data-model/insured'
import type { DisplayProduct } from 'core/service/display-product'
import type { BenefitSummary } from 'core/service/benefit-illustration'

const summaryTable = (displayProduct: DisplayProduct) => ({
  title: BIMESSAGES.SUMMARY_OF_MAIN_AND_ADDITIONAL_CONTRACTS,
  headers: [
    BIMESSAGES.BASIC_PLAN_RIDER,
    BIMESSAGES.COVERAGE_PERIOD + ` (${MESSAGES.TIME_UNIT_YEAR})`,
    BIMESSAGES.PAYMENT_PERIOD + ` (${MESSAGES.TIME_UNIT_YEAR})`,
    `${BIMESSAGES.SUM_ASSURED} (${MESSAGES.BAHT})`,
    `${BIMESSAGES.PREMIUM} (${MESSAGES.BAHT})`,
  ],
})

const buildBasicPlanRowInTable = (
  basicPlan: DisplayProduct,
  calculatedCoveragePeriod,
  calculatedPaymentPeriod,
  basicSumAssured,
  basicPremium,
  basicPlanLabel
) => [
  {
    text: getBasicPlanNameDisplayValue(
      // $$FlowFixMe
      _.isFunction(basicPlanLabel)
        ? {
            ...basicPlan,
            // $$FlowFixMe
            name: basicPlanLabel(basicPlan),
          }
        : basicPlan
    ),
  },
  { text: calculatedCoveragePeriod, alignment: 'center' },
  { text: calculatedPaymentPeriod, alignment: 'center' },
  {
    text: getSumAssuredDisplayValue(basicPlan, basicSumAssured, false),
    alignment: 'center',
  },
  { text: formatNumber(basicPremium, 2), bold: true, alignment: 'right' },
]

const buildTotalPremiumRow = (totalPremium: number) => {
  return [
    { colSpan: 4, rowSpan: 1, text: BIMESSAGES.TOTAL_PREMIUM_FIRST_PREMIUM },
    '',
    '',
    '',
    {
      colSpan: 1,
      rowSpan: 1,
      text: formatNumber(totalPremium, 2),
      bold: true,
      alignment: 'right',
    },
  ]
}

const buildRidersRowInTable = (riders: (Rider & RiderState)[]) => {
  const flattenRidersAndModules = getFlattenRidersAndModules(riders)

  return _.map(flattenRidersAndModules, (rider) => [
    // $$FlowFixMe
    { text: `${rider.productName} (${rider.productCode})${rider.asterisks}` },
    { text: _.get(rider, 'coverageYear', 1), alignment: 'center' },
    { text: _.get(rider, 'paymentPeriod', 1), alignment: 'center' },
    // $$FlowFixMe
    { text: BIMESSAGES.FLEXI_HEALTH_SUMMARY_BENEFIT_MESSAGE, alignment: 'center' },
    { text: formatNumber(rider.premium, 2), bold: true, alignment: 'right' },
  ])
}

const buildSummaryBasicPlan = (sumAssured, selectedModelFactorLabel) => [
  {
    label: { text: BIMESSAGES.BASIC_PLAN_SUM_ASSURED, style: '' },
    value: {
      text: `${formatNumber(sumAssured)} ${MESSAGES.BAHT}`,
      style: 'basicPlanSummaryValue',
    },
  },
  {
    label: { text: MESSAGES.PAYMENT_MODE, style: '' },
    value: { text: selectedModelFactorLabel, style: 'basicPlanSummaryValue' },
  },
]

const buildSummaryPremium = (basicPremium, riderPremium, totalPremium, riders) => [
  {
    label: { text: BIMESSAGES.BASIC_PREMIUM, style: '' },
    value: {
      text: `${formatNumber(basicPremium, 2)} ${MESSAGES.BAHT}`,
      style: 'basicPlanSummaryValue',
    },
  },
  {
    label: { text: `${BIMESSAGES.TOTAL_RIDER_PREMIUM}${hasAsteriskFromRiders(riders) ? '*' : ''}`, style: '' },
    value: {
      text: `${formatNumber(riderPremium, 2)} ${MESSAGES.BAHT}`,
      style: 'basicPlanSummaryValue',
    },
  },
  {
    label: { text: BIMESSAGES.TOTAL_PREMIUM_FIRST_PREMIUM, style: '' },
    value: {
      text: `${formatNumber(totalPremium, 2)} ${MESSAGES.BAHT}`,
      style: 'basicPlanSummaryValue',
    },
  },
]

const buildDisclaimers = (riders) => {
  return {
    stack: [
      `${hasAsteriskFromRiders(riders) ? '*' : ''} ${BIMESSAGES.DISCLAIMER_RIDER_PREMIUM}`,
      BIMESSAGES.FLEXI_SUMMARY_TABLE_DISCLAIMER_2,
      ' ',
    ],
    style: 'disclaimer',
  }
}

const buildSummaryTableDisclaimer = () => {
  return {
    stack: [BIMESSAGES.FLEXI_SUMMARY_TABLE_DISCLAIMER_3, BIMESSAGES.FLEXI_SUMMARY_TABLE_DISCLAIMER_4],
    style: 'disclaimerBox',
  }
}

type Props = {
  basicPlan: DisplayProduct,
  riders: (Rider & RiderState)[],
  genderLabel: string,
  age: Age,
  calculatedCoveragePeriod: number,
  calculatedPaymentPeriod: number,
  sumAssured: number,
  basicPremium: number,
  totalPremium: number,
  selectedModelFactorLabel: string,
  hasNonLevelRider: boolean,
  benefitSummary: BenefitSummary,
  basicPlanLabel: ?Function,
}

export const profileSnapshotSection = ({
  basicPlan,
  genderLabel,
  age,
  calculatedCoveragePeriod,
  calculatedPaymentPeriod,
  sumAssured,
  selectedModelFactorLabel,
  basicPremium,
  riders,
  hasNonLevelRider,
  totalPremium,
  basicPlanLabel,
  ...props
}: Props) => [
  {
    table: {
      widths: ['33%', '34%', '33%'],
      body: [
        buildStacksWithStyle(buildSummaryBasicPlan(sumAssured, selectedModelFactorLabel)).concat(''),
        buildStacksWithStyle(buildSummaryPremium(basicPremium, totalRiderPremium(riders), totalPremium, riders)),
      ],
    },
    layout: 'noBorders',
    marginBottom: 20,
  },
  {
    text: summaryTable(basicPlan).title,
    style: 'title',
  },
  {
    table: {
      headerRows: 1,
      widths: [180, 'auto', 'auto', 'auto', 'auto'],
      margin: [100, 10],
      body: [
        buildHeaderTable(summaryTable(basicPlan).headers),
        buildBasicPlanRowInTable(
          basicPlan,
          calculatedCoveragePeriod,
          calculatedPaymentPeriod,
          sumAssured,
          basicPremium,
          basicPlanLabel
        ),
        ...buildRidersRowInTable(riders),
        buildTotalPremiumRow(totalPremium),
      ],
    },
    layout: 'lightBorder',
    style: 'table',
  },
  buildDisclaimers(riders),
  buildSummaryTableDisclaimer(),
]
