// @flow
import { compact } from 'lodash/fp'
import {
  canProceedToFundAndCoveragePlan,
  isInvestmentInputsValid,
} from 'quick-quote/product-investment/journey-control-selectors'
import type { AppState } from 'quick-quote/reducers'
// Section props/data
import {
  getCoverageDeathBenefit,
  getCoveragePeriod,
  getLumpSumTopUp,
  getRegularPremium,
  getRegularTopUp,
  getSelectedModelFactorLabel,
  getSumAssured,
} from 'quick-quote/product-investment/coverage-plan/selectors'
import { getAllocationCategoryData } from 'quick-quote/product-investment/benefit-illustration/selectors'
import { getSelectedDisplayProduct } from 'quick-quote/product-selection/selectors'
import { getAge, getGenderLabel } from 'quick-quote/insured-information/selectors'
import { isBlockedEnteringESubInvestment, isReviseQQFlow } from 'quick-quote/selectors'
import { getTotalRiderPremium } from 'quick-quote/product-common/coverage-plan/selectors'
import {
  getCalculatedCoveragePeriod,
  getCalculatedPaymentPeriod,
} from 'quick-quote/product-common/benefit-illustration/selectors'
// Section Components
import { BenefitIllustration } from 'quick-quote/product-investment/benefit-illustration/components'

export const getCommonInvestmentInfo = (state: AppState) => {
  return {
    displayProduct: getSelectedDisplayProduct(state),
    genderLabel: getGenderLabel(state),
    age: getAge(state),
    sumAssured: getSumAssured(state).value,
    calculatedCoveragePeriod: getCalculatedCoveragePeriod(state),
    calculatedPaymentPeriod: getCalculatedPaymentPeriod(state),
    coveragePeriod: getCoveragePeriod(state),
    coverageDeath: getCoverageDeathBenefit(state),
    regularPremium: getRegularPremium(state).value,
    regularTopUp: getRegularTopUp(state).value,
    lumpSumTopUp: getLumpSumTopUp(state),
    totalRiderPremium: getTotalRiderPremium(state),
    selectedModelFactorLabel: getSelectedModelFactorLabel(state),
    canProceedToInvestmentBenefitIllustration: isInvestmentInputsValid(state),
    canProceedToFundAndCoveragePlan: canProceedToFundAndCoveragePlan(state),
    allocationCategoryData: getAllocationCategoryData(state),
    isBlockedEnteringESub: isBlockedEnteringESubInvestment(state),
    isReviseQQ: isReviseQQFlow(state),
  }
}

export const getRPULSection = (state: AppState) => {
  return compact([
    {
      component: BenefitIllustration,
      props: {
        ...getCommonInvestmentInfo(state),
      },
    },
  ])
}
export const getSPULSection = (state: AppState) => {
  return compact([
    {
      component: BenefitIllustration,
      props: {
        ...getCommonInvestmentInfo(state),
      },
    },
  ])
}

export const getSections = (state: AppState) => {
  return compact([])
}

export const getMenus = (state: AppState) => []
