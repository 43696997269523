//@flow
import { get } from 'lodash/fp'
import { createSelector } from 'reselect'
import type { FNAConfiguration } from 'core/data-model'
import type { Reason, VulnerableQuestion } from 'core/data-model/fna/types'
import type { AppState } from '../reducers'
import type { FnaState } from './reducer'

export const getFna = (state: AppState): FnaState => state.fna
export const getFnaPhase = createSelector(getFna, get('fnaPhase'))
export const isOnFnaPhase = createSelector(getFnaPhase, (fnaPhase) => fnaPhase)
export const getFnaConfiguration = (state: AppState): FNAConfiguration => state.fnaConfiguration
export const getFnaReasonToSelectProduct = createSelector(
  getFnaConfiguration,
  (fnaConfig: FNAConfiguration): Reason[] => fnaConfig.reasonToSelectProduct
)

export const getAllVulnerableQuestions = createSelector(
  getFnaConfiguration,
  (fnaConfig: FNAConfiguration): VulnerableQuestion[] => fnaConfig.newVulnerableQuestions
)

export const getORDVulnerableQuestions = createSelector(
  getFnaConfiguration,
  (fnaConfig: FNAConfiguration): VulnerableQuestion[] => fnaConfig.ordVulnerableQuestions
)

export const getILPVulnerableQuestions = createSelector(
  getFnaConfiguration,
  (fnaConfig: FNAConfiguration): VulnerableQuestion[] => fnaConfig.ilpVulnerableQuestions
)

export const getSelectedVulnerableQuestions = createSelector(getFna, get('vulnerableQuestions'))

export const getVenerableQuestions = createSelector(getAllVulnerableQuestions, (allQuestions) => {
  return allQuestions.filter((item: VulnerableQuestion) => item.venerable)
})

export const getNonVenerableQuestions = createSelector(getAllVulnerableQuestions, (allQuestions) => {
  return allQuestions.filter((item: VulnerableQuestion) => !item.venerable)
})

export const getORDNonVenerableQuestions = createSelector(getORDVulnerableQuestions, (allQuestions) => {
  return allQuestions.filter((item: VulnerableQuestion) => !item.venerable)
})

export const getILPNonVenerableQuestions = createSelector(getILPVulnerableQuestions, (allQuestions) => {
  return allQuestions.filter((item: VulnerableQuestion) => !item.venerable)
})

export const getSellingMode = createSelector(getFna, get('sellingMode'))
export const getBuyWithAdvisor = createSelector(getFna, get('buyWithAdvisor'))
export const getIsSocial = createSelector(getFna, get('socialMediaC'))
