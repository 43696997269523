// @flow
import { connect } from 'react-redux'
import { getSelectedDisplayProductCode } from 'quick-quote/product-selection/selectors'
import type { AppState } from 'quick-quote/reducers'
import RpulBasicPlanCalculation from './rpul-basic-plan-calculation'
import SpulBasicPlanCalculation from './spul-basic-plan-calculation'
import RPUDRBasicPlanCalculation from './rpudr-basic-plan-calculation'

type BasicPlanCalculationProps = {
  selectedProductCode: string,
}

export const BasicPlanCalculation = ({ selectedProductCode }: BasicPlanCalculationProps) => {
  if (selectedProductCode === 'RPUL') {
    return <RpulBasicPlanCalculation />
  } else if (selectedProductCode === 'SPUL') {
    return <SpulBasicPlanCalculation />
  } else if (selectedProductCode === 'RPUDR') {
    return <RPUDRBasicPlanCalculation />
  } else {
    return null
  }
}

const mapStateToProps = (state: AppState): * => ({
  selectedProductCode: getSelectedDisplayProductCode(state),
})

export default connect(mapStateToProps)(BasicPlanCalculation)
