// @flow
import { formatNumber } from 'core/service/lib/number-format'
import type { RiderPlan } from 'core/data-model/rider'
import BIMESSAGES from 'core/data-model/constants/bi-messages'
import { isIRetireProduct, isLifeRetireProduct } from 'core/service/basic-plan/benefit'

type SummaryRowProps = {
  id: string,
  productCode: string,
  productName: string,
  asterisks?: string,
  coverageYear: number | string,
  paymentPeriod: number | string,
  selectedPlan: RiderPlan,
  sumAssured: number | string,
  showSumAssuredDecimalPlaces: boolean,
  premium: number,
}

const SummaryRow = ({
  id,
  productCode,
  productName,
  asterisks = '',
  coverageYear,
  paymentPeriod,
  selectedPlan,
  sumAssured,
  showSumAssuredDecimalPlaces,
  premium,
}: SummaryRowProps) => {
  const displayProductName =
    productCode === '25PL' || productCode === '12PL'
      ? `${productName} ${asterisks}`
      : `${productName} (${productCode})${asterisks}`

  return isIRetireProduct(productCode) || isLifeRetireProduct(productCode) ? (
    <tr id={`summary-row-${id}`}>
      <td>{displayProductName}</td>
      <td>{BIMESSAGES.COVERAGE_PERIOD_RETIREMENT_SUFFIX}</td>
      <td>{paymentPeriod}</td>
      <td>{sumAssured}</td>
      <td>{formatNumber(premium, 2)}</td>
    </tr>
  ) : (
    <tr id={`summary-row-${id}`}>
      <td>{displayProductName}</td>
      <td>{coverageYear}</td>
      <td>{paymentPeriod}</td>
      <td>{sumAssured}</td>
      <td>{formatNumber(premium, 2)}</td>
    </tr>
  )
}

export default SummaryRow
