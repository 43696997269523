// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import NumberFormat from 'react-number-format'
import { Input } from 'reactstrap'

import { getLoanAmount } from 'quick-quote/product-loan/coverage-plan/redux'
import { editLoanAmount } from 'quick-quote/product-mrta/actions'

type LoanAmountProps = {
  isReadOnly: boolean,
  enabled: boolean,
  loanAmount: number,
  editLoanAmount: (?number) => void,
}

const LoanAmount = ({ isReadOnly = false, enabled = true, loanAmount, editLoanAmount }: LoanAmountProps) => {
  if (enabled) {
    return (
      <NumberFormat
        id="loan-amount-input"
        disabled={Boolean(isReadOnly)}
        isAllowed={({ formattedValue }) => formattedValue.length <= 17}
        customInput={Input}
        value={loanAmount}
        thousandSeparator={true}
        allowNegative={false}
        type="tel"
        pattern="[0-9]*"
        onFocus={(e) => e.target.setSelectionRange(0, -1)}
        onChange={(e, values) => editLoanAmount(Number(values.value))}
      />
    )
  } else {
    return <Input id="loan-amount-input" value="-" disabled={true} />
  }
}

const mapStateToProps = (state: AppState) => ({
  loanAmount: getLoanAmount(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  editLoanAmount: bindActionCreators(editLoanAmount, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoanAmount)
