// @flow
import _ from 'lodash'
import ProfileSnapshot from 'quick-quote/product-common/benefit-illustration/components/profile-summary/profile-snapshot'
import { LegalConditionDisclaimerMarketConduct } from 'quick-quote/product-flexi-health/benefit-illustration/components/flexi-health-legal-disclaimer'
import ProductBenefits from './product-benefits/product-benefits'
import PolicyValueTable from './policy-value/policy-value-table'
import InvestmentBenefit from './investment-benefit/investment-benefit'
import RiderBenefits from 'quick-quote/product-common/benefit-illustration/components/rider-benefits'
import { SidebarMenu } from 'quick-quote/components'
import { isNil } from 'core/service/lib/type-check'
import { FormFeedback, FormGroup } from 'reactstrap'
import { Redirect } from 'react-router-dom'
import PATH from 'quick-quote/constants/path'
import MESSAGES from 'core/data-model/constants/validation-messages'
import BIMESSAGES from 'core/data-model/constants/bi-messages'
import TaxConsentDeductionBenefit from 'quick-quote/product-common/benefit-illustration/components/tax-consent-deduction-benefit'
import type { DisplayProduct } from 'core/service/display-product'
import type { SumAssured$, BasicPremium$ } from 'core/data-model/basic-plan'
import type { PolicyValue, BenefitSummary as BenefitSummaryType, TaxDeduction } from 'core/service/benefit-illustration'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { Age } from 'core/data-model/insured'

export const summaryHeaderWithCode = ({ displayProduct, riders }: *) => {
  const name = _.get(displayProduct, 'displayName', '')
  const riderPlanCode = _.get(displayProduct, 'basicPlanCode', '')

  return `${name} (${riderPlanCode})`
}

const summaryHeader = ({ displayProduct }) => (displayProduct ? displayProduct.nameThai : '')

export type BenefitIllustrationProps = {
  displayProduct: ?DisplayProduct,
  riders: (Rider & RiderState)[],
  genderLabel: string,
  age: Age,
  calculatedCoveragePeriod: number,
  calculatedPaymentPeriod: number,
  sumAssured$: SumAssured$,
  basicPremium$: BasicPremium$,
  totalPremium: number,
  totalRiderPremium: number,
  selectedModelFactorLabel: string,
  selectedModelFactorPeriod: number,
  hasNonLevelRider: boolean,
  hasShowDisclaimerRiderAsterisk: boolean,
  coveragePlanStartDate: Date,
  legalDisclaimerEndDate: Date,
  policyValue: PolicyValue,
  benefitSummary: BenefitSummaryType,
  canProceedToBenefitIllustration: boolean,
  isBlockedPdfGeneration: boolean,
  isBlockedEnteringESub: boolean,
  taxDeduction: TaxDeduction,
  isBancAgent: boolean,
}

const BenefitIllustration = ({
  displayProduct,
  riders,
  genderLabel,
  age,
  calculatedCoveragePeriod,
  calculatedPaymentPeriod,
  sumAssured$,
  basicPremium$,
  totalPremium,
  totalRiderPremium,
  selectedModelFactorLabel,
  hasNonLevelRider,
  hasShowDisclaimerRiderAsterisk,
  coveragePlanStartDate,
  legalDisclaimerEndDate,
  policyValue,
  benefitSummary,
  canProceedToBenefitIllustration,
  isBlockedPdfGeneration,
  isBlockedEnteringESub,
  taxDeduction,
  isBancAgent,
  isReviseQQ,
}: BenefitIllustrationProps) => {
  if (!canProceedToBenefitIllustration) {
    return <Redirect to={PATH.PRODUCT_SELECTION} push />
  } else if (isNil(displayProduct)) return <div id="benefit-illustration-invalid" />
  else {
    return (
      <div id="benefit-illustration">
        <div className="content">
          <ProfileSnapshot
            displayProduct={displayProduct}
            riders={riders}
            genderLabel={genderLabel}
            age={age}
            calculatedCoveragePeriod={calculatedCoveragePeriod}
            calculatedPaymentPeriod={calculatedPaymentPeriod}
            policyValue={policyValue}
            sumAssured={sumAssured$.value}
            basicPremium={basicPremium$.value}
            totalPremium={totalPremium}
            totalRiderPremium={totalRiderPremium}
            selectedModelFactorLabel={selectedModelFactorLabel}
            hasNonLevelRider={hasNonLevelRider}
            hasShowDisclaimerRiderAsterisk={hasShowDisclaimerRiderAsterisk}
            profileSummaryHeader={summaryHeaderWithCode}
            summaryTableHeader={summaryHeader}
            isReviseQQ={isReviseQQ}
          />

          <ProductBenefits
            displayProduct={displayProduct}
            sumAssured={sumAssured$.value}
            basicPremium={basicPremium$.value}
            calculatedPaymentPeriod={calculatedPaymentPeriod}
            calculatedCoveragePeriod={calculatedCoveragePeriod}
            policyValue={policyValue}
            benefitSummaryHeader={({ displayProduct }) => displayProduct.nameThai}
          />

          {riders.length > 0 && <RiderBenefits />}

          <PolicyValueTable policyValue={policyValue} basicPlan={displayProduct} />

          <InvestmentBenefit
            displayProduct={displayProduct}
            coveragePlanStartDate={coveragePlanStartDate}
            legalDisclaimerEndDate={legalDisclaimerEndDate}
          />

          <TaxConsentDeductionBenefit taxDeduction={taxDeduction} />

          <LegalConditionDisclaimerMarketConduct
            data-testid="legal-condition-disclaimer-market-conduct"
            startDate={coveragePlanStartDate}
            endDate={legalDisclaimerEndDate}
            isBancAgent={isBancAgent}
          />

          {isBlockedEnteringESub ? (
            <FormGroup className="has-warning">
              <FormFeedback className={'eligibility-message attached-icon'}>
                <div> {MESSAGES.RULE_ENTER_ESUB_PREREQUISITE} </div>
              </FormFeedback>
            </FormGroup>
          ) : (
            ''
          )}
        </div>
        <SidebarMenu
          mainMenu={[
            {
              text: BIMESSAGES.SUMMARY_OF_MAIN_AND_ADDITIONAL_CONTRACTS,
              id: 'profile-summary',
              isSub: false,
            },
            {
              text: 'รายละเอียดของสัญญาหลัก',
              id: 'product-benefits',
              isSub: false,
            },
            {
              text: BIMESSAGES.POLICY_VALUE_TABLE,
              id: 'policy-value',
              isSub: false,
            },
            {
              text: BIMESSAGES.INVESTMENT_BENEFITS,
              id: 'investment-benefit',
              isSub: false,
            },
            {
              text: BIMESSAGES.LEGAL_DISCLAIMER,
              id: 'legal-disclaimer-market-conduct',
              isSub: false,
            },
          ]}
        />
      </div>
    )
  }
}

export default BenefitIllustration
