// // @flow
// import _ from 'lodash'
// import { formatNumber } from 'core/service/lib/number-format'
// import type { InvestmentBenefitTable, InvestmentBenefit } from 'core/service/benefit-illustration/investment-benefit'
import type { BenefitSummaryTableProps } from 'quick-quote/v2/products/customs/12pl/components/benefit-summary-table/container'
import type { DisplayProduct } from 'core/service/display-product/types'
import MESSAGES from 'core/data-model/constants/bi-messages'

type DisclaimerProps = {
  startDate: string,
  endDate: string,
  basicPlan: DisplayProduct,
}

export const buildDisclaimer = ({ startDate, endDate, basicPlan }: DisclaimerProps) => [
  {
    stack: [`${MESSAGES.NOTE}: ${MESSAGES.TWELVE_PL_BENEFIT_DISCLAIMER}`],
    style: 'disclaimer',
  },
  {
    text: ' ',
  },
  {
    text: ' ',
  },
]

const buildHeaderBenefitSummaryTable = () => {
  return [
    [
      {
        text: MESSAGES.INVESTMENT_BENEFITS_SUMMARY,
        rowSpan: 3,
        style: 'tableHeader',
        margin: [0, 25],
      },
      {
        text: MESSAGES.INVESTMENT_BENEFITS_GUARANTEE,
        rowSpan: 3,
        style: 'tableHeader',
        margin: [0, 25],
      },
      {
        text: MESSAGES.TWELVE_PL_BENEFIT_SUMMARY_SCENARIO,
        colSpan: 3,
        style: 'tableHeader',
      },
      { text: '' },
      { text: '' },
    ],
    [
      { text: '' },
      { text: '' },
      {
        text: MESSAGES.EXAMPLE_1,
        rowSpan: 2,
        style: 'tableHeader',
      },
      {
        text: MESSAGES.EXAMPLE_2,
        rowSpan: 2,
        style: 'tableHeader',
      },
      {
        text: MESSAGES.EXAMPLE_3,
        rowSpan: 2,
        style: 'tableHeader',
      },
    ],
    [{ text: '' }, { text: '' }, { text: '' }, { text: '' }, { text: '' }],
  ]
}

// const rowHeightConfig = { lineHeight: 0.75, margin: [0, -3, 0, 0] }

export const buildBenefitSummaryTable = ({
  lastGuaranteeDividend,
  totalGuaranteeDividend,
  totalGuaranteeDividendBeforeLastYear,
  nonGuaranteeDividendBeforeDueDate,
  totalNonGuaranteeDividend,
}: BenefitSummaryTableProps) => {
  return [
    {
      table: {
        headerRows: 1,
        widths: [200, 'auto', 'auto', 'auto', 'auto'],
        body: [
          ...buildHeaderBenefitSummaryTable(),
          [
            { text: MESSAGES.INVESTMENT_BENEFITS_GUARANTEE_CASHDROP },
            { text: totalGuaranteeDividendBeforeLastYear, alignment: 'right' },
            { text: totalGuaranteeDividendBeforeLastYear, alignment: 'right' },
            { text: totalGuaranteeDividendBeforeLastYear, alignment: 'right' },
            { text: totalGuaranteeDividendBeforeLastYear, alignment: 'right' },
          ],
          [
            { text: MESSAGES.INVESTMENT_BENEFITS_GUARANTEE_MATURITY },
            { text: lastGuaranteeDividend, alignment: 'right' },
            { text: lastGuaranteeDividend, alignment: 'right' },
            { text: lastGuaranteeDividend, alignment: 'right' },
            { text: lastGuaranteeDividend, alignment: 'right' },
          ],
          [
            { text: MESSAGES.INVESTMENT_BENEFITS_GUARANTEE_TOTAL },
            { text: totalGuaranteeDividend, alignment: 'right' },
            { text: totalGuaranteeDividend, alignment: 'right' },
            { text: totalGuaranteeDividend, alignment: 'right' },
            { text: totalGuaranteeDividend, alignment: 'right' },
          ],
          [
            { text: MESSAGES.TWELVE_PL_BENEFIT_NONGUARANTEE_MATURITY },
            { text: nonGuaranteeDividendBeforeDueDate.interestDeposit, alignment: 'right' },
            { text: nonGuaranteeDividendBeforeDueDate.lowInterestDeposit, alignment: 'right' },
            { text: nonGuaranteeDividendBeforeDueDate.midInterestDeposit, alignment: 'right' },
            { text: nonGuaranteeDividendBeforeDueDate.highInterestDeposit, alignment: 'right' },
          ],
          [
            { text: MESSAGES.TWELVE_PL_BENEFIT_NONGUARANTEE_TOTAL },
            { text: totalNonGuaranteeDividend.totalInterestDeposit, alignment: 'right' },
            { text: totalNonGuaranteeDividend.lowInterestDeposit, alignment: 'right' },
            { text: totalNonGuaranteeDividend.midInterestDeposit, alignment: 'right' },
            { text: totalNonGuaranteeDividend.highInterestDeposit, alignment: 'right' },
          ],
        ],
      },
      layout: 'lightBorder',
      style: 'table',
    },
  ]
}

export type benefitSummaryTableSectionProps = {
  benefitSummaryTable: BenefitSummaryTableProps,
  startDate: string,
  endDate: string,
  basicPlan: DisplayProduct,
}

export const benefitSummarySection = ({
  benefitSummaryTable,
  startDate,
  endDate,
  basicPlan,
}: benefitSummaryTableSectionProps) => [
  { text: MESSAGES.INVESTMENT_BENEFITS, style: 'title', pageBreak: 'before' },
  ...buildBenefitSummaryTable(benefitSummaryTable),
  buildDisclaimer({ startDate, endDate, basicPlan }),
]
