// @flow
import VALUES from 'core/data-model/constants/values'
import MESSAGES from 'core/data-model/constants/bi-messages'
import { PolicyValueRowMRTA, PolicyValueRowGLTSP } from './policy-value-row'

import type { PolicyValueTableProps } from 'core/service/benefit-illustration/policy-value'

const PolicyValueTable = (props: PolicyValueTableProps) => {
  return (
    <div className="policy group" id="policy-value">
      <h3>{MESSAGES.POLICY_VALUE_TABLE}</h3>
      {renderPolicyValueTable(props)}
    </div>
  )
}

const renderPolicyValueTable = ({ policyValue, basicPlan }: PolicyValueTableProps) => {
  return (
    <div>
      <div className="table-wrap">
        <PolicyValueContent basicPlan={basicPlan} {...policyValue} />
      </div>
      <div className="disclaimer">
        <p>{MESSAGES.REMARK_POLICY_TABLE_MRTA}</p>
      </div>
    </div>
  )
}

const PolicyValueContent = ({ basicPlan, ...policyValue }) => {
  return basicPlan.basicPlanCode !== VALUES.GLTSP_CODE.GLTSP ? (
    <PolicyMRTA {...policyValue} />
  ) : (
    <PolicyGLTSP {...policyValue} />
  )
}

const PolicyMRTA = ({ yearEndPolicyValues }) => (
  <PolicyTable className="policy-center-table">
    <thead>
      <tr>
        <td rowSpan={2}>{MESSAGES.GIO_END_OF_POLICY}</td>
        <td colSpan={2}>{MESSAGES.TABLE_HEADER_POLICY_COVERAGE}</td>
        <td rowSpan={2}>{MESSAGES.TABLE_HEADER_POLICY_TOTAL_SURRENDER}</td>
      </tr>
      <tr>
        <td>{MESSAGES.BASIC_PLAN}</td>
        <td style={{ borderRight: '1px solid #e5e5e5' }}>{MESSAGES.TABLE_HEADER_POLICY_RIDER_ACCIDENT}</td>
      </tr>
    </thead>
    <tbody>
      {yearEndPolicyValues.map((policyValue) => (
        <PolicyValueRowMRTA
          key={`policy-value-row-${policyValue.yearEndPolicy}`}
          yearEndPolicy={policyValue.yearEndPolicy}
          basicSumAssured={policyValue.basicSumAssured}
          riderSumAssured={policyValue.riderSumAssured}
          surrenderCash={policyValue.surrenderCash}
        ></PolicyValueRowMRTA>
      ))}
    </tbody>
  </PolicyTable>
)

const PolicyGLTSP = ({ yearEndPolicyValues }) => (
  <PolicyTable className="policy-center-table">
    <thead>
      <tr>
        <td>{MESSAGES.GIO_END_OF_POLICY}</td>
        <td>{MESSAGES.TABLE_HEADER_POLICY_COVERAGE_SUMASSURED}</td>
        <td>{MESSAGES.TABLE_HEADER_POLICY_SURRENDER}</td>
      </tr>
    </thead>
    <tbody>
      {yearEndPolicyValues.map((policyValue) => (
        <PolicyValueRowGLTSP
          key={`policy-value-row-${policyValue.yearEndPolicy}`}
          yearEndPolicy={policyValue.yearEndPolicy}
          basicSumAssured={policyValue.basicSumAssured}
          surrenderCash={policyValue.surrenderCash}
        ></PolicyValueRowGLTSP>
      ))}
    </tbody>
  </PolicyTable>
)

const PolicyTable = ({ className, children }: { className?: string, children?: React.Node }) => (
  <table className={`policy-table${className ? ` ${className}` : ''}`} id="policy-value-table">
    {children}
  </table>
)

export default PolicyValueTable
