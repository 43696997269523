// @flow

import type { Option } from 'common-components/dropdown'
import type { DisplayProduct } from 'core/service/display-product/types'
import type { ProductPlan } from 'core/data-model/basic-plan/types'
import type { AgentType } from 'core/data-model/identity/types'

import { flow, get, filter, keyBy, includes, map } from 'lodash/fp'
import { createSelector } from 'reselect'
import { getDisplayProducts, getSelectedDisplayProduct } from 'quick-quote/product-selection/selectors'
import { getUserAgentType } from 'identity/selectors'

import VALUES from 'core/data-model/constants/values'

export const getAllowForProductSavingCoveragePlan = createSelector(
  getSelectedDisplayProduct,
  (selectedProduct: DisplayProduct): boolean => {
    const code = get('code')(selectedProduct)
    const category = get('category')(selectedProduct)
    return category === VALUES.SAVING && code !== ''
  }
)

export const getIGrowProduct = createSelector(
  getDisplayProducts,
  (products: DisplayProduct[]): DisplayProduct => keyBy('code')(products)[VALUES.IGROW]
)

export const getAllProductSavingPlan = createSelector(
  getSelectedDisplayProduct,
  getUserAgentType,
  (product: DisplayProduct, userAgentType: AgentType): ProductPlan[] => {
    return flow(
      get('productPlans.values'),
      filter((plan) => flow(get('allowUserType'), includes(userAgentType))(plan))
    )(product)
  }
)

export const getBasicPlanOptions = createSelector(
  getSelectedDisplayProduct,
  getUserAgentType,
  (product: DisplayProduct, userAgentType: AgentType): Option[] => {
    return flow(
      get('productPlans.values'),
      filter((plan) => flow(get('allowUserType'), includes(userAgentType))(plan)),
      map((p) => ({
        value: p.plan,
        text: p.name,
      }))
    )(product)
  }
)
