//@flow
import MESSAGES from 'core/data-model/constants/bi-messages'
import { paymentChannelsSubSectionOrdinary } from '../../common/sections/payment-channels-sub-section-ordinary'

import type { DisplayProduct } from 'core/service/display-product'

export const _paymentChannelsSection = async (basicPlan: DisplayProduct) => {
  const paymentChannels = await paymentChannelsSubSectionOrdinary(basicPlan)
  return {
    stack: [
      {
        text: MESSAGES.PAYMENT_CHANNELS,
        style: 'title',
        marginTop: -6,
        pageBreak: 'before',
      },
      ...paymentChannels,
    ],
  }
}
export const paymentChannelsSection = async (basicPlan: DisplayProduct) => {
  return _paymentChannelsSection(basicPlan)
}
