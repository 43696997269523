//@flow
/* istanbul ignore file */
// ignore coverage test because this is configuration
import { ConditionalRoute } from 'quick-quote/components/navigations/utils'
import { NeedAndGapAnalysisContainer } from 'quick-quote/fna'
import {
  canProceedToFundAllocation,
  canProceedToFundAndCoveragePlan,
  canProceedToRiskQuestionnaire,
} from 'quick-quote/product-investment/journey-control-selectors'
import InvestmentBenefitillustrationContainer from 'quick-quote/v2/products/categories/investment/benefit-illustration'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { compose, lifecycle } from 'recompose'
import { PATH, ROUTE_PATH } from '../../../constants/path'
import { MarketConductsContainer } from '../../../fna/market-conducts'
import {
  FlexiHealthInsuredInformationContainer,
  HealthInsuredInformationContainer,
  HealthToppingInsuredInformationContainer,
  InvestmentInsuredInformationContainer,
  LifeSaveProInsuredInformationContainer,
  LoanInsuredInformationContainer,
  PerfectLifeInsuredInformationContainer,
  SukhapabDekDeeInsuredInformationContainer,
  ProtectionInsuredInformationContainer,
  RetirementInsuredInformationContainer,
  SavingInsuredInformationContainer,
  WholeLifeInsuredInformationContainer,
  OrdinaryInsuredInformationContainer,
  LifeReadyInsuredInformationContainer,
  MRTAInsuredInformationContainer,
} from '../../../insured-information'
import { PortalContainer } from '../../../portal'
import {
  BenefitIllustrationContainer as HealthToppingBenefitIllustrationContainer,
  BenefitIllustrationContainer as FlexiHealthBenefitIllustrationContainer,
} from '../../../product-flexi-health/benefit-illustration'
import { CoveragePlanContainer as FlexiHealthCoveragePlanContainer } from '../../../product-flexi-health/coverage-plan'
import { CoveragePlanContainer as HealthToppingCoveragePlanContainer } from '../../../product-health-topping/coverage-plan'
import { BenefitIllustrationContainer as HealthBenefitIllustrationContainer } from '../../../product-health/benefit-illustration'
import { CoveragePlanContainer as HealthCoveragePlanContainer } from '../../../product-health/coverage-plan'
import { InvestmentCoveragePlanContainer } from '../../../product-investment/coverage-plan'
import LstuFundAllocationContainer from '../../../product-investment/fund-allocation/lstu/container'
import FundAllocationContainer from '../../../product-investment/fund-allocation/rpp/containers'
import { RiskContainer } from '../../../product-investment/risk-questionnaire'
import { BenefitIllustrationContainer as LifeSaveProBenefitIllustrationContainer } from '../../../product-life-save-pro/benefit-illustration'
import { CoveragePlanContainer as LifeSaveProCoveragePlanContainer } from '../../../product-life-save-pro/coverage-plan'
import { LoanBenefitIllustrationContainer } from '../../../product-loan/benefit-illustration'
import { LoanCoveragePlanContainer } from '../../../product-loan/coverage-plan'
import { CoveragePlanContainer as PerfectLifeCoveragePlanContainer } from '../../../product-perfect-life/coverage-plan'
import { CoveragePlanContainer as SukhapabDekDeeCoveragePlanContainer } from '../../../product-sukhapabdekdee/coverage-plan'
import { BenefitIllustrationContainer as ProtectionBenefitIllustrationContainer } from '../../../product-protection/benefit-illustration'
import { CoveragePlanContainer as ProtectionCoveragePlanContainer } from '../../../product-protection/coverage-plan'
import { CoveragePlanContainer as RetirementCoveragePlanContainer } from '../../../product-retirement/coverage-plan'
import { CoveragePlanContainer as BumnanReadyCoveragePlanContainer } from '../../../product-bumnan-ready/coverage-plan'
import { CoveragePlanContainer as TermLifeCoveragePlanContainer } from '../../../product-term-life/coverage-plan'
import { BenefitIllustrationContainer as SavingBenefitIllustrationContainer } from '../../../product-saving/benefit-illustration'
import { CoveragePlanContainer as SavingCoveragePlanContainer } from '../../../product-saving/coverage-plan'
import { BasicPlanSelectionContainer } from '../../../product-selection'
import { CoveragePlanContainer as WholeLifeCoveragePlanContainer } from '../../../product-whole-life/coverage-plan'
import { BenefitIllustrationContainer as PerfectLifeBenefitIllustrationContainer } from '../../../v2/products/categories/perfect-life/benefit-illustration'
import { BenefitIllustrationContainer as SukhapabDekDeeBenefitIllustrationContainer } from '../../../v2/products/categories/sukhapabdekdee/benefit-illustration'
import { BenefitIllustrationContainer as RetirementBenefitIllustrationContainer } from '../../../v2/products/categories/retirement/benefit-illustration'
import { BenefitIllustrationContainer as WholeLifeBenefitIllustrationContainer } from '../../../v2/products/categories/whole-life/benefit-illustration'
import { BenefitIllustrationContainer as TermLifeBenefitIllustrationContainer } from '../../../product-term-life/benefit-illustration'
import { BenefitIllustrationContainer as MRTABenefitIllustrationContainer } from '../../../product-mrta/benefit-illustration'
import { BenefitIllustrationContainer as PersonalAccidentBenefitIllustrationContainer } from '../../../product-pa/benefit-illustration'
import { CoveragePlanContainer as IHealthyUltraCoveragePlanContainer } from '../../../product-ihealthy-ultra/coverage-plan'
import { BenefitIllustrationContainer as IHealthyUltraBenefitIllustrationContainer } from '../../../product-ihealthy-ultra/benefit-illustration'

import { CoveragePlanContainer as LifeReadyCoveragePlanContainer } from '../../../product-life-ready/coverage-plan'
import { CoveragePlanContainer as MRTACoveragePlanContainer } from '../../../product-mrta/coverage-plan'
import { CoveragePlanContainer as PersonalAccidentCoveragePlanContainer } from '../../../product-pa/coverage-plan'

import { MaturityValidateContainer } from '../../../product-s7cc/maturity-validate'
import { SelectPolicyOwnerContainer } from '../../../product-s7cc/select-policy-owner'
import { getToggles } from '../../../feature-toggles'

const redirectToLandingPage = ({ history, location }) => {
  if (history.action === 'POP' && location.pathname.endsWith(ROUTE_PATH.WHOLE_LIFE.BENEFIT_ILLUSTRATION)) {
    history.replace(ROUTE_PATH.PRODUCT_SELECTION)
  }
}

const FNA = ({ logPageView, match }) => (
  <Switch>
    <Route path={`${match.path}${PATH.PRODUCT_SELECTION}`} component={logPageView(BasicPlanSelectionContainer)} />
    <Route path={`${match.path}${PATH.PORTAL}`} component={logPageView(PortalContainer)} />
    <Route path={`${match.path}${PATH.NEED_AND_GAP_ANALYSIS}`} component={logPageView(NeedAndGapAnalysisContainer)} />
    {getToggles().ENABLE_FNA_V3 && (
      <Route path={`${match.path}${PATH.MARKET_CONDUCTS}`} component={logPageView(MarketConductsContainer)} />
    )}
    {/* === whole life === */}
    <Route
      path={`${match.path}${ROUTE_PATH.WHOLE_LIFE.NEED_AND_GAP_ANALYSIS}`}
      component={logPageView(NeedAndGapAnalysisContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.WHOLE_LIFE.PRODUCT_SELECTION}`}
      component={logPageView(BasicPlanSelectionContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.WHOLE_LIFE.MARKET_CONDUCTS}`}
      component={logPageView(MarketConductsContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.WHOLE_LIFE.INSURED_INFORMATION}`}
      component={logPageView(WholeLifeInsuredInformationContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.WHOLE_LIFE.COVERAGE_PLAN}`}
      component={logPageView(WholeLifeCoveragePlanContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.WHOLE_LIFE.BENEFIT_ILLUSTRATION}`}
      component={WholeLifeBenefitIllustrationContainer}
    />
    {/* === s7cc === */}
    <Route
      path={`${match.path}${ROUTE_PATH.S7CC.NEED_AND_GAP_ANALYSIS}`}
      component={logPageView(NeedAndGapAnalysisContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.S7CC.PRODUCT_SELECTION}`}
      component={logPageView(BasicPlanSelectionContainer)}
    />
    <Route path={`${match.path}${ROUTE_PATH.S7CC.MARKET_CONDUCTS}`} component={logPageView(MarketConductsContainer)} />
    <Route
      path={`${match.path}${ROUTE_PATH.S7CC.MATURITY_VALIDATE}`}
      component={logPageView(MaturityValidateContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.S7CC.SELECT_POLICY_OWNER}`}
      component={logPageView(SelectPolicyOwnerContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.S7CC.INSURED_INFORMATION}`}
      component={logPageView(OrdinaryInsuredInformationContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.S7CC.COVERAGE_PLAN}`}
      component={logPageView(WholeLifeCoveragePlanContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.S7CC.BENEFIT_ILLUSTRATION}`}
      component={WholeLifeBenefitIllustrationContainer}
    />
    {/* === term life === */}
    <Route
      path={`${match.path}${ROUTE_PATH.TERM_LIFE.NEED_AND_GAP_ANALYSIS}`}
      component={logPageView(NeedAndGapAnalysisContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.TERM_LIFE.PRODUCT_SELECTION}`}
      component={logPageView(BasicPlanSelectionContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.TERM_LIFE.MARKET_CONDUCTS}`}
      component={logPageView(MarketConductsContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.TERM_LIFE.INSURED_INFORMATION}`}
      component={logPageView(WholeLifeInsuredInformationContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.TERM_LIFE.COVERAGE_PLAN}`}
      component={logPageView(TermLifeCoveragePlanContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.TERM_LIFE.BENEFIT_ILLUSTRATION}`}
      component={TermLifeBenefitIllustrationContainer}
    />
    {/* === protection === */}
    <Route
      path={`${match.path}${ROUTE_PATH.PROTECTION.NEED_AND_GAP_ANALYSIS}`}
      component={logPageView(NeedAndGapAnalysisContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.PROTECTION.PRODUCT_SELECTION}`}
      component={logPageView(BasicPlanSelectionContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.PROTECTION.MARKET_CONDUCTS}`}
      component={logPageView(MarketConductsContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.PROTECTION.INSURED_INFORMATION}`}
      component={logPageView(ProtectionInsuredInformationContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.PROTECTION.COVERAGE_PLAN}`}
      component={logPageView(ProtectionCoveragePlanContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.PROTECTION.BENEFIT_ILLUSTRATION}`}
      component={ProtectionBenefitIllustrationContainer}
    />
    {/* === health === */}

    <Route
      path={`${match.path}${ROUTE_PATH.HEALTH.NEED_AND_GAP_ANALYSIS}`}
      component={logPageView(NeedAndGapAnalysisContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.HEALTH.PRODUCT_SELECTION}`}
      component={logPageView(BasicPlanSelectionContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.HEALTH.MARKET_CONDUCTS}`}
      component={logPageView(MarketConductsContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.HEALTH.INSURED_INFORMATION}`}
      component={logPageView(HealthInsuredInformationContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.HEALTH.COVERAGE_PLAN}`}
      component={logPageView(HealthCoveragePlanContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.HEALTH.BENEFIT_ILLUSTRATION}`}
      component={HealthBenefitIllustrationContainer}
    />

    {/* === health ultra === */}

    <Route
      path={`${match.path}${ROUTE_PATH.IHEALTHY_ULTRA.NEED_AND_GAP_ANALYSIS}`}
      component={logPageView(NeedAndGapAnalysisContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.IHEALTHY_ULTRA.PRODUCT_SELECTION}`}
      component={logPageView(BasicPlanSelectionContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.IHEALTHY_ULTRA.MARKET_CONDUCTS}`}
      component={logPageView(MarketConductsContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.IHEALTHY_ULTRA.INSURED_INFORMATION}`}
      // component={logPageView(IHealthyUltraInsuredInformationContainer)}
      component={logPageView(OrdinaryInsuredInformationContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.IHEALTHY_ULTRA.COVERAGE_PLAN}`}
      component={logPageView(IHealthyUltraCoveragePlanContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.IHEALTHY_ULTRA.BENEFIT_ILLUSTRATION}`}
      component={IHealthyUltraBenefitIllustrationContainer}
    />

    {/* === investment === */}
    <Route
      path={`${match.path}${ROUTE_PATH.INVESTMENT.NEED_AND_GAP_ANALYSIS}`}
      component={logPageView(NeedAndGapAnalysisContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.INVESTMENT.PRODUCT_SELECTION}`}
      component={logPageView(BasicPlanSelectionContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.INVESTMENT.MARKET_CONDUCTS}`}
      component={logPageView(MarketConductsContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.INVESTMENT.INSURED_INFORMATION}`}
      component={logPageView(InvestmentInsuredInformationContainer)}
    />
    <Route path={`${match.path}${ROUTE_PATH.INVESTMENT.RISK_QUESTIONNAIRE}`} component={logPageView(RiskContainer)} />
    <ConditionalRoute
      path={`${match.path}${ROUTE_PATH.INVESTMENT.COVERAGE_PLAN}`}
      component={logPageView(InvestmentCoveragePlanContainer)}
      conditions={[canProceedToRiskQuestionnaire]}
      redirect={<Redirect to={ROUTE_PATH.PORTAL} />}
    />
    <ConditionalRoute
      path={`${match.path}${ROUTE_PATH.INVESTMENT.FUND_ALLOCATION}`}
      component={logPageView(FundAllocationContainer)}
      conditions={[canProceedToFundAndCoveragePlan]}
      redirect={<Redirect to={ROUTE_PATH.PORTAL} />}
    />
    <ConditionalRoute
      path={`${match.path}${ROUTE_PATH.INVESTMENT.LSTU_FUND_ALLOCATION}`}
      component={logPageView(LstuFundAllocationContainer)}
      conditions={[canProceedToFundAllocation]}
      redirect={<Redirect to={ROUTE_PATH.PORTAL} />}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.INVESTMENT.BENEFIT_ILLUSTRATION}`}
      component={logPageView(InvestmentBenefitillustrationContainer)}
    />
    {/* === loan === */}
    <Route
      path={`${match.path}${ROUTE_PATH.LOAN.NEED_AND_GAP_ANALYSIS}`}
      component={logPageView(NeedAndGapAnalysisContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.LOAN.PRODUCT_SELECTION}`}
      component={logPageView(BasicPlanSelectionContainer)}
    />
    <Route path={`${match.path}${ROUTE_PATH.LOAN.MARKET_CONDUCTS}`} component={logPageView(MarketConductsContainer)} />
    <Route
      path={`${match.path}${ROUTE_PATH.LOAN.INSURED_INFORMATION}`}
      component={logPageView(LoanInsuredInformationContainer)}
    />
    <Route path={`${match.path}${ROUTE_PATH.LOAN.COVERAGE_PLAN}`} component={logPageView(LoanCoveragePlanContainer)} />
    <Route path={`${match.path}${ROUTE_PATH.LOAN.BENEFIT_ILLUSTRATION}`} component={LoanBenefitIllustrationContainer} />
    {/* === saving === */}
    <Route
      path={`${match.path}${ROUTE_PATH.SAVING.NEED_AND_GAP_ANALYSIS}`}
      component={logPageView(NeedAndGapAnalysisContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.SAVING.PRODUCT_SELECTION}`}
      component={logPageView(BasicPlanSelectionContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.SAVING.MARKET_CONDUCTS}`}
      component={logPageView(MarketConductsContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.SAVING.INSURED_INFORMATION}`}
      component={logPageView(SavingInsuredInformationContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.SAVING.COVERAGE_PLAN}`}
      component={logPageView(SavingCoveragePlanContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.SAVING.BENEFIT_ILLUSTRATION}`}
      component={SavingBenefitIllustrationContainer}
    />
    {/* === life-save-pro === */}
    <Route
      path={`${match.path}${ROUTE_PATH.LIFE_SAVE_PRO.NEED_AND_GAP_ANALYSIS}`}
      component={logPageView(NeedAndGapAnalysisContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.LIFE_SAVE_PRO.PRODUCT_SELECTION}`}
      component={logPageView(BasicPlanSelectionContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.LIFE_SAVE_PRO.MARKET_CONDUCTS}`}
      component={logPageView(MarketConductsContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.LIFE_SAVE_PRO.INSURED_INFORMATION}`}
      component={logPageView(LifeSaveProInsuredInformationContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.LIFE_SAVE_PRO.COVERAGE_PLAN}`}
      component={logPageView(LifeSaveProCoveragePlanContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.LIFE_SAVE_PRO.BENEFIT_ILLUSTRATION}`}
      component={LifeSaveProBenefitIllustrationContainer}
    />
    {/* === flexi-health === */}
    <Route
      path={`${match.path}${ROUTE_PATH.FLEXI_HEALTH.NEED_AND_GAP_ANALYSIS}`}
      component={logPageView(NeedAndGapAnalysisContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.FLEXI_HEALTH.PRODUCT_SELECTION}`}
      component={logPageView(BasicPlanSelectionContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.FLEXI_HEALTH.MARKET_CONDUCTS}`}
      component={logPageView(MarketConductsContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.FLEXI_HEALTH.INSURED_INFORMATION}`}
      component={logPageView(FlexiHealthInsuredInformationContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.FLEXI_HEALTH.COVERAGE_PLAN}`}
      component={logPageView(FlexiHealthCoveragePlanContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.FLEXI_HEALTH.BENEFIT_ILLUSTRATION}`}
      component={FlexiHealthBenefitIllustrationContainer}
    />

    {/* === retirement === */}
    <Route
      path={`${match.path}${ROUTE_PATH.RETIREMENT.NEED_AND_GAP_ANALYSIS}`}
      component={logPageView(NeedAndGapAnalysisContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.RETIREMENT.PRODUCT_SELECTION}`}
      component={logPageView(BasicPlanSelectionContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.RETIREMENT.MARKET_CONDUCTS}`}
      component={logPageView(MarketConductsContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.RETIREMENT.INSURED_INFORMATION}`}
      component={logPageView(RetirementInsuredInformationContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.RETIREMENT.COVERAGE_PLAN}`}
      component={logPageView(RetirementCoveragePlanContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.RETIREMENT.BENEFIT_ILLUSTRATION}`}
      component={RetirementBenefitIllustrationContainer}
    />

    {/* === bumnan-ready === */}
    <Route
      path={`${match.path}${ROUTE_PATH.BUMNAN_READY.NEED_AND_GAP_ANALYSIS}`}
      component={logPageView(NeedAndGapAnalysisContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.BUMNAN_READY.PRODUCT_SELECTION}`}
      component={logPageView(BasicPlanSelectionContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.BUMNAN_READY.MARKET_CONDUCTS}`}
      component={logPageView(MarketConductsContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.BUMNAN_READY.INSURED_INFORMATION}`}
      component={logPageView(RetirementInsuredInformationContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.BUMNAN_READY.COVERAGE_PLAN}`}
      component={logPageView(BumnanReadyCoveragePlanContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.BUMNAN_READY.BENEFIT_ILLUSTRATION}`}
      component={RetirementBenefitIllustrationContainer}
    />

    {/* === perfect-life === */}
    <Route
      path={`${match.path}${ROUTE_PATH.PERFECT_LIFE.NEED_AND_GAP_ANALYSIS}`}
      component={logPageView(NeedAndGapAnalysisContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.PERFECT_LIFE.PRODUCT_SELECTION}`}
      component={logPageView(BasicPlanSelectionContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.PERFECT_LIFE.MARKET_CONDUCTS}`}
      component={logPageView(MarketConductsContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.PERFECT_LIFE.INSURED_INFORMATION}`}
      component={logPageView(PerfectLifeInsuredInformationContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.PERFECT_LIFE.COVERAGE_PLAN}`}
      component={logPageView(PerfectLifeCoveragePlanContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.PERFECT_LIFE.BENEFIT_ILLUSTRATION}`}
      component={PerfectLifeBenefitIllustrationContainer}
    />

    {/* === sukhapabdekdee === */}
    <Route
      path={`${match.path}${ROUTE_PATH.SUKHAPABDEKDEE.NEED_AND_GAP_ANALYSIS}`}
      component={logPageView(NeedAndGapAnalysisContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.SUKHAPABDEKDEE.MARKET_CONDUCTS}`}
      component={logPageView(MarketConductsContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.SUKHAPABDEKDEE.PRODUCT_SELECTION}`}
      component={logPageView(BasicPlanSelectionContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.SUKHAPABDEKDEE.INSURED_INFORMATION}`}
      component={logPageView(SukhapabDekDeeInsuredInformationContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.SUKHAPABDEKDEE.COVERAGE_PLAN}`}
      component={logPageView(SukhapabDekDeeCoveragePlanContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.SUKHAPABDEKDEE.BENEFIT_ILLUSTRATION}`}
      component={SukhapabDekDeeBenefitIllustrationContainer}
    />

    {/* === health-topping === */}
    <Route
      path={`${match.path}${ROUTE_PATH.HEALTH_TOPPING.NEED_AND_GAP_ANALYSIS}`}
      component={logPageView(NeedAndGapAnalysisContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.HEALTH_TOPPING.PRODUCT_SELECTION}`}
      component={logPageView(BasicPlanSelectionContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.HEALTH_TOPPING.MARKET_CONDUCTS}`}
      component={logPageView(MarketConductsContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.HEALTH_TOPPING.INSURED_INFORMATION}`}
      component={logPageView(HealthToppingInsuredInformationContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.HEALTH_TOPPING.COVERAGE_PLAN}`}
      component={logPageView(HealthToppingCoveragePlanContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.HEALTH_TOPPING.BENEFIT_ILLUSTRATION}`}
      component={HealthToppingBenefitIllustrationContainer}
    />

    {/* === life-ready === */}
    <Route
      path={`${match.path}${ROUTE_PATH.LIFE_READY.NEED_AND_GAP_ANALYSIS}`}
      component={logPageView(NeedAndGapAnalysisContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.LIFE_READY.PRODUCT_SELECTION}`}
      component={logPageView(BasicPlanSelectionContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.LIFE_READY.MARKET_CONDUCTS}`}
      component={logPageView(MarketConductsContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.LIFE_READY.INSURED_INFORMATION}`}
      component={logPageView(LifeReadyInsuredInformationContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.LIFE_READY.COVERAGE_PLAN}`}
      component={logPageView(LifeReadyCoveragePlanContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.LIFE_READY.BENEFIT_ILLUSTRATION}`}
      component={WholeLifeBenefitIllustrationContainer}
    />

    {/* === mrta === */}
    <Route
      path={`${match.path}${ROUTE_PATH.MRTA.NEED_AND_GAP_ANALYSIS}`}
      component={logPageView(NeedAndGapAnalysisContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.MRTA.PRODUCT_SELECTION}`}
      component={logPageView(BasicPlanSelectionContainer)}
    />
    <Route path={`${match.path}${ROUTE_PATH.MRTA.MARKET_CONDUCTS}`} component={logPageView(MarketConductsContainer)} />
    <Route
      path={`${match.path}${ROUTE_PATH.MRTA.INSURED_INFORMATION}`}
      component={logPageView(MRTAInsuredInformationContainer)}
    />
    <Route path={`${match.path}${ROUTE_PATH.MRTA.COVERAGE_PLAN}`} component={logPageView(MRTACoveragePlanContainer)} />
    <Route path={`${match.path}${ROUTE_PATH.MRTA.BENEFIT_ILLUSTRATION}`} component={MRTABenefitIllustrationContainer} />

    {/* === pa (personal accident) === */}
    <Route
      path={`${match.path}${ROUTE_PATH.PA.NEED_AND_GAP_ANALYSIS}`}
      component={logPageView(NeedAndGapAnalysisContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.PA.PRODUCT_SELECTION}`}
      component={logPageView(BasicPlanSelectionContainer)}
    />
    <Route path={`${match.path}${ROUTE_PATH.PA.MARKET_CONDUCTS}`} component={logPageView(MarketConductsContainer)} />
    <Route
      path={`${match.path}${ROUTE_PATH.PA.INSURED_INFORMATION}`}
      component={logPageView(WholeLifeInsuredInformationContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.PA.COVERAGE_PLAN}`}
      component={logPageView(PersonalAccidentCoveragePlanContainer)}
    />
    <Route
      path={`${match.path}${ROUTE_PATH.PA.BENEFIT_ILLUSTRATION}`}
      component={PersonalAccidentBenefitIllustrationContainer}
    />

    <Redirect from="/" to={ROUTE_PATH.PORTAL} />
  </Switch>
)

export default compose(
  lifecycle({
    componentDidMount() {
      redirectToLandingPage(this.props)
    },
    UNSAFE_componentWillReceiveProps(nextProps) {
      redirectToLandingPage(nextProps)
    },
  })
)(FNA)
