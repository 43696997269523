import { get } from 'lodash/fp'
import { compact } from 'lodash/fp'
import remotePaymentSection from '../remotePaymentSection'

export const remotePayment = (app, props = {}) => {
  return Object.assign(
    {},
    {
      id: 'billing-method',
      prefix: 'billing-method',
      title: ' ชำระเบี้ย',
      label: 'ชำระเบี้ย',
      sections: compact([remotePaymentSection()]),
      remoteSection: true,
      disabled: get('remoteSelling.videoConsented', app) !== true,
    },
    props
  )
}
