import { flatten, compact, get, getOr, cloneDeep, uniqBy } from 'lodash/fp'
import { createField, createSectionCreator } from 'e-submission/domain/data-model/form/utils'
import { sendLinkCCPA } from 'e-submission/apps/actions'
import { isCCPASendLink } from 'e-submission/domain/data-model/form/selectors'
import {
  getDefaultPersonalTitleList,
  getKidPersonalTitleList,
  getRelationshipListForBeneficiary,
} from 'e-submission/apps/selectors'
import { isCCPAStatusCompleted } from 'e-submission/domain/data-model/form/selectors'
import { DOCUMENT_TYPE } from 'e-submission/domain/data-model/constants'

const CCPA = 'CCPA'
const CCPAStatus = 'CCPAStatus'

function checkPayerIsExist(app) {
  const payerFirstName = get(`payer.firstName`)(app)
  const payerRelationshipToInsured = get('payer.relationshipToInsured.value')(app)
  return !payerFirstName || (payerRelationshipToInsured !== 'SPOUSE' && payerRelationshipToInsured !== 'PARENT')
}

function checkInformationIsFilled(state) {
  const validationProperties = ['relations', 'title', 'firstName', 'lastName']
  for (let property of validationProperties) {
    if (!getCurrentAppIdAndStateData(state, property).stateTargetData) return false
  }
  const citizenId = getCurrentAppIdAndStateData(state, 'idNo.citizenId').stateTargetData
  const passport = getCurrentAppIdAndStateData(state, 'idNo.passport').stateTargetData
  const idType = getCurrentAppIdAndStateData(state, 'idNo.idType.value').stateTargetData
  if (idType === 'CITIZEN_ID' && (!citizenId || citizenId.length !== 13)) {
    return false
  }
  if (idType === 'PASSPORT' && !passport) {
    return false
  }
  const sendLinkMethod = getCurrentAppIdAndStateData(state, 'sendLink.method.value').stateTargetData
  if (sendLinkMethod === 'sms') {
    const mobileNumber = getCurrentAppIdAndStateData(state, `sendLink.${sendLinkMethod}`).stateTargetData
    return mobileNumber && mobileNumber.length === 10
  }

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  }

  return validateEmail(getCurrentAppIdAndStateData(state, `sendLink.${sendLinkMethod}`).stateTargetData)
}

function getDefaultFromPayerOrInsured(app, field) {
  const payerData = get(`payer.${field}`)(app)
  const insuredData = get(`insured.${field}`)(app)
  const payerFirstname = get('payer.firstName')(app)
  const payerRelationshipToInsured = get('payer.relationshipToInsured.value')(app)

  if (payerFirstname) {
    if (
      (payerRelationshipToInsured === 'PARENT' || payerRelationshipToInsured === 'SPOUSE') &&
      (field === 'mobileNumber' || field === 'email')
    ) {
      return ''
    }
    if (payerRelationshipToInsured === 'PARENT' || payerRelationshipToInsured === 'SPOUSE') {
      return payerData
    }
  }

  return insuredData
}

function getCurrentAppIdAndStateData(state, field) {
  const currentAppId = get('apps.currentAppId')(state)
  const stateTargetData = state ? get(`apps.byId[${currentAppId}].${CCPA}.${field}`)(state) : null

  return {
    currentAppId,
    stateTargetData,
  }
}

function checkDefaultValue(app, field, state, dispatch) {
  const { currentAppId, stateTargetData } = getCurrentAppIdAndStateData(state, field)
  const stateData = state ? get(`apps.byId[${currentAppId}].${CCPA}`)(state) : null
  // console.log(`checkDefaultValue stateTargetData :`, stateTargetData)
  if (stateTargetData || (stateData && stateData.hasOwnProperty(field)) || typeof stateTargetData === 'string') {
    return stateTargetData
  }

  const ccpaFieldTargetData = get(`${CCPA}.${field}`)(app)
  const ccpaData = get(`${CCPA}`)(app)

  if (ccpaFieldTargetData || (ccpaData && ccpaData.hasOwnProperty(field))) {
    return ccpaFieldTargetData
  }

  const payerData = get(`payer.${field}`)(app)
  const insuredData = get(`insured.${field}`)(app)
  const result = payerData || insuredData
  if (typeof dispatch === 'function' && !(stateData && stateData.hasOwnProperty('firstName'))) {
    const passport = getDefaultFromPayerOrInsured(app, 'idNo.passport')
    const citizenId = getDefaultFromPayerOrInsured(app, 'idNo.citizenId')
    const ccpaDefaultValue = {
      type: 'CREATE_CCPA_DEFAULT_VALUE',
      value: {
        relations: checkPayerIsExist(app)
          ? { text: 'ตัวเอง', value: 'SELF' }
          : getDefaultFromPayerOrInsured(app, 'relationshipToInsured'),
        title: getDefaultFromPayerOrInsured(app, 'title'),
        firstName: getDefaultFromPayerOrInsured(app, 'firstName'),
        lastName: getDefaultFromPayerOrInsured(app, 'lastName'),
        sendLink: {
          sms: getDefaultFromPayerOrInsured(app, 'mobileNumber'),
          email: getDefaultFromPayerOrInsured(app, 'email'),
          method: {
            value: getOr('sms', `${CCPA}.sendLink.method`, app),
          },
        },
        idNo: { citizenId, passport },
      },
    }

    if (passport && !citizenId) {
      ccpaDefaultValue.value.idNo.idType = { value: 'PASSPORT', text: 'หนังสือเดินทาง' }
    }
    setTimeout(dispatch(ccpaDefaultValue))
  }

  return result
}

export const CCPAFormSection = (app) => {
  const section = { id: CCPA }
  const id = section.id
  const methodSelected = get(`${CCPA}.${'sendLink.method.value'}`)(app)
  const componentForTextInputConnecting = methodSelected === 'sms' ? 'NumberInput' : 'TextInput'
  return compact([
    createField(`${id}.title`, [
      {
        label: 'คำนำหน้า',
        id: `${id}.title`,
        c: 'Dropdown',
        required: true,
        p: {
          placeHolder: 'กรุณาเลือกคำนำหน้าชื่อ',
          mergeState: (state) => ({
            options: uniqBy('value')([...getDefaultPersonalTitleList(state), ...getKidPersonalTitleList(state)]),
          }),
        },
      },
    ]),
    createField(`${id}.firstName`, [
      {
        label: 'ชื่อเจ้าของบัตรเครดิต',
        id: `${id}.firstName`,
        required: true,
        c: 'TextInput',
        p: {
          mergeState: (state, app, { dispatch }) => {
            return {
              value: checkDefaultValue(app, 'firstName', state, dispatch),
            }
          },
        },
      },
    ]),
    createField(`${id}.lastName`, [
      {
        label: 'นามสกุลเจ้าของบัตรเครดิต',
        id: `${id}.lastName`,
        required: true,
        c: 'TextInput',
        p: {
          mergeState: (state, __, { dispatch }) => {
            return {
              value: checkDefaultValue(app, 'lastName', state, dispatch),
            }
          },
        },
      },
    ]),
    createField(`${id}.idNo.idType`, [
      {
        label: 'เอกสารที่ใช้แสดง',
        id: `${id}.idNo.idType`,
        required: true,
        c: 'Dropdown',
        p: {
          mergeState: (state, app, { dispatch }) => {
            return {
              options: [
                {
                  value: 'CITIZEN_ID',
                  text: 'บัตรประจำตัวประชาชน',
                },
                {
                  value: 'PASSPORT',
                  text: 'หนังสือเดินทาง',
                },
              ],
              value: checkDefaultValue(app, 'idNo.idType', state, dispatch),
            }
          },
        },
      },
    ]),
    get('CCPA.idNo.idType.value')(app) !== 'PASSPORT' &&
      createField(`${id}.idNo.citizenId`, [
        {
          label: 'เลขประจำตัวประชาชน',
          id: `${id}.idNo.citizenId`,
          c: 'NumberInput',
          required: true,
          p: {
            mergeState: (state, app, { dispatch }) => {
              return {
                value: checkDefaultValue(app, 'idNo.citizenId', state, dispatch),
              }
            },
          },
        },
      ]),
    get('CCPA.idNo.idType.value')(app) === 'PASSPORT' &&
      createField(`${id}.idNo.passport`, [
        {
          label: 'หมายเลขหนังสือเดินทาง',
          id: `${id}.idNo.passport`,
          c: 'TextInput',
          required: true,
          p: {
            mergeState: (state, app, { dispatch }) => {
              return {
                value: checkDefaultValue(app, 'idNo.passport', state, dispatch),
              }
            },
          },
        },
      ]),
    createField(`${id}.relations`, [
      {
        label: 'ความสัมพันธ์กับผู้เอาประกัน',
        id: `${id}.relations`,
        required: true,
        c: 'Dropdown',
        p: {
          mergeState: (state) => {
            const relationshipListForBeneficiary = cloneDeep(getRelationshipListForBeneficiary(state))
            if (checkPayerIsExist(app)) relationshipListForBeneficiary.push({ text: 'ตัวเอง', value: 'SELF' })
            return {
              options: [...relationshipListForBeneficiary],
              value: checkDefaultValue(app, 'relations', state),
            }
          },
          placeHolder: 'กรุณาเลือกความสัมพันธ์',
        },
      },
    ]),
    createField(`${id}.sendLink.method`, [
      {
        label: 'ท่านต้องการส่งลิงก์สมัครหักค่าเบี้ยประกันอัตโนมัติผ่านบัตรเครดิตไปที่',
        id: `${id}.sendLink.method`,
        c: 'RadioButton',
        required: true,
        p: {
          options: [
            {
              text: 'อีเมล',
              value: 'email',
            },
            {
              text: 'SMS',
              value: 'sms',
            },
          ],
          mergeState: (state, app, { dispatch }) => ({
            value: checkDefaultValue(app, 'sendLink.method', state, dispatch),
          }),
        },
      },
    ]),
    createField(`${id}.sendLink.textInput`, [
      {
        id: `${id}.sendLink.${methodSelected}`,
        c: componentForTextInputConnecting,
        required: true,
        p: {
          mergeState: (state) => {
            return {}
          },
        },
        infoMessage: 'ท่านสามารถเลือกช่องทางในการส่งลิงก์ผ่านช่องทางที่ท่านต้องการ',
      },
    ]),
    createField(`${id}.submit`, [
      {
        id: `${id}.submit`,
        c: 'Button',
        p: {
          mergeState: (state, app, { dispatch, history }) => {
            return {
              disabled: !checkInformationIsFilled(state),
              onClick: () => {
                dispatch(sendLinkCCPA())
              },
            }
          },
          children: 'ยืนยัน',
          block: true,
        },
      },
    ]),
  ])
}

const StatusSection = (app) => {
  return [
    createField(
      `${CCPAStatus}`,
      compact([
        {
          id: 'document',
          c: 'CcpaStatusCard',
          p: {},
        },
        {
          id: 'CCPA.preview',
          c: 'UnsignedDocument',
          p: {
            mergeState: (state, app) => {
              return {
                disabled: !isCCPAStatusCompleted(app),
              }
            },
            description: DOCUMENT_TYPE.insured.CCPA.text,
            name: DOCUMENT_TYPE.insured.CCPA.name,
            policyId: app.policyId,
            hasBackground: true,
          },
        },
      ])
    ),
  ]
}

export const CCPASection = createSectionCreator((app, id, section) => {
  const CCPAFormFields = CCPAFormSection(app)
  const StatusFields = StatusSection(app)
  return flatten(compact([isCCPASendLink(app) ? [...StatusFields] : [...CCPAFormFields]]))
})
