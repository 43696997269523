//@flow
import type { Action } from './actions'
import { SET_ENTRY_COMPONENT, RESET_ENTRY_COMPONENT } from './actions'
import type { EntryComponent, NoEntryComponent } from 'core/data-model/entry-component'

const DEFAULT_STATE: NoEntryComponent = {
  source: '',
}

export const reducer = (state: State = DEFAULT_STATE, action: Action): State => {
  switch (action.type) {
    case SET_ENTRY_COMPONENT:
      return action.payload
    case RESET_ENTRY_COMPONENT:
      return DEFAULT_STATE
  }
  return state
}

export type State = EntryComponent
