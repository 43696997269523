// @flow
import type { Dispatch } from 'redux'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormGroup } from 'reactstrap'

import PremiumInput from './premium-input'
import RiderGroup from 'quick-quote/product-common/coverage-plan/components/rider/rider-group'
import type { ModelFactor } from 'core/data-model/basic-plan'
import type { RegularPremium, SumAssured, LumpSum } from 'core/service/investment'
import BasicPlanErrorMessages from './basic-plan-error-messages'
import {
  editRegularPremium,
  editSumAssured,
  selectModelFactor,
  previousModelFactor,
  editLumpSum,
} from 'quick-quote/product-investment/coverage-plan/actions'
import {
  getRegularPremium,
  getSelectedModelFactorID,
  getAvailableModelFactors,
  getSumAssured,
  getPayFirstMonthAndTotalRiderMessage,
  getExpectedReturnComparisons,
  getLumpSumErrors,
} from 'quick-quote/product-investment/coverage-plan/selectors'
import { getAvailableRiderCodes, getTotalRiderPremium } from 'quick-quote/product-common/coverage-plan/selectors'
import {
  toggleRider,
  editRiderSumAssured,
  editRiderSelectedPlan,
} from 'quick-quote/product-common/coverage-plan/actions'
import { getSelectedDisplayProductCode } from 'quick-quote/product-selection/selectors'
import type { AppState } from 'quick-quote/reducers'
import { RadioButtonGroup } from 'common-components'
import { formatNumber } from 'core/service/lib/number-format'
import MESSAGES from 'core/data-model/constants/messages'

import { LumpSumInput } from '../expected-return-comparison-table'

type RPUDRBasicPlanCalculationProps = {
  selectedProductCode: string,
  modelFactors: ModelFactor[],
  sumAssured: SumAssured,
  regularPremium: RegularPremium,
  payFirstMonthMessage: string,
  selectedModelFactorID: string,
  editSumAssured: (number) => void,
  editRegularPremium: (number) => void,
  editLumpSum: (number) => void,
  selectModelFactor?: (string) => void,
  previousModelFactor?: (string) => void,
  riderCodes: string[],
  toggleRider: (string, boolean) => void,
  editRiderSumAssured: (string, number) => void,
  editRiderSelectedPlan: (string, RiderPlan) => void,
}

export const firstYearLSTUValidation = (error: string[], lumpSum: LumpSum) => {
  return error && error.length > 0 && !lumpSum.isValid
    ? {
        message: error,
        type: 'VALIDATION',
      }
    : {}
}

export const buildLSTUError = (error: string[], lumpSum: LumpSum) => {
  return {
    errors: [firstYearLSTUValidation(error, lumpSum)],
  }
}

export const getFirstLSTU = (error: string[] = [], lumpSum: LumpSum) => {
  return error.length > 0 ? 0 : lumpSum.value
}

export const RPUDRBasicPlanCalculation = ({
  modelFactors,
  sumAssured,
  regularPremium,
  payFirstMonthMessage,
  selectedModelFactorID,
  editSumAssured,
  editRegularPremium,
  selectModelFactor,
  previousModelFactor,
  riderCodes,
  toggleRider,
  editRiderSumAssured,
  editRiderSelectedPlan,
  editLumpSum,
  expectedReturnComparisons,
  lumpsumError,
  totalRiderPremium,
}: RPUDRBasicPlanCalculationProps) => {
  const totalPremium = regularPremium.value + totalRiderPremium

  //this value must be first year of expectedReturnComparisons
  const { year, lumpSum } = expectedReturnComparisons[0]
  const lstuError = buildLSTUError(lumpsumError, lumpSum)
  const lstuValue = getFirstLSTU(lumpsumError, lumpSum)

  const updateModelFactorId = (id) => {
    previousModelFactor(selectedModelFactorID)
    selectModelFactor(id)
  }

  return (
    <div id="basic-plan" className="investment rpudr-investment">
      <div className="content">
        <div className="title-bottom-padding">{MESSAGES.PREMIUM_PROPORTION}</div>

        <FormGroup>
          <label> {MESSAGES.PAYMENT_MODE} </label>
          <RadioButtonGroup
            keyToGetValue="id"
            className="model-factor"
            onChange={updateModelFactorId}
            value={selectedModelFactorID}
            options={modelFactors}
          />
        </FormGroup>

        <div className="title-center">
          {' '}
          <label> {MESSAGES.GROSS_PREMIUM_AND_RIDER_PREMIUM} </label>{' '}
        </div>
        <div className="title-center-bold">
          {' '}
          <label>
            {' '}
            {formatNumber(totalPremium, 2, true)} {MESSAGES.BAHT}{' '}
          </label>{' '}
        </div>

        <div className="title-center">
          {' '}
          <label> {payFirstMonthMessage} </label>{' '}
        </div>

        <div className="title-center">
          {' '}
          <label className="text-normal"> {MESSAGES.LSTU_PREMIUM} </label>{' '}
        </div>
        <div className="title-center-bold">
          {' '}
          <label>
            {' '}
            {formatNumber(lstuValue, 2)} {MESSAGES.BAHT}{' '}
          </label>{' '}
        </div>

        <FormGroup className="coverage-plan-calculation item basic">
          <div>
            <div className="sum-assured-input">
              <div className="label">
                <label className="label-between">{MESSAGES.COVERAGE_RPP}</label>
                <label className="label-between">{MESSAGES.COVERAGE_LSTU}</label>
              </div>

              <div className="options">
                <div className="sum-assured">
                  <PremiumInput
                    id="coverage-regular-premium"
                    premium={regularPremium}
                    editPremiumAmount={editRegularPremium}
                  />
                </div>
                <div className="link">&nbsp;</div>

                <div className="sum-assured">
                  <LumpSumInput id="lumpsum-topup-0" year={year} lumpSum={lumpSum} editLumpSum={editLumpSum} />
                </div>
              </div>
              <FormGroup className="has-danger">
                <BasicPlanErrorMessages premiumValue={regularPremium} />
                <BasicPlanErrorMessages premiumValue={lstuError} />
              </FormGroup>
            </div>
          </div>

          <label>{MESSAGES.COVERAGE_SUM_ASSURED}</label>
          <PremiumInput id="life-coverage" premium={sumAssured} editPremiumAmount={editSumAssured} />

          <FormGroup className="has-danger">
            <BasicPlanErrorMessages premiumValue={sumAssured} />
          </FormGroup>
        </FormGroup>

        <RiderGroup
          riderCodes={riderCodes}
          toggleRider={toggleRider}
          editRiderSumAssured={editRiderSumAssured}
          editRiderSelectedPlan={editRiderSelectedPlan}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state: AppState): * => ({
  selectedProductCode: getSelectedDisplayProductCode(state),
  modelFactors: getAvailableModelFactors(state),
  sumAssured: getSumAssured(state),
  regularPremium: getRegularPremium(state),
  payFirstMonthMessage: getPayFirstMonthAndTotalRiderMessage(state),
  selectedModelFactorID: getSelectedModelFactorID(state),
  riderCodes: getAvailableRiderCodes(state),
  expectedReturnComparisons: getExpectedReturnComparisons(state),
  lumpsumError: getLumpSumErrors(state),
  totalRiderPremium: getTotalRiderPremium(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  selectModelFactor: bindActionCreators(selectModelFactor, dispatch),
  previousModelFactor: bindActionCreators(previousModelFactor, dispatch),
  editSumAssured: bindActionCreators(editSumAssured, dispatch),
  editRegularPremium: bindActionCreators(editRegularPremium, dispatch),
  toggleRider: bindActionCreators(toggleRider, dispatch),
  editRiderSumAssured: bindActionCreators(editRiderSumAssured, dispatch),
  editRiderSelectedPlan: bindActionCreators(editRiderSelectedPlan, dispatch),
  editLumpSum: bindActionCreators(editLumpSum, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(RPUDRBasicPlanCalculation)
