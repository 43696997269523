// @flow
import { combineReducers } from 'redux'
import { RESET_APPLICATION_STATE } from 'quick-quote/actions'
import { SELECT_PRODUCT } from 'quick-quote/product-selection/actions'
import {
  EDIT_MATURITY_ID_CARD,
  SELECT_POLICY_OWNER,
  MATURITY_VALIDATE,
  SET_VALID_MATURITY_ID_CARD,
  SET_ERROR_VALIDATE_MATURITY_ID_CARD,
  SET_ERROR_VALIDATE_MATURITY_STATUS,
  SET_ERROR_VALIDATE_SELECT_POLICY_OWNER,
  RESET_ERROR_VALIDATE_SELECT_POLICY_OWNER,
  RESET_ERROR_VALIDATE_MATURITY_ID_CARD,
  UPDATE_MATURITY_VALIDATE,
} from './actions'

export type MaturityIDCardState = {
  currentIDCard?: number,
  validIDCard?: number,
  errors: string[],
}
const initialMaturityIDCard = {
  currentIDCard: null,
  validIDCard: null,
  errors: [],
}

const initialErrorIDCard = []
export const idCard = (state: MaturityIDCardState = initialMaturityIDCard, action: Action) => {
  switch (action.type) {
    // case RESET_QUOTE_FORM:
    case RESET_APPLICATION_STATE:
    case SELECT_PRODUCT:
      return initialMaturityIDCard
    case EDIT_MATURITY_ID_CARD:
    case MATURITY_VALIDATE:
      return {
        ...state,
        currentIDCard: action.payload,
      }
    case SET_VALID_MATURITY_ID_CARD:
      return {
        ...state,
        validIDCard: action.payload,
        errors: [],
      }
    case SET_ERROR_VALIDATE_MATURITY_ID_CARD:
      return {
        ...state,
        validIDCard: null,
        errors: action.payload,
      }
    case SET_ERROR_VALIDATE_MATURITY_STATUS:
      return {
        ...state,
        errors: action.payload,
      }
    case RESET_ERROR_VALIDATE_MATURITY_ID_CARD:
      return {
        ...state,
        errors: initialErrorIDCard,
      }
    default:
      return state
  }
}

export type MaturityValidateState = {
  maturityStatus: boolean,
  isBuyForOwner: boolean,
}
const initialMaturityValidate = {
  maturityStatus: false,
  isBuyForOwner: false,
}
export const maturityValidate = (state: MaturityValidateState = initialMaturityValidate, action: Action) => {
  switch (action.type) {
    // case RESET_QUOTE_FORM:
    case RESET_APPLICATION_STATE:
    case SELECT_PRODUCT:
      return initialMaturityValidate
    // case SELECT_POLICY_OWNER:
    //   return action.payload
    case UPDATE_MATURITY_VALIDATE:
      return action.payload
    default:
      return state
  }
}

const initialPolicyOwner = ''
export const policyOwner = (state: string = initialPolicyOwner, action: Action) => {
  switch (action.type) {
    // case RESET_QUOTE_FORM:
    case RESET_APPLICATION_STATE:
    case SELECT_PRODUCT:
      return initialPolicyOwner
    case SELECT_POLICY_OWNER:
      return action.payload
    default:
      return state
  }
}

const initialError = []
export const errors = (state: string[] = initialError, action: Action) => {
  switch (action.type) {
    // case RESET_QUOTE_FORM:
    case RESET_APPLICATION_STATE:
    case SELECT_PRODUCT:
    case UPDATE_MATURITY_VALIDATE:
    case RESET_ERROR_VALIDATE_SELECT_POLICY_OWNER:
      return initialError
    case SET_ERROR_VALIDATE_SELECT_POLICY_OWNER:
      return action.payload
    default:
      return state
  }
}

export const reducer = combineReducers({
  idCard,
  maturityValidate,
  policyOwner,
  errors,
})
