import _ from 'lodash'

import { createSectionCreator, createField } from 'e-submission/domain/data-model/form/utils'
import {
  getDocumentTypeText,
  getDocumentTypeName,
  isRemoteSelling,
  getQuickQuoteInsuredAge,
  getUploadedDocuments,
  getSkippedInsuredEkyc,
  getSkippedPayerEkyc,
  getInsuredDopaRequestFailed,
  getPayerDopaRequestFailed,
} from 'e-submission/domain/data-model/form/selectors'
import { getToggles } from 'quick-quote/feature-toggles'
import { DOCUMENT_TYPE } from 'e-submission/domain/data-model/constants'
import { isSelectedRemoteSelling } from 'quick-quote/selectors'
import { isDisable2ndDoc as shouldDisable2ndDoc } from 'e-submission/utils'

export const shouldEnableBrowseId = (app) =>
  (getToggles().ENABLE_BROWSE_ID_DOCUMENT && isRemoteSelling(app)) || undefined

export const shouldEnableSelfie = (app, insuredOrPayer, insuredAge) => {
  if (isSelectedRemoteSelling(app)) {
    return false
  }

  const isEnableSelfie = (getToggles().ENABLE_REMOTE_SELLING_SELFIE && isRemoteSelling(app)) || undefined
  if (!isEnableSelfie) return false
  const isPayer = insuredOrPayer === 'payer'
  const isTooYoungToSelfie = insuredAge && insuredAge.unit === 'year' && insuredAge.value < 18
  const isInsuredAgeLessThan1Year =
    insuredAge.unit === 'month' && getToggles().ENABLE_INVESTMENT_PRODUCT_REMOTESELLING_WITHOUT_BANCA
  if (isPayer) {
    if (isInsuredAgeLessThan1Year) return true
    return insuredAge.unit === 'year' && insuredAge.value < 20
  } else {
    if (isInsuredAgeLessThan1Year) return false
    return !isTooYoungToSelfie
  }
}

export const shouldEnableTamlo = () => {
  return getToggles().ENABLE_TAMLO
}

export const documentFields = (insuredOrPayer) => (app, id) => {
  // pass undefined instead of false to let PhotoCard use its default props
  const isEnableBrowseFile = shouldEnableBrowseId(app)
  const isRemoteSelling = isSelectedRemoteSelling(app)
  const isInsured = insuredOrPayer === 'insured'
  const accept = 'image/x-png,image/jpeg,image/tiff,image/png'
  const customText =
    insuredOrPayer === 'insured' ? 'อัปโหลดเอกสารสำหรับผู้ขอเอาประกัน' : 'อัปโหลดเอกสารสำหรับผู้ชำระเบี้ยประกัน'
  const insuredAge = getQuickQuoteInsuredAge(app)
  const requiredSelfie = shouldEnableSelfie(app, insuredOrPayer, insuredAge)
  const requiredTamlo = shouldEnableTamlo()
  const isDisable2ndDoc = shouldDisable2ndDoc()

  const isSkippedInsuredEkyc = getSkippedInsuredEkyc(app)
  const isSkippedPayerEkyc = getSkippedPayerEkyc(app)
  // const isSkippedEkyc = isSkippedInsuredEkyc || isSkippedPayerEkyc
  const insuredDopaRequestFailed = getInsuredDopaRequestFailed(app)
  const payerDopaRequestFailed = getPayerDopaRequestFailed(app)
  // const isSkipDOPA = insuredDopaRequestFailed || payerDopaRequestFailed
  const isSkipInsuredEkycOrDopa = isSkippedInsuredEkyc || insuredDopaRequestFailed
  const isSkipPayerEkycOrDopa = isSkippedPayerEkyc || payerDopaRequestFailed
  const isSkipEkycOrDopa = isInsured ? isSkipInsuredEkycOrDopa : isSkipPayerEkycOrDopa

  return [
    createField(
      `${insuredOrPayer}.${id}`,
      _.compact([
        {
          id: `${insuredOrPayer}.${id}`,
          c: isRemoteSelling ? 'UploadDocCommand' : 'PhotoCard',
          required: true,
          p: {
            accept,
            customText,
            isBrowseFile: isEnableBrowseFile,
            mergeState: (state, app) => {
              let documentTypeText = getDocumentTypeText(app, insuredOrPayer)
              const documentTypeName = getDocumentTypeName(app, insuredOrPayer)
              const identityDocTypes = [
                'insured-id-card',
                'owner-id-card',
                'insured-passport',
                'owner-passport',
                'insured-house-registration',
              ]
              const existingId = getUploadedDocuments(documentTypeName, app)
              if ((getToggles().ENABLE_EKYC_F2F || isRemoteSelling) && _.includes(identityDocTypes, documentTypeName)) {
                documentTypeText = documentTypeText.replace('พร้อมลายเซ็น', '')
              }

              const value = [
                {
                  id: `${insuredOrPayer}.${id}.${documentTypeName}`,
                  name: documentTypeName,
                  description: documentTypeText,
                  existingId,
                  isInsured: insuredOrPayer === 'insured',
                  checked: _.has(app, ['_attachments', documentTypeName]),
                },
              ]
              return {
                value: isRemoteSelling ? value[0] : value,
              }
            },
          },
        },
        requiredSelfie && {
          id: `${insuredOrPayer}.selfie`,
          c: isRemoteSelling ? 'UploadDocCommand' : 'PhotoCard',
          required: true,
          p: {
            accept,
            isBrowseFile: true,
            mergeState: (state, app) => {
              const nameMapping = {
                insured: 'insured',
                payer: 'owner',
              }
              const documentTypeName = `${nameMapping[insuredOrPayer]}-selfie`
              const existingId = getUploadedDocuments(documentTypeName, app)
              const value = [
                {
                  id: `${insuredOrPayer}.${id}.${documentTypeName}`,
                  name: DOCUMENT_TYPE[insuredOrPayer].SELFIE.name,
                  existingId,
                  description: requiredTamlo
                    ? DOCUMENT_TYPE[insuredOrPayer].SELFIE_NEW.text
                    : DOCUMENT_TYPE[insuredOrPayer].SELFIE.text,
                  checked: _.has(app, ['_attachments', documentTypeName]),
                  isInsured: insuredOrPayer === 'insured',
                },
              ]
              return {
                value: isRemoteSelling ? value[0] : value,
              }
            },
          },
        },
        requiredTamlo &&
          !(isDisable2ndDoc && isSkipEkycOrDopa) && {
            // [GIFT comment code detail] ^ Tamlo is 2nd doc except form id card (ex. house registeration doc)
            id: `${insuredOrPayer}.official-document`,
            c: isRemoteSelling ? 'UploadDocCommand' : 'PhotoCard',
            required: true,
            p: {
              accept,
              isBrowseFile: isEnableBrowseFile,
              mergeState: (state, app) => {
                const nameMapping = {
                  insured: 'insured',
                  payer: 'owner',
                }
                const documentTypeName = `${nameMapping[insuredOrPayer]}-official-document`
                const existingId = getUploadedDocuments(documentTypeName, app)
                const value = [
                  {
                    id: `${insuredOrPayer}.${id}.${documentTypeName}`,
                    name: DOCUMENT_TYPE[insuredOrPayer].OFFICIAL_DOC.name,
                    description: DOCUMENT_TYPE[insuredOrPayer].OFFICIAL_DOC.text,
                    checked: _.has(app, ['_attachments', documentTypeName]),
                    isInsured: insuredOrPayer === 'insured',
                    existingId,
                  },
                ]
                return {
                  value: isRemoteSelling ? value[0] : value,
                }
              },
            },
          },
      ])
    ),
  ]
}

export const insuredDocumentSection = createSectionCreator(documentFields('insured'))

export const payerDocumentSection = createSectionCreator(documentFields('payer'))

export const tamloInfoMessageFields = () => () => {
  const requiredTamlo = shouldEnableTamlo()
  return requiredTamlo
    ? [
        createField(`tamloInfoMessage`, [
          {
            id: `tamloInfoMessage`,
            required: false,
            c: 'InfoMessage',
            p: {
              mergeState: () => ({
                value:
                  'ตามประกาศของ สำนักงานป้องกันและปราบปรามการฟอกเงิน (ปปง.) ผู้เอาประกันต้องแนบเอกสารเพื่อพิสูจน์ตัวตน',
              }),
            },
          },
        ]),
      ]
    : null
}
export const tamloInfoMessageSection = createSectionCreator(tamloInfoMessageFields())
