import { compact } from 'lodash/fp'

import { createSectionCreator, createField } from '../utils'
import { checker } from '../selectors'

const diseaseList = {
  diseaseVisuallyImpaired: 'สายตาพิการ',
  diseaseRetinal: 'โรคจอประสาทตา',
  diseaseGlaucoma: 'โรคต้อหิน',
  diseaseStroke: 'โรคหลอดเลือดในสมอง',
  diseaseParalysis: 'โรคอัมพฤกษ์/โรคอัมพาต',
  diseaseParkinson: 'โรคพาร์กินสัน',
  diseaseAlzheimer: 'โรคความจำเสื่อม',
  diseaseEpilepsy: 'โรคชัก',
  diseasePneumonia: 'โรคปอดหรือปอดอักเสบ',
  diseaseTuberculosis: 'โรควัณโรค',
  diseaseAsthma: 'โรคหอบหืด',
  diseaseCOPD: 'โรคปอดอุดกั้นเรื้อรัง',
  diseaseEmphysema: 'โรคถุงลมโป่งพอง',
  diseaseSleepApnea: 'ภาวะหยุดหายใจขณะหลับ',
  diseaseHypertension: 'โรคความดันโลหิตสูง',
  diseaseHeart: 'โรคหัวใจ',
  diseaseCoronaryArtery: 'โรคเส้นเลือดหัวใจตีบ',
  diseaseVascular: 'โรคหลอดเลือด',
  diseaseGastrointestinalUlcer: 'โรคแผลในทางเดินอาหาร',
  diseaseLiver: 'โรคตับหรือทางเดินน้ำดี',
  diseaseAlcoholism: 'โรคพิษสุราเรื้อรัง',
  diseaseJaundice: 'ดีซ่าน',
  diseaseSplenomegaly: 'ม้ามโต',
  diseasePancreatitis: 'โรคตับอ่อนอักเสบ',
  diseaseNephropathy: 'โรคไต',
  diseaseArthritis: 'โรคข้ออักเสบ',
  diseaseGout: 'โรคเก๊าท์',
  diseaseScleroderma: 'โรคหนังแข็ง',
  diseaseSLE: 'โรคเอสแอลอี',
  diseaseBlood: 'โรคเลือด',
  diseaseDiabetes: 'โรคเบาหวาน',
  diseaseThyroid: 'โรคไทรอยด์',
  diseaseLymphadenopathy: 'โรคต่อมน้ำเหลืองโต',
  diseaseCyst: 'เนื้องอก ก้อนหรือถุงน้ำ',
  diseaseCancer: 'โรคมะเร็ง',
  diseaseHIV: 'โรคเอดส์หรือภูมิคุ้มกันบกพร่อง',
  diseaseVenereal: 'โรคกามโรค (ภายใน 2 ปี)',
  diseaseDisability: 'พิการทางร่างกาย',
  diseasePsychosis: 'โรคจิต',
  diseaseNeuralgia: 'โรคประสาท',
  diseaseDepression: 'โรคซึมเศร้า',
  diseaseDownSyndrome: 'ดาวน์ซินโดรม',
}

let diseaseOptions = []

for (const key in diseaseList) {
  diseaseOptions.push({
    text: diseaseList[key],
    value: key,
  })
}
diseaseOptions.push({
  text: 'โรคอื่น ๆ',
  value: 'diseaseETC',
})

const reasonInputField = (id) =>
  createField(`${id}.detail`, [
    {
      label: 'โปรดระบุรายละเอียด',
      id: `${id}.detail`,
      c: 'TextInput',
      required: true,
      p: {},
    },
  ])

const infoLabel = (id, k) =>
  createField(`${id}.${k}.label`, [
    {
      id: `${id}.${k}.label`,
      c: 'PlainText',
      p: {
        children: 'โรค: ' + diseaseList[k],
      },
    },
  ])

const infoWhen = (id) =>
  createField(`${id}.when`, [
    {
      label: 'วัน/เดือน/ปี ที่รักษา (ระบุว่าได้รับการวินิจฉัย หรือรับการรักษา หรือตั้งข้อสังเกตโดยแพทย์)',
      id: `${id}.when`,
      c: 'TextInput',
      required: true,
      p: {},
    },
  ])

const infoSymptom = (id) =>
  createField(`${id}.symptom`, [
    {
      label: 'การรักษา และอาการปัจจุบัน',
      id: `${id}.symptom`,
      c: 'TextInput',
      required: true,
      p: {},
    },
  ])

const infoWhere = (id) =>
  createField(`${id}.where`, [
    {
      label: 'สถานพยาบาลที่รักษา (หากระบุชื่อแพทย์ได้ โปรดระบุ)',
      id: `${id}.where`,
      c: 'TextInput',
      required: true,
      p: {},
    },
  ])

const getQuestions = (app, id) => {
  const { isYes, isNo } = checker(app)

  const keys = Object.keys(diseaseList)

  return compact([
    createField(`${id}.diseaseMRTA.checked`, [
      {
        id: `${id}.diseaseMRTA.checked`,
        c: 'RadioButton',
        required: true,
        label:
          ' ท่านเคยได้รับการวินิจฉัย หรือรับการรักษา หรือตั้งข้อสังเกตโดยแพทย์ว่าป่วยเป็นโรคตามรายการท้ายคำถามนี้หรือไม่',
        p: {
          options: [
            {
              text: 'ไม่เคย',
              value: 'no',
            },
            {
              text: 'เคย',
              value: 'yes',
            },
          ],
          clearValue: 'insured.diseaseMRTA.detail',
        },
      },
    ]),
    !isNo(`${id}.diseaseMRTA.checked`) &&
      createField(`${id}.diseaseMRTA.detail`, [
        {
          id: `${id}.diseaseMRTA.detail`,
          c: 'MultipleCheckbox',
          label:
            'หากเคย กรุณาทำเครื่องหมายในช่อง □ และให้ระบุโรคและการรักษาตามรายละเอียดด้านล่าง (ตอบได้มากกว่า 1 ข้อ)',
          p: {
            options: diseaseOptions,
          },
          required: true,
        },
      ]),

    ...keys
      .map(
        (k) =>
          _.get(app, `${id}.diseaseMRTA.detail.${k}`) && [
            infoLabel(`${id}.diseaseMRTA.detail`, `${k}`),
            infoWhen(`${id}.diseaseMRTA.detail.${k}`),
            infoSymptom(`${id}.diseaseMRTA.detail.${k}`),
            infoWhere(`${id}.diseaseMRTA.detail.${k}`),
          ]
      )
      .flat(),
    _.get(app, `${id}.diseaseMRTA.detail.diseaseETC`) &&
      createField(`${id}.diseaseMRTA.detail.diseaseETC.label`, [
        {
          id: `${id}.diseaseMRTA.detail.diseaseETC.label`,
          c: 'PlainText',
          p: {
            children: 'โรคอื่น ๆ',
          },
        },
      ]),
    _.get(app, `${id}.diseaseMRTA.detail.diseaseETC`) && reasonInputField(`${id}.diseaseMRTA.detail.diseaseETC`),
  ])
}

export default createSectionCreator(getQuestions)
