// @flow
/* istanbul ignore file */

import BIMESSAGES from 'core/data-model/constants/bi-messages'
import { createFlexiHealthPdf } from 'core/service/pdf-generation/products/flexi-health/pdf-document'
import { createHealthPdf } from 'core/service/pdf-generation/products/health/pdf-document'
import { createiHealthyUltraPdf } from 'core/service/pdf-generation/products/ihealthy-ultra/pdf-document'
import { createInvestmentPdf } from 'core/service/pdf-generation/products/investment/pdf-document'
import { createLifeSaveProPdf } from 'core/service/pdf-generation/products/life-save-pro/pdf-document'
import { createTermLifePdf } from 'core/service/pdf-generation/products/term-life/pdf-document'
import { createProtectionPdf } from 'core/service/pdf-generation/products/protection/pdf-document'
import { createRetirementPdf } from 'core/service/pdf-generation/products/retirement/pdf-document'
import { createBumnanReadyPdf } from 'core/service/pdf-generation/products/bumnan-ready/pdf-document'
import { createSavingPdf } from 'core/service/pdf-generation/products/saving/pdf-document'
import { createWholeLifePdf } from 'core/service/pdf-generation/products/whole-life/pdf-document'
import { createMRTAPdf } from 'core/service/pdf-generation/products/mrta/pdf-document'
import { generatePassword } from 'core/service/pdf-generation/utils/digital-signature'

import { getAppConfig } from 'deploy-env/app-config'

import { instance } from 'e-submission/domain/data/request-wrapper'
import MESSAGES from 'quick-quote/constants/messages'
import { getToggles } from 'quick-quote/feature-toggles'
import type { BIProps } from 'core/service/pdf-generation'
import { isQuickQuotePath } from 'quick-quote/selectors'
import values from 'core/data-model/constants/values'

// this should be constraint by types so you don't need to write test for it
// when creaging new function for pdf document creation we should not use
// generic type like BIType or BIProps

export const createPdfDoc = (props: BIProps) => {
  switch (props.productCategory) {
    case BIMESSAGES.CATEGORY_WHOLE_LIFE:
    case BIMESSAGES.CATEGORY_PERFECT_LIFE:
    case BIMESSAGES.CATEGORY_SUKHAPABDEKDEE:
    case BIMESSAGES.CATEGORY_LIFE_READY:
      return createWholeLifePdf(props)
    case BIMESSAGES.CATEGORY_MRTA:
      return createMRTAPdf(props)
    case BIMESSAGES.CATEGORY_INVESTMENT:
      return createInvestmentPdf(props)
    case BIMESSAGES.CATEGORY_HEALTH:
      return createHealthPdf(props)
    case BIMESSAGES.CATEGORY_IHEALTHYULTRA:
      return createiHealthyUltraPdf(props)
    case BIMESSAGES.CATEGORY_PROTECTION:
      return createProtectionPdf(props)
    case BIMESSAGES.CATEGORY_SAVING:
      return createSavingPdf(props)
    case BIMESSAGES.CATEGORY_LIFE_SAVE_PRO:
      return createLifeSaveProPdf(props)
    case BIMESSAGES.CATEGORY_TERM_LIFE:
      return createTermLifePdf(props)
    case BIMESSAGES.CATEGORY_FLEXI_HEALTH:
    case BIMESSAGES.CATEGORY_HEALTH_TOPPING:
      return createFlexiHealthPdf(props)
    case BIMESSAGES.CATEGORY_RETIREMENT:
      return createRetirementPdf(props)
    case BIMESSAGES.CATEGORY_BUMNAN_READY:
      return createBumnanReadyPdf(props)
    default:
      ;(props.productCategory: empty)
      throw new Error(`No such a product exist, ${props.productCategory}`)
  }
}

export const getBase64FromPdfDoc = (pdfDoc: *): Promise<*> => {
  return new Promise((resolve, reject) => {
    pdfDoc.getBase64((data) => resolve(data))
  })
}

const signPdfDoc = async (pdfDocBase64: string, accessToken: string, birthdate: string) => {
  // return pdfDocBase64
  const requestUrl = `${getAppConfig().ADVISORZONE_MIDDLEWARE}/pdf/sign`
  const password = generatePassword(birthdate)
  const requestConfig = getToggles().ENABLE_AUTHORISATION_CODE_FLOW
    ? {}
    : {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
  return await instance
    .post(
      `${requestUrl}/${password}`,
      {
        data: pdfDocBase64,
      },
      requestConfig
    )
    .then(
      (response) => {
        return response.data
      },
      (err) => {
        console.error('error', err)
        if (getToggles().ENABLE_RED_TRUCK_ERROR_MSG) throw err
        else throw new Error('SIGN ERROR')
      }
    )
}

export const generatePdfAndSign = async (docData: object, type: string, accessToken: string, birthdate: string) => {
  // return pdfDocBase64
  const requestUrl = `${getAppConfig().ADVISORZONE_MIDDLEWARE}/pdf/get-and-sign/${type}`
  const password = generatePassword(birthdate)
  const requestConfig = {
    headers: { Authorization: `Bearer ${accessToken}` },
  }
  return await instance
    .post(
      `${requestUrl}/${password}`,
      {
        docData,
      },
      requestConfig
    )
    .then(
      (response) => {
        return response.data
      },
      (err) => {
        console.error('error', err)
        if (getToggles().ENABLE_RED_TRUCK_ERROR_MSG) throw err
        else throw new Error('SIGN ERROR')
      }
    )
}

export const generatePdfAndSignWithoutPwd = async (docData: object, type: string, accessToken: string) => {
  // return pdfDocBase64
  const requestUrl = `${getAppConfig().ADVISORZONE_MIDDLEWARE}/pdf/get-and-sign-no-key/${type}`
  const requestConfig = {
    headers: { Authorization: `Bearer ${accessToken}` },
  }
  return await instance
    .post(
      `${requestUrl}`,
      {
        docData,
      },
      requestConfig
    )
    .then(
      (response) => {
        return response.data
      },
      (err) => {
        console.error('error', err)
        if (getToggles().ENABLE_RED_TRUCK_ERROR_MSG) throw err
        else throw new Error('SIGN ERROR')
      }
    )
}

/* istanbul ignore next */
export const _shareQuickQuote = async (props: BIProps, openPdf: Function, openNewWindowWithLoading: Function) => {
  let newBrowserWindow
  /* istanbul ignore next */
  if (!window.cordova) {
    newBrowserWindow = openNewWindowWithLoading()
  }

  try {
    const pdfDoc = await createPdfDoc(props)
    let pdfDocBase64 = await getBase64FromPdfDoc(pdfDoc)
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    const isBanca = userInfo.agent_type === values.AGENT_TYPE_LB
    if (getToggles().ENABLE_REVISE_FNA && isQuickQuotePath() && !isBanca) {
      if (getToggles().ENABLE_HANDLE_PDF_CHROME_ANDROID) {
        await openPdf(pdfDocBase64, newBrowserWindow)
        return
      } else {
        return openPdf(pdfDocBase64, newBrowserWindow)
      }
    }
    if (!getToggles().SHORTCUT_DISABLE_SIGN_SHARE_QUOTE) {
      pdfDocBase64 = await signPdfDoc(pdfDocBase64, props.accessToken, props.birthdate)
    }
    if (getToggles().ENABLE_HANDLE_PDF_CHROME_ANDROID) {
      await openPdf(pdfDocBase64, newBrowserWindow)
    } else {
      return openPdf(pdfDocBase64, newBrowserWindow)
    }
  } catch (e) {
    /* istanbul ignore next */
    if (newBrowserWindow) {
      newBrowserWindow.close()
    }

    if (e.message === 'SIGN ERROR') return

    console.error('PDF Failure: ' + e.message)
    if (getToggles().ENABLE_RED_TRUCK_ERROR_MSG) throw e
    else throw new Error(MESSAGES.GENERATE_PDF_ERROR_MESSAGE)
  }
  if (getToggles().ENABLE_HANDLE_PDF_CHROME_ANDROID) {
    await new Promise((resolve, reject) => {
      setTimeout(() => {
        if (newBrowserWindow && !newBrowserWindow.location.href.startsWith('blob')) {
          newBrowserWindow.close()
          reject(new Error(MESSAGES.CHECK_PDF_MESSAGE))
        }
        resolve()
      }, 500)
    })
    return
  }
}
/* istanbul ignore next */
export const shareQuickQuote = async (props: BIProps) => {
  /* istanbul ignore next */
  const { openPdf, openNewWindowWithLoading } = await import(/* webpackChunkName: "openPdf" */ './pdf-opener.js')

  await _shareQuickQuote(props, openPdf, openNewWindowWithLoading)
}
