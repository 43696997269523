import _ from 'lodash'
import { createField, createSectionCreator } from './utils'
import { addressFields, twoLinesAddressField } from './addressSection'

import {
  getDefaultPersonalTitleList,
  getRelationshipListForBeneficiary,
  getInsureOrPayerFirstname,
  getInsureOrPayerLastname,
} from 'e-submission/apps/selectors'
import { getFullNameWithDefault, isJuvenileButNotKid, getBeneficiaryRelationship } from './selectors'
import { getToggles } from 'quick-quote/feature-toggles'
import { isMRTACategory, isPACategory } from './selectors'
import { setAppValue, setAllocationBeneficiaryMRTA } from 'e-submission/apps/actions'

const dataFields = (app, id) => {
  const title = _.get(app, `${id}.title`)
  const isOtherTitle = _.get(title, 'value') === 'other'
  const isShowRemoveButton = !id.includes('[0]')
  const isShowRelationshipMessage = (app, id) => {
    const beneficiaryRelationship = getBeneficiaryRelationship(app, id)
    if (_.isEmpty(beneficiaryRelationship)) {
      return false
    }
    return !_.includes(['SPOUSE', 'PARENT', 'CHILD'], beneficiaryRelationship)
  }

  return _.compact([
    createField(`${id}.title`, [
      {
        label: 'คำนำหน้า',
        id: `${id}.title`,
        c: 'Dropdown',
        required: true,
        p: {
          placeHolder: 'กรุณาเลือกคำนำหน้าชื่อ',
          mergeState: (state) => ({
            options: getDefaultPersonalTitleList(state),
          }),
        },
      },
    ]),
    isOtherTitle &&
      createField(`${id}.title.text`, [
        {
          label: 'ระบุคำนำหน้า',
          id: `${id}.title.text`,
          c: 'TextInput',
          required: true,
        },
      ]),
    createField(`${id}.firstName`, [
      {
        label: 'ชื่อของผู้รับประโยชน์',
        id: `${id}.firstName`,
        required: true,
        c: 'TextInput',
        p: {
          mergeState: (state) => {
            return {
              value: getInsureOrPayerFirstname(id, state),
            }
          },
          onChange: ({ value, dispatch }) => {
            dispatch(setAppValue(`${id}.firstName`, _.trim(value)))
          },
          mergeProps: ({ dispatch }) => ({
            onBlur: (e) => {
              dispatch(setAppValue(`${id}.firstName`, _.trim(e.target.value)))
            },
          }),
        },
      },
    ]),
    createField(`${id}.lastName`, [
      {
        label: 'นามสกุลของผู้รับประโยชน์',
        id: `${id}.lastName`,
        required: true,
        c: 'TextInput',
        p: {
          mergeState: (state) => {
            return {
              value: getInsureOrPayerLastname(id, state),
            }
          },
          onChange: ({ value, dispatch }) => {
            dispatch(setAppValue(`${id}.lastName`, _.trim(value)))
          },
          mergeProps: ({ dispatch }) => ({
            onBlur: (e) => {
              dispatch(setAppValue(`${id}.lastName`, _.trim(e.target.value)))
            },
          }),
        },
      },
    ]),
    createField(`${id}.sex`, [
      {
        label: 'เพศ',
        id: `${id}.sex`,
        c: 'RadioButton',
        required: true,
        p: {
          options: [
            {
              text: 'ชาย',
              value: 'male',
            },
            {
              text: 'หญิง',
              value: 'female',
            },
          ],
        },
      },
    ]),
    createField(`${id}.birthdate`, [
      {
        label: 'วันเกิด',
        id: `${id}.birthdate`,
        c: 'DateDropdownInput',
      },
    ]),
    createField(`${id}.relations-${getBeneficiaryRelationship(app, id)}`, [
      {
        label: 'ความสัมพันธ์',
        id: `${id}.relations`,
        c: 'Dropdown',
        required: true,
        p: {
          mergeState: (state) => ({
            options: getRelationshipListForBeneficiary(state),
          }),
          placeHolder: 'กรุณาเลือกความสัมพันธ์',
        },
        infoMessage: isShowRelationshipMessage(app, id)
          ? `ความสัมพันธ์ที่ท่านเลือกเข้าเงื่อนไขที่ต้องแนบหลักฐานพิสูจน์ความสัมพันธ์ระหว่าง ผู้ขอเอาประกันภัยและผู้รับประโยชน์ให้ท่านแนบเอกสารที่เกี่ยวข้องในขั้นตอนส่งใบคำขอเอาประกันภัย`
          : '',
      },
    ]),
    isShowRemoveButton &&
      createField(
        `${id}.remove`,
        [
          {
            id: `${id}.remove`,
            c: 'Button',
            p: {
              icon: 'remove',
              children: 'ลบ',
              mergeProps: ({ removeValue, dispatch }) => ({
                onClick: () => {
                  removeValue(id)
                  if (isMRTACategory(app)) {
                    dispatch(setAllocationBeneficiaryMRTA())
                  }
                },
              }),
              style: {
                fontSize: '0.8725rem',
                padding: '0.125rem 0.5rem',
              },
            },
          },
        ],
        {
          style: {
            position: 'absolute',
            top: '0',
            right: '0',
          },
        }
      ),
  ])
}

const beneficiaryAddressFields = (app, id) => {
  const isUseAdress = _.get(app, `${id}.beneficiaryAddress.useCurrentInsuredAddress`)
  return [
    createField(`${id}.beneficiaryAddress.useCurrentInsuredAddress`, [
      {
        id: `${id}.beneficiaryAddress.useCurrentInsuredAddress`,
        c: 'Checkbox',
        p: {
          children: 'ที่อยู่เดียวกับผู้เอาประกันภัย',
        },
      },
    ]),
    ...(isUseAdress
      ? []
      : getToggles().ENABLE_2_LINES_ADDRESS
      ? twoLinesAddressField('beneficiaryAddress')(app, id)
      : addressFields('beneficiaryAddress')(app, id)),
  ]
}

const beneficiaryAllocation = (app, id) => [
  createField(`${id}.allocation`, [
    {
      label: 'ร้อยละของผลประโยชน์',
      id: `${id}.allocation`,
      c: 'NumberInput',
      required: true,
      p: {
        thousandSeparator: true,
        suffix: '%',
        disabled: isMRTACategory(app),
      },
    },
  ]),
]

const addNewBeneficiary = (app, id, section) => [
  createField(`${id}.addNew`, [
    {
      id,
      c: 'Button',
      p: {
        icon: 'add',
        children: 'เพิ่มผู้รับประโยชน์',
        block: true,
        mergeProps: ({ setValue, dispatch }) => ({
          onClick: () => {
            setValue(null)
            if (isMRTACategory(app)) {
              setValue({ allocation: 1 })
              dispatch(setAllocationBeneficiaryMRTA())
            }
          },
        }),
      },
    },
  ]),
]

const payerFields = (app, id) => {
  const getFullName = getFullNameWithDefault('payer')
  return [
    createField(`${id}.fullname`, [
      {
        id: `${id}.fullname`,
        c: 'PlainText',
        p: {
          mergeState: (state, app) => ({
            value: `${getFullName(app)} - <span class="font-weight-normal">(ผู้ชำระเบี้ย)</span>`,
          }),
        },
      },
    ]),
    ...beneficiaryAllocation(app, id),
  ]
}

const bankFields = (app, id) => {
  const bankName = _.get(app, `${id}.firstName`) + ' ' + _.get(app, `${id}.lastName`)
  return [
    createField(`${id}.bankName`, [
      {
        id: `${id}.bankName`,
        c: 'PlainText',
        p: {
          children: bankName,
        },
      },
    ]),
    ...beneficiaryAllocation(app, id),
  ]
}

export default (sectionId, sectionLabel) => (app, id) => {
  const appBeneficiary = _.get(app, id, [null])
  const maxBeneficiary = isPACategory(app) ? 3 : 4
  return _.compact(
    _.reduce(
      appBeneficiary,
      (result, el, i) => {
        const isShowPayer = i === 0 && isJuvenileButNotKid(app)

        if (isShowPayer) {
          return [
            ...result,
            createSectionCreator(payerFields)(`${sectionId}[${i}]`, null, null, `${sectionLabel} ${i + 1}`)(
              app,
              `${id}[${i}]`
            ),
          ]
        }

        const isShowBank = i === 0 && isMRTACategory(app)
        if (isShowBank) {
          return [
            ...result,
            createSectionCreator(bankFields)(`${sectionId}[${i}]`, null, null, `${sectionLabel} ${i + 1}`)(
              app,
              `${id}[${i}]`
            ),
          ]
        }

        return [
          ...result,
          createSectionCreator(dataFields)(`${sectionId}[${i}]`, null, null, `${sectionLabel} ${i + 1}`)(
            app,
            `${id}[${i}]`
          ),
          createSectionCreator(beneficiaryAddressFields)(
            `${sectionId}[${i}]`,
            'ที่อยู่ของผู้รับประโยชน์',
            null,
            null,
            'form-section__beneficiary__address'
          )(app, `${id}[${i}]`),
          createSectionCreator(beneficiaryAllocation)(`${sectionId}[${i}]`)(app, `${id}[${i}]`),
        ]
      },
      []
    ).concat(
      appBeneficiary.length < maxBeneficiary &&
        createSectionCreator(addNewBeneficiary)(sectionId, null, null, null, 'form-section__beneficiary__btn-add-new')(
          app,
          `${id}[${appBeneficiary.length}]`
        )
    )
  )
}
