// @flow
import { sagas as analyticsSagas } from 'analytics'
import {
  APPLICATION_FETCH_UPDATE_ERROR,
  APPLICATION_FETCH_UPDATE_SUCCESS,
  APPLICATION_INSTALL_UPDATE_ERROR,
  APPLICATION_INSTALL_UPDATE_SUCCESS,
} from 'core/action-types'
import { applicationFetchUpdate, applicationCheckVersion } from 'core/actions'
import type { DataWrapper } from 'core/data'
import { getViewConfiguration } from 'core/data-model/basic-plan'
import BI_MESSAGES from 'core/data-model/constants/bi-messages'
import DEFAULTS from 'core/data-model/constants/defaults'

import VALUE from 'core/data-model/constants/values'
import type { DistributorGroup } from 'core/data-model/distributor'
import { getFNAConfiguration } from 'core/data-model/fna'
import type { IdToken, User } from 'core/data-model/identity/types'
import core from 'core/sagas'
import { listDisplayProducts, listRiders } from 'core/service'
import middlewareSvc from 'core/service/advisorzone-middleware/request-wrapper'

import { getValidationErrorMessage } from 'core/service/advisorzone-middleware/validation-error-message'
import type { DisplayProduct } from 'core/service/display-product'
import { listDistributorGroups } from 'core/service/distributor'
import { getAuthenticatedUserProfile } from 'core/service/identity'

import { isNil } from 'core/service/lib/type-check'
import { getAppConfig } from 'deploy-env/app-config'

import { createNewApp, loadQuickQuote } from 'e-submission/apps/actions'

import * as services from 'e-submission/domain/service'
import {
  GET_DISTRIBUTOR_GROUPS_FAILED,
  GET_DISTRIBUTOR_GROUPS_REQUEST,
  GET_DISTRIBUTOR_GROUPS_SUCCESS,
  getDistributorGroupsFailed,
  getDistributorGroupsRequest,
  getDistributorGroupsSuccess,
  updateAdditionalUserDetails,
  updateUser,
  updateCheckNativeAppVersionUser,
} from 'identity/actions'
import { getDistributorGroups, getDistributorGroupsStatus, getIdentityUser } from 'identity/selectors'
import { logoutFromIDP } from 'identity/service'
import { isMobileOnline } from 'lib/cordova-util'

import { omit } from 'lodash/fp'
import {
  ConnectToDatabaseRequest,
  InitialiseApplicationRequest,
  resetQuoteForm,
  backToHome,
  reloadApplicationRequest,
  resetSelectedRemoteSelling,
  resetISSocial,
} from 'quick-quote/actions'
import { getNavTabList } from 'quick-quote/components/navigations/tabs'
import { PATH, ROUTE_PATH, fnaPath, qqPath } from 'quick-quote/constants/path'
import {
  UPDATE_MATURITY_VALIDATE,
  selectPolicyOwner,
  resetQuoteFormAfterMaturityValidate,
} from 'quick-quote/product-s7cc/actions'
import { getMaturityValidate } from 'quick-quote/product-s7cc/selectors'

import { getToggles } from 'quick-quote/feature-toggles'
import { sagas as fnaMarketConductSagas } from 'quick-quote/fna/market-conducts/sagas'
import { sagas as fnaNeedGapSagas } from 'quick-quote/fna/need-and-gap-analysis/sagas'
import { sagas as fnaSagas } from 'quick-quote/fna/sagas'
import { sagas as InsuredInformationSagas } from 'quick-quote/insured-information'
import { getOccupations, getOccupationsStatus } from 'quick-quote/insured-information/selectors'
import { toQuickQuoteInterface } from 'quick-quote/interface'
import { setNotificationMessage } from 'quick-quote/notification/actions'
import { sagas as productCommonBenefitIllustrationSagas } from 'quick-quote/product-common/benefit-illustration'
import { getBIProps } from 'quick-quote/product-common/benefit-illustration/sagas'
import { sagas as productWholeLifeCoveragePlanSagas } from 'quick-quote/product-common/coverage-plan'
import {
  getModelFactors,
  getModelFactorsStatus,
  getRiders,
  getRidersStatus,
} from 'quick-quote/product-common/coverage-plan/selectors'
import { sagas as productFlexiHealthCoveragePlanSagas } from 'quick-quote/product-flexi-health/coverage-plan'
import { sagas as productHealthToppingSagas } from 'quick-quote/product-health-topping/sagas'
import { sagas as productHealthCoveragePlanSagas } from 'quick-quote/product-health/coverage-plan'
import { sagas as productIHealthyUltraCoveragePlanSagas } from 'quick-quote/product-ihealthy-ultra/coverage-plan'
import { sagas as productIHealthyUltraSagas } from 'quick-quote/product-ihealthy-ultra/sagas'
import { sagas as productLifeReadySagas } from 'quick-quote/product-life-ready/sagas'
import { sagas as productBumnanReadySagas } from 'quick-quote/product-bumnan-ready/sagas'
import { sagas as productInvestmentBenefitIllustrationSagas } from 'quick-quote/product-investment/benefit-illustration/sagas'
import { sagas as productInvestmentCoveragePlanSagas } from 'quick-quote/product-investment/coverage-plan'
import { sagas as fundAllocationSagas } from 'quick-quote/product-investment/fund-allocation/sagas'
import { sagas as productLifeSaveProBenefitSagas } from 'quick-quote/product-life-save-pro/benefit-illustration/sagas'
import { sagas as productLifeSaveProSagas } from 'quick-quote/product-life-save-pro/sagas'
import { sagas as productLoanBenefitIllustrationSagas } from 'quick-quote/product-loan/benefit-illustration/sagas'
import { sagas as productLoanCoveragePlanSagas } from 'quick-quote/product-loan/coverage-plan'
import { sagas as productPerfectLifeSagas } from 'quick-quote/product-perfect-life/sagas'
import { sagas as productSukhapabDekDeeSagas } from 'quick-quote/product-sukhapabdekdee/sagas'
import { sagas as productProtectionSagas } from 'quick-quote/product-protection/coverage-plan/sagas'
import { sagas as productSavingSagas } from 'quick-quote/product-saving/coverage-plan/sagas'
import { sagas as productSelectionSagas } from 'quick-quote/product-selection/sagas'
import { sagas as productS7CCSagas } from 'quick-quote/product-s7cc/sagas'
import { sagas as productTermLifeSagas } from 'quick-quote/product-term-life/sagas'
import { sagas as productInvestmentBenefitTermLifeSagas } from 'quick-quote/product-term-life/benefit-illustration/sagas'
import { sagas as productMRTASagas } from 'quick-quote/product-mrta/sagas'
import { sagas as productPASagas } from 'quick-quote/product-pa/sagas'
import { sagas as remoteSelling } from 'quick-quote/remote-selling/sagas'
import { sagas as optySagas } from 'quick-quote/opty/sagas'
import { sagas as mwPlusSagas } from 'quick-quote/my-wealth-plus/sagas'

import {
  getDisplayProducts,
  getDisplayProductsStatus,
  getSelectedDisplayProduct,
} from 'quick-quote/product-selection/selectors'
import { sagas as benefitIllustrationSelectionSagas } from 'quick-quote/product-whole-life/benefit-illustration'
import { sagas as productLifeProtect18Sagas } from 'quick-quote/product-whole-life/sagas'
import { getAppState } from 'quick-quote/selectors'

import { APIErrorWatcher } from 'quick-quote/util/api-watcher'
import { delay, eventChannel } from 'redux-saga'
import { apply, call, put, race, select, spawn, take, takeEvery, takeLatest, all } from 'redux-saga/effects'
import {
  BACK_TO_HOME,
  CLICK_HOME_BUTTON,
  CONNECT_TO_DATABASE_FAILED,
  CONNECT_TO_DATABASE_REQUEST,
  CONNECT_TO_DATABASE_SUCCESS,
  connectToDatabaseFailed,
  connectToDatabaseRequest,
  connectToDatabaseSuccess,
  GET_MODEL_FACTORS_FAILED,
  GET_MODEL_FACTORS_SUCCESS,
  GET_OCCUPATIONS_FAILED,
  GET_OCCUPATIONS_SUCCESS,
  GET_PRODUCTS_FAILED,
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_RIDERS_FAILED,
  GET_RIDERS_REQUEST,
  GET_RIDERS_SUCCESS,
  getModelFactorsRequest,
  getOccupationsRequest,
  getProductsFailed,
  getProductsRequest,
  getProductsSuccess,
  getRidersFailed,
  getRidersRequest,
  getRidersSuccess,
  INITIALISE_APPLICATION_REQUEST,
  initialiseApplicationFailedWithNoInternet,
  initialiseApplicationServerIsDown,
  initialiseApplicationSuccess,
  LOGIN_REQUEST,
  LOGOUT_REQUEST,
  RELOAD_APPLICATION_REQUEST,
  setAllowedAgentTypes,
  setFnaConfiguration,
  setVersion,
  setViewConfiguration,
  START_REMOTE_SELLING,
  START_STANDARD_SELLING,
  UPDATE_APPLICATION_REQUEST,
  CHECK_VERSION_APP,
} from './actions'
import { isFNAPath, isQuickQuotePath } from './components/navigations/utils'
import { ENABLE_FNA_PHASE } from './fna/actions'

import { getAppVersion, getIsUserOnline } from './selectors'
import type { Version } from './version'

import { getDevice } from './util/getDevice'
import { resetRemoteSellingState } from './remote-selling/actions'
import { analytics } from 'analytics/analytics'
import { IsRealDevice } from 'e-submission/utils'
import { compateLt } from 'deploy-env/app-config'

export const reloadApplication = (location: *) => location.reload(true)

export const updateApplication = (location: *) => {
  if (window.cordova) {
    if (window.cordova.platformId === 'ios') {
      return window.open('https://itunes.apple.com/us/app/advisorzone/id1287893930?ls=1&mt=8', '_self')
    } else {
      return window.open('https://play.google.com/store/apps/details?id=com.ktaxa.advisorzone', '_self')
    }
  }
  return location.reload(true)
}

export function* onReloadApplicationRequest(location: *): Generator<*, *, *> {
  yield call(reloadApplication, location)
}

export function* onUpdateApplicationRequest(location: *): Generator<*, *, *> {
  yield call(updateApplication, location)
}

/* eslint-disable no-console */
export function* onInitialiseApplicationRequest(action: InitialiseApplicationRequest): Generator<*, *, *> {
  console.info('onInitialiseApplicationRequest')
  console.info('try to connectToDatabaseRequest')
  yield put(connectToDatabaseRequest(action.dbLocalUrl))
  console.info('connectToDatabaseRequest success')
  if (getToggles().ENABLE_AUTHORISATION_CODE_FLOW || getToggles().ENABLE_OFFLINE_ACCESS_FLOW_ONLY) {
    console.info('validateAcessToken')
    const validateToken = yield* validateAcessToken()
    if (validateToken && validateToken.data.isTokenValid) {
      console.info('updateUserInfoAction')
      yield* updateUserInfoAction()
    } else {
      localStorage.removeItem('userInfo')
      localStorage.removeItem('sessionState')
    }
  }
}
/* eslint-enable no-console */
export function* checkIfApplicationDataIsReady(dataWrapper: DataWrapper): Generator<*, *, *> {
  const products = yield select(getDisplayProducts)
  const productsStatus = yield select(getDisplayProductsStatus)
  const riders = yield select(getRiders)
  const ridersStatus = yield select(getRidersStatus)
  const occupations = yield select(getOccupations)
  const occupationsStatus = yield select(getOccupationsStatus)
  const modelFactors = yield select(getModelFactors)
  const modelFactorsStatus = yield select(getModelFactorsStatus)
  const distributorGroups = yield select(getDistributorGroups)
  const distributorGroupsStatus = yield select(getDistributorGroupsStatus)
  const canLoadLookUpData = yield apply(dataWrapper, dataWrapper.canLoadLookUpData)

  const statuses = [productsStatus, ridersStatus, occupationsStatus, modelFactorsStatus, distributorGroupsStatus]

  const allItem = [products, riders, occupations, modelFactors, distributorGroups]

  const isAllNonEmpty = allItem.every((item) => item.length)

  if (
    statuses.includes('error') ||
    (statuses.every((status) => status === 'ready') && !isAllNonEmpty) ||
    !canLoadLookUpData
  ) {
    yield put(reloadApplicationRequest())
    yield put(initialiseApplicationServerIsDown())
  } else if (isAllNonEmpty) {
    const appVersion = yield select(getAppVersion)

    const version: Version = {
      appVersion,
    }

    console.warn(`appVersion: ${appVersion}`)

    if (isNil(appVersion) || appVersion === '') {
      yield put(reloadApplicationRequest())
      yield put(initialiseApplicationServerIsDown())
    } else {
      yield put(setVersion(version))
      yield put(setAllowedAgentTypes(getAppConfig().ALLOWED_AGENT_TYPES_FOR_ESUBMISSION))
      const viewConfiguration = yield call(getViewConfiguration)
      yield put(setViewConfiguration(viewConfiguration))
      const fnaConfiguration = yield call(getFNAConfiguration)
      yield put(setFnaConfiguration(fnaConfiguration))
      yield put(initialiseApplicationSuccess())
    }
  }
}

export function* onAnyRequiredDataRequestFailed(action: *): Generator<*, *, *> {
  const isUserOnline = yield select(getIsUserOnline)

  if (isUserOnline) {
    yield put(reloadApplicationRequest())
    yield put(initialiseApplicationServerIsDown())
  } else {
    yield put(initialiseApplicationFailedWithNoInternet())
  }
}

export function* onConnectToDatabaseRequest(
  dataWrapper: DataWrapper,
  action: ConnectToDatabaseRequest
): Generator<*, *, *> {
  try {
    yield call(dataWrapper.init, action.dbLocalUrl)
    yield put(connectToDatabaseSuccess())
  } catch (error) {
    yield put(connectToDatabaseFailed())
  }
}

export function* onGetProductsRequest(dataWrapper: DataWrapper): Generator<*, *, *> {
  try {
    const products: DisplayProduct[] = yield call(listDisplayProducts)
    const {
      SHOW_LOAN_PRODUCT,
      SHOW_SPUL,
      SHOW_PRODUCT_ICARE,
      SHOW_PRODUCT_25PL,
      SHOW_PRODUCT_5WL85,
      SHOW_PRODUCT_IGROW,
      SHOW_PRODUCT_25P15,
      SHOW_PRODUCT_20P10,
      SHOW_PRODUCT_FLEXI_HEALTH,
      SHOW_PRODUCT_25P15S,
      SHOW_PRODUCT_20P10S,
      SHOW_PRODUCT_W85P10J,
      SHOW_PRODUCT_W85P10S,
      SHOW_PRODUCT_WL88J,
      SHOW_PRODUCT_WL88S,
      SHOW_PRODUCT_IRETIRE_R01,
      SHOW_PRODUCT_IRETIRE_R05,
      SHOW_PRODUCT_LR05,
      SHOW_PRODUCT_PERFECT_LIFE_SOLUTION,
      SHOW_PRODUCT_HEALTH_TOPPING,
      SHOW_PRODUCT_IPROTECT_10,
      SHOW_PRODUCT_12PL,
      SHOW_PRODUCT_SUKHAPABDEKDEE,
      SHOW_PRODUCT_PLB05,
      SHOW_PRODUCT_PLB10,
      SHOW_PRODUCT_PLB12,
      SHOW_PRODUCT_PLB15,
      SHOW_PRODUCT_RPUDR,
      SHOW_PRODUCT_IHEALTHY_ULTRA,
      SHOW_PRODUCT_CIWLM39,
      SHOW_PRODUCT_CIWLM50,
      SHOW_PRODUCT_CIWLM60,
      SHOW_PRODUCT_CIWLS39,
      SHOW_PRODUCT_CIWLS50,
      SHOW_PRODUCT_CIWLS65,
      SHOW_PRODUCT_LIFEREADY,
      SHOW_PRODUCT_LIFEREADY_CAMPAIGN,
      SHOW_PRODUCT_BUMNAN_READY,
      SHOW_PRODUCT_ULTIMATE_GROWTH,
      SHOW_PRODUCT_LIFE_SUPER_SAVE_14_5,
      SHOW_PRODUCT_MRTA,
      SHOW_PRODUCT_GLTSP,
      SHOW_PRODUCT_CANCERX5,
      SHOW_PRODUCT_KOOMVER_RETIRE,
      SHOW_PRODUCT_KOOMVER_COVER,
      SHOW_PRODUCT_KOOMVER_COMPENSATE,
      SHOW_PRODUCT_ISMART_80_6,
      SHOW_PRODUCT_LIFETREASURE,
      SHOW_PRODUCT_PA,
    } = yield call(getToggles)
    let filteredProducts = products
      .filter((product) => SHOW_PRODUCT_25PL || product.code !== '25PL')
      .filter((product) => SHOW_PRODUCT_5WL85 || product.code !== VALUE['5WL85'])
      .filter((product) => SHOW_LOAN_PRODUCT || product.category !== VALUE.LOAN)
      .filter((product) => SHOW_SPUL || product.code !== VALUE.SPUL)
      .filter((product) => SHOW_PRODUCT_RPUDR || product.code !== 'RPUDR')
      .filter((product) => SHOW_PRODUCT_ICARE || product.code !== VALUE.ICARE)
      .filter((product) => SHOW_PRODUCT_IGROW || product.code !== VALUE.IGROW)
      .filter((product) => SHOW_PRODUCT_20P10 || product.code !== '20P10')
      .filter((product) => SHOW_PRODUCT_25P15 || product.code !== '25P15')
      .filter((product) => SHOW_PRODUCT_FLEXI_HEALTH || product.code !== 'FWLNP85')
      .filter((product) => SHOW_PRODUCT_25P15S || product.code !== '25P15S')
      .filter((product) => SHOW_PRODUCT_20P10S || product.code !== '20P10S')
      .filter((product) => SHOW_PRODUCT_W85P10J || product.code !== 'W85P10J')
      .filter((product) => SHOW_PRODUCT_W85P10S || product.code !== 'W85P10S')
      .filter((product) => SHOW_PRODUCT_WL88J || product.code !== 'WL88J')
      .filter((product) => SHOW_PRODUCT_WL88S || product.code !== 'WL88S')
      .filter((product) => SHOW_PRODUCT_IRETIRE_R01 || product.code !== 'R01')
      .filter((product) => SHOW_PRODUCT_IRETIRE_R05 || product.code !== 'R05')
      .filter((product) => SHOW_PRODUCT_LR05 || product.code !== 'LR05')
      .filter((product) => SHOW_PRODUCT_PLB05 || product.code !== 'PLB05')
      .filter((product) => SHOW_PRODUCT_PLB10 || product.code !== 'PLB10')
      .filter((product) => SHOW_PRODUCT_PLB12 || product.code !== 'PLB12')
      .filter((product) => SHOW_PRODUCT_PLB15 || product.code !== 'PLB15')
      .filter((product) => SHOW_PRODUCT_PERFECT_LIFE_SOLUTION || !VALUE.PERFECT_LIFE.includes(product.code))
      .filter((product) => SHOW_PRODUCT_HEALTH_TOPPING || product.code !== VALUE.HEALTH_TOPPING_CODE)
      .filter((product) => SHOW_PRODUCT_IPROTECT_10 || product.code !== '10WL85')
      .filter((product) => SHOW_PRODUCT_12PL || product.code !== '12PL')
      .filter((product) => SHOW_PRODUCT_SUKHAPABDEKDEE || product.code !== VALUE.SUKHAPABDEKDEE_CODE)
      .filter((product) => SHOW_PRODUCT_IHEALTHY_ULTRA || product.code !== VALUE.IHEALTHY_ULTRA_CODE.LIFE_LEGACY_99)
      .filter((product) => SHOW_PRODUCT_CIWLM39 || product.code !== VALUE.CIWLM39)
      .filter((product) => SHOW_PRODUCT_CIWLM50 || product.code !== VALUE.CIWLM50)
      .filter((product) => SHOW_PRODUCT_CIWLM60 || product.code !== VALUE.CIWLM60)
      .filter((product) => SHOW_PRODUCT_CIWLS39 || product.code !== VALUE.CIWLS39)
      .filter((product) => SHOW_PRODUCT_CIWLS50 || product.code !== VALUE.CIWLS50)
      .filter((product) => SHOW_PRODUCT_CIWLS65 || product.code !== VALUE.CIWLS65)
      .filter((product) => SHOW_PRODUCT_LIFEREADY || product.code !== VALUE.LIFEREADY_CODE.LIFEREADY18_UNDER)
      .filter((product) => SHOW_PRODUCT_LIFEREADY_CAMPAIGN || !VALUE.LIFEREADY_CAMPAIGN_CODE.includes(product.code))
      .filter((product) => SHOW_PRODUCT_BUMNAN_READY || !VALUE.BUMNAN_READY_CODE_GROUP.includes(product.code))
      .filter((product) => SHOW_PRODUCT_ULTIMATE_GROWTH || !VALUE.ULTIMATE_GROWTH_CODE_GROUP.includes(product.code))
      .filter((product) => SHOW_PRODUCT_LIFE_SUPER_SAVE_14_5 || product.code !== VALUE.LIFE_SUPER_SAVE_14_5_CODE)
      .filter((product) => SHOW_PRODUCT_MRTA || product.category !== VALUE.MRTA)
      .filter((product) => SHOW_PRODUCT_GLTSP || product.category !== VALUE.MRTA)
      .filter((product) => SHOW_PRODUCT_CANCERX5 || product.code !== VALUE.CANCERX5_DEFAULT_CODE)
      .filter((product) => SHOW_PRODUCT_KOOMVER_RETIRE || product.code !== VALUE.KOOMVER_RETIRE)
      .filter((product) => SHOW_PRODUCT_KOOMVER_COVER || product.code !== VALUE.KOOMVER_COVER_DEFAULT_CODE)
      .filter(
        (product) => SHOW_PRODUCT_KOOMVER_COMPENSATE || !VALUE.KOOMVER_COMPENSATE_DEFAULT_CODE.includes(product.code)
      )
      .filter((product) => SHOW_PRODUCT_ISMART_80_6 || product.code !== VALUE.ISMART_80_6)
      .filter((product) => SHOW_PRODUCT_LIFETREASURE || product.code !== VALUE.LIFETREASURE_CODE.LIFETREASURE_18)
      .filter((product) => SHOW_PRODUCT_PA || product.category !== VALUE.PA)

    yield put(getProductsSuccess(filteredProducts))
  } catch (error) {
    console.warn(error)
    yield put(getProductsFailed(error))
  }
}

export function* onGetDistributorGroupsRequest(dataWrapper: DataWrapper): Generator<*, *, *> {
  try {
    const distributorGroups: DistributorGroup[] = yield call(listDistributorGroups)
    yield put(getDistributorGroupsSuccess(distributorGroups))
  } catch (error) {
    console.warn(error)
    yield put(getDistributorGroupsFailed(error))
  }
}

export function* onGetRidersRequest(dataWrapper: DataWrapper): Generator<*, *, *> {
  try {
    const riders = yield call(listRiders)
    yield put(getRidersSuccess(riders))
  } catch (error) {
    yield put(getRidersFailed(error))
  }
}

export function* onConnectToDatabaseSuccess(): Generator<*, *, *> {
  yield put(getProductsRequest())
  yield put(getRidersRequest())
  yield put(getOccupationsRequest())
  yield put(getModelFactorsRequest())
  yield put(getDistributorGroupsRequest())
}

export function* onLogIn(userManager: *): Generator<*, *, *> {
  try {
    if (!isMobileOnline()) {
      throw new Error('Network is offline')
    }
    if (getToggles().ENABLE_AUTHORISATION_CODE_FLOW || getToggles().ENABLE_OFFLINE_ACCESS_FLOW_ONLY) {
      const loginWindowRef = yield* openLoginWindow()
      if (!window.cordova) {
        yield* waitForLoginWindowMessage()
        yield* ifLoginWindowClosed(loginWindowRef)
      } else {
        yield* userInfoListener(loginWindowRef)
        yield* watchCodovaInAppBrowser(loginWindowRef)
      }
      const userInfo = JSON.parse(localStorage.getItem('userInfo'))
      analytics.sendCustomEvent({
        category: 'Identity',
        action: 'Login_User_Type',
        label: userInfo.agent_type ? userInfo.agent_type : 'AGENT',
      })
    } else {
      yield userManager.signinPopup()
    }
  } catch (err) {
    if (err.message.indexOf('Network') !== -1) {
      yield put(setNotificationMessage({ type: 'Warning', subType: 'NoInternet' }))
    }
  }
}

export function* openLoginWindow(): Generator<*, *, *> {
  const middlewareLoginURL = `${getAppConfig().ADVISORZONE_MIDDLEWARE}/login`
  const appVersion = getAppVersion()
  const device = getDevice()
  let queryString = `?isRealDevice=${IsRealDevice}`
  if (getToggles().ENABLE_CHECK_NATIVE_APPVERSION) {
    queryString = `?applicationVersion=${appVersion}&device=${device}&isRealDevice=${IsRealDevice}`
  }
  const loginWindow = yield window.cordova
    ? window.cordova.InAppBrowser.open(`${middlewareLoginURL}/mobile${queryString}`, '_blank', 'location=no,zoom=no')
    : window.open(`${middlewareLoginURL}/web${queryString}`)

  if (getToggles().ENABLE_LODING_SPINNER_CORDOVA_IAB && window.cordova) {
    yield loginWindow.addEventListener('loadstart', (params, callback) => {
      window.plugins.spinnerDialog.show()
    })
    yield loginWindow.addEventListener('loadstop', (event) => {
      window.plugins.spinnerDialog.hide()
      loginWindow.show()
    })
  }

  return loginWindow
}

function* waitForLoginWindowMessage(): Generator<*, *, *> {
  localStorage.removeItem('userInfo')
  localStorage.removeItem('sessionState')
  window.addEventListener('message', handlePostMessage, true)
}

function* userInfoListener(cordovaWindow): Generator<*, *, *> {
  localStorage.removeItem('userInfo')
  localStorage.removeItem('sessionState')
  yield cordovaWindow.addEventListener('loadstop', (event) => {
    const loop = setInterval(() => {
      cordovaWindow.executeScript({ code: 'localStorage.getItem( "userInfo" )' }, (values) => {
        const value = values[0]
        if (value === '"logout"') {
          logoutFromIDP()
          clearInterval(loop)
          window.location.reload()
          setTimeout(() => {
            cordovaWindow.close()
          }, 1000)
        } else if (value) {
          clearInterval(loop)
          localStorage.setItem('userInfo', value)
          setTimeout(() => {
            cordovaWindow.close()
          }, 1000)
        }
      })
    }, 1000)
  })
}

function* watchCodovaInAppBrowser(cordovaWindow): Generator<*, *, *> {
  const cordovaChannel = eventChannel((emit) => {
    cordovaWindow.addEventListener('exit', (event) => {
      emit({ window: { closed: true } })
    })

    return () => {
      cordovaWindow.removeEventListener('exit', (event) => {
        emit({ window: { closed: false } })
      })
    }
  })
  // prettier-ignore
  while (true) { // NOSONAR
    const cordovaEvent = yield take(cordovaChannel)
    if (cordovaEvent.window.closed) {
      yield* updateUserInfoAction()
    }
  }
}

function handlePostMessage(event) {
  if (event.origin !== getAppConfig().ADVISORZONE_MIDDLEWARE) return
  localStorage.setItem('userInfo', JSON.stringify(event.data))
  event.target.removeEventListener(event.type, handlePostMessage, true)
}

function handleLogoutPostMessage(event) {
  if (event.origin !== getAppConfig().ADVISORZONE_MIDDLEWARE) return
  localStorage.removeItem('userInfo')
  localStorage.removeItem('sessionState')
  event.target.removeEventListener(event.type, handleLogoutPostMessage, true)
}

function* waitForLogoutWindowMessage(): Generator<*, *, *> {
  window.addEventListener('message', handleLogoutPostMessage, true)
}

function* ifLoginWindowClosed(loginWindow): Generator<*, *, *> {
  while (true) {
    if (yield loginWindow.closed) {
      yield* updateUserInfoAction()
      break
    }
    yield delay(1000)
  }
}

export function* updateUserInfoAction(): Generator<*, *, *> {
  const storedValue = localStorage.getItem('userInfo')
  if (storedValue) {
    const idTokenInfo: IdToken = JSON.parse(storedValue)
    const user: User = yield getAuthenticatedUserProfile(idTokenInfo)
    yield put(updateUser(user))
    yield put(updateAdditionalUserDetails())

    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    let appVersion = yield select(getAppVersion)
    try {
      appVersion = yield window.getAppVersionByBridging()
    } catch (e) {
      return e
    }
    if (getToggles().ENABLE_CHECK_TARGET_VERSION && IsRealDevice) {
      if (compateLt(appVersion, userInfo.azMWMinVersion)) {
        yield put(applicationCheckVersion())
        yield put(updateCheckNativeAppVersionUser())
      }
    }
  }
}

export function* validateAcessToken(): Generator<*, *, *> {
  const storedValue = yield localStorage.getItem('userInfo')
  if (storedValue) {
    const idTokenInfo: IdToken = yield JSON.parse(storedValue)
    return yield middlewareSvc.getRequest('auth/validate', idTokenInfo, {})
  } else {
    return false
  }
}

export function* onLogOut(userManager: *): Generator<*, *, *> {
  if (getToggles().ENABLE_AUTHORISATION_CODE_FLOW || getToggles().ENABLE_OFFLINE_ACCESS_FLOW_ONLY) {
    const logoutWindow = yield* openLogoutWindow()
    if (!window.cordova) {
      yield* waitForLogoutWindowMessage()
      yield* ifLogoutWindowClosed(logoutWindow)
    } else {
      yield* userInfoListener(logoutWindow)
    }
  } else {
    yield userManager
      .signoutPopup()
      .then(() => {
        logoutFromIDP()
        window.location.reload()
      })
      .catch((err) => err)
  }
}

export function* openLogoutWindow(): Generator<*, *, *> {
  const middlewareLogoutURL = `${getAppConfig().ADVISORZONE_MIDDLEWARE}/logout`
  const logoutWindow = yield window.cordova
    ? window.cordova.InAppBrowser.open(`${middlewareLogoutURL}/mobile`, '_blank', 'location=no')
    : window.open(`${middlewareLogoutURL}/web`, '_blank')
  return logoutWindow
}

function* ifLogoutWindowClosed(logoutWindow): Generator<*, *, *> {
  while (true) {
    if (yield logoutWindow.closed && isNil(localStorage.getItem('userInfo'))) {
      logoutFromIDP()
      if (getToggles().ENABLE_IAMC_IDP) {
        setTimeout(() => {
          window.location.reload()
        }, 1500)
        break
      }
      window.location.reload()
      break
    }
    yield delay(500)
  }
}

export function* watchAPIError(): Generator<*, *, *> {
  // prettier-ignore
  while (true) { // NOSONAR
    const error = yield take(APIErrorWatcher)

    error.status === 403
      ? yield put(setNotificationMessage({ type: 'AuthenticationError' }))
      : yield put(
        setNotificationMessage({
          type: 'GenericError',
          message: getValidationErrorMessage(error.data),
          title: undefined,
        })
      )
  }
}

export function* startEsub(esubStore: *, history: *): Generator<*, *, *> {
  const { dispatch } = esubStore
  if (getToggles().ENABLE_MANUAL_CORDOVA_UPDATE && window.chcp) {
    // start update
    yield put(applicationFetchUpdate())
    // wait for update procrss to completed
    const { fetchError } = yield race({
      fetchSuccess: take(APPLICATION_FETCH_UPDATE_SUCCESS),
      fetchError: take(APPLICATION_FETCH_UPDATE_ERROR),
    })
    // block go to esub if failed
    if (fetchError) {
      return
    }
    const { installError } = yield race({
      installSuccess: take(APPLICATION_INSTALL_UPDATE_SUCCESS),
      installError: take(APPLICATION_INSTALL_UPDATE_ERROR),
    })
    // block go to esub if failed
    if (installError) {
      return
    }
  }

  const qqState = yield select(getAppState)
  const { category } = yield select(getSelectedDisplayProduct)

  const biProps = yield call(getBIProps, {
    category,
    biType: BI_MESSAGES.BI_TYPE_FULL,
  })
  const smallerBiProps = omit(['basicPlan.image'], biProps)
  const quickQuoteInterface = yield call(toQuickQuoteInterface, qqState, smallerBiProps)
  const user = yield select(getIdentityUser)

  yield call(dispatch, loadQuickQuote(quickQuoteInterface))
  yield call(dispatch, createNewApp(user))
  history.push(ROUTE_PATH.ESUBMISSION)
}

export function* startFnaPhase(history: *): Generator<*, *, *> {
  const state = yield select(getAppState)
  const tabList = getNavTabList({ state, location: history.location })
  const needTab = tabList.find((tab) => tab.path.indexOf(PATH.NEED_AND_GAP_ANALYSIS) > -1) || { path: '/' }
  // send user to need-and-gap
  history.push(needTab.path)
}

export function* handleBackToHome(history: *): Generator<*, *, *> {
  yield put(resetSelectedRemoteSelling())
  yield put(resetQuoteForm())
  yield put(resetRemoteSellingState())
  yield put(resetISSocial())
  history.push('/')
}

export function* onHomeButtonClick(history: *): Generator<*, *, *> {
  if (isFNAPath(history.location.pathname) || isQuickQuotePath(history.location.pathname)) {
    yield put(
      setNotificationMessage({
        type: 'ConfirmToHome',
      })
    )
  } else {
    yield put(backToHome())
  }
}

// remote selling

export function* startSelectPolicyOwner(history: *): Generator<*, *, *> {
  const maturityValidate = yield select(getMaturityValidate)
  if (maturityValidate.maturityStatus === false) {
    yield put(selectPolicyOwner(''))
    return
  } else {
    if (maturityValidate.isBuyForOwner) yield put(selectPolicyOwner(DEFAULTS.SELF))
    else yield put(selectPolicyOwner(DEFAULTS.SPOUSE))
  }

  yield put(resetQuoteFormAfterMaturityValidate())

  const path = isFNAPath(history.location.pathname)
    ? fnaPath(ROUTE_PATH.S7CC.SELECT_POLICY_OWNER)
    : isQuickQuotePath(history.location.pathname)
    ? qqPath(ROUTE_PATH.S7CC.SELECT_POLICY_OWNER)
    : history.location.pathname
  history.push(path)
}

export function* checkApplicationUpdateFromMiddleware(history: *): Generator<*, *, *> {}

export function* rootSaga(dataWrapper: DataWrapper, userManager: *, esubStore: *, history: *): Generator<*, *, *> {
  const CORE_DATA_SUCCESS_ACTIONS = [
    GET_PRODUCTS_SUCCESS,
    GET_RIDERS_SUCCESS,
    GET_OCCUPATIONS_SUCCESS,
    GET_MODEL_FACTORS_SUCCESS,
    GET_DISTRIBUTOR_GROUPS_SUCCESS,
  ]

  const CORE_DATA_FAILURE_ACTIONS = [
    CONNECT_TO_DATABASE_FAILED,
    GET_PRODUCTS_FAILED,
    GET_RIDERS_FAILED,
    GET_OCCUPATIONS_FAILED,
    GET_MODEL_FACTORS_FAILED,
    GET_DISTRIBUTOR_GROUPS_FAILED,
  ]

  yield all([
    takeEvery(LOGIN_REQUEST, onLogIn, userManager),
    takeEvery(LOGOUT_REQUEST, onLogOut, userManager),
    takeEvery(INITIALISE_APPLICATION_REQUEST, onInitialiseApplicationRequest),
    takeEvery(GET_RIDERS_REQUEST, onGetRidersRequest, dataWrapper),

    // $FlowFixMe
    takeEvery(CONNECT_TO_DATABASE_REQUEST, onConnectToDatabaseRequest, dataWrapper),
    takeEvery(CONNECT_TO_DATABASE_SUCCESS, onConnectToDatabaseSuccess),
    takeEvery(CORE_DATA_SUCCESS_ACTIONS, checkIfApplicationDataIsReady, dataWrapper),
    takeEvery(CORE_DATA_FAILURE_ACTIONS, onAnyRequiredDataRequestFailed),
    takeEvery(GET_PRODUCTS_REQUEST, onGetProductsRequest, dataWrapper),
    takeEvery(GET_DISTRIBUTOR_GROUPS_REQUEST, onGetDistributorGroupsRequest, dataWrapper),
    takeEvery(RELOAD_APPLICATION_REQUEST, onReloadApplicationRequest, window.location),
    takeEvery(UPDATE_APPLICATION_REQUEST, onUpdateApplicationRequest, window.location),
    takeLatest(START_STANDARD_SELLING, startEsub, esubStore, history),
    takeLatest(START_REMOTE_SELLING, startEsub, esubStore, history),
    takeLatest(ENABLE_FNA_PHASE, startFnaPhase, history),
    takeLatest(BACK_TO_HOME, handleBackToHome, history),
    takeLatest(CLICK_HOME_BUTTON, onHomeButtonClick, history),
    takeLatest(UPDATE_MATURITY_VALIDATE, startSelectPolicyOwner, history),
    takeLatest(CHECK_VERSION_APP, checkApplicationUpdateFromMiddleware, history),
    // remoteSelling
    getToggles().ENABLE_APPMAN_REMOTE_SELLING && spawn(remoteSelling, history, esubStore, services),

    spawn(fnaSagas),
    spawn(fnaNeedGapSagas),
    spawn(fnaMarketConductSagas),
    spawn(InsuredInformationSagas, dataWrapper),
    spawn(benefitIllustrationSelectionSagas, dataWrapper),
    spawn(productWholeLifeCoveragePlanSagas, dataWrapper),
    spawn(productCommonBenefitIllustrationSagas, dataWrapper),
    spawn(productLoanCoveragePlanSagas, dataWrapper),
    spawn(productHealthCoveragePlanSagas),
    spawn(productFlexiHealthCoveragePlanSagas),
    spawn(productIHealthyUltraCoveragePlanSagas),
    spawn(productIHealthyUltraSagas),
    spawn(productLifeReadySagas),
    spawn(productBumnanReadySagas),
    spawn(productS7CCSagas),
    spawn(productTermLifeSagas),
    spawn(productInvestmentBenefitTermLifeSagas),
    spawn(productMRTASagas),
    spawn(productPASagas),
    spawn(productLoanBenefitIllustrationSagas),
    spawn(productLifeSaveProSagas),
    spawn(productLifeSaveProBenefitSagas),
    spawn(productLifeProtect18Sagas),
    spawn(productPerfectLifeSagas),
    spawn(productHealthToppingSagas),
    spawn(productSukhapabDekDeeSagas),
    spawn(productInvestmentCoveragePlanSagas, dataWrapper),
    spawn(analyticsSagas),
    spawn(fundAllocationSagas),
    spawn(productInvestmentBenefitIllustrationSagas, dataWrapper),
    spawn(productSelectionSagas, history),
    spawn(productProtectionSagas),
    spawn(productSavingSagas),
    spawn(optySagas),
    spawn(mwPlusSagas, history),
    spawn(watchAPIError),
    spawn(core),
  ])
}
