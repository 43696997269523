//@flow
import { visitPath } from 'quick-quote/ui/actions'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router-dom'
import { compose } from 'recompose'
import { bindActionCreators } from 'redux'
import { analyticsPageViewEvent } from 'analytics/actions'
import { PATH, ROUTE_PATH } from '../../constants/path'
import { getToggles } from '../../feature-toggles'
import pageViewCapturer from '../../page-view-capturer'
import { PortalContainer } from '../../portal'
import { OptyList } from '../../opty'
import { MwpCustomerProfileList } from '../../my-wealth-plus'
import FNA from './fna/routes'
import OldQuickQuote from './old-quick-quote/routes'
import QuickQuote from './quick-quote/routes'
import { BrochureProductList } from '../../product-brochure'

const _Routes = ({ logPageView }) => (
  <Switch>
    <Route
      path={ROUTE_PATH.BROCHURE_LIST}
      component={(props) => <BrochureProductList logPageView={logPageView} {...props} />}
    />

    {getToggles().ENABLE_FNA_FLOW && <Route path={ROUTE_PATH.PORTAL} component={PortalContainer} />}
    {getToggles().ENABLE_FNA_FLOW && (
      <Route path={PATH.FNA} component={(props) => <FNA logPageView={logPageView} {...props} />} />
    )}
    {getToggles().ENABLE_FNA_FLOW && (
      <Route path={PATH.QUICK_QUOTE} component={(props) => <QuickQuote logPageView={logPageView} {...props} />} />
    )}
    {getToggles().ENABLE_OPTY_MENU && <Route path={ROUTE_PATH.OPTY} component={(props) => <OptyList {...props} />} />}
    {getToggles().ENABLE_MWP_MENU && (
      <Route path={ROUTE_PATH.MW_PLUS} component={(props) => <MwpCustomerProfileList {...props} />} />
    )}
    {getToggles().ENABLE_FNA_FLOW && <Redirect from="/" to={ROUTE_PATH.PORTAL} />}
    <Route component={(props) => <OldQuickQuote logPageView={logPageView} {...props} />} />
  </Switch>
)

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  logPageView: pageViewCapturer({
    pageViewHandlers: [bindActionCreators(analyticsPageViewEvent, dispatch), bindActionCreators(visitPath, dispatch)],
  }),
})

const connector = connect(null, mapDispatchToProps)

export default compose(connector)(_Routes)
