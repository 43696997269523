//@flow
import type { DisplayProduct } from 'core/service/display-product'
import type { AppState } from 'quick-quote/reducers'
import type { PermissionResult } from 'core/service/distributor/permission'
import type { Dispatch } from 'redux'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'

import { ROUTE_PATH, PATH } from 'quick-quote/constants/path'
import { BasicPlanSummary } from './basic-plan-summary'

import { isQuickQuotePath, isFNAPath } from 'quick-quote/components/navigations/tabs'
import { resetInvestmentPermission } from 'quick-quote/product-investment/validate-permission/actions'
import { getToggles } from '../../feature-toggles'

type InvestmentBasicPlanProps = ownProps & {
  proceedToInvestmentProduct: PermissionResult,
  resetInvestmentPermission: Function,
  location: Object,
  isSelected?: boolean,
  isOnFnaPhase?: boolean,
}

type ownProps = {
  displayProduct: DisplayProduct,
  isSelected?: boolean,
  cssClass: string,
  validateProduct: (DisplayProduct) => void,
}

const getRedirectLink = (location, isOnFnaPhase) => {
  const path = location && location.pathname

  if (isQuickQuotePath(path) && !isOnFnaPhase) {
    return PATH.QUICK_QUOTE + ROUTE_PATH.INVESTMENT.INSURED_INFORMATION
  } else if (isQuickQuotePath(path) && isOnFnaPhase) {
    return PATH.QUICK_QUOTE + ROUTE_PATH.INVESTMENT.MARKET_CONDUCTS
  } else if (isFNAPath(path)) {
    return getToggles().ENABLE_FNA_V3
      ? PATH.FNA + ROUTE_PATH.INVESTMENT.INSURED_INFORMATION
      : PATH.FNA + ROUTE_PATH.INVESTMENT.MARKET_CONDUCTS
  } else {
    return ROUTE_PATH.INVESTMENT.INSURED_INFORMATION
  }
}
export class _InvestmentBasicPlan extends React.Component<InvestmentBasicPlanProps, *> {
  componentWillUnmount() {
    // $FlowFixMe
    if (['PUSH', 'REPLACE'].includes(this.props.history.action)) {
      this.props.resetInvestmentPermission()
    }
  }

  render() {
    const {
      displayProduct,
      isSelected,
      cssClass,
      validateProduct,
      proceedToInvestmentProduct,
      location,
      isOnFnaPhase,
    } = this.props

    if (getToggles().ENABLE_FNA_V3 && proceedToInvestmentProduct.isValid) {
      return <Redirect to={getRedirectLink(location, isOnFnaPhase)} />
    } else if (proceedToInvestmentProduct.isValid) return <Redirect to={getRedirectLink(location, isOnFnaPhase)} />

    return (
      <div
        key={displayProduct.code}
        className={['product', cssClass, isSelected ? 'selected' : ''].join(' ')}
        data-product-name={displayProduct.displayName}
        data-product-code={displayProduct.code}
        onClick={() => validateProduct(displayProduct)}
      >
        <div className="product-card-container">
          <BasicPlanSummary data-testid="basic-plan-summary" displayProduct={displayProduct} />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: AppState, props: ownProps) => ({
  displayProduct: props.displayProduct,
  isSelected: props.isSelected,
  cssClass: props.cssClass,
  validateProduct: props.validateProduct,
  proceedToInvestmentProduct: state.investment.proceedToInvestmentProduct,
})

export const InvestmentBasicPlan = withRouter(
  connect(mapStateToProps, (dispatch: Dispatch<*>) => ({
    resetInvestmentPermission: bindActionCreators(resetInvestmentPermission, dispatch),
  }))(_InvestmentBasicPlan)
)
