// @flow

import type { Checker, Checked } from 'core/service/lib/check'
import { createChecker, valid } from 'core/service/lib/check'
import { formatNumber } from 'core/service/lib/number-format'
import { getRiskiestOccupation } from 'core/service/insured'
import type { DisplayProductQuery } from 'core/service'
import { getBasicPlan } from 'core/service'
import MESSAGES from 'core/data-model/constants/validation-messages'
import Mustache from 'mustache'
import VALUES from 'core/data-model/constants/values'
import moment from 'moment-timezone'
import type { BasicPlan } from 'core/data-model/basic-plan'

const defaultLoanState = {
  coveragePlan: {
    loan: {
      amount: {
        value: 0,
        errors: [],
      },
      term: {
        value: 0,
        errors: [],
      },
      interestRate: valid(0),
    },
    coverage: {
      sumAssured: {
        value: 0,
        errors: [],
      },
      term: valid(0),
      premium: 0,
      insuranceLoanTerm: 0,
      paymentMethod: VALUES.CASH_INCLUDE_LOAN,
    },
  },
  documentList: {
    healthCheckList: [],
    financialCheckList: [],
  },
}

export const validateSumAssured = async (
  displayProductQuery: DisplayProductQuery,
  natureOfDutyCodes: string[],
  sumAssured: number,
  loanPeriod: number = 0,
  coveragePeriod: number = 0,
  loanAmount: number = 0,
  interestRate: number = 0,
  loan: Object = defaultLoanState,
  sumAssuredContract: number = 0
): Promise<Checked<number>> => {
  const basicPlanFromConfig: BasicPlan = await getBasicPlan(displayProductQuery)
  const basicPlan = basicPlanFromConfig.productSumAssured || basicPlanFromConfig
  const category = basicPlanFromConfig.category
  const productCode = basicPlanFromConfig.productCode
  const paymentMethod = loan.coveragePlan.coverage.paymentMethod
  const loanProps = { loanPeriod, coveragePeriod, loanAmount, interestRate, paymentMethod, sumAssuredContract }
  const basicOccupationFactor = await getRiskiestOccupation(displayProductQuery, natureOfDutyCodes)
  return _validateSumAssured({ basicPlan, basicOccupationFactor, category, loanProps, productCode }, sumAssured)
}

export const _validateSumAssured: Checker<
  { basicPlan: BasicPlan, basicOccupationFactor: number, category: string, loanProps: Object, productCode: string },
  number
> = createChecker([
  [
    'betweenMinimumAndMaximum',
    {
      type: 'VALIDATION',
      rule: ({ basicPlan }, sumAssured) =>
        sumAssured >= basicPlan.sumAssuredMinimum && sumAssured <= basicPlan.sumAssuredMaximum,

      message: ({ basicPlan }) =>
        Mustache.render(MESSAGES.RULE_SUM_ASSURED_RANGE, {
          sumAssuredMinimum: formatNumber(basicPlan.sumAssuredMinimum),
          sumAssuredMaximum: formatNumber(basicPlan.sumAssuredMaximum),
        }),
    },
  ],
  [
    'invalidBasicOccupationFactor',
    {
      type: 'VALIDATION',
      rule: ({ basicOccupationFactor }) => basicOccupationFactor !== -1,
      message: MESSAGES.RULE_OCCUPATION_FACTOR,
    },
  ],
  [
    'validSumAssuredForMRTA',
    {
      type: 'VALIDATION',
      rule: ({ category, loanProps }, sumAssured) =>
        category === VALUES.MRTA ? sumAssured <= loanProps.loanAmount : true,
      message: MESSAGES.RULE_MRTA_SUM_ASSURED_MAXIMUM,
    },
  ],
  [
    'validSumAssuredContractForMRTA',
    {
      type: 'VALIDATION',
      rule: ({ basicPlan, category, loanProps }, sumAssured) =>
        category === VALUES.MRTA && loanProps.paymentMethod === VALUES.CASH_INCLUDE_LOAN
          ? loanProps.sumAssuredContract <= basicPlan.sumAssuredMaximum
          : true,
      message: ({ basicPlan }) =>
        Mustache.render(MESSAGES.RULE_MRTA_SUM_ASSURED_CONTRACT_MAXIMUM, {
          sumAssuredMaximum: formatNumber(basicPlan.sumAssuredMaximum),
        }),
    },
  ],
  [
    'validCoveragePeriodForMRTA',
    {
      type: 'VALIDATION_NOTSHOW',
      rule: ({ category, loanProps }) =>
        category === VALUES.MRTA ? loanProps.loanPeriod >= loanProps.coveragePeriod : true,
      message: MESSAGES.RULE_MRTA_COVERAGE_PERIOD_MAXIMUM,
    },
  ],
  [
    'validInterestRateForGLTSP',
    {
      type: 'VALIDATION_NOTSHOW',
      rule: ({ category, loanProps, productCode }) =>
        category === VALUES.MRTA && productCode === VALUES.GLTSP && loanProps.paymentMethod === VALUES.CASH_INCLUDE_LOAN
          ? Number(loanProps.interestRate) > 0 && Number(loanProps.interestRate) <= 100
          : true,
      message: MESSAGES.RULE_GLTSP_INTEREST_RATE_RANGE,
    },
  ],
])

export const validateBasicPremium: Checker<{ basicPremiumState: number }, number> = createChecker([
  [
    'notEqualFromInput',
    {
      type: 'ADDITIONAL_INFORMATION',
      rule: ({ basicPremiumState }, basicPremium) =>
        basicPremiumState === -1 ? true : basicPremiumState === basicPremium || basicPremiumState === 0,
      message: ({}, basicPremium) =>
        Mustache.render(MESSAGES.RULE_BASIC_PREMIUM_NOT_EQUAL_INPUT, { basicPremium: formatNumber(basicPremium, 2) }),
    },
  ],
])

export const isMonthly = (modelFactorID: string) => 'model-factor_3' === modelFactorID

export const validateTotalPremium: Checker<{ basicSumAssured: number, modelFactorID: string }, number> = createChecker([
  [
    'notEqualFromInput',
    {
      type: 'ADDITIONAL_INFORMATION',
      rule: ({}, totalPremium) => totalPremium !== -1 && totalPremium >= 0,
      message: ({}, totalPremium) =>
        Mustache.render(MESSAGES.RULE_TOTAL_PREMIUM_NOT_EQUAL_INPUT, { totalPremium: formatNumber(totalPremium, 2) }),
    },
  ],
  [
    'lessThanMinimumCaseMonthly',
    {
      type: 'VALIDATION',
      rule: ({ modelFactorID }, totalPremium) =>
        0 === totalPremium
          ? true
          : !(isMonthly(modelFactorID) && totalPremium < VALUES.MINIMUM_TOTAL_PREMIUM_FOR_MONTHLY),
      message: () =>
        Mustache.render(MESSAGES.RULE_TOTAL_PREMIUM_AT_LEAST, {
          minimumTotalPremium: formatNumber(VALUES.MINIMUM_TOTAL_PREMIUM_FOR_MONTHLY, 0),
        }),
    },
  ],
])

export const validateCoveragePeriod: Checker<{ category: string, loanPeriod: number }, number> = createChecker([
  [
    'validCoveragePeriodForMRTA',
    {
      type: 'VALIDATION',
      rule: ({ category, loanPeriod }, coveragePeriod) =>
        category === VALUES.MRTA ? Number(loanPeriod) >= Number(coveragePeriod) : true,
      message: MESSAGES.RULE_MRTA_COVERAGE_PERIOD_MAXIMUM,
    },
  ],
])

export const validateInterestRate: Checker<{ category: string, paymentMethod: string }, number> = createChecker([
  [
    'validInterestRateGLTSP',
    {
      type: 'VALIDATION',
      rule: ({ category, paymentMethod }, interestRate) =>
        category === VALUES.MRTA && paymentMethod === VALUES.CASH_INCLUDE_LOAN
          ? Number(interestRate) > 0 && Number(interestRate) <= 100
          : true,
      message: MESSAGES.RULE_GLTSP_INTEREST_RATE_RANGE,
    },
  ],
])

export const isValidConfigurationForTMO = (code: string, effectiveRateDate: string, expiryRateDate: string) => {
  const effectiveDate = moment(effectiveRateDate, VALUES.DATE_FORMAT, VALUES.TIME_ZONE)
  const expiryDate = moment(expiryRateDate, VALUES.DATE_FORMAT, VALUES.TIME_ZONE)
  const currentDate = moment(new Date()).tz(VALUES.TIME_ZONE)
  return currentDate.isSameOrAfter(effectiveDate) && currentDate.isSameOrBefore(expiryDate)
}
