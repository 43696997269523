import { createSectionCreator, createField } from '../utils'

const familyDiseaseField = (app, id) => {
  return [
    createField(`${id}.familyDiseaseMRTA.checked`, [
      {
        id: `${id}.familyDiseaseMRTA.checked`,
        c: 'RadioButton',
        required: true,
        label:
          'บิดา มารดา พี่น้องร่วมบิดาและ/หรือมารดา ของท่านเคยได้รับการวินิจฉัยจากแพทย์ว่าเป็นหรือเคยเป็นโรคหัวใจ โรคหลอดเลือด โรคมะเร็ง โรคเบาหวาน โรคไต โรคความดันโลหิตสูง การพยายามฆ่าตัวตายหรืออาการป่วยทางจิต โรคเลือด หรือโรคไวรัสตับอักเสบ หรือไม่',
        p: {
          options: [
            {
              text: 'ไม่เคย',
              value: 'no',
            },
            {
              text: 'เคย',
              value: 'yes',
            },
          ],
        },
      },
    ]),
  ]
}

export default createSectionCreator(familyDiseaseField)
