// @flow
import _ from 'lodash'
import type { Rider, RiderState } from 'core/data-model/rider'
import { formatNumber } from 'core/service/lib/number-format'
import ArrowRight from '@axah/web-toolkit/icons/arrow-right.svg'
import VALUES from 'core/data-model/constants/values'
import BIMESSAGES from 'core/data-model/constants/bi-messages'
import {
  getTotalPremiumOfRiderAndModules,
  getRiderYearsOfCoverage,
  getRiderYearsOfPayment,
} from 'core/service/rider/benefits/benefit-data'
import { translatePlanToThai } from 'core/service/rider/benefits/benefit-data/fh'

type RiderBenefitSummaryProps = {
  rider: Rider & RiderState,
  title?: string,
}

const getSumAssuredText = (rider: Rider & RiderState): string => {
  const PRECISION = rider.showSumAssuredDecimalPlaces ? 2 : 0
  switch (rider.code) {
    case VALUES.WP:
    case VALUES.WPD:
    case VALUES.WPDD:
    case VALUES.WPTPD:
    case VALUES.WPTPDCI:
    case VALUES.PBPDD:
    case VALUES.PBPDDCI:
    case VALUES.PBSDD:
    case VALUES.PBSDDCI:
      return BIMESSAGES.SUMMARY_TABLE_WP_SUMASSURED
    case VALUES.MEH:
      const planName = _.get(rider, 'selectedPlan.plan')
      return `${BIMESSAGES.PLAN} ${planName}`
    case VALUES.MHP:
      const MHPplanName = _.get(rider, 'selectedPlan.plan')
      let addtional = ''
      switch (_.get(rider, 'selectedPlan.planOptional', '')) {
        case VALUES.MHP_CO_PAYMENT:
          addtional = ' ' + BIMESSAGES.MHP_BI_CO_PAYMENT
          break
        case VALUES.MHP_DEDUCTIBLE:
          addtional = ' ' + BIMESSAGES.MHP_BI_DEDUCTIBLE
          break
      }

      return `${BIMESSAGES.PLAN} ${MHPplanName}${addtional}`
    case VALUES.FH:
      return `${BIMESSAGES.PLAN} ${translatePlanToThai(_.get(rider, 'selectedPlan.plan'))}`
    case VALUES.ESCI:
      return `${BIMESSAGES.PLAN} ${formatNumber(rider.sumAssured, PRECISION)}`
    default:
      return `${formatNumber(rider.sumAssured, PRECISION)} บาท`
  }
}

export const getRiderDescriptionName = (rider, asterisks): string => {
  if ([VALUES.TPDUDR, VALUES.CIUDR, VALUES.HBUDR, VALUES.HSUDR].includes(rider.code)) {
    return `${rider.name} (${rider.description})${asterisks}`
  }
  return `${rider.description} (${rider.name})${asterisks}`
}

const RiderBenefitSummary = ({ rider, title }: RiderBenefitSummaryProps) => {
  const asterisks = rider.showAgentProtectionDisclaimer ? '' : ''
  const sumAssuredText = getSumAssuredText(rider)
  const riderDescription = getRiderDescriptionName(rider, asterisks)

  return (
    <div className="product" key={`${rider.name.toLowerCase()}-rider-benefit-summary`}>
      <h6 className="name">
        <span className="text">{title ? title : riderDescription}</span>
        <span className="icon d-md-none">
          <ArrowRight />
        </span>
      </h6>
      <div className="info-list">
        <div className="info">
          <p className="label">ระยะเวลาคุ้มครอง</p>
          <p id={`${rider.name.toLowerCase()}-benefit-coverage-period`} className="value">{`${getRiderYearsOfCoverage(
            rider
          )} ปี`}</p>
        </div>
        <div className="info">
          <p className="label">ระยะเวลาชำระเบี้ย</p>
          <p id={`${rider.name.toLowerCase()}-benefit-payment-period`} className="value">{`${getRiderYearsOfPayment(
            rider
          )} ปี`}</p>
        </div>
        <div className="info">
          <p className="label">จำนวนเงินเอาประกันภัย</p>
          <p id={`${rider.name.toLowerCase()}-benefit-sum-assured`} className="value">
            {sumAssuredText}
          </p>
        </div>
        <div className="info">
          <p className="label">เบี้ยประกันภัย</p>
          <p id={`${rider.name.toLowerCase()}-benefit-premium`} className="value">{`${formatNumber(
            getTotalPremiumOfRiderAndModules(rider),
            2
          )} บาท`}</p>
        </div>
      </div>
    </div>
  )
}

export default RiderBenefitSummary
