// @flow

import React from 'react'
import { Redirect } from 'react-router-dom'
import { FormGroup } from 'reactstrap'
import { withModalToggle } from 'lib/with-hoc'

import PATH from 'quick-quote/constants/path'
import { InsuredInformationBreadcrumb } from 'quick-quote/components'
import QuestionnaireModalComponent from './modal'
import RiskQuestionnaireComponent from './risk-questionaire'
import RiskAssessment from './risk-assessment'
import RiskProfile from '../../components/risk-profile'
import { getToggles } from 'quick-quote/feature-toggles'
import styled from 'styled-components'

export type QuestionnaireProps = {
  isEvaluated: boolean,
  canProceedToRiskQuestionnaire: boolean,
  allowHigherRiskInvestment: boolean,
  allowForeignInvestment: boolean,
}

const RiskProfileWrapper = styled.div`
  padding: 30px 0;
`

export const RiskQuestionnaire = (
  // $FlowFixMe
  { isEvaluated, canProceedToRiskQuestionnaire, isModalOpen, toggleModal }
) => {
  if (!canProceedToRiskQuestionnaire) {
    return <Redirect to={PATH.PRODUCT_SELECTION} push />
  }

  return (
    <div id="risk">
      <InsuredInformationBreadcrumb />
      <div className="progress-wrap">
        {isEvaluated ? (
          <RiskProfileWrapper>
            <RiskProfile onRetakeRPQConfirmed={toggleModal} />
          </RiskProfileWrapper>
        ) : getToggles().ENABLE_RPQ_WITHOUT_MODAL ? (
          <RiskQuestionnaireComponent />
        ) : (
          <FormGroup>
            <RiskAssessment toggleModal={toggleModal} />
          </FormGroup>
        )}
        {!getToggles().ENABLE_RPQ_WITHOUT_MODAL && (
          <QuestionnaireModalComponent toggleModal={toggleModal} isModalOpen={isModalOpen} />
        )}
      </div>
    </div>
  )
}

export default withModalToggle(RiskQuestionnaire)
