import { getImageFormat } from './pattern'

export const resolveLoop = (arr, doFunction) => {
  return arr.reduce((promise, item, index) => {
    return promise.then(() => {
      return new Promise(function(resolve, reject) {
        doFunction(item, index, resolve, reject)
      })
    })
  }, Promise.resolve())
}

export const resolveImageFile = (file) => {
  let reader = new FileReader()

  return new Promise((resolve, reject) => {
    reader.onload = function(e) {
      const format = getImageFormat(e.target.result)
      resolve(format)
    }

    reader.onerror = function(e) {
      reader.abort()
      reject(new DOMException('Problem parsing image file.'))
    }

    reader.readAsArrayBuffer(file)
  })
}
