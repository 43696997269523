import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { endsWith, noop, omit } from 'lodash/fp'
import { compose, lifecycle, mapProps, withHandlers } from 'recompose'
import { withModalToggle } from 'lib/with-hoc'
import MoreInfoCard from 'e-submission/components/Card/MoreInfoCard'
import { _Dialog } from './_Dialog'
import { connect } from 'react-redux'
import { getToggles } from 'quick-quote/feature-toggles'
import { setAppValue } from '../../../apps/actions'
import store from 'e-submission/store'

const Component = styled(
  ({
    sections,
    onClick,
    toggleModal,
    isModalOpen,
    subpage,
    customButtons,
    okButton,
    cybsRetryUntilCancel,
    isWarningEdaPaymentNeeded,
    ...props
  }) => (
    <MoreInfoCard {...props} onClick={onClick} isWarningEdaPaymentNeeded={isWarningEdaPaymentNeeded}>
      {sections && (
        <_Dialog
          {...props}
          sections={sections}
          toggleModal={toggleModal}
          isModalOpen={isModalOpen}
          subpage={subpage}
          customButtons={customButtons}
          okButton={okButton}
        />
      )}
    </MoreInfoCard>
  )
)`
  .modal-header {
    .modal-title {
      flex-direction: row;
      display: flex;
    }
    .icon,
    .btn-left {
      z-index: 999;
      cursor: pointer;
      height: 40px;
    }
    span {
      margin-left: -28px;
      flex: 1 1 auto;
    }
  }
  .modal-footer {
    justify-content: space-between;
  }
`

export default (hoc) =>
  compose(
    connect(null, (dispatch) => ({ dispatch })),
    withModalToggle,
    withRouter,
    lifecycle({
      componentDidMount() {
        if (endsWith(this.props.subpage, this.props.history.location.pathname)) {
          this.props.toggleModal()
        }
      },
      UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.isModalOpen && !endsWith(nextProps.subpage, nextProps.history.location.pathname)) {
          this.props.toggleModal()
        }
        if (!nextProps.isModalOpen && endsWith(nextProps.subpage, nextProps.history.location.pathname)) {
          this.props.toggleModal()
        }

        if (
          getToggles().ENABLE_CYBERSOURCE_RETRY &&
          nextProps.isModalOpen &&
          this.props.cybsRetryUntilCancel &&
          nextProps.subpage === 'payment'
        ) {
          if (nextProps.cybsRetryUntilCancel) {
            store.dispatch(setAppValue(`cybsRetryUntilCancel`, false))
            this.props.toggleModal()
          }
        }
      },
    }),
    withHandlers({
      toggleModal: ({ toggleModal, history, subpage }) => () => {
        if (endsWith(subpage, history.location.pathname)) {
          history.replace(history.location.pathname.replace(`/${subpage}`, ''))
        } else {
          history.push(`${history.location.pathname}/${subpage}`)
        }
        toggleModal()
      },
    }),
    withHandlers({
      onClick: ({ dispatch, toggleModal, sections, subpage, onClick = noop, title }) => (evt) => {
        if (sections) {
          if (subpage === 'payment' && title === 'ชำระเบี้ยงวดแรก') {
            dispatch({ type: 'START_CYBS_CHECK_RETRY' })
          }
          toggleModal()
        }
        onClick(evt)
      },
    }),
    mapProps(omit(['match', 'history', 'location', 'staticContext'])),
    hoc
  )(Component)
