import { DOCUMENT_META } from 'e-submission/domain/data-model/constants'
import { getToggles } from 'quick-quote/feature-toggles'
import moment from 'moment'
import values from 'core/data-model/constants/values'

export const updateDocumentDescriptionEmpty = (document) => {
  let resultText = ''
  switch (document.name) {
    case 'passport-consent-insured':
      resultText = DOCUMENT_META.PASSPORT_CONSENT_INSURED.text
      break
    case 'passport-consent-payer':
      resultText = DOCUMENT_META.PASSPORT_CONSENT_PAYER.text
      break
    case 'citizen-id-consent-insured':
      resultText = DOCUMENT_META.CITIZEN_ID_CONSENT_INSURED.text
      break
    case 'citizen-id-consent-payer':
      resultText = DOCUMENT_META.CITIZEN_ID_CONSENT_PAYER.text
      break
    default:
      resultText = ''
      break
  }

  return resultText
}

export const documentEcbrNewName = (documentTypeName = '', receiptNo = '') => {
  const documentTypeNewName = documentTypeName + (receiptNo ? `_${receiptNo}` : '')
  return documentTypeNewName
}

export const getQRData = (taxId, sanitizedPolicyId, value, ref2) => {
  let qrData = `|${taxId}\n${sanitizedPolicyId}\n${values.PAYMENT.QR.REF2}\n${value * 100}`
  if (getToggles().ENABLE_ECBR_SYSTEMIZATION) {
    qrData = `|${taxId}\n${sanitizedPolicyId}\n${ref2}\n${value * 100}`
  }

  return qrData
}

export const formatQRPaymentRef2 = () => {
  const channelCode = '80'
  const paymentType = '1'
  const formatDate = 'DDMMYYYYHHmmssSSS'
  const currentDate = new Date()
  return `${channelCode}${paymentType}${moment(currentDate).format(formatDate)}`
}
