// @flow

import MESSAGES from 'core/data-model/constants/bi-messages'
import CONSTANTS from 'core/data-model/constants/messages'
import type { PolicyValueTableProps } from 'core/service/benefit-illustration/policy-value'
import { formatNumber } from 'core/service/lib/number-format'

export const toCurrencyFormat = (value?: number, precision: number, isRound: boolean = true) =>
  value === undefined || value === -1 ? '' : formatNumber(value, precision, isRound)

const PolicyValueTable = (props: PolicyValueTableProps) => {
  return (
    <div className="policy group" id="policy-value">
      <h3>{MESSAGES.POLICY_VALUE_TABLE}</h3>
      {renderPolicyValueTable(props)}
    </div>
  )
}

const renderPolicyValueTable = ({ policyValue, basicPlan }: PolicyValueTableProps) => {
  const policyValueList = policyValue.yearEndPolicyValues.map(function(policyItem) {
    return (
      <tr id={`policy-value-row-${policyItem.yearEndPolicy}`} key={`policy-value-row-${policyItem.yearEndPolicy}`}>
        <td className="policy-value-age">{toCurrencyFormat(policyItem.age.value, 0)}</td>
        <td className="policy-value-year">{policyItem.yearEndPolicy}</td>
        <td className="policy-value-annual-basic-premium">{toCurrencyFormat(policyItem.cumulativeBasicPremium, 2)}</td>
        <td className="policy-value-annual-basic-premium">{toCurrencyFormat(policyItem.annualBasicPremium, 2)}</td>
        <td className="policy-value-annual-basic-premium">{toCurrencyFormat(policyItem.annualRiderPremium, 2)}</td>
        <td className="policy-value-annual-basic-premium">{toCurrencyFormat(policyItem.annualTotalPremium, 2)}</td>
        <td className="policy-value-annual-basic-premium">{policyItem.cashDropValue}</td>
        <td className="policy-value-annual-basic-premium">{policyItem.deathBenefitDisplay}</td>
        <td className="policy-value-annual-basic-premium">{policyItem.accidentDeathBenefit}</td>
        <td className="policy-value-annual-basic-premium">{toCurrencyFormat(policyItem.cashvalue, 2)}</td>
      </tr>
    )
  })

  return (
    <div>
      <div className="table-wrap">
        <table className="policy-table" id="policy-value-table">
          <thead>
            <tr>
              <td rowSpan="2">{CONSTANTS.AGE}</td>
              <td rowSpan="2">{MESSAGES.END_OF_THE_YEAR}</td>
              <td rowSpan="2">{MESSAGES.CUMULATIVE_PREMIUM}</td>
              <td colSpan="3">{MESSAGES.PREMIUM_PER_YEAR}</td>
              <td rowSpan="2">{MESSAGES.BENEFIT_CASH_DROP}</td>
              <td colSpan="2">{MESSAGES.DEATH_BENEFIT}</td>
              <td rowSpan="2">{MESSAGES.CASH_SURRENDER_VALUE}**</td>
            </tr>
            <tr>
              <td>{MESSAGES.BASIC_PLAN}</td>
              <td>{MESSAGES.RIDER}</td>
              <td>{MESSAGES.TOTAL}</td>
              <td>{MESSAGES.BENEFIT_GENERAL_CASE}</td>
              <td>{MESSAGES.BENEFIT_DEATH_ACCIDENT}</td>
            </tr>
          </thead>
          <tbody>{policyValueList}</tbody>
        </table>
      </div>

      <div className="disclaimer">
        <p>**{MESSAGES.NOTE}:</p>
        <ul>
          <li>{MESSAGES.BENEFIT_IGROW_DISCLAIMER}</li>
        </ul>
      </div>
    </div>
  )
}
export default PolicyValueTable
