//@flow
import _ from 'lodash'
import { getMHPExceptionNotes } from 'core/service/rider/benefits/exception-notes'

import MESSAGES from 'core/data-model/constants/bi-messages'
import VALUES from 'core/data-model/constants/values'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { RiderBenefitData } from 'core/data-model/rider/benefits'
import {
  findDataOnListByRiderCode,
  getMainPlanCode,
  isJuniorPlan,
  findTerritoryCode,
} from 'quick-quote/product-common/benefit-illustration/components/rider-benefits/components/mhp-rider-benefit'
import { buildHeaderTable } from 'core/service/pdf-generation/utils/builder'
import { getRiderPlanCode, getDisplayOptionalMessage } from 'core/service/rider/rider-mhp'

type MHPBenefitProps = {
  rider: Rider & RiderState,
  riderBenefitData: RiderBenefitData,
  sumAssured: number,
}

export const buildEndorseSection = (rider, endorse, planCode, productOptional, displayOptional) => {
  const { title, contents } = endorse
  const productClassName = _.get(rider, 'selectedPlan.plan').toLowerCase()

  if (!(productClassName === 'smart' || productClassName === 'bronze')) {
    return [
      { text: '', margin: [0, 0, 0, 25] },
      {
        text: `${title}`,
        style: 'sub-title',
        margin: [0, 0, 0, 15],
      },
      {
        table: buildEndorseTable(contents, planCode, rider, productOptional, displayOptional),
        layout: 'lightBorder',
        style: 'table',
      },
    ]
  } else {
    return ''
  }
}

const InlineTextTemplate = (content) => {
  return {
    text: `             ${content}`,
    alignment: 'left',
    bold: false,
    preserveLeadingSpaces: true,
    marginTop: -16,
  }
}

export const buildEndorseTable = (contents, planCode, rider, productOptional, displayOptional) => {
  const headers = [
    MESSAGES.BENEFIT_TITLE,
    MESSAGES.BENEFIT_BAHT,
    MESSAGES.RIDER_BENEFIT_DAY_OR_TIME,
    MESSAGES.RIDER_TOTAL_BENEFIT_BAHT,
  ]
  const basePlanCode = getMainPlanCode(planCode)
  const productClassName = _.get(rider, 'selectedPlan.plan').toLowerCase()
  const rowSpanSection = productClassName === 'diamond' ? 10 : productClassName === 'platinum' ? 14 : 6

  let content = {
    headerRows: 1,
    widths: ['*', 75, 50, 80],
    body: [
      buildHeaderTable(headers),
      [
        [
          {
            text: `${contents[0].text[0]}`,
            alignment: 'left',
            bold: true,
          },
          InlineTextTemplate(contents[0].text[1]),
        ],
        { text: `${findDataOnListByRiderCode(contents[0].benefit, basePlanCode)}`, alignment: 'center' },
        { text: `${contents[0].totalTimes[0]}`, alignment: 'center' },
        {
          text: `${findDataOnListByRiderCode(contents[0].totalBenefit, basePlanCode)}
                 ${
                   findDataOnListByRiderCode(contents[0].totalBenefit, basePlanCode) !== '-'
                     ? contents[0].totalBenefitDesc
                     : ''
                 }`,
          alignment: 'center',
          rowSpan: rowSpanSection,
          border: [false, false, true, true],
        },
      ],
      [
        [
          {
            text: `${contents[1].text[0]}`,
            alignment: 'left',
            bold: true,
          },
          InlineTextTemplate(contents[1].text[1]),
        ],
        { text: `${findDataOnListByRiderCode(contents[1].benefit, basePlanCode)}`, alignment: 'center' },
        { text: `${contents[1].totalTimes[0]}`, alignment: 'center' },
        '',
      ],
      [
        [
          {
            text: `${contents[2].text[0]}`,
            alignment: 'left',
            bold: true,
          },
          InlineTextTemplate(contents[2].text[1]),
        ],
        { text: `${findDataOnListByRiderCode(contents[2].benefit, basePlanCode)}`, alignment: 'center' },
        { text: `${contents[2].totalTimes[0]}`, alignment: 'center' },
        '',
      ],
      [
        [
          {
            text: `${contents[3].text[0]}`,
            alignment: 'left',
            bold: true,
          },
          InlineTextTemplate(contents[3].text[1]),
        ],
        { text: `${findDataOnListByRiderCode(contents[3].benefit, basePlanCode)}`, alignment: 'center' },
        { text: `${contents[3].totalTimes[0]}`, alignment: 'center' },
        '',
      ],
    ],
  }

  //# RowSpan Condition for No. 18 and 19
  if (productClassName === 'platinum') {
    content.body.push(
      [
        [
          {
            text: `${contents[4].text[0]}`,
            alignment: 'left',
            bold: true,
          },
          InlineTextTemplate(contents[4].text[1]),
        ],
        { text: `${findDataOnListByRiderCode(contents[4].benefit, basePlanCode)}`, alignment: 'center' },
        { text: `${contents[4].totalTimes[0]}`, alignment: 'center' },
        '',
      ],
      [
        [
          {
            text: `${contents[5].text[0]}`,
            alignment: 'left',
            bold: true,
          },
          InlineTextTemplate(contents[5].text[1]),
        ],
        { text: `${findDataOnListByRiderCode(contents[5].benefit, basePlanCode)}`, alignment: 'center' },
        { text: `${contents[5].totalTimes[0]}`, alignment: 'center' },
        '',
      ]
    )
  } else {
    content.body.push(
      [
        [
          {
            text: `${contents[4].text[0]}`,
            alignment: 'left',
            bold: true,
          },
          InlineTextTemplate(contents[4].text[1]),
        ],
        { text: `${findDataOnListByRiderCode(contents[4].benefit, basePlanCode)}`, alignment: 'center', rowSpan: 2 },
        { text: `${contents[4].totalTimes[0]}`, alignment: 'center' },
        '',
      ],
      [
        [
          {
            text: `${contents[5].text[0]}`,
            alignment: 'left',
            bold: true,
          },
          InlineTextTemplate(contents[5].text[1]),
        ],
        { text: `${findDataOnListByRiderCode(contents[5].benefit, basePlanCode)}`, alignment: 'center' },
        { text: `${contents[5].totalTimes[0]}`, alignment: 'center' },
        '',
      ]
    )
  }

  if (productClassName === 'diamond' || productClassName === 'platinum')
    content.body.push(
      [
        [
          {
            text: `${contents[6].text[0]}`,
            alignment: 'left',
            bold: true,
          },
          InlineTextTemplate(contents[6].text[1]),
        ],
        { text: `${findDataOnListByRiderCode(contents[6].benefit, basePlanCode)}`, alignment: 'center' },
        { text: `${contents[6].totalTimes[0]}`, alignment: 'center' },
        '',
      ],
      [
        [
          {
            text: `${contents[7].text[0]}`,
            alignment: 'left',
            bold: true,
          },
          InlineTextTemplate(contents[7].text[1]),
        ],
        { text: `${findDataOnListByRiderCode(contents[7].benefit, basePlanCode)}`, alignment: 'center' },
        { text: `${contents[7].totalTimes[0]}`, alignment: 'center' },
        '',
      ],
      [
        [
          {
            text: `${contents[8].text[0]}`,
            alignment: 'left',
            bold: true,
          },
          InlineTextTemplate(contents[8].text[1]),
        ],
        { text: `${findDataOnListByRiderCode(contents[8].benefit, basePlanCode)}`, alignment: 'center' },
        { text: `${contents[8].totalTimes[0]}`, alignment: 'center' },
        '',
      ],
      [
        [
          {
            text: `${contents[9].text[0]}`,
            alignment: 'left',
            bold: true,
          },
          InlineTextTemplate(contents[9].text[1]),
        ],
        { text: `${findDataOnListByRiderCode(contents[9].benefit, basePlanCode)}`, alignment: 'center' },
        {
          text: `${contents[9].totalTimes[0]}`,
          alignment: 'center',
          margin: [0, 0, 0, +`${productClassName === 'diamond' ? 20 : 0}`],
        },
        '',
      ]
    )

  if (productClassName === 'platinum')
    content.body.push(
      [
        [
          {
            text: `${contents[10].text[0]}`,
            alignment: 'left',
            bold: true,
          },
          InlineTextTemplate(contents[10].text[1]),
        ],
        { text: `${findDataOnListByRiderCode(contents[10].benefit, basePlanCode)}`, alignment: 'center' },
        { text: `${contents[10].totalTimes[0]}`, alignment: 'center' },
        '',
      ],
      [
        [
          {
            text: `${contents[11].text[0]}`,
            alignment: 'left',
            bold: true,
          },
          InlineTextTemplate(contents[11].text[1]),
        ],
        { text: `${findDataOnListByRiderCode(contents[11].benefit, basePlanCode)}`, alignment: 'center' },
        { text: `${contents[11].totalTimes[0]}`, alignment: 'center' },
        '',
      ],
      [
        [
          {
            text: `${contents[12].text[0]}`,
            alignment: 'left',
            bold: true,
          },
          InlineTextTemplate(contents[12].text[1]),
        ],
        { text: `${findDataOnListByRiderCode(contents[12].benefit, basePlanCode)}`, alignment: 'center' },
        { text: `${contents[12].totalTimes[0]}`, alignment: 'center' },
        '',
      ],
      [
        [
          {
            text: `${contents[13].text[0]}`,
            alignment: 'left',
            bold: true,
          },
          InlineTextTemplate(contents[13].text[1]),
        ],
        {
          text: `${findDataOnListByRiderCode(contents[13].benefit, basePlanCode)}`,
          alignment: 'center',
          margin: [0, 0, 0, 18],
        },
        { text: `${contents[13].totalTimes[0]}`, alignment: 'center' },
        '',
      ],
      [
        [
          {
            text: `${contents[14].text[0]}`,
            alignment: 'left',
            bold: true,
          },
          InlineTextTemplate(contents[14].text[1]),
        ],
        { text: `${findDataOnListByRiderCode(contents[14].benefit, basePlanCode)}`, alignment: 'center' },
        { text: `${contents[14].totalTimes[0]}`, alignment: 'center' },
        {
          text: ' ',
          alignment: 'center',
          border: [false, false, true, true],
        },
      ]
    )
  content.body.push(
    [
      {
        text: `${contents[16].text[displayOptional]}`,
        alignment: 'left',
        bold: true,
        colSpan: 4,
        decoration: 'underline',
      },
    ],
    [
      { text: `${contents[17].text[displayOptional]}`, alignment: 'left' },
      {
        text: `${findDataOnListByRiderCode(contents[17].totalBenefit, planCode)}`,
        alignment: 'center',
        colSpan: 3,
      },
    ],
    [
      { text: `${contents[18].text[displayOptional]}`, alignment: 'left' },
      {
        text: `${findDataOnListByRiderCode(contents[18].totalBenefit, productOptional)}`,
        alignment: 'center',
        colSpan: 3,
      },
    ]
  )
  return content
}

export const buildBenefitSection = (benefits, planCode, productOptional, displayOptional) => {
  const { title, contents } = benefits
  return [
    {
      text: `${title}`,
      style: 'sub-title',
    },
    {
      table: buildBenefitTable(contents, planCode, productOptional, displayOptional),
      layout: 'lightBorder',
      style: 'table',
    },
  ]
}

export const buildBenefitTable = (contents, planCode, productOptional, displayOptional) => {
  const headers = [
    MESSAGES.BENEFIT_TITLE,
    MESSAGES.BENEFIT_BAHT,
    MESSAGES.RIDER_BENEFIT_DAY_OR_TIME,
    MESSAGES.RIDER_TOTAL_BENEFIT_BAHT,
  ]
  const basePlanCode = getMainPlanCode(planCode)

  return {
    headerRows: 1,
    widths: ['*', 75, 50, 80],
    body: [
      buildHeaderTable(headers),
      [{ text: `${contents[0].text[0]}`, alignment: 'left', bold: true, colSpan: 4 }],
      [
        [
          {
            text: `${contents[1].text[0]}`,
            alignment: 'left',
            bold: true,
          },
          InlineTextTemplate(contents[1].text[1]),
          {
            text: `    ${contents[1].text[2]}`,
            alignment: 'left',
            preserveLeadingSpaces: true,
            bold: false,
          },
        ],
        { text: `${findDataOnListByRiderCode(contents[1].benefit, basePlanCode)}`, alignment: 'center' },
        { text: `${contents[1].totalTimes[0]}`, alignment: 'center' },
        {
          text: `${findDataOnListByRiderCode(contents[1].totalBenefit, basePlanCode)} ${contents[1].totalBenefitDesc}`,
          alignment: 'center',
          rowSpan: 9,
        },
      ],
      [
        {
          stack: [
            {
              text: `${contents[2].text[0]}`,
              alignment: 'left',
              bold: true,
            },
            InlineTextTemplate(contents[2].text[1]),
          ],
          colSpan: 3,
        },
        '',
      ],
      [
        {
          text: `${contents[3].text[0]}`,
          alignment: 'left',
        },
        { text: `${contents[3].benefit[0]}`, alignment: 'center' },
        { text: `${contents[3].totalTimes[0]}`, alignment: 'center' },
        '',
      ],
      [
        {
          text: `${contents[3].text[1]}`,
          alignment: 'left',
        },
        { text: `${contents[3].benefit[0]}`, alignment: 'center' },
        { text: `${contents[3].totalTimes[0]}`, alignment: 'center' },
        '',
      ],
      [
        {
          text: `${contents[3].text[2]}`,
          alignment: 'left',
        },
        { text: `${contents[3].benefit[0]}`, alignment: 'center' },
        { text: `${contents[3].totalTimes[0]}`, alignment: 'center' },
        '',
      ],
      [
        {
          text: `${contents[3].text[3]}`,
          alignment: 'left',
        },
        { text: `${contents[3].benefit[0]}`, alignment: 'center' },
        { text: `${contents[3].totalTimes[1]}`, alignment: 'center' },
        '',
      ],
      [
        {
          stack: [
            {
              text: `${contents[4].text[0]}`,
              alignment: 'left',
              bold: true,
            },
            InlineTextTemplate(contents[4].text[1]),
          ],
        },
        { text: `${findDataOnListByRiderCode(contents[4].benefit, basePlanCode)}`, alignment: 'center' },
        '',
        '',
      ],
      [
        {
          colSpan: 3,
          stack: [
            {
              text: `${contents[5].text[0]}`,
              alignment: 'left',
              bold: true,
            },
            InlineTextTemplate(contents[5].text[1]),
          ],
        },
        '',
        '',
        '',
      ],
      //#  4.1
      [
        {
          text: `${contents[6].text[0]}`,
          alignment: 'left',
          margin: [0, 0, 0, 20],
        },
        { text: `${contents[6].benefit[0]}`, alignment: 'center' },
        { text: `${contents[6].totalTimes[0]}`, alignment: 'center' },
        '',
      ],
      //# Section 2: 4.2
      [
        {
          text: `${contents[6].text[1]}`,
          alignment: 'left',
        },
        { text: `${contents[6].benefit[0]}`, alignment: 'center' },
        { text: `${contents[6].totalTimes[0]}`, alignment: 'center' },
        {
          text: ' ',
          alignment: 'center',
          rowSpan: 15,
        },
      ],
      [
        {
          text: `${contents[6].text[2]}`,
          alignment: 'left',
        },
        { text: `${contents[6].benefit[0]}`, alignment: 'center' },
        { text: `${contents[6].totalTimes[0]}`, alignment: 'center' },
        '',
      ],
      [
        {
          text: `${contents[6].text[3]}`,
          alignment: 'left',
        },
        { text: `${contents[6].benefit[0]}`, alignment: 'center' },
        { text: `${contents[6].totalTimes[0]}`, alignment: 'center' },
        '',
      ],
      [
        {
          text: `${contents[6].text[4]}`,
          alignment: 'left',
        },
        { text: `${contents[6].benefit[0]}`, alignment: 'center' },
        { text: `${contents[6].totalTimes[0]}`, alignment: 'center' },
        '',
      ],
      [
        {
          stack: [
            {
              text: `${contents[7].text[0]}`,
              alignment: 'left',
              bold: true,
            },
            InlineTextTemplate(contents[7].text[1]),
          ],
        },
        { text: `${contents[7].benefit[0]}`, alignment: 'center' },
        { text: `${contents[7].totalTimes[0]}`, alignment: 'center' },
        '',
      ],
      //# No 2.
      [{ text: `${contents[8].text[0]}`, alignment: 'left', bold: true, colSpan: 3 }, '', '', ''],
      [
        {
          stack: [
            {
              text: `${contents[9].text[0]}`,
              alignment: 'left',
              bold: true,
            },
            InlineTextTemplate(contents[9].text[1]),
          ],
          colSpan: 3,
        },
        '',
        '',
        '',
      ],
      [
        { text: `${contents[10].text[0]}`, alignment: 'left' },
        { text: `${contents[10].benefit[0]}`, alignment: 'center' },
        { text: `${contents[10].totalTimes[0]}`, alignment: 'center' },
        '',
      ],
      [
        { text: `${contents[10].text[1]}`, alignment: 'left' },
        { text: `${contents[10].benefit[0]}`, alignment: 'center' },
        { text: `${contents[10].totalTimes[1]}`, alignment: 'center' },
        '',
      ],
      [
        [
          {
            text: `${contents[11].text[0]}`,
            alignment: 'left',
            bold: true,
          },
          InlineTextTemplate(contents[11].text[1]),
        ],
        { text: `${contents[11].benefit[0]}`, alignment: 'center' },
        { text: `${contents[11].totalTimes[0]}`, alignment: 'center' },
        '',
      ],
      [
        [
          {
            text: `${contents[12].text[0]}`,
            alignment: 'left',
            bold: true,
          },
          InlineTextTemplate(contents[12].text[1]),
        ],
        { text: `${contents[12].benefit[0]}`, alignment: 'center' },
        { text: `${contents[12].totalTimes[0]}`, alignment: 'center' },
        '',
      ],
      [
        {
          stack: [
            {
              text: `${contents[13].text[0]}`,
              alignment: 'left',
              bold: true,
            },
            InlineTextTemplate(contents[13].text[1]),
          ],
          // border: [true, false, true, true],
        },
        { text: `${contents[13].benefit[0]}`, alignment: 'center', border: [true, false, true, true] },
        { text: `${contents[13].totalTimes[0]}`, alignment: 'center', border: [true, false, true, true] },
        '',
      ],
      [
        [
          {
            text: `${contents[14].text[0]}`,
            alignment: 'left',
            bold: true,
          },
          InlineTextTemplate(contents[14].text[1]),
        ],
        { text: `${contents[14].benefit[0]}`, alignment: 'center' },
        { text: `${contents[14].totalTimes[0]}`, alignment: 'center' },
        '',
      ],
      [
        [
          {
            text: `${contents[15].text[0]}`,
            alignment: 'left',
            bold: true,
          },
          InlineTextTemplate(contents[15].text[1]),
        ],
        { text: `${contents[15].benefit[0]}`, alignment: 'center' },
        { text: `${contents[15].totalTimes[0]}`, alignment: 'center' },
        '',
      ],
      //# Section 3.
      [
        [
          {
            text: `${contents[16].text[0]}`,
            alignment: 'left',
            bold: true,
          },
          InlineTextTemplate(contents[16].text[1]),
        ],
        { text: `${contents[16].benefit[0]}`, alignment: 'center', margin: [0, 0, 0, 7] },
        { text: `${contents[16].totalTimes[0]}`, alignment: 'center' },
        '',
      ],
      [
        [
          {
            text: `${contents[17].text[0]}`,
            alignment: 'left',
            bold: true,
          },
          InlineTextTemplate(contents[17].text[1]),
        ],
        { text: `${contents[17].benefit[0]}`, alignment: 'center' },
        { text: `${contents[17].totalTimes[0]}`, alignment: 'center' },
        '',
      ],
      //# Footer
      [
        {
          text: `${contents[18].text[displayOptional]}`,
          alignment: 'left',
          bold: true,
          colSpan: 4,
          decoration: 'underline',
        },
      ],
      [
        { text: `${contents[19].text[displayOptional]}`, alignment: 'left' },
        {
          text: `${findDataOnListByRiderCode(contents[19].totalBenefit, planCode)}`,
          alignment: 'center',
          colSpan: 3,
        },
      ],
      [
        { text: `${contents[20].text[displayOptional]}`, alignment: 'left' },
        {
          text: `${findDataOnListByRiderCode(contents[20].totalBenefit, productOptional)}`,
          alignment: 'center',
          colSpan: 3,
        },
      ],
    ],
  }
}

const buildMHPRiderDisclaimer = (rider, disclaimer, planCode, asian_remark, productOptional) => {
  const productClassName = _.get(rider, 'selectedPlan.plan').toLowerCase()
  let content = []
  let result = {}

  if (isJuniorPlan(planCode)) {
    content.push({
      text: `${disclaimer.junior_condition}`,
      style: 'disclaimer',
    })
  }
  if (productClassName === 'diamond') {
    content.push({
      text: `${disclaimer.diamond_condition}`,
      style: 'disclaimer',
    })
  }
  if (productClassName === 'platinum') {
    content.push({
      text: `${disclaimer.diamond_condition}`,
      style: 'disclaimer',
    })
    content.push({
      text: `${disclaimer.platinum_condition}`,
      style: 'disclaimer',
    })
  }
  if (productOptional === 'co_payment' || productOptional === 'deductible') {
    content.push({
      text: `${disclaimer.optional_condition}`,
      style: 'disclaimer',
    })
  }
  if (
    productClassName === 'silver' ||
    productClassName === 'gold' ||
    productClassName === 'platinum' ||
    productClassName === 'diamond'
  ) {
    content.push({
      text: `${disclaimer.smart_condition}`,
      style: 'disclaimer',
    })
  }

  content.push(
    {
      text: '',
      margin: [0, 0, 0, 10],
    },
    {
      text: `${disclaimer.common_condition[0]}`,
      bold: true,
      style: 'disclaimer',
    },
    {
      text: `${disclaimer.common_condition[1]}`,
      bold: false,
      style: 'disclaimer',
      margin: [0, 5, 0, 10],
    },
    {
      text: `${disclaimer.common_condition[2]}`,
      bold: true,
      style: 'disclaimer',
    },
    {
      text: `${disclaimer.common_condition[3]}`,
      bold: false,
      style: 'disclaimer',
      margin: [0, 5, 0, 10],
    },
    {
      text: `${disclaimer.common_condition[4]}`,
      bold: false,
      style: 'disclaimer',
      margin: [0, 0, 0, 10],
    }
  )

  if (asian_remark.length > 0) {
    content.push({
      text: `${asian_remark}`,
      bold: false,
      italics: true,
      style: 'disclaimer',
      margin: [0, 0, 0, 10],
    })
  }

  result.content = content
  return content
}

export const MHPBenefitSection = ({ rider, riderBenefitData, insured }: MHPBenefitProps) => {
  const exceptionNotes = buildExceptionNotes(rider)
  const planCode = getRiderPlanCode(rider, insured.age)
  const productOptional = _.get(rider, 'selectedPlan.planOptional', VALUES.MHP_FULL_COVERAGE).toLowerCase()
  const displayOptional = getDisplayOptionalMessage(rider)
  const { benefits, endorse, disclaimer, headTitle, territory } = riderBenefitData

  const territoryCode = findTerritoryCode(planCode)
  const area = territory.area.find((item) => item.key === territoryCode).value

  let asian_remark = ''
  if (territoryCode === 'A') {
    asian_remark = territory.asia_remark
  }

  return [
    { text: `${headTitle}`, style: 'sub-title' },
    { text: `${territory.title}${area}`, style: 'sub-title' },
    ...buildBenefitSection(benefits, planCode, productOptional, displayOptional),
    ...buildEndorseSection(rider, endorse, planCode, productOptional, displayOptional),
    ...buildMHPRiderDisclaimer(rider, disclaimer, planCode, asian_remark, productOptional),
    { text: '', pageBreak: 'after' },
    { ...exceptionNotes },
  ]
}

const buildExceptionNotes = (rider) => {
  const exceptionNotes = getMHPExceptionNotes()
  const exceptionTitle = exceptionNotes.title
  const [
    normalCondition,
    firstException,
    secondException,
    thirdException,
    extendExceptionTitle,
    extendExceptionDesc,
  ] = exceptionNotes.exceptions

  return {
    stack: [
      {
        text: `${exceptionTitle}`,
        style: 'sub-title-2',
      },
      {
        text: `${normalCondition}`,
        style: 'sub-title-2',
      },
      {
        ol: [
          firstException,
          {
            stack: [
              secondException[0],
              {
                columns: [
                  {
                    ul: secondException[1].slice(0, 4),
                    width: 255,
                  },
                  {
                    ul: secondException[1].slice(4, 8),
                    width: '*',
                  },
                ],
                columnGap: 15,
                margin: [5, 0, 0, 0],
              },
            ],
          },
          {
            stack: [
              thirdException[0],
              thirdException[1],
              {
                table: {
                  body: [
                    ['(1)', { text: `${thirdException[2][0]}`, margin: [-5, 0, 0, 0] }],
                    ['(2)', { text: `${thirdException[2][1]}`, margin: [-5, 0, 0, 0] }],
                  ],
                },
                layout: 'noBorders',
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
        margin: [0, 0, 0, 10],
      },
      {
        text: `${extendExceptionTitle}`,
        style: 'sub-title-2',
      },
      {
        text: `${extendExceptionDesc[0]}`,
      },
      {
        text: `${extendExceptionDesc[1]}`,
      },
      {
        ol: [...extendExceptionDesc[2]],
      },
      {
        table: {
          body: [
            ['(1)', { text: `${extendExceptionDesc[3][0]}`, margin: [-5, 0, 0, 0] }],
            ['(2)', { text: `${extendExceptionDesc[3][1]}`, margin: [-5, 0, 0, 0] }],
            ['(3)', { text: `${extendExceptionDesc[3][2]}`, margin: [-5, 0, 0, 0] }],
          ],
        },
        layout: 'noBorders',
        margin: [12, 0, 0, 0],
      },
    ],
  }
}

export const MHPAdditionalRiderDisclaimer = (riderBenefitData) => [
  {
    text: `${riderBenefitData.disclaimer.territory_condition[0]}`,
    style: 'disclaimer',
    bold: true,
    margin: [0, 5, 0, 0],
  },
  {
    text: `                                    ${riderBenefitData.disclaimer.territory_condition[1]}`,
    alignment: 'left',
    style: 'disclaimer',
    bold: false,
    preserveLeadingSpaces: true,
    marginTop: -14,
  },
]
