import { ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap'
import { connect } from 'react-redux'

import { getCurrentApp } from 'e-submission/apps/selectors'
import { getCitizenId } from 'e-submission/domain/data-model/form/selectors'
import { Modal } from 'common-components/index'
import styled from 'styled-components'

const ModalTitleSection = styled(ModalHeader)`
  .modal-title {
    width: 100%;
  }
  .modal-header-sub-title {
    text-align: center;
    font-weight: bold;
    display: block;
    color: ${({ theme }) => theme.variables['$brand-primary']};
  }
`

const SubmitButton = (props) => {
  const { handleConfirm, closeModal } = props

  return (
    <Button
      color="primary"
      onClick={() => {
        closeModal()
      }}
    >
      ยืนยัน
    </Button>
  )
}

export const PayerInfoApiFailedModal = (props) => {
  const { isOpen, closeModal } = props
  return (
    <Modal
      id="payer-info-api-failed-modal"
      className={'payer-info-api-failed-modal'}
      isOpen={isOpen}
      size="md"
      modalClassName="default-bootstrap"
      toggle={() => {
        closeModal()
      }}
    >
      <ModalTitleSection tag="div" toggle={() => closeModal()}>
        <span className="modal-header-sub-title">ระบบไม่สามารถออกใบรับเงินชั่วคราวได้</span>
      </ModalTitleSection>

      <ModalBody className="modal-body-content" style={{ textAlign: 'center' }}>
        ขออภัยในความไม่สะดวก <br />
        ขณะนี้ระบบไม่สามารถดำเนินการออกใบรับเงินชั่วคราวได้ <br />
        กรุณาลองใหม่อีกครั้ง
      </ModalBody>
      <ModalFooter style={{ justifyContent: 'center', borderTop: 0 }}>
        <SubmitButton handleConfirm={() => console.log('xxx')} closeModal={closeModal} />
      </ModalFooter>
    </Modal>
  )
}

const mapStateToProps = (state, props) => {
  const app = getCurrentApp(state)
  const insuredId = getCitizenId('insured')(app)
  const payerId = getCitizenId('payer')(app)

  return {
    insuredId,
    payerId,
  }
}
const mapDispatchToProps = (dispatch) => ({})
export default connect(mapStateToProps, mapDispatchToProps)(PayerInfoApiFailedModal)
