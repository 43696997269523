// @flow

import { createSelector } from 'reselect'
import _ from 'lodash'

import { getSelectedDisplayProduct } from 'quick-quote/product-selection/selectors'
import { getGenderLabel, getAge } from 'quick-quote/insured-information/selectors'
import {
  getCalculatedCoveragePeriod,
  getCalculatedPaymentPeriod,
  getHasShowDisclaimerRiderAsterisk,
} from 'quick-quote/product-common/benefit-illustration/selectors'
import {
  getSumAssured$,
  getTotalPremium,
  getBasicPremium$,
  getSelectedRiders,
  getTotalRiderPremium,
  getSelectedModelFactorLabel,
  getSelectedModelFactorID,
  getCampaignDiscountMessage,
} from 'quick-quote/product-common/coverage-plan/selectors'
import { getPolicyValues, getHasNonLevelRider } from 'quick-quote/product-whole-life/benefit-illustration/selectors'
import { isNewAsteriskOfSummaryMainContract } from 'identity/selectors'
import { isReviseQQFlow } from 'quick-quote/selectors'

const _summaryHeaderWithCode = ({ displayProduct }: *) => {
  const name = _.get(displayProduct, 'displayName', '')
  const riderPlanCode = _.get(displayProduct, 'basicPlanCode', '')

  if (_.get(displayProduct, 'basicPlanCode') === '25PL') {
    return name
  }

  return `${name} (${riderPlanCode})`
}

const _summaryHeader = ({ displayProduct }: *) => (displayProduct ? displayProduct.displayName : '')

// $FlowFixMe
export const getProfileSnapshot = createSelector(
  getSelectedDisplayProduct,
  getSelectedRiders,
  getGenderLabel,
  getAge,
  getCalculatedCoveragePeriod,
  getCalculatedPaymentPeriod,
  getPolicyValues,
  getSumAssured$,
  getBasicPremium$,
  getTotalPremium,
  getTotalRiderPremium,
  getSelectedModelFactorLabel,
  getHasNonLevelRider,
  getHasShowDisclaimerRiderAsterisk,
  getSelectedModelFactorID,
  isNewAsteriskOfSummaryMainContract,
  isReviseQQFlow,
  getCampaignDiscountMessage,
  (
    displayProduct,
    riders,
    genderLabel,
    age,
    calculatedCoveragePeriod,
    calculatedPaymentPeriod,
    policyValue,
    sumAssured,
    basicPremium,
    totalPremium,
    totalRiderPremium,
    selectedModelFactorLabel,
    hasNonLevelRider,
    hasShowDisclaimerRiderAsterisk,
    selectedModelFactorID,
    newAsteriskOfSummaryMainContract,
    isReviseQQ,
    campaignDiscountMessage
  ): ProfileSnapshotProps => ({
    displayProduct,
    riders,
    genderLabel,
    age,
    calculatedCoveragePeriod,
    calculatedPaymentPeriod,
    policyValue,
    sumAssured: sumAssured.value,
    basicPremium: basicPremium.value,
    totalPremium,
    totalRiderPremium,
    selectedModelFactorLabel,
    hasNonLevelRider,
    hasShowDisclaimerRiderAsterisk,
    selectedModelFactorID,
    isNewAsteriskOfSummaryMainContract: newAsteriskOfSummaryMainContract,
    profileSummaryHeader: _summaryHeaderWithCode,
    summaryTableHeader: _summaryHeader,
    isReviseQQ,
    campaignDiscountMessage,
  })
)
