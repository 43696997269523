//@flow
import type { Age } from 'core/data-model/insured/types'
import { calculateFontSizeToFitComponent } from 'core/service/lib/pdf-utils'
import { renderBIAge } from 'core/service/insured/age'
import BI_MESSAGES from 'core/data-model/constants/bi-messages'
import MESSAGES from 'core/data-model/constants/messages'

const { SPECIALLY_FOR, DEFAULT_TITLE, DEFAULT_FIRSTNAME, DEFAULT_LASTNAME } = BI_MESSAGES
const { AGE, GENDER } = MESSAGES

const COMPONENT_WIDTH = 150
const DEFAULT_NAME_FONT_SIZE = 26

type Insured = {
  title?: string,
  firstName?: string,
  lastName?: string,
  age: Age,
  gender: string,
}

export const styles = {
  speciallyFor: {
    fontSize: 14,
    color: '#2425aa',
  },
  name: {
    fontSize: DEFAULT_NAME_FONT_SIZE,
    bold: true,
  },
  age_gender: {
    fontSize: 14,
  },
}

export const render = ({
  title = DEFAULT_TITLE,
  firstName = DEFAULT_FIRSTNAME,
  lastName = DEFAULT_LASTNAME,
  age,
  gender,
  isReviseQQ,
}: Insured) => {
  const insuredName = `${title} ${firstName}  ${lastName}`
  const renderAge = renderBIAge(age, isReviseQQ)
  let nameStyle = {
    ...styles.name,
    fontSize: calculateFontSizeToFitComponent(insuredName, COMPONENT_WIDTH, DEFAULT_NAME_FONT_SIZE, true),
  }

  return {
    stack: [
      { text: SPECIALLY_FOR, ...styles.speciallyFor },
      { text: `${insuredName}`, ...nameStyle },
      { text: `${GENDER} ${gender}   ${AGE} ${renderAge}`, ...styles.age_gender },
    ],
    alignment: 'right',
    width: COMPONENT_WIDTH,
  }
}
