//@flow
/* istanbul ignore file */

import type { AnalyticsEventType } from 'quick-quote/../analytics/components'
import MESSAGES from 'quick-quote/../core/data-model/constants/messages'
import { AcceptLstuFundAllocationButton } from 'quick-quote/components/accept-fund-allocation-btn'
import ProceedToFnaButton from 'quick-quote/components/proceed-to-fna-btn'
import AcceptFundAllocationButton from 'quick-quote/components/rpp-accept-fund-allocation-btn'
import MaturityValidateButton from 'quick-quote/components/maturity-validate-btn'

import { qqPath, ROUTE_PATH } from 'quick-quote/constants/path'
import { isOnFnaPhase } from 'quick-quote/fna/selectors'
import { ShareButtonSection as FlexiHealthShareButtonSection } from 'quick-quote/product-flexi-health/benefit-illustration/components'
import { ShareButtonSection as HealthShareButtonSection } from 'quick-quote/product-health/benefit-illustration/components'
import ShareQuoteButton from 'quick-quote/product-investment/benefit-illustration/share-quote-button'
import { hasFirstYearLstu } from 'quick-quote/product-investment/coverage-plan/selectors'
import {
  getLstuAcceptFundAllocation,
  hasFinishedLstuFundAllocation,
} from 'quick-quote/product-investment/fund-allocation/lstu/selectors'
import { hasFinishedRppFundAllocation } from 'quick-quote/product-investment/fund-allocation/rpp/selectors'
import {
  canProceedToFnaPhase,
  canProceedToFundAllocation,
  canProceedToInvestmentBenefitIllustration,
  canProceedToPremiumCalculation,
  canProceedToRiskQuestionnaireV2,
  isInvestmentInputsValid,
} from 'quick-quote/product-investment/journey-control-selectors'
import { canProceedToNormalTab } from 'quick-quote/product-s7cc/selectors'
import { canProceedPerfectLifeBenefitIllustration } from 'quick-quote/product-perfect-life/coverage-plan/selectors'
import { canProceedSukhapabDekDeeBenefitIllustration } from 'quick-quote/product-sukhapabdekdee/coverage-plan/selectors'
import { canProceedRetirementBenefitIllustration } from 'quick-quote/product-retirement/coverage-plan/selectors'
import { canProceedBumnanReadyBenefitIllustration } from 'quick-quote/product-bumnan-ready/coverage-plan/selectors'
import { ShareButtonSection } from 'quick-quote/product-whole-life/benefit-illustration/components'
import ESubmitButton from 'quick-quote/product-whole-life/benefit-illustration/components/e-submit/esub-button'
import { canProceedToBenefitIllustration } from 'quick-quote/product-whole-life/coverage-plan/selectors'
import type { AppState } from 'quick-quote/reducers'
import { canProceedToCoveragePlan } from 'quick-quote/selectors/next-tab-proceeding'
import type { Element } from 'react'
import ValidateRPQScore from '../../../../product-investment/components/risk-profile/validate-rpq-score'

export type NavButton = {
  type: 'link',
  to?: string,
  text?: string,
  disabled?: boolean,
  analyticsEventType?: AnalyticsEventType,
  onClick?: Function,
}

export type NavButtonConfiguration = {
  path: string,
  props: {
    backButton?: NavButton,
    nextButton?:
      | NavButton
      | {
          type: 'component',
          component: Element<*>,
        },
    extraButton?: React$ComponentType<*>,
  },
  excludePath?: string,
}

const productSelectionBackBtn = (state, NEED_AND_GAP_ANALYSIS = '') =>
  isOnFnaPhase(state)
    ? {
        type: 'link',
        to: qqPath(NEED_AND_GAP_ANALYSIS),
      }
    : {
        type: 'link',
        toHome: true,
      }

const wholeLifeNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    PRODUCT_SELECTION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
  } = ROUTE_PATH.WHOLE_LIFE

  return [
    {
      path: qqPath(PRODUCT_SELECTION),
      props: {
        backButton: productSelectionBackBtn(state, NEED_AND_GAP_ANALYSIS),
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(INSURED_INFORMATION),
        },
      },
    },
    {
      path: qqPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: qqPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: qqPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: qqPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: qqPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          disabled: !canProceedToFnaPhase(state),
          component: <ProceedToFnaButton />,
        },
        extraButton: ShareButtonSection,
      },
    },
    {
      path: qqPath(NEED_AND_GAP_ANALYSIS),
      props: {
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(MARKET_CONDUCTS),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
          disabled: false,
        },
      },
    },
  ]
}

const lifeReadyNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    PRODUCT_SELECTION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
  } = ROUTE_PATH.LIFE_READY

  return [
    {
      path: qqPath(PRODUCT_SELECTION),
      props: {
        backButton: productSelectionBackBtn(state, NEED_AND_GAP_ANALYSIS),
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(INSURED_INFORMATION),
        },
      },
    },
    {
      path: qqPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: qqPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: qqPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: qqPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: qqPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          disabled: !canProceedToFnaPhase(state),
          component: <ProceedToFnaButton />,
        },
        extraButton: ShareButtonSection,
      },
    },
    {
      path: qqPath(NEED_AND_GAP_ANALYSIS),
      props: {
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(MARKET_CONDUCTS),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
          disabled: false,
        },
      },
    },
  ]
}

const mrtaNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    PRODUCT_SELECTION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
  } = ROUTE_PATH.MRTA

  return [
    {
      path: qqPath(PRODUCT_SELECTION),
      props: {
        backButton: productSelectionBackBtn(state, NEED_AND_GAP_ANALYSIS),
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(INSURED_INFORMATION),
        },
      },
    },
    {
      path: qqPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: qqPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: qqPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: qqPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: qqPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          disabled: !canProceedToFnaPhase(state),
          component: <ESubmitButton />,
        },
        extraButton: ShareButtonSection,
      },
    },
    {
      path: qqPath(NEED_AND_GAP_ANALYSIS),
      props: {
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(MARKET_CONDUCTS),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
          disabled: false,
        },
      },
    },
  ]
}

const paNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    PRODUCT_SELECTION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
  } = ROUTE_PATH.PA

  return [
    {
      path: qqPath(PRODUCT_SELECTION),
      props: {
        backButton: productSelectionBackBtn(state, NEED_AND_GAP_ANALYSIS),
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(INSURED_INFORMATION),
        },
      },
    },
    {
      path: qqPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: qqPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: qqPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: qqPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: qqPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          disabled: !canProceedToFnaPhase(state),
          component: <ProceedToFnaButton />,
        },
      },
    },
    {
      path: qqPath(NEED_AND_GAP_ANALYSIS),
      props: {
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(MARKET_CONDUCTS),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
          disabled: false,
        },
      },
    },
  ]
}

const termLifeNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    PRODUCT_SELECTION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
  } = ROUTE_PATH.TERM_LIFE

  return [
    {
      path: qqPath(PRODUCT_SELECTION),
      props: {
        backButton: productSelectionBackBtn(state, NEED_AND_GAP_ANALYSIS),
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(INSURED_INFORMATION),
        },
      },
    },
    {
      path: qqPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: qqPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: qqPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: qqPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: qqPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          disabled: !canProceedToFnaPhase(state),
          component: <ProceedToFnaButton />,
        },
        extraButton: ShareButtonSection,
      },
    },
    {
      path: qqPath(NEED_AND_GAP_ANALYSIS),
      props: {
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(MARKET_CONDUCTS),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
          disabled: false,
        },
      },
    },
  ]
}

const protectionNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    PRODUCT_SELECTION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
  } = ROUTE_PATH.PROTECTION

  return [
    {
      path: qqPath(PRODUCT_SELECTION),
      props: {
        backButton: productSelectionBackBtn(state, NEED_AND_GAP_ANALYSIS),
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(INSURED_INFORMATION),
        },
      },
    },
    {
      path: qqPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: qqPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: qqPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: qqPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: qqPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          disabled: !canProceedToFnaPhase(state),
          component: <ProceedToFnaButton />,
        },
        extraButton: ShareButtonSection,
      },
    },
    {
      path: qqPath(NEED_AND_GAP_ANALYSIS),
      props: {
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(MARKET_CONDUCTS),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
          disabled: false,
        },
      },
    },
  ]
}

const investmentNavigationButtonV2 = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    RISK_QUESTIONNAIRE,
    FUND_ALLOCATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    PRODUCT_SELECTION,
    NEED_AND_GAP_ANALYSIS,
    LSTU_FUND_ALLOCATION,
    MARKET_CONDUCTS,
  } = ROUTE_PATH.INVESTMENT

  const backButton = {
    backButton: {
      type: 'link',
      to: qqPath(COVERAGE_PLAN),
    },
  }

  const backNextButton = {
    ...backButton,
    nextButton: {
      type: 'component',
      disabled: false,
      component: (
        <ValidateRPQScore
          buttonName={hasFirstYearLstu(state) ? MESSAGES.RPP_FUND : MESSAGES.FUND}
          linkButton={qqPath(FUND_ALLOCATION)}
          linkModalBtn={qqPath(PRODUCT_SELECTION)}
        />
      ),
    },
  }

  const navButton = canProceedToFundAllocation(state) ? backNextButton : backButton
  return [
    {
      path: qqPath(PRODUCT_SELECTION),
      props: {
        backButton: productSelectionBackBtn(state, NEED_AND_GAP_ANALYSIS),
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(INSURED_INFORMATION),
        },
      },
    },
    {
      path: qqPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: qqPath(COVERAGE_PLAN),
          disabled: !canProceedToPremiumCalculation(state),
        },
      },
    },
    {
      path: qqPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.RISK_QUESTIONNAIRE,
          to: qqPath(RISK_QUESTIONNAIRE),
          disabled: !canProceedToRiskQuestionnaireV2(state),
        },
      },
    },
    {
      path: qqPath(RISK_QUESTIONNAIRE),
      props: navButton,
    },
    {
      path: qqPath(FUND_ALLOCATION),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(RISK_QUESTIONNAIRE),
        },
        nextButton: {
          type: 'component',
          disabled: !hasFinishedRppFundAllocation(state),
          component: <AcceptFundAllocationButton />,
        },
      },
    },
    {
      path: qqPath(LSTU_FUND_ALLOCATION),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(FUND_ALLOCATION),
        },
        nextButton: !getLstuAcceptFundAllocation(state)
          ? {
              type: 'component',
              disabled: !hasFinishedLstuFundAllocation(state),
              component: <AcceptLstuFundAllocationButton message={MESSAGES.SUBMIT} />,
            }
          : {
              type: 'link',
              text: MESSAGES.BENEFIT_ILLUSTRATION,
              to: qqPath(BENEFIT_ILLUSTRATION),
              disabled: !canProceedToInvestmentBenefitIllustration(state),
            },
      },
    },
    {
      path: qqPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: hasFirstYearLstu(state)
          ? {
              type: 'link',
              to: qqPath(LSTU_FUND_ALLOCATION),
            }
          : {
              type: 'link',
              to: qqPath(FUND_ALLOCATION),
            },
        nextButton: {
          type: 'component',
          disabled: !canProceedToFnaPhase(state) || !isInvestmentInputsValid(state),
          component: <ProceedToFnaButton />,
        },
        extraButton: ShareQuoteButton,
      },
    },
    {
      path: qqPath(NEED_AND_GAP_ANALYSIS),
      props: {
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(MARKET_CONDUCTS),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
          disabled: false,
        },
      },
    },
  ]
}

const loanNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    PRODUCT_SELECTION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
  } = ROUTE_PATH.LOAN

  return [
    {
      path: qqPath(PRODUCT_SELECTION),
      props: {
        backButton: productSelectionBackBtn(state, NEED_AND_GAP_ANALYSIS),
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(INSURED_INFORMATION),
        },
      },
    },
    {
      path: qqPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: qqPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: qqPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: qqPath(BENEFIT_ILLUSTRATION),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(COVERAGE_PLAN),
        },
      },
    },
    {
      path: qqPath(NEED_AND_GAP_ANALYSIS),
      props: {
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(MARKET_CONDUCTS),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
          disabled: false,
        },
      },
    },
  ]
}

const healthNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    PRODUCT_SELECTION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
  } = ROUTE_PATH.HEALTH

  return [
    {
      path: qqPath(PRODUCT_SELECTION),
      props: {
        backButton: productSelectionBackBtn(state, NEED_AND_GAP_ANALYSIS),
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(INSURED_INFORMATION),
        },
      },
    },
    {
      path: qqPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: qqPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: qqPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: qqPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: qqPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          disabled: !canProceedToFnaPhase(state),
          component: <ProceedToFnaButton />,
        },
        extraButton: HealthShareButtonSection,
      },
    },
    {
      path: qqPath(NEED_AND_GAP_ANALYSIS),
      props: {
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(MARKET_CONDUCTS),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
          disabled: false,
        },
      },
    },
  ]
}

const ihealthyUltraNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    PRODUCT_SELECTION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
  } = ROUTE_PATH.IHEALTHY_ULTRA

  return [
    {
      path: qqPath(PRODUCT_SELECTION),
      props: {
        backButton: productSelectionBackBtn(state, NEED_AND_GAP_ANALYSIS),
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(INSURED_INFORMATION),
        },
      },
    },
    {
      path: qqPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: qqPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: qqPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: qqPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: qqPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          disabled: !canProceedToFnaPhase(state),
          component: <ProceedToFnaButton />,
        },
        extraButton: HealthShareButtonSection,
      },
    },
    {
      path: qqPath(NEED_AND_GAP_ANALYSIS),
      props: {
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(MARKET_CONDUCTS),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
          disabled: false,
        },
      },
    },
  ]
}

const flexiHealthNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    PRODUCT_SELECTION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
  } = ROUTE_PATH.FLEXI_HEALTH

  return [
    {
      path: qqPath(PRODUCT_SELECTION),
      props: {
        backButton: productSelectionBackBtn(state, NEED_AND_GAP_ANALYSIS),
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(INSURED_INFORMATION),
        },
      },
    },
    {
      path: qqPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: qqPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: qqPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: qqPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: qqPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          disabled: !canProceedToFnaPhase(state),
          component: <ProceedToFnaButton />,
        },
        extraButton: FlexiHealthShareButtonSection,
      },
    },
    {
      path: qqPath(NEED_AND_GAP_ANALYSIS),
      props: {
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(MARKET_CONDUCTS),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
          disabled: false,
        },
      },
    },
  ]
}

const savingNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    PRODUCT_SELECTION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
  } = ROUTE_PATH.SAVING

  return [
    {
      path: qqPath(PRODUCT_SELECTION),
      props: {
        backButton: productSelectionBackBtn(state, NEED_AND_GAP_ANALYSIS),
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(INSURED_INFORMATION),
        },
      },
    },
    {
      path: qqPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: qqPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: qqPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: qqPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: qqPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          disabled: !canProceedToFnaPhase(state),
          component: <ProceedToFnaButton />,
        },
        extraButton: HealthShareButtonSection,
      },
    },
    {
      path: qqPath(NEED_AND_GAP_ANALYSIS),
      props: {
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(MARKET_CONDUCTS),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
          disabled: false,
        },
      },
    },
  ]
}

const eSubmissionNavigationButton = (): NavButtonConfiguration[] => {
  return [
    {
      path: qqPath(ROUTE_PATH.REMOTE_SELLING.VIDEO_CONSENT),
      props: {
        backButton: {},
      },
    },
    {
      path: qqPath(ROUTE_PATH.ESUBMISSION_FORM),
      props: {
        backButton: {
          type: 'link',
        },
      },
      excludePath: 'submit',
    },
    {
      path: qqPath(ROUTE_PATH.ESUBMISSION_PAYMENT),
      props: {
        backButton: {
          type: 'link',
        },
      },
    },
  ]
}

const lifeSaveProNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    PRODUCT_SELECTION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
  } = ROUTE_PATH.LIFE_SAVE_PRO

  return [
    {
      path: qqPath(PRODUCT_SELECTION),
      props: {
        backButton: productSelectionBackBtn(state, NEED_AND_GAP_ANALYSIS),
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(INSURED_INFORMATION),
        },
      },
    },
    {
      path: qqPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: qqPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: qqPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: qqPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: qqPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          disabled: !canProceedToFnaPhase(state),
          component: <ProceedToFnaButton />,
        },
        extraButton: ShareButtonSection,
      },
    },
    {
      path: qqPath(NEED_AND_GAP_ANALYSIS),
      props: {
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(MARKET_CONDUCTS),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
          disabled: false,
        },
      },
    },
  ]
}

const retirementNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    PRODUCT_SELECTION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
  } = ROUTE_PATH.RETIREMENT

  return [
    {
      path: qqPath(PRODUCT_SELECTION),
      props: {
        backButton: productSelectionBackBtn(state, NEED_AND_GAP_ANALYSIS),
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(INSURED_INFORMATION),
        },
      },
    },
    {
      path: qqPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: qqPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: qqPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: qqPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedRetirementBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: qqPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          disabled: !canProceedToFnaPhase(state),
          component: <ProceedToFnaButton />,
        },
        extraButton: ShareButtonSection,
      },
    },
    {
      path: qqPath(NEED_AND_GAP_ANALYSIS),
      props: {
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(MARKET_CONDUCTS),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
          disabled: false,
        },
      },
    },
  ]
}

const bumnanReadyNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    PRODUCT_SELECTION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
  } = ROUTE_PATH.BUMNAN_READY

  return [
    {
      path: qqPath(PRODUCT_SELECTION),
      props: {
        backButton: productSelectionBackBtn(state, NEED_AND_GAP_ANALYSIS),
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(INSURED_INFORMATION),
        },
      },
    },
    {
      path: qqPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: qqPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: qqPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: qqPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedBumnanReadyBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: qqPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          disabled: !canProceedToFnaPhase(state),
          component: <ProceedToFnaButton />,
        },
        extraButton: ShareButtonSection,
      },
    },
    {
      path: qqPath(NEED_AND_GAP_ANALYSIS),
      props: {
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(MARKET_CONDUCTS),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
          disabled: false,
        },
      },
    },
  ]
}

const perfectLifeNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    PRODUCT_SELECTION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
  } = ROUTE_PATH.PERFECT_LIFE

  return [
    {
      path: qqPath(PRODUCT_SELECTION),
      props: {
        backButton: productSelectionBackBtn(state, NEED_AND_GAP_ANALYSIS),
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(INSURED_INFORMATION),
        },
      },
    },
    {
      path: qqPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: qqPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: qqPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: qqPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedPerfectLifeBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: qqPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          disabled: !canProceedToFnaPhase(state),
          component: <ProceedToFnaButton />,
        },
        extraButton: ShareButtonSection,
      },
    },
    {
      path: qqPath(NEED_AND_GAP_ANALYSIS),
      props: {
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(MARKET_CONDUCTS),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
          disabled: false,
        },
      },
    },
  ]
}

const sukhapabDekDeeNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    PRODUCT_SELECTION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
  } = ROUTE_PATH.SUKHAPABDEKDEE

  return [
    {
      path: qqPath(PRODUCT_SELECTION),
      props: {
        backButton: productSelectionBackBtn(state, NEED_AND_GAP_ANALYSIS),
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(INSURED_INFORMATION),
        },
      },
    },
    {
      path: qqPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: qqPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: qqPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: qqPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedSukhapabDekDeeBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: qqPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          disabled: !canProceedToFnaPhase(state),
          component: <ProceedToFnaButton />,
        },
        extraButton: ShareButtonSection,
      },
    },
    {
      path: qqPath(NEED_AND_GAP_ANALYSIS),
      props: {
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(MARKET_CONDUCTS),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
          disabled: false,
        },
      },
    },
  ]
}

const healthToppingNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    PRODUCT_SELECTION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
  } = ROUTE_PATH.HEALTH_TOPPING

  return [
    {
      path: qqPath(PRODUCT_SELECTION),
      props: {
        backButton: productSelectionBackBtn(state, NEED_AND_GAP_ANALYSIS),
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(INSURED_INFORMATION),
        },
      },
    },
    {
      path: qqPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: qqPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: qqPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: qqPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: qqPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          disabled: !canProceedToFnaPhase(state),
          component: <ProceedToFnaButton />,
        },
        extraButton: ShareButtonSection,
      },
    },
    {
      path: qqPath(NEED_AND_GAP_ANALYSIS),
      props: {
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(MARKET_CONDUCTS),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
          disabled: false,
        },
      },
    },
  ]
}

const s7ccNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const {
    MATURITY_VALIDATE,
    SELECT_POLICY_OWNER,
    INSURED_INFORMATION,
    COVERAGE_PLAN,
    BENEFIT_ILLUSTRATION,
    PRODUCT_SELECTION,
    NEED_AND_GAP_ANALYSIS,
    MARKET_CONDUCTS,
  } = ROUTE_PATH.S7CC

  return [
    {
      path: qqPath(PRODUCT_SELECTION),
      props: {
        backButton: productSelectionBackBtn(state, NEED_AND_GAP_ANALYSIS),
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(INSURED_INFORMATION),
        },
      },
    },
    {
      path: qqPath(MATURITY_VALIDATE),
      props: {
        backButton: {
          type: 'link',
          to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(PRODUCT_SELECTION),
        },
        nextButton: {
          type: 'component',
          component: <MaturityValidateButton />,
        },
      },
    },
    {
      path: qqPath(SELECT_POLICY_OWNER),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(MATURITY_VALIDATE),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.INSURED_PERSON,
          to: qqPath(INSURED_INFORMATION),
          disabled: !canProceedToNormalTab(state),
        },
      },
    },
    {
      path: qqPath(INSURED_INFORMATION),
      props: {
        backButton: {
          type: 'link',
          // to: isOnFnaPhase(state) ? qqPath(MARKET_CONDUCTS) : qqPath(PRODUCT_SELECTION),
          to: qqPath(SELECT_POLICY_OWNER),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.CALCULATE_PREMIUM,
          to: qqPath(COVERAGE_PLAN),
          disabled: !canProceedToCoveragePlan(state),
        },
      },
    },
    {
      path: qqPath(COVERAGE_PLAN),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(INSURED_INFORMATION),
        },
        nextButton: {
          type: 'link',
          text: MESSAGES.BENEFIT_ILLUSTRATION,
          to: qqPath(BENEFIT_ILLUSTRATION),
          disabled: !canProceedToBenefitIllustration(state),
          analyticsEventType: 'BIEvent',
        },
      },
    },
    {
      path: qqPath(BENEFIT_ILLUSTRATION),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(COVERAGE_PLAN),
        },
        nextButton: {
          type: 'component',
          disabled: !canProceedToFnaPhase(state),
          component: <ProceedToFnaButton />,
        },
        extraButton: HealthShareButtonSection,
      },
    },
    {
      path: qqPath(NEED_AND_GAP_ANALYSIS),
      props: {
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: qqPath(MARKET_CONDUCTS),
          disabled: false,
        },
      },
    },
    {
      path: qqPath(MARKET_CONDUCTS),
      props: {
        backButton: {
          type: 'link',
          to: qqPath(NEED_AND_GAP_ANALYSIS),
        },
        nextButton: {
          type: 'component',
          component: <ESubmitButton />,
          disabled: false,
        },
      },
    },
  ]
}

const qqNavigationButton = (state: AppState): NavButtonConfiguration[] => {
  const { PRODUCT_SELECTION } = ROUTE_PATH

  return [
    {
      path: qqPath(PRODUCT_SELECTION),
      props: {
        backButton: productSelectionBackBtn(state),
        nextButton: {
          type: 'link',
          text: MESSAGES.SUBMIT,
          to: '',
          disabled: true,
        },
      },
    },
  ]
}

export default (state: AppState): NavButtonConfiguration[] =>
  wholeLifeNavigationButton(state)
    .concat(lifeReadyNavigationButton(state))
    .concat(investmentNavigationButtonV2(state))
    .concat(loanNavigationButton(state))
    .concat(healthToppingNavigationButton(state))
    .concat(healthNavigationButton(state))
    .concat(ihealthyUltraNavigationButton(state))
    .concat(savingNavigationButton(state))
    .concat(protectionNavigationButton(state))
    .concat(lifeSaveProNavigationButton(state))
    .concat(flexiHealthNavigationButton(state))
    .concat(retirementNavigationButton(state))
    .concat(bumnanReadyNavigationButton(state))
    .concat(perfectLifeNavigationButton(state))
    .concat(sukhapabDekDeeNavigationButton(state))
    .concat(s7ccNavigationButton(state))
    .concat(termLifeNavigationButton(state))
    .concat(mrtaNavigationButton(state))
    .concat(paNavigationButton(state))
    .concat(qqNavigationButton(state))
    .concat(eSubmissionNavigationButton())
