import { compose, branch, renderNothing, withStateHandlers } from 'recompose'
import _ from 'lodash'
import { getToggles, getTogglesAllEnv } from 'quick-quote/feature-toggles'
import { getProductsRequest } from 'quick-quote/actions'
import store from 'e-submission/store'
import styled, { css } from 'styled-components'

const ICON_MAP = {
  1: '😀',
  2: '🤪',
  3: '😍',
  4: '😟',
  5: '😨',
  6: '😡',
  7: '😱',
}

const Container = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1060;
`
const ToggleButton = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1060;
  width: 16px;
  height: 16px;
  transition: all 0.3s ease-in-out;
  padding: 4px;
  transform: scale(${(props) => 1 + props.clicked * 0.2})
    rotateZ(
      ${(props) => props.clicked * -10 * (props.clicked % 2 === 0 ? 1 : -1)}deg
    );
  &:hover {
    width: 32px;
    height: 32px;
    border-top-right-radius: 8px;
    &::after {
      content: "${(props) => ICON_MAP[props.clicked]}";
    }
  }
`
const DebuggerPanel = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  overflow: scroll;
  background-color: #040404;
  z-index: 9999;
  transition: all 0.3s ease-in-out;
  opacity: 0;
  transform: translateX(-640px);
  max-width: 640px;
  width: 100%;
  height: 100vh;
  box-shadow: 0 0 8px 10px rgba(0, 0, 0, 0.25);

  scroll-behavior: smooth;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent; /* Chrome/Safari/Webkit */
  }

  ${({ show }) =>
    show &&
    css`
      opacity: 1;
      transform: translateX(0);
    `}
  & > div {
    padding: 4px 10px;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover {
      background-color: rgba(255, 255, 255, 0.15);
    }
  }
  & > div:not(:first-child) {
    margin-top: 1px;
  }
  table {
    font-family: monospace;
    font-size: 0.55rem;
    color: #fff;
    background-color: #000;
    border-collapse: collapse;
    width: 100%;

    tbody {
      tr td {
        background: #000;
      }
      tr:nth-child(even) td {
        background-color: #222;
      }
    }

    th,
    td {
      border: solid 1px #666;
    }

    th {
      background: #333;
      position: sticky;
      top: 0;
      padding: 6px;
      z-index: 1070;
    }

    .toggle-env {
      width: 70px;
    }

    th:nth-child(n + 3),
    td:nth-child(n + 3) {
      text-align: center;
    }
  }
`
const DebuggerOverlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #000;
  opacity: 0.5;
  display: ${({ show }) => (show ? 'block' : 'none')};
`
const Status = styled.span`
  border-radius: 14px;
  display: inline-block;
  vertical-align: middle;
  width: 28px;
  height: 14px;
  margin-right: 8px;
  position: relative;
  cursor: pointer;

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    background: #333;
    border: solid 2px #555;
    border-radius: 14px;
  }

  &::after {
    content: '';
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    transition: all 0.3s ease-in-out;
    position: absolute;
    top: 0;
    ${(props) =>
      props.on
        ? `
      background: #2ab27b;
      left: 14px;
    `
        : `
      background: #555;
      left: 0;
    `}

  &:not(:first-child) {
    margin-top: 8px;
  }
`
const EnvStatus = styled.div`
  border-radius: 14px;
  display: inline-block;
  vertical-align: middle;
  height: 14px;
  width: 14px;
  background-color: ${({ value }) => (value ? '#2ab27b' : 'transparent')};
  border: solid 2px ${({ value }) => (value ? '#2ab27b' : '#5a5a5a')};
`
const RuntimeToggleIndicatorContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  background: green;
  color: white;
  padding: 2px;
  font-family: monospace;
  font-size: 0.55rem;
`
const RUNTIME_TOGGLES = ['RUNTIME_ENABLE_SAGA_MONITOR']
export const ToggleSwitcher = ({ show, clicked, handleToggleBtnClick, hide, reRender }) => {
  const toggles = getTogglesAllEnv()
  const runningRuntimeToggle = RUNTIME_TOGGLES.filter((k) => getToggles()[k])
  const isRunningRuntimeToggle = runningRuntimeToggle.length > 0
  return (
    <Container id="toggle-switcher">
      <DebuggerOverlay onClick={hide} show={show} />
      <ToggleButton clicked={clicked} onClick={handleToggleBtnClick} />
      {isRunningRuntimeToggle && (
        <RuntimeToggleIndicatorContainer>{runningRuntimeToggle.join('\n')}</RuntimeToggleIndicatorContainer>
      )}
      <DebuggerPanel show={show}>
        <table>
          <colgroup>
            <col className="toggle" />
            <col className="toggle-value" />
            <col span="3" className="toggle-env" />
          </colgroup>
          <thead>
            <tr>
              <th />
              <th>
                <span>Value</span>
              </th>
              <th>
                <span>Sandbox</span>
              </th>
              <th>
                <span>UAT</span>
              </th>
              <th>
                <span>Prod</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {_.map(RUNTIME_TOGGLES).map((k) => (
              <tr key={k}>
                <td>
                  <Status
                    on={getToggles()[k]}
                    onClick={(e) => {
                      getToggles()[k] = !getToggles()[k]
                      reRender()
                      e.stopPropagation()
                    }}
                  />
                </td>
                <td>{k}</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            ))}
            {_.toPairs(getToggles()).map(([k, v]) => (
              <tr key={k}>
                <td>
                  <Status
                    on={v}
                    onClick={(e) => {
                      getToggles()[k] = !v
                      reRender()
                      e.stopPropagation()

                      store.dispatch(getProductsRequest())
                    }}
                  />
                </td>
                <td>{k}</td>
                <td>
                  <EnvStatus value={toggles.sandbox[k]} />
                </td>
                <td>
                  <EnvStatus value={toggles.uat[k]} />
                </td>
                <td>
                  <EnvStatus value={toggles.prod[k]} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </DebuggerPanel>
    </Container>
  )
}

export default compose(
  withStateHandlers(
    {
      clicked: 1,
      show: false,
      tmp: false,
    },
    {
      handleToggleBtnClick: (state) => (e) =>
        state.show
          ? { ...state, show: false, clicked: 1 }
          : state.clicked > 6
          ? { ...state, clicked: 1, show: true }
          : { ...state, clicked: state.clicked + 1 },
      hide: (state) => () => ({ ...state, show: false }),
      reRender: (state) => () => ({ ...state, tmp: Math.random() }),
    }
  ),
  branch((props) => !getToggles().ENABLE_GOD_MODE, renderNothing)
)(ToggleSwitcher)
