// @flow
import _ from 'lodash'

import riderMessages from 'core/data-model/constants/validation-messages'
import type { RiderSumAssuredRule } from 'core/data-model/rider/rules'
import type { RiderEligibilities } from 'core/data-model/rider'
import type { ValidationMessage } from 'core/data-model/validation'

export const checkRiderSumAssuredRule = (
  rule: RiderSumAssuredRule,
  eligibilities: RiderEligibilities
): ValidationMessage => {
  let errorMessage = ''

  if (eligibilities.rider.sumAssured < rule.minimumValue || eligibilities.rider.sumAssured > rule.maximumValue) {
    let messageId = `RULE_RIDER_${eligibilities.rider.code}_SUM_ASSURED`
    errorMessage = _.get(riderMessages, messageId) || riderMessages.RULE_MESSAGE_NOT_FOUND
  }

  return {
    type: rule.validationType,
    message: errorMessage,
  }
}
