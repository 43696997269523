import { getOr, isNil, isEmpty } from 'lodash/fp'
import { Dropdown } from 'common-components'

import { compose, withHandlers, mapProps, defaultProps } from 'recompose'

export const Component = Dropdown

export default (hoc) =>
  compose(
    defaultProps({
      value: {},
    }),
    withHandlers({
      onChange: ({ setValue, options, placeHolder }) => (value, index) => {
        const indexToFind = isNil(placeHolder) ? index : index - 1
        const option = options[indexToFind]
        if (option) {
          const text = getOr('', 'text', option)
          setValue({
            ...option,
            text: getOr(text, 'defaultText', option),
          })
        } else {
          setValue(undefined)
        }
      },
    }),
    mapProps(({ disabled, value, ...props }) => ({
      ...props,
      value: getOr('', 'value', value),
      disabled: isNil(disabled) ? isEmpty(props.options) : disabled,
    })),
    hoc
  )(Component)
