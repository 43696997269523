//@flow
export const ANALYTICS_BI_EVENT = 'ANALYTICS_BI_EVENT'
export type AnalyticsBiEvent = {
  type: 'ANALYTICS_BI_EVENT',
}

export const analyticsBiEvent = (): AnalyticsBiEvent => ({
  type: ANALYTICS_BI_EVENT,
})

export const ANALYTICS_PAGE_VIEW_EVENT = 'ANALYTICS_PAGE_VIEW_EVENT'
export type AnalyticsPageViewEvent = {
  type: 'ANALYTICS_PAGE_VIEW_EVENT',
  payload: string,
}

export const analyticsPageViewEvent = (payload: string): AnalyticsPageViewEvent => ({
  type: ANALYTICS_PAGE_VIEW_EVENT,
  payload,
})
