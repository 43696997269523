// @flow
import type { RiderPlan } from 'core/data-model/rider'
import Rider from './rider'
import _ from 'lodash'
import MESSAGES from 'core/data-model/constants/messages'
import VALUES from 'core/data-model/constants/values'

type RiderGroupProps = {
  toggleRider: (string, boolean) => void,
  toggleRiderModule?: (string, string, boolean) => void,
  toggleRiderGroupModule?: (string, string, boolean) => void,
  editRiderSumAssured: (string, number) => void,
  editRiderSelectedPlan: (string, RiderPlan) => void,
  riderCodes: string[],
  infoMessage?: string,
}

const RiderGroup = ({
  children,
  riderCodes,
  toggleRider,
  editRiderSumAssured,
  editRiderSelectedPlan,
  toggleRiderModule,
  toggleRiderGroupModule,
  infoMessage,
}: RiderGroupProps) => (
  <div id="rider-plan" className="form-group">
    <div className="form-group-title">
      <span className="form-group-title-text">
        {_.isEmpty(riderCodes)
          ? ''
          : riderCodes[0] === VALUES.PARIDER
          ? MESSAGES.RIDER_SELECTED_PLAN
          : MESSAGES.RIDER_GROUP_TITLE}
      </span>
    </div>
    {infoMessage ? (
      <label>
        <div className="info-message attached-icon form-control-feedback">
          <p>{'infoMessage'}</p>
        </div>
      </label>
    ) : (
      ''
    )}
    <div className="content">
      {riderCodes.map((code) => (
        <Rider
          key={'rider-key' + code}
          code={code}
          toggleRider={toggleRider}
          toggleRiderModule={toggleRiderModule}
          toggleRiderGroupModule={toggleRiderGroupModule}
          editRiderSumAssured={editRiderSumAssured}
          editRiderSelectedPlan={editRiderSelectedPlan}
        />
      ))}
    </div>
  </div>
)

export default RiderGroup
