// @flow
import VALUES from 'core/data-model/constants/values'
import MESSAGES from 'core/data-model/constants/bi-messages'
import { isShowHealthCheckList, isShowFinancialCheckList } from 'core/service/pdf-generation/products/mrta/selectors'

type HealthCheckProps = {
  age: number,
  sumAssuredContract: number,
  paymentMethod: string,
}

export const HealthCheckRegulations = ({ age, sumAssuredContract, paymentMethod }: HealthCheckProps) => {
  return (
    <div id="legal-disclaimer-market-conduct" className="legal group">
      <h3>
        {paymentMethod === VALUES.CASH_INCLUDE_LOAN
          ? MESSAGES.HEALTH_CHECK_CASH_INCLUDE_LOAN_HEADER
          : MESSAGES.HEALTH_CHECK_CASH_EXCLUDE_LOAN_HEADER}
      </h3>
      <HealthCheckList age={age} sumAssuredContract={sumAssuredContract} />
      <FinancialCheckList sumAssuredContract={sumAssuredContract} />
    </div>
  )
}

const HealthCheckList = ({ age, sumAssuredContract }) => {
  const isHealthCheckList = isShowHealthCheckList(age, Number(sumAssuredContract))
  if (isHealthCheckList.nonCheck) return <p>{MESSAGES.NO_HEALTH_CHECK}</p>
  if (isHealthCheckList.cond1 || isHealthCheckList.cond2)
    return (
      <div className="taxdeduction">
        <div>
          <strong>{MESSAGES.HEALTH_CHECK_LIST}</strong>
        </div>
        <div>{MESSAGES.LISTS_HEALTH_CHECK[0]}</div>
        <div>{MESSAGES.LISTS_HEALTH_CHECK[1]}</div>
        <div>{MESSAGES.LISTS_HEALTH_CHECK[2]}</div>
        <div>{MESSAGES.LISTS_HEALTH_CHECK[3]}</div>
        {isHealthCheckList.cond2 ? <p>{MESSAGES.LISTS_HEALTH_CHECK[4]}</p> : <br />}

        <div className="disclaimer">
          <p>{MESSAGES.NOTE}:</p>
          <p>{MESSAGES.REMARK_HEALTH_CHECK[0]}</p>
          <p>{MESSAGES.REMARK_HEALTH_CHECK[1]}</p>
        </div>
        <br />
      </div>
    )
}

const FinancialCheckList = ({ sumAssuredContract }) => {
  const isFinancialCheckList = isShowFinancialCheckList(Number(sumAssuredContract))
  if (!isFinancialCheckList.nonCheck) {
    if (isFinancialCheckList.cond1) return _displayFinancialRules('Q1')
    else if (isFinancialCheckList.cond2) return _displayFinancialRules('Q2')
    else if (isFinancialCheckList.cond3) return _displayFinancialRules('FULL')
    else return null
  } else {
    return null
  }
}

const _displayFinancialRules = (text) => {
  switch (text) {
    case 'Q1':
      return (
        <div className="taxdeduction">
          <div>
            <strong>{MESSAGES.FINANCIAL_STATUS_REPORTING_RULES}</strong>
          </div>
          <ol className="counter">
            <li> {MESSAGES.FINANCIAL_RULES[0]}</li>
          </ol>
        </div>
      )
    case 'Q2':
      return (
        <div className="taxdeduction">
          <div>
            <strong>{MESSAGES.FINANCIAL_STATUS_REPORTING_RULES}</strong>
          </div>
          <ol className="counter">
            <li> {MESSAGES.FINANCIAL_RULES[1]}</li>
          </ol>
        </div>
      )
    case 'FULL':
      return (
        <div className="taxdeduction">
          <div>
            <strong>{MESSAGES.FINANCIAL_STATUS_REPORTING_RULES}</strong>
          </div>
          <ol className="counter">
            <li> {MESSAGES.FINANCIAL_RULES[2][0]}</li>
            <li> {MESSAGES.FINANCIAL_RULES[2][1]}</li>
            <li> {MESSAGES.FINANCIAL_RULES[2][2]}</li>
            <li> {MESSAGES.FINANCIAL_RULES[2][3]}</li>
            <li> {MESSAGES.FINANCIAL_RULES[2][4]}</li>
          </ol>
        </div>
      )
    default:
      return null
  }
}
