import { compose, defaultProps } from 'recompose'
import styled from 'styled-components'

const MutedText = styled(({ value, children = value || '-', ...props }) => (
  <div dangerouslySetInnerHTML={{ __html: children }} {...props} />
))`
  color: ${({ theme }) => theme.variables['$color-scorpion']};
`

export default (hoc) =>
  compose(
    defaultProps({
      className: 'text-center',
    }),
    hoc
  )(MutedText)
