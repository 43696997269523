// @flow
import _ from 'lodash'
import { isNil } from 'core/service/lib/type-check'
import { Redirect } from 'react-router-dom'
import PATH from 'quick-quote/constants/path'
import MESSAGES from 'core/data-model/constants/validation-messages'
import BIMESSAGES from 'core/data-model/constants/bi-messages'
import VALUES from 'core/data-model/constants/values'
import { FormFeedback, FormGroup } from 'reactstrap'
import ProfileSnapshot from 'quick-quote/product-common/benefit-illustration/components/profile-summary/profile-snapshot'
import RiderBenefits from 'quick-quote/product-common/benefit-illustration/components/rider-benefits'
import LegalDisclaimerMarketConduct from 'quick-quote/product-common/benefit-illustration/components/legal-disclaimer-market-conduct'

import { SidebarMenu } from 'quick-quote/components'
import ProductBenefits from 'quick-quote/product-ihealthy-ultra/benefit-illustration/components/product-benefits/product-benefits'
import TaxConsentDeductionBenefit from 'quick-quote/product-common/benefit-illustration/components/tax-consent-deduction-benefit'

import PolicyValueTable from './policy-value/policy-value-table'

import BenefitSummaryContents from './benefitSummary/benefitSumaryContents'
import type { DisplayProduct } from 'core/service/display-product'
import type { Age } from 'core/data-model/insured'
import type { SumAssured$, BasicPremium$ } from 'core/data-model/basic-plan'
import type { PolicyValue, TaxDeduction } from 'core/service/benefit-illustration'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { BenefitSummaryTableProps } from 'quick-quote/v2/products/customs/12pl/components/benefit-summary-table/container'

export type BenefitIllustrationProps = {
  displayProduct: ?DisplayProduct,
  riders: (Rider & RiderState)[],
  genderLabel: string,
  age: Age,
  calculatedCoveragePeriod: number,
  calculatedPaymentPeriod: number,
  sumAssured$: SumAssured$,
  basicPremium$: BasicPremium$,
  totalPremium: number,
  totalRiderPremium: number,
  selectedModelFactorLabel: string,
  selectedModelFactorPeriod: number,
  hasNonLevelRider: boolean,
  coveragePlanStartDate: Date,
  legalDisclaimerEndDate: Date,
  policyValue: PolicyValue,
  hasProductSelected: boolean,
  isBlockedPdfGeneration: boolean,
  isBlockedEnteringESub: boolean,
  profileSummaryHeader: Function | string,
  taxDeduction: TaxDeduction,
  benefitSummaryProps12PL: BenefitSummaryTableProps,
}

const BenefitIllustration = ({
  displayProduct,
  riders,
  genderLabel,
  age,
  hasProductSelected,
  sumAssured$,
  basicPremium$,
  totalPremium,
  totalRiderPremium,
  calculatedCoveragePeriod,
  calculatedPaymentPeriod,
  selectedModelFactorLabel,
  selectedModelFactorPeriod,
  hasNonLevelRider,
  hasShowDisclaimerRiderAsterisk,
  coveragePlanStartDate,
  legalDisclaimerEndDate,
  policyValue,
  isBlockedPdfGeneration,
  isBlockedEnteringESub,
  profileSummaryHeader,
  taxDeduction,
  benefitSummaryProps12PL,
  isReviseQQ,
}: BenefitIllustrationProps) => {
  if (!hasProductSelected) {
    return <Redirect to={PATH.PRODUCT_SELECTION} push />
  } else if (isNil(displayProduct)) return <div id="benefit-illustration-invalid" />
  else {
    return (
      <div id="benefit-illustration">
        <div className="content">
          <ProfileSnapshot
            displayProduct={displayProduct}
            riders={riders}
            genderLabel={genderLabel}
            age={age}
            sumAssured={sumAssured$.value}
            basicPremium={basicPremium$.value}
            totalPremium={totalPremium}
            policyValue={policyValue}
            totalRiderPremium={totalRiderPremium}
            selectedModelFactorLabel={selectedModelFactorLabel}
            calculatedCoveragePeriod={calculatedCoveragePeriod}
            calculatedPaymentPeriod={calculatedPaymentPeriod}
            hasNonLevelRider={hasNonLevelRider}
            hasShowDisclaimerRiderAsterisk={hasShowDisclaimerRiderAsterisk}
            profileSummaryHeader={profileSummaryHeader}
            summaryTableHeader={({ displayProduct }) => displayProduct.nameThai}
            isReviseQQ={isReviseQQ}
          />
          <ProductBenefits
            displayProduct={displayProduct}
            sumAssured={sumAssured$.value}
            basicPremium={basicPremium$.value}
            calculatedPaymentPeriod={calculatedPaymentPeriod}
            calculatedCoveragePeriod={calculatedCoveragePeriod}
            policyValue={policyValue}
            benefitSummaryHeader={({ displayProduct }) => displayProduct.fullNameThai}
          />

          {riders.length > 0 && <RiderBenefits />}

          <PolicyValueTable policyValue={policyValue} basicPlan={displayProduct} />

          <BenefitSummaryContents
            displayProduct={displayProduct}
            coveragePlanStartDate={coveragePlanStartDate}
            legalDisclaimerEndDate={legalDisclaimerEndDate}
            benefitSummaryProps12PL={benefitSummaryProps12PL}
          />

          <TaxConsentDeductionBenefit taxDeduction={taxDeduction} />

          <LegalDisclaimerMarketConduct startDate={coveragePlanStartDate} endDate={legalDisclaimerEndDate} />

          {isBlockedEnteringESub ? (
            <FormGroup className="has-warning">
              <FormFeedback className={'eligibility-message attached-icon'}>
                <div> {MESSAGES.RULE_ENTER_ESUB_PREREQUISITE} </div>
              </FormFeedback>
            </FormGroup>
          ) : (
            ''
          )}
        </div>
        <SidebarMenu mainMenu={_getCorrectMenu(displayProduct)} />
      </div>
    )
  }
}

const _getCorrectMenu = (displayProduct) => {
  const code = _.get(displayProduct, 'code')
  switch (code) {
    case VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_25_15.JUNIOR:
    case VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_25_15.SENIOR:
    case VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_20_10.JUNIOR:
    case VALUES.IHEALTHY_ULTRA_MULTI_CODE.LIFE_PLUS_SAVER_20_10.SENIOR:
      return [
        {
          text: BIMESSAGES.SUMMARY_OF_MAIN_AND_ADDITIONAL_CONTRACTS,
          id: 'profile-summary',
          isSub: false,
        },
        {
          text: 'รายละเอียดของสัญญาหลัก',
          id: 'product-benefits',
          isSub: false,
        },
        {
          text: BIMESSAGES.POLICY_VALUE_TABLE,
          id: 'policy-value',
          isSub: false,
        },
        {
          text: BIMESSAGES.INVESTMENT_BENEFITS,
          id: 'investment-benefit',
          isSub: false,
        },
        {
          text: BIMESSAGES.LEGAL_DISCLAIMER,
          id: 'legal-disclaimer-market-conduct',
          isSub: false,
        },
      ]
    case VALUES.IHEALTHY_ULTRA_CODE['12PL']:
      return [
        {
          text: BIMESSAGES.SUMMARY_OF_MAIN_AND_ADDITIONAL_CONTRACTS,
          id: 'profile-summary',
          isSub: false,
        },
        {
          text: 'รายละเอียดของสัญญาหลัก',
          id: 'product-benefits',
          isSub: false,
        },
        {
          text: BIMESSAGES.POLICY_VALUE_TABLE,
          id: 'policy-value',
          isSub: false,
        },
        {
          text: BIMESSAGES.INVESTMENT_BENEFITS,
          id: 'summary-benefit',
          isSub: false,
        },
        {
          text: BIMESSAGES.LEGAL_DISCLAIMER,
          id: 'legal-disclaimer-market-conduct',
          isSub: false,
        },
      ]
    case VALUES.IHEALTHY_ULTRA_CODE.IHEALTHY_60:
    case VALUES.IHEALTHY_ULTRA_CODE.IHEALTHY_85:
      return [
        {
          text: BIMESSAGES.SUMMARY_OF_MAIN_AND_ADDITIONAL_CONTRACTS,
          id: 'profile-summary',
          isSub: false,
        },
        {
          text: 'รายละเอียดของสัญญาหลัก',
          id: 'product-benefits',
          isSub: false,
        },
        {
          text: BIMESSAGES.POLICY_VALUE_TABLE,
          id: 'policy-value',
          isSub: false,
        },
        {
          text: BIMESSAGES.LEGAL_DISCLAIMER,
          id: 'legal-disclaimer-market-conduct',
          isSub: false,
        },
      ]
    default:
      return [
        {
          text: BIMESSAGES.SUMMARY_OF_MAIN_AND_ADDITIONAL_CONTRACTS,
          id: 'profile-summary',
          isSub: false,
        },
        {
          text: 'รายละเอียดของสัญญาหลัก',
          id: 'product-benefits',
          isSub: false,
        },
        {
          text: BIMESSAGES.POLICY_VALUE_TABLE,
          id: 'policy-value',
          isSub: false,
        },
        {
          text: BIMESSAGES.BENEFIT_SUMMARY,
          id: 'benefit-summary',
          isSub: false,
        },
        {
          text: BIMESSAGES.LEGAL_DISCLAIMER,
          id: 'legal-disclaimer-market-conduct',
          isSub: false,
        },
      ]
  }
}

export default BenefitIllustration
