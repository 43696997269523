//@flow
import styled from 'styled-components'

type CircleProps = {
  color: string,
}

export const Circle = styled.div`
  width: 130px;
  height: 130px;
  border-radius: 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: white;
  margin-bottom: 20px;
  background-color: ${(props: CircleProps) => props.color};
`

export const Content = styled.div`
  width: 320px;
  @media (min-width: 768px) {
    width: 420px;
    margin-bottom: 20px;
  }
`

type RiskLevelDisplayBlockProps = {
  color: string,
  contentInCircle: Object,
  content: Object | string,
}

const RiskLevelDisplayBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin-bottom: 20px;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
    text-align: left;
    margin-bottom: 0;
  }
`

export const RiskLevelDisplayBlock = (props: RiskLevelDisplayBlockProps) => (
  <RiskLevelDisplayBlockWrapper>
    <Circle color={props.color}>{props.contentInCircle}</Circle>
    <Content>{props.content}</Content>
  </RiskLevelDisplayBlockWrapper>
)
