import { compact, flow, map, cond, stubTrue, constant } from 'lodash/fp'
import baseForm from 'e-submission/domain/data-model/form'
import { insuredHealth, payerHealth } from 'e-submission/domain/data-model/form/pages/health'
import { signature } from 'e-submission/domain/data-model/form/pages/signature'
import { submit } from 'e-submission/domain/data-model/form/pages/submit'
import { appendBefore, concatWith, createPageCreator, deepAppend } from 'e-submission/domain/data-model/form/utils'
import dividendField from './dividendField'
import { getQuickQuoteProductCode } from 'e-submission/domain/data-model/form/selectors'
import VALUES from 'core/data-model/constants/values'
import { isSelectedRemoteSelling } from '../../form/selectors'
import { videoConsent } from '../../form/pages/videoConsent'
import { remotePayment } from '../../form/pages/remotePayment'
import { remoteSignature } from '../../form/pages/remoteSignature'

export default (app) => {
  const createPage = createPageCreator(app)
  const productCode = getQuickQuoteProductCode(app)
  const selectedRemoteSelling = app && app.quickQuote ? isSelectedRemoteSelling(app) : false
  return flow(
    baseForm,
    map(createPage),
    concatWith(
      selectedRemoteSelling
        ? [
            createPage(signature(app)),
            createPage(videoConsent(app)),
            createPage(remotePayment(app)),
            createPage(remoteSignature(app)),
            createPage(submit(null, selectedRemoteSelling, app)),
          ]
        : [createPage(signature(app)), createPage(submit())]
    ),
    appendBefore(null, 'FATCA', [createPage(insuredHealth(app)), createPage(payerHealth(app))]),
    cond([
      [
        constant(!VALUES.PRODUCT_GIO.includes(productCode)),
        deepAppend(
          [['insured', 'sections'], ['personal', 'questions'], ['insured.motorcycleUsage']],
          dividendField(app)
        ),
      ],
      [stubTrue, (form) => form],
    ]),
    compact
  )(app)
}
