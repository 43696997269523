// @flow

import _ from 'lodash'
import values from 'core/data-model/constants/values'
import { getToggles } from 'quick-quote/feature-toggles'

export type RiskLevelItem = {
  minScore: number,
  maxScore: number,
  value: number,
  label: string,
  description: string,
}

export type RiskColor = { level: number, color: string }

export type RiskLevel = {
  level: RiskLevelItem[],
}

export type RiskRange = {
  min: number,
  max: number,
}

export type RiskEvaluated = {
  value: number,
  label: string,
  description: string,
  isEvaluated: boolean,
}

export const getRiskLevel = (): RiskLevel => {
  return require('core/service/data-config/investment/risk-level.json')
}

export const calculateRiskLevel = (score: number, riskLevel: RiskLevelItem[]): RiskEvaluated => {
  const risk = _.find(riskLevel, (r) => score >= r.minScore && score <= r.maxScore)
  const value = _.get(risk, 'value', 0)
  const label = _.get(risk, 'label', '')
  const description = getToggles().ENABLE_RPQ_FORM_2022
    ? _.get(risk, 'descriptionV2', '')
    : _.get(risk, 'description', '')

  return {
    value,
    label,
    isEvaluated: true,
    description,
  }
}

export const calculateAcceptableRiskRange = (riskEvaluated: RiskEvaluated, riskItems: RiskLevelItem[]): RiskRange => {
  const acceptableRiskLevels = riskItems.filter((riskItem) => riskItem.value <= riskEvaluated.value)
  return {
    min: _.min(_.map(acceptableRiskLevels, 'value')),
    max: _.max(_.map(acceptableRiskLevels, 'value')),
  }
}

export const calculateOverRiskRange = (riskEvaluated: RiskEvaluated, riskItems: RiskLevelItem[]): ?RiskRange => {
  const acceptableRiskLevels = riskItems.filter((riskItem) => riskItem.value > riskEvaluated.value)
  return isMostRiskyRisk(riskEvaluated, riskItems)
    ? null
    : {
        min: riskEvaluated.value + 1,
        max: _.max(_.map(acceptableRiskLevels, 'value')),
      }
}

export const isMostRiskyRisk = (riskEvaluated: RiskEvaluated, riskItems: RiskLevelItem[]): boolean => {
  const mostRiskyRisk = _.maxBy(riskItems, 'value')
  return riskEvaluated.value >= mostRiskyRisk.value
}

export const getRiskColor = (riskLevel: number): string => {
  return values.RISK_LEVEL_COLORS[riskLevel]
}

export const getAllRiskColors = (): RiskColor[] => {
  return Object.keys(values.RISK_LEVEL_COLORS).map((level) => ({
    level: parseInt(level),
    color: values.RISK_LEVEL_COLORS[level],
  }))
}
