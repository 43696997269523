// @flow

export { ProtectionProps } from './types'
import { legalDisclaimerMarketConduct } from 'core/service/pdf-generation/products/common/sections/legal-disclaimer-section'
import { policyValueTableICareSection } from 'core/service/pdf-generation/products/whole-life/sections/policy-value-table-section'
import { benefitSummarySection } from 'core/service/pdf-generation/products/whole-life/sections/benefit-summary-section'
import { taxDeductionBenefit } from 'core/service/pdf-generation/products/whole-life/sections/tax-deduction-section'

export const sectionICare = {
  legalDisclaimerSectionICare: legalDisclaimerMarketConduct,
  policyValueTableICare: policyValueTableICareSection,
  benefitSummarySection: benefitSummarySection,
  taxDeductionBenefit: taxDeductionBenefit,
}
