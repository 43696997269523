import { isNil, omitBy, isPlainObject, assign, has, getOr, set, unset, toPath } from 'lodash/fp'

export const app = (state = {}, action = {}) => {
  switch (action.type) {
    case 'SET_DEFAULT_APP_VALUE':
      if (has(action.fieldId, state)) {
        return state
      }
      return set(action.fieldId, action.value, state)
    case 'SET_APP_VALUE':
      return set(
        action.fieldId,
        isPlainObject(action.value)
          ? action.isExcludeNull
            ? action.value
            : omitBy(isNil, action.value)
          : action.value,
        state
      )
    case 'UNSET_APP_VALUE':
      return unset(action.fieldId, state)
    case 'ADD_APP_MEMBER':
      return set(action.fieldId, {}, state)
    case 'REMOVE_APP_MEMBER':
      const path = toPath(action.fieldId)
      const filteredIndex = +path.pop()
      const filteredArray = getOr([], path, state).filter((value, index) => index !== filteredIndex)
      return set(path, filteredArray, state)
    default:
      return state
  }
}

export const currentAppId = (state = '', action = {}) => {
  switch (action.type) {
    case 'RESET_CURRENT_APP':
      return ''
    case 'CREATE_NEW_APP':
    case 'CHANGE_CURRENT_APP':
      return action.appId
    default:
      return state
  }
}

export const byId = (state = {}, action = {}) => {
  switch (action.type) {
    case 'MERGE_APP': {
      const currentApp = state[action.appId]
      if (currentApp) {
        return assign(action.payload, {
          [action.appId]: assign(currentApp, {
            status: getOr(currentApp.status, [action.appId, 'status'], action.payload),
            updatedAt: getOr(currentApp.updatedAt, [action.appId, 'updatedAt'], action.payload),
          }),
        })
      }
      return action.payload
    }
    case 'CHANGE_CURRENT_APP': {
      const currentApp = state[action.appId]
      if (currentApp) {
        return {
          [action.appId]: currentApp,
        }
      }
      return state
    }
    case 'CREATE_NEW_APP':
      return {
        [action.appId]: assign(state[action.appId], {
          _id: action.appId,
        }),
      }
    case 'LOAD_APP':
      if (has(action.appId, state)) {
        return {
          ...state,
          [action.appId]: assign(action.payload, {
            _id: action.appId,
            clientSignature: null,
            receipts: getOr([], [action.appId, 'receipts'], state),
          }),
        }
      }
      return state
    case 'RESET_APP_STATE':
      return {}
    default:
      return state
  }
}

export const apps = (state = { byId: byId(), currentAppId: currentAppId() }, action = {}) => {
  switch (action.type) {
    case 'RESET_CURRENT_APP':
    case 'MERGE_APP':
    case 'LOAD_APP':
    case 'CHANGE_CURRENT_APP':
    case 'CREATE_NEW_APP':
      return {
        ...state,
        byId: byId(state.byId, action),
        currentAppId: currentAppId(state.currentAppId, action),
      }
    case 'SET_DEFAULT_APP_VALUE':
    case 'SET_APP_VALUE':
    case 'UNSET_APP_VALUE':
    case 'ADD_APP_MEMBER':
    case 'REMOVE_APP_MEMBER':
      return {
        ...state,
        byId: {
          ...byId(state.byId, action),
          [state.currentAppId]: app(state.byId[state.currentAppId], action),
        },
      }
    case 'RESET_APP_STATE':
      return state
    default:
      return state
  }
}
