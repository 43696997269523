//@flow
import Mustache from 'mustache'
import { buildHeaderTable } from 'core/service/pdf-generation/utils/builder'
import MESSAGES from 'core/data-model/constants/bi-messages'
import { findDataOnListByRiderCode } from 'quick-quote/product-common/benefit-illustration/components/rider-benefits/components/hsudr-rider-benefit'
import { Header } from 'core/service/pdf-generation/products/common/components/general'

const LEFT_MARGIN = 10
const TOP_MARGIN = 10

const header = {
  marginBottom: 5,
  bold: true,
}

export const buildHeaderTableHSUDR = (riderBenefit, rider) => {
  const contents = riderBenefit.contents
  const headers = [
    MESSAGES.BENEFIT_TITLE,
    MESSAGES.BENEFIT_BAHT,
    MESSAGES.RIDER_BENEFIT_DAY_OR_TIME,
    MESSAGES.RIDER_TOTAL_BENEFIT_BAHT,
  ]
  return {
    headerRows: 1,
    dontBreakRows: true,
    widths: ['*', 75, 75, 85],
    body: [
      buildHeaderTable(headers),
      [{ text: contents[0].text[0], alignment: 'left', bold: true, colSpan: 4 }],
      [
        [
          {
            text: `${contents[1].text[0]}`,
            alignment: 'left',
            bold: true,
          },
          {
            text: `   ${contents[1].text[1]}`,
            alignment: 'left',
            bold: false,
            marginLeft: 5,
          },
        ],
        {
          text: `${findDataOnListByRiderCode(contents[1].benefit, rider)} ${contents[1].suffixs[0]}`,
          alignment: 'center',
        },
        {
          stack: [
            { text: `${contents[1].totalTimes[0]} ${contents[1].suffixs[1]}` },
            { text: `${contents[1].suffixs[2]}` },
          ],
          alignment: 'center',
        },
        { text: `${findDataOnListByRiderCode(contents[1].totalBenefit, rider)}`, alignment: 'center' },
      ],
      [
        {
          text: `${contents[2].text[0]}`,
          alignment: 'left',
          bold: true,
          colSpan: 3,
        },
        {
          text: '-',
          alignment: 'center',
        },
        {
          text: '-',
          alignment: 'center',
        },
        {
          stack: [
            { text: `${findDataOnListByRiderCode(contents[2].totalBenefit, rider)}` },
            { text: `${contents[2].suffixs[2]}` },
          ],
          alignment: 'center',
          rowSpan: 5,
        },
      ],
      [
        {
          text: `${contents[2].text[1]}`,
          alignment: 'left',
          bold: false,
        },
        {
          text: `${contents[2].benefit.firstBenefit}`,
          alignment: 'center',
          bold: false,
          rowSpan: 3,
        },
        {
          text: `${contents[2].totalTimes[0]}`,
          rowSpan: 3,
          alignment: 'center',
        },
        {
          text: '-',
        },
      ],
      [
        {
          text: `${contents[2].text[2]}`,
          alignment: 'left',
          bold: false,
        },
        {
          text: '-',
          alignment: 'center',
        },
        {
          text: '-',
          alignment: 'center',
        },
        {
          text: '-',
        },
      ],
      [
        {
          text: `${contents[2].text[3]}`,
          alignment: 'left',
          bold: false,
        },
        {
          text: '-',
          alignment: 'center',
        },
        {
          text: '-',
          alignment: 'center',
        },
        {
          text: '-',
        },
      ],
      [
        {
          text: `${contents[2].text[4]}`,
          alignment: 'left',
          bold: false,
        },
        {
          text: `${findDataOnListByRiderCode(contents[2].benefit.secondBenefit, rider)} ${contents[2].suffixs[1]}`,
          alignment: 'center',
        },
        {
          text: `${contents[2].totalTimes[1]} ${contents[2].suffixs[1]} `,
          alignment: 'center',
        },
        {
          text: '-',
        },
      ],
      [
        {
          text: `${contents[3].text[0]}`,
          alignment: 'left',
          bold: true,
          marginBottom: 50,
        },
        {
          text: `${findDataOnListByRiderCode(contents[3].benefit, rider)} ${contents[3].suffixs[0]}`,
          alignment: 'center',
        },
        { text: `${contents[1].totalTimes} ${contents[3].suffixs[1]}`, alignment: 'center' },
        { text: `${findDataOnListByRiderCode(contents[3].totalBenefit, rider)}`, alignment: 'center' },
      ],
    ],
  }
}

export const hsudrBenefitSection = (rider, riderBenefitData) => {
  const riderBenefit = riderBenefitData.riderBenefitData.benefits
  const benefitTable = buildHeaderTableHSUDR(riderBenefit, rider)

  return [
    {
      text: riderBenefit.title,
      style: header,
      marginTop: TOP_MARGIN,
    },
    {
      marginLeft: LEFT_MARGIN,
      stack: [{ text: riderBenefit.description[0] }, { text: riderBenefit.description[1] }],
    },
    {
      text: `${riderBenefit.subject}`,
      style: header,
      alignment: 'center',
      marginTop: TOP_MARGIN,
    },
    {
      marginTop: TOP_MARGIN,
      table: benefitTable,
      layout: 'lightBorder',
      style: 'table',
    },
  ]
}

export const buildHeaderSecondTableHSUDR = (riderBenefit, rider) => {
  const contents = riderBenefit.contents
  const headers = [
    MESSAGES.BENEFIT_TITLE,
    MESSAGES.BENEFIT_BAHT,
    MESSAGES.RIDER_BENEFIT_DAY_OR_TIME,
    MESSAGES.RIDER_TOTAL_BENEFIT_BAHT,
  ]
  return {
    headerRows: 1,
    dontBreakRows: true,
    widths: ['*', 75, 75, 85],
    body: [
      buildHeaderTable(headers),
      [
        {
          text: `${contents[4].text[0]}`,
          alignment: 'left',
          bold: true,
          colSpan: 3,
        },
        {
          text: '-',
          alignment: 'center',
        },
        {
          text: '-',
          alignment: 'center',
        },
        {
          stack: [
            { text: `${findDataOnListByRiderCode(contents[4].totalBenefit, rider)}` },
            { text: `${contents[4].suffixs[0]}` },
          ],
          alignment: 'center',
          rowSpan: 6,
        },
      ],
      [
        {
          text: `${contents[4].text[1]}`,
          alignment: 'left',
        },
        {
          text: `${contents[4].benefit[0]}`,
          alignment: 'center',
          rowSpan: 5,
        },
        {
          text: '-',
          alignment: 'center',
          rowSpan: 5,
        },
        { text: '-' },
      ],
      [
        {
          text: `${contents[4].text[2]}`,
          alignment: 'left',
        },
        { text: '-' },
        { text: '-' },
        { text: '-' },
      ],
      [
        {
          text: `${contents[4].text[3]}`,
          alignment: 'left',
        },
        { text: '-' },
        { text: '-' },
        { text: '-' },
      ],
      [
        {
          text: `${contents[4].text[4]}`,
          alignment: 'left',
        },
        { text: '-' },
        { text: '-' },
        { text: '-' },
      ],
      [
        {
          text: `${contents[4].text[5]}`,
          alignment: 'left',
        },
        { text: '-' },
        { text: '-' },
        { text: '-' },
      ],
      [
        {
          text: `${contents[5].text[0]}`,
          alignment: 'left',
          bold: true,
        },
        { text: '-', alignment: 'center' },
        { text: '-', alignment: 'center' },
        { text: `${contents[5].suffixs[0]}`, alignment: 'center' },
      ],
      [
        {
          text: `${contents[6].text[0]}`,
          alignment: 'left',
          bold: true,
          colSpan: 4,
        },
        { text: '-', alignment: 'center' },
        { text: '-', alignment: 'center' },
        { text: '-', alignment: 'center' },
      ],
      [
        {
          text: `${contents[7].text[0]}`,
          alignment: 'left',
          bold: true,
          colSpan: 4,
        },
        { text: '-', alignment: 'center' },
        { text: '-', alignment: 'center' },
        { text: '-', alignment: 'center' },
      ],
    ],
  }
}

export const hsudrSecondPageBenefitSection = (rider, riderBenefitData, title, benefitSummary) => {
  const riderBenefit = riderBenefitData.riderBenefitData.benefits
  const benefitTable = buildHeaderSecondTableHSUDR(riderBenefit, rider)
  return [
    Header(`${title} (ต่อ)`),
    benefitSummary,
    {
      text: riderBenefit.title,
      style: header,
      marginTop: TOP_MARGIN,
    },
    {
      marginLeft: LEFT_MARGIN,
      stack: [{ text: riderBenefit.description[0] }, { text: riderBenefit.description[1] }],
    },
    {
      text: `${riderBenefit.subject} (ต่อ)`,
      style: header,
      alignment: 'center',
      marginTop: TOP_MARGIN,
    },
    {
      marginTop: TOP_MARGIN,
      table: benefitTable,
      layout: 'lightBorder',
      style: 'table',
    },
  ]
}

export const buildHeaderThirdTableHSUDR = (riderBenefit, rider) => {
  const contents = riderBenefit.contents
  const headers = [
    MESSAGES.BENEFIT_TITLE,
    MESSAGES.BENEFIT_BAHT,
    MESSAGES.RIDER_BENEFIT_DAY_OR_TIME,
    MESSAGES.RIDER_TOTAL_BENEFIT_BAHT,
  ]
  return {
    headerRows: 1,
    dontBreakRows: true,
    widths: ['*', 75, 75, 85],
    body: [
      buildHeaderTable(headers),
      [
        {
          text: `${contents[8].text[0]}`,
          alignment: 'left',
        },
        {
          text: `${contents[8].benefit[0]}`,
          alignment: 'center',
        },
        { text: `${contents[8].totalTimes[0]}`, alignment: 'center' },
        {
          text: `${findDataOnListByRiderCode(contents[4].totalBenefit, rider)} ${contents[8].suffixs[0]}`,
          alignment: 'center',
        },
      ],
      [
        {
          text: [
            riderBenefit.contents[9].text[0],
            {
              text: `${riderBenefit.contents[9].text[1]}`,
              decoration: 'underline',
            },
          ],
          alignment: 'left',
        },
        {
          text: `${contents[9].benefit[0]}`,
          alignment: 'center',
        },
        { text: `${contents[9].totalTimes[0]}`, alignment: 'center' },
        {
          text: `${findDataOnListByRiderCode(contents[2].totalBenefit, rider)} ${contents[9].suffixs[0]}`,
          alignment: 'center',
        },
      ],
      [
        {
          text: `${contents[10].text[0]}`,
          alignment: 'left',
          bold: true,
        },
        {
          text: `${contents[10].benefit[0]}`,
          alignment: 'center',
        },
        { text: `${contents[10].totalTimes[0]}`, alignment: 'center' },
        {
          text: `${contents[10].suffixs[0]}`,
          alignment: 'center',
        },
      ],
      [
        {
          text: `${contents[11].text[0]}`,
          alignment: 'left',
          bold: true,
        },
        {
          text: `${contents[11].benefit[0]}`,
          alignment: 'center',
        },
        { text: `${contents[11].totalTimes[0]}`, alignment: 'center' },
        {
          text: `${contents[11].totalBenefit[0]}`,
          alignment: 'center',
        },
      ],
      [
        {
          text: `${contents[12].text[0]}`,
          alignment: 'left',
          bold: true,
        },
        {
          text: `${contents[12].benefit[0]}`,
          alignment: 'center',
          rowSpan: 2,
        },
        { text: `${contents[12].totalTimes[0]}`, alignment: 'center', rowSpan: 2 },
        {
          text: `${findDataOnListByRiderCode(contents[12].totalBenefit, rider)} ${contents[12].suffixs[0]}`,
          alignment: 'center',
          rowSpan: 2,
        },
      ],
      [
        {
          text: `${contents[12].text[1]}`,
          alignment: 'left',
          bold: true,
        },
        {
          text: '-',
        },
        { text: '-' },
        {
          text: '-',
        },
      ],
    ],
  }
}

export const hsudrThirdPageBenefitSection = (rider, riderBenefitData, title, benefitSummary) => {
  const riderBenefit = riderBenefitData.riderBenefitData.benefits
  const benefitTable = buildHeaderThirdTableHSUDR(riderBenefit, rider)
  return [
    Header(`${title} (ต่อ)`),
    benefitSummary,
    {
      text: riderBenefit.title,
      style: header,
      marginTop: TOP_MARGIN,
    },
    {
      marginLeft: LEFT_MARGIN,
      stack: [{ text: riderBenefit.description[0] }, { text: riderBenefit.description[1] }],
    },
    {
      text: `${riderBenefit.subject} (ต่อ)`,
      style: header,
      alignment: 'center',
      marginTop: TOP_MARGIN,
    },
    {
      marginTop: TOP_MARGIN,
      table: benefitTable,
      layout: 'lightBorder',
      style: 'table',
    },
  ]
}

export const buildHeaderForthTableHSUDR = (riderBenefit, rider) => {
  const contents = riderBenefit.contents
  const headers = [
    MESSAGES.BENEFIT_TITLE,
    MESSAGES.BENEFIT_BAHT,
    MESSAGES.RIDER_BENEFIT_DAY_OR_TIME,
    MESSAGES.RIDER_TOTAL_BENEFIT_BAHT,
  ]
  return {
    headerRows: 1,
    dontBreakRows: true,
    widths: ['*', 75, 75, 85],
    body: [
      buildHeaderTable(headers),
      [
        {
          text: `${contents[12].text[2]}`,
          alignment: 'left',
          bold: true,
        },
        {
          text: `${contents[12].benefit[0]}`,
          alignment: 'center',
        },
        { text: `${contents[12].totalTimes[0]}`, alignment: 'center' },
        {
          text: `${findDataOnListByRiderCode(contents[12].totalBenefit, rider)} ${contents[12].suffixs[0]}`,
          alignment: 'center',
        },
      ],
      [
        {
          text: `${contents[13].text[0]}`,
          alignment: 'left',
          bold: true,
        },
        {
          text: `${findDataOnListByRiderCode(contents[13].benefit, rider)}`,
          alignment: 'center',
        },
        { text: `${contents[13].totalTimes[0]}`, alignment: 'center' },
        {
          text: `${findDataOnListByRiderCode(contents[13].totalBenefit, rider)} ${contents[13].suffixs[0]}`,
          alignment: 'center',
        },
      ],
      [
        {
          text: `${contents[14].text[0]}`,
          alignment: 'left',
          bold: true,
        },
        {
          stack: [
            {
              text: `${findDataOnListByRiderCode(contents[14].benefit, rider)}`,
            },
            { text: `${contents[14].suffixs[0]}` },
          ],
          alignment: 'center',
        },
        { text: `${contents[13].totalTimes[0]}`, alignment: 'center' },
        {
          text: `${findDataOnListByRiderCode(contents[14].totalBenefit, rider)} ${contents[14].suffixs[1]}`,
          alignment: 'center',
        },
      ],
      [
        {
          text: `${contents[15].text[0]}`,
          alignment: 'left',
          bold: true,
        },
        {
          text: `${contents[15].benefit[0]}`,
          colSpan: 3,
        },
        { text: '-' },
        {
          text: '-',
        },
      ],
      [
        {
          text: `${contents[16].text[0]}`,
          alignment: 'left',
        },
        {
          text: `${contents[16].benefit}`,
          alignment: 'center',
        },
        { text: `${contents[16].totalTimes}`, alignment: 'center' },
        {
          text: `${contents[16].totalBenefit}`,
          alignment: 'center',
        },
      ],
      [
        {
          text: `${contents[17].text[0]}`,
          alignment: 'left',
        },
        {
          text: `${contents[17].benefit}`,
          alignment: 'center',
        },
        { text: `${contents[17].totalTimes}`, alignment: 'center' },
        {
          text: `${contents[17].totalBenefit}`,
          alignment: 'center',
        },
      ],
    ],
  }
}

export const hsudrForthPageBenefitSection = (rider, riderBenefitData, title, benefitSummary) => {
  const riderBenefit = riderBenefitData.riderBenefitData.benefits
  const benefitTable = buildHeaderForthTableHSUDR(riderBenefit, rider)
  return [
    Header(`${title} (ต่อ)`),
    benefitSummary,
    {
      text: riderBenefit.title,
      style: header,
      marginTop: TOP_MARGIN,
    },
    {
      marginLeft: LEFT_MARGIN,
      stack: [{ text: riderBenefit.description[0] }, { text: riderBenefit.description[1] }],
    },
    {
      text: `${riderBenefit.subject} (ต่อ)`,
      style: header,
      alignment: 'center',
      marginTop: TOP_MARGIN,
    },
    {
      marginTop: TOP_MARGIN,
      table: benefitTable,
      layout: 'lightBorder',
      style: 'table',
    },
  ]
}

export const buildEndorsementTableHSUDR = (endoseBenefit, rider) => {
  const contents = endoseBenefit.contents
  const headers = [
    MESSAGES.BENEFIT_TITLE,
    MESSAGES.BENEFIT_BAHT,
    MESSAGES.RIDER_BENEFIT_DAY_OR_TIME,
    MESSAGES.RIDER_TOTAL_BENEFIT_BAHT,
  ]
  return {
    headerRows: 1,
    dontBreakRows: true,
    widths: ['*', 75, 75, 85],
    body: [
      buildHeaderTable(headers),
      [
        {
          text: `${contents[0].text[0]}`,
          alignment: 'left',
          bold: true,
        },
        {
          text: `${findDataOnListByRiderCode(contents[0].benefit, rider)} ${contents[0].suffixs[0]}`,
          alignment: 'center',
        },
        { text: `${contents[0].totalTimes[0]}`, alignment: 'center' },
        {
          text: `${findDataOnListByRiderCode(contents[0].totalBenefit, rider)}`,
          alignment: 'center',
        },
      ],
      [
        {
          text: `${contents[1].text[0]}`,
          alignment: 'left',
          bold: true,
        },
        {
          text: `${contents[1].benefit[0]}`,
          alignment: 'center',
        },
        { text: `${contents[1].totalTimes[0]}`, alignment: 'center' },
        {
          text: `${findDataOnListByRiderCode(contents[1].totalBenefit, rider)}`,
          alignment: 'center',
        },
      ],
      [
        {
          text: `${contents[2].text[0]}`,
          alignment: 'left',
          bold: true,
        },
        {
          text: `${findDataOnListByRiderCode(contents[2].benefit, rider)}`,
          alignment: 'center',
        },
        { text: `${contents[2].totalTimes[0]}`, alignment: 'center' },
        {
          text: `${findDataOnListByRiderCode(contents[2].totalBenefit, rider)}`,
          alignment: 'center',
        },
      ],
      [
        {
          text: `${contents[3].text[0]}`,
          alignment: 'left',
          bold: true,
          colSpan: 4,
        },
      ],
      [
        {
          text: `${contents[4].text[0]}`,
          alignment: 'left',
          bold: true,
        },
        {
          text: `${contents[4].benefit[0]}`,
          alignment: 'center',
        },
        { text: `${contents[4].totalTimes[0]}`, alignment: 'center' },
        {
          text: `${findDataOnListByRiderCode(contents[4].totalBenefit, rider)}`,
          alignment: 'center',
        },
      ],
      [
        {
          text: `${contents[5].text[0]}`,
          alignment: 'left',
          bold: true,
        },
        {
          text: `${contents[5].benefit[0]}`,
          alignment: 'center',
        },
        { text: `${contents[5].totalTimes[0]}`, alignment: 'center' },
        {
          text: `${findDataOnListByRiderCode(contents[5].totalBenefit, rider)}`,
          alignment: 'center',
        },
      ],
    ],
  }
}

export const hsudrEndorsementBenefitSection = (rider, riderBenefitData, title, benefitSummary) => {
  const endorse = riderBenefitData.riderBenefitData.endorse
  const riderBenefit = riderBenefitData.riderBenefitData.benefits
  const territoryCondition = riderBenefitData.riderBenefitData.territoryCondition
  const yearlyBenefit = riderBenefitData.riderBenefitData.yearlyBenefit
  const endorseTable = buildEndorsementTableHSUDR(endorse, rider)

  return [
    Header(`${title} (ต่อ)`),
    benefitSummary,
    {
      text: riderBenefit.title,
      style: header,
      marginTop: TOP_MARGIN,
    },
    {
      marginLeft: LEFT_MARGIN,
      stack: [{ text: riderBenefit.description[0] }, { text: riderBenefit.description[1] }],
    },
    {
      text: `${endorse.subject}`,
      style: header,
      alignment: 'center',
      marginTop: TOP_MARGIN - 2,
    },
    {
      marginTop: TOP_MARGIN - 2,
      table: endorseTable,
      layout: 'lightBorder',
      style: 'table',
    },
    {
      text: territoryCondition.title,
      style: header,
      marginTop: TOP_MARGIN - 5,
    },
    {
      marginLeft: LEFT_MARGIN,
      text: territoryCondition.description,
    },
    {
      text: Mustache.render(yearlyBenefit.title, {
        value: findDataOnListByRiderCode(yearlyBenefit.values, rider),
      }),
      style: header,
      marginTop: TOP_MARGIN - 5,
    },
    {
      marginLeft: LEFT_MARGIN,
      text: yearlyBenefit.description,
    },
  ]
}

export const hsudrNoticeSection = (riderBenefitData, benefitSummary, title) => {
  const riderNotice = riderBenefitData.riderBenefitData.notice
  const contents = riderBenefitData.riderBenefitData.notice.contents
  return [
    Header(`${title} (ต่อ)`),
    benefitSummary,
    {
      text: riderNotice.title,
      style: header,
      marginTop: TOP_MARGIN,
    },
    {
      ol: [
        {
          stack: [
            {
              text: contents[0].text,
            },
            {
              stack: [
                {
                  text: '1.1 ' + contents[0].list[0].text,
                },
                {
                  text: '1.2 ' + contents[0].list[1].text,
                },
                {
                  marginLeft: LEFT_MARGIN * 2,
                  columns: [
                    {
                      stack: [
                        '- ' + contents[0].list[1].list[0],
                        '- ' + contents[0].list[1].list[2],
                        '- ' + contents[0].list[1].list[4],
                        '- ' + contents[0].list[1].list[6],
                      ],
                    },
                    {
                      stack: [
                        '- ' + contents[0].list[1].list[1],
                        '- ' + contents[0].list[1].list[3],
                        '- ' + contents[0].list[1].list[5],
                        '- ' + contents[0].list[1].list[7],
                      ],
                    },
                  ],
                },
                {
                  text: '1.3 ' + contents[0].list[2].text.split('\n')[0],
                },
                {
                  marginLeft: LEFT_MARGIN + 5,
                  text: contents[0].list[2].text.split('\n')[1],
                },
                {
                  marginLeft: LEFT_MARGIN + 5,
                  ol: [
                    {
                      text: contents[0].list[2].list[0],
                    },
                    {
                      text: contents[0].list[2].list[1].replace('5 ปี', '5 ปี '),
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          stack: [
            { text: contents[1].text.replace('เป็นลายลักษณ์อักษร', 'เป็น ลายลักษณ์อักษร') },
            {
              stack: [
                {
                  text: contents[1].list[0],
                },
                {
                  text: contents[1].list[1],
                },
                {
                  text: contents[1].list[2],
                },
                {
                  text: contents[1].list[3],
                },
              ],
            },
          ],
        },
        {
          stack: [
            {
              text: contents[2].text,
            },
            {
              stack: [
                {
                  text: contents[2].list[0],
                },
                {
                  text: contents[2].list[1],
                },
              ],
            },
          ],
        },
        {
          text: contents[3].text,
        },
      ],
    },
    {
      fontSize: 11,
      text: riderNotice.noticePeriod.text,
    },
  ]
}

export const hsudrDisclaimerSection = (riderBenefitData, benefitSummary, title) => {
  const riderDisclaimer = riderBenefitData.riderBenefitData.disclaimer
  return [
    Header(`${title} (ต่อ)`),
    benefitSummary,
    {
      text: riderDisclaimer.title,
      style: header,
      marginTop: TOP_MARGIN,
    },
    {
      marginLeft: LEFT_MARGIN + 10,
      text: riderDisclaimer.description,
    },
    {
      marginLeft: LEFT_MARGIN + 10,
      ol: [
        {
          text: `${riderDisclaimer.list[0].text}`,
        },
        {
          text: `${riderDisclaimer.list[1].text}`,
        },
        {
          text: `${riderDisclaimer.list[2].text}`,
        },
        {
          text: `${riderDisclaimer.list[3].text}`,
        },
        {
          text: `${riderDisclaimer.list[4].text}`,
        },
        {
          text: `${riderDisclaimer.list[5].text}`,
        },
        {
          stack: [
            { text: `${riderDisclaimer.list[6].text}` },
            {
              type: 'none',
              marginLeft: -9,
              ol: [
                { text: `${riderDisclaimer.list[6].conditions[0]}` },
                { text: `${riderDisclaimer.list[6].conditions[1]}` },
                { text: `${riderDisclaimer.list[6].conditions[2]}` },
              ],
            },
          ],
        },
      ],
    },
  ]
}

export const hsudrCriticalListSection = (riderBenefitData, benefitSummary, title) => {
  const riderCriticalList = riderBenefitData.riderBenefitData.criticalList
  return [
    Header(`${title} (ต่อ)`),
    benefitSummary,
    {
      text: riderCriticalList.title,
      style: header,
      marginTop: TOP_MARGIN,
    },
    {
      marginLeft: LEFT_MARGIN + 10,
      ol: [
        {
          text: `${riderCriticalList.list[0].text}`,
        },
        {
          text: `${riderCriticalList.list[1].text}`,
        },
        {
          text: `${riderCriticalList.list[2].text}`,
        },
        {
          text: `${riderCriticalList.list[3].text}`,
        },
        {
          text: `${riderCriticalList.list[4].text}`,
        },
        {
          text: `${riderCriticalList.list[5].text}`,
        },
        {
          text: `${riderCriticalList.list[6].text}`,
        },
        {
          text: `${riderCriticalList.list[7].text}`,
        },
        {
          text: `${riderCriticalList.list[8].text}`,
        },
        {
          text: `${riderCriticalList.list[9].text}`,
        },
        {
          text: `${riderCriticalList.list[10].text}`,
        },
        {
          text: `${riderCriticalList.list[11].text}`,
        },
        {
          text: `${riderCriticalList.list[12].text}`,
        },
        {
          text: `${riderCriticalList.list[13].text}`,
        },
        {
          text: `${riderCriticalList.list[14].text}`,
        },
        {
          text: `${riderCriticalList.list[15].text}`,
        },
        {
          text: `${riderCriticalList.list[16].text}`,
        },
        {
          text: `${riderCriticalList.list[17].text}`,
        },
        {
          text: `${riderCriticalList.list[18].text}`,
        },
        {
          text: `${riderCriticalList.list[19].text}`,
        },
      ],
    },
  ]
}
