// @flow

import type { DisplayProduct } from 'core/service/display-product'
import { render as renderProductInformationHeader } from './components/product-information-header'
import template from '../common/template'
import type { HealthProps } from './types'

const productInformationHeader = (
  basicPlan: DisplayProduct,
  selectedPlanName: string,
  selectedPlanCode: string,
  redLine: string
) => renderProductInformationHeader(basicPlan, selectedPlanName, selectedPlanCode, redLine)

const healthTemplate = async (props: HealthProps) => {
  const { basicPlan, selectedPlanName, selectedPlanCode, redLine } = props
  const header = productInformationHeader(basicPlan, selectedPlanName, selectedPlanCode, redLine)
  return template(props, header)
}

export default healthTemplate
