import styled from 'styled-components'
import PropTypes from 'prop-types'
import { has, isEqual, set, remove, get } from 'lodash/fp'
import { connect } from 'react-redux'
import { compose, withStateHandlers, withContext, withHandlers, lifecycle, mapProps } from 'recompose'
import { Flex } from 'rebass'
import Button from 'e-submission/components/Button'
import { withModalToggle } from 'lib/with-hoc'
import { FormFeedback, ModalHeader, ModalBody } from 'reactstrap'
import _getOr from 'lodash/fp/getOr'
import _ from 'lodash'

import { createSection } from 'e-submission/domain/data-model/form/utils'
import {
  removeError,
  resetValidation,
  verifyPayment,
  changePaymentMethod,
  checkPaymentStatus,
  setAppValue,
} from 'e-submission/apps/actions'
import { getErrors, isSectionValid, getCurrentAppId, getCurrentApp } from 'e-submission/apps/selectors'
import Dialog from 'e-submission/components/Dialog'
import Icon from 'e-submission/components/Icon'
import Content from 'e-submission/components/Layout/Content'
import CreditCardConsent from 'e-submission/components/Form/Payment/creditCardConsent'
import CreditCardPaymentMethod from '/e-submission/components/Form/Payment/creditCardPaymentMethod'
import { CREDIT_CARD_PAYMENT_METHOD } from 'e-submission/domain/data-model/constants'

import { getToggles } from 'quick-quote/feature-toggles'
import NumberInput from 'react-number-format'
import SendLinkSmsEmail from 'e-submission/containers/Dialog/SendLinkSmsEmail'
import { isSelectedRemoteSelling, isPayerInfoValid } from 'e-submission/domain/data-model/form/selectors'

import store from 'e-submission/store'

const removeFromArray = remove.convert({
  immutable: false,
})

export const CybersourceDisplayCreditCardPaymentFormButton = ({
  disabled,
  className,
  toggleModal,
  isModalOpen,
  value,
  checked,
  onOpen,
  onClick,
  policyId,
  product,
  onVerifyPayment,
  selectedModelFactorLabel,
  handleConsentValidation,
  isDisabledPaymentButton,
  handleCheckMethod,
  handleCheckCreditcardRelationShip,
  closeModal,
  onOpenQuicklink,
  onResendQuicklink,
  isOpenQuickpayModal,
  app,
  state,
  onChangePaymentMethod,
  creditCardPaymentMethod,
  creditCardRelationship,
  quickpayDetail,
}) => {
  const { status, isSendLink, expiredDateTime } = quickpayDetail
  const isEnabledECBRSystemization = getToggles().ENABLE_ECBR_SYSTEMIZATION

  const isRemoteSelling = isSelectedRemoteSelling(app)
  const isNotRemoteSelling = !creditCardRelationship //TODO Check with biz
  const isNotSelectedAllOptions = isEnabledECBRSystemization
    ? !(creditCardPaymentMethod && isPayerInfoValid(app, state))
    : !(creditCardPaymentMethod && creditCardRelationship)

  return (
    <div className={className}>
      {checked || status === 'SUCCESS' ? (
        <div className="form-group has-success">
          <FormFeedback className="validation-message attached-icon">
            <p>ชําระเงินเรียบร้อย</p>
          </FormFeedback>
        </div>
      ) : (
        <div>
          {getToggles().ENABLE_QUICKLINK_PAYMENT ? (
            <Flex mt={10}>
              <CreditCardPaymentMethod
                data-testid="creditcard-payment-method"
                handleCheckMethod={handleCheckMethod}
                creditCardMethod={creditCardPaymentMethod}
                isSendLink={isSendLink}
                isRemoteSelling={isRemoteSelling}
              />
            </Flex>
          ) : null}
          {getToggles().ENABLE_CREDIT_CARD_RELATIONSHIP ? (
            <Flex mt={10}>
              <CreditCardConsent
                data-testid="creditcard-consent"
                handleConsentValidation={handleConsentValidation}
                handleCheckCreditcardRelationShip={handleCheckCreditcardRelationShip}
                creditCardRelationship={creditCardRelationship}
                isSendLink={isSendLink}
              />
            </Flex>
          ) : null}

          {getToggles().ENABLE_QUICKLINK_PAYMENT ? (
            <div data-testid="quicklink-button" id="quicklink-status">
              {!isSendLink && !expiredDateTime ? (
                <Flex width={1} justify="center" mt={10}>
                  <Button
                    id="quicklink-button"
                    disabled={isRemoteSelling ? isNotRemoteSelling : isNotSelectedAllOptions}
                    onClick={onOpenQuicklink}
                  >
                    ยืนยัน
                  </Button>
                </Flex>
              ) : (
                <Flex column width={1} align="center" mt={10}>
                  <Button className="btn-col" onClick={onVerifyPayment}>
                    ตรวจสอบการชำระเงิน
                  </Button>
                  {!_.isEmpty(status) && (
                    <Button className="btn-col" outline onClick={onResendQuicklink}>
                      สร้างลิงก์ชำระเงินอีกครั้ง
                    </Button>
                  )}
                  {!_.isEmpty(status) && (
                    <Button className="btn-col" outline onClick={onChangePaymentMethod}>
                      เปลี่ยนวิธีการชำระเบี้ย
                    </Button>
                  )}
                  {expiredDateTime ? (
                    <FormFeedback className="info-message attached-icon">
                      <p>
                        ลิงก์ในการชำระเบี้ยของท่านจะหมดอายุภายในวันที่ {expiredDateTime.date} เวลา{' '}
                        {expiredDateTime.time} น.
                        <br />
                        หรือภายใน 60 นาทีหลังจากได้รับข้อความกรุณาตรวจสอบการชำระเบี้ยผู้เอาประกัน
                      </p>
                    </FormFeedback>
                  ) : null}
                </Flex>
              )}
            </div>
          ) : (
            <Flex mt={10} data-testid="cybersource-button">
              <Flex width={1} justify="flex-end">
                <Button disabled={isDisabledPaymentButton} onClick={onOpen}>
                  ชำระ
                </Button>
              </Flex>
            </Flex>
          )}
        </div>
      )}
      <SendLinkSmsEmail
        data-testid="quicklink-form-modal"
        isOpen={isOpenQuickpayModal}
        onExit={closeModal}
        currentApp={app}
      />
      <Dialog id="payment-form-modal" isOpen={isModalOpen} toggle={toggleModal} className={className}>
        <ModalHeader>
          <div className="btn-left" onClick={toggleModal}>
            <Icon.arrowLeft />
          </div>
          <span>กรุณาตรวจสอบข้อมูลก่อนการชำระเบี้ย</span>
        </ModalHeader>
        <ModalBody>
          <Content>
            <div>
              <div className="modal-title">
                <Icon.cybersource />
              </div>

              <Flex justify="space-between" className="user-fullname-container"></Flex>
              <div className="">
                <Flex justify="space-between">
                  <label className="text-primary">หมายเลขกรมธรรม์</label>
                  <label className="text-primary">{policyId}</label>
                </Flex>
                <Flex justify="space-between">
                  <label className="text-primary">ผลิตภัณฑ์</label>
                  <label className="text-primary">{product}</label>
                </Flex>
                <Flex justify="space-between">
                  <label className="text-primary">งวดการชำระเบี้ยประกันภัย</label>
                  <label className="text-primary">{selectedModelFactorLabel}</label>
                </Flex>

                <hr className="hr-solid-grey" />

                <Flex justify="space-between">
                  <label className="text-primary">
                    <b>จำนวนที่ชำระ</b>
                  </label>
                  <label className="text-primary">
                    <b>
                      <NumberInput
                        value={value}
                        displayType="text"
                        suffix=" THB"
                        thousandSeparator
                        decimalPrecision={2}
                      />
                    </b>
                  </label>
                </Flex>
                <hr className="hr-solid-brand-primary" />
                <label className="text-muted">*หมายเหตุ : ​ชำระกรมธรรม์งวดแรก</label>
              </div>
              <div className="modal-footer">
                <Button disabled={disabled} onClick={onClick}>
                  <span>ยืนยันการชำระเบี้ย</span>
                </Button>
              </div>
            </div>
          </Content>
        </ModalBody>
      </Dialog>
    </div>
  )
}

const StyledDisplayCreditCardPaymentFormButton = styled(CybersourceDisplayCreditCardPaymentFormButton)`
  .cross-icon {
    cursor: pointer;
  }
  .btn-col {
    width: 100%;
    max-width: 237px;
  }
  .info-message {
    width: 100%;
  }
  ${Content} {
    padding: 20px;
  }
  .modal-header {
    .modal-title {
      flex-direction: row;
      display: flex;
    }
    .icon,
    .btn-left {
      z-index: 999;
      cursor: pointer;
      height: 40px;
    }
    span {
      margin-left: -28px;
      flex: 1 1 auto;
    }
  }
  .modal-body {
    .expire-date {
      padding-right: 10px;
    }
    .omise-submit {
      margin-top: 40px;
    }
    span {
      margin-right: 5px;
    }
    .form-container {
      margin-top: 10px;
      .form-group {
        padding: 0;
      }
      input {
        font-size: 1rem;
      }
      input::placeholder {
        color: ${({ theme }) => theme.variables['$color-silver']};
      }
    }
    .omise-logo {
      margin-top: 5px;
    }
  }
`

export const getErrorMessage = (errors) => {
  let errorMessage

  if (has('OMISE_PAYMENT', errors)) {
    const errorCode = get('OMISE_PAYMENT', errors)
    if (errorCode === 'invalid_card') {
      errorMessage = 'หมายเลขบัตรไม่ถูกต้อง'
    } else if (errorCode === 'browser_popup_blocker') {
      errorMessage = 'กรุณาอณุญาตป๊อปอัปเพื่อ OTP'
    } else {
      errorMessage = 'ดำเนินการไม่สำเร็จ กรุณาลองใหม่อีกครั้ง'
    }
  }

  return errorMessage
}

export const addLocalStore = compose(
  withModalToggle,
  lifecycle({
    UNSAFE_componentWillReceiveProps(nextProps) {
      if (this.props.isModalOpen && !this.props.checked && nextProps.checked) {
        this.props.toggleModal()
      }
    },
  }),
  withStateHandlers(
    { form: {}, isOpenQuickpayModal: false },
    {
      setValue: ({ form }) => (id, value) => ({
        form: set(id, value, form),
      }),
      toggleQuickpayModal: ({ isOpenQuickpayModal }) => () => ({ isOpenQuickpayModal: !isOpenQuickpayModal }),
      closeModal: () => () => ({ isOpenQuickpayModal: false }),
    }
  ),
  connect(
    (state, { form, fields }) => {
      const currentAppId = getCurrentAppId(state)
      const currentApp = getCurrentApp(state)
      const errors = getErrors(state)
      const app = getCurrentApp(state)
      const quickpayDetail = _getOr({ status: null, isSendLink: false, expiredDateTime: null }, 'quickpayLink', app)
      const creditCardPaymentMethod = _getOr('', 'creditCardMethod', app)
      const creditCardRelationship = _getOr('', 'creditCardRelationship', app)
      const isRemoteSelling = isSelectedRemoteSelling(app)
      return {
        errorMessage: getErrorMessage(errors),
        state: set(['apps', 'byId', currentAppId], _.isEmpty(form) ? app : form, state),
        disabled: !isSectionValid(state, [createSection(fields)])(form),
        app: currentApp,
        quickpayDetail,
        creditCardPaymentMethod,
        creditCardRelationship,
        isRemoteSelling,
      }
    },
    null,
    null,
    { pure: false }
  ),
  withHandlers(() => {
    let callbackList = []
    const rerender = () => callbackList.forEach((callback) => callback())
    return {
      getCallBackList: () => () => callbackList,
      toggleModal: ({ dispatch, errorMessage, toggleModal }) => () => {
        if (errorMessage) {
          dispatch(removeError('OMISE_PAYMENT'))
        }
        toggleModal()
      },
      onOpen: ({ dispatch, toggleModal }) => (event) => {
        event.stopPropagation()

        dispatch(resetValidation())
        toggleModal()
      },

      onOpenQuicklink: ({ dispatch, toggleModal, toggleQuickpayModal, ...props }) => (event) => {
        event.stopPropagation()
        const creditCardPaymentMethod = get('creditCardMethod', props.app)

        if (props.isRemoteSelling) {
          store.dispatch(setAppValue('creditCardMethod', CREDIT_CARD_PAYMENT_METHOD.ON_AZ))
        }

        if (creditCardPaymentMethod === CREDIT_CARD_PAYMENT_METHOD.QUICKLINK) {
          toggleQuickpayModal()
        } else {
          dispatch(resetValidation())
          toggleModal()
        }
      },
      onResendQuicklink: ({ dispatch, toggleModal, toggleQuickpayModal, ...props }) => (event) => {
        event.stopPropagation()
        dispatch(checkPaymentStatus(toggleQuickpayModal))
      },
      onChangePaymentMethod: ({ dispatch }) => () => {
        dispatch(changePaymentMethod())
      },
      onVerifyPayment: ({ dispatch }) => () => {
        dispatch(verifyPayment())
      },

      onClick: ({ onClick, toggleModal, form, startCybs }) => () => {
        startCybs()
      },
      sendCommand: ({ closeModal, startCybs }) => () => {
        startCybs()
        closeModal()
      },
      setValue: ({ dispatch, errorMessage, setValue }) => (id, value) => {
        setValue(id, value)
        rerender()

        if (errorMessage) {
          dispatch(removeError('OMISE_PAYMENT'))
        }
      },
      dispatch: ({ dispatch }) => (action) => {
        dispatch(action)
        rerender()
      },
      getState: ({ state }) => () => state,
      subscribe: () => (callback) => {
        callbackList.push(callback)
        return () => removeFromArray(isEqual(callback), callbackList)
      },
    }
  }),
  withStateHandlers(
    {
      isDisabledPaymentButton: !!getToggles().ENABLE_CREDIT_CARD_RELATIONSHIP,
    },
    {
      handleConsentValidation: (state) => (val) => {
        return { isDisabledPaymentButton: val }
      },
      handleCheckMethod: (state) => (val) => ({ isSelectedMethod: val }),
      handleCheckCreditcardRelationShip: (state) => (val) => ({ isSelectedCreditCardRelationShip: val }),
    }
  ),
  mapProps((props) => ({
    ...props,
  })),
  withContext(
    {
      store: PropTypes.shape({
        subscribe: PropTypes.func.isRequired,
        dispatch: PropTypes.func.isRequired,
        getState: PropTypes.func.isRequired,
      }),
      storeSubscription: PropTypes.shape({
        trySubscribe: PropTypes.func.isRequired,
        tryUnsubscribe: PropTypes.func.isRequired,
        notifyNestedSubs: PropTypes.func.isRequired,
        isSubscribed: PropTypes.func.isRequired,
      }),
    },
    ({ subscribe, dispatch, getState }) => ({
      store: { subscribe, dispatch, getState },
      storeSubscription: null,
    })
  )
)

export default (hoc) => compose(hoc, addLocalStore)(StyledDisplayCreditCardPaymentFormButton)
