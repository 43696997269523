export const APPLICATION_FETCH_UPDATE = 'APPLICATION_FETCH_UPDATE'
export const RESET_APPLICATION_FETCH_UPDATE = 'RESET_APPLICATION_FETCH_UPDATE'
export const APPLICATION_FETCH_UPDATE_SUCCESS = 'APPLICATION_FETCH_UPDATE_SUCCESS'
export const APPLICATION_FETCH_UPDATE_ERROR = 'APPLICATION_FETCH_UPDATE_ERROR'
export const APPLICATION_INSTALL_UPDATE = 'APPLICATION_INSTALL_UPDATE'
export const APPLICATION_INSTALL_UPDATE_SUCCESS = 'APPLICATION_INSTALL_UPDATE_SUCCESS'
export const APPLICATION_INSTALL_UPDATE_ERROR = 'APPLICATION_INSTALL_UPDATE_ERROR'
export const APPLICATION_CHECK_VERSION = 'APPLICATION_CHECK_VERSION'
export const APPLICATION_CHECK_VERSION_VALIDVERSION = 'APPLICATION_CHECK_VERSION_VALIDVERSION'
export const APPLICATION_CHECK_VERSION_INVALIDVERSION = 'APPLICATION_CHECK_VERSION_INVALIDVERSION'

export const ACTIONS = {
  RESET_APPLICATION_FETCH_UPDATE,
  APPLICATION_FETCH_UPDATE,
  APPLICATION_FETCH_UPDATE_SUCCESS,
  APPLICATION_FETCH_UPDATE_ERROR,
  APPLICATION_INSTALL_UPDATE,
  APPLICATION_INSTALL_UPDATE_SUCCESS,
  APPLICATION_INSTALL_UPDATE_ERROR,
  APPLICATION_CHECK_VERSION,
  APPLICATION_CHECK_VERSION_VALIDVERSION,
  APPLICATION_CHECK_VERSION_INVALIDVERSION,
}

export default ACTIONS
