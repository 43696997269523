//@flow
import type { Profile } from 'core/data-model/identity'

import CheckCircle from '@axah/web-toolkit/icons/check-circle.svg'

export const ProfileImage = ({ profile }: { profile: Profile }) => {
  const isFacebookUser = profile.provider === 'facebook'

  const FBProfileImage = () => (
    <div className="user">
      <div className="image" style={{ backgroundImage: `url(${profile.user_image})` }} />
    </div>
  )

  const IAMProfileImage = () => (
    <div className="user">
      <div className="image show-default-image" />
      <div className="check-circle">
        <CheckCircle className="icon" />
      </div>
    </div>
  )

  return isFacebookUser ? <FBProfileImage /> : <IAMProfileImage />
}
