// @flow
import { flow, sumBy, get, map } from 'lodash/fp'
import MESSAGES from 'core/data-model/constants/bi-messages'
import { d } from 'core/service/lib/decimal'
import type { PolicyValue } from 'core/service/benefit-illustration/policy-value'

export type InvestmentBenefit = {
  title: string,
  guaranteeValue: number,
  rates: Array<number>,
}

export type InvestmentDivedendRate = {
  name: string,
  rate: number,
}

export type InvestmentBenefitTable = {
  guaranteeBenefits: Array<InvestmentBenefit>,
  nonGuaranteeBenefits: Array<InvestmentBenefit>,
}

export type InvestmentBenefitTableParams = {
  policyValue: PolicyValue,
  dividendRates: Array<InvestmentDivedendRate>,
  sumAssured: number,
}

export const summaryBenefits = (investmentBenefits: Array<InvestmentBenefit>): InvestmentBenefit => {
  let sumGuarantee = 0
  let sumRates = []
  investmentBenefits.forEach((v) => {
    sumGuarantee += v.guaranteeValue
    v.rates.forEach((v, k) => {
      sumRates[k] = sumRates[k] ? sumRates[k] + v : v
    })
  })

  return { title: MESSAGES.INVESTMENT_BENEFITS_GUARANTEE_TOTAL, guaranteeValue: sumGuarantee, rates: sumRates }
}

export const getGuaranteeBenefits = (
  investmentBenefitTableParams: InvestmentBenefitTableParams
): Array<InvestmentBenefit> => {
  const yearEndPolicyValues = get('yearEndPolicyValues')(investmentBenefitTableParams.policyValue)
  const dividendRates = get('dividendRates')(investmentBenefitTableParams)
  const cashDropSummary = flow(sumBy('cashDrop'))(yearEndPolicyValues)
  const guaranteeMaturity = get('cashDrop')(yearEndPolicyValues[yearEndPolicyValues.length - 1])
  const cashDropWithOutLast = cashDropSummary - guaranteeMaturity

  let results = [
    {
      title: MESSAGES.INVESTMENT_BENEFITS_GUARANTEE_CASHDROP,
      guaranteeValue: cashDropWithOutLast,
      rates: generateFixDividend(dividendRates, cashDropWithOutLast),
    },
    {
      title: MESSAGES.INVESTMENT_BENEFITS_GUARANTEE_MATURITY,
      guaranteeValue: guaranteeMaturity,
      rates: generateFixDividend(dividendRates, guaranteeMaturity),
    },
  ]
  return [...results, summaryBenefits(results)]
}

export const getNonGuaranteeBenefits = (
  investmentBenefitTableParams: InvestmentBenefitTableParams
  // productCategory: string
): Array<InvestmentBenefit> => {
  const subtotalCashDrop = get('subtotalCashDrop')(investmentBenefitTableParams.policyValue)
  const dividendRates = get('dividendRates')(investmentBenefitTableParams)
  const sumAssured = get('sumAssured')(investmentBenefitTableParams)
  const dividendBysumAssured = generateDividendBysumAssured(dividendRates, sumAssured)

  return [
    { title: MESSAGES.INVESTMENT_BENEFITS_NONGUARANTEE_MATURITY, guaranteeValue: 0, rates: dividendBysumAssured },
    {
      title: MESSAGES.INVESTMENT_BENEFITS_NONGUARANTEE_TOTAL,
      guaranteeValue: subtotalCashDrop,
      rates: flow(map((v) => v + subtotalCashDrop))(dividendBysumAssured),
    },
  ]
}

export const generateFixDividend = (rates: Array<InvestmentDivedendRate>, fixRate: number): Array<number> => {
  return rates.map((v) => d(fixRate).toNumber())
}

export const generateDividendBysumAssured = (
  rates: Array<InvestmentDivedendRate>,
  sumAssured: number
): Array<number> => {
  return rates.map((v: InvestmentDivedendRate) =>
    d(sumAssured)
      .dividedBy(100)
      .times(v.rate)
      .toNumber()
  )
}

export const generateInvestmentBenefit = (
  investmentBenefitTableParams: InvestmentBenefitTableParams
): InvestmentBenefitTable => {
  return {
    guaranteeBenefits: getGuaranteeBenefits(investmentBenefitTableParams),
    nonGuaranteeBenefits: getNonGuaranteeBenefits(investmentBenefitTableParams),
  }
}
