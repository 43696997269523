// @flow

export const getDevicePixelRatio = (devicePixelRatio: number, isWebBrowser: boolean) => {
  if (isWebBrowser) return 4
  if (devicePixelRatio === 3) return 1
  if (devicePixelRatio === 2) return 1
  if (devicePixelRatio < 2) return 4
  return devicePixelRatio * 2
}

export const SVGToBase64 = (rawSVG: string, isWebBrowser: boolean): Promise<string> => {
  //can't update unescape becuase effect old svg graph
  const url = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(rawSVG)))
  const img = new Image()

  return new Promise((resolve, reject) => {
    let canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    canvas.style.width = '100%'
    canvas.style.height = '100%'

    img.src = url
    const devicePixelRatio = getDevicePixelRatio(window.devicePixelRatio, isWebBrowser)
    img.onload = () => {
      if (!canvas) {
        return reject()
      }
      canvas.width = img.width * devicePixelRatio
      canvas.height = img.height * devicePixelRatio

      ctx.drawImage(img, 0, 0)
      resolve(canvas.toDataURL())
      canvas = null
    }
    img.onerror = () => reject('error')
  })
}

export const base64ToBlob = (base64: string): Promise<Blob> =>
  new Promise((resolve) => {
    const raw = window.atob(base64)
    const rawLength = raw.length
    const array = new Uint8Array(new ArrayBuffer(rawLength))

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i)
    }

    return resolve(new Blob([array.buffer], { type: 'application/pdf' }))
  })

export const svgStringToBase64 = (svgString: string): Promise<string> => {
  //can't update unescape becuase effect old svg graph
  const url = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgString)))
  const img = new Image()

  return new Promise((resolve, reject) => {
    let canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    canvas.style.width = '100%'
    canvas.style.height = '100%'

    img.src = url
    img.onload = () => {
      if (!canvas) {
        return reject()
      }
      const scale = window.devicePixelRatio
      canvas.width = img.width * scale
      canvas.height = img.height * scale
      ctx.scale(scale, scale)

      ctx.drawImage(img, 0, 0)
      resolve(canvas.toDataURL())
      canvas = null
    }
    img.onerror = (e) => reject(`error: ${e}`)
  })
}
