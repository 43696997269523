// @flow
import ProductBenefitsSummary from './product-benefits-summary'
import type { ProductBenefitProps } from 'quick-quote/product-common/benefit-illustration/components/product-benefits/types'

import GraphR08F from 'assets/images/product-benefits/Graph-R08F.svg'
import GraphR60F from 'assets/images/product-benefits/Graph-R60F.svg'

const renderGraph = (basicPlanCode: string) => {
  const className = `graph-${basicPlanCode}`

  return (
    <div className={className}>
      {(() => {
        switch (basicPlanCode) {
          case 'R08F':
            return <GraphR08F />
          case 'R60F':
            return <GraphR60F />
        }
      })()}
    </div>
  )
}

const ProductBenefits = ({
  displayProduct,
  sumAssured,
  basicPremium,
  calculatedPaymentPeriod,
  calculatedCoveragePeriod,
  policyValue,
  benefitSummaryHeader,
  showGraph = true,
}: ProductBenefitProps) => {
  return (
    <div id="product-benefits" className="benefit group">
      <ProductBenefitsSummary
        displayProduct={displayProduct}
        sumAssured={sumAssured}
        basicPremium={basicPremium}
        calculatedPaymentPeriod={calculatedPaymentPeriod}
        calculatedCoveragePeriod={calculatedCoveragePeriod}
        policyValue={policyValue}
        benefitSummaryHeader={benefitSummaryHeader}
      />
      {showGraph && displayProduct ? renderGraph(displayProduct.basicPlanCode) : ''}
    </div>
  )
}

export default ProductBenefits
