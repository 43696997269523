import styled from 'styled-components'
import { Flex, Box, Button } from 'rebass'
import Card from 'common-components/card'

const Side = styled(Flex).attrs({
  direction: 'column',
  align: 'center',
  justify: 'center',
  flex: ['0 0 15%'],
})`
  min-height: 80px;
  min-width: 95px;
`

const DisabledPhotoCard = styled(({ className, children, title, icon, ...props }) => (
  <Card className={className} w={1} column {...props} disabled>
    <Flex className="card-info-container">
      <Flex align="flex-start">
        <Box flex="auto">
          <div className="title">{title}</div>
          <small>{children}</small>
        </Box>
      </Flex>
      <Side align="flex-end">
        <Button icon="attach">แนบเอกสาร</Button>
      </Side>
    </Flex>
  </Card>
))`
  border-color: #ffffff;
  padding: 13px 12px;
  &:hover {
    cursor: pointer;
  }
  .title {
    color: #e5e5e5;
  }
  button {
    margin: -12px;
    font-size: 0.825rem;
    color: white;
    background: #e5e5e5;
  }
`

export default (hoc) => hoc(DisabledPhotoCard)
