//@flow
import { scroller } from 'react-scroll'
import _ from 'lodash'
import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import Reset from '!svg-react-loader!assets/images/reset_icon.svg'
import MESSAGES from 'core/data-model/constants/messages'

export class FundAllocationBar extends React.Component<*, *> {
  static propTypes = {
    selectedFunds: PropTypes.array.isRequired,
    totalAllocation: PropTypes.object.isRequired,
    resetFundAllocations: PropTypes.func.isRequired,
    stickedToElementId: PropTypes.string,
    offsetFromTop: PropTypes.number,
  }

  static defaultProps = {
    //Header height
    offsetFromTop: 123,
  }

  constructor(props) {
    super(props)
    this.refsBar = null
    this.state = {
      isSticky: false,
      barPosition: 0,
      calculated: false,
    }
  }

  topEdge = () => {
    return this.props.offsetFromTop + window.pageYOffset
  }

  currentBarPosition = () => {
    if (this.refsBar) {
      return ReactDOM.findDOMNode(this.refsBar).offsetTop
    }
  }

  barHeight = () => {
    if (this.refsBar) {
      return ReactDOM.findDOMNode(this.refsBar).offsetHeight
    }
  }

  barPositionChanged = () => {
    return !this.state.isSticky && this.currentBarPosition() !== this.state.barPosition
  }

  handleScroll = () => {
    if (this.refsBar) {
      if (!this.state.calculated) {
        this.setState({
          calculated: true,
          barPosition: this.currentBarPosition(),
        })
      } else {
        this.setState({
          isSticky: this.topEdge() >= this.state.barPosition,
        })
      }
      if (this.barPositionChanged()) {
        this.setState({
          calculated: false,
        })
      }
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', () => this.handleScroll())
  }

  render() {
    const { isSticky } = this.state
    const { selectedFunds, totalAllocation, resetFundAllocations } = this.props
    const remainingAllocation = 100 - totalAllocation.value
    const sortedFunds = _.sortBy(
      selectedFunds.filter((f) => f.fundAllocation !== 0),
      ['fundAllocation', 'code']
    )
    if (_.isEmpty(selectedFunds) || !_.isEmpty(totalAllocation.errors)) {
      return null
    }
    return (
      <div>
        <div
          className={`allocation-bar ${isSticky ? 'sticky' : ''}`}
          ref={(el) => (this.refsBar = el)}
          style={{ top: isSticky ? '123px' : 0 }}
        >
          <div className={'wrapper-content'}>
            <div className="text-block-investment-value-ration">
              <span>{MESSAGES.FUNDS_ALLOCATION.YOUR_INVESTMENT_VALUE_RATIO_MESSAGE}</span>
            </div>
            <div className="bar">
              {sortedFunds.map(({ code, riskLevel, fundAllocation }) => (
                <div
                  key={`part-${code}`}
                  className={`part level${riskLevel} assigned`}
                  href={`${code}-fund`}
                  onClick={(e) => {
                    e.preventDefault()
                    scroller.scrollTo(e.target.href)
                  }}
                  style={{
                    width: `${fundAllocation}%`,
                  }}
                >
                  {code} {fundAllocation}%
                </div>
              ))}
              {!!remainingAllocation && (
                <div
                  className="part unassigned"
                  style={{
                    width: `${remainingAllocation}%`,
                  }}
                >
                  {remainingAllocation}%
                </div>
              )}
            </div>
            <div className="reset">
              <a onClick={resetFundAllocations}>
                <Reset />
                <span>{MESSAGES.FUNDS_ALLOCATION['RESET']}</span>
              </a>
            </div>
          </div>
        </div>
        <div style={{ paddingTop: isSticky ? this.barHeight() : 0 }} />
      </div>
    )
  }
}
