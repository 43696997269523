// @flow
import _ from 'lodash'
import values from 'core/data-model/constants/values'
import VALUES from 'core/data-model/constants/values'
import defaults from 'core/data-model/constants/defaults'

import wp from './rider-wp'
import ap from './rider-ap'
import cpr from './rider-cpr'
import meaPlus from './rider-meaplus'
import ecare from './rider-ecare'
import dci from './rider-dci'
import plr from './rider-plr'
import hic from './rider-hic'
import meb from './rider-meb'
import wpd from './rider-wpd'
import wpdd from './rider-wpdd'
import esci from './rider-esci'
import wpeip85 from './rider-wpeip85'
import ciudr from './rider-ciudr'
import tpdudr from './rider-tpdudr'
import hbudr from './rider-hbudr'
import hsudr from './rider-hsudr'
import mex from './rider-mex'
import mhp from './rider-mhp'
import wptpd from './rider-wptpd'
import wptpdci from './rider-wptpdci'
import pbpdd from './rider-pbpdd'
import pbpddci from './rider-pbpddci'
import pbsdd from './rider-pbsdd'
import pbsddci from './rider-pbsddci'
import mrtaap from './rider-mrtaap'
import parider from './rider-parider'

import { get, flow, keyBy, filter, includes, flatten, eq, find } from 'lodash/fp'
import type { DisplayProductQuery } from 'core/service'
import { getBasicPlan } from 'core/service'
import { getRiskiestOccupationFactorForRiders } from 'core/service/insured'
import { checkRules } from 'core/service/rider/rules/rider-rules'
import { isCompatibleWithSelectedRiders, checkCompatibilities } from 'core/service/rider/rider-compatibilities'
import { getToggles } from 'quick-quote/feature-toggles'
import riderValidationMessages from 'core/data-model/constants/validation-messages'
import type { BasicPlan, Period } from 'core/data-model/basic-plan'
import type { Insured, Age } from 'core/data-model/insured'
import type { AgentType } from 'core/data-model/identity/types'
import type { ValidationMessage } from 'core/data-model/validation'
import type {
  RiderState,
  RiderEligibilities,
  RiderPlan,
  AllRiderPremiumCalculationDependencies,
  Rider,
  RiderCode,
  RiderPremiumRateQueryParams,
} from 'core/data-model/rider'
import type { State as LoanState } from 'quick-quote/product-loan/redux'

const riders = {
  HIC: hic,
  ECARE: ecare,
  WP: wp,
  AP: ap,
  MEAPlus: meaPlus,
  CPR: cpr,
  DCI: dci,
  PLR: plr,
  PLS: plr,
  MEB: meb,
  WPD: wpd,
  WPDD: wpdd,
  ESCI: esci,
  WPEIP85: wpeip85,
  CIUDR: ciudr,
  TPDUDR: tpdudr,
  HBUDR: hbudr,
  HSUDR: hsudr,
  MEX: mex,
  MHP: mhp,
  WPTPD: wptpd,
  WPTPDCI: wptpdci,
  PBPDD: pbpdd,
  PBPDDCI: pbpddci,
  PBSDD: pbsdd,
  PBSDDCI: pbsddci,
  MRTAAP: mrtaap,
  PARIDER: parider,
}

const isImplementedRider = (rider): boolean => _.has(riders, rider.code)

export const getYearsOfCoverage = (
  rider: Rider,
  insuredAge: Age,
  payerAge: Age,
  basicPaymentPeriod: Period,
  basicPlanCoveragePeriod: Period,
  selectedPlan: RiderPlan
): number => {
  if (!isImplementedRider(rider)) return 0

  const riderCoveragePeriod = rider.coveragePeriod ? rider.coveragePeriod.value : 0
  switch (rider.code) {
    case 'PBG':
      return payerAge.value === defaults.RIDER_PBG_MAX_PAYER_AGE
        ? defaults.RIDER_PBG_COVERAGE_MIN
        : defaults.RIDER_PBG_COVERAGE_MAX
    case 'WPD':
      return riders.WPD.getYearsOfCoverage(basicPaymentPeriod.value, insuredAge)
    case 'WPDD':
      return riders.WPDD.getYearsOfCoverage(basicPaymentPeriod.value, insuredAge)
    case 'PLS':
      return plr.getYearsOfCoverage(selectedPlan)
    case 'PLR':
      return plr.getYearsOfCoverage(selectedPlan)
    case 'WP':
      return riders[rider.code].getYearsOfCoverage(
        riderCoveragePeriod,
        insuredAge,
        basicPaymentPeriod.value,
        basicPlanCoveragePeriod.value
      )
    case 'WPTPD':
    case 'WPTPDCI':
      return riders[rider.code].getYearsOfCoverage(
        riderCoveragePeriod,
        insuredAge,
        basicPaymentPeriod,
        basicPlanCoveragePeriod.value
      )
    case 'PBPDD':
    case 'PBPDDCI':
      return riders[rider.code].getYearsOfCoverage(basicPaymentPeriod, insuredAge, payerAge)
    case 'PBSDD':
    case 'PBSDDCI':
      return riders[rider.code].getYearsOfCoverage(basicPaymentPeriod, insuredAge, payerAge)
    case 'MEH':
      return riders.MEH.getYearsOfCoverage(basicPlanCoveragePeriod, insuredAge.value)
    case 'FH':
      return riders.FH.getYearsOfCoverage(basicPlanCoveragePeriod, insuredAge.value)
    case 'MRTAAP':
      return mrtaap.getYearsOfCoverage(selectedPlan)
    default:
      const ageValue = insuredAge.unit !== 'year' ? 0 : insuredAge.value
      const yearsOfCoverage = riderCoveragePeriod - ageValue
      return yearsOfCoverage > basicPlanCoveragePeriod.value ? basicPlanCoveragePeriod.value : yearsOfCoverage
  }
}

export const getYearsOfPayment = (
  rider: Rider,
  insuredAge: Age,
  payerAge: Age,
  basicPaymentPeriod: Period,
  basicPlanCoveragePeriod: Period,
  selectedPlan: RiderPlan
): number => {
  if (!isImplementedRider(rider)) return 0

  switch (rider.code) {
    case 'PBG':
      return payerAge.value === defaults.RIDER_PBG_MAX_PAYER_AGE
        ? defaults.RIDER_PBG_COVERAGE_MIN
        : defaults.RIDER_PBG_COVERAGE_MAX
    case 'MEH':
      return riders.MEH.getYearsOfPayment(basicPaymentPeriod, insuredAge.value)
    case 'FH':
      return riders.FH.getYearsOfPayment(basicPaymentPeriod, insuredAge.value)
    default:
      if (_.includes(defaults.PROXY_RIDERS, rider.code)) {
        return _.get(basicPaymentPeriod, 'value')
      } else {
        return getYearsOfCoverage(
          rider,
          insuredAge,
          payerAge,
          basicPaymentPeriod,
          basicPlanCoveragePeriod,
          selectedPlan
        )
      }
  }
}

export const isRequirePayerInfo = (riderCode: RiderCode): boolean =>
  includes(riderCode)(defaults.RIDERS_REQUIRE_PAYER_INFO)

export const isValidPayerRelation = (rider: Rider, payerRelation: string): boolean => {
  return (
    payerRelation === '' ||
    _.isUndefined(payerRelation) ||
    _.isUndefined(rider.payerRelation) ||
    rider.payerRelation.length === 0 ||
    rider.payerRelation.includes(payerRelation)
  )
}

export const isRiderSelectable = (rider: Rider, riderEligibilities: RiderEligibilities): boolean => {
  return (
    checkRules(rider, riderEligibilities, values.ELIGIBILITY).isOk &&
    isValidPayerRelation(rider, riderEligibilities.payerRelation)
  )
}

export const isSelectedPlanShift = (rider: Rider, insuredAge?: Age, riderSelectedPlan?: RiderPlan): boolean => {
  const maxJuvenileAge = get('juvenilePolicy.maxAge')(rider)
  if (rider.packageRidersGroup !== undefined && riderSelectedPlan && insuredAge) {
    if (insuredAge.value <= maxJuvenileAge && !_.find(rider.packageRidersGroup, { plan: riderSelectedPlan.plan })) {
      return true
    } else if (insuredAge.value > maxJuvenileAge && !_.find(rider.packageRiders, { plan: riderSelectedPlan.plan })) {
      return true
    }
  }
  return false
}

export const getOptionValueFromList = (rider: Rider, insuredAge?: Age): RiderPlan => {
  const maxJuvenileAge = get('juvenilePolicy.maxAge')(rider)
  if (insuredAge && insuredAge.value <= maxJuvenileAge) {
    const defaultPackage = flow(get('options.0.values'), find({ view: 'juvenile' }))(rider)
    const selectedplanGroups = _.get(rider, 'packageRidersGroup', [])
      .find((p) => p.plan === defaultPackage.plan)
      .planGroups.find((p) => p.isSelected)

    const selectedModules = _.get(selectedplanGroups, 'modules', []).map((m) => ({
      code: m.code[defaultPackage.areaOfCover],
      description: m.description,
      isSelected: true,
    }))
    return { ...defaultPackage, modules: selectedModules }
  } else return flow(get('options.0.values'), find({ view: 'adult' }))(rider)
}
export const getSelectedRiderPlan = (
  rider: Rider,
  riderSelectedPlan?: RiderPlan,
  basicPlan?: BasicPlan,
  basicSumAssured?: number,
  userAgentType: AgentType = defaults.DEFAULT_AGENT_TYPE,
  occupationFactor?: number,
  payerOccupationFactor?: number,
  insuredAge?: Age
): RiderPlan => {
  if (!isImplementedRider(rider)) return { planCode: '' }
  const riderOptionType = get('options.0.type')(rider)
  const riderOptionSource = get('options.0.source')(rider)

  const isPlanShift = isSelectedPlanShift(rider, insuredAge, riderSelectedPlan)

  if (
    riderSelectedPlan &&
    riderSelectedPlan.planCode !== '' &&
    riderOptionType !== values.OPTION_FIXED_VALUE_FROM_LIST &&
    !isPlanShift
  ) {
    return riderSelectedPlan
  }

  if (rider.package && rider.packageRiders) {
    return { planCode: get('packageRiders.0')(rider) || '' }
  }

  switch (riderOptionType) {
    case values.OPTION_VALUE_FROM_LIST:
      return get('options.0.values.0')(rider)
    case values.OPTION_MULTI_VALUE_FROM_LIST:
      return getOptionValueFromList(rider, insuredAge)
    case values.OPTION_FIXED_VALUE_FROM_LIST:
      if (riderOptionSource === values.SOURCE_PRODUCT_PLAN) {
        return getSelectedRiderPlanByProductPlan(rider, basicPlan, basicSumAssured, userAgentType)
      } else if (
        riderOptionSource === values.SOURCE_TOTAL_PREMIUM ||
        riderOptionSource === values.SOURCE_TOTAL_ANNUAL_PREMIUM
      ) {
        return getSelectedRiderPlanByPayerOccupationFactor(
          rider,
          basicPlan,
          basicSumAssured,
          occupationFactor,
          payerOccupationFactor
        )
      } else {
        return { planCode: '' }
      }
    case values.OPTION_FIXED_VALUE:
    case values.OPTION_ANY_VALUE:
    case values.OPTION_FIXED_VALUE_HIDE_SUMASSURED:
    default:
      return { planCode: '' }
  }
}

export const getSelectedRiderPlanByPayerOccupationFactor = (
  rider: Rider,
  basicPlan?: BasicPlan,
  basicSumAssured?: number,
  occupationFactor?: number,
  payerOccupationFactor?: number
): RiderPlan => {
  const riderOptionValues = get('options.0.values')(rider)
  const riderPlanCode =
    flow(
      filter((v) => v.source === values.SOURCE_RIDER_PAYER_OCCUPATIONFACTOR),
      keyBy('value'),
      get(payerOccupationFactor),
      get('planCode')
    )(riderOptionValues) || get('0.planCode')(riderOptionValues)
  return { planCode: riderPlanCode }
}

export const getSelectedRiderPlanByProductPlan = (
  rider: Rider,
  basicPlan?: BasicPlan,
  basicSumAssured?: number,
  userAgentType?: AgentType
): RiderPlan => {
  const riderOptionValues = get('options.0.values')(rider)
  // $FlowFixMe
  const riderSumAssured = getRiderSumAssuredByProductPlan(rider, basicSumAssured, basicPlan, userAgentType)
  const riderPlanCode =
    flow(
      filter((v) => v.source === values.SOURCE_RIDER_SUM_ASSURED),
      keyBy('value'),
      get(riderSumAssured),
      get('planCode')
    )(riderOptionValues) || ''
  return { planCode: riderPlanCode }
}

export const calculateMaximumPayerAge = (
  riderCode: RiderCode,
  insuredAge: Age,
  yearsOfPayment: number,
  coveragePeriod: number
): Age => {
  if (riderCode === 'WPD' || riderCode === 'WPDD')
    return riders[riderCode].calculateMaximumPayerAge(yearsOfPayment, coveragePeriod)
  else if (VALUES.RIDER_PB.includes(riderCode)) return riders[riderCode].calculateMaximumPayerAge()

  return { value: 0, unit: 'year' }
}

export const getRiderSumAssured = (
  rider: Rider & RiderState,
  basicSumAssured: number,
  basicPremium: number,
  basicPlan?: BasicPlan,
  userAgentType: AgentType = defaults.DEFAULT_AGENT_TYPE,
  totalPremium: number = 0,
  sumAssuredContract?: number
): number => {
  if (!isImplementedRider(rider)) return 0

  const riderOptionType = get('options.0.type')(rider)
  const riderOptionSource = get('options.0.source')(rider)
  const riderOptionValue = get('options.0.values')(rider)
  switch (riderOptionType) {
    case values.OPTION_FIXED_VALUE:
    case values.OPTION_FIXED_VALUE_FROM_LIST:
    case values.OPTION_FIXED_VALUE_HIDE_SUMASSURED:
      if (riderOptionSource === values.SOURCE_BASIC_PREMIUM) {
        return basicPremium
      } else if (riderOptionSource === values.SOURCE_PRODUCT_PLAN) {
        return getRiderSumAssuredByProductPlan(rider, basicSumAssured, basicPlan, userAgentType)
      } else if (riderOptionSource === values.SOURCE_TOTAL_PREMIUM) {
        return totalPremium
      } else if (riderOptionSource === values.SOURCE_TOTAL_ANNUAL_PREMIUM) {
        return totalPremium
      } else if (riderOptionSource === values.SOURCE_BASIC_SUM_ASSURED) {
        return basicSumAssured
      } else if (riderOptionSource === values.SOURCE_PERCENT_BASIC_SUM_ASSURED) {
        let calculatedBasicSumAssured = basicSumAssured
        if (basicPlan !== undefined && basicPlan.category !== undefined && basicPlan.category === values.MRTA) {
          calculatedBasicSumAssured = sumAssuredContract || 0
        }
        return parseInt(calculatedBasicSumAssured * (riderOptionValue / 100))
      } else if (riderOptionSource === values.SOURCE_TOTAL_BASIC_ANNUAL_PREMIUM_WITH_LIMITED_RANGE) {
        return rider.sumAssured
      } else {
        return Math.min(basicSumAssured, rider.defaultSumAssured)
      }
    default:
      return rider.sumAssured ? rider.sumAssured : rider.defaultSumAssured
  }
}

export const getRiderSumAssuredByProductPlan = (
  rider: Rider & RiderState,
  basicSumAssured: number,
  basicPlan?: BasicPlan,
  userAgentType?: AgentType
): number => {
  const productPlanSource = get('productPlans.source')(basicPlan)
  const productPlans = get('productPlans.values')(basicPlan)

  switch (productPlanSource) {
    case values.SOURCE_BASIC_SUM_ASSURED:
      const sumAssured = flow(
        flatten,
        find((plan) => {
          return (
            flow(get('plan'), eq(basicSumAssured))(plan) && flow(get('allowUserType'), includes(userAgentType))(plan)
          )
        }),
        get('ridersOverrides'),
        get(rider.code),
        get('sumAssured')
      )(productPlans)

      return sumAssured ? sumAssured : rider.defaultSumAssured
    default:
      return rider.sumAssured ? rider.sumAssured : rider.defaultSumAssured
  }
}

export const calculateRiderPremium = (rider: Rider, dependencies: AllRiderPremiumCalculationDependencies): number => {
  if (!isImplementedRider(rider)) return 0
  return riders[rider.code].calculateRiderPremium(dependencies)
}

export const queryPremiumRate = (
  rider: RiderState,
  riderPremiumRates: Object,
  queryDependencies: RiderPremiumRateQueryParams
): number => {
  if (!isImplementedRider(rider)) return 0
  return riders[rider.code].queryPremiumRate(riderPremiumRates, queryDependencies)
}

export const queryTaxRate = (
  rider: RiderState,
  riderTaxRates: Object,
  queryDependencies: RiderPremiumRateQueryParams
): number => {
  if (typeof riders[rider.code].queryTaxRate === 'undefined')
    return {
      retire: 0,
      life: 0,
      health: 0,
    }
  return riders[rider.code].queryTaxRate(riderTaxRates, queryDependencies)
}

export const getValidationMessages = (
  rider: Rider & RiderState,
  riderSumAssured: number,
  riderEligibilities: RiderEligibilities,
  selectedRiders: (Rider & RiderState)[]
): ValidationMessage[] => {
  let validationMessages = checkRules(rider, riderEligibilities).messages
  let compatibilityMessages = checkCompatibilities(rider, selectedRiders).messages
  let invalidPayerMessages = isValidPayerRelation(rider, riderEligibilities.payerRelation)
    ? []
    : [
        {
          message: riderValidationMessages.RIDER_INVALID_PAYER,
          type: 'ELIGIBILITY',
        },
      ]

  return _.union(_.uniqBy(validationMessages, 'message'), compatibilityMessages, invalidPayerMessages)
}

export const _validateRiders = (
  riders: (Rider & RiderState)[],
  insured: Insured,
  payerAge: Age,
  sumAssured: number,
  occupationFactors: { [string]: number },
  payerOccupationFactors: { [string]: number },
  selectedBasicPlan: BasicPlan,
  payerRelation: string,
  loan: LoanState,
  userAgentType: AgentType
): (Rider & RiderState)[] => {
  const getRiderEligibilities = (
    rider: Rider & RiderState,
    selectedRiders: (Rider & RiderState)[],
    payerRelation: string,
    userAgentType: AgentType
  ): RiderEligibilities => {
    //TODO should move to acculateRider
    const yearsOfPayment = getYearsOfPayment(
      rider,
      insured.age,
      payerAge,
      selectedBasicPlan.paymentPeriod,
      selectedBasicPlan.coveragePeriod,
      rider.selectedPlan
    )
    const maxPayerAge = calculateMaximumPayerAge(rider.code, insured.age, yearsOfPayment, rider.coveragePeriod.value)

    return {
      insured,
      payerAge,
      basicSumAssured: sumAssured,
      yearsOfPayment,
      riderCoveragePeriod: rider.coveragePeriod.value,
      selectedRiders,
      rider,
      maxPayerAge,
      occupationFactor: occupationFactors[rider.code],
      payerOccupationFactor: payerOccupationFactors[rider.code],
      basicPaymentPeriod: selectedBasicPlan.paymentPeriod,
      payerRelation: payerRelation,
      loan: loan,
      userAgentType: userAgentType,
    }
  }

  let selectedRiders = riders.filter(
    (rider) =>
      rider.isSelected && isRiderSelectable(rider, getRiderEligibilities(rider, [], payerRelation, userAgentType))
  )
  let selectedRiderCodes = selectedRiders.map((riderPlan) => riderPlan.code)

  //if both WP and PB will consider PB as priority
  if (
    !_.isEqual(_.intersection([VALUES.WPTPDCI, VALUES.WPTPD], selectedRiderCodes), []) &&
    !_.isEqual(_.intersection(VALUES.RIDER_PB, selectedRiderCodes), [])
  ) {
    selectedRiders = selectedRiders.filter((rider) => ![VALUES.WPTPDCI, VALUES.WPTPD].includes(rider.code))
    selectedRiderCodes = selectedRiders.map((riderPlan) => riderPlan.code)
  }

  const isSelectable = (rider: Rider, riderEligibilities: RiderEligibilities): boolean =>
    isCompatibleWithSelectedRiders(rider, selectedRiderCodes) && isRiderSelectable(rider, riderEligibilities)

  return riders.map((rider) => {
    const yearsOfCoverage = getYearsOfCoverage(
      rider,
      insured.age,
      payerAge,
      selectedBasicPlan.paymentPeriod,
      selectedBasicPlan.coveragePeriod,
      rider.selectedPlan
    )
    const riderEligibilities = getRiderEligibilities(rider, selectedRiders, payerRelation, userAgentType)
    const isRiderSelectable = isSelectable(rider, riderEligibilities)
    const errors = getValidationMessages(rider, rider.sumAssured, riderEligibilities, selectedRiders)
    return {
      ...rider,
      isSelected: rider.isSelected,
      isSelectable: isRiderSelectable,
      errors,
      yearsOfCoverage: yearsOfCoverage,
      yearsOfPayment: riderEligibilities.yearsOfPayment,
    }
  })
}

export const validateRiders = async (
  riders: (Rider & RiderState)[],
  insured: Insured,
  payerAge: Age,
  sumAssured: number,
  natureOfDutyCodes: string[] = [],
  payerNatureOfDutyCodes: string[] = [],
  displayProductQuery: DisplayProductQuery,
  payerRelation: string = '',
  loan: LoanState,
  userAgentType: AgentType = 'AGENT'
): Promise<(Rider & RiderState)[]> => {
  const occupationFactors = await getRiskiestOccupationFactorForRiders(displayProductQuery, natureOfDutyCodes, riders)
  const payerOccupationFactors = await getRiskiestOccupationFactorForRiders(
    displayProductQuery,
    payerNatureOfDutyCodes,
    riders
  )
  const basicPlan: BasicPlan = await getBasicPlan(displayProductQuery)
  return _validateRiders(
    riders,
    insured,
    payerAge,
    sumAssured,
    occupationFactors,
    payerOccupationFactors,
    basicPlan,
    payerRelation,
    loan,
    userAgentType
  )
}

export const getAccurateRiders = async (props: {
  riders: (Rider & RiderState)[],
  basicSumAssured: number,
  displayProductQuery: DisplayProductQuery,
  natureOfDutyCodes: string[],
  payerNatureOfDutyCodes: string[],
  userAgentType: AgentType,
  insured: Insured,
  payerAge: Age,
}): Promise<(Rider & RiderState)[]> => {
  const {
    riders,
    basicSumAssured,
    displayProductQuery,
    natureOfDutyCodes,
    payerNatureOfDutyCodes,
    userAgentType,
    insured,
    payerAge,
  } = props

  const occupationFactors = await getRiskiestOccupationFactorForRiders(displayProductQuery, natureOfDutyCodes, riders)
  const payerOccupationFactors = await getRiskiestOccupationFactorForRiders(
    displayProductQuery,
    payerNatureOfDutyCodes,
    riders
  )
  const basicPlan = await getBasicPlan(displayProductQuery)
  return riders.map((rider) => {
    const occupationFactor = occupationFactors[rider.code]
    const payerOccupationFactor = payerOccupationFactors[rider.code]
    const yearsOfCoverage = getToggles().ENABLE_FIXED_WPD_CALCULATION
      ? getYearsOfCoverage(
          rider,
          insured.age,
          payerAge,
          basicPlan.paymentPeriod,
          basicPlan.coveragePeriod,
          rider.selectedPlan
        )
      : rider.yearsOfCoverage
    return {
      ...rider,
      yearsOfCoverage,
      selectedPlan: getSelectedRiderPlan(
        rider,
        rider.selectedPlan,
        basicPlan,
        basicSumAssured,
        userAgentType,
        occupationFactor,
        payerOccupationFactor,
        insured.age
      ),
      riderOccupationFactor: occupationFactor.toString(),
      riderPayerOccupationFactor: payerOccupationFactor.toString(),
    }
  })
}

export const hasRidersNeedToCalculateFromTotalPremiumOrAnnualPremium = (riders: (Rider & RiderState)[]) => {
  return riders.some((rider) => isRiderNeedToCalculateFromTotalPremiumOrAnnualPremium(rider))
}

export const hasRidersNeedToCalculateFromAnnualTotalPremium = (riders: (Rider & RiderState)[]) => {
  return riders.some((rider) => isRiderNeedToCalculateFromTotalAnnualPremium(rider))
}

export const isRiderNeedToCalculateFromTotalPremiumOrAnnualPremium = (rider: Rider & RiderState) => {
  const source = get('options.0.source')(rider)
  return source === values.SOURCE_TOTAL_PREMIUM || source === values.SOURCE_TOTAL_ANNUAL_PREMIUM
}

export const isRiderNeedToCalculateFromTotalAnnualPremium = (rider: Rider & RiderState) => {
  return get('options.0.source')(rider) === values.SOURCE_TOTAL_ANNUAL_PREMIUM
}

export const isRiderBelongToRpudr = (rider: Rider & RiderState) => {
  return [values.CIUDR, values.TPDUDR, values.HBUDR, values.HSUDR].includes(rider.code)
}
