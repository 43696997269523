/* eslint-disable quotes */
import { compact, get, flow } from 'lodash/fp'
import { createSectionCreator, createField } from './utils'

const insurancePrivacyConsentField = (app, id) => {
  const shouldShowInfo = app !== undefined ? flow(get('insurancePrivacyConsent.value'))(app) : false
  const infoMessage = shouldShowInfo ? (
    'ท่านสามารถอ่านรายละเอียดเพิ่มเติมของวัตถุประสงค์สำหรับการเก็บ ใช้ และเปิดเผยข้อมูลส่วนบุคคลสำหรับการรับประกันภัยได้ในขั้นตอนการลงนาม'
  ) : (
    <div className="text-danger">
      ทั้งนี้ โปรดทราบว่า หากท่านไม่ยินยอมให้บริษัทฯ เก็บรวบรวมและใช้ข้อมูลข้างต้น การใช้สิทธิของท่านอาจส่งผลให้บริษัทฯ
      ไม่สามารถปฏิบัติตามสัญญาประกันภัยกับท่านได้ ซึ่งจะนำไปสู่การปฏิเสธใบคำขอเอาประกันภัย
    </div>
  )
  return compact([
    createField('insurancePrivacyConsent', [
      {
        id: 'insurancePrivacyConsent',
        label: 'ข้าพเจ้ายินยอมที่จะให้เก็บ ใช้ และเปิดเผยข้อมูลส่วนบุคคล',
        fullLabel:
          'ข้าพเจ้ายินยอมที่จะให้เก็บ ใช้ และเปิดเผยข้อมูลส่วนบุคคล เพื่อวัตถุประสงค์สำหรับการรับประกันภัย การดำเนินการต่างๆ ที่เกี่ยวกับกรมธรรม์ประกันภัยที่ทำกับบริษัทฯ การจ่ายเงินตามกรมธรรม์หรือผลประโยชน์ทางการแพทย์ และการประกันภัยต่อที่เกี่ยวกับกรมธรรม์ประกันภัยของท่าน',
        fullLabelToggleTexts: {
          toShow: 'รายละเอียดเพิ่มเติม',
          toHide: 'ย่อรายละเอียดเพิ่มเติม',
        },
        c: 'RadioButton',
        required: true,
        p: {
          options: [
            {
              text: 'ยินยอม',
              value: true,
            },
            {
              text: 'ไม่ยินยอม',
              value: false,
            },
          ],
        },
        infoMessage: (shouldShowInfo && infoMessage) !== undefined ? infoMessage : '',
      },
    ]),
  ])
}
const marketingPrivacyConsentField = (app, id) => {
  const shouldShowInfo = app !== undefined ? flow(get('marketingPrivacyConsent.value'))(app) : false
  const infoMessage = shouldShowInfo
    ? 'ท่านสามารถอ่านรายละเอียดเพิ่มเติมของวัตถุประสงค์สำหรับการเก็บ ใช้ และเปิดเผยข้อมูลส่วนบุคคลเพื่อแจ้งข้อมูลข่าวสาร, การเสนอผลิตภัณฑ์หรือบริการอื่นๆ รวมถึงการวิเคราะห์ และ/หรือ พัฒนาผลิตภัณฑ์ได้ในขั้นตอนการลงนาม'
    : 'ทั้งนี้ โปรดทราบว่า หากท่านไม่ยินยอมให้บริษัทฯ เก็บรวบรวมและใช้ข้อมูลข้างต้นอาจมีผลทำให้บริษัทฯ ไม่สามารถดำเนินการแจ้งข้อมูลข่าวสารเกี่ยวกับผลิตภัณฑ์หรือบริการของบริษัทฯ รวมถึงรายการส่งเสริมการขายต่างๆ ได้'
  return compact([
    createField('marketingPrivacyConsent', [
      {
        id: 'marketingPrivacyConsent',
        label: 'ข้าพเจ้ายินยอมที่จะขอรับข้อมูลข่าวสาร โปรโมชั่น หรือแคมเปญจากบริษัทฯ',
        fullLabel:
          'ข้าพเจ้ายินยอมที่จะขอรับข้อมูลข่าวสาร โปรโมชั่น หรือแคมเปญจากบริษัทฯ และยินยอมให้บริษัทฯ จัดเก็บ ใช้ หรือเปิดเผย ข้อมูลส่วนบุคคลของข้าพเจ้าไปยังบุคคลที่สาม รวมถึงพันธมิตรของบริษัททั้งในและนอกประเทศ เพื่อให้บริการที่เกี่ยวข้องกับการแจ้งข้อมูลข่าวสาร โปรโมชั่น หรือแคมเปญของบริษัทฯ<br/><br/>ข้าพเจ้ายินยอมให้ตัวแทนของบริษัทฯ ติดต่อเพื่อแจ้งข้อมูลข่าวสาร โปรโมชั่น หรือแคมเปญ ตามหลักเกณฑ์ วิธีการที่บริษัทฯ กำหนด',
        fullLabelToggleTexts: {
          toShow: 'รายละเอียดเพิ่มเติม',
          toHide: 'ย่อรายละเอียดเพิ่มเติม',
        },
        c: 'RadioButton',
        required: true,
        p: {
          options: [
            {
              text: 'ยินยอม',
              value: true,
            },
            {
              text: 'ไม่ยินยอม',
              value: false,
            },
          ],
        },
        infoMessage: shouldShowInfo !== undefined ? infoMessage : '',
      },
    ]),
  ])
}
export const InsurancePrivacyConsentSection = createSectionCreator(insurancePrivacyConsentField)
export const MarketingPrivacyConsentSection = createSectionCreator(marketingPrivacyConsentField)
