//@flow
import { compose, lifecycle, setDisplayName } from 'recompose'
import { InsuredInformationBreadcrumb } from 'quick-quote/components'
import RiskProfile from 'quick-quote/product-investment/components/risk-profile'
import { FundCategories } from 'quick-quote/product-investment/fund-allocation/components/fund-categories'
import React from 'react'
import styled from 'styled-components'
import type { FundAllocationToggleItem, FundState, TotalFundAllocation } from 'core/data-model/investment'
import type {
  CalculateTotalFundAllocation,
  ResetRppFundAllocations,
  ToggleFundPayload,
} from 'quick-quote/product-investment/fund-allocation/rpp/actions'
import type { Tab } from 'quick-quote/components/navigation-item'
import FundSummary from 'quick-quote/product-investment/fund-allocation/components/fund-summary/index'
import MESSAGES from 'core/data-model/constants/messages'

export type FundAllocationProps = {
  funds: FundState[],
  allocations: FundAllocationToggleItem[],
  totalAllocation: TotalFundAllocation,
  toggleFundAllocation: ToggleFundPayload,
  calculateTotalFundAllocation: CalculateTotalFundAllocation,
  resetFundAllocations: ResetRppFundAllocations,
  navTabList: Tab[],
  allowHigherRiskInvestment: boolean,
  allowForeignInvestment: boolean,
  isLowRiskEvaluated: boolean,
  hasValidEntryComponent: boolean,
  history: Object,
}
const RiskProfileWrapper = styled.div`
  max-width: 680px;
  background: white;
  margin: 0 auto 20px auto;
`
const FundAllocationComponent = ({
  funds,
  allocations,
  totalAllocation,
  toggleFundAllocation,
  calculateTotalFundAllocation,
  resetFundAllocations,
  allowHigherRiskInvestment,
  allowForeignInvestment,
  isLowRiskEvaluated,
  hasValidEntryComponent,
  navTabList,
  history,
  rppAcceptFundAllocation,
  isAcceptOverRisk,
  isAcceptForeign,
  rppAcceptHigherRiskInvestment,
  rppAcceptForeignInvestment,
  foreignExchangingQuestion,
  resetRppAcceptFundAllocation,
}: FundAllocationProps) => {
  const goToRPQAndRetest = () => {
    const RPQTab = navTabList.find((tab) => tab.id === 'invest')
    if (RPQTab) history.push(RPQTab.path)
  }
  return (
    <div id="fund-allocation" className="fund-allocation">
      <InsuredInformationBreadcrumb />
      <div className="content">
        {rppAcceptFundAllocation === true ? (
          <div>
            <FundSummary
              fundSummaryTitile={MESSAGES.RPP_SUMMARY_FUND_TITLE}
              allocations={allocations}
              isAcceptOverRisk={isAcceptOverRisk}
              isAcceptForeign={isAcceptForeign}
              rppAcceptHigherRiskInvestment={rppAcceptHigherRiskInvestment}
              rppAcceptForeignInvestment={rppAcceptForeignInvestment}
              resetRppAcceptFundAllocation={resetRppAcceptFundAllocation}
              foreignExchangingQuestion={foreignExchangingQuestion}
            />
          </div>
        ) : (
          <div>
            <RiskProfileWrapper>
              <RiskProfile onRetakeRPQConfirmed={goToRPQAndRetest} />
            </RiskProfileWrapper>
            <FundCategories
              allocations={allocations}
              funds={funds}
              totalAllocation={totalAllocation}
              resetFundAllocations={resetFundAllocations}
              toggleFundAllocation={toggleFundAllocation}
              calculateTotalFundAllocation={calculateTotalFundAllocation}
              allowHigherRiskInvestment={allowHigherRiskInvestment}
              allowForeignInvestment={allowForeignInvestment}
              isLowRiskEvaluated={isLowRiskEvaluated}
              hasValidEntryComponent={hasValidEntryComponent}
              elementIdToSnapAllocationBar={'fund-allocation'}
            />
          </div>
        )}
      </div>
    </div>
  )
}
export const FundAllocation = compose(
  setDisplayName('FundAllocation'),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (this.props.rppAcceptFundAllocation !== prevProps.rppAcceptFundAllocation) {
        window.scrollTo(0, 0)
      }
    },
  })
)(FundAllocationComponent)
