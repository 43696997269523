// @flow

import _ from 'lodash'
import type { RiderPremiumRateQueryParams } from 'core/data-model/rider'

const createQuery = (requiredKeys: string[], queryDependencies: RiderPremiumRateQueryParams): string[] => {
  const age = queryDependencies.age.unit !== 'year' ? '0' : queryDependencies.age.value.toString()
  const gender = queryDependencies.gender
  const occupationFactor = Number(queryDependencies.occupationFactor).toFixed(1)
  const yearsOfCoverage = queryDependencies.yearsOfCoverage.toString()
  const basicYearsOfPayment = queryDependencies.basicYearsOfPayment
    ? queryDependencies.basicYearsOfPayment.toString()
    : '0'
  const waivePeriod = queryDependencies.waivePeriod ? queryDependencies.waivePeriod.toString() : '0'
  const selectedRiderPlanCode = queryDependencies.selectedRiderPlanCode
  const selectedBasicPlanCode = queryDependencies.selectedBasicPlanCode
  const riderSumAssured = queryDependencies.riderSumAssured ? queryDependencies.riderSumAssured.toString() : '0'

  const queryParams = [
    ['basicPlanCode', selectedBasicPlanCode],
    ['riderPlanCode', selectedRiderPlanCode],
    ['yearsOfCoverage', yearsOfCoverage],
    ['basicYearsOfPayment', basicYearsOfPayment],
    ['waivePeriod', waivePeriod],
    ['occupationFactor', occupationFactor],
    ['riderSumAssured', riderSumAssured],
    ['gender', gender],
    ['age', age],
  ].filter(([key]) => requiredKeys.includes(key.toString()))
  return _.flatten(queryParams)
}

export const query = (path: string[]) => (
  riderPremiumRatesLookupTable: Object,
  queryDependencies: RiderPremiumRateQueryParams
) => _.get(riderPremiumRatesLookupTable, createQuery(path, queryDependencies), 0)

export const queryPremiumRateForMEBandMEAPlus = query(['riderPlanCode', 'occupationFactor', 'gender', 'age'])

export const queryPremiumRateForESCI = query(['riderPlanCode', 'gender', 'age'])

export const queryPremiumRateForWPEWL85 = query(['riderPlanCode', 'gender', 'age'])

export const queryPremiumRateForWPEIP85 = query(['riderPlanCode', 'gender', 'age'])

export const queryPremiumRateForAP = query(['occupationFactor'])

export const queryPremiumRateForEcare = queryPremiumRateForAP

export const queryPremiumRateForCPR = query(['gender', 'age'])

export const queryPremiumRateForWPCWL60 = query(['gender', 'age'])

export const queryPremiumRateForWPCWL99 = query(['gender', 'age'])

export const queryPremiumRateForWPC25PL = query(['gender', 'age'])

export const queryPremiumRateForCIUDR = query(['gender', 'age'])

export const queryPremiumRateForTPDUDR = query(['gender', 'age'])

export const queryPremiumRateForHBUDR = query(['riderPlanCode', 'occupationFactor', 'gender', 'age'])

export const queryPremiumRateForHSUDR = query(['riderPlanCode', 'occupationFactor', 'gender', 'age'])

export const queryPremiumRateForDCI = queryPremiumRateForCPR

export const queryPremiumRateForWP = query(['basicPlanCode', 'gender', 'age'])

export const queryPremiumRateForPLR = query(['riderPlanCode', 'gender', 'age'])

export const queryPremiumRateForHic = query(['riderPlanCode', 'gender', 'age'])

export const queryPremiumRateForWpd = query(['yearsOfCoverage', 'gender', 'age'])

export const queryPremiumRateForMEH = query(['riderPlanCode', 'occupationFactor', 'gender', 'age'])

export const queryPremiumRateForFH = query(['gender', 'age'])

export const queryPremiumRateForAPG = query(['gender', 'age'])
export const queryPremiumRateForAMG = queryPremiumRateForAPG
export const queryPremiumRateForMEBG = query(['riderPlanCode', 'gender', 'age'])
export const queryPremiumRateForMESG = queryPremiumRateForMEBG
export const queryPremiumRateForPBG = queryPremiumRateForMEBG
export const queryPremiumRateForCIG = queryPremiumRateForAPG

export const queryPremiumRateForMEK = query(['riderPlanCode', 'gender', 'age'])

export const queryPremiumRateForMEX = queryPremiumRateForMEBandMEAPlus

export const queryPremiumRateForMHP = query(['riderPlanCode', 'occupationFactor', 'gender', 'age'])

export const queryPremiumRateForWPTPD = query(['basicYearsOfPayment', 'gender', 'age'])
export const queryPremiumRateForWPTPDCI = query(['basicYearsOfPayment', 'gender', 'age'])

export const queryPremiumRateForPBPDD = query(['waivePeriod', 'gender', 'age'])
export const queryPremiumRateForPBPDDCI = queryPremiumRateForPBPDD
export const queryPremiumRateForPBSDD = queryPremiumRateForPBPDD
export const queryPremiumRateForPBSDDCI = queryPremiumRateForPBPDD
export const queryPremiumRateForMRTAAP = query(['riderPlanCode', 'gender', 'age'])
export const queryPremiumRateForPARIDER = query(['riderPlanCode', 'occupationFactor', 'age'])
