// @flow

import type { DisplayProduct } from 'core/service/display-product'

export type Action = EditPerfectLifePlan

export const EDIT_PERFECT_LIFE_PLAN = 'EDIT_PERFECT_LIFE_PLAN'
export type EditPerfectLifePlan = {
  type: 'EDIT_PERFECT_LIFE_PLAN',
  // payload: string
  payload: DisplayProduct,
}

export const editPerfectLifePlan = (displayProducts: DisplayProduct): EditPerfectLifePlan => ({
  type: EDIT_PERFECT_LIFE_PLAN,
  payload: displayProducts,
})
