// @flow
import type { Dispatch } from 'redux'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { FormGroup } from 'reactstrap'
import _ from 'lodash'

import PremiumInput from './premium-input'
import type { ModelFactor } from 'core/data-model/basic-plan'
import type { RegularPremium, RegularTopUp, SumAssured } from 'core/service/investment'

import BasicPlanErrorMessages from './basic-plan-error-messages'
import {
  editRegularPremium,
  editRegularTopUp,
  editSumAssured,
  selectModelFactor,
  previousModelFactor,
} from 'quick-quote/product-investment/coverage-plan/actions'
import {
  getAvailableModelFactors,
  getPayFirstMonthMessage,
  getRegularPremium,
  getRegularTopUp,
  getSelectedModelFactorID,
  getSelectedPaymentFrequency,
  getSumAssured,
} from 'quick-quote/product-investment/coverage-plan/selectors'
import { getSelectedDisplayProductCode, getSelectedDisplayProduct } from 'quick-quote/product-selection/selectors'

import type { AppState } from 'quick-quote/reducers'
import { RadioButtonGroup } from 'common-components'
import { formatNumber } from 'core/service/lib/number-format'
import MESSAGES from 'core/data-model/constants/messages'
import { getToggles } from 'quick-quote/feature-toggles'

type RpulBasicPlanCalculationProps = {
  selectedProductCode: string,
  modelFactors: ModelFactor[],
  sumAssured: SumAssured,
  regularPremium: RegularPremium,
  payFirstMonthMessage: string,
  regularTopUp: RegularTopUp,
  selectedModelFactorID: string,
  selectedPaymentFrequency: string,
  selectedDisplayProduct: *,
  editSumAssured: (number) => void,
  editRegularPremium: (number) => void,
  editRegularTopUp: (number) => void,
  selectModelFactor?: (string) => void,
  previousModelFactor?: (string) => void,
}

export const RpulBasicPlanCalculation = ({
  modelFactors,
  sumAssured,
  regularPremium,
  payFirstMonthMessage,
  regularTopUp,
  selectedModelFactorID,
  selectedPaymentFrequency,
  selectedDisplayProduct,
  editSumAssured,
  editRegularPremium,
  editRegularTopUp,
  selectModelFactor,
  previousModelFactor,
}: RpulBasicPlanCalculationProps) => {
  const totalPremium = regularPremium.value + regularTopUp.value
  const paymentRange = _.get(
    selectedDisplayProduct,
    ['paymentRanges', 'regularPremium', _.camelCase(selectedPaymentFrequency)],
    {
      min: 0,
    }
  )
  const checkDisableSumAssured = getToggles().ENEBLE_IWEALTHY_OPTIMISE && regularPremium.value > paymentRange.min

  const updateModelFactorId = (id) => {
    previousModelFactor(selectedModelFactorID)
    selectModelFactor(id)
  }

  return (
    <div id="basic-plan" className="investment">
      <div className="content">
        <div className="title-bottom-padding">{MESSAGES.PREMIUM_PROPORTION}</div>

        <FormGroup>
          <label> {MESSAGES.PAYMENT_MODE} </label>
          <RadioButtonGroup
            keyToGetValue="id"
            className="model-factor"
            onChange={updateModelFactorId}
            value={selectedModelFactorID}
            options={modelFactors}
          />
        </FormGroup>

        <div className="title-center">
          {' '}
          <label> {MESSAGES.GROSS_PREMIUM} </label>{' '}
        </div>
        <div className="title-center-bold">
          {' '}
          <label>
            {' '}
            {formatNumber(totalPremium)} {MESSAGES.BAHT}{' '}
          </label>{' '}
        </div>
        <div className="title-center">
          {' '}
          <label> {payFirstMonthMessage} </label>{' '}
        </div>

        <FormGroup className="coverage-plan-calculation item basic">
          <div>
            <div className="sum-assured-input">
              <div className="label">
                <label>{MESSAGES.COVERAGE_LABEL}</label>
                <label>{MESSAGES.COVERAGE_FUND_LABEL}</label>
              </div>

              <div className="options">
                <div className="sum-assured">
                  <PremiumInput
                    id="coverage-regular-premium"
                    premium={regularPremium}
                    editPremiumAmount={editRegularPremium}
                  />
                </div>
                <div className="link">&nbsp;</div>

                <div className="sum-assured">
                  <PremiumInput
                    id="investing-regular-top"
                    premium={regularTopUp}
                    editPremiumAmount={editRegularTopUp}
                  />
                </div>
              </div>
              <FormGroup className="has-danger">
                <BasicPlanErrorMessages premiumValue={regularPremium} />
                <BasicPlanErrorMessages premiumValue={regularTopUp} />
              </FormGroup>
            </div>
          </div>

          <label>{MESSAGES.COVERAGE_SUM_ASSURED}</label>
          <PremiumInput
            id="life-coverage"
            premium={sumAssured}
            editPremiumAmount={editSumAssured}
            disabled={checkDisableSumAssured}
          />
          {!checkDisableSumAssured && (
            <FormGroup className="has-danger">
              <BasicPlanErrorMessages premiumValue={sumAssured} />
            </FormGroup>
          )}
        </FormGroup>
      </div>
    </div>
  )
}

const mapStateToProps = (state: AppState): * => {
  return {
    selectedProductCode: getSelectedDisplayProductCode(state),
    modelFactors: getAvailableModelFactors(state),
    sumAssured: getSumAssured(state),
    regularPremium: getRegularPremium(state),
    payFirstMonthMessage: getPayFirstMonthMessage(state),
    regularTopUp: getRegularTopUp(state),
    selectedModelFactorID: getSelectedModelFactorID(state),
    selectedPaymentFrequency: getSelectedPaymentFrequency(state),
    selectedDisplayProduct: getSelectedDisplayProduct(state),
  }
}

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  selectModelFactor: bindActionCreators(selectModelFactor, dispatch),
  previousModelFactor: bindActionCreators(previousModelFactor, dispatch),
  editSumAssured: bindActionCreators(editSumAssured, dispatch),
  editRegularPremium: bindActionCreators(editRegularPremium, dispatch),
  editRegularTopUp: bindActionCreators(editRegularTopUp, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(RpulBasicPlanCalculation)
