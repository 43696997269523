// @flow
import type { AppState } from 'quick-quote/reducers'
import type { TotalPremium$ } from 'core/data-model/basic-plan'
import { connect } from 'react-redux'
import { FormGroup, Input } from 'reactstrap'
import type { Dispatch } from 'redux'
import { bindActionCreators } from 'redux'
import { getToggles } from 'quick-quote/feature-toggles'

import NumberFormat from 'react-number-format'

import { editTotalPremium } from 'quick-quote/product-common/coverage-plan/actions'

import { getTotalPremium$, getAvailableRiderCodes } from 'quick-quote/product-common/coverage-plan/selectors'
import { getSelectedDisplayProduct } from 'quick-quote/product-selection/selectors'

import { renderErrorMessage } from 'common-components/view-logic'
import _ from 'lodash'
import MESSAGES from 'core/data-model/constants/messages'
import VALUES from 'core/data-model/constants/values'

const TotalPremiumMessage = ({ totalPremium$ }) => {
  return totalPremium$.isOk ? null : renderErrorMessage('total-premium-error-message')(totalPremium$)
}

type Props = {
  totalPremium$: TotalPremium$,
  riderCodes: [string],
  editTotalPremium: (number) => void,
}

export const TotalPremiumInput = ({ totalPremium$, riderCodes, displayProduct }: Props) => {
  const formGroupStateClass = totalPremium$.isOk ? '' : 'has-danger'

  const labelTotalPremium = (riderCodes, displayProduct) => {
    if (displayProduct && displayProduct.category === VALUES.PA) {
      return MESSAGES.PREMIUM_INPUT_LABEL
    } else if (_.isEmpty(riderCodes)) {
      return MESSAGES.TOTAL_PREMIUM_INPUT_LABEL_WITHOUT_RIDER
    } else {
      return MESSAGES.TOTAL_PREMIUM_INPUT_LABEL
    }
  }

  return (
    <FormGroup className={`item ${formGroupStateClass}`}>
      <div className="form-group-title">
        <span className="form-group-title-text">{labelTotalPremium(riderCodes, displayProduct)}</span>
      </div>
      <NumberFormat
        id="total-premium-input"
        customInput={Input}
        thousandSeparator={true}
        decimalPrecision={2}
        allowNegative={false}
        value={totalPremium$.value}
        readOnly={!getToggles().EDIT_TOTAL_PREMIUM}
      />
      <TotalPremiumMessage totalPremium$={totalPremium$} />
    </FormGroup>
  )
}

const mapStateToProps = (state: AppState) => ({
  totalPremium$: getTotalPremium$(state),
  riderCodes: getAvailableRiderCodes(state),
  displayProduct: getSelectedDisplayProduct(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  editTotalPremium: bindActionCreators(editTotalPremium, dispatch),
})
export default connect(mapStateToProps, mapDispatchToProps)(TotalPremiumInput)
