//@flow
import type { SetNotificationMessage, ClearNotificationMessage, Notification } from 'quick-quote/notification/actions'
import { SET_NOTIFICATION_MESSAGE, CLEAR_NOTIFICATION_MESSAGE } from 'quick-quote/notification/actions'

const initialNotificationMessage = { type: 'None' }

const PRIORITY = {
  AuthenticationError: 99,
  None: 0,
  ErrorMessage: 0,
  ErrorIncompleteFNA: 0,
  GenericError: 0,
  Warning: 0,
  Information: 0,
  PasswordInformation: 0,
  RPQPasswordInformation: 0,
  RAPasswordInformation: 0,
  RemoteSelling: 0,
  RemoteSellingVc: 0,
  ConfirmToHome: 0,
  ConfirmToFNA: 0,
  CordovaUpdateFailed: 0,
  SET_NOTIFICATION_MESSAGE: 0,
  ET_NOTIFICATION_MESSAGE: 0,
  CLEAR_NOTIFICATION_MESSAGE: 0,
  LEAR_NOTIFICATION_MESSAGE: 0,
}

const DEFAULT_PRIORITY = 0

export const reducer = (
  message: Notification = initialNotificationMessage,
  action: SetNotificationMessage | ClearNotificationMessage
): Notification => {
  switch (action.type) {
    case SET_NOTIFICATION_MESSAGE: {
      const currentPriority = PRIORITY[message.type] || DEFAULT_PRIORITY
      const newPriority = PRIORITY[action.payload.type] || DEFAULT_PRIORITY
      const shouldOverrideNotification = newPriority >= currentPriority
      return shouldOverrideNotification ? action.payload : message
    }
    case CLEAR_NOTIFICATION_MESSAGE:
      return initialNotificationMessage
    default:
      return message
  }
}
