import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Tab, WordWraps } from 'common-components'

const propTypes = {
  categoryList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      shortDescription: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  active: PropTypes.string,
  onChange: PropTypes.func,
  availableCategories: PropTypes.arrayOf(PropTypes.string),
}
const defaultProps = {
  categoryList: [],
  active: null,
  onChange() {},
  availableCategories: [],
}

const isTabDisabled = (availableCategories, id) =>
  availableCategories && availableCategories.length && !availableCategories.find((c) => c === id)

const ProductCategorySelection = (props) => {
  const { categoryList, active, onChange, availableCategories } = props
  const selectedCategoryTitle = (categoryList, active) => {
    const selected = categoryList.find((categoryItem) => categoryItem.id === active)
    return _.get(selected, 'shortDescription', '')
  }

  return (
    <div className="az-product-category">
      <div className="az-product-category__title">
        <span className="az-product-category__title-text">เลือกผลิตภัณฑ์ที่ใช่สำหรับคุณ</span>
      </div>
      <div className="az-product-category__selected-category">
        <span className="az-product-category__selected-category-text">
          {selectedCategoryTitle(categoryList, active)}
        </span>
      </div>
      <div className="az-product-category__tab">
        <Tab active={active} onChange={(id) => onChange(id)}>
          {categoryList.length
            ? categoryList.map((categoryItem) => (
                <Tab.Item
                  id={categoryItem.id}
                  key={categoryItem.id}
                  disabled={isTabDisabled(availableCategories, categoryItem.id)}
                >
                  <span className="title-icon" />
                  <span className="title-text">
                    <WordWraps>{categoryItem.description}</WordWraps>
                  </span>
                </Tab.Item>
              ))
            : null}
        </Tab>
      </div>
    </div>
  )
}

ProductCategorySelection.displayName = 'ProductCategoryTab'
ProductCategorySelection.propTypes = propTypes
ProductCategorySelection.defaultProps = defaultProps

export default ProductCategorySelection
