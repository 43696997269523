// @flow

import type { AppState } from 'quick-quote/reducers'
import { getSelectedDisplayProductCode } from 'quick-quote/product-selection/selectors'

import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { compose, branch, renderComponent } from 'recompose'

import {
  getMarketConductInsuranceQuestionWithChoice,
  getMarketConductRiskQuestionWithChoice,
  isILPProduct,
  isSelectedSavingAndInvestmentNeed,
  isSelectedThirdPartyJoin,
  isSelectedNeverSplittingPremiumFromExpense,
  getReasonForNeverSplittingPremiumFromExpense,
  isNonILPNeedWithInvestmentProduct,
} from './selectors'
import { setMarketConductQuestion, setReasonForNeverSplittingPremiumFromExpense } from './actions'
import { isFNAPath } from 'quick-quote/components/navigations/utils'
import { getSelectedNeedType } from '../need-and-gap-analysis/selectors'

import { MarketConducts } from './components'
import { getToggles } from '../../feature-toggles'

const mapStateToProps = (state: AppState) => {
  const selectedNeedType = getSelectedNeedType(state)
  return {
    selectedNeedType,
    riskQuestion: getMarketConductRiskQuestionWithChoice(state),
    insuranceQuestion: getMarketConductInsuranceQuestionWithChoice(state),
    productILP: isILPProduct(state),
    isSelectedThirdPartyJoin: isSelectedThirdPartyJoin(state),
    isSelectedSavingAndInvestmentNeed: isSelectedSavingAndInvestmentNeed(state),
    selectedDisplayProductCode: getSelectedDisplayProductCode(state),
    isSelectedNeverSplittingPremiumFromExpense: isSelectedNeverSplittingPremiumFromExpense(state),
    reasonForNeverSplittingPremiumFromExpense: getReasonForNeverSplittingPremiumFromExpense(state),
    isNonILPNeedWithInvestmentProduct: isNonILPNeedWithInvestmentProduct(state),
  }
}

const mapDispatchToProps = {
  setMarketConductQuestion,
  setReasonForNeverSplittingPremiumFromExpense,
}

export const MarketConductsContainer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  branch(
    (props) => {
      if (getToggles().ENABLE_FNA_V3) {
        const { location, selectedNeedType } = props
        const isFNA = isFNAPath(location.pathname)
        return isFNA && !selectedNeedType
      }
      return !props.selectedDisplayProductCode
    },
    renderComponent(() => <Redirect to="/" push />)
  )
)(MarketConducts)
