// @flow

export const chartLegend = (color: string, text: string) => ({
  table: {
    widths: [1, '*'],
    body: [
      [
        {
          table: {
            body: [[{ text: '', fillColor: color, marginTop: -4 }]],
          },
          layout: {
            hLineColor: () => color,
            vLineColor: () => color,
            paddingLeft: () => 1.5,
            paddingRight: () => 1.5,
            paddingTop: () => 1,
            paddingBottom: () => 2,
          },
        },
        { text: text, marginTop: -3, marginLeft: 8, fontSize: 8 },
      ],
    ],
  },
  layout: {
    hLineWidth: () => 0,
    vLineWidth: () => 0,
    paddingLeft: () => 0,
    paddingRight: () => 0,
    paddingTop: () => 0.5,
    paddingBottom: () => 0.5,
  },
  lineHeight: '0.9',
})

export default chartLegend
