//@flow
import { set, unset } from 'lodash/fp'
import type { Action } from './actions'
import {
  SET_FNA_NEED_CATEGORY,
  CLEAR_FNA_NEED_GAP_QUESTIONS,
  SET_FNA_GAP_VALUE,
  SET_FNA_GAP_TARGET_SAVINGS,
  CLEAR_FNA_GAP_TARGET_SAVINGS,
  CLEAR_FNA_GAP_INVESTMENT_GOAL_OTHER,
  CLEAR_REASON_FOR_CHOOSING_OTHER,
  CLEAR_OTHER_COVERAGE_OTHER,
  RESET_FNA_NEED,
} from './actions'
import { RESET_QUOTE_FORM } from '../../actions'

export const initialNeedGap = {
  type: '',
  questions: {
    targetSavings: null,
  },
}
export type NeedGapState = {
  type: string,
  questions: {
    targetSavings: ?Number,
  },
}

const reducer = (state: NeedGapState = initialNeedGap, action: Action) => {
  switch (action.type) {
    case SET_FNA_NEED_CATEGORY:
      const newState = set('type', action.payload.id)(state)
      return set('planId', action.payload.planId)(newState)
    case CLEAR_FNA_NEED_GAP_QUESTIONS:
      return set('questions', {})(state)
    case SET_FNA_GAP_TARGET_SAVINGS:
      return set('questions.targetSavings', action.payload)(state)
    case CLEAR_FNA_GAP_TARGET_SAVINGS:
      return unset('questions.targetSavings')(state)
    case CLEAR_FNA_GAP_INVESTMENT_GOAL_OTHER:
      return unset('questions.investmentGoalOther')(state)
    case CLEAR_REASON_FOR_CHOOSING_OTHER:
      return unset('questions.otherReason')(state)
    case CLEAR_OTHER_COVERAGE_OTHER:
      return unset('questions.otherCoverageOther')(state)
    case SET_FNA_GAP_VALUE:
      return set(`questions.${action.payload.questionKey}`, action.payload.value)(state)
    case RESET_QUOTE_FORM:
    case RESET_FNA_NEED:
      return initialNeedGap
    default: {
      return state
    }
  }
}

export default reducer
