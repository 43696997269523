// @flow
import _ from 'lodash'
import ProfileSnapshot from './profile-summary/profile-snapshot'
import {
  LegalConditionDisclaimerMarketConduct,
  LegalConditionDisclaimerMarketConductNoAddtionalContract,
} from 'quick-quote/product-flexi-health/benefit-illustration/components/flexi-health-legal-disclaimer'
import ProductBenefits from './product-benefits/product-benefits'
import PolicyValueTable from './policy-value/policy-value-table'
import PolicyValueGioTable from 'quick-quote/product-common/benefit-illustration/policy-value-gio/policy-value-table'
import InvestmentBenefit from './investment-benefit/investment-benefit'
import RiderBenefits from 'quick-quote/product-common/benefit-illustration/components/rider-benefits'
import { SidebarMenu } from 'quick-quote/components'
import { isNil } from 'core/service/lib/type-check'
import { FormFeedback, FormGroup } from 'reactstrap'

import type { DisplayProduct } from 'core/service/display-product'
import type { SumAssured$, BasicPremium$ } from 'core/data-model/basic-plan'
import type { PolicyValue, BenefitSummary as BenefitSummaryType, TaxDeduction } from 'core/service/benefit-illustration'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { Age } from 'core/data-model/insured'
import { Redirect } from 'react-router-dom'
import PATH from 'quick-quote/constants/path'
import MESSAGES from 'core/data-model/constants/validation-messages'
import VALUES from 'core/data-model/constants/values'
import BIMESSAGES from 'core/data-model/constants/bi-messages'
import TaxGioDeductionBenefit from 'quick-quote/product-common/benefit-illustration/components/tax-gio-deduction-benefit'
import TaxConsentDeductionBenefit from 'quick-quote/product-common/benefit-illustration/components/tax-consent-deduction-benefit'

export const summaryHeaderWithCode = ({ displayProduct, riders }: *) => {
  const name = _.get(displayProduct, 'displayName', '')
  const riderPlanCode = _.get(displayProduct, 'basicPlanCode', '')
  switch (riderPlanCode) {
    case BIMESSAGES.ULTIMATE_GROWTH_CODE_2010:
      return `${name} ${BIMESSAGES.ULTIMATE_GROWTH_QQ_2010} (${riderPlanCode})`
    case BIMESSAGES.ULTIMATE_GROWTH_CODE_2515:
      return `${name} ${BIMESSAGES.ULTIMATE_GROWTH_QQ_2515} (${riderPlanCode})`
    case BIMESSAGES.ULTIMATE_GROWTH_CODE_2525:
      return `${name} ${BIMESSAGES.ULTIMATE_GROWTH_QQ_2525} (${riderPlanCode})`
    default:
      return `${name} (${riderPlanCode})`
  }
}

const summaryHeader = ({ displayProduct }) => {
  if (displayProduct) {
    if (displayProduct.basicPlanCode === 'W80F06') {
      return displayProduct.name
    }

    return VALUES.PRODUCT_GIO.includes(displayProduct.code)
      ? VALUES.PREFIX_PRODUCT_NAME_THAI + ' ' + displayProduct.nameThai
      : displayProduct.nameThai
  } else {
    return ''
  }
}

export type BenefitIllustrationProps = {
  displayProduct: ?DisplayProduct,
  riders: (Rider & RiderState)[],
  genderLabel: string,
  age: Age,
  calculatedCoveragePeriod: number,
  calculatedPaymentPeriod: number,
  sumAssured$: SumAssured$,
  basicPremium$: BasicPremium$,
  totalPremium: number,
  totalRiderPremium: number,
  selectedModelFactorLabel: string,
  selectedModelFactorPeriod: number,
  hasNonLevelRider: boolean,
  hasShowDisclaimerRiderAsterisk: boolean,
  coveragePlanStartDate: Date,
  legalDisclaimerEndDate: Date,
  policyValue: PolicyValue,
  benefitSummary: BenefitSummaryType,
  canProceedToBenefitIllustration: boolean,
  isBlockedPdfGeneration: boolean,
  isBlockedEnteringESub: boolean,
  taxDeduction: TaxDeduction,
  isBancAgent: boolean,
  isNewAsteriskOfSummaryMainContract: boolean,
}

const BenefitIllustration = ({
  displayProduct,
  riders,
  genderLabel,
  age,
  calculatedCoveragePeriod,
  calculatedPaymentPeriod,
  sumAssured$,
  basicPremium$,
  totalPremium,
  totalRiderPremium,
  selectedModelFactorLabel,
  hasNonLevelRider,
  hasShowDisclaimerRiderAsterisk,
  coveragePlanStartDate,
  legalDisclaimerEndDate,
  policyValue,
  benefitSummary,
  canProceedToBenefitIllustration,
  isBlockedPdfGeneration,
  isBlockedEnteringESub,
  taxDeduction,
  isBancAgent,
  isNewAsteriskOfSummaryMainContract,
  isReviseQQ,
}: BenefitIllustrationProps) => {
  if (!canProceedToBenefitIllustration) {
    return <Redirect to={PATH.PRODUCT_SELECTION} push />
  } else if (isNil(displayProduct)) return <div id="benefit-illustration-invalid" />
  else {
    return (
      <div id="benefit-illustration">
        <div className="content">
          <ProfileSnapshot
            displayProduct={displayProduct}
            riders={riders}
            genderLabel={genderLabel}
            age={age}
            calculatedCoveragePeriod={calculatedCoveragePeriod}
            calculatedPaymentPeriod={calculatedPaymentPeriod}
            policyValue={policyValue}
            sumAssured={sumAssured$.value}
            basicPremium={basicPremium$.value}
            totalPremium={totalPremium}
            totalRiderPremium={totalRiderPremium}
            selectedModelFactorLabel={selectedModelFactorLabel}
            hasNonLevelRider={hasNonLevelRider}
            hasShowDisclaimerRiderAsterisk={hasShowDisclaimerRiderAsterisk}
            profileSummaryHeader={summaryHeaderWithCode}
            summaryTableHeader={summaryHeader}
            isNewAsteriskOfSummaryMainContract={isNewAsteriskOfSummaryMainContract}
            isReviseQQ={isReviseQQ}
          />

          <ProductBenefits
            displayProduct={displayProduct}
            sumAssured={sumAssured$.value}
            basicPremium={basicPremium$.value}
            calculatedPaymentPeriod={calculatedPaymentPeriod}
            calculatedCoveragePeriod={calculatedCoveragePeriod}
            policyValue={policyValue}
            benefitSummaryHeader={({ displayProduct }) => displayProduct.nameThai}
          />

          {riders.length > 0 && <RiderBenefits />}

          {VALUES.PRODUCT_GIO.includes(displayProduct.code) ? (
            <PolicyValueGioTable policyValue={policyValue} basicPlan={displayProduct} />
          ) : (
            <PolicyValueTable policyValue={policyValue} basicPlan={displayProduct} />
          )}

          {!VALUES.TERM_LIFE_PLANCODE_EXCEPT_CALCULATE_INVESTMENT_BENEFIT.includes(displayProduct.code) && (
            <InvestmentBenefit
              displayProduct={displayProduct}
              coveragePlanStartDate={coveragePlanStartDate}
              legalDisclaimerEndDate={legalDisclaimerEndDate}
            />
          )}

          {VALUES.PRODUCT_GIO.includes(displayProduct.code) ? (
            <TaxGioDeductionBenefit taxDeduction={taxDeduction} />
          ) : (
            <TaxConsentDeductionBenefit taxDeduction={taxDeduction} />
          )}

          {VALUES.PRODUCT_GIO.includes(displayProduct.code) ? (
            <LegalConditionDisclaimerMarketConductNoAddtionalContract
              startDate={coveragePlanStartDate}
              endDate={legalDisclaimerEndDate}
              isBancAgent={isBancAgent}
            />
          ) : (
            <LegalConditionDisclaimerMarketConduct
              startDate={coveragePlanStartDate}
              endDate={legalDisclaimerEndDate}
              isBancAgent={isBancAgent}
            />
          )}

          {isBlockedEnteringESub ? (
            <FormGroup className="has-warning">
              <FormFeedback className={'eligibility-message attached-icon'}>
                <div> {MESSAGES.RULE_ENTER_ESUB_PREREQUISITE} </div>
              </FormFeedback>
            </FormGroup>
          ) : (
            ''
          )}
        </div>
        <SidebarMenu
          mainMenu={[
            {
              text: VALUES.PRODUCT_PLANCODE_NO_RIDER.includes(displayProduct.code)
                ? BIMESSAGES.SUMMARY_OF_MAIN_CONTRACTS
                : BIMESSAGES.SUMMARY_OF_MAIN_AND_ADDITIONAL_CONTRACTS,
              id: 'profile-summary',
              isSub: false,
            },
            {
              text: 'รายละเอียดของสัญญาหลัก',
              id: 'product-benefits',
              isSub: false,
            },
            {
              text: BIMESSAGES.POLICY_VALUE_TABLE,
              id: 'policy-value',
              isSub: false,
            },
            {
              text: BIMESSAGES.INVESTMENT_BENEFITS,
              id: 'investment-benefit',
              isSub: false,
              isShow: !VALUES.TERM_LIFE_PLANCODE_EXCEPT_CALCULATE_INVESTMENT_BENEFIT.includes(displayProduct.code),
            },
            {
              text: BIMESSAGES.LEGAL_DISCLAIMER,
              id: 'legal-disclaimer-market-conduct',
              isSub: false,
            },
          ]}
        />
      </div>
    )
  }
}

export default BenefitIllustration
