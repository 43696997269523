import { compact } from 'lodash/fp'
import { createSectionCreator, createField } from '../utils'

const getQuestions = (app, id) => {
  return compact([
    createField(`${id}.isDisabledPerson.checked`, [
      {
        id: `${id}.isDisabledPerson.checked`,
        c: 'RadioButton',
        required: true,
        label: 'ง. ท่านมีร่างกายส่วนใดพิการหรือไม่',
        p: {
          options: [
            {
              text: 'ไม่มี',
              value: 'no',
            },
            {
              text: 'มี',
              value: 'yes',
            },
          ],
        },
      },
    ]),
  ])
}

export default createSectionCreator(getQuestions)
