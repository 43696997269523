// @flow
import NumberFormat from 'react-number-format'
import { Input } from 'reactstrap'

type BasicPremiumProps = {
  isReadOnly: boolean,
  basicPremium: number,
  editBasicPremium: (number) => void,
}

const BasicPremium = ({ isReadOnly, basicPremium, editBasicPremium }: BasicPremiumProps) => {
  return (
    <NumberFormat
      id="basic-premium-input"
      disabled={Boolean(isReadOnly)}
      value={basicPremium}
      onChange={(e, values) => editBasicPremium(Number(values.value))}
      isAllowed={({ formattedValue }) => formattedValue.length <= 17}
      customInput={Input}
      thousandSeparator={true}
      decimalPrecision={2}
      allowNegative={false}
      onFocus={(e) => e.target.setSelectionRange(0, -1)}
      type="tel"
      pattern="[0-9]*"
    />
  )
}

export default BasicPremium
