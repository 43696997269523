export const NavigationMenu = ({ children }) => (
  <nav>
    <ul className="nav">{children}</ul>
  </nav>
)

export const NavigationItem = ({ id, children }) => (
  <li className="nav-item">
    <span id={`${id}-icon`} className="nav-link">
      {children}
    </span>
  </li>
)
