// @flow
import _ from 'lodash'
import { isWealthHub } from 'core/service/investment/funds'
import type { ValidateFields } from './selectors'
import type { Category } from 'core/data-model/basic-plan'
import type { FundAllocationToggleItem } from 'core/service/investment'
import VALUES from 'core/data-model/constants/values'
import { getToggles } from 'quick-quote/feature-toggles'

export const allFieldsNotEmpty = (fields: *[]) => fields.every((f) => f !== undefined && f !== '')

export const isESubAction = (action: string): boolean => !action
export const isInvestment = (category: Category): boolean => category === VALUES.INVESTMENT
export const isRiskOver = (rpulFundAlloc: FundAllocationToggleItem[]): boolean => {
  const fundRiskies = _.maxBy(rpulFundAlloc, (fund) => fund.riskLevel) || {}
  const riskLevel = fundRiskies.riskLevel
  return riskLevel > VALUES.HIGH_RISK
}

type IsWealthHubStaffProps = {
  category: Category,
  rpulFundAlloc: FundAllocationToggleItem[],
  agentType: ?string,
}

//TODO [B] adjust function name to match with usage (isWealthHubStaffNeeded)
export const isWealthHubStaff = ({ category, rpulFundAlloc, agentType }: IsWealthHubStaffProps) =>
  isInvestment(category) && isWealthHub(agentType) && getToggles().ENABLE_IWEALTH_HUB_STUFF

export const _isRequiredWealthHubStaff = (
  validateFields: ValidateFields,
  action: string,
  agentType: ?string
): boolean => {
  return (
    isESubAction(action) &&
    isWealthHubStaff({
      category: _.get(validateFields, 'category'),
      rpulFundAlloc: _.get(validateFields, 'rpulFundAlloc'),
      agentType,
    })
  )
}
