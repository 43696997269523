import _ from 'lodash'
import { RadioButtonGroup } from 'common-components'

import { compose, withHandlers, mapProps } from 'recompose'

export const Component = RadioButtonGroup

export default (hoc) =>
  compose(
    withHandlers({
      onChange: ({ setValue, options, onChange, validate, unsetValue, clearValue }) => (value, index) => {
        setValue(options[index])
        clearValue && unsetValue(clearValue)
        validate(true)
      },
    }),
    mapProps(({ value, ...props }) => ({
      ...props,
      value: _.get(value, 'value'),
    })),
    hoc
  )(Component)
