// @flow

import ModelFactorSelection from './model-factor-selection'
import BasicPlan from './basic-plan'

export const BasicPlanCalculation = () => (
  <div id="basic-plan">
    <div className="content">
      <ModelFactorSelection />
      <BasicPlan />
    </div>
  </div>
)
export default BasicPlanCalculation
