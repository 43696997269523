import { compact } from 'lodash/fp'
import paymentModalMergeState from './paymentModalMergeState'
import { formatNumber } from 'core/service/lib/number-format'
import { getAppConfig } from 'deploy-env/app-config'
import { getToggles } from 'quick-quote/feature-toggles'
import { setSubmitWithPayment } from 'e-submission/apps/actions'

import { shouldShowOptionsToPayLater } from './selectors'
import {
  isSelectedToPayLater,
  isRemoteSelling,
  getInsuredDopaResult,
  getPayerDopaResult,
  getInsuredDopaRequestFailed,
  getPayerDopaRequestFailed,
  getQuickQuoteProductBasicPlanCode,
} from '../selectors'
import remotePaymentModalMergeState from './remotePaymentModalMergeState'

export const getSubmitWithoutPaymentField = (app, remoteSelling = false) => {
  const verifyFailed = !getInsuredDopaResult(app) || !getPayerDopaResult(app)
  const dopaFailed = getInsuredDopaRequestFailed(app) || getPayerDopaRequestFailed(app)
  const getPayLaterInfo = (options) => {
    if (options.isVerifiedDopaFailed)
      return 'เนื่องจากท่านยืนยันตัวตนไม่สำเร็จ ท่านจะสามารถชำระเงินได้ในภายหลังเท่านั้น'
    if (options.isRemoteSelling) return ''
    if (options.isONlyPaylater) return ''
    return options.isSelected
      ? `ลูกค้ามีความประสงค์ ที่จะให้พิจารณาใบคำขอเอาประกันพร้อมกับเอกสารอื่นๆ ก่อนที่จะชำระเบี้ยประกัน สำหรับแบบประกันภัยทั่วไป เบี้ยประกันตั้งแต่ ${formatNumber(
          getAppConfig().MINIMUM_PREMIUM_AMOUNT_TO_SUBMIT_WITHOUT_PAYMENT
        )} บาท หรือ จำนวนเงินเอาประกันภัยตั้งแต่ ${formatNumber(
          getAppConfig().MINIMUM_SA_AMOUNT_TO_SUBMIT_WITHOUT_PAYMENT
        )} บาท เท่านั้น`
      : ''
  }

  let optionsPayLater = [
    {
      text: 'ชำระเบี้ยภายหลัง',
      value: true,
      openDialog: false,
    },
    {
      text: 'ชำระเบี้ยงวดแรก',
      value: false,
      openDialog: true,
    },
  ]

  let optionsReason = [
    {
      text: 'ส่งใบคำขอเอาประกันเพื่อพิจารณาก่อน',
      value: '01_submit_for_approval',
    },
    {
      text: 'ลูกค้ายังไม่พร้อมชำระเบี้ย',
      value: '02_customer_not_ready_to_make_a_payment',
    },
  ]

  if (getToggles().ENABLE_PAY_LATER && getToggles().ENABLE_EKYC_F2F && verifyFailed && !dopaFailed) {
    optionsPayLater = optionsPayLater.map((op) =>
      op.text === 'ชำระเบี้ยงวดแรก'
        ? {
            text: 'ชำระเบี้ยงวดแรก',
            value: false,
            openDialog: true,
            canSelectable: false,
          }
        : op
    )

    optionsReason = [
      {
        text: 'ยืนยันตัวตนไม่สำเร็จ',
        value: '03_DOPA_failed_verifications_not_allow_payment',
      },
    ]
  } else if (getAppConfig().PRODUCT_ONLY_PAYLATER.includes(getQuickQuoteProductBasicPlanCode(app))) {
    optionsPayLater = optionsPayLater.map((op) =>
      op.text === 'ชำระเบี้ยงวดแรก'
        ? {
            text: 'ชำระเบี้ยงวดแรก',
            value: false,
            openDialog: true,
            canSelectable: false,
          }
        : op
    )
  }

  return compact([
    shouldShowOptionsToPayLater(app) && {
      id: 'submitWithoutPaymentTitle',
      c: 'SubmitWithoutPaymentTitle',
    },
    shouldShowOptionsToPayLater(app) && {
      id: 'submitWithoutPayment.selected',
      c: 'RadioButtonWithDialog',
      required: true,
      p: {
        options: optionsPayLater,
        onChange: ({ value, dispatch }) => (!value ? dispatch(setSubmitWithPayment()) : () => {}),
        subpage: 'payment',
        title: 'ชำระเบี้ยงวดแรก',
        mergeState: remoteSelling ? remotePaymentModalMergeState : paymentModalMergeState,
      },
      infoMessage: getPayLaterInfo({
        isRemoteSelling: isRemoteSelling(app),
        isSelected: isSelectedToPayLater(app),
        isVerifiedDopaFailed:
          getToggles().ENABLE_PAY_LATER && getToggles().ENABLE_EKYC_F2F && verifyFailed && !dopaFailed,
        isONlyPaylater: getAppConfig().PRODUCT_ONLY_PAYLATER.includes(getQuickQuoteProductBasicPlanCode(app)),
      }),
    },
    isSelectedToPayLater(app) && {
      label: 'เหตุผลที่ลูกค้าประสงค์จะชำระเบี้ยภายหลัง',
      id: 'submitWithoutPayment.reason',
      c: 'Dropdown',
      required: isSelectedToPayLater(app),
      p: {
        mergeState: (state) => ({
          options: optionsReason,
        }),
        placeHolder: 'กรุณาระบุเหตุผล',
      },
    },
  ])
}
