// @flow

import type { AgeRule } from 'core/data-model/rider/rules'
import type { RiderEligibilities } from 'core/data-model/rider'
import type { ValidationMessage } from 'core/data-model/validation'

import { isAgeLessThan, isAgeMoreThan } from 'core/service/insured'
import { formatAge } from 'core/service/lib/age-format'

import riderMessages from 'core/data-model/constants/validation-messages'
import values from 'core/data-model/constants/values'
import Mustache from 'mustache'

export const checkAgeRule = (rule: AgeRule, eligibilities: RiderEligibilities): ValidationMessage => {
  let errorMessage = ''
  let breakAgeLimit = (age, inverse) => {
    let ageLimitCheck = isAgeLessThan(age, rule.minimumValue) || isAgeMoreThan(age, rule.maximumValue)
    if (inverse) {
      return !ageLimitCheck
    }
    return ageLimitCheck
  }

  if (rule.checkFor === values.INSURED && breakAgeLimit(eligibilities.insured.age, rule.inverse)) {
    errorMessage = Mustache.render(riderMessages.RULE_RIDER_INSURED_AGE, {
      minimumAge: formatAge(rule.minimumValue),
      maximumAge: formatAge(rule.maximumValue),
    })
  } else if (rule.checkFor === values.PAYER && breakAgeLimit(eligibilities.payerAge, rule.inverse)) {
    errorMessage = Mustache.render(riderMessages.RULE_RIDER_PAYER_AGE, {
      insuredAge: formatAge(eligibilities.insured.age),
      maximumPayerAge: formatAge(eligibilities.maxPayerAge),
    })
  }

  return {
    type: rule.validationType,
    message: errorMessage,
  }
}
