// @flow

import MESSAGES from 'core/data-model/constants/messages'
import VALUES from 'core/data-model/constants/values'
import type { RiderPlan } from 'core/data-model/rider/types'
import type { ValidationMessage } from 'core/data-model/validation'
import type { NoImageDisplayProduct } from 'core/service/display-product'
import type { BIProps } from 'core/service/pdf-generation/document-creation'
import D from 'decimal.js'
import { find, flow, get, mapValues, omit, pick, set, unset } from 'lodash/fp'
import type { State as EntryComponentState } from 'quick-quote/entry-component/reducer'
import { getEntryComponent } from 'quick-quote/entry-component/selectors'

import { getToggles } from 'quick-quote/feature-toggles'
import type { State as InsuredInformationState } from 'quick-quote/insured-information/reducer'
import type { Funds } from 'quick-quote/interface/fund'
import { mapFunds } from 'quick-quote/interface/fund'
import { mapInvestmentRisk } from 'quick-quote/interface/investment-risk'

import type { AppState } from 'quick-quote/reducers'
import { getRiderPlanCode as getMEXRiderPlanCode } from 'core/service/rider/rider-mex'
import { isUnderage } from 'core/service/insured/age'
import constants from 'core/data-model/constants/defaults'

export type AsyncDataSource<T> = {
  status: 'uninitialised' | 'fetching' | 'ready' | 'error',
  payload: T,
}

type RiderInterface = {
  isSelected: boolean,
  isSelectable: boolean,
  errors: ValidationMessage[],
  code: string,
  name: string,
  sumAssured: number,
  premium: number,
  yearsOfCoverage: number,
  selectedPlan: RiderPlan,
}

type CoveragePlanInterface = {
  totalPremium: { value: number },
  selectedBasicPlan: {
    sumAssured: { value: number },
    premium: number,
    regularPremium?: number,
    regularTopUp?: number,
    lumpSumTopUp?: number,
  },
  selectedModelFactorID: string,
  riders: RiderInterface[],
  pensionType?: string,
  selectedPlan?: string,
}

type QuickQuoteInterface = {
  insured: InsuredInformationState,
  selectedDisplayProduct: NoImageDisplayProduct,
  coveragePlan: CoveragePlanInterface,
  funds?: Funds,
  entryComponent: EntryComponentState,
  isRemoteSelling: boolean,
  selectedRemoteSelling: boolean,
}

const mapObject = (key, fn) => (state) => {
  const value = get(key, state)
  return set(key, fn(value), unset(key, state))
}

// default
const riderStyle = (state) => {
  const getBase = flow(
    pick([
      'insured',
      'coveragePlan.totalPremium.value',
      'coveragePlan.selectedBasicPlan.sumAssured.value',
      'coveragePlan.selectedBasicPlan.premium',
      'coveragePlan.selectedBasicPlan.campaignDiscountEndDate',
      'coveragePlan.selectedModelFactorID',
      'coveragePlan.riders',
      'coveragePlan.pensionType',
      'coveragePlan.selectedPlan',
      'selectedDisplayProduct',
      'entryComponent',
      'isRemoteSelling',
      'selectedRemoteSelling',
      'remoteSelling',
      'fna',
      'sfReference',
    ]),
    omit([
      'selectedDisplayProduct.image',
      'remoteSelling.callStatus',
      'remoteSelling.mediaControl',
      'remoteSelling.appId',
      'remoteSelling.disableEditing',
    ]),
    mapObject(
      'coveragePlan.riders',
      mapValues(
        pick([
          'isSelected',
          'isSelectable',
          'errors',
          'code',
          'name',
          'sumAssured',
          'premium',
          'yearsOfCoverage',
          'selectedPlan',
          'aura',
        ])
      )
    )
  )
  let base = getBase(state)
  if (
    'MEX' in base.coveragePlan.riders &&
    base.coveragePlan.riders.MEX.isSelectable &&
    base.coveragePlan.riders.MEX.isSelected
  ) {
    base.coveragePlan.riders.MEX.selectedPlan.planCode = getMEXRiderPlanCode(
      base.coveragePlan.riders.MEX,
      base.insured.age
    )
  }

  for (const rider in base.coveragePlan.riders) {
    if (
      VALUES.QQ_ESUB_REPLACE_RIDER_SA_WITH_BASIC_SA.includes(rider) &&
      base.coveragePlan.riders[rider].isSelectable &&
      base.coveragePlan.riders[rider].isSelected
    ) {
      base.coveragePlan.riders[rider].sumAssured = Math.min(
        base.coveragePlan.selectedBasicPlan.sumAssured.value,
        get(`coveragePlan.riders.${rider}.defaultSumAssured`)(state) || 0
      )
    }
  }

  if (base.insured.payer.relation != constants.SELF && !isUnderage(base.insured.age)) {
    let inValidPayer = true
    VALUES.REQUIRED_RIDER_FOR_ADULT_INSURE_NOT_PAYER.forEach((rider) => {
      if (
        rider in base.coveragePlan.riders &&
        base.coveragePlan.riders[rider].isSelectable &&
        base.coveragePlan.riders[rider].isSelected
      ) {
        inValidPayer = false
      }
    })
    if (inValidPayer) {
      base.insured.payerNeeded = false
      base.insured.payer.relation = constants.SELF
    }
  }
  if (base.selectedDisplayProduct.category === VALUES.PA) {
    base.selectedDisplayProduct.basicPlanCode = base.coveragePlan.selectedPlan
  }
  return base
}

const fundStyle = (state) => {
  const { FIRST_NAME_DEFAULT, LAST_NAME_DEFAULT } = MESSAGES

  //TODO: Remove unnecessary getBase. use get(x)(state) in other places.
  const getBase = flow(
    pick([
      'insured',
      'selectedDisplayProduct',
      'isRemoteSelling',
      'fna',
      'selectedRemoteSelling',
      'remoteSelling',
      'sfReference',
    ]),
    omit([
      'selectedDisplayProduct.image',
      'remoteSelling.callStatus',
      'remoteSelling.mediaControl',
      'remoteSelling.appId',
      'remoteSelling.disableEditing',
    ])
  )

  const base = getBase(state)
  const firstName = base.insured.firstName
  const lastName = base.insured.lastName

  const getOrUseDefaultName = (name, defaultName) => {
    if (getToggles().ENABLE_USE_DEFAULT_NAME_FROM_QQ_FOR_ILP) {
      return name
    }
    return name.name === defaultName ? { name: '', errors: [] } : name
  }

  const insured = {
    ...base.insured,
    firstName: getOrUseDefaultName(firstName, FIRST_NAME_DEFAULT),
    lastName: getOrUseDefaultName(lastName, LAST_NAME_DEFAULT),
  }

  const coveragePlan = state.investment.coveragePlan
  // TODO: Change this logic for checking/getting rider premium on specific LIP product (RPUDR)
  const totalRiderPremium = get('coveragePlan.totalRiderPremium')(state)
  const regularPremium = get('selectedBasicPlan.regularPremium.value')(coveragePlan)
  const regularTopUp = get('selectedBasicPlan.regularTopUp.value')(coveragePlan)
  const firstYearLstu = flow(get('expectedReturnComparisons.value'), find({ year: 1 }))(coveragePlan)
  const lumpSumTopUp = get('lumpSum.value')(firstYearLstu)
  return {
    ...base,
    insured,
    coveragePlan: {
      selectedBasicPlan: {
        regularPremium,
        regularTopUp,
        lumpSumTopUp,
        sumAssured: get('selectedBasicPlan.sumAssured.value')(coveragePlan),
        regularPremiumRatio: get('selectedBasicPlan.regularPremiumRatio')(coveragePlan),
      },
      totalPremium: {
        value: D(regularPremium)
          .plus(D(regularTopUp))
          .plus(D(totalRiderPremium))
          .toNumber(),
      },
      selectedModelFactorID: get('selectedModelFactorID')(coveragePlan),
      riders: mapValues(
        pick([
          'isSelected',
          'isSelectable',
          'errors',
          'code',
          'name',
          'sumAssured',
          'premium',
          'yearsOfCoverage',
          'selectedPlan',
          'aura',
        ])
      )(state.coveragePlan.riders),
    },
    funds: mapFunds(state),
    investment: {
      investmentRisk: mapInvestmentRisk(state),
    },
    entryComponent: getEntryComponent(state),
    isRemoteSelling: base.isRemoteSelling,
    selectedRemoteSelling: base.selectedRemoteSelling,
    sfReference: base.sfReference,
  }
}

export const toQuickQuoteInterface = (state: AppState, biProps: BIProps): QuickQuoteInterface => {
  const { category } = state.selectedDisplayProduct
  if (category === VALUES.INVESTMENT) {
    return { ...fundStyle(state), biProps } // investment product
  }

  return { ...riderStyle(state), biProps } // default
}
