// @flow

import type { DisplayProduct } from 'core/service/display-product'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { Age } from 'core/data-model/insured'

import { isNil } from 'core/service/lib/type-check'
import SummaryRow from './summary-row'
import SummaryFooter from './summary-footer'
import MESSAGES from 'core/data-model/constants/messages'
import BIMESSAGES from 'core/data-model/constants/bi-messages'
import { getSumAssuredDisplayValue } from 'core/service/pdf-generation/products/common/logic'
import { getRiderSumAssuredDisplayValue } from 'core/service/benefit-illustration/profile-summary'

type SummaryTableProps = {
  displayProduct: ?DisplayProduct,
  age: Age,
  riders: (Rider & RiderState)[],
  totalPremium: number,
  calculatedCoveragePeriod: number,
  calculatedPaymentPeriod: number,
  basicPremium: number,
  basicSumAssured: number,
  hasNonLevelRider: boolean,
  summaryTableHeader: Function | string,
}

const SummaryTable = ({
  displayProduct,
  age,
  riders,
  calculatedCoveragePeriod,
  calculatedPaymentPeriod,
  basicPremium,
  basicSumAssured,
  totalPremium,
  hasNonLevelRider,
  summaryTableHeader,
}: SummaryTableProps) => {
  if (isNil(displayProduct)) {
    return
  }

  return (
    <div className="summary-table d-none d-md-block">
      <div className="details-table">
        <h3>{BIMESSAGES.SUMMARY_OF_MAIN_AND_ADDITIONAL_CONTRACTS}</h3>
        <table id="summary-table">
          <thead>
            <tr>
              <td>{`${BIMESSAGES.BASIC_PLAN} / ${BIMESSAGES.RIDER}`}</td>
              <td>{`${BIMESSAGES.COVERAGE_PERIOD} (${MESSAGES.TIME_UNIT_YEAR})`}</td>
              <td>{`${BIMESSAGES.PAYMENT_PERIOD} (${MESSAGES.TIME_UNIT_YEAR})`}</td>
              <td>{`${BIMESSAGES.SUM_ASSURED} (${MESSAGES.BAHT})`}</td>
              <td>{`${BIMESSAGES.PREMIUM} (${MESSAGES.BAHT})`}</td>
            </tr>
          </thead>
          <tbody>
            {/* Basic plan row */}
            {renderBasicPlanSummaryRow(
              displayProduct,
              calculatedCoveragePeriod,
              calculatedPaymentPeriod,
              basicSumAssured,
              basicPremium
            )}
            {/* Rider rows */}
            {renderRidersSummaryRows(riders, age)}
            <SummaryFooter totalPremium={totalPremium} />
          </tbody>
        </table>
      </div>
    </div>
  )
}

const renderBasicPlanSummaryRow = (
  displayProduct: DisplayProduct,
  calculatedCoveragePeriod: number,
  calculatedPaymentPeriod: number,
  basicSumAssured: number,
  basicPremium: number
) => {
  const sumAssured = getSumAssuredDisplayValue(displayProduct, basicSumAssured, false)

  return (
    <SummaryRow
      id={displayProduct.code}
      productCode={displayProduct.basicPlanCode}
      productName={'เบี้ยประกันภัยหลักเพื่อความคุ้มครอง'}
      coverageYear={calculatedCoveragePeriod}
      paymentPeriod={calculatedPaymentPeriod}
      premium={basicPremium}
      sumAssured={sumAssured}
      showSumAssuredDecimalPlaces={false}
    />
  )
}

const renderRidersSummaryRows = (riders: (Rider & RiderState)[], age: Age) => {
  if (isNil(riders) || isNil(age)) return

  return riders.map((rider) => {
    return (
      // $$FlowFixMe
      <SummaryRow
        key={`summary-row-${rider.code}`}
        id={rider.code}
        productCode={rider.package ? rider.selectedPlan.planCode : rider.code}
        productName={`${rider.thaiName}`}
        coverageYear={rider.yearsOfCoverage}
        paymentPeriod={rider.yearsOfPayment}
        premium={rider.premium}
        sumAssured={getRiderSumAssuredDisplayValue(rider)}
        showSumAssuredDecimalPlaces={rider.showSumAssuredDecimalPlaces}
      />
    )
  })
}

export default SummaryTable
