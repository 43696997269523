//@flow
import * as React from 'react'
import type { Dispatch } from 'redux'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { analyticsBiEvent } from 'analytics/actions'
export type AnalyticsEventType = 'BIEvent'

type AnalyticButtonProps = {
  children?: React.Node,
  dispatchAnalyticsEvent: Function,
  eventType: ?AnalyticsEventType,
  expectedEventType: AnalyticsEventType,
}

export const _BIAnalyticsButton = ({
  children,
  dispatchAnalyticsEvent,
  eventType,
  expectedEventType,
}: AnalyticButtonProps) => {
  if (eventType === expectedEventType) return <div onClick={() => dispatchAnalyticsEvent()}>{children}</div>
  else return children
}

const mapStateToProps = (state, props) => ({
  children: props.children,
  eventType: props.eventType,
  expectedEventType: props.expectedEventType,
})

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  dispatchAnalyticsEvent: bindActionCreators(analyticsBiEvent, dispatch),
})

export const WithAnalytics = connect(mapStateToProps, mapDispatchToProps)(_BIAnalyticsButton)
