// @flow

import GenderSelection from './gender-selection'
import Birthdate from './birthdate'
import OccupationSelection from './occupation-selection'
import OtherOccupationSelection from './other-occupation-selection'
import PayerInformation from './payer-information'
import InsuredInformationTemplate from './insured-information-template'
import LBLicenseInfoContainer from './lb-license-info'
import Name from './name'
import Title from './title'

export {
  GenderSelection,
  Birthdate,
  OccupationSelection,
  OtherOccupationSelection,
  PayerInformation,
  InsuredInformationTemplate,
  LBLicenseInfoContainer,
  Name,
  Title,
}
