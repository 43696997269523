// @flow
import _ from 'lodash'
import type { AppState } from 'quick-quote/reducers'
import type { InvestmentBenefitTable } from 'core/service/benefit-illustration/investment-benefit'
import { formatNumber } from 'core/service/lib/number-format'
import { connect } from 'react-redux'
import { getInvestmentBenefit } from './selectors'
import { getLegalDisclaimerEndDate } from 'quick-quote/product-whole-life/benefit-illustration/selectors'
import { getcoveragePlanStartDate } from 'quick-quote/product-common/coverage-plan/selectors'
import { getSelectedDisplayProductNameThai } from 'quick-quote/product-selection/selectors'
import MESSAGES from 'core/data-model/constants/bi-messages'

type InvestmentBenefitTableProps = {
  investmentBenefit: InvestmentBenefitTable,
  productName: string,
  legalDisclaimerEndDate: Date,
  coveragePlanStartDate: Date,
}

export const _InvestmentBenefitTable = ({
  investmentBenefit,
  productName,
  legalDisclaimerEndDate,
  coveragePlanStartDate,
}: InvestmentBenefitTableProps) => (
  <div className="group" id="investment-benefit">
    <h3>{MESSAGES.INVESTMENT_BENEFITS}</h3>
    <div className="dividend-table">
      <table>
        <thead>
          <tr>
            <td rowSpan={2}>{MESSAGES.INVESTMENT_BENEFITS_SUMMARY}</td>
            <td rowSpan={2}>{MESSAGES.INVESTMENT_BENEFITS_GUARANTEE}</td>
            <td colSpan={3}>{MESSAGES.INVESTMENT_BENEFITS_SCENARIO}</td>
          </tr>
          <tr>
            <td width="15%">{MESSAGES.EXAMPLE_1}</td>
            <td width="15%">{MESSAGES.EXAMPLE_2}</td>
            <td width="15%">{MESSAGES.EXAMPLE_3}</td>
          </tr>
        </thead>
        <tbody>
          {investmentBenefit.guaranteeBenefits.map((v, k) => (
            <InvestmentBenifitComponent datas={v} key={`guarantee_benefit_${k}`} />
          ))}
        </tbody>
        <tbody>
          {investmentBenefit.nonGuaranteeBenefits.map((v, k) => (
            <InvestmentBenifitComponent datas={v} key={`no_guarantee_benefit_${k}`} />
          ))}
        </tbody>
      </table>
    </div>
    <div className="disclaimer">
      <p>
        {MESSAGES.NOTE}: {MESSAGES.LIFE_SAVE_PRO_POLICY_VALUE_TABLE_NOTE_1}
      </p>
      <p>{MESSAGES.LIFE_SAVE_PRO_POLICY_VALUE_TABLE_NOTE_2}</p>
      <p>{MESSAGES.TERM_LIFE_BENEFITS_VALUE_TABLE_NOTE_1}</p>
      <p>{MESSAGES.TERM_LIFE_BENEFITS_VALUE_TABLE_NOTE_2}</p>
    </div>
  </div>
)

const InvestmentBenifitComponent = ({ datas }) => (
  <tr>
    <td>{datas.title}</td>
    <Amount amount={datas.guaranteeValue} key="guaranteeValue" />
    {datas.rates.map((v, k) => (_.isNumber(v) ? <Amount amount={v} key={k} /> : <td>{v}</td>))}
  </tr>
)

type AmountProps = {
  amount: number,
}

const Amount = ({ amount }: AmountProps) => <td>{formatNumber(amount, 0, true)}</td>

const mapStateToProps = (state: AppState): InvestmentBenefitTableProps => ({
  investmentBenefit: getInvestmentBenefit(state),
  legalDisclaimerEndDate: getLegalDisclaimerEndDate(state),
  coveragePlanStartDate: getcoveragePlanStartDate(state),
  productName: getSelectedDisplayProductNameThai(state),
})
export default connect(mapStateToProps)(_InvestmentBenefitTable)
