import { compact } from 'lodash/fp'

import { createSectionCreator, createField } from '../utils'
import { checker } from '../selectors'

const otherInsuranceCompanyField = (id) => [
  createField(`${id}.otherInsuranceCompany.sumAssured`, [
    {
      id: `${id}.otherInsuranceCompany.sumAssured`,
      c: 'NumberInput',
      required: true,
      label: 'จำนวนเงินเอาประกันภัยรวมทั้งหมด',
      p: {
        thousandSeparator: true,
      },
    },
  ]),
  createField(`${id}.otherInsuranceCompany.detail`, [
    {
      label: 'บริษัทประกันภัยนั้น ๆ ได้แก่',
      id: `${id}.otherInsuranceCompany.detail`,
      c: 'TextInput',
      required: true,
      p: {},
    },
  ]),
]

const getQuestions = (app, id) => {
  const { isYes } = checker(app)

  return compact([
    createField(`${id}.otherInsuranceCompany.checked`, [
      {
        id: `${id}.otherInsuranceCompany.checked`,
        c: 'RadioButton',
        required: true,
        label: 'ท่านมีหรือได้ขอเอาประกันชีวิต หรือประกันอุบัติเหตุส่วนบุคคลกับบริษัทอื่นหรือไม่',
        p: {
          options: [
            {
              text: 'ไม่มี',
              value: 'no',
            },
            {
              text: 'มี',
              value: 'yes',
            },
          ],
        },
      },
    ]),
    ...(isYes(`${id}.otherInsuranceCompany.checked`) ? otherInsuranceCompanyField(id) : []),
  ])
}

export default createSectionCreator(getQuestions)
