// @flow
import type { DisplayProduct } from 'core/service/display-product'
import { Link } from 'react-router-dom'
import { PATH, ROUTE_PATH } from 'quick-quote/constants/path'
import { isQuickQuotePath, isFNAPath } from 'quick-quote/components/navigations/tabs'
import VALUE from 'core/data-model/constants/values'
import { BasicPlanSummary } from './basic-plan-summary'
import { InvestmentBasicPlan } from './investment-basic-plan'
import { OrdinaryBasicPlan } from './ordinary-basic-plan'
import { getToggles } from 'quick-quote/feature-toggles'
import { getAppConfig } from 'deploy-env/app-config'

type BasicPlanComponentProps = {
  displayProduct: DisplayProduct,
  location: Object,
  isOnFnaPhase: boolean,
  selectProduct: (DisplayProduct) => void,
  validateInvestmentProduct: (DisplayProduct) => void,
  validateOrdinaryProduct: (DisplayProduct) => void,
  validateNormalProduct: (DisplayProduct) => void,
  cssClass?: string,
  isSelected?: boolean,
  disabled?: boolean,
}

const CATEGORY_ROUTE_QQ = {
  [VALUE.WHOLE_LIFE]: ROUTE_PATH.WHOLE_LIFE.INSURED_INFORMATION,
  [VALUE.HEALTH]: ROUTE_PATH.HEALTH.INSURED_INFORMATION,
  [VALUE.IHEALTHY_ULTRA]: ROUTE_PATH.IHEALTHY_ULTRA.INSURED_INFORMATION,
  [VALUE.LOAN]: ROUTE_PATH.LOAN.INSURED_INFORMATION,
  [VALUE.PROTECTION]: ROUTE_PATH.PROTECTION.INSURED_INFORMATION,
  [VALUE.SAVING]: ROUTE_PATH.SAVING.INSURED_INFORMATION,
  [VALUE.LIFE_SAVE_PRO]: ROUTE_PATH.LIFE_SAVE_PRO.INSURED_INFORMATION,
  [VALUE.FLEXI_HEALTH]: ROUTE_PATH.FLEXI_HEALTH.INSURED_INFORMATION,
  [VALUE.RETIREMENT]: ROUTE_PATH.RETIREMENT.INSURED_INFORMATION,
  [VALUE.PERFECTLIFE]: ROUTE_PATH.PERFECT_LIFE.INSURED_INFORMATION,
  [VALUE.HEALTH_TOPPING]: ROUTE_PATH.HEALTH_TOPPING.INSURED_INFORMATION,
  [VALUE.SUKHAPABDEKDEE]: ROUTE_PATH.SUKHAPABDEKDEE.INSURED_INFORMATION,
  [VALUE.LIFE_READY]: ROUTE_PATH.LIFE_READY.INSURED_INFORMATION,
  [VALUE.BUMNAN_READY]: ROUTE_PATH.BUMNAN_READY.INSURED_INFORMATION,
  [VALUE.TERM_LIFE]: ROUTE_PATH.TERM_LIFE.INSURED_INFORMATION,
  [VALUE.MRTA]: ROUTE_PATH.MRTA.INSURED_INFORMATION,
  [VALUE.PA]: ROUTE_PATH.PA.INSURED_INFORMATION,
}

const CATEGORY_ROUTE_QQ_FNA_PHASE = {
  [VALUE.WHOLE_LIFE]: ROUTE_PATH.WHOLE_LIFE.MARKET_CONDUCTS,
  [VALUE.HEALTH]: ROUTE_PATH.HEALTH.MARKET_CONDUCTS,
  [VALUE.IHEALTHY_ULTRA]: ROUTE_PATH.IHEALTHY_ULTRA.MARKET_CONDUCTS,
  [VALUE.LOAN]: ROUTE_PATH.LOAN.MARKET_CONDUCTS,
  [VALUE.PROTECTION]: ROUTE_PATH.PROTECTION.MARKET_CONDUCTS,
  [VALUE.SAVING]: ROUTE_PATH.SAVING.MARKET_CONDUCTS,
  [VALUE.LIFE_SAVE_PRO]: ROUTE_PATH.LIFE_SAVE_PRO.MARKET_CONDUCTS,
  [VALUE.FLEXI_HEALTH]: ROUTE_PATH.FLEXI_HEALTH.MARKET_CONDUCTS,
  [VALUE.RETIREMENT]: ROUTE_PATH.RETIREMENT.MARKET_CONDUCTS,
  [VALUE.PERFECTLIFE]: ROUTE_PATH.PERFECT_LIFE.MARKET_CONDUCTS,
  [VALUE.HEALTH_TOPPING]: ROUTE_PATH.HEALTH_TOPPING.MARKET_CONDUCTS,
  [VALUE.SUKHAPABDEKDEE]: ROUTE_PATH.SUKHAPABDEKDEE.MARKET_CONDUCTS,
  [VALUE.LIFE_READY]: ROUTE_PATH.LIFE_READY.MARKET_CONDUCTS,
  [VALUE.BUMNAN_READY]: ROUTE_PATH.BUMNAN_READY.MARKET_CONDUCTS,
  [VALUE.TERM_LIFE]: ROUTE_PATH.TERM_LIFE.MARKET_CONDUCTS,
  [VALUE.MRTA]: ROUTE_PATH.MRTA.MARKET_CONDUCTS,
  [VALUE.PA]: ROUTE_PATH.PA.MARKET_CONDUCTS,
}

const CATEGORY_ROUTE_FNA = {
  [VALUE.WHOLE_LIFE]: ROUTE_PATH.WHOLE_LIFE.MARKET_CONDUCTS,
  [VALUE.HEALTH]: ROUTE_PATH.HEALTH.MARKET_CONDUCTS,
  [VALUE.IHEALTHY_ULTRA]: ROUTE_PATH.IHEALTHY_ULTRA.MARKET_CONDUCTS,
  [VALUE.LOAN]: ROUTE_PATH.LOAN.MARKET_CONDUCTS,
  [VALUE.PROTECTION]: ROUTE_PATH.PROTECTION.MARKET_CONDUCTS,
  [VALUE.SAVING]: ROUTE_PATH.SAVING.MARKET_CONDUCTS,
  [VALUE.LIFE_SAVE_PRO]: ROUTE_PATH.LIFE_SAVE_PRO.MARKET_CONDUCTS,
  [VALUE.FLEXI_HEALTH]: ROUTE_PATH.FLEXI_HEALTH.MARKET_CONDUCTS,
  [VALUE.RETIREMENT]: ROUTE_PATH.RETIREMENT.MARKET_CONDUCTS,
  [VALUE.PERFECTLIFE]: ROUTE_PATH.PERFECT_LIFE.MARKET_CONDUCTS,
  [VALUE.HEALTH_TOPPING]: ROUTE_PATH.HEALTH_TOPPING.MARKET_CONDUCTS,
  [VALUE.SUKHAPABDEKDEE]: ROUTE_PATH.SUKHAPABDEKDEE.MARKET_CONDUCTS,
  [VALUE.LIFE_READY]: ROUTE_PATH.LIFE_READY.MARKET_CONDUCTS,
  [VALUE.BUMNAN_READY]: ROUTE_PATH.BUMNAN_READY.MARKET_CONDUCTS,
  [VALUE.TERM_LIFE]: ROUTE_PATH.TERM_LIFE.MARKET_CONDUCTS,
  [VALUE.MRTA]: ROUTE_PATH.MRTA.MARKET_CONDUCTS,
  [VALUE.PA]: ROUTE_PATH.PA.MARKET_CONDUCTS,
}

export const getLink = (productCategory: string, location: Object, isOnFnaPhase: boolean) => {
  if (
    !CATEGORY_ROUTE_QQ[productCategory] && getToggles().ENABLE_FNA_V3
      ? !CATEGORY_ROUTE_QQ[productCategory]
      : !CATEGORY_ROUTE_FNA[productCategory]
  )
    return
  const path = location.pathname
  const link = []
  if (isQuickQuotePath(path) && !isOnFnaPhase) {
    link.push(PATH.QUICK_QUOTE)
    link.push(CATEGORY_ROUTE_QQ[productCategory])
  } else if (isQuickQuotePath(path) && isOnFnaPhase) {
    link.push(PATH.QUICK_QUOTE)
    link.push(CATEGORY_ROUTE_QQ_FNA_PHASE[productCategory])
  } else if (isFNAPath(path)) {
    link.push(PATH.FNA)
    if (getToggles().ENABLE_FNA_V3) {
      link.push(CATEGORY_ROUTE_QQ[productCategory])
    } else {
      link.push(CATEGORY_ROUTE_FNA[productCategory])
    }
  } else {
    // Old Quick Quote
    link.push(CATEGORY_ROUTE_QQ[productCategory])
  }
  return link.join('')
}

export const BasicPlanComponent = ({
  displayProduct,
  isSelected,
  cssClass,
  selectProduct,
  validateInvestmentProduct,
  validateOrdinaryProduct,
  validateNormalProduct,
  disabled,
  location,
  isOnFnaPhase,
}: BasicPlanComponentProps) => {
  const { INVESTMENT } = VALUE

  const componentProps = {
    displayProduct: displayProduct,
    isSelected: isSelected,
    cssClass: cssClass,
    selectProduct: selectProduct,
    validateNormalProduct: validateNormalProduct,
    disabled: disabled,
  }

  switch (displayProduct.category) {
    case INVESTMENT:
      return (
        <InvestmentBasicPlan
          {...componentProps}
          validateProduct={validateInvestmentProduct}
          isOnFnaPhase={isOnFnaPhase}
          location={location}
        />
      )
    default: {
      if (
        getToggles().ENABLE_FORCE_LOGIN_ORDINARY_PRODUCT &&
        getAppConfig().ORDINARY_PRODUCT_FORCE_LOGIN_AVALIABLE.includes(displayProduct.code)
      ) {
        return (
          <OrdinaryBasicPlan
            {...componentProps}
            validateProduct={validateOrdinaryProduct}
            isOnFnaPhase={isOnFnaPhase}
            location={location}
          />
        )
      } else return <BasicPlan link={getLink(displayProduct.category, location, isOnFnaPhase)} {...componentProps} />
    }
  }
}

type BasicPlanProps = {
  displayProduct: DisplayProduct,
  selectProduct: (DisplayProduct) => void,
  validateNormalProduct: (DisplayProduct) => void,
  link?: string,
  cssClass?: string,
  isSelected?: boolean,
  disabled?: boolean,
}

export const BasicPlan = ({
  link,
  displayProduct,
  isSelected,
  cssClass,
  selectProduct,
  validateNormalProduct,
  disabled,
}: BasicPlanProps) => {
  if (!link || !displayProduct) return null
  return (
    <div
      key={displayProduct.code}
      className={['product', cssClass ? cssClass : '', isSelected ? 'selected' : '', disabled ? 'disabled' : ''].join(
        ' '
      )}
      data-product-name={displayProduct.displayName}
      data-product-code={displayProduct.code}
      onClick={() => !disabled && !isSelected && validateNormalProduct(displayProduct)}
    >
      <Link to={!disabled && link} className="product-card-container">
        <BasicPlanSummary displayProduct={displayProduct} />
      </Link>
    </div>
  )
}
