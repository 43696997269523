import _ from 'lodash'
import { createSectionCreator, createField } from '../utils'

const heightAndtWeightDetails = (app, id) => {
  const isWeightChangedRecently = _.get(app, `${id}.isWeightChangedRecently`)

  return _.compact([
    createField(`${id}.height`, [
      {
        label: 'ส่วนสูง (เซนติเมตร)',
        id: `${id}.height`,
        c: 'NumberInput',
        required: true,
        p: {},
      },
    ]),
    createField(`${id}.weight`, [
      {
        label: 'น้ำหนัก (กิโลกรัม)',
        id: `${id}.weight`,
        c: 'NumberInput',
        required: true,
        p: {},
      },
    ]),
  ])
}

export default createSectionCreator(heightAndtWeightDetails)
