//@flow
import { hasVisitedInsuredInformationPage, isCompletedMarketConducts } from 'quick-quote/fna/market-conducts/selectors'
import {
  canProceedToProductSelection,
  needTypeWasSelected,
  getStartSaleFrom,
} from 'quick-quote/fna/need-and-gap-analysis/selectors'
import { hasFirstYearLstu } from 'quick-quote/product-investment/coverage-plan/selectors'
import {
  canProceedToFundAndCoveragePlan,
  canProceedToInvestmentBenefitIllustration,
  canProceedToLstuFundAllocation,
  canProceedToPremiumCalculation,
  canProceedToRiskQuestionnaire,
  canProceedToRiskQuestionnaireV2,
  canProceedToRppFundAllocation,
  blockSelectedFund,
} from 'quick-quote/product-investment/journey-control-selectors'
import { canProceedPerfectLifeBenefitIllustration } from 'quick-quote/product-perfect-life/coverage-plan/selectors'
import { canProceedSukhapabDekDeeBenefitIllustration } from 'quick-quote/product-sukhapabdekdee/coverage-plan/selectors'
import { canProceedRetirementBenefitIllustration } from 'quick-quote/product-retirement/coverage-plan/selectors'
import { canProceedBumnanReadyBenefitIllustration } from 'quick-quote/product-bumnan-ready/coverage-plan/selectors'
import { productWasSelected, getIsProductValidated } from 'quick-quote/product-selection/selectors'
import { canProceedToBenefitIllustration } from 'quick-quote/product-whole-life/coverage-plan/selectors'
import { canProceedToBenefitIllustration as canProductPAProceedToBenefitIllustration } from 'quick-quote/product-pa/coverage-plan/selectors'
import { canProceedToCoveragePlan } from 'quick-quote/selectors/next-tab-proceeding'
import { canProceedToSelectPolicyOwner, canProceedToNormalTab } from 'quick-quote/product-s7cc/selectors'
import TABS from '../../../../core/data-model/constants/tab-messages'
import { fnaPath, PATH, ROUTE_PATH } from '../../../constants/path'
import type { AppState } from '../../../reducers'
import type { Tab } from '../../navigation-item'
import { getToggles } from '../../../feature-toggles'

export type NavTabConfig = {
  path: string,
  tabs: Tab[],
}

type BasicTabsPath = {
  NEED_AND_GAP_ANALYSIS: string,
  PRODUCT_SELECTION: string,
  MARKET_CONDUCTS: string,
}
export const proceedToProductSelection = (state: AppState) =>
  isCompletedMarketConducts(state) || canProceedToCoveragePlan(state) || hasVisitedInsuredInformationPage(state)

const canProceedToMarketConducts = (state: AppState) => {
  if (getToggles().ENABLE_FNA_V3) {
    return canProceedToProductSelection(state)
  } else return canProceedToProductSelection(state) && productWasSelected(state)
}

export const canProceedToInsuredInformation = (state: AppState) => {
  if (getToggles().ENABLE_FNA_V3) {
    const startSaleFromIpro = getStartSaleFrom(state) === 'SF'
    const isProductValidated = startSaleFromIpro
      ? getIsProductValidated(state) && productWasSelected(state)
      : productWasSelected(state)
    return proceedToProductSelection(state) && isProductValidated
  }
  return isCompletedMarketConducts(state) || canProceedToCoveragePlan(state) || hasVisitedInsuredInformationPage(state)
}

export const fnaTabs = (productRoutePath: BasicTabsPath, state: AppState) => {
  return [
    {
      title: TABS.NEED_AND_GAP_ANALYSIS,
      path: fnaPath(productRoutePath.NEED_AND_GAP_ANALYSIS),
      id: 'need',
      disabled: false,
      component: null,
    },

    {
      title: getToggles().ENABLE_FNA_V3 ? TABS.MARKET_CONDUCTS : TABS.PRODUCT_SELECTION,
      path: getToggles().ENABLE_FNA_V3
        ? fnaPath(productRoutePath.MARKET_CONDUCTS)
        : fnaPath(productRoutePath.PRODUCT_SELECTION),
      id: getToggles().ENABLE_FNA_V3 ? 'market-conduct' : 'product-selection',
      disabled: !needTypeWasSelected(state),
      component: null,
    },
    {
      title: getToggles().ENABLE_FNA_V3 ? TABS.PRODUCT_SELECTION : TABS.MARKET_CONDUCTS,
      path: getToggles().ENABLE_FNA_V3
        ? fnaPath(productRoutePath.PRODUCT_SELECTION)
        : fnaPath(productRoutePath.MARKET_CONDUCTS),
      id: getToggles().ENABLE_FNA_V3 ? 'product-selection' : 'market-conduct',
      disabled: getToggles().ENABLE_FNA_V3 ? !proceedToProductSelection(state) : !canProceedToMarketConducts(state),
      component: null,
    },
  ]
}

export const wholeLife = (state: AppState): NavTabConfig => {
  return {
    path: fnaPath(PATH.WHOLE_LIFE),
    tabs: fnaTabs(ROUTE_PATH.WHOLE_LIFE, state).concat([
      {
        title: TABS.INSURED_PERSON,
        path: fnaPath(ROUTE_PATH.WHOLE_LIFE.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: fnaPath(ROUTE_PATH.WHOLE_LIFE.COVERAGE_PLAN),
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: fnaPath(ROUTE_PATH.WHOLE_LIFE.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: !canProceedToBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ]),
  }
}

export const termLife = (state: AppState): NavTabConfig => {
  return {
    path: fnaPath(PATH.TERM_LIFE),
    tabs: fnaTabs(ROUTE_PATH.TERM_LIFE, state).concat([
      {
        title: TABS.INSURED_PERSON,
        path: fnaPath(ROUTE_PATH.TERM_LIFE.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: fnaPath(ROUTE_PATH.TERM_LIFE.COVERAGE_PLAN),
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: fnaPath(ROUTE_PATH.TERM_LIFE.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: !canProceedToBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ]),
  }
}

export const lifeReady = (state: AppState): NavTabConfig => {
  return {
    path: fnaPath(PATH.LIFE_READY),
    tabs: fnaTabs(ROUTE_PATH.LIFE_READY, state).concat([
      {
        title: TABS.INSURED_PERSON,
        path: fnaPath(ROUTE_PATH.LIFE_READY.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: fnaPath(ROUTE_PATH.LIFE_READY.COVERAGE_PLAN),
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: fnaPath(ROUTE_PATH.LIFE_READY.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: !canProceedToBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ]),
  }
}

export const mrta = (state: AppState): NavTabConfig => {
  return {
    path: fnaPath(PATH.MRTA),
    tabs: fnaTabs(ROUTE_PATH.MRTA, state).concat([
      {
        title: TABS.INSURED_PERSON,
        path: fnaPath(ROUTE_PATH.MRTA.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: fnaPath(ROUTE_PATH.MRTA.COVERAGE_PLAN),
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: fnaPath(ROUTE_PATH.MRTA.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: !canProceedToBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ]),
  }
}

export const pa = (state: AppState): NavTabConfig => {
  return {
    path: fnaPath(PATH.PA),
    tabs: fnaTabs(ROUTE_PATH.PA, state).concat([
      {
        title: TABS.INSURED_PERSON,
        path: fnaPath(ROUTE_PATH.PA.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: fnaPath(ROUTE_PATH.PA.COVERAGE_PLAN),
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: fnaPath(ROUTE_PATH.PA.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: !canProductPAProceedToBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ]),
  }
}

export const protection = (state: AppState): NavTabConfig => {
  return {
    path: fnaPath(PATH.PROTECTION),
    tabs: fnaTabs(ROUTE_PATH.PROTECTION, state).concat([
      {
        title: TABS.INSURED_PERSON,
        path: fnaPath(ROUTE_PATH.PROTECTION.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: fnaPath(ROUTE_PATH.PROTECTION.COVERAGE_PLAN),
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: fnaPath(ROUTE_PATH.PROTECTION.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: !canProceedToBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ]),
  }
}

export const flexiHealth = (state: AppState): NavTabConfig => {
  return {
    path: fnaPath(PATH.FLEXI_HEALTH),
    tabs: fnaTabs(ROUTE_PATH.FLEXI_HEALTH, state).concat([
      {
        title: TABS.INSURED_PERSON,
        path: fnaPath(ROUTE_PATH.FLEXI_HEALTH.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: fnaPath(ROUTE_PATH.FLEXI_HEALTH.COVERAGE_PLAN),
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: fnaPath(ROUTE_PATH.FLEXI_HEALTH.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: !canProceedToBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ]),
  }
}

export const healthTopping = (state: AppState): NavTabConfig => {
  return {
    path: fnaPath(PATH.HEALTH_TOPPING),
    tabs: [
      {
        title: TABS.NEED_AND_GAP_ANALYSIS,
        path: fnaPath(ROUTE_PATH.HEALTH_TOPPING.NEED_AND_GAP_ANALYSIS),
        id: 'need',
        disabled: false,
        component: null,
      },
      {
        title: getToggles().ENABLE_FNA_V3 ? TABS.MARKET_CONDUCTS : TABS.PRODUCT_SELECTION,
        path: getToggles().ENABLE_FNA_V3
          ? fnaPath(ROUTE_PATH.HEALTH_TOPPING.MARKET_CONDUCTS)
          : fnaPath(ROUTE_PATH.HEALTH_TOPPING.PRODUCT_SELECTION),
        id: getToggles().ENABLE_FNA_V3 ? 'market-conduct' : 'product-selection',
        disabled: !needTypeWasSelected(state),
        component: null,
      },
      {
        title: getToggles().ENABLE_FNA_V3 ? TABS.PRODUCT_SELECTION : TABS.MARKET_CONDUCTS,
        path: getToggles().ENABLE_FNA_V3
          ? fnaPath(ROUTE_PATH.HEALTH_TOPPING.PRODUCT_SELECTION)
          : fnaPath(ROUTE_PATH.HEALTH_TOPPING.MARKET_CONDUCTS),
        id: getToggles().ENABLE_FNA_V3 ? 'product-selection' : 'market-conduct',
        disabled: getToggles().ENABLE_FNA_V3 ? !proceedToProductSelection(state) : !canProceedToMarketConducts(state),
        component: null,
      },
      {
        title: TABS.INSURED_PERSON,
        path: fnaPath(ROUTE_PATH.HEALTH_TOPPING.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: fnaPath(ROUTE_PATH.HEALTH_TOPPING.COVERAGE_PLAN),
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: fnaPath(ROUTE_PATH.HEALTH_TOPPING.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: !canProceedToBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}
export const health = (state: AppState): NavTabConfig => {
  return {
    path: fnaPath(PATH.HEALTH),
    tabs: [
      {
        title: TABS.NEED_AND_GAP_ANALYSIS,
        path: fnaPath(ROUTE_PATH.HEALTH.NEED_AND_GAP_ANALYSIS),
        id: 'need',
        disabled: false,
        component: null,
      },
      {
        title: getToggles().ENABLE_FNA_V3 ? TABS.MARKET_CONDUCTS : TABS.PRODUCT_SELECTION,
        path: getToggles().ENABLE_FNA_V3
          ? fnaPath(ROUTE_PATH.HEALTH.MARKET_CONDUCTS)
          : fnaPath(ROUTE_PATH.HEALTH.PRODUCT_SELECTION),
        id: getToggles().ENABLE_FNA_V3 ? 'market-conduct' : 'product-selection',
        disabled: !needTypeWasSelected(state),
        component: null,
      },
      {
        title: getToggles().ENABLE_FNA_V3 ? TABS.PRODUCT_SELECTION : TABS.MARKET_CONDUCTS,
        path: getToggles().ENABLE_FNA_V3
          ? fnaPath(ROUTE_PATH.HEALTH.PRODUCT_SELECTION)
          : fnaPath(ROUTE_PATH.HEALTH.MARKET_CONDUCTS),
        id: getToggles().ENABLE_FNA_V3 ? 'product-selection' : 'market-conduct',
        disabled: getToggles().ENABLE_FNA_V3 ? !proceedToProductSelection(state) : !canProceedToMarketConducts(state),
        component: null,
      },
      {
        title: TABS.INSURED_PERSON,
        path: fnaPath(ROUTE_PATH.HEALTH.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: fnaPath(ROUTE_PATH.HEALTH.COVERAGE_PLAN),
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: fnaPath(ROUTE_PATH.HEALTH.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: !canProceedToBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}

export const ihealthyUltra = (state: AppState): NavTabConfig => {
  return {
    path: fnaPath(PATH.IHEALTHY_ULTRA),
    tabs: [
      {
        title: TABS.NEED_AND_GAP_ANALYSIS,
        path: fnaPath(ROUTE_PATH.IHEALTHY_ULTRA.NEED_AND_GAP_ANALYSIS),
        id: 'need',
        disabled: false,
        component: null,
      },
      {
        title: getToggles().ENABLE_FNA_V3 ? TABS.MARKET_CONDUCTS : TABS.PRODUCT_SELECTION,
        path: getToggles().ENABLE_FNA_V3
          ? fnaPath(ROUTE_PATH.IHEALTHY_ULTRA.MARKET_CONDUCTS)
          : fnaPath(ROUTE_PATH.IHEALTHY_ULTRA.PRODUCT_SELECTION),
        id: getToggles().ENABLE_FNA_V3 ? 'market-conduct' : 'product-selection',
        disabled: !needTypeWasSelected(state),
        component: null,
      },
      {
        title: getToggles().ENABLE_FNA_V3 ? TABS.PRODUCT_SELECTION : TABS.MARKET_CONDUCTS,
        path: getToggles().ENABLE_FNA_V3
          ? fnaPath(ROUTE_PATH.IHEALTHY_ULTRA.PRODUCT_SELECTION)
          : fnaPath(ROUTE_PATH.IHEALTHY_ULTRA.MARKET_CONDUCTS),
        id: getToggles().ENABLE_FNA_V3 ? 'product-selection' : 'market-conduct',
        disabled: getToggles().ENABLE_FNA_V3 ? !proceedToProductSelection(state) : !canProceedToMarketConducts(state),
        component: null,
      },
      {
        title: TABS.INSURED_PERSON,
        path: fnaPath(ROUTE_PATH.IHEALTHY_ULTRA.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: fnaPath(ROUTE_PATH.IHEALTHY_ULTRA.COVERAGE_PLAN),
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: fnaPath(ROUTE_PATH.IHEALTHY_ULTRA.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: !canProceedToBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}

export const investment = (state: AppState): NavTabConfig => {
  return {
    path: fnaPath(PATH.INVESTMENT),
    tabs: [
      {
        title: TABS.NEED_AND_GAP_ANALYSIS,
        path: fnaPath(ROUTE_PATH.INVESTMENT.NEED_AND_GAP_ANALYSIS),
        id: 'need',
        disabled: false,
        component: null,
      },
      {
        title: getToggles().ENABLE_FNA_V3 ? TABS.MARKET_CONDUCTS : TABS.PRODUCT_SELECTION,
        path: getToggles().ENABLE_FNA_V3
          ? fnaPath(ROUTE_PATH.INVESTMENT.MARKET_CONDUCTS)
          : fnaPath(ROUTE_PATH.INVESTMENT.PRODUCT_SELECTION),
        id: getToggles().ENABLE_FNA_V3 ? 'market-conduct' : 'product-selection',
        disabled: !needTypeWasSelected(state),
        component: null,
      },
      {
        title: getToggles().ENABLE_FNA_V3 ? TABS.PRODUCT_SELECTION : TABS.MARKET_CONDUCTS,
        path: getToggles().ENABLE_FNA_V3
          ? fnaPath(ROUTE_PATH.INVESTMENT.PRODUCT_SELECTION)
          : fnaPath(ROUTE_PATH.INVESTMENT.MARKET_CONDUCTS),
        id: getToggles().ENABLE_FNA_V3 ? 'product-selection' : 'market-conduct',
        disabled: getToggles().ENABLE_FNA_V3 ? !proceedToProductSelection(state) : !canProceedToMarketConducts(state),
        component: null,
      },
      {
        title: TABS.INSURED_PERSON,
        path: fnaPath(ROUTE_PATH.INVESTMENT.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.RISK,
        path: fnaPath(ROUTE_PATH.INVESTMENT.RISK_QUESTIONNAIRE),
        id: 'invest',
        disabled: !canProceedToRiskQuestionnaire(state),
        component: null,
      },
      {
        title: TABS.FUND,
        path: fnaPath(ROUTE_PATH.INVESTMENT.FUND_ALLOCATION),
        id: 'fund',
        disabled: !canProceedToFundAndCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.PREMIUM,
        path: fnaPath(ROUTE_PATH.INVESTMENT.COVERAGE_PLAN),
        id: 'premium',
        disabled: !canProceedToFundAndCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: fnaPath(ROUTE_PATH.INVESTMENT.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: !canProceedToFundAndCoveragePlan(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}

export const investmentV2 = (state: AppState): NavTabConfig => {
  return {
    path: fnaPath(PATH.INVESTMENT),
    tabs: [
      {
        title: TABS.NEED_AND_GAP_ANALYSIS,
        path: fnaPath(ROUTE_PATH.INVESTMENT.NEED_AND_GAP_ANALYSIS),
        id: 'need',
        disabled: false,
        component: null,
      },
      {
        title: getToggles().ENABLE_FNA_V3 ? TABS.MARKET_CONDUCTS : TABS.PRODUCT_SELECTION,
        path: getToggles().ENABLE_FNA_V3
          ? fnaPath(ROUTE_PATH.INVESTMENT.MARKET_CONDUCTS)
          : fnaPath(ROUTE_PATH.INVESTMENT.PRODUCT_SELECTION),
        id: getToggles().ENABLE_FNA_V3 ? 'market-conduct' : 'product-selection',
        disabled: !needTypeWasSelected(state),
        component: null,
      },
      {
        title: getToggles().ENABLE_FNA_V3 ? TABS.PRODUCT_SELECTION : TABS.MARKET_CONDUCTS,
        path: getToggles().ENABLE_FNA_V3
          ? fnaPath(ROUTE_PATH.INVESTMENT.PRODUCT_SELECTION)
          : fnaPath(ROUTE_PATH.INVESTMENT.MARKET_CONDUCTS),
        id: getToggles().ENABLE_FNA_V3 ? 'product-selection' : 'market-conduct',
        disabled: getToggles().ENABLE_FNA_V3 ? !proceedToProductSelection(state) : !canProceedToMarketConducts(state),
        component: null,
      },
      {
        title: TABS.INSURED_PERSON,
        path: fnaPath(ROUTE_PATH.INVESTMENT.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.PREMIUM,
        path: fnaPath(ROUTE_PATH.INVESTMENT.COVERAGE_PLAN),
        id: 'premium',
        disabled: !canProceedToPremiumCalculation(state),
        component: null,
      },
      {
        title: TABS.RISK,
        path: fnaPath(ROUTE_PATH.INVESTMENT.RISK_QUESTIONNAIRE),
        id: 'invest',
        disabled: !canProceedToRiskQuestionnaireV2(state),
        component: null,
      },
      {
        title: TABS.FUND,
        path: fnaPath(ROUTE_PATH.INVESTMENT.FUND_ALLOCATION),
        id: 'fund',
        disabled: !canProceedToRppFundAllocation(state) || blockSelectedFund(state),
        component: null,
      },
      {
        title: TABS.LSTU_FUND,
        path: fnaPath(ROUTE_PATH.INVESTMENT.LSTU_FUND_ALLOCATION),
        id: 'fund-lstu',
        disabled: !canProceedToLstuFundAllocation(state),
        component: null,
        hide: !hasFirstYearLstu(state),
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: fnaPath(ROUTE_PATH.INVESTMENT.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: !canProceedToInvestmentBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}

export const loan = (state: AppState): NavTabConfig => {
  return {
    path: fnaPath(PATH.LOAN),
    tabs: [
      {
        title: TABS.NEED_AND_GAP_ANALYSIS,
        path: fnaPath(ROUTE_PATH.LOAN.NEED_AND_GAP_ANALYSIS),
        id: 'need',
        disabled: false,
        component: null,
      },
      {
        title: getToggles().ENABLE_FNA_V3 ? TABS.MARKET_CONDUCTS : TABS.PRODUCT_SELECTION,
        path: getToggles().ENABLE_FNA_V3
          ? fnaPath(ROUTE_PATH.LOAN.MARKET_CONDUCTS)
          : fnaPath(ROUTE_PATH.LOAN.PRODUCT_SELECTION),
        id: getToggles().ENABLE_FNA_V3 ? 'market-conduct' : 'product-selection',
        disabled: !needTypeWasSelected(state),
        component: null,
      },
      {
        title: getToggles().ENABLE_FNA_V3 ? TABS.PRODUCT_SELECTION : TABS.MARKET_CONDUCTS,
        path: getToggles().ENABLE_FNA_V3
          ? fnaPath(ROUTE_PATH.LOAN.PRODUCT_SELECTION)
          : fnaPath(ROUTE_PATH.LOAN.MARKET_CONDUCTS),
        id: getToggles().ENABLE_FNA_V3 ? 'product-selection' : 'market-conduct',
        disabled: getToggles().ENABLE_FNA_V3 ? !proceedToProductSelection(state) : !canProceedToMarketConducts(state),
        component: null,
      },

      {
        title: TABS.INSURED_PERSON,
        path: fnaPath(ROUTE_PATH.LOAN.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: fnaPath(ROUTE_PATH.LOAN.COVERAGE_PLAN),
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: fnaPath(ROUTE_PATH.LOAN.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: false,
        component: null,
      },
    ],
  }
}

export const saving = (state: AppState): NavTabConfig => {
  return {
    path: fnaPath(PATH.SAVING),
    tabs: [
      {
        title: TABS.NEED_AND_GAP_ANALYSIS,
        path: fnaPath(ROUTE_PATH.SAVING.NEED_AND_GAP_ANALYSIS),
        id: 'need',
        disabled: false,
        component: null,
      },
      {
        title: getToggles().ENABLE_FNA_V3 ? TABS.MARKET_CONDUCTS : TABS.PRODUCT_SELECTION,
        path: getToggles().ENABLE_FNA_V3
          ? fnaPath(ROUTE_PATH.SAVING.MARKET_CONDUCTS)
          : fnaPath(ROUTE_PATH.SAVING.PRODUCT_SELECTION),
        id: getToggles().ENABLE_FNA_V3 ? 'market-conduct' : 'product-selection',
        disabled: !needTypeWasSelected(state),
        component: null,
      },
      {
        title: getToggles().ENABLE_FNA_V3 ? TABS.PRODUCT_SELECTION : TABS.MARKET_CONDUCTS,
        path: getToggles().ENABLE_FNA_V3
          ? fnaPath(ROUTE_PATH.SAVING.PRODUCT_SELECTION)
          : fnaPath(ROUTE_PATH.SAVING.MARKET_CONDUCTS),
        id: getToggles().ENABLE_FNA_V3 ? 'product-selection' : 'market-conduct',
        disabled: getToggles().ENABLE_FNA_V3 ? !proceedToProductSelection(state) : !canProceedToMarketConducts(state),
        component: null,
      },

      {
        title: TABS.INSURED_PERSON,
        path: fnaPath(ROUTE_PATH.SAVING.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: fnaPath(ROUTE_PATH.SAVING.COVERAGE_PLAN),
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: fnaPath(ROUTE_PATH.SAVING.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: !canProceedToBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}
export const lifeSavePro = (state: AppState): NavTabConfig => {
  return {
    path: fnaPath(PATH.LIFE_SAVE_PRO),
    tabs: [
      {
        title: TABS.NEED_AND_GAP_ANALYSIS,
        path: fnaPath(ROUTE_PATH.LIFE_SAVE_PRO.NEED_AND_GAP_ANALYSIS),
        id: 'need',
        disabled: false,
        component: null,
      },
      {
        title: getToggles().ENABLE_FNA_V3 ? TABS.MARKET_CONDUCTS : TABS.PRODUCT_SELECTION,
        path: getToggles().ENABLE_FNA_V3
          ? fnaPath(ROUTE_PATH.LIFE_SAVE_PRO.MARKET_CONDUCTS)
          : fnaPath(ROUTE_PATH.LIFE_SAVE_PRO.PRODUCT_SELECTION),
        id: getToggles().ENABLE_FNA_V3 ? 'market-conduct' : 'product-selection',
        disabled: !needTypeWasSelected(state),
        component: null,
      },
      {
        title: getToggles().ENABLE_FNA_V3 ? TABS.PRODUCT_SELECTION : TABS.MARKET_CONDUCTS,
        path: getToggles().ENABLE_FNA_V3
          ? fnaPath(ROUTE_PATH.LIFE_SAVE_PRO.PRODUCT_SELECTION)
          : fnaPath(ROUTE_PATH.LIFE_SAVE_PRO.MARKET_CONDUCTS),
        id: getToggles().ENABLE_FNA_V3 ? 'product-selection' : 'market-conduct',
        disabled: getToggles().ENABLE_FNA_V3 ? !proceedToProductSelection(state) : !canProceedToMarketConducts(state),
        component: null,
      },

      {
        title: TABS.INSURED_PERSON,
        path: fnaPath(ROUTE_PATH.LIFE_SAVE_PRO.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: fnaPath(ROUTE_PATH.LIFE_SAVE_PRO.COVERAGE_PLAN),
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: fnaPath(ROUTE_PATH.LIFE_SAVE_PRO.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: !canProceedToBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}

export const retirement = (state: AppState): NavTabConfig => {
  return {
    path: fnaPath(PATH.RETIREMENT),
    tabs: [
      {
        title: TABS.NEED_AND_GAP_ANALYSIS,
        path: fnaPath(ROUTE_PATH.RETIREMENT.NEED_AND_GAP_ANALYSIS),
        id: 'need',
        disabled: false,
        component: null,
      },
      {
        title: getToggles().ENABLE_FNA_V3 ? TABS.MARKET_CONDUCTS : TABS.PRODUCT_SELECTION,
        path: getToggles().ENABLE_FNA_V3
          ? fnaPath(ROUTE_PATH.RETIREMENT.MARKET_CONDUCTS)
          : fnaPath(ROUTE_PATH.RETIREMENT.PRODUCT_SELECTION),
        id: getToggles().ENABLE_FNA_V3 ? 'market-conduct' : 'product-selection',
        disabled: !needTypeWasSelected(state),
        component: null,
      },
      {
        title: getToggles().ENABLE_FNA_V3 ? TABS.PRODUCT_SELECTION : TABS.MARKET_CONDUCTS,
        path: getToggles().ENABLE_FNA_V3
          ? fnaPath(ROUTE_PATH.RETIREMENT.PRODUCT_SELECTION)
          : fnaPath(ROUTE_PATH.RETIREMENT.MARKET_CONDUCTS),
        id: getToggles().ENABLE_FNA_V3 ? 'product-selection' : 'market-conduct',
        disabled: getToggles().ENABLE_FNA_V3 ? !proceedToProductSelection(state) : !canProceedToMarketConducts(state),
        component: null,
      },
      {
        title: TABS.INSURED_PERSON,
        path: fnaPath(ROUTE_PATH.RETIREMENT.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: fnaPath(ROUTE_PATH.RETIREMENT.COVERAGE_PLAN),
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: fnaPath(ROUTE_PATH.RETIREMENT.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: !canProceedRetirementBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}

export const bumnanReady = (state: AppState): NavTabConfig => {
  return {
    path: fnaPath(PATH.BUMNAN_READY),
    tabs: [
      {
        title: TABS.NEED_AND_GAP_ANALYSIS,
        path: fnaPath(ROUTE_PATH.BUMNAN_READY.NEED_AND_GAP_ANALYSIS),
        id: 'need',
        disabled: false,
        component: null,
      },
      {
        title: getToggles().ENABLE_FNA_V3 ? TABS.MARKET_CONDUCTS : TABS.PRODUCT_SELECTION,
        path: getToggles().ENABLE_FNA_V3
          ? fnaPath(ROUTE_PATH.BUMNAN_READY.MARKET_CONDUCTS)
          : fnaPath(ROUTE_PATH.BUMNAN_READY.PRODUCT_SELECTION),
        id: getToggles().ENABLE_FNA_V3 ? 'market-conduct' : 'product-selection',
        disabled: !needTypeWasSelected(state),
        component: null,
      },
      {
        title: getToggles().ENABLE_FNA_V3 ? TABS.PRODUCT_SELECTION : TABS.MARKET_CONDUCTS,
        path: getToggles().ENABLE_FNA_V3
          ? fnaPath(ROUTE_PATH.BUMNAN_READY.PRODUCT_SELECTION)
          : fnaPath(ROUTE_PATH.BUMNAN_READY.MARKET_CONDUCTS),
        id: getToggles().ENABLE_FNA_V3 ? 'product-selection' : 'market-conduct',
        disabled: getToggles().ENABLE_FNA_V3 ? !proceedToProductSelection(state) : !canProceedToMarketConducts(state),
        component: null,
      },
      {
        title: TABS.INSURED_PERSON,
        path: fnaPath(ROUTE_PATH.BUMNAN_READY.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: fnaPath(ROUTE_PATH.BUMNAN_READY.COVERAGE_PLAN),
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: fnaPath(ROUTE_PATH.BUMNAN_READY.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: !canProceedBumnanReadyBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}

export const perfectLife = (state: AppState): NavTabConfig => {
  return {
    path: fnaPath(PATH.PERFECT_LIFE),
    tabs: [
      {
        title: TABS.NEED_AND_GAP_ANALYSIS,
        path: fnaPath(ROUTE_PATH.PERFECT_LIFE.NEED_AND_GAP_ANALYSIS),
        id: 'need',
        disabled: false,
        component: null,
      },
      {
        title: getToggles().ENABLE_FNA_V3 ? TABS.MARKET_CONDUCTS : TABS.PRODUCT_SELECTION,
        path: getToggles().ENABLE_FNA_V3
          ? fnaPath(ROUTE_PATH.PERFECT_LIFE.MARKET_CONDUCTS)
          : fnaPath(ROUTE_PATH.PERFECT_LIFE.PRODUCT_SELECTION),
        id: getToggles().ENABLE_FNA_V3 ? 'market-conduct' : 'product-selection',
        disabled: !needTypeWasSelected(state),
        component: null,
      },
      {
        title: getToggles().ENABLE_FNA_V3 ? TABS.PRODUCT_SELECTION : TABS.MARKET_CONDUCTS,
        path: getToggles().ENABLE_FNA_V3
          ? fnaPath(ROUTE_PATH.PERFECT_LIFE.PRODUCT_SELECTION)
          : fnaPath(ROUTE_PATH.PERFECT_LIFE.MARKET_CONDUCTS),
        id: getToggles().ENABLE_FNA_V3 ? 'product-selection' : 'market-conduct',
        disabled: getToggles().ENABLE_FNA_V3 ? !proceedToProductSelection(state) : !canProceedToMarketConducts(state),
        component: null,
      },

      {
        title: TABS.INSURED_PERSON,
        path: fnaPath(ROUTE_PATH.PERFECT_LIFE.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: fnaPath(ROUTE_PATH.PERFECT_LIFE.COVERAGE_PLAN),
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: fnaPath(ROUTE_PATH.PERFECT_LIFE.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: !canProceedPerfectLifeBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}

export const sukhapabDekDee = (state: AppState): NavTabConfig => {
  return {
    path: fnaPath(PATH.SUKHAPABDEKDEE),
    tabs: [
      {
        title: TABS.NEED_AND_GAP_ANALYSIS,
        path: fnaPath(ROUTE_PATH.SUKHAPABDEKDEE.NEED_AND_GAP_ANALYSIS),
        id: 'need',
        disabled: false,
        component: null,
      },
      {
        title: getToggles().ENABLE_FNA_V3 ? TABS.MARKET_CONDUCTS : TABS.PRODUCT_SELECTION,
        path: getToggles().ENABLE_FNA_V3
          ? fnaPath(ROUTE_PATH.SUKHAPABDEKDEE.MARKET_CONDUCTS)
          : fnaPath(ROUTE_PATH.SUKHAPABDEKDEE.PRODUCT_SELECTION),
        id: getToggles().ENABLE_FNA_V3 ? 'market-conduct' : 'product-selection',
        disabled: !needTypeWasSelected(state),
        component: null,
      },
      {
        title: getToggles().ENABLE_FNA_V3 ? TABS.PRODUCT_SELECTION : TABS.MARKET_CONDUCTS,
        path: getToggles().ENABLE_FNA_V3
          ? fnaPath(ROUTE_PATH.SUKHAPABDEKDEE.PRODUCT_SELECTION)
          : fnaPath(ROUTE_PATH.SUKHAPABDEKDEE.MARKET_CONDUCTS),
        id: getToggles().ENABLE_FNA_V3 ? 'product-selection' : 'market-conduct',
        disabled: getToggles().ENABLE_FNA_V3 ? !proceedToProductSelection(state) : !canProceedToMarketConducts(state),
        component: null,
      },
      {
        title: TABS.INSURED_PERSON,
        path: fnaPath(ROUTE_PATH.SUKHAPABDEKDEE.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: fnaPath(ROUTE_PATH.SUKHAPABDEKDEE.COVERAGE_PLAN),
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: fnaPath(ROUTE_PATH.SUKHAPABDEKDEE.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: !canProceedSukhapabDekDeeBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}

export const s7cc = (state: AppState): NavTabConfig => {
  return {
    path: fnaPath(PATH.S7CC),
    tabs: [
      {
        title: TABS.NEED_AND_GAP_ANALYSIS,
        path: fnaPath(ROUTE_PATH.S7CC.NEED_AND_GAP_ANALYSIS),
        id: 'need',
        component: null,
      },
      {
        title: getToggles().ENABLE_FNA_V3 ? TABS.MARKET_CONDUCTS : TABS.PRODUCT_SELECTION,
        path: getToggles().ENABLE_FNA_V3
          ? fnaPath(ROUTE_PATH.S7CC.MARKET_CONDUCTS)
          : fnaPath(ROUTE_PATH.S7CC.PRODUCT_SELECTION),
        id: getToggles().ENABLE_FNA_V3 ? 'market-conduct' : 'product-selection',
        disabled: !needTypeWasSelected(state),
        component: null,
      },
      {
        title: getToggles().ENABLE_FNA_V3 ? TABS.PRODUCT_SELECTION : TABS.MARKET_CONDUCTS,
        path: getToggles().ENABLE_FNA_V3
          ? fnaPath(ROUTE_PATH.S7CC.PRODUCT_SELECTION)
          : fnaPath(ROUTE_PATH.S7CC.MARKET_CONDUCTS),
        id: getToggles().ENABLE_FNA_V3 ? 'product-selection' : 'market-conduct',
        disabled: getToggles().ENABLE_FNA_V3 ? !proceedToProductSelection(state) : !canProceedToMarketConducts(state),
        component: null,
      },
      {
        title: TABS.MATURITY_VALIDATE,
        path: fnaPath(ROUTE_PATH.S7CC.MATURITY_VALIDATE),
        id: 'maturity-validate',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.SELECT_POLICY_OWNER,
        path: fnaPath(ROUTE_PATH.S7CC.SELECT_POLICY_OWNER),
        id: 'select-policy-owner',
        disabled: !canProceedToSelectPolicyOwner(state),
        component: null,
      },
      {
        title: TABS.INSURED_PERSON,
        path: fnaPath(ROUTE_PATH.S7CC.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToNormalTab(state) || !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: fnaPath(ROUTE_PATH.S7CC.COVERAGE_PLAN),
        id: 'calculate-premium',
        disabled: !canProceedToNormalTab(state) || !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: fnaPath(ROUTE_PATH.S7CC.BENEFIT_ILLUSTRATION),
        id: 'benefit-illustration',
        disabled: !canProceedToNormalTab(state) || !canProceedToBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}

export const fnaNeed = (state: AppState): NavTabConfig => {
  return {
    path: fnaPath(PATH.NEED_AND_GAP_ANALYSIS),
    tabs: [
      {
        title: TABS.NEED_AND_GAP_ANALYSIS,
        path: fnaPath(PATH.NEED_AND_GAP_ANALYSIS),
        id: 'need',
        disabled: false,
        component: null,
      },
      {
        title: getToggles().ENABLE_FNA_V3 ? TABS.MARKET_CONDUCTS : TABS.PRODUCT_SELECTION,
        path: getToggles().ENABLE_FNA_V3 ? fnaPath(PATH.MARKET_CONDUCTS) : fnaPath(PATH.PRODUCT_SELECTION),
        id: getToggles().ENABLE_FNA_V3 ? 'market-conduct' : 'product-selection',
        disabled: !needTypeWasSelected(state),
        component: null,
      },
      {
        title: getToggles().ENABLE_FNA_V3 ? TABS.PRODUCT_SELECTION : TABS.MARKET_CONDUCTS,
        path: getToggles().ENABLE_FNA_V3 ? fnaPath(PATH.PRODUCT_SELECTION) : '',
        id: getToggles().ENABLE_FNA_V3 ? 'product-selection' : 'market-conduct',
        disabled: getToggles().ENABLE_FNA_V3 ? !proceedToProductSelection(state) : true,
        component: null,
      },
      {
        title: TABS.INSURED_PERSON,
        path: '',
        id: 'insured-information',
        disabled: true,
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: '',
        id: 'calculate-premium',
        disabled: true,
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: '',
        id: 'benefit-illustration',
        disabled: true,
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}

export const fnaProductSelection = (state: AppState): NavTabConfig => {
  return {
    path: fnaPath(PATH.PRODUCT_SELECTION),
    tabs: [
      {
        title: TABS.NEED_AND_GAP_ANALYSIS,
        path: fnaPath(PATH.NEED_AND_GAP_ANALYSIS),
        id: 'need',
        disabled: false,
        component: null,
      },
      {
        title: getToggles().ENABLE_FNA_V3 ? TABS.MARKET_CONDUCTS : TABS.PRODUCT_SELECTION,
        path: getToggles().ENABLE_FNA_V3 ? fnaPath(PATH.MARKET_CONDUCTS) : fnaPath(PATH.PRODUCT_SELECTION),
        id: getToggles().ENABLE_FNA_V3 ? 'market-conduct' : 'product-selection',
        disabled: !needTypeWasSelected(state),
        component: null,
      },
      {
        title: getToggles().ENABLE_FNA_V3 ? TABS.PRODUCT_SELECTION : TABS.MARKET_CONDUCTS,
        path: getToggles().ENABLE_FNA_V3 ? fnaPath(PATH.PRODUCT_SELECTION) : '',
        id: getToggles().ENABLE_FNA_V3 ? 'product-selection' : 'market-conduct',
        disabled: getToggles().ENABLE_FNA_V3 ? !proceedToProductSelection(state) : true,
        component: null,
      },
      {
        title: TABS.INSURED_PERSON,
        path: fnaPath(PATH.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: '',
        id: 'calculate-premium',
        disabled: true,
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: '',
        id: 'benefit-illustration',
        disabled: true,
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}

export const marketConductsSelection = (state: AppState): NavTabConfig => {
  return {
    path: fnaPath(PATH.MARKET_CONDUCTS),
    tabs: [
      {
        title: TABS.NEED_AND_GAP_ANALYSIS,
        path: fnaPath(PATH.NEED_AND_GAP_ANALYSIS),
        id: 'need',
        disabled: false,
        component: null,
      },
      {
        title: getToggles().ENABLE_FNA_V3 ? TABS.MARKET_CONDUCTS : TABS.PRODUCT_SELECTION,
        path: getToggles().ENABLE_FNA_V3 ? fnaPath(PATH.MARKET_CONDUCTS) : fnaPath(PATH.PRODUCT_SELECTION),
        id: getToggles().ENABLE_FNA_V3 ? 'market-conduct' : 'product-selection',
        disabled: !needTypeWasSelected(state),
        component: null,
      },
      {
        title: getToggles().ENABLE_FNA_V3 ? TABS.PRODUCT_SELECTION : TABS.MARKET_CONDUCTS,
        path: getToggles().ENABLE_FNA_V3 ? fnaPath(PATH.PRODUCT_SELECTION) : '',
        id: getToggles().ENABLE_FNA_V3 ? 'product-selection' : 'market-conduct',
        disabled: getToggles().ENABLE_FNA_V3 ? !proceedToProductSelection(state) : true,
        component: null,
      },

      {
        title: TABS.INSURED_PERSON,
        path: fnaPath(PATH.INSURED_INFORMATION),
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: '',
        id: 'calculate-premium',
        disabled: true,
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: '',
        id: 'benefit-illustration',
        disabled: true,
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}

const martketAndFna = (state) => {
  if (getToggles().ENABLE_FNA_V3) {
    return [marketConductsSelection(state), fnaProductSelection(state)]
  }
  return [fnaProductSelection(state)]
}

export default (state: AppState): NavTabConfig[] => [
  wholeLife(state),
  lifeReady(state),
  investmentV2(state),
  loan(state),
  healthTopping(state),
  health(state),
  ihealthyUltra(state),
  saving(state),
  protection(state),
  lifeSavePro(state),
  flexiHealth(state),
  retirement(state),
  bumnanReady(state),
  perfectLife(state),
  s7cc(state),
  termLife(state),
  mrta(state),
  pa(state),
  fnaNeed(state),
  ...martketAndFna(state),
]
