// @flow

import type { AppState } from 'quick-quote/reducers'
import type { CoveragePlanDispatchers } from './components'
import { CoveragePlan } from './components'

import type { Dispatch } from 'redux'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { canProceedToCoveragePlan } from 'quick-quote/selectors/next-tab-proceeding'
import {
  getBasicPlanOptions,
  getiCareSelectedPlanCode,
  getDiscountStatus,
} from 'quick-quote/product-protection/coverage-plan/selectors'
import { toggleDiscount } from 'quick-quote/product-protection/coverage-plan/actions'
import { getAvailableRiderCodes, getBasicPlanErrorMessage } from 'quick-quote/product-common/coverage-plan/selectors'

import {
  toggleRider,
  editRiderSumAssured,
  editRiderSelectedPlan,
} from 'quick-quote/product-common/coverage-plan/actions'

import { updateSelectedBasicPlan } from 'quick-quote/product-health/coverage-plan/actions'

const mapStateToProps = (state: AppState) => ({
  riderCodes: getAvailableRiderCodes(state),
  canProceedToCoveragePlan: canProceedToCoveragePlan(state),
  basicPlanOptions: getBasicPlanOptions(state),
  selectedBasicPlanCode: getiCareSelectedPlanCode(state),
  isDiscountSelected: getDiscountStatus(state),
  basicPlanValidationError: getBasicPlanErrorMessage(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>): CoveragePlanDispatchers => ({
  toggleRider: bindActionCreators(toggleRider, dispatch),
  editRiderSumAssured: bindActionCreators(editRiderSumAssured, dispatch),
  editRiderSelectedPlan: bindActionCreators(editRiderSelectedPlan, dispatch),
  updateSelectedBasicPlan: bindActionCreators(updateSelectedBasicPlan, dispatch),
  toggleDiscount: bindActionCreators(toggleDiscount, dispatch),
})

export const CoveragePlanContainer = connect(mapStateToProps, mapDispatchToProps)(CoveragePlan)
