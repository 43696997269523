// @flow

import _ from 'lodash'
import Mustache from 'mustache'
import MESSAGES from 'core/data-model/constants/validation-messages'
import CONSTANTS from 'core/data-model/constants/messages'
import DEFAULTS from 'core/data-model/constants/defaults'
import VALUES from 'core/data-model/constants/values'
import type { DisplayProduct } from 'core/service/display-product'
import { isValidAge } from 'core/service/insured/birthdate'
import type { DisplayProductQuery } from 'core/service'
import {
  getDisplayProducts,
  getSelectedDisplayProduct,
  getSelectedDisplayProductQuery,
} from 'quick-quote/product-selection/selectors'
import { renderUnit } from 'core/service/insured/age'
import type { Age } from 'core/data-model/insured'
import type { Effect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import type { IHEALTHY_ULTRA_UpdateSelectedBasicPlan } from './actions'
import {
  IHEALTHY_ULTRA_SELECT_BASIC_PLAN,
  selectBasicPlan,
  IHEALTHY_ULTRA_UPDATE_SELECTED_BASIC_PLAN,
  VALIDATE_BASIC_PLAN,
  validateBasicPlan,
} from './actions'
import { calculateCoveragePlanFromBasicPlanChange } from 'core/service/basic-plan/basic-plan'
import {
  getAge,
  getAllNatureOfDutyCodes,
  getInsured,
  getPayerAge,
  getPayerGender,
} from 'quick-quote/insured-information/selectors'
import { getSelectedModelFactorID } from './selectors'
import { getAvailableRiders } from 'quick-quote/product-common/coverage-plan/selectors'
import { updateCoveragePlanState } from 'quick-quote/product-common/coverage-plan/sagas'
import { updateBasicPlanErrorMessage } from 'quick-quote/product-common/coverage-plan/actions'
import { UPDATE_BIRTHDATE } from 'quick-quote/insured-information/actions'
import {
  getLoanPeriod,
  getCoveragePeriod,
  getLoanAmount,
  getLoan,
  getInterestRateValue,
} from 'quick-quote/product-mrta/coverage-plan/selectors'

const getProductFromDefaultListIHealthyUltraProduct = (basicPlanCode) => {
  return _.find(DEFAULTS.DEFAULT_IHEALTHY_ULTRA_PRODUCT_LIST, { code: basicPlanCode })
}

const validateProductMultiBasicPlanCode = (basicPlanCode, insureAge) => {
  if (VALUES.IHEALTHY_ULTRA_LIST_PRODUCT_MULTI_PLANCODE.includes(basicPlanCode)) {
    const product = getProductFromDefaultListIHealthyUltraProduct(basicPlanCode)
    const productSelected = DEFAULTS.DEFAULT_IHEALTHY_ULTRA_PRODUCT_LIST.filter(
      (p) =>
        p.productName === product.productName &&
        insureAge.value >= p.entryAge.minimum.value &&
        insureAge.value <= p.entryAge.maximum.value
    )
    if (!!productSelected.length) {
      return productSelected[0].code
    } else {
      return basicPlanCode
    }
  } else {
    return basicPlanCode
  }
}

export const onUpdateSelectedBasicPlan = function*(action: IHEALTHY_ULTRA_UpdateSelectedBasicPlan): Generator<*, *, *> {
  // const basicPlanCode = action.payload
  const insureAge = yield select(getAge)
  const displayProducts = yield select(getDisplayProducts)
  const basicPlanCode = validateProductMultiBasicPlanCode(action.payload, insureAge)
  const productSelected = _.find(displayProducts, { code: basicPlanCode })
  if (!!productSelected) {
    yield put(selectBasicPlan(productSelected))
  }
}

export const generateCoveragePlanFromBasicPlanChange = function*(
  calculateCoveragePlanFn: Function
): Generator<*, *, *> {
  const displayProductQuery: DisplayProductQuery = yield select(getSelectedDisplayProductQuery)
  const natureOfDutyCodes: string[] = yield select(getAllNatureOfDutyCodes)
  const insured = yield select(getInsured)
  const modelFactorID = yield select(getSelectedModelFactorID)
  const availableRiders = yield select(getAvailableRiders)
  const payerAge = yield select(getPayerAge)
  const payerGender = yield select(getPayerGender)
  const loanPeriod = yield select(getLoanPeriod)
  const loanAmount = yield select(getLoanAmount)
  const coveragePeriod = yield select(getCoveragePeriod)
  const loan = yield select(getLoan)
  const interestRate = yield select(getInterestRateValue)
  const newValue = yield call(calculateCoveragePlanFn, {
    displayProductQuery,
    insured,
    natureOfDutyCodes,
    modelFactorID,
    availableRiders,
    payerAge,
    payerGender,
    loanPeriod,
    coveragePeriod,
    loanAmount,
    loan,
    interestRate,
  })
  yield call(updateCoveragePlanState, newValue)
  yield put(validateBasicPlan())
}

const getValidationMessage = (displayProduct: DisplayProduct, age: Age): string => {
  const { entryAge } = displayProduct
  if (!entryAge || isValidAge(age.value, age.unit, entryAge)) {
    return ''
  }
  return Mustache.render(MESSAGES.RULE_AGE, {
    owner: CONSTANTS.INSURED_PERSON,
    minimumAge: `${entryAge.minimum.value} ${renderUnit(entryAge.minimum.unit)}`,
    // minimumAge: `${_checkRenderMinAge(code, entryAge.minimum.value)} ${renderUnit(entryAge.minimum.unit)}`,
    maximumAge: `${entryAge.maximum.value} ${renderUnit(entryAge.maximum.unit)}`,
  })
}

export const onValidateBasicPlan = function*(): Generator<*, *, *> {
  const selectedDisplayProduct: DisplayProduct = yield select(getSelectedDisplayProduct)
  if (selectedDisplayProduct.category !== VALUES.IHEALTHY_ULTRA) {
    return
  }
  const age: Age = yield select(getAge)
  const message = yield call(getValidationMessage, selectedDisplayProduct, age)
  return yield put(updateBasicPlanErrorMessage(message))
}

export function* sagas(): Generator<*, *, Effect[]> {
  yield [
    takeLatest(IHEALTHY_ULTRA_UPDATE_SELECTED_BASIC_PLAN, onUpdateSelectedBasicPlan),
    takeLatest(
      IHEALTHY_ULTRA_SELECT_BASIC_PLAN,
      generateCoveragePlanFromBasicPlanChange,
      calculateCoveragePlanFromBasicPlanChange
    ),
    takeLatest(VALIDATE_BASIC_PLAN, onValidateBasicPlan),
    takeLatest(UPDATE_BIRTHDATE, onValidateBasicPlan),
  ]
}
