//@flow
import { set } from 'lodash/fp'
import type { Action as UiActions } from './actions'
import { VISIT_PATH, SAVE_IMAGE, SET_LOADING_STATUS, SET_ADDING_INVEST_QUESTION_NONILP_NEED_STATUS } from './actions'
import { RESET_QUOTE_FORM } from '../actions'
import {
  VALIDATE_NORMAL_PRODUCT,
  VALIDATE_ORDINARY_PRODUCT,
  VALIDATE_INVESTMENT_PRODUCT,
} from 'quick-quote/product-selection/actions'

type Action = UiActions

type UIState = {
  visited: { [key: string]: boolean },
  images: { [key: string]: string },
  isLoading: boolean,
}

export const DEFAULT_STATE: UIState = { visited: {} }

export const reducer = (state: UIState = DEFAULT_STATE, action: Action) => {
  switch (action.type) {
    case VISIT_PATH:
      return set(`visited.${action.payload}`, true)(state)
    case SAVE_IMAGE:
      return set(`images.${action.payload.name}`, action.payload.image)(state)
    case RESET_QUOTE_FORM:
      return DEFAULT_STATE
    case SET_LOADING_STATUS:
      state.isLoading = action.payload
      return state
    case SET_ADDING_INVEST_QUESTION_NONILP_NEED_STATUS:
      state.isAddingInvestmentQuestionForNonILPNeedType = action.payload
      return state
    case VALIDATE_NORMAL_PRODUCT:
    case VALIDATE_ORDINARY_PRODUCT:
    case VALIDATE_INVESTMENT_PRODUCT:
      state.isValidateProduct = true
      return state
    default:
      return state
  }
}

// export const isAddingInvestmentQuestionForNonILPNeedType = (state = false, action) => {
//   switch (action.type) {
//     case 'SET_ADDING_INVEST_QUESTION_NONILP_NEED':
//       return true
//     case 'RESET_ADDING_INVEST_QUESTION_NONILP_NEED':
//       return false
//     default:
//       return state
//   }
// }
