import { ThemeProvider, css } from 'styled-components'
import { global as variables } from '!sass-extract-loader?{"plugins":["minimal"]}!assets/styles/_variables.scss'

const mediaSize = {
  sm: 36,
  md: 48,
  lg: 62,
  xl: 75,
}

// iterate through the sizes and create a media template
export const media = Object.keys(mediaSize).reduce((accumulator, label) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const emSize = mediaSize[label]
  accumulator[label] = (...args) => css`
    @media (min-width: ${emSize}em) {
      ${css(...args)};
    }
  `
  return accumulator
}, {})

export const defaultTheme = {
  variables: {
    ...variables,
    sm: `${mediaSize.sm}em`,
    md: `${mediaSize.md}em`,
    lg: `${mediaSize.lg}em`,
    xl: `${mediaSize.xl}em`,
  },
  font: 'inherit',
  media,
  breakpoints: [mediaSize.sm, mediaSize.md, mediaSize.lg],
  space: [0, 11, 22],
  fontSizes: [12, 16, 18, 24, 36, 48, 72],
  weights: [400, 600],
}

const DefaultTheme = ({ children }) => <ThemeProvider theme={defaultTheme}>{children}</ThemeProvider>

export default DefaultTheme
