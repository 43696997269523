// @flow
import _ from 'lodash'
import riderMessages from 'core/data-model/constants/validation-messages'
import type { SumAssuredTiersRule } from 'core/data-model/rider/rules'
import type { RiderEligibilities } from 'core/data-model/rider'
import type { ValidationMessage } from 'core/data-model/validation'

const setMessageRiderAndBasicSumAssuredRule = (
  rule: SumAssuredTiersRule,
  eligibilities: RiderEligibilities
): ValidationMessage => {
  let messageId = `RULE_RIDER_${eligibilities.rider.code}_SUM_ASSURED`
  return _.get(riderMessages, messageId) || riderMessages.RULE_MESSAGE_NOT_FOUND
}

export const checkRiderAndBasicSumAssuredRule = (
  rule: SumAssuredTiersRule,
  eligibilities: RiderEligibilities
): ValidationMessage => {
  let errorMessage = ''

  const isNotEligible =
    eligibilities.rider.sumAssured < rule.minimumValue || eligibilities.rider.sumAssured > rule.maximumValue

  const isNotEligibleWithBasicSumAssured =
    eligibilities.rider.sumAssured < rule.minimumValue ||
    eligibilities.rider.sumAssured > rule.maximumValue ||
    eligibilities.rider.sumAssured > eligibilities.basicSumAssured

  if (eligibilities.basicSumAssured > 0 && eligibilities.basicSumAssured < rule.minimumValue) {
    errorMessage = setMessageRiderAndBasicSumAssuredRule(rule, eligibilities)
  }

  if (isNotEligibleWithBasicSumAssured && eligibilities.basicSumAssured > 0) {
    errorMessage = setMessageRiderAndBasicSumAssuredRule(rule, eligibilities)
  } else if (isNotEligible && eligibilities.basicSumAssured === 0) {
    errorMessage = setMessageRiderAndBasicSumAssuredRule(rule, eligibilities)
  }

  return {
    type: rule.validationType,
    message: errorMessage,
  }
}
