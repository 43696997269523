// @flow

import type { AppState } from 'quick-quote/reducers'
import type { CoveragePlanDispatchers } from './components'
import { CoveragePlan } from './components'

import type { Dispatch } from 'redux'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { canProceedToCoveragePlan } from 'quick-quote/selectors/next-tab-proceeding'
import { getAvailableRiderCodes } from 'quick-quote/product-common/coverage-plan/selectors'

import {
  toggleRider,
  editRiderSumAssured,
  editRiderSelectedPlan,
} from 'quick-quote/product-common/coverage-plan/actions'
import { getSelectedDisplayProductCode } from 'quick-quote/product-selection/selectors'

const mapStateToProps = (state: AppState) => ({
  riderCodes: getAvailableRiderCodes(state),
  canProceedToCoveragePlan: canProceedToCoveragePlan(state),
  infoMessage: '',
  productCode: getSelectedDisplayProductCode(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>): CoveragePlanDispatchers => ({
  toggleRider: bindActionCreators(toggleRider, dispatch),
  editRiderSumAssured: bindActionCreators(editRiderSumAssured, dispatch),
  editRiderSelectedPlan: bindActionCreators(editRiderSelectedPlan, dispatch),
})

export const CoveragePlanContainer = connect(mapStateToProps, mapDispatchToProps)(CoveragePlan)
