// @flow

import type { ModelFactor, SumAssured$, TotalPremium$ } from 'core/data-model/basic-plan'
import _ from 'lodash'
import type {
  Rider,
  RiderCode,
  RiderModuleCode,
  RiderModuleGroupCode,
  RiderPlan,
  RiderState,
} from 'core/data-model/rider'
import type { SelectProduct } from 'quick-quote/product-selection/actions'
// ==== action types ====

export type Action =
  | SelectModelFactor
  | EditTotalPremium
  | UpdateTotalPremium
  | EditSumAssured
  | EditBasicPremium
  | EditBasicPremiumState
  | SetModelFactors
  | ToggleRider
  | ToggleRiderModule
  | EditRiderSumAssured
  | EditRiderSelectedPlan
  | EditRiderPremium
  | UpdateBasicPremium
  | UpdateAvailableRiders
  | UpdateRidersBenefits
  | SelectProduct
  | UpdateTotalRiderPremium
  | UpdateBasicPlanErrorMessage
  | EditRiderModulePremium
  | UpdateSelectedPlan
  | EditLoanAmount

export const EDIT_TOTAL_PREMIUM = 'EDIT_TOTAL_PREMIUM'
export type EditTotalPremium = {
  type: 'EDIT_TOTAL_PREMIUM',
  payload: number,
}

export const UPDATE_TOTAL_PREMIUM = 'UPDATE_TOTAL_PREMIUM'
export type UpdateTotalPremium = {
  type: 'UPDATE_TOTAL_PREMIUM',
  payload: ?TotalPremium$,
}

export const UPDATE_SELECTED_PLAN = 'UPDATE_SELECTED_PLAN'
export type UpdateSelectedPlan = {
  type: 'UPDATE_SELECTED_PLAN',
  payload: string,
}

export const UPDATE_TOTAL_RIDER_PREMIUM = 'UPDATE_TOTAL_RIDER_PREMIUM'
export type UpdateTotalRiderPremium = {
  type: 'UPDATE_TOTAL_RIDER_PREMIUM',
  payload: number,
}

export const TOGGLE_RIDER = 'TOGGLE_RIDER'
export type ToggleRider = {
  type: 'TOGGLE_RIDER',
  payload: { riderCode: RiderCode, newState: boolean },
}

export const TOGGLE_RIDER_MODULE = 'TOGGLE_RIDER_MODULE'
export type ToggleRiderModule = {
  type: 'TOGGLE_RIDER_MODULE',
  payload: {
    riderCode: RiderCode,
    riderModuleCode: RiderModuleCode,
    newState: boolean,
  },
}

export const TOGGLE_RIDER_GROUP_MODULE = 'TOGGLE_RIDER_GROUP_MODULE'
export type ToggleRiderGroupModule = {
  type: 'TOGGLE_RIDER_GROUP_MODULE',
  payload: {
    riderCode: RiderCode,
    riderModuleGroupCode: RiderModuleGroupCode,
    newState: boolean,
  },
}

export const UPDATE_AVAILABLE_RIDERS = 'UPDATE_AVAILABLE_RIDERS'
export type UpdateAvailableRiders = {
  type: 'UPDATE_AVAILABLE_RIDERS',
  payload: { [string]: Rider & RiderState },
}

export const UPDATE_RIDERS_BENEFITS = 'UPDATE_RIDERS_BENEFITS'
export type UpdateRidersBenefits = {
  type: 'UPDATE_RIDERS_BENEFITS',
  payload: (Rider & RiderState)[],
}

export const SELECT_MODEL_FACTOR = 'SELECT_MODEL_FACTOR'
export type SelectModelFactor = {
  type: 'SELECT_MODEL_FACTOR',
  payload: string,
}

export const EDIT_SUM_ASSURED = 'EDIT_SUM_ASSURED'
export type EditSumAssured = {
  type: 'EDIT_SUM_ASSURED',
  payload: number,
}

export const EDIT_LOAN_AMOUNT = 'EDIT_LOAN_AMOUNT'
export type EditLoanAmount = {
  type: 'EDIT_LOAN_AMOUNT',
  payload: number,
}

export const UPDATE_SUM_ASSURED$ = 'UPDATE_SUM_ASSURED$'
export type UpdateSumAssured$ = {
  type: 'UPDATE_SUM_ASSURED$',
  payload: SumAssured$,
}

export const EDIT_BASIC_PREMIUM = 'EDIT_BASIC_PREMIUM'
export type EditBasicPremium = {
  type: 'EDIT_BASIC_PREMIUM',
  payload: number,
}

export const EDIT_BASIC_PREMIUM_STATE = 'EDIT_BASIC_PREMIUM_STATE'
export type EditBasicPremiumState = {
  type: 'EDIT_BASIC_PREMIUM_STATE',
  payload: number,
}

export const EDIT_PENSION_TYPE_STATE = 'EDIT_PENSION_TYPE_STATE'
export type EditPensionType = {
  type: 'EDIT_PENSION_TYPE_STATE',
  payload: string,
}

export const UPDATE_BASIC_PREMIUM = 'UPDATE_BASIC_PREMIUM'
export type UpdateBasicPremium = {
  type: 'UPDATE_BASIC_PREMIUM',
  payload: number,
}
export const UPDATE_FULL_BASIC_PREMIUM = 'UPDATE_FULL_BASIC_PREMIUM'
export type UpdateFullBasicPremium = {
  type: 'UPDATE_FULL_BASIC_PREMIUM',
  payload: number,
}

export const UPDATE_FULL_ANNUAL_BASIC_PREMIUM = 'UPDATE_FULL_ANNUAL_BASIC_PREMIUM'
export type UpdateFullAnnualBasicPremium = {
  type: 'UPDATE_FULL_ANNUAL_BASIC_PREMIUM',
  payload: number,
}

export const UPDATE_CAMPAIGN_DISCOUNT_MESSAGE = 'UPDATE_CAMPAIGN_DISCOUNT_MESSAGE'
export type UpdateCampaignDiscountMessage = {
  type: 'UPDATE_CAMPAIGN_DISCOUNT_MESSAGE',
  payload: string[],
}

export const UPDATE_CAMPAIGN_DISCOUNT_ENDDATE = 'UPDATE_CAMPAIGN_DISCOUNT_ENDDATE'
export type UpdateCampaignDiscountEndDate = {
  type: 'UPDATE_CAMPAIGN_DISCOUNT_ENDDATE',
  payload: string,
}

export const SET_MODEL_FACTORS = 'SET_MODEL_FACTORS'
export type SetModelFactors = {
  type: 'SET_MODEL_FACTORS',
  payload: ModelFactor[],
}

export const EDIT_RIDER_SUM_ASSURED = 'EDIT_RIDER_SUM_ASSURED'
export type EditRiderSumAssured = {
  type: 'EDIT_RIDER_SUM_ASSURED',
  payload: { code: string, sumAssured: number },
}

export const EDIT_RIDER_SELECTED_PLAN = 'EDIT_RIDER_SELECTED_PLAN'
export type EditRiderSelectedPlan = {
  type: 'EDIT_RIDER_SELECTED_PLAN',
  payload: { code: string, selectedPlan: RiderPlan },
}

export const EDIT_RIDER_PREMIUM = 'EDIT_RIDER_PREMIUM'
export type EditRiderPremium = {
  type: 'EDIT_RIDER_PREMIUM',
  payload: { code: string, premium: number },
}

export const EDIT_RIDER_MODULE_PREMIUM = 'EDIT_RIDER_MODULE_PREMIUM'
export type EditRiderModulePremium = {
  type: 'EDIT_RIDER_MODULE_PREMIUM',
  payload: {
    riderCode: RiderCode,
    riderModuleCode: RiderModuleCode,
    premium: number,
  },
}

// ==== action creators ====
export const updateTotalPremium = (totalPremium: ?TotalPremium$): UpdateTotalPremium => ({
  type: UPDATE_TOTAL_PREMIUM,
  payload: totalPremium,
})

export const updateTotalRiderPremium = (totalRiderPremium: number): UpdateTotalRiderPremium => ({
  type: UPDATE_TOTAL_RIDER_PREMIUM,
  payload: totalRiderPremium,
})

export const editTotalPremium = (totalPremium: number): EditTotalPremium => ({
  type: EDIT_TOTAL_PREMIUM,
  payload: _.toFinite(totalPremium),
})

export const toggleRider = (code: RiderCode, newState: boolean): ToggleRider => ({
  type: TOGGLE_RIDER,
  payload: { riderCode: code, newState },
})

export const toggleRiderModule = (
  riderCode: RiderCode,
  riderModuleCode: RiderModuleCode,
  newState: boolean
): ToggleRiderModule => ({
  type: TOGGLE_RIDER_MODULE,
  payload: { riderCode, riderModuleCode, newState },
})

export const toggleRiderGroupModule = (
  riderCode: RiderCode,
  riderModuleGroupCode: RiderModuleGroupCode,
  newState: boolean
): ToggleRiderGroupModule => ({
  type: TOGGLE_RIDER_GROUP_MODULE,
  payload: { riderCode, riderModuleGroupCode, newState },
})

export const updateAvailableRiders = (riders: { [string]: Rider & RiderState }): UpdateAvailableRiders => ({
  type: UPDATE_AVAILABLE_RIDERS,
  payload: riders,
})

export const updateRidersBenefits = (riders: (Rider & RiderState)[]): UpdateRidersBenefits => ({
  type: UPDATE_RIDERS_BENEFITS,
  payload: riders,
})

export const updateSelectedPlan = (selectedPlan: string): UpdateSelectedPlan => ({
  type: UPDATE_SELECTED_PLAN,
  payload: selectedPlan,
})

export const editRiderSumAssured = (code: RiderCode, sumAssured: number): EditRiderSumAssured => ({
  type: EDIT_RIDER_SUM_ASSURED,
  payload: { code, sumAssured },
})

export const editRiderSelectedPlan = (code: RiderCode, selectedPlan: RiderPlan): EditRiderSelectedPlan => ({
  type: EDIT_RIDER_SELECTED_PLAN,
  payload: { code, selectedPlan },
})
export const editRiderPremium = (code: RiderCode, premium: number): EditRiderPremium => ({
  type: EDIT_RIDER_PREMIUM,
  payload: { code, premium },
})
export const editRiderModulePremium = (riderCode: RiderCode, riderModuleCode: RiderModuleCode, premium: number) => ({
  type: EDIT_RIDER_MODULE_PREMIUM,
  payload: { riderCode, riderModuleCode, premium },
})
export const selectModelFactor = (modelFactorID: string): SelectModelFactor => ({
  type: SELECT_MODEL_FACTOR,
  payload: modelFactorID,
})

export const editSumAssured = (sumAssured: ?number): EditSumAssured => ({
  type: EDIT_SUM_ASSURED,
  payload: _.toFinite(sumAssured),
})

export const editLoanAmount = (loanAmount: ?number): EditLoanAmount => ({
  type: EDIT_LOAN_AMOUNT,
  payload: _.toFinite(loanAmount),
})

export const updateSumAssured = (sumAssured: SumAssured$): UpdateSumAssured$ => ({
  type: UPDATE_SUM_ASSURED$,
  payload: sumAssured,
})

export const editBasicPremium = (basicPremium: number): EditBasicPremium => ({
  type: EDIT_BASIC_PREMIUM,
  payload: _.toFinite(basicPremium),
})

export const editBasicPremiumState = (basicPremium: number): EditBasicPremiumState => ({
  type: EDIT_BASIC_PREMIUM_STATE,
  payload: _.toFinite(basicPremium),
})

export const editPensionType = (pensionType: string): EditPensionType => ({
  type: EDIT_PENSION_TYPE_STATE,
  payload: pensionType,
})

export const updateBasicPremium = (basicPremium: number): UpdateBasicPremium => ({
  type: UPDATE_BASIC_PREMIUM,
  payload: _.toFinite(basicPremium),
})

export const updateFullBasicPremium = (fullBasicPremium: number): UpdateFullBasicPremium => ({
  type: UPDATE_FULL_BASIC_PREMIUM,
  payload: _.toFinite(fullBasicPremium),
})

export const updatFullAnnualBasicPremium = (fullAnnualBasicPremium: number): UpdatFullAnnualBasicPremium => ({
  type: UPDATE_FULL_ANNUAL_BASIC_PREMIUM,
  payload: _.toFinite(fullAnnualBasicPremium),
})

export const updateCampaignDiscountMessage = (campaignDiscountMessage: string[]): UpdateCampaignDiscountMessage => ({
  type: UPDATE_CAMPAIGN_DISCOUNT_MESSAGE,
  payload: campaignDiscountMessage,
})

export const updateCampaignDiscountEndDate = (campaignDiscountEndDate: string): UpdateCampaignDiscountEndDate => ({
  type: UPDATE_CAMPAIGN_DISCOUNT_ENDDATE,
  payload: campaignDiscountEndDate,
})

export const setModelFactors = (modelFactors: ModelFactor[]): SetModelFactors => ({
  type: SET_MODEL_FACTORS,
  payload: modelFactors,
})

export const UPDATE_BASIC_PLAN_ERROR_MESSAGE = 'UPDATE_BASIC_PLAN_ERROR_MESSAGE'
export type UpdateBasicPlanErrorMessage = {
  type: 'UPDATE_BASIC_PLAN_ERROR_MESSAGE',
  payload: string,
}
export const updateBasicPlanErrorMessage = (errorMessage: string): UpdateBasicPlanErrorMessage => ({
  type: UPDATE_BASIC_PLAN_ERROR_MESSAGE,
  payload: errorMessage,
})
