// @flow
import type { Option } from 'common-components/dropdown'
import type { AppState } from 'quick-quote/reducers'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Dropdown } from 'common-components'
import { FormGroup } from 'reactstrap'
import { getSukhapabDekDeeOptionsPlans, getSelectCampaignPlan } from '../selectors'
import { selectSukhapabDekDeePlan } from '../../actions'

export type BasicPlanSelectionProps = {
  sukhapabDekDeeOptionsPlans: Option[],
  selectedSukhapabDekDeePlans: string,
  selectSukhapabDekDeePlan: (planCode: string) => void,
}

const BasicPlanSelection = ({
  sukhapabDekDeeOptionsPlans,
  selectedSukhapabDekDeePlans,
  selectSukhapabDekDeePlan,
}: BasicPlanSelectionProps) => {
  return (
    <FormGroup className={'basic-plan-selection item basic'}>
      <div className="form-group-title">
        <span className="form-group-title-text">สัญญาหลัก</span>
      </div>
      <div className="options option-labels">
        <label>เลือกแผน</label>
      </div>
      <Dropdown
        className="basic-plan form-control"
        options={sukhapabDekDeeOptionsPlans}
        onChange={(planCode) => {
          selectSukhapabDekDeePlan(planCode)
        }}
        value={selectedSukhapabDekDeePlans}
      />
    </FormGroup>
  )
}

const mapStateToProps = (state: AppState) => ({
  sukhapabDekDeeOptionsPlans: getSukhapabDekDeeOptionsPlans(state),
  selectedSukhapabDekDeePlans: getSelectCampaignPlan(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  selectSukhapabDekDeePlan: bindActionCreators(selectSukhapabDekDeePlan, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(BasicPlanSelection)
