//@flow

import MESSAGES from 'core/data-model/constants/bi-messages'

const TOP_MARGIN = -10

export const basicPlanConditionSection = () => ({
  stack: [
    {
      text: MESSAGES.CONDITION_TITLE,
      style: 'title',
      pageBreak: 'before',
    },
    {
      text: MESSAGES.COMPLETED_TITLE,
      style: 'sub-title',
    },
    {
      text: MESSAGES.COMPLETED_MESSAGE,
      style: 'textRow',
      marginTop: TOP_MARGIN,
    },
    {
      text: MESSAGES.NO_COVER_TITLE,
      style: 'sub-title',
    },
    {
      ol: [
        {
          text: MESSAGES.NO_COVER_MESSAGE_ONE,
        },
        {
          text: MESSAGES.NO_COVER_MESSAGE_TWO,
        },
      ],
      style: 'textRow',
      marginTop: TOP_MARGIN,
    },
    {
      text: MESSAGES.CLAIM_TITLE,
      style: 'sub-title',
    },
    {
      text: MESSAGES.CLAIM_MESSAGE,
      style: 'textRow',
      marginTop: TOP_MARGIN,
    },
    {
      text: MESSAGES.FREELOOK_TITLE,
      style: 'sub-title',
    },
    {
      text: MESSAGES.FREELOOK_MESSAGE,
      style: 'textRow',
      marginTop: TOP_MARGIN,
    },
    {
      text: MESSAGES.IMPACT_INSURED_TITLE,
      style: 'sub-title',
    },
    {
      text: MESSAGES.IMPACT_INSURED_MESSAGE,
      style: 'textRow',
      marginTop: TOP_MARGIN,
    },
    {
      text: MESSAGES.DELIVERY_PERIOD_TITLE,
      style: 'sub-title',
    },
    {
      text: MESSAGES.DELIVERY_PERIOD_MESSAGE,
      style: 'textRow',
      marginTop: TOP_MARGIN,
    },
    {
      text: MESSAGES.CHANNEL_TITLE,
      style: 'sub-title',
    },
    {
      text: MESSAGES.CHANNEL_MESSAGE,
      style: 'textRow',
      marginTop: TOP_MARGIN,
    },
  ],
  style: 'groupRow',
})
