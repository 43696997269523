//@flow
import type { AppState } from 'quick-quote/reducers'
import type { Tab } from 'quick-quote/components/navigation-item'

import { connect } from 'react-redux'
import { isArray } from 'core/service/lib/type-check'
import { Tab as NavTab } from 'common-components'
import { NavigationItem } from 'quick-quote/components'
import { getNavTabList } from './navigations/tabs'

type NavProps = {
  location: Object,
  pages?: Object[],
  navTabList: Tab[],
}

export const _NavigationMenu = (navProps: NavProps) => {
  const tabList = isArray(navProps.pages) ? navProps.pages : navProps.navTabList
  const currentPath = navProps.location.pathname

  if (tabList.length === 0) return null
  else {
    return (
      <NavTab active={currentPath} className="header-nav">
        {tabList
          .filter((tab) => !tab.hide)
          .map((tab) => (
            <NavTab.Item id={tab.path} key={tab.id} disabled={tab.disabled}>
              <NavigationItem tab={tab} />
            </NavTab.Item>
          ))}
      </NavTab>
    )
  }
}

const mapStateToProps = (state: AppState, ownProps: NavProps): * => ({
  navTabList: getNavTabList({ state, location: ownProps.location }),
})

export const Nav = connect(mapStateToProps)(_NavigationMenu)
