import { connect } from 'react-redux'
import { compose } from 'recompose'
import ROUTE_PATH from 'quick-quote/constants/path'
import { IsRealDevice } from 'e-submission/utils'
import Loading from './Layout/Loading'

const { default: styled } = require('styled-components')
const { isClientInCall, isAgentInCall } = require('quick-quote/remote-selling')

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 50;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
`

const RemoteSellingOverlay = (props) => {
  const { isOpen, path, isEsub } = props

  if (path.includes(ROUTE_PATH.REMOTE_SELLING.SUBMIT) || path.includes(ROUTE_PATH.POLICY_LIST)) {
    return <div></div>
  }

  // create an overlay that is transparent and covers the entire screen when the overlay is open
  return (
    <div>
      {!isEsub && <Loading />}
      <Overlay id={`RemoteSellingOverlay-${isOpen}`} isOpen={isOpen} />
    </div>
  )
}

const mapPropsToState = (state) => {
  return {
    isOpen: IsRealDevice && (!isClientInCall(state) || !isAgentInCall(state)),
    path: IsRealDevice ? window.location.hash : window.location.pathname,
  }
}
export default compose(connect(mapPropsToState))(RemoteSellingOverlay)
