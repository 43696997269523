// @flow
import { Input, FormGroup } from 'reactstrap'
import { connect } from 'react-redux'
import type { Dispatch } from 'redux'
import { bindActionCreators } from 'redux'
import { getSelectedDisplayProductCode } from 'quick-quote/product-selection/selectors'
import { getExpectedReturnComparisons, getLumpSumErrors } from 'quick-quote/product-investment/coverage-plan/selectors'
import type { ExpectedReturnComparison, LumpSum } from 'core/service/investment'
import { editLumpSum } from 'quick-quote/product-investment/coverage-plan/actions'
import type { AppState } from 'quick-quote/reducers'
import NumberFormat from 'react-number-format'
import { ErrorFeedback } from 'common-components/view-logic/display-message'
import { formatNumber } from 'core/service/lib/number-format'
import _ from 'lodash'
import VALUE from 'core/data-model/constants/values'
import MESSAGES from 'quick-quote/constants/messages'
import type { BenefitComparison } from 'core/service/investment/types'
import IInvestTable from './i-invest-table'
import IWealthyTable from './i-wealthy-table'
import RPUDRTable from './rpudr-table'
import { getToggles } from 'quick-quote/feature-toggles'

type Props = {
  selectedProductCode: string,
  expectedReturnComparisons: ExpectedReturnComparison[],
  lumpSumErrors: string[],
  editLumpSum: ({ year: number, value: number }) => void,
}

type LumpSumInputProps = {
  year: number,
  lumpSum: LumpSum,
  editLumpSum: ({ year: number, value: number }) => void,
  id: string,
  forceDisallow: boolean,
}

export const hasLumpSumError = (lumpSum: LumpSum) => lumpSum !== 'DISALLOWED' && !lumpSum.isValid

export const LumpSumInput = ({ year, lumpSum, editLumpSum, id = '', forceDisallow = false }: LumpSumInputProps) => {
  let onChange = () => {}
  let lumpSumValue = 0
  let isLumpSumDisabled = true

  if (lumpSum !== 'DISALLOWED') {
    isLumpSumDisabled = false
    lumpSumValue = lumpSum.value
    onChange = (e, { value }) => editLumpSum({ year, value: Number(value) })
  }

  if (forceDisallow) {
    isLumpSumDisabled = true
  }

  const hasError = hasLumpSumError(lumpSum)

  return (
    <div className={hasError ? 'has-danger' : ''}>
      <NumberFormat
        data-id={id}
        value={lumpSumValue}
        onChange={onChange}
        isAllowed={({ formattedValue }) => formattedValue.length <= 11}
        customInput={Input}
        thousandSeparator={true}
        decimalPrecision={0}
        allowNegative={false}
        disabled={isLumpSumDisabled}
        onFocus={(e) => e.target.setSelectionRange(0, -1)}
        type="tel"
        pattern="[0-9]*"
      />
    </div>
  )
}

type DisplayBenefitComparison = {
  accountValueAfterBonus: string,
  deathBenefit: string,
}

type DisplayBenefitByReturn = {
  '-1': DisplayBenefitComparison,
  '2': DisplayBenefitComparison,
  '5': DisplayBenefitComparison,
  '3': DisplayBenefitComparison,
}

type BenefitByReturn = {
  '-1': BenefitComparison,
  '2': BenefitComparison,
  '5': BenefitComparison,
  '3': BenefitComparison,
}

type DisplayExpectedReturnComparison = {
  year: number,
  age: string,
  accumulatedTotalPremium: string,
  benefitByExpectedReturn: DisplayBenefitByReturn,
  lumpSum: LumpSum,
}

const formatBenefit = (
  previousYearExpectedReturn: DisplayExpectedReturnComparison,
  benefitByExpectedReturn: BenefitByReturn
): DisplayBenefitByReturn => {
  return Object.keys(benefitByExpectedReturn).reduce((acc, expectedReturn) => {
    const { accountValueAfterBonus, deathBenefit } = benefitByExpectedReturn[expectedReturn]

    const maskValue = accountValueAfterBonus < 0
    acc[expectedReturn] = {
      accountValueAfterBonus: maskValue ? '0' : formatNumber(accountValueAfterBonus),
      deathBenefit: maskValue ? '0' : formatNumber(deathBenefit),
    }
    return acc
  }, {})
}

export const formatExpectedReturnComparison = (
  expectedReturnComparisons: ExpectedReturnComparison[]
): DisplayExpectedReturnComparison[] => {
  return _.reduce(
    expectedReturnComparisons,
    (acc, expectedReturnComparison) => {
      const { year, age, accumulatedTotalPremium, benefitByExpectedReturn, lumpSum } = expectedReturnComparison
      const previousYearExpectedReturn = _.last(acc)
      acc.push({
        year,
        age: formatNumber(age),
        accumulatedTotalPremium: formatNumber(accumulatedTotalPremium),
        lumpSum,
        benefitByExpectedReturn: formatBenefit(previousYearExpectedReturn, benefitByExpectedReturn),
      })
      return acc
    },
    []
  )
}

const formatCell = (value, defaultValue) => (value > 0 ? formatNumber(value) : defaultValue || MESSAGES.DASH)
const formatCellRound = (value, defaultValue) =>
  value > 0 ? formatNumber(value, 0, true) : defaultValue || MESSAGES.DASH

export const ExpectedTable = ({ selectedProductCode, expectedReturnComparisons, editLumpSum }) => {
  const tableProps = {
    className: `expected-table-${selectedProductCode}`,
    expectedReturnComparisons,
    editLumpSum,
    formatCell,
  }
  switch (selectedProductCode) {
    case VALUE.SPUL:
      return <IInvestTable {...tableProps} />
    case VALUE.RPUDR:
      return <RPUDRTable {...tableProps} formatCell={formatCellRound} formatAccountValue={formatCell} />
    default:
      return <IWealthyTable {...tableProps} />
  }
}

const Table = ({ selectedProductCode, expectedReturnComparisons, editLumpSum }) => {
  return (
    <div className="sticky-table-wrap">
      <ExpectedTable
        selectedProductCode={selectedProductCode}
        expectedReturnComparisons={expectedReturnComparisons}
        editLumpSum={editLumpSum}
      />
    </div>
  )
}

export const getTitleTable = (selectedProductCode: string) => {
  if (selectedProductCode === VALUE.RPUDR) {
    return (
      <div className="title-top-padding">
        <div>ตัวอย่างแสดงมูลค่ารับซื้อคืนหน่วยลงทุนและความคุ้มครองการเสียชีวิต </div>
        <div>ณ สิ้นปีกรมธรรม์ สำหรับอัตราผลตอบแทนการลงทุนต่างๆ *</div>
      </div>
    )
  }

  return (
    <div className="title-top-padding">
      <div>ตัวอย่างแสดงมูลค่าบัญชีกรมธรรม์และความคุ้มครองการเสียชีวิต </div>
      <div>ณ สิ้นปีกรมธรรม์ สำหรับอัตราผลตอบแทนการลงทุนต่างๆ *</div>
    </div>
  )
}

export const getNote = (selectedProductCode: string) => {
  if (selectedProductCode === VALUE.RPUDR) {
    return (
      <p>
        ข้อรับรู้ที่สำคัญ:
        <br />
        มูลค่ารับซื้อคืนหน่วยลงทุนที่แสดงอยู่ในเอกสารประกอบการเสนอขายกรมธรรม์ประกันชีวิตควบการลงทุนนี้
        เกิดจากการใช้สมมติฐาน
        เพื่อที่จะแสดงให้เห็นถึงผลกระทบของมูลค่ารับซื้อคืนหน่วยลงทุนที่เกิดจากการจ่ายค่าธรรมเนียมกรมธรรม์ต่างๆ
        รวมถึงผลตอบแทนการลงทุน โดยสมมติฐานเหล่านี้จะไม่มีส่วนเกี่ยวข้องกับเงื่อนไขต่างๆ ที่ถูกระบุไว้ในกรมธรรม์
        <br />
        <br />
        ** ตามสมมติฐานเรื่องอัตราเงินเฟ้อทางการแพทย์และค่ารักษาพยาบาล อาจทำให้กรมธรรม์นี้สิ้นผลบังคับก่อน
        โดยบริษัทมีสิทธิในการยกเลิก “สิทธิการมีผลบังคับอย่างต่อเนื่อง”
        ตามที่ระบุอยู่ในเงื่อนไขทั่วไปแห่งกรมธรรม์ประกันภัย
        หากผู้เอาประกันภัยปฏิเสธไม่ชำระเบี้ยประกันภัยเพิ่มพิเศษในอัตราที่บริษัทขอแจ้งปรับ
      </p>
    )
  }

  return (
    <p>
      ข้อรับรู้ที่สำคัญ:
      <br />
      มูลค่าบัญชีของกรมธรรม์ที่แสดงอยู่ใน แบบเสนอขายนี้ เกิดจากการใช้สมมติฐาน
      เพื่อที่จะแสดงให้เห็นถึงผลกระทบของมูลค่าบัญชีกรมธรรม์ ที่เกิดจากการจ่ายค่าธรรมเนียมต่างๆ รวมถึงผลตอบแทนการลงทุน
      โดยสมมติฐานเหล่านี้ จะไม่มีส่วนเกี่ยวข้อง กับเงื่อนไขต่างๆ ที่ถูกระบุไว้ในสัญญากรมธรรม์
    </p>
  )
}

const ExpectedReturnComparisonTable = ({
  selectedProductCode,
  expectedReturnComparisons,
  editLumpSum,
  lumpSumErrors,
}: Props) => (
  <div id="benefit-premuim-table">
    <div className="benefit-short-table">
      {getTitleTable(selectedProductCode)}

      {getToggles().MOVE_ERROR_MESSAGE_FOR_INVESTMENT_ON_COVERAGE_PLAN ? (
        <div>
          <FormGroup className="has-danger space-top">
            <ErrorFeedback id="lump-sum-errors" errorMessages={lumpSumErrors} />
          </FormGroup>
          <Table
            selectedProductCode={selectedProductCode}
            expectedReturnComparisons={expectedReturnComparisons}
            editLumpSum={editLumpSum}
          />
        </div>
      ) : (
        <div>
          <Table
            selectedProductCode={selectedProductCode}
            expectedReturnComparisons={expectedReturnComparisons}
            editLumpSum={editLumpSum}
          />
          <FormGroup className="has-danger space-top">
            <ErrorFeedback id="lump-sum-errors" errorMessages={lumpSumErrors} />
          </FormGroup>
        </div>
      )}

      <div className="disclaimer space-top">
        <p>
          *หมายเหตุ:
          <br />
          มูลค่าที่แสดงในเอกสารฉบับนี้เป็นเพียงตัวเลขประมาณการตามสมมติฐานที่ตั้งขึ้น ณ วันที่ทำเอกสารนี้
          เพื่อที่จะให้ผู้เอาประกันภัยได้ศึกษาเป็นตัวอย่างเท่านั้น ไม่ได้เป็นการรับรองผลประโยชน์
          และไม่ได้อ้างอิงตามผลการดำเนินงานย้อนหลังของผลิตภัณฑ์
        </p>
        <p>
          บริษัทฯ ไม่สามารถรับประกันและไม่สามารถรับรองว่าท่านจะได้รับผลประโยชน์ประมาณการตามตัวอย่างนี้ในอนาคต ดังนั้น
          ผลประโยชน์ที่ได้รับจริงอาจแตกต่างจากตัวเลขประมาณการข้างต้นนี้ โดยอาจมีความแตกต่างอย่างมีนัยสำคัญ
        </p>
        <p>
          ผู้เอาประกันภัยควรจะศึกษาตัวอย่างที่แสดงอย่างละเอียดถี่ถ้วน
          โดยวิเคราะห์ถึงผลประกอบการในอนาคตและความคาดหวังในผลิตภัณฑ์นี้ด้วยตัวท่านเอง
          เพื่อที่จะสามารถประเมินแนวโน้มของผลการดำเนินงานของผลิตภัณฑ์ในอนาคตได้อย่างชัดเจน
        </p>
        {getNote(selectedProductCode)}
      </div>
    </div>
  </div>
)

const mapStateToProps = (state: AppState) => ({
  expectedReturnComparisons: getExpectedReturnComparisons(state),
  lumpSumErrors: getLumpSumErrors(state),
  selectedProductCode: getSelectedDisplayProductCode(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  editLumpSum: bindActionCreators(editLumpSum, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(ExpectedReturnComparisonTable)
