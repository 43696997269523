// @flow

import type { IncompatibilitiesAgeRule } from 'core/data-model/rider/rules'
import type { RiderEligibilities } from 'core/data-model/rider'
import type { ValidationMessage } from 'core/data-model/validation'

import { isAgeLessThan } from 'core/service/insured'
import { formatAge } from 'core/service/lib/age-format'
import _ from 'lodash'
import riderMessages from 'core/data-model/constants/validation-messages'
import Mustache from 'mustache'

export const checkIncompatibilitiesAgeRule = (
  rule: IncompatibilitiesAgeRule,
  eligibilities: RiderEligibilities
): ValidationMessage => {
  let errorMessage = ''
  const breakAgeLimit = (age) => {
    return isAgeLessThan(age, rule.minimumValue)
  }
  const selectedRiderCodes = eligibilities.selectedRiders.map(function(selectedRider) {
    return selectedRider['code']
  })
  const breakIncompatibilities = !_.isEqual(_.intersection(rule.incompatibilities, selectedRiderCodes), [])

  if (breakIncompatibilities && breakAgeLimit(eligibilities.insured.age)) {
    errorMessage = Mustache.render(riderMessages.RULE_RIDER_INCOMPATIBILITY_AGE, {
      riderName: eligibilities.rider.code,
      minimumAge: formatAge(rule.minimumValue),
      riderIncompatibilities: rule.incompatibilities,
    })
  }
  /*
        "incompatibilities": [
          "MEB"
        ],
        "minimumValue": {
          "unit": "year",
          "value": 10
        },
        "type": "IncompatibilitiesAge",
        "validationType": "ELIGIBILITY"

      */

  return {
    type: rule.validationType,
    message: errorMessage,
  }
}
