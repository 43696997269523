//@flow
export type Notification =
  | NoneNotification
  | WarningNotification
  | InformationNotification
  | PasswordInformationNotification
  | RPQPasswordInformationNotification
  | RAPasswordInformationNotification
  | GenericErrorNotification
  | ErrorMessageNotification
  | ErrorIncompleteFNANotification
  | AuthenticationErrorNotification
  | RemoteSellingNotification
  | CordovaUpdateFailedNotification
  | ConfirmToHomeNotification
  | ConfirmToFNANotification
  | RemoteSellingVcNotification
  | CheckVersionNativeNotification
  | ConfirmEKYCNotification
  | NetworkNotification
  | ConfirmMessageNotification
  | ConfirmIproFNANotification

type NoneNotification = { type: 'None' }
export type ErrorMessageNotification = {
  type: 'ErrorMessage',
  title: string,
  message: string,
}

export type ConfirmEKYCNotification = {
  type: 'ConfirmEkyc',
}

export type ConfirmMessageNotification = {
  type: 'ConfirmModal',
  title: string,
  message: string,
  customOnClick?: Function,
}

export type ConfirmIproFNANotification = {
  type: 'ConfirmIproFNA',
}

export type NetworkNotification = {
  type: 'NetworkModal',
}

export type ErrorIncompleteFNANotification = {
  type: 'ErrorIncompleteFNA',
  incompleteNeedGap: boolean,
  incompleteMarketConducts: boolean,
}

export type AuthenticationErrorNotification = {
  type: 'AuthenticationError',
  message?: string,
}

export type GenericErrorNotification = {
  type: 'GenericError',
  message?: string,
}

export type WarningNotification = {
  type: 'Warning',
  subType: 'NoInternet' | '',
}

export type InformationNotification = {
  type: 'Information',
  message: string,
  title: ?string,
}

export type PasswordInformationNotification = {
  type: 'PasswordInformation',
  title: string,
  password: string,
  biType: 'FULL_BI' | 'MINI_BI',
  productCategory: 'INVESTMENT' | 'WHOLE_LIFE',
}

export type RPQPasswordInformationNotification = {
  type: 'RPQPasswordInformation',
  title: string,
  password: string,
}

export type RAPasswordInformationNotification = {
  type: 'RAPasswordInformation',
  title: string,
  password: string,
}

export type RemoteSellingNotification = {
  type: 'RemoteSelling',
}

export type CheckVersionNativeNotification = {
  type: 'CheckVersionNative',
}

export type RemoteSellingVcNotification = {
  type: 'RemoteSellingVc',
}

export type ConfirmToHomeNotification = {
  type: 'ConfirmToHome',
}
export type ConfirmToFNANotification = {
  type: 'ConfirmToFNA',
}

export type LoginLimitSessionExceeded = {
  type: 'LoginLimitSessionExceeded',
}

export type CordovaUpdateFailedNotification = {
  type: 'CordovaUpdateFailed',
  message?: string,
}

export const SET_NOTIFICATION_MESSAGE = 'SET_NOTIFICATION_MESSAGE'
export type SetNotificationMessage = {
  type: 'SET_NOTIFICATION_MESSAGE',
  payload: Notification,
}

export const setNotificationMessage = (payload: Notification): SetNotificationMessage => ({
  type: SET_NOTIFICATION_MESSAGE,
  payload,
})

export const CLEAR_NOTIFICATION_MESSAGE = 'CLEAR_NOTIFICATION_MESSAGE'
export type ClearNotificationMessage = {
  type: 'CLEAR_NOTIFICATION_MESSAGE',
}

export const clearNotificationMessage = (): ClearNotificationMessage => ({
  type: CLEAR_NOTIFICATION_MESSAGE,
})
