import { createSectionCreator, createField } from 'e-submission/domain/data-model/form/utils'

const disclaimerFields = (app, id) => {
  return [
    createField(`${id}.disclaimer`, [
      {
        id: `${id}.disclaimer`,
        c: 'Disclaimer',
        p: {
          icon: 'groupSignature',
          children: `
            <div>เมื่อเซ็นและยืนยันตัวตนลูกค้าแล้ว</div>
            <div><strong>ตัวแทน/นายหน้าประกันชีวิตจะไม่สามารถเปลี่ยนแปลงข้อมูลภายในใบคำขอนี้ได้</strong></div>
          `,
        },
      },
    ]),
  ]
}

export default createSectionCreator(disclaimerFields)
