import styled from 'styled-components'
import { Flex } from 'rebass'
import Card from './index'
import Button from 'e-submission/components/Button'

const Content = styled(Card)`
  border: 1px solid ${({ theme }) => theme.variables['$color-silver']};
  padding: 8px 16px;

  .view-btn {
    font-size: 1rem;
    padding: 0.336rem 1rem;
    background-color: white;
    margin-bottom: 0;
    margin-right: 5px;
    border-color: ${({ theme, disabled }) =>
      disabled ? theme.variables['$color-silver'] : theme.variables['$color-primary-blue']};

    :hover {
      background: ${({ theme }) => theme.variables['$color-primary-blue']};
    }
  }

  .view-btn:hover {
    background: ${({ theme }) => theme.variables['$color-primary-blue']};
  }

  #edit-card-btn-view:hover {
    background-color: ${({ theme }) => theme.variables['$color-primary-blue']};
  }

  button {
    font-size: 0.8725rem;
    padding: 0.125rem 0.5rem;
  }

  .card-content {
    border-top: 1px solid ${({ theme }) => theme.variables['$color-mercury']};
    margin-top: 10px;
    padding-top: 10px;
  }

  ${({ theme, viewmode }) => theme.media.md`
    padding:  ${viewmode === 'view' ? '16px' : '15px 30px'};
  `}
`

const Title = styled(Flex)`
  padding: 5px 0;
  label {
    flex: 1;
    margin: 0;
    align-self: center;
  }
  .btn-link {
    display: flex;
    align-items: center;
    text-decoration: underline;
    border: none;
    &:hover,
    &:active,
    &:focus {
      color: inherit;
      border: none;
      outline: none;
      box-shadow: none;
    }
    svg {
      margin-right: 3px;
      width: 30px;
      height: 30px;
    }
  }
`

const Side = styled(Flex).attrs({
  direction: 'column',
  align: 'center',
  justify: 'center',
  flex: ['0 0 15%'],
})`
  min-width: 50px;
`

const EditCard = ({ title, children, onClick, disabled, btnText, viewOnly, ...props }) => (
  <Content viewmode={viewOnly ? 'view' : ''} disabled={disabled} {...props} column>
    <Title>
      <label>
        <strong>{title}</strong>
      </label>
      {viewOnly ? (
        <Side align="flex-end">
          <Button
            id="edit-card-btn-view"
            className="view-btn"
            disabled={disabled}
            color={disabled ? 'disabled' : 'primary'}
            outline
            onClick={onClick}
          >
            ดู
          </Button>
        </Side>
      ) : (
        <Button icon="edit" disabled={disabled} onClick={onClick}>
          {btnText || 'แก้ไข'}
        </Button>
      )}
    </Title>
    {children ? <div className="card-content">{children}</div> : null}
  </Content>
)

export default EditCard
