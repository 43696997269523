//@flow
import MESSAGES from 'core/data-model/constants/bi-messages'
import { bankOptions, getBankData } from '../components/bank-options'
import { buildColumn } from 'core/service/pdf-generation/utils/builder'

const LIST_LEFT_MARGIN = 20
const header = {
  marginBottom: 5,
  bold: true,
}

type Props = {
  paymentLabel: string,
  productCategory: string,
}

export const _paymentChannelsSubSection = (paymentLabel: string, bankOptionsSection: Object) => [
  { text: MESSAGES.PAYMENT_TERMS },
  {
    margin: [LIST_LEFT_MARGIN, 10, 0, 0],
    ul: [
      {
        stack: [
          { text: MESSAGES.PAYMENT_BANK_COUNTER, bold: true },
          { text: MESSAGES.PAYMENT_BANK_COUNTER_TERMS },
          bankOptionsSection,
        ],
      },
      { marginTop: 10, stack: [{ text: paymentLabel, bold: true }, { text: MESSAGES.PAYMENT_AGENT_OR_BANK_TERMS }] },
      {
        marginTop: 7,
        stack: [{ text: MESSAGES.PAYMENT_COMPANY, bold: true }, { text: MESSAGES.PAYMENT_COMPANY_TERMS }],
      },
    ],
  },
  {
    text: MESSAGES.PAYMENT_TYPES,
    style: header,
    marginTop: 15,
  },
  {
    marginLeft: LIST_LEFT_MARGIN,
    ul: [
      { text: MESSAGES.PAYMENT_TYPES_CASH, bold: true },
      {
        stack: [{ text: MESSAGES.PAYMENT_TYPES_ATM, bold: true }, { text: MESSAGES.PAYMENT_TYPES_ATM_TERMS }],
      },
      {
        stack: [
          { text: MESSAGES.PAYMENT_TYPES_INTERNET_BANK, bold: true },
          { text: MESSAGES.PAYMENT_TYPES_INTERNET_BANK_TERMS },
        ],
      },
    ],
  },
  {
    text: MESSAGES.PAYMENT_FEE,
    style: header,
    marginTop: 15,
  },
  {
    text: MESSAGES.PAYMENT_FREE_OF_CHARGE,
  },
]

export const paymentChannelsSubSection = async ({ paymentLabel }: Props) => {
  const bankOptionsSection = await bankOptions()
  return _paymentChannelsSubSection(paymentLabel, bankOptionsSection)
}

export const paymentChannelsSectionILink = () => {
  const bankData = getBankData()
  return _paymentChannelsSectionILink(bankData)
}

export const _paymentChannelsSectionILink = (bankData: Object) => {
  return [
    {
      bold: true,
      text: 'หมายเหตุ',
    },
    {
      marginLeft: 10,
      text:
        'เพื่อให้กรมธรรม์ประกันชีวิตมีผลคุ้มครองโดยสมบูรณ์และผู้เอาประกันภัยได้รับประโยชน์สูงสุด ผู้เอาประกันภัยมีหน้าที่ในการนำส่งเบี้ยประกันภัย ซึ่งตัวแทน, นายหน้าประกันชีวิต,',
    },
    {
      text:
        ' ลูกจ้าง, หรือพนักงานบริษัท มาเก็บเบี้ยประกันภัยเป็นการให้บริการเท่านั้น โดยบริษัทฯ ได้อำนวยความสะดวกในการชำระเบี้ยประกันภัย ผ่านช่องทางต่าง ๆ ดังนี้',
    },
    {
      ol: [
        [
          'ชำระผ่านตัวแทนหรือเคาน์เตอร์ธนาคารกรุงไทย เป็นเงินสด หรือตั๋วแลกเงิน หรือแคชเชียร์เช็ค หรือเช็คสั่งจ่าย “บริษัท กรุงไทย-แอกซ่า ประกันชีวิต จำกัด (มหาชน)”',
          'โดยผู้เอาประกันภัยจะได้รับใบรับเงินชั่วคราวงวดแรกทันทีหลังจากชำระเงินค่าเบี้ยประกันภัย และใบรับเงินชั่วคราวดังกล่าวจะต้องไม่หมดอายุ',
        ],
        [
          'ชำระผ่านแผนกรับชำระเบี้ย ณ ที่ทำการสาขา หรือที่สำนักงานใหญ่ของบริษัทฯ เป็นเงินสด หรือตั๋วแลกเงิน หรือแคชเชียร์เช็ค หรือเช็คสั่งจ่าย “บริษัท กรุงไทย-แอกซ่า ประกันชีวิต จำกัด (มหาชน)” หรือบัตรเครดิต',
        ],
        [
          'ชำระผ่านเคาน์เตอร์ของธนาคารกรุงไทย ธนาคารกรุงเทพ ธนาคารกรุงศรีอยุธยา ธนาคารกสิกรไทย ธนาคารทหารไทยธนชาต ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร ทุกสาขา',
          'ทั่วประเทศไทยผ่านระบบ Bill payment โดยระบุชื่อบัญชี “บริษัท กรุงไทย-แอกซ่า ประกันชีวิต จำกัด (มหาชน)” และแจ้ง Comp.code หรือ Service code  โดยใช้แบบฟอร์มใบนำฝากของธนาคาร,',
          '“ใบรับชำระค่าสาธารณูปโภคและค่าบริการ”, หรือแบบฟอร์มใบนำฝากแบบพิเศษของบริษัทฯ เท่านั้น',
          {
            text: 'ชำระผ่านระบบ Bill payment',
          },
          {
            columns: buildColumn([
              {
                label: '',
                value: `${bankData.byBill[0][0]}`,
                width: '20%',
                bold: false,
              },
              {
                label: '',
                value: `${bankData.byBill[0][1]}`,
                width: '30%',
                bold: false,
              },
              {
                label: '',
                value: `${bankData.byBill[1][0]}`,
                width: '25%',
                bold: false,
              },
              {
                label: '',
                value: `${bankData.byBill[1][1]}`,
                width: '25%',
                bold: false,
              },
            ]),
          },
          {
            columns: buildColumn([
              {
                label: '',
                value: `${bankData.byBill[2][0]}`,
                width: '20%',
                bold: false,
              },
              {
                label: '',
                value: `${bankData.byBill[2][1]}`,
                width: '30%',
                bold: false,
              },
              {
                label: '',
                value: `${bankData.byBill[3][0]}`,
                width: '25%',
                bold: false,
              },
              {
                label: '',
                value: `${bankData.byBill[3][1]}`,
                width: '25%',
                bold: false,
              },
            ]),
          },
          {
            columns: buildColumn([
              {
                label: '',
                value: `${bankData.byBill[4][0]}`,
                width: '20%',
                bold: false,
              },
              {
                label: '',
                value: `${bankData.byBill[4][1]}`,
                width: '30%',
                bold: false,
              },
              {
                label: '',
                value: `${bankData.byBill[5][0]}`,
                width: '25%',
                bold: false,
              },
              {
                label: '',
                value: `${bankData.byBill[5][1]}`,
                width: '25%',
                bold: false,
              },
            ]),
          },
        ],
        [
          'ชำระโดยการโอนเงินผ่านตู้ ATM ของธนาคารกรุงไทย ธนาคารกรุงเทพ ธนาคารกรุงศรีอยุธยา ธนาคารไทยพาณิชย์ ธนาคารกสิกรไทย ธนาคารทหารไทยธนชาต ทั่วประเทศ',
          {
            columns: buildColumn([
              {
                label: '',
                value: `${bankData.byATM[0][0]}`,
                width: '20%',
                bold: false,
              },
              {
                label: '',
                value: `${bankData.byATM[0][1]}`,
                width: '30%',
                bold: false,
              },
              {
                label: '',
                value: `${bankData.byATM[1][0]}`,
                width: '25%',
                bold: false,
              },
              {
                label: '',
                value: `${bankData.byATM[1][1]}`,
                width: '25%',
                bold: false,
              },
            ]),
          },
          {
            columns: buildColumn([
              {
                label: '',
                value: `${bankData.byATM[2][0]}`,
                width: '20%',
                bold: false,
              },
              {
                label: '',
                value: `${bankData.byATM[2][1]}`,
                width: '30%',
                bold: false,
              },
              {
                label: '',
                value: `${bankData.byATM[3][0]}`,
                width: '25%',
                bold: false,
              },
              {
                label: '',
                value: `${bankData.byATM[3][1]}`,
                width: '25%',
                bold: false,
              },
            ]),
          },
          {
            columns: buildColumn([
              {
                label: '',
                value: `${bankData.byATM[4][0]}`,
                width: '20%',
                bold: false,
              },
              {
                label: '',
                value: `${bankData.byATM[4][1]}`,
                width: '30%',
                bold: false,
              },
              {
                label: '',
                value: `${bankData.byATM[5][0]}`,
                width: '25%',
                bold: false,
              },
              {
                label: '',
                value: `${bankData.byATM[5][1]}`,
                width: '25%',
                bold: false,
              },
            ]),
          },
        ],
        [
          'ชำระโดยการโอนเงินผ่านโมบายแอปพลิเคชันของธนาคารกรุงไทย ธนาคารกรุงเทพ ธนาคารกรุงศรีอยุธยา ธนาคารไทยพาณิชย์ ธนาคารกสิกรไทย ธนาคารทหารไทยธนชาต  อ้างอิง Comp. code หรือ รหัสบริษัท ตามข้อ 4',
        ],
        [
          'ชำระโดยการโอนเงินผ่านอินเตอร์เน็ตแบงกิ้งของธนาคารกรุงไทย ธนาคารกรุงเทพ ธนาคารกรุงศรีอยุธยา ธนาคารไทยพาณิชย์ ธนาคารกสิกรไทย ธนาคารทหารไทยธนชาต',
          'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร อ้างอิง Comp. code หรือ รหัสบริษัท ตามข้อ 3 และ 4',
        ],
        [
          'ชำระทางไปรษณีย์เป็นธนาณัติ ระบุชื่อผู้รับในนาม “บริษัท กรุงไทย-แอกซ่า ประกันชีวิต จำกัด (มหาชน)” และสั่งจ่าย ปณ.นานาเหนือ กรุงเทพฯ',
        ],
        [
          'บริการพิเศษ สำหรับการชำระเบี้ยประกันภัยในงวดถัดไป สามารถชำระโดยหักบัญชีเงินฝากธนาคารประเภทออมทรัพย์หรือกระแสรายวัน ผ่านธนาคารกรุงไทย ธนาคารกรุงเทพ ธนาคารกรุงศรีอยุธยา',
          'ธนาคารกสิกรไทย ธนาคารไทยพาณิชย์ ทุกสาขาทั่วประเทศ โดยกรอกหนังสือยินยอมหักบัญชีธนาคาร และแนบสำเนาสมุดบัญชีเงินฝากธนาคารหน้าแรก พร้อมสำเนาบัตรประจำตัวประชาชน',
          'ของเจ้าของบัญชีมาด้วยในการสมัครทุกครั้ง ทั้งนี้เบี้ยประกันภัยงวดแรกจะไม่สามารถชำระโดยการหักบัญชีธนาคารได้',
        ],
        [
          'บริการพิเศษ สำหรับการชำระเบี้ยประกันภัยในงวดถัดไป สามารถชำระโดยหักบัตรเครดิตอัตโนมัติ ประเภท VISA หรือ MASTER หรือ JCB โดยกรอกหนังสือยินยอมหักค่าเบี้ยประกันภัยอัตโนมัติ',
          'และแนบสำเนาหน้าบัตรเครดิต พร้อมสำเนาบัตรประจำตัวประชาชนของผู้ถือบัตร ทั้งนี้เบี้ยประกันภัยงวดแรกจะไม่สามารถชำระโดยการหักบัตรเครดิตอัตโนมัติได้',
        ],
      ],
    },
  ]
}
