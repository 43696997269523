// @flow

import _ from 'lodash'
import PATH from 'quick-quote/constants/path'
import { useState, useRef, useEffect, useCallback, useLayoutEffect } from 'react'
import ReactDOM from 'react-dom'
import styled, { css } from 'styled-components'

import TotalPremiumInput from 'quick-quote/product-common/coverage-plan/components/basic-plan/total-premium-input'
import { Redirect } from 'react-router-dom'
import { InsuredInformationBreadcrumb } from 'quick-quote/components'

import ModelFactorSelection from 'quick-quote/product-common/coverage-plan/components/basic-plan/model-factor-selection'
import RiderGroup from 'quick-quote/product-common/coverage-plan/components/rider/rider-group'

import type { DisplayProduct } from 'core/service/display-product'

export type CoveragePlanProps = CoveragePlanState & CoveragePlanDispatchers

export type CoveragePlanState = {
  listBasicPlanCode: string[],
  canProceedToCoveragePlan: boolean,
  displayProduct: DisplayProduct,
}

export type CoveragePlanDispatchers = {
  toggleRider: (string, boolean) => void,
  editRiderSumAssured: (string, number) => void,
  editRiderSelectedPlan: (string, RiderPlan) => void,
}

const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: rgba(245, 245, 245, 1);
  justify-content: center;
  top: 123px;
  position: sticky;
  z-index: 499;

  #wrapper {
    -webkit-box-flex: 0;
    -webkit-flex: 0 1 680px;
    -ms-flex: 0 1 680px;
    flex: 0 1 680px;
  }

  #basic-plan {
    margin: 0 auto;
    padding: 1.5rem 0 0 0;
    min-width: 0;
  }

  #total-premium {
    margin: 0 auto;
    min-width: 0;
  }

  @media (max-width: 575px) {
    margin: 0 -30px;
    padding: 0 30px;
  }
`

const RiderWrapper = styled.div`
  #rider-plan {
    margin-top: 10px !important;
  }
`

const contentStyle = { padding: '0 30px 30px 30px' }

const CoveragePlan = ({
  toggleRider,
  riderCodes,
  canProceedToCoveragePlan,
  displayProduct,
  editRiderSumAssured,
  editRiderSelectedPlan,
}: CoveragePlanProps) => {
  if (!canProceedToCoveragePlan) {
    return <Redirect to={PATH.PRODUCT_SELECTION} push />
  }

  return (
    <div>
      <InsuredInformationBreadcrumb />
      <div id="coverage-plan">
        <div className="content" style={contentStyle}>
          <FlexWrapper>
            <div id="wrapper">
              <div id="basic-plan">
                <div className="content">
                  <ModelFactorSelection />
                </div>
              </div>
              <div id="total-premium">
                <TotalPremiumInput />
              </div>
            </div>
          </FlexWrapper>
          <RiderWrapper>
            <RiderGroup
              riderCodes={riderCodes}
              toggleRider={toggleRider}
              editRiderSumAssured={editRiderSumAssured}
              editRiderSelectedPlan={editRiderSelectedPlan}
            />
          </RiderWrapper>
        </div>
      </div>
    </div>
  )
}

export default CoveragePlan
