// @flow
import { FormGroup } from 'reactstrap'
import type { PolicyOwner, PolicyOwnerOption } from 'core/data-model/policy-owner'
import { ErrorFeedback } from 'common-components/view-logic'
import { RadioButtonGroup } from 'common-components'

type Props = {
  policyownerOptions: PolicyOwnerOption[],
  policyowner: PolicyOwner,
  errors: void,
  validateSelectPolicyOwner: (string) => void,
}

const PolicyOwnerSelection = ({ policyownerOptions, policyowner, errors, validateSelectPolicyOwner }: Props) => (
  <FormGroup id="policy-owner-selection" className={`${errors.length > 0 ? 'has-danger' : ''}`}>
    <div className="options option-labels">
      <label>ท่านต้องการสมัครผลิตภัณฑ์ Super 7 CriticalCare ให้กับ</label>
    </div>
    <div className="options option-inputs">
      <div className="form-group">
        <div className="input">
          <RadioButtonGroup
            value={policyowner}
            options={policyownerOptions}
            onChange={validateSelectPolicyOwner}
            classNamePrefix="gender"
            autoClassName={true}
          />
          <ErrorFeedback
            id={'select-policy-owner-info'}
            errorMessages={['บริษัทฯ จะพิจารณารับประกันเฉพาะบุคคลที่มีสัญชาติไทยเท่านั้น']}
            messageType={'info-message'}
          />
          <ErrorFeedback id={'select-policy-owner-info'} errorMessages={[errors]} messageType={'error-message'} />
          {/* <FormFeedback className="validation-message">{errors}</FormFeedback> */}
        </div>
      </div>
    </div>
  </FormGroup>
)

export default PolicyOwnerSelection
