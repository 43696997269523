import styled from 'styled-components'
import { Checkbox } from 'common-components'
import { connect } from 'react-redux'
import { setAppValue } from 'e-submission/apps/actions'
import { compose, mapProps, withStateHandlers } from 'recompose'
import { FormFeedback } from 'reactstrap'
import { CREDIT_CARD_PAYMENT_METHOD } from 'e-submission/domain/data-model/constants'

const METHOD = CREDIT_CARD_PAYMENT_METHOD

const Header = styled.label`
  font-weight: bold;
`

const Container = styled.div`
  margin-top: 20px;
`

const CreditCardPaymentMethod = ({
  creditCardMethodProps: creditCardMethod,
  handleChangeCreditCardMethod,
  isSendLink,
  isRemoteSelling,
}) => {
  const isOnAZ = creditCardMethod === METHOD.ON_AZ
  const isQuickLink = creditCardMethod === METHOD.QUICKLINK

  return (
    <div data-testid="creditcard-payment-method">
      {!isRemoteSelling && (
        <div>
          <Container>
            <Header>รูปแบบการชำระเงิน</Header>
          </Container>
          <Container>
            {!isSendLink ? (
              <Checkbox checked={isOnAZ} onChange={() => handleChangeCreditCardMethod(METHOD.ON_AZ)}>
                ชำระทันที
              </Checkbox>
            ) : isSendLink && isOnAZ ? (
              <p>ชำระทันที</p>
            ) : null}
          </Container>
          <Container>
            {!isSendLink ? (
              <Checkbox checked={isQuickLink} onChange={() => handleChangeCreditCardMethod(METHOD.QUICKLINK)}>
                ผู้เอาประกันชำระผ่านลิงก์ด้วยตนเอง
              </Checkbox>
            ) : isSendLink && isQuickLink ? (
              <p>ผู้เอาประกันชำระผ่านลิงก์ด้วยตนเอง</p>
            ) : null}
          </Container>
          <Container>
            <FormFeedback className="info-message attached-icon">
              <p>
                กรณีผู้เอาประกันเลือกชำระผ่านลิงก์ด้วยตนเอง ผ่านบัตรเครดิต KTC สามารถใช้สิทธิ์ผ่อนชำระเบี้ยประกันได้
                ทั้งนี้เป็นไปตามเงื่อนไขของทางบริษัทฯ
              </p>
            </FormFeedback>
          </Container>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state, props) => {
  return {
    creditCardMethodProps: props.creditCardMethod || '',
    isSendLink: props.isSendLink || false,
    allState: state,
    isRemoteSelling: props.isRemoteSelling || false,
  }
}

const mapDispatchToProps = (dispatch) => ({
  setAppValue: (fieldId, newValue) => {
    dispatch(setAppValue(fieldId, newValue))
  },
})

export default compose(
  mapProps((props) => ({
    ...props,
  })),
  connect(mapStateToProps, mapDispatchToProps),
  withStateHandlers(
    {},
    {
      handleChangeCreditCardMethod: (state, props) => (val) => {
        props.setAppValue('creditCardMethod', val)
        props.handleCheckMethod(true)
        return { creditCardMethod: val }
      },
    }
  )
)(CreditCardPaymentMethod)
