import { Flex } from 'rebass'
import moment from 'moment'
import { compact, overEvery, noop, get, has, isNil } from 'lodash/fp'
import {
  validateApplication,
  setDefaultAppValue,
  updateReceipt,
  submitCreditCard,
  updateUnsignReceipt,
} from 'e-submission/apps/actions'
import { isSectionValid, getBankList, getBankListForNetbank, getFileWithName } from 'e-submission/apps/selectors'
import { isBuddhistYear } from 'core/service/lib/date'
import values from 'core/data-model/constants/values'
import { createField, createSectionCreator } from 'e-submission/domain/data-model/form/utils'
import { PAYMENT_TYPE } from 'e-submission/domain/data-model/constants'

import {
  isAllReceiptPaid,
  isAllReceiptCreated,
  getReceiptAmount,
  getReceiptQRRef2,
  isReceiptPaid,
  isMaxFirstTotalPayment,
  isMinFirstTotalPayment,
  getPolicyId,
  getReceiptType,
  getReceiptDocumentType,
  getReceiptNo,
  getSignatureKey,
  isRemoteSelling,
  getQuickQuoteProductName,
  getSelectedModelFactorLabel,
  getIsSendLink,
  getQuickpayPaymentStatus,
  getReceiptCreditCardRelationship,
  getUploadedDocuments,
  getQuickQuotePaymentModelLabel,
  isSelectedRemoteSelling,
  isHasPayerInfoEcbrNo,
} from 'e-submission/domain/data-model/form/selectors'
import { signatureField } from 'e-submission/domain/data-model/submit'
import { getIcon } from 'e-submission/components/Icon'
import QRPayment from 'e-submission/components/QRPayment'
import { getToggles } from 'quick-quote/feature-toggles'
import CreditCardConsent from 'e-submission/components/Form/Payment/creditCardConsent'
import { documentEcbrNewName, getQRData } from 'e-submission/utils/documents'

const getRelationshipInfoMessage = () => (
  <div>
    ในกรณีที่ผู้เอาประกันเลือกชำระค่าเบี้ยด้วยบุคคลอื่นที่มีความเกี่ยวข้อง จะต้องแนบ
    <strong> เอกสารพิสูจน์ความสัมพันธ์ผู้ชำระเบี้ย</strong> อาทิเช่น
    สามีต้องการชำระเบี้ยให้กับผู้เอาประกันจะต้องแนบเอกสารทะเบียนสมรสหรือเอกสารพิสูจน์ความสัมพันธ์อื่นๆด้วยทุกครั้ง
    หากไม่แนบเอกสารตัวแทนจะไม่สามารถส่งใบคำขอเอาประกัยในขั้นตอนต่อไปได้
  </div>
)

const PayerSection = (app, id) => {
  const fieldId = 'payerInfo'
  const payerInfo = get(`${fieldId}`, app)
  const receipt = get(id, app)
  const amount = getReceiptAmount(receipt)

  const fields = [
    {
      label: 'ชื่อของผู้ชำระเบี้ย',
      id: `${fieldId}.firstName`,
      c: 'TextInput',
      required: true,
    },
    {
      label: 'นามสกุลของผู้ชำระเบี้ย',
      id: `${fieldId}.lastName`,
      c: 'TextInput',
      required: true,
    },
    {
      label: 'เอกสารที่ใช้แสดง',
      id: `${fieldId}.idType`,
      c: 'Dropdown',
      required: true,
      p: {
        mergeState: (state) => {
          return {
            options: ECBR_PAYER_DOCTYPE,
          }
        },
      },
    },
    {
      label: 'เลขประจำตัวประชาชน',
      id: `${fieldId}.idNo.citizenId`,
      c: 'NumberInput',
      required: true,
    },
    {
      label: 'หมายเลขหนังสือเดินทาง',
      id: `${fieldId}.idNo.passport`,
      c: 'TextInput',
      required: true,
    },
    {
      label: 'ความสัมพันธ์',
      id: `${fieldId}.relations`,
      c: 'Dropdown',
      required: true,
      p: {
        placeHolder: 'กรุณาเลือกความสัมพันธ์',
        mergeState: (state) => {
          return {
            options: getRelationshipListForEcbr(state),
          }
        },
      },
    },
    {
      label: '',
      id: `${fieldId}.relations.others`,
      required: true,
      c: 'TextInput',
    },
    {
      id: `payerInfoMessage`,
      required: false,
      c: 'InfoMessage',
      p: {
        mergeState: () => ({
          value: getRelationshipInfoMessage(),
        }),
      },
    },
  ]
  return payerInfo?.payerType === ECBR_PAYER_TYPE.OTHER && amount ? fields : []
}

export const shouldEnableBrowseDocument = (app) =>
  (getToggles().ENABLE_ALLOW_BROWSE_PAYSLIP && isRemoteSelling(app)) ||
  getToggles().ENABLE_APPMAN_REMOTE_SELLING ||
  undefined

export const edcDocumentSection = createSectionCreator((app, id) => [
  createField(`${id}.transactionDate`, [
    {
      label: 'วันรูดบัตร',
      id: `${id}.transactionDate`,
      c: 'DateDropdownInput',
      required: true,
      p: {
        mergeState: (state, app) => ({
          value: get(`${id}.transactionDate`, app),
        }),
      },
    },
  ]),
  createField(`${id}.approvalCode`, [
    {
      label: 'รหัสอนุมัติ 6 ตัว',
      id: `${id}.approvalCode`,
      c: 'TextInput',
      required: true,
    },
  ]),
])

export const cashDocumentSection = createSectionCreator((app, id) => [
  createField(`${id}.bank`, [
    {
      id: `${id}.bank`,
      c: 'Dropdown',
      required: true,
      p: {
        placeHolder: 'กรุณาเลือกธนาคาร',
        mergeState: (state) => {
          return {
            options: getBankList(state),
          }
        },
      },
    },
  ]),
  createField(`${id}.bankReceiptDate`, [
    {
      label: 'วันที่นำฝาก',
      id: `${id}.bankReceiptDate`,
      c: 'DateDropdownInput',
      required: true,
    },
  ]),
])

export const netbankDocumentSection = createSectionCreator((app, id) => {
  const formFields = [
    createField(`${id}.bank`, [
      {
        id: `${id}.bank`,
        c: 'Dropdown',
        required: true,
        p: {
          placeHolder: 'กรุณาเลือกธนาคาร',
          mergeState: (state) => {
            return {
              options: getBankListForNetbank(state),
            }
          },
        },
      },
    ]),
    createField(`${id}.bankReceiptDate`, [
      {
        label: 'วันที่นำฝาก',
        id: `${id}.bankReceiptDate`,
        c: 'DateDropdownInput',
        required: true,
      },
    ]),
  ]

  return !getToggles().ENABLE_QR_CROSSBANK ? formFields : formFields.slice(1)
})

const ECBRLabel = ({ icon, label }) => (
  <Flex align="center">
    {getIcon(icon)}
    <span style={{ paddingRight: '10px' }}>{label}</span>
  </Flex>
)

export const ECBRPremiumElement = (paymentType) => (app, id, remoteSelling) => {
  const readOnly =
    paymentType !== PAYMENT_TYPE.EDC &&
    paymentType !== PAYMENT_TYPE.CASH &&
    paymentType !== PAYMENT_TYPE.CREDITCARD &&
    paymentType !== PAYMENT_TYPE.NETBANK

  const isRemoteSelling = isSelectedRemoteSelling(app)
  const label = {
    [PAYMENT_TYPE.CASH]: 'เงินสด',
    [PAYMENT_TYPE.EDC]: getToggles().HIDE_UNUSED_PAYMENT ? 'เครื่องรูดบัตร \nEDC' : 'เครื่องรูดบัตร\nEDC/MPOS',
    [PAYMENT_TYPE.CREDITCARD]:
      getToggles().ENABLE_QUICKLINK_PAYMENT && !isRemoteSelling ? 'บัตรเครดิต/บัตรเดบิต' : 'บัตรเครดิต',
    [PAYMENT_TYPE.NETBANK]: getToggles().ENABLE_QR_CROSSBANK ? 'QR Code' : 'อินเทอร์เน็ตแบงก์กิ้ง',
  }

  const icon = {
    [PAYMENT_TYPE.CASH]: 'cashPayment',
    [PAYMENT_TYPE.EDC]: 'edcPayment',
    [PAYMENT_TYPE.CREDITCARD]: 'creditCardPayment',
    [PAYMENT_TYPE.NETBANK]: getToggles().ENABLE_QR_CROSSBANK ? 'qrCode' : 'netbankPayment',
  }

  return {
    id: `${id}.totalFirstPremiumPayment`,
    required: true,
    c: 'NumberInput',
    p: {
      readOnly,
      mergeState: (state, app) => {
        const receipt = get(id, app)
        const policyId = getPolicyId(app)
        const sanitizedPolicyId = policyId && policyId.replace('-', '')
        const creditCardRelationship = getReceiptCreditCardRelationship(app)
        const receiptNo = getReceiptNo(receipt)
        const documentType = getReceiptDocumentType(receipt)
        const documentTypeName = documentEcbrNewName(documentType.name, receiptNo)
        const bankList = getBankListForNetbank(state)
        const key = getSignatureKey('ECBR', 'agent', id)
        // TODO: review to remove this line
        const paid = isReceiptPaid(receipt)
        let value = 0

        const isQRCodeNetbank =
          get('receipts', app).filter((receipt) => receipt?.type === 'net bank' && receipt?.totalFirstPremiumPayment)
            .length > 0
        if (isAllReceiptCreated(app)) {
          value = getReceiptAmount(receipt) || 0
        } else if (getReceiptType(receipt) === paymentType) {
          value = getReceiptAmount(receipt) || 0
        }

        let footer = null
        const isQPpaymentSuccess = getQuickpayPaymentStatus(app) === 'SUCCESS'
        const shouldShowQRPayment =
          getToggles().ENABLE_CASH_QR_PAYMENT && policyId && paymentType === PAYMENT_TYPE.NETBANK && !paid
        const shouldShowCreditCardConsent =
          getToggles().ENABLE_CREDIT_CARD_RELATIONSHIP && policyId && paymentType === PAYMENT_TYPE.EDC && !paid
        if (shouldShowQRPayment) {
          const ref2 = getReceiptQRRef2(receipt)
          const taxId = getToggles().ENABLE_QR_CROSSBANK ? values.PAYMENT.QR.TAXID_CROSSBANK : values.PAYMENT.QR.TAXID
          const qrData = getQRData(taxId, sanitizedPolicyId, value, ref2)

          const payload = {
            data: {
              policyId,
              product: getQuickQuoteProductName(app),
              selectedModelFactorLabel: `แบบ${getSelectedModelFactorLabel(app) || getQuickQuotePaymentModelLabel(app)}`,
              amount: value,
              qrCode: value && qrData,
              currency: 'บาท',
            },
            bankList,
            documentIds: getUploadedDocuments(key, app),
            documentTypeName,
          }
          footer = (
            <QRPayment
              remoteSelling={remoteSelling}
              payload={payload}
              value={value && qrData}
              amount={value}
              isQRCodeNetbank={isQRCodeNetbank}
            />
          )
        } else if (shouldShowCreditCardConsent) {
          footer = !!value && (
            <CreditCardConsent
              data-testid="creditcard-consent"
              handleConsentValidation={(val) => val}
              handleCheckCreditcardRelationShip={(val) => val}
              creditCardRelationship={creditCardRelationship}
              isSendLink={false}
            />
          )
        }
        return {
          value,
          disabled: isAllReceiptPaid(app) || isQPpaymentSuccess,
          children: <ECBRLabel icon={icon[paymentType]} label={label[paymentType]} />,
          footer: footer,
          displayType: isAllReceiptCreated(app) ? 'text' : undefined,
          className: 'text',
        }
      },
      decimalPrecision: 2,
      thousandSeparator: true,
    },
  }
}

export const cashSection = (app, state) => {
  const id = 'receipts[0]'
  const paymentType = PAYMENT_TYPE.CASH
  const documentSection = cashDocumentSection(id)(app, id)

  const receipt = get(id, app)

  // TODO: review to remove this line
  const receiptType = getReceiptType(receipt)
  const receiptNo = getReceiptNo(receipt)

  // TODO: review to remove this line
  const paid = isReceiptPaid(receipt)

  const documentType = getReceiptDocumentType(receipt)
  const documentTypeName = documentEcbrNewName(documentType.name, receiptNo)

  const key = getSignatureKey('ECBR', 'agent', id)
  const isSigned = !!getFileWithName(state, key)
  const isUploaded = overEvery([has(['_attachments', documentTypeName]), isSectionValid(state, [documentSection])])(app)

  const isEnableBrowseFile = shouldEnableBrowseDocument(app)

  const getECBRState = () => {
    if (isReceiptPaid(receipt)) {
      if (isSigned) {
        return 'done'
      } else if (getToggles().ENABLE_CHANGE_PAY_IN_ATTACHMENT_STEP && isUploaded) {
        return 'active'
      } else if (getToggles().ENABLE_CHANGE_PAY_IN_ATTACHMENT_STEP) {
        return ''
      }
      return 'active'
    }
    return ''
  }

  const getBankSlipState = () => {
    if (isReceiptPaid(receipt) && isSigned && isUploaded) {
      return 'done'
    } else if (isReceiptPaid(receipt) && isSigned) {
      return 'active'
    } else if (getToggles().ENABLE_CHANGE_PAY_IN_ATTACHMENT_STEP && isReceiptPaid(receipt)) {
      return 'active'
    }
    return ''
  }

  const isSendLink = getIsSendLink(app)

  let paymentSteps = []
  if (getToggles().ENABLE_CHANGE_PAY_IN_ATTACHMENT_STEP) {
    paymentSteps = [
      {
        elements: [ECBRPremiumElement(paymentType)(app, id)],
      },
      {
        label: 'ออกใบเสร็จรับเงินชั่วคราว',
        state: '',
        elements: [
          {
            id: `${id}.bankReceipt`,
            c: 'DisabledPhotoCard',
            p: {
              title: 'แนบเอกสารการชำระเงิน',
              children: 'กรณีเงินสดให้ออกใบเสร็จแล้วจึงแนบหลักฐานการชำระที่ขั้นตอนสุดท้าย',
            },
          },
        ],
      },
      {
        label: 'ออกใบเสร็จรับเงินชั่วคราว',
        state: getECBRState(),
        elements: signatureField(id)(app, 'agent.ECBR', {
          id,
          label: 'ลงนาม ตัวแทน/นายหน้าประกันชีวิต',
          warnText: 'หากท่านลงนามแล้ว ท่านจะไม่สามารถเปลี่ยนการชำระเงินได้',
        }).elements,
      },
    ]
  } else {
    paymentSteps = [
      {
        elements: [ECBRPremiumElement(paymentType)(app, id)],
      },
      {
        label: 'ออกใบเสร็จรับเงินชั่วคราว',
        state: getECBRState(),
        elements: signatureField(id)(app, 'agent.ECBR', {
          id,
          label: 'ลงนาม ตัวแทน/นายหน้าประกันชีวิต',
          warnText: 'หากท่านลงนามแล้ว ท่านจะไม่สามารถเปลี่ยนการชำระเงินได้',
        }).elements,
      },
      {
        state: getBankSlipState(),
        elements: [
          {
            id: `${id}.bankReceipt`,
            c: 'PhotoCard',
            p: {
              isBrowseFile: isEnableBrowseFile,
              mergeState: (state, app, { dispatch = noop } = {}) => {
                const key = getSignatureKey('ECBR', 'agent', id)
                const isSigned = !!getFileWithName(state, key)
                const isUploaded = overEvery([
                  has(['_attachments', documentTypeName]),
                  isSectionValid(state, [documentSection]),
                ])(app)

                return {
                  onOk: () => {
                    if (isUploaded) {
                      dispatch(updateReceipt(id, documentTypeName))
                      return true
                    } else {
                      dispatch(validateApplication())
                      return false
                    }
                  },
                  value: [
                    {
                      icon: null,
                      sections: [documentSection],
                      name: documentTypeName,
                      description: 'ถ่ายรูปใบนําฝากเงินจาก ธนาคาร',
                      checked: isUploaded,
                      disabled: !isSigned,
                    },
                  ],
                }
              },
            },
          },
        ],
      },
    ]
  }

  return {
    id,
    paymentType,
    title: 'เงินสด',
    icon: 'check',
    disabled: getToggles().ENABLE_QUICKLINK_PAYMENT
      ? (isAllReceiptPaid(app) || isSendLink) && isNil(receiptNo)
      : isAllReceiptPaid(app),
    isAllReceiptCreated: isAllReceiptCreated(app),
    selected: !isNil(receiptNo),
    receiptNo,
    paid,
    paymentSteps,
  }
}

export const edcSection = (app, state) => {
  const id = 'receipts[1]'
  const paymentType = PAYMENT_TYPE.EDC
  const documentSection = edcDocumentSection(id)(app, id)

  const receipt = get(id, app)
  const receiptType = getReceiptType(receipt)
  const receiptNo = getReceiptNo(receipt)

  const paid = isReceiptPaid(receipt)

  const documentType = getReceiptDocumentType(receipt)
  const documentTypeName = documentEcbrNewName(documentType.name, receiptNo)

  const key = getSignatureKey('ECBR', 'agent', id)
  const isSigned = !!getFileWithName(state, key)
  const isUploaded = overEvery([has(['_attachments', documentTypeName]), isSectionValid(state, [documentSection])])(app)

  const getECBRState = () => {
    if (isReceiptPaid(receipt)) {
      if (isSigned) {
        return 'done'
      } else if (getToggles().ENABLE_CHANGE_PAY_IN_ATTACHMENT_STEP && isUploaded) {
        return 'active'
      } else if (getToggles().ENABLE_CHANGE_PAY_IN_ATTACHMENT_STEP) {
        return ''
      }
      return 'active'
    }
    return ''
  }

  const getBankSlipState = () => {
    if (isReceiptPaid(receipt) && isSigned && isUploaded) {
      return 'done'
    } else if (isReceiptPaid(receipt) && isSigned) {
      return 'active'
    } else if (getToggles().ENABLE_CHANGE_PAY_IN_ATTACHMENT_STEP && isReceiptPaid(receipt)) {
      return 'active'
    }
    return ''
  }

  const isSendLink = getIsSendLink(app)

  let paymentSteps = []
  if (getToggles().ENABLE_CHANGE_PAY_IN_ATTACHMENT_STEP) {
    paymentSteps = [
      {
        elements: [ECBRPremiumElement(paymentType)(app, id)],
      },
      {
        state: getBankSlipState(),
        elements: [
          {
            id: `${id}.bankReceipt`,
            c: 'PhotoCard',
            p: {
              isBrowseFile: true,
              mergeState: (state, app, { dispatch = noop } = {}) => {
                const key = getSignatureKey('ECBR', 'agent', id)
                const isUploaded = overEvery([
                  has(['_attachments', documentTypeName]),
                  isSectionValid(state, [documentSection]),
                ])(app)

                return {
                  onOpen: () => {
                    const key = `${id}.transactionDate`
                    const transactionDate = get(key, app)
                    if (isNil(transactionDate)) {
                      const dateFormat = 'DD/MM/YYYY'
                      let today = moment().format(dateFormat)
                      if (!isBuddhistYear(today)) {
                        today = moment()
                          .add(543, 'years')
                          .format(dateFormat)
                      }
                      dispatch(setDefaultAppValue(key, today))
                    }
                  },
                  onOk: () => {
                    if (isUploaded) {
                      dispatch(updateUnsignReceipt(id, documentTypeName))
                      return true
                    } else {
                      dispatch(validateApplication())
                      return false
                    }
                  },
                  value: [
                    {
                      icon: null,
                      sections: [documentSection],
                      name: documentTypeName,
                      description: 'แนบสลิปการตัดบัตรเครดิต',
                      checked: isUploaded,
                      disabled: false,
                    },
                  ],
                }
              },
            },
          },
        ],
      },
      {
        label: 'ออกใบเสร็จรับเงินชั่วคราว',
        state: getECBRState(),
        elements: signatureField(id)(app, 'agent.ECBR', {
          id,
          label: 'ลงนาม ตัวแทน/นายหน้าประกันชีวิต',
          warnText: 'หากท่านลงนามแล้ว ท่านจะไม่สามารถเปลี่ยนการชำระเงินได้',
          documentTypeName,
        }).elements,
      },
    ]
  } else {
    paymentSteps = [
      {
        elements: [ECBRPremiumElement(paymentType)(app, id)],
      },
      {
        label: 'ออกใบเสร็จรับเงินชั่วคราว',
        state: getECBRState(),
        elements: signatureField(id)(app, 'agent.ECBR', {
          id,
          label: 'ลงนาม ตัวแทน/นายหน้าประกันชีวิต',
          warnText: 'หากท่านลงนามแล้ว ท่านจะไม่สามารถเปลี่ยนการชำระเงินได้',
        }).elements,
      },
      {
        state: getBankSlipState(),
        elements: [
          {
            id: `${id}.bankReceipt`,
            c: 'PhotoCard',
            p: {
              isBrowseFile: true,
              mergeState: (state, app, { dispatch = noop } = {}) => {
                const key = getSignatureKey('ECBR', 'agent', id)
                const isSigned = !!getFileWithName(state, key)
                const isUploaded = overEvery([
                  has(['_attachments', documentTypeName]),
                  isSectionValid(state, [documentSection]),
                ])(app)

                return {
                  onOpen: () => {
                    const key = `${id}.transactionDate`
                    const transactionDate = get(key, app)
                    if (isNil(transactionDate)) {
                      const dateFormat = 'DD/MM/YYYY'
                      let today = moment().format(dateFormat)
                      if (!isBuddhistYear(today)) {
                        today = moment()
                          .add(543, 'years')
                          .format(dateFormat)
                      }
                      dispatch(setDefaultAppValue(key, today))
                    }
                  },
                  onOk: () => {
                    if (isUploaded) {
                      if (getToggles().ENABLE_ECBR_SYSTEMIZATION === false) {
                        dispatch(updateReceipt(id, documentTypeName))
                      }
                      return true
                    } else {
                      dispatch(validateApplication())
                      return false
                    }
                  },
                  value: [
                    {
                      icon: null,
                      sections: [documentSection],
                      name: documentTypeName,
                      description: 'แนบสลิปการตัดบัตรเครดิต',
                      checked: isUploaded,
                      disabled: !isSigned,
                    },
                  ],
                }
              },
            },
          },
        ],
      },
    ]
  }

  return {
    id,
    paymentType,
    title: 'EDC/MPOS',
    icon: 'check',
    disabled: getToggles().ENABLE_QUICKLINK_PAYMENT
      ? (isAllReceiptPaid(app) || isSendLink) && isNil(receiptNo)
      : isAllReceiptPaid(app),
    isAllReceiptCreated: isAllReceiptCreated(app),
    selected: !isNil(receiptNo),
    receiptNo,
    paid,
    paymentSteps,
  }
}

const CreditCardPayment = (app, id, remoteSelling, history) => {
  const fields = getToggles().ENABLE_CYBERSOURCE
    ? []
    : [
        {
          label: 'หมายเลขบัตร',
          elements: [
            {
              id: 'creditCardNumber',
              c: 'NumberInput',
              required: true,
              p: {
                format: '#### #### #### ####',
                placeholder: '●●●● ●●●● ●●●● ●●●●',
              },
            },
          ],
        },
        {
          label: 'ชื่อบนบัตร',
          elements: [
            {
              id: 'nameOnCard',
              c: 'TextInput',
              required: true,
              p: {
                placeholder: 'ชื่อ-นามสกุล (ภาษาอังกฤษ)',
              },
            },
          ],
        },
        {
          label: 'วันหมดอายุ',
          elements: [
            {
              id: 'expiryDate',
              c: 'DateInput',
              required: true,
              p: {
                placeholder: 'ดด / ปป',
                type: 'tel',
                pattern: ['m', 'y'],
              },
            },
          ],
          flexProps: {
            flex: '0 0 45%',
          },
        },
        {
          label: 'รหัสหลังบัตร (CVV)',
          elements: [
            {
              id: 'securityCode',
              c: 'NumberInput',
              required: true,
              p: {
                placeholder: '●●●',
                format: '###',
              },
            },
          ],
          flexProps: {
            flex: '0 0 45%',
          },
        },
      ]

  return {
    id: `${id}.totalFirstPremiumPayment`,
    c: 'CybersourceDisplayCreditCardPaymentFormButton',
    p: {
      mergeState: (state, app, { dispatch = noop } = {}) => {
        const receipt = get(id, app)
        const paid = isReceiptPaid(receipt)

        return {
          fields,
          remoteSelling,
          checked: paid,
          amount: getReceiptAmount(receipt),
          policyId: getPolicyId(app),
          product: getQuickQuoteProductName(app),
          selectedModelFactorLabel: `แบบ${getSelectedModelFactorLabel(app) || getQuickQuotePaymentModelLabel(app)}`,
          closeModal: () => history.replace(history.location.pathname.replace('/payment', '')),
          startCybs: () =>
            dispatch({
              type: 'START_CYBS_PAYMENT',
              payload: {
                id,
                amount: getReceiptAmount(receipt),
                policyId: getPolicyId(app),
                product: getQuickQuoteProductName(app),
                selectedModelFactorLabel: `แบบ${getSelectedModelFactorLabel(app) ||
                  getQuickQuotePaymentModelLabel(app)}`,
                remoteSelling,
              },
            }),

          onClick: (form) => {
            const [month, year] = form.expiryDate.split('/')
            const cardInfo = {
              id,
              name: form.nameOnCard,
              number: form.creditCardNumber,
              expiration_month: month,
              expiration_year: year,
              security_code: form.securityCode,
              amount: getReceiptAmount(receipt),
            }
            dispatch(submitCreditCard(cardInfo))
          },
        }
      },
    },
  }
}

export const creditCardSection = (app, state, remoteSelling = false, history) => {
  const id = 'receipts[2]'
  const paymentType = PAYMENT_TYPE.CREDITCARD
  const receipt = get(id, app)
  const key = getSignatureKey('ECBR', 'agent', id)
  const isSigned = !!getFileWithName(state, key)
  const receiptNo = getReceiptNo(receipt)
  const paid = isReceiptPaid(receipt)
  const receiptAmount = getReceiptAmount(receipt)
  const isSendLink = getIsSendLink(app)
  const isUserSelected = getToggles().ENABLE_QUICKLINK_PAYMENT ? receiptAmount > 0 : receiptAmount > 0 || isSendLink
  const canPay =
    isUserSelected &&
    !isAllReceiptCreated(app) &&
    isMaxFirstTotalPayment(app, receiptAmount) &&
    isMinFirstTotalPayment(app, receiptAmount)

  const getECBRState = () => {
    if (isReceiptPaid(receipt)) {
      if (isSigned) {
        return 'done'
      }
      return 'active'
    }
    return ''
  }
  return {
    id,
    paymentType,
    title: 'เครดิตการ์ด',
    isAllReceiptCreated: isAllReceiptCreated(app),
    selected: !isNil(receiptNo),
    disabled: isAllReceiptPaid(app) && isNil(receiptNo),
    receiptNo,
    paid,
    paymentSteps: [
      {
        elements: compact([
          ECBRPremiumElement(paymentType)(app, id, remoteSelling),
          canPay && CreditCardPayment(app, id, remoteSelling, history),
        ]),
      },
      {
        label: 'ออกใบเสร็จรับเงินชั่วคราว',
        state: getECBRState(),
        elements: signatureField(id)(app, 'agent.ECBR', {
          id,
          label: 'ลงนาม ตัวแทน/นายหน้าประกันชีวิต',
        }).elements,
      },
    ],
  }
}

export const netbankSection = (app, state, remoteSelling = false, history) => {
  const id = 'receipts[3]'
  const paymentType = PAYMENT_TYPE.NETBANK
  const documentSection = netbankDocumentSection(id)(app, id)
  const receipt = get(id, app)

  // TODO: review to remove this line
  const receiptType = getReceiptType(receipt)
  const receiptNo = getReceiptNo(receipt)

  // TODO: review to remove this line
  const paid = isReceiptPaid(receipt)
  const documentType = getReceiptDocumentType(receipt)
  const documentTypeName = documentEcbrNewName(documentType.name, receiptNo)

  const key = getSignatureKey('ECBR', 'agent', id)
  const isSigned = !!getFileWithName(state, key)
  const isUploaded = overEvery([has(['_attachments', documentTypeName]), isSectionValid(state, [documentSection])])(app)

  const isEnableBrowseFile = shouldEnableBrowseDocument(app)

  const getECBRState = () => {
    if (isReceiptPaid(receipt)) {
      if (isSigned) {
        return 'done'
      } else if (getToggles().ENABLE_CHANGE_PAY_IN_ATTACHMENT_STEP && isUploaded) {
        return 'active'
      } else if (getToggles().ENABLE_CHANGE_PAY_IN_ATTACHMENT_STEP) {
        return ''
      }
      return 'active'
    }
    return ''
  }

  const getBankSlipState = () => {
    if (isReceiptPaid(receipt) && isSigned && isUploaded) {
      return 'done'
    } else if (isReceiptPaid(receipt) && isSigned) {
      return 'active'
    } else if (getToggles().ENABLE_CHANGE_PAY_IN_ATTACHMENT_STEP && isReceiptPaid(receipt)) {
      return 'active'
    }
    return ''
  }

  const isSendLink = getIsSendLink(app)

  let paymentSteps = []
  if (getToggles().ENABLE_CHANGE_PAY_IN_ATTACHMENT_STEP) {
    paymentSteps = [
      {
        elements: [ECBRPremiumElement(paymentType)(app, id, remoteSelling, history)],
      },
      {
        state: getBankSlipState(),
        elements: [
          {
            id: `${id}.bankReceipt`,
            c: 'PhotoCard',
            p: {
              // [DIGSALTOOL-1314] Netbank, commonly attach image from gallery that recieved from customer
              isBrowseFile: true,
              mergeState: (state, app, { dispatch = noop } = {}) => {
                const isUploaded = overEvery([
                  has(['_attachments', documentTypeName]),
                  isSectionValid(state, [documentSection]),
                ])(app)

                return {
                  onOk: () => {
                    if (isUploaded) {
                      dispatch(updateUnsignReceipt(id, documentTypeName))
                      return true
                    } else {
                      dispatch(validateApplication())
                      return false
                    }
                  },
                  value: [
                    {
                      icon: null,
                      sections: [documentSection],
                      name: documentTypeName,
                      description: 'ถ่ายรูปใบนําฝากเงินจาก ธนาคาร',
                      checked: isUploaded,
                      disabled: false,
                    },
                  ],
                }
              },
            },
          },
        ],
      },
      {
        label: 'ออกใบเสร็จรับเงินชั่วคราว',
        state: getECBRState(),
        elements: signatureField(id)(app, 'agent.ECBR', {
          id,
          label: 'ลงนาม ตัวแทน/นายหน้าประกันชีวิต',
          warnText: 'หากท่านลงนามแล้ว ท่านจะไม่สามารถเปลี่ยนการชำระเงินได้',
          documentTypeName,
        }).elements,
      },
    ]
  } else {
    paymentSteps = [
      {
        elements: [ECBRPremiumElement(paymentType)(app, id)],
      },
      {
        label: 'ออกใบเสร็จรับเงินชั่วคราว',
        state: getECBRState(),
        elements: signatureField(id)(app, 'agent.ECBR', {
          id,
          label: 'ลงนาม ตัวแทน/นายหน้าประกันชีวิต',
          warnText: 'หากท่านลงนามแล้ว ท่านจะไม่สามารถเปลี่ยนการชำระเงินได้',
        }).elements,
      },
      {
        state: getBankSlipState(),
        elements: [
          {
            id: `${id}.bankReceipt`,
            c: 'PhotoCard',
            p: {
              isBrowseFile: isEnableBrowseFile,
              mergeState: (state, app, { dispatch = noop } = {}) => {
                const key = getSignatureKey('ECBR', 'agent', id)
                const isSigned = !!getFileWithName(state, key)
                const isUploaded = overEvery([
                  has(['_attachments', documentTypeName]),
                  isSectionValid(state, [documentSection]),
                ])(app)

                return {
                  onOk: () => {
                    if (isUploaded) {
                      if (getToggles().ENABLE_ECBR_SYSTEMIZATION === false) {
                        dispatch(updateReceipt(id, documentTypeName))
                      }
                      return true
                    } else {
                      dispatch(validateApplication())
                      return false
                    }
                  },
                  value: [
                    {
                      icon: null,
                      sections: [documentSection],
                      name: documentTypeName,
                      description: 'ถ่ายรูปใบนําฝากเงินจาก ธนาคาร',
                      checked: isUploaded,
                      disabled: !isSigned,
                    },
                  ],
                }
              },
            },
          },
        ],
      },
    ]
  }

  return {
    id,
    paymentType,
    title: getToggles().ENABLE_QR_CROSSBANK ? 'QR Code' : 'อินเทอร์เน็ตแบงก์กิ้ง',
    icon: getToggles().ENABLE_QR_CROSSBANK ? 'qrCode' : 'netbankPayment',
    disabled: getToggles().ENABLE_QUICKLINK_PAYMENT
      ? (isAllReceiptPaid(app) || isSendLink) && isNil(receiptNo)
      : isAllReceiptPaid(app) && isNil(receiptNo),
    isAllReceiptCreated: isAllReceiptCreated(app),
    selected: !isNil(receiptNo),
    receiptNo,
    paid,
    paymentSteps,
  }
}
