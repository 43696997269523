import _ from 'lodash'

import { createSectionCreator, createField } from '../utils'
import { checker } from '../selectors'

const familyDiseaseField = (app, id) => {
  const isShowRemoveButton = !id.includes('[0]')

  return _.compact([
    createField(`${id}.relation`, [
      {
        label: 'บุคคลที่เป็น',
        id: `${id}.relation`,
        c: 'TextInput',
        required: true,
        p: {},
      },
    ]),
    createField(`${id}.disease`, [
      {
        label: 'โรค',
        id: `${id}.disease`,
        c: 'TextInput',
        required: true,
        p: {},
      },
    ]),
    createField(`${id}.age`, [
      {
        label: 'อายุที่เริ่มเป็น',
        id: `${id}.age`,
        c: 'TextInput',
        required: true,
        p: {},
      },
    ]),
    isShowRemoveButton &&
      createField(
        `${id}.remove`,
        [
          {
            id: `${id}.remove`,
            c: 'Button',
            p: {
              icon: 'remove',
              children: 'ลบ',
              mergeProps: ({ removeValue }) => ({
                onClick: () => removeValue(id),
              }),
              style: {
                fontSize: '0.8725rem',
                padding: '0.125rem 0.5rem',
              },
            },
          },
        ],
        {
          style: {
            position: 'absolute',
            top: '0',
            right: '0',
          },
        }
      ),
  ])
}

const addFamilyDisease = (app, id) => [
  createField(`${id}.addNew`, [
    {
      id,
      c: 'Button',
      p: {
        children: 'เพิ่มข้อมูล',
        icon: 'add',
        block: true,
        mergeState: () => ({
          mergeProps: ({ setValue }) => ({
            onClick: () => setValue(null),
          }),
        }),
      },
    },
  ]),
]

export default (sectionId, sectionLabel) => (app, id) => {
  const familyDiseaseDetail = _.get(app, `${id}.familyDiseaseMRTA.detail`, [null])
  const { isYes, isNo } = checker(app)

  return _.compact(
    isYes(`${id}.familyDiseaseMRTA.checked`) &&
      _.reduce(
        familyDiseaseDetail,
        (result, el, index) => [
          ...result,
          createSectionCreator(familyDiseaseField)(`${sectionId}[${index}]`, null, null, `บุคคลที่ ${index + 1}`)(
            app,
            `${id}.familyDiseaseMRTA.detail[${index}]`
          ),
        ],
        []
      ).concat(
        familyDiseaseDetail.length < 10 &&
          createSectionCreator(addFamilyDisease)(sectionId)(
            app,
            `${id}.familyDiseaseMRTA.detail[${familyDiseaseDetail.length}]`
          )
      )
  )
}
