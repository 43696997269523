//@flow
import {
  canProceedToFundAndCoveragePlan,
  canProceedToRiskQuestionnaire,
} from 'quick-quote/product-investment/journey-control-selectors'
import { canProceedPerfectLifeBenefitIllustration } from 'quick-quote/product-perfect-life/coverage-plan/selectors'
import { canProceedRetirementBenefitIllustration } from 'quick-quote/product-retirement/coverage-plan/selectors'
import { canProceedToInsuredInformation } from 'quick-quote/product-selection/selectors'
import { canProceedToBenefitIllustration } from 'quick-quote/product-whole-life/coverage-plan/selectors'
import { canProceedToCoveragePlan } from 'quick-quote/selectors/next-tab-proceeding'
import TABS from '../../../../core/data-model/constants/tab-messages'
import { PATH, ROUTE_PATH } from '../../../constants/path'
import type { AppState } from '../../../reducers'
import type { Tab } from '../../navigation-item'

export type NavTabConfig = {
  path: string,
  tabs: Tab[],
}

export const wholeLife = (state: AppState): NavTabConfig => {
  return {
    path: PATH.WHOLE_LIFE,
    tabs: [
      {
        title: TABS.INSURED_PERSON,
        path: ROUTE_PATH.WHOLE_LIFE.INSURED_INFORMATION,
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: ROUTE_PATH.WHOLE_LIFE.COVERAGE_PLAN,
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: ROUTE_PATH.WHOLE_LIFE.BENEFIT_ILLUSTRATION,
        id: 'benefit-illustration',
        disabled: !canProceedToBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}

export const protection = (state: AppState): NavTabConfig => {
  return {
    path: PATH.PROTECTION,
    tabs: [
      {
        title: TABS.INSURED_PERSON,
        path: ROUTE_PATH.PROTECTION.INSURED_INFORMATION,
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: ROUTE_PATH.PROTECTION.COVERAGE_PLAN,
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: ROUTE_PATH.PROTECTION.BENEFIT_ILLUSTRATION,
        id: 'benefit-illustration',
        disabled: !canProceedToBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}

export const flexiHealth = (state: AppState): NavTabConfig => {
  return {
    path: PATH.FLEXI_HEALTH,
    tabs: [
      {
        title: TABS.INSURED_PERSON,
        path: ROUTE_PATH.FLEXI_HEALTH.INSURED_INFORMATION,
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: ROUTE_PATH.FLEXI_HEALTH.COVERAGE_PLAN,
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: ROUTE_PATH.FLEXI_HEALTH.BENEFIT_ILLUSTRATION,
        id: 'benefit-illustration',
        disabled: !canProceedToBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}
export const healthTopping = (state: AppState): NavTabConfig => {
  return {
    path: PATH.HEALTH_TOPPING,
    tabs: [
      {
        title: TABS.INSURED_PERSON,
        path: ROUTE_PATH.HEALTH_TOPPING.INSURED_INFORMATION,
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: ROUTE_PATH.HEALTH_TOPPING.COVERAGE_PLAN,
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: ROUTE_PATH.HEALTH_TOPPING.BENEFIT_ILLUSTRATION,
        id: 'benefit-illustration',
        disabled: !canProceedToBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}
export const health = (state: AppState): NavTabConfig => {
  return {
    path: PATH.HEALTH,
    tabs: [
      {
        title: TABS.INSURED_PERSON,
        path: ROUTE_PATH.HEALTH.INSURED_INFORMATION,
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: ROUTE_PATH.HEALTH.COVERAGE_PLAN,
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: ROUTE_PATH.HEALTH.BENEFIT_ILLUSTRATION,
        id: 'benefit-illustration',
        disabled: !canProceedToBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}
export const investment = (state: AppState): NavTabConfig => {
  return {
    path: PATH.INVESTMENT,
    tabs: [
      {
        title: TABS.INSURED_PERSON,
        path: ROUTE_PATH.INVESTMENT.INSURED_INFORMATION,
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.RISK,
        path: ROUTE_PATH.INVESTMENT.RISK_QUESTIONNAIRE,
        id: 'invest',
        disabled: !canProceedToRiskQuestionnaire(state),
        component: null,
      },
      {
        title: TABS.FUND,
        path: ROUTE_PATH.INVESTMENT.FUND_ALLOCATION,
        id: 'funds',
        disabled: !canProceedToFundAndCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.PREMIUM,
        path: ROUTE_PATH.INVESTMENT.COVERAGE_PLAN,
        id: 'premium',
        disabled: !canProceedToFundAndCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: ROUTE_PATH.INVESTMENT.BENEFIT_ILLUSTRATION,
        id: 'benefit-illustration',
        disabled: !canProceedToFundAndCoveragePlan(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}
export const loan = (state: AppState): NavTabConfig => {
  return {
    path: PATH.LOAN,
    tabs: [
      {
        title: TABS.INSURED_PERSON,
        path: ROUTE_PATH.LOAN.INSURED_INFORMATION,
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: ROUTE_PATH.LOAN.COVERAGE_PLAN,
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: ROUTE_PATH.LOAN.BENEFIT_ILLUSTRATION,
        id: 'benefit-illustration',
        disabled: false,
        component: null,
      },
    ],
  }
}
export const saving = (state: AppState): NavTabConfig => {
  return {
    path: PATH.SAVING,
    tabs: [
      {
        title: TABS.INSURED_PERSON,
        path: ROUTE_PATH.SAVING.INSURED_INFORMATION,
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: ROUTE_PATH.SAVING.COVERAGE_PLAN,
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: ROUTE_PATH.SAVING.BENEFIT_ILLUSTRATION,
        id: 'benefit-illustration',
        disabled: !canProceedToBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}
export const lifeSavePro = (state: AppState): NavTabConfig => {
  return {
    path: PATH.LIFE_SAVE_PRO,
    tabs: [
      {
        title: TABS.INSURED_PERSON,
        path: ROUTE_PATH.LIFE_SAVE_PRO.INSURED_INFORMATION,
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: ROUTE_PATH.LIFE_SAVE_PRO.COVERAGE_PLAN,
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: ROUTE_PATH.LIFE_SAVE_PRO.BENEFIT_ILLUSTRATION,
        id: 'benefit-illustration',
        disabled: !canProceedToBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}

export const retirement = (state: AppState): NavTabConfig => {
  return {
    path: PATH.RETIREMENT,
    tabs: [
      {
        title: TABS.INSURED_PERSON,
        path: ROUTE_PATH.RETIREMENT.INSURED_INFORMATION,
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: ROUTE_PATH.RETIREMENT.COVERAGE_PLAN,
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: ROUTE_PATH.RETIREMENT.BENEFIT_ILLUSTRATION,
        id: 'benefit-illustration',
        disabled: !canProceedRetirementBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}

export const perfectLife = (state: AppState): NavTabConfig => {
  return {
    path: PATH.PERFECT_LIFE,
    tabs: [
      {
        title: TABS.INSURED_PERSON,
        path: ROUTE_PATH.PERFECT_LIFE.INSURED_INFORMATION,
        id: 'insured-information',
        disabled: !canProceedToInsuredInformation(state),
        component: null,
      },
      {
        title: TABS.CALCULATE_PREMIUM,
        path: ROUTE_PATH.PERFECT_LIFE.COVERAGE_PLAN,
        id: 'calculate-premium',
        disabled: !canProceedToCoveragePlan(state),
        component: null,
      },
      {
        title: TABS.BENEFIT_ILLUSTRATION,
        path: ROUTE_PATH.PERFECT_LIFE.BENEFIT_ILLUSTRATION,
        id: 'benefit-illustration',
        disabled: !canProceedPerfectLifeBenefitIllustration(state),
        component: null,
        analyticsEventType: 'BIEvent',
      },
    ],
  }
}

export default (state: AppState): NavTabConfig[] => [
  wholeLife(state),
  investment(state),
  loan(state),
  //TODO: Health Topping must be before Health due to current logic, must refactor
  healthTopping(state),
  health(state),
  saving(state),
  protection(state),
  lifeSavePro(state),
  flexiHealth(state),
  retirement(state),
  perfectLife(state),
]
