// @flow
import * as React from 'react'
import _ from 'lodash'
import { bindActionCreators } from 'redux'
import { Modal } from 'common-components'
import { withModalToggle } from 'lib/with-hoc'
import FSESelection from './fse-selection'
import BranchSelection from './branch-selection'
import type { AppState } from 'quick-quote/reducers'
import { validateFields } from '../selectors'
import type { ValidateFields } from '../selectors'
import { connect } from 'react-redux'
import {
  FormGroup,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormFeedback,
  Input,
  Card,
  CardBlock,
} from 'reactstrap'
import MESSAGES from 'core/data-model/constants/messages'
import type { FSE, Office, User, Staff, AdvisorApiUserInfo } from 'core/data-model/identity'
import type { Notification } from 'quick-quote/notification/actions'
import type { BIType } from 'core/service/pdf-generation/document-creation'
import { updateStaffId, updateStaffName, updateStaffSurname, updateStaffPosition } from 'identity/actions'
import { allFieldsNotEmpty, _isRequiredWealthHubStaff } from '../services'
import { getStaffInfo } from 'identity/selectors'
import { getToggles } from 'quick-quote/feature-toggles'

type Props = {
  isModalOpen: boolean,
  toggleModal: (?Object) => void,
  submitAction: (*) => void,
  biType: BIType,
  selectedFSE: ?FSE,
  selectedOffice: ?Office,
  updateOffice: (office: Office) => void,
  selectFSE: (AdvisorApiUserInfo) => void,
  updateStaffId: (staffId: string) => void,
  updateStaffName: (staffName: string) => void,
  updateStaffSurname: (staffSurname: string) => void,
  updateStaffPosition: (staffPosition: string) => void,
  user: User,
  setNotificationMessage: (Notification) => void,
  validateFields: ValidateFields,
  staffInfo: Staff,
}

export const FSEBranchModal = ({
  isModalOpen,
  toggleModal,
  submitAction,
  biType,
  selectedFSE,
  selectedOffice,
  updateOffice,
  selectFSE,
  updateStaffId,
  updateStaffName,
  updateStaffSurname,
  updateStaffPosition,
  user,
  setNotificationMessage,
  validateFields,
  staffInfo,
}: Props) => {
  const onSubmit = (selectedFSE, selectedOffice, submitAction, biType) => {
    let fields = [selectedFSE, selectedOffice]
    if (isRequiredWealthHubStaff()) {
      fields = fields.concat(_.toArray(staffInfo))
    }

    if (allFieldsNotEmpty(fields)) {
      submitAction(biType)
    } else {
      setNotificationMessage({
        message: MESSAGES.PLEASE_SELECT_FSE_BRANCH,
        title: undefined,
        type: 'Information',
      })
    }
  }

  const isRequiredWealthHubStaff = (): boolean => {
    // $FlowFixMe
    return _isRequiredWealthHubStaff(validateFields, biType, _.get(user, 'profile.agent_type'))
  }

  const errors = []
  return (
    <Modal
      isOpen={isModalOpen}
      toggle={toggleModal}
      backdrop={false}
      autoFocus={false}
      modalClassName="ltr-modal scrollable-modal fse-branch-modal"
    >
      <ModalHeader tag="div">
        <span className="text">{MESSAGES.SELECT_FSE_AND_BRANCH}</span>
      </ModalHeader>
      <ModalBody>
        <div className="content">
          <FormGroup className={'fse-selection'}>
            {getToggles().ENABLE_SEARCH_BRANCH_ONLY_6_DIGIT ? (
              <label>
                <b>{MESSAGES.BRANCH_CODE}</b> {MESSAGES.BRANCH_CODE_HINT}
              </label>
            ) : (
              <label>{MESSAGES.BRANCH_CODE}</label>
            )}
            <BranchSelection
              selectedValue={selectedOffice}
              updateSelectedValue={(office) => updateOffice(office)}
              user={user}
            />
            <FormFeedback className="validation-message">{errors.join(' ')}</FormFeedback>
          </FormGroup>
          <FormGroup className={'fse-selection'}>
            {getToggles().ENABLE_SEARCH_BRANCH_ONLY_6_DIGIT ? (
              <label>
                <b>{MESSAGES.FSE_CODE}</b> {MESSAGES.FSE_CODE_HINT}
              </label>
            ) : (
              <label>{MESSAGES.FSE_CODE}</label>
            )}
            <FSESelection
              selectedValue={selectedFSE}
              updateSelectedValue={(fse: AdvisorApiUserInfo) => selectFSE(fse)}
              user={user}
            />
            <FormFeedback className="validation-message">{errors.join(' ')}</FormFeedback>
          </FormGroup>

          {isRequiredWealthHubStaff() && (
            <FormGroup>
              <Card>
                <CardBlock>
                  <h6>เจ้าหน้าที่ WealthHub</h6>
                  <FormGroup>
                    <label>รหัสพนักงาน</label>
                    <Input
                      className="w-50"
                      value={staffInfo.id || ''}
                      onChange={(e) => {
                        updateStaffId(e.target.value)
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label>ชื่อ</label>
                    <Input
                      value={staffInfo.name || ''}
                      onChange={(e) => {
                        updateStaffName(e.target.value)
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label>นามสกุล</label>
                    <Input
                      value={staffInfo.surname || ''}
                      onChange={(e) => {
                        updateStaffSurname(e.target.value)
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <label>ตำแหน่งงาน</label>
                    <Input
                      value={staffInfo.position || ''}
                      onChange={(e) => {
                        updateStaffPosition(e.target.value)
                      }}
                    />
                  </FormGroup>
                </CardBlock>
              </Card>
            </FormGroup>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <div className="bottom">
          <Button className="done cancel" onClick={toggleModal}>
            {MESSAGES.CANCEL}
          </Button>
          <Button
            type="button"
            className="submit"
            color="primary"
            onClick={() => onSubmit(selectedFSE, selectedOffice, submitAction, biType)}
          >
            {MESSAGES.SUBMIT}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

const mapStateToProps = (state: AppState) => ({
  validateFields: validateFields(state),
  staffInfo: getStaffInfo(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  updateStaffId: bindActionCreators(updateStaffId, dispatch),
  updateStaffName: bindActionCreators(updateStaffName, dispatch),
  updateStaffSurname: bindActionCreators(updateStaffSurname, dispatch),
  updateStaffPosition: bindActionCreators(updateStaffPosition, dispatch),
})
export const _FSEBranchModal = connect(mapStateToProps, mapDispatchToProps)(FSEBranchModal)

export default withModalToggle(_FSEBranchModal)
