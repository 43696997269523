// @flow
import type { SelectedPlanRule } from 'core/data-model/rider/rules'
import type { RiderEligibilities } from 'core/data-model/rider'
import type { ValidationMessage } from 'core/data-model/validation'
import riderMessages from 'core/data-model/constants/validation-messages'
import _ from 'lodash'

export const checkSelectedPlanRule = (rule: SelectedPlanRule, eligibilities: RiderEligibilities): ValidationMessage => {
  let errorMessage = ''

  if (rule.value.includes(eligibilities.rider.selectedPlan.planCode)) {
    const plancode = eligibilities.rider.selectedPlan.planCode
    switch (plancode) {
      case 'MHPC1J':
      case 'MHPC1S':
      case 'MHPC2J':
      case 'MHPC2S':
      case 'MHPC3S':
      case 'MHPC4S':
      case 'MHPC5S':
      case 'MHPC6S':
        errorMessage = riderMessages.RULE_MHP_COPAYMENT_MESSAGE
        break
      default:
        errorMessage =
          _.get(riderMessages, `RULE_MHP_${eligibilities.rider.selectedPlan.planCode}_MESSAGE`) ||
          riderMessages.RULE_MESSAGE_NOT_FOUND
    }
  }
  return {
    type: rule.validationType,
    message: errorMessage,
  }
}
