// @flow
import type { DisplayProduct } from 'core/service/display-product'
import {
  getIRetireBenefitData,
  getLifeRetireBenefitData,
  isIRetireProduct,
  isLifeRetireProduct,
} from 'core/service/basic-plan/benefit'
import { wrapWordingToTextJustify } from 'core/service/benefit-illustration/utils/wording-wrapper'
import MESSAGES from 'core/data-model/constants/bi-messages'

type RetirementProductBenefitsProps = {
  displayProduct: DisplayProduct,
  sumAssured: number,
}

const RetirementProductBenefits = ({ displayProduct, sumAssured }: RetirementProductBenefitsProps) => {
  let benefitData = null
  if (isLifeRetireProduct(displayProduct.basicPlanCode)) {
    benefitData = getLifeRetireBenefitData()
  } else if (isIRetireProduct(displayProduct.basicPlanCode)) {
    benefitData = getIRetireBenefitData()
  } else {
    return null
  }

  return (
    <div id="product-benefits-content">
      <p>
        <b>{MESSAGES.BASIC_PLAN_BENEFITS}</b>
      </p>
      <ol id="benefit-list">
        <li>{benefitData.coverageAmountDetails}</li>
        <li>{benefitData.pensionCoveragePeriod}</li>
        <li>
          {' '}
          <div dangerouslySetInnerHTML={{ __html: `${wrapWordingToTextJustify(benefitData.deathBenefit)}` }}></div>
        </li>
      </ol>
    </div>
  )
}

export default RetirementProductBenefits
