// @flow
import type { BasicPremium$, ModelFactor, SumAssured$, TotalPremium$ } from 'core/data-model/basic-plan'
import DEFAULTS from 'core/data-model/constants/defaults'
import CONSTANTS from 'core/data-model/constants/values'
import VALUES from 'core/data-model/constants/values'
import type { Rider, RiderState } from 'core/data-model/rider'
import { validateBasicPremium } from 'core/service/basic-plan/validation'
import type { DisplayProduct } from 'core/service/display-product'
import { isNil, isNotNil } from 'core/service/lib/type-check'

import { getDistributorGroups, getUserDistributorGroup } from 'identity/selectors'
import _ from 'lodash'
import {
  getFlexiHealthSelectedPlanCode,
  getFlexiHealthSelectedPlanName,
} from 'quick-quote/product-flexi-health/coverage-plan/selectors'
import {
  getIhealthySelectedPlanCode,
  getIhealthySelectedPlanName,
} from 'quick-quote/product-health/coverage-plan/selectors'
import {
  getIhealthyUltraSelectedPlanName,
  getIhealthyUltraSelectedPlanCode,
} from 'quick-quote/product-ihealthy-ultra/coverage-plan/selectors'
import {
  getICareSelectedPlanCode,
  getICareSelectedPlanName,
} from 'quick-quote/product-protection/coverage-plan/selectors'
import { getSelectedDisplayProduct, getSelectedDisplayProductCode } from 'quick-quote/product-selection/selectors'

import type { AppState } from 'quick-quote/reducers'

import { createSelector } from 'reselect'

export const getSumAssured = (state: AppState): number => state.coveragePlan.selectedBasicPlan.sumAssured.value
export const getSumAssured$ = (state: AppState): SumAssured$ => state.coveragePlan.selectedBasicPlan.sumAssured
export const getBasicPremium = (state: AppState): number => state.coveragePlan.selectedBasicPlan.premium
export const getFullBasicPremium = (state: AppState): number => state.coveragePlan.selectedBasicPlan.fullBasicPremium
export const getFullAnnualBasicPremium = (state: AppState): number =>
  state.coveragePlan.selectedBasicPlan.fullAnnualBasicPremium
export const getCampaignDiscountMessage = (state: AppState): string[] =>
  state.coveragePlan.selectedBasicPlan.campaignDiscountMessage
export const getCampaignDiscountEndDate = (state: AppState): string =>
  state.coveragePlan.selectedBasicPlan.campaignDiscountEndDate
export const getBasicPlanErrorMessage = (state: AppState): string => state.coveragePlan.selectedBasicPlan.error
export const getBasicPremiumState = (state: AppState): number => state.coveragePlan.premiumState
export const getTotalPremium$ = (state: AppState): TotalPremium$ => state.coveragePlan.totalPremium
export const getPensionType = (state: AppState): string => state.coveragePlan.pensionType
export const haveBasicPremiumState = (state: AppState): boolean => state.coveragePlan.premiumState !== -1
export const haveTotalPremiumState = (state: AppState): boolean => state.coveragePlan.totalPremium.value !== -1
export const getTotalRiderPremium = (state: AppState): number => state.coveragePlan.totalRiderPremium
export const getRiders = (state: AppState): Rider[] => state.riders.payload
export const getRidersStatus = (state: AppState): string => state.riders.status
export const getModelFactors = (state: AppState): ModelFactor[] => state.modelFactors.payload
export const getModelFactorsStatus = (state: AppState): string => state.modelFactors.status
export const getSelectedModelFactorID = (state: AppState): string => state.coveragePlan.selectedModelFactorID
export const getcoveragePlanStartDate = (state: AppState): Date => state.coveragePlan.startDate
export const getSelectedPlan = (state: AppState): string => state.coveragePlan.selectedPlan

export const getSelectedBasicCoveragePlan = createSelector(
  (state: AppState) => state.coveragePlan.selectedBasicPlan,
  getSelectedDisplayProductCode,
  (basicPlan, basicPlanCode: string) => ({
    ...basicPlan,
    code: basicPlanCode,
  })
)

export const getSelectedModelFactor = createSelector(
  getModelFactors,
  getSelectedModelFactorID,
  (modelFactors: ModelFactor[], selectedModelFactorID: string): ?ModelFactor =>
    _.find(modelFactors, { id: selectedModelFactorID })
)

export const getSelectedModelFactorValue = createSelector(getSelectedModelFactor, (modelFactor): number =>
  modelFactor ? modelFactor.factor : 1
)

export const getSelectedModelFactorPeriod = createSelector(
  getSelectedModelFactor,
  (selectedModelFactor: ?ModelFactor): number => (selectedModelFactor ? selectedModelFactor.periods : 0)
)

export const getSelectedModelFactorLabel = createSelector(
  getSelectedModelFactor,
  (selectedModelFactor: ?ModelFactor): string => _.get(selectedModelFactor, 'displayLabel', '-')
)

export const getAvailableModelFactors = createSelector(
  getSelectedDisplayProduct,
  getModelFactors,
  (basicPlan: DisplayProduct, modelFactors: ModelFactor[]): ModelFactor[] =>
    modelFactors.filter((modelFactor) => basicPlan.modelFactors.indexOf(modelFactor.id) > -1)
)

export const getOptionsLoanPeriod = createSelector(getSelectedDisplayProduct, (basicPlan: DisplayProduct) => {
  switch (basicPlan.basicPlanCode) {
    case 'MRTA_SIMPLY':
    case 'MRTA_PLUS':
    case 'MRTA_FULL':
    case 'MRTA_TRUNCATE':
      return DEFAULTS.DEFAULT_LOAN_PERIOD.MRTA
    case 'GLTSP':
      return DEFAULTS.DEFAULT_LOAN_PERIOD.GLTSP
    default:
      return []
  }
})

export const getModelFactorsForUser = (state: AppState): ModelFactor[] => {
  const basicPlan = getSelectedDisplayProduct(state)
  const userDistributorGroup = getUserDistributorGroup(state)
  const distributorGroups = getDistributorGroups(state)
  let modelFactors = getModelFactors(state)
  modelFactors = modelFactors.filter((modelFactor) => basicPlan.modelFactors.indexOf(modelFactor.id) > -1)
  const distributorGroupForUser = distributorGroups.find(
    (distributorGroup) => distributorGroup.id === userDistributorGroup
  )
  if (distributorGroupForUser) {
    const result = modelFactors.filter((modelFactor) => distributorGroupForUser.modelFactors.includes(modelFactor.id))
    return result
  }
  return modelFactors
}

export const getReadOnlyFields = createSelector(
  getSelectedDisplayProduct,
  (basicPlan: DisplayProduct) => basicPlan.readOnlyFields
)

export const getBasicPremium$ = createSelector(
  getBasicPremiumState,
  (state: AppState) => state.coveragePlan.selectedBasicPlan.premium,
  (basicPremiumState: number, basicPremium: number) => validateBasicPremium({ basicPremiumState }, basicPremium)
)

export const getTotalPremium = createSelector(getTotalPremium$, (totalPremium: TotalPremium$) => {
  if (isNil(totalPremium)) {
    return 0
  }
  return totalPremium.value
})

export const getAvailableRidersByCode = (state: AppState) => state.coveragePlan.riders

export const getAvailableRiderCodes = createSelector(getAvailableRidersByCode, (table) => {
  return Object.keys(table).sort(
    (a, b) => DEFAULTS.RIDERS_SORT_ORDER.indexOf(a) - DEFAULTS.RIDERS_SORT_ORDER.indexOf(b)
  )
})

export const getAvailableRiders = createSelector(
  getAvailableRiderCodes,
  getAvailableRidersByCode,
  (codes, table): (Rider & RiderState)[] => codes.map((code) => table[code]).filter(isNotNil)
)

export const getSelectedRiders = createSelector(getAvailableRiders, (availableRiders) =>
  availableRiders.filter((rider) => rider.isSelected && rider.isSelectable)
)

export const hasMHPRiderAvailableRider = createSelector(
  getAvailableRiders,
  (availableRiders: (Rider & RiderState)[]): boolean => {
    const riderMHP = _.find(availableRiders, (rider) => rider.code === 'MHP')
    return riderMHP ? true : false
  }
)

export const getSelectedPlanName = (state: AppState): string => {
  switch (state.selectedDisplayProduct.category) {
    case VALUES.HEALTH:
      return getIhealthySelectedPlanName(state)
    case VALUES.PROTECTION:
      return getICareSelectedPlanName(state)
    case VALUES.FLEXI_HEALTH:
      return getFlexiHealthSelectedPlanName(state)
    case VALUES.IHEALTHY_ULTRA:
      return getIhealthyUltraSelectedPlanName(state)
    default:
      return ''
  }
}

export const getSelectedPlanCode = (state: AppState): string => {
  switch (state.selectedDisplayProduct.category) {
    case VALUES.HEALTH:
      return getIhealthySelectedPlanCode(state)
    case VALUES.PROTECTION:
      return getICareSelectedPlanCode(state)
    case VALUES.FLEXI_HEALTH:
      return getFlexiHealthSelectedPlanCode(state)
    case VALUES.IHEALTHY_ULTRA:
      return getIhealthyUltraSelectedPlanCode(state)
    default:
      return ''
  }
}

export const _hasInvalidRiders = (selectedRiders: (Rider & RiderState)[]): boolean => {
  const selectedRiderInvalidMessages = selectedRiders.filter((rider) =>
    isNotNil(rider.errors.find((error) => error.type === CONSTANTS.VALIDATION))
  )
  return selectedRiderInvalidMessages.length > 0
}

const hasInvalidRiders = createSelector(getSelectedRiders, _hasInvalidRiders)

export const _hasFinishedPremium = (
  sumAssured$: SumAssured$,
  basicPremium: BasicPremium$,
  totalPremium$: TotalPremium$,
  foundInvalidRiders: boolean,
  basicPlanErrorMessage: string,
  selectedRiders: (Rider & RiderState)[],
  selectedDisplayProduct: DisplayProduct
): boolean => {
  const finished =
    sumAssured$.isOk &&
    basicPremium.isOk &&
    totalPremium$.isOk &&
    !foundInvalidRiders &&
    _.isEmpty(basicPlanErrorMessage)
  if (selectedDisplayProduct.type === CONSTANTS.CAMPAIGN_PRODUCT_TYPE) {
    const selectedRiderCodes = selectedRiders.map((rider) => rider.code)
    return (
      finished &&
      selectedDisplayProduct.requiredRiders != null &&
      selectedDisplayProduct.requiredRiders.every((riderCode) => {
        return selectedRiderCodes.indexOf(riderCode) > -1
      })
    )
  }
  return finished
}

export const getWealthDataSource = (state: AppState) => state.wealthInfo.dataSource

export const hasFinishedPremiumCalculation = createSelector(
  getSumAssured$,
  getBasicPremium$,
  getTotalPremium$,
  hasInvalidRiders,
  getBasicPlanErrorMessage,
  getSelectedRiders,
  getSelectedDisplayProduct,
  _hasFinishedPremium
)
