//@flow
import {
  Section,
  RowSpan,
  ColSpan,
  Center,
  Table,
  Bold,
} from 'core/service/pdf-generation/products/common/components/general'
import VALUE from 'core/data-model/constants/values'

import BIMESSAGES from 'core/data-model/constants/bi-messages'

type ProcessingStepType = {
  name: string,
  minorSteps: MinorStep[],
}

type MinorStep = {
  name: string,
  date: string,
  informPriceDate: string,
  note: string,
}

// ==================== Main View
export const ProcessingTimeline = (productCode: string) => {
  const smallFontSize = 12
  const page = (processingSteps, headerMessage, disclaimer, disclaimer_second) =>
    Section(headerMessage, [
      ...processingSteps.map((p) => ProcessingTimelineTable(p)),
      { text: disclaimer, fontSize: smallFontSize, marginTop: 4 },
      disclaimer_second ? { text: disclaimer_second, fontSize: smallFontSize } : {},
    ])

  switch (productCode) {
    case BIMESSAGES.BI_TYPE_PRODUCT_CODE_IINVEST:
      return [
        page(
          [iInvestProcessingStep1, iInvestProcessingStep2, iInvestProcessingStep3],
          BIMESSAGES.PROCESSING_TIMELINE_HEADER,
          BIMESSAGES.PROCESSING_TIMELINE_DISCLAIMER_2
        ),
        page(
          [iInvestProcessingStep4, iInvestProcessingStep5],
          BIMESSAGES.EXTENED_PROCESSING_TIMELINE_HEADER,
          BIMESSAGES.PROCESSING_TIMELINE_DISCLAIMER_2,
          BIMESSAGES.PROCESSING_TIMELINE_SECOND_DISCLAIMER_2
        ),
      ]
    case BIMESSAGES.BI_TYPE_PRODUCT_CODE_IWEALTHY:
      return [
        page(
          [iWealthyProcessingStep1, iWealthyProcessingStep2, iWealthyProcessingStep3],
          BIMESSAGES.PROCESSING_TIMELINE_HEADER,
          BIMESSAGES.PROCESSING_TIMELINE_DISCLAIMER_1,
          BIMESSAGES.PROCESSING_TIMELINE_SECOND_DISCLAIMER_1
        ),
        page(
          [iWealthyProcessingStep4, iWealthyProcessingStep5],
          BIMESSAGES.EXTENED_PROCESSING_TIMELINE_HEADER,
          BIMESSAGES.PROCESSING_TIMELINE_DISCLAIMER_2,
          BIMESSAGES.PROCESSING_TIMELINE_SECOND_DISCLAIMER_2
        ),
      ]
    case BIMESSAGES.BI_TYPE_PRODUCT_CODE_RPUDR:
      return []
    default:
      return []
  }
}

// =================== Model - iWealthy
const iWealthyProcessingStep1: ProcessingStepType = {
  name: 'การขอเอาประกันภัยใหม่',
  minorSteps: [
    {
      name: 'วันที่บริษัทอนุมัติกรมธรรม์พร้อมได้รับชำระเบี้ยประกันภัย',
      date: 'T',
      informPriceDate: '',
      note: '',
    },
    {
      name: 'บริษัทส่งคำสั่งซื้อหน่วยลงทุนไปยังบริษัทจัดการกองทุน',
      date: 'T + 1',
      informPriceDate: 'ผู้เอาประกันภัยได้รับราคาขายหน่วยลงทุน ในวันที่ T+2* หรือ T+3*',
      note: '',
    },
    {
      name: 'บริษัทจะมีการยืนยันการสั่งซื้อหน่วยลงทุน\nพร้อมเล่มกรมธรรม์ให้กับผู้เอาประกันภัย',
      date: 'T + 10',
      note: '',
      informPriceDate:
        'จำนวนหน่วยลงทุนที่ผู้เอาประกันภัยจะได้รับ เท่ากับ \n(เบี้ยประกันภัย หักด้วย ค่าธรรมเนียมต่าง ๆ ในเดือนแรกของกรมธรรม์) \nหารด้วยราคาขายหน่วยลงทุน ณ สิ้นวันที่ T+1',
    },
  ],
}

const iWealthyProcessingStep2: ProcessingStepType = {
  name: 'การชำระเบี้ยประกันภัยเพิ่มเติมพิเศษ',
  minorSteps: [
    {
      name:
        'วันที่บริษัทได้รับใบคำขอและเอกสารอื่นๆ ครบถ้วน พร้อมได้รับ\nชำระเบี้ยประกันภัยเพิ่มเติมพิเศษครบเต็มจำนวน ก่อน 14.00 น.\n ในกรณีหลัง 14.00 น. จะนับวัน T เป็นวันทำการถัดไป',
      date: 'T',
      informPriceDate: '',
      note: '',
    },
    {
      name: 'บริษัทส่งคำสั่งซื้อหน่วยลงทุนไปยังบริษัทจัดการกองทุน',
      date: 'T + 2',
      informPriceDate: 'ผู้เอาประกันภัยได้รับราคาขายหน่วยลงทุน ภายในวันที่ T+3** หรือ T+4**',
      note: '',
    },
    {
      name: 'บริษัทส่งจดหมายแจ้งผลการเพิ่มเบี้ยประกันภัยเพิ่มเติมพิเศษ',
      date: 'T + 10',
      note: '',
      informPriceDate:
        'จำนวนหน่วยลงทุนที่ผู้เอาประกันภัยจะได้รับ เท่ากับ\n(เบี้ยประกันภัยเพิ่มเติมพิเศษ หักด้วย ค่าใช้จ่ายในการดำเนินการประกันภัยสำหรับเบี้ย\nประกันภัยเพิ่มเติมพิเศษ) หารด้วยราคาขายหน่วยลงทุน ณ สิ้นวัน T+1',
    },
  ],
}

const iWealthyProcessingStep3: ProcessingStepType = {
  name: 'การถอนเงินจากกรมธรรม์บางส่วน',
  minorSteps: [
    {
      name:
        'วันที่บริษัทได้รับใบคำขอและเอกสารอื่นๆ ครบถ้วน ก่อน 14.00 น.\n และบริษัทพิจารณาอนุมัติตามเงื่อนไขการถอนเงินจากกรมธรรม์ \nในกรณีหลัง 14.00 น จะนับวัน T เป็นวันทำการถัดไป',
      date: 'T',
      informPriceDate: '',
      note: '',
    },
    {
      name: 'บริษัทส่งคำสั่งขายหน่วยลงทุนไปยังบริษัทจัดการกองทุน',
      date: 'T + 1',
      informPriceDate: 'ผู้เอาประกันภัยได้รับราคาซื้อคืนหน่วยลงทุน ในวันที่ T+2* หรือ T+3*',
      note: '',
    },
    {
      name: 'บริษัทจะส่งจดหมายยืนยันการขายหน่วยลงทุน พร้อมโอนเงินผ่านบัญชีธนาคารให้กับผู้เอาประกันภัย',
      date: 'T + 5',
      note: '',
      informPriceDate:
        'จำนวนเงินที่ผู้เอาประกันภัยจะได้รับ เท่ากับ \n(ราคารับซื้อคืนหน่วยลงทุน * จำนวนหน่วยลงทุนที่ขาย)\n หักด้วย ค่าธรรมเนียมในการถอนเงินจากกรมธรรม์ (ถ้ามี)',
    },
  ],
}

const iWealthyProcessingStep4: ProcessingStepType = {
  name: 'การเวนคืนกรมธรรม์',
  minorSteps: [
    {
      name:
        'วันที่บริษัทได้รับใบคำขอและเอกสารอื่นๆครบถ้วน ก่อน 14.00น.\n และบริษัทพิจารณาอนุมัติตามเงื่อนไขการเวนคืนกรมธรรม์ \nในกรณีหลัง 14.00 น. จะนับวัน T เป็นวันทำการถัดไป',
      date: 'T',
      informPriceDate: '',
      note: '',
    },
    {
      name: 'บริษัทส่งคำสั่งขายหน่วยลงทุนไปยังบริษัทจัดการกองทุน',
      date: 'T + 1',
      informPriceDate: 'ผู้เอาประกันภัยได้รับราคาซื้อคืนหน่วยลงทุน ในวันที่ T+2* หรือ T+3*',
      note: '',
    },
    {
      name: 'บริษัทจะส่งจดหมายยืนยันการขายหน่วยลงทุน พร้อมโอนเงินผ่านบัญชีธนาคารให้กับผู้เอาประกันภัย',
      date: 'T + 5',
      informPriceDate:
        'มูลค่าเวนคืนเงินสด ที่ผู้เอาประกันภัยจะได้รับ เท่ากับ \n(ราคารับซื้อคืนของหน่วยลงทุนแต่ละกองทุนภายใต้กรมธรรม์ * จำนวนหน่วยลงทุน)\n หักด้วย ค่าธรรมเนียมในการถอนเงินจากกรมธรรม์ (ถ้ามี)',
      note: '',
    },
  ],
}

const iWealthyProcessingStep5: ProcessingStepType = {
  name: 'การสับเปลี่ยนกองทุน',
  minorSteps: [
    {
      name:
        'วันที่บริษัทได้รับใบคำขอและเอกสารอื่นๆ ครบถ้วน ก่อน 14.00น. \nและบริษัทพิจารณาอนุมัติตามเงื่อนไขการสับเปลี่ยนกองทุน \nในกรณีหลัง 14.00 น. จะนับวัน T เป็นวันทำการถัดไป',
      date: 'T',
      informPriceDate: '',
      note: '',
    },
    {
      name: 'บริษัทส่งคำสั่งขายหน่วยลงทุนไปยังบริษัทจัดการกองทุน',
      date: 'T + 1',
      informPriceDate:
        'ผู้เอาประกันภัยได้รับราคาซื้อคืนหน่วยลงทุน ในวันที่ T+2* หรือ T+3* \nจำนวนเงินที่จะนำไปซื้อหน่วยกองทุนใหม่ เท่ากับ \n(ราคารับซื้อคืนของหน่วยลงทุนแต่ละกองทุนที่เลือก * จำนวนหน่วยลงทุน) หักด้วย ค่าธรรมเนียมในการสับเปลี่ยนกองทุน (ถ้ามี)',
      note: '',
    },
    {
      name:
        'บริษัทส่งคำสั่งซื้อหน่วยลงทุนไปยังบริษัทจัดการกองทุน เมื่อได้รับ\nเงินค่าขายคืนหน่วยลงทุนของกองทุนต้นทางครบทุกกองทุน',
      date: 'T + X + 1',
      informPriceDate:
        'ผู้เอาประกันภัยได้รับราคาขายหน่วยลงทุน ในวันที่ T+X+2** หรือ T+X+3**\n จำนวนหน่วยลงทุนที่ผู้เอาประกันภัยจะได้รับ เท่ากับ\nจำนวนเงินที่จะนำไปซื้อหน่วยกองทุนใหม่ หารด้วย ราคาขายหน่วยลงทุน \nณ สิ้นวันที่ T+X+1',
      note: 'X คือ จำนวนวันที่บริษัททราบราคาจากการขายคืนหน่วยลงทุนกองทุนต้นทางครบทุกกองทุน',
    },
    {
      name: 'บริษัทส่งหนังสือยืนยันการสับเปลี่ยนกองทุนให้ผู้เอาประกันภัย',
      date: 'T + 10',
      informPriceDate: '',
      note: '',
    },
  ],
}

// =================== Model - iInvest
const iInvestProcessingStep1: ProcessingStepType = {
  name: 'การขอเอาประกันภัยใหม่',
  minorSteps: [
    {
      name:
        'วันที่บริษัทได้รับใบคำขอและเอกสารอื่นๆ ครบถ้วน\n พร้อมได้รับชำระเบี้ยประกันภัยครบเต็มจำนวน ก่อน 14.00 น. \nในกรณีหลัง 14.00 น. จะนับวัน T เป็นวันทำการถัดไป',
      date: 'T',
      informPriceDate: '',
      note: '',
    },
    {
      name: 'บริษัทส่งคำสั่งซื้อหน่วยลงทุนไปยังบริษัทจัดการกองทุน',
      date: 'T + 1',
      informPriceDate: 'ผู้เอาประกันภัยได้รับราคาขายหน่วยลงทุน ในวันที่ T+2* หรือ T+3*',
      note: '',
    },
    {
      name: 'บริษัทจะมีการยืนยันการสั่งซื้อหน่วยลงทุน\nพร้อมเล่มกรมธรรม์ให้กับผู้เอาประกันภัย',
      date: 'T + 10',
      note: '',
      informPriceDate:
        'จำนวนหน่วยลงทุนที่ผู้เอาประกันภัยจะได้รับ เท่ากับ \n(เบี้ยประกันภัย หักด้วย ค่าธรรมเนียมต่าง ๆ ในเดือนแรกของกรมธรรม์) \nหารด้วยราคาขายหน่วยลงทุน ณ สิ้นวันที่ T+1',
    },
  ],
}

const iInvestProcessingStep2: ProcessingStepType = {
  name: 'การชำระเบี้ยประกันภัยเพิ่มเติมพิเศษ',
  minorSteps: [
    {
      name:
        'วันที่บริษัทได้รับใบคำขอและเอกสารอื่นๆ ครบถ้วน พร้อมได้รับชำระ \nเบี้ยประกันภัยเพิ่มเติมพิเศษครบเต็มจำนวน ก่อน 14.00 น.\nในกรณีหลัง 14.00 น. จะนับวัน T เป็นวันทำการถัดไป',
      date: 'T',
      informPriceDate: '',
      note: '',
    },
    {
      name: 'บริษัทส่งคำสั่งซื้อหน่วยลงทุนไปยังบริษัทจัดการกองทุน',
      date: 'T + 1',
      informPriceDate: 'ผู้เอาประกันภัยได้รับราคาขายหน่วยลงทุน ในวันที่ T+2* หรือ T+3*',
      note: '',
    },
    {
      name: 'บริษัทส่งจดหมายแจ้งผลการเพิ่มเบี้ยประกันภัยเพิ่มเติมพิเศษ',
      date: 'T + 10',
      note: '',
      informPriceDate:
        'จำนวนหน่วยลงทุนที่ผู้เอาประกันภัยจะได้รับ เท่ากับ \n(เบี้ยประกันภัยเพิ่มเติมพิเศษ หักด้วย ค่าใช้จ่ายในการดำเนินการประกันภัยสำหรับเบี้ยประกันภัยเพิ่มเติมพิเศษ)\nหารด้วยราคาขายหน่วยลงทุน ณ สิ้นวันที่ T+1',
    },
  ],
}

const iInvestProcessingStep3: ProcessingStepType = {
  name: 'การถอนเงินจากกรมธรรม์บางส่วน',
  minorSteps: [
    {
      name:
        'วันที่บริษัทได้รับใบคำขอและเอกสารอื่นๆ ครบถ้วน ก่อน 14.00 น. \nและบริษัทพิจารณาอนุมัติตามเงื่อนไขการถอนเงินจากกรมธรรม์ \nในกรณีหลัง 14.00 น. จะนับวัน T เป็นวันทำการถัดไป',
      date: 'T',
      informPriceDate: '',
      note: '',
    },
    {
      name: 'บริษัทส่งคำสั่งขายหน่วยลงทุนไปยังบริษัทจัดการกองทุน',
      date: 'T + 1',
      informPriceDate: 'ผู้เอาประกันภัยได้รับราคาซื้อคืนหน่วยลงทุน ในวันที่ T+2* หรือ T+3*',
      note: '',
    },
    {
      name: 'บริษัทจะส่งจดหมายยืนยันการขายหน่วยลงทุน พร้อมโอนเงินผ่านบัญชีธนาคาร ให้กับผู้เอาประกันภัย',
      date: 'T + 5',
      note: '',
      informPriceDate:
        'จำนวนเงินที่ผู้เอาประกันภัยจะได้รับ เท่ากับ \n(ราคารับซื้อคืนหน่วยลงทุน x จำนวนหน่วยลงทุนที่ขาย) \nหักด้วย ค่าธรรมเนียมในการถอนเงินจากกรมธรรม์ (ถ้ามี)',
    },
  ],
}

const iInvestProcessingStep4: ProcessingStepType = {
  name: 'การเวนคืนกรมธรรม์',
  minorSteps: [
    {
      name:
        'วันที่บริษัทได้รับใบคำขอและเอกสารอื่นๆ ครบถ้วน ก่อน 14.00 น. \nและบริษัทพิจารณาอนุมัติตามเงื่อนไขการเวนคืนกรมธรรม์\nในกรณีหลัง 14.00 น. จะนับวัน T เป็นวันทำการถัดไป',
      date: 'T',
      informPriceDate: '',
      note: '',
    },
    {
      name: 'บริษัทส่งคำสั่งขายหน่วยลงทุนไปยังบริษัทจัดการกองทุน',
      date: 'T + 1',
      informPriceDate: 'ผู้เอาประกันภัยได้รับราคาซื้อคืนหน่วยลงทุน ในวันที่ T+2* หรือ T+3*',
      note: '',
    },
    {
      name: 'บริษัทจะส่งจดหมายยืนยันการขายหน่วยลงทุน พร้อมโอนเงินผ่านบัญชี\nธนาคารให้กับผู้เอาประกันภัย',
      date: 'T + 5',
      informPriceDate:
        'มูลค่าเวนคืนเงินสด ที่ผู้เอาประกันภัยจะได้รับ เท่ากับ \n(ราคารับซื้อคืนของหน่วยลงทุนแต่ละกองทุนภายใต้กรมธรรม์ x จำนวนหน่วยลงทุน) \nหักด้วย ค่าธรรมเนียมในการถอนเงินจากกรมธรรม์ (ถ้ามี)',
      note: '',
    },
  ],
}

const iInvestProcessingStep5: ProcessingStepType = {
  name: 'การสับเปลี่ยนกองทุน',
  minorSteps: [
    {
      name:
        'วันที่บริษัทได้รับใบคำขอและเอกสารอื่นๆ ครบถ้วน ก่อน 14.00 น. \nและบริษัทพิจารณาอนุมัติตามเงื่อนไขการสับเปลี่ยนกองทุน \nในกรณีหลัง 14.00 น. จะนับวัน T เป็นวันทำการถัดไป',
      date: 'T',
      informPriceDate: '',
      note: '',
    },
    {
      name: 'บริษัทส่งคำสั่งขายหน่วยลงทุนไปยังบริษัทจัดการกองทุน',
      date: 'T + 1',
      informPriceDate:
        'ผู้เอาประกันภัยได้รับราคาซื้อคืนหน่วยลงทุน ในวันที่ T+2* หรือ T+3* \nจำนวนเงินที่จะนำไปซื้อหน่วยกองทุนใหม่ เท่ากับ \n(ราคารับซื้อคืนของหน่วยลงทุนแต่ละกองทุนที่เลือก x จำนวนหน่วยลงทุน) \nหักด้วย ค่าธรรมเนียมในการสับเปลี่ยนกองทุน (ถ้ามี)',
      note: '',
    },
    {
      name:
        'บริษัทส่งคำสั่งซื้อหน่วยลงทุนไปยังบริษัทจัดการกองทุน \nเมื่อได้รับเงินค่าขายคืนหน่วยลงทุนของกองทุนต้นทางครบทุกกองทุน',
      date: 'T + X + 1',
      informPriceDate:
        'ผู้เอาประกันภัยได้รับราคาขายหน่วยลงทุน ในวันที่ T+X+2** หรือ T+X+3** \nจำนวนหน่วยลงทุนที่ผู้เอาประกันภัยจะได้รับ เท่ากับ\nจำนวนเงินที่จะนำไปซื้อหน่วยกองทุนใหม่ หารด้วย ราคาขายหน่วยลงทุน ณ สิ้น\nวันที่ T+X+1',
      note: 'X คือ จำนวนวันที่บริษัททราบราคาจากการขายคืนหน่วยลงทุนกองทุนต้นทางครบทุกกองทุน',
    },
    {
      name: 'บริษัทส่งหนังสือยืนยันการสับเปลี่ยนกองทุนให้ผู้เอาประกันภัย',
      date: 'T + 10',
      informPriceDate: '',
      note: '',
    },
  ],
}

// =================== View

export const MinorStepRow = (step: MinorStep, processingStep: ProcessingStepType): *[] => {
  const mainRow = [
    RowSpan(Bold(processingStep.name), spanSize(processingStep)),
    Center(RowSpan(step.date, spanDateSize(step))),
    step.name,
    step.informPriceDate,
  ]
  const styledMainRow = mainRow.map((item) => SmallFontSize(item))
  const noteRow = ['', '', SmallFontSize(ColSpan(step.note, 2)), '']
  if (step.note === '') return [styledMainRow]
  return [styledMainRow, noteRow]
}

export const ProcessingStep = (processingStep: ProcessingStepType): *[] => {
  return processingStep.minorSteps.reduce((result, step) => result.concat(MinorStepRow(step, processingStep)), [])
}

export const ProcessingTimelineTable = (processingStep: ProcessingStepType) => {
  const HeaderColumns = [
    BIMESSAGES.TOPIC_TABLE_HEADER,
    BIMESSAGES.DATE,
    BIMESSAGES.STEP_HEADER,
    BIMESSAGES.INFORM_PRICE_DATE_HEADER,
  ]
  const TableHeaderStyle = (item: string): Object => Bold({ text: item, style: 'tableHeader', fontSize: 12 })
  return Table([HeaderColumns.map((column) => TableHeaderStyle(column)), ...ProcessingStep(processingStep)], {
    widths: [140, 50, 250, '*'],
    layout: {
      hLineColor: VALUE.MEDIUM_BLUE,
      vLineColor: VALUE.MEDIUM_BLUE,
    },
    marginBottom: 10,
  })
}

// ============== Helper
export const spanSize = (processingStep: ProcessingStepType): number => {
  const optionRowSizes = processingStep.minorSteps.filter((minorStep) => minorStep.note !== '').length
  return processingStep.minorSteps.length + optionRowSizes
}

const spanDateSize = (step: MinorStep): number => {
  return step.note === '' ? 1 : 2
}

const SmallFontSize = (item: Object | string): Object => {
  if (typeof item === 'object') return { ...item, fontSize: 12 }
  return { text: item, fontSize: 12 }
}
