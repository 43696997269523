// @flow
import type { InvalidPayerOccupationFactorRule } from 'core/data-model/rider/rules'
import type { RiderEligibilities } from 'core/data-model/rider'
import type { ValidationMessage } from 'core/data-model/validation'
import riderMessages from 'core/data-model/constants/validation-messages'

export const checkInvalidPayerOccupationFactorRule = (
  rule: InvalidPayerOccupationFactorRule,
  eligibilities: RiderEligibilities
): ValidationMessage => {
  const errorMessage =
    eligibilities.payerOccupationFactor === rule.value ? riderMessages.RULE_PAYER_OCCUPATION_FACTOR : ''

  return {
    type: rule.validationType,
    message: errorMessage,
  }
}
