//@flow
import _ from 'lodash'
import { formatNumber } from 'core/service/lib/number-format'
import { buildHeaderTable } from 'core/service/pdf-generation/utils/builder'
import MESSAGES from 'core/data-model/constants/bi-messages'
import CONSTANTS from 'core/data-model/constants/messages'
import type { PolicyValueTableProps } from 'core/service/benefit-illustration/policy-value'
import { _getMessageUnderiShieldPolicyValueTable } from 'core/service/benefit-illustration/policy-value'
import Mustache from 'mustache'
import {
  isLifeProtectProduct,
  isProtectionProduct,
  isLifeEnsureProduct,
  isSukhapabDekDeeProduct,
  isS7Product,
  isLifeReadyProduct,
} from 'core/service/basic-plan/benefit'
import { calculatePageAmountPolicyValueTable } from 'core/service/pdf-generation/products/common/logic'
import type { PolicyValue, YearEndPolicyValue } from 'core/service/benefit-illustration'
import { type DisplayProduct } from 'core/service/display-product'

// this is a hack for line wrapping in pdf which is not
// support Thai language yet. Once it is implemented
// this should be removed
export const _breakLine = (text: string) => {
  const premiumPattern = /^(เบี้ยประกันภัย)(สัญญา.+)$/
  const deathBenefitPattern = /^(ความคุ้มครอง)(การเสียชีวิต)$/
  const surrenderCashPattern = /^(มูลค่าเวนคืน)(เงินสด\**)$/
  const savingWithCompany = /^(กรณีรับเป็น)(เงินสด)$/

  const patterns = [premiumPattern, deathBenefitPattern, surrenderCashPattern, savingWithCompany]

  // add space to trick pdfmake to do line wrapping for us
  const breakByFirstMatched = ([p, ...rest]) => (text.match(p) ? text.replace(p, '$1 $2') : breakByFirstMatched(rest))

  return breakByFirstMatched(patterns)
}

const policyValueTable = {
  title: MESSAGES.POLICY_VALUE_TABLE,
  headers: [
    CONSTANTS.AGE,
    MESSAGES.END_OF_THE_YEAR,
    _breakLine(MESSAGES.CUMULATIVE_PREMIUM),
    _breakLine(MESSAGES.BASIC_PREMIUM),
    _breakLine(MESSAGES.RIDER_PREMIUM),
    MESSAGES.EACH_TOTAL_PREMIUM,
    _breakLine(MESSAGES.DEATH_BENEFIT),
    _breakLine(MESSAGES.CASH_SURRENDER_VALUE) + '**',
  ],
}

const rowHeightConfig = { lineHeight: 0.75, margin: [0, -3, 0, 0] }

const build25PLPolicyValueRow = (yearEndPolicy: YearEndPolicyValue) => [
  {
    text: yearEndPolicy.age.value,
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.yearEndPolicy,
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.cumulativeBasicPremium === 0 ? '-' : formatNumber(yearEndPolicy.cumulativeBasicPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.annualBasicPremium === 0 ? '-' : formatNumber(yearEndPolicy.annualBasicPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.annualRiderPremium === 0 ? '-' : formatNumber(yearEndPolicy.annualRiderPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.annualTotalPremium === 0 ? '-' : formatNumber(yearEndPolicy.annualTotalPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text:
      yearEndPolicy.cashDrop === 0
        ? '-'
        : // $$FlowFixMe
          formatNumber(yearEndPolicy.cashDrop, 0, true),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text:
      yearEndPolicy.lowSavingCashDrop === 0
        ? '-'
        : // $$FlowFixMe
          formatNumber(yearEndPolicy.lowSavingCashDrop, 0, true),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text:
      yearEndPolicy.mediumSavingCashDrop === 0
        ? '-'
        : // $$FlowFixMe
          formatNumber(yearEndPolicy.mediumSavingCashDrop, 0, true),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text:
      yearEndPolicy.highSavingCashDrop === 0
        ? '-'
        : // $$FlowFixMe
          formatNumber(yearEndPolicy.highSavingCashDrop, 0, true),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.deathBenefit === 0 ? '-' : formatNumber(yearEndPolicy.deathBenefit, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.surrenderCash === 0 ? '-' : formatNumber(yearEndPolicy.surrenderCash),
    alignment: 'right',
    ...rowHeightConfig,
  },
]

const build25PLTotalPolicyValueRow = (policyValue: PolicyValue) => [
  '',
  '',
  '',
  {
    text: policyValue.subtotalAnnualBasicPremium === 0 ? '-' : formatNumber(policyValue.subtotalAnnualBasicPremium, 2),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  {
    text: policyValue.subtotalAnnualRiderPremium === 0 ? '-' : formatNumber(policyValue.subtotalAnnualRiderPremium, 2),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  {
    text: policyValue.grandTotalPremium === 0 ? '-' : formatNumber(policyValue.grandTotalPremium, 2),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  {
    text:
      // $$FlowFixMe
      policyValue.subtotalCashDrop === 0 ? '-' : formatNumber(_.get(policyValue, 'subtotalCashDrop'), 0, true),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  {
    text:
      // $$FlowFixMe
      policyValue.subtotalLowSavingCashDrop === 0
        ? '-'
        : formatNumber(_.get(policyValue, 'subtotalLowSavingCashDrop'), 0, true),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  {
    text:
      // $$FlowFixMe
      policyValue.subtotalMediumSavingCashDrop === 0
        ? '-'
        : formatNumber(_.get(policyValue, 'subtotalMediumSavingCashDrop'), 0, true),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  {
    text:
      // $$FlowFixMe
      policyValue.subtotalHighSavingCashDrop === 0
        ? '-'
        : formatNumber(_.get(policyValue, 'subtotalHighSavingCashDrop'), 0, true),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  '',
  '',
]

const buildPolicyValueRow = (yearEndPolicy: YearEndPolicyValue) => [
  {
    text: yearEndPolicy.age.value,
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.yearEndPolicy,
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.cumulativeBasicPremium === 0 ? '-' : formatNumber(yearEndPolicy.cumulativeBasicPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.annualBasicPremium === 0 ? '-' : formatNumber(yearEndPolicy.annualBasicPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.annualRiderPremium === 0 ? '-' : formatNumber(yearEndPolicy.annualRiderPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.annualTotalPremium === 0 ? '-' : formatNumber(yearEndPolicy.annualTotalPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.deathBenefit === 0 ? '-' : formatNumber(yearEndPolicy.deathBenefit, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.surrenderCash === 0 ? '-' : formatNumber(yearEndPolicy.surrenderCash),
    alignment: 'right',
    ...rowHeightConfig,
  },
]

const buildTotalPolicyValueRow = (policyValue: PolicyValue) => [
  '',
  '',
  '',
  {
    text: policyValue.subtotalAnnualBasicPremium === 0 ? '-' : formatNumber(policyValue.subtotalAnnualBasicPremium, 2),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  {
    text: policyValue.subtotalAnnualRiderPremium === 0 ? '-' : formatNumber(policyValue.subtotalAnnualRiderPremium, 2),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  {
    text: policyValue.grandTotalPremium === 0 ? '-' : formatNumber(policyValue.grandTotalPremium, 2),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  '',
  '',
]

let disclaimer = {
  stack: [`**${MESSAGES.NOTE}:`, MESSAGES.POLICY_VALUE_TABLE_NOTE_1, MESSAGES.POLICY_VALUE_TABLE_NOTE_2],
  style: 'disclaimer',
  pageBreak: '',
}

let disclaimerICare = {
  stack: [
    `${MESSAGES.NOTE}:`,
    MESSAGES.POLICY_VALUE_TABLE_DISCLAIMER_ONE,
    MESSAGES.POLICY_VALUE_TABLE_DISCLAIMER_TWO,
    MESSAGES.POLICY_VALUE_TABLE_ICARE_DISCLAIMER_THREE,
    MESSAGES.POLICY_VALUE_TABLE_DISCLAIMER_FOUR,
  ],
  style: 'disclaimer',
  pageBreak: '',
}

let disclaimerLifeProtect18 = {
  stack: [
    `${MESSAGES.NOTE}:`,
    MESSAGES.POLICY_VALUE_TABLE_DISCLAIMER_ONE,
    MESSAGES.POLICY_VALUE_TABLE_DISCLAIMER_TWO,
    MESSAGES.POLICY_VALUE_TABLE_DISCLAIMER_THREE,
    MESSAGES.POLICY_VALUE_TABLE_DISCLAIMER_FOUR,
  ],
  style: 'disclaimer',
  pageBreak: '',
}

let disclaimerLifeReady = {
  stack: [
    `${MESSAGES.NOTE}:`,
    MESSAGES.POLICY_VALUE_TABLE_DISCLAIMER_ONE,
    MESSAGES.POLICY_VALUE_TABLE_DISCLAIMER_TWO,
    MESSAGES.POLICY_VALUE_TABLE_DISCLAIMER_THREE,
    { text: '', pageBreak: 'after' },
  ],
  style: 'disclaimer',
  // unbreakable: true,
}

let disclaimerSukhapabDekDee = {
  stack: [
    `${MESSAGES.NOTE}:`,
    MESSAGES.POLICY_VALUE_TABLE_DISCLAIMER_ONE,
    MESSAGES.POLICY_VALUE_TABLE_DISCLAIMER_TWO,
    MESSAGES.POLICY_VALUE_TABLE_ICARE_DISCLAIMER_THREE,
  ],
  style: 'disclaimer',
  pageBreak: '',
}

let disclaimerS7 = {
  stack: [
    `${MESSAGES.NOTE}:`,
    MESSAGES.POLICY_VALUE_TABLE_S7_DISCLAIMER_ONE,
    MESSAGES.POLICY_VALUE_TABLE_S7_DISCLAIMER_TWO,
    MESSAGES.POLICY_VALUE_TABLE_S7_DISCLAIMER_THREE,
  ],
  style: 'disclaimer',
  pageBreak: '',
  unbreakable: true,
}

export const iShieldShowMessageUnderPdfTable = ({ policyValue, basicPlan }: PolicyValueTableProps) => {
  const message = _getMessageUnderiShieldPolicyValueTable({
    policyValue,
    basicPlan,
  })
  if (message) {
    return {
      stack: [{ text: message, color: 'red', fontSize: 11, alignment: 'center' }],
    }
  } else return { text: '' }
}

const build25PLPolicyValueTable = (policyValue: PolicyValue) => {
  const policyValueTables = []
  const rowsPerPage = [54, 55, 54]
  const pageAmount = Math.ceil(policyValue.yearEndPolicyValues.length / rowsPerPage[0])
  _.times(pageAmount, (currentPage) => {
    policyValueTables.push({
      table: {
        headerRows: 1,
        widths: [12, 12, 'auto', '*', '*', '*', '*', '*', '*', '*', '*', '*'],
        body: [
          ...build25PLHeaderTable(),
          ...policyValue.yearEndPolicyValues
            .slice(
              currentPage * rowsPerPage[currentPage > 0 ? currentPage - 1 : 0],
              rowsPerPage[currentPage] * (currentPage + 1)
            )
            .map((p) => build25PLPolicyValueRow(p)),
        ],
      },
      layout: 'lightBorder',
      style: 'table25PL',
    })
  })

  _.last(policyValueTables).table.body.push(build25PLTotalPolicyValueRow(policyValue))
  return policyValueTables
}

const build25PLHeaderTable = () => {
  return [
    [
      { text: CONSTANTS.AGE, rowSpan: 3, style: 'tableHeader' },
      { text: MESSAGES.END_OF_THE_YEAR, rowSpan: 3, style: 'tableHeader' },
      {
        text: _breakLine(MESSAGES.CUMULATIVE_PREMIUM),
        rowSpan: 3,
        style: 'tableHeader',
      },
      { text: MESSAGES.PREMIUM_PER_YEAR, colSpan: 3, style: 'tableHeader' },
      { text: '' },
      { text: '' },
      { text: MESSAGES.CASH_DROP_VALUE, colSpan: 4, style: 'tableHeader' },
      { text: '' },
      { text: '' },
      { text: '' },
      {
        text: _breakLine(MESSAGES.DEATH_BENEFIT),
        rowSpan: 3,
        style: 'tableHeader',
      },
      {
        text: _breakLine(MESSAGES.CASH_SURRENDER_VALUE) + '**',
        rowSpan: 3,
        style: 'tableHeader',
      },
    ],
    [
      '',
      '',
      '',
      { text: MESSAGES.BASIC_PLAN, rowSpan: 2, style: 'tableHeader' },
      { text: MESSAGES.RIDER, rowSpan: 2, style: 'tableHeader' },
      { text: MESSAGES.TOTAL, rowSpan: 2, style: 'tableHeader' },
      {
        text: _breakLine(MESSAGES.INCASE_OF_CASH),
        rowSpan: 2,
        style: 'tableHeader',
      },
      {
        text: MESSAGES.INCASE_OF_SAVING_WITH_COMPANY,
        colSpan: 3,
        style: 'tableHeader',
      },
      '',
      '',
      '',
      '',
    ],
    [
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      {
        stack: [MESSAGES.LOW_LEVEL, Mustache.render(MESSAGES.PERCENTS_PER_YEAR, { percent: '0.5' })],
        style: 'tableHeader',
      },
      {
        stack: [MESSAGES.MEDIUM_LEVEL, Mustache.render(MESSAGES.PERCENTS_PER_YEAR, { percent: '1.5' })],
        style: 'tableHeader',
      },
      {
        stack: [MESSAGES.HIGH_LEVEL, Mustache.render(MESSAGES.PERCENTS_PER_YEAR, { percent: '2.5' })],
        style: 'tableHeader',
      },
      '',
      '',
    ],
  ]
}

const buildWholeLifePolicyValueTable = (policyValue: PolicyValue, basicPlan) => {
  const policyValueTables = []
  const rowsPerPage = [36, 36, 36]
  const pageAmount = Math.ceil(policyValue.yearEndPolicyValues.length / rowsPerPage[0])
  _.times(pageAmount, (currentPage) => {
    policyValueTables.push({
      table: {
        headerRows: 1,
        widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', '*'],
        body: [
          buildHeaderTable(policyValueTable.headers),
          ...policyValue.yearEndPolicyValues
            .slice(
              currentPage * rowsPerPage[currentPage > 0 ? currentPage - 1 : 0],
              rowsPerPage[currentPage] * (currentPage + 1)
            )
            .map((p) => buildPolicyValueRow(p)),
        ],
      },
      layout: 'lightBorder',
      style: 'table',
      pageBreak: pageAmount !== currentPage + 1 ? 'after' : '',
    })
  })

  _.last(policyValueTables).table.body.push(buildTotalPolicyValueRow(policyValue))

  const iShieldMsg = iShieldShowMessageUnderPdfTable({ policyValue, basicPlan })
  const lineOfDisclaimer = iShieldMsg.text === '' ? 3 : 4
  setLayoutDisclaimer(policyValueTables, rowsPerPage, disclaimer, lineOfDisclaimer)
  return policyValueTables
}

const buildPolicyValueTableNoRider = (policyValue: PolicyValue) => {
  const policyValueTables = []
  const rowsPerPage = [36, 36, 36]
  const coverageYear = policyValue.yearEndPolicyValues.length
  const pageAmount = Math.ceil(coverageYear / (rowsPerPage[0] === coverageYear ? rowsPerPage[0] - 1 : rowsPerPage[0]))

  _.times(pageAmount, (currentPage) => {
    policyValueTables.push({
      table: {
        headerRows: 1,
        widths: ['auto', 60, '*', '*', '*', '*'],
        body: [
          ...buildNoRiderHeaderTable(),
          ...policyValue.yearEndPolicyValues
            .slice(
              currentPage * rowsPerPage[currentPage > 0 ? currentPage - 1 : 0],
              rowsPerPage[currentPage] * (currentPage + 1)
            )
            .map((p) => buildNoRiderPolicyValueRow(p)),
        ],
      },
      layout: 'lightBorder',
      style: 'table',
      pageBreak: pageAmount !== currentPage + 1 ? 'after' : '',
    })
  })

  _.last(policyValueTables).table.body.push(buildTotalNoRiderPolicyValueRow(policyValue))
  return policyValueTables
}

const buildNoRiderHeaderTable = () => {
  return [
    [
      { text: CONSTANTS.AGE, rowSpan: 3, style: 'tableHeader' },
      {
        text: MESSAGES.POLICY_VALUE_TABLE_INSURANCE_YEAR,
        rowSpan: 3,
        style: 'tableHeader',
      },
      { text: MESSAGES.PREMIUM_PER_YEAR + '\n' + MESSAGES.BASIC_PLAN, rowSpan: 3, style: 'tableHeader' },
      {
        text: MESSAGES.END_OF_YEAR_POLICY,
        colSpan: 3,
        style: 'tableHeader',
      },
      { text: '' },
      { text: '' },
    ],
    [
      '',
      '',
      '',
      {
        text: _breakLine(MESSAGES.CUMULATIVE_PREMIUM),
        rowSpan: 2,
        style: 'tableHeader',
      },
      {
        text: _breakLine(MESSAGES.POLICY_VALUE_TABLE_CASH_SURRENDER_VALUE_NORIDER),
        rowSpan: 2,
        style: 'tableHeader',
      },
      {
        text: _breakLine(MESSAGES.POLICY_VALUE_TABLE_DEATH_BENEFIT_NO_RIDER_BI_PDF),
        rowSpan: 2,
        style: 'tableHeader',
      },
    ],
    ['', '', '', '', '', ''],
  ]
}

const buildNoRiderPolicyValueRow = (yearEndPolicy: YearEndPolicyValue) => [
  {
    text: yearEndPolicy.age.value,
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.yearEndPolicy,
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.annualBasicPremium === 0 ? '-' : formatNumber(yearEndPolicy.annualBasicPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.cumulativeBasicPremium === 0 ? '-' : formatNumber(yearEndPolicy.cumulativeBasicPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.surrenderCash === 0 ? '-' : formatNumber(yearEndPolicy.surrenderCash),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.deathBenefit === 0 ? '-' : formatNumber(yearEndPolicy.deathBenefit, 2, true),
    alignment: 'right',
    ...rowHeightConfig,
  },
]

const buildTotalNoRiderPolicyValueRow = (policyValue: PolicyValue) => [
  '',
  '',
  {
    text: policyValue.subtotalAnnualBasicPremium === 0 ? '-' : formatNumber(policyValue.subtotalAnnualBasicPremium, 2),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  '',
  '',
  '',
]

const buildLifeProtect18PolicyValueTable = (policyValue: PolicyValue) => {
  const policyValueTables = []
  const rowsPerPage = [36, 36, 36]
  const coverageYear = policyValue.yearEndPolicyValues.length
  const pageAmount = Math.ceil(coverageYear / (rowsPerPage[0] === coverageYear ? rowsPerPage[0] - 1 : rowsPerPage[0]))
  _.times(pageAmount, (currentPage) => {
    policyValueTables.push({
      table: {
        headerRows: 1,
        widths: ['auto', 60, '*', 65, '*', '*', '*', '*'],
        body: [
          ...buildLifeProtect18HeaderTable(),
          ...policyValue.yearEndPolicyValues
            .slice(
              currentPage * rowsPerPage[currentPage > 0 ? currentPage - 1 : 0],
              rowsPerPage[currentPage] * (currentPage + 1)
            )
            .map((p) => buildLifeProtect18PolicyValueRow(p)),
        ],
      },
      layout: 'lightBorder',
      style: 'table',
    })
  })
  _.last(policyValueTables).table.body.push(buildTotalLifeProtect18PolicyValueRow(policyValue))
  setLayoutDisclaimer(policyValueTables, rowsPerPage, disclaimerLifeProtect18)
  return policyValueTables
}

const buildLifeReadyPolicyValueTable = (policyValue: PolicyValue) => {
  const policyValueTables = []
  const rowsPerPage = [36, 36, 36]
  if (rowsPerPage[0] === policyValue.yearEndPolicyValues.length) {
    rowsPerPage[0] = rowsPerPage[0] - 1
  }
  const pageAmount = calculatePageAmountPolicyValueTable(policyValue, rowsPerPage)
  _.times(pageAmount, (currentPage) => {
    policyValueTables.push({
      table: {
        headerRows: 1,
        widths: ['auto', 60, '*', 65, '*', '*', '*', '*'],
        body: [
          ...buildLifeReadyHeaderTable(),
          ...policyValue.yearEndPolicyValues
            .slice(
              currentPage * rowsPerPage[currentPage > 0 ? currentPage - 1 : 0],
              rowsPerPage[currentPage] * (currentPage + 1)
            )
            .map((p) => buildLifeReadyPolicyValueRow(p)),
        ],
      },
      layout: 'lightBorder',
      style: 'table',
    })
  })
  _.last(policyValueTables).table.body.push(buildTotalLifeProtect18PolicyValueRow(policyValue))
  setLayoutDisclaimer(policyValueTables, rowsPerPage, disclaimerLifeReady, 5)
  return policyValueTables
}

const setLayoutDisclaimer = (policyValueTables, rowsPerPage, disclaimer, rowDisclaimer = 7) => {
  const headerRow = 4
  const amountPage = policyValueTables.length ? policyValueTables.length : 1
  const maxofPage = rowsPerPage[amountPage - 1] + headerRow
  const rowsofPage = policyValueTables[amountPage - 1].table.body.length
  if (rowsofPage >= maxofPage - rowDisclaimer) {
    disclaimer.pageBreak = 'before'
  } else {
    disclaimer.pageBreak = ''
  }
}

const buildLifeProtect18HeaderTable = () => {
  return [
    [
      { text: CONSTANTS.AGE, rowSpan: 3, style: 'tableHeader' },
      {
        text: MESSAGES.POLICY_VALUE_TABLE_INSURANCE_YEAR,
        rowSpan: 3,
        style: 'tableHeader',
      },
      { text: MESSAGES.PREMIUM_PER_YEAR, colSpan: 3, style: 'tableHeader' },
      { text: '' },
      { text: '' },
      {
        text: MESSAGES.END_OF_YEAR_POLICY_WITH_BRACKET,
        colSpan: 3,
        style: 'tableHeader',
      },
      { text: '' },
      { text: '' },
    ],
    [
      '',
      '',
      { text: MESSAGES.BASIC_PLAN, rowSpan: 2, style: 'tableHeader' },
      { text: MESSAGES.RIDER, rowSpan: 2, style: 'tableHeader' },
      { text: MESSAGES.TOTAL, rowSpan: 2, style: 'tableHeader' },
      {
        text: _breakLine(MESSAGES.CUMULATIVE_PREMIUM),
        rowSpan: 2,
        style: 'tableHeader',
      },
      {
        text: _breakLine(MESSAGES.POLICY_VALUE_TABLE_CASH_SURRENDER_VALUE),
        rowSpan: 2,
        style: 'tableHeader',
      },
      {
        text: _breakLine(MESSAGES.POLICY_VALUE_TABLE_DEATH_BENEFIT),
        rowSpan: 2,
        style: 'tableHeader',
      },
    ],
    ['', '', '', '', '', '', '', ''],
  ]
}

const buildLifeReadyHeaderTable = () => {
  return [
    [
      { text: CONSTANTS.AGE, rowSpan: 3, style: 'tableHeader' },
      {
        text: MESSAGES.POLICY_VALUE_TABLE_INSURANCE_YEAR,
        rowSpan: 3,
        style: 'tableHeader',
      },
      { text: MESSAGES.PREMIUM_PER_YEAR, colSpan: 3, style: 'tableHeader' },
      { text: '' },
      { text: '' },
      {
        text: MESSAGES.END_OF_YEAR_POLICY_WITH_BRACKET,
        colSpan: 3,
        style: 'tableHeader',
      },
      { text: '' },
      { text: '' },
    ],
    [
      '',
      '',
      { text: MESSAGES.BASIC_PLAN, rowSpan: 2, style: 'tableHeader' },
      { text: MESSAGES.RIDER, rowSpan: 2, style: 'tableHeader' },
      { text: MESSAGES.TOTAL, rowSpan: 2, style: 'tableHeader' },
      {
        text: _breakLine(MESSAGES.CUMULATIVE_PREMIUM),
        rowSpan: 2,
        style: 'tableHeader',
      },
      {
        text: _breakLine(MESSAGES.POLICY_VALUE_TABLE_CASH_SURRENDER_VALUE_NORIDER_BI_PDF),
        rowSpan: 2,
        style: 'tableHeader',
      },
      {
        text: _breakLine(MESSAGES.POLICY_VALUE_TABLE_DEATH_BENEFIT_NO_RIDER_BI_PDF),
        rowSpan: 2,
        style: 'tableHeader',
      },
    ],
    ['', '', '', '', '', '', '', ''],
  ]
}

const buildLifeReadyPolicyValueRow = (yearEndPolicy: YearEndPolicyValue) => [
  {
    text: yearEndPolicy.age.value,
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.yearEndPolicy,
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.annualBasicPremium === 0 ? '-' : formatNumber(yearEndPolicy.annualBasicPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.annualRiderPremium === 0 ? '-' : formatNumber(yearEndPolicy.annualRiderPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.annualTotalPremium === 0 ? '-' : formatNumber(yearEndPolicy.annualTotalPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.cumulativeBasicPremium === 0 ? '-' : formatNumber(yearEndPolicy.cumulativeBasicPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.surrenderCash === 0 ? '-' : formatNumber(yearEndPolicy.surrenderCash, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.deathBenefit === 0 ? '-' : formatNumber(yearEndPolicy.deathBenefit, 2, true),
    alignment: 'right',
    ...rowHeightConfig,
  },
]

const buildLifeProtect18PolicyValueRow = (yearEndPolicy: YearEndPolicyValue) => [
  {
    text: yearEndPolicy.age.value,
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.yearEndPolicy,
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.annualBasicPremium === 0 ? '-' : formatNumber(yearEndPolicy.annualBasicPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.annualRiderPremium === 0 ? '-' : formatNumber(yearEndPolicy.annualRiderPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.annualTotalPremium === 0 ? '-' : formatNumber(yearEndPolicy.annualTotalPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.cumulativeBasicPremium === 0 ? '-' : formatNumber(yearEndPolicy.cumulativeBasicPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.surrenderCash === 0 ? '-' : formatNumber(yearEndPolicy.surrenderCash),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.deathBenefit === 0 ? '-' : formatNumber(yearEndPolicy.deathBenefit, 2, true),
    alignment: 'right',
    ...rowHeightConfig,
  },
]

const buildTotalLifeProtect18PolicyValueRow = (policyValue: PolicyValue) => [
  '',
  '',
  {
    text: policyValue.subtotalAnnualBasicPremium === 0 ? '-' : formatNumber(policyValue.subtotalAnnualBasicPremium, 2),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  {
    text: policyValue.subtotalAnnualRiderPremium === 0 ? '-' : formatNumber(policyValue.subtotalAnnualRiderPremium, 2),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  {
    text: policyValue.grandTotalPremium === 0 ? '-' : formatNumber(policyValue.grandTotalPremium, 2),
    alignment: 'right',
    bold: true,
    ...rowHeightConfig,
  },
  '',
  '',
  '',
]

export const buildPolicyValueTable = (policyValue: PolicyValue, basicPlan: DisplayProduct) => {
  if (basicPlan.code === '25PL') {
    return build25PLPolicyValueTable(policyValue)
  } else if (
    isLifeProtectProduct(basicPlan.code) ||
    isProtectionProduct(basicPlan.code) ||
    isLifeEnsureProduct(basicPlan.code) ||
    isSukhapabDekDeeProduct(basicPlan.code)
  ) {
    return buildLifeProtect18PolicyValueTable(policyValue)
  } else if (isLifeReadyProduct(basicPlan.code)) {
    return buildLifeReadyPolicyValueTable(policyValue)
  } else if (isS7Product(basicPlan.code)) {
    return buildPolicyValueTableNoRider(policyValue) //buildLifeProtect18PolicyValueTable(policyValue)
  } else {
    return buildWholeLifePolicyValueTable(policyValue, basicPlan)
  }
}

const buildDisclaimer = (basicPlan: DisplayProduct) => {
  if (isLifeProtectProduct(basicPlan.code) || isLifeEnsureProduct(basicPlan.code)) {
    return disclaimerLifeProtect18
  } else if (isSukhapabDekDeeProduct(basicPlan.code)) {
    return disclaimerSukhapabDekDee
  } else if (isS7Product(basicPlan.code)) {
    return disclaimerS7
  } else if (isLifeReadyProduct(basicPlan.code)) {
    return disclaimerLifeReady
  } else {
    return disclaimer
  }
}

export const policyValueTableSection = ({ policyValue, basicPlan }: PolicyValueTableProps) => [
  { text: MESSAGES.POLICY_VALUE_TABLE, style: 'title', pageBreak: 'before' },
  ...buildPolicyValueTable(policyValue, basicPlan),
  iShieldShowMessageUnderPdfTable({ policyValue, basicPlan }),
  buildDisclaimer(basicPlan),
]

export const policyValueTableICareSection = ({ policyValue, basicPlan }: PolicyValueTableProps) => [
  { text: MESSAGES.POLICY_VALUE_TABLE, style: 'title', pageBreak: 'before' },
  ...buildPolicyValueTable(policyValue, basicPlan),
  iShieldShowMessageUnderPdfTable({ policyValue, basicPlan }),
  disclaimerICare,
]
