//@flow
import styled from 'styled-components'
import { SubFundItem } from './sub-fund-item'
import type { FundListProps } from './fund-list'
import { sortFunds } from './fund-list'

const SubFundWrapper = styled.div`
  margin-bottom: 16px;
`

export const SubFundList = ({ allFunds }: FundListProps) => (
  <div className="fund-summary">
    <div className="fund-group">
      {allFunds.sort(sortFunds).map((item, index) => (
        <SubFundWrapper key={`sub-fund-${item.code}`}>
          <SubFundItem key={`sub-fund-${index}`} {...item} />
        </SubFundWrapper>
      ))}
    </div>
  </div>
)
