//@flow
import _ from 'lodash'

import { commonSection } from 'core/service/pdf-generation/products/common/sections'
import {
  section,
  section12PL,
  sectionPLB,
  sectionSukhapabdekdee,
  sectionS7,
} from 'core/service/pdf-generation/products/whole-life/sections'
import { basicPlanDetailsLabelFn } from '../common/sections/product-benefit-section'

import {
  getThaiBasicPlanLabel,
  getThaiBasicPlanDetailsLabelFn,
  getBasicPlanDetailsLabelFnLifeReady,
  getThaiBasicPlanDetailsLabelFnWithOutCode,
  getBasicPlanNameLabel,
  getBasicPlanDetailsLabelFn,
  getPLBBasicPlanLabel,
  getS7BasicPlanLabel,
} from './selectors'

import { isRenderETIandRPU } from 'core/service/pdf-generation/products/common/logic'
import type { WholeLifeProps } from './index'

const get12PLMiniContent = (props: WholeLifeProps) => {
  return [
    ...commonSection.profile({ ...props, basicPlanLabel: getThaiBasicPlanLabel() }),
    ...commonSection.productBenefits({ ...props, basicPlanDetailsLabelFn: getThaiBasicPlanDetailsLabelFn() }),
    ...commonSection.riders(props),
    ...section12PL.policyValueTable(props),
    ...section12PL.secondPolicyValueTable(props),
    section.benefitSummary12PL(props),
    section.taxDeductionBenefit(props),
    ...commonSection.legalDisclaimerMarketConduct(props),
  ]
}

const get12PLFullContent = async (props: WholeLifeProps) => {
  const paymentChannels = await commonSection.paymentChannels(props)

  return [
    ...commonSection.profile({ ...props, basicPlanLabel: getThaiBasicPlanLabel() }),
    ...commonSection.productBenefits({ ...props, basicPlanDetailsLabelFn: getThaiBasicPlanDetailsLabelFn() }),
    ...commonSection.riders(props),
    commonSection.basicPlanCondition,
    ...section12PL.policyValueTable(props),
    ...section12PL.secondPolicyValueTable(props),
    section.benefitSummary12PL(props),
    [{ text: '', pageBreak: 'before' }, { ...commonSection.taxConsentDeductionBenefit(props) }],
    { ...commonSection.legalDisclaimerMarketConduct(props) },

    paymentChannels,
    commonSection.signature(props),
  ]
}

const getPerfectLifeMiniContent = (props: WholeLifeProps) => {
  return [
    ...commonSection.profile({ ...props, basicPlanLabel: getBasicPlanNameLabel() }),
    ...commonSection.productBenefits({ ...props, basicPlanDetailsLabelFn: getBasicPlanDetailsLabelFn() }),
    ...commonSection.riders(props),
    ...section.policyValueTable(props),
    section.benefitSummary(props),
    section.taxDeductionBenefit(props),
    commonSection.legalDisclaimerMarketConduct(props),
  ]
}

const getPerfectLifeFullContent = async (props: WholeLifeProps) => {
  const paymentChannels = await commonSection.paymentChannels(props)

  return [
    ...commonSection.profile({ ...props, basicPlanLabel: getBasicPlanNameLabel() }),
    ...commonSection.productBenefits({ ...props, basicPlanDetailsLabelFn: getBasicPlanDetailsLabelFn() }),
    ...commonSection.riders(props),
    commonSection.basicPlanCondition,
    ...section.policyValueTable(props),
    section.benefitSummary(props),
    commonSection.taxConsentDeductionBenefit(props),
    { stack: [commonSection.legalDisclaimerMarketConduct(props)], marginTop: -10 },

    paymentChannels,
    commonSection.signature(props),
  ]
}

const getSukhapabDekDeeFullContent = async (props: WholeLifeProps) => {
  const paymentChannels = await commonSection.paymentChannels(props)
  return [
    ...commonSection.profile(props),
    ...commonSection.productBenefits({ ...props, basicPlanDetailsLabelFn }),
    ...commonSection.riders(props),
    commonSection.basicPlanCondition,
    ...sectionSukhapabdekdee.policyValueTable(props),
    sectionSukhapabdekdee.benefitSummary(props),
    sectionSukhapabdekdee.taxDeductionBenefit(props),
    commonSection.legalDisclaimerMarketConduct(props),
    paymentChannels,
    commonSection.signature(props),
  ]
}

const getLifeProtect10MiniContent = (props: WholeLifeProps) => {
  return [
    ...commonSection.profile(props),
    ...commonSection.productBenefits({ ...props, basicPlanDetailsLabelFn }),
    ...commonSection.riders(props),
    ...section.policyValueTable(props),
    section.benefitSummary(props),
    section.taxDeductionBenefit(props),
    commonSection.legalDisclaimerMarketConduct(props),
  ]
}

const getLifeProtect10FullContent = async (props: WholeLifeProps) => {
  const paymentChannels = await commonSection.paymentChannels(props)

  return [
    ...commonSection.profile(props),
    ...commonSection.productBenefits({ ...props, basicPlanDetailsLabelFn }),
    ...commonSection.riders(props),
    commonSection.basicPlanCondition,
    ...section.policyValueTable(props),
    section.benefitSummary(props),
    commonSection.taxConsentDeductionBenefit(props),
    commonSection.legalDisclaimerMarketConduct(props),
    paymentChannels,
    commonSection.signature(props),
  ]
}

const get25PLMiniContent = (props: WholeLifeProps) => {
  return [
    ...commonSection.profile({ ...props, basicPlanLabel: getThaiBasicPlanLabel() }),
    ...commonSection.productBenefits({ ...props, basicPlanDetailsLabelFn: getThaiBasicPlanDetailsLabelFn() }),
    ...commonSection.riders(props),
    ...section.policyValueTable(props),
    section.benefitSummary(props),
    commonSection.legalDisclaimerMarketConduct(props),
  ]
}

const get25PLFullContent = async (props: WholeLifeProps) => {
  const paymentChannels = await commonSection.paymentChannels(props)
  return [
    ...commonSection.profile({ ...props, basicPlanLabel: getThaiBasicPlanLabel() }),
    ...commonSection.productBenefits({ ...props, basicPlanDetailsLabelFn: getThaiBasicPlanDetailsLabelFn() }),
    ...commonSection.riders(props),
    commonSection.basicPlanCondition,
    ...section.policyValueTable(props),
    section.benefitSummary(props),
    commonSection.legalDisclaimerMarketConduct(props),
    paymentChannels,
    commonSection.signature(props),
  ]
}

const getPLBMiniContent = (props: WholeLifeProps) => {
  return [
    ...commonSection.profile({ ...props, basicPlanLabel: getPLBBasicPlanLabel() }),
    ...commonSection.productBenefits({ ...props, basicPlanDetailsLabelFn: getThaiBasicPlanDetailsLabelFnWithOutCode }),
    ...commonSection.riders(props),
    ...sectionPLB.policyValueTable(props),
    section.benefitSummary(props),
    section.taxDeductionBenefit(props),
    commonSection.legalDisclaimerMarketConduct(props),
  ]
}

const getPLBFullContent = async (props: WholeLifeProps) => {
  const paymentChannels = await commonSection.paymentChannels(props)
  return [
    ...commonSection.profile({ ...props, basicPlanLabel: getPLBBasicPlanLabel() }),
    ...commonSection.productBenefits({ ...props, basicPlanDetailsLabelFn: getThaiBasicPlanDetailsLabelFnWithOutCode }),
    ...commonSection.riders(props),
    commonSection.basicPlanCondition,
    ...sectionPLB.policyValueTable(props),
    section.benefitSummary(props),
    commonSection.taxConsentDeductionBenefit(props),
    commonSection.legalDisclaimerMarketConduct(props),
    paymentChannels,
    commonSection.signature(props),
  ]
}

const getDefaultMiniContent = (props: WholeLifeProps) => {
  return [
    ...commonSection.profile(props),
    ...commonSection.productBenefits({ ...props, basicPlanDetailsLabelFn }),
    ...commonSection.riders(props),
    ...section.policyValueTable(props),
    section.benefitSummary(props),
    commonSection.legalDisclaimerMarketConduct(props),
  ]
}

const getDefaultFullContent = async (props: WholeLifeProps) => {
  const paymentChannels = await commonSection.paymentChannels(props)

  return [
    ...commonSection.profile(props),
    ...commonSection.productBenefits({ ...props, basicPlanDetailsLabelFn }),
    ...commonSection.riders(props),
    commonSection.basicPlanCondition(),
    ...section.policyValueTable(props),
    section.benefitSummary(props),
    commonSection.taxConsentDeductionBenefit(props),
    commonSection.legalDisclaimerMarketConduct(props),
    paymentChannels,
    commonSection.signature(props),
  ]
}

const getS7FullContent = async (props: WholeLifeProps) => {
  const paymentChannels = await commonSection.paymentChannels(props)
  return [
    ...commonSection.profile({ ...props, basicPlanLabel: getS7BasicPlanLabel() }),
    ...commonSection.productBenefits({ ...props, basicPlanDetailsLabelFn }),
    ...commonSection.riders(props),
    sectionS7.basicPlanCondition(),
    ...section.policyValueTable(props),
    {
      text: '',
      pageBreak: 'after',
    },
    commonSection.taxConsentDeductionS7Benefit(props),
    commonSection.legalDisclaimerMarketConductNoAddtionalContract(props),
    paymentChannels,
    commonSection.signature(props),
  ]
}

const getLifeReadyFullContent = async (props: WholeLifeProps) => {
  const paymentChannels = await commonSection.paymentChannels(props)
  return [
    ...commonSection.profile(props),
    ...commonSection.productBenefits({ ...props, basicPlanDetailsLabelFn: getBasicPlanDetailsLabelFnLifeReady }),
    ...commonSection.riders(props),
    commonSection.basicPlanOrdinaryConditionSection(props),
    ...section.policyValueTable(props),
    isRenderETIandRPU(props) ? commonSection.policyValueEtiRpuTable(props) : '',
    commonSection.taxConsentDeductionBenefit(props),
    commonSection.legalDisclaimerMarketConduct(props),
    paymentChannels,
    commonSection.signature(props),
  ]
}

const getTemplateCode = (props: WholeLifeProps): string => {
  return _.get(props, 'basicPlan.code')
}

export const getContentMiniContent = async (props: WholeLifeProps) => {
  const productCode = getTemplateCode(props)
  switch (productCode) {
    case 'WL88J':
    case 'WL88S':
    case 'W85P10J':
    case 'W85P10S':
      return getLifeProtect10MiniContent(props)
    case 'PERFECTLIFE-WL88J':
    case 'PERFECTLIFE-WL88S':
      return getPerfectLifeMiniContent(props)
    case 'PLB05':
    case 'PLB10':
    case 'PLB12':
    case 'PLB15':
      return getPLBMiniContent(props)
    case '12PL':
      return get12PLMiniContent(props)
    case '25PL':
      return get25PLMiniContent(props)
    default:
      return getDefaultMiniContent(props)
  }
}

export const getContentFullContent = async (props: WholeLifeProps) => {
  const productCode = getTemplateCode(props)
  switch (productCode) {
    case 'WL88J':
    case 'WL88S':
    case 'W85P10J':
    case 'W85P10S':
      return await getLifeProtect10FullContent(props)
    case 'PERFECTLIFE-WL88J':
    case 'PERFECTLIFE-WL88S':
      return await getPerfectLifeFullContent(props)
    case 'SUKHAPABDEKDEE-WLNP85':
      return await getSukhapabDekDeeFullContent(props)
    case 'PLB05':
    case 'PLB10':
    case 'PLB12':
    case 'PLB15':
      return getPLBFullContent(props)
    case '12PL':
      return await get12PLFullContent(props)
    case '25PL':
      return await get25PLFullContent(props)
    case 'CIWLS39':
    case 'CIWLS50':
    case 'CIWLS65':
    case 'CIWLM39':
    case 'CIWLM50':
    case 'CIWLM60':
      return getS7FullContent(props)
    case 'W99F99H':
    case 'W99F99M':
    case 'W99FU06':
    case 'W99FU12':
    case 'W99FU18':
    case 'W99FH18':
    case 'W99FU99':
    case 'W99FH99':
    case 'CANCERX5-1':
    case 'CANCERX5-2':
    case 'CANCERX5-3':
    case 'KOOMVER-COVER-1':
    case 'KOOMVER-COVER-2':
    case 'KOOMVER-COMPENSATE-1':
    case 'KOOMVER-COMPENSATE-2':
    case 'H99F06A':
    case 'H99F12A':
    case 'H99F18A':
      return getLifeReadyFullContent(props)
    default:
      return await getDefaultFullContent(props)
  }
}
