// @flow
import type { Effect } from 'redux-saga/effects'
import { takeLatest, put, call, select } from 'redux-saga/effects'
import _ from 'lodash'
import type { InsuredProps } from 'core/service/pdf-generation/products/common/types'
import { updateTermLifeInvestmentBenefit } from './actions'
import { UPDATE_POLICY_VALUE } from 'quick-quote/product-whole-life/benefit-illustration/actions'
import { getInvestmentBenefitParams, getInvestmentBenefit } from './components/investment-benefit/selectors'
import { generateInvestmentBenefit } from 'core/service/benefit-illustration/investment-benefit'
import {
  getSelectedBasicCategory,
  getSelectedDisplayProductCode,
  getSelectedDisplayProduct,
} from 'quick-quote/product-selection/selectors'
import VALUES from 'core/data-model/constants/values'

//BI
import AuthApi from 'core/service/distributor/auth-api'
import type { BIProps } from 'quick-quote/product-common/benefit-illustration/actions'
import { getDistributorProfile } from 'core/service/distributor'
import { christianToThaiDate, christianToThaiWithoutFormat } from 'core/service/lib/date'

import {
  getIdentityProfile,
  getIdentityUser,
  getSelectedOffice,
  getUserDistributorGroup,
  isBancAgent,
  isNewAsteriskOfSummaryMainContract as isUseNewAsteriskOfSummaryMainContract,
} from 'identity/selectors'
import {
  getAge,
  getBirthdate,
  getGenderLabel,
  getInsuredFirstName,
  getInsuredLastName,
} from 'quick-quote/insured-information/selectors'
import {
  getBenefitSummary,
  getHasNonLevelRider,
  getLegalDisclaimerEndDate,
  getPolicyValues,
  getRiderBenefitTables,
  getTaxDeduction,
} from 'quick-quote/product-whole-life/benefit-illustration/selectors'
import { getVersion, isReviseQQFlow } from 'quick-quote/selectors'
import {
  getSelectedRiders,
  getSumAssured$,
  getTotalPremium,
  getBasicPremium$,
  getSelectedPlanName,
  getSelectedPlanCode,
  getcoveragePlanStartDate,
  getSelectedModelFactorLabel,
} from 'quick-quote/product-common/coverage-plan/selectors'
import {
  getCalculatedCoveragePeriod,
  getCalculatedPaymentPeriod,
  getHasShowDisclaimerRiderAsterisk,
} from 'quick-quote/product-common/benefit-illustration/selectors'

//

export function* getBITermLifeProps(biProps: BIProps): Generator<*, *, *> {
  const basicPlan = yield select(getSelectedDisplayProduct)
  const productCategory = basicPlan.category
  const riders = yield select(getSelectedRiders)
  const genderLabel = yield select(getGenderLabel)
  const age = yield select(getAge)
  const firstName = yield select(getInsuredFirstName)
  const lastName = yield select(getInsuredLastName)
  const birthdate = yield select(getBirthdate)
  const sumAssured$ = yield select(getSumAssured$)
  const selectedModelFactorLabel = yield select(getSelectedModelFactorLabel)
  const calculatedCoveragePeriod = yield select(getCalculatedCoveragePeriod)
  const calculatedPaymentPeriod = yield select(getCalculatedPaymentPeriod)
  const hasNonLevelRider = yield select(getHasNonLevelRider)
  const hasShowDisclaimerRiderAsterisk = yield select(getHasShowDisclaimerRiderAsterisk)
  const totalPremium = yield select(getTotalPremium)
  const basicPremium$ = yield select(getBasicPremium$)
  const benefitSummary = yield select(getBenefitSummary)
  const investmentBenefit = yield select(getInvestmentBenefit)
  const taxDeduction = yield select(getTaxDeduction)
  const policyValue = yield select(getPolicyValues)
  const riderBenefitData = yield select(getRiderBenefitTables)
  const version = yield select(getVersion)
  let profile = yield select(getIdentityProfile)
  const user = yield select(getIdentityUser)
  const accessToken = user.access_token
  const agentInfo = yield call(AuthApi.getUserInformation, user)
  const bancOffice = yield select(getSelectedOffice)
  profile = getDistributorProfile(profile, agentInfo, bancOffice)
  const distributorType = yield select(getUserDistributorGroup)
  const startDate = yield select(getcoveragePlanStartDate)
  const endDate = yield select(getLegalDisclaimerEndDate)
  const isReviseQQ = yield select(isReviseQQFlow)

  const selectedPlanName = yield select(getSelectedPlanName)
  const selectedPlanCode = yield select(getSelectedPlanCode)
  const isBanc = yield select(isBancAgent)
  const isNewAsteriskOfSummaryMainContract = yield select(isUseNewAsteriskOfSummaryMainContract)

  const getDateTime = () => christianToThaiWithoutFormat().format('Do MMMM YYYY เวลา kk:mm')

  const datetime = yield call(getDateTime)
  const validateFullName = _.isEmpty(firstName) && _.isEmpty(lastName) ? null : { firstName, lastName }

  const insured: InsuredProps = {
    genderLabel,
    age,
    ...validateFullName,
  }
  return {
    // meta data
    productCategory,
    biType: biProps.biType,

    // data
    basicPlan,
    insured,
    riders,
    birthdate,
    calculatedCoveragePeriod,
    calculatedPaymentPeriod,
    sumAssured: sumAssured$.value,
    basicPremium: basicPremium$.value,
    totalPremium,
    selectedModelFactorLabel,
    hasNonLevelRider,
    hasShowDisclaimerRiderAsterisk,
    benefitSummary,
    investmentBenefit,
    policyValue,
    riderBenefitData,
    version: version.appVersion,
    profile,
    accessToken,
    distributorType,
    startDate: christianToThaiDate(startDate),
    endDate: christianToThaiDate(endDate),
    datetime,
    logo: '',
    redLine: '',
    productGraph: '',
    selectedPlanName,
    selectedPlanCode,
    taxDeduction,
    isBancAgent: isBanc,
    isNewAsteriskOfSummaryMainContract,
    isReviseQQ,
  }
}

export function* caculateInvestmentBenefit(): Generator<*, *, *> {
  const productCategory = yield select(getSelectedBasicCategory)
  const productCode = yield select(getSelectedDisplayProductCode)
  if (
    productCategory !== VALUES.TERM_LIFE ||
    VALUES.TERM_LIFE_PLANCODE_EXCEPT_CALCULATE_INVESTMENT_BENEFIT.includes(productCode)
  )
    return

  const investmentBenefitParams = yield select(getInvestmentBenefitParams)
  const investmentBenefit = yield call(generateInvestmentBenefit, investmentBenefitParams)
  yield put(updateTermLifeInvestmentBenefit(investmentBenefit))
}

export function* watchers(): Generator<*, *, Effect[]> {
  yield [takeLatest(UPDATE_POLICY_VALUE, caculateInvestmentBenefit)]
}

export const sagas = watchers
