// @flow
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Dropdown } from 'common-components'

import { getLoanTerm } from 'quick-quote/product-loan/coverage-plan/redux'
import { getOptionsLoanPeriod } from 'quick-quote/product-mrta/coverage-plan/selectors'
import { editLoanPeriod } from 'quick-quote/product-mrta/actions'

type LoanPeriodProps = {
  optionsLoanPeriod: Object[],
  loanPeriod: number,
  editLoanPeriod: (number) => void,
}

const LoanPeriod = ({ optionsLoanPeriod, loanPeriod, editLoanPeriod }: LoanPeriodProps) => {
  return (
    <Dropdown
      // className="basic-plan form-control"
      className="loan-period"
      options={optionsLoanPeriod}
      onChange={editLoanPeriod}
      value={loanPeriod}
    />
  )
}

const mapStateToProps = (state: AppState) => ({
  loanPeriod: getLoanTerm(state),
  optionsLoanPeriod: getOptionsLoanPeriod(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  editLoanPeriod: bindActionCreators(editLoanPeriod, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoanPeriod)
