/* istanbul ignore file */

import styled from 'styled-components'
import { ListGroup as StrapListGroup, ListGroupItem } from 'reactstrap'

const ListGroup = styled(({ className, onChange, value, options, isActive, render, ...props }) => (
  <StrapListGroup className={className}>
    {options.map((option, index) => {
      const active = isActive(option)
      return (
        <ListGroupItem
          key={`${index}-${option.value}`}
          active={active}
          onClick={(e) => {
            e.preventDefault()
            onChange(option)
          }}
        >
          {render({ checked: active, children: option.text })}
        </ListGroupItem>
      )
    })}
  </StrapListGroup>
))`
  flex-direction: row !important;
  flex-wrap: wrap;
  .list-group-item {
    cursor: pointer;
    flex: 0 0 100%;
    align-items: flex-start;
    padding: 8px 10px;
    &.active {
      background-color: ${({ theme }) => theme.variables['$brand-primary']};
      color: white;
      border-color: ${({ theme }) => theme.variables['$color-tbd-13']};
      .custom-control-indicator {
        border-color: white !important;
      }
    }
    &:last-child {
      margin-bottom: -1px;
    }
    label {
      margin-bottom: 0;
    }
  }

  ${({ theme }) => theme.media.md`
.list-group-item {
  flex: 0 0 50%;
}
`};
`
export default ListGroup
