import moment from 'moment'
import { cashSection, creditCardSection, edcSection, netbankSection } from '../../payment/index_old'
import { every, flow, get, getOr, last, map, overEvery, some, toFinite, nth } from 'lodash/fp'
import values from 'core/data-model/constants/values'
import {
  isPayerInfoValid,
  canCreateReceipt,
  getReceiptAmount,
  hasReceiptSigned,
  isAllReceiptCreated,
  getSelectedReceiptType,
  hasEvidenceUpload,
  isInvestmentCategory,
} from '../selectors'
import { createField, createSection } from '../utils'
import { isSectionValid } from '../../../../apps/selectors'
import {
  createReceipt,
  removePaymentReceipt,
  setAppValue,
  unsetAppValue,
  validateApplication,
} from '../../../../apps/actions'
import {
  getPaymentSections,
  getSelectedPaymentSections,
  isBackButtonVisible,
  isChangePaymentButtonVisible,
} from './selectors'
import { getToggles } from 'quick-quote/feature-toggles'

export default (state, app, { history, dispatch } = {}) => {
  const isReceiptValid = (app, state) =>
    overEvery([
      canCreateReceipt,
      flow(
        getPaymentSections,
        map('paymentSteps'),
        map((steps) => createSection([steps[0]])),
        every((section) => isSectionValid(state, [section])(app))
      ),
      isPayerInfoValid,
    ])(app, state)

  const getReceiptNoList = flow(getSelectedPaymentSections, map('receiptNo'))

  let isPaid = flow(
    getPaymentSections,
    map('paymentSteps'),
    map((steps) => last(steps)),
    some(({ state }) => state === 'done')
  )

  if (
    getToggles().ENABLE_CHANGE_PAY_IN_ATTACHMENT_STEP &&
    (getSelectedReceiptType(app) === 'edc' || getSelectedReceiptType(app) === 'net bank')
  ) {
    isPaid = overEvery([
      flow(
        getPaymentSections,
        map('paymentSteps'),
        map((steps) => nth(-2, steps)),
        some(({ state }) => state === 'done')
      ),
      flow(
        getPaymentSections,
        map('paymentSteps'),
        map((steps) => last(steps)),
        some(({ state }) => state === 'done')
      ),
    ])
  }

  let checked = isPaid(app, state)
  if (getToggles().ENABLE_CHANGE_PAY_IN_ATTACHMENT_STEP && getSelectedReceiptType(app) === 'cash') {
    checked = overEvery([
      hasEvidenceUpload,
      flow(
        getPaymentSections,
        map('paymentSteps'),
        map((steps) => last(steps)),
        some(({ state }) => state === 'done')
      ),
    ])(app, state)
  }
  const getReceiptId = flow(getSelectedPaymentSections, last, getOr('', 'id'))

  const receiptId = getReceiptId(app, state)

  const receiptNoList = getReceiptNoList(app, state)

  const cybsRetryUntilCancel = get('cybsRetryUntilCancel', app)

  const payerInfoStatus = get('payerInfo.status', app)

  const enableCreditCardSection = (app) => {
    const isSpulProduct = app.quickQuote.selectedDisplayProduct.basicPlanCode === values.SPUL
    if (isSpulProduct) {
      return false
    }
    return true
  }

  const isHideEDCSection = () => {
    const currentDate = moment().utc()
    const timeToHide = moment(getToggles().HIDE_EDC_PAYMENT).utc()
    return currentDate.isSameOrAfter(timeToHide)
  }

  const handleSection = (app, state) => {
    const alreadyCashPaid =
      get('receipts', app).filter((receipt) => receipt?.type === 'cash' && receipt?.paid).length > 0
    const alreadyEdcPaid = get('receipts', app).filter((receipt) => receipt?.type === 'edc' && receipt?.paid).length > 0
    const isInvestment = isInvestmentCategory(app)
    const sections = [
      netbankSection(app, state),
      enableCreditCardSection(app) ? creditCardSection(app, state) : null,
      isHideEDCSection() && !alreadyEdcPaid ? null : isInvestment ? null : edcSection(app, state),
      getToggles().HIDE_UNUSED_PAYMENT && !alreadyCashPaid ? null : cashSection(app, state),
    ]
    return sections.filter((section) => section)
  }

  return {
    checked,
    icon: isPaid(app, state) ? 'check' : '',
    cybsRetryUntilCancel: cybsRetryUntilCancel,
    sections: [
      createSection([
        createField(
          'payment',
          [
            {
              id: 'payment',
              c: 'Payment',
              p: {
                mergeState: (state, app, { dispatch } = {}) => ({
                  disabled: !isPaid(app, state),
                  title: 'ชำระด้วย',
                  isValid: isReceiptValid(app, state),
                  sections: handleSection(app, state),
                  onSelect: ({ id, paymentType }) => {
                    const receipt = get(id, app)
                    const receiptAmount = getReceiptAmount(receipt)
                    if (toFinite(receiptAmount) === 0 && !isPaid(app, state)) {
                      dispatch(setAppValue(`${id}.type`, paymentType))
                      dispatch(validateApplication())
                    }
                  },
                }),
              },
            },
          ],
          {
            pl: [0, 0, 0],
            pr: [0, 0, 0],
          }
        ),
      ]),
    ],
    // TODO: Add test to cover this change payment
    customButtons: [
      {
        text: 'เปลี่ยนวิธีชำระเงิน',
        visible: isChangePaymentButtonVisible(app, state),
        disabled: hasReceiptSigned(app),
        onClick: () => {
          dispatch(removePaymentReceipt(receiptNoList))
          dispatch(unsetAppValue(`${receiptId}.paid`))
          dispatch(unsetAppValue(`${receiptId}.receiptNo`))
          dispatch(unsetAppValue(`${receiptId}.createAt`))
          dispatch(unsetAppValue('submitWithoutPayment.selected'))
          if (getToggles().ENABLE_ECBR_SYSTEMIZATION) {
            dispatch(unsetAppValue(`${receiptId}.oldReceiptNo`))
            dispatch(unsetAppValue(`${receiptId}.isCreateEcbrNo`))
            dispatch(unsetAppValue('payerInfo'))
          }
          history.replace(history.location.pathname.replace('/payment', ''))
        },
      },
      {
        text: 'กลับ',
        visible: isBackButtonVisible(app, state),
        outline: true,
        onClick: ({ toggleModal }) => {
          toggleModal()
        },
      },
    ],
    okButton: isAllReceiptCreated(app)
      ? {
          label: 'ชำระเสร็จ',
          disabled: !isPaid(app, state),
          onClick: () => {
            if (payerInfoStatus !== 'error') {
              history.replace(history.location.pathname.replace('/payment', ''))
            }
          },
        }
      : {
          label: getToggles().ENABLE_CHANGE_PAY_IN_ATTACHMENT_STEP
            ? getSelectedReceiptType(app) === 'credit card' || getSelectedReceiptType(app) === 'cash'
              ? 'ออกใบเสร็จ'
              : 'ถ่ายรูปใบนำฝาก'
            : 'ออกใบเสร็จ',
          id: 'payment-modal_create-receipt-btn',
          disabled: !isReceiptValid(app, state),
          onClick: () => {
            if (isReceiptValid(app, state)) {
              dispatch(createReceipt())
            } else {
              dispatch(validateApplication())
            }
          },
        },
  }
}
