import _ from 'lodash'
import { MWP_PRODUCTS } from '../constant'

const CustomerProfile = (props) => {
  const { name, coverage, product, date } = props

  const { displayedText } = _.find(MWP_PRODUCTS, ['productCode', product])

  return (
    <div>
      <div>
        <strong>ชื่อ-นามสกุล</strong>
        <span> : {name}</span>
      </div>
      <div>
        <strong>ผลิตภัณฑ์ที่สนใจ</strong>
        <span> : {displayedText}</span>
      </div>
      <div>
        <strong>ผลประโยชน์กรณีเสียชีวิต (ขั้นต่ำ)</strong>
        <span> : {coverage}</span>
      </div>
      <div>
        <strong>วันที่สร้าง</strong>
        <span> : {date}</span>
      </div>
    </div>
  )
}

export default CustomerProfile
