// @flow

import _ from 'lodash'
import { dataWrapper } from 'core/data'
import type { taxRates } from 'core/data-model/basic-plan'
import type { DisplayProduct } from 'core/service/display-product'
import type { Age, Gender } from 'core/data-model/insured'

export const _lookUpBasicTaxRate = (
  allTaxRates: Array<taxRates>,
  basicPlan: DisplayProduct,
  gender: Gender,
  age: Age,
  coveragePeriod: number
): Object => {
  const basicPlanTaxRates = allTaxRates.find(({ productCode }) => productCode === basicPlan.basicPlanCode)
  if (!basicPlanTaxRates) {
    return { retire: 0, life: 0, health: 0 }
  }
  const ageValue = age.unit !== 'year' ? 0 : age.value
  const coveragePeriodQuery = coveragePeriod < 10 ? 0 : 10
  const queryParams = [
    ['gender', gender],
    ['age', ageValue],
    ['coveragePeriod', coveragePeriodQuery],
  ].filter(([key]) => basicPlanTaxRates.criteria.includes(key.toString()))
  const queryParamsPath = _.flatten(queryParams)
  return _.get(basicPlanTaxRates, queryParamsPath, { retire: 0, life: 0, health: 0 })
}

export const lookUpBasicTaxRate = (
  basicPlan: DisplayProduct,
  gender: Gender,
  age: Age,
  coveragePeriod: number
): Promise<number> => {
  return dataWrapper.getTaxRates().then((allTaxRates) => {
    return _lookUpBasicTaxRate(allTaxRates, basicPlan, gender, age, coveragePeriod)
  })
}
