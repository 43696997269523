// @flow

import * as React from 'react'
import _ from 'lodash'
import { get } from 'lodash/fp'
import { getBranches } from 'e-submission/domain/service'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { FormGroup, FormFeedback } from 'reactstrap'
import MESSAGES from 'core/data-model/constants/messages'
import { getToggles } from 'quick-quote/feature-toggles'

class BranchSelection extends React.Component<*, *> {
  constructor(props: *, state: *) {
    super(props)

    this.state = {
      options: [],
      errors: [],
      isFocus: false,
    }
  }

  _onSelect = (selectedItems: Object[]) => {
    const office = selectedItems[0]
    if (!_.isUndefined(office)) {
      this.props.updateSelectedValue({
        address1: get('address1')(office),
        address2: get('address2')(office),
        branchCode: get('branchCode')(office),
        branchName: get('branchName')(office),
        regionCode: get('regionCode')(office),
        regionName: get('regionName')(office),
        areaCode: get('areaCode')(office),
        areaName: get('areaName')(office),
        managerCode: get('managerCode')(office),
        managerFirstName: get('managerFirstName')(office),
        managerLastName: get('managerLastName')(office),
        fseCode: get('fseCode')(office),
        fseFirstName: get('fseFirstName')(office),
        fseLastName: get('fseLastName')(office),
      })
    } else {
      this.props.updateSelectedValue(undefined)
    }
  }

  _handleSearch = (query: string) => {
    getBranches(this.props.user, query).then((data) => {
      this._setOptions(data)
    })
  }

  _setOptions = (options: *) => {
    this.setState({ options })
  }

  render() {
    return (
      <FormGroup className={'branch-selection'}>
        <AsyncTypeahead
          ref="asyncTypeAhead"
          inputProps={{
            type: 'tel',
            maxLength: getToggles().ENABLE_SEARCH_BRANCH_ONLY_6_DIGIT ? 6 : 200,
            onPaste: getToggles().ENABLE_SEARCH_BRANCH_ONLY_6_DIGIT ? 'return false' : '',
          }}
          {...this.state}
          minLength={getToggles().ENABLE_SEARCH_BRANCH_ONLY_6_DIGIT ? 6 : 3}
          selected={[
            this.props.selectedValue && {
              ...this.props.selectedValue,
              isFocus: this.state.isFocus,
            },
          ]}
          useCache={false}
          labelKey={({ branchCode = '', branchName, isFocus }) =>
            isFocus ? branchCode : `${branchCode} - ${branchName}`
          }
          searchText={MESSAGES.SEARCHING}
          promptText={MESSAGES.TYPE_TO_SEARCH}
          emptyLabel={MESSAGES.DATA_NOT_FOUND}
          onSearch={this._handleSearch}
          placeholder={MESSAGES.BRANCH_PLACEHOLDER}
          renderMenuItemChildren={this._renderMenuItemChildren}
          onChange={this._onSelect}
          onFocus={() => this.setState({ isFocus: true })}
          onBlur={() => this.setState({ isFocus: false })}
        />
        <FormFeedback className="validation-message">{this.state.errors.join(' ')}</FormFeedback>
      </FormGroup>
    )
  }

  keydownValidate = (evt: *) => {
    var charCode = evt.which ? evt.which : evt.keyCode

    if (
      [46, 8, 9, 27, 13].indexOf(charCode) !== -1 ||
      // Allow: Ctrl+A, Command+A
      ((charCode === 65 || charCode === 67 || charCode === 86 || charCode === 88) &&
        (evt.ctrlKey === true || evt.metaKey === true)) ||
      // Allow: home, end, left, right, down, up
      (charCode >= 35 && charCode <= 40)
    ) {
      // let it happen, don't do anythinggit g
      return
    }
    // Ensure that it is a number and stop the keypress
    if ((evt.shiftKey || charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105)) {
      evt.preventDefault()
    }
  }

  componentDidMount() {
    this.refs.asyncTypeAhead.getInstance().refs.input._input._input.onkeydown = this.keydownValidate
    this.refs.asyncTypeAhead
      .getInstance()
      .refs.input._input._input.setAttribute('maxLength', getToggles().ENABLE_SEARCH_BRANCH_ONLY_6_DIGIT ? '6' : '200')
    this.refs.asyncTypeAhead
      .getInstance()
      .refs.input._input._input.setAttribute(
        'onPaste',
        getToggles().ENABLE_SEARCH_BRANCH_ONLY_6_DIGIT ? 'return false' : ''
      )
  }

  _renderMenuItemChildren(option: *, props: *, index: number) {
    return (
      <div key={option.branchCode}>
        <span>
          {option.branchCode} - {option.branchName}
        </span>
      </div>
    )
  }
}

export default BranchSelection
