//@flow
import React from 'react'
import { isEmpty, isNil, isNotNil } from 'core/service/lib/type-check'
import { renderBIAge } from 'core/service/insured/age'
import {
  getAge,
  getGender,
  getPayerAge,
  getPayerGender,
  getSelectedOccupation,
} from 'quick-quote/insured-information/selectors'
import { getSelectedDisplayProductName } from 'quick-quote/product-selection/selectors'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Link, withRouter } from 'react-router-dom'
import { Button } from 'reactstrap'
import MESSAGES from 'core/data-model/constants/messages'
import type { AppState } from 'quick-quote/reducers'
import { WithAnalytics } from 'analytics/components'
import type { NavButton, NavButtonConfiguration } from './navigations/quick-quote/nav-buttons'
import createNavigationButtonConfigurations from './navigations/nav-buttons'
import { clickHomeButton } from 'quick-quote/actions'
import { isSelectedRemoteSelling, isReviseQQFlow } from 'quick-quote/selectors'

const translations = {
  gender: {
    M: MESSAGES.MALE,
    F: MESSAGES.FEMALE,
  },
  unit: {
    year: MESSAGES.TIME_UNIT_YEAR,
    month: MESSAGES.TIME_UNIT_MONTH,
    day: MESSAGES.TIME_UNIT_DAY,
  },
}

export const PersonalInformationBreadCrumb = ({
  productName,
  gender,
  age,
  occupation,
  className,
  isReviseQQ,
}: Object) => {
  const getOccupationLabel = (occupation) => {
    if (occupation && occupation.th && occupation.th.occupationName && occupation.th.natureOfDuty) {
      return `${occupation.th.occupationName}, ${occupation.th.natureOfDuty}`
    } else {
      return '-'
    }
  }

  let infoWholeLife = [
    productName,
    translations.gender[gender],
    age.value ? renderBIAge(age, isReviseQQ) : '-',
    getOccupationLabel(occupation),
  ]
  let infoInvestment = [productName, translations.gender[gender], age.value ? renderBIAge(age, isReviseQQ) : '-']
  const personalInformation = productName === 'iWealthy' ? infoInvestment : infoWholeLife

  return (
    <div className={`personal-information-breadcrumb ${className ? className : ''}`}>
      <p>{personalInformation.filter((value) => isNotNil(value) && !isEmpty(value)).join(' / ')}</p>
    </div>
  )
}

const mapInsuredInformationBreadcrumbStateToProps = (state: AppState): * => ({
  productName: getSelectedDisplayProductName(state),
  age: getAge(state),
  occupation: getSelectedOccupation(state),
  gender: getGender(state),
  isReviseQQ: isReviseQQFlow(state),
})

export const InsuredInformationBreadcrumb = connect(mapInsuredInformationBreadcrumbStateToProps)(
  PersonalInformationBreadCrumb
)

const mapPayerInformationBreadcrumbStateToProps = (state: AppState): * => ({
  age: getPayerAge(state),
  gender: getPayerGender(state),
})

export const PayerInformationBreadcrumb = connect(mapPayerInformationBreadcrumbStateToProps)(
  PersonalInformationBreadCrumb
)

const _BackToHome = connect(
  (state) => ({
    selectedRemoteSelling: isSelectedRemoteSelling(state),
  }),
  (dispatch) => ({
    backToHome: bindActionCreators(clickHomeButton, dispatch),
  })
)((props) => (
  <Button
    onClick={props.backToHome}
    type="button"
    color={props.selectedRemoteSelling ? 'disabled' : 'primary'}
    outline={!props.selectedRemoteSelling}
    disabled={props.selectedRemoteSelling}
  >
    กลับ
  </Button>
))

const _BackButton = ({ backButton }) => {
  if (isEmpty(backButton)) {
    return null
  }
  if (backButton && backButton.toHome) {
    return <_BackToHome />
  }
  if (backButton && isNil(backButton.to)) {
    return (
      <Button onClick={() => window.history.back()} type="button" color="primary" outline={true}>
        กลับ
      </Button>
    )
  }
  if (backButton) {
    return (
      <Link to={backButton.to} className="back">
        <Button type="button" color="primary" outline={true}>
          กลับ
        </Button>
      </Link>
    )
  } else {
    return null
  }
}

const _NextButton = ({ nextButton }) => {
  if (isEmpty(nextButton)) {
    return null
  }

  if (nextButton.type === 'component') {
    return React.cloneElement(nextButton.component, { disabled: nextButton.disabled })
  }

  if (nextButton.disabled) {
    return <Button color="disabled">{nextButton.text}</Button>
  }

  return (
    <WithAnalytics eventType={nextButton.analyticsEventType} expectedEventType="BIEvent">
      <Link to={nextButton.to} className="next">
        <Button color="primary" onClick={nextButton.onClick}>
          {nextButton.text}
        </Button>
      </Link>
    </WithAnalytics>
  )
}

type NavigationButtonProps = {
  nextButton?: NavButton,
  location: { pathname: string },
  navigationButtonConfigurations: NavButtonConfiguration[],
}

export const _NavigationButton = (props: NavigationButtonProps) => {
  const { navigationButtonConfigurations, location } = props
  const navConfig = navigationButtonConfigurations.find(
    ({ path, excludePath }) =>
      location.pathname.startsWith(path) && (excludePath ? !location.pathname.includes(excludePath) : true)
  )

  if (navConfig) {
    const { backButton, nextButton, extraButton: ExtraButton } = navConfig.props
    return (
      <div id="nav-bottom" className="nav-bottom">
        <div className="nav-group">
          <div className="left">
            <_BackButton backButton={backButton} />
          </div>
          {ExtraButton && <ExtraButton />}
          <_NextButton nextButton={isNotNil(nextButton) ? nextButton : props.nextButton} />
        </div>
      </div>
    )
  } else {
    return null
  }
}

const mapStateToProps = (
  state: AppState
): {
  navigationButtonConfigurations: NavButtonConfiguration[],
} => ({
  navigationButtonConfigurations: createNavigationButtonConfigurations(state),
})

export const NavigationButton = withRouter(connect(mapStateToProps)(_NavigationButton))
