//@flow

import VALUES from 'core/data-model/constants/values'
import { getSellableFunds } from 'core/service/investment/funds'
import { getUserAgentType } from 'identity/selectors'
import _ from 'lodash'
import {
  CALCULATE_LSTU_TOTAL_FUND_ALLOCATION,
  TOGGLE_LSTU_FUND_ALLOCATION,
  allocateLstuIndependently,
} from 'quick-quote/product-investment/fund-allocation/lstu/actions'
import {
  onCalculateLstuTotalAllocationRequest,
  processAndUpdateLstuFundAllocation,
} from 'quick-quote/product-investment/fund-allocation/lstu/sagas'
import { getLstuFundAllocations } from 'quick-quote/product-investment/fund-allocation/lstu/selectors'
import {
  CALCULATE_RPP_TOTAL_FUND_ALLOCATION,
  TOGGLE_RPP_FUND_ALLOCATION,
  UPDATE_RPP_ACCEPT_FUND_ALLOCATION,
} from 'quick-quote/product-investment/fund-allocation/rpp/actions'
import {
  onCalculateRppTotalAllocationRequest,
  onEditRppFundAllocation,
  processAndUpdateRppFundAllocation,
} from 'quick-quote/product-investment/fund-allocation/rpp/sagas'
import { getRppFundAllocations } from 'quick-quote/product-investment/fund-allocation/rpp/selectors'
import { onToggleFundAllocation } from 'quick-quote/product-investment/fund-allocation/shared-sagas'
import {
  ACCEPT_FOREIGN_INVESTMENT,
  ACCEPT_HIGHER_RISK_INVESTMENT,
  CALCULATE_RISK,
} from 'quick-quote/product-investment/risk-questionnaire/actions'
import { getSelectedDisplayProduct } from 'quick-quote/product-selection/selectors'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import { SELECTED_PRODUCT } from '../../product-selection/actions'
import {
  getAllowForeignInvestment,
  getAllowHigherRiskInvestment,
  getRiskEvaluated,
} from '../risk-questionnaire/selectors'
import { updateAvailableFunds } from './actions'
import { getAvailableFunds } from './selectors'
import { getWealthSource, getFundList, getFundListLSTU, getPremiumPrefill } from 'quick-quote/my-wealth-plus/selectors'
import { FUND_ALLOCATION } from 'quick-quote/my-wealth-plus/constant'

export function* checkAndUpdateFundAllowance(action: Function): Generator<*, *, *> {
  const funds = yield select(getAvailableFunds)
  const allocations = yield select(getRppFundAllocations)
  const risk = yield select(getRiskEvaluated)
  const allowHigherRiskInvestment = yield select(getAllowHigherRiskInvestment)
  const allowForeignInvestment = yield select(getAllowForeignInvestment)
  const acceptableRisk = risk.value
  const fundStates = funds.map((fund) => ({
    ...fund,
    isOverRisk: fund.riskLevel > acceptableRisk,
    isAllowed:
      (allowHigherRiskInvestment || fund.riskLevel <= acceptableRisk) && (allowForeignInvestment || !fund.isForeign),
  }))

  let allowedFunds = fundStates.filter((fund) => fund.isAllowed)
  let allowedAllocations = allocations.filter(
    (allocation) => !!allowedFunds.find((fund) => fund.code === allocation.code)
  )
  // fundStates map data with fundListFromMWP
  const myWealthPlusSource = yield select(getWealthSource)
  const myWealthPremiumPrefill = yield select(getPremiumPrefill)
  const shouldUpdatePremiumPrefill =
    myWealthPlusSource === 'MY_WEALTH_PLUS' ? myWealthPremiumPrefill.includes(FUND_ALLOCATION) : false

  yield put(updateAvailableFunds(fundStates))
  if (myWealthPlusSource == 'MY_WEALTH_PLUS' && shouldUpdatePremiumPrefill) {
    const fundListFromMWP = yield select(getFundList)
    const fundListLSTUFromMWP = yield select(getFundListLSTU)
    let mapRppfundStateAndMWP = []
    let mapLSTUfundStateAndMWP = []

    if (!_.isUndefined(fundListFromMWP) && fundListFromMWP.length > 0) {
      mapRppfundStateAndMWP = fundListFromMWP.map((fundList) => {
        return {
          ...fundStates.find((fundState) => fundState.code === fundList.fundCode),
          fundAllocation: fundList.allocation,
          selected: true,
          errors: [],
        }
      })
    }

    if (!_.isUndefined(fundListLSTUFromMWP) && fundListLSTUFromMWP.length > 0) {
      mapLSTUfundStateAndMWP = fundListLSTUFromMWP.map((fundListLSTU) => {
        return {
          ...fundStates.find((fundState) => fundState.code === fundListLSTU.fundCode),
          fundAllocation: fundListLSTU.allocation,
          selected: true,
          errors: [],
        }
      })
    }

    if (Array.isArray(allowedAllocations) && !allowedAllocations.length) {
      // Check Array and must be empty
      yield call(processAndUpdateRppFundAllocation, mapRppfundStateAndMWP)
      if (_.isEmpty(fundListLSTUFromMWP) && !_.isNaN(fundListLSTUFromMWP)) {
        yield call(processAndUpdateLstuFundAllocation, mapRppfundStateAndMWP)
      } else {
        yield put(allocateLstuIndependently())
        yield call(processAndUpdateLstuFundAllocation, mapLSTUfundStateAndMWP)
      }
    }
  } else {
    yield call(processAndUpdateRppFundAllocation, allowedAllocations)
    yield call(processAndUpdateLstuFundAllocation, allowedAllocations)
  }
}

export function* loadSellableFunds(getSellableFunds: Function): Generator<*, *, *> {
  const displayProductQuery = yield select(getSelectedDisplayProduct)
  if (_.get(displayProductQuery, 'category') !== VALUES.INVESTMENT) {
    return
  }
  const userAgentType = yield select(getUserAgentType)
  const funds = yield call(getSellableFunds, userAgentType, displayProductQuery)
  const fundStates = funds.map((fund) => ({ ...fund, isAllowed: false }))
  yield put(updateAvailableFunds(fundStates))
}

export function* sagas(): Generator<*, *, *> {
  yield [takeEvery(SELECTED_PRODUCT, loadSellableFunds, getSellableFunds)]
  yield [takeEvery(CALCULATE_RPP_TOTAL_FUND_ALLOCATION, onCalculateRppTotalAllocationRequest)]
  yield [takeEvery(CALCULATE_LSTU_TOTAL_FUND_ALLOCATION, onCalculateLstuTotalAllocationRequest)]
  yield [takeEvery(UPDATE_RPP_ACCEPT_FUND_ALLOCATION, onEditRppFundAllocation)]
  yield [
    takeEvery(
      TOGGLE_RPP_FUND_ALLOCATION,
      onToggleFundAllocation(getRppFundAllocations, processAndUpdateRppFundAllocation)
    ),
  ]
  yield [
    takeEvery(
      TOGGLE_LSTU_FUND_ALLOCATION,
      onToggleFundAllocation(getLstuFundAllocations, processAndUpdateLstuFundAllocation)
    ),
  ]
  yield [
    takeEvery([CALCULATE_RISK, ACCEPT_HIGHER_RISK_INVESTMENT, ACCEPT_FOREIGN_INVESTMENT], checkAndUpdateFundAllowance),
  ]
}
