//@flow
import { combineReducers } from 'redux'
import type { PermissionResult } from 'core/service/distributor/permission'
import { reducer as proceedToOrdinaryProduct } from './validate-permission/reducer'

export type State = {
  proceedToOrdinaryProduct: PermissionResult,
}

export const reducer = combineReducers({
  proceedToOrdinaryProduct,
})
