// @flow

import type { AppState } from 'quick-quote/reducers'
import type { CoveragePlanDispatchers } from './components'
import { CoveragePlan } from './components'
import type { Dispatch } from 'redux'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { canProceedToCoveragePlan } from 'quick-quote/selectors/next-tab-proceeding'
import { updateProductPlan } from './actions'
import { getBasicPlanOptions } from './selectors'

import {
  toggleRider,
  editRiderSumAssured,
  editRiderSelectedPlan,
} from 'quick-quote/product-common/coverage-plan/actions'

import {
  getAvailableRiderCodes,
  getBasicPremiumState,
  getReadOnlyFields,
  getSumAssured,
  haveBasicPremiumState,
} from 'quick-quote/product-common/coverage-plan/selectors'

const mapStateToProps = (state: AppState) => ({
  canProceedToCoveragePlan: canProceedToCoveragePlan(state),
  riderCodes: getAvailableRiderCodes(state),
  basicPlanOptions: getBasicPlanOptions(state),
  selectedBasicPlanCode: getSumAssured(state),
  basicPremiumState: getBasicPremiumState(state),
  haveBasicPremiumState: haveBasicPremiumState(state),
  readOnlyFields: getReadOnlyFields(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>): CoveragePlanDispatchers => ({
  toggleRider: bindActionCreators(toggleRider, dispatch),
  editRiderSumAssured: bindActionCreators(editRiderSumAssured, dispatch),
  editRiderSelectedPlan: bindActionCreators(editRiderSelectedPlan, dispatch),
  updateSelectedBasicPlan: bindActionCreators(updateProductPlan, dispatch),
})

export const CoveragePlanContainer = connect(mapStateToProps, mapDispatchToProps)(CoveragePlan)
