import { compact } from 'lodash/fp'

import { getToggles } from 'quick-quote/feature-toggles'

import heightandWeightSection from 'e-submission/domain/data-model/form/heightAndWeightSection'
import healthSection1 from 'e-submission/domain/data-model/form/health/section1'
import healthSection1Igrow from 'e-submission/domain/data-model/form/health/section1-igrow'
import healthSection1S7 from 'e-submission/domain/data-model/form/health/section1-S7'
import healthSection1PA from 'e-submission/domain/data-model/form/personal-accident/healthSection1'
import healthSection2PA from 'e-submission/domain/data-model/form/personal-accident/healthSection2'
import haveDiagnosisSection from 'e-submission/domain/data-model/form/personal-accident/haveDiagnosisSection'
import fiveYearsHealthCheckSection from 'e-submission/domain/data-model/form/personal-accident/fiveYearsHealthCheckSection'
import useDrugSection from 'e-submission/domain/data-model/form/personal-accident/useDrugSection'
import isDisabledPersonSection from 'e-submission/domain/data-model/form/personal-accident/isDisabledPersonSection'
import healthSection2 from 'e-submission/domain/data-model/form/health/section2'
import healthSection2InsuredIgrow from 'e-submission/domain/data-model/form/health/section2-insured-igrow.js'
import healthSection2PayerIgrow from 'e-submission/domain/data-model/form/health/section2-payer-igrow.js'
import healthSection3 from 'e-submission/domain/data-model/form/health/section3'
import healthSection4 from 'e-submission/domain/data-model/form/health/section4'
import healthSection5 from 'e-submission/domain/data-model/form/health/section5'
import hasWasRejectedMRTASection from 'e-submission/domain/data-model/form/health/hasWasRejectedMRTASection'
import wasRejectedMRTASection from 'e-submission/domain/data-model/form/health/wasRejectedMRTASection'
import hasFamilyDiseaseMRTASection from 'e-submission/domain/data-model/form/health/hasFamilyDiseaseMRTASection'
import familyDiseaseMRTASection from 'e-submission/domain/data-model/form/health/familyDiseaseMRTASection'
import diagnosisMRTASection from 'e-submission/domain/data-model/form/health/diagnosisMRTASection'
import hasDiagnosisMRTASection from 'e-submission/domain/data-model/form/health/hasDiagnosisMRTASection'
import treatmentMRTASection from 'e-submission/domain/data-model/form/health/treatmentMRTASection'
import hasTreatmentMRTASection from 'e-submission/domain/data-model/form/health/hasTreatmentMRTASection'
import diseaseMRTASection from 'e-submission/domain/data-model/form/health/diseaseMRTASection'
import onlyHeightAndWeightSection from 'e-submission/domain/data-model/form/health/onlyHeightAndWeightSection'
import heightandWeightSectionPA from 'e-submission/domain/data-model/form/personal-accident/heightAndWeightSection'
import treatmentPAQuestionSection from 'e-submission/domain/data-model/form/personal-accident/treatmentQuestionSection'
import { checker } from '../selectors'

import {
  isSLI,
  canPregnant,
  isAuraSupport,
  havePayer,
  isWP,
  isPB,
  isIGrow,
  isS7,
  isGIOProduct,
  isMRTACategory,
  isPACategory,
} from 'e-submission/domain/data-model/form/selectors'

export const insuredHealth = (app) => {
  const isShowInsuredHealthSection3Page = canPregnant(app, 'insured')
  const isShowInsuredHealthSection4Page = isSLI(app)
  const isShowIGrow = isIGrow(app)
  const isShowAura = getToggles().ALLOW_AURA_QUESTIONARE && isAuraSupport(app)
  const isShowS7 = isS7(app)
  const isShowGIO = isGIOProduct(app)
  const isShowMRTA = isMRTACategory(app)
  const isShowPA = isPACategory(app)
  const { isYes } = checker(app)

  if (isShowPA) {
    const prefix = 'insured'
    return (
      !isShowAura && {
        id: 'insured-health',
        prefix,
        title: 'ประวัติสุขภาพของผู้เอาประกันภัย',
        label: 'สุขภาพผู้เอาประกัน',
        sections: compact([
          heightandWeightSectionPA('height-and-weight'),
          healthSection1PA('health-section-1-pa'),
          healthSection2PA('health-section-2-pa'),
          haveDiagnosisSection('haveDiagnosis'),
          isYes(`${prefix}.haveDiagnosis.checked`) &&
            treatmentPAQuestionSection(`${prefix}.haveDiagnosis.detail`, 'รายละเอียดที่', true),
          fiveYearsHealthCheckSection('fiveYearsHealthCheck'),
          isYes(`${prefix}.fiveYearsHealthCheck.checked`) &&
            treatmentPAQuestionSection(`${prefix}.fiveYearsHealthCheck.detail`, 'รายละเอียดที่', true),
          useDrugSection('useDrug'),
          isYes(`${prefix}.useDrug.checked`) && treatmentPAQuestionSection(`${prefix}.useDrug.detail`, 'รายละเอียดที่'),
          isDisabledPersonSection('isDisabledPerson'),
          isYes(`${prefix}.isDisabledPerson.checked`) &&
            treatmentPAQuestionSection(`${prefix}.isDisabledPerson.detail`, 'รายละเอียดที่'),
        ]),
      }
    )
  }

  return (
    !isShowAura && {
      id: 'insured-health',
      prefix: 'insured',
      title: 'ประวัติสุขภาพของผู้เอาประกันภัย',
      label: 'สุขภาพผู้เอาประกัน',
      sections: compact([
        !isShowMRTA && !isShowGIO && heightandWeightSection('height-and-weight'),
        isShowMRTA && onlyHeightAndWeightSection('height-and-weight'),
        isShowGIO && onlyHeightAndWeightSection('height-and-weight'),
        !isShowMRTA && !isShowGIO && !isShowS7 && !isShowIGrow && healthSection1('health-section-1'),
        !isShowMRTA && !isShowGIO && !isShowS7 && isShowIGrow && healthSection1Igrow('health-section-1-igrow'),
        !isShowMRTA && !isShowGIO && isShowS7 && healthSection1S7('health-section-1-S7'),
        !isShowMRTA && !isShowGIO && !isShowS7 && !isShowIGrow && healthSection2('health-section-2'),
        !isShowMRTA &&
          !isShowGIO &&
          !isShowS7 &&
          isShowIGrow &&
          healthSection2InsuredIgrow('health-section-2-insured-igrow'),
        !isShowMRTA && !isShowGIO && !isShowS7 && isShowInsuredHealthSection3Page && healthSection3('health-section-3'),
        !isShowMRTA && !isShowGIO && !isShowS7 && isShowInsuredHealthSection4Page && healthSection4('health-section-4'),
        !isShowMRTA && !isShowGIO && !isShowS7 && healthSection5('health-section-5'),
        isShowMRTA && hasWasRejectedMRTASection('wasRejected'),
        isShowMRTA && wasRejectedMRTASection('wasRejected'),
        isShowMRTA && hasFamilyDiseaseMRTASection('familyDisease'),
        isShowMRTA && familyDiseaseMRTASection('familyDisease'),
        isShowMRTA && diseaseMRTASection('disease'),
        isShowMRTA && hasDiagnosisMRTASection('diagnosis'),
        isShowMRTA && diagnosisMRTASection('diagnosis'),
        isShowMRTA && hasTreatmentMRTASection('treatment'),
        isShowMRTA && treatmentMRTASection('treatment'),
      ]),
    }
  )
}

export const payerHealth = (app) => {
  const isShowPayerHealthSection3Page = canPregnant(app, 'payer')
  const isShowPayerPage = havePayer(app)
  const isShowAura = getToggles().ALLOW_AURA_QUESTIONARE && isAuraSupport(app)
  const isShowIGrow = isIGrow(app)
  const isShowPayerHealth = () => {
    return !isShowAura && isShowPayerPage && (isWP(app) || isShowIGrow || isPB(app))
  }

  return (
    isShowPayerHealth() && {
      id: 'owner-health',
      prefix: 'payer',
      title: 'ประวัติสุขภาพของผู้ชำระเบี้ย',
      label: 'สุขภาพผู้ชำระเบี้ย',
      sections: compact([
        heightandWeightSection('height-and-weight'),
        !isShowIGrow && healthSection1('health-section-1'),
        isShowIGrow && healthSection1Igrow('health-section-1-igrow'),
        !isShowIGrow && healthSection2('health-section-2'),
        isShowIGrow && healthSection2PayerIgrow('health-section-2-payer-igrow'),
        isShowPayerHealthSection3Page && healthSection3('health-section-3'),
      ]),
    }
  )
}
