// @flow
import styled from 'styled-components'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { Benefit } from 'core/data-model/rider/benefits'
import RiderBenefitSummary from './rider-benefit-summary'
import RiderWithBenefitModal from './rider-benefit-modal'
import RiderList from './rider-list'
import { WordWraps } from 'common-components'

export type HBUDRRiderBenefitProps = {
  rider: Rider & RiderState,
  riderBenefitData: Benefit,
}

export type NoticeProps = {
  title: string,
  generic: {
    text: string,
    list: string[],
  },
}

export type BenefitContentValueDetail = {
  planCode: string,
  value: string,
}

export type BenefitContents = {
  text: string,
  values: BenefitContentValueDetail[],
}

export type ListText = {
  text: string,
}

const BenefitStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 667px) {
    display: inline-block;
  }
`

const MoreNoticeStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const ChildNoticeStyled = styled.div`
  flex: 1 0 22%;
`

const renderBenefit = (content: string, value: string, index: number) => {
  return (
    <BenefitStyled className={'hbudr-rider-benefit'} key={index}>
      <div>{content}</div>
      <div>{value}</div>
    </BenefitStyled>
  )
}

const benefitListBySelectedPlanCode = (contents: BenefitContents, selectedPlanCode: string) => {
  return contents.length > 0
    ? contents.map((content, index) =>
        renderBenefit(content.text, content.values.find((value) => value.planCode === selectedPlanCode).value, index)
      )
    : null
}

const renderNotice = (notice: NoticeProps) => {
  return (
    <RiderList
      unordered
      name="notice"
      items={notice.generic}
      render={(item, index) => (
        <div key={index}>
          <span className="description">{item.text}</span>
          {item.list ? (
            <MoreNoticeStyled>
              {item.list.map((value, index) => (
                <ChildNoticeStyled key={index}>{`- ${value.text}`}</ChildNoticeStyled>
              ))}
            </MoreNoticeStyled>
          ) : null}
          {item.detail ? renderNoticeDetail(item.detail) : null}
        </div>
      )}
    />
  )
}
const renderNoticeDetail = (list: ListText[]) => {
  return (
    <RiderList
      start={1}
      name="description"
      items={list}
      render={(item, index) => <span key={index}>{item.text}</span>}
    />
  )
}

const renderDisclaimerList = (list: ListText[]) => {
  return (
    <RiderList
      start={1}
      name="description"
      items={list}
      render={(item, index) => (
        <div>
          <div key={index}>{item.text}</div>
        </div>
      )}
    />
  )
}

const RenderBenefits = (props) => {
  const { content, rider } = props
  const { contents } = content
  return (
    <div className="hbudr-rider-content hbudr-rider-content--benefit">
      <p className="heading">{content.title}</p>
      <p className="description">
        <WordWraps>{content.description.join(' ')}</WordWraps>
      </p>
      {benefitListBySelectedPlanCode(contents, rider.selectedPlan.planCode)}
      {RenderBenefitsRemark(content.remark)}
    </div>
  )
}
const RenderBenefitsRemark = (list: ListText[]) => {
  return list.map((item, index) => <div key={index}> {item.text} </div>)
}
const RenderNotice = (props) => {
  const { content } = props
  return (
    <div className="hbudr-rider-content hbudr-rider-content--notice">
      <p className="heading">{content.title}</p>
      {renderNotice(content)}
    </div>
  )
}

const RenderDisclaimer = (props) => {
  const { content } = props
  return (
    <div className="hbudr-rider-content hbudr-rider-content--disclaimer">
      <p className="heading">{content.title}</p>
      <p className="description">{content.description[0]}</p>
      {renderDisclaimerList(content.list)}
    </div>
  )
}

const HBUDRRiderBenefit = (props: HBUDRRiderBenefitProps) => {
  const { rider, riderBenefitData } = props
  const title = `${rider.description} ${rider.name}`
  const riderName = rider.name.toLowerCase()

  const { benefits, notice, disclaimer } = riderBenefitData
  const benefitInfo = benefits && (
    <div className="hbudr-details-table">
      <RenderBenefits content={benefits} rider={rider} />
    </div>
  )

  return (
    <RiderWithBenefitModal
      id={`${riderName}-rider-benefit`}
      className={`rider ${riderName}-rider`}
      title={title}
      modalChildren={benefitInfo}
    >
      <RiderBenefitSummary rider={rider} />
      {benefitInfo}
      {disclaimer && <RenderDisclaimer content={disclaimer} />}
      {notice && <RenderNotice content={notice} />}
    </RiderWithBenefitModal>
  )
}

export default HBUDRRiderBenefit
