import { compose, withProps, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Flex } from 'rebass'
import { get } from 'lodash/fp'

import Button from 'e-submission/components/Button'
import { getIcon } from 'e-submission/components/Icon'
import { getEdaStatus, createNewEda, removeEDACheckStatus } from 'e-submission/apps/actions'
import {
  isEDACheckingStatus,
  isEDACheckingStatusError,
  isEDACheckingStatusExpired,
  getCurrentApp,
} from 'e-submission/apps/selectors'
import { getPolicyId, getRecurringPayment } from 'e-submission/domain/data-model/form/selectors'

const EDACheckStatusPage = (props) => {
  const {
    onClickCheckStatus,
    onClickResendForm,
    onClickChangeMethod,
    isLoading,
    isStatusError,
    isStatusExpired,
    policyId,
    accountNo,
  } = props

  return (
    <>
      {isLoading && (
        <div className="spinner-overlay">
          <div className="spinner-eda"></div>
        </div>
      )}
      <div className="eda-check-status">
        <label className="heading">สมัครหักค่าเบี้ยประกันอัตโนมัติผ่านบัญชีธนาคาร</label>
        <div className="content-container">
          <div className="sub-heading">ตรวจสอบสถานะผลการสมัครหักค่าเบี้ยประกันอัตโนมัติผ่านบัญชีธนาคาร</div>
          <div className="info-message">
            <div className="icon">{getIcon('checkFillCircle')}</div>
            กรุณาตรวจสอบแอปพลิเคชั่นธนาคารที่ต้องการตัดบัญชีธนาคารอัตโนมัติ
          </div>
          <Flex column width={1} align="center" className="button-container">
            {!isStatusExpired && (
              <Button className="btn-col" onClick={onClickCheckStatus}>
                ตรวจสอบสถานะ
              </Button>
            )}
            {(isStatusExpired || isStatusError) && (
              <Button
                className="btn-col"
                outline={!isStatusExpired}
                onClick={() => onClickResendForm(policyId, accountNo)}
              >
                สมัครอีกครั้ง
              </Button>
            )}
            {(isStatusExpired || isStatusError) && (
              <Button className="btn-col" outline onClick={onClickChangeMethod}>
                เปลี่ยนวิธีการสมัคร
              </Button>
            )}
          </Flex>
          <div className="info-message attached-icon form-control-feedback">
            <p>กรุณาทำรายการสมัครผ่านแอปพลิเคชั่นของทางธนาคารให้แล้วเสร็จภายใน 5 นาที</p>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  const isLoading = isEDACheckingStatus(state)
  const isStatusError = isEDACheckingStatusError(state)
  const isStatusExpired = isEDACheckingStatusExpired(state)

  const app = getCurrentApp(state)
  const recurringPayment = getRecurringPayment(app)
  const policyId = getPolicyId(app)
  const accountNo = get('bankBookNumber', recurringPayment)

  return {
    isLoading,
    isStatusError,
    isStatusExpired,
    policyId,
    accountNo,
  }
}
const mapDispatchToProps = (dispatch) => ({
  onClickCheckStatus: () => {
    dispatch(getEdaStatus())
  },
  onClickResendForm: (policyId, accountNo) => {
    const payload = {
      accountNo: accountNo,
      policyNo: policyId,
      bankName: 'KTB', // only KTB for now
      applyDtm: new Date().getTime(),
    }
    dispatch(createNewEda(payload))
  },
  unsetCheckStatusEDA: () => {
    dispatch(removeEDACheckStatus())
  },
})

export default (hoc) =>
  compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    withProps((props) => ({
      onClickChangeMethod: () => {
        const { unsetCheckStatusEDA } = props
        unsetCheckStatusEDA()
      },
    })),
    lifecycle({
      componentDidMount() {
        console.log('component mounted!', this.props)
      },
    }),
    hoc
  )(EDACheckStatusPage)
