// @flow
import Mustache from 'mustache'
import MESSAGES from 'core/data-model/constants/bi-messages'
import { christianToThaiDate } from 'core/service/lib/date'
import type { TaxDeduction } from 'core/service/benefit-illustration'
import { formatNumber } from 'core/service/lib/number-format'
import { renderListItems } from 'quick-quote/util/html-render-utils'

type FlexiHealthTaxBenefitProps = {
  taxDeduction: TaxDeduction,
}

export const FlexiHealthTaxBenefit = ({ taxDeduction }: FlexiHealthTaxBenefitProps) => {
  return (
    <div id="flexi-health-tax-benefit" className="legal group flexi-tax-benefit">
      <h3>{MESSAGES.TAX_DEDUCTION_BENEFIT_TITLE}</h3>
      <div className="disclaimer">
        <div>{MESSAGES.TAX_DEDUCTION_BENEFIT_SUB_TITLE}</div>
        <div
          dangerouslySetInnerHTML={{
            __html: `${MESSAGES.TAX_DEDUCTION_BENEFIT_SUB_DESCRIPTION} <strong>${MESSAGES.GIO_TAX_DEDUCTION_BENEFIT_SUB_DESCRIPTION_STRONG}</strong>`,
          }}
        ></div>
        <ul className="taxdeduction">
          <li>
            <div>{MESSAGES.FLEXI_HEALTH_TAX_BENEFIT_SUB_LIST[0].description}</div>
            <div>
              {Mustache.render(MESSAGES.FLEXI_HEALTH_TAX_BENEFIT_SUB_LIST[0].benefit, {
                value: formatNumber(taxDeduction.taxDeductionBasicPremiumForYear, 2, true),
              })}
            </div>
          </li>
        </ul>
        <p>{MESSAGES.TAX_DEDUCTION_BENEFIT_SUB_ADDITIONAL_HTML}</p>
        <br />
        <p>{MESSAGES.NOTE}:</p>
        <ul>
          <li>
            <strong>{MESSAGES.FLEXI_HEALTH_TAX_BENEFIT_SUB_NOTES[0][0]}</strong>{' '}
            {MESSAGES.FLEXI_HEALTH_TAX_BENEFIT_SUB_NOTES[0][1]}
          </li>
          <br />
          <li>{MESSAGES.FLEXI_HEALTH_TAX_BENEFIT_SUB_NOTES[2][0]} </li>
        </ul>
      </div>
    </div>
  )
}

type FlexiHealthLegalDisclaimerProps = {
  isPlatinum: boolean,
}
export const FlexiHealthLegalDisclaimer = ({ isPlatinum }: FlexiHealthLegalDisclaimerProps) => {
  return (
    <div id="flexi-health-legal-disclaimer" className="legal group">
      <h3>{MESSAGES.FLEXI_HEALTH_LEGAL_DISCLAIMER_CONDITION_TITLE}</h3>
      <h3>{MESSAGES.FLEXI_HEALTH_LEGAL_DISCLAIMER_CONDITION_SUB_TITLE}</h3>
      <ol>
        <li>{MESSAGES.FLEXI_HEALTH_LEGAL_DISCLAIMER_CONDITION_LIST_FIRST}</li>
        <li>{MESSAGES.FLEXI_HEALTH_LEGAL_DISCLAIMER_CONDITION_LIST_SECOND}</li>
        <li>
          {MESSAGES.FLEXI_HEALTH_LEGAL_DISCLAIMER_CONDITION_LIST_THIRD}
          <div className="flexi-diseases">
            <div>
              <ul>{renderListItems(MESSAGES.FLEXI_HEALTH_LEGAL_DISCLAIMER_CONDITION_LIST_THIRD_DISEASES, 0, 4)}</ul>
            </div>
            <div>
              <ul>{renderListItems(MESSAGES.FLEXI_HEALTH_LEGAL_DISCLAIMER_CONDITION_LIST_THIRD_DISEASES, 4, 8)}</ul>
            </div>
          </div>
        </li>
        {isPlatinum ? (
          <li>
            {MESSAGES.FLEXI_HEALTH_LEGAL_DISCLAIMER_CONDITION_LIST_FOURTH}
            <div className="flexi-health-care">
              <ul>{renderListItems(MESSAGES.FLEXI_HEALTH_LEGAL_DISCLAIMER_CONDITION_LIST_FOURTH_DISEASES, 0, 4)}</ul>
            </div>
          </li>
        ) : (
          ''
        )}
      </ol>
      <h3>{MESSAGES.FLEXI_HEALTH_LEGAL_DISCLAIMER_EXEMPTION_TITLE}</h3>
      <p>{MESSAGES.FLEXI_HEALTH_LEGAL_DISCLAIMER_EXEMPTION_SUB_TITLE}</p>
      <ol>
        <li>{MESSAGES.FLEXI_HEALTH_LEGAL_DISCLAIMER_EXEMPTION_LIST_FIRST}</li>
        <li>{MESSAGES.FLEXI_HEALTH_LEGAL_DISCLAIMER_EXEMPTION_LIST_SECOND}</li>
        <li>{MESSAGES.FLEXI_HEALTH_LEGAL_DISCLAIMER_EXEMPTION_LIST_THIRD}</li>
        <li>{MESSAGES.FLEXI_HEALTH_LEGAL_DISCLAIMER_EXEMPTION_LIST_FORTH}</li>
        <li>{MESSAGES.FLEXI_HEALTH_LEGAL_DISCLAIMER_EXEMPTION_LIST_FIFTH}</li>
      </ol>
      <div>{MESSAGES.FLEXI_HEALTH_LEGAL_DISCLAIMER_EXEMPTION_LIST_NOTE}</div>
    </div>
  )
}

type LegalDisclaimerProps = {
  startDate: Date,
  endDate: Date,
  isBancAgent: boolean,
}

export const LegalConditionDisclaimer = ({ startDate, endDate, isBancAgent }: LegalDisclaimerProps) => {
  return (
    <div id="legal-disclaimer" className="legal group">
      <h3>{MESSAGES.LEGAL_DISCLAIMER}</h3>
      <ol className="nested-counter">
        <li>
          {MESSAGES.POLICY_MAY_CHANGE}
          <ol className="nested-counter">
            <li>{MESSAGES.AGE_HEALTH_OR_CAREER}</li>
            <li>
              {Mustache.render(MESSAGES.FLEXI_HEALTH_INCREASE_IN_PREMIUM_ADDITIONAL_CONTRACT, {
                agentName: isBancAgent ? MESSAGES.FLEXI_HEALTH_BANC : MESSAGES.FLEXI_HEALTH_AGENT,
              })}
            </li>
          </ol>
        </li>
        <li>{MESSAGES.PRELIMINARY_OFFER_MAY_CHANGE}</li>
        <li>
          {Mustache.render(MESSAGES.FLEXI_HEALTH_OFFER_SALE_PERIOD, {
            startOffer: christianToThaiDate(startDate),
            endOffer: christianToThaiDate(endDate),
          })}
        </li>
      </ol>
    </div>
  )
}

export const LegalConditionDisclaimerMarketConduct = ({ startDate, endDate, isBancAgent }: LegalDisclaimerProps) => {
  return (
    <div id="legal-disclaimer-market-conduct" className="legal group">
      <h3>{MESSAGES.LEGAL_DISCLAIMER}</h3>
      <ol className="nested-counter">
        <li>
          {Mustache.render(MESSAGES.FLEXI_HEALTH_INCREASE_IN_PREMIUM_ADDITIONAL_CONTRACT, {
            agentName: isBancAgent ? MESSAGES.FLEXI_HEALTH_BANC : MESSAGES.FLEXI_HEALTH_AGENT,
          })}
        </li>
        <li>{MESSAGES.PRELIMINARY_OFFER_MAY_CHANGE}</li>
        <li>
          {Mustache.render(MESSAGES.FLEXI_HEALTH_OFFER_SALE_PERIOD, {
            startOffer: christianToThaiDate(startDate),
            endOffer: christianToThaiDate(endDate),
          })}
        </li>
      </ol>
    </div>
  )
}

export const LegalConditionDisclaimerMarketConductNoAddtionalContract = ({
  startDate,
  endDate,
  isBancAgent,
}: LegalDisclaimerProps) => {
  return (
    <div id="legal-disclaimer-market-conduct" className="legal group">
      <h3>{MESSAGES.LEGAL_DISCLAIMER}</h3>
      <ol className="nested-counter">
        <li>{MESSAGES.PRELIMINARY_OFFER_MAY_CHANGE}</li>
        <li>
          {Mustache.render(MESSAGES.FLEXI_HEALTH_OFFER_SALE_PERIOD, {
            startOffer: christianToThaiDate(startDate),
            endOffer: christianToThaiDate(endDate),
          })}
        </li>
      </ol>
    </div>
  )
}
