// @flow
import type { PremiumAmount } from 'core/data-model/investment'
import { getToggles } from 'quick-quote/feature-toggles'
import _ from 'lodash'

export type MinPaymentRange = {
  min: number,
  messages?: {
    min: string,
  },
  paymentFrequency?: string,
}

export type SPULPaymentRange = {
  max?: number | Object,
  min: number,
  messages?: string,
  age?: {
    min: number,
    max: number,
  },
}

export type MaxPaymentRange = {
  max: {
    maxRegularPremiumMultiplier: number,
  },
}

export type MinMaxRange = {
  max: number,
  min: number,
}

export type SumAssuredPremium = {
  annualRegularPremium: number,
  sumAssured: number,
}

export type PaymentRange = (MinPaymentRange & MaxPaymentRange) | MinPaymentRange | SPULPaymentRange

export const validateMinTopUpValueRule = (premiumPaymentRange: ?PaymentRange, regularTopUp: number) => {
  return getToggles().ENEBLE_IWEALTHY_OPTIMISE
    ? regularTopUp >= 0
    : regularTopUp === 0 || regularTopUp >= _.get(premiumPaymentRange, 'min', 0)
}

export const validateMinPremiumValueRule = (premiumPaymentRange: ?PaymentRange, premiumValue: number) => {
  const minimumPremium = premiumValue === 0 || premiumValue >= _.get(premiumPaymentRange, 'min', 0)
  const maxPremium = _.get(premiumPaymentRange, 'max', 0)
  const maximumPremium = premiumValue <= maxPremium
  return maxPremium === 0 ? minimumPremium : minimumPremium && maximumPremium
}

export const validateSumAssuredRule = (
  { min, max }: MinMaxRange,
  { sumAssured, annualRegularPremium }: SumAssuredPremium
) => {
  return sumAssured === 0 || (sumAssured >= annualRegularPremium * min && sumAssured <= annualRegularPremium * max)
}

export const validateMaxRegularTopUpRule = (
  premiumPaymentRange: ?PaymentRange,
  { regularTopUp, regularPremium }: PremiumAmount
) => {
  let premiumMultiplier = _.get(premiumPaymentRange, ['max', 'maxRegularPremiumMultiplier'], 1)
  return regularTopUp === 0 || regularTopUp <= premiumMultiplier * regularPremium
}
