//@flow
import { formattedHeaderWithStyles, productInfoHeaderStyles } from '../../common/components/product-information-header'
import type { Rider, RiderState } from 'core/data-model/rider'

export const packageThaiNameConstants = {
  PLATINUM: 'แพลทินั่ม',
  DIAMOND: 'ไดมอนด์',
  GOLD: 'โกลด์',
  BRONZE: 'บรอนซ์',
  SILVER: 'ซิลเวอร์',
  SMART: 'สมาร์ท',
}

const modifiedStyles = {
  ...productInfoHeaderStyles,
  productName: {
    ...productInfoHeaderStyles.productName,
    lineHeight: 0.8,
  },
}

export const getPdfHeaderParameters = (displayProduct: Rider & RiderState) => {
  const selectedPlan = displayProduct.selectedPlan
  const header1 = `${displayProduct.name} ${selectedPlan.plan}`
  const header2 = `${displayProduct.description} ${packageThaiNameConstants[selectedPlan.plan?.toUpperCase()]} (${
    selectedPlan.planCode
  })`
  return { header1, header2 }
}

export const render = (displayProduct: Rider & RiderState, redline: string) => {
  const { header1, header2 } = getPdfHeaderParameters(displayProduct)
  return formattedHeaderWithStyles(header1, header2, redline, modifiedStyles)
}
