import { renameProp, withProps, compose } from 'recompose'

import { isCordova } from 'e-submission/utils'
import MoreInfoPhotoCard from 'e-submission/components/Card/MoreInfoPhotoCard'
import { CheckedCard, NotCheckedCard, NotCheckedWithInputCard } from 'e-submission/components/Card/DocumentCard'

const Component = ({ validateFile, icon = 'camera', action, documents, ...props }) => (
  <div>
    {documents.map((documentProps, index) => (
      <MoreInfoPhotoCard
        key={index}
        icon={icon}
        action={action}
        validateFile={validateFile}
        CheckedComponent={CheckedCard}
        NotCheckedComponent={documentProps.editable ? NotCheckedWithInputCard : NotCheckedCard}
        {...documentProps}
        {...props}
      />
    ))}
  </div>
)

export default (hoc) =>
  compose(
    renameProp('value', 'documents'),
    renameProp('validate', 'validateFile'),
    withProps(({ isBrowseFile }) => ({
      action: isCordova && !isBrowseFile ? 'ถ่ายรูป' : 'แนบรูป',
    })),
    hoc
  )(Component)
