//@flow
import MESSAGES from 'core/data-model/constants/bi-messages'
import VALUES from 'core/data-model/constants/values'
import { bankOptionsOrdianry, bankOptionsATM } from '../components/bank-options'

import type { DisplayProduct } from 'core/service/display-product'

export const _paymentChannelsSubSection = (bankOptionsSection: Object, bankData: Object, basicPlan: DisplayProduct) => [
  { text: MESSAGES.PAYMENT_TERM_ORDINARY },
  {
    ol: [
      { text: MESSAGES.PAYMENT_CHANNEL_ORDINARY_1 },
      {
        text:
          basicPlan.productCategory !== VALUES.MRTA
            ? MESSAGES.PAYMENT_CHANNEL_ORDINARY_2
            : MESSAGES.PAYMENT_CHANNEL_ORDINARY_2_WITHOUT_CREDIT,
      },
      {
        stack: [
          { text: MESSAGES.PAYMENT_CHANNEL_ORDINARY_3 },
          { text: MESSAGES.PAYMENT_CHANNEL_ORDINARY_3_SUB_TITLE },
          bankOptionsSection,
        ],
      },
      {
        stack: [{ text: MESSAGES.PAYMENT_CHANNEL_ORDINARY_4 }, bankData],
      },
      { text: MESSAGES.PAYMENT_CHANNEL_ORDINARY_5 },
      { text: MESSAGES.PAYMENT_CHANNEL_ORDINARY_6 },
      { text: MESSAGES.PAYMENT_CHANNEL_ORDINARY_7 },
      { text: MESSAGES.PAYMENT_CHANNEL_ORDINARY_8, pageBreak: 'after' },
      { text: basicPlan.productCategory !== VALUES.MRTA ? MESSAGES.PAYMENT_CHANNEL_ORDINARY_9 : '' },
    ],
  },
]

export const paymentChannelsSubSectionOrdinary = async (basicPlan: DisplayProduct) => {
  const bankOptionsSection = await bankOptionsOrdianry()
  const bankData = await bankOptionsATM()
  return _paymentChannelsSubSection(bankOptionsSection, bankData, basicPlan)
}
