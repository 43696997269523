//@flow
import _ from 'lodash'
import { flow, cond, lte, constant, stubTrue, get } from 'lodash/fp'
import type Decimal from 'decimal.js'
import D from 'decimal.js'
import type { PolicyPeriod, COIRates, FeeStructure, LoyaltyBonusRate } from 'core/data-model/investment/types'
import type { Gender } from 'core/data-model/insured'
import { d } from 'core/service/lib/decimal'
import constants from 'core/data-model/constants/defaults'

export const accountValueBeforeDeductionIwealthy = (
  netAllocationPremium: Decimal,
  accountValuesAfterBonus: Decimal[]
): Decimal => {
  const previousAccountValueAfterBonus = _.last(accountValuesAfterBonus) || d(0)
  if (previousAccountValueAfterBonus.lt(0)) return d(0)

  return netAllocationPremium.plus(previousAccountValueAfterBonus)
}

export const accountValueBeforeDeductionIinvest = (
  netAllocationPremium: Decimal,
  accountValuesAfterBonus: Decimal[],
  netLumpsum: Decimal,
  policyPeriod: PolicyPeriod
): Decimal => {
  if (policyPeriod.year === 1 && policyPeriod.month === 1) return netAllocationPremium

  const previousAccountValueAfterBonus = _.last(accountValuesAfterBonus) || d(0)
  if (previousAccountValueAfterBonus.lt(0)) return d(0)

  return previousAccountValueAfterBonus.plus(netLumpsum)
}

export const accountValueAfterDeduction = (
  accountValueBeforeDeduction: Decimal,
  chargeOfInsurance: Decimal,
  adminCharge: Decimal
): Decimal => accountValueBeforeDeduction.minus(chargeOfInsurance).minus(adminCharge)

export const accountValueBeforeBonus = (accountValueAfterDeduction: Decimal, fundReturn: Decimal): Decimal =>
  accountValueAfterDeduction.plus(fundReturn)

export const accountValueAfterBonus = (accountValueBeforeBonus: Decimal, loyaltyBonus: Decimal): Decimal =>
  accountValueBeforeBonus.plus(loyaltyBonus)

export const loyaltyBonus = (
  { month, year }: PolicyPeriod,
  loyaltyBonusRates: LoyaltyBonusRate[],
  currentAccountValueBeforeBonus: Decimal,
  accountValueBeforeDeduction: Decimal,
  accountValuesBeforeBonus: { year: number, value: Decimal }[]
) => {
  if (month !== 12 || accountValueBeforeDeduction.lte(0)) return d(0)

  const loyaltyBonusRateForYear = loyaltyBonusRates.find(
    (r) => r.policyPeriodStart <= year && year <= r.policyPeriodEnd
  )
  if (!loyaltyBonusRateForYear || loyaltyBonusRateForYear.rate === 0.0) return d(0)

  const averageAccountValueBeforeBonus = _(accountValuesBeforeBonus)
    .filter({ year })
    .reduce((acc, accountValue) => acc.plus(d(accountValue.value)), currentAccountValueBeforeBonus)
    .div(d(constants.MONTHS_IN_YEAR))

  return averageAccountValueBeforeBonus.times(loyaltyBonusRateForYear.rate)
}

export const fundReturn = (expectedReturn: Decimal, accountValueAfterDeduction: Decimal) =>
  expectedReturn
    .plus(d(1))
    .pow(d(1).div(d(constants.MONTHS_IN_YEAR)))
    .minus(d(1))
    .times(accountValueAfterDeduction)

export const adminChargeRPP = (accountValueBeforeDeductionRPP: Decimal, amcRate: Decimal): Decimal =>
  accountValueBeforeDeductionRPP.times(amcRate)
export const adminChargeIwealthy = (accountValueBeforeDeduction: Decimal, amcRate: Decimal): Decimal =>
  accountValueBeforeDeduction.times(amcRate).div(d(constants.MONTHS_IN_YEAR))

export const adminChargeIinvest = (
  accountValueBeforeDeduction: Decimal,
  amcRate: Decimal,
  chargeOfInsuranceValue: Decimal
): Decimal => {
  const accountValueAfterDeduction = accountValueBeforeDeduction - chargeOfInsuranceValue
  return amcRate.div(d(constants.MONTHS_IN_YEAR)).times(accountValueAfterDeduction)
}

export const chargeOfInsurance = (coiRates: COIRates, age: number, gender: Gender, sumAtRisk: Decimal) => {
  const coi = _.find(coiRates[gender], { age })
  if (!coi) {
    throw new Error(`COI Rate missing for gender: ${gender}, age: ${age}`)
  }
  return sumAtRisk
    .times(d(coi.rate))
    .div(d(constants.MONTHS_IN_YEAR))
    .div(d(1000))
}

export const sumAtRiskIwealthy = (
  sumAssured: Decimal,
  accountValueBeforeDeduction: Decimal,
  deathBenefitRate: Decimal
): Decimal => {
  return D.max(sumAssured, accountValueBeforeDeduction)
    .times(deathBenefitRate)
    .minus(accountValueBeforeDeduction)
}

// TODO: use deathBenefit from product config
export const sumAtRiskIinvest = (sumAssured: Decimal, accountValueBeforeDeduction: Decimal): Decimal => {
  const hundredTenPercentSumAssuredMinusAccountValue = sumAssured.times(1.1).minus(accountValueBeforeDeduction)
  const tenPercentAccountValue = accountValueBeforeDeduction.times(0.1)
  return D.max(hundredTenPercentSumAssuredMinusAccountValue, tenPercentAccountValue)
}

export const deathBenefit = (
  sumAssured: Decimal,
  accountValueAfterBonus: Decimal,
  deathBenefitRate: Decimal
): Decimal => D.max(sumAssured, accountValueAfterBonus).times(deathBenefitRate)

type SurrenderPremium = {
  totalPremium: Decimal,
  regularPremium: Decimal,
  premiumCharge: Decimal,
  regularPremiumCharge: Decimal,
}

export const surrenderCashIWealthy = (
  feeStructure: FeeStructure,
  policyYear: number,
  accountValueAfterBonus: Decimal,
  currentPremium: SurrenderPremium,
  pastPremium: SurrenderPremium[]
): Decimal => {
  const surrenderChargeForYear = _.find(feeStructure.surrenderCharge, { policyYear }) || { charge: 0 }
  const surrenderCharge = surrenderChargeForYear.charge
  if (surrenderCharge === 0) return accountValueAfterBonus
  const accumulatedNetPremium: Decimal = _.reduce(
    pastPremium,
    (acc, s) => acc.plus(s.totalPremium).minus(s.premiumCharge),
    currentPremium.totalPremium.minus(currentPremium.premiumCharge)
  )
  const accumulatedNetRegularPremium: Decimal = _.reduce(
    pastPremium,
    (acc, s) => acc.plus(s.regularPremium).minus(s.regularPremiumCharge),
    currentPremium.regularPremium.minus(currentPremium.regularPremiumCharge)
  )
  const deduction = accountValueAfterBonus
    .times(accumulatedNetRegularPremium)
    .div(accumulatedNetPremium)
    .times(surrenderCharge)
  return accountValueAfterBonus.minus(deduction)
}

export const surrenderCashIInvest = (
  feeStructure: FeeStructure,
  policyYear: number,
  accountValueAfterBonus: Decimal
) => {
  const surrenderChargeForYear = _.find(feeStructure.surrenderCharge, { policyYear }) || { charge: 0 }
  const surrenderCharge = surrenderChargeForYear.charge
  if (surrenderCharge === 0) return accountValueAfterBonus
  const deduction = accountValueAfterBonus.times(surrenderCharge)
  return accountValueAfterBonus.minus(deduction)
}

const isSetAccountValueBenefitDeductRPPToZero = (d: AccountValueBenefitDeductRPP): boolean =>
  (d.year > 5 || d.previousRedemptionValueAtMonthEndPlusBonusRPP.toNumber() > 0) &&
  d.previousAccountValuePlusBonusAtMonthEnd.toNumber() <= 0

type AccountValueBenefitDeductRPP = {
  age: number,
  year: number,
  previousRedemptionValueAtMonthEndPlusBonusRPP: Decimal,
  previousAccountValuePlusBonusAtMonthEnd: Decimal,
  netAllocationRPPPremium: Decimal,
}

export const accountValueBenefitDeductRPP = (props: AccountValueBenefitDeductRPP): number => {
  return flow(
    cond([
      [flow(get('age'), lte(85)), constant(0)],
      [isSetAccountValueBenefitDeductRPPToZero, constant(0)],
      [
        stubTrue,
        constant(
          d(props.previousRedemptionValueAtMonthEndPlusBonusRPP)
            .plus(props.netAllocationRPPPremium)
            .toNumber()
        ),
      ],
    ])
  )(props)
}

const isSetAccountValueBenefitDeductLSTUToZero = (d: AccountValueBenefitDeductRPP): boolean =>
  d.previousAccountValuePlusBonusAtMonthEnd.toNumber() <= 0

type IsFirstYearAndFirstMonthProps = {
  year: number,
  month: number,
}
const isFirstYearAndFirstMonth = ({ year, month }: IsFirstYearAndFirstMonthProps) => year === 1 && month === 1

type AccountValueBenefitDeductLSTU = {
  age: number,
  year: number,
  previousRedemptionValueAtMonthEndPlusBonusLSTU: Decimal,
  previousAccountValuePlusBonusAtMonthEnd: Decimal,
  netAllocationLSTUPremium: Decimal,
}

export const accountValueBenefitDeductLSTU = (props: AccountValueBenefitDeductLSTU): number => {
  return flow(
    cond([
      [isFirstYearAndFirstMonth, constant(props.netAllocationLSTUPremium.toNumber())],
      [flow(get('age'), lte(85)), constant(0)],
      [isSetAccountValueBenefitDeductLSTUToZero, constant(0)],
      [
        stubTrue,
        constant(
          d(props.previousRedemptionValueAtMonthEndPlusBonusLSTU)
            .plus(props.netAllocationLSTUPremium)
            .toNumber()
        ),
      ],
    ])
  )(props)
}
