// @flow

import { Section, Bold } from 'core/service/pdf-generation/products/common/components/general'
import VALUE from 'core/data-model/constants/values'

export const row1 = [Bold({ style: 'tableHeader', colSpan: 2, text: 'ความยืดหยุ่นของกรมธรรม์' }), {}]
const row2 = [
  Bold('การสับเปลี่ยนกองทุน'),
  {
    stack: [
      {
        ul: [
          'การสับเปลี่ยนกองทุนบางส่วน หรือทั้งหมดสามารถทำได้บนพื้นฐานของเงื่อนไขของบริษัท',
          'สับเปลี่ยนกองทุนได้สูงสุด 5 ครั้งแรกในแต่ละปีของกรมธรรม์ โดยไม่มีค่าธรรมเนียม ครั้งที่ 6 ขึ้นไป มีค่าธรรมเนียม 150 บาทต่อรายการ',
          'การสับเปลี่ยนกองทุนแต่ละครั้งต้องมีมูลค่าที่ได้รับจากการขายคืนหน่วยลงทุนของกองทุนต้นทางขั้นต่ำอย่างน้อย 1,000 บาท ',
          {
            text: [
              'บริษัทจะดำเนินการซื้อหน่วยลงทุนของกองทุนปลายทาง ณ วันที่ได้รับเงินค่าขายคืนหน่วยลงทุนของกองทุนต้นทางครบทุกกองทุนแล้ว การสับเปลี่ยนกองทุนดังกล่าวข้างต้น ราคาเสนอขายของกองทุนปลายทาง',
              ' ณ วันที่บริษัทซื้ออาจมีราคาแตกต่างจากราคาเสนอขายกองทุนปลายทาง ณ วันที่บริษัทได้รับเงินจากการขายคืนหน่วยลงทุนของกองทุนต้นทาง โดยผู้เอาประกันภัยจะเป็นผู้รับความเสี่ยงของความแตกต่าง',
              'ระหว่างราคารับซื้อ และราคาขาย (Bid/offer spread) ในการสับเปลี่ยนกองทุนดังกล่าว',
            ],
          },
        ],
      },
    ],
  },
]
const row3 = [
  Bold('การถอนเงินจากกรมธรรม์ / การเวนคืนกรมธรรม์'),
  {
    stack: [
      {
        ul: [
          'ผู้เอาประกันภัยสามารถใช้สิทธิการถอนเงินจากกรมธรรม์ได้ตั้งแต่ วันที่กรมธรรม์เริ่มมีผลคุ้มครอง ตราบใดที่มูลค่าบัญชีกรมธรรม์ภายหลังการถอนยังไม่น้อยกว่า 5,000 บาท ในช่วง 3 ปีแรกของกรมธรรม์และยังไม่น้อยกว่า 15,000 บาทตั้งแต่ปีกรมธรรม์ที่ 4 เป็นต้นไป',
          'จำนวนเงินขั้นต่ำของการถอนเงินจากกรมธรรม์ในแต่ละครั้ง จะต้องไม่ต่ำกว่าที่บริษัทกำหนด',
          'ค่าธรรมเนียมการถอนเงินจากกรมธรรม์/การเวนคืนกรมธรรม์ เป็นไปตามตารางค่าธรรมเนียมการถอนเงินจากกรมธรรม์หรือการเวนคืนกรมธรรม์ ตามเงื่อนไขของกรมธรรม์',
          'ผู้เอาประกันภัยจะได้รับเงินค่าขายคืนหน่วยลงทุนภายใน 5 วันทำการ นับแต่วันที่บริษัทดำเนินการขายคืนหน่วยลงทุน เว้นแต่ในกรณีที่บริษัทจัดการกองทุนรวมได้รับการผ่อนผันระยะเวลาการคำนวณ มูลค่าสินทรัพย์สุทธิ มูลค่าหน่วยลงทุน ราคาขายหน่วยลงทุน และราคารับซื้อคืนหน่วยลงทุน จากสำนักงานคณะกรรมการกำกับหลักทรัพย์และตลาดหลักทรัพย์ หรือบริษัทจัดการกองทุนรวม เลื่อนกำหนดการชำระเงินค่าขายคืนหน่วยลงทุน ตามประกาศคณะกรรมการกำกับหลักทรัพย์ และตลาดหลักทรัพย์',
        ],
      },
    ],
  },
]
const row4 = [
  Bold('การหยุดพักชำระเบี้ยประกันภัย'),
  {
    stack: [
      {
        ul: [
          {
            text: [
              'ผู้เอาประกันภัยสามารถใช้สิทธิหยุดพักชำระเบี้ยประกันภัยหลักเพื่อความคุ้มครองรายงวดได้ตั้งแต่ปีกรมธรรม์ที่ 3 เป็นต้นไป หากผู้เอาประกันภัยไม่ชำระเบี้ยประกันภัยหลักเพื่อความคุ้มครองรายงวด',
              'ภายในระยะเวลาผ่อนผันชำระเบี้ยประกันภัย กรมธรรม์นี้จะอยู่ในลักษณะของการหยุดพักชำระเบี้ยประกันภัยโดยอัตโนมัติ',
            ],
          },
          'ในกรณีที่ผู้เอาประกันภัยไม่ชำระเบี้ยประกันภัยหลักเพื่อความคุ้มครองรายงวดภายในระยะเวลาผ่อนผันชำระเบี้ยประกันภัย ก่อนปีกรมธรรม์ที่ 3 กรมธรรม์นี้จะไม่มีลักษณะเป็นการหยุดพักชำระเบี้ยประกันภัย และทำให้กรมธรรม์นี้สิ้นผลบังคับ',
          'ในกรณีที่ผู้เอาประกันภัยชำระเบี้ยประกันภัยหลักเพื่อความคุ้มครองครบทุกงวดใน 2 ปีแรก แต่เลือกที่จะหยุดพักชำระเบี้ยประกันภัยหลักเพื่อความคุ้มครองรายงวดในช่วงระหว่างปีที่ 3 – 6 สิทธิในการได้รับโบนัสสำหรับการถือกรมธรรม์ (Loyalty Bonus) จะสิ้นสุดลง แต่หากผู้เอาประกันภัยชำระเบี้ยประกันภัยหลักเพื่อความคุ้มครองครบทุกงวดใน 6 ปีแรก แต่เลือกที่จะหยุดพักชำระเบี้ยประกันภัยหลักเพื่อความคุ้มครองรายงวดในปีใด บริษัทจะงดให้เงินโบนัสสำหรับการถือกรมธรรม์เฉพาะในปีกรมธรรม์นั้น',
        ],
      },
    ],
  },
]
const row5 = [Bold({ style: 'tableHeader', colSpan: 2, text: 'นิยามในส่วนของการลงทุนของกรมธรรม์' }), {}]
const row6 = [
  Bold('มูลค่าหน่วยลงทุน'),
  'มูลค่าทรัพย์สินสุทธิของแต่ละกองทุนภายใต้กรมธรรม์ หารด้วยจำนวนหน่วยลงทุนที่จำหน่ายได้แล้วทั้งหมดของกองทุนนั้น เมื่อสิ้นวันทำการที่คำนวณ',
]
const row7 = [
  Bold('มูลค่าบัญชีกรมธรรม์'),
  'ผลรวมของจำนวนหน่วยลงทุนของเบี้ยประกันภัย คูณกับมูลค่าหน่วยลงทุน ณ วันที่ประเมินราคาของแต่ละกองทุนภายใต้กรมธรรม์',
]
const row8 = [
  Bold('มูลค่ารับซื้อคืนหน่วยลงทุน'),
  'ผลรวมของจำนวนหน่วยลงทุน คูณกับราคารับซื้อคืนหน่วยลงทุน ณ วันที่ประเมินราคาของแต่ละกองทุนภายใต้กรมธรรม์',
]
const row9 = [
  Bold('มูลค่ารับซื้อคืนหน่วยลงทุนของเบี้ยประกันภัย'),
  'ผลรวมของจำนวนหน่วยลงทุนของเบี้ยประกันภัย คูณกับราคารับซื้อคืนหน่วยลงทุน ณ วันที่ประเมินราคาของแต่ละกองทุนภายใต้กรมธรรม์',
]
const row10 = [
  Bold('ราคารับซื้อคืนหน่วยลงทุน'),
  'ราคาที่นำไปใช้คำนวณเพื่อหาจำนวนหน่วยลงทุนที่จะถูกหักออก (Debited) เนื่องจากการขายหน่วยลงทุน ราคารับซื้อคืนหน่วยลงทุนของแต่ละกองทุนจะถูกคำนวณตามวิธีที่ระบุไว้ในหนังสือชี้ชวนของกองทุน (รายละเอียดและวิธีคำนวณแสดงไว้ในหนังสือชี้ชวนของกองทุนดังกล่าว)',
]
const row11 = [
  Bold('ราคาขายหน่วยลงทุน'),
  'ราคาที่นำไปใช้คำนวณเพื่อหาจำนวนหน่วยลงทุนที่จะได้รับ (Credited) จากการซื้อหน่วยลงทุน ราคาขายหน่วยลงทุนของแต่ละกองทุนจะถูกคำนวณตามวิธีที่ระบุไว้ในหนังสือชี้ชวนของกองทุน (รายละเอียดและวิธีคำนวณแสดงไว้ในหนังสือชี้ชวนของกองทุนดังกล่าว)',
]

const title = 'สรุปย่อ ข้อกำหนดและเงื่อนไขทั่วไป รวมทั้ง สิทธิและผลประโยชน์ตามกรมธรรม์ประกันภัย (ต่อ)'
const content = () => [
  {
    fontSize: 11,
    table: {
      widths: [180, '*'],
      body: [row1, row2, row3, row4, row5, row6, row7, row8, row9, row10, row11],
    },
    layout: {
      hLineColor: VALUE.MEDIUM_BLUE,
      vLineColor: VALUE.MEDIUM_BLUE,
      fillColor: function(i, node) {
        return i % 2 === 0 ? VALUE.ZEBRA_BACKGROUND_BLUE : null
      },
    },
  },
]

export const RPULConditions2 = () => Section(title, content())
