// @flow
import type { Option } from 'common-components/dropdown'
import { Dropdown } from 'common-components'
import { FormFeedback, FormGroup } from 'reactstrap'

export type BasicPlanSelectionProps = {
  basicPlanOptions: Option[],
  selectedBasicPlanCode: string,
  basicPlanValidationError: string,
  editSelectedPlan: (string) => void,
}

const BasicPlanSelection = ({
  basicPlanOptions,
  selectedBasicPlanCode,
  basicPlanValidationError,
  editSelectedPlan,
}: BasicPlanSelectionProps) => {
  return (
    <FormGroup className={`basic-plan-selection item basic ${basicPlanValidationError !== '' ? 'has-danger' : ''}`}>
      <div className="label">
        <label>เลือกแผนสัญญา iGrow</label>
      </div>
      <Dropdown
        className="basic-plan form-control"
        options={basicPlanOptions}
        onChange={editSelectedPlan}
        value={selectedBasicPlanCode}
      />
      <FormFeedback className="validation-message">{basicPlanValidationError}</FormFeedback>
    </FormGroup>
  )
}

export default BasicPlanSelection
