// @flow
import MESSAGES from 'core/data-model/constants/bi-messages'

export type BenefitSummaryTableProps = {
  lastGuaranteeDividend: string,
  totalGuaranteeDividend: string,
  totalGuaranteeDividendBeforeLastYear: string,
  nonGuaranteeDividendBeforeDueDate: {
    interestDeposit: string,
    lowInterestDeposit: string,
    midInterestDeposit: string,
    highInterestDeposit: string,
  },
  totalNonGuaranteeDividend: {
    totalInterestDeposit: string,
    lowInterestDeposit: string,
    midInterestDeposit: string,
    highInterestDeposit: string,
  },
}

const _BenefitSummaryTable = ({
  lastGuaranteeDividend,
  totalGuaranteeDividend,
  totalGuaranteeDividendBeforeLastYear,
  nonGuaranteeDividendBeforeDueDate,
  totalNonGuaranteeDividend,
}: BenefitSummaryTableProps) => (
  <div className="group" id="summary-benefit">
    <h3>{MESSAGES.INVESTMENT_BENEFITS}</h3>
    <div className="dividend-table">
      <table>
        <thead>
          <tr>
            <td rowSpan={2}>{MESSAGES.INVESTMENT_BENEFITS_SUMMARY}</td>
            <td rowSpan={2}>{MESSAGES.INVESTMENT_BENEFITS_GUARANTEE}</td>
            <td colSpan={3}>{MESSAGES.TWELVE_PL_BENEFIT_SUMMARY_SCENARIO}</td>
          </tr>
          <tr>
            <td width="15%">{MESSAGES.EXAMPLE_1}</td>
            <td width="15%">{MESSAGES.EXAMPLE_2}</td>
            <td width="15%">{MESSAGES.EXAMPLE_3}</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{MESSAGES.INVESTMENT_BENEFITS_GUARANTEE_CASHDROP}</td>
            <td className="text-right">{totalGuaranteeDividendBeforeLastYear}</td>
            <td className="text-right">{totalGuaranteeDividendBeforeLastYear}</td>
            <td className="text-right">{totalGuaranteeDividendBeforeLastYear}</td>
            <td className="text-right">{totalGuaranteeDividendBeforeLastYear}</td>
          </tr>
          <tr>
            <td>{MESSAGES.INVESTMENT_BENEFITS_GUARANTEE_MATURITY}</td>
            <td className="text-right">{lastGuaranteeDividend}</td>
            <td className="text-right">{lastGuaranteeDividend}</td>
            <td className="text-right">{lastGuaranteeDividend}</td>
            <td className="text-right">{lastGuaranteeDividend}</td>
          </tr>
          <tr>
            <td>{MESSAGES.INVESTMENT_BENEFITS_GUARANTEE_TOTAL}</td>
            <td className="text-right">{totalGuaranteeDividend}</td>
            <td className="text-right">{totalGuaranteeDividend}</td>
            <td className="text-right">{totalGuaranteeDividend}</td>
            <td className="text-right">{totalGuaranteeDividend}</td>
          </tr>
        </tbody>
        <tbody>
          <tr>
            <td>{MESSAGES.TWELVE_PL_BENEFIT_NONGUARANTEE_MATURITY}</td>
            <td className="text-right">{nonGuaranteeDividendBeforeDueDate.interestDeposit}</td>
            <td className="text-right">{nonGuaranteeDividendBeforeDueDate.lowInterestDeposit}</td>
            <td className="text-right">{nonGuaranteeDividendBeforeDueDate.midInterestDeposit}</td>
            <td className="text-right">{nonGuaranteeDividendBeforeDueDate.highInterestDeposit}</td>
          </tr>
          <tr>
            <td>{MESSAGES.TWELVE_PL_BENEFIT_NONGUARANTEE_TOTAL}</td>
            <td className="text-right">{totalNonGuaranteeDividend.totalInterestDeposit}</td>
            <td className="text-right">{totalNonGuaranteeDividend.lowInterestDeposit}</td>
            <td className="text-right">{totalNonGuaranteeDividend.midInterestDeposit}</td>
            <td className="text-right">{totalNonGuaranteeDividend.highInterestDeposit}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className="disclaimer">
      <p>
        {MESSAGES.NOTE}: {MESSAGES.TWELVE_PL_BENEFIT_DISCLAIMER}
      </p>
    </div>
  </div>
)

export default _BenefitSummaryTable
