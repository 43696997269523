// @flow
import type { Gender, Age } from 'core/data-model/insured'
import type { RiderState, Rider, RiderTaxRate } from 'core/data-model/rider'
import type { DisplayProductQuery } from 'core/service'
import { queryTaxRate } from 'core/service/rider/rider'
import { dataWrapper } from 'core/data'
import { getRiskiestOccupationFactorForRiders } from 'core/service/insured'

export const _getRiderTaxRates = (
  gender: Gender,
  age: Age,
  availableRiders: (Rider & RiderState)[],
  riderTaxRatesPayload: RiderTaxRate[],
  occupationFactor: { [string]: number }
): { [riderCode: string]: number } => {
  return availableRiders.map((rider) => {
    let riderTaxRates
    const planCode = rider.selectedPlan ? rider.selectedPlan.planCode : ''

    for (let riderTaxRate of riderTaxRatesPayload) {
      if ([rider.code, planCode].includes(riderTaxRate.riderCode)) {
        riderTaxRates = riderTaxRate
      }
    }

    if (!riderTaxRates)
      return {
        code: rider.code,
        TaxRate: {
          retire: 0,
          life: 0,
          health: 0,
        },
      }
    const queryDependencies = {
      selectedRiderPlanCode: planCode,
      gender: gender,
      age: age,
      occupationFactor: occupationFactor[rider.code],
    }

    const taxRate = queryTaxRate(rider, riderTaxRates, queryDependencies)

    return { code: rider.code, taxRate: taxRate }
  })
}

export const getRiderTaxRates = async (
  gender: Gender,
  age: Age,
  availableRiders: (Rider & RiderState)[],
  natureOfDuties: string[],
  displayProductQuery: DisplayProductQuery
) => {
  const riderTaxRatesPayload = await dataWrapper.getRiderTaxRates()
  const occupationFactor = await getRiskiestOccupationFactorForRiders(
    displayProductQuery,
    natureOfDuties,
    availableRiders
  )
  return _getRiderTaxRates(gender, age, availableRiders, riderTaxRatesPayload, occupationFactor)
}
