import styled from 'styled-components'

import { RadioButtonGroup } from 'common-components'
import { IsRealDevice } from 'e-submission/utils'
import { getToggles } from 'quick-quote/feature-toggles'
import OptyDetail from 'quick-quote/opty/components/optyDetail'
import CustomDialog from 'quick-quote/opty/containers/dialog'

const OptyDetailContent = styled.div`
  text-align: start;

  @media only screen and (min-width: 680px) {
    margin: 0 8px;
  }
`

const OptionSelectSection = styled.div`
  margin-top: 16px;
  margin-bottom: 8px !important;
`

const OptyDetailModal = ({ setSelectedOption, selectedOption, selectedOpty, onConfirm, isOpen, toggle }) => {
  return (
    <CustomDialog
      isOpen={isOpen}
      message={{
        title: 'เลือกช่องทางการนำเสนอการขาย',
      }}
      content={
        <OptyDetailContent>
          <OptyDetail
            name={selectedOpty.Name}
            source={selectedOpty.LeadSource}
            product={selectedOpty.Product_interest__c}
            mobile={selectedOpty.Mobile_of_Lead__c}
            date={selectedOpty.CreatedDate}
          />
          {getToggles().ENABLE_APPMAN_REMOTE_SELLING && (
            <OptionSelectSection className="form-group">
              <strong>โปรดเลือกช่องทางการนำเสนอการขาย</strong>
              <RadioButtonGroup
                value={selectedOption}
                options={[
                  {
                    text: 'Face to face',
                    value: 'f2f',
                  },
                  {
                    text: 'Digital remote selling',
                    value: 'rms',
                    canSelectable: IsRealDevice,
                  },
                ]}
                onChange={(optionValue) => {
                  setSelectedOption(optionValue)
                }}
                classNamePrefix="sellingOption"
                autoClassName={true}
              />
              {!IsRealDevice && (
                <div
                  style={{ width: '100%' }}
                  className="validation-message attached-icon info-message-wrap form-control-feedback"
                >
                  <p>กรณีต้องการขายผ่าน Remote selling สามารถใช้งานผ่านแอปพลิเคชันได้เท่านั้น</p>
                </div>
              )}
            </OptionSelectSection>
          )}
        </OptyDetailContent>
      }
      onConfirm={onConfirm}
      toggle={toggle}
    />
  )
}

export default OptyDetailModal
