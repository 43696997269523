// @flow

import { Redirect } from 'react-router-dom'
import { compose, branch, renderComponent } from 'recompose'
import type { Dispatch } from 'redux'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import type { AppState } from 'quick-quote/reducers'
import type { CoveragePlanDispatchers } from './components'
import { SelectPolicyOwner } from './components'
import { canProceedToCoveragePlan } from 'quick-quote/selectors/next-tab-proceeding'
import { getAvailableRiderCodes } from 'quick-quote/product-common/coverage-plan/selectors'
import { toggleRider } from 'quick-quote/product-common/coverage-plan/actions'
import { getSelectedDisplayProductCode } from 'quick-quote/product-selection/selectors'

const mapStateToProps = (state: AppState) => ({
  riderCodes: getAvailableRiderCodes(state),
  canProceedToSelectOwnerPolicy: canProceedToCoveragePlan(state),
  selectedDisplayProductCode: getSelectedDisplayProductCode(state),
})

const mapDispatchToProps = (dispatch: Dispatch<*>): CoveragePlanDispatchers => ({
  toggleRider: bindActionCreators(toggleRider, dispatch),
})

export const SelectPolicyOwnerContainer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  branch(
    (props) => !props.selectedDisplayProductCode,
    renderComponent(() => <Redirect to="/" push />)
  )
)(SelectPolicyOwner)
