//@flow
import { compact } from 'lodash'
import type { RiderState, Rider } from 'core/data-model/rider'
import MESSAGES from 'core/data-model/constants/bi-messages'
import { isCancerRiders } from 'core/service/benefit-illustration/legal-disclaimer'
import Mustache from 'mustache'
import { getToggles } from 'quick-quote/feature-toggles'
import { getAdditionalDisclaimerMessage } from 'quick-quote/product-common/benefit-illustration/components/rider-benefits/components/additional-disclaimer'
import VALUES from 'core/data-model/constants/values'
type RiderBenefitLastDisclaimerSectionProps = {
  riders: (Rider & RiderState)[],
}
/* Code below is obsoleted */
export const RiderBenefitLastDisclaimerSection = ({ riders }: RiderBenefitLastDisclaimerSectionProps) => {
  return {
    stack: compact([
      `${MESSAGES.NOTE}: ${MESSAGES.RIDER_BENEFIT_DISCLAIMER_ONE}`,
      isCancerRiders(riders) && {
        text: `${MESSAGES.RIDER_BENEFIT_CANCER_DISCLAIMER_TWO}`,
        margin: [37, 0, 0, 0],
      },
    ]),
    style: 'disclaimer',
    margin: [0, -20, 0, 0],
  }
}

const _breakLine = (message, code, isUpdatedDisclaimer) => {
  let match = 'ที่สัญญาเพิ่มเติมนี้แนบท้ายอยู่'
  const newoBreaklineDisclaimerRider = [VALUES.MHP]
  if (newoBreaklineDisclaimerRider.includes(code) && isUpdatedDisclaimer) {
    match = 'กรมธรรม์ประกันชีวิต'
  }
  if (message.length > 156) {
    return message.replace(match, `\n${match}`)
  }
  return message
}

export const AdditionalRiderDisclaimerSection = (rider: Rider & RiderState, overrideTitle?: string) => {
  const title = overrideTitle ? overrideTitle : `${rider.description} (${rider.name})`
  const coveragePeriod = rider.coveragePeriod.value - 1
  const isUpdatedDisclaimer = rider.code === 'MHP' && getToggles().ENABLE_OPTIONAL_IHEALTHY_ULTRA
  return getToggles().ADDITAIONAL_RIDER_DISCLAIMER
    ? {
        stack: [
          {
            text: `${_breakLine(
              Mustache.render(getAdditionalDisclaimerMessage(rider.code, isUpdatedDisclaimer), {
                title,
                coveragePeriod,
              }),
              rider.code,
              isUpdatedDisclaimer
            )}`,
          },
        ],
        style: 'disclaimer',
      }
    : {}
}
