// @flow
import _ from 'lodash'

import { createSelector } from 'reselect'
import type { AppState } from 'quick-quote/reducers'
import type { PolicyOwner } from 'core/data-model/policy-owner'
import CONSTANTS from 'core/data-model/constants/defaults'
import MESSAGES from 'core/data-model/constants/messages'
import { isNotNil } from 'core/service/lib/type-check'
import { isIDCardNo } from 'e-submission/utils/pattern'
import { getToggles } from 'quick-quote/feature-toggles'
import { isLoading } from 'quick-quote/ui/selectors'

export const getPolicyOwnerOptions = () => [
  {
    text: MESSAGES.SELF,
    value: CONSTANTS.SELF,
  },
  {
    text: MESSAGES.SPOUSE,
    value: CONSTANTS.SPOUSE,
  },
  {
    text: MESSAGES.CHILD,
    value: CONSTANTS.CHILD,
  },
]

export const getCurrentMaturityIDCard = (state: AppState): number =>
  _.get(state, 'insured.maturity.idCard.currentIDCard')

export const getValidMaturityIDCard = (state: AppState): number => _.get(state, 'insured.maturity.idCard.validIDCard')

export const getMaturityValidate = (state: AppState): boolean => _.get(state, 'insured.maturity.maturityValidate')

export const getPolicyOwner = (state: AppState): PolicyOwner => _.get(state, 'insured.maturity.policyOwner')

export const getErrorMaturityValidateMsg = (state: AppState) => state.insured.maturity.idCard.errors

export const getErrorSelectPolicyOwnerValidateMsg = (state: AppState) => state.insured.maturity.errors

export const canProceedToSelectPolicyOwner = createSelector(
  getCurrentMaturityIDCard,
  getValidMaturityIDCard,
  getMaturityValidate,
  isLoading,
  (
    currentMaturityIDCard: number,
    validMaturityIDCard: number,
    maturityValidate: Object,
    isLoading: boolean
  ): boolean => {
    const canProceed =
      isNotNil(currentMaturityIDCard) &&
      isNotNil(getValidMaturityIDCard) &&
      maturityValidate.maturityStatus === true &&
      currentMaturityIDCard === validMaturityIDCard &&
      isLoading === false
    return canProceed
  }
)

export const canProceedToNormalTab = createSelector(
  canProceedToSelectPolicyOwner,
  getPolicyOwner,
  getErrorSelectPolicyOwnerValidateMsg,
  isLoading,
  (canProceedToSelectPolicyOwner: boolean, policyOwner: string, errors: string[], isLoading: boolean): boolean => {
    const canProceed =
      canProceedToSelectPolicyOwner &&
      isNotNil(policyOwner) &&
      !_.isEmpty(policyOwner) &&
      errors.length === 0 &&
      isLoading === false
    return canProceed
  }
)

export const canEnableBtnProceedToSelectPolicyOwner = createSelector(
  getCurrentMaturityIDCard,
  (currentMaturityIDCard): boolean => {
    if (currentMaturityIDCard === null) return false
    else {
      const allowBtn = (idCard) => {
        if (getToggles().ENABLE_CHECK_FORMAT_IDCARD_S7CC) return isIDCardNo(idCard.toString())
        else return idCard.toString().length === 13
      }

      const canProceed = isNotNil(currentMaturityIDCard) && allowBtn(currentMaturityIDCard)
      return canProceed
    }
  }
)
