import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Input } from 'reactstrap'
import NumberFormat from 'react-number-format'

import { Dropdown } from 'common-components'

function RenderInputNumber(props) {
  const { id, value, setQuestion, validations } = props
  const normalizeValue = (v) => {
    if (!validations) return v
    if (validations.min || validations.max) {
      return Math.min(Math.max(0, validations.min, v), v, validations.max)
    }
    return v
  }
  return (
    <NumberFormat
      id={`fna.needGap.need.${id}`}
      value={value}
      disabled={false}
      maxLength="15"
      customInput={Input}
      thousandSeparator={true}
      allowNegative={false}
      type="tel"
      pattern="[0-9]*"
      onChange={(e, values) => setQuestion(id, normalizeValue(Number(values.value)))}
    />
  )
}

function RenderInputText(props) {
  const { id, value } = props
  const { setQuestion } = props
  return <Input value={value} onChange={(e) => setQuestion(id, e.target.value)} maxLength={100} />
}

function RenderSelect(props) {
  const { id, validations, setQuestion, value } = props
  const { optionType, options, optionValues } = validations
  const displayOptions = []
  if (optionValues && optionValues.length) {
    for (const option of optionValues) {
      displayOptions.push({
        value: option.value,
        text: option.text ? option.text : option.value,
      })
    }
  }
  if (displayOptions.length < 1 && optionType === 'number') {
    let numValue = options.min
    while (numValue <= options.max) {
      displayOptions.push({
        value: numValue,
        text: numValue,
      })
      numValue = numValue + options.step
    }
  }
  displayOptions.unshift({ value: '', text: 'กรุณาเลือก' })
  return (
    <Dropdown
      id={`fna.needGap.need.${id}`}
      options={displayOptions}
      value={value}
      disabled={false}
      onChange={(value) => setQuestion(id, value)}
    />
  )
}

function RenderRadioWithOther(props) {
  const { id, validations, value, questions, multiple, selectedPlanId } = props
  const { setQuestion } = props
  const { optionOther, optionValues } = validations

  const isChecked = (optValue) => (multiple ? _.includes(value, optValue) : optValue === value)
  const handleClick = (optValue) => () =>
    multiple ? setQuestion(id, _.xor(value || [], [optValue])) : setQuestion(id, optValue)

  const radioItem = optionValues
    .filter((option) => !option.onlyPlan || option.onlyPlan === selectedPlanId)
    .map((option, index) => {
      return (
        <label className="custom-control custom-checkbox" key={`${id}-${index}`} htmlFor={`${id}-${option.value}`}>
          <input
            className="custom-control-input"
            type={multiple ? 'checkbox' : 'radio'}
            name={`${id}-radio`}
            defaultChecked={isChecked(option.value)}
            id={`${id}-${option.value}`}
            onClick={handleClick(option.value)}
          />
          <span className="custom-control-indicator" />
          <span>{option.text}</span>
        </label>
      )
    })

  return (
    <div className="form-group form-group--radio form-group--radio-with-other">
      <div className="fieldset">{radioItem}</div>
      <div className="form-group--radio-other-input">
        {optionOther && isChecked(optionOther.value) ? (
          <RenderInputText
            {...props}
            id={questions[optionOther.questionKey].id}
            value={questions[optionOther.questionKey].value}
          />
        ) : null}
      </div>
    </div>
  )
}

function RenderQuestion(props) {
  const { id, type, label } = props
  return (
    <div key={id} id={`question-${id}`} data-id={id} className="form-group">
      <label>{label}</label>
      {type === 'number' ? <RenderInputNumber {...props} /> : null}
      {type === 'selectOneFromOptions' ? <RenderSelect {...props} /> : null}
      {type === 'selectOneFromOptionsAndOther' ? <RenderRadioWithOther {...props} /> : null}
      {type === 'selectManyFromOptionsAndOther' ? <RenderRadioWithOther {...props} multiple /> : null}
      {type === 'text' ? <RenderInputText {...props} /> : null}
    </div>
  )
}

export const TargetSavings = ({ targetSavings }) => {
  if (targetSavings === undefined) return <div>-</div>
  if (targetSavings <= 0) return <div>ท่านมีเงินออมเพียงพอต่อค่าใช้จ่ายในอนาคต</div>
  return (
    <NumberFormat
      id={'fna.needGap.need.target'}
      value={targetSavings}
      disabled={true}
      maxLength="15"
      customInput={Input}
      thousandSeparator={true}
      decimalPrecision={0}
      allowNegative={false}
      type="text"
      pattern="[0-9]*"
    />
  )
}

function NeedQuestions(props) {
  const { needPlan, questions, setQuestion, targetSavings, selectedPlanId } = props
  const { questionSet = [], subQuestionSet = [] } = needPlan

  return (
    <div
      id="az-need-and-gap__section--questions"
      className="az-need-and-gap__section az-need-and-gap__section--questions"
    >
      <div className="az-need-and-gap__title">
        <h1 className="az-need-and-gap__title-text">วางแผนทางการเงินตามที่ท่านต้องการ</h1>
      </div>
      <div className="az-need-and-gap__content">
        <div className="az-need-and-gap__summary">
          {subQuestionSet.length
            ? subQuestionSet.map((questionId) => {
                const questionProps = _.get(questions, questionId, {})
                return (
                  <RenderQuestion
                    key={questionId}
                    selectedPlanId={selectedPlanId}
                    setQuestion={setQuestion}
                    value={questions[questionId]}
                    questions={questions}
                    {...questionProps}
                  />
                )
              })
            : null}
        </div>
        <div className="az-need-and-gap__questions">
          <div className="az-need-and-gap__questions-title">
            <h2 className="az-need-and-gap__questions-title-text">
              กรุณากรอกข้อมูล (เพื่อใช้ประเมินเงินออมเป้าหมายของคุณ)
            </h2>
          </div>
          <div className="az-need-and-gap__questions-list">
            {questionSet.length
              ? questionSet.map((questionId) => {
                  const questionProps = _.get(questions, questionId, {})
                  return (
                    <RenderQuestion
                      key={questionId}
                      selectedPlanId={selectedPlanId}
                      setQuestion={setQuestion}
                      value={questions[questionId]}
                      questions={questions}
                      {...questionProps}
                    />
                  )
                })
              : null}
          </div>
          {needPlan.targetText && (
            <div data-id="targetSaving" className="form-group">
              <div className="input">
                <label>เงินออมเป้าหมาย (บาท)</label>
                <TargetSavings targetSavings={targetSavings} />
              </div>

              <div className="validation-message attached-icon info-message form-control-feedback">
                <p>{needPlan.targetText}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const propTypes = {
  questions: PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.string,
  }),
  selectedPlanId: PropTypes.string,
  needPlan: PropTypes.shape({}),
  setQuestion: PropTypes.func,
  targetSavings: PropTypes.number,
}
const defaultProps = {
  questions: {},
  needPlan: {},
  setQuestion() {},
  selectedPlanId: '',
}

NeedQuestions.propTypes = propTypes
NeedQuestions.defaultProps = defaultProps

export default NeedQuestions
export { NeedQuestions, RenderInputText, RenderInputNumber, RenderSelect, RenderRadioWithOther, RenderQuestion }
