// @flow
import _ from 'lodash'
import type { Effect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getDisplayProducts, getSelectedDisplayProductCode } from 'quick-quote/product-selection/selectors'
import { getSelectableProductBumnanReady, getSelectedProductBumnanReady } from './coverage-plan/selectors'
import type { SelectProductBumnanReady } from './actions'
import { SELECT_PRODUCT_BUMNAN_READY, selectBasicPlan } from './actions'
import { UPDATE_BIRTHDATE } from 'quick-quote/insured-information/actions'
import { populateAvailableRiders } from 'quick-quote/product-common/coverage-plan/sagas'
import CONSTANTS from 'core/data-model/constants/values'
import { getSumAssured } from 'quick-quote/product-common/coverage-plan/selectors'
import { editSumAssured, editBasicPremiumState } from 'quick-quote/product-common/coverage-plan/actions'

export const onUpdateSelectedBumnanReady = function*(action: SelectProductBumnanReady): Generator<*, *, *> {
  const displayProducts = yield select(getDisplayProducts)
  const productSelected = _.find(displayProducts, { code: action.payload })
  if (!!productSelected) {
    const sumAssured = yield select(getSumAssured)
    yield put(selectBasicPlan(productSelected))
    yield put(editSumAssured(sumAssured))
    yield put(editBasicPremiumState(-1))
  }
}

export const switchBumnanReadyProductByAge = function*(action: *): Generator<*, *, *> {
  const productCode = yield select(getSelectedDisplayProductCode)
  const selectableProducts = yield select(getSelectableProductBumnanReady)
  const selectedProductBumnanReady = getSelectedProductBumnanReady(selectableProducts, productCode)
  if (
    !!selectedProductBumnanReady &&
    selectedProductBumnanReady !== productCode &&
    CONSTANTS.BUMNAN_READY_CODE_GROUP.includes(productCode)
  ) {
    const displayProducts = yield select(getDisplayProducts)
    const productSelected = _.find(displayProducts, { code: selectedProductBumnanReady })
    yield put(selectBasicPlan(productSelected))
    yield call(populateAvailableRiders)
  }
}

export function* watchers(): Generator<*, *, Effect[]> {
  yield [
    takeLatest([UPDATE_BIRTHDATE], switchBumnanReadyProductByAge),
    takeLatest([SELECT_PRODUCT_BUMNAN_READY], onUpdateSelectedBumnanReady),
  ]
}

export const sagas = watchers
