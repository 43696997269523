//@flow
import type { PermissionResult } from 'core/service/distributor/permission'

export type Action = AllowProceedInvestment | ResetInvestmentPermission | RejectInvestmentPermission

type AllowProceedInvestment = { type: 'ALLOW_PROCEED_INVESTMENT' }
export const ALLOW_PROCEED_INVESTMENT = 'ALLOW_PROCEED_INVESTMENT'
export const allowProceedInvestment = (): AllowProceedInvestment => ({ type: 'ALLOW_PROCEED_INVESTMENT' })

type ResetInvestmentPermission = { type: 'RESET_INVESTMENT_PERMISSION' }
export const RESET_INVESTMENT_PERMISSION = 'RESET_INVESTMENT_PERMISSION'
export const resetInvestmentPermission = (): ResetInvestmentPermission => ({ type: 'RESET_INVESTMENT_PERMISSION' })

type RejectInvestmentPermission = { type: 'REJECT_INVESTMENT_PERMISSION', payload: PermissionResult }
export const REJECT_INVESTMENT_PERMISSION = 'REJECT_INVESTMENT_PERMISSION'
export const rejectInvestmentPermission = (permissionResult: PermissionResult): RejectInvestmentPermission => ({
  type: 'REJECT_INVESTMENT_PERMISSION',
  payload: permissionResult,
})

type ValidatingSalePermission = { type: 'VALIDATING_SALE_PERMISSION' }
export const VALIDATING_SALE_PERMISSION = 'VALIDATING_SALE_PERMISSION'
export const validatingSalePermission = (): ValidatingSalePermission => ({
  type: 'VALIDATING_SALE_PERMISSION',
})

type SalePermissionValidationCompleted = { type: 'SALE_PERMISSION_VALIDATION_COMPLETED' }
export const SALE_PERMISSION_VALIDATION_COMPLETED = 'SALE_PERMISSION_VALIDATION_COMPLETED'
export const salePermissionValidationCompleted = (): SalePermissionValidationCompleted => ({
  type: 'SALE_PERMISSION_VALIDATION_COMPLETED',
})
