// @flow

import type { AllRiderPremiumCalculationDependencies, DiscountRate } from 'core/data-model/rider'

import { d } from 'core/service/lib/decimal'
import { roundDown, round } from 'core/service/lib/number-format'
import { getToggles } from 'quick-quote/feature-toggles'
import VALUES from 'core/data-model/constants/values'

const PRECISION = 2

export const getRiderDiscount = (): DiscountRate => {
  return require('core/service/data-config/rider-discount/discount.json')
}

export const getPLRDiscount = (riderDependencies: AllRiderPremiumCalculationDependencies): number => {
  let discountRate = 0
  if (riderDependencies.occupationFactor === 0) {
    let sumAssured = riderDependencies.riderSumAssured
    let riderDiscount = getRiderDiscount()

    for (let item of riderDiscount.discountTable) {
      if (item.riderCode === 'PLR' || item.riderCode === 'PLS') {
        for (let rule of item.discountRules) {
          if (sumAssured >= rule.minSA && sumAssured <= rule.maxSA) {
            discountRate = rule.dicountRate
            break
          }
        }
      }
    }
  }
  return discountRate
}

export const calculateRiderPremiumForVaryingSumAssured = (
  riderDependencies: AllRiderPremiumCalculationDependencies
): number => {
  const premiumRate = d(riderDependencies.riderPremiumRate)
  const sumAssured = d(riderDependencies.riderSumAssured)
  const modelFactor = d(riderDependencies.modelFactor)

  const premium = premiumRate
    .times(sumAssured)
    .dividedBy(1000)
    .times(modelFactor)
    .toNumber()
  return roundDown(premium, PRECISION)
}

export const calculateRiderPremiumForVaryingSumAssuredRoundUp = (
  riderDependencies: AllRiderPremiumCalculationDependencies
): number => {
  const premiumRate = d(riderDependencies.riderPremiumRate)
  const sumAssured = d(riderDependencies.riderSumAssured)
  const modelFactor = d(riderDependencies.modelFactor)

  const premium = d(
    premiumRate
      .times(sumAssured)
      .dividedBy(1000)
      .toFixed(2)
  )
    .times(modelFactor)
    .toNumber()
  return roundDown(premium, PRECISION)
}

export const calculateRiderPremiumForPBG = (riderDependencies: AllRiderPremiumCalculationDependencies): number => {
  const premiumRate = d(riderDependencies.riderPremiumRate)
  const sumAssured = d(riderDependencies.riderSumAssured)
  const modelFactor = d(riderDependencies.modelFactor)
  const premium = d(
    premiumRate
      .times(sumAssured)
      .floor()
      .dividedBy(100)
      .toFixed(2)
  )
    .times(modelFactor)
    .toNumber()
  return roundDown(premium, PRECISION)
}

export const calculateRiderPremiumWithVaryingSumAssuredAndDiscount = (
  riderDependencies: AllRiderPremiumCalculationDependencies
): number => {
  const premiumRate = d(riderDependencies.riderPremiumRate)
  const sumAssured = d(riderDependencies.riderSumAssured)
  const modelFactor = d(riderDependencies.modelFactor)
  const occupationFactor = d(riderDependencies.occupationFactor)
  const discount = d(getPLRDiscount(riderDependencies))

  const premium = premiumRate
    .plus(occupationFactor)
    .minus(discount)
    .mul(sumAssured)
    .div(1000)
    .mul(modelFactor)
  return roundDown(premium, PRECISION)
}

export const calculateRiderPremiumForHIC = (riderDependencies: AllRiderPremiumCalculationDependencies): number => {
  const annualPremium = d(riderDependencies.riderPremiumRate)
  const modelFactor = d(riderDependencies.modelFactor)
  const premium = annualPremium.times(modelFactor).toNumber()

  return roundDown(premium, PRECISION)
}

export const calculateRiderPremiumForMEAPlus = calculateRiderPremiumForHIC
export const calculateRiderPremiumForMEB = calculateRiderPremiumForHIC
export const calculateRiderPremiumForMEK = calculateRiderPremiumForHIC
export const calculateRiderPremiumForMEX = calculateRiderPremiumForHIC

export const calculateRiderPremiumForHBUDR = (riderDependencies: AllRiderPremiumCalculationDependencies): number => {
  const premiumRate = d(riderDependencies.riderPremiumRate)
  const modelFactor = d(riderDependencies.modelFactor)
  const premium = premiumRate.times(modelFactor).toNumber()

  return roundDown(premium, PRECISION)
}

export const calculateRiderPremiumForHSUDR = (riderDependencies: AllRiderPremiumCalculationDependencies): number => {
  const premiumRate = d(riderDependencies.riderPremiumRate)
  const modelFactor = d(riderDependencies.modelFactor)
  const premium = premiumRate.times(modelFactor).toNumber()

  return roundDown(premium, PRECISION)
}

export const calculateRiderPremiumForCIUDR = (riderDependencies: AllRiderPremiumCalculationDependencies): number => {
  const premiumRate = d(riderDependencies.riderPremiumRate)
  const sumAssured = d(riderDependencies.riderSumAssured)
  const modelFactor = d(riderDependencies.modelFactor)
  const premium = sumAssured
    .div(1000)
    .mul(premiumRate)
    .mul(modelFactor)
  return roundDown(premium, PRECISION)
}

export const calculateRiderPremiumForTPDUDR = calculateRiderPremiumForCIUDR

export const calculateRiderPremiumBasedOnBasicPremium = (
  riderDependencies: AllRiderPremiumCalculationDependencies
): number => {
  const { basicPremium, riderPremiumRate } = riderDependencies
  const premium = d(basicPremium)
    .div(d(100))
    .times(d(riderPremiumRate))

  return roundDown(premium, PRECISION)
}

export const calculateRiderPremiumFromPremiumRateAndModelFactor = (riderDependencies: {
  riderPremiumRate: number,
  modelFactor: number,
}): number => {
  const { riderPremiumRate, modelFactor } = riderDependencies

  const premium = d(riderPremiumRate)
    .times(d(modelFactor))
    .toNumber()

  return roundDown(premium, PRECISION)
}

export const calculateRiderPremiumForMEBG = calculateRiderPremiumFromPremiumRateAndModelFactor
export const calculateRiderPremiumForMESG = calculateRiderPremiumFromPremiumRateAndModelFactor
export const calculateRiderPremiumForESCI = calculateRiderPremiumFromPremiumRateAndModelFactor
export const calculateRiderPremiumForWPEWL85 = calculateRiderPremiumFromPremiumRateAndModelFactor
export const calculateRiderPremiumForWPEIP85 = calculateRiderPremiumFromPremiumRateAndModelFactor

export const calculateRiderPremiumForWPD = calculateRiderPremiumBasedOnBasicPremium
export const calculateRiderPremiumForWPDD = calculateRiderPremiumBasedOnBasicPremium

export const calculateRiderPremiumForWPTPD = (riderDependencies: AllRiderPremiumCalculationDependencies): number => {
  const riderPremiumRate = d(riderDependencies.riderPremiumRate)
  const occupationFactor = d(riderDependencies.occupationFactor)
  const modelFactor = d(riderDependencies.modelFactor)
  const sumAssured = d(riderDependencies.riderSumAssured)

  const premiumOccupation = d(round(riderPremiumRate.times(occupationFactor), PRECISION))
  const roundSumAssured = d(roundDown(sumAssured.div(d(100)), 3))
  const annualPremium = d(roundDown(roundSumAssured.times(premiumOccupation), PRECISION))
  return roundDown(annualPremium.times(modelFactor), PRECISION)
}

export const calculateRiderPremiumForWPTPDCI = calculateRiderPremiumForWPTPD

export const calculateRiderPremiumForPBSDD = (riderDependencies: AllRiderPremiumCalculationDependencies): number => {
  const riderPremiumRate = d(riderDependencies.riderPremiumRate)
  const payerOccupationFactor = d(1)
  const modelFactor = d(riderDependencies.modelFactor)
  const sumAssured = d(riderDependencies.riderSumAssured)

  return roundDown(
    riderPremiumRate
      .times(payerOccupationFactor)
      .div(d(100))
      .times(sumAssured)
      .times(modelFactor),
    PRECISION
  )
}

export const calculateRiderPremiumForPBSDDCI = calculateRiderPremiumForPBSDD
export const calculateRiderPremiumForPBPDD = calculateRiderPremiumForPBSDD
export const calculateRiderPremiumForPBPDDCI = calculateRiderPremiumForPBSDD

export const calculateRiderPremiumForWP = (riderDependencies: AllRiderPremiumCalculationDependencies): number => {
  const isUseReCalculateWP = getToggles().ENABLE_RECALCULATE_PREMIUM_WP

  const premiumRate = d(riderDependencies.riderPremiumRate)
  const occupationFactor = d(riderDependencies.occupationFactor)
  const sumAssured = d(riderDependencies.riderSumAssured)
  const modelFactor = d(riderDependencies.modelFactor)

  if (isUseReCalculateWP) {
    const premiumOccupation = round(premiumRate.times(occupationFactor), PRECISION)
    const premiumSumassured = round(
      d(premiumOccupation)
        .times(sumAssured)
        .dividedBy(1000),
      PRECISION
    )
    const premiumAmount = d(premiumSumassured).times(modelFactor)

    return roundDown(premiumAmount, PRECISION)
  } else {
    const premium = premiumRate
      .times(occupationFactor)
      .times(sumAssured)
      .dividedBy(1000)
      .times(modelFactor)
      .toNumber()

    return roundDown(premium, PRECISION)
  }
}

export const calculateRiderPremiumForDCI = (riderDependencies: AllRiderPremiumCalculationDependencies): number => {
  const premium: number = d(riderDependencies.riderPremiumRate)
    .plus(d(riderDependencies.occupationFactor))
    .dividedBy(1000)
    .times(d(riderDependencies.riderSumAssured).times(d(riderDependencies.modelFactor)))
    .toNumber()
  return roundDown(premium, PRECISION)
}

export const calculateRiderPremiumForMEH = (riderDependencies: AllRiderPremiumCalculationDependencies): number => {
  const premiumRate = d(riderDependencies.riderPremiumRate)
  const modelFactor = d(riderDependencies.modelFactor)

  const premium = premiumRate.times(modelFactor).toNumber()

  return roundDown(premium, PRECISION)
}

export const calculateRiderPremiumForFH = (riderDependencies: AllRiderPremiumCalculationDependencies): number => {
  const premiumRate = d(riderDependencies.riderPremiumRate)
  const modelFactor = d(riderDependencies.modelFactor)
  const occupationFactor = d(riderDependencies.occupationFactor)

  const premium = premiumRate
    .times(occupationFactor)
    .times(modelFactor)
    .toNumber()

  return roundDown(premium, PRECISION)
}

export const calculateRiderPremiumForMHP = (riderDependencies: AllRiderPremiumCalculationDependencies): number => {
  const premiumRate = d(riderDependencies.riderPremiumRate)
  const modelFactor = d(riderDependencies.modelFactor)

  const premium = premiumRate.times(modelFactor).toNumber()
  return roundDown(premium, PRECISION)
}

export const calculateRiderPremiumForMRTAAP = (riderDependencies: AllRiderPremiumCalculationDependencies): number => {
  const premiumRate = d(riderDependencies.riderPremiumRate)
  const sumAssured = d(riderDependencies.riderSumAssured)
  if (typeof riderDependencies.loan == 'undefined') {
    return 0
  }
  return riderDependencies.loan.coveragePlan.coverage.paymentMethod === VALUES.CASH_INCLUDE_LOAN
    ? round(premiumRate * (sumAssured / 1000), PRECISION)
    : round(sumAssured * (roundDown(premiumRate, 4) / 1000), PRECISION)
}

export const calculateRiderPremiumForPARIDER = (riderDependencies: AllRiderPremiumCalculationDependencies): number => {
  const premiumRate = d(riderDependencies.riderPremiumRate).toNumber()
  return premiumRate
}
