// @flow
import type { DisplayProduct } from 'core/service/display-product'

// export const UPDATE_LIFE_SAVE_PRO_BASIC_PLAN = 'UPDATE_LIFE_SAVE_PRO_BASIC_PLAN'
// export type UpdateLifeSaveProBasicPlan = {
//   type: 'UPDATE_LIFE_SAVE_PRO_BASIC_PLAN',
//   payload: DisplayProduct
// }

// export const updateLifeSaveProBasicPlan = (product: DisplayProduct): UpdateLifeSaveProBasicPlan => ({
//   type: UPDATE_LIFE_SAVE_PRO_BASIC_PLAN,
//   payload: product
// })

export const SELECT_BASIC_PLAN = 'SELECT_BASIC_PLAN'
export type SelectBasicPlan = {
  type: 'SELECT_BASIC_PLAN',
  payload: DisplayProduct,
}
export const selectBasicPlan = (product: DisplayProduct): SelectBasicPlan => ({
  type: SELECT_BASIC_PLAN,
  payload: product,
})
