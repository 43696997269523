// @flow
import _ from 'lodash'
import { splitWords } from './line-wrapper'
import { Bold } from 'core/service/pdf-generation/products/common/components/general'

export type TableRow = (string | number | Object | *)[]
export type TableBody = TableRow[]

export type TableDefinition = {
  header: TableBody,
  body: TableBody,
  widthRatios: number[],
}

export const combineTables = (tableBodies: TableBody[]) => {
  const longestBodyLength = _.max(tableBodies.map((body) => body.length)) || 0
  const identityBody = Array(longestBodyLength).fill([])

  return tableBodies.reduce((body1, body2) => body1.map((body1Row, i) => body1Row.concat(body2[i])), identityBody)
}

export const SimpleHeader = (title: string, rowSpan: number = 3, fillColor: string = '#ffffff') => {
  const fillCount = rowSpan - 1
  return [[{ text: '\n\n' + title, rowSpan, alignment: 'center', fillColor }]].concat(
    _.fill(Array(fillCount), [{ text: '', fillColor }])
  )
}

export const ColorfulHeader = (title: string | string[], fillColor: string = '#FFFFFF', colSpan: number = 1) => {
  return [
    [
      Bold({ text: title, colSpan, alignment: 'center', fillColor }),
      ..._.times(colSpan - 1, _.constant({ text: '', fillColor })),
    ],
  ]
}

export const WithPadding = (text: string) => {
  return '\n' + text + '\n'
}

export const GroupedTableHeader = (
  mainTitle: string,
  subTitles: string[],
  startIndex: number,
  fillColor: string = '#FFFFFF'
): TableBody => {
  const colSpan = subTitles.length

  return [
    [
      { text: mainTitle, alignment: 'center', fillColor, colSpan },
      ..._.times(colSpan - 1, _.constant({ text: '', fillColor })),
    ],
    subTitles.map((subTitle) => ({
      text: splitWords(subTitle),
      fillColor,
      alignment: 'center',
    })),
    subTitles.map((subTitle, index) => ({
      text: `(${startIndex + index})`,
      fillColor,
      alignment: 'center',
    })),
  ]
}

export const StackedTableHeader = (title: string, columnIndex: number, fillColor: string = '#FFFFFF'): TableBody => {
  const rowSpan = 2
  return [
    [{ text: ['\n'].concat(splitWords(title)), alignment: 'center', rowSpan, fillColor }],
    [{ text: '', fillColor }],
    [{ text: `(${columnIndex})`, fillColor, alignment: 'center' }],
  ]
}

export const assembleTable = (tableDefinitions: { header: TableBody, body: TableBody }[]): TableBody => {
  const tableBodies = tableDefinitions.map(({ header, body }) => header.concat(body))
  return combineTables(tableBodies)
}

export const centerAlignTable = (table: Object) => ({
  columns: [
    { width: '*', text: '' },
    {
      width: 'auto',
      ...table,
    },
    { width: '*', text: '' },
  ],
})

export const formatFromZeroToDash = (number: number) => (Number(number) === 0 ? '-' : number)
