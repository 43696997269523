// @flow
import type { DisplayProduct } from 'core/service/display-product'
import { getS7BenefitData, isS7Product } from 'core/service/basic-plan/benefit'

import { renderListItems } from 'quick-quote/util/html-render-utils'
import Mustache from 'mustache'
import { formatNumber } from 'core/service/lib/number-format'
import { WordWraps } from 'common-components'

type S7ProductBenefitsProps = {
  displayProduct: DisplayProduct,
  sumAssured: number,
}

type diseasesDetail = {
  title: string,
  desc: string,
  detail: string,
}

export const renderDiseaseItems = (data: diseasesDetail[]) => {
  return data.map((dataRow, index) => {
    return (
      <li key={`data_${index}`}>
        <span className="title">{dataRow.title}</span>
        &nbsp;<WordWraps>{dataRow.desc.replace(/\n/g, '')}</WordWraps>
        <ol>
          {renderListItems(
            dataRow.detail.map((item) => item.replace(/\n/g, '')),
            0,
            dataRow.detail.length
          )}
        </ol>
        {dataRow.remark.replace(/\n/g, '')}
      </li>
    )
  })
}

const S7ProductBenefits = ({ displayProduct, sumAssured }: S7ProductBenefitsProps) => {
  let benefitData = null
  if (isS7Product(displayProduct.basicPlanCode)) {
    benefitData = getS7BenefitData()
  } else {
    return null
  }
  const majorStageFactor = benefitData.majorStageFactor[displayProduct.basicPlanCode]
  const majorStageSumassured = (sumAssured * majorStageFactor) / 100

  return (
    <div id="product-benefits-content">
      <div id="major-stage-benefit">
        <p className="title">
          {Mustache.render(benefitData.majorStageCriticalIllnessTitle, {
            majorStageSumassured: formatNumber(majorStageSumassured),
          })}
        </p>
        {Mustache.render(benefitData.majorStageCriticalIllnessMaximumBenefit, {
          majorStageFactor: formatNumber(majorStageFactor),
        })}
        <p>
          <div>{benefitData.majorStageCriticalIllnessTerminate}</div>
          <div>{benefitData.majorStageCriticalIllnessTerminateSecondLine}</div>
        </p>
        <p className="title">{benefitData.majorStageCriticalIllnessDiseasesCovered}</p>

        <div className="disease">
          <div className="block">
            <ol start="1">{renderListItems(benefitData.diseases, 0, 4)}</ol>
          </div>
          <div className="block">
            <ol start="5">{renderListItems(benefitData.diseases, 4, 7)}</ol>
          </div>
        </div>
        <div className="remark-disease">
          {benefitData.majorStageCriticalIllnessDiseasesCoveredRemark.replace(/\n/g, '')}
        </div>
      </div>

      <div id="disease-info">
        <p className="title">{benefitData.diseasesTitle}</p>
        {benefitData.diseasesDesc}
        <ol>{renderDiseaseItems(benefitData.diseasesDetail)}</ol>
      </div>

      <div id="important-facts">
        <p className="title">{benefitData.importantFacts}</p>
        <ul>
          <li>{benefitData.waitingPeriod.replace(/\n/g, '')}</li>
          <li>{benefitData.incorrectInformation}</li>
          <li>
            {benefitData.cliamFormHeader}
            <ol>
              {renderListItems(
                benefitData.claimFormList.map((item) => item.replace(/\n/g, '')),
                0,
                4
              )}
            </ol>
          </li>
          <li>{benefitData.policyChangeCondition}</li>
        </ul>
      </div>

      <div id="exemption">
        <p>
          <b>{benefitData.majorExemption}</b>
        </p>
        {benefitData.reasonsForNoCoverage}
        <ol>
          {renderListItems(
            benefitData.majorExemptions.slice(0, 7).map((item) => item.replace(/\n/g, '')),
            0,
            7
          )}
          <ol>
            {' '}
            {renderListItems(
              benefitData.majorExemptions[7].map((item) => item.replace(/\n/g, '')),
              0,
              3
            )}{' '}
          </ol>
        </ol>
      </div>
    </div>
  )
}

export default S7ProductBenefits
