// @flow
import { FormFeedback, FormGroup } from 'reactstrap'
import { connect } from 'react-redux'

import type { Option } from 'common-components/dropdown'
import Dropdown from 'common-components/dropdown'
import messages from 'core/data-model/constants/messages'

import { getFnaReasonToSelectProduct } from 'quick-quote/fna/selectors'
import { getSelectedDisplayProductName } from 'quick-quote/product-selection/selectors'
import { setFnaMarketConductReasonOthers, setMarketConductReasonValue } from 'quick-quote/fna/market-conducts/actions'
import {
  getMarketConductReason,
  getMarketConductReasonOther,
  isReasonOthers,
} from 'quick-quote/fna/market-conducts/selectors'

import type { AppState } from 'quick-quote/reducers'

type Props = {
  reason: string,
  defaultReason: string,
  productName: string,
  selectReason: (string) => void,
  options: Option[],
  errors?: string,
  isReasonOthers: boolean,
  reasonOthers: string,
  editReasonOthers: (string) => void,
}
export const Reason = ({
  reason,
  defaultReason,
  selectReason,
  productName,
  options,
  errors,
  isReasonOthers,
  reasonOthers,
  editReasonOthers,
}: Props) => {
  return (
    <FormGroup className={`reason-selection ${errors ? 'has-danger' : ''}`}>
      <div className="reason-input">
        <label>
          {messages.REASON_LABEL} {productName}
        </label>
        <Dropdown id="reason" value={reason} options={options} onChange={(e) => selectReason(e)} />
        <FormFeedback className="validation-message">{errors}</FormFeedback>
        {isReasonOthers ? (
          <div id="reason-other">
            <label>{messages.REASON_OTHERS}</label>
            <input
              id="other"
              className="form-control"
              type="text"
              value={reasonOthers}
              onChange={(e) => editReasonOthers(e.target.value)}
            />
          </div>
        ) : null}
      </div>
    </FormGroup>
  )
}

const ReasonContainer = connect(
  (state: AppState) => {
    return {
      reason: getMarketConductReason(state),
      options: getFnaReasonToSelectProduct(state),
      productName: getSelectedDisplayProductName(state),
      isReasonOthers: isReasonOthers(state),
      reasonOthers: getMarketConductReasonOther(state),
    }
  },
  {
    selectReason: setMarketConductReasonValue,
    editReasonOthers: setFnaMarketConductReasonOthers,
  }
)(Reason)

export default ReasonContainer
