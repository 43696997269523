// @flow

import type { Checker, Checked } from 'core/service/lib/check'
import { createChecker } from 'core/service/lib/check'
import type { InvestmentFundLimits } from 'core/service/investment'
import { getInvestmentFundLimits } from 'core/service/investment'
import type { InvestmentFundAllocation } from './rules'
import { validateTotalAllocationRule, validateMaxFundAllocationRule, validateMinFundAllocationRule } from './rules'
import MESSAGES from 'core/data-model/constants/validation-messages'
import Mustache from 'mustache'
import type { DisplayProductQuery } from 'core/service'

export const _validateInvestmentFundTotalAllocation = (displayProductQuery: DisplayProductQuery) => async (
  totalFundAllocation: number,
  createValidationChecker: * = createChecker,
  getFunds: * = getInvestmentFundLimits
): Promise<Checked<number>> => {
  const totalAllocationSpec = {
    type: 'VALIDATION',
    rule: validateTotalAllocationRule,
    message: MESSAGES.RULE_IWEALTHY_TOTAL_FUND_LIMIT,
  }
  const investmentFundLimits: InvestmentFundLimits = await getFunds(displayProductQuery)
  const validationChecker: Checker<InvestmentFundLimits, number> = createValidationChecker([
    ['withinRpulFundLimitRange', totalAllocationSpec],
  ])
  return validationChecker(investmentFundLimits, totalFundAllocation)
}

export const validateInvestmentFundTotalAllocation = (displayProductQuery: DisplayProductQuery) => async (
  totalFundAllocation: number
): Promise<Checked<number>> => _validateInvestmentFundTotalAllocation(displayProductQuery)(totalFundAllocation)

export const _validateInvestmentFundAllocation = (displayProductQuery: DisplayProductQuery) => async (
  fund: InvestmentFundAllocation,
  createValidationChecker: * = createChecker,
  getFunds: * = getInvestmentFundLimits
): Promise<Checked<InvestmentFundAllocation>> => {
  const fundMaxAllocationSpec = {
    type: 'VALIDATION',
    rule: validateMaxFundAllocationRule,
    message: MESSAGES.RULE_IWEALTHY_MAX_FUND_LIMIT,
  }
  const fundMinAllocationSpec = {
    type: 'VALIDATION',
    rule: validateMinFundAllocationRule,
    message: (investmentFundLimits, _) =>
      Mustache.render(MESSAGES.RULE_IWEALTHY_MIN_FUND_LIMIT, {
        minFundAllocation: investmentFundLimits.minFundAllocation,
      }),
  }
  const investmentFundLimits: InvestmentFundLimits = await getFunds(displayProductQuery)
  const validationChecker: Checker<InvestmentFundLimits, InvestmentFundAllocation> = createValidationChecker([
    ['withinRpulMaxLimit', fundMaxAllocationSpec],
    ['withinRpulMinLimit', fundMinAllocationSpec],
  ])
  return validationChecker(investmentFundLimits, fund)
}

export const validateInvestmentFundAllocation = (displayProductQuery: DisplayProductQuery) => async (
  fund: InvestmentFundAllocation
): Promise<Checked<InvestmentFundAllocation>> => _validateInvestmentFundAllocation(displayProductQuery)(fund)
