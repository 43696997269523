// @flow
import _ from 'lodash'
import { flow, filter, map, keyBy, replace, toUpper } from 'lodash/fp'
import Mustache from 'mustache'
import BIMESSAGES from 'core/data-model/constants/bi-messages'
import type { RiderBenefitState, RiderBenefitData } from 'core/data-model/rider/benefits'
import type { Rider, RiderState } from 'core/data-model/rider'

//Move to constant config file
const FLEXI_DIAMOND_PLATINUM = ['DIAMOND', 'PLATINUM']
const _groupOfModuleOne = ['GLAS1', 'GLTH1', 'SLTH1', 'SMTH1', 'SKTH1', 'GKTH1']
const _groupOfModuleTwo = ['GLAS2', 'GLTH2', 'SLTH2', 'SMTH2', 'SKTH2', 'GKTH2']

export const isFlexiHealthModuleGroupOne = (code: string) => _.includes(_groupOfModuleOne, code)
export const isFlexiHealthModuleGroupTwo = (code: string) => _.includes(_groupOfModuleTwo, code)

const getCodeNumberFromSelectedModules = (modules) => {
  const typeModules = flow(
    filter((m) => m.isSelected),
    map((m) => {
      const typeModule = isFlexiHealthModuleGroupOne(m.code) ? 1 : isFlexiHealthModuleGroupTwo(m.code) ? 2 : 0
      return { typeModule }
    }),
    keyBy('typeModule')
  )(modules)

  return `1${_.get(typeModules, '1') ? 1 : 0}${_.get(typeModules, '2') ? 1 : 0}`
}
const _getTemplateCode = (plan, rider) => {
  const selectedModules = _.filter(_.get(rider, 'selectedPlan.modules'), (m) => m.isSelected)
  const code = getCodeNumberFromSelectedModules(selectedModules)
  if (_.includes(FLEXI_DIAMOND_PLATINUM, plan)) {
    return 'FLEXI_DIAMOND_PLATINUM'
  }

  switch (code) {
    case '111':
      return 'FLEXI_OTHER_ALL'
    case '110':
      return 'FLEXI_OTHER_MODULE1'
    case '101':
      return 'FLEXI_OTHER_MODULE2'
    default:
      return 'FLEXI_OTHER'
  }
}

const _mapPlanToTemplateKey = flow(toUpper, replace(' ', ''))

export const generateRiderBenefitDataForFH = ({
  selectedRiders,
  riderBenefitData,
}: {
  selectedRiders: (Rider & RiderState)[],
  riderBenefitData: RiderBenefitData,
}): RiderBenefitState => {
  const rider = _.find(selectedRiders, { code: 'FH' })
  const plan = _mapPlanToTemplateKey(_.get(rider, 'selectedPlan.plan'))
  const areaOfCover = _.get(rider, 'selectedPlan.areaOfCover')
  const areaOfCoverName = _.get(rider, `areasOfCover.${areaOfCover}.text`)
  const templateCode = _getTemplateCode(plan, rider)
  const benefitList = _.get(riderBenefitData, 'benefitList')
  const benefitVariables = { ..._.get(riderBenefitData, `benefitVariables.${plan}`), areaOfCoverName }
  const benefitContents = _.find(benefitList, { code: templateCode })

  const benefitsList = _.get(benefitContents, 'values', []).map((val) => {
    return {
      description: Mustache.render(_.get(val, '0'), benefitVariables),
      value: Mustache.render(_.get(val, '1'), benefitVariables),
    }
  })

  const noteList = _.get(benefitContents, 'notes', []).map((val) => Mustache.render(val, benefitVariables))

  return {
    riderBenefitData: {
      benefits: benefitsList,
      notes: noteList,
    },
  }
}

// $$FlowFixMe
export const translatePlanToThai = (plan) => {
  return _.get(BIMESSAGES, `FLEXI_HEALTH_PLAN_THAI.${_.lowerCase(plan).replace(' ', '')}`)
}
