// @flow

import _ from 'lodash'
import riderMessages from 'core/data-model/constants/validation-messages'
import { getToggles } from 'quick-quote/feature-toggles'
import type { OccupationRule } from 'core/data-model/rider/rules'
import type { RiderEligibilities } from 'core/data-model/rider'
import type { ValidationMessage } from 'core/data-model/validation'

export const checkOccupationRule = (rule: OccupationRule, eligibilities: RiderEligibilities): ValidationMessage => {
  const isMatchingRuleOccupationCode = (occupation) => occupation && occupation.occupationCode === rule.occupationCode

  const { occupation, otherOccupation } = eligibilities.insured

  let occupations = [occupation]

  if (getToggles().VALIDATE_OTHER_OCCUPATION) {
    occupations.push(otherOccupation)
  }

  const hasInvalidOccupation = _.some(occupations, isMatchingRuleOccupationCode)

  const errorMessage = hasInvalidOccupation ? riderMessages.RULE_RIDER_OCCUPATION : ''

  return {
    type: rule.validationType,
    message: errorMessage,
  }
}
