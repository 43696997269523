// @flow

import _ from 'lodash'
import { STANDARD_OCCUPATION } from 'core/service/insured'
import { getToggles } from 'quick-quote/feature-toggles'
import type { DiscountRate } from 'core/data-model/basic-plan'

export const getDiscount = (discountRates: DiscountRate[], sumAssured: number) => {
  const discountRate = _.find(discountRates, (discountRate) => isMatchDiscountRange(sumAssured, discountRate))
  if (!discountRate) return 0
  return discountRate.discount
}

const isMatchDiscountRange = (sumAssured, discountRate) => {
  return (
    sumAssured >= discountRate.minSumAssured && (sumAssured < discountRate.maxSumAssured || !discountRate.maxSumAssured)
  )
}

export type HasDiscountProps = {
  occupationFactor: number,
  discount: number,
}

export const getDiscountByOccupationFactor = ({ occupationFactor, discount }: HasDiscountProps): number => {
  return getToggles().ENABLE_FIX_OCCUPATION_DISCOUNT
    ? discount
    : occupationFactor === STANDARD_OCCUPATION
    ? discount
    : 0
}
