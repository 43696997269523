//@flow
import type { DisplayProduct } from 'core/service/display-product'
import type { PolicyValue } from 'core/service/benefit-illustration'
import _ from 'lodash'
import { formatNumber } from 'core/service/lib/number-format'
import { getWholeLife25PLBenefitData } from 'core/service/basic-plan/benefit'
import Mustache from 'mustache'
import MESSAGES from 'core/data-model/constants/bi-messages'
import CONSTANTS from 'core/data-model/constants/messages'

type WholeLife25PLBenefitProps = {
  displayProduct: DisplayProduct,
  policyValue: PolicyValue,
  sumAssured: number,
}

const WholeLife25PLBenefits = ({ displayProduct, policyValue, sumAssured }: WholeLife25PLBenefitProps) => {
  const benefitData = getWholeLife25PLBenefitData()
  return (
    <div id="product-benefits-whole-life-25pl">
      <p>
        <b>{benefitData.basicPlanBenefits}</b>
      </p>
      <ol>
        <li>
          {Mustache.render(benefitData.paymentAndBenefits, {
            paymentPeriod: displayProduct.paymentPeriod.value,
            coveragePeriod: displayProduct.coveragePeriod.value,
          })}
        </li>
        <li>{benefitData.coverageBenefits}</li>
        <li>
          {Mustache.render(benefitData.dividendBenefits, {
            coveragePeriod: displayProduct.coveragePeriod.value,
          })}
        </li>
        <li>
          {Mustache.render(benefitData.maturityBenefits, {
            coveragePeriod: displayProduct.coveragePeriod.value,
          })}
        </li>
      </ol>

      <p>
        <b>{MESSAGES.BASIC_PLAN_BENEFITS}</b>
      </p>
      <ol>
        <li>
          {benefitData.summaryMaturityBenefits}
          <ol className="detail-counter">
            <li>
              <div className="row">
                <div className="col col-7 col-lg-9">{benefitData.summaryMaturityBenefitsDetail1}</div>
                <div className="col col-5 col-lg-3">
                  {formatNumber(_.get(policyValue, 'subtotalCashDrop') - sumAssured, 0, true)} {CONSTANTS.BAHT}
                </div>
              </div>
            </li>
            <li>
              <div className="row">
                <div className="col col-7 col-lg-9">{benefitData.summaryMaturityBenefitsDetail2}</div>
                <div className="col col-5 col-lg-3">
                  {formatNumber(sumAssured)} {CONSTANTS.BAHT}
                </div>
              </div>
            </li>
          </ol>
        </li>

        <li>
          <div className="row">
            <div className="col col-7 col-lg-9">{benefitData.loanBenefits}</div>
            <div className="col col-5 col-lg-3"></div>
          </div>
        </li>
      </ol>

      <p>
        <b>{benefitData.summaryBasicPlanBenefits}</b>
      </p>
      <ol>
        <li>
          <div className="row">
            <div className="col col-7 col-lg-9">{benefitData.installmentRefundBenefits}</div>
            <div className="col col-5 col-lg-3">
              {formatNumber(_.get(policyValue, 'subtotalCashDrop'), 0, true)} {CONSTANTS.BAHT}
            </div>
          </div>
        </li>

        <li>
          {benefitData.singleRefundBenefits}
          <div className="sub-total">
            <div className="row">
              <div className="col col-7 col-lg-9">- {benefitData.minimumBenefits}</div>
              <div className="col col-5 col-lg-3">
                {formatNumber(_.get(policyValue, 'subtotalLowSavingCashDrop'), 0, true)} {CONSTANTS.BAHT}
              </div>
            </div>
            <div className="row">
              <div className="col col-7 col-lg-9">- {benefitData.mediumBenefits}</div>
              <div className="col col-5 col-lg-3">
                {formatNumber(_.get(policyValue, 'subtotalMediumSavingCashDrop'), 0, true)} {CONSTANTS.BAHT}
              </div>
            </div>
            <div className="row">
              <div className="col col-7 col-lg-9">- {benefitData.maximumBenefits}</div>
              <div className="col col-5 col-lg-3">
                {formatNumber(_.get(policyValue, 'subtotalHighSavingCashDrop'), 0, true)} {CONSTANTS.BAHT}
              </div>
            </div>
          </div>
        </li>
      </ol>

      <p>
        <b>{benefitData.beneficiaryBenefits}</b>
      </p>
      <div className="benefit-ciary">
        <div className="row">
          <div className="col col-7 col-lg-9">{benefitData.minimumAmountReceivedByBeneficiariesFor25PL}</div>
          <div className="col col-5 col-lg-3">
            {formatNumber(sumAssured)} {CONSTANTS.BAHT}
          </div>
        </div>
      </div>
    </div>
  )
}

export default WholeLife25PLBenefits
