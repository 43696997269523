import ACTIONS from './action-types'

export const resetApplicationFetchUpdate = () => ({
  type: ACTIONS.RESET_APPLICATION_FETCH_UPDATE,
})

export const applicationFetchUpdate = () => ({
  type: ACTIONS.APPLICATION_FETCH_UPDATE,
})

export const applicationFetchUpdateSuccess = () => ({
  type: ACTIONS.APPLICATION_FETCH_UPDATE_SUCCESS,
})

export const applicationFetchUpdateError = (error) => ({
  type: ACTIONS.APPLICATION_FETCH_UPDATE_ERROR,
  payload: { error },
})

export const applicationInstallUpdate = () => ({
  type: ACTIONS.APPLICATION_INSTALL_UPDATE,
})

export const applicationInstallUpdateSuccess = () => ({
  type: ACTIONS.APPLICATION_INSTALL_UPDATE_SUCCESS,
})

export const applicationInstallUpdateError = (error) => ({
  type: ACTIONS.APPLICATION_INSTALL_UPDATE_ERROR,
  payload: { error },
})

export const applicationCheckVersion = () => ({
  type: ACTIONS.APPLICATION_CHECK_VERSION,
})

export const applicationCheckVersionValidVersion = () => ({
  type: ACTIONS.APPLICATION_CHECK_VERSION_VALIDVERSION,
})

export const applicationCheckVersionInvalidVersion = (error) => ({
  type: ACTIONS.APPLICATION_CHECK_VERSION_INVALIDVERSION,
  payload: { error },
})
