import words from './breaking-words.json'

/**
 * This module is about to adding new line (\n) to word generated to PDF (pdfmake)
 * Not for the web/frontend
 */
const formatLineBreak = (input = '') => {
  const conditions = new RegExp(words.join('|'), 'gi')
  return input ? input.replace(conditions, (word) => `\n${word}`) : input
}

export default formatLineBreak
