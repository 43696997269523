// @flow
import MESSAGES from 'core/data-model/constants/bi-messages'
import VALUES from 'core/data-model/constants/values'
import {
  getWholeLife25PLBenefitData,
  getIProtectBenefitData,
  getIShieldBenefitData,
  getLifeLegacyBenefitData,
  getLifeProtectBenefitData,
  getLifeEnsureBenefitData,
  getIRetireBenefitData,
  getLifeRetireBenefitData,
  getS7BenefitData,
} from 'core/service/basic-plan/benefit'

import { formatNumber } from 'core/service/lib/number-format'
import { untilAge } from 'core/service/pdf-generation/products/common/logic'
import { lineBreak } from 'core/service/pdf-generation/utils/builder'
import { addUnderlineDecorator } from 'core/service/pdf-generation/utils/decorator-utils'
import Mustache from 'mustache'
import type { DisplayProduct } from 'core/service/display-product'
import type { PolicyValue } from 'core/service/benefit-illustration/policy-value'
import type { AdditionalBenefit, AdditionalBenefitDetails } from 'core/data-model/basic-plan'

export const buildIProtectBenefitsSection = (basicPlan: DisplayProduct, sumAssured: number) => {
  let benefitData = getIProtectBenefitData()
  return [
    {
      text: MESSAGES.BASIC_PLAN_BENEFITS,
      style: 'sub-title',
    },
    {
      ol: [
        {
          text: Mustache.render(benefitData.paidAndCoveragePeriod, {
            untilAge: untilAge(basicPlan.paymentPeriod),
            paymentPeriod: basicPlan.paymentPeriod.value,
            coveragePeriod: basicPlan.coveragePeriod.value,
          }),
        },
        {
          text: benefitData.coverageAmountDetails,
        },
        {
          text: Mustache.render(benefitData.maturityDetails, {
            coveragePeriod: basicPlan.coveragePeriod.value,
          }),
        },
      ],
      style: 'textRow',
    },
    {
      text: benefitData.beneficiaryBenefits,
      style: 'sub-title',
    },
    {
      stack: [
        {
          text: Mustache.render(benefitData.minimumAmountReceivedByBeneficiaries, {
            sumAssured: formatNumber(sumAssured),
          }),
        },
        {
          text: benefitData.coverageDetails,
        },
      ],
      style: 'textRow',
    },
  ]
}

export const buildLifeProtectBenefitsSection = (basicPlan: DisplayProduct, sumAssured: number) => {
  let benefitData = getLifeProtectBenefitData()
  return [
    {
      text: MESSAGES.BASIC_PLAN_BENEFITS,
      style: 'sub-title',
    },
    {
      ol: [
        {
          text: Mustache.render(benefitData.paidAndCoveragePeriod, {
            untilAge: untilAge(basicPlan.paymentPeriod),
            paymentPeriod: basicPlan.paymentPeriod.value,
            coveragePeriod: basicPlan.coveragePeriod.value,
          }),
        },
        {
          text: benefitData.coverageAmountDetails,
        },
        {
          text: Mustache.render(benefitData.maturityDetails, {
            coveragePeriod: basicPlan.coveragePeriod.value,
          }),
        },
      ],
      style: 'textRow',
    },
    {
      text: benefitData.beneficiaryBenefits,
      style: 'sub-title',
    },
    {
      stack: [
        {
          text: Mustache.render(benefitData.minimumAmountReceivedByBeneficiaries, {
            sumAssured: formatNumber(sumAssured),
          }),
        },
        {
          text: benefitData.coverageDetails,
        },
      ],
      style: 'textRow',
    },
  ]
}

export const buildLifeEnsureBenefitsSection = (basicPlan: DisplayProduct) => {
  let benefitData = getLifeEnsureBenefitData()
  return [
    {
      text: MESSAGES.BASIC_PLAN_BENEFITS,
      style: 'sub-title',
    },
    {
      ol: [
        {
          text: Mustache.render(benefitData.paidAndCoveragePeriod, {
            untilAge: untilAge(basicPlan.paymentPeriod),
            paymentPeriod: basicPlan.paymentPeriod.value,
            coveragePeriod: basicPlan.coveragePeriod.value,
          }),
        },
        {
          text: benefitData.coverageAmountDetails,
        },
        {
          text: Mustache.render(benefitData.maturityDetails, {
            coveragePeriod: basicPlan.coveragePeriod.value,
          }),
        },
      ],
      style: 'textRow',
    },
    {
      text: benefitData.beneficiaryBenefits,
      style: 'sub-title',
    },
    {
      stack: [
        {
          text: benefitData.minimumAmountReceivedByBeneficiaries,
        },
        {
          text: benefitData.coverageDetails,
        },
      ],
      style: 'textRow',
    },
  ]
}

export const buildRetirementBenefitsSection = (basicPlan: DisplayProduct, sumAssured: number) => {
  const benefitData =
    basicPlan.basicPlanCode === VALUES.LIFE_RETIRE_5 ? getLifeRetireBenefitData() : getIRetireBenefitData()
  return [
    {
      text: MESSAGES.BASIC_PLAN_BENEFITS,
      style: 'sub-title',
    },
    {
      ol: [
        {
          text: benefitData.coverageAmountDetails,
        },
        {
          text: benefitData.pensionCoveragePeriod,
        },
        {
          text: benefitData.deathBenefit,
        },
      ],
      style: 'textRow',
    },
  ]
}

type BenefitValues = {
  totalCashDrop: string,
  sumAssured: string,
  subtotalCashDrop: string,
  lowSavingCashDrop: string,
  mediumSavingCashDrop: string,
  highSavingCashDrop: string,
  deathBenefit: string,
}

const cashDropBenefitDetailTable = (details: AdditionalBenefitDetails[], benefitValue: BenefitValues) => {
  return {
    table: {
      widths: ['85%', '*'],
      body: details.map((detail) => [
        { text: detail.description },
        {
          text: Mustache.render(detail.benefit, benefitValue),
          alignment: 'right',
        },
      ]),
    },
    layout: 'noBorders',
  }
}

export const wholeLife25PLBenefitDetails = (
  additional: AdditionalBenefit[],
  benefitValues: BenefitValues
): Object[] => {
  const additinalDetails = additional.reduce(
    (accumulator, current) => [
      ...accumulator,
      {
        style: 'sub-title',
        text: current.title,
      },
      cashDropBenefitDetailTable(current.details, benefitValues),
      lineBreak(),
    ],
    []
  )

  return [{ text: '', pageBreak: 'before' }, ...additinalDetails]
}

export const buildBenefitValues = (
  sumAssured: number,
  {
    subtotalCashDrop = 0,
    subtotalLowSavingCashDrop = 0,
    subtotalMediumSavingCashDrop = 0,
    subtotalHighSavingCashDrop = 0,
  }: PolicyValue
): BenefitValues => {
  return {
    totalCashDrop: formatNumber(subtotalCashDrop - sumAssured),
    sumAssured: formatNumber(sumAssured),
    subtotalCashDrop: formatNumber(subtotalCashDrop),
    lowSavingCashDrop: formatNumber(subtotalLowSavingCashDrop),
    mediumSavingCashDrop: formatNumber(subtotalMediumSavingCashDrop),
    highSavingCashDrop: formatNumber(subtotalHighSavingCashDrop),
    deathBenefit: formatNumber(sumAssured),
  }
}

export const buildWholeLife25PLBenefitsSection = (
  basicPlan: DisplayProduct,
  sumAssured: number,
  policyValue: PolicyValue
): Object[] => {
  const benefitData = getWholeLife25PLBenefitData()

  const benefitValues = buildBenefitValues(sumAssured, policyValue)
  const benefitDetails = wholeLife25PLBenefitDetails(benefitData.additional, benefitValues)

  return [
    {
      text: MESSAGES.BASIC_PLAN_COVERAGE_BENEFITS,
      style: 'sub-title',
    },
    {
      ol: [
        {
          text: Mustache.render(benefitData.paidAndCoveragePeriod, {
            untilAge: untilAge(basicPlan.paymentPeriod),
            paymentPeriod: basicPlan.paymentPeriod.value,
            coveragePeriod: basicPlan.coveragePeriod.value,
          }),
        },
        {
          text: benefitData.coverageBenefits,
        },
        {
          text: Mustache.render(benefitData.dividendBenefits, {
            coveragePeriod: basicPlan.coveragePeriod.value,
          }),
        },
        {
          text: Mustache.render(benefitData.maturityBenefits, {
            coveragePeriod: basicPlan.coveragePeriod.value,
          }),
        },
      ],
    },
    ...benefitDetails,
  ]
}

export const buildWholeLife12PLBenefitsSection = (
  basicPlan: DisplayProduct,
  sumAssured: number,
  policyValue: PolicyValue
): Object[] => {
  return []
}

const majorStageCriticalIllness = (benefitData, sumAssured) => [
  {
    text: Mustache.render(benefitData.majorStageCriticalIllness, {
      sumAssured: formatNumber(sumAssured),
    }),
    style: 'sub-title',
  },
  {
    columns: [
      {
        ol: benefitData.diseases.slice(20, 45),
        width: 255,
      },
      {
        start: 26,
        ol: benefitData.diseases.slice(45, 70),
        width: '*',
      },
    ],
    columnGap: 15,
    margin: [5, 0, 0, 0],
  },
]

export const renderSumssuredDisclaimer = (sumAssuredDisclaimer: string) => {
  return addUnderlineDecorator(sumAssuredDisclaimer)
}

export const renderClaimForm = (cliamFormHeader: string, claimFormList: string[]) => {
  return {
    ol: [
      {
        ol: claimFormList.map((condition) => ({ text: condition })),
      },
    ],
  }
}

export const buildIShieldBenefitsSection = (basicPlan: DisplayProduct, sumAssured: number) => {
  let benefitData = getIShieldBenefitData()
  let earlyStageCriticalIllnessBenefit = sumAssured * benefitData.earlyStageCriticalIllnessBenefitFactor

  return [
    { text: benefitData.deathBenefits, style: 'second-title' },
    addUnderlineDecorator(benefitData.deathBenefitsDescription),
    { text: benefitData.maturityBenefits, style: 'second-title' },
    addUnderlineDecorator(benefitData.maturityBenefitsDescription),
    { text: benefitData.criticalIllnessBenefits, style: 'second-title' },
    benefitData.criticalIllnessBenefitsDescription,
    lineBreak(),
    {
      text: Mustache.render(benefitData.earlyStageCriticalIllness, {
        earlyStageCriticalIllnessBenefit: formatNumber(earlyStageCriticalIllnessBenefit, 2),
      }),
      style: 'sub-title',
    },
    {
      columns: [
        { ol: benefitData.diseases.slice(0, 10), width: 255 },
        { start: 11, ol: benefitData.diseases.slice(10, 20), width: '*' },
      ],
      columnGap: 15,
      margin: [5, 0, 0, 0],
    },
    {
      ul: benefitData.benefitDisclaimers.map((x) => addUnderlineDecorator(x)),
      margin: [0, 8, 0, 8],
    },
    lineBreak(),
    ...majorStageCriticalIllness(benefitData, sumAssured),
    lineBreak(),
    {
      ul: [renderSumssuredDisclaimer(benefitData.sumAssuredDisclaimer)],
    },
    {
      pageBreak: 'before',
      style: 'sub-title',
      text: benefitData.importantFacts,
    },
    {
      ul: [
        benefitData.waitingPeriod,
        benefitData.incorrectInformation,
        benefitData.cliamFormHeader,
        renderClaimForm(benefitData.cliamFormHeader, benefitData.claimFormList),
        benefitData.earlyBenefitPayment,
        benefitData.policyChangeCondition,
      ],
    },
    lineBreak(),
    { text: benefitData.majorExemption, style: 'sub-title' },
    { text: benefitData.reasonsForNoCoverage },
    { ol: benefitData.majorExemptionsPDF },
    {
      text: benefitData.disclaimerMajorExemption,
      style: 'disclaimer',
      marginTop: 3,
    },
  ]
}

export const buildS7BenefitsSection = (basicPlan: DisplayProduct, sumAssured: number) => {
  let benefitData = getS7BenefitData()
  const majorStageFactor = benefitData.majorStageFactor[basicPlan.basicPlanCode]
  const majorStageSumassured = (sumAssured * majorStageFactor) / 100

  return [
    {
      style: 'sub-title',
      text: Mustache.render(benefitData.majorStageCriticalIllnessTitle, {
        majorStageSumassured: formatNumber(majorStageSumassured),
      }),
    },
    {
      text: Mustache.render(benefitData.majorStageCriticalIllnessMaximumBenefit, {
        majorStageFactor: formatNumber(majorStageFactor),
      }),
    },
    {
      text: benefitData.majorStageCriticalIllnessTerminate,
    },
    {
      text: benefitData.majorStageCriticalIllnessTerminateSecondLine,
    },
    {
      text: benefitData.majorStageCriticalIllnessDiseasesCovered,
      bold: true,
      margin: [0, 8, 0, 8],
    },
    {
      columns: [
        { ol: benefitData.diseases.slice(0, 4), width: 255 },
        { start: 5, ol: benefitData.diseases.slice(4, 7), width: '*' },
      ],
      columnGap: 15,
      margin: [5, 0, 0, 0],
    },
    {
      text: benefitData.majorStageCriticalIllnessDiseasesCoveredRemark,
    },
    {
      text: benefitData.diseasesTitle,
      style: 'sub-title',
      marginTop: -6,
      pageBreak: 'before',
    },
    {
      text: benefitData.diseasesDesc,
    },
    {
      ol: [
        [
          {
            stack: [
              { text: benefitData.diseasesDetail[0].title, bold: true },
              {
                text: `                                                                             ${benefitData.diseasesDetail[0].desc}`,
                alignment: 'left',
                bold: false,
                preserveLeadingSpaces: true,
                marginTop: -16,
              },
            ],
          },
          { ol: benefitData.diseasesDetail[0].detail },
        ],
        [
          {
            stack: [
              { text: benefitData.diseasesDetail[1].title, bold: true },
              {
                text: `                                               ${benefitData.diseasesDetail[1].desc}`,
                alignment: 'left',
                bold: false,
                preserveLeadingSpaces: true,
                marginTop: -16,
              },
            ],
          },
          { ol: benefitData.diseasesDetail[1].detail },
        ],
        {
          stack: [
            { text: benefitData.diseasesDetail[2].title, bold: true },
            {
              text: `                                                          ${benefitData.diseasesDetail[2].desc}`,
              alignment: 'left',
              bold: false,
              preserveLeadingSpaces: true,
              marginTop: -16,
            },
            { text: benefitData.diseasesDetail[2].remark },
          ],
        },
        [
          {
            stack: [
              { text: benefitData.diseasesDetail[3].title, bold: true },
              {
                text: `                                           ${benefitData.diseasesDetail[3].desc}`,
                alignment: 'left',
                bold: false,
                preserveLeadingSpaces: true,
                marginTop: -16,
              },
            ],
          },
          { ol: benefitData.diseasesDetail[3].detail },
          { text: benefitData.diseasesDetail[3].remark },
        ],
        {
          stack: [
            { text: benefitData.diseasesDetail[4].title, bold: true },
            {
              text: `                                                                              ${benefitData.diseasesDetail[4].desc}`,
              alignment: 'left',
              bold: false,
              preserveLeadingSpaces: true,
              marginTop: -16,
            },
          ],
        },
        {
          stack: [
            { text: benefitData.diseasesDetail[5].title, bold: true },
            {
              text: `                                                                      ${benefitData.diseasesDetail[5].desc}`,
              alignment: 'left',
              bold: false,
              preserveLeadingSpaces: true,
              marginTop: -16,
            },
            { text: benefitData.diseasesDetail[5].remark },
          ],
        },
        [
          {
            stack: [
              { text: benefitData.diseasesDetail[6].title, bold: true },
              {
                text: `                  ${benefitData.diseasesDetail[6].desc}`,
                alignment: 'left',
                bold: false,
                preserveLeadingSpaces: true,
                marginTop: -16,
              },
            ],
          },
          { ol: benefitData.diseasesDetail[6].detail },
        ],
      ],
    },
    lineBreak(),
    {
      style: 'sub-title',
      text: benefitData.importantFacts,
    },
    {
      ul: [
        benefitData.waitingPeriod,
        benefitData.incorrectInformation,
        benefitData.cliamFormHeader,
        renderClaimForm(benefitData.cliamFormHeader, benefitData.claimFormList),
        benefitData.policyChangeCondition,
      ],
    },
    { text: benefitData.majorExemption, style: 'sub-title', pageBreak: 'before' },
    { text: benefitData.reasonsForNoCoverage },
    { ol: benefitData.majorExemptions.slice(0, 7) },
    { ol: benefitData.majorExemptions[7], marginLeft: 10 },
  ]
}

export const buildLifeLegacyProduct = () => {
  const benefitData = getLifeLegacyBenefitData()
  return [
    {
      text: benefitData.deathBenefits,
      style: 'sub-title',
    },
    {
      text: benefitData.deathBenefitsDescription,
      style: 'textRow',
    },
    {
      text: benefitData.maturityBenefits,
      style: 'sub-title',
    },
    {
      text: benefitData.maturityBenefitsDescription,
      style: 'textRow',
    },
  ]
}
