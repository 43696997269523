// @flow
import type { Effect } from 'redux-saga/effects'
import { put, select, takeLatest } from 'redux-saga/effects'
import { SELECT_HEALTH_TOPPING_PLAN, type SelectHealthToppingPlan } from './actions'
import { getSelectedDisplayProduct } from 'quick-quote/product-selection/selectors'

import {
  editRiderSumAssured,
  editRiderSelectedPlan,
  updateSelectedPlan,
} from 'quick-quote/product-common/coverage-plan/actions'

export const selectHealthToppingPlan = function*(action: SelectHealthToppingPlan): Generator<*, *, *> {
  const selectedPlanCode = action.payload
  const displayProduct = yield select(getSelectedDisplayProduct)
  const plans = displayProduct.plans

  const selectedPlan = plans.find((v) => v.planCode === selectedPlanCode)
  if (selectedPlan) {
    yield put(updateSelectedPlan(selectedPlanCode))
    const selectedPlan = plans.find((v) => v.planCode === selectedPlanCode)
    for (let rider of selectedPlan.riders) {
      yield put(editRiderSumAssured(rider.code, 0))
      yield put(editRiderSelectedPlan(rider.code, rider))
    }
  }
}

export function* watchers(): Generator<*, *, Effect[]> {
  yield [takeLatest([SELECT_HEALTH_TOPPING_PLAN], selectHealthToppingPlan)]
}

export const sagas = watchers
