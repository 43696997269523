// @flow
import { FormGroup } from 'reactstrap'
import type { Gender, GenderOption } from 'core/data-model/insured'
import { RadioButtonGroup } from 'common-components'

type Props = {
  payerRelationOptions: GenderOption[],
  payerRelation: Gender,
  selectGender: (string) => void,
}

const PayerRelationSelection = ({ payerRelationOptions, payerRelation, selectPayerRelation }: Props) => (
  <FormGroup id="payer-relation-selection" className="payer-selection">
    <RadioButtonGroup
      value={payerRelation}
      options={payerRelationOptions}
      onChange={selectPayerRelation}
      classNamePrefix="payer"
      autoClassName={true}
    />
  </FormGroup>
)

export default PayerRelationSelection
