import React from 'react'
import { connect } from 'react-redux'
import { compose, lifecycle, withHandlers, withStateHandlers } from 'recompose'
import styled from 'styled-components'
import { Flex } from 'rebass'
import _has from 'lodash/fp/has'
import _getOr from 'lodash/fp/getOr'

import Button from '../Button'
import EkycDetail from './EkycDetailCard'
import SubHeading from '../Heading/SubHeading'
import { getCurrentApp, getErrors } from '../../apps/selectors'
import SendLinkEkyc from 'e-submission/containers/Dialog/SendLinkEkyc'
import IdentifyEkycResponding from 'e-submission/containers/Dialog/IdentifyEkycResponding'
import DuplicateEkycCase from 'e-submission/containers/Dialog/DuplicateEkycCase'
import DopaRequestFailed from 'e-submission/containers/Dialog/DopaRequestFailed'
import EkycOnDevice from 'e-submission/containers/Dialog/EkycOnDevice'
import EkycPreviewInfo from 'e-submission/containers/Dialog/EkycPreviewInfo'
import { IsRealDevice } from 'e-submission/utils'
import { getToggles } from 'quick-quote/feature-toggles'

import {
  createCaseEkyc,
  createCaseEkycFromDevice,
  verifyEkyc,
  setDopaRequestFailedModal,
  getAttachment,
  setFrontIdCardImage,
  setBackIdCardLaserCode,
  setAppValue,
} from '../../apps/actions'
import {
  getInsuredDopaResult,
  getPayerDopaResult,
  getInsuredDopaRequestFailed,
  getPayerDopaRequestFailed,
  isOnDeviceEKYC,
  getCitizenId,
  isQuickQuoteBancAgent,
} from '../../domain/data-model/form/selectors'
import { getIcon } from '../Icon'
import EkycValidation from 'e-submission/containers/AppForm/FormValidation/ekycValidation'

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  .btn-link {
    padding: 0;
    display: flex;
    color: inherit;
    align-items: center;
    border: none;
    &:hover,
    &:active,
    &:focus {
      color: inherit;
      border: none;
      outline: none;
      box-shadow: none;
    }
  }
`

const SubHeadingModified = styled(SubHeading)`
  color: ${({ theme }) => theme.variables['$brand-primary']};
`

const Container = styled.div`
  background: white;
  padding: 15px 15px;
  border-radius: 6px;
  box-shadow: 0 4px 5px 3px rgb(0 0 0 / 8%);
  .btn-outline-primary {
    border-radius: 4px;
  }
`
const TextStatus = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`

const FlexModified = styled(Flex)`
  text-align: center;
`
const Footer = styled.div`
  display: flex;
  justify-content: center;
`

const EkycStatus = (detail) => {
  const icon = {
    success: 'ekycStatusSuccess',
    error: 'ekycStatusError',
    pending: 'ekycStatusPending',
  }

  const checkStatusDopa = () => {
    const { dopaResult } = detail
    if (dopaResult.dopaRequestFailed) {
      return 'pending'
    }
    if (dopaResult.verified && !dopaResult.dopaRequestFailed) {
      return 'success'
    }
    if (!dopaResult.verified && !dopaResult.dopaRequestFailed) {
      return 'error'
    }
    return ''
  }
  return (
    <Flex>
      <FlexModified column width={1 / 3} px={'20px'} py={'10px'}>
        <TextStatus>หน้าบัตรประชาชน</TextStatus>
        <div>
          {detail?.frontIdCardResult ? getIcon(icon[detail?.frontIdCardResult.verified ? 'success' : 'error']) : '-'}
        </div>
      </FlexModified>
      <FlexModified column width={1 / 3} px={'20px'} py={'10px'}>
        <TextStatus>หลังบัตรประชาชน</TextStatus>
        <div>
          {detail?.backIdCardResult ? getIcon(icon[detail?.backIdCardResult.verified ? 'success' : 'error']) : '-'}
        </div>
      </FlexModified>
      <FlexModified column width={1 / 3} px={'20px'} py={'10px'}>
        <TextStatus>DOPA</TextStatus>
        <div>{detail?.dopaResult ? getIcon(icon[checkStatusDopa()]) : '-'}</div>
      </FlexModified>
    </Flex>
  )
}

const textMapping = {
  insured: 'ผู้ขอเอาประกัน',
  payer: 'ผู้ชำระเบี้ยประกัน',
}

function findParentNodeTarget(domElement) {
  if (!domElement) return null
  if (domElement && domElement.className.match(/FormEngine/)) {
    return domElement
  } else {
    const parentDom = domElement.parentNode
    return findParentNodeTarget(parentDom)
  }
}

class _EkycDocument extends React.Component {
  constructor(props) {
    super(props)
    this.refRootComponent = null
    this.state = {
      isSuccessSubmit: false,
    }
    this.setRootComponentRef = (element) => {
      this.refRootComponent = element
    }
    this.successSubmitted = this.successSubmitted.bind(this)
  }

  successSubmitted() {
    this.setState((prevState) => {
      let isSuccessSubmit = !prevState.isSuccessSubmit
      return {
        isSuccessSubmit,
      }
    })
  }

  componentDidMount() {
    const formEngineDom = findParentNodeTarget(this.refRootComponent)
    if (formEngineDom) formEngineDom.style.maxWidth = '900px'
    this.props.createEkycOptionDefaultValueForAgent(this.props.isOnDeviceEkyc, this.props.isBancUser)
  }

  componentWillUnmount() {
    const formEngineDom = findParentNodeTarget(this.refRootComponent)
    if (formEngineDom) formEngineDom.style.maxWidth = '680px'
  }

  render() {
    const {
      insuredOrPayer,
      verificationDetail,
      isOpen,
      isOpenDuplicateModal,
      isOpenIdentifyRespondingModal,
      isOpenEkycOnDevice,
      isShowDetail,
      setOffSendLinkEkycModal,
      setOnSendLinkEkycModal,
      setOffEkycOnDeviceModal,
      setShowEkycDetail,
      isDuplicate,
      isReuseCase,
      handlerRequestEkyc,
      handleRequestEkycFromDevice,
      closeDuplicateModal,
      closeIdentifyRespondingModal,
      resend,
      hasError,
      cases,
      skipped,
      isOpenDopaRequestFailedModal,
      closeDopaRequestFailedModal,
      insuredDopaVerified,
      payerDopaVerified,
      insuredDopaRequestFailed,
      payerDopaRequestFailed,
      openIdentifyRespondingModal,
      isOnDeviceEkyc,
      citizenId,
      verifyStateOfDopa,
      isOpenEkycPreviewInfoModal,
      openEkycPreviewInfoModal,
      closeEkycPreviewInfoModal,
    } = this.props

    const isSkipped = skipped[insuredOrPayer]
    const isDopaVerified = verifyStateOfDopa[insuredOrPayer]
    // if (isOpenDuplicateModal) {
    // document.body.classList.add('modal-open')
    // }

    const isEnableEkycOnDevice =
      getToggles().ENABLE_EKYC_ONDEVICE && getToggles().ENABLE_EKYC_F2F && IsRealDevice && isOnDeviceEkyc

    const getFunctionEKYC = () => {
      return isEnableEkycOnDevice ? handleRequestEkycFromDevice(citizenId) : setOnSendLinkEkycModal()
    }

    return (
      <div ref={this.setRootComponentRef}>
        <EkycValidation />
        <Container>
          {isDuplicate && (
            <DuplicateEkycCase
              successSubmitted={this.successSubmitted}
              isEnableEkycOnDevice={isEnableEkycOnDevice}
              isOpen={isOpenDuplicateModal}
              onExit={closeDuplicateModal}
              duplicateCase={cases}
              insuredOrPayer={insuredOrPayer}
              openIdentifyRespondingModal={openIdentifyRespondingModal}
              closeIdentifyRespondingModal={closeIdentifyRespondingModal}
            />
          )}

          <IdentifyEkycResponding
            isDuplicate={isDuplicate}
            insuredOrPayer={insuredOrPayer}
            identifyEkycRespond={isReuseCase ? 'warning' : hasError ? 'failed' : 'success'}
            isReuseCase={isReuseCase}
            isOpen={isOpenIdentifyRespondingModal}
            onExit={closeIdentifyRespondingModal}
          />
          <DopaRequestFailed
            insuredDopaRequestFailed={insuredDopaRequestFailed}
            payerDopaRequestFailed={payerDopaRequestFailed}
            insuredDopaVerified={insuredDopaVerified}
            payerDopaVerified={payerDopaVerified}
            isOpen={isOpenDopaRequestFailedModal}
            onExit={closeDopaRequestFailedModal}
          />
          <SendLinkEkyc
            isOpen={isOpen}
            onExit={setOffSendLinkEkycModal}
            handlerCreateCaseEkyc={handlerRequestEkyc}
            insuredOrPayer={insuredOrPayer}
          />

          <EkycOnDevice
            isOpen={isOpenEkycOnDevice}
            onExit={setOffEkycOnDeviceModal}
            handlerCreateCaseEkyc={handlerRequestEkyc}
            insuredOrPayer={insuredOrPayer}
          />

          <EkycPreviewInfo
            isOpen={isOpenEkycPreviewInfoModal}
            onExit={closeEkycPreviewInfoModal}
            handlerCreateCaseEkyc={getFunctionEKYC}
            insuredOrPayer={insuredOrPayer}
          />

          <TitleContainer>
            <SubHeadingModified>{textMapping[insuredOrPayer]}</SubHeadingModified>
            {resend ? (
              <Button
                color="link"
                icon="redo"
                onClick={() => {
                  if (getToggles().ENABLE_EKYC_F2F_RECREATE_CASE) {
                    openEkycPreviewInfoModal()
                  } else {
                    getFunctionEKYC()
                  }
                }}
                disabled={isSkipped || isDopaVerified}
              >
                ส่งคำสั่งอีกครั้ง
              </Button>
            ) : (
              <Button
                onClick={() => {
                  if (getToggles().ENABLE_EKYC_F2F_RECREATE_CASE) {
                    openEkycPreviewInfoModal()
                  } else {
                    getFunctionEKYC()
                  }
                }}
                disabled={isSkipped}
              >
                ส่งคำสั่ง
              </Button>
            )}
          </TitleContainer>
          {EkycStatus(verificationDetail)}
          <Footer>
            {isShowDetail ? null : (
              <Button outline icon="arrowDown" onClick={() => setShowEkycDetail()}>
                แสดงข้อมูล
              </Button>
            )}
          </Footer>
          <EkycDetail
            setShowEkycDetail={setShowEkycDetail}
            detail={verificationDetail}
            isShowDetail={isShowDetail}
            insuredOrPayer={insuredOrPayer}
          />
        </Container>
      </div>
    )
  }
}

function getVerifyStateOfDopa(app, keys, insuredOrPayer) {
  const { ekycRootKey, verificationDetailKey, dopaResult } = keys
  return (
    _getOr(false, [ekycRootKey, insuredOrPayer, verificationDetailKey, dopaResult, 'verified'], app) ||
    _getOr(false, [ekycRootKey, insuredOrPayer, verificationDetailKey, dopaResult, 'dopaRequestFailed'], app)
  )
}

const mapStateToProps = (state, props) => {
  const app = getCurrentApp(state)
  const errors = getErrors(state)
  const ekycRootKey = 'ekycInfo'
  const insuredKey = 'insured'
  const payerKey = 'payer'
  const resendLinkKey = 'resendLink'
  const skippedKey = 'skipped'
  const verificationDetailKey = 'verificationDetail'
  const dopaResult = 'dopaResult'
  let cases = {}
  const insuredId = getCitizenId(insuredKey)(app)
  const payerId = getCitizenId(payerKey)(app)
  const resend = {
    insured: _getOr(false, [ekycRootKey, insuredKey, resendLinkKey], app),
    payer: _getOr(false, [ekycRootKey, payerKey, resendLinkKey], app),
  }
  const skipped = {
    insured: _getOr(false, [ekycRootKey, insuredKey, skippedKey], app),
    payer: _getOr(false, [ekycRootKey, payerKey, skippedKey], app),
  }
  const verificationDetail = _getOr({}, `ekycInfo.${props.insuredOrPayer}.${verificationDetailKey}`, app)
  const verifyStateOfDopa = {
    insured: getVerifyStateOfDopa(app, { ekycRootKey, verificationDetailKey, dopaResult }, insuredKey),
    payer: getVerifyStateOfDopa(app, { ekycRootKey, verificationDetailKey, dopaResult }, payerKey),
  }
  const isDuplicate = _has('EKYC_DUPLICATED_CITIZENID', errors)
  const isReuseCase = _has('EKYC_REUSE_CASE', errors)
  if (isDuplicate) {
    cases = errors['EKYC_DUPLICATED_CITIZENID'].cases
  }
  const hasError = _has('EKYC_VERIFY_CITIZENID', errors) || _has('EKYC_VERIFY_PROPRIETORID', errors)
  const isOpenDopaRequestFailedModal = _getOr(
    false,
    `ui.setDopaRequestFailedModal.${props.insuredOrPayer}.isOpenDopaRequestFailedModal`,
    state
  )
  const insuredDopaVerified = getInsuredDopaResult(app)
  const payerDopaVerified = getPayerDopaResult(app)
  const insuredDopaRequestFailed = getInsuredDopaRequestFailed(app)
  const payerDopaRequestFailed = getPayerDopaRequestFailed(app)

  const proprietorId = _getOr(null, `ekycInfo.${props.insuredOrPayer}.proprietorId`, app)
  const isOnDeviceEkyc = isOnDeviceEKYC(app)

  const citizenId = { insured: insuredId, payer: payerId }

  return {
    resend: resend[props.insuredOrPayer],
    verificationDetail,
    hasError,
    isDuplicate,
    isReuseCase,
    cases,
    skipped,
    isOpenDopaRequestFailedModal,
    insuredDopaVerified,
    payerDopaVerified,
    insuredDopaRequestFailed,
    payerDopaRequestFailed,
    proprietorId,
    isOnDeviceEkyc,
    citizenId: citizenId[props.insuredOrPayer],
    verifyStateOfDopa,
    isBancUser: isQuickQuoteBancAgent(app),
  }
}

const mapDispatchToProps = (
  dispatch: Dispatch<*>,
  {
    openModal,
    closeModal,
    openEkycOnDeviceModal,
    closeEkycOnDeviceModal,
    isOpenDuplicateModal,
    closeDuplicateModal,
    openDuplicateModal,
    setShowEkycDetail,
    closeEkycPreviewInfoModal,
    ...props
  }
) => ({
  setOffSendLinkEkycModal: () => closeModal(),
  setOnSendLinkEkycModal: () => openModal(),
  setOffEkycOnDeviceModal: () => closeEkycOnDeviceModal(),
  setOnEkycOnDeviceModal: function(p) {
    return openEkycOnDeviceModal(p)
  },
  setShowEkycDetail: () => setShowEkycDetail(),
  createCase: (citizenId) => dispatch(createCaseEkyc(props.insuredOrPayer, citizenId)),
  createCaseFromDevice: (citizenId, openRespondModalOnFailedCase) => {
    dispatch(createCaseEkycFromDevice(props.insuredOrPayer, citizenId, openRespondModalOnFailedCase))
  },
  verify: () => dispatch(verifyEkyc(props.insuredOrPayer)),
  closeDopaRequestFailedModal: () => dispatch(setDopaRequestFailedModal(false, props.insuredOrPayer)),
  getFronIdCard: () =>
    dispatch(
      getAttachment(props.insuredOrPayer === 'insured' ? 'insured-id-card' : 'owner-id-card', props.insuredOrPayer)
    ),
  clearFrontIdCardImage: () => dispatch(setFrontIdCardImage(null, props.insuredOrPayer)),
  clearBackIdCardLaserCode: () => dispatch(setBackIdCardLaserCode(null, props.insuredOrPayer)),
  createEkycOptionDefaultValueForAgent: (ekycInfoIsOnDeviceValue, isBancUser) => {
    if (!getToggles().ENABLE_EKYC_ONDEVICE) return

    if (!IsRealDevice) {
      dispatch(setAppValue('ekycInfo.isOnDevice.value', false))
    } else if (typeof ekycInfoIsOnDeviceValue !== 'boolean' || isBancUser) {
      dispatch(setAppValue('ekycInfo.isOnDevice.value', true))
    }
  },
})

export default compose(
  withStateHandlers(
    {
      isOpen: false,
      isOpenDuplicateModal: false,
      isOpenIdentifyRespondingModal: false,
      isShowDetail: false,
      isOpenEkycOnDevice: false,
      isOpenEkycPreviewInfoModal: false,
    },
    {
      openModal: (state) => () => ({ isOpen: true }),
      closeModal: (state) => () => ({ isOpen: false }),
      openEkycOnDeviceModal: (a) => function(b) {},
      closeEkycOnDeviceModal: (state) => () => ({ isOpenEkycOnDevice: false }),
      openDuplicateModal: (state) => () => ({ isOpenDuplicateModal: true }),
      closeDuplicateModal: (state) => () => ({ isOpenDuplicateModal: false }),
      openIdentifyRespondingModal: (state) => () => ({ isOpenIdentifyRespondingModal: true }),
      closeIdentifyRespondingModal: (state) => () => ({ isOpenIdentifyRespondingModal: false }),
      setShowEkycDetail: (state) => () => ({ isShowDetail: !state.isShowDetail }),
      openEkycPreviewInfoModal: (state) => () => ({ isOpenEkycPreviewInfoModal: true }),
      closeEkycPreviewInfoModal: (state) => () => ({ isOpenEkycPreviewInfoModal: false }),
    }
  ),
  connect(mapStateToProps, mapDispatchToProps),
  withHandlers({
    handlerRequestEkyc: ({ openDuplicateModal, createCase, openIdentifyRespondingModal, isDuplicate, hasError }) => (
      citizenId
    ) => {
      createCase(citizenId)
      openDuplicateModal()
      if (hasError || !isDuplicate) {
        openIdentifyRespondingModal()
      }
    },
    handleRequestEkycFromDevice: ({ createCaseFromDevice, openDuplicateModal, openIdentifyRespondingModal }) => (
      citizenId
    ) => {
      function openRespondModalOnFailedCase() {
        openIdentifyRespondingModal()
      }
      createCaseFromDevice(citizenId, openRespondModalOnFailedCase)
      openDuplicateModal()
    },
    handlerEkycAuth: ({ openEkycRequestFailedModal, ekycAuthError, insuredOrPayer }) => () => {
      if (ekycAuthError && insuredOrPayer !== 'payer') {
        openEkycRequestFailedModal()
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.clearFrontIdCardImage()
      this.props.clearBackIdCardLaserCode()
      this.props.verify()
      this.props.getFronIdCard()
    },
  })
)(_EkycDocument)
