// @flow
import type { RiderPlan } from 'core/data-model/rider'

import {
  calculateRiderPremiumWithVaryingSumAssuredAndDiscount as calculateRiderPremium,
  getPLRDiscount as getDiscount,
} from 'core/service/rider/premium-calculation'
import { queryPremiumRateForPLR as queryPremiumRate } from 'core/service/rider/query-premium-rate'
import { queryTaxRateForPLS as queryTaxRate } from 'core/service/rider/query-tax-rate'

const getYearsOfCoverage = (selectedPlan: RiderPlan) => {
  return selectedPlan.planCode ? Number(selectedPlan.planCode.substring(3)) : 0
}

export default {
  calculateRiderPremium,
  getYearsOfCoverage,
  queryPremiumRate,
  getDiscount,
  queryTaxRate,
}
