//@flow
import _ from 'lodash'
import { getMEKExceptionNotes } from 'core/service/rider/benefits/exception-notes'
import { buildHeaderTable } from 'core/service/pdf-generation/utils/builder'

import MESSAGES from 'core/data-model/constants/bi-messages'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { RiderBenefitData, Benefit } from 'core/data-model/rider/benefits'
import {
  getSelectedPlanBenefit,
  formatBenefitValue,
} from 'core/service/pdf-generation/products/common/sections/riders/common'

type MEHBenefitProps = {
  rider: Rider & RiderState,
  riderBenefitData: RiderBenefitData,
  sumAssured: number,
}

export const buildBenefitContent = (benefit: Benefit, selectedPlan: string) => {
  let result = []
  result.push(buildMEKBenefitsRow(getSelectedPlanBenefit(benefit, selectedPlan)))

  benefit.subBenefits.forEach((subBenefit) => {
    result.push(buildMEKSubBenefitsRow(getSelectedPlanBenefit(subBenefit, selectedPlan)))
  })
  return result
}

const buildMEKBenefitsRow = (benefit: Benefit) => {
  if (benefit.value === '') {
    return [{ colSpan: 2, text: benefit.description }, {}]
  }
  return [[benefit.description], { text: formatBenefitValue(benefit.value), alignment: 'right', bold: true }]
}

const buildMEKSubBenefitsRow = (benefit: Benefit) => {
  return [[benefit.description], { text: formatBenefitValue(benefit.value), alignment: 'right', bold: true }]
}

export const MEKBenefitSection = ({ rider, riderBenefitData, sumAssured }: MEHBenefitProps) => {
  const selectedPlan = _.get(rider, 'selectedPlan.planCode')
  const planHeader = getSelectedPlanBenefit(riderBenefitData.planheader, selectedPlan)
  const headers = [MESSAGES.BENEFIT_LIST, planHeader.value]
  const benefits = [].concat.apply(
    [],
    riderBenefitData.benefits.map((benefit) => buildBenefitContent(benefit, selectedPlan))
  )
  const exceptionNotes = buildExceptionNotes(rider)

  return [
    { text: ' ' },
    { text: ' ' },
    { text: ' ' },
    {
      table: {
        headerRows: 1,
        widths: [320, 'auto'],
        body: [
          buildHeaderTable(headers),
          ...riderBenefitData.sumassurebenefits.map((benefit) =>
            buildMEKBenefitsRow({ description: benefit.description, value: sumAssured })
          ),
          ...benefits,
        ],
      },
      layout: 'lightBorder',
      style: 'table',
    },

    { ...exceptionNotes },
  ]
}

const buildExceptionNotes = (rider) => {
  const exceptionNotes = getMEKExceptionNotes()
  const exceptionTitle = exceptionNotes.title
  const [
    normalCondition,
    firstException,
    secondException,
    extendExceptionTitle,
    extendExceptionDesc,
  ] = exceptionNotes.exceptions

  return {
    stack: [
      { text: ' ' },
      {
        text: `${exceptionTitle}`,
        style: 'sub-title',
      },
      {
        text: `${normalCondition}`,
        style: 'sub-title',
      },
      {
        ol: [
          firstException,
          {
            stack: [
              secondException[0],
              {
                columns: [
                  {
                    ul: secondException[1].slice(0, 4),
                    width: 255,
                  },
                  {
                    ul: secondException[1].slice(4, 8),
                    width: '*',
                  },
                ],
                columnGap: 15,
                margin: [5, 0, 0, 0],
              },
            ],
          },
        ],
        margin: [0, 0, 0, 30],
      },
      {
        text: `${extendExceptionTitle}`,
        style: 'sub-title',
      },
      {
        text: `${extendExceptionDesc[0]}`,
      },
      {
        ol: [...extendExceptionDesc[1]],
      },
      {
        text: exceptionNotes.condition,
        style: 'disclaimer',
        margin: [0, 5, 0, 0],
      },
    ],
  }
}
