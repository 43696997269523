// @flow

import type { InvestmentFundLimits } from 'core/service/investment'
import type { FundAllocation } from 'core/data-model/investment/types'

export type InvestmentFundAllocation = FundAllocation & {
  riskLevel: number,
  selected?: boolean,
  errors?: string[],
}

export const validateTotalAllocationRule = (
  investmentFundLimits: InvestmentFundLimits,
  totalFundAllocation: number
) => {
  return totalFundAllocation >= 0 && totalFundAllocation <= investmentFundLimits.maxTotalAllocation
}

export const validateMaxFundAllocationRule = (
  investmentFundLimits: InvestmentFundLimits,
  fund: InvestmentFundAllocation
) => {
  return fund.fundAllocation <= investmentFundLimits.maxTotalAllocation
}

export const validateMinFundAllocationRule = (
  investmentFundLimits: InvestmentFundLimits,
  fund: InvestmentFundAllocation
) => {
  return fund.fundAllocation >= investmentFundLimits.minFundAllocation
}
