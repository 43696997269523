//@flow
import _ from 'lodash'
import type { AgentType } from 'core/data-model/identity'
import type { Fund } from 'core/data-model/investment'
import { getInvestmentProductConfiguration } from 'core/data-model/investment'
import type { DisplayProductQuery } from 'core/service'
import type { InvestmentProductConfig } from 'core/data-model/investment/types'
import { getAppConfig } from 'deploy-env/app-config'
import { getToggles } from 'quick-quote/feature-toggles'
import { listInvestmentFunds } from './index'
import { overridingFundInfo } from './overriding'
import CONSTANTS from 'core/data-model/constants/values'

export const getSellableFunds = async (
  userAgentType: AgentType,
  displayProductQuery: DisplayProductQuery
): Promise<Fund[]> => {
  return _getSellableFunds(userAgentType, displayProductQuery, listInvestmentFunds, getVisibleFunds)
}

export const _getSellableFunds = async (
  userAgentType: AgentType,
  displayProductQuery: DisplayProductQuery,
  listRpulFundsFn: () => Promise<Fund[]>,
  getVisibleFundsFn: (DisplayProductQuery, Fund[]) => Promise<Fund[]>
): Promise<Fund[]> => {
  const allFunds = await listRpulFundsFn()
  const visibleFunds = await getVisibleFundsFn(displayProductQuery, allFunds)
  return filterByUserType(visibleFunds, userAgentType)
}

export const filterByUserType = (visibleFunds: Fund[], userAgentType: AgentType) =>
  _(visibleFunds)
    .filter({ allowUserType: [userAgentType] })
    .value()

//TODO [B] create function for checking wealth hub users
export const isWealthHub = (agentType: ?string): boolean => !!agentType && agentType === CONSTANTS.WEALTH_HUB

export const getVisibleFunds = (displayProductQuery: DisplayProductQuery, allFunds: Fund[]): Promise<Fund[]> => {
  return _getVisibleFunds(displayProductQuery, allFunds, getInvestmentProductConfiguration)
}

export const additionalFunds = ['KT-GOI-A', 'KT-MFB', 'KT-MAB', 'KT-MFE']
export const additional2020Q2Funds = ['HI-DIV PLUS', 'KT-EMEQ-A', 'TMBWDEQ']
export const additional2021Q1Funds = ['KT-CHINA-A']
export const removedFunds2021Q2 = ['KT-MFB', 'KT-MFB-A']
export const additional2022Q1Funds = ['KT-EPIC-A']
export const additional2023Q2Funds = ['KT-JAPANSM-A', 'UGIS-N']
export const additional2024Q1Lot1Funds = ['KT-GAEQ-A']
export const additional2024Q1Lot2Funds = ['KTWC-DEFENSIVE-A', 'KTWC-GROWTH-A', 'KTWC-MODERATE-A']

export const _getVisibleFunds = (
  displayProductQuery: DisplayProductQuery,
  allFunds: Fund[],
  _getInvestmentProductConfiguration: (DisplayProductQuery) => Promise<InvestmentProductConfig>
): Promise<Fund[]> => {
  return _getInvestmentProductConfiguration(displayProductQuery)
    .then((investmentProductCfg: InvestmentProductConfig) => {
      const investmentConfigVisibleFunds: string[] = investmentProductCfg.visibleFunds
      const isNot2023Q2Funds = (code) => !_.includes(additional2023Q2Funds, code)
      const isNot2024Q1Lot1Funds = (code) => !_.includes(additional2024Q1Lot1Funds, code)
      const isNot2024Q1Lot2Funds = (code) => !_.includes(additional2024Q1Lot2Funds, code)

      const isNotInRemoveList = (code) => {
        return !_.includes(removedFunds2021Q2, code)
      }
      const visibleFunds = allFunds
        .filter((f) => investmentConfigVisibleFunds.includes(f.code))
        .filter((f) => getToggles().SHOW_ADDITIONAL_2023Q2_FUNDS || isNot2023Q2Funds(f.code))
        .filter((f) => getToggles().SHOW_ADDITIONAL_2024Q1_LOT1_FUNDS || isNot2024Q1Lot1Funds(f.code))
        .filter((f) => getToggles().SHOW_ADDITIONAL_2024Q1_LOT2_FUNDS || isNot2024Q1Lot2Funds(f.code))
        .filter((f) => isNotInRemoveList(f.code))
        .filter((f) => _.includes(getAppConfig().FUNDS_AVAILABLE, f.code))

      const overrideFunds = allFunds.filter((f) => _.includes(getAppConfig().FUNDS_OVERRIDE, f.code))
      return overrideFunds.length ? overridingFundInfo(visibleFunds, overrideFunds) : visibleFunds
    })
    .catch((e) => {
      console.error(`error when getting visible funds from repository. e=${e}`)
      return []
    })
}

export const containOverRiskFund = (funds: Fund[]): boolean =>
  funds.some((fund: Fund) => {
    return fund.isOverRisk
  })

export const containForeignFund = (funds: Fund[]): boolean =>
  funds.some((fund: Fund) => {
    return fund.isForeign
  })

export const isAcceptForeignInvestment = (
  allowForeignInvestment: boolean,
  isSelectedYesOnExchangeRateQuestion: boolean
): boolean => {
  return isSelectedYesOnExchangeRateQuestion ? isSelectedYesOnExchangeRateQuestion : allowForeignInvestment === true
}

export const fundsContainErrors = (funds: Fund[]): boolean => {
  return funds.some((fund: Fund) => fund.errors.length > 0)
}
