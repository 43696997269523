//@flow

export default {
  GO_TO_POLICY_LIST: 'ดูใบคำขอเอาประกัน',
  GO_TO_QUICK_QUOTE: 'คำนวณเบี้ย',
  GO_TO_REVISE_QUICK_QUOTE: 'Quick Quote',
  GO_TO_NEED_AND_GAP_ANALYSIS: 'ค้นหาความต้องการ',
  GO_TO_ESUBMISSION: 'ดูใบคำขอเอาประกัน',
  GO_TO_REMOTE_SELLING: 'Remote Selling',
  GO_TO_OPTY: 'ผู้มุ่งหวัง',
  GO_TO_MW_PLUS: 'MyWealth+',
  GO_TO_I_PRO: 'iPro',
  GO_TO_PERFECT_ADVICE: 'Perfect Advice',
  GO_TO_MY_WEALTH: 'MyWealth+',
  GO_TO_PRIVILEGE: 'Privilege',
  GO_TO_ECBR: 'ใบรับเงินชั่วคราว',
  GO_TO_EKYC: 'eKYC Service',
  GO_TO_BROCHURE: 'ดาวน์โหลดโบรชัวร์',
  BAHT_PER_WEEK: 'บาท ต่อสัปดาห์',
  BAHT_PER_MONTH: 'บาท ต่อเดือน',
  BAHT: 'บาท',
  DATA_NOT_FOUND: 'ไม่พบข้อมูล',

  DOCUMENT_MAXIMUM_AGE_INFO: {
    DRAFTED: [
      'เอกสารระหว่างดำเนินการต้องถูกนำส่งภายใน <u>30 วัน</u> นับตั้งแต่วันที่ถูกสร้าง',
      'เอกสารจะถูก<u>ลบออกจากระบบ</u>เมื่อเกินระยะเวลาที่กำหนด',
    ],
    SUBMITTED: [
      'เอกสารที่นำส่งแล้วจะแสดงในแอพฯอีก <u>120 วัน</u> นับตั้งแต่วันที่ถูกสร้าง',
      'เอกสารจะ<u>ไม่แสดงในแอพฯ</u>เมื่อเกินระยะเวลาที่กำหนด',
    ],
  },

  // QQ LABEL
  TOTAL_PREMIUM_INPUT_LABEL: 'เบี้ยรวมสัญญาหลัก + สัญญาเพิ่มเติม',
  TOTAL_PREMIUM_INPUT_LABEL_WITHOUT_RIDER: 'เบี้ยรวมสัญญาหลัก',
  PREMIUM_INPUT_LABEL: 'เบี้ยประกันของคุณ',
  RIDER_GROUP_TITLE: 'สัญญาเพิ่มเติม',
  RIDER_SELECTED_PLAN: 'เลือกแผนความคุ้มครอง',
  BASIC_PLAN_GROUP_TITLE: 'เลือกแผนความคุ้มครอง',

  // from infomation branch selection
  INFORMATION_BRANCH_HEADER: 'การให้ความยินยอมในการเปิดเผยข้อมูล',
  INFORMATION_BRANCH_BODY:
    'ข้าพเจ้าตกลงยินยอมให้ บมจ.ธนาคารกรุงไทย (“ธนาคาร”) เปิดเผยข้อมูลส่วนบุคคลของข้าพเจ้า เช่น ชื่อ ช่องทางในการติดต่อ เพศ อายุ การศึกษา เป็นต้น แต่ทั้งนี้ไม่รวมถึงข้อมูลที่เกี่ยวกับบัญชีของข้าพเจ้า เช่น เลขที่บัญชี ยอดคงเหลือในบัญชี รายการเคลื่อนไหวในบัญชี เป็นต้น ให้แก่ บริษัทหรือนิติบุคคลนอกกลุ่มธุรกิจทางการเงินของธนาคารที่ธนาคารเห็นสมควร และให้บริษัทหรือนิติบุคคลนอกกลุ่มธุรกิจทางการเงินของธนาคารสามารถใช้ข้อมูลดังกล่าว เพื่อวัตถุประสงค์ทางการตลาด เช่น เพื่อวัตถุประสงค์ในการสำรวจ/วิจัยความคิดเห็นของลูกค้าเพื่อวัตถุประสงค์ทางการตลาด และการส่งเสริมการขายผลิตภัณฑ์ของธนาคาร หรือเพื่อนำมาปรับปรุงพัฒนาผลิตภัณฑ์หรือบริการของธนาคาร',
  INFORMATION_BRANCH_CANCEL_BUTTON: 'ยกเลิก',
  INFORMATION_BRANCH_SUBMIT_BUTTON: 'ลูกค้าตกลง',
  INFORMATION_BRANCH_CHECKED: '  ให้ความยินยอมกับธนาคาร',

  // from fse and branch selection
  SELECT_FSE_AND_BRANCH: 'กรุณายืนยันสาขาและผู้ร่วมงาน',
  FSE_CODE: 'รหัสเจ้าหน้าที่บริการทางการเงิน',
  FSE_CODE_HINT: 'แสดงผลอัตโนมัติเมื่อท่านเลือกสาขาที่ท่านดำเนินการได้ถูกต้อง',
  FSE_NAME: 'ชื่อเจ้าหน้าที่บริการทางการเงิน',
  SUBMIT: 'ยืนยัน',
  BRANCH_NAME: 'ชื่อสาขา',
  BRANCH_CODE: 'รหัสสาขา',
  BRANCH_CODE_HINT:
    'กรุณาระบุให้ครบ 6 หลัก (เช่น 200001) จากนั้นระบบจะแสดงชื่อสาขาใต้กล่องข้อความ ให้ท่านเลือกสาขาที่ท่านดำเนินการ',
  FSE_PLACEHOLDER: '000000 - กรุณากรอกรหัสผู้ร่วมงาน',
  BRANCH_PLACEHOLDER: '000000 - กรุณากรอกรหัสสาขา',
  RECOMMENDER_PLACEHOLDER: '000000 - กรุณากรอกรหัสนายหน้า',
  SEARCHING: 'กำลังค้นหา...',
  TYPE_TO_SEARCH: 'กรุณาพิมพ์เพื่อค้นหา',
  PLEASE_SELECT_FSE_BRANCH: 'กรุณาเลือกสาขาและผู้ร่วมงานเพื่อดำเนินการต่อ',

  // from insured-information
  LB_LICENSE_INFO: 'แสดงใบอนุญาตนายหน้าประกันภัยทุกครั้งที่มีการเสนอขายผลิตภัณฑ์ประกันภัย',
  BIRTHDATE_PLACEHOLDER: '(วว/ดด/ปปปป)',
  BIRTHDATE_LABEL: 'วันเกิด',
  TITLE: 'คำนำหน้าชื่อ',
  FIRST_NAME_WARNING: '(ไม่ต้องระบุคำนำหน้า)',
  FIRST_NAME: 'ชื่อ',
  LAST_NAME: 'นามสกุล',
  FIRST_NAME_DEFAULT: 'ลูกค้า',
  LAST_NAME_DEFAULT: 'คนพิเศษ',
  AGE_SELECT_LABEL: 'อายุ (กรณีอายุต่ำกว่า 1 ปี ระบุ 0)',

  MALE: 'ชาย',
  FEMALE: 'หญิง',
  AGE: 'อายุ',
  GENDER: 'เพศ',

  SELF: 'ตนเอง',
  DIRECTDEPENDENT: 'คู่สมรสหรือบุตร',
  SPOUSE: 'คู่สมรส',
  CHILD: 'บุตร',
  PARENT: 'ผู้ปกครอง',
  PAY_MY_SELF: 'ชำระเบี้ยด้วยตนเอง',

  PAYER_GENDER: 'เพศตามกำเนิดผู้ชำระเงิน',
  PAYER_BIRTHDATE: 'วันเกิดผู้ชำระเงิน',

  OCCUPATION: 'อาชีพ',
  CHOOSE_OCCUPATION: 'เลือกอาชีพ',
  FINISHED: 'เสร็จ',
  CANCEL: 'ยกเลิก',
  OK: 'ตกลง',
  NATURE_OF_DUTY: 'รายละเอียดของงาน',

  //from containers.js
  ESUBMISSION: 'ดำเนินการใบคำขอ',
  PROCEED_TO_FNA: 'ดำเนินการ FNA',
  CALCULATE_PREMIUM: 'คำนวณเบี้ย',
  BENEFIT_ILLUSTRATION: 'สรุปสัญญา',
  RISK_QUESTIONNAIRE: 'ประเมินความเสี่ยง',
  FUND: 'เลือกกองทุน',
  RPP_FUND: 'เลือกกองทุน RPP',
  LSTU_FUND: 'เลือกกองทุน LSTU',
  SHARE: 'แชร์',
  EDIT: 'แก้ไข',
  FUND_SELECTED: 'กองทุนที่ท่านเลือก',
  FUNDING_RATIO: 'สัดส่วนกองทุน',
  DOWNLOAD_SALE_PAPER: 'ดาวน์โหลดใบเสนอขาย',
  DOWNLOAD_RPQ_FORM: 'ดาวน์โหลดฟอร์ม RPQ',
  DOWNLOAD_RA_FORM: 'ดาวน์โหลดฟอร์ม RA',

  TIME_UNIT_YEAR: 'ปี',
  TIME_UNIT_MONTH: 'เดือน',
  TIME_UNIT_DAY: 'วัน',

  PAYER: 'ผู้ชำระเงิน',
  INSURED_PERSON: 'ผู้ขอเอาประกัน',
  MARKET_CONDUCTS: 'แบบสอบถาม',
  NEED_AND_GAP_ANALYSIS: 'ความต้องการของท่าน',
  PRODUCT_SELECTION: 'ผลิตภัณฑ์ที่ใช่',
  REASON_LABEL: 'เหตุผลที่เลือกผลิตภัณฑ์',
  REASON_PLACEHOLDER: 'เหตุผลที่เลือกผลิตภัณฑ์',
  MATURITY_VALIDATE: 'ตรวจสอบสิทธิ์',
  SELECT_POLICY_OWNER: 'เลือกผู้ขอเอาประกัน',

  PAYMENT_MODE: 'งวดการชำระเบี้ยประกันภัย',
  RPDUR_PAYMENT_MODE: 'งวดการชำระเบี้ย',

  //RPUL Coverage page
  COVERAGE_LABEL: 'เพื่อความคุ้มครองรายงวด (RPP)',
  SPUL_PREMIUM_LABEL: 'เบี้ยประกันภัย',
  COVERAGE_FUND_LABEL: 'เพื่อการลงทุนรายงวด (RTU)',
  COVERAGE_SUM_ASSURED: 'จำนวนเงินเอาประกันภัย',
  GROSS_PREMIUM: 'เบี้ยประกันภัยรวมรายงวด',
  GROSS_PREMIUM_AND_RIDER_PREMIUM: 'เบี้ยประกันภัยหลักเพื่อความคุ้มครอง รวมสัญญาเพิ่มเติม (ถ้ามี)',
  LSTU_PREMIUM: 'เบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษ (LSTU) ตอนออกกรมธรรม์',
  PAY_FIRST_MONTH: 'โดยชำระรายเดือนงวดแรก',
  FIRST_PAYMENT_TITLE: 'งวดแรก',
  ONE_TIME_PAYMENT_TITLE: 'ชำระครั้งเดียว',
  PREMIUM_PROPORTION: 'จัดสัดส่วนเบี้ยประกันภัย',

  COVERAGE_RPP: 'เบี้ยประกันภัยหลักเพื่อความคุ้มครอง สำหรับความคุ้มครองชีวิต',
  COVERAGE_LSTU: 'เบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษ (LSTU) ตอนออกกรมธรรม์',

  RPUL_RISK_QUESTIONNAIRE_PROMPT_LINE_01: 'กรุณาตอบแบบประเมินระดับความเสี่ยงในการลงทุน',
  RPUL_RISK_QUESTIONNAIRE_PROMPT_LINE_02:
    'เพื่อความรู้ความเข้าใจเกี่ยวกับระดับความเสี่ยงที่รับได้ของลูกค้า ก่อนเริ่มจัดสัดส่วนการลงทุน',
  FUNDS_ALLOCATION: {
    DISCLAIMER_MESSAGE: 'การลงทุนมีความเสี่ยง ผู้ลงทุนควรศึกษาข้อมูลก่อนการตัดสินใจลงทุน',
    INVESTMENT_ALLOCATION_MESSAGE: 'สัดส่วนการลงทุนรวม',
    EXPECTED_RETURN_MESSAGE: 'ผลตอบแทนที่คาดหวังจากพอร์ตลงทุน',
    RESET: 'เริ่มใหม่',
    YOUR_INVESTMENT_VALUE_RATIO_MESSAGE: 'สัดส่วนมูลค่าการลงทุนของท่่าน',
  },
  MONTHLY: 'เดือน',
  ANNUAL: 'ปี',
  SEMI_ANNUAL: 'หกเดือน',
  QUARTERLY: 'สามเดือน',
  LICENSE_WIFI: 'ยืนยันใบอนุญาต',

  //INVESTMENT PRODUCT SALE PERMISSION
  INVALID_LICENSES_ERROR_MSG: 'ใบอนุญาตของท่านไม่สามารถให้บริการขายผลิตภัณฑ์เพื่อการลงทุนได้ กรุณาติดต่อ 1159',
  INVALIED_LICENSES_INVESTMENT_ERROR_MSG:
    'ท่านไม่ได้รับการอบรมในการให้บริการขายผลิตภัณฑ์เพื่อการลงทุน กรุณาติดต่อ 1159',
  INACTIVE_USER_ERROR_MSG: 'สถานะบัญชีของท่านพ้นสภาพการใช้งาน กรุณาติดต่อ 1159',
  NON_VERIFY_USER_ERROR_MSG:
    'Facebook ของท่านยังไม่ได้ผูกบัญชีกับบริษัทกรุงไทย-แอกซ่า กรุณาเข้าสู่ระบบด้วยบัญชีผู้ใช้ IAM',
  INVALID_RIGHT_LICENSES_ERROR_MSG: 'ท่านยังไม่ได้รับสิทธิขายผลิตภัณฑ์นี้ กรุณาติดต่อ 1159',
  NOT_ALLOW_ESUBMISSION: {
    TITLE: 'พบข้อผิดพลาด',
    DESCRIPTION: 'คุณไม่มีสิทธิ์ดำเนินการขอใบคำขอ',
  },
  CASH_PAYMENT: 'ชำระด้วยเงินสด',
  LOAN_PAYMENT: 'รวมเบี้ยในวงเงินกู้',

  AGENT_DECLARATION_INVESTMENT_INFO_MESSAGE:
    'ตัวแทน/นายหน้า มีหน้าที่จะต้องอธิบายรายละเอียดเกี่ยวกับกองทุนให้กับลูกค้าทุกครั้ง',
  HIGHER_RISK_INVESTMENT_INFO_MESSAGE:
    'กรุณายอมรับความเสี่ยงในการเลือกซื้อกองทุนที่ความเสี่ยงสูงกว่าคะแนนความเสี่ยงที่คุณยอมรับได้',
  FOREIGN_INVESTMENT_INFO_MESSAGE: 'กรุณายอมรับความเสี่ยงในการเลือกซื้อกองทุนต่างประเทศ',
  HERE: 'ที่นี่',

  // iHealthy coverage page
  BASIC_PLAN_SELECTION_LABEL: '{{{basicPlanCode}}} (อายุรับประกันภัย {{entryAgeMinimum}}-{{entryAgeMaximum}} ปี)',

  // flexi-health
  FLEXI_HEALTH_BASIC_PLAN_SELECTION_LABEL:
    '{{basicPlanCode}} (อายุรับประกันภัย {{entryAgeMinimum}} เดือน - {{entryAgeMaximum}} ปี)',

  // fsa login blocking popup
  ONLY_ALLOW_FROM_PA: {
    TITLE: 'พบข้อผิดพลาด',
    DESCRIPTION: 'คุณไม่มีสิทธิ์ดำเนินการขอใบคำขอ โดยสามารถเข้าใช้งานผ่าน Perfect Advice เท่านั้น',
  },

  PENSION_TYPE_INFO_MESSAGE:
    'ผู้เอาประกันภัยสามารถเปลี่ยนรูปแบบการรับเงินบำนาญได้ ทั้งนี้จะต้องแจ้งเปลี่ยนก่อนการจ่ายเงินบำนาญงวดแรก',
  RETIREMENT_RIDER_INFO_MESSAGE: 'งวดการชำระเบี้ยประกันภัยของสัญญาเพิ่มเติมเป็น รายปี เท่านั้น',
  DCA_CONSENT_RECEIVE_BENEFITS: 'ยินยอมขอรับผลประโยชน์ผ่านบัญชีธนาคารกรุงไทย',
  ACCOUNT_NUMBER: 'เลขที่บัญชี',

  //FNA
  MARKET_CONDUCTS_TITLE: 'กรุณาตอบคำถามเกี่ยวกับข้อมูลการซื้อประกันชีวิต',
  RISK_QUESTION_TITLE: '',
  THIRD_PARTY_NAME: 'ชื่อ-นามสกุล บุคคลที่สาม',
  THIRD_PARTY_RELATIONSHIP: 'ความสัมพันธ์',
  THIRD_PARTY_TELEPHONE: 'เบอร์โทรศัพท์',
  REASON_OTHERS: 'กรุณาระบุเหตุผล (ถ้ามี)',
  THIRD_PARTY_TITLE: 'คำนำหน้า',
  THIRD_PARTY_TITLE_TEXT: 'ระบุคำนำหน้า',
  THIRD_PARTY_FIRST_NAME: 'ชื่อ',
  THIRD_PARTY_LAST_NAME: 'นามสกุล',
  RPP_SUMMARY_FUND_TITLE: 'การจัดสรรการลงทุนเบี้ยประกันภัยหลัก (RPP) ของท่าน',
  LSTU_SUMMARY_FUND_TITLE: 'การจัดสรรการลงทุนเบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษ (LSTU) ของท่าน',
  RPP_OVER_RISK_SUMMARY_TITLE: 'กองทุนที่มีระดับความเสี่ยงสูงกว่าระดับความเสี่ยงที่ท่านรับได้',
  RPP_FOREIGNER_RISK_SUMMARY_TITLE:
    'กองทุนที่ท่านเลือกมีการลงทุนในต่างประเทศซึ่งอาจไม่ได้มีการทำสัญญาป้องกันความเสี่ยงจากอัตราแลกเปลี่ยนหรือมีนโยบายป้องกันความเสี่ยงต่ำกว่าร้อยละ 90 ซึ่งไม่สอดคล้องกับความเสี่ยงที่ท่านรับได้',
  OVER_RISK_SUMMARY_TITLE:
    'ข้าพเจ้ารับทราบว่าหากกองทุนรวมที่ข้าพเจ้าประสงค์ลงทุนมีระดับความเสี่ยงที่สูงกว่าระดับความเสี่ยงที่ข้าพเจ้าลงทุนได้หรือเป็นการลงทุนที่ไม่เป็นไปตามคำแนะนำของบริษัท ข้าพเจ้าทราบระดับความเสี่ยงเป็นอย่างดีแล้ว และยืนยันจะลงทุนในกองทุนรวมข้างต้น',
  FOREIGNER_RISK_SUMMARY_TITLE:
    'ข้าพเจ้ารับทราบว่ากรณีที่กองทุนมีการลงทุนในต่างประเทศและไม่ได้ทำสัญญาป้องกันความเสี่ยงจากอัตราแลกเปลี่ยน หรือมีนโยบายป้องกันความเสี่ยงต่ำกว่าร้อยละ 90 กองทุนอาจมีความเสี่ยงจากอัตราแลกเปลี่ยน ซึ่งอาจทำให้ผู้ลงทุนได้รับเงินคืนต่ำกว่าเงินลงทุนแรกเริ่มได้',
  FUND_CHANGING_BUTTON_TEXT: 'ต้องการแก้ไขปรับเปลี่ยนกองทุน',

  //S7CC
  PLEASE_INPUT_CORRECT_FORMAT: 'กรุณากรอกข้อมูลให้ถูกต้อง',
  NOT_ALLOW_BUY_S7CC:
    'ขออภัยคุณไม่สามารถซื้อผลิตภัณฑ์นี้ได้ กรุณาเลือกผลิตภัณฑ์อื่นที่ท่านสนใจ บริษัทฯ ขอขอบพระคุณเป็นอย่างสูงที่ให้ความสนใจในผลิตภัณฑ์นี้',
  NOT_ALLOW_BUY_FOR_OWNER:
    'ขออภัยท่านไม่ผ่านเกณฑ์ในการซื้อผลิตภัณฑ์ Super 7 Critical Care ทั้งนี้ ท่านสามารถแนะนำผลิตภัณฑ์นี้ให้กับคู่สมรสหรือบุตรได้ บริษัทฯ ขอขอบพระคุณเป็นอย่างสูงที่ให้ความสนในในผลิตภัณฑ์นี้',
}
