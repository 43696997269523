import DEFAULT from 'core/data-model/constants/defaults'
import { buddhistToChristianDate } from 'core/service/lib/date'
import moment from 'moment'

export const getPredefineData = (currentData, insuredSessionId, payerSessionId, hasPayerSession, accessToken) => {
  const dateFormatISO = moment()
    .format()
    .substring(0, 10)
  const timeFormatISO = moment()
    .format()
    .substring(11, 25)

  const birthDateInsured = _.get(currentData, 'quickQuote.insured.birthdate.value', '')
  const birthDatePayer = _.get(currentData, 'quickQuote.insured.payer.birthdate.value', '')
  const productType = _.get(currentData, 'quickQuote.selectedDisplayProduct.type', '')

  const riders = _.get(currentData, 'quickQuote.biProps.riders', {})
  const product = _.get(currentData, 'quickQuote.biProps.basicPlan.aura.AURA_PRODUCT_CODE', '')
  const genderInsured = _.get(currentData, 'quickQuote.insured.gender', '')
  const genderPayer = _.get(currentData, 'quickQuote.insured.payer.gender', '')

  const productSelect = product.map((item) => {
    if (_.isNil(item)) return {}
    return {
      planName: _.get(currentData, 'quickQuote.biProps.basicPlan.basicPlanCode', ''),
      productCode: item,
      deathBenefitAmt:
        _.get(currentData, 'quickQuote.coveragePlan.selectedBasicPlan.sumAssured.value', '') ||
        _.get(currentData, 'quickQuote.coveragePlan.selectedBasicPlan.sumAssured', ''),
      indicatorCode: productType === 'product' ? 'Base' : 'Rider',
    }
  })

  const ridersSelect = riders.reduce((prev, item) => {
    if (_.isNil(item)) return {}
    const riderSelect = _.get(currentData, 'quickQuote.coveragePlan.riders', {})
    const planName = _.get(item, 'code', '')
    const productCode = _.get(item, 'aura.AURA_PRODUCT_CODE', '')
    const data = _.map(productCode, (item) => ({
      planName,
      productCode: item,
      deathBenefitAmt: _.get(riderSelect[planName], 'sumAssured', ''),
      indicatorCode: 'Rider',
    }))
    return [...prev, ...data]
  }, [])

  const officeAddress = (currentData, role) => {
    const checkSameAddress = _.get(currentData, `${role}.officeAddress.isSameAsCurrentAddress`, false)
    const checkPathAddress = checkSameAddress ? `${role}.currentAddress` : `${role}.officeAddress`
    const address = _.get(currentData, checkPathAddress, '')
    if (_.isEmpty(address)) return ''

    const { numberMoobanArkan, province, postCode, amphur, tambol } = address

    return `${numberMoobanArkan ? 'บ้านเลขที่ ' + numberMoobanArkan : ''} ${
      tambol?.text ? 'ตำบล ' + tambol?.text : ''
    } ${amphur?.text ? 'อำเภอ ' + amphur?.text : ''} ${province?.text ? 'จังหวัด ' + province?.text : ''} ${
      postCode?.text ? 'รหัสไปรษณี ' + postCode?.text : ''
    }`
  }

  const motorcycleUsageMap = (currentData, role) => {
    const valueMotorcycleUsage = _.get(currentData, `${role}.motorcycleUsage.value`, '')
    if (_.isEmpty(valueMotorcycleUsage)) return ''
    return valueMotorcycleUsage === 'yes' ? true : false
  }
  const havePayer = (role) => (role === 'payer' ? `quickQuote.insured.${role}` : `quickQuote.${role}`)

  const occupation = (currentData, role) => {
    const occupation = _.get(currentData, `${havePayer(role)}.occupation.natureOfDuty.text`)
    const occupationClassOther = _.get(currentData, `${havePayer(role)}.otherOccupation.natureOfDuty.text`)

    const tranFormOccupation = occupationClassOther ? `${occupation} | ${occupationClassOther}` : occupation
    return tranFormOccupation || ''
  }

  const occupationCode = (currentData, role) => {
    const occupationClassCode = _.get(currentData, `${havePayer(role)}.occupation.natureOfDuty.code`)
    const occupationClassCodeOther = _.get(currentData, `${havePayer(role)}.otherOccupation.natureOfDuty.code`)

    const tranFormOccupationCode = occupationClassCodeOther ? 'OTHER' : occupationClassCode
    return tranFormOccupationCode || ''
  }

  const payerParty =
    hasPayerSession && payerSessionId
      ? [
          {
            gender: DEFAULT.GENDER[genderPayer],
            birthDate: buddhistToChristianDate(birthDatePayer)
              .split('/')
              .reverse()
              .join('-'),
            estSalary: _.get(currentData, 'payer.mainOccupationIncomePerMonth', 0) * 12,
            estSalaryOther: _.get(currentData, 'payer.otherOccupationIncomePerMonth', 0) * 12,
            age: _.get(currentData, 'quickQuote.insured.payer.age.value', ''),
            occupationCode: occupationCode(currentData, 'payer'),
            occupation: occupation(currentData, 'payer'),
            motorcycleUsage: motorcycleUsageMap(currentData, 'insured'),
            officeAddress: officeAddress(currentData, 'payer'),
            transRefGUID: payerSessionId,
            sessionId: payerSessionId,
            relationRoleCode: 'Payor',
          },
        ]
      : []

  const isCompleteAura = !_.isNil(_.get(currentData, 'insured.aura.auraImpairment', undefined))

  return {
    appId: _.get(currentData, '_id', ''),
    transExeDate: dateFormatISO,
    transExeTime: timeFormatISO,
    coverage: [...productSelect, ...ridersSelect],
    party: [
      {
        gender: DEFAULT.GENDER[genderInsured],
        birthDate: buddhistToChristianDate(birthDateInsured)
          .split('/')
          .reverse()
          .join('-'),
        estSalary: _.get(currentData, 'insured.mainOccupationIncomePerMonth', 0) * 12,
        estSalaryOther: _.get(currentData, 'insured.otherOccupationIncomePerMonth', 0) * 12,
        age: _.get(currentData, 'quickQuote.insured.age.value', ''),
        occupationCode: occupationCode(currentData, 'insured'),
        occupation: occupation(currentData, 'insured'),
        motorcycleUsage: motorcycleUsageMap(currentData, 'insured'),
        officeAddress: officeAddress(currentData, 'insured'),
        transRefGUID: insuredSessionId,
        sessionId: insuredSessionId,
        relationRoleCode: 'Owner',
      },
      ...payerParty,
    ],
    isCompleteAura,
    accessToken,
  }
}
