// @flow
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Collapse, FormGroup, Input, Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap'
import NumberFormat from 'react-number-format'
import { ErrorFeedback } from 'common-components/view-logic'

import { withDropdown } from 'lib/with-hoc'
import { getRiskColor } from 'core/service/investment/risklevel'
import type { FundItemProps, FundAllocationProps, RiskLevelProps } from '../../types/fund-item'
import {
  getWealthSource,
  getIsOpenedPDF,
  getIsOpenedLSTUPDF,
  getSalePermission,
} from 'quick-quote/my-wealth-plus/selectors'
import { updateWealthSourceData } from 'quick-quote/my-wealth-plus/actions'
import { getUiVisited } from 'quick-quote/ui/selectors'
import { useDispatch, useSelector } from 'react-redux'

const RiskLevel = ({ value }): RiskLevelProps => {
  return (
    <div className="az-fund-item__risk-lv" style={{ backgroundColor: getRiskColor(value) }}>
      <span className="az-fund-item__risk-lv__label">Risk</span>
      <span className="az-fund-item__risk-lv__value">{value}</span>
    </div>
  )
}

const FundToggler = (props): FundItemProps => {
  const stateSelector = useSelector((state) => state)
  const dispatch = useDispatch()
  const { code, factSheet, selected, onToggle } = props

  const isMyWealthPlusSource = getWealthSource(stateSelector) === 'MY_WEALTH_PLUS'
  const isOpenedPDF = getIsOpenedPDF(stateSelector)
  const isOpenedLSTUPDF = getIsOpenedLSTUPDF(stateSelector)
  const isValidSale = getSalePermission(stateSelector)
  const getUiPathVisited = getUiVisited(stateSelector)

  const isFundAllocationPath = Object.keys(getUiPathVisited).find((key) => {
    return key === '/quick/investment/fund-allocation'
  })
  const isLTSUFundAllocationPath = Object.keys(getUiPathVisited).find((key) => {
    return key === '/quick/investment/lstu-fund-allocation'
  })

  useEffect(() => {
    if (selected && isOpenedPDF && isFundAllocationPath && isMyWealthPlusSource) {
      Object.assign(document.createElement('a'), { target: '_blank', href: factSheet }).click()
      dispatch(
        updateWealthSourceData({
          dataSource: 'MY_WEALTH_PLUS',
          isValidSalePermission: isValidSale ? true : false,
          isOpenedPDF: false,
          isOpenedLSTUPDF: true,
        })
      )
    }
  }, [selected, isFundAllocationPath, isOpenedPDF, isMyWealthPlusSource])

  useEffect(() => {
    if (selected && isOpenedLSTUPDF && isLTSUFundAllocationPath && isMyWealthPlusSource) {
      Object.assign(document.createElement('a'), { target: '_blank', href: factSheet }).click()
      dispatch(
        updateWealthSourceData({
          dataSource: 'MY_WEALTH_PLUS',
          isValidSalePermission: isValidSale ? true : false,
          isOpenedPDF: false,
          isOpenedLSTUPDF: false,
        })
      )
    }
  }, [selected, isLTSUFundAllocationPath, isOpenedLSTUPDF, isMyWealthPlusSource])

  return (
    <span key={`toggle-${code}`} className="toggler">
      <div className="common-toggle">
        <input
          id={`${code}-toggle-input`}
          className="round-flat"
          type="checkbox"
          checked={selected}
          onChange={() => code && onToggle({ code })}
          onClick={(e) => {
            if (!selected) {
              Object.assign(document.createElement('a'), { target: '_blank', href: factSheet }).click()
            }
            e.stopPropagation()
          }}
        />
        <label htmlFor={`${code}-toggle-input`} />
      </div>
    </span>
  )
}

const FundAllocationInput = ({
  selected,
  code,
  fundAllocation,
  errors,
  onChangeAllocationPercent,
}): FundAllocationProps => {
  const hasError = !_.isEmpty(errors)
  return (
    <Collapse className="assign-percent" isOpen={selected}>
      <FormGroup color={hasError ? 'danger' : ''}>
        <span className="assign-label">สัดส่วนกองทุน</span>
        <NumberFormat
          id={`${code}-assign-percent-input`}
          isAllowed={({ value }) => value >= 0 && value <= 100}
          value={fundAllocation}
          customInput={Input}
          allowNegative={false}
          type="tel"
          pattern="[0-9]*"
          decimalPrecision={0}
          suffix="%"
          onFocus={(e) => e.target.setSelectionRange(0, -1)}
          onChange={(e, values) => code && onChangeAllocationPercent(code, Number(values.value))}
        />
        {hasError && <ErrorFeedback id={`${code}-allocation-error`} errorMessages={errors} />}
      </FormGroup>
    </Collapse>
  )
}

export const FundItem = (props): FundItemProps => {
  const { fundHouse, code, thaiName, factSheet, riskLevel, isOverRisk, isForeign } = props

  return (
    <div className="az-fund-item">
      <RiskLevel value={riskLevel} />
      <div className="az-fund-item__fund-content">
        <div className="az-fund-item__fund-content__name">
          <span className={`firm ${_.lowerCase(fundHouse)}`} />
          <FundToggler {...props} />

          <span className="code-and-name">
            <FundCodeWithInfo code={code} isOverRisk={isOverRisk} isForeign={isForeign} />
            <span className="name">{thaiName}</span>
          </span>
        </div>
        <div className="az-fund-item__fund-content__link">
          <a href={factSheet} target="_blank" className="description">
            Fund facts (PDF)
          </a>
        </div>
        <div className="az-fund-item__fund-content__allocation">
          <FundAllocationInput {...props} />
        </div>
      </div>
    </div>
  )
}

const FundCodeWithInfo = withDropdown(({ code, isOverRisk, isForeign, dropdownOpen, toggle }) => {
  return isOverRisk || isForeign ? (
    <Dropdown direction="left" isOpen={dropdownOpen} toggle={toggle} data-dropdownopen={dropdownOpen}>
      <DropdownToggle>
        <span className="fund-info">
          <span className="code">{code}</span>
          <i className="icon--info" />
        </span>
      </DropdownToggle>
      <DropdownMenu>
        <FundRiskInfoContent isOverRisk={isOverRisk} isForeign={isForeign} />
      </DropdownMenu>
    </Dropdown>
  ) : (
    <span className="fund-info">
      <span className="code">{code}</span>
    </span>
  )
})

const FundRiskInfoContent = ({ isOverRisk, isForeign }) => {
  return isOverRisk || isForeign ? (
    <div className="az-fund-item__risk-info__content" data-info-count={isOverRisk && isForeign ? '2' : '1'}>
      {isOverRisk ? <p>กองทุนที่มีระดับความเสี่ยงสูงกว่าระดับความเสี่ยงที่ท่านรับได้</p> : null}
      {isForeign ? (
        <p>
          กองทุนที่ท่านเลือกมีการลงทุนในต่างประเทศซึ่งอาจไม่ได้มีการทำสัญญาป้องกันความเสี่ยงจากอัตราแลกเปลี่ยนหรือมีนโยบายป้องกันความเสี่ยงต่ำกว่าร้อยละ
          90 ซึ่งไม่สอดคล้องกับความเสี่ยงที่ท่านรับได้
        </p>
      ) : null}
    </div>
  ) : null
}

const propTypes = {
  thaiName: PropTypes.string,
  englishName: PropTypes.string,
  code: PropTypes.string,
  fundHouse: PropTypes.string,
  factSheet: PropTypes.string,
  riskLevel: PropTypes.number,
  isAllowed: PropTypes.bool,
  isOverRisk: PropTypes.bool,
  isForeign: PropTypes.bool,
  selected: PropTypes.bool,
  allocationPercent: PropTypes.number,
  errors: PropTypes.arrayOf(PropTypes.string),
  onToggle: PropTypes.func,
  onChangeAllocationPercent: PropTypes.func,
}
const defaultProps = {
  thaiName: '',
  englishName: '',
  code: '',
  fundHouse: '',
  factSheet: '',
  riskLevel: 1,
  isAllowed: true,
  isOverRisk: false,
  isForeign: false,
  selected: false,
  allocationPercent: 0,
  errors: [],
  onToggle() {},
  onChangeAllocationPercent() {},
}

FundItem.propTypes = propTypes
FundItem.defaultProps = defaultProps

export default FundItem
