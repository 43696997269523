import _ from 'lodash'
import { noop, negate, spread, rest, isEqual, map, get, omit, flow } from 'lodash/fp'
import { Flex } from 'rebass'
import { withRouter } from 'react-router-dom'
import { pure, compose, mapProps, withPropsOnChange, setDisplayName } from 'recompose'

import * as _FormComponent from 'e-submission/components/Form'

import connectForm from './connectForm'
import addFormGroup from './addFormGroup'
import addValidation from './addValidation'
import addLocalState from './addLocalState'

const FormComponent = _.mapValues(
  _FormComponent,
  compose(pure, connectForm, addLocalState, addValidation, addFormGroup, (createComponent, displayName) => {
    const Component = createComponent(
      compose(
        withRouter,
        mapProps(({ mergeProps = noop, ...props }) => ({
          ...props,
          ...mergeProps(props),
        })),
        mapProps(
          omit([
            'setValue',
            'unsetValue',
            'removeValue',
            'showDialog',
            'hideDialog',
            'dispatch',
            'allow',
            'validate',
            'match',
            'history',
            'location',
            'staticContext',
          ])
        ),
        setDisplayName(displayName + 'Component')
      )
    )

    return setDisplayName(displayName)(Component)
  })
)

const FormManager = ({ children, infoMessage }) => {
  return (
    <Flex wrap justify="space-between">
      {children}
    </Flex>
  )
}

const getElementId = flow(get('elements'), map('id'))
const isNotEqual = negate(isEqual)
const isElementIdChange = flow(rest(map(getElementId)), spread(isNotEqual))

export default compose(
  withPropsOnChange(
    isElementIdChange,
    ({
      elements,
      setValue = noop,
      unsetValue = noop,
      removeValue = noop,
      showDialog = noop,
      hideDialog = noop,
      styleOptions,
    }) => ({
      children:
        elements &&
        elements.map(({ c: component, p: elementProps, ...otherProps }, index) => {
          const Component = FormComponent[component]
          if (_.isNil(Component)) {
            return <label key={index}>!! Component {component} not found.</label>
          }
          return (
            <Component
              key={index}
              {...elementProps}
              {...otherProps}
              component={component}
              styleOptions={styleOptions}
              setValue={setValue}
              unsetValue={unsetValue}
              removeValue={removeValue}
              showDialog={showDialog}
              hideDialog={hideDialog}
            />
          )
        }),
    })
  )
)(FormManager)
