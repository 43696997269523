// @flow
import type { InvestmentBenefitTable } from 'core/service/benefit-illustration/investment-benefit'

export const UPDATE_TERM_LIFE_INVESTMENT_BENEFIT = 'UPDATE_TERM_LIFE_INVESTMENT_BENEFIT'
export type UpdateTermLifeInvestmentBenefit = {
  type: 'UPDATE_TERM_LIFE_INVESTMENT_BENEFIT',
  payload: InvestmentBenefitTable,
}
export const updateTermLifeInvestmentBenefit = (
  investmentBenefit: InvestmentBenefitTable
): UpdateTermLifeInvestmentBenefit => ({
  type: UPDATE_TERM_LIFE_INVESTMENT_BENEFIT,
  payload: investmentBenefit,
})
