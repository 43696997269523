//@flow
import _ from 'lodash'
import type { PolicyValue, YearEndPolicyValue } from 'core/service/benefit-illustration'
import { formatNumber } from 'core/service/lib/number-format'
import MESSAGES from 'core/data-model/constants/bi-messages'
import type { PolicyValueTableProps } from 'core/service/benefit-illustration/policy-value'
import { calculatePageAmountPolicyValueTable } from 'core/service/pdf-generation/products/common/logic'

export const _breakLine = (text: string) => {
  const premiumPattern = /^(เบี้ยประกันภัย)(สัญญา.+)$/
  const deathBenefitPattern = /^(ความคุ้มครอง)(การเสียชีวิต)$/
  const surrenderCashPattern = /^(มูลค่าเวนคืน)(เงินสด\**)$/
  const savingWithCompany = /^(กรณีรับเป็น)(เงินสด)$/

  const patterns = [premiumPattern, deathBenefitPattern, surrenderCashPattern, savingWithCompany]

  const breakByFirstMatched = ([p, ...rest]) => (text.match(p) ? text.replace(p, '$1 $2') : breakByFirstMatched(rest))

  return breakByFirstMatched(patterns)
}

const rowHeightConfig = { lineHeight: 0.75, margin: [0, -3, 0, 0] }

const buildEtiRpuHeaderTable = () => {
  return [
    [
      {
        text: MESSAGES.ETI_RPU_YEAR_END_POLICY,
        rowSpan: 3,
        style: 'tableHeader',
        bold: true,
        margin: [0, 10, 0, 0],
      },
      {
        text: MESSAGES.ETI_RPU_SURRENDER_VALUE,
        rowSpan: 3,
        style: 'tableHeader',
        bold: true,
        margin: [0, 10, 0, 0],
      },
      {
        text: MESSAGES.RPU,
        colSpan: 2,
        rowSpan: 2,
        style: 'tableHeader',
        bold: true,
        margin: [0, 9, 0, 0],
      },
      { text: '' },
      {
        text: MESSAGES.ETI,
        colSpan: 4,
        style: 'tableHeader',
        bold: true,
      },
      { text: '' },
      { text: '' },
      { text: '' },
    ],
    [
      '',
      '',
      '',
      '',
      {
        text: MESSAGES.ETI_VALUE,
        style: 'tableHeader',
        colSpan: 2,
        bold: true,
      },
      { text: '' },
      {
        text: MESSAGES.CASHPAID,
        rowSpan: 2,
        style: 'tableHeader',
        bold: true,
        margin: [0, 10, 0, 0],
      },
      {
        text: MESSAGES.ETI_MATURITY,
        rowSpan: 2,
        style: 'tableHeader',
        bold: true,
        margin: [0, 10, 0, 0],
      },
    ],
    [
      '',
      '',
      {
        text: MESSAGES.CASHPAID,
        style: 'tableHeader',
        bold: true,
      },
      {
        text: MESSAGES.RPU_SA,
        style: 'tableHeader',
        bold: true,
      },
      {
        text: MESSAGES.YEAR,
        style: 'tableHeader',
        bold: true,
      },
      {
        text: MESSAGES.DAY,
        style: 'tableHeader',
        bold: true,
      },
      '',
      '',
    ],
  ]
}

const buildEtiRpuPolicyValueRow = (yearEndPolicy: YearEndPolicyValue) => [
  {
    text: yearEndPolicy.yearEndPolicy,
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.surrenderCash === 0 ? '-' : formatNumber(yearEndPolicy.surrenderCash, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.RPUCashpaid === 0 ? '-' : formatNumber(yearEndPolicy.RPUCashpaid, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.RPUSumAssured === 0 ? '-' : formatNumber(yearEndPolicy.RPUSumAssured, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.ETIYear === 0 ? '-' : formatNumber(yearEndPolicy.ETIYear, 0),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.ETIDay === 0 ? '-' : formatNumber(yearEndPolicy.ETIDay, 0),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.ETICashpaid === 0 ? '-' : formatNumber(yearEndPolicy.ETICashpaid, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.ETIMaturity === 0 ? '-' : formatNumber(yearEndPolicy.ETIMaturity, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
]

const buildPolicyValueEtiRpuTable = (policyValue: PolicyValue) => {
  const policyValueTables = []
  let rowsPerPage = [50, 50, 50, 50, 50]
  if (rowsPerPage[0] === policyValue.yearEndPolicyValues.length) {
    rowsPerPage[0] = rowsPerPage[0] - 1
  }
  const pageAmount = calculatePageAmountPolicyValueTable(policyValue, rowsPerPage)
  _.times(pageAmount, (currentPage) => {
    policyValueTables.push({
      table: {
        headerRows: 5,
        widths: [40, 80, 65, 65, 40, 40, 65, 65],
        body: [
          ...buildEtiRpuHeaderTable(),
          ...policyValue.yearEndPolicyValues
            .slice(
              currentPage * rowsPerPage[currentPage > 0 ? currentPage - 1 : 0],
              rowsPerPage[currentPage] * (currentPage + 1)
            )
            .map((p) => buildEtiRpuPolicyValueRow(p)),
        ],
      },
      layout: 'lightBorder',
      style: 'table25PL',
    })
  })

  return policyValueTables
}

export const policyValueEtiRpuTableSection = ({ policyValue, basicPlan }: PolicyValueTableProps) => [
  {
    text: MESSAGES.POLICY_VALUE_TABLE,
    style: 'title',
    alignment: 'center',
  },
  ...buildPolicyValueEtiRpuTable(policyValue),
  {
    text: '',
    pageBreak: 'after',
  },
]
