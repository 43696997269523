// @flow

import type { FundState } from 'core/service/investment'
import type { UpdateAvailableFunds } from './actions'
import { UPDATE_AVAILABLE_FUNDS } from './actions'
import { combineReducers } from 'redux'
import type { FundAllocationState } from './rpp/reducers'
import { reducer as rpp } from './rpp/reducers'
import type { LstuFundAllocationState } from './lstu/reducers'
import { reducer as lstu } from './lstu/reducers'

export type State = {
  availableFunds: FundState[],
  rpp: FundAllocationState,
  lstu: LstuFundAllocationState,
}

export const availableFunds = (state: FundState[] = [], action: UpdateAvailableFunds): FundState[] => {
  switch (action.type) {
    case UPDATE_AVAILABLE_FUNDS:
      return action.payload
    default:
      return state
  }
}

// $$FlowFixMe
export const reducer = combineReducers({
  availableFunds,
  lstu,
  rpp,
})
