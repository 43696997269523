import { policyValueTableSection } from './policy-value-table-section'
import {
  flexiHealthLegalDisclaimer,
  flexiHealthTaxBenefit,
  flexiHealthlegalConditionDisclaimer,
  flexiHealthlegalConditionDisclaimerMarketConduct,
} from './legal-disclaimer-section'
import { profileSnapshotSection } from './profile-snapshot-section'

export const sections = {
  policyValueTable: policyValueTableSection,
  flexiHealthLegalDisclaimer: flexiHealthLegalDisclaimer,
  flexiHealthTaxBenefit: flexiHealthTaxBenefit,
  flexiHealthlegalConditionDisclaimer: flexiHealthlegalConditionDisclaimer,
  flexiHealthlegalConditionDisclaimerMarketConduct: flexiHealthlegalConditionDisclaimerMarketConduct,
  profile: profileSnapshotSection,
}
