// @flow
import type { CashFlow } from 'core/service/investment/cash-flow/cash-flow'
import type Decimal from 'decimal.js'
import BIMESSAGES from 'core/data-model/constants/bi-messages'
import {
  assembleTable,
  combineTables,
  GroupedTableHeader,
  SimpleHeader,
  StackedTableHeader,
} from 'core/service/pdf-generation/utils/table-utils'
import Mustache from 'mustache'
import { round, formatNumber } from 'core/service/lib/number-format'
import VALUES from 'core/data-model/constants/values'
import _ from 'lodash'
import type { PolicyPeriod } from 'core/data-model/investment/types'

export const roundAndFormat = (decimal: Decimal) => {
  const roundedNum = round(decimal.toNumber())
  const formattedNumber = formatNumber(roundedNum)

  return roundedNum <= 0 ? '-' : formattedNumber
}

export const roundAndFormatAccountValue = (decimal: Decimal) => {
  const roundedNum = round(decimal.toNumber())
  const formattedNumber = formatNumber(roundedNum)

  return roundedNum <= 0 ? '0' : formattedNumber
}

export const roundAndFormatFundReturn = (fundReturn: Decimal, accountValueAfterDeduction: Decimal) => {
  // TODO: test this logic
  if (accountValueAfterDeduction.lt(0)) return '0'

  const roundedNum = round(fundReturn.toNumber())
  return formatNumber(roundedNum)
}

const WIDTH_ONE = 15
const WIDTH_TWO = 40
const policyPeriod = (cashFlows: CashFlow[]) => {
  const text = {
    year: BIMESSAGES.YEAR_AT,
    month: BIMESSAGES.MONTH,
    age: BIMESSAGES.AGE,
  }

  return {
    widths: [WIDTH_ONE, WIDTH_ONE, WIDTH_ONE],
    header: combineTables([
      SimpleHeader(text.year, 3, VALUES.CYAN),
      SimpleHeader(text.month, 3, VALUES.CYAN),
      SimpleHeader(text.age, 3, VALUES.CYAN),
    ]),
    body: cashFlows.map(({ policyPeriod }) => {
      const { year, month, age } = policyPeriod
      return [year, month, age]
    }),
  }
}

const iWealthyPremium = (cashFlows: CashFlow[]) => {
  const text = {
    premium: BIMESSAGES.PREMIUM,
    regularPremium: BIMESSAGES.REGULAR_COVERAGE_BASIC_PREMIUM,
    regularTopUp: BIMESSAGES.REGULAR_INVESTMENT_PREMIUM,
    lumpSum: BIMESSAGES.CASHFLOW_TABLE_PREMIUM_REGULAR_LUMPSUM.join(''),
    totalPremium: BIMESSAGES.CASHFLOW_TABLE_PREMIUM_TOTAL_PREMIUM,
  }

  const startIndex = 1
  const subTitles = [text.regularPremium, text.regularTopUp, text.lumpSum, text.totalPremium]

  return {
    widths: [WIDTH_TWO, WIDTH_TWO, WIDTH_TWO, WIDTH_TWO],
    header: GroupedTableHeader(text.premium, subTitles, startIndex),
    body: cashFlows.map(({ premium }) => {
      const { regularPremium, regularTopUp, lumpSum, totalPremium } = premium
      return [regularPremium, regularTopUp, lumpSum, totalPremium].map(roundAndFormat)
    }),
  }
}
const iInvestPremium = (cashFlows: CashFlow[]) => {
  const text = {
    premium: BIMESSAGES.PREMIUM,
    regularPremium: BIMESSAGES.PREMIUM,
    lumpSum: BIMESSAGES.CASHFLOW_TABLE_PREMIUM_LUMPSUM,
    totalPremium: BIMESSAGES.CASHFLOW_TABLE_PREMIUM_TOTAL_PREMIUM,
  }

  const startIndex = 1
  const subTitles = [text.regularPremium, text.lumpSum, text.totalPremium]

  return {
    widths: [WIDTH_TWO, WIDTH_TWO, WIDTH_TWO],
    header: GroupedTableHeader(text.premium, subTitles, startIndex),
    body: cashFlows.map(({ premium }) => {
      const { regularPremium, lumpSum, totalPremium } = premium
      return [regularPremium, lumpSum, totalPremium].map(roundAndFormat)
    }),
  }
}

const iWealthyPolicyCharges = (cashFlows: CashFlow[]) => {
  const text = {
    policyCharge: BIMESSAGES.CASHFLOW_TABLE_POLICY_CHARGE,
    premiumCharge: BIMESSAGES.PREMIUM_ALLOCATION_FEE,
    coi: BIMESSAGES.CASHFLOW_TABLE_POLICY_CHARGE_COI,
    amc: BIMESSAGES.CASHFLOW_TABLE_POLICY_CHARGE_AMC,
  }

  const subTitles = [text.premiumCharge, text.coi, text.amc]

  const startIndex = 5
  return {
    widths: [WIDTH_TWO, WIDTH_TWO, WIDTH_TWO],
    header: GroupedTableHeader(text.policyCharge, subTitles, startIndex, VALUES.PINK),
    body: cashFlows.map(({ policyCharges }) => {
      const { premiumCharge, chargeOfInsurance, adminCharge } = policyCharges
      return [premiumCharge, chargeOfInsurance, adminCharge].map(roundAndFormat)
    }),
  }
}

const iInvestPolicyCharges = (cashFlows: CashFlow[]) => {
  const text = {
    policyCharge: BIMESSAGES.CASHFLOW_TABLE_POLICY_CHARGE,
    premiumCharge: BIMESSAGES.PREMIUM_ALLOCATION_FEE,
    lumpsumCharge: BIMESSAGES.CASHFLOW_TABLE_POLICY_CHARGE_LUMPSUM_TOPUP,
    coi: BIMESSAGES.CASHFLOW_TABLE_POLICY_CHARGE_COI,
    amc: BIMESSAGES.CASHFLOW_TABLE_POLICY_CHARGE_AMC,
  }

  const subTitles = [text.premiumCharge, text.lumpsumCharge, text.coi, text.amc]

  const startIndex = 4
  return {
    widths: [WIDTH_TWO, WIDTH_TWO, WIDTH_TWO, WIDTH_TWO],
    header: GroupedTableHeader(text.policyCharge, subTitles, startIndex, VALUES.PINK),
    body: cashFlows.map(({ policyCharges }) => {
      const { premiumCharge, lumpsumCharge, chargeOfInsurance, adminCharge } = policyCharges
      return [premiumCharge, lumpsumCharge, chargeOfInsurance, adminCharge].map(roundAndFormat)
    }),
  }
}

const investment = (expectedReturn: number, cashFlows: CashFlow[]) => {
  const text = {
    investment: BIMESSAGES.CASHFLOW_TABLE_INVESTMENT,
    accountValueAfterDeduction: BIMESSAGES.CASHFLOW_TABLE_INVESTMENT_ACCOUNT_VALUE_ACCOUNT_VALUE_AFTER_DEDUCTION,
    fundReturn: Mustache.render(BIMESSAGES.CASHFLOW_TABLE_INVESTMENT_FUND_RETURN, { expectedReturn }),
    accountValueBeforeBonus: BIMESSAGES.CASHFLOW_TABLE_INVESTMENT_ACCOUNT_VALUE_BEFORE_BONUS,
    bonus: BIMESSAGES.CASHFLOW_TABLE_INVESTMENT_BONUS,
    accountValueAfterBonus: BIMESSAGES.CASHFLOW_TABLE_INVESTMENT_ACCOUNT_VALUE_AFTER_BONUS,
  }

  const subTitles = [
    text.accountValueAfterDeduction,
    text.fundReturn,
    text.accountValueBeforeBonus,
    text.bonus,
    text.accountValueAfterBonus,
  ]

  const startIndex = 8

  return {
    widths: [WIDTH_TWO, WIDTH_TWO, WIDTH_TWO, WIDTH_TWO, WIDTH_TWO],
    header: GroupedTableHeader(text.investment, subTitles, startIndex, VALUES.GREEN),
    body: cashFlows.map(({ investment }) => {
      const {
        accountValueAfterDeduction,
        fundReturn,
        accountValueBeforeBonus,
        bonus,
        accountValueAfterBonus,
      } = investment

      return [
        roundAndFormatAccountValue(accountValueAfterDeduction),
        roundAndFormatFundReturn(fundReturn, accountValueAfterDeduction),
        roundAndFormatAccountValue(accountValueBeforeBonus),
        roundAndFormat(bonus),
        roundAndFormatAccountValue(accountValueAfterBonus),
      ]
    }),
  }
}

const surrenderCash = (cashFlows: CashFlow[]) => ({
  widths: [WIDTH_TWO],
  header: StackedTableHeader(BIMESSAGES.SURRENDER_CASH_VALUE, 13, VALUES.DARK_CYAN),
  body: cashFlows.map(({ surrenderCash }) => {
    return [surrenderCash].map(roundAndFormat)
  }),
})

const deathBenefit = (cashFlows: CashFlow[]) => ({
  widths: [WIDTH_TWO],
  header: StackedTableHeader(BIMESSAGES.BASIC_PLAN_DEATH_BENEFIT, 14, VALUES.DARK_CYAN),
  body: cashFlows.map(({ deathBenefit }) => {
    return [deathBenefit].map(roundAndFormat)
  }),
})

const paddingTop = () => -0.85

const checkLastMonthOfMaxAgeInsured = (lastAge, lastMonth, months, defaultMonths) => {
  const { INFORCE } = VALUES
  const aryFirstMonth = [1]
  if (lastAge === INFORCE.MAXIMUM_COVERAGE_AGE_RPUDR - 1 && lastMonth === 12) return defaultMonths
  else if (lastAge === INFORCE.MAXIMUM_COVERAGE_AGE_RPUDR - 1 && lastMonth !== 12) return aryFirstMonth.concat(months)
  else return months
}

const investmentPolicyPeriod = (
  endOfInvestmentPeriod: { year: number, month: number }[],
  defaultYears: number[],
  productCode: string
) => (year) => {
  const displayEveryMonthOnYears = [1]
  const defaultMonths = [1, 12]
  const allMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
  const endOfInvestmentYears = endOfInvestmentPeriod.map((p) => p.year)
  const monthsForEndOfInvestment = (year) => {
    const investmentMonths = _.filter(endOfInvestmentPeriod, { year }).map((p) => p.month)
    const lastMonth = _.last(investmentMonths)
    const investmentInsureAges = _.filter(endOfInvestmentPeriod, { year }).map((p) => p.age)
    const lastAge = _.last(investmentInsureAges)
    const months = _.includes(defaultYears, year) ? [...defaultMonths, ...investmentMonths] : investmentMonths

    const lastMonthDisplayForMaxAgeInsured =
      productCode === BIMESSAGES.BI_TYPE_PRODUCT_CODE_RPUDR
        ? checkLastMonthOfMaxAgeInsured(lastAge, lastMonth, months, defaultMonths)
        : months
    return _.uniq(_.filter(lastMonthDisplayForMaxAgeInsured, (month) => month <= lastMonth))
  }
  if (displayEveryMonthOnYears.includes(year)) return { year, months: allMonths }
  if (_.includes(endOfInvestmentYears, year)) return { year, months: monthsForEndOfInvestment(year) }
  return { year, months: defaultMonths }
}

export const yearsToDisplay = (
  { age }: PolicyPeriod,
  endOfInvestmentPeriod: { year: number, month: number }[],
  productCode: string
) => {
  const endOfInvestmentYears = endOfInvestmentPeriod.map((p) => p.year)
  const firstYearOfNonInvestment = _.max(endOfInvestmentYears)
  const lastYear = _.min([99 - age, firstYearOfNonInvestment])
  const defaultYears = [1, 2, 3, 4, 6, 10, 20, 30, 40, 50, 60, 70, 80]
  const investmentYears = [...defaultYears, ...endOfInvestmentYears, lastYear]
  const years = _.uniq(_.filter(investmentYears, (year) => year <= lastYear))
  const investmentPolicyPeriodForYear = investmentPolicyPeriod(endOfInvestmentPeriod, defaultYears, productCode)
  return years.map(investmentPolicyPeriodForYear)
}

export const policyPeriodFilter = (chosenPeriods: { year: number, months: number[] }[]) => ({
  policyPeriod,
}: CashFlow) => {
  return !_(chosenPeriods)
    .filter(({ year, months }) => year === policyPeriod.year && _.includes(months, policyPeriod.month))
    .isEmpty()
}

export const findEndOfInvestmentPeriod = (cashFlows: CashFlow[]) => {
  const firstNegativeAccountValue = _.findIndex(
    cashFlows,
    (cashFlow) => cashFlow.investment.accountValueAfterDeduction < 0
  )
  if (firstNegativeAccountValue < 1) return []
  const lastInvestmentPeriod = cashFlows[firstNegativeAccountValue - 1].policyPeriod
  const firstNonInvestmentPeriod = cashFlows[firstNegativeAccountValue].policyPeriod
  return _.uniqBy(
    [
      { age: lastInvestmentPeriod.age, month: 1, year: lastInvestmentPeriod.year },
      lastInvestmentPeriod,
      firstNonInvestmentPeriod,
    ],
    'month'
  )
}

export const iInvestCashFlowTable = (expectedReturn: number, cashFlows: CashFlow[], productCode: string) => {
  const entryCashFlow = _.find(cashFlows, { policyPeriod: { year: 1, month: 1 } }) || {
    policyPeriod: { year: 1, month: 1, age: 1 },
  }
  const endOfInvestmentPeriod = findEndOfInvestmentPeriod(cashFlows)
  const chosenYearsToDisplay = yearsToDisplay(entryCashFlow.policyPeriod, endOfInvestmentPeriod, productCode)
  const cashFlowsToDisplay = _.filter(cashFlows, policyPeriodFilter(chosenYearsToDisplay))

  const policyPeriodTable = policyPeriod(cashFlowsToDisplay)
  const premiumTable = iInvestPremium(cashFlowsToDisplay)
  const policyChargesTable = iInvestPolicyCharges(cashFlowsToDisplay)
  const investmentTable = investment(expectedReturn, cashFlowsToDisplay)
  const surrenderCashTable = surrenderCash(cashFlowsToDisplay)
  const deathBenefitTable = deathBenefit(cashFlowsToDisplay)

  const tables = [
    policyPeriodTable,
    premiumTable,
    policyChargesTable,
    investmentTable,
    surrenderCashTable,
    deathBenefitTable,
  ]

  const body = assembleTable(tables)

  const widths = tables.reduce((acc, table) => acc.concat(table.widths), [])

  return {
    table: { body, widths },
    fontSize: 8,
    marginTop: 5,
    alignment: 'right',
    lineHeight: 0.8,
    layout: { paddingTop },
  }
}

export const iWealthyCashFlowTable = (expectedReturn: number, cashFlows: CashFlow[], productCode: string) => {
  const entryCashFlow = _.find(cashFlows, { policyPeriod: { year: 1, month: 1 } }) || {
    policyPeriod: { year: 1, month: 1, age: 1 },
  }
  const endOfInvestmentPeriod = findEndOfInvestmentPeriod(cashFlows)
  const chosenYearsToDisplay = yearsToDisplay(entryCashFlow.policyPeriod, endOfInvestmentPeriod, productCode)
  const cashFlowsToDisplay = _.filter(cashFlows, policyPeriodFilter(chosenYearsToDisplay))
  const policyPeriodTable = policyPeriod(cashFlowsToDisplay)
  const premiumTable = iWealthyPremium(cashFlowsToDisplay)
  const policyChargesTable = iWealthyPolicyCharges(cashFlowsToDisplay)
  const investmentTable = investment(expectedReturn, cashFlowsToDisplay)
  const surrenderCashTable = surrenderCash(cashFlowsToDisplay)
  const deathBenefitTable = deathBenefit(cashFlowsToDisplay)

  const tables = [
    policyPeriodTable,
    premiumTable,
    policyChargesTable,
    investmentTable,
    surrenderCashTable,
    deathBenefitTable,
  ]

  const body = assembleTable(tables)

  const widths = tables.reduce((acc, table) => acc.concat(table.widths), [])

  return {
    table: { body, widths },
    fontSize: 8,
    marginTop: 1,
    alignment: 'right',
    lineHeight: 0.8,
    layout: { paddingTop },
  }
}
