//@flow
import { getMEXExceptionNotes } from 'core/service/rider/benefits/exception-notes'

import MESSAGES from 'core/data-model/constants/bi-messages'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { RiderBenefitData } from 'core/data-model/rider/benefits'
import { findDataOnListByRiderCode } from 'quick-quote/product-common/benefit-illustration/components/rider-benefits/components/mex-rider-benefit'
import { buildHeaderTable } from 'core/service/pdf-generation/utils/builder'
import { getRiderPlanCode } from 'core/service/rider/rider-mex'
import Mustache from 'mustache'

type MEXBenefitProps = {
  rider: Rider & RiderState,
  riderBenefitData: RiderBenefitData,
  sumAssured: number,
}

export const buildEndorseSection = (endorse, planCode) => {
  const { title, contents, remark, remarkTotalBenefit } = endorse

  return [
    {
      text: `${title}`,
      style: 'sub-title',
    },
    {
      table: buildEndorseTable(contents, planCode),
      layout: 'lightBorder',
      style: 'table',
    },
    {
      text: planCode.endsWith('J') ? remark[0] : '',
      margin: planCode.endsWith('J') ? [0, 0, 0, 10] : '',
      style: planCode.endsWith('J') ? 'disclaimer' : '',
    },
    {
      text: `${remark[1]}`,
      bold: true,
      style: 'disclaimer',
    },
    {
      text: `${remark[2]}`,
      bold: false,
      style: 'disclaimer',
      margin: [0, 5, 0, 10],
    },
    {
      text: `${remark[3]}`,
      bold: true,
      style: 'disclaimer',
    },
    {
      text: `${remark[4]}`,
      bold: false,
      style: 'disclaimer',
      margin: [0, 5, 0, 10],
    },
    {
      text: Mustache.render(remark[5], {
        remarkTotalBenefit: findDataOnListByRiderCode(remarkTotalBenefit, planCode),
      }),
      style: 'disclaimer',
      margin: [0, 5, 0, 0],
    },
  ]
}

export const buildBenefitSection = (benefits, planCode) => {
  const { title, contents, remark } = benefits
  return [
    {
      text: `${title}`,
      style: 'sub-title',
    },
    {
      table: buildBenefitTable(contents, planCode),
      layout: 'lightBorder',
      style: 'table',
    },
    {
      text: planCode.endsWith('J') ? remark[0] : '',
      style: 'disclaimer',
      margin: [0, 5, 0, 0],
    },
  ]
}

export const buildEndorseTable = (contents, planCode) => {
  const headers = [
    MESSAGES.BENEFIT_TITLE,
    MESSAGES.MEX_RIDER_BENEFIT_BAHT,
    MESSAGES.MEX_RIDER_BENEFIT_DAY_OR_TIME,
    MESSAGES.MEX_RIDER_TOTAL_BENEFIT_BAHT,
  ]
  return {
    headerRows: 1,
    widths: ['*', 95, 60, 80],
    dontBreakRows: true,
    body: [
      buildHeaderTable(headers),

      [
        {
          stack: [
            {
              text: `${contents[0].text[0]}`,
              alignment: 'left',
              bold: true,
            },
            {
              text: `             ${contents[0].text[1]}`,
              alignment: 'left',
              bold: false,
              preserveLeadingSpaces: true,
              marginTop: -16,
            },
          ],
        },
        {
          stack: [
            { text: `${findDataOnListByRiderCode(contents[0].benefit, planCode)}`, alignment: 'center' },
            { text: `${contents[0].benefitDesc}`, alignment: 'center' },
          ],
        },
        { text: `${contents[0].totalTimes[0]}`, alignment: 'center' },
        {
          stack: [
            { text: `${findDataOnListByRiderCode(contents[0].totalBenefit, planCode)}`, alignment: 'center' },
            { text: `${contents[0].totalBenefitDesc}`, alignment: 'center' },
          ],
        },
      ],
      [
        {
          stack: [
            {
              text: `${contents[1].text[0]}`,
              alignment: 'left',
              bold: true,
            },
            {
              text: `             ${contents[1].text[1]}`,
              alignment: 'left',
              bold: false,
              preserveLeadingSpaces: true,
              marginTop: -16,
            },
          ],
        },
        { text: `${contents[1].benefit[0]}`, alignment: 'center' },
        { text: `${contents[1].totalTimes[0]}`, alignment: 'center' },
        { text: `${contents[1].totalBenefit[0]}`, alignment: 'center' },
      ],
      [
        {
          stack: [
            {
              text: `${contents[2].text[0]}`,
              alignment: 'left',
              bold: true,
            },
            {
              text: `             ${contents[2].text[1]}${findDataOnListByRiderCode(contents[2].text[2], planCode)}`,
              alignment: 'left',
              bold: false,
              preserveLeadingSpaces: true,
              marginTop: -16,
            },
          ],
        },
        { text: `${contents[2].benefit[0]}`, alignment: 'center' },
        { text: `${contents[2].totalTimes[0]}`, alignment: 'center' },
        { text: `${contents[2].totalBenefit[0]}`, alignment: 'center' },
      ],
    ],
  }
}

export const buildBenefitTable = (contents, planCode) => {
  const headers = [
    MESSAGES.BENEFIT_TITLE,
    MESSAGES.MEX_RIDER_BENEFIT_BAHT,
    MESSAGES.MEX_RIDER_BENEFIT_DAY_OR_TIME,
    MESSAGES.MEX_RIDER_TOTAL_BENEFIT_BAHT,
  ]
  return {
    headerRows: 1,
    widths: ['*', 95, 60, 80],
    dontBreakRows: true,
    body: [
      buildHeaderTable(headers),

      [{ text: `${contents[0].text[0]}`, alignment: 'left', bold: true, colSpan: 4 }],
      [
        {
          stack: [
            {
              text: `${contents[1].text[0]}`,
              alignment: 'left',
              bold: true,
            },
            {
              text: `             ${contents[1].text[1]}\n     ${contents[1].text[2]}`,
              alignment: 'left',
              bold: false,
              preserveLeadingSpaces: true,
              marginTop: -16,
            },
          ],
        },
        { text: `${findDataOnListByRiderCode(contents[1].benefit, planCode)}`, alignment: 'center' },
        { text: `${contents[1].totalTimes[0]}`, alignment: 'center' },
        { text: `${findDataOnListByRiderCode(contents[1].totalBenefit, planCode)}`, alignment: 'center' },
      ],
      [
        {
          stack: [
            {
              text: `${contents[2].text[0]}`,
              alignment: 'left',
              bold: true,
            },
            {
              text: `             ${contents[2].text[1]}`,
              alignment: 'left',
              bold: false,
              preserveLeadingSpaces: true,
              marginTop: -16,
            },
          ],
          colSpan: 3,
        },
        '',
        '',
        {
          text: '',
        },
      ],
      [
        {
          text: `${contents[3].text[0]}`,
          alignment: 'left',
        },
        { text: `${contents[3].benefit[0]}`, alignment: 'center', rowSpan: 3 },
        { text: `${contents[3].totalTimes[0]}`, alignment: 'center', rowSpan: 3 },
        {
          stack: [
            { text: `${findDataOnListByRiderCode(contents[3].totalBenefit, planCode)}` },
            { text: `${contents[3].totalBenefitDesc}` },
          ],
          alignment: 'center',
          rowSpan: 4,
        },
      ],
      [
        {
          text: `${contents[3].text[1]}`,
          alignment: 'left',
        },
        '',
        '',
        '',
      ],
      [
        {
          text: `${contents[3].text[2]}`,
          alignment: 'left',
        },
        '',
        '',
        '',
      ],
      [
        {
          text: `${contents[3].text[3]}`,
          alignment: 'left',
        },
        { text: `${contents[3].benefit[3]}`, alignment: 'center' },
        { text: `${contents[3].totalTimes[3]}`, alignment: 'center' },
        '',
      ],
      [
        {
          stack: [
            {
              text: `${contents[4].text[0]}`,
              alignment: 'left',
              bold: true,
            },
            {
              text: `             ${contents[4].text[1]}`,
              alignment: 'left',
              bold: false,
              preserveLeadingSpaces: true,
              marginTop: -16,
            },
          ],
        },
        { text: `${findDataOnListByRiderCode(contents[4].benefit, planCode)}`, alignment: 'center' },
        { text: `${contents[4].totalTimes[0]}`, alignment: 'center' },
        { text: `${findDataOnListByRiderCode(contents[4].totalBenefit, planCode)}`, alignment: 'center' },
      ],
      [
        {
          stack: [
            {
              text: `${contents[5].text[0]}`,
              alignment: 'left',
              bold: true,
            },
            {
              text: `             ${contents[5].text[1]}`,
              alignment: 'left',
              bold: false,
              preserveLeadingSpaces: true,
              marginTop: -16,
            },
          ],
          colSpan: 3,
        },
        '',
        '',
        {
          text: '',
        },
      ],
      [
        { text: `${contents[6].text[0]}`, alignment: 'left' },
        { text: `${contents[6].benefit[0]}`, alignment: 'center' },
        { text: `${contents[6].totalTimes[0]}`, alignment: 'center' },
        { text: `${findDataOnListByRiderCode(contents[6].totalBenefit[0], planCode)}`, alignment: 'center' },
      ],
      [
        { text: `${contents[6].text[1]}`, alignment: 'left' },
        {
          stack: [
            { text: `${contents[6].benefit[1][0]}`, alignment: 'center' },
            { text: `${contents[6].benefit[1][1]}`, alignment: 'center' },
          ],
        },
        { text: `${contents[6].totalTimes[1]}`, alignment: 'center' },
        { text: `${contents[6].totalBenefit[1]}`, alignment: 'center' },
      ],
      [
        { text: `${contents[6].text[2]}`, alignment: 'left' },
        { text: `${contents[6].benefit[2]}`, alignment: 'center' },
        { text: `${contents[6].totalTimes[2]}`, alignment: 'center' },
        { text: `${findDataOnListByRiderCode(contents[6].totalBenefit[2], planCode)}`, alignment: 'center' },
      ],
      [
        { text: `${contents[6].text[3]}`, alignment: 'left' },
        { text: `${contents[6].benefit[3]}`, alignment: 'center' },
        { text: `${contents[6].totalTimes[3]}`, alignment: 'center' },
        { text: `${findDataOnListByRiderCode(contents[6].totalBenefit[3], planCode)}`, alignment: 'center' },
      ],
      [
        { text: `${contents[6].text[4]}`, alignment: 'left' },
        { text: `${contents[6].benefit[4]}`, alignment: 'center' },
        { text: `${contents[6].totalTimes[4]}`, alignment: 'center' },
        { text: `${contents[6].totalBenefit[4]}`, alignment: 'center' },
      ],
      [
        [
          {
            text: `${contents[7].text[0]}`,
            alignment: 'left',
            bold: true,
          },
          {
            text: `             ${contents[7].text[1]}`,
            alignment: 'left',
            bold: false,
            preserveLeadingSpaces: true,
            marginTop: -16,
          },
        ],
        { text: `${contents[7].benefit[0]}`, alignment: 'center' },
        { text: `${contents[7].totalTimes[0]}`, alignment: 'center' },
        { text: `${contents[7].totalBenefit[0]}`, alignment: 'center' },
      ],
      [{ text: `${contents[8].text[0]}`, alignment: 'left', bold: true, colSpan: 4 }],
      [
        {
          stack: [
            {
              text: `${contents[9].text[0]}`,
              alignment: 'left',
              bold: true,
            },
            {
              text: `             ${contents[9].text[1]}`,
              alignment: 'left',
              bold: false,
              preserveLeadingSpaces: true,
              marginTop: -16,
            },
          ],
          colSpan: 3,
        },
        '',
        '',
        {
          text: '',
        },
      ],
      [
        {
          text: `${contents[10].text[0][0]}`,
          alignment: 'left',
        },

        { text: `${contents[10].benefit[0]}`, alignment: 'center' },
        { text: `${contents[10].totalTimes[0]}`, alignment: 'center' },
        { text: `${findDataOnListByRiderCode(contents[10].totalBenefit[0], planCode)}`, alignment: 'center' },
      ],
      [
        {
          text: `${contents[10].text[1][0]}`,
          alignment: 'left',
        },
        { text: `${contents[10].benefit[1]}`, alignment: 'center' },
        { text: `${contents[10].totalTimes[1]}`, alignment: 'center' },
        { text: `${contents[10].totalBenefit[1]}`, alignment: 'center' },
      ],
      [
        {
          stack: [
            {
              text: `${contents[11].text[0]}`,
              alignment: 'left',
              bold: true,
            },
            {
              text: `             ${contents[11].text[1]}`,
              alignment: 'left',
              bold: false,
              preserveLeadingSpaces: true,
              marginTop: -16,
            },
          ],
        },
        { text: `${contents[11].benefit[0]}`, alignment: 'center' },
        { text: `${contents[11].totalTimes[0]}`, alignment: 'center' },
        { text: `${findDataOnListByRiderCode(contents[11].totalBenefit, planCode)}`, alignment: 'center' },
      ],
      [
        {
          stack: [
            {
              text: `${contents[12].text[0]}`,
              alignment: 'left',
              bold: true,
            },
            {
              text: `             ${contents[12].text[1]}`,
              alignment: 'left',
              bold: false,
              preserveLeadingSpaces: true,
              marginTop: -16,
            },
          ],
        },
        { text: `${contents[12].benefit[0]}`, alignment: 'center' },
        { text: `${contents[12].totalTimes[0]}`, alignment: 'center' },
        { text: `${contents[12].totalBenefit[0]}`, alignment: 'center' },
      ],
      [
        {
          stack: [
            {
              text: `${contents[13].text[0]}`,
              alignment: 'left',
              bold: true,
            },
            {
              text: `             ${contents[13].text[1]}`,
              alignment: 'left',
              bold: false,
              preserveLeadingSpaces: true,
              marginTop: -16,
            },
          ],
        },
        { text: `${contents[13].benefit[0]}`, alignment: 'center' },
        { text: `${contents[13].totalTimes[0]}`, alignment: 'center' },
        { text: `${contents[13].totalBenefit[0]}`, alignment: 'center' },
      ],
      [
        {
          stack: [
            {
              text: `${contents[14].text[0]}`,
              alignment: 'left',
              bold: true,
            },
            {
              text: `             ${contents[14].text[1]}`,
              alignment: 'left',
              bold: false,
              preserveLeadingSpaces: true,
              marginTop: -16,
            },
          ],
        },
        { text: `${contents[14].benefit[0]}`, alignment: 'center' },
        { text: `${contents[14].totalTimes[0]}`, alignment: 'center' },
        { text: `${contents[14].totalBenefit[0]}`, alignment: 'center' },
      ],
      [
        {
          stack: [
            {
              text: `${contents[15].text[0]}`,
              alignment: 'left',
              bold: true,
            },
            {
              text: `             ${contents[15].text[1]}`,
              alignment: 'left',
              bold: false,
              preserveLeadingSpaces: true,
              marginTop: -16,
            },
          ],
        },
        { text: `${contents[15].benefit[0]}`, alignment: 'center' },
        { text: `${contents[15].totalTimes[0]}`, alignment: 'center' },
        { text: `${contents[15].totalBenefit[0]}`, alignment: 'center' },
      ],
      [
        {
          stack: [
            {
              text: `${contents[16].text[0]}`,
              alignment: 'left',
              bold: true,
            },
            {
              text: `             ${contents[16].text[1]}`,
              alignment: 'left',
              bold: false,
              preserveLeadingSpaces: true,
              marginTop: -16,
            },
          ],
        },
        { text: `${findDataOnListByRiderCode(contents[16].benefit, planCode)}`, alignment: 'center' },
        { text: `${contents[16].totalTimes[0]}`, alignment: 'center' },
        {
          stack: [
            { text: `${findDataOnListByRiderCode(contents[16].totalBenefit, planCode)}`, alignment: 'center' },
            { text: `${contents[16].totalBenefitDesc}`, alignment: 'center' },
          ],
        },
      ],
      [
        {
          stack: [
            {
              text: `${contents[17].text[0]}`,
              alignment: 'left',
              bold: true,
            },
            {
              text: `             ${contents[17].text[1]}`,
              alignment: 'left',
              bold: false,
              preserveLeadingSpaces: true,
              marginTop: -16,
            },
          ],
        },
        { text: `${contents[17].benefit[0]}`, alignment: 'center' },
        { text: `${contents[17].totalTimes[0]}`, alignment: 'center' },
        {
          text: [findDataOnListByRiderCode(contents[17].totalBenefit, planCode), ' ', contents[17].totalBenefitDesc],
          alignment: 'center',
          preserveLeadingSpaces: true,
        },
      ],
      [{ text: `${contents[18].text[0]}`, alignment: 'left', bold: true, colSpan: 4 }],
      [
        { text: `${contents[19].text[0]}`, alignment: 'left' },

        {
          text: [findDataOnListByRiderCode(contents[19].totalBenefit, planCode)],
          alignment: 'center',
          colSpan: 3,
        },
      ],
      [
        { text: `${contents[20].text[0]}`, alignment: 'left' },
        { text: `${contents[20].totalBenefit[0]}`, alignment: 'center', colSpan: 3 },
      ],
    ],
  }
}

export const MEXBenefitSection = ({ rider, riderBenefitData, insured }: MEXBenefitProps) => {
  const exceptionNotes = buildExceptionNotes(rider)
  const planCode = getRiderPlanCode(rider, insured.age)
  const { benefits, endorse, headTitle, headSubTitle } = riderBenefitData

  return [
    { text: `${headTitle}`, style: 'sub-title' },
    { text: `${headSubTitle}`, style: 'sub-title' },
    ...buildBenefitSection(benefits, planCode),
    {
      text: '',
      pageBreak: 'after',
    },

    ...buildEndorseSection(endorse, planCode),

    { text: '', pageBreak: 'after' },
    { ...exceptionNotes },
  ]
}

const buildExceptionNotes = (rider) => {
  const exceptionNotes = getMEXExceptionNotes()
  const exceptionTitle = exceptionNotes.title
  const [
    normalCondition,
    firstException,
    secondException,
    thirdException,
    extendExceptionTitle,
    extendExceptionDesc,
  ] = exceptionNotes.exceptions

  return {
    stack: [
      {
        text: `${exceptionTitle}`,
        style: 'sub-title',
      },
      {
        text: `${normalCondition}`,
        style: 'sub-title',
      },
      {
        ol: [
          firstException,
          {
            stack: [
              secondException[0],
              {
                columns: [
                  {
                    ul: secondException[1].slice(0, 4),
                    width: 255,
                  },
                  {
                    ul: secondException[1].slice(4, 8),
                    width: '*',
                  },
                ],
                columnGap: 15,
                margin: [5, 0, 0, 0],
              },
            ],
          },
          {
            stack: [
              thirdException[0],
              thirdException[1],
              {
                table: {
                  body: [
                    ['(1)', { text: `${thirdException[2][0]}`, margin: [-5, 0, 0, 0] }],
                    ['(2)', { text: `${thirdException[2][1]}`, margin: [-5, 0, 0, 0] }],
                  ],
                },
                layout: 'noBorders',
                margin: [0, 0, 0, 0],
              },
            ],
          },
        ],
        margin: [0, 0, 0, 5],
      },
      {
        text: `${extendExceptionTitle}`,
        style: 'sub-title',
      },
      {
        text: `${extendExceptionDesc[0]}`,
        margin: [0, -3, 0, 0],
      },
      {
        text: `${extendExceptionDesc[1]}`,
      },
      {
        ol: [...extendExceptionDesc[2]],
      },
      {
        table: {
          body: [
            ['(1)', { text: `${extendExceptionDesc[3][0]}`, margin: [-5, 0, 0, 0] }],
            ['(2)', { text: `${extendExceptionDesc[3][1]}`, margin: [-5, 0, 0, 0] }],
            ['(3)', { text: `${extendExceptionDesc[3][2]}`, margin: [-5, 0, 0, 0] }],
          ],
        },
        layout: 'noBorders',
        margin: [12, 0, 0, 0],
      },
      {
        text: exceptionNotes.condition,
        style: 'disclaimer',
        margin: [0, 5, 0, 0],
      },
    ],
  }
}
