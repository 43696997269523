import _ from 'lodash'
import { createSectionCreator, createField } from '../utils'

const heightAndtWeightDetails = (app, id) => {
  const isWeightChangedRecently = _.get(app, `${id}.isWeightChangedRecently`)
  const isWeightChanged =
    _.get(isWeightChangedRecently, 'value') === 'increase' || _.get(isWeightChangedRecently, 'value') === 'decrease'

  return _.compact([
    createField(`${id}.height`, [
      {
        label: 'ส่วนสูง (เซนติเมตร)',
        id: `${id}.height`,
        c: 'NumberInput',
        required: true,
        p: {},
      },
    ]),
    createField(`${id}.weight`, [
      {
        label: 'น้ำหนัก (กิโลกรัม)',
        id: `${id}.weight`,
        c: 'NumberInput',
        required: true,
        p: {},
      },
    ]),
    createField(`${id}.isWeightChangedRecently`, [
      {
        id: `${id}.isWeightChangedRecently`,
        c: 'RadioButton',
        required: true,
        label: 'ใน 6 เดือนที่ผ่านมามีการเปลี่ยนแปลงน้ำหนักหรือไม่',
        p: {
          options: [
            {
              text: 'ไม่เปลี่ยนแปลง',
              value: 'no',
            },
            {
              text: 'เพิ่มขึ้น',
              value: 'increase',
            },
            {
              text: 'ลดลง',
              value: 'decrease',
            },
          ],
        },
      },
    ]),
    ...(isWeightChanged ? weightChangedRecentlyFields(id) : []),
  ])
}

const weightChangedRecentlyFields = (id) => [
  createField(`${id}.weightChangedRecently.value`, [
    {
      label: 'น้ำหนัก (กิโลกรัม)',
      id: `${id}.weightChangedRecently.value`,
      c: 'NumberInput',
      required: true,
      p: {
        format: null,
      },
    },
  ]),
  createField(`${id}.weightChangedRecently.reason30Chars`, [
    {
      label: 'สาเหตุ',
      id: `${id}.weightChangedRecently.reason30Chars`,
      c: 'TextInput',
      required: true,
      p: {},
    },
  ]),
]

export default createSectionCreator(heightAndtWeightDetails)
