import { compact, flow, get, getOr, isEmpty, isEqual } from 'lodash/fp'

import { getToggles } from 'quick-quote/feature-toggles'

import { agentRecommenderSection, agentSection, agentShareCaseButton } from './agentSection'

import { bancInfoSection, bancRecommenderSection, bancShareCaseButton } from './bancSection'

import personalSection from './personalSection'
import {
  currentAddressSection,
  officeAddressSection,
  payerAddressSection,
  registeredAddressSection,
  spouseAddressSection,
  spouseOfficeAddressSection,
} from './addressSection'
import beneficiarySection from './beneficiarySection'
import hasOtherPolicySection from './hasOtherPolicySection'
import otherPolicySection from './otherPolicySection'
import FATCASection from './FATCASection'
import documentDisclaimerSection from './documentDisclaimerSection'
import { insuredDocumentSection, payerDocumentSection, tamloInfoMessageSection } from './documentSection'
import { insuredEkycSection, payerEkycSection, ekycSectionFooter, ekycOnDeviceSection } from './eKYCSection'
import { IsRealDevice } from 'e-submission/utils'
import { certifyByAgentSection } from './certifyByAgentSection'
import juvenileParentSection from './juvenileParentSection'
import juvenileSiblingSection from './juvenileSiblingSection'
import CRSSection from './CRSSection'
import CRSDetailSection from './CRSDetailSection'
import foreignerSection from './foreignerSection'

import foreignerSpouseSection from './foreignerSpouseSection'
import auraHealthSection from './auraHealthSection.js'
import { bancDocumentSection } from './suitabilitySection'
import { PolicyDeliverySection } from './policyDeliverySection'
import { InsurancePrivacyConsentSection, MarketingPrivacyConsentSection } from './pdpaConsentSection'
import {
  getQuickQuoteAgentBranchCode,
  getQuickQuoteAgentBranchName,
  getQuickQuoteAgentPartyCode,
  havePayer,
  isAuraSupport,
  is2ndSupport,
  isShowPayerDocSection,
  isShowInsuredDocSection,
  isShowInsuredAndPayerEkycSection,
  isIGrow,
  isQuickQuoteBancAgent,
  isShowAgentPage as isShowAgentPageSelector,
  isSuitablilityForm,
  isJuvenilePurchasing,
  isForeigner,
  getInsuredMarriedStatus,
  isS7,
  isPACategory,
  isNeedEkyc,
  isDopaFailed,
  isEnableEKYCTabs,
  isPayerNeeded,
  isSelectedRemoteSelling,
  isEkycSupport,
  isGIOProduct,
  isRemoteSelling,
  isMRTACategory,
  isAllowCRSForm,
} from './selectors'

import { taxCertConsentForeignerSection, taxCertConsentSection } from './taxCertConsentSection'
import VALUE from 'core/data-model/constants/values'

export default (app) => {
  const isShowBancSectionInAgentPage = isQuickQuoteBancAgent(app)
  const isShowAgentSectionInAgentPage = !isShowBancSectionInAgentPage
  const isShareCaseFallback = !isEmpty(get('agent.recommender', app))
  const isShowAgentPage = isShowAgentPageSelector(app) && !isPACategory(app)
  const isShareCase = getOr(isShareCaseFallback, 'agent.shareCase', app)
  const isShowAura = getToggles().ALLOW_AURA_QUESTIONARE && isAuraSupport(app)
  const isSkipAura = getToggles().ENABLE_SKIP_AURA
  const isShowPolicyDeliveryMethod = getToggles().ENABLE_E_POLICY
  const isShowPDPAConsent = getToggles().ENABLE_PDPA_CONSENT
  const isEnableTamlo = getToggles().ENABLE_TAMLO
  const isShowSuitablilityForm = isSuitablilityForm(app)
  const isShowPayerPage = havePayer(app)
  const isShowPayerDoc = isShowPayerDocSection(app) || isDopaFailed('payer', app)
  const isShowInsuredDoc = isShowInsuredDocSection(app) || isDopaFailed('insured', app)
  const { isShowInsured: isShowInsuredEkyc, isShowPayer: isShowPayerEkyc } = isShowInsuredAndPayerEkycSection(app)
  const isShowIGrow = isIGrow(app)
  const isShowPreviousPolicy = !isS7(app) && !isGIOProduct(app) && !isMRTACategory(app) && !isPACategory(app)
  const isShowFatca = !isPACategory(app)
  const isPayerofficeAddress = isShowIGrow
  const isInsureofficeAddress = !isShowIGrow
  const isShowJuvenilePurchasingForm = getToggles().ENABLE_JUVENILE_PURCHASING_FORM && isJuvenilePurchasing(app)
  const isShowForeignerForm = getToggles().ENABLE_FOREIGNER_FORM && isForeigner(app, 'insured')
  const isAppmanRemoteSelling = isSelectedRemoteSelling(app)
  const isEkycFeature =
    (!isRemoteSelling(app) && getToggles().ENABLE_EKYC_F2F) || getToggles().ENABLE_APPMAN_REMOTE_SELLING
  const condition2ndDocWithEkyc = isAppmanRemoteSelling
    ? is2ndSupport(app) || !isAppmanRemoteSelling
    : is2ndSupport(app)

  const isShowEkyc = isNeedEkyc(app) || isEkycSupport(app) || false
  const isShowEkycOnDevice =
    (getToggles().ENABLE_EKYC_ONDEVICE && getToggles().ENABLE_EKYC_F2F && IsRealDevice) || false
  const isShow2ndDoc = isEkycFeature ? condition2ndDocWithEkyc : true
  const insuredMarriedStatus = getInsuredMarriedStatus(app)
  const insuredMarried = insuredMarriedStatus !== 'S' && insuredMarriedStatus !== undefined
  let isShowThaiConsent = true
  let isShowConsentInfo = false
  const isShowPolicyDeliveryMethodInfo = flow(get('insured.isPolicyDeliveryByEmail.value'), isEqual(true))(app)
  const isShowInsurancePrivacyConsentInfo = get('insurancePrivacyConsent.value')(app)
  const isShowMarketingPrivacyConsentInfo = get('marketingPrivacyConsent.value')(app)
  if (isShowPayerPage) {
    isShowThaiConsent = flow(get('payer.nationality.value'), isEqual('Thai'))(app)
    isShowConsentInfo = flow(get('payer.taxCertConsent.value'), isEqual(true))(app)
  } else {
    isShowThaiConsent = flow(get('insured.nationality.value'), isEqual('Thai'))(app)
    isShowConsentInfo = flow(get('insured.taxCertConsent.value'), isEqual(true))(app)
  }

  const isToggleConsentTH = isShowThaiConsent
  const isToggleConsentFR = !isShowThaiConsent
  const isInsuredToggleConsentTH = isToggleConsentTH && !isShowPayerPage
  const isInsuredToggleConsentFR = isToggleConsentFR && !isShowPayerPage

  const PDPASection = !isShowPDPAConsent
    ? []
    : [
        InsurancePrivacyConsentSection(
          `insurance-privacy-consent-${isShowInsurancePrivacyConsentInfo}`,
          'ความยินยอมในการเก็บ ใช้และเปิดเผยข้อมูลส่วนบุคคลของผู้ขอเอาประกันภัย'
        ),
        MarketingPrivacyConsentSection(
          `marketing-privacy-consent-${isShowMarketingPrivacyConsentInfo}`,
          'การขอรับข้อมูลข่าวสาร โปรโมชั่น หรือแคมเปญจากบริษัทฯ'
        ),
      ]

  const insuredPDPASection = isShowPayerPage ? [] : [...PDPASection]
  const payerPDPASection = !isShowPayerPage ? [] : [...PDPASection]

  const isInvestment = get('quickQuote.selectedDisplayProduct.category')(app) === VALUE.INVESTMENT

  const isInsuredEnableEKYC = isEnableEKYCTabs(app, 'insured')
  const isPayerEnableEKYC = isPayerNeeded(app) ? isEnableEKYCTabs(app, 'payer') : true
  const isEnableEKYCSteps = isInsuredEnableEKYC && isPayerEnableEKYC
  return compact([
    isShowAgentPage && {
      id: 'agent',
      prefix: 'agent',
      title: 'ตัวแทน/นายหน้าประกันชีวิต',
      label: 'ตัวแทน',
      sections: isShowAgentSectionInAgentPage
        ? compact([
            agentSection('agent', null, null, `หน่วย ${getQuickQuoteAgentPartyCode(app) || '-'}`),
            isShareCase ? agentRecommenderSection('recommender', 'นายหน้า') : agentShareCaseButton('share-case'),
          ])
        : compact([
            bancInfoSection(
              'agent-info',
              null,
              null,
              `สาขา ${getQuickQuoteAgentBranchCode(app) || '-'} - ${getQuickQuoteAgentBranchName(app) || '-'}`
            ),
            isShareCase ? bancRecommenderSection('recommender', 'ผู้แนะนำบริการ') : bancShareCaseButton('share-case'),
          ]),
    },
    {
      id: 'insured',
      prefix: 'insured',
      title: 'ข้อมูลผู้เอาประกันภัย',
      label: 'ผู้ขอเอาประกัน',
      sections: compact([
        personalSection('personal'),
        currentAddressSection(
          'current-address',
          'ที่อยู่ปัจจุบัน (ที่อยู่สำหรับติดต่อ และส่งเอกสารสำคัญ)',
          null,
          'ที่อยู่'
        ),
        registeredAddressSection('registered-address', 'ที่อยู่ตามทะเบียนบ้าน'),
        isInsureofficeAddress &&
          officeAddressSection(
            'office-address',
            getToggles().ENABLE_OFFICE_NAME_ILP_PRODUCT && isInvestment ? 'ที่ทำงาน' : 'ที่อยู่ที่ทำงาน'
          ),
        isInsuredToggleConsentFR &&
          taxCertConsentForeignerSection(
            `tax-cert-consent-en-${isShowConsentInfo}`,
            'การใช้สิทธิขอยกเว้นภาษีเงินได้ตามกฏหมายว่าด้วยภาษีอากร'
          ),
        isInsuredToggleConsentTH &&
          taxCertConsentSection(
            `tax-cert-consent-th-${isShowConsentInfo}`,
            'การใช้สิทธิขอยกเว้นภาษีเงินได้ตามกฏหมายว่าด้วยภาษีอากร'
          ),
        isShowPolicyDeliveryMethod &&
          PolicyDeliverySection(`policy-delivery-${isShowPolicyDeliveryMethodInfo}`, 'การขอรับกรมธรรม์อิเล็กทรอนิกส์'),
        ...insuredPDPASection,
        isAllowCRSForm(app) && CRSSection('CRSForm', 'เอกสารรับรองตนเอง (CRS)'),
        isAllowCRSForm(app) && CRSDetailSection('CRSForm'),
      ]),
    },
    isShowForeignerForm && {
      id: 'foreigner',
      prefix: 'insured',
      title: 'แบบสอบถามชาวต่างชาติ',
      label: 'แบบสอบถามชาวต่างชาติ',
      sections: compact([
        foreignerSection('foreigner'),
        insuredMarried && foreignerSpouseSection('foreigner.spouse', 'รายละเอียดคู่สมรส'),
        insuredMarried && spouseAddressSection('spouse-address', 'ที่อยู่ปัจจุบันของคู่สมรส'),
        insuredMarried && spouseOfficeAddressSection('foreigner.spouse-office-address', 'ที่อยู่ที่ทำงานของคู่สมรส'),
      ]),
    },
    isShowPayerPage && {
      id: 'owner',
      prefix: 'payer',
      title: 'ข้อมูลผู้ชำระเบี้ย',
      label: 'ผู้ชำระเบี้ย',
      sections: compact([
        personalSection('personal'),
        payerAddressSection('payer-address', 'ที่อยู่ปัจจุบันของผู้ชำระเบี้ย'),
        isPayerofficeAddress && officeAddressSection('office-address', 'ที่อยู่ที่ทำงาน'),
        isToggleConsentFR &&
          taxCertConsentForeignerSection(
            `tax-cert-consent-en-${isShowConsentInfo}`,
            'การใช้สิทธิขอยกเว้นภาษีเงินได้ตามกฏหมายว่าด้วยภาษีอากร'
          ),
        isToggleConsentTH &&
          taxCertConsentSection(
            `tax-cert-consent-th-${isShowConsentInfo}`,
            'การใช้สิทธิขอยกเว้นภาษีเงินได้ตามกฏหมายว่าด้วยภาษีอากร'
          ),
        ...payerPDPASection,
      ]),
    },
    {
      id: 'beneficiary',
      prefix: 'beneficiary',
      title: 'ผู้รับประโยชน์',
      label: 'ผู้รับประโยชน์',
      sections: [beneficiarySection('beneficiary', 'ผู้รับประโยชน์คนที่')],
    },
    isShowJuvenilePurchasingForm && {
      id: 'juvenile-purchasing',
      prefix: 'insured',
      title: 'แบบประกันสุขภาพผู้เยาว์',
      label: 'แบบประกันสุขภาพผู้เยาว์',
      sections: [
        juvenileParentSection('juvenile-purchasing', 'ชื่อและนามสกุลของ'),
        juvenileSiblingSection('juvenile-purchasing'),
      ],
    },
    isShowAura &&
      !isSkipAura && {
        id: 'aura-health',
        prefix: 'insured',
        title: 'ประวัติสุขภาพ Aura',
        label: 'ประวัติสุขภาพ Aura',
        sections: [auraHealthSection('aura-health-detail', 'ประวัติสุขภาพ Aura')],
      },
    !isShowAura &&
      isShowPreviousPolicy && {
        id: 'previous-policy',
        prefix: 'insured',
        title: 'ประวัติการทำประกัน',
        label: 'ประวัติ',
        sections: [hasOtherPolicySection('has-other-policy'), otherPolicySection('other-policy', 'กรมธรรม์ที่')],
      },
    !isShowAura &&
      isShowFatca && {
        id: 'FATCA',
        prefix: 'FATCA',
        title: 'กฎหมาย FATCA',
        label: 'FATCA',
        sections: [FATCASection('FATCA')],
      },
    isShowEkyc && {
      id: 'ekyc',
      prefix: 'ekyc',
      title: 'ยืนยันตัวตน',
      label: 'ยืนยันตัวตน',
      disabled: !isEnableEKYCSteps,
      sections: compact([
        isShowEkycOnDevice &&
          isShowAgentSectionInAgentPage &&
          !isAppmanRemoteSelling &&
          ekycOnDeviceSection('ekyc', null, null, 'กรุณาเลือกช่องทางในการยืนยันตัวตน'),
        isShowInsuredEkyc &&
          insuredEkycSection(
            'ekyc',
            null,
            null,
            'ยืนยันตัวตนทางอิเล็กทรอนิกส์ (eKYC)',
            'form-section__ekyc',
            'cameraEkyc'
          ),
        isShowPayerEkyc &&
          payerEkycSection(
            'ekyc',
            null,
            null,
            !isShowInsuredEkyc ? 'ยืนยันตัวตนทางอิเล็กทรอนิกส์ (eKYC)' : null,
            'form-section__ekyc',
            'cameraEkyc'
          ),
        ekycSectionFooter(),
      ]),
    },
    isShow2ndDoc && {
      id: 'document',
      prefix: 'document',
      title: 'เอกสาร',
      label: 'เอกสาร',
      disabled: !isEnableEKYCSteps,
      sections: compact([
        documentDisclaimerSection('document'),
        // [GIFT comment code detail] ^ show image and description
        isShowInsuredDoc &&
          insuredDocumentSection(
            'insured-document',
            'เอกสาร ผู้เอาประกันภัย',
            null,
            null,
            'form-section__document__insured'
          ),
        // [GIFT comment code detail] ^ show additional document upload section for insured
        !isShowInsuredDoc && isShowPayerDoc
          ? payerDocumentSection('payer-document', 'เอกสาร ผู้ชำระเบี้ย', null, null, 'form-section__document__insured')
          : isShowPayerDoc && payerDocumentSection('payer-document', 'เอกสาร ผู้ชำระเบี้ย'),
        // [GIFT comment code detail] ^ show additional document upload section for payer (in case insured is child ** under 15 yo. **)
        isShowSuitablilityForm && bancDocumentSection('banc-suitability', 'เอกสาร ตัวแทนธนาคาร'),
        isEnableTamlo && tamloInfoMessageSection('document'),
        isEnableTamlo && certifyByAgentSection('document'),
      ]),
      isDocumentPage: true,
    },
  ])
}
