//@flow
import type { InsuredProps } from 'core/service/pdf-generation/products/common/types'
import type { Rider } from 'core/data-model/rider'
import type { CashFlow } from 'core/service/investment/cash-flow/cash-flow'
import type { DisplayProduct } from 'core/service'

import { BenefitTableExampleExtendPage } from 'core/service/pdf-generation/products/investment/components'

export type ExpectedReturn = '-1' | '2' | '5'

type BenefitTableExampleGroupProps = {
  policyType: string,
  sumAssured: number,
  calculatedCoveragePeriod: number,
  calculatedPaymentPeriod: number,
  insured: InsuredProps,
  regularPremium: number,
  regularTopupPremium: number,
  coverageDeath: number,
  paymentModeLabel: string,
  cashFlowByExpectedReturn: { [ExpectedReturn]: CashFlow[] },
}

type BenefitTableExampleGroupByType = {
  displayProduct: DisplayProduct,
  commonCashFlow: BenefitTableExampleGroupProps[],
  withInflationCashFlow: BenefitTableExampleGroupProps[],
  riders: Rider[],
}

export const BenefitTableExampleGroup = (props: BenefitTableExampleGroupByType) => {
  const { displayProduct, commonCashFlow, withInflationCashFlow } = props
  const EXPECTED_RETURN_RATES: ExpectedReturn[] = Object.keys(commonCashFlow.cashFlowByExpectedReturn).sort()
  const table = []
  EXPECTED_RETURN_RATES.forEach((rate, index) => {
    const cashFlows = commonCashFlow.cashFlowByExpectedReturn[rate]
    const inflationRate = 0
    const caseNumber = index + 1
    table.push(
      BenefitTableExampleExtendPage(
        {
          ...commonCashFlow,
          displayProduct,
          cashFlows,
          cashFlowByExpectedReturn: commonCashFlow.cashFlowByExpectedReturn,
        },
        caseNumber,
        parseInt(rate),
        parseInt(inflationRate)
      )
    )
  })

  if (withInflationCashFlow) {
    const EXPECTED_RETURN_RATES_WITH_INFLATION: ExpectedReturn[] = Object.keys(
      withInflationCashFlow.cashFlowByExpectedReturn
    ).sort()
    EXPECTED_RETURN_RATES_WITH_INFLATION.forEach((rate, index) => {
      const cashFlows = withInflationCashFlow.cashFlowByExpectedReturn[rate]
      // Continue the case number
      const inflationRates = Object.keys(cashFlows)
      const caseNumber = EXPECTED_RETURN_RATES.length + index + 1
      inflationRates.forEach((inflationRate) => {
        table.push(
          BenefitTableExampleExtendPage(
            {
              ...withInflationCashFlow,
              displayProduct,
              cashFlows: cashFlows[inflationRate],
              cashFlowByExpectedReturn: cashFlows,
            },
            caseNumber,
            parseInt(rate),
            parseInt(inflationRate)
          )
        )
      })
    })
  }

  return table
}
