// @flow

import type { Dispatch } from 'redux'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import type { AppState } from 'quick-quote/reducers'
import { validateSelectPolicyOwner } from 'quick-quote/product-s7cc/actions'
import {
  getPolicyOwnerOptions,
  getPolicyOwner,
  getErrorSelectPolicyOwnerValidateMsg,
} from 'quick-quote/product-s7cc/selectors'
import PolicyOwnerSelection from './policy-owner-selection'

const PolicyOwnerSelectionContainer = connect(
  (state: AppState) => ({
    policyownerOptions: getPolicyOwnerOptions(),
    policyowner: getPolicyOwner(state),
    errors: getErrorSelectPolicyOwnerValidateMsg(state),
  }),
  (dispatch: Dispatch<*>) => ({
    validateSelectPolicyOwner: bindActionCreators(validateSelectPolicyOwner, dispatch),
  })
)(PolicyOwnerSelection)

const SelectPolicyOwner = () => {
  // if (!canProceedToCoveragePlan) {
  //   return <Redirect to={PATH.PRODUCT_SELECTION} push />
  // }
  return (
    <div>
      <div className={'personal-information-breadcrumb'}>
        <p>เลือกผู้มีสิทธิ์ในการขอเอาประกัน</p>
      </div>
      <div id="information">
        <div className="content">
          <PolicyOwnerSelectionContainer />
        </div>
      </div>
    </div>
  )
}

export default SelectPolicyOwner
