import {
  at,
  defaultTo,
  eq,
  every,
  filter,
  findIndex,
  flatMap,
  flatten,
  flow,
  get,
  getOr,
  has,
  includes,
  isEmpty,
  isEqual,
  isNil,
  isString,
  join,
  keys,
  map,
  negate,
  over,
  overEvery,
  overSome,
  reject,
  set,
  size,
  some,
  split,
  startsWith,
  sum,
  sumBy,
  toFinite,
  values,
  isUndefined,
  last,
} from 'lodash/fp'
import { createSelector } from 'reselect'
import _ from 'lodash'
import Decimal from 'decimal.js'
import VALUES from 'core/data-model/constants/values'
import { getAppConfig } from 'deploy-env/app-config'

import {
  DOCUMENT_TYPE,
  PAYMENT_TYPE,
  SIGNATURE_TYPE,
  CREDIT_CARD_RELATIONSHIP_LIST,
  ECBR_PAYER_TYPE,
} from 'e-submission/domain/data-model/constants'

import { getToggles } from 'quick-quote/feature-toggles'
import { isMonthly } from 'core/service/basic-plan/validation'
import { isWealthHubStaff } from 'identity/fse-branch/services'

import { formatNumber, roundDown } from 'core/service/lib/number-format'
import { isFinishedPayLaterSelection, isSectionValid } from 'e-submission/apps/selectors'
import { PayerSection } from 'e-submission/components/Form/Payment/PayerSection'

export const getQuickQuotePdfProps = get('quickQuote.bi')

export const isForeigner = (app, owner) => {
  const nationality = get(`${owner}.nationality.value`)(app)
  const isForeignerFlag = nationality !== 'Thai'
  return isForeignerFlag
}

export const isNeedDocCRS = (app, insuredOrPayer) => {
  const insuredAge = get('quickQuote.insured.age.value', app)
  const payerRelation = get('quickQuote.insured.payer.relation', app)
  switch (insuredOrPayer) {
    case 'insured':
      return has('insured.CRS', app)
    case 'payer':
      return has('insured.CRS', app) && insuredAge < 20 && payerRelation !== 'SPOUSE'
    default:
      return false
  }
}

export const isSelectedRemoteSelling = (app) =>
  getToggles().ENABLE_APPMAN_REMOTE_SELLING && get('quickQuote.selectedRemoteSelling', app)

export const isNeedEkyc = (app) => {
  return isSelectedRemoteSelling(app) && (insurerDoesEkyc(app) || payerDoesEkyc(app))
}

export const isProprietorNeedEkyc = (app, insuredOrPayer) => {
  if (insuredOrPayer === 'insured') return insurerDoesEkyc(app)

  if (insuredOrPayer === 'payer') return payerDoesEkyc(app)
  return false
}

export const getSfReference = (app) => {
  return get('sfReference')(app)
}

export const insurerDoesEkyc = (app) => {
  const insuredAge = getQuickQuoteInsuredAge(app)?.value
  const isForeignerInsured = isForeigner(app, 'insured')
  const isInsuredNoID = get(`insured.idType.value`)(app) !== 'CITIZEN_ID'
  const isKid = insuredAge >= 7 && insuredAge <= 19
  const isAdult = insuredAge > 19
  const isThaiInsured = !isForeignerInsured
  const insuredHasIdCard = !isInsuredNoID
  const case1 = isAdult && insuredHasIdCard
  const case2 = isKid && isThaiInsured && insuredHasIdCard
  return case1 || case2
}
export const payerDoesEkyc = (app) => {
  const insuredAge = getQuickQuoteInsuredAge(app)?.value
  const isForeignerPayer = isForeigner(app, 'payer')
  const isForeignerInsured = isForeigner(app, 'insured')
  const isInsuredNoID = get(`insured.idType.value`)(app) !== 'CITIZEN_ID'
  const isPayerNoID = get(`payer.idType.value`)(app) !== 'CITIZEN_ID'
  const isMinor = insuredAge < 7
  const isAdult = insuredAge > 19
  const isKid = insuredAge >= 7 && insuredAge <= 19
  const isThaiPayer = !isForeignerPayer
  const isThaiInsured = !isForeignerInsured
  const payerHasIdCard = !isPayerNoID
  const insuredHasIdCard = !isInsuredNoID
  const case1 = isMinor && (isForeignerInsured || isThaiInsured) && isThaiPayer && payerHasIdCard
  const case2 = isKid && isThaiInsured && (isInsuredNoID || insuredHasIdCard) && isThaiPayer && payerHasIdCard
  const case3 = isKid && isForeignerInsured && isThaiPayer && payerHasIdCard
  const case4 = isAdult && isPayerNeeded(app) && payerHasIdCard
  return case1 || case2 || case3 || case4
}

export const showInsuredSection = (app) => {
  const insuredAge = getQuickQuoteInsuredAge(app)?.value
  const isForeignerInsured = isForeigner(app, 'insured')
  const isInsuredNoID = get(`insured.idType.value`)(app) !== 'CITIZEN_ID'

  return (
    (insuredAge > 19 && (isForeignerInsured || (!isForeignerInsured && isInsuredNoID))) ||
    (insuredAge >= 7 && insuredAge <= 19 && (isForeignerInsured || (!isForeignerInsured && isInsuredNoID)))
  )
}

export const showPayerSection = (app) => {
  const insuredAge = getQuickQuoteInsuredAge(app)?.value
  const isForeignerPayer = isForeigner(app, 'payer')
  const isPayerNoID = get(`payer.idType.value`)(app) !== 'CITIZEN_ID'

  return (
    (insuredAge < 7 && (isForeignerPayer || (!isForeignerPayer && isPayerNoID))) ||
    (insuredAge >= 7 && insuredAge <= 19 && (isForeignerPayer || (!isForeignerPayer && isPayerNoID)))
  )
}

export const getInsuredMarriedStatus = (app, owner) => get('insured.maritalStatus.value')(app)
export const getInsuredDCAProps = (app) => ({
  firstName: get('insured.firstName')(app),
  lastName: get('insured.lastName')(app),
  title: get('insured.title')(app),
})

export const getInsuredPdfProps = (app) => ({
  firstName: get('insured.firstName')(app),
  lastName: get('insured.lastName')(app),
  mobile: get('insured.mobileNumber')(app),
  title: get('insured.title.text')(app),
})

export const getInsuredOnboardingProps = (app) => ({
  firstName: get('insured.firstName')(app),
  lastName: get('insured.lastName')(app),
  title: get('insured.title')(app),
})

export const hasWealthHubSignPage = (app) =>
  isWealthHubStaff({
    category: _.get(app, 'quickQuote.biProps.productCategory'),
    rpulFundAlloc: _.get(app, 'quickQuote.biProps.allocations'),
    agentType: _.get(app, 'quickQuote.biProps.profile.agent_type'),
  })

export const isPayerNeeded = (app) => get('quickQuote.insured.payerNeeded')(app)

export const getPayerIfNeeded = (app) => {
  const hasPayer = isPayerNeeded(app)
  const payerInfo = hasPayer
    ? {
        firstName: get('payer.firstName')(app),
        lastName: get('payer.lastName')(app),
        title: get('payer.title')(app),
        citizenId: get('payer.idNo.citizenId')(app),
        passportNumber: get('payer.idNo.passport')(app),
        phoneNumber: get('payer.mobileNumber')(app),
        email: get('payer.email')(app),
        gender: getQuickQuotePayerGender(app),
      }
    : undefined
  return payerInfo
}
export const getInsuredData = (app) => {
  return {
    firstName: get('insured.firstName')(app),
    lastName: get('insured.lastName')(app),
    title: get('insured.title')(app),
    citizenId: get('insured.idNo.citizenId')(app),
    passportNumber: get('insured.idNo.passport')(app),
    phoneNumber: get('insured.mobileNumber')(app),
    email: get('insured.email')(app),
    gender: getQuickQuoteInsuredGender(app),
  }
}
export const getPayerPdfProps = (payerNeeded, app) => {
  if (payerNeeded) {
    return {
      firstName: get('payer.firstName')(app),
      lastName: get('payer.lastName')(app),
      age: get('quickQuote.insured.payer.age')(app),
      relationship: get('payer.relationshipToInsured.text')(app),
    }
  } else {
    return {}
  }
}

export const getEsubPdfProps = (app) => {
  const insured = getInsuredPdfProps(app)

  const payerNeeded = isPayerNeeded(app)
  const payer = getPayerPdfProps(payerNeeded, app)

  return { insured, payer }
}

export const getRemoteSellingState = get('remoteSelling')
export const getRemoteSellingInsured = get('remoteSelling.insured')
export const getRemoteSellingPayer = get('remoteSelling.payer')
export const getRemoteSellingDopaResult = (insuredOrPayer, app) =>
  get(`remoteSelling.${insuredOrPayer}.dopaResult`, app)

export const getUploadedDocuments = (key, app) => {
  return getOr([], `remoteSelling.${key}.documentIds`, app)
}

export const getQuickQuoteIsOtherOccupationSelected = get('quickQuote.insured.otherOccupation.isSelected')
export const getQuickQuoteInsuredMainOccupationCode = get('quickQuote.insured.occupation.code')
export const getQuickQuoteInsuredMainNatureOfDutyCode = get('quickQuote.insured.occupation.natureOfDuty.code')
export const getQuickQuoteInsuredOtherOccupationCode = get('quickQuote.insured.otherOccupation.code')
export const getQuickQuoteInsuredOtherNatureOfDutyCode = get('quickQuote.insured.otherOccupation.natureOfDuty.code')
export const getQuickQuoteIsPayerOtherOccupationSelected = get('quickQuote.insured.payer.otherOccupation.isSelected')
export const getQuickQuotePayerMainOccupationCode = get('quickQuote.insured.payer.occupation.code')
export const getQuickQuotePayerMainNatureOfDutyCode = get('quickQuote.insured.payer.occupation.natureOfDuty.code')
export const getQuickQuotePayerOtherOccupationCode = get('quickQuote.insured.payer.otherOccupation.code')
export const getQuickQuotePayerOtherNatureOfDutyCode = get('quickQuote.insured.payer.otherOccupation.natureOfDuty.code')
export const getQuickQuoteNatureOfDutyCodeOf = (insuredOrPayer) =>
  createSelector(getQuickQuoteInsuredMainNatureOfDutyCode, getQuickQuotePayerMainNatureOfDutyCode, (insured, payer) =>
    insuredOrPayer === 'payer' ? payer : insured
  )
export const getQuickQuoteHavePayer = get('quickQuote.insured.payerNeeded')
export const getQuickQuoteInsuredGender = get('quickQuote.insured.gender')
export const getQuickQuotePayerGender = get('quickQuote.insured.payer.gender')
export const getQuickQuotePayerRelation = get('quickQuote.insured.payer.relation')
export const getQuickQuoteInsuredAge = get('quickQuote.insured.age')
export const getQuickQuotePayerAge = get('quickQuote.insured.payer.age')
export const getQuickQuoteProductType = get('quickQuote.selectedDisplayProduct.type')
export const getQuickQuoteProduct = get('quickQuote.selectedDisplayProduct')
export const getQuickQuoteProductName = get('quickQuote.selectedDisplayProduct.name')
export const getQuickQuoteProductNameThai = get('quickQuote.selectedDisplayProduct.nameThai')
export const getQuickQuoteProductDisplayName = get('quickQuote.selectedDisplayProduct.displayName')
export const getQuickQuoteProductCode = get('quickQuote.selectedDisplayProduct.code')
export const getQuickQuoteMaturityPolicyOwner = get('quickQuote.insured.maturity.policyOwner')
export const getQuickQuoteMaturityIDCard = get('quickQuote.insured.maturity.idCard.validIDCard')
export const getProductsExpiryDate = flow(get('product'), get('[0].expiryDate'))
export const getProductDisplayFlag = get('isProductDisplay')
export const getPayer = get('payer')
export const getInsured = get('insured')
export const getQuickQuoteProductBasicPlanCode = get('quickQuote.selectedDisplayProduct.basicPlanCode')
export const getQuickQuoteTotalPremium = get('quickQuote.coveragePlan.totalPremium.value')
export const getQuickQuoteProductCoveragePlan = get('quickQuote.biProps.calculatedCoveragePeriod')
export const getQuickQuoteProductPaymentPeriod = get('quickQuote.selectedDisplayProduct.paymentPeriod.value')
export const getSelectedRiders = get('quickQuote.biProps.riders')
export const getQuickQuoteSelectedProductLicenses = get('quickQuote.selectedDisplayProduct.licenses')

export const getVideoConsentRiders = (app) => {
  const riders = getSelectedRiders(app)
  return riders.map((item) => ({
    riderCode: item.code,
    riderPremium: item.premium,
    riderSum: item.sumAssured,
    riderName: `สัญญาเพิ่มเติม${item.description}`,
  }))
}
export const getVideoConsentData = (app) => {
  const firstName = getOr(undefined, 'insured.firstName', app)
  const lastName = getOr(undefined, 'insured.lastName', app)
  return {
    customerInfo: {
      periodInfo: {
        protectionPeriod: getQuickQuoteProductCoveragePlan(app).toString(),
        paymentPeriod: getQuickQuoteProductPaymentPeriod(app).toString(),
        installment: getSelectedModelFactorLabel(app) || getQuickQuotePaymentModelLabel(app),
      },
      firstName,
      lastName,
      planInfo: {
        productName: getQuickQuoteProductName(app),
        riderPlanNames: getVideoConsentRiders(app),
      },
    },
  }
}
export const isEnableEKYCTabs = (app, insuredOrPayer) => {
  if (isNeedEkyc(app) && isSelectedRemoteSelling(app)) {
    const firstName = get(`${insuredOrPayer}.firstName`)(app)
    const lastName = get(`${insuredOrPayer}.lastName`)(app)
    const idType = get(`${insuredOrPayer}.idType.value`)(app)
    let idNo = undefined

    const isNotThai = isForeigner(app, insuredOrPayer)
    if (!isNotThai) idNo = get(`${insuredOrPayer}.idNo.citizenId`)(app)
    if (isNotThai) idNo = get(`${insuredOrPayer}.idNo.passport`)(app)
    const title = get(`${insuredOrPayer}.title.value`)(app)
    const nationality = get(`${insuredOrPayer}.nationality.value`)(app)
    return firstName && lastName && idType && idNo && title && nationality
  }
  return true
}
export const getQuickQuoteSumAssured = (app) => {
  if (isInvestmentCategory(app)) {
    return get('quickQuote.coveragePlan.selectedBasicPlan.sumAssured', app)
  } else {
    return get('quickQuote.coveragePlan.selectedBasicPlan.sumAssured.value', app)
  }
}

export const getCybsRetry = get('cybsRetry')

export const getQuickQuoteProductCategory = get('quickQuote.selectedDisplayProduct.category')

export const getQuickQuoteModelFactor = get('quickQuote.coveragePlan.selectedModelFactorID')

export const getQuickQuoteSelectedCampaignPlan = get('quickQuote.selectedDisplayProduct.selectedCampaignPlan')

export const getQuickQuoteCoverageSelectedPlan = get('quickQuote.coveragePlan.selectedPlan')

export const getQuickQuoteLumpSumTopUp = get('quickQuote.coveragePlan.selectedBasicPlan.lumpSumTopUp')

export const isInvestmentCategory = flow(getQuickQuoteProductCategory, isEqual('INVESTMENT'))

export const isMRTACategory = flow(getQuickQuoteProductCategory, isEqual('MRTA'))

export const isQuickQuoteFamilyDiscount = flow(
  get('quickQuote.coveragePlan.riders["ESCI"].selectedPlan.group'),
  isEqual(VALUES.RIDER_OPTION_GROUP_DISCOUNT)
)

export const isQuickQuoteRequireTypeOfBusiness = (insuredOrPayer) =>
  createSelector(
    getQuickQuoteNatureOfDutyCodeOf(insuredOrPayer),
    (natureOfDuty) =>
      insuredOrPayer === 'insured' &&
      includes(natureOfDuty)(['ND0510', 'ND0511', 'ND0635', 'ND0636', 'ND0643', 'ND0644'])
  )
export const isQuickQuoteModelFactorMonthly = flow(getQuickQuoteModelFactor, isMonthly)

export const getQuickQuoteRiders = get('quickQuote.coveragePlan.riders')
export const getQuickQuoteSelectedRiders = flow(
  getQuickQuoteRiders,
  values,
  filter(['isSelected', true]),
  filter(['isSelectable', true])
)
export const getQuickQuoteAgentAdvisorCode = flow(get('quickQuote.agent.advisorCode'), split('-'), get('[2]'))
export const getQuickQuoteAgentFirstName = get('quickQuote.agent.firstName')
export const getQuickQuoteAgentLastName = get('quickQuote.agent.lastName')
export const getQuickQuoteAgentFullName = get('quickQuote.agent.fullName')
export const getQuickQuoteAgentBranchCode = get('quickQuote.agent.branchCode')
export const getQuickQuoteAgentBranchName = get('quickQuote.agent.branchName')
export const getQuickQuoteAgentPartyCode = get('quickQuote.agent.partyCode')
export const isQuickQuoteBancAgent = get('quickQuote.agent.isBanc')

export const isShowAgentPage = flow(get('quickQuote.agent.type.code'), negate(overSome([eq(VALUES.AGENT_TYPE_FSA)])))

export const isJuvenile = flow(getQuickQuoteInsuredAge, get('value'), (age) => age < 18)
export const isJuvenileButNotKid = flow(getQuickQuoteInsuredAge, get('value'), (age) => age < 16)
export const isKid = flow(getQuickQuoteInsuredAge, get('value'), (age) => age <= 15)
export const isJuvenileKid = flow(getQuickQuoteInsuredAge, get('value'), (age) => age < 15)

export const getSkippedPayerEkyc = flow(get('ekycInfo.payer.skipped'))
export const getSkippedInsuredEkyc = flow(get('ekycInfo.insured.skipped'))
export const getInsuredDopaRequestFailed = flow(
  getOr(false, 'ekycInfo.insured.verificationDetail.dopaResult.dopaRequestFailed')
)
export const getPayerDopaRequestFailed = flow(
  getOr(false, 'ekycInfo.payer.verificationDetail.dopaResult.dopaRequestFailed')
)

export const getInsuredOrPayerDopaResults = (insuredOrPayer, app) =>
  get(`ekycInfo.${insuredOrPayer}.verificationDetail.dopaResult`, app)
export const getInsuredOrPayerDopaResultVerified = (insuredOrPayer, app) =>
  get(`ekycInfo.${insuredOrPayer}.verificationDetail.dopaResult.verified`, app)
export const getInsuredOrPayerDopaRequestFailed = (insuredOrPayer, app) =>
  get(`ekycInfo.${insuredOrPayer}.verificationDetail.dopaResult.dopaRequestFailed`, app)

export const isDopaResultsResponse = (insuredOrPayer, app) => {
  let isShowValidation = false

  if (getInsuredOrPayerDopaResults(insuredOrPayer, app)) {
    // Have EKYC DOPA RESULTS
    if (!_.isNil(getInsuredOrPayerDopaResultVerified(insuredOrPayer, app))) {
      if (getInsuredOrPayerDopaResultVerified(insuredOrPayer, app) === false) {
        isShowValidation = true
      }
    }

    if (!_.isNil(getInsuredOrPayerDopaRequestFailed(insuredOrPayer, app))) {
      if (getInsuredOrPayerDopaRequestFailed(insuredOrPayer, app) === true) {
        isShowValidation = true
      }
    }
  } else {
    //  NO HAVE EKYC RESULTS
    isShowValidation = true
  }
  return isShowValidation
}

export const isDopaVerifieldAndRequestFailed = () => {
  if (
    _.isNil(getInsuredOrPayerDopaResultVerified(insuredOrPayer, app)) ||
    _.isNil(getInsuredOrPayerDopaRequestFailed(insuredOrPayer, app))
  ) {
    return true
  }
}

export const getInsuredDopaResult = flow(get('ekycInfo.insured.verificationDetail.dopaResult.verified'))
export const getPayerDopaResult = flow(get('ekycInfo.payer.verificationDetail.dopaResult.verified'))
export const getInsuredProprietorId = flow(getOr(false, 'ekycInfo.insured.proprietorId'))
export const getPayerProprietorId = flow(getOr(false, 'ekycInfo.payer.proprietorId'))

export const isDataConfirmed = flow(getOr(false, `ekycInfo.isDataConfirmed`))

export const isOnDeviceEKYC = flow(getOr(undefined, `ekycInfo.isOnDevice.value`))

export const getAppStatus = get('status')
export const getInsuredUploadDocStatus = get('insuredUploadDocStatus')
export const getPayerUploadDocStatus = get('payerUploadDocStatus')
export const getAppId = get('_id')
export const getPolicyId = get('policyId')
export const getRecurringPayment = getOr({}, 'ATP')
export const getPayment = getOr({}, 'payment')

export const getIsOnOtp = get('isOnOtp')

// export const hasATP = getOr(false, 'payment.hasATP')
export const hasATP = isQuickQuoteModelFactorMonthly
export const disableATP = isQuickQuoteModelFactorMonthly
export const getATPBank = get('ATP.bank.value')
export const isATPBankWasKTB = flow(get('ATP.bank.value'), isEqual('006'))

export const isATPRelationWasSelf = flow(get('ATP.relations.value'), isEqual('SELF'))

export const isATPSubmitted = flow(get('ATP.submitted'), isEqual(true))
export const isATPPaid = flow(get('ATP.paid'), isEqual(true))
export const isCheckStatusEDA = flow(get('ATP.checkStatusEDA'), isEqual(true))

export const isFormTypeEDA = flow(get('ATP.typeDA'), isEqual('ONLINE'))
export const isFormTypeDA = flow(get('ATP.typeDA'), isEqual('FORM'))

export const isAcceptConsentDA = flow(get('consentDA'), isEqual(true))
export const isEDAConsent = flow(get('isEDAConsent'), isEqual(true))

export const getDCA = get('DCA')

export const isBumnanReadyProductCategory = flow(getQuickQuoteProductCategory, isEqual(VALUES.BUMNAN_READY))

export const isCashDividend = (app) => {
  return getToggles().ENABLE_DCA_CASH_DIVIDEND ? get('insured.dividend.value', app) === 'cash' : false
}

export const getDCASelected = (app) => {
  if (isBumnanReadyProductCategory(app) || isCashDividend(app)) {
    return true
  } else {
    return get('DCA.selected.value', app)
  }
}

export const isUndefinedDCA = flow(getDCASelected, isUndefined)

export const isDCASelected = flow(getDCASelected, isEqual(true))

export const isDCASubmitted = flow(get('DCA.status'), isEqual(VALUES.DCA_STATUS.SUBMITTED))

export const isDCASigned = flow(get('DCA.status'), isEqual(VALUES.DCA_STATUS.SIGNED))

export const isDCAUnsigned = flow(get('DCA.status'), isEqual(VALUES.DCA_STATUS.UNSIGHED))

export const isDCAInformation = flow(get('DCA.status'), isEqual(VALUES.DCA_STATUS.INFORMATION))

export const isCloneATPtoDCA = overEvery([isATPBankWasKTB, isQuickQuoteModelFactorMonthly, isATPRelationWasSelf])

export const isAdditionalDocumentPlaceHolder = isEqual(get(['insured', 'ADDITIONAL_DOCUMENT', 'name'], DOCUMENT_TYPE))

export const isCreditCardRelationshipDocumentPlaceHolder = isEqual(
  get(['insured', 'CREDIT_CARD_RELATIONSHIP_DOCUMENT', 'name'], DOCUMENT_TYPE)
)

export const isDaBookBankRelationshipDocumentPlaceHolder = isEqual(
  get(['insured', 'DA_RELATIONSHIP', 'name'], DOCUMENT_TYPE)
)

export const isInsuredIdCardZeroByte = flow(
  get(`_attachments.${get(['insured', 'CITIZEN_ID', 'name'])(DOCUMENT_TYPE)}.length`),
  isEqual(0)
)

export const isBancSuitablityFormZeroByte = flow(
  get(`_attachments.${get(['banc', 'SUITABILITY_FORM', 'name'])(DOCUMENT_TYPE)}.length`),
  isEqual(0)
)

export const isPayerIdCardZeroByte = flow(
  get(`_attachments.${get(['payer', 'CITIZEN_ID', 'name'])(DOCUMENT_TYPE)}.length`),
  isEqual(0)
)

export const isAdditionalDocument = overEvery([
  negate(isEmpty),
  startsWith(get(['insured', 'ADDITIONAL_DOCUMENT', 'name'], DOCUMENT_TYPE)),
])

export const isCreditCardRelationshipDocument = overEvery([
  negate(isEmpty),
  startsWith(get(['insured', 'CREDIT_CARD_RELATIONSHIP_DOCUMENT', 'name'], DOCUMENT_TYPE)),
])

export const isDaRelationshipDocument = overEvery([
  negate(isEmpty),
  startsWith(get(['insured', 'DA_RELATIONSHIP', 'name'], DOCUMENT_TYPE)),
])

export const isFinishCreditCardPaymentSection = (app) => {
  const paidEDC = get('receipts.[1].paid', app) || false
  const paidCreditCard = get('receipts.[2].paid', app) || false
  const isSuccess = paidCreditCard || paidEDC
  return isSuccess
}

export const isSignedCreditCardPaymentSection = (app) => {
  const signedEDC = get('receipts.[1].signed', app) || false
  const signedCreditCard = get('receipts.[2].signed', app) || false
  const isSigned = signedCreditCard || signedEDC
  return isSigned
}

export const isCreditCardRelationshipTier1 = (app) => {
  return (get('creditCardRelationship', app) || '') === CREDIT_CARD_RELATIONSHIP_LIST.TIER1
}

export const isPayerOther = (app) => {
  return (get('payerInfo.payerType', app) || '') === ECBR_PAYER_TYPE.OTHER
}

export const isSignedEcbr = (app) => {
  const { receipts } = app
  const ecbrIssue = receipts?.find((receipt) => receipt?.receiptNo && receipt?.signed && receipt?.paid)
  return ecbrIssue
}

export const isAcknowledge = startsWith('acknowledge')

export const isSignOnce = overSome([isEqual('agent.ECBR')])

export const isReceipt = flow(get('name'), isEqual(get(['agent', 'ECBR', 'name'], DOCUMENT_TYPE)))
export const getReceipts = getOr([], 'receipts')
export const getReceiptBank = get('bank')
export const getReceiptNo = get('receiptNo')
export const getReceiptAmount = get('totalFirstPremiumPayment')
export const getReceiptQRRef2 = get('ref2')
export const getReceiptType = get('type')
export const isReceiptSigned = getOr(false, 'signed')
export const isReceiptPaid = getOr(false, 'paid')
const isSendLink = getOr(false, 'quickpayLink.isSendLink')
const quickpayStatus = getOr('', 'quickpayLink.status')

export const getIsSendLink = overSome([
  isSendLink,
  flow(quickpayStatus, overSome([isEqual('PENDING'), isEqual('EXPIRED')])),
])

export const isCCPASendLink = getOr(false, 'CCPA.isSendLink')
const ccpaStatus = getOr('', 'CCPA.status')

export const isCCPAStatusExpiredOrPending = flow(ccpaStatus, overSome([isEqual('PENDING'), isEqual('EXPIRED')]))
export const isCCPAStatusCompleted = flow(
  ccpaStatus,
  overSome([isEqual('SUCCESS'), isEqual('IN PROGRESS'), isEqual('FAIL')])
)

export const isCCPAStatusError = flow(ccpaStatus, isEqual('ERROR'))

export const getQuickpayPaymentStatus = flow([quickpayStatus])
export const getReceiptCreditCardRelationship = get('creditCardRelationship')
export const getClientCancelPayment = getOr(false, 'clientCancelPayment')

export const hasReceiptSigned = flow(getReceipts, some(['signed', true]))

export const hasReceiptPaid = flow(getReceipts, some(['paid', true]))

export const getPaidReceipts = flow(getReceipts, filter(flow(isReceiptPaid, isEqual(true))))
export const getReceiptFromDocument = (app, document) => ({
  ...getOr({}, document.sectionId, app),
  key: document.sectionId,
})
export const getReceiptKey = (receiptNo) => flow(getReceipts, findIndex({ receiptNo }), (index) => `receipts[${index}]`)
export const getReceiptDocumentType = flow(get('type'), (type) => {
  if (type === PAYMENT_TYPE.EDC) {
    return get('insured.EDC', DOCUMENT_TYPE)
  }
  return get('insured.BANK_RECEIPT', DOCUMENT_TYPE)
})
const defaultItemTo = (defaultValue) => (list) => (size(list) === 0 ? [defaultValue] : list)
export const getReceiptSections = (receipts) =>
  flow(
    over([
      flow(
        filter(['type', PAYMENT_TYPE.CASH]),
        defaultItemTo({ type: PAYMENT_TYPE.CASH, totalFirstPremiumPayment: 0 })
      ),
      flow(filter(['type', PAYMENT_TYPE.EDC]), defaultItemTo({ type: PAYMENT_TYPE.EDC, totalFirstPremiumPayment: 0 })),
      flow(
        filter(['type', PAYMENT_TYPE.CREDITCARD]),
        defaultItemTo({
          type: PAYMENT_TYPE.CREDITCARD,
          totalFirstPremiumPayment: 0,
        })
      ),
      flow(
        filter(['type', PAYMENT_TYPE.NETBANK]),
        defaultItemTo({
          type: PAYMENT_TYPE.NETBANK,
          totalFirstPremiumPayment: 0,
        })
      ),
      flow(
        filter(['type', PAYMENT_TYPE.QUICKPAY]),
        defaultItemTo({
          type: PAYMENT_TYPE.QUICKPAY,
          totalFirstPremiumPayment: 0,
        })
      ),
    ]),
    flatten
  )(receipts)

export const getReceiptTransactionId = (receipt) => flow(get('transactionId'))(receipt)

export const getAllocateReceiptAmount = flow(
  getReceipts,
  map((receipt) => {
    if (isEmpty(receipt)) {
      return 0
    }
    return toFinite(getReceiptAmount(receipt))
  }),
  sum,
  defaultTo(0)
)
export const getRemainReceiptAmount = (app) => {
  const totalPremium = getQuickQuoteTotalPremium(app)
  const allocatePremium = getAllocateReceiptAmount(app)

  return Math.max(0, totalPremium - allocatePremium)
}

export const getSelectedReceipts = flow(
  getReceipts,
  filter(flow(getReceiptAmount, (receiptAmount) => toFinite(receiptAmount) > 0))
)

export const getDocumentType = (app, insuredOrPayer) =>
  flow(get(`${insuredOrPayer}.idType.value`), (type) => get([insuredOrPayer, type], DOCUMENT_TYPE))(app)

export const getCitizenId = (insuredOrPayer) => (app) => get(`${insuredOrPayer}.idNo.citizenId`)(app)
export const getPassport = (insuredOrPayer) => (app) => get(`${insuredOrPayer}.idNo.passport`)(app)

export const getUploadDocStatus = (app, insuredOrPayer) => get(`${insuredOrPayer}UploadDocStatus.status`)(app)

export const getDocumentTypeName = flow(getDocumentType, get('name'))

export const getDocumentTypeText = flow(getDocumentType, get('text'))

export const checker = (app) => {
  const isValue = (value) => (key) => flow(get(`${key}.value`), isEqual(value))(app)

  return {
    isYes: isValue('yes'),
    isNo: isValue('no'),
  }
}

export const isMatchValue = (key, value) => (app) => {
  return flow(get(`${key}.value`), isEqual(value))(app)
}

export const havePayer = flow(getQuickQuoteHavePayer, isEqual(true))

export const isWP = flow(getQuickQuoteSelectedRiders, map('code'), overSome([includes('WPD'), includes('WPDD')]))

export const isPB = flow(
  getQuickQuoteSelectedRiders,
  map('code'),
  overSome([includes('PBSDD'), includes('PBSDDCI'), includes('PBPDD'), includes('PBPDDCI')])
)

export const isIGrow = flow(getQuickQuoteProductCode, isEqual('IGROW'))

export const isPACategory = flow(getQuickQuoteProductCategory, isEqual(VALUES.PA))

export const findS7Product = (str) => {
  return VALUES.S7_3PAY.concat(VALUES.S7_SINGLE).includes(str)
}

export const isS7 = flow(getQuickQuoteProductCode, overSome([findS7Product]))

export const isGIOProduct = flow(getQuickQuoteProductCode, (code) => VALUES.PRODUCT_GIO.includes(code))

export const isBuyForSelfS7CC = createSelector(getQuickQuoteMaturityPolicyOwner, (policyOwner) => {
  return policyOwner === 'SELF'
})

export const isDefaultIDCardForSelfS7CC = overEvery([isS7, isBuyForSelfS7CC])

export const isMEAPlus = flow(getQuickQuoteSelectedRiders, map('code'), overSome(includes('MEAPlus')))

const isSLIRider = flow(
  getQuickQuoteSelectedRiders,
  map('code'),
  overSome([
    includes('MEAPlus'),
    includes('MEB'),
    includes('HIC'),
    includes('DCI'),
    includes('CPR'),
    includes('ESCI'),
    includes('MEX'),
    includes('MHP'),
    includes('CIUDR'),
    includes('TPDUDR'),
    includes('HSUDR'),
    includes('HBUDR'),
  ])
)
const findAuraProduct = (str) => {
  const auraProduct = getAppConfig().AURA_PRODUCT_CONFIG
  const isEqualAura = _.indexOf(auraProduct, str) >= 0 ? true : false
  return isEqualAura
}

export const isAuraSupport = flow(
  getQuickQuoteProductCode,
  //overSome([isEqual('5WL85'), isEqual('10WL85'),isEqual('WLNP85'),isEqual('SukhapapDee4200'),isEqual('SukhapapDee6200'),isEqual('WLNP60L'),isEqual('WLNP99L'),isEqual('25PL'),isEqual('ICARE')])
  overSome([findAuraProduct])
)

export const isJuvenilePurchasing = overEvery([isKid, isMEAPlus])

export const getAuraInsured = (app) => get('insured.aura.auraJson')(app)

export const getAuraPayer = (app) => get('payer.aura.auraJson')(app)

const isSLIBasicPlan = flow(
  getQuickQuoteProductCode,
  overSome([isEqual('WLCI05'), isEqual('WLCI10'), isEqual('WLCI15'), isEqual('WLCI20')])
)
export const isSLI = overSome([isSLIRider, isSLIBasicPlan])

export const canPregnant = flow((app, insuredOrPayer) => {
  let age, gender
  if (insuredOrPayer === 'insured') {
    age = getQuickQuoteInsuredAge(app)
    gender = getQuickQuoteInsuredGender(app)
  } else {
    age = getQuickQuotePayerAge(app)
    gender = getQuickQuotePayerGender(app)
  }
  return { age, gender }
}, overEvery([({ gender }) => gender === 'F', ({ age: { value, unit } = {} }) => value >= 10 && unit === 'year']))

export const getCurrentBeneficiary = getOr([], 'beneficiary')
export const getBeneficiaryRelationship = (app, relationshipIndex) => get(`${relationshipIndex}.relations.value`)(app)

export const getTotalBeneficiaryAllocation = createSelector(
  getCurrentBeneficiary,
  sumBy(flow(getOr(0, 'allocation'), toFinite))
)

export const getAvailableBeneficiaryAllocation = createSelector(getTotalBeneficiaryAllocation, (total) => 100 - total)

export const isExceedBeneficiaryAllocation = createSelector(getAvailableBeneficiaryAllocation, (value) => value >= 0)

export const isEqualBeneficiaryAllocation = createSelector(getAvailableBeneficiaryAllocation, (value) => value <= 0)

export const isInsuredAndPayerCitizenIdEqual = createSelector(
  [getCitizenId('insured'), getCitizenId('payer')],
  (insuredCitizenId, payerCitizenId) =>
    !_.isEmpty(insuredCitizenId) && !_.isEmpty(payerCitizenId) && _.isEqual(insuredCitizenId, payerCitizenId)
)

export const isInsuredAndPayerPassportEqual = createSelector(
  [getPassport('insured'), getPassport('payer')],
  (insuredPassport, payerPassport) =>
    !_.isEmpty(insuredPassport) && !_.isEmpty(payerPassport) && _.isEqual(insuredPassport, payerPassport)
)

export const isSuitablilityForm = (app) => {
  //return isQuickQuoteBancAgent(app)
  // interim Remove Sustaibility form on Banca-Submit by hardcode return false
  return false
}

export const getTaxIdValidation = () => (app) => {
  const hasPayer = isPayerNeeded(app)
  const insuredOrPayer = hasPayer ? 'payer' : 'insured'
  const hasConsent = get(`${insuredOrPayer}.taxCertConsent.value`)(app)
  const taxId = get(`${insuredOrPayer}.idNo.taxId`)(app)
  return taxId !== undefined && hasConsent ? taxId.length === 13 : true
}

export const isValidateTaxId = createSelector([getTaxIdValidation()], (isCheckTaxId) => {
  return isCheckTaxId
})

export const getFullName = (prefix) => (app) =>
  flow(
    set('agent', get('quickQuote.user')(app)),
    at([`${prefix}.title.text`, `${prefix}.firstName`, `${prefix}.lastName`]),
    join(' ')
  )(app)

export const getFullNameWithoutPrefix = (prefix) => (app) =>
  flow(set('agent', get('quickQuote.user')(app)), at([`${prefix}.firstName`, `${prefix}.lastName`]), join(' '))(app)

export const getFullNameThirdPerson = (app) => {
  return get('FNA.marketConduct.thirdPerson.fullName')(app)
}

export const getFullNameFromSplitThirdPerson = (app) => {
  return (
    get('FNA.marketConduct.thirdPerson.title.text')(app) +
    ' ' +
    get('FNA.marketConduct.thirdPerson.firstName')(app) +
    ' ' +
    get('FNA.marketConduct.thirdPerson.lastName')(app)
  )
}

export const getFullNameFromSplitThirdPersonWithoutPrefix = (app) => {
  return get('FNA.marketConduct.thirdPerson.firstName')(app) + ' ' + get('FNA.marketConduct.thirdPerson.lastName')(app)
}

export const getFullNameWithDefault = (prefix) => (app) => {
  const firstNameLastName = at([`${prefix}.firstName`, `${prefix}.lastName`])(app)
  const isPlaceholder = every(isEmpty)(firstNameLastName)
  return [get(`${prefix}.title.text`, app), ...(isPlaceholder ? ['ชื่อ', 'นามสกุล'] : firstNameLastName)].join(' ')
}

const flatMapWithKey = flatMap.convert({ cap: false })
export const getSignatureKey = (documentName, signedBy, key) => {
  if (!includes(signedBy, keys(DOCUMENT_TYPE)) || !isEmpty(key)) {
    return `${documentName}_${key}`
  }
  return `${documentName}_${signedBy}`
}
export const listSignature = (app) =>
  flow(
    flatMapWithKey(({ signedBy: signatures }, documentName) =>
      flatMapWithKey((fn, signedBy) => {
        const value = fn(app)

        if (isString(value)) {
          return {
            documentName: get([signedBy, documentName, 'name'], DOCUMENT_TYPE),
            key: getSignatureKey(documentName, signedBy),
            signedBy: value,
          }
        }

        return map(
          (key) => ({
            documentName: get([signedBy, documentName, 'name'], DOCUMENT_TYPE),
            key: getSignatureKey(documentName, signedBy, key),
            signedBy: key,
          }),
          value
        )
      })(signatures)
    ),
    reject(['signedBy', undefined])
  )(SIGNATURE_TYPE)

export const getSumPremiumPayment = createSelector(getQuickQuoteTotalPremium, (totalPremium) => totalPremium)

export const getAgentType = get('quickQuote.biProps.profile.agent_type')
export const getSelectedModelFactorLabel = get('quickQuote.biProps.selectedModelFactorLabel')
export const getQuickQuotePaymentModelLabel = get('quickQuote.biProps.paymentModeLabel')
export const isValidateActualPayment = (app) => {
  const allowedType = getAppConfig().ALLOWED_AGENT_TYPES_FOR_ACTUALPAYMENT
  return _.includes(allowedType, getAgentType(app))
}
export const isAllowCRSForm = (app) => {
  const allowedType = getAppConfig().ALLOWED_CRS_FORM
  return getToggles().ENABLE_CRS_SECTION && _.includes(allowedType, getAgentType(app))
}

export const isMinFirstTotalPayment = createSelector(
  getQuickQuoteTotalPremium,
  (app, value) => value,
  (app, value) => app,
  (totalPremium = 0, value, app) => {
    if (getToggles().ENABLE_ACTUAL_PAYMENT && isValidateActualPayment(app)) {
      return true
    }
    return isMoreThanMinimumPaidPremium(value, totalPremium)
  }
)

const isMoreThanMinimumPaidPremium = (amount, totalPremium) => {
  const minimumPaidPremium = getMinimumPaidPremium(totalPremium)
  return amount >= minimumPaidPremium
}

export const getValidatedTotalPaidPremium = createSelector(
  getAllocateReceiptAmount,
  getQuickQuoteTotalPremium,
  isQuickQuoteModelFactorMonthly,
  (allocateReceiptAmount, totalPremium = 0, isMonthlyPayment) => {
    if (!isLessThanMaximumPaidPremium(allocateReceiptAmount, totalPremium, isMonthlyPayment)) {
      const maximumPaidPremium = getMaximumPaidPremium(totalPremium, isMonthlyPayment)
      return {
        isValid: false,
        errorMessage: `ยอดชำระรวมต้องไม่เกิน ${formatNumber(maximumPaidPremium)} บาท`,
      }
    }
    if (!getToggles().ENABLE_ACTUAL_PAYMENT) {
      if (!isMoreThanMinimumPaidPremium(allocateReceiptAmount, totalPremium)) {
        const minimumPaidPremium = getMinimumPaidPremium(totalPremium)
        return {
          isValid: false,
          errorMessage: `ยอดชำระรวมต้องไม่ต่ำกว่า ${formatNumber(minimumPaidPremium)} บาท`,
        }
      }
    } else {
      if (isValidateActualPayment(app)) {
        return { isValid: true }
      }
    }
    return { isValid: true }
  }
)

export const getMinimumPaidPremium = (totalPremium) => roundDown(totalPremium)

export const isMaxFirstTotalPayment = createSelector(
  getQuickQuoteTotalPremium,
  getSumPremiumPayment,
  isQuickQuoteModelFactorMonthly,
  (app, value) => value,
  (totalPremium = 0, sumPremiumPayment, isMonthlyPayment, value) => {
    return isLessThanMaximumPaidPremium(value, totalPremium, isMonthlyPayment)
  }
)

const isLessThanMaximumPaidPremium = (amount, totalPremium, isMonthlyPayment) => {
  const maximumTotalPayment = getMaximumPaidPremium(totalPremium, isMonthlyPayment)
  return amount <= maximumTotalPayment
}

export const getMaximumPaidPremium = (totalPremium, isMonthlyPayment) => {
  const maximumTotalPayment = (isMonthlyPayment
    ? new Decimal(totalPremium).mul(2)
    : new Decimal(totalPremium)
  ).toNumber()
  return Math.ceil(maximumTotalPayment)
}
export const isFinishEDCPaymentSection = (app) => {
  const isEDCSection = flow(
    getSelectedReceipts,
    filter(flow(getReceiptType, isEqual(PAYMENT_TYPE.EDC))),
    every(isReceiptPaid)
  )(app)
  const isChooseCreditCardRelationship = app.creditCardRelationship !== ''
  const checkByPassValidateRelation = getToggles().ENABLE_ECBR_SYSTEMIZATION ? true : isChooseCreditCardRelationship
  return isEDCSection || checkByPassValidateRelation || !getToggles().ENABLE_CREDIT_CARD_RELATIONSHIP
}

export const isPayerInfoValid = (app, state) => {
  const checkValidatePayerSection = getToggles().ENABLE_ECBR_SYSTEMIZATION
    ? isSectionValid(state, [PayerSection('payerInfo')(app)])(app)
    : true
  return checkValidatePayerSection
}

export const canCreateReceipt = overEvery([
  flow(getAllocateReceiptAmount, (amount) => amount !== 0),
  flow(
    getSelectedReceipts,
    filter(flow(getReceiptType, (item) => isEqual(item, PAYMENT_TYPE.CREDITCARD))),
    every(isReceiptPaid)
  ),
  isFinishEDCPaymentSection,
  (app) => {
    const amount = getAllocateReceiptAmount(app)
    return isMinFirstTotalPayment(app, amount)
  },
])

export const isAllReceiptPaid = flow(
  getSelectedReceipts,
  overEvery([every(isReceiptPaid), (receipts) => size(receipts) > 0])
)

export const isRemoteSelling = overSome([flow(get('isRemoteSelling'), isEqual(true))])
export const getMinimumPremiumAmountToSubmitWithoutPayment = () =>
  getAppConfig().MINIMUM_PREMIUM_AMOUNT_TO_SUBMIT_WITHOUT_PAYMENT

export const getMinimumSAAmountToSubmitWithoutPayment = () => getAppConfig().MINIMUM_SA_AMOUNT_TO_SUBMIT_WITHOUT_PAYMENT

export const allowToSubmitWithoutPayment = createSelector(
  getCybsRetry,
  getQuickQuoteTotalPremium,
  getQuickQuoteSumAssured,
  getMinimumPremiumAmountToSubmitWithoutPayment,
  getMinimumSAAmountToSubmitWithoutPayment,
  isRemoteSelling,
  getQuickQuoteProductBasicPlanCode,
  (cybsRetry, totalPremium = 0, sumAssured = 0, minTotalPremium, minSumAssured, isRemoteSelling, basicPlanCode) => {
    if (getToggles().ENABLE_CYBERSOURCE_RETRY && cybsRetry) return false
    else {
      return (
        totalPremium >= minTotalPremium ||
        sumAssured >= minSumAssured ||
        (isRemoteSelling && getToggles().ENABLE_SUBMIT_WITHOUT_PAYMENT_ANY_AMOUNT) ||
        getAppConfig().PRODUCT_ONLY_PAYLATER.includes(basicPlanCode)
      )
    }
  }
)
export const isRemoteSellingPaymentCompleted = (app) => {
  const paidReceipts = getPaidReceipts(app)
  const receiptNo = paidReceipts.length > 0 ? paidReceipts[0]?.receiptNo || paidReceipts[0]?.transactionID : undefined
  return receiptNo !== undefined || (isSelectedToPayLater(app) && isFinishedPayLaterSelection(app))
}
export const isSelectedToPayLater = flow(get('submitWithoutPayment.selected.value'), isEqual(true))
export const isSelectedToPayNow = overEvery([
  flow(get('submitWithoutPayment.selected.value'), isEqual(false)),
  hasReceiptPaid,
])

export const hasNotChosenToPayNowOrPayLater = overEvery([negate(isSelectedToPayLater), negate(isSelectedToPayNow)])

export const isAllReceiptCreated = overEvery([
  canCreateReceipt,
  isAllReceiptPaid,
  flow(getSelectedReceipts, every(flow(getReceiptNo, negate(isNil)))),
])

export const isRetirementProductCategory = flow(getQuickQuoteProductCategory, isEqual(VALUES.RETIREMENT))

export const isShowDCASelected = (app) => {
  return getToggles().ENABLE_ALWAYS_DCA_OPTIONS
    ? isRetirementProductCategory(app)
    : overEvery([isRetirementProductCategory, allowToSubmitWithoutPayment, isSelectedToPayLater])(app)
}

export const isShowDCAForm = (app) => {
  return getToggles().ENABLE_ALWAYS_DCA_OPTIONS
    ? isBumnanReadyProductCategory(app) || isDCASelected(app) || isCashDividend(app)
    : overEvery([
        isRetirementProductCategory,
        overSome([
          flow(allowToSubmitWithoutPayment, isEqual(false)),
          isDCASelected,
          flow(get('submitWithoutPayment.selected.value'), isEqual(false)),
        ]),
      ])(app)
}

const evidenceIsCitizenId = (obj) => overEvery([flow(obj, get('idType.value'), (type) => type === 'CITIZEN_ID')])
const hasFillIdNo = (obj) => flow(obj, getOr('idNo.citizenId', 'idNo.passport'), (id) => !isEmpty(id))
export const isPayerEvidenceCitizenId = evidenceIsCitizenId(getPayer)
export const isInsuredEvidenceCitizenId = evidenceIsCitizenId(getInsured)
export const isInsureFilled = hasFillIdNo(getInsured)
export const isPayerFilled = hasFillIdNo(getPayer)
export const isJuvenileEkyc = flow(getQuickQuoteInsuredAge, get('value'), (age) => age < 7)
export const isJuvenileButNotKidEkyc = flow(getQuickQuoteInsuredAge, get('value'), (age) => age >= 7 && age < 20)
export const isAdultEkyc = flow(getQuickQuoteInsuredAge, get('value'), (age) => age >= 20)
export const isDopaFailed = (insuredOrPayer, app) => {
  if (isSelectedRemoteSelling(app)) {
    return getRemoteSellingDopaResult(insuredOrPayer, app)?.dopaRequestFailed
  }
  return false
}
export const isShowInsuredDocSection = (app) => {
  if (isRemoteSelling(app)) return true
  return (getToggles().ENABLE_EKYC_F2F || isSelectedRemoteSelling(app)) && !isRemoteSelling(app)
    ? getSkippedInsuredEkyc(app) || isDopaFailed('insured', app)
      ? true
      : isJuvenileEkyc(app)
      ? true
      : !isInsuredEvidenceCitizenId(app)
    : true
}

export const isShowPayerDocSection = (app) => {
  if (isRemoteSelling(app)) return havePayer(app)
  return getToggles().ENABLE_EKYC_F2F && getSkippedPayerEkyc(app)
    ? true
    : (getToggles().ENABLE_EKYC_F2F || isSelectedRemoteSelling(app)) && !isRemoteSelling(app)
    ? havePayer(app) && (!isPayerEvidenceCitizenId(app) || isDopaFailed('payer', app))
    : havePayer(app)
}

export const is2ndSupport = (app) => {
  if (isInsureFilled(app) || isPayerFilled(app)) {
    if (isDopaFailed('payer', app) || isDopaFailed('insured', app)) {
      return true
    } else if (getSkippedInsuredEkyc(app) || getSkippedPayerEkyc(app)) {
      return true
    } else if (isJuvenileEkyc(app)) {
      return true
    } else if (isPayerNeeded(app)) {
      return isPayerEvidenceCitizenId(app) && isInsuredEvidenceCitizenId(app) ? false : true
    } else {
      return isInsuredEvidenceCitizenId(app) ? false : true
    }
  }
  return false
}

export const isShowInsuredAndPayerEkycSection = (app) => {
  return {
    isShowInsured: isInsuredEvidenceCitizenId(app),
    isShowPayer: isPayerEvidenceCitizenId(app),
  }
}

export const isEkycSupport = (app) => {
  if (!isRemoteSelling(app) && getToggles().ENABLE_EKYC_F2F)
    return isInsuredEvidenceCitizenId(app) || isPayerEvidenceCitizenId(app)
  return false
}

export const getCertifyByAgent = get('document.certifyByAgent.isCertify')
export const getAgentMobileNumber = get('quickQuote.agent.contactMethods.phoneContacts')
export const isReceiptEvidenceUploaded = (app) => {
  return get('isEvidenceUploaded')(app)
}

export const hasEvidenceUpload = flow(isReceiptEvidenceUploaded)

export const getSelectedReceiptType = flow(getSelectedReceipts, last, getReceiptType)

/* DIGSALTOOL-1661 */
export const eKycCheckDataPayer = (insuredOrPayer, app) => {
  const isSkippedPayer = insuredOrPayer === 'payer' && getSkippedPayerEkyc(app)

  const isProprietorIdPayer = insuredOrPayer === 'payer' && getPayerProprietorId(app)

  const isDopaVerifiedPayer = insuredOrPayer === 'payer' && getPayerDopaResult(app)

  const isDopaRequestFailedPayer = insuredOrPayer === 'payer' && getPayerDopaRequestFailed(app)

  let isRequiredPayer = false
  if (isSkippedPayer === true) {
    isRequiredPayer = false
  } else {
    if (isEmpty(isProprietorIdPayer) && !isSkippedPayer) {
      isRequiredPayer = true
    } else if (isDopaRequestFailedPayer === true && !isSkippedPayer) {
      isRequiredPayer = true
    } else if (!isNil(isDopaVerifiedPayer) && !isDopaVerifiedPayer && !isDopaRequestFailedPayer) {
      isRequiredPayer = !getToggles().ENABLE_PAY_LATER
    } else if (isNil(isDopaVerifiedPayer)) {
      isRequiredPayer = true
    } else {
      isRequiredPayer = false
    }
  }

  const isFieldPayer = `validationEKYC.payer.isRequired`

  return { isFieldPayer, isRequiredPayer }
}

export const eKycCheckDataInsured = (insuredOrPayer, app) => {
  const isSkippedInsured = insuredOrPayer === 'insured' && getSkippedInsuredEkyc(app)

  const isProprietorIdInsured = insuredOrPayer === 'insured' && getInsuredProprietorId(app)

  const isDopaVerifiedInsured = insuredOrPayer === 'insured' && getInsuredDopaResult(app)

  const isDopaRequestFailedInsured = insuredOrPayer === 'insured' && getInsuredDopaRequestFailed(app)

  let isRequiredInsured = false
  if (isSkippedInsured === true) {
    isRequiredInsured = false
  } else {
    if (isEmpty(isProprietorIdInsured) && !isSkippedInsured) {
      isRequiredInsured = true
    } else if (isDopaRequestFailedInsured === true && !isSkippedInsured) {
      isRequiredInsured = true
    } else if (!isNil(isDopaVerifiedInsured) && !isDopaVerifiedInsured && !isDopaRequestFailedInsured) {
      isRequiredInsured = !getToggles().ENABLE_PAY_LATER
    } else if (isNil(isDopaVerifiedInsured)) {
      isRequiredInsured = true
    } else {
      isRequiredInsured = false
    }
  }

  const isFieldInsured = `validationEKYC.insured.isRequired`

  return { isFieldInsured, isRequiredInsured }
}

/* DIGSALTOOL-1661 */
export const isEkycSkippedAura = (id): boolean => {
  return _.includes(['validationEKYC.insured.isRequired', 'validationEKYC.payer.isRequired'], id)
}

export const showSecWarningMsg = (app) => {
  let msg = ''
  const insuredMobileNumber = getInsuredData(app)?.phoneNumber
  const agentMobileNumber = getAgentMobileNumber(app)
  if (agentMobileNumber && insuredMobileNumber?.length === 10) {
    const amb = agentMobileNumber.filter((m) => m.type === 'mobile').map((m) => m.localNumber)
    msg = amb.includes(insuredMobileNumber)
      ? 'คำเตือน : ระบบตรวจพบหมายเลขโทรศัพท์ของลูกค้าเป็นหมายเลขเดียวกันกับตัวแทนผู้ให้บริการ กรุณาตรวจสอบหมายเลขโทรศัพท์ของลูกค้าอีกครั้ง'
      : ''
  }
  return msg
}

export const getInsuredHaveSession = get('healthSession.sessionId')

export const getPayerHaveSession = get('healthSession.payerSessionId')

export const isAppEligibleToDoCCPA = function(app, basicPlanCodes) {
  return basicPlanCodes.some((product) => product === getQuickQuoteProductBasicPlanCode(app))
}

export const isHasPayerInfoEcbrNo = (app) => get('payerInfo.ecbrNo', app)
export const isHasPayerInfoAttachment = (app) => get('payerInfo.attachment', app)

export const isNotPaymentTypeEdc = (app) => getSelectedReceiptType(app) !== PAYMENT_TYPE.EDC
export const isPaymentTypeCreditCard = (app) =>
  getSelectedReceiptType(app) === PAYMENT_TYPE.CREDITCARD || getSelectedReceiptType(app) === PAYMENT_TYPE.QUICKPAY
export const isPaymentTypeNetbank = (app) => getSelectedReceiptType(app) == PAYMENT_TYPE.NETBANK
export const isPaymentTypeCash = (app) => getSelectedReceiptType(app) == PAYMENT_TYPE.CASH
