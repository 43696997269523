import { noop, isNil, isEmpty } from 'lodash/fp'
import { connect } from 'react-redux'
import { Box, Flex } from 'rebass'
import { defaultProps, withStateHandlers, withHandlers, compose, lifecycle } from 'recompose'

import { getFileWithName, isPDFFileType, getErrorByTypeName } from 'e-submission/apps/selectors'
import {
  removeFile,
  deletePhoto,
  browsePhoto,
  capturePhoto,
  previewPhoto,
  removeError,
} from 'e-submission/apps/actions'

import styled from 'styled-components'
import { Button, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import Dialog from 'e-submission/components/Dialog'
import Icon, { getIcon } from 'e-submission/components/Icon'
import FormEngine from 'e-submission/components/FormEngine'
import waterMaskUrl from 'assets/images/water-mask.png'
import viewerUrl from 'file-loader?name=pdf-viewer/web/viewer.html&emitFile=false!e-submission/pdf-viewer/web/viewer.html'
import Card from 'e-submission/components/Card/index'
import { isBlob } from 'e-submission/utils/pattern'
import { resolveImageFile } from 'e-submission/utils/promise'

export const WatermarkModal = styled(Dialog)`
  .modal-content {
    height: 100vh;
  }
  .modal-title {
    display: flex;
    flex: 1 0 100%;
    justify-content: space-between;
    align-items: center;
    button {
      padding: 5px 10px;
    }
  }
  .modal-body {
    display: flex;
    flex-direction: column;
    padding: 0 !important;
    iframe {
      flex: 1;
    }
  }
  .modal-footer {
    justify-content: space-between;
  }
  .formEngine {
    min-height: 260px;
  }
`
const onLoadIframe = (evt) => {
  const iframe = evt.target
  const iframeDocument = iframe.contentWindow.document
  const toolbar = iframeDocument.querySelector('.toolbar')
  const main = iframeDocument.querySelector('#mainContainer')
  const content = iframeDocument.querySelector('#viewerContainer')
  const customStyle = document.createElement('style')
  customStyle.type = 'text/css'
  customStyle.textContent = `
    .canvasWrapper:before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-image: url(${waterMaskUrl});
      background-size: 200px;
      z-index: 10;
    }
  `
  iframeDocument.head.appendChild(customStyle)
  toolbar.style.visibility = 'hidden'
  content.style.top = 0
}

export const WithWatermark = styled(({ image, ...props }) => <Flex {...props} />)`
  position: relative;
  overflow: hidden;
  background-color: black;
  background-image: url('${({ image }) => image}');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: url(${waterMaskUrl});
    background-size: 200px;
    background-attachment: fixed;
  }
`

export const MoreInfoPhotoCard = lifecycle({
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.isOpen && nextProps.isUploadError === true) {
      this.props.onClose()
    }
  },
})(
  ({
    clearFile,
    editable,
    disabled,
    isOk,
    sections,
    setRef,
    getRef,
    name,
    url,
    isPDF,
    accept,
    onOk,
    onOpen,
    onClose,
    onDelete,
    onPreview,
    checked,
    readOnly,
    setFileProps,
    isOpen,
    validateFile,
    openModal,
    closeModal,
    CheckedComponent,
    NotCheckedComponent,
    isBrowseFile,
    isUploadError,
    ...props
  }) => {
    const browseFile = () => getRef().click()
    const InfoCard = styled(({ className, children, description, icon, ...props }) => (
      <Card className={className} w={1} column {...props}>
        <Flex align="center">
          <Flex className="icon-left">{icon && getIcon(icon)}</Flex>
          <Box flex="auto">
            <strong>{description}</strong>
          </Box>
          <Icon.arrowRight className="arrowRight" />
        </Flex>
      </Card>
    ))`
      padding: 10px 20px;
      &:hover {
        cursor: pointer;
      }
      .arrowRight {
        color: ${({ theme }) => theme.variables['$brand-primary']};
      }
      .icon-left svg {
        max-width: 22px;
        max-height: 30px;
        margin-left: -3px;
        margin-right: 5px;
      }
    `

    return (
      <div>
        <WatermarkModal isOpen={isOpen} toggle={onClose}>
          <ModalHeader tag="div">
            <div className="btn-left" onClick={onClose}>
              <Icon.arrowLeft className="icon" />
            </div>
            <div>{props.title}</div>
            <div />
          </ModalHeader>
          <ModalBody>
            {!isPDF ? (
              <WithWatermark flex="1 1 100%" align="center" image={url} />
            ) : (
              <iframe src={`${viewerUrl}?file=${url}`} onLoad={onLoadIframe} />
            )}
            {sections && (
              <div className="formEngine">
                <FormEngine sections={sections} />
              </div>
            )}
          </ModalBody>
          {readOnly ? null : (
            <ModalFooter>
              <Button color="primary" outline onClick={onDelete}>
                ลบ
              </Button>
              <Button color={!checked ? 'disabled' : 'primary'} onClick={onOk}>
                ตกลง
              </Button>
            </ModalFooter>
          )}
        </WatermarkModal>
        {checked ? (
          <InfoCard {...props} onClick={onPreview} />
        ) : (
          <div>
            <InfoCard {...props} onClick={() => !disabled && (isBrowseFile ? browseFile() : onOpen())} />
            {isBrowseFile && <input ref={setRef} type="file" onChange={onOpen} accept={accept} />}
          </div>
        )}
      </div>
    )
  }
)

export default compose(
  defaultProps({
    onOpen: noop,
    onOk: noop,
    isBrowseFile: !(typeof window === 'object' && !isEmpty(window.cordova)),
    isUploadError: false,
    accept: 'image/x-png,image/jpeg,application/pdf',
  }),
  withHandlers(() => {
    let ref
    return {
      setRef: () => (_ref) => {
        ref = _ref
      },
      getRef: () => () => ref,
      clearFile: () => () => {
        if (ref) {
          ref.value = ''
        }
      },
    }
  }),
  withStateHandlers(
    { isOpen: false },
    {
      openModal: (state) => () => {
        return { isOpen: true }
      },
      closeModal: (state, { clearFile }) => () => {
        clearFile()
        return { isOpen: false }
      },
    }
  ),
  connect(
    (state, { name, disabled }) => ({
      disabled,
      url: getFileWithName(state, name),
      isPDF: isPDFFileType(state, name),
      isUploadError: !isNil(getErrorByTypeName(state, name)),
    }),
    (
      dispatch,
      {
        isBrowseFile,
        onOpen,
        checked,
        disabled,
        sections,
        clearFile,
        validateFile,
        name,
        openModal,
        closeModal,
        onOk,
        editable = false,
        isUploadError,
      }
    ) => ({
      onDelete: () => {
        closeModal()
        dispatch(deletePhoto(name))
        if (!editable) {
          dispatch(removeFile(name))
        }
      },
      onPreview: () => {
        if (checked) {
          openModal()
          dispatch(previewPhoto(name))
        }
      },
      onClose: () => {
        closeModal()
        if (!editable) {
          dispatch(removeFile(name))
        }
      },
      onOk: () => {
        const isOk = onOk()
        if (isOk !== false) {
          closeModal()
          if (!editable) {
            dispatch(removeFile(name))
          }
        }
      },
      onOpen: async (event) => {
        dispatch(removeError(name))
        onOpen()

        const isShowPreview = !isNil(sections)

        if (isShowPreview) {
          openModal()
        }

        if (isBrowseFile) {
          const file = event.target.files[0]

          if (isBlob(file) && file) {
            const format = await resolveImageFile(file)

            validateFile()
            dispatch(browsePhoto(name, file, isShowPreview, undefined, format))
          } else {
            validateFile()
          }
        } else {
          validateFile()
          dispatch(capturePhoto(name, isShowPreview))
        }

        if (editable) {
          clearFile()
        }
      },
    })
  )
)(MoreInfoPhotoCard)
