// @flow
import type { InvestmentProps } from 'core/service/pdf-generation/products/investment/pdf-document'

import {
  CashflowChart,
  RPUDRCover,
  FeeDetails,
  FeeDetailsContinued_1,
  FeeDetailsContinued_2,
  FeeDetailsContinued_3,
  FormulaDetails,
  MutualFundPortfolio,
  NoteFormulaRpudrDetail,
  PaymentChannelsILink,
  ProcessingTimeline,
  Recommendation,
  RightsInsured,
  RPUDRBenefitTableExampleGroup,
  RPUDRConditions,
  Sign,
} from '../../sections'
import { commonSection } from 'core/service/pdf-generation/products/common/sections'
import { generateCashFlow } from 'core/service/investment/cash-flow/cash-flow'
import BIMESSAGES from 'core/data-model/constants/bi-messages'

export const _generateCashFlowByExpectedReturn = async (
  displayProduct: DisplayProduct,
  regularPremium: number,
  regularTopUp: number,
  lumpSum: { year: number, value: number }[],
  age: number,
  gender: Gender,
  paymentInterval: number,
  sumAssured: number,
  percentages: number[],
  inflationPercentages: number[],
  riderOccupationFactors: { [riderCode: string]: number },
  riders: (Rider & RiderState)[]
) => {
  const generateCashFlowByExpectedReturn = (expectedReturn, inflation) =>
    generateCashFlow(
      displayProduct,
      expectedReturn,
      regularPremium,
      regularTopUp,
      lumpSum,
      age,
      gender,
      paymentInterval,
      sumAssured,
      inflation,
      riderOccupationFactors,
      riders
    )

  let cashFlowByExpectedReturn = {}
  if (inflationPercentages && inflationPercentages.length) {
    let index = 0
    for (const expectedReturn of percentages) {
      if (!cashFlowByExpectedReturn[`${expectedReturn}`]) {
        cashFlowByExpectedReturn[`${expectedReturn}`] = {}
      }
      const inflation = inflationPercentages[index] || 0
      cashFlowByExpectedReturn[`${expectedReturn}`][`${inflation}`] = await generateCashFlowByExpectedReturn(
        expectedReturn,
        inflation
      )
      index = index + 1
    }
  } else {
    for (let expectedReturn of percentages) {
      cashFlowByExpectedReturn[`${expectedReturn}`] = await generateCashFlowByExpectedReturn(expectedReturn)
    }
  }

  return cashFlowByExpectedReturn
}

export const rpudrContent = async (props: InvestmentProps) => {
  const paymentChannels = PaymentChannelsILink()
  const portfolio = await MutualFundPortfolio(props)

  const {
    displayProduct,
    regularPremium,
    regularTopUp,
    lumpSum,
    age,
    gender,
    paymentInterval,
    sumAssured,
    riderOccupationFactors,
    riders,
  } = props.cashFlowProps

  const overriddenLumpSum = lumpSum.map((lumpSumByYear) => {
    if (lumpSumByYear.year === 1) {
      return { ...lumpSumByYear }
    }
    return { ...lumpSumByYear, value: 0 }
  })

  const cashFlowByExpectedReturn = await _generateCashFlowByExpectedReturn(
    displayProduct,
    regularPremium,
    regularTopUp,
    overriddenLumpSum,
    age,
    gender,
    paymentInterval,
    sumAssured,
    [-1, 2, 5],
    undefined,
    riderOccupationFactors,
    riders
  )
  const cashFlowByExpectedReturnWithInflation = await _generateCashFlowByExpectedReturn(
    displayProduct,
    regularPremium,
    regularTopUp,
    overriddenLumpSum,
    age,
    gender,
    paymentInterval,
    sumAssured,
    [2],
    [5],
    riderOccupationFactors,
    riders
  )
  const propWithCashFlowByExpectedReturn = { ...props, cashFlowByExpectedReturn }
  const propWithCashFlowByExpectedReturnWithInflation = {
    ...props,
    cashFlowByExpectedReturn: cashFlowByExpectedReturnWithInflation,
  }
  const chart = CashflowChart(props.productCode, propWithCashFlowByExpectedReturn)
  const benefitTable = RPUDRBenefitTableExampleGroup({
    displayProduct,
    commonCashFlow: propWithCashFlowByExpectedReturn,
    withInflationCashFlow: propWithCashFlowByExpectedReturnWithInflation,
    riders: riders,
  })

  return [
    RPUDRCover({
      ...props,
      unitLinkedMessage: BIMESSAGES.RPUDR_UNIT_LINKED,
      unitLinkedThMessage: BIMESSAGES.RPUDR_UNIT_LINKED_TH,
    }),
    chart,
    portfolio,
    benefitTable,
    FormulaDetails(props.productCode, ''),
    NoteFormulaRpudrDetail(),
    RPUDRConditions(),
    ProcessingTimeline(props.productCode),
    FeeDetails(props.productCode, ''),
    FeeDetailsContinued_1(props.productCode),
    FeeDetailsContinued_2(props.productCode),
    FeeDetailsContinued_3(props.productCode),
    paymentChannels,
    RightsInsured(props.productCode),
    Recommendation(props.productCode),
    commonSection.riders(props),
    Sign(props),
  ]
}
