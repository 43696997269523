//@flow

import type { FinancialCheckList, HealthCheckList } from 'core/data-model/loan'
import { listFinancialTable, listHealthCheckTable } from 'core/data-model/loan'

export const _getFinancialDocument = async (
  listFinancialTable: () => Promise<FinancialCheckList[]>
): Promise<FinancialCheckList[]> => {
  return await listFinancialTable()
}

export const getFinancialDocument = () => {
  return _getFinancialDocument(listFinancialTable)
}

export const _getHealthCheckItem = async (
  listHealthCheckTable: () => Promise<HealthCheckList[]>
): Promise<HealthCheckList[]> => {
  return await listHealthCheckTable()
}

export const getHealthCheckItem = () => {
  return _getHealthCheckItem(listHealthCheckTable)
}
