// @flow
import type { Rider, RiderCode, RiderState } from 'core/data-model/rider'
import type { BasicPlan, DiscountRate, ModelFactor, SumAssured$ } from 'core/data-model/basic-plan'
import type { Age, Gender, Insured } from 'core/data-model/insured'
import type { DisplayProductQuery } from 'core/service'
import { getBasicPlan } from 'core/service'
import type { AgentType } from 'core/data-model/identity/types'
import type { State as LoanState } from '../../../quick-quote/product-loan/redux'
import moment from 'moment'

import { getDiscountByOccupationFactor } from './discount'
import {
  calculateBasicPremium,
  calculateBasicSumAssured,
  getDiscount,
  lookUpBasicPremiumRate,
  lookUpBasicPremiumRateMRTA,
  lookUpRiderPremiumRateMRTA,
  validateSumAssured,
  validateTotalPremium,
  calculateMRTASumAssuredContract,
  calculateGLTSPSumAssuredContract,
} from 'core/service/basic-plan'
import {
  calculateRiderPremium,
  getRiderPremiumRates,
  getRiderSumAssured,
  getSelectedRiderPlan,
  validateRiders,
  getAccurateRiders,
  hasRidersNeedToCalculateFromTotalPremiumOrAnnualPremium,
  hasRidersNeedToCalculateFromAnnualTotalPremium,
} from 'core/service/rider'
import { getRiskiestOccupationFactorForRiders, getRiskiestOccupation } from 'core/service/insured'
import { dataWrapper } from 'core/data'
import { calculateTotalPremium, totalRiderPremium } from './premium'
import VALUES from 'core/data-model/constants/values'
import _ from 'lodash'
import { isInvestment } from 'identity/fse-branch/services'
import { getToggles } from 'quick-quote/feature-toggles'
import { isMRTAGroup, isGLTSPGroup } from 'quick-quote/product-mrta/coverage-plan/selectors'

type GetBasicPlan = (DisplayProductQuery) => Promise<BasicPlan>

export const _getBasicPlanDiscountRates = (
  getBasicPlan: GetBasicPlan,
  query: DisplayProductQuery
): Promise<DiscountRate[]> => getBasicPlan(query).then((basicPlan) => basicPlan.discountRates)

export const getBasicPlanDiscountRates = (query: DisplayProductQuery) => _getBasicPlanDiscountRates(getBasicPlan, query)

export const getDefaultSumAssured = (basicPlan: BasicPlan) => {
  return isNaN(basicPlan.defaultSumAssured) ? basicPlan.sumAssuredMinimum : basicPlan.defaultSumAssured
}

export const _getDefaultSelectedRiders = (
  getBasicPlan: GetBasicPlan,
  query: DisplayProductQuery
): Promise<RiderCode[]> => getBasicPlan(query).then((basicPlan) => basicPlan.selectedRiders)

export const getDefaultSelectedRiders = (query: DisplayProductQuery) => _getDefaultSelectedRiders(getBasicPlan, query)

export const _getBasicPlanDiscountRate = (
  getBasicPlan: GetBasicPlan,
  query: DisplayProductQuery,
  sumAssured: number,
  occupationFactor: number
): Promise<number> =>
  getBasicPlan(query).then((basicPlan) => {
    return getDiscountByOccupationFactor({
      occupationFactor,
      discount: getDiscount(basicPlan.discountRates, sumAssured),
    })
  })

export const getBasicPlanCampaignDiscountRate = (query: DisplayProductQuery) =>
  getBasicPlanCampaignDiscount(query).then((campaignDiscount) => {
    if (campaignDiscount.value) {
      return campaignDiscount.value
    }
    return 0
  })

export const getBasicPlanCampaignDiscount = (query: DisplayProductQuery) =>
  getBasicPlan(query).then((basicPlan) => {
    if (basicPlan.campaignDiscount) {
      const DATE_FORMAT = 'DD/MM/YYYY'
      return moment().isBetween(
        moment(basicPlan.campaignDiscount.startDate, DATE_FORMAT),
        moment(basicPlan.campaignDiscount.endDate, DATE_FORMAT),
        null,
        '[]'
      )
        ? basicPlan.campaignDiscount
        : {}
    } else {
      return {}
    }
  })

export const getBasicPlanDiscountRate = (query: DisplayProductQuery, sumAssured: number, occupationFactor: number) =>
  _getBasicPlanDiscountRate(getBasicPlan, query, sumAssured, occupationFactor)

export const getBasicPlanRateUnit = async (query: DisplayProductQuery) => {
  const basicPlan: BasicPlan = await getBasicPlan(query)
  return basicPlan ? basicPlan.rateUnit : 0
}

export const getFactor = (basicPlan: BasicPlan, modelFactor: ModelFactor) => {
  return isInvestment(basicPlan.category) ? modelFactor.factorILP : modelFactor.factor
}

const calculateSumAssuredAndPremiumForSelectedRiders = (params: {
  riders: (Rider & RiderState)[],
  validatedSumAssured: SumAssured$,
  basicPremium: number,
  annualBasicPremium: number,
  riderPremiumRates: { [riderCode: string]: number },
  riderOccupationFactors: { [riderCode: string]: number },
  modelFactor: ModelFactor,
  basicPlan: BasicPlan,
  userAgentType: AgentType,
  riderPayerOccupationFactors: { [riderCode: string]: number },
  loan: LoanState,
  sumAssuredContract: number,
}) => {
  const {
    riders,
    validatedSumAssured,
    basicPremium,
    riderPremiumRates,
    riderOccupationFactors,
    modelFactor,
    basicPlan,
    userAgentType,
    riderPayerOccupationFactors,
    loan,
    sumAssuredContract,
  } = params
  const totalPremium = hasRidersNeedToCalculateFromTotalPremiumOrAnnualPremium(riders)
    ? preCalculateTotalPremium(params)
    : 0

  return riders.map((rider: Rider & RiderState) => {
    if (!rider.isSelected) {
      return rider
    }

    const sumAssured = getRiderSumAssured(
      rider,
      validatedSumAssured.value,
      basicPremium,
      basicPlan,
      userAgentType,
      totalPremium,
      sumAssuredContract
    )
    const premium = calculateRiderPremium(rider, {
      riderPremiumRate: riderPremiumRates[rider.code],
      riderSumAssured: sumAssured,
      occupationFactor: riderOccupationFactors[rider.code],
      basicPremium,
      modelFactor: getFactor(basicPlan, modelFactor),
      modelFactorPeriod: modelFactor.periods,
      payerOccupationFactor: riderPayerOccupationFactors[rider.code],
      loan: loan,
    })

    // TODO: Test this once module requirement is clear
    if (_.get(rider, 'selectedPlan.modules')) {
      const modules = rider.selectedPlan.modules.map((riderModule) => {
        const modulePremium = calculateRiderPremium(rider, {
          riderPremiumRate: riderPremiumRates[riderModule.code],
          riderSumAssured: sumAssured,
          occupationFactor: riderOccupationFactors[rider.code],
          basicPremium,
          modelFactor: getFactor(basicPlan, modelFactor),
          modelFactorPeriod: modelFactor.periods,
          payerOccupationFactor: riderPayerOccupationFactors[rider.code] || 1,
        })
        return { ...riderModule, premium: modulePremium }
      })
      const selectedPlanState = { selectedPlan: { ...rider.selectedPlan, modules } }
      return { ...rider, sumAssured, premium, ...selectedPlanState }
    }

    return { ...rider, sumAssured, premium }
  })
}

const preCalculateTotalPremium = (params: {
  riders: (Rider & RiderState)[],
  validatedSumAssured: SumAssured$,
  basicPremium: number,
  annualBasicPremium: number,
  riderPremiumRates: { [riderCode: string]: number },
  riderOccupationFactors: { [riderCode: string]: number },
  modelFactor: ModelFactor,
  basicPlan: BasicPlan,
  userAgentType: AgentType,
  riderPayerOccupationFactors: { [riderCode: string]: number },
  loan: LoanState,
}) => {
  const {
    riders,
    validatedSumAssured,
    basicPremium,
    annualBasicPremium,
    riderPremiumRates,
    riderOccupationFactors,
    modelFactor,
    basicPlan,
    userAgentType,
    riderPayerOccupationFactors,
    loan,
  } = params
  const isNeedAnnualTotalPremium = hasRidersNeedToCalculateFromAnnualTotalPremium(riders)
  const _basicPremium = isNeedAnnualTotalPremium ? annualBasicPremium : basicPremium
  const _modelFactor = isNeedAnnualTotalPremium ? VALUES.MODEL_FACTOR_ANNUAL : modelFactor.factor
  const _modelFactorPeriod = isNeedAnnualTotalPremium ? VALUES.MODEL_FACTOR_ANNUAL : modelFactor.periods

  const updatedRiders = riders.map((rider: Rider & RiderState) => {
    if (!rider.isSelected) {
      return rider
    }
    const sumAssured = getRiderSumAssured(
      rider,
      validatedSumAssured.value,
      _basicPremium,
      basicPlan,
      userAgentType,
      loan
    )
    const premium = calculateRiderPremium(rider, {
      riderPremiumRate: riderPremiumRates[rider.code],
      riderSumAssured: sumAssured,
      occupationFactor: riderOccupationFactors[rider.code],
      basicPremium: _basicPremium,
      modelFactor: _modelFactor,
      modelFactorPeriod: _modelFactorPeriod,
      payerOccupationFactor: riderPayerOccupationFactors[rider.code],
      loan: loan,
    })
    return { ...rider, sumAssured, premium }
  })

  const riderPremium = totalRiderPremium(updatedRiders)
  return calculateTotalPremium(_basicPremium, riderPremium)
}

const calculateBasicPremiumAndAnnualBasicPremium = async (basicPremiumParams: {
  displayProductQuery: DisplayProductQuery,
  sumAssured: number,
  premiumRate: number,
  natureOfDutyCodes: string[],
  discount: number,
  rateUnit: number,
  modelFactor: number,
  loan: LoanState,
  riderPremiumRate: number,
  campaignDiscount: number,
}) => {
  const { modelFactor } = basicPremiumParams
  const basicPremium = await calculateBasicPremium(basicPremiumParams)
  const annualBasicPremium =
    modelFactor === VALUES.MODEL_FACTOR_ANNUAL
      ? basicPremium
      : await calculateBasicPremium({
          ...basicPremiumParams,
          modelFactor: VALUES.MODEL_FACTOR_ANNUAL,
        })
  const fullBasicPremium = await calculateBasicPremium({
    ...basicPremiumParams,
    campaignDiscount: 0,
  })
  const fullAnnualBasicPremium = await calculateBasicPremium({
    ...basicPremiumParams,
    campaignDiscount: 0,
    modelFactor: VALUES.MODEL_FACTOR_ANNUAL,
  })

  return { basicPremium, annualBasicPremium, fullBasicPremium, fullAnnualBasicPremium }
}

export const getUpdatedRidersSAFromAnnualBasicPremium = async (basicPremiumParams: {
  riders: (Rider & RiderState)[],
  displayProductQuery: DisplayProductQuery,
  sumAssured: number,
  premiumRate: number,
  natureOfDutyCodes: string[],
  discount: number,
  rateUnit: number,
  modelFactor: modelFactor,
  annualBasicPremium: number,
  loan: LoanState,
  campaignDiscount: number,
}) => {
  const {
    riders,
    displayProductQuery,
    sumAssured,
    premiumRate,
    natureOfDutyCodes,
    discount,
    rateUnit,
    modelFactor,
    annualBasicPremium,
    loan,
    campaignDiscount,
  } = basicPremiumParams

  const unresolvedPromises = riders.map(async (rider) => {
    if (_.get(rider, 'options.0.source') === VALUES.SOURCE_TOTAL_BASIC_ANNUAL_PREMIUM_WITH_LIMITED_RANGE)
      if (sumAssured > rider.defaultSumAssured) {
        const result = await calculateBasicPremiumAndAnnualBasicPremium({
          displayProductQuery,
          sumAssured: rider.defaultSumAssured,
          natureOfDutyCodes,
          premiumRate: premiumRate,
          discount,
          rateUnit,
          modelFactor: modelFactor.factor,
          loan,
          campaignDiscount: campaignDiscount,
        })

        rider.sumAssured = result.annualBasicPremium
      } else {
        rider.sumAssured = annualBasicPremium
      }
    return rider
  })

  return await Promise.all(unresolvedPromises)
}

export const calculateCoveragePlanFromBasicPlanChange = async (props: {
  displayProductQuery: DisplayProductQuery,
  insured: Insured,
  natureOfDutyCodes: string[],
  payerNatureOfDutyCodes: string[],
  modelFactorID: string,
  payerAge: Age,
  payerGender: Gender,
  availableRiders: (Rider & RiderState)[],
  userAgentType: AgentType,
  payerRelation: string,
  loanPeriod: number,
  coveragePeriod: number,
  loanAmount: number,
  loan: LoanState,
  interestRate: number,
}) => {
  const {
    displayProductQuery,
    natureOfDutyCodes,
    payerRelation,
    loanPeriod,
    coveragePeriod,
    loanAmount,
    interestRate,
    loan,
  } = props
  const basicPlan: BasicPlan = await getBasicPlan(displayProductQuery)
  const sumAssured = getDefaultSumAssured(basicPlan)

  const validatedSumAssured = await validateSumAssured(
    displayProductQuery,
    natureOfDutyCodes,
    sumAssured,
    loanPeriod,
    coveragePeriod,
    loanAmount,
    interestRate,
    loan
  )
  return submitCoveragePlanPremiumCalculation(props, basicPlan, validatedSumAssured, payerRelation)
}

export const calculateCoveragePlanFromSumAssured = async (props: {
  sumAssured: number,
  displayProductQuery: DisplayProductQuery,
  insured: Insured,
  natureOfDutyCodes: string[],
  payerNatureOfDutyCodes: string[],
  modelFactorID: string,
  availableRiders: (Rider & RiderState)[],
  payerAge: Age,
  payerGender: Gender,
  userAgentType: AgentType,
  payerRelation: string,
  loanPeriod: number,
  coveragePeriod: number,
  loanAmount: number,
  loan: LoanState,
  interestRate: number,
}) => {
  let {
    sumAssured,
    displayProductQuery,
    natureOfDutyCodes,
    payerRelation,
    loanPeriod,
    coveragePeriod,
    loanAmount,
    interestRate,
    loan,
    insured,
    modelFactorID,
    availableRiders,
  } = props

  const basicPlan = await getBasicPlan(displayProductQuery)

  let sumAssuredContract = 0
  if (basicPlan.category === VALUES.MRTA) {
    const basicPremiumRate: number = await lookUpBasicPremiumRateMRTA(
      basicPlan.productCode,
      insured.gender,
      insured.age,
      displayProductQuery.productPlanCode
    )
    const riderPremiumRate: number = isMRTAGroup(displayProductQuery.code)
      ? await lookUpRiderPremiumRateMRTA(availableRiders, insured.gender, insured.age)
      : 0

    if (isMRTAGroup(displayProductQuery.code)) {
      sumAssuredContract = await calculateMRTASumAssuredContract({
        sumAssured,
        premiumRate: basicPremiumRate,
        loan,
        riderPremiumRate,
      })
    }

    if (isGLTSPGroup(displayProductQuery.code)) {
      const rateUnit: number = _.get(basicPlan, 'rateUnit', 0)
      const modelFactor: ModelFactor = await dataWrapper.getModelFactorById(modelFactorID)
      const occupationFactor: number = await getRiskiestOccupation(displayProductQuery, natureOfDutyCodes)
      const discount: number = await getBasicPlanDiscountRate(displayProductQuery, sumAssured, occupationFactor)
      const { basicPremium } = await calculateBasicPremiumAndAnnualBasicPremium({
        displayProductQuery,
        sumAssured,
        natureOfDutyCodes,
        premiumRate: basicPremiumRate,
        discount,
        rateUnit,
        modelFactor: modelFactor.factor,
        loan,
        riderPremiumRate,
      })

      sumAssuredContract = await calculateGLTSPSumAssuredContract({
        sumAssured,
        basicPremium: basicPremium,
        loan,
      })
    }
  }

  let validatedSumAssured = await validateSumAssured(
    displayProductQuery,
    natureOfDutyCodes,
    sumAssured,
    loanPeriod,
    coveragePeriod,
    loanAmount,
    interestRate,
    loan,
    sumAssuredContract
  )
  return submitCoveragePlanPremiumCalculation(props, basicPlan, validatedSumAssured, payerRelation)
}

export const calculateCoveragePlanFromPremium = async (props: {
  basicPremium: number,
  basicPremiumState: number,
  displayProductQuery: DisplayProductQuery,
  insured: Insured,
  natureOfDutyCodes: string[],
  payerNatureOfDutyCodes: string[],
  modelFactorID: string,
  availableRiders: (Rider & RiderState)[],
  payerAge: Age,
  payerGender: Gender,
  userAgentType: AgentType,
  payerRelation: string,
  loanPeriod: number,
  coveragePeriod: number,
  loanAmount: number,
  loan: LoanState,
  interestRate: number,
}) => {
  const {
    basicPremium,
    displayProductQuery,
    insured,
    natureOfDutyCodes,
    modelFactorID,
    payerRelation,
    loanPeriod,
    coveragePeriod,
    loanAmount,
    loan,
    availableRiders,
    interestRate,
  } = props
  const basicPlan: BasicPlan = await getBasicPlan(displayProductQuery)
  const basicPremiumRate =
    isMRTAGroup(displayProductQuery.code) || isGLTSPGroup(displayProductQuery.code)
      ? await lookUpBasicPremiumRateMRTA(
          basicPlan.productCode,
          insured.gender,
          insured.age,
          displayProductQuery.productPlanCode
        )
      : await lookUpBasicPremiumRate(basicPlan.productCode, insured.gender, insured.age)
  const riderPremiumRate: number = isMRTAGroup(displayProductQuery.code)
    ? await lookUpRiderPremiumRateMRTA(availableRiders, insured.gender, insured.age)
    : 0
  const discountRates = await getBasicPlanDiscountRates(displayProductQuery)
  const occupationFactor = await getRiskiestOccupation(displayProductQuery, natureOfDutyCodes)
  let discount = await getBasicPlanDiscountRate(displayProductQuery, getDefaultSumAssured(basicPlan), occupationFactor)
  const rateUnit: number = await getBasicPlanRateUnit(displayProductQuery)
  const modelFactor: ModelFactor = await dataWrapper.getModelFactorById(modelFactorID)
  let campaignDiscount: number = await getBasicPlanCampaignDiscountRate(displayProductQuery)
  const basicSumAssured = await calculateBasicSumAssured(
    {
      displayProductQuery,
      premium: basicPremium,
      natureOfDutyCodes,
      premiumRate: basicPremiumRate,
      rateUnit,
      discount,
      modelFactor: modelFactor.factor,
      loan,
      riderPremiumRate,
    },
    discountRates,
    campaignDiscount
  )

  const validatedSumAssured = await validateSumAssured(
    displayProductQuery,
    natureOfDutyCodes,
    basicSumAssured,
    loanPeriod,
    coveragePeriod,
    loanAmount,
    interestRate,
    loan
  )
  return submitCoveragePlanPremiumCalculation(props, basicPlan, validatedSumAssured, payerRelation)
}

export const populateInitialCoveragePlan = async (props: {
  displayProductQuery: DisplayProductQuery,
  insured: Insured,
  natureOfDutyCodes: string[],
  payerNatureOfDutyCodes: string[],
  modelFactorID: string,
  payerAge: Age,
  payerGender: Gender,
  userAgentType: AgentType,
  userLicenses: string[],
  payerRelation: string,
  loanPeriod: number,
  coveragePeriod: number,
  loanAmount: number,
  loan: LoanState,
  interestRate: number,
  allowOptionalIHealthyUltra: Boolean,
}) => {
  const {
    displayProductQuery,
    natureOfDutyCodes,
    userAgentType,
    insured,
    payerRelation,
    loanPeriod,
    coveragePeriod,
    loanAmount,
    interestRate,
    loan,
    allowOptionalIHealthyUltra,
  } = props
  const userLicenses = props.userLicenses || []
  const basicPlan: BasicPlan = await getBasicPlan(displayProductQuery)

  const sumAssured = getDefaultSumAssured(basicPlan)
  let availableRiders = basicPlan.riders.map((rider) => ({
    ...rider,
    isSelected: basicPlan.selectedRiders.indexOf(rider.code) > -1,
    sumAssured: 0,
    premium: 0,
    selectedPlan: getSelectedRiderPlan(
      rider,
      undefined,
      basicPlan,
      sumAssured,
      userAgentType,
      undefined,
      undefined,
      insured.age
    ),
    isSelectable: true,
    yearsOfCoverage: 0,
    yearsOfPayment: 0,
    errors: [],
  }))

  if (!getToggles().ENABLE_RIDER_PB) {
    availableRiders = availableRiders.filter((rider) => !VALUES.RIDER_PB.includes(rider.code))
  }
  if (!getToggles().ENABLE_RIDER_NEW_WP) {
    availableRiders = availableRiders.filter((rider) => ![VALUES.WPTPD, VALUES.WPTPDCI].includes(rider.code))
  }
  if (getToggles().ENABLE_LICENSE_RIDER) {
    availableRiders = availableRiders.filter(
      (rider) => !rider.hasOwnProperty('licenses') || rider.licenses.every((v) => userLicenses.includes(v))
    )
  }
  if (getToggles().ENABLE_OPTIONAL_IHEALTHY_ULTRA) {
    var foundIndex = availableRiders.findIndex((rider) => rider.code == VALUES.MHP)
    if (foundIndex >= 0) {
      var updateSelectedPlan = availableRiders[foundIndex].selectedPlan
      updateSelectedPlan = {
        ...updateSelectedPlan,
        allowOptionalIHealthyUltra: allowOptionalIHealthyUltra ? allowOptionalIHealthyUltra : false,
      }
      availableRiders[foundIndex].selectedPlan = updateSelectedPlan
    }
  }
  const validatedSumAssured = await validateSumAssured(
    displayProductQuery,
    natureOfDutyCodes,
    sumAssured,
    loanPeriod,
    coveragePeriod,
    loanAmount,
    interestRate,
    loan
  )
  return submitCoveragePlanPremiumCalculation(
    { ...props, availableRiders },
    basicPlan,
    validatedSumAssured,
    payerRelation
  )
}

const submitCoveragePlanPremiumCalculation = async (
  props: {
    displayProductQuery: DisplayProductQuery,
    insured: Insured,
    natureOfDutyCodes: string[],
    payerNatureOfDutyCodes: string[],
    modelFactorID: string,
    availableRiders: (Rider & RiderState)[],
    payerAge: Age,
    payerGender: Gender,
    userAgentType: AgentType,
    payerRelation: string,
    loan: LoanState,
  },
  basicPlan: BasicPlan,
  validatedSumAssured: SumAssured$
) => {
  const { displayProductQuery, insured, modelFactorID, natureOfDutyCodes, payerRelation, availableRiders } = props
  const occupationFactor: number = await getRiskiestOccupation(displayProductQuery, natureOfDutyCodes)
  const discount: number = await getBasicPlanDiscountRate(
    displayProductQuery,
    validatedSumAssured.value,
    occupationFactor
  )
  const basicPremiumRate: number =
    isMRTAGroup(displayProductQuery.code) || isGLTSPGroup(displayProductQuery.code)
      ? await lookUpBasicPremiumRateMRTA(
          basicPlan.productCode,
          insured.gender,
          insured.age,
          displayProductQuery.productPlanCode
        )
      : await lookUpBasicPremiumRate(basicPlan.productCode, insured.gender, insured.age)

  const riderPremiumRate: number = isMRTAGroup(displayProductQuery.code)
    ? await lookUpRiderPremiumRateMRTA(availableRiders, insured.gender, insured.age)
    : 0

  const rateUnit: number = _.get(basicPlan, 'rateUnit', 0)
  const modelFactor: ModelFactor = await dataWrapper.getModelFactorById(modelFactorID)
  const campaignDiscount: number = await getBasicPlanCampaignDiscountRate(displayProductQuery)
  const basicPlanCampaignDiscount = await getBasicPlanCampaignDiscount(displayProductQuery)
  let campaignDiscountMessage = []
  if (basicPlanCampaignDiscount.campaignDiscountMessage) {
    campaignDiscountMessage = basicPlanCampaignDiscount.campaignDiscountMessage
  }
  let campaignDiscountEndDate = ''
  if (basicPlanCampaignDiscount.endDate) {
    campaignDiscountEndDate = basicPlanCampaignDiscount.endDate
  }
  return calculateCoveragePlanPremium(
    props,
    validatedSumAssured,
    discount,
    basicPremiumRate,
    rateUnit,
    modelFactor,
    basicPlan,
    payerRelation,
    riderPremiumRate,
    campaignDiscount,
    campaignDiscountMessage,
    campaignDiscountEndDate
  )
}

const calculateCoveragePlanPremium = async (
  props: {
    displayProductQuery: DisplayProductQuery,
    insured: Insured,
    natureOfDutyCodes: string[],
    payerNatureOfDutyCodes: string[],
    modelFactorID: string,
    availableRiders: (Rider & RiderState)[],
    payerAge: Age,
    payerGender: Gender,
    userAgentType: AgentType,
    loan: LoanState,
  },
  validatedSumAssured: SumAssured$,
  discount: number,
  basicPremiumRate: number,
  rateUnit: number,
  modelFactor: ModelFactor,
  basicPlan: BasicPlan,
  payerRelation: String,
  riderPremiumRate: number,
  campaignDiscount: number,
  campaignDiscountMessage: string[],
  campaignDiscountEndDate: string
) => {
  const {
    displayProductQuery,
    insured,
    natureOfDutyCodes,
    payerNatureOfDutyCodes,
    modelFactorID,
    availableRiders,
    payerAge,
    payerGender,
    userAgentType,
    loan,
  } = props
  const {
    basicPremium,
    annualBasicPremium,
    fullBasicPremium,
    fullAnnualBasicPremium,
  } = await calculateBasicPremiumAndAnnualBasicPremium({
    displayProductQuery,
    sumAssured: validatedSumAssured.value,
    natureOfDutyCodes,
    premiumRate: basicPremiumRate,
    discount,
    rateUnit,
    modelFactor: modelFactor.factor,
    loan,
    riderPremiumRate,
    campaignDiscount,
  })

  // add additional value for yearly MRTA
  let sumAssuredContract
  if (isMRTAGroup(displayProductQuery.code)) {
    sumAssuredContract = await calculateMRTASumAssuredContract({
      sumAssured: validatedSumAssured.value,
      premiumRate: basicPremiumRate,
      loan,
      riderPremiumRate,
    })
  }

  if (isGLTSPGroup(displayProductQuery.code)) {
    sumAssuredContract = await calculateGLTSPSumAssuredContract({
      sumAssured: validatedSumAssured.value,
      basicPremium: basicPremium,
      loan,
    })
  }

  const accurateRiders = await getAccurateRiders({
    riders: availableRiders,
    basicSumAssured: validatedSumAssured.value,
    displayProductQuery,
    natureOfDutyCodes: natureOfDutyCodes,
    payerNatureOfDutyCodes: payerNatureOfDutyCodes,
    userAgentType,
    insured,
    payerAge,
  })

  const riderPremiumRates = await getRiderPremiumRates(
    insured.age,
    payerAge,
    insured.gender,
    payerGender,
    accurateRiders,
    natureOfDutyCodes,
    displayProductQuery
  )
  const riderOccupationFactors = await getRiskiestOccupationFactorForRiders(
    displayProductQuery,
    natureOfDutyCodes,
    accurateRiders
  )
  const riderPayerOccupationFactors = await getRiskiestOccupationFactorForRiders(
    displayProductQuery,
    payerNatureOfDutyCodes,
    accurateRiders
  )
  // IT will set SA for it should refactor to include to calculateSumAssuredAndPremiumForSelectedRiders
  const updatedSARiders = await getUpdatedRidersSAFromAnnualBasicPremium({
    riders: accurateRiders,
    displayProductQuery,
    sumAssured: validatedSumAssured.value,
    natureOfDutyCodes,
    premiumRate: basicPremiumRate,
    discount,
    rateUnit,
    modelFactor: modelFactor,
    annualBasicPremium,
    loan,
    campaignDiscount,
  })

  const updatedRiders = calculateSumAssuredAndPremiumForSelectedRiders({
    riders: updatedSARiders,
    validatedSumAssured,
    basicPremium,
    annualBasicPremium,
    riderPremiumRates,
    riderOccupationFactors,
    modelFactor,
    basicPlan,
    userAgentType,
    riderPayerOccupationFactors,
    loan,
    sumAssuredContract,
  })

  const validatedRiders = await validateRiders(
    updatedRiders,
    insured,
    payerAge,
    validatedSumAssured.value,
    natureOfDutyCodes,
    payerNatureOfDutyCodes,
    displayProductQuery,
    payerRelation,
    loan,
    userAgentType
  )
  const riderPremium = totalRiderPremium(validatedRiders)
  const totalPremium = calculateTotalPremium(basicPremium, riderPremium)

  return {
    totalPremium: validateTotalPremium({ basicSumAssured: validatedSumAssured.value, modelFactorID }, totalPremium),
    riderPremium: riderPremium,
    validatedRiders,
    basicPremium,
    validatedSumAssured,
    sumAssuredContract,
    fullBasicPremium,
    fullAnnualBasicPremium,
    campaignDiscountMessage,
    campaignDiscountEndDate,
  }
}
