// @flow
import { formatNumber } from 'core/service/lib/number-format'
import type { ProductBenefitProps } from './product-benefits'
import Mustache from 'mustache'
import MESSAGES from 'core/data-model/constants/bi-messages'

const ProductBenefitsSummary = ({
  displayProduct,
  sumAssured,
  basicPremium,
  coverageTerm,
  loanTerm,
}: ProductBenefitProps) => {
  return (
    <div id="product-benefits-summary" className="basicPlan">
      <h3 className="name">
        {Mustache.render(MESSAGES.BASIC_PLAN_DETAILS, {
          name: displayProduct.basicPlanName,
          code: displayProduct.basicPlanCode,
        })}
      </h3>
      <div className="product d-none d-md-block">
        <div className="info-list">
          <div id="benefits-coverage-period" className="info">
            <p className="label">{MESSAGES.LOAN_PERIOD_INSURED}</p>
            <p className="value">{loanTerm} ปี</p>
          </div>
          <div id="benefits-payment-period" className="info">
            <p className="label">{MESSAGES.COVERAGE_PERIOD_INSURED}</p>
            <p className="value">{coverageTerm} ปี</p>
          </div>
          <div id="benefits-sum-assured" className="info">
            <p className="label">{MESSAGES.LOAN_SUM_ASSURE}</p>
            <p className="value">{formatNumber(sumAssured)} บาท</p>
          </div>
          <div id="benefits-basic-premium" className="info">
            <p className="label">{MESSAGES.BASIC_PREMIUM}</p>
            <p className="value">{formatNumber(basicPremium, 2)} บาท</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductBenefitsSummary
