// @flow
import type { Effect } from 'redux-saga/effects'
import { takeEvery, takeLatest, put, select, call } from 'redux-saga/effects'

import _ from 'lodash'
import moment from 'moment'
import type { Age, Insured, NatureOfDuty } from 'core/data-model/insured'
import type { DisplayProduct } from 'core/service/display-product'
import type {
  SelectOccupation,
  SelectOtherOccupation,
  EditBirthdate,
  EditPayerBirthdate,
} from 'quick-quote/insured-information/actions'
import { isNotNil } from 'core/service/lib/type-check'
import type { VisitPath } from 'quick-quote/ui/actions'

import type { DataWrapper } from 'core/data'

import { getToggles } from 'quick-quote/feature-toggles'
import { isUnderage, isPreAdult, isPreAdultForPayerRelation, isKidForPayerRelation } from 'core/service/insured/age'
import {
  SELECT_OCCUPATION,
  SELECT_OTHER_OCCUPATION,
  SELECT_PAYER_OCCUPATION,
  SELECT_NATURE_OF_DUTY,
  SELECT_PAYER_NATURE_OF_DUTY,
  SELECT_PAYER_OTHER_NATURE_OF_DUTY,
  SELECT_PAYER_RELATION,
  VALIDATE_INSURED_INFORMATION,
  SELECT_OTHER_NATURE_OF_DUTY,
  selectOccupation,
  selectNatureOfDuty,
  selectOtherNatureOfDuty,
  selectPayerOccupation,
  selectPayerNatureOfDuty,
  selectPayerRelation,
  EDIT_BIRTHDATE,
  UPDATE_BIRTHDATE,
  SELECT_GENDER,
  updatePayerNeeded,
  updateBirthdate,
  EDIT_PAYER_BIRTHDATE,
  updatePayerBirthdate,
  updateOccupationErrors,
  updateOtherOccupationErrors,
  updatePayerOccupationErrors,
  updatePayerOtherOccupationErrors,
  editBirthdate,
  editPayerBirthdate,
} from './actions'

import {
  GET_OCCUPATIONS_REQUEST,
  INITIALISE_APPLICATION_SUCCESS,
  getOccupationsFailed,
  getOccupationsSuccess,
  GET_OCCUPATION_FACTORS_REQUEST,
  getOccupationFactorsSuccess,
  getOccupationFactorsFailed,
} from 'quick-quote/actions'

import { VISIT_PATH } from 'quick-quote/ui/actions'

import { RESET_QUOTE_FORM_AFTER_MATURITY_VALIDATE } from 'quick-quote/product-s7cc/actions'

import { IHEALTHY_ULTRA_SELECT_BASIC_PLAN } from 'quick-quote/product-ihealthy-ultra/coverage-plan/actions'

import { TitleRedux } from 'quick-quote/insured-information/redux/title'

import { editPerfectLifePlan } from 'quick-quote/product-perfect-life/coverage-plan/actions'

import { setFnaGapValue } from 'quick-quote/fna/need-and-gap-analysis/actions'

import {
  getNatureOfDuties,
  getOtherNatureOfDuties,
  getAge,
  getPayerAge,
  getPayerNeeded,
  getBirthdate,
  getPayerBirthdate,
  getBirthdateErrors,
  getPayerBirthdateErrors,
  getPayerNatureOfDuties,
  getPayerRelation,
  getDefaultPayerNatureOfDuty,
  getInsured,
  getSelectedNatureOfDutyCode,
  getSelectedOtherNatureOfDutyCode,
  getSelectedPayerNatureOfDutyCode,
  getSelectedPayerOtherNatureOfDutyCode,
} from './selectors'

import {
  isPerfectLifeSolution,
  getSelectedDisplayProduct,
  getSelectedDisplayProductQuery,
  getSelectedDisplayProductValidOccupationFactors,
} from 'quick-quote/product-selection/selectors'

import { isReviseQQFlow } from 'quick-quote/selectors'

import * as insureValidationService from 'core/service/insured/validation'
import { getSumAssured$, getAvailableRiders } from 'quick-quote/product-common/coverage-plan/selectors'
import { validateRiders } from 'core/service/rider'
import { SELECTED_PRODUCT } from '../product-selection/actions'
import { getSelectedNeedType } from '../fna/need-and-gap-analysis/selectors'
import { getUserAgentType } from 'identity/selectors'
import { getLoan } from 'quick-quote/product-mrta/coverage-plan/selectors'
import CONSTANTS from 'core/data-model/constants/values'
import VALIDATION from 'core/data-model/constants/validation-messages'
import constants from 'core/data-model/constants/defaults'
import { convertAgeToBirthdate } from './utils'

const getDefaultNatureOfDuty = (natureOfDuties, occupationCode): NatureOfDuty => {
  if (!_.isEmpty(occupationCode)) {
    const [defaultNatureOfDuty] = natureOfDuties
    return defaultNatureOfDuty
  }

  return { code: '', text: '' }
}

export function* updateNatureOfDuty(action: SelectOccupation): Generator<*, *, *> {
  const occupationCode = action.payload
  const natureOfDuties = yield select(getNatureOfDuties, occupationCode)
  const natureOfDuty = getDefaultNatureOfDuty(natureOfDuties, occupationCode)
  yield put(selectNatureOfDuty(natureOfDuty))
}

export function* updateNatureOfDutyByCode(occupationCode: string, natureOfDutyCode: string): Generator<*, *, *> {
  const natureOfDuties = yield select(getNatureOfDuties, occupationCode)
  const [selectedNatureOfDuty] = natureOfDuties.filter((n) => n.code === natureOfDutyCode)
  if (selectedNatureOfDuty) {
    yield put(selectNatureOfDuty(selectedNatureOfDuty))
  } else {
    const defaultNatureOfDuty = getDefaultNatureOfDuty(natureOfDuties, occupationCode)
    yield put(selectNatureOfDuty(defaultNatureOfDuty))
  }
}

export function* updateOtherNatureOfDuty(action: SelectOtherOccupation): Generator<*, *, *> {
  const occupationCode = action.payload
  const natureOfDuties = yield select(getOtherNatureOfDuties, occupationCode)
  const natureOfDuty = getDefaultNatureOfDuty(natureOfDuties, occupationCode)
  yield put(selectOtherNatureOfDuty(natureOfDuty))
}

export function* updatePayerNatureOfDuty(): Generator<*, *, *> {
  const natureOfDuty = yield select(getDefaultPayerNatureOfDuty)
  yield put(selectPayerNatureOfDuty(natureOfDuty))
}

export function* updatePayerNatureOfDutyByCode(natureOfDutyCode: string): Generator<*, *, *> {
  const natureOfDuties = yield select(getPayerNatureOfDuties)
  const [selectedNatureOfDuty] = natureOfDuties.filter((n) => n.code === natureOfDutyCode)
  if (selectedNatureOfDuty) {
    yield put(selectPayerNatureOfDuty(selectedNatureOfDuty))
  } else {
    const defaultNatureOfDuty = yield select(getDefaultPayerNatureOfDuty)
    yield put(selectPayerNatureOfDuty(defaultNatureOfDuty))
  }
}

export function* riderValidationForOccupation(
  updateErrorOccupation: *,
  insured: Insured,
  natureOfDutyCode: string,
  displayProductQuery: DisplayProduct
): Generator<*, *, *> {
  const selectedDisplayProduct = yield select(getSelectedDisplayProduct)
  const { requiredRiders = [] } = selectedDisplayProduct
  const CAMPAIGN_OCCUPATION_VALIDATION_MESSAGES = [
    VALIDATION.RULE_RIDER_OCCUPATION_FACTOR,
    VALIDATION.RULE_RIDER_OCCUPATION,
  ]
  const availableRiders = yield select(getAvailableRiders)
  const payerAge: Age = yield select(getPayerAge)
  const sumAssured$ = yield select(getSumAssured$)
  const natureOfDutyCodes = [natureOfDutyCode]
  const payerNatureOfdutyCodes = []
  const payerRelation = yield select(getPayerRelation)
  const loan = yield select(getLoan)
  const userAgentType = yield select(getUserAgentType)
  const validatedRiders = yield call(
    validateRiders,
    availableRiders,
    insured,
    payerAge,
    sumAssured$.value,
    natureOfDutyCodes,
    payerNatureOfdutyCodes,
    displayProductQuery,
    payerRelation,
    loan,
    userAgentType
  )
  const validatedRequiredRiders = validatedRiders.filter((rider) => requiredRiders.indexOf(rider.code) > -1)

  let riderValidationFailsForOccupation
  let errorMessage

  if (
    selectedDisplayProduct.type === CONSTANTS.CAMPAIGN_PRODUCT_TYPE &&
    (CONSTANTS.PERFECTLIFE === selectedDisplayProduct.category ||
      CONSTANTS.HEALTH_TOPPING === selectedDisplayProduct.category ||
      CONSTANTS.SUKHAPABDEKDEE === selectedDisplayProduct.category ||
      CONSTANTS.IHEALTHY_ULTRA === selectedDisplayProduct.category)
  ) {
    if (
      CONSTANTS.PERFECTLIFE === selectedDisplayProduct.category ||
      CONSTANTS.SUKHAPABDEKDEE === selectedDisplayProduct.category
    ) {
      riderValidationFailsForOccupation = validatedRequiredRiders.some(
        (rider) =>
          rider.errors.filter((error) => CAMPAIGN_OCCUPATION_VALIDATION_MESSAGES.indexOf(error.message) > -1).length > 0
      )
      errorMessage = VALIDATION.RULE_INSURED_PRODUCT_OCCUPATION_FACTOR
    } else {
      riderValidationFailsForOccupation =
        validatedRequiredRiders.filter((rider) =>
          isNotNil(rider.errors.find((error) => error.type !== CONSTANTS.ADDITIONAL_INFORMATION))
        ).length > 0
      errorMessage = VALIDATION.RULE_INSURED_OCCUPATION_FACTOR
    }
  } else if (
    selectedDisplayProduct.type === CONSTANTS.CAMPAIGN_PRODUCT_TYPE ||
    CONSTANTS.LIFEREADY_CAMPAIGN_CODE.includes(selectedDisplayProduct.basicPlanCode)
  ) {
    riderValidationFailsForOccupation = validatedRequiredRiders.some(
      (rider) =>
        rider.errors.filter((error) => CAMPAIGN_OCCUPATION_VALIDATION_MESSAGES.indexOf(error.message) > -1).length > 0
    )
    errorMessage = VALIDATION.RULE_RIDER_OCCUPATION
  } else if ([CONSTANTS.HEALTH, CONSTANTS.FLEXI_HEALTH, CONSTANTS.PA].includes(selectedDisplayProduct.category)) {
    riderValidationFailsForOccupation = validatedRequiredRiders.some((rider) => rider.errors.length > 0)
    errorMessage = VALIDATION.RULE_INSURED_OCCUPATION_FACTOR
  }

  if (riderValidationFailsForOccupation) {
    yield put(updateErrorOccupation([errorMessage]))
  }
}

export function* riderValidationForPayerOccupation(
  updatePayerOccupationErrors: *,
  insured: Insured,
  payerNatureOfDutyCode: string,
  displayProductQuery: DisplayProduct,
  isOtherOccupation: boolean
): Generator<*, *, *> {
  const selectedDisplayProduct = yield select(getSelectedDisplayProduct)
  const { requiredRiders = [] } = selectedDisplayProduct
  const availableRiders = yield select(getAvailableRiders)
  const payerAge: Age = yield select(getPayerAge)
  const sumAssured$ = yield select(getSumAssured$)
  const natureOfDutyCodes = []
  const payerRelation = yield select(getPayerRelation)
  const loan = yield select(getLoan)
  const userAgentType = yield select(getUserAgentType)
  const validatedRiders = yield call(
    validateRiders,
    availableRiders,
    insured,
    payerAge,
    sumAssured$.value,
    natureOfDutyCodes,
    [payerNatureOfDutyCode],
    displayProductQuery,
    payerRelation,
    loan,
    userAgentType
  )
  const validatedRequiredRiders = validatedRiders.filter((rider) => requiredRiders.indexOf(rider.code) > -1)

  let riderValidationFailsForOccupation
  let errorMessage = ''

  if (selectedDisplayProduct.category === CONSTANTS.SAVING) {
    riderValidationFailsForOccupation = validatedRequiredRiders.some((rider) => {
      return rider.errors.filter((error) => error.message === VALIDATION.RULE_INSURED_OCCUPATION_FACTOR).length > 0
    })
    errorMessage = VALIDATION.RULE_INSURED_OCCUPATION_FACTOR
  }

  if (riderValidationFailsForOccupation) {
    if (isOtherOccupation) {
      yield put(updatePayerOtherOccupationErrors([errorMessage]))
    } else {
      yield put(updatePayerOccupationErrors([errorMessage]))
    }
  } else {
    if (isOtherOccupation) {
      yield put(updatePayerOtherOccupationErrors([]))
    } else {
      yield put(updatePayerOccupationErrors([]))
    }
  }
}

export function* validateOccupation(): Generator<*, *, *> {
  const displayProductQuery = yield select(getSelectedDisplayProductQuery)
  const natureOfDutyCode = yield select(getSelectedNatureOfDutyCode)
  const validOccupationFactorForProduct = yield select(getSelectedDisplayProductValidOccupationFactors)

  let errors = []
  // TODO: can remove this "if" when those 2 function are grouped
  if (validOccupationFactorForProduct.length > 0) {
    errors = yield call(insureValidationService.validateValidOccupationFactor, displayProductQuery, natureOfDutyCode)
  } else {
    errors = yield call(insureValidationService.validateOccupationFactor, displayProductQuery, natureOfDutyCode)
  }
  yield put(updateOccupationErrors(errors))
  const { age, gender, occupation } = yield select(getInsured)
  const insured = { age, gender, occupation }
  yield call(riderValidationForOccupation, updateOccupationErrors, insured, natureOfDutyCode, displayProductQuery)
}

export function* validatePayerOccupation(): Generator<*, *, *> {
  const selectedDisplayProduct = yield select(getSelectedDisplayProduct)
  if (selectedDisplayProduct.category === CONSTANTS.INVESTMENT) return

  const isPayerNeeded = yield select(getPayerNeeded)
  if (!isPayerNeeded) return

  const displayProductQuery = yield select(getSelectedDisplayProductQuery)
  const payerNatureOfDutyCode = yield select(getSelectedPayerNatureOfDutyCode)

  const { age, gender } = yield select(getInsured)
  const insured = { age, gender }

  yield call(
    riderValidationForPayerOccupation,
    updatePayerOccupationErrors,
    insured,
    payerNatureOfDutyCode,
    displayProductQuery,
    false
  )
}

export function* validatePayerOtherOccupation(): Generator<*, *, *> {
  const selectedDisplayProduct = yield select(getSelectedDisplayProduct)
  if (selectedDisplayProduct.category !== CONSTANTS.SAVING) return

  const isPayerNeeded = yield select(getPayerNeeded)
  if (!isPayerNeeded) return

  const displayProductQuery = yield select(getSelectedDisplayProductQuery)
  const payerOtherNatureOfDutyCode = yield select(getSelectedPayerOtherNatureOfDutyCode)

  const { age, gender } = yield select(getInsured)
  const insured = { age, gender }

  yield call(
    riderValidationForPayerOccupation,
    updatePayerOtherOccupationErrors,
    insured,
    payerOtherNatureOfDutyCode,
    displayProductQuery,
    true
  )
}

export function* validateOtherOccupation(): Generator<*, *, *> {
  if (!getToggles().VALIDATE_OTHER_OCCUPATION) {
    return
  }
  const displayProductQuery = yield select(getSelectedDisplayProductQuery)
  const natureOfDutyCode = yield select(getSelectedOtherNatureOfDutyCode)

  const errors = yield call(insureValidationService.validateOccupationFactor, displayProductQuery, natureOfDutyCode)
  yield put(updateOtherOccupationErrors(errors))

  const { age, gender, otherOccupation } = yield select(getInsured)
  const insured = { age, gender, otherOccupation }
  yield call(riderValidationForOccupation, updateOtherOccupationErrors, insured, natureOfDutyCode, displayProductQuery)
}

export function* onGetOccupationsRequest(dataWrapper: DataWrapper): Generator<*, *, *> {
  try {
    const occupations = yield call(dataWrapper.getOccupations)
    yield put(getOccupationsSuccess(occupations))
  } catch (error) {
    yield put(getOccupationsFailed(error))
  }
}

export function* onGetOccupationFactorsRequest(dataWrapper: DataWrapper): Generator<*, *, *> {
  try {
    const occupationFactors = yield call(dataWrapper.getOccupationFactors)
    yield put(getOccupationFactorsSuccess(occupationFactors))
  } catch (error) {
    yield put(getOccupationFactorsFailed(error))
  }
}

export function* onSelectedProduct(): Generator<*, *, *> {
  const selectedDisplayProduct = yield select(getSelectedDisplayProduct)
  const currentBirthdate = yield select(getBirthdate) || ''
  const currentPayerBirthdate = yield select(getPayerBirthdate) || ''
  const _isPerfectLifeSolution = yield select(isPerfectLifeSolution)

  if (currentBirthdate === '') {
    yield call(setDefaultInsuredAge, selectedDisplayProduct, currentBirthdate)
    yield call(setDefaultInsureOccupation, selectedDisplayProduct)
  }

  if (currentPayerBirthdate === '') {
    yield call(setDefaultPayerAge, selectedDisplayProduct, currentPayerBirthdate)
    yield call(setDefaultPayerOccupation, selectedDisplayProduct)
  }

  if (selectedDisplayProduct.type === 'campaign' && _isPerfectLifeSolution) {
    yield call(setDefaultSelectedCampaignPlan, selectedDisplayProduct)
  }

  if (selectedDisplayProduct.category === CONSTANTS.HEALTH_TOPPING) {
    yield call(setDefaultHealthToppingPlan, selectedDisplayProduct)
  }

  if (selectedDisplayProduct.category === CONSTANTS.SUKHAPABDEKDEE) {
    yield call(setDefaultSukhapabDekDeePlan, selectedDisplayProduct)
  }
}

export function* setDefaultSukhapabDekDeePlan(selectedDisplayProduct: DisplayProduct): Generator<*, *, *> {
  selectedDisplayProduct.selectedCampaignPlan = CONSTANTS.SUKHAPABDEKDEE_DEFAULT_CAMPAIGNPLAN
}

export function* setDefaultHealthToppingPlan(selectedDisplayProduct: DisplayProduct): Generator<*, *, *> {
  selectedDisplayProduct.selectedCampaignPlan = 'PLAN_A'
}

export function* setDefaultSelectedCampaignPlan(selectedDisplayProduct: DisplayProduct): Generator<*, *, *> {
  selectedDisplayProduct.selectedCampaignPlan = 'PLAN_1'
  yield put(editPerfectLifePlan(selectedDisplayProduct))
}

export function* setDefaultInsuredAge(
  selectedDisplayProduct: DisplayProduct,
  currentBirthdate: string
): Generator<*, *, *> {
  const defaultInsuredAge = _.get(selectedDisplayProduct, 'defaults.insured.age', constants.DEFAULT_INSURED_AGE)
  const isReviseQQ = yield select(isReviseQQFlow)

  if (isReviseQQ) {
    const defaultYear = defaultInsuredAge.value
    const currentDate = moment().subtract(defaultYear, 'years')
    const formatDate = currentDate.format('DD/MM/YYYY')
    yield put(updateBirthdate(formatDate, [], defaultInsuredAge))
  } else {
    yield put(updateBirthdate(currentBirthdate, [], defaultInsuredAge))
  }
  yield put(updatePayerNeeded(isUnderage(defaultInsuredAge)))
  if (isUnderage(defaultInsuredAge)) yield put(selectPayerRelation(constants.PARENT))
  else yield put(selectPayerRelation(constants.PAY_MY_SELF))
}

export function* setDefaultPayerAge(
  selectedDisplayProduct: DisplayProduct,
  currentPayerBirthdate: string
): Generator<*, *, *> {
  const defaultPayerAge = _.get(selectedDisplayProduct, 'defaults.payer.age', constants.DEFAULT_PAYER_AGE)
  const isReviseQQ = yield select(isReviseQQFlow)
  if (isReviseQQ) {
    const defaultYear = defaultPayerAge.value
    const currentDate = moment().subtract(defaultYear, 'years')
    const formatDate = currentDate.format('DD/MM/YYYY')
    yield put(updatePayerBirthdate(formatDate, [], defaultPayerAge))
  } else {
    yield put(updatePayerBirthdate(currentPayerBirthdate, [], defaultPayerAge))
  }
}

export function* setDefaultInsureOccupation(selectedDisplayProduct: DisplayProduct): Generator<*, *, *> {
  const defaultOccupationCode = _.get(
    selectedDisplayProduct,
    'defaults.insured.occupation.occupationCode',
    getToggles().ENABLE_OCCUPATION_BLANK ? '' : constants.DEFAULT_OCCUPATION_CODE
  )
  yield put(selectOccupation(defaultOccupationCode))

  const natureOfDutyCode = _.get(
    selectedDisplayProduct,
    'defaults.insured.occupation.natureOfDutyCode',
    getToggles().ENABLE_OCCUPATION_BLANK ? '' : constants.DEFAULT_NATURE_OF_DUTY_CODE
  )
  yield call(updateNatureOfDutyByCode, defaultOccupationCode, natureOfDutyCode)
}

export function* setDefaultPayerOccupation(selectedDisplayProduct: DisplayProduct): Generator<*, *, *> {
  if (selectedDisplayProduct.category === CONSTANTS.INVESTMENT) {
    yield put(selectPayerOccupation(''))
    yield put(selectPayerNatureOfDuty({ code: '', text: '' }))
  } else {
    const payerDefaultOccupationCode = _.get(
      selectedDisplayProduct,
      'defaults.payer.occupation.occupationCode',
      getToggles().ENABLE_OCCUPATION_BLANK ? '' : constants.DEFAULT_OCCUPATION_CODE
    )
    yield put(selectPayerOccupation(payerDefaultOccupationCode))

    const natureOfDutyCode = _.get(
      selectedDisplayProduct,
      'defaults.payer.occupation.natureOfDutyCode',
      getToggles().ENABLE_OCCUPATION_BLANK ? '' : constants.DEFAULT_NATURE_OF_DUTY_CODE
    )
    yield call(updatePayerNatureOfDutyByCode, natureOfDutyCode)
  }
}

export function* runInsuredBirthdateValidationFromState(): Generator<*, *, *> {
  const dobInState = yield select(getBirthdate)
  if (dobInState === undefined) {
    return
  }
  yield* validateDateOfBirth(dobInState)
}

export function* validateInsuredBirthdate(action: EditBirthdate): Generator<*, *, *> {
  const { birthdate } = action // payload when DOB is edited
  yield* validateDateOfBirth(birthdate)
}

export function* validateDateOfBirth(birthdateToValidate: string): Generator<*, *, *> {
  const previousAge: Age = yield select(getAge)
  const insuredAgeError = yield select(getBirthdateErrors)
  const displayProductQuery = yield select(getSelectedDisplayProductQuery)
  const currentDate: string = moment().format('DD/MM/YYYY')

  const result = yield call(
    insureValidationService.validateBirthdate,
    birthdateToValidate,
    displayProductQuery,
    previousAge,
    currentDate,
    false
  )

  const hasMonthsYearsUnitChanged = previousAge.unit !== result.age.unit
  const hasAgeValueChanged = previousAge.value !== result.age.value
  const hasErrorChanged = !_.isEqual(insuredAgeError, result.error)

  const stateStoreBirthdate = yield select(getBirthdate)
  const isStoreBirthdayEmpty = stateStoreBirthdate.length === 0
  const isFirstTimeFilledValidBirthday =
    !insureValidationService.isPartiallyFilled(birthdateToValidate) && isStoreBirthdayEmpty

  const hasDOBChanged = stateStoreBirthdate !== birthdateToValidate
  const hasDOBChangeWithSameAge =
    !insureValidationService.isPartiallyFilled(stateStoreBirthdate) &&
    !insureValidationService.isPartiallyFilled(birthdateToValidate) &&
    hasDOBChanged &&
    !hasAgeValueChanged

  if (
    hasMonthsYearsUnitChanged ||
    hasAgeValueChanged ||
    hasErrorChanged ||
    isFirstTimeFilledValidBirthday ||
    hasDOBChangeWithSameAge
  ) {
    yield put(updateBirthdate(result.birthdate, result.error, result.age))
    yield call(checkIfUnderage, previousAge, result.age)

    if (getToggles().ENABLE_NEW_PAYER_SELECTION) yield call(validatePayerRelationWithAgeChange, previousAge, result.age)
  }
}

export function* validatePayerInformationNeeded(action): Generator<*, *, *> {
  if (action.payload === constants.PAY_MY_SELF) yield put(updatePayerNeeded(false))
  else yield put(updatePayerNeeded(true))
}

export function* validatePayerBirthdate(action: EditPayerBirthdate): Generator<*, *, *> {
  const { birthdate } = action
  const previousAge: Age = yield select(getPayerAge)
  const payerAgeError = yield select(getPayerBirthdateErrors)
  const displayProductQuery = yield select(getSelectedDisplayProductQuery)
  const currentDate: string = moment().format('DD/MM/YYYY')
  const result = yield call(
    insureValidationService.validateBirthdate,
    birthdate,
    displayProductQuery,
    previousAge,
    currentDate,
    true
  )

  const hasMonthsYearsUnitChanged = previousAge.unit !== result.age.unit
  const hasAgeValueChanged = previousAge.value !== result.age.value
  const hasErrorChanged = !_.isEqual(payerAgeError, result.error)

  const stateStoreBirthdate = yield select(getPayerBirthdate)
  const isStoreBirthdayEmpty = stateStoreBirthdate.length === 0
  const isFirstTimeFilledValidBirthday = !insureValidationService.isPartiallyFilled(birthdate) && isStoreBirthdayEmpty

  const hasDOBChanged = stateStoreBirthdate !== birthdate
  const hasDOBChangeWithSameAge =
    !insureValidationService.isPartiallyFilled(stateStoreBirthdate) &&
    !insureValidationService.isPartiallyFilled(birthdate) &&
    hasDOBChanged &&
    !hasAgeValueChanged

  if (
    hasMonthsYearsUnitChanged ||
    hasAgeValueChanged ||
    hasErrorChanged ||
    isFirstTimeFilledValidBirthday ||
    hasDOBChangeWithSameAge
  ) {
    yield put(updatePayerBirthdate(result.birthdate, result.error, result.age))
  }
}

export function* setDefaultOccupation(occupationCode: string): Generator<*, *, *> {
  const defaultOccupationCode = getToggles().ENABLE_OCCUPATION_BLANK ? '' : occupationCode
  yield put(selectOccupation(defaultOccupationCode))
}

export function* setDefaultNatureOfDuty(natureOfDufy: NatureOfDuty): Generator<*, *, *> {
  const defaultNatureOfDuty = {
    code: getToggles().ENABLE_OCCUPATION_BLANK ? '' : natureOfDufy.code,
    text: getToggles().ENABLE_OCCUPATION_BLANK ? '' : natureOfDufy.text,
  }
  yield put(selectNatureOfDuty(defaultNatureOfDuty))
}

export function* validatePayerRelationWithAgeChange(previousAge: Age, age: Age): Generator<*, *, *> {
  const getTypePayerRelation = (age) => {
    if (!isUnderage(age)) return constants.ADULT
    else if (isPreAdultForPayerRelation(age)) return constants.PRE_ADULT
    else if (isKidForPayerRelation(age)) return constants.KID
    else return constants.ADULT
  }

  const previousAgeTypeInsured = getTypePayerRelation(previousAge)
  const ageTypeInsured = getTypePayerRelation(age)
  if (previousAgeTypeInsured !== ageTypeInsured) {
    if (ageTypeInsured === constants.ADULT) yield put(selectPayerRelation(constants.PAY_MY_SELF))
    else yield put(selectPayerRelation(constants.PARENT))
  }
}

export function* checkIfUnderage(previousAge: Age, age: Age): Generator<*, *, *> {
  const oldPayerNeeded = yield select(getPayerNeeded)
  const payerRelation = yield select(getPayerRelation)
  const payerNeeded: boolean = getToggles().ENABLE_NEW_PAYER_SELECTION
    ? isUnderage(age) || payerRelation === constants.SPOUSE
    : isUnderage(age)
  const previousPreAdult = isPreAdult(previousAge)
  const currentPreAdult = isPreAdult(age)
  const hasUnderAgeChanged = payerNeeded && previousPreAdult !== currentPreAdult
  const needGapType = yield select(getSelectedNeedType)

  if (getToggles().ENABLE_FNA_PHASE2 && payerNeeded && needGapType === CONSTANTS.NEED_CATEGORY.EDUCATION) {
    yield put(setFnaGapValue('numberOfChild', '0'))
  }

  if (oldPayerNeeded !== payerNeeded) {
    yield put(updatePayerNeeded(payerNeeded))

    if (payerNeeded) {
      yield call(setDefaultOccupation, constants.DEFAULT_UNDERAGE_OCCUPATION_CODE)
      if (currentPreAdult) {
        yield call(setDefaultNatureOfDuty, {
          code: constants.DEFAULT_UNDERAGE_18_19_NATURE_OF_DUTY_CODE,
          text: constants.DEFAULT_UNDERAGE_18_19_NATURE_OF_DUTY_TEXT,
        })
      } else {
        yield call(setDefaultNatureOfDuty, {
          code: constants.DEFAULT_UNDERAGE_NATURE_OF_DUTY_CODE,
          text: constants.DEFAULT_UNDERAGE_NATURE_OF_DUTY_TEXT,
        })
      }
    } else {
      yield call(setDefaultOccupation, constants.DEFAULT_OCCUPATION_CODE)
      yield call(setDefaultNatureOfDuty, {
        code: constants.DEFAULT_NATURE_OF_DUTY_CODE,
        text: constants.DEFAULT_NATURE_OF_DUTY_TEXT,
      })
    }
  } else if (hasUnderAgeChanged) {
    yield call(setDefaultOccupation, constants.DEFAULT_UNDERAGE_OCCUPATION_CODE)
    if (currentPreAdult) {
      yield call(setDefaultNatureOfDuty, {
        code: constants.DEFAULT_UNDERAGE_18_19_NATURE_OF_DUTY_CODE,
        text: constants.DEFAULT_UNDERAGE_18_19_NATURE_OF_DUTY_TEXT,
      })
    } else {
      yield call(setDefaultNatureOfDuty, {
        code: constants.DEFAULT_UNDERAGE_NATURE_OF_DUTY_CODE,
        text: constants.DEFAULT_UNDERAGE_NATURE_OF_DUTY_TEXT,
      })
    }
  }
}

export function* validateInsuredInformation(): Generator<*, *, *> {
  const insuredBirthdate = yield select(getBirthdate)
  const payerBirthdate = yield select(getPayerBirthdate)
  // $FlowFixMe
  yield call(validateInsuredBirthdate, { birthdate: insuredBirthdate })
  yield call(validateOccupation)
  yield call(validateOtherOccupation)
  // $FlowFixMe
  yield call(validatePayerBirthdate, { birthdate: payerBirthdate })
  yield call(validatePayerOccupation)
}

export function* handleDefaultName(): Generator<*, *, *> {
  yield put({
    type: 'FIRST_NAME/CLEAR_NAME',
  })
  yield put({
    type: 'LAST_NAME/CLEAR_NAME',
  })
}

export function* setDefaultTitle(): Generator<*, *, *> {
  const defaultTitle = yield select(TitleRedux.selectors.getDefaultValue)
  yield put(TitleRedux.actionCreators.editTitle(defaultTitle))
}

export function* setDefaultBirthdayForReviseFNAQQ(action: VisitPath): Generator<*, *, *> {
  const path = action.payload
  const regex = new RegExp('^/quick(?:/[^/]+)?/insured-information$')
  const isMatchPath = regex.test(path)
  const isReviseQQ = yield select(isReviseQQFlow)
  const insuredBirthdate = yield select(getBirthdate)

  if (isReviseQQ && isMatchPath && insuredBirthdate === '') {
    const insuredAge = yield select(getAge)
    const payerAge = yield select(getPayerAge)

    const newInsuredBirthdate = convertAgeToBirthdate(insuredAge.value || 0)
    const newPayerBirthdate = convertAgeToBirthdate(payerAge.value || 0)

    yield put(editBirthdate(newInsuredBirthdate))
    yield put(editPayerBirthdate(newPayerBirthdate))
  }
}

export function* watchers(dataWrapper: DataWrapper): Generator<*, *, Effect[]> {
  yield [
    takeLatest([SELECT_NATURE_OF_DUTY, IHEALTHY_ULTRA_SELECT_BASIC_PLAN], validateOccupation),
    takeEvery(SELECT_OTHER_NATURE_OF_DUTY, validateOtherOccupation),
    takeEvery(SELECT_PAYER_NATURE_OF_DUTY, validatePayerOccupation),
    takeEvery(SELECT_PAYER_OTHER_NATURE_OF_DUTY, validatePayerOtherOccupation),
    takeEvery([UPDATE_BIRTHDATE, SELECT_GENDER, RESET_QUOTE_FORM_AFTER_MATURITY_VALIDATE], setDefaultTitle),
    takeEvery(SELECT_OCCUPATION, updateNatureOfDuty),
    takeEvery(SELECT_PAYER_OCCUPATION, updatePayerNatureOfDuty),
    takeEvery(SELECT_OTHER_OCCUPATION, updateOtherNatureOfDuty),
    takeEvery(GET_OCCUPATIONS_REQUEST, onGetOccupationsRequest, dataWrapper),
    takeEvery(GET_OCCUPATION_FACTORS_REQUEST, onGetOccupationFactorsRequest, dataWrapper),
    takeEvery(SELECTED_PRODUCT, onSelectedProduct),
    takeEvery([INITIALISE_APPLICATION_SUCCESS, RESET_QUOTE_FORM_AFTER_MATURITY_VALIDATE], handleDefaultName),
    takeEvery(VISIT_PATH, setDefaultBirthdayForReviseFNAQQ),
    takeLatest(EDIT_BIRTHDATE, validateInsuredBirthdate),
    takeLatest(SELECT_PAYER_RELATION, validatePayerInformationNeeded),
    takeLatest(EDIT_PAYER_BIRTHDATE, validatePayerBirthdate),
    takeLatest(VALIDATE_INSURED_INFORMATION, validateInsuredInformation),
  ]
}

export const sagas = watchers
