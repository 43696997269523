import { compact, get } from 'lodash/fp'
import { createSectionCreator, createField } from './utils'
import { getToggles } from 'quick-quote/feature-toggles'

const FATCAFields = (app, id) => {
  const isChecked = get(`${id}.checked.value`, app)
  return compact([
    createField(`${id}.disclaimer`, [
      {
        id: `${id}.disclaimer`,
        c: 'PlainText',
        p: {
          children:
            'การรับรองสถานะและคำยินยอมและตกลงเพื่อปฏิบัติตามกฎหมาย Foreign Account Tax Compliance Act ของประเทศสหรัฐอเมริกา',
        },
      },
      {
        id: `${id}.checked`,
        label: [
          'ผู้ขอเอาประกันภัยมีสัญชาติ ประเทศที่เกิด ที่เกี่ยวข้องกับประเทศสหรัฐอเมริกา',
          'ผู้ขอเอาประกันภัยเป็นหรือเคยเป็นผู้ถือบัตรประจําตัวผู้มีถิ่นที่อยู่ถาวรอย่างถูกกฎหมายในประเทศสหรัฐอเมริกา (Green card)',
          'ผู้ขอเอาประกันภัยมีหน้าที่เสียภาษีให้แก่กรมสรรพากรสหรัฐอเมริกา',
          'ผู้ขอเอาประกันภัยมีสถานะเป็นผู้มีถิ่นที่อยู่ในประเทศสหรัฐอเมริกาเพื่อวัตถุประสงค์ในการเก็บภาษีอากรของประเทศสหรัฐอเมริกา (เช่น มีถิ่นที่อยู่ในประเทศสหรัฐอเมริกาอย่างน้อย 183 วันในปีปฏิทินที่ผ่านมา)',
        ],
        invalidLabel:
          'การรับรองสถานะและคำยินยอมและตกลงเพื่อปฏิบัติตามกฎหมาย Foreign Account Tax Compliance Act ของประเทศสหรัฐอเมริกา',
        c: 'RadioButton',
        required: true,
        p: {
          options: [
            {
              text: 'ไม่ใช่',
              value: false,
            },
            {
              text: 'ใช่',
              value: true,
            },
          ],
        },
      },
    ]),
    isChecked &&
      createField(
        `${id}.questions`,
        [
          {
            id: `${id}.relation`,
            label: 'ผู้ขอเอาประกันภัยมีสัญชาติ ประเทศที่เกิด ที่เกี่ยวข้องกับประเทศสหรัฐอเมริกาหรือไม่',
            c: 'RadioButton',
            required: true,
            p: {
              options: [
                {
                  text: 'ไม่ใช่',
                  value: false,
                },
                {
                  text: 'ใช่',
                  value: true,
                },
              ],
            },
          },
          {
            id: `${id}.birth`,
            label: 'เกิดในประเทศสหรัฐอเมริกา',
            c: 'RadioButton',
            required: true,
            p: {
              options: [
                {
                  text: 'ไม่ใช่',
                  value: false,
                },
                {
                  text: 'ใช่',
                  value: true,
                },
              ],
            },
          },
          {
            id: `${id}.permanentResidence`,
            label:
              'ผู้ขอเอาประกันภัยเป็นหรือเคยเป็นผู้ถือบัตรประจำตัวผู้มีถิ่นที่อยู่ถาวรอย่างถูกกฎหมายในประเทศสหรัฐอเมริกา (Green card) หรือไม่',
            c: 'RadioButton',
            required: true,
            p: {
              options: [
                {
                  text: 'ไม่เป็น',
                  value: 'no',
                },
                {
                  text: 'เป็น',
                  value: 'yes',
                },
                {
                  text: 'สิ้นผลบังคับ',
                  value: 'expired',
                },
              ],
            },
          },
          ...(getToggles().ENABLE_OIC_REGULATOR_APPFORM &&
          (get(`${id}.permanentResidence.value`, app) === 'expired' ||
            get(`${id}.permanentResidence.value`, app) === 'yes')
            ? [
                {
                  id: `${id}.permanentResidence.greenCardNo`,
                  label: 'โปรดระบุเลขที่ Green Card',
                  c: 'TextInput',
                  p: {},
                },
                {
                  label: 'วันบัตรหมดอายุ',
                  id: `${id}.permanentResidence.greenCardExpired`,
                  c: 'DateDropdownInput',
                  p: {
                    addYear: 10,
                  },
                },
              ]
            : []),
          {
            id: `${id}.taxpayer`,
            label: 'ผู้ขอเอาประกันภัยมีหน้าที่เสียภาษีให้แก่กรมสรรพากรสหรัฐอเมริกาหรือไม่',
            c: 'RadioButton',
            required: true,
            p: {
              options: [
                {
                  text: 'ไม่ใช่',
                  value: false,
                },
                {
                  text: 'ใช่',
                  value: true,
                },
              ],
            },
          },
          {
            id: `${id}.tenant`,
            label:
              'ผู้ขอเอาประกันมีสถานะเป็นผู้มีถิ่นที่อยู่ในประเทศสหรัฐอเมริกาเพื่อวัตถุประสงค์ในการเก็บภาษีอากรของประเทศสหรัฐอเมริกาใช่หรือไม่ (เช่น มีถิ่นที่อยู่ในประเทศสหรัฐอเมริกาอย่างน้อย 183 วัน ในปีปฏิทินที่ผ่านมา)',
            c: 'RadioButton',
            required: true,
            p: {
              options: [
                {
                  text: 'ไม่ใช่',
                  value: false,
                },
                {
                  text: 'ใช่',
                  value: true,
                },
              ],
            },
          },
        ],
        {
          // style: {
          //   paddingTop: '0.5rem',
          //   paddingBottom: '0.5rem'
          // }
        }
      ),
  ])
}

export default createSectionCreator(FATCAFields)
