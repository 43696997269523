// @flow

import _ from 'lodash'
import Mustache from 'mustache'
import type { LumpSum, LumpSumByYear, LumpSumValidationResult } from '../types'
import { createChecker, valid, type Checker } from 'core/service/lib/check'
import MESSAGES from 'core/data-model/constants/validation-messages'
import type { ModelFactor } from 'core/data-model/basic-plan'
import { dataWrapper } from 'core/data'

const disallowedYearForLumpSum = [1]
const defaultLumpSum = { value: 0, isValid: true }

export const disallowLumpSumInputByYear = (year: number, lumpSum: LumpSum) => {
  const isInDisallowedYear = disallowedYearForLumpSum.includes(year)
  const isCurrentLumpSumDisallowed = lumpSum === 'DISALLOWED'

  return isInDisallowedYear ? 'DISALLOWED' : isCurrentLumpSumDisallowed ? defaultLumpSum : lumpSum
}

export const totalLumpSum = (lumpSums: LumpSumByYear[]) => _.sumBy(lumpSums, 'value')

export const lumpSumRPULChecker: Checker<
  { annualRegularPremium: number, totalLumpSumAmount: number },
  number
> = createChecker([
  [
    'lessThanMinimum',
    {
      type: 'VALIDATION',
      rule: ({}, lumpSum) => lumpSum >= 10000,
      message: Mustache.render(MESSAGES.RULE_IWEALTHY_LUMP_SUM_LESS_THAN_MINIMUM, { lumpSum: '10,000' }),
    },
  ],
  [
    'moreThanMaximum',
    {
      type: 'VALIDATION',
      rule: ({ annualRegularPremium }, lumpSum) => lumpSum <= 5 * annualRegularPremium,
      message: MESSAGES.RULE_IWEALTHY_LUMP_SUM_MORE_THAN_MAXIMUM,
    },
  ],
  [
    'moreThanTotalLumpSumMaximum',
    {
      type: 'VALIDATION',
      rule: ({ totalLumpSumAmount }, lumpSum) => totalLumpSumAmount <= 150000000,
      message: MESSAGES.RULE_IWEALTHY_LUMP_SUM_MORE_THAN_TOTAL_MAXIMUM,
    },
  ],
])

export const lumpSumSPULChecker: Checker<
  { annualRegularPremium: number, totalLumpSumAmount: number },
  number
> = createChecker([
  [
    'lessThanMinimum',
    {
      type: 'VALIDATION',
      rule: ({}, lumpSum) => lumpSum >= 5000,
      message: Mustache.render(MESSAGES.RULE_IINVEST_LUMP_SUM_LESS_THAN_MINIMUM, { lumpSum: '5,000' }),
    },
  ],
  [
    'moreThanMaximum',
    {
      type: 'VALIDATION',
      rule: ({ annualRegularPremium }, lumpSum) => lumpSum <= 10 * annualRegularPremium,
      message: MESSAGES.RULE_IINVEST_LUMP_SUM_MORE_THAN_MAXIMUM,
    },
  ],
])

export const lumpSumRPUDRChecker: Checker<
  { annualRegularPremium: number, totalLumpSumAmount: number },
  number
> = createChecker([
  [
    'lessThanMinimum',
    {
      type: 'VALIDATION',
      rule: ({}, lumpSum) => lumpSum >= 10000,
      message: MESSAGES.RULE_RPUDR_LUMP_SUM_LESS_THAN_MINIMUM,
    },
  ],
])

export const _validateLumpSum = (
  regularPremium: number,
  lumpSums: LumpSumByYear[],
  paymentFrequency: number,
  lumpSumChecker: Checker<{ annualRegularPremium: number, totalLumpSumAmount: number }, number>
): LumpSumValidationResult => {
  const totalLumpSumAmount = totalLumpSum(lumpSums)
  const annualRegularPremium = regularPremium * paymentFrequency
  const validatedEach = lumpSums.map(({ year, value }) => {
    const checked = value === 0 ? valid(value) : lumpSumChecker({ annualRegularPremium, totalLumpSumAmount }, value)

    const checkedLumpSumByYear = { year, value, isValid: checked.isOk }
    const errors = checked.getErrorMessages()

    return { value: checkedLumpSumByYear, errors }
  })

  const allErrors = validatedEach.reduce((acc, { errors }) => acc.concat(errors), [])

  return {
    values: validatedEach.map(({ value }) => value),
    errors: _.uniq(allErrors),
  }
}

export const validateLumpSumRPUL = async (
  regularPremium: number,
  lumpSums: LumpSumByYear[],
  modelFactorId: string
): Promise<LumpSumValidationResult> => {
  let modelFactor: ModelFactor = await dataWrapper.getModelFactorById(modelFactorId)
  return _validateLumpSum(regularPremium, lumpSums, modelFactor.periods, lumpSumRPULChecker)
}

export const validateLumpSumSPUL = async (
  regularPremium: number,
  lumpSums: LumpSumByYear[],
  modelFactorId: string
): Promise<LumpSumValidationResult> => {
  let modelFactor: ModelFactor = await dataWrapper.getModelFactorById(modelFactorId)
  return _validateLumpSum(regularPremium, lumpSums, modelFactor.periods, lumpSumSPULChecker)
}

export const validateLumpSumRPUDR = async (
  regularPremium: number,
  lumpSums: LumpSumByYear[],
  modelFactorId: string
): Promise<LumpSumValidationResult> => {
  let modelFactor: ModelFactor = await dataWrapper.getModelFactorById(modelFactorId)
  return _validateLumpSum(regularPremium, lumpSums, modelFactor.periods, lumpSumRPUDRChecker)
}

export const updateLumpSumByYear = (lumpSums: LumpSumByYear[], lumpSumToUpdate: LumpSumByYear): LumpSumByYear[] => {
  return lumpSums.map((lumpSum) => (lumpSum.year === lumpSumToUpdate.year ? lumpSumToUpdate : lumpSum))
}
