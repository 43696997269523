// @flow
import { formatNumber } from 'core/service/lib/number-format'
import type { YearEndPolicyValue } from 'core/service/benefit-illustration'
import { getToggles } from 'quick-quote/feature-toggles'
import { Entry, Track } from 'quick-quote/product-investment/coverage-plan/components/StickyTable'

type YearEndPolicyRetirementValue = YearEndPolicyValue & {
  pensionType: string,
  cumulativePersionAmount?: number,
}

export const dashedOutOrWithPrecision = (value: number, precision: number, isRound: boolean = true) =>
  value === 0 ? '-' : formatNumber(value, precision, isRound)

export const PolicyAnnuallyValueRow = ({
  age,
  yearEndPolicy,
  cumulativeBasicPremium,
  annualBasicPremium,
  surrenderCash,
  annualRiderPremium,
  annualTotalPremium,
  deathBenefit,
  pensionPerYear,
  monthEndPolicy,
  pensionTotal,
  pensionType,
}: YearEndPolicyRetirementValue) => {
  return (
    <Track>
      <Entry className="policy-value-age">{age.value - 1}</Entry>
      <Entry className="policy-value-year">{yearEndPolicy}</Entry>
      <Entry className="policy-value-cumulative-basic-premium">
        {dashedOutOrWithPrecision(cumulativeBasicPremium, 2)}
      </Entry>
      <Entry className="policy-value-annual-basic-premium">{dashedOutOrWithPrecision(annualBasicPremium, 2)}</Entry>
      <Entry className="policy-value-annual-rider-premium">{dashedOutOrWithPrecision(annualRiderPremium, 2)}</Entry>
      <Entry className="policy-value-annual-total-premium">{dashedOutOrWithPrecision(annualTotalPremium, 2)}</Entry>
      <Entry className="policy-value-surrender-cash">{dashedOutOrWithPrecision(surrenderCash, 0)}</Entry>
      <Entry className="policy-value-death-benefit">{dashedOutOrWithPrecision(deathBenefit, 2)}</Entry>
      <Entry className="policy-value-pension-year-cash">{dashedOutOrWithPrecision(pensionPerYear || 0, 2)}</Entry>
      <Entry className="policy-value-pension-balance-cash">{dashedOutOrWithPrecision(pensionTotal || 0, 2)}</Entry>
    </Track>
  )
}

export const PolicyAnnuallyValueRowLifeRetire5 = ({
  age,
  yearEndPolicy,
  cumulativeBasicPremium,
  annualBasicPremium,
  surrenderCash,
  annualRiderPremium,
  annualTotalPremium,
  deathBenefit,
  pensionPerYear,
  monthEndPolicy,
  pensionTotal,
  pensionType,
  cumulativePersionAmount,
}: YearEndPolicyRetirementValue) => {
  return (
    <Track>
      <Entry className="policy-value-age">{age.value - 1}</Entry>
      <Entry className="policy-value-year">{yearEndPolicy}</Entry>
      <Entry className="policy-value-annual-basic-premium">{dashedOutOrWithPrecision(annualBasicPremium, 2)}</Entry>
      {!getToggles().ENABLE_HIDING_RIDER_PREMIUM_AND_TOTAL_PREMIUM && (
        <Entry className="policy-value-annual-rider-premium">{dashedOutOrWithPrecision(annualRiderPremium, 2)}</Entry>
      )}
      {!getToggles().ENABLE_HIDING_RIDER_PREMIUM_AND_TOTAL_PREMIUM && (
        <Entry className="policy-value-annual-total-premium">{dashedOutOrWithPrecision(annualTotalPremium, 2)}</Entry>
      )}
      <Entry className="policy-value-cumulative-basic-premium">
        {dashedOutOrWithPrecision(cumulativeBasicPremium, 2)}
      </Entry>
      <Entry className="policy-value-surrender-cash">{dashedOutOrWithPrecision(surrenderCash, 0)}</Entry>
      <Entry className="policy-value-death-benefit">{dashedOutOrWithPrecision(deathBenefit, 2)}</Entry>
      <Entry className="policy-value-pension-year-cash">{dashedOutOrWithPrecision(pensionPerYear || 0, 2)}</Entry>
      <Entry className="policy-value-cumulative-pension-year-cash">
        {dashedOutOrWithPrecision(cumulativePersionAmount || 0, 2)}
      </Entry>
      <Entry className="policy-value-pension-balance-cash">{dashedOutOrWithPrecision(pensionTotal || 0, 2)}</Entry>
    </Track>
  )
}

export const PolicyMonthlyValueRow = ({
  age,
  yearEndPolicy,
  cumulativeBasicPremium,
  annualBasicPremium,
  surrenderCash,
  annualRiderPremium,
  annualTotalPremium,
  deathBenefit,
  pensionPerYear,
  monthEndPolicy,
  pensionTotal,
  pensionType,
}: YearEndPolicyRetirementValue) => {
  return (
    <Track>
      <Entry className="policy-value-age">{age.value - 1}</Entry>
      <Entry className="policy-value-year">{yearEndPolicy}</Entry>
      <Entry className="policy-value-month">{monthEndPolicy}</Entry>
      <Entry className="policy-value-cumulative-basic-premium">
        {dashedOutOrWithPrecision(cumulativeBasicPremium, 2)}
      </Entry>
      <Entry className="policy-value-annual-basic-premium">{dashedOutOrWithPrecision(annualBasicPremium, 2)}</Entry>
      <Entry className="policy-value-annual-rider-premium">{dashedOutOrWithPrecision(annualRiderPremium, 2)}</Entry>
      <Entry className="policy-value-annual-total-premium">{dashedOutOrWithPrecision(annualTotalPremium, 2)}</Entry>
      <Entry className="policy-value-surrender-cash">{dashedOutOrWithPrecision(surrenderCash, 0)}</Entry>
      <Entry className="policy-value-death-benefit">{dashedOutOrWithPrecision(deathBenefit, 2)}</Entry>
      <Entry className="policy-value-pension-year-cash">{dashedOutOrWithPrecision(pensionPerYear || 0, 2)}</Entry>
      <Entry className="policy-value-pension-balance-cash">{dashedOutOrWithPrecision(pensionTotal || 0, 2)}</Entry>
    </Track>
  )
}

export const PolicyMonthlyValueRowLifeRetire5 = ({
  age,
  yearEndPolicy,
  cumulativeBasicPremium,
  annualBasicPremium,
  surrenderCash,
  annualRiderPremium,
  annualTotalPremium,
  deathBenefit,
  pensionPerYear,
  monthEndPolicy,
  pensionTotal,
  pensionType,
  cumulativePersionAmount,
}: YearEndPolicyRetirementValue) => {
  return (
    <Track>
      <Entry className="policy-value-age">{age.value - 1}</Entry>
      <Entry className="policy-value-year">{yearEndPolicy}</Entry>
      <Entry className="policy-value-month">{monthEndPolicy}</Entry>
      <Entry className="policy-value-annual-basic-premium">{dashedOutOrWithPrecision(annualBasicPremium, 2)}</Entry>
      {!getToggles().ENABLE_HIDING_RIDER_PREMIUM_AND_TOTAL_PREMIUM && (
        <Entry className="policy-value-annual-rider-premium">{dashedOutOrWithPrecision(annualRiderPremium, 2)}</Entry>
      )}
      {!getToggles().ENABLE_HIDING_RIDER_PREMIUM_AND_TOTAL_PREMIUM && (
        <Entry className="policy-value-annual-total-premium">{dashedOutOrWithPrecision(annualTotalPremium, 2)}</Entry>
      )}
      <Entry className="policy-value-cumulative-basic-premium">
        {dashedOutOrWithPrecision(cumulativeBasicPremium, 2)}
      </Entry>
      <Entry className="policy-value-surrender-cash">{dashedOutOrWithPrecision(surrenderCash, 0)}</Entry>
      <Entry className="policy-value-death-benefit">{dashedOutOrWithPrecision(deathBenefit, 2)}</Entry>
      <Entry className="policy-value-pension-year-cash">{dashedOutOrWithPrecision(pensionPerYear || 0, 2)}</Entry>
      <Entry className="policy-value-cumulative-pension-year-cash">
        {dashedOutOrWithPrecision(cumulativePersionAmount || 0, 2)}
      </Entry>
      <Entry className="policy-value-pension-balance-cash">{dashedOutOrWithPrecision(pensionTotal || 0, 2)}</Entry>
    </Track>
  )
}
