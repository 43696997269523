//@flow
import Mustache from 'mustache'
import VALUES from 'core/data-model/constants/values'
import MESSAGES from 'core/data-model/constants/bi-messages'
import { customer } from './signature-section-agent'
import { getToggles } from 'quick-quote/feature-toggles'
import { signBox, blankCell } from 'core/service/pdf-generation/products/common/components/sign-box'

import type { Profile } from 'core/data-model/distributor'
import type { DisplayProduct } from 'core/service/display-product'
import type { InsuredProps } from 'core/service/pdf-generation/products/common/types'

const distributor = (profile) => {
  const enableAddBasicLicenseBi = getToggles().ENABLE_ADD_BASIC_LICENSE_BI
  const fullName = profile.given_name && profile.family_name ? `${profile.given_name} ${profile.family_name}` : ''
  const name = fullName ? Mustache.render(MESSAGES.SIGNATURE_NAME, { name: fullName }) : ''

  if (enableAddBasicLicenseBi) {
    return [
      [MESSAGES.CUSTOMER_SIGN, ''],
      [' ', name],
      [MESSAGES.DISTRICT_OFFICE, profile.branchName || ''],
      [MESSAGES.OFFICE_CODE, profile.lbCode || ''],
      [MESSAGES.LICENSE_NO, profile.basicLicenseNumber || ''],
      [MESSAGES.TELEPHONE_NUMBER, profile.mobile || ''],
    ]
  }

  return [
    [MESSAGES.CUSTOMER_SIGN, ''],
    [' ', name],
    [MESSAGES.DISTRICT_OFFICE, profile.branchName || ''],
    [MESSAGES.OFFICE_CODE, profile.lbCode || ''],
    [MESSAGES.TELEPHONE_NUMBER, profile.mobile || ''],
  ]
}

const signSection = (profile: Profile, insured: InsuredProps) => {
  const enableAddBasicLicenseBi = getToggles().ENABLE_ADD_BASIC_LICENSE_BI
  const fullName = profile.given_name && profile.family_name ? `${profile.given_name} ${profile.family_name}` : ''
  const name = fullName ? Mustache.render(MESSAGES.SIGNATURE_NAME, { name: fullName }) : ''

  if (enableAddBasicLicenseBi) {
    return {
      table: {
        widths: ['*', 10, '*'],
        body: [
          [
            signBox(customer(insured)),
            blankCell,
            signBox(distributor(profile), {
              1: { fontSize: name.length > 27 ? 11 : 12.5 },
              2: { fontSize: (profile.branchName || '').length > 27 ? 11 : 12.5 },
            }),
          ],
        ],
      },
    }
  }

  return {
    table: {
      widths: ['*', 10, '*'],
      body: [[signBox(customer(insured)), blankCell, signBox(distributor(profile))]],
    },
  }
}

export const signatureSectionBanc = (
  profile: Profile,
  notes: Object,
  insured: InsuredProps,
  basicPlan: DisplayProduct
) => ({
  stack: [
    notes,
    { ...signSection(profile, insured), absolutePosition: { x: 30, y: 510 } },
    {
      text: basicPlan.category === VALUES.MRTA ? MESSAGES.REMARK_SIGNING_BOX : '',
      style: 'disclaimer',
      lineHeight: 0.8,
      absolutePosition: { x: 30, y: 676 },
    },
  ],
})
