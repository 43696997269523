import _ from 'lodash'
import moment from 'moment'

import { getOptyList } from 'e-submission/domain/service/opty'

import { PRODUCT_IPRO_WITH_AZ } from './constant'

export const mappingProductIproWithAz = (iproProductName) =>
  PRODUCT_IPRO_WITH_AZ.find(({ ipro }) => ipro === (iproProductName || '').toLowerCase())

export const getProductDetail = (azCode, azProductList) => azProductList.find(({ code }) => code === azCode)

const defaultNeededStage = ['INTRODUCTION', 'QUOTE&FNA']
export const filterOptyStage = (optyList, neededStage = defaultNeededStage) => {
  const filteredOptyList = (optyList || []).filter((item) => {
    const optyStageUppercase = (item.StageName || '').toUpperCase()
    return defaultNeededStage.includes(optyStageUppercase)
  })
  return filteredOptyList
}

export const formatAndSortOptyByDate = (data = []) => {
  const sortedData = data.sort((a, b) => new Date(b.Create_Date_for_AZ__c) - new Date(a.Create_Date_for_AZ__c))

  const formattedData = sortedData.map((rawData) => {
    const formattedMobileNumber = (rawData.Mobile_of_Lead__c || '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
    const formattedDate = moment(rawData.Create_Date_for_AZ__c).format('DD/MM/YYYY HH:mm')

    return {
      ...rawData,
      Mobile_of_Lead__c: formattedMobileNumber || '',
      CreatedDate: formattedDate || '',
    }
  })

  return formattedData
}

export const handleGetOptyList = async (user) => {
  const agentCode = _.get(user, 'profile.agent_code')

  const resp = await getOptyList(user, agentCode).catch((e) => undefined)
  const optyList = _.get(resp, 'response.records')

  if (!optyList) {
    return
  }
  const formattedOptyList = formatAndSortOptyByDate(optyList)
  return formattedOptyList
}

export const getQueryParam = (string) => {
  const queryString = string.replace('?', '')
  const param = queryString.split('&')
  const paramObj = {}
  param.map((item) => {
    const [key, value] = item.split('=')
    _.set(paramObj, [`${key}`], value)
  })
  return paramObj
}
