// @flow
import type { DisplayProduct } from 'core/service/display-product'
import ProductBenefitsSummary from 'quick-quote/product-common/benefit-illustration/components/product-benefits/product-benefits-summary'
import { getBenefitData } from 'core/service/basic-plan/benefit'

import { underlineHtml } from 'quick-quote/util/html-render-utils'

type ProductBenefitProps = {
  displayProduct: DisplayProduct,
  sumAssured: number,
}

const ProductBenefits = ({
  displayProduct,
  sumAssured,
  regularPremium,
  calculatedPaymentPeriod,
  calculatedCoveragePeriod,
  policyValue,
  benefitSummaryHeader,
}: ProductBenefitProps) => {
  const benefitData = getBenefitData(displayProduct.category)
  return (
    <div id="product-benefits" className="benefit group">
      <ProductBenefitsSummary
        displayProduct={displayProduct}
        sumAssured={sumAssured}
        basicPremium={regularPremium}
        calculatedPaymentPeriod={calculatedPaymentPeriod}
        calculatedCoveragePeriod={calculatedCoveragePeriod}
        policyValue={policyValue}
        benefitSummaryHeader={benefitSummaryHeader}
      />
      <div id="product-benefits-content">
        <p id="death-benefits">
          <b className="caption">{benefitData.deathBenefits}</b>
        </p>
        <p className="description">
          <span dangerouslySetInnerHTML={{ __html: underlineHtml(benefitData.deathBenefitsDescription) }} />
        </p>
        <p id="maturity-benefits">
          <b className="caption">{benefitData.maturityBenefits}</b>
        </p>
        <p className="description">
          <span dangerouslySetInnerHTML={{ __html: underlineHtml(benefitData.maturityBenefitsDescription) }} />
        </p>
      </div>
    </div>
  )
}

export default ProductBenefits
