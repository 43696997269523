import { debounce, omit, isEmpty, getOr } from 'lodash/fp'
import styled, { css } from 'styled-components'
import { Input, FormFeedback } from 'reactstrap'
import { Flex } from 'rebass'
import { lifecycle, mapProps, withState, withHandlers, compose } from 'recompose'
import Button from 'e-submission/components/Button'
import Icon, { getIcon } from 'e-submission/components/Icon'
import { backgroundAsBorderMixin } from '../mixins'
import { isLengthLimit, isTextAndNumber } from 'e-submission/utils/pattern'
import { updateDocumentDescriptionEmpty } from 'e-submission/utils/documents'

export const Side = styled(Flex).attrs({
  direction: 'column',
  align: 'center',
  justify: 'center',
  flex: ['0 0 15%'],
})`
  min-height: 80px;
  min-width: 50px;
`

export const NotCheckedSide = Side.extend`
  font-size: 0.825rem;
  color: white;
  background: ${({ theme }) => theme.variables['$brand-primary']};
`

export const Description = styled(({ description, warnText, ...props }) => {
  return (
    <Flex {...props}>
      <div>
        <div>{description}</div>
        {warnText && <small className={'text-danger'}>{warnText}</small>}
      </div>
    </Flex>
  )
}).attrs({
  flex: 1,
  align: 'center',
})`
  padding: 12px;
  word-break: break-word;
`

export const NotCheckedCard = styled(
  ({
    icon,
    action,
    description,
    warnText,
    children,
    validateFile,
    disabled,
    infoMessage,
    onClick,
    selectedRemoteSelling,
    isWarning,
    ...props
  }) => {
    return (
      <Flex column {...props} onClick={onClick}>
        <Flex {...props} flex={1}>
          {!disabled && (
            <NotCheckedSide>
              {React.Children.toArray([icon && getIcon(icon), <div>{action}</div>], children)}
            </NotCheckedSide>
          )}
          <Description description={description} warnText={warnText} />
        </Flex>
        {infoMessage && (
          <FormFeedback className={`info-message ${isWarning ? 'info-message-warning' : ''} attached-icon`}>
            <p dangerouslySetInnerHTML={{ __html: infoMessage }} />
          </FormFeedback>
        )}
      </Flex>
    )
  }
)`
  .header {
    background: ${({ theme }) => theme.variables['$color-wild-sand']};
  }
  .no-description {
    padding: 10px;
    flex-direction: row;
    min-height: auto;
    min-width: auto;
    > svg {
      width: 30px;
      height: 30px;
    }
    > div {
      margin-left: 10px;
    }
  }
  .icon-item {
    display: flex;
    align-items: center;
  }
  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer')};
    color: ${({ theme }) => theme.variables['$color-axa-blue']};
    ${NotCheckedSide} {
      background: ${({ theme }) => theme.variables['$color-axa-blue']};
    }
    ${Description} {
      border: 1px solid ${({ theme }) => theme.variables['$color-axa-blue']};
    }
  }
  color: ${({ theme }) => theme.variables['$brand-primary']};
  background: white;
  & + & {
    margin-top: 10px;
  }
  svg {
    width: 36px;
    height: 36px;
    path {
      stroke: white;
    }
    line {
      stroke: white;
    }
  }
  ${Description} {
    border: 1px solid ${({ theme }) => theme.variables['$brand-primary']};
  }
`

export const NewDescription = ({ description, ...props }) => {
  const textDescription = updateDocumentDescriptionEmpty(props)

  const matcheDocuments = [
    'passport-consent-insured',
    'passport-consent-payer',
    'citizen-id-consent-insured',
    'citizen-id-consent-payer',
  ]
  const resultMatch = matcheDocuments.includes(props.name)

  if (resultMatch) {
    return <Description description={textDescription} />
  } else {
    return <Description description={description} />
  }
}

export const CheckedCard = styled(({ hasBorder, description, warnText, infoMessage, onClick, isWarning, ...props }) => {
  return (
    <Flex column {...props} onClick={onClick}>
      <Flex {...props} className="card-info-container" flex={1}>
        <Side>
          <Icon.check />
        </Side>
        <NewDescription description={description} {...props} />
        <Side align="flex-end">
          <Button color="primary" outline>
            ดู
          </Button>
        </Side>
      </Flex>
      {infoMessage && (
        <FormFeedback className={`info-message ${isWarning ? 'info-message-warning' : ''} attached-icon`}>
          <p dangerouslySetInnerHTML={{ __html: infoMessage }} />
        </FormFeedback>
      )}
    </Flex>
  )
})`
  & + & {
    padding-top: 10px;
    ${backgroundAsBorderMixin};
    background-size: 100% 1px;
    background-position-y: 5px;
  }
  ${({ hasBorder }) =>
    hasBorder &&
    css`
      padding-${hasBorder}: 10px;
      ${backgroundAsBorderMixin};
      background-size: 100% 1px;
      background-position-x: 0;
    `} svg {
    width: 48px;
    fill: ${({ theme }) => theme.variables['$brand-submitted']};
  }
  button {
    background: white;
    margin-bottom: 0;
  }
  .icon-check {
    align-self: center;
  }
`

const BaseAdditionalBox = styled(Flex).attrs({
  w: 1,
})`
  height: 60px;
  background-color: white;
`

export const PlaceHolderCard = BaseAdditionalBox.extend`
  border: 1px dotted ${({ theme }) => theme.variables['$color-silver']};
`

export const AdditionalButton = styled(Button)`
  &.btn.btn-disabled,
  &.btn-primary {
    margin-bottom: 0;
  }
`

export const NotCheckedWithInputCardComponent = styled(
  ({ className, disabled, value, validateFile, placeholder, onChange, onClick, getRef, clear, ...props }) => (
    <BaseAdditionalBox className={className} {...props}>
      <Input value={value} placeholder={placeholder} onChange={onChange} />
      <AdditionalButton icon="attach" onClick={onClick} disabled={disabled} />
    </BaseAdditionalBox>
  )
)`
  input {
    margin-right: 10px;
  }
`
export const NotCheckedWithInputCard = compose(
  withState('value', 'updateValue', getOr('', 'description')),
  lifecycle({
    UNSAFE_componentWillReceiveProps(nextProps) {
      if (this.props.description !== nextProps.description) {
        this.props.updateValue(nextProps.description)
      }
    },
  }),
  withHandlers(({ onChange }) => {
    const onDebounceChange = debounce(300, onChange)

    return {
      onClick: ({ onClick, value, disabled = false }) => (evt) => {
        if (!isEmpty(value) && !disabled) {
          onClick(evt)
        }
      },
      onChange: ({ updateValue }) => (evt) => {
        const value = evt.target.value
        if ((isLengthLimit(100)(value) && isTextAndNumber(value)) || value === '') {
          onDebounceChange(value)
          updateValue(value)
        }
      },
    }
  }),
  mapProps(omit(['updateValue', 'description']))
)(NotCheckedWithInputCardComponent)

export const CheckedOrNotCard = ({ checked, ...props }) =>
  checked ? <CheckedCard {...props} /> : <NotCheckedCard {...props} />
