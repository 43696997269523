//@flow
import { Button, ModalBody, ModalHeader } from 'reactstrap'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import styled from 'styled-components'

import type { ErrorIncompleteFNANotification } from 'quick-quote/notification/actions'
import { getNavTabList } from 'quick-quote/components/navigations/tabs'
import MESSAGES from 'quick-quote/constants/messages'
import { Layout, Modal, EditCard } from 'common-components'
import { getIcon } from 'e-submission/components/Icon'

import { PATH } from '../constants/path'

type ErrorIncompleteFNAProps = {
  notificationMessage: ErrorIncompleteFNANotification,
  onExit: Function,
  clearNotificationMessage: Function,
  history: Object,
  needGapUrl: string,
  marketConductsUrl: string,
}
const Status = styled.div`
  text-align: center;
  padding: 30px 0px;
  div:last-child {
    padding-top: 30px;
  }
`

export const _ErrorIncompleteFNA = ({
  onExit,
  notificationMessage,
  history,
  needGapUrl,
  marketConductsUrl,
}: ErrorIncompleteFNAProps) => (
  <Modal
    isOpen={notificationMessage.type !== 'None'}
    modalClassName="full-screen-modal error-message-modal"
    toggle={onExit}
  >
    <ModalHeader toggle={onExit}>{MESSAGES.FNA_VALIDATE_TITLE}</ModalHeader>
    <ModalBody>
      <Layout.Content>
        <Status>
          {getIcon('formInvalid')}
          <div>
            ไม่สามารถสร้างเอกสารได้
            <br />
            <b>กรุณากลับไปแก้ไขข้อมูลด้านล่างให้สมบูรณ์</b>
          </div>
        </Status>
        {notificationMessage.incompleteNeedGap && (
          <EditCard
            title={MESSAGES.FNA_REQUIRED_NEED_TITLE}
            onClick={() => {
              history.push(needGapUrl)
              onExit()
            }}
          >
            <ul>
              <li>{MESSAGES.FNA_REQUIRED_NEED_MESSAGE}</li>
            </ul>
          </EditCard>
        )}
        {notificationMessage.incompleteMarketConducts && (
          <EditCard
            title={MESSAGES.FNA_REQUIRED_MARKET_CONDUCTS_TITLE}
            onClick={() => {
              history.push(marketConductsUrl)
              onExit()
            }}
          >
            <ul>
              <li>{MESSAGES.FNA_REQUIRED_MARKET_CONDUCTS_MESSAGE}</li>
            </ul>
          </EditCard>
        )}
      </Layout.Content>
      <Button color="primary" onClick={onExit}>
        {MESSAGES.OK}
      </Button>
    </ModalBody>
  </Modal>
)
const mapStateToProps = (state, ownProps) => {
  const navTabList = getNavTabList({ state, location: ownProps.history.location })
  const needGapTab = navTabList.find((tab) => tab.path.includes(PATH.NEED_AND_GAP_ANALYSIS))
  const marketConductsTab = navTabList.find((tab) => tab.path.includes(PATH.MARKET_CONDUCTS))
  return {
    needGapUrl: needGapTab && needGapTab.path,
    marketConductsUrl: marketConductsTab && marketConductsTab.path,
  }
}
const enhance = compose(withRouter, connect(mapStateToProps, null))
export default enhance(_ErrorIncompleteFNA)
