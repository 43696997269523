// @flow

export type Action = ToggleDiscount

export const TOGGLE_DISCOUNT = 'TOGGLE_DISCOUNT'
export type ToggleDiscount = {
  type: 'TOGGLE_DISCOUNT',
}
export const toggleDiscount = (): ToggleDiscount => ({
  type: TOGGLE_DISCOUNT,
})
