// @flow
import _ from 'lodash'
import type { Effect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import type { DisplayProduct } from 'core/service/display-product'
import type { Age } from 'core/data-model/insured'
import { selectBasicPlan } from './actions'
import { SELECTED_PRODUCT } from 'quick-quote/product-selection/actions'
import VALUES from 'core/data-model/constants/values'
import { UPDATE_BIRTHDATE } from 'quick-quote/insured-information/actions'
import { selectModelFactor } from 'quick-quote/product-flexi-health/coverage-plan/actions'
import { populateAvailableRiders } from 'quick-quote/product-common/coverage-plan/sagas'
import {
  getSelectedDisplayProductCode,
  getSelectedBasicCategory,
  getDisplayProducts,
} from 'quick-quote/product-selection/selectors'
import { isValidAge } from 'core/service/insured/birthdate'
import { getAge } from 'quick-quote/insured-information/selectors'

export const _switchLifeSaveProProductByAge = (products: DisplayProduct[], age: Age) => {
  const { value, unit } = age
  return _.find(products, (product) => isValidAge(value, unit, _.get(product, 'entryAge')))
}

const getProductGroupCodes = (productCode: string) => {
  const _is20P10Group = () => _.includes(VALUES.LIFE_SAVE_PRO_20P10, productCode)
  const _is25P15Group = () => _.includes(VALUES.LIFE_SAVE_PRO_25P15, productCode)

  return _is20P10Group() ? VALUES.LIFE_SAVE_PRO_20P10 : _is25P15Group() ? VALUES.LIFE_SAVE_PRO_25P15 : []
}

export const switchLifeSaveProProductByBirthDate = function*(): Generator<*, *, *> {
  const productCategory = yield select(getSelectedBasicCategory)
  if (productCategory != VALUES.LIFE_SAVE_PRO) return

  const age = yield select(getAge)
  const products = yield select(getDisplayProducts)
  const productCode = yield select(getSelectedDisplayProductCode)
  const productGroupCodes = getProductGroupCodes(productCode)

  const lifeSaveProProducts = _.filter(products, (product) => _.includes(productGroupCodes, _.get(product, 'code')))
  const product = _switchLifeSaveProProductByAge(lifeSaveProProducts, age)

  if (product != undefined && productCode != _.get(product, 'code')) {
    yield put(selectBasicPlan(product))
    yield put(selectModelFactor('model-factor_1'))
    yield call(populateAvailableRiders)
  }
}

export function* watchers(): Generator<*, *, Effect[]> {
  yield [takeLatest([UPDATE_BIRTHDATE, SELECTED_PRODUCT], switchLifeSaveProProductByBirthDate)]
}

export const sagas = watchers
