// @flow
import _ from 'lodash'
import React from 'react'
import Mustache from 'mustache'
import VALUES from 'core/data-model/constants/values'
import MESSAGES from 'core/data-model/constants/messages'
import BIMESSAGES from 'core/data-model/constants/bi-messages'
import Graph_GLTSP from 'assets/images/product-benefits/Graph-GLTSP.svg'
import Graph_MRTA_FULL from 'assets/images/product-benefits/Graph-MRTA_FULL.svg'
import Graph_MRTA_PLUS from 'assets/images/product-benefits/Graph-MRTA_PLUS.svg'
import Graph_MRTA_TRUNCATE from 'assets/images/product-benefits/Graph-MRTA_TRUNCATE.svg'
import { formatNumber } from 'core/service/lib/number-format'

import type { ProductBenefitProps } from './types'

const _getProductName = (productName, displayProduct) => {
  var planCode
  const code = _.get(displayProduct, 'code', '')
  const basicPlanCode = _.get(displayProduct, 'basicPlanCode', '')
  switch (basicPlanCode) {
    case VALUES.MRTA_CODE.MRTA_FULL:
      planCode = BIMESSAGES.MRTA_FULL_EN_NAME
      break
    case VALUES.MRTA_CODE.MRTA_PLUS:
      planCode = BIMESSAGES.MRTA_PLUS_EN_NAME
      break
    case VALUES.MRTA_CODE.MRTA_TRUNCATE:
      planCode = BIMESSAGES.MRTA_TRUNCATE_EN_NAME
      break
    case VALUES.GLTSP_CODE.GLTSP:
      planCode = basicPlanCode
      break
    default:
      planCode = code
      break
  }
  return Mustache.render(BIMESSAGES.BASIC_PLAN_DETAILS_MRTA, {
    name: productName,
    planCode: planCode,
    code: code,
  })
}

const renderGraph = (basicPlanCode) => {
  const graph = (() => {
    switch (basicPlanCode) {
      case VALUES.MRTA_CODE.MRTA_FULL:
        return <Graph_MRTA_FULL />
      case VALUES.MRTA_CODE.MRTA_PLUS:
        return <Graph_MRTA_PLUS />
      case VALUES.MRTA_CODE.MRTA_TRUNCATE:
        return <Graph_MRTA_TRUNCATE />
      case VALUES.GLTSP_CODE.GLTSP:
        return <Graph_GLTSP />
      default:
        return null
    }
  })()
  if (graph) {
    const className = `graph-${basicPlanCode}`
    return <div className={className}>{graph}</div>
  } else {
    return ''
  }
}

const ProductBenefitsSummary = ({
  displayProduct,
  sumAssured,
  basicPremium,
  calculatedPaymentPeriod,
  calculatedCoveragePeriod,
  benefitSummaryHeader,
  selectedModelFactorID,
  selectedModelFactorLabel,
  sumAssuredContract,
}: ProductBenefitProps) => {
  const productName = _.isFunction(benefitSummaryHeader)
    ? // $$FlowFixMe
      benefitSummaryHeader({
        displayProduct,
        sumAssured,
        basicPremium,
        calculatedPaymentPeriod,
        calculatedCoveragePeriod,
      })
    : benefitSummaryHeader
  return (
    <div id="product-benefits-summary" className="product">
      <h3 className="name">{_getProductName(productName, displayProduct)}</h3>
      <div className="info-list">
        <div id="benefits-coverage-period" className="info">
          <p className="label">{BIMESSAGES.COVERAGE_PERIOD}</p>
          <p className="value">
            {calculatedCoveragePeriod} {MESSAGES.TIME_UNIT_YEAR}
          </p>
        </div>
        <div id="benefits-payment-period" className="info">
          <p className="label">{BIMESSAGES.PAYMENT_PERIOD}</p>
          <p className="value">
            {selectedModelFactorID === 'model-factor_5' ? selectedModelFactorLabel : calculatedPaymentPeriod + ' ปี'}
          </p>
        </div>
        <div id="benefits-sum-assured" className="info">
          <p className="label">{BIMESSAGES.BASIC_PLAN_SUM_ASSURED}</p>
          <p className="value">
            {formatNumber(sumAssuredContract)} {MESSAGES.BAHT}
          </p>
        </div>
        <div id="benefits-basic-premium" className="info">
          <p className="label">{BIMESSAGES.BASIC_PREMIUM}</p>
          <p className="value">
            {formatNumber(basicPremium, 2)} {MESSAGES.BAHT}
          </p>
        </div>
      </div>
      {displayProduct ? renderGraph(displayProduct.basicPlanCode) : ''}
    </div>
  )
}

export default ProductBenefitsSummary
