// @flow
import { get } from 'lodash/fp'
import { createSelector } from 'reselect'

export const getUi = get('ui')
export const getUiVisited = createSelector(getUi, get('visited'))

export const getImages = createSelector(getUi, get('images'))
export const getImageByName = (imageName) => createSelector(getImages, get(imageName))

export const isVisitedPath = (path) => createSelector(getUiVisited, get(path))
export const isLoading = (state: AppState): boolean => state.ui.isLoading
export const getIsAddingInvestmentQuestionForNonILPNeedType = createSelector(
  getUi,
  get('isAddingInvestmentQuestionForNonILPNeedType')
)
