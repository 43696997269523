// @flow
import _ from 'lodash'
import { d } from 'core/service/lib/decimal'
import { round } from 'core/service/lib/number-format'
import type { Rider, RiderState } from 'core/data-model/rider'

const PRECISION = 2

type TaxDeDeductionProps = {
  modelFactorPeriod: number,
  basicPremium: number,
  totalRiderPremium: number,
  yearOfCoverage: number,
  availableRiders: (Rider & RiderState)[],
  basicPlan: { code: string },
  riderTaxRates: Object,
  basicTaxRates: Object,
}

export type TaxDeduction = {
  taxDeductionBasicPremiumForYear: number,
  taxDeductionRiderPremuimForYear: number,
  taxDeductionTotalForYear: number,
  taxDeductionForCoverage: number,
  taxDeductionRetirementForYear: number,
  taxDeductionLifeForYear: number,
  taxDeductionHealthForYear: number,
}

export type TaxDeductionForYear = {
  taxDeductionRetirementForYear: number,
  taxDeductionLifeForYear: number,
  taxDeductionHealthForYear: number,
}

const getRiderPremiumForYear = ({ basicPlan, totalRiderPremium, availableRiders, modelFactorPeriod }): number => {
  const riderPLS = availableRiders.find(
    (r) => _.includes(['PLS10', 'PLS12', 'PLS15'], _.get(r, 'selectedPlan.planCode')) && r.isSelected
  )
  const riderPremium = _.get(riderPLS, 'premium', 0)
  let totalRiderPremiumTaxDeduct = d(riderPremium)
    .times(modelFactorPeriod)
    .toNumber()

  const riderMEK = availableRiders.find((r) => _.includes(['MEK'], _.get(r, 'code')) && r.isSelected)
  const riderPremiumMEK = _.get(riderMEK, 'premium', 0)
  totalRiderPremiumTaxDeduct =
    totalRiderPremiumTaxDeduct +
    d(riderPremiumMEK)
      .times(modelFactorPeriod)
      .toNumber()
  return totalRiderPremiumTaxDeduct
}
const getBasicTaxForYear = (basicPremium, modelFactorPeriod, basicTaxRate): TaxDeductionForYear => {
  let basicTaxDeductionForYear = {
    taxDeductionRetirementForYear: 0,
    taxDeductionLifeForYear: 0,
    taxDeductionHealthForYear: 0,
  }
  basicTaxDeductionForYear.taxDeductionRetirementForYear = round(
    d(basicPremium)
      .times(_.get(basicTaxRate, 'retire', 0))
      .div(100)
      .times(modelFactorPeriod)
      .toNumber(),
    PRECISION
  )

  basicTaxDeductionForYear.taxDeductionLifeForYear = round(
    d(basicPremium)
      .times(_.get(basicTaxRate, 'life', 0))
      .div(100)
      .times(modelFactorPeriod)
      .toNumber(),
    PRECISION
  )

  basicTaxDeductionForYear.taxDeductionHealthForYear = round(
    d(basicPremium)
      .times(_.get(basicTaxRate, 'health', 0))
      .div(100)
      .times(modelFactorPeriod)
      .toNumber(),
    PRECISION
  )

  return basicTaxDeductionForYear
}

const getRiderTaxForYear = (availableRiders, modelFactorPeriod, riderTaxRates): TaxDeductionForYear => {
  let riderTaxDeductionForYear = {
    taxDeductionRetirementForYear: 0,
    taxDeductionLifeForYear: 0,
    taxDeductionHealthForYear: 0,
  }

  availableRiders
    .filter((r) => {
      return r.isSelected && r.isSelectable
    })
    .map((r) => {
      const RiderTaxRate = riderTaxRates.find((taxRate) => _.includes([r.code], _.get(taxRate, 'code')))
      riderTaxDeductionForYear.taxDeductionRetirementForYear += round(
        d(_.get(r, 'premium', 0))
          .times(_.get(RiderTaxRate, 'taxRate.retire', 0))
          .div(100)
          .times(modelFactorPeriod)
          .toNumber(),
        PRECISION
      )
      riderTaxDeductionForYear.taxDeductionLifeForYear += round(
        d(_.get(r, 'premium', 0))
          .times(_.get(RiderTaxRate, 'taxRate.life', 0))
          .div(100)
          .times(modelFactorPeriod)
          .toNumber(),
        PRECISION
      )
      riderTaxDeductionForYear.taxDeductionHealthForYear += round(
        d(_.get(r, 'premium', 0))
          .times(_.get(RiderTaxRate, 'taxRate.health', 0))
          .div(100)
          .times(modelFactorPeriod)
          .toNumber(),
        PRECISION
      )
    })
  return riderTaxDeductionForYear
}

const _generateTaxDeductionBenefit = ({
  modelFactorPeriod,
  basicPremium,
  totalRiderPremium,
  yearOfCoverage,
  availableRiders,
  basicPlan,
  riderTaxRates,
  basicTaxRate,
}: TaxDeDeductionProps): TaxDeduction => {
  const taxDeductionBasicPremiumForYear = d(basicPremium)
    .times(modelFactorPeriod)
    .toNumber()
  const taxDeductionRiderPremuimForYear = getRiderPremiumForYear({
    basicPlan,
    availableRiders,
    totalRiderPremium,
    modelFactorPeriod,
  })

  const taxDeductionTotalForYear = d(taxDeductionBasicPremiumForYear)
    .plus(taxDeductionRiderPremuimForYear)
    .toNumber()
  const taxDeductionForCoverage = d(taxDeductionTotalForYear)
    .times(yearOfCoverage)
    .toNumber()

  const riderTaxForYear = getRiderTaxForYear(availableRiders, modelFactorPeriod, riderTaxRates)
  const basicTaxForYear = getBasicTaxForYear(basicPremium, modelFactorPeriod, basicTaxRate)
  const taxDeductionRetirementForYear =
    basicTaxForYear.taxDeductionRetirementForYear + riderTaxForYear.taxDeductionRetirementForYear
  const taxDeductionLifeForYear = basicTaxForYear.taxDeductionLifeForYear + riderTaxForYear.taxDeductionLifeForYear
  const taxDeductionHealthForYear =
    basicTaxForYear.taxDeductionHealthForYear + riderTaxForYear.taxDeductionHealthForYear

  return {
    taxDeductionBasicPremiumForYear,
    taxDeductionRiderPremuimForYear,
    taxDeductionTotalForYear,
    taxDeductionForCoverage,
    taxDeductionRetirementForYear,
    taxDeductionLifeForYear,
    taxDeductionHealthForYear,
  }
}

export const generateTaxDeductionBenefit = (props: TaxDeDeductionProps): TaxDeduction => {
  return props.basicPlan.code !== 'PLB05'
    ? _generateTaxDeductionBenefit(props)
    : {
        taxDeductionBasicPremiumForYear: 0,
        taxDeductionRiderPremuimForYear: 0,
        taxDeductionTotalForYear: 0,
        taxDeductionForCoverage: 0,
        taxDeductionRetirementForYear: 0,
        taxDeductionLifeForYear: 0,
        taxDeductionHealthForYear: 0,
      }
}
