//@flow
import type { ConfirmIproFNANotification } from '../notification/actions'
import { Button, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import styled from 'styled-components'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Modal } from '../../common-components'
import { confirmIproFNA } from 'quick-quote/actions'
import { resetFNA } from 'quick-quote/fna/need-and-gap-analysis/actions'

type ConfirmIproFNAProps = {
  notificationMessage: ConfirmIproFNANotification,
  onExit: Function,
  cancelIproFNA: () => void,
  confirmIproFNA: () => void,
}

const CustomModal = styled(Modal)`
  .modal-title {
    flex: 1 0 auto;
    font-weight: bold;
    text-align: center;
    color: ${({ theme }) => theme.variables['$brand-primary']};
  }
  .modal-body {
    padding: 50px 20px;
    text-align: center;
  }
  .modal-footer {
    justify-content: space-between;
    &.center {
      justify-content: center;
    }
    border-top: none;
  }
`

export const ConfirmIproFNAModal = ({
  notificationMessage,
  onExit,
  cancelIproFNA,
  confirmIproFNA,
}: ConfirmIproFNAProps) => (
  <CustomModal isOpen={notificationMessage.type !== 'None'} backdrop={true} modalClassName="default-bootstrap">
    <ModalHeader tag="div">กรุณาตรวจสอบข้อมูล</ModalHeader>
    <ModalBody>
      ความคุ้มครองที่ท่านเลือกไม่ตรงกับผลิตภัณฑ์ที่ท่านระบุไว้
      <br />
      ท่านสามารถแก้ไขข้อมูลหรือกดยืนยันเพื่อเข้าสู่ขั้นตอนถัดไป
    </ModalBody>
    <ModalFooter className="center">
      <Button
        color="outline-primary"
        onClick={() => {
          cancelIproFNA()
          onExit()
        }}
      >
        เปลี่ยน FNA
      </Button>
      <Button
        color="primary"
        onClick={() => {
          confirmIproFNA()
          onExit()
        }}
      >
        ยืนยัน FNA
      </Button>
    </ModalFooter>
  </CustomModal>
)

const mapDispatchToProps = (dispatch: Dispatch<*>) => ({
  cancelIproFNA: bindActionCreators(resetFNA, dispatch),
  confirmIproFNA: bindActionCreators(confirmIproFNA, dispatch),
})

export default connect(null, mapDispatchToProps)(ConfirmIproFNAModal)
