import _ from 'lodash'

import { createSectionCreator, createField } from '../utils'
import { checker } from '../selectors'

const wasRejectedField = (app, id) => {
  const isShowRemoveButton = !id.includes('[0]')

  return _.compact([
    createField(`${id}.companyName`, [
      {
        label: 'บริษัท',
        id: `${id}.companyName`,
        c: 'TextInput',
        required: true,
        p: {},
      },
    ]),
    createField(`${id}.cause`, [
      {
        label: 'สาเหตุ',
        id: `${id}.cause`,
        c: 'TextInput',
        required: true,
        p: {},
      },
    ]),
    createField(`${id}.when`, [
      {
        label: 'เมื่อใด',
        id: `${id}.when`,
        c: 'TextInput',
        required: true,
        p: {},
      },
    ]),
    isShowRemoveButton &&
      createField(
        `${id}.remove`,
        [
          {
            id: `${id}.remove`,
            c: 'Button',
            p: {
              icon: 'remove',
              children: 'ลบ',
              mergeProps: ({ removeValue }) => ({
                onClick: () => removeValue(id),
              }),
              style: {
                fontSize: '0.8725rem',
                padding: '0.125rem 0.5rem',
              },
            },
          },
        ],
        {
          style: {
            position: 'absolute',
            top: '0',
            right: '0',
          },
        }
      ),
  ])
}

const addWasRejected = (app, id) => [
  createField(`${id}.addNew`, [
    {
      id,
      c: 'Button',
      p: {
        children: 'เพิ่มข้อมูล',
        icon: 'add',
        block: true,
        mergeState: () => ({
          mergeProps: ({ setValue }) => ({
            onClick: () => setValue(null),
          }),
        }),
      },
    },
  ]),
]

export default (sectionId, sectionLabel) => (app, id) => {
  const wasRejectedDetail = _.get(app, `${id}.wasRejectedMRTA.detail`, [null])
  const { isYes, isNo } = checker(app)

  return _.compact(
    isYes(`${id}.wasRejectedMRTA.checked`) &&
      _.reduce(
        wasRejectedDetail,
        (result, el, index) => [
          ...result,
          createSectionCreator(wasRejectedField)(`${sectionId}[${index}]`, null, null, `บริษัทที่ ${index + 1}`)(
            app,
            `${id}.wasRejectedMRTA.detail[${index}]`
          ),
        ],
        []
      ).concat(
        wasRejectedDetail.length < 10 &&
          createSectionCreator(addWasRejected)(sectionId)(
            app,
            `${id}.wasRejectedMRTA.detail[${wasRejectedDetail.length}]`
          )
      )
  )
}
