// @flow

import type { Rider, RiderState } from 'core/data-model/rider'
import type { Benefit } from 'core/data-model/rider/benefits'

import RiderBenefitSummary from './rider-benefit-summary'
import RiderWithBenefitModal from './rider-benefit-modal'
import { formatNumber } from 'core/service/lib/number-format'
import { isObject, isNotNil, isString, isArray } from 'core/service/lib/type-check'
import { AgentProtectionDisclaimer } from './agent-protection-disclaimer'
import { AdditionalDisclaimer } from './additional-disclaimer'
import _ from 'lodash'

type MEKRiderBenefitProps = {
  rider: Rider & RiderState,
  benefits: Benefit[],
  planHeader: Benefit,
  sumAssureBenefits: Benefit[],
  sumAssure: number,
}

type MEKRiderBenefitsRowProps = {
  description: string,
  benefit: string,
}

const getSelectedPlanBenefit = (benefit: RiderBenefitData, selectedPlanCode: string): Benefit => {
  let selectedPlanBenefit =
    benefit.values && isArray(benefit.values) ? _.find(benefit.values, ['planCode', selectedPlanCode]) : null
  return {
    description: isString(benefit.description) ? benefit.description : '',
    value:
      selectedPlanBenefit && selectedPlanBenefit.value && isString(selectedPlanBenefit.value)
        ? selectedPlanBenefit.value
        : '',
  }
}
const renderMEKRiderSubBenefitsRow = (rowNumber: number, mekRiderBenefitsRowProps: MEKRiderBenefitsRowProps) => {
  const benefitDescription = mekRiderBenefitsRowProps.description
    .split('\n')
    .map((text) => <div key={`${text}-${rowNumber}`}>{text}</div>)
  const benefitValue = isNaN(parseInt(mekRiderBenefitsRowProps.benefit))
    ? mekRiderBenefitsRowProps.benefit.replace(/\n/g, '')
    : formatNumber(parseInt(mekRiderBenefitsRowProps.benefit))
  return (
    <tr key={`mek-rider-benefit-row-${rowNumber}`} id={`mek-rider-benefit-row-${rowNumber}`}>
      <td>{benefitDescription}</td>
      <td>{benefitValue}</td>
    </tr>
  )
}

const renderMEKRiderBenefitsRow = (rowNumber: number, mekRiderBenefitsRowProps: MEKRiderBenefitsRowProps) => {
  const benefitDescription = mekRiderBenefitsRowProps.description
    .split('\n')
    .map((text) => <div key={`${text}-${rowNumber}`}>{text}</div>)
  const benefitValue = isNaN(parseInt(mekRiderBenefitsRowProps.benefit))
    ? mekRiderBenefitsRowProps.benefit
    : formatNumber(parseInt(mekRiderBenefitsRowProps.benefit))
  if (mekRiderBenefitsRowProps.benefit === '') {
    return (
      <tr key={`mek-rider-benefit-row-${rowNumber}`} id={`mek-rider-benefit-row-${rowNumber}`}>
        <td className="benefit-bold " colSpan={2}>
          {benefitDescription}
        </td>
      </tr>
    )
  }

  return (
    <tr key={`mek-rider-benefit-row-${rowNumber}`} id={`mek-rider-benefit-row-${rowNumber}`}>
      <td className="benefit-bold">{benefitDescription}</td>
      <td>{benefitValue}</td>
    </tr>
  )
}

const renderMEKRiderBenefitsTable = ({
  rider,
  benefits,
  planHeader,
  sumAssureBenefits,
  sumAssure,
}: MEKRiderBenefitProps) => {
  const planHeaderSelected = getSelectedPlanBenefit(planHeader, rider.selectedPlan.planCode)
  let tableIndex = 0
  return (
    <div className="details-table">
      <table id="mek-benefit-table">
        <thead>
          <tr>
            <td>{planHeaderSelected.description}</td>
            <td>{planHeaderSelected.value}</td>
          </tr>
        </thead>
        <tbody>
          {sumAssureBenefits.map((benefit) => {
            return renderMEKRiderSubBenefitsRow(++tableIndex, {
              description: isObject(benefit) && isNotNil(benefit.description) ? benefit.description : '',
              benefit: sumAssure,
            })
          })}

          {benefits.map((benefit) => {
            let benefitSelected = getSelectedPlanBenefit(benefit, rider.selectedPlan.planCode)
            const resultBenefit = renderMEKRiderBenefitsRow(++tableIndex, {
              description: benefitSelected.description,
              benefit: benefitSelected.value,
            })

            let result = benefit.subBenefits.map((subBenefit) => {
              let subBenefitSelected = getSelectedPlanBenefit(subBenefit, rider.selectedPlan.planCode)
              return renderMEKRiderSubBenefitsRow(++tableIndex, {
                description: subBenefitSelected.description,
                benefit: subBenefitSelected.value,
              })
            })
            result.unshift(resultBenefit)
            return result
          })}
        </tbody>
      </table>
    </div>
  )
}

const MEKRiderBenefit = (props: MEKRiderBenefitProps) => {
  const title = `${props.rider.description} ${props.rider.name}`
  const benefitTable = props.benefits ? renderMEKRiderBenefitsTable(props) : null
  return (
    <RiderWithBenefitModal
      id="mek-rider-benefit"
      className="rider mek-rider"
      title={title}
      modalChildren={benefitTable}
    >
      <RiderBenefitSummary rider={props.rider} />
      {benefitTable}
      <AgentProtectionDisclaimer rider={props.rider} />
      <AdditionalDisclaimer rider={props.rider} />
    </RiderWithBenefitModal>
  )
}

export default MEKRiderBenefit
