// @flow
import type { AppState } from 'quick-quote/reducers'
import type {
  InvestmentDivedendRate,
  InvestmentBenefitTableParams,
} from 'core/service/benefit-illustration/investment-benefit'
import { createSelector } from 'reselect'
import MESSAGES from 'core/data-model/constants/bi-messages'
import { getPolicyValues } from 'quick-quote/product-whole-life/benefit-illustration/selectors'
import { getSelectedDisplayProductCode } from 'quick-quote/product-selection/selectors'
import { getSumAssured } from 'quick-quote/product-common/coverage-plan/selectors'

export const getDividendRatesByProductCode = createSelector(
  getSelectedDisplayProductCode,
  (productCode): Array<InvestmentDivedendRate> => {
    switch (productCode) {
      case '20P10':
      case '20P10S':
      case 'IHEALTHYULTRA-20P10':
      case 'IHEALTHYULTRA-20P10S':
        return [
          { name: MESSAGES.EXAMPLE_1, rate: 0 },
          { name: MESSAGES.EXAMPLE_2, rate: 10 },
          { name: MESSAGES.EXAMPLE_3, rate: 30 },
        ]
      default:
        return [
          { name: MESSAGES.EXAMPLE_1, rate: 0 },
          { name: MESSAGES.EXAMPLE_2, rate: 8 },
          { name: MESSAGES.EXAMPLE_3, rate: 35 },
        ]
    }
  }
)

export const getInvestmentBenefitParams = createSelector(
  getPolicyValues,
  getDividendRatesByProductCode,
  getSumAssured,
  (policyValue, dividendRates, sumAssured): InvestmentBenefitTableParams => ({ policyValue, dividendRates, sumAssured })
)

export const getInvestmentBenefit = (state: AppState) => state.benefitIllustration.investmentBenefit
