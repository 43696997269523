//@flow
import type { AppState } from 'quick-quote/reducers'
import type { FSE, Office, Profile, Staff, User } from 'core/data-model/identity'
import type { DistributorGroup, DistributorGroupType } from 'core/data-model/distributor'
import { createSelector } from 'reselect'
import { get, includes } from 'lodash/fp'
import DEFAULT from 'core/data-model/constants/defaults'
import { getAppConfig } from 'deploy-env/app-config'
import { getToggles } from 'quick-quote/feature-toggles'

import { filterValidateLicense } from 'core/service/distributor/permission'

export const getIdentityProfileImage = (state: AppState) => {
  if (state.identity.user.type === 'Authenticated') return state.identity.user.profile.user_image
  return undefined
}

export const getIdentityProfile = (state: AppState): ?Profile => {
  if (state.identity.user.type === 'Authenticated') return state.identity.user.profile
  else return undefined
}
export const getIdentityUser = (state: AppState): User => state.identity.user
export const getUserAgentCode = (state: AppState): ?string => {
  const user = state.identity.user
  if (user.type === 'Authenticated') return user.profile.agent_code
  return undefined
}
// $$FlowFixMe
export const getUserAgentType = (state: AppState): string =>
  get('identity.user.profile.agent_type')(state) || DEFAULT.DEFAULT_AGENT_TYPE
export const isLoggedIn = (state: AppState): boolean => state.identity.user.type === 'Authenticated'
export const getUserDistributorGroup = (state: AppState): DistributorGroupType => state.identity.user.distributorGroup

export const isBancAgent = (state: AppState): boolean =>
  state.identity.user.distributorGroup === 'BANC' && state.identity.user.type === 'Authenticated'

export const getIsAgent = (state: AppState): boolean =>
  state.identity.user.distributorGroup === 'AGENT' && state.identity.user.type === 'Authenticated'

export const isAllowIproMenu = (state: AppState): boolean => {
  const allowedUserType = getAppConfig().ALLOWED_IPRO_MENU
  return getToggles().ENABLE_OPTY_MENU && isLoggedIn(state) && includes(getUserAgentType(state))(allowedUserType)
}

export const isAllowOptionalIHealtyUltra = (state: AppState): boolean => {
  const allowedUserType = getAppConfig().ALLOWED_OPTIONAL_IHEALTHY_ULTRA
  return (
    getToggles().ENABLE_OPTIONAL_IHEALTHY_ULTRA &&
    isLoggedIn(state) &&
    includes(getUserAgentType(state))(allowedUserType)
  )
}

export const isAllowReviseQQMenu = (state: AppState): boolean => {
  const allowedUserType = getAppConfig().ALLOWED_REVISE_QQ_MENU
  return getToggles().ENABLE_REVISE_FNA && isLoggedIn(state) && includes(getUserAgentType(state))(allowedUserType)
}
export const isNewAsteriskOfSummaryMainContract = (state: AppState): boolean => {
  const allowedUserTypeNewAsterisk = getAppConfig().ALLOWED_NEW_ASTERISK_OF_SUMMARY_MAIN_CONTRACT
  return (
    getToggles().ENABLE_NEW_ASTERISK_OF_SUMMARY_MAIN_CONTRACT &&
    isLoggedIn(state) &&
    includes(getUserAgentType(state))(allowedUserTypeNewAsterisk)
  )
}

export const isAllowMwpMenu = (state: AppState): boolean => {
  const allowedUserType = getAppConfig().ALLOWED_MWP_MENU
  return getToggles().ENABLE_MWP_MENU && isLoggedIn(state) && includes(getUserAgentType(state))(allowedUserType)
}

export const getSelectedFSE = (state: AppState): ?FSE =>
  // $$FlowFixMe
  isBancAgent(state) ? state.identity.user.fse : undefined

export const getSelectedOffice = (state: AppState): ?Office =>
  // $$FlowFixMe
  isBancAgent(state) ? state.identity.user.office : undefined

export const getDistributorGroups = (state: AppState): DistributorGroup[] => state.distributorGroups.payload
export const getDistributorGroupsStatus = (state: AppState): string => state.distributorGroups.status

// $$FlowFixMe
export const getStaffId = (state: AppState): string => state.identity.user.staffId || ''
// $$FlowFixMe
export const getStaffName = (state: AppState): string => state.identity.user.staffName || ''
// $$FlowFixMe
export const getStaffSurname = (state: AppState): string => state.identity.user.staffSurname || ''
// $$FlowFixMe
export const getStaffPosition = (state: AppState): string => state.identity.user.staffPosition || ''

export const getValidUserLicenses = (state: AppState): string =>
  filterValidateLicense(state.identity.user.registrations || [])

export const getStaffInfo = createSelector(
  getStaffId,
  getStaffName,
  getStaffSurname,
  getStaffPosition,
  (id, name, surname, position): Staff => ({ id, name, surname, position })
)
