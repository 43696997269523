//@flow
export default {
  // Insured INFORMATION
  DEFAULT_INSURED_AGE: { value: 30, unit: 'year' },
  DEFAULT_INSURED_GENDER: 'M',
  DEFAULT_PAYER_AGE: { value: 30, unit: 'year' },
  DEFAULT_PAYER_GENDER: 'M',

  DEFAULT_SELECT_OCCUPATION_CODE: 'OC0091',
  DEFAULT_SELECT_OCCUPATION_CODE_KIDS: 'OC0198',

  // Product Category: RECOMMEND AND P-H-E-R-L
  DEFAULT_PRODUCT_CATEGORY: 'CATEGORY_RECOMMEND',

  // Effect on insured page when switch age between kids and adult
  DEFAULT_UNDERAGE_OCCUPATION_CODE: 'OC0198',
  DEFAULT_UNDERAGE_OCCUPATION_TEXT: 'นักเรียน,นิสิต,นักศึกษา',
  DEFAULT_UNDERAGE_NATURE_OF_DUTY_CODE: 'ND0755',
  DEFAULT_UNDERAGE_NATURE_OF_DUTY_TEXT: 'กำลังศึกษา (ไม่มีรายได้)',
  DEFAULT_UNDERAGE_18_19_NATURE_OF_DUTY_CODE: 'ND0756',
  DEFAULT_UNDERAGE_18_19_NATURE_OF_DUTY_TEXT: 'กำลังศึกษาและอายุ 18 ปีขึ้นไป',

  IGNORE_NATURE_OF_DUTY_CODE_BETWEEN_AGE_18_19: ['ND0754', 'ND0756'],

  // Effect to default reducer and set default state when select product
  DEFAULT_OCCUPATION_CODE: '',
  DEFAULT_NATURE_OF_DUTY_CODE: '',
  DEFAULT_NATURE_OF_DUTY_TEXT: '',

  DEFAULT_INSURED_ENTRY_AGE_MINIMUM: '0d',
  DEFAULT_INSURED_ENTRY_AGE_MAXIMUM: '200y',
  DEFAULT_PAYER_ENTRY_AGE: {
    minimum: { value: 20, unit: 'year' },
    maximum: { value: 99, unit: 'year' },
  },
  MALE: 'M',
  FEMALE: 'F',

  SELF: 'SELF',
  DIRECTDEPENDENT: 'DIRECTDEPENDENT',
  SPOUSE: 'SPOUSE',
  CHILD: 'CHILD',
  PARENT: 'PARENT',
  PAY_MY_SELF: 'SELF',

  ADULT: 'ADULT',
  PRE_ADULT: 'PRE_ADULT',
  KID: 'KID',

  DEFAULT_PAYER_RELATION_ENTRY_AGE: {
    SELF: {
      minimum: { value: 20, unit: 'year' },
      maximum: { value: 99, unit: 'year' },
    },
    PARENT: {
      minimum: { value: 1, unit: 'month' },
      maximum: { value: 19, unit: 'year' },
    },
    SPOUSE: {
      minimum: { value: 16, unit: 'year' },
      maximum: { value: 99, unit: 'year' },
    },
  },

  RIDERS_SORT_ORDER: [
    'WP',
    'WPTPD',
    'WPTPDCI',
    'PBPDD',
    'PBPDDCI',
    'PBSDD',
    'PBSDDCI',
    'WPD',
    'WPDD',
    'MHP',
    'MEX',
    'MEAPlus',
    'MEB',
    'AP',
    'ECARE',
    'DCI',
    'PLR',
    'PLS',
    'CPR',
    'HIC',
    'CI',
    'TPD',
    'IPD',
    'HB',
    'MRTAAP',
  ],
  RIDERS_REQUIRE_PAYER_INFO: ['WPD', 'WPDD', 'PBG', 'PBPDD', 'PBPDDCI', 'PBSDD', 'PBSDDCI'],
  PROXY_RIDERS: ['PBG', 'MESG'],
  SELECTED_PRODUCT_CODE: '10WL85',
  SELECTED_PRODUCT_TYPE: 'product',
  INVESTMENT_REGULAR_PREMIUM_RATIO: 50,
  SELECTED_MODEL_FACTOR: 'model-factor_1',
  SELECTED_MODEL_FACTOR_MONTHLY: 'model-factor_3',
  SELECTED_MODEL_FACTOR_SPUL: 'model-factor_5',
  SELECTED_MODEL_FACTOR_S7_SINGLE: 'model-factor_5',
  SELECTED_MODEL_FACTOR_MRTA: 'model-factor_5',
  SELECTED_MODEL_FACTOR_R01: 'model-factor_6',
  MONTHS_IN_YEAR: 12,
  RIDER_PBG_COVERAGE_MIN: 5,
  RIDER_PBG_COVERAGE_MAX: 6,
  RIDER_PBG_MAX_PAYER_AGE: 60,
  LOAN_PRODUCT_CATEGORY: 'LOAN',

  DEFAULT_HEALTH_PRODUCT_CODE: 'PWLNP85',
  DEFAULT_FLEXI_HEALTH_PRODUCT_CODE: 'FWLNP85',
  DEFAULT_IRETIRE_1_PRODUCT_CODE: 'R01',
  DEFAULT_IRETIRE_5_PRODUCT_CODE: 'R05',
  DEFAULT_PERFECT_LIFE_PRODUCT_CODE: 'PERFECTLIFE-WL88J',
  DEFAULT_IINVEST_PRODUCT_CODE: 'SPUL',
  DEFAULT_LIFE_SAVE_PRO_PRODUCT_CODE: ['20P10', '25P15'],
  DEFAULT_LIFE_PROTECT_18_PRODUCT_CODE: 'WL88J',
  DEFAULT_LIFE_PROTECT_10_PRODUCT_CODE: 'W85P10J',
  DEFAULT_S7_3PAY_PRODUCT_CODE: 'CIWLM39',
  DEFAULT_S7_SINGLE_PRODUCT_CODE: 'CIWLS39',
  DEFAULT_HEALTH_TOPPING_PRODUCT_CODE: 'HEALTHTOPPING-WLNP60L',
  DEFAULT_SUKHAPABDEKDEE_PRODUCT_CODE: 'SUKHAPABDEKDEE-WLNP85',
  DEFAULT_IHEALTHY_ULTRA_PRODUCT_CODE: 'IHEALTHYULTRA-WLNP85',

  DEFAULT_LIFE_READY_6_YEAR: 'W99FU06',
  DEFAULT_LIFE_READY_12_YEAR: 'W99FU12',
  DEFAULT_LIFE_READY_18_YEAR_UNDER: 'W99FU18',
  DEFAULT_LIFE_READY_18_YEAR_HIGHER: 'W99FH18',
  DEFAULT_LIFE_READY_99_YEAR_UNDER: 'W99FU99',
  DEFAULT_LIFE_READY_99_YEAR_HIGHER: 'W99FH99',

  DEFAULT_LIFE_TREASURE_6_YEAR: 'H99F06A',
  DEFAULT_LIFE_TREASURE_12_YEAR: 'H99F12A',
  DEFAULT_LIFE_TREASURE_18_YEAR: 'H99F18A',

  DEFAULT_MRTA_FULL_PRODUCT_CODE: 'MRTA_FULL',
  DEFAULT_MRTA_PLUS_PRODUCT_CODE: 'MRTA_PLUS',
  DEFAULT_MRTA_SIMPLY_PRODUCT_CODE: 'MRTA_SIMPLY',
  DEFAULT_MRTA_TRUNCATE_PRODUCT_CODE: 'MRTA_TRUNCATE',
  MRTA_FULL_GROUP_PRODUCT_CODE: [
    'M03TB',
    'M04TB',
    'M05TB',
    'M06TB',
    'M07TB',
    'M08TB',
    'M09TB',
    'M10TB',
    'M11TB',
    'M12TB',
    'M13TB',
    'M14TB',
    'M15TB',
    'M16TB',
    'M17TB',
    'M18TB',
    'M19TB',
    'M20TB',
    'M21TB',
    'M22TB',
    'M23TB',
    'M24TB',
    'M25TB',
    'M26TB',
    'M27TB',
    'M28TB',
    'M29TB',
    'M30TB',
  ],
  MRTA_PLUS_GROUP_PRODUCT_CODE: [
    'M03TA',
    'M04TA',
    'M05TA',
    'M06TA',
    'M07TA',
    'M08TA',
    'M09TA',
    'M10TA',
    'M11TA',
    'M12TA',
    'M13TA',
    'M14TA',
    'M15TA',
    'M16TA',
    'M17TA',
    'M18TA',
    'M19TA',
    'M20TA',
    'M21TA',
    'M22TA',
    'M23TA',
    'M24TA',
    'M25TA',
    'M26TA',
    'M27TA',
    'M28TA',
    'M29TA',
    'M30TA',
  ],
  MRTA_SIMPLY_GROUP_PRODUCT_CODE: [
    'M03T',
    'M04T',
    'M05T',
    'M06T',
    'M07T',
    'M08T',
    'M09T',
    'M10T',
    'M11T',
    'M12T',
    'M13T',
    'M14T',
    'M15T',
    'M16T',
    'M17T',
    'M18T',
    'M19T',
    'M20T',
    'M21T',
    'M22T',
    'M23T',
    'M24T',
    'M25T',
    'M26T',
    'M27T',
    'M28T',
    'M29T',
    'M30T',
  ],
  MRTA_TRUNCATE_GROUP_PRODUCT_CODE: [
    'N10T10A',
    'N11T10A',
    'N12T10A',
    'N13T10A',
    'N14T10A',
    'N15T10A',
    'N16T10A',
    'N17T10A',
    'N18T10A',
    'N19T10A',
    'N20T10A',
    'N21T10A',
    'N22T10A',
    'N23T10A',
    'N24T10A',
    'N25T10A',
    'N26T10A',
    'N27T10A',
    'N28T10A',
    'N29T10A',
    'N30T10A',
  ],
  DEFAULT_GLTSP_PRODUCT_CODE: 'GLTSP',
  GLTSP_GROUP_PRODUCT_CODE: [
    'GL01TA',
    'GL02TA',
    'GL03TA',
    'GL04TA',
    'GL05TA',
    'GL06TA',
    'GL07TA',
    'GL08TA',
    'GL09TA',
    'GL10TA',
    'GL11TA',
    'GL12TA',
    'GL13TA',
    'GL14TA',
    'GL15TA',
  ],

  DEFAULT_LOAN_PERIOD: {
    MRTA: [
      { value: 3, text: '3' },
      { value: 4, text: '4' },
      { value: 5, text: '5' },
      { value: 6, text: '6' },
      { value: 7, text: '7' },
      { value: 8, text: '8' },
      { value: 9, text: '9' },
      { value: 10, text: '10' },
      { value: 11, text: '11' },
      { value: 12, text: '12' },
      { value: 13, text: '13' },
      { value: 14, text: '14' },
      { value: 15, text: '15' },
      { value: 16, text: '16' },
      { value: 17, text: '17' },
      { value: 18, text: '18' },
      { value: 19, text: '19' },
      { value: 20, text: '20' },
      { value: 21, text: '21' },
      { value: 22, text: '22' },
      { value: 23, text: '23' },
      { value: 24, text: '24' },
      { value: 25, text: '25' },
      { value: 26, text: '26' },
      { value: 27, text: '27' },
      { value: 28, text: '28' },
      { value: 29, text: '29' },
      { value: 30, text: '30' },
      { value: 31, text: '31' },
      { value: 32, text: '32' },
      { value: 33, text: '33' },
      { value: 34, text: '34' },
      { value: 35, text: '35' },
      { value: 36, text: '36' },
      { value: 37, text: '37' },
      { value: 38, text: '38' },
      { value: 39, text: '39' },
      { value: 40, text: '40' },
    ],
    GLTSP: [
      { value: 1, text: '1' },
      { value: 2, text: '2' },
      { value: 3, text: '3' },
      { value: 4, text: '4' },
      { value: 5, text: '5' },
      { value: 6, text: '6' },
      { value: 7, text: '7' },
      { value: 8, text: '8' },
      { value: 9, text: '9' },
      { value: 10, text: '10' },
      { value: 11, text: '11' },
      { value: 12, text: '12' },
      { value: 13, text: '13' },
      { value: 14, text: '14' },
      { value: 15, text: '15' },
      { value: 16, text: '16' },
      { value: 17, text: '17' },
      { value: 18, text: '18' },
      { value: 19, text: '19' },
      { value: 20, text: '20' },
      { value: 21, text: '21' },
      { value: 22, text: '22' },
      { value: 23, text: '23' },
      { value: 24, text: '24' },
      { value: 25, text: '25' },
      { value: 26, text: '26' },
      { value: 27, text: '27' },
      { value: 28, text: '28' },
      { value: 29, text: '29' },
      { value: 30, text: '30' },
      { value: 31, text: '31' },
      { value: 32, text: '32' },
      { value: 33, text: '33' },
      { value: 34, text: '34' },
      { value: 35, text: '35' },
      { value: 36, text: '36' },
      { value: 37, text: '37' },
      { value: 38, text: '38' },
      { value: 39, text: '39' },
      { value: 40, text: '40' },
    ],
  },

  DEFAULT_MRTA_GLTSP_RANGE_COVERAGE_PERIOD: {
    MRTA_PLUS: {
      minimum: 3,
      maximum: 30,
    },
    MRTA_SIMPLY: {
      minimum: 3,
      maximum: 30,
    },
    MRTA_FULL: {
      minimum: 3,
      maximum: 30,
    },
    MRTA_TRUNCATE: {
      minimum: 10,
      maximum: 10,
    },
    GLTSP: {
      minimum: 1,
      maximum: 15,
    },
  },

  DEFAULT_MRTA_DESCRIPTION_PRODUCT_PLAN: {
    MRTA_FULL: 'คุ้มครองการเสียชีวิตและทุพพลภาพตลอดระยะเวลากู้',
    MRTA_PLUS: 'คุ้มครองการเสียชีวิตและทุพพลภาพตลอดระยะเวลากู้ พร้อมความคุ้มครองอุบัติเหตุ',
    MRTA_TRUNCATE: 'คุ้มครองการเสียชีวิตและทุพพลภาพ 10 ปี พร้อมความคุ้มครองอุบัติเหตุ',
  },

  DEFAULT_PAYMENT_SELECTED_MRTA: [
    { value: 'CASH_INCLUDE_LOAN', text: 'เงินสด รวมเบี้ยในวงเงินกู้' },
    { value: 'CASH_EXCLUDE_LOAN', text: 'เงินสด ไม่รวมเบี้ยในวงเงินกู้' },
  ],

  DEFAULT_RIDER: {
    SHOW_DESCRIPTION_ON_COVERAGE_PLAN_PAGE: true,
  },

  DEFAULT_IHEALTHY_ULTRA_DISPLAY_NAME: 'iHealthy Ultra',
  DEFAULT_IHEALTHY_ULTRA_PRODUCT_LIST: [
    {
      code: 'IHEALTHYULTRA-WLNP99L',
      productName: 'Life Legacy 99',
      entryAgeDisplay: {
        min: '6',
        max: '70',
      },
      entryAge: {
        minimum: { value: '6', unit: 'year' },
        maximum: { value: '70', unit: 'year' },
      },
      isShow: false,
    },
    {
      code: 'IHEALTHYULTRA-WLNP60L',
      productName: 'Life Legacy 60',
      entryAgeDisplay: {
        min: '6',
        max: '50',
      },
      entryAge: {
        minimum: { value: '6', unit: 'year' },
        maximum: { value: '50', unit: 'year' },
      },
      isShow: false,
    },
    {
      code: 'IHEALTHYULTRA-WLNP85',
      productName: 'iProtect 85',
      entryAgeDisplay: {
        min: '6',
        max: '70',
      },
      entryAge: {
        minimum: { value: '6', unit: 'year' },
        maximum: { value: '70', unit: 'year' },
      },
      isShow: true,
    },
    {
      code: 'IHEALTHYULTRA-WL88J',
      productName: 'Life Protect 18',
      entryAgeDisplay: {
        min: '6',
        max: '65',
      },
      entryAge: {
        minimum: { value: '6', unit: 'year' },
        maximum: { value: '55', unit: 'year' },
      },
      isShow: false,
    },
    {
      code: 'IHEALTHYULTRA-WL88S',
      productName: 'Life Protect 18',
      entryAgeDisplay: {
        min: '6',
        max: '65',
      },
      entryAge: {
        minimum: { value: '56', unit: 'year' },
        maximum: { value: '65', unit: 'year' },
      },
      isShow: false,
    },
    {
      code: 'IHEALTHYULTRA-W85P10J',
      productName: 'Life Ensure 10',
      entryAgeDisplay: {
        min: '6',
        max: '65',
      },
      entryAge: {
        minimum: { value: '6', unit: 'year' },
        maximum: { value: '55', unit: 'year' },
      },
      isShow: false,
    },
    {
      code: 'IHEALTHYULTRA-W85P10S',
      productName: 'Life Ensure 10',
      entryAgeDisplay: {
        min: '6',
        max: '65',
      },
      entryAge: {
        minimum: { value: '56', unit: 'year' },
        maximum: { value: '65', unit: 'year' },
      },
      isShow: false,
    },
    {
      code: 'IHEALTHYULTRA-25P15',
      productName: 'Life Plus Saver 25/15',
      entryAgeDisplay: {
        min: '6',
        max: '50',
      },
      entryAge: {
        minimum: { value: '6', unit: 'year' },
        maximum: { value: '40', unit: 'year' },
      },
      isShow: true,
    },
    {
      code: 'IHEALTHYULTRA-25P15S',
      productName: 'Life Plus Saver 25/15',
      entryAgeDisplay: {
        min: '6',
        max: '50',
      },
      entryAge: {
        minimum: { value: '41', unit: 'year' },
        maximum: { value: '50', unit: 'year' },
      },
      isShow: false,
    },
    {
      code: 'IHEALTHYULTRA-20P10',
      productName: 'Life Plus Saver 20/10',
      entryAgeDisplay: {
        min: '6',
        max: '55',
      },
      entryAge: {
        minimum: { value: '6', unit: 'year' },
        maximum: { value: '40', unit: 'year' },
      },
      isShow: true,
    },
    {
      code: 'IHEALTHYULTRA-20P10S',
      productName: 'Life Plus Saver 20/10',
      entryAgeDisplay: {
        min: '6',
        max: '55',
      },
      entryAge: {
        minimum: { value: '41', unit: 'year' },
        maximum: { value: '55', unit: 'year' },
      },
      isShow: false,
    },
    {
      code: 'IHEALTHYULTRA-12PL',
      productName: '12PL',
      entryAgeDisplay: {
        min: '6',
        max: '60',
      },
      entryAge: {
        minimum: { value: '6', unit: 'year' },
        maximum: { value: '60', unit: 'year' },
      },
      isShow: true,
    },
    {
      code: 'IHEALTHYULTRA-PWLNP85',
      productName: 'PWLNP85 (SA 50K)',
      entryAgeDisplay: {
        min: '6',
        max: '80',
      },
      entryAge: {
        minimum: { value: '6', unit: 'year' },
        maximum: { value: '80', unit: 'year' },
      },
      isShow: false,
    },
    {
      code: 'IHEALTHYULTRA-PWLNP60',
      productName: 'PWLNP60 (SA 50K)',
      entryAgeDisplay: {
        min: '6',
        max: '55',
      },
      entryAge: {
        minimum: { value: '6', unit: 'year' },
        maximum: { value: '55', unit: 'year' },
      },
      isShow: false,
    },
  ],
  DEFAULT_MHP_OPTIONAL: 'full_coverage',
  DEFAULT_MHP_PLANCODE: {
    SMART: {
      JUNIOR: 'MHP1J',
      JUNIOR_COPAY: 'MHPC1J',
      JUNIOR_DEDUCT: 'MHPD1J',
      SENIOR: 'MHP1S',
    },
    BRONZE: {
      JUNIOR: 'MHP2J',
      SENIOR: 'MHP2S',
    },
    SILVER: 'MHP3S',
    GOLD: 'MHP4S',
    DIAMOND: {
      THAILAND: 'MHP5S',
      ASIA: 'MHP5SA',
      WORLDWIDE: 'MHP5SW',
    },
    PLATINUM: {
      THAILAND: 'MHP6S',
      ASIA: 'MHP6SA',
      WORLDWIDE: 'MHP6SW',
    },
  },
  DEFAULT_MHP_PLANNAME: {
    SMART: 'Smart',
    BRONZE: 'Bronze',
    SILVER: 'Silver',
    GOLD: 'Gold',
    DIAMOND: 'Diamond',
    PLATINUM: 'Platinum',
  },
  DEFAULT_MHP_AREA_COVER: {
    THAILAND: 'Thailand',
    ASIA: 'Asia',
    WORLDWIDE: 'Worldwide',
  },

  DEFAULT_AGENT_TYPE: 'AGENT',

  GENDER: {
    M: 'Male',
    F: 'Female',
  },
}
