// @flow

import type { DisplayProduct } from 'core/service/display-product'
import type { PolicyValue } from 'core/service/benefit-illustration'
import ProductBenefitsSummary from 'quick-quote/product-common/benefit-illustration/components/product-benefits/product-benefits-summary'
import ProductBenefitsContent from './product-benefits-content'

export type ProductBenefitProps = {
  displayProduct: DisplayProduct,
  sumAssured: number,
  basicPremium: number,
  calculatedPaymentPeriod: number,
  calculatedCoveragePeriod: number,
  policyValue: PolicyValue,
  benefitSummaryHeader: Function | string,
}

const ProductBenefits = ({
  displayProduct,
  sumAssured,
  basicPremium,
  calculatedPaymentPeriod,
  calculatedCoveragePeriod,
  policyValue,
  benefitSummaryHeader,
}: ProductBenefitProps) => {
  return (
    <div id="product-benefits" className="benefit group">
      <ProductBenefitsSummary
        displayProduct={displayProduct}
        sumAssured={sumAssured}
        basicPremium={basicPremium}
        calculatedPaymentPeriod={calculatedPaymentPeriod}
        calculatedCoveragePeriod={calculatedCoveragePeriod}
        policyValue={policyValue}
        benefitSummaryHeader={benefitSummaryHeader}
      />

      <ProductBenefitsContent />
    </div>
  )
}

export default ProductBenefits
