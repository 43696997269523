import { isNil } from 'lodash/fp'
import { connect } from 'react-redux'

import Content from 'e-submission/components/Layout/Content'
import FormSection from 'e-submission/components/Layout/FormSection'
import FormContent from 'e-submission/components/Layout/FormContent'
import FormManager from './FormManager'

import MainHeading from 'e-submission/components/Heading'
import SubHeading from 'e-submission/components/Heading/SubHeading'
import { HeadingWithIcon } from 'e-submission/components/Form/Heading/HeadingWithIcon'

import { setAppValue, unsetAppValue, removeAppMember } from 'e-submission/apps/actions'

const FormContainer = Content.extend`
  ${({ theme }) => theme.media.sm`
padding: 0 50px;
`};
  .formEngineUl {
    padding-left: 20px;
  }
`

const mapDispatchToProps = (dispatch) => ({
  setValue: (fieldId, newValue) => {
    dispatch(setAppValue(fieldId, newValue))
  },
  unsetValue: (fieldId) => {
    dispatch(unsetAppValue(fieldId))
  },
  removeValue: (fieldId) => {
    dispatch(removeAppMember(fieldId))
  },
})

export default connect(
  null,
  mapDispatchToProps
)(({ sections, children, size, ...props }) => (
  <FormContainer className="FormEngine" size={size}>
    {children}
    {sections &&
      sections.map(({ id, label, questions, styleOptions, heading, className, icon }, index) => (
        <FormSection key={`${id}[${index}]`} {...styleOptions} className={`${['form-section', className].join(' ')}`}>
          <FormContent key={index}>
            {!isNil(heading) && icon ? (
              <HeadingWithIcon icon={icon} title={heading} />
            ) : (
              <MainHeading>{heading}</MainHeading>
            )}
            {!isNil(label) && questions.length > 0 && <SubHeading>{label}</SubHeading>}

            {questions &&
              questions.map((question, index) => <FormManager key={question.id} {...question} {...props} />)}
          </FormContent>
        </FormSection>
      ))}
  </FormContainer>
))
