import { omit, toFinite, toString, isNil } from 'lodash/fp'
import { withState, compose, defaultProps, withHandlers, mapProps, withProps, lifecycle } from 'recompose'
import { Flex } from 'rebass'
import { Input } from 'reactstrap'
import NumberInput from 'react-number-format'

export const onKeyDown = ({ setDirty }) => (event) => {
  const nextValue = event.target.value.substring(1)
  const isBackSpace = event.keyCode === 8
  const isDeleteFirstDigit = event.target.selectionStart === 1
  const isNotEmpty = !isNil(event.target.value) && nextValue !== ''
  const isDeletingFrontOfComma = !/[1-9.]/.test(event.target.value.substring(1, 2))
  const isNextValueIsNotZero = !(nextValue.startsWith('0.') || nextValue === '0')

  if (isBackSpace && isDeleteFirstDigit && isNotEmpty && isDeletingFrontOfComma && isNextValueIsNotZero) {
    event.preventDefault()
    setDirty(true)
  }
}

export const composingHoc = compose(
  defaultProps({
    type: 'tel',
    value: '',
    allowNegative: false,
    customInput: Input,
    pattern: '[0-9]*',
    format: (value) => value,
  }),
  withProps(({ readOnly, setValue, value, displayType, allow, thousandSeparator, format }) => ({
    value: displayType === 'text' ? toFinite(value) || '-' : value,
    format: thousandSeparator ? undefined : format,
    isAllowed: ({ value }) => (allow ? allow(value) : true),
    style: {
      pointerEvents: readOnly ? 'none' : undefined,
    },
  })),
  withState('isDirty', 'setDirty', false),
  withHandlers({
    onFocus: ({ validate }) => () => {
      if (validate) {
        validate(false)
      }
    },
    onBlur: ({ isDirty, value, setDirty, validate }) => (event) => {
      if (validate) {
        validate(true)
      }
      if (isDirty) {
        event.target.value = toString(value).substring(0, 1) + event.target.value
        setDirty(false)
      }
    },
    onKeyDown,
    onChange: ({ setDirty, setValue, isNumber = false }) => (event, { value }) => {
      setDirty(false)
      if (isNumber) {
        setValue(toFinite(value))
      } else {
        setValue(value)
      }
    },
  }),
  lifecycle({
    componentDidUpdate(prevProps) {
      const input = document.activeElement
      if (input && !isNil(input.value) && this.props.isDirty) {
        input.value = input.value.substring(1)

        if (input.createTextRange) {
          const range = input.createTextRange()
          range.move('character', 0)
          range.select()
        } else if (input.setSelectionRange) {
          input.setSelectionRange(0, 0)
        }
      }
    },
  }),
  mapProps(omit(['isDirty', 'setDirty', 'isNumber'])),
  (Component) => ({ children, footer, ...props }) => {
    if (children || footer) {
      return (
        <Flex column>
          <Flex flex="1 0 100%" justify="space-between">
            {!!children && children}
            <Component {...props} />
          </Flex>
          {!!footer && footer}
        </Flex>
      )
    }

    return <Component {...props} />
  }
)
export const Component = NumberInput
export default (hoc) => compose(composingHoc, hoc)(Component)
