// @flow
import type { Rider, RiderState } from 'core/data-model/rider'
import ArrowRight from '@axah/web-toolkit/icons/arrow-right.svg'
import BIMESSAGES from 'core/data-model/constants/bi-messages'

type RiderBenefitSummaryProps = {
  rider: Rider & RiderState,
  title?: string,
}

const RiderBenefitSummary = ({ rider, title }: RiderBenefitSummaryProps) => {
  return (
    <div className="product" key={`${rider.name.toLowerCase()}-rider-benefit-summary`}>
      <h6 className="name-link">
        <span className="text">{BIMESSAGES.BENEFIT_HEADER_IGROW}</span>
        <span className="icon d-md-none">
          <ArrowRight />
        </span>
      </h6>
    </div>
  )
}

export default RiderBenefitSummary
