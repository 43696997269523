// @flow
import * as React from 'react'
import _ from 'lodash'
import { Modal } from 'common-components'
import { withModalToggle } from 'lib/with-hoc'
import { Typeahead } from 'react-bootstrap-typeahead'
import { FormGroup, ModalHeader, ModalBody, ListGroup, ListGroupItem, Button, FormFeedback } from 'reactstrap'
import type { Occupation, NatureOfDuty } from 'core/data-model/insured'
import MESSAGES from 'core/data-model/constants/messages'

type Props = {
  selectedOccupation: ?Occupation,
  occupations: Occupation[],
  errors: string[],
  natureOfDuties: NatureOfDuty[],
  selectedNatureOfDutyCode: string,
  selectOccupation: (string) => void,
  selectNatureOfDuty: (NatureOfDuty) => void,
  isModalOpen: boolean,
  toggleModal: (?Object) => void,
  defaultOccupationCode: string,
}

export const OccupationSelection = ({
  selectedOccupation,
  occupations,
  natureOfDuties,
  errors,
  selectedNatureOfDutyCode,
  selectOccupation,
  selectNatureOfDuty,
  isModalOpen,
  toggleModal,
  defaultOccupationCode,
}: Props) => {
  const concatedOccupation = !_.isUndefined(selectedOccupation)
    ? `${_.get(selectedOccupation, 'th.occupationName', '-')}, ${_.get(selectedOccupation, 'th.natureOfDuty', '-')}`
    : 'กรุณาระบุอาชีพ'
  return (
    <FormGroup className={`occupation-selection ${errors.length > 0 ? 'has-danger' : ''}`}>
      <label>{MESSAGES.OCCUPATION}</label>
      <div
        className="occupation-detail form-control"
        onClick={toggleModal}
        onKeyDown={(e) => {
          if (e.keyCode === 32) {
            toggleModal(e)
          }
        }}
        tabIndex="0"
      >
        {concatedOccupation}
      </div>
      <FormFeedback className="validation-message">{errors.join(' ')}</FormFeedback>
      <Modal
        isOpen={isModalOpen}
        toggle={toggleModal}
        backdrop={false}
        autoFocus={false}
        modalClassName="ltr-modal occupation-modal scrollable-modal"
      >
        <ModalHeader tag="div">
          <span className="text">{MESSAGES.CHOOSE_OCCUPATION}</span>
          <Button id="btn-choose-occupation" className="done" onClick={toggleModal}>
            {MESSAGES.FINISHED}
          </Button>
        </ModalHeader>
        <ModalBody>
          <Typeahead
            labelKey={(option: Occupation) => {
              return option.th.occupationName
            }}
            filterBy={(option: Occupation, text: string) => _.includes(option.th.occupationName, text)}
            className="occupation-typeahead form-group"
            options={occupations}
            minLength={3}
            onChange={([option = { occupationCode: '' }]) => {
              if (!_.isEmpty(option.occupationCode)) {
                selectOccupation(option.occupationCode)
              }
            }}
            selected={[selectedOccupation]}
            onFocus={(e) => {
              e.persist()
              const temp_value = e.target.value
              e.target.value = ''
              e.target.value = temp_value
              setTimeout(() => {
                document.getElementsByClassName('occupation-typeahead')[0].style.width = '100%'
              }, 600)
            }}
            autoFocus={true}
            emptyLabel={MESSAGES.DATA_NOT_FOUND}
            placeholder="กรุณาระบุอาชีพ"
          />
          {natureOfDuties.length > 0 && (
            <NatureOfDutiesSelection
              selectNatureOfDuty={selectNatureOfDuty}
              selectedOccupation={selectedOccupation}
              selectedNatureOfDutyCode={selectedNatureOfDutyCode}
              selectOccupation={selectOccupation}
              defaultOccupationCode={defaultOccupationCode}
              natureOfDuties={natureOfDuties}
            />
          )}
        </ModalBody>
      </Modal>
    </FormGroup>
  )
}

type NatureOfDutiesSelectionProps = {
  selectNatureOfDuty: (NatureOfDuty) => void,
  selectOccupation: (string) => void,
  selectedNatureOfDutyCode: string,
  natureOfDuties: NatureOfDuty[],
  defaultOccupationCode: string,
  selectedOccupation: ?Occupation,
}

export const NatureOfDutiesSelection = ({
  selectNatureOfDuty,
  selectOccupation,
  selectedNatureOfDutyCode,
  natureOfDuties,
  defaultOccupationCode,
  selectedOccupation,
}: NatureOfDutiesSelectionProps) => (
  <FormGroup>
    <label>{MESSAGES.NATURE_OF_DUTY}</label>
    <ListGroup id="nature-of-duty-list" className="nature-of-duty-list">
      {natureOfDuties.map((natureOfDuty, index) => (
        <ListGroupItem
          tabIndex="0"
          key={index}
          active={selectedNatureOfDutyCode === natureOfDuty.code}
          onClick={() => {
            if (_.isUndefined(selectedOccupation)) {
              selectOccupation(defaultOccupationCode)
            }
            selectNatureOfDuty(natureOfDuty)
          }}
          onKeyDown={(e) => {
            if (e.keyCode === 32) {
              e.preventDefault()
              selectNatureOfDuty(natureOfDuty)
            }
          }}
        >
          {natureOfDuty.text}
        </ListGroupItem>
      ))}
    </ListGroup>
  </FormGroup>
)

export default withModalToggle(OccupationSelection)
