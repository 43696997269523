//@flow
import ReactGA from 'react-ga4'
import config from 'config'
import { getToggles } from 'quick-quote/feature-toggles'

const analyticsFn = () => {
  const initialiseGA = (trackingID: string, options: Object = {}) => {
    ReactGA.initialize(trackingID, options)
    ReactGA.set({
      appName: config.appName,
      appVersion: config.appVersion,
    })
  }

  const logPageView = (location: string) => {
    if (getToggles().ENABLE_REMOVE_POLICY_NO_GA4 && !location.includes('middleware')) {
      ReactGA.set({ page: location })
      ReactGA.send({ hitType: 'pageview', page: location })
    }
  }

  const sendCustomEvent = (args: Object) => {
    ReactGA.event(args)
  }

  return {
    initialiseGA,
    logPageView,
    sendCustomEvent,
  }
}

export const analytics = analyticsFn()
