// @flow

import { isBuddhistYear, christianToThaiWithoutFormat, dateFormat, dateToMoment } from 'core/service/lib/date'

const formatPassword = (birthdate: string): string => birthdate.replace(/\//g, '')

export const generatePassword = (birthdate: string): string => {
  let date = birthdate
  if (!isBuddhistYear(birthdate)) {
    date = christianToThaiWithoutFormat(dateToMoment(birthdate).format('MM/DD/YYYY')).format(dateFormat)
  }

  return formatPassword(date)
}
