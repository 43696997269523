// @flow
import { createSelector } from 'reselect'
import { getPolicyValues } from 'quick-quote/product-whole-life/benefit-illustration/selectors'
import { getSelectedDisplayProduct } from 'quick-quote/product-selection/selectors'
import { getPensionType } from 'quick-quote/product-common/coverage-plan/selectors'

import type { PolicyValueTableProps } from 'core/service/benefit-illustration/policy-value'

export type PolicyRetirementValueTableProps = PolicyValueTableProps & {
  pensionType: string,
}

export const getPolicyValueTableBenefits = createSelector(
  getSelectedDisplayProduct,
  getPolicyValues,
  getPensionType,
  (displayProduct, policyValue, pensionType): PolicyRetirementValueTableProps => ({
    basicPlan: displayProduct,
    policyValue,
    pensionType,
  })
)
