import { useEffect } from 'react'
import _ from 'lodash'

const useUserAgent = () => {
  // get userInfo from local storage
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))

  useEffect(() => {
    if (!userInfo) {
      window.location.href = '/'
    }
  }, [userInfo])

  return {
    access_token: _.get(userInfo, ['access_token'], null),
    profile: {
      agent_code: _.get(userInfo, ['agent_code'], null),
    },
  }
}

export default useUserAgent
