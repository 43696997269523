import { compact } from 'lodash/fp'

import { createSectionCreator, createField } from '../utils'
import { checker } from '../selectors'

const reasonInputField = (id) =>
  createField(`${id}.detail`, [
    {
      label: 'โปรดระบุรายละเอียด',
      id: `${id}.detail`,
      c: 'TextInput',
      required: true,
      p: {},
    },
  ])

const getQuestions = (app, id) => {
  const { isYes } = checker(app)

  return compact([
    createField(`${id}.wasRejected.checked`, [
      {
        id: `${id}.wasRejected.checked`,
        c: 'RadioButton',
        required: true,
        label:
          'ท่านเคยถูกปฏิเสธ หรือเลื่อนการพิจารณา หรือเรียกเบี้ยประกันภัยเพิ่ม หรือเปลี่ยนแปลงเงื่อนไขการรับประกันภัยหรือไม่',
        p: {
          options: [
            {
              text: 'ไม่เคย',
              value: 'no',
            },
            {
              text: 'เคย',
              value: 'yes',
            },
          ],
        },
      },
    ]),
    isYes(`${id}.wasRejected.checked`) && reasonInputField(`${id}.wasRejected`),
  ])
}

export default createSectionCreator(getQuestions)
