// @flow
import type { Rider, RiderPlan, RiderState } from 'core/data-model/rider'
import { Dropdown } from 'common-components'
import DEFAULTS from 'core/data-model/constants/defaults'
import _ from 'lodash'
import MESSAGES from 'core/data-model/constants/bi-messages'
import VALUES from 'core/data-model/constants/values'

type RiderPlanNameProps = {
  rider: Rider & RiderState,
  editRiderSelectedPlan: (string, RiderPlan) => void,
}

export const getRiderTitle = (rider: Rider & RiderState) => {
  const showRiderDescriptionOnCoveragePlanPage = _.get(
    rider,
    'riderSettings.showRiderDescriptionOnCoveragePlanPage',
    DEFAULTS.DEFAULT_RIDER.SHOW_DESCRIPTION_ON_COVERAGE_PLAN_PAGE
  )
  switch (rider.code) {
    case VALUES.WPTPDCI:
      return `${rider.name} (${MESSAGES.WPTPDCI_QQ_NAME})`
    case VALUES.WPTPD:
      return `${rider.name} (${MESSAGES.WPTPD_QQ_NAME})`
    case VALUES.MRTAAP:
      return `${rider.description}`
    default:
      return showRiderDescriptionOnCoveragePlanPage ? `${rider.name} (${rider.description})` : rider.name
  }
}

const RiderPlanName = ({ rider, editRiderSelectedPlan }: RiderPlanNameProps) => {
  if (rider.package && rider.packageRiders && rider.packageRiders.length > 0) {
    const packageRiders = _.map(rider.packageRiders, (packageRider) => ({
      value: packageRider,
      text: packageRider,
    }))
    const isDisabled = !rider.isSelectable || !rider.isSelected || rider.isReadOnly
    const selectedPackageRider =
      !isDisabled && rider.selectedPlan.planCode ? rider.selectedPlan.planCode : packageRiders[0].value
    return (
      <Dropdown
        id={`rider-${rider.code}-package-riders`}
        options={packageRiders}
        value={selectedPackageRider}
        disabled={isDisabled}
        onChange={(value) => {
          editRiderSelectedPlan(rider.code, { planCode: value })
        }}
      />
    )
  } else {
    const riderTitle = getRiderTitle(rider)
    return <span className="custom-control-description">{riderTitle}</span>
  }
}

export default RiderPlanName
