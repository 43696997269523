//@flow
import type {
  FundAllocationToggleItem,
  RppAcceptFundAllocation,
  RppForeignConsent,
  RppOverRiskConsent,
  TotalFundAllocation,
} from 'core/data-model/investment'
import {
  containForeignFund,
  containOverRiskFund,
  isAcceptForeignInvestment,
  fundsContainErrors,
} from 'core/service/investment/funds'
import _ from 'lodash'
import { isSelectedYesOnForeignExchangeQuestion } from 'quick-quote/product-investment/risk-questionnaire/selectors'
import type { AppState } from 'quick-quote/reducers'
import { createSelector } from 'reselect'

export const getRppFundAllocations = (state: AppState): FundAllocationToggleItem[] => {
  const { investment } = state
  return investment.funds.rpp.allocations
}

export const getRppTotalAllocations = (state: AppState): TotalFundAllocation =>
  state.investment.funds.rpp.totalAllocation

export const getRppAcceptFundAllocation = (state: AppState): RppAcceptFundAllocation =>
  _.get(state, 'investment.funds.rpp.acceptFundAllocation', false)

export const hasFinishedRppFundAllocation = createSelector(
  getRppFundAllocations,
  getRppTotalAllocations,
  (rppAllocation, rppTotalAllocation): boolean =>
    rppAllocation.length > 0 && rppTotalAllocation.value === 100 && !fundsContainErrors(rppAllocation)
)

export const getRppOverRiskConsent = (state: AppState): RppOverRiskConsent =>
  _.get(state, 'investment.funds.rpp.allowHigherRiskInvestment', false)

export const getRppForeignConsent = (state: AppState): RppForeignConsent =>
  _.get(state, 'investment.funds.rpp.allowForeignInvestment', false)

export const hasOverRiskFundAllocation = createSelector(getRppFundAllocations, containOverRiskFund)

export const hasForeignFundAllocation = createSelector(getRppFundAllocations, containForeignFund)

export const rppAcceptForeignInvestment = createSelector(
  getRppForeignConsent,
  isSelectedYesOnForeignExchangeQuestion,
  isAcceptForeignInvestment
)

export const isRppOverRiskConsentNeeded = hasOverRiskFundAllocation

export const _isRppForeignConsentNeeded = (isSelectedYesOnForeignExchangeQuestion, hasForeignFundAllocation) => {
  return !isSelectedYesOnForeignExchangeQuestion && hasForeignFundAllocation
}

export const isRppForeignConsentNeeded = createSelector(
  isSelectedYesOnForeignExchangeQuestion,
  hasForeignFundAllocation,
  _isRppForeignConsentNeeded
)

export const hasFinishedRppFundSummary = createSelector(
  hasOverRiskFundAllocation,
  hasForeignFundAllocation,
  getRppOverRiskConsent,
  rppAcceptForeignInvestment,
  (isOverRiskFund, isForeignFund, allowHigherRiskInvestment, rppAcceptForeignInvestment): boolean => {
    if (isOverRiskFund && isForeignFund) {
      return allowHigherRiskInvestment === true && rppAcceptForeignInvestment
    } else if (isOverRiskFund) {
      return allowHigherRiskInvestment === true
    } else if (isForeignFund) {
      return rppAcceptForeignInvestment
    } else {
      return true
    }
  }
)
