import Date from '../../../../common-components/date-dropdown'
import { compose, defaultProps, withHandlers, withPropsOnChange } from 'recompose'
import { dateFormatterPattern } from 'core/service/lib/date'

export const Component = Date

export default (hoc) =>
  compose(
    defaultProps({
      className: 'form-control',
      type: 'tel',
      pattern: dateFormatterPattern,
    }),
    withPropsOnChange(
      (current, next) => current.id !== next.id || current.value !== next.value,
      ({ value, addYear }) => ({
        value: value,
        addYear: addYear,
      })
    ),
    withHandlers({
      onBlur: ({ validate }) => () => validate && validate(true),
      onFocus: ({ validate }) => () => validate && validate(false),
      onChange: ({ setValue }) => (event) => {
        setValue(event.target.value)
      },
    }),
    hoc
  )(Component)
