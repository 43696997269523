import { compact, flow, get, isEqual } from 'lodash/fp'
import { createSectionCreator, createField } from './utils'
import { havePayer } from './selectors'

const taxCertConsentFields = (app, id) => {
  let isShowConsentInfo = false
  if (app !== undefined) {
    if (havePayer(app)) {
      isShowConsentInfo = flow(get('payer.taxCertConsent.value'), isEqual(true))(app)
    } else {
      isShowConsentInfo = flow(get('insured.taxCertConsent.value'), isEqual(true))(app)
    }
  }
  let consentInfo = isShowConsentInfo
    ? 'และยินยอมให้บริษัทประกันชีวิตส่งและเปิดเผยข้อมูลเกี่ยวกับเบี้ยประกันภัยต่อกรมสรรพากร ตามหลักเกณฑ์ วิธีการที่กรมสรรพากรกำหนด'
    : ''

  return compact([
    createField(`${id}.taxCertConsent`, [
      {
        id: `${id}.taxCertConsent`,
        label: 'ผู้ขอเอาประกันประสงค์จะใช้สิทธิขอยกเว้นภาษีเงินได้ตามกฏหมายว่าด้วยภาษีอากรหรือไม่',
        c: 'RadioButton',
        required: true,
        p: {
          options: [
            {
              text: 'มีความประสงค์',
              value: true,
            },
            {
              text: 'ไม่มีความประสงค์',
              value: false,
            },
          ],
        },
        infoMessage: consentInfo,
      },
    ]),
  ])
}

const taxCertConsentForeignerFields = (app, id) => {
  let isShowConsentInfo = false
  if (app !== undefined) {
    if (havePayer(app) && app.payer !== undefined) {
      isShowConsentInfo = app.payer.taxCertConsent !== undefined ? app.payer.taxCertConsent.value : false
    } else if (app.insured !== undefined) {
      isShowConsentInfo = app.insured.taxCertConsent !== undefined ? app.insured.taxCertConsent.value : false
    }
  }
  let consentInfo = isShowConsentInfo
    ? 'และยินยอมให้บริษัทประกันชีวิตส่งและเปิดเผยข้อมูลเกี่ยวกับเบี้ยประกันภัยต่อกรมสรรพากร ตามหลักเกณฑ์ วิธีการที่กรมสรรพากรกำหนด และหากผู้ขอเอาประกันภัยเป็นชาวต่างชาติ (Non-Thai Residence) ซึ่งเป็นผู้มีหน้าที่ต้องเสียภาษีเงินได้ตามกฎหมายว่าด้วยภาษีอากร โปรดระบุเลขประจำตัวผู้เสียภาษีที่ได้รับจากกรมสรรพากร'
    : ''

  if (isShowConsentInfo)
    return compact([
      createField(`${id}.taxCertConsent`, [
        {
          id: `${id}.taxCertConsent`,
          label: 'ผู้ขอเอาประกันประสงค์จะใช้สิทธิขอยกเว้นภาษีเงินได้ตามกฏหมายว่าด้วยภาษีอากรหรือไม่',
          c: 'RadioButton',
          required: true,
          p: {
            options: [
              {
                text: 'มีความประสงค์',
                value: true,
              },
              {
                text: 'ไม่มีความประสงค์',
                value: false,
              },
            ],
          },
          infoMessage: consentInfo,
        },
      ]),
      createField(`${id}.idNo.taxId`, [
        {
          label: 'โปรดระบุเลขประจำตัวผู้เสียภาษีที่ได้รับจากกรมสรรพากร เลขที่',
          id: `${id}.idNo.taxId`,
          c: 'NumberInput',
          required: false,
          p: {},
        },
      ]),
    ])

  return compact([
    createField(`${id}.taxCertConsent`, [
      {
        id: `${id}.taxCertConsent`,
        label: 'ผู้ขอเอาประกันประสงค์จะใช้สิทธิขอยกเว้นภาษีเงินได้ตามกฏหมายว่าด้วยภาษีอากรหรือไม่',
        c: 'RadioButton',
        required: true,
        p: {
          options: [
            {
              text: 'มีความประสงค์',
              value: true,
            },
            {
              text: 'ไม่มีความประสงค์',
              value: false,
            },
          ],
        },
        infoMessage: consentInfo,
      },
    ]),
  ])
}

export const taxCertConsentSection = createSectionCreator(taxCertConsentFields)
export const taxCertConsentForeignerSection = createSectionCreator(taxCertConsentForeignerFields)
