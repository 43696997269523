import { IsRealDevice } from 'e-submission/utils'
export const makeNameGABrochureByName = (productName = '') => {
  let replaceProductName = productName.replace(/ /g, '')
  let sentNewName = ''
  if (IsRealDevice) {
    sentNewName = 'MOBILE_BROC_'
  } else {
    sentNewName = 'WEB_BROC_'
  }
  return sentNewName.concat('', replaceProductName)
}
