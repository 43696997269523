import { compose, withHandlers } from 'recompose'
import { get } from 'lodash/fp'
import { ListGroup as _ListGroup } from 'common-components'
import radioOff from 'assets/images/form/radio-off.png'
import radioOn from 'assets/images/form/radio-on.png'

const ListGroup = _ListGroup.extend`
  .list-group-item {
    background-image: url(${radioOff});
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: top 10px left 14px;
    padding-left: 45px;
  &.active {
    background-image: url(${radioOn});
  }
  span {
    width: 100%;
  }
}
`

const Component = ({ options, onChange, value }) => (
  <ListGroup
    options={options}
    onChange={onChange}
    value={value}
    isActive={(option) => get('value')(option) === get('value')(value)}
    render={({ children }) => <span>{children}</span>}
  />
)

export default (hoc) =>
  compose(
    withHandlers({
      onChange: ({ setValue }) => (option) => {
        setValue(option)
      },
    }),
    hoc
  )(Component)
