const playStoreAzURL = 'https://play.google.com/store/apps/details?id=com.ktaxa.advisorzone&hl=th'
const appStoreAzURL = 'https://apps.apple.com/th/app/advisorzone/id1287893930?l=th'

export const getURLStore = () => {
  const android = window.navigator.userAgent.match(/Android/i)
  if (android) {
    return playStoreAzURL
  }
  return appStoreAzURL
}

export const getDevice = () => {
  const android = window.navigator.userAgent.match(/Android/i)
  const ios = navigator.userAgent.match(/iPhone|iPad|iPod/i)

  if (android) {
    return 'android'
  }

  if (ios) {
    return 'ios'
  }

  return 'other'
}
