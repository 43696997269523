import _ from 'lodash'
import { createField, createSectionCreator } from './utils'

import { getRelationshipListForFamily, getDefaultPersonalTitleList } from 'e-submission/apps/selectors'

const dataFields = (app, id) => {
  const title = _.get(app, `${id}.title`)
  const isOtherTitle = _.get(title, 'value') === 'other'
  const isShowRemoveButton = !id.includes('[0]')

  return _.compact([
    createField(`${id}.title`, [
      {
        label: 'คำนำหน้า',
        id: `${id}.title`,
        c: 'Dropdown',
        required: true,
        p: {
          placeHolder: 'กรุณาเลือกคำนำหน้าชื่อ',
          mergeState: (state) => ({
            options: getDefaultPersonalTitleList(state),
          }),
        },
      },
    ]),
    isOtherTitle &&
      createField(`${id}.title.text`, [
        {
          label: 'ระบุคำนำหน้า',
          id: `${id}.title.text`,
          c: 'TextInput',
          required: true,
        },
      ]),
    createField(`${id}.firstName`, [
      {
        label: 'ชื่อ',
        id: `${id}.firstName`,
        required: true,
        c: 'TextInput',
      },
    ]),
    createField(`${id}.lastName`, [
      {
        label: 'นามสกุล',
        id: `${id}.lastName`,
        required: true,
        c: 'TextInput',
      },
    ]),
    createField(`${id}.relations`, [
      {
        label: 'ความสัมพันธ์',
        id: `${id}.relations`,
        c: 'Dropdown',
        required: true,
        p: {
          mergeState: (state) => ({
            options: getRelationshipListForFamily(state),
          }),
          placeHolder: 'กรุณาเลือกความสัมพันธ์',
        },
      },
    ]),
    createField(`${id}.idNo.citizenId`, [
      {
        label: 'เลขประจำตัวประชาชน',
        id: `${id}.idNo.citizenId`,
        c: 'NumberInput',
        required: true,
        p: {},
      },
    ]),
    createField(`${id}.policyNumber`, [
      {
        label: 'เลขที่กรมธรรม์ (ถ้ามี)',
        id: `${id}.policyNumber`,
        c: 'TextInput',
        p: {},
      },
    ]),
    isShowRemoveButton &&
      createField(
        `${id}.remove`,
        [
          {
            id: `${id}.remove`,
            c: 'Button',
            p: {
              icon: 'remove',
              children: 'ลบ',
              mergeProps: ({ removeValue }) => ({
                onClick: () => removeValue(id),
              }),
              style: {
                fontSize: '0.8725rem',
                padding: '0.125rem 0.5rem',
              },
            },
          },
        ],
        {
          style: {
            position: 'absolute',
            top: '0',
            right: '0',
          },
        }
      ),
  ])
}

const addNewFamilyMember = (app, id, section) => [
  createField(`${id}.addNew`, [
    {
      id,
      c: 'Button',
      p: {
        icon: 'add',
        children: 'เพิ่มสมาชิกครอบครัว',
        block: true,
        mergeProps: ({ setValue }) => ({
          onClick: () => {
            const appFamilyMember = _.get(app, section.id)
            if (_.isEmpty(appFamilyMember)) {
              setValue(null)
            }
            setValue(null)
          },
        }),
      },
    },
  ]),
]

export default (sectionId, sectionLabel) => (app, id) => {
  const appFamilyMember = _.get(app, id, [null])

  return _.compact(
    _.reduce(
      appFamilyMember,
      (result, el, i) => {
        return [
          ...result,
          createSectionCreator(dataFields)(`${sectionId}[${i}]`, null, null, `${sectionLabel} ${i + 1}`)(
            app,
            `${id}[${i}]`
          ),
        ]
      },
      []
    ).concat(
      appFamilyMember.length < 7 &&
        createSectionCreator(addNewFamilyMember)(
          sectionId,
          null,
          null,
          null,
          'form-section__family-discount__btn-add-new'
        )(app, `${id}[${appFamilyMember.length}]`)
    )
  )
}
