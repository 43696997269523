// @flow
import type { RegularPremium, RegularTopUp } from 'core/service/investment'
import { renderErrorMessages } from 'common-components/view-logic'
import _ from 'lodash'

type BasicPlanErrorMessagesProps = {
  premiumValue: RegularPremium | RegularTopUp,
}

const BasicPlanErrorMessages = ({ premiumValue }: BasicPlanErrorMessagesProps) => {
  let messageType = _.isEmpty(premiumValue.errors) ? null : premiumValue.errors[0].type
  return <div>{renderErrorMessages('premium-error-message')(premiumValue.errors, messageType)}</div>
}

export default BasicPlanErrorMessages
