// @flow

import MESSAGES from 'core/data-model/constants/bi-messages'
import type { Rider, RiderState } from 'core/data-model/rider'
import Mustache from 'mustache'
import { getToggles } from 'quick-quote/feature-toggles'
import VALUES from 'core/data-model/constants/values'
type Props = {
  rider: Rider & RiderState,
  overrideTitle?: string,
}
export const getAdditionalDisclaimerMessage = (code, isUpdatedDisclaimer) => {
  switch (code) {
    case VALUES.MHP:
      return isUpdatedDisclaimer ? MESSAGES.DISCLAIMER_ADDITIONAL_MHP_RIDER : MESSAGES.DISCLAIMER_ADDITIONAL_RIDER
    default:
      return MESSAGES.DISCLAIMER_ADDITIONAL_RIDER
  }
}

export const AdditionalDisclaimer = ({ rider, overrideTitle }: Props) => {
  const title = overrideTitle ? overrideTitle : `${rider.description} (${rider.name})`
  const coveragePeriod = rider.coveragePeriod.value - 1

  const isUpdatedDisclaimer = rider.code === 'MHP' && getToggles().ENABLE_OPTIONAL_IHEALTHY_ULTRA
  return getToggles().ADDITAIONAL_RIDER_DISCLAIMER ? (
    <div className="disclaimer-box">
      <p>
        {Mustache.render(getAdditionalDisclaimerMessage(rider.code, isUpdatedDisclaimer), {
          title,
          coveragePeriod,
        })}
      </p>
    </div>
  ) : (
    <div></div>
  )
}
