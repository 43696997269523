// @flow

export const VALIDATION = 'VALIDATION'

type ErrorResponse = {
  type: string,
  messages: string[],
}

export const getValidationErrorMessage = (error: ErrorResponse) => {
  if (!error || error.type !== VALIDATION) return ''
  return error.messages.join(' ')
}
