// @flow
import _ from 'lodash'
import type {
  COIRates,
  COIRateTable,
  CORRateTables,
  FeeStructure,
  InvestmentProductConfig,
  LoyaltyBonusRate,
  PolicyPeriod,
  Premium,
} from 'core/data-model/investment/types'
import type Decimal from 'decimal.js'
import type { Gender } from 'core/data-model/insured'
import type { Rider, RiderState } from 'core/data-model/rider'
import { d } from 'core/service/lib/decimal'
import { totalPremium } from './premium'
import {
  accountValueAfterBonus,
  accountValueAfterDeduction,
  accountValueBeforeBonus,
  accountValueBeforeDeductionIinvest,
  accountValueBeforeDeductionIwealthy,
  adminChargeIinvest,
  adminChargeIwealthy,
  chargeOfInsurance,
  deathBenefit,
  fundReturn,
  loyaltyBonus,
  sumAtRiskIinvest,
  sumAtRiskIwealthy,
  surrenderCashIInvest,
  surrenderCashIWealthy,
} from './account-value'

import { processRPUDRCashFlow } from './rpudr-cash-flow'
import { isValidConfigurationForTMO } from 'core/service/basic-plan/validation'
import { lumpSumTopUpCharge, premiumChargeIinvest, premiumChargeIwealthy, regularPremiumCharge } from './premium-charge'
import constants from 'core/data-model/constants/defaults'
import type { InvestmentDisplayProduct } from 'core/service/display-product'
import { getInvestmentProductConfiguration, listCoiRates, listCorRates } from 'core/data-model/investment'
import type { ExpectedReturnComparisonWithoutLumpSum } from 'core/service/investment/types'
import VALUES from 'core/data-model/constants/values'

type PolicyCharges = {
  premiumCharge: Decimal,
  chargeOfInsurance: Decimal,
  adminCharge: Decimal,
  // FIXME: make this non optional
  lumpsumCharge?: Decimal,
  regularPremiumCharge: Decimal,
}

type Investment = {
  accountValueBeforeDeduction: Decimal,
  accountValueAfterDeduction: Decimal,
  fundReturn: Decimal,
  accountValueBeforeBonus: Decimal,
  bonus: Decimal,
  accountValueAfterBonus: Decimal,
}

export type CashFlow = {
  policyPeriod: PolicyPeriod,
  premium: Premium,
  policyCharges: PolicyCharges,
  netAllocationPremium: Decimal,
  sumAtRisk: Decimal,
  investment: Investment,
  surrenderCash: Decimal,
  deathBenefit: Decimal,
}

export type ProductConfig = {
  feeStructure: FeeStructure,
  coveragePeriodByAge: number,
  deathBenefitRate: number,
  coiRates: COIRates,
  corRates: CORRateTables,
  amcRate: number,
  loyaltyBonusRates: LoyaltyBonusRate[],
}

export type CoiRate = { age: number, rate: Decimal }

export type COIRatesForCalculation = {
  [Gender]: CoiRate[],
}

export type CORRatesForCalculation = {
  [Gender]: CoiRate[],
}

type CashFlowsByExpectedReturn = {
  '-1': CashFlow[],
  '2': CashFlow[],
  '5': CashFlow[],
}

export const shouldPayPremium = (paymentInterval: number, month: number): boolean =>
  (month - 1) % (12 / paymentInterval) === 0

export const premiumForPolicyPeriodIwealthy = (
  paymentInterval: number,
  { year, month }: PolicyPeriod,
  inputRegularPremium: Decimal,
  inputRegularTopUp: Decimal,
  lumpSumByYears: { year: number, value: Decimal }[]
): Premium => {
  const lumpSumForGivenYear = _.find(lumpSumByYears, { year: year }) || {
    value: d(0),
  }

  const needPayment = shouldPayPremium(paymentInterval, month)
  const regularPremium = needPayment ? inputRegularPremium : d(0)
  const regularTopUp = needPayment ? inputRegularTopUp : d(0)
  const lumpSum = month === 1 ? lumpSumForGivenYear.value : d(0)
  return {
    regularPremium,
    regularTopUp,
    lumpSum,
    totalPremium: totalPremium(regularPremium, regularTopUp, lumpSum),
  }
}

export const premiumForPolicyPeriodIinvest = (
  { year, month }: PolicyPeriod,
  inputRegularPremium: Decimal,
  lumpSumByYears: { year: number, value: Decimal }[]
): Premium => {
  const lumpSumForGivenYear = _.find(lumpSumByYears, { year: year }) || {
    value: d(0),
  }

  const needPayment = year === 1 && month === 1
  const regularPremium = needPayment ? inputRegularPremium : d(0)
  const regularTopUp = d(0)
  const lumpSum = month === 1 ? lumpSumForGivenYear.value : d(0)

  return {
    regularPremium,
    regularTopUp,
    lumpSum,
    totalPremium: totalPremium(regularPremium, regularTopUp, lumpSum),
  }
}

const mergePrevious = (dependencies: CashFlowDependencies) => (f) => (prev) => ({
  ...prev,
  ...f(dependencies, prev),
})

export const mergeProps = (...args) => (prev) => {
  const [key, f] = typeof args[0] === 'function' ? [null, args[0]] : [args[0], args[1]]
  const mergeProps = key ? { [key]: f(prev) } : f(prev)

  return {
    ...prev,
    ...mergeProps,
  }
}

export type CashFlowDependencies = {
  feeStructure: FeeStructure,
  coveragePeriodByAge: number,
  deathBenefitRate: Decimal,
  coiRates: COIRatesForCalculation,
  corRates: CORRatesForCalculation,
  amcRate: Decimal,
  loyaltyBonusRates: LoyaltyBonusRate[],
  regularPremium: Decimal,
  regularTopUp: Decimal,
  lumpSum: { year: number, value: Decimal }[],
  age: number,
  paymentInterval: number,
  sumAssured: Decimal,
  expectedReturn: Decimal,
  inflationRate: Decimal,
  gender: Gender,
  riderOccupationFactors: { [riderCode: string]: number },
  riders: (Rider & RiderState)[],
}

const generatePremiumIwealthy = ({ paymentInterval, regularPremium, regularTopUp, lumpSum }, { policyPeriod }) => ({
  premium: premiumForPolicyPeriodIwealthy(paymentInterval, policyPeriod, regularPremium, regularTopUp, lumpSum),
})

const generatePremiumIinvest = ({ regularPremium, lumpSum }, { policyPeriod }) => ({
  premium: premiumForPolicyPeriodIinvest(policyPeriod, regularPremium, lumpSum),
})

const generatePolicyChargesIwealthy = (
  { feeStructure }: CashFlowDependencies,
  { policyPeriod, premium }: CashFlow
) => ({
  policyCharges: {
    premiumCharge: premiumChargeIwealthy(feeStructure)(policyPeriod.year, premium),
    regularPremiumCharge: regularPremiumCharge(policyPeriod.year, premium.regularPremium, feeStructure),
  },
})

export const generatePolicyChargesIinvest = (
  { feeStructure }: CashFlowDependencies,
  { policyPeriod, premium }: CashFlow
) => ({
  policyCharges: {
    premiumCharge: premiumChargeIinvest(feeStructure)(policyPeriod.year, premium),
    lumpsumCharge: lumpSumTopUpCharge(premium.lumpSum, feeStructure),
  },
})

export const netAllocationPremium = (totalPremium: Decimal, premiumCharge: Decimal): Decimal =>
  totalPremium.minus(premiumCharge)

const generateNetAllocationPremiumIwealthy = ({}, { premium, policyCharges }: CashFlow) => ({
  netAllocationPremium: netAllocationPremium(premium.totalPremium, policyCharges.premiumCharge),
})

const generateNetAllocationPremiumIinvest = ({}, { premium, policyCharges }: CashFlow) => ({
  netAllocationPremium: netAllocationPremium(premium.totalPremium, policyCharges.premiumCharge),
  netLumpsum: premium.lumpSum.minus(policyCharges.lumpsumCharge),
})

export const surrenderDependencies = ({ premium, policyCharges }: CashFlow) => ({
  totalPremium: premium.totalPremium,
  regularPremium: premium.regularPremium,
  premiumCharge: policyCharges.premiumCharge,
  regularPremiumCharge: policyCharges.regularPremiumCharge,
})

export const cashFlowStructure = (
  curr,
  chargeOfInsuranceValue,
  adminChargeValue,
  accountValueBeforeDeductionValue,
  accountValueAfterDeductionValue,
  fundReturnValue,
  accountValueBeforeBonusValue,
  loyaltyBonusValue,
  accountValueAfterBonusValue,
  acc,
  sumAtRiskValue,
  deathBenefitValue,
  surrenderCashValue
) => {
  const policyCharges = {
    ...curr.policyCharges,
    chargeOfInsurance: chargeOfInsuranceValue,
    adminCharge: adminChargeValue,
  }

  const investment = {
    accountValueBeforeDeduction: accountValueBeforeDeductionValue,
    accountValueAfterDeduction: accountValueAfterDeductionValue,
    fundReturn: fundReturnValue,
    accountValueBeforeBonus: accountValueBeforeBonusValue,
    bonus: loyaltyBonusValue,
    accountValueAfterBonus: accountValueAfterBonusValue,
  }

  return [
    ...acc,
    {
      ...curr,
      policyCharges,
      investment,
      sumAtRisk: sumAtRiskValue,
      deathBenefit: deathBenefitValue,
      surrenderCash: surrenderCashValue,
    },
  ]
}

const generateInvestmentAndChargesIwealthy = (cashFlowDependencies) => (acc, curr) => {
  const {
    deathBenefitRate,
    loyaltyBonusRates,
    coiRates,
    amcRate,
    gender,
    sumAssured,
    expectedReturn,
    feeStructure,
  } = cashFlowDependencies
  const { netAllocationPremium, policyPeriod } = curr

  const accountValueBeforeDeductionValue = accountValueBeforeDeductionIwealthy(
    netAllocationPremium,
    acc.map((a) => a.investment.accountValueAfterBonus)
  )
  const sumAtRiskValue = sumAtRiskIwealthy(sumAssured, accountValueBeforeDeductionValue, deathBenefitRate)
  const adminChargeValue = adminChargeIwealthy(accountValueBeforeDeductionValue, amcRate)
  const chargeOfInsuranceValue = chargeOfInsurance(coiRates, policyPeriod.age, gender, sumAtRiskValue)
  const accountValueAfterDeductionValue = accountValueAfterDeduction(
    accountValueBeforeDeductionValue,
    chargeOfInsuranceValue,
    adminChargeValue
  )
  const fundReturnValue = fundReturn(expectedReturn, accountValueAfterDeductionValue)
  const accountValueBeforeBonusValue = accountValueBeforeBonus(accountValueAfterDeductionValue, fundReturnValue)
  const loyaltyBonusValue = loyaltyBonus(
    policyPeriod,
    loyaltyBonusRates,
    accountValueBeforeBonusValue,
    accountValueBeforeDeductionValue,
    acc.map((a) => ({
      value: a.investment.accountValueBeforeBonus,
      year: a.policyPeriod.year,
    }))
  )
  const accountValueAfterBonusValue = accountValueAfterBonus(accountValueBeforeBonusValue, loyaltyBonusValue)
  const surrenderCashValue = surrenderCashIWealthy(
    feeStructure,
    policyPeriod.year,
    accountValueAfterBonusValue,
    surrenderDependencies(curr),
    acc.map(surrenderDependencies)
  )
  const deathBenefitValue = deathBenefit(sumAssured, accountValueAfterBonusValue, deathBenefitRate)

  return cashFlowStructure(
    curr,
    chargeOfInsuranceValue,
    adminChargeValue,
    accountValueBeforeDeductionValue,
    accountValueAfterDeductionValue,
    fundReturnValue,
    accountValueBeforeBonusValue,
    loyaltyBonusValue,
    accountValueAfterBonusValue,
    acc,
    sumAtRiskValue,
    deathBenefitValue,
    surrenderCashValue
  )
}

const generateInvestmentAndChargesIinvest = (cashFlowDependencies) => (acc, curr) => {
  const {
    deathBenefitRate,
    loyaltyBonusRates,
    coiRates,
    amcRate,
    gender,
    sumAssured,
    expectedReturn,
    feeStructure,
  } = cashFlowDependencies
  const { netAllocationPremium, policyPeriod, netLumpsum } = curr

  const accountValueBeforeDeductionValue = accountValueBeforeDeductionIinvest(
    netAllocationPremium,
    acc.map((a) => a.investment.accountValueAfterBonus),
    netLumpsum,
    policyPeriod
  )
  const sumAtRiskValue = sumAtRiskIinvest(sumAssured, accountValueBeforeDeductionValue)
  const chargeOfInsuranceValue = chargeOfInsurance(coiRates, policyPeriod.age, gender, sumAtRiskValue)
  const adminChargeValue = adminChargeIinvest(accountValueBeforeDeductionValue, amcRate, chargeOfInsuranceValue)
  const accountValueAfterDeductionValue = accountValueAfterDeduction(
    accountValueBeforeDeductionValue,
    chargeOfInsuranceValue,
    adminChargeValue
  )
  const fundReturnValue = fundReturn(expectedReturn, accountValueAfterDeductionValue)
  const accountValueBeforeBonusValue = accountValueBeforeBonus(accountValueAfterDeductionValue, fundReturnValue)
  const loyaltyBonusValue = loyaltyBonus(
    policyPeriod,
    loyaltyBonusRates,
    accountValueBeforeBonusValue,
    accountValueBeforeDeductionValue,
    acc.map((a) => ({
      value: a.investment.accountValueBeforeBonus,
      year: a.policyPeriod.year,
    }))
  )
  const accountValueAfterBonusValue = accountValueAfterBonus(accountValueBeforeBonusValue, loyaltyBonusValue)
  const surrenderCashValue = surrenderCashIInvest(feeStructure, policyPeriod.year, accountValueAfterBonusValue)
  const deathBenefitValue = deathBenefit(sumAssured, accountValueAfterBonusValue, deathBenefitRate)

  return cashFlowStructure(
    curr,
    chargeOfInsuranceValue,
    adminChargeValue,
    accountValueBeforeDeductionValue,
    accountValueAfterDeductionValue,
    fundReturnValue,
    accountValueBeforeBonusValue,
    loyaltyBonusValue,
    accountValueAfterBonusValue,
    acc,
    sumAtRiskValue,
    deathBenefitValue,
    surrenderCashValue
  )
}

const processCashFlow = (
  config,
  mergeToCashFlow,
  generatePremium,
  generatePolicyCharges,
  generateNetAllocationPremium,
  generateInvestmentAndCharges,
  cashFlowDependencies
) => {
  const age = cashFlowDependencies.age
  const policyPeriodLengthInYears = config.coveragePeriodByAge - age
  const policyYears = _.range(1, policyPeriodLengthInYears + 1)

  const getPolicyPeriodForYear = (year) =>
    _.range(1, constants.MONTHS_IN_YEAR + 1).map((month) => ({ policyPeriod: { year, month, age: age + year - 1 } }))

  return _(policyYears)
    .flatMap(getPolicyPeriodForYear)
    .map(mergeToCashFlow(generatePremium))
    .map(mergeToCashFlow(generatePolicyCharges))
    .map(mergeToCashFlow(generateNetAllocationPremium))
    .reduce(generateInvestmentAndCharges(cashFlowDependencies), [])
}

export const _generateCashFlow = (config: ProductConfig) => (
  expectedReturn: number,
  regularPremium: number,
  regularTopUp: number,
  lumpSum: { year: number, value: number }[],
  age: number,
  gender: Gender,
  paymentInterval: number,
  sumAssured: number,
  productCode: string,
  inflationPercentage: number,
  riderOccupationFactors: { [riderCode: string]: number },
  riders: (Rider & RiderState)[]
): CashFlow[] => {
  const transformExpectedReturn = expectedReturn === 3 ? 2 : expectedReturn
  const transformInflationPercentage = expectedReturn === 3 ? 5 : inflationPercentage
  const expectedReturnPercentage = transformExpectedReturn / 100
  const cashFlowDependencies = {
    feeStructure: config.feeStructure,
    coveragePeriodByAge: config.coveragePeriodByAge,
    deathBenefitRate: d(config.deathBenefitRate),
    // temporarily fix wrong data type in db
    coiRates: _.mapValues(config.coiRates, (v) =>
      _.map(v, ({ age, rate }) => ({
        age: parseInt(age),
        rate: d(parseFloat(rate)),
      }))
    ),
    corRates: config.corRates,
    amcRate: d(config.amcRate),
    loyaltyBonusRates: config.loyaltyBonusRates,
    regularPremium: d(regularPremium),
    regularTopUp: d(regularTopUp),
    lumpSum: lumpSum.map(({ year, value }) => ({ year, value: d(value) })),
    age,
    paymentInterval,
    sumAssured: d(sumAssured),
    expectedReturn: d(expectedReturnPercentage),
    medicalInflationRate: transformInflationPercentage ? d(transformInflationPercentage / 100) : undefined,
    gender,
    riderOccupationFactors,
    riders,
  }

  const mergeToCashFlow = mergePrevious(cashFlowDependencies)

  switch (productCode) {
    case VALUES.SPUL:
      return processCashFlow(
        config,
        mergeToCashFlow,
        generatePremiumIinvest,
        generatePolicyChargesIinvest,
        generateNetAllocationPremiumIinvest,
        generateInvestmentAndChargesIinvest,
        cashFlowDependencies
      )
    case VALUES.RPUDR:
      return processRPUDRCashFlow(config, mergeProps, cashFlowDependencies, expectedReturn, inflationPercentage)
    case VALUES.RPUL:
    default:
      return processCashFlow(
        config,
        mergeToCashFlow,
        generatePremiumIwealthy,
        generatePolicyChargesIwealthy,
        generateNetAllocationPremiumIwealthy,
        generateInvestmentAndChargesIwealthy,
        cashFlowDependencies
      )
  }
}

export const isUsedCoiTMORate = async () => {
  return _isUsedCoiTMORate(await listCoiRates(), [VALUES.RPUL])
}

export const _isUsedCoiTMORate = (coiRatePayload: COIRateTable[], products: string[]) => {
  for (let premium of coiRatePayload) {
    for (let product of products) {
      if (product === premium.code) {
        if (isValidConfigurationForTMO(premium.code, premium.effectiveDate, premium.expiryDate)) {
          return premium.effectiveDate === VALUES.TMO_EFFECTIVE_DATE
        }
        break
      }
    }
  }
  return false
}

const getCoiRates = async (productCode: string): COIRates => {
  const coiRatePayload = await listCoiRates()
  for (let rate of coiRatePayload) {
    if (rate.code === productCode) {
      if (isValidConfigurationForTMO(rate.code, rate.effectiveDate, rate.expiryDate)) {
        return rate.coiRates
      } else if (rate.effectiveDate === undefined && rate.expiryDate === undefined) {
        return rate.coiRates
      }
    }
  }
  return {}
}

const getCorRates = async (riderCodes: string[]): CORRateTables => {
  const corRatePayload = await listCorRates()
  return corRatePayload ? corRatePayload.filter((v) => riderCodes.includes(v.code)) : []
}

export const generateCashFlow = async (
  displayProduct: InvestmentDisplayProduct,
  expectedReturn: number,
  regularPremium: number,
  regularTopUp: number,
  lumpSum: { year: number, value: number }[],
  age: number,
  gender: Gender,
  paymentInterval: number,
  sumAssured: number,
  inflationRate: number,
  riderOccupationFactors: { [riderCode: string]: number },
  riders: (Rider & RiderState)[]
): Promise<CashFlow[]> => {
  const investmentProduct: InvestmentProductConfig = await getInvestmentProductConfiguration({
    code: displayProduct.code,
    type: displayProduct.type,
  })
  const investmentRates = investmentProduct.rates

  const productCode = displayProduct.code
  const riderCodes = displayProduct.riders || []
  const coiRates = await getCoiRates(productCode)
  const corRates = await getCorRates(riderCodes)

  const config = {
    feeStructure: investmentProduct.feeStructure,
    coveragePeriodByAge: investmentProduct.coveragePeriod.value,
    deathBenefitRate: investmentRates.deathBenefitRate,
    coiRates,
    corRates,
    amcRate: investmentRates.amcRate,
    loyaltyBonusRates: investmentRates.loyaltyBonusRates,
    selectableRidersInProduct: investmentProduct.riders || [],
  }
  return _generateCashFlow(config)(
    expectedReturn,
    regularPremium,
    regularTopUp,
    lumpSum,
    age,
    gender,
    paymentInterval,
    sumAssured,
    productCode,
    inflationRate,
    riderOccupationFactors,
    riders
  )
}

const filterDisplayByYear = (policyPeriod): boolean => {
  const december = 12
  return policyPeriod.month === december
}

const filterIInvestDisplay = (policyPeriod): boolean => {
  const months = [1, 2]
  return policyPeriod.year === 1 && _.includes(months, policyPeriod.month)
}

const policyChargesToNumber = (policyCharges: PolicyCharges) => {
  let _policyCharges = {
    premiumCharge: 0,
    chargeOfInsurance: 0,
    adminCharge: 0,
    lumpsumCharge: 0,
  }

  return fillValueEachField(_policyCharges, policyCharges)
}

const investmentToNumber = (investment: Investment) => {
  let _investment = {
    accountValueBeforeBonus: 0,
    accountValueAfterBonus: 0,
    accountValueAfterDeduction: 0,
    accountValueBeforeDeduction: 0,
    bonus: 0,
    fundReturn: 0,
  }

  return fillValueEachField(_investment, investment)
}

const premiumToNumber = (premium: Premium) => {
  let _premium = {
    regularPremium: 0,
    lumpSum: 0,
    totalPremium: 0,
  }

  return fillValueEachField(_premium, premium)
}

const fillValueEachField = (values: Object, data: Object) => {
  let newValue = {}
  const fields = Object.keys(values)
  fields.forEach((key) => {
    newValue[key] = toNumber(data[key])
  })
  return newValue
}

const toNumber = (value: Decimal): number => {
  return value ? value.round().toNumber() : 0
}

const iInvestCashFlowsToExpectedReturnComparisons = (
  cashFlowsByExpectedReturn: CashFlowsByExpectedReturn
): ExpectedReturnComparisonWithoutLumpSum[] => {
  const findAdditionalCashFlowsByExpectedReturn = (expectedReturn, _year, _month) =>
    cashFlowsByExpectedReturn[expectedReturn].find(
      ({ policyPeriod }) => _year === policyPeriod.year && _month === policyPeriod.month
    )

  return cashFlowsByExpectedReturn['2']
    .filter(({ policyPeriod }) => filterIInvestDisplay(policyPeriod) || filterDisplayByYear(policyPeriod))
    .map(({ policyPeriod, premium, policyCharges, investment, deathBenefit, surrenderCash }) => {
      const cashFlowsByExpectedReturnM1 = findAdditionalCashFlowsByExpectedReturn(
        '-1',
        policyPeriod.year,
        policyPeriod.month
      )
      const cashFlowsByExpectedReturn2 = findAdditionalCashFlowsByExpectedReturn(
        '2',
        policyPeriod.year,
        policyPeriod.month
      )
      const cashFlowsByExpectedReturn5 = findAdditionalCashFlowsByExpectedReturn(
        '5',
        policyPeriod.year,
        policyPeriod.month
      )

      // $FlowFixMe
      const {
        // $FlowFixMe
        premium: firstMonthPremium,
        // $FlowFixMe
        policyCharges: firstMonthPolicyCharges,
      } = findAdditionalCashFlowsByExpectedReturn('2', policyPeriod.year, 1)

      // $FlowFixMe
      return {
        year: policyPeriod.year,
        month: policyPeriod.month,
        age: policyPeriod.age,
        benefitByExpectedReturn: {
          '-1': {
            accountValueAfterBonus: _.has(cashFlowsByExpectedReturnM1, 'investment.accountValueAfterBonus')
              ? // $$FlowFixMe
                cashFlowsByExpectedReturnM1.investment.accountValueAfterBonus.round().toNumber()
              : 0,
          },
          '2': {
            premium: premiumToNumber({
              ...premium,
              lumpSum: firstMonthPremium.lumpSum,
            }),
            policyCharges: policyChargesToNumber({
              ...policyCharges,
              lumpsumCharge: firstMonthPolicyCharges.lumpsumCharge,
            }),
            investment: investmentToNumber(investment),
            surrenderCash: toNumber(surrenderCash),
            deathBenefit: toNumber(deathBenefit),
            accountValueAfterBonus: _.has(cashFlowsByExpectedReturn2, 'investment.accountValueAfterBonus')
              ? // $$FlowFixMe
                cashFlowsByExpectedReturn2.investment.accountValueAfterBonus.round().toNumber()
              : 0,
          },
          '5': {
            accountValueAfterBonus: _.has(cashFlowsByExpectedReturn5, 'investment.accountValueAfterBonus')
              ? // $$FlowFixMe
                cashFlowsByExpectedReturn5.investment.accountValueAfterBonus.round().toNumber()
              : 0,
          },
        },
      }
    })
}

const iWealthyCashFlowsToExpectedReturnComparisons = (
  cashFlowsByExpectedReturn: CashFlowsByExpectedReturn
): ExpectedReturnComparisonWithoutLumpSum[] => {
  const accumulateTotalPremium = (acc, curr) => {
    const lastAccumulatedTotalPremium = _.isEmpty(acc) ? d(0) : _.last(acc).accumulatedTotalPremium
    const accumulatedTotalPremium = lastAccumulatedTotalPremium.plus(curr.premium.totalPremium)
    return [...acc, { ...curr, accumulatedTotalPremium }]
  }

  const yearEndBenefitByExpectedReturn = (expectedReturn) =>
    cashFlowsByExpectedReturn[expectedReturn]
      .reduce(accumulateTotalPremium, [])
      .filter(({ policyPeriod }) => filterDisplayByYear(policyPeriod))
      .map(({ policyPeriod, accumulatedTotalPremium, investment, deathBenefit }) => ({
        year: policyPeriod.year,
        age: policyPeriod.age,
        accumulatedTotalPremium: accumulatedTotalPremium.round().toNumber(),
        benefitByExpectedReturn: {
          [expectedReturn]: {
            accountValueAfterBonus: investment.accountValueAfterBonus.round().toNumber(),
            deathBenefit: deathBenefit.round().toNumber(),
          },
        },
      }))

  const mergeByYear = (comparisons, toAdd) => {
    const isToAddYearExist = !!comparisons.find(({ year }) => year === toAdd.year)

    if (isToAddYearExist) {
      return comparisons.map((comparison) =>
        comparison.year !== toAdd.year
          ? comparison
          : {
              ...comparison,
              benefitByExpectedReturn: {
                ...comparison.benefitByExpectedReturn,
                ...toAdd.benefitByExpectedReturn,
              },
            }
      )
    } else {
      return [...comparisons, toAdd]
    }
  }

  const expectedReturns = _.keys(cashFlowsByExpectedReturn)

  return _(expectedReturns)
    .flatMap(yearEndBenefitByExpectedReturn)
    .reduce(mergeByYear, [])
}

const RpudrCashFlowsToExpectedReturnComparisons = (
  cashFlowsByExpectedReturn: CashFlowsByExpectedReturn
): ExpectedReturnComparisonWithoutLumpSum[] => {
  const accumulateTotalPremium = (acc, curr) => {
    const lastAccumulatedTotalPremium = _.isEmpty(acc) ? d(0) : _.last(acc).accumulatedTotalPremium
    const accumulatedTotalPremium = lastAccumulatedTotalPremium.plus(curr.premium.totalPremium)
    return [...acc, { ...curr, accumulatedTotalPremium }]
  }

  const yearEndBenefitByExpectedReturn = (expectedReturn) =>
    cashFlowsByExpectedReturn[expectedReturn]
      .reduce(accumulateTotalPremium, [])
      .filter(({ policyPeriod }) => policyPeriod.month === 12)
      .map(({ policyPeriod, accumulatedTotalPremium, investment, deathBenefit, rider, premium, policyCharges }) => ({
        year: policyPeriod.year,
        age: policyPeriod.age,
        accumulatedTotalPremium: accumulatedTotalPremium.round().toNumber(),
        premium,
        policyCharges,
        rider,
        benefitByExpectedReturn: {
          [expectedReturn]: {
            accountValueAfterBonus: investment.redemptionValueAtMonthEndPlusBonus.total.round().toNumber(),
            deathBenefit: deathBenefit,
          },
        },
      }))

  const mergeByYear = (comparisons, toAdd) => {
    const isToAddYearExist = !!comparisons.find(({ year }) => year === toAdd.year)

    if (isToAddYearExist) {
      return comparisons.map((comparison) =>
        comparison.year !== toAdd.year
          ? comparison
          : {
              ...comparison,
              benefitByExpectedReturn: {
                ...comparison.benefitByExpectedReturn,
                ...toAdd.benefitByExpectedReturn,
              },
            }
      )
    } else {
      return [...comparisons, toAdd]
    }
  }

  const expectedReturns = _.keys(cashFlowsByExpectedReturn)
    .map((k) => parseInt(k, 10))
    .sort((a, b) => b - a)
    .map((k) => k.toString())

  return _(expectedReturns)
    .flatMap(yearEndBenefitByExpectedReturn)
    .reduce(mergeByYear, [])
}

export const cashFlowsToExpectedReturnComparisons = (
  cashFlowsByExpectedReturn: CashFlowsByExpectedReturn,
  productCode: string
) => {
  switch (productCode) {
    case VALUES.SPUL:
      return iInvestCashFlowsToExpectedReturnComparisons(cashFlowsByExpectedReturn)
    case VALUES.RPUDR:
      return RpudrCashFlowsToExpectedReturnComparisons(cashFlowsByExpectedReturn)
    case VALUES.RPUL:
    default:
      return iWealthyCashFlowsToExpectedReturnComparisons(cashFlowsByExpectedReturn)
  }
}
