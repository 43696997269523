// @flow
import _ from 'lodash'
import { pouchDbFactory } from './pouch-db-factory'
import type {
  PremiumRate,
  ModelFactor,
  CashValueRates,
  CashDropRates,
  PresentValue,
  TaxRate,
  RPURates,
  ETIRates,
  PENRates,
  SaRates,
} from 'core/data-model/basic-plan'
import type { RiderPremiumRate, RiderTaxRate } from 'core/data-model/rider'
import type {
  Occupation,
  OccupationFactor,
  RiderOccupationFactor,
  ProductOccupationFactor,
} from 'core/data-model/insured'
import type { RiderBenefitData } from 'core/data-model/rider/benefits'

const { memoize } = _
type QueryResult<T> = { rows: Array<{ doc: T & { _id: string } }> }

export type PromiseFunction<T> = (void | string) => Promise<T>

export const _DB = (_pouchDbFactory: *) => {
  let databaseInstance

  const destroy = (): Promise<*> => {
    if (databaseInstance) {
      return databaseInstance.destroy()
    }
    return Promise.resolve()
  }

  const init = (localDbUrl: string): Promise<*> => {
    if (databaseInstance) {
      return Promise.resolve()
    }

    let result = _pouchDbFactory.createInstance(localDbUrl)

    return result.databaseInstanceCreationPromise.then((dbInstance) => {
      databaseInstance = dbInstance
    })
  }

  const get = memoize((id) => {
    if (!databaseInstance) throw new Error('DB init is not done yet')
    return databaseInstance.get(id)
  })

  const list = memoize((key) => {
    if (!databaseInstance) throw new Error('DB init is not done yet')
    return databaseInstance
      .allDocs({
        startkey: `${key}_`,
        endkey: `${key}_\ufff0`,
        include_docs: true,
        attachments: true,
      })
      .then((data: QueryResult<*>) => {
        return data.rows.map((row) => row.doc)
      })
  })

  const _getPouchDBInstance = () => databaseInstance

  const getOccupations = (): Promise<Occupation[]> => list('occupation')

  const getOccupationFactors = (): Promise<OccupationFactor[]> => {
    return Promise.all([
      getOccupationFactorsForOccupations(),
      getOccupationFactorsForProducts(),
      getOccupationFactorsForRiders(),
    ]).then((allOccupationFactors: OccupationFactor[][]) => {
      return allOccupationFactors.reduce((a, b) => a.concat(b))
    })
  }

  const getOccupationFactorsForProducts = (): Promise<OccupationFactor[]> => {
    return list('product-occupation-factor').then((pofs: ProductOccupationFactor[]) => {
      return pofs
        .map(({ occupationFactors }) => occupationFactors)
        .concat(
          pofs
            .map(({ riderOccupationFactors }) => riderOccupationFactors.map((rider) => rider.occupationFactors))
            .reduce((a, b) => a.concat(b))
        )
        .reduce((a, b) => a.concat(b))
    })
  }

  const getOccupationFactorsForRiders = (): Promise<OccupationFactor[]> => {
    return list('rider-occupation-factor').then((riderOccupationFactors: RiderOccupationFactor[]) =>
      riderOccupationFactors.map(({ occupationFactors }) => occupationFactors).reduce((a, b) => a.concat(b))
    )
  }

  const getOccupationFactorsForOccupations = (): Promise<OccupationFactor[]> => {
    return list('occupation').then((occupations: Occupation[]) => {
      return occupations.map(({ occupationFactor }) => occupationFactor)
    })
  }

  const sortByPeriods = (first: ModelFactor, second: ModelFactor) => {
    return parseFloat(first.periods) - parseFloat(second.periods)
  }

  const getModelFactors = (): Promise<ModelFactor[]> => {
    return list('model-factor').then((modelFactors: *) => {
      return modelFactors
        .map((doc) => ({ ...doc, id: doc._id, text: doc.displayLabel }))
        .sort(sortByPeriods)
        .reverse()
    })
  }

  const getModelFactorById = (id: string): Promise<ModelFactor> => get(id)

  const getPremiumRates = (): Promise<PremiumRate[]> => list('premium-rate')

  const getPresentValues = (): Promise<PresentValue[]> => list('present-value')

  const getRiderPremiumRates = (): Promise<RiderPremiumRate[]> => list('rider-premium-rate')

  const getRiderTaxRates = (): Promise<RiderTaxRate[]> => list('rider-tax-rate')

  const getTaxRates = (): Promise<TaxRate[]> => list('tax-rate')

  const getCashValueRates = (): Promise<CashValueRates[]> => list('cash-value-rate')

  const getCashDropRates = (): Promise<CashDropRates[]> => list('cash-drop-rate')

  const getRPURates = (): Promise<RPURates[]> => list('RPU-rate')
  const getETIRates = (): Promise<ETIRates[]> => list('ETI-rate')
  const getPENRates = (): Promise<PENRates[]> => list('PEN-rate')
  const getSaRates = (): Promise<SaRates[]> => list('sa-rate')

  const getRiderBenefit = (key: string): Promise<RiderBenefitData> => {
    return get('rider-benefit_' + key).catch(() => {
      return {}
    })
  }

  // canLoadLookUpData below cannot be tracked by instanbul
  /* istanbul ignore next */
  return {
    init,
    destroy,
    _getPouchDBInstance,
    get,
    list,
    getOccupations,
    getOccupationFactors,
    getOccupationFactorsForProducts,
    getOccupationFactorsForRiders,
    getOccupationFactorsForOccupations,
    getModelFactors,
    getModelFactorById,
    getPremiumRates,
    getPresentValues,
    getRiderPremiumRates,
    getTaxRates,
    getRiderTaxRates,
    getCashValueRates,
    getRPURates,
    getETIRates,
    getPENRates,
    getSaRates,
    getCashDropRates,
    getRiderBenefit,
    canLoadLookUpData(): Promise<boolean> {
      return Promise.all([
        this.getPremiumRates(),
        this.getOccupationFactors(),
        this.getRiderPremiumRates(),
        this.getRiderTaxRates(),
        this.getCashValueRates(),
        this.getRPURates(),
        this.getETIRates(),
        this.getPENRates(),
        this.getSaRates(),
        this.getCashDropRates(),
      ]).then(
        ([
          premiumRates,
          occupationFactors,
          riderPremiumRates,
          riderTaxRates,
          cashValueRates,
          RPURates,
          ETIRates,
          PENRates,
          SaRates,
          cashDropRates,
        ]) => {
          return (
            premiumRates.length > 0 &&
            Object.keys(occupationFactors).length > 0 &&
            riderPremiumRates.length > 0 &&
            riderTaxRates.length > 0 &&
            cashValueRates.length > 0 &&
            RPURates.length > 0 &&
            ETIRates.length > 0 &&
            PENRates.length > 0 &&
            SaRates.length > 0 &&
            cashDropRates.length > 0
          )
        }
      )
    },
  }
}

export type DataWrapper = {
  init: (string) => Promise<*>,
  destroy: () => Promise<*>,
  _getPouchDBInstance: () => Object,
  list: (string) => Promise<*>,
  get: PromiseFunction<*>,
  getOccupations: () => Promise<Occupation[]>,
  getOccupationFactors: () => Promise<OccupationFactor[]>,
  getOccupationFactorsForProducts: () => Promise<OccupationFactor[]>,
  getOccupationFactorsForRiders: () => Promise<OccupationFactor[]>,
  getOccupationFactorsForOccupations: () => Promise<OccupationFactor[]>,
  getModelFactors: () => Promise<ModelFactor[]>,
  getModelFactorById: () => Promise<ModelFactor>,
  getPremiumRates: () => Promise<PremiumRate[]>,
  getRiderPremiumRates: () => Promise<RiderPremiumRate[]>,
  getTaxRates: () => Promise<TaxRate[]>,
  getRiderTaxRates: () => Promise<RiderTaxRate[]>,
  getCashValueRates: () => Promise<CashValueRates[]>,
  getRPURates: () => Promise<RPURates[]>,
  getETIRates: () => Promise<ETIRates[]>,
  getPENRates: () => Promise<PENRates[]>,
  getSaRates: () => Promise<SaRates[]>,
  getCashDropRates: () => Promise<CashDropRates[]>,
  getRiderBenefit: (key: string) => Promise<RiderBenefitData>,
  canLoadLookUpData: () => Promise<boolean>,
}

export default _DB(pouchDbFactory)
