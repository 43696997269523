// @flow

import { createPdfDocument } from '../../document-creation'
import { createCommonPdf } from '../common/pdf-document'
import { basicPlanDetailsLabelFn } from '../common/sections/product-benefit-section'
import protectionTemplate from './protection-template'
import { commonSection } from 'core/service/pdf-generation/products/common/sections'
import type { ProtectionProps } from './index'
import { sectionICare } from './index'
import { getToggles } from 'quick-quote/feature-toggles'
import type { DisplayProduct } from 'core/service/display-product'

export const basicPlanLabel = (basicPlan: DisplayProduct) => basicPlan.basicPlanName

export const createMiniBIDocDefinition = async (props: ProtectionProps) => {
  const pdfTemplate = await protectionTemplate(props)
  return {
    content: [
      commonSection.profile({ ...props, basicPlanLabel }),
      commonSection.productBenefits({ ...props, basicPlanDetailsLabelFn }),
      ...commonSection.riders(props),
      commonSection.legalDisclaimerMarketConduct(props),
    ],
    ...pdfTemplate,
  }
}

export const createFullBIDocDefinition = async (props: ProtectionProps) => {
  const _props: ProtectionProps = { ...props, biType: props.biType }
  const pdfTemplate = await protectionTemplate(_props)
  const paymentChannels = await commonSection.paymentChannels(props)

  if (getToggles().ENABLE_ICARE_REPRICING === true) {
    return {
      content: [
        commonSection.profile({ ...props, basicPlanLabel }),
        commonSection.productBenefits({ ...props, basicPlanDetailsLabelFn }),
        ...commonSection.riders(props),
        commonSection.basicPlanCondition,
        ...sectionICare.policyValueTableICare(props),
        sectionICare.benefitSummarySection(props),
        commonSection.taxConsentDeductionBenefit(props),
        sectionICare.legalDisclaimerSectionICare(props),
        paymentChannels,
        commonSection.signature(props),
      ],
      ...pdfTemplate,
    }
  } else {
    return {
      content: [
        commonSection.profile({ ...props, basicPlanLabel }),
        commonSection.productBenefits({ ...props, basicPlanDetailsLabelFn }),
        ...commonSection.riders(props),
        commonSection.basicPlanCondition,
        commonSection.legalDisclaimerMarketConduct(props),
        paymentChannels,
        commonSection.signature(props),
      ],
      ...pdfTemplate,
    }
  }
}

const createFullBIPdf = createPdfDocument(createFullBIDocDefinition)
const createMiniBIPdf = createPdfDocument(createMiniBIDocDefinition)

export const createProtectionPdf = async (props: ProtectionProps) => {
  return await createCommonPdf(props, createFullBIPdf, createMiniBIPdf)
}
