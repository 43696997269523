// @flow
import _ from 'lodash'
import type { Effect } from 'redux-saga/effects'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import type { DisplayProduct } from 'core/service/display-product'
import type { Age } from 'core/data-model/insured'
import { selectBasicPlan } from './actions'
import { SELECTED_PRODUCT } from 'quick-quote/product-selection/actions'
import VALUES from 'core/data-model/constants/values'
import { UPDATE_BIRTHDATE } from 'quick-quote/insured-information/actions'
import { populateAvailableRiders } from 'quick-quote/product-common/coverage-plan/sagas'
import { getDisplayProducts, getSelectedDisplayProductCode } from 'quick-quote/product-selection/selectors'
import { isValidAge } from 'core/service/insured/birthdate'
import { getAge } from 'quick-quote/insured-information/selectors'

export const _getProductByAge = (products: DisplayProduct[], age: Age) => {
  const { value, unit } = age
  return _.find(products, (product) => isValidAge(value, unit, _.get(product, 'entryAge')))
}

const _isLifeProtect18Group = (productCode: string) => _.includes(VALUES.LIFE_PROTECT_18, productCode)
const _isLifeProtect10Group = (productCode: string) => _.includes(VALUES.LIFE_PROTECT_10, productCode)
const _isS73PayGroup = (productCode: string) => _.includes(VALUES.S7_3PAY, productCode)
const _isS7SingleGroup = (productCode: string) => _.includes(VALUES.S7_SINGLE, productCode)

const _getProductGroupCode = (productCode: string) => {
  if (_isLifeProtect10Group(productCode)) {
    return VALUES.LIFE_PROTECT_10
  } else if (_isLifeProtect18Group(productCode)) {
    return VALUES.LIFE_PROTECT_18
  } else if (_isS73PayGroup(productCode)) {
    return VALUES.S7_3PAY
  } else if (_isS7SingleGroup(productCode)) {
    return VALUES.S7_SINGLE
  }
}

export const switchProductByBirthDate = function*(): Generator<*, *, *> {
  const productCode = yield select(getSelectedDisplayProductCode)
  if (
    !_isLifeProtect18Group(productCode) &&
    !_isLifeProtect10Group(productCode) &&
    !_isS73PayGroup(productCode) &&
    !_isS7SingleGroup(productCode)
  )
    return

  const age = yield select(getAge)
  const products = yield select(getDisplayProducts)
  const productGroupCodes = _getProductGroupCode(productCode)

  const productGroup = _.filter(products, (product) => _.includes(productGroupCodes, _.get(product, 'code')))
  const product = _getProductByAge(productGroup, age)
  if (product !== undefined && productCode !== _.get(product, 'code')) {
    yield put(selectBasicPlan(product))
    yield call(populateAvailableRiders)
  }
}

export function* watchers(): Generator<*, *, Effect[]> {
  yield [takeLatest([UPDATE_BIRTHDATE, SELECTED_PRODUCT], switchProductByBirthDate)]
}

export const sagas = watchers
