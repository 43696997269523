// @flow

import _ from 'lodash'

import CONSTANTS from 'core/data-model/constants/values'
import { createSelector } from 'reselect'
import { getDisplayProducts } from 'quick-quote/product-selection/selectors'
import { getAge } from 'quick-quote/insured-information/selectors'

import { isValidAge } from 'core/service/insured/birthdate'

export const getSelectableProductUltimateGrowth = createSelector(getAge, getDisplayProducts, (age: Age, products) => {
  const productGroup = _.filter(products, (product) =>
    _.includes(CONSTANTS.ULTIMATE_GROWTH_CODE_GROUP, _.get(product, 'code'))
  )
  const validProducts = _.filter(productGroup, (product) => isValidAge(age.value, age.unit, _.get(product, 'entryAge')))
  const validProductCodes = validProducts.map((product) => product.code)

  return validProductCodes
})

export const getSelectedProductUltimateGrowth = (selectableProductUltimateGrowth, selectedProduct) => {
  // if current is not valid return the first valie
  return selectableProductUltimateGrowth.includes(selectedProduct)
    ? selectedProduct
    : selectableProductUltimateGrowth[0]
}
