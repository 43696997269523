import { createSectionCreator, createField } from './utils'

const hasOtherPolicyField = (app, id) => {
  return [
    createField(`${id}.hasOtherPolicy`, [
      {
        label:
          'ท่านมีหรือเคยมีประกันชีวิตหรือประกันสุขภาพหรือประกันอุบัติเหตุ หรือกำลังขอเอาประกันภัยดังกล่าวไว้กับบริษัทนี้หรือบริษัทอื่นหรือไม่',
        id: `${id}.hasOtherPolicy`,
        c: 'RadioButton',
        required: true,
        p: {
          options: [
            {
              text: 'มี',
              value: true,
            },
            {
              text: 'ไม่มี',
              value: false,
            },
          ],
        },
      },
    ]),
  ]
}

export default createSectionCreator(hasOtherPolicyField)
