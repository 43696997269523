// @flow
import _ from 'lodash'
import { d } from 'core/service/lib/decimal'
import { createSelector } from 'reselect'
import { formatNumber } from 'core/service/lib/number-format'

import type { AppState } from 'quick-quote/reducers'
import type { BenefitSummaryTableProps } from './container'

export const getLastGuaranteeDividend = (state: AppState): number => {
  const lastIndex = _.get(state, 'benefitIllustration.policyValue.yearEndPolicyValues', []).length
  return _.get(state, `benefitIllustration.policyValue.yearEndPolicyValues.${lastIndex - 1}.guaranteeDividend`, 0)
}

export const getLastLowInterestDeposit = (state: AppState): number => {
  return _.get(state, 'benefitIllustration.policyValue.subTotalLowInterest', 0)
}

export const getLastMidInterestDeposit = (state: AppState): number => {
  return _.get(state, 'benefitIllustration.policyValue.subTotalMidInterest', 0)
}

export const getLastHighInterestDeposit = (state: AppState): number => {
  return _.get(state, 'benefitIllustration.policyValue.subTotalHighInterest', 0)
}

export const getTotalGuaranteeDividend = (state: AppState): number =>
  _.get(state, 'benefitIllustration.policyValue.subtotalGuaranteeDividend', 0)

const _dashedOutOrWithPrecision = (value: number, precision: number = 0, isRound: boolean = true) =>
  value === 0 ? '-' : formatNumber(value, precision, isRound)

export const getDisplayBenefitSummaryTable = createSelector(
  getTotalGuaranteeDividend,
  getLastGuaranteeDividend,
  getLastLowInterestDeposit,
  getLastMidInterestDeposit,
  getLastHighInterestDeposit,
  (
    totalGuaranteeDividend,
    lastGuaranteeDividend,
    lastLowInterestDeposit,
    lastMidInterestDeposit,
    lastHighInterestDeposit
  ): BenefitSummaryTableProps => {
    return {
      totalGuaranteeDividend: _dashedOutOrWithPrecision(totalGuaranteeDividend),
      lastGuaranteeDividend: _dashedOutOrWithPrecision(lastGuaranteeDividend),
      totalGuaranteeDividendBeforeLastYear: _dashedOutOrWithPrecision(
        d(totalGuaranteeDividend)
          .minus(lastGuaranteeDividend)
          .toNumber()
      ),
      nonGuaranteeDividendBeforeDueDate: {
        interestDeposit: _dashedOutOrWithPrecision(0),
        lowInterestDeposit: _dashedOutOrWithPrecision(lastLowInterestDeposit),
        midInterestDeposit: _dashedOutOrWithPrecision(lastMidInterestDeposit),
        highInterestDeposit: _dashedOutOrWithPrecision(lastHighInterestDeposit),
      },
      totalNonGuaranteeDividend: {
        totalInterestDeposit: _dashedOutOrWithPrecision(totalGuaranteeDividend),
        lowInterestDeposit: _dashedOutOrWithPrecision(d(totalGuaranteeDividend).plus(lastLowInterestDeposit)),
        midInterestDeposit: _dashedOutOrWithPrecision(d(totalGuaranteeDividend).plus(lastMidInterestDeposit)),
        highInterestDeposit: _dashedOutOrWithPrecision(d(totalGuaranteeDividend).plus(lastHighInterestDeposit)),
      },
    }
  }
)
