import {
  constant,
  cond,
  defaultsDeep,
  flow,
  get,
  has,
  isEqual,
  isNil,
  map,
  negate,
  omitBy,
  overEvery,
  overSome,
  set,
  unset,
} from 'lodash/fp'
import { getAllAnswer } from 'e-submission/apps/selectors'
import { isKid, isMRTACategory } from './selectors'
import { bankBeneficiary } from 'e-submission/domain/data-model/constants'

const is = (value) => (key) => flow(get(key), isEqual(value))
const isTrue = is(true)
const isFalse = is(false)
const doesExist = has
const doesNotExist = (path) => negate(has(path))

const when = (condition, fn) => (state) => {
  if (condition(state)) {
    return fn(state)
  }
  return state
}

const mapObject = (key, fn) => (state) => {
  const value = get(key, state)
  return set(key, fn(value), unset(key, state))
}

const setDefault = (key, value) => (state) => {
  return defaultsDeep(set(key, value, {}), state)
}

const toGenderValue = cond([
  [isEqual('M'), constant({ text: 'ชาย', value: 'male' })],
  [isEqual('F'), constant({ text: 'หญิง', value: 'female' })],
])

export default (state) => {
  const app = getAllAnswer(state)
  const currentAddress = get('insured.currentAddress', app)
  return flow(
    when(
      overSome([doesNotExist('insured.registeredAddress'), isFalse('insured.registeredAddress.notUseCurrentAddress')]),
      flow(set('insured.registeredAddress', currentAddress), set('insured.registeredAddress.useCurrentAddress', true))
    ),
    when(overSome([doesNotExist('insured.officeAddress')]), flow(set('insured.officeAddress', undefined))),
    when(
      overSome([
        overEvery([doesExist('payer'), doesNotExist('payer.payerAddress')]),
        isTrue('payer.payerAddress.useCurrentInsuredAddress'),
      ]),
      setDefault('payer.payerAddress', currentAddress)
    ),
    when(
      isKid,
      flow(
        set('beneficiary[0].title', get('payer.title', app)),
        set('beneficiary[0].firstName', get('payer.firstName', app)),
        set('beneficiary[0].lastName', get('payer.lastName', app)),
        set('beneficiary[0].sex', flow(get('quickQuote.insured.payer.gender'), toGenderValue)(app)),
        set('beneficiary[0].birthdate', get('quickQuote.insured.payer.birthdate.value', app)),
        set('beneficiary[0].relations', get('payer.relationshipToInsured', app)),
        set('beneficiary[0].beneficiaryAddress', get('payer.payerAddress', app))
      )
    ),
    when(
      isMRTACategory,
      flow(
        set('beneficiary[0].title', bankBeneficiary.title),
        set('beneficiary[0].firstName', bankBeneficiary.firstName),
        set('beneficiary[0].lastName', bankBeneficiary.lastName),
        set('beneficiary[0].sex', bankBeneficiary.sex),
        set('beneficiary[0].birthdate', bankBeneficiary.birthdate),
        set('beneficiary[0].relations', bankBeneficiary.relations),
        set('beneficiary[0].beneficiaryAddress', bankBeneficiary.beneficiaryAddress)
      )
    ),
    mapObject(
      'beneficiary',
      map(when(isTrue('beneficiaryAddress.useCurrentInsuredAddress'), setDefault('beneficiaryAddress', currentAddress)))
    ),
    when(
      isFalse('FATCA.checked.value'),
      flow(
        set('FATCA.relation.value', false),
        set('FATCA.birth.value', false),
        set('FATCA.permanentResidence.value', 'no'),
        set('FATCA.taxpayer.value', false),
        set('FATCA.tenant.value', false)
      )
    ),
    when(
      overSome([
        overEvery([doesExist('insured.foreigner.spouse'), doesNotExist('insured.foreigner.spouse.address')]),
        isTrue('insured.foreigner.spouse.address.useCurrentInsuredAddress'),
      ]),
      setDefault('insured.foreigner.spouse.address', currentAddress)
    ),
    omitBy(isNil)
  )(app)
}
