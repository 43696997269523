// @flow

export const rpudrInforce = (
  year: number,
  age: number,
  noLapseGuarantee: number,
  accountValuePlusBonusAtMonthEnd: number,
  basicMaximumCoverageAge: number,
  prevYear: number,
  prevMonth: number
): boolean => {
  return (
    year <= noLapseGuarantee ||
    (year > noLapseGuarantee &&
      ((accountValuePlusBonusAtMonthEnd > 0 && age < basicMaximumCoverageAge) ||
        (prevYear <= noLapseGuarantee && prevMonth === 12)))
  )
}

export const rpudrRiderInforce = (
  age: number,
  attainedAge: number,
  minimumIssueAge: number,
  maximumIssueAge: number,
  maximumCoverageAge: number,
  isBasicInforce: boolean
) => {
  const isBetweenIssueAge = age >= minimumIssueAge && age <= maximumIssueAge
  return isBetweenIssueAge && isBasicInforce ? attainedAge < maximumCoverageAge : false
}
