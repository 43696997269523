// @flow
import type { RiderEligibilities } from 'core/data-model/rider'
import type { RiderRule } from 'core/data-model/rider/rules'
import type { ValidationMessage } from 'core/data-model/validation'

import { checkAgeRule } from './age-rule'
import { checkInvalidOccupationFactorRule } from './invalid-occupation-factor-rule'
import { checkInvalidPayerOccupationFactorRule } from './invalid-payer-occupation-factor-rule'
import { checkOccupationRule } from './occupation-rule'
import { checkNatureOfDutyRule } from './nature-of-duty-rule'
import { checkFactorOfSumAssuredRule } from './factor-of-sum-assured-rule'
import { checkRiderSumAssuredRule } from './rider-sum-assured-rule'
import { checkSumAssuredTiersRule } from './sum-assured-tiers-rule'
import { checkLinkedRidersSumAssuredRule } from './linked-riders-sum-assured-rule'
import { checkCompositeRule } from './composite-rule'
import { checkWPDAgeRule } from './wpd-age-rule'
import { invalidSumAssuredRule } from './invalid-sum-assured-rule'
import { checkRiderAndBasicSumAssuredRule } from './rider-and-basic-sum-assured-rule'
import { constraintStepSumAssuredRule } from './constraint-step-sum-assured-rule'
import { checkIncompatibilitiesAgeRule } from './incompatibilities-age-rule'
import { overBasicSumassured } from './over-sum-assured-rule'
import { checkSelectedPlanRule } from './selected-plan-rule'

export const checkRule = (rule: RiderRule, eligibilities: RiderEligibilities): ValidationMessage => {
  switch (rule.type) {
    case 'AgeRule': {
      return checkAgeRule(rule, eligibilities)
    }
    case 'InvalidOccupationFactorRule': {
      return checkInvalidOccupationFactorRule(rule, eligibilities)
    }
    case 'InvalidPayerOccupationFactorRule': {
      return checkInvalidPayerOccupationFactorRule(rule, eligibilities)
    }
    case 'OccupationRule': {
      return checkOccupationRule(rule, eligibilities)
    }
    case 'NatureOfDutyRule': {
      return checkNatureOfDutyRule(rule, eligibilities)
    }
    case 'FactorOfBasicSumAssuredRule': {
      return checkFactorOfSumAssuredRule(rule, eligibilities)
    }
    case 'RiderSumAssuredRule': {
      return checkRiderSumAssuredRule(rule, eligibilities)
    }
    case 'RiderAndBasicSumassuredRule': {
      return checkRiderAndBasicSumAssuredRule(rule, eligibilities)
    }
    case 'SumAssuredTiersRule': {
      return checkSumAssuredTiersRule(rule, eligibilities)
    }
    case 'LinkedRidersSumAssuredRule': {
      return checkLinkedRidersSumAssuredRule(rule, eligibilities)
    }
    case 'CompositeRule': {
      return checkCompositeRule(rule, eligibilities)
    }
    case 'WPDAgeRule': {
      return checkWPDAgeRule(rule, eligibilities)
    }
    case 'InvalidBasicSumassured': {
      return invalidSumAssuredRule(rule, eligibilities)
    }
    case 'ConstraintStepSumAssuredRule': {
      return constraintStepSumAssuredRule(rule, eligibilities)
    }
    case 'IncompatibilitiesAge': {
      return checkIncompatibilitiesAgeRule(rule, eligibilities)
    }
    case 'overBasicSumassured': {
      return overBasicSumassured(rule, eligibilities)
    }
    case 'SelectedPlanRule': {
      return checkSelectedPlanRule(rule, eligibilities)
    }
    default:
      ;(rule.type: empty)
      throw new Error('Unknown Rule Type')
  }
}
