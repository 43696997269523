// @flow

import { isNil } from 'core/service/lib/type-check'
import _ from 'lodash'
import VALUES from 'core/data-model/constants/values'
import type { Period } from 'core/data-model/basic-plan/types'

export const calculatePeriod = (period: ?Period, age: ?number): number => {
  if (isNil(period) || isNil(age)) return 0
  let result
  switch (period.type) {
    case 'limited_to_age':
      result = period.value - age
      break
    case 'limited_by_duration':
    default:
      result = period.value
      break
  }
  return _.toFinite(result)
}

export const calculateWaivePeriod = (riderCode: string, basicPaymentPeriod: ?number, age: ?number): number => {
  let result
  switch (riderCode) {
    case VALUES.PBSDD:
    case VALUES.PBSDDCI:
      result = basicPaymentPeriod
      break
    case VALUES.PBPDD:
    case VALUES.PBPDDCI:
      result = Math.min(basicPaymentPeriod, 25 - age)
      break
    default:
      result = 0
      break
  }
  return result
}
