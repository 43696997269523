import { createSectionCreator, createField } from '../utils'

const diagnosisField = (app, id) => {
  return [
    createField(`${id}.diagnosisMRTA.checked`, [
      {
        id: `${id}.diagnosisMRTA.checked`,
        c: 'RadioButton',
        required: true,
        label:
          'ในระหว่าง 3 ปีที่ผ่านมา ท่านเคยได้รับ<br/>ก. การตรวจสุขภาพ การตรวจชิ้นเนื้อ หรือการตรวจเพื่อวินิจฉัยโรค เช่น การเอกซเรย์ การตรวจคลื่นหัวใจ การตรวจเลือด หรือการตรวจด้วยเครื่องมือพิเศษอื่น ๆ หรือไม่',
        p: {
          options: [
            {
              text: 'ไม่เคย',
              value: 'no',
            },
            {
              text: 'เคย',
              value: 'yes',
            },
          ],
        },
      },
    ]),
  ]
}

export default createSectionCreator(diagnosisField)
