// @flow

import _ from 'lodash'
import type { Age } from 'core/data-model/insured'
import type { DisplayProduct } from 'core/service/display-product'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { PolicyValue } from 'core/service/benefit-illustration'
import { hasAsteriskFromRiders } from 'core/service/rider/benefits/benefit-data'
import { renderBIAge } from 'core/service/insured/age'
import { formatNumber } from 'core/service/lib/number-format'
import SummaryTable from './summary-table'
import MESSAGES from 'core/data-model/constants/messages'
import BIMESSAGES from 'core/data-model/constants/bi-messages'

type Props = {
  displayProduct: ?DisplayProduct,
  riders: (Rider & RiderState)[],
  genderLabel: string,
  age: Age,
  calculatedCoveragePeriod: number,
  calculatedPaymentPeriod: number,
  policyValue: PolicyValue,
  sumAssured: number,
  basicPremium: number,
  totalPremium: number,
  totalRiderPremium: number,
  selectedModelFactorLabel: string,
  hasNonLevelRider: boolean,
  profileSummaryHeader: Function | string,
  summaryTableHeader: Function | string,
  showGraph?: boolean,
}

const ProfileSnapshot = ({
  displayProduct,
  riders,
  genderLabel,
  age,
  calculatedCoveragePeriod,
  calculatedPaymentPeriod,
  policyValue,
  sumAssured,
  basicPremium,
  totalPremium,
  selectedModelFactorLabel,
  hasNonLevelRider,
  totalRiderPremium,
  profileSummaryHeader,
  summaryTableHeader,
  showGraph = true,
  isReviseQQ,
}: Props) => {
  return (
    <div id="profile-summary" className="snapshot group">
      <h2>
        {_.isFunction(profileSummaryHeader)
          ? // $$FlowFixMe
            profileSummaryHeader({
              displayProduct,
              riders,
              genderLabel,
              age,
              calculatedCoveragePeriod,
              calculatedPaymentPeriod,
              sumAssured,
              basicPremium,
              totalPremium,
              selectedModelFactorLabel,
              hasNonLevelRider,
              totalRiderPremium,
            })
          : profileSummaryHeader}
      </h2>
      <div className="profile">
        <div className="info gender">
          <p className="label">{MESSAGES.GENDER}</p>
          <p className="value">{genderLabel}</p>
        </div>
        <div className="info age">
          <p className="label">{MESSAGES.AGE}</p>
          <p className="value">{renderBIAge(age, isReviseQQ)}</p>
        </div>
        <div className="info coverage-period">
          <p className="label">{BIMESSAGES.COVERAGE_PERIOD}</p>
          <p className="value">
            {calculatedCoveragePeriod} {MESSAGES.TIME_UNIT_YEAR}
          </p>
        </div>
        <div className="info payment-period">
          <p className="label">{BIMESSAGES.PREMIUM_PAYMENT_PERIOD}</p>
          <p className="value">
            {calculatedPaymentPeriod} {MESSAGES.TIME_UNIT_YEAR}
          </p>
        </div>
      </div>
      <div className="coverage">
        <div className="info sum-assured">
          <p className="label">{BIMESSAGES.BASIC_PLAN_SUM_ASSURED}</p>
          <p className="value">
            {formatNumber(sumAssured)} {MESSAGES.BAHT}
          </p>
        </div>
        <div className="info model-factor">
          <p className="label">{MESSAGES.PAYMENT_MODE}</p>
          <p className="value">{selectedModelFactorLabel}</p>
        </div>
      </div>
      <div className="coverage">
        <div className="info basic-premium">
          <p className="label">{BIMESSAGES.BASIC_PREMIUM}</p>
          <p className="value">
            {formatNumber(basicPremium, 2)} {MESSAGES.BAHT}
          </p>
        </div>
        <div className="info rider-premium">
          <p className="label">
            {BIMESSAGES.TOTAL_RIDER_PREMIUM}
            {hasAsteriskFromRiders(riders) ? '*' : ''}
          </p>
          <p className="value">
            {formatNumber(totalRiderPremium, 2)} {MESSAGES.BAHT}
            {/*{totalRiderPremium(riders)}*/}
          </p>
        </div>
        <div className="info total-premium">
          <p className="label">{BIMESSAGES.TOTAL_PREMIUM_FIRST_PREMIUM}</p>
          <p className="value">
            {formatNumber(totalPremium, 2)} {MESSAGES.BAHT}
          </p>
        </div>
      </div>

      {/* <div className="graph-IGROW">
        <GraphIGROW />
      </div> */}

      <SummaryTable
        displayProduct={displayProduct}
        riders={riders}
        age={age}
        calculatedCoveragePeriod={calculatedCoveragePeriod}
        calculatedPaymentPeriod={calculatedPaymentPeriod}
        basicPremium={basicPremium}
        totalPremium={totalPremium}
        basicSumAssured={sumAssured}
        hasNonLevelRider={hasNonLevelRider}
        summaryTableHeader={summaryTableHeader}
      />
    </div>
  )
}

export default ProfileSnapshot
