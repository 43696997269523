// @flow

import template from 'core/service/pdf-generation/products/term-life/template'
import { section } from 'core/service/pdf-generation/products/term-life/sections'
import { commonSection } from 'core/service/pdf-generation/products/common/sections'
import { createPdfDocument } from 'core/service/pdf-generation/document-creation'
import { createCommonPdf } from '../common/pdf-document'
import type { TermLifeProps } from './index'
import { basicPlanDetailsLabelFn } from 'core/service/pdf-generation/products/term-life/sections/product-benefit-section'
import VALUES from 'core/data-model/constants/values'

export const createFullBIDocDefinition = async (props: TermLifeProps) => {
  const pdfTemplate = await template({ ...props, biType: props.biType })
  const paymentChannels = await commonSection.paymentChannels(props)

  const findContentByProduct = (productCode) => {
    switch (productCode) {
      case VALUES.LIFE_SUPER_SAVE_14_5_CODE:
        return [
          ...commonSection.profile(props),
          ...commonSection.productBenefits({
            ...props,
            basicPlanDetailsLabelFn,
          }),
          ...commonSection.riders(props),
          section.basicPlanConditionGIO(),
          ...commonSection.policyGioValueTable(props),
          { text: '', pageBreak: 'after' },
          commonSection.taxGioDeductionBenefit(props),
          commonSection.legalDisclaimerMarketConductNoAddtionalContract(props),
          paymentChannels,
          commonSection.signature(props),
        ]
      case VALUES.ISMART_80_6:
        return [
          section.profileSnapshot(props),
          section.basicPlanBenefit({
            ...props,
            basicPlanDetailsLabelFn,
          }),
          ...commonSection.riders(props),
          section.basicPlanConditionISmart(),
          ...section.iSmartPolicyValueTable(props),
          { text: '', pageBreak: 'after' },
          commonSection.taxConsentDeductionBenefit(props),
          commonSection.legalDisclaimerMarketConduct(props),
          paymentChannels,
          commonSection.signature(props),
        ]
      default:
        return [
          ...commonSection.profile(props),
          ...commonSection.productBenefits({
            ...props,
            basicPlanDetailsLabelFn,
          }),
          ...commonSection.riders(props),
          section.basicPlanCondition(),
          ...section.policyValueTable(props),
          section.investmentBenefitTable(props),
          commonSection.taxConsentDeductionBenefit(props),
          commonSection.legalDisclaimerMarketConduct(props),
          paymentChannels,
          commonSection.signature(props),
        ]
    }
  }

  return {
    content: findContentByProduct(props.basicPlan.code),
    ...pdfTemplate,
  }
}

const createFullBIPdf = createPdfDocument(createFullBIDocDefinition)

export const createTermLifePdf = async (props: TermLifeProps) => {
  return await createCommonPdf(props, createFullBIPdf)
}
