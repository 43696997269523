// @flow
import type { Option } from 'common-components/dropdown'
import { Dropdown } from 'common-components'
import { FormFeedback, FormGroup } from 'reactstrap'

export type BasicPlanSelectionProps = {
  basicPlanOptions: Option[],
  selectedBasicPlanCode: string,
  basicPlanValidationError: string[],
  editSelectedHealthPlan: (string) => void,
}

const BasicPlanSelection = ({
  basicPlanOptions,
  selectedBasicPlanCode,
  basicPlanValidationError,
  editSelectedHealthPlan,
}: BasicPlanSelectionProps) => {
  return (
    <FormGroup className={`basic-plan-selection item basic ${basicPlanValidationError !== '' ? 'has-danger' : ''}`}>
      <div className="form-group-title">
        <span className="form-group-title-text">สัญญาหลัก</span>
      </div>
      <div className="options option-labels">
        <label>เลือกแผนสัญญาหลัก</label>
      </div>
      <Dropdown
        className="basic-plan form-control"
        options={basicPlanOptions}
        onChange={editSelectedHealthPlan}
        value={selectedBasicPlanCode}
      />
      {basicPlanValidationError.map((message, index) => (
        <FormFeedback className={'validation-message attached-icon error-message'} key={`${index}`}>
          <p>{message}</p>
        </FormFeedback>
      ))}
    </FormGroup>
  )
}

export default BasicPlanSelection
