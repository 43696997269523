// @flow
import _ from 'lodash'
import { splitWords } from 'core/service/pdf-generation/utils/line-wrapper'
import { Bold } from 'core/service/pdf-generation/products/common/components/general'

export type TableRow = (string | number | Object | *)[]
export type TableBody = TableRow[]
export type TableHeaderOption = {
  fillColor?: string,
  colSpan?: number,
  rowSpan?: number,
}
export type HeaderTitle = {
  text: string,
  props?: Object,
}

export const renderHeader = (
  title: string = '',
  options: TableHeaderOption = {
    fillColor: '#ffffff',
    colSpan: 1,
    rowSpan: 1,
  }
) => {
  return [
    Bold({
      text: title,
      alignment: 'center',
      fillColor: options.fillColor,
      colSpan: options.colSpan,
      rowSpan: options.rowSpan,
    }),
  ]
}

export const SimpleHeader = (title: HeaderTitle[], rowSpan: number = 3, fillColor: string = '#ffffff') => {
  const fillCount = rowSpan - 1
  const { text, props } = title
  return [[{ text: text, rowSpan, alignment: 'center', fillColor, ...props }]].concat(
    _.fill(Array(fillCount), [{ text: '', fillColor }])
  )
}

export const tableHeaderWithIndex = (
  title: HeaderTitle[],
  columnIndex: number,
  fillColor: string = '#ffffff',
  headerRowCount: number = 3
): TableBody => {
  const extra = Array(headerRowCount - 2).fill([{ text: '', fillColor }])
  const rowSpan = headerRowCount - 1
  const { text, props } = title
  return [
    [{ text: ['\n'].concat(splitWords(text)), alignment: 'center', fillColor, rowSpan, ...props }],
    ...extra,
    [{ text: `(${columnIndex})`, fillColor, alignment: 'center' }],
  ]
}

export const GroupedTableHeader = (
  mainTitle: string,
  subTitles: HeaderTitle[],
  startIndex: number,
  fillColor: string = '#ffffff',
  headerRowCount: number = 3
): TableBody => {
  const colSpan = subTitles.length
  const extra = Array(headerRowCount - 3).fill(Array(colSpan).fill({ text: '', fillColor }))
  return [
    [
      { text: mainTitle, alignment: 'center', fillColor, colSpan },
      ..._.times(colSpan - 1, _.constant({ text: '', fillColor })),
    ],
    subTitles.map((subTitle) => {
      const { text, props } = subTitle
      return {
        text: splitWords(text),
        fillColor,
        alignment: 'center',
        rowSpan: headerRowCount - 2,
        margin: [0, 0, 0, 0],
        ...props,
      }
    }),
    ...extra,
    subTitles.map((subTitle, index) => ({
      text: `(${startIndex + index})`,
      fillColor,
      alignment: 'center',
    })),
  ]
}
