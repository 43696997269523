//@flow
import type Decimal from 'decimal.js'
import _ from 'lodash'
import type { FeeStructureByYear, FeeStructure } from 'core/data-model/investment/types'

export const calculatePremiumCharge = (
  feeStructure: FeeStructureByYear[],
  policyYear: number,
  premiumValue: Decimal
): Decimal => {
  const premiumChargeForGivenYear = _.find(feeStructure, { policyYear }) || { charge: 0 }
  return premiumValue.times(premiumChargeForGivenYear.charge)
}

export const regularPremiumCharge = (
  policyYear: number,
  regularPremium: Decimal,
  feeStructure: FeeStructure
): Decimal => {
  return calculatePremiumCharge(feeStructure.regularPremiumCharge, policyYear, regularPremium)
}

export const regularTopUpCharge = (policyYear: number, regularTopUp: Decimal, feeStructure: FeeStructure): Decimal => {
  return calculatePremiumCharge(feeStructure.regularTopUpCharge, policyYear, regularTopUp)
}

export const lumpSumTopUpCharge = (lumpSum: Decimal, feeStructure: FeeStructure): Decimal =>
  lumpSum.times(feeStructure.lumpSumpCharge)

type PremiumInput = {
  regularPremium: Decimal,
  regularTopUp: Decimal,
  lumpSum: Decimal,
}

export const premiumChargeIwealthy = (feeStructure: FeeStructure) => (
  policyYear: number,
  { regularPremium, regularTopUp, lumpSum }: PremiumInput
): Decimal =>
  regularPremiumCharge(policyYear, regularPremium, feeStructure)
    .plus(regularTopUpCharge(policyYear, regularTopUp, feeStructure))
    .plus(lumpSumTopUpCharge(lumpSum, feeStructure))

// FIXME
export const premiumChargeIinvest = (feeStructure: FeeStructure) => (
  policyYear: number,
  { regularPremium }: PremiumInput
): Decimal => regularPremiumCharge(policyYear, regularPremium, feeStructure)

// //should return object
// export const premiumChargeUdr = (feeStructure: FeeStructure) => (
//   policyYear: number,
//   { regularPremium, lumpSum }: PremiumInput
// ): Decimal =>
//   regularPremiumCharge(policyYear, regularPremium, feeStructure).plus(lumpSumTopUpCharge(lumpSum, feeStructure))
