// @flow
import _ from 'lodash'
import PolicyValueRow, { dashedOutOrWithPrecision } from './policy-value-row'
import MESSAGES from 'core/data-model/constants/bi-messages'
import CONSTANTS from 'core/data-model/constants/messages'
import type { PolicyValueTableProps } from 'core/service/benefit-illustration/policy-value'
import { WordWraps } from 'common-components'

const PolicyValueTable = (props: PolicyValueTableProps) => {
  return (
    <div className="policy group" id="policy-value">
      <h3>{MESSAGES.POLICY_VALUE_TABLE}</h3>

      {renderPolicyValueTableForWholeLife(props)}
    </div>
  )
}

const PolicyTable = ({ className, children }: { className?: string, children?: React.Node }) => (
  <table className={`policy-table${className ? ` ${className}` : ''}`} id="policy-value-table">
    {children}
  </table>
)

// $$FlowFixMe
export const PolicyWithOutCashDrop = ({ yearEndPolicyValues, lastYearEndPolicy, subtotalAnnualBasicPremium }) => (
  <PolicyTable>
    <thead>
      <tr>
        <td rowSpan={2} width="5%">
          {CONSTANTS.AGE}
        </td>
        <td className="nowrap" rowSpan={2} width="5%">
          {MESSAGES.POLICY_VALUE_TABLE_INSURANCE_YEAR}
        </td>
        <td rowSpan={2}>
          {MESSAGES.PREMIUM_PER_YEAR} <br /> {MESSAGES.BASIC_PLAN}
        </td>
        <td colSpan={3}>{MESSAGES.END_OF_YEAR_POLICY}</td>
      </tr>
      <tr>
        <td width="20%">
          <WordWraps>{MESSAGES.CUMULATIVE_PREMIUM}</WordWraps>
        </td>
        <td width="20%">
          <WordWraps>{MESSAGES.POLICY_VALUE_TABLE_CASH_SURRENDER_VALUE_NORIDER}</WordWraps>
        </td>
        <td width="20%">
          <WordWraps>{MESSAGES.DEATH_COVERAGE}</WordWraps>
        </td>
      </tr>
    </thead>
    <tbody>
      {yearEndPolicyValues.map((policyValue, index) => (
        <PolicyValueRow
          key={`policy-value-row-${policyValue.age.value}`}
          age={policyValue.age}
          yearEndPolicy={policyValue.yearEndPolicy}
          cumulativeBasicPremium={policyValue.cumulativeBasicPremium}
          annualBasicPremium={policyValue.annualBasicPremium}
          surrenderCash={policyValue.surrenderCash}
          deathBenefit={policyValue.deathBenefit}
          basicSumAssured={policyValue.basicSumAssured}
        />
      ))}
      <tr className="total" id={`policy-value-row-${lastYearEndPolicy}`} key={`policy-value-row-${lastYearEndPolicy}`}>
        <td />
        <td />
        <td>{dashedOutOrWithPrecision(subtotalAnnualBasicPremium, 2)}</td>
        <td />
        <td />
        <td />
      </tr>
    </tbody>
  </PolicyTable>
)

const PolicyValueContent = ({ basicPlan, ...props }) => {
  return <PolicyWithOutCashDrop {...props} />
}

const renderPolicyValueTableForWholeLife = ({ policyValue, basicPlan }: PolicyValueTableProps) => {
  const lastYearEndPolicy = _.get(_.last(policyValue.yearEndPolicyValues), 'yearEndPolicy', 0) + 1

  return (
    <div>
      <div className="table-wrap">
        <PolicyValueContent lastYearEndPolicy={lastYearEndPolicy} basicPlan={basicPlan} {...policyValue} />
      </div>

      <div className="disclaimer">
        <div>{MESSAGES.NOTE}:</div>
        <div>
          <ul>
            <li>{MESSAGES.POLICY_VALUE_TABLE_S7_DISCLAIMER_ONE}</li>
            <li>{MESSAGES.POLICY_VALUE_TABLE_S7_DISCLAIMER_TWO}</li>
            <li>{MESSAGES.POLICY_VALUE_TABLE_S7_DISCLAIMER_THREE}</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default PolicyValueTable
