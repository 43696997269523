//@flow

export const isShowHealthCheckList = (age, sumAssuredContract) => {
  let nonCheck = false
  let cond1 = false
  let cond2 = false

  if (
    (age >= 20 && age <= 45 && sumAssuredContract <= 15000000) ||
    (age >= 46 && age <= 60 && sumAssuredContract <= 12000000) ||
    (age >= 61 && age <= 70 && sumAssuredContract <= 8000000)
  ) {
    nonCheck = true
  } else if (age >= 20 && age <= 45 && sumAssuredContract >= 15000001) {
    cond1 = true
  } else if (
    (age >= 46 && age <= 60 && sumAssuredContract >= 12000001) ||
    (age >= 61 && age <= 70 && sumAssuredContract >= 8000001)
  ) {
    cond2 = true
  }

  return { nonCheck, cond1, cond2 }
}

export const isShowFinancialCheckList = (sumAssuredContract) => {
  let nonCheck = false
  let cond1 = false
  let cond2 = false
  let cond3 = false

  if (sumAssuredContract >= 5000000 && sumAssuredContract <= 10000000) {
    cond1 = true
  } else if (sumAssuredContract > 10000000 && sumAssuredContract <= 20000000) {
    cond2 = true
  } else if (sumAssuredContract > 20000000) {
    cond3 = true
  } else {
    nonCheck = true
  }

  return { nonCheck, cond1, cond2, cond3 }
}
