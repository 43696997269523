// @flow

import React from 'react'
import styled from 'styled-components'
import Mustache from 'mustache'
import RiderWithBenefitModal from './rider-benefit-modal'
import RiderBenefitSummary from './rider-benefit-summary'
import MESSAGES from 'core/data-model/constants/bi-messages'
import RiderList from './rider-list'
import { WordWraps } from 'common-components'

const BoldStyled = styled.div`
  font-weight: bold;
`
const IndentStyled = styled.div`
  text-indent: 2rem;
`

const CenterStyled = styled.div`
  text-align: center;
`
const UnderlineStyled = styled.div`
  display: inline-block;
  text-decoration: underline;
`
export type HSUDRRiderBenefitProps = {
  rider: Rider & RiderState,
  riderBenefitData: Benefit,
}

export type NoticeProps = {
  title: string,
  contents: {
    text: string,
    list: string[],
  },
  noticePeriod: {
    text: string,
  },
}

export type ListText = {
  text: string,
}

export const findDataOnListByRiderCode = (contents, rider) => {
  return contents.find((content) => content.planCode === rider.selectedPlan.planCode)
    ? contents.find((content) => content.planCode === rider.selectedPlan.planCode).value
    : ''
}

const renderHSUDRRiderBenefitsTable = (contents: Benefit[], rider) => {
  return (
    <table id="hsudr-benefit-table">
      <thead>
        <tr>
          <td>{MESSAGES.BENEFIT_TITLE}</td>
          <td>{MESSAGES.BENEFIT_BAHT}</td>
          <td>{MESSAGES.RIDER_BENEFIT_DAY_OR_TIME}</td>
          <td>{MESSAGES.RIDER_TOTAL_BENEFIT_BAHT}</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan={4}>
            <BoldStyled>{contents[0].text[0]}</BoldStyled>
          </td>
        </tr>
        <tr>
          <td>
            <BoldStyled>{contents[1].text[0]}</BoldStyled>
            <IndentStyled>{contents[1].text[1]}</IndentStyled>
          </td>
          <td>
            <CenterStyled>
              {findDataOnListByRiderCode(contents[1].benefit, rider)} {contents[1].suffixs[0].replace(/\n/g, '')}
            </CenterStyled>
          </td>
          <td>
            <CenterStyled>
              {contents[1].totalTimes[0]} {contents[1].suffixs[1]} <br /> {contents[1].suffixs[2].replace(/\n/g, '')}
            </CenterStyled>
          </td>
          <td>
            <CenterStyled>{findDataOnListByRiderCode(contents[1].totalBenefit, rider)} </CenterStyled>
          </td>
        </tr>
        <tr>
          <td colSpan={3}>
            <BoldStyled>{contents[2].text[0]}</BoldStyled>
          </td>
          <td rowSpan={5}>
            <CenterStyled>
              {findDataOnListByRiderCode(contents[2].totalBenefit, rider)} <br />{' '}
              {contents[2].suffixs[2].replace(/\n/g, '')}
            </CenterStyled>
          </td>
        </tr>
        <tr>
          <td>{contents[2].text[1]}</td>
          <td rowSpan={3}>
            <CenterStyled>{contents[2].benefit.firstBenefit}</CenterStyled>
          </td>
          <td rowSpan={3}>
            <CenterStyled>{contents[2].totalTimes[0]}</CenterStyled>
          </td>
        </tr>
        <tr>
          <td>{contents[2].text[2]}</td>
        </tr>
        <tr>
          <td>{contents[2].text[3]}</td>
        </tr>
        <tr>
          <td>{contents[2].text[4]}</td>
          <td>
            <CenterStyled>
              {findDataOnListByRiderCode(contents[2].benefit.secondBenefit, rider)}{' '}
              {contents[2].suffixs[1].replace(/\n/g, '')}
            </CenterStyled>
          </td>
          <td>
            <CenterStyled>
              {contents[2].totalTimes[1]} {contents[2].suffixs[1].replace(/\n/g, '')}
            </CenterStyled>
          </td>
        </tr>
        <tr>
          <td>
            <BoldStyled>{contents[3].text[0]}</BoldStyled>
          </td>
          <td>
            <CenterStyled>
              {findDataOnListByRiderCode(contents[3].benefit, rider)} {contents[3].suffixs[0].replace(/\n/g, '')}
            </CenterStyled>
          </td>
          <td>
            <CenterStyled>
              {contents[3].totalTimes[0]} {contents[3].suffixs[1].replace(/\n/g, '')}
            </CenterStyled>
          </td>
          <td>
            <CenterStyled>{findDataOnListByRiderCode(contents[3].totalBenefit, rider)}</CenterStyled>
          </td>
        </tr>
        {/* #4 */}
        <tr>
          <td colSpan={3}>
            <BoldStyled>{contents[4].text[0]}</BoldStyled>
          </td>
          <td rowSpan={6}>
            <CenterStyled>
              {findDataOnListByRiderCode(contents[4].totalBenefit, rider)} <br />{' '}
              {contents[4].suffixs[0].replace(/\n/g, '')}
            </CenterStyled>
          </td>
        </tr>
        <tr>
          <td>{contents[4].text[1]}</td>
          <td rowSpan={5}>
            <CenterStyled>{contents[4].benefit[0]}</CenterStyled>
          </td>
          <td rowSpan={5}>
            <CenterStyled>{contents[4].totalTimes[0]}</CenterStyled>
          </td>
        </tr>
        <tr>
          <td>{contents[4].text[2]}</td>
        </tr>
        <tr>
          <td>{contents[4].text[3]}</td>
        </tr>
        <tr>
          <td>{contents[4].text[4]}</td>
        </tr>
        <tr>
          <td>{contents[4].text[5]}</td>
        </tr>
        <tr>
          <td>
            <BoldStyled>{contents[5].text[0]}</BoldStyled>
          </td>
          <td>
            <CenterStyled>{contents[5].benefit[0]}</CenterStyled>
          </td>
          <td>
            <CenterStyled>{contents[5].totalTimes[0]}</CenterStyled>
          </td>
          <td>
            <CenterStyled>{contents[5].suffixs[0].replace(/\n/g, '')}</CenterStyled>
          </td>
        </tr>
        <tr>
          <td colSpan={4}>
            <BoldStyled>{contents[6].text[0]}</BoldStyled>
          </td>
        </tr>
        <tr>
          <td colSpan={4}>
            <BoldStyled>{contents[7].text[0]}</BoldStyled>
          </td>
        </tr>
        {/* #6.1 */}
        <tr>
          <td>{contents[8].text[0].replace(/\n/g, '')}</td>
          <td>
            <CenterStyled>{contents[8].benefit[0]}</CenterStyled>
          </td>
          <td>
            <CenterStyled>{contents[8].totalTimes[0]}</CenterStyled>
          </td>
          <td>
            <CenterStyled>
              {findDataOnListByRiderCode(contents[4].totalBenefit, rider)} {contents[8].suffixs[0].replace(/\n/g, '')}
            </CenterStyled>
          </td>
        </tr>
        <tr>
          <td>
            {contents[9].text[0]}
            <UnderlineStyled>{contents[9].text[1]}</UnderlineStyled>
          </td>
          <td>
            <CenterStyled>{contents[9].benefit[0]}</CenterStyled>
          </td>
          <td>
            <CenterStyled>{contents[9].totalTimes[0]}</CenterStyled>
          </td>
          <td>
            <CenterStyled>
              {findDataOnListByRiderCode(contents[2].totalBenefit, rider)} {contents[9].suffixs[0].replace(/\n/g, '')}
            </CenterStyled>
          </td>
        </tr>
        <tr>
          <td>
            <BoldStyled>{contents[10].text[0]}</BoldStyled>
          </td>
          <td>
            <CenterStyled>{contents[10].benefit[0]}</CenterStyled>
          </td>
          <td>
            <CenterStyled>{contents[10].totalTimes[0]}</CenterStyled>
          </td>
          <td>
            <CenterStyled>{contents[10].suffixs[0].replace(/\n/g, '')}</CenterStyled>
          </td>
        </tr>
        <tr>
          <td>
            <BoldStyled>{contents[11].text[0]}</BoldStyled>
          </td>
          <td>
            <CenterStyled>{contents[11].benefit[0]}</CenterStyled>
          </td>
          <td>
            <CenterStyled>{contents[11].totalTimes[0]}</CenterStyled>
          </td>
          <td>
            <CenterStyled>{contents[11].totalBenefit[0]}</CenterStyled>
          </td>
        </tr>
        {/* #9 */}
        <tr>
          <td>
            <BoldStyled>
              <WordWraps>{contents[12].text[0].replace(/\n/g, '')}</WordWraps>
            </BoldStyled>
          </td>
          <td rowSpan={3}>
            <CenterStyled>{contents[12].benefit[0]}</CenterStyled>
          </td>
          <td rowSpan={3}>
            <CenterStyled>{contents[12].totalTimes[0]}</CenterStyled>
          </td>
          <td rowSpan={3}>
            <CenterStyled>
              {findDataOnListByRiderCode(contents[12].totalBenefit, rider)} {contents[12].suffixs[0].replace(/\n/g, '')}
            </CenterStyled>
          </td>
        </tr>
        <tr>
          <td>
            <BoldStyled>{contents[12].text[1].replace(/\n/g, '')}</BoldStyled>
          </td>
        </tr>
        <tr>
          <td>
            <BoldStyled>
              <WordWraps>{contents[12].text[2]}</WordWraps>
            </BoldStyled>
          </td>
        </tr>
        {/* #12 */}
        <tr>
          <td>
            <BoldStyled>{contents[13].text[0]}</BoldStyled>
          </td>
          <td>
            <CenterStyled>{findDataOnListByRiderCode(contents[13].benefit, rider)}</CenterStyled>
          </td>
          <td>
            <CenterStyled>{contents[13].totalTimes[0]}</CenterStyled>
          </td>
          <td>
            <CenterStyled>
              {findDataOnListByRiderCode(contents[13].totalBenefit, rider)} {contents[13].suffixs[0].replace(/\n/g, '')}
            </CenterStyled>
          </td>
        </tr>
        <tr>
          <td>
            <BoldStyled>{contents[14].text[0]}</BoldStyled>
          </td>
          <td>
            <CenterStyled>
              {findDataOnListByRiderCode(contents[14].benefit, rider)}
              <br />
              {contents[14].suffixs[0]}
            </CenterStyled>
          </td>
          <td>
            <CenterStyled>{contents[14].totalTimes[0]}</CenterStyled>
          </td>
          <td>
            <CenterStyled>
              {findDataOnListByRiderCode(contents[14].totalBenefit, rider)}
              <br />
              {contents[14].suffixs[1].replace(/\n/g, '')}
            </CenterStyled>
          </td>
        </tr>
        <tr>
          <td>
            <BoldStyled>{contents[15].text[0]}</BoldStyled>
          </td>
          <td colSpan={3}>{''}</td>
        </tr>
        <tr>
          <td>{contents[16].text[0]}</td>
          <td>
            <CenterStyled>{contents[16].benefit[0]}</CenterStyled>
          </td>
          <td>
            <CenterStyled>{contents[16].totalTimes[0]}</CenterStyled>
          </td>
          <td>
            <CenterStyled>{contents[16].totalBenefit[0]}</CenterStyled>
          </td>
        </tr>
        <tr>
          <td>{contents[17].text[0]}</td>
          <td>
            <CenterStyled>{contents[17].benefit[0]}</CenterStyled>
          </td>
          <td>
            <CenterStyled>{contents[17].totalTimes[0]}</CenterStyled>
          </td>
          <td>
            <CenterStyled>{contents[17].totalBenefit[0]}</CenterStyled>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

const RenderBenefits = (props) => {
  const { content, rider } = props
  const { contents } = content
  return (
    <div className="hsudr-rider-content hsudr-rider-content--benefit">
      <p className="heading">{content.title}</p>
      <p className="description">{content.description.join(' ')}</p>
      <p className="heading">{content.subject}</p>
      {renderHSUDRRiderBenefitsTable(contents, rider)}
    </div>
  )
}

const RenderEndorse = (props) => {
  const { content, rider } = props
  const { contents } = content
  return (
    <div className="hsudr-rider-content hsudr-rider-content--endorse">
      <p className="heading">{content.title}</p>
      {renderHSUDRRiderEndorseTable(contents, rider)}
    </div>
  )
}

const renderHSUDRRiderEndorseTable = (contents: Benefit[], rider) => {
  return (
    <table id="hsudr-endorsement-table">
      <thead>
        <tr>
          <td>{MESSAGES.BENEFIT_TITLE}</td>
          <td>{MESSAGES.BENEFIT_BAHT}</td>
          <td>{MESSAGES.RIDER_BENEFIT_DAY_OR_TIME}</td>
          <td>{MESSAGES.RIDER_TOTAL_BENEFIT_BAHT}</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <BoldStyled>{contents[0].text[0]}</BoldStyled>
          </td>
          <td>
            <CenterStyled>
              {findDataOnListByRiderCode(contents[0].benefit, rider)} {contents[0].suffixs[0]}
            </CenterStyled>
          </td>
          <td>
            <CenterStyled>{contents[0].totalTimes[0]}</CenterStyled>
          </td>
          <td>
            <CenterStyled>{findDataOnListByRiderCode(contents[0].totalBenefit, rider)} </CenterStyled>
          </td>
        </tr>
        <tr>
          <td>
            <BoldStyled>{contents[1].text[0]}</BoldStyled>
          </td>
          <td>
            <CenterStyled>{contents[1].benefit[0]}</CenterStyled>
          </td>
          <td>
            <CenterStyled>{contents[1].totalTimes[0]}</CenterStyled>
          </td>
          <td>
            <CenterStyled>{findDataOnListByRiderCode(contents[1].totalBenefit, rider)}</CenterStyled>
          </td>
        </tr>
        <tr>
          <td>
            <BoldStyled>{contents[2].text[0]}</BoldStyled>
          </td>
          <td>
            <CenterStyled>{findDataOnListByRiderCode(contents[2].benefit, rider)}</CenterStyled>
          </td>
          <td>
            <CenterStyled>{contents[2].totalTimes[0]}</CenterStyled>
          </td>
          <td>
            <CenterStyled>{findDataOnListByRiderCode(contents[2].totalBenefit, rider)} </CenterStyled>
          </td>
        </tr>
        <tr>
          <td colSpan={4}>
            <BoldStyled>{contents[3].text[0]}</BoldStyled>
          </td>
        </tr>
        <tr>
          <td>{contents[4].text[0]}</td>
          <td>
            <CenterStyled>{contents[4].benefit[0]}</CenterStyled>
          </td>
          <td>
            <CenterStyled>{contents[4].totalTimes[0]}</CenterStyled>
          </td>
          <td>
            <CenterStyled>{findDataOnListByRiderCode(contents[4].totalBenefit, rider)}</CenterStyled>
          </td>
        </tr>
        <tr>
          <td>{contents[5].text[0]}</td>
          <td>
            <CenterStyled>{contents[5].benefit[0]}</CenterStyled>
          </td>
          <td>
            <CenterStyled>{contents[5].totalTimes[0]}</CenterStyled>
          </td>
          <td>
            <CenterStyled>{findDataOnListByRiderCode(contents[5].totalBenefit, rider)} </CenterStyled>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

const withNewLine = (s) => {
  return s.split('\n').map((item, key) => (
    <span key={key}>
      {item}
      <br />
    </span>
  ))
}

const renderNotice = (notice: NoticeProps) => {
  const { contents: item, noticePeriod } = notice
  return (
    <div className="list-container list-container--notice">
      <ol>
        <li>
          {item[0].text}
          <ol data-nested="true">
            <li>{item[0].list[0].text}</li>
            <li>
              {item[0].list[1].text}
              <div data-col="true" data-layout="left-to-right">
                {item[0].list[1].list.map((lv1, lv1Index) => (
                  <div key={lv1Index}>- {lv1}</div>
                ))}
              </div>
            </li>
            <li>
              {withNewLine(item[0].list[2].text)}
              <div>
                {item[0].list[2].list.map((lv1, lv1Index) => (
                  <div key={lv1Index}>
                    {lv1Index + 1}. {lv1}
                  </div>
                ))}
              </div>
            </li>
          </ol>
        </li>
        <li>
          {item[1].text}
          <ol>
            {item[1].list.map((lv1, lv1Index) => (
              <li key={lv1Index}>{lv1}</li>
            ))}
          </ol>
        </li>
        <li>
          {item[2].text}
          <ol>
            {item[2].list.map((lv1, lv1Index) => (
              <li key={lv1Index}>{lv1}</li>
            ))}
          </ol>
        </li>
        <li>{item[3].text}</li>
      </ol>
      <p></p>
      <p className="disclaimer">{noticePeriod.text}</p>
    </div>
  )
}

const RenderNotice = (props) => {
  const { content } = props

  return (
    <div>
      <div className="hsudr-rider-content hsudr-rider-content--notice">
        <p className="heading">{content.title}</p>
        <p className="description">{content.description}</p>
        {renderNotice(content)}
      </div>
    </div>
  )
}

// const renderNoticeNotes = (contents) => {
//   return (
//     <div id="list-container list-container--disclaimer">
//       <ol id="disclaimer">
//         <li>{contents[0].text}</li>
//         <li>
//           <div>{contents[1].text}</div>
//           <div className="meh-diseases more-detail">
//             <div>
//               <ul>{renderListItems(contents[1].list, 0, 4)}</ul>
//             </div>
//             <div>
//               <ul>{renderListItems(contents[1].list, 4, 8)}</ul>
//             </div>
//           </div>
//         </li>
//         <li>
//           <div>{contents[2].text}</div>
//           <div>{contents[3].text}</div>
//           <ol className="more-detail">
//             <li>{contents[3].list[0]}</li>
//             <li>{contents[3].list[1]}</li>
//           </ol>
//         </li>
//         <li>
//           <div>{contents[4].text}</div>
//           <div className="more-detail">
//             <ul className="list">{renderListText(contents[4].list)}</ul>
//           </div>
//         </li>
//         <li>
//           <div>{contents[5].text}</div>
//           <div className="more-detail">
//             <ul className="list">{renderListText(contents[5].list)}</ul>
//           </div>
//         </li>
//         <li>
//           <div>{contents[6].text}</div>
//         </li>
//       </ol>
//       <div>{contents[7].text}</div>
//     </div>
//   )
// }

// const renderListText = (data: string[]) => {
//   return data.map((item, index) => {
//     return <li key={`data_${index}`}>{item}</li>
//   })
// }

const renderDisclaimerList = (list: ListText[]) => {
  return (
    <RiderList
      start={1}
      name="disclaimer"
      items={list}
      render={(item, index) => (
        <div>
          <div key={index}>{item.text}</div>
          {item.conditions &&
            item.conditions.map((condition) => {
              return <div>{condition.replace(/\n/g, '')}</div>
            })}
        </div>
      )}
    />
  )
}

const renderCriticalList = (list: ListText[]) => {
  return (
    <RiderList
      start={1}
      name="critical"
      items={list}
      render={(item, index) => (
        <div>
          <div key={index}>{item.text}</div>
        </div>
      )}
    />
  )
}

const RenderDisclaimer = (props) => {
  const { content } = props
  return (
    <div className="hsudr-rider-content hsudr-rider-content--disclaimer">
      <p className="heading">{content.title}</p>
      <p className="description">{content.description}</p>
      {renderDisclaimerList(content.list)}
    </div>
  )
}

const RenderCritical = (props) => {
  const { content } = props
  return (
    <div className="hsudr-rider-content hsudr-rider-content--critical">
      <p className="heading">{content.title}</p>
      {renderCriticalList(content.list)}
    </div>
  )
}

const RenderYearlyBenefit = (props) => {
  const { content, rider } = props
  return (
    <div className="hsudr-rider-content hsudr-rider-content--disclaimer">
      <p className="heading">
        {Mustache.render(content.title, {
          value: findDataOnListByRiderCode(content.values, rider),
        })}
      </p>
      <p className="description">{content.description}</p>
    </div>
  )
}

const RenderTeritoryCondition = (props) => {
  const { content } = props
  return (
    <div className="hsudr-rider-content hsudr-rider-content--disclaimer">
      <p className="heading">{content.title}</p>
      <p className="description">{content.description}</p>
    </div>
  )
}

const HSUDRRiderBenefit = (props: HSUDRRiderBenefitProps) => {
  const { rider, riderBenefitData } = props
  const title = `${rider.description} ${rider.name}`
  const riderName = rider.name.toLowerCase()

  const { benefits, endorse, notice, disclaimer, criticalList, yearlyBenefit, territoryCondition } = riderBenefitData

  const benefitInfo = benefits && (
    <div className="hsudr-details-table">
      <RenderBenefits content={benefits} rider={rider} />
      <RenderEndorse content={endorse} rider={rider} />
    </div>
  )

  return (
    <RiderWithBenefitModal
      id={`${riderName}-rider-benefit`}
      className={`rider ${riderName}-rider`}
      title={title}
      modalChildren={benefitInfo}
    >
      <RiderBenefitSummary rider={rider} />
      {benefitInfo}
      {territoryCondition && <RenderTeritoryCondition content={territoryCondition} />}
      {yearlyBenefit && <RenderYearlyBenefit content={yearlyBenefit} rider={rider} />}
      {notice && <RenderNotice content={notice} />}
      {disclaimer && <RenderDisclaimer content={disclaimer} />}
      {criticalList && <RenderCritical content={criticalList} />}
    </RiderWithBenefitModal>
  )
}

export default HSUDRRiderBenefit
