//@flow
import _ from 'lodash'
import { getToggles } from 'quick-quote/feature-toggles'
import { getMEHExceptionNotes, getMEHExceptionNotes32 } from 'core/service/rider/benefits/exception-notes'
import { buildHeaderTable } from 'core/service/pdf-generation/utils/builder'

import MESSAGES from 'core/data-model/constants/bi-messages'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { RiderBenefitData } from 'core/data-model/rider/benefits'

type MEHBenefitProps = {
  rider: Rider & RiderState,
  riderBenefitData: RiderBenefitData,
}

type Benefit = {
  description: string,
  value: string,
}

export const buildSubBenefitContent = (subBenefits: Benefit[]) => {
  let result = []
  subBenefits.forEach((subBenefit) => {
    const value = { text: subBenefit.value, style: 'tableValue' }
    const description = subBenefit.description
    result.push([description, value])
  })
  return result
}

export const MEHBenefitSection = ({ rider, riderBenefitData }: MEHBenefitProps) => {
  const planName = _.get(rider, 'selectedPlan.plan')
  const headers = [MESSAGES.BENEFIT_LIST, `${MESSAGES.BENEFIT_TITLE} (${planName})`]
  const benefits = _.get(riderBenefitData, 'benefits')
  const exceptionNotes = getToggles().ENABLE_NEW_MEH_PDF_EXCLUSION_TERM
    ? buildExceptionNotes32(rider)
    : buildExceptionNotes(rider)

  return [
    {
      table: {
        headerRows: 1,
        widths: ['*', 'auto'],
        body: [buildHeaderTable(headers), ...benefits.map((benefit) => buildMEHBenefitsRow(benefit))],
      },
      layout: 'lightBorder',
      style: 'table',
    },
    buildDisclaimer,
    { ...exceptionNotes, pageBreak: 'before' },
  ]
}

const buildExceptionNotes32 = (rider) => {
  const exceptionNotes = getMEHExceptionNotes32()
  const exceptionTitle = exceptionNotes.title
  const [
    normalCondition,
    firstException,
    secondException,
    extendExceptionTitle,
    extendExceptionDesc,
  ] = exceptionNotes.exceptions

  return {
    stack: [
      {
        text: `${exceptionTitle} ${rider.description}`,
        style: 'sub-title',
      },
      {
        text: `${normalCondition}`,
        style: 'sub-title',
      },
      {
        ol: [
          firstException,
          {
            stack: [
              secondException[0],
              {
                columns: [
                  {
                    ul: secondException[1].slice(0, 4),
                    width: 255,
                  },
                  {
                    ul: secondException[1].slice(4, 8),
                    width: '*',
                  },
                ],
                columnGap: 15,
                margin: [5, 0, 0, 0],
              },
            ],
          },
        ],
        margin: [0, 0, 0, 30],
      },
      {
        text: `${extendExceptionTitle}`,
        style: 'sub-title',
      },
      {
        text: `${extendExceptionDesc[0]}`,
      },
      {
        ol: [...extendExceptionDesc[1]],
      },
      {
        text: exceptionNotes.condition,
        style: 'disclaimer',
        margin: [0, 5, 0, 0],
      },
    ],
  }
}

const buildExceptionNotes = (rider) => {
  const exceptionNotes = getMEHExceptionNotes()
  const [firstException, secondException, ...restException] = exceptionNotes.exceptions
  return {
    stack: [
      {
        text: `${MESSAGES.IMPORTANT_EXCEPTION_NOTE} ${rider.description}`,
        style: 'sub-title',
      },
      {
        ol: [
          firstException,
          {
            stack: [
              secondException[0],
              {
                columns: [
                  {
                    ul: secondException[1].slice(0, 4),
                    width: 255,
                  },
                  {
                    ul: secondException[1].slice(4, 8),
                    width: '*',
                  },
                ],
                columnGap: 15,
                margin: [5, 0, 0, 0],
              },
            ],
          },
          ...restException,
        ],
      },
      {
        text: exceptionNotes.condition,
        style: 'disclaimer',
        margin: [0, 5, 0, 0],
      },
    ],
  }
}

const buildMEHBenefitsRow = (benefit: Benefit) => {
  if (benefit.value === '') {
    return [{ colSpan: 2, text: benefit.description }, {}]
  }
  return [[benefit.description], `${benefit.value}`]
}

const buildDisclaimer = () => ({
  stack: [
    `${MESSAGES.BENEFIT_MEH_DISCLAIMER_LINE_01}`,
    `${MESSAGES.BENEFIT_MEH_DISCLAIMER_LINE_02}`,
    `${MESSAGES.BENEFIT_MEH_DISCLAIMER_LINE_03}`,
    `${MESSAGES.BENEFIT_MEH_DISCLAIMER_LINE_04}`,
  ],
  style: 'disclaimer',
})
