// @flow

import _ from 'lodash'
import type { Age } from 'core/data-model/insured'
import type { DisplayProduct } from 'core/service/display-product'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { PolicyValue } from 'core/service/benefit-illustration'
import { hasAsteriskFromRiders } from 'core/service/rider/benefits/benefit-data'
import { renderBIAge } from 'core/service/insured/age'
import { formatNumber } from 'core/service/lib/number-format'
import SummaryTable from './summary-table'
import MESSAGES from 'core/data-model/constants/messages'
import BIMESSAGES from 'core/data-model/constants/bi-messages'

import WholeLife25PLBenefits from 'quick-quote/product-whole-life/benefit-illustration/components/product-benefits/product-benefits-whole-life-25pl'

import Graph12PL from 'assets/images/product-benefits/Graph-12PL.svg'
import Graph25P15 from 'assets/images/product-benefits/Graph-25P15.svg'
import Graph20P10 from 'assets/images/product-benefits/Graph-20P10.svg'
import Graph25P15S from 'assets/images/product-benefits/Graph-25P15S.svg'
import Graph20P10S from 'assets/images/product-benefits/Graph-20P10S.svg'
import GraphIProtect85 from 'assets/images/product-benefits/Graph-WLNP85.svg'
import GraphPLB05 from 'assets/images/product-benefits/Graph-PLB05.svg'
import GraphPLB10 from 'assets/images/product-benefits/Graph-PLB10.svg'
import GraphPLB12 from 'assets/images/product-benefits/Graph-PLB12.svg'
import GraphPLB15 from 'assets/images/product-benefits/Graph-PLB15.svg'
import GraphLR05 from 'assets/images/product-benefits/Graph-LR05.svg'
import GraphCIWLM39 from 'assets/images/product-benefits/Graph-CIWLM39.svg'
import GraphCIWLM50 from 'assets/images/product-benefits/Graph-CIWLM50.svg'
import GraphCIWLM60 from 'assets/images/product-benefits/Graph-CIWLM60.svg'
import GraphCIWLS39 from 'assets/images/product-benefits/Graph-CIWLS39.svg'
import GraphCIWLS50 from 'assets/images/product-benefits/Graph-CIWLS50.svg'
import GraphCIWLS65 from 'assets/images/product-benefits/Graph-CIWLS65.svg'
import GraphW99FU06 from 'assets/images/product-benefits/Graph-W99FU06.svg'
import GraphW99FU12 from 'assets/images/product-benefits/Graph-W99FU12.svg'
import GraphW99FU18 from 'assets/images/product-benefits/Graph-W99FU18.svg'
import GraphW99FH18 from 'assets/images/product-benefits/Graph-W99FH18.svg'
import GraphW99FU99 from 'assets/images/product-benefits/Graph-W99FU99.svg'
import GraphW99FH99 from 'assets/images/product-benefits/Graph-W99FH99.svg'
import GraphW99F99H from 'assets/images/product-benefits/Graph-W99F99H.svg'
import GraphW99F99M from 'assets/images/product-benefits/Graph-W99F99M.svg'
import GraphH99F06A from 'assets/images/product-benefits/Graph-H99F06A.svg'
import GraphH99F12A from 'assets/images/product-benefits/Graph-H99F12A.svg'
import GraphH99F18A from 'assets/images/product-benefits/Graph-H99F18A.svg'

export type ProfileSnapshotProps = {
  displayProduct: ?DisplayProduct,
  riders: (Rider & RiderState)[],
  genderLabel: string,
  age: Age,
  calculatedCoveragePeriod: number,
  calculatedPaymentPeriod: number,
  policyValue: PolicyValue,
  sumAssured: number,
  basicPremium: number,
  totalPremium: number,
  totalRiderPremium: number,
  selectedModelFactorLabel: string,
  hasNonLevelRider: boolean,
  hasShowDisclaimerRiderAsterisk: boolean,
  profileSummaryHeader: Function | string,
  summaryTableHeader: Function | string,
  showGraph?: boolean,
  selectedModelFactorID: string,
  isNewAsteriskOfSummaryMainContract: boolean,
}

const renderGraph = (basicPlanCode: string, code: string) => {
  const className = `graph-${basicPlanCode}`
  return (
    <div className={className}>
      {(() => {
        switch (basicPlanCode) {
          case '20P10':
            return <Graph20P10 />
          case '25P15':
            return <Graph25P15 />
          case '20P10S':
            return <Graph20P10S />
          case '25P15S':
            return <Graph25P15S />
          case '12PL':
            return <Graph12PL />
          case 'WLNP85':
            return <GraphIProtect85 />
          case 'PLB05':
            return <GraphPLB05 />
          case 'PLB10':
            return <GraphPLB10 />
          case 'PLB12':
            return <GraphPLB12 />
          case 'PLB15':
            return <GraphPLB15 />
          case 'LR05':
            return <GraphLR05 />
          case 'CIWLM39':
            return <GraphCIWLM39 />
          case 'CIWLM50':
            return <GraphCIWLM50 />
          case 'CIWLM60':
            return <GraphCIWLM60 />
          case 'CIWLS39':
            return <GraphCIWLS39 />
          case 'CIWLS50':
            return <GraphCIWLS50 />
          case 'CIWLS65':
            return <GraphCIWLS65 />
          case 'W99FU06':
            return <GraphW99FU06 />
          case 'W99FU12':
            return <GraphW99FU12 />
          case 'W99FU18':
            return <GraphW99FU18 />
          case 'W99FH18':
            return <GraphW99FH18 />
          case 'W99FU99':
            return <GraphW99FU99 />
          case 'W99FH99':
            return <GraphW99FH99 />
          case 'W99F99H':
            return <GraphW99F99H />
          case 'W99F99M':
            return <GraphW99F99M />
          case 'H99F06A':
            return <GraphH99F06A />
          case 'H99F12A':
            return <GraphH99F12A />
          case 'H99F18A':
            return <GraphH99F18A />
        }
      })()}
    </div>
  )
}

const renderProductBenefits = (displayProduct: DisplayProduct, policyValue: PolicyValue, sumAssured: number) => {
  const basicPlanCode = _.get(displayProduct, 'basicPlanCode')

  if (basicPlanCode === '25PL') {
    return (
      <div className="d-md-none d-lg-none">
        <WholeLife25PLBenefits displayProduct={displayProduct} policyValue={policyValue} sumAssured={sumAssured} />
      </div>
    )
  }

  return ''
}

const ProfileSnapshot = ({
  displayProduct,
  riders,
  genderLabel,
  age,
  calculatedCoveragePeriod,
  calculatedPaymentPeriod,
  policyValue,
  sumAssured,
  basicPremium,
  totalPremium,
  selectedModelFactorLabel,
  hasNonLevelRider,
  hasShowDisclaimerRiderAsterisk,
  totalRiderPremium,
  profileSummaryHeader,
  summaryTableHeader,
  showGraph = true,
  selectedModelFactorID,
  isNewAsteriskOfSummaryMainContract,
  isReviseQQ,
}: ProfileSnapshotProps) => {
  return (
    <div id="profile-summary" className="snapshot group">
      <h2>
        {_.isFunction(profileSummaryHeader)
          ? // $$FlowFixMe
            profileSummaryHeader({
              displayProduct,
              riders,
              genderLabel,
              age,
              calculatedCoveragePeriod,
              calculatedPaymentPeriod,
              sumAssured,
              basicPremium,
              totalPremium,
              selectedModelFactorLabel,
              hasNonLevelRider,
              totalRiderPremium,
            })
          : profileSummaryHeader}
      </h2>
      <div className="profile">
        <div className="info gender">
          <p className="label">{MESSAGES.GENDER}</p>
          <p className="value">{genderLabel}</p>
        </div>
        <div className="info age">
          <p className="label">{MESSAGES.AGE}</p>
          <p className="value">{renderBIAge(age, isReviseQQ)}</p>
        </div>
        <div className="info coverage-period">
          <p className="label">{BIMESSAGES.COVERAGE_PERIOD}</p>
          <p className="value">
            {calculatedCoveragePeriod} {MESSAGES.TIME_UNIT_YEAR}
          </p>
        </div>
        <div className="info payment-period">
          <p className="label">{BIMESSAGES.PREMIUM_PAYMENT_PERIOD}</p>
          <p className="value">
            {selectedModelFactorID === 'model-factor_5'
              ? selectedModelFactorLabel
              : calculatedPaymentPeriod + ' ' + MESSAGES.TIME_UNIT_YEAR}
          </p>
        </div>
      </div>
      <div className="coverage">
        <div className="info sum-assured">
          <p className="label">{BIMESSAGES.BASIC_PLAN_SUM_ASSURED}</p>
          <p className="value">
            {formatNumber(sumAssured)} {MESSAGES.BAHT}
          </p>
        </div>
        <div className="info model-factor">
          <p className="label">{MESSAGES.PAYMENT_MODE}</p>
          <p className="value">{selectedModelFactorLabel}</p>
        </div>
      </div>
      <div className="coverage">
        <div className="info basic-premium">
          <p className="label">{BIMESSAGES.BASIC_PREMIUM}</p>
          <p className="value">
            {formatNumber(basicPremium, 2)} {MESSAGES.BAHT}
          </p>
        </div>
        <div className="info rider-premium">
          <p className="label">
            {BIMESSAGES.TOTAL_RIDER_PREMIUM}
            {hasAsteriskFromRiders(riders) ? '*' : ''}
          </p>
          <p className="value">
            {formatNumber(totalRiderPremium, 2)} {MESSAGES.BAHT}
            {/*{totalRiderPremium(riders)}*/}
          </p>
        </div>
        <div className="info total-premium">
          <p className="label">{BIMESSAGES.TOTAL_PREMIUM_FIRST_PREMIUM}</p>
          <p className="value">
            {formatNumber(totalPremium, 2)} {MESSAGES.BAHT}
          </p>
        </div>
      </div>
      {showGraph && displayProduct ? renderGraph(displayProduct.basicPlanCode, _.get(displayProduct, 'code')) : ''}
      {displayProduct ? renderProductBenefits(displayProduct, policyValue, sumAssured) : ''}
      <SummaryTable
        displayProduct={displayProduct}
        riders={riders}
        age={age}
        calculatedCoveragePeriod={calculatedCoveragePeriod}
        calculatedPaymentPeriod={calculatedPaymentPeriod}
        basicPremium={basicPremium}
        totalPremium={totalPremium}
        basicSumAssured={sumAssured}
        hasNonLevelRider={hasNonLevelRider}
        hasShowDisclaimerRiderAsterisk={hasShowDisclaimerRiderAsterisk}
        selectedModelFactorID={selectedModelFactorID}
        selectedModelFactorLabel={selectedModelFactorLabel}
        summaryTableHeader={summaryTableHeader}
        isNewAsteriskOfSummaryMainContract={isNewAsteriskOfSummaryMainContract}
      />
    </div>
  )
}

export default ProfileSnapshot
