import { createSectionCreator, createField } from './utils'

const videoConsentField = (app, id) => {
  return [
    createField(`${id}.videoConsent`, [
      {
        id: `${id}.videoConsent`,
        c: 'VideoConsent',
      },
    ]),
  ]
}

export default createSectionCreator(videoConsentField)
