import _ from 'lodash'

import { createSectionCreator, createField } from './utils'
import { getDefaultPersonalTitleList } from 'e-submission/apps/selectors'

const topMarginFields = (app, id) => {
  return _.compact([
    createField(`${id}.margin`, [
      {
        label: '',
        id: `${id}.info`,
        c: 'PlainText',
        p: {
          style: {
            padding: '0',
            display: 'none',
          },
        },
        required: false,
      },
    ]),
  ])
}
const juvenileInfoFields = (app, id) => {
  return _.compact([
    createField(`${id}.title`, [
      {
        label: '',
        id: `${id}.info`,
        c: 'PlainText',
        p: {
          style: {
            padding: '0',
            display: 'none',
          },
        },
        required: false,
        infoMessage:
          'ในกรณีที่ผู้ชำระเบี้ยประกันมีการระบุความสัมพันธ์กับผู้เอาประกันภัยนอกเหนือจากบิดามารดา ท่านสามารถกรอกหรือไม่กรอกข้อมูลในหน้าแบบสอบถามเพื่อการซื้อประกันสุขภาพผู้เยาว์ได้',
      },
    ]),
  ])
}

const juvenileParentFields = (app, id) => {
  const title = _.get(app, `${id}.title`)
  const isOtherTitle = _.get(title, 'value') === 'other'
  const hasPolicy = _.get(app, `${id}.hasPolicy.value`)
  const payerRelationshipValue = _.get(app, 'payer.relationshipToInsured.value')
  const payerRelationshipFlag = payerRelationshipValue === 'PARENT'
  const idTypeValue = _.get(app, `${id}.idType.value`) ? _.get(app, `${id}.idType.value`) : ''

  const inputIdTypes = {
    CITIZEN_ID: {
      id: 'citizenId',
      label: 'เลขประจำตัวประชาชน',
      c: 'NumberInput',
    },
    PASSPORT: {
      id: 'passport',
      label: 'หมายเลขหนังสือเดินทาง',
      c: 'TextInput',
    },
    GOV_ID: {
      id: 'house-registration',
      label: 'เลขประจำตัวประชาชน',
      c: 'TextInput',
    },
  }
  return _.compact([
    createField(`${id}.title`, [
      {
        label: 'คำนำหน้า',
        id: `${id}.title`,
        c: 'Dropdown',
        required: payerRelationshipFlag,
        p: {
          placeHolder: 'กรุณาเลือกคำนำหน้าชื่อ',
          mergeState: (state) => ({
            options: getDefaultPersonalTitleList(state),
          }),
        },
      },
    ]),
    isOtherTitle &&
      createField(`${id}.title.text`, [
        {
          label: 'ระบุคำนำหน้า',
          id: `${id}.title.text`,
          c: 'TextInput',
          required: payerRelationshipFlag,
        },
      ]),
    createField(`${id}.firstName`, [
      {
        label: 'ชื่อ',
        id: `${id}.firstName`,
        required: payerRelationshipFlag,
        c: 'TextInput',
      },
    ]),
    createField(`${id}.lastName`, [
      {
        label: 'นามสกุล',
        id: `${id}.lastName`,
        required: payerRelationshipFlag,
        c: 'TextInput',
      },
    ]),
    createField(`${id}.idType`, [
      {
        label: 'เอกสารที่ใช้แสดง',
        id: `${id}.idType`,
        required: payerRelationshipFlag,
        c: 'Dropdown',
        p: {
          mergeState: (state, app) => {
            return {
              options: [
                {
                  text: 'กรุณาเลือกเอกสารที่ใช้แสดง',
                  value: '',
                },
                {
                  text: 'บัตรประจำตัวประชาชน',
                  value: 'CITIZEN_ID',
                },
                {
                  text: 'หนังสือเดินทาง',
                  value: 'PASSPORT',
                },
                {
                  text: 'ทะเบียนบ้าน',
                  value: 'GOV_ID',
                },
              ],
            }
          },
        },
      },
    ]),
    idTypeValue &&
      createField(`${id}.idNo.${inputIdTypes[idTypeValue].id}`, [
        {
          label: inputIdTypes[idTypeValue].label,
          id: `${id}.idNo.${inputIdTypes[idTypeValue].id}`,
          c: inputIdTypes[idTypeValue].c,
          required: payerRelationshipFlag,
          p: {},
        },
      ]),
    createField(`${id}.hasPolicyTitle`, [
      {
        id: `${id}.hasPolicyTitle`,
        c: 'PlainText',
        p: {
          mergeState: (state, app) => ({
            value: `โปรดระบุความคุ้มครองค่ารักษาพยาบาล<br/>
              <span class="font-weight-normal">ท่านมีความคุ้มครองค่ารักษาพยาบาลกับบริษัท กรุงไทย-แอกซ่า ประกันชีวิต จำกัด (มหาชน)</span>`,
          }),
        },
      },
    ]),
    createField(`${id}.hasPolicy`, [
      {
        id: `${id}.hasPolicy`,
        c: 'RadioButton',
        required: payerRelationshipFlag,
        invalidLabel: 'โปรดระบุความคุ้มครองค่ารักษาพยาบาล',
        p: {
          options: [
            {
              text: 'มีความคุ้มครอง',
              value: true,
            },
            {
              text: 'ไม่มีความคุ้มครอง',
              value: false,
            },
          ],
        },
      },
    ]),
    hasPolicy &&
      createField(`${id}.policyNumber`, [
        {
          label: 'เลขที่กรมธรรม์',
          id: `${id}.policyNumber`,
          c: 'TextInput',
          p: {},
          required: false,
        },
      ]),
  ])
}

export default (sectionId, sectionLabel) => (app, id) => {
  return _.compact([
    createSectionCreator(topMarginFields)(`${sectionId}`, null, null, null, 'form-section__juvenile')(app, `${id}`),
    createSectionCreator(juvenileInfoFields)(`${sectionId}`, null, null, null, 'form-section__juvenile')(app, `${id}`),
    createSectionCreator(juvenileParentFields)(
      `${sectionId}`,
      null,
      null,
      `${sectionLabel}บิดา`,
      'form-section__juvenile'
    )(app, `${id}.juvenilePurchasing.father`),
    createSectionCreator(juvenileParentFields)(
      `${sectionId}`,
      null,
      null,
      `${sectionLabel}มารดา`,
      'form-section__juvenile'
    )(app, `${id}.juvenilePurchasing.mother`),
  ])
}
