// @flow
import Mustache from 'mustache'
import MESSAGES from 'core/data-model/constants/bi-messages'
import { christianToThaiDate } from 'core/service/lib/date'

export type LegalDisclaimerProps = {
  startDate: Date,
  endDate: Date,
  isBancAgent: boolean,
}

export type LegalDisclaimerNoAddtionalContactProps = {
  startDate: Date,
  endDate: Date,
  isBancAgent: boolean,
}

export const LegalConditionDisclaimer = ({ startDate, endDate, isBancAgent }: LegalDisclaimerProps) => {
  return (
    <div id="legal-disclaimer" className="legal group">
      <h3>{MESSAGES.LEGAL_DISCLAIMER}</h3>
      <ol className="nested-counter">
        <li>
          {MESSAGES.POLICY_MAY_CHANGE}
          <ol className="nested-counter">
            <li>{MESSAGES.AGE_HEALTH_OR_CAREER}</li>
            <li>
              {Mustache.render(MESSAGES.FLEXI_HEALTH_INCREASE_IN_PREMIUM_ADDITIONAL_CONTRACT, {
                agentName: isBancAgent ? MESSAGES.FLEXI_HEALTH_BANC : MESSAGES.FLEXI_HEALTH_AGENT,
              })}
            </li>
          </ol>
        </li>
        <li>{MESSAGES.PRELIMINARY_OFFER_MAY_CHANGE}</li>
        <li>
          {Mustache.render(MESSAGES.FLEXI_HEALTH_OFFER_SALE_PERIOD, {
            startOffer: christianToThaiDate(startDate),
            endOffer: christianToThaiDate(endDate),
          })}
        </li>
      </ol>
    </div>
  )
}

export const LegalConditionDisclaimerMarketConduct = ({ startDate, endDate, isBancAgent }: LegalDisclaimerProps) => {
  return (
    <div id="legal-disclaimer-market-conduct" className="legal group">
      <h3>{MESSAGES.LEGAL_DISCLAIMER}</h3>
      <ol className="nested-counter">
        <li>
          {Mustache.render(MESSAGES.FLEXI_HEALTH_INCREASE_IN_PREMIUM_ADDITIONAL_CONTRACT, {
            agentName: isBancAgent ? MESSAGES.FLEXI_HEALTH_BANC : MESSAGES.FLEXI_HEALTH_AGENT,
          })}
        </li>
        <li>{MESSAGES.PRELIMINARY_OFFER_MAY_CHANGE}</li>
        <li>
          {Mustache.render(MESSAGES.FLEXI_HEALTH_OFFER_SALE_PERIOD, {
            startOffer: christianToThaiDate(startDate),
            endOffer: christianToThaiDate(endDate),
          })}
        </li>
      </ol>
    </div>
  )
}

export const LegalConditionDisclaimerMarketConductNoAddtionalContract = ({
  startDate,
  endDate,
  isBancAgent,
}: LegalDisclaimerProps) => {
  return (
    <div id="legal-disclaimer-market-conduct" className="legal group">
      <h3>{MESSAGES.LEGAL_DISCLAIMER}</h3>
      <ol className="nested-counter">
        <li>{MESSAGES.PRELIMINARY_OFFER_MAY_CHANGE}</li>
        <li>
          {Mustache.render(MESSAGES.FLEXI_HEALTH_OFFER_SALE_PERIOD, {
            startOffer: christianToThaiDate(startDate),
            endOffer: christianToThaiDate(endDate),
          })}
        </li>
      </ol>
    </div>
  )
}
