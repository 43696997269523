//@flow
import { call, put, select } from 'redux-saga/effects'
import { updateFundAllocation } from 'quick-quote/product-investment/fund-allocation/utils'
import type { DisplayProductQuery } from 'core/service'
import { getSelectedDisplayProductQuery } from 'quick-quote/product-selection/selectors'
import { getRiskEvaluated } from 'quick-quote/product-investment/risk-questionnaire/selectors'
import type { FundAllocationToggleItem } from 'core/service/investment'
import { calculateTotalAllocation } from 'core/service/investment'
import type { CalculateTotalLstuFundAllocation } from 'quick-quote/product-investment/fund-allocation/lstu/actions'
import {
  updateLstuFundAllocation,
  updateLstuTotalFundAllocation,
} from 'quick-quote/product-investment/fund-allocation/lstu/actions'
import { getLstuFundAllocations } from 'quick-quote/product-investment/fund-allocation/lstu/selectors'

export function* onCalculateLstuTotalAllocationRequest(action: CalculateTotalLstuFundAllocation): Generator<*, *, *> {
  const fundAllocations = yield select(getLstuFundAllocations)
  yield call(processAndUpdateLstuFundAllocation, updateFundAllocation(fundAllocations, action.payload))
}

export function* processAndUpdateLstuFundAllocation(fundAllocations: FundAllocationToggleItem[]): Generator<*, *, *> {
  const displayProductQuery: DisplayProductQuery = yield select(getSelectedDisplayProductQuery)
  const insuredRiskLevel = yield select(getRiskEvaluated)
  const allocationResult = yield call(
    calculateTotalAllocation,
    displayProductQuery,
    insuredRiskLevel.value,
    fundAllocations
  )

  yield put(updateLstuFundAllocation(allocationResult.fundAllocations))
  yield put(updateLstuTotalFundAllocation(allocationResult.totalAllocation))
}
