// @flow

import Mustache from 'mustache'
import MESSAGES from 'core/data-model/constants/bi-messages'
import type { DisplayProduct } from 'core/service/display-product'
import { createPdfDocument } from '../../document-creation'
import { createCommonPdf } from '../common/pdf-document'
import healthTemplate from './health-template'
import { commonSection } from 'core/service/pdf-generation/products/common/sections'
import type { HealthProps } from './index'
import { sections } from './sections'
import { getToggles } from 'quick-quote/feature-toggles'

export const basicPlanLabel = (basicPlan: DisplayProduct) => basicPlan.fullNameThai || basicPlan.nameThai

export const iHealthyBasicPlanDetailsLabelFn = (basicPlan: DisplayProduct): string =>
  Mustache.render(MESSAGES.BASIC_PLAN_DETAILS, {
    // $FlowFixMe: `fullNameThai` is not in `DisplayProduct` but was added as optional to `RawBasicPlan` and mapped in the fn `transformToDisplayProduct`
    name: basicPlanLabel(basicPlan),
    code: basicPlan.basicPlanCode,
  })

export const createMiniBIDocDefinition = async (props: HealthProps) => {
  const pdfTemplate = await healthTemplate(props)
  return {
    content: [
      ...commonSection.profile({ ...props, basicPlanLabel }),
      ...commonSection.productBenefits({ ...props, basicPlanDetailsLabelFn: iHealthyBasicPlanDetailsLabelFn }),
      ...commonSection.riders(props),
      commonSection.legalDisclaimerMarketConduct(props),
    ],
    ...pdfTemplate,
  }
}

export const createFullBIDocDefinition = async (props: HealthProps) => {
  const _props: HealthProps = { ...props, biType: props.biType }
  const pdfTemplate = await healthTemplate(_props)
  const paymentChannels = await commonSection.paymentChannels(props)
  return {
    content: [
      ...commonSection.profile({ ...props, basicPlanLabel }),
      ...commonSection.productBenefits({ ...props, basicPlanDetailsLabelFn: iHealthyBasicPlanDetailsLabelFn }),
      ...commonSection.riders(props),
      commonSection.taxConsentDeductionBenefit(props),
      getToggles().ENABLE_NEW_MEH_PDF_EXCLUSION_TERM ? sections.basicPlanCondition(props.basicPlan) : '',
      commonSection.legalDisclaimerMarketConduct(props),
      paymentChannels,
      commonSection.signature(props),
    ],
    ...pdfTemplate,
  }
}

const createFullBIPdf = createPdfDocument(createFullBIDocDefinition)
const createMiniBIPdf = createPdfDocument(createMiniBIDocDefinition)

export const createHealthPdf = async (props: HealthProps) => {
  return await createCommonPdf(props, createFullBIPdf, createMiniBIPdf)
}
