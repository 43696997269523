//@flow
import BIMESSAGES from 'core/data-model/constants/bi-messages'
import MESSAGES from 'core/data-model/constants/messages'
import VALUES from 'core/data-model/constants/values'

import { calculateCoverageDeathBenefit } from 'core/service/investment'

import { formatNumber } from 'core/service/lib/number-format'
import { renderBIAge } from 'core/service/insured/age'
import { getInsuredFullName } from 'core/service/pdf-generation/utils/insured'

import {
  Columns,
  Bold,
  MarginLeft,
  MarginTop,
  Shrinked,
} from 'core/service/pdf-generation/products/common/components/general'

// $$FlowFixMe
import CheckBoxImage from '!url-loader!core/service/pdf-generation/assets/images/checkedBox.png'
// $$FlowFixMe
import BlankBoxImage from '!url-loader!core/service/pdf-generation/assets/images/blankBox.png'
import type { InsuredProps } from 'core/service/pdf-generation/products/common/types'

type PolicySummaryProps = {
  policyType: string,
  sumAssured: number,
  calculatedCoveragePeriod: number,
  calculatedPaymentPeriod: number,
  insured: InsuredProps,
  regularPremium: number,
  regularTopupPremium: number,
  coverageDeath: number,
  paymentModeLabel: string,
}

export const iWealthyPolicySummary = (props: PolicySummaryProps, lineHeight: number = 1) =>
  Columns([InsuredSummary(props, lineHeight), SecondColumn(props, lineHeight), ThirdColumn(props)])

export const iInvestPolicySummary = (props: PolicySummaryProps, lineHeight: number = 1) =>
  Columns([IInvestInsuredSummary(props, lineHeight), IInvestSecondColumn(props, lineHeight)])

export const rpudrPolicySummary = (props: PolicySummaryProps, lineHeight: number = 1) =>
  Shrinked(
    Columns([RpudrInsuredSummary(props, lineHeight), RpudrSecondColumn(props, lineHeight), RpudrThirdColumn(props)])
  )

export const PolicySummary = (productCode: string, props: PolicySummaryProps, lineHeight: number = 1) => {
  const patchedCoverageDeath = props.coverageDeath
    ? props.coverageDeath
    : calculateCoverageDeathBenefit(productCode, props.sumAssured)

  const patchedProps = {
    ...props,
    coverageDeath: patchedCoverageDeath,
  }

  switch (productCode) {
    case VALUES.SPUL:
      return iInvestPolicySummary(patchedProps, lineHeight)
    case VALUES.RPUL:
      return iWealthyPolicySummary(patchedProps, lineHeight)
    case VALUES.RPUDR:
      return rpudrPolicySummary(patchedProps, lineHeight)
    default:
      return []
  }
}

const noBorder = [false, false, false, false]

export const InsuredSummary = (props: PolicySummaryProps, lineHeight: number) => {
  const insuredFullName = getInsuredFullName(props.insured)
  const { age, genderLabel } = props.insured
  const renderAge = renderBIAge(age, props?.isReviseQQ)
  const items = [
    [BIMESSAGES.POLICY_TYPE, props.policyType],
    [BIMESSAGES.INSURED_FULLNAME, insuredFullName],
    [BIMESSAGES.INSURED_AGE, renderAge],
    [MESSAGES.GENDER, genderLabel],
    [BIMESSAGES.SUM_ASSURED, `${formatNumber(props.sumAssured)} ${MESSAGES.BAHT}`],
  ]
  const body = items.map(([label, value]) => [
    { text: `${label}:`, border: noBorder },
    { text: value, bold: true, border: noBorder },
  ])
  return {
    lineHeight,
    table: { body, widths: ['auto', 'auto'] },
  }
}

export const IInvestInsuredSummary = (props: PolicySummaryProps, lineHeight: number) => {
  const insuredFullName = getInsuredFullName(props.insured)
  const { age, genderLabel } = props.insured
  const renderAge = renderBIAge(age, props?.isReviseQQ)
  const items = [
    [BIMESSAGES.POLICY_TYPE, props.policyType],
    [BIMESSAGES.INSURED_FULLNAME, insuredFullName],
    [BIMESSAGES.INSURED_AGE, renderAge],
    [MESSAGES.GENDER, genderLabel],
  ]
  const body = items.map(([label, value]) => [
    { text: `${label}:`, border: noBorder },
    { text: value, bold: true, border: noBorder },
  ])
  return {
    lineHeight,
    table: { body, widths: ['auto', 'auto'] },
  }
}

export const RpudrInsuredSummary = (props: PolicySummaryProps, lineHeight: number) => {
  const insuredFullName = getInsuredFullName(props.insured)
  const { age, genderLabel } = props.insured
  const renderAge = renderBIAge(age, props?.isReviseQQ)
  const items = [
    [BIMESSAGES.POLICY_TYPE, props.policyType],
    ['ชื่อ-สกุล ผู้ขอเอาประกันภัย', insuredFullName],
    ['อายุผู้ขอเอาประกันภัย', renderAge],
    [MESSAGES.GENDER, `${genderLabel}`],
  ]
  const body = items.map(([label, value]) => [
    { text: `${label}:`, border: noBorder },
    { text: value, bold: true, border: noBorder },
  ])
  return {
    lineHeight,
    table: { body, widths: ['auto', 'auto'] },
  }
}

const displayMaybeNumber = (input: number, decimalPlaces: number = 0): string => {
  if (input === 0) return '-'
  return `${formatNumber(input, decimalPlaces)}`
}

export const SecondColumn = (props: PolicySummaryProps, lineHeight: number) => {
  const items = [
    [BIMESSAGES.PREMIUM_PAYMENT_PERIOD, `${props.calculatedPaymentPeriod} ${MESSAGES.TIME_UNIT_YEAR}`],
    [BIMESSAGES.COVERAGE_PERIOD, `${props.calculatedCoveragePeriod} ${MESSAGES.TIME_UNIT_YEAR}`],
    [BIMESSAGES.REGULAR_COVERAGE_BASIC_PREMIUM, `${formatNumber(props.regularPremium)} ${MESSAGES.BAHT}`],
    [BIMESSAGES.REGULAR_INVESTMENT_PREMIUM, `${displayMaybeNumber(props.regularTopupPremium)} ${MESSAGES.BAHT}`],
    [`${BIMESSAGES.BASIC_PLAN_DEATH_BENEFIT} (ขั้นต่ำ)`, `${formatNumber(props.coverageDeath)} ${MESSAGES.BAHT}`],
  ]
  const body = items.map(([label, value]) => [
    { text: `${label}:`, border: noBorder },
    { text: value, bold: true, border: noBorder },
  ])
  return {
    lineHeight,
    table: { body, widths: ['auto', 'auto'] },
  }
}

export const RpudrSecondColumn = (props: PolicySummaryProps, lineHeight: number) => {
  const { cashFlowByExpectedReturn } = props
  const firstPolicyPeriodCashFlow = cashFlowByExpectedReturn[Object.keys(cashFlowByExpectedReturn)[0]][0]
  const { premium } = firstPolicyPeriodCashFlow
  const items = [
    [BIMESSAGES.PREMIUM_PAYMENT_PERIOD, `${props.calculatedPaymentPeriod} ${MESSAGES.TIME_UNIT_YEAR}`],
    ['ระยะเวลาเอาประกันภัย', `${props.calculatedCoveragePeriod} ${MESSAGES.TIME_UNIT_YEAR}`],
    [BIMESSAGES.SUM_ASSURED, `${formatNumber(props.sumAssured)} ${MESSAGES.BAHT}`],
    [
      'เบี้ยประกันภัยหลักเพื่อความคุ้มครอง',
      `${formatNumber(premium.totalRegularAndRiderPremium, 2, true)} ${MESSAGES.BAHT}`,
    ],
    [
      'เบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษ',
      `${displayMaybeNumber(props.lumpSumTopUp, 2)} ${MESSAGES.BAHT} (ตอนออกกรมธรรม์)`,
    ],
  ]
  const body = items.map(([label, value], index) => [
    { text: `${label}:`, lineHeight, border: noBorder },
    { text: value, bold: true, border: noBorder },
  ])
  return {
    lineHeight,
    table: { body, widths: [120, 'auto'] },
  }
}

export const IInvestSecondColumn = (props: PolicySummaryProps, lineHeight: number) => {
  const items = [
    [BIMESSAGES.PREMIUM_PAYMENT_PERIOD, `${props.paymentModeLabel}`],
    [BIMESSAGES.COVERAGE_PERIOD, `${props.calculatedCoveragePeriod} ${MESSAGES.TIME_UNIT_YEAR}`],
    [BIMESSAGES.BASIC_PLAN_DEATH_BENEFIT, `${formatNumber(props.coverageDeath)} ${MESSAGES.BAHT}`],
    [BIMESSAGES.PREMIUM, `${formatNumber(props.regularPremium)} ${MESSAGES.BAHT}`],
  ]
  const body = items.map(([label, value]) => [
    { text: `${label}:`, border: noBorder },
    { text: value, bold: true, border: noBorder },
  ])
  return {
    lineHeight,
    table: { body, widths: ['auto', 'auto'] },
  }
}

export const ThirdColumn = (props: PolicySummaryProps) => {
  return {
    stack: [
      Columns([{ text: `${MESSAGES.PAYMENT_MODE}:`, width: 'auto' }, MarginLeft(20, Bold(props.paymentModeLabel))]),
      MarginTop(
        15,
        Columns([
          { image: CheckBoxImage, height: 15, width: 15, marginTop: -1 },
          { text: BIMESSAGES.STANDARD_RISK, width: 'auto', marginLeft: 5 },
          { image: BlankBoxImage, height: 15, width: 15, marginTop: -1, marginLeft: 10 },
          { text: BIMESSAGES.LOWER_STANDARD_RISK, width: 'auto', marginLeft: 15 },
        ])
      ),
    ],
    width: '*',
  }
}

export const RpudrThirdColumn = (props: PolicySummaryProps) => {
  return {
    stack: [
      Columns([
        { text: `${MESSAGES.RPDUR_PAYMENT_MODE}:`, width: 'auto' },
        MarginLeft(20, Bold(props.paymentModeLabel)),
      ]),
      { text: '', marginBottom: 6 },
      Columns([
        { image: CheckBoxImage, height: 15, width: 15, marginTop: -1 },
        { text: BIMESSAGES.STANDARD_RISK, width: 'auto', marginLeft: 5 },
        { text: 'ขั้นอาชีพ: มาตรฐาน', marginLeft: 60 },
      ]),
    ],
    width: '*',
  }
}
