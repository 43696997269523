// @flow

import VALUES from 'core/data-model/constants/values'
import type { LstuAcceptFundAllocation } from 'core/data-model/investment'
import type { FundAllocationToggleItem, LstuFundAllocationOption, TotalFundAllocation } from 'core/service/investment'
import {
  LSTU_ACCEPT_FOREIGN_INVESTMENT,
  LSTU_ACCEPT_HIGHER_RISK_INVESTMENT,
  RESET_LSTU_FUND_ALLOCATION,
  RESET_LSTU_FUND_CONSENTS,
  UPDATE_LSTU_ACCEPT_FUND_ALLOCATION,
  ANSWER_QUESTION_TO_ACCEPT_LSTU_FOREIGN_INVESTMENT,
  RESET_LSTU_ACCEPT_FUND_ALLOCATION,
} from 'quick-quote/product-investment/fund-allocation/lstu/actions'
import type { ResetRiskEvaluated } from 'quick-quote/product-investment/risk-questionnaire/actions'
import { RESET_RISK_EVALUATED } from 'quick-quote/product-investment/risk-questionnaire/actions'

import { combineReducers } from 'redux'
import type { SelectProduct } from '../../../product-selection/actions'
import { SELECT_PRODUCT } from '../../../product-selection/actions'
import type { FundAllocationState } from '../rpp/reducers'
import type { Action } from './actions'
import {
  ALLOCATE_LSTU_INDEPENDENTLY,
  ALLOCATE_LSTU_SAME_AS_RPP,
  UPDATE_LSTU_FUND_ALLOCATIONS,
  UPDATE_LSTU_TOTAL_FUND_ALLOCATION,
} from './actions'

export type LstuFundAllocationState = FundAllocationState & {
  selectedAllocationOption: ?LstuFundAllocationOption,
}

export const allocations = (
  state: FundAllocationToggleItem[] = [],
  action: Action | SelectProduct
): FundAllocationToggleItem[] => {
  switch (action.type) {
    case UPDATE_LSTU_FUND_ALLOCATIONS:
      return action.payload
    case RESET_RISK_EVALUATED:
    case SELECT_PRODUCT:
    case RESET_LSTU_FUND_ALLOCATION:
      return []
    default:
      return state
  }
}

export const totalAllocation = (
  state: TotalFundAllocation = { value: 0 },
  action: Action | SelectProduct
): TotalFundAllocation => {
  switch (action.type) {
    case UPDATE_LSTU_TOTAL_FUND_ALLOCATION:
      return action.payload
    case RESET_RISK_EVALUATED:
    case SELECT_PRODUCT:
    case RESET_LSTU_FUND_ALLOCATION:
      return {}
    default:
      return state
  }
}

export const selectedAllocationOption = (
  state: ?LstuFundAllocationOption = null,
  action: Action | SelectProduct | ResetRiskEvaluated
): ?LstuFundAllocationOption => {
  switch (action.type) {
    case ALLOCATE_LSTU_INDEPENDENTLY:
      return VALUES.LSTU_FUND_ALLOCATION_OPTION.INDEPENDENT
    case ALLOCATE_LSTU_SAME_AS_RPP:
      return VALUES.LSTU_FUND_ALLOCATION_OPTION.SAME_AS_RPP
    case SELECT_PRODUCT:
    case RESET_RISK_EVALUATED:
      return null
    default:
      return state
  }
}

export const acceptFundAllocation = (
  state: ?LstuAcceptFundAllocation = false,
  action: Action | SelectProduct | ResetRiskEvaluated
): LstuAcceptFundAllocation => {
  switch (action.type) {
    case UPDATE_LSTU_ACCEPT_FUND_ALLOCATION:
      return action.payload
    case SELECT_PRODUCT:
    case RESET_RISK_EVALUATED:
    case RESET_LSTU_ACCEPT_FUND_ALLOCATION:
      return false
    default:
      return state
  }
}

export const allowHigherRiskInvestment = (
  state: boolean = false,
  action: Action | SelectProduct | ResetRiskEvaluated
): boolean => {
  switch (action.type) {
    case LSTU_ACCEPT_HIGHER_RISK_INVESTMENT:
      return !state
    case SELECT_PRODUCT:
    case RESET_LSTU_ACCEPT_FUND_ALLOCATION:
    case RESET_LSTU_FUND_CONSENTS:
    case RESET_RISK_EVALUATED:
      return false
    default:
      return state
  }
}

export const allowForeignInvestment = (
  state: boolean = false,
  action: Action | SelectProduct | ResetRiskEvaluated
): boolean => {
  switch (action.type) {
    case LSTU_ACCEPT_FOREIGN_INVESTMENT:
      return !state
    case SELECT_PRODUCT:
    case RESET_LSTU_ACCEPT_FUND_ALLOCATION:
    case RESET_LSTU_FUND_CONSENTS:
    case RESET_RISK_EVALUATED:
      return false
    case ANSWER_QUESTION_TO_ACCEPT_LSTU_FOREIGN_INVESTMENT:
      return action.payload
    default:
      return state
  }
}

// $$FlowFixMe
export const reducer = combineReducers({
  allocations,
  totalAllocation,
  selectedAllocationOption,
  acceptFundAllocation,
  allowHigherRiskInvestment,
  allowForeignInvestment,
})
