import { previewMultiplePDF, previewPDF } from 'e-submission/apps/actions'

import {
  getCurrentApp,
  getFileWithName,
  isAddendumDocumentNeeded,
  isEPolicyConsentDocumentNeeded,
  isFNADocumentNeeded,
  isFATCADocumentNeeded,
  isCRSDocumentNeeded,
  isCitizenIdConsentInsuredNeeded,
  isCitizenIdConsentPayerNeeded,
  isPassportConsentInsuredNeeded,
  isPassportConsentPayerNeeded,
  isForeignerSelection,
  isJuvenileSelection,
  isPrivacyConsentDocumentNeeded,
  isPrivacyPolicyDocumentNeeded,
  isRADocumentNeeded,
  isVCDocumentNeeded,
  isBIDocumentNeeded,
} from 'e-submission/apps/selectors'
import { DOCUMENT_TYPE, SIGNATURE_TYPE } from 'e-submission/domain/data-model/constants'
import {
  getPolicyId,
  getSignatureKey,
  isAcknowledge,
  isSelectedRemoteSelling,
  hasReceiptSigned,
  getPaidReceipts,
  isRemoteSelling,
} from 'e-submission/domain/data-model/form/selectors'
import { createField, createSectionCreator } from 'e-submission/domain/data-model/form/utils'
import _ from 'lodash'
import { get, invokeArgs, noop } from 'lodash/fp'
import { getToggles } from 'quick-quote/feature-toggles'

const getSignature = ([signedBy, documentName], state) => {
  const signature = get(documentName, SIGNATURE_TYPE)
  const app = getCurrentApp(state)

  if ((getToggles().ENABLE_EKYC_F2F || isSelectedRemoteSelling(app)) && !isRemoteSelling(app)) {
    if (
      documentName === 'PASSPORT_CONSENT_INSURED' ||
      documentName === 'PASSPORT_CONSENT_PAYER' ||
      documentName === 'CITIZEN_ID_CONSENT_INSURED' ||
      documentName === 'CITIZEN_ID_CONSENT_PAYER'
    ) {
      delete signature.signedBy['acknowledge']
    }
  }
  return {
    ...signature,
    signedBy: invokeArgs(['signedBy', signedBy], [app], signature),
  }
}
const isSign = (key, state) => {
  if (key.includes('ECBR')) {
    const app = getCurrentApp(state)
    const isReceiptSigned = hasReceiptSigned(app)
    return isReceiptSigned === true
  }
  const checked = getFileWithName(state, key)
  return checked === true
}

export const getDocOnClickFromToggle = (on, off) => (getToggles().ENABLE_SINGLE_SIGN_DOCUMENT ? on : off)

export const createDocumentCreator = ({
  baseId,
  once = false,
  policyId,
  dispatch,
  section,
  state,
  requiredDocuments,
  overrideDocumentType,
  selectedRemoteSelling,
  isOldRemoteSelling,
  // totalAmount,
  app,
}) => (path, suffix, person) => {
  const finalDocumentType = _.merge(DOCUMENT_TYPE, overrideDocumentType)
  const document = get(path, finalDocumentType)
  const overRideByEkycToggles = [
    'passport-consent-insured',
    'passport-consent-payer',
    'citizen-id-consent-insured',
    'citizen-id-consent-payer',
  ]
  /* istanbul ignore next */
  if (!document) {
    return null
  }

  if (
    (baseId.includes('insured') || baseId.includes('payer')) &&
    overRideByEkycToggles.includes(document.name) &&
    (getToggles().ENABLE_EKYC_F2F || getToggles().ENABLE_APPMAN_REMOTE_SELLING) &&
    isRemoteSelling(app)
  ) {
    // Hide section on old RMS
    document.notShowInSignatureSection = true
  }

  if (
    document.notShowInSignatureSection &&
    (getToggles().ENABLE_EKYC_F2F || getToggles().ENABLE_APPMAN_REMOTE_SELLING) &&
    !isRemoteSelling(app)
  ) {
    if (overRideByEkycToggles.includes(document.name)) {
      document.notShowInSignatureSection = !(getToggles().ENABLE_EKYC_F2F || getToggles().ENABLE_APPMAN_REMOTE_SELLING)
    }
  }
  const [signedBy, documentName] = path
  const key = getSignatureKey(documentName, signedBy, suffix)
  /* istanbul ignore next */
  if (document.notShowInSignatureSection) {
    return {
      key,
      id: `${baseId}.${document.name}`,
      name: document.name,
      readOnly: true,
      checked: true,
      hidden: true,
    }
  }

  const otherDocPerson = path[0]
  let allDocuments = requiredDocuments
    ? requiredDocuments.map((doc) => get([otherDocPerson, doc], finalDocumentType))
    : []
  if (isOldRemoteSelling) {
    allDocuments = allDocuments.filter((item) => {
      return !overRideByEkycToggles.includes(item.name)
    })
  }
  const signature = getSignature(path, state)
  const checked = isSign(key, state)
  const readOnly = once ? checked : false
  const shouldUseMultiplePreviewPdf = [
    'insured.signature',
    'agent.signature',
    'payer.signature',
    'agent.remote-signature',
    'thirdPerson.signature',
  ].includes(baseId)
  const shouldSendToClient = [
    'insured.DCA',
    'insured.signature',
    'payer.signature',
    'acknowledge.signature',
    'thirdPerson.signature',
  ].includes(baseId)
  /* istanbul ignore next: hard to write test */
  const openPreviewMultiplePdf = () =>
    dispatch(
      previewMultiplePDF({
        ...document,
        allDocuments,
        readOnly,
        checked,
        signature,
        policyId,
        sectionId: section.id,
        sectionLabel: isAcknowledge(person || signedBy) ? 'ตรวจทานเรียบร้อย' : section.label,
        person: person || signedBy,
        selectedRemoteSelling: selectedRemoteSelling && shouldSendToClient,
      })
    )
  /* istanbul ignore next */
  const openPreviewPdf = () =>
    dispatch(
      previewPDF({
        ...document,
        allDocuments,
        readOnly,
        checked,
        signature,
        policyId,
        sectionId: section.id,
        sectionLabel: isAcknowledge(person || signedBy) ? 'ตรวจทานเรียบร้อย' : section.label,
        person: person || signedBy,
        selectedRemoteSelling: selectedRemoteSelling && shouldSendToClient,
      })
    )
  /* istanbul ignore next: tired of writing tests */
  let onClickFunction = null
  if (
    getToggles().ENABLE_CHANGE_PAY_IN_ATTACHMENT_STEP &&
    documentName === 'ECBR' &&
    section.documentTypeName !== undefined
  ) {
    if (section.isUploaded) {
      if (shouldUseMultiplePreviewPdf) {
        onClickFunction = getDocOnClickFromToggle(openPreviewMultiplePdf, openPreviewPdf)
      } else {
        onClickFunction = openPreviewPdf
      }
    }
  } else {
    if (shouldUseMultiplePreviewPdf) {
      onClickFunction = getDocOnClickFromToggle(openPreviewMultiplePdf, openPreviewPdf)
    } else {
      onClickFunction = openPreviewPdf
    }
  }

  return {
    id: `${baseId}.${document.name}`,
    icon: isAcknowledge(person || signedBy) ? 'magnify' : 'sign',
    action: isAcknowledge(person || signedBy) ? 'ตรวจทาน' : 'ลงนาม',
    readOnly,
    checked,
    key,
    selectedRemoteSelling: selectedRemoteSelling && shouldSendToClient,
    // totalAmount,
    name: document.name,
    description: document.text,
    warnText: section.warnText,
    onClick: onClickFunction,
  }
}

const when = (proposition, value) => (proposition ? value : undefined)

const toDocumentType = (person, overrideDescription) => ({
  [person]: _.mapValues(overrideDescription, (desc) => ({ text: desc })),
})

const signatureField = (person, requiredDocs, overrideDescription = {}) => (app, id, section) => {
  const mergeSignatureState = (person, section) => (state, app, { dispatch = noop } = {}) => {
    const policyId = getPolicyId(app)
    const showAddendumDocument = isAddendumDocumentNeeded(state)
    const showEPolicyConsentDocument = isEPolicyConsentDocumentNeeded(state)
    const showPrivacyConsentDocument = isPrivacyConsentDocumentNeeded(state)
    const showPrivacyPolicyDocument = isPrivacyPolicyDocumentNeeded(state)
    /* istanbul ignore next */
    const showJuvenileDocument = getToggles().ENABLE_JUVENILE_PURCHASING_FORM && isJuvenileSelection(state)
    /* istanbul ignore next */
    const showForeigner = getToggles().ENABLE_FOREIGNER_FORM && isForeignerSelection(state)
    /* istanbul ignore next */
    const showRAConsentDocument = getToggles().ENABLE_RA_FORM && isRADocumentNeeded(state)
    const showFNADocument = isFNADocumentNeeded(state)
    const showVulnerableCustomerDocument = isVCDocumentNeeded(state)
    const showFATCADocument = isFATCADocumentNeeded(state)
    const showBIDocument = isBIDocumentNeeded(state)
    const showCRSDocument = isCRSDocumentNeeded(state)

    const showCitizenIdConsentInsuredDocument = isCitizenIdConsentInsuredNeeded(state)
    const showCitizenIdConsentPayerDocument = isCitizenIdConsentPayerNeeded(state)
    const showPassportConsentInsuredDocument = isPassportConsentInsuredNeeded(state)
    const showPassportConsentPayerDocument = isPassportConsentPayerNeeded(state)
    // const totalAmount = getQuickQuoteTotalPremium(app)
    const selectedRemoteSelling = isSelectedRemoteSelling(app)
    const isOldRemoteSelling = isRemoteSelling(app)
    const showEcbr = getPaidReceipts(app).length > 0

    const none = true

    /**
     * IMPORTANT
     * If you're reading this comment. It mean you're developer who assigned to add more documents to policies
     * Above selector and requiredDocuments below will be reuse on /abcd/frontend/web/src/e-submission/domain/data-model/submit/index.js to determine is that policy is able to submit
     * PLEASE BE SURED that selector logic is already concern about DRAFT POLICY and SIGNED POLICY that not submitted
     * Mostly, you have to check some flag or some field that will exists if that policy created from the new version that required new document
     * For example, isFNADocumentNeeded will return true only if FNA.needGap.type is not equal empty string which mean that form is created when FNA flow is enabled.
     * and isFNADocumentNeeded will return false when FNA.needGap.type is not exists. So, FNA document will not required for DRAFT POLICY and SIGNED POLICY that not submitted
     */

    /**
     * Related file that config the documents
     *
     * Frontend
     * Config Document in each section: /abcd/frontend/web/src/e-submission/domain/data-model/form/pages/signature.js
     * /Users/nantaphopphuengphae/Workspace/abcd/frontend/web/src/e-submission/domain/data-model/constants.js
     *
     * Middleware
     * Cofig Doc Metadata /advisorzone-middleware/server/services/get-document-metadata.js
     * /advisorzone-middleware/server/services/document-types.js
     * /advisorzone-middleware/server/services/pdf-builder-service/mapping-data.js
     * /advisorzone-middleware/server/services/signature-builder-service.js
     * /Users/nantaphopphuengphae/Workspace/advisorzone-middleware/server/pdf/index.js
     */

    const requirements = [
      ['BI', showBIDocument],
      ['APPLICATION', none],
      ['E_POLICY_CONSENT', showEPolicyConsentDocument],
      ['PRIVACY_CONSENT', showPrivacyConsentDocument],
      ['PRIVACY_POLICY', showPrivacyPolicyDocument],
      ['KYC', none],
      ['RPQ', none],
      ['RA', showRAConsentDocument],
      ['FAMILY_DISCOUNT', none],
      ['PENSION_RECEIVE', none],
      ['JUVENILE_PURCHASE', showJuvenileDocument],
      ['FOREIGNER', showForeigner],
      ['ADDENDUM', showAddendumDocument],
      ['FNA', showFNADocument],
      ['FATCA', showFATCADocument],
      ['CRS', showCRSDocument],
      ['VULNERABLE_CUSTOMER', showVulnerableCustomerDocument],
      ['CITIZEN_ID_CONSENT_INSURED', showCitizenIdConsentInsuredDocument],
      ['CITIZEN_ID_CONSENT_PAYER', showCitizenIdConsentPayerDocument],
      ['PASSPORT_CONSENT_INSURED', showPassportConsentInsuredDocument],
      ['PASSPORT_CONSENT_PAYER', showPassportConsentPayerDocument],
      ['ECBR', showEcbr],
    ]

    const shouldSendToClient = (baseId) =>
      [
        'insured.signature',
        'payer.signature',
        'acknowledge.signature',
        'thirdPerson.signature',
        'insured.DCA',
      ].includes(baseId)
    const requiredDocuments = requirements.filter(([docType]) => _.includes(requiredDocs, docType))
    const createDocument = createDocumentCreator({
      policyId,
      dispatch,
      section,
      state,
      requiredDocuments: requiredDocuments
        .filter(([docType, required]) => required)
        .map(([docType, required]) => docType),
      baseId: `${person}.${id}`,
      overrideDocumentType: toDocumentType(person, overrideDescription),
      selectedRemoteSelling: selectedRemoteSelling && shouldSendToClient(`${person}.${id}`),
      isOldRemoteSelling,
      // totalAmount,
      app,
    })
    const documents = requiredDocuments.map(([docType, requirement]) =>
      when(requirement, createDocument([person, docType]))
    )
    return {
      documents: _.compact(documents),
    }
  }

  return [
    createField(`${person}.${id}`, [
      {
        id: `${person}.${id}`,
        c: 'DocumentCard',
        p: {
          mergeState: mergeSignatureState(person, section),
        },
      },
    ]),
  ]
}

export const signatureSection = (person, docs, overrideDescription) =>
  createSectionCreator(signatureField(person, docs, overrideDescription))
