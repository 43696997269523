// @flow

import { isNil, isNotNil } from 'core/service/lib/type-check'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { APAndECARERiderBenefits } from 'core/service/rider/benefits'

import RiderBenefitSummary from './rider-benefit-summary'
import RiderWithBenefitModal from './rider-benefit-modal'
import { formatNumber } from 'core/service/lib/number-format'
import ArrowRight from '@axah/web-toolkit/icons/arrow-right.svg'
import MESSAGES from 'core/data-model/constants/bi-messages'
import { AgentProtectionDisclaimer } from './agent-protection-disclaimer'
import { getRiderYearsOfCoverage, getRiderYearsOfPayment } from 'core/service/rider/benefits/benefit-data'
import { AdditionalDisclaimer } from './additional-disclaimer'

type APAndECARERiderBenefitProps = {
  rider: {
    apRider?: Rider & RiderState,
    ecareRider?: Rider & RiderState,
  },
  apAndEcareBenefits: ?APAndECARERiderBenefits,
}

type AccidentRowProps = {
  description: string,
  value: ?string,
}
const AccidentHeadingRow = ({ description, value }: AccidentRowProps) => {
  return (
    <tr>
      <td>{description}</td>
      <td>{value}</td>
    </tr>
  )
}

const AccidentSubHeadingRow = ({ description, value }: AccidentRowProps) => {
  return (
    <tr>
      <td>
        <ul>
          <li>{description.replace('- ', '')}</li>
        </ul>
      </td>
      <td>{value}</td>
    </tr>
  )
}

type BurnsRowProps = {
  areaInvolved: ?string,
  description: ?string,
  value: ?string,
}
const BurnsRow = ({ areaInvolved, description, value }: BurnsRowProps) => {
  return (
    <tr>
      <td>{areaInvolved}</td>
      <td>{description}</td>
      <td>{value}</td>
    </tr>
  )
}

const renderOrgansRows = (apAndEcareBenefits: APAndECARERiderBenefits) => {
  const rows = [
    {
      description: apAndEcareBenefits.description.apAndEcare.accidents.lossOfLife,
      value: apAndEcareBenefits.value.apAndEcare.accidents.lossOfLife,
      Component: AccidentHeadingRow,
    },
    {
      description: apAndEcareBenefits.description.apAndEcare.accidents.lossOf1orMoreLimbs,
      value: apAndEcareBenefits.value.apAndEcare.accidents.lossOf1orMoreLimbs,
      Component: AccidentHeadingRow,
    },
    {
      description: apAndEcareBenefits.description.apAndEcare.accidents.lossOfSightOf1orBothEyes,
      value: apAndEcareBenefits.value.apAndEcare.accidents.lossOfSightOf1orBothEyes,
      Component: AccidentHeadingRow,
    },
    {
      description: apAndEcareBenefits.description.apAndEcare.accidents.lossOfHearing.description,
      value: '',
      Component: AccidentHeadingRow,
    },
    {
      description: apAndEcareBenefits.description.apAndEcare.accidents.lossOfHearing.bothEars,
      value: apAndEcareBenefits.value.apAndEcare.accidents.lossOfHearing.bothEars,
      Component: AccidentSubHeadingRow,
    },
    {
      description: apAndEcareBenefits.description.apAndEcare.accidents.lossOfHearing.oneEars,
      value: apAndEcareBenefits.value.apAndEcare.accidents.lossOfHearing.oneEars,
      Component: AccidentSubHeadingRow,
    },
    {
      description: apAndEcareBenefits.description.apAndEcare.accidents.lossOfSpeech,
      value: apAndEcareBenefits.value.apAndEcare.accidents.lossOfSpeech,
      Component: AccidentHeadingRow,
    },
    {
      description: apAndEcareBenefits.description.apAndEcare.accidents.lossOfLensOfBothEyes,
      value: apAndEcareBenefits.value.apAndEcare.accidents.lossOfLensOfBothEyes,
      Component: AccidentHeadingRow,
    },
    {
      description: apAndEcareBenefits.description.apAndEcare.accidents.lossOfAllJointsOf4fingersAndThumbOf.description,
      value: '',
      Component: AccidentHeadingRow,
    },
    {
      description: apAndEcareBenefits.description.apAndEcare.accidents.lossOfAllJointsOf4fingersAndThumbOf.rightHand,
      value: apAndEcareBenefits.value.apAndEcare.accidents.lossOfAllJointsOf4fingersAndThumbOf.rightHand,
      Component: AccidentSubHeadingRow,
    },
    {
      description: apAndEcareBenefits.description.apAndEcare.accidents.lossOfAllJointsOf4fingersAndThumbOf.leftHand,
      value: apAndEcareBenefits.value.apAndEcare.accidents.lossOfAllJointsOf4fingersAndThumbOf.leftHand,
      Component: AccidentSubHeadingRow,
    },
    {
      description: apAndEcareBenefits.description.apAndEcare.accidents.lossOfAllJointsOf4fingersOf.description,
      value: '',
      Component: AccidentHeadingRow,
    },
    {
      description: apAndEcareBenefits.description.apAndEcare.accidents.lossOfAllJointsOf4fingersOf.rightHand,
      value: apAndEcareBenefits.value.apAndEcare.accidents.lossOfAllJointsOf4fingersOf.rightHand,
      Component: AccidentSubHeadingRow,
    },
    {
      description: apAndEcareBenefits.description.apAndEcare.accidents.lossOfAllJointsOf4fingersOf.leftHand,
      value: apAndEcareBenefits.value.apAndEcare.accidents.lossOfAllJointsOf4fingersOf.leftHand,
      Component: AccidentSubHeadingRow,
    },
    {
      description: apAndEcareBenefits.description.apAndEcare.accidents.fracturedLegOrPatella,
      value: apAndEcareBenefits.value.apAndEcare.accidents.fracturedLegOrPatella,
      Component: AccidentHeadingRow,
    },
    {
      description: apAndEcareBenefits.description.apAndEcare.accidents.shorteningOfLegByLeast5cms,
      value: apAndEcareBenefits.value.apAndEcare.accidents.shorteningOfLegByLeast5cms,
      Component: AccidentHeadingRow,
    },
  ]

  return (
    <tbody key="ap-rider-benefit-table-organs">
      {rows
        .filter(({ description }) => isNotNil(description))
        // prettier-ignore
        .map((row, index) => {
          return <row.Component key={`ap-rider-benefit-table-organs-${index}`} {...row} />
        })}
    </tbody>
  )
}

const renderOrgansFingersRows = (apAndEcareBenefits: APAndECARERiderBenefits) => {
  const rows = [
    {
      description: apAndEcareBenefits.description.apAndEcare.accidents.lossOfThumb.description,
      value: '',
      Component: AccidentHeadingRow,
    },
    {
      description: apAndEcareBenefits.description.apAndEcare.accidents.lossOfThumb.twoJointsOfRightThumb,
      value: apAndEcareBenefits.value.apAndEcare.accidents.lossOfThumb.twoJointsOfRightThumb,
      Component: AccidentSubHeadingRow,
    },
    {
      description: apAndEcareBenefits.description.apAndEcare.accidents.lossOfThumb.oneJointsOfRightThumb,
      value: apAndEcareBenefits.value.apAndEcare.accidents.lossOfThumb.oneJointsOfRightThumb,
      Component: AccidentSubHeadingRow,
    },
    {
      description: apAndEcareBenefits.description.apAndEcare.accidents.lossOfThumb.twoJointsOfLeftThumb,
      value: apAndEcareBenefits.value.apAndEcare.accidents.lossOfThumb.twoJointsOfLeftThumb,
      Component: AccidentSubHeadingRow,
    },
    {
      description: apAndEcareBenefits.description.apAndEcare.accidents.lossOfThumb.oneJointsOfLeftThumb,
      value: apAndEcareBenefits.value.apAndEcare.accidents.lossOfThumb.oneJointsOfLeftThumb,
      Component: AccidentSubHeadingRow,
    },
    {
      description: apAndEcareBenefits.description.apAndEcare.accidents.lossOfOtherFinger.description,
      value: '',
      Component: AccidentHeadingRow,
    },
    {
      description:
        apAndEcareBenefits.description.apAndEcare.accidents.lossOfOtherFinger.threeJointsOf1fingerOfRightHand,
      value: apAndEcareBenefits.value.apAndEcare.accidents.lossOfOtherFinger.threeJointsOf1fingerOfRightHand,
      Component: AccidentSubHeadingRow,
    },
    {
      description: apAndEcareBenefits.description.apAndEcare.accidents.lossOfOtherFinger.twoJointsOf1fingerOfRightHand,
      value: apAndEcareBenefits.value.apAndEcare.accidents.lossOfOtherFinger.twoJointsOf1fingerOfRightHand,
      Component: AccidentSubHeadingRow,
    },
    {
      description: apAndEcareBenefits.description.apAndEcare.accidents.lossOfOtherFinger.oneJointsOf1fingerOfRightHand,
      value: apAndEcareBenefits.value.apAndEcare.accidents.lossOfOtherFinger.oneJointsOf1fingerOfRightHand,
      Component: AccidentSubHeadingRow,
    },
    {
      description: apAndEcareBenefits.description.apAndEcare.accidents.lossOfOtherFinger.threeJointsOf1fingerOfLeftHand,
      value: apAndEcareBenefits.value.apAndEcare.accidents.lossOfOtherFinger.threeJointsOf1fingerOfLeftHand,
      Component: AccidentSubHeadingRow,
    },
    {
      description: apAndEcareBenefits.description.apAndEcare.accidents.lossOfOtherFinger.twoJointsOf1fingerOfLeftHand,
      value: apAndEcareBenefits.value.apAndEcare.accidents.lossOfOtherFinger.twoJointsOf1fingerOfLeftHand,
      Component: AccidentSubHeadingRow,
    },
    {
      description: apAndEcareBenefits.description.apAndEcare.accidents.lossOfOtherFinger.oneJointsOf1fingerOfLeftHand,
      value: apAndEcareBenefits.value.apAndEcare.accidents.lossOfOtherFinger.oneJointsOf1fingerOfLeftHand,
      Component: AccidentSubHeadingRow,
    },
    {
      description: apAndEcareBenefits.description.apAndEcare.accidents.lossOfToe.description,
      value: '',
      Component: AccidentHeadingRow,
    },
    {
      description: apAndEcareBenefits.description.apAndEcare.accidents.lossOfToe.allJointsOfAllToesOf1foot,
      value: apAndEcareBenefits.value.apAndEcare.accidents.lossOfToe.allJointsOfAllToesOf1foot,
      Component: AccidentSubHeadingRow,
    },
    {
      description: apAndEcareBenefits.description.apAndEcare.accidents.lossOfToe.allJointsOfGreatToe,
      value: apAndEcareBenefits.value.apAndEcare.accidents.lossOfToe.allJointsOfGreatToe,
      Component: AccidentSubHeadingRow,
    },
    {
      description: apAndEcareBenefits.description.apAndEcare.accidents.lossOfToe.oneJointsOfGreatToe,
      value: apAndEcareBenefits.value.apAndEcare.accidents.lossOfToe.oneJointsOfGreatToe,
      Component: AccidentSubHeadingRow,
    },
  ]

  return (
    <tbody key="ap-rider-benefit-table-organs-fingers">
      {rows
        .filter(({ description }) => isNotNil(description))
        // prettier-ignore
        .map((row, index) => (
          <row.Component key={`ap-rider-benefit-table-organs-${index}`} {...row} />
        ))}
    </tbody>
  )
}

const renderBurnsRows = (apAndEcareBenefits: APAndECARERiderBenefits) => {
  const rows = [
    {
      areaInvolved: apAndEcareBenefits.description.apAndEcare.burns.head.description,
      description: apAndEcareBenefits.description.apAndEcare.burns.head.equalToOrGreaterThan2PercentButLessThan4Percent,
      value: apAndEcareBenefits.value.apAndEcare.burns.head.equalToOrGreaterThan2PercentButLessThan4Percent,
    },
    {
      areaInvolved: '',
      description: apAndEcareBenefits.description.apAndEcare.burns.head.equalToOrGreaterThan4PercentButLessThan6Percent,
      value: apAndEcareBenefits.value.apAndEcare.burns.head.equalToOrGreaterThan4PercentButLessThan6Percent,
    },
    {
      areaInvolved: '',
      description: apAndEcareBenefits.description.apAndEcare.burns.head.equalToOrGreaterThan6PercentButLessThan8Percent,
      value: apAndEcareBenefits.value.apAndEcare.burns.head.equalToOrGreaterThan6PercentButLessThan8Percent,
    },
    {
      areaInvolved: '',
      description: apAndEcareBenefits.description.apAndEcare.burns.head.equalToOrGreaterThan8Percent,
      value: apAndEcareBenefits.value.apAndEcare.burns.head.equalToOrGreaterThan8Percent,
    },
    {
      areaInvolved: apAndEcareBenefits.description.apAndEcare.burns.body.description,
      description:
        apAndEcareBenefits.description.apAndEcare.burns.body.equalToOrGreaterThan10PercentButLessThan12Point5Percent,
      value: apAndEcareBenefits.value.apAndEcare.burns.body.equalToOrGreaterThan10PercentButLessThan12Point5Percent,
    },
    {
      areaInvolved: '',
      description:
        apAndEcareBenefits.description.apAndEcare.burns.body.equalToOrGreaterThan12Point5PercentButLessThan15Percent,
      value: apAndEcareBenefits.value.apAndEcare.burns.body.equalToOrGreaterThan12Point5PercentButLessThan15Percent,
    },
    {
      areaInvolved: '',
      description:
        apAndEcareBenefits.description.apAndEcare.burns.body.equalToOrGreaterThan15PercentButLessThan20Percent,
      value: apAndEcareBenefits.value.apAndEcare.burns.body.equalToOrGreaterThan15PercentButLessThan20Percent,
    },
    {
      areaInvolved: '',
      description: apAndEcareBenefits.description.apAndEcare.burns.body.equalToOrGreaterThan20Percent,
      value: apAndEcareBenefits.value.apAndEcare.burns.body.equalToOrGreaterThan20Percent,
    },
  ]

  return (
    <tbody key="ap-rider-benefit-table-burns">
      {rows
        .filter(({ description }) => isNotNil(description))
        .map((row, index) => (
          <BurnsRow key={`ap-rider-benefit-table-burns-row-${index}`} {...row} />
        ))}
    </tbody>
  )
}

const renderApAndEcareBenefit = (apRider: ?(Rider & RiderState), ecareRider: ?(Rider & RiderState)) => {
  if (isNotNil(apRider) && isNil(ecareRider)) {
    return {
      title: `${apRider.description} ${apRider.name}`,
      benefitSummary: <RiderBenefitSummary rider={apRider} />,
    }
  }
  if (isNil(apRider) && isNotNil(ecareRider)) {
    return {
      title: `${ecareRider.description} ${ecareRider.name}`,
      benefitSummary: <RiderBenefitSummary rider={ecareRider} />,
    }
  }
  if (isNotNil(apRider) && isNotNil(ecareRider)) {
    const apRiderSumAssured = formatNumber(apRider.sumAssured)
    const ecareRiderSumAssured = formatNumber(ecareRider.sumAssured)
    const apRiderPremium = formatNumber(apRider.premium, 2)
    const ecareRiderPremium = formatNumber(ecareRider.premium, 2)
    return {
      title: `${ecareRider.description} (${apRider.name} & ${ecareRider.name})`,
      benefitSummary: (
        <div
          className="product"
          id={`${apRider.name.toLowerCase()}-${ecareRider.name.toLowerCase()}-rider-benefit-summary`}
        >
          <h6 className="name">
            <span className="text">{`${ecareRider.description} (${apRider.name} & ${ecareRider.name})`}</span>
            <span className="icon d-md-none">
              <ArrowRight />
            </span>
          </h6>
          <div className="info-list">
            <div className="info d-none d-md-block">
              <p className="label">&nbsp;</p>
              <p id={`${apRider.name.toLowerCase()}-benefit-rider-name`} className="heading">{`${apRider.name}`}</p>
              <p
                id={`${ecareRider.name.toLowerCase()}-benefit-rider-name`}
                className="heading"
              >{`${ecareRider.name}`}</p>
            </div>
            <div className="info">
              <p className="label">ระยะเวลาคุ้มครอง</p>
              <p
                id={`${apRider.name.toLowerCase()}-benefit-coverage-period`}
                className="value"
              >{`${getRiderYearsOfCoverage(apRider)} ปี`}</p>
              <p
                id={`${ecareRider.name.toLowerCase()}-benefit-coverage-period`}
                className="value d-none d-md-block"
              >{`${getRiderYearsOfCoverage(ecareRider)} ปี`}</p>
            </div>
            <div className="info">
              <p className="label">ระยะเวลาชำระเบี้ย</p>
              <p
                id={`${apRider.name.toLowerCase()}-benefit-payment-period`}
                className="value"
              >{`${getRiderYearsOfPayment(apRider)} ปี`}</p>
              <p
                id={`${ecareRider.name.toLowerCase()}-benefit-payment-period`}
                className="value d-none d-md-block"
              >{`${getRiderYearsOfPayment(ecareRider)} ปี`}</p>
            </div>
            <div className="info d-none d-md-block">
              <p className="label">จำนวนเอาประกัน</p>
              <p
                id={`${apRider.name.toLowerCase()}-benefit-sum-assured`}
                className="value"
              >{`${apRiderSumAssured} บาท`}</p>
              <p
                id={`${ecareRider.name.toLowerCase()}-benefit-sum-assured`}
                className="value"
              >{`${ecareRiderSumAssured} บาท`}</p>
            </div>
            <div className="info d-none d-md-block">
              <p className="label">เบี้ยประกัน</p>
              <p id={`${apRider.name.toLowerCase()}-benefit-premium`} className="value">{`${apRiderPremium} บาท`}</p>
              <p
                id={`${ecareRider.name.toLowerCase()}-benefit-premium`}
                className="value"
              >{`${ecareRiderPremium} บาท`}</p>
            </div>

            {/*Mobile*/}
            <div className="info d-md-none m-0">
              <p
                id={`${apRider.name.toLowerCase()}-benefit-rider-name-mobile`}
                className="value"
              >{`${apRider.name}`}</p>
            </div>
            <div className="info d-md-none" />
            <div className="info d-md-none">
              <p className="label">จำนวนเอาประกัน</p>
              <p
                id={`${apRider.name.toLowerCase()}-benefit-sum-assured-mobile`}
                className="value"
              >{`${apRiderSumAssured} บาท`}</p>
            </div>
            <div className="info d-md-none">
              <p className="label">เบี้ยประกัน</p>
              <p
                id={`${apRider.name.toLowerCase()}-benefit-premium-mobile`}
                className="value"
              >{`${apRiderPremium} บาท`}</p>
            </div>
            <div className="info d-md-none m-0">
              <p
                id={`${ecareRider.name.toLowerCase()}-benefit-rider-name-mobile`}
                className="value"
              >{`${ecareRider.name}`}</p>
            </div>
            <div className="info d-md-none" />
            <div className="info d-md-none">
              <p className="label">จำนวนเอาประกัน</p>
              <p
                id={`${ecareRider.name.toLowerCase()}-benefit-sum-assured-mobile`}
                className="value"
              >{`${ecareRiderSumAssured} บาท`}</p>
            </div>
            <div className="info d-md-none">
              <p className="label">เบี้ยประกัน</p>
              <p
                id={`${ecareRider.name.toLowerCase()}-benefit-premium-mobile`}
                className="value"
              >{`${ecareRiderPremium} บาท`}</p>
            </div>
          </div>
        </div>
      ),
    }
  }
}

const renderAPAndECAREBenefitTable = (apAndEcareBenefits: APAndECARERiderBenefits) => {
  return (
    <div className="details-table">
      <div className="detail">
        <p className="heading">{apAndEcareBenefits.description.apAndEcare.accidents.description}</p>
        <div id="organs">
          <table id="organs-table">
            <thead>
              <tr>
                <td>รายการทดแทน</td>
                <td>{MESSAGES.BENEFIT_BAHT}</td>
              </tr>
            </thead>
            {renderOrgansRows(apAndEcareBenefits)}
          </table>
          <table id="organs-fingers-table">
            <thead>
              <tr>
                <td>รายการทดแทน</td>
                <td>{MESSAGES.BENEFIT_BAHT}</td>
              </tr>
            </thead>
            {apAndEcareBenefits && renderOrgansFingersRows(apAndEcareBenefits)}
          </table>
        </div>
        <div className="disclaimer">
          <p>{apAndEcareBenefits.description.apAndEcare.accidents.disclaimer}</p>
        </div>
      </div>
      <div className="detail">
        <p className="heading">{apAndEcareBenefits.description.apAndEcare.burns.description}</p>
        <table id="burns-table">
          <thead>
            <tr>
              <td>บริเวณที่ไหม้</td>
              <td>ความเสียหายคิดเป็นเปอร์เซ็นต์ของผิวหนังทั้งหมด</td>
              <td>{MESSAGES.BENEFIT_BAHT}</td>
            </tr>
          </thead>
          {renderBurnsRows(apAndEcareBenefits)}
        </table>
      </div>
      {apAndEcareBenefits.description.ecare && apAndEcareBenefits.value.ecare && (
        <div className="detail">
          <div className="temporary-disability">
            {apAndEcareBenefits.description.ecare.temporaryDisability.description}
            <ol>
              <li className="heading list-unstyled">
                <span className="title">
                  {apAndEcareBenefits.description.ecare.temporaryDisability.totalAndTemporaryDisability}
                </span>
                <span className="value">
                  {apAndEcareBenefits.value.ecare.temporaryDisability.totalAndTemporaryDisability}&nbsp; บาท ต่อสัปดาห์
                </span>
              </li>
              <li className="heading list-unstyled">
                <span className="title">
                  {apAndEcareBenefits.description.ecare.temporaryDisability.partialAndTemporaryDisability}
                </span>
                <span className="value">
                  {apAndEcareBenefits.value.ecare.temporaryDisability.partialAndTemporaryDisability}&nbsp; บาท
                  ต่อสัปดาห์
                </span>
              </li>
            </ol>
            <div className="disclaimer">{apAndEcareBenefits.description.ecare.temporaryDisability.disclaimer}</div>
          </div>
          <div className="heading temporary-disability">
            <span className="title">{apAndEcareBenefits.description.ecare.permanentDisability}</span>
            <span className="value">{apAndEcareBenefits.value.ecare.permanentDisability} บาท ต่อเดือน</span>
          </div>
          <div className="heading temporary-disability">
            <span className="title">{apAndEcareBenefits.description.ecare.hospitalConfinement}</span>
            <span className="value">{apAndEcareBenefits.value.ecare.hospitalConfinement} บาท ต่อสัปดาห์</span>
          </div>
          <div className="heading temporary-disability">
            <span className="title">{apAndEcareBenefits.description.ecare.accidentalReimbursed}</span>
            <span className="value">{apAndEcareBenefits.value.ecare.accidentalReimbursed} บาท</span>
            <p className="disclaimer">{apAndEcareBenefits.description.ecare.disclaimer}</p>
          </div>
        </div>
      )}
    </div>
  )
}

const APAndECARERiderBenefit = ({
  rider: { apRider, ecareRider },
  apAndEcareBenefits,
}: APAndECARERiderBenefitProps) => {
  const { title, benefitSummary } = renderApAndEcareBenefit(apRider, ecareRider) || {}
  const benefitTable = apAndEcareBenefits ? renderAPAndECAREBenefitTable(apAndEcareBenefits) : null
  const chosenRider = isNotNil(apRider) ? apRider : ecareRider
  const isChosenBoth = isNotNil(apRider) && isNotNil(ecareRider)
  return (
    <RiderWithBenefitModal
      id="ap-ecare-rider-benefit"
      className="rider ap-ecare-rider"
      title={title}
      modalChildren={benefitTable}
    >
      {benefitSummary}
      {benefitTable}
      {chosenRider && <AgentProtectionDisclaimer rider={chosenRider} />}
      {chosenRider && (
        <AdditionalDisclaimer
          rider={chosenRider}
          overrideTitle={isChosenBoth ? 'อุบัติเหตุและค่ารักษาพยาบาล (AP & ECARE)' : ''}
        />
      )}
    </RiderWithBenefitModal>
  )
}

export default APAndECARERiderBenefit
