import _ from 'lodash'
import { FormFeedback } from 'reactstrap'
import { connect } from 'react-redux'
import { omit, filter, flow, first, defaultTo, isArray, isString } from 'lodash/fp'
import { lifecycle, compose, mapProps, withProps, withStateHandlers } from 'recompose'

import { isSubmitting } from 'e-submission/apps/selectors'
import { isEmpty } from 'e-submission/utils/pattern'
import FormGroup from 'e-submission/components/Layout/FormGroup'

export default compose(
  withProps(({ errorMessages, requiredMessages, warningMessages }) =>
    flow(
      filter(({ messages }) => _.size(messages) > 0),
      first,
      defaultTo({})
    )([
      { messages: requiredMessages, formColor: 'danger' },
      { messages: warningMessages, formColor: 'warning' },
      { messages: errorMessages, formColor: 'danger' },
    ])
  ),
  connect((state) => ({ isSubmitting: isSubmitting(state) })),
  withStateHandlers(({ isSubmitting }) => ({ validated: isSubmitting }), {
    validate: (state, { value }) => (validated = true) => {
      if (!isEmpty(value) && !validated) {
        return { validated: true }
      }
      return { validated }
    },
  }),
  lifecycle({
    UNSAFE_componentWillReceiveProps(nextProps) {
      if (!this.props.isSubmitting && nextProps.isSubmitting) {
        this.props.validate(true)
      }
    },
  }),
  (Component) => ({
    messages,
    infoMessage,
    formColor,
    id,
    label,
    fullLabel,
    fullLabelToggleTexts,
    isShowFullLabel,
    toggleFullLabel,
    validated,
    styleOptions,
    invalidLabel,
    useLocalFormValidation,
    warningMessage,
    ...props
  }) => {
    let Label
    if (isString(label)) {
      const labelText = !isShowFullLabel ? label : fullLabel
      Label = <label dangerouslySetInnerHTML={{ __html: labelText }} />
    } else if (isArray(label)) {
      Label = (
        <ul className="formEngineUl">
          {label.map((value, index) => (
            <li key={index}>{value}</li>
          ))}
        </ul>
      )
    }

    const toggleText = fullLabel
      ? !isShowFullLabel
        ? _.get(fullLabelToggleTexts, 'toShow')
        : _.get(fullLabelToggleTexts, 'toHide')
      : null

    return (
      <FormGroup id={id} {...styleOptions} color={validated ? formColor : ''}>
        {Label}
        {toggleText ? (
          <span className="full-label-toggle" onClick={toggleFullLabel}>
            {toggleText}
          </span>
        ) : null}
        {/* DIGSALTOOL-2781: This info message need to located above OK button */}
        {infoMessage && id === 'ATP.submit' && (
          <FormFeedback
            className="info-message attached-icon atp-review-page"
            style={{
              marginBottom: '10px',
            }}
          >
            <p>{infoMessage}</p>
          </FormFeedback>
        )}
        {useLocalFormValidation ? <Component {...props} isValid={isEmpty(messages)} /> : <Component {...props} />}
        {validated &&
          _.map(messages, (message, index) => (
            <FormFeedback className="validation-message attached-icon" key={index}>
              <p>{message}</p>
            </FormFeedback>
          ))}
        {warningMessage && (
          <FormFeedback className="warning-message attached-icon">
            <p>{warningMessage}</p>
          </FormFeedback>
        )}
        {infoMessage && id !== 'ATP.submit' && (
          <FormFeedback className="info-message attached-icon">
            <p>{infoMessage}</p>
          </FormFeedback>
        )}
      </FormGroup>
    )
  },
  mapProps(omit(['isSubmitting', 'errorMessages', 'requiredMessages', 'warningMessages']))
)
