import { get, first, map, flow } from 'lodash/fp'

import applicationCordovaWrapper from 'e-submission/domain/data/application-cordova-wrapper'
import { applicationWrapper } from 'e-submission/domain/data'
import { isCordova } from 'e-submission/utils'

const wrap = (fn) => (isCordova ? fn : () => Promise.resolve())

export const openCamera = wrap(applicationCordovaWrapper.openCamera)
export const subscribeReplicationStatus = wrap(applicationCordovaWrapper.subscribeReplicationStatus)

export const setAttachment = (application, imageName, image, user, inInsured = false) => {
  return applicationWrapper()
    .setAttachment(application, imageName, image, user, inInsured)
    .then(({ data }) => data)
}
export const getAttachment = (application, imageName, user) => {
  return applicationWrapper()
    .getAttachment(application, imageName, user)
    .then(({ data }) => data)
}

export const listApplication = (user) => {
  return applicationWrapper()
    .getAll(user)
    .then(({ data }) => data)
    .then(flow(get('rows'), map('doc')))
}

export const listApplicationSummary = (user) => {
  return applicationWrapper()
    .getSummaries(user)
    .then(({ data }) => data)
    .then(flow(get('rows'), map('value')))
    .then((value) => value.filter(({ metadata }) => metadata === undefined || (metadata && metadata.hide === false)))
}

export const createApplication = (appId, user) => {
  return applicationWrapper()
    .create(appId, user)
    .then(({ data }) => data)
}

export const getApplicationRev = (app, user) => {
  return applicationWrapper()
    .getRev(app, user)
    .then(({ data }) => data)
    .then(flow(get('rows'), map('value'), first, get('rev')))
}

export const getApplication = (app, user) => {
  return applicationWrapper()
    .get(app, user)
    .then(({ data }) => data)
}

export const saveApplication = (draft, user) => {
  return applicationWrapper()
    .update(draft, user)
    .then(({ data }) => data)
}
