import { combineReducers } from 'redux'

// Import reducers
import ui from './uiReducer'
import data from './dataReducer'
import { apps } from './appReducer'
import { quickQuoteInterface } from './quickQuoteInterfaceReducer'
import { appVersion } from 'core/reducers'

export const reducer = combineReducers({
  apps,
  data,
  ui,
  quickQuoteInterface,
  appVersion,
})
