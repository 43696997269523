// @flow

import { Modal as StrapModal } from 'reactstrap'

class Modal extends React.Component<*> {
  static defaultProps = {
    onEnter: () => {},
    onExit: () => {},
    toggle: () => {},
  }

  onEnter = () => {
    this.props.onEnter()
    document.addEventListener('backbutton', this.props.toggle)
  }

  onExit = () => {
    this.props.onExit()
    document.removeEventListener('backbutton', this.props.toggle)
  }

  render() {
    return <StrapModal {...this.props} onEnter={this.onEnter} onExit={this.onExit} />
  }
}

Modal.propTypes = StrapModal.propTypes

export default Modal
