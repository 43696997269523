// @flow
import Mustache from 'mustache'
import MESSAGES from 'core/data-model/constants/bi-messages'
import { christianToThaiDate } from 'core/service/lib/date'

type LegalDisclaimerProps = {
  startDate: Date,
  endDate: Date,
}

export const LegalConditionDisclaimer = ({ startDate, endDate }: LegalDisclaimerProps) => {
  return (
    <div id="legal-disclaimer-market-conduct" className="legal group">
      <h3>{MESSAGES.LEGAL_DISCLAIMER}</h3>
      <ol className="nested-counter">
        <li> {MESSAGES.PRELIMINARY_OFFER_MAY_CHANGE}</li>
        <li>
          {Mustache.render(MESSAGES.FLEXI_HEALTH_OFFER_SALE_PERIOD, {
            startOffer: christianToThaiDate(startDate),
            endOffer: christianToThaiDate(endDate),
          })}
        </li>
      </ol>
    </div>
  )
}
