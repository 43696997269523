import _ from 'lodash'
import { createSectionCreator, createField } from './utils'
import {
  getPersonalNationalityList,
  getCountryList,
  getSelectedPersonalTitleList,
  getIsDataEkycConfirmed,
  getPersonalDocumentTypeList,
  getRelationshipListForPayer,
  getRelationshipSpouse,
  getPersonalDocumentTypeListEkyc,
  getInsureOrPayerFirstname,
  getInsureOrPayerLastname,
  isEDAViewFormOnly,
  getMotorcycleUsageOption,
} from 'e-submission/apps/selectors'
import { setAppValue } from 'e-submission/apps/actions'
import { getInsuredData, getQuickQuoteProductCategory } from './selectors'
import {
  getQuickQuoteIsPayerOtherOccupationSelected,
  getQuickQuoteIsOtherOccupationSelected,
  getQuickQuotePayerRelation,
  isQuickQuoteRequireTypeOfBusiness,
  getAuraInsured,
  isIGrow,
  isPACategory,
  isJuvenilePurchasing,
  isJuvenile,
  isS7,
  isDefaultIDCardForSelfS7CC,
  showSecWarningMsg,
  isAllowCRSForm,
  isATPPaid,
  isFormTypeEDA,
} from './selectors'
import values from 'core/data-model/constants/values'
import DEFAULTS from 'core/data-model/constants/defaults'
import { getToggles } from 'quick-quote/feature-toggles'

export const nameField = (app, insuredOrPayer) => {
  const title = _.get(app, `${insuredOrPayer}.title`)
  const isOtherTitle = _.get(title, 'value') === 'other'
  const ekycCompleted = _.get(app, `remoteSelling.${insuredOrPayer}.completedEkyc`) == true

  const label = {
    firstName: insuredOrPayer === 'insured' ? 'ชื่อของผู้เอาประกันภัย' : 'ชื่อของผู้ชำระเบี้ย',
    lastName: insuredOrPayer === 'insured' ? 'นามสกุลของผู้เอาประกันภัย' : 'นามสกุลของผู้ชำระเบี้ย',
  }

  const isDataConfirmed =
    getToggles().ENABLE_EKYC_F2F && _.get(app, `ekycInfo.${insuredOrPayer}.isDataConfirmed`, false)

  return _.compact([
    createField(`${insuredOrPayer}.title`, [
      {
        label: 'คำนำหน้า',
        id: `${insuredOrPayer}.title`,
        c: 'Dropdown',
        required: true,
        p: {
          mergeState: (state) => ({
            options: getSelectedPersonalTitleList(state, insuredOrPayer),
            disabled: getToggles().ENABLE_EKYC_F2F_RECREATE_CASE ? isDataConfirmed : getIsDataEkycConfirmed(state),
          }),
        },
      },
    ]),
    isOtherTitle &&
      createField(`${insuredOrPayer}.title.text`, [
        {
          label: 'ระบุคำนำหน้า',
          id: `${insuredOrPayer}.title.text`,
          c: 'TextInput',
          required: true,
          p: {
            mergeState: (state) => ({
              disabled: getToggles().ENABLE_EKYC_F2F_RECREATE_CASE ? isDataConfirmed : getIsDataEkycConfirmed(state),
            }),
          },
        },
      ]),
    createField(`${insuredOrPayer}.firstName`, [
      {
        label: label.firstName,
        id: `${insuredOrPayer}.firstName`,
        c: 'TextInput',
        required: true,
        p: {
          mergeState: (state) => ({
            disabled: getToggles().ENABLE_EKYC_F2F_RECREATE_CASE
              ? isDataConfirmed
              : getIsDataEkycConfirmed(state) || ekycCompleted,
            value: getInsureOrPayerFirstname(insuredOrPayer, state),
          }),
          onChange: ({ value, dispatch }) => {
            dispatch(setAppValue(`${insuredOrPayer}.firstName`, _.trim(value)))
          },
          mergeProps: ({ dispatch }) => ({
            onBlur: (e) => {
              dispatch(setAppValue(`${insuredOrPayer}.firstName`, _.trim(e.target.value)))
            },
          }),
        },
      },
    ]),
    createField(`${insuredOrPayer}.lastName`, [
      {
        label: label.lastName,
        id: `${insuredOrPayer}.lastName`,
        c: 'TextInput',
        required: true,
        p: {
          mergeState: (state) => ({
            disabled: getToggles().ENABLE_EKYC_F2F_RECREATE_CASE
              ? isDataConfirmed
              : getIsDataEkycConfirmed(state) || ekycCompleted,
            value: getInsureOrPayerLastname(insuredOrPayer, state),
          }),
          onChange: ({ value }) => {
            dispatch(setAppValue(`${insuredOrPayer}.lastName`, _.trim(value)))
          },
          mergeProps: ({ dispatch }) => ({
            onBlur: (e) => {
              dispatch(setAppValue(`${insuredOrPayer}.lastName`, _.trim(e.target.value)))
            },
          }),
        },
      },
    ]),
  ])
}

export const identityField = (app, insuredOrPayer, isToggleOn = false) => {
  const idTypeCode = _.get(app, [insuredOrPayer, 'idType', 'value'], 'CITIZEN_ID')
  const ekycCompleted = _.get(app, `remoteSelling.${insuredOrPayer}.completedEkyc`) == true
  const defaultIDCardForSelfS7CC = isDefaultIDCardForSelfS7CC(app) && insuredOrPayer === 'insured'
  let isDataConfirmed
  if (getToggles().ENABLE_EKYC_F2F_RECREATE_CASE) {
    isDataConfirmed = getToggles().ENABLE_EKYC_F2F && _.get(app, `ekycInfo.${insuredOrPayer}.isDataConfirmed`, false)
  } else {
    isDataConfirmed = _.get(app, `ekycInfo.isDataConfirmed`, false)
  }

  const codeSelect = createField(`${insuredOrPayer}.idTypeCode-${defaultIDCardForSelfS7CC ? 'dis' : 'not-dis'}`, [
    {
      label: 'เอกสารที่ใช้แสดง',
      id: `${insuredOrPayer}.idType`,
      c: 'Dropdown',
      required: isToggleOn ? false : true,
      p: {
        mergeState: (state) => {
          const options = isToggleOn
            ? [
                {
                  text: 'บัตรประจำตัวประชาชน',
                  value: 'CITIZEN_ID',
                },
                {
                  text: 'หนังสือเดินทาง',
                  value: 'PASSPORT',
                },
              ]
            : getToggles().ENABLE_APPMAN_REMOTE_SELLING || getToggles().ENABLE_EKYC_F2F
            ? getPersonalDocumentTypeListEkyc(
                state,
                [insuredOrPayer, 'nationality', 'value'],
                ['quickQuote', insuredOrPayer, 'age'],
                insuredOrPayer
              )
            : getPersonalDocumentTypeList(state, [insuredOrPayer, 'nationality', 'value'])

          let isDisabled = getToggles().ENABLE_EKYC_F2F_RECREATE_CASE
            ? isDataConfirmed
            : getIsDataEkycConfirmed(state) || ekycCompleted

          const isAppliedATPonline = getToggles().ENABLE_EDA && isATPPaid(app) && isFormTypeEDA(app)
          const onlyViewEDASummaryInForm = isAppliedATPonline && isEDAViewFormOnly(state)

          if (onlyViewEDASummaryInForm) {
            isDisabled = true
          }

          return {
            disabled: isDisabled,
            options,
          }
        },
      },
    },
  ])

  // mergeState เหมือน merge ทุก props
  const inputIdTypes = {
    CITIZEN_ID: {
      id: 'citizenId',
      label: 'เลขประจำตัวประชาชน',
      c: isDataConfirmed ? 'TextInput' : 'NumberInput',
    },
    // This needs to have id citizen id because of business logic. don't change to house-registration
    GOV_ID: {
      id: 'citizenId',
      label: 'เลขประจำตัวประชาชน',
      c: 'NumberInput',
    },
    PASSPORT: {
      id: 'passport',
      label: 'หมายเลขหนังสือเดินทาง',
      c: 'TextInput',
    },
  }

  const idInput = createField(
    `${insuredOrPayer}.idNo.${inputIdTypes[idTypeCode].id}-${defaultIDCardForSelfS7CC ? 'dis' : 'not-dis'}`,
    [
      {
        label: inputIdTypes[idTypeCode].label,
        id: `${insuredOrPayer}.idNo.${inputIdTypes[idTypeCode].id}`,
        c: inputIdTypes[idTypeCode].c,
        required: true,
        p: {
          mergeState: (state) => ({
            disabled: getToggles().ENABLE_EKYC_F2F_RECREATE_CASE
              ? isDataConfirmed
                ? isDataConfirmed
                : ekycCompleted || defaultIDCardForSelfS7CC
              : getIsDataEkycConfirmed(state)
              ? getIsDataEkycConfirmed(state)
              : ekycCompleted || defaultIDCardForSelfS7CC,
          }),
        },
      },
    ]
  )
  return [codeSelect, idInput]
}

const educationField = (app, insuredOrPayer) =>
  createField(`${insuredOrPayer}.education`, [
    {
      label: 'การศึกษา',
      c: 'RadioButton',
      id: `${insuredOrPayer}.education`,
      required: true,
      p: {
        options: [
          {
            value: 'belowBachelor',
            text: 'ต่ำกว่าปริญญาตรี',
          },
          {
            value: 'bachelor',
            text: 'ปริญญาตรี',
          },
          {
            value: 'masterOrAbove',
            text: 'สูงกว่าปริญญาตรี',
          },
        ],
      },
    },
  ])

const noOfDependenceField = (app, insuredOrPayer) => {
  const disable = getToggles().DEFAULT_JUVENILE_DEPENDENCE_ZERO && isJuvenile(app)
  return createField(`${insuredOrPayer}.noOfDependence-${disable.toString()}`, [
    {
      label: 'จำนวนบุคคลที่อยู่ภายใต้การดูแล (พ่อแม่ บุตร คู่สมรส)',
      id: `${insuredOrPayer}.noOfDependence`,
      c: 'Dropdown',
      required: true,
      placeHolder: 'กรุณาเลือก',
      p: {
        disabled: disable,
        mergeState: (state) => ({
          options: [
            {
              text: '0',
              value: '0',
            },
            {
              text: '1',
              value: '1',
            },
            {
              text: '2',
              value: '2',
            },
            {
              text: '3',
              value: '3',
            },
            {
              text: '4',
              value: '4',
            },
            {
              text: '5',
              value: '5',
            },
            {
              text: '6',
              value: '6',
            },
            {
              text: '7',
              value: '7',
            },
            {
              text: '8',
              value: '8',
            },
            {
              text: '9',
              value: '9',
            },
            {
              text: '10',
              value: '10',
            },
          ],
        }),
      },
    },
  ])
}

const mobileField = (app, insuredOrPayer) => {
  if (getToggles().ENABLE_SEC_AUDIT_DUP_PHONE) {
    const isMobileNumber = getInsuredData(app)?.phoneNumber?.length === 10

    if (isMobileNumber) {
      return createField(`${insuredOrPayer}.mobileNumber-secAudit`, [
        {
          label: 'เบอร์โทรศัพท์มือถือ',
          id: `${insuredOrPayer}.mobileNumber`,
          c: 'NumberInput',
          required: true,
          p: {},
          infoMessage: getToggles().ENABLE_EKYC_F2F ? '' : 'เบอร์นี้จะถูกใช้ SMS เพื่อยืนยันตัวตนในตอนเซ็นเอกสาร',
          warningMessage: showSecWarningMsg(app),
        },
      ])
    }

    return createField(`${insuredOrPayer}.mobileNumber`, [
      {
        label: 'เบอร์โทรศัพท์มือถือ',
        id: `${insuredOrPayer}.mobileNumber`,
        c: 'NumberInput',
        required: true,
        p: {},
        infoMessage: getToggles().ENABLE_EKYC_F2F ? '' : 'เบอร์นี้จะถูกใช้ SMS เพื่อยืนยันตัวตนในตอนเซ็นเอกสาร',
        warningMessage: showSecWarningMsg(app),
      },
    ])
  }

  return createField(`${insuredOrPayer}.mobileNumber`, [
    {
      label: 'เบอร์โทรศัพท์มือถือ',
      id: `${insuredOrPayer}.mobileNumber`,
      c: 'NumberInput',
      required: true,
      p: {},
      infoMessage: getToggles().ENABLE_EKYC_F2F ? '' : 'เบอร์นี้จะถูกใช้ SMS เพื่อยืนยันตัวตนในตอนเซ็นเอกสาร',
    },
  ])
}

const telephoneField = (app, insuredOrPayer) => {
  return createField(`${insuredOrPayer}.telephoneNumber`, [
    {
      label: 'เบอร์โทรศัพท์บ้าน (ถ้ามี)',
      id: `${insuredOrPayer}.telephoneNumber`,
      c: 'NumberInput',
      p: {},
    },
  ])
}

const emailField = (app, insuredOrPayer) => {
  const isDynamicRequired = getToggles().ENABLE_E_POLICY
  const isRequiredByEPolicy = isDynamicRequired
    ? _.get(app, `${insuredOrPayer}.isPolicyDeliveryByEmail.value`, false)
    : false

  const isRequiredEmailForILP = getToggles().ENABLE_REQUIRED_EMAIL_FOR_ILP
  const isILP = getQuickQuoteProductCategory(app) === values.INVESTMENT
  const isRequiredByILP = isRequiredEmailForILP && isILP

  const required = isRequiredByEPolicy || isRequiredByILP
  const infoMessage =
    isDynamicRequired && isRequiredByEPolicy ? 'ท่านจะได้รับเอกสารการสมัคร และใบเสร็จรับเงินชั่วคราวทางอีเมล' : ''
  const label = !required ? 'Email (ถ้ามี)' : 'Email'
  return createField(`${insuredOrPayer}.email-${required ? 'required' : 'not-required'}`, [
    {
      label: label,
      id: `${insuredOrPayer}.email`,
      c: 'TextInput',
      required: required,
      infoMessage: infoMessage,
    },
  ])
}

export const nationalityField = (app, insuredOrPayer) => {
  const ekycCompleted = _.get(app, `remoteSelling.${insuredOrPayer}.completedEkyc`) == true
  return [
    createField(`${insuredOrPayer}.nationality-${isS7(app) ? 'dis' : 'not-dis'}`, [
      {
        label: 'สัญชาติ',
        id: `${insuredOrPayer}.nationality`,
        c: 'Dropdown',
        required: true,
        p: {
          mergeState: (state) => ({
            options: getPersonalNationalityList(state),
            disabled:
              getAuraInsured(app) ||
              (isS7(app) && insuredOrPayer === 'insured') ||
              ekycCompleted ||
              getIsDataEkycConfirmed(state),
          }),
        },
      },
    ]),
  ]
}

export const placeOfBirthField = (app, insuredOrPayer) => {
  return [
    createField(`${insuredOrPayer}.placeOfBirthCity}`, [
      {
        label: 'จังหวัด/เมืองที่เกิด',
        id: `${insuredOrPayer}.placeOfBirthCity`,
        c: 'TextInput',
        required: isAllowCRSForm(app),
      },
    ]),
    createField(`${insuredOrPayer}.placeOfBirthCountry}`, [
      {
        label: 'ประเทศที่เกิด',
        id: `${insuredOrPayer}.placeOfBirthCountry`,
        c: 'Dropdown',
        required: isAllowCRSForm(app),
        placeHolder: 'กรุณาเลือก',
        p: {
          mergeState: (state) => ({
            options: getCountryList(state),
          }),
        },
      },
    ]),
  ]
}

const maritalStatusField = (app, insuredOrPayer) => {
  const firstField = [
    createField(`${insuredOrPayer}.maritalStatus`, [
      {
        label: 'สถานภาพ',
        c: 'RadioButton',
        id: `${insuredOrPayer}.maritalStatus`,
        required: true,
        p: {
          options: [
            {
              value: 'S',
              text: 'โสด',
            },
            {
              value: 'M',
              text: 'สมรส',
            },
            {
              value: 'W',
              text: 'หม้าย',
            },
            {
              value: 'D',
              text: 'หย่า',
            },
          ],
        },
      },
    ]),
  ]
  return firstField
}

const relationshipToInsured = (app, insuredOrPayer) => {
  const isShowJuvenilePurchasingForm = getToggles().ENABLE_JUVENILE_PURCHASING_FORM && isJuvenilePurchasing(app)
  const isSpousePayer = getQuickQuotePayerRelation(app) === DEFAULTS.SPOUSE
  const getInfoMessage = () => {
    if (isShowJuvenilePurchasingForm)
      return `กรณีที่ท่านระบุความสัมพันธ์ของผู้ชำระเบี้ยและผู้เอาประกันเป็นบิดามารดา
      ข้อมูลของผู้ชำระเบี้ยจะถูกนำไปแสดงในหน้าของแบบสอบถามการซื้อแบบประกันสุขภาพผู้เยาว์โดยอัตโนมัติ`
    else if (isSpousePayer)
      return `ความสัมพันธ์ของผู้ชำระเบี้ยที่ระบุ ต้องแนบหลักฐานพิสูจน์ความสัมพันธ์กับผู้เอาประกันภัยไว้ที่ส่วนเอกสารอื่นๆ เพิ่มเติม ก่อนนำส่งใบคำขอเอาประกันภัย`
    else return ''
  }
  const infoMsg = getInfoMessage()

  return [
    createField('payer.relationshipToInsured', [
      {
        label: 'ความสัมพันธ์กับผู้เอาประกันภัย',
        id: 'payer.relationshipToInsured',
        c: 'Dropdown',
        required: true,
        p: {
          disabled: getAuraInsured(app) || isSpousePayer,
          mergeState: (state) => ({
            options: isSpousePayer ? getRelationshipSpouse(state) : getRelationshipListForPayer(state),
          }),
          placeHolder: 'กรุณาเลือกความสัมพันธ์กับผู้เอาประกันภัย',
        },
        infoMessage: infoMsg,
      },
    ]),
  ]
}

const mainOccupationIncomePerMonthField = (life, insuredOrPayer) => {
  return createField(
    `${insuredOrPayer}.mainOccupationIncomePerMonth`,
    [
      {
        label: 'รายได้ต่อเดือน',
        id: `${insuredOrPayer}.mainOccupation`,
        c: 'OccupationText',
        p: {
          type: 'main',
          insuredOrPayer,
        },
      },
      {
        id: `${insuredOrPayer}.mainOccupationIncomePerMonth`,
        c: 'NumberInput',
        required: true,
        p: {
          disabled: getAuraInsured(life),
          thousandSeparator: true,
        },
      },
    ],
    {
      marginTopLess: true,
    }
  )
}

const typeOfBusiness = (life, insuredOrPayer) => {
  return createField(
    `${insuredOrPayer}.typeOfBusiness`,
    [
      {
        label: 'กรุณาระบุประเภทของธุรกิจ/สินค้าและบริการ',
        id: `${insuredOrPayer}.typeOfBusiness`,
        c: 'TextInput',
        required: true,
      },
    ],
    {
      marginTopLess: true,
    }
  )
}

const otherOccupationIncomePerMonth = (life, insuredOrPayer) => {
  return createField(
    `${insuredOrPayer}.otherOccupationIncomePerMonth`,
    [
      {
        label: 'รายได้ต่อเดือน',
        id: `${insuredOrPayer}.otherOccupation`,
        c: 'OccupationText',
        p: {
          type: 'other',
          insuredOrPayer,
        },
      },
      {
        id: `${insuredOrPayer}.otherOccupationIncomePerMonth`,
        c: 'NumberInput',
        p: {
          disabled: getAuraInsured(life),
          thousandSeparator: true,
        },
      },
    ],
    {
      marginTopLess: true,
    }
  )
}

const incomePerYearField = (life, insuredOrPayer) => {
  return createField(
    `${insuredOrPayer}.incomePerYear`,
    [
      {
        label: 'รายได้ต่อปี',
        id: `${insuredOrPayer}.incomePerYear`,
        c: 'NumberInput',
        p: {
          thousandSeparator: true,
          displayType: 'text',
          allowNegative: true,
          suffix: ' บาท',
          style: {
            fontWeight: 'bold',
            fontSize: '1.5rem',
          },
        },
      },
    ],
    {
      style: {
        display: 'flex',
        alignItems: 'baseline',
        justifyContent: 'space-between',
      },
    }
  )
}

const motorcycleUsageField = (app, insuredOrPayer) => {
  const motorcycleUsage = _.get(app, `${insuredOrPayer}.motorcycleUsage`)
  const isOtherMotorcycle = _.get(motorcycleUsage, 'value') === 'other'
  return _.compact([
    createField(`${insuredOrPayer}.motorcycleUsage`, [
      {
        label: 'ท่านใช้รถจักรยานยนต์เป็นพาหนะหรือไม่',
        id: `${insuredOrPayer}.motorcycleUsage`,
        c: 'RadioButton',
        required: true,
        p: {
          mergeState: (state) => ({
            options: getMotorcycleUsageOption(state),
          }),
        },
      },
    ]),
    isOtherMotorcycle &&
      createField(
        `${insuredOrPayer}.motorcycleUsage.otherText`,
        [
          {
            label: 'อื่นๆ ระบุ',
            id: `${insuredOrPayer}.motorcycleUsage.otherText`,
            c: 'TextInput',
            required: true,
            p: {},
          },
        ],
        {
          style: {
            marginTop: '-1rem',
          },
        }
      ),
  ])
}

const getQuestions = (app, insuredOrPayer) => {
  const hasInsuredOtherOccupation = getQuickQuoteIsOtherOccupationSelected(app) === true
  const hasPayerOtherOccupation = getQuickQuoteIsPayerOtherOccupationSelected(app) === true

  const isShowIGrow = isIGrow(app)
  const hasOtherOccupationIncomePerMonth =
    (insuredOrPayer === 'insured' && hasInsuredOtherOccupation) ||
    (insuredOrPayer === 'payer' && hasPayerOtherOccupation)
  const hasMotorcycleUsageField =
    (isShowIGrow && insuredOrPayer === 'payer') || (!isShowIGrow && insuredOrPayer === 'insured')
  const isShowTypeOfBusiness =
    getToggles().ENABLE_TYPE_OF_BUSINESS && isQuickQuoteRequireTypeOfBusiness(insuredOrPayer)(app)

  return _.compact([
    ...nameField(app, insuredOrPayer),
    insuredOrPayer === 'insured' && mobileField(app, insuredOrPayer),
    insuredOrPayer === 'insured' && telephoneField(app, insuredOrPayer),
    insuredOrPayer === 'insured' && emailField(app, insuredOrPayer),
    ...(insuredOrPayer === 'payer' && relationshipToInsured(app, insuredOrPayer)
      ? relationshipToInsured(app, insuredOrPayer)
      : []),
    ...maritalStatusField(app, insuredOrPayer),
    ...(getToggles().ENABLE_PLACEOFBIRTH_SECTION && insuredOrPayer === 'insured'
      ? placeOfBirthField(app, insuredOrPayer)
      : []),
    ...nationalityField(app, insuredOrPayer),
    ...identityField(app, insuredOrPayer),
    insuredOrPayer === 'insured' && getToggles().ENABLE_FNA_PHASE2 && educationField(app, insuredOrPayer),
    insuredOrPayer === 'insured' && getToggles().ENABLE_FNA_PHASE2 && noOfDependenceField(app, insuredOrPayer),
    mainOccupationIncomePerMonthField(app, insuredOrPayer),
    isShowTypeOfBusiness && typeOfBusiness(app, insuredOrPayer),
    hasOtherOccupationIncomePerMonth && otherOccupationIncomePerMonth(app, insuredOrPayer),
    incomePerYearField(app, insuredOrPayer),
    ...(hasMotorcycleUsageField ? motorcycleUsageField(app, insuredOrPayer) : []),
  ])
}

export default createSectionCreator(getQuestions)
