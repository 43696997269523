// @flow
import { get } from 'lodash/fp'
import MESSAGES from 'core/data-model/constants/bi-messages'
import { getTermLife_GIO_LifeSuperSave_BenefitData } from 'core/service/basic-plan/benefit'
import type { DisplayProduct } from 'core/service/display-product'
import type { PolicyValue } from 'core/service/benefit-illustration/policy-value'

import { dashedOutOrWithPrecision } from 'quick-quote/product-life-save-pro/benefit-illustration/components/policy-value/policy-value-row'
import { lineBreak, buildStacks } from 'core/service/pdf-generation/utils/builder'
import Mustache from 'mustache'
import VALUES from 'core/data-model/constants/values'
import CONSTANTS from 'core/data-model/constants/messages'
import { formatNumber } from 'core/service/lib/number-format'

type Props = {
  basicPlan: DisplayProduct,
  calculatedCoveragePeriod: number,
  calculatedPaymentPeriod: number,
  sumAssured: number,
  basicPremium: number,
  productGraph: string,
  basicPlanDetailsLabelFn: (DisplayProduct) => string,
  policyValue: PolicyValue,
}

type SummarySectionProps = Props & {
  showGraph: boolean,
}

type BenefitValues = {
  minDeathCoverage: string,
  maturityBenefit: string,
}

export const buildBenefitValues = (sumAssured: number, basicPlan: PolicyValue): BenefitValues => {
  switch (basicPlan.code) {
    case VALUES.LIFE_SUPER_SAVE_14_5_CODE: {
      return {
        minDeathCoverage: dashedOutOrWithPrecision(
          (sumAssured * VALUES.LIFE_SUPER_SAVE_14_5_MAIN_CONTRACT_BENEFIT.MINIMUM_DEATH_BENEFIT_PERCENT) / 100,
          2
        ),
        maturityBenefit: dashedOutOrWithPrecision(
          (sumAssured * VALUES.LIFE_SUPER_SAVE_14_5_MAIN_CONTRACT_BENEFIT.MATURITY_BENEFIT_PERCENT) / 100,
          2
        ),
      }
    }
    default: {
      return {
        minDeathCoverage: 0,
        maturityBenefit: 0,
      }
    }
  }
}

export const _getBenefitDataByProductCode = (basicPlan: DisplayProduct) => {
  const productCode = get('basicPlanCode')(basicPlan)
  switch (productCode) {
    case VALUES.LIFE_SUPER_SAVE_14_5_CODE:
      return getTermLife_GIO_LifeSuperSave_BenefitData()
    default:
      return []
  }
}

export const buildProductGIOBenefitsSection = (basicPlan: DisplayProduct, sumAssured: number): Object[] => {
  const benefitData = _getBenefitDataByProductCode(basicPlan)
  const benefitValues = buildBenefitValues(sumAssured, basicPlan)
  const benefitDetail = benefitData.benefits.map((detail) => [
    { text: detail.description },
    { text: Mustache.render(detail.amount, benefitValues), alignment: 'right' },
  ])

  return [
    {
      text: benefitData.mainContractBenefitTitle,
      style: 'sub-title',
    },
    {
      table: {
        widths: ['85%', '*'],
        body: benefitDetail,
      },
      layout: 'noBorders',
    },
    lineBreak(),
  ]
}

export const productBenefitsSection = (props: Props) => {
  const { productGraph } = props
  const showGraph = productGraph !== ''
  return getSummarySection({ ...props, showGraph })
}

export const getSummarySection = ({
  basicPlanDetailsLabelFn,
  basicPlan,
  calculatedCoveragePeriod,
  calculatedPaymentPeriod,
  sumAssured,
  basicPremium,
  showGraph,
  productGraph,
}: SummarySectionProps) => {
  return [
    {
      pageBreak: 'before',
      text: basicPlanDetailsLabelFn(basicPlan),
      style: 'title',
    },
    {
      columns: buildStacks([
        {
          label: MESSAGES.COVERAGE_PERIOD,
          value: `${MESSAGES.COVERAGE_PERIOD_ISMART_SUFFIX}`,
        },
        {
          label: MESSAGES.PAYMENT_PERIOD,
          value: `${calculatedPaymentPeriod} ${CONSTANTS.TIME_UNIT_YEAR}`,
        },
        {
          label: MESSAGES.BASIC_PLAN_SUM_ASSURED,
          value: `${formatNumber(sumAssured)} ${CONSTANTS.BAHT}`,
        },
        {
          label: MESSAGES.BASIC_PREMIUM,
          value: `${formatNumber(basicPremium, 2)} ${CONSTANTS.BAHT}`,
        },
      ]),
      style: 'info',
    },
    showGraph ? { image: productGraph, alignment: 'center', width: 425, margin: [0, 20, 0, 30] } : {},
  ]
}

export const basicPlanDetailsLabelFn = (basicPlan: DisplayProduct): string =>
  Mustache.render(MESSAGES.BASIC_PLAN_DETAILS, {
    name: basicPlan.basicPlanCode === VALUES.LIFE_SUPER_SAVE_14_5_CODE ? basicPlan.nameThai : basicPlan.basicPlanName,
    code: basicPlan.basicPlanCode,
  })
