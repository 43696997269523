//@flow

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { withDropdown } from 'lib/with-hoc'
import { ProfileImage } from 'quick-quote/components'
import { getAppConfig } from '../../deploy-env/app-config'
import { Link } from 'react-router-dom'
import type { AuthenticatedUser } from 'core/data-model/identity'

type Props = {
  dropdownOpen: boolean,
  toggle: (?Object) => void,
  icon: string,
  user: AuthenticatedUser,
  logoutRequest: () => void,
}

export const LoginDropdown = ({ dropdownOpen, toggle, icon, user, logoutRequest }: Props) => {
  const handleProfileLink = (e) => {
    toggle()
    e.preventDefault()
    window.open(`${getAppConfig().KEYCLOAK_PROFILE_URL}`, '_blank')
  }

  const handleLogoutLink = (e) => {
    toggle()
    e.preventDefault()
    logoutRequest()
  }

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle} data-dropdownopen={dropdownOpen}>
      <DropdownToggle>
        <ProfileImage profile={user.profile} />
      </DropdownToggle>
      <DropdownMenu right>
        <span id="dt-profile" data-tag={`${user.profile.name} (${user.profile.agent_code || ''})`} />
        <Link className="dropdown-item-profile" to="/profile" onClick={handleProfileLink}>
          บัญชีผู้ใช้งาน
        </Link>
        <DropdownItem divider />
        <Link className="dropdown-item-logout" to="/logout" onClick={handleLogoutLink}>
          ออกจากระบบ
        </Link>
      </DropdownMenu>
    </Dropdown>
  )
}

export default withDropdown(LoginDropdown)
