// @flow

export type Choice = { choice: string, value: number }

export type Questionnaire = {
  id: number,
  question: string,
  choices: Choice[],
  selectedChoice: number | [number],
  hasScore: boolean,
}

export const getQuestionnaire = (): Questionnaire[] => {
  return require('core/service/data-config/investment/questionnaire.json')
}

export const getQuestionnaireV2 = () => {
  return require('core/service/data-config/investment/questionnaireV2.json')
}

export const getForeignExchangeRiskQuestion = (questionnaires: Questionnaire[]): Questionnaire => {
  return questionnaires.find((question) => question.id === 11)
}

export const isSelectedYesOnForeignExchangeRiskQuestion = (questionnaires: Questionnaire[]): Questionnaire => {
  const foreignExchangeRiskQuestion = getForeignExchangeRiskQuestion(questionnaires)
  return foreignExchangeRiskQuestion?.selectedChoice && foreignExchangeRiskQuestion?.selectedChoice === 1
}

export type RiskParams = {
  riskLevelValue: number,
  riskLevelText: string,
  answeredCount: number,
  answeredPercent: number,
  isEvaluated: boolean,
}

export const requireRADocument = (hasOverRiskFunds, hasForeignFunds, isSelectedYesOnForeignExchangeQuestion) => {
  return hasOverRiskFunds || (hasForeignFunds && !isSelectedYesOnForeignExchangeQuestion)
}
