// @flow
/* istanbul ignore file */

import _ from 'lodash'

const pouchDbFactoryFn = () => {
  const createInstance = (localDbUrl: string) => {
    let databaseInstance

    const PouchDB = require('pouchdb-browser').default
    PouchDB.plugin(require('pouchdb-load'))

    const cordovaAdapter = {
      adapter: 'cordova-sqlite',
      androidDatabaseImplementation: 2,
      iosDatabaseLocation: 'Library',
    }
    const indexedDBAdapter = { adapter: 'idb' }

    if (window.cordova) {
      PouchDB.plugin(require('pouchdb-adapter-cordova-sqlite/dist/pouchdb.cordova-sqlite'))
    }

    const instantiateDatatbase = () => {
      try {
        databaseInstance = new PouchDB(localDbUrl, indexedDBAdapter)
      } catch (err) {
        if (window.cordova) {
          databaseInstance = new PouchDB(localDbUrl, cordovaAdapter)
        } else {
          databaseInstance = new PouchDB(localDbUrl)
        }
      }
    }
    instantiateDatatbase()

    let getDataChecksum = () => {
      return databaseInstance
        .get('checksum', { ajax: { cache: true } })
        .then(({ value }) => value)
        .catch((e) => '')
    }

    let loadNewData = async (allDocs) => {
      const checksum = await getDataChecksum()
      let checkSumDoc = _.find(allDocs, { _id: 'checksum' }) || { value: '' }
      let newChecksum = checkSumDoc.value
      if (newChecksum !== checksum) {
        await databaseInstance.destroy()
        instantiateDatatbase()
        await databaseInstance.bulkDocs({ docs: allDocs })
      }
      return databaseInstance
    }

    // $FlowFixMe
    let databaseInstanceCreationPromise = new Promise((resolve) => resolve(require('core/data/dump/data.json')))
      .then(loadNewData)
      .catch((e) => console.warn(e))

    return {
      databaseInstanceCreationPromise,
    }
  }

  return {
    createInstance,
  }
}

export const pouchDbFactory = pouchDbFactoryFn()
