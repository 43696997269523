// @flow
import type { IdToken, User, AgentType, RawAgentType } from 'core/data-model/identity'
import { has, get, omit, flow } from 'lodash/fp'

export const getAuthenticatedUserProfile = (idToken: IdToken): User => {
  const type = !has('error')(idToken) ? 'Authenticated' : 'Anonymous'
  return {
    type,
    access_token: get('access_token')(idToken),
    profile: {
      ...omit('access_token')(idToken),
      agent_type: flow(get('agent_type'), getAgentType)(idToken),
    },
    distributorGroup: 'AGENT', // TODO: combine distributor group logic with this.
  }
}

export const getAgentType = (rawAgentType: RawAgentType): AgentType => {
  switch (rawAgentType) {
    case 'FA':
      return 'FSA'
    default:
      return rawAgentType
  }
}
