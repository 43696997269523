//@flow

import { dataWrapper } from 'core/data'
import type { PromiseFunction } from 'core/data'
import type { Rider } from 'core/data-model/rider'
const DOCUMENT_KEY = 'rider'
const { list, get } = dataWrapper

export const _listRiders = (list: PromiseFunction<Rider[]>) => list(DOCUMENT_KEY)

export const listRiders = (): Promise<Rider[]> => _listRiders(list)

export const _getRider = (get: PromiseFunction<Rider>, code: string): Promise<Rider> => get(`${DOCUMENT_KEY}_${code}`)

export const getRider = (code: string): Promise<Rider> => _getRider(get, code)
