export const optionsSendLinkMethod = [
  {
    value: 'email',
    text: 'อีเมล',
  },
  {
    value: 'sms',
    text: 'SMS',
  },
]
