// @flow
import type { Choice, Questionnaire } from 'core/data-model/fna/types'
import _ from 'lodash'
import { ListGroup, ListGroupItem } from 'reactstrap'
import ThirdPerson from './third-person'
import { getToggles } from '../../../feature-toggles'

type Props = {
  questionnaires: Questionnaire[],
  setMarketConductQuestion: Function,
  isSelectedNeverSplittingPremiumFromExpense: boolean,
  isSelectedThirdPartyJoin: boolean,
  setReasonForNeverSplittingPremiumFromExpense: Function,
  reasonForNeverSplittingPremiumFromExpense: string,
}

const Reason = ({ reason, setReason }) => {
  return (
    <div className="form-group">
      <div className="name-input">
        <label>เนื่องจาก </label>
        <input
          id="titleText"
          className="form-control"
          type="text"
          maxLength="100"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
      </div>
    </div>
  )
}

const Questionnaires = ({
  questionnaires,
  setMarketConductQuestion,
  isSelectedNeverSplittingPremiumFromExpense,
  setReasonForNeverSplittingPremiumFromExpense,
  reasonForNeverSplittingPremiumFromExpense,
  isSelectedThirdPartyJoin,
}: Props) => {
  const checkRequired = (q) => {
    if (!q.required) {
      return true
    } else {
      return _.toPairs(q.required).every(([k, v]) => {
        const targetQuestion = questionnaires.find((tq) => tq.id === k)
        if (targetQuestion.selectedChoice === v) {
          return true
        }
      })
    }
  }

  return (
    <div id="list-questionnaire">
      {questionnaires.filter(checkRequired).map((item: Questionnaire, i: number) => {
        return (
          <div key={i}>
            <li className="question-item form-group" key={item.id}>
              <span className="question-text">
                <span className="number">{i + 1}.</span>
                <span>{item.question}</span>
                <span className="sub">{item.description}</span>
              </span>
              <ListGroup>
                {item.choices.map((i: Choice, index) => (
                  <ListGroupItem
                    id={`${item.id}_choice_${index}`}
                    key={`${item.id}${i.value}`}
                    active={item.selectedChoice === i.value}
                    onClick={() => setMarketConductQuestion(item.id, i.value)}
                  >
                    <span>{i.choice}</span>
                  </ListGroupItem>
                ))}
              </ListGroup>
              {getToggles().ENABLE_FNA_PHASE2_MARKET_CONDUCT &&
              item.id === 'splittingPremiumFromExpense' &&
              isSelectedNeverSplittingPremiumFromExpense ? (
                <Reason
                  reason={reasonForNeverSplittingPremiumFromExpense}
                  setReason={setReasonForNeverSplittingPremiumFromExpense}
                />
              ) : null}
            </li>
            {item.id === 'isVenerableClient' && isSelectedThirdPartyJoin && <ThirdPerson />}
          </div>
        )
      })}
    </div>
  )
}

export default Questionnaires
