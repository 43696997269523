// @flow
import type { Rider, RiderState } from 'core/data-model/rider'
import type { ValidationResult } from 'core/data-model/validation'
import _ from 'lodash'
import values from 'core/data-model/constants/values'
import validationMessages from 'core/data-model/constants/validation-messages'
import Mustache from 'mustache'

export const isCompatibleWithSelectedRiders = (rider: Rider, selectedRiderCodes: string[]): boolean => {
  return _.isEqual(_.intersection(rider.incompatibilities, selectedRiderCodes), [])
}

export const isPrerequisitesRiderSelected = (rider: Rider, selectedRiderCodes: string[]): boolean => {
  return _.intersection(rider.prerequisites, selectedRiderCodes).length === rider.prerequisites.length
}

export const checkCompatibilities = (
  rider: Rider & RiderState,
  selectedRiders: (Rider & RiderState)[]
): ValidationResult => {
  let isOk = true
  let messages = []

  const selectedRiderCodes = selectedRiders.map((r) => r.code)

  // check incompatibilities
  if (!isCompatibleWithSelectedRiders(rider, selectedRiderCodes)) {
    isOk = false
    const selectedIncompatibleRiders = _.filter(selectedRiders, (selectedRider) =>
      _.includes(rider.incompatibilities, selectedRider.code)
    )

    const riderIncompatibilitiesDisplay = selectedIncompatibleRiders
      .map((selectedIncompatibleRider) => selectedIncompatibleRider.name)
      .join(', ')

    messages.push({
      message: Mustache.render(validationMessages.RULE_RIDER_INCOMPATIBILITY, {
        riderName: rider.name,
        riderIncompatibilities: riderIncompatibilitiesDisplay,
      }),
      type: values.ELIGIBILITY,
    })
  }

  // check prerequisites
  if (!isPrerequisitesRiderSelected(rider, selectedRiderCodes)) {
    const riderPrerequisiteDisplay = rider.prerequisites.join(', ')
    messages.push({
      message: Mustache.render(validationMessages.RULE_RIDER_PREREQUISITE, {
        riderName: rider.name,
        riderPrerequisite: riderPrerequisiteDisplay,
      }),
      type: values.VALIDATION,
    })
  }

  return {
    isOk,
    messages,
  }
}
