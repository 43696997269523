import { ModalBody, ModalHeader } from 'reactstrap'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { withRouter } from 'react-router-dom'
import { Modal } from '../../common-components'
import { cancelNetworkModal, hangup } from 'quick-quote/actions'
import { getCallLatency, isAgentInCall } from 'quick-quote/remote-selling'

const CenteredHeader = styled.div`
  .modal-header {
    position: relative;
  }
  button {
    position: absolute;
    right: 15px;
    top: 4px;
  }
`

export const _ConfirmNetworkPopup = ({
  notificationMessage,
  onExit,
  history,
  cancelNetworkModal,
  hangup,
  ...props
}) => (
  <Modal
    isOpen={notificationMessage !== 'None'}
    backdrop={true}
    size="lg"
    modalClassName="default-bootstrap remote-selling-modal text-center"
  >
    <CenteredHeader>
      <ModalHeader
        toggle={() => {
          cancelNetworkModal()
          onExit()
        }}
        style={{
          justifyContent: 'center',
        }}
      >
        {notificationMessage.title}
      </ModalHeader>
    </CenteredHeader>
    <ModalBody>
      <p>{notificationMessage.message}</p>
    </ModalBody>
  </Modal>
)
const mapPropsToState = (state) => {
  const weekNetworkStatus = ['wifiMedium', 'wifiLow']
  const agentInCall = isAgentInCall(state)
  const latency = getCallLatency(state)
  const getNetworkState = (latency, agentInCall) => {
    const between = (x, min, max) => x >= min && x <= max
    if (!agentInCall) return 'wifiOff'
    if (between(latency, 0, 50)) return 'wifiHigh'
    if (between(latency, 51, 500)) return 'wifiMedium'
    if (between(latency, 501, Infinity)) return 'wifiLow'
  }
  const isWarning = weekNetworkStatus.includes(getNetworkState(latency, agentInCall))
  return {
    isWarning,
  }
}
const mapDispatchToProps = (dispatch) => ({
  cancelNetworkModal: bindActionCreators(cancelNetworkModal, dispatch),
  hangup: bindActionCreators(hangup, dispatch),
})

export default withRouter(connect(mapPropsToState, mapDispatchToProps)(_ConfirmNetworkPopup))
