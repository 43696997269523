// @flow
import type { BIProps } from 'quick-quote/product-common/benefit-illustration/actions'
import type { InsuredProps } from 'core/service/pdf-generation/products/common/types'
import { call, select } from 'redux-saga/effects'
import { christianToThaiDate, christianToThaiWithoutFormat } from 'core/service/lib/date'
import { getDistributorProfile } from 'core/service/distributor'
import AuthApi from 'core/service/distributor/auth-api'
import _ from 'lodash'

import {
  getIdentityProfile,
  getIdentityUser,
  getSelectedOffice,
  getUserDistributorGroup,
  isBancAgent,
  isNewAsteriskOfSummaryMainContract as isUseNewAsteriskOfSummaryMainContract,
} from 'identity/selectors'
import {
  getAge,
  getBirthdate,
  getGenderLabel,
  getInsuredFirstName,
  getInsuredLastName,
} from 'quick-quote/insured-information/selectors'

import { getSelectedDisplayProduct } from 'quick-quote/product-selection/selectors'
import {
  getBenefitSummary,
  getHasNonLevelRider,
  getLegalDisclaimerEndDate,
  getPolicyValues,
  getRiderBenefitTables,
  getTaxDeduction,
} from 'quick-quote/product-whole-life/benefit-illustration/selectors'
import { getVersion, isReviseQQFlow } from 'quick-quote/selectors'
import {
  getSelectedRiders,
  getSumAssured$,
  getTotalPremium,
  getBasicPremium$,
  getTotalRiderPremium,
  getSelectedPlanName,
  getSelectedPlanCode,
  getcoveragePlanStartDate,
  getSelectedModelFactorLabel,
  getPensionType,
  getCampaignDiscountMessage,
} from 'quick-quote/product-common/coverage-plan/selectors'
import {
  getCalculatedCoveragePeriod,
  getCalculatedPaymentPeriod,
} from 'quick-quote/product-common/benefit-illustration/selectors'

export function* getBIRetirementProps(biProps: BIProps): Generator<*, *, *> {
  const basicPlan = yield select(getSelectedDisplayProduct)
  const productCategory = basicPlan.category
  const riders = yield select(getSelectedRiders)
  const genderLabel = yield select(getGenderLabel)
  const age = yield select(getAge)
  const firstName = yield select(getInsuredFirstName)
  const lastName = yield select(getInsuredLastName)
  const birthdate = yield select(getBirthdate)
  const sumAssured$ = yield select(getSumAssured$)
  const selectedModelFactorLabel = yield select(getSelectedModelFactorLabel)
  const calculatedCoveragePeriod = yield select(getCalculatedCoveragePeriod)
  const calculatedPaymentPeriod = yield select(getCalculatedPaymentPeriod)
  const hasNonLevelRider = yield select(getHasNonLevelRider)
  const totalPremium = yield select(getTotalPremium)
  const basicPremium$ = yield select(getBasicPremium$)
  const totalRiderPremium = yield select(getTotalRiderPremium)
  const benefitSummary = yield select(getBenefitSummary)
  const policyValue = yield select(getPolicyValues)
  const taxDeduction = yield select(getTaxDeduction)
  const riderBenefitData = yield select(getRiderBenefitTables)
  const version = yield select(getVersion)
  let profile = yield select(getIdentityProfile)
  const user = yield select(getIdentityUser)
  const accessToken = user.access_token
  const agentInfo = yield call(AuthApi.getUserInformation, user)
  const bancOffice = yield select(getSelectedOffice)
  profile = getDistributorProfile(profile, agentInfo, bancOffice)
  const distributorType = yield select(getUserDistributorGroup)
  const startDate = yield select(getcoveragePlanStartDate)
  const endDate = yield select(getLegalDisclaimerEndDate)
  const selectedPlanName = yield select(getSelectedPlanName)
  const selectedPlanCode = yield select(getSelectedPlanCode)
  const isBanc = yield select(isBancAgent)
  const pensionType = yield select(getPensionType)
  const isNewAsteriskOfSummaryMainContract = yield select(isUseNewAsteriskOfSummaryMainContract)
  const isReviseQQ = yield select(isReviseQQFlow)
  const getDateTime = () => christianToThaiWithoutFormat().format('Do MMMM YYYY เวลา kk:mm')
  const campaignDiscountMessage = yield select(getCampaignDiscountMessage)
  const datetime = yield call(getDateTime)
  const validateFullName = _.isEmpty(firstName) && _.isEmpty(lastName) ? null : { firstName, lastName }

  const insured: InsuredProps = {
    genderLabel,
    age,
    ...validateFullName,
  }
  return {
    // meta data
    productCategory,
    biType: biProps.biType,

    // data
    basicPlan,
    insured,
    riders,
    birthdate,
    calculatedCoveragePeriod,
    calculatedPaymentPeriod,
    sumAssured: sumAssured$.value,
    basicPremium: basicPremium$.value,
    totalPremium,
    selectedModelFactorLabel,
    hasNonLevelRider,
    benefitSummary,
    policyValue,
    riderBenefitData,
    version: version.appVersion,
    profile,
    accessToken,
    distributorType,
    startDate: christianToThaiDate(startDate),
    endDate: christianToThaiDate(endDate),
    datetime,
    logo: '',
    redLine: '',
    productGraph: '',
    selectedPlanName,
    selectedPlanCode,
    taxDeduction,
    totalRiderPremium,
    isBancAgent: isBanc,
    pensionType,
    productCode: basicPlan.code,
    isNewAsteriskOfSummaryMainContract,
    isReviseQQ,
    campaignDiscountMessage,
  }
}
