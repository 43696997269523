// @flow
import VALUES from 'core/data-model/constants/values'
import MESSAGES from 'core/data-model/constants/messages'
import BIMESSAGES from 'core/data-model/constants/bi-messages'
import { formatNumber, round } from 'core/service/lib/number-format'
import { buildStacksWithStyle } from 'core/service/pdf-generation/utils/builder'
import type { Insured } from 'core/data-model/insured'
import type { Rider, RiderState } from 'core/data-model/rider'
import type { DisplayProduct } from 'core/service/display-product'
import { getMRTATRUNCATELoanPeriod } from 'quick-quote/product-mrta/coverage-plan/selectors'

const summaryTable = (displayProduct: DisplayProduct) => ({
  title: displayProduct?.riders?.length
    ? BIMESSAGES.SUMMARY_OF_MAIN_AND_ADDITIONAL_CONTRACTS
    : BIMESSAGES.SUMMARY_OF_MAIN_CONTRACTS,
  headers: [BIMESSAGES.CASH_INCLUDE_LOAN],
})

const buildRowInTable = (messages, value, decimal) => {
  return [
    { text: messages },
    { text: `${formatNumber(value, decimal)} ${MESSAGES.BAHT}`, bold: true, alignment: 'right' },
  ]
}

const buildSummaryBasicPainText = (
  basicPlan,
  title1,
  value1,
  title2,
  value2,
  style1 = 'basicPlanSummaryValueSmall',
  style2 = 'basicPlanSummaryValueSmall'
) => {
  if (basicPlan.basicPlanCode !== VALUES.MRTA_CODE.MRTA_FULL) {
    return [
      {
        label: { text: title1, style: '' },
        value: {
          text: value1,
          style: style1,
        },
      },
      {
        label: { text: title2, style: '' },
        value: {
          text: value2,
          style: style2,
        },
      },
    ]
  } else
    return [
      {
        label: { text: title1, style: '' },
        value: {
          text: value1,
          style: style1,
        },
      },
    ]
}

const buildSummaryBasicWithUnit = (
  title1,
  value1,
  unit1,
  title2,
  value2,
  unit2,
  style1 = 'basicPlanSummaryValue',
  style2 = 'basicPlanSummaryValue',
  digit1 = 0,
  digit2 = 0
) => [
  {
    label: { text: title1, style: '' },
    value: {
      text: `${formatNumber(value1, digit1)} ${unit1}`,
      style: style1,
    },
  },
  {
    label: { text: title2, style: '' },
    value: {
      text: `${formatNumber(value2, digit2)} ${unit2}`,
      style: style2,
    },
  },
]
const buildSummaryBasicWithUnitAndCondition = (
  basicPlan,
  title1,
  value1,
  unit1,
  title2,
  value2,
  unit2,
  style1 = 'basicPlanSummaryValue',
  style2 = 'basicPlanSummaryValue'
) => {
  if (basicPlan.basicPlanCode !== VALUES.MRTA_CODE.MRTA_FULL) {
    return [
      {
        label: { text: title1, style: '' },
        value: {
          text: `${formatNumber(value1)} ${unit1}`,
          style: style1,
        },
      },
      {
        label: { text: title2, style: '' },
        value: {
          text: `${formatNumber(value2)} ${unit2}`,
          style: style2,
        },
      },
    ]
  } else {
    return [
      {
        label: { text: title1, style: '' },
        value: {
          text: `${formatNumber(value1)} ${unit1}`,
          style: style1,
        },
      },
    ]
  }
}
const buildSummaryTable = (
  basicPlan,
  paymentMethod,
  sumAssured,
  basicPremium,
  riders,
  totalRiderPremium,
  totalPremium,
  sumAssuredContract
) => {
  if (![VALUES.MRTA_CODE.MRTA_FULL, VALUES.GLTSP_CODE.GLTSP].includes(basicPlan.basicPlanCode)) {
    return [
      buildRowInTable(BIMESSAGES.SUM_INSURED_OF_BASIC_PLAN_AT_START_DATE, round(sumAssuredContract, 0), 0),
      buildRowInTable(BIMESSAGES.SUM_RIDER_OF_ACCIDENT_PROTECTION, riders == null ? 0 : riders[0].sumAssured, 0),
      buildRowInTable(BIMESSAGES.BASIC_PREMIUM, basicPremium, 2),
      buildRowInTable(BIMESSAGES.RIDER_PREMIUM_COVER_ACCIDENT_PROTECTION, totalRiderPremium, 2),
      buildRowInTable(BIMESSAGES.SUMMARY_STANDARD_PREMIUM, totalPremium, 2),
    ]
  } else {
    return [
      buildRowInTable(BIMESSAGES.SUM_INSURED_OF_BASIC_PLAN_AT_START_DATE, round(sumAssuredContract, 0)),
      buildRowInTable(BIMESSAGES.BASIC_PREMIUM, basicPremium, 2),
    ]
  }
}

type Props = {
  basicPlan: DisplayProduct,
  riders: (Rider & RiderState)[],
  loanAmount: number,
  loanPeriod: number,
  calculatedCoveragePeriod: number,
  sumAssured: number,
  paymentMethod: string,
  basicPremium: number,
  totalPremium: number,
  totalRiderPremium: number,
  interestRate: number,
  premiumPerMonth: number,
  sumAssuredContract: number,
  insured: Insured,
}

export const profileSnapshotSectionMRTA = ({
  basicPlan,
  riders,
  loanAmount,
  loanPeriod,
  calculatedCoveragePeriod,
  sumAssured,
  paymentMethod,
  basicPremium,
  totalPremium,
  totalRiderPremium,
  sumAssuredContract,
  insured,
}: Props) => [
  {
    table: {
      widths: ['45%', '50%'],
      body: [
        buildStacksWithStyle(
          buildSummaryBasicWithUnit(
            BIMESSAGES.LOAN_PERIOD_INSURED,
            basicPlan.basicPlanCode !== VALUES.MRTA_CODE.MRTA_TRUNCATE
              ? calculatedCoveragePeriod
              : getMRTATRUNCATELoanPeriod(loanPeriod, insured.age),
            MESSAGES.TIME_UNIT_YEAR,
            BIMESSAGES.COVERAGE_PERIOD_INSURED,
            calculatedCoveragePeriod,
            MESSAGES.TIME_UNIT_YEAR
          )
        ),
      ],
    },
    layout: 'noBorders',
  },
  {
    table: {
      widths: ['45%', '50%'],
      body: [
        buildStacksWithStyle(
          buildSummaryBasicPainText(
            basicPlan,
            BIMESSAGES.COVERAGE_BASIC_PLAN,
            BIMESSAGES.COVERAGE_BASIC_PLAN_MESSAGE,
            BIMESSAGES.COVERAGE_RIDER,
            BIMESSAGES.COVERAGE_RIDER_MESSAGE
          )
        ),
      ],
    },
    layout: 'noBorders',
  },
  {
    table: {
      widths: ['45%', '50%'],
      body: [
        buildStacksWithStyle(
          buildSummaryBasicWithUnitAndCondition(
            basicPlan,
            BIMESSAGES.AMOUNT_INSURED_OF_BASIC_PLAN,
            round(sumAssuredContract, 0),
            MESSAGES.BAHT,
            BIMESSAGES.AMOUNT_RIDER_ACCIDENT,
            [VALUES.MRTA_CODE.MRTA_FULL, VALUES.GLTSP_CODE.GLTSP].includes(basicPlan.basicPlanCode)
              ? 0
              : riders[0].sumAssured,
            MESSAGES.BAHT
          )
        ),
      ],
    },
    layout: 'noBorders',
  },
  {
    table: {
      widths: ['45%', '50%'],
      body: [
        buildStacksWithStyle(
          buildSummaryBasicWithUnit(
            BIMESSAGES.AMOUNT_LOAN,
            loanAmount,
            MESSAGES.BAHT,
            BIMESSAGES.COVERAGE_LOAN_PERIOD_BANK,
            loanPeriod,
            MESSAGES.TIME_UNIT_YEAR
          )
        ),
      ],
    },
    layout: 'noBorders',
    marginBottom: 20,
  },
  {
    text: summaryTable(basicPlan).title,
    style: 'title',
  },
  {
    text: paymentMethod === VALUES.CASH_INCLUDE_LOAN ? BIMESSAGES.CASH_INCLUDE_LOAN : BIMESSAGES.CASH_EXCLUDE_LOAN,
    style: 'tableHeader',
    alignment: 'left',
  },
  {
    table: {
      widths: ['*', 'auto'],
      body: buildSummaryTable(
        basicPlan,
        paymentMethod,
        sumAssured,
        basicPremium,
        riders,
        totalRiderPremium,
        totalPremium,
        sumAssuredContract
      ),
    },
    layout: 'noBorders',
    style: 'table',
  },
  {
    text: BIMESSAGES.NOTE,
    style: 'disclaimer',
  },
  {
    ol: [
      {
        text: BIMESSAGES.REMARK_OVERALL_SUMMARY_MRTA[0],
      },
      {
        text: BIMESSAGES.REMARK_OVERALL_SUMMARY_MRTA[1],
      },
    ],
    style: 'disclaimer',
  },
]

export const profileSnapshotSectionGLTSP = ({
  basicPlan,
  riders,
  loanAmount,
  loanPeriod,
  calculatedCoveragePeriod,
  sumAssured,
  paymentMethod,
  basicPremium,
  totalPremium,
  totalRiderPremium,
  interestRate,
  premiumPerMonth,
  sumAssuredContract,
}: Props) => [
  {
    table: {
      widths: ['45%', '50%'],
      body: [
        buildStacksWithStyle(
          buildSummaryBasicWithUnit(
            BIMESSAGES.LOAN_PERIOD_INSURED,
            calculatedCoveragePeriod,
            MESSAGES.TIME_UNIT_YEAR,
            BIMESSAGES.COVERAGE_PERIOD_INSURED,
            calculatedCoveragePeriod,
            MESSAGES.TIME_UNIT_YEAR
          )
        ),
      ],
    },
    layout: 'noBorders',
  },
  {
    table: {
      widths: ['45%', '50%'],
      body: [
        buildStacksWithStyle(
          buildSummaryBasicPainText(
            basicPlan,
            BIMESSAGES.COVERAGE_BASIC_PLAN,
            BIMESSAGES.COVERAGE_BASIC_PLAN_MESSAGE,
            BIMESSAGES.AMOUNT_INSURED_OF_BASIC_PLAN,
            `${formatNumber(sumAssuredContract, 0, true)} ${MESSAGES.BAHT}`,
            'basicPlanSummaryValueSmall',
            'basicPlanSummaryValue'
          )
        ),
      ],
    },
    layout: 'noBorders',
  },
  {
    table: {
      widths: ['45%', '50%'],
      body: [
        buildStacksWithStyle(
          buildSummaryBasicWithUnitAndCondition(
            basicPlan,
            BIMESSAGES.AMOUNT_LOAN,
            loanAmount,
            MESSAGES.BAHT,
            BIMESSAGES.COVERAGE_LOAN_PERIOD_BANK,
            loanPeriod,
            MESSAGES.TIME_UNIT_YEAR
          )
        ),
      ],
    },
    layout: 'noBorders',
  },
  {
    table: {
      widths: ['45%', '50%'],
      body: [
        buildStacksWithStyle(
          paymentMethod === VALUES.CASH_INCLUDE_LOAN
            ? buildSummaryBasicWithUnit(
                BIMESSAGES.INTEREST_RATE_BORROW_PREMIUM,
                interestRate,
                '%',
                BIMESSAGES.PREMIUM_MONTHLY,
                premiumPerMonth,
                MESSAGES.BAHT,
                'basicPlanSummaryValue',
                'basicPlanSummaryValue',
                2,
                2
              )
            : []
        ),
      ],
    },
    layout: 'noBorders',
  },
  {
    text: ' ',
    style: 'title',
  },
  {
    text: summaryTable(basicPlan).title,
    style: 'title',
  },
  {
    text: paymentMethod === VALUES.CASH_INCLUDE_LOAN ? BIMESSAGES.CASH_INCLUDE_LOAN : BIMESSAGES.CASH_EXCLUDE_LOAN,
    style: 'tableHeader',
    alignment: 'left',
  },
  {
    table: {
      widths: ['*', 'auto'],
      body: buildSummaryTable(
        basicPlan,
        paymentMethod,
        sumAssured,
        basicPremium,
        riders,
        totalRiderPremium,
        totalPremium,
        sumAssuredContract
      ),
    },
    layout: 'noBorders',
    style: 'table',
  },
  {
    text: BIMESSAGES.NOTE,
    style: 'disclaimer',
  },
  {
    ol: [
      {
        text: BIMESSAGES.REMARK_OVERALL_SUMMARY_MRTA[0],
      },
      {
        text: BIMESSAGES.REMARK_OVERALL_SUMMARY_MRTA[1],
      },
    ],
    style: 'disclaimer',
  },
]

export const profileSnapshotSection = (props: Props) => {
  switch (props.basicPlan.basicPlanCode) {
    case VALUES.MRTA_CODE.MRTA_FULL:
    case VALUES.MRTA_CODE.MRTA_PLUS:
    case VALUES.MRTA_CODE.MRTA_TRUNCATE:
      return profileSnapshotSectionMRTA(props)
    case VALUES.GLTSP_CODE.GLTSP:
      return profileSnapshotSectionGLTSP(props)
    default:
      return []
  }
}
