// @flow
import type { DisplayProduct } from 'core/service/display-product'

export const SELECT_BASIC_PLAN = 'SELECT_BASIC_PLAN'
export const SELECT_PERFECT_LIFE_PLAN = 'SELECT_PERFECT_LIFE_PLAN'
export type SelectBasicPlan = {
  type: 'SELECT_BASIC_PLAN',
  payload: DisplayProduct,
}

export type SelectPerfectLifePlan = {
  type: 'SELECT_PERFECT_LIFE_PLAN',
  payload: string,
}

export const selectBasicPlan = (product: DisplayProduct): SelectBasicPlan => ({
  type: SELECT_BASIC_PLAN,
  payload: product,
})

export const selectPerfectLifePlan = (planCode: string): SelectPerfectLifePlan => ({
  type: SELECT_PERFECT_LIFE_PLAN,
  payload: planCode,
})
