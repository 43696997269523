// @flow
import { FormFeedback, FormGroup } from 'reactstrap'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose, branch, renderComponent } from 'recompose'

import type { AppState } from 'quick-quote/reducers'
import MESSAGES from 'core/data-model/constants/validation-messages'
import { SidebarMenu } from 'quick-quote/components'
import { getSelectedDisplayProductCode } from 'quick-quote/product-selection/selectors'

export type BenefitIllustrationProps = {
  // eslint-disable-next-line flowtype/no-weak-types
  sections: any[],
  menus: MenuProps[],
  isBlockedEnteringESub: boolean,
  className?: string,
}

export type MenuProps = {
  text: string,
  id: string,
  isSub: boolean,
}

export const BenefitIllustration = ({
  sections,
  menus,
  isBlockedEnteringESub,
  className,
}: BenefitIllustrationProps) => {
  return (
    <div id="benefit-illustration" className={className || ''}>
      <div className="content">
        {sections.map((section, i) => (
          <section.component key={`benefit-illustration-section-${i}`} {...section.props} />
        ))}

        {isBlockedEnteringESub ? (
          <FormGroup className="has-warning">
            <FormFeedback className={'eligibility-message attached-icon'}>
              <div>{MESSAGES.RULE_ENTER_ESUB_PREREQUISITE}</div>
            </FormFeedback>
          </FormGroup>
        ) : null}
      </div>
      <SidebarMenu mainMenu={menus} />
    </div>
  )
}

const mapStateToProps = (state: AppState) => {
  return {
    selectedDisplayProductCode: getSelectedDisplayProductCode(state),
  }
}

export default compose(
  connect(mapStateToProps, () => ({})),
  branch(
    (props) => !props.selectedDisplayProductCode,
    renderComponent(() => <Redirect to="/" push />)
  )
)(BenefitIllustration)
