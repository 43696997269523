// @flow

import { Section, Bold } from 'core/service/pdf-generation/products/common/components/general'
import VALUE from 'core/data-model/constants/values'

const TITLE = 'ตารางค่าธรรมเนียมกรมธรรม์ประกันภัย'
const TITLE_RPUDR = 'ตารางค่าธรรมเนียมกรมธรรม์'
const TITLE_RPUDR_CONTINUED = 'ตารางค่าธรรมเนียมกรมธรรม์ (ต่อ)'
const layout = {
  hLineColor: VALUE.MEDIUM_BLUE,
  vLineColor: VALUE.MEDIUM_BLUE,
  fillColor: function(i, node) {
    return i % 2 === 0 ? null : VALUE.ZEBRA_BACKGROUND_BLUE
  },
}

const tableHeader = [
  Bold({ text: 'ค่าธรรมเนียม', style: 'tableHeader' }),
  Bold({ text: 'การคำนวณ', style: 'tableHeader' }),
  Bold({ text: 'อัตรา', style: 'tableHeader' }),
]

const tableRPUDRHeader = [
  Bold({ text: 'ค่าธรรมเนียมกรมธรรม์', style: 'tableHeader' }),
  Bold({ text: 'การคำนวณ', style: 'tableHeader' }),
  Bold({ text: 'อัตรา', style: 'tableHeader' }),
]

const iWealthyFeeDetailsContent = (rateYear: string) => {
  const row2 = [
    {
      text: [
        'ค่าธรรมเนียมในการจัดสรรเบี้ยประกันภัยหลัก',
        'เพื่อความคุ้มครองรายงวด\n(Regular Protection Premium Charge)',
      ],
      bold: true,
    },
    'ค่าธรรมเนียมซึ่งถูกหักออกจากเบี้ยประกันภัยหลักเพื่อความคุ้มครองรายงวด',
    [
      {
        style: 'center',
        table: {
          widths: ['auto', '*', '*', '*', '*', 'auto'],
          body: [
            ['ปีกรมธรรม์', '1', '2', '3', '4', '5 เป็นต้นไป'],
            ['ร้อยละ*', '45', '30', '15', '10', 'ไม่มี'],
          ],
        },
        layout: {
          hLineColor: VALUE.MEDIUM_BLUE,
          vLineColor: VALUE.MEDIUM_BLUE,
        },
      },
      '*อัตราร้อยละของเบี้ยประกันภัยหลักเพื่อความคุ้มครองรายงวด',
    ],
  ]

  const row3 = [
    {
      text: 'ค่าธรรมเนียมในการจัดสรรเบี้ยประกันภัย\nเพื่อการลงทุนรายงวด\n(Regular Top-up Investment Premium Charge)',
      bold: true,
    },
    'ค่าธรรมเนียมซึ่งถูกหักออกจากเบี้ยประกันภัยเพื่อการลงทุนรายงวด',
    [
      {
        style: 'center',
        table: {
          widths: ['auto', '*', '*', '*', '*', 'auto'],
          body: [
            ['ปีกรมธรรม์', '1', '2', '3', '4', '5 เป็นต้นไป'],
            ['ร้อยละ*', '3', '2', '1', '1', 'ไม่มี'],
          ],
        },
        layout: {
          hLineColor: VALUE.MEDIUM_BLUE,
          vLineColor: VALUE.MEDIUM_BLUE,
        },
      },
      '*อัตราร้อยละของเบี้ยประกันภัยเพื่อการลงทุนรายงวด',
    ],
  ]

  const row4 = [
    {
      text:
        'ค่าธรรมเนียมในการจัดสรรเบี้ยประกันภัย\nเพื่อการลงทุนเพิ่มเติมพิเศษ\n(Lump Sum Top-up Investment Premium Charge)',
      bold: true,
    },
    'ค่าธรรมเนียมซึ่งถูกหักออกจากเบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษทุกครั้งที่บริษัทได้รับชำระ',
    'ร้อยละ 1.25 ของเบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษ',
  ]

  const row5 = [
    { text: 'ค่าธรรมเนียมการบริหารกรมธรรม์\n(Policy Administration Charge)', bold: true },
    'หักค่าธรรมเนียมการบริหารกรมธรรม์เป็นรายเดือน โดยจะเท่ากับอัตราค่าธรรมเนียม การบริหารกรมธรรม์ต่อปี หารด้วยสิบสอง โดยจะทำการหักจากกองทุนภายใต้กรมธรรม์ ตามสัดส่วนของมูลค่ารับซื้อคืนหน่วยลงทุนของแต่ละกองทุน',
    'ร้อยละ 0.6 ต่อปี ของมูลค่ารับซื้อคืนหน่วยลงทุน',
  ]

  const row6 = [
    { text: 'ค่าการประกันภัย\nCost of Insurance (COI)', bold: true },
    'ค่าการประกันภัยจะถูกหักจากเบี้ยประกันภัยหลักเพื่อความคุ้มครองรายงวด ณ วันที่กรมธรรม์\nเริ่มมีผลบังคับหลังจากนั้นจะหักเป็นรายเดือนทุก ๆ เดือน ตามสัดส่วนของมูลค่ารับซื้อคืนหน่วยลงทุน ของแต่ละกองทุน ณ วันที่มีการหักค่าการประกันภัยนั้น',
    'อัตรามรณะของจำนวนเสี่ยงภัยสุทธิจากตารางมรณะไทย ' + rateYear + ' ประเภทสามัญ',
  ]

  const row7 = [
    {
      text: 'ค่าธรรมเนียมในการถอนเงินจากกรมธรรม์ / \nการเวนคืนกรมธรรม์\n(Partial Withdrawal Charge / Surrender Charge)',
      bold: true,
    },
    'ค่าธรรมเนียมที่เกิดจากการถอนเงินจากกรมธรรม์ หรือการเวนคืนกรมธรรม์ โดยไม่คิดค่าธรรมเนียมนี้ ในส่วนของเบี้ยประกันภัยเพื่อการลงทุนรายงวด และเบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษ',
    [
      {
        style: 'center',
        table: {
          widths: ['auto', '*', '*', '*', '*', 'auto'],
          body: [
            ['ปีกรมธรรม์', '1', '2', '3', '4', '5 เป็นต้นไป'],
            ['ร้อยละ*', '50', '30', '20', '5', 'ไม่มี'],
          ],
        },
        layout: {
          hLineColor: VALUE.MEDIUM_BLUE,
          vLineColor: VALUE.MEDIUM_BLUE,
        },
      },
      '*อัตราร้อยละของมูลค่ารับซื้อคืนหน่วยลงทุน',
    ],
  ]

  const row8 = [
    { text: 'ค่าธรรมเนียมในการสับเปลี่ยนกองทุน\n(Fund Switching Charge)', bold: true },
    {
      text: [
        'หักค่าธรรมเนียมในการสับเปลี่ยนกองทุน จากมูลค่าที่ได้รับจากการขายคืนหน่วยลงทุนของกองทุนต้นทาง',
        'ก่อนที่จะทำการซื้อหน่วยลงทุนในกองทุนปลายทาง',
      ],
    },
    'ไม่มีค่าใช้จ่าย 5 ครั้งต่อปีกรมธรรม์ หลังจากนั้นมีค่าธรรมเนียมครั้งละ 150 บาทต่อครั้ง และจำนวนมูลค่าในการสับเปลี่ยนขั้นต่ำสุดต่อครั้ง คือ 1,000 บาท',
  ]

  const row9 = [
    { text: 'ค่าธรรมเนียมการขอใบแจ้งรายงาน\nสถานะทางการเงินของกรมธรรม์', bold: true },
    {
      text: [
        'บริษัทเรียกเก็บค่าธรรมเนียมการขอใบแจ้งรายงานสถานะทางการเงินของกรมธรรม์จากผู้เอาประกันภัย ในกรณีที่ผู้เอาประกันภัยร้องขอให้บริษัทจัดส่งรายงานสถานะทางการเงินของกรมธรรม์มากกว่า',
        'เดือนละครั้ง',
      ],
    },
    'ฉบับละ 100 บาท',
  ]

  const content = [
    {
      fontSize: 12,
      table: {
        widths: ['25%', '45%', '30%'],
        body: [tableHeader, row2, row3, row4, row5, row6, row7, row8, row9],
      },
      layout,
    },
  ]

  return Section(TITLE, content)
}

const iInvestFeeDetailsContent = () => {
  const content = [
    {
      fontSize: 12,
      table: {
        widths: ['25%', '45%', '30%'],
        body: [
          tableHeader,
          [
            { text: 'ค่าธรรมเนียมในการจัดสรรเบี้ยประกันภัย\n(Premium Charge)', bold: true },
            'ค่าธรรมเนียมซึ่งถูกหักออกจากเบี้ยประกันภัย',
            'ร้อยละ 0.75 ของเบี้ยประกันภัยชำระครั้งเดียว',
          ],
          [
            { text: 'ค่าธรรมเนียมในการจัดสรรเบี้ยประกันภัยเพิ่มเติมพิเศษ\n(Top-up Premium Charge)', bold: true },
            'ค่าใช้จ่ายซึ่งถูกหักออกจากเบี้ยประกันภัยเพิ่มเติมพิเศษทุกครั้งที่บริษัทได้รับชำระ',
            'ร้อยละ 0.75 ของเบี้ยประกันภัยเพิ่มเติมพิเศษ',
          ],
          [
            { text: 'ค่าธรรมเนียมบริหารกรมธรรม์\n(Policy Administration Charge)', bold: true },
            'หักค่าธรรมเนียมการบริหารจัดการเป็นรายเดือน โดยจะเท่ากับอัตราค่าธรรมเนียม การบริหารต่อปี หารด้วยสิบสอง โดยจะทำการหักจากกองทุนภายใต้กรมธรรม์ ตามสัดส่วนของมูลค่ารับซื้อคืนหน่วยลงทุนของแต่ละกองทุน',
            'ร้อยละ 1.00 ต่อปี ของมูลค่าบัญชีกรมธรรม์',
          ],
          [
            { text: 'ค่าการประกันภัย\nCost of insurance (COI)', bold: true },
            'ค่าการประกันภัยจะถูกหักจากมูลค่าบัญชีกรมธรรม์ ณ วันที่กรมธรรม์เริ่มมีผลบังคับหลังจากนั้นจะหักเป็นรายเดือนทุก ๆ เดือน โดยจะทำการขายหน่วยลงทุน ณ ราคาขาย ตามสัดส่วนการลงทุนของพอร์ตการลงทุน ณ วันที่มีการหักค่าการประกันภัยนั้น',
            'อัตรามรณะของจำนวนเสี่ยงภัยสุทธิจากตารางมรณะไทย 2560 ประเภทสามัญ',
          ],
          [
            {
              text:
                'ค่าธรรมเนียมในการถอนเงินจากกรมธรรม์ / การเวนคืนกรมธรรม์\n(Partial withdrawal Charge / Surrender charge)',
              bold: true,
            },
            'ค่าธรรมเนียมที่เกิดจากการถอนเงินจากกรมธรรม์ หรือการเวนคืนกรมธรรม์',
            [
              {
                style: 'center',
                margin: 15,
                table: {
                  widths: ['30%', '70%'],
                  body: [
                    ['กรมธรรม์ปีที่', 'ร้อยละของมูลค่าบัญชีกรมธรรม์'],
                    ['1', '5.0'],
                    ['2', '3.0'],
                    ['3', '2.0'],
                    ['4', '1.0'],
                    ['5 เป็นต้นไป', 'ไม่มีค่าธรรมเนียม'],
                  ],
                },
                layout: {
                  hLineColor: VALUE.MEDIUM_BLUE,
                  vLineColor: VALUE.MEDIUM_BLUE,
                  fillColor: 'white',
                },
              },
            ],
          ],
          [
            { text: 'ค่าธรรมเนียมในการสับเปลี่ยนกองทุน\n(Fund switching charge)', bold: true },
            'หักค่าธรรมเนียมในการสับเปลี่ยนกองทุน จากมูลค่าที่ได้รับจากการขายคืนหน่วยลงทุนของกองทุนต้นทางก่อนที่จะทำการซื้อหน่วยลงทุนในกองทุนปลายทาง',
            'ไม่มีค่าใช้จ่าย 5 ครั้งต่อปีกรมธรรม์ หลังจากนั้นมีค่าธรรมเนียมครั้งละ 150 บาทต่อครั้ง และจำนวนมูลค่าในการสับเปลี่ยนขั้นต่ำสุดต่อครั้ง คือ 1,000 บาท',
          ],
        ],
      },
      layout,
    },
  ]
  return Section(TITLE, content)
}

const rpudrFeeDetailsContent = () => {
  const row2 = [
    {
      text: ['ค่าธรรมเนียมในการจัดสรรเบี้ยประกันภัยหลักเพื่อความคุ้มครอง'],
      bold: true,
    },
    'ค่าธรรมเนียมในการจัดสรรเบี้ยประกันภัยหลักเพื่อความคุ้มครองจะถูกหักออกจากเบี้ยประกันภัยหลักเพื่อความคุ้มครองตามที่บริษัทได้รับชำระแต่ละครั้ง โดยคิดเป็นอัตราร้อยละดังนี้',
    [
      {
        style: 'center',
        table: {
          widths: ['16%', '16%', '17%', '17%', '17%', '*', '*'],
          body: [
            [
              { alignment: 'center', text: 'ปีที่ชำระเบี้ยประกันภัย', rowSpan: 2 },
              { colSpan: 4, alignment: 'center', text: 'ครั้งที่ของงวดการชำระเบี้ยประกันภัย' },
              {},
              {},
              {},
              {
                rowSpan: 2,
                colSpan: 2,
                alignment: 'center',
                text: 'ร้อยละของเบี้ยประกันภัยหลักเพื่อความคุ้มครองที่ชำระแต่ละครั้ง',
              },
              {},
            ],
            [
              {},
              { alignment: 'center', text: 'รายปี' },
              { alignment: 'center', text: 'ราย\n6 เดือน' },
              { alignment: 'center', text: 'ราย\n3 เดือน' },
              { alignment: 'center', text: 'รายเดือน' },
              {},
              {},
            ],
            [
              { alignment: 'center', text: '1' },
              { alignment: 'center', text: '1' },
              { alignment: 'center', text: '1 - 2' },
              { alignment: 'center', text: '1 - 4' },
              { alignment: 'center', text: '1 - 12' },
              { colSpan: 2, alignment: 'center', text: '55' },
              {},
            ],
            [
              { alignment: 'center', text: '2' },
              { alignment: 'center', text: '2' },
              { alignment: 'center', text: '3 - 4' },
              { alignment: 'center', text: '5 - 8' },
              { alignment: 'center', text: '13 - 24' },
              { colSpan: 2, alignment: 'center', text: '40' },
              {},
            ],
            [
              { alignment: 'center', text: '3' },
              { alignment: 'center', text: '3' },
              { alignment: 'center', text: '5 - 6' },
              { alignment: 'center', text: '9 - 12' },
              { alignment: 'center', text: '25 - 36' },
              { colSpan: 2, alignment: 'center', text: '20' },
              {},
            ],
            [
              { alignment: 'center', text: '4' },
              { alignment: 'center', text: '4' },
              { alignment: 'center', text: '7 - 8' },
              { alignment: 'center', text: '13 - 16' },
              { alignment: 'center', text: '37 - 48' },
              { colSpan: 2, alignment: 'center', text: '10' },
              {},
            ],
            [
              { alignment: 'center', text: '5' },
              { alignment: 'center', text: '5' },
              { alignment: 'center', text: '9 - 10' },
              { alignment: 'center', text: '17 - 20' },
              { alignment: 'center', text: '49 - 60' },
              { colSpan: 2, alignment: 'center', text: '5' },
              {},
            ],
            [
              { alignment: 'center', text: '6 เป็นต้นไป' },
              { alignment: 'center', text: '6 เป็นต้นไป' },
              { alignment: 'center', text: '11 เป็นต้นไป' },
              { alignment: 'center', text: '21 เป็นต้นไป' },
              { alignment: 'center', text: '61 เป็นต้นไป' },
              { colSpan: 2, alignment: 'center', text: '0' },
              {},
            ],
          ],
        },
        layout: {
          hLineColor: VALUE.MEDIUM_BLUE,
          vLineColor: VALUE.MEDIUM_BLUE,
        },
      },
      {
        text:
          'ในกรณีที่มีการเปลี่ยนแปลงงวดการชำระเบี้ยประกันภัยหลักเพื่อความคุ้มครอง ค่าธรรมเนียมในการจัดสรรเบี้ยประกันภัยหลักเพื่อความคุ้มครองที่เรียกเก็บจะเป็นไปตามอัตราที่ระบุในปีที่ชำระเบี้ยประกันภัยถัดจากงวดสุดท้ายที่ได้ชำระ',
      },
    ],
  ]

  const row3 = [
    {
      text: ['ค่าธรรมเนียมในการจัดสรรเบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษ'],
      bold: true,
    },
    {
      text: [
        'ค่าธรรมเนียมในการจัดสรรเบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษจะถูกหักออกจากเบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษตามที่บริษัทได้รับชำระแต่ละครั้ง',
      ],
    },
    {
      text: ['อัตราร้อยละ 1.25 ของเบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษ'],
    },
  ]

  const content = [
    {
      fontSize: 12,
      table: {
        widths: ['22%', '35%', '43%'],
        body: [tableRPUDRHeader, row2, row3],
      },
      layout,
    },
  ]

  return Section(TITLE_RPUDR, content)
}

const rpudrFeeDetailsContentContinued_1 = () => {
  const row1 = [
    {
      text: ['ค่าการประกันภัย'],
      bold: true,
    },
    {
      text: [
        '- ค่าการประกันภัยสำหรับความคุ้มครองชีวิตจะคำนวณจากค่าการประกันภัยรายปี ตามจำนวนเงินเสี่ยงภัยสุทธิ\n',
        '- ค่าการประกันภัยที่หักในแต่ละเดือนจะเท่ากับอัตราค่าการประกันภัยรายปีหาร\n',
        'ด้วยสิบสอง (12) คูณด้วยจำนวนเงินเสี่ยงภัยสุทธิ โดยค่าการประกันภัยรายปี\n',
        'ดังกล่าวจะปรับเปลี่ยนตามอายุของผู้เอาประกันภัย ณ วันครบรอบปีกรมธรรม์\n',
        'แต่ละปี\n',
        '- ค่าการประกันภัยสําหรับสัญญาเพิ่มเติมแบบชําระค่าการประกันภัยโดย\n',
        'การขายคืนหน่วยลงทุน (ถ้ามี) จะถูกรวมเข้ากับค่าการประกันภัยสําหรับ\n',
        'ความคุ้มครองชีวิต\n',
        '- ผู้เอาประกันภัยอาจถูกเรียกเก็บค่าการประกันภัยสำหรับภัยต่ำกว่ามาตรฐานเพิ่ม\n',
        'เนื่องจากอาชีพ และ/หรือค่าการประกันภัยสำหรับภัยต่ำกว่ามาตรฐานเพิ่ม\n',
        'พิเศษชั่วคราวเนื่องจากสุขภาพ (ถ้ามี)\n',
        '- ในกรณีที่มูลค่ารับซื้อคืนหน่วยลงทุนมีมูลค่าไม่เพียงพอต่อการหักค่าการ\n',
        'ประกันภัยมูลค่าที่ไม่เพียงพอต่อการหักค่าการประกันภัยดังกล่าวถือเป็นหนี้สิน\n',
        'ที่ค้างชำระภายใต้สัญญาประกันภัยนี้ เมื่อมีการชำระเบี้ยประกันภัยหลัก\n',
        'เพื่อความคุ้มครองและ/หรือเบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษ (ถ้ามี)\n',
        'บริษัทจะทำการหักหนี้สินสะสมดังกล่าว จากเบี้ยประกันภัยที่ชำระหลังจาก\n',
        'หักค่าธรรมเนียมในการจัดสรรเบี้ยประกันภัยดังกล่าว (ถ้ามี) ก่อนนำไปลงทุน\n',
        '- ตารางอัตราค่าการประกันภัยรายปีสําหรับความคุ้มครองชีวิตที่แนบมานี้คำนวณ\n',
        'จากอัตรามรณะตามตาราง มรณะไทย ประเภทสามัญ ตามที่นายทะเบียน\n',
        'ประกาศกําหนด ซึ่งอาจเปลี่ยนแปลงได้ และบริษัทจะแจ้งให้ผู้เอาประกันภัย\n',
        'ทราบล่วงหน้าเป็นลายลักษณ์อักษรไม่น้อยกว่าสามเดือน (3 เดือน) \n',
        'ก่อนวันที่การเปลี่ยนแปลงนั้นมีผลบังคับ',
      ],
    },
    {
      text: ['อ้างอิงจากอัตราค่าการประกันภัยที่ได้รับการอนุมัติจากนายทะเบียนตามหลักเกณฑ์ที่บริษัทกำหนด\n'],
    },
  ]

  const content = [
    {
      fontSize: 12,
      table: {
        widths: ['22%', '35%', '43%'],
        body: [tableRPUDRHeader, row1],
      },
      layout,
    },
  ]

  return Section(TITLE_RPUDR_CONTINUED, content)
}

const rpudrFeeDetailsContentContinued_2 = () => {
  const row2 = [
    {
      text: ['ค่าธรรมเนียมการบริหารกรมธรรม์'],
      bold: true,
    },
    {
      text: [
        '- อัตราค่าธรรมเนียมการบริหารกรมธรรม์รายเดือนจะเท่ากับอัตราค่าธรรมเนียมการบริหารกรมธรรม์ต่อปี ที่อัตราร้อยละ 0.6 ของมูลค่ารับซื้อคืนหน่วยลงทุน \nหารด้วยสิบสอง (12)\n',
        '- ในกรณีที่มูลค่ารับซื้อคืนหน่วยลงทุนมีมูลค่าไม่เพียงพอต่อการหักค่าธรรมเนียมการบริหารกรมธรรม์มูลค่าที่ไม่เพียงพอต่อการหักค่าธรรมเนียมการบริหาร\n',
        'กรมธรรม์ดังกล่าวถือเป็นหนี้สินที่ค้างชำระภายใต้สัญญาประกันภัยนี้ เมื่อมีการ\n',
        'ชำระเบี้ยประกันภัยหลักเพื่อความคุ้มครองและ/หรือเบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษ (ถ้ามี) บริษัทจะทำการหักหนี้สินสะสมดังกล่าวจากเบี้ยประกันภัย\n',
        'ที่ชำระหลังจากหักค่าธรรมเนียมในการจัดสรรเบี้ยประกันภัยดังกล่าว (ถ้ามี)\n',
        'ก่อนนำไปลงทุน\n',
        '- ค่าธรรมเนียมการบริหารกรมธรรม์อาจเปลี่ยนแปลงได้ตามที่ได้รับความเห็นชอบ\n',
        'จากนายทะเบียน ทั้งนี้ การเปลี่ยนแปลงค่าธรรมเนียมแต่ละครั้ง บริษัทจะแจ้งให้\n',
        'ผู้เอาประกันภัยทราบล่วงหน้าเป็นลายลักษณ์อักษรไม่น้อยกว่าสามเดือน (3 เดือน)\n',
        'ก่อนวันที่การเปลี่ยนแปลงนั้นมีผลบังคับ\n',
      ],
    },
    {
      text: ['อัตราร้อยละ 0.6 ของมูลค่ารับซื้อคืนหน่วยลงทุนต่อปี'],
    },
  ]

  const row3 = [
    {
      text: ['ค่าธรรมเนียมในการถอนเงินจากกรมธรรม์'],
      bold: true,
    },
    {
      text: [
        '- บริษัทจะคิดค่าธรรมเนียมในการถอนเงินจากกรมธรรม์ การเวนคืนกรมธรรม์\n',
        'และการเวนคืนกรมธรรม์โดยอัตโนมัติ จากมูลค่ารับซื้อคืนหน่วยลงทุนของ\n',
        'เบี้ยประกันภัยหลักเพื่อความคุ้มครอง\n',
        '- ในกรณีที่มีการเปลี่ยนแปลงงวดการชำระเบี้ยประกันภัยหลักเพื่อความคุ้มครอง\n',
        'ค่าธรรมเนียมในการถอนเงินจาก กรมธรรม์ที่เรียกเก็บจะเป็นไปตามอัตรา\n',
        'ที่ระบุในปีที่ชำระเบี้ยประกันภัยถัดจากงวดสุดท้ายที่ได้ชำระ\n',
        '- บริษัทจะไม่คิดค่าธรรมเนียมในการถอนเงินจากกรมธรรม์ การเวนคืนกรมธรรม์\n',
        'และการเวนคืนกรมธรรม์โดยอัตโนมัติจากมูลค่ารับซื้อคืนหน่วยลงทุนของ\n',
        'เบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษ',
      ],
    },
    [
      {
        style: 'center',
        table: {
          widths: ['16%', '16%', '17%', '17%', '17%', '*', '*'],
          body: [
            [
              { alignment: 'center', text: 'ปีที่ชำระเบี้ยประกันภัย', rowSpan: 2 },
              { colSpan: 4, alignment: 'center', text: 'ครั้งที่ของงวดการชำระเบี้ยประกันภัย' },
              {},
              {},
              {},
              {
                rowSpan: 2,
                colSpan: 2,
                alignment: 'center',
                text: 'ร้อยละของมูลค่ารับซื้อคืนหน่วยลงทุนของเบี้ยประกันภัยหลักเพื่อความคุ้มครองที่ถอน',
              },
              {},
            ],
            [
              {},
              { alignment: 'center', text: 'รายปี' },
              { alignment: 'center', text: 'ราย\n6 เดือน' },
              { alignment: 'center', text: 'ราย\n3 เดือน' },
              { alignment: 'center', text: 'รายเดือน' },
              {},
              {},
            ],
            [
              { alignment: 'center', text: '1' },
              { alignment: 'center', text: '1' },
              { alignment: 'center', text: '1 - 2' },
              { alignment: 'center', text: '1 - 4' },
              { alignment: 'center', text: '1 - 12' },
              { colSpan: 2, alignment: 'center', text: '50' },
              {},
            ],
            [
              { alignment: 'center', text: '2' },
              { alignment: 'center', text: '2' },
              { alignment: 'center', text: '3 - 4' },
              { alignment: 'center', text: '5 - 8' },
              { alignment: 'center', text: '13 - 24' },
              { colSpan: 2, alignment: 'center', text: '30' },
              {},
            ],
            [
              { alignment: 'center', text: '3' },
              { alignment: 'center', text: '3' },
              { alignment: 'center', text: '5 - 6' },
              { alignment: 'center', text: '9 - 12' },
              { alignment: 'center', text: '25 - 36' },
              { colSpan: 2, alignment: 'center', text: '10' },
              {},
            ],
            [
              { alignment: 'center', text: '4' },
              { alignment: 'center', text: '4' },
              { alignment: 'center', text: '7 - 8' },
              { alignment: 'center', text: '13 - 16' },
              { alignment: 'center', text: '37 - 48' },
              { colSpan: 2, alignment: 'center', text: '5' },
              {},
            ],
            [
              { alignment: 'center', text: '5 เป็นต้นไป' },
              { alignment: 'center', text: '5 เป็นต้นไป' },
              { alignment: 'center', text: '9 เป็นต้นไป' },
              { alignment: 'center', text: '17 เป็นต้นไป' },
              { alignment: 'center', text: '49 เป็นต้นไป' },
              { colSpan: 2, alignment: 'center', text: '0' },
              {},
            ],
          ],
        },
        layout: {
          hLineColor: VALUE.MEDIUM_BLUE,
          vLineColor: VALUE.MEDIUM_BLUE,
        },
      },
    ],
  ]

  const content = [
    {
      fontSize: 12,
      table: {
        widths: ['22%', '35%', '43%'],
        body: [tableRPUDRHeader, row2, row3],
      },
      layout,
    },
  ]

  return Section(TITLE_RPUDR_CONTINUED, content)
}

const rpudrFeeDetailsContentContinued_3 = () => {
  const row4 = [
    {
      text: ['ค่าธรรมเนียมในการสับเปลี่ยนกองทุน'],
      bold: true,
    },
    {
      text: [
        '- บริษัทจะไม่คิดค่าธรรมเนียมในการสับเปลี่ยนกองทุน\n',
        '- บริษัทสงวนสิทธิในการเปลี่ยนแปลงค่าธรรมเนียมในการสับเปลี่ยนกองทุน\n',
        'โดยต้องได้รับความเห็นชอบจากนายทะเบียนก่อน และบริษัทจะแจ้งให้\n',
        'ผู้เอาประกันภัยทราบล่วงหน้าเป็นลายลักษณ์อักษรไม่น้อยกว่าสามเดือน\n',
        '(3 เดือน) ก่อนวันที่การเปลี่ยนแปลงนั้นมีผลบังคับ',
      ],
    },
    {
      text: ['ไม่มีค่าธรรมเนียม'],
    },
  ]

  const row5 = [
    {
      text: ['ค่าธรรมเนียมการขอใบแจ้งรายงานสถานะทางการเงินของกรมธรรม์'],
      bold: true,
    },
    {
      text: [
        '- บริษัทจะจัดส่งใบแจ้งรายงานสถานะทางการเงินของกรมธรรม์ให้แก่\n',
        'ผู้เอาประกันภัยอย่างน้อยปีละสองครั้ง\n',
        '- บริษัทสงวนสิทธิในการเปลี่ยนแปลงค่าธรรมเนียมการขอใบแจ้งรายงานสถานะ\n',
        'ทางการเงินของกรมธรรม์ โดยอ้างอิงกับ ดัชนีราคาผู้บริโภคซึ่งจัดทำโดยกระทรวง\n',
        'พาณิชย์และต้องได้รับความเห็นชอบจากนายทะเบียนก่อน และบริษัทจะแจ้งให้\n',
        'ผู้เอาประกันภัยทราบล่วงหน้าเป็นลายลักษณ์อักษรไม่น้อยกว่าสามเดือน (3 เดือน)\n',
        'ก่อนวันที่การเปลี่ยนแปลงนั้นมีผลบังคับ',
      ],
    },
    {
      text: ['ฉบับละ 100 บาท'],
    },
  ]

  const content = [
    {
      fontSize: 12,
      table: {
        widths: ['22%', '35%', '43%'],
        body: [tableRPUDRHeader, row4, row5],
      },
      layout,
    },
  ]

  return Section(TITLE_RPUDR_CONTINUED, content)
}

export const FeeDetails = (productCode: string, rateYear: string) => {
  switch (productCode) {
    case VALUE.SPUL:
      return iInvestFeeDetailsContent()
    case VALUE.RPUL:
      return iWealthyFeeDetailsContent(rateYear)
    case VALUE.RPUDR:
      return rpudrFeeDetailsContent()
    default:
      return iWealthyFeeDetailsContent(rateYear)
  }
}

export const FeeDetailsContinued_1 = (productCode: string) => {
  switch (productCode) {
    case VALUE.RPUDR:
      return rpudrFeeDetailsContentContinued_1()
    default:
      return []
  }
}

export const FeeDetailsContinued_2 = (productCode: string) => {
  switch (productCode) {
    case VALUE.RPUDR:
      return rpudrFeeDetailsContentContinued_2()
    default:
      return []
  }
}

export const FeeDetailsContinued_3 = (productCode: string) => {
  switch (productCode) {
    case VALUE.RPUDR:
      return rpudrFeeDetailsContentContinued_3()
    default:
      return []
  }
}
