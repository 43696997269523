// @flow

import type { Age } from 'core/data-model/insured'
import VALUES from 'core/data-model/constants/values'
import MESSAGES from 'core/data-model/constants/messages'

export const isAgeLessThan = (age: Age, otherAge: Age): boolean => {
  if (age.unit === otherAge.unit) {
    return age.value < otherAge.value
  }
  return otherAge.unit === 'year' || (age.unit === 'day' && otherAge.unit === 'month')
}

export const isUnderage = (age: Age): boolean => {
  return isAgeLessThan(age, VALUES.LEGAL_AGE) && isAgeMoreThan(age, VALUES.VALID_AGE)
}

export const isPreAdult = (age: Age): boolean => {
  return isUnderage(age) && isAgeMoreThanOrEqual(age, { value: 18, unit: 'year' })
}

export const isPreAdultForPayerRelation = (age: Age): boolean => {
  return isUnderage(age) && isAgeMoreThanOrEqual(age, { value: 16, unit: 'year' })
}

export const isKidForPayerRelation = (age: Age): boolean => {
  return isAgeLessThanOrEqual(age, { value: 15, unit: 'year' })
}

export const isKid = (age: Age): boolean => {
  return isAgeLessThan(age, VALUES.MAX_KID_AGE)
}

export const isAgeMoreThan = (age: Age, otherAge: Age): boolean => isAgeLessThan(otherAge, age)

export const isAgeEqual = (age: Age, otherAge: Age): boolean => {
  return age.unit === otherAge.unit && age.value === otherAge.value
}
export const isAgeMoreThanOrEqual = (age: Age, otherAge: Age): boolean =>
  isAgeMoreThan(age, otherAge) || isAgeEqual(age, otherAge)

export const isAgeLessThanOrEqual = (age: Age, otherAge: Age): boolean =>
  isAgeLessThan(age, otherAge) || isAgeEqual(age, otherAge)

export const renderUnit = (unit: string) => {
  switch (unit) {
    case 'year':
      return MESSAGES.TIME_UNIT_YEAR
    case 'month':
      return MESSAGES.TIME_UNIT_MONTH
    case 'day':
      return MESSAGES.TIME_UNIT_DAY
    default:
      return MESSAGES.TIME_UNIT_YEAR
  }
}

export const asYears = (age: Age) => {
  if (age.unit === 'year') {
    return age.value
  } else {
    return 0
  }
}

export const becomeOlder = (age: Age, years: number): Age => {
  const yearsToAdd = years <= 0 ? 0 : years
  const isAddingNothing = yearsToAdd === 0

  return isAddingNothing ? age : { value: asYears(age) + yearsToAdd, unit: 'year' }
}

export const isZeroDay = (age: Age): boolean => {
  return age.value === 0 && age.unit === 'day'
}

export const renderBIAge = (age: Age, isReviseQQ: boolean): string =>
  isReviseQQ && age.unit === 'month' ? `- ${renderUnit(age.unit)}` : `${age.value} ${renderUnit(age.unit)}`
