import React from 'react'
import PropTypes from 'prop-types'

import { getToggles } from 'quick-quote/feature-toggles'
import NeedButton from './need-button'

function NeedList(props) {
  const { needs, selectedNeedId } = props
  const { setSelectedNeedId } = props
  const needVersion = getToggles().FNA_NEED_VERSION || 1

  return (
    <div id="az-need-and-gap__section--need" className="az-need-and-gap__section az-need-and-gap__section--need">
      <div className="az-need-and-gap__title">
        <h1 className="az-need-and-gap__title-text">คุณกำลังมองหาความคุ้มครองแบบไหน</h1>
      </div>
      <div className="az-need-and-gap__content">
        <div className={`az-need-and-gap__need-list need-version-${needVersion}`}>
          {needs.length
            ? needs
                .filter((n) => n._v === needVersion)
                .map((item) => (
                  <NeedButton
                    key={item.id}
                    id={item.id}
                    title={item.description}
                    onClick={() => setSelectedNeedId(item.needId || item.id, item.planId)}
                    active={item.id === selectedNeedId || item.needId === selectedNeedId}
                  />
                ))
            : null}
        </div>
      </div>
    </div>
  )
}

const propTypes = {
  needs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    })
  ),
  selectedNeedId: PropTypes.string,
  setSelectedNeedId: PropTypes.func,
}
const defaultProps = {
  needs: [],
  selectedNeedId: '',
  setSelectedNeedId() {},
}

NeedList.propTypes = propTypes
NeedList.defaultProps = defaultProps

export default NeedList
