import { compose } from 'recompose'
import { connect } from 'react-redux'
import {
  getInsuredOtherOccupation,
  getPayerOtherOccupation,
  getInsuredMainOccupation,
  getPayerMainOccupation,
} from 'e-submission/apps/selectors'

const OccupationText = ({ occupation }) => {
  const getOccupationLabel = (occupation) => {
    if (occupation && occupation.th && occupation.th.occupationName && occupation.th.natureOfDuty) {
      return occupation.th.occupationName + ', ' + occupation.th.natureOfDuty
    } else {
      return ''
    }
  }

  return <div>{getOccupationLabel(occupation)}</div>
}

const mapStateToProps = (state, { type, insuredOrPayer }) => {
  let occupation = {}

  if (type === 'main') {
    if (insuredOrPayer === 'insured') {
      occupation = getInsuredMainOccupation(state)
    } else {
      occupation = getPayerMainOccupation(state)
    }
  } else if (type === 'other') {
    if (insuredOrPayer === 'insured') {
      occupation = getInsuredOtherOccupation(state)
    } else {
      occupation = getPayerOtherOccupation(state)
    }
  }

  return {
    occupation,
  }
}

export default (hoc) => compose(connect(mapStateToProps), hoc)(OccupationText)
