// @flow

import type { AppState } from 'quick-quote/reducers'
import type { CoveragePlanDispatchers } from './components'
import { CoveragePlan } from './components'

import type { Dispatch } from 'redux'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { canProceedToCoveragePlan } from 'quick-quote/selectors/next-tab-proceeding'
import { getAvailableRiderCodes, getPensionType } from 'quick-quote/product-common/coverage-plan/selectors'

import {
  toggleRider,
  editRiderSumAssured,
  editRiderSelectedPlan,
  editPensionType,
} from 'quick-quote/product-common/coverage-plan/actions'

import { getSelectedDisplayProductCode } from 'quick-quote/product-selection/selectors'

import VALUES from 'core/data-model/constants/values'
import MESSAGES from 'core/data-model/constants/messages'

const mapStateToProps = (state: AppState) => ({
  riderCodes: getAvailableRiderCodes(state),
  canProceedToCoveragePlan: canProceedToCoveragePlan(state),
  pensionType: getPensionType(state),
  infoMessage: getSelectedDisplayProductCode(state) === VALUES.IRETIRE_1 ? MESSAGES.RETIREMENT_RIDER_INFO_MESSAGE : '',
})

const mapDispatchToProps = (dispatch: Dispatch<*>): CoveragePlanDispatchers => ({
  toggleRider: bindActionCreators(toggleRider, dispatch),
  editRiderSumAssured: bindActionCreators(editRiderSumAssured, dispatch),
  editRiderSelectedPlan: bindActionCreators(editRiderSelectedPlan, dispatch),
  editPensionType: bindActionCreators(editPensionType, dispatch),
})

export const CoveragePlanContainer = connect(mapStateToProps, mapDispatchToProps)(CoveragePlan)
