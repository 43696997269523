// @flow

import type { ModelFactor, Period } from 'core/data-model/basic-plan'
import DEFAULTS from 'core/data-model/constants/defaults'
import MESSAGES from 'core/data-model/constants/messages'
import type { DisplayProduct } from 'core/service/display-product'
import type {
  ExpectedReturnComparison,
  FundAllocationToggleItem,
  RegularPremium,
  RegularTopUp,
  SumAssured,
  TotalFundAllocation,
} from 'core/service/investment'
import { calculateCoverageDeathBenefit } from 'core/service/investment'
import { formatNumber } from 'core/service/lib/number-format'

import _ from 'lodash'
import {
  getModelFactors,
  getModelFactorsForUser,
  getTotalRiderPremium,
  getSelectedRiders,
} from 'quick-quote/product-common/coverage-plan/selectors'
import {
  getRppFundAllocations,
  getRppTotalAllocations,
} from 'quick-quote/product-investment/fund-allocation/rpp/selectors'
import {
  getDisplayProducts,
  getSelectedDisplayProduct,
  getSelectedDisplayProductCode,
} from 'quick-quote/product-selection/selectors'
import type { AppState } from 'quick-quote/reducers'
import type { Rider, RiderState } from 'core/data-model/rider'
import { createSelector } from 'reselect'
import { hasRiderErrors } from 'quick-quote/product-investment/coverage-plan/sagas'

export const getRegularPremium = (state: AppState): RegularPremium =>
  state.investment.coveragePlan.selectedBasicPlan.regularPremium

export const getRegularTopUp = (state: AppState): RegularTopUp =>
  state.investment.coveragePlan.selectedBasicPlan.regularTopUp

export const getRegularPremiumRatio = (state: AppState): number =>
  state.investment.coveragePlan.selectedBasicPlan.regularPremiumRatio

export const getSelectedModelFactorID = (state: AppState): string => state.investment.coveragePlan.selectedModelFactorID

export const getPreviousModelFactorID = (state: AppState): string => state.investment.coveragePlan.previousModelFactorID

export const getSumAssured = (state: AppState): SumAssured => state.investment.coveragePlan.selectedBasicPlan.sumAssured

export const getExpectedReturnComparisons = (state: AppState): ExpectedReturnComparison[] =>
  state.investment.coveragePlan.expectedReturnComparisons.value

export const getLumpSumTopUp = (state: AppState): number =>
  _.get(_.first(getExpectedReturnComparisons(state)), 'lumpSum.value', 0)

export const getLumpSumErrors = (state: AppState): string[] =>
  state.investment.coveragePlan.expectedReturnComparisons.errors

export const hasFirstYearLstu = createSelector(
  getLumpSumTopUp,
  getLumpSumErrors,
  (lstu, errors) => lstu > 0 && errors.length === 0
)

export const getPayFirstMonthMessage = (state: AppState): string => {
  const modelFactorID = getSelectedModelFactorID(state)
  const regularPremium = getRegularPremium(state)
  const regularTopUp = getRegularTopUp(state)
  const totalPremium = regularPremium.value + regularTopUp.value
  return modelFactorID !== DEFAULTS.SELECTED_MODEL_FACTOR_MONTHLY
    ? ''
    : `(${MESSAGES.PAY_FIRST_MONTH} ${formatNumber(totalPremium * 2)} ${MESSAGES.BAHT})`
}

export const getPayFirstMonthAndTotalRiderMessage = (state: AppState): string => {
  const modelFactorID = getSelectedModelFactorID(state)
  const regularPremium = getRegularPremium(state)
  const totalRiderPremium = getTotalRiderPremium(state)
  const totalPremium = regularPremium.value + totalRiderPremium
  return modelFactorID !== DEFAULTS.SELECTED_MODEL_FACTOR_MONTHLY
    ? ''
    : `(${MESSAGES.PAY_FIRST_MONTH} ${formatNumber(totalPremium * 2, 2)} ${MESSAGES.BAHT})`
}

export const getLumpSum = createSelector(
  getExpectedReturnComparisons,
  (expectedReturnComparisons: ExpectedReturnComparison[]) =>
    expectedReturnComparisons.reduce((acc, { year, lumpSum }) => {
      if (lumpSum === 'DISALLOWED') {
        return acc
      }
      return acc.concat([{ year, value: lumpSum.value }])
    }, [])
)

export const getCoverageDeathBenefit = (state: AppState): number => {
  const displayProduct = getSelectedDisplayProduct(state)
  return calculateCoverageDeathBenefit(displayProduct.code, getSumAssured(state).value)
}

export const getCoveragePeriod = (state: AppState): number => {
  let displayProduct = getDisplayProducts(state)
  let selectedProduct = getSelectedDisplayProductCode(state)
  let period: Period = {
    type: 'limited_by_duration',
    value: 0,
  }

  displayProduct.forEach((product, index) => {
    if (product.code === selectedProduct) {
      period = product.coveragePeriod
    }
  })
  return period.value
}

export const getAvailableModelFactors = createSelector(
  getSelectedDisplayProduct,
  getModelFactorsForUser,
  (selectedBasicPlan: ?DisplayProduct, modelFactors: ModelFactor[]): ModelFactor[] =>
    modelFactors.filter((modelFactor) =>
      selectedBasicPlan && selectedBasicPlan ? selectedBasicPlan.modelFactors.indexOf(modelFactor.id) > -1 : false
    )
)

export const getSelectedModelFactor = createSelector(
  getModelFactors,
  getSelectedModelFactorID,
  (modelFactors: ModelFactor[], selectedModelFactorID: string): ?ModelFactor =>
    _.find(modelFactors, { id: selectedModelFactorID })
)

export const getSelectedPaymentFrequency = createSelector(
  getSelectedModelFactor,
  (selectedModelFactor: ?ModelFactor): string => _.get(selectedModelFactor, 'paymentFrequency', '-')
)
export const getSelectedModelFactorLabel = createSelector(
  getSelectedModelFactor,
  (selectedModelFactor: ?ModelFactor): string => _.get(selectedModelFactor, 'displayLabel', '-')
)

export const getSelectedModelFactorValue = createSelector(getSelectedModelFactor, (modelFactor): number =>
  modelFactor ? modelFactor.factor : 1
)

export const getSelectedModelFactorILPValue = createSelector(
  getSelectedModelFactor,
  (selectedModelFactor: ?ModelFactor): number => (selectedModelFactor ? selectedModelFactor.factorILP : 0)
)

export const getSelectedModelFactorPeriod = createSelector(
  getSelectedModelFactor,
  (selectedModelFactor: ?ModelFactor): number => (selectedModelFactor ? selectedModelFactor.periods : 0)
)

export const _isValidSumAssured = (sumAssured: SumAssured) => {
  return sumAssured.value !== 0 && !sumAssured.errors.some((e) => e.type === 'VALIDATION')
}
export const _isValidTopUp = (regularTopUp: RegularTopUp) => {
  return regularTopUp.errors.length === 0
}
export const _isValidPremium = (regularPremium: RegularPremium) => {
  return regularPremium.value !== 0 && regularPremium.errors.length === 0
}

export const isLumpSumValid = createSelector(getLumpSumErrors, (lumpSumErrors: string[]): boolean =>
  _.isEmpty(lumpSumErrors)
)

export const isPremiumPageValid = createSelector(
  getSumAssured,
  getRegularTopUp,
  getRegularPremium,
  getSelectedRiders,
  (
    sumAssured: SumAssured,
    regularTopUp: RegularTopUp,
    regularPremium: RegularPremium,
    riders: Rider & RiderState[]
  ): boolean => {
    return (
      _isValidSumAssured(sumAssured) &&
      _isValidTopUp(regularTopUp) &&
      _isValidPremium(regularPremium) &&
      !hasRiderErrors(riders)
    )
  }
)

export const _isFundPageValid = (totalAllocation: number, fundAllocation: FundAllocationToggleItem[]) => {
  const fullAllocation = 100
  return totalAllocation === fullAllocation && !fundAllocation.some(({ errors }) => !_.isEmpty(errors))
}

export const isFundPageValid = createSelector(
  getRppFundAllocations,
  getRppTotalAllocations,
  (fundAllocation: FundAllocationToggleItem[], totalAllocation: TotalFundAllocation): boolean =>
    _isFundPageValid(totalAllocation.value, fundAllocation)
)
