// @flow
import type { DisplayProduct } from 'core/service/display-product'
import { getLifeEnsureBenefitData, isLifeEnsureProduct } from 'core/service/basic-plan/benefit'
import Mustache from 'mustache'
import MESSAGES from 'core/data-model/constants/bi-messages'

type LifeEnsureProductBenefitProps = {
  displayProduct: DisplayProduct,
}

const LifeEnsureProductBenefits = ({ displayProduct }: LifeEnsureProductBenefitProps) => {
  if (!isLifeEnsureProduct(displayProduct.basicPlanCode)) return null
  const benefitData = getLifeEnsureBenefitData()
  return (
    <div id="product-benefits-content">
      <p>
        <b>{MESSAGES.BASIC_PLAN_BENEFITS}</b>
      </p>
      <ol id="benefit-list">
        <li>
          {Mustache.render(benefitData.paidAndCoveragePeriod, {
            paymentPeriod: displayProduct.paymentPeriod.value,
            coveragePeriod: displayProduct.coveragePeriod.value,
          })}
        </li>
        <li>{benefitData.coverageAmountDetails}</li>
        <li>
          {Mustache.render(benefitData.maturityDetails, {
            coveragePeriod: displayProduct.coveragePeriod.value,
          })}
        </li>
      </ol>
      <p>
        <b>{benefitData.beneficiaryBenefits}</b>
      </p>
      <div className="info-pair" id="death-benefit">
        <p className="description" id="death-benefit-sum-assured">
          {benefitData.minimumAmountReceivedByBeneficiaries}
          <br />
          {benefitData.coverageDetails}
        </p>
      </div>
    </div>
  )
}

export default LifeEnsureProductBenefits
