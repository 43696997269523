//@flow

export default {
  RULE_MESSAGE_NOT_FOUND: 'ERROR NOT FOUND',

  // basic plans
  RULE_AGE: 'อายุของ{{owner}}ต้องอยู่ระหว่าง {{minimumAge}} ถึง {{maximumAge}}',
  RULE_BIRTHDATE: 'กรุณากรอก วัน เดือน ปี เกิดใหม่',
  RULE_YEAR_OF_PAYMENT_WPD:
    'สัญญาเพิ่มเติม {{riderName}} ไม่สามารถซื้อกับแบบประกันที่ระยะเวลาชำระเบี้ยประกัน {{yearsOfPayment}} ปีได้',
  RULE_TITLE_EMPTY_OTHER: 'กรุณากรอกคำนำหน้าชื่อ',
  RULE_NAME_EXCEED_LENGTH: 'กรุณากรอกใหม่',
  RULE_NAME_INVALID_CHARACTER: 'กรุณากรอกใหม่',
  RULE_OCCUPATION_FACTOR: 'อาชีพมีความเสี่ยงสูงเกินไป',
  RULE_INSURED_OCCUPATION_FACTOR: 'อาชีพมีความเสี่ยงเกินไป',
  RULE_PAYER_OCCUPATION_FACTOR: 'อาชีพของผู้ชำระเบี้ยไม่สามารถซื้อสัญญาเพิ่มเติมนี้ได้',
  RULE_INSURED_PRODUCT_OCCUPATION_FACTOR: 'อาชีพของผู้เอาประกันภัยไม่สามารถซื้อแบบประกันภัยนี้ได้',
  RULE_SUM_ASSURED_RANGE:
    'จำนวนเงินเอาประกันภัยต้องอยู่ระหว่าง {{sumAssuredMinimum}} บาท ถึง {{sumAssuredMaximum}} บาท',
  RULE_BASIC_PREMIUM_NOT_EQUAL_INPUT: 'เบี้ยประกันภัยสัญญาหลักตามทุนประกันคือ {{basicPremium}} บาท',
  RULE_BASIC_PREMIUM_MINIMUM: 'เบี้ยขั้นต่ำคือ {{basicPremiumMinimum}}',
  RULE_TOTAL_PREMIUM_NOT_EQUAL_INPUT: 'เบี้ยประกันภัยที่ท่านต้องชำระคือ {{totalPremium}} บาท',
  RULE_TOTAL_PREMIUM_MINIMUM: 'เบี้ยประกันภัยน้อยเกินไป',
  RULE_TOTAL_PREMIUM_AT_LEAST: 'เบี้ยประกันภัยรวมต้องไม่ต่ำกว่า {{minimumTotalPremium}} บาท',

  RULE_MRTA_COVERAGE_PERIOD_MAXIMUM: 'ระยะเวลาคุ้มครองต้องไม่มากกว่าระยะเวลากู้',
  RULE_MRTA_SUM_ASSURED_MAXIMUM: 'จำนวนเงินเอาประกันภัยต้องไม่มากกว่าจำนวนเงินกู้ตามสัญญาธนาคาร',
  RULE_MRTA_SUM_ASSURED_CONTRACT_MAXIMUM:
    'รวมเบี้ยในวงเงินกู้ จำนวนเงินเอาประกันภัยรวมเบี้ยในวงเงินกู้ จะต้องไม่เกิน {{sumAssuredMaximum}} บาท',
  RULE_GLTSP_INTEREST_RATE_RANGE: 'อัตราดอกเบี้ยกรณีกู้จ่ายค่าเบี้ยต้องมีมูลค่ามากกว่า 0 และไม่เกิน 100 เปอร์เซนต์',
  // all riders
  RULE_RIDER_INCOMPATIBILITY:
    'สัญญาเพิ่มเติม {{riderName}} ไม่สามารถซื้อคู่กับสัญญาเพิ่มเติม {{riderIncompatibilities}} ได้',

  RIDER_INVALID_PAYER: 'RIDER_INVALID_PAYER',
  RULE_RIDER_PREREQUISITE: 'สัญญาเพิ่มเติม {{riderName}} ต้องซื้อคู่กับ สัญญาเพิ่มเติม {{riderPrerequisite}}',
  RULE_RIDER_OCCUPATION_FACTOR: 'อาชีพของผู้เอาประกันไม่สามารถซื้อสัญญาเพิ่มเติมนี้ได้',
  RULE_RIDER_OCCUPATION: 'อาชีพของผู้เอาประกันไม่สามารถซื้อแผนประกันสำเร็จรูปนี้ได้',
  RULE_RIDER_INSURED_AGE: 'อายุของผู้ขอเอาประกันต้องอยู่ระหว่าง {{minimumAge}} ถึง {{maximumAge}}',
  RULE_RIDER_PAYER_AGE:
    'สำหรับผู้เอาประกันอายุ {{insuredAge}} อายุของผู้ชำระเบี้ยต้องอยู่ระหว่าง 20 ปี ถึง {{maximumPayerAge}}',
  RULE_RIDER_SUM_ASSURED_TIERS:
    'สำหรับจำนวนเงินเอาประกันภัยสัญญาหลัก {{minimumBasicSumAssured}} - {{maximumBasicSumAssured}} บาท สามารถซื้อสัญญาเพิ่มเติมได้สูงสุด {{maximumRiderSumAssured}} บาท',
  RULE_RIDER_SUM_ASSURED: 'จำนวนเงินเอาประกันภัยสัญญาหลักต้องมากกว่า {{minimumSumassured}} บาท',
  RULE_RIDER_INCOMPATIBILITY_AGE:
    'สัญญาเพิ่มเติม {{riderName}} ที่ผู้เอาประกันมีอายุน้อยกว่าหรือเท่ากับ {{minimumAge}} ไม่สามารถซื้อคู่กับสัญญาเพิ่มเติม {{riderIncompatibilities}} ได้',

  // rider specific
  RULE_RIDER_AP_SUM_ASSURED_FACTOR:
    'จำนวนเงินเอาประกันภัยต้องอยู่ระหว่าง 100,000 บาท ถึง 10 ล้านบาท และไม่เกิน 5 เท่าของจำนวนเงินเอาประกันภัยสัญญาหลัก',
  RULE_RIDER_AP_CHILD:
    'สำหรับผู้เอาประกันอายุ 1 เดือน ถึง 15 ปี สามารถซื้อสัญญาเพิ่มเติมได้สูงสุด 2 เท่าของจำนวนเอาประกันภัยสัญญาหลัก และไม่เกิน 3,000,000 บาท',
  RULE_RIDER_AP_CHILD_FACTOR:
    'สำหรับผู้เอาประกันอายุ 1 เดือน ถึง 15 ปี สามารถซื้อสัญญาเพิ่มเติมได้สูงสุด 2 เท่าของจำนวนเอาประกันภัยสัญญาหลัก และไม่เกิน 3,000,000 บาท',
  RULE_RIDER_CPR_SUM_ASSURED_FACTOR:
    'จำนวนเงินเอาประกันภัยต้องอยู่ระหว่าง 300,000 บาท ถึง 5 ล้านบาท และไม่เกิน 5 เท่าของจำนวนเงินเอาประกันภัยสัญญาหลัก',
  RULE_RIDER_CPR_HEALTH_CHECK: 'ผู้เอาประกันต้องตรวจสุขภาพหากมีอายุ 56 ปีขึ้นไป',
  RULE_RIDER_DCI_HEALTH_CHECK: 'ผู้เอาประกันต้องตรวจสุขภาพหากมีอายุ 56 ปีขึ้นไป',
  RULE_RIDER_ESCI_HEALTH_CHECK: 'ผู้เอาประกันต้องตรวจสุขภาพหากมีอายุ 56 ปีขึ้นไป',
  RULE_RIDER_DCI_SUM_ASSURED: 'จำนวนเงินเอาประกันภัยต้องอยู่ระหว่าง 200,000 บาท ถึง 10 ล้านบาท',
  RULE_RIDER_DCI_AGENT_SUM_ASSURED_FACTOR:
    'จำนวนเงินเอาประกันภัยต้องอยู่ระหว่าง 200,000 บาท ถึง 10 ล้านบาท และไม่เกิน 3 เท่าของจำนวนเงินเอาประกันภัยสัญญาหลัก',
  RULE_RIDER_DCI_LB_SUM_ASSURED_FACTOR:
    'จำนวนเงินเอาประกันภัยต้องอยู่ระหว่าง 200,000 บาท ถึง 5 ล้านบาท และไม่เกิน 3 เท่าของจำนวนเงินเอาประกันภัยสัญญาหลัก',
  RULE_RIDER_ECARE_SUM_ASSURED_FACTOR:
    'จำนวนเงินเอาประกันภัยต้องอยู่ระหว่าง 100,000 บาท ถึง 10 ล้านบาท และไม่เกิน 5 เท่าของจำนวนเงินเอาประกันภัยสัญญาหลัก',
  RULE_RIDER_ECARE_MONK: 'พระภิกษุ,นักบวช ซื้อได้ไม่เกิน 1 ล้านบาท',
  RULE_RIDER_ECARE_HOUSEWIFE: 'แม่บ้าน-พ่อบ้าน(ไม่มีรายได้) ซื้อได้ไม่เกิน 2 ล้านบาท',
  RULE_RIDER_ECARE_EARNING_MAJOR_STUDENT:
    'นักเรียน,นิสิต,นักศึกษา, กำลังศึกษา อายุตั้งแต่ 18 ปีขึ้นไป (มีรายได้จากการประกอบอาชีพอื่นๆ) ซื้อได้ไม่เกิน 2 ล้านบาท',
  RULE_RIDER_MEAPlus_MONK: 'พระภิกษุ,นักบวช ซื้อได้ไม่เกิน 4,200 บาท',
  RULE_RIDER_MEAPlus_CHILD: 'สำหรับผู้เอาประกันอายุ 0 ถึง 10 ปี สามารถซื้อสัญญาเพิ่มเติมได้สูงสุด 1,000 บาท',
  RULE_RIDER_MEB_CHILD: 'สำหรับผู้เอาประกันอายุ 6 ถึง 10 ปี สามารถซื้อสัญญาเพิ่มเติมได้สูงสุด 500 บาท',
  RULE_RIDER_MEB_TEEN: 'สำหรับผู้เอาประกันอายุ 11 ถึง 15 ปี สามารถซื้อสัญญาเพิ่มเติมได้สูงสุด 1,000 บาท',
  RULE_RIDER_MEB_STUDENT: 'นักเรียน,นิสิต,นักศึกษา ซื้อได้ไม่เกิน 1,000 บาท',
  RULE_RIDER_MEB_HOUSEWIFE: 'แม่บ้าน-พ่อบ้าน(ไม่มีรายได้) ซื้อได้ไม่เกิน 1,000 บาท',
  RULE_RIDER_MEB_REAL_ESTATE: 'หอพัก,ที่พัก,เกสเฮ้าส์,อพาร์ทเมนท์(เจ้าของ) ซื้อได้ไม่เกิน 1,000 บาท',
  RULE_RIDER_MEX_MONK: 'พระภิกษุ,นักบวช ซื้อได้ไม่เกิน 4,200 บาท',
  RULE_RIDER_MEX_CHILD: 'สำหรับผู้เอาประกันอายุ 1 เดือน ถึง 10 ปี สามารถซื้อสัญญาเพิ่มเติมได้สูงสุด 3,200 บาท',

  RULE_RIDER_HBUDR_CHILD: 'สำหรับผู้เอาประกันอายุ 6 ถึง 10 ปี สามารถซื้อสัญญาเพิ่มเติมได้สูงสุด 500 บาท',
  RULE_RIDER_HBUDR_TEEN: 'สำหรับผู้เอาประกันอายุ 11 ถึง 15 ปี สามารถซื้อสัญญาเพิ่มเติมได้สูงสุด 1,000 บาท',
  RULE_RIDER_HBUDR_STUDENT: 'นักเรียน,นิสิต,นักศึกษา ซื้อได้ไม่เกิน 1,000 บาท',
  RULE_RIDER_HBUDR_HOUSEWIFE: 'แม่บ้าน-พ่อบ้าน(ไม่มีรายได้) ซื้อได้ไม่เกิน 1,000 บาท',
  RULE_RIDER_HBUDR_REAL_ESTATE: 'หอพัก,ที่พัก,เกสเฮ้าส์,อพาร์ทเมนท์(เจ้าของ) ซื้อได้ไม่เกิน 1,000 บาท',

  RULE_RIDER_PLR_SUM_ASSURED_FACTOR:
    'จำนวนเงินเอาประกันภัยต้องอยู่ระหว่าง 300,000 บาท และไม่เกิน 5 เท่าของจำนวนเงินเอาประกันภัยสัญญาหลัก',
  RULE_RIDER_PLS_SUM_ASSURED_FACTOR:
    'จำนวนเงินเอาประกันภัยต้องอยู่ระหว่าง 300,000 บาท และไม่เกิน 5 เท่าของจำนวนเงินเอาประกันภัยสัญญาหลัก',
  RULE_RIDER_WP_SUM_ASSURED: 'จำนวนเงินเอาประกันภัย WP มีสูงสุดได้แค่ 20 ล้านบาท',
  RULE_RIDER_WPTPD_OVER_SUM_ASSURED:
    'สัญญาเพิ่มเติม WP Fit จะสามารถซื้อแนบได้กับสัญญาหลักที่มีจำนวนเงินเอาประกันภัยไม่เกิน 30 ล้านบาท',
  RULE_RIDER_WPTPDCI_OVER_SUM_ASSURED:
    'สัญญาเพิ่มเติม WP Beyond จะสามารถซื้อแนบได้กับสัญญาหลักที่มีจำนวนเงินเอาประกันภัยไม่เกิน 30 ล้านบาท',
  RULE_RIDER_PBPDD_OVER_SUM_ASSURED:
    'สัญญาเพิ่มเติม PB Fit จะสามารถซื้อแนบได้กับสัญญาหลักที่มีจำนวนเงินเอาประกันภัยไม่เกิน 30 ล้านบาท',
  RULE_RIDER_PBPDDCI_OVER_SUM_ASSURED:
    'สัญญาเพิ่มเติม PB Beyond จะสามารถซื้อแนบได้กับสัญญาหลักที่มีจำนวนเงินเอาประกันภัยไม่เกิน 30 ล้านบาท',
  RULE_RIDER_PBSDD_OVER_SUM_ASSURED:
    'สัญญาเพิ่มเติม PB Fit จะสามารถซื้อแนบได้กับสัญญาหลักที่มีจำนวนเงินเอาประกันภัยไม่เกิน 30 ล้านบาท',
  RULE_RIDER_PBSDDCI_OVER_SUM_ASSURED:
    'สัญญาเพิ่มเติม PB Beyond จะสามารถซื้อแนบได้กับสัญญาหลักที่มีจำนวนเงินเอาประกันภัยไม่เกิน 30 ล้านบาท',
  RULE_RIDER_AP_ECARE:
    'จำนวนเงินเอาประกันรวมของสัญญาเพิ่มเติม ECARE และ AP ต้องไม่เกิน 5 เท่าของจำนวนเอาประกันภัยสัญญาหลัก และสูงสุดไม่เกิน 10,000,000 บาท',
  RULE_RIDER_ECARE_AP:
    'จำนวนเงินเอาประกันรวมของสัญญาเพิ่มเติม ECARE และ AP ต้องไม่เกิน 5 เท่าของจำนวนเอาประกันภัยสัญญาหลัก และสูงสุดไม่เกิน 10,000,000 บาท',
  RULE_RIDER_WPCWL60_SUM_ASSURED:
    'จำนวนเงินเอาประกันภัยต้องอยู่ระหว่าง 100,000 บาท ถึง 3.5 ล้านบาท และต้องเท่ากับจำนวนเงินเอาประกันภัยสัญญาหลัก',
  RULE_RIDER_WPCWL99_SUM_ASSURED:
    'จำนวนเงินเอาประกันภัยต้องอยู่ระหว่าง 100,000 บาท ถึง 3.5 ล้านบาท และต้องเท่ากับจำนวนเงินเอาประกันภัยสัญญาหลัก',
  RULE_RIDER_WPC25PL_SUM_ASSURED:
    'จำนวนเงินเอาประกันภัยต้องอยู่ระหว่าง 100,000 บาท ถึง 3.5 ล้านบาท และต้องเท่ากับจำนวนเงินเอาประกันภัยสัญญาหลัก',
  RULE_RIDER_CIUDR_SUM_ASSURED_FACTOR:
    'จำนวนเงินเอาประกันภัยต้องอยู่ระหว่าง 150,000 บาท ถึง 10 ล้านบาท และไม่เกิน 5 เท่าของจำนวนเงินเอาประกันภัยของสัญญาหลัก',
  RULE_RIDER_CIUDR_LIMIT_INCREASING: 'จำนวนเงินเอาประกันภัยสามารถปรับเพิ่มขึ้น/ลดลง ครั้งละ 10,000 บาท',
  RULE_RIDER_TPDUDR_SUM_ASSURED:
    'จำนวนเงินเอาประกันภัยต้องอยู่ระหว่าง 500,000 บาท ถึง 100 ล้านบาท และไม่เกินจำนวนเงินเอาประกันภัยสัญญาหลัก',
  RULE_RIDER_TPDUDR_MUST_MORE_THAN_499999: 'จำนวนเงินเอาประกันภัยสัญญาหลักต้องมากกว่า 499,999 บาท',

  // linked riders rules
  RULE_RIDER_LINKED_AP_ECARE:
    'จำนวนเงินเอาประกันรวมของสัญญาเพิ่มเติม ECARE และ AP ต้องไม่เกิน 5 เท่าของจำนวนเอาประกันภัยสัญญาหลัก และสูงสุดไม่เกิน 10,000,000 บาท',
  RULE_RIDER_LINKED_ECARE_AP:
    'จำนวนเงินเอาประกันรวมของสัญญาเพิ่มเติม ECARE และ AP ต้องไม่เกิน 5 เท่าของจำนวนเอาประกันภัยสัญญาหลัก และสูงสุดไม่เกิน 10,000,000 บาท',
  RULE_RIDER_LINKED_AP_ECARE_MONK:
    'จำนวนเงินเอาประกันรวมของสัญญาเพิ่มเติม ECARE และ AP สำหรับพ่อบ้านแม่บ้าน และนักบวช/พระ ต้องไม่เกิน 5 เท่าของจำนวนเอาประกันภัยสัญญาหลัก และสูงสุดไม่เกิน 1,000,000 บาท',
  RULE_RIDER_LINKED_ECARE_AP_MONK:
    'จำนวนเงินเอาประกันรวมของสัญญาเพิ่มเติม ECARE และ AP สำหรับพ่อบ้านแม่บ้าน และนักบวช/พระ ต้องไม่เกิน 5 เท่าของจำนวนเอาประกันภัยสัญญาหลัก และสูงสุดไม่เกิน 1,000,000 บาท',
  RULE_RIDER_LINKED_AP_ECARE_HOUSEWIFE:
    'จำนวนเงินเอาประกันรวมของสัญญาเพิ่มเติม ECARE และ AP สำหรับพ่อบ้านแม่บ้าน และนักบวช/พระ ต้องไม่เกิน 5 เท่าของจำนวนเอาประกันภัยสัญญาหลัก และสูงสุดไม่เกิน 1,000,000 บาท',
  RULE_RIDER_LINKED_ECARE_AP_HOUSEWIFE:
    'จำนวนเงินเอาประกันรวมของสัญญาเพิ่มเติม ECARE และ AP สำหรับพ่อบ้านแม่บ้าน และนักบวช/พระ ต้องไม่เกิน 5 เท่าของจำนวนเอาประกันภัยสัญญาหลัก และสูงสุดไม่เกิน 1,000,000 บาท',
  //TODO: old- remove after refactor (#1070) finished
  RULE_RIDER_AP_ECARE_COMBINATION:
    'จำนวนเงินเอาประกันรวมของสัญญาเพิ่มเติม ECARE และ AP{{additionalWord}}ต้องไม่เกิน 5 เท่าของจำนวนเอาประกันภัยสัญญาหลัก และสูงสุดไม่เกิน {{maximumSumAssured}} บาท',
  RULE_IWEALTHY_TOTAL_FUND_LIMIT: 'สัดส่วนการลงทุนเกินกว่าที่กำหนด กรุณาตรวจสอบอีกครั้ง',
  RULE_IWEALTHY_MAX_FUND_LIMIT: 'สัดส่วนการลงทุนรวมต้องไม่เกิน 100%',
  RULE_IWEALTHY_MIN_FUND_LIMIT: 'สัดส่วนขั้นต่ำต่อหน่วยลงทุนคือ {{minFundAllocation}}%',
  RULE_IWEALTHY_MIN_TOPUP_PREMIUM:
    'เบี้ยประกันภัยเพื่อการลงทุนราย{{paymentFrequency}} ต้องไม่ต่ำกว่า {{minRegularTopUp}} บาท',
  RULE_IWEALTHY_MAX_TOPUP_PREMIUM: 'เบี้ยประกันภัยเพื่อการลงทุนต้องไม่เกิน 3 เท่าของ เบี้ยประกันภัยเพื่อความคุ้มครอง',
  RULE_IWEALTHY_SUM_ASSURED_LIMIT:
    'ตามสัดส่วนเบี้ยประกันภัย จำนวนเงินเอาประกันภัย ขั้นต่ำ {{minSumAssured}} บาท - สูงสุด {{maxSumAssured}} บาท',
  RULE_IWEALTHY_SUM_ASSURED_LESS_THAN_MAXIMUM: 'จำนวนเงินเอาประกันภัย ต้องไม่ต่ำกว่า {{maxSumAssured}} บาท',
  RULE_IWEALTHY_EXPECTED_SUM_ASSURED: 'จำนวนเงินเอาประกันภัยตามสัดส่วนเบี้ยประกันภัย คือ {{minSumAssured}} บาท',
  RULE_IWEALTHY_LUMP_SUM_MORE_THAN_MAXIMUM:
    'เบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษ (LSTU) ต่อปี สูงสุด 5 เท่าของเบี้ยประกันภัยหลักเพื่อความคุ้มครอง (RPP) ต่อปี',
  RULE_IWEALTHY_LUMP_SUM_MORE_THAN_TOTAL_MAXIMUM:
    'เบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษ (LSTU) รวมตลอดสัญญาประกันภัย สูงสุด 150,000,000 บาท',
  RULE_IWEALTHY_LUMP_SUM_LESS_THAN_MINIMUM:
    'เบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษ (LSTU) ต่อครั้ง ต้องไม่ต่ำกว่า {{lumpSum}} บาท',
  RULE_PDF_DIGITAL_PREREQUISITE:
    'กรุณากรอกวัน เดือน ปี เกิด ของลูกค้าที่หน้าข้อมูลผู้เอาประกันภัยเพื่อใช้เป็นรหัสเปิดใบเสนอขาย',
  RULE_ENTER_ESUB_PREREQUISITE:
    'กรุณากรอก วัน เดือน ปี เกิด และ อาชีพ ของผู้เอาประกัน และ/หรือ ผู้ชำระเบี้ย ให้ครบถ้วนเพื่อดำเนินการต่อ',
  RULE_IINVEST_LUMP_SUM_MORE_THAN_MAXIMUM:
    'เบี้ยประกันภัยเพิ่มเติมพิเศษ (LSTU) ต่อปี สูงสุด 10 เท่าของเบี้ยประกันภัยชำระครั้งเดียว',
  RULE_IINVEST_LUMP_SUM_LESS_THAN_MINIMUM:
    'เบี้ยประกันภัยเพิ่มเติมพิเศษ (LSTU) ต่อครั้ง ต้องไม่ต่ำกว่า {{lumpSum}} บาท',

  RULE_RPUDR_LUMP_SUM_LESS_THAN_MINIMUM: 'เบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษ (LSTU) ขั้นต่ำ 10,000 บาท',
  RULE_RPUDR_LUMP_SUM_MORE_THAN_TOTAL_MAXIMUM:
    'เบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษ (LSTU) รวมตลอดสัญญาประกันภัย สูงสุด 999,999,999 บาท',

  // LOAN PRODUCT
  RULE_LOAN_TERM:
    'ระยะเวลากู้ตามสัญญาธนาคาร ขั้นต่ำ {{minLoanTerm}} ปี ถึง {{maxLoanTerm}} ปี และรวมกับอายุผู้เอาประกันภัยไม่เกิน {{maxInsuredAgeAtEndOfLoanTerm}} ปี',
  RULE_LOAN_AMOUNT: 'จำนวนเงินกู้ตามสัญญาธนาคาร ขั้นต่ำ {{minimumValue}} บาท',
  RULE_LOAN_SUM_ASSURED: 'จำนวนเงินเอาประกันภัย ขั้นต่ำ {{minimumValue}} บาท หรือไม่เกินจำนวนเงินกู้ตามสัญญาธนาคาร',
  RULE_COVERAGE_PERIOD_INSURED: 'ระยะเวลาคุ้มครอง ขั้นต่ำ {{minCoverageTerm}} ปี หรือไม่เกินระยะเวลากู้ตามสัญญาธนาคาร',

  // MHP PRODUCT
  RULE_MHP_COPAYMENT_MESSAGE: 'ค่าใช้จ่ายร่วม (ร้อยละ 20% ของค่าใช้จ่ายที่ได้รับความคุ้มครอง)',
  RULE_MHP_MHPD1J_MESSAGE: 'ความรับผิดส่วนแรก 30,000 บาท ต่อรอบปีกรมธรรม์',
  RULE_MHP_MHPD2J_MESSAGE: 'ความรับผิดส่วนแรก 30,000 บาท ต่อรอบปีกรมธรรม์',
  RULE_MHP_MHPD1S_MESSAGE: 'ความรับผิดส่วนแรก 30,000 บาท ต่อรอบปีกรมธรรม์',
  RULE_MHP_MHPD2S_MESSAGE: 'ความรับผิดส่วนแรก 30,000 บาท ต่อรอบปีกรมธรรม์',
  RULE_MHP_MHPD3S_MESSAGE: 'ความรับผิดส่วนแรก 50,000 บาท ต่อรอบปีกรมธรรม์',
  RULE_MHP_MHPD4S_MESSAGE: 'ความรับผิดส่วนแรก 50,000 บาท ต่อรอบปีกรมธรรม์',
  RULE_MHP_MHPD5S_MESSAGE: 'ความรับผิดส่วนแรก 100,000 บาท ต่อรอบปีกรมธรรม์',
  RULE_MHP_MHPD6S_MESSAGE: 'ความรับผิดส่วนแรก 100,000 บาท ต่อรอบปีกรมธรรม์',
}
