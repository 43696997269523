//@flow
import { compose, lifecycle } from 'recompose'
import { ModalBody, ModalHeader, Button } from 'reactstrap'
import { connect } from 'react-redux'

import { Modal } from 'common-components/index'
import type { AppState } from 'quick-quote/reducers'
import { setAppValue } from '../../../apps/actions'
import { getCurrentApp } from 'e-submission/apps/selectors'
import { Link } from 'react-router-dom'
import _ from 'lodash'

class EkycPreviewInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSkipEkyc: false,
    }
  }

  render() {
    const {
      isOpen,
      firstName,
      lastName,
      citizenId,
      confirmCreateEkyc,
      clearEkycResults,
      onExit,
      handlerCreateCaseEkyc,
      insuredOrPayer,
    } = this.props
    return (
      <Modal
        className={'insured-and-payer-details-confirmations-modal'}
        isOpen={isOpen}
        size="md"
        modalClassName="default-bootstrap remote-selling-modal"
      >
        <ModalHeader className={'header-modal'}>
          กรุณายืนยันเพื่อเข้าสู่ขั้นตอนการยืนยันตัวตน
          <Button
            className="close"
            onClick={onExit}
            type="button"
            style={{ fontSize: '1.5rem', position: 'absolute', right: '15px', top: '5px' }}
          >
            &times;
          </Button>
        </ModalHeader>
        <ModalBody>
          <div className={'flex-container'} style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ textAlign: 'right', paddingRight: '5px' }}>
              ชื่อ:
              <br />
              นามสกุล:
              <br />
              เลขประจำตัวประชาชน:
            </div>
            <div style={{ textAlign: 'left', paddingLeft: '5px' }}>
              {firstName}
              <br />
              {lastName}
              <br />
              {citizenId}
            </div>
          </div>
          <div className={'normal-text detail-text modal-title'} style={{ color: '#2425aa' }}>
            โปรดตรวจสอบความถูกต้องอีกครั้งก่อนกดยืนยัน
          </div>
          <div className={'normal-text'}>
            <Link to={insuredOrPayer === 'insured' ? 'insured' : 'owner'}>
              <Button
                className="back-button"
                onClick={() => {
                  clearEkycResults()
                }}
              >
                แก้ไข
              </Button>
            </Link>
            <Button
              color={'primary'}
              onClick={() => {
                confirmCreateEkyc()
                onExit()
                handlerCreateCaseEkyc()
              }}
              className="primary"
            >
              ยืนยัน
            </Button>
          </div>
        </ModalBody>
      </Modal>
    )
  }
}

const mapStateToEkycPreviewInfoModal = (state: AppState, props) => {
  const app = getCurrentApp(state)
  const firstName = _.get(app, `${props.insuredOrPayer}.firstName`, 'xxx')
  const lastName = _.get(app, `${props.insuredOrPayer}.lastName`, 'xxx')
  const citizenId = _.get(app, `${props.insuredOrPayer}.idNo.citizenId`, 'xxx')
  return {
    firstName,
    lastName,
    citizenId,
  }
}

const mapDispatchToEkycPreviewInfoModal = (dispatch: Dispatch<*>, props) => ({
  confirmCreateEkyc: () => {
    dispatch(setAppValue(`ekycInfo.${props.insuredOrPayer}`, { isDataConfirmed: true }))
  },
  clearEkycResults: () => {
    dispatch(setAppValue(`ekycInfo.${props.insuredOrPayer}`, { isDataConfirmed: false }))
  },
})

export default compose(
  connect(mapStateToEkycPreviewInfoModal, mapDispatchToEkycPreviewInfoModal),
  lifecycle({
    componentDidMount() {},
    componentDidUpdate(prevProps) {},
    UNSAFE_componentWillReceiveProps(nextProps) {},
  })
)(EkycPreviewInfo)
