// @flow

import { Section, CustomSymbolList, Title } from 'core/service/pdf-generation/products/common/components/general'
import BIMESSAGES from 'core/data-model/constants/bi-messages'

const iWealthyContents = () => [
  Title(BIMESSAGES.RIGHTS_INSURED_TITLE),
  {
    ul: [
      'สิทธิของผู้เอาประกันภัยในการยกเลิกกรมธรรม์ภายใน 15 วันนับตั้งแต่วันที่ได้รับกรมธรรม์ประกันภัย โดยหักค่าธรรมเนียม 500 บาท และค่าตรวจสุขภาพตามที่จ่ายจริง (ถ้ามี) และเงินคืนในส่วนที่นำไปลงทุนจะเป็นไปตามมูลค่ารับซื้อคืนหน่วยลงทุนภายใต้เงื่อนไขของบริษัท',
      'สิทธิการมีผลบังคับอย่างต่อเนื่องในส่วนของความคุ้มครองชีวิตสำหรับเบี้ยประกันภัยหลักเพื่อความคุ้มครองรายงวด (Non-Lapse Guaranteed)',
      {
        type: 'circle',
        ul: [
          'ภายในระยะเวลา 2 ปีนับจากวันที่กรมธรรม์เริ่มมีผลคุ้มครอง ความคุ้มครองชีวิตตามผลประโยชน์กรณีเสียชีวิตภายใต้กรมธรรม์จะมีผลบังคับอย่างต่อเนื่อง ถึงแม้มูลค่ารับซื้อคืนหน่วยลงทุน จะมีไม่เพียงพอต่อการชำระค่าการประกันภัยในส่วนของความคุ้มครองชีวิตภายใต้กรมธรรม์ ค่าธรรมเนียมการบริหารกรมธรรม์ ภายใต้เงื่อนไขเมื่อผู้เอาประกันภัย',
          CustomSymbolList([
            'ได้ชำระเบี้ยประกันภัยหลักเพื่อความคุ้มครองรายงวดครบตามกำหนดชำระเบี้ยประกันภัยทุกงวดภายในระยะเวลาผ่อนผันชำระเบี้ยประกันภัย และ',
            'ไม่เคยมีการถอนเงินจากการขายคืนหน่วยลงทุนของเบี้ยประกันภัยหลักเพื่อความคุ้มครองรายงวด และ',
            'ไม่เคยทำการลดจำนวนเบี้ยประกันภัยหลักเพื่อความคุ้มครองรายงวด และ',
            'ได้ชำระเบี้ยประกันภัยเพิ่มเติมตามที่บริษัทร้องขอ ในกรณีที่ได้มีการเปลี่ยนแปลงค่าการประกันภัยในส่วนของความคุ้มครองชีวิตภายใต้กรมธรรม์สัญญาหลัก',
          ]),
          'หากผู้เอาประกันภัยมิได้ชำระเบี้ยประกันภัยหลักเพื่อความคุ้มครองรายงวดในระยะเวลาผ่อนผัน 31 วัน ความคุ้มครองใด ๆ ภายใต้สัญญาประกันภัยจะขาดอายุและสิ้นผลบังคับ',
          'หากผู้เอาประกันภัยมิได้ปฏิบัติตามเงื่อนไขข้างต้น สิทธิของการมีผลบังคับอย่างต่อเนื่องของกรมธรรม์นี้จะสิ้นสุดลง และไม่สามารถใช้สิทธิดังกล่าวได้อีก',
        ],
      },
      'สิทธิในการรับทราบการจัดสรรเบี้ยประกันภัยที่ลงทุนในกองทุนรวม',
      'สิทธิในการขอรับหนังสือชี้ชวนส่วนข้อมูลรายละเอียดโครงการจากตัวแทนประกันชีวิต หรือ นายหน้าประกันชีวิตที่ได้รับอนุญาตจากกรุงไทย-แอกซ่า',
      'สิทธิในการรับทราบข้อมูลเกี่ยวกับ ชื่อ ที่อยู่ ของบริษัทจัดการ กรุงไทย-แอกซ่า รวมทั้งชื่อ และที่อยู่ และเลขประจำตัวของตัวแทนที่ได้รับอนุญาต หรือพนักงานนายหน้าที่ได้รับอนุญาตจากกรุงไทย-แอกซ่า',
      'สิทธิในการได้ทราบข้อมูลเกี่ยวกับความเสี่ยงที่เกี่ยวข้องกับหน่วยลงทุนที่ได้รับคำแนะนำเพื่อซื้อหน่วยลงทุนนั้น ตลอดจนรับทราบคำเตือนและคำอธิบายเกี่ยวกับความเสี่ยงของการลงทุนในหน่วยลงทุน',
      'สิทธิในการได้รับทราบข้อเท็จจริงที่มีผลกระทบต่อสิทธิประโยชน์ของผู้เอาประกันภัย หรือต่อการตัดสินใจในการลงทุน เช่น การขายหน่วยลงทุนของกองทุนที่อยู่ในขั้นตอนการดำเนินการเพื่อควบกองทุน หรือการรวมกองทุน เป็นต้น',
      'สิทธิในการได้ทราบข้อมูลเกี่ยวกับความขัดแย้งทางผลประโยชน์ เช่น ค่าธรรมเนียม หรือผลตอบแทนที่ตัวแทนที่ได้รับอนุญาตจาก กรุงไทย-แอกซ่าอาจได้รับจากการซื้อกรมธรรม์ รวมทั้งการซื้อหรือขายคืน หน่วยลงทุน เป็นต้น',
      'สิทธิของผู้ถือหน่วยลงทุนภายใต้กรมธรรม์ประกันชีวิตควบการลงทุน โดยไม่เปิดเผยชื่อที่แท้จริงของผู้ถือหน่วยลงทุน (omnibus account) อาจแตกต่างจากผู้ถือหน่วยลงทุนทั่วไป เช่น',
      {
        type: 'circle',
        ul: [
          'ในการสั่งซื้อกรมธรรม์ควบหน่วยลงทุน จะไม่ได้ราคา ณ วันทำการนั้น ๆ เนื่องจากกรมธรรม์เป็นส่วนผสมของการให้ความคุ้มครอง และการลงทุน ซึ่งในส่วนของการให้ความคุ้มครองนั้น บริษัทจะมีระยะเวลาในการดำเนินการพิจารณาให้ความคุ้มครองตามกฎเกณฑ์ของบริษัท',
          'เอกสารต่าง ๆ ที่เกี่ยวกับกองทุน จะถูกจัดส่งจากบริษัทฯ ภายหลังจากที่บริษัทฯ ได้รับข้อมูลจากบริษัทจัดการกองทุน',
          'ผู้เอาประกันภัยอาจไม่ได้รับสิทธิในการขายคืนหน่วยลงทุนแบบสม่ำเสมอตามคำสั่งล่วงหน้า (Regular Redemption Service) จากบริษัทหลักทรัพย์จัดการกองทุน เหมือนกับผู้ถือหน่วยลงทุนโดยตรงจาก บริษัทจัดการ',
        ],
      },
    ],
  },
]

const iInvestContent = () => [
  Title(BIMESSAGES.RIGHTS_INSURED_TITLE),
  {
    ul: [
      'สิทธิของผู้เอาประกันภัยในการยกเลิกกรมธรรม์ภายใน 15 วันนับตั้งแต่วันที่ได้รับกรมธรรม์ประกันภัย โดยหักค่าธรรมเนียม 500 บาท และค่าตรวจสุขภาพตามที่จ่ายจริง (ถ้ามี) และเงินคืนในส่วนที่นำไปลงทุนจะเป็นไปตามมูลค่ารับซื้อคืนหน่วยลงทุนภายใต้เงื่อนไขของบริษัท',
      'สิทธิในการรับทราบการจัดสรรเบี้ยประกันภัยที่ลงทุนในกองทุนรวม',
      'สิทธิในการขอรับหนังสือชี้ชวนส่วนข้อมูลรายละเอียดโครงการจากตัวแทนประกันชีวิต หรือ นายหน้าประกันชีวิตที่ได้รับอนุญาตจากกรุงไทย-แอกซ่า',
      'สิทธิในการรับทราบข้อมูลเกี่ยวกับ ชื่อ ที่อยู่ ของบริษัทจัดการ กรุงไทย-แอกซ่า รวมทั้งชื่อ และที่อยู่ และเลขประจำตัวของตัวแทนที่ได้รับอนุญาต หรือพนักงานนายหน้าที่ได้รับอนุญาตจากกรุงไทย-แอกซ่า',
      'สิทธิในการได้ทราบข้อมูลเกี่ยวกับความเสี่ยงที่เกี่ยวข้องกับหน่วยลงทุนที่ได้รับคำแนะนำเพื่อซื้อหน่วยลงทุนนั้น ตลอดจนรับทราบคำเตือนและคำอธิบายเกี่ยวกับความเสี่ยงของการลงทุนในหน่วยลงทุน',
      'สิทธิในการได้รับทราบข้อเท็จจริงที่มีผลกระทบต่อสิทธิประโยชน์ของผู้เอาประกันภัย หรือต่อการตัดสินใจในการลงทุน เช่น การขายหน่วยลงทุนของกองทุนที่อยู่ในขั้นตอนการดำเนินการเพื่อควบกองทุนหรือการรวมกองทุน เป็นต้น',
      'สิทธิในการได้ทราบข้อมูลเกี่ยวกับความขัดแย้งทางผลประโยชน์ เช่น ค่าธรรมเนียม หรือผลตอบแทนที่ตัวแทนที่ได้รับอนุญาตจาก กรุงไทย-แอกซ่าอาจได้รับจากการซื้อกรมธรรม์ รวมทั้งการซื้อหรือขายคืนหน่วยลงทุน เป็นต้น',
      'สิทธิของผู้ถือหน่วยลงทุนภายใต้กรมธรรม์ประกันชีวิตควบการลงทุน โดยไม่เปิดเผยชื่อที่แท้จริงของผู้ถือหน่วยลงทุน (omnibus account) อาจแตกต่างจากผู้ถือหน่วยลงทุนทั่วไป เช่น',
      {
        type: 'circle',
        ul: [
          'ในการสั่งซื้อกรมธรรม์ควบหน่วยลงทุน จะไม่ได้ราคา ณ วันทำการนั้น ๆ เนื่องจากกรมธรรม์เป็นส่วนผสมของการให้ความคุ้มครอง และการลงทุน ซึ่งในส่วนของการให้ความคุ้มครองนั้น บริษัทจะมีระยะเวลาในการดำเนินการพิจารณาให้ความคุ้มครองตามกฎเกณฑ์ของบริษัท',
          'เอกสารต่าง ๆ ที่เกี่ยวกับกองทุน จะถูกจัดส่งจากบริษัทฯ ภายหลังจากที่บริษัทฯ ได้รับข้อมูลจากบริษัทจัดการกองทุน',
          'ผู้เอาประกันภัยอาจไม่ได้รับสิทธิในการขายคืนหน่วยลงทุนแบบสม่ำเสมอตามคำสั่งล่วงหน้า (Regular Redemption Service) จากบริษัทหลักทรัพย์จัดการกองทุน เหมือนกับผู้ถือหน่วยลงทุนโดยตรงจากบริษัทจัดการ',
        ],
      },
    ],
  },
]

const rpudrContents = () => [
  Title(BIMESSAGES.RIGHTS_INSURED_TITLE),
  {
    ol: [
      {
        text: [
          'สิทธิของผู้เอาประกันภัยในการยกเลิกกรมธรรม์ภายใน 15 วันนับตั้งแต่วันที่ได้รับกรมธรรม์ โดยมีค่าธรรมเนียมของบริษัทฉบับละ 500 บาท ค่าตรวจสุขภาพตามที่จ่ายจริง (ถ้ามี) ค่าธรรมเนียมกรมธรรม์ที่เคยถูกเรียกเก็บ และเงินส่วนที่นำไปลงทุนตามมูลค่ารับซื้อคืนหน่วยลงทุน\n',
          'ในกรณีที่ผู้เอาประกันภัยได้ใช้สิทธิเรียกร้องค่าสินไหมทดแทน หรือชำระเบี้ยประกันภัยหลักเพื่อความคุ้มครองที่ครบกำหนดชำระในงวดถัดไป หรือชำระเบี้ยประกันภัยเพื่อการลงทุนเพิ่มเติมพิเศษหลังจากวันเริ่มมีผลคุ้มครอง หรือมีการแจ้งความประสงค์เพื่อขอถอนเงินจากกรมธรรม์ หรือมีการแจ้งความประสงค์เพื่อขอดำเนินการสับเปลี่ยนกองทุนแล้ว ผู้เอาประกันภัยไม่มีสิทธิยกเลิกกรมธรรม์ฉบับนี้',
        ],
      },
      [
        'สิทธิการมีผลบังคับอย่างต่อเนื่องในส่วนของความคุ้มครองชีวิตตามจำนวนเงินเอาประกันภัย และความคุ้มครองตามสัญญาเพิ่มเติมแบบชำระค่าการประกันภัยโดยการขายคืนหน่วยลงทุนทุกฉบับ (ถ้ามี)',
        {
          separator: ['2.'],
          ol: [
            [
              'ภายในระยะเวลา 5 ปี นับจากวันเริ่มมีผลคุ้มครอง ความคุ้มครองชีวิตตามจำนวนเงินเอาประกันภัย และความคุ้มครองตามสัญญาเพิ่มเติมแบบชำระค่าการประกันภัยโดยการขายคืนหน่วยลงทุนทุก',
              'ฉบับ (ถ้ามี) จะมีผลบังคับอย่างต่อเนื่องถึงแม้มูลค่ารับซื้อคืนหน่วยลงทุนจะมีไม่เพียงพอต่อการชำระค่าธรรมเนียมกรมธรรม์รายเดือนซึ่งถึงกำหนดชำระภายใต้เงื่อนไขเมื่อผู้เอาประกันภัย',
              '(1) ได้ชำระเบี้ยประกันภัยหลักเพื่อความคุ้มครองครบตามกำหนดชำระเบี้ยประกันภัยทุกงวดภายในระยะเวลาผ่อนผันชำระเบี้ยประกันภัย และ',
              '(2) ไม่เคยมีการถอนเงินจากการขายคืนหน่วยลงทุนของเบี้ยประกันภัยหลักเพื่อความคุ้มครอง และ',
              '(3) ไม่เคยทำการลดจำนวนเบี้ยประกันภัยหลักเพื่อความคุ้มครอง',
            ],
            'หากผู้เอาประกันภัยมิได้ปฏิบัติตามเงื่อนไขข้างต้น สิทธิของการมีผลบังคับอย่างต่อเนื่องของกรมธรรม์นี้จะสิ้นสุดลง และไม่สามารถใช้สิทธิดังกล่าวได้อีก',
            'ข้อกำหนดและเงื่อนไขอื่น ๆ ของสัญญาเพิ่มเติมแบบชําระค่าการประกันภัยโดยการขายคืนหน่วยลงทุนให้อ้างอิงตามข้อตกลงคุ้มครองของสัญญาเพิ่มเติมนั้น',
          ],
        },
      ],
      'สิทธิในการรับทราบการจัดสรรเบี้ยประกันภัยที่ลงทุนในกองทุน',
      'สิทธิในการขอรับหนังสือชี้ชวนส่วนข้อมูลรายละเอียดโครงการจากตัวแทนประกันชีวิต หรือ นายหน้าประกันชีวิตที่ได้รับอนุญาตจากกรุงไทย-แอกซ่า',
      'สิทธิในการได้รับทราบรายชื่อของบริษัทจัดการทุกแห่งที่รับจัดการกองทุนควบกรมธรรม์ที่เสนอขายโดยบริษัท',
      'สิทธิในการได้รับทราบข้อมูลเกี่ยวกับชื่อ ที่อยู่ ของบริษัทและบริษัทจัดการ รวมทั้งชื่อ ที่อยู่ และเลขประจำตัวของตัวแทนที่ได้รับอนุญาต หรือพนักงานนายหน้าที่ได้รับอนุญาตจากกรุงไทย-แอกซ่า',
      'สิทธิในการได้ทราบข้อมูลเกี่ยวกับความเสี่ยงที่เกี่ยวข้องกับหน่วยลงทุนที่ได้รับคำแนะนำเพื่อซื้อหน่วยลงทุนนั้น ตลอดจนรับทราบคำเตือนและคำอธิบายเกี่ยวกับความเสี่ยงของการลงทุนในหน่วยลงทุน',
      'สิทธิในการได้รับทราบข้อเท็จจริงที่มีผลกระทบต่อสิทธิประโยชน์ของผู้เอาประกันภัย หรือต่อการตัดสินใจในการลงทุน เช่น การขายหน่วยลงทุนของกองทุนที่อยู่ในขั้นตอนการดำเนินการเพื่อควบกองทุนหรือการรวมกองทุน เป็นต้น',
      'สิทธิในการได้ทราบข้อมูลเกี่ยวกับความขัดแย้งทางผลประโยชน์ เช่น ค่าธรรมเนียม หรือผลตอบแทนที่ตัวแทนประกันชีวิต หรือ นายหน้าประกันชีวิตที่ได้รับอนุญาตจากกรุงไทย-แอกซ่าอาจได้รับจากการซื้อกรมธรรม์ รวมทั้งการซื้อหรือขายคืนหน่วยลงทุน เป็นต้น',
      'สิทธิในการแสดงเจตนาว่าไม่ต้องการรับการติดต่ออีกเป็นระยะเวลา 2 ปี',
      'สิทธิของผู้ถือหน่วยลงทุนภายใต้กรมธรรม์ โดยไม่เปิดเผยชื่อที่แท้จริงของผู้ถือหน่วยลงทุน (Omnibus Account) อาจมีสิทธิแตกต่างจากผู้ถือหน่วยลงทุนปกติที่ซื้อหน่วยลงทุนผ่านบริษัทจัดการโดยตรง เช่น',
      {
        separator: ['11.'],
        ol: [
          'ในการซื้อกรมธรรม์ประกันชีวิตควบการลงทุน จะไม่ได้ราคา ณ วันทำการนั้น ๆ เนื่องจากการดำเนินงานประกันชีวิตต้องใช้เวลาในการพิจารณาคำขอเอาประกันชีวิตของลูกค้า ทั้งนี้บริษัทจะทำการซื้อหน่วยลงทุนตามวันที่เป็นไปตามหลักเกณฑ์ที่บริษัทกำหนด',
          'การรับเอกสารต่างๆ เกี่ยวกับกองทุน อาจช้ากว่าการซื้อหน่วยลงทุนจากบริษัทจัดการโดยตรง เนื่องจากบริษัทจะดำเนินการจัดส่งให้แก่ลูกค้าเมื่อได้รับเอกสารดังกล่าวจากบริษัทจัดการ',
          'ผู้เอาประกันภัยอาจไม่ได้รับสิทธิในการขายคืนหน่วยลงทุนแบบสม่ำเสมอตามคำสั่งล่วงหน้า (Regular Redemption Service) จากบริษัทจัดการเหมือนกับผู้ถือหน่วยลงทุนโดยตรงจากบริษัทจัดการ',
          'บริษัทจะหักค่าการประกันภัย และค่าธรรมเนียมการบริหารกรมธรรม์ทุกเดือนจากการขายคืนหน่วยลงทุนอัตโนมัติ ตามที่ระบุไว้ในตารางค่าธรรมเนียมกรมธรรม์',
        ],
      },
    ],
  },
]

export const RightsInsured = (productCode: string) => {
  switch (productCode) {
    case BIMESSAGES.BI_TYPE_PRODUCT_CODE_IINVEST:
      return Section(BIMESSAGES.RIGHTS_INSURED_HEADER, iInvestContent())
    case BIMESSAGES.BI_TYPE_PRODUCT_CODE_IWEALTHY:
      return Section(BIMESSAGES.RIGHTS_INSURED_HEADER, iWealthyContents())
    case BIMESSAGES.BI_TYPE_PRODUCT_CODE_RPUDR:
      return Section(BIMESSAGES.RIGHTS_INSURED_HEADER, rpudrContents())
    default:
      return []
  }
}
