//@flow
import type { ToggleRppFundAllocation } from 'quick-quote/product-investment/fund-allocation/rpp/actions'
import type { ToggleLstuFundAllocation } from 'quick-quote/product-investment/fund-allocation/lstu/actions'

import _ from 'lodash'
import { call, select } from 'redux-saga/effects'

import { getAvailableFunds } from 'quick-quote/product-investment/fund-allocation/selectors'
import { getToggles } from 'quick-quote/feature-toggles'
import values from 'core/data-model/constants/values'

export function getRemainingFundAllocated(fundAllocations = {}) {
  const codes = Object.keys(fundAllocations)
  if (codes.length < 1) return 100

  let allocated = 0
  codes.forEach((code) => (allocated += fundAllocations[code].fundAllocation))
  const remaining = Math.max(100 - allocated, 0)

  return remaining
}

export function onToggleFundAllocation(
  getFundAllocation: Function,
  processAndUpdateFundAllocation: Function
): Function {
  return function*(action: ToggleRppFundAllocation | ToggleLstuFundAllocation): Generator<*, *, *> {
    const funds = yield select(getAvailableFunds)
    const fundByCode = _.keyBy(funds, 'code')
    const fundAllocations = _.keyBy(yield select(getFundAllocation), 'code')
    const fundCode = action.payload.code
    const fund = fundAllocations[fundCode] || { selected: false, fundAllocation: 0, code: fundCode }
    const allocated = !getToggles().ENABLE_AUTO_CALCULATION_FUND_ALLOCATION
      ? values.FUND_DEFAULT_ALLOCATED
      : getRemainingFundAllocated(fundAllocations)

    fundAllocations[fundCode] = {
      ...fund,
      selected: !fund.selected,
      fundAllocation: allocated,
      ...fundByCode[fundCode],
    }

    yield call(processAndUpdateFundAllocation, _.values(fundAllocations))
  }
}
