import values from 'core/data-model/constants/values'
import styled from 'styled-components'

export const Heading = styled.div`
  color: ${values.PRIMARY_COLOR};
  font-size: 1.333rem;
`

export const Emphasized = styled.span`
  color: ${values.PRIMARY_COLOR};
`
export const SpanTextSize = styled.span`
  font-weight: bold;
`
