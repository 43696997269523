import VALUES from 'core/data-model/constants/values'
import RiskProfile from 'quick-quote/product-investment/components/risk-profile'
import { FundCategories } from 'quick-quote/product-investment/fund-allocation/components/fund-categories'
import LstuOptions from 'quick-quote/product-investment/fund-allocation/lstu/components/lstu-options'
import styled from 'styled-components'

const RiskProfileWrapper = styled.div`
  max-width: 680px;
  background: white;
  margin: 0 auto 20px auto;
`
const AllocationPart = ({
  funds,
  allocations,
  totalAllocation,
  toggleFundAllocation,
  calculateTotalFundAllocation,
  resetFundAllocations,
  allowHigherRiskInvestment,
  allowForeignInvestment,
  isLowRiskEvaluated,
  hasValidEntryComponent,
  onRetakeRPQ,
  onAllocateSameAsRPP,
  onAllocateIndependently,
  chosenOption,
}) => {
  return (
    <div>
      <LstuOptions
        onAllocateSameAsRPP={onAllocateSameAsRPP}
        onAllocateIndependently={onAllocateIndependently}
        chosenOption={chosenOption}
      />
      {chosenOption === VALUES.LSTU_FUND_ALLOCATION_OPTION.INDEPENDENT && (
        <RiskProfileWrapper>
          <RiskProfile onRetakeRPQConfirmed={onRetakeRPQ} />
        </RiskProfileWrapper>
      )}
      {chosenOption === VALUES.LSTU_FUND_ALLOCATION_OPTION.INDEPENDENT && (
        <FundCategories
          allocations={allocations}
          funds={funds}
          totalAllocation={totalAllocation}
          resetFundAllocations={resetFundAllocations}
          toggleFundAllocation={toggleFundAllocation}
          calculateTotalFundAllocation={calculateTotalFundAllocation}
          allowHigherRiskInvestment={allowHigherRiskInvestment}
          allowForeignInvestment={allowForeignInvestment}
          isLowRiskEvaluated={isLowRiskEvaluated}
          hasValidEntryComponent={hasValidEntryComponent}
          elementIdToSnapAllocationBar={'lstu-fund-allocation'}
        />
      )}
    </div>
  )
}
export default AllocationPart
