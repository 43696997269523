//@flow
import _ from 'lodash'
import { formatNumber } from 'core/service/lib/number-format'
import MESSAGES from 'core/data-model/constants/bi-messages'
import CONSTANTS from 'core/data-model/constants/messages'
import type { PolicyValueTableProps } from 'core/service/benefit-illustration/policy-value'
import { _getMessageUnderiShieldPolicyValueTable } from 'core/service/benefit-illustration/policy-value'
import type { PolicyValue, YearEndPolicyValue } from 'core/service/benefit-illustration'
import type { DisplayProduct } from 'core/service/display-product'

type FlexiHealthPolicyValueTableProps = {
  policyValue: PolicyValue,
  basicPlan: DisplayProduct,
  title: string,
}
// this is a hack for line wrapping in pdf which is not
// support Thai language yet. Once it is implemented
// this should be removed
export const _breakLine = (text: string) => {
  const premiumPattern = /^(เบี้ยประกันภัย)(สัญญา.+)$/
  const surrenderCashPattern = /^(มูลค่าเวนคืนกรมธรรม์)(ของสัญญาหลัก)$/
  const savingWithCompany = /^(กรณีรับเป็น)(เงินสด)$/

  const patterns = [premiumPattern, surrenderCashPattern, savingWithCompany]

  // add space to trick pdfmake to do line wrapping for us
  const breakByFirstMatched = ([p, ...rest]) => (text.match(p) ? text.replace(p, '$1 $2') : breakByFirstMatched(rest))

  return breakByFirstMatched(patterns)
}

const buildFlexiHealthHeaderTable = () => {
  return [
    [
      { text: CONSTANTS.AGE, rowSpan: 3, style: 'tableHeader', margin: [0, 15] },
      { text: MESSAGES.END_OF_THE_YEAR_AT, rowSpan: 3, style: 'tableHeader', margin: [0, 15] },
      { text: _breakLine(MESSAGES.PREMIUM_PER_YEAR), style: 'tableHeader' },
      { text: _breakLine(MESSAGES.END_OF_YEAR_POLICY), colSpan: 3, style: 'tableHeader' },
      { text: '' },
      { text: '' },
    ],
    [
      { text: '' },
      { text: '' },
      { text: _breakLine(MESSAGES.BASIC_PLAN), rowSpan: 2, style: 'tableHeader' },
      { text: _breakLine(MESSAGES.FLEXI_HEALTH_CUMULATIVE_PREMIUM), rowSpan: 2, colSpan: 1, style: 'tableHeader' },
      {
        text: _breakLine(MESSAGES.POLICY_VALUE_TABLE_CASH_SURRENDER_VALUE),
        rowSpan: 2,
        colSpan: 1,
        style: 'tableHeader',
      },
      { text: _breakLine(MESSAGES.FLEXI_HEALTH_BASIC_DEATH_BENEFIT), rowSpan: 2, colSpan: 1, style: 'tableHeader' },
    ],
    [{ text: '' }, { text: '' }, { text: '' }, { text: '' }, { text: '' }, { text: '' }],
  ]
}

const rowHeightConfig = { lineHeight: 0.75, margin: [0, -3, 0, 0] }

const buildPolicyValueRow = (yearEndPolicy: YearEndPolicyValue) => [
  {
    text: yearEndPolicy.age.value,
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.yearEndPolicy,
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.annualBasicPremium === 0 ? '-' : formatNumber(yearEndPolicy.annualBasicPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.cumulativeBasicPremium === 0 ? '-' : formatNumber(yearEndPolicy.cumulativeBasicPremium, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.surrenderCash === 0 ? '-' : formatNumber(yearEndPolicy.surrenderCash),
    alignment: 'right',
    ...rowHeightConfig,
  },
  {
    text: yearEndPolicy.deathBenefit === 0 ? '-' : formatNumber(yearEndPolicy.deathBenefit, 2),
    alignment: 'right',
    ...rowHeightConfig,
  },
]

const buildTotalPolicyValueRow = (policyValue: PolicyValue) => [
  { text: _breakLine(MESSAGES.TOTAL), colSpan: 2, style: 'tableHeader' },
  '',
  {
    text: policyValue.subtotalAnnualBasicPremium === 0 ? '-' : formatNumber(policyValue.subtotalAnnualBasicPremium, 2),
    alignment: 'right',
    bold: true,
    margin: [0, 0],
  },
  {
    text: policyValue.grandTotalPremium === 0 ? '-' : formatNumber(policyValue.grandTotalPremium, 2),
    alignment: 'right',
    bold: true,
    margin: [0, 0],
  },
  '',
  '',
]

const disclaimer = {
  stack: [
    `${MESSAGES.NOTE}:`,
    MESSAGES.FLEXI_HEALTH_POLICY_VALUE_TABLE_NOTE_1,
    MESSAGES.FLEXI_HEALTH_POLICY_VALUE_TABLE_NOTE_2,
  ],
  style: 'disclaimer',
  pageBreak: '',
}

export const iShieldShowMessageUnderPdfTable = ({ policyValue, basicPlan }: PolicyValueTableProps) => {
  const message = _getMessageUnderiShieldPolicyValueTable({ policyValue, basicPlan })
  if (message) {
    return { stack: [{ text: message, color: 'red', fontSize: 11, alignment: 'center' }] }
  } else return { text: '' }
}

const calPageAmount = (rowsAmount, rowsPerPage) => {
  if (rowsAmount === rowsPerPage[0]) {
    return 2
  } else if (rowsPerPage[0] - rowsAmount === 1) {
    return 2
  } else {
    return Math.ceil(rowsAmount / rowsPerPage[0])
  }
}

const buildFlexiHealthPolicyValueTable = (policyValue: PolicyValue) => {
  const policyValueTables = []
  const rowsPerPage = [37, 37, 37]
  const pageAmount = calPageAmount(policyValue.yearEndPolicyValues.length, rowsPerPage)
  _.times(pageAmount, (currentPage) => {
    policyValueTables.push({
      table: {
        headerRows: 1,
        widths: ['auto', 'auto', 100, 100, 100, '*'],
        body: [
          ...buildFlexiHealthHeaderTable(),
          ...policyValue.yearEndPolicyValues
            .slice(
              currentPage * rowsPerPage[currentPage > 0 ? currentPage - 1 : 0],
              rowsPerPage[currentPage] * (currentPage + 1)
            )
            .map((p) => buildPolicyValueRow(p)),
        ],
      },
      layout: 'lightBorder',
      style: 'table',
    })
  })

  _.last(policyValueTables).table.body.push(buildTotalPolicyValueRow(policyValue))
  setLayoutDisclaimer(policyValueTables, rowsPerPage, disclaimer)
  return policyValueTables
}

const setLayoutDisclaimer = (policyValueTables, rowsPerPage, disclaimer) => {
  const headerRow = 3
  const rowDisclaimer = 3
  const amountPage = policyValueTables.length ? policyValueTables.length : 1
  const maxofPage = rowsPerPage[amountPage - 1] + headerRow
  const rowsofPage = policyValueTables[amountPage - 1].table.body.length
  if (rowsofPage >= maxofPage - rowDisclaimer) {
    disclaimer.pageBreak = 'before'
  } else {
    disclaimer.pageBreak = ''
  }
}

export const buildPolicyValueTable = (policyValue: PolicyValue, basicPlan: DisplayProduct) =>
  buildFlexiHealthPolicyValueTable(policyValue)

export const policyValueTableSection = ({ policyValue, basicPlan, title }: FlexiHealthPolicyValueTableProps) => [
  { text: title, style: 'title', pageBreak: 'before' },
  ...buildPolicyValueTable(policyValue, basicPlan),
  iShieldShowMessageUnderPdfTable({ policyValue, basicPlan }),
  disclaimer,
]
