//@flow
import _ from 'lodash'
import { connect } from 'react-redux'
import { Route } from 'react-router-dom'
import { PATH } from '../../constants/path'

export const isEsubmissionPath = (urlPath: string): boolean => {
  return urlPath.startsWith(PATH.ESUBMISSION)
}

export const isFNAPath = (urlPath: string): boolean => {
  return urlPath.includes(PATH.FNA)
}

export const isQuickQuotePath = (urlPath: string): boolean => {
  return urlPath.includes(PATH.QUICK_QUOTE)
}

export const isPortalPath = (urlPath: string): boolean => {
  return urlPath.endsWith('portal')
}

export const isProductSelectionPath = (urlPath: string): boolean => {
  return urlPath.endsWith('product-selection')
}

export const CanEnter = connect((state, props) => ({
  validated: _.overEvery(props.validators)(state),
}))(({ component: WrappedComponent, validated, redirect, ...props }) => {
  if (validated) {
    return <WrappedComponent {...props} />
  }
  return redirect
})
export type ConditionalRouteProps = {
  path: string,
  component: Object,
  conditions: Function | Function[],
  redirect: Object,
}
export const ConditionalRoute = ({ path, component, conditions, redirect }) => (
  <Route
    path={path}
    render={(props) => <CanEnter component={component} validators={conditions} redirect={redirect} {...props} />}
  />
)
