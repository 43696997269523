//@flow
import type { FundAllocationToggleItem, RppAcceptFundAllocation, TotalFundAllocation } from 'core/data-model/investment'
import type { Action } from 'quick-quote/product-investment/fund-allocation/rpp/actions'
import {
  RESET_RPP_FUND_ALLOCATION,
  RPP_ACCEPT_FOREIGN_INVESTMENT,
  RPP_ACCEPT_HIGHER_RISK_INVESTMENT,
  UPDATE_RPP_ACCEPT_FUND_ALLOCATION,
  UPDATE_RPP_FUND_ALLOCATIONS,
  UPDATE_RPP_TOTAL_FUND_ALLOCATION,
  ANSWER_QUESTION_TO_ACCEPT_RPP_FOREIGN_INVESTMENT,
  RESET_RPP_ACCEPT_FUND_ALLOCATION,
} from 'quick-quote/product-investment/fund-allocation/rpp/actions'
import { RESET_RISK_EVALUATED } from 'quick-quote/product-investment/risk-questionnaire/actions'
import type { SelectProduct } from 'quick-quote/product-selection/actions'
import { SELECT_PRODUCT } from 'quick-quote/product-selection/actions'
import { combineReducers } from 'redux'

export type FundAllocationState = {
  allocations: FundAllocationToggleItem[],
  totalAllocation: TotalFundAllocation,
  acceptFundAllocation: boolean,
  allowHigherRiskInvestment: boolean,
  allowForeignInvestment: boolean,
}

export const allocations = (
  state: FundAllocationToggleItem[] = [],
  action: Action | SelectProduct
): FundAllocationToggleItem[] => {
  switch (action.type) {
    case UPDATE_RPP_FUND_ALLOCATIONS:
      return action.payload
    case RESET_RISK_EVALUATED:
    case SELECT_PRODUCT:
    case RESET_RPP_FUND_ALLOCATION:
      return []
    default:
      return state
  }
}
export const totalAllocation = (
  state: TotalFundAllocation = { value: 0 },
  action: Action | SelectProduct
): TotalFundAllocation => {
  switch (action.type) {
    case UPDATE_RPP_TOTAL_FUND_ALLOCATION:
      return action.payload
    case RESET_RISK_EVALUATED:
    case SELECT_PRODUCT:
    case RESET_RPP_FUND_ALLOCATION:
      return {}
    default:
      return state
  }
}

export const acceptFundAllocation = (
  state: RppAcceptFundAllocation = false,
  action: Action | SelectProduct
): RppAcceptFundAllocation => {
  switch (action.type) {
    case UPDATE_RPP_ACCEPT_FUND_ALLOCATION:
      return action.payload
    case SELECT_PRODUCT:
    case RESET_RISK_EVALUATED:
    case RESET_RPP_ACCEPT_FUND_ALLOCATION:
      return false
    default:
      return state
  }
}

export const allowHigherRiskInvestment = (state: boolean = false, action: Action): boolean => {
  switch (action.type) {
    case RPP_ACCEPT_HIGHER_RISK_INVESTMENT:
      return !state
    case SELECT_PRODUCT:
    case RESET_RISK_EVALUATED:
    case RESET_RPP_ACCEPT_FUND_ALLOCATION:
      return false
    default:
      return state
  }
}

export const allowForeignInvestment = (state: boolean = false, action: Action): boolean => {
  switch (action.type) {
    case RPP_ACCEPT_FOREIGN_INVESTMENT:
      return !state
    case SELECT_PRODUCT:
    case RESET_RISK_EVALUATED:
    case RESET_RPP_ACCEPT_FUND_ALLOCATION:
      return false
    case ANSWER_QUESTION_TO_ACCEPT_RPP_FOREIGN_INVESTMENT:
      return action.payload
    default:
      return state
  }
}

// $$FlowFixMe
export const reducer = combineReducers({
  allocations,
  totalAllocation,
  acceptFundAllocation,
  allowHigherRiskInvestment,
  allowForeignInvestment,
})
