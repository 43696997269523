//@flow
import { Heading } from 'common-components'
import type { RiskColor } from 'core/service/investment/risklevel'
import styled from 'styled-components'
import { getToggles } from 'quick-quote/feature-toggles'

export type ColorBlockProps = RiskColor & {
  className: Object,
}

export const ColorBlock = styled((props: ColorBlockProps) => (
  <div className={props.className} style={{ backgroundColor: props.color }}>
    {props.level}
  </div>
))`
  flex-grow: 1;
  color: white;
  font-size: 0.6rem;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 0.8rem;
    padding: 2px 0;
  }
`

export const ColorBar = styled.div`
  display: flex;
  flex-direction: row;
  width: 320px;
  @media (min-width: 768px) {
    width: 100%;
  }
`

export type RiskLevelTableProps = {
  colors: RiskColor[],
}

const RiskLevelTableHeading = styled.div`
  @media (min-width: 768px) {
    width: 290px;
  }
`

const RiskLevelsTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
    text-align: left;
  }
`

export const RiskLevelsTable = (props: RiskLevelTableProps) => (
  <RiskLevelsTableWrapper>
    <RiskLevelTableHeading>
      <Heading>{getToggles().ENABLE_RPQ_FORM_2022 ? 'ตารางระดับความเสี่ยงของกองทุน' : 'ตารางระดับความเสี่ยง'}</Heading>
    </RiskLevelTableHeading>
    <ColorBar>
      {props.colors
        .sort((a, b) => a.level - b.level)
        .map((color) => (
          <ColorBlock key={color.level} level={color.level} color={color.color} />
        ))}
    </ColorBar>
  </RiskLevelsTableWrapper>
)
