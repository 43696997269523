// @flow

import moment from 'moment'
import 'moment/locale/th'
import DateFormatter from '../../../lib/date-formatter'

export const dateFormat = 'DD/MM/YYYY'
export const dateFormatterPattern = ['d', 'm', 'Y']
export const DIFFERENCE_BUDDHIST_CHRISTIAN_YEAR = 543
export const MINIMUM_BUDDHIST_YEAR_IN_ADVISOR_ZONE = 2400

export const addDaysToDate = (date: Date, days: number): Date => {
  return moment(date)
    .add(days, 'days')
    .toDate()
}

export const addYearsToDate = (date: Date, years: number): Date => {
  return moment(date)
    .add(years, 'years')
    .toDate()
}

export const isPartiallyValidDate = (date: string): boolean => {
  const validFirstDateChar = '[0-3]'
  const validDateChar = '(0[1-9]|[12][0-9]|3[01])'
  const validDateCharWithSlash = validDateChar + '/'
  const validFirstMonthChar = validDateCharWithSlash + '[0-1]'

  switch (date.length) {
    case 0:
      return true
    case 1:
      return new RegExp(validFirstDateChar).test(date)
    case 2:
      return new RegExp(validDateChar).test(date)
    case 3:
      return new RegExp(validDateCharWithSlash).test(date)
    case 4:
      return new RegExp(validFirstMonthChar).test(date)
    case date.length > 10:
      return false
    default:
      return partialDateToMoment(date).isValid()
  }
}

export const isBuddhistYear = (birthdate: string): boolean => {
  if (!birthdate) {
    birthdate = moment().format(dateFormat)
  }
  const dateFormatter = new DateFormatter(dateFormatterPattern)
  const date = dateFormatter.getDayMonthYear(birthdate)

  return date.year >= MINIMUM_BUDDHIST_YEAR_IN_ADVISOR_ZONE
}

export const buddhistToChristianDate = (buddhistDate: string): string => {
  const buddhistYear = Number(buddhistDate.substring(6, buddhistDate.length))
  if (Number.isNaN(buddhistYear) || buddhistDate === '') {
    throw new Error('Invalid year')
  }
  const yearInChristianDate = buddhistYear - DIFFERENCE_BUDDHIST_CHRISTIAN_YEAR
  return buddhistDate.substring(0, 6) + String(yearInChristianDate)
}

export const dateToMoment = (dateString: string) => moment(dateString, dateFormat, true)

export const dateNowMoment = () => moment().format(dateFormat)

const partialDateToMoment = (dateString: string) => moment(dateString, dateFormat.substring(0, dateString.length), true)

export const christianToThaiWithoutFormat = (christianDate: ?Date) => {
  moment.locale('th')
  return moment(christianDate).add(DIFFERENCE_BUDDHIST_CHRISTIAN_YEAR, 'year')
}

export const christianToThaiDate = (christianDate: Date): string =>
  christianToThaiWithoutFormat(christianDate).format('LL')
