// @flow

export default {
  // basic plan category
  LIFE_SAVE_PRO: 'LIFE_SAVE_PRO',
  FLEXI_HEALTH: 'FLEXI_HEALTH',
  WHOLE_LIFE: 'WHOLE_LIFE',
  RETIREMENT: 'RETIREMENT',
  BUMNAN_READY: 'BUMNAN_READY',
  INVESTMENT: 'INVESTMENT',
  SAVING: 'SAVING',
  HEALTH: 'HEALTH',
  LOAN: 'LOAN',
  PROTECTION: 'PROTECTION',
  ICARE: 'ICARE',
  PERFECTLIFE: 'PERFECTLIFE',
  HEALTH_TOPPING: 'HEALTH_TOPPING',
  HEALTH_TOPPING_CODE: 'HEALTHTOPPING-WLNP60L',
  LIFE_READY: 'LIFE_READY',
  IHEALTHY_ULTRA: 'IHEALTHYULTRA',
  TERM_LIFE: 'TERM_LIFE',
  MRTA: 'MRTA',
  GLTSP: 'GLTSP',
  PA: 'PERSONAL_ACCIDENT',
  CASH_INCLUDE_LOAN: 'CASH_INCLUDE_LOAN',
  CASH_EXCLUDE_LOAN: 'CASH_EXCLUDE_LOAN',
  IHEALTHY_ULTRA_CODE: {
    LIFE_LEGACY_99: 'IHEALTHYULTRA-WLNP99L',
    LIFE_LEGACY_60: 'IHEALTHYULTRA-WLNP60L',
    IPROTECT_85: 'IHEALTHYULTRA-WLNP85',
    '12PL': 'IHEALTHYULTRA-12PL',
    IHEALTHY_85: 'IHEALTHYULTRA-PWLNP85',
    IHEALTHY_60: 'IHEALTHYULTRA-PWLNP60',
  },
  IHEALTHY_ULTRA_MULTI_CODE: {
    LIFE_PROTECT_18: {
      JUNIOR: 'IHEALTHYULTRA-WL88J',
      SENIOR: 'IHEALTHYULTRA-WL88S',
    },
    LIFE_ENSURE_10: {
      JUNIOR: 'IHEALTHYULTRA-W85P10J',
      SENIOR: 'IHEALTHYULTRA-W85P10S',
    },
    LIFE_PLUS_SAVER_20_10: {
      JUNIOR: 'IHEALTHYULTRA-20P10',
      SENIOR: 'IHEALTHYULTRA-20P10S',
    },
    LIFE_PLUS_SAVER_25_15: {
      JUNIOR: 'IHEALTHYULTRA-25P15',
      SENIOR: 'IHEALTHYULTRA-25P15S',
    },
  },
  IHEALTHY_ULTRA_LIFE_SAVE_PRO_CODE: [
    'IHEALTHYULTRA-20P10',
    'IHEALTHYULTRA-20P10S',
    'IHEALTHYULTRA-25P15',
    'IHEALTHYULTRA-25P15S',
  ],
  IHEALTHY_ULTRA_IHEALTHY_CODE: ['IHEALTHYULTRA-PWLNP85', 'IHEALTHYULTRA-PWLNP60'],
  IHEALTHY_ULTRA_GROUP_BENEFIT_SUMMARY_TABLE: [
    'IHEALTHYULTRA-20P10',
    'IHEALTHYULTRA-20P10S',
    'IHEALTHYULTRA-25P15',
    'IHEALTHYULTRA-25P15S',
    'IHEALTHYULTRA-12PL',
  ],
  IHEALTHY_ULTRA_GROUP_COVERAGE_SUMMARY_TABLE: [
    'IHEALTHYULTRA-WLNP85',
    'IHEALTHYULTRA-WLNP60L',
    'IHEALTHYULTRA-WLNP99L',
    'IHEALTHYULTRA-WL88J',
    'IHEALTHYULTRA-WL88S',
    'IHEALTHYULTRA-W85P10J',
    'IHEALTHYULTRA-W85P10S',
  ],
  IHEALTHY_ULTRA_GROUP_NEW_PAGE_FOR_LEGAL_PDF: [
    'IHEALTHYULTRA-20P10',
    'IHEALTHYULTRA-20P10S',
    'IHEALTHYULTRA-25P15',
    'IHEALTHYULTRA-25P15S',
    'IHEALTHYULTRA-12PL',
  ],
  LIFEREADY_CODE: {
    LIFEREADY6: 'W99FU06',
    LIFEREADY12: 'W99FU12',
    LIFEREADY18_UNDER: 'W99FU18',
    LIFEREADY18_HIGHER: 'W99FH18',
    LIFEREADY99_UNDER: 'W99FU99',
    LIFEREADY99_HIGHER: 'W99FH99',
  },
  LIFEREADY_CAMPAIGN_CODE: [
    'W99F99H',
    'W99F99M',
    'KOOMVER-COVER-1',
    'KOOMVER-COVER-2',
    'KOOMVER-COMPENSATE-1',
    'KOOMVER-COMPENSATE-2',
  ],
  LIFETREASURE_GROUP_CODE: ['H99F06A', 'H99F12A', 'H99F18A'],
  LIFEREADY_CAMPAIGN_CODE_W99F99H: 'W99F99H',
  LIFEREADY_CAMPAIGN_CODE_W99F99M: 'W99F99M',
  LIFETREASURE_CODE: {
    LIFETREASURE_6: 'H99F06A',
    LIFETREASURE_12: 'H99F12A',
    LIFETREASURE_18: 'H99F18A',
  },
  BUMNAN_READY_CODE: {
    BUMNAN_READY_8: 'R08F',
    BUMNAN_READY_60: 'R60F',
  },
  ULTIMATE_GROWTH_CODE: {
    ULTIMATE_GROWTH_2010: 'E20F10',
    ULTIMATE_GROWTH_2515: 'E25F15',
    ULTIMATE_GROWTH_2525: 'E25F25',
  },
  BUMNAN_READY_CODE_GROUP: ['R08F', 'R60F'],
  ULTIMATE_GROWTH_CODE_GROUP: ['E20F10', 'E25F15', 'E25F25'],
  PA_CODE_GROUP: ['PA_CLASSIC', 'PA_PLATINUM', 'PA_MAX'],
  CANCERX5_CODE: {
    CANCERX5_1: 'CANCERX5-1',
    CANCERX5_2: 'CANCERX5-2',
    CANCERX5_3: 'CANCERX5-3',
  },
  CANCERX5_DEFAULT_CODE: 'CANCERX5-2',
  CANCERX5_CAMPAIGN_CODE: ['CANCERX5-1', 'CANCERX5-2', 'CANCERX5-3'],
  KOOMVER_RETIRE: 'KOOMVER-RETIRE',
  KOOMVER_COVER_DEFAULT_CODE: 'KOOMVER-COVER-1',
  KOOMVER_COMPENSATE_DEFAULT_CODE: ['KOOMVER-COMPENSATE-1', 'KOOMVER-COMPENSATE-2'],
  LIFE_SUPER_SAVE_14_5_CODE: 'S14G5T1',
  LIFE_SUPER_SAVE_14_5_MAIN_CONTRACT_BENEFIT: {
    MINIMUM_DEATH_BENEFIT_PERCENT: 101,
    MATURITY_BENEFIT_PERCENT: 548,
  },
  MRTA_CODE: {
    MRTA_FULL: 'MRTA_FULL',
    MRTA_PLUS: 'MRTA_PLUS',
    MRTA_TRUNCATE: 'MRTA_TRUNCATE',
    MRTA_SIMPLY: 'MRTA_SIMPLY',
  },
  MRTA_TRUNCATE_YEAR_END_ROW: 11,
  MRTA_YEAR_END_ROW: 31,
  GLTSP_YEAR_END_ROW: 16,
  GLTSP_CODE: {
    GLTSP: 'GLTSP',
  },
  MRTA_TRUNCATE_MAX_LOAN_INSURE_AGE: 70,
  MRTA_CODE_GROUP_ACTIVE: ['MRTA_FULL', 'MRTA_PLUS', 'MRTA_TRUNCATE'],
  PRODUCT_CODE_FOR_REUSE_FLEXI_HEALTH: ['HEALTHTOPPING-WLNP60L'],
  SUKHAPABDEKDEE: 'SUKHAPABDEKDEE',
  SUKHAPABDEKDEE_CODE: 'SUKHAPABDEKDEE-WLNP85',
  SUKHAPABDEKDEE_DEFAULT_CAMPAIGNPLAN: 'MEK1',
  IGROW: 'IGROW',
  CASH: 'CASH',
  TMRTA: 'TMRTA',
  SPUL: 'SPUL',
  RPUL: 'RPUL',
  RPUDR: 'RPUDR',
  DEFAULT_RPP: 18000,
  '25PL': '25PL',
  '12PL': '12PL',
  '5WL85': '5WL85',
  WL6099: ['WLNP60L', 'WLNP99L'],
  LIFE_SAVE_PRO_20P10: ['20P10', '20P10S'],
  LIFE_SAVE_PRO_25P15: ['25P15', '25P15S'],
  LIFE_PROTECT_18: ['WL88J', 'WL88S'],
  LIFE_PROTECT_10: ['W85P10J', 'W85P10S'],
  S7_3PAY: ['CIWLM39', 'CIWLM50', 'CIWLM60'],
  S7_SINGLE: ['CIWLS39', 'CIWLS50', 'CIWLS65'],
  CIWLM39: 'CIWLM39',
  CIWLM50: 'CIWLM50',
  CIWLM60: 'CIWLM60',
  CIWLS39: 'CIWLS39',
  CIWLS50: 'CIWLS50',
  CIWLS65: 'CIWLS65',
  IHEALTHY_ULTRA_LIFE_SAVE_PRO_20P10: ['IHEALTHYULTRA-20P10', 'IHEALTHYULTRA-20P10S'],
  IHEALTHY_ULTRA_LIFE_SAVE_PRO_25P15: ['IHEALTHYULTRA-25P15', 'IHEALTHYULTRA-25P15S'],
  IHEALTHY_ULTRA_LIFE_PROTECT_18: ['IHEALTHYULTRA-WL88J', 'IHEALTHYULTRA-WL88S'],
  IHEALTHY_ULTRA_LIFE_PROTECT_10: ['IHEALTHYULTRA-W85P10J', 'IHEALTHYULTRA-W85P10S'],
  IHEALTHY_ULTRA_MIN_AGE: 6,
  IRETIRE_1: 'R01',
  IRETIRE_5: 'R05',
  LIFE_RETIRE_5: 'LR05',
  W85P10J: 'W85P10J',
  W85P10S: 'W85P10S',
  WLNP85: 'WLNP85',
  WLNP60L: 'WLNP60L',
  WLNP99L: 'WLNP99L',
  WL88J: 'WL88J',
  WL88S: 'WL88S',
  LIFE_SAVE_PRO_20P10_CODE: {
    JUNIOR: '20P10',
    SENIOR: '20P10S',
  },
  LIFE_SAVE_PRO_25P15_CODE: {
    JUNIOR: '25P15',
    SENIOR: '25P15S',
  },
  ISMART_80_6: 'W80F06',
  CODE_12PL: '12PL',
  PERFECT_LIFE: ['PERFECTLIFE-WL88J', 'PERFECTLIFE-WL88S'],
  RIDER_OPTION_GROUP_DISCOUNT: 'discount',
  RIDER_OPTION_GROUP_NO_DISCOUNT: 'no-discount',
  ADDITIONAL_INFORMATION: 'ADDITIONAL_INFORMATION',
  CAMPAIGN_PRODUCT_TYPE: 'campaign',
  ELIGIBILITY: 'ELIGIBILITY',
  FIRST_NAME_MIN_LENGTH: 1,
  FIRST_NAME_MAX_LENGTH: 30,
  TITLE_MIN_LENGTH: 1,
  TITLE_MAX_LENGTH: 30,
  HATS_AND_TAILS: ['ั', 'ิ', 'ี', 'ึ', 'ื', 'ุ', 'ู', 'ฺ', '็', '่', '้', '๊', '๋', '์', 'ํ', '๎'],
  INSURED: 'insured',
  LAST_NAME_MIN_LENGTH: 1,
  LAST_NAME_MAX_LENGTH: 50,
  LEGAL_DISCLAIMER_DURATION: 30,
  LEGAL_AGE: { value: 20, unit: 'year' },
  MAX_KID_AGE: { value: 15, unit: 'year' },
  MINIMUM_TOTAL_PREMIUM_FOR_MONTHLY: 1000,
  OPTION_ANY_VALUE: 'ANY_VALUE',
  OPTION_FIXED_VALUE: 'FIXED_VALUE',
  OPTION_FIXED_VALUE_HIDE_SUMASSURED: 'FIXED_VALUE_HIDE_SUMASSURED',
  OPTION_FIXED_VALUE_FROM_LIST: 'FIXED_VALUE_FROM_LIST',
  OPTION_VALUE_FROM_LIST: 'VALUE_FROM_LIST',
  OPTION_MULTI_VALUE_FROM_LIST: 'MULTI_VALUE_FROM_LIST',
  PAYER: 'payer',
  PREMIUM_LEVEL: 'LEVEL',
  PREMIUM_NON_LEVEL: 'NON_LEVEL',
  SOURCE_BASIC_SUM_ASSURED: 'BasicSumAssured',
  SOURCE_PERCENT_BASIC_SUM_ASSURED: 'PercentBasicSumAssured',
  SOURCE_BASIC_SUM_ASSURED_WITH_LIMITED_RANGE: 'BasicSumAssuredWithLimitedRange',
  SOURCE_BASIC_PREMIUM: 'BasicPremium',
  SOURCE_PRODUCT_PLAN: 'ProductPlan',
  SOURCE_RIDER_SUM_ASSURED: 'RiderSumAssured',
  SOURCE_TOTAL_PREMIUM: 'TotalPremium',
  SOURCE_TOTAL_ANNUAL_PREMIUM: 'TotalAnnualPremium',
  SOURCE_TOTAL_BASIC_ANNUAL_PREMIUM_WITH_LIMITED_RANGE: 'TotalBasicAnnualPremiumWithLimitedRange',
  SOURCE_RIDER_OCCUPATIONFACTOR: 'RiderOccupationFactor',
  SOURCE_RIDER_PAYER_OCCUPATIONFACTOR: 'RiderPayerOccupationFactor',
  TOTAL_DAYS_PER_YEAR: 365,
  TOTAL_DAYS_PER_MONTH: 30,
  TAX_DEDUCTION_PERCENT: 0.1,
  TAX_DEDUCTION_MAXIMUM_BASIC: 100000,
  TAX_DEDUCTION_MAXIMUM_RIDER: 15000,
  VALID_AGE: { value: 0, unit: 'day' },
  VALIDATION: 'VALIDATION',
  ZEBRA_BACKGROUND_BLUE: '#dce6f1',
  MEDIUM_BLUE: '#1f497d',
  HARD_BLUE: '#244061',
  PRIMARY_COLOR: '#2425aa',
  AXA_LOGO_LIGHT_BLUE: '#0098d8',
  EMERALD_GREEN_2021: '#a5b300',
  EMERALD_GREEN: '#c2e673',
  SAFFRON: '#fff8b3',
  EGG_WHITE: '#fff7c5',
  PURPLE_2021: '#cc91ff',
  PURPLE: '#e9d0ff',
  WOOD_BROWN: '#98946b',
  RED: '#af3f3f',
  CYAN: '#dbeef3',
  DARK_CYAN: '#dce6f1',
  PINK: '#f1dcdb',
  DARK_PINK: '#e6b8b7',
  GREEN: '#e5ead9',
  RISK_LEVEL_COLORS: {
    '1': '#025d34',
    '2': '#39ab48',
    '3': '#d3db31',
    '4': '#feda2e',
    '5': '#f2a848',
    '6': '#ec8b30',
    '7': '#df542e',
    '8': '#d92329',
  },
  RPUL_DEATH_BENEFIT_PERCENT: 120,
  SPUL_DEATH_BENEFIT_PERCENT: 110,
  RPUL_FUND_MIN_ALLOCATED: 100,
  FUND_DEFAULT_ALLOCATED: 100,
  PIE_CHART_FUND_COLORS: ['#0088fe', '#00c49f', '#ffbb28', '#ff8042', '#8884d8', '#d3db31', '#adb9ca', '#84d8a3'],
  BANC: 'BANC',
  // TODO [B] change WEALTH_HUB to WEALTH_HUB_USERS and change data type to Array - e.g. ['LB']
  WEALTH_HUB: 'LB',
  RPUL_FUNDS_RISK_THRESHOLD: 5,
  HIGH_RISK: 5,
  MEH: 'MEH',
  MHP: 'MHP',
  PARIDER: 'PARIDER',
  FH: 'FH',
  PLR: 'PLR',
  PLS: 'PLS',
  DCI: 'DCI',
  CI: 'CIUDR',
  TPUDR: 'TPUDR',
  WP: 'WP',
  WPTPD: 'WPTPD',
  WPTPDCI: 'WPTPDCI',
  WPD: 'WPD',
  WPDD: 'WPDD',
  WPC: 'WPC',
  WPC25PL: 'WPC25PL',
  WPCWL60: 'WPCWL60',
  WPCWL99: 'WPCWL99',
  WPEWL85: 'WPEWL85',
  WPEIP85: 'WPEIP85',
  WPDIP85: 'WPDIP85',
  ESCI: 'ESCI',
  ECARE: 'ECARE',
  AP: 'AP',
  APG: 'APG',
  AMG: 'AMG',
  MESG: 'MESG',
  WPDWL85: 'WPDWL85',
  TPDUDR: 'TPDUDR',
  HBUDR: 'HBUDR',
  CIUDR: 'CIUDR',
  HSUDR: 'HSUDR',
  MEK: 'MEK',
  MEK1: 'MEK1',
  MEK2: 'MEK2',
  MEX: 'MEX',
  PBPDD: 'PBPDD',
  PBPDDCI: 'PBPDDCI',
  PBSDD: 'PBSDD',
  PBSDDCI: 'PBSDDCI',
  MRTAAP: 'MRTAAP',
  TIME_ZONE: 'Asia/Bangkok',
  DATE_FORMAT: 'DD-MM-YYYY HH:mm:ss',
  TMO_EFFECTIVE_DATE: '01-06-2018 00:00:00',
  PLS_EFFECTIVE_DATE: '11-06-2018 08:00:00',
  COI_RATE_YEAR_BEFORE: '2551',
  COI_RATE_YEAR_CURRENT: '2560',
  PERIOD_BY_PACKAGE: 'by_package',
  PERIOD_LIMITED_BY_DURATION: 'limited_by_duration',
  PERIOD_LIMITED_TO_AGE: 'limited_to_age',
  MIN_OF_BMI_UNDERWEIGHT: 17,
  MAX_OF_BMI_UNDERWEIGHT: 17.99,

  AGENT_TYPE_AGENT: 'AGENT',
  AGENT_TYPE_LB: 'LB',
  AGENT_TYPE_FSA: 'FSA',
  AGENT_TYPE_CS: 'WS',
  AGENT_TYPE_BANCA: 'BA',
  AGENT_TYPE_FSE: 'FSE',

  MODEL_FACTOR_ANNUAL: 1,
  MODEL_FACTOR_SEMI_ANNUAL: 0.52,
  MODEL_FACTOR_MONTHLY: 0.09,
  MODEL_FACTOR_QUARTERLY: 0.27,
  MODEL_FACTOR_ONCE: 1,

  MODEL_FACTOR_PERIOD_ANNUAL: 1,
  MODEL_FACTOR_PERIOD_SEMI_ANNUAL: 2,
  MODEL_FACTOR_PERIOD_QUARTERLY: 4,
  MODEL_FACTOR_PERIOD_MONTHLY: 12,

  RIDER_PREMIUM_DISPLAY_MONTHLY: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  RIDER_PREMIUM_DISPLAY_QUATERLY: [1, 4, 7, 10],
  RIDER_PREMIUM_DISPLAY_SEMI_ANNUALLY: [1, 7],
  RIDER_PREMIUM_DISPLAY_ANNUALLY: [1],

  LSTU_FUND_ALLOCATION_OPTION: {
    SAME_AS_RPP: 'same_as_rpp',
    INDEPENDENT: 'independent',
  },
  DCA_DEFAULT_BANK: {
    monthlyCollect: 1,
    text: 'ธนาคารกรุงไทย',
    value: '006',
  },
  DCA_STATUS: {
    INFORMATION: 'information',
    UNSIGHED: 'unsigned',
    SIGNED: 'signed',
    SUBMITTED: 'submitted',
  },

  PENSION_ANNUALLY: 'annually',
  PENSION_MONTHLY: 'monthly',

  PENSION_TYPE_IRETIRE: {
    monthly: 'รายเดือน',
    annually: 'รายปี',
  },

  PENSION_PERCENT_IRETIRE_R05: {
    monthly: 1.68,
    annually: 20,
  },
  PENSION_PERCENT_IRETIRE_R01: {
    monthly: 0.42,
    annually: 5,
  },
  PENSION_PERCENT_LR05: {
    monthly: 1.26,
    annually: 15,
  },

  PENSION_PERCENT_R08F: {
    monthly: 1.52,
    annually: 18,
  },
  PENSION_PERCENT_R60F: {
    monthly: [
      {
        entryAge: {
          min: 60,
          max: 70,
        },
        percentPension: 1.27,
      },
      {
        entryAge: {
          min: 71,
          max: 80,
        },
        percentPension: 1.69,
      },
      {
        entryAge: {
          min: 81,
          max: 88,
        },
        percentPension: 2.11,
      },
    ],
    annually: [
      {
        entryAge: {
          min: 60,
          max: 70,
        },
        percentPension: 15,
      },
      {
        entryAge: {
          min: 71,
          max: 80,
        },
        percentPension: 20,
      },
      {
        entryAge: {
          min: 81,
          max: 88,
        },
        percentPension: 25,
      },
    ],
  },

  R60F_RANGE_OF_AGE_TO_GEN_PENSION_PERCENT: [60, 71, 81],

  PERCENT_MINIMUM_DEATH_COVERAGE_LR05: {
    surrender: 3.5,
    standard: 1.0,
  },
  PERCENT_MINIMUM_DEATH_COVERAGE_BUMNAN_READY: {
    surrender: 1.0,
    standard: {
      beforeFiveYearInsurance: 1.0,
      afterFiveYearInsurance: 1.8,
    },
  },
  PERCENT_MINIMUM_DEATH_COVERAGE: {
    surrender: 2.0,
    standard: 1.1,
  },

  TOTAL_PENSION_PERIOD: {
    monthly: 312,
    annually: 26,
  },
  TOTAL_BUMNAN_READY_PENSION_PERIOD: {
    monthly: 348,
    annually: 29,
    R08F: {
      monthly: 348,
      annually: 29,
    },
    R60F: {
      AGE_60_TO_70: {
        monthly: 132,
        annually: 11,
      },
      AGE_71_TO_80: {
        monthly: 120,
        annually: 10,
      },
      AGE_81_TO_88: {
        monthly: 96,
        annually: 8,
      },
    },
  },

  PENSION_PERIOD: {
    monthly: 180,
    annually: 15,
  },
  PENSION_TYPE: {
    anually: 'annually',
    monthly: 'monthly',
  },

  PERFECT_LIFE_PLAN: {
    PLAN_1: 'แผน 1',
    PLAN_2: 'แผน 2',
    PLAN_3: 'แผน 3',
  },

  HEALTH_TOPPING_PLAN: {
    PLAN_A: 'แผน เอ',
    PLAN_B: 'แผน บี',
  },
  MARKET_CONDUCT_QUESTIONS_TYPES: {
    ILP: 'ILP',
    NON_ILP: 'NON-ILP',
  },
  VALIDATED_ENTERING_ESUB_RULES: {
    'HEALTHTOPPING-WLNP60L': {
      allowAgentRule: {
        agentTypes: [
          {
            AGENT: {
              startDate: '01-01-2019 00:00:00',
              endDate: '01-01-2099 00:00:00',
            },
          },
        ],
        errorMessage: 'ผลิตภัณฑ์นี้ สำหรับช่องทางตัวแทน เท่านั้น',
      },
    },
    R01: {
      allowAgentRule: {
        agentTypes: [
          {
            AGENT: {
              startDate: '01-01-2019 00:00:00',
              endDate: '01-01-2099 00:00:00',
            },
            BANC: {
              startDate: '01-01-2019 00:00:00',
              endDate: '01-04-2020 00:00:00',
            },
          },
        ],
        errorMessage: 'ผลิตภัณฑ์นี้ สำหรับช่องทางตัวแทน เท่านั้น',
      },
    },
    R05: {
      allowAgentRule: {
        agentTypes: [
          {
            AGENT: {
              startDate: '01-01-2019 00:00:00',
              endDate: '01-01-2099 00:00:00',
            },
            BANC: {
              startDate: '01-01-2019 00:00:00',
              endDate: '01-04-2020 00:00:00',
            },
          },
        ],
        errorMessage: 'ผลิตภัณฑ์นี้ สำหรับช่องทางตัวแทน เท่านั้น',
      },
    },
  },
  PAYMENT: {
    QR: {
      TAXID: '010755500037600',
      TAXID_CROSSBANK: '010755500037601',
      REF2: '01',
    },
  },

  FNA_IS_VENERABLE_CLIENT: 'YES',
  FNA_NEVER_SPLITTING_PREMIUM_FROM_EXPENSE: 'Never',
  NEED_CATEGORY: {
    PROTECTION_AND_LEGACY: 'PROTECTION_AND_LEGACY',
    HEALTH: 'HEALTH',
    EDUCATION: 'EDUCATION',
    RETIREMENT: 'RETIREMENT',
    SAVING_AND_INVESTMENT: 'SAVING_AND_INVESTMENT',
    ILP: 'ILP',
  },
  PLANCODE_EXCEPT_PREMIUM_OCCUPATION_FACTOR: ['LR05', 'S14G5T1', 'R60F', 'R08F'],
  TERM_LIFE_PLANCODE_EXCEPT_CALCULATE_INVESTMENT_BENEFIT: ['S14G5T1', 'W80F06'],
  LIST_EXCEPT_ASTERISKS_BENEFIT_SUMMARY_TABLE: ['DCI', 'HIC', 'CPR', 'AP', 'ECARE', 'ESCI', 'WPEI85', 'MEX', 'MHP'],
  LIST_FIVE_ASTERISKS_BENEFIT_SUMMARY_TABLE: ['MEX', 'MHP'],

  PRODUCT_PLANCODE_NO_RIDER: ['CIWLM39', 'CIWLM50', 'CIWLM60', 'CIWLS39', 'CIWLS50', 'CIWLS65', 'S14G5T1'],

  INFORCE: {
    NO_LAPSE_GUARANTEE: 5,
    NO_LAPSE_GUARANTEE_WITH_MI: 1,
    MINIMUM_ISSUE_AGE_CIUDR: 0,
    MINIMUM_ISSUE_AGE_TPDUDR: 0,
    MINIMUM_ISSUE_AGE_HSUDR: 11,
    MINIMUM_ISSUE_AGE_HBUDR: 6,

    MAXIMUM_ISSUE_AGE_CIUDR: 70,
    MAXIMUM_ISSUE_AGE_TPDUDR: 70,
    MAXIMUM_ISSUE_AGE_HSUDR: 70,
    MAXIMUM_ISSUE_AGE_HBUDR: 65,

    MAXIMUM_COVERAGE_AGE_RPUDR: 85,
    MAXIMUM_COVERAGE_AGE_CIUDR: 85,
    MAXIMUM_COVERAGE_AGE_TPDUDR: 80,
    MAXIMUM_COVERAGE_AGE_HSUDR: 85,
    MAXIMUM_COVERAGE_AGE_HBUDR: 75,
  },

  IHEALTHY_ULTRA_LIST_PRODUCT_MULTI_PLANCODE: [
    'IHEALTHYULTRA-WL88J',
    'IHEALTHYULTRA-WL88S',
    'IHEALTHYULTRA-25P15',
    'IHEALTHYULTRA-25P15S',
    'IHEALTHYULTRA-20P10',
    'IHEALTHYULTRA-20P10S',
    'IHEALTHYULTRA-W85P10J',
    'IHEALTHYULTRA-W85P10S',
  ],

  LIFE_READY_LIST_PRODUCT_MULTI_PLANCODE: ['W99FU18', 'W99FH18', 'W99FU99', 'W99FH99'],
  LIFE_READY_18_GROUP: ['W99FU18', 'W99FH18'],
  LIFE_READY_99_GROUP: ['W99FU99', 'W99FH99'],

  MHP_AGE_OF_SMART_SILVER_JUVENILE_PLAN: {
    MIN: {
      value: 6,
      unit: 'year',
    },
    MAX: {
      value: 10,
      unit: 'year',
    },
  },

  LIST_OF_MHP_MULTI_PLANCODE: [
    'MHP1J',
    'MHP1S',
    'MHP2J',
    'MHP2S',
    'MHPD1J',
    'MHPD1S',
    'MHPD2J',
    'MHPD2S',
    'MHPC1J',
    'MHPC1S',
    'MHPC2J',
    'MHPC2S',
  ],
  LIST_OF_MHP_ASIA: ['MHP5SA', 'MHP6SA'],
  LIST_OF_MHP_WW: ['MHP5SW', 'MHP6SW'],
  LIST_OF_MHP_JUNIOR: ['MHP1J', 'MHP2J', 'MHPD1J', 'MHPD2J', 'MHPC1J', 'MHPC2J'],
  MHP_BENEFIT_GROUP: {
    MHP1J: ['MHPD1J', 'MHPC1J', 'MHP1J'],
    MHP1S: ['MHPD1S', 'MHPC1S', 'MHP1S'],
    MHP2J: ['MHPD2J', 'MHPC2J', 'MHP2J'],
    MHP2S: ['MHPD2S', 'MHPC2S', 'MHP2S'],
    MHP3S: ['MHPD3S', 'MHPC3S', 'MHP3S'],
    MHP4S: ['MHPD4S', 'MHPC4S', 'MHP4S'],
    MHP5S: ['MHPD5S', 'MHPC5S', 'MHP5S', 'MHP5SA', 'MHP5SW'],
    MHP6S: ['MHPD6S', 'MHPC6S', 'MHP6S', 'MHP6SA', 'MHP6SW'],
  },
  MHP_FULL_COVERAGE: 'full_coverage',
  MHP_CO_PAYMENT: 'co_payment',
  MHP_DEDUCTIBLE: 'deductible',
  QQ_ESUB_REPLACE_RIDER_SA_WITH_BASIC_SA: ['WPTPD', 'WPTPDCI'],
  RIDER_PB: ['PBPDD', 'PBPDDCI', 'PBSDD', 'PBSDDCI'],
  REQUIRED_RIDER_FOR_ADULT_INSURE_NOT_PAYER: ['PBPDD', 'PBPDDCI', 'PBSDD', 'PBSDDCI'],
  PRODUCT_GIO: ['S14G5T1'],
  PREFIX_PRODUCT_NAME_THAI: 'แบบประกัน',
  LIST_USE_DISPLAY_NAME_ON_ESUB: [
    'KOOMVER-COVER-1',
    'KOOMVER-COVER-2',
    'KOOMVER-COMPENSATE-1',
    'KOOMVER-COMPENSATE-2',
    'KOOMVER-RETIRE',
  ],
}
