//@flow
import { set, omit, isEmpty } from 'lodash/fp'
import type { Action as FnaActions } from './actions'
import {
  DISABLE_FNA_PHASE,
  ENABLE_FNA_PHASE,
  SET_FNA_VALUE,
  SET_FNA_VC_QUESTION,
  SET_FNA_SELLING_MODE,
  SET_FNA_BUYWITHADVISOR,
  SET_FNA_ISSOCIAL,
} from './actions'
import type { Action as NeedGapActions } from './need-and-gap-analysis/actions'
import type { Action as MarketConductsActions } from './market-conducts/actions'
import type { MarketConductState } from './market-conducts/reducer'
import marketConductsReducer, { initialMarketConduct } from './market-conducts/reducer'
import type { NeedGapState } from './need-and-gap-analysis/reducer'
import needAndGapReducer, { initialNeedGap } from './need-and-gap-analysis/reducer'
import { RESET_IS_SOCIAL, RESET_QUOTE_FORM } from '../actions'

type Action = FnaActions | NeedGapActions | MarketConductsActions

export type FnaState = {
  needGap: NeedGapState,
  marketConduct: MarketConductState,
  fnaPhase: boolean,
  sellingMode: string,
}

export const DEFAULT_FNA_STATE = {
  fnaPhase: false,
  needGap: initialNeedGap,
  marketConduct: initialMarketConduct,
  vulnerableQuestions: [],
  sellingMode: '',
  buyWithAdvisor: '',
  socialMediaC: '',
}

const fna = (state: FnaState = DEFAULT_FNA_STATE, action: Action) => {
  let newState
  switch (action.type) {
    case SET_FNA_VALUE:
      newState = set(action.payload.path, action.payload.value)(state)
      break
    case ENABLE_FNA_PHASE:
      newState = set('fnaPhase', true)(state)
      break
    case DISABLE_FNA_PHASE:
      newState = set('fnaPhase', false)(state)
      break
    case RESET_QUOTE_FORM:
      newState = set(
        'sellingMode',
        DEFAULT_FNA_STATE.sellingMode
      )(
        set(
          'vulnerableQuestions',
          DEFAULT_FNA_STATE.vulnerableQuestions
        )(set('fnaPhase', DEFAULT_FNA_STATE.fnaPhase)(set('buyWithAdvisor', DEFAULT_FNA_STATE.buyWithAdvisor)(state)))
      )
      break
    case SET_FNA_VC_QUESTION:
      newState = set('vulnerableQuestions', action.payload.value)(state)
      break
    case SET_FNA_SELLING_MODE:
      newState = set('sellingMode', action.payload.value)(state)
      break
    case SET_FNA_BUYWITHADVISOR:
      newState = set('buyWithAdvisor', action.payload.value)(state)
      break
    case SET_FNA_ISSOCIAL:
      newState = set('socialMediaC', action.payload.value)(state)
      break
    case RESET_IS_SOCIAL:
      newState = set('socialMediaC', DEFAULT_FNA_STATE.socialMediaC)(state)
      break
    default:
      newState = state
      break
  }
  newState = !isEmpty(newState.buyWithAdvisor) ? newState : omit(['buyWithAdvisor'], newState)
  return {
    ...newState,
    needGap: needAndGapReducer(newState.needGap, action),
    marketConduct: marketConductsReducer(newState.marketConduct, action),
  }
}

export const reducer = fna
