// @flow
import type { NatureOfDutyRule } from 'core/data-model/rider/rules'
import type { RiderEligibilities } from 'core/data-model/rider'
import type { ValidationMessage } from 'core/data-model/validation'
import riderMessages from 'core/data-model/constants/validation-messages'

export const checkNatureOfDutyRule = (rule: NatureOfDutyRule, eligibilities: RiderEligibilities): ValidationMessage => {
  let errorMessage = ''

  if (eligibilities.insured.occupation && eligibilities.insured.occupation.natureOfDutyCode === rule.natureOfDutyCode) {
    errorMessage = riderMessages.RULE_MESSAGE_NOT_FOUND
  }
  return {
    type: rule.validationType,
    message: errorMessage,
  }
}
