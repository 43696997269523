//@flow
import { eventChannel } from 'redux-saga'
import { includes } from 'lodash/fp'
import { instance } from 'e-submission/domain/data/request-wrapper'
import PATH from 'quick-quote/constants/path'
import _ from 'lodash'

export const APIErrorResponseFilterByExpectedUrl = (emit: Function) => {
  const id = instance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (!includes(PATH.ESUBMISSION, error.config.currentHref) && !includes(PATH.OPTY, error.config.currentHref)) {
        const data = _.get(error, 'response', {})
        emit(data)
      }
      return Promise.reject(error)
    }
  )
  return () => {
    instance.interceptors.response.eject(id)
  }
}

export const APIErrorResponse = (emit: Function) => APIErrorResponseFilterByExpectedUrl(emit)

export const APIErrorWatcher = eventChannel(APIErrorResponse)
