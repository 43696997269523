// @flow

// %checks is from Flow failing to recognise null/undefined check in function https://github.com/facebook/flow/issues/3417

import _ from 'lodash'

export const isNil = (value: ?*): boolean %checks => {
  return value == null || Number.isNaN(value)
}

export const isNotNil = (value: ?*): boolean %checks => {
  return !isNil(value)
}

export const isString = (value: ?*): boolean %checks => {
  return isNotNil(value) && typeof value === 'string' && _.isString(value)
}

export const isNumber = (value: ?*): boolean %checks => {
  return _.isNumber(value) && !Number.isNaN(value)
}

export const isObject = (value: ?*): boolean %checks => {
  return isNotNil(value) && typeof value === 'object' && _.isPlainObject(value)
}

export const isArray = (value: ?*): boolean %checks => {
  return isNotNil(value) && value instanceof Array
}

export const isEmpty = (value: ?*): boolean %checks => {
  return isNil(value) || _.isEmpty(value)
}
